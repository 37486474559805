import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const POINTER_TYPE = 'compBehaviours'

pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true))

const getterFunctions = {
    getCompBehavioursMapPointer(getItemAt, cache) {
        return cache.getPointer('compBehavioursMap', POINTER_TYPE, ['compBehavioursMap'])
    },
    getCompBehaviourPointer(getItemAt, cache, pageId, compId) {
        return cache.getPointer(`compBehavioursMap_${pageId}_${compId}`, POINTER_TYPE, ['compBehavioursMap', pageId, compId])
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions)
