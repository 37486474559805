define([
    'lodash',
    'documentServices/documentServicesDataFixer/fixers/styles/moveCustomStylesToPagesFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/duplicateCustomStylesDataFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/addMissingDefaultStylesDataFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/garbageCollectionFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/rgbaSystemColorsFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/unknownSystemStylesFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/utils'
], function (
    _,
    moveCustomStylesToPagesFixer,
    duplicateCustomStylesDataFixer,
    addMissingDefaultStylesDataFixer,
    garbageCollectionFixer,
    rgbaSystemColorsFixer,
    unknownSystemStylesFixer,
    styleFixerUtils
) {
    'use strict'
    return {
        exec(ps) {
            unknownSystemStylesFixer.exec(ps)

            if (_.get(ps.config, 'runStylesGC')) {
                garbageCollectionFixer.exec(ps)
            }

            const wasMigrated = styleFixerUtils.isSiteMigrated(ps)
            const didRunDuplicateStylesFixer = styleFixerUtils.didRunDuplicateStylesFixer(ps)

            const shouldMigrate = ps.runtimeConfig.stylesPerPage && !wasMigrated
            const shouldRunFixer = didRunBefore => ps.runtimeConfig.stylesPerPage && !didRunBefore && (shouldMigrate || wasMigrated)

            const shouldRunDuplicateStylesFixer = shouldRunFixer(didRunDuplicateStylesFixer)

            if (shouldMigrate) {
                moveCustomStylesToPagesFixer.exec(ps)
            }

            if (shouldRunDuplicateStylesFixer) {
                addMissingDefaultStylesDataFixer.exec(ps)
                duplicateCustomStylesDataFixer.exec(ps)
            }

            rgbaSystemColorsFixer.exec(ps)
        },
        name: 'stylesDataFixer',
        version: 1
    }
})
