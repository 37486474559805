define([], function () {
    'use strict'
    const metaData = {
        canBeStretched: true,
        styleCanBeApplied: true,
        layoutLimits: {
            minWidth: 45,
            minHeight: 70
        },

        defaultMobileProperties(ps, comp, desktopProps) {
            return {
                margin: Math.min(20, desktopProps.margin),
                numCols: desktopProps.numCols >= 2 ? 2 : 1
            }
        },

        mobileConversionConfig: {
            preserveAspectRatio: false
        }
    }

    return metaData
})
