import _ from 'lodash'
import * as skinsPackage from '../../../_internal/skins'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import viewsUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/viewsUtils'
import styleData from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleData'
import styleMap from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleMap.json'

const {skinsMap} = skinsPackage

/**
 * Adds a the style if it wasn't loaded already
 * @param {string} styleId The style Id
 * @param {string} skinName The name of the skin
 * @param {data.themeData} themeData
 * @param {{skinName: string, componentType: string}} loadedStyles
 */
function addStyleIfNeeded(styleId, skinName, getStyleData, loadedStyles) {
    styleId = _.isString(styleId) ? styleId : null
    const styleDef = styleId && getStyleData(styleId) && skinsMap.get(getStyleData(styleId).skin)
    let key
    if (styleDef) {
        key = styleId
    } else {
        const skinDef = skinName && skinsMap.get(skinName)
        key = skinDef ? coreUtils.santaTypes.shortenStyleId(skinName) : ''
    }
    if (key && !loadedStyles[key]) {
        loadedStyles[key] = {
            skinName
        }
    }
}

/**
 * Collect all the styles that are in use in this view definition and add it to the loadedStyles
 * @param {object} viewDef The view definition
 * @param {data.themeData} themeData
 * @param {{skinName: string, componentType: string}} loadedStyles
 */
function collectViewStyles(viewDef, getStyleData, loadedStyles) {
    function processViewDef(currViewDef) {
        const styleId = _.get(currViewDef, 'comp.style')
        const skinName = _.get(currViewDef, 'comp.skin') || (currViewDef.comp && styleData.getDefaultSkinName(currViewDef.comp.name))

        addStyleIfNeeded(styleId, skinName, getStyleData, loadedStyles)
    }

    viewsUtils.traverseViews(viewDef, processViewDef)
}

/**
 * Adds all the default styles that are set in StyleData
 * @param {data.themeData} themeData
 * @param {{skinName: string, componentType: string}} loadedStyles
 */
function addDefaultStyles(getStyleData, loadedStyles) {
    _.forEach(styleMap, function (compStyle) {
        _.forEach(compStyle, function (styleObj: any) {
            addStyleIfNeeded(styleObj.style, styleObj.skin, getStyleData, loadedStyles)
        })
    })
}

/**
 * @class wixappsCore.styleCollector
 */
export default {
    collectViewStyles,
    addStyleIfNeeded,
    addDefaultStyles
}
