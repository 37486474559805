import index from './src/wixappsBuilder'
export default index
export const {
    appBuilderSantaTypes,
    appBuilderSantaTypesDefinitions,
    appRepo,
    builderDataHandler,
    dataFetchingStateManager,
    viewsTemplatesUtils,
    fieldBoxProxyUtils,
    viewsTemplatesData,
    resolveImageData
} = index
