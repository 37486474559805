define([
    'lodash',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionBarManager',
    'documentServices/mobileConversion/mobileEditorSettings/quickActions',
    'documentServices/component/component',
    'documentServices/tpa/constants',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/tpa'
], function (_, quickActionBarManager, quickActions, component, constants, tpaEventHandlersService, tpa) {
    'use strict'

    function siteContainsLegacyActionBar(ps) {
        return quickActions.isEnabled(ps)
    }

    function isChatTpa(ps, compPointer) {
        const compData = component.data.get(ps, compPointer)
        return _.get(ps.dal.get(ps.pointers.general.getClientSpecMap()), [compData.applicationId, 'appDefinitionId']) === constants.APP_DEF_ID.CHAT
    }

    function addedGluedWidget(ps, compPointer) {
        const shouldUpgradeActionBar = isChatTpa(ps, compPointer) && !siteContainsLegacyActionBar(ps)
        if (shouldUpgradeActionBar) {
            quickActionBarManager.addQuickActionBar(ps)
        }
    }

    function willRemoveGluedWidget(ps, compPointer) {
        const shouldRevertToLegacyActionBar = isChatTpa(ps, compPointer) && _(quickActionBarManager.getActions(ps)).reject('isDynamic').isEmpty()
        if (shouldRevertToLegacyActionBar) {
            quickActionBarManager.removeQuickActionBar(ps)
        }
    }

    function executeDisconnectHandler(ps, compPointer) {
        tpaEventHandlersService.executeDisconnectHandler(compPointer.id)
    }

    const addOriginCompIdToWidgetsOnPage = serializedComp => {
        tpa.addOriginCompIdForTpaWidget(serializedComp)
    }

    return {
        addedGluedWidget,
        willRemoveGluedWidget,
        executeDisconnectHandler,
        addOriginCompIdToWidgetsOnPage
    }
})
