define(['lodash', 'documentServices/component/component'], function (_, component) {
    'use strict'

    function getRowMargin(ps, columnsContainerCompPtr) {
        return component.properties.get(ps, columnsContainerCompPtr).rowMargin
    }

    const PATH_TO_Y = ['layout', 'y']
    const PATH_TO_HEIGHT = ['layout', 'height']
    const moveY = (ps, compPtr, diff) => ps.dal.get(ps.pointers.getInnerPointer(compPtr, PATH_TO_Y)) + diff
    const getHeight = (ps, compPtr) => ps.dal.get(ps.pointers.getInnerPointer(compPtr, PATH_TO_HEIGHT))
    return function changeColumnsContainerHeight(ps, updatedColumnPointer, newLayoutToUpdate, updateCompLayoutFn, isTriggeredByHook, previousLayout) {
        if (isTriggeredByHook || !_.isNumber(newLayoutToUpdate.height) || newLayoutToUpdate.height === previousLayout.height) {
            return
        }

        const columnsContainerPtr = component.getContainer(ps, updatedColumnPointer)

        if (ps.pointers.components.isMobile(updatedColumnPointer)) {
            const heightDiff = newLayoutToUpdate.height - previousLayout.height
            const columnPointers = ps.pointers.components.getChildren(columnsContainerPtr)
            _(columnPointers)
                .takeRightWhile(columnPtr => columnPtr.id !== updatedColumnPointer.id)
                .forEach(columnPtr => updateCompLayoutFn(ps, columnPtr, {y: moveY(ps, columnPtr, heightDiff)}, updateCompLayoutFn, true))
            const containerHeight = _.reduce(columnPointers, (sum, ptr) => getHeight(ps, ptr) + sum, 0)
            ps.dal.set(ps.pointers.getInnerPointer(columnsContainerPtr, PATH_TO_HEIGHT), containerHeight)
            return
        }

        const rowMargins = getRowMargin(ps, columnsContainerPtr)

        const parentLayoutUpdate = {
            height: newLayoutToUpdate.height + 2 * rowMargins // eslint-disable-line no-mixed-operators
        }

        updateCompLayoutFn(ps, columnsContainerPtr, parentLayoutUpdate)
    }
})
