import coreUtils from '../../coreUtils'
import requireCompCode from './core/requireCompCode'
import RuntimeDal from './core/data/RuntimeDal'
import events from './bi/events'
import errors from './bi/errors'
import popoverAspectPropsFetcher from './aspects/popoverAspectPropsFetcher'
import storageAPI from './core/storageAPI'
import structureInfoGetter from './core/data/structureInfoGetter'
import ViewerDisplayedJsonUpdater from './core/data/ViewerDisplayedJsonUpdater'
import wixCodeComponentsFetcher from './core/data/wixCode/wixCodeComponentsFetcher'
import viewerCompsService from './core/viewerCompsService'
import externalComponentsServicesRegistry from './core/externalComponentsServicesRegistry'

export default {
    RuntimeDal,
    MobileDeviceAnalyzer: coreUtils.MobileDeviceAnalyzer,
    styleCollector: coreUtils.styleCollector,
    requireCompCode,
    behaviorHandlersFactory: coreUtils.behaviorHandlersFactory,
    behaviorsService: coreUtils.behaviorsService,
    biEvents: events,
    DataAccessPointers: coreUtils.pointers.DataAccessPointers,
    PointersCache: coreUtils.pointers.PointersCache,
    pointerGeneratorsRegistry: coreUtils.pointers.pointerGeneratorsRegistry,
    SimpleDisplayedJsonUpdater: ViewerDisplayedJsonUpdater,
    storageAPI,
    aspects: {
        popoverAspectPropsFetcher
    },
    wixCode: {
        componentsFetcher: wixCodeComponentsFetcher
    },
    errors,
    structureInfoGetter,
    animationsService: coreUtils.animationsService,
    viewerCompsService,
    externalComponentsServicesRegistry
}
