import _ from 'lodash'
import * as mobx from 'mobx'

export default function transform(siteData, path, value) {
    if (path.length > 1) {
        return value
    }

    const transformedRenderFlags = _.defaults(
        {
            componentPreviewStates: mobx.observable.map(value.componentPreviewStates)
        },
        value
    )

    return mobx.observable(transformedRenderFlags)
}
