import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import baseCompositeProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseCompositeProxy'
import '@wix/santa-ds-libs/src/wixappsCore/src/components/toggle/toggle'

function createInnerProxies() {
    const templateDef = this.getCompProp('templates')
    const onProxyInstance = this.renderChildProxy(templateDef.on, 'on')
    const offProxyInstance = this.renderChildProxy(templateDef.off, 'off')

    return [onProxyInstance, offProxyInstance]
}

/**
 * @class proxies.Toggle
 * @extends proxies.mixins.baseComposite
 */
export default {
    mixins: [baseCompositeProxy],

    renderProxy() {
        const children = createInnerProxies.call(this)
        const componentType = 'wixapps.integration.components.Toggle'
        const props = this.getChildCompProps(componentType)
        props.initialState = this.getCompProp('initialState') || 'off'

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props, children)
    }
}
