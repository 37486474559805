import santaComponents from '@wix/santa-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import mediaZoom from './mediaZoom'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import biEvents from '../bi/events.json'

/**
 * @class components.ImageZoom
 * @extends {core.skinBasedComp}`
 */
const imageZoom = {
    displayName: 'ImageZoom',
    mixins: [componentsCore.mixins.mediaZoomWrapperMixin],

    propTypes: _.assign(
        {
            id: santaComponents.santaTypesDefinitions.Component.id,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
            siteId: santaComponents.santaTypesDefinitions.RendererModel.siteId.isRequired,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth,
            navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
            getScreenHeight: santaComponents.santaTypesDefinitions.getScreenHeight.isRequired,
            getScreenWidth: santaComponents.santaTypesDefinitions.getScreenWidth.isRequired,
            getWindowInnerHeight: santaComponents.santaTypesDefinitions.__DangerousSantaTypes.getWindowInnerHeight.isRequired,
            getCustomMeasureMap: santaComponents.santaTypesDefinitions.__DangerousSantaTypes.getCustomMeasureMap.isRequired,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            pageItemAdditionalData: PropTypes.object,
            galleryCompId: PropTypes.string,
            zoom: santaComponents.santaTypesDefinitions.NonPageItemZoom.zoom,
            unzoom: santaComponents.santaTypesDefinitions.NonPageItemZoom.unzoom,
            currentZoomItem: santaComponents.santaTypesDefinitions.NonPageItemZoom.currentItem
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mediaZoom)
    ),

    isNonOptimizedView() {
        return this.props.isMobileDevice || this.props.isTabletDevice()
    },

    fireEnterExpandModeEvent() {
        this.props.reportBI(biEvents.ENTER_EXPAND_MODE, {
            site_id: this.props.siteId
        })
    },

    componentWillMount() {
        this.fireEnterExpandModeEvent()
    },

    getInitialState() {
        if (this.isNonOptimizedView()) {
            this.enableInnerScrolling = true
        }
        return {}
    },

    getPrevAndNextState() {
        let items
        let currIndex
        const galleryData = this.props.pageItemAdditionalData
        const ids = {
            next: null,
            prev: null
        }

        if (galleryData) {
            items = _.map(galleryData.items, 'id')
            if (items.length > 1) {
                currIndex = items.indexOf(this.props.compData.id)
                ids.next = items[(currIndex + 1) % items.length]
                ids.prev = items[(currIndex - 1 + items.length) % items.length]
            }
        }

        return ids
    },

    isDataChanged(prevProps, nextProps) {
        return prevProps.compData !== nextProps.compData
    },

    getChildComp(additionalProps, spacers) {
        const siteDataInformation = {
            isMobileDevice: this.props.isMobileDevice,
            isTabletDevice: this.props.isTabletDevice(),
            siteWidth: this.props.siteWidth
        }

        const screenMeasures = {
            width: this.props.getScreenWidth(),
            height: this.props.getScreenHeight(),
            innerHeight: this.props.getWindowInnerHeight()
        }

        const measurable = _.every(screenMeasures)

        const getDimensionsFunc = this.isNonOptimizedView()
            ? coreUtils.mediaZoomCalculations.getNonOptimizedViewDimensions
            : coreUtils.mediaZoomCalculations.getDesktopViewDimensions
        const props = {
            // @ts-ignore
            zoomDimensions: getDimensionsFunc(this.props.compData, siteDataInformation, measurable ? screenMeasures : null, spacers),
            quality: {quality: 90} // force jpeg compression to 90
        }
        _.assign(props, additionalProps)
        return this.createChildComponent(this.props.compData, 'wysiwyg.components.ImageZoomDisplayer', 'imageItem', props)
    },

    getBoxDimensions() {
        return this.props.getCustomMeasureMap(this.props.id + this.props.compData.id)
    },

    actualNavigateToItem(itemId) {
        if (!this.props.currentZoomItem) {
            const navigationInfo = _.clone(this.props.rootNavigationInfo)
            navigationInfo.pageItemId = itemId
            this.props.navigateToPage(navigationInfo)
        } else if (itemId) {
            const item = _.find(this.props.currentZoomItem.galleryData.items, {id: itemId})
            this.props.zoom(item, this.props.currentZoomItem.galleryData)
        } else {
            this.props.unzoom()
        }
    },

    getChildZoomComponentType() {
        return 'wysiwyg.viewer.components.MediaZoom'
    },
    getImageIndex() {
        const pageItemData = _.get(this.props.pageItemAdditionalData, 'items')
        if (!pageItemData) {
            return
        }
        return _.findIndex(pageItemData, {id: this.props.compData.id})
    },
    getChildZoomExtraProps() {
        return _.assign({}, _.pickBy({galleryCompId: this.props.galleryCompId}), {imageIndex: this.getImageIndex()})
    }
}

export default imageZoom
