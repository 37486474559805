import index from './src/wixappsClassics'
export default index
export const {
    appPartCommonDataManager,
    viewCacheUtils,
    numberOfPostsPerPageGetter,
    componentTypeUtil,
    descriptorUtils,
    transformMediaItemsResponse,
    blogSinglePostPageLogicUtils
} = index
