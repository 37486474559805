define(['documentServices/component/componentStylesAndSkinsAPI'], function (componentStylesAndSkinsAPI) {
    'use strict'

    function runStyleValidations(styleId, styleValue) {
        if (styleId) {
            if (!styleValue) {
                throw new Error(`There is no existing style for styleId [${styleId}]`)
            }
            if (typeof styleValue !== 'object') {
                throw new Error(`The given style for for styleId [${styleId}] is not an object`)
            }
            if (!styleValue.skin) {
                throw new Error(`The given style for for styleId [${styleId}] does not contain a skin`)
            }
            if (!styleValue.type) {
                throw new Error(`The given style for for styleId [${styleId}] does not contain a type`)
            }
        }
    }

    function validateComponentStyle(ps, compPointer) {
        const styleId = componentStylesAndSkinsAPI.style.getId(ps, compPointer)
        const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointer)
        runStyleValidations(styleId, styleItem)
    }

    /**
     * @exports documentServices/validation/validators/styleValidator
     */
    return {
        validateComponentStyle
    }
})
