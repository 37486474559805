import errors from '@wix/santa-ds-libs/src/core/src/bi/errors.json'
import _ from 'lodash'
import coreUtils from '../../../coreUtils'

const {logger} = coreUtils.loggingUtils

/**
 * Please ctrl/cmd + click on biError to see the schema :)
 * @type {Object.<String, biError>}
 */

_.forEach(errors, function (error, key) {
    // @ts-ignore
    error.errorName = key
})

logger.register('core', 'error', errors)

export default errors
