define(['documentServices/dataModel/dataModel', 'documentServices/dataModel/common', 'document-services-schemas', '@wix/santa-core-utils'], function (
    dataModel,
    common,
    documentServicesSchemas,
    santaCoreUtils
) {
    'use strict'

    const {data: DATA_TYPE} = santaCoreUtils.constants.DATA_TYPES
    const {schemasService} = documentServicesSchemas.services

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {null|*}
     */
    const getA11ySchema = (ps, compPointer) => {
        const data = dataModel.getDataItem(ps, compPointer)
        const dataType = data ? data.type : dataModel.getFirstValidDataType(ps, compPointer)
        const schema = schemasService.getSchema(DATA_TYPE, dataType)
        const a11yDefinition = common.getPropertyDefinition(schema, 'a11y', DATA_TYPE)
        if (a11yDefinition) {
            const a11Type = a11yDefinition.refTypes[0]
            const a11TypeSchema = schemasService.getSchema(DATA_TYPE, a11Type)
            return a11TypeSchema
                ? {
                      properties: a11TypeSchema.properties,
                      type: a11Type
                  }
                : null
        }
        return null
    }

    return {
        getA11ySchema
    }
})
