import serviceTopology from '@wix/santa-ds-libs/src/platformInit/src/utils/dataGetters/serviceTopologyDataGetter'
import clientSpec from '@wix/santa-ds-libs/src/platformInit/src/utils/dataGetters/clientSpecDataGetter'
import appStudioBundler from '@wix/santa-ds-libs/src/platformInit/src/utils/dataGetters/appStudioBundlerDataGetter'
import universalEditorApp from '@wix/santa-ds-libs/src/platformInit/src/utils/dataGetters/universalEditorAppDataGetter'
import appBuilderPreviewBundler from '@wix/santa-ds-libs/src/platformInit/src/utils/dataGetters/appBuilderPreviewBundlerDataGetter'

export default {
    serviceTopology,
    clientSpec,
    appStudioBundler,
    universalEditorApp,
    appBuilderPreviewBundler
}
