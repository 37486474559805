define([], function () {
    'use strict'

    const setResponsive = (ps, isResponsive) => {
        const isResponsivePointer = ps.pointers.rendererModel.isResponsive()
        ps.dal.full.set(isResponsivePointer, isResponsive)
    }

    return {
        setResponsive
    }
})
