define(['lodash'], function (_) {
    'use strict'

    /**
     * @param {string} packageName
     * @returns {!Array.<string>}
     */
    function getPackagePath(packageName) {
        return ['wixapps', packageName]
    }

    /**
     * @param {string} packageName
     * @returns {!Array.<string>}
     */
    function getPackageDescriptorPath(packageName) {
        return getPackagePath(packageName).concat(['descriptor'])
    }

    function getPackageMetaDataPath(packageName) {
        return _.compact(getPackagePath(packageName).concat(['metadata']))
    }

    function getAppPartDataPath(packageName, compId) {
        return getPackagePath(packageName).concat([compId])
    }

    function getAppPartItemsPath(packageName) {
        return getPackagePath(packageName).concat('items')
    }

    return {
        getPackagePath,
        getPackageDescriptorPath,
        getPackageMetaDataPath,
        getAppPartDataPath,
        getAppPartItemsPath
    }
})
