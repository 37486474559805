define(['lodash', 'documentServices/siteMetadata/clientSpecMap', 'documentServices/wixCode/utils/constants'], function (_, clientSpecMap, constants) {
    'use strict'

    function getExistingWixCodeAppFromPS(ps) {
        return _.head(clientSpecMap.filterAppsDataByType(ps, constants.WIX_CODE_SPEC_TYPE))
    }

    function getExistingWixCodeAppFromSnapshot(snapshotDal) {
        const specMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        return _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
    }

    function getExistingWixCodeAppFromDataProvider(dataProvider) {
        const specMap = dataProvider.clientSpecMap
        return _.find(specMap, {type: constants.WIX_CODE_SPEC_TYPE})
    }

    return {
        getExistingWixCodeAppFromPS,
        getExistingWixCodeAppFromSnapshot,
        getExistingWixCodeAppFromDataProvider
    }
})
