import _ from 'lodash'
import dataSelectorFactory from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/dataSelectorFactory'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

/**
 * Get all the items of the data selector that is defined in the part definition of this appPartName.
 * @param {AppRepoDefinition} appRepo
 * @param {string} appPartName
 * @param {SiteData} siteData
 * @param {object} appService The entry of this app in the clientSpecMap
 * @param {number} instanceVersion the version of the current repo
 * @returns {DataSelector} The data selector of the appPart
 */
function getDataSelector(appRepo, appPartName, siteData, appService, instanceVersion) {
    const dataSelectorDef = getDataSelectorDefinition(appRepo, appPartName)
    return dataSelectorDef ? dataSelectorFactory.getDataSelector(dataSelectorDef, siteData, appService, instanceVersion) : null
}

function getDataSelectorDefinition(appRepo, appPartName) {
    /** @type AppPartDefinition */
    const partDefinition = getAppPartDefinition(appRepo, appPartName)

    if (!partDefinition) {
        return null
    }

    const dataSelectorId = partDefinition.dataSelector || (partDefinition.dataSelectorDef && partDefinition.dataSelectorDef.id)
    return appRepo.dataSelectors[dataSelectorId]
}

/**
 * Get the AppPart Definition according to the (unique) appPartName.
 * @param {AppRepoDefinition} appRepo
 * @param {string} appPartName
 * @returns {AppPartDefinition}
 */
function getAppPartDefinition(appRepo, appPartName) {
    return _.get(appRepo, ['parts', appPartName])
}

/**
 * Get the view definition with the given name for the type and format needed.
 * @param {AppRepoDefinition} appRepo
 * @param {string} viewName
 * @param {string} type
 * @param {''|'Mobile'|'*'} formatName
 * @returns {ViewDefinition}
 */
function getViewDef(appRepo, viewName, type, formatName) {
    const idWithFormat = [type, viewName, formatName].join('|')
    const idWithoutFormat = [type, viewName].join('|')

    const viewDef = appRepo.views[idWithFormat] || appRepo.views[idWithoutFormat]
    if (viewDef) {
        // Adds missing ids (i.e. def_0, def_1) to proxies without id or data.
        wixapps.viewsUtils.fillViewDefMissingIDs(viewDef)
    }
    return viewDef
}

function getNamesOfPartsOfType(appRepo, typeId) {
    return _(appRepo.parts).pickBy({type: typeId}).keys().value()
}

function getPartViews(repo, partDefinition, formatName) {
    const compViews = {
        Array: getViewDef(repo, partDefinition.viewName, 'Array', formatName)
    }
    compViews[partDefinition.type] = getViewDef(repo, partDefinition.viewName, partDefinition.type, formatName)
    return compViews
}

/**
 * @class appRepo
 */
export default {
    getDataSelector,
    getAppPartDefinition,
    getViewDef,
    getPartViews,
    getDataSelectorDefinition,
    getNamesOfPartsOfType
}

/**
 * @typedef {object} AppRepoDefinition
 * @property {string} applicationInstanceVersion
 * @property {{DataSelectorDefinition[]}} dataSelectors
 * @property {{AppPartDefinition[]}} parts
 * @property {{ViewDefinition[]}} views
 */

/**
 * @typedef {object} AppPartPreDefinedSettings
 * @property {string[]} tags
 * @property {string} selectedTag
 */

/**
 * @typedef {object} AppPartDefinition
 * @property {?string} dataSelector
 * @property {?{id: string, predefinedSettings: AppPartPreDefinedSettings}} dataSelectorDef
 * @property {string} viewName
 * @property {string} type
 * @property {string} displayName
 */

/**
 * @typedef {object} ViewDefinition
 * @property {object} comp
 * @property {object[]} customizations
 * @property {string} forType
 * @property {string} id
 * @property {string} name
 * @property {?string} format
 */
