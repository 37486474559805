import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import layout from '../../util/layout'
import imageLayout from '../imageLayout'
import balataLayout from '../balataLayout'

const POPUP_OVERLAY_SKIN_PART_ID = warmupUtilsLib.mediaConsts.balataConsts.BALATA

function measurePage(compId, measureMap, nodesMap, siteData, structureInfo) {
    const pageNode = nodesMap[compId]
    if (pageNode.dataset.isMeshLayout !== 'true') {
        const pageContainerMarginHeight = measureMap.containerHeightMargin[compId] || 0
        //IMPORTANT! containerHeightMargin must be here or we will have an endless loop with anchors resizing the page
        measureMap.height[compId] =
            warmupUtilsLib.layoutUtils.getPageBottomChildEnd(measureMap, nodesMap, siteData.isMobileView(), structureInfo) + pageContainerMarginHeight
    }
}

function measurePopupPage(compId, measureMap, nodesMap, siteData, structureInfo) {
    const background = getPageBackground(compId, nodesMap, structureInfo)

    if (!background) {
        return
    }

    const overlayStructureInfo = _.assign({designDataItem: {background}}, structureInfo)
    overlayStructureInfo.id += POPUP_OVERLAY_SKIN_PART_ID
    measureMap.height[compId] = nodesMap[compId].offsetHeight
    balataLayout.measure(compId, measureMap, nodesMap, overlayStructureInfo)
}

function getPageBackground(compId, nodesMap, structureInfo) {
    const pageNode = nodesMap[compId]
    const isMobileMode = pageNode.getAttribute('data-ismobile') === 'true'
    const viewMode = isMobileMode ? 'mobile' : 'desktop'
    return _.get(structureInfo.dataItem, ['pageBackgrounds', viewMode, 'ref'])
}

function layoutPage(compId, nodesMap, measureMap, siteData, structureInfo) {
    const pageNode = nodesMap[compId]
    const isPagePopup = pageNode.getAttribute('data-ispopup') === 'true'

    measurePage(compId, measureMap, nodesMap, siteData, structureInfo)
    let imagePatcher: (a?) => void = () => {}
    if (isPagePopup) {
        measurePopupPage(compId, measureMap, nodesMap, siteData, structureInfo)
        imagePatcher = imageLayout.layoutChildNodeImages(compId, nodesMap, measureMap, siteData, structureInfo)
    }

    return patchers => {
        if (isPagePopup) {
            const overlayId = compId + POPUP_OVERLAY_SKIN_PART_ID
            const overlayStructureInfo = _.defaultsDeep(
                {
                    id: overlayId,
                    rootId: 'masterPage',
                    structure: {behaviors: []},
                    designDataItem: {background: getPageBackground(compId, nodesMap, structureInfo)}
                },
                structureInfo
            )

            balataLayout.patch(compId, patchers, measureMap, overlayStructureInfo, siteData)
            imagePatcher(patchers)
        }
    }
}

function getChildrenToMeasure(compId, nodesMap) {
    const isPopup = nodesMap[compId].getAttribute('data-ispopup') === 'true'
    const childrenToMeasure = isPopup ? balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE : []
    return [['inlineContent'], ...childrenToMeasure]
}

const COMP_TYPE = 'mobile.core.components.Page'

layout.registerRequestToMeasureDom(COMP_TYPE)
layout.registerMeasureChildrenFirst(COMP_TYPE, true)
layout.registerRequestToMeasureChildren(COMP_TYPE, getChildrenToMeasure)
layout.registerCustomLayoutFunction(COMP_TYPE, layoutPage)
