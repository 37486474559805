define(['lodash', 'documentServices/accessibility/utils'], function (_, accessibilityUtils) {
    'use strict'

    return {
        /**
         * @param {ps} ps
         * @returns {boolean}
         */
        get(ps) {
            const dataItem = ps.dal.get(accessibilityUtils.getAccessibilitySettingsDataPointer(ps))
            const vfFlag = _.get(dataItem, 'visualFocusDisabled')

            return _.isUndefined(vfFlag) ? false : !vfFlag
        },
        /**
         * @param {ps} ps
         * @param {boolean} enabled
         */
        set(ps, enabled) {
            if (!_.isBoolean(enabled)) {
                throw new Error('visualFocus.set accepts only boolean value')
            }

            const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
            const accessibilitySettings = ps.dal.get(pointer) || {}

            ps.dal.set(pointer, _.assign(accessibilitySettings, {visualFocusDisabled: !enabled}))
        }
    }
})
