define(['lodash', '@wix/mobile-conversion', '@wix/santa-core-utils', 'documentServices/theme/theme', 'documentServices/utils/utils'], function (
    _,
    mobileCore,
    santaCoreUtils,
    theme,
    dsUtils
) {
    'use strict'

    const DEFAULT_FONT_SIZE = 14
    const DEFAULT_TEXT_LENGTH = 10
    const MAX_MOBILE_FONT_SIZE = 24

    const heightMap = {
        breakPoints: [16, 19, 24],
        value: [42, 48, 54]
    }

    const widthMap = {
        breakPoints: [7, 14, 20, 30, 100],
        value: [120, 160, 200, 240, 280]
    }

    const getSizeByValue = (value, sizeBreakPoints) => {
        const {breakPoints} = sizeBreakPoints
        const normalizedValue = Math.min(value, _.last(breakPoints))
        const breakPointIndex = _.findIndex(breakPoints, breakPoint => normalizedValue <= breakPoint)
        return sizeBreakPoints.value[breakPointIndex]
    }

    const getButtonSize = (fontSize, textLength) => {
        const height = getSizeByValue(fontSize, heightMap)
        const width = getSizeByValue(textLength, widthMap)
        return {height, width}
    }

    function getDesktopFontSize(ps, styleId, pageId) {
        if (!styleId) {
            return DEFAULT_FONT_SIZE
        }
        const componentStyle = theme.styles.get(ps, styleId, pageId)
        const fontStyle = _.get(componentStyle, ['style', 'properties', 'fnt'], '')
        const fontSizePixels = _.get(fontStyle.match(/\d+px/), '0', `${DEFAULT_FONT_SIZE} + 'px'`)
        return +_.get(fontSizePixels.match(/\d+/), '0', DEFAULT_FONT_SIZE)
    }

    function getButtonTextLength(ps, buttonDataId, pageId) {
        if (!buttonDataId) {
            return DEFAULT_TEXT_LENGTH
        }
        const buttonDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(buttonDataId), pageId)
        const buttonText = _.get(ps.dal.get(buttonDataPointer), 'label', '')
        return buttonText.length || DEFAULT_TEXT_LENGTH
    }

    function getMobileScale(mobileFontSize, desktopFontSize) {
        const sizeAfterLegacyImplicitConversionInViewer = santaCoreUtils.mobileUtils.convertFontSizeToMobile(desktopFontSize, 1)
        return mobileFontSize / sizeAfterLegacyImplicitConversionInViewer
    }

    function getButtonConversionData(ps, component, pageId) {
        const desktopFontSize = getDesktopFontSize(ps, component.styleId, pageId) //this should consider refArray JIRA DM-4440
        const buttonTextLength = getButtonTextLength(ps, component.dataQuery, pageId)
        const mobileFontSize = Math.min(mobileCore.textUtils.convertFontSizeByTextLength(desktopFontSize, buttonTextLength), MAX_MOBILE_FONT_SIZE)

        const buttonConversionData = {}
        buttonConversionData.fixedSize = getButtonSize(mobileFontSize, buttonTextLength)
        buttonConversionData.mobileScale = getMobileScale(mobileFontSize, desktopFontSize)
        return buttonConversionData
    }

    return {
        getButtonConversionData
    }
})
