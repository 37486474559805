define(['lodash'], function (_) {
    'use strict'

    function setMobileMediaSizing(ps, pageId) {
        const pageDataPointer = ps.pointers.data.getDataItem(pageId, pageId)
        const pageBackgrounds = _.get(ps.dal.get(pageDataPointer), 'pageBackgrounds', {})
        const mobileBG = pageBackgrounds.mobile

        if (mobileBG && !mobileBG.mediaSizing) {
            mobileBG.mediaSizing = 'viewport'
            ps.dal.merge(pageDataPointer, {pageBackgrounds})
        }
    }

    function setMobileMediaSizingByPointer(ps, pagePointer) {
        setMobileMediaSizing(ps, pagePointer.id)
    }

    function setMediaSizingForDevice(ps, pageId, device) {
        if (device === 'mobile') {
            setMobileMediaSizing(ps, pageId)
        }
    }

    return {
        setMobileMediaSizing,
        setMobileMediaSizingByPointer,
        setMediaSizingForDevice
    }
})
