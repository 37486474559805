define(['documentServices/component/component', 'documentServices/structure/structure', 'documentServices/dataModel/dataModel'], function (
    component,
    structure,
    dataModel
) {
    'use strict'

    return (ps, compPointer) => {
        const bindToComp =
            func =>
            (...args) =>
                func(ps, compPointer, ...args)
        return {
            layout: {
                update: bindToComp(structure.updateCompLayout)
            },
            data: {
                update: bindToComp(dataModel.updateDataItem)
            },
            properties: {
                update: bindToComp(component.properties.update)
            }
        }
    }
})
