import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const CONTROLLER_OVERRIDE_QUERY_PARAM_NAME = 'controllersUrlOverride'
const WIDGET_COMPONENT_OVERRIDE_QUERY_PARAM_NAME = 'widgetsUrlOverride'

const getControllersOverride = queryParams => _.get(queryParams, CONTROLLER_OVERRIDE_QUERY_PARAM_NAME)
const getWidgetsOverride = queryParams => _.get(queryParams, WIDGET_COMPONENT_OVERRIDE_QUERY_PARAM_NAME)

const isValidOverrideUrl = (url, scriptsLocation) =>
    _.startsWith(url, scriptsLocation) || _.startsWith(url, 'http://localhost') || _.startsWith(url, 'https://localhost')

const getUrlOverrideForWidget = (widgetId, widgetsOverrideParamValue, scriptsLocation) =>
    getUrlOverrideForScript(widgetId, widgetsOverrideParamValue, scriptsLocation)

const getUrlOverrideForControllerScript = (controllerId, scriptsOverrideParamValue, scriptsLocation) =>
    getUrlOverrideForScript(controllerId, scriptsOverrideParamValue, scriptsLocation)

const getUrlOverrideForScript = (id, paramValue, scriptsLocation) => {
    if (paramValue) {
        const scriptOverrideMap = getScriptOverrides(paramValue)
        const scriptOverrideUrl = scriptOverrideMap[id]

        if (scriptOverrideUrl && scriptsLocation) {
            if (!isValidOverrideUrl(scriptOverrideUrl, scriptsLocation)) {
                warmupUtilsLib.log.verbose(`URL override ${scriptOverrideUrl} is invalid. please use a URL served from ${scriptsLocation} or localhost`)
                return
            }
        }

        return scriptOverrideUrl
    }
}

const getScriptOverrides = paramValue => {
    if (paramValue) {
        const allOverridesMap = paramValue.split(',')

        return _(allOverridesMap).invokeMap('split', '=').fromPairs().value()
    }
}

const getValidScriptOverrides = (paramValue, scriptsLocation) => {
    const allOverrideScripts = getScriptOverrides(paramValue)

    if (!scriptsLocation) {
        return allOverrideScripts
    }

    const validOverrideScripts = _.pickBy(allOverrideScripts, url => {
        const isUrlValid = isValidOverrideUrl(url, scriptsLocation)
        if (!isUrlValid) {
            warmupUtilsLib.log.verbose(`URL override ${url} is invalid. please use a URL served from ${scriptsLocation} or localhost`)
            return false
        }
        return true
    })

    return validOverrideScripts
}

export default {
    getControllersOverride,
    getWidgetsOverride,
    getUrlOverrideForWidget,
    getUrlOverrideForControllerScript,
    getValidScriptOverrides
}
