import blogUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/blog/utils'
import blogSettings from '@wix/santa-ds-libs/src/wixappsClassics/src/blog/blogSettings'

function translateToBlogLocale(translationKey) {
    const settings = blogSettings.get(this.siteData)
    const {locale} = settings

    return blogUtils.translate(locale, translationKey)
}

export default {
    translateToBlogLocale
}
