import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import santaComponents from '@wix/santa-components'
import coreUtilsLib from '@wix/santa-core-utils'
import tpaCompBaseMixin from '../mixins/tpaCompBaseMixin'
import tpaUrlBuilderMixin from '../mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '../mixins/tpaCompApiMixin'
import tpaWidgetMixin from '../mixins/tpaWidgetMixin'
import tpaResizeWindowMixin from '../mixins/tpaResizeWindowMixin'

const {compRegistrar} = componentsCore

/**
 * @class components.TPAWidget
 * @extends {tpa.mixins.tpaCompBase}
 * @extends {tpa.mixins.tpaCompApiMixin}
 * @extends {tpa.mixins.tpaUrlBuilder}
 */
const TPAWidget = {
    displayName: 'TPAWidget',
    propTypes: {
        structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired
    },
    mixins: [
        componentsCore.mixins.skinBasedComp,
        coreUtilsLib.timersMixins.timeoutsMixin,
        tpaCompBaseMixin,
        tpaUrlBuilderMixin,
        tpaCompApiMixin,
        tpaWidgetMixin,
        tpaResizeWindowMixin
    ],
    mutateIframeUrlQueryParam(queryParamsObj) {
        queryParamsObj.width = this.state.initialWidth

        const {originCompId} = this.props.structure
        if (originCompId && !_.isEmpty(originCompId)) {
            queryParamsObj.originCompId = originCompId
        }
        return queryParamsObj
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAWidget', TPAWidget)
export default TPAWidget
