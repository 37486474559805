import _ from 'lodash'

const globalProperties = ['quality', 'unsharpMask']

/**
 *
 * @param {object} data
 * @param {function(queryParam:string)} getData
 */
function imageDataResolver(data, getData) {
    const newData: Record<string, any> = {}

    //Resolve 'link' and 'originalImageRef'
    if (data.link) {
        newData.link = getData(data.link)
    }
    if (data.originalImageDataRef) {
        newData.originalImageDataRef = getData(data.originalImageDataRef)
    }

    // Apply global quality if exists
    const globalImageQuality = _.pick(getData('IMAGE_QUALITY'), globalProperties)

    if (_.isEmpty(data.quality) && !_.isEmpty(globalImageQuality)) {
        newData.quality = globalImageQuality
    }

    if (!_.isEmpty(newData)) {
        return _.defaults(newData, data)
    }

    return data
}

export default {
    resolve: imageDataResolver
}
