import _ from 'lodash'
import * as mobx from 'mobx'

export default function wixMap(data) {
    let observers = []
    // @ts-ignore
    const isInitialized = mobx.observable.shallowBox()
    let map = null

    const initializeMap = _.once(function () {
        // @ts-ignore
        map = mobx.observable.shallowMap(data)
        _.forEach(observers, function (callback) {
            map.observe(callback)
        })
        observers = []
        isInitialized.set(true)
    })

    return {
        WixMap: true,
        get(key) {
            return map ? map.get(key) : isInitialized.get() || data[key]
        },
        set: mobx.action(function (key, value) {
            initializeMap()
            map.set(key, value)
        }),
        keys() {
            return map ? map.keys() : isInitialized.get() || _.keys(data)
        },
        delete: mobx.action(function (key) {
            initializeMap()
            map.delete(key)
        }),
        has(key) {
            return map ? map.has(key) : isInitialized.get() || _.has(data, key)
        },
        merge: mobx.action(function (other) {
            initializeMap()
            map.merge(other)
        }),
        forEach(callback) {
            if (!map) {
                isInitialized.get()
                _.forEach(data, callback)
            } else {
                map.forEach(callback)
            }
        },
        size() {
            return map ? map.size() : isInitialized.get() || _.size(data)
        },
        toJS() {
            return map ? map.toJS() : isInitialized.get() || data
        },
        touch() {
            return map ? map.forEach(_.noop) : isInitialized.get()
        },
        observe(callback) {
            if (map) {
                map.observe(callback)
            } else {
                observers.push(callback)
            }
        }
    }
}
