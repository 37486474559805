import santaComponents from '@wix/santa-components'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import appPartStyleCollector from '@wix/santa-ds-libs/src/wixappsClassics/src/core/appPartStyleCollector'
import wixappsClassicsSantaTypes from '@wix/santa-ds-libs/src/wixappsClassics/src/core/wixappsClassicsSantaTypes'
import fontUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/util/fontUtils'
import appPart from '@wix/santa-ds-libs/src/wixappsClassics/src/comps/appPart'
import * as imageZoom from '../../../_internal/imageZoom'

componentsCore.compRegistrar.register('wixapps.integration.components.AppPart', appPart)
componentsCore.compRegistrar.register('wysiwyg.viewer.components.MediaZoom', imageZoom.mediaZoom)
componentsCore.compRegistrar.register('wysiwyg.viewer.components.MobileMediaZoom', imageZoom.mobileMediaZoom)

const {santaTypesDefinitions} = santaComponents
const AppPartClass = componentsCore.compRegistrar.getCompReactClass('wixapps.integration.components.AppPart')
const MediaZoom = componentsCore.compRegistrar.getCompReactClass('wysiwyg.viewer.components.MediaZoom')
const MobileMediaZoom = componentsCore.compRegistrar.getCompReactClass('wysiwyg.viewer.components.MobileMediaZoom')
const getCustomCss = wixapps.appPartCustomCss.getCustomCss(appPartStyleCollector)
const getCustomCompFonts = ({compData, partData, descriptor}) =>
    _.union(fontUtils.getFontFamiliesFromTextData(partData), fontUtils.getCustomFontFamilies(descriptor, compData))
const fontsTypes = {
    compData: santaTypesDefinitions.Component.compData,
    partData: wixappsClassicsSantaTypes.partData,
    descriptor: wixapps.CoreSantaTypes.descriptor
}
getCustomCompFonts.fontsTypes = fontsTypes

const getMediaZoomWrapperMixinWithCustomSkinBasedComp = mediaZoomMixin => {
    const mediaZoomMixinClone = _.cloneDeep(mediaZoomMixin)
    const customSkinBasedCompMixin = componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCss, customFontsFunc: getCustomCompFonts})
    mediaZoomMixinClone.mixins = _.map(mediaZoomMixinClone.mixins, mixin =>
        _.get(mixin.statics, ['getCompCss']) || _.get(mixin.statics, ['getCompFonts']) ? customSkinBasedCompMixin : mixin
    )

    return mediaZoomMixinClone
}
/**
 * @class components.AppPartZoom
 * @extends {componentsCore.mixins.mediaZoomWrapperMixin}
 */
export default {
    displayName: 'AppPartZoom',
    mixins: [getMediaZoomWrapperMixinWithCustomSkinBasedComp(componentsCore.mixins.mediaZoomWrapperMixin)],
    propTypes: _.assign(
        {},
        santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(AppPartClass.propTypes),
        santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(MediaZoom.propTypes),
        santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(MobileMediaZoom.propTypes),
        {
            compData: santaTypesDefinitions.Component.compData.isRequired,
            packageMetadata: wixapps.CoreSantaTypes.packageMetadata.isRequired,
            pageSubItemId: appPart.statics.santaTypeDefinitions.wixappsClassics.Zoom.pageSubItemId,
            packageName: wixapps.CoreSantaTypes.packageName.isRequired,
            getMeasures: wixapps.CoreSantaTypes.__DangerousSantaTypes.getMeasures.isRequired,
            pageItemAdditionalData: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
            isMobileView: santaTypesDefinitions.isMobileView.isRequired,
            navigateToPage: santaTypesDefinitions.navigateToPage.isRequired,
            getDataByPath: wixapps.CoreSantaTypes.Data.getDataByPath.isRequired,
            getAppPartZoomAdditionalDataPart: appPart.statics.santaTypeDefinitions.wixappsClassics.Zoom.getAppPartZoomAdditionalDataPart.isRequired
        }
    ),

    getDefaultProps() {
        return {
            pageItemAdditionalData: []
        }
    },

    componentWillMount() {
        this.enableInnerScrolling = true
    },

    componentDidMount() {
        if (this.props.packageMetadata.removed) {
            this.closeMediaZoom()
        }
    },

    getPartData() {
        let partData = this.props.compData
        if (!partData.appPartName) {
            partData = this.props.compData.dataItemRef
        }
        return partData
    },

    getPrevAndNextState() {
        let items = this.props.pageItemAdditionalData
        if (_.isString(this.props.pageItemAdditionalData)) {
            const path = this.props.pageItemAdditionalData.split('.')
            items = this.props.getDataByPath(this.props.packageName, path)
        }

        const ids = {
            prev: null,
            next: null
        }
        if (items.length > 1) {
            const myIndex = _.findIndex(
                items,
                function (item) {
                    // id - for inline items
                    // itemId - for refs
                    return item.id === this.props.pageSubItemId || item.itemId === this.props.pageSubItemId
                }.bind(this)
            )
            ids.next = items[myIndex < items.length - 1 ? myIndex + 1 : 0]
            ids.prev = items[myIndex > 0 ? myIndex - 1 : items.length - 1]
        }
        return ids
    },

    componentWillReceiveProps(nextProps) {
        this.dataChanged = this.props.pageSubItemId !== nextProps.pageSubItemId
    },

    isDataChanged() {
        return this.dataChanged
    },

    getChildComp(additionalProps) {
        const partData = this.getPartData()

        additionalProps.key = this.props.pageSubItemId
        //other wise the id in the dom is different from the ref path
        additionalProps.id = this.props.id + this.props.compData.id + partData.id

        return this.createChildComponent(partData, 'wixapps.integration.components.AppPart', 'appPart', additionalProps)
    },

    getBoxDimensions() {
        //this is for the ecom migration, we might try to render the zoom when the app doesn't exist anymore
        //if someone got to the product page from the outside
        if (!this.props.packageName) {
            return {
                imageContainerWidth: 0,
                dialogBoxHeight: 0,
                dialogBoxWidth: 0,
                marginTop: 0,
                padding: 0
            }
        }
        const partDataId = this.getPartData().id
        const appPartComp = this.refs[this.props.compData.id].refs[partDataId]

        const displayerSize = this.props.getMeasures(this.props.id).custom
        const layoutRootProxy = appPartComp.getLayoutRootProxy()
        const imageContainerWidth = layoutRootProxy ? layoutRootProxy.getProxyStyle().width : 200

        return {
            imageContainerWidth,
            dialogBoxHeight: displayerSize.height,
            dialogBoxWidth: imageContainerWidth,
            marginTop: displayerSize.marginTop,
            padding: 0
        }
    },

    actualNavigateToItem(itemRef) {
        const navigationInfo = _.clone(this.props.rootNavigationInfo)

        let title = null
        let itemId = null
        if (itemRef.collectionId) {
            const pathToTitle = [itemRef.collectionId, itemRef.itemId, 'title']
            title = this.props.getDataByPath(this.props.packageName, pathToTitle)
            itemId = itemRef.itemId
        } else {
            navigationInfo.title = navigationInfo.title || 'product'
            title = itemRef.title
            itemId = itemRef.id
        }
        navigationInfo.pageAdditionalData = this.props.getAppPartZoomAdditionalDataPart(itemId, title, navigationInfo.title)

        this.props.navigateToPage(navigationInfo)
    },

    getChildZoomComponentType() {
        if (this.props.isMobileView) {
            return 'wysiwyg.viewer.components.MobileMediaZoom' //mobile view only!
        }
        return 'wysiwyg.viewer.components.MediaZoom' //desktop, mobile non optimized or tablet
    },

    getChildZoomExtraProps() {
        return {}
    }
}
