import $ from 'zepto'
import layout from '../util/layout'

const stylableHorizontalMenuCompName = 'wixui.StylableHorizontalMenu'

const patchFitToContentCompHeight = (id, patchers) => {
    patchers.css(id, {
        height: ''
    })
}

const originalHeightPatcher = (id, patchers, measureMap) => {
    patchers.css(id, {
        height: measureMap.height[id]
    })
}

function measureStylableHorizontalMenu(id, measureMap, nodesMap) {
    const $menu = $(nodesMap[id])
    const menuMode = $menu.data('menuMode')
    const shouldApplyPureMeasurements = menuMode === 'wrap'

    if (shouldApplyPureMeasurements) {
        layout.registerPatcher(stylableHorizontalMenuCompName, patchFitToContentCompHeight)
        layout.registerPureDomHeightMeasure(stylableHorizontalMenuCompName)
    } else {
        layout.registerPatcher(stylableHorizontalMenuCompName, originalHeightPatcher)
        layout.unregisterPureDomHeightMeasure(stylableHorizontalMenuCompName)
    }
}

layout.registerCustomMeasure(stylableHorizontalMenuCompName, measureStylableHorizontalMenu)

export default {stylableHorizontalMenuCompName}
