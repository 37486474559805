import PropTypes from 'prop-types'
import santaComponents from '@wix/santa-components'

const {utils: santaUtils} = santaComponents

export default santaUtils.createSantaTypesDefinitions(
    {
        appPartDefinition: PropTypes.object,
        dataSelector: PropTypes.object,
        dataSelectorDefinition: PropTypes.object,
        partDataLocation: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
        partData: PropTypes.arrayOf(PropTypes.object),
        views: PropTypes.object,
        compMetadata: PropTypes.object,
        isRequestSent: PropTypes.bool,
        didPackageHadRequestsTimeoutInSSR: PropTypes.bool
    },
    'listBuilderSantaTypes'
)
