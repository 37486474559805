import * as skinsPackage from '../../../../_internal/skins'
import santaComponents from '@wix/santa-components'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import {infoTip} from '../../../../_internal/components'

const toolTipId = 'toolTip'

/**
 * @class optionInput
 * @extends {skinBasedComp}
 */
export default {
    propTypes: santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(infoTip),
    mixins: [santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap), componentsCore.mixins.createChildComponentMixin],

    getInitialState() {
        return this.getCssState(this.props)
    },

    componentWillReceiveProps(props) {
        this.setState(this.getCssState(props))
    },

    getCssState(props) {
        return {
            $enabledState: props.compData.disabled ? 'disabled' : 'enabled',
            $selectState: props.selected ? 'selected' : 'unselected'
        }
    },

    onMouseEnter() {
        this.refs[toolTipId].showToolTip({}, {source: this})
    },

    onMouseLeave() {
        this.refs[toolTipId].closeToolTip()
    },

    createInfoTipChildComponent() {
        return this.createChildComponent(
            {
                content: this.props.compData.description,
                id: toolTipId
            },
            'wysiwyg.common.components.InfoTip',
            {
                //TODO: this should be in skin exports
                skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin',
                styleId: coreUtils.santaTypes.shortenStyleId('wixapps.integration.skins.ecommerce.options.InfoTipSkin')
            },
            {
                ref: toolTipId,
                className: `${this.props.styleId}tooltip`
            }
        )
    }
}
