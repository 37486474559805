import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
// import imageClientApi from '@wix/image-client-api/dist/cjs/sdk/api'
import imageClientApi from '@wix/image-client-api/dist/imageClientApi'
import ajaxLibrary from './core/ajaxLibrary'
import trackingReporter from './core/trackingReporter'
import shouldShowDebugInformation from './core/shouldShowDebugInformation'
import indicator from './core/indicator'
import compAlignmentUtils from './core/compAlignmentUtils'
import coreTpaUtils from './core/coreTpaUtils'
import flatStructureUtil from './core/flatStructureUtil'
import languages from './core/languages'
import layoutUtils from './core/layoutUtils'
import mobileUtils from './core/mobileUtils'
import maxScrollHandler from './core/maxScrollHandler'
import measurerUtils from './core/measurerUtils'
import mobxDataHandlers from './core/mobxDataHandlers'
import multilingual from './core/multilingual'
import pathValidationUtil from './core/pathValidationUtil'
import positionAndSize from './core/positionAndSize'
import proxyLayoutRegistrar from './core/proxyLayoutRegistrar'
import routerUtils from './core/routerUtils'
import sessionFreezer from './core/SessionFreezer'
import SiteDataPrivates from './core/SiteDataPrivates'
import structuresDescription from './core/structuresDescription'
import svgFeatureDetection from './core/svgFeatureDetection'
import touchMediaZoomUtils from './core/touchMediaZoomUtils'
import warmupMenuUtils from './core/warmupMenuUtils'
import siteDataUtils from './dal/siteDataUtils'
import DALFactory from './dal/DALFactory'
import loggingUtils from './loggingUtils/loggingUtils'
import pointers from './pointers/pointers'
import dataResolver from './siteData/dataResolver'
import MobileDeviceAnalyzer from './siteData/MobileDeviceAnalyzer'
import pagesUrlUtils from './siteData/pagesUrlUtils'
import SiteData from './siteData/SiteData'
import layoutAPI from './siteData/layoutAPI'
import siteStructureUtils from './siteData/siteStructureUtils'
import wixUrlParser from './wixUrlParser/wixUrlParser'
import dataRefsMap from './siteData/dataRefsMap'
import tpaWarmup from './tpa/tpaWarmup'
import platformAppsUtils from './siteData/platformAppsUtils'

export default _.assign({}, warmupUtilsLib, {
    dataRefs: dataRefsMap, // used by santa-renderer to resolve the data items
    ajaxLibrary,
    trackingReporter,
    shouldShowDebugInformation,
    indicator,
    compAlignmentUtils,
    coreTpaUtils,
    DALFactory,
    dataResolver,
    flatStructureUtil,
    imageClientApi,
    languages,
    layoutUtils,
    mobileUtils,
    log: warmupUtilsLib.log,
    loggingUtils,
    maxScrollHandler,
    measurerUtils,
    MobileDeviceAnalyzer,
    mobxDataHandlers,
    multilingual,
    pagesUrlUtils,
    pathValidationUtil,
    pointers,
    positionAndSize,
    proxyLayoutRegistrar,
    routerUtils,
    sessionFreezer,
    SiteData,
    layoutAPI,
    SiteDataPrivates,
    siteDataUtils,
    siteStructureUtils,
    structuresDescription,
    // @ts-ignore
    svgFeatureDetection,
    touchMediaZoomUtils,
    warmupMenuUtils,
    wixUrlParser,
    tpaWarmup,
    platformAppsUtils
}) as any
