define([
    'lodash',
    'documentServices/multilingual/multilingual',
    '@wix/santa-multilingual/dist/languages',
    'documentServices/hooks/hooks',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/utils'
], function (_, multilingual, coreMultilingual, hooks, extensionsAPI, dsUtils) {
    'use strict'

    const {languageStatus} = coreMultilingual
    const getLanguageCode = _.property('languageCode')

    function _includesLanguage(languages, language) {
        return _(languages).map(getLanguageCode).includes(getLanguageCode(language))
    }

    function getCurrentLanguageCode(ps) {
        return multilingual.getCurrentLanguageCode(ps)
    }

    function setCurrentLanguageCode(ps, languageCode) {
        if (isSiteLanguage(ps, {languageCode})) {
            multilingual.setCurrentLanguage(ps, languageCode)
            hooks.executeHook(hooks.HOOKS.MULTILINGUAL.AFTER_CHANGE_LANGUAGE, null, [ps, languageCode])
        }
    }

    function init(ps, {originalLanguage, translationLanguages}) {
        ps.dal.set(ps.pointers.multilingual.originalLanguage(), originalLanguage)
        ps.dal.set(ps.pointers.multilingual.translationLanguages(), translationLanguages)
        setCurrentLanguageCode(ps, getLanguageCode(originalLanguage) || null)
    }

    function initPageTranslations(ps, language) {
        const pageIds = extensionsAPI.pages.getAllPagesIds(ps)
        pageIds.forEach(id => {
            const emptyTranslationData = {[getLanguageCode(language)]: _.cloneDeep(multilingual.EMPTY_TRANSLATION_DATA)}
            const translationsPointer = ps.pointers.page.getPageTranslations(id)
            const existingTranslationData = ps.dal.fullJsonDal.get(translationsPointer)
            const newTranslationData = _.assign(emptyTranslationData, existingTranslationData)
            ps.dal.fullJsonDal.set(translationsPointer, newTranslationData)
        })
    }

    function isMultilingualEnabled(ps) {
        return !_.isEmpty(getTranslationLanguages(ps))
    }

    function getLanguagePublicAvailability(ps, language) {
        const publicLanguages = getSitePublicLanguages(ps)
        return _includesLanguage(publicLanguages, language)
    }

    function setLanguageStatus(ps, language, status) {
        const translationLanguages = getTranslationLanguages(ps)
        const isTranslationLanguage = _includesLanguage(translationLanguages, language)
        if (isTranslationLanguage) {
            const updatedLanguage = _.assign({}, language, {status})
            const updatedLanguages = translationLanguages.map(translationLanguage =>
                getLanguageCode(translationLanguage) === getLanguageCode(language) ? updatedLanguage : translationLanguage
            )
            _setTranslationLanguages(ps, updatedLanguages)

            if (status === languageStatus.DELETED && getCurrentLanguageCode(ps) === language.languageCode) {
                const originalLanguage = getOriginalLanguage(ps)
                setCurrentLanguageCode(ps, originalLanguage.languageCode)
            }
        }
    }

    function addTranslationLanguage(ps, language) {
        if (!isSiteLanguage(ps, language)) {
            const newTranslationLanguages = getTranslationLanguages(ps).concat(language)
            _setTranslationLanguages(ps, newTranslationLanguages)
            initPageTranslations(ps, language)
        }
    }

    function removeTranslationLanguage(ps, language) {
        const siteLanguages = getTranslationLanguages(ps)
        const languageQuery = {languageCode: getLanguageCode(language)}
        const targetLanguage = _.find(siteLanguages, languageQuery)

        if (targetLanguage) {
            const newTranslationLanguages = _.reject(siteLanguages, languageQuery)
            const newActiveTranslationLanguages = _.filter(newTranslationLanguages, {status: languageStatus.ACTIVE})

            if (_.isEmpty(newActiveTranslationLanguages)) {
                setLanguageStatus(ps, getOriginalLanguage(ps), languageStatus.ACTIVE)
            }
            const {dal, pointers} = ps
            dal.set(pointers.multilingual.translationLanguages(), newTranslationLanguages)
        }
    }

    function setOriginalLanguage(ps, language) {
        if (!isSiteLanguage(ps, language)) {
            const previousOriginalLanguageCode = getLanguageCode(getOriginalLanguage(ps))
            language.status = languageStatus.ACTIVE
            const {dal, pointers} = ps
            dal.set(pointers.multilingual.originalLanguage(), language)
            if (getCurrentLanguageCode(ps) === previousOriginalLanguageCode) {
                setCurrentLanguageCode(ps, getLanguageCode(language))
            }
        }
    }

    function getOriginalLanguage({dal, pointers}) {
        return dal.get(pointers.multilingual.originalLanguage())
    }

    function getTranslationLanguages({dal, pointers}) {
        return dal.get(pointers.multilingual.translationLanguages()) || []
    }

    const getTranslationLanguageCodes = ps => getTranslationLanguages(ps).map(getLanguageCode)

    function _setTranslationLanguages({dal, pointers}, translationLanguages) {
        return dal.set(pointers.multilingual.translationLanguages(), translationLanguages)
    }

    function getNonDeletedTranslationLanguages({dal, pointers}) {
        return _.reject(getTranslationLanguages({dal, pointers}), {status: languageStatus.DELETED})
    }

    function getAllLanguages(ps) {
        return _.concat(getOriginalLanguage(ps), getNonDeletedTranslationLanguages(ps))
    }

    function getSitePublicLanguages(ps) {
        return _.filter(getAllLanguages(ps), {status: languageStatus.ACTIVE})
    }

    function isSiteLanguage(ps, language) {
        return _includesLanguage(getAllLanguages(ps), language)
    }

    function getComponentPageId(ps, componentPointer) {
        const originalPagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
        return ps.dal.fullJsonDal.get(originalPagePointer).id
    }

    function getComponentDataId(ps, componentPointer) {
        const originalDataQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'dataQuery')
        const dataQuery = ps.dal.fullJsonDal.get(originalDataQueryPointer)
        if (!_.isUndefined(dataQuery)) {
            return dsUtils.stripHashIfExists(dataQuery)
        }
        return null
    }

    function copyTranslationData(ps, newPointer, translationData, dataItemIdMap) {
        const compPageId = getComponentPageId(ps, newPointer)

        _.forEach(translationData, (dataItemMap, languageCode) => {
            _.forEach(dataItemMap, (dataItem, dataItemId) => {
                if (!dataItemIdMap[dataItemId]) {
                    return
                }
                const newDataItem = _.cloneDeep(dataItem)

                // this differentiates between copy-paste operation and page duplication operation (DM-2023)
                if (!ps.pointers.components.isPage(newPointer)) {
                    newDataItem.compId = newPointer.id
                }

                newDataItem.id = dataItemIdMap[dataItemId]

                const newDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(compPageId, languageCode, newDataItem.id)
                ps.dal.fullJsonDal.set(newDataItemPointer, newDataItem)
            })
        })

        ps.siteDataAPI.createDisplayedNode(newPointer)
    }

    function getComponentTranslationData(ps, rootComponentPointer) {
        const componentPageId = getComponentPageId(ps, rootComponentPointer)
        const translationsPointer = ps.pointers.page.getPageTranslations(componentPageId)
        const pageTranslationData = ps.dal.fullJsonDal.get(translationsPointer)
        const componentPointers = _.concat(rootComponentPointer, ps.pointers.components.getChildrenRecursively(rootComponentPointer))
        return _.reduce(
            pageTranslationData,
            (componentTranslations, translation, languageCode) => {
                _.forEach(componentPointers, componentPointer => {
                    const componentDataId = getComponentDataId(ps, componentPointer)
                    if (!_.isNull(componentDataId)) {
                        const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
                            componentPageId,
                            languageCode,
                            componentDataId
                        )
                        if (ps.dal.fullJsonDal.isExist(translationDataItemPointer)) {
                            const translationDataItem = ps.dal.fullJsonDal.get(translationDataItemPointer)
                            _.merge(componentTranslations, {[languageCode]: {[componentDataId]: translationDataItem}})
                        }
                    }
                })
                return componentTranslations
            },
            {}
        )
    }

    function patchTranslations(ps, pageId, dataItemId, patch) {
        _.forEach(getTranslationLanguages(ps), ({languageCode}) => {
            const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(pageId, languageCode, dataItemId)
            if (ps.dal.fullJsonDal.isExist(translationDataItemPointer)) {
                const translationDataItem = ps.dal.fullJsonDal.get(translationDataItemPointer)
                const updatedTranslationDataItem = _.isFunction(patch) ? patch(translationDataItem) : _.merge(translationDataItem, patch)
                ps.dal.fullJsonDal.set(translationDataItemPointer, updatedTranslationDataItem)
            }
        })
    }

    function patchPageTranslations(ps, pageId, patch) {
        patchTranslations(ps, 'masterPage', pageId, patch)
    }

    function isCurrentLanguageSecondary(ps) {
        return isMultilingualEnabled(ps) && getOriginalLanguage(ps).languageCode !== getCurrentLanguageCode(ps)
    }

    function patchDataItemTranslations(ps, pageId, dataItemId, patch) {
        patchTranslations(ps, pageId, dataItemId, patch)
    }

    function copyDataItemTranslations(ps, pageId, dataItemId, newPageId, newData) {
        _.forEach(getTranslationLanguages(ps), ({languageCode}) => {
            const translationPointer = ps.pointers.multilingualTranslations.translationDataItem(pageId, languageCode, dataItemId)

            if (ps.dal.fullJsonDal.isExist(translationPointer)) {
                const translationDataItem = ps.dal.fullJsonDal.get(translationPointer)
                const newDataItem = _.assign({}, _.cloneDeep(translationDataItem), newData)
                const newTranslationPointer = ps.pointers.multilingualTranslations.translationDataItem(newPageId, languageCode, newDataItem.id)
                ps.dal.fullJsonDal.set(newTranslationPointer, newDataItem)
            }
        })
    }

    /** @class documentServices.language */
    return {
        /** @class documentServices.language.multilingual */
        multilingual: {
            /**
             * Gets the enabled/disabled state of the multilingual feature.
             *
             * @returns {boolean} true if the multilingual feature is enabled, false otherwise.
             */
            isEnabled: isMultilingualEnabled
        },
        /** @class documentServices.language.current */
        current: {
            /**
             * Gets the current active language for the site.
             *
             * @returns {string} An ISO-639-3 language code of the site's current active language .
             */
            get: getCurrentLanguageCode,
            /**
             * Sets the current active language for the site.
             *
             * @param {string} languageCode An ISO-639-3 language code to be set as the active language for the site.
             */
            set: setCurrentLanguageCode
        },
        /** @class documentServices.language.original */
        original: {
            /**
             * Gets language code for the original (first edited) language for the site.
             *
             * @returns {string} An ISO-639-3 language code of the site's current active language .
             */
            get: getOriginalLanguage,
            /**
             * Sets language code for the original (first edited) language for the site.
             *
             * @param {LanguageDefinition} languageCode An ISO-639-3 language code to be set as the active language for the site.
             */
            set: setOriginalLanguage
        },
        /** @class documentServices.language.public */
        public: {
            /**
             * Gets the public availability status of a specific language on the site.
             *
             * @param {string} languageCode An ISO-639-3 language code of the language to query.
             * @returns {boolean} true if the language at hand is publicly available to site users, false otherwise.
             */
            get: getLanguagePublicAvailability
        },
        status: {
            /**
             * Set the status of language oneOf(ACTIVE, INACTIVE, DELETED)
             * @param {object} language
             * @param {string} status of of the statuses from description
             */
            set: setLanguageStatus
        },
        /**
         * Retrieves the list of available languages for the site
         *
         * @returns {Array.<object>}
         */
        get: getNonDeletedTranslationLanguages,
        /**
         * Retrieves the list of languages that have been added
         *
         * @returns {Array.<object>} An array of ISO-639-3 language codes.
         */
        getFull: getTranslationLanguages,
        getTranslationLanguageCodes,
        /**
         * Adds site support for a specific language
         *
         * @param {string} languageCode An ISO-639-3 language code of the language to be added to the site.
         */
        add: addTranslationLanguage,
        /**
         * Remove site support for a specific language
         *
         * @param {string} languageCode An ISO-639-3 language code of the language to be removed from the site.
         */
        remove: removeTranslationLanguage,

        init,
        copyTranslationData,
        patchPageTranslations,
        patchDataItemTranslations,
        copyDataItemTranslations,

        isCurrentLanguageSecondary,
        component: {
            getTranslations: getComponentTranslationData
        }
    }
})
