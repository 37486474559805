define([
    'experiment',
    'documentServices/wixCode/utils/constants',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/utils/errors',
    'documentServices/wixCode/services/markAppImmutable',
    'documentServices/wixCode/services/wixCodeServiceFacade',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/services/filesDAL',
    'documentServices/wixCode/utils/utils'
], function (experiment, wixCodeConstants, wixCodeClientSpecMapUtils, wixCodeErrors, markAppImmutable, wixCodeServiceFacade, kibanaWrapper, filesDAL, utils) {
    'use strict'

    const getGridAppForRevision = async ({
        wixCodeApp,
        revisionGridAppId,
        openGridAppId,
        serviceTopology,
        isFirstSave,
        metasiteId,
        isAppReadOnly,
        traceFunc,
        bi
    }) => {
        if ((!revisionGridAppId && !openGridAppId) || !wixCodeApp) {
            return null
        }

        if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
            const wixCodeServiceUrl = serviceTopology[wixCodeConstants.WIX_CODE_SERVICE_URL_KEY]

            if (isFirstSave) {
                try {
                    await markAppImmutable.runUsingSnapshot({
                        traceFunc,
                        isAppReadOnly,
                        wixCodeApp,
                        baseUrl: wixCodeServiceUrl,
                        metasiteId,
                        gridAppId: revisionGridAppId
                    })
                } catch (serverError) {
                    const error = new wixCodeErrors.WixCodeMarkImmutableError({serverError})
                    wixCodeErrors.sendBiError(bi, error)
                    throw error
                }
                return revisionGridAppId
            }

            try {
                if (experiment.isOpen('dm_wixCodeCloneIfNeeded') && !!revisionGridAppId) {
                    const {newGridAppId} = await wixCodeServiceFacade.cloneIfNeeded({
                        baseUrl: wixCodeServiceUrl,
                        openGridAppId,
                        lastRevisionGridAppId: revisionGridAppId,
                        signedInstance: wixCodeApp.instance
                    })
                    return newGridAppId
                }
                const {newGridAppId} = await wixCodeServiceFacade.cloneImmutable({
                    baseUrl: wixCodeServiceUrl,
                    gridAppId: openGridAppId,
                    signedInstance: wixCodeApp.instance
                })
                return newGridAppId
            } catch (serverError) {
                const error = new wixCodeErrors.WixCodeCloneForRevisionError({serverError})
                wixCodeErrors.sendBiError(bi, error)
                throw error
            }
        }

        return revisionGridAppId
    }

    const runUsingSnapshotDal = async (snapshotDal, bi) => {
        const wixCodeApp = wixCodeClientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshotDal)
        const openGridAppId = utils.extractFromSnapshotDal(snapshotDal, wixCodeConstants.paths.OPEN_WIX_CODE_APP_ID)
        const revisionGridAppId = utils.extractFromSnapshotDal(snapshotDal, wixCodeConstants.paths.REVISION_GRID_APP_ID)

        const isAppReadOnly = snapshotDal.getValue({
            type: 'wixCode',
            id: 'wixCode',
            innerPath: ['nonUndoable', 'isAppReadOnly']
        })
        const serviceTopology = snapshotDal.getValue({
            type: 'serviceTopology',
            id: 'serviceTopology'
        })
        const isFirstSave = snapshotDal.getValue({
            type: 'documentServicesModel',
            id: 'neverSaved'
        })
        const metasiteId = snapshotDal.getValue({
            type: 'rendererModel',
            id: 'metaSiteId'
        })
        const traceFunc = params => kibanaWrapper.traceWithSnapshot(null, params, snapshotDal)

        return getGridAppForRevision({
            wixCodeApp,
            revisionGridAppId,
            openGridAppId,
            serviceTopology,
            isFirstSave,
            metasiteId,
            isAppReadOnly,
            traceFunc,
            bi
        })
    }
    const runUsingSnapshot = async (snapshot, bi) => {
        const wixCodeApp = wixCodeClientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshot)
        const revisionGridAppId = snapshot.getIn(wixCodeConstants.paths.REVISION_GRID_APP_ID)
        const openGridAppId = snapshot.getIn(wixCodeConstants.paths.OPEN_WIX_CODE_APP_ID)
        const isAppReadOnly = snapshot.getIn(wixCodeConstants.paths.IS_APP_READ_ONLY)
        const serviceTopology = snapshot.getIn('serviceTopology')
        const metasiteId = snapshot.getIn(['rendererModel', 'metaSiteId'])
        const isFirstSave = snapshot.getIn(['documentServicesModel', 'neverSaved'])

        const traceFunc = params => kibanaWrapper.traceWithSnapshot(snapshot, params)
        return getGridAppForRevision({
            wixCodeApp,
            revisionGridAppId,
            openGridAppId,
            serviceTopology,
            isFirstSave,
            metasiteId,
            isAppReadOnly,
            traceFunc,
            bi
        })
    }

    const shouldGridAppChangeBySnapshot = (lastSnapshot, currentSnapshot) => {
        const changes = filesDAL.getChangesBetweenSnapshots(lastSnapshot, currentSnapshot)
        return !filesDAL.isChangesEmpty(changes)
    }

    const shouldGridAppChangeBySnapshotDal = (pointers, lastSnapshotDal, currentSnapshotDal) => {
        const changes = filesDAL.getChangesBetweenSnapshotDals(pointers, lastSnapshotDal, currentSnapshotDal)
        return !filesDAL.isChangesEmpty(changes)
    }

    return {
        runUsingSnapshotDal,
        runUsingSnapshot,
        shouldGridAppChangeBySnapshot,
        shouldGridAppChangeBySnapshotDal
    }
})
