import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import * as skinsPackage from '../../_internal/skins'
import wixappsCore from '@wix/santa-ds-libs/src/wixappsCore'
import blog from '@wix/santa-ds-libs/src/wixappsClassics/src/blog/blog'
import appPartZoom from '@wix/santa-ds-libs/src/wixappsClassics/src/comps/appPartZoom'
import viewCacheUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/util/viewCacheUtils'
import numberOfPostsPerPageGetter from '@wix/santa-ds-libs/src/wixappsClassics/src/util/numberOfPostsPerPageGetter'
import componentTypeUtil from '@wix/santa-ds-libs/src/wixappsClassics/src/util/componentTypeUtil'
import descriptorUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/util/descriptorUtils'
import blogSinglePostPageLogicUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/util/blogSinglePostPageLogicUtils'
import transformMediaItemsResponse from '@wix/santa-ds-libs/src/wixappsClassics/src/core/transformMediaItemsResponse'
// @ts-ignore
import skinsJson from '@wix/santa-ds-libs/src/wixappsClassics/src/skins/skins.json'
import ecommerceSantaTypes from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/ecommerceSantaTypes'
import wixappsClassicsSantaTypes from '@wix/santa-ds-libs/src/wixappsClassics/src/core/wixappsClassicsSantaTypes'
import appPartCommonDataManager from '@wix/santa-ds-libs/src/wixappsClassics/src/util/appPartCommonDataManager'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/ecommerce'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/appPartDataRequirementsChecker'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/appPartStyleCollector'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/data/converters/mediaPostConverter'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/langs/defaultPostsTranslation'
import '@wix/santa-ds-libs/src/wixappsClassics/src/util/fontUtils'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/twoLevelCategoryLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/listFromPageLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/archiveLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/singlePostPageLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/customFeedLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/core/logics/relatedPostsLogic'
// import 'messageView'
// import 'table'

const functionLibrary = wixappsCore.FunctionLibrary
// @ts-ignore
functionLibrary.prototype.addFunctions(blog.functionLibrary)

componentsCore.compRegistrar.register('wixapps.integration.components.AppPartZoom', appPartZoom)
skinsPackage.skinsMap.addBatch(skinsJson)
componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('ecommerceSantaTypes', ecommerceSantaTypes)
componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixapssClassicsSantaTypes', wixappsClassicsSantaTypes)
export default {
    appPartCommonDataManager,
    viewCacheUtils,
    numberOfPostsPerPageGetter,
    componentTypeUtil,
    descriptorUtils,
    transformMediaItemsResponse,
    blogSinglePostPageLogicUtils
}
