import _ from 'lodash'
import type {
    CreateExtensionArgument,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    Pointer,
    ValidatorResult,
    WhitelistCheckResult
} from '@wix/document-manager-core'
import type {PageAPI, PageExtensionAPI} from './page'
export type GetErrorContext = () => any
type WhitelistCheckWithContext = (
    pointer: Pointer,
    value: DalValue,
    validatorResult: ValidatorResult,
    errorContext: Record<string, any>,
    origin: string
) => WhitelistCheckResult

const includesApi = (errorContext: Record<string, any>, apis: string[]) => _.some(errorContext.SOQ?.items, ({methodName}) => apis.includes(methodName))

export const NOT_LISTED = 'NOT_LISTED'
const shouldValidateTheRefForPartialPageLoading = (pageApi: PageAPI, value: any, path: string[]) =>
    pageApi.isPartiallyLoaded() && _.includes(path, 'pageBackgrounds')

const createWhitelists = (extensionAPI: ExtensionAPI): Record<string, WhitelistCheckWithContext> => {
    return {
        existingReferencesError: (pointer, value, validatorResult, errorContext) => {
            const {referrer, deletedPointer} = validatorResult.extras!
            if (deletedPointer.id === 'MEMBERS_LOGIN_MENU' && referrer.type === 'CustomMenuDataRef') {
                /* https://jira.wixpress.com/browse/DM-5874 */
                return {issue: 'DM-5874', isWhiteListed: true}
            }

            if (errorContext.SOQ?.commitsAndSaveDisabled && validatorResult.tags?.committer === 'postUpdateOp') {
                /* https://jira.wixpress.com/browse/DM-5899 */
                return {issue: 'DM-5899', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        missingReferenceError: (pointer, value, validatorResult, errorContext, origin) => {
            const namespace = pointer.type
            const {referred, offendingData, path} = validatorResult.extras!
            const {page: pageApi} = extensionAPI as PageExtensionAPI
            if (shouldValidateTheRefForPartialPageLoading(pageApi, value, path)) {
                /*handle case in partial page loading where we load just master page but page data contains bg for non loaded page (page background in on the page)*/
                return {issue: 'DM-6005', isWhiteListed: true}
            }
            if (namespace === 'MOBILE' && referred.type === 'mobileHints') {
                /* https://jira.wixpress.com/browse/DM-5256 */
                return {issue: 'DM-5256', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['transactionRejected_afterRender'])) {
                /* https://jira.wixpress.com/browse/DM-5257 */
                return {issue: 'DM-5257', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['Undo', 'Redo'])) {
                /* https://jira.wixpress.com/browse/DM-5258 */
                return {issue: 'DM-5258', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['components.layout.update']) && origin === 'Editor1.4') {
                /* https://jira.wixpress.com/browse/DM-5259 */
                return {issue: 'DM-5259', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['components.layout.update']) && origin === 'onboarding') {
                /* https://jira.wixpress.com/browse/DM-5260 */
                return {issue: 'DM-5260', isWhiteListed: true}
            }

            if (origin === 'onboarding' && includesApi(errorContext, ['components.remove'])) {
                /* https://jira.wixpress.com/browse/DM-5261 */
                return {issue: 'DM-5261', isWhiteListed: true}
            }

            if (errorContext.SOQ?.commitsAndSaveDisabled && validatorResult.tags?.committer === 'postUpdateOp') {
                /* https://jira.wixpress.com/browse/DM-5899 */
                return {issue: 'DM-5899', isWhiteListed: true}
            }

            if (origin === 'onboarding' && namespace === 'MOBILE' && offendingData?.componentType === 'wysiwyg.viewer.components.StripColumnsContainer') {
                /* https://jira.wixpress.com/browse/DM-6139 */
                return {issue: 'DM-6139', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        DuplicateReferenceError: (pointer, value) => {
            const {type: namespace} = pointer
            if (namespace === 'MOBILE') {
                /* https://jira.wixpress.com/browse/DM-5614 */
                return {issue: 'DM-5614', isWhiteListed: true}
            }

            if (_.includes(['CustomMenu', 'BasicMenuItem'], value.type)) {
                /* https://jira.wixpress.com/browse/DM-5615 */
                return {issue: 'DM-5615', isWhiteListed: true}
            }

            if (pointer.type === 'multilingualTranslations') {
                /* https://jira.wixpress.com/browse/DM-6122 */
                return {issue: 'DM-6122', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        wrongPageRefError: (pointer, value, validatorResult, errorContext) => {
            if (value.type === 'BackgroundMedia' && validatorResult.extras!.referred?.type === 'SolidColorLayer') {
                /* https://jira.wixpress.com/browse/DM-6021 */
                return {issue: 'DM-6021', isWhiteListed: true}
            }

            if (pointer.type === 'multilingualTranslations') {
                /* https://jira.wixpress.com/browse/DM-6052 */
                return {issue: 'DM-6052', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['pages.duplicate'])) {
                /* https://jira.wixpress.com/browse/DM-6022 */
                return {issue: 'DM-6022', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        invalidComp_design: (pointer, value, validatorResult, errorContext, origin) => {
            const namespace = pointer.type
            const {offendingData} = validatorResult.extras!
            if (origin === 'onboarding' && namespace === 'MOBILE' && offendingData?.componentType === 'wysiwyg.viewer.components.StripColumnsContainer') {
                /* https://jira.wixpress.com/browse/DM-6139 */
                return {issue: 'DM-6139', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        }
    }
}

export interface ValidationWhitelistExtensionAPI extends ExtensionAPI {
    validationWhitelist: {
        registerContextProvider(name: string, contextProvider: GetErrorContext): void
    }
}

const createExtension = ({dsConfig, experimentInstance}: CreateExtensionArgument): Extension => {
    const contextProviders: Record<string, GetErrorContext> = {}
    const createExtensionAPI = (): ValidationWhitelistExtensionAPI => {
        const registerContextProvider = (name: string, contextProvider: GetErrorContext) => {
            contextProviders[name] = contextProvider
        }
        return {
            validationWhitelist: {
                registerContextProvider
            }
        }
    }

    const initialize = async ({dal, extensionAPI}: DmApis) => {
        if (experimentInstance.isOpen('dm_disableValidationWhitelist')) {
            // This experiment is for the sake of tests only
            // Tests should always fail for strict-mode violations
            return
        }
        const whitelists: Record<string, WhitelistCheckWithContext> = createWhitelists(extensionAPI)
        dal.registrar.registerValidationWhitelistCheck((pointer: Pointer, value: DalValue, validatorResult: ValidatorResult) => {
            if (whitelists[validatorResult.type]) {
                const errorContext = _.mapValues(contextProviders, fn => fn())
                return whitelists[validatorResult.type](pointer, value, validatorResult, errorContext, dsConfig.origin)
            }
            return {issue: NOT_LISTED, isWhiteListed: false}
        })
    }

    return {
        name: 'validationWhitelist',
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
