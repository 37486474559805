define([
    'lodash',
    'documentServices/editorServerFacade/editorServerFacade',
    'documentServices/utils/contextAdapter',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, editorServerFacade, contextAdapter, extensionsAPI) {
    const GET_BRANCHES_REQUEST_TIMEOUT = 1500

    const getBranchesList = async ({ps = null, immutable = null, snapshotDal = null} = {}) => {
        contextAdapter.utils.fedopsLogger.interactionStarted('LIST_BRANCHES_REQUEST', {})

        try {
            let branchesList
            if (ps) {
                branchesList = await editorServerFacade.sendWithPsAsync(ps, editorServerFacade.ENDPOINTS.LIST_BRANCHES, null)
            } else if (immutable) {
                branchesList = await editorServerFacade.sendWithImmutableAsync(immutable, editorServerFacade.ENDPOINTS.LIST_BRANCHES, null)
            } else if (snapshotDal) {
                branchesList = await editorServerFacade.sendWithSnapshotDalAsync(snapshotDal, editorServerFacade.ENDPOINTS.LIST_BRANCHES, null)
            }
            return branchesList
        } finally {
            contextAdapter.utils.fedopsLogger.interactionEnded('LIST_BRANCHES_REQUEST', {})
        }
    }

    const getBranchId = ({ps, immutable, snapshotDal}) => {
        try {
            if (ps) {
                return extensionsAPI.documentServicesModel.getBranchId(ps)
            } else if (immutable) {
                return immutable.getIn(['documentServicesModel', 'branchId'])
            } else if (snapshotDal) {
                return snapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'})
            }
        } catch (e) {
            return null
        }
    }

    const timeout = (promise, time) => Promise.race([promise, new Promise((res, rej) => setTimeout(rej, time))])

    const doesHaveBranches = async ({ps = null, immutable = null, snapshotDal = null} = {}) => {
        const branchId = getBranchId({ps, immutable, snapshotDal})

        if (_.isNil(branchId)) {
            return false
        }

        try {
            return await timeout(
                getBranchesList({ps, immutable, snapshotDal}).then(branchesList => branchesList.branches.length > 1),
                GET_BRANCHES_REQUEST_TIMEOUT
            )
        } catch (e) {
            return true
        }
    }

    return {
        doesHaveBranches
    }
})
