define(['lodash', 'documentServices/siteMetadata/siteMetadata', 'document-services-schemas'], function (_, siteMetadataAPI, documentServicesSchemas) {
    'use strict'

    function getSiteMetaData(ps) {
        return siteMetadataAPI.getProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA)
    }

    function fixSiteMetaData(ps, siteMetaData) {
        const mobileMetaData = _.omit(siteMetaData, ['preloader', 'contactInfo', 'quickActions']) || {}
        const preloader = _.clone(_.get(siteMetaData, 'preloader')) || {}
        const contactInfo = _.clone(_.get(siteMetaData, 'contactInfo')) || {}
        const quickActions = _.clone(_.get(siteMetaData, 'quickActions')) || {}
        const {dataValidators} = documentServicesSchemas.services
        dataValidators.validateItem(mobileMetaData, 'data', {schemaName: 'MobileMetaData'})
        dataValidators.validateItem(preloader, 'data', {schemaName: 'Preloader'})
        dataValidators.validateItem(contactInfo, 'data', {schemaName: 'ContactInfo'})
        dataValidators.validateItem(quickActions, 'data', {schemaName: 'QuickActions'})
        return _.assign({}, mobileMetaData, {preloader}, {contactInfo}, {quickActions})
    }

    function setSiteMetaData(ps, siteMetaData) {
        siteMetadataAPI.setProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA, fixSiteMetaData(ps, siteMetaData))
    }

    return {
        setSiteMetaData,
        getSiteMetaData,
        fixSiteMetaData
    }
})
