import type {DocumentManager, DSConfig} from '@wix/document-manager-core'
import {extensions} from '@wix/document-manager-extensions'
import type {EnvironmentContext} from './host'

export const loadExtensions = (core: DocumentManager, dsConfig: DSConfig, environmentContext: EnvironmentContext = {}) => {
    const {experimentInstance, logger} = core
    // notice: order of extensionsList affects loading order
    dsConfig.extensionsList.forEach(extensionName => {
        core.registerExtension(extensions[extensionName].createExtension({logger, experimentInstance, dsConfig, environmentContext}))
    })
}
