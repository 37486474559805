import {ReportableError} from '@wix/document-manager-utils'
import type {Experiment} from '@wix/document-services-types'
import _ from 'lodash'
import type {ServerFacadeWithAuthorization} from '../host/serverFacadeWithAuthorization'

const APP_JSON_TYPE = 'application/json'
const TYPES_WITHOUT_CONTENT = ['GET', 'DELETE']

/*TODO: complete full possible types*/
export interface FetchOptions extends RequestInit {
    data?: any
    headers?: any
    success?(): void
    error?(response: Response): void
    url?: string
    dataType?: string
    contentType?: string
    type?: string
    // use auth from client spec map
    autoAuth?: boolean
    xhrFields?: {
        withCredentials: boolean
    }
    allowErrors?: boolean
}

export async function fetchFunction(url: string, options?: RequestInit, dataType?: string, allowErrors?: boolean): Promise<Response> {
    const res = await fetch(url, options ?? undefined)
    if (res.ok || allowErrors) {
        return res[dataType ?? 'json']()
    }
    throw res
}

type AjaxFn = (options: FetchOptions) => void

interface AjaxLibrary {
    register(ajaxMethod: AjaxFn): void
    ajax(options: FetchOptions): void
}

const wrapErrorHandler = (handler: (response: Response) => void) => (response: Response) => {
    if (_.isFunction(response.text)) {
        response
            .clone()
            .text()
            .then((responseText: string) => handler(Object.assign(response, {response: responseText})))
    } else {
        handler(response)
    }
}

const ajaxMethod = (options: FetchOptions = {}, instance?: string) => {
    options.headers = options.headers ?? {}
    const contentType = options.headers['Content-Type'] || options.contentType
    if (contentType) {
        options.headers['Content-Type'] = contentType
    } else if (!options.data && !_.includes(TYPES_WITHOUT_CONTENT, options.type)) {
        options.headers['Content-Type'] = APP_JSON_TYPE
    }
    const acceptHeaders = options.headers.Accept
    if (_.includes(options.headers['Content-Type'], APP_JSON_TYPE) && !acceptHeaders) {
        options.headers.Accept = APP_JSON_TYPE
    }
    if (_.get(options, ['xhrFields', 'withCredentials'])) {
        options.credentials = 'include'
    }
    if (options.headers.Authorization && options.autoAuth) {
        throw new ReportableError({errorType: 'autoAuth', message: 'Both autoAuth and Authorization supplied to ajax call'})
    }
    if (options.autoAuth && !options.headers.Authorization && instance) {
        options.headers.Authorization = instance
    }
    const supportedOptions = _.pick(options, ['cache', 'credentials', 'headers', 'integrity', 'keepalive', 'mode', 'redirect', 'referrer', 'referrerPolicy'])
    return fetchFunction(options.url!, {method: options.type, body: options.data, ...supportedOptions}, options.dataType!)
        .then(options.success)
        .catch(wrapErrorHandler(options.error ?? _.noop))
}

const wrapAjaxMethod = (serverFacade: ServerFacadeWithAuthorization, experimentInstance: Experiment, isReadOnly: boolean = false) => {
    return (options: FetchOptions) => {
        if (isReadOnly && options.type !== 'GET') {
            throw new Error("this operation isn't allowed while in read only mode")
        }
        if (experimentInstance.isOpen('dm_ajaxFetchInstance')) {
            return serverFacade
                .refreshInstanceIfExpired()
                .then(auth => ajaxMethod(options, auth.instance))
                .catch(options.error ?? _.noop)
        }
        return ajaxMethod(options)
    }
}

export const registerAjaxMethod = (
    ajaxLibrary: AjaxLibrary,
    serverFacade: ServerFacadeWithAuthorization,
    experimentInstance: Experiment,
    isReadOnly?: boolean
) => {
    ajaxLibrary.register(wrapAjaxMethod(serverFacade, experimentInstance, isReadOnly))
}
