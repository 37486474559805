import santaComponents from '@wix/santa-components'

export default {
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
    },
    getOrigComponentId() {
        return this.props.compData.origCompId
    }
}
