import {CreateExtArgs, DAL, DmApis, Extension, ExtensionAPI, PointerMethods, pointerUtils} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../types'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {CHILDREN_PROPERTY_NAMES, DM_POINTER_TYPES, VIEW_MODES} from '../constants/constants'
import {findCompBFS} from '../utils/findCompBFS'
import {pageGetterFromDisplayed} from '../utils/pageUtils'
import {getRefPointerType, isRefHost, isRefPointer} from '../utils/refStructureUtils'
import {
    getRepeaterItemId,
    getUniqueDisplayedId,
    getUniqueStructure,
    isRepeatedComponent,
    isRepeater,
    isRepeaterMasterItem,
    updateRepeaterMasterItem
} from '../utils/repeaterUtils'
import {getChildrenRecursivelyRightLeftRoot} from '../utils/structureUtils'
import {getIdFromRef} from '../utils/dataUtils'

const {getInnerPointer, getInnerValue, getPointer, getRepeatedItemPointerIfNeeded, normalizeInnerPath, stripInnerPath} = pointerUtils

const structureTypes = _.values(VIEW_MODES)
const isModeActive = (modeId: string, allActiveModeIds: Record<string, any>) => !!allActiveModeIds[modeId]
const applyOverride = (value: any, override: any) => {
    const cleanOverride = _.omit(override, ['modeIds', 'overrides', 'definitions'])
    return _.defaults(cleanOverride, value)
}

/**
 * @param {ViewerManager} viewerManager
 * @returns {Extension}
 */
const createExtension = ({viewerManager, experimentInstance}: CreateViewerExtensionArgument): Extension => {
    const applyModes = (current: any) => {
        if (!_.has(current, ['modes'])) {
            return current
        }

        let valueWithOverrides = applyOverride(current, current.modes)

        if (_.has(current, ['modes', 'overrides'])) {
            const allActiveModeIds = viewerManager.viewerSiteAPI.getAllActiveModeIds()
            const matchingOverrides = _.filter(
                current.modes.overrides,
                override => !_.some(override.modeIds, modeId => !isModeActive(modeId, allActiveModeIds))
            )
            _.forEach(matchingOverrides, override => {
                valueWithOverrides = applyOverride(valueWithOverrides, override)
            })
        }

        if (valueWithOverrides.isHiddenByModes) {
            return undefined
        }

        return _.omit(valueWithOverrides, ['isHiddenByModes'])
    }

    const getRepeaterStructure = ({get}: Pick<DAL, 'get'>, current: any) => {
        const templateId: string = _.head(current.components) as string

        const repeaterDataQuery = getIdFromRef(current.dataQuery)
        const repeaterDataPointer = getPointer(repeaterDataQuery, 'data')
        const repeaterData = get(repeaterDataPointer)

        const newChildren = repeaterData ? _.map(repeaterData.items, itemId => getUniqueDisplayedId(templateId, itemId)) : []
        return _.defaults({components: newChildren}, current)
    }

    const getRepeaterStructureFromViewerManager = (current: any, pointer: Pointer) => {
        const repeaterStructure = viewerManager.dal.get(pointer)
        if (repeaterStructure) {
            return _.defaults({components: repeaterStructure.components}, current)
        }
        return current
    }

    const getRepeatedItemStructure = ({get}: Pick<DAL, 'get'>, pointer: Pointer) => {
        const itemId = getRepeaterItemId(pointer.id)
        const templatePointer = getRepeatedItemPointerIfNeeded(pointer)
        const templateStructure = get(templatePointer)
        if (!templateStructure) {
            return undefined
        }
        const inflatedRepeatedStructure = _.assign({}, templateStructure, getUniqueStructure(templateStructure, itemId))
        const parentPointer = getPointer(templateStructure.parent, pointer.type)
        const shouldPreserveParentId = parentPointer && isRepeater({get}, parentPointer)
        const compMeasures = viewerManager.viewerSiteAPI.getBasicMeasureForComp(pointer.id)
        return _.defaultsDeep(
            {layout: compMeasures, parent: shouldPreserveParentId ? templateStructure.parent : inflatedRepeatedStructure.parent},
            inflatedRepeatedStructure
        )
    }

    const applyRepeatedStructureIfNeeded = (dal: DAL, current: any, pointer: Pointer) => {
        const {get} = dal
        const isRepeaterComp = isRepeater({get}, pointer)
        const isRepeatedComp = isRepeatedComponent(pointer.id)

        const isWidgetInRepeaterOpen = experimentInstance.isOpen('dm_widgetInRepeater')

        if (!isRepeaterComp && !isRepeatedComp) {
            return current
        }

        const templatePointer = getRepeatedItemPointerIfNeeded(pointer)
        const isByRef = isRefPointer(pointer) || isRefHost(templatePointer, dal)

        if (isByRef && isWidgetInRepeaterOpen) {
            if (viewerManager.dal.isExist(pointer)) {
                const compFromViewer = viewerManager.dal.get(pointer)
                const compMeasures = viewerManager.viewerSiteAPI.getBasicMeasureForComp(pointer.id)
                return _.defaultsDeep({layout: compMeasures}, compFromViewer)
            }
            return current
        }

        if (isRepeaterComp) {
            if (viewerManager.dal.isExist(pointer)) {
                return getRepeaterStructureFromViewerManager(current, pointer)
            }
            if (current) {
                return getRepeaterStructure({get}, current)
            }
        }
        if (isRepeatedComp) {
            return getRepeatedItemStructure({get}, pointer)
        }
        return current
    }

    const getDisplayedPage = (dal: DAL, pointer: Pointer) => {
        const page = pageGetterFromDisplayed(dal, pointer)
        return getInnerValue(pointer, page)
    }

    const getFromDisplayedStructure = (dal: DAL, pointer: Pointer) => {
        const strippedPointer = stripInnerPath(pointer)
        const value = dal.get(strippedPointer)
        const valueAfterModes = applyModes(value)
        const valueAfterRepeatedStructure = applyRepeatedStructureIfNeeded(dal, valueAfterModes, strippedPointer)
        return getInnerValue(pointer, valueAfterRepeatedStructure)
    }

    const getFromDisplayed = (dal: DAL, pointer: Pointer) => {
        if (pointer.type === DM_POINTER_TYPES.pageDM) {
            return getDisplayedPage(dal, pointer)
        }

        if (structureTypes.includes(pointer.type)) {
            return getFromDisplayedStructure(dal, pointer)
        }

        return dal.get(pointer)
    }

    /**
     * Returns an index to an override that matches current active modes, -1 otherwise
     *
     * I know the code is old school, doing something similar to the below line. The reason is that this is a very high usage function,
     * so performance is the top priority
     * ALTERNATIVE _.filter(current.modes.overrides, override => !_.some(override.modeIds, modeId => !isModeActive(modeId, allActiveModeIds)))
     *
     * @param current
     * @param allActiveModeIds
     * @returns {number}
     */
    const getMatchingOverrideIndex = (current: any, allActiveModeIds: Record<string, any>) => {
        const {overrides} = current.modes
        for (let i = 0; i < overrides.length; i++) {
            let allMatch = true
            for (const modeId of overrides[i].modeIds) {
                if (!isModeActive(modeId, allActiveModeIds)) {
                    allMatch = false
                    break
                }
            }

            if (allMatch) {
                return i
            }
        }

        return -1
    }

    const getOverriddenPath = (current: any, path: any) => {
        if (!_.has(current, ['modes', 'overrides'])) {
            return path
        }

        const allActiveModeIds = viewerManager.viewerSiteAPI.getAllActiveModeIds()
        const matchingOverrideIndex = getMatchingOverrideIndex(current, allActiveModeIds)

        if (matchingOverrideIndex === -1) {
            return path
        }

        const overridePath = ['modes', 'overrides', matchingOverrideIndex].concat(path)
        if (!_.get(current, overridePath)) {
            // There is no override for this value, not adding a new one
            return path
        }
        return overridePath
    }

    /**
     * @param {{dal:DAL, pointers}} o
     * @returns {{displayed: {setDisplayedValue: (function(*, *): void), getDisplayedValue: (function(*): *)}}}
     */
    const createExtensionAPI = ({dal, pointers, coreConfig: {logger}}: CreateExtArgs): FTDExtApi => {
        const setToDisplayedStructure = (pointer: Pointer, value: any) => {
            const {get, set} = dal
            const innerPath = normalizeInnerPath(pointer.innerPath)
            const pointerToUpdate = getRepeatedItemPointerIfNeeded(pointer)

            if (!innerPath.length) {
                set(pointerToUpdate, value)
            }

            const isRepeatedLayoutChange = isRepeatedComponent(pointer.id) && innerPath.includes('layout') && !isRepeaterMasterItem({dal, pointers}, pointer)
            const isRepeatedChildrenChange = isRepeatedComponent(pointer.id) && _.intersection(innerPath, CHILDREN_PROPERTY_NAMES).length > 0
            if (isRepeatedLayoutChange || isRepeatedChildrenChange) {
                updateRepeaterMasterItem({pointers, dal, viewerManager, logger}, pointer)
            }

            const basePointer = stripInnerPath(pointerToUpdate)
            const current = get(basePointer)
            const overriddenPath = getOverriddenPath(current, innerPath)

            let updatedValue = current
            if (typeof updatedValue !== 'undefined') {
                updatedValue = _.setWith(deepClone(current), overriddenPath, value, Object)
            }
            set(basePointer, updatedValue)
        }

        /**
         *
         * @param {{dal:DAL, pointers:Pointers}} p
         * @param {Pointer} pointer
         * @param value
         */
        const setToDisplayed = (pointer: Pointer, value: any) => {
            if (structureTypes.includes(pointer.type)) {
                return setToDisplayedStructure(pointer, value)
            }

            return dal.set(pointer, value)
        }

        return {
            displayed: {
                getDisplayedValue: pointer => getFromDisplayed(dal, pointer),
                setDisplayedValue: setToDisplayed
            }
        }
    }

    /**
     * @param structurePointers
     * @param {DAL} dal
     * @returns {*}
     */
    const getComponentPointersFromDisplay = (structurePointers: any, dal: DAL) => {
        const getSlottedChildren = (pointer: Pointer, type: string): Pointer[] => {
            if (experimentInstance.isOpen('dm_getSlottedCompsAtXY')) {
                const slotsQuery = getFromDisplayed(dal, getInnerPointer(pointer, ['slotsQuery']))

                if (slotsQuery) {
                    const {slots}: Record<string, string> = getFromDisplayed(dal, getPointer(slotsQuery, 'slots'))
                    return Object.values(slots).map(id => getPointer(id, type))
                }
            }
            return []
        }

        const getChildren = (originalPointer: Pointer): Pointer[] => {
            if (!originalPointer) {
                return []
            }

            const pointer = isRefHost(originalPointer, dal) ? getRefPointerType(originalPointer) : originalPointer
            const childrenIds: string[] = getFromDisplayed(dal, getInnerPointer(pointer, ['components']))

            const childPointers = _.map(childrenIds, id => getPointer(id, originalPointer.type))
            const slottedChildren = getSlottedChildren(pointer, originalPointer.type)

            return _.filter(_.unionBy([...childPointers, ...slottedChildren], 'id'), childPointer => !!getFromDisplayed(dal, childPointer))
        }

        const getChildrenRecursively = (pointer: Pointer): Pointer[] => {
            const children = getChildren(pointer)
            return children.concat(_.flatMap(children, getChildrenRecursively))
        }

        const getComponent = (id: string, pagePointer: Pointer) => getPointer(id, pagePointer.type)

        const getChildrenRecursivelyRightLeftRootIncludingRoot = (pointer: Pointer) => getChildrenRecursivelyRightLeftRoot(getChildren, pointer)

        const getParent = (pointer: Pointer) => {
            if (!pointer) {
                return null
            }

            const parentId = getFromDisplayed(dal, getInnerPointer(pointer, ['parent']))
            return _.isUndefined(parentId) ? null : getPointer(parentId, pointer.type)
        }

        const findDescendant = (componentPointer: Pointer, predicate: any) => {
            const comp = findCompBFS(componentPointer, getChildren, compPointer => predicate(getFromDisplayed(dal, compPointer)))
            return comp ? getComponent(comp.id, componentPointer) : null
        }

        const findComponentInPage = (pagePointer: Pointer, isMobileView: boolean, predicate: any) => findDescendant(pagePointer, predicate)

        const displayPointers = {
            getParent,
            getChildren,
            getComponent,
            getChildrenRecursively,
            getChildrenRecursivelyRightLeftRootIncludingRoot,
            findComponentInPage,
            findDescendant
        }

        return _.assign({}, structurePointers, displayPointers)
    }

    const createPointersMethods = ({pointers, dal}: DmApis): PointerMethods => ({
        components: getComponentPointersFromDisplay(pointers.structure, dal),
        full: {
            components: pointers.structure
        }
    })

    return {
        name: 'fullToDisplay',
        createExtensionAPI,
        createPointersMethods
    }
}

export interface FTDExtApi extends ExtensionAPI {
    displayed: {
        getDisplayedValue(pointer: Pointer): any
        setDisplayedValue(pointer: Pointer, value: any): void
    }
}

export {createExtension}
