import _ from 'lodash'
import type {HistoryItem, Pointer} from '../types'
import {getPointer} from '../utils/pointerUtils'
import {deepClone} from '@wix/wix-immutable-proxy'

export type DalValue = any //object | object[] | string | number /*| Record<any, any>*/

export interface DalItemMetaData {
    pageId?: string
    isHidden?: boolean
    isPreset?: boolean
    schemaVersion?: string
    sig?: string
    basedOnSignature?: string
}

export type DalItem = Record<string, any> & {
    type?: string
    id?: string
    metaData?: DalItemMetaData
}

export type DalStore = Map<string, DalNamespace>
export type DalNamespace = Map<string, DalValue>

const cloneMap = (map: Map<string, any>) => {
    const clone = new Map()
    map.forEach((val, key) => {
        clone.set(key, val instanceof Map ? cloneMap(val) : deepClone(val))
    })
    return clone
}

type ValueTransformer = (value: DalValue) => DalValue

const mapToJson = (map: Map<string, any>, valueTransformer: ValueTransformer) => {
    const json = {}
    map.forEach((val, key) => {
        json[key] = val instanceof Map ? mapToJson(val, valueTransformer) : valueTransformer(val)
    })
    return json
}

const jsToDalStore = (js: DalJsStore): DalStore => {
    const dalStore = new Map<string, DalNamespace>()
    Object.entries(js).forEach(([typeName, typeData]) => {
        dalStore.set(typeName, new Map(Object.entries(typeData as any)))
    })
    return dalStore
}

// serialized store
export type DalJsStore = Record<string, DalJsNamespace>
export type DalJsNamespace = Record<string, DalItem | undefined>

class DmStore {
    readonly _store: DalStore

    constructor(initialState?: DalStore) {
        this._store = initialState ?? new Map()
    }

    static createFromJS(initialState: DalJsStore): DmStore {
        return new DmStore(jsToDalStore(initialState))
    }

    get(pointer: Pointer): DalValue {
        const typeStore = this._store.get(pointer.type)
        return typeStore?.get(pointer.id)
    }

    has(pointer: Pointer): boolean {
        const typeStore = this._store.get(pointer.type)
        return typeStore ? typeStore.has(pointer.id) : false
    }

    /**
     /** return true if the pointer was removed from the store
     * @param {Pointer} pointer
     * @returns {boolean}
     */
    isRemoved(pointer: Pointer): boolean {
        return this.has(pointer) && _.isUndefined(this.get(pointer))
    }

    /**
     * @param {Pointer} pointer
     * @param {DalValue} value - value to set
     */
    set(pointer: Pointer, value: DalValue): void {
        const {type, id} = pointer
        let valuesMap = this._store.get(type)
        if (!valuesMap) {
            valuesMap = new Map()
            this._store.set(type, valuesMap)
        }
        valuesMap.set(id, value)
    }

    /**
     * Marks the pointer value as deleted by setting the pointer value to undefined
     * @param pointer
     */
    remove(pointer: Pointer): void {
        this.set(pointer, undefined)
    }

    /**
     * Deletes the pointer key. If there are no longer any ids for the pointer namespace, the namespace is also deleted
     * @param pointer
     */
    deleteKey(pointer: Pointer): void {
        const namespace = this._store.get(pointer.type)
        namespace?.delete(pointer.id)
        if (namespace?.size === 0) {
            this._store.delete(pointer.type)
        }
    }

    /**
     * Returns true if the predicate returns true for any pointer-value in the store
     * @param predicate
     */
    some(predicate: (pointer: Pointer, value: any) => boolean) {
        for (const [type, valuesMap] of this._store) {
            for (const [id, value] of valuesMap) {
                if (predicate({type, id}, value)) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * an array of history items with where key is the pointer and value is the actual set value
     */
    getValues(): HistoryItem[] {
        const values: HistoryItem[] = []
        for (const [type, dataMap] of this._store.entries()) {
            for (const [id, value] of dataMap.entries()) {
                values.push({key: {id, type}, value})
            }
        }
        return values
    }

    keys(): Pointer[] {
        const keys: Pointer[] = []
        for (const [type, dataMap] of this._store.entries()) {
            for (const id of dataMap.keys()) {
                keys.push(getPointer(id, type))
            }
        }
        return keys
    }

    /**
     * execute callback over all store items
     */
    forEach(callback: (pointer: Pointer, value: DalValue) => void): void {
        this._store.forEach((valuesMap, type) => {
            valuesMap.forEach((value, id) => {
                callback(getPointer(id, type), value)
            })
        })
    }

    /**
     * Creates and returns a new store containing all the key-values for which the predicate returns true
     * @param predicate
     */
    filter(predicate: (pointer: Pointer, value: DalValue) => boolean): DmStore {
        const newStore: DmStore = new DmStore()
        this.forEach((pointer: Pointer, value: DalValue) => {
            if (predicate(pointer, value)) {
                newStore.set(pointer, value)
            }
        })
        return newStore
    }

    /**
     * true if there are no stored values
     * @return {boolean}
     */
    isEmpty(): boolean {
        return _.isEmpty(this._store)
    }

    _applyValuesMap(valuesMap: DalNamespace, typeValues: Record<string, any>): void {
        valuesMap.forEach((value: any, id: string) => {
            if (value === undefined) {
                delete typeValues[id]
            } else {
                typeValues[id] = value
            }
        })
    }

    /** Copies the values for the specified type to the given object, deleting undefined values and cloning defined values */
    applyTypeValuesWithDelete(type: string, typeValues: Record<string, any>): void {
        const valuesMap = this._store.get(type)
        if (valuesMap) {
            this._applyValuesMap(valuesMap, typeValues)
        }
    }

    /**
     * Copies the entire store to the given object, deleting undefined values and cloning defined values
     * @param {*} js
     */
    applyValuesWithDelete(js: DalJsStore): void {
        this._store.forEach((valuesMap, type) => {
            let typeValues = js[type]
            if (!typeValues) {
                typeValues = {}
                js[type] = typeValues
            }
            this._applyValuesMap(valuesMap, typeValues)
        })
    }

    /**
     * Copies the entire store to the given object as is
     * @param js
     */
    applyValues(js: any): void {
        this._store.forEach((valuesMap, type) => {
            let typeValues = js[type]
            if (!typeValues) {
                typeValues = {}
                js[type] = typeValues
            }
            valuesMap.forEach((value: any, id: string) => {
                typeValues[id] = value
            })
        })
    }

    /**
     * Merge the given store into this store.
     * @param store
     */
    merge(store: DmStore): void {
        store._store.forEach((namespaceValues, namespace) => {
            const thisNamespaceValues = this._store.get(namespace)
            if (!thisNamespaceValues) {
                this._store.set(namespace, new Map(namespaceValues))
            } else {
                namespaceValues.forEach((value: any, id: string) => {
                    thisNamespaceValues.set(id, value)
                })
            }
        })
    }

    /**
     * Returns a deep clone of the specified namespace in json format
     * @param namespace
     */
    cloneNamespaceAsJson(namespace: string): any {
        const m: DalNamespace | undefined = this._store.get(namespace)
        return m instanceof Map ? mapToJson(m, deepClone) : m
    }

    /**
     * Returns a shallow clone of the specified namespace in json format
     * @param namespace
     */
    namespaceAsJson(namespace: string): any {
        const m: DalNamespace | undefined = this._store.get(namespace)
        return m instanceof Map ? mapToJson(m, _.identity) : m
    }

    /**
     * Returns a shallow clone of this store in json format
     */
    asJson(): DalJsStore {
        return mapToJson(this._store, _.identity)
    }

    /**
     * Returns a deep clone of this store in json format
     */
    cloneAsJson(): DalJsStore {
        return mapToJson(this._store, deepClone)
    }

    /**
     * Returns a deep clone of the store
     */
    clone(): DmStore {
        return new DmStore(cloneMap(this._store))
    }
}

function createStore(): DmStore {
    return new DmStore()
}

function createStoreFromJS(initialState: DalJsStore): DmStore {
    return DmStore.createFromJS(initialState)
}

export type {DmStore}

export {createStore, createStoreFromJS}
