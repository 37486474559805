import _ from 'lodash'
import responsiveConfig from './responsiveConfig'

const conf = _.cloneDeep(responsiveConfig)
conf.extensionsList = _.without(conf.extensionsList, 'continuousSave', 'continuousSaveEvents')

export default {
    ...conf,
    initialSeed: 1000
}
