define([
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/services/wixCodeServiceFacade',
    'documentServices/wixCode/utils/fetchResponseErrorObject',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/saveAPI/lib/saveRunner',
    'documentServices/wixCode/services/markAppImmutable',
    'documentServices/hooks/hooks',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment'
], function (
    clientSpecMapUtils,
    kibana,
    wixCodeServiceFacade,
    fetchResponseErrorObject,
    generalInfo,
    saveRunner,
    markAppImmutable,
    hooks,
    extensionsAPI,
    experiment
) {
    'use strict'

    function _updateRevisionGridApp(ps, gridAppId) {
        ps.dal.set(ps.pointers.wixCode.getRevisionGridAppId(), gridAppId)
        hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION)
        hooks.executeHook(hooks.HOOKS.WIXCODE.UPDATE_MODEL, null, [])
    }

    function _getMetaSiteIdFromPS(ps) {
        const pointer = ps.pointers.general.getMetaSiteId()
        return ps.dal.get(pointer)
    }

    function _getIsFirstSaveFromPS(ps) {
        return generalInfo.isFirstSave(ps)
    }

    async function _doClone(ps) {
        const options = {
            baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
            gridAppId: extensionsAPI.wixCode.getRevisionGridAppId(ps),
            metasiteId: _getMetaSiteIdFromPS(ps),
            signedInstance: clientSpecMapUtils.getExistingWixCodeAppFromPS(ps).instance,
            isFirstSave: _getIsFirstSaveFromPS(ps)
        }
        const cloneResponse = await wixCodeServiceFacade.clone(options)
        _updateRevisionGridApp(ps, cloneResponse.gridAppId)
        setAppWriteable(ps)
        //in santa tests there is no ps.dal.commitTransaction, this can be removed after
        if (ps.dal.commitTransaction) {
            ps.dal.commitTransaction('wix-code_writableAppService')
        }
        return cloneResponse
    }

    async function _cloneAppWithRetries(ps, retriesLeft) {
        const traceEnd = kibana.trace(ps, {action: 'cloneApp'})

        function isCloneUnsavedAppError(error) {
            const errorCode = fetchResponseErrorObject.safeGetErrorCode(error)
            return errorCode === -409 || errorCode === -409000 || errorCode === -409001
        }

        async function onCloneError(error) {
            if (isCloneUnsavedAppError(error)) {
                setAppWriteable(ps)
                traceEnd({level: kibana.levels.WARN, message: error})
                await markAppImmutable.runUsingPS(ps)
                setAppReadOnly(ps)
                return _cloneAppWithRetries(ps, retriesLeft)
            }

            if (retriesLeft === 0) {
                traceEnd({level: kibana.levels.ERROR, message: error})
                throw error
            }

            traceEnd({level: kibana.levels.WARN, message: error, params: {retriesLeft}})
            return _cloneAppWithRetries(ps, retriesLeft - 1)
        }

        try {
            const serverResponse = await _doClone(ps)
            traceEnd({appId: extensionsAPI.wixCode.getRevisionGridAppId(ps), message: serverResponse})
        } catch (e) {
            await onCloneError(e)
        }
    }

    async function _cloneApp(ps) {
        if (_isAppWriteable(ps)) {
            return
        }
        const NUM_OF_RETRIES = 2
        return _cloneAppWithRetries(ps, NUM_OF_RETRIES)
    }

    function _isAppWriteable(ps) {
        const pointer = ps.pointers.wixCode.getIsAppReadOnly()
        return !ps.dal.get(pointer)
    }

    function setAppWriteable(ps) {
        const pointer = ps.pointers.wixCode.getIsAppReadOnly()
        ps.dal.set(pointer, false)
    }

    function setAppReadOnly(ps) {
        const pointer = ps.pointers.wixCode.getIsAppReadOnly()
        ps.dal.set(pointer, true)
    }

    function handleAppIsReadOnlyServerError(ps) {
        // If the app is read only on the server although,
        // we think it's not, reset the read only flag
        const pointer = ps.pointers.wixCode.getIsAppReadOnly()
        ps.dal.set(pointer, true)

        return ensureAppIsWriteable(ps)
    }

    function ensureAppIsWriteable(ps) {
        if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled') && !generalInfo.isFirstSave(ps)) {
            return Promise.resolve()
        }
        return saveRunner.runFunctionInSaveQueue(() => _cloneApp(ps))
    }

    return {
        ensureAppIsWriteable,
        setAppWriteable,
        handleAppIsReadOnlyServerError
    }
})
