define(['@wix/santa-core-utils'], function (santaCoreUtils) {
    'use strict'

    /**
     * @param {ps} ps
     * @returns {Pointer}
     */
    function getAccessibilitySettingsDataPointer(ps) {
        const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
        return ps.pointers.getInnerPointer(masterPageDataPointer, 'accessibilitySettings')
    }

    return {
        getAccessibilitySettingsDataPointer
    }
})
