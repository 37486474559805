define([
    'lodash',
    'documentServices/component/componentModes',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/modes/modesUtils',
    'documentServices/component/component'
], function (_, componentModes, componentDetectorAPI, modesUtils, component) {
    'use strict'

    const SITE_REGION_CONTAINER_TYPE = 'wysiwyg.viewer.components.SiteRegionContainer'

    function isModeIdInCompOverrides(compOverrides, modeId) {
        return _(compOverrides).flatMap('modeIds').includes(modeId)
    }

    function removeOverridesPointingToMode(ps, masterPagePointer, modeId) {
        const activeModes = componentModes.getComponentActiveModeIds(ps, masterPagePointer)
        const shouldActivateMode = !_.includes(activeModes, modeId)
        if (shouldActivateMode) {
            componentModes.activateComponentMode(ps, masterPagePointer, modeId)
        }
        const allSiteRegionContainers = componentDetectorAPI.getComponentByType(ps, SITE_REGION_CONTAINER_TYPE, masterPagePointer)
        const allSOSPModes = modesUtils.getSOSPModes(ps)
        const allSOSPModesId = _.map(allSOSPModes, 'modeId')
        _.forEach(allSiteRegionContainers, siteRegionContainer => {
            const compOverrides = componentModes.overrides.getAllOverrides(ps, siteRegionContainer)

            if (!isModeIdInCompOverrides(compOverrides, modeId)) {
                return
            }

            const newOverrides = _.reduce(
                compOverrides,
                (res, compOverride) => {
                    const {modeIds} = compOverride
                    const newOverride = _.cloneDeep(compOverride)
                    newOverride.modeIds = _.without(modeIds, modeId)
                    if (!_.isEmpty(newOverride.modeIds)) {
                        res.push(newOverride)
                    }
                    return res
                },
                []
            )
            const newOverrideIds = _.flatMap(newOverrides, 'modeIds')
            if (_.isEmpty(newOverrides) || _.isEmpty(_.intersection(newOverrideIds, allSOSPModesId))) {
                component.remove(ps, siteRegionContainer)
            } else {
                if (shouldActivateMode) {
                    componentModes.deactivateCompMode(ps, masterPagePointer, modeId)
                }
                componentModes.overrides.updateComponentOverrides(ps, siteRegionContainer, newOverrides)
            }
        })
    }

    return {
        removeOverridesPointingToMode
    }
})
