/* eslint-disable prettier/prettier */
import {
    CoreLogger,
    DocumentManager,
    DSConfig,
    store as dmStore,
    Pointer,
    DalValue,
    deepCompare,
    deepCompare4,
    deepCompareDebug,
    deepCompareDebug2
} from '@wix/document-manager-core'
import {constants, extensions, FetchFn, siteDataImmutableFromSnapshot} from '@wix/document-manager-extensions'
import type {CSaveApi} from '@wix/document-manager-extensions/src/extensions/csave/continuousSave'
import type {DataFixerVersioningApi} from '@wix/document-manager-extensions/src/extensions/dataFixerVersioning/dataFixerVersioning'
import type {ServiceTopology} from '@wix/document-manager-extensions/src/extensions/serviceTopology'
import type {SnapshotExtApi} from '@wix/document-manager-extensions/src/extensions/snapshots'
import type {ViewsExtensionAPI, ViewsAPI} from '@wix/document-manager-extensions/src/extensions/views/views'
import type {DataFixer, Experiment, PageList} from '@wix/document-services-types'
import _ from 'lodash'
import {INTERACTIONS} from '../constants/constants'
import {DataMigrationRunner, FixerCategory} from '../dataMigration/dataMigrationRunner'
import * as commonConfigInitializer from './commonConfigInitializer'
import {fetchPages} from './fetchPages'
import * as modelsInitializer from './modelsInitializer'
import type {DocumentServicesModel, DocumentServicesModelForServer, RendererModel, RendererModelForServer} from './modelTypes'
import multilingualInitializer from './multilingualInitializer'
import {movePageDataToMaster} from './pageDataMigrator'
import pagesPlatformApplicationsInitializer from './pagesPlatformApplicationsInitializer'
import {addStoreToDal, convertStore, getRemovalCandidates, mergeDeletionsToDalApprovedStore, mergeToDalApprovedStore, removeDataFromDal} from './storeToDal'
import {ReportableError} from '@wix/document-manager-utils'

const {SNAPSHOTS} = constants
const {getSiteDataJson} = siteDataImmutableFromSnapshot
const {createStore} = dmStore

const isExperimentOpen = (value: string) => !!(value && value !== 'old' && value !== 'false')

const getDataFixersParams = (store: ServerStore) => {
    const {rendererModel} = store
    const pageIdsArray = _(store.pagesData.masterPage.data.document_data)
        .filter(
            data => (data?.type === 'Page' || data?.type === 'AppPage') && data?.id !== 'SITE_STRUCTURE'
        )
        .map('id')
        .value()
    const runningExperiments = rendererModel.runningExperiments || {}
    const {urlFormatModel} = rendererModel
    const {quickActionsMenuEnabled} = _.get(rendererModel, ['siteMetaData', 'quickActions', 'configuration'], {quickActionsMenuEnabled: false})
    const experiments = _(runningExperiments)
        .pickBy(val => isExperimentOpen(val))
        .keys()
        .value()

    return {
        clientSpecMap: rendererModel.clientSpecMap,
        urlFormatModel,
        quickActionsMenuEnabled,
        isViewerMode: !rendererModel.previewMode,
        experiments,
        pageIdsArray
    }
}

export interface InitParams {
    documentManager: DocumentManager
    dataFixer: DataFixer
    partialPages: string[]
    dataMigrationRunner: DataMigrationRunner
    rendererModel: RendererModelForServer | RendererModel
    serviceTopology: ServiceTopology
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    config: DSConfig
    logger: CoreLogger
    fetchFn: FetchFn
    trackingFn<T>(...args: any[]): Promise<T>
    // Injectable for testing, no need to pass these params in production code:
    fetchPagesFunction?(fetchFn: any, pageList:  any, concurrency?: number): Promise<Record<string, any>>
    experimentInstance: Experiment
}

export interface InitConfig {
    documentManager: DocumentManager
    rendererModel: RendererModelForServer | RendererModel
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    serviceTopology: ServiceTopology
}

export interface ServerStore {
    rendererModel: RendererModelForServer | RendererModel
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    serviceTopology: ServiceTopology
    pagesData: Record<string, any>
    orphanPermanentDataNodes?: any[]
    routers?: any
    pagesPlatformApplications?: any
    origin?: string
}

const stubTrackingFn = async (name: string, fn: Function) => await fn()

const initialize = async ({
    documentManager,
    dataFixer,
    partialPages,
    dataMigrationRunner,
    serviceTopology,
    rendererModel,
    documentServicesModel,
    config,
    logger,
    fetchFn,
    fetchPagesFunction = fetchPages,
    trackingFn = stubTrackingFn
}: InitParams): Promise<{store: ServerStore}> => {
    const options = {
        paramsOverrides: {
            isDraft: _.get(documentServicesModel, ['isDraft'])
        }
    }
    const interactionStartedWithOptions = (name: string) => logger.interactionStarted(name, options)
    const interactionEndedWithOptions = (name: string) => logger.interactionEnded(name, options)
    async function trackingAndInteraction<T>(name: string, fn: (...args: any[]) => Promise<T>): Promise<T> {
        interactionStartedWithOptions(name)
        const returnValue: T = await trackingFn(name, async () => await fn())
        interactionEndedWithOptions(name)

        return returnValue
    }

    const initializeModels = async (store: ServerStore, disableCommonConfig = false) => {
        const initConfig: InitConfig = {
            documentManager,
            rendererModel: store.rendererModel,
            documentServicesModel: store.documentServicesModel,
            serviceTopology: store.serviceTopology
        }

        const initialModelsState = createStore()
        const initializers = disableCommonConfig
            ? [modelsInitializer, pagesPlatformApplicationsInitializer]
            : [modelsInitializer, pagesPlatformApplicationsInitializer, commonConfigInitializer]

        const promises = initializers.map(init => init.initialize(initConfig, initialModelsState))

        await Promise.all(promises)
        documentManager.dal.mergeToApprovedStore(initialModelsState, 'model-initializer')
    }

    const minorFixing = (pageJson: any, pageId: string) => {
        if (!pageJson.structure.id && pageId === 'masterPage') {
            pageJson.structure.id = 'masterPage'
        }
        return pageJson
    }

    const applyAndUpdateToApprovedStore = (store: ServerStore, op: (store: ServerStore) => ServerStore, label: string): ServerStore => {
        const removalCandidates = getRemovalCandidates(documentManager, store)
        const newStore = op(store)
        mergeToDalApprovedStore(documentManager, newStore, removalCandidates, `${label}-update`)
        mergeDeletionsToDalApprovedStore(documentManager, removalCandidates, `${label}-remove`)
        return newStore
    }

    const updateApprovedStoreBeforeCSaveAndVerifyNoChanges = (serverStore: ServerStore) => {
        const changes: object[] = []

        const verifyUnchanged = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => {
            if (!deepCompare(oldValue, newValue)) {
                changes.push({
                    pointer,
                    oldValueExists: !!oldValue,
                    newValueExists: !!newValue,
                    oldSig: _.get(oldValue, ['metaData', 'sig']),
                    newSig: _.get(newValue, ['metaData', 'sig']),
                    reallyDifferent: !_.isEqual(oldValue, newValue),
                    compareDebug: deepCompareDebug(oldValue, newValue),
                    compareDebug2: deepCompareDebug2(oldValue, newValue),
                    deep4: deepCompare4(oldValue, newValue),
                    oldToOld: deepCompare(oldValue, oldValue),
                    newToNew: deepCompare(newValue, newValue)
                })
            }
        }
        documentManager.dal.registerForChangesCallback(verifyUnchanged)

        applyAndUpdateToApprovedStore(serverStore, _.identity, 'before-csave')

        documentManager.dal.unregisterForChangesCallback(verifyUnchanged)
        if (changes.length) {
            logger.captureError(new ReportableError({
                errorType: 'initCSaveFixFailed',
                message: `${changes.length} values changed during cSave initialization`,
                extras: {
                    numberOfChanges: changes.length,
                    examples: changes.slice(0, 5),
                    revision: _.get(serverStore, ['documentServicesModel', 'revision'])
                }
            }))
        }
    }

    const applyAndUpdate = (store: ServerStore, op: (store: ServerStore) => ServerStore): ServerStore => {
        const removalCandidates = getRemovalCandidates(documentManager, store)
        const newStore = op(store)
        addStoreToDal(documentManager, newStore, removalCandidates)
        removeDataFromDal(documentManager, removalCandidates)
        documentManager.dal.commitTransaction('dataFixers', true)
        return newStore
    }

    const createServerStore = async (pagesData: Record<string, any>, disableCommonConfig: boolean): Promise<ServerStore> => {
        // Setup
        const serverStore = {
            pagesData,
            rendererModel,
            documentServicesModel,
            serviceTopology,
            orphanPermanentDataNodes: [] as any[],
            routers: rendererModel.routers,
            pagesPlatformApplications: rendererModel.pagesPlatformApplications
        }
        // Load to DAL
        await initializeModels(serverStore, disableCommonConfig)

        _(serverStore.pagesData)
            .omit(['masterPage'])
            .forEach(page => movePageDataToMaster(page, serverStore.pagesData.masterPage))

        _.forEach(serverStore.pagesData, minorFixing)

        const initialDocument = convertStore(serverStore, logger)
        documentManager.dal.mergeToApprovedStore(initialDocument, 'create-store')

        const {snapshots} = documentManager.extensionAPI as SnapshotExtApi
        snapshots.takeSnapshot(SNAPSHOTS.DAL_INITIAL)
        return serverStore
    }

    /**
     * @param patchedStore
     * @returns {*}
     */
    const runFixers = (patchedStore: ServerStore): ServerStore => {
        const fixerParams = getDataFixersParams(patchedStore)

        const {dataFixerVersioning} = documentManager.extensionAPI as DataFixerVersioningApi

        const fixerVersions = {}
        const fixerChangesOnReruns = {}

        const fixPage = (pageJson: any) =>
            dataFixer.fix({
                ...fixerParams,
                pageId: _.get(pageJson, ['structure', 'id'], 'masterPage'),
                pageJson,
                fixerVersions,
                fixerChangesOnReruns
            })

        const fixedStore = applyAndUpdate(patchedStore, store => ({
            ...store,
            pagesData: _.mapValues(patchedStore.pagesData, fixPage)
        }))

        Object.keys(fixerVersions).forEach(pageId => {
            dataFixerVersioning.updatePageVersionData(pageId, fixerVersions[pageId])
        })

        dataFixerVersioning.reportFixerActions(FixerCategory.VIEWER, fixerChangesOnReruns)

        return fixedStore
    }

    const getPageListToLoad = async (): Promise<PageList> => {
        let pageListToLoad = rendererModel.pageList

        if (partialPages.length > 0) {
            const partialPagesSet = new Set<string>(partialPages)

            pageListToLoad = {
                ...rendererModel.pageList,
                pages: rendererModel.pageList.pages.filter(rmPage => partialPagesSet.has(rmPage.pageId))
            }
        }

        return pageListToLoad
    }

    const buildServerStore = async (): Promise<ServerStore> => {
        const pageListToLoad = await getPageListToLoad()

        const concurrency = documentManager.config.experimentInstance.isOpen('dm_limitFetchPagesConcurrency') ? 100 : 0
        const pagesData = await trackingAndInteraction(INTERACTIONS.FETCH_PAGES, async () => fetchPagesFunction(fetchFn, pageListToLoad, concurrency))
        interactionEndedWithOptions(INTERACTIONS.LOAD_PAGE_PAYLOADS)

        const serverStore = await trackingAndInteraction(INTERACTIONS.CREATE_SERVER_STORE, async () => createServerStore(pagesData, config.disableCommonConfig))

        return serverStore
    }

    const initCSave = async (serverStore: ServerStore): Promise<ServerStore> => {
        const {snapshots} = documentManager.extensionAPI as SnapshotExtApi

        // apply server store into dal
        interactionStartedWithOptions(INTERACTIONS.APPLY_TO_APPROVED_STORE)

        if (!documentManager.config.experimentInstance.isOpen('dm_removeCSaveMerge')) {
            if (documentManager.config.experimentInstance.isOpen('dm_changedOnCsaveInit')) {
                updateApprovedStoreBeforeCSaveAndVerifyNoChanges(serverStore)
            } else {
                applyAndUpdateToApprovedStore(serverStore, _.identity, 'before-csave')
            }
        }

        interactionEndedWithOptions(INTERACTIONS.APPLY_TO_APPROVED_STORE)

        const {continuousSave} = documentManager.extensionAPI as CSaveApi
        // apply csave transactions and take csave snapshot
        const changedApplied = await trackingAndInteraction(INTERACTIONS.INIT_CSAVE, async () => continuousSave.initCSave(partialPages))

        if (!changedApplied) {
            return serverStore
        }

        // rebuild a server store (use site data immutable)
        const store = getSiteDataJson(snapshots.getLastSnapshotByTagName(extensions.continuousSave.CSAVE_TAG), config.origin, {
            withPagesData: true
        }) as ServerStore
        store.rendererModel.pagesPlatformApplications = store.pagesPlatformApplications
        return store
    }

    const initCSaveIfNeeded = async (serverStore: ServerStore): Promise<ServerStore> => {
        const {continuousSave} = documentManager.extensionAPI as CSaveApi
        if (continuousSave && config.continuousSave) {
            return await initCSave(serverStore)
        }
        return serverStore
    }

    const initViews = async (views: ViewsAPI) => {
        await views.initViews()
    }

    const initViewsIfNeeded = async () => {
        const {views} = documentManager.extensionAPI as ViewsExtensionAPI
        if (views && config.cedit) {
            return trackingAndInteraction(INTERACTIONS.INIT_VIEWS, () => initViews(views))
        }
    }

    const main = async (): Promise<{store: ServerStore}> => {
        interactionStartedWithOptions(INTERACTIONS.LOAD_PAGE_PAYLOADS)

        const serverStore = await buildServerStore()
        const newStorePending = initCSaveIfNeeded(serverStore)
        const views = initViewsIfNeeded()
        const resolvedStoredAndViews = await Promise.all([newStorePending, views])
        const newStore = resolvedStoredAndViews[0]
        interactionStartedWithOptions(INTERACTIONS.RUN_FIXERS)
        const fixedStore = await trackingAndInteraction(INTERACTIONS.RUN_VIEWER_FIXERS, async () => runFixers(newStore))
        await trackingAndInteraction(INTERACTIONS.RUN_MIGRATORS, async () => dataMigrationRunner.runDataMigration(documentManager))
        interactionEndedWithOptions(INTERACTIONS.RUN_FIXERS)

        // Applying multilingual overrides
        await trackingAndInteraction(INTERACTIONS.MULTILINGUAL_INIT, async () => multilingualInitializer.initialize(documentManager))

        // Closing the transaction
        await trackingAndInteraction(INTERACTIONS.INIT_FINAL_COMMIT, async () => documentManager.dal.commitTransaction('mainInitialization', true))
        return {store: fixedStore}
    }

    return await main()
}

export {initialize}
