import imageDataResolver from './imageDataResolver'
import pageLinkDataResolver from './pageLinkDataResolver'
import anchorLinkDataResolver from './anchorLinkDataResolver'
import connectionListDataResolver from './connectionListDataResolver'
import appControllerDataResolver from './appControllerDataResolver'
import behaviorsListDataResolver from './behaviorsListDataResolver'

export default {
    Image: imageDataResolver,
    PageLink: pageLinkDataResolver,
    AnchorLink: anchorLinkDataResolver,
    ConnectionList: connectionListDataResolver,
    AppController: appControllerDataResolver,
    ObsoleteBehaviorsList: behaviorsListDataResolver
}
