define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/component/componentData',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/mobileConversion/mobileActions',
    'documentServices/mobileConversion/modules/mobileOnlyComponents',
    'documentServices/mobileConversion/modules/userModifiedComponentHandler',
    'documentServices/mobileConversion/modules/migrations/mobilePageToPreset',
    'documentServices/mobileConversion/modules/utils',
    'documentServices/constants/constants',
    'documentServices/documentMode/documentModeInfo'
], function (
    _,
    dataModel,
    component,
    componentData,
    componentsMetaData,
    mobileActions,
    mobileOnlyComponents,
    userModifiedComponent,
    mobilePageToPreset,
    mobileConversionUtils,
    constants,
    documentModeInfo
) {
    'use strict'
    const {isMobileOnlyComponent} = mobileOnlyComponents
    const mobileDataItemsHandlers = [
        {
            isSplit: componentData.isMobileDesignDataSplit,
            getPointer: dataModel.getDesignItemPointer
        },
        {
            isSplit: componentData.isMobileComponentPropertiesSplit,
            getPointer: dataModel.getPropertyItemPointer
        }
    ]

    function addDeadComponentWithTheSameLayout(ps, originalMobileComponentPointer, parentPointer) {
        const mobileComponent = ps.dal.full.get(originalMobileComponentPointer)
        const deadComp = {
            id: `${constants.DOM_ID_PREFIX.DEAD_MOBILE_COMP}${mobileComponent.id}`,
            type: 'Container',
            componentType: constants.DEAD_MOBILE_COMPONENT_TYPE,
            layout: mobileComponent.layout,
            components: []
        }
        const siblingComponents = ps.pointers.full.components.getChildrenContainer(parentPointer)
        const deadCompPointer = component.getComponentToAddRef(ps, parentPointer, deadComp, deadComp.id)
        ps.dal.full.push(siblingComponents, deadComp, deadCompPointer)
    }

    function shouldReplaceWithDeadComponent(ps, mobileComponentPointer) {
        const pageId = _.get(ps.pointers.full.components.getPageOfComponent(mobileComponentPointer), 'id', '')
        const mobileComponent = ps.dal.full.get(mobileComponentPointer)
        const isNonLayoutComponent = componentsMetaData.public.getMobileConversionConfigByName(ps, mobileComponent, 'nonLayoutComponent', pageId)
        return !isNonLayoutComponent
    }

    /**
     * Replace mobile component with dummy container that has the same layout.
     * MobileMerge will remove mobile component from mobile structure in a right way,
     * but we need to cut off all its data references to make sure the site JSON is valid.
     */
    function setMobileComponentAsDead(ps, mobilePointer) {
        const parentPointer = ps.pointers.full.components.getParent(mobilePointer)
        if (parentPointer && shouldReplaceWithDeadComponent(ps, mobilePointer)) {
            addDeadComponentWithTheSameLayout(ps, mobilePointer, parentPointer)
        }

        _(ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(mobilePointer)).forEach(childPointer => {
            if (ps.dal.full.isExist(childPointer)) {
                mobileDataItemsHandlers.forEach(({isSplit, getPointer}) => {
                    if (isSplit(ps, childPointer)) {
                        const dataItemPointer = getPointer(ps, childPointer)
                        if (dataItemPointer && ps.dal.isExist(dataItemPointer)) {
                            ps.dal.full.remove(dataItemPointer)
                        }
                    }
                })

                ps.dal.full.remove(childPointer)
            }
        })
    }

    function setMobileComponentAsDeadOnRemoval(ps, componentPointer) {
        if (ps.pointers.components.isPage(componentPointer) || ps.pointers.components.isMobile(componentPointer) || ps.dal.full.isExist(componentPointer)) {
            return
        }

        const mobilePointer = ps.pointers.full.components.getMobilePointer(componentPointer)
        if (!ps.dal.full.isExist(mobilePointer)) {
            return
        }

        setMobileComponentAsDead(ps, mobilePointer)
    }

    const mobileCompExistsAndOnSamePageAsDesktop = (ps, componentPointer) => {
        const mobilePointer = ps.pointers.full.components.getMobilePointer(componentPointer)
        if (!ps.dal.full.isExist(mobilePointer)) {
            return false
        }
        const pageIdOfDesktopComp = _.get(ps.pointers.components.getPageOfComponent(componentPointer), 'id')
        const pageIdOfMobileComp = _.get(ps.pointers.components.getPageOfComponent(mobilePointer), 'id')

        return pageIdOfDesktopComp === pageIdOfMobileComp
    }

    function setDesktopChildrenAsDeadOnPageMove(ps, componentPointer) {
        const desktopChildren = ps.pointers.components.getChildren(componentPointer)
        _.forEach(desktopChildren, childPointer => {
            const mobileChildPointer = ps.pointers.full.components.getMobilePointer(childPointer)
            if (!mobileCompExistsAndOnSamePageAsDesktop(ps, childPointer)) {
                setMobileComponentAsDead(ps, mobileChildPointer)
            }

            setDesktopChildrenAsDeadOnPageMove(ps, childPointer)
        })
    }

    function setMobileComponentAsDeadReparent(ps, componentPointer) {
        if (ps.pointers.components.isPage(componentPointer) || ps.pointers.components.isMobile(componentPointer)) {
            return
        }
        if (mobileCompExistsAndOnSamePageAsDesktop(ps, componentPointer)) {
            mobileActions.markComponentAsDirtyForForceReRender(ps, componentPointer)
            return
        }

        setDesktopChildrenAsDeadOnPageMove(ps, componentPointer)

        const mobilePointer = ps.pointers.full.components.getMobilePointer(componentPointer)
        if (!ps.dal.full.isExist(mobilePointer)) {
            return
        }

        setMobileComponentAsDead(ps, mobilePointer)
    }

    /**
     *
     * @param {ps} ps
     * @param {AbstractComponent} componentToMark
     * @param {boolean} shouldMarkParent
     */
    function setTouchedToComponent(ps, componentToMark, shouldMarkParent) {
        const pagePointer = ps.pointers.full.components.getPageOfComponent(componentToMark)
        // Desktop pointer because we keep all mobileHints in desktop structure and write to it
        const desktopCompPointer = ps.pointers.full.components.getDesktopPointer(componentToMark)
        const mobileHintsItem = dataModel.getMobileHintsItem(ps, desktopCompPointer)
        if (mobileHintsItem && userModifiedComponent.isModifiedByUser(mobileHintsItem)) {
            return
        }
        mobileActions.markMobileComponentChangedByUser(ps, pagePointer.id, desktopCompPointer, shouldMarkParent)
    }

    const getFirstNonMobileOnlyComponent = (ps, compPointer) => {
        let componentToMark = compPointer
        const isMobileOnly = isMobileOnlyComponent(ps, componentToMark.id)
        if (isMobileOnly) {
            componentToMark = ps.pointers.components.getParent(componentToMark)
            return getFirstNonMobileOnlyComponent(ps, componentToMark)
        }
        return compPointer
    }

    /***
     * @description Method to set component as modified by user,
     *  for future mobile algo conversion
     * @param {object} ps
     * @param {object} compPointer
     */
    function setMobileComponentAsTouched(ps, compPointer) {
        const componentToMark = compPointer
        const isDesktopPointer = !ps.pointers.components.isMobile(componentToMark)
        const documentViewMode = documentModeInfo.getViewMode(ps)

        if (
            !mobileConversionUtils.shouldEnableNewMergeFlow(ps) ||
            isDesktopPointer ||
            ps.pointers.components.isPage(componentToMark) ||
            documentViewMode === constants.VIEW_MODES.DESKTOP
        ) {
            return
        }

        const isMobileOnly = isMobileOnlyComponent(ps, componentToMark.id)
        if (isMobileOnly) {
            const firstNotMobileOnlyParent = getFirstNonMobileOnlyComponent(ps, componentToMark)
            setTouchedToComponent(ps, firstNotMobileOnlyParent, false)
            return
        }
        setTouchedToComponent(ps, componentToMark, true)
    }

    const cretatePresetForPage = ps => {
        const focusedPageId = ps.siteAPI.getFocusedRootId()
        if (mobilePageToPreset.isAbleToRunMigration(ps, focusedPageId)) {
            mobilePageToPreset.migratePage(ps, focusedPageId)
        }
    }

    return {
        setMobileComponentAsDeadOnRemoval,
        setMobileComponentAsDeadReparent,
        setMobileComponentAsTouched,
        cretatePresetForPage
    }
})
