import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const autosave = viewerLibrary.get('documentServices/autosave/autosave')
    const initMethod = autosave.initMethod || (() => {}) //temp noop to solve dependency updates
    return {
        initMethod,
        methods: {
            /**
             * performs an autosave
             * @member documentServices
             * @param {ps} privateServices
             * @param {onSuccess} onSuccess
             * @param {onError} onError
             * @param {string} triggerName
             */
            autosave: publicMethodUtils.defineAction(autosave.autosave),

            /**
             * Checks if autosave should be performed
             * @member documentServices
             * @return {boolean}
             */
            canAutosave: publicMethodUtils.defineGetter(autosave.canAutosave),

            /**
             * Returns documentServicesModel.autoSaveInfo
             * @member documentServices
             * @return {boolean}
             */
            getAutoSaveInfo: publicMethodUtils.defineGetter(autosave.getAutoSaveInfo),

            /**
             * Initializes Auto-Save by passing configuration object
             * @member documentServices
             * @param {object} configuration object
             */
            initAutosave: publicMethodUtils.defineAction(autosave.init)
        }
    }
}
