define([
    'documentServices/page/pageProperties',
    'lodash',
    '@wix/mobile-conversion',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/modules/displayModeUtils',
    'documentServices/mobileConversion/modules/hideMobileComponent',
    'documentServices/mobileConversion/modules/conversionSettings',
    'documentServices/mobileConversion/modules/menuContainer/menuContainer',
    'documentServices/mobileConversion/mobileEditorSettings/backToTopButtonDefinitions',
    'documentServices/constants/constants',
    'documentServices/hooks/hooks',
    '@wix/santa-core-utils',
    'documentServices/mobileConversion/modules/mobileHints',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/mobileConversion/modules/mobileOnlyComponents',
    'documentServices/mobileConversion/modules/userModifiedComponentHandler',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/componentDetectorAPI/componentDetectorAPI'
], function (
    pageProperties,
    _,
    mobileCore,
    mobileConversion,
    mergeAggregator,
    displayModeUtils,
    hideMobileComponent,
    conversionSettings,
    menuContainer,
    backToTopButtonDefinitions,
    constants,
    hooks,
    santaCoreUtils,
    mobileHints,
    componentsMetaData,
    component,
    dataModel,
    mobileOnlyComponents,
    userModifiedComponents,
    extensionsAPI,
    refComponentUtils,
    componentDetectorAPI
) {
    'use strict'
    const getDesktopPointer = (ps, compId, pageId) =>
        ps.pointers.full.components.getComponent(compId, ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP))
    const getMobilePointer = (ps, compId, pageId) =>
        ps.pointers.components.getComponent(compId, ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE))
    const getMetaData = (ps, comp, pageId, metaDataPath) =>
        _.get(componentsMetaData.public.getMobileConversionConfig(ps, ps.dal.full.get(comp), pageId), metaDataPath)

    const {isMobileOnlyComponent, isNativeMobileOnlyComponent} = mobileOnlyComponents

    function resetPageMinHeight(ps, pageId) {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        if (pagePointer.id !== 'masterPage') {
            pageProperties.updatePageProperties(ps, pagePointer, {minHeight: null})
        }
    }

    function runHoverBoxHooksOnPage(ps, pagePointer) {
        const type = ps.dal.get(ps.pointers.getInnerPointer(pagePointer, 'componentType'))
        hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.BEFORE, type, [ps, pagePointer])
    }

    function runHoverBoxHooksIfNeeded(ps) {
        const changedPagesPointers = mergeAggregator.getChangedPagesPointers(ps)
        const renderedPagesPointers = _.map(ps.siteAPI.getAllRenderedRootIds(), rootId => ps.pointers.page.getPagePointer(rootId))
        _.forEach(_.uniqBy(changedPagesPointers.concat(renderedPagesPointers), 'id'), pagePointer => runHoverBoxHooksOnPage(ps, pagePointer))
    }

    function registerMobileConversionHook(key, callback) {
        hooks.unregisterHooks([key])
        hooks.registerHook(key, callback)
    }

    function convertMobileStructure(ps) {
        runHoverBoxHooksIfNeeded(ps)
        mobileConversion.runPartialConversionAllPages(ps)
    }

    /***********************PUBLIC FUNCTIONS *************************************/
    function initialize(ps) {
        registerMobileConversionHook(hooks.HOOKS.SWITCH_VIEW_MODE.MOBILE, _.bind(convertMobileStructure, null, ps))
        mobileHints.initialize(ps)
    }

    function reLayoutPage(ps, pageId) {
        pageId = pageId || ps.siteAPI.getCurrentUrlPageId()
        runHoverBoxHooksOnPage(ps, ps.pointers.page.getPagePointer(pageId))
        resetPageMinHeight(ps, pageId)
        const page = mergeAggregator.getPage(ps, pageId)
        mobileConversion.runMobileConversionOnPage(ps, page)
        hooks.executeHook(hooks.HOOKS.RELAYOUT_MOBILE_PAGE.AFTER, null, [ps, pageId])
    }

    function handleReAddMobileOnlyComponent(ps, compId) {
        switch (compId) {
            case constants.MOBILE_ONLY_COMPONENTS.TINY_MENU:
                mobileConversion.addMobileOnlyComponent(ps, constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, {commitConversionResults: false})
                break
            case constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE:
                menuContainer.toggle.add(ps)
                break
            case constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU:
                menuContainer.expandableMenu.add(ps)
                break
        }
    }

    function reAddDeletedMobileComponent(ps, compToReAddRef, compId, pageId) {
        if (!compId || !pageId) {
            throw new Error('function must receive component id and page id')
        }
        const mobilePtr = getMobilePointer(ps, compId, pageId)
        if (isMobileOnlyComponent(ps, compId)) {
            handleReAddMobileOnlyComponent(ps, compId)
            return
        }
        const desktopCompPointer = getDesktopPointer(ps, compId, pageId)
        mobileHints.updateProperty(ps, {hidden: false}, desktopCompPointer, {
            updateChildren: !santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(desktopCompPointer),
            childrenSourceView: constants.VIEW_MODES.DESKTOP
        })
        if (mobilePtr && santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(mobilePtr)) {
            return refComponentUtils.unGhostifyComponent(ps, mobilePtr)
        }
        const desktopPage = mergeAggregator.getPage(ps, pageId)
        const mobilePage = mobileCore.conversionUtils.extractMobilePage(desktopPage)
        const settings = conversionSettings.getConversionSettings({conversionType: 'MERGE_UNHIDE'})
        mobileConversion.runMobileMergeOnPage(ps, desktopPage, mobilePage, settings)
    }

    function isLegacyBackToTopButton(ps, backToTopPointer) {
        const backToTopButtonType = ps.dal.get(ps.pointers.getInnerPointer(backToTopPointer, 'componentType'))
        return backToTopButtonType === constants.MOBILE_ONLY_COMPONENT_TYPES.BACK_TO_TOP_BUTTON_LEGACY
    }

    function addBackToTopButton(ps, isLegacyMigration, dockedOverrides) {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const compToAddRef = component.getComponentToAddRef(ps, masterPagePointer, null, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON)
        const backToTopButtonDefaultDefinition = backToTopButtonDefinitions.getBackToTopButtonDefinition(isLegacyMigration, dockedOverrides)
        component.add(ps, compToAddRef, masterPagePointer, backToTopButtonDefaultDefinition)
    }

    function toggleBackToTopButton(ps, isToggleOn, dockedOverrides) {
        const backToTopPointer = getMobilePointer(ps, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON, constants.MASTER_PAGE_ID)
        const isExist = ps.dal.isExist(backToTopPointer)

        if (isExist && isToggleOn && isLegacyBackToTopButton(ps, backToTopPointer)) {
            component.deleteComponent(ps, backToTopPointer)
            addBackToTopButton(ps, true, dockedOverrides)
        } else if (!isExist && isToggleOn) {
            addBackToTopButton(ps, false, dockedOverrides)
        } else if (isExist && !isToggleOn) {
            component.deleteComponent(ps, backToTopPointer)
        }
    }

    function setBackToTopButton(ps, enable) {
        if (typeof enable !== 'boolean') {
            throw new Error('invalid parameter type')
        }
        const pageId = constants.MASTER_PAGE_ID
        const compPointer = getMobilePointer(ps, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON, pageId)
        const isEnabled = compPointer && ps.dal.isExist(compPointer)
        if (enable && !isEnabled) {
            mobileConversion.addMobileOnlyComponent(ps, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON)
        }
        if (!enable && isEnabled) {
            hideMobileComponent.hideMobileComponent(ps, compPointer, pageId, {updateLayout: false})
        }
    }

    function omitChildrenOfHiddenComponentsIfNeeded(ps, deletedCompList, pageId) {
        const componentsToFilter = _.flatMap(deletedCompList, function (deletedCompId) {
            if (isMobileOnlyComponent(ps, deletedCompId)) {
                return []
            }
            const desktopPage = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
            const deletedCompPointer = ps.pointers.full.components.getComponent(deletedCompId, desktopPage)
            const filterChildrenWhenHidden = getMetaData(ps, deletedCompPointer, pageId, 'filterChildrenWhenHidden')
            return filterChildrenWhenHidden ? _.map(ps.pointers.full.components.getChildrenRecursively(deletedCompPointer), 'id') : []
        })
        return _.difference(deletedCompList, componentsToFilter)
    }

    function getFilteredMobileDeletedComponentsMap(ps, deletedCompsMap) {
        return _.reduce(deletedCompsMap, (res, deletedCompList, id) => _.set(res, id, omitChildrenOfHiddenComponentsIfNeeded(ps, deletedCompList, id)), {})
    }

    function getFilteredMobileDeletedComponents(ps, pageId) {
        const deletedComps = getMobileDeletedCompMap(ps, pageId)
        const deletedCompsMap = pageId ? _.set({}, pageId, deletedComps) : deletedComps
        const filteredDeletedCompsMap = getFilteredMobileDeletedComponentsMap(ps, deletedCompsMap)
        return pageId ? filteredDeletedCompsMap[pageId] : filteredDeletedCompsMap
    }

    function addMobileOnlyComponentsToDeletedCompsListIfNeeded(ps, deletedComps) {
        const shouldAddTinyMenu =
            !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) && !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.TINY_MENU)
        if (shouldAddTinyMenu) {
            deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU)
        }

        const shouldAddMenuToggle =
            existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) &&
            !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE)
        if (shouldAddMenuToggle) {
            deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE)
        }

        const shouldAddExpandableMenu =
            existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) &&
            !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU)
        if (shouldAddExpandableMenu) {
            deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU)
        }
    }

    const getCompPointersFromHiddenCompsMap = (ps, hiddenCompsMap, pageId) =>
        _(hiddenCompsMap)
            .keys()
            .map(compId => componentDetectorAPI.getComponentById(ps, compId, pageId, constants.VIEW_MODES.DESKTOP))
            .value()

    const getGhostCompsOfPage = (ps, pagePointer) => {
        const pageCompsFromDisplayed = componentDetectorAPI.getComponentsUnderAncestor(ps, pagePointer)
        const ghostRefCompsPointers = _(pageCompsFromDisplayed)
            .filter(compPointer => refComponentUtils.isRefHost(ps, compPointer))
            .flatMap(compPointer => ps.pointers.referredStructure.getGhostRefComponents(compPointer.id))
            .compact()
            .flatMap(hiddenCompsMap => getCompPointersFromHiddenCompsMap(ps, hiddenCompsMap, pagePointer.id))
            .value()

        return ghostRefCompsPointers
    }

    const getCompId = hiddenComp => {
        if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(hiddenComp)) {
            return hiddenComp.id
        }
        return _.get(hiddenComp.mobileHints, 'displayedCompId', hiddenComp.id)
    }

    // TODO: will fail if active modes on mobile are not as on desktop
    function getMobileDeletedCompsList(ps, pageId) {
        const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        if (!desktopPagePointer) {
            return null
        }
        const pageChildren = ps.pointers.full.components.getChildrenRecursively(desktopPagePointer)

        const deletedComps = _(pageChildren)
            .concat(getGhostCompsOfPage(ps, desktopPagePointer))
            .map(compPointer => ({id: compPointer.id, mobileHints: dataModel.getMobileHintsItem(ps, compPointer)}))
            .filter(({mobileHints: hints}) => hints && hints.hidden)
            .map(hiddenComp => getCompId(hiddenComp))
            .value()

        if (pageId !== constants.MASTER_PAGE_ID) {
            return deletedComps
        }

        addMobileOnlyComponentsToDeletedCompsListIfNeeded(ps, deletedComps)

        return _.compact(deletedComps)
    }

    function setHiddenComponentsList(ps, pageId, hiddenCompsList) {
        const pageStructure = ps.dal.full.get(ps.pointers.page.getPagePointer(pageId)).structure
        const allCompsIds = _(santaCoreUtils.dataUtils.getAllCompsInStructure(pageStructure))
            .reject(mobileCore.conversionUtils.isPageComponent)
            .map('id')
            .value()
        _.forEach(allCompsIds, compId => {
            const hidden = _.includes(hiddenCompsList, compId)
            const compPointer = getDesktopPointer(ps, compId, pageId)
            mobileHints.updateProperty(ps, {hidden}, compPointer)
        })
    }

    function existsOnMasterPage(ps, compId) {
        const compPointer = getMobilePointer(ps, compId, constants.MASTER_PAGE_ID)
        return compPointer && ps.dal.isExist(compPointer)
    }

    function implicitFontSize(ps, desktopFontSize) {
        return santaCoreUtils.mobileUtils.convertFontSizeToMobile(desktopFontSize, 1)
    }

    function getMobileDeletedCompMap(ps, pageId) {
        const pageIds = pageId ? [pageId] : extensionsAPI.pages.getAllPagesIds(ps)
        const deletedCompsMap = _.reduce(pageIds, (res, id) => _.set(res, id, getMobileDeletedCompsList(ps, id)), {})
        return pageId ? deletedCompsMap[pageId] : deletedCompsMap
    }

    function hide(ps, compPointer, settings = {updateLayout: true}) {
        compPointer = ps.pointers.components.getMobilePointer(compPointer)
        if (componentsMetaData.shouldBeRemovedByParent(ps, compPointer)) {
            compPointer = ps.pointers.components.getParent(compPointer)
        }
        if (!ps.dal.isExist(compPointer)) {
            return
        }
        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        resetPageMinHeight(ps, pageId)
        const desktopCompPointer = getDesktopPointer(ps, compPointer.id, pageId)
        const isDisplayedOnlyComp = santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)
        const property = {hidden: true}

        if (isDisplayedOnlyComp) {
            _.assign(property, {displayedCompId: compPointer.id})
        }

        if (ps.dal.full.isExist(desktopCompPointer)) {
            mobileHints.updateProperty(
                ps,
                property,
                desktopCompPointer,
                {
                    updateChildren: true,
                    childrenSourceView: constants.VIEW_MODES.MOBILE
                },
                pageId
            )
        }

        hideMobileComponent.hideMobileComponent(ps, compPointer, pageId, settings)
    }

    function markMobileComponentChangedByUser(ps, pageId, compPointer, shouldMarkParent) {
        userModifiedComponents.markComponentAsTouched(ps, pageId, compPointer, shouldMarkParent)
    }

    const markComponentAsDirtyForForceReRender = (ps, desktopCompPointer) => {
        mobileHints.markComponentAsDirtyForForceReRender(ps, desktopCompPointer)
    }

    const clearMobileReferences = ps => {
        const allMobileComp = ps.siteAPI.getAllMobileComponents()
        mergeAggregator.resetCommittedMobilePages(ps)
        _.forEach(allMobileComp, compVal => {
            if (compVal && compVal.id) {
                const compId = compVal.id
                const pageId = _.get(compVal, ['metaData', 'pageId'])
                const compRef = ps.pointers.components.getComponent(compId, ps.pointers.components.getPage(pageId, 'MOBILE'))
                if (component.isComponentRemovable(ps, compRef)) {
                    component.deleteComponent(ps, compRef)
                }
            }
        })
    }

    const mobileActions = {
        initialize,
        /**
         * clears all changes preformed by user on mobile structure and runs the mobile algorithm again for the given page. If a page is not given
         * the algorithm we run on the current page.
         * @member documentServices.mobile
         * @param {string} [pageId] pageId to relayout - runs mobile conversion on the page - if null relayout current page.
         */
        reLayoutPage,
        /**
         * show or hide back to top button based on enable parameter
         * @member documentServices.mobile
         * @param {boolean} enable - enable or disable to add to top button
         *
         */
        enableBackToTopButton: setBackToTopButton,
        toggleBackToTopButton,
        isLegacyBackToTopButton,
        /**
         * updates display mode of component in the mobile view and page layout when needed
         * @member documentServices.mobile
         * @param compPointer pointer of component in mobile
         * @param mobileDisplayedModeId display mode of component
         *
         */
        setComponentDisplayMode: displayModeUtils.setComponentDisplayMode,
        /**
         * Return the default position of tinyMenu, to be used when
         * setting tinyMenu as fixedPosition while tinyMenu is outside the 'green zone'
         *
         * @returns {{x: number, y: number}}
         */
        getTinyMenuDefaultPosition: mobileCore.conversionUtils.getTinyMenuDefaultPosition,
        /**
         * @class documentServices.mobile.hiddenComponents
         */
        hiddenComponents: {
            /**
             * returns all components deleted for mobile structure - if page id is passed an array with comps for the page will be returned
             * @param {string} [pageId] -  deleted comps of the page id. if not passed return deleted comps for all pages.
             */
            get: getMobileDeletedCompMap,
            /**
             * returns a subset of components deleted for mobile structure - if page id is passed an array with comps for the page will be returned
             * the filter will return only components that their parent on desktop is not presented on the list. That way a child can be hidden and appear in the list
             * only if its parent is not also hidden (if the parent is hidden, only the parent will appear in the list)
             * @param {string} [pageId] -  deleted comps of the page id. if not passed return deleted comps for all pages.
             */
            getFiltered: getFilteredMobileDeletedComponents,
            /**
             * hides the component from mobile structure
             * @param {object} compPointer - pathAbstraction of the comp we want to hide
             * @param {string} compPointer.id - id of component
             * @param {string} compPointer.type - DESKTOP/MOBILE
             *
             */
            hide,
            /**
             * allows to re add component to mobile structure. You can re add only components that were hidden before.
             * @param {string} compId to show
             * @param {string} pageId the component was deleted from
             */
            show: reAddDeletedMobileComponent,
            set: setHiddenComponentsList
        },
        /**
         * Checks if a component is only mobile component
         *
         * @param {ps} ps - private document services
         * @param {string} compId
         *
         * @returns {boolean}
         */
        isMobileOnlyComponent,
        /**
         * Checks if a component is a legacy mobile only component (Legacy components - Like Back to Top, QAB, Tiny Menu, etc..)
         *
         * @param {ps} ps - private document services
         * @param {string} compId The component to check
         *
         * @returns {boolean}
         */
        isNativeMobileOnlyComponent,
        /**
         * Checks if a component that is only mobile exist on mobile structure
         *
         * @param {ps} ps - private document services
         * @param {string} compId
         *
         * @returns {boolean}
         */
        isMobileOnlyComponentExistOnStructure: existsOnMasterPage,
        /**
         * return object with mobile Only components and their ids
         *
         * @returns {object}
         */
        mobileOnlyComps: constants.MOBILE_ONLY_COMPONENTS,
        /**
         * return compRef for mobile component to be shown (re-added)
         *
         * @returns {object}
         */
        implicitFontSize,
        getMobileComponentToShow: getMobilePointer,
        markMobileComponentChangedByUser,
        markComponentAsDirtyForForceReRender,
        clearMobileReferences
    }
    return mobileActions
})
