define(['lodash', 'color', 'documentServices/constants/constants'], function (_, Color, constants) {
    const valueToHex = colorString => {
        if (colorString.indexOf('#') !== 0) {
            const rgbString = colorString.indexOf('r') === 0 ? colorString : `rgba(${colorString})`
            const colorObj = new Color(rgbString)
            return colorObj.hexString()
        }
        return colorString
    }

    return {
        exec(ps) {
            const THEME_DATA_ID = 'THEME_DATA'
            const type = 'color'
            const theme = ps.pointers.data.getThemeItem(THEME_DATA_ID, constants.MASTER_PAGE_ID)
            const pointer = ps.pointers.getInnerPointer(theme, type)
            const values = ps.dal.full.get(pointer)
            const result = _.map(values, valueToHex)
            if (!_.isEqual(result, values)) {
                ps.dal.full.set(pointer, result)
            }
        },
        name: 'rgbaSystemColorsFixer',
        version: 1
    }
})
