import santaComponents from '@wix/santa-components'
import tpaPreviewEditorMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaPreviewEditorMixin'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {compRegistrar} = componentsCore

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay', {
    displayName: 'TPAUnavailableMessageOverlay',
    mixins: [componentsCore.mixins.skinBasedComp, tpaPreviewEditorMixin],
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
    },

    getInitialState() {
        return {
            showOverlay: true
        }
    },

    getSkinProperties() {
        return {
            text: {
                children: [this.props.compData.text]
            },
            dismissButton: {
                onClick: this.props.compData.hideOverlayFunc
            },
            reloadButton: {
                children: [this.props.compData.reload],
                onClick: this.props.compData.reloadFunc
            },
            openHelp: this.openHelp
        }
    }
})
