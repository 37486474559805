define(['tpa'], function (tpa) {
    'use strict'
    class ProvisionUrlBuilder extends tpa.common.TPABaseUrlBuilder {
        addMetaSiteId(metaSiteId) {
            return this.addQueryParam('metaSiteId', metaSiteId)
        }

        addEditorSessionId(editorSessionId) {
            return this.addQueryParam('editorSessionId', editorSessionId)
        }

        addAcceptJson() {
            return this.addQueryParam('accept', 'json')
        }

        addAppVersionAndSiteRevision(appVersion, siteRevision) {
            if (appVersion) {
                return this.addQueryParam('appVersion', appVersion).addQueryParam('siteRevision', siteRevision)
            }

            return this
        }
    }

    return ProvisionUrlBuilder
})
