define(['lodash', 'experiment'], function (_, experiment) {
    'use strict'
    const DEFAULT_CONVERSION_CONFIGURATION = {
        conversionType: 'FULL',
        heuristicStrategy: 'default'
    }
    const HEURISTIC_STRATEGIES_SETTINGS = {
        default: {
            detectSemanticGroups: true,
            fixMasterPageStructureOrder: false
        },
        appbuilder: {
            keepEmptyTextComponents: true,
            keepNotRecommendedMobileComponents: true,
            keepOccludedAndEmptyBackgrounds: true,
            keepOutOfScreenComponents: true,
            keepInvisibleComponents: true
        },
        onboarding: {
            detectSemanticGroups: false
        },
        accessibility: {
            keepNotRecommendedMobileComponents: true,
            detectSemanticGroups: false,
            analyzeOnly: true
        }
    }
    const CONVERSION_TYPES_SETTINGS = {
        FULL: {},
        MERGE_ALL_PAGES: {
            keepEmptyTextComponents: false,
            keepNotRecommendedMobileComponents: false,
            keepOccludedAndEmptyBackgrounds: true,
            keepOutOfScreenComponents: true,
            fixMasterPageStructureOrder: false
        },
        MERGE_UNHIDE: {
            keepEmptyTextComponents: true,
            keepNotRecommendedMobileComponents: true,
            keepOccludedAndEmptyBackgrounds: true,
            keepOutOfScreenComponents: true,
            keepInvisibleComponents: true,
            fixMasterPageStructureOrder: false
        },
        MERGE_PRESET: {
            keepEmptyTextComponents: true,
            keepNotRecommendedMobileComponents: true,
            keepOccludedAndEmptyBackgrounds: true,
            keepOutOfScreenComponents: true,
            fixMasterPageStructureOrder: false
        },
        MERGE_DISPLAY_MODE: {
            keepEmptyTextComponents: false,
            keepNotRecommendedMobileComponents: true,
            keepOccludedAndEmptyBackgrounds: true,
            keepOutOfScreenComponents: true,
            fixMasterPageStructureOrder: false
        }
    }
    function getConversionSettings(conversionConfiguration) {
        conversionConfiguration = _.defaults({}, conversionConfiguration, DEFAULT_CONVERSION_CONFIGURATION)
        const mobileAlgoImprovedConfig = experiment.isOpen('dm_mobileAlgoImprovements')
            ? {
                  keepEmptyTextComponents: true,
                  keepNotRecommendedMobileComponents: true
              }
            : {}

        const config = _.assign(
            {
                applySemanticRules: true,
                useOverlapRules: experiment.isOpen('sv_mobileOverlapRules'),
                reparentMobileSOAP: true
            },
            CONVERSION_TYPES_SETTINGS[conversionConfiguration.conversionType],
            HEURISTIC_STRATEGIES_SETTINGS[conversionConfiguration.heuristicStrategy],
            mobileAlgoImprovedConfig
        )

        return config
    }
    return {getConversionSettings}
})
