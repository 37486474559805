define(['experiment', 'documentServices/constants/constants'], function (experiment, constants) {
    'use strict'

    return {
        get isRepeatable() {
            return experiment.isOpen('dm_widgetInRepeater')
        },
        shouldAutoSetNickname: false,
        mobileConversionConfig: {
            stretchHorizontally: true
        },
        canContainByStructure: true,
        isPublicContainer: false,
        resizableSides() {
            if (experiment.isOpen('dm_allowResizeRefComponents')) {
                return [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT] //this is the default value, when experiemnt will be merged, we will not return this object at all
            }

            return []
        },
        // when the experiment is merged: return a static value of true
        // canBeStretched: true
        canBeStretched: () => experiment.isOpen('dm_allowResizeRefComponents')
    }
})
