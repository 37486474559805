define(['lodash', 'documentServices/componentDetectorAPI/componentDetectorAPI', 'documentServices/dataModel/dataModel'], function (
    _,
    componentDetectorAPI,
    dataModel
) {
    'use strict'

    function replaceFullScreenModeToDock(ps, compPointer, compProperties) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const layout = ps.dal.full.get(layoutPointer)
        layout.docked = {left: {vw: 0}, right: {vw: 0}}
        ps.dal.full.set(layoutPointer, layout)

        compProperties.fullScreenModeOn = false
        dataModel.updatePropertiesItem(ps, compPointer, compProperties)
    }

    return {
        exec(ps) {
            const allFiveGridLinesPointersInSite = componentDetectorAPI.getComponentByType(ps, 'wysiwyg.viewer.components.FiveGridLine')
            _.forEach(allFiveGridLinesPointersInSite, function (compPointer) {
                const compProperties = dataModel.getPropertiesItem(ps, compPointer)
                const isFullModeOn = compProperties && compProperties.fullScreenModeOn
                if (isFullModeOn) {
                    replaceFullScreenModeToDock(ps, compPointer, compProperties)
                }
            })
        },
        name: 'fiveGridLineFullWidthFixer',
        version: 1
    }
})
