define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/hooks/hooks',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/utils/utils',
    'document-services-schemas',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment'
], function (_, santaCoreUtils, hooks, tpaUtils, dsUtils, documentServicesSchemas, extensionsAPI, experiment) {
    'use strict'

    /**
     * @param {ps} privateServices
     * @param {Pointer} componentPointer
     * @return {null}
     */
    function getComponentType(privateServices, componentPointer) {
        let result = null
        if (privateServices && componentPointer) {
            result = privateServices.pointers.components.isMasterPage(componentPointer)
                ? 'wysiwyg.viewer.components.WSiteStructure'
                : dsUtils.getComponentType(privateServices, componentPointer)
        }
        return result
    }

    function getComponentDefinition(ps, componentType) {
        return documentServicesSchemas.services.schemasService.getDefinition(componentType)
    }

    function getComponentProperties(ps, componentPointer) {
        const propQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'propertyQuery')
        const propQuery = propQueryPointer && ps.dal.get(propQueryPointer)
        const page = ps.pointers.components.getPageOfComponent(componentPointer)
        const pageId = page && page.id
        const propPointer = propQuery && ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propQuery), pageId)
        return propPointer && ps.dal.get(propPointer)
    }

    function getSiblings(ps, compPointer) {
        const parentComp = ps.pointers.components.getParent(compPointer)
        const siblings = getChildComponents(ps, parentComp)
        _.remove(siblings, {id: compPointer.id})
        return siblings
    }

    function getRepeatedComponents(ps, compPointer) {
        if (!santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)) {
            return []
        }

        return ps.pointers.components.getAllDisplayedOnlyComponents(compPointer)
    }

    /**
     * this should be used in every method that adds a component to a container,
     * some containers have other containers in them that the component should be added to
     * @param {ps} ps
     * @param {Pointer} containerPointer the container that we want to add a component to
     * @returns {Pointer} a pointer to the container
     */
    function getContainerToAddComponentTo(ps, containerPointer) {
        const type = getComponentType(ps, containerPointer)
        const values = hooks.executeHooksAndCollectValues(hooks.HOOKS.ADD_ROOT.GET_CONTAINER_TO_ADD_TO, type, [ps, containerPointer])
        if (values.length > 1) {
            throw new Error("you can't have more that one hook returning a container")
        }
        return values[0] || containerPointer
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer The component to check its parent
     * @returns {Pointer} The pointer of the parent in case one is found, undefined otherwise
     */
    function getParentForSlottedComps(ps, componentPointer) {
        return experiment.isOpen('dm_getSlottedRefCompParent') ? extensionsAPI.slots.getPluginParent(ps, componentPointer) : undefined
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Pointer}
     */
    function getParent(ps, componentPointer) {
        return getParentForSlottedComps(ps, componentPointer) || ps.pointers.components.getParent(componentPointer)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Pointer}
     */
    function getParentFromFull(ps, componentPointer) {
        return getParentForSlottedComps(ps, componentPointer) || ps.pointers.full.components.getParent(componentPointer)
    }

    function getAncestors(ps, componentPointer, getParentMethod) {
        let parentRef = getParentMethod(ps, componentPointer)
        const ancestors = []

        while (parentRef !== null) {
            ancestors.push(parentRef)
            parentRef = getParentMethod(ps, parentRef)
        }

        return ancestors
    }

    const getAncestorsFromDisplayed = (ps, componentPointer) => getAncestors(ps, componentPointer, getParent)
    const getAncestorsFromFull = (ps, componentPointer) => getAncestors(ps, componentPointer, getParentFromFull)

    function getAllJsonChildren(privateServices, parentCompPointer) {
        return getChildComponents(privateServices, parentCompPointer, false)
    }

    function getTpaChildComponents(privateServices, parentCompPointer) {
        const childrenArr = privateServices.pointers.components.getChildrenRecursively(parentCompPointer)
        return _.filter(childrenArr, function (childComp) {
            const componentType = getComponentType(privateServices, childComp)
            return tpaUtils.isTpaByCompType(componentType)
        })
    }

    function getBlogChildComponents(privateServices, parentCompPointer) {
        const childrenArr = privateServices.pointers.components.getChildrenRecursively(parentCompPointer)
        return _.filter(childrenArr, function (childComp) {
            const componentType = getComponentType(privateServices, childComp)
            return componentType === 'wixapps.integration.components.AppPart'
        })
    }

    function isPageComponent(privateServices, compPointer) {
        return privateServices.pointers.components.isPage(compPointer)
    }

    function getChildComponents(privateServices, parentCompPointer, isRecursive) {
        return isRecursive
            ? privateServices.pointers.components.getChildrenRecursively(parentCompPointer)
            : privateServices.pointers.components.getChildren(parentCompPointer)
    }

    function getChildComponentsFromFull(privateServices, parentCompPointer, isRecursive) {
        return isRecursive
            ? privateServices.pointers.full.components.getChildrenRecursively(parentCompPointer)
            : privateServices.pointers.full.components.getChildren(parentCompPointer)
    }

    function isRenderedOnSite(privateServices, componentPointer) {
        return privateServices.siteAPI.isComponentRenderedOnSite(componentPointer.id)
    }

    function isContainsCompWithType(privateServices, parentCompPointer, types) {
        types = _.isArray(types) ? types : [types]
        const childrenArr = privateServices.pointers.components.getChildrenRecursively(parentCompPointer)
        for (let i = 0, childCompType; i < childrenArr.length; i++) {
            childCompType = getComponentType(privateServices, childrenArr[i])
            if (_.includes(types, childCompType)) {
                return true
            }
        }
        return false
    }

    function isComponentExist(privateServices, compPointer) {
        return privateServices.dal.isExist(compPointer)
    }

    const buildDefaultComponentStructure = (ps, compType) => documentServicesSchemas.services.buildDefaultComponentStructure(compType)

    function getAncestorByPredicate(ps, compPointer, predicate) {
        return ps.pointers.components.getAncestorByPredicate(compPointer, predicate)
    }

    return {
        buildDefaultComponentStructure,
        /**
         * returns the type/"class" of a component.
         *
         * @function
         * @memberof documentServices.components.component
         *
         * @param {Pointer} componentReference the reference to the component to get its type.
         * @returns {string} the name of the component Type/"class". 'null' if no corresponding component was found.
         *
         *      @example
         *      const photoType = documentServices.components.getType(myPhotoReference);
         */
        getType: getComponentType,
        /**
         * Returns the Definition of the component by componentType
         *
         * @returns {componentDefinition} the definition schema of the component
         *
         *      @example
         *      const buttonContainerCompRef = documentServices.components.getDefinition('wysiwyg.viewer.components.StripContainerSlideShow');
         * @param string componentType
         */
        getDefinition: getComponentDefinition,
        /**
         *
         */
        getPropertiesItem: getComponentProperties,
        /**
         * Returns the parent Component of a component.
         *
         * @returns {Pointer} the page that the component is in
         * @throws an error in case the '<i>componentReference</i>' is invalid.
         *
         *      @example
         *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
         * @param {ps} ps
         * @param {Pointer} compPointer
         */
        getPage(ps, compPointer) {
            return ps.pointers.full.components.getPageOfComponent(compPointer)
        },
        /**
         * this should be used in every method that adds a component to a container,
         * some containers have other containers in them that the component should be added to
         * @param {ps} privateServices
         * @param {Pointer} containerPointer the container that we want to add a component to
         * @returns {Pointer} a pointer to the container
         */
        getContainerToAddComponentTo,
        /**
         * Returns the parent Component of a component.
         *
         * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
         * @returns {Pointer} the Component Reference of the parent component, or null if no parent component (example - for page)
         * @throws an error in case the '<i>componentReference</i>' is invalid.
         *
         *      @example
         *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
         */
        getContainer: getParent,
        /**
         * Returns all parent Components of a component.
         *
         * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
         * @returns [{Pointer}] array of the Components Reference of the parent components, or empty array when there is no parent component (example - for page)
         * @throws an error in case the '<i>componentReference</i>' is invalid.
         *
         *      @example
         *      const buttonContainerCompRef = documentServices.components.getAncestors(buttonComponentRef);
         */
        getAncestors: getAncestorsFromDisplayed,
        getAncestorsFromFull,
        /**
         * Returns the Siblings array of a component.
         *
         * @param {Pointer} compReference a Component Reference corresponding a component in the document.
         * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's siblings.
         * @throws an Error in case the compReference isn't valid.
         */
        getSiblings,
        /**
         * Returns an array of the repeated items of a component.
         *
         * @param {Pointer} compReference a Component Reference corresponding a component in the document.
         * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's repeated components.
         * @throws an Error in case the compReference isn't valid.
         */
        getRepeatedComponents,
        /**
         * returns the children components of a parent component, that should be displayed on render.
         * If a site exists, these are the currently rendered children.
         *
         * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
         * taken from the parent's reference or from the current view mode of the document.
         * @returns {Pointer[]} an array of the Component's Children (Component) References.
         * @throws an error in case the <i>parentCompReference</i> is invalid.
         *
         *      @example
         *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
         */
        getChildren: getChildComponents,
        /**
         * returns the children components of a parent component, that should be displayed on render.
         * If a site exists, these are the currently rendered children.
         *
         * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
         * taken from the parent's reference or from the current view mode of the document.
         * @returns {Pointer[]} an array of the Component's Children (Component) References.
         * @throws an error in case the <i>parentCompReference</i> is invalid.
         *
         *      @example
         *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
         */
        getChildrenFromFull: getChildComponentsFromFull,
        /**
         * returns the children components of a parent component.
         *
         * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
         * taken from the parent's reference or from the current view mode of the document.
         * @returns {Pointer[]} an array of the Component's Children (Component) References.
         * @throws an error in case the <i>parentCompReference</i> is invalid.
         *
         *      @example
         *      const mainPageChildren = documentServices.components.getAllJsonChildren(mainPageRef);
         */
        getAllJsonChildren,
        /**
         * returns the children tpa components recurse of a parent component.
         *
         * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
         * @param {string} [viewMode] is the view mode of the document (DESKTOP|MOBILE), if not specified will be
         * taken from the parent's reference or from the current view mode of the document.
         * @returns {Pointer[]} an array of the Component's Children (Component) References.
         * @throws an error in case the <i>parentCompReference</i> is invalid.
         *
         *      @example
         *      const viewMode = 'DESKTOP'; // This is optional
         *      const mainPageChildren = documentServices.components.layout.getChildComponents(mainPageRef, viewMode);
         */
        getTpaChildren: getTpaChildComponents,
        getBlogChildren: getBlogChildComponents,
        getAncestorByPredicate,
        isRenderedOnSite,
        isContainsCompWithType,
        isExist: isComponentExist,
        isPageComponent
    }
})
