import _ from 'lodash'

const PUBLIC_DATA_SCOPE = {
    APP: 'APP',
    COMPONENT: 'COMPONENT'
}

function getPublicData(siteData, applicationId, publicDataQuery, pageId) {
    const result = {}

    result[PUBLIC_DATA_SCOPE.APP] = getPublicDataContent(siteData, `tpaData-${applicationId}`, 'masterPage')
    result[PUBLIC_DATA_SCOPE.COMPONENT] = getPublicDataContent(siteData, publicDataQuery, pageId)

    return result
}

function getPublicDataContent(siteData, publicDataQuery, pageId) {
    const publicData = siteData.getDataByQuery(publicDataQuery, pageId)
    if (publicData && publicData.content) {
        return JSON.parse(publicData.content)
    }

    return null
}

function getStyleParams(siteData, styleUtils, styleId, pageId) {
    const {documentType} = siteData.rendererModel.siteInfo
    const {characterSets} = siteData.getDataByQuery('masterPage')

    const themeData = styleUtils.getAllThemeForSDK(siteData, pageId, styleId)
    const styleParams = styleUtils.getStylesForSDK(themeData, styleId, false, siteData.serviceTopology, documentType, characterSets, true)
    return {styleParams}
}

function getExternalId(siteData, compData) {
    return _.get(compData, 'referenceId')
}

export default {
    getStyleParams,
    getPublicData,
    getExternalId
}
