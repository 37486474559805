define([
    'lodash',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/utils/utils'
], function (_, componentDetectorAPI, component, dataModel, dsUtils) {
    'use strict'
    const LOGIN_BAR_TYPE = 'wysiwyg.viewer.components.LoginSocialBar'

    const isValidMenuRef = (ps, menuData) => !!dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(menuData.menuRef))

    const removeBrokenMembersLoginBar = (ps, compPointer) => {
        const compData = dataModel.getDataItem(ps, compPointer)
        const compWithInvalidMenuRef = [compData.menuItemsRef, compData.iconItemsRef].some(menu => menu && !isValidMenuRef(ps, menu))
        if (compWithInvalidMenuRef) {
            component.remove(ps, compPointer)
        }
    }

    return {
        exec(ps) {
            const loginBarComps = componentDetectorAPI.getComponentByType(ps, LOGIN_BAR_TYPE)
            _.forEach(loginBarComps, comp => removeBrokenMembersLoginBar(ps, comp))
        },
        name: 'brokenLoginBarFixer',
        version: 1
    }
})
