import {Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'

const {getPointer, getInnerPointer, getInnerPointerPathRoot, getOriginalPointerFromInner, isSamePointer} = pointerUtils

const createPointersMethods = (): PointerMethods => {
    const getPointerType = (pointer: Pointer): string => {
        switch (pointer.type) {
            case 'DESKTOP':
            case 'MOBILE':
                return _.isEmpty(pointer.innerPath) ? 'component' : 'componentStructure'
            default:
                return pointer.type
        }
    }
    return {
        getPointer,
        getInnerPointer,
        isSamePointer,
        // @ts-ignore
        getInnerPointerPathRoot,
        getOriginalPointerFromInner,
        // @ts-ignore
        getPointerType
    }
}

const createExtension = (): Extension => ({
    name: 'dataAccess',
    createPointersMethods
})

export {createExtension}
