import _ from 'lodash'
import * as tpaComponents from '../../../_internal/tpaComponents'
import PubSubHub from '../classes/PubSubHub'

/**
 * @constructor
 * @param {core.SiteAspectsSiteAPI} siteAPI
 */
const PubSubAspect = function (aspectSiteAPI) {
    this.aspectSiteAPI = aspectSiteAPI
    this.hub = new PubSubHub()
    _.bindAll(this, _.functionsIn(this))
}

PubSubAspect.prototype = {
    publish(appDefId, compId, msgData) {
        const event = msgData.eventKey
        const {isPersistent} = msgData
        const eventListeners = this.hub.getEventListeners(appDefId, event)
        const workerEventListeners = this.hub.getWorkerEventListeners(appDefId, event)
        const dataToPublish = {
            eventType: tpaComponents.common.utils.addPubSubEventPrefix(event),
            intent: 'addEventListener',
            params: {
                data: msgData.eventData,
                name: event,
                origin: compId
            }
        }

        _.forEach(eventListeners, componentId => this._sendDataToComp(componentId, dataToPublish))
        _.forEach(workerEventListeners, workerId => this._sendMessageToWorker(workerId, appDefId, {eventKey: event, eventData: dataToPublish.params}))

        if (isPersistent) {
            const dataToPersist = msgData.eventData
            this.hub.persistData(appDefId, event, compId, dataToPersist)
        }
    },
    subscribe(props) {
        const {compId} = props
        const propsData = props.data
        const event = tpaComponents.common.utils.stripPubSubPrefix(propsData.eventKey)
        const {receivePastEvents} = propsData
        const appDefId = tpaComponents.common.utils.getAppDefId(this.aspectSiteAPI, compId)

        this.hub.addEventListener(appDefId, event, compId)

        if (receivePastEvents) {
            const persistedData = this.hub.getPersistedData(appDefId, event)

            if (!_.isEmpty(persistedData)) {
                const responseMsg = {
                    intent: 'TPA_RESPONSE',
                    callId: props.callId,
                    type: props.type,
                    res: {
                        drain: true,
                        data: persistedData
                    },
                    status: true,
                    compId
                }

                this._sendDataToComp(compId, responseMsg)
            }
        }
    },
    unsubscribe(appDefId, compId, event) {
        const listeners = this.hub.getEventListeners(appDefId, event)

        if (listeners) {
            this.hub.removeEventListener(appDefId, event, compId)
        }
    },
    deleteCompListeners(appDefId, compId) {
        this.hub.deleteCompListeners(appDefId, compId)
    },
    subscribeAsPlatformWorker(appDefId, workerId, eventKey, receivePastEvents, callbackId) {
        this.hub.addWorkerEventListener(appDefId, eventKey, workerId)
        if (receivePastEvents) {
            const persistedData = this.hub.getPersistedData(appDefId, eventKey)
            if (!_.isEmpty(persistedData)) {
                this._sendMessageToWorker(workerId, appDefId, {
                    eventKey,
                    callbackId,
                    eventDataArray: persistedData,
                    invokePastEvents: true
                })
            }
        }
    },
    unsubscribeAsPlatformWorker(appDefId, compId, event) {
        const platformWorkerListeners = this.hub.getWorkerEventListeners(appDefId, event)
        if (platformWorkerListeners) {
            this.hub.removeWorkerEventListener(appDefId, event, compId)
        }
    },
    /**
     * @private
     */
    _sendDataToComp(compId, data) {
        const comp = this.aspectSiteAPI.getComponentById(compId)

        if (comp) {
            comp.sendPostMessage(data)
        }
    },
    _sendMessageToWorker(workerId, appDefId, messageData) {
        const widgetAspect = this.aspectSiteAPI.getSiteAPI().getSiteAspect('WidgetAspect')
        widgetAspect.getWidgetHandler().invokeWorkerSubscribers(workerId, appDefId, messageData)
    }
}

export default PubSubAspect
