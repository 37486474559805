import _ from 'lodash'

function anchorLinkDataResolver(data, getData) {
    const pageId = _.startsWith(data.pageId, '#') ? data.pageId.replace('#', '') : data.pageId
    const anchorDataId = getData(data.anchorDataId, pageId)
    const pageData = getData(data.pageId, 'masterPage')
    const resolvedData = _.pickBy({anchorDataId, pageId: pageData})
    return _.defaults(resolvedData, data)
}

export default {
    resolve: anchorLinkDataResolver
}
