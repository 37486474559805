define([
    'lodash',
    'documentServices/wixCode/utils/constants',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/services/markAppImmutable',
    'documentServices/wixCode/services/kibanaReporterWrapper'
], function (_, constants, clientSpecMapUtils, markAppImmutable, kibana) {
    'use strict'

    const dataProviderFromSnapshotDal = snapshotDal => {
        const [type, id] = constants.paths.REVISION_GRID_APP_ID
        const gridAppIdPointer = {type, id}
        const data = {
            clientSpecMap: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}),
            isAppReadOnly: _.get(snapshotDal.getValue({type: 'wixCode', id: 'wixCode'}), constants.paths.IS_APP_READ_ONLY.slice(1)),
            getBaseUrl(key) {
                return snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'})[key]
            },
            metasiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
            gridAppId: _.get(snapshotDal.getValue(gridAppIdPointer), constants.paths.REVISION_GRID_APP_ID.slice(2))
        }
        return data
    }

    const getDataProvider = (currentSnapshot, currentSnapshotDal) => {
        const dataProvider = dataProviderFromSnapshotDal(currentSnapshotDal)

        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromDataProvider(dataProvider)
        const wixCodeInstanceId = wixCodeApp ? wixCodeApp.instanceId : ''
        return Object.assign(dataProvider, {
            wixCodeApp,
            wixCodeInstanceId,
            baseUrl: dataProvider.getBaseUrl(constants.WIX_CODE_SERVICE_URL_KEY),
            traceFunc: params => kibana.traceWithSnapshot(currentSnapshot, params, currentSnapshotDal)
        })
    }

    const createRunTask = () => (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) => {
        const currentDataProvider = getDataProvider(currentSnapshot, currentSnapshotDal)

        const runUsingSnapshot = async () => {
            if (!currentDataProvider.wixCodeApp) {
                return
            }
            await markAppImmutable.runUsingSnapshot(currentDataProvider)
            if (!currentDataProvider.isAppReadOnly) {
                return {changes: [{path: constants.paths.IS_APP_READ_ONLY, value: true}]}
            }
        }

        runUsingSnapshot().then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    }

    const createTask = () => {
        const runTask = createRunTask()
        const task = {
            partialSave: runTask,
            fullSave: runTask,
            firstSave: runTask,
            saveAsTemplate: runTask,
            autosave: (lastSavedState, currentState, resolve) => {
                resolve()
            },
            publish(currentData, resolve) {
                resolve()
            },
            getTaskName() {
                return 'markAppImmutable'
            },
            getSnapshotTags() {
                return ['primary']
            }
        }
        return task
    }

    const taskWithSnapshotDal = createTask()
    return () => taskWithSnapshotDal
})
