define(['documentServices/platform/platform', 'documentServices/wixCode/services/fileSystemAPI', 'documentServices/wixCode/utils/constants'], function (
    platform,
    fileSystemAPI,
    constants
) {
    'use strict'

    function getFileId(ps, pageId) {
        const pagesRoot = fileSystemAPI.getRoots(ps).pages.location
        return `${pagesRoot}${pageId}${constants.JS_EXTENSION}`
    }

    function hasCode(ps, pageId) {
        return platform.pageHasPlatformApp(ps, pageId, constants.WIX_CODE_APP_ID)
    }

    function readCode(ps, pageId) {
        const fileId = getFileId(ps, pageId)
        const descriptor = fileSystemAPI.getVirtualDescriptor(ps, fileId, false)
        return fileSystemAPI.readFile(ps, descriptor)
    }

    return {
        getFileId,
        hasCode,
        readCode
    }
})
