define([
    'lodash',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/appStudioWidgets/appStudioWidgets',
    'documentServices/component/componentCode'
], function (_, refComponentUtils, appStudioWidgets, componentCode) {
    'use strict'

    /**
     * Returns ref nickname taken from its connection override(appWidget nickname),
     * in case it's not rendered and getChildren returns empty
     * @param {ps} ps
     * @param compRef of type wysiwyg.viewer.components.RefComponent
     * @return {*}
     */
    const getRefNickname = (ps, compRef) => {
        const [connectionItem] = appStudioWidgets.getPrimaryConnectionItems(ps, compRef)
        return _.get(connectionItem, 'role')
    }

    const getNickname = (ps, compRef) => {
        const isRefComp = refComponentUtils.isRefHost(ps, compRef)
        return isRefComp ? getRefNickname(ps, compRef) : componentCode.getNickname(ps, compRef)
    }

    return {
        get: getNickname
    }
})
