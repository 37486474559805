import _ from 'lodash'
import santaComponents from '@wix/santa-components'
import coreUtilsLib from '@wix/santa-core-utils'
import tpaCompBaseMixin from '../mixins/tpaCompBaseMixin'
import tpaUrlBuilderMixin from '../mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '../mixins/tpaCompApiMixin'
import tpaSectionMixin from '../mixins/tpaSectionMixin'
import tpaResizeWindowMixin from '../mixins/tpaResizeWindowMixin'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {compRegistrar} = componentsCore

/**
 * @class components.TPAMultiSection
 * @extends {tpa.mixins.tpaCompBase}
 * @extends {tpa.mixins.tpaUrlBuilder}
 * @extends {tpa.mixins.tpaCompAPI}
 * @extends {ReactCompositeComponent}
 * @property {comp.properties} props
 */
const TPAMultiSection = {
    displayName: 'TPAMultiSection',
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
    },
    mixins: [
        componentsCore.mixins.skinBasedComp,
        coreUtilsLib.timersMixins.timeoutsMixin,
        tpaCompBaseMixin,
        tpaUrlBuilderMixin,
        tpaCompApiMixin,
        tpaSectionMixin,
        tpaResizeWindowMixin
    ],
    getBaseUrl() {
        const appData = this.getAppData()
        let {sectionUrl} = appData
        const {widgetId} = this.props.compData

        const widget = appData.widgets[widgetId]
        let {sectionDefaultPage} = appData

        if (widget) {
            if (this.isUnderMobileView() && this.isMobileReady()) {
                sectionUrl = this.getTPAOverrideUrl('tpaMobileUrlOverride', widgetId) || widget.mobileUrl
            } else {
                sectionUrl = this.getTPAOverrideUrl('tpaWidgetUrlOverride', widgetId) || widget.widgetUrl
            }

            sectionDefaultPage = widget.appPage.defaultPage
        }

        if (sectionDefaultPage && !_.isEmpty(sectionDefaultPage)) {
            if (sectionUrl.slice(-1) !== '/') {
                sectionUrl += '/'
            }

            sectionUrl += sectionDefaultPage
        }

        return sectionUrl
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAMultiSection', TPAMultiSection)
export default TPAMultiSection
