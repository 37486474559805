define([
    'lodash',
    'documentServices/hooks/hooks',
    'documentServices/constants/constants',
    'documentServices/routers/utils/constants',
    'documentServices/page/pageData',
    'documentServices/utils/runtimeConfig',
    'documentServices/atomicScopes/atomicScopesUtils',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, hooks, constants, routerConstants, pageData, runtimeConfig, utils, extensionsAPI) {
    'use strict'

    const {ROUTER_TYPE} = routerConstants
    const {
        PAGE_TYPES,
        ATOMIC_SCOPES: {URI_PREFIX}
    } = constants
    const {getPageUriAtomicScopeId, getRouterAtomicScopeId, addAtomicScope, removeAtomicScope, changeAtomicScope} = utils

    const initialize = ps => {
        if (!runtimeConfig.isSanta(ps)) {
            hooks.registerHook(hooks.HOOKS.DATA.UPDATE_BEFORE, beforePageDataChanged, PAGE_TYPES.MOBILE_PAGE)
            hooks.registerHook(hooks.HOOKS.ADD_PAGE.AFTER, afterPageAdd, PAGE_TYPES.MOBILE_PAGE)
            hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, beforePageRemove, PAGE_TYPES.MOBILE_PAGE)

            hooks.registerHook(hooks.HOOKS.ROUTER.AFTER_ADD, afterRouterAdd, ROUTER_TYPE)
            hooks.registerHook(hooks.HOOKS.ROUTER.BEFORE_UPDATE, beforeRouterUpdate, ROUTER_TYPE)
            hooks.registerHook(hooks.HOOKS.ROUTER.BEFORE_REMOVE, beforeRouterRemove, ROUTER_TYPE)

            hooks.registerHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_AFTER, afterNicknameChanged)
            hooks.registerHook(hooks.HOOKS.ADD.AFTER, afterComponentAdd)
        }
    }

    const NICKNAMES_KEY_PREFIX = 'wcid_'
    const CLEAR_NICKNAMES_DEBOUNCE_TIME = 60000
    const DELETE_NICKNAMES_COUNT_THRESHOLD = 200

    const getAllNicknameKeys = ps => extensionsAPI.data.queryKeys(ps, 'atomicScopes', null, (value, key) => value && key.startsWith(NICKNAMES_KEY_PREFIX))

    const clearAtomicNicknames = ps => {
        const keys = getAllNicknameKeys(ps)
        if (keys.length >= DELETE_NICKNAMES_COUNT_THRESHOLD) {
            keys.forEach(id => {
                removeAtomicScope(ps, id)
            })
        }
    }

    const getAtomicScopeNicknameKey = nickname => `${NICKNAMES_KEY_PREFIX}${nickname}`

    const debounce = (() => {
        let timeoutId
        return (callback, delay) => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(callback, delay)
        }
    })()

    const addNicknameToAtomicScopes = (ps, nickname) => {
        addAtomicScope(ps, getAtomicScopeNicknameKey(nickname))
        debounce(() => clearAtomicNicknames(ps), CLEAR_NICKNAMES_DEBOUNCE_TIME)
    }

    const afterComponentAdd = (ps, pointer, serializedComp) => {
        const nickname = serializedComp.connections?.items?.find(e => e.type === 'WixCodeConnectionItem')?.role
        if (nickname) {
            addNicknameToAtomicScopes(ps, nickname)
        }
    }

    const afterNicknameChanged = (ps, pointer, nickname) => {
        addNicknameToAtomicScopes(ps, nickname)
    }

    const afterPageAdd = (ps, pagePointer) => {
        const id = getPageUriAtomicScopeId(ps, pagePointer.id)
        addAtomicScope(ps, id)
    }

    const beforePageDataChanged = (ps, pagePointer, dataToChange) => {
        const {pageUriSEO} = dataToChange
        const currentPageUriSEO = pageData.getPageUriSEO(ps, pagePointer.id)
        if (pageUriSEO && !_.isEqual(pageUriSEO, currentPageUriSEO)) {
            const newId = `${URI_PREFIX}-${pageUriSEO}`
            const oldId = `${URI_PREFIX}-${currentPageUriSEO}`
            changeAtomicScope(ps, oldId, newId)
        }
    }

    const beforePageRemove = (ps, pagePointer) => {
        const id = getPageUriAtomicScopeId(ps, pagePointer.id)
        removeAtomicScope(ps, id)
    }

    const beforeRouterUpdate = (ps, routerPointer, dataToChange) => {
        const {prefix} = dataToChange
        const currentRouterData = ps.dal.get(routerPointer)
        const currentPrefix = currentRouterData.prefix
        if (prefix && !_.isEqual(prefix, currentPrefix)) {
            const newId = `${URI_PREFIX}-${prefix}`
            const oldId = `${URI_PREFIX}-${currentPrefix}`
            changeAtomicScope(ps, oldId, newId)
        }
    }

    const afterRouterAdd = (ps, routerPointer) => {
        const id = getRouterAtomicScopeId(ps, routerPointer)
        addAtomicScope(ps, id)
    }

    const beforeRouterRemove = (ps, routerPointer) => {
        const id = getRouterAtomicScopeId(ps, routerPointer)
        removeAtomicScope(ps, id)
    }

    return {
        initialize,
        _atomicScopeNicknames: {
            getKey: getAtomicScopeNicknameKey,
            getAllNicknameKeys
        }
    }
})
