define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/constants/constants',
    'document-services-schemas',
    'documentServices/utils/utils'
], function (_, dataModel, componentsMetaData, constants, documentServicesSchemas, dsUtils) {
    'use strict'

    const {schemasService} = documentServicesSchemas.services

    function getDesktopComp(privateServices, mobileCompPointer) {
        return privateServices.pointers.full.components.getDesktopPointer(mobileCompPointer)
    }

    function shouldGetDesktopComp(privateServices, mobileCompPointer) {
        return !componentsMetaData.public.isMobileOnly(privateServices, mobileCompPointer)
    }

    /**
     * Resets the properties item for the mobile component.
     * @param {ps} privateServices
     * @param {Pointer} mobileCompPointer
     */
    function resetMobileComponentProperties(privateServices, mobileCompPointer) {
        if (!privateServices || !mobileCompPointer || !shouldGetDesktopComp(privateServices, mobileCompPointer)) {
            return
        }

        const equivalentCompInDesktop = getDesktopComp(privateServices, mobileCompPointer)
        const desktopPropsItem = dataModel.getPropertiesItem(privateServices, equivalentCompInDesktop)

        dataModel.deletePropertiesItem(privateServices, mobileCompPointer)
        dataModel.updatePropertiesItem(privateServices, mobileCompPointer, desktopPropsItem)
    }

    function splitMobileComponentProperties(privateServices, mobileCompPointer) {
        if (!privateServices || !mobileCompPointer || !shouldGetDesktopComp(privateServices, mobileCompPointer)) {
            return
        }

        const equivalentCompInDesktopPointer = getDesktopComp(privateServices, mobileCompPointer)
        const clonedDesktopPropsItem = dataModel.getPropertiesItem(privateServices, equivalentCompInDesktopPointer)
        const desktopProps = privateServices.dal.full.get(privateServices.pointers.getInnerPointer(equivalentCompInDesktopPointer, 'propertyQuery'))
        const desktopPropsItemId = desktopProps && dsUtils.stripHashIfExists(desktopProps)
        const mobilePropsItemId = constants.DOM_ID_PREFIX.MOBILE + desktopPropsItemId

        dataModel.setPropertiesItem(privateServices, mobileCompPointer, clonedDesktopPropsItem, mobilePropsItemId)
        privateServices.dal.set(privateServices.pointers.getInnerPointer(mobileCompPointer, 'propertyQuery'), mobilePropsItemId)
    }

    /**
     * Checks if component has split properties - mobile and desktop.
     * @param {ps} ps
     * @param {Pointer} mobileCompPointer
     * @returns {boolean} true if component has split properties
     */
    function isMobileComponentPropertiesSplit(ps, mobileCompPointer) {
        return mobileDataItemCompare(ps, mobileCompPointer, dataModel.getPropertyItemPointer)
    }

    function isMobileDesignDataSplit(ps, mobileCompPointer) {
        return mobileDataItemCompare(ps, mobileCompPointer, dataModel.getDesignItemPointer)
    }

    function mobileDataItemCompare(privateServices, mobileCompPointer, dataItemGetterFunction) {
        if (componentsMetaData.public.isMobileOnly(privateServices, mobileCompPointer)) {
            return true
        }

        const desktopComp = getDesktopComp(privateServices, mobileCompPointer)
        const mobileDataItem = dataItemGetterFunction(privateServices, mobileCompPointer)
        const desktopDataItem = dataItemGetterFunction(privateServices, desktopComp)
        const mobileDataItemId = mobileDataItem && mobileDataItem.id
        const desktopDataItemId = desktopDataItem && desktopDataItem.id

        return !_.isEqual(mobileDataItemId, desktopDataItemId)
    }

    /**
     * @param {ps} privateServices
     * @param {*} componentType
     */
    function getComponentDataSchemasByType(privateServices, componentType) {
        if (privateServices && componentType) {
            const compDefinition = schemasService.getDefinition(componentType)
            if (!compDefinition) {
                throw new Error(`component type: ${componentType} is unsupported`)
            }
            const dataTypes = compDefinition.dataTypes || []
            return _.map(dataTypes, dataType => dataModel.getDataSchemaByType(privateServices, dataType))
        }
        return null
    }

    /**
     * @param {ps} privateServices
     * @param componentType
     */
    function getComponentPropertiesByType(privateServices, componentType) {
        if (privateServices && componentType) {
            const compDefinition = schemasService.getDefinition(componentType)
            if (!compDefinition) {
                throw new Error(`component type: ${componentType} is unsupported`)
            }
            const propType = _(compDefinition.propertyTypes || [])
                .without('')
                .find()
            if (propType) {
                return dataModel.getPropertiesSchemaByType(privateServices, propType)
            }
        }
        return null
    }

    return {
        getComponentDataSchemasByType,
        getComponentPropertiesByType,
        /**
         * Resets a separation of a component properties in mobile & desktop structures if exists.
         *
         * @function
         * @memberof documentServices.components.componentData
         *
         * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
         * @returns undefined
         *
         *      @example
         *      documentServices.components.properties.mobile.join(mobileComponentRef);
         */
        resetMobileComponentProperties,
        /**
         * Performs a separation of the properties of a component from its mobile structure and desktop.
         * (new properties data item will be created for the mobile structure, separated from the desktop's).
         *
         * @function
         * @memberof documentServices.components.componentData
         *
         * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
         * @returns undefined
         *
         *      @example
         *      documentServices.components.properties.mobile.fork(mobileComponentRef);
         */
        splitMobileComponentProperties,
        /**
         * Checks if separation exists between component Desktop & Mobile Properties items.
         *
         * @function
         * @memberof documentServices.components.componentData
         *
         * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
         * @returns {Boolean} true iff the corresponding Component's Mobile Properties & Desktop Properties Items are different instances.
         */
        isMobileComponentPropertiesSplit,
        isMobileDesignDataSplit
    }
})
