import * as _ from 'lodash'
import {Axis, Event, Component, ComponentWithConversionData, MobileHints, MobileHintsAuthor, MobilePreset, ObjMap, OffsetOrigin} from '../types'
import * as sweepSortAnalyzer from './analyzer/sweepSortAnalyzer'
import {conversionUtils} from './mobileCore'

const toEvents = (components, axis: Axis): Event[] => sweepSortAnalyzer.createEventsQueue(<any>components, axis)
const toFragments = (events: Event[]): any[] => _.map(sweepSortAnalyzer.createFragments(events), 'comps')

const getPresetAuthor = (preset: MobilePreset): MobileHintsAuthor | undefined =>
    //@ts-expect-error
    _.get<MobileHintsAuthor | undefined>(preset, ['metaData', 'author'])

export interface PresetWithOffsetOrigin {
    preset: MobilePreset
    offsetOrigin: OffsetOrigin
}

export function calculateRootMobileHints(preset: MobilePreset, ignoreWidgetsSize?: boolean): ObjMap<MobileHints> {
    const originalCompId = _.get(preset, ['metaData', 'originalCompId'])
    const author = getPresetAuthor(preset) || 'studio'
    const {
        layout: {width: recommendedWidth, height: recommendedHeight, scale: recommendedScale}
    } = preset

    const isAppWidget = conversionUtils.isAppWidgetComponent(preset as unknown as Component)

    return <ObjMap<MobileHints>>_.assign(
        {author},
        isAppWidget && ignoreWidgetsSize
            ? {}
            : {
                  recommendedWidth,
                  recommendedHeight,
                  recommendedScale
              },
        originalCompId ? {originalCompId} : {}
    )
}

export function calculateOffsetsMobileHints(previousPreset: MobilePreset | null, currentPreset: MobilePreset, offsetOrigin: OffsetOrigin): MobileHints {
    if (!previousPreset) {
        return {
            offsetX: currentPreset.layout.x,
            offsetY: currentPreset.layout.y,
            offsetOrigin: 'leftTop'
        }
    }
    if (offsetOrigin === 'rightTop') {
        return {
            offsetX: currentPreset.layout.x - previousPreset.layout.x - previousPreset.layout.width,
            offsetY: currentPreset.layout.y - previousPreset.layout.y,
            offsetOrigin
        }
    }
    if (offsetOrigin === 'leftBottom') {
        return {
            offsetX: currentPreset.layout.x - previousPreset.layout.x,
            offsetY: currentPreset.layout.y - previousPreset.layout.y - previousPreset.layout.height,
            offsetOrigin
        }
    }
    return {
        offsetX: currentPreset.layout.x - previousPreset.layout.x,
        offsetY: currentPreset.layout.y - previousPreset.layout.y,
        offsetOrigin: 'leftTop'
    }
}

function getOffsetOrigin(verticalFragmentIndex: number, horizontalFragmentIndex = 0, componentFragmentIndex = 0): OffsetOrigin {
    const verticalFragmentOrigin = verticalFragmentIndex ? 'leftBottom' : 'leftTop'
    const horizontalFragmentOrigin = horizontalFragmentIndex ? 'rightTop' : verticalFragmentOrigin
    return componentFragmentIndex ? 'leftTop' : horizontalFragmentOrigin
}

export function sortPresets(presets: MobilePreset[]): PresetWithOffsetOrigin[] {
    const presetsNaturalOrder = _.map(presets, 'id')
    const yEvents = toEvents(presets, 'y')
    const verticalFragments = toFragments(yEvents)

    return _.reduce(
        verticalFragments,
        (res, verticalFragment, verticalFragmentIndex) => {
            if (_.size(verticalFragment) === 1) {
                return [
                    ...res,
                    {
                        preset: verticalFragment[0],
                        offsetOrigin: getOffsetOrigin(verticalFragmentIndex)
                    }
                ]
            }

            const xEvents = toEvents(verticalFragment, 'x')
            const horizontalFragments = toFragments(xEvents)

            return _.reduce(
                horizontalFragments,
                (res2, horizontalFragment, horizontalFragmentIndex) => {
                    if (_.size(horizontalFragment) === 1) {
                        return [
                            ...res2,
                            {
                                preset: horizontalFragment[0],
                                offsetOrigin: getOffsetOrigin(verticalFragmentIndex, horizontalFragmentIndex)
                            }
                        ]
                    }

                    const sortedFragment = _.sortBy(horizontalFragment, preset => presetsNaturalOrder.indexOf((<any>preset).id))
                    const sortedFragmentWithOffsetOrigin = _.map(sortedFragment, (component, componentIndex) => {
                        return {
                            preset: component,
                            offsetOrigin: getOffsetOrigin(verticalFragmentIndex, horizontalFragmentIndex, componentIndex)
                        }
                    })
                    return [...res2, ...sortedFragmentWithOffsetOrigin]
                },
                res
            )
        },
        []
    )
}

function parseChildMobilePresets(mobilePreset: MobilePreset, ignoreWidgetsSize?: boolean): ObjMap<MobileHints> {
    const childrenMobileHintsMaps = <Array<ObjMap<MobileHints>>>(
        _.map(mobilePreset.components, component => parseChildMobilePresets(component, ignoreWidgetsSize))
    )
    const sortedPresetsWithOffsetOrigin = sortPresets(mobilePreset.components)

    const mobileHintsMap = sortedPresetsWithOffsetOrigin.reduce((hintMap, preset: PresetWithOffsetOrigin, orderIndex) => {
        const previousPreset = <MobilePreset>_.get(sortedPresetsWithOffsetOrigin, [orderIndex - 1, 'preset'], null)
        const rootMobileHints = calculateRootMobileHints(preset.preset, ignoreWidgetsSize)
        const offsetPresets = calculateOffsetsMobileHints(previousPreset, preset.preset, preset.offsetOrigin)

        const mobileHints = {...rootMobileHints, ...offsetPresets, orderIndex}
        return _.set(hintMap, preset.preset.id, mobileHints)
    }, <ObjMap<MobileHints>>{})

    return <ObjMap<MobileHints>>_.assign(mobileHintsMap, ...childrenMobileHintsMaps)
}

function getMobilePreset(desktopStructure: Component): MobilePreset | undefined {
    if (!desktopStructure.mobileStructure) {
        return
    }

    const mobilePreset = <MobilePreset>(
        _.assign(
            {metaData: {}},
            _.pick(desktopStructure, ['id', 'componentType', 'type']),
            _.pick(desktopStructure.mobileStructure, ['layout']),
            _.pick(desktopStructure.mobileStructure, 'metaData'),
            _.pick(desktopStructure.mobileStructure, ['metaData']),
            _.pick(desktopStructure.mobileStructure, ['parent'])
        )
    )
    if (_.has(desktopStructure, 'components')) {
        const childrenPresetStructures = _(desktopStructure.components).map(getMobilePreset).compact().value()
        _.set(mobilePreset, 'components', childrenPresetStructures)
    }

    return mobilePreset
}

export function parseMobilePreset(desktopStructure: Component, ignoreWidgetsSize?: boolean): ObjMap<MobileHints> {
    const mobilePreset = getMobilePreset(desktopStructure)
    if (!mobilePreset) {
        return {}
    }
    return parseMobileStructure(mobilePreset, ignoreWidgetsSize)
}

export function parseMobileStructure(mobileStructure: MobilePreset, ignoreWidgetsSize?: boolean): ObjMap<MobileHints> {
    const mobileHintsMap = mobileStructure.components ? parseChildMobilePresets(mobileStructure, ignoreWidgetsSize) : {}
    _.set(mobileHintsMap, mobileStructure.id, calculateRootMobileHints(mobileStructure, ignoreWidgetsSize))
    return mobileHintsMap
}

export function getConversionOrder(components: ComponentWithConversionData[]): string[] {
    const componentsNaturalOrder = _.map(components, 'id')
    const yEvents = toEvents(components, 'y')
    const verticalFragments = toFragments(yEvents)

    const orderedComponents = _.reduce(
        verticalFragments,
        (sortedComponents, verticalFragment) => {
            if (_.size(verticalFragment) === 1) {
                return [...sortedComponents, ...verticalFragment]
            }

            const xEvents = toEvents(verticalFragment, 'x')
            const horizontalFragments = toFragments(xEvents)

            return _.reduce(
                horizontalFragments,
                (res, horizontalFragment) => {
                    if (_.size(horizontalFragment) === 1) {
                        return [...res, ...horizontalFragment]
                    }

                    const sortedOverlappingComponents = _.sortBy(horizontalFragment, component => componentsNaturalOrder.indexOf((<any>component).id))
                    return [...res, ...sortedOverlappingComponents]
                },
                sortedComponents
            )
        },
        []
    )
    return <string[]>_.map(orderedComponents, 'id')
}

export const testAPI = {
    calculateRootMobileHints,
    calculateOffsetsMobileHints,
    getOffsetOrigin,
    getMobilePreset,
    sortPresets,
    parseChildMobilePresets
}
