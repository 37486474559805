define(['@wix/santa-core-utils', 'lodash'], function (santaCoreUtils, _) {
    'use strict'

    return {
        /**
         * @param {ps} ps
         * @returns {boolean}
         */
        get(ps) {
            const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
            const dataItem = ps.dal.get(masterPageDataPointer)
            const reorderFlag = _.get(dataItem, 'autoDomReorder')

            return _.isUndefined(reorderFlag) ? true : reorderFlag
        },
        /**
         * @param {ps} ps
         * @param {boolean} enabled
         */
        set(ps, enabled) {
            if (!_.isBoolean(enabled)) {
                throw new Error('autoDomReorder.set accepts only boolean value')
            }

            const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
            const dataItem = ps.dal.get(masterPageDataPointer) || {}

            ps.dal.set(masterPageDataPointer, _.assign(dataItem, {autoDomReorder: enabled}))
        }
    }
})
