define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/component/componentModes',
    'documentServices/utils/utils'
], function (_, santaCoreUtils, documentModeInfo, componentModes, dsUtils) {
    'use strict'

    function getSOSPModes(ps) {
        const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
        return componentModes.getComponentModesByType(ps, masterPagePointer, santaCoreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES)
    }

    function removeSOSPMode(ps, modeId) {
        const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
        componentModes.removeComponentMode(ps, masterPagePointer, modeId)
    }

    function isShowOnSomePages(ps, compPointer, sospModesIds) {
        const compOverrides = componentModes.overrides.getAllOverrides(ps, compPointer)
        return _.some(compOverrides, override => !_(override.modeIds).intersection(sospModesIds).isEmpty())
    }

    function getShowOnSomePagesRoot(ps, compPointer) {
        if (!ps.pointers.components.isInMasterPage(compPointer)) {
            return null
        }

        const sospModes = getSOSPModes(ps)

        if (_.isEmpty(sospModes)) {
            return null
        }

        const sospModesIds = _.map(sospModes, 'modeId')

        let compToCheck = compPointer
        while (!isShowOnSomePages(ps, compToCheck, sospModesIds)) {
            compToCheck = ps.pointers.components.getParent(compToCheck)
            if (!compToCheck) {
                return null
            }
        }

        return compToCheck
    }

    function getSOSPRootMode(ps, sospRoot) {
        const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
        const sospModesIds = _.map(getSOSPModes(ps), 'modeId')
        const compOverrides = componentModes.overrides.getAllOverrides(ps, sospRoot)
        const sospOverride = _.find(compOverrides, override => !_(override.modeIds).intersection(sospModesIds).isEmpty())

        return componentModes.getComponentModeById(ps, masterPagePointer, sospOverride.modeIds[0])
    }

    function getSOSPModePagesGroupId(sospModeDefinition) {
        return dsUtils.stripHashIfExists(_.get(sospModeDefinition, ['settings', 'pagesGroupId'], ''))
    }

    function getComponentPagesGroupId(ps, compPointer) {
        const sospRoot = getShowOnSomePagesRoot(ps, compPointer)
        const sospMode = sospRoot ? getSOSPRootMode(ps, sospRoot) : null

        return getSOSPModePagesGroupId(sospMode)
    }

    return {
        getSOSPModes,
        removeSOSPMode,
        getShowOnSomePagesRoot,
        getComponentPagesGroupId
    }
})
