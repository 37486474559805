define(['documentServices/component/component', 'documentServices/dataModel/dataModel'], function (component, dataModel) {
    'use strict'

    return (ps, compPointer) => ({
        layout: {
            get: () => component.layout.get(ps, compPointer)
        },
        data: {
            get: () => dataModel.getDataItem(ps, compPointer)
        },
        properties: {
            get: () => component.properties.get(ps, compPointer)
        },
        isMobile: () => ps.pointers.components.isMobile(compPointer)
    })
})
