import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

function resolveImageData(data, serviceTopology, packageName) {
    const url = data.src
    const {scriptsLocationMap} = serviceTopology
    if (!scriptsLocationMap || !url) {
        return data
    }
    const appBasedPath = packageName === 'ecommerce' ? scriptsLocationMap.ecommerce : `${scriptsLocationMap.wixapps}/javascript/wixapps/apps/${packageName}/`

    const match = url.match(/^(http:\/\/)?(images\/.*)/)
    data.src = match ? coreUtils.urlUtils.joinURL(appBasedPath, match[2]) : url

    return data
}

export default {
    resolveImageData
}
