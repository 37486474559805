import {structureRefTypes, dataRefTypes} from '../../utils/refStructureUtils'
export const namespacesWithoutTypeAndId = new Set([
    'documentServicesModel',
    'coBranding',
    'rendererModel',
    'wixCode',
    'deletedPagesMap',
    'appsState',
    'save',
    'contactFormsMetaData',
    'serviceTopology',
    'pagesPlatformApplications',
    'commonConfig',
    'wixCodeUndoable',
    'platform',
    'testType',
    'renderFlags',
    'semanticAppVersions',
    'renderRealTimeConfigPointer',
    'runtime',
    'platformSharedAPIState',
    'svgShapes',
    'wixCodeWaitForApproval',
    'displayedOnlyComponents',
    'ghostStructure',
    'mobile',
    'blocksPreviewData',
    'wixCodeSharedFileState',
    'viewerRuntime',
    'livePreviewSharedState',
    'partners',
    'ghostControllers',
    'multilingual',
    'activeVariants',
    'DESKTOP-ref',
    'customElementsPointer',
    'platformSharedManifestState',
    'documentServiceModel',
    'orphanPermanentDataNodes',
    //further check needed on:
    'atomicScopes',
    'devSiteAppDefId',
    'lastBuildId',
    ...structureRefTypes,
    ...dataRefTypes
])
