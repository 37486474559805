import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const siteSegments = viewerLibrary.get('documentServices/siteSegments/siteSegments')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    return {
        methods: {
            /**
             * @class documentServices.siteSegments
             */
            siteSegments: {
                getGaps: publicMethodUtils.defineGetter(siteSegments.getGaps),
                updateGaps: publicMethodUtils.defineDataManipulationAction(siteSegments.updateGaps, {
                    isUpdatingAnchors: dsUtils.NO
                }),
                isUsingSectionsLayout: publicMethodUtils.defineGetter(siteSegments.isUsingSectionsLayout),
                getFooter: publicMethodUtils.defineGetter(siteSegments.getFooter),
                getHeader: publicMethodUtils.defineGetter(siteSegments.getHeader),
                getPagesContainer: publicMethodUtils.defineGetter(siteSegments.getPagesContainer),
                getSiteStructure: publicMethodUtils.defineGetter(siteSegments.getSiteStructure),
                getSiteStructureId: publicMethodUtils.defineGetter(siteSegments.getSiteStructureId)
            }
        }
    }
}
