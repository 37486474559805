import type {PublicMethodUtils, ViewerLibrary} from '../../publicAPI/apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const variants = viewerLibrary.get('documentServices/variants/variants')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')

    return {
        methods: {
            components: {
                variants: {
                    get: publicMethodUtils.defineGetter(variants.getByComponentAndType),
                    getPointer: publicMethodUtils.defineGetter(variants.getPointerWithVariants),
                    getAllAffectingVariantsGroupedByVariantType: publicMethodUtils.defineGetter(variants.getAllAffectingVariantsGroupedByVariantType),
                    getAllAffectingVariantsForPresets: publicMethodUtils.defineGetter(variants.getAllAffectingVariantsForPresets),
                    getEnabled: publicMethodUtils.defineGetter(variants.getComponentEnabledVariants)
                }
            },
            variants: {
                create: publicMethodUtils.defineDataManipulationAction(variants.create, {
                    isUpdatingAnchors: dsUtils.NO,
                    getReturnValue: variants.getVariantToAddRef
                }),
                get: publicMethodUtils.defineGetter(variants.getData),
                remove: publicMethodUtils.defineDataManipulationAction(variants.remove),
                hasOverrides: publicMethodUtils.defineGetter(variants.hasOverrides),
                getComponentsWithOverrides: publicMethodUtils.defineGetter(variants.getComponentsWithOverrides),
                enable: publicMethodUtils.defineDataManipulationAction(variants.enable),
                disable: publicMethodUtils.defineDataManipulationAction(variants.disable),
                getHoverType: publicMethodUtils.defineGetter(variants.getHoverType),
                getMobileType: publicMethodUtils.defineGetter(variants.getMobileType),
                getPresetType: publicMethodUtils.defineGetter(variants.getPresetType)
            }
        }
    }
}
