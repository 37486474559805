define([], function () {
    'use strict'

    let _state = null
    let _saveRequested = false

    function getAnotherSaveRequested() {
        return _saveRequested
    }

    function setAnotherSaveRequested(bool) {
        _saveRequested = bool
    }

    function getState() {
        return _state
    }

    function setState(state) {
        _state = state
    }
    return {
        getState,
        setState,
        getAnotherSaveRequested,
        setAnotherSaveRequested
    }
})
