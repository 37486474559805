define([
    'experiment',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/theme/common/constants',
    'documentServices/theme/common/themeDAL',
    'documentServices/theme/textThemes/textThemes'
], function (experiment, coreUtils, themeConstants, themeDAL, textThemes) {
    'use strict'

    const switchToTextTheme = () => experiment.isOpen('dm_migrateToTextTheme')
    const {PROPERTY_TYPE} = themeConstants
    const {fontStringToTextTheme, textThemeToFontString} = coreUtils.fonts

    function get(ps, index) {
        return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.FONT, index)
    }

    function getAll(ps) {
        return themeDAL.getCollection(ps, PROPERTY_TYPE.FONT)
    }

    function set(ps, index, val) {
        return themeDAL.setCollectionItem(ps, PROPERTY_TYPE.FONT, index, val)
    }

    function setToTextTheme(ps, index, val) {
        const textTheme = fontStringToTextTheme(val)
        textThemes.set(ps, index, textTheme)
    }

    function getFromTextTheme(ps, index) {
        const textTheme = textThemes.get(ps, index)
        return textTheme ? textThemeToFontString(textTheme) : get(ps, index)
    }

    function getAllFromTextTheme(ps) {
        const allTextThemes = textThemes.getAll(ps)
        return allTextThemes?.length ? allTextThemes.map(textThemeToFontString) : getAll(ps)
    }

    return {
        set: (...args) => (switchToTextTheme() ? setToTextTheme(...args) : set(...args)),
        get: getFromTextTheme,
        getAll: getAllFromTextTheme
    }
})
