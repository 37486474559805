import _ from 'lodash'
import {
    CreateExtArgs,
    CreateExtensionArgument,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    KeyValPredicate,
    Pointer,
    pointerUtils,
    DalValue,
    DmApis,
    DAL
} from '@wix/document-manager-core'
import {DATA_TYPES, MULTILINGUAL_TYPES} from '../constants/constants'
import {getTranslationItemKey, getTranslationInfoFromKey, getLanguageFromKey} from '../utils/translationUtils'
import {pointerType as rendererModelPointerType} from './rendererModel'
import {ReportableError} from '@wix/document-manager-utils'
import type {RelationshipsAPI} from './relationships'

const {getPointer, getInnerPointer} = pointerUtils
const ALL_TRANSLATIONS = 'ALL_TRANSLATIONS'
const ALL_TRANSLATIONS_MATCH = [ALL_TRANSLATIONS]
const NO_MATCH: string[] = []

const createPointersMethods = () => {
    const multilingualInfo = () => getPointer('sitePropertiesInfo', rendererModelPointerType, ['multilingualInfo'])
    const getMultilingualInnerPointer = (key: string) => getInnerPointer(multilingualInfo(), [key])
    const originalLanguage = () => getMultilingualInnerPointer('originalLanguage')
    const translationLanguages = () => getMultilingualInnerPointer('translationLanguages')
    const translationDataItem = (pageId: string, lang: string, itemId: string) => ({
        pageId,
        ...getPointer(getTranslationItemKey(pageId, lang, itemId), MULTILINGUAL_TYPES.multilingualTranslations)
    })
    const getTranslation = (pointer: Pointer, language: string) => translationDataItem(pointer.pageId!, language, pointer.id)

    return {
        multilingual: {
            originalLanguage,
            translationLanguages,
            multilingualInfo
        },
        multilingualTranslations: {
            translationDataItem,
            getTranslation
        }
    }
}

const getTranslationsById = (dal: DAL, dataId: string) => {
    const pageTranslationKey = dal.queryFilterGetters.getTranslationsFilter(dataId)
    return dal.getIndexPointers(pageTranslationKey, MULTILINGUAL_TYPES.multilingualTranslations)
}

const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): MultilingualExtensionAPI => {
    const {relationships} = extensionAPI as RelationshipsAPI

    const getTranslationsKeys = (predicate?: KeyValPredicate) => {
        const translationsFilter = dal.queryFilterGetters.getTranslationsFilter(ALL_TRANSLATIONS)
        return dal.queryKeys(MULTILINGUAL_TYPES.multilingualTranslations, translationsFilter, predicate)
    }

    const removeTranslations = (predicate: KeyValPredicate) => {
        const translationsKeys = getTranslationsKeys(predicate)

        _.forEach(translationsKeys, (key: string) => {
            dal.remove(getPointer(key, MULTILINGUAL_TYPES.multilingualTranslations))
        })
    }

    const removeTranslationByCompRef = (componentPointer: Pointer, languageCode?: string) => {
        const component = dal.get(componentPointer)
        const dataItemId = component?.dataQuery ? relationships.getIdFromRef(component.dataQuery) : null

        if (dataItemId === null || !dal.has(getPointer(dataItemId, DATA_TYPES.data))) {
            return
        }
        removeTranslations((v, key) => {
            if (languageCode) {
                const [, itemLangCode, itemId] = getTranslationInfoFromKey(key)

                return itemId === dataItemId && itemLangCode === languageCode
            }

            return key.includes(dataItemId)
        })
    }

    const hasTranslations = (languageCode: string) => {
        const typeTranslationFilter = dal.queryFilterGetters.getTranslationsFilter(ALL_TRANSLATIONS)
        const query = dal.queryKeys(MULTILINGUAL_TYPES.multilingualTranslations, typeTranslationFilter)

        return query.some((key: string) => {
            const [, keyLanguageCode] = getTranslationInfoFromKey(key)
            return keyLanguageCode === languageCode
        })
    }

    const getPresentLanguages = (dataId: string) => {
        const presentLanguages = _([
            ..._.map(dal.get(pointers.multilingual.translationLanguages()), 'languageCode'),
            ..._.map(getTranslationsById(dal, dataId), pointer => getLanguageFromKey(pointer.id))
        ])
            .uniq()
            .compact()
            .value()
        return presentLanguages
    }

    return {
        multilingualTranslations: {
            removeAll: () => removeTranslations(() => true),
            remove: (languageCode: string) => removeTranslations((v, key) => getLanguageFromKey(key) === languageCode),
            removeByComponentRef: removeTranslationByCompRef,
            getAllTranslationsKeys: getTranslationsKeys,
            hasTranslations,
            getTranslationsById: (dataId: string) => getTranslationsById(dal, dataId),
            getPresentLanguages
        }
    }
}

export interface MultilingualTranslationsAPI extends ExtensionAPI {
    removeAll(): void
    remove(languageCode: string): void
    removeByComponentRef(pointer: Pointer, languageCode?: string): void
    getAllTranslationsKeys(predicate?: KeyValPredicate): string[]
    hasTranslations(languageCode: string): boolean
    getTranslationsById(dataId: string): Pointer[]
    getPresentLanguages(dataId: string): string[]
}

export interface MultilingualExtensionAPI extends ExtensionAPI {
    multilingualTranslations: MultilingualTranslationsAPI
}

const getDocumentDataTypes = (): DocumentDataTypes => _.mapValues(MULTILINGUAL_TYPES, () => ({hasSignature: true}))

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const createPostSetOperation =
        ({dal}: DmApis) =>
        ({type: namespace, id}: Pointer, value: DalValue) => {
            if (!value && namespace === 'data') {
                _.forEach(getTranslationsById(dal, id), dal.remove)
            }
        }

    const createFilters = () => ({
        getTranslationsFilter: (namespace: string, value: any): string[] => {
            if (namespace !== MULTILINGUAL_TYPES.multilingualTranslations || !value) {
                return NO_MATCH
            }
            if (value.id) {
                return [value.id, ALL_TRANSLATIONS]
            }

            // There should never be a translation without id
            // This is how little faith i have in humanity
            logger.captureError(
                new ReportableError({
                    errorType: 'translationWithoutId',
                    message: 'indexed ML translation without id',
                    extras: {
                        value
                    }
                })
            )

            return ALL_TRANSLATIONS_MATCH
        }
    })

    return {
        name: 'multilingual',
        createPointersMethods,
        getDocumentDataTypes,
        createExtensionAPI,
        createFilters,
        createPostSetOperation
    }
}

export {createExtension}
