import {
    CreateExtArgs,
    CreateExtensionArgument,
    createSnapshotChain,
    DAL,
    DalValue,
    debug,
    DmApis,
    DmStore,
    DSConfig,
    EnvironmentContext,
    Extension,
    ExtensionAPI,
    LoggerDriver,
    Null,
    pointerUtils,
    SnapshotDal,
    store as dmStore,
    TransactionRejectionError
} from '@wix/document-manager-core'
import {Notifier, ReportableError, taskWithRetries} from '@wix/document-manager-utils'
import _ from 'lodash'
import {SNAPSHOTS} from '../../constants/constants'
import type {CreateRevisionRes, CreateTransactionRequest, GetDocumentResponse, PendingTransaction} from '../../types'
import {AsyncQueue, createQueue, QueueFunction} from '../../utils/asyncQueue'
import type {DocumentServicesModelExtApi} from '../documentServicesModel'
import type {RMApi} from '../rendererModel'
import type {SafeRemovalError, SchemaExtensionAPI} from '../schema/schema'
import type {SnapshotApi, SnapshotExtApi} from '../snapshots'
import * as converter from './csaveConverter'
import {removeFromLoadStore} from './csaveDataRules'
import {
    convertToDSError,
    FirstTransactionRejectionError,
    InvalidLastTransactionIdError,
    isNetworkError,
    makeReportable,
    MissingTransactionInServerPayloadError,
    TransactionAlreadyApproveError
} from './csaveErrors'
import type {ContinuousSaveServer, CreateRevArgs, CSaveHooks, Approved, Rejected, StaleEditorEnvironment, ApprovedNonEmpty} from './csaveTypes'
import {Channel, ChannelEvents} from '../channelUtils/duplexer'
import {DuplexerOrderCheck, reportTransactionId} from './duplexerOrderCheck'
import {longGt} from './long'
import {EmptyCSaveExt} from './emptyCSaveExt'
import {GetTransactionRes, MockCEditTestServer} from './MockCEditTestServer'
import {MockCSDuplexer} from './mockCSChannel'
import {RegisterHandlers, registerOnlineHandler} from './registerOnlineHandler'
import {Action, ActionOperation, CreateRevisionReq, GetTransactionsResponse, SaveRequest, Transaction as EDSTransaction} from './serverProtocol'
import {tagsFromError, validateCSaveTransaction} from './validateCSaveTransaction'
import long, {Long} from 'long'

const {createStore} = dmStore
const {getPointer} = pointerUtils

const ACTIONS_TO_AUTOSAVE_COUNT_DENOMINATOR = 15
const ACTIONS_COUNT_THRESHOLD = 6
const AUTOCLAVE_COUNT_THRESHOLD = 9
const MAX_TRANSACTIONS_BASE = 2000

export const actionCountToAutosaveActionCount = (actionCount: number): number => _.floor(actionCount / ACTIONS_TO_AUTOSAVE_COUNT_DENOMINATOR)

export const CS_EVENTS = {
    CSAVE: {
        SITE_SAVED: 'SITE_SAVED',
        DO_CSAVE: 'DO_CSAVE',
        NON_RECOVERABLE_ERROR: 'CAVE_NON_RECOVERABLE_ERROR'
    }
}

let log: LoggerDriver

const CSAVE_TAG = 'CSAVE_TAG'
const CSAVE_PENDING_TAG = 'CSAVE_PENDING_TAG'
const CSAVE_INTERACTION = 'csave'
const CSAVE_VALIDATION_INTERACTION = 'csave-validation'
const CSAVE_TRANSACTION_INTERACTION = 'csave-approval-rate'
const CSAVE_TRANSACTION_REJECTED = 'csave-transaction-rejected'
const channelName = 'dm_transactions'

export interface CSaveApi extends ExtensionAPI {
    continuousSave: {
        connect(): Promise<void>
        createRevision(args: CreateRevArgs, dataToSave: Record<string, any>): Promise<CreateRevisionRes>
        getLastTransactionId(): string | undefined
        initCSave(partialPages?: string[]): Promise<boolean>
        initHooks(saveHooksMap: CSaveHooks): void
        getWrappedHooks(hooks: CSaveHooks): CSaveHooks
        save(): Promise<void>
        saveAndWaitForResult(): Promise<void>
        forceSaveAndWaitForResult(): Promise<void>
        setEnabled(enabled: boolean): void
        setSaving(isSaving: boolean): void
        shouldSave(): boolean
        isCSaveOpen(): boolean
        isCEditOpen(): boolean
        isCreateRevisionOpen(): boolean
        isValidationRecovery(): boolean
        // for development purposes
        getTransactions(afterTransactionId?: string, untilTransactionId?: string, branchId?: string): Promise<GetTransactionsResponse>
        getTransactionsFromLastRevision(untilTransactionId?: string, branchId?: string): Promise<GetTransactionsResponse>
        getStore(branchId?: string, afterTransactionId?: string, untilTransactionId?: string): Promise<GetDocumentResponse>
        approveForeignTransaction(t: GetTransactionRes): Promise<void>
        deleteTx(): Promise<void>
        rejectNext(): void
        registerToTransactionApproved(cb: (txStore: DmStore) => Promise<void>): void
        waitForResponsesToBeProcessed(): Promise<void>
        test(): CSaveTestApi
        onRevisionChange(): void
    }
}

export type CSaveExtApi = CSaveApi['continuousSave']

export class CSaveTestApi {
    constructor(private ext: CSaveExtension) {}

    approveTransaction(correlationId: string, transactionId: string) {
        // @ts-ignore
        this.ext.duplexer?.simulateApproval(correlationId, transactionId)
    }

    simulateOutOfSync() {
        // @ts-ignore
        this.ext.duplexer?.simulateSubscriptionSucceeded({isSynced: false})
    }

    getUnappliedTransactionsCount(): number {
        return this.ext.getUnappliedTransactionsCount()
    }
    getMaxUnappliedTransactionsCount(): number {
        return this.ext.getMaxUnappliedTransactionsCount()
    }
}

const getLastCsaveSnapshot = (snapshots: SnapshotApi): SnapshotDal => snapshots.getLastSnapshotByTagName(CSAVE_TAG) ?? snapshots.getInitialSnapshot()

export function rebase(dal: DAL, snapshots: SnapshotApi, actions: Action[], label: string) {
    const lastSnapshot = getLastCsaveSnapshot(snapshots)
    rebaseFromSnapshot(dal, lastSnapshot, actions, label)
    snapshots.tagSnapshot(CSAVE_TAG, snapshots.getLastSnapshot()!)
}

export function rebaseFromSnapshot(dal: DAL, lastSnapshot: SnapshotDal, actions: Action[], label: string) {
    const store = createStore()
    _.forEach(actions, (action: Action) => {
        store.set({type: action.namespace!, id: action.id!}, action.value)
    })
    dal.rebase(store, lastSnapshot.id, label)
}

const strToLong = (str: string | undefined): Long => (_.isEmpty(str) ? long.ZERO : long.fromString(str!))

const notifierTimeout = 1000 * 60 * 2

interface CreateTransactionReq {
    payload?: SaveRequest
    error?: SafeRemovalError
}

class CSaveExtension implements Extension {
    readonly name: string = 'continuousSave'
    readonly EVENTS: Record<string, any> = CS_EVENTS
    readonly dependencies: ReadonlySet<string> = new Set(['snapshots', 'documentServicesModel', 'rendererModel'])
    private neverSynced: boolean = false
    private saving: boolean = false
    private pendingIndex: number = -1
    private hooks: CSaveHooks = {
        onDiffSaveStarted: _.noop,
        onDiffSaveFinished: _.noop,
        onPartialSaveStarted: _.noop,
        onPartialSaveFinished: _.noop,
        onRefreshRequired: _.noop
    }

    private isEnabled: boolean = true
    private isInitialized: boolean = false
    private validationRecovery = false
    private duplexer: Channel | null = null
    private queue: AsyncQueue = createQueue()

    private firstCorrelationId: string | null = null
    private revisionTransaction: Long = long.ZERO

    constructor(
        private readonly server: ContinuousSaveServer,
        private readonly config: DSConfig,
        private readonly registerHandlers: RegisterHandlers,
        private readonly maxTransactionsModifier: number
    ) {}

    setSaving(isSaving: boolean) {
        log.info('state.saving', isSaving)
        this.saving = isSaving
    }

    getMaxUnappliedTransactionsCount(): number {
        return MAX_TRANSACTIONS_BASE + this.maxTransactionsModifier
    }

    getUnappliedTransactionsCount(): number {
        const last = strToLong(this.server.getLast())
        const result = last.sub(this.revisionTransaction)
        if (result.isNegative()) {
            return 0
        }
        return result.toNumber()
    }

    createExtensionAPI({coreConfig, dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): CSaveApi {
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {siteAPI, rendererModel} = extensionAPI as RMApi
        const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
        const {logger, experimentInstance} = coreConfig
        const {server} = this
        let transactionApprovedCb: (txStore: DmStore) => Promise<void>
        const orderCheck = new DuplexerOrderCheck('0', logger)

        const markForPartialUpdate = (validationRecovery = false) => {
            this.validationRecovery = validationRecovery
            dsSiteApi.setActionsCount(ACTIONS_COUNT_THRESHOLD)
            dsSiteApi.setAutosaveCount(AUTOCLAVE_COUNT_THRESHOLD)
        }

        const onRevisionChange = () => {
            this.revisionTransaction = strToLong(this.server.getLast())
        }

        const checkForUnappliedTransactionThreshold = () => {
            const count = this.getUnappliedTransactionsCount()
            const max = this.getMaxUnappliedTransactionsCount()

            if (count > max) {
                const validationRecovery = false
                markForPartialUpdate(validationRecovery)
            }
        }

        const initUnappliedTransactions = (transactionResult: GetDocumentResponse) => {
            this.revisionTransaction = strToLong(transactionResult.firstTransactionId)
            const count = this.getUnappliedTransactionsCount()
            const max = this.getMaxUnappliedTransactionsCount()
            log.info(`${count} unapplied csave/cedit transactions. Maximum is ${max}`)
        }

        const reportFirstTransactionRejection = (correlationId: string): void => {
            logger.captureError(new FirstTransactionRejectionError(correlationId))
        }

        const notifier = new Notifier<string, void, any>(
            {
                onReject: (correlationId: string) => {
                    if (this.firstCorrelationId && this.firstCorrelationId === correlationId) {
                        reportFirstTransactionRejection(correlationId)
                    }
                    log.info(`Notifier rejected ${correlationId}`)
                },
                onResolve: (correlationId: string) => {
                    log.info(`Notifier approved ${correlationId}`)
                },
                onTimeout: (correlationId: string) => {
                    log.info(`Notifier rejected ${correlationId} Request timed out`)
                }
            },
            notifierTimeout
        )

        const setEnabled = (enabled: boolean) => {
            this.isEnabled = enabled ?? true
        }

        const isSiteEligibleForCSave = (): boolean => {
            const neverSaved = dsSiteApi.getNeverSaved()
            return !neverSaved
        }

        const isSavePermitted = (): boolean => {
            const eligible = isSiteEligibleForCSave()
            const {disableSave} = this.config
            const result = eligible && !disableSave && !this.validationRecovery
            return result
        }

        const shouldAutoSaveFromDsSiteApi = (): boolean => dsSiteApi.getAutosaveInfo()?.shouldAutoSave

        const isCSaveEnabled = (): boolean => {
            const isDraftMode = dsSiteApi.getIsDraft()
            const {disableAutoSave} = this.config
            const isAutosaveOn = !disableAutoSave
            const result = isAutosaveOn && this.isEnabled && shouldAutoSaveFromDsSiteApi()
            return result && !isDraftMode
        }

        const shouldSave = (): boolean => isSavePermitted() && isCSaveEnabled()

        const toPointer = ({id, namespace}: Action) => getPointer(id!, namespace!)

        const actionToDalValue = (action: Action): DalValue => {
            const {id, namespace, value, op} = action
            const pointer = getPointer(id!, namespace!)
            if (!value) {
                return value
            }

            if (!action.basedOnSignature && !dal.hasSignature(pointer)) {
                return value
            }

            const basedOnSignature = action.basedOnSignature ?? (op === ActionOperation.ADD ? undefined : null)
            const metaData = _.assign({}, value.metaData, {basedOnSignature})
            return _.assign({}, value, {metaData})
        }

        const actionsToStore = (actions: Action[]): DmStore => {
            const store = createStore()
            _.forEach(actions, (action: Action) => store.set(toPointer(action), actionToDalValue(action)))
            return store
        }

        const isNewFromTemplate = (): boolean => siteAPI.isTemplate() && !dsSiteApi.getAutosaveInfo()?.shouldAutoSave

        const getOrFetchTransactionIfMissing = async (payload: ApprovedNonEmpty) => {
            if (!payload.transaction) {
                log.info(`fetching remote transaction ${payload.correlationId}`)
                const tx = await this.server.getTransaction(payload.transactionId, dsSiteApi.getBranchId())
                if (!tx?.transaction) {
                    throw new MissingTransactionInServerPayloadError(payload.correlationId, payload.transactionId)
                }
                return tx.transaction
            }
            return payload.transaction
        }

        const handleDuplexerError = (operation: string, error: unknown, extras?: Record<string, string | boolean>) => {
            const err = error as Error
            const baseExtras = error instanceof ReportableError ? error.extras : {}
            const baseTags = error instanceof ReportableError ? error.tags : {}
            const e = new ReportableError({
                message: err.message,
                errorType: 'ERROR_PROCESSING_TRANSACTION',
                tags: {...baseTags, duplexer: true, csaveOp: operation},
                extras: {
                    ...baseExtras,
                    ...extras,
                    reason: {name: err.name, message: err.message},
                    isWixInstanceExpired: rendererModel.isWixInstanceExpired(),
                    isRefreshInOnlineHandlerConducted: experimentInstance.isOpen('dm_refreshWixInstanceInOnlineHandler')
                }
            })
            logger.captureError(e, {tags: e.tags, extras: e.extras})
            this.hooks.onRefreshRequired?.('ERROR_PROCESSING_TRANSACTION')
            throw err
        }

        const processForeignActions = async (actions: Action[], txId: string | null, correlationId?: string) => {
            if (txId && !longGt(txId, server.getLast()!)) {
                log.info(`TransactionAlreadyApproveError correlationId=${correlationId} transactionId=${txId}`)
                logger.captureError(
                    new TransactionAlreadyApproveError({
                        lastTxId: `${server.getLast()}`,
                        transactionId: txId,
                        correlationId: `${correlationId}`,
                        source: 'processForeignTransactions'
                    })
                )
                return dal.getLastApprovedSnapshot().id
            }
            const txStore = actionsToStore(actions)
            if (_.isFunction(transactionApprovedCb)) {
                await transactionApprovedCb(txStore)
            }
            const lastApproved = dal.getLastApprovedSnapshot()
            const snapshot = dal.rebaseForeignChange(txStore, lastApproved.id, correlationId)
            const lastCSave = getLastCsaveSnapshot(snapshots)
            if (lastCSave === lastApproved) {
                dal.tagManager.addSnapshot(CSAVE_TAG, snapshot)
            }
            dal.approve(snapshot.id)
            return snapshot.id!
        }

        const isEmptyTx = (payload: Approved) => _.isNull(payload.transactionId)

        const isNonEmptyTx = (payload: Approved): payload is ApprovedNonEmpty => !isEmptyTx(payload)

        const processForeignApproved = async (payload: Approved) => {
            if (!isNonEmptyTx(payload)) {
                return
            }
            log.info(`processing remote transaction ${payload.correlationId}`)
            const tx = await getOrFetchTransactionIfMissing(payload)
            await processForeignActions(tx.actions!, payload.transactionId, payload.correlationId)
        }

        const getPendingUpTo = (upTo: SnapshotDal): string[] => {
            const pendingSnapshots = dal.getLastApprovedSnapshot()!.createSnapshotChainTo(upTo)
            return _.map(pendingSnapshots, 'id')
        }

        const pendingUpToId = (id: string): string[] => getPendingUpTo(dal._snapshots.findById(id)!)

        const pendingTransactions = (): string[] => getPendingUpTo(dal.getLastSnapshot()!)

        const isLocalTransaction = (transactionId: string) => {
            const pending = pendingTransactions()
            return _.some(pending, (id: string) => id === transactionId)
        }

        const endCSaveTransactionInteraction = () => {
            if (this.config.cedit) {
                logger.interactionEnded(CSAVE_TRANSACTION_INTERACTION)
            }
        }

        const processLocalApproved = ({correlationId}: Approved) => {
            endCSaveTransactionInteraction()
            log.info(`processing local transaction ${correlationId}`)
            // also resolve up to correlationId
            const pendingSnapshots = pendingUpToId(correlationId)
            dal.approve(correlationId)
            pendingSnapshots.forEach(id => notifier.resolve(id))
        }

        const startCSaveTransactionInteraction = () => {
            if (this.config.cedit) {
                logger.interactionStarted(CSAVE_TRANSACTION_INTERACTION)
            }
        }

        const validateTxId = (id: string | null) => {
            if (_.isUndefined(id) || id === '' || id === '0') {
                logger.captureError(new InvalidLastTransactionIdError(id))
            }
        }

        const safeLongGt = (a?: string, b?: string) => a && b && longGt(a, b)

        const isFirstTransaction = () => {
            const last = this.server.getLast()
            // the first transaction could be numbered differently
            return last === dsSiteApi.getAutosaveInfo()?.lastTransactionId
        }

        const isGreaterThanLast = (transactionId: string) => safeLongGt(transactionId, this.server.getLast())

        const updateLastTxId = (transactionId: string | null) => {
            if (transactionId && (isFirstTransaction() || isGreaterThanLast(transactionId))) {
                this.server.setLast(transactionId)
            }
            checkForUnappliedTransactionThreshold()
        }

        function processLastTxId(payload: Approved): void {
            if (isNonEmptyTx(payload)) {
                validateTxId(payload.transactionId)
                updateLastTxId(payload.transactionId)
            }
        }

        const processTransaction = async (payload: Approved): Promise<void> => {
            if (isLocalTransaction(payload.correlationId)) {
                processLocalApproved(payload)
            } else {
                await processForeignApproved(payload)
            }
            processLastTxId(payload)
        }

        const processFullSync = async () => {
            const lastTransactionId = server.getLast()
            log.info('sync from lastTransactionId =', lastTransactionId)
            const branchId = dsSiteApi.getBranchId()
            const result = await server.getTransactions(lastTransactionId, undefined, branchId)
            for (const transaction of result.transactions!) {
                await processTransaction({transaction, transactionId: transaction.transactionId!, correlationId: transaction.metadata!.correlationId!})
            }
        }

        const getProcessFullSync =
            (timeOffline: number): QueueFunction =>
            async (): Promise<void> => {
                try {
                    await taskWithRetries(processFullSync, isNetworkError, 100, 5000)
                } catch (e) {
                    handleDuplexerError('processFullSync', e, {timeOffline: `${timeOffline}`})
                }
            }

        const registerNetworkHandlers = () => {
            if (experimentInstance.isOpen('dm_disableOnlineHandlersInCsave')) {
                return
            }
            if (this.config.cedit) {
                this.registerHandlers(log, (stale: boolean, timeOffline: number) => {
                    if (stale) {
                        this.hooks.onRefreshRequired?.('OUT_OF_SYNC')
                    } else {
                        this.queue.add(getProcessFullSync(timeOffline))
                    }
                })
            }
        }

        const getProcessApproved =
            (payload: Approved): QueueFunction =>
            async (): Promise<void> => {
                try {
                    await processTransaction(payload)
                } catch (e) {
                    handleDuplexerError('processApproved', e as Error, {transactionId: payload.transactionId ?? '', correlationId: payload.correlationId})
                }
            }

        const getProcessRejected =
            (correlationId: string): QueueFunction =>
            (): void => {
                try {
                    if (isLocalTransaction(correlationId)) {
                        log.info(`processed reject for ${correlationId}`)
                        dal.reject(correlationId)
                    } else {
                        log.info(`ignoring remote reject for ${correlationId}`)
                    }
                } catch (e) {
                    handleDuplexerError('processRejected', e as Error, {correlationId})
                } finally {
                    notifier.reject(correlationId, new TransactionRejectionError(correlationId))
                }
            }

        const createAsyncDuplexer = (): Channel => {
            if (this.config.cedit) {
                const branchId = dsSiteApi.getBranchId()
                return this.server.createDuplexer(() => siteAPI.getInstance(), this.config.origin, branchId)
            }
            return new MockCSDuplexer()
        }

        const onApprovedTx = (payload: Approved): void => {
            const {correlationId, transactionId} = payload
            orderCheck.check(transactionId, correlationId, {usingCEdit: this.config.cedit})
            log.info(`approved ${correlationId}`)
            this.queue.add(getProcessApproved(payload))
        }

        const logRejection = (correlationId: string, reason?: string) => {
            const conflictDetected = 'conflict detected: '
            if (reason?.startsWith(conflictDetected)) {
                const json = reason?.substring(conflictDetected.length)
                const details = JSON.parse(json)
                log.info(`rejected ${correlationId} conflict detected: `, details)
            } else {
                log.info(`rejected ${correlationId} ${reason}`)
            }
        }

        const reportRejection = (payload: Rejected): void => {
            const {correlationId, reason} = payload
            logger.interactionStarted(CSAVE_TRANSACTION_REJECTED, {
                tags: {
                    isLocal: isLocalTransaction(correlationId)
                },
                extras: {
                    reason
                }
            })
            logRejection(correlationId, reason)
        }

        const onRejectedTx = (payload: Rejected): void => {
            const {correlationId} = payload
            reportRejection(payload)
            this.queue.add(getProcessRejected(correlationId))
        }

        const loadTransactions = async () => {
            const timeOffline = 0
            this.queue.add(getProcessFullSync(timeOffline))
        }

        const connect = async () => {
            if (!this.duplexer) {
                try {
                    this.duplexer = createAsyncDuplexer()
                    await this.duplexer.subscribe(channelName)
                    this.duplexer.on(ChannelEvents.approved, (payload: Approved) => {
                        if (this.server.getLast()) {
                            onApprovedTx(payload)
                        }
                    })
                    this.duplexer.on(ChannelEvents.rejected, (payload: Rejected) => {
                        onRejectedTx(payload)
                    })
                    this.duplexer.on(ChannelEvents.majorSiteChange, (payload?: StaleEditorEnvironment) => {
                        if (payload?.reason === 'staleEditorEnvironment') {
                            log.info('majorSiteChange event received OUTDATED_VERSION')
                            this.hooks.onRefreshRequired?.('OUTDATED_VERSION')
                        } else {
                            log.info('majorSiteChange event received VERSION_RESTORED')
                            this.hooks.onRefreshRequired?.('VERSION_RESTORED')
                        }
                    })
                    this.duplexer.on(ChannelEvents.outOfSync, async () => {
                        log.info('Duplexer out of sync - loading transactions')
                        try {
                            if (this.neverSynced) {
                                // Do not sync after first save https://jira.wixpress.com/browse/DM-4521
                                // Should be irrelevant after https://jira.wixpress.com/browse/DM-4537
                                this.neverSynced = false
                                return
                            }
                            if (experimentInstance.isOpen('dm_refreshWixInstanceInOnlineHandler') && rendererModel.isWixInstanceExpired()) {
                                await rendererModel.refreshWixInstance()
                            }
                            await loadTransactions()
                        } catch (e) {
                            handleDuplexerError('loadTransactions', e as Error)
                        }
                    })
                } catch (e) {
                    log.error('error connecting to duplexer', e as Error)
                }
            }
        }

        function handleRemovalError(error: SafeRemovalError) {
            const {exception, invalidData, namespace, dataType} = error
            logger.captureError(exception, {
                tags: {
                    additionalProperties: true,
                    namespace,
                    dataType
                },
                extras: {invalidData}
            })
            eventEmitter.emit(CS_EVENTS.CSAVE.NON_RECOVERABLE_ERROR)
        }

        const handleCSaveError = (e: any, fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal) => {
            // if it's a recoverable error, that snapshot will be sent with the next csave,
            // otherwise that snapshot will be reverted and the editor will be notified
            if (isNetworkError(e)) {
                snapshots.removeLastSnapshot(CSAVE_TAG)
            } else {
                dal.reject(toSnapshot.id)
                this.hooks.onDiffSaveFinished?.(convertToDSError(e))
            }
            log.error('Error while trying to save continuously', e)
        }

        const saveSync = async (payload: CreateTransactionRequest, correlationId: string) => {
            payload.correlationId = correlationId
            const {transactionId} = await server.save(payload)
            reportTransactionId(logger, log, transactionId, correlationId, server.getLast())
            processLocalApproved({correlationId, transactionId: transactionId!})
            updateLastTxId(transactionId!)
        }

        const sendSaveToServer = async (payload: SaveRequest, correlationId: string) => {
            log.info('calling server save, cedit =', this.config.cedit)
            if (this.config.cedit) {
                await server.asyncSave(payload)
            } else {
                await saveSync(_.head<CreateTransactionRequest>(payload.transactions)!, correlationId)
            }
        }

        const saveDiff = async (payload: SaveRequest, toSnapshot: SnapshotDal) => {
            const correlationId = toSnapshot.id
            if (!this.firstCorrelationId) {
                this.firstCorrelationId = correlationId
            }
            this.hooks.onDiffSaveStarted?.()

            snapshots.tagSnapshot(CSAVE_TAG, toSnapshot)
            this.pendingIndex = -1

            startCSaveTransactionInteraction()
            await sendSaveToServer(payload, correlationId)

            this.hooks.onDiffSaveFinished?.()

            if (this.pendingIndex > -1) {
                const fromSnap = getLastCsaveSnapshot(snapshots)
                const toSnap = snapshots.getSnapshotByTagAndIndex(CSAVE_PENDING_TAG, this.pendingIndex)
                await saveSnapshotsDiff(fromSnap, toSnap) // eslint-disable-line @typescript-eslint/no-use-before-define
            }
        }

        const createTransaction = (toSnapshot: SnapshotDal) => {
            const siteVersion = `${dsSiteApi.getSiteVersion()}`
            const {result: diff, error} = converter.convertData(
                toSnapshot.getPreviousSnapshot()!,
                toSnapshot,
                logger,
                experimentInstance,
                (extensionAPI as SchemaExtensionAPI).schemaAPI
            )
            if (error) {
                throw error
            }
            const transaction: CreateTransactionRequest = {
                correlationId: toSnapshot.id,
                actions: diff! as Action[],
                metadata: {siteVersion},
                envSessionId: dsSiteApi.getEnvSessionId()
            }
            return transaction
        }

        const createTransactionsCEdit = (fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal): CreateTransactionReq => {
            const chain = createSnapshotChain(fromSnapshot, toSnapshot)
            try {
                const branchId = dsSiteApi.getBranchId()
                const transactions: EDSTransaction[] = _.map(chain, createTransaction)
                const payload = {transactions, branchId}
                return {payload: payload as SaveRequest}
            } catch (e) {
                return {error: e as SafeRemovalError}
            }
        }

        const createTransactionsCSave = (fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal): CreateTransactionReq => {
            const siteVersion = `${dsSiteApi.getSiteVersion()}`
            const {result: diff, error} = converter.convertData(
                fromSnapshot,
                toSnapshot,
                logger,
                experimentInstance,
                (extensionAPI as SchemaExtensionAPI).schemaAPI
            )
            if (error) {
                return {error}
            }
            if (_.isEmpty(diff)) {
                return experimentInstance.isOpen('dm_localApproveEmptyDiffCSave')
                    ? {
                          payload: {
                              transactions: []
                          }
                      }
                    : {}
            }
            const transaction: CreateTransactionRequest | PendingTransaction = {
                actions: diff! as Action[],
                metadata: {lastTransactionId: this.server.getLast(), siteVersion},
                envSessionId: dsSiteApi.getEnvSessionId(),
                branchId: dsSiteApi.getBranchId()
            }
            const payload = {
                transactions: [transaction]
            }
            return {payload: payload as SaveRequest}
        }

        const createTransactions = (fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal): CreateTransactionReq => {
            if (this.config.cedit) {
                return createTransactionsCEdit(fromSnapshot, toSnapshot)
            }
            return createTransactionsCSave(fromSnapshot, toSnapshot)
        }

        const saveSnapshotsDiff = async (fromSnapshot: Null<SnapshotDal>, toSnapshot: SnapshotDal): Promise<void> => {
            if (toSnapshot === fromSnapshot && experimentInstance.isOpen('dm_localApproveEmptyDiffCSave')) {
                log.info('trying to save diff between same snapshots')
                return
            }
            logger.interactionStarted(CSAVE_INTERACTION, {extras: {correlation_id: toSnapshot.id}})
            const {error, payload} = createTransactions(fromSnapshot, toSnapshot)
            if (error) {
                handleRemovalError(error)
                snapshots.tagSnapshot(CSAVE_TAG, snapshots.getLastSnapshot()!)
                this.pendingIndex = -1
                notifier.resolve(toSnapshot.id)
                return
            }
            if (_.isEmpty(payload?.transactions)) {
                log.info('no diff, skipping save')
                if (!this.config.cedit && experimentInstance.isOpen('dm_localApproveEmptyDiffCSave')) {
                    processLocalApproved({correlationId: toSnapshot.id, transactionId: null})
                    snapshots.tagSnapshot(CSAVE_TAG, toSnapshot)
                }
                notifier.resolve(toSnapshot.id)
                logger.interactionEnded(CSAVE_INTERACTION, {tags: {skipped: true}, extras: {correlation_id: toSnapshot.id}})
                return
            }
            await saveDiff(payload!, toSnapshot)
            logger.interactionEnded(CSAVE_INTERACTION, {tags: {skipped: false}, extras: {correlation_id: toSnapshot.id}})
        }

        const saveFromLastCSaveSnapshot = async (snapshot: SnapshotDal): Promise<void> => {
            if (this.saving) {
                log.info('save is in progress, skipping save')
                this.pendingIndex = snapshots.tagSnapshot(CSAVE_PENDING_TAG, snapshot)
            } else {
                const csaveSnap = getLastCsaveSnapshot(snapshots)
                try {
                    this.setSaving(true)
                    await saveSnapshotsDiff(csaveSnap, snapshot)
                } catch (e) {
                    notifier.reject(snapshot.id, e)
                    handleCSaveError(e, csaveSnap, snapshot)
                    throw makeReportable(e)
                } finally {
                    this.setSaving(false)
                }
            }
        }

        const save = async () => {
            if (shouldSave()) {
                try {
                    await saveFromLastCSaveSnapshot(snapshots.getLastSnapshot()!)
                } catch (e) {
                    logger.captureError(e as Error, {tags: {csaveOp: 'save'}})
                    throw e
                }
            }
        }

        const saveAndWait = async (triggeredFromForcedSave: boolean): Promise<void> => {
            const snapshot = snapshots.getLastSnapshot()!
            const lastCSave = getLastCsaveSnapshot(snapshots)
            if (snapshot === lastCSave) {
                return
            }
            const promise = notifier.register(snapshot.id)
            try {
                // if saving, will wait on promise until next save
                await saveFromLastCSaveSnapshot(snapshot)
                // in case of success the promise is resolved by the duplexer approval
            } catch (e) {
                logger.captureError(makeReportable(e), {tags: {csaveOp: 'saveAndWait', triggeredFromForcedSave}})
                log.error('Error saving from last csave snapshot', e as Error)
                notifier.reject(snapshot.id, e)
            }
            return promise
        }

        const saveAndWaitForResult = async (): Promise<void> => {
            const triggeredFromForcedSave = false
            if (shouldSave()) {
                await saveAndWait(triggeredFromForcedSave)
            }
        }

        const forceSaveAndWaitForResult = async (): Promise<void> => {
            const triggeredFromForcedSave = true
            if (isSavePermitted()) {
                logger.interactionStarted('forceSaveAndWaitForResult')
                await saveAndWait(triggeredFromForcedSave)
                logger.interactionEnded('forceSaveAndWaitForResult')
            }
        }

        const createRevision = async (args: CreateRevArgs, updateSiteDto: Record<string, any>): Promise<CreateRevisionRes> => {
            const recovery = this.validationRecovery
            const editorSessionId = dsSiteApi.getEditorSessionId()
            const req: CreateRevisionReq = {
                dsOrigin: args.dsOrigin,
                initiatorOrigin: args.initiatorOrigin,
                editorVersion: args.editorVersion,
                initiator: args.initiator,
                viewerName: args.viewerName,
                editorSessionId,
                metaSiteActions: updateSiteDto.metaSiteActions,
                branchId: updateSiteDto.branchId,
                siteName: updateSiteDto.metaSiteData?.siteName,
                closedWixCodeAppId: updateSiteDto?.wixCodeAppData?.codeAppId,
                ...(recovery
                    ? {
                          suppressUnappliedTransactions: true,
                          updateSiteDto: undefined,
                          lastTransactionId: undefined
                      }
                    : {
                          suppressUnappliedTransactions: false,
                          updateSiteDto,
                          lastTransactionId: server.getLast()
                      })
            }
            const res = await server.createRevision(req)
            log.info('createRevision called, result: ', res)
            dsSiteApi.setSiteRevision(res.siteRevision.revision)
            dsSiteApi.setSiteVersion(res.siteRevision.version)
            return res
        }

        const mergeActions = (actionsToApply: Action[]): void => {
            const changes = actionsToStore(actionsToApply)
            const autosaveInfoPointer = pointers.general.getAutosaveInfo()
            const autoSaveInfo = _.cloneDeep(dal.get(autosaveInfoPointer)) ?? {}
            autoSaveInfo.changesApplied = true
            changes.set(autosaveInfoPointer, autoSaveInfo)
            dal.mergeToApprovedStore(changes, 'get-transactions')
        }

        const validate = (actionsToApply: Action[]) => {
            try {
                const store: DmStore = actionsToStore(actionsToApply)
                const transactionStore = store.asJson()
                logger.interactionStarted(CSAVE_VALIDATION_INTERACTION)
                validateCSaveTransaction(
                    dal._getApprovedStoreAsJson(),
                    transactionStore,
                    (extensionAPI as SchemaExtensionAPI).schemaAPI,
                    logger,
                    experimentInstance
                )
                logger.interactionEnded(CSAVE_VALIDATION_INTERACTION)
            } catch (e) {
                logger.captureError(e as Error, tagsFromError(e as Error))
                return false
            }
            return true
        }

        const initChannel = async () => {
            await connect()
            await this.server.onChannelReady?.()
        }

        const hasTransactionsSinceRevision = (transactionResult?: GetDocumentResponse): boolean => !!transactionResult?.lastTransactionId

        const isCreateRevisionOpen = () => !!this.config.createRevision

        const initCSaveInternal = async (partialPages: string[]) => {
            const lastTransactionId = dsSiteApi.getAutosaveInfo()?.lastTransactionId ?? '0'
            server.setLast(lastTransactionId)
            log.info(`lastTransactionId=${lastTransactionId}`)
            const branchId = dsSiteApi.getBranchId()
            const transactionResult = await server.getStore(branchId, lastTransactionId, this.config.untilTransactionId)
            const partialPagesSet = new Set(partialPages)
            transactionResult.actions =
                partialPages.length > 0
                    ? transactionResult?.actions?.filter(action => !action?.value?.metaData?.pageId || partialPagesSet.has(action?.value?.metaData?.pageId))
                    : transactionResult.actions
            if (!hasTransactionsSinceRevision(transactionResult)) {
                return false
            }
            server.setLast(transactionResult.lastTransactionId!)
            initUnappliedTransactions(transactionResult)
            const actionsToApply = removeFromLoadStore(transactionResult.actions!)
            if (!this.config.disableCSaveValidationOnInitialization) {
                const valid = validate(actionsToApply)
                if (!valid || this.config.autosaveRestore === 'false') {
                    if (isCreateRevisionOpen()) {
                        this.validationRecovery = true
                    } else {
                        markForPartialUpdate(true)
                    }
                    return false
                }
            }
            snapshots.takeSnapshot(SNAPSHOTS.BEFORE_AUTOSAVE_APPLY)
            snapshots.takeSnapshot(SNAPSHOTS.MOBILE_MERGE)
            mergeActions(actionsToApply)
            dsSiteApi.setActionsCount(actionCountToAutosaveActionCount(actionsToApply.length))
            return true
        }

        const initCSave = async (partialPages: string[] = []) => {
            if (isNewFromTemplate()) {
                log.info('template site skipping initCSave')
                return false
            }
            this.server.setInstanceProvider(() => siteAPI.getInstance())
            const result = await initCSaveInternal(partialPages)
            snapshots.takeSnapshot(CSAVE_TAG)
            await initChannel()
            this.isInitialized = true
            registerNetworkHandlers()
            return result
        }

        eventEmitter.addListener(CS_EVENTS.CSAVE.SITE_SAVED, async () => {
            if (!this.isInitialized) {
                log.info('FIRST SAVE - initCSave')
                await initCSave()
            }
            this.validationRecovery = false
        })

        const rejectNext = () => {
            this.duplexer!.rejectNext()
        }

        const testApi = new CSaveTestApi(this)
        const test = () => testApi

        const wrappedHookSymbol = Symbol('wrappedHook')

        const getWrappedHook = (hook: Function, hookName: string) => {
            if (hook[wrappedHookSymbol]) return hook

            const wrappedHook = (...hookArgs: []) => {
                try {
                    hook(...hookArgs)
                } catch (e: any) {
                    logger.captureError(e, {tags: {csaveHook: true}, extras: {hookName}})
                }
            }

            wrappedHook[wrappedHookSymbol] = true

            return wrappedHook
        }

        const getWrappedHooks = (hooks: CSaveHooks) => {
            const callbacks = {}

            _.forEach(hooks, (val, key) => {
                if (_.isFunction(val)) {
                    callbacks[key] = getWrappedHook(val, key)
                }
            })

            return callbacks
        }

        return {
            continuousSave: {
                createRevision,
                save,
                saveAndWaitForResult,
                forceSaveAndWaitForResult,
                initCSave,
                deleteTx: async () => {
                    const t = await this.server.deleteTransactions()
                    log.info('deleteTransaction', t)
                },
                setSaving: isSaving => {
                    this.setSaving(isSaving)
                },
                getLastTransactionId: () => this.server.getLast(),
                initHooks: (saveHooks: CSaveHooks) => {
                    this.hooks = _.defaults(getWrappedHooks(saveHooks), this.hooks)
                },
                getWrappedHooks,
                setEnabled,
                shouldSave,
                connect,
                /**
                 * for testing purposes, rejects the next transaction
                 */
                rejectNext,
                /**
                 * for rendererModel#clientSpecMap reloader, don't use for other purposes
                 */
                registerToTransactionApproved: (cb: (txStore: DmStore) => Promise<void>) => {
                    if (_.isFunction(transactionApprovedCb)) {
                        throw new Error('registerToTransactionApproved is not allowed twice')
                    }
                    transactionApprovedCb = cb
                },
                /**
                 * debug method, prints store from a transaction id
                 * @param branch
                 * @param afterTransactionId
                 * @param untilTransactionId
                 * @returns {Promise<void>}
                 */
                getStore: async (branch?: string, afterTransactionId?: string, untilTransactionId?: string) =>
                    await this.server.getStore(branch, afterTransactionId, untilTransactionId),
                //@ts-ignore
                getTransactions: async (afterTransactionId?: string, untilTransactionId?: string, branchId?: string) =>
                    await this.server.getTransactions(afterTransactionId, untilTransactionId, branchId),
                getTransactionsFromLastRevision: async (untilTransactionId?: string, branchId?: string) => {
                    const revisionTransactionId = dal.get(pointers.autoSave.getAutoSaveInnerPointer('lastTransactionId'))
                    return await this.server.getTransactions(revisionTransactionId, untilTransactionId, branchId)
                },
                approveForeignTransaction: async (t: GetTransactionRes) => {
                    if (this.server instanceof MockCEditTestServer) {
                        await this.server.approveForeignTransaction(t)
                    }
                },
                test,
                isCSaveOpen: () => true,
                isCEditOpen: () => !!this.config.cedit,
                isCreateRevisionOpen,
                waitForResponsesToBeProcessed: async () => {
                    await this.queue.toBeEmpty()
                },
                isValidationRecovery: () => this.validationRecovery,
                onRevisionChange
            }
        }
    }

    async initialize({extensionAPI, dal, pointers}: DmApis): Promise<void> {
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI
        const {siteAPI: dsSiteApi} = extensionAPI as DocumentServicesModelExtApi
        this.neverSynced = dsSiteApi.getNeverSaved()
        const {siteAPI: rmSiteApi} = extensionAPI as RMApi
        this.server.setInstanceProvider(() => rmSiteApi.getInstance())
        const {continuousSave} = extensionAPI as CSaveApi
        if (this.validationRecovery && continuousSave.isCreateRevisionOpen()) {
            const res = await continuousSave.createRevision(
                {
                    dsOrigin: this.config.origin,
                    initiator: 'validationRecovery',
                    initiatorOrigin: '',
                    editorVersion: dsSiteApi.getSiteVersion().toString(),
                    viewerName: ''
                },
                {}
            )
            dal.set(pointers.general.getIsDraft(), false)
            const actionsWithClientStyleNamespaces = converter.convertActionsNamespacesFromServerStyle(schemaAPI, res.actions)
            rebase(dal, snapshots, actionsWithClientStyleNamespaces, `revision-${res.siteRevision.revision}`)
            this.validationRecovery = false
        }
    }
}

const getOnlineHandler = (dsConfig: DSConfig, environmentContext: EnvironmentContext) => environmentContext.registerOnlineHandler ?? registerOnlineHandler

const createExtension = ({dsConfig, environmentContext}: CreateExtensionArgument): Extension => {
    if (dsConfig.continuousSave) {
        if (!environmentContext?.serverFacade) {
            throw new Error('Illegal attempt to register CSave without providing server facade implementation')
        }
        log = debug('csave', environmentContext.loggerDriver)
        const maxTransactionsModifier = environmentContext.csaveMaxTransactionsModifier ?? _.random(1, 500)
        const registerHandlers = getOnlineHandler(dsConfig, environmentContext)
        return new CSaveExtension(environmentContext.serverFacade, dsConfig, registerHandlers, maxTransactionsModifier)
    }
    return new EmptyCSaveExt()
}

export {createExtension, CSAVE_TAG, MAX_TRANSACTIONS_BASE}
