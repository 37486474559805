import _ from 'lodash'
import type {DocumentManager, Pointer} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import {runOnPageComponents, cleanRef} from './utils/migrationUtil'

const cleanBrokenQuery = (documentManager: DocumentManager, pageId: string, pointer: Pointer, queryProp: string, targetNamespace: string) => {
    const queryPointer = documentManager.pointers.getInnerPointer(pointer, queryProp)
    const query = documentManager.dal.get(queryPointer)
    if (query) {
        const itemId = cleanRef(query)
        const itemPointer = documentManager.pointers.data.getItem(targetNamespace, itemId, pageId)
        if (!documentManager.dal.get(itemPointer)) {
            documentManager.dal.remove(queryPointer)
        }
    }
}

const cleanComponent = (documentManager: DocumentManager, pageId: string, pointer: Pointer) => {
    cleanBrokenQuery(documentManager, pageId, pointer, 'mobileHintsQuery', constants.DATA_TYPES.mobileHints)
    cleanBrokenQuery(documentManager, pageId, pointer, 'propertyQuery', constants.DATA_TYPES.prop)
    cleanBrokenQuery(documentManager, pageId, pointer, 'behaviorQuery', constants.DATA_TYPES.behaviors)
    cleanBrokenQuery(documentManager, pageId, pointer, 'transformationQuery', constants.DATA_TYPES.transformations)
    cleanBrokenQuery(documentManager, pageId, pointer, 'transitionQuery', constants.DATA_TYPES.transitions)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    _.forEach(constants.VIEW_MODES, viewMode => {
        runOnPageComponents(documentManager, pageId, viewMode, cleanComponent)
    })
}

const name = 'removeBrokenQueries'
const version = 1

export {migratePage, name, version}
