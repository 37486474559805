import {
    CreateExtArgs,
    CreateExtensionArgument,
    DmApis,
    DmStore,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils,
    Transaction,
    HistoryItem
} from '@wix/document-manager-core'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {ClientSpecMap, ClientSpecMapEntry, MetaSiteClientSpecEntry, WixCodeModel} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
const {getGUID} = guidUtils
import type {CSaveApi} from './csave/continuousSave'
import {ReportableError} from '@wix/document-manager-utils'

// should come from platformConstants.APPS.META_SITE.applicationId
const META_SITE_APP_ID = '-666'
const META_SITE_CLIENT_SPEC_MAP_URL = '/_api/msm/v1/meta-site/editor-client-spec-map/'

const {getPointer} = pointerUtils
const pointerType = 'rendererModel'
const clientSpecMapCacheKiller = 'clientSpecMapCacheKiller'
const NO_MATCH: string[] = []
const INDEX_MATCH = [pointerType]

const FIVE_MINUTES = 1000 * 60 * 5

interface RMApi extends ExtensionAPI {
    siteAPI: {
        getDocumentType(): string
        isTemplate(): boolean
        getInstance(): string
        getRendererModel(): any
        getSiteId(): string
    }
    rendererModel: {
        registerToClientSpecMapUpdate(cb: ClientUpdateListener): void
        registerToWixCodeModelUpdate(cb: WixCodeModelUpdateListener): void
        updateClientSpecMap(txStore: DmStore): Promise<any>
        askRemoteEditorsToRefreshClientSpecMap(): void
        onAppsInstalledRemotely(cb: (appData: any) => void): void
        refreshWixInstance(): Promise<string>
        isWixInstanceExpired(): boolean
        getTimeUntilWixInstanceExpiration(now: number): number
        getSiteId(): string
        getMetaSiteId(): string
    }
}
type ClientUpdateListener = (clientSpecMap: ClientSpecMap) => void
type WixCodeModelUpdateListener = (wixCodeModel: WixCodeModel) => void

const createMetaSiteClientSpecMapUrl = (msid: string): string => `${META_SITE_CLIENT_SPEC_MAP_URL}${msid}?https=true`

/**
 * @returns {Extension}
 */
const createExtension = ({experimentInstance, environmentContext}: CreateExtensionArgument): Extension => {
    const clientUpdateListeners: ClientUpdateListener[] = []
    const wixCodeModelListeners: WixCodeModelUpdateListener[] = []
    const createPointersMethods = ({dal}: DmApis): PointerMethods => {
        const getUrlFormat = () => getPointer('urlFormatModel', pointerType, ['format'])
        const getForbiddenPageUriSEOs = () => getPointer('urlFormatModel', pointerType, ['forbiddenPageUriSEOs'])
        const getClientSpecMapCacheKiller = () => getPointer(clientSpecMapCacheKiller, pointerType)
        const getClientSpecMap = () => getPointer('clientSpecMap', pointerType)
        const getClientSpecMapEntry = (applicationId: string) => getPointer('clientSpecMap', pointerType, [applicationId])
        const getClientSpecMapEntryByAppDefId = (appDefinitionId: string) => {
            const csm = dal.get(getPointer('clientSpecMap', pointerType))
            const applicationId = _.get(_.find(csm, {appDefinitionId}), ['applicationId'])
            return getClientSpecMapEntry(applicationId)
        }
        const getClientSpecMapEntriesByPredicate = (predicate: (v: any) => any) => {
            const filteredCSM = predicate(dal.get(getPointer('clientSpecMap', pointerType)))
            return _.map(filteredCSM, appData => getClientSpecMapEntry(appData.applicationId))
        }

        const getSiteMetaData = () => getPointer('siteMetaData', pointerType)
        const isResponsive = () => getPointer('siteMetaData', pointerType, ['isResponsive'])
        const getMetaSiteId = () => getPointer('metaSiteId', pointerType)
        const getSiteId = () => getPointer('siteInfo', pointerType, ['siteId'])
        const getLanguageCode = () => getPointer('languageCode', pointerType)
        const getGeo = () => getPointer('geo', pointerType)
        const getUserId = () => getPointer('userId', pointerType)
        const getSiteTitleSEO = () => getPointer('siteInfo', pointerType, ['siteTitleSEO'])
        const getDocumentType = () => getPointer('siteInfo', pointerType, ['documentType'])
        const getApplicationType = () => getPointer('siteInfo', pointerType, ['applicationType'])
        const getPremiumFeatures = () => getPointer('premiumFeatures', pointerType)
        const getPasswordProtectedPages = () => getPointer('passwordProtectedPages', pointerType, ['pages'])
        const getSitePropertiesInfo = () => getPointer('sitePropertiesInfo', pointerType)
        const getCurrency = () => getPointer('sitePropertiesInfo', pointerType, ['currency'])
        const getTimeZone = () => getPointer('sitePropertiesInfo', pointerType, ['timeZone'])
        const getLocale = () => getPointer('sitePropertiesInfo', pointerType, ['locale'])
        const getRegionalLanguage = () => getPointer('sitePropertiesInfo', pointerType, ['language'])
        const getMultilingualInfo = () => getPointer('sitePropertiesInfo', pointerType, ['multilingualInfo'])
        const getSiteDisplayName = () => getPointer('sitePropertiesInfo', pointerType, ['siteDisplayName'])
        const getMediaAuthToken = () => getPointer('mediaAuthToken', pointerType)
        const getWixCodeAppData = () => getPointer('wixCodeModel', pointerType, ['appData'])
        const getRevisionGridAppId = () => getPointer('wixCodeModel', pointerType, ['appData', 'codeAppId'])
        const getWixCodeModel = () => getPointer('wixCodeModel', pointerType)
        const getPageToHashedPassword = () => getPointer('pageToHashedPassword', pointerType, ['pages'])
        const getRoutersPointer = () => getPointer('routers', pointerType)
        const getRoutersConfigMapPointer = () => getPointer('routers', pointerType, ['configMap'])
        const getRouterPointer = (routerId: string) => getPointer('routers', pointerType, ['configMap', routerId])

        return {
            general: {
                getUrlFormat,
                getForbiddenPageUriSEOs,
                getClientSpecMap,
                getSiteMetaData,
                getMetaSiteId,
                isResponsive,
                getPageToHashedPassword,
                getClientSpecMapEntry,
                getClientSpecMapEntryByAppDefId,
                getClientSpecMapEntriesByPredicate,
                getCurrency,
                getRegionalLanguage,
                getTimeZone
            },
            rendererModel: {
                getUrlFormat,
                getForbiddenPageUriSEOs,
                getClientSpecMapCacheKiller,
                getClientSpecMap,
                getClientSpecMapEntry,
                getClientSpecMapEntryByAppDefId,
                getClientSpecMapEntriesByPredicate,
                getSiteMetaData,
                getMetaSiteId,
                isResponsive,
                getSiteId,
                getLanguageCode,
                getGeo,
                getUserId,
                getSiteTitleSEO,
                getDocumentType,
                getPremiumFeatures,
                getPasswordProtectedPages,
                getSitePropertiesInfo,
                getCurrency,
                getApplicationType,
                getMultilingualInfo,
                getTimeZone,
                getLocale,
                getRegionalLanguage,
                getMediaAuthToken,
                getPageToHashedPassword,
                getSiteDisplayName
            },
            wixCode: {
                getWixCodeModel,
                getRevisionGridAppId,
                getWixCodeAppData
            },
            routers: {
                getRoutersPointer,
                getRoutersConfigMapPointer,
                getRouterPointer
            }
        }
    }

    const getDocumentDataTypes = (): DocumentDataTypes => ({
        [pointerType]: {
            idsWithSignature: new Set(
                ['routers', 'siteMetaData'].concat(experimentInstance.isOpen('specs.WixCodeOpenCodeAppIdEnabled') ? [] : ['wixCodeModel'])
            )
        }
    })

    const onAppsInstalledRemotelyCallbacks: ((appData: object) => void)[] = []
    const {fetchFn} = environmentContext
    let remotelyInstalledAppsToNotifySubscribers: ClientSpecMapEntry[] = []
    const createExtensionAPI = ({dal, pointers, coreConfig}: CreateExtArgs): RMApi => {
        let currentClientSpecMapCacheKiller = ''

        const generateClientSpecMapCacheKiller = () => {
            currentClientSpecMapCacheKiller = getGUID()
            return currentClientSpecMapCacheKiller
        }
        const getCurrentClientSpecMapCacheKiller = () => currentClientSpecMapCacheKiller
        const setCurrentClientSpecMapCacheKiller = (newVal: string) => {
            currentClientSpecMapCacheKiller = newVal
        }

        const getSpecMap = () => dal.get(pointers.general.getClientSpecMapEntry(META_SITE_APP_ID)) || {}

        const getMetaSiteId = (): string => dal.get(pointers.general.getMetaSiteId())

        const getWixInstanceExpirationTime = (now: number): number => {
            const expirationTimestamp = getSpecMap().expirationDate
            if (!expirationTimestamp) {
                return now
            }
            const expirationDate = new Date(expirationTimestamp)
            return expirationDate.getTime() - FIVE_MINUTES
        }

        const getTimeUntilWixInstanceExpiration = (now: number) => getWixInstanceExpirationTime(now) - now

        const isWixInstanceExpired = (): boolean => getTimeUntilWixInstanceExpiration(Date.now()) <= 0

        const createFetchError = (response: Response | null, e: Error): ReportableError =>
            new ReportableError({
                message: 'Cannot fetch client spec map',
                errorType: 'CLIENT_SPEC_MAP_FETCH_FAILURE',
                extras: {
                    status: response?.status,
                    requestId: response?.headers.get('x-wix-request-id'),
                    reason: {name: e.name, message: e.message},
                    isWixInstanceExpired: isWixInstanceExpired(),
                    newUrl: true
                }
            })

        const fetchCsmResponse = async (): Promise<Response> => await fetchFn(createMetaSiteClientSpecMapUrl(getMetaSiteId()))

        const fetchClientSpecMap: () => Promise<ClientSpecMap> = async () => {
            let response: Response
            const interaction = 'fetchCsmFromMetaSite'
            try {
                coreConfig.logger.interactionStarted(interaction)
                response = await fetchCsmResponse()
                const result = await response!.json()
                coreConfig.logger.interactionEnded(interaction)
                return result
            } catch (e) {
                const err = createFetchError(response! ?? null, e as Error)
                coreConfig.logger.captureError(err)
                throw err
            }
        }

        const nonRevoked = (appData: ClientSpecMapEntry) => !_.get(appData, ['permissions', 'revoked'], false)
        const revoked = (appData: ClientSpecMapEntry) => _.get(appData, ['permissions', 'revoked'], false)
        const getRevokedClientSpecs = (existingCSM: ClientSpecMap, refreshedCSM: ClientSpecMap) =>
            _(refreshedCSM).filter(revoked).keyBy('applicationId').value()
        const getNewInstalledClientSpecs = (existingCSM: ClientSpecMap, refreshedCSM: ClientSpecMap) =>
            _(refreshedCSM)
                .filter(nonRevoked)
                .filter(cs => _.isNil(existingCSM[_.get(cs, ['applicationId'])]))
                .keyBy('applicationId')
                .value()
        const getReinstalledClientSpecs = (existingCSM: ClientSpecMap, refreshedCSM: ClientSpecMap) =>
            _(refreshedCSM)
                .filter(cs => _.get(existingCSM, [_.get(cs, ['applicationId']), 'permissions', 'revoked'], false))
                .filter(nonRevoked)
                .keyBy('applicationId')
                .value()
        const getUpdatedClientSpecs = (existingCSM: ClientSpecMap, refreshedCSM: ClientSpecMap) =>
            _(refreshedCSM)
                .filter(nonRevoked)
                .filter(cs => {
                    const applicationId = _.get(cs, ['applicationId'])
                    return (
                        !_.isNil(existingCSM[applicationId]) &&
                        !_.isEqual(_.get(existingCSM, [applicationId, 'version']), _.get(refreshedCSM, [applicationId, 'version']))
                    )
                })
                .keyBy('applicationId')
                .value()

        // @ts-ignore
        function getInstance(): string {
            const clientSpecMap = getSpecMap()
            if (clientSpecMap.instance) {
                return clientSpecMap.instance
            }
            // throw new Error('Could not find instance in client spec map')
        }

        const getDocumentTypeVal = () => dal.get(pointers.rendererModel.getDocumentType())

        const getSiteId = () => dal.get(pointers.rendererModel.getSiteId())

        return {
            siteAPI: {
                getDocumentType: getDocumentTypeVal,
                isTemplate: () => getDocumentTypeVal() === 'Template',
                getInstance,
                getSiteId,
                getRendererModel: () =>
                    _.map(dal.query(pointerType, dal.queryFilterGetters.getRendererModelFilter(pointerType)), (value, id) => ({
                        pointer: getPointer(id, pointerType),
                        value
                    }))
            },
            rendererModel: {
                registerToClientSpecMapUpdate: (cb: ClientUpdateListener) => {
                    if (_.isFunction(cb)) {
                        clientUpdateListeners.push(cb)
                    }
                },
                registerToWixCodeModelUpdate: (cb: WixCodeModelUpdateListener) => {
                    if (_.isFunction(cb)) {
                        wixCodeModelListeners.push(cb)
                    }
                },
                updateClientSpecMap: async (txStore: DmStore) => {
                    const changes = txStore.getValues()
                    const clientSpecMapCacheKillerItem = _.find(changes, e => e?.key?.type === pointerType && e?.key?.id === clientSpecMapCacheKiller)
                    const received = clientSpecMapCacheKillerItem?.value?.cacheKiller
                    const curr = getCurrentClientSpecMapCacheKiller()

                    if (clientSpecMapCacheKillerItem && received !== curr) {
                        setCurrentClientSpecMapCacheKiller(received)
                        const refreshedCSM = await fetchClientSpecMap()
                        const existingCSM: ClientSpecMap = dal.get(pointers.general.getClientSpecMap())
                        const updatedClientSpecMap = deepClone(existingCSM)

                        const newClientSpecs = getNewInstalledClientSpecs(existingCSM, refreshedCSM)
                        const revokedClientSpecs = getRevokedClientSpecs(existingCSM, refreshedCSM)
                        const reinstalledClientSpecs = getReinstalledClientSpecs(existingCSM, refreshedCSM)
                        const updatedClientSpecs = getUpdatedClientSpecs(existingCSM, refreshedCSM)

                        remotelyInstalledAppsToNotifySubscribers.push(
                            ..._.uniqBy(_.values(newClientSpecs).concat(_.values(reinstalledClientSpecs)), 'applicationId')
                        )
                        _.assign(updatedClientSpecMap, newClientSpecs, revokedClientSpecs, reinstalledClientSpecs, updatedClientSpecs)

                        txStore.set(pointers.general.getClientSpecMap(), updatedClientSpecMap)
                    }
                },
                async refreshWixInstance(): Promise<string> {
                    const refreshedClientSpecMap = await fetchClientSpecMap()
                    const refreshedMsEntry = refreshedClientSpecMap[META_SITE_APP_ID]
                    if (!refreshedMsEntry) {
                        return getInstance()
                    }

                    const msEntry: MetaSiteClientSpecEntry = _.cloneDeep(getSpecMap())
                    _.assign(msEntry, _.pick(refreshedClientSpecMap[META_SITE_APP_ID], ['instance', 'expirationDate']))
                    dal.set(pointers.general.getClientSpecMapEntry(META_SITE_APP_ID), msEntry)
                    return getInstance()
                },
                isWixInstanceExpired,
                getTimeUntilWixInstanceExpiration,
                askRemoteEditorsToRefreshClientSpecMap: () => {
                    dal.set(pointers.rendererModel.getClientSpecMapCacheKiller(), {cacheKiller: generateClientSpecMapCacheKiller()})
                },
                onAppsInstalledRemotely: (cb: (appData: any) => void) => {
                    onAppsInstalledRemotelyCallbacks.push(cb)
                },
                getSiteId,
                getMetaSiteId
            }
        }
    }

    const createFilters = () => ({
        getRendererModelFilter: (namespace: string): string[] => {
            if (namespace === pointerType) {
                return INDEX_MATCH
            }
            return NO_MATCH
        }
    })

    const initialState = {
        [pointerType]: {
            sitePropertiesInfo: {
                multilingualInfo: {
                    originalLanguage: {}
                }
            }
        }
    }

    const createPostTransactionOperations = () => ({
        renderereModel: (documentTransaction: Transaction) => {
            if (!_.isEmpty(remotelyInstalledAppsToNotifySubscribers)) {
                for (const cb of onAppsInstalledRemotelyCallbacks) {
                    cb(remotelyInstalledAppsToNotifySubscribers)
                }

                remotelyInstalledAppsToNotifySubscribers = []
            }
            const rendererModelOperations = documentTransaction.items.filter(e => e?.key?.type === pointerType)
            _.forEach(rendererModelOperations, (item: HistoryItem) => {
                const {
                    value,
                    key: {id: pointerId}
                } = item
                if (pointerId === 'clientSpecMap') {
                    _.forEach(clientUpdateListeners, cb => cb(value))
                }
                if (pointerId === 'wixCodeModel') {
                    _.forEach(wixCodeModelListeners, cb => cb(value))
                }
            })
        }
    })

    const initialize = async ({extensionAPI}: DmApis) => {
        const {continuousSave} = extensionAPI as CSaveApi
        const {rendererModel} = extensionAPI as RMApi
        continuousSave?.registerToTransactionApproved(rendererModel.updateClientSpecMap)
    }

    return {
        name: 'rendererModel',
        createPointersMethods,
        getDocumentDataTypes,
        initialState,
        createExtensionAPI,
        createFilters,
        createPostTransactionOperations,
        initialize
    }
}

export {META_SITE_APP_ID, createExtension, pointerType, RMApi, createMetaSiteClientSpecMapUrl}
