import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import experiment from 'experiment'

const {urlUtils} = coreUtils

const PAGES_ROOT = 'pages'
const JS_SUFFIX = '.js'
const SOURCE_MAP_SUFFIX = `${JS_SUFFIX}.map`

const CACHE_KILLERS_MAP = ['wixCode', 'nonUndoable', 'bundleCacheKillers']
const GLOBAL_CACHE_KILLER = ['wixCode', 'nonUndoable', 'defaultBundleCacheKiller']

function getViewMode(ps) {
    return ps.dal.get({type: 'rendererModel', id: 'previewMode'}) ? 'preview' : 'site'
}

function getServiceTopology(ps) {
    const serviceTopologyPointer = ps.pointers.serviceTopology.getServiceTopology()
    return ps.dal.get(serviceTopologyPointer)
}
function computeCacheKiller(bundleId, ps) {
    const bundleCacheKiller = ps.dal.get(ps.pointers.wixCode.getBundleCacheKiller(bundleId))
    const defaultCacheKiller = ps.dal.get(ps.pointers.wixCode.getGlobalBundleCacheKiller())

    return bundleCacheKiller || defaultCacheKiller
}

function getProtocol() {
    return 'https:'
}

function getEditorUserCodeBaseUrl(wixCodeSpec, ps) {
    const serviceTopology = getServiceTopology(ps)
    return urlUtils.joinURL(`${getProtocol()}//${wixCodeSpec.instanceId}.dev.${serviceTopology.wixCloudBaseDomain}`, PAGES_ROOT)
}

function isUserCodeUrl(url, wixCodeSpec, ps) {
    const baseUrl = getEditorUserCodeBaseUrl(wixCodeSpec, ps)
    return _.startsWith(url, baseUrl)
}

function getModuleNameByUserCodeUrl(userCodeUrl) {
    const urlWithoutJsSuffix = userCodeUrl.substring(0, userCodeUrl.indexOf(JS_SUFFIX))
    return urlWithoutJsSuffix.substring(urlWithoutJsSuffix.lastIndexOf('/') + 1)
}

function getSourceMapUrl(userCodeUrl, gridAppId, wixCodeSpec, ps) {
    const moduleName = getModuleNameByUserCodeUrl(userCodeUrl)

    const baseUrl = `//${wixCodeSpec.instanceId}.dev.wix-code.com/preview`

    const useCoreNg = experiment.isOpen('wix_code_bundler_use_core_ng') ? 'true' : 'false'

    const sourceMapUrl = urlUtils.joinURL(baseUrl, PAGES_ROOT, moduleName + SOURCE_MAP_SUFFIX)

    return (
        `${sourceMapUrl}?use-globals-object=true&` +
        `module-name=${moduleName}&viewMode=${getViewMode(ps)}&instance=${
            wixCodeSpec.instance
        }&gridAppId=${gridAppId}&use_core_ng=${useCoreNg}&cacheKiller=${computeCacheKiller(moduleName, ps)}`
    )
}

function getEditorUserCodeUrl(scriptName, moduleName, wixCodeModel, wixCodeSpec, ps) {
    const baseUrl = getEditorUserCodeBaseUrl(wixCodeSpec, ps)

    const userCodeUrl = urlUtils.joinURL(baseUrl, scriptName)

    const queryParams = {
        viewMode: getViewMode(ps),
        instance: wixCodeSpec.instance,
        gridAppId: wixCodeModel.appData.codeAppId,
        cacheKiller: computeCacheKiller(moduleName, ps)
    }

    if (experiment.isOpen('wix_code_bundler_use_core_ng')) {
        _.set(queryParams, 'use_core_ng', 'true')
    }

    queryParams['use-globals-object'] = 'true'
    const queryParamsStr = _(queryParams)
        .mapValues((value, key) => `${key}=${value}`)
        .values()
        .sort()
        .join('&')

    // NOTE: This URL has to EXACTLY match the one generated in Bolt, so that code prefetching in preview will be effective
    return `${userCodeUrl}?${queryParamsStr}`
}

function getViewerUserCodeUrl(scriptName, wixCodeModel, wixCodeSpec, ps) {
    const platformVersion = 'v2'
    const serviceTopology = getServiceTopology(ps)
    const baseUrl = `${getProtocol()}//${wixCodeSpec.instanceId}.static.pub.${serviceTopology.wixCloudBaseDomain}`

    return urlUtils.joinURL(
        baseUrl,
        'static',
        platformVersion,
        wixCodeModel.appData.codeAppId, // which is actually the gridAppId...
        wixCodeSpec.instanceId,
        PAGES_ROOT,
        scriptName
    )
}

function getUserCodeUrl(scriptName, moduleName, wixCodeModel, wixCodeSpec, ps) {
    return getViewMode(ps) === 'site'
        ? getViewerUserCodeUrl(scriptName, wixCodeModel, wixCodeSpec, ps)
        : getEditorUserCodeUrl(scriptName, moduleName, wixCodeModel, wixCodeSpec, ps)
}

function getWidgetDisplayName(widget) {
    return widget.type === 'masterPage' ? 'site' : `${widget.displayName} ${widget.type === 'Popup' ? 'popup' : 'page'}`
}

function getUserScript(widget, wixCodeModel, wixCodeSpec, ps) {
    const moduleName = widget.type === 'masterPage' ? 'masterPage' : widget.id
    const scriptName = `${moduleName}.js`
    return {
        url: getUserCodeUrl(scriptName, moduleName, wixCodeModel, wixCodeSpec, ps),
        displayName: getWidgetDisplayName(widget),
        scriptName
    }
}

function filePathAffectsBundles(path) {
    return _.startsWith(path, 'public/') || _.startsWith(path, 'backend/')
}

function filePathAffectsSingleBundle(path) {
    return _.startsWith(path, 'public/pages/')
}

function bundleIdFromFilePath(path) {
    if (!filePathAffectsSingleBundle(path)) {
        throw new Error(`The path "${path}" does not belong to a single bundle`)
    }
    return path.split('public/pages/')[1].split('.')[0]
}

export default {
    paths: {
        GLOBAL_CACHE_KILLER,
        CACHE_KILLERS_MAP
    },
    filePathAffectsBundles,
    filePathAffectsSingleBundle,
    bundleIdFromFilePath,
    getUserScript,
    isUserCodeUrl,
    getSourceMapUrl,
    getUserCodeUrl
}
