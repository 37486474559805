define([], function () {
    'use strict'

    return {
        removable: false,
        hiddenable: false,
        duplicatable: false,
        mobileConversionConfig: {
            isDockingAllowed: true,
            isFixedPositionAllowed: true,
            isAllowedToBeChildOfMasterPage: true,
            isNonContainableFullWidth: true
        }
    }
})
