import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const ASPECT_NAME = 'tpaModalAspect'

function getModalStructure() {
    return {
        componentType: 'wysiwyg.viewer.components.tpapps.TPAModal',
        type: 'Component',
        // @ts-ignore
        id: coreUtils.guidUtils.getUniqueId(),
        skin: 'wysiwyg.viewer.skins.TPAModalSkin'
    }
}

function getModalGlobalData(aspectSiteApi) {
    const aspectGlobalData = aspectSiteApi.getAspectGlobalData(ASPECT_NAME)
    return _.get(aspectGlobalData, 'modalStructure')
}

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function TPAModalAspect(aspectSiteApi) {
    /** @type core.SiteAspectsSiteAPI */
    this.aspectSiteApi = aspectSiteApi

    _.bindAll(this, _.functionsIn(this))
}

TPAModalAspect.prototype = {
    getComponentsToRender() {
        const modalStructure = getModalGlobalData(this.aspectSiteApi)
        if (modalStructure) {
            const aspectGlobalData = this.aspectSiteApi.getAspectGlobalData(ASPECT_NAME)
            return [
                {
                    structure: modalStructure,
                    props: {
                        compData: aspectGlobalData.modalData,
                        onCloseCallback: aspectGlobalData.modalOnClose,
                        key: modalStructure.id
                    }
                }
            ]
        }
        return null
    },

    showModal(data, callback) {
        if (this.aspectSiteApi.getSiteData().isMobileView()) {
            if (data.theme === 'LIGHT_BOX') {
                this.aspectSiteApi.exitFullScreenMode()
                this.aspectSiteApi.setSiteRootHiddenState(true)
            } else {
                this.aspectSiteApi.enterFullScreenMode({scrollable: false})
                this.aspectSiteApi.setSiteRootHiddenState(false)
            }
        }

        this.aspectSiteApi.setSiteRootAriaHiddenState(true)
        this.aspectSiteApi.getComponentById('SITE_BACKGROUND').setAriaHiddenState(true)
        this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, {modalStructure: getModalStructure(), modalData: data, modalOnClose: callback})
        this.aspectSiteApi.forceUpdate()
    },

    removeModal() {
        if (this.aspectSiteApi.getSiteData().isMobileView()) {
            this.aspectSiteApi.exitFullScreenMode()
            this.aspectSiteApi.setSiteRootHiddenState(false)
        }
        this.aspectSiteApi.setSiteRootAriaHiddenState(false)
        this.aspectSiteApi.getComponentById('SITE_BACKGROUND').setAriaHiddenState(false)
        this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, {modalStructure: null})
    }
}

export default TPAModalAspect

/* eslint-enable*/
