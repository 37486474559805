define([
    'lodash',
    'experiment',
    'documentServices/routers/routers',
    'documentServices/page/page',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/menu/menuAPI',
    'documentServices/pagesGroup/pagesGroup',
    'documentServices/tpa/tpa',
    'documentServices/tpa/services/tpaDeleteService',
    'documentServices/connections/connections',
    'documentServices/menu/menuUtils',
    'documentServices/platform/services/workerService',
    'documentServices/platform/platform',
    'documentServices/platform/appComponents',
    'documentServices/wixCode/wixCode',
    'documentServices/platform/common/constants',
    'documentServices/tpa/utils/permissionsUtils',
    'documentServices/platform/services/platformStateService'
], function (
    _,
    experiment,
    routers,
    page,
    componentDetectorAPI,
    component,
    clientSpecMapService,
    menuAPI,
    pagesGroup,
    tpa,
    tpaDeleteService,
    connections,
    menuUtils,
    workerService,
    platform,
    appComponents,
    wixCode,
    constants,
    permissionsUtils,
    platformStateService
) {
    'use strict'

    const excludeApps = ['dataBinding', 'wix-code']

    function uninstall(ps, appDeletionData, appDefIds, onSuccess, onError, progressCallback = _.noop) {
        const promises = []
        for (const data of appDeletionData) {
            try {
                _.forEach(data.appIdsToDelete, applicationId => {
                    tpa.app.delete(ps, data, applicationId)
                    const appData = clientSpecMapService.getAppData(ps, applicationId)
                    const appDefinitionId = _.get(appData, 'appDefinitionId')
                    promises.push(deletePlatformPart(ps, appData))
                    if (clientSpecMapService.hasEditorPlatformPart(clientSpecMapService.getAppData(ps, applicationId))) {
                        platform.remove(ps, applicationId)
                    }
                    progressCallback(appDefinitionId)
                })
            } catch (e) {
                onError('Uninstall App Failed', e)
            }
        }

        promises.push(ps.setOperationsQueue.waitForChangesAppliedAsync())

        Promise.all(promises).then(() => onSuccess(), onError) // eslint-disable-line promise/prefer-await-to-then
    }

    async function deletePlatformPart(ps, appData) {
        const {appDefinitionId, applicationId} = appData

        deletePagesGroup(ps, appDefinitionId, applicationId)
        deleteRouters(ps, appDefinitionId)
        deletePages(ps, appDefinitionId)
        deleteComponents(ps, appDefinitionId)
        deleteMenus(ps, applicationId)

        const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({ps}, {intent: constants.Intents.USER_ACTION})
        if (!shouldAvoidRevoking) {
            platformStateService.setAppPendingAction(ps, appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
        }

        if (experiment.isOpen('dm_uninstallCodePackages')) {
            await deleteCodePackages(ps, appData)
        }
    }

    async function deleteCodePackages(ps, appData) {
        if (appComponents.hasCodePackage(appData)) {
            return wixCode.codePackages.uninstallCodeReusePkg(ps, appData.appDefinitionId)
        }
    }

    function deleteMenus(ps, applicationId) {
        const appMenus = menuUtils.getMenusByFilter(ps, {appId: applicationId})
        _.forEach(appMenus, menu => menuAPI.remove(ps, menu.id))
    }

    function deletePages(ps, appDefinitionId) {
        const pages = page.getPagesDataItems(ps).filter(pageData => pageData.managingAppDefId === appDefinitionId)
        _.forEach(pages, pageData => {
            const pagePointer = page.getPage(ps, pageData.id)
            if (ps.dal.isExist(pagePointer)) {
                page.remove(ps, pageData.id)
            }
        })
    }

    function deleteRouters(ps, appDefinitionId) {
        const appRouters = _.filter(routers.get.all(ps), router => router.appDefinitionId === appDefinitionId)

        _.forEach(appRouters, routerRef => {
            const routerPointer = routers.getRouterRef.byPrefix(ps, routerRef.prefix)
            _.forEach(routerRef.pages, routerPage => {
                if (ps.dal.isExist(page.getPage(ps, routerPage))) {
                    routers.pages.removePageFromRouter(ps, routerPointer, routerPage)
                    page.remove(ps, routerPage)
                }
            })
            routers.remove(ps, routerPointer)
        })
    }

    function deletePagesGroup(ps, appDefinitionId, applicationId) {
        const pageGroup = pagesGroup.getPagesGroupByAppId(ps, applicationId)
        pagesGroup.removePagesGroup(ps, pageGroup)
    }

    function deleteComponents(ps, appDefinitionId) {
        const controlledComponents = _.filter(componentDetectorAPI.getAllComponents(ps), comp => {
            const compData = component.data.get(ps, comp)
            const applicationId = _.get(compData, 'applicationId')
            const appDefId = _.get(compData, 'appDefinitionId')
            return (applicationId || appDefId) === appDefinitionId
        })

        _.forEach(controlledComponents, comp => {
            if (ps.dal.isExist(comp)) {
                const controllerConnections = connections.getControllerConnectionsByAncestor(ps, comp)
                _.forEach(controllerConnections, componentRef => {
                    component.remove(ps, componentRef)
                })

                if (ps.dal.isExist(comp)) {
                    component.remove(ps, comp)
                }
            }
        })
    }

    const getAppPagesToDelete = function (ps, applicationId, onError) {
        try {
            const appsPagesToDelete = tpaDeleteService.notifyAppsToDelete(ps, applicationId, true)
            appsPagesToDelete.isPlatformRemoval = true
            return appsPagesToDelete
        } catch (e) {
            onError(e)
        }
    }

    const getAppsPagesToDelete = function (ps, appDeleteData, appsData, applicationId, onError) {
        const appPagesToDelete = getAppPagesToDelete(ps, applicationId, onError)
        if (appPagesToDelete) {
            appDeleteData.push(appPagesToDelete)
        }
    }

    const notifyBeforeApplicationDelete = async function (ps, appDefId, onSuccess, onError) {
        const appDefIds = _.castArray(appDefId)
        const appDeleteData = []
        const appsData = appDefIds.reduce((res, appDefinitionId) => {
            const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            if (clientSpecMapService.isAppActive(ps, appData)) {
                res.push(appData)
            }
            return res
        }, [])
        for (const appData of appsData) {
            if (_.includes(excludeApps, appData.appDefinitionId)) {
                throw new Error(`It is not allowed to delete (${appDefId}) app.`)
            }
            const applicationId = _.get(appData, 'applicationId')
            if (clientSpecMapService.hasEditorPlatformPart(clientSpecMapService.getAppData(ps, applicationId))) {
                try {
                    await workerService.notifyBeforeRemoveApp(ps, applicationId)
                } catch (e) {
                    throw new Error('Uninstall App Failed.')
                }
                getAppsPagesToDelete(ps, appDeleteData, appsData, applicationId, onError)
            } else {
                getAppsPagesToDelete(ps, appDeleteData, appsData, applicationId, onError)
            }
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete(appDeleteData)
    }

    return {
        uninstall,
        notifyBeforeApplicationDelete
    }
})
