define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/connections/connections',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/hooks/componentHooks/utils/platformAppComp',
    'experiment'
], function (_, santaCoreUtils, connections, component, dataModel, componentDetectorAPI, platformAppComp, experiment) {
    'use strict'

    function removeControllerConnections(ps, controllerRef) {
        const connectedComponents = connections.getConnectedComponents(ps, controllerRef)

        _.forEach(connectedComponents, function (comp) {
            connections.disconnect(ps, comp, controllerRef)
        })

        if (experiment.isOpen('dm_disconnectMobileComponentsFromControllers')) {
            const mobileControllerRef = ps.pointers.structure.getMobilePointer(controllerRef)
            if (controllerRef.type === 'DESKTOP' && ps.dal.isExist(mobileControllerRef)) {
                removeControllerConnections(ps, mobileControllerRef)
            }
        }
    }

    function handleControllerDeletion(ps, controllerRef) {
        removeControllerConnections(ps, controllerRef)
        platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
        platformAppComp.removeControllerFromStateMap(ps, controllerRef)
    }

    function addRelatedConnections(ps, controllerRef, customStructureData) {
        const connectedComponents = connections.getConnectedComponents(ps, controllerRef)
        if (_.isEmpty(connectedComponents)) {
            return
        }
        const relatedConnections = _.transform(
            connectedComponents,
            function (acc, compRef) {
                acc[compRef.id] = _.filter(connections.get(ps, compRef), {controllerRef})
            },
            {}
        )
        _.set(customStructureData, 'relatedConnections', relatedConnections)
    }

    function handleControllerSerialization(ps, controllerRef, customStructureData) {
        addRelatedConnections(ps, controllerRef, customStructureData)
        platformAppComp.addControllerStateToSerializedStructure(ps, controllerRef, customStructureData)
    }

    function getRelatedConnections(controllerDefinition, oldToNewIdMap) {
        return _(controllerDefinition)
            .chain()
            .get('custom.relatedConnections')
            .transform(function (acc, compConnections, compId) {
                acc[_.get(oldToNewIdMap, compId, compId)] = compConnections
            }, {})
            .value()
    }

    function reconnectComponentsToController(componentsToReconnect, relatedConnections, ps, controllerRef) {
        _.forEach(componentsToReconnect, function (compRef) {
            const compConnections = _.get(relatedConnections, compRef.id, [])
            _.forEach(compConnections, function (connectionItem) {
                connections.connect(ps, compRef, controllerRef, connectionItem.role, connectionItem.config, connectionItem.isPrimary)
            })
        })
    }

    function reconnectComponents(ps, controllerRef, controllerDefinition, optionalCustomId, oldToNewIdMap) {
        const relatedConnections = getRelatedConnections(controllerDefinition, oldToNewIdMap)
        const connectedComponentIds = _.keys(relatedConnections)
        const controllerPageId = component.getPage(ps, controllerRef).id
        const pageToGetCompsFrom = controllerPageId === santaCoreUtils.siteConstants.MASTER_PAGE_ID ? null : controllerPageId
        const componentsToReconnect = componentDetectorAPI.getAllComponentsFromFull(ps, pageToGetCompsFrom, function (compRef) {
            return _.includes(connectedComponentIds, compRef.id)
        })

        reconnectComponentsToController(componentsToReconnect, relatedConnections, ps, controllerRef)
    }

    function handleControllerAddition(ps, controllerRef, controllerDefinition, optionalCustomId, oldToNewIdMap) {
        reconnectComponents(ps, controllerRef, controllerDefinition, optionalCustomId, oldToNewIdMap)
        platformAppComp.setControllerStateFromSerializedStructure(ps, controllerRef, controllerDefinition)
        platformAppComp.addPlatformAppToPageIfFirstController(ps, controllerRef, controllerDefinition.data.applicationId)
    }

    function removeInvalidConnections(ps, controllerRef, newParentPointer) {
        const newPageId = component.getPage(ps, newParentPointer).id
        if (newPageId === santaCoreUtils.siteConstants.MASTER_PAGE_ID) {
            return
        }
        const connectedComponents = connections.getConnectedComponents(ps, controllerRef)
        _(connectedComponents)
            .reject(function (comp) {
                return component.getPage(ps, comp).id === newPageId
            })
            .forEach(function (comp) {
                connections.disconnect(ps, comp, controllerRef)
            })
    }

    function handleControllerParentChange(ps, controllerRef, newParentPointer) {
        const {applicationId} = dataModel.getDataItem(ps, controllerRef)
        removeInvalidConnections(ps, controllerRef, newParentPointer)
        platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
        platformAppComp.addPlatformAppToPageIfFirstController(ps, controllerRef, applicationId, component.getPage(ps, newParentPointer))
    }

    return {
        //Before Remove
        handleControllerDeletion,
        //Custom Serialize
        handleControllerSerialization,
        //After Add
        handleControllerAddition,
        //Change Parent
        handleControllerParentChange
    }
})
