define(['lodash', 'experiment', 'documentServices/wixCode/utils/utils', 'documentServices/wixCode/utils/constants'], function (
    _,
    experiment,
    wixCodeUtils,
    wixCodeConstants
) {
    'use strict'
    const wixCodeProvisionUrlTemplate = _.template('<%= baseUrl %>/api/code/apps?msid=<%= metasiteId %>')
    const wixCodeNewProvisionUrlTemplate = _.template(
        '<%= baseUrl %>/api/code/apps?msid=<%= metasiteId %>&ce=true<%= branchId ? `&branchId=${branchId}` : "" %>'
    )
    const wixCodeCloneAppUrlTemplate = _.template('<%= baseUrl %>/api/code/apps/<%= gridAppId %>/clone?msid=<%= metasiteId %>&isFirstSave=<%= isFirstSave %>')
    const wixCodeCloneImmutableAppUrlTemplate = _.template('<%= baseUrl %>/v1/apps')
    const wixCodeCloneIfNeededUrlTemplate = _.template('<%= baseUrl %>/v1/apps/<%= openGridAppId %>/clone')
    const wixCodeSaveUrlTemplate = _.template('<%= baseUrl %>/api/code/apps/<%= gridAppId %>/save?msid=<%= metasiteId %>')

    const _createHeaders = ({signedInstance, accept, withAutorization = false}) =>
        _.pickBy({
            [withAutorization ? 'Authorization' : 'X-Wix-Si']: signedInstance,
            Accept: accept
        })

    const _createRequest = ({url, data, signedInstance, accept, withAutorization = false}) =>
        _.assign({
            url,
            type: wixCodeUtils.requestTypes.POST,
            contentType: 'application/json',
            data,
            headers: _createHeaders({signedInstance, accept, withAutorization}),
            timeout: 15000,
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        })

    function getBaseUrlFromPS(ps) {
        const pointer = ps.pointers.general.getServiceTopology()
        return ps.dal.get(ps.pointers.getInnerPointer(pointer, wixCodeConstants.WIX_CODE_SERVICE_URL_KEY))
    }

    const create = async ({baseUrl, metasiteId, signedInstance, branchId}) => {
        const url = experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')
            ? wixCodeNewProvisionUrlTemplate({baseUrl, metasiteId, branchId})
            : wixCodeProvisionUrlTemplate({baseUrl, metasiteId})

        // @ts-ignore
        const request = _createRequest({url, data: {}, signedInstance})
        const response = await wixCodeUtils.sendRequestObj(request)
        return response.payload
    }

    const save = ({baseUrl, gridAppId, metasiteId, signedInstance}) => {
        const url = wixCodeSaveUrlTemplate({baseUrl, gridAppId, metasiteId})
        // @ts-ignore
        const request = _createRequest({url, data: {}, signedInstance})
        return wixCodeUtils.sendRequestObj(request)
    }

    const clone = async ({baseUrl, gridAppId, metasiteId, signedInstance, isFirstSave}) => {
        const url = wixCodeCloneAppUrlTemplate({baseUrl, gridAppId, metasiteId, isFirstSave})
        const accept = '*/*'
        const request = _createRequest({url, data: {}, signedInstance, accept})
        const response = await wixCodeUtils.sendRequestObj(request)
        return response.payload
    }

    const cloneImmutable = async ({baseUrl, gridAppId, signedInstance}) => {
        const url = wixCodeCloneImmutableAppUrlTemplate({baseUrl})
        const request = _createRequest({
            withAutorization: true,
            url,
            data: {
                app: {
                    sourceAppId: gridAppId,
                    mutable: false
                }
            },
            signedInstance,
            accept: '*/*'
        })
        const response = await wixCodeUtils.sendRequestObj(request)
        return {newGridAppId: response.app.id}
    }

    const cloneIfNeeded = async ({baseUrl, openGridAppId, lastRevisionGridAppId, signedInstance}) => {
        const url = wixCodeCloneIfNeededUrlTemplate({baseUrl, openGridAppId})
        const request = _createRequest({
            withAutorization: true,
            url,
            data: {
                openGridAppId,
                latestRevisionGridAppId: lastRevisionGridAppId
            },
            signedInstance,
            accept: '*/*'
        })
        const response = await wixCodeUtils.sendRequestObj(request)
        return {newGridAppId: response.app.id}
    }

    return {
        create,
        save,
        clone,
        getBaseUrlFromPS,
        cloneImmutable,
        cloneIfNeeded
    }
})
