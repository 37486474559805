define(['documentServices/siteMetadata/sitePropertiesInfo'], function (sitePropertiesInfo) {
    'use strict'

    const TASK_NAME = 'reloadSiteProperyInfoAfterFirstSave'

    const {fetchSitePropertiesInfoWithSnapshotDal} = sitePropertiesInfo

    const onSuccess = (resolve, response) => {
        resolve({
            changes: [
                {
                    path: ['rendererModel', 'sitePropertiesInfo', 'language'],
                    value: response.language
                },
                {
                    path: ['rendererModel', 'sitePropertiesInfo', 'currency'],
                    value: response.paymentCurrency
                },
                {
                    path: ['rendererModel', 'sitePropertiesInfo', 'timeZone'],
                    value: response.timeZone
                }
            ]
        })
    }

    const createReloadSitePropertiesInfo = () => (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) => {
        const orError = resolve
        const {editorOrigin} = options
        fetchSitePropertiesInfoWithSnapshotDal(currentSnapshotDal, onSuccess.bind(null, resolve), orError, editorOrigin)
    }

    const createTask = () => {
        const reloadSitePropertiesInfo = createReloadSitePropertiesInfo()

        return {
            partialSave(lastSavedData, currentData, resolve) {
                resolve()
            },
            fullSave(lastSavedData, currentData, resolve) {
                resolve()
            },
            firstSave: reloadSitePropertiesInfo,
            saveAsTemplate(lastSavedData, currentData, resolve) {
                resolve()
            },
            publish(currentData, resolve) {
                resolve()
            },
            autosave(lastSnapshot, currentSnapshot, resolve) {
                resolve()
            },
            getTaskName() {
                return TASK_NAME
            },
            getSnapshotTags(methodName) {
                switch (methodName) {
                    case 'autosave':
                        return ['autosave']
                    default:
                        return ['primary']
                }
            }
        }
    }

    return () => createTask()
})
