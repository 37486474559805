import {DocumentManager, Pointer, pointerUtils} from '@wix/document-manager-core'
import {dataUtils, constants} from '@wix/document-manager-extensions'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import {deepClone} from '@wix/wix-immutable-proxy'
import {ReportableError} from '@wix/document-manager-utils'
import type {Behavior} from '@wix/viewer-manager-interface'

const {getPointer} = pointerUtils
const {DESKTOP} = constants.VIEW_MODES

function getBehaviorDataAndPointer(query: string, documentManager: DocumentManager, pageId: string) {
    const pointer = query && documentManager.pointers.data.getBehaviorsItem(query, pageId)
    const data = pointer && deepClone(documentManager.dal.get(pointer))

    return {
        data,
        pointer
    }
}

const migrateBehavior = (documentManager: DocumentManager, pointer: Pointer, compStructure: any) => {
    const {pageId} = compStructure.metaData
    const sectionBehaviorDataAndPointer = getBehaviorDataAndPointer(compStructure.behaviorQuery, documentManager, pageId)
    const sectionBehaviorData = sectionBehaviorDataAndPointer.data
    const sectionBehaviorPointer = sectionBehaviorDataAndPointer.pointer as Pointer

    if (sectionBehaviorData) {
        try {
            // get and parse screenIn behavior
            const sectionBehaviors = JSON.parse(sectionBehaviorData.items)
            const sectionScreenIn = sectionBehaviors.find((item: {action: string}) => item.action === 'screenIn')

            if (!sectionScreenIn) {
                return
            }

            // loop over children
            compStructure.components.forEach((childId: string) => {
                const childPointer = getPointer(childId, constants.VIEW_MODES.DESKTOP)
                const childComp = documentManager.dal.get(childPointer)
                let updatedChildParsedItems: Array<Behavior> = []
                let childBehaviorsPointer
                let behaviorsPointerId
                let childBehaviorsData

                try {
                    if (childComp.behaviorQuery) {
                        // get existing behaviors of child and check for screenIn
                        const dataAndPointer = getBehaviorDataAndPointer(childComp.behaviorQuery, documentManager, pageId)
                        childBehaviorsPointer = dataAndPointer.pointer
                        childBehaviorsData = dataAndPointer.data
                        const parseBehaviorItems = JSON.parse(childBehaviorsData.items)
                        const hasScreenIn = parseBehaviorItems.some((item: {action: string}) => item.action === 'screenIn')

                        if (hasScreenIn) {
                            // if we have screenIn on chlid => leave as is and continue
                            return
                        }

                        updatedChildParsedItems = parseBehaviorItems
                    }
                } catch (e: any) {
                    documentManager.logger.captureError(
                        new ReportableError({
                            errorType: 'sectionMigrationChildBehaviorError',
                            message: e.message,
                            extras: {
                                page: compStructure.metaData.pageId,
                                childId,
                                section: compStructure.id,
                                behaviors: childBehaviorsData?.items
                            }
                        })
                    )
                    return
                }

                // add Section's screenIn to child's behaviors
                updatedChildParsedItems.push({...sectionScreenIn, targetId: childId} as Behavior)

                if (!childBehaviorsPointer) {
                    // generate pointer if there's non
                    const type = namespaceMapping.DATA_TYPES.behaviors
                    behaviorsPointerId = dataUtils.generateUniqueIdByType(type, compStructure.metaData.pageId, documentManager.dal, documentManager.pointers)
                    childBehaviorsPointer = getPointer(behaviorsPointerId, type)

                    if (!childComp.behaviorQuery) {
                        // add behavior to child's structure if it's new
                        const childCompCopy = deepClone(childComp)
                        childCompCopy.behaviorQuery = behaviorsPointerId
                        documentManager.dal.set(childPointer, childCompCopy)
                    }
                }

                // update or create a full behaviors item with the updated items
                if (!childBehaviorsData) {
                    childBehaviorsData = {
                        type: 'ObsoleteBehaviorsList',
                        id: behaviorsPointerId,
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false, pageId: compStructure.metaData.pageId}
                    }
                } else {
                    childBehaviorsData = deepClone(childBehaviorsData)
                }

                childBehaviorsData.items = JSON.stringify(updatedChildParsedItems)

                // set updated item with the pointer
                documentManager.dal.set(childBehaviorsPointer, childBehaviorsData)
            })

            // remove Section's screenIn from behaviors and update
            sectionBehaviors.splice(sectionBehaviors.indexOf(sectionScreenIn), 1)
            sectionBehaviorData.items = JSON.stringify(sectionBehaviors)
            documentManager.dal.set(sectionBehaviorPointer, sectionBehaviorData)
        } catch (e: any) {
            documentManager.logger.captureError(
                new ReportableError({
                    errorType: 'sectionMigrationBehaviorError',
                    message: e.message,
                    extras: {
                        page: compStructure.metaData.pageId,
                        section: compStructure.id,
                        behaviors: sectionBehaviorData?.items
                    }
                })
            )
        }
    }
}

const migrateComponent = (documentManager: DocumentManager, pointer: Pointer, compStructure: any, viewMode: string) => {
    const isExpOpen = documentManager.experimentInstance.isOpen('dm_sectionDesignDataMigration')
    if (viewMode === DESKTOP && isExpOpen && !compStructure.designQuery && compStructure.behaviorQuery) {
        migrateBehavior(documentManager, pointer, compStructure)
    }
}

const components = {
    'responsive.components.Section': migrateComponent
}

export {components}
