import type {PublicMethodUtils, ViewerLibrary} from '../../publicAPI/apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const componentStylesAndSkinsAPI = viewerLibrary.get('documentServices/component/componentStylesAndSkinsAPI')

    return {
        methods: {
            components: {
                style: {
                    getId: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.getId),
                    get: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.style.get),
                    update: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.update),
                    connectToTheme: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.connectToThemeStyle),
                    scoped: {
                        remove: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.removeScoped)
                    },
                    fork: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.style.fork)
                }
            }
        }
    }
}
