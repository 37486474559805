define(['documentServices/constants/constants', 'documentServices/page/pageData'], function (constants, pageData) {
    'use strict'

    const {
        DATA_TYPES,
        MASTER_PAGE_ID,
        ATOMIC_SCOPES: {TYPE, URI_PREFIX}
    } = constants

    const getAtomicScopePointer = (ps, id) => ps.pointers.data.getItem(DATA_TYPES.atomicScopes, id, MASTER_PAGE_ID)

    const addAtomicScope = (ps, id) => {
        const data = {type: TYPE, id}
        const pointer = getAtomicScopePointer(ps, id)
        ps.dal.set(pointer, data)
    }

    const removeAtomicScope = (ps, id) => {
        const pointer = getAtomicScopePointer(ps, id)
        ps.dal.remove(pointer)
    }

    const changeAtomicScope = (ps, oldId, newId) => {
        removeAtomicScope(ps, oldId)
        addAtomicScope(ps, newId)
    }

    const getPageUriAtomicScopeId = (ps, pageId) => {
        const pageUriSEO = pageData.getPageUriSEO(ps, pageId)
        return `${URI_PREFIX}-${pageUriSEO}`
    }

    const getRouterAtomicScopeId = (ps, routerPointer) => {
        const routerData = ps.dal.get(routerPointer)
        const {prefix} = routerData
        return `${URI_PREFIX}-${prefix}`
    }

    return {
        addAtomicScope,
        removeAtomicScope,
        changeAtomicScope,
        getAtomicScopePointer,
        getPageUriAtomicScopeId,
        getRouterAtomicScopeId
    }
})
