define([
    'lodash',
    'documentServices/environment/environment',
    'documentServices/page/page',
    'documentServices/component/component',
    'documentServices/component/componentCode',
    'documentServices/component/nicknameContextRegistrar',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/dataModel/dataModel',
    'documentServices/features/features',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/appStudio/nameGenerator',
    'documentServices/appStudio/appStudioPresets',
    'documentServices/appStudio/appStudioPresetsHooks',
    'documentServices/appStudio/widgetConfigs/widgetConfigs',
    'documentServices/appStudio/constants',
    'documentServices/appStudio/appStudioStructureFixer',
    'documentServices/appStudio/blocksLifecycle',
    'documentServices/appStudio/appStudioAppName',
    'documentServices/appStudio/appStudioAppDescription',
    'documentServices/hooks/hooks',
    'documentServices/page/pageData',
    'documentServices/constants/constants',
    'documentServices/appStudio/appStudioSchemaHelpers',
    'documentServices/appStudio/appMetadata',
    'documentServices/appStudio/appBuilderPlatformApp',
    'documentServices/platform/platform',
    'documentServices/wixCode/utils/codeAppInfo',
    'documentServices/utils/utils',
    '@wix/santa-core-utils',
    'documentServices/appStudioWidgets/appStudioWidgets',
    'documentServices/appStudio/appStudioDependencies',
    'documentServices/appStudio/appStudioBobApp',
    'documentServices/appStudio/widgetDescriptors',
    'documentServices/appStudio/appStudioAppDataUtils',
    'documentServices/appStudio/appStudioCodePackages',
    'documentServices/appStudio/appStudioPanels',
    'documentServices/appStudio/nickname',
    'documentServices/documentMode/documentMode',
    'documentServices/appStudio/widgetConfig',
    'documentServices/appStudio/appType',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/utils/contextAdapter',
    'documentServices/platform/provision',
    '@wix/document-manager-utils'
], function (
    _,
    environment,
    page,
    component,
    componentCode,
    nicknameContextRegistrar,
    componentDetectorAPI,
    dataModel,
    features,
    refComponentUtils,
    appStudioDataModel,
    nameGenerator,
    appStudioPresets,
    appStudioPresetsHooks,
    widgetConfigs,
    appStudioConstants,
    appStudioStructureFixer,
    blocksLifecycle,
    appStudioAppName,
    appStudioAppDescription,
    hooks,
    pageData,
    constants,
    appStudioSchemaHelpers,
    appMetadata,
    appBuilderPlatformApp,
    platform,
    codeAppInfoUtils,
    dsUtils,
    coreUtilsLib,
    appStudioWidgets,
    appStudioDependencies,
    appStudioBobApp,
    widgetDescriptors,
    appStudioAppDataUtils,
    appStudioCodePackages,
    appStudioPanels,
    appStudioNickname,
    documentMode,
    widgetConfig,
    appType,
    metaDataUtils,
    contextAdapter,
    provision,
    documentManagerUtils
) {
    'use strict'

    const APP_STUDIO_DATA_TYPE = 'AppStudioData'
    const WIDGET_TYPE = 'WidgetDescriptor'
    const VARIATION_TYPE = 'VariationDescriptor'
    const NEW_WIDGET_PREFIX = 'Widget'
    const NEW_VARIATION_PREFIX = 'Variation'
    const APP_WIDGET_TYPE = 'platform.components.AppWidget'

    const {fetchJson} = coreUtilsLib.requestsUtil
    const {ReportableError} = documentManagerUtils

    /**
     * @param {ps} ps
     * @param compRef
     * @returns {*}
     */
    function getCompsFromVariationPages(ps, compRef) {
        const pageContainer = component.getPage(ps, compRef)
        if (!pageContainer || !appStudioDataModel.isWidgetPage(ps, pageContainer.id) || appStudioDataModel.isVariationPage(ps, pageContainer.id)) {
            return
        }

        const widgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, pageContainer.id)

        const compNickName = appStudioNickname.get(ps, compRef)

        if (!compNickName) {
            return
        }
        const variations = getWidgetVariations(ps, widgetPointer)

        return _(variations)
            .map(variation => {
                const pageId = appStudioDataModel.getRootCompIdByPointer(ps, variation.pointer)
                const allComps = componentDetectorAPI.getAllComponentsFromFull(ps, pageId)
                return _.filter(allComps, comp => appStudioNickname.get(ps, comp) === compNickName)
            })
            .flatten()
            .value()
    }

    /**
     * @param {ps} ps
     * @param compRef
     */
    function removeFromAllVariations(ps, compRef) {
        const isDesktop = ps.pointers.components.getViewMode(compRef) === constants.VIEW_MODES.DESKTOP
        if (isDesktop) {
            const compsToDelete = getCompsFromVariationPages(ps, compRef)
            _.forEach(compsToDelete, comp => component.remove(ps, comp))
        }
    }

    /**
     * @param {ps} ps
     * @param compRef
     * @param newNickName
     */
    function setNicknameInAllVariations(ps, compRef, newNickName) {
        const compsInVariations = getCompsFromVariationPages(ps, compRef)
        _.forEach(compsInVariations, comp => componentCode.setNickname(ps, comp, newNickName))
    }

    const propertiesToSync = ['isCollapsed', 'isHidden', 'isDisabled']

    /**
     * @param {ps} ps
     * @param compRef
     * @param propertiesItem
     */
    function setPropertyInAllVariations(ps, compRef, propertiesItem) {
        const compsInVariations = getCompsFromVariationPages(ps, compRef)
        const propertiesToUpdate = _.pick(propertiesItem, propertiesToSync)
        if (!_.isEmpty(propertiesToUpdate)) {
            _.forEach(compsInVariations, comp => dataModel.updatePropertiesItem(ps, comp, propertiesToUpdate))
        }
    }

    const getGhostControllerData = (ps, widgetContext, compRef, nickname) => {
        const compData = component.data.get(ps, widgetContext)
        compData.settings = JSON.parse(compData.settings)
        const connections = appStudioWidgets.getPrimaryConnectionItems(ps, compRef)
        const controllerId = _.get(connections, '0.controllerId')
        const compId = `${_.uniqueId('ghost-')}_${nickname}`

        return {
            nickname,
            controllerData: compData,
            compId,
            connections,
            controllerBehaviors: compData.settings.behaviors,
            dependencies: [controllerId]
        }
    }

    const getGhostStructureByWidgetPointer = (ps, widgetPointer) => {
        const widgetContext = appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
        const pageStructure = component.serialize(ps, widgetContext)
        return buildGhostStructure(ps, pageStructure)
    }

    const getGhostControllersInfoFromGhostStructure = rootIdToGhostStructure => {
        let controllersInfo = _.reduce(
            rootIdToGhostStructure,
            (result, value) => {
                const controller = _.mapValues(value.controllers, controllerInfo => {
                    const {type} = controllerInfo.controllerData.settings
                    const connections = _.map(rootIdToGhostStructure[type].components, compRef => _.get(compRef, ['connections', 'items', 0]))
                    controllerInfo.connections = connections
                    return controllerInfo
                })
                _.assign(result, controller)
                return result
            },
            {}
        )

        controllersInfo = _.mapKeys(controllersInfo, value => value.compId)
        return controllersInfo
    }

    const setGhostableData = ps => {
        const focusedWidgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, ps.siteAPI.getFocusedRootId())

        if (!focusedWidgetPointer) {
            return
        }

        const containedRefComponents = appStudioDataModel.getContainedWidgets(ps, focusedWidgetPointer, {})
        const innerWidgetsPointers = _.map(containedRefComponents, ps.pointers.data.getDataItemFromMaster)

        const widgetsInPage = _.concat(focusedWidgetPointer, innerWidgetsPointers)

        const rootIdToGhostStructure = _(widgetsInPage)
            .keyBy(widgetPointer => appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer))
            .mapValues(widgetPointer => getGhostStructureByWidgetPointer(ps, widgetPointer))
            .value()

        const ghostStructure = {
            [appStudioConstants.APP_BUILDER_PREVIEW_APP_ID]: _.mapValues(rootIdToGhostStructure, value => value.components)
        }
        const controllers = getGhostControllersInfoFromGhostStructure(rootIdToGhostStructure)
        const ghostControllers = {
            [appStudioConstants.APP_BUILDER_PREVIEW_APP_ID]: controllers
        }

        platform.setGhostStructure(ps, ghostStructure)
        platform.setGhostControllers(ps, ghostControllers)
    }

    const updateBlocksPreviewData = (ps, dataToUpdate) => {
        const currentData = ps.dal.get(ps.pointers.blocks.getBlocksPreviewData())
        const updatedData = _.assign(currentData, dataToUpdate)
        ps.dal.set(ps.pointers.blocks.getBlocksPreviewData(), updatedData)
    }

    /**
     * @param {ps} ps
     * @param viewMode
     */
    function handleViewModeChange(ps, viewMode) {
        if (viewMode === 'preview') {
            setGhostableData(ps)
            updateBlocksPreviewData(ps, {widgetDescriptorsMap: widgetDescriptors.createPublicDescriptorsMap(ps)})
        }
    }

    function displayFirstWidget(ps) {
        const mainPageId = page.homePage.get(ps)
        const firstWidgetOnLoad = appStudioDataModel.getWidgetByRootCompId(ps, mainPageId)
        displayFirstPreset(ps, firstWidgetOnLoad)
    }

    function validateAndUpdateRootContainerHeight(ps, newLayout, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const parentType = parentPointer && metaDataUtils.getComponentType(ps, parentPointer)
        if (parentType === APP_WIDGET_TYPE) {
            if (newLayout.componentLayout.height?.type && newLayout.componentLayout.height?.type !== 'auto') {
                contextAdapter.utils.fedopsLogger.captureError(
                    new ReportableError({
                        message: 'Attempted to set height to root container',
                        errorType: 'heightToBlocksRootContainer',
                        tags: {blocks: true},
                        extras: {newLayout: _.cloneDeep(newLayout), compPointer}
                    })
                )
                newLayout.componentLayout.height = {type: 'auto'}
            }
            if (newLayout.componentLayout.minHeight?.value && newLayout.componentLayout.minHeight?.value !== 0) {
                contextAdapter.utils.fedopsLogger.captureError(
                    new ReportableError({
                        message: 'Attempted to set min-height to root container',
                        errorType: 'minHeightToBlocksRootContainer',
                        tags: {blocks: true},
                        extras: {newLayout: _.cloneDeep(newLayout), compPointer}
                    })
                )
                newLayout.componentLayout.minHeight.value = 0
            }
        }

        return newLayout
    }

    function setHooks() {
        hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, removeFromAllVariations)
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, updateContainedWidgetsIfNeeded, 'wysiwyg.viewer.components.RefComponent')
        hooks.registerHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_BEFORE, setNicknameInAllVariations)
        hooks.registerHook(hooks.HOOKS.PROPERTIES.UPDATE_AFTER, setPropertyInAllVariations)
        hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, removeFromAllVariations)
        hooks.registerHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.BEFORE, handleViewModeChange)
        hooks.registerHook(hooks.HOOKS.METADATA.MAXIMUM_CHILDREN_NUMBER, shouldPageHaveOneChild, 'mobile.core.components.Page')
        hooks.registerHook(hooks.HOOKS.ADD.AFTER, appStudioPresetsHooks.createPresetsForDuplicatedWidget, 'mobile.core.components.Page')
        hooks.registerHook(hooks.HOOKS.RESPONSIVE_LAYOUT.BEFORE_UPDATE, validateAndUpdateRootContainerHeight, 'mobile.core.components.Container')
    }

    /**
     * @param {ps} ps
     */
    function initialize(ps) {
        ps.dal.set(ps.pointers.general.getMobileConversionHeuristicStrategy(), 'appbuilder')
        ps.dal.set(ps.pointers.general.getRenderFlag('isWixBlocks'), true)
        documentMode.setShouldKeepTPAComps(ps, false)
        displayFirstWidget(ps)
        setHooks()
        nicknameContextRegistrar.setContextProvider(getComponentNicknameContext)
        appBuilderPlatformApp.updateApp(ps)
    }

    function displayFirstPreset(ps, widgetPointer) {
        const [firstPreset] = appStudioPresets.getWidgetPresets(ps, widgetPointer)

        if (firstPreset) {
            appStudioPresets.displayPreset(ps, firstPreset.pointer, widgetPointer)
        }
    }

    function shouldPageHaveOneChild(ps, metaDataValue, pagePtr) {
        if (isConfigurationPage(ps, pagePtr) || appStudioDataModel.isWidgetPage(ps, pagePtr.id)) {
            return 1
        }
        return metaDataValue
    }

    function isConfigurationPage(ps, pageRef) {
        const intentData = features.getFeatureData(ps, pageRef, 'intent')
        return !!intentData && intentData.intent === 'configurationPage'
    }

    function updateContainedWidgetsIfNeeded(ps, compRef, deletingParent, removeArgs, deletedParentFromFull, copyDataItem, parentPointer) {
        const pageContainer = component.getPage(ps, parentPointer)
        if (pageContainer && appStudioDataModel.isWidgetPage(ps, pageContainer.id)) {
            appStudioDataModel.updateWidgetContainedWidgets(ps)
        }
    }

    function getComponentNicknameContext(ps, compPointer) {
        if (compPointer) {
            const pagePointer = component.isPageComponent(ps, compPointer) ? compPointer : component.getPage(ps, compPointer)

            if (!pagePointer) {
                return null
            }

            if (ps.pointers.components.isMasterPage(pagePointer)) {
                return null
            }

            if (pagePointer) {
                return appStudioDataModel.getRootWidgetByPage(ps, pagePointer)
            }
        }

        return null
    }

    /**
     * @param {ps} ps
     * @param appStudioData
     * @returns {*}
     */
    function createBlankAppStudioData(ps, appStudioData) {
        const appStudioDefaults = dataModel.createDataItemByType(ps, APP_STUDIO_DATA_TYPE)
        appStudioDefaults.id = appStudioDataModel.getNewDataId()
        const siteName = ps.dal.getByPath(['documentServicesModel', 'metaSiteData', 'siteName']) || 'MyProject1' //TODO: key from Uval
        appStudioDefaults.name = _.replace(siteName, 'mysite', 'MyProject')

        return _.defaults(appStudioDefaults, appStudioData)
    }

    /**
     * @param {ps} ps
     * @param newWidgetsList
     */
    function updateWidgetsOnMasterPage(ps, newWidgetsList) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        appStudioData.widgets = newWidgetsList
        appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudioData)
    }

    /**
     * @param {ps} ps
     * @param [appStudioData]
     */
    function createAppStudioIfNeeded(ps, appStudioData) {
        let appStudio = appStudioDataModel.getAppStudioData(ps)
        if (_.isUndefined(appStudio)) {
            appStudio = createBlankAppStudioData(ps, appStudioData)
            appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudio)
        }
    }

    function provisionWixBlocks(ps) {
        return provision.provision(ps, appStudioConstants.APP_BUILDER_PREVIEW_APP_ID)
    }

    function getDevSiteAppDefId(ps) {
        const devSiteAppDefIdPointer = ps.pointers.general.getDevSiteAppDefIdPointer()
        return ps.dal.get(devSiteAppDefIdPointer)
    }

    function addWidgetToAppStudio(ps, newWidget) {
        const newWidgetsList = appStudioDataModel.getAppStudioData(ps).widgets
        newWidgetsList.push(newWidget)
        updateWidgetsOnMasterPage(ps, newWidgetsList)
    }

    function createBlankWidgetData(ps, widgetID, widgetName, widgetKind, newPageRef) {
        const newWidget = dataModel.createDataItemByType(ps, WIDGET_TYPE)
        newWidget.id = widgetID
        newWidget.name = widgetName
        newWidget.kind = widgetKind
        newWidget.rootCompId = `#${_.get(newPageRef, 'id')}`

        return newWidget
    }

    function createBlankVariationData(ps, variationPointer, variationName, newPageRef) {
        const variationDataItem = dataModel.createDataItemByType(ps, VARIATION_TYPE)
        variationDataItem.id = variationPointer.id
        variationDataItem.name = variationName
        variationDataItem.rootCompId = `#${_.get(newPageRef, 'id')}`

        ps.dal.set(variationPointer, variationDataItem)

        return variationDataItem
    }

    function validateWidget(ps, name) {
        if (_.isEmpty(name)) {
            throw new Error('appStudio.widgets: name is required')
        }

        if (_.includes(_.map(appStudioDataModel.getAllWidgets(ps), 'name'), name)) {
            throw new Error('appStudio.widgets: widget name already exists')
        }
    }
    function validateVariationName(ps, variationName, widgetPointer) {
        if (_.isEmpty(variationName)) {
            throw new Error('appStudio.variations: name is required')
        }

        if (_.includes(_.map(getWidgetVariations(ps, widgetPointer), 'name'), variationName)) {
            throw new Error('appStudio.variations: variation name already exists')
        }
    }

    function addRootStructure(ps, rootStructure, pageRef, controllerType, newWidgetConfig) {
        const rootRef = component.getComponentToAddRef(ps, pageRef)
        const structureToAdd = _.defaultsDeep({components: [rootStructure]}, newWidgetConfig.appWidgetStructure)
        component.add(ps, rootRef, pageRef, structureToAdd)
        appStudioWidgets.setInitialAppWidgetData(ps, rootRef, controllerType || pageRef.id)
    }

    function addRootStructureWrappedWithStageContainer(ps, rootStructure, pageRef, controllerType, newWidgetConfig) {
        const stageContainerRef = component.getComponentToAddRef(ps, pageRef)
        const rootWidgetStructure = _.defaultsDeep({components: [rootStructure]}, newWidgetConfig.appWidgetStructure)
        const structureToAdd = _.defaultsDeep({components: [rootWidgetStructure]}, newWidgetConfig.stageContainerStructure)
        component.add(ps, stageContainerRef, pageRef, structureToAdd)

        const rootWidgetRef = appStudioDataModel.getRootWidgetByPage(ps, pageRef)
        appStudioWidgets.setInitialAppWidgetData(ps, rootWidgetRef, controllerType || pageRef.id)
    }

    function createWidgetPageWithStructure(ps, widgetName, newPageRef, controllerType, initialWidgetRootStructure, newWidgetPointer, presetDescriptors) {
        const isResponsive = environment.isResponsiveDocument(ps)
        const newWidgetConfig = isResponsive ? widgetConfigs.createNewResponsiveWidgetConfig() : widgetConfigs.createNewWidgetConfig()
        page.add(ps, newPageRef, widgetName, newWidgetConfig.getPageStructure(ps))

        if (presetDescriptors) {
            createPresets(ps, newWidgetPointer, presetDescriptors, initialWidgetRootStructure)
        } else {
            createFirstPreset(ps, newWidgetPointer)
        }

        if (initialWidgetRootStructure) {
            if (isResponsive) {
                addRootStructureWrappedWithStageContainer(ps, initialWidgetRootStructure, newPageRef, controllerType, newWidgetConfig)
            } else {
                addRootStructure(ps, initialWidgetRootStructure, newPageRef, controllerType, newWidgetConfig)
            }
        }
    }

    function createWidget(ps, widgetDataId, widgetNameOrOptions, initialWidgetRootStructureOrCallback, callback) {
        let options

        if (typeof widgetNameOrOptions === 'object' && widgetNameOrOptions !== null) {
            options = widgetNameOrOptions
            callback = initialWidgetRootStructureOrCallback
        } else {
            options = {
                widgetName: widgetNameOrOptions,
                initialWidgetRootStructure: initialWidgetRootStructureOrCallback
            }
        }

        return _createWidget(ps, widgetDataId, options, callback)
    }

    function mapVariantsOldToNew(compStructure, scopedDataMapName, oldToNewVariantIdMap) {
        compStructure[scopedDataMapName] = _.mapKeys(compStructure[scopedDataMapName], (scopedDataItem, oldId) => oldToNewVariantIdMap[oldId])
    }

    function updateScopedMaps(compStructure, oldToNewVariantIdMap) {
        mapVariantsOldToNew(compStructure, 'scopedStyles', oldToNewVariantIdMap)
        mapVariantsOldToNew(compStructure, 'scopedLayouts', oldToNewVariantIdMap)
        _.forEach(compStructure.components, childCompStructure => updateScopedMaps(childCompStructure, oldToNewVariantIdMap))
    }

    function createPresets(ps, widgetPointer, presetDescriptors, widgetRootStructure) {
        const oldToNewVariantIdMap = {}
        _.forEach(presetDescriptors, ({name: presetName, variantId, defaultSize}) => {
            const newPresetPointer = appStudioDataModel.getNewDataItemPointer(ps)
            appStudioPresets.createPreset(ps, newPresetPointer, widgetPointer, {newPresetName: presetName})
            appStudioPresets.setPresetDefaultSize(ps, newPresetPointer, defaultSize)
            const newVariantId = appStudioPresets.getPresetVariantId(ps, newPresetPointer)
            oldToNewVariantIdMap[variantId] = newVariantId
        })
        updateScopedMaps(widgetRootStructure, oldToNewVariantIdMap)
    }

    function _createWidget(ps, widgetDataId, options = {}, callback) {
        const {widgetKind, initialWidgetRootStructure, presetDescriptors} = options

        const widgetName = options.widgetName || generateNewWidgetName(ps, NEW_WIDGET_PREFIX)
        createAppStudioIfNeeded(ps)
        provisionWixBlocks(ps)
        validateWidget(ps, widgetName)

        const newPageRef = page.getPageIdToAdd(ps)
        const newWidget = createBlankWidgetData(ps, widgetDataId, widgetName, widgetKind, newPageRef)
        addWidgetToAppStudio(ps, newWidget)

        const newWidgetPointer = ps.pointers.data.getDataItemFromMaster(newWidget.id)
        createWidgetPageWithStructure(ps, widgetName, newPageRef, newPageRef.id, initialWidgetRootStructure, newWidgetPointer, presetDescriptors)

        if (_.isFunction(callback)) {
            callback(newWidgetPointer)
        }

        return newWidgetPointer
    }

    function createFirstPreset(ps, widgetPointer) {
        const isResponsive = environment.isResponsiveDocument(ps)
        if (isResponsive) {
            const presetPointer = appStudioDataModel.getNewDataItemPointer(ps)
            appStudioPresets.createPreset(ps, presetPointer, widgetPointer)
        }
    }

    function createVariation(ps, variationPointer, widgetPointer, initialRootStructure, duplicatePageId, newVariationName) {
        const isFirstVariation = _.isEmpty(getWidgetVariations(ps, widgetPointer))

        if (widgetPointer) {
            const variationName = newVariationName || generateNewVariationName(ps, NEW_VARIATION_PREFIX, widgetPointer)
            validateVariationName(ps, variationName, widgetPointer)

            const newPageRef = page.getPageIdToAdd(ps)
            const variationData = createBlankVariationData(ps, variationPointer, variationName, newPageRef)
            addVariationToWidgetDataItem(ps, variationData, widgetPointer)

            if (ps.pointers.page.isExists(duplicatePageId)) {
                page.duplicate(ps, newPageRef, duplicatePageId, undefined, false)
                setPageTitle(ps, newPageRef.id, variationName)
            } else {
                const widgetPageId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
                createWidgetPageWithStructure(ps, variationName, newPageRef, widgetPageId, initialRootStructure)
            }
            if (isFirstVariation) {
                updateAllInstancesToPointToVariation(ps, widgetPointer, duplicatePageId, variationPointer)
            }
        }
    }

    function updateAllInstancesToPointToVariation(ps, currentVariationPointer, currentVariationPageId, newVariationPointer) {
        const allWidgets = appStudioDataModel.getAllWidgets(ps)
        const innerWidgetsForVariationChange = getWidgetInstances(ps, currentVariationPointer, currentVariationPageId, allWidgets)
        const newVariationRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, newVariationPointer)

        _.forEach(innerWidgetsForVariationChange, widgetRef =>
            appStudioWidgets.changeVariation(ps, widgetRef, newVariationRootCompId, undefined, undefined, {keepOverrides: true})
        )
    }

    function getWidgetInstances(ps, widgetPointer, widgetPageId, possibleContainingWidgets) {
        return _.flatMap(possibleContainingWidgets, containingWidget => {
            const instancesInWidgets = getFirstInnerWidgetsLayerWithRootCompId(ps, containingWidget.pointer, widgetPageId)

            const variations = getWidgetVariations(ps, containingWidget.pointer)
            const instancesInVariations = getWidgetInstances(ps, widgetPointer, widgetPageId, variations)
            return _.concat(instancesInWidgets, instancesInVariations)
        })
    }

    const getFirstInnerWidgetsLayerWithRootCompId = (ps, containingWidgetPointer, rootCompId) => {
        const innerWidgets = appStudioDataModel.getFirstLevelRefChildren(ps, containingWidgetPointer)
        return _.filter(innerWidgets, refComp => {
            const {pageId} = dataModel.getDataItem(ps, refComp)
            return pageId === rootCompId
        })
    }

    function addVariationToWidgetDataItem(ps, newVariation, widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        if (widgetData) {
            widgetData.variations.push(`#${newVariation.id}`)
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        }
    }

    function validateNames(newData) {
        const allFunctionsHasName = _.every(newData, 'name')
        if (!allFunctionsHasName) {
            throw new Error('appStudio.widgets: can not set widget API without a name')
        }

        const allNamesAreUniq = _.uniqBy(newData, 'name').length === newData.length
        if (!allNamesAreUniq) {
            throw new Error('appStudio.widgets: API name is already in use for this widget')
        }
    }

    function validateAndFixParams(newData) {
        const fixParamsIfNeeded = param => _.mapValues(param, value => (_.isNil(value) ? '' : value))
        return _.forEach(newData, data => {
            const {params} = data
            if (params) {
                _.set(
                    data,
                    'params',
                    _.map(params, param => fixParamsIfNeeded(param))
                )
            }
        })
    }

    function validateSchemasNames(newData) {
        const allNamesAreUniq = _.uniqBy(newData, item => appStudioSchemaHelpers.getSchemaStructureKey(item)).length === newData.length
        if (!allNamesAreUniq) {
            throw new Error('appStudio.widgets: property name is already in use for this widget')
        }
    }

    function removeFunctionFromObject(object) {
        return JSON.parse(JSON.stringify(object))
    }

    /**
     * @param {ps} ps
     * @param {Pointer} widgetPointer
     * @param oldProps
     * @param newProps
     */
    function notifyPropertiesUpdateToWorker(ps, widgetPointer, oldProps, newProps) {
        if (!_.isEqual(oldProps, newProps)) {
            const pageRef = ps.pointers.components.getPage(dsUtils.stripHashIfExists(ps.siteAPI.getFocusedRootId()), constants.VIEW_MODES.DESKTOP)
            const widgetRoot = appStudioDataModel.getRootWidgetByPage(ps, pageRef)
            ps.siteAPI.triggerAppStudioWidgetOnPropsChanged(pageRef.id, widgetRoot.id, removeFunctionFromObject(newProps))
        }
    }

    function getWidgetFunctions(ps, widgetPointer) {
        const functionsPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.functions')
        return ps.dal.get(functionsPointer)
    }

    function getWidgetPropertiesSchema(ps, widgetPointer) {
        const propertiesPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.propertiesSchemas')
        return _.map(ps.dal.get(propertiesPointer), prop => _.assign(prop.structure, _.omit(prop, ['structure', 'type'])))
    }

    function getWidgetEvents(ps, widgetPointer) {
        const eventsPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.events')
        return ps.dal.get(eventsPointer)
    }

    function setWidgetApiPart(ps, apiPartName, widgetPointer, newData) {
        if (apiPartName === 'propertiesSchemas') {
            validateSchemasNames(newData)

            const appStudioData = appStudioDataModel.getAppStudioData(ps)
            const customDefinitions = appStudioData.customDefinitions || []
            appStudioSchemaHelpers.validatePropertyDefaultValue(customDefinitions, newData)

            newData = _.map(newData, item => ({structure: item}))
        } else {
            validateNames(newData)
            newData = validateAndFixParams(newData)
        }
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        appStudioDataModel.updateWidgetApi(ps, widgetPointer, widgetData, apiPartName, newData)
    }

    function generateNewWidgetName(ps, prefix) {
        return nameGenerator.generateName(appStudioDataModel.getAllWidgets(ps), prefix)
    }

    function generateNewVariationName(ps, prefix, widgetPointer) {
        const widgetVariations = getWidgetVariations(ps, widgetPointer)
        return nameGenerator.generateName(widgetVariations, prefix)
    }

    function getWidgetVariations(ps, widgetPointer) {
        if (widgetPointer) {
            const widgetData = appStudioDataModel.getData(ps, widgetPointer) || {}
            return _.map(widgetData.variations, variationId => {
                const pointer = ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
                const data = ps.dal.get(pointer)
                return {
                    pointer,
                    name: _.get(data, 'name')
                }
            })
        }
        return []
    }

    function setPageTitle(ps, pageId, pageTitle) {
        const widgetPageData = pageData.getPageData(ps, pageId, true)
        widgetPageData.title = pageTitle
        pageData.setPageData(ps, pageId, widgetPageData, true)
    }

    function syncWidgetNameWithPage(ps, widgetPointer, widgetName) {
        const pageId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
        setPageTitle(ps, pageId, widgetName)
    }

    const getVariationData = (ps, variationDataId) => ps.dal.get(ps.pointers.data.getDataItemFromMaster(dsUtils.stripHashIfExists(variationDataId)))

    function copyVariations(ps, widgetToCopy, newWidgetPointer) {
        _.forEach(widgetToCopy.variations, variationDataId => {
            const variationData = getVariationData(ps, variationDataId)
            const newVariationPointer = appStudioDataModel.getNewDataItemPointer(ps)
            createVariation(ps, newVariationPointer, newWidgetPointer, null, dsUtils.stripHashIfExists(variationData.rootCompId), variationData.name)
        })
    }

    const updateWidgetControllerData = (ps, widgetPointer) => {
        const widget = appStudioDataModel.getData(ps, widgetPointer)

        const widgetPageId = dsUtils.stripHashIfExists(widget.rootCompId)

        _(widget.variations)
            .map(variationDataId => getVariationData(ps, variationDataId))
            .map('rootCompId')
            .concat([widget.rootCompId])
            .map(pageId => ps.pointers.components.getPage(dsUtils.stripHashIfExists(pageId), constants.VIEW_MODES.DESKTOP))
            .forEach(variationPagePointer => {
                const widgetRoot = appStudioDataModel.getRootWidgetByPage(ps, variationPagePointer)
                if (widgetRoot) {
                    appStudioWidgets.setInitialAppWidgetData(ps, widgetRoot, widgetPageId)
                }
            })
    }

    function duplicatePanels(ps, widgetToCopy, newWidgetPointer) {
        _.forEach(widgetToCopy.panels || [], panelId => {
            const panelPointer = ps.pointers.data.getDataItemFromMaster(dsUtils.stripHashIfExists(panelId))
            appStudioPanels.duplicatePanel(ps, appStudioDataModel.getNewDataItemPointer(ps), panelPointer, newWidgetPointer)
        })
    }

    function duplicateWidget(ps, widgetPointer, callback, {widgetName = ''} = {}) {
        const widgetToCopy = appStudioDataModel.getData(ps, widgetPointer)
        if (widgetToCopy) {
            const newWidgetName = widgetName || generateNewWidgetName(ps, widgetToCopy.name)
            const newID = appStudioDataModel.getNewDataId()
            const newPagePointer = page.getPageIdToAdd(ps)
            const newWidgetData = createBlankWidgetData(ps, newID, newWidgetName, widgetToCopy.kind, newPagePointer)
            newWidgetData.widgetApi = _.cloneDeep(widgetToCopy.widgetApi)
            newWidgetData.defaultSize = widgetToCopy.defaultSize
            addWidgetToAppStudio(ps, newWidgetData)

            const newWidgetPointer = ps.pointers.data.getDataItemFromMaster(newWidgetData.id)
            page.duplicate(ps, newPagePointer, dsUtils.stripHashIfExists(widgetToCopy.rootCompId))
            syncWidgetNameWithPage(ps, newWidgetPointer, newWidgetName)

            copyVariations(ps, widgetToCopy, newWidgetPointer)
            duplicatePanels(ps, widgetToCopy, newWidgetPointer)
            updateWidgetControllerData(ps, newWidgetPointer)

            if (_.isFunction(callback)) {
                callback(newWidgetPointer)
            }

            return newWidgetPointer
        }
    }

    function updateNameData(ps, dataPointer, newName) {
        const widgetData = appStudioDataModel.getData(ps, dataPointer)
        widgetData.name = newName
        appStudioDataModel.setWidgetData(ps, dataPointer, widgetData)
    }

    function setWidgetName(ps, widgetPointer, newName) {
        validateWidget(ps, newName)
        updateNameData(ps, widgetPointer, newName)
        syncWidgetNameWithPage(ps, widgetPointer, newName)
        appBuilderPlatformApp.updateApp(ps)
    }

    function setVariationName(ps, variationPointer, widgetPointer, newName) {
        validateVariationName(ps, newName, widgetPointer)
        updateNameData(ps, variationPointer, newName)
        syncWidgetNameWithPage(ps, variationPointer, newName)
    }

    function getWidgetName(ps, widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        return _.get(widgetData, 'name')
    }

    function getVariationName(ps, variationPointer) {
        const variationData = ps.dal.get(variationPointer)
        return _.get(variationData, 'name')
    }

    function displayWidget(ps, widgetPointer, callback, presetPointer) {
        const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)

        if (rootCompId) {
            page.navigateTo(ps, rootCompId, callback)
            if (presetPointer) {
                appStudioPresets.displayPreset(ps, presetPointer, widgetPointer)
            } else {
                displayFirstPreset(ps, widgetPointer)
            }
        }
        appBuilderPlatformApp.updateApp(ps)
    }

    function removeWidget(ps, widgetPointer, callback) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        if (widgetData && widgetData.rootCompId) {
            removeWidgetFromContainingWidgets(ps, widgetPointer)
            const newWidgetsList = appStudioDataModel.getAppStudioData(ps).widgets
            _.pullAt(newWidgetsList, _.findIndex(newWidgetsList, {id: widgetPointer.id}))
            const widgetToRemoveRootId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
            updateWidgetsOnMasterPage(ps, newWidgetsList)

            if (widgetToRemoveRootId === page.homePage.get(ps)) {
                const newWidgetRootHomePage = _.head(newWidgetsList).rootCompId
                page.homePage.set(ps, dsUtils.stripHashIfExists(newWidgetRootHomePage, '#'))
            }

            removeVariationPages(ps, widgetPointer)
            removePanels(ps, widgetPointer)

            if (newWidgetsList.length !== 0) {
                page.remove(ps, widgetData.rootCompId, callback)
                return
            }

            const pagesIds = page.getPageIdList(ps).filter(pageId => pageId !== dsUtils.stripHashIfExists(widgetData.rootCompId))
            if (pagesIds.length !== 1) {
                throw new Error('Invalid count of pages. It should remain only one page if no widgets')
            }

            page.navigateTo(ps, pagesIds[0], () => {
                // the removal callback create an error in console, but it should be resolved with https://jira.wixpress.com/browse/DM-5574
                page.remove(ps, widgetData.rootCompId, callback)
            })
        }
    }

    function removePanels(ps, widgetPointer) {
        const panels = appStudioDataModel.getWidgetPanelsPointers(ps, widgetPointer)

        _.forEach(panels, panel => {
            appStudioPanels.removePanel(ps, panel, widgetPointer)
        })
    }

    function removeVariationPages(ps, widgetPointer) {
        const variations = getWidgetVariations(ps, widgetPointer)
        _.forEach(variations, variation => {
            page.remove(ps, appStudioDataModel.getRootCompIdByPointer(ps, variation.pointer))
        })
    }

    function removeVariation(ps, variationPointer, widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        if (widgetData) {
            const variationsArr = widgetData.variations
            _.remove(variationsArr, item => item === `#${variationPointer.id}`)
            widgetData.variations = variationsArr
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
            const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, variationPointer)

            const firstVariationId = widgetData.variations[0]
            const firstVariationPointer = ps.pointers.data.getDataItemFromMaster(_.replace(firstVariationId, '#', ''))
            updateAllInstancesToPointToVariation(ps, variationPointer, rootCompId, firstVariationPointer)

            page.remove(ps, rootCompId)
        }
    }

    function removeWidgetFromContainingWidgets(ps, widgetToBeRemovePointer) {
        const widgets = appStudioDataModel.getAllWidgets(ps)
        _.forEach(widgets, widget => {
            if (widgetToBeRemovePointer.id === widget.pointer.id) {
                return
            }
            removeWidgetFromWidgetIfExists(ps, widget.pointer, widgetToBeRemovePointer)
        })
    }

    function removeWidgetFromWidgetIfExists(ps, containingWidgetPointer, widgetToBeRemovePointer) {
        const refChildren = appStudioDataModel.getFirstLevelRefChildren(ps, containingWidgetPointer)
        _.forEach(refChildren, refComp => {
            const childWidgetPointer = appStudioDataModel.getWidgetPointerByRefComp(ps, refComp)
            if (_.get(childWidgetPointer, 'id') === widgetToBeRemovePointer.id) {
                component.remove(ps, refComp)
            }
        })
    }

    function getVariationByRootCompId(ps, rootCompId) {
        const {variationId} = appStudioDataModel.findVariationByPageId(ps, rootCompId)

        return variationId && ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
    }

    function setCustomDefinition(ps, definitionPointer, newDefinitionData, callback) {
        const newDefinitionName = appStudioSchemaHelpers.getSchemaStructureKey(newDefinitionData)

        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        const appStudioDataDefinitions = appStudioData.customDefinitions || []

        appStudioSchemaHelpers.validateDefinitionHasProperties(newDefinitionData)

        const currentDefinitionData = definitionPointer && ps.dal.get(definitionPointer)
        if (currentDefinitionData) {
            const definitionIndex = _.findIndex(appStudioDataDefinitions, ['id', definitionPointer.id])
            const currentDefinitionName = appStudioSchemaHelpers.getSchemaStructureKey(appStudioDataDefinitions[definitionIndex].structure)
            if (currentDefinitionName !== newDefinitionName) {
                appStudioSchemaHelpers.validateNewDefinitionName(appStudioDataDefinitions, newDefinitionName)
            }
            appStudioDataDefinitions[definitionIndex] = appStudioSchemaHelpers.mergeExistingDefinitionWithDefinitionData(
                currentDefinitionData,
                newDefinitionData
            )
            appStudioDataDefinitions[definitionIndex].structure = appStudioSchemaHelpers.setPropertiesOrder(appStudioDataDefinitions[definitionIndex].structure)
        } else {
            appStudioSchemaHelpers.validateNewDefinitionName(appStudioDataDefinitions, newDefinitionName)
            newDefinitionData = appStudioSchemaHelpers.setPropertiesOrder(newDefinitionData)
            const newDefinition = appStudioSchemaHelpers.createNewDefinitionWithData(ps, dataModel, newDefinitionData)
            appStudioDataDefinitions.push(newDefinition)
        }

        appStudioSchemaHelpers.validateDefinitionDefaultValue(appStudioDataDefinitions, newDefinitionData)

        appStudioData.customDefinitions = appStudioDataDefinitions
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)

        if (currentDefinitionData) {
            updateDefinitionUsages(ps, definitionPointer, currentDefinitionData.structure, newDefinitionData)
        }

        if (_.isFunction(callback)) {
            callback()
        }
    }

    function removeCustomDefinition(ps, definitionPointer) {
        const definition = ps.dal.get(definitionPointer)
        if (!definition) {
            throw new Error('appStudio.definitions: cannot remove non existing definition')
        }
        validateDefinitionHasNoUsages(ps, definitionPointer)

        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        _.pullAt(appStudioData.customDefinitions, _.findIndex(appStudioData.customDefinitions, {id: definitionPointer.id}))
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function updateDefinitionUsages(ps, definitionPointer, oldDefinitionData, updatedDefinitionData) {
        const difference = appStudioSchemaHelpers.getDefinitionPropertiesDifference(oldDefinitionData, updatedDefinitionData)

        if (!_.isEmpty(difference.addedProperties) || !_.isEmpty(difference.removedProperties) || !_.isEmpty(difference.restrictedProperties)) {
            const usages = getAllDefinitionUsages(ps, definitionPointer)
            _.forEach(usages.definitions, usingDefinition => {
                setCustomDefinition(ps, usingDefinition.definition.pointer, appStudioSchemaHelpers.updateDefinitionDefault(ps, usingDefinition, difference))
            })

            _.forEach(usages.widgets, usingWidget => {
                setWidgetApiPart(
                    ps,
                    'propertiesSchemas',
                    usingWidget.widget.pointer,
                    appStudioSchemaHelpers.updateWidgetPropertiesDefault(ps, usingWidget, difference, getWidgetPropertiesSchema)
                )
            })
        }
    }

    function getAllDefinitionUsages(ps, definitionPointer) {
        const definitionData = ps.dal.get(definitionPointer)
        const definitionName = appStudioSchemaHelpers.getSchemaStructureKey(definitionData.structure)
        const definitionRefId = _.get(definitionData, ['structure', definitionName, '$id'])
        return {
            widgets: appStudioSchemaHelpers.getDefinitionUsagesInWidgets(ps, getWidgetPropertiesSchema, definitionRefId),
            definitions: appStudioSchemaHelpers.getDefinitionUsagesInDefinitions(ps, definitionRefId)
        }
    }

    function validateDefinitionHasNoUsages(ps, definitionPointer) {
        const definitionUsages = getAllDefinitionUsages(ps, definitionPointer)
        if (definitionUsages.widgets.length > 0 || definitionUsages.definitions.length > 0) {
            throw new Error('appStudio.definitions: definition is in use, cannot remove')
        }
    }

    async function getTokenFromServer(ps, appMarketingName) {
        const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
        const res = await fetchJson('https://www.wix.com/_api/cloud-data-packaging/v1/package-data', {
            mode: 'cors',
            method: 'POST',
            credentials: 'include',
            headers: new Headers({Authorization: codeAppInfo.signedInstance}),
            body: JSON.stringify({
                signedInstance: codeAppInfo.signedInstance,
                gridAppId: codeAppInfo.appId,
                appMarketingName,
                skipDataFor: []
            })
        })
        return res && res.packageToken
    }

    function setTokenInMockServer(appDefId, appVersion, token, appMarketingName) {
        fetchJson('https://apps.wix.com/app-studio-server/setToken', {
            mode: 'cors',
            method: 'POST',
            credentials: 'include',
            headers: new Headers({'content-type': 'application/json; charset=utf-8', 'X-XSRF-TOKEN': coreUtilsLib.cookieUtils.getCookie('XSRF-TOKEN')}),
            body: JSON.stringify({
                token,
                appVersion,
                appDefId,
                appMarketingName
            })
        })
    }

    async function setWixDataTokenAsync(ps, appVersion, appDefId, appMarketingName) {
        try {
            const token = await getTokenFromServer(ps, appMarketingName)
            return setTokenInMockServer(appDefId, appVersion, token, appMarketingName)
        } catch (/** @type any */ errRes) {
            const e = await errRes.json()
            if (!_.get(e, 'message', '').contains('No collections to package')) {
                throw e
            }
        }
    }

    async function setWixDataToken(ps, appVersion, appDefId, appMarketingName, onSuccess, onError) {
        setWixDataTokenAsync(ps, appVersion, appDefId, appMarketingName).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
    }

    function buildGhostStructure(ps, pageStructure) {
        const components = {}
        const controllers = {}

        const compsToTransform = [..._.get(pageStructure, ['components'], [])]
        while (compsToTransform.length > 0) {
            let comp = compsToTransform.shift()
            let widgetContext

            const compRef = componentDetectorAPI.getComponentById(ps, comp.id)
            const nickname = appStudioNickname.get(ps, compRef)

            if (refComponentUtils.isInternalRef(ps, compRef)) {
                const pagePointer = page.getPage(ps, comp.data.pageId)
                widgetContext = appStudioDataModel.getRootWidgetByPage(ps, pagePointer)
                const {excludeFromComponents} = comp
                comp = component.serialize(ps, widgetContext)
                comp.excludeFromComponents = excludeFromComponents
            }

            if (nickname) {
                if (!comp.excludeFromComponents) {
                    components[nickname] = comp
                    if (!components[nickname].connections) {
                        components[nickname].connections = {items: appStudioWidgets.getPrimaryConnectionItems(ps, compRef)}
                    }
                    components[nickname].id = _.uniqueId('ghost-')
                }

                //children of inner app should not be included in the components.
                //we keep iterating them so we would get them as controllers
                if (comp.excludeFromComponents || comp.componentType === APP_WIDGET_TYPE) {
                    _.forEach(comp.components, childComp => {
                        childComp.excludeFromComponents = true
                    })
                }
                compsToTransform.push(...(comp.components || []))
                if (comp.components && !comp.excludeFromComponents) {
                    components[nickname].children = _(comp.components)
                        .map(child => componentCode.getNickname(ps, componentDetectorAPI.getComponentById(ps, child)))
                        .compact()
                        .value()
                    delete components[nickname].components
                }

                if (comp.componentType === APP_WIDGET_TYPE) {
                    controllers[nickname] = getGhostControllerData(ps, widgetContext, compRef, nickname)
                }
            }
        }

        return {components, controllers}
    }

    const getDefaultVariationId = (ps, widgetPageId) => {
        const widgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, widgetPageId)
        const [firstVariation] = getWidgetVariations(ps, widgetPointer) || []

        return firstVariation && appStudioDataModel.getRootCompIdByPointer(ps, firstVariation.pointer)
    }

    const getSerializedWidgetStructure = (ps, widgetPageId, options) => {
        const {appDefinitionId, devCenterWidgetId, variationId = getDefaultVariationId(ps, widgetPageId)} = options || {}
        const pageIdToSerialize = variationId || widgetPageId
        const serializedWidgetPage = page.serializePage(ps, pageIdToSerialize, true)

        return appStudioStructureFixer.getFixedAppWidgetStructure(ps, serializedWidgetPage, {
            appDefinitionId,
            devCenterWidgetId,
            variationId
        })
    }

    const getLastBuildId = ps => {
        const pointer = ps.pointers.appBuilder.getLastBuildIdPointer()
        return ps.dal.get(pointer)
    }

    const getAppWidgetRefFromPointer = (ps, widgetPointer) => {
        return appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
    }

    return {
        initialize,
        getNewDataId: appStudioDataModel.getNewDataId,
        updateBlocksPreviewData,
        getNewDataItemPointer: appStudioDataModel.getNewDataItemPointer,
        getAppStudioMetaData: appStudioDataModel.getAppStudioMetaData,
        updateAppStudioMetaData: appStudioDataModel.updateAppStudioMetaData,
        saveAppMetadata: appMetadata.saveMetadata,
        preBuild: blocksLifecycle.preBuild,
        build: blocksLifecycle.build,
        buildWithOptions: blocksLifecycle.buildWithOptions,
        getBuildStatusById: blocksLifecycle.getBuildStatusById,
        getDevSiteAppDefId,
        setWixDataToken,
        appName: appStudioAppName,
        appDescription: appStudioAppDescription,
        isBobApp: appStudioBobApp.isBobApp,
        getUpdatedWidgetPropertiesDefaults: appStudioSchemaHelpers.getUpdatedWidgetPropertiesDefaults,
        fetchAppData: appStudioAppDataUtils.fetchAppData,
        definitions: {
            getAllCustomDefinitions: appStudioDataModel.getAllCustomDefinitions,
            getAllSerializedCustomDefinitions: appStudioDataModel.getAllSerializedCustomDefinitions,
            getSerializedCustomDefinition: appStudioDataModel.getSerializedCustomDefinition,
            setCustomDefinition,
            removeCustomDefinition,
            getAllDefinitionUsages,
            validateDefinitionHasNoUsages,
            validateNewDefinitionName: appStudioSchemaHelpers.validateNewDefinitionName
        },
        panels: {
            createPanel: appStudioPanels.createPanel,
            generateNewPanelName: appStudioPanels.generateNewPanelName,
            displayPanel: appStudioPanels.displayPanel,
            removePanel: appStudioPanels.removePanel,
            duplicatePanel: appStudioPanels.duplicatePanel,
            renamePanel: appStudioPanels.renamePanel,
            getAllPanels: appStudioDataModel.getAllPanels,
            getPanelPointerByRootCompId: appStudioPanels.getPanelPointerByRootCompId,
            getRootCompIdByPanelPointer: appStudioPanels.getRootCompIdByPanelPointer,
            setHelpId: appStudioPanels.setHelpId,
            setTitle: appStudioPanels.setTitle,
            setHeight: appStudioPanels.setHeight,
            validateTitle: appStudioPanels.validateTitle,
            validateHelpId: appStudioPanels.validateHelpId,
            validatePanelName: appStudioPanels.validatePanelName
        },
        dependencies: {
            hasDependency: appStudioDependencies.hasDependency,
            addDependency: appStudioDependencies.addDependency,
            removeDependency: appStudioDependencies.removeDependency,
            setDependencies: appStudioDependencies.setDependencies,
            getAllDependencies: appStudioDependencies.getAllDependencies
        },
        widgets: {
            updateConfig: widgetConfig.update,
            getContainingWidgetsMap: appStudioDataModel.getContainingWidgetsMap,
            getWidgetInstances,
            getFirstLevelRefChildren: appStudioDataModel.getFirstLevelRefChildren,
            createWidget,
            getAllWidgets: appStudioDataModel.getAllWidgets,
            getAllSerializedWidgets: appStudioDataModel.getAllSerializedWidgets,
            getRootWidgetByPage: appStudioDataModel.getRootWidgetByPage,
            getWidgetFunctions,
            getWidgetPropertiesSchema,
            getWidgetEvents,
            getSerializedWidgetStructure,
            setWidgetApiPart,
            duplicateWidget,
            setWidgetName,
            getWidgetName,
            displayWidget,
            removeWidget,
            getWidgetPanels: appStudioDataModel.getWidgetPanelsPointers,
            setDefaultSize: appStudioDataModel.setDefaultSize,
            getDefaultSize: appStudioDataModel.getDefaultSize,
            isWidgetPage: appStudioDataModel.isWidgetPage,
            getWidgetByRootCompId: appStudioDataModel.getWidgetByRootCompId,
            getRootCompIdByPointer: appStudioDataModel.getRootCompIdByPointer,
            getWidgetById: appStudioDataModel.getWidgetPointerByWidgetId,
            notifyPropertiesUpdateToWorker,
            variations: {
                createVariation,
                getVariationName,
                setVariationName,
                removeVariation,
                isVariationPage: appStudioDataModel.isVariationPage,
                getWidgetVariations,
                getVariationByRootCompId
            },
            presets: {
                createPreset: appStudioPresets.createPreset,
                removePreset: appStudioPresets.removePreset,
                getPresetVariantId: appStudioPresets.getPresetVariantId,
                getPresetVariantPointer: appStudioPresets.getPresetVariantPointer,
                displayPreset: appStudioPresets.displayPreset,
                setPresetName: appStudioPresets.setPresetName,
                getPresetName: appStudioPresets.getPresetName,
                duplicatePreset: appStudioPresets.duplicatePreset,
                getWidgetPresets: appStudioPresets.getWidgetPresets,
                setPresetDefaultSize: appStudioPresets.setPresetDefaultSize,
                getPresetDefaultSize: appStudioPresets.getPresetDefaultSize
            }
        },
        codePackages: appStudioCodePackages,
        getLastBuildId,
        getAppType: appType.getAppType,
        getAppWidgetRefFromPointer
    }
})
