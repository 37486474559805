import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import boxProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/boxProxy'

const anchorInPositionAbsoluteWithSiblingsClass = createReactClass({
    displayName: 'AnchorInPositionAbsoluteWithSiblings',
    propTypes: {
        href: PropTypes.string,
        style: PropTypes.object,
        children: PropTypes.node
    },

    onClick(event) {
        event.target = this.refs.a
    },

    render() {
        const props = _.omit(this.props, ['style', 'href'])
        // anchor doesn't get the click so the div catches it and change the target
        // special thanks to Avi Marcus
        props.onClick = this.onClick
        // set the cursor on the div to pointer because the anchor doesn't get it. stupid anchor.
        props.style = _.merge({}, this.props.style, {cursor: 'pointer'})

        return santaComponents.utils.createReactElement(
            'div',
            props,
            santaComponents.utils.createReactElement('a', {
                ref: 'a',
                href: this.props.href,
                'data-page-item-context': props.pathToItems,
                target: props.target,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                }
            }),
            props.children
        )
    }
})
const anchorInPositionAbsoluteWithSiblings = React.createElement.bind(null, anchorInPositionAbsoluteWithSiblingsClass)
anchorInPositionAbsoluteWithSiblings.type = anchorInPositionAbsoluteWithSiblingsClass

/**
 * @class proxies.mixins.zoomProxy
 * @extends proxies.mixins.boxProxy
 * @property {proxy.properties} props
 */
export default {
    mixins: [boxProxy],
    getChildrenOrientation() {
        return this.getCompProp('orientation') || 'vertical'
    },

    getReactClass() {
        return this.getCustomZoomReactClass ? this.getCustomZoomReactClass() : anchorInPositionAbsoluteWithSiblings
    }
}
