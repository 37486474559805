define(['lodash', '@wix/santa-core-utils'], function (_, santaCoreUtils) {
    'use strict'
    class AppMarketUrlBuilder {
        constructor(baseUrl) {
            this.url = santaCoreUtils.urlUtils.parseUrl(baseUrl)
            this.url.query = {}
        }

        addLangParam(lang) {
            if (lang && !_.isEmpty(lang)) {
                this.url.query.lang = lang
            }
            return this
        }

        addOriginParam(origin) {
            if (origin && !_.isEmpty(origin)) {
                this.url.query.eo = window.btoa(origin)
            }
            return this
        }

        addDevAppParam(appId) {
            if (appId && !_.isEmpty(appId)) {
                this.url.query.appDefinitionId = appId
            }
            return this
        }

        addCompIdParam() {
            this.url.query.compId = 'MarketPanel'
            return this
        }

        addAppMarketTests(tests) {
            if (tests && !_.isEmpty(tests)) {
                this.url.query.experiment = tests
            }
            return this
        }

        addMetaSiteIdParam(metaSiteId) {
            if (metaSiteId && !_.isEmpty(metaSiteId)) {
                this.url.query.metaSiteId = metaSiteId
            }
            return this
        }

        addModalParams(modalName, appName, tab) {
            if (modalName) {
                this.url.path += `/${modalName}/${appName}/`
                if (tab) {
                    this.url.path += `${tab}/`
                }
            }
            return this
        }

        addSiteIdParam(siteId) {
            if (siteId && !_.isEmpty(siteId)) {
                this.url.query.siteId = siteId
            }
            return this
        }

        addTagsParam(tag) {
            if (tag && !_.isEmpty(tag)) {
                this.url.query.query = tag
            }
            return this
        }

        addOpenAppParam(appDefIdTag) {
            if (appDefIdTag && !_.isEmpty(appDefIdTag)) {
                this.url.query.openAppDefId = appDefIdTag
            }
            return this
        }

        addAppMarketParams(appMarketParams) {
            if (appMarketParams) {
                this.url.query.appMarketParams = appMarketParams
            }
            return this
        }

        addNewWixStores(isTrue) {
            if (!_.isUndefined(isTrue)) {
                this.url.query.newWixStores = `${isTrue}`
            }
            return this
        }

        addCategoryParam(category) {
            if (category && !_.isEmpty(category)) {
                this.url.query.categorySlug = category
            }
            return this
        }

        addBiReferralInfoParam(referralInfo) {
            if (referralInfo && !_.isEmpty(referralInfo)) {
                this.url.query.referralInfo = referralInfo
            }
            return this
        }

        addBiReferralInfoCategoryParam(referralInfoCategory) {
            if (referralInfoCategory && !_.isEmpty(referralInfoCategory)) {
                this.url.query.referralInfoCategory = referralInfoCategory
            }
            return this
        }

        addBiSectionParam(section) {
            if (section && !_.isEmpty(section)) {
                this.url.query.section = section
            }
            return this
        }

        addAddingMethodParam(addingMethod) {
            if (addingMethod && !_.isEmpty(addingMethod)) {
                this.url.query.addingMethod = addingMethod
            }
            return this
        }

        addStateParam(state) {
            if (state && !_.isEmpty(state)) {
                this.url.query.state = state
            }
            return this
        }

        addCommonConfigParam(ps) {
            const commonConfig = ps.dal.get(ps.pointers.general.getCommonConfig())
            this.url.query.commonConfig = JSON.stringify(commonConfig)
            return this
        }

        build() {
            return santaCoreUtils.urlUtils.buildFullUrl(this.url)
        }
    }

    return AppMarketUrlBuilder
})
