import tpaWidget from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaWidget'
import tpaWidgetNative from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaWidgetNative/tpaWidgetNative'
import tpaWidgetNativeDeadComp from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaWidgetNative/tpaWidgetNativeDeadcomp'
import tpaSection from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaSection'
import tpaMultiSection from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaMultiSection'
import tpaWorker from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaWorker'
import tpaGluedWidget from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaGluedWidget'
import tpaUrlBuilder from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaUrlBuilderMixin'
import tpaCompApi from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaCompApiMixin'
import '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaPreloaderOverlay'
import '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaUnavailableMessageOverlay'
import '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaModal'
import '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaPopup'
import tpaCompBase from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaCompBaseMixin'
import TPAUrlBuilder from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/common/TPAUrlBuilder'
import TPABaseUrlBuilder from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/common/TPABaseUrlBuilder'
import tpaUtils from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/utils/tpaUtils'
import tpaPreviewEditorCommunicationService from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/services/tpaPreviewEditorCommunicationService'
import sitePages from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/utils/sitePages'
import tpaMetaData from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/common/tpaMetaData'
import * as skinsPackage from '@wix/santa-ds-libs/src/_internal/skins'
// @ts-ignore
import skinsJson from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/skins/skins.json'
import nativeCompRequireService from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/components/tpaWidgetNative/nativeCompRequireService'
import pageService from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/services/pageService'
import clientSpecMapService from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/services/clientSpecMapService'
import biErrors from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/bi/errors'
import events from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/bi/events'
import supportedSiteEvents from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/common/supportedSiteEvents'
import '@wix/santa-ds-libs/src/_internal/tpaComponents/src/layout/tpaLayout'
import gluedWidgetPatcher from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/layout/gluedWidgetPatcher'
import tpaSectionPatcher from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/layout/tpaSectionPatcher'
import gluedWidgetMeasuringUtils from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/utils/gluedWidgetMeasuringUtils'

skinsPackage.skinsMap.addBatch(skinsJson)

export default {
    tpaWidgetNative,
    tpaWidgetNativeDeadComp,
    nativeCompRequireService,
    widget: tpaWidget,
    section: tpaSection,
    multiSection: tpaMultiSection,
    worker: tpaWorker,
    gluedWidget: tpaGluedWidget,
    tpaMixins: {
        tpaUrlBuilder,
        tpaCompApi,
        tpaCompBase
    },
    gluedWidgetMeasuringUtils,
    GluedWidgetPatcher: gluedWidgetPatcher,
    tpaSectionPatcher,
    common: {
        metaData: tpaMetaData,
        TPAUrlBuilder,
        TPABaseUrlBuilder,
        utils: tpaUtils,
        supportedSiteEvents
    },
    services: {
        tpaPreviewEditorCommunicationService,
        pageService,
        clientSpecMapService
    },
    sitePages,
    bi: {
        errors: biErrors,
        events
    }
}
