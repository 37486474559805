/**
 * Created by alexandergonchar on 10/27/14.
 */
define([
    'lodash',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/page/pageData',
    'documentServices/page/popupUtils',
    'documentServices/structure/structure',
    'documentServices/component/componentStructureInfo',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/componentDetectorAPI/resolveAdditionalComponentsRegistrar',
    'documentServices/page/pageUtils'
], function (_, documentModeInfo, pageData, popupUtils, structure, componentStructureInfo, mobileUtil, resolveAdditionalComponentsRegistrar, pageUtils) {
    'use strict'

    function isClickOnHorizontalComponent(pos, rect) {
        return pos.x > rect.left && pos.x < rect.right && pos.y > rect.top && pos.y < rect.bottom
    }

    function degreesToRadians(angleInDegrees) {
        return (angleInDegrees * Math.PI) / 180
    }

    // TODO: refactor this (copied from html-client)
    function isClickOnRotatedComponent(pos, rect, rotationInDegrees) {
        const compWidth = rect.right - rect.left + 1,
            compHeight = rect.bottom - rect.top + 1,
            compCenter = {
                x: rect.left + compWidth / 2, // eslint-disable-line no-mixed-operators
                y: rect.top + compHeight / 2 // eslint-disable-line no-mixed-operators
            }

        const clickCalculationTriangle = {
            A: pos,
            B: compCenter
        }

        const dy = pos.y - compCenter.y
        const dx = pos.x - compCenter.x
        clickCalculationTriangle.hypotenuse = Math.pow(Math.pow(dx, 2) + Math.pow(dy, 2), 0.5)
        if (dx === 0) {
            clickCalculationTriangle.ABHorizontalXangle = dy > 0 ? Math.PI / 2 : -Math.PI / 2
        } else {
            clickCalculationTriangle.ABHorizontalXangle = Math.atan(dy / dx)
        }
        const compRotationAngel = degreesToRadians(rotationInDegrees)
        clickCalculationTriangle.ABComponentXangle = clickCalculationTriangle.ABHorizontalXangle - compRotationAngel
        const clickComponentDx = Math.abs(clickCalculationTriangle.hypotenuse * Math.cos(clickCalculationTriangle.ABComponentXangle))
        const clickComponentDy = Math.abs(clickCalculationTriangle.hypotenuse * Math.sin(clickCalculationTriangle.ABComponentXangle))
        return clickComponentDx < compWidth / 2 && clickComponentDy < compHeight / 2
    }

    function getComponentLayout(ps, compPointer, layoutGetter) {
        return layoutGetter(ps, compPointer)
    }

    function compAtPosition(ps, x, y, margin, scrollTop, layoutGetter, compPointer) {
        const cursorPosition = {
            x,
            y: structure.isShowOnFixedPosition(ps, compPointer) ? y - scrollTop : y,
            margin
        }

        let layout = getComponentLayout(ps, compPointer, layoutGetter) // structure.getCompLayoutRelativeToScreen(ps, compPointer);
        const {rotationInDegrees} = layout
        const isRotated = rotationInDegrees > 0

        if (!isRotated) {
            layout = layout.bounding
        }

        const extendBy = isNaN(margin) ? 0 : margin
        const boundingRect = composeBoundingRectObj(layout.width, layout.height, layout.x, layout.y, extendBy)

        return isRotated
            ? isClickOnRotatedComponent(cursorPosition, boundingRect, rotationInDegrees)
            : isClickOnHorizontalComponent(cursorPosition, boundingRect)
    }

    function composeBoundingRectObj(width, height, left, top, extendBy) {
        return {
            left: left - extendBy,
            top: top - extendBy,
            right: left + width + extendBy,
            bottom: top + height + extendBy
        }
    }

    function getSiteScroll(ps) {
        return structure.getScroll(ps)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer[]} rootComps in the correct dom order (top to bottom)
     * @param {Predicate|undefined} predicate
     * @param {boolean} [isFull]
     * @param [mappingMethod]
     * @returns {Pointer[]}
     */
    function getComponentsByZOrder(ps, rootComps, predicate, isFull, mappingMethod = flatRecursive) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const pointers = isFull ? ps.pointers.full : ps.pointers
        const orderedRootComps = rootComps.reverse()
        return _(orderedRootComps)
            .flatMapDeep(rootComp => mappingMethod(ps, pointers, rootComp))
            .filter(predicate)
            .sortBy(function (compPointer) {
                const zIndex = _.get(measureMap, ['zIndex', compPointer.id], 0)
                const isFixed = structure.isFixedPosition(ps, compPointer) || structure.isAncestorFixedPosition(ps, compPointer)
                return isFixed ? -1 : 1000 - zIndex
            })
            .value()
    }

    function flatRecursive(ps, pointers, rootComp) {
        return pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(rootComp)
    }

    function flatRecursiveWithResolvers(ps, pointers, rootComp) {
        const allCompsPointers = pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(rootComp)
        return _.flatMapDeep(allCompsPointers, compPointer =>
            _.concat(resolveAdditionalComponentsRegistrar.resolveAdditionalComponents(ps, compPointer), compPointer)
        )
    }

    /**
     * @param {ps} ps
     * @param {Pointer[]} rootComps
     * @returns {Pointer[]} compPointers
     */
    function getComponentsRecursive(ps, rootComps) {
        return _(rootComps)
            .map(rootComp => ps.pointers.full.components.getChildrenRecursively(rootComp))
            .flattenDeep()
            .value()
    }

    function getAllControllerComponents(ps, rootComponents, isFull) {
        const dal = isFull ? ps.dal.full : ps.dal

        return _.filter(rootComponents, function (compPointer) {
            const compType = dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            return compType === 'platform.components.AppController'
        })
    }

    function getRootComponents(ps, pageId, viewMode, isFull) {
        viewMode = mobileUtil.getViewMode(ps, viewMode, documentModeInfo)

        const pointers = isFull ? ps.pointers.full : ps.pointers
        const masterPage = pointers.components.getMasterPage(viewMode)
        const rootComponents = pointers.components.getChildren(masterPage)

        if (pageId === masterPage.id) {
            return rootComponents
        }

        const pageIds = pageId ? [pageId] : pageData.getPagesList(ps, false, true)

        const pages = _.map(pageIds, id => pointers.components.getPage(id, viewMode))

        let allControllersInMasterPage
        if (pageId && pageUtils.isLandingPage(ps, pageId)) {
            const landingPageComponents = pointers.components.getLandingPageComponents(viewMode)
            allControllersInMasterPage = getAllControllerComponents(ps, rootComponents, isFull)
            return landingPageComponents.concat(pages, allControllersInMasterPage)
        }

        if (pageId && popupUtils.isPopup(ps, pageId)) {
            allControllersInMasterPage = getAllControllerComponents(ps, rootComponents, isFull)
            return pages.concat(allControllersInMasterPage)
        }

        const indexToInsertPage = _.findIndex(rootComponents, {id: 'PAGES_CONTAINER'}) + 1
        rootComponents.splice(indexToInsertPage, 0, ...pages)

        return rootComponents
    }

    /**
     * name Predicate
     * function
     * param {AbstractComponent} compRef the reference to a component upon which the predicate will be tested
     */

    /**
     * @function
     * @param {ps} ps
     * @param {string} pageId - the pageId of the page for which to get all the components
     * @param {Predicate} [predicate] a predicate function used to filter components
     * @param {string} [viewMode]
     * @returns {Array.<AbstractComponent>} all the components in site that fit the predicate, ordered by the dom - auto - z index, in other words, if you were to click, then comps[0] is the first candidate to have been clicked
     */
    function getAllComponents(ps, pageId, predicate, viewMode) {
        const rootComponents = getRootComponents(ps, pageId, viewMode)
        return getComponentsByZOrder(ps, rootComponents, predicate)
    }

    function getAllComponentsFromFull(ps, pageId, predicate, viewMode) {
        const rootComponents = getRootComponents(ps, pageId, viewMode, true)
        return getComponentsByZOrder(ps, rootComponents, predicate, true)
    }

    function getAllComponentsFromFullWithResolvers(ps, pageId, predicate, viewMode) {
        const rootComponents = getRootComponents(ps, pageId, viewMode, true)
        return getComponentsByZOrder(ps, rootComponents, predicate, true, flatRecursiveWithResolvers)
    }

    function getAllComponentsFromFullWithResolversByAncestor(ps, pageId, predicate, viewMode) {
        const components = getRootComponents(ps, pageId, viewMode, true)
        let compChild
        let nextLevel
        const results = []
        while (components.length) {
            compChild = components.shift()
            if (predicate(compChild)) {
                results.push(compChild)
            } else {
                nextLevel = ps.pointers.full.components.getChildren(compChild)
                nextLevel.forEach(child => components.push(child))
            }
        }
        return results
    }

    /**
     * Retrieve all components under some ancestor
     * @param {ps} ps
     * @param {AbstractComponent} ancestor - comp pointer to ancestor in which to search
     * @returns {Array.<AbstractComponent>} pointers to components under ancestor or an empty array
     */
    function getComponentsUnderAncestor(ps, ancestor) {
        return ps.pointers.components.getChildrenRecursively(ancestor)
    }

    /**
     * Finds all components in a specific point
     * @param {ps} ps
     * @param {number} x is mouse x coord relative to a site structure
     * @param {number} y is mouse y coord relative to a site structure
     * @param {number} margin - components will be extended in size by this value
     * @param {String} [pageId] - the page to search the components in. The default value will be the current page.
     * @returns {Pointer[]} component paths from top to bottom (i.e. the top most component will be in index 0)
     */
    function getComponentsAtXYRelativeToStructure(ps, x, y, margin, pageId) {
        return getComponentsAtXY(ps, x, y, margin, pageId, structure.getCompLayoutRelativeToStructure, false)
    }

    /**
     * Finds all components in a specific point
     * @param {ps} ps
     * @param {number} x is mouse x coord relative to a screen
     * @param {number} y is mouse y coord relative to a screen
     * @param {number} margin - components will be extended in size by this value
     * @param {String} [pageId] - the page to search the components in. The default value will be the current page.
     * @returns {Pointer[]} component paths from top to bottom (i.e. the top most component will be in index 0)
     */
    function getComponentsAtXYRelativeToScreen(ps, x, y, margin, pageId) {
        return getComponentsAtXY(ps, x, y, margin, pageId, structure.getCompLayoutRelativeToScreen, true)
    }

    /**
     * @param {ps} ps
     * @param x
     * @param y
     * @param margin
     * @param pageId
     * @param layoutGetter
     * @param shouldAddSiteScroll
     * @returns {Pointer[]}
     */
    function getComponentsAtXY(ps, x, y, margin, pageId, layoutGetter, shouldAddSiteScroll) {
        let xCoord = x
        let yCoord = y
        const siteScroll = getSiteScroll(ps)
        if (shouldAddSiteScroll) {
            const siteScaleFlagPointer = ps.pointers.general.getRenderFlag('siteScale')
            const siteScale = ps.dal.get(siteScaleFlagPointer)

            xCoord += siteScroll.x
            yCoord += siteScroll.y / siteScale
        }

        const doesCompMatchPosition = _.partial(compAtPosition, ps, xCoord, yCoord, margin, siteScroll.y, layoutGetter)

        pageId = pageId || ps.siteAPI.getFocusedRootId()

        const rootComponents = getRootComponents(ps, pageId)

        let comps = getComponentsByZOrder(ps, rootComponents, doesCompMatchPosition)
        if (pageId === ps.siteAPI.getFocusedRootId()) {
            comps = _.reject(comps, comp => !componentStructureInfo.isRenderedOnSite(ps, comp))
        }
        return comps
    }

    /**
     * @param {ps} ps
     * @param pageRef
     * @returns {*}
     */
    function getAvailableComponentUnderMouse(ps, pageRef) {
        const viewMode = mobileUtil.getViewMode(ps, null, documentModeInfo)
        const masterPageRef = ps.pointers.components.getMasterPage(viewMode)
        return _.keyBy([...getComponentsUnderAncestor(ps, pageRef), ...getComponentsUnderAncestor(ps, masterPageRef), pageRef], 'id')
    }

    /**
     * Finds all components in a specific point
     * @param {ps} ps
     * @param {number} x is mouse x coord
     * @param {number} y is mouse y coord
     * @returns {Pointer[]} component refs ordered by z-index.
     */
    function getComponentsAtXYConsideringFrame(ps, x, y) {
        if (!ps.siteAPI.getElementsUnderXY) {
            return getComponentsAtXYRelativeToScreen(ps, x, y, 0)
        }

        const components = []
        const domElementNodes = ps.siteAPI.getElementsUnderXY(x, y)
        const currentPage = ps.siteAPI.getFocusedRootId()

        const focusedPageRef = getComponentById(ps, currentPage)
        const availableComponentUnderMouse = getAvailableComponentUnderMouse(ps, focusedPageRef)
        domElementNodes.forEach(({id}) => {
            if (availableComponentUnderMouse[id]) {
                components.push(availableComponentUnderMouse[id])
            }
        })

        return components
    }

    /**
     * Finds all components in a specific point
     * @param {ps} ps
     * @param {number} x is mouse x coord
     * @param {number} y is mouse y coord
     * @returns {Pointer[]} component refs ordered by z-index.
     */
    const getComponentsAtXYFromDom = (ps, x, y) => {
        const idsUnderXY = ps.siteAPI.getComponentsUnderXY(x, y)
        const viewMode = mobileUtil.getViewMode(ps, null, documentModeInfo)

        return idsUnderXY.map(id => ps.pointers.getPointer(id, viewMode))
    }

    /**
     * Gets all the components of the given type/s in the entire site or descendants of the rootComp.
     * @param {ps} ps
     * @param {String|string[]} compType a single component type or an array of multiple component types
     * @param {AbstractComponent} [rootComp] rootComp The root parent component ref that will be used for the search
     * @returns {AbstractComponent[]} an array of component refs of the passed component type/s
     */
    function getComponentByType(ps, compType, rootComp) {
        const compTypes = _.isArray(compType) ? compType : [compType]
        const compTypesMap = _.mapKeys(compTypes)
        const rootComponents = rootComp ? [rootComp] : getRootComponents(ps, undefined, undefined, true)
        let comps = getComponentsRecursive(ps, rootComponents)
        comps = comps.concat(rootComponents)
        return _.filter(comps, function (compPointer) {
            const compTypeToTest = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            return compTypesMap[compTypeToTest]
        })
    }

    /**
     * Gets component by given id ,pageId (if pageId isn't passed takes the current pageId) and view mode (if view mode not passed takes the current view mode).
     * @param {ps} ps
     * @param {String} id component full id
     * @param {String} [pageId] id of the page that the component is in
     * @param {String} [viewMode] mobile/desktop
     * @returns {AbstractComponent|null} a ref to the component with the passed id
     */
    function getComponentById(ps, id, pageId, viewMode) {
        if (!id) {
            return null
        }
        pageId = pageId || ps.siteAPI.getCurrentUrlPageId()
        viewMode = mobileUtil.getViewMode(ps, viewMode, documentModeInfo)
        // @ts-ignore
        let page = ps.pointers.components.getPage(pageId, viewMode)
        let compRef
        if (page) {
            compRef = page ? ps.pointers.components.getComponent(id, page) : null
            const comp = compRef ? ps.dal.get(compRef) : null
            if (comp) {
                return compRef
            }
        }
        // @ts-ignore
        page = ps.pointers.full.components.getPage(pageId, viewMode)
        compRef = page ? ps.pointers.full.components.getComponent(id, page) : null
        // @ts-ignore
        return ps.dal.full.isExist(compRef) ? compRef : null
    }

    /**
     * Gets site header if view mode not passed takes the current view mode.
     * @param {ps} ps
     * @param {String} [viewMode] mobile/desktop
     * @returns {?AbstractComponent} a ref to the component with the passed id
     */
    function getSiteHeader(ps, viewMode) {
        return getComponentById(ps, 'SITE_HEADER', 'masterPage', viewMode)
    }

    /**
     * Gets site header if view mode not passed takes the current view mode.
     * @param {ps} ps
     * @param {String} [viewMode] mobile/desktop
     * @returns {?AbstractComponent} a ref to the component with the passed id
     */
    function getSiteFooter(ps, viewMode) {
        return getComponentById(ps, 'SITE_FOOTER', 'masterPage', viewMode)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} comp
     * @param {Pointer} possibleAncestor
     * @returns {*}
     */
    function isDescendantOfComp(ps, comp, possibleAncestor) {
        return possibleAncestor && ps.pointers.full.components.isDescendant(comp, possibleAncestor)
    }

    return {
        /**
         * @function
         * @memberof documentServices.componentDetectorAPI
         * @param {ps} privateServices
         * @param {x} x coordinate
         * @param {y} y coordinate
         * @returns {Array<CompRef>} array of components under (x, y) coordinate
         */
        getComponentsAtXYConsideringFrame,
        /**
         * @function
         * @memberof documentServices.componentDetectorAPI
         * @param {ps} privateServices
         * @param {x} x coordinate
         * @param {y} y coordinate
         * @returns {Array<CompRef>} array of components under (x, y) coordinate
         */
        getComponentsAtXYFromDom,
        /**
         * @function
         * @memberof documentServices.componentDetectorAPI
         *
         * TODO 12/31/14 3:59 PM - JSDocs not yet implemented
         */
        getComponentsAtXYRelativeToStructure,
        /**
         * @function
         * @memberof documentServices.componentDetectorAPI
         *
         * TODO 12/31/14 3:59 PM - JSDocs not yet implemented
         */
        getComponentsAtXYRelativeToScreen,
        /**
         * @function
         * @memberof documentServices.componentDetectorAPI
         *
         * TODO 12/31/14 3:59 PM - JSDocs not yet implemented
         */
        getComponentByType,
        /**
         * @function
         * @param {ps} privateServices
         * @param {string} pageId - the pageId of the page for which to get all the components
         * @returns {Array.<AbstractComponent>} all the components in the masterPage and the given pageId, ordered by the dom - auto - z index, in other words, if you were to click, then comps[0] is the first candidate to have been clicked
         */
        getAllComponents,
        getAllComponentsFromFull,
        getAllComponentsFromFullWithResolvers,
        getAllComponentsFromFullWithResolversByAncestor,

        getComponentsUnderAncestor,

        getComponentById,

        getSiteHeader,
        getSiteFooter,

        isDescendantOfComp
    }
})
