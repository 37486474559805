define(['@wix/document-manager-utils'], function ({ReportableError}) {
    function buildAbortedError() {
        const error = new ReportableError({
            message: 'Request aborted by browser',
            errorType: 'RequestAbortError'
        })
        error.name = 'RequestAbortError'
        return error
    }

    function buildServerError(status, responseText) {
        return new ReportableError({
            message: `Server Error\nStatus: ${status}\nResponse Text: ${responseText}`,
            errorType: 'BuildServerError'
        })
    }

    return {
        buildAbortedError,
        buildServerError
    }
})
