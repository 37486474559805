import _ from 'lodash'
import type {DalValue, DmApis} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {WixCodeExtensionAPI} from '../wixCode'
import type {ViewerManagerDAL} from '@wix/viewer-manager-adapter'
import {deepClone} from '@wix/wix-immutable-proxy'

const rendererModelItemsWrappedAsPages = new Set(['passwordProtectedPages', 'pageToHashedPassword'])
export const viewerTransformSet = (dsApis: DmApis, viewerManagerDal: ViewerManagerDAL, pointer: Pointer, value: DalValue): DalValue => {
    const {extensionAPI, pointers: documentManagerPointers, dal: documentManagerDal} = dsApis

    if (pointer.type === 'rendererModel' && rendererModelItemsWrappedAsPages.has(pointer.id)) {
        viewerManagerDal.set(pointer, value.pages)
        return
    }

    if ((pointer.type === 'wixCode' && pointer.id === 'ISOLATED_GRID_APP') || (pointer.type === 'documentServicesModel' && pointer.id === 'openWixCodeAppId')) {
        const wixCodeModelPtr = documentManagerPointers.wixCode.getWixCodeModel()
        const wixCodeModel = deepClone(documentManagerDal.get(wixCodeModelPtr))
        viewerTransformSet(dsApis, viewerManagerDal, wixCodeModelPtr, wixCodeModel)
        return
    }

    if (pointer.type === 'rendererModel' && pointer.id === 'wixCodeModel') {
        const {wixCode} = extensionAPI as WixCodeExtensionAPI
        const wixCodeModel = deepClone(value)
        if (wixCode) {
            _.setWith(wixCodeModel, ['appData', 'codeAppId'], wixCode.getEditedGridAppId(), Object)
        }
        viewerManagerDal.set(pointer, wixCodeModel)
        return
    }

    viewerManagerDal.set(pointer, value)
}
