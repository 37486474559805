import type {ViewerAPI, ResponsiveViewerAPI} from '@wix/viewer-manager-interface'
import type {Pointer} from '@wix/document-manager-core'
import type {BIEvt, BIParams, Callback} from '@wix/document-services-types'
import _ from 'lodash'
import type {ViewerSiteAPI} from './types'
import {getViewMode} from './structureUtils'

export interface ExtendedViewerAPI {
    getViewMode(): string
    switchModes(pointer: Pointer, modeIdToDeactivate: string, modeIdToActivate: string): void
    activateMode(pointer: Pointer, modeId: string): void
    deactivateMode(pointer: Pointer, modeId: string): void
    registerNavigationComplete(callback: Callback): void
    registerNavigationError(id: string, callback: Callback): void
    setLockedCompsForEnforceAnchors(comps: {[compId: string]: boolean}): void
    setFullLayoutNeeded(b: boolean): void
    forceGenerateAnchors(): void
    setIsGeneratingAnchors(isGenerating: boolean): void
    setIsEnforcingAnchors(isEnforcing: boolean): void
    createChildrenAnchors(parentStructure: any, parentPageId: string, op: {forceMobileStructure: boolean}): void
    unregisterNavigationError(id: string): void
    setPremiumNavigationHandler(callback: Callback): void
    // it can be undefined for classic
    responsive?: ResponsiveViewerAPI
}

// maybe it is better to have them as map in future
// this will make possible for us to provide injected config and include not only scopes
// but validate structure. Here for now we are using lodash ability to pick by path
const REQUIRED_SCOPES = [
    'navigation',
    'query',
    'actionsAndBehaviors',
    'updateStatus',
    'component',
    'componentDetector',
    'layout',
    'fonts',
    'livePreview',
    'media',
    'members',
    'pages',
    'platform',
    'animation',
    'scroll',
    'selectiveDownload',
    'stylable',
    'tpa',
    'viewMode',
    'legacy',
    'runtime',
    'runtimeLayout',
    'variants',
    'miniSites',
    'modes.getAllActiveModeIds',
    'modes.updateActiveSOSPModes',
    'modes.switchModes',
    'modes.activateMode',
    'modes.deactivateMode',
    'modes.resetAllActiveModes',
    'byRef.getStructure',
    'rendererModel.getClientSpecMap',
    'diagnostics'
]

const getMockedLegacyImplementations = () => ({
    resetRuntimeData: _.noop,
    createPageAnchors: _.noop,
    createDisplayedNode: _.noop,
    getPageMargins: () => ({bottom: 0, top: 0})
})

const getExtendedViewerAPI = (viewer: ViewerAPI): ExtendedViewerAPI => {
    const extApi = {
        responsive: viewer.responsive,
        getViewMode: (): string => getViewMode(viewer),
        switchModes: (compPointer: Pointer, modeIdToDeactivate: string, modeIdToActivate: string): void => {
            viewer.modes.switchModes(compPointer.id, modeIdToDeactivate, modeIdToActivate)
        },
        activateMode: (compPointer: Pointer, modeId: string): void => {
            viewer.modes.activateMode(compPointer.id, modeId)
        },
        deactivateMode: (compPointer: Pointer, modeId: string): void => {
            viewer.modes.deactivateMode(compPointer.id, modeId)
        },
        registerNavigationComplete: (callback: Callback): void => {
            viewer.navigation.waitForNavigation().then(() => callback())
        },
        registerNavigationError: (id: string, callback: Callback): void => {
            viewer.navigation.waitForNavigation().catch(() => callback())
        },
        setLockedCompsForEnforceAnchors: _.noop,
        setFullLayoutNeeded: _.noop,
        forceGenerateAnchors: _.noop,
        setIsGeneratingAnchors: _.noop,
        setIsEnforcingAnchors: _.noop,
        createChildrenAnchors: _.noop,
        unregisterNavigationError: _.noop,
        setPremiumNavigationHandler: _.noop,
        reportBI: (reportDef: BIEvt, params: BIParams = {}): void => {
            params.viewMode = extApi.getViewMode()
            window.biLogger?.log(reportDef, params)
        }
    }
    return extApi
}

export const buildViewerSiteAPI = (viewer: ViewerAPI): ViewerSiteAPI => {
    const value = _(viewer).pick(REQUIRED_SCOPES).values().value()
    const extendedViewerAPI = getExtendedViewerAPI(viewer)

    return _.merge({}, getMockedLegacyImplementations(), ...value, extendedViewerAPI) as ViewerSiteAPI
}
