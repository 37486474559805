define(['@wix/santa-core-utils'], function (coreUtilsLib) {
    'use strict'

    const buildAppsServiceBaseUrl = ps => {
        const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
        const editorServerRootPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, 'editorServerRoot')
        const editorServerRoot = ps.dal.get(editorServerRootPointer)

        return `${coreUtilsLib.urlUtils.baseUrl(editorServerRoot)}/_api/app-service/v1/`
    }

    const fetchAppData = (ps, appDefinitionId) =>
        coreUtilsLib.requestsUtil.fetchJson(`${buildAppsServiceBaseUrl(ps)}apps/${appDefinitionId}`, {credentials: 'include'})

    const postComponentData = (ps, appDefinitionId, componentData) =>
        coreUtilsLib.requestsUtil.fetchJson(`${buildAppsServiceBaseUrl(ps)}apps/${appDefinitionId}/components`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(componentData)
        })

    return {
        fetchAppData,
        postComponentData
    }
})
