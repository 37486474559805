import _ from 'lodash'
import {DAL, DmApis, Extension, Pointer, pointerUtils, ValidateValue, ExtensionAPI, CreateExtArgs, CreateExtensionArgument} from '@wix/document-manager-core'
import type {RelationshipsAPI} from './relationships'
import {DATA_TYPES, VIEW_MODES} from '../constants/constants'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'

const {getPointer, getOverrideComponentPointer} = pointerUtils
const {DESKTOP, MOBILE} = VIEW_MODES
const NO_MATCH: string[] = []

interface SlotsExtensionAPI extends ExtensionAPI {
    slots: {
        getPluginParent(childPointer: Pointer): Pointer | null
    }
}

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createFilters = () => ({
        getSlotsFilter: (namespace: string, value: any): string[] => {
            if (namespace !== DATA_TYPES.slots || !value) {
                return NO_MATCH
            }
            if (value.slots) {
                return _.values(value.slots)
            }
            return NO_MATCH
        }
    })

    const createExtensionAPI = ({dal}: CreateExtArgs): SlotsExtensionAPI => ({
        slots: {
            getPluginParent: (compPointer: Pointer): Pointer | null => {
                if (!compPointer || !compPointer.id) {
                    // we know that pointers always have ids, but public api consumers can pass us arrays sometimes
                    return null
                }

                const slotsDataItems = dal.queryKeys('slots', dal.queryFilterGetters.getSlotsFilter(compPointer.id))
                if (slotsDataItems.length === 0) {
                    return null
                }

                const slotDataPointer = slotsDataItems[0]
                if (!displayedOnlyStructureUtil.isReferredId(slotDataPointer)) {
                    return null
                }

                return getOverrideComponentPointer(slotDataPointer, compPointer.type, 'slotsQuery')
            }
        }
    })

    const getReferringSlot = (dal: DAL, refCompChildId: string) => {
        return dal.getIndexPointers(dal.queryFilterGetters.getSlotsFilter(refCompChildId), DATA_TYPES.slots)
    }

    const belongsToOverride = (dal: DAL, refCompChildId: string, refHostId: string): boolean => {
        const slotsOverrideId = getReferringSlot(dal, refCompChildId)
        if (slotsOverrideId.length === 1) {
            return displayedOnlyStructureUtil.getRootRefHostCompId(slotsOverrideId[0].id) === refHostId
        }
        return false
    }

    const createValidator = (apis: DmApis): Record<string, ValidateValue> => {
        const {dal, extensionAPI} = apis

        const getSlotOwners = (childComp: Pointer): Pointer[] => {
            const {relationships} = extensionAPI as RelationshipsAPI
            if (displayedOnlyStructureUtil.isRefPointer(childComp)) {
                const refComponentId = displayedOnlyStructureUtil.getRootRefHostCompId(childComp.id)

                return _.filter([getPointer(refComponentId, DESKTOP), getPointer(refComponentId, MOBILE)], refComp => {
                    const plugin = getPointer(childComp.id, refComp.type)
                    return dal.has(refComp) && dal.has(plugin)
                })
            }

            return relationships.getOwningReferencesToPointer(childComp)
        }

        return {
            invalidRefComponentChildren: (pointer, value) => {
                if (!experimentInstance.isOpen('dm_runSlotsValidator')) {
                    return
                }

                const {type: dalValueType} = value ?? {}

                if (dalValueType === 'RefComponent' && value.components?.length) {
                    const refComponentChildren = value.components.filter((compId: string) => !compId.startsWith('dead-'))
                    const invalidRefCompChildren = _.reject(refComponentChildren, child => belongsToOverride(dal, child, value.id))
                    if (!_.isEmpty(invalidRefCompChildren)) {
                        return [
                            {
                                shouldFail: experimentInstance.isOpen('dm_failOnSlotValidationErrors'),
                                type: 'invalidRefComponentChildren',
                                message: 'Invalid components inside refComponent',
                                extras: {
                                    invalidRefCompChildren
                                }
                            }
                        ]
                    }
                }
            },
            invalidSlotsReference: (pointer, value) => {
                if (!experimentInstance.isOpen('dm_runSlotsValidator')) {
                    return
                }

                const {type: pointerType} = pointer

                if (pointerType === DATA_TYPES.slots && !_.isEmpty(value?.slots)) {
                    const referencedCompsIds = value.slots as Record<string, string>
                    const slotOwners = getSlotOwners(pointer)
                    const missingCompsIds = _.pickBy(referencedCompsIds, refId =>
                        _.some(slotOwners, ownerPointer => {
                            const owner = dal.get(ownerPointer)
                            return !_.includes(owner.components, refId)
                        })
                    )
                    if (!_.isEmpty(missingCompsIds)) {
                        return [
                            {
                                shouldFail: experimentInstance.isOpen('dm_failOnSlotValidationErrors'),
                                type: 'invalidSlotsReference',
                                message: 'Invalid slots reference to one or more of its components',
                                extras: {
                                    missingCompsIds
                                }
                            }
                        ]
                    }
                }
            }
        }
    }

    return {
        name: 'slots',
        createFilters,
        createValidator,
        createExtensionAPI
    }
}
export {createExtension}
