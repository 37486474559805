import * as _ from 'lodash'

import * as conversionUtils from '../conversionUtils'
import {shouldKeepOnMobile} from '../conversionUtils'
import * as semanticGroupsConverter from './semanticGroupsConverter'
import {conversionConfig} from '../conversionConfig'
import {
    getCustomWidth,
    getHorizontalMargin,
    getMarginForNextRow,
    getOffsetX,
    getOrderedChildren,
    getScaleFactor,
    getTopPadding,
    setHeight
} from './structureConverterUtils'
import {PROPORTIONAL_GROUP_FONT_SCALEDOWN_FACTOR} from './structureConverterConstants'
import {ComponentWithConversionData, ConversionSettings, ObjMap} from '../../types'

const removeComponentsThatWillBeReplacedByPresets = (component, futureMobileStructure) => {
    const componentContainer = conversionUtils.getParent(component.id, futureMobileStructure)
    if (componentContainer) {
        conversionUtils.removeChildrenFromById(componentContainer, component.id)
    }
}

/**
 *
 * @param component
 * @param presetComponent
 * @param allDesktopComponents
 * @param desktopStructure
 * @returns
 */
const reparentComponentAccordingToItMobilePreset = (
    component: ComponentWithConversionData,
    presetComponent: ComponentWithConversionData,
    allDesktopComponents: ObjMap<ComponentWithConversionData>,
    desktopStructure: ComponentWithConversionData
) => {
    const presetParentId = conversionUtils.getParentIdFromMobileHints(presetComponent as ComponentWithConversionData)
    const childParentId = conversionUtils.getParentIdFromMobileHints(component)
    const presetParent = allDesktopComponents[presetParentId]
    const desktopParent = allDesktopComponents[childParentId]
    /**
     * If component on desktop and on mobile has different parent,
     * it need to be moved to new parent as in mobile preset
     */
    if (presetParent.id !== desktopParent.id && allDesktopComponents[presetParent.id] !== undefined) {
        if (!conversionUtils.isPageComponent(presetParent)) {
            const newParent = conversionUtils.getComponentByIdFromStructure(presetParent.id, desktopStructure)
            if (!newParent) {
                return
            }
            removeComponentsThatWillBeReplacedByPresets(component, desktopStructure)

            conversionUtils.addComponentsTo(newParent, [component])
        }
    }
}

export function applyPreset(
    component: ComponentWithConversionData,
    presetComponent: ComponentWithConversionData,
    settings: ConversionSettings,
    allDesktopComponents: ObjMap<ComponentWithConversionData> | undefined,
    desktopStructure: ComponentWithConversionData | undefined
): ComponentWithConversionData {
    _.assign(component, _.omit(presetComponent, ['conversionData', 'components']))

    if (settings.skipLayoutOverwrite && allDesktopComponents !== undefined) {
        reparentComponentAccordingToItMobilePreset(component, presetComponent, allDesktopComponents, desktopStructure)
    }
    const presetChildren = conversionUtils.getChildren(presetComponent)

    if (!_.isEmpty(presetChildren)) {
        const components = presetChildren.map(childPreset => {
            let child: ComponentWithConversionData
            if (settings.skipLayoutOverwrite && allDesktopComponents !== undefined) {
                child = allDesktopComponents[childPreset.id]
                if (child) {
                    removeComponentsThatWillBeReplacedByPresets(child, desktopStructure)
                }
            } else {
                child = conversionUtils.getComponentByIdFromStructure(childPreset.id, component) as ComponentWithConversionData
            }
            return child ? applyPreset(child, <ComponentWithConversionData>childPreset, settings, allDesktopComponents, desktopStructure) : null
        })
        component.components = _.compact(components)
    }

    return component
}
function rescaleLayout(comp: ComponentWithConversionData, scaleFactor: number, dims: string[] = ['x', 'y', 'width', 'height']): void {
    if (scaleFactor === 1) {
        return
    }
    _.assign(
        comp.layout,
        _.zipObject(
            dims,
            _.map(dims, dim => Math.round(comp.layout[dim] * scaleFactor))
        )
    )
}
/**
 * @public
 * @param component
 * @param scaleFactor
 */
const rescaleProportionally = (component: ComponentWithConversionData, scaleFactor: number) => {
    rescaleLayout(component, scaleFactor)
    _.forEach(component.components, comp =>
        //@ts-expect-error
        rescaleProportionally(comp, scaleFactor)
    )
    rescaleText(component, scaleFactor)
}

/**
 * @public
 * @param ComponentWithConversionData component
 * @param number scaleFactor
 */
const rescaleText = (component: ComponentWithConversionData, scaleFactor: number) => {
    if (!conversionUtils.isTextComponent(component)) {
        return
    }
    const averageFontSize = component.conversionData.averageFontSize || 0
    component.layout.scale = (averageFontSize * scaleFactor * PROPORTIONAL_GROUP_FONT_SCALEDOWN_FACTOR) / component.conversionData.mobileAdjustedAverageFontSize
}
/**
 * @private
 * @param ComponentWithConversionData component
 * @param number allowedWidth
 * @param boolean hasCustomWidth
 * @param settings
 * @param modifiedComponents
 * @returns void
 */
const rescaleChildToFitParent = (
    component: ComponentWithConversionData,
    allowedWidth: number,
    hasCustomWidth: boolean,
    settings: ConversionSettings,
    allDesktopComponents: ObjMap<ComponentWithConversionData>,
    desktopStructure: ComponentWithConversionData | undefined
) => {
    if (_.has(component, ['conversionData', 'preset']) && _.get(component, ['conversionData', 'shouldApplyPreset'])) {
        applyPreset(component, <ComponentWithConversionData>component.conversionData.preset, settings, allDesktopComponents, desktopStructure)
        return
    }

    if (
        component.layout.width > allowedWidth ||
        component.conversionData.shouldEnlargeToFitWidth ||
        component.layout.width > conversionConfig.MIN_WIDTH_FOR_ENLARGE ||
        hasCustomWidth ||
        component.conversionData.rescaleMethod === 'row'
    ) {
        rescaleComponent(component, allowedWidth, settings, allDesktopComponents, desktopStructure)
    } else {
        rescaleComponentHeight(component, {scaleFactor: 1})
    }
}

/**
 * Function is called recursivly and calculate new layout
 * for mobile components it used by merge and optimize
 * @public
 * @param componentToUpdate
 * @param parent
 * @param allowedWidth
 * @param settings
 */
const reorganizeChildren = (
    componentToUpdate: ComponentWithConversionData,
    parent: ComponentWithConversionData,
    allowedWidth: number,
    settings: ConversionSettings = {
        imageScaleFactor: 1,
        applyFontScaling: true,
        enableNewMergeFlow: false
    },
    allDesktopComponents?: ObjMap<ComponentWithConversionData> | undefined,
    desktopStructure?: ComponentWithConversionData | undefined
) => {
    const orderedComponents = getOrderedChildren(componentToUpdate)

    const topRightMargin = parent.conversionData.topRightMargin || [0, 0]
    const firstComponent = _.head(orderedComponents)
    const topPadding = getTopPadding(componentToUpdate, parent, firstComponent)
    let y = topPadding

    _.forEach(orderedComponents, (curComp, i) => {
        const horizontalMargin = getHorizontalMargin(curComp, parent)

        let shouldKeepMobileSizes = false

        if (settings.enableNewMergeFlow) {
            shouldKeepMobileSizes = shouldKeepOnMobile(curComp, settings)
        }

        if ((settings.applyFontScaling && conversionUtils.isTextComponent(curComp)) || curComp.conversionData.mobileScale) {
            if (!shouldKeepMobileSizes) {
                curComp.layout.scale = curComp.conversionData.mobileScale
            }
        }

        const isScreenWidth = conversionUtils.shouldStretchToScreenWidth(curComp)

        let marginLeft = horizontalMargin
        let marginRight = horizontalMargin

        const alignment = curComp.conversionData.isInvisible || isScreenWidth ? 'screen' : componentToUpdate.conversionData.naturalAlignment || 'center'

        if (!settings.skipLayoutOverwrite) {
            if (y < topRightMargin[1] - topPadding) {
                marginRight = Math.max(marginRight, topRightMargin[0])
                if (alignment === 'center') {
                    marginLeft = marginRight
                }
            }
        }

        const allowedWidthWithoutMargins = Math.max(allowedWidth - marginLeft - marginRight, 0)

        const customWidth = getCustomWidth(curComp, allowedWidth, allowedWidthWithoutMargins, isScreenWidth, settings)
        const hasCustomWidth = _.isNumber(customWidth)
        const widthToFit = hasCustomWidth ? customWidth : allowedWidthWithoutMargins

        rescaleChildToFitParent(curComp, widthToFit, hasCustomWidth, settings, allDesktopComponents, desktopStructure)

        if (y < topRightMargin[1] && customWidth > allowedWidthWithoutMargins && !curComp.conversionData.isTransparentContainer) {
            y = topRightMargin[1]
            marginRight = marginLeft = horizontalMargin
        }

        if (_.isNumber(curComp.conversionData.yOffset)) {
            y -= curComp.conversionData.yOffset
        } else if (curComp.conversionData.yOffsetRatio) {
            y -= curComp.layout.height * curComp.conversionData.yOffsetRatio
        }
        if (!shouldKeepMobileSizes) {
            curComp.layout.x = getOffsetX(curComp, alignment, allowedWidth, marginRight, marginLeft, orderedComponents[i - 1])
            const isFirstInvisibleChild = curComp.conversionData.isInvisible && !i
            curComp.layout.y = y - (isFirstInvisibleChild ? topPadding + 1 : 0)

            if (!curComp.conversionData.stackLayout) {
                const nextRowMargin = getMarginForNextRow(curComp, orderedComponents[i + 1], settings.enableImprovedMergeFlow)
                const invisibleComponentsSizePlceholder = 1

                y += curComp.conversionData.isInvisible ? invisibleComponentsSizePlceholder : curComp.layout.height + nextRowMargin
            }
        }
    })
}

/**
 * @public
 * @param ComponentWithConversionData component
 * @param Object options
 * @param string[] allModifiedCompIds
 * @returns
 */
const rescaleComponentHeight = (component: ComponentWithConversionData, options: {scaleFactor: number}) => {
    const presetHeight = _.get(component.conversionData, ['mobileHints', 'recommendedHeight'])

    if (presetHeight) {
        return setHeight(component, presetHeight)
    }

    const fixedHeight = _.get(component.conversionData.fixedSize, 'height')

    if (conversionUtils.isGraphicComponent(component) && fixedHeight) {
        return setHeight(component, fixedHeight)
    }

    if (conversionUtils.isTextComponent(component) && !conversionUtils.isVerticalText(component)) {
        return setHeight(component, conversionConfig.DEFAULT_TEXT_HEIGHT)
    }

    const desktopHeight = component.layout.height
    const minHeight = component.conversionData.minHeight || 0

    if (_.isNumber(fixedHeight)) {
        setHeight(component, fixedHeight)
    } else if (_.get(component.conversionData, 'preserveAspectRatio', true)) {
        setHeight(component, Math.round(component.layout.height * options.scaleFactor))
    } else if (minHeight) {
        setHeight(component, 0)
    }
    setHeight(component, minHeight < desktopHeight ? Math.max(component.layout.height, minHeight) : desktopHeight)

    const heightAccordingToChildren = conversionUtils.getHeightAccordingToChildren(
        component,
        <ComponentWithConversionData[]>_.reject(component.components, 'conversionData.isInvisible'),
        false
    )

    if (
        heightAccordingToChildren &&
        (!fixedHeight || fixedHeight <= heightAccordingToChildren || conversionUtils.containsComponent(component, conversionUtils.isTextComponent))
    ) {
        setHeight(component, heightAccordingToChildren)
    }
}
/**
 * @public
 * @param component
 * @param parentWidth
 * @param settings
 * @param modifiedComponents
 */
const rescaleComponent = (
    component: ComponentWithConversionData,
    parentWidth: number,
    settings: ConversionSettings = {
        imageScaleFactor: 1,
        applyFontScaling: true
    },
    allDesktopComponent?: ObjMap<ComponentWithConversionData> | undefined,
    desktopStructure?: ComponentWithConversionData | undefined
) => {
    const scaleFactor = getScaleFactor(component, parentWidth)

    const method = component.conversionData.rescaleMethod
    if (method !== 'none' && !shouldKeepOnMobile(component, settings)) {
        component.layout.width = parentWidth
    }

    const nextSettings = <any>_.assign(settings, {
        imageScaleFactor: component.conversionData.descendantImageScaleFactor || settings.imageScaleFactor
    })

    switch (method) {
        case 'proportional':
            _.forEach(component.components, child =>
                //@ts-expect-error
                rescaleProportionally(child, scaleFactor)
            )
            break
        case 'logo':
            semanticGroupsConverter.convertLogo(component, scaleFactor)
            break
        case 'row':
            semanticGroupsConverter.convertRow(component, scaleFactor)
            break
        case 'none':
            break
        default:
            reorganizeChildren(component, component, parentWidth, nextSettings, allDesktopComponent, desktopStructure)
            break
    }

    rescaleComponentHeight(component, {scaleFactor})
}

export {reorganizeChildren, rescaleComponent, rescaleComponentHeight, rescaleText, rescaleLayout, rescaleProportionally}
