define(['lodash', 'documentServices/dataModel/dataModel', 'textCommon'], function (_, dataModel, textCommon) {
    'use strict'

    const {minInputHeight, defaultInputHeight} = textCommon.labelUtils

    function updateInputHeightProp(ps, compPointer, compProperties, mode) {
        const propToUpdate = mode === 'DESKTOP' ? 'inputHeight' : 'inputHeightMobile'
        const textInputStructure = ps.dal.get({type: mode, id: compPointer.id})
        if (!textInputStructure) {
            return
        }
        const inputHeight = Math.max(textInputStructure.layout.height, minInputHeight)
        dataModel.setPropertiesItem(ps, compPointer, _.assign(compProperties, {[propToUpdate]: inputHeight}))
    }

    function beforeUpdateInputLabelData(ps, compPointer, updatedDataItem) {
        if (_.isEmpty(updatedDataItem.label) || !_.isEmpty(dataModel.getDataItem(ps, compPointer).label)) {
            return
        }
        const compProperties = dataModel.getPropertiesItem(ps, compPointer)
        const updateInputHeightPropByMode = _.partial(updateInputHeightProp, ps, compPointer, compProperties)
        updateInputHeightPropByMode('DESKTOP')
        updateInputHeightPropByMode('MOBILE')
    }

    function beforeUpdateInputLabelLayout(ps, compPointer, newLayout) {
        if (!newLayout.height) {
            return
        }

        const propToUpdate = ps.pointers.components.isMobile(compPointer) ? 'inputHeightMobile' : 'inputHeight'

        const textInputStructure = ps.dal.get(compPointer)
        const compProperties = dataModel.getPropertiesItem(ps, compPointer)

        const hasLabel = !_.isEmpty(dataModel.getDataItem(ps, compPointer).label)
        const oldLayout = textInputStructure.layout

        const inputHeightWithLabel = (compProperties[propToUpdate] || defaultInputHeight) + newLayout.height - oldLayout.height
        const inputHeight = hasLabel ? inputHeightWithLabel : newLayout.height
        const height = Math.max(minInputHeight, inputHeight)

        dataModel.setPropertiesItem(ps, compPointer, _.assign(compProperties, {[propToUpdate]: height}))
    }

    return {
        beforeUpdateInputLabelData,
        beforeUpdateInputLabelLayout
    }
})
