import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const menuAPI = viewerLibrary.get('documentServices/menu/menuAPI')
    const dataModel = viewerLibrary.get('documentServices/dataModel/dataModel')
    return {
        methods: {
            multilingual: {
                languages: {
                    remove: publicMethodUtils.defineGetter(dataModel.multilingual.remove),
                    removeAll: publicMethodUtils.defineGetter(dataModel.multilingual.removeAll),
                    hasTranslations: publicMethodUtils.defineGetter(dataModel.multilingual.hasTranslations)
                },
                components: {
                    data: {
                        /**
                         *
                         * @param {ps} ps
                         * @param {string|undefined} languageCode
                         * @param {Pointer} componentPointer
                         * @param {boolean} deleteId
                         */
                        get: publicMethodUtils.defineGetter(dataModel.multilingual.get),
                        /**
                         *
                         * @param {ps} ps
                         * @param {string|undefined} languageCode
                         * @param {Pointer} componentPointer
                         * @param {any} data
                         */
                        update: publicMethodUtils.defineDataManipulationAction(dataModel.multilingual.update),
                        remove: publicMethodUtils.defineDataManipulationAction(dataModel.multilingual.removeByComponent),
                        removeAll: publicMethodUtils.defineDataManipulationAction(dataModel.multilingual.removeAllByComponent)
                    }
                },
                menu: {
                    /**
                     * @member documentServices.multilingual.menu
                     * @param {string} languageCode
                     * @param {string} menuId
                     * @returns {[menuData]}}
                     */
                    get: publicMethodUtils.defineGetter(menuAPI.multilingual.get),

                    /**
                     * @member documentServices.multilingual.menu
                     * @param {string} languageCode
                     * @param {string} menuId
                     * @param {menuData} menuData
                     */
                    update: publicMethodUtils.defineDataManipulationAction(menuAPI.multilingual.update),
                    /**
                     * @member documentServices.multilingual.menu
                     * @param {String} languageCode
                     * @param {String} menuId
                     * @param {String} itemId
                     * @param {menuItem} menuItem
                     */
                    updateItem: publicMethodUtils.defineDataManipulationAction(menuAPI.multilingual.updateItem)
                }
            }
        }
    }
}
