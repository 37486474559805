define(['documentServices/dataModel/dataModel', 'documentServices/appControllerData/appControllerUtils'], function (dataModel, appControllerUtils) {
    'use strict'

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {*|Pointer}
     */
    function getControllerDataItem(ps, controllerRef) {
        appControllerUtils.verifyController(ps, controllerRef)
        return dataModel.getDataItem(ps, controllerRef)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param dataItem
     * @param {boolean} useOriginalLanguage
     */
    function setControllerDataItem(ps, controllerRef, dataItem, useOriginalLanguage = false) {
        appControllerUtils.verifyController(ps, controllerRef)
        dataModel.updateDataItem(ps, controllerRef, dataItem, useOriginalLanguage)
    }

    return {
        getControllerDataItem,
        setControllerDataItem
    }
})
