define(['lodash'], function (_) {
    'use strict'

    const CONTACT_FORM_COMP_TYPE = 'wysiwyg.viewer.components.ContactForm'
    const CONTACT_FORM_DATA_TYPE = 'ContactForm'

    function resolveContactFormMetaData(privateServices, contactFormDataItem) {
        const contactFormsMetaDatas = privateServices.dal.get(privateServices.pointers.general.getContactFormsMetaData())
        const metaDataForContactForm = contactFormsMetaDatas[contactFormDataItem.id]
        _.merge(contactFormDataItem, metaDataForContactForm)
    }

    function updateContactFormMetaData(privateServices, componentPointer, dataItem) {
        if (dataItem) {
            const contactFormsMetaDataPointer = privateServices.pointers.general.getContactFormsMetaData()
            const contactFormsMetaDatas = privateServices.dal.get(contactFormsMetaDataPointer)
            const metaDataForContactForm = contactFormsMetaDatas[dataItem.id]
            const updatedContactFormMetaData = _.assign({}, metaDataForContactForm, _.pick(dataItem, _.keys(metaDataForContactForm)))
            const contactFormMetaDataPointer = privateServices.pointers.getInnerPointer(contactFormsMetaDataPointer, dataItem.id)
            privateServices.dal.set(contactFormMetaDataPointer, updatedContactFormMetaData)
        }
    }

    function initContactFromHooks(hooks) {
        hooks.registerHook(hooks.HOOKS.DATA.AFTER_GET, resolveContactFormMetaData, CONTACT_FORM_DATA_TYPE)
        hooks.registerHook(hooks.HOOKS.DATA.UPDATE_BEFORE, updateContactFormMetaData, CONTACT_FORM_COMP_TYPE)
    }

    return {
        initContactFromHooks
    }
})
