import _ from 'lodash'
import * as Constants from '../constants/constants'

const separator = '^'
const getTranslationsPath = (pageId: string, languageCode: string, dataItemId: string) => [
    pageId,
    'translations',
    languageCode,
    Constants.DATA_TYPES.data,
    Constants.PAGE_DATA_TYPES.data,
    dataItemId
]
const getTranslationInfoFromKey = (key: string) => _.split(key, separator)
const getTranslationItemKey = (pageId: string, languageCode: string, dataItemId: string) => `${pageId}${separator}${languageCode}${separator}${dataItemId}`
const getTranslationsByPage = (flatTranslations: Record<string, {metaData: {pageId: string}}>) => {
    const groupedByPage = {}

    _.forEach(flatTranslations, (value, key) => {
        const {pageId} = value.metaData
        const [, languageCode, dataItemId] = getTranslationInfoFromKey(key)

        _.setWith(groupedByPage, getTranslationsPath(pageId, languageCode, dataItemId), value, Object)
    })

    return groupedByPage
}

const getLanguageFromKey = (key: string) => {
    const [, languageCode] = getTranslationInfoFromKey(key)
    return languageCode
}

const isLanguageContainedInKey = (key: string, language: string) => key.includes(`${separator}${language}${separator}`)

export {getTranslationsByPage, getTranslationsPath, getTranslationInfoFromKey, getTranslationItemKey, getLanguageFromKey, isLanguageContainedInKey}
