define(['documentServices/componentsMetaData/metaDataUtils'], function (metaDataUtils) {
    'use strict'

    const {isMembersMenu} = metaDataUtils

    return {
        isRepeatable: false,
        canBeStretched: true,
        styleCanBeApplied: true,
        mobileConversionConfig: {
            hideByDefault(ps, compStructure, pageId) {
                return isMembersMenu(ps, compStructure, pageId) ? false : undefined
            },
            isScreenWidth(ps, compStructure, pageId) {
                return isMembersMenu(ps, compStructure, pageId) ? true : undefined
            },
            desktopOnly(ps, compStructure, pageId) {
                return !isMembersMenu(ps, compStructure, pageId)
            },
            fixedSize(ps, compStructure, pageId) {
                return isMembersMenu(ps, compStructure, pageId) ? {height: 48} : undefined
            }
        }
    }
})
