define([
    'lodash',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionBarManager',
    'documentServices/mobileConversion/mobileEditorSettings/mobileMetaDataUtils',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionBarItemsDataBuilder',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionsDefinitions'
], function (_, quickActionBarManager, mobileMetaDataUtils, quickActionBarItemsDataBuilder, quickActionsDefinitions) {
    'use strict'
    function revert(ps) {
        quickActionBarManager.removeQuickActionBar(ps)
    }

    function upgrade(ps) {
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        let quickActionBarItemsData = quickActionBarItemsDataBuilder.createFromSiteMetaData(siteMetaData)
        if (_.isEmpty(quickActionBarItemsData)) {
            quickActionBarItemsData = quickActionsDefinitions.defaultQuickActionItems
        }
        quickActionBarManager.addQuickActionBar(ps, quickActionsDefinitions.defaultSerializedMAB, quickActionBarItemsData)
    }

    function getVersion(ps) {
        return quickActionBarManager.hasQuickActionBar(ps) ? 2 : 1
    }

    return {
        revert,
        upgrade,
        getVersion
    }
})
