import _ from 'lodash'

const COMPONENTS_TO_KEEP_IN_STUB = [
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wixapps.integration.components.AppPage'
]

const stubifyContainerRecursively = (pointers, displayedDAL, containerPointer, acc = {}) => {
    const currentChildren = pointers.components.getChildren(containerPointer)
    const isContainerComponent = !_.isEmpty(currentChildren)

    if (!isContainerComponent) {
        const componentType = displayedDAL.get(pointers.getInnerPointer(containerPointer, ['componentType']))
        const shouldKeepComp = _.includes(COMPONENTS_TO_KEEP_IN_STUB, componentType)
        acc[containerPointer.id] = shouldKeepComp

        return shouldKeepComp
    }

    const stubChildren = _.filter(currentChildren, child => stubifyContainerRecursively(pointers, displayedDAL, child, acc))
    const hasDescendantCompToKeep = !_.isEmpty(stubChildren)
    acc[containerPointer.id] = hasDescendantCompToKeep

    return hasDescendantCompToKeep
}

const stubifyPage = (actionQueue, displayedDAL, pointers, viewMode, pageId) => {
    actionQueue.runImmediately(() => {
        const pagePointer = pointers.components.getPage(pageId, viewMode)

        const pageStubComponents = {}
        stubifyContainerRecursively(pointers, displayedDAL, pagePointer, pageStubComponents)

        displayedDAL.setByPath(['pageStubComponents', pageId], pageStubComponents)
    })
}

const clearStubifyPageData = (actionQueue, displayedDAL, pageId) => {
    actionQueue.runImmediately(() => {
        displayedDAL.setByPath(['pageStubComponents', pageId], {})
    })
}

export default {
    stubifyPage,
    clearStubifyPageData
}
