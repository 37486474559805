import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {tpaWarmup} = warmupUtils

const reportEvent = function (comp, event, params?) {
    if (comp && comp.reportPerformanceBiEvent) {
        const appData = comp.getAppData()
        const widgetId = comp.getWidgetId()
        tpaWarmup.reportPerformanceBiEvent(
            event,
            comp.props.reportBI,
            comp.props.biData,
            comp.props.id,
            comp.props.isTpaRenderedInSsr,
            appData,
            widgetId,
            params
        )
    }
}

const applicationLoaded = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    reportEvent(comp, tpaWarmup.events.APP_LOADED_SUCCESSFULLY)
}

const applicationLoadingStep = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    const params = {
        stage: msg.data.stage,
        stageNum: msg.data.stageNum
    }
    reportEvent(comp, tpaWarmup.events.APP_LOADED_PARTIALLY, params)
}

export default {
    applicationLoaded,
    applicationLoadingStep
}
