define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'document-services-schemas',
    'documentServices/constants/constants',
    'documentServices/theme/theme',
    '@wix/mobile-conversion',
    'documentServices/hooks/hooks'
], function (_, dataModel, documentServicesSchemas, constants, theme, mobileCore, hooks) {
    'use strict'
    const REF_FOR_LOGIN_SOCIAL_BAR = 'loginSocialBarRef'
    const LOGIN_SOCIAL_BAR = 'wysiwyg.viewer.components.LoginSocialBar'
    const TINY_MENU_DATA_SCHEMA = 'TinyMenu'
    const TINY_MENU_DATA_ITEM_ID = 'TINY_MENU'

    const isLoginSocialBar = (ps, compPointer) => ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType')) === LOGIN_SOCIAL_BAR

    function createTinyMenuData(ps, tinyMenuPointer) {
        const tinyMenuDataPointer = ps.pointers.data.getDataItemFromMaster('TINY_MENU')

        if (!ps.dal.isExist(tinyMenuDataPointer)) {
            const dataQueryPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'dataQuery')
            ps.dal.set(dataQueryPointer, `#${TINY_MENU_DATA_ITEM_ID}`)
            const emptyTinyMenuDataItem = documentServicesSchemas.services.createDataItemByType(TINY_MENU_DATA_SCHEMA)
            const tinyMenuData = _.assign({id: TINY_MENU_DATA_ITEM_ID}, emptyTinyMenuDataItem)
            dataModel.setDataItemByPointer(ps, tinyMenuDataPointer, tinyMenuData, 'data')
        }
    }

    function createTinyMenuConnectionDataItem(ps, tinyMenuPointer) {
        const connectionQueryPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'connectionQuery')
        if (!ps.dal.isExist(connectionQueryPointer)) {
            ps.dal.set(connectionQueryPointer, 'TINY_MENU_CONNECTION')
            dataModel.updateConnectionsItem(ps, tinyMenuPointer, [])
        }
    }

    function getFirstLoginSocialBar(ps) {
        const desktopHeaderPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.DESKTOP)
        const headerChildren = ps.pointers.components.getChildrenRecursively(desktopHeaderPointer)
        const desktopPointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.DESKTOP)
        const desktopPageChildren = ps.pointers.components.getChildrenRecursively(desktopPointer)
        //we specifically want to search header first, then masterPage
        const children = _.unionBy(headerChildren, desktopPageChildren, compPointer => compPointer.id)
        return _.find(children, childPointer => isLoginSocialBar(ps, childPointer))
    }

    function getTinyMenuOnlyConnections(ps, tinyMenuPointer) {
        const tinyMenuConnections = dataModel.getConnectionsItem(ps, tinyMenuPointer)
        const platformConnections = dataModel.getPlatformAppConnections(ps, tinyMenuPointer)
        return _.reject(tinyMenuConnections, ({type, role}) => _.find(platformConnections, {type, role}))
    }

    function connectToLoginSocialBar(ps, tinyMenuPointer, loginSocialBar, loginSocialBarQuery) {
        const componentDataQuery = ps.dal.get(ps.pointers.getInnerPointer(loginSocialBar, 'dataQuery'))
        ps.dal.set(loginSocialBarQuery, componentDataQuery)
        const loginSocialBarConnections = dataModel.getPlatformAppConnections(ps, loginSocialBar)
        const tinyMenuOnlyConnections = getTinyMenuOnlyConnections(ps, tinyMenuPointer)
        const updatedConnections = _.union(tinyMenuOnlyConnections, loginSocialBarConnections)
        dataModel.updateConnectionsItem(ps, tinyMenuPointer, updatedConnections)
    }

    function disconnectFromLoginSocialBar(ps, loginSocialBarQuery, tinyMenuPointer) {
        if (ps.dal.isExist(loginSocialBarQuery)) {
            ps.dal.remove(loginSocialBarQuery)
            const tinyMenuOnlyConnections = getTinyMenuOnlyConnections(ps, tinyMenuPointer)
            dataModel.updateConnectionsItem(ps, tinyMenuPointer, tinyMenuOnlyConnections)
        }
    }

    function applyDataToTinyMenu(ps) {
        const pagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.MOBILE)
        const tinyMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, pagePointer)
        if (!tinyMenuPointer || !ps.dal.isExist(tinyMenuPointer)) {
            return
        }

        createTinyMenuData(ps, tinyMenuPointer)
        createTinyMenuConnectionDataItem(ps, tinyMenuPointer)
        const loginSocialBar = getFirstLoginSocialBar(ps)
        const loginSocialBarQuery = ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('TINY_MENU'), REF_FOR_LOGIN_SOCIAL_BAR)

        if (loginSocialBar) {
            connectToLoginSocialBar(ps, tinyMenuPointer, loginSocialBar, loginSocialBarQuery)
        } else {
            disconnectFromLoginSocialBar(ps, loginSocialBarQuery, tinyMenuPointer)
        }
    }

    function addAdditionalStyles(ps) {
        _.forOwn(mobileCore.mobileOnlyComponents.getAdditionalStyles(), (styleProps, styleId) => {
            if (theme.styles.get(ps, styleId)) {
                return
            }
            const style = _.assign(
                {
                    type: 'TopLevelStyle',
                    styleType: 'system',
                    id: styleId,
                    metaData: {isHidden: false, isPreset: false}
                },
                styleProps
            )
            theme.styles.createAndAddStyleItem(ps, style, styleId)
        })
    }

    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, deleteLoginSocialBarRefFromTinyMenu, 'wysiwyg.viewer.components.LoginSocialBar')

    function deleteLoginSocialBarRefFromTinyMenu(ps) {
        const pagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, constants.VIEW_MODES.MOBILE)
        const tinyMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, pagePointer)
        if (!tinyMenuPointer || !ps.dal.isExist(tinyMenuPointer)) {
            return
        }
        const loginSocialBarQuery = ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('TINY_MENU'), REF_FOR_LOGIN_SOCIAL_BAR)
        disconnectFromLoginSocialBar(ps, loginSocialBarQuery, tinyMenuPointer)
    }

    return {
        applyDataToTinyMenu,
        addAdditionalStyles
    }
})
