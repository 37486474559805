define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/page/page',
    '@wix/santa-ds-libs/src/utils'
], function (_, dataModel, generalInfo, page, utils) {
    'use strict'

    function updateComponentData(ps, compPointer, compData) {
        const args = [ps, compPointer, compData]
        const params = {
            methodName: 'components.data.update',
            singleComp: dataModel.shouldUpdateDataWithSingleComp(ps, compPointer)
        }
        ps.setOperationsQueue.runSetOperation(dataModel.updateDataItem, args, params)
    }

    function updateUrlFormatIfNeeded(ps, compPointer, urlFormat) {
        const compData = dataModel.getDataItem(ps, compPointer)

        if (compData && compData.urlFormat !== urlFormat) {
            compData.urlFormat = urlFormat
            updateComponentData(ps, compPointer, compData)
        }
    }

    function enableHttps(ps, compPointer) {
        const compData = dataModel.getDataItem(ps, compPointer)
        if (compData) {
            compData.isHttpsEnabled = true
            updateComponentData(ps, compPointer, compData)
        }
    }

    function upgradeUrl(ps, compPointer, socialType, options) {
        const urlFormats = generalInfo.getPossibleUrlFormats()
        const hashBangUrl = page.getSocialUrl(ps, urlFormats.HASH_BANG, options && options.forceMainPage)

        if (generalInfo.isHttpsEnabled(ps)) {
            enableHttps(ps, compPointer)
        }

        if (generalInfo.isUsingUrlFormat(ps, urlFormats.HASH_BANG)) {
            updateUrlFormatIfNeeded(ps, compPointer, urlFormats.HASH_BANG)
        } else if (socialType) {
            _.get(utils.socialAPI, socialType)(hashBangUrl, function (isActiveSocialLink) {
                updateUrlFormatIfNeeded(ps, compPointer, isActiveSocialLink ? urlFormats.HASH_BANG : urlFormats.SLASH)
            })
        }
    }

    return {
        updateUrlFormatIfNeeded,
        upgradeUrl
    }
})
