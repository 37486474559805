import * as verticalDividersConversion from './verticalDividersConversion'
import * as virtualGroupHandler from './virtualGroupHandler'
import {getAllCompsInStructure, isDockingAllowed} from './conversionUtils'
import {isMobileOnlyComponent} from './mobileOnlyComponents/mobileOnlyComponentsUtils'
import * as _ from 'lodash'
import {ComponentWithConversionData, MasterPageComponentWithConversionData, Component, MasterPageComponent, ObjMap} from '../types'

export function postProcessStructure(
    component: ComponentWithConversionData | MasterPageComponentWithConversionData,
    children: ComponentWithConversionData[]
): void {
    verticalDividersConversion.postprocess(component)
    virtualGroupHandler.flattenGroups(component, children)
}

function removeConversionData(comp: ComponentWithConversionData | MasterPageComponentWithConversionData): Component | MasterPageComponent {
    if (comp.layout) {
        delete comp.layout.anchors
        if (!isMobileOnlyComponent(comp) && !isDockingAllowed(comp)) {
            delete comp.layout.docked
        }
    }
    delete comp.conversionData
    return <Component | MasterPageComponent>comp
}

export function removeConversionDataRecursively(
    component: ComponentWithConversionData | MasterPageComponentWithConversionData
): Component | MasterPageComponent {
    const allDescendants = getAllCompsInStructure(component) as ObjMap<ComponentWithConversionData>
    _.forEach(allDescendants, removeConversionData)
    return removeConversionData(component)
}
