const DEFINITIONS_TYPES = {
    GETTER: 'GETTER',
    ACTION: 'ACTION',
    DATA_MANIPULATION_ACTION: 'DATA_MANIPULATION_ACTION'
}

const METHOD_TYPES = {
    READ: 'read',
    ACTION: 'actions'
}

const LOG_TYPES = {
    DATA_MANIPULATION_ACTION: 'Q OPERATION ADDED',
    ACTION: 'IMMEDIATE ACTION'
}

export {DEFINITIONS_TYPES, METHOD_TYPES, LOG_TYPES}
