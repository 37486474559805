import coreUtilsLib from '@wix/santa-core-utils'

function isSkinRepresentation(splitted) {
    return splitted.length > 1
}

export default {
    shortenStyleId(styleIdOrSkin) {
        const splitted = (styleIdOrSkin || '').split('.')
        if (isSkinRepresentation(splitted)) {
            const fullSkinNameHashValue = coreUtilsLib.hashUtils.SHA256.b64_sha256(styleIdOrSkin)
            const result = fullSkinNameHashValue.substr(fullSkinNameHashValue.length - 5) + splitted.pop()
            return `s_${result.replace(/[^a-z]/gi, '')}`
        }
        return styleIdOrSkin
    }
}
