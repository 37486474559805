define(['lodash'], function (_) {
    'use strict'

    function isPlatformAppInstalled(plaformApps, appData) {
        if (!appData) {
            return false
        }
        const appDefinitionId = _.get(appData, 'appDefinitionId')
        const appInstalledPages = _.get(plaformApps, [appDefinitionId], {})

        return !_.isEmpty(appInstalledPages)
    }

    return {
        isPlatformAppInstalled
    }
})
