define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'experiment',
    '@wix/santa-ds-libs/src/wixCode',
    'documentServices/hooks/hooks',
    'documentServices/wixCode/services/wixCodeLifecycleService',
    'documentServices/wixCode/services/fileSystemService',
    'documentServices/wixCode/services/filesDAL',
    'documentServices/wixCode/services/userCodeCacheKillerService',
    'documentServices/wixCode/services/saveService',
    'documentServices/wixCode/services/appSaveState',
    'documentServices/wixCode/utils/fileDescriptorUtils',
    'documentServices/wixCode/utils/errors',
    'documentServices/wixCode/utils/codeAppInfo',
    'documentServices/wixCode/utils/undoRedo',
    'documentServices/wixCode/utils/schemaUtils',
    'documentServices/wixCode/utils/fetchResponseErrorObject',
    'documentServices/wixCode/utils/systemFolders',
    'documentServices/platform/platform',
    'documentServices/constants/constants',
    'documentServices/wixCode/utils/constants',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/services/wixDataSchemas',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/utils/utils',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/wixCode/services/disabledWixCodeSave'
], function (
    _,
    coreUtils,
    experiment,
    wixCode,
    hooks,
    wixCodeLifecycleService,
    fileSystemService,
    filesDAL,
    userCodeCacheKillerService,
    saveService,
    appSaveState,
    fileDescriptorUtils,
    errors,
    codeAppInfoUtils,
    undoRedoUtils,
    schemaUtils,
    fetchResponseErrorObject,
    systemFolders,
    platform,
    dsConstants,
    constants,
    kibana,
    wixDataSchemas,
    clientSpecMapUtils,
    wixCodeUtils,
    extensionsAPI,
    disabledWixCodeSave
) {
    'use strict'

    const {getCodeAppInfoFromPS} = codeAppInfoUtils

    function _updatePagePlatformApp(ps, fileDescriptor) {
        if (fileDescriptorUtils.isPageFile(fileDescriptor)) {
            const fileName = fileDescriptorUtils.getFileName(fileDescriptor)
            const pageId = fileName.replace('.js', '')
            const pagePointer = ps.pointers.components.getPage(pageId, dsConstants.VIEW_MODES.DESKTOP)
            if (pagePointer) {
                platform.updatePagePlatformApp(ps, pagePointer, constants.WIX_CODE_APP_ID, true)
            }
        }
    }

    function _getParentFolderDescriptor(ps, itemDescriptor) {
        const parentFolderLocation = fileDescriptorUtils.getParentFolderPath(itemDescriptor)
        return getVirtualDescriptor(ps, parentFolderLocation, true)
    }

    function _addItemToFolder(ps, itemDescriptor) {
        const folderDescriptor = _getParentFolderDescriptor(ps, itemDescriptor)
        if (folderDescriptor !== itemDescriptor && !_isKnownFolder(ps, folderDescriptor)) {
            _addItemToFolder(ps, folderDescriptor)
        }
        filesDAL.addChild(ps, folderDescriptor.location, itemDescriptor)
    }

    function _removeItemFromFolder(ps, itemDescriptor) {
        const folderDescriptor = _getParentFolderDescriptor(ps, itemDescriptor)

        filesDAL.removeChild(ps, folderDescriptor.location, itemDescriptor)
    }

    function _isKnownFolder(ps, folderDescriptor) {
        if (fileDescriptorUtils.isSystemFolder(folderDescriptor)) {
            return true
        }

        if (filesDAL.isChildrenExists(ps, folderDescriptor.location)) {
            return true // present in children cache
        }

        const parentFolderDescriptor = _getParentFolderDescriptor(ps, folderDescriptor)
        if (filesDAL.hasKnownChild(ps, parentFolderDescriptor.location, folderDescriptor)) {
            return true // present in parent's children cache
        }

        return false
    }

    async function copyFile(ps, fileDescriptor, targetFolderDescriptor, newItemName) {
        if (!fileDescriptorUtils.isFile(fileDescriptor)) {
            throw new errors.ArgumentError('fileDescriptor', 'filesAPI.copyFile', fileDescriptor.location)
        }
        if (!fileDescriptorUtils.isFolder(targetFolderDescriptor)) {
            throw new errors.ArgumentError('targetFolderDescriptor', 'filesAPI.copyFile', targetFolderDescriptor.location)
        }

        const targetFilePath = targetFolderDescriptor.location + newItemName

        const targetFileDescriptor = fileSystemService.getVirtualDescriptor(targetFilePath, false)
        _updatePagePlatformApp(ps, targetFileDescriptor)

        if (filesDAL.isFileExists(ps, fileDescriptor.location)) {
            const content = filesDAL.readFile(ps, fileDescriptor.location)
            filesDAL.writeFile(ps, targetFilePath, content)
        } else {
            filesDAL.markFileForDuplication(ps, fileDescriptor.location, targetFilePath)
        }

        _addItemToFolder(ps, targetFileDescriptor)

        await wixCodeLifecycleService.ensureAppIsWriteable(ps)
        hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, /*compType*/ undefined, [ps])
    }

    function isFileNotFoundError(error) {
        return _.get(error, 'xhr.status') === 404
    }

    const ElementoryErrorCode = {
        COLLECTION_DOES_NOT_EXIST: 'WD_SCHEMA_DOES_NOT_EXIST',
        COLLECTION_IS_DELETED: 'WD_COLLECTION_DELETED'
    }

    async function writeFile(ps, fileDescriptor, newContent) {
        const contentToWrite = newContent || ''
        const operationIsInvalidateCacheCommand = fileDescriptor.location === `${systemFolders.SCHEMAS}/$commands/invalidateCache`
        const operationIsInvalidateAuthorizationConfigCommand = fileDescriptor.location === `${systemFolders.BACKEND}/$commands/invalidateAuthorizationConfig`

        if (operationIsInvalidateCacheCommand) {
            invalidateSchemasAndNotifySubscribers(ps)
            return
        }

        if (operationIsInvalidateAuthorizationConfigCommand) {
            invalidateAuthorizationConfig(ps)
            return
        }

        if (!fileDescriptorUtils.isFile(fileDescriptor)) {
            throw new errors.ArgumentError('fileDescriptor', 'filesAPI.writeFile', fileDescriptor.location)
        }

        const fileIsInSchemas = schemaUtils.isSchemaFile(fileDescriptor.location)
        if (fileIsInSchemas && !schemaUtils.isValidContent(contentToWrite)) {
            throw new Error('Invalid file content')
        }

        const folderDescriptor = _getParentFolderDescriptor(ps, fileDescriptor)
        if (!_isKnownFolder(ps, folderDescriptor)) {
            if (fileDescriptorUtils.isInsideFolder(getRoots().pages, fileDescriptor)) {
                throw new Error(`cannot write to unknown folder: ${folderDescriptor.location}`)
            }
        }

        _updatePagePlatformApp(ps, fileDescriptor)

        const fileAlreadyExists = filesDAL.isFileExists(ps, fileDescriptor.location)

        filesDAL.writeFile(ps, fileDescriptor.location, contentToWrite)
        _addItemToFolder(ps, fileDescriptor)

        const readUndoableFile = () =>
            !fileAlreadyExists && undoRedoUtils.isUndoableFile(fileDescriptor.location) ? readFileFromServer(ps, fileDescriptor) : Promise.resolve()

        await Promise.all([readUndoableFile(), wixCodeLifecycleService.ensureAppIsWriteable(ps)])
        hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_CONTENT_CHANGED, /*compType*/ undefined, [ps, fileDescriptor])
        hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, /*compType*/ undefined, [ps])
        return fileDescriptor
    }

    function loadChildren(ps, tree, parentPath) {
        const isDirectory = node => !_.isString(node)
        const childDescriptors = _.map(tree, (child, relativePath) =>
            fileSystemService.getVirtualDescriptor(`${parentPath}/${relativePath}`, isDirectory(child))
        )
        filesDAL.loadChildren(ps, `${parentPath}/`, childDescriptors)
    }

    function recursiveLoadToDAL(ps, tree = {}, parentPath) {
        const isFile = node => _.isString(node)

        if (isFile(tree)) {
            filesDAL.loadFileContent(ps, parentPath, tree)
        } else {
            loadChildren(ps, tree, parentPath)
            _.keys(tree).forEach(relativePath => recursiveLoadToDAL(ps, tree[relativePath], `${parentPath}/${relativePath}`))
        }
    }

    async function loadAllFiles(ps) {
        const files = await fileSystemService.readAllFiles(getCodeAppInfoFromPS(ps))
        const filesToLoad = files.filter(({path}) => !schemaUtils.isSchemaFile(path))
        const tree = _.reduce(filesToLoad, (currentTree, {path, content}) => _.set(currentTree, path.split('/'), content), {})
        _.map(['public', 'backend'], relativePath => recursiveLoadToDAL(ps, tree[relativePath], [relativePath]))
    }

    async function readFileFromServer(ps, fileDescriptor, traceEnd) {
        traceEnd = traceEnd || kibana.trace(ps, {action: 'DSFS.readFile', message: {file: fileDescriptor.location}})
        const sourceFilePath = filesDAL.getSourceFilePath(ps, fileDescriptor.location)
        const sourceFileDescriptor = fileSystemService.getVirtualDescriptor(sourceFilePath, false)

        const isSchemaFile = schemaUtils.isSchemaFile(fileDescriptor.location)
        return (isSchemaFile ? readSchemaFileUsingWixDataSchemas() : readFileUsingFileSystemService())
            .catch(function (error) {
                const fileIsNotOnServer = isSchemaFile
                    ? [ElementoryErrorCode.COLLECTION_DOES_NOT_EXIST, ElementoryErrorCode.COLLECTION_IS_DELETED].includes(error.code)
                    : isFileNotFoundError(error)
                const fileExistsButNotYetOnServer = fileIsNotOnServer && filesDAL.isFileExists(ps, fileDescriptor.location)
                return fileExistsButNotYetOnServer ? null : Promise.reject(error)
            })
            .then(
                function (fileContent) {
                    filesDAL.loadFileContent(ps, fileDescriptor.location, fileContent)
                    filesDAL.updateDuplicates(ps, fileDescriptor.location, fileContent)
                    traceEnd({message: 'read file from server'})
                    return filesDAL.readFile(ps, fileDescriptor.location)
                },
                function (error) {
                    traceEnd(
                        error.code === ElementoryErrorCode.COLLECTION_IS_DELETED
                            ? {level: kibana.levels.INFO, message: error.message}
                            : {level: kibana.levels.ERROR, message: error}
                    )

                    throw error
                }
            )

        async function readSchemaFileUsingWixDataSchemas() {
            const schema = await wixDataSchemas.get(getCodeAppInfoFromPS(ps), schemaUtils.getSchemaIdFromFilePath(fileDescriptor.location))
            if (!schema) {
                const error = new Error()
                // @ts-ignore
                error.code = ElementoryErrorCode.COLLECTION_DOES_NOT_EXIST
                throw error
            }
            return JSON.stringify(schema)
        }

        function readFileUsingFileSystemService() {
            return fileSystemService.readFile(getCodeAppInfoFromPS(ps), sourceFileDescriptor)
        }
    }

    function readFile(ps, fileDescriptor) {
        if (!fileDescriptorUtils.isFile(fileDescriptor)) {
            return Promise.reject(new errors.ArgumentError('fileDescriptor', 'filesAPI.readFile', fileDescriptor.location))
        }

        const traceEnd = kibana.trace(ps, {action: 'DSFS.readFile', message: {file: fileDescriptor.location}})

        if (filesDAL.isFileReadable(ps, fileDescriptor.location)) {
            traceEnd({message: 'read file from cache'})
            return Promise.resolve(filesDAL.readFile(ps, fileDescriptor.location))
        }
        return readFileFromServer(ps, fileDescriptor, traceEnd)
    }

    function updateDALCacheAfterDescriptorRename(ps, fileDescriptor, newFileDescriptor) {
        if (fileDescriptorUtils.isFolder(fileDescriptor)) {
            const parentPath = fileDescriptorUtils.getParentFolderPath(fileDescriptor)
            filesDAL.clearCache(ps, [parentPath])
        } else {
            filesDAL.moveFile(ps, fileDescriptor.location, newFileDescriptor.location)
            _addItemToFolder(ps, newFileDescriptor)
            _removeItemFromFolder(ps, fileDescriptor)
        }
    }

    async function moveFileOrFolder(ps, fileDescriptor, targetFolderDescriptor, newItemName) {
        if (!fileDescriptorUtils.isFileSystemItem(fileDescriptor)) {
            throw new errors.ArgumentError('fileDescriptor', 'filesAPI.moveFile', fileDescriptor.location)
        }
        if (!fileDescriptorUtils.isInsideFolder(targetFolderDescriptor, fileDescriptor)) {
            throw new errors.FileSystemError('Cannot move files between folders')
        }
        if (fileDescriptorUtils.isPageFile(fileDescriptor)) {
            throw new errors.FileSystemError('Cannot move page files')
        }

        const traceEnd = kibana.trace(ps, {
            action: 'DSFS.moveFile',
            message: {
                file: fileDescriptor.location,
                newName: newItemName
            }
        })

        userCodeCacheKillerService.notifyPathModified(ps, fileDescriptor.location)

        try {
            await wixCodeLifecycleService.ensureAppIsWriteable(ps)
            await flush(ps, {origin: FLUSH_ORIGINS.MOVE_FILE})
            const newFileDescriptor = await fileSystemService.move(
                extensionsAPI.getAPI(ps),
                getCodeAppInfoFromPS(ps),
                fileDescriptor,
                targetFolderDescriptor,
                newItemName
            )

            updateDALCacheAfterDescriptorRename(ps, fileDescriptor, newFileDescriptor)
            hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, /*compType*/ undefined, [ps])
            traceEnd({message: {newFile: newFileDescriptor.location}})
            return newFileDescriptor
        } catch (error) {
            traceEnd({message: error, level: kibana.levels.ERROR})
            throw error
        }
    }

    async function deleteItem(ps, itemDescriptor) {
        if (fileDescriptorUtils.isPageFile(itemDescriptor)) {
            throw new errors.FileSystemError('Cannot delete page files')
        }
        if (fileDescriptorUtils.isSystemFolder(itemDescriptor)) {
            throw new errors.FileSystemError('Cannot delete system folders')
        }

        const traceEnd = kibana.trace(ps, {action: 'DSFS.deleteItem', message: {item: itemDescriptor.location}})

        userCodeCacheKillerService.notifyPathModified(ps, itemDescriptor.location)

        filesDAL.markPathAsDeleted(ps, itemDescriptor.location, itemDescriptor.directory)
        try {
            await wixCodeLifecycleService.ensureAppIsWriteable(ps)
            const result = await flush(ps, {origin: FLUSH_ORIGINS.DELETE_ITEM})
            if (fileDescriptorUtils.isFolder(itemDescriptor)) {
                filesDAL.deleteFolder(ps, itemDescriptor.location)
            } else {
                filesDAL.deleteFile(ps, itemDescriptor.location)
            }
            _removeItemFromFolder(ps, itemDescriptor)

            // When the last schema is removed from a subdirectory of `.schemas`, delete the subdirectory from cache.
            // There’s no such thing as a subdirectory on Wix Data Schemas server: we have to create/delete one
            // for nested schemas within the same namespace for their structural representation in the file system.
            if (schemaUtils.isSchemaFile(itemDescriptor.location)) {
                const parentFolderDescriptor = _getParentFolderDescriptor(ps, itemDescriptor)
                const children = filesDAL.getChildren(ps, parentFolderDescriptor.location)
                if (typeof children !== 'undefined' && children.length === 0) {
                    filesDAL.deleteFolder(ps, parentFolderDescriptor.location)
                    _removeItemFromFolder(ps, parentFolderDescriptor)
                }
            }
            hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, /*compType*/ undefined, [ps])
            traceEnd({message: result})
            return result
        } catch (error) {
            filesDAL.clearDeletedPath(ps, itemDescriptor.location)
            traceEnd({message: error, level: kibana.levels.ERROR})
            throw error
        }
    }

    async function createFolder(ps, folderName, parentFolderDescriptor) {
        const traceEnd = kibana.trace(ps, {
            action: 'DSFS.createFolder',
            message: {
                name: folderName,
                parent: parentFolderDescriptor.location
            }
        })

        try {
            await wixCodeLifecycleService.ensureAppIsWriteable(ps)
            const newFolderDescriptor = await fileSystemService.createFolder(
                extensionsAPI.getAPI(ps),
                getCodeAppInfoFromPS(ps),
                folderName,
                parentFolderDescriptor
            )
            _addItemToFolder(ps, newFolderDescriptor)
            hooks.executeHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, /*compType*/ undefined, [ps])
            traceEnd({message: {newFolder: newFolderDescriptor.location}})
            return newFolderDescriptor
        } catch (error) {
            traceEnd({message: error, level: kibana.levels.ERROR})
            throw error
        }
    }

    function getChildren(ps, parentFolderDescriptor) {
        const traceEnd = kibana.trace(ps, {action: 'DSFS.getChildren', message: {parent: parentFolderDescriptor.location}})

        return loadToCacheIfNeeded().then(returnFromCache).then(logSuccess, logError)

        function loadToCacheIfNeeded() {
            if (filesDAL.areChildrenLoaded(ps, parentFolderDescriptor.location)) {
                return Promise.resolve({didExistInCache: true})
            }
            const folderIsInSchemas = schemaUtils.isSchemaFile(parentFolderDescriptor.location)
            return (folderIsInSchemas ? loadToCacheUsingWixDataSchemas() : loadToCacheUsingFileSystemService()).then(() => ({didExistInCache: false}))

            function loadToCacheUsingWixDataSchemas() {
                return wixDataSchemas.list(getCodeAppInfoFromPS(ps)).then(schemaById => {
                    const schemasDirectoryPath = `${systemFolders.SCHEMAS}/`
                    _(schemaById)
                        .tap(loadFileContentsOfSchemas)
                        .groupBy(getDirectoryPathOfSchema)
                        .mapValues(getDescriptorsForSchemaFiles)
                        .tap(createNamespaceDirectoriesInSchemasDirectory)
                        .forEach(loadChildrenOfDirectory)

                    function loadFileContentsOfSchemas(schemas) {
                        _.forEach(schemas, schema => {
                            filesDAL.loadFileContent(ps, getPathToSchema(schema), JSON.stringify(schema))
                        })
                    }

                    function getDirectoryPathOfSchema(schema) {
                        return schema.namespace ? `${schemasDirectoryPath + schema.namespace}/` : schemasDirectoryPath
                    }

                    function getDescriptorsForSchemaFiles(schemas) {
                        return _.map(schemas, schema => getVirtualDescriptor(ps, getPathToSchema(schema), false))
                    }

                    function getPathToSchema(schema) {
                        return `${schemasDirectoryPath + schema.id}.json`
                    }

                    function createNamespaceDirectoriesInSchemasDirectory(childrenByDirectoryPath) {
                        const namespaceDirectoryPaths = _(childrenByDirectoryPath).keys().without(schemasDirectoryPath).value()
                        const namespaceDirectoryDescriptors = _.map(namespaceDirectoryPaths, namespaceDirectoryPath =>
                            getVirtualDescriptor(ps, namespaceDirectoryPath, true)
                        )

                        const schemasDirectoryChildren = _.get(childrenByDirectoryPath, schemasDirectoryPath, [])
                        childrenByDirectoryPath[schemasDirectoryPath] = namespaceDirectoryDescriptors.concat(schemasDirectoryChildren)
                    }

                    function loadChildrenOfDirectory(children, directoryPath) {
                        filesDAL.loadChildren(ps, directoryPath, children)
                    }
                })
            }

            async function loadToCacheUsingFileSystemService() {
                await flush(ps, {origin: FLUSH_ORIGINS.GET_CHILDREN})
                const loadedChildren = await fileSystemService.getChildren(getCodeAppInfoFromPS(ps), parentFolderDescriptor)
                return filesDAL.loadChildren(ps, parentFolderDescriptor.location, loadedChildren)
            }
        }

        function returnFromCache(result) {
            return {
                children: filesDAL.getChildren(ps, parentFolderDescriptor.location),
                didExistInCache: result.didExistInCache
            }
        }

        function logSuccess(result) {
            const message = result.didExistInCache ? 'read from cache' : 'read from server'
            traceEnd({message: {message, first10Children: _.take(result.children, 10)}})
            return result.children
        }

        function logError(error) {
            traceEnd({message: error, level: kibana.levels.ERROR})
            throw error
        }
    }

    function getRoots() {
        return fileSystemService.getRoots()
    }

    function getVirtualDescriptor(ps, givenPath, isDirectory) {
        return fileSystemService.getVirtualDescriptor(givenPath, isDirectory)
    }

    async function findByExtension(ps, parentFolder, extension) {
        const traceEnd = kibana.trace(ps, {
            action: 'DSFS.findByExtension',
            message: {
                parent: parentFolder.location,
                extension
            }
        })

        try {
            const result = await fileSystemService.findByExtension(getCodeAppInfoFromPS(ps), parentFolder, extension)
            traceEnd({message: {first10Results: _.take(result, 10)}})
            return result
        } catch (e) {
            traceEnd({message: e, level: kibana.levels.ERROR})
            throw e
        }
    }

    function prefetchUserCode(ps) {
        const wixCodeModelPointer = ps.pointers.wixCode.getWixCodeModel()
        const wixCodeModel = ps.dal.get(wixCodeModelPointer)
        const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)

        const renderedRoots = ps.siteAPI.getAllRenderedRootIds().filter(rootId => platform.pageHasPlatformApp(ps, rootId, constants.WIX_CODE_APP_ID))
        return Promise.all(
            renderedRoots.map(rootId => {
                const scriptName = `${rootId}.js`
                const url = wixCode.wixCodeUserScriptsService.getUserCodeUrl(scriptName, rootId, wixCodeModel, wixCodeSpec, ps)

                return wixCodeUtils
                    .sendRequestObj({
                        url,
                        headers: {
                            Accept: '*/*'
                        },
                        type: 'GET',
                        dataType: 'text' // makes zepto not evaluate our prefetched script
                    })
                    .catch(() => {
                        /* don't care about prefetching errors for now */
                    })
            })
        )
    }

    /**
     * @param {ps} ps
     * @param origin
     * @returns {Promise<void>}
     */
    function flush(ps, {origin = 'MISSING'} = {}) {
        const traceEnd = kibana.trace(ps, {action: 'DSFS.flush', params: {flushOrigin: origin}})

        return new Promise((resolve, reject) => {
            // Flush operations queue before saving because some queued actions may affect schema files (add/remove dynamic page, undo/redo)
            ps.setOperationsQueue.flushQueueAndExecute(function () {
                const changes = filesDAL.getChanges(ps)
                if (filesDAL.isChangesEmpty(changes)) {
                    const saveInProgressPromise = appSaveState.getState()
                    if (saveInProgressPromise) {
                        traceEnd({message: 'no new changes, waiting for pending changes'})
                        resolve(saveInProgressPromise)
                        return
                    }
                    traceEnd({message: 'no changes'})
                    resolve()
                    return
                }

                // Clone action is added to the queue
                wixCodeLifecycleService.ensureAppIsWriteable(ps)

                // Save action is added to the queue right after Clone, to prevent anything from sneaking in between
                saveService
                    .save(ps)
                    .then(() => prefetchUserCode(ps))
                    .then(onSuccess, onError)
                    .then(resolve, reject)
            })
        })

        function onSuccess() {
            hooks.executeHook(hooks.HOOKS.WIX_CODE.FLUSH)
            traceEnd()
        }

        function onError(error) {
            handleAppIsReadOnlyError(error)

            traceEnd({message: error, level: kibana.levels.ERROR})

            throw error
        }

        function handleAppIsReadOnlyError(error) {
            const statusCode = fetchResponseErrorObject.safeGetStatusCode(error)
            const errorCode = fetchResponseErrorObject.safeGetErrorCode(error)

            if (statusCode === 400 && (errorCode === 10009 || (errorCode >= -400107 && errorCode <= -400100))) {
                // Operation not allowed to write to an immutable app
                wixCodeLifecycleService.handleAppIsReadOnlyServerError(ps)
            }
        }
    }

    function getViewerInfo(ps) {
        return {
            gridAppId: extensionsAPI.wixCode.getEditedGridAppId(ps)
        }
    }

    const invalidateSchemasAndNotifySubscribers = ps => {
        invalidateSchemasCache(ps)

        const subscribers = getSchemasInvalidationSubscribers(ps)
        subscribers.forEach(subscriber => {
            subscriber()
        })
    }

    const invalidateAuthorizationConfig = ps => {
        filesDAL.deleteFile(ps, 'backend/authorization-config.json')
    }

    function handleSchemaInvalidationActions() {
        hooks.registerHook(hooks.HOOKS.ADD_TPA.AFTER, invalidateSchemasAndNotifySubscribers)
        hooks.registerHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, invalidateSchemasAndNotifySubscribers)
        hooks.registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, invalidateSchemasAndNotifySubscribers)
    }

    function invalidateSchemasCache(ps) {
        filesDAL.deleteFolder(ps, systemFolders.SCHEMAS)
    }

    function subscribeToSchemasInvalidation(ps, subscriber) {
        const subscribers = getSchemasInvalidationSubscribers(ps)
        subscribers.push(subscriber)
        return _.once(_.partial(unsubscribeFromSchemasInvalidation, ps, subscriber))
    }

    function unsubscribeFromSchemasInvalidation(ps, subscriber) {
        const subscribers = getSchemasInvalidationSubscribers(ps)
        const indexOfSubscriber = subscribers.indexOf(subscriber)
        if (indexOfSubscriber !== -1) {
            subscribers.splice(indexOfSubscriber, 1)
        }
    }

    const schemasInvalidationSubscribersBySiteData = new coreUtils.SiteDataPrivates()

    function getSchemasInvalidationSubscribers(ps) {
        const {
            siteDataAPI: {siteData}
        } = ps
        if (!schemasInvalidationSubscribersBySiteData.has(siteData)) {
            schemasInvalidationSubscribersBySiteData.set(siteData, [])
        }
        return schemasInvalidationSubscribersBySiteData.get(siteData)
    }

    const FLUSH_ORIGINS = {
        ROUTERS_PAGE: 'ROUTERS_PAGE',
        ROUTERS_ROUTES: 'ROUTERS_ROUTES',
        ROUTERS_ROUTES_COUNT: 'ROUTERS_ROUTES_COUNT',
        MOVE_FILE: 'MOVE_FILE',
        GET_CHILDREN: 'GET_CHILDREN',
        DELETE_ITEM: 'DELETE_ITEM',
        LOCAL_MODE_INIT_EDIT: 'LOCAL_MODE_INIT_EDIT',
        COUNTINUOUS_SAVE: 'COUNTINUOUS_SAVE',
        HANDLE_CONCURRENT_CHANGE: 'HANDLE_CONCURRENT_CHANGE'
    }

    async function loadAllFilesFromGrid(ps, files = _.values(getRoots())) {
        const loadFile = async file => {
            if (file.directory) {
                const children = await getChildren(ps, file)
                return await loadAllFilesFromGrid(ps, children)
            }
            const content = await readFile(ps, file)
            return {path: file.location, content}
        }
        const vals = await Promise.all(files.map(loadFile))
        return _.flatten(vals)
    }

    function isReadOnly(ps) {
        return !disabledWixCodeSave.isWixCodeSaveAllowed(ps)
    }

    /**
     * @param ps
     * @returns {Promise<void>}
     */
    const markChangesForCSaveApproval = ps =>
        new Promise(resolve => {
            ps.setOperationsQueue.runSetOperation(() => {
                ps.dal.set(ps.pointers.wixCode.getWaitForApproval(), {timestamp: Date.now().toString()})
                resolve()
            })
        })

    const withCSaveApproval =
        (callback, shouldFlushAfterMark = false) =>
        async (ps, ...args) => {
            const result = await callback(ps, ...args)
            if (experiment.isOpen('dm_wixCodeWaitForApprovalBeforeSave')) {
                await markChangesForCSaveApproval(ps)
                if (shouldFlushAfterMark) {
                    await flush(ps)
                }
            }
            return result
        }

    const withDisabledSaveValidation =
        callback =>
        async (ps, ...args) => {
            disabledWixCodeSave.ensureWixCodeSaveAllowed(ps)
            return callback(ps, ...args)
        }

    const wrapWriteAction = (callback, shouldFlushAfterMark) => withCSaveApproval(withDisabledSaveValidation(callback), shouldFlushAfterMark)

    function subscribeToConcurrentChange(ps, newListener) {
        hooks.registerHook(hooks.HOOKS.WIX_CODE.CONCURRENT_FILES_CHANGED, newListener)
        return () => hooks.unregisterHook(hooks.HOOKS.WIX_CODE.CONCURRENT_FILES_CHANGED, newListener)
    }

    function notifyOnConcurrentChange(ps, changedPaths) {
        try {
            hooks.executeHook(hooks.HOOKS.WIX_CODE.CONCURRENT_FILES_CHANGED, null, [changedPaths])
        } catch (error) {
            kibana.trace(ps, {action: 'notifyOnConcurrentChange'})({level: kibana.levels.ERROR, message: error})
        }
    }

    async function handleExternalChange(ps, changedPaths) {
        changedPaths.forEach(changedPath => userCodeCacheKillerService.notifyPathModified(ps, changedPath))
        await flush(ps, {origin: FLUSH_ORIGINS.HANDLE_CONCURRENT_CHANGE})
        filesDAL.clearCache(ps, changedPaths)
        notifyOnConcurrentChange(ps, changedPaths)
    }

    const notifyFileChanges = async (ps, changedPaths) => {
        fileSystemService.notifyLocalPathsChanged(extensionsAPI.getAPI(ps), changedPaths)
        await handleExternalChange(ps, changedPaths)
    }

    const ensureAppIsWriteable = async ps => {
        disabledWixCodeSave.ensureWixCodeSaveAllowed(ps)
        return await wixCodeLifecycleService.ensureAppIsWriteable(ps)
    }

    return {
        /* write operations */

        //Sync (but return ensureAppIsWritable promise)
        copyFile: wrapWriteAction(copyFile),
        writeFile: wrapWriteAction(writeFile),

        //Async
        move: wrapWriteAction(moveFileOrFolder),
        deleteItem: wrapWriteAction(deleteItem, true),
        createFolder: wrapWriteAction(createFolder),
        flush,

        /* read operations */

        //Async
        loadAllFiles,
        readFile,
        getChildren,
        findByExtension,

        ensureAppIsWriteable,
        notifyFileChanges,

        loadAllFilesFromGrid,
        prefetchUserCode,
        handleExternalChange,

        //Sync
        getRoots,
        getViewerInfo,
        getVirtualDescriptor,
        handleSchemaInvalidationActions,
        subscribeToSchemasInvalidation,
        subscribeToConcurrentChange,
        isReadOnly,

        FLUSH_ORIGINS
    }
})
