import _ from 'lodash'

function isDynamicPage(routers, pageId) {
    return _.some(routers, function (routerData) {
        return _.includes(_.values(routerData.pages), pageId)
    })
}

export default {
    isDynamicPage
}
