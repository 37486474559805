import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import localizer from '@wix/santa-ds-libs/src/wixappsCore/src/util/localizer'

const componentType = 'wysiwyg.viewer.components.SiteButton'

export default {
    mixins: [baseProxy],

    statics: {
        componentType
    },

    getCustomStyle() {
        let style = {position: 'relative'}
        if (this.getAdditionalButtonStyle) {
            style = _.merge(style, this.getAdditionalButtonStyle())
        }

        return style
    },

    renderProxy() {
        const compData = typesConverter.linkableButton(this.proxyData, this.props.viewProps.linkRenderInfo.pagesDataItemsMap)
        let label = this.getCompProp('label')
        if (_.isUndefined(label)) {
            label = compData.label
        }
        compData.label = label.toString() ? localizer.localize(label, this.props.viewProps.getLocalizationBundle()) : this.getDefaultLabel()
        // @ts-ignore
        compData.noAutoLinkGeneration = true

        const props = this.getChildCompProps(componentType, this.transformSkinProperties, {
            compData,
            compProp: {
                align: this.getCompProp('align') || 'center',
                margin: this.getCompProp('margin') || 0,
                padding: this.getCompProp('labelPadding') || ''
            },
            shouldUseFlex: false,
            noAutoLinkGeneration: true
        })

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
