define(['documentServices/constants/constants'], function (constants) {
    'use strict'

    return {
        mobileConversionConfig: {
            category: 'graphic',
            isSuitableForProportionGrouping: true,
            keepIfVerticalDivider: true
        },
        rotatable: true,
        styleCanBeApplied: true,
        resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
        widthAuto: true
    }
})
