import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {pointerUtils, Getter, SetterSet} from '@wix/document-manager-core'
import {refStructureUtils, repeaterUtils} from '@wix/document-manager-extensions'
const {isRepeatedComponent} = repeaterUtils
import {addPageIdFromPointer} from './transformerUtils'

const {stripInnerPath, getInnerPath, hasInnerPath, getPointer, getRepeatedItemPointerIfNeeded} = pointerUtils
const {isRefPointer, getRefPointerType} = refStructureUtils

const childrenProperties = ['components', 'children', 'mobileComponents']

const isPageIdChanged = (pageId: string, component: any) => _.get(component, ['metaData', 'pageId']) !== pageId

const cleanAndCreateChildren = (pointer: Pointer, children: any[], set: SetterSet, get: Getter, pageId: string) => {
    // @ts-ignore
    const cleanedList = _.map(children, component => (_.isObject(component) ? component.id : component))
    const pointerToUpdate = getRepeatedItemPointerIfNeeded(pointer)

    _.forEach(children, component => {
        const isNewComponent = _.isObject(component)
        const componentToUpdate = isNewComponent ? component : get(getPointer(component, pointerToUpdate.type))

        if (!isNewComponent && !isPageIdChanged(pageId, componentToUpdate)) {
            return
        }

        const withPageAndParentId = _.merge({}, componentToUpdate, {metaData: {pageId}, parent: pointerToUpdate.id})
        const newCompPointer = getPointer(componentToUpdate.id, pointerToUpdate.type)

        set(newCompPointer, withPageAndParentId)
    })

    return cleanedList
}

const getPageId = (pointer: Pointer, get: Getter) => {
    if (pointer.pageId) {
        return pointer.pageId
    }
    const pointerToGet = getRepeatedItemPointerIfNeeded(pointer)

    const rootValue = get(stripInnerPath(pointerToGet))

    return rootValue.metaData.pageId
}

const fixChildInnerPath = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    const innerPath = getInnerPath(pointer)

    //Ignoring a set of a specific child. Assuming it will be a string
    if (innerPath.length !== 1 || !childrenProperties.includes(innerPath[0])) {
        return value
    }

    const pageId = getPageId(pointer, get)

    return cleanAndCreateChildren(pointer, value, set, get, pageId)
}

const replaceComponentsFromAnotherProp = (value: any, otherProp: string) => ({
    ..._.omit(value, [otherProp]),
    components: value[otherProp]
})

const setComponentsOnValue = (pointer: Pointer, value: any) => {
    if (hasInnerPath(pointer)) {
        return value
    }
    if (pointer.type === 'MOBILE' && value.mobileComponents) {
        return replaceComponentsFromAnotherProp(value, 'mobileComponents')
    }

    if (pointer.type === 'DESKTOP' && pointer.id === 'masterPage' && value.children) {
        return replaceComponentsFromAnotherProp(value, 'children')
    }

    return value
}

const cleanChildren = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    if (!value.components) {
        return value
    }
    const {pageId} = value.metaData
    return {
        ...value,
        components: cleanAndCreateChildren(pointer, value.components, set, get, pageId)
    }
}

const getValueWithFixedChildren = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    const valueWithCleanChildren = cleanChildren(pointer, value, get, set)
    return fixChildInnerPath(pointer, valueWithCleanChildren, get, set)
}

const fixPointerType = (pointer: Pointer) => {
    const newPointer = {...pointer}
    if (hasInnerPath(pointer) && pointer.innerPath[0] === 'mobileComponents') {
        newPointer.type = 'MOBILE'
    }

    if (isRefPointer(newPointer)) {
        return getRefPointerType(newPointer)
    }

    return newPointer
}

const fixInnerPath = (pointer: Pointer) => {
    if (hasInnerPath(pointer) && childrenProperties.includes(pointer.innerPath[0])) {
        return {
            ...pointer,
            innerPath: ['components', ...pointer.innerPath.slice(1)]
        }
    }

    return pointer
}

const fixRepeatedItemPointer = (pointer: Pointer) => {
    if (!isRepeatedComponent(pointer.id)) {
        return pointer
    }

    if (hasInnerPath(pointer) && _.includes(['dataQuery', 'designQuery', 'layout'], pointer.innerPath?.[0])) {
        return pointer
    }

    return getRepeatedItemPointerIfNeeded(pointer)
}

const isPage = (value: any) => _.includes(['mobile.core.components.Page', 'mobile.core.components.MasterPage'], _.get(value, ['componentType']))

const createMobilePageFromNewDesktopPage = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    if (pointer.type === 'DESKTOP' && isPage(value) && !pointer.innerPath) {
        const mobilePagePointer = getPointer(pointer.id, 'MOBILE')
        if (!get(mobilePagePointer)) {
            const valueCopy = {...value, components: []} //there is a deep clone on set anyway, we just want to override components
            set(mobilePagePointer, valueCopy)
        }
    }
}

const replicatePageStructureChangesAmongViewModes = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    const rootPointer = stripInnerPath(pointer)
    const structure = get(rootPointer)
    const pointerOfOtherViewMode = {...pointer, type: pointer.type === 'DESKTOP' ? 'MOBILE' : 'DESKTOP', doNotSync: true}
    if (!pointer.doNotSync && isPage(structure) && hasInnerPath(pointer) && !_.includes(['children', 'components', 'mobileComponents'], pointer.innerPath[0])) {
        set(pointerOfOtherViewMode, value)
    }
}

const transformSet = (pointer: Pointer, value: any, get: Getter, set: SetterSet) => {
    const transformedValue = _(value)
        .thru(v => addPageIdFromPointer(pointer, v))
        // @ts-ignore
        .thru(v => setComponentsOnValue(pointer, v))
        .thru((v: any) => getValueWithFixedChildren(pointer, v, get, set))
        .value()

    createMobilePageFromNewDesktopPage(pointer, transformedValue, get, set)
    replicatePageStructureChangesAmongViewModes(pointer, transformedValue, get, set)
    const transformedPointer = _(pointer)
        .thru(fixPointerType)
        // @ts-ignore
        .thru(fixInnerPath)
        .thru(fixRepeatedItemPointer)
        .value()

    return {
        value: transformedValue,
        pointer: transformedPointer
    }
}

const transformRemove = (pointer: Pointer, removeFromFull: boolean) => {
    if (!removeFromFull && isRepeatedComponent(pointer.id)) {
        return null
    }

    return isRefPointer(pointer) ? getRefPointerType(pointer) : getRepeatedItemPointerIfNeeded(pointer)
}

export {transformRemove, transformSet}
