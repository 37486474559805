import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import googleAnalytics from './services/googleAnalytics'
import facebookRemarketing from './services/facebookRemarketing'
import googleRemarketing from './services/googleRemarketing'
import yandexMetrika from './services/yandexMetrika'
import googleTagManager from './services/googleTagManager'
import wixAnalytics from './services/wixAnalytics'
import biAnalytics from './services/biAnalytics'
import warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const IS_PREVIEW = typeof window !== 'undefined' && window.queryUtil && window.queryUtil.isParameterTrue('isEdited')

const reporterDecorators = {
    wixAnalytics: biWithSessionTracker,
    biAnalytics: biWithSessionTracker
}

function biWithSessionTracker(siteData, reportFn) {
    return params => {
        if (!siteData.isViewerMode() || shouldSuppressBI(siteData)) {
            return
        }

        if (_.get(siteData, ['wixBiSession', 'renderType']) === 'santa') {
            const session = warmupUtils.loggingUtils.browsingSession.track()
            params = _.merge(params, {bsi: session.id})
        }

        return reportFn(wixAnalytics.enrich(siteData, params))
    }
}

function shouldSuppressBI(siteData) {
    return coreUtilsLib.stringUtils.isTrue(siteData.currentUrl.query.suppressbi)
}

function extractAccountParams(siteData, accountType) {
    const isPremium = siteData.isPremiumDomain()

    switch (accountType) {
        case 'googleAnalytics': {
            if (hasAccount(siteData, accountType)) {
                return [siteData.googleAnalytics]
            }
            break
        }
        case 'googleTagManager': {
            if (hasAccount(siteData, accountType)) {
                return [siteData.googleTagManager]
            }
            break
        }
        case 'facebookRemarketing': {
            if (hasAccount(siteData, accountType) && isPremium) {
                return [siteData.facebookRemarketing]
            }
            break
        }
        case 'googleRemarketing': {
            if (hasAccount(siteData, accountType) && isPremium) {
                return [siteData.googleRemarketing]
            }
            break
        }
        case 'yandexMetrika': {
            if (hasAccount(siteData, accountType) && isPremium) {
                return [siteData.yandexMetrika]
            }
            break
        }
    }
    return []
}

function hasAccount(siteData, accountType) {
    return !_.isEmpty(siteData[accountType])
}

function initFacebookRemarketingUserPixel(siteData) {
    if (!IS_PREVIEW && !shouldSuppressBI(siteData)) {
        const pixelId = extractAccountParams(siteData, 'facebookRemarketing')[0]
        facebookRemarketing.initRemarketingPixel(siteData, pixelId)
    }
}

function initFacebookRemarketingPixelId(siteData, pixelId) {
    if (!IS_PREVIEW && !shouldSuppressBI(siteData)) {
        facebookRemarketing.initPixelId(siteData, pixelId)
    }
}

function fireFacebookRemarketingPixel(siteData, eventName, eventData) {
    facebookRemarketing.fireRemarketingPixel(siteData, eventName, eventData)
}

function initGoogleRemarketingPixel(siteData) {
    if (!IS_PREVIEW && !shouldSuppressBI(siteData)) {
        googleRemarketing.initRemarketingPixel(extractAccountParams(siteData, 'googleRemarketing'))
    }
}

function fireGoogleRemarketingPixel() {
    googleRemarketing.fireRemarketingPixel()
}

function initYandexMetrika(siteData) {
    if (!IS_PREVIEW && !shouldSuppressBI(siteData)) {
        yandexMetrika.initialize(extractAccountParams(siteData, 'yandexMetrika'))
    }
}

function reportYandexPageHit(url) {
    yandexMetrika.reportPageHit(url)
}

/**
 *
 * @param {SiteData} siteData
 * @param {string} pageUrl
 */
function reportPageEvent(siteData, pageUrl) {
    if (siteData && _.isString(pageUrl) && !IS_PREVIEW && !shouldSuppressBI(siteData)) {
        googleAnalytics.reportPageEvent(siteData, pageUrl, extractAccountParams(siteData, 'googleAnalytics'))

        const googleTagManagerIds = extractAccountParams(siteData, 'googleTagManager')
        if (!_.isEmpty(googleTagManagerIds)) {
            googleTagManager.reportPageEvent(pageUrl, googleTagManagerIds[0])
        }
    }
}

//noinspection JSUnusedLocalSymbols
function reportGoogleAnalytics(siteData, ...rest) {
    if (!IS_PREVIEW) {
        const accountIds = extractAccountParams(siteData, 'googleAnalytics')
        // @ts-ignore
        googleAnalytics.report(accountIds, siteData.ipAnonymization, ...rest)
    }
}

function fireFacebookCustomEvent(siteData, eventType, eventName, data) {
    facebookRemarketing.fireCustomEvent(siteData, eventType, eventName, data)
}

function reportGoogleTagManager(siteData, eventParams) {
    if (!IS_PREVIEW) {
        const accountIds = extractAccountParams(siteData, 'googleTagManager')
        if (!_.isEmpty(accountIds)) {
            googleTagManager.report(accountIds[0], eventParams)
        }
    }
}

function reportBiAnalytics(siteData, params) {
    biAnalytics.report(siteData, params)
}

function decorateReporter(siteData, name, reportFn) {
    return reporterDecorators[name] ? reporterDecorators[name](siteData, reportFn) : reportFn
}

export default {
    reportGoogleAnalytics,
    reportBiAnalytics,
    reportPageEvent,
    initFacebookRemarketingUserPixel,
    initFacebookRemarketingPixelId,
    fireFacebookRemarketingPixel,
    fireFacebookCustomEvent,
    initGoogleRemarketingPixel,
    fireGoogleRemarketingPixel,
    initYandexMetrika,
    reportYandexPageHit,
    reportGoogleTagManager,
    hasAccount,
    decorateReporter
}
