import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const bi = viewerLibrary.get('documentServices/bi/bi')
    return {
        methods: {
            bi: {
                event: publicMethodUtils.defineAction(bi.event, {isUpdatingData: false}),
                error: publicMethodUtils.defineAction(bi.error, {isUpdatingData: false}),
                /**
                 *
                 * @param {*} reportDefCollection map of errors by the errorName
                 * @param {string} packageName to assign the errors
                 * @param {boolean} shouldMutate - should mutate the error.reportDefCollection object?
                 * @returns {*} mutated/clone of reportDefCollection with the added properties packageName and errorName
                 */
                formatErrorEvents: publicMethodUtils.defineAction(bi.formatErrorEvents)
            }
        }
    }
}
