import _ from 'lodash'
import {languages, ajaxLibrary, loggingUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import {urlUtils} from '@wix/santa-core-utils'
import biErrors from '../bi/errors'

const SANTA_LANGS_FALLBACK_VERSION = '1.2543.0'

export class TranslationsLoader {
    private translations: any
    private oldTranslations: any
    private failedLanguageCodes: any
    private useOldTranslations: any
    constructor({translations, oldTranslations, failedLanguageCodes}: any) {
        this.translations = translations
        this.oldTranslations = oldTranslations
        this.failedLanguageCodes = failedLanguageCodes || []
    }

    getRequest(siteData, langs, context) {
        const languagesToLoad = this.filterRequiredLangs(langs)
        if (_.isEmpty(languagesToLoad)) {
            return null
        }

        const requestDescriptor = {
            customDownload: () => {
                // this tragic done method is assigned to the requestDescriptor by santa-core-utils/coreUtils/core/store2_new.js
                // @ts-ignore
                const {done} = requestDescriptor
                return this.load(siteData, langs, context).then(done).catch(done)
            }
        }

        return requestDescriptor
    }

    /**
     * @param {string} santa-langs base url (something like http://static.parastorage.com/services/santa-langs/x.x.x)
     * @param {Array.<string>|string} langs codes
     * @param context
     * @param siteData
     * @returns {Promise}
     */
    load(siteData, langs, context) {
        const langsBase = siteData.serviceTopology.scriptsLocationMap['santa-langs']
        const languagesToLoad = this.filterRequiredLangs(langs)
        return Promise.all(
            _.map(languagesToLoad, l =>
                loadSingle(this, siteData, langsBase, l, context).catch(() => {
                    const fallbackBaseUrl = getFallbackBaseUrl(langsBase)
                    const fallbackRequestContext = _.assign({}, context, {isFallback: true})
                    return loadSingle(this, siteData, fallbackBaseUrl, l, fallbackRequestContext)
                })
            )
        )
    }

    isLoaded(lang, options?) {
        return _.has(this.resolveTranslations(options), lang)
    }

    resolveTranslations(options) {
        const useOldTranslations = this.useOldTranslations || _.has(options, ['useOldTranslations']) || _.isEmpty(this.translations)
        return useOldTranslations ? this.oldTranslations : this.translations
    }

    getTranslation(feature, lang, key, defaultValue?, options?) {
        const allKeys = this.getTranslationAllKeys(feature, lang, {}, options)
        return _.get(allKeys, key, defaultValue)
    }

    getTranslationAllKeys(feature, lang, defaultValue, options) {
        if (!_.includes(languages, lang)) {
            lang = 'en'
        }

        if (!this.isLoaded(lang, options)) {
            lang = 'en'
        }

        return _.get(this.resolveTranslations(options), [lang, feature], defaultValue)
    }

    overrideTranslations({translations, oldTranslations}) {
        this.translations = translations
        this.oldTranslations = oldTranslations
    }

    setToUseOldTranslations() {
        // @ts-ignore
        this.useOldTranslations = true
    }

    filterRequiredLangs(langs) {
        return (
            _(langs)
                .concat()
                .compact()
                .invokeMap('toLowerCase')
                .uniq()
                .map(fixLanguageCode)
                .filter(l => _.includes(languages, l))
                // @ts-ignore
                .reject(l => _.includes(this.failedLanguageCodes, l))
                .reject(l => this.isLoaded(l))
                .value()
        )
    }
}

function getFallbackBaseUrl(langsBaseUrl) {
    const execRes = /^(.*\/)[\d.]*$/.exec(langsBaseUrl)
    return `${execRes[1]}${SANTA_LANGS_FALLBACK_VERSION}`
}

function fixLanguageCode(lang) {
    switch (lang) {
        case 'jp':
            return 'ja'
        case 'kr':
            return 'ko'
        default:
            return lang
    }
}

function loadSingle(loader, siteData, langsBase, lang, context) {
    // There is a bug in IE11 that fail to redirect (status 307) here for cross origin ajax requests WEED-10793
    // Converting http to https will prevent this redirect temporarily until all Parastorage urls in
    // serviceTopology.scriptsLocationMap will be https HTMLSRVR-2321
    const url = urlUtils.joinURL(langsBase, `resources/santa-viewer/bundles/_generated/santa_viewer_${lang}.json`).replace(/https?/, 'https')

    return new Promise((resolve, reject) =>
        ajaxLibrary.ajax({
            type: 'GET',
            url,
            dataType: 'json',
            success: resolve,
            error: (xhr, errorType, error) => {
                if (errorType !== 'abort') {
                    const response = {
                        status: xhr.status,
                        errorType,
                        response: xhr.response.substring(0, 128),
                        responseSize: xhr.response.length,
                        error
                    }

                    loggingUtils.logger.reportBI(siteData, biErrors.FAILED_TO_FETCH_TRANSLATIONS, {
                        language: lang,
                        url,
                        response: JSON.stringify(response),
                        context: JSON.stringify(context)
                    })
                }
                loader.failedLanguageCodes.push(lang)
                reject()
            }
        })
    ).then(data => _.assign(loader.translations, {[lang]: data}))
}

const translationsLoaderInstance = new TranslationsLoader({translations: {}, oldTranslations: {}})
export default _.assign(translationsLoaderInstance, {
    // For testing purposes only
    TranslationsLoaderFactory: TranslationsLoader
})
