define(['lodash'], function (_) {
    'use strict'

    /******************************* Public Functions  *************************************/

    function getAllRouters(ps) {
        const routersConfigMapPointer = ps.pointers.routers.getRoutersConfigMapPointer()
        return ps.dal.get(routersConfigMapPointer)
    }

    function getRoutersForApp(ps, appDefinitionId) {
        return _.filter(getAllRouters(ps), {appDefinitionId})
    }

    function getRouterByRef(ps, routerPtr) {
        const routerData = ps.dal.get(routerPtr)
        if (routerData && routerData.config) {
            routerData.config = JSON.parse(routerData.config)
            routerData.id = getRouterIdByPrefix(ps, routerData.prefix)
        }

        return routerData || null
    }

    function getRouterByPage(ps, pagePtr) {
        const pageId = _.get(ps.dal.get(pagePtr), 'id')
        const allRouters = getAllRouters(ps)
        const routerId = _.findKey(allRouters, function (router) {
            return router.pages && _.includes(_.values(router.pages), pageId)
        })
        return routerId && ps.pointers.routers.getRouterPointer(routerId)
    }

    function getRouterIdByPrefix(ps, routerPrefix) {
        const allRouters = getAllRouters(ps)
        return _.findKey(allRouters, {prefix: routerPrefix})
    }

    function getRouterByPrefix(ps, routerPrefix) {
        const routerId = getRouterIdByPrefix(ps, routerPrefix)
        return routerId && ps.pointers.routers.getRouterPointer(routerId)
    }

    function getRouterById(ps, routerId) {
        const routerPointer = ps.pointers.routers.getRouterPointer(routerId)
        return ps.dal.get(routerPointer)
    }

    function getRouterDataForPageIfExist(ps, pageId) {
        pageId = _.startsWith(pageId, '#') ? pageId.substr(1) : pageId
        const allRouters = getAllRouters(ps)
        const routerId = _.findKey(allRouters, function (router) {
            return router.pages && _.includes(_.values(router.pages), pageId)
        })

        return routerId ? _.assign(getRouterById(ps, routerId), {routerId}) : null
    }

    return {
        get: {
            all: getAllRouters,
            byApp: getRoutersForApp,
            byRef: getRouterByRef,
            byId: getRouterById,
            byPrefix: getRouterByPrefix,
            byPage: getRouterByPage
        },
        getRouterDataForPageIfExist
    }
})
