define(['lodash', 'documentServices/page/page'], function (_, pages) {
    'use strict'

    function markAsPreset(ps, pageDataItems, pageId) {
        _.forEach(pageDataItems, function (dataItem) {
            dataItem.metaData.isPreset = true
            const dataItemPointer = ps.pointers.data.getDataItem(dataItem.id, pageId)
            ps.dal.set(dataItemPointer, dataItem)
        })
    }

    return function markDataItemsAsPreset(ps) {
        const pageIdToDataItemsMap = _(pages.getPageIdList(ps, true))
            .transform(function (acc, pageId) {
                acc[pageId] = ps.pointers.page.getPageData(pageId)
            }, {})
            .mapValues(ps.dal.get.bind(ps.dal))
            .mapValues(function (dataItems) {
                return _.pickBy(dataItems, 'metaData')
            })
            .value()

        _.forEach(pageIdToDataItemsMap, markAsPreset.bind(null, ps))
    }
})
