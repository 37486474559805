define(['document-services-schemas', 'experiment'], function (schemas, experiment) {
    'use strict'

    function addDefaultSlotsQuery(ps, componentRef, containerRef, serializedComp) {
        if (experiment.isOpen('dm_addSlotsQueryViaHook') && !serializedComp.slots) {
            const {schemasService} = schemas.services
            const componentDefinition = schemasService.getDefinition(serializedComp.componentType)

            if (componentDefinition?.slotsDataType) {
                serializedComp.slots = {slots: {}, type: componentDefinition.slotsDataType}
            }
        }
    }

    return {
        addDefaultSlotsQuery
    }
})
