/**
 * Created by eitanr on 6/25/14.
 */
import basic from './svgBasicScaler'

export default {
    scale(ellipseElement, scaleX, scaleY) {
        const cxAttribute = ellipseElement.getAttribute('cx')
        const cyAttribute = ellipseElement.getAttribute('cy')
        const rxAttribute = ellipseElement.getAttribute('rx')
        const ryAttribute = ellipseElement.getAttribute('ry')
        const ellipseScaled: any = {}
        if (cxAttribute && rxAttribute) {
            ellipseScaled.cx = basic.scaleSingleValue(cxAttribute, scaleX)
            ellipseScaled.rx = basic.scaleSingleValue(rxAttribute, scaleX)
        }

        if (cyAttribute && ryAttribute) {
            ellipseScaled.cy = basic.scaleSingleValue(cyAttribute, scaleY)
            ellipseScaled.ry = basic.scaleSingleValue(ryAttribute, scaleY)
        }

        return ellipseScaled
    }
}
