define(['lodash', '@wix/document-manager-utils', 'experiment'], function (_, {ReportableError}, experiment) {
    const PARTNERS_SERVER_BASE_URL = '/_api/experts-server/v1/experts/'
    const PARTNERS_SERVER_NEW_URL = '/_api/partners-accounts-server/v1/partners/account'
    const PARTNERS_POSSIBLE_TYPES = {
        EXPERT: 'site_builder',
        POTENTIAL_EXPERT: 'potential_expert',
        PRO: 'pro'
    }

    const waitForAjaxToBeResolved = async (contextAdapter, url) =>
        new Promise((resolve, reject) => {
            const onPartnersDataResolved = partnersJsonData => {
                resolve(partnersJsonData)
            }
            const onPartnersDataRejected = (failedReq, textStatus, errorMessage) => {
                reject(errorMessage)
            }
            contextAdapter.actions.sendHttpRequest(url, 'GET', null, null, onPartnersDataResolved, onPartnersDataRejected)
        })

    const shouldFetchFromNewUrl = () => experiment.isOpen('dm_newPartnersApi')

    const isPartnerFromOldApi = async contextAdapter => {
        const partnersJsonData = await waitForAjaxToBeResolved(contextAdapter, PARTNERS_SERVER_BASE_URL)
        const partnerTypeFromServer = _.get(partnersJsonData, ['expertTypes', 'types'], [])
            .map(type => type)
            .join()
        return partnerTypeFromServer === PARTNERS_POSSIBLE_TYPES.POTENTIAL_EXPERT
    }

    const isPartnerFromNewApi = async contextAdapter => {
        const response = await waitForAjaxToBeResolved(contextAdapter, PARTNERS_SERVER_NEW_URL)
        return response.partnerAccount.isOfficialPartner
    }

    const isPartner = async contextAdapter => {
        if (shouldFetchFromNewUrl()) {
            return await isPartnerFromNewApi(contextAdapter)
        }
        return await isPartnerFromOldApi(contextAdapter)
    }

    /**
     *
     * @param {ps} ps
     * @param contextAdapter
     */
    const fetchAndWritePartnersData = async (ps, contextAdapter) => {
        const {dal, pointers} = ps
        try {
            const isPotentialPartnerVal = await isPartner(contextAdapter)
            dal.set(pointers.partners.getIsPotentialPartnerPointer(), isPotentialPartnerVal)
            return isPotentialPartnerVal
        } catch (/** @type any */ e) {
            dal.set(pointers.partners.getIsPotentialPartnerPointer(), false)
            contextAdapter.utils.fedopsLogger.captureError(new ReportableError({errorType: 'partnersDataError', message: 'failed to fetch partners data'}), {
                tags: {fetchAndWritePartnersData: true},
                extras: {message: e?.message, newUrl: shouldFetchFromNewUrl()}
            })
            return false
        }
    }

    return {
        fetchAndWritePartnersData
    }
})
