define([
    'lodash',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/platform/platform',
    'documentServices/appControllerData/appControllerData'
], function (_, component, dataModel, platform, appControllerData) {
    'use strict'

    function removePlatformApplicationFromPageIfLastController(ps, controllerRef) {
        const controllerPageRef = component.getPage(ps, controllerRef)
        const controllerApplicationId = dataModel.getDataItem(ps, controllerRef).applicationId
        const appControllersInPage = ps.pointers.data.getDataItemsWithPredicate({applicationId: controllerApplicationId}, controllerPageRef.id)
        platform.updatePagePlatformApp(ps, controllerPageRef, controllerApplicationId, appControllersInPage.length > 1)
    }

    function removeControllerFromStateMap(ps, controllerRef) {
        const controllerStatePointer = ps.pointers.platform.getControllerStatePointer(controllerRef.id)
        if (ps.dal.isExist(controllerStatePointer)) {
            ps.dal.remove(controllerStatePointer)
        }
    }

    function addControllerStateToSerializedStructure(ps, controllerRef, customStructureData) {
        const controllerState = ps.dal.get(ps.pointers.platform.getControllerStatePointer(controllerRef.id))
        if (typeof controllerState !== 'undefined') {
            customStructureData.state = controllerState
        }
    }

    function setControllerStateFromSerializedStructure(ps, controllerRef, controllerDefinition) {
        if (_.has(controllerDefinition, 'custom.state')) {
            appControllerData.setState(ps, _.set({}, _.get(controllerDefinition, 'custom.state'), [controllerRef]))
        }
    }

    function addPlatformAppToPageIfFirstController(ps, controllerRef, applicationId, controllerPageRef) {
        controllerPageRef = controllerPageRef || component.getPage(ps, controllerRef)
        platform.updatePagePlatformApp(ps, controllerPageRef, applicationId, true)
    }

    return {
        removePlatformApplicationFromPageIfLastController,
        removeControllerFromStateMap,
        addControllerStateToSerializedStructure,
        setControllerStateFromSerializedStructure,
        addPlatformAppToPageIfFirstController
    }
})
