import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const feedback = viewerLibrary.get('documentServices/feedback/feedback')
    return {
        methods: {
            feedback: {
                comments: {
                    create: publicMethodUtils.defineAction(feedback.comments.create),
                    add: publicMethodUtils.defineAction(feedback.comments.add),
                    get: publicMethodUtils.defineGetter(feedback.comments.get),
                    remove: publicMethodUtils.defineAction(feedback.comments.remove),
                    markAsRead: publicMethodUtils.defineAction(feedback.comments.markAsRead)
                },
                isSiteShared: publicMethodUtils.defineGetter(feedback.isSiteShared),
                markSiteAsShared: publicMethodUtils.defineAction(feedback.markSiteAsShared)
            }
        }
    }
}
