define([
    'lodash',
    'experiment',
    'documentServices/hooks/hooks',
    'documentServices/wixCode/utils/pathUtils',
    'documentServices/wixCode/services/wixCodeLifecycleService',
    'documentServices/wixCode/services/userCodeCacheKillerService',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/services/fileSystemAPI',
    'documentServices/wixCode/services/codePackageAPI',
    'documentServices/wixCode/services/pagesAPI',
    'documentServices/wixCode/services/pageCodeDuplicator',
    'documentServices/wixCode/services/codeIntelligenceService',
    'documentServices/platform/platform',
    'documentServices/wixCode/utils/constants',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/bi/bi',
    'documentServices/bi/events.json',
    '@wix/santa-ds-libs/src/wixCode',
    '@wix/santa-core-utils',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/saveAPI/saveAPI',
    'documentServices/component/componentCode',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/wixCode/services/disabledWixCodeSave',
    'documentServices/wixCode/utils/errors',
    '@wix/document-manager-utils'
], function (
    _,
    experiment,
    hooks,
    pathUtils,
    wixCodeLifecycleService,
    userCodeCacheKillerService,
    kibana,
    fileSystemAPI,
    codePackageAPI,
    pagesAPI,
    pageCodeDuplicator,
    codeIntelligenceService,
    platform,
    wixCodeConstants,
    clientSpecMap,
    bi,
    biEvents,
    wixCode,
    santaCoreUtils,
    clientSpecMapUtils,
    generalInfo,
    saveAPI,
    componentCode,
    extensionsAPI,
    disabledWixCodeSave,
    wixCodeErrors,
    documentManagerUtils
) {
    'use strict'
    const {ReportableError} = documentManagerUtils

    function getClientSpec(ps) {
        return _.head(clientSpecMap.filterAppsDataByType(ps, wixCodeConstants.WIX_CODE_SPEC_TYPE))
    }

    const {joinURL} = santaCoreUtils.urlUtils

    function reportEditorLoadedWithApps(ps) {
        const appNames = _(clientSpecMap.getAppsDataWithPredicate(ps, csm => _.filter(csm, 'platformApp')))
            .map('displayName')
            .value()

        if (!_.isEmpty(appNames)) {
            bi.event(ps, biEvents.EDITOR_PLATFORM_APPS_LOADED, {app_list: appNames, number_of_apps: appNames.length})
        }
    }

    const debouncedFlush = _.debounce(fileSystemAPI.flush, 1000)
    const onFileOrFolderChanged = ps => {
        if (!ps.runtimeConfig.disableWixCodeContinuousSave) {
            debouncedFlush(ps, {origin: fileSystemAPI.FLUSH_ORIGINS.COUNTINUOUS_SAVE})
        }
    }

    const reportIfMissingOpenGridApp = ps => {
        const isFirstSave = generalInfo.isFirstSave(ps)
        const isProvisioned = !!clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        if (!isFirstSave && isProvisioned) {
            const lastSavedGridAppId = ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())
            const openGridAppId = ps.dal.get(ps.pointers.wixCode.getOpenWixCodeAppId())
            if (_.isEmpty(openGridAppId) && !_.isEmpty(lastSavedGridAppId)) {
                extensionsAPI.logger.getLogger(ps).captureError(new wixCodeErrors.WixCodeMissingOpenGridAppError({openGridAppId, lastSavedGridAppId}), {
                    extras: {
                        openGridAppId,
                        lastSavedGridAppId,
                        isOpenGridAppExperimentOpen: experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled'),
                        isFirstSave,
                        isTemplate: generalInfo.isTemplate(ps),
                        isDraft: generalInfo.isDraft(ps)
                    },
                    level: 'error'
                })
            }
        }
    }

    function initializeWixCode(ps) {
        pathUtils.initPaths(ps)

        userCodeCacheKillerService.init(ps)

        if (wixCodeLifecycleService.isProvisioned(ps)) {
            performAfterProvisionActions(ps)
            fileSystemAPI.prefetchUserCode(ps)
        }

        const isReadOnlyPointer = ps.pointers.wixCode.getIsAppReadOnly()
        // if grid-app was "autosaved" then we assume the app is WRITEABLE - readOnly: false
        // if not then we assume that it is NOT WRITEABLE (i.e the user intended to lock it) - readOnly: true
        ps.dal.set(isReadOnlyPointer, !wasGridAppAutosaved())

        extensionsAPI.wixCode.syncGridAppToViewer(ps)

        if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
            reportIfMissingOpenGridApp(ps)
        }

        reportEditorLoadedWithApps(ps)
        extensionsAPI.getAPI(ps).wixCodeSharedFileState.subscribeToPathChanges(changedPaths => fileSystemAPI.handleExternalChange(ps, changedPaths))

        hooks.registerHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, onFileOrFolderChanged)
        extensionsAPI.wixCode.updateWixCodeLocalProvisionState(ps, wixCodeLifecycleService.isProvisioned(ps))
        extensionsAPI.wixCode.updateWixCodeModelLocalState(ps, !!extensionsAPI.wixCode.getEditedGridAppId(ps))
        extensionsAPI.wixCode.updateSiteExtensionState(ps, !!clientSpecMapUtils.getExistingWixCodeAppFromPS(ps))
        extensionsAPI.wixCode.subscribeToWixCodeProvision(ps, () => {
            performAfterProvisionActions(ps)
            reloadAppsContainer(ps)
        })

        function wasGridAppAutosaved() {
            try {
                if (ps.siteAPI.wasCodeAppIdValueChangedSinceLastSnapshot) {
                    return ps.siteAPI.wasCodeAppIdValueChangedSinceLastSnapshot('before-autosave-apply', ps.pointers.wixCode.getRevisionGridAppId())
                }
                if (ps.dal.wasPointerValueChangedSinceLastSnapshot) {
                    return ps.dal.wasPointerValueChangedSinceLastSnapshot('before-autosave-apply', ps.pointers.wixCode.getRevisionGridAppId())
                }
                return ps.dal.wasValueChangedSinceLastSnapshot('before-autosave-apply', ps.pointers.wixCode.getRevisionGridAppId())
            } catch (e) {
                // throws if no autosave
                return false
            }
        }
    }

    function performAfterProvisionActions(ps) {
        fileSystemAPI.handleSchemaInvalidationActions()
        // TODO: replace once platform apps provision flow has been decided
        _tempInitDataBindingApp(ps)
        _tempInitWixCodeEditorApp(ps)
    }

    function reloadAppsContainer(ps) {
        ps.siteAPI.reloadAppsContainer()
    }

    /**
     * @param {ps} ps
     * @returns {Promise<void>}
     */
    const saveSite = async ps => {
        const isFirstSave = generalInfo.isFirstSave(ps)
        const isQA = ps.siteAPI.isQaMode()

        if (isFirstSave || isQA) {
            return
        }
        await saveAPI.promises.save(ps, false)
    }

    function initializeWixCodeWidget(ps) {
        performAfterProvisionActions(ps)
        reloadAppsContainer(ps)
    }

    async function provisionAsync(ps) {
        if (!wixCodeLifecycleService.isProvisioned(ps)) {
            disabledWixCodeSave.ensureWixCodeSaveAllowed(ps)
        }
        const traceEnd = kibana.trace(ps, {action: 'provision'})
        try {
            if (generalInfo.isFirstSave(ps)) {
                throw new Error('Cannot provision wix-code on a template')
            }
            const provisionResult = await wixCodeLifecycleService.provision(ps)
            componentCode.generateNicknamesForSite(ps)
            initializeWixCodeWidget(ps)
            extensionsAPI.wixCode.updateWixCodeLocalProvisionState(ps, true)
            ps.setOperationsQueue.asyncPreDataManipulationComplete()
            await saveSite(ps)
            traceEnd({message: provisionResult})
            return provisionResult
        } catch (/** @type {*}*/ error) {
            extensionsAPI.wixCode.updateWixCodeLocalProvisionState(ps, false)
            ps.setOperationsQueue.asyncPreDataManipulationComplete(
                null,
                new ReportableError({
                    errorType: 'WixCodeProvisionFailed',
                    message: error.message
                })
            )
            traceEnd({level: kibana.levels.ERROR, message: error})
            throw error
        }
    }

    function provision(ps, callbacks) {
        callbacks = _.defaults({}, callbacks, {onSuccess: _.noop, onError: _.noop})
        provisionAsync(ps).then(callbacks.onSuccess, callbacks.onError) // eslint-disable-line promise/prefer-await-to-then
    }

    function getFocusedRootRef(ps, compPointers) {
        const currentRootId = ps.siteAPI.getCurrentUrlPageId()
        const viewModes = santaCoreUtils.constants.VIEW_MODES
        const viewMode = ps.siteAPI.isMobileView() ? viewModes.MOBILE : viewModes.DESKTOP
        return compPointers.getPage(currentRootId, viewMode)
    }

    function getWidgetRef(ps, compRef) {
        const compPointers = ps.pointers.components
        const compRootRef = compPointers.getPageOfComponent(compRef)
        if (!compRootRef) {
            return null
        }
        if (compPointers.isMasterPage(compRootRef)) {
            return getFocusedRootRef(ps, compPointers)
        }
        return compRootRef
    }

    function _tempInitDataBindingApp(ps) {
        // TODO: replace once platform apps provision flow has been decided
        platform.initApp(ps, _tempGetAppAppDef(ps, 'dbsm-viewer-app', 'dbsm-editor-app', 'dataBinding', true))
    }

    function _tempInitWixCodeEditorApp(ps) {
        // TODO: replace once platform apps provision flow has been decided
        platform.initApp(ps, _tempGetAppAppDef(ps, '', 'wix-code-editor-app', 'wix-code', false))
    }

    function _tempGetAppAppDef(ps, viewerAppName, editorAppName, appDefId, verbose) {
        // TODO: replace once platform apps provision flow has been decided
        function parseAppSources(appSources) {
            return _(appSources || '')
                .split(',')
                .invokeMap('split', ':')
                .fromPairs()
                .value()
        }

        function getArtifactUrl(serviceTopology, artifactName, version) {
            const artifactPath = joinURL(serviceTopology.scriptsDomainUrl, 'services', artifactName)
            if (version) {
                return joinURL(artifactPath, version)
            }

            return serviceTopology.scriptsLocationMap[artifactName]
        }

        if (wixCodeLifecycleService.isProvisioned(ps)) {
            const pointer = ps.pointers.general.getServiceTopology()
            const serviceTopology = ps.dal.get(pointer)
            const currentUrl = ps.siteAPI.getCurrentUrl()
            const viewerAppVersion = parseAppSources(_.get(currentUrl, ['query', 'viewerPlatformAppSources']))[appDefId]
            const editorAppVersion = parseAppSources(_.get(currentUrl, ['query', 'editorPlatformAppSources']))[appDefId]
            const appData = {
                appDefinitionId: appDefId
            }
            if (viewerAppName) {
                _.set(
                    appData,
                    'appFields.platform.viewerScriptUrl',
                    joinURL(getArtifactUrl(serviceTopology, viewerAppName, viewerAppVersion), `/app${verbose ? '.verbose' : ''}.js`)
                )
            }
            if (editorAppName) {
                _.set(
                    appData,
                    'appFields.platform.editorScriptUrl',
                    joinURL(getArtifactUrl(serviceTopology, editorAppName, editorAppVersion), '/editorAppModule.js')
                )
                appData.editorArtifact = editorAppName
            }
            return appData
        }
    }

    function duplicatePageCode(ps, newPageId, originalPageId) {
        if (wixCodeLifecycleService.isProvisioned(ps)) {
            pageCodeDuplicator.duplicatePageCode(ps, newPageId, originalPageId)
        }
    }

    hooks.registerHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, reloadAppsContainer)
    hooks.registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, reloadAppsContainer)

    function isUserCodeUrl(ps, url) {
        const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        return wixCode.wixCodeUserScriptsService.isUserCodeUrl(url, wixCodeSpec, ps)
    }

    function getSourceMapUrl(ps, userCodeUrl) {
        const gridAppId = extensionsAPI.wixCode.getEditedGridAppId(ps)
        const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        return wixCode.wixCodeUserScriptsService.getSourceMapUrl(userCodeUrl, gridAppId, wixCodeSpec, ps)
    }

    const setIsolatedGridApp = (ps, gridAppId) => {
        ps.dal.set(ps.pointers.wixCode.getIsolatedGridApp(), gridAppId)
    }

    const getIsolatedGridApp = ps => ps.dal.get(ps.pointers.wixCode.getIsolatedGridApp())
    const getEditedGridApp = ps => extensionsAPI.wixCode.getEditedGridAppId(ps)

    return {
        initializeWixCode,
        provision,
        isProvisioned: wixCodeLifecycleService.isProvisioned,
        getClientSpec,
        getWidgetRef,
        duplicatePageCode,
        setIsolatedGridApp,
        getIsolatedGridApp,
        getEditedGridApp,
        getCompSdkType: codeIntelligenceService.getCompSdkType,
        log: {
            levels: kibana.levels,
            trace: kibana.trace
        },
        codeIntelligence: codeIntelligenceService,
        fileSystem: fileSystemAPI,
        codePackages: codePackageAPI,
        pages: pagesAPI,
        userScripts: {
            isUserCodeUrl,
            getSourceMapUrl
        }
    }
})
