import _ from 'lodash'

function getThankYouPageUrl(siteData, partDefinition) {
    const thankYouPageData = getThankYouPageData(siteData, partDefinition)
    const baseUrl = siteData.getExternalBaseUrl()
    if (thankYouPageData) {
        return `${baseUrl}#!${thankYouPageData.pageUriSEO}/${thankYouPageData.id}`
    }
    return null
}

function getThankYouPageData(siteData, partDefinition) {
    const pagesData = siteData.getPagesDataItems()
    const thankYouPageId = partDefinition.childPage

    if (thankYouPageId) {
        return _.find(pagesData, {appPageId: thankYouPageId})
    }
    return null
}

/**
 * @class ecommerce.util.thankYouPageHandler
 */
export default {
    getThankYouPageUrl
}
