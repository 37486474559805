import _ from 'lodash'
import type {DocumentManager, Pointer} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import {runOnPageComponents} from '../utils/migrationUtil'
import * as removeStyleIdForComps from './removeStyleIdUnstylableComps'
import * as sectionMigrations from './sectionMigrations'

const componentMigrators = {
    ...removeStyleIdForComps.components,
    ...sectionMigrations.components
}

const migrateComponent = (documentManager: DocumentManager, pageId: string, pointer: Pointer, viewMode: string) => {
    const compStructure = documentManager.dal.get(pointer)
    const {componentType} = compStructure
    componentMigrators[componentType]?.(documentManager, pointer, compStructure, viewMode)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    _.forEach(constants.VIEW_MODES, viewMode => {
        runOnPageComponents(documentManager, pageId, viewMode, migrateComponent)
    })
}

const name = 'runComponentSpecificMigrations'
const version = 1

export {migratePage, name, version}
