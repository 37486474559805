import pMap from 'p-map'
import type {FetchFn} from '@wix/document-manager-extensions/src/utils/fetchUtils'
import type {PageList} from '@wix/document-services-types'
export type PageLoadedCallback = (pageJson: any, pageId: string) => void

// todo: PageList is not actually a page list.. its a piece of metadata containing info of how to fetch a site pages
async function fetchPages(fetchFn: FetchFn, pageList: PageList, concurrency: number = 0): Promise<Record<string, any>> {
    const urlFallbacksTemplates = pageList.topology.map(urlDescriptor => `${urlDescriptor.baseUrl}${urlDescriptor.parts}`)
    const entries = await fetchPageEntries(fetchFn, pageList, urlFallbacksTemplates, concurrency)
    return Object.fromEntries(entries.map(({pageId, page}) => [pageId, page]))
}

async function fetchPageEntries(fetchFn: FetchFn, pageList: PageList, urlFallbacksTemplates: string[], concurrency: number) {
    if (concurrency > 0) {
        return await fetchPageEntriesNew(fetchFn, pageList, urlFallbacksTemplates, concurrency)
    }
    return await fetchPageEntriesOld(fetchFn, pageList, urlFallbacksTemplates)
}

async function fetchPageEntriesOld(fetchFn: FetchFn, pageList: PageList, urlFallbacksTemplates: string[]) {
    const pageRequests = pageList.pages.map(({pageJsonFileName, pageId}) => fetchPageJson(fetchFn, urlFallbacksTemplates, pageId, pageJsonFileName))

    pageRequests.push(fetchPageJson(fetchFn, urlFallbacksTemplates, 'masterPage', pageList.masterPageJsonFileName))

    return await Promise.all(pageRequests)
}

async function fetchPageEntriesNew(fetchFn: FetchFn, pageList: PageList, urlFallbacksTemplates: string[], concurrency: number) {
    const allPages = [...pageList.pages, {pageId: 'masterPage', pageJsonFileName: pageList.masterPageJsonFileName}]
    return await pMap(allPages, ({pageJsonFileName, pageId}) => fetchPageJson(fetchFn, urlFallbacksTemplates, pageId, pageJsonFileName), {concurrency})
}

async function fetchPageJson(fetchFn: FetchFn, urlFallbacksTemplates: string[], pageId: string, pageJsonFileName: string): Promise<any> {
    for (const urlTemplate of urlFallbacksTemplates) {
        const url = urlTemplate.replace('{filename}', pageJsonFileName)

        const response = await fetchFn(url)
        if (!response.ok) {
            console.warn(`HTTP: failed to fetch page ${url} - ${response.status}: ${response.statusText}`)
            continue
        }
        const page = await response.json()
        return {pageId, page}
    }
    throw new Error(`Failed to fetch page using all fallback URLs. pageId: ${pageId}, pageJsonFileName: ${pageJsonFileName}`)
}

export {fetchPages}
