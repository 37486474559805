define(['documentServices/constants/constants'], function (consts) {
    'use strict'

    const metaData = {
        anchors: {
            to: {allow: true, lock: consts.ANCHORS.LOCK_CONDITION.NEVER},
            from: false
        },
        canBeFixedPosition: false,
        resizableSides: [],
        moveDirections: [],
        containable: false,
        duplicatable: false,
        removable: false,
        fullWidth: true,
        styleCanBeApplied(ps, compRef) {
            return !(ps.siteAPI.getCurrentPopupId() === compRef.id)
        },
        hiddenable: false,
        collapsible: false,
        dockable: false,
        mobileConversionConfig: {
            shouldApplyPreset: false,
            marginX: 20,
            category: 'page'
        },
        maximumChildrenNumber: Number.MAX_VALUE
    }

    return metaData
})
