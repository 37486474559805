import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import appLinkProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/appLinkProxy'

const cmp = createReactClass({
    displayName: 'FlexboxContainerAnchor',
    propTypes: {
        href: PropTypes.string.isRequired,
        pathToItems: PropTypes.string,
        target: PropTypes.string,
        children: PropTypes.node
    },
    render() {
        return santaComponents.utils.createReactElement(
            'div',
            _.omit(this.props, 'href'),
            santaComponents.utils.createReactElement(
                'a',
                {
                    'data-page-item-context': this.props.pathToItems,
                    href: this.props.href,
                    ref: 'a',
                    style: {
                        // The style is required to avoid issues with flex-box children.
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%'
                    },
                    target: this.props.target
                },
                this.props.children
            )
        )
    }
})
const reactClass = React.createElement.bind(null, cmp)
reactClass.type = cmp

export default {
    mixins: [appLinkProxy],
    getCustomZoomReactClass() {
        return reactClass
    }
}
