import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import wixBI from '../bi/wixBI'
import beat from './beat'

// @ts-ignore
const IS_PREVIEW = typeof window !== 'undefined' && window.queryUtil && window.queryUtil.isParameterTrue('isEdited')
// @ts-ignore
const MAJOR_VER = typeof window !== 'undefined' && !window.clientSideRender ? 4 : 3
const DEFAULT_SAMPLE_RATIO = 10

const errorMap = {
    errorName: 'errn',
    errorCode: 'errc',
    errc: 'errc',
    src: 'src',
    severity: 'sev',
    sev: 'sev',
    packageName: 'errscp'
}

const errorSeverityMap = {
    recoverable: 10,
    warning: 20,
    error: 30,
    fatal: 40
}

const eventMap = {
    eventId: 'evid',
    evid: 'evid',
    src: 'src',
    ds_origin: 'ds_origin',
    builder_environment: 'builder_environment',
    corvidSessionId: 'corvidSessionId'
}

const viewerSessionId = {}

function getErrorSeverity(severity) {
    return typeof severity === 'string' ? errorSeverityMap[severity] : severity
}

function extractDefaultErrorDefParams(siteData, reportDef, params, visitorId) {
    const paramsToReturn = {}
    if (reportDef.src === 44 || (reportDef.src === 42 && visitorId)) {
        // @ts-ignore
        paramsToReturn.visitor_id = visitorId
    }

    const dsOrigin = _.get(params, ['dsOrigin'])
    if (dsOrigin) {
        // @ts-ignore
        paramsToReturn.dsOrigin = dsOrigin
        if (params.esi) {
            // @ts-ignore
            paramsToReturn.esi = params.esi
        }
    }

    return _.assign(paramsToReturn, {
        errn: reportDef.errorName,
        evid: 10,
        sev: getErrorSeverity(reportDef.severity),
        cat: IS_PREVIEW ? 1 : 2,
        iss: 1,
        is_rollout: _.get(siteData, ['wixBiSession', 'is_rollout'], 0),
        ut: warmupUtilsLib.cookieUtils.getCookie('userType')
    })
}

function getTrancateDescription(params) {
    if (_.has(params, ['description'])) {
        return {desc: JSON.stringify(params.description).slice(0, 512)}
    }
}

function extractErrorParams(siteData, reportDef, params, visitorId) {
    return _.merge(
        {
            src: 44,
            sev: 30,
            errn: 'error_name_not_found'
        },
        extractReportParamsAccordingToMap(reportDef, errorMap),
        extractDefaultErrorDefParams(siteData, reportDef, params, visitorId),
        extractAdditionalParams(reportDef.params, params),
        getTrancateDescription(params)
    )
}

function extractEventParams(reportDef, params) {
    return _.merge({src: 42}, extractReportParamsAccordingToMap(reportDef, eventMap), extractAdditionalParams(reportDef.params, params))
}

function extractParams(siteData, reportType, reportDef, params) {
    let resultParams
    const paramsFromSite = getParamsFromSite(siteData)

    switch (reportType) {
        case 'error':
            const visitorId = getVisitorId(siteData)
            resultParams = extractErrorParams(siteData, reportDef, params, visitorId)
            break
        case 'event':
            resultParams = extractEventParams(reportDef, params)
            break
    }

    return _.merge(resultParams, paramsFromSite, {viewerName: 'santa'})
}

function sanitizePIIForBi(paramToSanitize) {
    if (_.isString(paramToSanitize)) {
        return warmupUtilsLib.biLoggerSanitizer.sanitizePII(paramToSanitize)
    }
    return paramToSanitize
}

function createWixBIOptions(siteData, reportDef, params) {
    const reportType = reportDef.reportType || (reportDef.errorCode || reportDef.errc ? 'error' : 'event')
    return {
        biUrl: siteData.serviceTopology.biServerUrl,
        adapter: reportDef.adapter || reportDef.endpoint || (reportType === 'error' ? 'trg' : 'ugc-viewer'),
        params: extractParams(siteData, reportType, reportDef, params)
    }
}

function getMetaSiteId(siteData) {
    return _.isFunction(siteData.getMetaSiteId) ? siteData.getMetaSiteId() : siteData.rendererModel.metaSiteId
}

function extractSantaVersion(siteData) {
    const sourceMatches = siteData.santaBase && siteData.santaBase.match(/([\d\.]+)\/?$/)
    return (sourceMatches && sourceMatches[1]) || ''
}

function getParamsFromSite(siteData) {
    viewerSessionId[siteData.siteId] = viewerSessionId[siteData.siteId] || siteData.wixBiSession.viewerSessionId || warmupUtilsLib.guidUtils.getGUID() // FIXME(ssr-guid) - needs to be taken from siteData
    const server = siteData.serviceTopology.serverName ? _.head(siteData.serviceTopology.serverName.split('.')) : ''
    const siteParams: any = {
        site_id: siteData.siteId,
        msid: getMetaSiteId(siteData),
        majorVer: MAJOR_VER,
        ver: extractSantaVersion(siteData),
        server,
        viewMode: siteData.viewMode
    }
    if (!IS_PREVIEW) {
        siteParams.vsi = viewerSessionId[siteData.siteId]
    }
    return siteParams
}

function encodeString(v) {
    return typeof v === 'string' ? encodeURIComponent(v) : v
}

function extractAdditionalParams(reportDefParams, params) {
    const additionalParams =
        (_.isArray(reportDefParams) && _.pick(params, reportDefParams)) ||
        (_.isObject(reportDefParams) && _.mapValues(reportDefParams, v => params[v])) ||
        params

    return _(additionalParams).mapValues(sanitizePIIForBi).mapValues(encodeString).value()
}

function extractReportParamsAccordingToMap(reportDef, reportMap) {
    return _.transform(
        reportDef,
        function (accum, val, key) {
            const mapped = reportMap[key]
            if (mapped) {
                accum[mapped] = val
            }
        },
        {}
    )
}

function shouldSuppressBI(siteData) {
    return warmupUtilsLib.stringUtils.isTrue(siteData.currentUrl.query.suppressbi)
}

function passedCallLimit(reportDef) {
    if (!reportDef) {
        return false
    }
    reportDef.callCount = reportDef.callCount || 0
    reportDef.callCount++
    return reportDef.callLimit && reportDef.callCount > reportDef.callLimit
}

function isSiteInSampleRatio(siteData, reportDef) {
    if (siteData.forceBI) {
        return true
    }
    let sampleRatio = DEFAULT_SAMPLE_RATIO
    if (reportDef) {
        if (_.result(siteData, 'isWixSite', false) && 'wixSiteSampleRatio' in reportDef) {
            sampleRatio = reportDef.wixSiteSampleRatio
        } else if ('sampleRatio' in reportDef) {
            sampleRatio = reportDef.sampleRatio
        } else if ('errorCode' in reportDef || reportDef.endpoint === 'editor') {
            sampleRatio = 0
        }
    }
    if (sampleRatio && sampleRatio >= 1) {
        return beat.shouldIncludeInSampleRatio(siteData, sampleRatio)
    }
    return true
}

function shouldSendReport(siteData, reportDef) {
    return !shouldSuppressBI(siteData) && !passedCallLimit(reportDef) && isSiteInSampleRatio(siteData, reportDef)
}

/**
 *
 * @param {SiteData} siteData
 * @param {biError|biEvent} reportDef
 * @param {object} params
 */
function reportBI(siteData, reportDef, params?) {
    if (!siteData || !_.isObject(reportDef)) {
        return //TODO: throw error
    }
    if (shouldSendReport(siteData, reportDef)) {
        const options = createWixBIOptions(siteData, reportDef, params)
        wixBI.report(siteData, options)
    }
}

/**
 * @param siteData
 * @return {string}
 */
function getVisitorId(siteData) {
    return viewerSessionId[siteData.siteId] || (siteData.wixBiSession && siteData.wixBiSession.viewerSessionId)
}

/**
 * adds errorName and packageName fields to errors
 * @param {*} reportDefCollection map of errors by the errorName
 * @param {string} packageName to assign the errors
 * @param {boolean} shouldMutate - should mutate the error.reportDefCollection object?
 * @returns {*} mutated/clone of reportDefCollection with the added properties packageName and errorName
 */

function formatErrorEvents({reportDefCollection, packageName, shouldMutate = false}) {
    reportDefCollection = shouldMutate === true ? reportDefCollection : _.cloneDeep(reportDefCollection)

    _.forOwn(reportDefCollection, (reportDef, errorName) => {
        reportDef.packageName = packageName
        reportDef.errorName = errorName
    })

    return reportDefCollection
}

/**
 * adds errorName and packageName fields to errors
 **/
function register(packageName, reportType, reportDefCollection) {
    if (reportType === 'error') {
        formatErrorEvents({packageName, reportDefCollection, shouldMutate: true})
    }
}

export default {
    reportBI,
    sanitizePIIForBi,
    register,
    reportBeatEvent: beat.reportBeatEvent,
    shouldSendReport,
    getVisitorId,
    extractAdditionalParams
}

/**
 * Legend for properties:
 * severity: ['minor', 'major', 'critical']
 *c
 */

/**
 * @typedef {{
 *      severity: string,
 *      errorCode: number,
 *      eventId: [number],
 *      src: [string],
 *      sampleRatio: [number],
 *      callLimit: [number],
 *      params: [{p1: [string], p2: [string], p3: [string], p4: [string]}]
 *      }} biError
 */

/**
 * @typedef {{
 *      eventId: number,
 *      src: string
 *      sampleRatio: [number],
 *      callLimit: [number],
 *      params: string[]
 *      }} biEvent
 */
