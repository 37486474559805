import functionLibrary from '@wix/santa-ds-libs/src/wixappsClassics/src/blog/functionLibrary'
import blogSettings from '@wix/santa-ds-libs/src/wixappsClassics/src/blog/blogSettings'

function getRequests(siteData, compData, appService) {
    return [].concat(blogSettings.getRequest(siteData, compData, appService))
}

export default {
    functionLibrary,
    getRequests
}
