import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import SUPPORTED_SITE_EVENTS from '../common/supportedSiteEvents'
import clientSpecMapService from '../services/clientSpecMapService'

const {santaTypesDefinitions} = santaComponents
const {Component: component} = santaTypesDefinitions
export default {
    propTypes: {
        pageStub: component.pageStub,
        formFactor: santaTypesDefinitions.NativeComponentSantaTypes.formFactor.isRequired,
        getExistingRootNavigationInfo: santaTypesDefinitions.getExistingRootNavigationInfo,
        rootId: component.rootId.isRequired,
        pageUrlWithHash: santaTypesDefinitions.pageUrlWithHash.isRequired,
        pageId: component.pageId,
        compData: component.compData.isRequired,
        isViewerMode: santaTypesDefinitions.isViewerMode,
        currentUrlPageId: component.currentUrlPageId,
        dynamicClientSpecMapAspect: santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
        enterOverflowHiddenMode: santaTypesDefinitions.enterOverflowHiddenMode.isRequired,
        exitOverflowHiddenMode: santaTypesDefinitions.exitOverflowHiddenMode.isRequired
    },
    isTPASection: true,

    getSiteUrlStateFromProps(props) {
        const info = props.getExistingRootNavigationInfo(props.rootId)
        return (info && info.tpaInnerRoute) || ''
    },

    getSiteUrlQueryParamsFromProps(props) {
        const info = props.getExistingRootNavigationInfo(props.rootId)

        const appSectionParams = _.get(info, ['queryParams', 'appSectionParams'])
        let queryParams = {}

        if (appSectionParams) {
            try {
                queryParams = JSON.parse(appSectionParams)
            } catch (ex) {
                /* bummer */
            }
        }

        return queryParams
    },

    mutateSkinProperties(skinProps) {
        if (typeof skinProps.iframe === 'object') {
            const src = this.buildUrl(this.getBaseUrl())
            if (skinProps.iframe.src) {
                skinProps.iframe.src = src
            } else {
                skinProps.iframe['data-src'] = src
            }
        }

        return skinProps
    },

    mutateInitialState(initialState) {
        initialState.sectionUrlState = this.getSiteUrlStateFromProps(this.props)
        initialState.sectionUrlParams = this.getSiteUrlQueryParamsFromProps(this.props)
        initialState.sectionUrl = this.fixSectionUrl()

        this.isInOverflowHiddenMode = false

        return initialState
    },

    fixSectionUrl() {
        const sectionUrl = this.props.pageUrlWithHash
        return this.endsWith(sectionUrl, '/') ? sectionUrl : `${sectionUrl}/`
    },

    endsWith(str, suffix) {
        return _.endsWith(str, suffix)
    },

    isViewerMode() {
        return this.getViewMode() === 'site'
    },

    shouldHaveOverflowHidden(props) {
        const isMobileView = props.formFactor === 'Mobile'
        return !props.pageStub && !isMobileView && clientSpecMapService.isFullPageDesktopOnly(props.compData, this.getAppData())
    },

    updateOverflowHiddenState(props) {
        const shouldHaveOverflowHidden = this.shouldHaveOverflowHidden(props)
        if (shouldHaveOverflowHidden && !this.isInOverflowHiddenMode) {
            props.enterOverflowHiddenMode()
            this.isInOverflowHiddenMode = true
        }
        if (!shouldHaveOverflowHidden && this.isInOverflowHiddenMode) {
            props.exitOverflowHiddenMode()
            this.isInOverflowHiddenMode = false
        }
    },

    componentWillReceiveProps(nextProps) {
        const nextUrlState = this.getSiteUrlStateFromProps(nextProps)
        const nextUrlParams = this.getSiteUrlQueryParamsFromProps(nextProps)

        if (!_.isUndefined(this.state.pushState)) {
            this.urlState = this.state.pushState
            this.setState({
                pushState: undefined
            })
            this.reportStateChanged(this.urlState)
        } else if (nextProps.currentUrlPageId === this.props.pageId) {
            if (this.urlState !== nextUrlState || this.shouldUpdateState) {
                this.urlState = nextUrlState
                this.shouldUpdateState = false
                this.setState({
                    sectionUrlState: nextUrlState
                })
                this.reportStateChanged(this.urlState)
            }
        }

        if (nextProps.currentUrlPageId === this.props.pageId && this.state.sectionUrlParams !== nextUrlParams) {
            this.setState({
                sectionUrlParams: nextUrlParams
            })
        }

        this.updateOverflowHiddenState(nextProps)
    },

    componentWillMount() {
        this.updateOverflowHiddenState(this.props)
    },

    componentDidMount() {
        this.shouldUpdateState = false
        this.props.dynamicClientSpecMapAspect.registerReloadSpecMapPlugin(this.props.pageId, () => {
            this.shouldUpdateState = true
        })
    },

    componentWillUnmount() {
        if (this.isInOverflowHiddenMode) {
            this.props.exitOverflowHiddenMode()
        }
    },

    reportStateChanged(nextUrlState) {
        if (this.isCompListensTo(SUPPORTED_SITE_EVENTS.STATE_CHANGED)) {
            this.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'STATE_CHANGED',
                params: {
                    newState: nextUrlState
                }
            })
        }
    },

    isMobileReady() {
        const appData = this.getAppData()
        const isInDevMode = this.isInMobileDevMode && this.isInMobileDevMode()
        const {compData} = this.props
        const {widgets} = appData
        const widgetData = compData.widgetId && _.get(widgets, compData.widgetId)
        if (widgetData) {
            return widgetData.mobileUrl && (isInDevMode || widgetData.mobilePublished)
        }

        return appData.sectionMobileUrl && (isInDevMode || appData.sectionMobilePublished)
    },

    mutateIframeUrlQueryParam(queryParamsObj) {
        if (this.props.isViewerMode) {
            queryParamsObj['section-url'] = this.fixSectionUrl()
            queryParamsObj.target = '_top'
        } else {
            queryParamsObj['section-url'] = this.getBaseUrl()
            queryParamsObj.target = '_self'
        }

        queryParamsObj.width = this.state.initialWidth

        return queryParamsObj
    },

    mutateIframeSrc(urlObj) {
        urlObj = this.addStateToUrlObj(urlObj, this.state.sectionUrlState)

        return urlObj
    },

    addStateToUrlObj(urlObj, state) {
        if (state) {
            const hashState = state.charAt(0) === '#'

            if (hashState) {
                urlObj.hash = state
            } else {
                if (urlObj.path && urlObj.path.slice(-1) !== '/') {
                    urlObj.path += '/'
                }

                urlObj.path += state
            }
        }

        return urlObj
    }
}
