import _ from 'lodash'

const ASPECT_NAME = 'tpaWidgetNativeAspect'

export default function (siteAPI) {
    const aspectDataPath = ['siteAspectsData', 'tpaWidgetNativeAspect', 'dataByCompId']
    const siteData = siteAPI.getSiteData()
    const isInSSR = siteData.isInSSR()
    const getControllerProps = controllerId => siteAPI.getAspectComponentData(ASPECT_NAME, controllerId) || {}
    const updateControllerProps = (controllerId, props) => siteAPI.updateAspectComponentData(ASPECT_NAME, controllerId, props)
    const warmupData = siteData.tpaWidgeNativeAspectWarmupData

    if (isInSSR) {
        //init aspect data in siteAspectsData in SSR only since aspects are not initialize together is SSR for performance reasons.
        //client code will do that in siteAspectsMixin.js (line 130) for all site aspects.
        siteAPI.getDisplayedDAL().setByPath(aspectDataPath, {})
    }
    _.forOwn(warmupData, (compProps, controllerId) => siteAPI.updateAspectComponentData(ASPECT_NAME, controllerId, compProps))

    function setControllerProps(controllerId, newProps) {
        const currentProps = getControllerProps(controllerId)
        updateControllerProps(controllerId, {
            wixCodeProps: _.defaults(newProps.wixCodeProps, currentProps.wixCodeProps),
            events: _.defaults(newProps.events, currentProps.events)
        })
    }

    return {
        setControllerProps,
        getControllerProps
    }
}
