define(['lodash', 'documentServices/pathHelpers/siteMetadataPropertiesInfo', '@wix/santa-core-utils'], function (
    _,
    siteMetadataPropertiesInfo,
    santaCoreUtils
) {
    'use strict'

    const PROPERTY_INFO = siteMetadataPropertiesInfo
    // the paths are NOT exposed to the consumer
    const PROPERTY_NAMES = _.mapValues(PROPERTY_INFO, function (info, prop) {
        return prop
    })

    function getRevertibleMetaDataInfo() {
        return _.filter(PROPERTY_INFO, {revertible: true})
    }

    function getNonRevertibleMetaDataInfo() {
        return _.filter(PROPERTY_INFO, {revertible: false})
    }

    /**
     * get a siteMetaData param value
     * @param {ps} privateServices
     * @param {string} param one of the keys in the PROPERTY_NAMES exported enum
     * @return {*} the value of that param from the data
     */
    function getProperty(privateServices, param) {
        const pointer = privateServices.pointers.metadata.getSiteMetaDataPointer(param)
        if (!pointer) {
            santaCoreUtils.log.error('SiteMetaData getProperty was called with an invalid metadata property')
            return null
        }
        return privateServices.dal.get(pointer)
    }

    /**
     * set a site metadata parameter
     * @param {ps} privateServices
     * @param {string} param a key from the exported PROPERTY_NAMES enum
     * @param value the value to be set for the parameter
     */
    function setProperty(privateServices, param, value) {
        const pointer = privateServices.pointers.metadata.getSiteMetaDataPointer(param)
        if (!pointer) {
            santaCoreUtils.log.error('SiteMetaData setProperty was called with an invalid metadata property')
            return
        }
        privateServices.dal.set(pointer, value)
    }

    return {
        getRevertibleMetaDataInfo,
        getNonRevertibleMetaDataInfo,
        getProperty,
        setProperty,
        PROPERTY_NAMES
    }
})
