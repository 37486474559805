import type {DocumentManager} from '@wix/document-manager-core'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import {viewerExtensions, ViewerExtensionFactory} from '@wix/document-manager-extensions'
import {extensionLoader} from '@wix/document-manager-host-common'

const {loadExtensions} = extensionLoader

const loadViewerExtensions = (core: DocumentManager, viewerManager: ViewerManager) => {
    const extensionsToRegister: ViewerExtensionFactory[] = [
        viewerExtensions.viewerExtension,
        viewerExtensions.displayedStructure,
        viewerExtensions.refComponents
    ]
    extensionsToRegister.forEach(extension => core.registerExtension(extension.createExtension({viewerManager, experimentInstance: core.experimentInstance})))
}

export {loadExtensions, loadViewerExtensions}
