/**
 * Created by eitanr on 6/24/14.
 */
import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import layout from '../util/layout'
import biEvents from '../bi/events.json'
import svgLayoutUtils from '../util/svgLayoutUtils'
import svgScaler from './svgShape/svgScaler'

const {reportBI} = warmupUtils.loggingUtils.logger
const debouncedReportBI = _.debounce((layoutAPI, biParams, svgId) => {
    reportBI(layoutAPI, biParams, {svgId})
}, 300)
const SVG_SELECTOR = 'svgcontent'

function parseViewBox(viewBoxString) {
    const [x, y, width, height] = viewBoxString.split(' ')
    return {x, y, width, height}
}

function isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio) {
    // does browser supports css 'vector-effect: non-scaling-stroke'
    const {isVectorEffect} = warmupUtilsLib.svgFeatureDetection.flags()
    return strokeWidth > 0 && !maintainAspectRatio && !isVectorEffect
}

function getSvgMeasures(nodesMap, id, svgId, viewBox) {
    const node = nodesMap[id]
    const svgNode = nodesMap[id + SVG_SELECTOR]
    const boxBoundaries = viewBox ? parseViewBox(viewBox) : svgNode.getBBox()
    if (!boxBoundaries) {
        return
    }

    const displayMode = node.getAttribute('data-display-mode')
    const maintainAspectRatio = displayMode !== 'stretch'
    const strokeWidth = parseInt(node.getAttribute('data-strokewidth'), 10)
    const requestedSize = {
        width: node.offsetWidth,
        height: node.offsetHeight
    }

    if (isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio)) {
        //const svgString = svgNode.outerHTML;

        requestedSize.width -= strokeWidth
        requestedSize.height -= strokeWidth

        return {
            polyfillScale: svgScaler.measure(id, nodesMap, requestedSize, strokeWidth, maintainAspectRatio)
        }
    }
    return {
        scale: svgLayoutUtils.getSvgScaleProps(boxBoundaries, strokeWidth, requestedSize, maintainAspectRatio)
    }
}

function measureShape(id, nodesMap, layoutAPI) {
    if (!nodesMap[id]) {
        return
    }

    const node = nodesMap[id]
    const {svgId, preserveViewbox} = node.dataset
    const svgElem = node.querySelector(`#${id + SVG_SELECTOR}`)

    const needsMeasure =
        svgElem &&
        svgElem.childNodes.length &&
        //no dataset for svg on IE browser
        !svgElem.getAttribute('data-bbox')

    if (needsMeasure) {
        debouncedReportBI(layoutAPI, biEvents.SVG_NO_MEASURES, svgId)
        nodesMap[id + SVG_SELECTOR] = svgElem
        const viewBox = preserveViewbox === 'preserve' ? node.getAttribute('data-viewbox') : ''
        return getSvgMeasures(nodesMap, id, svgId, viewBox)
    }
}

/**
 *
 * @param id
 * @param patchers
 * @param {layout.structureInfo} structureInfo
 * @param siteData
 */
function layoutShape(id, patchers, svgMeasures) {
    const svgScaleProps = _.get(svgMeasures, 'scale')
    const polyfillScaleProps = _.get(svgMeasures, 'polyfillScale')

    if (svgScaleProps) {
        const {strokeWidth, preserveAspectRatio, viewBox} = svgScaleProps
        if (strokeWidth) {
            patchers.css(id + SVG_SELECTOR, {strokeWidth})
        }
        patchers.attr(id + SVG_SELECTOR, {preserveAspectRatio, viewBox})
    } else if (polyfillScaleProps) {
        svgScaler.patch(id, patchers, polyfillScaleProps)
    }

    // else, do nothing
}

function layoutAndPatchShapes(shapeNodes, nodesMap, layoutAPI) {
    const ids = []
    const svgMeasuresMap = {}

    _.forEach(shapeNodes, shape => {
        const compId = shape.getAttribute('id')
        nodesMap[compId] = shape
        svgMeasuresMap[compId] = measureShape(compId, nodesMap, layoutAPI)
        ids.push(compId)
    })

    return patchers => {
        _.forEach(ids, compId => layoutShape(compId, patchers, svgMeasuresMap[compId]))
    }
}

function layoutShapeNodes(id, nodesMap, measureMap?, layoutAPI?) {
    if (nodesMap[id].getAttribute('data-svg-id')) {
        return layoutAndPatchShapes([nodesMap[id]], nodesMap, layoutAPI)
    }
    const shapeNodes = nodesMap[id].querySelectorAll('*[data-svg-id]')
    return layoutAndPatchShapes(shapeNodes, nodesMap, layoutAPI)
}

layout.registerCustomLayoutFunction('wysiwyg.viewer.components.VectorImage', layoutShapeNodes)
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.svgshape.SvgShape', layoutShapeNodes)
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.PopupCloseIconButton', layoutShapeNodes)
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaControls', layoutShapeNodes)
// layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaOverlayControls', layoutShapeNodes); // see mediaOverlayControlsLayout
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.mobile.TinyMenu', layoutShapeNodes)
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.LoginSocialBar', layoutShapeNodes)
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.BackToTopButton', layoutShapeNodes)

export default {
    layoutShapeNodes
}
