import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import _ from 'lodash'

const {logicFactory} = wixapps
const {blogAppPartNames} = coreUtils

/**
 * @class wixappsClassics.TwoLevelCategoryLogic
 * @param partApi
 * @constructor
 */
function RelatedPostsLogic(partApi) {
    this.partApi = partApi
}

RelatedPostsLogic.prototype = {
    getViewVars() {
        return {
            isEditMode: false
        }
    },
    isHeightResizable() {
        const compData = this.partApi.getRootDataItemRef()
        return !_.isEmpty(compData)
    }
}

logicFactory.register(blogAppPartNames.RELATED_POSTS, RelatedPostsLogic)
