define([
    'lodash',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/documentServicesDataFixer/dsDataFixers',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, mobileUtil, contextAdapter, constants, dsDataFixers, extensionsAPI) {
    'use strict'

    const masterPageId = 'masterPage'

    function fix(ps) {
        const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS

        contextAdapter.utils.fedopsLogger.interactionStarted(dsDataFixersFedOpsTags.ALL)

        const {dataFixerVersioning} = extensionsAPI
        const dsFixersCategory = 'ds_fixer'
        const fixerChangesOnReruns = {}
        const fixerVersions = {}

        _.forEach(dsDataFixers, function (fixerData) {
            if (mobileUtil.isMobileSupported(ps) || !_.get(fixerData, ['config', 'fixMobileOnly'])) {
                const {name: fixerName, version: fixerVersion, disableVersioning, disableFixerAfterFirstRun} = fixerData.fixer
                if (dataFixerVersioning.hasFixerRunOnCurrentVersion(ps, masterPageId, dsFixersCategory, fixerName, fixerVersion)) {
                    if (disableFixerAfterFirstRun) {
                        return
                    }
                    const changes = dataFixerVersioning.executeFixerAndSetModifications(
                        ps,
                        fixerData.fixer.exec.bind(null, ps),
                        '_site_',
                        fixerName,
                        fixerVersion
                    )
                    _.merge(fixerChangesOnReruns, changes)
                } else {
                    if (!disableVersioning) {
                        fixerVersions[fixerName] = fixerVersion
                    }
                    fixerData.fixer.exec(ps)
                }
            }
        })

        dataFixerVersioning.updatePageVersionData(ps, masterPageId, {[dsFixersCategory]: fixerVersions})
        dataFixerVersioning.reportFixerActions(ps, dsFixersCategory, fixerChangesOnReruns)

        contextAdapter.utils.fedopsLogger.interactionEnded(dsDataFixersFedOpsTags.ALL)
    }

    return {
        fix
    }
})
