define([], function () {
    'use strict'

    const resizableSides = []
    return {
        minimalChildrenNumber: 2,
        resizableSides,
        canBeFixedPosition: false,
        mobileConversionConfig: {
            category: 'boxContainer',
            filterChildrenWhenHidden: true,
            isTightContainer: true,
            nestOverlayingSiblings: false,
            marginX: 0
        }
    }
})
