import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const componentsMetaData = viewerLibrary.get('documentServices/componentsMetaData/componentsMetaData')
    const createCompDriver = viewerLibrary.get('documentServices/hooks/componentsExternalHooks/createCompDriver')

    return {
        initMethod: () => {
            componentsMetaData.init(createCompDriver)
        },
        methods: {
            components: {
                layout: {
                    getResizableSides: publicMethodUtils.defineGetter(componentsMetaData.public.getResizableSides),
                    canBeFixedPosition: publicMethodUtils.defineGetter(componentsMetaData.public.canBeFixedPosition),
                    canBeStretched: publicMethodUtils.defineGetter(componentsMetaData.public.canBeStretched),
                    canBeStretchedByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.canBeStretchedByStructure),
                    getLimits: publicMethodUtils.defineGetter(componentsMetaData.public.getLayoutLimits)
                },
                is: {
                    /**
                     * Checks if a container need to enforce child size limitation
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    enforcingContainerChildLimitations: publicMethodUtils.defineGetter(componentsMetaData.public.isEnforcingContainerChildLimitations),
                    /**
                     * Checks if a component is a valid container
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    container: publicMethodUtils.defineGetter(componentsMetaData.public.isContainer),
                    /**
                     * Checks if a component can be contained in a potential container
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer the component that will be contained
                     * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
                     * @returns {boolean}
                     */
                    containable: publicMethodUtils.defineGetter(componentsMetaData.public.isContainable),
                    /**
                     * Checks if a component can be contained in a potential container, using the component's serialized structure
                     * @member documentServices.components.is
                     * @param {Object} componentStructure the serialized structure of the component that will be contained
                     * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
                     * @returns {boolean}
                     */
                    containableByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.isContainableByStructure),
                    styleCanBeApplied: publicMethodUtils.defineGetter(componentsMetaData.public.isStyleCanBeApplied),
                    /**
                     * Checks if a component is cross-site duplicatable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    crossSiteDuplicatable: publicMethodUtils.defineGetter(componentsMetaData.public.isCrossSiteDuplicatable),
                    /**
                     * Checks if a component is cross-site duplicatable, using the component's serialized structure
                     * @member documentServices.components.is
                     * @param {Object} componentStructure the serialized structure of the component that will be contained
                     * @returns {boolean}
                     */
                    crossSiteDuplicatableByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.isCrossSiteDuplicatableByStructure),
                    /**
                     * Checks if a component is movable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    movable: publicMethodUtils.defineGetter(componentsMetaData.public.isMovable),
                    /**
                     * Checks if a component is horizontally movable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    horizontallyMovable: publicMethodUtils.defineGetter(componentsMetaData.public.isHorizontallyMovable),
                    /**
                     * Checks if a component is vertically movable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    verticallyMovable: publicMethodUtils.defineGetter(componentsMetaData.public.isVerticallyMovable),
                    /**
                     * Checks if a component is resizable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    resizable: publicMethodUtils.defineGetter(componentsMetaData.public.isResizable),
                    /**
                     * Checks if a component is horizontally resizable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    horizontallyResizable: publicMethodUtils.defineGetter(componentsMetaData.public.isHorizontallyResizable),
                    /**
                     * Checks if a component is vertically resizable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    verticallyResizable: publicMethodUtils.defineGetter(componentsMetaData.public.isVerticallyResizable),
                    /**
                     * Checks if a component is proportionally resizable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    proportionallyResizable: publicMethodUtils.defineGetter(componentsMetaData.public.isProportionallyResizable),
                    /**
                     * Checks if a component should keep its proportions and allow resizing by corner knobs only
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    resizeOnlyProportionally: publicMethodUtils.defineGetter(componentsMetaData.public.resizeOnlyProportionally),

                    enforceResizableCorners: publicMethodUtils.defineGetter(componentsMetaData.public.enforceResizableCorners),
                    /**
                     * Checks if a component is rotatable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    rotatable: publicMethodUtils.defineGetter(componentsMetaData.public.isRotatable),
                    /**
                     * Checks if a component can be flipped
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    flippable: publicMethodUtils.defineGetter(componentsMetaData.public.isFlippable),
                    /**
                     * Checks if a component is rendered in a full width mode
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    fullWidth: publicMethodUtils.defineGetter(componentsMetaData.public.isFullWidth),
                    /**
                     * Checks if a component is rendered in a full width mode
                     * @member documentServices.components.is
                     * @param {object} componentStructure
                     * @returns {boolean}
                     */
                    fullWidthByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.isFullWidthByStructure),
                    /**
                     * Checks if a component can be contained in a group
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentPointer
                     * @returns {boolean}
                     */
                    groupable: publicMethodUtils.defineGetter(componentsMetaData.public.isGroupable),

                    /**
                     * Checks if a component is alignable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    alignable: publicMethodUtils.defineGetter(componentsMetaData.public.isAlignable),
                    /**
                     * Checks if a component is hiddenable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    hiddenable: publicMethodUtils.defineGetter(componentsMetaData.public.isHiddenable),
                    /**
                     * Checks if a component is collapsible
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    collapsible: publicMethodUtils.defineGetter(componentsMetaData.public.isCollapsible),
                    dockable: publicMethodUtils.defineGetter(componentsMetaData.public.isDockable),
                    /**
                     * Checks if a component is disableable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    disableable: publicMethodUtils.defineGetter(componentsMetaData.public.isDisableable),
                    anchorableFrom: publicMethodUtils.defineGetter(componentsMetaData.public.isAnchorableFrom),
                    anchorableTo: publicMethodUtils.defineGetter(componentsMetaData.public.isAnchorableTo),
                    potentialContainerForScreenWidthComp: publicMethodUtils.defineGetter(componentsMetaData.public.isPotentialContainerForScreenWidthComp),
                    repeatedComponent: publicMethodUtils.defineGetter(componentsMetaData.public.isRepeatedComponent),
                    allowedToContainMoreChildren: publicMethodUtils.defineGetter(componentsMetaData.public.allowedToContainMoreChildren),
                    canReparent: publicMethodUtils.defineGetter(componentsMetaData.public.canReparent),
                    heightAuto: publicMethodUtils.defineGetter(componentsMetaData.public.heightAuto),
                    heightAutoByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.heightAutoByStructure),
                    widthAuto: publicMethodUtils.defineGetter(componentsMetaData.public.widthAuto),
                    widthAutoByStructure: publicMethodUtils.defineGetter(componentsMetaData.public.widthAutoByStructure),
                    a11yConfigurable: publicMethodUtils.defineGetter(componentsMetaData.public.isA11yConfigurable)
                }
            },
            siteSegments: {
                layout: {
                    getResizableSides: publicMethodUtils.defineGetter(componentsMetaData.public.getResizableSides)
                }
            }
        }
    }
}
