import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import tpaUrlBuilderMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaCompApiMixin'
import tpaScrollMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaScrollMixin'
import tpaRuntimeCompMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaRuntimeCompMixin'
import tpaResizeWindowMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaResizeWindowMixin'
import tpaUtils from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/utils/tpaUtils'
/* eslint-enable */

const {compRegistrar} = componentsCore

const parseUnit = x => _.parseInt((x || '').toString().split('px')[0])

const windowOrigin = {
    FIXED: 'FIXED',
    ABSOLUTE: 'ABSOLUTE',
    RELATIVE: 'RELATIVE',
    DEFAULT: 'DEFAULT'
}

const windowPlacement = {
    bottomCenter: 'BOTTOM_CENTER',
    bottomLeft: 'BOTTOM_LEFT',
    bottomRight: 'BOTTOM_RIGHT',
    center: 'CENTER',
    centerLeft: 'CENTER_LEFT',
    centerRight: 'CENTER_RIGHT',
    topCenter: 'TOP_CENTER',
    topLeft: 'TOP_LEFT',
    topRight: 'TOP_RIGHT'
}

const getRelativeCenterLeft = function (originalCompLeft, originalCompWidth, popupWidth) {
    return originalCompLeft + originalCompWidth / 2 - popupWidth / 2
}

const getRelativeBottomHeight = function (windowHeight, originalCompTop, originalCompHeight) {
    return windowHeight - (originalCompTop + originalCompHeight)
}

const getRelativeRightWidth = function (windowWidth, originalCompWidth, originalCompLeft) {
    return windowWidth - (originalCompWidth + originalCompLeft)
}

const getRelativeCenterTop = function (originalCompTop, originalCompHeight, popupHeight) {
    return originalCompTop + originalCompHeight / 2 - popupHeight / 2
}

const parseCssSize = function (requestedSize) {
    function getSizeUnit(reqSize) {
        return /(%)$/.exec(reqSize)
    }

    function getSizeNumber(reqSize) {
        return /^([0-9]+)/.exec(reqSize)
    }

    let size = 0
    let unit = ''

    if (_.isNumber(requestedSize)) {
        size = requestedSize
    } else if (_.isString(requestedSize)) {
        const splitUnit = getSizeUnit(requestedSize)
        const splitSize = getSizeNumber(requestedSize)
        size = splitSize && splitSize[1] ? parseInt(splitSize[1], 10) : 0

        if (splitUnit && splitUnit[1]) {
            unit = splitUnit[1]
        }
    }

    return {size, unit}
}

const handleFixedPosition = function (position, windowSize, width, height) {
    const style = {
        position: 'fixed',
        display: 'block',
        width,
        height
    }

    height = parseCssSize(height)
    if (height.unit === '%') {
        height.size = _.min([height.size, 100])
    } else if (height.size > windowSize.height) {
        height.unit = '%'
        height.size = 100
    }
    width = parseCssSize(width)
    width.size = width.unit === '%' ? _.min([width.size, 100]) : _.min([width.size, windowSize.width])

    if (position.placement === windowPlacement.center) {
        _.assign(style, {
            marginLeft: width.size / -2 + (width.unit || 'px'),
            marginTop: height.unit === '%' ? 0 : height.size / -2 + (height.unit || 'px'),
            left: '50%',
            top: height.unit === '%' ? 0 : '50%'
        })
    } else if (position.placement === windowPlacement.topLeft) {
        _.assign(style, {
            left: '0px',
            top: '0px'
        })
    } else if (position.placement === windowPlacement.topRight) {
        _.assign(style, {
            right: '0px',
            top: '0px'
        })
    } else if (position.placement === windowPlacement.topCenter) {
        _.assign(style, {
            marginLeft: width.size / -2 + (width.unit || 'px'),
            top: '0px',
            left: '50%'
        })
    } else if (position.placement === windowPlacement.centerRight) {
        _.assign(style, {
            marginTop: height.unit === '%' ? 0 : height.size / -2 + (height.unit || 'px'),
            top: height.unit === '%' ? 0 : '50%',
            right: '0px'
        })
    } else if (position.placement === windowPlacement.centerLeft) {
        _.assign(style, {
            marginTop: height.unit === '%' ? 0 : height.size / -2 + (height.unit || 'px'),
            top: height.unit === '%' ? 0 : '50%',
            left: '0px'
        })
    } else if (position.placement === windowPlacement.bottomLeft) {
        _.assign(style, {
            bottom: '0px',
            left: '0px'
        })
    } else if (position.placement === windowPlacement.bottomRight) {
        _.assign(style, {
            bottom: '0px',
            right: '0px'
        })
    } else if (position.placement === windowPlacement.bottomCenter) {
        _.assign(style, {
            marginLeft: width.size / -2 + (width.unit || 'px'),
            left: '50%',
            bottom: '0px'
        })
    }

    // React throws a alot of warnings like:
    // 'Warning: a `div` tag (owner: `TPAPopup`) was passed a numeric string value for CSS property `height` (value: `400`) which will be treated as a unitless number in a future version of React.',
    // React defaults to 'px' when given a unitless number, so we might as well be explicit and provide it.
    if (height.unit === '') {
        height.unit = 'px'
    }
    if (width.unit === '') {
        width.unit = 'px'
    }

    _.assign(style, {
        height: height.size + height.unit,
        width: width.size + width.unit
    })
    return style
}

const handleRelativePosition = function (position, originalCompStyle, windowSize, height, width) {
    const style = {
        position: 'absolute',
        display: 'block'
    }
    let top
    let left

    if (position.placement === windowPlacement.center) {
        height = _.min([height, windowSize.height])
        width = _.min([width, windowSize.width])
        top = getRelativeCenterTop(originalCompStyle.top, originalCompStyle.height, height)
        left = getRelativeCenterLeft(originalCompStyle.left, originalCompStyle.width, width)
    } else if (position.placement === windowPlacement.topLeft) {
        height = _.min([height, originalCompStyle.top])
        width = _.min([width, originalCompStyle.left])
        top = originalCompStyle.top - height
        left = originalCompStyle.left - width
    } else if (position.placement === windowPlacement.topRight) {
        height = _.min([height, originalCompStyle.top])
        width = _.min([width, getRelativeRightWidth(windowSize.width, originalCompStyle.width, originalCompStyle.left)])
        top = originalCompStyle.top - height
        left = originalCompStyle.width + originalCompStyle.left
    } else if (position.placement === windowPlacement.topCenter) {
        height = _.min([height, originalCompStyle.top])
        width = _.min([width, windowSize.width])
        top = originalCompStyle.top - height
        left = getRelativeCenterLeft(originalCompStyle.left, originalCompStyle.width, width)
    } else if (position.placement === windowPlacement.centerRight) {
        height = _.min([height, windowSize.height])
        width = _.min([width, getRelativeRightWidth(windowSize.width, originalCompStyle.width, originalCompStyle.left)])
        top = getRelativeCenterTop(originalCompStyle.top, originalCompStyle.height, height)
        left = originalCompStyle.width + originalCompStyle.left
    } else if (position.placement === windowPlacement.centerLeft) {
        height = _.min([height, windowSize.height])
        width = _.min([width, originalCompStyle.left])
        top = getRelativeCenterTop(originalCompStyle.top, originalCompStyle.height, height)
        left = originalCompStyle.left - width
    } else if (position.placement === windowPlacement.bottomLeft) {
        height = _.min([height, getRelativeBottomHeight(windowSize.height, originalCompStyle.top, originalCompStyle.height)])
        width = _.min([width, originalCompStyle.left])
        top = originalCompStyle.top + originalCompStyle.height
        left = originalCompStyle.left - width
    } else if (position.placement === windowPlacement.bottomRight) {
        height = _.min([height, getRelativeBottomHeight(windowSize.height, originalCompStyle.top, originalCompStyle.height)])
        width = _.min([width, getRelativeRightWidth(windowSize.width, originalCompStyle.width, originalCompStyle.left)])
        top = originalCompStyle.top + originalCompStyle.height
        left = originalCompStyle.width + originalCompStyle.left
    } else if (position.placement === windowPlacement.bottomCenter) {
        height = _.min([height, getRelativeBottomHeight(windowSize.height, originalCompStyle.top, originalCompStyle.height)])
        width = _.min([width, windowSize.width])
        top = originalCompStyle.top + originalCompStyle.height
        left = getRelativeCenterLeft(originalCompStyle.left, originalCompStyle.width, width)
    }

    top = _.max([0, top])
    left = _.max([0, left])

    return _.assign(style, {
        top,
        left,
        width,
        height
    })
}

const handleAbsolutePosition = function (position, originalCompStyle, windowSize, height, width) {
    const style = {
        position: 'absolute',
        display: 'block'
    }
    let top
    let left
    let smallestHeight
    let smallestWidth

    if (position.placement === windowPlacement.center) {
        smallestHeight = _.min([originalCompStyle.actualTop + position.y, windowSize.height - (originalCompStyle.actualTop + position.y)])
        height = _.min([height, 2 * smallestHeight])
        smallestWidth = _.min([originalCompStyle.actualLeft + position.x, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        width = _.min([width, 2 * smallestWidth])
        top = originalCompStyle.top + position.y - height / 2
        left = originalCompStyle.left + position.x - width / 2
    } else if (position.placement === windowPlacement.topLeft) {
        height = _.min([height, originalCompStyle.actualTop + position.y])
        width = _.min([width, originalCompStyle.actualLeft + position.x])
        top = originalCompStyle.top + position.y - height
        left = originalCompStyle.left + position.x - width
    } else if (position.placement === windowPlacement.topRight) {
        height = _.min([height, originalCompStyle.actualTop + position.y])
        width = _.min([width, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        top = originalCompStyle.top + position.y - height
        left = originalCompStyle.left + position.x
    } else if (position.placement === windowPlacement.topCenter) {
        height = _.min([height, originalCompStyle.actualTop + position.y])
        smallestWidth = _.min([originalCompStyle.actualLeft + position.x, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        width = _.min([width, 2 * smallestWidth])
        top = originalCompStyle.top + position.y - height
        left = originalCompStyle.left + position.x - width / 2
    } else if (position.placement === windowPlacement.centerRight) {
        smallestHeight = _.min([originalCompStyle.actualTop + position.y, windowSize.height - (originalCompStyle.actualTop + position.y)])
        height = _.min([height, 2 * smallestHeight])
        width = _.min([width, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        top = originalCompStyle.top + position.y - height / 2
        left = originalCompStyle.left + position.x
    } else if (position.placement === windowPlacement.centerLeft) {
        smallestHeight = _.min([originalCompStyle.actualTop + position.y, windowSize.height - (originalCompStyle.actualTop + position.y)])
        height = _.min([height, 2 * smallestHeight])
        width = _.min([width, originalCompStyle.actualLeft + position.x])
        top = originalCompStyle.top + position.y - height / 2
        left = originalCompStyle.left + position.x - width
    } else if (position.placement === windowPlacement.bottomLeft) {
        height = _.min([height, windowSize.height - (originalCompStyle.actulaTop + position.y)])
        width = _.min([width, originalCompStyle.actualLeft + position.x])
        top = originalCompStyle.top + position.y
        left = originalCompStyle.left + position.x - width
    } else if (position.placement === windowPlacement.bottomRight) {
        height = _.min([height, windowSize.height - (originalCompStyle.actualTop + position.y)])
        width = _.min([width, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        top = originalCompStyle.top + position.y
        left = originalCompStyle.left + position.x
    } else if (position.placement === windowPlacement.bottomCenter) {
        height = _.min([height, windowSize.height - (originalCompStyle.actualTop + position.y)])
        smallestWidth = _.min([originalCompStyle.actualLeft + position.x, windowSize.width - (originalCompStyle.actualLeft + position.x)])
        width = _.min([width, 2 * smallestWidth])
        top = originalCompStyle.top + position.y
        left = originalCompStyle.left + position.x - width / 2
    }

    top = _.max([0, top])
    left = _.max([0, left])

    return _.assign(style, {
        top,
        left,
        width,
        height
    })
}

const fallbackToCenterIfNeeded = function (windowSize, origPopupWidth, origPopupHeight, style) {
    const MIN_EDGE_SIZE = 10
    const widthNumber = parseUnit(style.width)
    const heightNumber = parseUnit(style.height)

    if (widthNumber < MIN_EDGE_SIZE || heightNumber < MIN_EDGE_SIZE) {
        const height = parseCssSize(origPopupHeight)
        height.size = height.unit === '%' ? _.min([height.size, 100]) : _.min([height.size, windowSize.height])
        const width = parseCssSize(origPopupWidth)
        width.size = width.unit === '%' ? _.min([width.size, 100]) : _.min([width.size, windowSize.width])

        return {
            position: 'fixed',
            display: 'block',
            width: width.size + (height.unit || 'px'),
            height: height.size + (width.unit || 'px'),
            marginLeft: width.size / -2 + (height.unit || 'px'),
            marginTop: height.size / -2 + (width.unit || 'px'),
            left: '50%',
            top: '50%'
        }
    }

    return style
}

const getStyleFor = function (position, originalCompStyle, windowSize, width, height) {
    let style = {}

    if (position.origin === windowOrigin.DEFAULT || position.origin === windowOrigin.FIXED) {
        style = handleFixedPosition(position, windowSize, width, height)
    }

    if (position.origin === windowOrigin.RELATIVE) {
        style = handleRelativePosition(position, originalCompStyle, windowSize, height, width)
    }

    if (position.origin === windowOrigin.ABSOLUTE) {
        style = handleAbsolutePosition(position, originalCompStyle, windowSize, height, width)
    }

    style = fallbackToCenterIfNeeded(windowSize, width, height, style)

    return style
}

const getPopupZIndexValue = function (aspectData) {
    const isModalOpened = aspectData.isModalOpen
    return isModalOpened ? 1001 : null
}

const isFullScreen = function (height, width, windowSize) {
    return height === '100%' && (width === '100%' || Number(parseUnit(width)) >= windowSize.width)
}

/**
 * @class components.TPAPopup
 * @extends {ReactCompositeComponent}
 * @extends {tpa.mixins.tpaUrlBuilder}
 * @extends {tpa.mixins.tpaCompAPI}
 * @property {comp.properties} props
 */
const TPAPopup = {
    displayName: 'TPAPopup',
    mixins: [componentsCore.mixins.skinBasedComp, tpaUrlBuilderMixin, tpaCompApiMixin, tpaRuntimeCompMixin, tpaResizeWindowMixin, tpaScrollMixin],
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        dynamicClientSpecMapAspect: santaComponents.santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
        clientSpecMap: santaComponents.santaTypesDefinitions.RendererModel.clientSpecMap.isRequired,
        siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker.isRequired,
        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
        getCommonConfig: santaComponents.santaTypesDefinitions.CommonConfig.getAll,
        aspectData: santaComponents.santaTypesDefinitions.TPA.data.isRequired,
        onCloseCallback: PropTypes.func,
        removePopup: santaComponents.santaTypesDefinitions.TPA.removePopup.isRequired,
        isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired
    },
    getInitialState() {
        return {
            showComponent: true,
            commonConfig: _.cloneDeep(this.props.getCommonConfig()),
            initialInstance: this.props.dynamicClientSpecMapAspect.getAppInstance(this.props.compData.applicationId) || this.getAppData(this).instance,
            registeredEvents: []
        }
    },

    getSkinProperties() {
        const selfStyle = this.state.showComponent ? this.getSelfStyle() : {display: 'none'}
        const showCloseButton = this.isBareTheme() ? 'none' : 'block'
        const iframeSrc = this.buildUrl(this.props.compData.url)

        if (this.state.showComponent) {
            return {
                '': {
                    style: selfStyle,
                    'data-src': iframeSrc,
                    tagName: 'wix-iframe'
                },
                closeButton: {
                    onClick: this.hide,
                    style: {display: showCloseButton}
                },
                iframe: {
                    'data-src': iframeSrc,
                    scrolling: 'no',
                    frameBorder: '0',
                    allow: tpaUtils.getIFramePermissions(),
                    allowtransparency: 'true',
                    allowFullScreen: true,
                    name: this.props.id
                }
            }
        }
        return {
            '': {
                style: selfStyle
            }
        }
    },

    mutateIframeUrlQueryParam(queryParamsObj) {
        queryParamsObj.origCompId = this.props.compData.origCompId

        return queryParamsObj
    },

    getPopupWidth() {
        return _.isUndefined(this.state.width) ? this.props.compData.width : this.state.width
    },

    getPopupHeight() {
        return _.isUndefined(this.state.height) ? this.props.compData.height : this.state.height
    },

    getSelfStyle() {
        const position = _.defaults(this.props.compData.position, {x: 0, y: 0})
        const originalCompStyle = this.props.compData.origCompStyle
        const {windowSize} = this.props.compData
        const style: any = getStyleFor(position, originalCompStyle, windowSize, this.getPopupWidth(), this.getPopupHeight())
        style.zIndex = getPopupZIndexValue(this.props.aspectData)
        this.shouldDisableScroll = isFullScreen(style.height, style.width, windowSize) && this.props.isMobileView
        return this.getThemeStyle(style)
    },

    getThemeStyle(style) {
        if (this.isBareTheme()) {
            return _.merge(style, {
                background: 'none',
                boxShadow: 'none',
                borderRadius: 0
            })
        }

        return style
    },

    hide(data, callback) {
        const self = this

        this.setState({showComponent: false}, function () {
            const callBackData = data && data.message ? data : undefined
            if (self.props.onCloseCallback) {
                self.props.onCloseCallback(callBackData)
            }

            this.props.removePopup(self)
            if (_.isFunction(callback)) {
                callback()
            }
        })
    },

    isBareTheme() {
        return this.props.compData.theme === 'BARE'
    },

    componentWillReceiveProps(nextProps) {
        const oldInstance = _.get(this.props.clientSpecMap, [this.props.compData.applicationId, 'instance'])
        const newInstance = _.get(nextProps.clientSpecMap, [this.props.compData.applicationId, 'instance'])
        if (oldInstance !== newInstance) {
            this.setState({
                initialInstance: newInstance
            })
        }
    },

    componentDidMount() {
        this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, this.shouldDisableScroll)
    },

    componentDidUpdate() {
        this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, this.shouldDisableScroll)
    },

    componentWillUnmount() {
        this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, false)
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAPopup', TPAPopup)
export default TPAPopup
