/**
 * Created by Talm on 14/07/2014.
 */
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const MIN_GALLERY_HEIGHT = 70

function getGalleryHeight(currentNumberOfRows, newNumberOfRows, margin, skinName, height, itemHeightFromProp?) {
    const marginBetweenImages = margin
    const heightDiff = this.getSkinHeightDiff(skinName)
    const itemHeight = itemHeightFromProp || coreUtils.matrixCalculations.getItemHeight(margin, height, currentNumberOfRows, heightDiff)
    return Math.floor(newNumberOfRows * itemHeight + (newNumberOfRows - 1) * marginBetweenImages) + heightDiff
}

export {getGalleryHeight, MIN_GALLERY_HEIGHT}
