import _ from 'lodash'
import * as mobx from 'mobx'

export default {
    getStorage() {
        // @ts-ignore
        const map = mobx.observable.shallowMap()

        return {
            transaction: mobx.runInAction,
            set(key, value) {
                map.set(key, value)
            },
            update(key, value) {
                const currentValue = map.get(key)
                const newValue = _.defaults(value, currentValue)
                map.set(key, newValue)

                return newValue
            },
            get(key) {
                return map.get(key)
            },
            remove(key) {
                return map.delete(key)
            },
            getAll() {
                return mobx.toJS(map)
            },
            clear() {
                map.clear()
            }
        }
    }
}
