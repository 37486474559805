define([], function () {
    'use strict'

    return {
        PUBLIC: 'public',
        PAGES: 'public/pages',
        BACKEND: 'backend',
        SCHEMAS: '.schemas'
    }
})
