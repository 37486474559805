define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/constants/constants',
    'documentServices/page/page',
    'documentServices/component/component',
    'documentServices/component/componentBehaviors',
    'documentServices/documentMode/documentMode',
    'documentServices/documentServicesDataFixer/fixers/utils/meshLayoutReadyUtils',
    'documentServices/documentServicesDataFixer/fixers/soapOrderFixer',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/structure/utils/layoutSettingsUtils',
    'documentServices/utils/contextAdapter'
], function (
    _,
    coreUtils,
    constants,
    page,
    component,
    componentBehaviors,
    documentMode,
    meshLayoutReadyUtils,
    soapOrderFixer,
    mobileConversionFacade,
    mobileUtil,
    layoutSettingsUtils,
    contextAdapter
) {
    'use strict'

    const COMPS_WITH_SLIDES = ['wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.StripContainerSlideShow']

    const MIGRATION_STATUS = {
        CANCELLED: 'CANCELLED',
        DONE: 'DONE',
        INIT: 'INIT',
        RUNNING: 'RUNNING'
    }

    let migrationStatus = MIGRATION_STATUS.INIT

    const createNextSlideAsync = (ps, compRef, slideIndex) =>
        new Promise(resolve => {
            componentBehaviors.executeBehavior(ps, compRef, 'moveToSlide', {slide: slideIndex}, resolve)
        })

    /**
     * @param {ps} ps
     * @param {Pointer} compRef
     * @param slidesIndexes
     * @return {Promise<any>}
     */
    async function executeSequentially(ps, compRef, slidesIndexes) {
        const x = await createNextSlideAsync(ps, compRef, slidesIndexes.pop())
        if (_.isEmpty(slidesIndexes)) {
            return x
        }
        return await executeSequentially(ps, compRef, slidesIndexes)
    }

    async function navigateToAllSlides(ps, compInfo) {
        const slidesIndexes = compInfo.slides
        if (!_.isEmpty(slidesIndexes)) {
            return executeSequentially(ps, compInfo.pointer, slidesIndexes)
        }
    }

    const getSiteStructureAnchors = (ps, isMigrationForced = false) =>
        _.reduce(
            page.getPageIdList(ps, false, true),
            (anchorsPerPage, pageId) => {
                mobileUtil.getSupportedViewModes(ps).forEach(viewMode => {
                    const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
                    const compsWithAnchors = getCompsWithAnchorsOnPage(ps, pagePointer, isMigrationForced)
                    if (!_.isEmpty(compsWithAnchors)) {
                        anchorsPerPage[viewMode][pageId] = compsWithAnchors
                    }
                })
                return anchorsPerPage
            },
            {
                [constants.VIEW_MODES.DESKTOP]: {},
                [constants.VIEW_MODES.MOBILE]: {}
            }
        )

    const getCompsWithAnchorsOnPage = (ps, pagePointer, isMigrationForced) => {
        const childrenPointers = ps.pointers.components.getChildrenRecursively(pagePointer)
        const compsWithAnchors = isMigrationForced ? childrenPointers : childrenPointers.filter(_.partial(doesCompHaveAnchorsInStructure, ps))
        if (!_.isEmpty(compsWithAnchors)) {
            return compsWithAnchors.map(compPointer => {
                const hasSlides = doesComponentHaveSlides(ps, compPointer)
                return {pointer: compPointer, hasSlides, slides: hasSlides ? getSlidesWithAnchors(ps, compPointer) : null}
            })
        }
        return []
    }

    const doesComponentHaveSlides = (ps, compPointer) => _.includes(COMPS_WITH_SLIDES, component.getType(ps, compPointer))

    const getSlidesWithAnchors = (ps, compPointer) => {
        const slides = ps.pointers.components.getChildren(compPointer)
        const slidesWithAnchors = _.map(slides, _.partial(doesCompHaveAnchorsInStructure, ps))
        return getTruthyIndexes(slidesWithAnchors)
    }

    const getTruthyIndexes = array =>
        _.reduce(
            array,
            (acc, val, idx) => {
                if (val) {
                    acc.push(idx)
                }
                return acc
            },
            []
        )

    const doesCompHaveAnchorsInStructure = (ps, compPointer) => {
        const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const compLayout = ps.dal.full.get(compLayoutPointer)
        return (
            !_.isEmpty(_.get(compLayout, 'anchors')) || _.some(ps.pointers.components.getChildren(compPointer), _.partial(doesCompHaveAnchorsInStructure, ps))
        )
    }

    const navigateThroughCompsWithSlides = (ps, compsWithSlidesToNavigate) => Promise.all(compsWithSlidesToNavigate.map(navigateToAllSlides.bind(null, ps)))

    const waitForChangesToApply = ps => new Promise(res => ps.setOperationsQueue.waitForChangesApplied(res))

    async function navToPages(ps, pageToCompsAnchors, updateCallback) {
        const navigateSerially = async (totalNumOfPages, pageIds) => {
            await promisifiedNavigateToPage(ps, _.head(pageIds))
            await navigateThroughCompsWithSlides(ps, getCompPointersWithSlides(pageToCompsAnchors[_.head(pageIds)]))
            await updateCallback({status: 'page-migrated', pageId: _.head(pageIds)})
            await waitForChangesToApply(ps)
            pageIds.shift()
            checkMigrationStatus()
            if (!_.isEmpty(pageIds)) {
                await navigateSerially(totalNumOfPages, pageIds)
            }
        }
        if (!_.isEmpty(pageToCompsAnchors)) {
            await navigateSerially(_.size(pageToCompsAnchors), _.keys(pageToCompsAnchors))
        }
    }

    const getCompPointersWithSlides = pageCompsWithAnchorsInfo => _.filter(pageCompsWithAnchorsInfo, {hasSlides: true})

    const checkMigrationStatus = () => {
        if (migrationStatus !== MIGRATION_STATUS.RUNNING) {
            throw migrationStatus
        }
    }

    function promisifiedNavigateToPage(ps, pageId) {
        ps.setOperationsQueue.runSetOperation(page.navigateTo, [ps, pageId], {
            methodName: 'page.navigateTo',
            waitingForTransition: true,
            noBatchingAfter: true
        })
        return waitForChangesToApply(ps)
    }

    async function navigateToAllPages(ps, viewMode, sitePagesWithAnchors, updateCallback) {
        ps.setOperationsQueue.runSetOperation(documentMode.setViewMode, [ps, viewMode])
        await waitForChangesToApply(ps)
        checkMigrationStatus()
        await updateCallback({status: 'view-mode', viewMode})
        await waitForChangesToApply(ps)
        await navToPages(ps, sitePagesWithAnchors, updateCallback)
    }

    async function navigateToPageOnViewMode(ps, {pageId, viewMode}) {
        ps.setOperationsQueue.runSetOperation(documentMode.setViewMode, [ps, viewMode])
        await waitForChangesToApply(ps)
        await promisifiedNavigateToPage(ps, pageId)
    }

    async function cleanSiteAnchors(ps, sitePagesWithAnchors, updateCallback) {
        await updateCallback({
            status: 'init',
            maxValue: _.size(sitePagesWithAnchors[constants.VIEW_MODES.DESKTOP]) + _.size(sitePagesWithAnchors[constants.VIEW_MODES.MOBILE]),
            value: 0
        })
        await navigateToAllPages(ps, constants.VIEW_MODES.DESKTOP, sitePagesWithAnchors[constants.VIEW_MODES.DESKTOP], updateCallback)
        await navigateToAllPages(ps, constants.VIEW_MODES.MOBILE, sitePagesWithAnchors[constants.VIEW_MODES.MOBILE], updateCallback)
        await waitForChangesToApply(ps)
    }

    const getLayoutSettings = ps => layoutSettingsUtils.getLayoutSettings(ps)

    const setTpasRenderFlags = (ps, isEnabled) => {
        documentMode.enableRenderTPAsIframe(ps, isEnabled)
        return waitForChangesToApply(ps)
    }

    const disableTPAs = ps => setTpasRenderFlags(ps, false)
    const enableTPAs = ps => setTpasRenderFlags(ps, true)

    const setTransitionsRenderFlags = (ps, isEnabled) => {
        documentMode.enableComponentTransitions(ps, isEnabled)
        documentMode.enableStubifyComponents(ps, isEnabled)
        documentMode.enablePlaying(ps, isEnabled)
        documentMode.enablePageTransitions(ps, isEnabled)
        return waitForChangesToApply(ps)
    }

    const disableTransitions = ps => setTransitionsRenderFlags(ps, false)
    const enableTransitions = ps => setTransitionsRenderFlags(ps, true)

    const setEnableFetchDynamicPageInnerRoutesFlag = (ps, isEnabled) => {
        documentMode.enableFetchDynamicPageInnerRoutes(ps, isEnabled)
        return waitForChangesToApply(ps)
    }

    const disableFetchDynamicPageInnerRoutes = ps => setEnableFetchDynamicPageInnerRoutesFlag(ps, false)
    const enableFetchDynamicPageInnerRoutes = ps => setEnableFetchDynamicPageInnerRoutesFlag(ps, true)

    const isSiteLayoutMechanismMesh = ps => _.get(getLayoutSettings(ps), 'mechanism') === coreUtils.constants.LAYOUT_MECHANISMS.MESH
    const shouldRunMeshMigrator = ps => !isSiteLayoutMechanismMesh(ps)

    const resetRenderFlags = async ps => {
        await enableTPAs(ps)
        await enableTransitions(ps)
        await enableFetchDynamicPageInnerRoutes(ps)
    }

    const handleError = async (ps, pageToNavTo, error, onFail) => {
        await navigateToPageOnViewMode(ps, pageToNavTo)
        await resetRenderFlags(ps)
        await onFail(error)
    }

    const getStartingPage = ps => {
        const viewMode = constants.VIEW_MODES.DESKTOP
        const pageId = ps.siteAPI.getFocusedRootId()
        return {
            viewMode,
            pageId
        }
    }

    const cancel = () => {
        migrationStatus = MIGRATION_STATUS.CANCELLED
        return true
    }

    const runSOAPOrderFixer = ps => soapOrderFixer.exec(ps)

    /**
     * @param {ps} ps
     */
    const fixTinyMenuInMobile = ps => {
        const masterPagePointer = ps.pointers.full.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const tinyMenuPointer = masterPagePointer && ps.pointers.full.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, masterPagePointer)
        const tinyMenuParentPointer = tinyMenuPointer && ps.pointers.full.components.getParent(tinyMenuPointer)
        const headerPointer = ps.pointers.full.components.getHeader(constants.VIEW_MODES.MOBILE)
        const shouldReparent =
            ps.dal.full.isExist(tinyMenuPointer) && tinyMenuParentPointer && headerPointer && !_.isEqual(tinyMenuParentPointer, headerPointer)
        if (shouldReparent) {
            // I cannot use structure.setContainer(ps, tinyMenuPointer, tinyMenuPointer, headerPointer) since TINY_MENU is not 'containable' by its componentMetaData
            // the only way to do it is directly via dal.remove & dal.push
            const siblingComponents = ps.pointers.full.components.getChildrenContainer(headerPointer)
            const tinyMenuStructure = ps.dal.full.get(tinyMenuPointer)
            ps.dal.full.remove(tinyMenuPointer)
            ps.dal.full.push(siblingComponents, tinyMenuStructure, tinyMenuPointer)
        }
    }
    const fixInvalidSOAPInMobile = ps => {
        if (!documentMode.isMobileView(ps)) {
            fixTinyMenuInMobile(ps)
            mobileConversionFacade.runPartialConversionAllPages(ps, true)
        }
    }
    const recreatePagesAnchors = (ps, pageIds) => {
        pageIds.forEach(ps.siteAPI.createPageAnchors.bind(ps.siteAPI))
    }

    const runMeshReadyFixer = ps =>
        new Promise((res, rej) => {
            meshLayoutReadyUtils.meshLayoutReadySiteDataFixerAfterMeshWizard(ps, res, rej)
        })

    const validateSiteMigratedToMesh = ps => {
        if (!isSiteLayoutMechanismMesh(ps)) {
            throw new Error('Failed to migrate to mesh')
        }
    }

    const runMeshMigrationAsync = async (ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback) => {
        migrationStatus = MIGRATION_STATUS.RUNNING
        // console.time('migrateSiteToMesh');
        contextAdapter.utils.fedopsLogger.interactionStarted(constants.INTERACTIONS.MESH_SITE_MIGRATION)
        const sitePagesWithAnchors = getSiteStructureAnchors(ps, isMigrationForced)
        await disableTransitions(ps)
        await disableTPAs(ps)
        await disableFetchDynamicPageInnerRoutes(ps)
        await cleanSiteAnchors(ps, sitePagesWithAnchors, updateCallback)
        await navigateToPageOnViewMode(ps, startingPage)
        runSOAPOrderFixer(ps)
        await fixInvalidSOAPInMobile(ps)
        await resetRenderFlags(ps)
        await runMeshReadyFixer(ps)
        await recreatePagesAnchors(ps, ['masterPage', startingPage.pageId])
        await validateSiteMigratedToMesh(ps)
        await updateCallback({status: 'done'})
        // await console.timeEnd('migrateSiteToMesh'))
        await contextAdapter.utils.fedopsLogger.interactionEnded(constants.INTERACTIONS.MESH_SITE_MIGRATION)
        migrationStatus = MIGRATION_STATUS.DONE
    }

    const runMeshMigration = (ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback) => {
        runMeshMigrationAsync(ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback).then(
            () => onSuccess('migration finished successfully'),
            err => {
                handleError(ps, startingPage, err, onFail)
            }
        )
    }

    function migrateSiteToMesh(ps, onSuccess, onFail, {isMigrationForced = undefined, updateCallback = _.noop} = {}) {
        if (migrationStatus === MIGRATION_STATUS.RUNNING) {
            return
        }
        const startingPage = getStartingPage(ps)
        try {
            if (shouldRunMeshMigrator(ps) || isMigrationForced) {
                runMeshMigration(ps, startingPage, onSuccess, onFail, isMigrationForced, updateCallback)
            } else {
                onSuccess('site is already mesh or mesh eligible')
            }
        } catch (err) {
            handleError(ps, startingPage, err, onFail)
        }
    }

    return {
        migrateSiteToMesh,
        cancel
    }
})
