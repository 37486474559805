define([
    'lodash',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/componentStructureInfo',
    'documentServices/dataModel/dataModel',
    'documentServices/menu/menuUtils',
    'documentServices/constants/constants',
    'documentServices/utils/multilingual',
    'documentServices/siteMetadata/language',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment'
], function (_, componentDetectorAPI, componentStructureInfo, dataModel, menuUtils, constants, mlUtils, language, extensionsAPI, experiment) {
    'use strict'

    const {VIEW_MODES} = constants
    /**
     * @typedef {{
     *  link: [string],
     *  items: menuItem[],
     *  label: [string],
     *  isVisible: boolean,
     *  isVisibleMobile: boolean,
     *  displayCount: [number]
     * }} menuItem
     */

    /**
     * @typedef {{
     *  name: string,
     *  items: menuItem[]
     * }} menuData
     */

    const CUSTOM_MAIN_MENU = 'CUSTOM_MAIN_MENU'
    const ALLOWED_MENUS = [
        'wysiwyg.viewer.components.menus.DropDownMenu',
        'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
        'wysiwyg.viewer.components.mobile.TinyMenu',
        'wysiwyg.viewer.components.ExpandableMenu',
        'wixui.StylableHorizontalMenu',
        'wixui.Breadcrumbs'
    ]
    const ALLOWED_MENUS_AS_MAP = _.keyBy(ALLOWED_MENUS)

    const supportedConnectType = ['CustomMenuDataRef', 'TinyMenu', 'BreadcrumbsData']
    const cannotLinkThisCompToMenuData = _.template(
        `Cannot link a menu data item to a component that is not one of: ${ALLOWED_MENUS.join(', ')}. Given component type was <%= compType %>.`
    )

    function hasMoreThanOneLevelOfSubItems(menuItem) {
        return menuItem.items && _.some(menuItem.items, item => item.items && item.items.length > 0)
    }

    const NO_SUPPORT_FOR_2_LEVELS = 'Currently, components do not support menus which are more than 2 levels deep.'

    function validateMenuDataDepth(menuData) {
        const allowDeep = experiment.isOpen('dm_allowDeepMenus')
        if (!allowDeep && menuData.items && _.some(menuData.items, hasMoreThanOneLevelOfSubItems)) {
            throw new Error(`You cannot add a menu which is more than 2 levels deep (a subitem cannot have subitems). ${NO_SUPPORT_FOR_2_LEVELS}`)
        }
    }

    function mutateItems(mutators, item) {
        _.forEach(mutators, function (mutator) {
            mutator(item)
        })
        if (item.items) {
            _.forEach(item.items, function (subItem) {
                mutateItems(mutators, subItem)
            })
        }
    }

    function normalizeMenuItems(menuData) {
        _.forEach(menuData.items, function (item) {
            mutateItems([withoutId, withoutLinkId, asBasicMenuItem], item)
        })
    }

    const withoutId = _.partialRight(_.unset, 'id')
    const withoutLinkId = _.partialRight(_.unset, ['link', 'id'])
    const asBasicMenuItem = _.partialRight(_.set, 'type', 'BasicMenuItem')

    /**
     *
     * @param {ps} ps
     * @param {String} newMenuId - this is injected
     * @param {menuData} [menuData]
     * @returns {*}
     */
    function createMenu(ps, newMenuId, menuData) {
        if (getMenuById(ps, newMenuId)) {
            throw new Error(`Menu with id ${newMenuId} already exist.`)
        }

        const dataItem = dataModel.createDataItemByType(ps, 'CustomMenu')
        menuData = _.assign(dataItem, menuData, {id: newMenuId})
        normalizeMenuItems(menuData)
        validateMenuDataDepth(menuData)
        dataModel.addSerializedDataItemToPage(ps, 'masterPage', menuData, newMenuId)
        const customMenusArrayPointer = ps.pointers.getInnerPointer(ps.pointers.data.getDataItem('CUSTOM_MENUS', 'masterPage'), 'menus')
        ps.dal.push(customMenusArrayPointer, `#${newMenuId}`)
        return newMenuId
    }

    /**
     *
     * @param {ps} ps
     * @param {menuData} [menuData]
     * @param {String} [optionalCustomId]
     * @returns {*}
     */
    function getMenuIdToCreate(ps, menuData, optionalCustomId) {
        return optionalCustomId || dataModel.generateNewDataItemId()
    }

    /** Updates the menu with the provided menu data, in current language
     *
     * @param {ps} ps Private Services instance
     * @param {String} menuId The menu ID to update
     * @param {menuData} menuData The menu data to update
     */
    function updateMenu(ps, menuId, menuData) {
        const useLanguage = language.current.get(ps)
        updateMenuInLang(ps, menuId, menuData, useLanguage)
    }

    /** Updates the menu with the provided menu data
     *
     * @param {ps} ps Private Services instance
     * @param {string} menuId The menu ID to update
     * @param {menuData} menuData The menu data to update
     * @param {string} useLanguage the language code to use
     */
    function updateMenuInLang(ps, menuId, menuData, useLanguage) {
        validateMenuDataDepth(menuData)
        validateMenuExists(ps, menuId)

        const isUpdatingMainMenu = menuId === CUSTOM_MAIN_MENU
        if (!isUpdatingMainMenu) {
            const originalLang = mlUtils.getLanguageByUseOriginal(ps, true)
            if (useLanguage === originalLang) {
                menuUtils.splitMenusInSecondaryLanguagesIfAltered(ps, menuId)
            }
        }
        dataModel.addSerializedDataItemToPage(ps, 'masterPage', menuData, menuId, useLanguage)
        if (isUpdatingMainMenu) {
            menuUtils.updateTranslatedMenuItems(ps, menuData)
        }
    }

    function validateMenuExists(ps, menuId) {
        const menuDataPointer = ps.pointers.data.getDataItemFromMaster(menuId)

        if (!menuId || !ps.dal.full.isExist(menuDataPointer)) {
            throw new Error(`Menu with id ${menuId} does not exist.`)
        }
    }

    function validateCompConnectionToMenu(ps, menuCompPointer, menuId) {
        const compType = componentStructureInfo.getType(ps, menuCompPointer)

        if (!_.includes(ALLOWED_MENUS, compType)) {
            throw new Error(cannotLinkThisCompToMenuData({compType}))
        }

        validateMenuExists(ps, menuId)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} menuCompPointer
     * @param {String} menuId
     */
    function connectComponentToMenu(ps, menuCompPointer, menuId) {
        validateCompConnectionToMenu(ps, menuCompPointer, menuId)
        const {type: currentDataType = 'CustomMenuDataRef'} = dataModel.getDataItem(ps, menuCompPointer) || {}
        dataModel.updateDataItem(ps, menuCompPointer, {
            menuRef: `#${menuId}`,
            type: _.includes(supportedConnectType, currentDataType) ? currentDataType : 'CustomMenuDataRef'
        })
    }

    /**
     *
     * @param {ps} ps
     * @return {menuData[]}
     */
    function getAllMenus(ps) {
        return dataModel.getDataItemById(ps, 'CUSTOM_MENUS', 'masterPage').menus
    }

    /**
     *
     * @param {ps} ps
     * @param {string} menuId
     * @param {boolean} [useOriginalLanguage]
     * @return {menuData|null}
     */
    function getMenuById(ps, menuId, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
        return getMenuByIdInLang(ps, menuId, useLanguage)
    }

    /**
     *
     * @param {ps} ps
     * @param {string} menuId
     * @param {string|undefined} [useLanguage=undefined]
     * @return {menuData|null}
     */
    function getMenuByIdInLang(ps, menuId, useLanguage = undefined) {
        const pointer = ps.pointers.data.getDataItemFromMaster(menuId)
        if (ps.dal.isExist(pointer)) {
            return dataModel.getDataItemByIdInLang(ps, menuId, undefined, undefined, useLanguage)
        }
        return null
    }

    function getMenusByType(ps, menuType) {
        const menuPointers = menuUtils.getMenusByFilter(ps, {menuType})
        return _.map(menuPointers, menuPtr => dataModel.getDataItemById(ps, menuPtr.id, 'masterPage'))
    }

    function isConnectedToMenuData(ps, menuId) {
        return function (comp) {
            const compData = dataModel.getDataItem(ps, comp)
            return compData.menuRef === `#${menuId}`
        }
    }

    const isMenuComponent = ps => ptr => !!ALLOWED_MENUS_AS_MAP[ps.dal.full.get(ps.pointers.getInnerPointer(ptr, 'componentType'))]

    function hasCompsConnectedToMenu(ps, menuId) {
        const desktop = componentDetectorAPI.getAllComponentsFromFull(ps, null, isMenuComponent(ps), VIEW_MODES.DESKTOP)
        const mobile = componentDetectorAPI.getAllComponentsFromFull(ps, null, isMenuComponent(ps), VIEW_MODES.MOBILE)
        return _.some(desktop.concat(mobile), isConnectedToMenuData(ps, menuId))
    }

    function validateNoCompsConnectedToMenu(ps, menuId) {
        if (hasCompsConnectedToMenu(ps, menuId)) {
            throw new Error(
                `Cannot remove menu with id ${menuId}, there are components connected to this menu. Please connect the other components to a differnt menu first.`
            )
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {String} menuId
     */
    function removeMenu(ps, menuId) {
        if (menuId === CUSTOM_MAIN_MENU) {
            throw new Error('You cannot delete the CUSTOM_MAIN_MENU.')
        }
        validateMenuExists(ps, menuId)
        validateNoCompsConnectedToMenu(ps, menuId)

        const customMenusDataItemPointer = ps.pointers.data.getDataItemFromMaster('CUSTOM_MENUS')
        const customMenus = ps.dal.full.get(customMenusDataItemPointer)

        _.pull(customMenus.menus, `#${menuId}`)
        ps.dal.set(customMenusDataItemPointer, customMenus)
        dataModel.removeItemRecursivelyByType(ps, ps.pointers.data.getDataItemFromMaster(menuId))
    }

    function addItem(ps, newMenuItemId, menuId, menuItem) {
        const menuDataForValidation = {items: [menuItem]}
        validateMenuDataDepth(menuDataForValidation)
        normalizeMenuItems(menuDataForValidation)
        const dataItem = dataModel.createDataItemByType(ps, 'BasicMenuItem')
        dataModel.addSerializedDataItemToPage(ps, 'masterPage', _.assign(dataItem, menuItem, {id: newMenuItemId}), newMenuItemId)

        const menuDataPointer = ps.pointers.data.getDataItemFromMaster(menuId)
        const menuItemsPointer = ps.pointers.getInnerPointer(menuDataPointer, 'items')
        ps.dal.push(menuItemsPointer, `#${newMenuItemId}`)
    }

    function removeTreeItemDeep(items, itemId, shouldKeepSubitems) {
        if (!items) {
            return items
        }

        return items.reduce((acc, item) => {
            if (item.id === itemId) {
                if (shouldKeepSubitems && item.items) {
                    acc = acc.concat(item.items)
                }
            } else {
                item.items = removeTreeItemDeep(item.items, itemId, shouldKeepSubitems)
                acc.push(item)
            }

            return acc
        }, [])
    }

    function removeItemInLang(ps, menuId, itemId, shouldKeepSubitems, useLanguage) {
        validateMenuExists(ps, menuId)
        const menuData = getMenuByIdInLang(ps, menuId, useLanguage)
        // @ts-ignore
        menuData.items = removeTreeItemDeep(menuData.items, itemId, shouldKeepSubitems)
        // @ts-ignore
        updateMenuInLang(ps, menuId, menuData, useLanguage)
    }

    function removeItem(ps, menuId, itemId, shouldKeepSubitems) {
        const originalLang = mlUtils.getLanguageByUseOriginal(ps, true)
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, false)

        if (originalLang !== useLanguage) {
            removeItemInLang(ps, menuId, itemId, shouldKeepSubitems, useLanguage)
        } else {
            // Remove in all languages
            removeItemInLang(ps, menuId, itemId, shouldKeepSubitems, originalLang)
            const presentLanguages = extensionsAPI.multilingualTranslations.getPresentLanguages(ps, menuId)
            presentLanguages.forEach(translatedLanguageCode => removeItemInLang(ps, menuId, itemId, shouldKeepSubitems, translatedLanguageCode))
        }

        dataModel.removeDataByPointer(ps, ps.pointers.data.getDataItem(itemId, 'masterPage'))
    }

    /**
     *
     * @param {ps} ps
     * @param menuId
     * @param itemId
     * @param partialMenuItem

     */
    function updateItem(ps, menuId, itemId, partialMenuItem) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, false)
        updateItemInLang(ps, menuId, itemId, partialMenuItem, useLanguage)
    }

    /**
     *
     * @param {ps} ps
     * @param menuId
     * @param itemId
     * @param partialMenuItem
     * @param {string|undefined} useLanguage the language code to use
     */
    function updateItemInLang(ps, menuId, itemId, partialMenuItem, useLanguage) {
        validateMenuExists(ps, menuId)
        const menuItemPointer = ps.pointers.data.getDataItemFromMaster(itemId)

        if (!ps.dal.isExist(menuItemPointer)) {
            throw new Error(`Menu item with id ${itemId} does not exist.`)
        }

        if (partialMenuItem.items && partialMenuItem.items.length) {
            const menuData = getMenuById(ps, menuId)
            // @ts-ignore
            if (!_.find(menuData.items, {id: itemId})) {
                //this means we will make it 2 levels deep
                throw new Error(`Updating menu item with id ${itemId} with items will cause the menu to be more than 2 levels deep. ${NO_SUPPORT_FOR_2_LEVELS}`)
            }
        }

        dataModel.addSerializedDataItemToPage(
            ps,
            ps.pointers.data.getPageIdOfData(menuItemPointer),
            _.assign(partialMenuItem, {
                id: itemId,
                type: 'BasicMenuItem'
            }),
            itemId,
            useLanguage
        )
    }

    /** Updates the menu with the provided menu data for the desired language
     *
     * @param {ps} ps Private Services instance
     * @param {string} languageCode the language code to use
     * @param {string} menuId The menu ID to update
     * @param {menuData} menuData The menu data to update
     */
    function multilingualMenuUpdate(ps, languageCode, menuId, menuData) {
        return updateMenuInLang(ps, menuId, menuData, languageCode)
    }

    /** gets the menu data for the desired language
     *
     * @param {ps} ps Private Services instance
     * @param {string} languageCode the language code to use
     * @param {string} menuId The menu ID to update
     */
    function multilingualMenuGet(ps, languageCode, menuId) {
        const menuWithOriginalLanguageOverides = getMenuByIdInLang(ps, menuId, languageCode)
        const originalLanguageCode = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
        if (originalLanguageCode === languageCode) {
            return menuWithOriginalLanguageOverides
        }

        const allMenuAndItemIds = menuUtils.getFlatMenuWithMetaData(ps, menuId, languageCode)
        return _.some(allMenuAndItemIds, {isTranslatedItem: true}) ? menuWithOriginalLanguageOverides : undefined
    }

    /**
     *
     * @param {ps} ps
     * @param {string} languageCode
     * @param {string} menuId
     * @param {string} itemId
     * @param {*} data
     */
    function multilingualItemUpdate(ps, languageCode, menuId, itemId, data) {
        return updateItemInLang(ps, menuId, itemId, data, languageCode)
    }

    return {
        update: updateMenu,
        create: createMenu,
        getMenuIdToCreate,
        getAll: getAllMenus,
        getById: getMenuById,
        getByType: getMenusByType,
        remove: removeMenu,
        connect: connectComponentToMenu,
        hasConnectedComps: hasCompsConnectedToMenu,
        addItem,
        removeItem,
        updateItem,
        updateItemInLang,
        multilingual: {
            get: multilingualMenuGet,
            update: multilingualMenuUpdate,
            updateItem: multilingualItemUpdate
        }
    }
})
