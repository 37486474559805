define(['documentServices/mobileConversion/mobileConversionFacade'], function (mobileConversionFacade) {
    'use strict'

    return function runMobileMergeOnAllPages(privateServices) {
        if (privateServices.siteAPI.isMobileView()) {
            return
        }
        const commitConversionResults = false
        mobileConversionFacade.runPartialConversionAllPages(privateServices, commitConversionResults)
    }
})
