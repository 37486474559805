define(['documentServices/appStudio/constants', 'documentServices/platform/platform', 'documentServices/appStudio/appStudioDataModel'], function (
    appStudioConstants,
    platform,
    appStudioDataModel
) {
    'use strict'

    const UPDATE_PUBLIC_API = 'updatePublicAPI'

    function updateApp(ps) {
        const {applicationId} = platform.getAppDataByAppDefId(ps, appStudioConstants.APP_BUILDER_PREVIEW_APP_ID) || {}

        if (applicationId) {
            platform.notifyApplication(ps, applicationId, {
                eventType: UPDATE_PUBLIC_API,
                eventPayload: {
                    widgets: appStudioDataModel.getAllSerializedWidgets(ps),
                    definitions: appStudioDataModel.getAllSerializedCustomDefinitions(ps)
                }
            })
        }
    }

    return {
        updateApp
    }
})
