import $ from 'zepto'
import _ from 'lodash'
import experiment from 'experiment'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import warmupUtilsLib from '@wix/santa-core-utils'

const menuUtils = warmupUtils.warmupMenuUtils

const moreButtonSuffix = '__more__'
const moreContainerPrefix = 'moreContainer'

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

function getLabelLineHeight(moreItemLineHeight, customMeasure) {
    return moreItemLineHeight + 15 + customMeasure.menuBorderY + customMeasure.labelPad + customMeasure.menuButtonBorder
}

function decideOnMenuPosition(id, measureMap, menuItemIds, hoveredId, hoveredListPosition, menuHeight, alignButtons, hoveredItem, moreContainerWidth) {
    const menuItemsIdsWithMore = menuItemIds.concat('__more__')
    const customMeasure = measureMap.custom[id]
    const {realWidths} = customMeasure
    const extraPixels = customMeasure.menuItemContainerExtraPixels
    const menuWidth = measureMap.width[id]
    const hoverIndex = _.findIndex(menuItemsIdsWithMore, function (itemId) {
        return itemId === hoveredId
    })

    if (hoverIndex >= 0 && realWidths) {
        const menuLeft = measureMap.absoluteLeft[id]
        const menuRight = menuLeft + menuWidth

        const menuPosition = getMenuPosition(
            extraPixels,
            alignButtons,
            moreContainerWidth,
            menuWidth,
            hoveredListPosition,
            hoveredItem,
            menuLeft,
            menuRight,
            measureMap.clientWidth
        )

        return {
            left: menuPosition.moreContainerLeft,
            right: menuPosition.moreContainerRight,
            top: customMeasure.needToOpenMenuUp ? 'auto' : `${menuHeight}px`,
            bottom: customMeasure.needToOpenMenuUp ? `${menuHeight}px` : 'auto'
        }
    }
}

function getMenuPosition(menuExtraPixels, alignButtons, moreContainerWidth, menuWidth, hoveredListPosition, hoveredItem, menuLeft, menuRight, clientWidth) {
    let moreContainerLeft: any = '0px'
    let moreContainerRight: any = 'auto'
    const hoveredNodeLeftOffset = hoveredItem.left
    const hoveredNodeWidthOffset = hoveredItem.width
    if (alignButtons === 'left') {
        if (hoveredListPosition === 'left') {
            moreContainerLeft = 0
        } else {
            moreContainerLeft = `${hoveredNodeLeftOffset + menuExtraPixels.left}px`
        }
    } else if (alignButtons === 'right') {
        if (hoveredListPosition === 'right') {
            moreContainerRight = 0
        } else {
            moreContainerRight = `${menuWidth - hoveredNodeLeftOffset - hoveredNodeWidthOffset - menuExtraPixels.right}px`
        }
        moreContainerLeft = 'auto'
    } else if (hoveredListPosition === 'left') {
        //center
        moreContainerLeft = `${hoveredNodeLeftOffset + (hoveredNodeWidthOffset + menuExtraPixels.left - moreContainerWidth) / 2}px`
    } else if (hoveredListPosition === 'right') {
        moreContainerLeft = 'auto'
        moreContainerRight = `${(hoveredNodeWidthOffset + menuExtraPixels.right - (moreContainerWidth + menuExtraPixels.width)) / 2}px`
    } else {
        moreContainerLeft = `${menuExtraPixels.left + hoveredNodeLeftOffset + (hoveredNodeWidthOffset - (moreContainerWidth + menuExtraPixels.width)) / 2}px`
    }

    if (moreContainerLeft !== 'auto') {
        const subMenuLeft = menuLeft + parseInt(moreContainerLeft, 10)
        moreContainerLeft = subMenuLeft < 0 ? 0 : moreContainerLeft
    }

    if (moreContainerRight !== 'auto') {
        const subMenuRight = menuRight - parseInt(moreContainerRight, 10)
        moreContainerRight = subMenuRight > clientWidth ? 0 : moreContainerRight
    }

    return {moreContainerLeft, moreContainerRight}
}

function patchDropDownMenuItems(id, patchers, measureMap, menuItemsIdsWithMore, realWidths, menuHeight, extraPixels) {
    // go over all the items (not the more)
    let totalVisible = 0
    let lastVisibleMenuId = null
    let innerLinkElementId = null
    const menuLineHeight = measureMap.custom[id].lineHeight[id]
    const menuItemHeight = menuHeight - extraPixels.height
    for (let i = 0; i < menuItemsIdsWithMore.length; i++) {
        const activeWidth = realWidths[i]
        const isVisible = activeWidth > 0
        const menuIndexOrMoreBecauseOfLegacyImplementation = menuItemsIdsWithMore[i]
        const menuId = id + menuIndexOrMoreBecauseOfLegacyImplementation
        innerLinkElementId = measureMap.custom[id].linkIds[menuId]
        if (isVisible) {
            totalVisible++
            lastVisibleMenuId = menuId
            patchers.css(menuId, {
                width: `${activeWidth}px`,
                height: `${menuItemHeight}px`,
                position: 'relative',
                'box-sizing': 'border-box',
                overflow: 'visible'
            })
            patchers.css(`${menuId}label`, {
                'line-height': menuLineHeight
            })
            patchers.attr(menuId, {
                'aria-hidden': false
            })
        } else {
            patchers.css(menuId, {height: '0px', overflow: 'hidden', position: 'absolute'}) //,"height":"0px"
            patchers.attr(menuId, {
                'aria-hidden': true
            })
            patchers.attr(innerLinkElementId, {
                tabIndex: -1
            })
        }
    }
    if (totalVisible === 1) {
        patchers.data(`${id}moreContainer`, {
            listposition: 'lonely'
        })
        patchers.data(lastVisibleMenuId, {
            listposition: 'lonely'
        })
    }
}

function updateDropDownContainerLocation(id, patchers, measureMap, maxLabelWidth) {
    const customMeasure = measureMap.custom[id]
    const {hoverState} = customMeasure
    if (hoverState !== '-1') {
        const {menuItemIds} = customMeasure
        const subItemsIndex = menuItemIds.indexOf(hoverState)
        if (isNumber(customMeasure.hoverState) || hoverState === moreButtonSuffix) {
            const measureMapRealWidths = measureMap.custom[id].realWidths
            if (!measureMapRealWidths) {
                return
            }
            const moreContainerWidth = Math.max(maxLabelWidth, measureMap.width[subItemsIndex !== -1 ? id + subItemsIndex : id + moreButtonSuffix]) // more container width is the max between text width and the more btn width
            const moreItemLineHeight = !_.isEmpty(customMeasure.moreSubItem) ? customMeasure.lineHeight[`${customMeasure.moreSubItem[0]}label`] : 0
            const newSubItemsLineHeight = getLabelLineHeight(moreItemLineHeight, customMeasure)

            /*set css for every menu item in the more container*/
            _.forEach(customMeasure.moreSubItem, function (subId) {
                patchers.css(subId, {'min-width': `${moreContainerWidth}px`})
                patchers.css(`${subId}label`, {'min-width': '0px', 'line-height': `${newSubItemsLineHeight}px`})
            })

            /*set container position and decide if it's open up.down*/
            const hoveredInd = isNumber(customMeasure.hoverState) ? customMeasure.hoverState : '__more__'
            const hoveredItem = {
                width: measureMap.width[id + hoveredInd],
                left: measureMap.left[id + hoveredInd]
            }

            const menuPosition = decideOnMenuPosition(
                id,
                measureMap,
                menuItemIds,
                hoverState,
                customMeasure.hoverListPosition,
                measureMap.height[id],
                customMeasure.alignButtons,
                hoveredItem,
                moreContainerWidth
            )
            patchers.css(id + moreContainerPrefix, {left: menuPosition.left, right: menuPosition.right})
            patchers.css(`${id}dropWrapper`, {
                left: menuPosition.left,
                right: menuPosition.right,
                top: menuPosition.top,
                bottom: menuPosition.bottom
            })
        }
    }
}

/**
 *
 * @param id
 * @param patchers
 * @param measureMap
 * @param ignored
 * @param {core.SiteData} siteData
 */
function patchDropDownMenu(id, patchers, measureMap, ignored, siteData) {
    // dropDownMenu displays ComboBoxInput for Members Area in mobile mode, which needs different adjustments
    if (siteData.isMobileView()) {
        const listId = `${id}navContainer`

        if (!measureMap.height[listId] || !measureMap.width[listId]) {
            return
        }

        patchers.css(listId, {
            height: measureMap.height[listId],
            width: measureMap.width[listId]
        })
        return
    }

    patchers.css(id, {'overflow-x': 'visible'})
    // measure widths to find out if there should be "more" and what would be the real widths
    // based on the comp properties
    const customMeasure = measureMap.custom[id]
    const {menuItemIds} = customMeasure
    const menuHeight = measureMap.height[id]
    const menuItemsIdsWithMore = menuItemIds.concat('__more__')
    setDropModeData(id, patchers, customMeasure.needToOpenMenuUp)
    const extraPixels = customMeasure.menuItemContainerExtraPixels
    let maxLabelWidth = 0

    if (customMeasure.hoverState === moreButtonSuffix) {
        //when hover more button - we render all item and hide the ones that already appear on the menu
        const firstIndexThatIsHidden = customMeasure.realWidths.indexOf(0)
        const firstItemShownInMore = customMeasure.menuItems[_.findKey(customMeasure.menuItems, ['menuIndex', firstIndexThatIsHidden])]
        const indexOfFirstItemShownInMore = firstItemShownInMore.moreIndex

        const hasOneItem = indexOfFirstItemShownInMore === menuItemIds.length - 1
        if (firstItemShownInMore.moreDOMid) {
            patchers.attr(firstItemShownInMore.moreDOMid, {'data-listposition': hasOneItem ? 'dropLonely' : 'top'})
        }

        _(customMeasure.menuItems)
            .filter(function (menuItem) {
                return !!menuItem.moreDOMid
            })
            .forEach(function (item) {
                if (item.moreIndex < indexOfFirstItemShownInMore) {
                    patchers.css(item.moreDOMid, {display: 'none'})
                } else {
                    const moreItemLabelId = `${item.moreDOMid}label`
                    maxLabelWidth = Math.max(measureMap.width[moreItemLabelId], maxLabelWidth)
                }
            })
    } else if (customMeasure.hoverState) {
        _.forEach(customMeasure.moreSubItem, function (v, i) {
            const subItemLabelId = `${id + moreContainerPrefix + i}label`
            maxLabelWidth = Math.max(measureMap.width[subItemLabelId], maxLabelWidth)
        })
    }
    updateDropDownContainerLocation(id, patchers, measureMap, maxLabelWidth)

    if (customMeasure.originalGapBetweenTextAndBtn) {
        _.forEach(menuItemsIdsWithMore, function (item) {
            if (!customMeasure.hasOriginalGapData[item]) {
                patchers.data(id + item, {
                    originalGapBetweenTextAndBtn: customMeasure.originalGapBetweenTextAndBtn[id + item]
                })
            }
        })
    }
    patchDropDownMenuItems(id, patchers, measureMap, menuItemsIdsWithMore, customMeasure.realWidths, menuHeight, extraPixels)
}

function checkForMarginMenu(itemsContainer) {
    const menuItem = itemsContainer.lastChild
    const marginLeft = parseInt($(menuItem).css('margin-left'), 10) || 0
    const marginRight = parseInt($(menuItem).css('margin-right'), 10) || 0
    return marginLeft + marginRight
}

function checkValidNumber(num) {
    const number = parseFloat(num)
    return isFinite(number) ? number : 0
}

function getDivExtraPixels(div, includeMargin) {
    const divComputedStyle = $(div).css([
        'border-top-width',
        'border-bottom-width',
        'border-left-width',
        'border-right-width',
        'padding-top',
        'padding-bottom',
        'padding-left',
        'padding-right',
        'margin-top',
        'margin-bottom',
        'margin-left',
        'margin-right'
    ])
    let top = checkValidNumber(divComputedStyle['border-top-width']) + checkValidNumber(divComputedStyle['padding-top'])
    let bottom = checkValidNumber(divComputedStyle['border-bottom-width']) + checkValidNumber(divComputedStyle['padding-bottom'])
    let left = checkValidNumber(divComputedStyle['border-left-width']) + checkValidNumber(divComputedStyle['padding-left'])
    let right = checkValidNumber(divComputedStyle['border-right-width']) + checkValidNumber(divComputedStyle['padding-right'])
    if (includeMargin) {
        top += checkValidNumber(divComputedStyle['margin-top'])
        bottom += checkValidNumber(divComputedStyle['margin-bottom'])
        left += checkValidNumber(divComputedStyle['margin-left'])
        right += checkValidNumber(divComputedStyle['margin-right'])
    }
    return {top, bottom, left, right, height: top + bottom, width: left + right}
}

function setDropModeData(menuId, patchers, isUp) {
    patchers.data(menuId, {
        dropmode: isUp ? 'dropUp' : 'dropDown'
    })
}

function needToOpenDropDownUp(win, menuCompDom) {
    const menuClientRect = menuCompDom.getBoundingClientRect()
    const topRelativeToWindow = menuClientRect.top
    return topRelativeToWindow > win.innerHeight / 2
}

function getDataIDs(id, nodesMap) {
    const numItems = +$(nodesMap[id]).data('numItems')
    if (!_.isFinite(numItems) || numItems <= 0 || numItems > Number.MAX_SAFE_INTEGER) {
        return []
    }

    return new Array(numItems).fill(0).map((num, i) => String(i))
}

function getChildrenIdToMeasure(compId, nodesMap, ignored, siteData) {
    // dropDownMenu displays ComboBoxInput for Members Area in mobile mode, which needs different adjustments
    if (siteData.isMobileView()) {
        return [['navContainer']]
    }

    const visiblePageIds = getDataIDs(compId, nodesMap).concat('__more__')
    const subChildren = [[]]
    let res = [['moreContainer'], ['itemsContainer'], ['dropWrapper']]
    _.forEach(subChildren, function (childArr) {
        res = res.concat(
            _.map(visiblePageIds, function (menuItemId) {
                return [menuItemId].concat(childArr)
            })
        )
    })
    return res
}

function getMenuItemsToPresent(id, measureMap, menuProperties, nodesMap, menuItemsIdsWithMore) {
    const menuWidth = measureMap.width[id]

    const customMeasure = measureMap.custom[id]
    customMeasure.hasOriginalGapData = {}
    customMeasure.originalGapBetweenTextAndBtn = {}
    const widths = _.map(menuItemsIdsWithMore, function (item) {
        const $menuItem = $(nodesMap[id + item])
        let gapBetweenTextAndBtn
        const originalGap = $menuItem.data('originalGapBetweenTextAndBtn')
        if (_.isUndefined(originalGap)) {
            customMeasure.hasOriginalGapData[item] = false
            gapBetweenTextAndBtn = measureMap.width[id + item] - customMeasure.labelWidths[`${id + item}label`]
            customMeasure.originalGapBetweenTextAndBtn[id + item] = gapBetweenTextAndBtn
        } else {
            customMeasure.hasOriginalGapData[item] = true
            gapBetweenTextAndBtn = parseInt(originalGap, 10)
        }
        if (measureMap.width[id + item] > 0) {
            return customMeasure.labelWidths[`${id + item}label`] + gapBetweenTextAndBtn
        }
        return 0
    })
    const moreWidth = widths.pop()
    const sameWidth = menuProperties.sameWidthButtons
    const stretch = menuProperties.stretchButtonsToMenuWidth
    let moreShown = false
    const menuWidthToReduce = customMeasure.menuItemContainerMargins
    const removeMarginFromAllChildren = customMeasure.menuItemMarginForAllChildren
    const extraPixels = customMeasure.menuItemContainerExtraPixels
    // check if it the menu can fit without "more"
    const maxWidth = menuUtils.getMaxWidth(widths)
    let realWidths = menuUtils.getDropDownWidthIfOk(
        menuWidth,
        sameWidth,
        stretch,
        widths,
        menuWidthToReduce,
        maxWidth,
        removeMarginFromAllChildren,
        extraPixels
    )
    if (!realWidths) {
        // find the menu with most items that work
        for (let i = 1; i <= widths.length; i++) {
            realWidths = menuUtils.getDropDownWidthIfOk(
                menuWidth,
                sameWidth,
                stretch,
                widths.slice(0, -1 * i).concat(moreWidth),
                menuWidthToReduce,
                maxWidth,
                removeMarginFromAllChildren,
                extraPixels
            )
            if (realWidths) {
                moreShown = true
                break
            }
        }
        if (!realWidths) {
            //found a case where the menu text was bigger then menu - more get the menu width
            moreShown = true
            realWidths = [moreWidth]
        }
    }
    if (moreShown) {
        const widthMore = realWidths[realWidths.length - 1]
        realWidths = realWidths.slice(0, -1)
        while (realWidths.length < menuItemsIdsWithMore.length) {
            realWidths.push(0)
        }
        realWidths[realWidths.length - 1] = widthMore
    }
    return {
        realWidths,
        moreShown
    }
}

function calculateLineHeight(menuHeight, customMeasure) {
    // @ts-ignore
    return warmupUtilsLib.style.unitize(
        menuHeight - customMeasure.menuBorderY - customMeasure.labelPad - customMeasure.ribbonEls - customMeasure.menuButtonBorder - customMeasure.ribbonExtra
    )
}

function getLabelWidth(win, labelNode) {
    const labelNodeComputedStyle = win.getComputedStyle(labelNode)
    return parseInt(labelNodeComputedStyle.width, 10) + parseInt(labelNodeComputedStyle.paddingLeft, 10) + parseInt(labelNodeComputedStyle.paddingRight, 10)
}
function measureMenu(win, id, measureMap, nodesMap, __, siteData) {
    // dropDownMenu displays ComboBoxInput for Members Area in mobile mode, which needs different adjustments
    if (siteData.isMobileView()) {
        measureMap.height[`${id}navContainer`] = measureMap.height[id]
        measureMap.width[`${id}navContainer`] = measureMap.width[id]
        return
    }

    const $menu = $(nodesMap[id])
    const itemsContainer = $(`#${id}itemsContainer`)[0] || nodesMap[`${id}itemsContainer`]
    const menuItems = itemsContainer.childNodes
    const moreContainer = nodesMap[`${id}moreContainer`]
    const moreChildNodes = moreContainer.childNodes

    const stretchButtonsToMenuWidth = $menu.data('stretchButtonsToMenuWidth')
    const sameWidthButtons = $menu.data('sameWidthButtons')

    /*add skin params to measure map*/
    const customMeasure: any = (measureMap.custom[id] = {
        alignButtons: $menu.data('dropalign'),
        hoverListPosition: $menu.data('drophposition'),
        menuBorderY: parseInt($menu.data('menuborder-y'), 10),
        ribbonExtra: parseInt($menu.data('ribbon-extra'), 10),
        ribbonEls: parseInt($menu.data('ribbon-els'), 10),
        labelPad: parseInt($menu.data('label-pad'), 10),
        menuButtonBorder: parseInt($menu.data('menubtn-border'), 10),
        menuItemContainerMargins: checkForMarginMenu(itemsContainer),
        menuItemContainerExtraPixels: getDivExtraPixels(itemsContainer, true),
        needToOpenMenuUp: needToOpenDropDownUp(win, nodesMap[id]),
        menuItemMarginForAllChildren: !stretchButtonsToMenuWidth || itemsContainer.getAttribute('data-marginAllChildren') !== 'false'
    })
    customMeasure.moreSubItem = []
    customMeasure.lineHeight = {}
    customMeasure.labelWidths = {}
    customMeasure.linkIds = {}
    customMeasure.parentId = {}
    customMeasure.menuItems = {}

    /*add more container menu items + menu items labels to custom measure map + nodes map*/
    _.forEach(moreChildNodes, function (moreChild, i) {
        const $child = $(moreChild)
        customMeasure.parentId[moreChild.id] = $child.data('parent-id')
        const dataId = $child.data('data-id')

        customMeasure.menuItems[dataId] = {
            dataId,
            parentId: $child.data('parent-id'),
            moreDOMid: moreChild.id,
            moreIndex: i
        }

        nodesMap[moreChild.id] = moreChild
        const labelNode = $(moreChild).find('p')[0]
        nodesMap[labelNode.id] = labelNode
        measureMap.width[labelNode.id] = labelNode.offsetWidth
        measureMap.height[labelNode.id] = labelNode.offsetHeight
        measureMap.left[labelNode.id] = labelNode.offsetLeft
        customMeasure.lineHeight[labelNode.id] = parseInt(win.getComputedStyle(labelNode).fontSize, 10)
        customMeasure.moreSubItem.push(moreChild.id)
    })

    /*add menu items left to measure map + add the labels to nodes map*/
    _.forEach(menuItems, function (menuItem, i) {
        const $item = $(menuItem)
        const dataId = $item.data('data-id')

        customMeasure.menuItems[dataId] = customMeasure.menuItems[dataId] || {}
        customMeasure.menuItems[dataId].menuIndex = i
        customMeasure.menuItems[dataId].menuDOMid = menuItem.id

        measureMap.left[menuItem.id] = menuItem.offsetLeft
        const labelNode = $(menuItem).find('p')[0]
        nodesMap[labelNode.id] = labelNode
        customMeasure.labelWidths[labelNode.id] = getLabelWidth(win, labelNode)
        const linkElementNode = $(menuItem).find('a')[0]
        nodesMap[linkElementNode.id] = linkElementNode
        customMeasure.linkIds[menuItem.id] = linkElementNode.id
    })

    const menuHeight = measureMap.height[id]
    customMeasure.lineHeight[id] = calculateLineHeight(menuHeight, customMeasure)
    const menuItemIds = getDataIDs(id, nodesMap)
    const arrayWidths = getMenuItemsToPresent(id, measureMap, {sameWidthButtons, stretchButtonsToMenuWidth}, nodesMap, menuItemIds.concat('__more__'))

    customMeasure.realWidths = arrayWidths.realWidths
    customMeasure.isMoreShown = arrayWidths.moreShown
    customMeasure.menuItemIds = menuItemIds
    customMeasure.hoverState = moreContainer.getAttribute('data-hover')
}

// In ssr the `window` object will not be available and simpler ways to check for its existence,
// such as `_.isUndefined`, will fail.
const isInSsr = typeof window !== 'object'
const measureMenuWithDefaultWindow = isInSsr ? _.noop : _.partial(measureMenu, window)

function register(layoutUtils) {
    const isClientSideBolt = () => !isInSsr && _.get(window, 'wixBiSession.renderType') === 'bolt'
    const isUsingWixDropDown = () => experiment.isOpen('bv_wixDropdown') && isClientSideBolt()

    if (!isUsingWixDropDown()) {
        layoutUtils.registerPatcher('wysiwyg.viewer.components.menus.DropDownMenu', patchDropDownMenu)
        layoutUtils.registerCustomMeasure('wysiwyg.viewer.components.menus.DropDownMenu', measureMenuWithDefaultWindow)
        layoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.menus.DropDownMenu', getChildrenIdToMeasure)
    }
}

export default {
    register,
    patch: patchDropDownMenu,
    measure: measureMenu
}
