import type {Action} from './serverProtocol'
import _ from 'lodash'
import type {Namespaces, Namespace} from '../../types'
import {DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES} from '../../constants/constants'

type NamespacesOp = (data: Namespaces) => Namespaces

const arraysHaveItemsInCommon = <T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): boolean => _.intersection(a, b).length > 0

/** These namespaces should be included in csave transactions in their entirety */
const COMPLETELY_ALLOWED_NAMESPACES: ReadonlyArray<string> = _.flatMap([DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES], _.values).concat([
    'orphanPermanentDataNodes',
    'pagesPlatformApplications',
    'wixCodeSharedFileState',
    'wixCodeWaitForApproval',
    'tpaSharedState',
    'platformSharedManifestState',
    'platformSharedAPIState',
    'appsState',
    'livePreviewSharedState'
])

/** The keys in this object are namespaces that are only partially allowed.
 * Each key contains an array with the ids that are allowed in that namespace.
 */
const PARTIALLY_ALLOWED_NAMESPACES: Record<string, ReadonlyArray<string>> = {
    rendererModel: ['pageToHashedPassword', 'passwordProtectedPages', 'siteMetaData', 'wixCodeModel', 'routers', 'clientSpecMapCacheKiller'],
    documentServicesModel: ['metaSiteData', 'customHeadTags', 'versionInfo'],
    platform: ['appState']
}

if (arraysHaveItemsInCommon(_.keys(PARTIALLY_ALLOWED_NAMESPACES), COMPLETELY_ALLOWED_NAMESPACES)) {
    throw new Error('PARTIALLY_ALLOWED_NAMESPACES and COMPLETELY_ALLOWED_NAMESPACES should not contain namespaces in common')
}

const NAMESPACES_TO_KEEP: ReadonlyArray<string> = [...COMPLETELY_ALLOWED_NAMESPACES, ..._.keys(PARTIALLY_ALLOWED_NAMESPACES)]

const removeExcludedKeysFromNamespace = (data: Namespace, name: string): Namespace => {
    const allowedKeys = PARTIALLY_ALLOWED_NAMESPACES[name]
    if (allowedKeys) {
        return _.pick(data, allowedKeys)
    }
    return data
}

const removeExcludedNamespaces: NamespacesOp = data => _.pick(data, NAMESPACES_TO_KEEP)

const removeExcludedKeysFromNamespaces: NamespacesOp = data => _.mapValues(data, removeExcludedKeysFromNamespace)

const removeExclusions: NamespacesOp = _.flow([removeExcludedNamespaces, removeExcludedKeysFromNamespaces])

const allowDiff = ({namespace, id}: Action): boolean => {
    const partial = PARTIALLY_ALLOWED_NAMESPACES[namespace!]
    const allowedByPartial = partial && _.includes(partial, id)
    const allowedByComplete = _.includes(COMPLETELY_ALLOWED_NAMESPACES, namespace)
    return allowedByPartial || allowedByComplete
}

const removeExclusionsFromDiffs = (diffs: Action[]): Action[] => _.filter(diffs, allowDiff)

const removeTransientData = (diffs: Action[]): Action[] => _.reject(diffs, {namespace: 'documentServicesModel', id: 'versionInfo'})

const removeFromLoadStore: (diffs: Action[]) => Action[] = _.flow([removeTransientData, removeExclusionsFromDiffs])

export {removeFromLoadStore, removeExclusionsFromDiffs, removeExclusions}
