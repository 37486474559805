import {DocumentDataTypes, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils
const pointerType = 'platform'
const pagesPlatformPointerType = 'pagesPlatformApplications'
const semanticAppVersionsPointerType = 'semanticAppVersions'
const appsStatePointerType = 'appsState'

const createPointersMethods = (): PointerMethods => {
    const getPlatformPointer = () => getPointer('platform', pointerType)
    const getAppManifestPointer = (appDefinitionId: string) => getPointer('platform', pointerType, ['appManifest', appDefinitionId])
    const appPublicApiNamePointer = (appDefinitionId: string) => getPointer('platform', pointerType, ['appPublicApiName', appDefinitionId])
    const appPrivateApiNamePointer = (appDefinitionId: string) => getPointer('platform', pointerType, ['appPrivateApiName', appDefinitionId])
    const appEditorApiNamePointer = (appDefinitionId: string) => getPointer('platform', pointerType, ['appEditorApiName', appDefinitionId])
    const getAppStatePointer = () => getPointer('appState', pointerType)
    const getControllerStatePointer = (controllerId: string) => getPointer('appState', pointerType, [controllerId])
    const getControllerStageDataPointer = (appDefinitionId: string, controllerType: string, controllerState: string) =>
        getPointer('platform', pointerType, ['appManifest', appDefinitionId, 'controllersStageData', controllerType, controllerState])
    const getControllerRolePointer = (appDefinitionId: string, controllerType: string, controllerState: string, role: string) =>
        getPointer('platform', pointerType, ['appManifest', appDefinitionId, 'controllersStageData', controllerType, controllerState, 'connections', role])

    const getPagesPlatformApplicationsPointer = () => getPointer('pagesPlatformApplications', pagesPlatformPointerType)
    const getPagesPlatformApplicationPointer = (appDefinitionId: string) => getPointer('pagesPlatformApplications', pagesPlatformPointerType, [appDefinitionId])

    const getSemanticAppVersionPointer = (appDefinitionId: string) => getPointer('semanticAppVersions', semanticAppVersionsPointerType, [appDefinitionId])
    const getSemanticAppVersionsPointer = () => getPointer('semanticAppVersions', semanticAppVersionsPointerType)

    const getAppInstallationStatePointer = (appDefinitionId: string) => getPointer('appsState', appsStatePointerType, [appDefinitionId])
    const getAppsInstallationStatePointer = () => getPointer('appsState', appsStatePointerType)

    return {
        platform: {
            getControllerRolePointer,
            getPlatformPointer,
            getAppManifestPointer,
            appPublicApiNamePointer,
            appPrivateApiNamePointer,
            appEditorApiNamePointer,
            getPagesPlatformApplicationPointer,
            getPagesPlatformApplicationsPointer,
            getControllerStageDataPointer,
            getControllerStatePointer,
            getAppStatePointer,
            getSemanticAppVersionPointer,
            getSemanticAppVersionsPointer,
            getAppInstallationStatePointer,
            getAppsInstallationStatePointer
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pointerType]: {},
    [pagesPlatformPointerType]: {},
    [appsStatePointerType]: {}
})

const initialState = {
    [appsStatePointerType]: {
        [appsStatePointerType]: {}
    }
}

const createExtension = (): Extension => ({
    name: 'platform',
    createPointersMethods,
    getDocumentDataTypes,
    initialState
})

export {createExtension}
