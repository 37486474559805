import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import wixappsClassicsLogger from './bi/wixappsClassicsLogger'
import componentUtils from './components/componentUtils'
import countryCodes from './core/countryCodes.json'
import svg from './core/svg'
import media from './core/media'
import filterHtmlString from './core/filterHtmlString'
import behaviorsService from './core/behaviorsService'
import behaviorHandlersFactory from './core/behaviorHandlersFactory'
import animationsService from './core/animationsService'
import santaTypes from './core/santaTypes'
import propsSelectorsUtils from './core/propsSelectorsUtils'
import staticValue from './core/staticValue'
import styleCollector from './core/styleCollector'
import colorParser from './core/colorParser'
// import dataCapsuleWrapper from './core/integrations/dataCapsuleWrapper'
// import promoteAnalytics from './core/integrations/promoteAnalytics'
// import fullStory from './core/integrations/fullStory'
// import ravenUtils from './core/integrations/ravenUtils'
import triggerTypesConsts from './core/triggerTypesConsts'
import linkRenderer from './core/linkRenderer'
import linkTypes from './core/linkTypes'
import linkUtils from './core/linkUtils'
import menuUtils from './core/menuUtils'
import fontUtils from './core/fontUtils'
import translationsLoader from './core/translationsLoader'
import masterPageLayoutUtils from './core/masterPageLayoutUtils'
import originalValuesMapGenerator from './core/originalValuesMapGenerator'
import componentsAnchorsMetaData from './core/componentsAnchorsMetaData'
import layoutAnchors from './core/layoutAnchorsUtils'
import wixUserApi from './core/wixUserApi'
import scrollAnchors from './core/scrollAnchorsUtils'
import renderUtils from './core/renderUtils'
import scriptsOverridesService from './core/scriptsOverridesService'
import componentVisibility from './core/componentVisibility'
import fileUploadManager from './core/fileUploadManager'
import externalScriptRegistery from './core/externalScriptRegistery'
import fullToDisplayedJson from './core/fullToDisplayedJson'
import jsonUpdaterRegistrar from './core/jsonUpdaterRegistrar'
import mobileUtils from './core/mobileUtils'
import modesUtils from './core/modesUtils'
import structureDimensions from './core/structureDimensions'
import stubPageUtil from './core/stubPageUtil'
import versionUtils from './core/versionUtils'
import socialShareHandler from './core/socialShareHandler'
import socialCounterDatabaseAPI from './core/socialCounterDatabaseAPI'
import compsToPackages from './core/compsToPackages'
import browserUtils from './core/browserUtils'

const coreUtils = {
    componentUtils,
    countryCodes,
    svg,
    media,
    filterHtmlString,
    behaviorsService,
    behaviorHandlersFactory,
    animationsService,
    santaTypes,
    propsSelectorsUtils,
    staticValue,
    styleCollector,
    colorParser,
    integrations: {
        dataCapsuleWrapper: {},
        promoteAnalytics: {},
        fullStory: {},
        ravenUtils: {
            // TODO fix
            breadcrumb: () => {},
            captureError: () => {}
        }
    },
    triggerTypesConsts,
    linkRenderer,
    linkTypes,
    linkUtils,
    menuUtils,
    fontUtils,
    translationsLoader,
    masterPageLayoutUtils,
    originalValuesMapGenerator,
    getComponentsAnchorsMetaData: componentsAnchorsMetaData.get,
    layoutAnchors,
    wixUserApi,
    scrollAnchors,
    Store: coreUtilsLib.StoreNew,
    renderUtils,
    scriptsOverridesService,
    componentVisibility,
    fileUploadManager,
    externalScriptRegistery,
    fullToDisplayedJson,
    jsonUpdaterRegistrar,
    mobileUtils,
    modesUtils,
    structureDimensions,
    stubPageUtil,
    versionUtils,
    wixappsClassicsLogger,
    socialShareHandler,
    socialCounterDatabaseAPI,
    getPackagesForComponent: compsToPackages.compToPackages,
    allPackages: compsToPackages.all,
    // @ts-ignore
    allButNativeComponents: compsToPackages.allButNativeComponents,
    packagesToCallbacks: compsToPackages.packagesToCallbacks,
    getPackagesToCallbacksForComponent: compsToPackages.getPackagesToCallbacksForComponent,
    getAllPackagesForAllComponents: compsToPackages.getAllPackagesForAllComponents,
    getCallbackForRequiredPackage: compsToPackages.getCallbackForRequiredPackage,
    browserUtils
}

export default _.assign({}, coreUtilsLib, warmupUtils, coreUtils)
