define([], function () {
    'use strict'

    const registeredComponentTypes = {}

    /**
     * Default resolver for comp types that does not have a registered resolver
     * @returns [] - empty array, no additional comps
     */
    const defaultResolver = () => []

    /**
     * Register for resolving additional components for methods that allows is by comp type
     * @param componentType - comp type that method will be executed for
     * @param resolver - a methods that receives (ps, compPointer) and returns components to be resolved according to pointer
     */
    const registerAdditionalComponentsResolver = (componentType, resolver) => {
        registeredComponentTypes[componentType] = resolver
    }

    /**
     * Returns additional components to be resolved by registered method if registered for the comp type of the supplied comp pointer
     * Checks if comp type of the comp pointer has a registered method, if so execute it and return the value, else execute default method
     * @param {ps} ps - private services
     * @param compPointer - component pointer to check its type and pass to the resolver
     * @returns [] - array of additional components pointer to be resolved base on comp pointer
     */
    const resolveAdditionalComponents = (ps, compPointer) => {
        const compType = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
        const resolver = registeredComponentTypes[compType] || defaultResolver
        return resolver(ps, compPointer)
    }

    return {
        registerAdditionalComponentsResolver,
        resolveAdditionalComponents
    }
})
