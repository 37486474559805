const {ReportableError} = require('@wix/document-manager-utils')
define([
    'lodash',
    'pm-rpc',
    'documentServices/platform/services/sdkAPIService',
    'documentServices/platform/core/workerFactory',
    'documentServices/platform/core/messageFormatter',
    'documentServices/platform/common/constants',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/platform/services/platformAppDataGetter',
    'documentServices/platform/services/apiCallBiService',
    'documentServices/bi/bi',
    'documentServices/bi/events.json',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/platform/services/reloadAppService',
    'documentServices/platform/services/platformStateService',
    'documentServices/utils/contextAdapter',
    'tpa',
    'platformInit'
], function (
    _,
    rpc,
    sdkAPIService,
    workerFactory,
    messageFormatter,
    constants,
    siteMetadata,
    documentModeInfo,
    platformAppDataGetter,
    apiCallBiService,
    bi,
    biEvents,
    extensionsAPI,
    clientSpecMapService,
    reloadAppService,
    platformStateService,
    contextAdapter,
    tpa,
    platformInit
) {
    'use strict'

    let _appsContainer
    let editorSDKrpcSet = false
    let callbackQueue = {}
    const messageQueue = []
    let applicationMessagesQueue = {}
    let isWorkerReadyToReceiveMessages = false
    let isAppsCompleted = false
    let isAppReadyToReceiveMessages = {}
    let isAddAppStarted = {}
    let platformInitiated = false

    function didMessageArriveFromWorker(event) {
        return event.target === _appsContainer
    }

    const handleAddAppCompleted = event => {
        const {applicationId} = event.data
        isAppReadyToReceiveMessages[applicationId] = true
        _.forEach(applicationMessagesQueue[applicationId], sendMessageToWorker)
        delete applicationMessagesQueue[applicationId]
        const callbacks = _.get(callbackQueue, [constants.MessageTypes.ADD_APP_COMPLETED, applicationId], [])
        callbacks.forEach(callback => {
            if (_.isFunction(callback)) {
                callback(event.data)
            }
        })
        _.unset(callbackQueue, [constants.MessageTypes.ADD_APP_COMPLETED, applicationId])
    }

    const handleAllAppsCompleted = event => {
        if (event.data.success === true) {
            isAppsCompleted = true
            const callbacks = _.get(callbackQueue, [constants.MessageTypes.ADD_ALL_APPS_COMPLETED], [])
            callbacks.forEach(callback => {
                if (_.isFunction(callback)) {
                    callback()
                }
            })
        }
    }

    const setAppCompletedCallback = (applicationId, cb) => {
        const callbacks = _.get(callbackQueue, [constants.MessageTypes.ADD_APP_COMPLETED, applicationId], [])
        _.set(callbackQueue, [constants.MessageTypes.ADD_APP_COMPLETED, applicationId], [...callbacks, cb])
    }

    function reloadPlatformApplication(ps, {appDefinitionId, cachedEditorReadyOptions, registeredEvents}) {
        const editorScriptHot = ps.siteAPI.getQueryParam('editorScriptHot') === 'true'
        if (editorScriptHot) {
            const appToAdd = reloadAppService.reloadApplication(
                ps,
                {
                    appDefinitionId,
                    cachedEditorReadyOptions: JSON.parse(cachedEditorReadyOptions),
                    registeredEvents: registeredEvents.split(',')
                },
                enhanceAppWithEditorScript(ps)
            )
            addApp(ps, appToAdd, _.noop, 'reloadPlatformApplication')
        }
    }

    function handleMessageFromWorker(ps, event) {
        if (didMessageArriveFromWorker(event)) {
            switch (event.data.type) {
                case constants.MessageTypes.RELOAD_PLATFORM_APPLICATION:
                    reloadPlatformApplication(ps, event.data)
                    break
                case constants.MessageTypes.SDK_READY:
                    isWorkerReadyToReceiveMessages = true
                    _.forEach(messageQueue, sendMessageToWorker)
                    messageQueue.length = 0
                    break
                case constants.MessageTypes.SET_MANIFEST:
                    const appDefinitionId = _.get(platformAppDataGetter.getAppDataByApplicationId(ps, event.data.applicationId), 'appDefinitionId')
                    platformAppDataGetter.setManifest(ps, appDefinitionId, event.data.manifest)
                    if (event.data.firstInstall === false) {
                        extensionsAPI.snapshots.clearUndoRedo()
                    }
                    if (event.data.origin === 'reloadManifest') {
                        ps.setOperationsQueue.runSetOperation(() => {
                            extensionsAPI.platformSharedState.notifyManifestWasChanged(ps, appDefinitionId)
                        })
                    }
                    break
                case constants.MessageTypes.SET_APP_EXPORTED_APIS:
                    platformAppDataGetter.setAppExportedAPIs(ps, event.data.appDefinitionId, event.data.apisNames)
                    isAppReadyToReceiveMessages[event.data.applicationId] = true
                    break
                case constants.MessageTypes.ADD_APP_COMPLETED: {
                    handleAddAppCompleted(event)
                    break
                }
                case constants.MessageTypes.ADD_ALL_APPS_COMPLETED: {
                    handleAllAppsCompleted(event)
                    break
                }
                case constants.MessageTypes.GET_INSTALLED_APPS:
                    const installedApps = getInstalledApps(ps)
                    if (event.ports && event.ports[0] && _.isFunction(event.ports[0].postMessage)) {
                        event.ports[0].postMessage(installedApps.map(appData => appData.applicationId))
                    }
                    break
                case constants.MessageTypes.GET_IS_PLATFORM_APP_INSTALLED:
                    const isPlatformAppInstalled = platformAppDataGetter.isPlatformAppInstalled(ps, event.data.appDefinitionId)
                    if (event.ports && event.ports[0]) {
                        event.ports[0].postMessage({isAppInstalled: isPlatformAppInstalled})
                    }
                    break
                default:
                    break
            }
        }
    }

    function reportWorkerCreation(ps) {
        if (window.performance && window.performance.measure && window.performance.mark) {
            window.performance.mark('platformWorkerCreated')
            const timeForWorker = window.performance.measure('timeUntilWorkerReady', 'documentServicesLoaded', 'platformWorkerCreated')
            bi.event(ps, biEvents.PLATFORM_WORKER_LOADED, {
                dsOrigin: ps.config.origin,
                viewerName: ps.runtimeConfig.viewerName,
                ts: Math.round(_.get(timeForWorker, 'duration'))
            })
        }
    }

    function init(ps, apiOverride, config) {
        if (_appsContainer) {
            clearWorker()
        }

        platformInitiated = true
        let appsContainerUrl = workerFactory.getAppsContainerUrl(ps, config)
        const isLocalWorker = workerFactory.isLocalWorker(appsContainerUrl)
        if (isLocalWorker) {
            appsContainerUrl = workerFactory.createWorkerBlobUrl(appsContainerUrl)
        }
        _appsContainer = new Worker(appsContainerUrl)
        setEditorSDKRpc(ps, apiOverride)
        _appsContainer.postMessage(messageFormatter.initialize(workerFactory.getAppsWorkerInitializeParams(ps, config), getInitData(ps)))

        reportWorkerCreation(ps)

        // @ts-ignore
        workerFactory.addWorkerHandler(handleMessageFromWorker.bind(this, ps), _appsContainer)
        return _appsContainer
    }

    function setEditorSDKRpc(ps, apiOverride) {
        if (editorSDKrpcSet) {
            return
        }

        rpc.api.set(constants.AppIds.EDITOR, sdkAPIService.getAPIForSDK(apiOverride), {onApiCall: apiCallBiService.reportAPICallFedOps.bind(null, ps)}, [
            _appsContainer
        ])

        editorSDKrpcSet = true
    }

    function getEditorSdkUrl(ps) {
        return workerFactory.getEditorSdkUrl(ps)
    }

    function clearWorker() {
        if (_appsContainer.terminate) {
            _appsContainer.terminate()
        }

        messageQueue.length = 0
        callbackQueue = {}
        applicationMessagesQueue = {}
        isAppReadyToReceiveMessages = {}
        isAddAppStarted = {}
        isWorkerReadyToReceiveMessages = false
    }

    function sendMessageToWorker(message) {
        _appsContainer.postMessage(message)
    }

    function sendMessageToWorkerWhenPossible(message) {
        if (isWorkerReadyToReceiveMessages) {
            sendMessageToWorker(message)
        } else {
            messageQueue.push(message)
        }
    }

    function sendAppMessageToWorkerWhenPossible(ps, message, applicationId) {
        const appData = platformAppDataGetter.getAppDataByApplicationId(ps, applicationId)
        if (clientSpecMapService.isAppPermissionsIsRevoked(appData, platformStateService.getAppsState(ps))) {
            return
        }
        if (isWorkerReadyToReceiveMessages && isAppReadyToReceiveMessages[applicationId]) {
            sendMessageToWorker(message)
        } else {
            applicationMessagesQueue[applicationId] = applicationMessagesQueue[applicationId] || []
            applicationMessagesQueue[applicationId].push(message)
        }
    }

    function triggerEvent(ps, applicationId, options) {
        sendAppMessageToWorkerWhenPossible(ps, messageFormatter.triggerEvent(applicationId, options), applicationId)
    }

    const getInitData = ps => ({
        languageCode: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en',
        viewMode: documentModeInfo.getViewMode(ps),
        metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
        userId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_INFO)?.userId,
        editorSessionId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.EDITOR_SESSION_ID)
    })

    function addApps(ps, apps) {
        _.forEach(apps, appData => {
            isAddAppStarted[appData.applicationId] = true
            const enhancedAppData = enhanceAppWithEditorScript(ps)(appData)
            addAppCallbackWrapper(ps, enhancedAppData)
        })
        sendMessageToWorkerWhenPossible(messageFormatter.addApps(apps, getInitData(ps)))
    }

    function addApp(ps, appData, callback, options = {}) {
        if (!appData?.instance && ![constants.APPS.DATA_BINDING.appDefId, constants.APPS.DYNAMIC_PAGES.appDefId].includes(appData.appDefinitionId)) {
            const error = new ReportableError({
                message: `Instance missing for applicationId: ${appData.applicationId}`,
                errorType: 'TPAInstanceMissing',
                extras: {
                    applicationId: appData.applicationId,
                    appData,
                    origin: options.origin,
                    internalOrigin: options.internalOrigin
                }
            })
            _.set(error, ['extras', 'stackTrace'], error.stack)
            contextAdapter.utils.fedopsLogger.captureError(error)
        }
        isAddAppStarted[appData.applicationId] = true
        const enhancedAppData = enhanceAppWithEditorScript(ps)(appData)
        addAppCallbackWrapper(ps, enhancedAppData, callback)
        sendMessageToWorkerWhenPossible(messageFormatter.addApp(enhancedAppData, getInitData(ps)))
    }

    function addAppCallbackWrapper(ps, appData, callback) {
        const originalCallback = _.isFunction(callback) ? callback : _.noop
        setAppCompletedCallback(
            appData.applicationId,
            appData.firstInstall
                ? eventData =>
                      notifyAppInstalled(ps, appData).then(() => {
                          originalCallback(eventData)
                      })
                : originalCallback
        )
    }

    function loadManifest(ps, appData) {
        sendMessageToWorkerWhenPossible(messageFormatter.loadManifest(appData, getInitData(ps)))
    }

    function notifyAppInstalled(ps, {appDefinitionId, biData = {}}) {
        return notifyAppsAsync(ps, constants.NotifyMethods.APP_INSTALLED, {appDefinitionId, biData})
    }

    function notifyAppUpdated(ps, applicationId, options) {
        return notifyAppAsync(ps, applicationId, constants.NotifyMethods.APP_UPDATED, options)
    }

    async function notifyRemoveApp(ps, applicationId) {
        const appDefinitionId = _.get(platformAppDataGetter.getAppDataByApplicationId(ps, applicationId), 'appDefinitionId')
        await notifyAppAsync(ps, applicationId, constants.NotifyMethods.REMOVE_APP, {})
        sendAppMessageToWorkerWhenPossible(ps, messageFormatter.removeApp(applicationId, appDefinitionId), applicationId)
        return notifyAppsAsync(ps, constants.NotifyMethods.REMOVE_APP_COMPLETED, {appDefinitionId})
    }

    function notifyBeforeRemoveApp(ps, applicationId) {
        return notifyAppAsync(ps, applicationId, constants.NotifyMethods.BEFORE_REMOVE_APP, {})
    }

    function notifyMigrate(ps, applicationId, payload) {
        return notifyAppAsync(ps, applicationId, constants.NotifyMethods.MIGRATE, payload)
    }

    function notifyBeforeWidgetAdded(ps, applicationId, payload) {
        return notifyAppAsync(ps, applicationId, constants.NotifyMethods.BEFORE_WIDGET_ADDED, payload)
    }

    function isInitiated() {
        return platformInitiated
    }

    function registerToAppsCompleted(callback) {
        if (isAppsCompleted) {
            callback()
        }
        const callbacksArr = _.get(callbackQueue, [constants.MessageTypes.ADD_ALL_APPS_COMPLETED])
        if (callbacksArr) {
            callbacksArr.push(callback)
        } else {
            _.set(callbackQueue, [constants.MessageTypes.ADD_ALL_APPS_COMPLETED], [callback])
        }
    }

    function notifyAppsAsync(ps, methodName, methodPayload) {
        const appIds = getInstalledApps(ps).map(appData => appData.applicationId)
        return Promise.all(appIds.reduce((acc, applicationId) => [...acc, notifyAppAsync(ps, applicationId, methodName, methodPayload)], []))
    }

    function notifyAppAsync(ps, applicationId, methodName, methodPayload) {
        return new Promise((resolve, reject) => {
            const notify = () => {
                const appDefinitionId = _.get(platformAppDataGetter.getAppDataByApplicationId(ps, applicationId), 'appDefinitionId')
                const apiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
                const apiName = ps.dal.get(apiNamePointer)
                if (!apiName) {
                    return resolve({})
                }
                return requestAPIFromWorker(ps, apiName)
                    .then(editorApi => editorApi[methodName](methodPayload))
                    .then(resolve)
                    .catch(reject)
            }
            if (isAppReadyToReceiveMessages[applicationId]) {
                notify()
            } else {
                setAppCompletedCallback(applicationId, notify)
            }
        })
    }

    function getInstalledApps(ps) {
        return clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
            Object.values(csm).filter(
                appData =>
                    appData.appDefinitionId &&
                    isAddAppStarted[appData.applicationId] &&
                    clientSpecMapService.isAppActive(ps, appData) &&
                    clientSpecMapService.hasEditorPlatformPart(appData) &&
                    !clientSpecMapService.isDashboardAppOnly(appData)
            )
        )
    }

    function requestAPIFromWorker(ps, apiName) {
        return rpc.api.request(apiName, {target: _appsContainer})
    }

    function enhanceAppWithEditorScript(ps) {
        return function resolveEditorScript(app) {
            const currentUrl = ps.siteAPI.getCurrentUrl()
            const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
            const editorScriptOverrideUrl = tpa.common.utils.getTpaOverrideMap(currentUrl, 'editorScriptUrlOverride')[app.appDefinitionId]
            const withEditorScript = _.get(app, 'appFields.platform.editorScriptUrl')
            if (editorScriptOverrideUrl && withEditorScript) {
                app = _.merge({}, app, {
                    appFields: {
                        platform: {
                            editorScriptUrl: editorScriptOverrideUrl
                        }
                    }
                })
            }
            return platformInit.specMapUtils.resolveEditorScriptUrl(app, {clientSpec: app, serviceTopology})
        }
    }

    function getNotInitError(ps, additionalMessage) {
        const err = new Error(`Worker Service is not initiated ${additionalMessage}`)
        contextAdapter.utils.fedopsLogger.captureError(err, {
            tags: {
                workerNotInit: true
            },
            extras: {
                originalError: err
            }
        })
        return err
    }

    return {
        init,
        getNotInitError,
        triggerEvent,
        addApp,
        addApps,
        loadManifest,
        notifyAppInstalled,
        notifyAppUpdated,
        notifyRemoveApp,
        notifyMigrate,
        notifyBeforeWidgetAdded,
        notifyBeforeRemoveApp,
        isInitiated,
        registerToAppsCompleted,
        requestAPIFromWorker,
        getEditorSdkUrl,
        enhanceAppWithEditorScript
    }
})
