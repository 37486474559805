import {getPointer} from './pointerUtils'
const pointerType = 'renderRealTimeConfigPointer'

const createPointersMethods = () => {
    const getRenderRealtimeConfigItem = (item: string) => getPointer(item, pointerType)
    const getIsCompHiddenPointer = (compId: string, viewMode: string) => getPointer(['compsToHide', viewMode, compId].join('_'), pointerType)
    return {
        general: {
            getRenderRealtimeConfigItem,
            getIsCompHiddenPointer
        }
    }
}

export {createPointersMethods}
