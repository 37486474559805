import _ from 'lodash'

function enrich(siteData, params) {
    return _.assign(params, {
        uuid: siteData.getUserId(),
        msid: siteData.getMetaSiteId(),
        vsi: siteData.wixBiSession.viewerSessionId,
        vid: siteData.wixBiSession.visitorId,
        mid: siteData.wixBiSession.siteMemberId,
        url: window.document.location.href,
        ref: window.document.referrer,
        bot: siteData.wixBiSession.maybeBot
    })
}

export default {
    enrich
}
