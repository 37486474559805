define([], function () {
    'use strict'
    return {
        isRepeatable: false,
        layoutLimits: {
            minWidth: 250,
            minHeight: 50
        }
    }
})
