/**
 * Created by eitanr on 6/24/14.
 */
import layout from '../util/layout'
import $ from 'zepto'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import _ from 'lodash'
import imageLayout from './imageLayout'
import warmupUtilsLib from '@wix/santa-core-utils'

const {matrixScalingCalculations} = warmupUtilsLib
const {galleriesCommonLayout} = warmupUtilsLib

function getSizeAfterScaling(displayerData, galleryHeight, galleryWidth, imageMode, displayerNode) {
    const $displayer = $(displayerNode)
    return matrixScalingCalculations.getSizeAfterScaling({
        itemHeight: galleryHeight,
        itemWidth: galleryWidth,
        displayerData,
        imageMode,
        heightDiff: parseInt($displayer.data('height-diff'), 10) || 0,
        widthDiff: parseInt($displayer.data('width-diff'), 10) || 0,
        bottomGap: parseInt($displayer.data('bottom-gap'), 10) || 0
    })
}

function measureDisplayer(compId, galleryHeight, galleryWidth, imageMode, imageItems, displayerNode) {
    const $displayer = $(displayerNode)
    const imageIndex = $displayer.data('image-index')
    const imageData = _.defaults({displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL}, imageItems[imageIndex])

    const sizeAfterScaling = getSizeAfterScaling(imageData, galleryHeight, galleryWidth, imageMode, $displayer)
    const containerSize = galleriesCommonLayout.getContainerSize(sizeAfterScaling.imageWrapperSize, $displayer)

    return {
        id: displayerNode.id,
        imageData,
        imageNodeId: `${displayerNode.id}image`,
        imageWrapperId: `${displayerNode.id}imageWrapper`,
        containerSize,
        sizeAfterScaling
    }
}

function measureSlideShow(id, measureMap, nodesMap, structureInfo) {
    galleriesCommonLayout.measureFlexibleHeightGallery(id, measureMap, nodesMap)
    const itemsContainer = nodesMap[`${id}itemsContainer`]
    const displayers = $(itemsContainer).children()
    const $gallery = $(nodesMap[id])
    const galleryHeight = measureMap.height[id] - parseInt($gallery.data('height-diff'), 10) || 0
    const galleryWidth = measureMap.width[id] - parseInt($gallery.data('height-diff'), 10) || 0
    const imageItems = structureInfo.dataItem.items
    const imageMode = $gallery.data('imageMode')
    measureMap.custom[id] = {
        imageMode,
        // @ts-ignore
        displayers: _.map(displayers, _.partial(measureDisplayer, id, galleryHeight, galleryWidth, imageMode, imageItems))
    }
}

function patchSlideShow(id, patchers, measureMap, structureInfo, siteData) {
    let galleryHeight = measureMap.height[id]
    const galleryWidth = measureMap.width[id]
    const isMobile = siteData.isMobileDevice() || siteData.isMobileView()
    const galleryDisplayers = measureMap.custom[id].displayers
    if (_.isEmpty(galleryDisplayers)) {
        return
    }
    if (measureMap.custom[id].imageMode === 'flexibleHeight') {
        const lastDisplayer: any = _.last(galleryDisplayers)
        galleryHeight = lastDisplayer.sizeAfterScaling.displayerSize.height
    }

    _.forEach(galleryDisplayers, function (displayer) {
        patchers.css(displayer.id, {
            height: galleryHeight,
            width: galleryWidth
        })
        galleriesCommonLayout.updateImageWrapperSizes(patchers, displayer.imageWrapperId, displayer.sizeAfterScaling)
        imageLayout.patchNodeImage(displayer.imageNodeId, patchers, measureMap, siteData, displayer.imageData, displayer.containerSize, isMobile)
    })
}

function getChildrenIdToMeasure(id, nodesMap, structureInfo) {
    const res: any[] = [['itemsContainer']]
    _.forEach(structureInfo.dataItem.items, function (displayer) {
        const childImageComponent = {pathArray: [displayer.id, 'image'], type: 'core.components.Image'}
        const imageWrapper = [displayer.id, 'imageWrapper']
        const displayerPath = [displayer.id]
        res.push(childImageComponent, imageWrapper, displayerPath)
    })
    return res
}

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.SlideShowGallery')
layout.registerCustomMeasure('wysiwyg.viewer.components.SlideShowGallery', measureSlideShow)
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.SlideShowGallery', getChildrenIdToMeasure)
layout.registerPatcher('wysiwyg.viewer.components.SlideShowGallery', patchSlideShow)

export default {measureSlideShow, getChildrenIdToMeasure, patchSlideShow}
