import _ from 'lodash'
import repeaterHooks from './hooksHandlers/repeaterHooks'
import matrixGalleryDataHook from './hooksHandlers/matrixGalleryDataHook'

/**
 *
 * @param {core.SiteAspectsSiteAPI} siteAPI
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function wixCodeChangeHooksAspect(siteAPI) {
    this.siteAPI = siteAPI
    this.DATA = 'dataChange'
    this.compsPlugin = {dataChange: {}}
    this.registerListeners()

    const changeListener = this.handleRuntimeDalCompChange.bind(this)
    siteAPI.getRuntimeDal().registerChangeListener(changeListener)
    this.applySsrChanges = applyPendingRuntimeDalChanges.bind(this, changeListener)
}

wixCodeChangeHooksAspect.prototype = {
    getSiteAPI() {
        return this.siteAPI
    },
    getPlugin(changeObject, componentType) {
        if (this.compsPlugin[changeObject.type] && this.compsPlugin[changeObject.type][componentType]) {
            return this.compsPlugin[changeObject.type][componentType]
        }
        return null
    },
    handleRuntimeDalCompChange(compId, changeObject, dataBeforeChange) {
        const runtimeDal = this.getSiteAPI().getRuntimeDal()
        const compStructure = runtimeDal.getCompStructure(compId)
        if (!changeObject || !compStructure || !compStructure.componentType) {
            return
        }
        const plugin = this.getPlugin(changeObject, compStructure.componentType)
        if (plugin) {
            plugin.call(this, compId, changeObject, dataBeforeChange)
        }
    },
    registerListeners() {
        this.registerCompListener(this.DATA, 'wysiwyg.viewer.components.MatrixGallery', matrixGalleryDataHook.setCompLayoutOnDataChange)
        this.registerCompListener(this.DATA, 'wysiwyg.viewer.components.Repeater', repeaterHooks.handleRepeaterDataChange)
    },
    registerCompListener(changeType, compType, callback) {
        this.compsPlugin[changeType][compType] = callback
    }
}

function applyPendingRuntimeDalChanges(changeListener) {
    _.forEach(this.siteAPI.getSiteData().ssr.pendingRuntimeDalChanges, function (change) {
        changeListener(change.compId, change.changeObject, change.dataBeforeChange)
    })
}

export default wixCodeChangeHooksAspect
