const META_OR_LINK_OR_COMMENT_TAG_REGEX = /^\s*((((<meta|<link)\s[^>]*>*[\s\n]*)*>)|[\s\n]*(<!--[^<>]*-->)[\s\n]*)*[\s\n]*$/i

const ERRORS = {
    SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN: 'SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN',
    TEXT_IS_NOT_STRING: 'TEXT_IS_NOT_STRING',
    TEXT_TOO_LONG: 'TEXT_TOO_LONG',
    TEXT_INVALID_CHARS: 'TEXT_INVALID_CHARS',
    KEYWORDS_INVALID_CHARS: 'KEYWORDS_INVALID_CHARS',
    METATAGS_INVALID_FORMAT: 'METATAGS_INVALID_FORMAT',
    METATAGS_INVALID_CHARS: 'METATAGS_INVALID_CHARS',
    METATAGS_SERVER_INVALID_CODE: 'METATAGS_SERVER_INVALID_CODE',
    METATAGS_SERVER_INVALID_TAG: 'METATAGS_SERVER_INVALID_TAG',
    REDIRECT_INVALID_CHARS: 'REDIRECT_INVALID_CHARS',
    REDIRECT_URI_MAPPING_IS_NOT_OBJECT: 'REDIRECT_URI_MAPPING_IS_NOT_OBJECT',
    REDIRECT_FROM_URIS_IS_NOT_ARRAY: 'REDIRECT_FROM_URIS_IS_NOT_ARRAY',
    REDIRECT_MAPPING_INVALID_FORMAT: 'REDIRECT_MAPPING_INVALID_FORMAT',
    REDIRECT_MAPPING_URIS_NOT_STRING: 'REDIRECT_MAPPING_URIS_NOT_STRING',
    SERVER_VALIDATION_TIMEOUT: 'SERVER_VALIDATION_TIMEOUT',
    METATAGS_EMOJIS_NOT_SUPPORTED: 'METATAGS_EMOJIS_NOT_SUPPORTED'
}

const VALIDATIONS = {
    TITLE_MAX_LENGTH: 200,
    DESCRIPTION_MAX_LENGTH: 500,
    KEYWORDS_MAX_LENGTH: 250,
    METATAGS_MAX_LENGTH: 2000,
    REDIRECT_MAX_LENGTH: 149
}

const VALIDATIONS_REGEX = {
    HTML_CHARS: /[<>]/g,
    KEYWORD_CHARS: /[!#$%^&()+=\]\[}{";:\/><~]/g,
    META_TAG: META_OR_LINK_OR_COMMENT_TAG_REGEX,
    URI_CHARS: /[^.,';()%@!?$&*+~a-zA-Z0-9\[\]_:\/\"\n=-]/gi
}

export {META_OR_LINK_OR_COMMENT_TAG_REGEX, ERRORS, VALIDATIONS, VALIDATIONS_REGEX}
