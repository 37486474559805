import layout from '../../util/layout'

const COMP_TYPE = 'wysiwyg.viewer.components.PagesContainer'

const isMeshLayout = node => node.dataset.isMesh === 'true'

const measurePagesContainer = (id, node, measureMap) => {
    measureMap.left[id] = 0
    if (isMeshLayout(node)) {
        measureMap.height[id] = node.offsetHeight
    }
}

const customPagesContainerLayouter = (id, nodesMap, measureMap) => {
    const node = nodesMap[id]
    measurePagesContainer(id, node, measureMap)
}

layout.registerCustomLayoutFunction(COMP_TYPE, customPagesContainerLayouter)
layout.registerRequestToMeasureChildren(COMP_TYPE, [['screenWidthBackground'], ['inlineContent']])
