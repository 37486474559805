define(['lodash', 'documentServices/platform/common/constants'], function (_, constants) {
    'use strict'

    function triggerEvent(applicationId, options) {
        return {
            intent: constants.Intents.PLATFORM_WORKER,
            type: constants.MessageTypes.TRIGGER_EVENT,
            applicationId,
            args: options
        }
    }

    const addApps = (apps, initData) => ({
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.ADD_APPS,
        apps: _.map(apps, app =>
            _.pick(app, [
                'applicationId',
                'appFields.platform.editorScriptUrl',
                'appFields.platform.baseUrls',
                'appDefinitionId',
                'firstInstall',
                'origin',
                'biData',
                'instance',
                'instanceId',
                'appUrlQueryParams',
                'silentInstallation',
                'headlessInstallation',
                'components',
                'settings'
            ])
        ),
        initData
    })

    function addApp(app, initData) {
        return addApps([app], initData)
    }

    const removeApp = (applicationId, appDefinitionId) => ({
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.NotifyMethods.REMOVE_APP,
        applicationId,
        appDefinitionId
    })

    function loadManifest(appData, initData) {
        return {
            intent: constants.Intents.PLATFORM_WORKER,
            type: constants.MessageTypes.LOAD_MANIFEST,
            applicationId: appData.applicationId,
            appData,
            initData
        }
    }

    const initialize = (
        {extraNamespaces, sdkVersion, editorType, metaSiteId, userId, commonConfig, wixExpConfig, concurrentEditing, editorScriptHot},
        initData
    ) => ({
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.INITIALIZE,
        extraNamespaces,
        sdkVersion,
        editorType,
        metaSiteId,
        userId,
        commonConfig,
        wixExpConfig,
        concurrentEditing,
        editorScriptHot,
        initData
    })

    return _.mapValues(
        {
            initialize,
            triggerEvent,
            addApp,
            addApps,
            removeApp,
            loadManifest
        },
        fn => _.flow(fn, JSON.stringify)
    )
})
