import type {ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary) {
    const errors = viewerLibrary.get('documentServices/errors/errors')
    return {
        methods: {
            /**
             * Site error constants
             * @member documentServices
             */
            errors
        }
    }
}
