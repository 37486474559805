/**
 * Created by talm on 18/08/15.
 */
define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/structure/structure',
    'documentServices/utils/utils',
    'documentServices/component/componentData',
    'documentServices/constants/constants'
], function (_, dataModel, component, componentsMetaData, structure, dsUtils, componentData, constants) {
    'use strict'

    const COLUMNS_CONTAINER_TYPE = 'wysiwyg.viewer.components.StripColumnsContainer'

    function splitMobileComponentPropsIfNeeded(ps, componentPointer) {
        if (componentPointer.type === constants.VIEW_MODES.DESKTOP) {
            return
        }

        if (componentData.isMobileComponentPropertiesSplit(ps, componentPointer)) {
            return
        }

        componentData.splitMobileComponentProperties(ps, componentPointer)
    }

    function handleColumnDeletion(ps, deletedCompPointer, deletingParent) {
        if (deletingParent) {
            return
        }

        const columnsContainerPtr = ps.pointers.components.getParent(deletedCompPointer) || ps.pointers.full.components.getParent(deletedCompPointer)
        const columnPointers = ps.pointers.components.getChildren(columnsContainerPtr) || ps.pointers.full.components.getChildren(columnsContainerPtr)
        const numberOfColumns = columnPointers.length

        if (numberOfColumns === 2) {
            const noMargin = {
                rowMargin: 0,
                columnsMargin: 0,
                siteMargin: 0,
                frameMargin: 0
            }

            splitMobileComponentPropsIfNeeded(ps, columnsContainerPtr)
            dataModel.updatePropertiesItem(ps, columnsContainerPtr, noMargin)
        }
    }

    function deleteContainerIfHasNoMoreColumns(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, copyDataItem, parentPointer) {
        const columnPointers = ps.pointers.components.getChildren(parentPointer) || ps.pointers.full.components.getChildren(parentPointer)

        if (!columnPointers.length && !deletingParent) {
            component.deleteComponent(ps, parentPointer)
        }
    }

    function moveFullWidthCompsToPage(ps, columnsContainerPtr) {
        const singleColumnStripPtr = _.head(ps.pointers.components.getChildren(columnsContainerPtr))
        const stripChildren = ps.pointers.components.getChildren(singleColumnStripPtr)

        _.forEach(stripChildren, function (childComp) {
            if (dsUtils.getComponentType(ps, childComp) === COLUMNS_CONTAINER_TYPE || componentsMetaData.public.isFullWidth(ps, childComp)) {
                structure.reparentComponentToPage(ps, childComp, true)
            }
        })
    }

    function handleSplitToColumns(ps, addedColumnPtr, columnsContainerPtr /*, compDefinitionPrototype*/) {
        const columns = ps.pointers.components.getChildren(columnsContainerPtr)
        if (columns.length === 1) {
            // splitting from strip to column
            moveFullWidthCompsToPage(ps, columnsContainerPtr)
        }
    }

    function handleFullWidthPropertyChange(ps, columnsContainerPtr, updatedPropertiesItem) {
        const currProperties = dataModel.getPropertiesItem(ps, columnsContainerPtr)
        if (currProperties.fullWidth && _.get(updatedPropertiesItem, 'fullWidth', currProperties.fullWidth) !== currProperties.fullWidth) {
            moveFullWidthCompsToPage(ps, columnsContainerPtr)
        }
    }

    return {
        handleFullWidthPropertyChange,
        handleSplitToColumns,
        handleColumnDeletion,
        deleteContainerIfHasNoMoreColumns
    }
})
