define([
    'lodash',
    '@wix/mobile-conversion',
    '@wix/santa-core-utils',
    'experiment',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/dataModel/dataModel',
    'documentServices/constants/constants',
    'documentServices/component/componentData',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsConstants',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsUtils',
    'documentServices/mobileConversion/modules/utils',
    'documentServices/mobileConversion/modules/mobileOnlyComponents',
    'documentServices/componentsMetaData/componentsMetaData'
], function (
    _,
    mobileCore,
    santaCoreUtils,
    experiment,
    mergeAggregator,
    dataModel,
    constants,
    componentData,
    mobileHintsPresetsConstants,
    mobilePresetsUtils,
    mobileConversionUtils,
    mobileOnlyComponents,
    componentsMetaData
) {
    'use strict'

    const {HINTS_PROPERTIES, PRESET_CONVERSION_CONFIG, MOBILE_HINTS_AUTHORS, PROPERTIES_FOR_MOBILE_PRESETS, ALIASES} = mobileHintsPresetsConstants

    const {
        isMobileHintsPreset,
        getOrderIndex,
        getComponentStructure,
        getComponentsMap,
        shouldRemoveOffset,
        shouldRemovePresetSize,
        hasOffsetData,
        hasSizeData,
        hasGeneralData,
        convertMobilePresetsToMobileHints,
        isDeadComponentById
    } = mobilePresetsUtils

    const setMobileHints = (ps, mobileHintsMap, pagePointer, fullStructureMap) => {
        _.forEach(mobileHintsMap, (mobileHints, componentId) => {
            const componentPointer = ps.pointers.full.components.getComponent(componentId, pagePointer)
            if (ps.dal.full.isExist(componentPointer)) {
                const fullChildStructure = fullStructureMap[componentId]
                const fullChildStructureWithModes = getComponentStructure(ps, fullChildStructure, pagePointer.id)
                if (_.has(fullChildStructureWithModes, ['mobileStructure', 'props'])) {
                    mobileHints.props = fullChildStructureWithModes.mobileStructure.props
                }
                dataModel.updateMobileHintsItem(ps, componentPointer, mobileHints)
            }
        })
    }

    const removeMobileStructures = (ps, componentIds, pagePointer) => {
        _.forEach(componentIds, componentId => {
            const componentPointer = ps.pointers.full.components.getComponent(componentId, pagePointer)
            const mobileStructurePointer = ps.pointers.getInnerPointer(componentPointer, 'mobileStructure')
            ps.dal.full.remove(mobileStructurePointer)
        })
    }
    /**
     * Runs when user duplicates component
     * @param {ps} ps
     * @param {string} pageId
     * @param {AbstractComponent} componentPointer
     * @param {*} desktopComponent
     */
    const createDuplicateMobilePreset = (ps, pageId, componentPointer, desktopComponent) => {
        const mobileComponentPointer = ps.pointers.components.getMobilePointer(componentPointer)
        if (!pageId || !mobileComponentPointer || !ps.dal.isExist(mobileComponentPointer)) {
            return
        }
        const isQA = ps.siteAPI.isQaMode()
        const mobileComponent = mergeAggregator.getCommittedMobileComponent(ps, pageId, mobileComponentPointer)
        /**
         * For new merge flow we need to save mobile structure
         * and mobile hints for mobile conversion, to generate mobile components
         */
        const shouldNotCreatePreset =
            !mobileComponent || (!mobileConversionUtils.shouldEnableNewMergeFlow(ps) && !isQA && isMobileHintsPreset(desktopComponent.mobileHints))
        if (shouldNotCreatePreset) {
            return
        }

        const mobileProps = getMobilePropsIfForked(ps, mobileComponentPointer.id, pageId)
        _.set(desktopComponent, 'mobileStructure', {
            layout: santaCoreUtils.objectUtils.cloneDeep(mobileComponent.layout),
            props: mobileProps ? _.omit(mobileProps, 'id') : undefined,
            metaData: {originalCompId: mobileComponentPointer.id, author: MOBILE_HINTS_AUTHORS.DUPLICATE}
        })
    }

    /**
     * Use to remove preset data from component. It needs for optimize
     * layout to not use layout from origin mobile component when generated
     * moile structure from skretch
     * @param {*} ps
     * @param {*} parentPointer
     */
    const removeAutomaticPresets = (ps, parentPointer) => {
        if (mobileConversionUtils.shouldEnableNewMergeFlow(ps)) {
            const childrenPointers = ps.pointers.components.getChildren(parentPointer)
            _.forEach(childrenPointers, compPointer => removeAutomaticPresets(ps, compPointer))
            const mobileHints = dataModel.getMobileHintsItem(ps, parentPointer)
            if (_.get(mobileHints, 'author') === MOBILE_HINTS_AUTHORS.DUPLICATE) {
                removeAllPresetData(ps, parentPointer)
            }
            return
        }
        const childrenPointers = ps.pointers.components.getChildren(parentPointer)
        _.forEach(childrenPointers, removeAutomaticPresets.bind(null, ps))

        const mobileHints = dataModel.getMobileHintsItem(ps, parentPointer)
        if (_.get(mobileHints, 'author') === MOBILE_HINTS_AUTHORS.DUPLICATE) {
            dataModel.deleteMobileHintsItem(ps, parentPointer, true)
        }
    }

    const getMobilePropsIfForked = (ps, componentId, pageId) => {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        const componentPointer = ps.pointers.components.getComponent(componentId, pagePointer)
        if (!componentPointer || !ps.dal.isExist(componentPointer)) {
            return null
        }
        const arePropsForked = componentData.isMobileComponentPropertiesSplit(ps, componentPointer)
        if (!arePropsForked) {
            return null
        }
        const propsPointer = dataModel.getPropertyItemPointer(ps, componentPointer)
        return propsPointer && ps.dal.isExist(propsPointer) ? ps.dal.get(propsPointer) : null
    }
    /**
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Array<string>} presetDataToRemove preset data fields look in ./mobilePresetsConstants.js
     */
    const removePresetData = (ps, componentPointer, presetDataToRemove) => {
        if (ps.pointers.components.isMobile(componentPointer) || ps.pointers.full.components.isMobile(componentPointer)) {
            return
        }
        const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)
        if (!isMobileHintsPreset(mobileHintsItem)) {
            return
        }
        const newMobileHintsItem = _.omit(mobileHintsItem, [...presetDataToRemove, ...HINTS_PROPERTIES.GENERAL_HINTS_PROPERTIES])
        if (!_.isEmpty(newMobileHintsItem)) {
            dataModel.deleteMobileHintsItem(ps, componentPointer)
            dataModel.updateMobileHintsItem(ps, componentPointer, newMobileHintsItem)
        } else {
            // clearing the query
            dataModel.deleteMobileHintsItem(ps, componentPointer, true)
        }
    }

    const removePresetSizeData = (ps, componentPointer) => {
        removePresetData(ps, componentPointer, HINTS_PROPERTIES.SIZE_DATA)
    }

    const removeAllPresetData = (ps, componentPointer) => {
        const presetDataToRemove = [...HINTS_PROPERTIES.OFFSET_DATA, ...HINTS_PROPERTIES.SIZE_DATA, ...HINTS_PROPERTIES.GENERAL_PRESET_DATA]
        removePresetData(ps, componentPointer, presetDataToRemove)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    const removeAllPresetDataRecursively = (ps, componentPointer) => {
        removeAllPresetData(ps, componentPointer)
        const children = ps.pointers.components.getChildren(componentPointer)
        _.forEach(children, childPointer => removeAllPresetDataRecursively(ps, childPointer))
    }

    const removeGeneralPresetData = (ps, componentPointer) => {
        removePresetData(ps, componentPointer, HINTS_PROPERTIES.GENERAL_PRESET_DATA)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {MobileHints} mobileHintsItem
     */
    const removeGeneralPresetDataIfNeeded = (ps, componentPointer, mobileHintsItem) => {
        if (!hasGeneralData(mobileHintsItem)) {
            return
        }
        removeGeneralPresetData(ps, componentPointer)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    const removeChildrenGeneralPresetData = (ps, componentPointer) => {
        const children = ps.pointers.components.getChildren(componentPointer)
        _.forEach(children, childPointer => removeGeneralPresetData(ps, childPointer))
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Object} newLayout layout data
     * @param {Object} previousLayout layout data
     * @param {MobileHints} mobileHintsItem
     */
    const removePresetOffsetDataIfNeeded = (ps, componentPointer, newLayout, previousLayout, mobileHintsItem) => {
        removeGeneralPresetDataIfNeeded(ps, componentPointer, mobileHintsItem)
        if (!hasOffsetData(mobileHintsItem)) {
            return
        }
        if (shouldRemoveOffset(newLayout, previousLayout)) {
            removePresetOffsetData(ps, componentPointer)
            const parentPointer = ps.pointers.components.getParent(componentPointer)
            if (!ps.pointers.components.isPage(componentPointer)) {
                removePresetOffsetDataOfChildren(ps, parentPointer)
                removeChildrenGeneralPresetData(ps, parentPointer)
            }
        }
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Object} newLayout layout data
     * @param {Object} previousLayout layout data
     * @param {MobileHints} mobileHintsItem
     */
    const removePresetSizeIfNeeded = (ps, componentPointer, newLayout, previousLayout, mobileHintsItem) => {
        if (!hasSizeData(mobileHintsItem)) {
            return
        }
        if (shouldRemovePresetSize(newLayout, previousLayout)) {
            removePresetSizeData(ps, componentPointer)
            removeGeneralPresetDataIfNeeded(ps, componentPointer, mobileHintsItem)
        }
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    const removePresetOffsetData = (ps, componentPointer) => {
        const presetDataToRemove = HINTS_PROPERTIES.OFFSET_DATA
        removePresetData(ps, componentPointer, presetDataToRemove)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    const removePresetOffsetDataOfChildren = (ps, componentPointer) => {
        const children = ps.pointers.components.getChildren(componentPointer) || ps.pointers.full.components.getChildren(componentPointer)

        _.forEach(children, childPointer => removePresetOffsetData(ps, childPointer))
        removePresetHeight(ps, componentPointer)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    const removePresetHeight = (ps, componentPointer) => {
        const presetDataToRemove = ['recommendedHeight']
        removePresetData(ps, componentPointer, presetDataToRemove)
        const parentPointer = ps.pointers.components.getParent(componentPointer) || ps.pointers.full.components.getParent(componentPointer)
        if (parentPointer && !ps.pointers.components.isPage(parentPointer)) {
            removePresetHeight(ps, parentPointer)
        }
    }

    /**
     * @param {ps} ps
     * @param {Array} components array of full components // TODO: Add type
     * @package {string} pageId
     * @returns {Array|null} List of components id
     */
    const getRecommendedComponentsOrder = (ps, components, pageId) => {
        const hasOrderIndex = component => _.isNumber(getOrderIndex(ps, pageId, component))
        if (!_.isEmpty(components) && !_.every(components, hasOrderIndex)) {
            return null
        }
        return _(components)
            .reject(['conversionData.mobileHints.hidden', true])
            .sortBy(component => getOrderIndex(ps, pageId, component))
            .map('id')
            .value()
    }
    /**
     *
     * @param {ps} ps
     * @param {MobileHints} mobileHintsItem
     * @param {string} pageId
     * @param {Object} component
     */
    const getMobileProps = (ps, mobileHintsItem, pageId, component) => {
        const {originalCompId} = mobileHintsItem
        const originalCompMobileProps = originalCompId ? getMobilePropsIfForked(ps, originalCompId, pageId) : null
        // TODO: figure out is it relevat property in mobile hints, according to MobileHints type this field is redundant
        // @ts-ignore
        const maybeMobileProps = mobileHintsItem.props
        return originalCompMobileProps || maybeMobileProps || component.conversionData.mobileProps
    }
    /**
     * @param {ps} ps
     * @param {Array<Object>} components list of full components
     * @param {string} pageId
     */
    const applyPresetsToConversionData = (ps, components, pageId) => {
        const MOBILE_HINTS_CONVERSION_PROPERTIES = [...HINTS_PROPERTIES.SIZE_DATA, ...HINTS_PROPERTIES.OFFSET_DATA, ...HINTS_PROPERTIES.GENERAL_PRESET_DATA]
        _.forEach(components, component => {
            const mobileHintsItem = dataModel.getMobileHintsItemById(ps, component.mobileHintsQuery, pageId)
            applyPresetsToConversionData(ps, component.components, pageId)
            if (!isMobileHintsPreset(mobileHintsItem)) {
                return
            }

            const mobileProps = getMobileProps(ps, mobileHintsItem, pageId, component)
            const mobileScale = mobileHintsItem.recommendedScale || component.conversionData.mobileScale
            const recommendedComponentsOrder = getRecommendedComponentsOrder(ps, component.components, pageId)

            const mobileHints = _.pick(mobileHintsItem, MOBILE_HINTS_CONVERSION_PROPERTIES)
            _.assign(
                component.conversionData,
                {
                    mobileScale,
                    mobileProps
                },
                PRESET_CONVERSION_CONFIG
            )

            _.assign(component.conversionData.mobileHints, mobileHints, {recommendedComponentsOrder})
        })
    }

    const shouldIgnoreWidgetOwnSize = () => experiment.isOpen('dm_ignoreWidgetsSizeInMobilePresets')
    /**
     * Used only for converting presets for components added from add panel
     */
    const convertPresetToMobileHints = (ps, desktopStructure, fullStructureMap, pagePointer) => {
        const mobileHintsMap = mobileCore.mobileHints.parseMobilePreset(desktopStructure, shouldIgnoreWidgetOwnSize())
        setMobileHints(ps, mobileHintsMap, pagePointer, fullStructureMap)
        removeMobileStructures(ps, _.keys(mobileHintsMap), pagePointer)
    }

    const convertMobileComponentToMobileHints = (ps, compPointer) => {
        if (!ps.dal.isExist(compPointer)) {
            return
        }
        if (mobileOnlyComponents.isMobileOnlyComponent(ps, compPointer.id)) {
            return
        }
        const fullStructure = ps.siteAPI.getDeepStructure ? ps.siteAPI.getDeepStructure(compPointer) : ps.dal.full.get(compPointer)
        const fullStructureMap = getComponentsMap(fullStructure)
        const mobileStructure = fullStructureMap[compPointer.id]
        const mobileHintsMap = mobileCore.mobileHints.parseMobileStructure(mobileStructure, shouldIgnoreWidgetOwnSize())
        const desktopCompPointer = ps.pointers.components.getDesktopPointer(compPointer)
        const pagePointer = ps.pointers.components.getPageOfComponent(desktopCompPointer)
        setMobileHints(ps, mobileHintsMap, pagePointer, fullStructureMap)
    }

    /**
     * @param {ps} ps
     * @param {object} compStructure TODO: FIX TYPE
     * @param {{ [compId: string]: object; }} fullStructureMap
     * @param {Pointer} pagePointer
     */
    const migrateCompPreset = (ps, compStructure, fullStructureMap, pagePointer) => {
        const desktopStructure = fullStructureMap[compStructure.id]
        if (_.get(desktopStructure, 'mobileStructure')) {
            convertPresetToMobileHints(ps, desktopStructure, fullStructureMap, pagePointer)
        } else {
            _.forEach(compStructure.components, childStructure => migrateCompPreset(ps, childStructure, fullStructureMap, pagePointer))
        }
    }

    /**
     * @param {ps} ps
     * @param {AbstractComponent} mobilePointer
     * @param {string} pageId
     * @return {MobileStructure}
     */
    const getMobilePreset = (ps, mobilePointer, pageId) => {
        const props = getMobilePropsIfForked(ps, mobilePointer.id, pageId)
        const component = ps.dal.get(mobilePointer)

        const mobileStructure = PROPERTIES_FOR_MOBILE_PRESETS.reduce((structure, propName) => {
            const alias = ALIASES[propName] || propName
            const value = component[propName]

            if (value) {
                structure[alias] = value
            }

            return structure
        }, {})

        if (props) {
            // @ts-ignore
            mobileStructure.props = props
        }

        // @ts-ignore
        return mobileStructure
    }

    const fillPresetsMap = (ps, mobilePointer, pageId, componentsPresest) => {
        const children = ps.pointers.components.getChildren(mobilePointer)

        if (children) {
            children.forEach(child => {
                const mobileStructure = getMobilePreset(ps, child, pageId)
                const isNonLayoutComponent = componentsMetaData.public.getMobileConversionConfigByName(ps, mobileStructure, 'nonLayoutComponent', pageId)

                if (isNonLayoutComponent) {
                    fillPresetsMap(ps, child, pageId, componentsPresest)
                    return
                }

                componentsPresest.set(mobileStructure.id, mobileStructure)
                fillPresetsMap(ps, child, pageId, componentsPresest)
            })
        }
    }

    /**
     * @typedef MobileStructure
     * @type {object}
     * @property {Object} layout
     * @property {number} layout.x
     * @property {number} layout.y
     * @property {string} styleId
     * @property {Array<string>} components
     * @property {string} componentType
     * @property {string} type
     * @property {string} id
     */

    /**
     *
     * @param {*} ps
     * @param {*} mobilePointer
     * @param {*} pageId
     * @returns Map<string, MobileStructure>
     */
    const getMobilePresets = (ps, mobilePointer, pageId) => {
        const componentsPresest = new Map()
        fillPresetsMap(ps, mobilePointer, pageId, componentsPresest)
        return componentsPresest
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     */
    const convertPagePresetToMobileHints = (ps, pageId) => {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        const componentsPresets = getMobilePresets(ps, pagePointer, pageId)
        const mobileHintsMap = convertMobilePresetsToMobileHints(ps, pagePointer, componentsPresets)

        for (const [id, mobileHints] of mobileHintsMap) {
            const componentPointer = ps.pointers.getPointer(id, constants.VIEW_MODES.DESKTOP)

            if (mobileOnlyComponents.isMobileOnlyComponent(ps, id) || isDeadComponentById(componentPointer.id)) {
                continue
            }

            dataModel.updateMobileHintsItem(ps, componentPointer, mobileHints)
        }
        /**
         * Need to set author equals to studio for page, because it used in check inside migration. We don't need
         * other mobile data for page like layout because it should calculated by algorithm
         */
        dataModel.updateMobileHintsItem(ps, ps.pointers.getPointer(pageId, constants.VIEW_MODES.DESKTOP), {author: MOBILE_HINTS_AUTHORS.STUDIO})
    }

    /**
     * @description
     * function migrate component preset kept as mobileStructure in component preset JSON,
     * and convert it to mobileHints item to further processing while mobile conversion runs
     * @param {ps} ps
     * @param {Pointer} compPointer
     */
    const handleMobileStructure = (ps, compPointer) => {
        /**
         * Handle adding of new page from template
         */
        if (mobileConversionUtils.shouldEnableNewMergeFlow(ps)) {
            if (ps.pointers.full.components.isPage(compPointer)) {
                convertPagePresetToMobileHints(ps, compPointer.id)
                return
            }
        }
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const fullStructure = ps.siteAPI.getDeepStructure ? ps.siteAPI.getDeepStructure(compPointer) : ps.dal.full.get(compPointer)

        const fullStructureMap = getComponentsMap(fullStructure)
        migrateCompPreset(ps, fullStructure, fullStructureMap, pagePointer)
    }

    return {
        createDuplicateMobilePreset,
        removeAutomaticPresets,
        removeAllPresetDataRecursively,
        removeGeneralPresetDataIfNeeded,
        removePresetOffsetDataOfChildren,
        removePresetOffsetDataIfNeeded,
        removePresetSizeIfNeeded,
        handleMobileStructure,
        setMobileHints,
        removePresetOffsetData,
        applyPresetsToConversionData,
        getMobilePropsIfForked,
        removePresetData,
        removePresetHeight,
        convertPagePresetToMobileHints,
        convertPresetToMobileHints,
        convertMobileComponentToMobileHints,
        getMobilePresets,
        getMobilePreset
    }
})
