define([
    'lodash',
    'documentServices/component/componentStructureInfo',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/page/popupUtils',

    /* Plugins */
    'documentServices/structure/relativeToScreenPlugins/matrixGalleryPlugin',
    'documentServices/structure/relativeToScreenPlugins/screenWidthPlugin',
    'documentServices/structure/relativeToScreenPlugins/fullViewPortPlugin',
    'documentServices/structure/relativeToScreenPlugins/siteStructurePlugin',
    'documentServices/structure/relativeToScreenPlugins/anchorPlugin',
    'documentServices/structure/relativeToScreenPlugins/textHeightPlugin',
    'documentServices/structure/relativeToScreenPlugins/verticalLinePlugin',
    'documentServices/structure/relativeToScreenPlugins/horizontalLinePlugin',
    'documentServices/structure/relativeToScreenPlugins/popupContainerWidthPlugin',
    'documentServices/structure/relativeToScreenPlugins/quickActionBarPlugin'
], function (
    _,
    componentStructureInfo,
    metaDataUtils,
    popupUtils,
    matrixGalleryPlugin,
    screenWidthPlugin,
    fullViewPortPlugin,
    siteStructurePlugin,
    anchorPlugin,
    textHeightPlugin,
    verticalLinePlugin,
    horizontalLinePlugin,
    popupContainerWidthPlugin,
    quickActionBarPlugin
) {
    'use strict'

    const getScreenLayoutPlugins = viewerName => ({
        'wysiwyg.viewer.components.MatrixGallery': matrixGalleryPlugin,
        'wysiwyg.common.components.anchor.viewer.Anchor': anchorPlugin,
        'wysiwyg.viewer.components.WSiteStructure': siteStructurePlugin,
        'wysiwyg.viewer.components.WRichText': viewerName === 'tb' ? _.noop : textHeightPlugin,
        'wysiwyg.viewer.components.FiveGridLine': horizontalLinePlugin,
        'wysiwyg.viewer.components.VerticalLine': verticalLinePlugin,
        'wysiwyg.viewer.components.PopupContainer': popupContainerWidthPlugin,
        'wysiwyg.viewer.components.QuickActionBar': quickActionBarPlugin
    })

    return {
        getPlugin(privateApi, compPointer) {
            const {viewerName} = privateApi.runtimeConfig
            const compType = componentStructureInfo.getType(privateApi, compPointer)
            let plugin = getScreenLayoutPlugins(viewerName)[compType]

            if (!plugin && metaDataUtils.isLegacyFullWidthContainer(privateApi, compPointer)) {
                plugin = popupUtils.isPopup(privateApi, compPointer.id) ? fullViewPortPlugin : screenWidthPlugin
            }

            return plugin
        }
    }
})
