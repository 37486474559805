import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    const platform = viewerLibrary.get('documentServices/platform/platform')
    const provision = viewerLibrary.get('documentServices/platform/provision')
    const pages = viewerLibrary.get('documentServices/platform/pages')
    const addApps = viewerLibrary.get('documentServices/platform/addApps')
    const sessionState = viewerLibrary.get('documentServices/platform/sessionState')
    const serialization = viewerLibrary.get('documentServices/platform/serialization')
    const viewerInfoChangedEmitter = viewerLibrary.get('documentServices/platform/services/viewerInfoChangedEmitter')
    const apiCallBiService = viewerLibrary.get('documentServices/platform/services/apiCallBiService')
    const livePreview = viewerLibrary.get('documentServices/platform/livePreview/livePreview')
    const constants = viewerLibrary.get('documentServices/platform/common/constants')
    const uninstall = viewerLibrary.get('documentServices/platform/uninstall')

    return {
        methods: {
            platform: {
                init: publicMethodUtils.defineAction(platform.init),
                isAppActive: publicMethodUtils.defineGetter(platform.isAppActive),
                initApp: publicMethodUtils.defineAction(platform.initApp),
                isPlatformAppInstalled: publicMethodUtils.defineGetter(platform.isPlatformAppInstalled),
                getAppsDependenciesWithPredicate: publicMethodUtils.defineGetter(platform.getAppsDependenciesWithPredicate),
                addApps: publicMethodUtils.defineDataManipulationAction(addApps.addApps, {
                    isUpdatingAnchors: dsUtils.YES,
                    /**
                     * @param {ps} ps
                     * @param {string} appDefinitionIds
                     * @return {{apps_ids: string}}
                     */
                    getInteractionParams: addApps.getAddAppsParams,
                    shouldLogInteraction: true
                }),
                provision: publicMethodUtils.defineAction(provision.provision),
                uninstall: publicMethodUtils.defineDataManipulationAction(uninstall.uninstall, {
                    isUpdatingAnchors: dsUtils.YES,
                    isAsyncOperation: true,
                    asyncPreDataManipulation: uninstall.notifyBeforeApplicationDelete,
                    /**
                     * @param {ps} ps
                     * @param {string} appDefinitionId
                     * @return {{app_id: string}}
                     */
                    getInteractionParams: uninstall.getUninstallInteractionParams,
                    shouldLogInteraction: true
                }),
                update: publicMethodUtils.defineAction(provision.update),
                notifyApplication: publicMethodUtils.defineAction(platform.notifyApplication),
                notifyAppsOnCustomEvent: publicMethodUtils.defineAction(platform.notifyAppsOnCustomEvent),
                registerAppToCustomEvents: publicMethodUtils.defineAction(platform.registerAppToCustomEvents),
                getAPIForSDK: publicMethodUtils.defineGetter(platform.getAPIForSDK),
                getInstalledAppsData: publicMethodUtils.defineGetter(platform.getInstalledAppsData),
                getAppManifest: publicMethodUtils.defineGetter(platform.getAppManifest),
                hasAppManifest: publicMethodUtils.defineGetter(platform.hasAppManifest),
                getAppPublicApi: publicMethodUtils.defineGetter(platform.getAppPublicApi),
                getAppPrivateApi: publicMethodUtils.defineGetter(platform.getAppPrivateApi),
                getAppEditorApi: publicMethodUtils.defineGetter(platform.getAppEditorApi),
                getPagesDataFromAppManifest: publicMethodUtils.defineGetter(platform.getPagesDataFromAppManifest),
                getAppDataByAppDefId: publicMethodUtils.defineGetter(platform.getAppDataByAppDefId),
                getAppDataByApplicationId: publicMethodUtils.defineGetter(platform.getAppDataByApplicationId),
                getAppDescriptor: publicMethodUtils.defineGetter(platform.getAppDescriptor),
                getInstalledEditorApps: publicMethodUtils.defineGetter(platform.getInstalledEditorApps),
                getInstalledApps: publicMethodUtils.defineGetter(platform.getInstalledApps),
                editorApps: constants.APPS,
                registerToAppsCompleted: publicMethodUtils.defineAction(platform.registerToAppsCompleted),
                getEditorSdkUrl: publicMethodUtils.defineGetter(platform.getEditorSdkUrl),
                serialization: {
                    /**
                     * Returns controller serialized data with the passed connectedComponents info (i.e connections from other components to the serialized controller)
                     * @member documentServices.platform.serialization
                     * @param {Object} serializedController - a serialized data of an appController component
                     * @param {Object} connectedComponents - a serialized connections data of components connected to the serialized controller
                     * @returns {Object} the serializedController data with the passed connectedComponents
                     */
                    setConnectedComponents: publicMethodUtils.defineAction(serialization.setConnectedComponents),
                    /**
                     * Returns controller serialized connectedComponents info (i.e connections from other components to the serialized controller)
                     * @member documentServices.platform.serialization
                     * @param {Object} serializedController - a serialized data of an appController component
                     * @returns {Object} a map from component id to an array containing the component connections to the serialized controller
                     */
                    getConnectedComponents: publicMethodUtils.defineGetter(serialization.getConnectedComponents)
                },
                sessionState: {
                    update: publicMethodUtils.defineAction(sessionState.updateSessionState),
                    get: publicMethodUtils.defineGetter(sessionState.getSessionState)
                },
                pages: {
                    navigateTo: publicMethodUtils.defineAction(pages.navigateTo),
                    setState: publicMethodUtils.defineDataManipulationAction(pages.setState),
                    getState: publicMethodUtils.defineGetter(pages.getState)
                },
                livePreview: {
                    refresh: publicMethodUtils.defineAction(livePreview.debouncedRefresh),
                    isActive: publicMethodUtils.defineGetter(livePreview.isActive),
                    autoRun: publicMethodUtils.defineAction(livePreview.autoRun)
                },
                concurrentEditing: {
                    registerToPlatformAPIChange: publicMethodUtils.defineAction(platform.concurrentEditing.registerToPlatformAPIChange),
                    unregisterToPlatformAPIChange: publicMethodUtils.defineAction(platform.concurrentEditing.unregisterToPlatformAPIChange)
                },
                registerToViewerInfoChanged: publicMethodUtils.defineAction(viewerInfoChangedEmitter.register),
                registerToManifestAdded: publicMethodUtils.defineAction(platform.registerToManifestAdded),
                registerToPublicApiSet: publicMethodUtils.defineAction(platform.registerToPublicApiSet),
                registerToPrivateApiSet: publicMethodUtils.defineAction(platform.registerToPrivateApiSet),
                reportAPICallFedOps: publicMethodUtils.defineAction(apiCallBiService.reportAPICallFedOps),
                /**
                 * Returns promise to the API by the requested apiName
                 * Used to get applications API (public and private) for applications running in Platform panels.
                 * Note: now getAppPublicApi, getAppPrivateApi, getAppEditorApi  are exposed, so no need to use requestAPIFromWorker directly
                 */
                requestAPIFromWorker: publicMethodUtils.defineGetter(platform.requestAPIFromWorker),
                migrate: publicMethodUtils.defineAction(platform.migrate)
            }
        },
        initMethod: platform.initialize
    }
}
