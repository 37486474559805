define([
    'lodash',
    'tpa',
    'documentServices/tpa/constants',
    'documentServices/page/pageData',
    'documentServices/page/blankPageStructure',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/theme/theme',
    'documentServices/component/component'
], function (_, tpa, tpaConstants, pageData, blankPageStructure, clientSpecMapService, theme, component) {
    'use strict'

    const getSectionStructure = function (
        ps,
        definitionData,
        sectionId,
        tpaPageId,
        hidePage,
        indexable,
        landingPageParams = {},
        pageUriSEO,
        requireLogin,
        managingAppDefId
    ) {
        const appId = definitionData.applicationId
        //TODO - get this from definitionData
        const defSize = {
            w: 980,
            h: 500
        }

        const pageStructure = {
            componentType: 'mobile.core.components.Page',
            type: 'Page',
            styleId: 'p2',
            skin: _.get(ps, 'runtimeConfig.tpa.section.defaults.skin', 'skins.core.InlineSkin'),
            layout: {
                x: 0,
                y: 0,
                width: defSize.w,
                height: defSize.h,
                anchors: []
            },
            data: {
                type: 'Page',
                metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false
                },
                hideTitle: true,
                icon: '',
                descriptionSEO: '',
                metaKeywordsSEO: '',
                pageTitleSEO: '',
                pageUriSEO,
                hidePage: _.isBoolean(hidePage) ? hidePage : undefined,
                mobileHidePage: null,
                underConstruction: false,
                tpaApplicationId: appId,
                indexable: _.isBoolean(indexable) ? indexable : true,
                tpaPageId,
                managingAppDefId,
                pageBackgrounds: getPageBackgrounds(ps, definitionData),
                isLandingPage: _.isBoolean(landingPageParams.desktop) ? landingPageParams.desktop : false,
                isMobileLandingPage: _.isBoolean(landingPageParams.mobile) ? landingPageParams.mobile : false
            },
            components: []
        }
        if (requireLogin) {
            pageStructure.data.pageSecurity = {
                requireLogin: true,
                dialogLanguage: 'en'
            }
        }

        return pageStructure
    }

    const getPageBackgrounds = function (ps, definitionData) {
        if (_.get(definitionData, 'appPage.hideFromMenu')) {
            return blankPageStructure.getBlankBackground()
        }
        return _.get(pageData.getPageDataWithoutIds(ps, ps.siteAPI.getPrimaryPageId()), 'pageBackgrounds')
    }

    function getLandingPageParams(widgetData) {
        return {
            desktop: widgetData.appPage.fullPage || _.get(widgetData, 'componentFields.fullPageDesktopOnly'),
            mobile: widgetData.appPage.landingPageInMobile
        }
    }

    const getSubSectionStructure = function (ps, widgetData, sectionId, pageUriSEO, requireLogin, isHidden) {
        const landingPageParams = getLandingPageParams(widgetData)
        const hidePage = clientSpecMapService.computeHidePage(widgetData, isHidden, true)
        const structure = getSectionStructure(
            ps,
            widgetData,
            sectionId,
            widgetData.appPage.id,
            hidePage,
            widgetData.appPage.indexable,
            landingPageParams,
            pageUriSEO,
            requireLogin
        )
        structure.data.title = widgetData.appPage.name
        return structure
    }

    const getMultiSectionStructure = function (ps, widgetData, sectionId, pageUriSEO, requireLogin, isHidden) {
        const landingPageParams = getLandingPageParams(widgetData)
        const tpaPageId = widgetData.appPage.id + tpa.common.utils.Constants.MULTI_SECTION_DELIMITER + sectionId
        const hidePage = clientSpecMapService.computeHidePage(widgetData, isHidden, false)
        return getSectionStructure(ps, widgetData, sectionId, tpaPageId, hidePage, widgetData.appPage.indexable, landingPageParams, pageUriSEO, requireLogin)
    }

    const getWidgetStructure = function (applicationId, appDefinitionId, widgetId, layout, styleId) {
        return {
            layout: {
                width: layout && layout.width,
                height: layout && layout.height,
                x: layout && layout.x,
                y: layout && layout.y,
                anchors: []
            },
            data: {
                widgetId,
                applicationId: applicationId.toString(),
                appDefinitionId,
                type: tpaConstants.DATA_TYPE.TPA_WIDGET,
                metaData: {
                    isHidden: false,
                    isPreset: true,
                    schemaVersion: '1.0'
                }
            },
            type: 'Component',
            skin: tpaConstants.SKINS.TPA_WIDGET,
            componentType: tpaConstants.COMP_TYPES.TPA_WIDGET,
            style: styleId || tpaConstants.STYLE.TPA_WIDGET
        }
    }

    const getGluedWidgetStructure = function (applicationId, appDefinitionId, widgetData, layout, styleId) {
        return {
            layout: {
                width: (layout && layout.width) || 300,
                height: (layout && layout.height) || 200,
                x: (layout && layout.x) || 300,
                y: (layout && layout.y) || 120,
                anchors: [],
                fixedPosition: true
            },
            data: {
                widgetId: widgetData.widgetId,
                applicationId: applicationId.toString(),
                appDefinitionId,
                type: tpaConstants.DATA_TYPE.TPA_WIDGET,
                metaData: {
                    isHidden: false,
                    isPreset: true,
                    schemaVersion: '1.0'
                }
            },
            type: 'Component',
            skin: tpaConstants.SKINS.TPA_WIDGET,
            componentType: tpaConstants.COMP_TYPES.TPA_GLUED_WIDGET,
            style: styleId || tpaConstants.STYLE.TPA_GLUED_WIDGET,
            props: {
                placement: widgetData.gluedOptions.placement,
                verticalMargin: widgetData.gluedOptions.verticalMargin,
                horizontalMargin: widgetData.gluedOptions.horizontalMargin,
                type: 'TPAGluedProperties',
                metaData: {
                    schemaVersion: '1.0'
                }
            }
        }
    }
    const convertStyleIdToStyleDef = function (ps, compDef) {
        const {style} = compDef
        if (_.isString(style)) {
            const allStyles = theme.styles.getAllFromAllPages(ps)
            const styleDef = allStyles[style]
            if (!styleDef) {
                const definition = component.getDefinition(ps, compDef.componentType)
                compDef.style = _(definition.styles).keys().head()
                return
            }
            if (styleDef.styleType === 'custom') {
                compDef.style = _.omit(styleDef, ['id'])
            }
        }
    }

    return {
        getSectionStructure,
        getWidgetStructure,
        getGluedWidgetStructure,
        /**
         * Structure for hidden sub sections
         */
        getSubSectionStructure,
        /**
         * Structure for multi main section
         */
        getMultiSectionStructure,
        convertStyleIdToStyleDef
    }
})
