define([
    'lodash',
    'documentServices/errors/errors',
    'documentServices/utils/utils',
    'documentServices/saveAPI/lib/saveRunner',
    'documentServices/saveAPI/lib/saveState',
    'documentServices/page/page',
    'documentServices/responsiveLayout/responsiveLayout',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/appStudio/appStudioPresets',
    'documentServices/variants/variants',
    'documentServices/appStudio/appStudioAppDataUtils',
    'documentServices/appStudio/widgetDescriptors',
    'documentServices/appStudio/manifestUtils',
    'documentServices/appStudio/widgetConfig',
    'documentServices/appStudio/services/wixBlocksService'
], function (
    _,
    DSErrors,
    utils,
    saveRunner,
    saveState,
    page,
    responsiveLayout,
    appStudioDataModel,
    appStudioPresets,
    variants,
    appStudioAppDataUtils,
    widgetDescriptors,
    manifestUtils,
    widgetConfig,
    wixBlocksService
) {
    'use strict'

    const STUDIO_WIDGET_ID_PATH = 'compData.studioWidgetComponentData.studioWidgetId'

    const {
        INVALID_VERSION_TYPE,
        NO_GRID_APP_ID,
        NO_STUDIO_APP_APP_ID,
        USER_NOT_AUTHORIZED_FOR_APP,
        UNKNOWN_SERVER_ERROR,
        NO_APP_DEF_ID,
        NO_BUILD_ID,
        NO_PACKAGE_IMPORT_NAME
    } = DSErrors.build

    const {SAVE_DISABLED_IN_DOCUMENT_SERVICES, SESSION_EXPIRED, NOT_LOGGED_IN, USER_NOT_AUTHORIZED_FOR_SITE} = DSErrors.save

    const hasWidgetComponentByPageId = (widgetComponents, widgetPageId) =>
        _.some(widgetComponents, {
            compData: {
                studioWidgetComponentData: {
                    studioWidgetId: widgetPageId
                }
            }
        })

    const hasSerializedWidgetByPageId = (widgets, widgetPageId) => _.some(widgets, widget => utils.stripHashIfExists(widget.rootCompId) === widgetPageId)

    const getUnbuiltWidgets = (widgetComponents, widgets) =>
        _(widgets)
            .map(widget => ({
                widgetId: utils.stripHashIfExists(widget.rootCompId),
                name: widget.name
            }))
            .reject(({widgetId}) => hasWidgetComponentByPageId(widgetComponents, widgetId))
            .value()

    const keyExistingWidgetsById = (widgetComponents, widgets) =>
        _(widgetComponents)
            .filter(widgetComponent => hasSerializedWidgetByPageId(widgets, _.get(widgetComponent, STUDIO_WIDGET_ID_PATH)))
            .keyBy(STUDIO_WIDGET_ID_PATH)
            .mapValues('compId')
            .value()

    const partitionWidgetsByIsCreated = async (ps, appDefinitionId) => {
        const widgets = appStudioDataModel.getAllSerializedWidgets(ps)
        const appData = await appStudioAppDataUtils.fetchAppData(ps, appDefinitionId)
        const widgetComponents = _.filter(appData.components, {compType: 'STUDIO_WIDGET'})
        const missingWidgets = getUnbuiltWidgets(widgetComponents, widgets)
        const existingIdsMap = keyExistingWidgetsById(widgetComponents, widgets)
        return {existingIdsMap, missingWidgets}
    }

    const createWidgetComponentData = widget => ({
        compType: 'STUDIO_WIDGET',
        compName: widget.name,
        compData: {
            studioWidgetComponentData: {
                studioWidgetId: widget.widgetId
            }
        }
    })

    const createWidgetComponent = async (ps, appDefinitionId, widget) => {
        const componentData = createWidgetComponentData(widget)
        const newWidgetResult = await appStudioAppDataUtils.postComponentData(ps, appDefinitionId, componentData)
        return newWidgetResult.compId
    }

    const createDevCenterWidgetIdsMap = (widgetPageIds, devCenterWidgetIds) => _(widgetPageIds).zip(devCenterWidgetIds).fromPairs().value()

    // We create the components sequentially because of a bug in dev center REST api.
    // This will be resolved once we move this operation to be an RPC in Blocks Server https://jira.wixpress.com/browse/WBL-846
    const createWidgetComponents = async (ps, appDefinitionId, widgets) => {
        const devCenterWidgetIds = []
        for (const widget of widgets) {
            const newDevCenterWidgetId = await createWidgetComponent(ps, appDefinitionId, widget)
            devCenterWidgetIds.push(newDevCenterWidgetId)
        }

        return devCenterWidgetIds
    }

    const setAppWidgetSettings = (ps, widgetPageId, devCenterWidgetId, isVariation) => {
        const pagePointer = page.getPage(ps, widgetPageId)
        const appWidget = appStudioDataModel.getRootWidgetByPage(ps, pagePointer)
        widgetConfig.update(ps, appWidget, {devCenterWidgetId})
        if (isVariation) {
            widgetConfig.update(ps, appWidget, {variationPageId: widgetPageId})
        }
    }

    const setWidgetRootsSettings = (ps, widget, widgetPageId, devCenterWidgetId) => {
        setAppWidgetSettings(ps, widgetPageId, devCenterWidgetId)

        const serializedWidget = appStudioDataModel.serializeWidget(ps, widget.pointer)

        _.forEach(serializedWidget.variations, variation => {
            setAppWidgetSettings(ps, utils.stripHashIfExists(variation.rootCompId), devCenterWidgetId, true)
        })
    }

    const setDevCenterWidgetIds = (ps, devCenterIdsMap) => {
        _.forEach(devCenterIdsMap, (devCenterWidgetId, widgetPageId) => {
            const widget = appStudioDataModel.findWidgetByPageId(ps, widgetPageId)
            appStudioDataModel.setWidgetDevCenterId(ps, widget.pointer, devCenterWidgetId)
            setWidgetRootsSettings(ps, widget, widgetPageId, devCenterWidgetId)
        })
    }

    const updatePresetsDefaultSize = ps => {
        const widgets = appStudioDataModel.getAllWidgets(ps)
        widgets.forEach(({pointer: widgetPointer}) => {
            const rootContainer = appStudioDataModel.getRootContainerByWidgetPointer(ps, widgetPointer)

            const layout = responsiveLayout.get(ps, rootContainer)

            const widgetMinHeight = _.get(layout, 'componentLayouts.0.minHeight.value', _.get(layout, 'componentLayout.minHeight.value'))

            const presets = appStudioPresets.getWidgetPresets(ps, widgetPointer)

            presets.forEach(({pointer: presetPointer}) => {
                const defaultSize = appStudioPresets.getPresetDefaultSize(ps, presetPointer) || {}

                const vp = appStudioPresets.getPresetVariantPointer(ps, presetPointer, widgetPointer)
                const cvp = variants.getPointerWithVariants(ps, rootContainer, vp)

                const presetStageContainerLayout = responsiveLayout.get(ps, cvp)
                const presetMinHeight = _.get(presetStageContainerLayout, 'componentLayout.minHeight.value', widgetMinHeight)

                const newSize = _.defaultsDeep({height: {value: presetMinHeight, type: 'px'}}, defaultSize)
                appStudioPresets.setPresetDefaultSize(ps, presetPointer, newSize)
            })
        })
    }

    const createMissingDevCenterWidgets = async (ps, appDefinitionId) => {
        const {existingIdsMap, missingWidgets} = await partitionWidgetsByIsCreated(ps, appDefinitionId)
        const newDevCenterIds = await createWidgetComponents(ps, appDefinitionId, missingWidgets)
        const newIdsMap = createDevCenterWidgetIdsMap(_.map(missingWidgets, 'widgetId'), newDevCenterIds)
        return _.merge(existingIdsMap, newIdsMap)
    }

    const preBuild = (ps, appDefinitionId, {onSuccess = _.noop, onError = _.noop} = {}) => {
        appStudioDataModel.updateWidgetContainedWidgets(ps)
        updatePresetsDefaultSize(ps)

        createMissingDevCenterWidgets(ps, appDefinitionId)
            .then(devCenterWidgetIdsMap => {
                setDevCenterWidgetIds(ps, devCenterWidgetIdsMap)
                onSuccess()
            })
            .catch(onError)
    }

    const BUILD_ERROR_TYPES = {
        '-12': SESSION_EXPIRED,
        '-15': NOT_LOGGED_IN,
        '-17': USER_NOT_AUTHORIZED_FOR_SITE,
        '-10198': NO_GRID_APP_ID,
        '-15053': NO_STUDIO_APP_APP_ID,
        '-15054': USER_NOT_AUTHORIZED_FOR_APP
    }

    const getBuildError = ({errorCode, errorDescription, errorType = UNKNOWN_SERVER_ERROR, applicationError = undefined}) =>
        new BuildError({
            errorCode,
            errorDescription,
            errorType: BUILD_ERROR_TYPES[errorCode] || errorType,
            applicationError
        })

    const VERSION_TYPES = {
        minor: 'minor',
        major: 'major',
        build: 'build',
        test: 'test'
    }

    function isVersionTypeValid(versionType) {
        return !!VERSION_TYPES[versionType]
    }

    class BuildError extends Error {
        constructor(error) {
            super('Build Error')
            this.errorCode = error.errorCode
            this.errorType = error.errorType
            this.errorDescription = error.errorDescription
            this.applicationError = error.applicationError
        }
    }

    function getWidgetsMapForBuild(ps) {
        const allWidgets = appStudioDataModel.getAllWidgets(ps)

        return _.reduce(
            allWidgets,
            (accumulator, {pointer, variations, name}) => {
                const widgetRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, pointer)
                const variationRootCompIds = _.map(variations, variationId => {
                    const variationPointer = ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
                    return appStudioDataModel.getRootCompIdByPointer(ps, variationPointer)
                })
                const manifestInfo = manifestUtils.getWidgetManifest(ps, pointer)
                const widgetPublicDescriptor = widgetDescriptors.createPublicDescriptor(ps, pointer)
                accumulator[widgetRootCompId] = {
                    widgetId: widgetRootCompId,
                    variations: variationRootCompIds,
                    manifestInfo,
                    widgetPublicDescriptor,
                    name
                }
                return accumulator
            },
            {}
        )
    }

    function getCodePackageData(ps, packageImportName) {
        const codePackageData = _.head(appStudioDataModel.getAppStudioData(ps)?.codePackages)
        if (!codePackageData) {
            return undefined
        }

        if (!packageImportName) {
            throw getBuildError({
                errorCode: '-1',
                errorDescription: 'packageImportName must being passed',
                errorType: NO_PACKAGE_IMPORT_NAME
            })
        }

        return {
            packageDisplayName: codePackageData.displayName,
            packageImportName,
            description: codePackageData.description
        }
    }

    function performBuild(ps, options) {
        const {versionType, appMarketingName, blocksVersion, appDefId, isAsyncBuild, releaseNotes, publishRc, packageImportName} = options

        return new Promise((resolve, reject) => {
            if (!saveState.canSave(ps)) {
                reject(
                    getBuildError({
                        errorCode: '-1',
                        errorDescription: 'DocumentServices was created with parameter disableSave=true, so build is disabled.',
                        errorType: SAVE_DISABLED_IN_DOCUMENT_SERVICES
                    })
                )
                return
            }
            const widgetsMap = getWidgetsMapForBuild(ps)

            const codePackage = getCodePackageData(ps, packageImportName)

            // TODO this is temporary until WixCode is platformised and can accept metasite signed instance as Authorization header: https://jira.wixpress.com/browse/WEED-20081
            // const {instance} = platform.getAppDataByApplicationId(ps, platformConstants.APPS.META_SITE.applicationId)

            wixBlocksService.postBuildRequest(
                ps,
                {
                    widgetsMap,
                    appMarketingName,
                    blocksVersion,
                    versionType,
                    codePackage,
                    appDefId,
                    isAsyncBuild,
                    releaseNotes,
                    publishRc
                },
                resolve,
                reject,
                getBuildError
            )
        })
    }

    function buildWithOptions(ps, options) {
        const {onSuccess, onError, versionType, appDefId} = options

        if (!appDefId) {
            onError(
                getBuildError({
                    errorCode: '-1',
                    errorDescription: 'appDefId must being passed',
                    errorType: NO_APP_DEF_ID
                })
            )
            return
        }

        if (isVersionTypeValid(versionType)) {
            saveRunner.runFunctionInSaveQueue(_.partial(performBuild, ps, options)).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
        } else {
            onError(
                getBuildError({
                    errorCode: '-1',
                    errorDescription: 'Version type should be one of: "build", "minor" "major".',
                    errorType: INVALID_VERSION_TYPE
                })
            )
        }
    }

    function build(ps, onSuccess, onError, versionType, appMarketingName, blocksVersion = '0.0.1', appDefId, isAsyncBuild, releaseNotes, publishRc) {
        const options = {
            onSuccess,
            onError,
            versionType,
            appMarketingName,
            blocksVersion,
            appDefId,
            isAsyncBuild,
            releaseNotes,
            publishRc
        }

        buildWithOptions(ps, options)
    }

    async function getBuildStatusById(ps, buildId, appDefinitionId) {
        if (!buildId) {
            throw getBuildError({
                errorCode: '-1',
                errorDescription: 'buildId must being passed',
                errorType: NO_BUILD_ID
            })
        }

        if (!appDefinitionId) {
            throw getBuildError({
                errorCode: '-1',
                errorDescription: 'appDefId must being passed',
                errorType: NO_APP_DEF_ID
            })
        }

        return saveRunner.runFunctionInSaveQueue(() => wixBlocksService.performGetBuildById(ps, buildId, appDefinitionId, getBuildError))
    }

    return {
        preBuild,
        buildWithOptions,
        build,
        getBuildStatusById
    }
})
