define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/structure/structure',
    'documentServices/constants/constants',
    'documentServices/responsiveLayout/validations',
    'documentServices/variants/variantsUtils',
    'documentServices/hooks/hooks',
    'documentServices/componentsMetaData/metaDataUtils',
    'experiment'
], function (_, dataModel, structure, constants, validations, variantsUtils, hooks, metaDataUtils, experiment) {
    'use strict'

    const getResponsiveLayout = (ps, compPointer) => variantsUtils.getComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)

    const updateResponsiveLayout = (ps, compPointer, newLayout) => {
        validations.validateSingleLayoutObject(newLayout)
        const compType = metaDataUtils.getComponentType(ps, compPointer)
        const modifiedNewLayout = hooks.executeHookAndUpdateValue(ps, hooks.HOOKS.RESPONSIVE_LAYOUT.BEFORE_UPDATE, compType, [compPointer], newLayout)
        const toUpdate = dataModel.cleanLayoutAdditionalProps(ps, modifiedNewLayout)
        return variantsUtils.updateComponentDataConsideringVariants(ps, compPointer, toUpdate, constants.DATA_TYPES.layout)
    }

    const pinComponent = (ps, compPointer, newLayout) => {
        if (!experiment.isOpen('dm_removeLayoutPinValidations')) {
            const currentLayout = getResponsiveLayout(ps, compPointer)
            validations.validatedPinnedComponent(currentLayout, newLayout)
        }
        structure.reparentComponentToPage(ps, compPointer, false)
        return updateResponsiveLayout(ps, compPointer, newLayout)
    }

    const unpinComponent = (ps, compPointer, newContainerPointer, newLayout) => {
        if (!experiment.isOpen('dm_removeLayoutPinValidations')) {
            const currentLayout = getResponsiveLayout(ps, compPointer)
            validations.validateUnPinnedComponent(currentLayout, newLayout)
        }
        structure.setContainer(ps, null, compPointer, newContainerPointer)
        return updateResponsiveLayout(ps, compPointer, newLayout)
    }

    const removeScopedLayout = (ps, compPointer) => {
        const isWithVariants = ps.pointers.components.isWithVariants(compPointer)
        if (!isWithVariants) {
            throw new Error('cannot remove non scoped layout')
        }
        variantsUtils.removeComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)
    }

    return {
        get: getResponsiveLayout,
        update: updateResponsiveLayout,
        pin: pinComponent,
        unpin: unpinComponent,
        isNewLayoutMigration: () => true,
        removeScopedLayout,

        // runtime
        measure: (ps, compPointer) => ({
            boundingBox: ps.siteAPI.getComponentBoundingBox(compPointer.id),
            containerMeasures: ps.siteAPI.responsive.getGridMeasures(compPointer.id)
        }),
        getBoundingBox: (ps, compPointer) => ps.siteAPI.getComponentBoundingBox(compPointer.id),
        getNonRotatedBoundingBox: (ps, compPointer) => ps.siteAPI.getOriginalComponentBoundingBox(compPointer.id),
        getRelativeToViewportBoundingBox: (ps, compPointer) => ps.siteAPI.getRelativeToViewportBoundingBox(compPointer.id),
        getPadding: (ps, compPointer) => ps.siteAPI.getPadding(compPointer.id),
        getScrollHeight: (ps, compPointer) => ps.siteAPI.responsive.getScrollHeight(compPointer.id),
        getClientHeight: (ps, compPointer) => ps.siteAPI.responsive.getClientHeight(compPointer.id),
        getScrollWidth: (ps, compPointer) => ps.siteAPI.responsive.getScrollWidth(compPointer.id),
        getClientWidth: (ps, compPointer) => ps.siteAPI.responsive.getClientWidth(compPointer.id),
        getGridMeasures: (ps, compPointer) => ps.siteAPI.responsive.getGridMeasures(compPointer.id),
        detachLayout: (ps, compPointer) => {
            const parentPointer = ps.pointers.components.getParent(compPointer)
            ps.siteAPI.responsive.detach(compPointer.id, parentPointer.id)
        },
        detachComponents: (ps, componentsPointers) => {
            ps.siteAPI.responsive.detachMulti(_.map(componentsPointers, 'id'))
        },
        updateDetachedLayout: (ps, detachedCompPointer, boundingBox) => ps.siteAPI.responsive.updateDetached(detachedCompPointer.id, boundingBox),
        updateDetachedRotation: (ps, detachedCompPointer, rotationsInDegrees) =>
            ps.siteAPI.responsive.updateDetachedRotation(detachedCompPointer.id, rotationsInDegrees),
        updateDetachedTransformation: (ps, detachedCompPointer, transformationOverrides) =>
            ps.siteAPI.responsive.updateDetachedTransformation(detachedCompPointer.id, transformationOverrides),
        reattachLayout: (ps, detachedCompPointer) => {
            ps.siteAPI.responsive.clearDetached(detachedCompPointer.id)
        },
        reattachComponents: ps => {
            ps.siteAPI.responsive.clearDetached()
        },
        detachGridItem: (ps, compPointer) => {
            const parentPointer = ps.pointers.components.getParent(compPointer)
            ps.siteAPI.responsive.detachGridItem(compPointer.id, parentPointer.id)
        },
        updateDetachedGridItem: (ps, detachedCompPointer, boundingBox) => ps.siteAPI.responsive.updateDetachedGridItem(detachedCompPointer.id, boundingBox),
        reattachGridItem: (ps, detachedCompPointer) => {
            ps.siteAPI.responsive.clearDetachedGridItem(detachedCompPointer.id)
        },
        detachStackItem: (ps, compPointer) => {
            const parentPointer = ps.pointers.components.getParent(compPointer)
            ps.siteAPI.responsive.detachStackItem(compPointer.id, parentPointer.id)
        },
        updateDetachedStackItem: (ps, detachedCompPointer, boundingBox) => ps.siteAPI.responsive.updateDetachedStackItem(detachedCompPointer.id, boundingBox),
        updateDetachedStackItemOrder: (ps, detachedCompPointer, order) => ps.siteAPI.responsive.updateDetachedStackItemOrder(detachedCompPointer.id, order),
        reattachStackItem: (ps, detachedCompPointer) => {
            ps.siteAPI.responsive.clearDetachedStackItem(detachedCompPointer.id)
        }
    }
})
