define([
    'lodash',
    'documentServices/componentsMetaData/components/ebayItemsBySellerMetaData',
    'documentServices/componentsMetaData/components/verticalAnchorsMenuMetaData',
    'documentServices/componentsMetaData/components/adminLoginButtonMetaData',
    'documentServices/componentsMetaData/components/bgImageStripMetaData',
    'documentServices/componentsMetaData/components/contactFormMetaData',
    'documentServices/componentsMetaData/components/facebookLikeBoxMetaData',
    'documentServices/componentsMetaData/components/facebookLikeMetaData',
    'documentServices/componentsMetaData/components/facebookShareMetaData',
    'documentServices/componentsMetaData/components/fiveGridLineMetaData',
    'documentServices/componentsMetaData/components/flickrBadgeWidgetMetaData',
    'documentServices/componentsMetaData/components/headerMetaData',
    'documentServices/componentsMetaData/components/footerMetaData',
    'documentServices/componentsMetaData/components/imageButtonMetaData',
    'documentServices/componentsMetaData/components/itunesButtonMetaData',
    'documentServices/componentsMetaData/components/linkBarMetaData',
    'documentServices/componentsMetaData/components/loginButtonMetaData',
    'documentServices/componentsMetaData/components/loginSocialBarMetadata',
    'documentServices/componentsMetaData/components/onlineClockMetaData',
    'documentServices/componentsMetaData/components/pageGroupMetaData',
    'documentServices/componentsMetaData/components/pageMetaData',
    'documentServices/componentsMetaData/components/groupMetaData',
    'documentServices/componentsMetaData/components/flashComponentMetaData',
    'documentServices/componentsMetaData/components/pagesContainerMetaData',
    'documentServices/componentsMetaData/components/payPalButtonMetaData',
    'documentServices/componentsMetaData/components/pinItPinWidgetMetaData',
    'documentServices/componentsMetaData/components/pinterestFollowMetaData',
    'documentServices/componentsMetaData/components/pinterestPinItMetaData',
    'documentServices/componentsMetaData/components/rssButtonMetaData',
    'documentServices/componentsMetaData/components/screenWidthContainerMetaData',
    'documentServices/componentsMetaData/components/stripContainerMetaData',
    'documentServices/componentsMetaData/components/singleAudioPlayerMetaData',
    'documentServices/componentsMetaData/components/siteButtonMetaData',
    'documentServices/componentsMetaData/components/siteSegmentContainerMetaData',
    'documentServices/componentsMetaData/components/siteRegionContainerMetaData',
    'documentServices/componentsMetaData/components/siteStructureMetaData',
    'documentServices/componentsMetaData/components/skypeCallButtonMetaData',
    'documentServices/componentsMetaData/components/slideShowGalleryMetaData',
    'documentServices/componentsMetaData/components/thumbnailsMetaData',
    'documentServices/componentsMetaData/components/accordionMetaData',
    'documentServices/componentsMetaData/components/htmlComponentMetaData',
    'documentServices/componentsMetaData/components/googleMapMetaData',
    'documentServices/componentsMetaData/components/spotifyFollowMetaData',
    'documentServices/componentsMetaData/components/subscribeFormMetaData',
    'documentServices/componentsMetaData/components/svgShapeMetaData',
    'documentServices/componentsMetaData/components/vectorImageMetaData',
    'documentServices/componentsMetaData/components/tinyMenuMetaData',
    'documentServices/componentsMetaData/components/backToTopButtonLegacyMetaData',
    'documentServices/componentsMetaData/components/backToTopButtonMetaData',
    'documentServices/componentsMetaData/components/verticalLineMetaData',
    'documentServices/componentsMetaData/components/vKShareButtonMetaData',
    'documentServices/componentsMetaData/components/weatherMetaData',
    'documentServices/componentsMetaData/components/wFacebookCommentMetaData',
    'documentServices/componentsMetaData/components/disqusCommentsMetaData',
    'documentServices/componentsMetaData/components/radioGroupMetaData',
    'documentServices/componentsMetaData/components/checkboxGroupMetaData',
    'documentServices/componentsMetaData/components/wGooglePlusOneMetaData',
    'documentServices/componentsMetaData/components/wPhotoMetaData',
    'documentServices/componentsMetaData/components/wTwitterFollowMetaData',
    'documentServices/componentsMetaData/components/wTwitterTweetMetaData',
    'documentServices/componentsMetaData/components/youTubeSubscribeButtonMetaData',
    'documentServices/componentsMetaData/components/tpaWidgetMetaData',
    'documentServices/componentsMetaData/components/tpaGluedWidgetMetaData',
    'documentServices/componentsMetaData/components/tpaSectionMetaData',
    'documentServices/componentsMetaData/components/appPageMetaData',
    'documentServices/componentsMetaData/components/appPartMetaData',
    'documentServices/componentsMetaData/components/appPart2MetaData',
    'documentServices/componentsMetaData/components/anchorMetaData',
    'documentServices/componentsMetaData/components/masonryMetaData',
    'documentServices/componentsMetaData/components/freestyleMetaData',
    'documentServices/componentsMetaData/components/stripGallerySlideShowMetaData',
    'documentServices/componentsMetaData/components/stripShowCaseMetaData',
    'documentServices/componentsMetaData/components/collageMetaData',
    'documentServices/componentsMetaData/components/3DCarouselMetaData',
    'documentServices/componentsMetaData/components/videoMetaData',
    'documentServices/componentsMetaData/components/matrixGalleryMetaData',
    'documentServices/componentsMetaData/components/documentMediaMetaData',
    'documentServices/componentsMetaData/components/spotifyPlayerMetaData',
    'documentServices/componentsMetaData/components/soundCloudMetaData',
    'documentServices/componentsMetaData/components/wRichTextMetaData',
    'documentServices/componentsMetaData/components/verticalMenuMetaData',
    'documentServices/componentsMetaData/components/expandableMenuMetaData',
    'documentServices/componentsMetaData/components/dropDownMenuMetaData',
    'documentServices/componentsMetaData/components/sliderGalleryMetaData',
    'documentServices/componentsMetaData/components/PaginatedGridGalleryMetaData',
    'documentServices/componentsMetaData/components/containerMetaData',
    'documentServices/componentsMetaData/components/popupContainerMetaData',
    'documentServices/componentsMetaData/components/audioPlayerMetaData',
    'documentServices/componentsMetaData/components/clipArtMetaData',
    'documentServices/componentsMetaData/components/boxSlideShowSlideMetaData',
    'documentServices/componentsMetaData/components/boxSlideShowMetaData',
    'documentServices/componentsMetaData/components/stateBoxStateMetaData',
    'documentServices/componentsMetaData/components/stateBoxFormStateMetaData',
    'documentServices/componentsMetaData/components/stateBoxMetaData',
    'documentServices/componentsMetaData/components/stateStripStateMetaData',
    'documentServices/componentsMetaData/components/stateStripMetaData',
    'documentServices/componentsMetaData/components/stripSlideShowSlideMetaData',
    'documentServices/componentsMetaData/components/quickActionBarMetaData',
    'documentServices/componentsMetaData/components/quickActionBarItemMetaData',
    'documentServices/componentsMetaData/components/stripSlideShowMetaData',
    'documentServices/componentsMetaData/components/stripColumnsMetaData',
    'documentServices/componentsMetaData/components/textInputMetadata',
    'documentServices/componentsMetaData/components/textAreaMetadata',
    'documentServices/componentsMetaData/components/fileUploaderMetaData',
    'documentServices/componentsMetaData/components/checkboxMetadata',
    'documentServices/componentsMetaData/components/datePickerMetadata',
    'documentServices/componentsMetaData/components/columnMetaData',
    'documentServices/componentsMetaData/components/mediaPlayerMetaData',
    'documentServices/componentsMetaData/components/mediaControlsMetaData',
    'documentServices/componentsMetaData/components/mediaOverlayControlsMetaData',
    'documentServices/componentsMetaData/components/controllerMetaData',
    'documentServices/componentsMetaData/components/wixappsOnlyMetaData',
    'documentServices/componentsMetaData/components/hoverboxMetaData',
    'documentServices/componentsMetaData/components/popoverMetaData',
    'documentServices/componentsMetaData/components/repeaterMetaData',
    'documentServices/componentsMetaData/components/mediaContainerMetaData',
    'documentServices/componentsMetaData/components/gridMetaData',
    'documentServices/componentsMetaData/components/languageSelectorMetaData',
    'documentServices/componentsMetaData/components/formContainerMetaData',
    'documentServices/componentsMetaData/components/appWidgetMetaData',
    'documentServices/componentsMetaData/components/inlinePopupToggleMetaData',
    'documentServices/componentsMetaData/components/menuToggleMetaData',
    'documentServices/componentsMetaData/components/menuContainerMetaData',
    'documentServices/componentsMetaData/components/sectionMetaData',
    'documentServices/componentsMetaData/components/refComponentMetaData',
    'documentServices/componentsMetaData/components/classicSectionMetadata',
    'documentServices/componentsMetaData/externalComponentsMetaData'
], function (
    _,
    ebayItemsBySellerMetaData,
    verticalAnchorsMenuMetaData,
    adminLoginButtonMetaData,
    bgImageStripMetaData,
    contactFormMetaData,
    facebookLikeBoxMetaData,
    facebookLikeMetaData,
    facebookShareMetaData,
    fiveGridLineMetaData,
    flickrBadgeWidgetMetaData,
    headerMetaData,
    footerMetaData,
    imageButtonMetaData,
    itunesButtonMetaData,
    linkBarMetaData,
    loginButtonMetaData,
    loginSocialBarMetadata,
    onlineClockMetaData,
    pageGroupMetaData,
    pageMetaData,
    groupMetaData,
    flashComponentMetaData,
    pagesContainerMetaData,
    payPalButtonMetaData,
    pinItPinWidgetMetaData,
    pinterestFollowMetaData,
    pinterestPinItMetaData,
    rssButtonMetaData,
    screenWidthContainerMetaData,
    stripContainerMetaData,
    singleAudioPlayerMetaData,
    siteButtonMetaData,
    siteSegmentContainerMetaData,
    siteRegionContainerMetaData,
    siteStructureMetaData,
    skypeCallButtonMetaData,
    slideShowGalleryMetaData,
    thumbnailsMetaData,
    accordionMetaData,
    htmlComponentMetaData,
    googleMapMetaData,
    spotifyFollowMetaData,
    subscribeFormMetaData,
    svgShapeMetaData,
    vectorImageMetaData,
    tinyMenuMetaData,
    backToTopButtonLegacyMetaData,
    backToTopButtonMetaData,
    verticalLineMetaData,
    vKShareButtonMetaData,
    weatherMetaData,
    wFacebookCommentMetaData,
    disqusCommentsMetaData,
    radioGroupMetaData,
    checkboxGroupMetaData,
    wGooglePlusOneMetaData,
    wPhotoMetaData,
    wTwitterFollowMetaData,
    wTwitterTweetMetaData,
    youTubeSubscribeButtonMetaData,
    tpaWidgetMetaData,
    tpaGluedWidgetMetaData,
    tpaSectionMetaData,
    appPageMetaData,
    appPartMetaData,
    appPart2MetaData,
    anchorMetaData,
    masonryMetaData,
    freestyleMetaData,
    stripGallerySlideShowMetaData,
    stripShowCaseMetaData,
    collageMetaData,
    threeDCarouselMetaData,
    videoMetaData,
    matrixGallery,
    documentMedia,
    spotifyPlayer,
    soundCloudMetaData,
    wRichTextMetaData,
    verticalMenuMetaData,
    expandableMenuMetaData,
    dropDownMenuMetaData,
    sliderGalleryMetaData,
    PaginatedGridGalleryMetaData,
    containerMetaData,
    popupContainerMetaData,
    audioPlayerMetaData,
    clipArtMetaData,
    boxSlideShowSlideMetaData,
    boxSlideShowMetaData,
    stateBoxStateMetaData,
    stateBoxFormStateMetaData,
    stateBoxMetaData,
    stateStripStateMetaData,
    stateStripMetaData,
    stripSlideShowSlideMetaData,
    quickActionBarMetaData,
    quickActionBarItemMetaData,
    stripSlideShowMetaData,
    stripColumnsMetaData,
    textInputMetadata,
    textAreaMetadata,
    fileUploaderMetaData,
    checkboxMetadata,
    datePickerMetadata,
    columnMetadata,
    mediaPlayerMetadata,
    mediaControlsMetadata,
    mediaOverlayControlsMetadata,
    controllerMetaData,
    wixappsOnlyMetaData,
    hoverboxMetaData,
    popoverMetaData,
    repeaterMetaData,
    mediaContainerMetaData,
    gridMetaData,
    languageSelectorMetaData,
    formContainerMetaData,
    appWidgetMetaData,
    inlinePopupToggleMetaData,
    menuToggleMetaData,
    menuContainerMetaData,
    sectionMetaData,
    refComponentMetaData,
    classicSectionMetadata,
    externalComponentsMetaData
) {
    'use strict'

    // compType -> attributesMap -> attribute -> value/function
    return _.defaults(
        {
            'wysiwyg.viewer.components.HeaderContainer': headerMetaData,
            'wysiwyg.viewer.components.EbayItemsBySeller': ebayItemsBySellerMetaData,
            'wysiwyg.viewer.components.FooterContainer': footerMetaData,
            'wysiwyg.viewer.components.AdminLoginButton': adminLoginButtonMetaData,
            'wysiwyg.viewer.components.LoginButton': loginButtonMetaData,
            'wysiwyg.viewer.components.BgImageStrip': bgImageStripMetaData,
            'wysiwyg.viewer.components.ContactForm': contactFormMetaData,
            'wysiwyg.viewer.components.DynamicContactForm': contactFormMetaData,
            'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox': facebookLikeBoxMetaData,
            'wysiwyg.viewer.components.WFacebookLike': facebookLikeMetaData,
            'wysiwyg.viewer.components.FacebookShare': facebookShareMetaData,
            'wysiwyg.viewer.components.FiveGridLine': fiveGridLineMetaData,
            'wysiwyg.viewer.components.FlickrBadgeWidget': flickrBadgeWidgetMetaData,
            'wysiwyg.common.components.imagebutton.viewer.ImageButton': imageButtonMetaData,
            'wysiwyg.viewer.components.ItunesButton': itunesButtonMetaData,
            'wysiwyg.viewer.components.LinkBar': linkBarMetaData,
            'wysiwyg.viewer.components.LoginSocialBar': loginSocialBarMetadata,
            'wysiwyg.common.components.onlineclock.viewer.OnlineClock': onlineClockMetaData,
            'wysiwyg.viewer.components.PageGroup': pageGroupMetaData,
            'mobile.core.components.Page': pageMetaData,
            'wysiwyg.viewer.components.Group': groupMetaData,
            'wysiwyg.viewer.components.FlashComponent': flashComponentMetaData,
            'wysiwyg.viewer.components.PagesContainer': pagesContainerMetaData,
            'wysiwyg.viewer.components.PayPalButton': payPalButtonMetaData,
            'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget': pinItPinWidgetMetaData,
            'wysiwyg.viewer.components.PinterestFollow': pinterestFollowMetaData,
            'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt': pinterestPinItMetaData,
            'wysiwyg.common.components.rssbutton.viewer.RSSButton': rssButtonMetaData,
            'wysiwyg.viewer.components.ScreenWidthContainer': screenWidthContainerMetaData,
            'wysiwyg.viewer.components.StripContainer': stripContainerMetaData,
            'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': singleAudioPlayerMetaData,
            'wysiwyg.viewer.components.SiteButton': siteButtonMetaData,
            'wysiwyg.viewer.components.SiteSegmentContainer': siteSegmentContainerMetaData,
            'wysiwyg.viewer.components.SiteRegionContainer': siteRegionContainerMetaData,
            'wysiwyg.viewer.components.WSiteStructure': siteStructureMetaData,
            'wysiwyg.common.components.skypecallbutton.viewer.SkypeCallButton': skypeCallButtonMetaData,
            'wysiwyg.viewer.components.SlideShowGallery': slideShowGalleryMetaData,
            'tpa.viewer.components.Thumbnails': thumbnailsMetaData,
            'tpa.viewer.components.Accordion': accordionMetaData,
            'wysiwyg.viewer.components.HtmlComponent': htmlComponentMetaData,
            'wysiwyg.viewer.components.GoogleMap': googleMapMetaData,
            'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow': spotifyFollowMetaData,
            'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': subscribeFormMetaData,
            'wysiwyg.viewer.components.svgshape.SvgShape': svgShapeMetaData,
            'wysiwyg.viewer.components.VectorImage': vectorImageMetaData,
            'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuMetaData,
            'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': backToTopButtonLegacyMetaData,
            'wysiwyg.viewer.components.BackToTopButton': backToTopButtonMetaData,
            'wysiwyg.viewer.components.VerticalLine': verticalLineMetaData,
            'wysiwyg.viewer.components.VKShareButton': vKShareButtonMetaData,
            'wysiwyg.common.components.weather.viewer.Weather': weatherMetaData,
            'wysiwyg.viewer.components.WFacebookComment': wFacebookCommentMetaData,
            'wysiwyg.common.components.disquscomments.viewer.DisqusComments': disqusCommentsMetaData,
            'wysiwyg.viewer.components.WGooglePlusOne': wGooglePlusOneMetaData,
            'wysiwyg.viewer.components.WPhoto': wPhotoMetaData,
            'wysiwyg.viewer.components.ClipArt': clipArtMetaData,
            'wysiwyg.viewer.components.WTwitterFollow': wTwitterFollowMetaData,
            'wysiwyg.viewer.components.WTwitterTweet': wTwitterTweetMetaData,
            'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton': youTubeSubscribeButtonMetaData,
            'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
            'tpa.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData, // alias
            'wysiwyg.viewer.components.tpapps.TPASection': tpaSectionMetaData,
            'wysiwyg.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
            'tpa.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData, // alias
            'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSectionMetaData,
            'wixapps.integration.components.AppPage': appPageMetaData,
            'wixapps.integration.components.AppPart': appPartMetaData,
            'wixapps.integration.components.AppPart2': appPart2MetaData,
            'wysiwyg.common.components.anchor.viewer.Anchor': anchorMetaData,
            'tpa.viewer.components.StripSlideshow': stripGallerySlideShowMetaData,
            'tpa.viewer.components.StripShowcase': stripShowCaseMetaData,
            'tpa.viewer.components.Collage': collageMetaData,
            'tpa.viewer.components.Masonry': masonryMetaData,
            'tpa.viewer.components.Freestyle': freestyleMetaData,
            'wysiwyg.viewer.components.tpapps.TPA3DCarousel': threeDCarouselMetaData,
            'wysiwyg.viewer.components.Video': videoMetaData,
            'wysiwyg.viewer.components.MatrixGallery': matrixGallery,
            'wysiwyg.viewer.components.documentmedia.DocumentMedia': documentMedia,
            'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer': spotifyPlayer,
            'wysiwyg.viewer.components.SoundCloudWidget': soundCloudMetaData,
            'wysiwyg.viewer.components.WRichText': wRichTextMetaData,
            'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': verticalMenuMetaData,
            'wysiwyg.viewer.components.ExpandableMenu': expandableMenuMetaData,
            'wysiwyg.viewer.components.menus.DropDownMenu': dropDownMenuMetaData,
            'wysiwyg.viewer.components.SliderGallery': sliderGalleryMetaData,
            'wysiwyg.viewer.components.PaginatedGridGallery': PaginatedGridGalleryMetaData,
            'mobile.core.components.Container': containerMetaData,
            'wysiwyg.viewer.components.PopupContainer': popupContainerMetaData,
            'wysiwyg.viewer.components.PopupCloseTextButton': siteButtonMetaData,
            'wysiwyg.viewer.components.AudioPlayer': audioPlayerMetaData,
            'wysiwyg.viewer.components.BoxSlideShowSlide': boxSlideShowSlideMetaData,
            'wysiwyg.viewer.components.BoxSlideShow': boxSlideShowMetaData,
            'wysiwyg.viewer.components.StateBoxState': stateBoxStateMetaData,
            'wysiwyg.viewer.components.StateBoxFormState': stateBoxFormStateMetaData,
            'wysiwyg.viewer.components.StateBox': stateBoxMetaData,
            'wysiwyg.viewer.components.StateStripState': stateStripStateMetaData,
            'wysiwyg.viewer.components.StateStrip': stateStripMetaData,
            'wysiwyg.viewer.components.StripContainerSlideShowSlide': stripSlideShowSlideMetaData,
            'wysiwyg.viewer.components.QuickActionBar': quickActionBarMetaData,
            'wysiwyg.viewer.components.QuickActionBarItem': quickActionBarItemMetaData,
            'wysiwyg.viewer.components.StripContainerSlideShow': stripSlideShowMetaData,
            'wysiwyg.viewer.components.StripColumnsContainer': stripColumnsMetaData,
            'wysiwyg.viewer.components.ClassicSection': classicSectionMetadata,
            'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': verticalAnchorsMenuMetaData,
            'wysiwyg.viewer.components.inputs.TextInput': textInputMetadata,
            'wysiwyg.viewer.components.inputs.TextAreaInput': textAreaMetadata,
            'wysiwyg.viewer.components.inputs.FileUploader': fileUploaderMetaData,
            'wysiwyg.viewer.components.inputs.ComboBoxInput': textInputMetadata,
            'wysiwyg.viewer.components.inputs.Checkbox': checkboxMetadata,
            'wysiwyg.viewer.components.inputs.DatePicker': datePickerMetadata,
            'wysiwyg.viewer.components.Column': columnMetadata,
            'wysiwyg.viewer.components.MediaControls': mediaControlsMetadata,
            'wysiwyg.viewer.components.MediaOverlayControls': mediaOverlayControlsMetadata,
            'wysiwyg.viewer.components.MediaPlayer': mediaPlayerMetadata,
            'platform.components.AppController': controllerMetaData,
            'wysiwyg.viewer.components.ImageButtonWithText': wixappsOnlyMetaData,
            'wysiwyg.viewer.components.inputs.RadioButton': textInputMetadata,
            'wysiwyg.viewer.components.HoverBox': hoverboxMetaData,
            'wysiwyg.viewer.components.Popover': popoverMetaData,
            'wysiwyg.viewer.components.inputs.RadioGroup': radioGroupMetaData,
            'wysiwyg.viewer.components.Repeater': repeaterMetaData,
            'wysiwyg.viewer.components.MediaContainer': mediaContainerMetaData,
            'wysiwyg.viewer.components.Grid': gridMetaData,
            'wysiwyg.viewer.components.LanguageSelector': languageSelectorMetaData,
            'wysiwyg.viewer.components.FormContainer': formContainerMetaData,
            'wysiwyg.viewer.components.inputs.CheckboxGroup': checkboxGroupMetaData,
            'wysiwyg.viewer.components.InlinePopupToggle': inlinePopupToggleMetaData,
            'wysiwyg.viewer.components.MenuToggle': menuToggleMetaData,
            'wysiwyg.viewer.components.MenuContainer': menuContainerMetaData,
            'platform.components.AppWidget': appWidgetMetaData,
            'wysiwyg.viewer.components.RefComponent': refComponentMetaData,
            'responsive.components.Section': sectionMetaData,
            'responsive.components.HeaderSection': sectionMetaData,
            'responsive.components.FooterSection': sectionMetaData,
            'responsive.components.MembersAreaSection': sectionMetaData
        },
        externalComponentsMetaData.getMetaDataMap()
    )
})
