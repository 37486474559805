define(['lodash', '@wix/santa-core-utils', 'documentServices/page/pageData'], function (_, santaCoreUtils, pageData) {
    'use strict'

    function generateRouterId(allRouters) {
        let routerId = santaCoreUtils.guidUtils.getUniqueId('routers', '-')

        while (_(allRouters).keys().includes(routerId)) {
            routerId = santaCoreUtils.guidUtils.getUniqueId('routers', '-')
        }
        return routerId
    }

    function getAllRouterPrefixes(ps) {
        const routersPointer = ps.pointers.routers.getRoutersConfigMapPointer()
        return _.map(ps.dal.get(routersPointer), 'prefix')
    }

    function isPrefixExist(ps, prefix) {
        const allRoutersPrefixes = getAllRouterPrefixes(ps)
        return _.includes(allRoutersPrefixes, prefix)
    }

    const isPageUriSEOExist = (ps, prefix) => {
        const allPageIds = pageData.getPagesList(ps, true)
        const allPageUriSEO = _.map(allPageIds, function (pageId) {
            return pageData.getPageUriSEO(ps, pageId)
        })

        return _.includes(allPageUriSEO, prefix)
    }

    const validateNewRouter = (ps, router) => {
        if (!router.prefix) {
            throw new Error('Router not valid - Missing prefix.')
        }

        if (!router.appDefinitionId) {
            throw new Error('Router not valid - Missing appDefinitionId.')
        }
        if (!_.isNil(router.hide) && !_.isBoolean(router.hide)) {
            throw new Error('Router not valid - hide parameter need to be boolean')
        }

        if (router.pages) {
            throw new Error('Router not valid - pages should not be on the router object')
        }

        validateUpdatedRouter(ps, router)
    }

    const validateUpdatedRouter = (ps, updateRouterData) => {
        if (isPrefixExist(ps, updateRouterData.prefix)) {
            throw new Error(`Router not valid - Prefix: ${updateRouterData.prefix}, already exist`)
        }

        if (isPageUriSEOExist(ps, updateRouterData.prefix)) {
            throw new Error(`Router not valid - Page Uri SEO: ${updateRouterData.prefix}, already exist.`)
        }
    }

    return {
        generateRouterId,
        validateUpdatedRouter,
        validateNewRouter
    }
})
