define([
    '@wix/santa-core-utils',
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/connections/connectionsDataGetter',
    'documentServices/component/component',
    'documentServices/hooks/hooks',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    'experiment'
], function (santaCoreUtils, _, dataModel, connectionsDataGetter, component, hooks, componentDetectorAPI, tpaUtils, componentsMetaData, experiment) {
    'use strict'

    const CONTROLLER_TYPE = 'platform.components.AppController'
    const APP_WIDGET_TYPE = 'platform.components.AppWidget'

    const INVALID_ROLE = '*'

    function isControllerType(compType) {
        return isAppControllerType(compType) || isAppWidgetType(compType)
    }

    function isAppControllerType(compType) {
        return compType === CONTROLLER_TYPE
    }

    function isAppWidgetType(compType) {
        return compType === APP_WIDGET_TYPE
    }

    function isOOIController(compType) {
        return tpaUtils.isTpaByCompType(compType)
    }

    function connect(ps, compRef, controllerRef, role, connectionConfig, isPrimary, subRole) {
        const controllerCompType = component.getType(ps, controllerRef)
        if (isAppWidgetType(controllerCompType)) {
            if (!componentDetectorAPI.isDescendantOfComp(ps, compRef, controllerRef)) {
                throw new Error('cannot connect to app widget from the outside')
            }
            if (isPrimary !== false) {
                isPrimary = true
            }
        } else if (controllerCompType === CONTROLLER_TYPE || isOOIController(controllerCompType)) {
            isPrimary = isPrimary || false
        } else {
            throw new Error('controllerRef component type is invalid - should be a controller or current context')
        }
        if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(controllerRef)) {
            throw new Error('controllerRef component cannot be inflated component')
        }
        if (isPrimary && isAppControllerType(component.getType(ps, compRef))) {
            throw new Error('cannot connect to another app controller with a primary connection')
        }
        if (role === INVALID_ROLE) {
            throw new Error('invalid connection role - cannot be *')
        }
        if (!componentsMetaData.canConnectToCode(ps, compRef)) {
            throw new Error('cannot connect to this component type')
        }
        const newConnectionItem = {
            type: 'ConnectionItem',
            controllerRef,
            role,
            isPrimary
        }
        if (connectionConfig) {
            newConnectionItem.config = connectionConfig
        }
        if (subRole) {
            newConnectionItem.subRole = subRole
        }

        // The controllerRef might be null when reconnecting a connection to the page itself (rather than to another comp).
        // For example, if a page has a background connected to a dataset, then during the duplication of that page the
        // controllerRef might be null. This has to do with the order in which the components are added to the new page.
        // We reject connections with null controllerRefs to avoid issues in these scenarios
        const existingConnections = connectionsDataGetter.getConnections(ps, compRef)
        const otherConnections = _(existingConnections).reject({controllerRef: null}).reject({controllerRef, role}).value()

        if (newConnectionItem.isPrimary && _.some(otherConnections, {isPrimary: true})) {
            throw new Error('Primary connection is already connected to the component')
        }

        dataModel.updateConnectionsItem(ps, compRef, otherConnections.concat(newConnectionItem))
        hooks.executeHook(hooks.HOOKS.CONNECTION.AFTER_CONNECT, null, [ps, compRef, controllerRef])
    }

    function createConnectionItem(role, controllerId, isPrimary = true, subRole) {
        return {
            type: 'ConnectionItem',
            role,
            controllerId,
            isPrimary,
            subRole
        }
    }

    function disconnect(ps, compRef, controllerRef, role) {
        const existingConnections = connectionsDataGetter.getConnections(ps, compRef)
        const filter = {controllerRef}
        if (role) {
            filter.role = role
        }
        const newConnections = _.reject(existingConnections, filter)
        if (_.isEmpty(newConnections)) {
            dataModel.removeConnectionsItem(ps, compRef)
        } else {
            dataModel.updateConnectionsItem(ps, compRef, newConnections)
        }
        const compType = component.getType(ps, compRef)

        hooks.executeHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, compType, [ps, compRef, controllerRef, role])
    }

    function getConnectedComponentsForController(ps, controllerRef) {
        const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)
        if (!pageRef) {
            return []
        }
        const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
        //when the controller is on master pages it will search on all pages from full json

        if (experiment.isOpen('dm_disconnectMobileComponentsFromControllers')) {
            return componentDetectorAPI.getAllComponentsFromFullWithResolvers(
                ps,
                pageId,
                function (pointer) {
                    return connectionsDataGetter.isCompConnectedToController(ps, pointer, controllerRef)
                },
                controllerRef.type
            )
        }
        return componentDetectorAPI.getAllComponentsFromFullWithResolvers(ps, pageId, function (pointer) {
            return connectionsDataGetter.isCompConnectedToController(ps, pointer, controllerRef)
        })
    }

    function getConnectedComponentsRecursively(ps, controllerRef) {
        const connectedComponents = getConnectedComponentsForController(ps, controllerRef)
        const allConnectedComponents = connectedComponents.reduce((acc, comp) => {
            if (isAppWidgetType(component.getType(ps, comp))) {
                acc.push(comp, ...getConnectedComponentsRecursively(ps, comp))
            } else {
                acc.push(comp)
            }
            return acc
        }, connectedComponents)
        return _.uniq(allConnectedComponents)
    }

    function getConnectedComponents(ps, controllerRef, isRecursive) {
        if (isRecursive) {
            return getConnectedComponentsRecursively(ps, controllerRef)
        }
        return getConnectedComponentsForController(ps, controllerRef)
    }

    function getControllerConnections(ps, controllerRef) {
        const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)
        if (!pageRef) {
            return []
        }
        const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
        //when the controller is on master pages it will search on all pages from full json
        const allComponents = componentDetectorAPI.getAllComponentsFromFullWithResolvers(ps, pageId)
        return _.flatMap(allComponents, function (componentRef) {
            return _(connectionsDataGetter.getConnections(ps, componentRef))
                .reject({type: 'WixCodeConnectionItem'})
                .filter(['controllerRef.id', controllerRef.id])
                .map(function (connection) {
                    return {
                        componentRef,
                        connection: {
                            config: connection.config,
                            controllerRef: connection.controllerRef,
                            role: connection.role,
                            subRole: connection.subRole,
                            isPrimary: connection.isPrimary
                        }
                    }
                })
                .value()
        })
    }

    function getControllerConnectionsByAncestor(ps, controllerRef) {
        const pageRef = ps.pointers.full.components.getPageOfComponent(controllerRef)
        if (!pageRef) {
            return []
        }
        const pageId = ps.pointers.full.components.isMasterPage(pageRef) ? null : pageRef.id
        //when the controller is on master pages it will search on all pages from full json
        return componentDetectorAPI.getAllComponentsFromFullWithResolversByAncestor(
            ps,
            pageId,
            c => _.get(connectionsDataGetter.getPrimaryConnection(ps, c), ['controllerRef', 'id']) === controllerRef.id
        )
    }

    function getAppsConnectedToComponent(ps, componentPointer) {
        return connectionsDataGetter
            .getPlatformAppConnections(ps, componentPointer)
            .map(connection => _.get(component.data.get(ps, connection.controllerRef, 'appDefinitionId')))
    }

    return {
        connect,
        disconnect,
        createConnectionItem,
        get: connectionsDataGetter.getConnections,
        getByConnectionPointer: connectionsDataGetter.getConnectionsByPointer,
        getPlatformAppConnections: connectionsDataGetter.getPlatformAppConnections,
        getPrimaryConnection: connectionsDataGetter.getPrimaryConnection,
        getAppsConnectedToComponent,
        getConnectedComponents,
        getControllerConnections,
        getControllerConnectionsByAncestor,
        isAppControllerType,
        isAppWidgetType,
        isControllerType,
        isOOIController
    }
})
