import santaComponents from '@wix/santa-components'

const SantaTypes = santaComponents.santaTypesDefinitions

/**
 * @class componentsCore.mixins.uniquePageIdMixin
 */
export default {
    propTypes: {
        rootId: SantaTypes.Component.rootId.isRequired,
        externalBaseUrl: SantaTypes.PublicModel.externalBaseUrl.isRequired,
        isSinglePostPage: santaComponents.santaTypesDefinitions.isSinglePostPage.isRequired,
        pageUrl: SantaTypes.pageUrl.isRequired,
        getUniquePageId: SantaTypes.getUniquePageId.isRequired
    },

    /**
     * Uses site url to home page for components, displayed for all pages
     * Uses corresponding uniquePageIdProvider if one exists in registry
     * Uses full page url for all other cases
     *
     * @returns {string} SHA 256 hash or url
     */
    getUniquePageId: function getUniquePageId() {
        if (this.props.rootId === 'masterPage') {
            return this.props.externalBaseUrl
        }

        const {rootId} = this.props
        if (this.props.isSinglePostPage) {
            return this.props.getUniquePageId(rootId)
        }

        return this.props.pageUrl
    }
}
