define([
    'lodash',
    '@wix/wix-json-schema-utils',
    'documentServices/utils/utils',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/appStudio/appMetadata'
], function (_, jsonSchemaUtils, dsUtils, appStudioDataModel, appMetadata) {
    'use strict'

    const createPropertiesDescriptor = (ps, propertiesSchemas) => {
        const customDefinitions = _.assign({}, ...appStudioDataModel.getAllSerializedCustomDefinitions(ps))
        const resolver = jsonSchemaUtils.createResolver(jsonSchemaUtils.baseDefinitions, customDefinitions)
        return _(propertiesSchemas)
            .map(propSchema => resolver.resolve(propSchema.structure))
            .thru(resolvedProperty => _.assign(...resolvedProperty))
            .toPairs()
            .map(([name, {default: defaultValue}]) => ({
                name,
                defaultValue
            }))
            .value()
    }

    const createPublicDescriptor = (ps, widgetPointer) => {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)

        return {
            functions: _.map(widgetData.widgetApi.functions, ({name}) => ({name})),
            events: _.map(widgetData.widgetApi.events, ({name}) => ({name})),
            properties: createPropertiesDescriptor(ps, widgetData.widgetApi.propertiesSchemas),
            behaviors: appMetadata.getChildrenStaticEvents(ps, dsUtils.stripHashIfExists(widgetData.rootCompId))
        }
    }

    const createPublicDescriptorsMap = ps => {
        const allWidgets = appStudioDataModel.getAllWidgets(ps)
        return _.reduce(
            allWidgets,
            (accumulator, {pointer}) => {
                const widgetRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, pointer)
                accumulator[widgetRootCompId] = createPublicDescriptor(ps, pointer)
                return accumulator
            },
            {}
        )
    }

    return {
        createPublicDescriptor,
        createPublicDescriptorsMap
    }
})
