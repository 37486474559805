define([
    'lodash',
    'documentServices/wixapps/utils/pathUtils',
    'documentServices/hooks/hooks',
    'documentServices/dataModel/dataModel',
    'documentServices/wixapps/services/listTemplates',
    'documentServices/wixapps/services/lists',
    'documentServices/wixapps/services/clientSpecMap'
], function (_, pathUtils, hooks, dataModel, listTemplates, lists, clientSpecMap) {
    'use strict'

    const APP_PART_2_COMP_TYPE = 'wixapps.integration.components.AppPart2'
    const COMP_DEFINITION_CUSTOM_TEMPLATE_ATTRIBUTE = 'template'

    function afterAddAppPart2(ps, compPointer, compDefinition) {
        const listId = listTemplates.createListFromTemplate(ps, compDefinition.custom[COMP_DEFINITION_CUSTOM_TEMPLATE_ATTRIBUTE])
        const appInnerId = clientSpecMap.getApplicationId(ps)
        ps.wixappsDAL.pushByPath(pathUtils.getAppbuilderMetadataPath('requestedPartNames'), listId)
        ps.wixappsDAL.setByPath(pathUtils.getAppPart2MetadataPath(listId), {loading: false})
        dataModel.updateDataItem(ps, compPointer, {appPartName: listId, appInnerID: appInnerId})
        return {success: true, description: `New list was added with id [${listId}]`}
    }

    function afterSerializeAppPart2(ps, compPointer, customStructureData) {
        const compData = dataModel.getDataItem(ps, compPointer)
        if (!_.isEmpty(compData)) {
            customStructureData[COMP_DEFINITION_CUSTOM_TEMPLATE_ATTRIBUTE] = listTemplates.generateTemplate(ps, compData.appPartName)
            return {success: true, description: 'Added template to the custom component structure'}
        }
        return {success: true, description: 'compData is empty'}
    }

    function afterDeleteList(ps, compRef, deletingParent, removeArgs, deletedParentFromFull, dataItem) {
        if (compRef.type === 'MOBILE') {
            return {success: true, description: 'Skipped deleting part views when deleting mobile component'}
        }

        const {appPartName} = dataItem
        const listDef = lists.getListDef(ps, appPartName)
        if (!listDef) {
            return {success: true, description: 'Missing Part definition: views were not deleted'}
        }

        const views = ps.wixappsDAL.getByPath(pathUtils.getBaseViewsPath())

        _(views)
            .pickBy({name: listDef.viewName})
            .forEach(function (view, viewKey) {
                ps.wixappsDAL.removeByPath(pathUtils.getViewPath(viewKey))
            })

        return {success: true, description: 'Deleted Part views'}
    }

    function registerHooks() {
        hooks.registerHook(hooks.HOOKS.ADD.AFTER, afterAddAppPart2, APP_PART_2_COMP_TYPE)
        hooks.registerHook(hooks.HOOKS.SERIALIZE.SET_CUSTOM, afterSerializeAppPart2, APP_PART_2_COMP_TYPE)
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, afterDeleteList, APP_PART_2_COMP_TYPE)
    }

    return {
        registerHooks,
        afterDeleteList //here for testing purposes
    }
})
