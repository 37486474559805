define(['documentServices/constants/constants', 'documentServices/structure/siteGapMap', 'experiment'], function (consts, siteGapMap, experiment) {
    'use strict'

    return {
        resizableSides: [consts.RESIZE_SIDES.BOTTOM],
        moveDirections(ps) {
            if (experiment.isOpen('sv_zeroGapsThreshold')) {
                return [consts.MOVE_DIRECTIONS.VERTICAL]
            }
            const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
            return initialSiteGapMap && initialSiteGapMap.aboveHeader >= consts.SITE_SEGMENTS_GAP_THRESHOLD ? [consts.MOVE_DIRECTIONS.VERTICAL] : []
        },
        canBeFixedPosition: true,
        removable: false,
        duplicatable: false,
        containable: false,
        fullWidth: true,
        enforceContainerChildLimitsByHeight(ps, compPointer) {
            return !ps.pointers.components.isMobile(compPointer)
        },
        isVisible(ps, compPointer) {
            const allowShowingFixedComponents = ps.dal.get(ps.pointers.general.getRenderFlag('allowShowingFixedComponents'))
            const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
            return allowShowingFixedComponents || !compLayout.fixedPosition
        },
        styleCanBeApplied(ps) {
            const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
            return ps.dal.get(isStudioUserPointer)
        },
        hiddenable: false,
        collapsible: false,
        mobileConversionConfig: {
            preserveAspectRatio: false,
            minHeight: 90,
            topRightMargin: [90, 90],
            descendantImageScaleFactor: 0.7,
            siteSegmentRole: 'SITE_HEADER',
            category: 'siteSegments'
        }
    }
})
