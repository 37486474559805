define([
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/page/popupUtils',
    'documentServices/dataModel/dataModel'
], function (constants, metaDataUtils, popupUtils, dataModel) {
    'use strict'

    const COLUMN_COMPONENT_TYPE = 'wysiwyg.viewer.components.Column'
    const CLASSIC_SECTION_COMPONENT_TYPE = 'wysiwyg.viewer.components.ClassicSection'
    const ALLOWED_CONTAINER_TYPES = [COLUMN_COMPONENT_TYPE, CLASSIC_SECTION_COMPONENT_TYPE]

    function isContainable(ps, compPointer, containerPointer) {
        if (!containerPointer) {
            return false
        }

        const potentialContainerCompType = metaDataUtils.getComponentType(ps, containerPointer)
        const pagePointer = ps.siteAPI.getFocusedRootId()

        const isPopupContainer = popupUtils.isPopup(ps, pagePointer) && popupUtils.isPopupContainer(potentialContainerCompType)

        return (
            ALLOWED_CONTAINER_TYPES.includes(potentialContainerCompType) || isPopupContainer || metaDataUtils.isSiteSegmentContainer(potentialContainerCompType)
        )
    }

    return {
        minimalChildrenNumber: 1,
        canContain(ps, compPointer, potentialChildCompPtr) {
            const childType = metaDataUtils.getComponentType(ps, potentialChildCompPtr)
            return childType === COLUMN_COMPONENT_TYPE
        },
        canContainByStructure(ps, compPointer, potentialChildCompStructure) {
            return potentialChildCompStructure.componentType === COLUMN_COMPONENT_TYPE
        },
        containable: isContainable,
        containableByStructure: metaDataUtils.containableByFullWidthPopup,
        moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
        resizableSides(ps, compPtr) {
            const isSingleColumnStrip = ps.pointers.components.getChildren(compPtr).length === 1
            if (ps.pointers.components.isMobile(compPtr) && !isSingleColumnStrip) {
                return []
            }

            return [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM]
        },
        fullWidth(ps, compPointer) {
            const propertiesItem = dataModel.getPropertiesItem(ps, compPointer)
            return propertiesItem.fullWidth
        },
        fullWidthByStructure(ps, compStructure) {
            return compStructure.props.fullWidth
        },
        canBeFixedPosition: false,
        mobileConversionConfig: {
            nestOverlayingSiblings: false,
            hideWhenEmptyBackgroundContainer: true,
            filterChildrenWhenHidden: true,
            isScreenWidth: true,
            marginX: 0,
            category: 'columns'
        },
        allowedChildTypes: [COLUMN_COMPONENT_TYPE]
    }
})
