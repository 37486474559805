define([], function () {
    'use strict'

    const ANCHOR_WIDTH = 141
    const MARGINS_FROM_RIGHT = 68

    function pluginFn(siteAPI, compLayoutRelativeToStructure) {
        let {width} = compLayoutRelativeToStructure
        let left = siteAPI.getSiteMeasureMap().clientWidth - (ANCHOR_WIDTH + MARGINS_FROM_RIGHT)

        if (siteAPI.isMobileView()) {
            left = left - (siteAPI.getSiteMeasureMap().clientWidth - siteAPI.getSiteWidth()) / 2 - 13 // eslint-disable-line no-mixed-operators
            width = 140
        }

        return {
            x: left,
            y: compLayoutRelativeToStructure.y,
            height: 0,
            width
        }
    }

    return pluginFn
})
