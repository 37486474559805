define([
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsAPI',
    'documentServices/dataModel/dataModel',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsConstants',
    'documentServices/mobileConversion/modules/utils',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/siteMetadata/generalInfo'
], function (mobilePresetsAPI, dataModel, mobilePresetsConstants, mobileConversionUtils, contextAdapter, constants) {
    const {convertPagePresetToMobileHints} = mobilePresetsAPI
    const {
        MOBILE_HINTS_AUTHORS,
        FEDOPS: {MOBILE_HINTS_MIGRATION}
    } = mobilePresetsConstants

    /**
     *
     * @param {ps} ps
     * @param {*} pageId
     */
    const migrateMobileStructureToMobileHints = (ps, pageId) => {
        convertPagePresetToMobileHints(ps, pageId)
    }

    const logStart = () => contextAdapter.utils.fedopsLogger.interactionStarted(MOBILE_HINTS_MIGRATION)
    const logEnd = () => contextAdapter.utils.fedopsLogger.interactionEnded(MOBILE_HINTS_MIGRATION)

    const runMigration = (ps, pageId) => {
        logStart()
        migrateMobileStructureToMobileHints(ps, pageId)
        logEnd()
    }

    const isMigrated = mobileHintsItem => mobileHintsItem && mobileHintsItem.author === MOBILE_HINTS_AUTHORS.STUDIO

    const migratePage = (ps, pageId) => {
        runMigration(ps, pageId)
    }

    const isAbleToRunMigration = (ps, pageId) => {
        const mobileHintsItem = dataModel.getMobileHintsItem(ps, {id: pageId, type: constants.VIEW_MODES.DESKTOP})
        return mobileConversionUtils.shouldEnableNewMergeFlow(ps) && !isMigrated(mobileHintsItem)
    }

    return {
        /**
         * This method use to save existing user's mobile pages when new merge flow is enabled
         * @param {*} ps
         */
        migratePage,
        isAbleToRunMigration
    }
})
