define(['documentServices/dataModel/dataModel'], function (dataModel) {
    'use strict'

    const DIMENSIONS = {
        DEFAULT: {
            minWidth: 10,
            minHeight: 10
        },
        VIMEO: {
            minWidth: 100,
            minHeight: 100
        },
        YOUTUBE: {
            minWidth: 200,
            minHeight: 200
        },
        DAILYMOTION: {
            minWidth: 100,
            minHeight: 100
        },
        FACEBOOK: {
            minWidth: 100,
            minHeight: 100
        }
    }

    return {
        isRepeatable: false,
        styleCanBeApplied: true,
        layoutLimits(ps, compPointer) {
            const compData = dataModel.getDataItem(ps, compPointer)
            return DIMENSIONS[compData.videoType] || DIMENSIONS.DEFAULT
        },
        defaultMobileProperties: {
            autoplay: false
        }
    }
})
