define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/connections/connections',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/hooks/componentHooks/nickname'
], function (_, santaCoreUtils, connections, component, dataModel, componentDetectorAPI, nickname) {
    'use strict'

    const controllerCompType = 'platform.components.AppController'
    const appWidgetCompType = 'platform.components.AppWidget'

    function isValidConnection(ps, validConnectTargets, oldToNewIdMap, connection) {
        const controllerDataId = _.get(connection, 'controllerId')
        return _(validConnectTargets)
            .map(controllerRef => _.get(dataModel.getDataItem(ps, controllerRef), 'id'))
            .some(dataItemId => controllerDataId === dataItemId || dataItemId === oldToNewIdMap[controllerDataId])
    }

    function getValidConnectTargets(ps, isPage, compId, containerRef) {
        const pageId = isPage ? compId : ps.pointers.full.components.getPageOfComponent(containerRef).id
        const controllers = componentDetectorAPI.getAllComponents(ps, pageId, compRef => component.getType(ps, compRef) === controllerCompType)
        const widgets = _.filter(
            [containerRef, ...component.getAncestorsFromFull(ps, containerRef)],
            ancestorRef => component.getType(ps, ancestorRef) === appWidgetCompType
        )
        return [...controllers, ...widgets]
    }

    function updateControllerIds(oldToNewIdMap, compConnections) {
        return _.map(compConnections, function (connection) {
            const currentControllerId = _.get(connection, 'controllerId')
            if (oldToNewIdMap[currentControllerId]) {
                return _.assign({}, connection, {controllerId: oldToNewIdMap[currentControllerId]})
            }
            return connection
        })
    }

    function getUpdatedConnections(ps, validConnectTargets, componentConnections, oldToNewIdMap) {
        return _(componentConnections)
            .filter(connection => isValidConnection(ps, validConnectTargets, oldToNewIdMap, connection))
            .thru(_.partial(updateControllerIds, oldToNewIdMap))
            .value()
    }

    function getUpdatedSerializedConnections(ps, containerRef, compId, componentConnections, isPage, oldToNewIdMap = {}) {
        const nonWixCodeConnections = _.reject(componentConnections, {type: 'WixCodeConnectionItem'})
        if (_.isEmpty(nonWixCodeConnections)) {
            return
        }
        const validConnectTargets = getValidConnectTargets(ps, isPage, compId, containerRef)
        const newConnections = getUpdatedConnections(ps, validConnectTargets, nonWixCodeConnections, oldToNewIdMap)
        const wixCodeConnections = _.difference(componentConnections, nonWixCodeConnections)
        return wixCodeConnections.concat(newConnections)
    }

    function updateSerializedConnectionsData(ps, componentRef, containerRef, compDefinition, optionalCustomId, isPage, oldToNewIdMap = {}) {
        nickname.updateNicknameContextByNewContainer(ps, componentRef, compDefinition, containerRef)
        const componentConnections = _.get(compDefinition, 'connections.items')
        const updatedConnections = getUpdatedSerializedConnections(ps, containerRef, compDefinition.id, componentConnections, isPage, oldToNewIdMap)

        if (!_.isNil(updatedConnections)) {
            _.set(compDefinition, 'connections.items', updatedConnections)
        }
    }

    function updateSerializedConnectionsDataForComponents(ps, componentRef, containerRef, compDefinition, optionalCustomId, isPage, oldToNewIdMap) {
        if (!isPage) {
            updateSerializedConnectionsData(ps, componentRef, containerRef, compDefinition, optionalCustomId, isPage, oldToNewIdMap)
        }
    }

    function updateSerializedConnectionsDataForPage(ps, compToAddPointer, clonedSerializedComp, optionalCustomId, oldToNewIdMap) {
        const isPage = true
        const componentRef = null
        const containerRef = null
        const serializedConnections = clonedSerializedComp.connections

        updateSerializedConnectionsData(ps, componentRef, containerRef, clonedSerializedComp, optionalCustomId, isPage, oldToNewIdMap)
        if (serializedConnections) {
            dataModel.addSerializedConnectionsItemToPage(ps, compToAddPointer.id, serializedConnections, optionalCustomId)
        }
    }

    function removeInvalidConnections(ps, compRef, newParentPointer) {
        const oldPageId = component.getPage(ps, compRef).id
        const newPageId = component.getPage(ps, newParentPointer).id
        if (oldPageId === santaCoreUtils.siteConstants.MASTER_PAGE_ID || oldPageId === newPageId) {
            return
        }
        const compsAffected = ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compRef)
        const isAffected = ref => _.some(compsAffected, affectedRef => ps.pointers.isSamePointer(affectedRef, ref))
        _.forEach(compsAffected, function (comp) {
            const compConnections = connections.getPlatformAppConnections(ps, comp)
            _.forEach(compConnections, function (connection) {
                const controllerRef = _.get(connection, 'controllerRef')
                const controllerPage = component.getPage(ps, controllerRef)
                if (controllerPage.id !== newPageId && !isAffected(controllerRef)) {
                    connections.disconnect(ps, comp, controllerRef)
                }
            })
        })
    }

    return {
        getUpdatedSerializedConnections,
        //Before Add
        updateSerializedConnectionsData: updateSerializedConnectionsDataForComponents,
        updateSerializedConnectionsDataForPage,
        //Before change parent
        removeInvalidConnections
    }
})
