define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/page/pageData',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/tpa/services/provisionService',
    'documentServices/wixapps/services/blogUtils'
], function (_, santaCoreUtils, pageData, clientSpecMap, provisionService, blogUtils) {
    'use strict'

    const WIXAPPS_OLD_BLOG_APP_DEFID = '61f33d50-3002-4882-ae86-d319c1a249ab'
    const TASK_NAME = 'wixappsSavePermissionsPlugin'
    const SNAPSHOT_TAGS = ['primary']

    const updateClientSpecMapWithEntry = (privateServices, clientSpecMapAppData) => {
        clientSpecMap.registerAppData(privateServices, clientSpecMapAppData)
    }

    const updateDataFromServerOnResolve = (ps, operationDescription, resolve, reject) => data => {
        if (data && data.success) {
            updateClientSpecMapWithEntry(ps, data.payload)
            resolve()
        } else {
            santaCoreUtils.log.error(
                `wixapps blog "${operationDescription}" operation failed on MetaSite, errorCode: ${data.errorCode}, errorDecription: ${data.errorDescription}`
            )
            reject()
        }
    }

    const isWixappsBlogFoundOnSite = ps => _.some(pageData.getPagesList(ps), _.partial(blogUtils.isBlogPage, ps))
    const isWixappDeleted = clientSpecMapWixappEntry => clientSpecMapWixappEntry.state === 'Deleted'

    const handleWixappsBlogOnSave = (ps, resolve, reject) => {
        const blogClientSpecMapEntry = clientSpecMap.getAppDataByAppDefinitionId(ps, WIXAPPS_OLD_BLOG_APP_DEFID)
        const isWixappsBlogFoundOnClientSpecMap = !!blogClientSpecMapEntry
        if (isWixappsBlogFoundOnClientSpecMap) {
            const isBlogPageComponentFound = isWixappsBlogFoundOnSite(ps)
            const isWixappRevoked = isWixappDeleted(blogClientSpecMapEntry)
            if (isBlogPageComponentFound && isWixappRevoked) {
                provisionService.restoreWixappOnServer(
                    ps,
                    blogClientSpecMapEntry.appDefinitionId,
                    updateDataFromServerOnResolve(ps, 'restore', resolve, reject),
                    reject
                )
            } else if (!isBlogPageComponentFound && !isWixappRevoked) {
                provisionService.disableWixappOnServer(
                    ps,
                    blogClientSpecMapEntry.appDefinitionId,
                    updateDataFromServerOnResolve(ps, 'disable', resolve, reject),
                    reject
                )
            } else if (!isBlogPageComponentFound && isWixappRevoked) {
                resolve()
            } else if (isBlogPageComponentFound && !isWixappRevoked) {
                resolve()
            }
        } else {
            resolve()
        }
    }

    /**
     * @param {ps} privateServices
     * @returns {*}
     */
    const create = privateServices => ({
        partialSave(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject) {
            handleWixappsBlogOnSave(privateServices, resolve, reject)
        },

        fullSave(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject) {
            handleWixappsBlogOnSave(privateServices, resolve, reject)
        },

        firstSave(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
            resolve()
        },

        saveAsTemplate(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
            resolve()
        },

        autosave(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
            resolve()
        },

        publish(currentImmutableSnapshot, resolve) {
            resolve()
        },

        getTaskName() {
            return TASK_NAME
        },

        getSnapshotTags() {
            return SNAPSHOT_TAGS
        }
    })

    return {
        create
    }
})
