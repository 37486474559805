define(['lodash'], function (_) {
    'use strict'

    let pageTitlesArray = []

    const init = function (data) {
        pageTitlesArray = data
    }

    const getTitle = function (appDefinitionId, widgetId) {
        const appData = _.find(pageTitlesArray, {appDefinitionId})
        if (appData) {
            const widgetData = _.find(appData.widgets, {widgetId})
            return _.get(widgetData, 'title', '')
        }
        return ''
    }

    return {
        init,
        getTitle
    }
})
