define([], function () {
    'use strict'

    const BEHAVIOR_TYPE = 'comp'
    function executeBehavior(privateServices, compPointer, behaviorName, params, callback) {
        const behavior = {
            type: BEHAVIOR_TYPE,
            targetId: compPointer.id,
            name: behaviorName,
            params
        }

        privateServices.siteAPI.handleBehavior(behavior, {group: BEHAVIOR_TYPE, callback})
    }

    function getRuntimeState(ps, compPointer) {
        return ps.siteAPI.getRuntimeDal().getCompState(compPointer.id)
    }

    return {
        executeBehavior,
        getRuntimeState
    }
})
