import {skinsJson} from '@wix/santa-components'
import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import {skins} from './skins'

export default {
    /***
     *
     * @param {string} skinName
     * @returns {object|undefined} Skin object by name, returns undefined if skin not found
     */
    get(skinName) {
        const skin = skins[skinName]

        if (!skin) {
            if (_.isNil(skinName)) {
                return
            }

            /**
             * svgshape skins are not loaded to the skinsJson
             */
            if (!_.startsWith(skinName, 'svgshape.')) {
                coreUtilsLib.log.error("Required skin wasn't loaded yet", skinName)
            }
        }

        return skin
    },
    /***
     *
     * @param {object} skinsMap - map of skins to add to the map
     */
    addBatch(skinsMap) {
        _.assign(skinsJson, skinsMap)
    }
}
