define([
    'lodash',
    'documentServices/wixCode/services/wixCodeLifecycleService',
    'documentServices/component/component',
    'documentServices/component/componentCode'
], function (_, wixCodeLifecycleService, component, componentCode) {
    'use strict'

    function generateNicknamesForPageOfComponent(ps, compToAddPointer) {
        if (wixCodeLifecycleService.isProvisioned(ps)) {
            const pagePointer = component.isPageComponent(ps, compToAddPointer) ? compToAddPointer : component.getPage(ps, compToAddPointer)
            const viewMode = ps.pointers.components.getViewMode(pagePointer)
            componentCode.generateNicknamesForPages(ps, [pagePointer.id], viewMode)
        }
    }

    function generateNicknamesForComponents(ps, compsToGenerateNickname, pagePointer) {
        const viewMode = ps.pointers.components.getViewMode(pagePointer)
        componentCode.generateNicknamesForComponents(ps, compsToGenerateNickname, pagePointer, viewMode)
    }

    function generateNicknamesForPages(ps, pages) {
        _.forEach(pages, _.partial(generateNicknamesForPageOfComponent, ps))
    }

    function deleteNicknameFromComponentIfInvalid(ps, compPointer, containerPointer) {
        if (wixCodeLifecycleService.isProvisioned(ps) && component.getType(ps, compPointer) !== 'Page') {
            componentCode.removeNicknameFromComponentIfInvalid(ps, compPointer, containerPointer)
        }
    }

    function updateConnectionsIfNeeded(ps, compPointer, containerPointer, compDefinitionPrototype) {
        if (wixCodeLifecycleService.isProvisioned(ps) && compDefinitionPrototype.type !== 'Page' && compDefinitionPrototype.connections) {
            componentCode.updateConnectionsIfNeeded(ps, compPointer, containerPointer, compDefinitionPrototype)
        }
    }

    function updateNicknameContextByNewContainer(ps, compPointer, componentDefinition, newContainerPointer) {
        if (wixCodeLifecycleService.isProvisioned(ps) && componentDefinition.type !== 'Page') {
            componentCode.updateNicknameContextByNewContainer(ps, compPointer, componentDefinition, newContainerPointer)
        }
    }

    function setOriginalContextToSerializedComponent(ps, compPointer, customStructureData) {
        componentCode.setOriginalContextToSerializedComponent(ps, compPointer, customStructureData)
    }

    return {
        generateNicknamesForPages,
        generateNicknamesForComponents,
        generateNicknamesForPageOfComponent,
        updateConnectionsIfNeeded,
        deleteNicknameFromComponentIfInvalid,
        updateNicknameContextByNewContainer,
        setOriginalContextToSerializedComponent
    }
})
