// @ts-ignore
import {createLoggerApi} from '@wix/thunderbolt-logger'
import {create as createFedopsLogger} from '@wix/fedops-logger'
import type {WixBiSession} from '@wix/document-services-types'
import {presetsTypes} from '@wix/fedops-presets'
import _ from 'lodash'

const extractVersion = (base: string) => base.split('/').pop()

const getEnvironment = (isRollout: number | boolean | null) => {
    if (_.isNull(isRollout)) {
        return 'Canary'
    }
    return isRollout ? 'Rollout' : 'Production'
}

export const createThunderboltLogger = (dmBase: string, biContext: WixBiSession, biLoggerFactory: any) => {
    const fedopsLogger = createFedopsLogger('tb-ds', {
        presetType: presetsTypes.DS,
        isServerSide: false,
        reportBlackbox: true,
        biLoggerFactory,
        phasesConfig: 'SEND_ON_FINISH',
        // @ts-ignore
        paramsOverrides: {is_rollout: biContext.is_rollout}
    })
    const sentryStore = {
        release: extractVersion(dmBase),
        environment: getEnvironment(biContext.is_rollout),
        user: biContext.viewerSessionId
    }

    const logger = createLoggerApi({
        fedopsLogger,
        sentry: _.isUndefined(window.Sentry) ? {} : window.Sentry,
        sentryStore,
        errorLimit: 10000
    })

    logger.setGlobalsForErrors({
        tags: {}, //todo: add relevant tags to DS
        extra: {} //todo: add relevant extra data to DS
    })

    return logger
}
