import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import slashUrlParser from './parsers/slashUrlParser'
import hashBangUrlParser from './parsers/hashBangUrlParser'
import urlParserUtils from './utils/urlParserUtils'

const FORMAT_PARSERS = {
    slash: slashUrlParser,
    hashBang: hashBangUrlParser
}

function useSlashFormat(siteData, url) {
    const urlObj: any = _.isObject(url) ? url : warmupUtilsLib.urlUtils.parseUrl(url)
    //var path = urlObj.path || urlObj.pathname || '';

    if (warmupUtilsLib.stringUtils.startsWith(urlObj.hash, '#!')) {
        return false
    }

    //return path.split('/').length > 2;
    return siteData.isUsingSlashUrlFormat
}

function getFormatParser(siteData, requestedFormat) {
    return FORMAT_PARSERS[requestedFormat || siteData.urlFormat] || FORMAT_PARSERS[siteData.urlFormat]
}

function isWixMobileApp(externalBaseUrl, url) {
    return url === `${externalBaseUrl.replace(/\/$/, '')}/app`
}

/**
 *
 * @param {core.SiteData} siteData
 * @param {string|object} url
 * @returns {{
 *      pageTitle: string,
 *      pageId: string,
 *      pageItemId: ?string,
 *      pageAdditionalData: ?string
 * }}
 */
function parseUrl(siteData, url) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    if (!url || isWixMobileApp(resolvedSiteData.externalBaseUrl, url)) {
        return null
    }

    if (url === '#') {
        return parseUrl(resolvedSiteData, resolvedSiteData.currentUrl.full)
    }

    if (useSlashFormat(resolvedSiteData, url)) {
        return slashUrlParser.parseUrl(resolvedSiteData, url)
    }

    return hashBangUrlParser.parseUrl(resolvedSiteData, url)
}

function getUrl(siteData, pageInfo, forceAddPageInfo?, cleanQuery?, baseUrl?, urlMapping?, dismissQueryParamList?, ignorePageUriSeo?) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    const parser = getFormatParser(resolvedSiteData, pageInfo.format)

    return parser.getUrl(resolvedSiteData, pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping, siteData, dismissQueryParamList, ignorePageUriSeo)
}

function isUrlToCurrentSite(siteData, url) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    if (useSlashFormat(resolvedSiteData, url)) {
        return slashUrlParser.isUrlToCurrentSite(resolvedSiteData, url)
    }
    return hashBangUrlParser.isUrlToCurrentSite(resolvedSiteData, url)
}

function normalizeProtocolForSameSite(siteData, url) {
    if (isUrlToCurrentSite(siteData, url)) {
        const currentProtocol = siteData.currentUrl.protocol
        return url.replace(/^https?:/, currentProtocol)
    }
    return url
}

/** @class core.wixUrlParser */
export default {
    getUrl,
    parseUrl,
    utils: urlParserUtils,
    normalizeProtocolForSameSite
}
