define([
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/utils/errors'
], function (extensionsAPI, clientSpecMapUtils, errors) {
    'use strict'

    function _validateArgument(argName, methodName, argValue, expected) {
        if (!argValue) {
            throw new errors.ArgumentError(argName, methodName, argValue, expected)
        }
    }

    /**
     * @description Creates a codeAppInfo object from the given parameters
     * param {string} baseUrl The base URL for the app, usually taken from service topology
     * param {string} appId The app ID
     * param {string} signedInstance The signed instance, usually taken from the client spec entry
     * param {string} instanceId The instance ID, usually taken from the client spec entry
     * @param {{baseUrl:string, appId:string, signedInstance:string, instanceId:string}} op
     */
    function createCodeAppInfo({baseUrl, appId, signedInstance, instanceId}) {
        _validateArgument('baseUrl', 'createCodeAppInfo', baseUrl, 'string')
        _validateArgument('appId', 'createCodeAppInfo', appId, 'string')
        _validateArgument('signedInstance', 'createCodeAppInfo', signedInstance, 'string')
        _validateArgument('instanceId', 'createCodeAppInfo', instanceId, 'string')

        return {
            baseUrl,
            signedInstance,
            instanceId,
            appId
        }
    }

    function getCodeAppInfoFromPS(ps) {
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        const gridAppId = extensionsAPI.wixCode.getEditedGridAppId(ps)
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())

        const baseUrl = serviceTopology.wixCodeIdeServerUrl

        return createCodeAppInfo({
            baseUrl,
            appId: gridAppId,
            signedInstance: wixCodeApp.instance,
            instanceId: wixCodeApp.instanceId
        })
    }

    return {
        createCodeAppInfo,
        getCodeAppInfoFromPS
    }
})
