import _ from 'lodash'
import layout from '../util/layout'
import balataLayout from './balataLayout'

//To Use mediaContainer with background register you component here

const CONTAINER_POSTFIX = 'container'

function measureMediaContainer(compType, id, measureMap, nodesMap, structureInfo?, layoutAPI?, prevMeasuredValues?) {
    if (!_.get(structureInfo, 'designDataItem.background')) {
        return
    }

    balataLayout.measure(id, measureMap, nodesMap, structureInfo)

    measureMap.width[id] = structureInfo.layout.width || nodesMap[id].clientWidth || measureMap.width[id]

    const isMesh = nodesMap[id].getAttribute('data-is-mesh') === 'true'
    const repeaterId = nodesMap[id].parentElement && nodesMap[id].parentElement.getAttribute('data-repeater-id')
    const shouldFixMediaContainerLeft = !isMesh && repeaterId && compType === 'wysiwyg.viewer.components.MediaContainer'

    if (shouldFixMediaContainerLeft) {
        if (layoutAPI.isDuringInteractionMode && !_.isUndefined(prevMeasuredValues.left)) {
            // keep existing measured values in interactions mode
            measureMap.left[id] = prevMeasuredValues.left
        } else {
            measureMap.left[id] = nodesMap[id].getBoundingClientRect().left - nodesMap[repeaterId].getBoundingClientRect().left
        }
    }
}

function patchMediaContainer(id, patchers, measureMap, structureInfo, siteData) {
    if (!_.get(structureInfo, 'designDataItem.background')) {
        return
    }

    balataLayout.patch(id, patchers, measureMap, structureInfo, siteData)
}

const registeredComponentTypes = [
    'wysiwyg.viewer.components.MediaBox',
    'wysiwyg.viewer.components.HoverBox',
    'wysiwyg.viewer.components.MediaPlayer',
    'wysiwyg.viewer.components.MediaContainer',
    'wysiwyg.viewer.components.RichContainer'
]

_.forEach(registeredComponentTypes, function (compType) {
    // @ts-ignore
    layout.registerCustomMeasure(compType, (...args) => measureMediaContainer(compType, ...args))
    layout.registerPureDomHeightMeasure(compType)
    layout.registerRequestToMeasureChildren(compType, balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE.concat([[CONTAINER_POSTFIX]]))
    layout.registerPatcher(compType, patchMediaContainer)
})

export default {
    measure: measureMediaContainer,
    patch: patchMediaContainer
}
