define(['documentServices/wixCode/utils/errorObject'], function (errorObject) {
    'use strict'

    const errorTypes = {
        ABORT: 'abort',
        TIMEOUT: 'timeout',
        PARSE: 'parseerror'
    }

    function _buildTimeoutError() {
        const error = new Error('Request timed out')
        error.name = 'RequestTimeoutError'
        return error
    }

    const isFailedFetch = (errorType, xhr) => errorType === errorTypes.ABORT || (!errorType && xhr && (xhr.name === 'TypeError' || xhr.name === 'AbortError'))

    const createError = ({errorType, xhr}) => {
        if (isFailedFetch(errorType, xhr)) {
            return errorObject.buildAbortedError()
        } else if (errorType === errorTypes.TIMEOUT) {
            return _buildTimeoutError()
        }

        return errorObject.buildServerError(xhr.status, xhr.response)
    }

    function build({errorType, xhr}) {
        // At this point "xhr" can unfortunately be either one of:
        // - zepto XHR object (maybe not anymore since Santa was killed?)
        // - fetch "Response" object (for any server errors in Bolt)
        // - Error object, if "fetch" call in Bolt threw an error (e.g. "TypeError: Failed to fetch")
        //
        // "errorType" is "undefined" in Bolt, and zepto-style string in Santa

        const error = createError({errorType, xhr})
        error.xhr = xhr
        error.errorCode = safeGetErrorCode(error)

        return error
    }

    function safeGetErrorCode(error) {
        try {
            const errorObj = JSON.parse(error.xhr.response)
            return errorObj.errorCode
        } catch (e) {
            return null
        }
    }

    function safeGetStatusCode(error) {
        try {
            return error.xhr.status
        } catch (e) {
            return null
        }
    }

    return {
        build,
        errorTypes,
        safeGetErrorCode,
        safeGetStatusCode
    }
})
