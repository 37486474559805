import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

/**
 * @class proxies.Icon
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],
    propTypes: {
        viewProps: PropTypes.shape({
            serviceTopology: PropTypes.object.isRequired,
            resolveImageData: PropTypes.func.isRequired,
            packageName: PropTypes.string.isRequired
        })
    },
    renderProxy() {
        const data = this.proxyData
        const componentType = 'wixapps.integration.components.Icon'
        const props = this.getChildCompProps(componentType)
        props.compData = typesConverter.icon(
            data,
            this.props.viewProps.resolveImageData,
            this.props.viewProps.serviceTopology,
            this.props.viewProps.packageName
        )

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
