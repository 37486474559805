define([
    'lodash',
    '@wix/mobile-conversion',
    '@wix/santa-core-utils',
    'documentServices/mobileConversion/modules/mobileConversion',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsHandler',
    'documentServices/mobileConversion/modules/utils',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/mobileConversion/mobileFacadeValidationWrapper',
    'documentServices/constants/constants',
    'documentServices/extensionsAPI/extensionsAPI'
], function (
    _,
    mobileCore,
    santaCoreUtils,
    mobileConversion,
    mobilePresetsHandler,
    mobileConversionUtils,
    mobileUtils,
    mobileFacadeValidationWrapper,
    constants,
    extensionsAPI
) {
    'use strict'

    const {isMobileSupported} = mobileUtils

    const mobileConversionFacade = {
        runMobileConversionOnPage(ps, page) {
            if (!isMobileSupported(ps)) {
                return
            }

            mobileConversion.mobileConversion({ps}).runMobileConversionOnPage(page)
        },
        runMobileConversionOnAllPages(ps, options) {
            if (!isMobileSupported(ps)) {
                return
            }
            const heuristicStrategy = _.get(options, 'heuristicStrategy')
            return heuristicStrategy === 'minimal'
                ? mobileConversion.mobileConversion({ps}).syncMobileSite()
                : mobileConversion
                      .mobileConversion({
                          ps,
                          heuristicStrategy
                      })
                      .runMobileConversionOnAllPages()
        },
        prepareForConversion(ps, pageIds) {
            if (!isMobileSupported(ps)) {
                return
            }
            const allPageIds = extensionsAPI.pages.getAllPagesIds(ps)
            let pagesData = allPageIds.reduce((acc, pageId) => {
                acc[pageId] = ps.dal.get(ps.pointers.page.getPagePointer(pageId))
                return acc
            }, {})
            const api = mobileConversion.mobileConversion({ps})
            if (pageIds) {
                pagesData = _.pick(pagesData, pageIds)
            }
            return _.mapValues(pagesData, (pageData, pageId) => {
                const compsMap = pageData.structure.DESKTOP
                const page = santaCoreUtils.mobileUtils.buildDeepStructure(compsMap[pageId], compsMap)
                return api.prepareConversion(page)
            })
        },
        applyConversion(ps, convertedPages) {
            if (!isMobileSupported(ps)) {
                return
            }
            const mobileComponentsMap = _.mapValues(convertedPages, mobileCore.conversionUtils.getChildren)
            mobileConversion.mobileConversion({ps}).applyConvertedComponentsMap(mobileComponentsMap)
        },
        execConversion(ps, desktopSite, heuristicStrategy) {
            if (!isMobileSupported(ps)) {
                return
            }
            const options = {ps, heuristicStrategy}
            const api = mobileConversion.mobileConversion(options)
            _.forEach(desktopSite, page => api.executeConversion(page))
        },
        runMobileMergeOnPage(ps, desktopPage, mobilePage, settings) {
            if (!isMobileSupported(ps)) {
                return
            }
            mobileConversion.mobileConversion({ps}).runMobileMergeOnPage(desktopPage, mobilePage, settings)
        },
        addMobileOnlyComponent(ps, compId, postConversionParameters) {
            if (!isMobileSupported(ps)) {
                return
            }
            const mobileComponentsPointer = ps.pointers.page.getComponentsMapPointer('masterPage', constants.VIEW_MODES.MOBILE)
            const compsMap = ps.dal.get(mobileComponentsPointer, true)
            const masterPage = santaCoreUtils.mobileUtils.buildDeepStructure(compsMap.masterPage, compsMap)
            masterPage.mobileComponents = masterPage.children

            const api = mobileConversion.mobileConversion({ps})
            api.prepareConversion(masterPage)
            mobileCore.mobileOnlyComponents.addComponent(masterPage, compId)
            api.applyConvertedComponentsToPage(mobileCore.conversionUtils.getChildren(masterPage), masterPage.id, postConversionParameters)
        },
        syncMobileSite(ps, options) {
            if (!isMobileSupported(ps)) {
                return
            }
            mobileConversion.mobileConversion({ps}).syncMobileSite(options)
        },
        runPartialConversionAllPages(ps, commitConversionResults = true) {
            if (!isMobileSupported(ps)) {
                return
            }
            if (ps.config.disableMobileConversion) {
                mobileConversion.mobileConversion({ps}).syncMobileSite()
                return
            }
            mobileConversion.mobileConversion({ps}).runMobileMergeOnAllPages(commitConversionResults)
        },
        /**
         * Method to run mobile merge algorithm only for the current page
         * should be used for getting the result for mobile page programmatically
         * without saving it, only with committing merge result to dal
         * @param {ps} ps
         * @param {string} pageId
         * @param {object|undefined} settings
         * @returns
         */
        runMobileMergeOnPageById(ps, pageId, settings) {
            if (!mobileConversionUtils.shouldRunMerge(ps)) {
                return
            }
            mobileConversion.mobileConversion({ps}).runMobileMergeOnPageById(pageId, settings)
        },
        inspect(ps, settings) {
            if (!isMobileSupported(ps)) {
                return
            }

            return mobileConversion.mobileConversion({ps}).inspect(settings)
        },
        getComponentsOrder(ps, pageId) {
            if (!isMobileSupported(ps)) {
                return
            }

            return mobileConversion.mobileConversion({ps}).getComponentsOrder(pageId)
        },
        runMobileMergeOnAllPagesWithoutLayoutOverwrite(ps) {
            if (!mobileConversionUtils.shouldRunMerge(ps)) {
                return
            }
            mobileConversion.mobileConversion({ps}).runMobileMergeOnAllPagesWithoutLayoutOverwrite()
        },
        shouldCreateMobilePreset: mobilePresetsHandler.shouldCreateMobilePreset,
        createMobilePreset: mobilePresetsHandler.createMobilePreset
    }

    return mobileFacadeValidationWrapper.wrap(mobileConversionFacade, ['shouldCreateMobilePreset', 'createMobilePreset', 'getComponentsOrder', 'inspect'])
})
