import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

const {localizer} = wixapps
const {wixappsLogger} = wixapps

/**
 * @class wixAppsBuilder.AppPart2Presenter
 * @param partApi
 * @constructor
 */
function AppPart2Presenter(partApi) {
    this.partApi = partApi
}

AppPart2Presenter.prototype = {
    getViewVars() {
        if (!this.userTags) {
            this.userTags = {
                enabled: false,
                items: [{value: '', text: ''}],
                selectedValue: ''
            }

            const appPartSelectorDefinition = this.partApi.getAppPartDefinition().dataSelectorDef

            if (appPartSelectorDefinition) {
                /** @type AppRepoDefinition */
                const appRepo = this.partApi.getRepo()
                const dataSelectorDefinition = appRepo.dataSelectors[appPartSelectorDefinition.id]

                if (dataSelectorDefinition.logicalTypeName === 'IB.TagsFilteredList' && appPartSelectorDefinition.predefinedSettings) {
                    this.userTags.items = _.map(appPartSelectorDefinition.predefinedSettings.tags, function (value) {
                        return {
                            value,
                            text: appRepo.tags[value]
                        }
                    })

                    // Add tag 'all' at first option.
                    this.userTags.items.unshift({value: 'all', text: localizer.localize('@FILTER_DIALOG_All_Tags@', this.partApi.getLocalizationBundle())})

                    this.userTags.selectedValue = appPartSelectorDefinition.predefinedSettings.selectedTag
                    this.userTags.enabled = true
                }
            }
        }

        return {
            userTags: this.userTags
        }
    },

    onTagChanged(event) {
        const dataSelector = this.partApi.getDataSelector()
        const appPartSelectorDefinition = this.partApi.getAppPartDefinition().dataSelectorDef
        const tagIds = event.payload.value === 'all' ? appPartSelectorDefinition.predefinedSettings.tags : [event.payload.value]

        // Update the tags in the dataSelector instance.
        this.partApi.setCssState('loading')
        this.userTags.selectedValue = event.payload.value
        dataSelector.setTags(
            tagIds,
            function () {
                this.partApi.setCssState('content')

                wixappsLogger.reportEvent(this.partApi.siteData, wixappsLogger.events.TAG_SELECTED_IN_VIEWER)
            }.bind(this)
        )
    }
}

export default AppPart2Presenter
