define(['lodash', 'documentServices/dataModel/dataModel'], function (_, dataModel) {
    'use strict'

    function getDevicePropName(ps, pointer) {
        return ps.pointers.components.getViewMode(pointer).toLowerCase()
    }

    function getPageProperties(ps, pagePointer) {
        return _.get(dataModel.getPropertiesItem(ps, pagePointer), getDevicePropName(ps, pagePointer))
    }

    function mergePageProps(ps, pagePointer, chunkToMerge) {
        const allPageProps = dataModel.getPropertiesItem(ps, pagePointer) || dataModel.createPropertiesItemByType(ps, 'PageProperties')
        const path = getDevicePropName(ps, pagePointer)
        let mergedChunk

        if (_.isPlainObject(chunkToMerge)) {
            const chunkFromDAL = _.get(allPageProps, path, {})
            mergedChunk = _.assign(chunkFromDAL, chunkToMerge)
        } else {
            mergedChunk = chunkToMerge
        }
        _.set(allPageProps, path, mergedChunk)

        return allPageProps
    }

    function updatePageProperties(ps, pagePointer, props) {
        const mergedProps = mergePageProps(ps, pagePointer, props)
        dataModel.updatePropertiesItem(ps, pagePointer, mergedProps)
    }

    return {
        getPageProperties,
        updatePageProperties
    }
})
