import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const partners = viewerLibrary.get('documentServices/partners/partners')
    return {
        methods: {
            partners: {
                isPotentialPartnerAsync: publicMethodUtils.defineGetter(partners.isPotentialPartnerAsync)
            }
        },
        initMethod: partners.initState
    }
}
