define(['lodash', 'documentServices/constants/constants', 'documentServices/variants/variantsUtils'], function (_, constants, variantsUtils) {
    'use strict'

    const {DATA_TYPES} = constants

    const updateTransitionsData = (ps, componentVariantsPointer, transitions) => {
        if (_.isEmpty(transitions)) {
            console.warn('please pass non empty object, for full delete use transitions.remove api')
            return
        }

        const transitionsWithType = _.defaults({}, {type: 'TransitionData'}, transitions)
        return variantsUtils.updateComponentDataConsideringVariants(ps, componentVariantsPointer, transitionsWithType, DATA_TYPES.transitions)
    }

    const getTransitionsData = (ps, componentVariantsPointer) =>
        variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

    const removeTransitionsData = (ps, componentVariantsPointer) =>
        variantsUtils.removeComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

    const hasTransitionsData = (ps, componentVariantsPointer) =>
        !!variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transitions)

    const setEnableVariantsTransitionsInEditor = (ps, shouldEnableTransitions) => ps.siteAPI.setEnableVariantsTransitionsInEditor(shouldEnableTransitions)

    return {
        updateTransitionsData,
        getTransitionsData,
        setEnableVariantsTransitionsInEditor,
        removeTransitionsData,
        hasTransitionsData
    }
})
