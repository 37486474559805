define(['documentServices/constants/constants'], function (constants) {
    'use strict'

    const isSanta = ps => ps.runtimeConfig.viewerName === constants.VIEWER_NAMES.SANTA
    const isBolt = ps => ps.runtimeConfig.viewerName === constants.VIEWER_NAMES.BOLT
    const isThunderbolt = ps => ps.runtimeConfig.viewerName === constants.VIEWER_NAMES.THUNDERBOLT

    return {
        isSanta,
        isBolt,
        isThunderbolt
    }
})
