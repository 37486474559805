import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

function doesAllowHeightResize(partDefinition, viewName, format) {
    const path = 'allowHeightResize'

    const configByFormat = coreUtils.objectUtils.resolvePath(partDefinition, ['configByFormat', format, path])
    if (configByFormat !== null) {
        return configByFormat
    }

    const configByView = coreUtils.objectUtils.resolvePath(partDefinition, ['configByView', viewName, path])
    if (configByView !== null) {
        return configByView
    }

    if (_.has(partDefinition, path)) {
        return partDefinition[path]
    }

    return false
}

function getDescriptorUrl(siteData, packageName, isFallback) {
    let host = !isFallback ? siteData.santaBase : siteData.santaBaseFallbackUrl
    if (_.last(host) !== '/') {
        host += '/'
    }

    return `${host}static/wixapps/apps/${packageName}/descriptor.json`
}

export default {
    getDescriptorUrl,
    doesAllowHeightResize
}
