const WORKER_PUBLISH_PREFIX = 'WORKER_'

function publish(siteAPI, msg) {
    const pubSub = siteAPI.getSiteAspect('tpaPubSubAspect')
    pubSub.publish(msg.appDefId, WORKER_PUBLISH_PREFIX + msg.workerId, msg.msgData)
}

function subscribe(siteAPI, msg) {
    if (!msg.msgData) {
        return
    }
    const pubSub = siteAPI.getSiteAspect('tpaPubSubAspect')
    pubSub.subscribeAsPlatformWorker(msg.appDefId, msg.workerId, msg.msgData.eventKey, msg.msgData.receivePastEvents, msg.msgData.callbackId)
}

function unsubscribe(siteAPI, msg) {
    const pubSub = siteAPI.getSiteAspect('tpaPubSubAspect')
    const {appDefId} = msg
    const {eventKey} = msg.msgData
    pubSub.unsubscribeAsPlatformWorker(appDefId, msg.workerId, eventKey)
}

export default {
    publish,
    subscribe,
    unsubscribe
}
