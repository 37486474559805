define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/hooks/hooks',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/dataSerialization',
    'documentServices/theme/isSystemStyle',
    '@wix/document-manager-utils',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, santaCoreUtils, hooks, constants, dataModel, dataSerialization, isSystemStyle, dmUtils, extensionsAPI) {
    'use strict'

    const {
        DATA_TYPES,
        DATA_TYPES_VALUES_MAP,
        RELATION_DATA_TYPES,
        VARIANTS: {VALID_VARIANTS_DATA_TYPES},
        DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT
    } = constants

    const {ReportableError} = dmUtils

    /**
     * @typedef {object} VariantRelation
     * @property {string} type
     * @property {string[]} variants
     * @property {string} from
     * @property {string} to
     */

    /**
     * Returns true if the given VariantRelation variants matches ALL of the provided variant IDs, false otherwise.
     * @param {VariantRelation} relation
     * @param {string[]} variants
     * @returns {boolean}
     */
    const exactMatchVariantRelationPredicateitem = (ps, relation, variants) =>
        _.get(relation, 'type') === RELATION_DATA_TYPES.VARIANTS &&
        _.isEqual(_.sortBy(dataModel.variantRelation.extractVariants(ps, relation)), _.sortBy(variants))

    /**
     * connect the scopedValue id to relation, and will add relation and refArray if not exiting already
     *
     * @param {ps} ps
     * @param compRef
     * @param {string} scopedValueId
     * @param {string} itemType
     * @param {Object} refArrayOrDataItem
     * @param {string []} variants
     * @param {string} pageId
     * @returns {string} returns refArray Id
     */
    const addScopedValueToRelation = (ps, compRef, scopedValueId, itemType, refArrayOrDataItem, variants, pageId) => {
        const compIdToAdd = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compRef.id)
        const variantRelation = dataModel.variantRelation.create(ps, variants, compIdToAdd, scopedValueId)
        const relId = dataSerialization.addDeserializedItemToPage(ps, pageId, itemType, variantRelation)

        if (dataModel.refArray.isRefArray(ps, refArrayOrDataItem)) {
            const newValues = [...dataModel.refArray.extractValues(ps, refArrayOrDataItem), `#${relId}`]

            const newValuesAfterHook = hooks.executeHookAndUpdateValue(
                ps,
                hooks.HOOKS.SET_SCOPED_VALUE.BEFORE,
                undefined,
                [compRef, itemType, pageId, relId, refArrayOrDataItem.id],
                newValues
            )

            const refArrayPointer = ps.pointers.data.getItem(itemType, refArrayOrDataItem.id, pageId)
            ps.dal.set(ps.pointers.getInnerPointer(refArrayPointer, 'values'), newValuesAfterHook)
            return refArrayOrDataItem.id
        }

        const refValues = _.get(refArrayOrDataItem, 'id') ? [refArrayOrDataItem.id, relId] : [relId]
        const refArr = dataModel.refArray.create(ps, refValues)
        return dataSerialization.addDeserializedItemToPage(ps, pageId, itemType, refArr)
    }

    /**
     * finds the scoped value that been referenced by _relationPointer
     * in case of not passing _relationPointer the func search for relation that holds the same variants that is passed to func (full comparison)
     *
     *
     * @param {ps} ps
     * @param {string} namespace
     * @param {RefArray} refArray
     * @param {string []} variants
     * @param pageId
     * @param {Pointer} _relationPointer - this is optional when passing its skips the part of finding the variant relation
     * @returns {Pointer | undefined} returns scoped value pointer
     */
    const getScopedValuePointerByVariants = (ps, namespace, refArray, variants, pageId, _relationPointer) => {
        if (!DATA_TYPES_VALUES_MAP[namespace]) {
            throw new Error(`data type ${namespace}, is not valid`)
        }

        const relationPointer = _relationPointer || getRelationPointerFromRefArrayByVariants(ps, namespace, refArray, variants, pageId)
        if (!relationPointer) {
            return
        }

        return getScopedValueByRelationPtr(ps, namespace, _relationPointer)
    }

    /**
     * get scoped data pointer from a variant relation pointer
     * @param {ps} ps
     * @param {string} namespace
     * @param {Pointer} relationPointer
     * @returns {Pointer | undefined} returns scoped value pointer
     */
    const getScopedValueByRelationPtr = (ps, namespace, relationPointer) => {
        const pageId = ps.pointers.data.getPageIdOfData(relationPointer)
        const relation = ps.dal.get(relationPointer)
        const scopedValueId = dataModel.variantRelation.extractTo(ps, relation)
        return ps.pointers.data.getItem(namespace, scopedValueId, pageId)
    }

    /**
     * search in the schema that corresponds to itemType for relation that holds the same variants that is passed to func (full comparison)
     *
     * @param {ps} ps
     * @param itemType
     * @param refArray
     * @param variants
     * @param pageId
     * @returns {DataItem | null} returns relation
     */
    const getRelationPointerFromRefArrayByVariants = (ps, itemType, refArray, variants, pageId) => {
        if (!dataModel.refArray.isRefArray(ps, refArray) || !variants) {
            return null
        }

        const relationId = _.find(dataModel.refArray.extractValuesWithoutHash(ps, refArray), refValueId => {
            const refValuePointer = ps.pointers.data.getItem(itemType, refValueId, pageId)
            const refValue = ps.dal.get(refValuePointer)
            return exactMatchVariantRelationPredicateitem(ps, refValue, variants)
        })

        return relationId ? ps.pointers.data.getItem(itemType, relationId, pageId) : null
    }

    /**
     * search in the schema that corresponds to itemType for non scooped value item in the refArray
     *
     * @param {ps} ps
     * @param itemType
     * @param refArray
     * @param pageId
     * @returns {DataItem | null} returns relation
     */
    const nonScopedValuePointer = (ps, itemType, refArray, pageId) => {
        if (!dataModel.refArray.isRefArray(ps, refArray)) {
            return null
        }

        const nonRelationId = _.find(dataModel.refArray.extractValuesWithoutHash(ps, refArray), refValueId => {
            const refValuePointer = ps.pointers.data.getItem(itemType, refValueId, pageId)
            const refValue = ps.dal.get(refValuePointer)
            return _.get(refValue, 'type') !== RELATION_DATA_TYPES.VARIANTS
        })

        return nonRelationId ? ps.pointers.data.getItem(itemType, nonRelationId, pageId) : null
    }

    const removeScopedValue = (ps, scopedValueId, itemType, pageId) => {
        const scopedValuePointer = ps.pointers.data.getItem(itemType, scopedValueId, pageId)
        dataModel.removeItemRecursivelyByType(ps, scopedValuePointer)
    }

    const getComponentFromRelation = (ps, relation, pageId) => {
        const compId = dataModel.variantRelation.extractFrom(ps, relation)
        const pagePointer = ps.pointers.components.getPage(pageId, ps.siteAPI.getViewMode())
        return ps.pointers.full.components.getComponent(compId, pagePointer)
    }

    /**
     * getting the default handlers for removing relation from RefArray
     * @param {ps} ps
     * @param {Object} relation
     * @param {string} itemType
     * @param {string} pageId
     * @returns {RemoveScopedAndNonScopedDataHandlers} handlers
     */
    const getDefaultRemoveRelationFromRefArrayHandlers = (ps, relation, itemType, pageId) => {
        const pointer = getComponentFromRelation(ps, relation, pageId)
        return {
            refArrayOrValuePointerResolver: () => dataModel.getComponentDataPointerByType(ps, pointer, itemType),
            removeRefArrayFromReferenceResolver: () => dataModel.removeComponentDataByType(ps, pointer, itemType, true)
        }
    }

    /**
     * remove relation from the ref array
     * @param {ps} ps
     * @param {Object} relation
     * @param {string} itemType
     * @param {string} pageId
     * @param {RemoveScopedAndNonScopedDataHandlers | undefined} handlers
     */
    const removeRelationFromRefArray = (ps, relation, itemType, pageId, handlers) => {
        const {refArrayOrValuePointerResolver, removeRefArrayFromReferenceResolver} =
            handlers || getDefaultRemoveRelationFromRefArrayHandlers(ps, relation, itemType, pageId)
        const refArrayPointer = refArrayOrValuePointerResolver()
        if (!ps.dal.isExist(refArrayPointer)) {
            return
        }
        const refArrayData = ps.dal.get(refArrayPointer)
        const newValues = _.without(dataModel.refArray.extractValues(ps, refArrayData), `#${_.get(relation, 'id')}`)
        if (!_.isEmpty(newValues)) {
            ps.dal.set(ps.pointers.getInnerPointer(refArrayPointer, 'values'), newValues)
        } else {
            removeRefArrayFromReferenceResolver()
        }
    }

    /**
     * remove relation
     * @param {ps} ps
     * @param {Pointer} relationPointer
     * @param {string} itemType
     * @param {string} pageId
     * @param {RemoveScopedAndNonScopedDataHandlers | undefined} handlers
     */
    const removeRelation = (ps, relationPointer, itemType, pageId, handlers = undefined) => {
        const relation = ps.dal.get(relationPointer)
        const scopedValueId = dataModel.variantRelation.extractTo(ps, relation)
        const shouldRemoveScopedDataItem = itemType !== DATA_TYPES.theme || !isSystemStyle(scopedValueId)
        if (shouldRemoveScopedDataItem) {
            removeScopedValue(ps, scopedValueId, itemType, pageId)
        }

        removeRelationFromRefArray(ps, relation, itemType, pageId, handlers)
        ps.dal.remove(relationPointer)
    }

    /**
     * filter given relations that contains exactly the given variants
     * @param {ps} ps
     * @param {Pointer []} variantRelationsPointers
     * @param {string []} variants
     * @returns {Pointer []} filteredVariantsPointers
     */
    const filterRelationsWithExactVariants = (ps, variantRelationsPointers, variants) =>
        _.filter(variantRelationsPointers, relationPointer => {
            const relation = ps.dal.get(relationPointer)
            return exactMatchVariantRelationPredicateitem(ps, relation, variants)
        })

    /**
     * get the relations that will be deleted according to the following cases:
     * 1. single variant - we will delete all relations that contains the variant (may contain more)
     * 2. multiple variants - we will delete only the relations that contains exactly the variants.
     * @param {ps} ps
     * @param {Pointer []} variantsPointers
     * @param {string} itemDataType
     * @returns {Pointer []} relationsPointers
     */
    const getRelationsToDelete = (ps, variantsPointers, itemDataType) => {
        const variantsIds = _.map(variantsPointers, 'id')
        const shouldDeleteAllRelationsWithVariant = variantsPointers.length === 1

        const relationsToDeleteByVariantId = variantId => {
            const variantRelationsPointers = ps.pointers.data.getVariantRelations(itemDataType, variantId)
            return shouldDeleteAllRelationsWithVariant ? variantRelationsPointers : filterRelationsWithExactVariants(ps, variantRelationsPointers, variantsIds)
        }

        return _(variantsIds).map(relationsToDeleteByVariantId).flatMap().value()
    }

    /**
     * Validates the variants array if more than one variant was provided. Throws error if there was a validation issue
     *
     * @param {ps} ps
     * @param {Pointer []} variantPointers
     */
    const validateVariants = (ps, variantPointers) => {
        const variantsData = variantPointers.map(ps.dal.get)
        if (_.uniqBy(variantsData, 'type').length !== variantsData.length) {
            throw new ReportableError({
                message: 'Invalid variants array provided, please ensure that no more than one variant type is present',
                errorType: 'variantValidation'
            })
        }
    }

    /**
     * remove the variants according to the following algorithm:
     * 1. for each schema looks for the relations that includes the variants and remove them
     * 2. for each removed relation looks for the ref array and removes the ref to relation from the refArray
     * 3. remove all variants
     * @param {ps} ps
     * @param {Pointer []} variantsPointers
     */
    const removeScopedValuesByVariants = (ps, variantsPointers) => {
        const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointers))
        _.forEach(VALID_VARIANTS_DATA_TYPES, itemDataType => {
            const variantRelationsPointersToBeDeleted = getRelationsToDelete(ps, variantsPointers, itemDataType)

            _.forEach(variantRelationsPointersToBeDeleted, relationPointer => {
                let handlers
                if (DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[itemDataType]) {
                    handlers = {
                        refArrayOrValuePointerResolver: () => extensionsAPI.relationships.getOwningReferencesToPointer(ps, relationPointer, itemDataType)[0],
                        removeRefArrayFromReferenceResolver: _.noop
                    }
                }

                removeRelation(ps, relationPointer, itemDataType, pageId, handlers)
            })
        })

        // in case of multiple variants GC will remove variants when there no relation referring to variants
        if (variantsPointers.length === 1) {
            ps.dal.remove(_.head(variantsPointers))
        } else if (variantsPointers.length > 1) {
            validateVariants(ps, variantsPointers)
        }
    }

    /**
     * search in the schema that corresponds to itemType for relations that holds the same variants that is passed to func (full comparison)
     * predicate is optional when no predicate all relations that matches to variants exactly are returned
     *
     * @param {ps} ps
     * @param {string []} variantsPointers Ids
     * @param {string} itemType
     * @param {Function ?} predicate
     * @returns {Pointer [] | null} returns comp ids
     */
    const getRelationsByVariantsAndPredicate = (ps, variantsPointers, itemType, shouldMatchExactly = true, predicate = _.identity) => {
        const variants = _.map(variantsPointers, 'id')

        return _.reduce(
            variants,
            (result, variantId) => {
                const variantRelationsPointers = ps.pointers.data.getVariantRelations(itemType, variantId)

                const filteredRelationsPointers = _.filter(variantRelationsPointers, relationPointer => {
                    const relation = ps.dal.get(relationPointer)

                    // @ts-ignore
                    return (shouldMatchExactly ? exactMatchVariantRelationPredicateitem(ps, relation, variants) : true) && predicate(relation)
                })

                return _.unionBy(result, filteredRelationsPointers, 'id')
            },
            []
        )
    }

    /**
     * remove all illegal relation from ref array + it scoped value.
     * illegal relation is relation with variant that not in validVariantsIds list
     * if the func deletes all refArray values it will delete the refArray as well
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     * @param {Pointer} refArrayPointer
     * @param {Pointer} relationPointer
     * @param {string []} validVariantsIds
     * @param {string} dataType
     */
    const removeRelationIfIllegal = (ps, componentPointer, refArrayPointer, relationPointer, validVariantsIds, dataType) => {
        const relation = ps.dal.get(relationPointer)
        const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
        const relationVariants = dataModel.variantRelation.extractVariants(ps, relation)
        if (!_.isEmpty(relationVariants) && _.intersection(validVariantsIds, relationVariants).length !== relationVariants.length) {
            removeRelation(ps, relationPointer, dataType, componentPage.id)
        }
    }

    /**
     * remove all illegal relations from ref array + it scoped value.
     * illegal relation is relation with variant that no in validVariantsIds list
     * if the func deletes all refArray values it will delete the refArray as well
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     * @param {string} dataType
     * @param {string []} variantsToRemove
     */
    const removeIllegalRelationsFromRefArray = (ps, componentPointer, dataType, variantsToRemove) => {
        const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
        const dataPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, dataType)
        if (dataPointer) {
            const data = ps.dal.get(dataPointer)
            if (dataModel.refArray.isRefArray(ps, data)) {
                const relationIds = dataModel.refArray.extractValuesWithoutHash(ps, data)
                _.forEach(relationIds, relationId => {
                    const relationPointer = ps.pointers.data.getItem(dataType, relationId, componentPage.id)
                    removeRelationIfIllegal(ps, componentPointer, dataPointer, relationPointer, variantsToRemove, dataType)
                })
            }
        }
    }

    /**
     * get all variants from a component refArray > variantRelations
     *
     * @param {ps} ps privateServices
     * @param {Pointer} compPointer
     * @param {string} dataType
     * @return {string []}
     */

    const getCompVariantsFromRelations = (ps, compPointer, dataType) => {
        const data = dataModel.getComponentDataItemByType(ps, compPointer, dataType)
        if (dataModel.refArray.isRefArray(ps, data)) {
            const variantsIdsFromRelations = _(data.values)
                .map('variants')
                .compact()
                .flatten()
                .map(id => _.replace(id, '#', ''))
                .value()

            return variantsIdsFromRelations
        }
        return []
    }

    /**
     * returns all variants pointers from a component refArray > variantRelations for all namespaces
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     */
    const getAllAffectingVariantsGroupedByVariantType = (ps, componentPointer) => {
        let variantPointers = []
        _.forEach(VALID_VARIANTS_DATA_TYPES, dataType => {
            variantPointers = variantPointers.concat(getAllAffectingVariantsForDataType(ps, componentPointer, dataType))
        })
        const variantsAndVariantType = _.map(variantPointers, pointer => {
            const variantData = ps.dal.get(pointer)
            return {pointer, type: variantData.type}
        })

        return _(variantsAndVariantType)
            .uniqBy(item => item.pointer.id)
            .groupBy('type')
            .mapValues(arr => _.map(arr, 'pointer'))
            .value()
    }

    /**
     * returns all variants pointers from a component refArray > variantRelations for all namespaces
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     */
    const getAllAffectingVariantsForDataType = (ps, componentPointer, dataType) => {
        const variantIds = getCompVariantsFromRelations(ps, componentPointer, dataType)
        const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
        return _.map(variantIds, variantId => ps.pointers.data.getVariantsDataItem(variantId, componentPage.id))
    }

    return {
        exactMatchVariantRelationPredicateitem,
        nonScopedValuePointer,
        scopedValuePointer: getScopedValueByRelationPtr,
        getScopedValuePointerByVariants,
        addScopedValueToRelation,
        getRelationPointerFromRefArrayByVariants,
        removeScopedValuesByVariants,
        getRelationsByVariantsAndPredicate,
        getComponentFromRelation,
        removeIllegalRelationsFromRefArray,
        removeRelation,
        getAllAffectingVariantsGroupedByVariantType,
        getAllAffectingVariantsForDataType,
        getCompVariantsFromRelations
    }
})
