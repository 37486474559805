define([
    'lodash',
    'documentServices/variants/variants',
    'documentServices/namespaces/namespaces',
    'documentServices/constants/constants',
    'documentServices/utils/utils'
], (_, variants, namespaces, constants, dsUtils) => {
    const TRIGGER_TYPE = constants.VARIANTS.TYPES.TRIGGER
    const TRIGGERS_DATA_TYPE = 'Triggers'
    const TRIGGERS_NAMESPACE = constants.DATA_TYPES.triggers

    const getTriggerVariantToAddRef = (ps, compPointer) => variants.getVariantToAddRef(ps, compPointer, TRIGGER_TYPE)

    const validateTriggerData = triggerData => {
        if (!triggerData || !_.has(triggerData, 'trigger')) {
            throw new Error('Trigger should be an object with a "trigger" property')
        }
    }

    /**
     * Adds a new trigger to a component
     *
     * @param {ps} ps
     * @param {Pointer} variantToAddRef
     * @param {Pointer} componentPointer
     * @param {object} triggerData
     */
    const addTrigger = (ps, variantToAddRef, componentPointer, triggerData) => {
        validateTriggerData(triggerData)
        const currentTriggers = namespaces.getNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE)?.values || []
        const page = ps.pointers.components.getPageOfComponent(componentPointer)

        if (
            currentTriggers
                .map(v => ps.dal.get(ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(v), page.id)))
                .some(t => t.trigger === triggerData.trigger)
        ) {
            throw new Error(`Trigger of type ${triggerData.trigger} already exist for component ${JSON.stringify(componentPointer)}`)
        }

        variants.createInternal(ps, variantToAddRef, componentPointer, TRIGGER_TYPE, triggerData)
        currentTriggers.push(`#${variantToAddRef.id}`)

        namespaces.updateNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE, {type: TRIGGERS_DATA_TYPE, values: currentTriggers})
    }

    /**
     * Removes a trigger from a component
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} variantPointer
     */
    const removeComponentTrigger = (ps, componentPointer, variantPointer) => {
        if (!ps.dal.isExist(variantPointer) || ps.dal.get(variantPointer).type !== TRIGGER_TYPE) {
            throw new Error('Cannot remove a non-trigger pointer')
        }

        const allTriggers = getAllTriggers(ps, componentPointer)

        variants.remove(ps, variantPointer)

        const triggerDataAfterRemoval = allTriggers.filter(trigger => trigger.id !== variantPointer.id).map(varPtr => `#${varPtr.id}`)
        namespaces.updateNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE, {type: TRIGGERS_DATA_TYPE, values: triggerDataAfterRemoval})
    }

    /**
     * Gets the trigger data for a given variant
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} variantPointer
     * @returns {{ trigger: string, type: string}} Trigger data for the given trigger variant
     */
    const getTrigger = (ps, componentPointer, variantPointer) => {
        const triggerData = ps.dal.get(variantPointer)
        if (!triggerData || triggerData.type !== TRIGGER_TYPE) {
            throw new Error('Cannot get missing or non-trigger variant')
        }

        return _.pick(triggerData, ['trigger', 'type'])
    }

    /**
     * Gets all the triggerVariant references we have in the components' triggersData
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Array} An array of all Trigger variants of the component
     */
    const getAllTriggers = (ps, componentPointer) => {
        const triggersData = namespaces.getNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE)

        if (triggersData?.values) {
            const originalPagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
            const pageId = ps.dal.get(originalPagePointer).id

            return triggersData.values.map(variantId => ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(variantId), pageId))
        }

        return []
    }

    return {
        getTriggerVariantToAddRef,
        addTrigger,
        removeComponentTrigger,
        getTrigger,
        getAllTriggers
    }
})
