define(['lodash', '@wix/santa-ds-libs/src/coreUtils', 'documentServices/documentMode/documentModeInfo', '@wix/santa-core-utils'], function (
    _,
    coreUtils,
    documentModeInfo,
    santaCoreUtils
) {
    'use strict'

    const MODES_WITH_MANUAL_OVERRIDES = {}
    MODES_WITH_MANUAL_OVERRIDES[coreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES] = true

    function getMasterPageModeDefinitions(ps) {
        const viewMode = documentModeInfo.getViewMode(ps)
        const masterPagePointer = ps.pointers.components.getMasterPage(viewMode)
        const modesPointer = ps.pointers.componentStructure.getModes(masterPagePointer)

        if (!modesPointer) {
            return []
        }

        return _.get(ps.dal.get(modesPointer), 'definitions', [])
    }

    function isModeOverrideAffective(supportedModeIds, override) {
        return _.every(override.modeIds, modeId => !!supportedModeIds[modeId])
    }

    function getCompDefinitions(pointers, fullJsonDal, compPointer) {
        const definitionsPointer = pointers.componentStructure.getModesDefinitions(compPointer)

        return fullJsonDal.get(definitionsPointer)
    }

    function getAllModeIdsOfAncestors(pointers, fullJsonDal, currentAncestorPointer) {
        let modeIdsInAncestors = {}
        while (currentAncestorPointer !== null) {
            const modeDefinitions = getCompDefinitions(pointers, fullJsonDal, currentAncestorPointer)
            const modeIdsOfComp = _.map(modeDefinitions, 'modeId')
            modeIdsInAncestors = _.merge(modeIdsInAncestors, _.zipObject(modeIdsOfComp, modeIdsOfComp))
            currentAncestorPointer = pointers.components.getParent(currentAncestorPointer)
        }

        return modeIdsInAncestors
    }

    function getRootActiveModesSet(pointers, displayedJsonDal, rootId) {
        const activeModesPointer = pointers.general.getActiveModes()
        const activeModes = displayedJsonDal.get(activeModesPointer)
        if (rootId && activeModes[rootId]) {
            return _.omitBy(activeModes[rootId], val => !val)
        }

        return {}
    }

    function removeUnusedOverridesInComponentTree(pointers, fullJsonDal, fullCompStructure, newParentPointer) {
        const res = santaCoreUtils.objectUtils.cloneDeep(fullCompStructure)
        let modeIds = getAllModeIdsOfAncestors(pointers, fullJsonDal, newParentPointer)

        let compsToScan = [res]
        while (!_.isEmpty(compsToScan)) {
            const comp = compsToScan.pop()
            const modes = _.get(comp, 'modes.definitions')
            const modeIdsInComp = _.map(modes, 'modeId')
            modeIds = _.merge(modeIds, _.zipObject(modeIdsInComp, modeIdsInComp))

            const overrides = _.get(comp, 'modes.overrides')
            if (overrides) {
                const affectiveOverrides = _.filter(overrides, _.partial(isModeOverrideAffective, modeIds))
                _.set(comp, 'modes.overrides', affectiveOverrides)
                if (_.isEmpty(affectiveOverrides)) {
                    delete comp.modes.isHiddenByModes
                }
            }

            compsToScan = compsToScan.concat(comp.components || [])
        }

        return res
    }

    function getSOSPModes(ps) {
        const masterPageModes = getMasterPageModeDefinitions(ps)

        return _.filter(masterPageModes, {type: coreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES})
    }

    function getSospModeByPagesGroup(ps, pagesGroupPointer) {
        if (!ps.dal.isExist(pagesGroupPointer)) {
            return undefined
        }

        const sospModes = getSOSPModes(ps)

        return _.find(sospModes, ['settings.pagesGroupId', `#${pagesGroupPointer.id}`])
    }

    function createEmptyModesObject() {
        return {
            isHiddenByModes: false,
            definitions: [],
            overrides: []
        }
    }

    function getFirstAncestorWithModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, compPointer, isSelfIncluded) {
        if (!compPointer) {
            return null
        }

        let ancestorPointer = isSelfIncluded ? compPointer : pointers.full.components.getParent(compPointer)
        while (ancestorPointer) {
            if (!_.isEmpty(getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, ancestorPointer))) {
                return ancestorPointer
            }
            ancestorPointer = pointers.components.getParent(ancestorPointer)
        }

        return null
    }

    function getFirstAncestorModeToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, compPointer, isSelfIncluded) {
        const ancestorWithActiveModes = getFirstAncestorWithModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, compPointer, isSelfIncluded)
        if (ancestorWithActiveModes) {
            return _.head(getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, ancestorWithActiveModes))
        }

        return null
    }

    function createModesObjectForCompAddedInMode(activeModes, existingDefaultLayout) {
        const modes = createEmptyModesObject()
        modes.isHiddenByModes = true
        modes.overrides.push({
            // @ts-ignore
            modeIds: activeModes,
            // @ts-ignore
            isHiddenByModes: false,
            // @ts-ignore
            layout: existingDefaultLayout
        })

        return modes
    }

    function getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, compPointer) {
        const containingRootPointer = pointers.components.getPageOfComponent(compPointer)
        if (containingRootPointer) {
            const rootActiveModes = getRootActiveModesSet(pointers, displayedJsonDal, containingRootPointer.id)
            const compModesDefPointer = pointers.componentStructure.getModesDefinitions(compPointer)

            const modesToGenerateOverrides = _.reject(fullJsonDal.get(compModesDefPointer), modeDef => MODES_WITH_MANUAL_OVERRIDES[modeDef.type])

            return _.keys(coreUtils.modesUtils.getActiveComponentModeIds(rootActiveModes, modesToGenerateOverrides))
        }

        return []
    }

    function adjustModesToNewContainer(pointers, fullJsonDal, displayedJsonDal, newContainerPointer, compStructure) {
        compStructure = removeUnusedOverridesInComponentTree(pointers, fullJsonDal, compStructure, newContainerPointer)
        const ancestorActiveMode =
            getFirstAncestorModeToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, newContainerPointer) ||
            _.head(getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, newContainerPointer))

        if (ancestorActiveMode) {
            const initializedModes = createModesObjectForCompAddedInMode([ancestorActiveMode], compStructure.layout)
            if (compStructure.modes) {
                compStructure.modes.isHiddenByModes = true
                compStructure.modes.overrides = compStructure.modes.overrides || []
                const matchingOverride = _.find(compStructure.modes.overrides, override => _.isEqual(override.modeIds, [ancestorActiveMode]))
                if (matchingOverride) {
                    matchingOverride.isHiddenByModes = false
                } else {
                    compStructure.modes.overrides.push(initializedModes.overrides[0])
                }
            } else {
                compStructure.modes = initializedModes
            }
        }

        return compStructure
    }

    return {
        adjustModesToNewContainer,
        createEmptyModesObject,
        getFirstAncestorModeToGenerateOverrides,
        createModesObjectForCompAddedInMode,
        getComponentModesToGenerateOverrides,
        getSospModeByPagesGroup,
        getFirstAncestorWithModesToGenerateOverrides,
        getSOSPModes
    }
})
