import _ from 'lodash'
import * as mobx from 'mobx'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import observableDataUtil from '../observableData/observableDataUtil'

// @ts-ignore
const getObservableMap = (value, isShallow) => (isShallow ? mobx.observable.shallowMap(value) : mobx.observable.map(value))

export default isShallow => (siteData, path, value) => {
    if (path.length > 1) {
        return value
    }

    try {
        return getObservableMap(value || {}, isShallow)
    } catch (e) {
        coreUtils.loggingUtils.logger.reportBI(siteData, coreUtils.loggingUtils.bi.errors.DATA_PLUGIN_ERROR, {
            store: _.head(path),
            path,
            value: JSON.stringify(value),
            stack: e.stack
        })
        const newValue = value ? observableDataUtil.cloneDeep(value) : {}
        return getObservableMap(newValue, isShallow)
    }
}
