define(['lodash', '@wix/santa-core-utils'], function (_, santaCoreUtils) {
    return function (currentImmutableSnapshot, metaSiteResponse, currentSnapshotDal) {
        const clientSpecMap = currentSnapshotDal
            ? currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
            : currentImmutableSnapshot.getIn(['rendererModel', 'clientSpecMap']).toJS()

        return [
            {
                path: ['rendererModel', 'clientSpecMap'],
                value: _.assign({}, clientSpecMap, metaSiteResponse.payload.clientSpecMap)
            },
            {
                path: ['rendererModel', 'clientSpecMapCacheKiller'],
                value: {cacheKiller: santaCoreUtils.guidUtils.getGUID()}
            }
        ]
    }
})
