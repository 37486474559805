import _ from 'lodash'
import santaComponents from '@wix/santa-components'
import {styleCollector} from '@wix/santa-ds-libs/src/coreUtils'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import appRepo from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appRepo'
import appBuilderSantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appBuilderSantaTypesDefinitions'
import dataFetchingStateManager from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appPart2DataFetchingStateManager'

function addAppPartStyles({getStyleData, views, loadedStyles = {}}) {
    _.forEach(views, function (viewDef) {
        if (viewDef) {
            wixapps.styleCollector.collectViewStyles(viewDef, getStyleData, loadedStyles)
        }
    })

    wixapps.styleCollector.addDefaultStyles(getStyleData, loadedStyles)

    return loadedStyles
}

addAppPartStyles.cssTypes = {
    views: appBuilderSantaTypesDefinitions.views,
    getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData
}

styleCollector.registerClassBasedStyleCollector('wixapps.integration.components.AppPart2', function (structureInfo, getStyleData, siteData, loadedStyles) {
    const compData = structureInfo.dataItem
    const appService = siteData.getClientSpecMapEntry(compData.appInnerID)

    if (dataFetchingStateManager.isPartErroneous(siteData, appService)) {
        return []
    }

    const descriptor = wixapps.wixappsDataHandler.getDescriptor(siteData, appService.type)
    const partDef = descriptor && appRepo.getAppPartDefinition(descriptor, compData.appPartName)
    const views = partDef && appRepo.getPartViews(descriptor, partDef, '*')
    if (views) {
        addAppPartStyles({getStyleData, views, loadedStyles})
    }
})

export default addAppPartStyles
