import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import _ from 'lodash'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

export default {
    mixins: [baseProxy],
    propTypes: {
        viewProps: PropTypes.shape({
            compProp: PropTypes.shape({
                direction: PropTypes.string.isRequired
            })
        })
    },

    componentDidMount() {
        if (this.getCompProp('type') === 'unlike') {
            const postId = _.get(this.props, 'viewProps.partData._iid')
            const isPostLiked = _.get(JSON.parse(window.localStorage.getItem('likedBlogPosts')) || {}, postId)
            if (isPostLiked) {
                this.setVar('$isPostLiked', true)
            }
        }
    },

    renderProxy() {
        const COMP_TYPE = 'wysiwyg.viewer.components.ImageButtonWithText'
        const compProps = _.merge({}, this.getChildCompProps(COMP_TYPE), {
            compData: {
                iconSource: this.getCompProp('iconSource'),
                label: this.getCompProp('label'),
                extraInfo: this.getCompProp('extraInfo'),
                type: this.getCompProp('type')
            },
            compProp: {
                direction: this.props.viewProps.compProp.direction,
                size: this.getCompProp('size')
            }
        })
        return componentsCore.compRegistrar.getCompClass(COMP_TYPE, true)(compProps)
    }
}
