define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/variants/variantsUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/utils/utils',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/dataModel/dataSerialization',
    'documentServices/dataModel/dataIds',
    'documentServices/hooks/hooks',
    'documentServices/variants/relationsUtils',
    'experiment'
], function (_, constants, variantsUtils, dataModel, dsUtils, mobileUtil, dataSerialization, dataIds, hooks, relationsUtils, experiment) {
    'use strict'

    const {DATA_TYPES, COMP_DATA_QUERY_KEYS} = constants

    const ERRORS = {
        designOverVariants: componentPointer => `design over variants should not be used, please contact DM group, component pointer: ${componentPointer}`,
        designOverVariantsWithModes: componentPointer => `variant designs should not be used in modes, component pointer: ${componentPointer}`
    }

    const COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS = {
        'responsive.components.Section': true,
        'wysiwyg.viewer.components.StripColumnsContainer': true
    }

    const isSectionDataMigrationOpen = () => experiment.isOpen('dm_sectionDesignDataMigration')

    const isCompTypeIsValidForDesignInVariants = compType =>
        experiment.isOpen('dm_designOverVariants') && COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS[compType]

    const shouldUseDesignInVariants = (ps, componentPointer) => {
        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, DATA_TYPES.design)
        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
        const componentType = dsUtils.getComponentType(ps, actualComponentPointer)
        if (shouldConsiderVariants && !isCompTypeIsValidForDesignInVariants(componentType)) {
            throw new Error(ERRORS.designOverVariants(componentPointer))
        }

        return shouldConsiderVariants
    }

    const throwErrorIfDesignWithVariantsUsedInModes = (ps, componentPointer) => {
        if (ps.pointers.components.isWithVariants(componentPointer)) {
            throw new Error(ERRORS.designOverVariantsWithModes(componentPointer))
        }
    }

    const getDesignItemPointer = (ps, componentVariantsPointer) =>
        variantsUtils.getComponentDataPointerConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.design)

    const getDesignItem = (ps, componentVariantsPointer, deleteId) =>
        variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.design, deleteId)

    const getDesignPointerInModes = (ps, componentPointer, modes) => {
        const overrides = ps.dal.full.get(ps.pointers.componentStructure.getModesOverrides(componentPointer))
        const overrideInMode = _.find(overrides, function (override) {
            return modes && override.modeIds.length === modes.length && _.intersection(override.modeIds, modes).length === override.modeIds.length
        })
        let designQuery = _.get(overrideInMode, COMP_DATA_QUERY_KEYS.design)
        if (!designQuery) {
            const designQueryPointer = ps.pointers.getInnerPointer(componentPointer, COMP_DATA_QUERY_KEYS.design)
            designQuery = ps.dal.full.get(designQueryPointer)
        }
        if (designQuery) {
            const page = ps.pointers.full.components.getPageOfComponent(componentPointer)
            const designItemPointer = ps.pointers.data.getDesignItem(dsUtils.stripHashIfExists(designQuery), page.id)
            const designItem = ps.dal.get(designItemPointer) || ps.dal.full.get(designItemPointer)
            if (dataModel.refArray.isRefArray(ps, designItem)) {
                return getDesignItemPointer(ps, componentPointer)
            }

            return designItemPointer
        }
    }

    const getDesignItemByModes = (ps, componentPointer, modes) => {
        const designPointer = getDesignPointerInModes(ps, componentPointer, modes)
        return dataModel.getDataByPointer(ps, DATA_TYPES.design, designPointer)
    }

    const getDesignItemById = (ps, dataItemId, pageId, deleteId = false) => {
        const designPointer = ps.pointers.data.getDesignItem(dataItemId, pageId || 'masterPage')
        return dataModel.getDataByPointer(ps, DATA_TYPES.design, designPointer, deleteId)
    }

    const shouldRemoveOverlay = (ps, componentPointer, designItem) =>
        designItem.background && !designItem.background.colorOverlay && !designItem.background.imageOverlay && !shouldUseDesignInVariants(ps, componentPointer)

    const oldUpdateDesign = (ps, componentPointer, actualComponentPointer, designItem) => {
        const compDesignQuery = dataModel.getComponentDataItemId(ps, actualComponentPointer, COMP_DATA_QUERY_KEYS.design)
        const pageId = ps.pointers.full.components.getPageOfComponent(actualComponentPointer).id

        const doesComponentHaveDesignData = Boolean(compDesignQuery)
        const designId = dataSerialization.addSerializedDesignItemToPage(ps, pageId, designItem, compDesignQuery, actualComponentPointer)

        if (!doesComponentHaveDesignData) {
            dataModel.linkComponentToItemByType(ps, actualComponentPointer, designId, DATA_TYPES.design)
        }

        mobileUtil.linkMobileComponentToDesktopDesignItem(ps, componentPointer, designId)
        return designId
    }

    const updateDesignItem = (ps, componentPointer, designItem, retainCharas) => {
        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
        if (designItem.dataChangeBehaviors && shouldRemoveOverlay(ps, componentPointer, designItem)) {
            designItem.dataChangeBehaviors = _.reject(designItem.dataChangeBehaviors, {part: 'overlay'})
        }
        const compType = dsUtils.getComponentType(ps, actualComponentPointer)

        if (!retainCharas) {
            designItem.charas = dataIds.generateNewDesignId()
        }

        const designId = shouldUseDesignInVariants(ps, componentPointer)
            ? variantsUtils.updateComponentDataConsideringVariants(ps, componentPointer, designItem, DATA_TYPES.design)
            : oldUpdateDesign(ps, componentPointer, actualComponentPointer, designItem)

        hooks.executeHook(hooks.HOOKS.DESIGN.UPDATE_AFTER, compType, [ps, actualComponentPointer, designItem])

        return designId
    }

    const BEHAVIOR_PROPS_TO_COMPARE = ['trigger', 'type', 'part', 'name']

    const areBehaviorsEqual = (behavior1, behavior2) => BEHAVIOR_PROPS_TO_COMPARE.every(prop => behavior1[prop] === behavior2[prop])

    const getUpdatedBehaviors = (oldBehaviors, behaviorsToUpdate, shouldRemove) => {
        const isEqualToUpdatedBehaviorObjects = _.map(behaviorsToUpdate, function (behavior) {
            return {
                isEqualFunc: _.partial(areBehaviorsEqual, behavior),
                behavior
            }
        })

        let updatedBehaviors = oldBehaviors

        _.forEach(isEqualToUpdatedBehaviorObjects, function (isEqualToUpdatedBehaviorObject) {
            updatedBehaviors = _.reject(updatedBehaviors, isEqualToUpdatedBehaviorObject.isEqualFunc)
            if (!shouldRemove) {
                updatedBehaviors.push(isEqualToUpdatedBehaviorObject.behavior)
            }
        })

        return updatedBehaviors
    }

    const updateDesignItemBehaviors = (ps, componentPointer, newBehaviors) => {
        throwErrorIfDesignWithVariantsUsedInModes(ps, componentPointer)
        const designItem = getDesignItem(ps, componentPointer)

        designItem.dataChangeBehaviors = getUpdatedBehaviors(designItem.dataChangeBehaviors, newBehaviors, false)

        return updateDesignItem(ps, componentPointer, designItem)
    }

    const removeDesignItemBehaviors = (ps, componentPointer, behaviorsToRemove) => {
        throwErrorIfDesignWithVariantsUsedInModes(ps, componentPointer)
        const designItem = getDesignItem(ps, componentPointer)

        designItem.dataChangeBehaviors = getUpdatedBehaviors(designItem.dataChangeBehaviors, behaviorsToRemove, true)

        return updateDesignItem(ps, componentPointer, designItem)
    }

    const removeComponentDesignItem = (ps, componentPointer) => {
        if (shouldUseDesignInVariants(ps, componentPointer)) {
            variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, DATA_TYPES.design)
        } else {
            const designQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'designQuery')
            //do not handle deleting of data items themselves (e.g deleteDataItem),
            //can be risky and will be handled via garbage collection
            ps.dal.remove(designQueryPointer)
        }
    }

    const getMediaRef = designItem => _.get(designItem, 'background.mediaRef')

    const getComponentDefaultDesign = (ps, compStructure, pageId) => {
        const designItemId = compStructure.designQuery && dsUtils.stripHashIfExists(compStructure.designQuery)
        const designItem = ps.dal.get(ps.pointers.data.getDesignItem(designItemId, pageId || 'masterPage'))
        const nonScopesPointer = relationsUtils.nonScopedValuePointer(ps, DATA_TYPES.design, designItem, pageId)
        const defaultDesignItemId = nonScopesPointer ? nonScopesPointer.id : designItemId
        return defaultDesignItemId && getDesignItemById(ps, defaultDesignItemId, pageId)
    }

    return {
        getComponentDefaultDesign,
        getDesignItemById,
        getDesignItemPointer,
        updateDesignItem,
        updateDesignItemBehaviors,
        removeDesignItemBehaviors,
        getDesignItem,
        getDesignItemByModes,
        removeComponentDesignItem,
        getMediaRef,
        shouldUseDesignInVariants,
        isSectionDataMigrationOpen,
        ERRORS,
        COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS
    }
})
