define([
    'documentServices/documentServicesDataFixer/fixers/adiGroupFixer',
    'documentServices/documentServicesDataFixer/fixers/fiveGridLineFullWidthFixer',
    'documentServices/documentServicesDataFixer/fixers/brokenAnchorsFixer',
    'documentServices/documentServicesDataFixer/fixers/brokenLoginBarFixer',
    'documentServices/documentServicesDataFixer/fixers/orphanSlideDataFixer',
    'documentServices/documentServicesDataFixer/fixers/siteMembersDataFixer',
    'documentServices/documentServicesDataFixer/fixers/textComponentDataFixer',
    'documentServices/documentServicesDataFixer/fixers/VectorImageDataFixer',
    'documentServices/documentServicesDataFixer/fixers/visualFocusFixer',
    'documentServices/documentServicesDataFixer/fixers/mobileAnimationsDataFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/stylesDataFixer',
    'documentServices/documentServicesDataFixer/fixers/menuReferencesFixer',
    'documentServices/documentServicesDataFixer/fixers/soapOrderFixer',
    'documentServices/documentServicesDataFixer/fixers/languageSelectorFixer',
    'documentServices/documentServicesDataFixer/fixers/meshLayoutReadySiteDataFixer',
    'documentServices/documentServicesDataFixer/fixers/routersDataFixer',
    'documentServices/documentServicesDataFixer/fixers/sospDataFixer',
    'documentServices/documentServicesDataFixer/fixers/menuContainerDataFixer',
    'documentServices/documentServicesDataFixer/fixers/styles/addMissingPageStyleFixer',
    'documentServices/documentServicesDataFixer/fixers/formsControllerTypeDataFixer',
    'documentServices/documentServicesDataFixer/fixers/badPagesDataItemsDataFixer',
    'documentServices/documentServicesDataFixer/fixers/customMenuPermanentDataNodesFixer',
    'documentServices/documentServicesDataFixer/fixers/duplicatedMobileHintsDataFixer',
    'documentServices/documentServicesDataFixer/fixers/hoverboxSplitOverrideFixer',
    'documentServices/documentServicesDataFixer/fixers/duplicateDesignDataFixer',
    'documentServices/documentServicesDataFixer/fixers/pageBackgroundTranslationFixer',
    'documentServices/documentServicesDataFixer/fixers/unknownTypeVariantsFixer',
    'documentServices/documentServicesDataFixer/fixers/oldBlogDeprecationFixer',
    'documentServices/documentServicesDataFixer/fixers/dynamicContactFormDeprecationFixer',
    'documentServices/constants/constants'
], function (
    adiGroupFixer,
    fiveGridLineFullWidthFixer,
    brokenAnchorsFixer,
    brokenLoginBarFixer,
    orphanSlideDataFixer,
    siteMembersDataFixer,
    textComponentDataFixer,
    VectorImageDataFixer,
    visualFocusFixer,
    mobileAnimationsDataFixer,
    stylesDataFixer,
    menuReferencesFixer,
    soapOrderFixer,
    meshLayoutReadySiteDataFixer,
    routersDataFixer,
    sospDataFixer,
    languageSelectorFixer,
    menuContainerDataFixer,
    addMissingPageStyleFixer,
    formsControllerTypeDataFixer,
    badPagesDataItemsDataFixer,
    customMenuPermanentDataNodesFixer,
    duplicatedMobileHintsDataFixer,
    hoverboxSplitOverrideFixer,
    duplicateDesignDataFixer,
    pageBackgroundTranslationFixer,
    unknownTypeVariantsFixer,
    oldBlogDeprecationFixer,
    dynamicContactFormDeprecationFixer,
    constants
) {
    const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS

    return [
        {fixer: fiveGridLineFullWidthFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.FIVE_GRID_LINE_FULL_WIDTH}},
        {fixer: adiGroupFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ADI_GROUP}},
        {fixer: brokenAnchorsFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.BROKEN_ANCHORS}},
        {fixer: brokenLoginBarFixer, config: {fixMobileOnly: false, fedopsTag: 'broken-members-login-bar'}},
        {fixer: orphanSlideDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ORPHAN_SLIDER}},
        {fixer: siteMembersDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SITE_MEMBERS}},
        {fixer: textComponentDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.TEXT_COMPONENET}},
        {fixer: VectorImageDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.VECTOR_IMAGE}},
        {fixer: visualFocusFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.VISUAL_FOCUS}},
        {fixer: mobileAnimationsDataFixer, config: {fixMobileOnly: true, fedopsTag: dsDataFixersFedOpsTags.MOBILE_ANIMATION}},
        {fixer: stylesDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.STYLES}},
        {fixer: menuReferencesFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.DUPLICATE_ITEMS_REF_IN_MENU}},
        {fixer: soapOrderFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SOAP_ORDER}},
        {fixer: meshLayoutReadySiteDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.MESH_LAYOUT_READY_SITE}},
        {fixer: routersDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ROUTERS}},
        {fixer: sospDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SOSP}},
        {fixer: languageSelectorFixer, config: {fixMobileOnly: false, fedopsTag: 'language-selector-fixer'}},
        {fixer: menuContainerDataFixer, config: {fixMobileOnly: true, fedopsTag: dsDataFixersFedOpsTags.MENU_CONTAINER}},
        {fixer: addMissingPageStyleFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ADD_MISSING_PAGE_STYLE}},
        {fixer: badPagesDataItemsDataFixer, config: {fixMobileOnly: false, fedopsTag: 'bad-pages-data-items-fixer'}},
        {fixer: customMenuPermanentDataNodesFixer, config: {fixMobileOnly: false, fedopsTag: 'custom-menu-permanent-data-nodes-fixer'}},
        {fixer: formsControllerTypeDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ALL}},
        {fixer: duplicatedMobileHintsDataFixer, config: {fixMobileOnly: false, fedopsTag: 'duplicated-mobile-hints-data-fixer'}},
        {fixer: hoverboxSplitOverrideFixer, config: {fixMobileOnly: false, fedopsTag: 'hoverbox-design-fixer'}},
        {fixer: duplicateDesignDataFixer, config: {fixMobileOnly: false, fedopsTag: 'duplicate-design-data-fixer'}},
        {fixer: pageBackgroundTranslationFixer, config: {fixMobileOnly: false, fedopsTag: 'page-background-translation-fixer'}},
        {fixer: unknownTypeVariantsFixer, config: {fixMobileOnly: false, fedopsTag: 'unknown-type-variants-fixer'}},
        {fixer: oldBlogDeprecationFixer, config: {fixMobileOnly: false, fedopsTag: 'old-blog-deprecation-fixer'}},
        {fixer: dynamicContactFormDeprecationFixer, config: {fixMobileOnly: false, fedopsTag: 'old-contact-form-deprecation-fixer'}}
    ]
})
