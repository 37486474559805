define([
    'lodash',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/namespaces/namespaces',
    'documentServices/constants/constants',
    'documentServices/reactions/reactions',
    'documentServices/triggers/triggers',
    'documentServices/states/states',
    'experiment',
    'documentServices/utils/contextAdapter'
], function (_, componentDetectorAPI, namespaces, constants, reactions, triggers, states, experiment, contextAdapter) {
    'use strict'
    const STATES_NAMESPACE = constants.DATA_TYPES.states
    const STATES_TYPE = 'States'

    const getANewStateName = (ps, compRef) => {
        const allStates = states.getAll(ps, compRef)
        return `Hover ${allStates.length === 0 ? '' : allStates.length + 1}`.trim()
    }

    const getExistingStatesValues = (ps, compRef) => {
        const compStatesData = namespaces.getNamespaceData(ps, compRef, STATES_NAMESPACE)
        return compStatesData?.values || []
    }

    const MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER = 'MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER'
    const logTheHoverVariantAndHoverTriggerVariantFoundTogetherWhileMigrating = (compRef, pageId, hoverTriggers, hoverPointer) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER, {
            extras: {
                pageId,
                compRef,
                triggerVariantsIds: hoverTriggers.map(trigger => trigger.id),
                hoverVariantId: hoverPointer.id
            }
        })
        contextAdapter.utils.fedopsLogger.interactionEnded(MIGRATING_HOVER_VARIANT_TO_STATE_WHILE_THERE_IS_HOVER_TRIGGER)
    }

    const addHoverTriggerIfThereIsNoSuch = (ps, compRef, pageId, hoverPointer) => {
        const existingHoverTriggers = ps.pointers.data.getVariantItemsWithPredicate({type: 'Trigger', trigger: 'hover', componentId: compRef.id}, pageId)
        if (existingHoverTriggers.length > 0) {
            logTheHoverVariantAndHoverTriggerVariantFoundTogetherWhileMigrating(compRef, pageId, existingHoverTriggers, hoverPointer)
            return existingHoverTriggers[0]
        }
        const newHoverTrigger = triggers.getTriggerVariantToAddRef(ps, compRef)
        triggers.addTrigger(ps, newHoverTrigger, compRef, {trigger: 'hover'})
        return newHoverTrigger
    }

    const MIGRATE_HOVER_TO_STATE_REACTION_FIXER = 'migrate_hover_to_state_reaction_fixer'
    const makeHoverVariantsStatesVariants = ps => {
        const allPages = ps.pointers.page.getNonDeletedPagesPointers(true)
        const allDesktopPages = _.map(allPages, ps.pointers.components.getDesktopPointer)
        const toMigrate = _.map(allDesktopPages, page => {
            const hoverPointers = ps.pointers.data.getVariantItemsWithPredicate(variant => variant.type === 'Hover', page.id)
            return {page, hoverPointers}
        })

        contextAdapter.utils.fedopsLogger.interactionStarted(MIGRATE_HOVER_TO_STATE_REACTION_FIXER)

        // migrating the hover variant to a state variant, maintaining the id
        _.forEach(toMigrate, item => {
            item.hoverPointers.forEach(hoverPointer => {
                const data = ps.dal.get(hoverPointer)
                const compRef = componentDetectorAPI.getComponentById(ps, data.componentId)

                states.update(ps, compRef, hoverPointer, {type: 'State', name: getANewStateName(ps, compRef)})

                const statesValues = [...getExistingStatesValues(ps, compRef), `#${data.id}`]
                namespaces.updateNamespaceData(ps, compRef, STATES_NAMESPACE, {type: STATES_TYPE, values: statesValues})

                const hoverTrigger = addHoverTriggerIfThereIsNoSuch(ps, compRef, item.page.id, hoverPointer)
                reactions.add(ps, compRef, hoverTrigger, {type: 'AddState', state: hoverPointer})
            })
        })

        contextAdapter.utils.fedopsLogger.interactionEnded(MIGRATE_HOVER_TO_STATE_REACTION_FIXER)
    }

    return {
        exec(ps) {
            if (experiment.isOpen('dm_migrateHoverVariantToStateReaction')) {
                try {
                    makeHoverVariantsStatesVariants(ps)
                } catch (e) {
                    const err = new Error('Failed to migrate hover to state reaction')
                    contextAdapter.utils.fedopsLogger.captureError(err, {
                        extras: {
                            originalError: e
                        }
                    })
                    throw e
                }
            }
        },
        name: 'hoverToStateReactionFixer',
        version: 1
    }
})
