import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const refComponent = viewerLibrary.get('documentServices/refComponent/refComponent')
    return {
        methods: {
            components: {
                refComponents: {
                    hasOverridesToBeRemoved: publicMethodUtils.defineGetter(refComponent.hasOverridesToBeRemoved),
                    removeAllOverrides: publicMethodUtils.defineDataManipulationAction(refComponent.removeAllOverrides),
                    removeUnusedOverrides: publicMethodUtils.defineDataManipulationAction(refComponent.removeUnusedOverrides),
                    unGhostifyComponent: publicMethodUtils.defineDataManipulationAction(refComponent.unGhostifyComponent),
                    getGhostRefComponents: publicMethodUtils.defineGetter(refComponent.getGhostRefComponents),
                    getUniqueRefCompPointer: publicMethodUtils.defineGetter(refComponent.getUniqueRefCompPointer),
                    getRefHostCompPointer: publicMethodUtils.defineGetter(refComponent.getRefHostCompPointer),
                    getRootRefHostCompPointer: publicMethodUtils.defineGetter(refComponent.getRootRefHostCompPointer),
                    getTemplateCompPointer: publicMethodUtils.defineGetter(refComponent.getTemplateCompPointer),
                    isReferredComponent: publicMethodUtils.defineGetter(refComponent.isReferredComponent),
                    openReferredComponent: publicMethodUtils.defineDataManipulationAction(refComponent.openReferredComponent, {
                        getReturnValue: refComponent.getComponentToCreateRef
                    }),
                    closeWidgetToReferredComponent: publicMethodUtils.defineDataManipulationAction(refComponent.closeWidgetToReferredComponent, {
                        getReturnValue: refComponent.getComponentToCreateRef
                    }),
                    getAllGhostRefComponentsPrimaryConnection: publicMethodUtils.defineGetter(refComponent.getAllGhostRefComponentsPrimaryConnection),
                    /**
                     * the method get the template component references and returns all the ref components instances inflated form this template.
                     *
                     * @param {CompRef} compRef - pointers to ref component template
                     * @param {string} [pageId] - allow to filter and get the ref instance from the template of the same page. if not passed you will get instances from all pages
                     * @return {CompRef[]} all inflated refComponents that are reffered from the template passed
                     * @example documentServices.components.refComponents.getReferredComponents(compRef)
                     */
                    getReferredComponents: publicMethodUtils.defineGetter(refComponent.getReferredComponents)
                }
            }
        },
        initMethod: refComponent.init
    }
}
