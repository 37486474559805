import _ from 'lodash'
import layout from '../util/layout'
import $ from 'zepto'

const createRotationPatch = (node, rotation) => {
    if (node.getAttribute('data-force-3d') === 'true') {
        return {transform: `rotate3d(0, 0, 1, ${rotation}deg)`}
    }
    return null
}

function lineLayout(id, nodesMap, measureMap, siteData, structureInfo) {
    const minHeight = measureMap.height[`${id}line`] || 0
    const node = nodesMap[id]
    const borderTopWidth = parseInt($(node).data('borderWidth'), 10)
    measureMap.height[id] = Math.max(5, isNaN(borderTopWidth) ? 0 : borderTopWidth, minHeight)
    measureMap.minHeight[id] = minHeight

    return patchers => {
        const isFulllScreen = $(node).data('legacyFullScreen')
        const rotation = _.get(structureInfo, 'layout.rotationInDegrees')

        if (isFulllScreen) {
            patchers.css(id, {width: '100%', left: 0, marginLeft: 0})
        }
        if (rotation) {
            const rotationPatch = createRotationPatch(node, rotation)
            if (rotationPatch) {
                patchers.css(id, rotationPatch)
            }
        }
    }
}

layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.FiveGridLine', [['line']])
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.FiveGridLine')
layout.registerCustomLayoutFunction('wysiwyg.viewer.components.FiveGridLine', lineLayout)
