import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const environment = viewerLibrary.get('documentServices/environment/environment')
    return {
        methods: {
            env: {
                viewer: {
                    /**
                     * Returns the name of the viewer currently used by DS
                     */
                    getViewerName: publicMethodUtils.defineGetter(environment.getViewerName),

                    /**
                     * Returns the version of the viewer currently used by DS
                     */
                    getViewerVersion: publicMethodUtils.defineGetter(environment.getViewerVersion)
                },
                screen: {
                    getResolution: publicMethodUtils.defineGetter(environment.getScreenResolution)
                }
            }
        }
    }
}
