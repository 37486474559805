import _ from 'lodash'
import {DocumentManager, pointerUtils} from '@wix/document-manager-core'
import type {PageAPI} from '@wix/document-manager-extensions/src/extensions/page'
import {ReportableError} from '@wix/document-manager-utils'
const {getPointer} = pointerUtils

const fixChildRefs = (documentManager: DocumentManager, namespace: string) => {
    const {dal, logger, extensionAPI} = documentManager
    const allPagesIndex = (extensionAPI.page as PageAPI).getAllPagesIndexId()

    const allComponents = dal.query(namespace, allPagesIndex)
    _.forEach(allComponents, component => {
        const componentPageId = component.metaData.pageId
        const componentId = component.id
        const childIds = component.components
        const childrenToRemove: string[] = []
        _.forEach(childIds, childId => {
            const child = allComponents[childId]
            if (!child) {
                logger.captureError(
                    new ReportableError({
                        errorType: 'brokenChildRefError',
                        message: `Comp ${componentId} in page ${componentPageId} has broken child ref ${childId}, ns: ${namespace}`,
                        extras: {
                            brokenRef: childId,
                            parent: componentId,
                            page: componentPageId,
                            namespace
                        }
                    })
                )
            } else if (child.parent !== componentId) {
                const childPage = child.metaData.pageId
                logger.captureError(
                    new ReportableError({
                        errorType: 'duplicateChildRefError',
                        message: `Comp ${componentId} in page ${componentPageId} has child ${childId} belonging to parent ${child.parent} in page ${childPage}, ns: ${namespace}`,
                        extras: {
                            duplicate: childId,
                            parent: componentId,
                            page: componentPageId,
                            originalParent: child.parent,
                            originalPage: childPage,
                            namespace
                        }
                    })
                )
                childrenToRemove.push(childId)
            }
        })

        if (childrenToRemove.length > 0) {
            dal.set(getPointer(component.id, namespace, ['components']), _.without(childIds, ...childrenToRemove))
        }
    })
}
const migrateMasterPage = (documentManager: DocumentManager) => {
    if ((documentManager.extensionAPI.page as PageAPI).isPartiallyLoaded()) {
        return
    }

    fixChildRefs(documentManager, 'DESKTOP')
    fixChildRefs(documentManager, 'MOBILE')
}

const name = 'childRefFixer'
const version = 1

export {migrateMasterPage, name, version}
