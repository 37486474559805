define([
    'lodash',
    'documentServices/structure/utils/layoutValidation',
    'documentServices/validation/validators/styleValidator',
    'documentServices/validation/validators/skinValidator',
    'documentServices/validation/validators/compPropValidator',
    'documentServices/validation/validators/compDataValidator',
    'documentServices/validation/validators/siteJsonValidator',
    'documentServices/mobileConversion/modules/mobileHintsValidator',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/dataModel/dataModel'
], function (
    _,
    layoutValidation,
    styleValidator,
    skinValidator,
    compPropValidator,
    compDataValidator,
    siteJsonValidator,
    mobileHintsValidator,
    componentStylesAndSkinsAPI,
    componentDetectorAPI,
    dataModel
) {
    'use strict'

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {{componentType: *, dataType: any | Pointer | string, skin: *, id: *, pageId: string}}
     */
    function getInvalidComponentDescription(ps, compPointer) {
        const compStructure = ps.dal.get(compPointer)

        const pageOfComp = ps.pointers.components.getPageOfComponent(compPointer)
        const data = dataModel.getDataItem(ps, compPointer)

        return {
            id: compPointer.id,
            pageId: pageOfComp && pageOfComp.id,
            componentType: compStructure.componentType ?? compStructure.documentType,
            skin: componentStylesAndSkinsAPI.skin.get(ps, compPointer),
            dataType: data && data.type
        }
    }

    const VALIDATORS = {
        mobileHints: mobileHintsValidator.validateComponentMobileHints,
        data: compDataValidator.validateCompData,
        properties: compPropValidator.validateCompProps,
        layout: layoutValidation.validateLayout,
        skin: skinValidator.validateComponentSkin,
        style: styleValidator.validateComponentStyle,
        json: siteJsonValidator.validateCompJSONpaths
    }

    function asArray(possiblyArray) {
        return _.isArray(possiblyArray) ? possiblyArray : [possiblyArray]
    }

    function validateComponentsWithValidators(ps, comps, validators) {
        return _.reduce(
            asArray(comps),
            function (invalidComps, comp) {
                const invalidations = _(validators)
                    .mapValues(function (validator) {
                        try {
                            validator(ps, comp)
                        } catch (/** @type any */ validationError) {
                            return validationError.message ?? validationError.toString()
                        }
                        return null
                    })
                    .omitBy(_.isNull)
                    .value()

                if (!_.isEmpty(invalidations)) {
                    invalidComps.push({
                        comp: getInvalidComponentDescription(ps, comp),
                        invalidations
                    })
                }

                return invalidComps
            },
            []
        )
    }

    function validateComponents(ps, comps) {
        return validateComponentsWithValidators(ps, comps, VALIDATORS)
    }

    /**
     * Used for automation
     * @param {ps} ps
     * @param validators
     * @param [pageId]
     * @param [viewMode]
     * @returns {*}
     */
    function validateAllComponentsWithValidators(ps, validators, pageId, viewMode) {
        const allComponents = componentDetectorAPI.getAllComponents(
            ps,
            pageId,
            function (comp) {
                return !ps.pointers.components.isMasterPage(comp)
            },
            viewMode
        )
        return validateComponentsWithValidators(ps, allComponents, validators)
    }

    function validateAllComponents(ps) {
        return validateAllComponentsWithValidators(ps, VALIDATORS)
    }

    function validateAllMobileHints(ps) {
        const pageIds = _.map(ps.pointers.page.getNonDeletedPagesPointers(true), 'id')
        try {
            mobileHintsValidator.validateMobileHintsOnPages(ps, pageIds)
        } catch (error) {
            return error
        }

        return null
    }

    function initialize(ps) {
        mobileHintsValidator.initialize(ps)
    }

    return {
        initialize,
        validateComponentData: VALIDATORS.data,
        validateComponentProperties: VALIDATORS.properties,
        validateComponentLayout: VALIDATORS.layout,
        validateComponentSkin: VALIDATORS.skin,
        validateStyle: VALIDATORS.style,
        validateMobileHints: VALIDATORS.mobileHints,
        validateComponents,
        validateAllComponents,
        validateAllComponentsWithValidators,
        validateAllMobileHints
    }
})
