import _ from 'lodash'
import PropTypes from 'prop-types'
import coreUtilsLib from '@wix/santa-core-utils'
import santaComponents from '@wix/santa-components'

export default {
    propTypes: {
        performanceNow: santaComponents.santaTypesDefinitions.Utils.logging.performance.now,
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
        rootId: santaComponents.santaTypesDefinitions.Component.rootId.isRequired,
        handleAction: santaComponents.santaTypesDefinitions.Behaviors.handleAction.isRequired,
        galleryCompId: PropTypes.string,
        imageIndex: PropTypes.number
    },

    handleImageExpandedAction() {
        const currentPageId = this.props.rootId
        const expandedImageData = _.get(this.props, 'compData')
        const imageIndex = _.get(this.props, 'imageIndex')
        const sourceId = this.props.galleryCompId

        if (sourceId) {
            const {handleAction} = this.props

            const actions = [
                {
                    name: coreUtilsLib.siteConstants.ACTION_TYPES.ITEM_CLICKED,
                    pageId: currentPageId,
                    sourceId,
                    type: 'comp'
                },
                {
                    name: coreUtilsLib.siteConstants.ACTION_TYPES.IMAGE_EXPANDED,
                    pageId: currentPageId,
                    sourceId,
                    type: 'comp'
                },
                {
                    name: coreUtilsLib.siteConstants.ACTION_TYPES.IMAGE_CHANGED,
                    pageId: currentPageId,
                    sourceId,
                    type: 'comp'
                }
            ]

            _.forEach(actions, action => {
                const event = {
                    item: expandedImageData,
                    timeStamp: this.props.performanceNow(),
                    imageIndex,
                    name: 'imageExpanded'
                }
                handleAction(action, event)
            })
        }
    }
}
