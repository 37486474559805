define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/platform/common/constants',
    'documentServices/connections/connectionsDataGetter',
    'documentServices/appControllerData/appControllerStageData',
    'documentServices/appControllerData/appControllerDataItem',
    'documentServices/appControllerData/appControllerState',
    'platformEvents',
    'documentServices/platform/services/notificationService',
    'documentServices/tpa/services/clientSpecMapService'
], function (
    _,
    dataModel,
    constants,
    connectionsDataGetter,
    appControllerStageData,
    appControllerDataItem,
    appControllerState,
    platformEvents,
    notificationService,
    clientSpecMapService
) {
    'use strict'

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {any}
     */
    function getSettings(ps, controllerRef) {
        const controllerData = appControllerDataItem.getControllerDataItem(ps, controllerRef)
        return controllerData.settings ? JSON.parse(controllerData.settings) : {}
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param path
     * @returns {*}
     */
    function getSettingsIn(ps, controllerRef, path) {
        const settings = getSettings(ps, controllerRef)
        return _.get(settings, path)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {*}
     */
    function getName(ps, controllerRef) {
        return _.get(appControllerDataItem.getControllerDataItem(ps, controllerRef), 'name')
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param settingsItem
     * @param {boolean} useOriginalLanguage
     */
    function setSettings(ps, controllerRef, settingsItem, useOriginalLanguage = false) {
        let stringifiedSettings
        try {
            stringifiedSettings = JSON.stringify(settingsItem)
        } catch (e) {
            throw new Error('Invalid settings item - should be JSON stringifiable')
        }
        const previousData = getSettings(ps, controllerRef)
        appControllerDataItem.setControllerDataItem(ps, controllerRef, {settings: stringifiedSettings}, useOriginalLanguage)
        notifyApplicationAboutControllerDataChanged(ps, controllerRef, previousData)
    }

    function notifyApplicationAboutControllerDataChanged(ps, controllerRef, previousData) {
        const {applicationId: appDefinitionId} = appControllerDataItem.getControllerDataItem(ps, controllerRef)

        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        notificationService.notifyApplication(
            ps,
            appData?.applicationId,
            platformEvents.factory.componentDataChanged({
                compRef: controllerRef,
                previousData
            })
        )
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param path
     * @param settingsItem
     */
    function setSettingsIn(ps, controllerRef, path, settingsItem) {
        const settings = getSettings(ps, controllerRef)
        _.set(settings, path, settingsItem)
        setSettings(ps, controllerRef, settings)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param controllerName
     */
    function setName(ps, controllerRef, controllerName) {
        appControllerDataItem.setControllerDataItem(ps, controllerRef, {name: controllerName})
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {*}
     */
    function getControllerStageDataByControllerRef(ps, controllerRef) {
        return appControllerStageData.getControllerStageDataByControllerRef(ps, controllerRef)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param role
     * @param subRole
     * @returns {*}
     */
    function getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role, subRole) {
        return appControllerStageData.getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role, subRole)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {Object} options
     * options.usePrimaryRoleOnly - If provided, only primary role of the connection is considerd. defaults to false.
     * @returns {*}
     */
    function getConnectedComponentStageData(ps, compPointer, {usePrimaryRoleOnly = false} = {}) {
        const connection = connectionsDataGetter.getPrimaryConnection(ps, compPointer)
        if (!connection) {
            return
        }

        const role = _.get(connection, ['role'], constants.Controller.WILDCARD_ROLE)
        const subRole = usePrimaryRoleOnly ? null : _.get(connection, ['subRole'])
        return getControllerRoleStageDataByControllerRefAndRole(ps, connection.controllerRef, role, subRole)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param {string} [externalId]
     */
    function setExternalId(ps, controllerRef, externalId) {
        externalId = externalId || ''
        appControllerDataItem.setControllerDataItem(ps, controllerRef, {externalId})
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {string}
     */
    function getExternalId(ps, controllerRef) {
        return _.get(appControllerDataItem.getControllerDataItem(ps, controllerRef), 'externalId')
    }

    /**
     * Get controllerRef of connection item
     * @param {ps} ps
     * @param connectionItem
     * @param compRef - The connected component (or refComponent in case of closed inner widget)
     */
    function getControllerRefByConnectionItem(ps, connectionItem, compRef) {
        const controllerDataId = _.get(connectionItem, 'controllerId')
        const pagePointer = ps.pointers.components.getPageOfComponent(compRef)
        return dataModel.getControllerRefFromId(ps, controllerDataId, pagePointer)
    }

    return {
        setSettings,
        getSettings,
        setSettingsIn,
        getSettingsIn,
        getName,
        setName,
        setState: appControllerState.setState,
        /**
         * @param {ps} ps
         * @param controllerRef
         * @returns {any|string}
         */
        getState(ps, controllerRef) {
            return appControllerState.getState(ps, controllerRef.id)
        },
        getControllerStageDataByControllerRef,
        getControllerRoleStageDataByControllerRefAndRole,
        getConnectedComponentStageData,
        getControllerRefByConnectionItem,
        setExternalId,
        getExternalId,
        // privateAPI
        getControllerStageData: appControllerStageData.getControllerStageData,
        hasAppManifestByControllerRef: appControllerStageData.hasAppManifestByControllerRef
    }
})
