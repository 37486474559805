define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/santa-core-utils',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/dataModel/dataModel',
    'experiment'
], function (_, coreUtils, santaCoreUtils, siteMetadata, clientSpecMap, dataModel) {
    'use strict'

    const VENDOR_ID_TO_MODEL_KEY = {
        google: 'socialLoginGoogleEnabled',
        facebook: 'socialLoginFacebookEnabled'
    }

    const SIGNUP_POLICIES_TO_MODEL_KEY = {
        termsOfUse: 'termsOfUse',
        privacyPolicy: 'privacyPolicy',
        codeOfConduct: 'codeOfConduct'
    }

    const COMMUNITY_SETTINGS_MODEL_KEY = 'joinCommunityCheckedByDefault'

    const CUSTOM_SIGNUP_SETTINGS = 'customSignUpPageId'

    const CUSTOM_SIGN_IN_SETTINGS = 'customSignInPageId'

    const CUSTOM_NO_PERMISSIONS_PAGE = 'customNoPermissionsPageId'

    function getMemberSettingsEndpoint(ps) {
        const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
        return `/_api/member-permissions-server/metasite/${metaSiteId}/siteMemberSettings`
    }

    function getSiteMembersApp(ps) {
        return _.head(clientSpecMap.filterAppsDataByType(ps, 'sitemembers'))
    }

    function getSiteMembersSettingsPointer(ps) {
        const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
        return ps.pointers.getInnerPointer(siteStructureDataPointer, 'smSettings')
    }

    function setAutoApproval(ps, autoApproval, onSuccess, onError) {
        const onAjaxSuccess = function () {
            const siteMembersApp = getSiteMembersApp(ps)
            siteMembersApp.collectionType = autoApproval ? 'Open' : 'ApplyForMembership'
            clientSpecMap.registerAppData(ps, siteMembersApp)
            if (_.isFunction(onSuccess)) {
                onSuccess()
            }
        }

        coreUtils.ajaxLibrary.ajax({
            type: 'POST',
            url: getMemberSettingsEndpoint(ps),
            dataType: 'json',
            headers: {
                Authorization: ps.siteDataAPI.siteData.getClientSpecMap()[-666].instance,
                'X-XSRF-TOKEN': santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN')
            },
            data: {siteMemberSettings: {autoApprove: autoApproval}},
            success: onAjaxSuccess,
            error: onError || _.noop
        })
    }

    function isAutoApproval(ps) {
        const siteMembersApp = getSiteMembersApp(ps)
        return siteMembersApp.collectionType === 'Open'
    }

    function setSmSettingsData(ps, data) {
        const masterPagePointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
        const masterPageData = ps.dal.get(masterPagePointer)
        masterPageData.smSettings = _.merge(masterPageData.smSettings, data)
        dataModel.addSerializedDataItemToPage(ps, 'masterPage', masterPageData, 'masterPage')
    }

    function setLoginDialogFirst(ps, loginDialogFirst) {
        setSmSettingsData(ps, {smFirstDialogLogin: !!loginDialogFirst})
    }

    function isLoginDialogFirst(ps) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return !!(smSettings && smSettings.smFirstDialogLogin)
    }

    function setSocialLoginVendorStatus(ps, vendor, enabled) {
        const key = VENDOR_ID_TO_MODEL_KEY[vendor]
        if (key) {
            setSmSettingsData(ps, _.set({}, key, !!enabled))
        }
    }

    function isSocialLoginEnabled(ps, vendor) {
        const key = VENDOR_ID_TO_MODEL_KEY[vendor]
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return !!_.get(smSettings, key)
    }

    function setBackendLoginOnlyStatus(ps, backendLoginOnlyStatus, onSuccess, onError) {
        coreUtils.ajaxLibrary.ajax({
            type: 'POST',
            url: getMemberSettingsEndpoint(ps),
            dataType: 'json',
            headers: {
                Authorization: ps.siteDataAPI.siteData.getClientSpecMap()[-666].instance,
                'X-XSRF-TOKEN': santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN')
            },
            data: {siteMemberSettings: {shouldSupportBackendOnly: backendLoginOnlyStatus}},
            success: onSuccess || _.noop,
            error: onError || _.noop
        })
    }

    function isBackendLoginOnlyEnabled(ps, onSuccess, onError) {
        coreUtils.ajaxLibrary.ajax({
            type: 'GET',
            url: getMemberSettingsEndpoint(ps),
            dataType: 'json',
            headers: {
                Authorization: ps.siteDataAPI.siteData.getClientSpecMap()[-666].instance,
                'X-XSRF-TOKEN': santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN')
            },
            success: ({shouldSupportBackendOnly}) => _.isFunction(onSuccess) && onSuccess(shouldSupportBackendOnly),
            error: onError || _.noop
        })
    }

    function setJoinCommunityStatus(ps, enabled) {
        setSmSettingsData(ps, _.set({}, COMMUNITY_SETTINGS_MODEL_KEY, !!enabled))
    }

    function isJoinCommunityEnabled(ps) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return !(_.get(smSettings, COMMUNITY_SETTINGS_MODEL_KEY) === false)
    }

    function isSignupPoliciesFieldEnabled(ps, policy) {
        const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return !!_.get(smSettings, [key, 'enabled'])
    }

    function setSignupPoliciesFieldStatus(ps, policy, enabled) {
        const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
        if (key) {
            setSmSettingsData(ps, _.set({}, [key, 'enabled'], !!enabled))
        }
    }

    function setSignupPoliciesLink(ps, policy, link) {
        const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
        if (key) {
            const linkData = dataModel.addLink(ps, link.type, link)
            setSmSettingsData(ps, _.set({}, [key, 'link'], `#${linkData}`))
        }
    }

    function getSignupPoliciesLink(ps, policy) {
        const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return _.get(smSettings, [key, 'link'], null)
    }

    function setCustomSignupPageId(ps, pageId) {
        setSmSettingsData(ps, _.set({}, CUSTOM_SIGNUP_SETTINGS, pageId))
    }

    function getCustomSignupPageId(ps) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return _.get(smSettings, CUSTOM_SIGNUP_SETTINGS, null)
    }

    function setCustomSignInPageId(ps, pageId) {
        setSmSettingsData(ps, _.set({}, CUSTOM_SIGN_IN_SETTINGS, pageId))
    }

    function getCustomSignInPageId(ps) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return _.get(smSettings, CUSTOM_SIGN_IN_SETTINGS, null)
    }

    function setCustomNoPermissionsPageId(ps, pageId) {
        setSmSettingsData(ps, _.set({}, CUSTOM_NO_PERMISSIONS_PAGE, pageId))
    }

    function getCustomNoPermissionsPageId(ps) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        return _.get(smSettings, CUSTOM_NO_PERMISSIONS_PAGE, null)
    }

    const showSignUpDialog = async ps => ps.siteAPI.showSignUpDialog()
    const showLoginDialog = async ps => ps.siteAPI.showLoginDialog()
    const hideAuthDialog = async ps => ps.siteAPI.hideAuthDialog()

    return {
        setAutoApproval,
        isAutoApproval,
        setLoginDialogFirst,
        isLoginDialogFirst,
        setSocialLoginVendorStatus,
        isSocialLoginEnabled,
        setBackendLoginOnlyStatus,
        isBackendLoginOnlyEnabled,
        setJoinCommunityStatus,
        isJoinCommunityEnabled,
        isSignupPoliciesFieldEnabled,
        setSignupPoliciesFieldStatus,
        showSignUpDialog,
        showLoginDialog,
        hideAuthDialog,
        getSignupPoliciesLink,
        setSignupPoliciesLink,
        setCustomSignupPageId,
        getCustomSignupPageId,
        setCustomSignInPageId,
        getCustomSignInPageId,
        setCustomNoPermissionsPageId,
        getCustomNoPermissionsPageId
    }
})
