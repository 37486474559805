import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const routers = viewerLibrary.get('documentServices/routers/routers')
    viewerLibrary.get('documentServices/routers/pointers/routersPointers')
    return {
        methods: {
            routers: {
                add: publicMethodUtils.defineDataManipulationAction(routers.add, {
                    getReturnValue: routers.getRouterPointer
                }),
                remove: publicMethodUtils.defineDataManipulationAction(routers.remove),
                reloadRouterData: publicMethodUtils.defineDataManipulationAction(routers.reloadRouterData),
                get: {
                    all: publicMethodUtils.defineGetter(routers.get.all),
                    byApp: publicMethodUtils.defineGetter(routers.get.byApp),
                    byRef: publicMethodUtils.defineGetter(routers.get.byRef),
                    byId: publicMethodUtils.defineGetter(routers.get.byId)
                },
                getRouterRef: {
                    byPrefix: publicMethodUtils.defineGetter(routers.getRouterRef.byPrefix),
                    byPage: publicMethodUtils.defineGetter(routers.getRouterRef.byPage)
                },
                update: publicMethodUtils.defineDataManipulationAction(routers.update),
                getRouterDataForPageIfExist: publicMethodUtils.defineGetter(routers.getRouterDataForPageIfExist),
                getPageFromInnerRoute: publicMethodUtils.defineGetter(routers.getPageFromInnerRoute),
                getRouterInnerRoutes: publicMethodUtils.defineGetter(routers.getRouterInnerRoutes),
                getRouterInnerRoutesCount: publicMethodUtils.defineGetter(routers.getRouterInnerRoutesCount),
                getRouterSiteMap: publicMethodUtils.defineGetter(routers.getRouterSiteMap),
                getCurrentInnerRoute: publicMethodUtils.defineGetter(routers.getCurrentInnerRoute),
                isValidPrefix: publicMethodUtils.defineGetter(routers.isValidPrefix),
                pages: {
                    add: publicMethodUtils.defineDataManipulationAction(routers.pages.add, {
                        getReturnValue: routers.getPageToAddPointer
                    }),
                    connect: publicMethodUtils.defineDataManipulationAction(routers.pages.connect),
                    disconnect: publicMethodUtils.defineDataManipulationAction(routers.pages.disconnect),
                    removePageFromRouter: publicMethodUtils.defineDataManipulationAction(routers.pages.removePageFromRouter),
                    listConnectablePages: publicMethodUtils.defineGetter(routers.pages.listConnectablePages),
                    isConnectablePage: publicMethodUtils.defineGetter(routers.pages.isConnectablePage),
                    getDynamicPagesList: publicMethodUtils.defineGetter(routers.pages.getDynamicPagesList),
                    move: publicMethodUtils.defineDataManipulationAction(routers.pages.move)
                },
                livePreview: {
                    subscribeToRoutersInvalidation: publicMethodUtils.defineDataManipulationAction(routers.subscribeToConcurrentRoutersInvalidation),
                    invalidateRouters: publicMethodUtils.defineDataManipulationAction(routers.notifyConcurrentRoutersInvalidation)
                }
            },
            tpa: {
                getTpaInnerRoutes: publicMethodUtils.defineGetter(routers.getTpaInnerRoutes)
            }
        },
        initMethod: routers.initialize
    }
}
