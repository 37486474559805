import type {DAL, Extension} from '@wix/document-manager-core'
import type {Pointers, Experiment} from '@wix/document-services-types'
import type {ViewerManager} from '@wix/viewer-manager-adapter'

export * from './extensions/csave/serverProtocol'
export {EnvironmentExtensionAPI} from './extensions/environment'
export {DocumentServicesModelExtApi} from './extensions/documentServicesModel'
export {CSaveApi} from './extensions/csave/continuousSave'
export {ValidationWhitelistExtensionAPI} from './extensions/validationWhitelist'
export {ViewsExtensionAPI} from './extensions/views/views'

export interface DalPointers {
    dal: DAL
    pointers: Pointers
}

export interface PageModel {
    pageId: string
    title: string
    pageUriSEO: string
    pageJsonFileName: string
}

export interface CreateViewerExtensionArgument {
    viewerManager: ViewerManager
    experimentInstance: Experiment
}

export interface ViewerExtensionFactory {
    createExtension(createViewerExtensionArgument: CreateViewerExtensionArgument): Extension
}

type Atom = null | string | number | boolean

export interface Serializable {
    [key: string]: Atom | Serializable[] | Serializable
}

export type Namespace = Record<string, Serializable>

export type Namespaces = Record<string, Namespace>
