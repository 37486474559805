define([
    'lodash',
    'documentServices/mobileConversion/modules/utils',
    'documentServices/constants/constants',
    'documentServices/hooks/hooks',
    'documentServices/dataModel/dataModel',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsUtils',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsAPI',
    'documentServices/mobileConversion/modules/mobileHints'
], function (_, mobileConversionUtils, constants, hooks, dataModel, mobilePresetsUtils, mobilePresetsAPI, mobileHints) {
    const {isMobileHintsPreset, shouldMarkAsDirty, hasGeneralData} = mobilePresetsUtils
    const {shouldEnableNewMergeFlow, shouldEnableImprovedMergeFlow} = mobileConversionUtils

    const {
        removeAllPresetDataRecursively,
        removePresetOffsetDataOfChildren,
        removePresetOffsetData,
        removeAutomaticPresets,
        removePresetOffsetDataIfNeeded,
        handleMobileStructure,
        removeGeneralPresetDataIfNeeded,
        removePresetSizeIfNeeded,
        convertMobileComponentToMobileHints
    } = mobilePresetsAPI

    const mobileHintsChangeHooks = {
        [hooks.HOOKS.CHANGE_PARENT.BEFORE]: (ps, componentPointer, newContainerPointer) => {
            if (ps.pointers.components.isMobile(componentPointer)) {
                return
            }
            removeAllPresetDataRecursively(ps, componentPointer)
            removePresetOffsetDataOfChildren(ps, newContainerPointer)
            const containerPointer = ps.pointers.components.getParent(componentPointer)
            removePresetOffsetDataOfChildren(ps, containerPointer)
        },
        [hooks.HOOKS.ADD_ROOT.BEFORE]: (ps, componentPointer, containerPointer) => {
            if (ps.pointers.components.isMobile(componentPointer) || ps.pointers.components.isPage(componentPointer)) {
                return
            }
            removePresetOffsetDataOfChildren(ps, containerPointer)
            removePresetOffsetData(ps, componentPointer)
            if (ps.pointers.components.isPage(containerPointer)) {
                return
            }
            removeAllPresetDataRecursively(ps, componentPointer)
        },
        [hooks.HOOKS.REMOVE.BEFORE]: (ps, componentPointer, removeParent) => {
            if (removeParent || ps.pointers.components.isPage(componentPointer)) {
                return
            }
            if (ps.pointers.components.isMobile(componentPointer)) {
                if (shouldEnableImprovedMergeFlow(ps)) {
                    convertMobileComponentToMobileHints(ps, componentPointer)
                }
                return
            }
            const containerPointer = ps.pointers.components.getParent(componentPointer) || ps.pointers.full.components.getParent(componentPointer)
            removePresetOffsetDataOfChildren(ps, containerPointer)
        },
        [hooks.HOOKS.LAYOUT.UPDATE_AFTER]: (ps, componentPointer, newLayoutData, _updateCompLayoutCallbackForHooks, _isTriggeredByHook, previousLayout) => {
            if (shouldEnableNewMergeFlow(ps)) {
                if (ps.pointers.components.isMobile(componentPointer)) {
                    if (shouldEnableImprovedMergeFlow(ps)) {
                        const parent = ps.pointers.components.getParent(componentPointer)
                        convertMobileComponentToMobileHints(ps, parent)
                    }

                    return
                }
                const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)

                if (shouldEnableImprovedMergeFlow(ps) && hasGeneralData(mobileHintsItem)) {
                    const parent = ps.pointers.components.getParent(componentPointer)
                    /** To change order of components on mobile when it was changed in zoom out mode on desktop */
                    if (ps.pointers.components.isPage(parent)) {
                        mobileHints.markComponentAsDirtyForForceReRender(ps, componentPointer)
                    }
                    return
                }
                if (shouldMarkAsDirty(newLayoutData, previousLayout)) {
                    mobileHints.markComponentAsDirtyForForceReRender(ps, componentPointer)
                }
                if (!isMobileHintsPreset(mobileHintsItem)) {
                    return
                }
                const parentPointer = ps.pointers.components.getParent(componentPointer)
                const parentMobileHintsItem = dataModel.getMobileHintsItem(ps, parentPointer)
                removeGeneralPresetDataIfNeeded(ps, parentPointer, parentMobileHintsItem)
                removePresetOffsetDataIfNeeded(ps, componentPointer, newLayoutData, previousLayout, mobileHintsItem)
                removePresetSizeIfNeeded(ps, componentPointer, newLayoutData, previousLayout, mobileHintsItem)
            }
        }
    }

    const initialize = () => {
        hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, handleMobileStructure)

        _.forEach(mobileHintsChangeHooks, (cb, hookName) => hooks.registerHook(hookName, cb))
        hooks.registerHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, (ps, updatedRoots, commitConversionResults) => {
            if (!commitConversionResults) {
                return
            }
            _.forEach(updatedRoots, rootPointer => removeAutomaticPresets(ps, ps.pointers.components.getPage(rootPointer.id, constants.VIEW_MODES.DESKTOP)))
        })
    }

    return {initialize}
})
