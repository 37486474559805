define([
    'lodash',
    'experiment',
    'documentServices/wixCode/utils/utils',
    'documentServices/wixCode/utils/constants',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/tpa/utils/provisionUtils',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/services/wixCodeServiceFacade',
    'documentServices/wixCode/services/writeableAppService',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/hooks/hooks'
], function (
    _,
    experiment,
    wixCodeUtils,
    constants,
    clientSpecMap,
    clientSpecMapService,
    clientSpecMapUtils,
    tpaProvisionUtils,
    kibana,
    wixCodeServiceFacade,
    writeableAppService,
    extensionsAPI,
    hooks
) {
    'use strict'

    const WIX_CODE_APP_STORE_ENDPOINT = '/appStore/wix-code/'
    const WIX_CODE_SAVE_ENDPOINT = 'save'
    const WIX_CODE_NOT_PROVISION_KEY = 'wixCodeNotProvisioned'

    function _registerApp(ps, createAppResult) {
        const csm = clientSpecMap.getAppsData(ps)
        const currentLargestId = clientSpecMapService.getLargestApplicationId(csm)
        const appDefinition = {
            type: constants.WIX_CODE_SPEC_TYPE,
            appDefinitionId: createAppResult.appDefinitionId,
            instance: createAppResult.dsi,
            instanceId: createAppResult.instanceId,
            applicationId: tpaProvisionUtils.generateAppFlowsLargestAppId(currentLargestId)
        }
        appDefinition[WIX_CODE_NOT_PROVISION_KEY] = true

        clientSpecMap.registerAppData(ps, appDefinition)

        return appDefinition
    }

    function _updateWixCodeModel(ps, newAppResult) {
        const {gridAppId} = newAppResult
        if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
            ps.dal.set(ps.pointers.wixCode.getOpenWixCodeAppId(), gridAppId)
        } else {
            ps.dal.set(ps.pointers.wixCode.getRevisionGridAppId(), gridAppId)
        }
        hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION)
        return newAppResult
    }

    const getAppStoreBaseUrlFromImmutable = snapshot =>
        wixCodeUtils.extractFromSnapshot(snapshot, ['serviceTopology', 'appStoreUrl']) + WIX_CODE_APP_STORE_ENDPOINT

    const getAppStoreBaseUrlFromSnapshotDal = snapshotDal =>
        snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'}).appStoreUrl + WIX_CODE_APP_STORE_ENDPOINT

    async function _doProvision(ps, existingWixCodeApp) {
        const options = {
            baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
            metasiteId: getMetaSiteIdFromPS(ps),
            signedInstance: existingWixCodeApp ? existingWixCodeApp.instance : null,
            branchId: extensionsAPI.documentServicesModel.getBranchId(ps)
        }

        const newAppResult = await wixCodeServiceFacade.create(options)
        _updateWixCodeModel(ps, newAppResult)
        writeableAppService.setAppWriteable(ps)
        return existingWixCodeApp ? existingWixCodeApp : _registerApp(ps, newAppResult)
    }

    async function provision(ps) {
        const traceEnd = kibana.trace(ps, {action: 'createApp'})
        const existingWixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)

        if (isProvisioned(ps)) {
            traceEnd({message: 'wix code is already provisioned'})
            return existingWixCodeApp
        }

        try {
            const result = await _doProvision(ps, existingWixCodeApp)
            traceEnd({message: result})
            return result
        } catch (error) {
            traceEnd({message: error, level: kibana.levels.ERROR})
            throw error
        }
    }

    function getMetaSiteIdFromPS(ps) {
        const pointer = ps.pointers.general.getMetaSiteId()
        return ps.dal.get(pointer)
    }

    const getMetaSiteIdFromImmutable = snapshot => wixCodeUtils.extractFromSnapshot(snapshot, ['rendererModel', 'metaSiteId'])

    const getMetaSiteIdFromSnapshotDal = snapshotDal => snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'})

    function getClientSpecMap(snapshot) {
        return wixCodeUtils.extractFromSnapshot(snapshot, ['rendererModel', 'clientSpecMap'], true)
    }

    const toRequestForm = (gridAppId, appData) => ({
        applicationId: appData.applicationId.toString(),
        instanceId: appData.instanceId,
        signedInstance: appData.instance,
        gridAppId
    })

    /**
     * @param snapshot
     * @param resolve
     * @param reject
     * @param snapshotDal
     */
    function save(snapshot, resolve, reject, snapshotDal) {
        const appStoreBaseUrl = snapshotDal ? getAppStoreBaseUrlFromSnapshotDal(snapshotDal) : getAppStoreBaseUrlFromImmutable(snapshot)
        const url = appStoreBaseUrl + WIX_CODE_SAVE_ENDPOINT

        let gridAppId
        if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
            gridAppId = snapshotDal
                ? wixCodeUtils.extractFromSnapshotDal(snapshotDal, constants.paths.OPEN_WIX_CODE_APP_ID)
                : wixCodeUtils.extractFromSnapshot(snapshot, constants.paths.OPEN_WIX_CODE_APP_ID)
        } else {
            gridAppId = snapshotDal
                ? wixCodeUtils.extractFromSnapshotDal(snapshotDal, constants.paths.REVISION_GRID_APP_ID)
                : wixCodeUtils.extractFromSnapshot(snapshot, constants.paths.REVISION_GRID_APP_ID)
        }

        const metaSiteId = snapshotDal ? getMetaSiteIdFromSnapshotDal(snapshotDal) : getMetaSiteIdFromImmutable(snapshot)

        const wixCodeApps = (snapshotDal ? _(snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})) : _(snapshot).thru(getClientSpecMap))
            .filter(WIX_CODE_NOT_PROVISION_KEY)
            .map(toRequestForm.bind(null, gridAppId))
            .value()

        if (wixCodeApps.length > 0) {
            const body = {
                metaSiteId,
                codeAppSpec: wixCodeApps[0]
            }

            wixCodeUtils.sendRequest(url, wixCodeUtils.requestTypes.POST, body).then(onSaveSuccess, onSaveError) // eslint-disable-line promise/prefer-await-to-then
        } else {
            resolve()
        }

        function toResponseWithClientSpecMap(response) {
            if (!response.success) {
                // return the response as is in case of an error that was reported
                // with status code 200, for example a concurrency error
                return response
            }

            const partialClientSpecMap = {}
            partialClientSpecMap[response.payload.applicationId] = response.payload
            return _.assign({}, response, {
                payload: {
                    clientSpecMap: partialClientSpecMap
                }
            })
        }

        function onSaveSuccess(serverResponse) {
            resolve(toResponseWithClientSpecMap(serverResponse))
        }

        function onSaveError(error) {
            reject(error)
        }
    }

    function isProvisioned(ps) {
        return _hasWixCodeSpec(ps) && _hasGridApp(ps)
    }

    function _hasWixCodeSpec(ps) {
        return !!clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    }

    function _hasGridApp(ps) {
        return !!extensionsAPI.wixCode.getEditedGridAppId(ps)
    }

    return {
        provision,
        isProvisioned,
        save,
        ensureAppIsWriteable: writeableAppService.ensureAppIsWriteable,
        handleAppIsReadOnlyServerError: writeableAppService.handleAppIsReadOnlyServerError
    }
})
