import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import tpaCompBaseMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaCompBaseMixin'
import tpaUrlBuilderMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaCompApiMixin'
import tpaWidgetMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaWidgetMixin'
import tpaResizeWindowMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaResizeWindowMixin'

/* eslint-enable */

const {compRegistrar} = componentsCore

/**
 * @class components.TPAPreloader
 * @extends {componentsCore.skinBasedComp}
 * @extends {tpa.mixins.tpaCompBase}
 */
const tpaGluedWidget = {
    displayName: 'TPAGluedWidget',
    statics: {
        behaviors: {
            notifyWidget: {methodName: 'notifyWidgetInternal', params: ['action']}
        }
    },
    mixins: [
        componentsCore.mixins.skinBasedComp,
        coreUtilsLib.timersMixins.timeoutsMixin,
        tpaCompBaseMixin,
        tpaUrlBuilderMixin,
        tpaCompApiMixin,
        tpaWidgetMixin,
        tpaResizeWindowMixin
    ],
    isZeroHeightAllowed() {
        return true
    },
    mutateIframeUrlQueryParam(queryParamsObj) {
        queryParamsObj.width = this.state.initialWidth

        return queryParamsObj
    },
    mutateSkinProperties(skinProps) {
        return _.merge(skinProps, {
            '': {
                'data-has-ads': this.props.shouldShowWixAds,
                'data-horizontal-margin': this.props.compProp.horizontalMargin,
                'data-vertical-margin': this.props.compProp.verticalMargin,
                'data-placement': this.props.compProp.placement
            }
        })
    },
    notifyWidgetInternal(actionName) {
        this.sendPostMessage({
            intent: 'addEventListener',
            eventType: 'QUICK_ACTION_TRIGGERED',
            params: {
                quickAction: actionName
            }
        })
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAGluedWidget', tpaGluedWidget)
export default tpaGluedWidget
