define(['@wix/santa-core-utils', 'lodash', '@wix/document-services-json-schemas'], function (santaCoreUtils, _, jsonSchemas) {
    'use strict'
    const {
        namespaceMapping: {NAMESPACE_MAPPING}
    } = jsonSchemas

    function generateItemIdWithPrefix(prefix) {
        return santaCoreUtils.guidUtils.getUniqueId(prefix, '-', {bucket: `generateItemIdWithPrefix_${prefix}`})
    }

    /**
     * Generates a new data Item ID.
     * usually the path to the page to add the data to.
     */
    function generateNewDataItemId() {
        return generateItemIdWithPrefix('dataItem')
    }

    /**
     * Generates a new property Item ID.
     * usually the path to the page to add the data to.
     */
    function generateNewPropertiesItemId() {
        return generateItemIdWithPrefix('propItem')
    }

    const DEFAULT_DATA_PREFIX = _.mapValues(NAMESPACE_MAPPING, (v, k) => k)

    /**
     * return the function to generate a new id
     *
     * @param {string} idType (data || props || style)
     */
    function generateNewId(idType) {
        switch (idType) {
            case 'data':
                return generateNewDataItemId()
            case 'props':
            case 'properties':
                return generateNewPropertiesItemId()
            case 'design':
                return generateNewDataItemId()
            case 'behaviors':
                return generateItemIdWithPrefix('behavior')
            case 'connections':
                return generateItemIdWithPrefix('connection')
        }

        //general/default case:
        if (DEFAULT_DATA_PREFIX[idType]) {
            return generateItemIdWithPrefix(idType)
        }
        return null
    }

    /**
     * Generates a new inner design ID.
     * used to compare two design data objects
     */
    function generateNewDesignId() {
        return generateItemIdWithPrefix('design')
    }

    return {
        generateNewId,
        generateNewDataItemId,
        generateNewPropertiesItemId,
        generateNewDesignId
    }
})
