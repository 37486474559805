define(['@wix/snapify'], function (snapify) {
    'use strict'

    function getSelectorOfComp(compRef) {
        return `#${compRef.id}`
    }

    function isHTMLElement(element) {
        return element instanceof HTMLElement
    }

    /**
     * @param {ps} privateServices
     * @param {Pointer} compRef
     * @returns string
     */
    function getSnapshot(privateServices, compRef) {
        const selector = getSelectorOfComp(compRef)
        const element = document.querySelector(selector)

        if (!element || !isHTMLElement(element)) {
            throw new Error(`Couldn't find element for selector: '${selector}' of comp id: '${compRef.id}'`)
        }

        return snapify.createSnapshotString(element)
    }

    return {
        getSnapshot
    }
})
