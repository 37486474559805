define(['lodash', 'immutable', 'documentServices/jsonConfig/jsonConfig', 'documentServices/errors/errors', 'documentServices/wixCode/utils/constants'], (
    _,
    Immutable,
    jsonConfig,
    errorConstants,
    wixCodeConstants
) => {
    const ERROR_CODE_MAPPING = {
        '-12': errorConstants.save.SESSION_EXPIRED,
        '-15': errorConstants.save.NOT_LOGGED_IN,
        '-17': errorConstants.save.USER_NOT_AUTHORIZED_FOR_SITE,
        '-10163': errorConstants.save.PARTIAL_SAVE_THROTTLE,
        '-10156': errorConstants.save.SITE_DELETED,
        '-10134': errorConstants.save.FAILED_TO_PARSE_REVIEW_TOKEN,
        '-10135': errorConstants.save.REVIEW_TOKEN_EXPIRED,
        '-10116': errorConstants.save.CONCURRENT_SAVE,
        '-40003': errorConstants.save.SITE_NAME_TAKEN,
        '-10132': errorConstants.save.SAVE_PUBLISH_DISABLED_ON_SERVER,
        '-10154': errorConstants.save.SAVE_PUBLISH_RC_FAILED_ON_SERVER,
        '-10157': errorConstants.save.USER_BLOCKED_FOR_PUBLISH,
        '-10160': errorConstants.save.OUTDATED_LAST_TX_ID,
        '-10104'(response) {
            const {payload} = response
            if (!_.isEmpty(payload.duplicateComponents)) {
                return errorConstants.save.DUPLICATE_COMPONENTS
            }
            if (!_.isEmpty(payload.dataReferenceMismatches)) {
                return errorConstants.save.DATA_REFERENCE_MISMATCH
            }
            if (!_.isEmpty(payload.missingContainers)) {
                return errorConstants.save.MISSING_CONTAINERS
            }
            if (!_.isEmpty(payload.appControllerReferenceMismatches)) {
                return errorConstants.save.APP_CONTROLLER_REFERENCE_MISMATCH
            }
            if (!_.isEmpty(payload.connectionListReferenceMismatches)) {
                return errorConstants.save.CONNECTION_LIST_REFERENCE_MISMATCHES
            }
            if (!_.isEmpty(payload.styleReferenceMismatches)) {
                return errorConstants.save.STYLE_REFERENCE_MISMATCHES
            }
            if (!_.isEmpty(payload.behaviorReferenceMismatches)) {
                return errorConstants.save.BEHAVIOR_REFERENCE_MISMATHCHES
            }
            if (!_.isEmpty(payload.propertyReferenceMismatches)) {
                return errorConstants.save.PROPERTY_REFERENCE_MISMATCHES
            }
            if (!_.isEmpty(payload.designReferenceMismatches)) {
                return errorConstants.save.DESIGN_REFERENCE_MISMATCHES
            }

            /**
             * @param {{name: string}} errorItem
             * @returns {string|null}
             */
            const handleFatalErrors = ({name}) => {
                switch (name) {
                    case 'MissingReference':
                        return errorConstants.save.MISSING_REFERENCE
                    default:
                        return null
                }
            }

            try {
                if (!_.isEmpty(payload.fatalErrorsNamesAndCount)) {
                    const count = payload.fatalErrorsNamesAndCount.length
                    let index
                    for (index = 0; index < count; index++) {
                        const error = handleFatalErrors(payload.fatalErrorsNamesAndCount[index])
                        if (error) {
                            return error
                        }
                    }
                }
                // failing silently to unknown fallback as it was before
                // eslint-disable-next-line no-empty
            } catch (e) {}
        },
        '-10145': errorConstants.save.CONCURRENT_AUTO_SAVE,
        '-10148': errorConstants.save.SITE_STALE_STATE_FROM_AUTO_SAVE,
        '-10124': errorConstants.save.SITE_DESERIALIZATION_ERROR,
        '-41231': errorConstants.save.TOO_MANY_SITES_FOR_FREE_USER,
        '-10933': errorConstants.save.RC_EXISTS,
        '-10934': errorConstants.save.RC_ROLLED_OUT
    }

    const VALIDATION_ERROR_TYPES = [
        errorConstants.save.DUPLICATE_COMPONENTS,
        errorConstants.save.DATA_REFERENCE_MISMATCH,
        errorConstants.save.MISSING_CONTAINERS,
        errorConstants.save.APP_CONTROLLER_REFERENCE_MISMATCH,
        errorConstants.save.DESIGN_REFERENCE_MISMATCHES,
        errorConstants.save.BEHAVIOR_REFERENCE_MISMATHCHES,
        errorConstants.save.PROPERTY_REFERENCE_MISMATCHES,
        errorConstants.save.STYLE_REFERENCE_MISMATCHES,
        errorConstants.save.CONNECTION_LIST_REFERENCE_MISMATCHES,
        errorConstants.save.MISSING_REFERENCE
    ]

    const REST_TO_DS_ERROR_CODE_MAPPING = {
        RC_EXISTS: '-10933',
        RC_ROLLED_OUT: '-10934'
    }

    const REST_TO_DS_ERROR_MAPPING = {
        RC_EXISTS: errorConstants.save.RC_EXISTS,
        RC_ROLLED_OUT: errorConstants.save.RC_ROLLED_OUT
    }

    function getErrorType(response) {
        let errorType = ERROR_CODE_MAPPING[response.errorCode]
        if (_.isFunction(errorType)) {
            errorType = errorType(response)
        }
        return errorType || errorConstants.save.UNKNOWN_SERVER_ERROR
    }

    function isValidationError(response) {
        return _.includes(VALIDATION_ERROR_TYPES, getErrorType(response))
    }

    function getDataInPath(pathArr, immutableSnapshot, prefix) {
        let data = {}
        prefix = prefix || []

        if (pathArr.length === 1) {
            data[pathArr[0]] = immutableSnapshot.getIn(prefix.concat(pathArr[0]))
        } else {
            prefix = prefix.concat(pathArr.splice(0, 1))
            data[_.last(prefix)] = getDataInPath(pathArr, immutableSnapshot, prefix)
        }

        data = _.omitBy(data, _.isUndefined)

        return data
    }

    function getOnlySaveableData(immutableSnapshot) {
        const autosavePaths = jsonConfig.getAutosavePaths()

        let dataToSave = Immutable.fromJS({})

        _.forEach(autosavePaths, function (path) {
            const pathArr = path.split('.')
            const dataItem = getDataInPath(pathArr, immutableSnapshot)
            dataToSave = dataToSave.mergeDeep(dataItem)
        })

        return dataToSave
    }

    function createDocumentForFirstSave(fullSaveDTO) {
        const masterPageStructure = fullSaveDTO.masterPage
        const pagesContainer = _.find(masterPageStructure.children, {componentType: 'wysiwyg.viewer.components.PagesContainer'})
        const pageGroup = pagesContainer.components[0]
        pageGroup.components = fullSaveDTO.updatedPages

        return _.merge(
            fullSaveDTO.masterPage,
            {componentProperties: fullSaveDTO.dataDelta.component_properties},
            {themeData: fullSaveDTO.dataDelta.theme_data},
            {mobileHintsQuery: masterPageStructure.mobileHintsQuery}
        )
    }

    /**
     * These two crappy params are here because the payload for first save is the legacy of the legacy.
     * These maps in first save all have different keys in the payload than in other requests.
     * For theme_data and component_properties, it is even worse, where these dont exist as normal in the payload,
     * and instead they exist under documents[0] (the masterpage) as themeData and componentProperties
     */
    const FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME = {
        document_data: 'dataNodes',
        layout_data: 'layoutDataNodes',
        anchors_data: 'anchorsInfo',
        breakpoints_data: 'breakpointsNodes',
        behaviors_data: 'behaviors',
        connections_data: 'connections',
        mobile_hints: 'mobileHints',
        design_data: 'designNodes'
    }
    const FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE = ['theme_data', 'component_properties']

    function getHistoryAlteringChanges(itemsToDelete) {
        const changes = []
        _.forEach(itemsToDelete, function (itemsMap, pageId) {
            _.forEach(itemsMap, function (deletedIds, dataType) {
                _.forEach(deletedIds, function (deletedItemId) {
                    changes.push({
                        path: ['pagesData', pageId, 'data', dataType, deletedItemId],
                        value: undefined
                    })
                })
            })
        })

        return changes
    }

    function createErrorObject(response) {
        return {
            errorCode: response.errorCode,
            errorType: getErrorType(response),
            errorDescription: response.errorDescription
        }
    }

    const createErrorObjectFromRestException = async error => {
        const errorDetails = await error.json()
        const code = _.get(errorDetails, 'details.applicationError.code')
        if (_.has(REST_TO_DS_ERROR_MAPPING, code)) {
            return {
                errorType: REST_TO_DS_ERROR_MAPPING[code],
                errorCode: REST_TO_DS_ERROR_CODE_MAPPING[code],
                errorDescription: _.get(errorDetails, 'details.applicationError.data')
            }
        }
        return createErrorObject(error)
    }

    function addWixCodeFirstSaveGridAppToResult(returnedPayload, result) {
        addWixCodeSavedGridAppToResult(returnedPayload, result)
        if (returnedPayload.openWixCodeAppId) {
            result.changes.push({
                path: wixCodeConstants.paths.OPEN_WIX_CODE_APP_ID,
                value: returnedPayload.openWixCodeAppId
            })
        }
    }

    function addWixCodeSavedGridAppToResult(returnedPayload, result) {
        if (returnedPayload.wixCodeModel?.appData?.codeAppId) {
            result.changes.push(
                {
                    path: wixCodeConstants.paths.REVISION_GRID_APP_ID,
                    value: returnedPayload.wixCodeModel.appData.codeAppId
                },
                {
                    path: wixCodeConstants.paths.IS_APP_READ_ONLY,
                    value: true
                }
            )
        }
    }

    function addDevSiteAppDefIdToResult(payload, result) {
        if (payload.devSiteAppDefId) {
            result.changes.push({
                path: ['devSiteAppDefId'],
                value: payload.devSiteAppDefId
            })
        }
    }

    return {
        ERROR_CODE_MAPPING,
        VALIDATION_ERROR_TYPES,
        getErrorType,
        isValidationError,
        getOnlySaveableData,
        FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME,
        FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE,
        createDocumentForFirstSave,
        getHistoryAlteringChanges,
        createErrorObject,
        addWixCodeFirstSaveGridAppToResult,
        addWixCodeSavedGridAppToResult,
        addDevSiteAppDefIdToResult,
        createErrorObjectFromRestException
    }
})
