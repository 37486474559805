import santaComponents from '@wix/santa-components'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import $ from 'zepto'
import svgShapesData from '../data/svgShapesData'
import ImageZoomDisplayer from './imageZoomDisplayer'

/**
 * The widthSpacer and heightSpacer are passed to the mediaZoomCalculations.getDesktopViewDimensions or
 * mediaZoomCalculations.getNonOptimizedViewDimensions function to calculate the max width/height we have
 * for our image
 * widthSpacer is basically the width of the navigation arrows
 * heightSpacer is used to add some padding to the top/bottom
 */

const desktopSpacers = {
    width: 240,
    height: 60
}

const nonDesktopSpacers = {
    width: 0,
    height: 0
}

function isClickedNotLink(event) {
    return event && !event.target.href
}

function isMobileOrTablet(props) {
    return props.isMobileDevice || props.isTabletDevice()
}

/**
 * @class components.MediaZoom
 * @extends {core.skinBasedComp}
 * @extends {core.animationsMixin}
 * @extends {components.mediaZoomMixin}
 */
const mediaZoom = {
    displayName: 'MediaZoom',
    mixins: [
        componentsCore.mixins.skinBasedComp,
        santaComponents.mixins.animationsMixin,
        componentsCore.mixins.skinInfo,
        componentsCore.mixins.galleryImageExpandedActionMixin,
        componentsCore.mixins.cyclicTabbingMixin
    ],
    propTypes: _.assign(
        {
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            isZoomAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isZoomAllowed.isRequired,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
            passClickEvent: santaComponents.santaTypesDefinitions.passClickEvent.isRequired,
            exitFullScreenMode: santaComponents.santaTypesDefinitions.exitFullScreenMode.isRequired,
            enterFullScreenMode: santaComponents.santaTypesDefinitions.enterFullScreenMode.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            isFirstRenderAfterSSR: santaComponents.santaTypesDefinitions.isFirstRenderAfterSSR.isRequired,
            getPrevAndNextStateFunc: PropTypes.func,
            isDataChangedFunc: PropTypes.func,
            getChildCompFunc: PropTypes.func,
            getBoxDimensionsFunc: PropTypes.func,
            actualNavigateToItemFunc: PropTypes.func,
            closeFunction: PropTypes.func,
            dataChanged: PropTypes.bool
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(ImageZoomDisplayer)
    ),
    getSvgButton(svgShapeName, extraProps) {
        const svgShape = svgShapesData[svgShapeName]
        const svgProps = _.clone(svgShape.svg)
        const classNamePrefix = `${this.props.styleId}_`
        _.assign(
            svgProps,
            {
                className: classNamePrefix + svgShape.svg.className,
                dangerouslySetInnerHTML: {__html: svgShape.content},
                tabIndex: 0,
                role: 'button',
                onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
            },
            extraProps
        )

        return santaComponents.utils.createReactElement('svg', svgProps)
    },

    getInitialState() {
        this.isAnimating = false
        this.shouldUpdateSizeOnLayout = true
        // identifying device for appPart zoom on non-optimised sites
        let deviceType = 'desktop'
        if (this.props.isMobileDevice) {
            deviceType = 'mobile'
        } else if (this.props.isTabletDevice()) {
            deviceType = 'tablet'
        }

        return _.assign(
            {
                $buttonsState: 'showButtons',
                $device: deviceType
            },
            this.props.getPrevAndNextStateFunc()
        )
    },

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isZoomAllowed) {
            setTimeout(this.closeMediaZoom, 0)
            return
        }

        const isDataChanged = this.props.dataChanged || this.props.isDataChangedFunc(this.props, nextProps)
        this.shouldUpdateSizeOnLayout = isDataChanged && !this.props.isFirstRenderAfterSSR
        if (isDataChanged) {
            this.setState(this.props.getPrevAndNextStateFunc())
        }
    },

    getSkinProperties() {
        const isWithMultipleItems = !!this.state.next
        const buttonsStyle = isWithMultipleItems ? {} : {display: 'none'}
        const spacersToUse = isMobileOrTablet(this.props) ? nonDesktopSpacers : desktopSpacers

        const childComp = this.props.getChildCompFunc(
            {
                toggleButtons: this.toggleButtons,
                goToNextItem: this.clickOnNextButton,
                goToPrevItem: this.clickOnPreviousButton
            },
            spacersToUse
        )

        const refs = {
            '': {
                onKeyDown: this.preventTabbingOut,
                'data-width-spacer': spacersToUse.width,
                'data-height-spacer': spacersToUse.height
                // tabIndex: -1
            },
            blockingLayer: {
                onClick: this.onBlockingLayerClick
            },
            xButton: {
                onClick: this.closeMediaZoom,
                children: [this.getSvgButton('buttonClose', {'aria-label': 'close'})]
            },
            dialogBox: {
                onClick: this.handleDialogBoxClick
            },
            itemsContainer: {
                children: childComp,
                'aria-live': 'polite',
                tabIndex: -1
            },
            buttonPrev: {
                onClick: this.clickOnPreviousButton,
                style: buttonsStyle,
                children: [this.getSvgButton('buttonPrevious', {'aria-label': 'previous'})]
            },
            buttonNext: {
                onClick: this.clickOnNextButton,
                style: buttonsStyle,
                children: [this.getSvgButton('buttonNext', {'aria-label': 'next'})]
            }
        }

        if (isMobileOrTablet(this.props)) {
            // @ts-ignore
            refs.blockingLayer.onSwipeLeft = this.clickOnNextButton
            // @ts-ignore
            refs.blockingLayer.onSwipeRight = this.clickOnPreviousButton
        }
        return refs
    },

    onBlockingLayerClick(event) {
        if (isClickedNotLink(event)) {
            this.closeMediaZoom()
            event.preventDefault()
            event.stopPropagation()
        }
    },

    componentDidLayout() {
        const dialogBoxSize = this.props.getBoxDimensionsFunc()

        if (!this.shouldUpdateSizeOnLayout) {
            const cssForZepto = {
                width: dialogBoxSize.dialogBoxWidth,
                height: dialogBoxSize.dialogBoxHeight,
                'margin-top': dialogBoxSize.marginTop,
                'margin-left': dialogBoxSize.marginLeft,
                padding: dialogBoxSize.padding
            }
            $(ReactDOM.findDOMNode(this.refs.dialogBox)).css(cssForZepto)
            return
        }
        this.shouldUpdateSizeOnLayout = false

        ReactDOM.findDOMNode(this.refs.itemsContainer).focus()

        const self = this

        if (!this.props.isFirstRenderAfterSSR) {
            const cssForReact = {
                width: dialogBoxSize.dialogBoxWidth,
                height: dialogBoxSize.dialogBoxHeight,
                marginTop: dialogBoxSize.marginTop,
                marginLeft: dialogBoxSize.marginLeft,
                padding: dialogBoxSize.padding
            }
            const sequence = this.sequence()
            sequence
                .add('dialogBox', 'BaseDimensions', 0.5, 0, {to: cssForReact})
                .add('itemsContainer', 'FadeIn', 0.5, 0)
                .onCompleteAll(function () {
                    self.unBlockNavigation()
                    self.handleImageExpandedAction()
                })
                .execute()
        }
    },

    clickOnNextButton(event) {
        this.navigateToOtherPageWithAnimations(this.state.next)
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
    },

    /**
     * Handle left arrow click
     */
    clickOnPreviousButton(event) {
        this.navigateToOtherPageWithAnimations(this.state.prev)
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
    },

    /**
     * Handles navigation click ( should not navigate if navigation is blocked )
     */
    navigateToOtherPageWithAnimations(itemId) {
        if (this.isNavigationBlocked()) {
            return
        }
        const self = this
        this.blockNavigation()
        this.animate('itemsContainer', 'FadeOut', 0.5, 0, null, {
            onComplete() {
                self.props.actualNavigateToItemFunc(itemId)
            }
        })
    },

    closeMediaZoom() {
        if (this.props.closeFunction) {
            this.props.closeFunction()
        } else {
            const navInfo = _.omit(this.props.rootNavigationInfo, ['imageZoom', 'pageItemId', 'title'])
            this.props.navigateToPage(navInfo)
        }
    },

    /**
     * Handles dialog box clicks
     * @returns {boolean}
     * @param event
     */
    handleDialogBoxClick(event) {
        // allow links to passthrough ( some media items come with links )
        if (isClickedNotLink(event)) {
            event.preventDefault()
            event.stopPropagation()
            this.props.passClickEvent(event)
        }
    },

    /**
     * Unblocks navigations ( when finished animating )
     */
    unBlockNavigation() {
        this.isAnimating = false
    },

    /**
     * Blocks navigation arrows when animating
     */
    blockNavigation() {
        this.isAnimating = true
    },

    /**
     * Checks if navigation is blocked
     */
    isNavigationBlocked() {
        return this.isAnimating
    },

    componentWillMount() {
        // Disable site scrolling
        this.props.enterFullScreenMode()
    },

    componentDidMount() {
        this._focusedElementBeforeMediaZoom = window.document.activeElement
    },

    componentWillUnmount() {
        // Enable site scrolling
        this.props.exitFullScreenMode()
        $(this._focusedElementBeforeMediaZoom).focus()
    },

    toggleButtons(event) {
        const buttonsNewState = this.state.$buttonsState === 'showButtons' ? 'hideButtons' : 'showButtons'
        this.setState({$buttonsState: buttonsNewState})
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
    }
}

export default mediaZoom
