define(['lodash', 'documentServices/constants/constants', 'documentServices/dataModel/dataModel', 'documentServices/utils/utils'], function (
    _,
    constants,
    dataModel,
    dsUtils
) {
    'use strict'

    function moveDirections(isFullWidth) {
        const directions = [constants.MOVE_DIRECTIONS.VERTICAL]
        if (!isFullWidth) {
            directions.push(constants.MOVE_DIRECTIONS.HORIZONTAL)
        }
        return directions
    }

    function getResizableSides(ps, compPointer) {
        if (!isCompFullWidth(ps, compPointer)) {
            return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
        }
        return []
    }

    function moveDirectionsByPointer(ps, compPointer) {
        return moveDirections(isCompFullWidth(ps, compPointer))
    }

    function isCompFullWidth(ps, compPointer) {
        return !!dataModel.getPropertiesItem(ps, compPointer).fitToScreenWidth
    }

    function isCompFullWidthByStructure(ps, compStructure) {
        return !!compStructure.props.fitToScreenWidth
    }

    return {
        isRepeatable: false,
        mobileConversionConfig: {
            isScreenWidth(ps, component, pageId) {
                const props = ps.dal.get(ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(component.propertyQuery), pageId))
                return _.get(props, 'fitToScreenWidth', false)
            }
        },
        moveDirections: moveDirectionsByPointer,
        resizableSides: getResizableSides,
        fullWidth: isCompFullWidth,
        fullWidthByStructure: isCompFullWidthByStructure
    }
})
