import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

function shouldShowIndicator(urlParam?) {
    const url = _.defaultTo(urlParam, _.get(window, ['location', 'href']))
    const queryParameters: any = _.get(warmupUtilsLib.urlUtils.parseUrl(url), ['query'], {})

    const ssrIndicatorValue = _.defaultTo(queryParameters.ssrIndicator, queryParameters.ssrindicator)
    if (ssrIndicatorValue === 'true') {
        return true
    }

    if (ssrIndicatorValue === 'false') {
        return false
    }

    if (nonDevQueryParamIsTruthy(queryParameters)) {
        return false
    }

    return overriddenParameter(queryParameters) || devParameter(queryParameters)
}

function nonDevQueryParamIsTruthy(queryParameters) {
    return queryParameters.isEdited === 'true' || queryParameters.isqa === 'true'
}

function overriddenParameter(queryParameters) {
    return _(queryParameters)
        .keys()
        .some(k => k.includes('override'))
}

function devParameter(queryParameters) {
    const queryKeys = _.keys(queryParameters)

    // If one of these keys exists (regardless it's value) we should show
    // the indicator
    const relevantKeys = [
        'debug',
        'ssrIndicator',
        'ReactSource',
        'ssrWarmupOnly',
        'ssrDebug',
        'petri_ovr',
        'experiments',
        'experimentsoff',
        'viewerexp',
        'layoutMechanism',
        'WixCodeRuntimeSource',
        'viewerPlatformAppSources',
        'ssrPrewarmupOnly',
        'forceBolt'
    ]

    const indicatorKeys = _.clone(relevantKeys)
    // @ts-ignore
    _.forEach(relevantKeys, key => indicatorKeys.push(_.invoke(_.toLower(key))))

    return _.intersection(indicatorKeys, queryKeys).length > 0
}

export default shouldShowIndicator
