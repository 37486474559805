/**
 * Get a cookie by name
 * @param name
 * @returns {string}
 */
function getCookie(name) {
    if (typeof document !== 'undefined') {
        const re = new RegExp(`(?:^|\\;)\\s*${name}\\=([^;]+)`)
        const match = window.document.cookie.match(re)
        if (match) {
            return match[1]
        }
    }
}

export default {
    getCookie
}
