define([
    'lodash',
    'documentServices/errors/errors',
    'documentServices/bi/events',
    'documentServices/bi/errors',
    'documentServices/editorServerFacade/editorServerFacade',
    'documentServices/saveAPI/saveDataFixer/saveDataFixer',
    'documentServices/wixCode/utils/constants',
    'documentServices/hooks/hooks',
    '@wix/santa-core-utils',
    'documentServices/metaSiteProvisioner/semanticAppVersionsCleaner',
    'documentServices/constants/constants',
    'documentServices/tpa/services/appStoreService',
    'documentServices/tpa/utils/permissionsUtils',
    'documentServices/saveAPI/monitoring',
    '@wix/wix-immutable-proxy',
    'experiment',
    'documentServices/saveAPI/appServiceData',
    '@wix/document-services-json-schemas',
    'documentServices/saveAPI/cloneWithoutAdditionalProperties',
    'documentServices/saveAPI/extractDataDeltaFromSnapshotDiff',
    'documentServices/saveAPI/snapshotDalSaveUtils',
    'documentServices/saveAPI/saveTasks/saveDocumentBase',
    'documentServices/wixCode/services/getGridAppForRevision',
    '@wix/document-manager-utils',
    'documentServices/utils/contextAdapter'
], function (
    _,
    errorConstants,
    biEvents,
    biErrors,
    editorServerFacade,
    saveDataFixer,
    wixCodeConstants,
    hooks,
    santaCoreUtils,
    semanticAppVersionsCleaner,
    constants,
    appStoreService,
    permissionsUtils,
    monitoring,
    wixImmutableProxy,
    experiment,
    appServiceData,
    documentServicesJsonSchemas,
    cloneWithoutAdditionalProperties,
    extractDataDeltaFromSnapshotDiff,
    snapshotDalSaveUtils,
    saveDocumentBase,
    getGridAppForRevision,
    utils,
    contextAdapter
) {
    'use strict'
    const {ReportableError, asyncAttempt} = utils
    const TASK_NAME = 'saveDocument'
    const STRUCTURE_DATA_TYPES = _.values(constants.VIEW_MODES)
    const {PAGE_DATA_DATA_TYPES, MULTILINGUAL_TYPES, COMP_DATA_QUERY_KEYS} = constants
    const {deepClone} = wixImmutableProxy
    const {createFromSnapshotDiff} = appServiceData

    const {
        namespaceMapping: {NAMESPACE_MAPPING, OVERRIDE_NAMESPACES}
    } = documentServicesJsonSchemas

    const {shouldSaveDiff} = snapshotDalSaveUtils
    const pageDataTypeToKey = _.invert(NAMESPACE_MAPPING)
    const {
        isValidationError,
        createDocumentForFirstSave,
        FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME,
        FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE,
        getHistoryAlteringChanges,
        createErrorObject,
        addWixCodeFirstSaveGridAppToResult,
        addWixCodeSavedGridAppToResult,
        addDevSiteAppDefIdToResult,
        createErrorObjectFromRestException
    } = saveDocumentBase

    const getTranslationInfoFromKey = key => _.split(key, '^')

    const previousDiffIdPath = ['documentServicesModel', 'autoSaveInfo', 'previousDiffId']

    const changedPageTypes = [...STRUCTURE_DATA_TYPES, ..._.keys(PAGE_DATA_DATA_TYPES)]

    const isPageComponent = (type, comp) => type === 'DESKTOP' && comp.type === 'Page'

    const getChangedPagesFromSnapshotDal = (diff, lastSnapshotDal, currentSnapshotDal) => {
        const updatedPageIdSet = new Set()
        const deletedPageIdsSet = new Set()
        const addedPageIdsSet = new Set()

        for (const type of changedPageTypes) {
            for (const [id, comp] of Object.entries(diff[type] || {})) {
                const wasDeleted = comp === undefined
                if (wasDeleted) {
                    const prevComp = lastSnapshotDal.getValue({type, id})
                    const {pageId} = prevComp.metaData
                    if (isPageComponent(type, prevComp)) {
                        deletedPageIdsSet.add(pageId)
                    } else {
                        updatedPageIdSet.add(pageId)
                    }
                } else {
                    const wasAdded = !lastSnapshotDal || !lastSnapshotDal.exists({type, id})
                    const {pageId} = comp.metaData
                    if (wasAdded && isPageComponent(type, comp)) {
                        addedPageIdsSet.add(pageId)
                    } else {
                        updatedPageIdSet.add(pageId)
                    }
                }
            }
        }

        _.forEach(diff.multilingualTranslations, (value, key) => {
            const [pageId] = getTranslationInfoFromKey(key)
            updatedPageIdSet.add(pageId)
        })

        const updatedPageIds = [...updatedPageIdSet].filter(pageId => {
            if (pageId && !addedPageIdsSet.has(pageId) && !deletedPageIdsSet.has(pageId)) {
                // Filter out pages that were inserted because of garbage components from deleted pages (for example in the mobileHints namespace)
                return currentSnapshotDal.exists({type: 'DESKTOP', id: pageId})
            }
            return false
        })
        const deletedPageIds = _.compact([...deletedPageIdsSet])
        const addedPageIds = _.compact([...addedPageIdsSet])

        return {
            updatedPageIds,
            deletedPageIds,
            addedPageIds
        }
    }

    const cleanComponentMetaData = component => {
        const sig = _.get(component, ['metaData', 'sig'])
        if (sig) {
            component.metaData = {sig}
        } else {
            delete component.metaData
        }
    }

    const getComponentsRecursively = (snapshotDal, type, id) => {
        const component = deepClone(snapshotDal.getValue({type, id}))
        cleanComponentMetaData(component)
        delete component.parent
        const children = component.components
        if (children) {
            component.components = children.map(childId => getComponentsRecursively(snapshotDal, type, childId))
        }
        return component
    }

    const getPageStructure = (snapshotDal, pageId) => {
        const rootComponent = getComponentsRecursively(snapshotDal, 'DESKTOP', pageId)
        const mobilePageComp = _.clone(snapshotDal.getValue({type: 'MOBILE', id: pageId}))
        rootComponent.mobileComponents = mobilePageComp ? getComponentsRecursively(snapshotDal, 'MOBILE', pageId).components : []
        if (mobilePageComp) {
            cleanComponentMetaData(mobilePageComp)
            rootComponent.mobileMetaData = mobilePageComp.metaData
        }
        if (pageId === 'masterPage') {
            rootComponent.children = rootComponent.components
            delete rootComponent.components
        }
        return rootComponent
    }

    const masterPageProperties = ['children', 'mobileComponents', 'type', 'layout', 'modes', 'metaData', 'mobileMetaData', 'componentType', 'id'].concat(
        Object.values(COMP_DATA_QUERY_KEYS)
    )

    function extractUpdatedPagesSnapshotDal(lastSnapshotDal, currentSnapshotDal, diff) {
        const {updatedPageIds, deletedPageIds, addedPageIds} = getChangedPagesFromSnapshotDal(diff, lastSnapshotDal, currentSnapshotDal)
        const changedPageIds = _.concat(updatedPageIds, addedPageIds)
        const updatedPages = changedPageIds.filter(pageId => pageId !== 'masterPage').map(pageId => getPageStructure(currentSnapshotDal, pageId))

        const masterPage = _.includes(changedPageIds, 'masterPage')
            ? _.pick(getPageStructure(currentSnapshotDal, 'masterPage'), masterPageProperties)
            : undefined

        return {
            updatedPages,
            masterPage,
            deletedPageIds
        }
    }

    function getSiteMetaData(snapshotDal) {
        const siteMetaData = deepClone(snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData'}))
        const customHeadTags = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'customHeadTags'}))
        return _(siteMetaData)
            .omit(['adaptiveMobileOn'])
            .merge({headTags: customHeadTags || ''})
            .value()
    }

    function getSiteMetaDataWithoutAdditionalProperties(snapshot) {
        return cloneWithoutAdditionalProperties('siteMetaData', getSiteMetaData(snapshot))
    }

    function extractSiteMetaDataIfChanged(lastSnapshotDal, currentSnapshotDal) {
        const oldSiteMetaData = getSiteMetaData(lastSnapshotDal)
        const currentSiteMetaData = getSiteMetaData(currentSnapshotDal)
        if (!_.isEqual(oldSiteMetaData, currentSiteMetaData)) {
            return cloneWithoutAdditionalProperties('siteMetaData', currentSiteMetaData)
        }
    }

    function isAdaptiveMobileOn(snapshotDal) {
        return snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData', innerPath: 'adaptiveMobileOn'})
    }

    function getSiteName(snapshotDal) {
        return snapshotDal.getValue({type: 'documentServicesModel', id: 'siteName'})
    }

    function needToAddSiteName(currentSnapshotDal) {
        return currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})
    }

    function getMetaSiteData(snapshotDal) {
        const metaSiteData = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'metaSiteData'}))
        metaSiteData.adaptiveMobileOn = isAdaptiveMobileOn(snapshotDal)
        if (needToAddSiteName(snapshotDal)) {
            metaSiteData.siteName = getSiteName(snapshotDal)
        }
        return cloneWithoutAdditionalProperties('metaSiteData', metaSiteData)
    }

    function extractMetaSiteDataIfChanged(lastSnapshotDal, currentSnapshotDal, diff) {
        const {documentServicesModel} = diff
        const metaSiteDataChanged = documentServicesModel && documentServicesModel.hasOwnProperty('metaSiteData')
        const isAdaptiveMobileChanged = isAdaptiveMobileOn(lastSnapshotDal) !== isAdaptiveMobileOn(currentSnapshotDal)
        const needToSendSiteName = needToAddSiteName(currentSnapshotDal)
        if (metaSiteDataChanged || isAdaptiveMobileChanged || needToSendSiteName) {
            return getMetaSiteData(currentSnapshotDal)
        }
    }

    function getProtectedPagesData(lastSnapshotDal, diff) {
        const rendererModelDiff = diff.rendererModel
        const currentPageToHashedPasswordMap = rendererModelDiff && deepClone(_.get(rendererModelDiff, ['pageToHashedPassword', 'pages']))
        if (currentPageToHashedPasswordMap) {
            const lastPageToHashedPasswordMap =
                lastSnapshotDal && lastSnapshotDal.getValue({type: 'rendererModel', id: 'pageToHashedPassword', innerPath: ['pages']})
            if (lastPageToHashedPasswordMap) {
                return _.pickBy(currentPageToHashedPasswordMap, (newHash, pageId) => newHash !== lastPageToHashedPasswordMap[pageId])
            }
            return currentPageToHashedPasswordMap
        }
        return {}
    }

    // In case no changes - server expected to get undefined
    // In case of changes - server expect to get an object
    function getRouters(diff) {
        const rendererModelDiff = diff.rendererModel
        if (rendererModelDiff && rendererModelDiff.hasOwnProperty('routers')) {
            const {routers} = rendererModelDiff
            if (routers) {
                return cloneWithoutAdditionalProperties('routers', routers)
            }
            // in case of undo/redo or revision history
            return {}
        }
    }

    function getPlatformApplications(diff) {
        const platformApplicationsDiff = diff.pagesPlatformApplications
        if (platformApplicationsDiff) {
            return deepClone(platformApplicationsDiff.pagesPlatformApplications)
        }
        return undefined
    }

    function getPermanentDataNodesToDelete(snapshotDal) {
        return deepClone(snapshotDal.getValue({type: 'save', id: 'orphanPermanentDataNodes'})) || []
    }

    function getSiteId(snapshotDal) {
        return snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'})
    }

    function getRevision(snapshotDal) {
        return snapshotDal.getValue(snapshotDalSaveUtils.revisionPointer)
    }

    function getVersion(snapshotDal) {
        return snapshotDal.getValue(snapshotDalSaveUtils.versionPointer)
    }

    async function getWixCodeAppData(lastSnapshotDal, currentSnapshotDal, bi) {
        const gridAppIdForRevision = await getGridAppForRevision.runUsingSnapshotDal(currentSnapshotDal, bi)

        const [type, id, ...innerPath] = wixCodeConstants.paths.REVISION_GRID_APP_ID
        const previousRevisionGridAppId = lastSnapshotDal && lastSnapshotDal.getValue({type, id, innerPath})

        if (gridAppIdForRevision && previousRevisionGridAppId !== gridAppIdForRevision) {
            return {
                codeAppId: gridAppIdForRevision
            }
        }
    }

    const getTranslationsDeltaFromDiff = diff => {
        const translationsDelta = {}
        /**
         * ts expression: {[lang: string]: string[]}
         * @type {Object.<string, string[]>}
         * @example
         * {
         *   "lang-0": [
         *     "item-0",
         *     "item-1"
         *   ],
         *   "lang-1": [
         *     "item-0",
         *     "item-1"
         *   ]
         * }
         */
        const deletedTranslations = {}
        const changedTranslationPageIds = new Set()
        _.forEach(diff.multilingualTranslations, (value, key) => {
            const [pageId, languageCode, id] = getTranslationInfoFromKey(key)

            if (value === undefined) {
                // removed translations
                const translationDataItemsToRemove = _.get(deletedTranslations, [languageCode], []).concat(id)
                _.setWith(deletedTranslations, [languageCode], translationDataItemsToRemove, Object)
                return
            }

            _.setWith(translationsDelta, [languageCode, 'data', 'document_data', id], cloneWithoutAdditionalProperties(id, value), Object)
            changedTranslationPageIds.add(pageId)

            // The translation of page is showing in both the masterPage and actual page.
            // Need to make sure the server loads both, otherwise there is a mismatch
            if (value.type === 'Page') {
                changedTranslationPageIds.add(id)
                changedTranslationPageIds.add('masterPage')
            }
        })
        return {
            translationsDelta: _.isEmpty(translationsDelta) ? undefined : translationsDelta,
            deletedTranslations,
            changedTranslationPageIds: Array.from(changedTranslationPageIds)
        }
    }

    /**
     * @param lastSnapshotDal
     * @param currentSnapshotDal
     * @param diff
     * @param extensionsAPI
     * @param bi
     * @returns {Promise<{dataNodeIdsToDelete: *, branchId: *, lastTransactionId: string, initiator: string, wixCodeAppData: ({codeAppId: *}|undefined), pagesPlatformApplications: *, id, protectedPagesData: (*|{}), version, routers: (*|{}), signatures: *, revision}>}
     */
    const createBaseDataToSave = async (lastSnapshotDal, currentSnapshotDal, diff, extensionsAPI, bi) => {
        const dataToSave = {
            lastTransactionId: currentSnapshotDal.lastTransactionId,
            protectedPagesData: getProtectedPagesData(lastSnapshotDal, diff),
            dataNodeIdsToDelete: getPermanentDataNodesToDelete(currentSnapshotDal),
            id: getSiteId(currentSnapshotDal),
            revision: getRevision(currentSnapshotDal),
            version: getVersion(currentSnapshotDal),
            routers: getRouters(diff),
            initiator: getInitiator(currentSnapshotDal),
            wixCodeAppData: await getWixCodeAppData(lastSnapshotDal, currentSnapshotDal, bi),
            pagesPlatformApplications: getPlatformApplications(diff),
            branchId: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'}),
            signatures: getSignaturesMap(diff)
        }
        if (extensionsAPI.csave.isCEditOpen()) {
            dataToSave.cedit = true
        }
        return dataToSave
    }

    const getSignaturesMap = diff =>
        _.pickBy({
            'rendererModel.routers': _.get(diff, ['rendererModel', 'routers', 'metaData', 'sig']),
            'rendererModel.siteMetaData': _.get(diff, ['rendererModel', 'siteMetaData', 'metaData', 'sig']),
            'rendererModel.wixCodeModel': _.get(diff, ['rendererModel', 'wixCodeModel', 'metaData', 'sig']),
            'documentServicesModel.metaSiteData': _.get(diff, ['documentServicesModel', 'metaSiteData', 'metaData', 'sig'])
        })

    const getSignaturesMapFromSnapshotDal = snapshotDal =>
        _.pickBy({
            'rendererModel.routers': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'routers'}), ['metaData', 'sig']),
            'rendererModel.siteMetaData': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData'}), ['metaData', 'sig']),
            'rendererModel.wixCodeModel': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'wixCodeModel'}), ['metaData', 'sig']),
            'documentServicesModel.metaSiteData': _.get(snapshotDal.getValue({type: 'documentServicesModel', id: 'metaSiteData'}), ['metaData', 'sig'])
        })

    const reportIgnoredDeletions = (isFull, ignoredDeletions) => {
        if (ignoredDeletions.length < 1) {
            return
        }
        const saveName = isFull ? 'fullSave' : 'partialSave'
        const message = `ignoredDeletions from ${saveName}`
        const err = new ReportableError({
            message,
            errorType: 'ignoredDeletions',
            tags: {
                saveName
            },
            extras: {
                ignoredDeletions
            }
        })
        contextAdapter.utils.fedopsLogger.captureError(err)
    }

    /**
     * @typedef {object} PartialSaveOptions
     * @property {boolean} [settleInServer]
     * @property {string} [viewerName]
     * @property {string} [initiatorOrigin]
     * @property {string} [editorOrigin]
     */

    /**
     * @param bi
     * @param {PartialSaveOptions} o
     * @param lastSnapshotDal
     * @param currentSnapshotDal
     * @param boundExtensionsAPI
     * @returns {Promise<{}>}
     */
    const createPartialDataToSave = async (
        bi,
        {settleInServer, viewerName, initiatorOrigin} = {settleInServer: undefined, viewerName: undefined, initiatorOrigin: ''},
        lastSnapshotDal,
        currentSnapshotDal,
        boundExtensionsAPI
    ) => {
        const diff = currentSnapshotDal.diff(lastSnapshotDal)

        const {changedData, deletedData, deletedDataForSave, changedDataPageIds, deletedDataPageIds, deletedDataPageIdsForSave, ignoredDeletions} =
            extractDataDeltaFromSnapshotDiff(diff, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI)
        reportIgnoredDeletions(false, ignoredDeletions)

        const {updatedPages, masterPage, deletedPageIds} = extractUpdatedPagesSnapshotDal(lastSnapshotDal, currentSnapshotDal, diff)

        const {deletedTranslations, translationsDelta, changedTranslationPageIds} = getTranslationsDeltaFromDiff(diff)
        const pageIdsWithChangedData = _(changedDataPageIds).concat(deletedDataPageIdsForSave).concat(changedTranslationPageIds).uniq().value()

        const nodeIdsToDelete = _.mapValues(deletedDataForSave, _.keys)
        const base = await createBaseDataToSave(lastSnapshotDal, currentSnapshotDal, diff, boundExtensionsAPI, bi)
        const dataToSave = {
            ...base,
            dataDelta: changedData,
            nodeIdsToDelete,
            deletedPageIds,
            masterPage,
            updatedPages,
            siteMetaData: extractSiteMetaDataIfChanged(lastSnapshotDal, currentSnapshotDal),
            metaSiteData: extractMetaSiteDataIfChanged(lastSnapshotDal, currentSnapshotDal, diff),
            initiatorOrigin: initiatorOrigin || '',
            translationsDelta,
            pageIdsWithChangedData,
            viewerName
        }

        if (!_.isEmpty(deletedTranslations)) {
            _.assign(dataToSave, {translationsToDelete: deletedTranslations})
        }

        if (settleInServer) {
            const isMasterPageUpdated = _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
            const appStoreServiceData = createFromSnapshotDiff(
                diff,
                lastSnapshotDal,
                currentSnapshotDal,
                changedData.document_data,
                deletedData.document_data,
                isMasterPageUpdated
            )
            const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})
            const actions = appStoreService.getSettleActionsForSave(appStoreServiceData, shouldAvoidRevoking)
            if (!_.isEmpty(actions)) {
                // @ts-ignore
                dataToSave.metaSiteActions = _.omitBy(
                    {
                        actions,
                        maybeBranchId: appStoreServiceData.branchId
                    },
                    _.isNil
                )
            }
        }

        saveDataFixer.fixData(dataToSave, {lastSnapshotDal, currentSnapshotDal, bi})

        return _.omitBy(dataToSave, value => _.isNil(value))
    }

    function getInitiator(currentSnapshotDal) {
        if (getAutosaveInfo(currentSnapshotDal, 'autoFullSaveFlag')) {
            return 'auto_save'
        } else if (getPublishSaveInitiator(currentSnapshotDal)) {
            return 'publish'
        } else if (getSilentSaveInitiator(currentSnapshotDal)) {
            return 'provision'
        }
        return 'manual'
    }

    const getAutosaveInfo = (snapshotDal, key) => snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: key})
    const getPublishSaveInitiator = snapshotDal => snapshotDal.getValue({type: 'save', id: 'publishSaveInitiator'})
    const getSilentSaveInitiator = snapshotDal => snapshotDal.getValue({type: 'save', id: 'silentSaveInitiator'})

    async function createFullDataToSave(currentSnapshotDal, options = {}, boundExtensionsAPI, bi) {
        const diff = currentSnapshotDal.toJS()
        const {changedData, deletedData, changedDataPageIds, deletedDataPageIds, ignoredDeletions} = extractDataDeltaFromSnapshotDiff(
            diff,
            null,
            currentSnapshotDal,
            boundExtensionsAPI
        )
        reportIgnoredDeletions(true, ignoredDeletions)
        const {deletedTranslations, translationsDelta} = getTranslationsDeltaFromDiff(diff)
        const {masterPage, updatedPages} = extractUpdatedPagesSnapshotDal(null, currentSnapshotDal, diff)
        const base = await createBaseDataToSave(null, currentSnapshotDal, diff, boundExtensionsAPI, bi)
        const dataToSave = {
            ...base,
            dataDelta: changedData,
            deletedPageIds: [],
            masterPage,
            updatedPages,
            siteMetaData: getSiteMetaDataWithoutAdditionalProperties(currentSnapshotDal),
            metaSiteData: getMetaSiteData(currentSnapshotDal),
            initiatorOrigin: _.get(options, 'initiatorOrigin', '')
        }
        if (!_.isEmpty(translationsDelta)) {
            _.assign(dataToSave, {translationsDelta})
        }
        if (!_.isEmpty(deletedTranslations)) {
            _.assign(dataToSave, {translationsToDelete: deletedTranslations})
        }

        saveDataFixer.fixData(dataToSave, {lastSnapshotDal: null, currentSnapshotDal})

        if (options.settleInServer) {
            const isMasterPageUpdated = _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
            const appStoreServiceData = createFromSnapshotDiff(
                diff,
                null,
                currentSnapshotDal,
                changedData.document_data,
                deletedData.document_data,
                isMasterPageUpdated
            )
            const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})
            // @ts-ignore
            dataToSave.metaSiteActions = _.omitBy(
                {
                    actions: appStoreService.getSettleActionsForFullSave(appStoreServiceData, shouldAvoidRevoking),
                    maybeBranchId: appStoreServiceData.branchId
                },
                _.isNil
            )
        }

        return _.omitBy(dataToSave, value => _.isNil(value))
    }

    function convertFullSaveToFirstSaveDto(fullSaveDTO, snapshotDal) {
        const dataMapsForFirstSave = _(fullSaveDTO.dataDelta)
            .mapKeys((delta, data_map_name) => FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME[data_map_name] || data_map_name)
            .omit(FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE)
            .value()

        const data = {
            documents: [createDocumentForFirstSave(fullSaveDTO)],
            protectedPagesData: fullSaveDTO.protectedPagesData,
            siteMetaData: fullSaveDTO.siteMetaData,
            metaSiteData: fullSaveDTO.metaSiteData,
            pagesPlatformApplications: fullSaveDTO.pagesPlatformApplications,
            sourceSiteId: fullSaveDTO.id,
            targetName: snapshotDal.getValue({type: 'documentServicesModel', id: 'siteName'}),
            wixCodeAppData: fullSaveDTO.wixCodeAppData,
            urlFormat: snapshotDal.getValue({type: 'urlFormatModel', id: 'format'}),
            initiator: fullSaveDTO.initiator,
            initiatorOrigin: fullSaveDTO.initiatorOrigin,
            ...dataMapsForFirstSave,
            signatures: getSignaturesMapFromSnapshotDal(snapshotDal)
        }
        if (fullSaveDTO.routers) {
            data.routers = fullSaveDTO.routers
        }
        data.translations = fullSaveDTO.translationsDelta
        return data
    }

    function createFirstSaveResultObject(firstSaveResponsePayload, currentSnapshotDal) {
        const clientSpecMap = deepClone(currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}))
        const usedMetaSiteNames = deepClone(currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'usedMetaSiteNames'}))

        const rendererModelChanges = [
            {
                path: ['rendererModel', 'siteInfo', 'siteId'],
                value: firstSaveResponsePayload.previewModel.siteId
            },
            {
                path: ['rendererModel', 'metaSiteId'],
                value: firstSaveResponsePayload.previewModel.metaSiteModel.metaSiteId
            },
            {
                path: ['rendererModel', 'mediaAuthToken'],
                value: firstSaveResponsePayload.mediaAuthToken
            },
            {
                path: ['rendererModel', 'premiumFeatures'],
                value: firstSaveResponsePayload.previewModel.metaSiteModel.premiumFeatures
            },
            {
                path: ['rendererModel', 'siteInfo', 'documentType'],
                value: firstSaveResponsePayload.previewModel.metaSiteModel.documentType
            },
            {
                path: ['rendererModel', 'clientSpecMap'],
                value: _.mergeWith(clientSpecMap, firstSaveResponsePayload.previewModel.metaSiteModel.clientSpecMap, function (oldVal, newVal) {
                    if (!newVal.demoMode || !oldVal || oldVal.demoMode) {
                        // We would like to merge the new value only if:
                        //  1. It is not in demo mode
                        //  2. There is no old value
                        //  3. The old value is not in demo mode
                        return _.merge({}, oldVal, newVal)
                    }
                    return oldVal
                })
            }
        ]

        const documentServicesModelChanges = [
            {
                path: ['documentServicesModel', 'neverSaved'],
                value: false
            },
            {
                path: ['documentServicesModel', 'siteName'],
                value: firstSaveResponsePayload.previewModel.metaSiteModel.siteName
            },
            {
                path: ['documentServicesModel', 'metaSiteData'],
                value: firstSaveResponsePayload.metaSiteData
            },
            {
                path: ['documentServicesModel', 'publicUrl'],
                value: firstSaveResponsePayload.publicUrl
            },
            {
                path: ['documentServicesModel', 'usedMetaSiteNames'],
                value: usedMetaSiteNames.push(firstSaveResponsePayload.publicUrl.match(/^.*\/([^\/]+)/)[1]) && usedMetaSiteNames
            },
            {
                path: snapshotDalSaveUtils.versionPath,
                value: firstSaveResponsePayload.siteHeader.version
            },
            {
                path: snapshotDalSaveUtils.revisionPath,
                value: firstSaveResponsePayload.siteHeader.revision
            },
            {
                path: ['documentServicesModel', 'autoSaveInfo'],
                value: firstSaveResponsePayload.autoSaveInfo
            },
            {
                path: ['documentServicesModel', 'mediaManagerInfo', 'siteUploadToken'],
                value: firstSaveResponsePayload.mediaSiteUploadToken
            },
            {
                path: ['documentServicesModel', 'permissionsInfo'],
                value: firstSaveResponsePayload.permissionsInfo
            },
            {
                path: ['documentServicesModel', 'hasSites'],
                value: true
            }
        ]

        let itemsToDelete
        if (experiment.isOpen('dm_removeUsageOfDeletedItemInPartialUpdate')) {
            const {deleted} = firstSaveResponsePayload
            itemsToDelete = addPagesOfItemsToDelete(deleted, currentSnapshotDal)
        } else {
            const {deleted, deletedItems} = firstSaveResponsePayload
            itemsToDelete = addPagesOfItemsToDelete(deleted || deletedItems, currentSnapshotDal)
        }

        const resultObject = {
            changes: rendererModelChanges.concat(documentServicesModelChanges).concat({path: ['orphanPermanentDataNodes'], value: []}),
            historyAlteringChanges: getHistoryAlteringChanges(itemsToDelete)
        }

        addWixCodeFirstSaveGridAppToResult(firstSaveResponsePayload, resultObject)

        addDevSiteAppDefIdToResult(firstSaveResponsePayload, resultObject)

        return resultObject
    }

    const saveEndpoints = [editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, editorServerFacade.ENDPOINTS.PARTIAL_SAVE]

    function cleanupData(dataDelta, boundExtensionsAPI, conservativeRemoval) {
        const remappedDataDelta = _.mapKeys(dataDelta, (v, sns) => _.findKey(NAMESPACE_MAPPING, k => k === sns))

        const dataDeltaAfterRemovals = boundExtensionsAPI.schema.removeWhitelistedPropertiesSafely(remappedDataDelta, conservativeRemoval)
        const restrictedDataDelta = _.mapKeys(dataDeltaAfterRemovals, (v, sns) => NAMESPACE_MAPPING[sns])
        return restrictedDataDelta
    }

    const semverPattern = /.*(\d+\.(\d+)\.\d+)$/

    function generateSaveLogMarkers(useRadicalWhitelistAtMonitoring) {
        const logsMarkers = {
            sessionStartTime: window.performance?.timing?.navigationStart,
            sessionLength: window.performance?.now() / 1000,
            whitelist: useRadicalWhitelistAtMonitoring ? 'radical' : 'conservative'
        }
        if (semverPattern.test(window.dmBase)) {
            const minorVersion = window.dmBase?.replace(semverPattern, '$2')
            logsMarkers.dmVersion = _.toNumber(minorVersion) || window.dmBase
        }
        return logsMarkers
    }

    /**
     * cleans up data delta contents according the whitelist (instead of ajv additional properties removal)
     * use radical model for monitoring and conservative for actual removal
     * @param {string} endpoint
     * @param data
     * @param boundExtensionsAPI
     */
    function cleanupDataDeltaContents(endpoint, data, boundExtensionsAPI) {
        const useRadicalWhitelistAtMonitoring = experiment.isOpen('dm_radicalWhitelistBasedDataDeltaCleanup')
        if (saveEndpoints.includes(endpoint)) {
            const conservativeDataDelta = cleanupData(data.dataDelta, boundExtensionsAPI, true)
            data.dataDelta = conservativeDataDelta

            if (data.translationsDelta) {
                data.translationsDelta = _.forEach(data.translationsDelta, multilingualDataDelta => {
                    multilingualDataDelta.data = cleanupData(multilingualDataDelta.data, boundExtensionsAPI, true)
                })
            }

            data.logsMarkers = generateSaveLogMarkers(useRadicalWhitelistAtMonitoring)

            if (useRadicalWhitelistAtMonitoring) {
                const restrictedDataDelta = cleanupData(data.dataDelta, boundExtensionsAPI, false)
                data.restrictedDataDelta = restrictedDataDelta
            }
        }
    }

    const sendRequestAsyncWrapped = async (endpoint, data, snapshotDal, editorOrigin, boundExtensionsAPI) => {
        let response
        try {
            response = await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, boundExtensionsAPI)
        } catch (e) {
            throwHttpError(e)
        }
        if (response.success) {
            return response
        }
        throw createErrorObject(response)
    }

    const sendRestRequestAsyncWrapped = async (endpoint, data, snapshotDal, editorOrigin, boundExtensionsAPI) => {
        try {
            return await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, boundExtensionsAPI)
        } catch (e) {
            throw await createErrorObjectFromRestException(e)
        }
    }

    const sendRequestAsync = async (endpoint, data, snapshotDal, editorOrigin, boundExtensionsAPI) =>
        new Promise((resolve, reject) => {
            sendRequest(endpoint, data, snapshotDal, resolve, reject, editorOrigin, boundExtensionsAPI)
        })

    function sendRequest(endpoint, data, snapshotDal, onSuccess, onError, editorOrigin, boundExtensionsAPI) {
        const editorSessionId = snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'})
        cleanupDataDeltaContents(endpoint, data, boundExtensionsAPI)

        const send = () => {
            monitoring.start(monitoring.SERVER_SAVE)
            editorServerFacade.sendWithSnapshotDal(
                snapshotDal,
                endpoint,
                data,
                result => {
                    monitoring.end(monitoring.SERVER_SAVE)
                    onSuccess(result)
                },
                onError,
                editorOrigin
            )
        }

        const ds_recordActionsToCompareWithRC = experiment.isOpen('ds_recordActionsToCompareWithRC')
        const ds_runActionsToCompareWithRC = experiment.isOpen('ds_runActionsToCompareWithRC')
        if (ds_recordActionsToCompareWithRC || ds_runActionsToCompareWithRC) {
            if (ds_recordActionsToCompareWithRC) {
                window.documentServices.debug.trace
                    .upload()
                    .catch(e => e) // eslint-disable-line promise/prefer-await-to-then
                    .then(send) // eslint-disable-line promise/prefer-await-to-then
            }

            if (ds_runActionsToCompareWithRC) {
                if (typeof window.autopilotSaves === 'undefined') {
                    window.autopilotSaves = []
                }

                const headers = getSaveDocumentHeaders(editorSessionId, editorOrigin)
                window.autopilotSaves.push({endpoint, body: data, headers})
            }
        } else {
            send()
        }
    }

    function hasAutoSaveInfo(snapshotDal) {
        return Boolean(snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: 'shouldAutoSave'}))
    }

    function onSaveCompleteError(snapshotDal, bi, editorOrigin, response, boundExtensionsAPI) {
        const rejectionInfo = createErrorObject(response)
        const {errorType} = rejectionInfo
        const validationError = isValidationError(response)
        if (validationError) {
            boundExtensionsAPI.logger.getLogger().captureError(new ReportableError({message: `Save Error: ${errorType}`, errorType: 'saveValidationError'}), {
                tags: {
                    errorType,
                    saveError: true
                },
                extras: {
                    origin: editorOrigin,
                    errorCode: _.get(rejectionInfo, 'errorCode'),
                    errorDescription: _.get(rejectionInfo, 'errorDescription'),
                    duplicateComponentId: _.get(response, 'payload.duplicateComponents[0].id') || null,
                    serverPayload: _.get(response, 'payload')
                }
            })
        }
        bi.error(biErrors.SAVE_DOCUMENT_FAILED_ON_SERVER, {
            serverErrorCode: rejectionInfo.errorCode,
            errorType,
            origin: editorOrigin
        })
        return rejectionInfo
    }

    function onSaveCompleteSuccess(snapshotDal, bi, settleInServer, response, boundExtensionsAPI) {
        const resolveObject = {
            changes: [
                {
                    path: snapshotDalSaveUtils.revisionPath,
                    value: response.payload.revision
                },
                {
                    path: snapshotDalSaveUtils.versionPath,
                    value: response.payload.version
                },
                {
                    path: ['orphanPermanentDataNodes'],
                    value: []
                }
            ]
        }
        if (hasAutoSaveInfo(snapshotDal)) {
            resolveObject.changes.push({
                path: previousDiffIdPath,
                value: undefined
            })
        }

        if (snapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})) {
            resolveObject.changes.push({
                path: ['documentServicesModel', 'isDraft'],
                value: false
            })
        }

        if (settleInServer) {
            const clientSpecMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
            const documentType = snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'documentType'})
            if (response.payload.clientSpecMap) {
                resolveObject.changes.push(
                    {
                        path: ['rendererModel', 'clientSpecMap'],
                        value: documentType === 'Template' ? response.payload.clientSpecMap : _.assign({}, clientSpecMap, response.payload.clientSpecMap)
                    },
                    {
                        path: ['rendererModel', 'clientSpecMapCacheKiller'],
                        value: {cacheKiller: santaCoreUtils.guidUtils.getGUID()}
                    }
                )
                resolveObject.changes.push(semanticAppVersionsCleaner())
                boundExtensionsAPI.logger.getLogger().interactionEnded(constants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS)
            }
            resolveObject.changes.push({
                path: ['rendererModel', 'siteInfo', 'documentType'],
                value: documentType
            })
        }

        let itemsToDelete
        if (experiment.isOpen('dm_removeUsageOfDeletedItemInPartialUpdate')) {
            const {deleted} = response.payload
            itemsToDelete = addPagesOfItemsToDelete(deleted, snapshotDal)
        } else {
            const {deleted, deletedItems} = response.payload
            itemsToDelete = deleted ? addPagesOfItemsToDelete(deleted, snapshotDal) : deletedItems
        }

        if (!boundExtensionsAPI.csave.isCEditOpen()) {
            resolveObject.historyAlteringChanges = getHistoryAlteringChanges(itemsToDelete)
        }

        addWixCodeSavedGridAppToResult(response.payload, resolveObject)

        return resolveObject
    }

    function addPagesOfItemsToDelete(itemsToDelete, snapshotDal) {
        const result = {}
        _.forEach(itemsToDelete, function (deletedIds, dataType) {
            _.forEach(deletedIds, function (deletedItemId) {
                /**
                 * for dataType === 'multilingualTranslations' deletedItemId will be `itemid^langCode`
                 */
                const isMultilingualTranslations = dataType === MULTILINGUAL_TYPES.multilingualTranslations
                const actualItemId = isMultilingualTranslations ? deletedItemId.split('^')[0] : deletedItemId
                const dataItem = snapshotDal.getValue({
                    type: isMultilingualTranslations ? OVERRIDE_NAMESPACES[dataType] : pageDataTypeToKey[dataType],
                    id: actualItemId
                })
                const pageId = _.get(dataItem, ['metaData', 'pageId'])
                if (pageId) {
                    const deletesIdsArr = _.get(result, [pageId, dataType], [])
                    let idToPush = deletedItemId
                    if (isMultilingualTranslations) {
                        const splitted = deletedItemId.split('^')
                        idToPush = [pageId, splitted[1], splitted[0]].join('^')
                    }
                    deletesIdsArr.push(idToPush)
                    _.set(result, [pageId, dataType], deletesIdsArr)
                }
            })
        })
        return result
    }

    function getSaveDocumentHeaders(sessionId, editorOrigin) {
        return {
            'X-Wix-Editor-Version': 'new',
            'X-Wix-DS-Origin': editorOrigin,
            'X-Editor-Session-Id': sessionId
        }
    }

    const fullSaveAsync = (lastSnapshotDal, currentSnapshotDal, bi, options, boundExtensionsAPI) =>
        saveWithFullPayloadAsync(currentSnapshotDal, bi, options, editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, boundExtensionsAPI)

    const fullSave = (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        fullSaveAsync(lastSnapshotDal, currentSnapshotDal, bi, options, boundExtensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    }

    async function fullPartialSave(bi, options, currentSnapshotDal, boundExtensionsAPI) {
        setSaving(boundExtensionsAPI, true)
        try {
            await saveWithFullPayloadAsync(currentSnapshotDal, bi, options, editorServerFacade.ENDPOINTS.PARTIAL_SAVE, boundExtensionsAPI)
        } finally {
            setSaving(boundExtensionsAPI, false)
        }
    }

    const validateSite = (last, current, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        validateSiteAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    }

    const validateSiteAsync = async (last, current, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        const opts = {settleInServer: false, viewerName: '', initiatorOrigin: _.get(options, 'initiatorOrigin', '')}
        const dataToValidate = await createPartialDataToSave(bi, opts, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) //no settling on validation
        await sendRequestAsyncWrapped(editorServerFacade.ENDPOINTS.VALIDATE, dataToValidate, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
    }

    async function saveWithFullPayloadAsync(currentSnapshotDal, bi, options, endPoint, boundExtensionsAPI) {
        setSaving(boundExtensionsAPI, true)
        const dataToSave = await createFullDataToSave(currentSnapshotDal, options, boundExtensionsAPI, bi)
        let response
        try {
            response = await sendRequestAsync(endPoint, dataToSave, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
        } catch (e) {
            throwHttpError(e)
        } finally {
            setSaving(boundExtensionsAPI, false)
        }
        if (response.success) {
            return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, boundExtensionsAPI)
        }
        throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response, boundExtensionsAPI)
    }

    const setSaving = (boundExtensionsAPI, isSaving) => {
        boundExtensionsAPI.csave.setSaving(isSaving)
    }

    const convertCreateRevisionResponse = ({siteRevision, actions}) => {
        return {
            revision: siteRevision.revision,
            version: siteRevision.version,
            clientSpecMap: _.find(actions, {id: 'clientSpecMap', namespace: 'rendererModel'})?.value,
            wixCodeModel: {
                appData: {
                    codeAppId: _.find(actions, {namespace: 'rendererModel', id: 'wixCodeModel'})?.value.appData?.codeAppId
                }
            },
            deleted: _(actions)
                .filter({op: 'REMOVE'})
                .groupBy('namespace')
                .mapValues(x => _.map(x, 'id'))
                .value()
        }
    }

    const csaveCreateRevision = async (currentSnapshotDal, options, updateSiteDto, boundExtensionsAPI) => {
        // send origin from here
        const initiator = getInitiator(currentSnapshotDal)
        const createRevisionArgs = {
            initiator,
            viewerName: options.viewerName,
            initiatorOrigin: _.get(options, 'initiatorOrigin', ''),
            dsOrigin: options.editorOrigin,
            editorVersion: updateSiteDto.version.toString()
        }
        const crResult = await asyncAttempt(() => boundExtensionsAPI.csave.createRevision(createRevisionArgs, updateSiteDto))
        if (crResult.didThrow) {
            const {message, details, status, extras} = crResult.error
            const isServerError = _.isNumber(status) && !_.inRange(status, 200, 300)
            if (isServerError) {
                throw _.pick(extras, ['status', 'statusText'])
            }
            return {
                success: false,
                message,
                errorCode: details?.applicationError?.code,
                errorDescription: details?.applicationError?.description
            }
        }
        return {
            success: true,
            payload: convertCreateRevisionResponse(crResult.value)
        }
    }

    /**
     * @param last
     * @param current
     * @param resolve
     * @param reject
     * @param bi
     * @param options
     * @param lastSnapshotDal
     * @param currentSnapshotDal
     * @param boundExtensionsAPI
     */
    const partialSave = (last, current, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        partialSaveAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    }

    /**
     *
     * @param last
     * @param current
     * @param bi
     * @param {PartialSaveOptions} options
     * @param lastSnapshotDal
     * @param currentSnapshotDal
     * @param boundExtensionsAPI
     * @returns {Promise<any>}
     */
    const partialSaveAsync = async (last, current, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        if (_.get(options, 'fullPayload')) {
            return await fullPartialSave(bi, options, currentSnapshotDal, boundExtensionsAPI)
        }
        monitoring.start(monitoring.BUILD_PARTIAL_PAYLOAD)
        setSaving(boundExtensionsAPI, true)
        const dataToSave = await createPartialDataToSave(bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI)
        monitoring.end(monitoring.BUILD_PARTIAL_PAYLOAD)
        let response
        try {
            response = boundExtensionsAPI.csave.isCreateRevisionOpen()
                ? await csaveCreateRevision(currentSnapshotDal, options, dataToSave, boundExtensionsAPI)
                : await sendRequestAsync(editorServerFacade.ENDPOINTS.PARTIAL_SAVE, dataToSave, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
        } catch (e) {
            throwHttpError(e)
        } finally {
            setSaving(boundExtensionsAPI, false)
        }
        if (response.success) {
            return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, boundExtensionsAPI)
        }
        if (isValidationError(response)) {
            bi.event(biEvents.FULL_DOCUMENT_SAVE_ATTEMPTED_AFTER_PARTIAL_FAILURE, {endpoint: 'partial'})
            monitoring.start(monitoring.FULL_PARTIAL_SAVE)
            await fullPartialSave(bi, options, currentSnapshotDal, boundExtensionsAPI)
            monitoring.end(monitoring.FULL_PARTIAL_SAVE)
        } else {
            throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response, boundExtensionsAPI)
        }
    }

    const shouldRun = (lastSnapshot, currentSnapshot, methodName, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI, pointers) => {
        if (methodName === 'partialSave') {
            const diff = currentSnapshotDal.diff(lastSnapshotDal)
            const didSnapShotsChangeFromLastSave = shouldSaveDiff(diff)
            const shouldCodeAppChange = getGridAppForRevision.shouldGridAppChangeBySnapshotDal(pointers, lastSnapshotDal, currentSnapshotDal)
            boundExtensionsAPI.logger.getLogger().breadcrumb('shouldRun', {didSnapShotsChangeFromLastSave, shouldCodeAppChange})
            return didSnapShotsChangeFromLastSave || shouldCodeAppChange
        }
        return true
    }

    const throwHttpError = e => {
        // eslint-disable-next-line no-throw-literal
        throw {
            errorCode: e.status,
            errorType: errorConstants.save.HTTP_REQUEST_ERROR,
            errorDescription: e.statusText
        }
    }

    const saveAsTemplateAsync = async (lastSnapshot, currentSnapshot, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        await sendRequestAsyncWrapped(editorServerFacade.ENDPOINTS.SAVE_AS_TEMPLATE, null, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
        return {
            changes: [{path: ['rendererModel', 'siteInfo', 'documentType'], value: 'Template'}]
        }
    }

    const firstSaveAsync = async (lastSnapshot, currentSnapshot, bi, options = {}, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) => {
        const fullSaveDTO = await createFullDataToSave(currentSnapshotDal, options, boundExtensionsAPI, bi)
        const firstSaveDTO = _.assign({}, convertFullSaveToFirstSaveDto(fullSaveDTO, currentSnapshotDal), options.extraPayload)
        const {payload} = await sendRequestAsyncWrapped(
            editorServerFacade.ENDPOINTS.FIRST_SAVE,
            firstSaveDTO,
            currentSnapshotDal,
            options.editorOrigin,
            boundExtensionsAPI
        )
        return createFirstSaveResultObject(payload, currentSnapshotDal)
    }

    const sendPublishWithOverridesRequest = async (currentSnapshotDal, options, boundExtensionsAPI) => {
        const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_WITH_OVERRIDES
        const branchId = boundExtensionsAPI.documentServicesModel.getBranchId()
        const revision = boundExtensionsAPI.documentServicesModel.getRevision()
        const {publishedSiteDetails} = await sendRestRequestAsyncWrapped(
            editorServerFacade.ENDPOINTS.PUBLISHED_SITE_DETAILS,
            null,
            currentSnapshotDal,
            options.editorOrigin,
            boundExtensionsAPI
        )
        const {branchId: basedOnBranch, siteRevision: basedOnRevision} = publishedSiteDetails.siteProperties
        return sendRestRequestAsyncWrapped(
            endpoint,
            {
                label: options.label ?? 'publish-specific-pages',
                deployment_attributes: [
                    {
                        page_attribute: {
                            page_ids: options.specificPages,
                            editor_revision: {branch_id: branchId, site_revision: revision}
                        }
                    }
                ],
                specific_version: {
                    branch_id: basedOnBranch,
                    site_revision: basedOnRevision
                },
                should_publish: true
            },
            currentSnapshotDal,
            options.editorOrigin,
            boundExtensionsAPI
        )
    }

    const sendPublishTestSiteRequest = async (currentSnapshotDal, options, boundExtensionsAPI) => {
        const {viewerName} = options
        const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_TEST_SITE
        const data = {viewerName}
        const branchId = boundExtensionsAPI.documentServicesModel.getBranchId()
        if (branchId) {
            data.branchId = branchId
        }
        if (options.overrideRevisionInfo) {
            data.overrideRevisionInfo = {
                revision: options.overrideRevisionInfo.revision
            }
            if (options.overrideRevisionInfo.branchId) {
                data.overrideRevisionInfo.branchId = options.overrideRevisionInfo.branchId
            }
        }

        return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
    }

    const sendFullPublishRequest = async (currentSnapshotDal, options, boundExtensionsAPI) => {
        const {viewerName} = options
        const endpoint = options.publishRC ? editorServerFacade.ENDPOINTS.PUBLISH_RC : editorServerFacade.ENDPOINTS.PUBLISH
        const data = {viewerName}
        const branchId = boundExtensionsAPI.documentServicesModel.getBranchId()
        if (branchId) {
            data.branchId = branchId
        }

        return sendRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, boundExtensionsAPI)
    }

    const sendPublishRequest = async (currentSnapshotDal, options, basedOnRevision) => {
        if (options.specificPages) {
            return sendPublishWithOverridesRequest(currentSnapshotDal, options, basedOnRevision)
        }
        if (options.publishTestSite) {
            return sendPublishTestSiteRequest(currentSnapshotDal, options, basedOnRevision)
        }
        return sendFullPublishRequest(currentSnapshotDal, options, basedOnRevision)
    }

    const publishAsync = async (currentSnapshot, bi, options = {}, currentSnapshotDal, boundExtensionsAPI) => {
        hooks.executeHook(hooks.HOOKS.PUBLISH.BEFORE)
        let response
        setSaving(boundExtensionsAPI, true)
        try {
            response = await sendPublishRequest(currentSnapshotDal, options, boundExtensionsAPI)
        } finally {
            setSaving(boundExtensionsAPI, false)
        }

        const changes = [
            {
                path: ['documentServicesModel', 'isPublished'],
                value: true
            }
        ]

        const revision = _.get(response, 'payload.revision') || _.get(response, 'revisionInfo.revision')

        if (revision) {
            changes.push({
                path: snapshotDalSaveUtils.revisionPath,
                value: revision
            })
        }

        const version = _.get(response, 'payload.version')
        if (version) {
            changes.push({
                path: snapshotDalSaveUtils.versionPath,
                value: version
            })
        }
        return {
            changes
        }
    }

    /**
     * @exports documentServices/saveAPI/saveTasks/saveDocument
     */
    return {
        /** @private */
        paths: {
            versionPath: snapshotDalSaveUtils.versionPath,
            revisionPath: snapshotDalSaveUtils.revisionPath,
            previousDiffId: previousDiffIdPath
        },

        csaveCreateRevision,

        /**
         *
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         * @param {Function} resolve - resolve this task (success).
         * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
         * @param {{error: Function, event: Function}} bi
         */
        partialSave,

        /**
         * @param {object} last - the DAL snapshot, since the last save
         * @param {object} current - the DAL snapshot, since the last save
         * @param {{error: Function, event: Function}} bi
         * @param {object} options
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         */
        partialSaveAsync,

        /**
         *
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         * @param {Function} resolve - resolve this task (success).
         * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
         */
        fullSave,

        /**
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         * @param {Function} resolve - resolve this task (success).
         * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
         */
        fullSaveAsync,

        /**
         *
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         * @param {Function} resolve - resolve this task (success).
         * @param {Function} reject - reject this validation (fail). Can be called with errorType, errorMessage
         */
        validateSite,

        /**
         *
         * @param {object} lastSnapshot - the DAL snapshot, since the last save
         * @param {object} currentSnapshot - the DAL snaUpshot, as it is right now
         * @param {*} resolve - resolve this task (success).
         * @param {*} reject - reject this save (fail). Can be called with errorType, errorMessage
         * @param bi
         * @param options
         * @param lastSnapshotDal
         * @param currentSnapshotDal
         * @param boundExtensionsAPI
         */
        firstSave(lastSnapshot, currentSnapshot, resolve, reject, bi, options = {}, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI) {
            firstSaveAsync(lastSnapshot, currentSnapshot, bi, options, lastSnapshotDal, currentSnapshotDal, boundExtensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
        },

        firstSaveAsync,

        saveAsTemplate(lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) {
            saveAsTemplateAsync(lastSnapshot, currentSnapshot, bi, options, lastSnapshotDal, currentSnapshotDal).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
        },

        /**
         * @param {object} currentSnapshot - the DAL snapshot, as it is right now
         * @param {*} resolve - resolve this task (success).
         * @param {*} reject - reject this save (fail). Can be called with errorType, errorMessage
         * @param bi
         * @param {{viewerName?: string, publishRC?: string, publishTestSite?: boolean, overrideRevisionInfo?:object, editorOrigin?: string}} options
         * @param currentSnapshotDal
         * @param boundExtensionsAPI
         */
        publish(currentSnapshot, resolve, reject, bi, options = {}, currentSnapshotDal, boundExtensionsAPI) {
            publishAsync(currentSnapshot, bi, options, currentSnapshotDal, boundExtensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
        },

        publishAsync,

        getTaskName() {
            return TASK_NAME
        },

        shouldRun,

        getSnapshotTags(methodName) {
            switch (methodName) {
                case 'partialSave':
                case 'fullSave':
                case 'firstSave':
                case 'saveAsTemplate':
                    return ['primary', 'autosave'] // initial snapshot for saveDocumentautosave tag is taken right after applying patches (patchData.js). If you change the tag here - please update there
                case 'autosave':
                    return ['autosave']
                case 'publish':
                    return ['primary']
                default:
                    return ['primary']
            }
        },

        cleanupDataDeltaContents
    }
})
