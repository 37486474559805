import * as multilingualReferenceFixer from './multilingualReferenceFixer'
import * as removeBrokenQueries from './removeBrokenQueries'
import * as removeBrokenMemberPages from './removeBrokenMemberPages'
import * as removeBrokenUnitSizesFromLayout from './removeBrokenUnitSizesFromLayout'
import * as removeBrokenStyleRelations from './removeBrokenVariantRelations'
import * as removeBrokenTpaSharedState from './removeBrokenTpaSharedState'
import * as fixBrokenConnections from './fixBrokenConnections'
import * as removeFromConnectionBrokenControllers from './removeFromConnectionBrokenControllers'
import * as removeMetaDataFromMaps from './removeMetaDataFromMaps'
import * as wrapMapsForSignatures from './wrapMapsForSignatures'
import * as removeCorruptedLinksFromStyledText from './removeCorruptedLinksFromStyledText'
import * as customMenusCollectionFixer from './customMenusCollectionFixer'
import * as fixMobileComponentsWithWrongParent from './fixMobileComponentsWithWrongPage'
import * as runComponentSpecificMigrations from './componentMigrations/runComponentSpecificMigrations'
import * as childRefFixer from './childRefFixer'
import * as duplicateAndCrossPageRefFixer from './duplicateAndCrossPageRefFixer/duplicateAndCrossPageRefFixer'
import * as fixBrokenLayout from './fixBrokenLayout'
import * as fixBreakpointsVariantsQuery from './fixBreakpointsVariantsQuery'
import * as fixBrokenMenusRefs from './fixBrokenMenuRefs'

import type {PageMigrator, MasterPageMigrator, SiteMigrator} from '../dataMigrationRunner'

const defaultRunAlways: PageMigrator[] = [
    removeBrokenQueries,
    removeBrokenMemberPages,
    removeBrokenUnitSizesFromLayout,
    removeBrokenStyleRelations,
    removeFromConnectionBrokenControllers,
    removeCorruptedLinksFromStyledText,
    runComponentSpecificMigrations,
    fixMobileComponentsWithWrongParent,
    fixBrokenLayout,
    duplicateAndCrossPageRefFixer,
    customMenusCollectionFixer
]

const defaultRunOnce: PageMigrator[] = [fixBrokenConnections, fixBreakpointsVariantsQuery]

const defaultMasterPageMigrations: MasterPageMigrator[] = [
    removeBrokenTpaSharedState,
    fixBrokenMenusRefs,
    removeMetaDataFromMaps,
    wrapMapsForSignatures,
    childRefFixer
]

const defaultRunOncePerSite: SiteMigrator[] = [multilingualReferenceFixer]

export {defaultRunAlways, defaultRunOnce, defaultMasterPageMigrations, defaultRunOncePerSite}
