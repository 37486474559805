/**
 * This is the description for the tpa namespace.
 * @memberof documentServices
 * @namespace documentServices.tpa
 */
define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'tpa',
    'experiment',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/hooks/hooks',
    'documentServices/component/component',
    'documentServices/structure/structure',
    'documentServices/page/page',
    'documentServices/structure/fixedComponentMeasuring',
    'documentServices/platform/provision',
    'documentServices/tpa/services/tpaComponentService',
    'documentServices/tpa/services/tpaAddService',
    'documentServices/tpa/services/tpaComponentCommonService',
    'documentServices/tpa/services/tpaWidgetService',
    'documentServices/tpa/services/tpaSectionService',
    'documentServices/tpa/services/tpaPageService',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/appMarketService',
    'documentServices/tpa/services/provisionService',
    'documentServices/tpa/services/appStoreService',
    'documentServices/tpa/services/sectionsTranslatedPageTitlesCache',
    'documentServices/tpa/data/componentDefinition',
    'documentServices/tpa/services/pendingAppsService',
    'documentServices/tpa/constants',
    'documentServices/tpa/services/tpaSettingsService',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/services/appInstallationAndDeletionEvents',
    'documentServices/tpa/bi/errors',
    'documentServices/tpa/services/billingService',
    'documentServices/tpa/services/tpaDataService',
    'documentServices/tpa/services/tpaPostMessageService',
    'documentServices/tpa/services/tpaStyleService',
    'documentServices/tpa/handlers/tpaHandlers',
    'documentServices/tpa/services/tpaDeleteService',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/tpa/services/tpaPreviewEditorCommunicationService',
    'documentServices/tpa/utils/permissionsUtils',
    'documentServices/platform/services/platformStateService',
    'documentServices/tpa/utils//componentAppDataClone'
], function (
    _,
    coreUtils,
    tpa,
    experiment,
    generalInfo,
    hooks,
    component,
    structure,
    page,
    fixedComponentMeasuring,
    platformProvision,
    tpaComponentService,
    tpaAddService,
    tpaComponentCommonService,
    tpaWidgetService,
    tpaSectionService,
    tpaPageService,
    installedTpaAppsOnSiteService,
    clientSpecMapService,
    appMarketService,
    provisionService,
    appStoreService,
    sectionsTranslatedPageTitlesCache,
    componentDefinitionService,
    pendingAppsService,
    tpaConstants,
    tpaSettingsService,
    tpaUtils,
    tpaEventHandlersService,
    appInstallationAndDeletionEvents,
    errors,
    billingService,
    tpaDataService,
    tpaPostMessageService,
    tpaStyleService,
    tpaHandlers,
    tpaDeleteService,
    extensionsAPI,
    tpaPreviewEditorCommunicationService,
    permissionsUtils,
    platformStateService,
    componentAppDataClone
) {
    'use strict'

    let _dsHandlersReady = false

    function undoRedoHookHandler(ps) {
        const tpaCompPointers = getAllTPAsOnCurrentPage(ps) || []

        tpaCompPointers.forEach(tpaCompPointer => {
            tpaEventHandlersService.executeHistoryChangeHandler(tpaCompPointer.id)
        })
    }

    /**
     * @param {ps} ps
     */
    const initialize = function (ps) {
        coreUtils.loggingUtils.performance.start(coreUtils.loggingUtils.performanceMetrics.TPA.INITIALIZE)

        tpaDataService.runGarbageCollection(ps)
        const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
        fixedComponentMeasuring.setMeasuringForType(
            'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
            tpa.gluedWidgetMeasuringUtils.getGluedWidgetMeasurements.bind(null, clientSpecMap)
        )

        tpaHandlers.settpads(api)
        tpaPostMessageService.init(ps)
        tpaPreviewEditorCommunicationService.init(ps)
        markDocumentServicesHandlersAreReady(true)

        permissionsUtils.shouldAvoidRevoking({ps}).then(shouldAvoidRevoking => {
            if (shouldAvoidRevoking) {
                cacheUnusedAppsMap(ps)
            }

            appStoreService.settleOnLoad(ps, shouldAvoidRevoking, () => clientSpecMapService.setClientSpecMapReadyOnLoad(ps))
        })

        if (!experiment.isOpen('dm_useProvisionApi')) {
            appStoreService.preSaveAddAppsOnLoad(ps)
        }

        fixPageUriSEOIfNeeded(ps)

        Promise.resolve(
            appMarketService.getSectionsTranslatedPageTitles(ps).then(function (data) {
                sectionsTranslatedPageTitlesCache.init(data)
                fixHiddenPagesTitleIfNeeded(ps, clientSpecMap, data)
            })
        ).catch(e => {
            console.log(e)
        })

        extensionsAPI.tpa.setSyncer(ps, 'TPA_COMP_PREVIEW_DATA', _.partial(tpaComponentService.tpaCompPreviewDataSyncer, ps))
        extensionsAPI.tpa.setSyncer(ps, 'COMP_STATE', _.partial(tpaComponentService.compStateSyncer, ps))
        extensionsAPI.tpa.setSyncer(ps, 'POST_MESSAGE_TO_APP', tpaComponentService.postMessageToAppSyncer, {fetchCompIdFromId: id => id[0]})

        extensionsAPI.rendererModel.onAppsInstalledRemotely(ps, newApps => {
            _.forEach(newApps, newApp => {
                platformProvision.onRemoteProvision(ps, newApp)
            })
        })

        hooks.registerHook(hooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, undoRedoHookHandler)
        coreUtils.loggingUtils.performance.finish(coreUtils.loggingUtils.performanceMetrics.TPA.INITIALIZE)
    }

    /**
     * This grabs and caches apps that are unused, a.k.a would have been revoked if site had not had branches enabled
     * so clientSpecMapService.isAppActive(...) will still behave like the app is revoked
     */
    const cacheUnusedAppsMap = ps => {
        const unusedApps = installedTpaAppsOnSiteService.getUnusedApps(ps)
        platformStateService.setUnusedApps(ps, unusedApps)
    }

    const getAllTPAsOnCurrentPage = ps => {
        const renderedRootIds = ps.siteAPI.getAllRenderedRootIds()
        const rootPagePointers = renderedRootIds.map(rootId => page.getPage(ps, rootId))
        const tpaChildrenPerPage = rootPagePointers.map(rootPagePointer => component.getTpaChildren(ps, rootPagePointer))
        return _.union(...tpaChildrenPerPage)
    }

    const fixHiddenPagesTitleIfNeeded = function (ps, clientSpecMap, data) {
        if (generalInfo.isFirstSave(ps) || generalInfo.isDraft(ps)) {
            const pagesData = page.getPagesDataItems(ps)
            _.forEach(pagesData, pageData => {
                if (pageData.tpaApplicationId > 0 && pageData.tpaPageId) {
                    const appData = _.get(clientSpecMap, pageData.tpaApplicationId)
                    if (appData) {
                        const widget = _.find(appData.widgets, widgetData => _.get(widgetData, 'appPage.id') === pageData.tpaPageId)
                        if (_.get(widget, 'appPage.hidden')) {
                            const appTranslatedData = _.find(data, {appDefinitionId: appData.appDefinitionId})
                            const widgetTranslatedData = _.find(_.get(appTranslatedData, 'widgets'), {widgetId: widget.widgetId})
                            const translatedTitle = _.get(widgetTranslatedData, 'title')
                            if (translatedTitle) {
                                page.data.set(ps, pageData.id, {title: translatedTitle})
                            }
                        }
                    }
                }
            })
        }
    }

    /**
     * @param {ps} ps
     * @param componentToAddRef
     * @param appDefinitionId
     * @param options
     */
    const provisionSystemApp = function (ps, componentToAddRef, appDefinitionId, options) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (appData && !isAppPermissionsIsRevoked(ps, appData)) {
            const widgetId = _.get(options, 'widgetId')
            const widgetData = appData.widgets[widgetId]
            const isAlreadyInstalled = tpaWidgetService.isGlued(widgetData)
                ? isAppInstalledOnPage(ps, 'masterPage', appDefinitionId)
                : isAppInstalledBy(ps, appDefinitionId)
            if (!isAlreadyInstalled) {
                tpaComponentService.provisionWidget(ps, componentToAddRef, appDefinitionId, {widgetId})
                pendingAppsService.add(appData) //in order to settle on save
                return
            }
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete({dontAdd: true})
    }

    const sectionAlreadyInstalled = function (ps, appDefinitionId) {
        return tpaSectionService.alreadyInstalled(ps, appDefinitionId)
    }

    const getPageData = function (ps, pageId) {
        const pageData = page.data.get(ps, pageId)
        const hasSection = pageData.tpaApplicationId > 0
        let appData
        if (hasSection) {
            appData = clientSpecMapService.getAppData(ps, pageData.tpaApplicationId)
        }
        return {
            title: pageData.title,
            hasSection,
            appData
        }
    }

    const getComponentDefinition = function (ps, params) {
        return componentDefinitionService.getComponentDefinition(params)
    }

    const isPremiumApp = function (ps, applicationId) {
        return clientSpecMapService.isPremiumApp(ps, applicationId)
    }

    const hasPremiumOffering = function (ps, applicationId) {
        return clientSpecMapService.hasPremiumOffering(ps, applicationId)
    }

    const isPremiumByAppDefinitionId = function (ps, appDefinitionId) {
        const app = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (app) {
            return isPremiumApp(ps, app.applicationId)
        }
        return false
    }

    const getWidgetDataFromTPAPageId = function (ps, appDefinitionId, tpaPageId) {
        return clientSpecMapService.getWidgetDataFromTPAPageId(ps, appDefinitionId, tpaPageId)
    }

    const getWidgetDataFromTPAWidgetId = function (ps, appDefinitionId, tpaWidgetId) {
        return clientSpecMapService.getWidgetDataFromTPAWidgetId(ps, appDefinitionId, tpaWidgetId)
    }

    const registerOnAppInstalled = function (ps, appDefinitionId, cb) {
        appInstallationAndDeletionEvents.registerOnAppInstalled(appDefinitionId, cb)
    }

    const registerOnAppDeleted = function (ps, appDefinitionId, cb) {
        appInstallationAndDeletionEvents.registerOnAppDeleted(appDefinitionId, cb)
    }

    const getExtensionsWidgets = function (ps, appData) {
        return clientSpecMapService.getExtensionsWidgets(ps, appData)
    }

    const isHybridApp = function (ps, applicationId) {
        return clientSpecMapService.isHybridApp(ps, applicationId)
    }

    const isDashboardAppOnly = function (ps, applicationId) {
        const appData = applicationId && getAppData(ps, applicationId)
        return clientSpecMapService.isDashboardAppOnly(appData)
    }

    const getFirstAppCompPageId = function (ps, appDefinitionId, useDefaultWidget) {
        return installedTpaAppsOnSiteService.getFirstAppCompPageId(ps, appDefinitionId, useDefaultWidget)
    }

    const duplicateWidget = function (ps, compPointer, pageId) {
        tpaWidgetService.duplicateWidget(ps, compPointer, pageId)
    }

    const registerDeleteCompHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerDeleteCompHandler(compId, handler)
    }

    const registerDisconnectCompHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerDisconnectCompHandler(compId, handler)
    }

    const registerCompLoadedHandler = function (ps, handler) {
        tpaEventHandlersService.registerCompLoaded(handler)
    }

    const registerEditModeChangeHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerEditModeChangeHandler(compId, handler)
    }

    const registerEditorEventHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerEditorEventHandler(compId, handler)
    }

    const registerThemeChangeHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerThemeChangeHandler(ps, compId, handler)
    }

    const registerSettingsUpdatedHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerSettingsUpdatedHandler(compId, handler)
    }

    const registerPublicDataChangedHandler = function (ps, compId, applicationId, handler) {
        tpaEventHandlersService.registerPublicDataChangedHandler(compId, applicationId, handler)
    }

    const registerSitePublishedHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerSitePublishedHandler(compId, handler)
    }

    const registerDeviceTypeChangeHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerDeviceTypeChangeHandler(compId, handler)
    }

    const registerWindowPlacementChangedHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerWindowPlacementChangedHandler(compId, handler)
    }

    const registerHistoryChangeHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerHistoryChangeHandler(compId, handler)
    }

    const editModeChange = function (ps, editorMode) {
        tpaEventHandlersService.editModeChange(editorMode)
    }

    const triggerEditorEvent = function (ps, msg) {
        tpaEventHandlersService.triggerEditorEvent(msg)
    }

    const registerSiteSavedHandler = function (ps, compId, handler) {
        tpaEventHandlersService.registerSiteSavedHandler(compId, handler)
    }

    const getSanitizedNumber = str => {
        const num = parseFloat(str)
        return isNaN(num) ? undefined : num
    }

    const fixLayoutStrings = layout => {
        const props = ['width', 'height']
        _.forEach(props, prop => {
            if (typeof layout[prop] === 'string') {
                layout[prop] = getSanitizedNumber(layout[prop])
            }
        })
    }

    const resizeComponent = function (ps, compPointer, width, height, callback) {
        const isStretched = structure.isHorizontallyStretchedToScreen(ps, compPointer)
        if (isStretched) {
            callback({onError: 'component is already set to full width'})
        } else {
            let layout = component.layout.get(ps, compPointer)
            const exit = () => {
                callback({
                    width: layout.width,
                    height: layout.height
                })
            }
            const newLayout = _.defaults({width, height}, {width: layout.width, height: layout.height})
            fixLayoutStrings(newLayout)
            const layoutIsInvalid = _.values(newLayout).some(_.isUndefined)
            if (layoutIsInvalid) {
                exit()
                return
            }
            structure.updateCompLayout(ps, compPointer, newLayout)
            ps.siteAPI.forceUpdate()
            layout = component.layout.get(ps, compPointer)
            exit()
        }
    }

    const setExternalId = function (ps, componentPointer, referenceId, callback, preventRefresh) {
        tpaComponentService.setExternalId(ps, componentPointer, referenceId, callback, preventRefresh)
    }

    const getExternalId = function (ps, componentPointer) {
        return tpaComponentService.getExternalId(ps, componentPointer)
    }

    const sitePublished = function () {
        tpaEventHandlersService.sitePublished()
    }

    const siteSaved = function () {
        tpaEventHandlersService.siteSaved()
    }

    const deviceTypeChange = function (ps, deviceType) {
        tpaEventHandlersService.deviceTypeChange(deviceType)
    }

    const triggerSettingsUpdated = function (ps, applicationId, targetCompId, message) {
        tpaComponentService.settingsUpdated(ps, applicationId, targetCompId, message)
    }

    const refreshApp = function (ps, comps, queryParams) {
        tpaComponentService.refreshApp(ps, comps, queryParams)
    }

    const setDeviceType = function (ps, deviceType) {
        const deviceTypes = ['desktop', 'mobile']
        if (deviceTypes.includes(deviceType)) {
            tpaComponentService.setCompPreviewDataForAllTPA(ps, 'deviceTypeForTPA', deviceType)
            ps.siteAPI.reloadAppsContainer()
        } else {
            console.error(`${deviceType} is invalid device type! use {desktop, mobile}`)
        }
    }

    const getAppData = function (ps, applicationId) {
        return clientSpecMapService.getAppData(ps, applicationId)
    }

    const setStyleParam = function (ps, styleId, compId) {
        registerThemeChangeHandler(ps, compId, function (changedData) {
            tpaComponentService.postBackThemeData(ps, compId, changedData)
        })
    }

    const getSettingsModalParams = function (ps, urlParams, panelParams) {
        return tpaSettingsService.getSettingsModalParams(ps, urlParams, panelParams)
    }

    const triggerOnWindowPlacementChanged = function (ps, msg) {
        tpaEventHandlersService.triggerOnWindowPlacementChanged(msg)
    }

    const getStyleDataToPassIntoApp = function (ps, compId) {
        return tpaStyleService.getStyleDataToPassIntoApp(ps, compId)
    }

    const getCompStyleDataToPassIntoApp = function (ps, compRef) {
        return tpaStyleService.getCompStyleDataToPassIntoApp(ps, compRef)
    }

    const getNameToFontsKeyMap = function () {
        return tpa.common.styleUtils.getNameToFontsKeyMap()
    }

    const isAppInstalledBy = function (ps, appDefinitionId, filterDemoMode) {
        return installedTpaAppsOnSiteService.isAppInstalledBy(ps, appDefinitionId, filterDemoMode)
    }

    const isAppInstalledOnPage = function (ps, pageId, appDefinitionId, filterDemoMode) {
        return installedTpaAppsOnSiteService.isAppInstalledOnPage(ps, pageId, appDefinitionId, filterDemoMode)
    }

    const isLastAppComp = function (ps, applicationId) {
        const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppId(ps, applicationId)
        return comps && comps.length === 1
    }

    /**
     * @class documentServices.tpa
     */
    const getAppByAppDefId = function (ps, appDefId) {
        return clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
    }

    const getSectionRefByPageId = function (ps, pageId) {
        return tpaComponentService.getSectionRefByPageId(ps, pageId)
    }

    const isContainerContainsPremiumTpa = function (ps, containerPointer) {
        const tpaPremiumComps = getPremiumTpaRecursive(ps, containerPointer)

        return !_.isEmpty(tpaPremiumComps)
    }

    const getPremiumTpaRecursive = function (ps, containerPointers) {
        const premiumTPAs = _(getTpaPointersRecursive(ps, containerPointers))
            .map(function (compPointer) {
                return component.data.get(ps, compPointer)
            })
            .filter(function (tpaCompData) {
                return clientSpecMapService.isPremiumApp(ps, tpaCompData.applicationId)
            })
            .map(function (tpaPremiumCompData) {
                return clientSpecMapService.getAppData(ps, tpaPremiumCompData.applicationId)
            })
            .value()

        return _.uniqBy(premiumTPAs, 'applicationId')
    }

    const getTpaPointersRecursive = function (ps, componentPointers) {
        componentPointers = _.isArray(componentPointers) ? componentPointers : [componentPointers]
        let tpaPointers = []

        _.forEach(componentPointers, function (componentPointer) {
            const compType = component.getType(ps, componentPointer)
            const isTPA = tpaUtils.isTpaByCompType(compType)

            if (isTPA) {
                tpaPointers.push(componentPointer)
            }
            tpaPointers = tpaPointers.concat(component.getTpaChildren(ps, componentPointer))
        })

        return tpaPointers
    }

    const getPremiumTpaChildrenOnPage = function (ps, pagePointer, tpaApplicationId, filterSubComponents) {
        const premiumTpaChildren = getPremiumTpaRecursive(ps, pagePointer)

        if (filterSubComponents) {
            return _.reject(premiumTpaChildren, function (tpaPremiumAppData) {
                if (!tpaApplicationId || tpaApplicationId !== tpaPremiumAppData.applicationId) {
                    return clientSpecMapService.hasSections(ps, tpaPremiumAppData)
                }
            })
        }

        return premiumTpaChildren
    }

    const isMultiSectionInstalled = function (ps, applicationId) {
        return installedTpaAppsOnSiteService.isMultiSectionInstalled(ps, applicationId)
    }

    const closePopupsAndModal = function (ps) {
        ps.siteAPI.removeAllPopups()
        ps.siteAPI.removeModal()
    }

    const isTpaByCompType = function (ps, compType) {
        return tpaUtils.isTpaByCompType(compType)
    }

    const getSections = function (ps, applicationId) {
        const appComps = installedTpaAppsOnSiteService.getAllAppCompsByAppId(ps, applicationId)
        return _.filter(appComps, function (appComp) {
            return appComp.type === tpaConstants.DATA_TYPE.TPA_SECTION || appComp.type === tpaConstants.DATA_TYPE.TPA_MULTI_SECTION
        })
    }

    const deleteApp = function (ps, appDeletionData, applicationId, onSuccess, onError) {
        return tpaDeleteService.actualDeleteApps(ps, appDeletionData, applicationId).then(onSuccess, () => {
            onError({error: 'error when deleting app'})
        })
    }

    const getTPAAppDeleteInteractionParams = (ps, applicationId) => {
        const appDefinitionId = _.get(clientSpecMapService.getAppData(ps, applicationId), 'appDefinitionId')
        return {app_id: appDefinitionId}
    }

    const notifyBeforeApplicationDelete = function (ps, applicationId) {
        const appsPagesToDelete = tpaDeleteService.notifyAppsToDelete(ps, applicationId, true)
        ps.setOperationsQueue.asyncPreDataManipulationComplete(appsPagesToDelete)
    }

    const isAppPermissionsIsRevoked = function (ps, appData) {
        return clientSpecMapService.isAppPermissionsIsRevoked(appData, platformStateService.getAppsState(ps))
    }

    const fixEcomIfNeeded = function (ps, pageToAddRef) {
        const eComAppDefId = '1380b703-ce81-ff05-f115-39571d94dfcd'
        const eComData = clientSpecMapService.getAppDataByAppDefinitionId(ps, eComAppDefId)

        if (eComData && installedTpaAppsOnSiteService.isAppInstalledBy(ps, eComAppDefId)) {
            const eComPagesData = ps.pointers.data.getDataItemsWithPredicate({tpaApplicationId: eComData.applicationId}, 'masterPage')
            const eComPages = eComPagesData.map(ps.dal.get)
            const clientSpecMapPages = clientSpecMapService.getAppSections(undefined, eComData)

            const pageIdsFromCSM = _(clientSpecMapPages).map('appPage').map('id').value()
            const pageIdFromEComPagesData = _.map(eComPages, 'tpaPageId')

            const mainSectionWidgetData = clientSpecMapService.getMainSectionWidgetData(undefined, eComData)
            const mainSectionId = mainSectionWidgetData.appPage.id
            let intersection, subPageIdsFromCSM

            const mainSectionInstalled = _.some(eComPages, function (eComPage) {
                return _.startsWith(eComPage.tpaPageId, mainSectionId)
            })

            if (mainSectionInstalled) {
                subPageIdsFromCSM = _.reject(pageIdsFromCSM, function (id) {
                    return _.startsWith(id, mainSectionId)
                })
                const subPageIdFromEComPagesData = _.reject(pageIdFromEComPagesData, function (id) {
                    return _.startsWith(id, mainSectionId)
                })
                intersection = _.intersection(subPageIdsFromCSM, subPageIdFromEComPagesData)
            }

            if (mainSectionInstalled) {
                if (!_.isEqual(intersection, subPageIdsFromCSM)) {
                    tpaComponentCommonService.addHiddenPages(ps, eComData)
                }
            } else if (!_.isEqual(intersection, subPageIdsFromCSM) || _.size(eComPages) === 0) {
                tpaComponentService.provisionSection(ps, pageToAddRef, eComAppDefId)
            }
        }
    }

    const fixProGallery = function (ps) {
        const proGalleryAppDefId = '14271d6f-ba62-d045-549b-ab972ae1f70e'
        const proGalleryData = clientSpecMapService.getAppDataByAppDefinitionId(ps, proGalleryAppDefId)
        if (proGalleryData && installedTpaAppsOnSiteService.isAppInstalledBy(ps, proGalleryAppDefId)) {
            const proGalleryPage = ps.pointers.data.getDataItemWithPredicate({tpaApplicationId: proGalleryData.applicationId}, 'masterPage')

            if (!proGalleryPage) {
                tpaComponentCommonService.addHiddenPages(ps, proGalleryData)
            }
        }
    }

    const fixPageUriSEOIfNeeded = function (ps) {
        const pagesIds = page.getPageIdList(ps, false, false)
        const pagesWithoutPageUriSEO = _(pagesIds)
            .map(function (pageId) {
                return page.data.get(ps, pageId)
            })
            .filter('tpaApplicationId')
            .reject('pageUriSEO')
            .value()

        _.forEach(pagesWithoutPageUriSEO, function (pageData) {
            const appData = clientSpecMapService.getAppData(ps, pageData.tpaApplicationId)
            const widgetData = getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, pageData.tpaPageId)
            if (widgetData) {
                const pageUriSEO = tpaComponentCommonService.getPageUriSEO(ps, _.get(widgetData, 'appPage.name'))
                page.data.set(ps, pageData.id, {pageUriSEO})
            }
        })
    }

    const areDocumentServicesHandlersReady = function () {
        return _dsHandlersReady
    }

    const markDocumentServicesHandlersAreReady = function (isDsHandlersInit) {
        _dsHandlersReady = isDsHandlersInit
    }

    const isPageMarkedAsHideFromMenu = function (ps, applicationId, tpaPageId) {
        const appData = getAppData(ps, applicationId)
        return tpa.common.utils.isPageMarkedAsHideFromMenu(appData, tpaPageId)
    }

    const sendBIEvent = function (ps, msg, origin) {
        tpaUtils.sendBIEvent(ps, msg, origin)
    }

    function addOriginCompIdForTpaWidget(compStructure) {
        if (compStructure.componentType === tpaConstants.COMP_TYPES.TPA_WIDGET) {
            compStructure.originCompId = compStructure.id
        }
    }

    function addOriginCompId(container) {
        _.forEach(container.components, function (comp) {
            addOriginCompIdForTpaWidget(comp)
            addOriginCompId(comp)
        })
    }

    const addOriginCompIdToWidgetsOnPage = function (ps, serializedPage) {
        addOriginCompId(serializedPage)
    }

    const getCompToAddWithOptionalCustomIdRef = function (ps, containerReference, options) {
        return component.getComponentToAddRef(ps, containerReference, options, _.get(options, 'optionalCustomId'))
    }

    const onProvisionSucceeded = function (ps, appData, applicationId, callback) {
        clientSpecMapService.registerAppData(ps, appData)

        const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppId(ps, applicationId)
        tpaComponentService.refreshApp(ps, comps)

        if (callback) {
            callback()
        }
    }

    const hasEditorPlatformPart = function (ps, appData) {
        return clientSpecMapService.hasEditorPlatformPart(appData)
    }

    const setComponentDefinitionModifier = (ps, modifierFn) => {
        hooks.unregisterHooks(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER)
        if (_.isFunction(modifierFn)) {
            hooks.registerHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, (compDefinition, containerRef) => {
                const modified = modifierFn({
                    containerRef,
                    layout: compDefinition.layout,
                    layoutResponsive: compDefinition.layoutResponsive,
                    style: compDefinition.style,
                    componentType: compDefinition.componentType,
                    appDefinitionId: _.get(compDefinition, 'data.appDefinitionId') || '',
                    widgetId: _.get(compDefinition, 'data.widgetId') || '',
                    skin: compDefinition.skin
                })

                _.assign(compDefinition, {
                    ...(modified.layout ? {layout: modified.layout} : {}),
                    ...(modified.layoutResponsive ? {layoutResponsive: modified.layoutResponsive} : {}),
                    ...(modified.style ? {style: modified.style} : {}),
                    skin: modified.skin || compDefinition.skin
                })
                _.forEach(['breakpoints', 'variants', 'layouts', 'scopedLayouts'], key => {
                    if (modified[key]) {
                        compDefinition[key] = modified[key]
                    }
                })
                _.assign(containerRef, {...(modified.containerRef ? modified.containerRef : {})})
            })
        }
    }

    const setDefaultSectionSkin = (ps, defaultSkin) => {
        _.set(ps, 'runtimeConfig.tpa.section.defaults.skin', defaultSkin)
    }

    const setDefaultIsContainableState = (ps, isContainable) => {
        _.set(ps, 'runtimeConfig.tpa.section.defaults.isContainable', isContainable)
    }

    const api = {
        initialize,
        /**
         *
         * @return if a component type is a tpa
         */
        isTpaByCompType,

        /**
         *set deviceType in tpa url
         */
        setDeviceType,

        addApp: tpaComponentService.addAppWrapper,
        getCompToAddWithOptionalCustomIdRef,
        onProvisionSucceeded,
        /**
         * @return if a page hideFromMenu flag is true in the client spec map
         */
        isPageMarkedAsHideFromMenu,

        /**
         * return if the container contains at least one premium tpa app
         *
         * @param {Object} containerPointer - container reference
         * @return return if the container contains at least on premium tpa app
         * @example documentServices.tpa.isContainerContainsPremiumTpa(containerPointer)
         */
        isContainerContainsPremiumTpa,

        /**
         * return all premium TPA child components of a given component ref.
         *
         * @param {Object} containerPointer - container reference
         * @return all container's premium TPA child components
         * @example documentServices.tpa.getPremiumTpaChildren(componentsPointers)
         */
        getPremiumTpaChildren: getPremiumTpaRecursive,

        /**
         * recursively searches for tpa components among the given components and returns their pointers
         *
         * @param {Object} componentPointers - pointers of components
         * @return pointers for all TPA components that were found
         * @example documentServices.tpa.getTpaPointersRecursive(componentPointers)
         */
        getTpaPointersRecursive,

        /**
         * filter out from tpa app premiums comps that are sub components.
         * for example: sub component of hotels section
         *
         * @param {Object} containerPointer - container reference
         * @param tpaApplicationId {String} - tpaApplicationId on page
         * @param filterSubComponents {Boolean} - indicate if to filter sub components
         * @return all container's TPA premium child components filtering out the sub components if required
         * @example documentServices.tpa.getPremiumTpaChildrenOnPage(pagePointer, tpaApplicationId, filterSubComponents)
         */
        getPremiumTpaChildrenOnPage,

        /**
         * close all opened popups and modal
         *
         * @example documentServices.tpa.closePopupsAndModal()
         */
        closePopupsAndModal,

        /**
         * Add widget to the document if it was provisioned (e.g. from My Account) but not installed
         *
         * @param {string} appDefinitionId of the widget being added
         * @param {Object} [options]
         * {String} [widgetId] of the widget being added
         *
         * @example documentServices.tpa.addSystemApp('135aad86-9125-6074-7346-29dc6a3c9bcf',
         * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4' })
         */
        addSystemApp: _.partial(tpaComponentService.addAppWrapper, tpaConstants.TYPE.TPA_WIDGET),
        provisionSystemApp,

        /**
         * Adds originCompId property to all TPA widget comps in page structure
         *
         * @param {Object} serializedPage the page structure to add the originCompId to
         *
         * @example
         * const serializedPage = documentServices.page.serializePage(ps, 'pageId', maintainIdentifiers)
         * documentServices.tpa.addOriginCompIdToWidgetsOnPage(serializedPage)
         */
        addOriginCompIdToWidgetsOnPage,
        /*
         * Adds originCompId if comp strucre is tpaWidget
         *
         * @param {Object} serializedPage the page structure to add the originCompId to
         *
         */
        addOriginCompIdForTpaWidget,

        /**
         * widget
         * @member documentServices.tpa.widget
         * @namespace documentServices.tpa.widget
         */
        widget: {
            /**
             * Add widget to the document
             * For displaying the new widget refresh the site
             *
             * @param {string} appDefinitionId of the widget being added
             * @param {Object} [options]
             * {String} [widgetId] of the widget being added
             * {Object} [Layout] object describes the app width, height, x and y location - if no layout is given a default one will be taken
             * {String} [pageId] Wix pageId that the widget should be added to
             * @example documentServices.tpa.widget.add('135aad86-9125-6074-7346-29dc6a3c9bcf',
             * { widgetId: '135aae78-42c9-63b5-d09a-77233cebc1c4', pageId: 'mainPage', layout: {"width": 300, "height": 200, "x": 200, "y": 200 } })
             */
            add: _.partial(tpaComponentService.addAppWrapper, tpaConstants.TYPE.TPA_WIDGET),
            provisionWidget: tpaComponentService.provisionWidget,

            /**
             * Removes a tpa widget from a given compId.
             * @param {Object} compPointer of the widget
             *
             * @example
             * documentServices.tpa.widget.delete(compPointer, onSuccess, onError)
             */
            delete: tpaWidgetService.deleteWidget,
            notifyBeforeDelete: tpaWidgetService.notifyBeforeWidgetDelete,
            getTPAWidgetDeleteInteractionParams: tpaWidgetService.getTPAWidgetDeleteInteractionParams,

            /**
             * Returns an array with the pages that will be removed when the widget is removed
             * @param {Object} compPointer of the widget

             * @example
             * documentServices.tpa.widget.getPagesToBeDeleted(compPointer)
             */
            getPagesToBeDeleted: tpaWidgetService.getPagesToBeRemovedWithWidget,

            /**
             * Duplicates the given widget into the given page.
             *
             * @param {object} compPointer of the Widget id in query
             * @param {string} pageId of the page to paste in
             * @example
             * documentServices.tpa.widget.duplicate(compPointer,'c1qpo')
             */
            duplicate: duplicateWidget,
            cloneTpaCompData: componentAppDataClone.cloneTpaCompData,
            addAndCloneTpaCompData: componentAppDataClone.addAndCloneTpaCompData
        },

        /**
         * section
         * @member documentServices.tpa.section
         * @namespace documentServices.tpa.section
         */
        section: {
            /**
             * Add section to the document and navigate to it
             *
             * @param {string} appDefinitionId of the widget being added
             * @param {Object} [options]
             * {String} [widgetId] of the multi multi section being added
             * @return an object contains the new added page id that contains the new section and the section id
             * @example
             * documentServices.tpa.section.add("135aad86-9125-6074-7346-29dc6a3c9bcf")
             */
            add: tpaComponentService.addSection,
            provisionSection: tpaComponentService.provisionSection,

            /**
             * Add section to the document and navigate to it
             *
             * @param {string} appDefinitionId of the widget being added
             * @param {Object} options
             * @param {String} pageId of the multi multi section being added
             * @param {String} [title] of the new page
             * @example
             * const options = {
             *   pageId: 'tpaPageId',
             *   title: 'title'
             * }
             * documentServices.tpa.section.addMultiSection('appDef', options)
             */
            addMultiSection: tpaComponentService.addMultiSection,
            provisionMultiSection: tpaComponentService.provisionMultiSection,

            /**
             * Add sub section to the document
             *
             * @param {string} appDefinitionId of the section being added
             * @param {Object} options
             * @param {String} pageId of the sub section being added
             * @param {String} [title] of the new page
             * @example
             * const options = {
             *   pageId: 'tpaPageId',
             *   title: 'title'
             * }
             * documentServices.tpa.section.addSubSection('appDef', options)
             */
            addSubSection: tpaComponentService.addSubSection,

            /**
             * Removes a section for a given page id.
             *
             * @param {string} pageId of the tpa section
             * @param {Object} [options]
             * @example
             * documentServices.tpa.deleteSection("k66c")
             */
            delete: tpaSectionService.actualDeleteSection,
            notifyBeforeDelete: tpaSectionService.notifyDeleteSection,
            getTPAWidgetDeleteInteractionParams: tpaSectionService.getTPAWidgetDeleteInteractionParams,

            /**
             * check if the section is already installed in the site
             *
             * @param {string} appDefinitionId of the app
             *
             * @example
             * documentServices.tpa.section.alreadyInstalled("135aad86-9125-6074-7346-29dc6a3c9bcf")
             */
            alreadyInstalled: sectionAlreadyInstalled,

            /**
             * checks if a tpa section page is already installed in the site
             *
             * @param {Number} applicationId of the app
             * @param {string} tpaPageId of the section
             *
             * @example
             * documentServices.tpa.section.isSectionInstalledByTpaPageId('1234', 'product_page')
             */
            isSectionInstalledByTpaPageId: installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId,

            /**
             * check if the a given page id has a section installed on it.
             *
             * @param {string} pageId
             *
             * @example
             * documentServices.tpa.section.getPageData("pageId")
             */
            getPageData,

            /**
             * Gets the section ref on a given page
             * @param {string} pageId
             * @return the section on the given page or undefined if none exists
             */
            getSectionRefByPageId,

            /**
             * defaults
             * @member documentServices.tpa.section.defaults
             * @namespace documentServices.tpa.section.defaults
             */
            defaults: {
                /**
                 * skin
                 * @member documentServices.tpa.section.defaults.skin
                 * @namespace documentServices.tpa.section.defaults.skin
                 */
                skin: {
                    /**
                     * Sets the default skin a section will be created with
                     *
                     * @param {string} skin
                     *
                     * @example
                     * documentServices.tpa.section.defaults.skin.set("wysiwyg.viewer.skins.page.TransparentPageSkin")
                     */
                    set: setDefaultSectionSkin
                },
                isContainable: {
                    /**
                     * Controls whether the TPA section should be containable or not
                     *
                     * @param {boolean} isContainable
                     *
                     * @example
                     * documentServices.tpa.section.defaults.isContainable.set(true)
                     */
                    set: setDefaultIsContainableState
                }
            }
        },

        /**
         * app
         * @member documentServices.tpa.app
         * @namespace documentServices.tpa.app
         */
        app: {
            /**
             * installs an application if it doesn't exist yet
             *
             * @param {string} appDefinitionId of the application
             * @param [options] object which can contain 'callback', 'widgetId', 'showPageAddedPanel', 'x', 'y', 'width', 'height', 'shouldNavigate', 'pageId', 'containerRef'
             *
             * @example
             * documentServices.tpa.app.add('1363adbc-c783-b1e0-d8ef-4a661300ac8c', {})
             */
            provisionApp: tpaAddService.provisionApp,
            add: tpaAddService.addApp,
            getAddTPAAppInteractionParams: tpaAddService.getAddTPAAppInteractionParams,
            getAddTPAInteractionParams: tpaAddService.getAddTPAInteractionParams,
            /**
             * Gets the app data by application id
             *
             * @param {string} applicationId of the application
             * @return the application data from the client spec map
             *
             * @example
             * documentServices.tpa.app.getData(16)
             */
            getData: getAppData,

            /**
             * Gets the application data by app definition id
             *
             * @param {string} appDefinitionId of the application in query
             *
             * @example
             * documentServices.tpa.app.getDataByAppDefId('1363adbc-c783-b1e0-d8ef-4a661300ac8c')
             * */

            getDataByAppDefId: getAppByAppDefId,

            /**
             * Checks if this application is hybrid
             *
             * @param {string} applicationId of the application in query
             * @example
             * documentServices.tpa.app.isHybrid(16)
             */
            isHybrid: isHybridApp,

            /**
             * Checks if this application is dashboard only
             *
             * @param {string} applicationId of the application in query
             * @example
             * documentServices.tpa.app.isDashboardAppOnly(16)
             */
            isDashboardAppOnly,

            /**
             * Checks if this application was upgraded to premium
             *
             * @param {string} applicationId of the application in query
             * @example
             * documentServices.tpa.app.isPremium(16)
             */
            isPremium: isPremiumApp,

            /**
             * Checks if an application has premium offering
             *
             * @param {string} applicationId of the application in query
             * @example
             * documentServices.tpa.app.hasPremiumOffering(16)
             */
            hasPremiumOffering,

            /**
             * Checks if this appDefinitionId was upgraded to premium
             *
             * @param {string} appDefinitionId of the application in query
             * @example
             * documentServices.tpa.app.isPremium('1380b703-ce81-ff05-f115-39571d94dfcd')
             */
            isPremiumByAppDefinitionId,

            /**
             * Checks if this application has only one component
             *
             * @param {string} applicationId of the application in query
             * @example
             * documentServices.tpa.app.isLastAppComp(16)
             */
            isLastAppComp,

            /**
             * Returns true if and only if the application has been installed in the site
             *
             * @param {string} appDefinitionId of the application
             * @params {boolean} [filterDemoMode] filter out demo mode app
             * @return {boolean} application was installed
             *
             * @example
             * documentServices.tpa.app.isInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c')
             */
            isInstalled: isAppInstalledBy,

            /**
             * Returns widget data from pageId
             *
             * @param {string} applicationId of the application in query
             * @param {string} tpaPageId of the app widget
             * @example
             * documentServices.tpa.getWidgetDataFromTPAPageId('appDefId', 'page_id')
             */
            getWidgetDataFromTPAPageId,

            /**
             * Returns widget data from tpaWidgetId
             *
             * @param {string} applicationId of the application in query
             * @param {string} tpaPageId of the app widget
             * @example
             * documentServices.tpa.getWidgetDataFromTPAWidgetId('appDefId', 'tpa_widget_id')
             */
            getWidgetDataFromTPAWidgetId,

            /**
             * Register a callback to be called when an app is installed
             *
             * @param {string} appDefinitionId of the application
             * @return {function} callback when the app was installed
             *
             * @example
             * documentServices.tpa.app.registerOnAppInstalled('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
             */
            registerOnInstalled: registerOnAppInstalled,

            /**
             * Register a callback to be called when an app is removed
             *
             * @param {string} appDefinitionId of the application
             * @return {function} callback when the app was installed
             *
             * @example
             * documentServices.tpa.app.registerOnAppDeleted('13016589-a9eb-424a-8a69-46cb05ce0b2c', function () {])
             */
            registerOnDeleted: registerOnAppDeleted,

            /**
             * Get all rendered react comp on site by Application Id
             *
             * @param {string|string[]} applicationIds - Id/s of the application/s
             *
             * @example
             * documentServices.tpa.app.getRenderedReactCompsByApplicationId('18')
             * @example
             * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
             */
            getRenderedReactCompsByApplicationId: installedTpaAppsOnSiteService.getAllAppCompsByAppId,

            /**
             * Get all comps on site by Application Id
             *
             * @param {string|string[]} applicationIds - Id/s of the application/s
             *
             * @example
             * documentServices.tpa.app.getAllCompsByApplicationId('18')
             * @example
             * documentServices.tpa.app.getRenderedReactCompsByApplicationId(['23', '42'])
             */
            getAllCompsByApplicationId: installedTpaAppsOnSiteService.getAllAppCompsByAppId,

            /**
             * Returns true if and only if a given app has at least one section.
             *
             * @example
             * documentServices.tpa.app.hasSections(appData)
             */
            hasSections: clientSpecMapService.hasSections,

            getDefaultLayout: tpaComponentService.getDefaultLayout,

            /**
             * url
             * @member documentServices.tpa.app.url
             * @namespace documentServices.tpa.app.url
             */
            url: {
                /**
                 * Gets the settings url of the app
                 *
                 * @param {string} applicationId of the application
                 * @param {string} [widgetId] of the application
                 * @return {string} the settings url
                 *
                 * @example
                 * documentServices.tpa.app.url.getSettingsUrl(16, '135aae78-42c9-63b5-d09a-77233cebc1c4', 'comp-1234')
                 */
                getSettingsUrl: tpaSettingsService.getSettingsUrl,

                /**
                 * Gets the settings modal url of the app
                 *
                 * @param {object} urlParams must contain url
                 * @param {string} [compId] compId
                 * @return {string} the settings modal url
                 *
                 * @example
                 * documentServices.tpa.app.url.getSettingsModalUrl({url: 'url'}, 'comp-1234')
                 */
                getSettingsModalUrl: tpaSettingsService.getSettingsModalUrl
            },

            /**
             * Returns this application extensions' widgets
             *
             * @param {Object} appData the application data
             * @example
             * documentServices.tpa.app.getExtensionsWidgets({})
             */
            getExtensionsWidgets,

            /**
             * Returns this application installed dependent apps appData
             *
             * @param {Number} applicationId the main application id
             * @example
             * documentServices.tpa.app.getInstalledDependentAppsData(1234)
             */
            getInstalledDependentAppsData: installedTpaAppsOnSiteService.getInstalledDependentAppsData,

            /**
             * Gets the first application component's page id and comp id. In case the application has a section and
             * a widget - the section's page id anc comp id are returned.
             *
             * @param {string} applicationId of the application in query
             * @param [boolean] useDefaultWidget if true will return the default widget data
             * @example
             * documentServices.tpa.getFirstAppCompPageId("1363adbc-c783-b1e0-d8ef-4a661300ac8c")
             */
            getFirstAppCompPageId,

            refreshApp,
            shouldDeleteWholeApp: tpaSectionService.shouldDeleteWholeApp,
            /**
             * Returns true iff multi section installed (has more than one main section).
             *
             * @param {string} applicationId The application ID of the app
             * @example
             * documentServices.tpa.section.isMultiSectionInstalled("20")
             */
            isMultiSectionInstalled,

            /**
             * Returns the app sections
             *
             * @param {string} applicationId The application ID of the app
             * @example
             * documentServices.tpa.app.getSections("20")
             */
            getSections,

            /**
             * Returns true if app is proviosned on server and false otherwise
             *
             * @param {string} applicationId The application ID of the app
             * @example
             * documentServices.tpa.app.isAppProvisionedOnServer("20")
             */
            isAppProvisionedOnServer: clientSpecMapService.isAppProvisionedOnServer,

            /**
             * Returns true if app's permissions are revoked
             *
             * @param {string} applicationId The application ID of the app
             * @example
             * documentServices.tpa.app.isPermissionsRevoked("20")
             */
            isPermissionsRevoked: isAppPermissionsIsRevoked,

            /**
             * Returns the widget data of the main section
             *
             * @param {string} applicationId The application ID of the app
             * @example
             * documentServices.tpa.app.getMainSectionWidgetData("20")
             */
            getMainSectionWidgetDataFromApplicationId: clientSpecMapService.getMainSectionWidgetDataFromApplicationId,

            /**
             * Returns the widget data of the main section
             *
             * @param {object} appData The data of the app
             * @example
             * documentServices.tpa.app.getMainSectionWidgetData(appData)
             */
            getMainSectionWidgetData: clientSpecMapService.getMainSectionWidgetData,

            /**
             * Returns an array of the sections that should be installed automatically in the correct order
             *
             * @param {Number} applicationId The application ID of the app
             * @example
             * documentServices.tpa.app.getSectionsToAutoInstallWidgetData(applicationId)
             */
            getSectionsToAutoInstallWidgetData: clientSpecMapService.getAppSectionsToInstall,

            /**
             * Returns true if the app has a main section
             *
             * @param {object} appData The data of the app
             * @example
             * documentServices.tpa.app.hasMainSection(appData)
             */
            hasMainSection: clientSpecMapService.hasMainSection,

            /**
             *  Returns true if the app is a super app (an app Developed internally by Wix)
             *  @param {ps} ps
             *  @param {string} compId a component ID the app is from
             */
            isSuperAppByCompId(ps, compId) {
                return clientSpecMapService.isSuperAppByCompId(ps, compId)
            },

            /**
             *  Returns true if the app has platform part
             *  @param {object} appData The data of the app
             */
            hasEditorPlatformPart,

            /**
             * Removes all app TPA components and notify the app platform script
             *
             * @param {string} applicationId
             * @param {Function} [onSuccess]
             * @param {Function} [onError]
             *
             * @example
             * documentServices.tpa.app.delete('1234', ()=> {}, ()=> {})
             */
            delete: deleteApp,
            notifyBeforeApplicationDelete,
            getTPAAppDeleteInteractionParams
        },

        /**
         * change
         * @member documentServices.tpa.change
         * @namespace documentServices.tpa.change
         */
        change: {
            /**
             * Notify the ds the editor mode has changed
             *
             * @param {string} editorMode the editor new mode
             *
             * @example
             * documentServices.tpa.change.editMode('preview')
             */
            editMode: editModeChange,

            /**
             * Notify the ds the site was published
             *
             *
             * @example
             * documentServices.tpa.change.sitePublished()
             */
            siteSaved,

            /**
             * Notify the ds the site was published
             *
             *
             * @example
             * documentServices.tpa.change.sitePublished()
             */
            sitePublished,

            /**
             * Notify the ds the editor switch from mobile to desktop or vice versa
             *
             *
             * @example
             * documentServices.tpa.change.deviceType()
             */
            deviceType: deviceTypeChange,

            /**
             * register
             * @member documentServices.tpa.change.register
             * @namespace documentServices.tpa.change.register
             */
            register: {
                /**
                 * Register for an editor event.
                 * Callback will be called when a custom editor event happened such as "ADI_SETTINGS_PANEL_CLOSED"
                 * event payload will have a type field
                 * @param {function} callback A callback function that will get called when an editor event happened
                 * @example
                 * documentServices.tpa.change.register.editorEventHandler(function({type}) {
                 *    console.log(type)
                 * })
                 */
                editorEventHandler: registerEditorEventHandler,

                /**
                 * Register for component loaded handler.
                 * Callback will be called, with an object containing compPointer property, every time a TPA calls Wix.Performance.applicationLoaded() SDK func.
                 *
                 * @param {function} callback A callback function that will get called once a comp has loaded.
                 * @example
                 * documentServices.tpa.change.register.compLoadedHandler(function(data) {
                 *      console.log(data.compPointer)
                 * })
                 */
                compLoadedHandler: registerCompLoadedHandler,

                /**
                 * Register for component delete handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once a delete comp event happens.
                 */
                deleteCompHandler: registerDeleteCompHandler,
                /**
                 * Register for component disconnect handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once a disconnect comp event happens.
                 */
                disconnectCompHandler: registerDisconnectCompHandler,

                /**
                 * Register for editor mode change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the editor mode has changed.
                 */
                editModeChangeHandler: registerEditModeChangeHandler,

                /**
                 * Register for device type change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the device type has changed.
                 */
                deviceTypeChangeHandler: registerDeviceTypeChangeHandler,

                /**
                 * Register for editor theme change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the editor theme has changed.
                 */
                themeChangeHandler: registerThemeChangeHandler,

                /**
                 * Register for window placement  change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the editor mode has changed.
                 */
                windowPlacementChangedHandler: registerWindowPlacementChangedHandler,

                /**
                 * Register for settings update change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the editor mode has changed.
                 */
                settingsUpdatedHandler: registerSettingsUpdatedHandler,

                /**
                 * Register for set public data change handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the editor mode has changed.
                 */
                registerPublicDataChangedHandler,

                /**
                 * Register for site published handler.
                 *
                 * @param {String} compId of the component
                 * @param {function} handler A callback function that will get called once the site is published
                 */
                sitePublishedHandler: registerSitePublishedHandler,

                /**
                 * Register a handler for site saved event.
                 *
                 * @param {String} compId - id of the component
                 * @param {function} handler A callback function that will get called once the site is saved
                 */
                siteSavedHandler: registerSiteSavedHandler,

                /**
                 * Register a handler for undo & redo events
                 *
                 * @param {String} compId - id of the component
                 * @param {function} handler A callback function that will get called once revision changed
                 */
                historyChangeHandler: registerHistoryChangeHandler
            },

            /**
             * trigger
             * @member documentServices.tpa.change.trigger
             * @namespace documentServices.tpa.change.trigger
             */
            trigger: {
                /**
                 * Notify the ds when an editor event happened
                 * @param {type} the type of the editor event
                 */
                editorEvent: triggerEditorEvent,

                /**
                 * Tell the ds that the window placement was changed
                 * @param  {String} placement of the glued widget
                 */
                windowPlacement: triggerOnWindowPlacementChanged,

                /**
                 * Tell the ds that the setting app triggered an update
                 *
                 * @param {String} applicationId the app application id
                 * @param {String} targetCompId the target comp id or * to broadcast the data to all the app components
                 * @param {Object} message the data to broadcast
                 *
                 * @example
                 * const applicationId = 16
                 * const targetCompIds = 'compId'
                 * const message = {
                 *      data: 'show-all'
                 * }
                 * documentServices.tpa.triggerSettingsUpdated(applicationId, targetCompId, message)
                 */
                settingsUpdated: triggerSettingsUpdated
            }
        },

        getComponentDefinition,

        getSettingsModalParams,

        pending: {
            /**
             * Add a pending app
             * @param appDefId
             * @example
             * documentServices.tpa.pending.add('appDefId')
             */
            add: pendingAppsService.addPendingDashboardApp,
            /**
             * Dismiss a pending app from the meta-site
             * @param appDefId
             * @example
             * documentServices.tpa.pending.dismiss('appDefId')
             */
            dismiss: pendingAppsService.dismiss,
            /**
             * Returns premium pending applications
             *
             * @return {Array} list of pending applications
             *
             * @example
             * documentServices.tpa.pending.getPendingApps()
             */
            getPendingApps: pendingAppsService.getPendingAppsFromSiteMetaData
        },

        preAdd: {
            setComponentDefinitionModifier,
            setComponentLayoutModifier: setComponentDefinitionModifier //deprecated
        },

        /**
         * Returns hybrid pending applications
         *
         * @return {Array} list of pending applications
         * @deprecated
         *
         * @example
         * documentServices.tpa.getPendingApps()
         */
        getPendingApps: pendingAppsService.getPendingApps,

        /**
         * Returns premium pending applications
         *
         * @return {Array} list of pending applications
         * @deprecated
         * @example
         * documentServices.tpa.getPendingApps()
         */
        getPremiumPendingApps: pendingAppsService.getPremiumPendingApps,

        /**
         * AppMarket
         * @member documentServices.tpa.appMarket
         * @namespace documentServices.tpa.appMarket
         */
        appMarket: {
            /**
             * Returns app market data for the given appDefinitionId
             *
             * @param {String} appDefinitionId
             * @returns {Object} app market data
             */
            getData: appMarketService.getAppMarketData,

            /**
             * Returns app market data for the given pageId
             *
             * @param {String} pageId
             * @returns {Object} app market data
             */
            getDataForPage: appMarketService.getAppMarketDataForPage,

            /**
             * Returns app market data for the given appDefinitionId or retrieves it async
             *
             * @param {String} appDefinitionId
             * @returns Promise
             */
            getDataAsync: appMarketService.getAppMarketDataAsync,

            /**
             * Returns app market URL which is its iframe src
             *
             * @param {Object} editorParams Set of parameters from editor
             * @returns String
             * @example
             * documentServices.tpa.appMarket.getUrl({
             *  openAppDefId: '1234',
             *  experiments: 'exp1',
             *  query: 'tag1',
             *  origin: 'http://editor.wix.com',
             *  appDefinitionId: '1234'
             * })
             */
            getUrl: appMarketService.getAppMarketUrl,

            /**
             * Returns app market info URL which is its iframe src
             *
             * @param {Object} editorParams Set of parameters from editor
             * @returns String
             * @example
             * documentServices.tpa.appMarket.getInfoUrl({
             *  openAppDefId: '1234',
             *  experiments: 'exp1',
             *  query: 'tag1',
             *  origin: 'http://editor.wix.com',
             *  appDefinitionId: '1234'
             * })
             */
            getInfoUrl: appMarketService.getAppMarketInfoUrl,

            /**
             * Returns the app info URL, on the reviews tab
             *
             * @param {Object} editorParams Set of parameters from editor:
             * origin (mandatory) ,
             * openMarketOrigin (mandatory),
             * test (optional)
             * @returns String
             * @example
             * documentServices.tpa.appMarket.getAppReviewsUrl({
             *  origin: 'http://editor.wix.com',
             *  openMarketOrigin: 'settings_panel,
             *  tests: 'exp1'
             * })
             */
            getAppReviewsUrl: appMarketService.getAppReviewsUrl,

            /**
             * Returns app market permissions URL which is its iframe src
             *
             * @param {Object} editorParams Set of parameters from editor
             * @returns String
             * @example
             * documentServices.tpa.appMarket.getPermissionsUrl({
             *  openAppDefId: '1234',
             *  experiments: 'exp1',
             *  query: 'tag1',
             *  origin: 'http://editor.wix.com',
             *  appDefinitionId: '1234'
             * })
             */
            getPermissionsUrl: appMarketService.getAppMarketPermissionsUrl,

            /**
             * Returns app market related apps
             * @returns Array
             * @example
             * documentServices.tpa.appMarket.getRelatedApps(function(apps){console.log(apps)})
             */
            getRelatedApps: appMarketService.getRelatedApps,

            /**
             * Returns app market packages with purchase url and currency info for the given appDefinitionId and instanceId
             *
             * @param {String} appDefinitionId
             * @param {String} instanceId
             * @returns Promise
             * @example
             * documentServices.tpa.appMarket.getPackages('appDefId','instanceId')
             */
            getPackages: appMarketService.getPackages
        },

        billing: {
            /**
             * Returns premium apps
             * @returns Array containing appDefinitionIds of upgraded apps
             * @example
             * documentServices.tpa.billing.getPremiumApps(function(appDefIds){console.log(appDefIds)})
             */
            getPremiumApps: appMarketService.getPremiumApps,

            /**
             * Returns the meta site upgrade url
             * @param {Object} url params referralAdditionalInfo
             * @returns String
             * @example
             * documentServices.tpa.billing.getSiteUpgradeUrl({referralAdditionalInfo : 'gfpp'})
             */
            getSiteUpgradeUrl: billingService.getSiteUpgradeUrl,

            /**
             * Gets upgrade to premium url for an app.
             *
             * @function
             * @memberof documentServices.tpa
             * @param {string} applicationId
             * @param {string} vendorProductId
             * @param {string} paymentCycle could be 'YEARLY' or 'MONTHLY', default: 'MONTHLY'
             * @parma {Object} options param like pp_type and referralAdditionalInfo
             * @return {string} upgrade page payment url
             *
             * @example
             * documentServices.tpa.billing.getAppUpgradeUrl(16, 'Premium1', 'MONTHLY', options)
             */
            getAppUpgradeUrl: billingService.getAppUpgradeUrl
        },

        /**
         * style
         * @member documentServices.tpa.style
         * @namespace documentServices.tpa.style
         */
        style: {
            /**
             * Set style param handler for SDK 1.22.0-1.24.0.
             *
             * @param {String} compId the component id
             * @param {Object} data the style param data.
             */
            setParamOldSDK: setStyleParam,

            setStyleParam: tpaStyleService.setStyleParam,

            setCompStyleParam: tpaStyleService.setCompStyleParam,

            setStyleParamData: tpaStyleService.setStyleParamData,

            mapWixParamsToCssValues: tpaStyleService.mapWixParamsToCssValues,

            /**
             * Returns theme and style data to the given component
             *
             * @param {String} component id
             * @returns Object style data
             */
            get: getStyleDataToPassIntoApp,

            /**
             * Returns theme and style data to the given component
             *
             * @param {Object} componentPointer - component reference
             * @returns Object style data
             */
            getByComp: getCompStyleDataToPassIntoApp,

            postBackThemeData: tpaComponentService.postBackThemeData,

            /**
             * Returns Name To FontsKey Map
             *
             * @returns Returns Name To FontsKey Map
             */
            getNameToFontsKeyMap,

            /**
             * Returns a map of tpa colors to site colors
             *
             * @returns Returns Name To FontsKey Map
             */
            getTpaColorsToSiteColors: tpaStyleService.getTpaColorsToSiteColors
        },

        provision: {
            refreshAppSpecMap: provisionService.refreshSpecMap
        },

        /**
         * Tpa constants
         *
         * Constants Definitions for tpa components:
         * TYPE: Describe the tpa type constants: TPASection, TPAMultiSection and etc
         * @example documentServices.tpa.constants.TYPE.TPA_WIDGET
         *
         * COMP_TYPES: Describes tpa component types: 'wysiwyg.viewer.components.tpapps.TPASection', 'wysiwyg.viewer.components.tpapps.TPAMultiSection'
         * @example documentServices.tpa.constants.COMP_TYPE.TPA_WIDGET
         *
         * TPA_COMP_TYPES: Deprecated - Describes tpa component types: 'tpa.viewer.components.tpapps.TPASection', 'tpa.viewer.components.tpapps.TPAMultiSection'
         * @example documentServices.tpa.constants.TPA_COMP_TYPE.TPA_WIDGET
         *
         * SKINS: Describes tpa component skins: 'wysiwyg.viewer.skins.TPASectionSkin', 'wysiwyg.viewer.skins.TPAWidgetSkin'
         * @example documentServices.tpa.constants.SKINS.TPA_WIDGET
         *
         */
        constants: tpaConstants,

        /**
         * Tpa BI error and events constants
         *
         */
        bi: {
            /*
             * TPA Bi errors constants
             *
             * @example documentServices.tpa.bi.errors.EVENT_TYPE_NOT_SUPPORTED
             */
            errors,

            /*
             * Send a BI event for a message to the SDK endpoint from a specified origin
             *
             * @example documentServices.tpa.bi.sendBIEvent(msg, 'editor')
             */
            sendBIEvent
        },

        comp: {
            /*
             * resize a comp
             *
             * @example documentServices.tpa.comp.resize(compPointer, {width: 100, height: 100})
             */
            resize: resizeComponent,

            /*
             * set a comp externalId
             *
             * @example documentServices.tpa.comp.setExternalId(compPointer, '123-456-789')
             */
            setExternalId,

            /*
             * get a comp externalId if one is set
             *
             * @example documentServices.tpa.comp.getExternalId(compPointer)
             */
            getExternalId,

            /*
             * post message back to the comp iframe
             */
            postMessageBackToApp: tpaComponentService.postMessageBackToApp,

            /**
             * Used for checking if a certain component from an app was added to the site
             *
             * @param {String} widgetId of the component
             * @return {Boolean} true if the widget is installed, false otherwise
             * @example documentServices.tpa.comp.isInstalled('135aad86-9125-6074-7346-29dc6a3c9bcf')
             */
            isInstalled: installedTpaAppsOnSiteService.isAppComponentInstalled,

            /**
             * Adds a tpa widget or section to the site. if the comp application doesn't already exist, it will first install the application.
             *
             * @param [options] object which can contain:
             * appDefinitionId (mandatory),
             * componentType (mandatory),
             *  copyStyle,
             *  styleId,
             *  widget - object containing widgetId, allPages, wixPageId
             *  page - object containing title, pageId
             *
             * @example
             * documentServices.tpa.comp.add({
             *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
             *  componentType: 'PAGE',
             *  page: {pageId:'order_history'},
             *  callback: console.log
             *  }
             * })
             *
             * documentServices.tpa.comp.add({
             *  appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
             *  componentType: 'WIDGET',
             *  widget: {widgetId:'grid_gallery'},
             *  callback: console.log
             *  }
             * })
             */
            add: tpaAddService.addComponent,
            provisionComp: tpaAddService.provisionComp
        },

        data: {
            set: tpaDataService.set,
            setMultiple: tpaDataService.setMultiple,
            app: {
                get: tpaDataService.getAppValue,
                getMulti: tpaDataService.getAppValues
            },
            comp: {
                get: tpaDataService.getComponentValue,
                getMulti: tpaDataService.getComponentValues
            },
            getPublicData: tpaDataService.getPublicData,
            remove: tpaDataService.remove,
            SCOPE: tpaDataService.SCOPE,

            // TODO: remove once santa-editor version is deployed
            get: tpaDataService.get,
            getMulti: tpaDataService.getMulti
        },
        page: {
            getSubPages: tpaPageService.getSubPages
        },
        getSiteMap: ps => ps.siteAPI.getSiteMap(),
        __privates: {
            fixEcomIfNeeded,
            areDocumentServicesHandlersReady,
            fixProGallery
        }
    }

    return api
})
