define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/component/component'
], function (_, santaCoreUtils, dataModel, componentStylesAndSkinsAPI, component) {
    'use strict'

    const MIN_GALLERY_HEIGHT = 70
    function beforeLayout(ps, compPointer, newLayout) {
        if (_.isUndefined(newLayout.height)) {
            return
        }
        const newHeight = Math.max(MIN_GALLERY_HEIGHT, newLayout.height)
        const currentLayout = component.layout.get(ps, compPointer)
        const currentHeight = _.get(currentLayout, 'height')
        if (_.isEqual(currentHeight, newHeight) && _.isEqual(currentLayout.width, newLayout.width)) {
            return
        }
        const compProp = dataModel.getPropertiesItem(ps, compPointer)
        const compData = dataModel.getDataItem(ps, compPointer)

        const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
        const heightDiff = (skinExports && skinExports.heightDiff) || 0
        const numberOfRows = santaCoreUtils.matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, compData.items.length)

        const itemHeight = santaCoreUtils.matrixCalculations.getItemHeight(compProp.margin, newHeight, numberOfRows, heightDiff)
        component.properties.update(ps, compPointer, {itemHeight})
    }

    return {
        before: beforeLayout
    }
})
