define([
    'documentServices/siteMetadata/dataManipulation',
    'documentServices/siteMetadata/seo',
    'documentServices/siteMetadata/social',
    'documentServices/siteMetadata/favicon',
    'documentServices/siteMetadata/premiumFeatures',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/siteMetadata/siteName',
    'documentServices/siteMetadata/siteDisplayName',
    'documentServices/siteMetadata/statistics',
    'documentServices/siteMetadata/compatibility',
    'documentServices/siteMetadata/language'
], function (dataManipulation, seo, social, favicon, premiumFeatures, generalInfo, siteName, siteDisplayName, statistics, compatibility, language) {
    'use strict'

    return {
        PROPERTY_NAMES: dataManipulation.PROPERTY_NAMES,
        getProperty: dataManipulation.getProperty,
        setProperty: dataManipulation.setProperty,
        getRevertibleMetaDataInfo: dataManipulation.getRevertibleMetaDataInfo,
        getNonRevertibleMetaDataInfo: dataManipulation.getNonRevertibleMetaDataInfo,

        seo,
        social,
        favicon,
        premiumFeatures,
        generalInfo,
        siteName,
        siteDisplayName,
        statistics,
        compatibility,
        language
    }
})
