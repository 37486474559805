define([
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    '@wix/santa-core-utils'
], function (metaDataUtils, constants, dataModel, coreUtils) {
    'use strict'

    const repeaterType = 'wysiwyg.viewer.components.Repeater'

    function setMovableSidesForRepeaterItems(ps, metaDataValue, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
            return []
        }
        return metaDataValue
    }

    function disableRemovalForRepeaterItems(ps, metaDataValue, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
            return false
        }
        return metaDataValue
    }

    function disableDuplicationOfRepeaterItem(ps, metaDataValue, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
            return false
        }
        return metaDataValue
    }

    function setResizableSidesForRepeaterItems(ps, metaDataValue, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
            const compPropertiesItem = dataModel.getPropertiesItem(ps, parentPointer)
            return compPropertiesItem && compPropertiesItem.alignment === coreUtils.constants.COMP_LAYOUT_OPTIONS.REPEATER.ALIGNMENTS.RIGHT
                ? [constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.LEFT]
                : [constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT]
        }
        return metaDataValue
    }

    return {
        setMovableSidesForRepeaterItems,
        disableRemovalForRepeaterItems,
        disableDuplicationOfRepeaterItem,
        setResizableSidesForRepeaterItems
    }
})
