define(['lodash'], function (_) {
    'use strict'

    function getEditorSessionId(ps) {
        return ps.siteAPI.getEditorSessionId()
    }

    function getEditorWorkingMode(ps) {
        return ps.siteAPI.isMobileView() ? 'mobile' : 'desktop'
    }

    function event(ps, ...args) {
        args[0] = _.defaults({}, args[0], {
            src: 38,
            endpoint: 'editor',
            sampleRatio: 0
        })
        args[1] = _.defaults({}, args[1], {
            esi: getEditorSessionId(ps),
            editor_working_mode: getEditorWorkingMode(ps)
        })

        if (ps.runtimeConfig.viewerName && (args[0].src === 40 || args[0].src === 38)) {
            args[0].viewerName = ps.runtimeConfig.viewerName
            args[0].viewerVersion = ps.runtimeConfig.viewerVersion
        }

        return ps.siteAPI.reportBI(...args)
    }

    function error(ps, ...args) {
        args[0] = _.defaults(args[0], {endpoint: 'trg', evid: 10})
        args[1] = _.defaults(args[1], {dsOrigin: ps.config.origin})
        return event(ps, ...args)
    }

    /**
     * param {*} reportDefCollection map of errors by the errorName
     * param {string} packageName to assign the errors
     * param {boolean} shouldMutate - should mutate the error.reportDefCollection object?
     * @param {{reportDefCollection:*, packageName:string, shouldMutate:boolean}} op
     * @returns {*} mutated/clone of reportDefCollection with the added properties packageName and errorName
     */
    function formatErrorEvents({reportDefCollection, packageName, shouldMutate = false}) {
        reportDefCollection = shouldMutate === true ? reportDefCollection : _.cloneDeep(reportDefCollection)

        _.forOwn(reportDefCollection, (reportDef, errorName) => {
            reportDef.packageName = packageName
            reportDef.errorName = errorName
        })

        return reportDefCollection
    }

    /**
     * adds errorName and packageName fields to errors
     * @param packageName
     * @param reportType
     * @param reportDefCollection
     */
    function register(packageName, reportType, reportDefCollection) {
        if (reportType === 'error') {
            formatErrorEvents({packageName, reportDefCollection, shouldMutate: true})
        }
    }

    return {
        event,
        error,
        formatErrorEvents,
        register
    }
})
