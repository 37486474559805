import _ from 'lodash'

function versionGreaterEqual(versionA, versionB) {
    const firstNonEqualDiff =
        _(versionA)
            .split('.')
            // @ts-ignore
            .zipWith(versionB.split('.'), (a, b) => (parseInt(a, 10) || 0) - (parseInt(b, 10) || 0))
            .find(n => n !== 0) || 0

    return firstNonEqualDiff >= 0
}

export default {
    versionGreaterEqual
}
