import _ from 'lodash'
import PropTypes from 'prop-types'
import coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {touchMediaZoomUtils} = coreUtils
const LOW_RES_IMAGE_SCALE = 0.25

/**
 * @class components.TouchMediaZoomItem
 * @extends {core.skinBasedComp}
 */
const touchMediaZoomItem = {
    displayName: 'TouchMediaZoomItem',
    mixins: [componentsCore.mixins.skinBasedComp],
    propTypes: {
        itemToScreenRatio: PropTypes.number.isRequired,
        itemLeft: PropTypes.number.isRequired,
        imageData: PropTypes.object.isRequired,
        clientData: PropTypes.object.isRequired,
        showInfo: PropTypes.bool.isRequired,
        firstRender: PropTypes.bool.isRequired,
        link: PropTypes.object,
        screenSize: PropTypes.object
    },

    getTitle() {
        const title = _.get(this.props.imageData, 'title')
        return {
            className: this.classSet({hidden: !title}),
            children: title
        }
    },

    getDescription() {
        const description = _.get(this.props.imageData, 'description')
        return {
            className: this.classSet({hidden: !description}),
            children: description
        }
    },

    getLink() {
        return _.assign({}, this.props.link, {
            className: this.classSet({hidden: !this.props.link})
        })
    },

    hasInfo() {
        return this.props.imageData.title || this.props.imageData.description || this.props.link
    },

    isImageFullWidth() {
        return _.has(this.props.screenSize, 'width') ? this.props.screenSize <= _.get(this.props.imageApiFastData, 'css.img.width') : true
    },

    getImageProps(imageData, scale) {
        if (this.props.firstRender) {
            return {
                'data-imgdata': JSON.stringify(_.pick(imageData, ['width', 'height', 'uri', 'quality'])),
                'data-imgscale': scale || 1
            }
        }
        const imageApiData = touchMediaZoomUtils.getImageApiData(imageData, this.props.clientData, scale || 1)
        return {src: imageApiData.uri, style: imageApiData.css.img}
    },

    getSkinProperties() {
        const fastImageProps = _.assign(this.getImageProps(this.props.imageData, LOW_RES_IMAGE_SCALE), {className: this.classSet({fast: true})})

        const imageProps = _.assign(this.getImageProps(this.props.imageData), {className: this.classSet({heavy: true})})

        const screenWidthStyle = _.has(this.props.screenSize, 'width') ? {width: `${this.props.screenSize.width}px`} : {}

        const itemWidthStyle = _.has(this.props.screenSize, 'width') ? {width: `${this.props.screenSize.width * this.props.itemToScreenRatio}px`} : {}

        return {
            '': {
                className: this.classSet({parallax: this.isImageFullWidth()}),
                style: _.assign({}, itemWidthStyle, {
                    left: `${this.props.itemLeft}%`
                }),
                'data-firstrender': this.props.firstRender
            },
            imageContainer: {
                style: screenWidthStyle
            },
            hirRes: imageProps,
            lowRes: fastImageProps,
            info: {
                className: this.classSet({hidden: !this.props.showInfo || !this.hasInfo()}),
                style: screenWidthStyle
            },
            description: this.getDescription(),
            title: this.getTitle(),
            link: this.getLink()
        }
    }
}

export default touchMediaZoomItem
