define([
    'documentServices/constants/constants',
    'documentServices/structure/siteGapMap',
    'documentServices/structure/utils/layoutSettingsUtils',
    'experiment'
], function (consts, siteGapMap, layoutSettingsUtils, experiment) {
    'use strict'

    function canBeFixedPosition(ps, compPointer) {
        return !ps.pointers.components.isMobile(compPointer)
    }

    return {
        resizableSides: [consts.RESIZE_SIDES.BOTTOM],
        moveDirections(ps) {
            const masterPageLayoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
            if (masterPageLayoutSettings.useDesktopSectionsLayout) {
                return []
            }

            if (experiment.isOpen('sv_zeroGapsThreshold')) {
                return [consts.MOVE_DIRECTIONS.VERTICAL]
            }
            const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
            return initialSiteGapMap.aboveFooter >= consts.SITE_SEGMENTS_GAP_THRESHOLD ? [consts.MOVE_DIRECTIONS.VERTICAL] : []
        },
        canBeFixedPosition,
        isVisible(ps, compPointer) {
            const allowShowingFixedComponents = ps.dal.get(ps.pointers.general.getRenderFlag('allowShowingFixedComponents'))
            const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
            return allowShowingFixedComponents || !compLayout.fixedPosition
        },
        removable: false,
        duplicatable: false,
        containable: false,
        fullWidth: true,
        styleCanBeApplied(ps) {
            const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
            return ps.dal.get(isStudioUserPointer)
        },
        hiddenable: false,
        collapsible: false,
        mobileConversionConfig: {
            preserveAspectRatio: false,
            siteSegmentRole: 'SITE_FOOTER',
            category: 'siteSegments'
        }
    }
})
