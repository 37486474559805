import skinsMap from './util/skinsMap'
import santaComponents from '@wix/santa-components'
import params from './util/params'

skinsMap.addBatch(santaComponents.skinsJson)

export default {
    skinsMap,
    skinsRenderer: santaComponents.utils.skinsRenderer,
    registerRenderPlugin: santaComponents.utils.skinsRenderer.registerRenderPlugin,
    params
}
