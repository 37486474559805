import type {PS, SetOperationsQueue} from '@wix/document-services-types'
import type {PublicMethodDefinition} from './publicMethods'

const getMethodReturnValue = (ps: PS, method: PublicMethodDefinition, args: any[]): {hasReturnValue: boolean; returnValue: any} => {
    if (method.getReturnValue) {
        return {
            hasReturnValue: true,
            returnValue: method.getReturnValue(ps, ...args)
        }
    }
    return {
        hasReturnValue: false,
        returnValue: null
    }
}

const createPromiseFromAsyncPartOfPublicMethod = (ps: PS, method: PublicMethodDefinition, args: any[]): Promise<any> =>
    new Promise((resolve, reject) => {
        const completeFunction = (data: any, error?: any) => {
            if (error) {
                reject(error)
            } else {
                resolve(data)
            }
        }
        const augmentedSoq: SetOperationsQueue = Object.create(ps.setOperationsQueue)
        augmentedSoq.asyncPreDataManipulationComplete = completeFunction
        const augmentedPs: PS = {
            ...ps,
            setOperationsQueue: augmentedSoq
        }
        method.asyncPreDataManipulation?.(augmentedPs, ...args)
    })

export {getMethodReturnValue, createPromiseFromAsyncPartOfPublicMethod}
