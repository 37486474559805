import {DAL, DmApis, Extension, IndexKey, pointerUtils} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../types'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {MASTER_PAGE_ID, PAGE_DATA_TYPES, VIEW_MODES, VIEWER_DATA_TYPES} from '../constants/constants'
import {
    dataRefTypes,
    getOverridePrefix,
    getPointerWithoutFallbacksFromPointer,
    getRefPointerType,
    isRefPointer,
    structureRefTypes
} from '../utils/refStructureUtils'
import {createItemGetter} from './data'

const {getPointer} = pointerUtils

const DISPLAYED_ONLY_TYPE_CANDIDATE = _.invert(VIEWER_DATA_TYPES)

const DATA_TYPES_TO_OVERRIDE_GETTERS = {
    ...VIEW_MODES,
    ...PAGE_DATA_TYPES
}

const REFERRED_DATA_TYPES = [...structureRefTypes, ...dataRefTypes]

const NO_MATCH: string[] = []
const ALL_INDEX_KEY = 'all'
const ALL_INDEX_KEY_MATCH = [ALL_INDEX_KEY]

const createExtension = ({viewerManager}: CreateViewerExtensionArgument): Extension => {
    const getter = ({get}: DAL, pointer: Pointer) => {
        if (pointer.noRefFallbacks) {
            return get(pointer)
        }

        if (isRefPointer(pointer)) {
            const newPointer = getRefPointerType(pointer)
            return viewerManager.dal.get(newPointer) || get(pointer)
        }

        const value = get(pointer)

        if (_.isUndefined(value) && DISPLAYED_ONLY_TYPE_CANDIDATE[pointer.type]) {
            const newPointer = getRefPointerType(pointer)
            return viewerManager.dal.get(newPointer)
        }

        return value
    }

    const viewerManagerGetter = ({}, pointer: Pointer) => viewerManager.dal.get(pointer)

    const createGetters = () => {
        const refDataGetters = _(REFERRED_DATA_TYPES)
            .keyBy()
            .mapValues(() => viewerManagerGetter)
            .value()
        const overridenGetters = _.mapValues(DATA_TYPES_TO_OVERRIDE_GETTERS, () => getter)

        return {
            ...refDataGetters,
            ...overridenGetters
        }
    }

    function getOverridesByType(dal: DAL, compPtr: Pointer, dataType: string, queryFilter: IndexKey) {
        const overridePrefix = getOverridePrefix(compPtr.id)
        const overrides = dal.query(dataType, queryFilter, item => _.startsWith(item.id, overridePrefix))
        return _(overrides)
            .values()
            .map(({id, metaData}) => createItemGetter(dataType)(id, _.get(metaData, ['pageId'], MASTER_PAGE_ID)))
            .value()
    }

    const getReferredStructurePointers = (dal: DAL): any => ({
        getPointerWithoutFallbacks: getPointerWithoutFallbacksFromPointer,
        getAllOverrides(compPtr: Pointer) {
            // @ts-ignore
            const queryFilter = dal.queryFilterGetters.overridesFilterFactory(ALL_INDEX_KEY)
            return _.reduce(VIEWER_DATA_TYPES, (result, dataType) => _.concat(result, getOverridesByType(dal, compPtr, dataType, queryFilter)), [] as Pointer[])
        },
        getConnectionOverrides(compPtr: Pointer): Pointer[] {
            // @ts-ignore
            const queryFilter = dal.queryFilterGetters.overridesFilterFactory(ALL_INDEX_KEY)
            return getOverridesByType(dal, compPtr, VIEWER_DATA_TYPES.connections, queryFilter)
        },
        getGhostRefComponents(compPrtId: string) {
            const pointer = {
                type: 'ghostRefComps',
                id: compPrtId
            }
            return viewerManager.dal.get(pointer)
        },
        getInternallyReferredComponents(compPtr: Pointer) {
            const pointer = getPointer(compPtr.id, 'internalyReferredComps')
            return viewerManager.dal.get(pointer)
        },
        getDisplayedConnectionOverrides(compId: string) {
            const pointer = getPointer(compId, 'displayedConnectionOverrides')
            return viewerManager.dal.get(pointer)
        },
        getRemoteOverrides(rootCompId: string, innerRefHostId: string) {
            const pointer = getPointer(rootCompId, 'remoteOverrides', [innerRefHostId])
            return viewerManager.dal.get(pointer)
        }
    })

    const createPointersMethods = ({dal}: DmApis) => ({
        referredStructure: getReferredStructurePointers(dal),
        full: {
            referredStructure: getReferredStructurePointers(dal)
        }
    })

    const createFilters = () => ({
        overridesFilterFactory: (namespace: string, value: any): string[] => {
            const id = value?.id
            if (id?.includes?.('_r_')) {
                return ALL_INDEX_KEY_MATCH
            }
            return NO_MATCH
        }
    })

    return {
        name: 'refComponents',
        // @ts-ignore
        createGetters,
        createPointersMethods,
        createFilters
    }
}

export {createExtension}
