define([], function () {
    'use strict'

    const VERTICAL_LINE_MIN_WIDTH = 25

    function pluginFn(siteAPI, compLayoutRelativeToStructure) {
        const pluginResult = {}

        if (compLayoutRelativeToStructure.width < VERTICAL_LINE_MIN_WIDTH) {
            pluginResult.x = compLayoutRelativeToStructure.x - VERTICAL_LINE_MIN_WIDTH / 2 // eslint-disable-line no-mixed-operators
            pluginResult.width = VERTICAL_LINE_MIN_WIDTH
        }

        return pluginResult
    }

    return pluginFn
})
