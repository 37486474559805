define(['documentServices/documentMode/documentMode'], function (documentMode) {
    'use strict'

    function removeComponentHook(privateServices, compRef) {
        const previewState = documentMode.getComponentPreviewState(privateServices) || {}
        if (previewState[compRef.id]) {
            documentMode.setComponentPreviewState(privateServices, compRef.id, null)
        }
    }

    return {
        removeComponentHook
    }
})
