define([
    'lodash',
    'documentServices/appStudio/widgetConfigs/appWidgetDefinition.json',
    'documentServices/appStudio/widgetConfigs/responsiveWidgetOverrides.json'
], function (_, appWidgetDefinition, responsiveWidgetOverrides) {
    const pageStructure = {
        componentType: 'mobile.core.components.Page',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
        style: {
            compId: '',
            type: 'ComponentStyle',
            componentClassName: '',
            pageId: '',
            skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
            style: {
                properties: {
                    'alpha-bg': '1',
                    bg: 'color_11'
                },
                propertiesSource: {
                    'alpha-bg': 'value',
                    bg: 'theme'
                },
                groups: {}
            },
            styleType: 'custom'
        },
        layouts: {
            type: 'SingleLayoutData',
            componentLayout: {
                type: 'ComponentLayout',
                height: {
                    type: 'auto'
                }
            },
            itemLayout: {},
            containerLayout: {
                type: 'GridContainerLayout',
                rows: [
                    {
                        type: 'auto'
                    }
                ],
                columns: [
                    {
                        type: 'MinMaxSize',
                        min: {
                            type: 'px',
                            value: 0
                        },
                        max: {
                            type: 'fr',
                            value: 1
                        }
                    }
                ]
            }
        }
    }

    const stageContainerStructure = {
        componentType: 'mobile.core.components.Container',
        type: 'Container',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        components: [],
        layouts: {
            type: 'SingleLayoutData',
            componentLayout: {
                minHeight: {
                    type: 'px',
                    value: 500
                },
                hidden: false,
                height: {
                    type: 'auto'
                },
                type: 'ComponentLayout',
                width: {
                    type: 'auto'
                },
                minWidth: {
                    type: 'px',
                    value: 0
                }
            },
            containerLayout: {
                type: 'GridContainerLayout',
                columns: [
                    {
                        type: 'fr',
                        value: 1
                    }
                ],
                rows: [
                    {
                        type: 'fr',
                        value: 1
                    }
                ]
            },
            itemLayout: {
                alignSelf: 'stretch',
                margins: {
                    left: {
                        type: 'px',
                        value: 0
                    },
                    right: {
                        type: 'px',
                        value: 0
                    },
                    top: {
                        type: 'px',
                        value: 0
                    },
                    bottom: {
                        type: 'px',
                        value: 0
                    }
                },
                gridArea: {
                    rowStart: 1,
                    rowEnd: 2,
                    columnStart: 1,
                    columnEnd: 2
                },
                justifySelf: 'stretch',
                type: 'GridItemLayout'
            }
        },
        style: 'c1',
        activeModes: {}
    }

    const getPageStructure = () => pageStructure

    const appWidgetStructure = _.assign({}, appWidgetDefinition, responsiveWidgetOverrides)

    return {
        getPageStructure,
        appWidgetStructure,
        stageContainerStructure
    }
})
