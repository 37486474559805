import tabbable from './tabbable/tabbable'
import keyboardInteractions from './keyboardInteractions/keyboardInteractions'

export default {
    addFocusRingClass: element => {
        element.classList.add('focus-ring')
    },
    getTabbaleElements: tabbable.getTabbableElements,
    keyboardInteractions
}
