define([
    'lodash',
    'documentServices/page/page',
    'documentServices/component/component',
    'documentServices/tpa/services/appInstallationAndDeletionEvents',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/tpaWidgetService',
    'documentServices/tpa/services/tpaComponentCommonService',
    'documentServices/tpa/services/tpaDeleteService',
    'documentServices/tpa/services/sectionsTranslatedPageTitlesCache',
    'documentServices/tpa/compStructure',
    'documentServices/tpa/constants',
    'documentServices/platform/common/constants'
], function (
    _,
    page,
    component,
    appInstallationAndDeletionEvents,
    installedTpaAppsOnSiteService,
    clientSpecMapService,
    tpaWidgetService,
    tpaComponentCommonService,
    tpaDeleteService,
    sectionsTranslatedPageTitlesCache,
    compStructure,
    tpaConstants,
    constants
) {
    'use strict'

    const getSectionAppPageFrom = function (ps, appData, title) {
        let data = {
            name: appData.appDefinitionName
        }
        _.forEach(appData.widgets, function (widget) {
            if (widget.appPage && widget.appPage.name && !widget.appPage.hidden) {
                data = _.clone(widget.appPage)
                const widgetName = title || widget.appPage.name
                data.name = title || sectionsTranslatedPageTitlesCache.getTitle(appData.appDefinitionId, widget.widgetId) || data.name
                data.pageUriSEO = tpaComponentCommonService.getPageUriSEO(ps, widgetName)
                data = _.merge(data, widget)
            }
        })
        return data
    }

    //multi-multi section
    const addMultiSection = function (ps, pageToAddPointer, options) {
        tpaComponentCommonService.addSectionComponentOptionsIfNeeded(options.widgetData.appPage.id, options)
        const {widgetData, sectionId, appDefinitionId, applicationId, title, requireLogin, isHidden} = options
        widgetData.applicationId = applicationId
        widgetData.name = title || sectionsTranslatedPageTitlesCache.getTitle(appDefinitionId, widgetData.widgetId) || widgetData.appPage.name

        const pageUriSEO = tpaComponentCommonService.getPageUriSEO(ps, title || _.get(options, 'widgetData.appPage.name', ''))

        const serializedPage = compStructure.getMultiSectionStructure(ps, widgetData, sectionId, pageUriSEO, requireLogin, isHidden)

        const sectionPointer = tpaComponentCommonService.addPageAndSection(
            ps,
            pageToAddPointer,
            sectionId,
            applicationId,
            appDefinitionId,
            widgetData,
            serializedPage,
            widgetData.widgetId || null,
            tpaConstants.COMP_TYPES.TPA_SECTION,
            tpaConstants.DATA_TYPE.TPA_SECTION,
            options
        )

        const addedCompsRef = [pageToAddPointer, sectionPointer]

        ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
            invokeSectionCallback(options, pageToAddPointer, sectionId, true, addedCompsRef)
        })
    }

    const addSectionAfterProvision = function (ps, pageToAddPointer, options = {}, appData, onSuccess, onError, waitForSOQ = true) {
        if (!canAddSection(ps, appData)) {
            onError(ps, new Error('section already installed'))
            return
        }
        let addedCompsRef = [pageToAddPointer]
        const {sectionId} = options
        const mainSection = _.find(appData.widgets, {default: true})
        tpaComponentCommonService.addSectionComponentOptionsIfNeeded(_.get(mainSection, 'appPage.id'), options)
        const appPage = getSectionAppPageFrom(ps, appData, options.title)

        const landingPageParams = {
            desktop: appPage.fullPage || _.get(mainSection, 'componentFields.fullPageDesktopOnly'),
            mobile: appPage.landingPageInMobile
        }
        const hidePage = clientSpecMapService.computeHidePage(appPage, options.isHidden, false)
        const serializedPage = compStructure.getSectionStructure(
            ps,
            appData,
            sectionId,
            appPage.id,
            hidePage,
            appPage.indexable,
            landingPageParams,
            appPage.pageUriSEO,
            options.requireLogin,
            options.managingAppDefId
        )
        const firstAdd = !installedTpaAppsOnSiteService.isApplicationIdExists(ps, appData.applicationId)
        const sectionPointer = tpaComponentCommonService.addPageAndSection(
            ps,
            pageToAddPointer,
            sectionId,
            appData.applicationId,
            appData.appDefinitionId,
            appPage,
            serializedPage,
            appPage.widgetId || null,
            tpaConstants.COMP_TYPES.TPA_SECTION,
            tpaConstants.DATA_TYPE.TPA_SECTION,
            options
        )
        const addedHiddenPages = tpaComponentCommonService.addHiddenPages(ps, appData, options.componentOptions)
        const addedWidgets = addWidgets(ps, appData, pageToAddPointer, options.componentOptions)
        addedCompsRef = addedCompsRef.concat([sectionPointer], addedWidgets, addedHiddenPages)

        if (firstAdd) {
            appInstallationAndDeletionEvents.invokeAddAppCallbacks(appData.appDefinitionId, options)
        }

        tpaComponentCommonService.setPrefetchPageBehaviorIfNeeded(ps, sectionPointer, appData)

        const addPlatformPartFunc = () =>
            tpaComponentCommonService
                .addPlatformAppIfNeeded(ps, appData, options)
                .then(_.partial(invokeSectionCallback, options, pageToAddPointer, sectionId, true, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
                .catch(_.partial(invokeSectionCallback, options, pageToAddPointer, sectionId, false, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then

        if (waitForSOQ) {
            ps.setOperationsQueue.executeAfterCurrentOperationDone(addPlatformPartFunc)
        } else {
            addPlatformPartFunc().then(onSuccess) // eslint-disable-line promise/prefer-await-to-then
        }
    }

    const invokeSectionCallback = function (options, pageToAddPointer, sectionId, success, addedCompsRef) {
        const res = {
            page: pageToAddPointer,
            sectionId,
            success
        }
        if (addedCompsRef) {
            _.assign(res, {addedCompsRef})
        }

        if (options.callback) {
            options.callback(res)
        }
    }

    const addWidgets = function (ps, appData, pageToAddPointer, componentOptions) {
        const widgets = clientSpecMapService.widgetsToAutoAddToSite(ps, appData)
        const addedWidgets = []
        _.forEach(widgets, function (widget) {
            const compPointer = component.getComponentToAddRef(ps, pageToAddPointer)
            const compRef = tpaWidgetService.addWidgetAfterProvision(
                ps,
                compPointer,
                {
                    widgetId: widget.widgetId,
                    componentOptions
                },
                appData
            )
            addedWidgets.push(compRef)
        })
        return addedWidgets
    }

    const shouldDeleteWholeApp = function (ps, pageData) {
        const isMultiSectionPage = installedTpaAppsOnSiteService.isMultiSectionPage(ps, pageData)
        const shouldDeleteAppWhenDeleted = clientSpecMapService.isPageMarkedAsShouldDeleteAppWhenDeleted(ps, pageData)
        if (isMultiSectionPage && !shouldDeleteAppWhenDeleted) {
            return false
        }
        const isMultipleSectionPagesInstalled = installedTpaAppsOnSiteService.isMultiSectionInstalled(ps, _.get(pageData, 'tpaApplicationId'))
        const autoAddToSitePage = clientSpecMapService.isPageMarkedAsAutoAddToSite(ps, pageData)

        const isMandatorySection = !(autoAddToSitePage === false)
        const isLastSection = !isMultipleSectionPagesInstalled
        return isLastSection && (isMandatorySection || shouldDeleteAppWhenDeleted)
    }

    const actualDeleteSection = function (ps, {appsPagesToDelete, appIdsToDelete}, pageId) {
        const pageData = page.data.get(ps, pageId)
        const applicationId = pageData.tpaApplicationId
        const promises = []
        if (shouldDeleteWholeApp(ps, pageData)) {
            promises.push(tpaDeleteService.actualDeleteApps(ps, {appsPagesToDelete, appIdsToDelete, intent: constants.Intents.USER_ACTION}, applicationId))
        } else {
            const widgetCompsToDelete = tpaDeleteService.getWidgetCompsToDeleteMap(ps, tpaDeleteService.getWidgetsOnPagesToDelete(ps, [pageId]))
            _.forEach(widgetCompsToDelete, function (compMap) {
                promises.push(new Promise(res => tpaDeleteService.deleteWidget(ps, compMap.compPointer, compMap.applicationId, res)))
            })
            page.remove(ps, pageId)
        }

        Promise.all(promises).then(() => {
            tpaDeleteService.deleteTpaCompleteCallback(ps, appIdsToDelete)
        })
    }

    const notifyDeleteSection = function (ps, pageId) {
        const pageData = page.data.get(ps, pageId)
        const applicationId = pageData.tpaApplicationId
        let appDeletionData
        if (shouldDeleteWholeApp(ps, pageData)) {
            appDeletionData = tpaDeleteService.notifyAppsToDelete(ps, applicationId, true)
        } else {
            appDeletionData = {
                appsPagesToDelete: [pageData],
                appIdsToDelete: [pageData.tpaApplicationId]
            }
            const widgetCompsToDelete = tpaDeleteService.getWidgetCompsToDeleteMap(ps, tpaDeleteService.getWidgetsOnPagesToDelete(ps, [pageId]))
            _.forEach(widgetCompsToDelete, function (compMap) {
                tpaDeleteService.notifyWidgetAboutToDelete(ps, compMap.compPointer, compMap.applicationId)
            })
            page.validatePageRemovalInternal(ps, pageId)
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete(appDeletionData)
    }

    const canAddSection = function (ps, appData) {
        return !installedTpaAppsOnSiteService.isMainSectionInstalled(ps, appData.applicationId)
    }

    const alreadyInstalled = function (ps, appDefinitionId) {
        return installedTpaAppsOnSiteService.isAppInstalledBy(ps, appDefinitionId)
    }

    const getTPAWidgetDeleteInteractionParams = (ps, pageId) => {
        const pageData = page.data.get(ps, pageId)
        const applicationId = pageData.tpaApplicationId
        const appDefinitionId = _.get(clientSpecMapService.getAppData(ps, applicationId), 'appDefinitionId')

        return {app_id: appDefinitionId, page_id: pageId}
    }

    return {
        shouldDeleteWholeApp,
        addSectionAfterProvision,
        addMultiSection,
        notifyDeleteSection,
        actualDeleteSection,
        alreadyInstalled,
        invokeSectionCallback,
        getTPAWidgetDeleteInteractionParams
    }
})
