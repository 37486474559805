define([
    'lodash',
    'documentServices/saveAPI/lib/saveRunner',
    'documentServices/saveAPI/preSaveOperations/preSaveOperations',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/bi/bi',
    'documentServices/bi/errors',
    'documentServices/saveAPI/saveErrors',
    'documentServices/constants/constants',
    'experiment',
    'documentServices/saveAPI/lib/saveState',
    'documentServices/hooks/hooks',
    'documentServices/tpa/services/appStoreService',
    '@wix/santa-core-utils',
    'documentServices/utils/contextAdapter',
    'documentServices/saveAPI/monitoring',
    'documentServices/extensionsAPI/extensionsAPI',
    '@wix/document-manager-utils'
], function (
    _,
    saveTaskRunner,
    preSaveOperations,
    generalInfo,
    bi,
    biErrors,
    saveErrors,
    constants,
    experiment,
    saveState,
    hooks,
    appStoreService,
    santaCoreUtils,
    contextAdapter,
    monitoring,
    extensionsAPI,
    {ReportableError}
) {
    const SANTA_EDITOR = 'Editor1.4'
    const ADI_ORIGIN = 'onboarding'

    const reportError = (onError, error) => {
        const cb = onError ? onError : console.log
        cb(error)
    }

    const saveDisabled = onError => {
        reportError(onError, saveErrors.SAVE_DISABLED_IN_DOCUMENT_SERVICES)
    }

    const saveInProgress = onError => {
        reportError(onError, saveErrors.SAVE_IN_PROGRESS)
    }

    /**
     * @param {ps} ps
     * @param {boolean=} flag
     */
    function migrateFromOnBoardingIfNeeded(ps, flag) {
        if (ps.config.origin === SANTA_EDITOR) {
            generalInfo.setUseOnBoarding(ps, flag)
        }
    }

    /**
     * @param {ps} ps
     */
    function setPublishSaveFlag(ps) {
        const publishSaveInnerPointer = ps.pointers.general.getPublishSaveInnerPointer()
        ps.dal.set(publishSaveInnerPointer, true)
    }

    /**
     * @param {ps} ps
     */
    function setSilentSaveFlag(ps) {
        const silentSaveInnerPointer = ps.pointers.general.getSilentSaveInnerPointer()
        ps.dal.set(silentSaveInnerPointer, true)
    }

    /**
     * @param {ps} ps
     * @returns {BICallbacks}
     */
    function getBiCallbacks(ps) {
        return {
            event: _.partial(bi.event, ps),
            error: _.partial(bi.error, ps)
        }
    }

    /**
     * @param {ps} ps
     */
    function isNeverSaved(ps) {
        return !!ps.dal.get(ps.pointers.general.getNeverSaved())
    }

    return saveTaskRegistry => {
        /**
         * @param {ps} ps
         * @param {OnSuccess} onSuccess
         * @param {OnError} onError
         */
        function runSaveAsTemplate(ps, onSuccess, onError) {
            const args = [saveTaskRegistry.getSaveTasksConfig(ps), ps, onSuccess, onError, getBiCallbacks(ps), getEditorOriginSaveOption(ps)]
            saveTaskRunner.runSaveAsTemplate(...args)
        }

        function hasPermission(ps, permission) {
            const permissions = generalInfo.getUserPermissions(ps)
            return _.includes(permissions, permission)
        }

        /**
         * @param {ps} ps
         * @return {boolean}
         */
        function canUserPublish(ps) {
            if (!saveState.isEnabled(ps)) {
                return false
            }
            const isOwnerAndPermitted = generalInfo.isOwner(ps) && !experiment.isOpen('dm_publishByPermissionsNotOwner')

            if (generalInfo.isFirstSave(ps) || isOwnerAndPermitted) {
                return true
            }

            return hasPermission(ps, constants.PERMISSIONS.PUBLISH)
        }

        /**
         * @param {ps} ps
         * @return {boolean}
         */
        const hasSavePermissions = ps => hasPermission(ps, constants.PERMISSIONS.SAVE)

        /**
         * @param {ps} ps
         */
        function removeSaveFlags(ps) {
            const publishSaveInnerPointer = ps.pointers.general.getPublishSaveInnerPointer()
            const silentSaveInnerPointer = ps.pointers.general.getSilentSaveInnerPointer()
            if (ps.dal.get(publishSaveInnerPointer)) {
                ps.dal.remove(publishSaveInnerPointer)
            } else if (ps.dal.get(silentSaveInnerPointer)) {
                ps.dal.remove(silentSaveInnerPointer)
            }
        }

        const runPresaveOperations = (ps, isFullSave, options, tags) => {
            const isFirstSave = isNeverSaved(ps)
            const {FIRST_PRESAVE_OPERATIONS, PRESAVE_OPERATIONS} = constants.INTERACTIONS.SAVE
            try {
                contextAdapter.utils.fedopsLogger.interactionStarted(isFirstSave ? FIRST_PRESAVE_OPERATIONS : PRESAVE_OPERATIONS)
                contextAdapter.utils.fedopsLogger.breadcrumb(`preSaveOperation on save started - FirstSave: ${isFirstSave}.`)
                preSaveOperations.save(ps, options)
                contextAdapter.utils.fedopsLogger.interactionEnded(isFirstSave ? FIRST_PRESAVE_OPERATIONS : PRESAVE_OPERATIONS)
            } catch (/** @type any */ e) {
                contextAdapter.utils.fedopsLogger.breadcrumb('preSaveOperation on save failed.')
                bi.error(ps, biErrors.SAVE_FAILED_DUE_TO_PRE_SAVE_OPERATION, {stack: e.stack})
                contextAdapter.utils.fedopsLogger.captureError(e, {
                    tags: {preSaveOperationError: true, isFullSave, ...tags}
                })
                santaCoreUtils.log.error(
                    'Save has failed due to a preSaveOperation, and no request has been sent to the server - please see the failure details below:',
                    e
                )
                ps.dal.dropUncommittedTransaction(e.message)
                throw saveErrors.normalizeError({preSaveOperation: e})
            }
        }

        const checkForPermissions = ps => {
            const hasSave = hasSavePermissions(ps)
            if (!hasSave) {
                throw saveErrors.USER_NOT_AUTHORIZED_FOR_SITE
            }
        }

        /**
         * @param {ps} ps
         * @param {boolean} isFullSave
         * @param {SaveOptions} options
         */
        const asyncSave = async (ps, isFullSave, options) => {
            monitoring.start(monitoring.SAVE, options)
            const isFirstSave = isNeverSaved(ps)
            const isSaveFromDraft = generalInfo.isDraft(ps)
            const usingCSave = extensionsAPI.getAPI(ps).csave.isCSaveOpen()
            const usingCEdit = extensionsAPI.getAPI(ps).csave.isCEditOpen()
            const firstUserSave = isFirstSave || isSaveFromDraft
            const tags = {usingCSave, usingCEdit, firstUserSave}

            const isTemplate = ps.extensionAPI.siteAPI.isTemplate()
            if (!isTemplate) {
                checkForPermissions(ps)
            }

            migrateFromOnBoardingIfNeeded(ps, false)

            runPresaveOperations(ps, isFullSave, options, tags)

            if (isFirstSave) {
                monitoring.start(monitoring.FIRST_SAVE, options)
                extensionsAPI.snapshots.approveCurrentSnapshot(ps)
            }
            contextAdapter.utils.fedopsLogger.breadcrumb(`preSaveOperation on save finished - FirstSave: ${isFirstSave}.`)
            const biCallbacks = getBiCallbacks(ps)

            if (options) {
                if (options.isPublish) {
                    setPublishSaveFlag(ps)
                } else if (options.isSilent) {
                    setSilentSaveFlag(ps)
                }
                if (!_.isUndefined(options.onBoarding)) {
                    migrateFromOnBoardingIfNeeded(ps, options.onBoarding)
                }
            }
            saveState.setSaveProgress(ps, true)
            if (!experiment.isOpen('dm_useProvisionApi') && (isFirstSave || experiment.isOpen('ds_removePreSaveAppDataFixerOnLoad'))) {
                saveTaskRunner.runFunctionInSaveQueue(appStoreService.preSaveAddAppsAsync(ps))
            }

            const handleSaveValidationError = e => {
                const isADI = _.get(ps, ['config', 'origin']) === ADI_ORIGIN
                if (isADI) {
                    contextAdapter.utils.fedopsLogger.captureError(
                        new ReportableError({
                            message: 'Site validation error - ADI',
                            errorType: 'siteValidationErrorADI',
                            tags: {adiSaveError: isADI, ...tags},
                            extras: {errorDescription: e}
                        })
                    )
                }
                hooks.executeHook(hooks.HOOKS.SAVE.VALIDATION_ERROR)
            }

            const saveErrorHandler = e => {
                const message = _.isString(e) ? e : _.get(e, ['document', 'errorDescription'], 'unknown save error')
                const errorType = _.isString(e) ? e : _.get(e, ['document', 'errorType'], 'unknownSaveError')
                monitoring.error(new ReportableError({message, errorType, tags}), {errorDescription: e}, {isFirstSave})
                saveState.setSaveProgress(ps, false)
                removeSaveFlags(ps)
                if (saveErrors.isSaveValidationError(e)) {
                    handleSaveValidationError(e)
                }
                throw saveErrors.normalizeError(e)
            }

            if (isFirstSave) {
                await runFirstSaveTasks(ps, options, biCallbacks).catch(saveErrorHandler)
            } else if (isFullSave) {
                await runFullSaveTasks(ps, options, biCallbacks).catch(saveErrorHandler)
            } else {
                await runPartialSaveTasks(ps, options, biCallbacks).catch(saveErrorHandler)
            }
            monitoring.end(monitoring.SAVE, options)
            if (isFirstSave) {
                monitoring.end(monitoring.FIRST_SAVE, options)
            }
            removeSaveFlags(ps)
            saveState.setSaveProgress(ps, false)
            hooks.executeHook(hooks.HOOKS.SAVE.SITE_SAVED, null, [firstUserSave])
        }

        /**
         * @param {ps} ps
         * @param {OnSuccess} onSuccess
         * @param {OnError} onError
         * @param {boolean} isFullSave
         * @param {SaveOptions} options
         */
        const save = (ps, onSuccess, onError, isFullSave, options) => {
            asyncSave(ps, isFullSave, options).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
        }

        /**
         * @param {ps} ps
         * @param options
         * @returns {Promise<void>}
         */
        const runPresaveTasks = async (ps, options) => {
            if (_.get(options, ['trigger']) !== 'CSAVE_NON_RECOVERABLE_ERROR') {
                try {
                    contextAdapter.utils.fedopsLogger.interactionStarted('presave_forceSaveAndWaitForResult')
                    await extensionsAPI.csave.forceSaveAndWaitForResult(ps)
                    contextAdapter.utils.fedopsLogger.interactionEnded('presave_forceSaveAndWaitForResult')
                } catch (e) {
                    throw saveErrors.createPresaveError(e)
                }
            }
        }

        /**
         * @param {ps} ps
         * @param {SaveOptions} options
         * @param {BICallbacks} biCallbacks
         * @returns {Promise<void>}
         */
        const runPartialSaveTasks = async (ps, options, biCallbacks) => {
            await saveTaskRunner.runFunctionInSaveQueue(() => runPresaveTasks(ps, options))
            await saveTaskRunner.promises.runPartialSaveTasks(saveTaskRegistry.getSaveTasksConfig(ps), ps, biCallbacks, getSaveOptions(ps, options))
        }

        /**
         * @param {ps} ps
         * @param {SaveOptions} options
         * @param {BICallbacks} biCallbacks
         * @returns {Promise<void>}
         */
        const runFirstSaveTasks = async (ps, options, biCallbacks) => {
            ps.dal.takeSnapshot('CSAVE_TAG')
            await saveTaskRunner.promises.runFirstSaveTasks(saveTaskRegistry.getSaveTasksConfig(ps), ps, biCallbacks, getFirstSaveOptions(ps, options))
        }

        /**
         * @param {ps} ps
         * @param {SaveOptions} options
         * @param {BICallbacks} biCallbacks
         * @returns {Promise<void>}
         */
        const runFullSaveTasks = async (ps, options, biCallbacks) => {
            await saveTaskRunner.promises.runFullSaveTasks(saveTaskRegistry.getSaveTasksConfig(ps), ps, biCallbacks, getSaveOptions(ps, options))
        }

        /**
         * @param {ps} ps
         * @param {SaveOptions} options
         * @returns {{settleInServer, editorOrigin, extraPayload, initiatorOrigin}}
         */
        const getFirstSaveOptions = (ps, options) => ({
            extraPayload: ps.runtimeConfig.firstSaveExtraPayload,
            settleInServer: ps.runtimeConfig.settleInServer,
            editorOrigin: ps.runtimeConfig.origin,
            initiatorOrigin: _.get(options, 'origin', '')
        })

        /**
         * @param {ps} ps
         * @param {SaveOptions} [options]
         * @returns {{viewerName, settleInServer, editorOrigin, initiatorOrigin: *}}
         */
        const getSaveOptions = (ps, options) => ({
            settleInServer: ps.runtimeConfig.settleInServer,
            viewerName: ps.runtimeConfig.viewerName,
            editorOrigin: ps.runtimeConfig.origin,
            initiatorOrigin: _.get(options, 'origin', '')
        })

        const getEditorOriginSaveOption = ps => ({editorOrigin: ps.runtimeConfig.origin})

        /**
         * @param {ps} ps
         * @param {OnSuccess} onSuccess
         * @param {OnError} onError
         */
        const autosave = (ps, onSuccess, onError) => {
            const biCallbacks = getBiCallbacks(ps)

            ps.setOperationsQueue.flushQueueAndExecute(function () {
                saveTaskRunner.runAutosaveTasks(saveTaskRegistry.getSaveTasksConfig(ps), ps, onSuccess, onError, biCallbacks, getEditorOriginSaveOption(ps))
            })
        }

        /**
         * @param {ps} ps
         * @param {OnSuccess} onSuccess
         * @param {OnError} onError
         */
        const saveAsTemplate = (ps, onSuccess, onError) => {
            try {
                preSaveOperations.saveAsTemplate(ps)
            } catch (e) {
                if (onError) {
                    onError(saveErrors.normalizeError({preSaveAsTemplateOperation: e}))
                    return
                }
            }

            saveAPI.save(
                ps,
                function onSaveSuccess() {
                    saveAPI.publish(ps, runSaveAsTemplate.bind(null, ps, onSuccess, onError), onError)
                },
                onError,
                false
            )
        }

        const shouldSaveBeforePublish = (saveConfig, ps) => saveTaskRunner.shouldSaveBeforePublish(saveConfig, ps)

        const publishAsync = async (ps, options) => {
            const saveTasksConfig = saveTaskRegistry.getSaveTasksConfig(ps)
            const innerOptions = getSaveOptions(ps)
            const extendedOptions = _.assign({}, innerOptions, options)
            try {
                if (shouldSaveBeforePublish(saveTasksConfig, ps)) {
                    contextAdapter.utils.fedopsLogger.breadcrumb('shouldSaveBeforePublish - saving before publishing')
                    contextAdapter.utils.fedopsLogger.interactionStarted(constants.INTERACTIONS.SAVE_BEFORE_PUBLISH)
                    await saveAPI.promises.save(ps, false, options)
                    contextAdapter.utils.fedopsLogger.interactionEnded(constants.INTERACTIONS.SAVE_BEFORE_PUBLISH)
                } else {
                    contextAdapter.utils.fedopsLogger.breadcrumb('shouldSaveBeforePublish - no need to save, only publishing')
                }
                saveState.setPublishProgress(ps, true)
                await saveTaskRunner.promises.runPublishTasks(saveTasksConfig, ps, getBiCallbacks(ps), extendedOptions)
            } finally {
                saveState.setPublishProgress(ps, false)
            }
        }

        /**
         * @param {ps} ps
         * @param {OnSuccess} onSuccess
         * @param {OnError} onError
         * @param {SaveOptions} options
         */
        const publish = (ps, onSuccess, onError, options) => {
            publishAsync(ps, options).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
        }

        const ifSaveAllowed =
            f =>
            (ps, onSuccess, onError, ...rest) => {
                if (saveState.canSave(ps)) {
                    return f(ps, onSuccess, onError, ...rest)
                }
                if (!saveState.isEnabled(ps)) {
                    return saveDisabled(onError)
                }
                if (saveState.isSaveInProgress(ps)) {
                    return saveInProgress(onError)
                }
            }

        /**
         * @param {ps} ps
         * @param {{disableSave:boolean,firstSaveExtraPayload:boolean|null,settleInServer:boolean}} op
         */
        const initMethod = (
            ps,
            {disableSave = false, firstSaveExtraPayload = null, settleInServer = true} = {
                disableSave: false,
                firstSaveExtraPayload: null,
                settleInServer: true
            }
        ) => {
            saveState.setSaveAllowed(ps, !disableSave)
            if (firstSaveExtraPayload) {
                ps.runtimeConfig.firstSaveExtraPayload = firstSaveExtraPayload
            }
            ps.runtimeConfig.settleInServer = settleInServer
        }

        /**
         * @exports documentServices/saveAPI/saveAPI
         */
        const saveAPI = {
            initMethod,
            /**
             * performs a partial save
             * @instance
             * @param {OnSuccess} onSuccess
             * @param {OnError} onError
             * @param {boolean} isFullSave - whether to perform a full save or not
             * @param {SaveOptions} options
             * @param {ps} privateServices
             */
            save: ifSaveAllowed(save),

            /**
             * performs an autosave.
             * You should call autosave from the public autosave endpoint, which validates that you can actually autosave
             * @instance
             * @param {ps} privateServices
             * @param {OnSuccess} onSuccess
             * @param {OnError} onError
             */
            autosave: ifSaveAllowed(autosave),

            saveAsTemplate: ifSaveAllowed(saveAsTemplate),

            /**
             * publishes the site
             * @instance
             * @param {ps} privateServices
             * @param {OnSuccess} onSuccess
             * @param {OnError} onError
             * @param {SaveOptions} options
             */
            publish: ifSaveAllowed(publish),

            promises: {
                /**
                 * performs a partial save
                 * @instance
                 * @param {ps} ps
                 * @param {boolean} isFullSave - whether to perform a full save or not
                 * @param {SaveOptions} options
                 */
                save: (ps, isFullSave, options) =>
                    new Promise((resolve, reject) => {
                        saveAPI.save(ps, resolve, reject, isFullSave, options)
                    }),
                // save: ifAsyncSaveAllowed(asyncSave),

                /**
                 * performs an autosave.
                 * You should call autosave from the public autosave endpoint, which validates that you can actually autosave
                 * @instance
                 * @param {ps} ps
                 */
                autosave: ps =>
                    new Promise((resolve, reject) => {
                        saveAPI.autosave(ps, resolve, reject)
                    }),

                /**
                 * publishes the site
                 * @instance
                 * @param ps
                 * @param options
                 */
                publish: (ps, options) =>
                    new Promise((resolve, reject) => {
                        saveAPI.publish(ps, resolve, reject, options)
                    })
            },

            /**
             * Returns true if the user is allowed to publish according to server, false otherwise
             * @instance
             * @param {ps} privateServices
             * @returns {boolean}
             */
            canUserPublish,

            saveState
        }

        return saveAPI
    }
})

/**
 * SaveOptions
 * @typedef {Object} SaveOptions
 * @property {boolean} [isPublish]
 * @property {boolean} [isSilent]
 * @property {boolean} [onBoarding]
 */

/**
 * callback executed upon success
 * @callback OnSuccess
 */

/**
 * callback executed upon error
 * @callback OnError
 * @param {failInfo} failInfo
 */

/**
 * information about the failure of a specific service within the process
 * @typedef {Object} errorInfo
 * @property {string} errorType
 * @property {number} errorCode
 * @property {string} description
 */

/**
 * A map of 'serviceName: errorInfo'
 * @typedef {Object.<String, errorInfo>} failInfo
 */

/**
 * @typedef {Object} BICallbacks
 * @property {function(*): void} event
 * @property {function(*): void} error
 */
