define([
    'lodash',
    '@wix/santa-ds-libs/src/warmupUtils',
    'documentServices/bi/events',
    'documentServices/constants/constants',
    'documentServices/structure/structure',
    'documentServices/structure/structureUtils',
    'documentServices/page/pageUtils'
], function (_, warmupUtils, biEvents, constants, structure, structureUtils, pageUtils) {
    'use strict'

    const SECTION_ID_TO_SHORT_NAME = {
        SITE_HEADER: 'HEADER',
        PAGES_CONTAINER: 'PAGES',
        SITE_FOOTER: 'FOOTER'
    }

    const gap = (upper, lower) => lower.top - upper.bottom

    const onlyNonZeroValues = obj => _.omitBy(obj, v => !v)

    const onlyPositive = obj => _.pickBy(obj, v => v > 0)

    function getSectionsData(compsInMasterPage) {
        const {HEADER, PAGES, FOOTER} = _(compsInMasterPage)
            .filter(({pointer}) => isSection(pointer))
            .reduce((acc, comp) => {
                acc[SECTION_ID_TO_SHORT_NAME[comp.pointer.id]] = comp
                return acc
            }, {})

        return {
            PAGES,
            FOOTER,
            gaps: onlyNonZeroValues({
                headerToPagesGap: gap(HEADER, PAGES),
                pagesToFooterGap: gap(PAGES, FOOTER)
            })
        }
    }

    const updateLayoutSettings = (ps, gaps) => {
        const layoutSettingsPointer = ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('masterPage'), 'layoutSettings')
        const layoutSettings = ps.dal.get(layoutSettingsPointer)

        ps.dal.set(
            layoutSettingsPointer,
            _.merge(
                {},
                layoutSettings,
                {
                    useDesktopSectionsLayout: true
                },
                gaps
            )
        )
    }

    const fixCompY = (ps, sectionPointer, yDelta) => {
        const layoutPointer = ps.pointers.getInnerPointer(sectionPointer, 'layout')
        const layout = ps.dal.get(layoutPointer)

        layout.y += yDelta

        ps.dal.set(layoutPointer, layout)
    }

    const fixSectionsLayoutForNegativeGaps = (ps, {gaps, PAGES, FOOTER}) => {
        if (gaps.headerToPagesGap && gaps.headerToPagesGap < 0) {
            fixCompY(ps, PAGES.pointer, gaps.headerToPagesGap * -1)
        }

        if (gaps.pagesToFooterGap && gaps.pagesToFooterGap < 0) {
            fixCompY(ps, FOOTER.pointer, gaps.pagesToFooterGap * -1)
        }
    }

    const isSection = comp => !!SECTION_ID_TO_SHORT_NAME[comp.id]

    const layoutInfo = (layoutsMap, compId, layoutOverrides) => {
        const layout = _.assign({}, layoutsMap[compId], layoutOverrides[compId])

        return {top: layout.y, bottom: layout.y + layout.height, isFixed: !!layout.fixedPosition}
    }

    const reparentTo = (ps, newParent) => comp => structure.setContainer(ps, comp, comp, newParent)
    const reportBi = (ps, newParent) => comp => {
        ps.siteAPI.reportBI(biEvents.SECTION_LAYOUT_MIGRATION_REPARENT, {
            component_id: comp.id,
            new_container_id: newParent.id
        })
    }

    const reparentOverlappingCompsToFooter = (ps, comps, footerData) => {
        const {top, pointer: footerPointer, isFixed} = footerData

        if (isFixed) {
            return
        }

        const componentsToReparent = _(comps)
            .filter(({bottom}) => bottom > top)
            .map('pointer')
            .value()

        _.forEach(componentsToReparent, reportBi(ps, footerPointer))
        _.forEach(componentsToReparent, reparentTo(ps, footerPointer))
    }

    const hasYOverlapWith = compA => compB => !(compB.top > compA.bottom || compB.bottom < compA.top)
    const closeSmallPagesToFooterGapIfNoOverlap = (floatingComps, PAGES, gaps) => {
        if (!gaps.pagesToFooterGap) {
            return gaps
        }

        const bottomGapPosition = {
            top: PAGES.bottom,
            bottom: PAGES.bottom + gaps.pagesToFooterGap
        }

        if (gaps.pagesToFooterGap <= 20 && !_.some(floatingComps, hasYOverlapWith(bottomGapPosition))) {
            return _.assign({}, gaps, {pagesToFooterGap: 0})
        }

        return gaps
    }

    const getGapsToKeep = (ps, floatingComps, sectionsData) => {
        if (ps.config.closeSectionGaps) {
            return {}
        }

        const gaps = closeSmallPagesToFooterGapIfNoOverlap(floatingComps, sectionsData.PAGES, sectionsData.gaps)

        return onlyPositive(gaps)
    }

    const reportMigrationBiEvents = (ps, originalGaps, gapsKept) => {
        ps.siteAPI.reportBI(biEvents.SECTION_LAYOUT_MIGRATION_RUN)

        _.forEach(originalGaps, (gapSize, gapType) => {
            ps.siteAPI.reportBI(biEvents.SECTION_LAYOUT_MIGRATION_CLOSE_GAP, {
                gap_size: gapSize,
                gap_type: gapType,
                is_closed: !_.has(gapsKept, gapType)
            })
        })
    }

    const getLayoutSettings = ps => ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('masterPage'), 'layoutSettings')) || {}

    const isUsingDesktopSectionsLayout = ps => getLayoutSettings(ps).useDesktopSectionsLayout

    const getMasterPageLayout = (ps, masterPage) => ps.dal.get(ps.pointers.getInnerPointer(masterPage, 'layout'))

    const getDistanceFromHeaderToPagesContainer = (ps, masterPage) => _.get(getMasterPageLayout(ps, masterPage), 'anchors.0.distance', 0)

    const getLayoutOverridesForLandingPage = (ps, masterPage, layoutsMap) => {
        const {SITE_HEADER: headerLayout, SITE_FOOTER: footerLayout} = layoutsMap
        const distanceFromHeaderToPagesContainer = getDistanceFromHeaderToPagesContainer(ps, masterPage)
        const newPagesContainerTop =
            distanceFromHeaderToPagesContainer +
            warmupUtils.boundingLayout.getBoundingY(headerLayout) +
            warmupUtils.boundingLayout.getBoundingHeight(headerLayout)

        return {
            PAGES_CONTAINER: {
                y: newPagesContainerTop,
                height: footerLayout.y - newPagesContainerTop
            }
        }
    }

    const getLayoutsMap = (ps, comps) =>
        _.reduce(
            comps,
            (result, compPointer) => {
                result[compPointer.id] = structureUtils.getComponentLayout(ps, compPointer)
                return result
            },
            {}
        )

    const getMasterPageChildren = ps => {
        const viewMode = constants.VIEW_MODES.DESKTOP
        const masterPage = ps.pointers.components.getMasterPage(viewMode)
        const children = ps.pointers.components.getChildren(masterPage)
        const layoutsMap = getLayoutsMap(ps, children)

        const layoutOverrides = pageUtils.isLandingPage(ps, ps.siteAPI.getPrimaryPageId()) ? getLayoutOverridesForLandingPage(ps, masterPage, layoutsMap) : {}

        return _.map(children, (child, i) => _.assign({i, pointer: child}, layoutInfo(layoutsMap, child.id, layoutOverrides)))
    }

    const sectionsLayoutMigrator = ps => {
        if (isUsingDesktopSectionsLayout(ps)) {
            return
        }

        const compsInMasterPage = getMasterPageChildren(ps)
        const sectionsData = getSectionsData(compsInMasterPage)

        const floatingMasterPageComps = _.filter(
            compsInMasterPage,
            _.conforms({
                isFixed: isFixed => !isFixed,
                pointer: pointer => !isSection(pointer)
            })
        )

        reparentOverlappingCompsToFooter(ps, floatingMasterPageComps, sectionsData.FOOTER)
        fixSectionsLayoutForNegativeGaps(ps, sectionsData)
        const gapsToKeep = getGapsToKeep(ps, floatingMasterPageComps, sectionsData)
        updateLayoutSettings(ps, gapsToKeep)
        reportMigrationBiEvents(ps, sectionsData.gaps, gapsToKeep)

        ps.siteAPI.createPageAnchors('masterPage')
    }

    return sectionsLayoutMigrator
})
