import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

const {logicFactory} = wixapps

/**
 * @class wixappsClassics.TwoLevelCategoryLogic
 * @param partApi
 * @constructor
 */
function TwoLevelCategoryLogic(partApi) {
    this.partApi = partApi
}

TwoLevelCategoryLogic.prototype = {
    getViewVars() {
        return {
            toggleState: 'off'
        }
    }
}

// Register for FAQ part
logicFactory.register('f2c4fc13-e24d-4e99-aadf-4cff71092b88', TwoLevelCategoryLogic)
