import _ from 'lodash'
import viewsUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/viewsUtils'

function findViewInDescriptorByNameTypeAndFormatResolver(descriptor, viewName, typeName, formatName) {
    return [descriptor.id, viewName, typeName, formatName].join('|')
}

export default {
    findViewInDescriptorByNameTypeAndFormat: _.memoize(viewsUtils.findViewInDescriptorByNameTypeAndFormat, findViewInDescriptorByNameTypeAndFormatResolver)
}
