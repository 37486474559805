import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const reactions = viewerLibrary.get('documentServices/reactions/reactions')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')

    return {
        methods: {
            components: {
                reactions: {
                    add: publicMethodUtils.defineDataManipulationAction(reactions.add, {
                        isUpdatingAnchors: dsUtils.DONT_CARE
                    }),
                    update: publicMethodUtils.defineDataManipulationAction(reactions.update),
                    disable: publicMethodUtils.defineDataManipulationAction(reactions.disable),
                    get: publicMethodUtils.defineGetter(reactions.get),
                    removeAll: publicMethodUtils.defineGetter(reactions.removeAll),
                    remove: publicMethodUtils.defineGetter(reactions.remove)
                }
            }
        }
    }
}
