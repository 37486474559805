const pointerType = 'customElementsPointer'
import {getPointer} from './pointerUtils'
const cacheKillersStorePath = 'cacheKillers'

const createPointersMethods = () => ({
    customElements: {
        getCacheKillerCounter: (fileId: string) => getPointer(cacheKillersStorePath, pointerType, [fileId])
    }
})

export {createPointersMethods}
