import {debug} from '@wix/document-manager-core'
import type {
    CreateTransactionRequest,
    CreateTransactionResponse,
    GetDocumentResponse,
    GetTransactionResponse,
    HttpServer,
    Method
} from '@wix/document-manager-extensions'
import type {ContinuousSaveServer} from '@wix/document-manager-extensions/src/extensions/csave/csaveTypes'
import type {ViewsRESTRes, ViewsServer} from '@wix/document-manager-extensions/src/extensions/views/viewsTypes'
import type {CreateRevisionReq, CreateRevisionRes, GetTransactionsResponse, SaveRequest} from '@wix/document-manager-extensions/src/types'
import {fetchJsonWithAuthorization, Query, toQueryString} from '../utils/fetch'
import {AuthorizationStatus, fetchClientSpecMap, InvalidAuthorizationInstance} from './clientSpecMap'
import {MAX_LONG, ServerFacade} from './serverFacade'
import type {AdapterLogger} from '../adapter/adapterLogger'

const log = debug('csave')

/**
 * based on
 * https://github.com/wix-private/editor-server/blob/master/wix-html-server/editor-document-store/src/main/proto/com/wixpress/editor/api/document_store.proto
 */
export class ServerFacadeWithAuthorization extends ServerFacade implements ContinuousSaveServer, ViewsServer, HttpServer {
    constructor(editorRootUrl: string, private logger: AdapterLogger, private authorizationInstance: AuthorizationStatus = new InvalidAuthorizationInstance()) {
        super(editorRootUrl)
    }

    async deleteTransactions(instance: string, to: string = MAX_LONG): Promise<void> {
        // deleteTransaction must include body param to => the transactions up until this ID will be deleted
        const payload = {to}
        const result = await this.deleteJson(this.urls.transactions, payload)
        this.lastTransactionId = result.transactionId
        log.info('deleteTransactions done', result)
    }

    async createRevision(req: CreateRevisionReq): Promise<CreateRevisionRes> {
        log.info('createRevision')
        return await this.post(this.urls.createRevision, req)
    }

    async getTransactions(afterTransactionId: string, untilTransactionId: string, branchId: string): Promise<GetTransactionsResponse> {
        const queryParams = {afterTransactionId, untilTransactionId, branchId}
        return this.getJsonWithParams(this.urls.transactions, queryParams)
    }

    async getViews(): Promise<ViewsRESTRes> {
        const payload: ViewsRESTRes = await this.getJsonWithParams(this.urls.views, {})
        return payload
    }

    async getTransaction(transactionId: string, branchId?: string): Promise<GetTransactionResponse> {
        const params = {branchId}
        return this.getJsonWithParams(`${this.urls.transactions}/${transactionId}`, params)
    }

    async getStore(branchId: string, afterTransactionId: string, untilTransactionId?: string): Promise<GetDocumentResponse> {
        const params = {afterTransactionId, untilTransactionId, branchId}
        return this.getJsonWithParams(this.urls.document, params)
    }

    /**
     * first createTransaction should be sent without lastTransactionId, the subsequent calls must include the previous tx id as lastTransactionId
     * PendingServerTransaction
     */
    async save(payload: CreateTransactionRequest): Promise<CreateTransactionResponse> {
        log.info('sending tx')
        return await this.post<CreateTransactionResponse>(this.urls.save, payload)
    }

    async asyncSave(payload: SaveRequest): Promise<void> {
        log.info('sending pending tx')
        return await this.post<void>(this.urls.save2, payload)
    }

    async post<T = any, R = any>(url: string, data?: R): Promise<T> {
        return this.fetch<T>(url, 'POST', data)
    }

    async deleteJson<T = any, R = any>(url: string, data?: R): Promise<T> {
        return this.fetch<T>(url, 'DELETE', data)
    }

    async refreshInstanceIfExpired(): Promise<AuthorizationStatus> {
        if (this.authorizationInstance.isExpired()) {
            const interactionName = 'authorizationRefresh'
            log.info('authorization instance is stale')
            this.logger.interactionStarted(interactionName)
            this.authorizationInstance = await fetchClientSpecMap(fetch, this.authorizationInstance.metaSiteId)
            this.logger.interactionEnded(interactionName)
        }
        return this.authorizationInstance
    }

    async fetch<T = any, R = any>(url: string, method: Method, data?: R): Promise<T> {
        await this.refreshInstanceIfExpired()

        this.logger.interactionEnded('serverFacadeFetch', {
            extras: {
                instance: this.authorizationInstance.instance,
                expirationDate: this.authorizationInstance.expirationDate,
                isExpired: this.authorizationInstance.isExpired()
            }
        })
        return fetchJsonWithAuthorization(url, this.authorizationInstance.instance, method, data)
    }

    async getJsonWithParams<T = any>(url: string, queryParams: Query): Promise<T> {
        const queryString = toQueryString(queryParams)
        return this.fetch(`${url}?${queryString}`, 'GET')
    }
}
