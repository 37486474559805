import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const account = viewerLibrary.get('documentServices/account/account')
    return {
        methods: {
            account: {
                hasSites: publicMethodUtils.defineGetter(account.hasSites),
                isCoBranded: publicMethodUtils.defineGetter(account.isCoBranded),
                canUserUpgrade: publicMethodUtils.defineGetter(account.canUserUpgrade),
                getCoBrandedType: publicMethodUtils.defineGetter(account.getCoBrandedType)
            }
        }
    }
}
