import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const appStudioWidgets = viewerLibrary.get('documentServices/appStudioWidgets/appStudioWidgets')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    return {
        methods: {
            appStudioWidgets: {
                /**
                 * Allows changing the widget variation for open and closed app studio widgets
                 * @member documentServices.appStudioWidgets
                 * @param {Pointer} componentRef a reference to the widget you want to change it's variation
                 * @param {string} variationId the pageId of the variation you want to switch to
                 * @param [function] onSuccess function called with the widget compRef (same as the componentRef for a closed widget or a new ref for an open widget)
                 * @param [function] onError function called with an error
                 * @param [object] options object containing: customOverrides
                 *
                 * @example
                 * documentServices.appStudioWidgets.changeVariation({id:'comp-122', type: 'DESKTOP'}, 'rjvq0', compRef => {})
                 */
                changeVariation: publicMethodUtils.defineDataManipulationAction(appStudioWidgets.changeVariation, {
                    isUpdatingAnchors: dsUtils.NO
                }),
                /** @deprecated */
                changePreset: publicMethodUtils.defineDataManipulationAction(appStudioWidgets.changePreset),
                presets: {
                    get: publicMethodUtils.defineGetter(appStudioWidgets.getPreset),
                    change: publicMethodUtils.defineDataManipulationAction(appStudioWidgets.changePreset)
                },
                /**
                 * Returns all ref component overrides with a role property
                 * @member documentServices.appStudioWidgets
                 * @param {Pointer} refComponentPtr a reference to the ref component
                 *
                 * @example
                 * documentServices.appStudioWidgets.getOverridesWithPrimaryRole(refComponentPtr)
                 */
                getOverridesWithPrimaryRole: publicMethodUtils.defineGetter(appStudioWidgets.getOverridesWithPrimaryRole),
                addWidget: publicMethodUtils.defineDataManipulationAction(appStudioWidgets.addWidget, {
                    isUpdatingAnchors: dsUtils.YES,
                    getReturnValue: appStudioWidgets.getComponentToAddRef
                }),
                /**
                 * Returns a RefComponent structure that points to the specified app and widget
                 * @param ps
                 * @param appDefinitionId - id of the app in dev center
                 * @param widgetId - id of the widget in dev ccnter
                 * @param options - widget options
                 * @return RefComponent structure
                 */
                buildRefWidgetStructure: publicMethodUtils.defineGetter(appStudioWidgets.buildRefWidgetStructure),
                /**
                 * Returns stageData of the ref direct appWidget child
                 * @param ps
                 * @param compRef - comp ref of type wysiwyg.viewer.components.RefComponent
                 * @return stageData from manifest
                 */
                getStageData: publicMethodUtils.defineGetter(appStudioWidgets.getStageData),
                /**
                 * Get the remote structure of an app builder widget
                 * @param ps
                 * @param {string} appDefinitionId
                 * @param {string} widgetId the widget id in dev center
                 * @returns A promise which resolves to the widget structure
                 */
                getRemoteWidgetStructure: publicMethodUtils.defineGetter(appStudioWidgets.getRemoteWidgetStructure),
                /**
                 * Get the remote app descriptor of an app builder application
                 * @param ps
                 * @param {string} appDefinitionId
                 * @returns A promise which resolves to the app descriptor
                 */
                getAppDescriptor: publicMethodUtils.defineGetter(appStudioWidgets.getAppDescriptor),
                props: {
                    /**
                     * Gets the properties part of the public api of a given Wix Blocks widget
                     * @member documentServices.appStudioWidgets
                     * @param {Pointer} widgetRef a reference to the blocks widget
                     *
                     * @example
                     * documentServices.appStudioWidgets.props.get(widgetRef)
                     */
                    get: publicMethodUtils.defineGetter(appStudioWidgets.props.get),
                    /**
                     * Sets the properties part of the public api of a given Wix Blocks widget. Calls livePreview.refresh
                     * Current widget properties are overwritten by properties in newProps if they have the same key.
                     * Pass newProps = undefined to clear the widget properties.
                     * @member documentServices.appStudioWidgets
                     * @param {Pointer} widgetRef a reference to the blocks widget
                     * @param {object} newProps a json object with the properties
                     * @example
                     * documentServices.appStudioWidgets.props.get(widgetRef)
                     */
                    set: publicMethodUtils.defineDataManipulationAction(appStudioWidgets.props.set)
                }
            }
        }
    }
}
