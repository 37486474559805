define([
    'lodash',
    'documentServices/siteMetadata/siteMetadata',
    'platformEvents',
    'documentServices/component/componentStructureInfo',
    'documentServices/component/component',
    'documentServices/platform/services/notificationService',
    'documentServices/platform/services/platformEventsService',
    'documentServices/dataModel/dataModel',
    'documentServices/platform/common/constants',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/connections/connections',
    'documentServices/utils/utils',
    'documentServices/tpa/utils/tpaUtils',
    'experiment',
    'documentServices/platform/services/platformAppDataGetter'
], function (
    _,
    siteMetadata,
    platformEvents,
    componentStructureInfo,
    component,
    notificationService,
    platformEventsService,
    dataModel,
    constants,
    componentDetectorAPI,
    clientSpecMapService,
    connections,
    dsUtils,
    tpaUtils,
    experiment,
    platformAppDataGetter
) {
    'use strict'

    function notifyOnFirstSaved(ps, appsToNotify, notifyApplicationFunc) {
        const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
        _.forEach(appsToNotify, ({applicationId, instance, instanceId}) => {
            notifyApplicationFunc(
                ps,
                applicationId,
                platformEvents.factory.siteWasFirstSaved({
                    metaSiteId,
                    instance,
                    instanceId
                })
            )
        })

        ps.siteAPI.updateBiData({metaSiteId})
    }

    function removeGhostStructureForApp(ps, {appDefinitionId}) {
        ps.siteDataAPI.siteData.removeGhostStructureData(appDefinitionId)
    }

    function notifyAddToAppWidget(ps, componentPointer, newContainerPointer, oldParentPointer) {
        if (!newContainerPointer || ps.pointers.components.isPage(newContainerPointer)) {
            return
        }
        const closestAppWidget = componentStructureInfo.getAncestorByPredicate(
            ps,
            newContainerPointer,
            parentPointer => component.getType(ps, parentPointer) === constants.CONTROLLER_TYPES.APP_WIDGET
        )
        if (closestAppWidget) {
            if (oldParentPointer && componentDetectorAPI.isDescendantOfComp(ps, oldParentPointer, closestAppWidget)) {
                return
            }
            const fullData = dataModel.getDataItem(ps, closestAppWidget) || {}
            if (fullData.applicationId) {
                const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, fullData.applicationId)
                if (appData && appData.applicationId) {
                    const event = platformEvents.factory.componentAddedToApp({
                        widgetRef: closestAppWidget,
                        compRef: componentPointer
                    })
                    notificationService.notifyApplication(ps, appData.applicationId, event)
                }
            }
        }
    }

    function notifyComponentAddedToStage(notifyApplicationCb, ps, componentPointer, newContainerPointer, oldParentPointer) {
        if (experiment.isOpen('dm_moveComponentAddedToStageToDm')) {
            if (!componentPointer || ps.pointers.components.isPage(componentPointer)) {
                return
            }
            const event = Object.assign(
                {eventOrigin: 'DM'},
                platformEvents.factory.componentAddedToStage({
                    compRef: componentPointer,
                    componentType: component.getType(ps, componentPointer)
                })
            )
            const compData = component.data.get(ps, componentPointer)
            const appsToNotify = new Set(
                _.compact(
                    connections
                        .getAppsConnectedToComponent(ps, componentPointer)
                        .concat([compData?.appDefinitionId, compData?.applicationId])
                        .map(appDefinitionId => _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId), 'applicationId'))
                        .concat(platformEventsService.getAppsRegisteredToEventType(event.eventType))
                )
            )
            appsToNotify.forEach(applicationId => notifyApplicationCb(ps, applicationId, event))
        }
        notifyAddToAppWidget(ps, componentPointer, newContainerPointer, oldParentPointer)
    }

    function getComponentAddedToStageHook(notifyApplicationCb) {
        return _.partial(notifyComponentAddedToStage, notifyApplicationCb)
    }

    function getApplicationId(ps, componentPointer) {
        const {applicationId, appDefinitionId} = dataModel.getDataItem(ps, componentPointer) || {}
        const componentType = dsUtils.getComponentType(ps, componentPointer)
        const appDefId = tpaUtils.isTpaByCompType(componentType) ? appDefinitionId : applicationId
        return _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId), 'applicationId')
    }

    function notifyComponentDisconnected(ps, componentPointer, controllerRef) {
        const applicationId = getApplicationId(ps, componentPointer)
        if (applicationId) {
            const event = platformEvents.factory.componentDisconnected({
                controllerRef,
                compRef: componentPointer
            })
            notificationService.notifyApplication(ps, applicationId, event)
        }
    }

    function notifyComponentConnected(ps, componentPointer, controllerRef) {
        const applicationId = getApplicationId(ps, componentPointer)
        if (applicationId) {
            const event = platformEvents.factory.componentConnected({
                controllerRef,
                compRef: componentPointer
            })
            notificationService.notifyApplication(ps, applicationId, event)
        }
    }

    function addAppsControllers(ps, dataItem) {
        const appsToNotify = {}
        const {applicationId, appDefinitionId} = dataItem || {}
        let appData = {}
        switch (dataItem?.type) {
            case 'AppController':
                appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, applicationId)
                break
            case 'WidgetRef':
                appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
                break
            default:
                appData = clientSpecMapService.getAppData(ps, applicationId)
        }
        if (clientSpecMapService.hasEditorPlatformPart(appData)) {
            appsToNotify[appData.applicationId] = appsToNotify[appData.applicationId] || []
        }
        return appsToNotify
    }

    function addConnectedComponentsApps(ps, componentConnections) {
        return _.reduce(
            componentConnections,
            function (appsToNotifyAcc, connection) {
                const controllerData = dataModel.getDataItem(ps, connection.controllerRef)
                if (controllerData) {
                    const appDefId = controllerData.applicationId
                    const applicationId = _.get(platformAppDataGetter.getAppDataByAppDefId(ps, appDefId), 'applicationId')

                    if (applicationId) {
                        appsToNotifyAcc[applicationId] = appsToNotifyAcc[applicationId] || []
                        appsToNotifyAcc[applicationId].push(connection)
                    }
                }

                return appsToNotifyAcc
            },
            {}
        )
    }

    function getOnDeleteHook(notifyApplicationCb) {
        return _.partial(notifyConnectedAppOnDelete, notifyApplicationCb)
    }

    function notifyConnectedAppOnDelete(
        notifyApplicationCb,
        ps,
        compPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        dataItem,
        deletedCompParentPointer,
        componentConnections
    ) {
        const appsToNotify = {
            ...addAppsControllers(ps, dataItem),
            ...addConnectedComponentsApps(ps, componentConnections)
        }

        _.forEach(appsToNotify, function (appConnections, applicationId) {
            notifyApplicationCb(
                ps,
                applicationId,
                platformEvents.factory.componentDeleted({
                    componentRef: compPointer,
                    connections: appConnections
                }),
                true
            )
        })
    }

    return {
        notifyOnFirstSaved,
        removeGhostStructureForApp,
        notifyAddToAppWidget,
        getComponentAddedToStageHook,
        notifyComponentDisconnected,
        notifyComponentConnected,
        getOnDeleteHook
    }
})
