import _ from 'lodash'
import packagesMap from './packagesMap'

const PACKAGES_CALLBACKS_MAP = {
    facebookShare: (facebookShareComp, registerComponent) => registerComponent('wysiwyg.viewer.components.FacebookShare', facebookShareComp, true),
    svgShape: (svgShapeComps, registerComponent) => {
        const {svgShape, vectorImage, popupCloseIconButton} = svgShapeComps
        registerComponent('wysiwyg.viewer.components.svgshape.SvgShape', svgShape)
        registerComponent('wysiwyg.viewer.components.VectorImage', vectorImage)
        registerComponent('wysiwyg.viewer.components.PopupCloseIconButton', popupCloseIconButton)
    },
    imageZoom: (imageZoomComps, registerComponent) => {
        const {imageZoom, imageZoomDisplayer, mediaZoom, mobileMediaZoom, touchMediaZoom, touchMediaZoomItem} = imageZoomComps
        registerComponent('wysiwyg.components.imageZoom', imageZoom)
        registerComponent('wysiwyg.components.ImageZoomDisplayer', imageZoomDisplayer)
        registerComponent('wysiwyg.viewer.components.MediaZoom', mediaZoom)
        registerComponent('wysiwyg.viewer.components.MobileMediaZoom', mobileMediaZoom)
        registerComponent('wysiwyg.viewer.components.TouchMediaZoom', touchMediaZoom)
        registerComponent('wysiwyg.viewer.components.TouchMediaZoomItem', touchMediaZoomItem)
    },
    slideShowGallery: (slideShowGalleryComp, registerComponent) => registerComponent('wysiwyg.viewer.components.SlideShowGallery', slideShowGalleryComp, true),
    'santa-components/popover': (popoverAssets, registerComponent, registerAspect) => {
        const {Component, Aspect} = popoverAssets
        registerComponent('wysiwyg.viewer.components.Popover', Component)
        registerAspect(Aspect.aspectName, Aspect)
    },
    inlinePopup: (inlinePopupAssets, registerComponent) => {
        const {InlinePopup, MenuContainer, InlinePopupToggle, MenuToggle} = inlinePopupAssets
        registerComponent('wysiwyg.viewer.components.InlinePopup', InlinePopup)
        registerComponent('wysiwyg.viewer.components.InlinePopupToggle', InlinePopupToggle)
        registerComponent('wysiwyg.viewer.components.MenuContainer', MenuContainer)
        registerComponent('wysiwyg.viewer.components.MenuToggle', MenuToggle)
    }
}

function compToPackages(componentType) {
    const pkgs = packagesMap[componentType]
    if (pkgs) {
        return _.isArray(pkgs) ? pkgs : [pkgs]
    }
    return []
}

function getCallbackForRequiredPackage(packageName) {
    return PACKAGES_CALLBACKS_MAP[packageName] || _.noop
}

function packagesToCallbacks(packageNames) {
    return _.zipObject(
        packageNames,
        _.map(packageNames, packageName => PACKAGES_CALLBACKS_MAP[packageName] || _.noop)
    )
}

/**
 *
 * @param componentType
 * @param siteData
 * @returns {Object.<packageName, callback>}
 */
function getPackagesToCallbacksForComponent(componentType) {
    const packageNames = compToPackages(componentType)
    return packagesToCallbacks(packageNames)
}

/**
 *
 * @returns {packageName[]}
 */
function getAllUniquePackagesNames() {
    return _(packagesMap).values().flatten().concat('skins').uniq().value()
}

function getAllPackagesForAllComponents() {
    const packageNames = getAllUniquePackagesNames()
    return packagesToCallbacks(packageNames)
}

export default {
    compToPackages,
    getPackagesToCallbacksForComponent,
    all: getAllUniquePackagesNames,
    getAllPackagesForAllComponents,
    getCallbackForRequiredPackage,
    packagesToCallbacks
}
