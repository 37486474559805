import _ from 'lodash'
import wixCodeUrlUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/wixCodeUrlUtils'
import urlBuilder from '@wix/santa-ds-libs/src/platformInit/src/utils/urlBuilder'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {parseUrl, isQueryParamOn} = coreUtils.urlUtils

function getViewMode(siteModel) {
    return siteModel.publicModel ? 'site' : 'preview'
}

function getOpenExperiments(runningExperiments) {
    return _.reduce(
        runningExperiments,
        (openExperiments, value, name) => {
            if (value === 'new') {
                openExperiments.push(name)
            }
            return openExperiments
        },
        []
    )
}

function buildBiSession({wixBiSession, rendererModel, currentUrl, biData}) {
    const {viewerSessionId, visitorId, siteMemberId, random, coin, pn, requestId, initialTimestamp, initialRequestTimestamp} = wixBiSession
    const {metaSiteId, userId} = rendererModel
    const muteBi = isQueryParamOn(currentUrl, 'isqa') || isQueryParamOn(currentUrl, 'suppressbi')
    const sampleRatioState = _.get(currentUrl, 'query.sampleratio')
    const pageLoadStart = _.get(biData, 'pageLoadStart', initialTimestamp)
    const networkPageLoadStart = _.get(biData, 'networkPageLoadStart', initialRequestTimestamp)

    return {
        metaSiteId,
        viewerSessionId,
        visitorId,
        siteMemberId,
        requestId,
        ownerId: userId,
        pageLoadStart,
        networkPageLoadStart,
        pageNumber: pn,
        random,
        coin,
        muteBi,
        sampleRatioState
    }
}

function build(siteModel, clientSpecMap, options) {
    const {rendererModel, userWarmup, serviceTopology, santaBase, baseVersion, wixBiSession, biData} = siteModel
    const biSessionData = buildBiSession({wixBiSession, rendererModel, currentUrl: parseUrl(options.currentUrl), biData})

    return {
        getElementoryArguments: widgets => wixCodeUrlUtils.getElementoryArguments(siteModel, clientSpecMap, widgets, getViewMode(siteModel)), // TODO check if can be replaced with static data
        getUserCodeUrlsDetails: wixCodeUrlUtils.getUserCodeUrlsDetails,
        viewMode: getViewMode(siteModel),
        locale: rendererModel.locale,
        userWarmup,
        workerUrl: urlBuilder.buildUrl(siteModel, options),
        wixCodePlatformRoot: serviceTopology.scriptsLocationMap['wix-code-platform'],
        santaBase,
        staticServerUrl: serviceTopology.staticServerUrl,
        baseVersion,
        openExperiments: getOpenExperiments(rendererModel.runningExperiments),
        csrfToken: coreUtils.cookieUtils.getCookie('XSRF-TOKEN'),
        biSessionData
    }
}

export default {
    build,
    buildBiSession
}
