import layout from '../../util/layout'

const COMP_TYPE = 'wysiwyg.viewer.components.PageGroup'

const isMeshLayout = node => node.dataset.isMesh === 'true'

const customPageGroupLayouter = (id, nodesMap, measureMap, {getPrimaryPageId, isSiteBusyIncludingTransition}) => {
    const node = nodesMap[id]

    return patchers => {
        if (isMeshLayout(node)) {
            const minHeight = measureMap.height[`${getPrimaryPageId()}inlineContent`]
            if (isSiteBusyIncludingTransition()) {
                patchers.css(id, {minHeight})
            } else {
                patchers.css(id, {minHeight: null})
            }
        }
    }
}

layout.registerCustomLayoutFunction(COMP_TYPE, customPageGroupLayouter)
