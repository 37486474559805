import _ from 'lodash'
import $ from 'zepto'
import layout from '../util/layout'
import imageClientApi from '@wix/image-client-api/dist/imageClientApi'
import html5VideoLayout from './html5VideoLayout'
import optimizedCssImageLayout from '../util/optimizedCssImageLayout'
import 'experiment'

const DEFAULT_SIZE = 1920
const DEFAULT_MOBILE_SIZE = 1000
const SITE_BACKGROUND = 'SITE_BACKGROUND'

const SITE_BACKGROUND_CURRENT_IMAGE = 'siteBackgroundcurrentImage'
const SITE_BACKGROUND_CURRENT = 'siteBackgroundcurrent'
const SITE_BACKGROUND_PREVIOUS = 'siteBackgroundprevious'

const CURRENT_VIDEO = 'currentVideo'
const POSTER = 'poster'

const isBolt = () => typeof window === 'object' && _.get(window, 'wixBiSession.renderType') === 'bolt'

function patchSiteBackground(id, patchers, measureMap, structureInfo, siteData) {
    // DO NOT REMOVE THESE MEASURES!!
    // These measures does not access the DOM!
    // must be here, due to dependency between wixAds measure and siteBackground,
    // and because background is loaded before anchors so measure stage is too early.
    // and the fact that enforceAnchors clears the measure for the background anyway :(
    measureMap.width[id] = getBackgroundWidth(measureMap, siteData)
    measureMap.height[id] = getBackgroundHeight(measureMap, siteData)

    //TODO: this is a bit dirty, but we need currentImage in the measuremap for parallax animation.
    measureMap.height.currentImage = measureMap.height.masterPage

    patchers.css(id, {
        height: `${measureMap.height[id]}px`,
        width: `${measureMap.width[id]}px`
    })

    const customMeasure = measureMap.custom[id]

    // patching background roots
    const isMobileView = siteData.isMobileView()
    const isMobileDevice = siteData.isMobileDevice()
    const mediaSizing = {
        // fix for mobile view in Editor, fixed position
        width: isMobileView ? measureMap.width.screen : '100%',
        // fix for mobile devices for bg with opacity and bg-color (browser action bar is shown in some cases and push the image up living the white space)
        height: isMobileView && customMeasure.bgPosition === 'fixed' ? getDeviceScreenDimensions(measureMap, isMobileView, isMobileDevice).availHeight : '100%'
    }
    patchers.css(SITE_BACKGROUND_CURRENT, mediaSizing)
    patchers.css(SITE_BACKGROUND_PREVIOUS, mediaSizing)

    // patching image
    const imageCssData = getCssImageData(customMeasure, siteData, measureMap)
    optimizedCssImageLayout.patchCssImage(customMeasure, SITE_BACKGROUND_CURRENT_IMAGE, patchers, imageCssData.css, imageCssData.uri, siteData)

    // patching video - legacy - santa only - Bolt is using wixVideo custom element
    if (!isBolt() && measureMap.custom[id].hasVideo && !_.get(siteData.getRootNavigationInfo(), 'pageBackgroundColorOverride')) {
        html5VideoLayout.patchBgVideo(id + CURRENT_VIDEO, null, patchers, measureMap, siteData.prefersReducedMotion)
    }
}

function getDeviceScreenDimensions(measureMap, isMobileView, isMobileDevice) {
    const deviceScreen = {
        availWidth: measureMap.width.screen,
        availHeight: measureMap.height.screen,
        pixelAspectRatio: measureMap.devicePixelRatio
    }

    if (isMobileView && isMobileDevice) {
        const deviceWidth = Math.min(measureMap.availWidth.device, measureMap.availHeight.device)
        const deviceHeight = Math.max(measureMap.availWidth.device, measureMap.availHeight.device)
        deviceScreen.availHeight = Math.round(deviceScreen.availWidth * (deviceHeight / deviceWidth))
    }

    return deviceScreen
}

function shouldApplyMediaSizing(bgData, mediaSizing, isMobileView) {
    return isMobileView && bgData.mediaRef && mediaSizing === 'viewport'
}

function calculateTargetImageSize(imageData, bgData, deviceScreen, applyMediaSizing, isMobileView) {
    const target: any = {}
    const legacyFittingTypes = _.at(imageClientApi.fittingTypes, [
        'LEGACY_BG_NORMAL',
        'LEGACY_BG_FIT_AND_TILE',
        'LEGACY_BG_FIT_AND_TILE_HORIZONTAL',
        'LEGACY_BG_FIT_AND_TILE_VERTICAL'
    ])
    const isLegacyFitting = _.includes(legacyFittingTypes, bgData.fittingType)

    if (applyMediaSizing && !isLegacyFitting) {
        target.width = deviceScreen.availWidth
        target.height = deviceScreen.availHeight
        target.pixelAspectRatio = deviceScreen.pixelAspectRatio
    } else {
        // legacy code
        const size = isMobileView ? DEFAULT_MOBILE_SIZE : DEFAULT_SIZE
        target.width = Math.min(size, imageData.width)
        target.height = Math.min(size, Math.round(target.width / (imageData.width / imageData.height)))
    }

    target.htmlTag = 'bg'
    target.alignment = bgData.alignType

    return target
}

/**
 *
 * @param customMeasure
 * @param siteData
 * @param measureMap
 * @returns {{backgroundImage: string, backgroundSize: string, backgroundPosition: string, backgroundRepeat: string}}
 */
function getCssImageData(customMeasure, siteData, measureMap) {
    const {bgData} = customMeasure
    let imageData = bgData.mediaRef

    let imageCss: any = {
        backgroundSize: '',
        backgroundPosition: '',
        backgroundRepeat: ''
    }

    let uri = ''

    if (imageData && _.isUndefined(_.get(siteData.getRootNavigationInfo(), 'pageBackgroundColorOverride'))) {
        if (imageData.type === 'WixVideo') {
            imageData = imageData.posterImageRef
        }

        const isMobileView = siteData.isMobileView()
        const isMobileDevice = siteData.isMobileDevice()
        const deviceScreen = getDeviceScreenDimensions(measureMap, isMobileView, isMobileDevice)
        const applyMediaSizing = shouldApplyMediaSizing(bgData, customMeasure.mediaSizing, isMobileView)

        const src = {id: imageData.uri, width: imageData.width, height: imageData.height}
        const target = calculateTargetImageSize(imageData, bgData, deviceScreen, applyMediaSizing, isMobileView)
        const imageQualityFilters = _.defaults({quality: 85}, imageData.quality || {})
        const bgDetails = imageClientApi.getData(bgData.fittingType, src, target, imageQualityFilters, siteData.browser)
        uri = bgDetails.uri

        imageCss = {
            backgroundSize: bgDetails.css.container.backgroundSize,
            backgroundPosition: bgDetails.css.container.backgroundPosition,
            backgroundRepeat: bgDetails.css.container.backgroundRepeat,
            height: customMeasure.currentImageHeight
        }

        if (applyMediaSizing) {
            if (bgData.fittingType === 'fill') {
                imageCss.backgroundSize = 'auto 100%'
                imageCss.height = deviceScreen.availHeight
            }
            imageCss.backgroundPosition = imageCss.backgroundPosition.replace(/(center|bottom)$/, 'top')
        }
    }

    return {
        css: imageCss,
        uri
    }
}

function getBackgroundHeight(measureMap, siteData) {
    const siteStructureHeight = measureMap.height[siteData.getStructureCompId()]
    const wixAdsTop = parseInt(measureMap.height.WIX_ADStop, 10) || 0
    const availableScreenHeight = measureMap.height.screen - wixAdsTop
    return Math.max(availableScreenHeight, siteStructureHeight)
}

function getBackgroundWidth(measureMap, siteData) {
    const siteStructureWidth = measureMap.width[siteData.getStructureCompId()]
    return Math.ceil(Math.max(measureMap.width.screen, siteStructureWidth))
}

function requestBackgroundToMeasureChildren(compId, nodesMap, structureInfo, siteData) {
    return [[siteData.getPrimaryPageId()]]
}

function measureSiteBackground(id, measureMap, nodesMap, structureInfo, {getDataByQuery, isMobileView, primaryPageBackground, getPrimaryPageId}) {
    nodesMap[SITE_BACKGROUND_CURRENT_IMAGE] = window.document.querySelector(`.${SITE_BACKGROUND_CURRENT_IMAGE}`)
    nodesMap[SITE_BACKGROUND_CURRENT] = window.document.querySelector(`.${SITE_BACKGROUND_CURRENT}`)
    nodesMap[SITE_BACKGROUND_PREVIOUS] = window.document.querySelector(`.${SITE_BACKGROUND_PREVIOUS}`)

    const pageId = getPrimaryPageId()
    // TODO: Remove this getDataByQuery once BOLT is synced with this code
    const {ref, mediaSizing} = primaryPageBackground || getDataByQuery(pageId, pageId).pageBackgrounds[isMobileView() ? 'mobile' : 'desktop']

    const siteBg = {
        bgData: ref || {},
        mediaSizing
    }

    measureMap.custom[id] = {
        bgData: siteBg.bgData,
        hasVideo: false,
        mediaSizing: siteBg.mediaSizing,
        currentImageHeight: $(nodesMap[SITE_BACKGROUND_CURRENT_IMAGE]).data('height')
    }
    if (shouldApplyMediaSizing(siteBg.bgData, siteBg.mediaSizing, isMobileView())) {
        // bgData.scrollType isn't the source of truth for bg position, since it could be overridden for mobile
        // so get it from DOM
        measureMap.custom[id].bgPosition = $(nodesMap[SITE_BACKGROUND_CURRENT]).data('position')
    }
    optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[id], nodesMap[SITE_BACKGROUND_CURRENT_IMAGE])

    // legacy - santa only - Bolt is using wixVideo custom element
    if (!isBolt() && _.get(siteBg.bgData.mediaRef, 'type') === 'WixVideo') {
        const currentVideoNode: any = window.document.querySelector(`#${SITE_BACKGROUND + CURRENT_VIDEO}`)
        nodesMap[SITE_BACKGROUND + CURRENT_VIDEO] = currentVideoNode
        nodesMap[`${SITE_BACKGROUND}${CURRENT_VIDEO}video`] = window.document.querySelector(`#${SITE_BACKGROUND}${CURRENT_VIDEO}video`)
        nodesMap[SITE_BACKGROUND + CURRENT_VIDEO + POSTER] = window.document.querySelector(`#${SITE_BACKGROUND + CURRENT_VIDEO + POSTER}`)
        measureMap.width[SITE_BACKGROUND + CURRENT_VIDEO] = currentVideoNode.offsetWidth
        measureMap.height[SITE_BACKGROUND + CURRENT_VIDEO] = currentVideoNode.offsetHeight
        measureMap.custom[id].hasVideo = true
        html5VideoLayout.measureBgVideo(id, id + CURRENT_VIDEO, id + CURRENT_VIDEO + POSTER, measureMap, nodesMap)
    }
}

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.SiteBackground')
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.SiteBackground', requestBackgroundToMeasureChildren)
layout.registerPatcher('wysiwyg.viewer.components.SiteBackground', patchSiteBackground)
layout.registerCustomMeasure('wysiwyg.viewer.components.SiteBackground', measureSiteBackground)

const testAPI = {
    getDeviceScreenDimensions,
    calculateTargetImageSize,
    getCssImageData
}

export default {
    testAPI
}
