define(['lodash', 'documentServices/constants/constants', 'documentServices/utils/utils'], function (_, constants, dsUtils) {
    'use strict'

    function getImageOrCropAspectRatio(compData, compMobileProps) {
        let aspectRatio = compData.width / compData.height
        if (!_.isEmpty(compData.crop) && _.isEmpty(compMobileProps.overrideCrop)) {
            aspectRatio = compData.crop.width / compData.crop.height
        } else if (!_.isEmpty(_.get(compMobileProps, 'overrideCrop'))) {
            aspectRatio = compMobileProps.overrideCrop.width / compMobileProps.overrideCrop.height
        }
        return aspectRatio
    }

    return {
        /**
         * Get aspect ratio based on original image size or saved crop size
         * @param {ps} ps
         * @param compPtr
         * @returns {number}
         */
        getImageAspectRatio(ps, compPtr) {
            const compStructure = ps.dal.get(compPtr)
            const page = ps.pointers.components.getPageOfComponent(compPtr)

            const compDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(compStructure.dataQuery), page.id)
            const compData = ps.dal.get(compDataPointer)

            let compMobileProps = {}
            if (compPtr.type === constants.VIEW_MODES.MOBILE) {
                const compMobilePropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(compStructure.propertyQuery), page.id)
                compMobileProps = ps.dal.get(compMobilePropsPointer)
            }

            return getImageOrCropAspectRatio(compData, compMobileProps)
        },
        /**
         * Get aspect ratio based on current image (or any other component) size in its saved layout
         * @param {ps} ps
         * @param compPtr
         * @returns {number}
         */
        getStageAspectRatio(ps, compPtr) {
            const compStructure = ps.dal.get(compPtr)
            const {width, height} = compStructure.layout
            return width / height
        }
    }
})
