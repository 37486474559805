import _ from 'lodash'
import fullFunctionalityConfig from './fullFunctionalityConfig'
import {withoutCsaveExtensions} from './csaveConfigs'

const conf = _.cloneDeep(fullFunctionalityConfig)
conf.extensionsList = withoutCsaveExtensions(conf.extensionsList)

export default {
    ...conf,
    initialSeed: 1000
}
