define([
    'documentServices/tpa/services/tpaPostMessageService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/experimentService'
], function (tpaPostMessageService, clientSpecMapService, experimentService) {
    'use strict'

    const handlers = function (ps, msg, callback) {
        tpaPostMessageService.callHandler(ps, msg, callback)
    }

    const initEditor = function (ps, isInDevMode, experiments) {
        clientSpecMapService.setIsInDevMode(isInDevMode)
        experimentService.setExperiments(experiments)
    }

    return {
        handlers,
        initEditor
    }
})
