//HTMLSRCR-1183 -- see todo.md
define([
    'lodash',
    'documentServices/routers/utils/routersBackendRequests',
    'documentServices/page/pageData',
    'documentServices/page/page',
    'documentServices/menu/mainMenu',
    'documentServices/routers/routersGetters',
    'documentServices/routers/utils/routersUtils',
    'documentServices/wixCode/wixCode',
    'documentServices/platform/platform',
    'documentServices/hooks/hooks',
    'documentServices/routers/utils/constants',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, routersBackendRequests, pageData, page, mainMenu, routersGetters, routersUtils, wixCode, platform, hooks, constants, extensionsAPI) {
    'use strict'

    const {ROUTER_TYPE} = constants
    /*****************************************  Private Functions  ****************************************************/
    function getMenuItemIfSubPage(menuItems, pageId) {
        let res = {isSubPage: false}
        _.forEach(menuItems, function (menuItem) {
            const subItems = menuItem.items || []
            _.forEach(subItems, function (subItem) {
                const subItemPageId = subItem.link && subItem.link.pageId ? subItem.link.pageId.slice(1) : ''
                if (subItemPageId === pageId) {
                    res = {
                        isSubPage: true,
                        menuItemId: subItem.id
                    }
                }
            })
        })
        return res
    }

    function getSubItems(menuItems, pageId) {
        let subItems = []
        _.forEach(menuItems, function (menuItem) {
            const menuItemPageId = _.get(menuItem, 'link.pageId')
            if (menuItemPageId && menuItemPageId.slice(1) === pageId) {
                subItems = menuItem.items
            }
        })
        return subItems
    }

    function makeSubPageMainPage(ps, pageId) {
        const menuItems = mainMenu.getMenu(ps)
        const subPage = getMenuItemIfSubPage(menuItems, pageId)
        if (subPage.isSubPage) {
            mainMenu.moveItem(ps, subPage.menuItemId, null, menuItems.length)
        }
    }

    function removeSubPageForPage(ps, pageId) {
        const menuItems = mainMenu.getMenu(ps)
        const subPages = getSubItems(menuItems, pageId)
        _.forEach(subPages, function (subPage, index) {
            mainMenu.moveItem(ps, subPage.id, null, menuItems.length + index)
        })
    }

    function initRoutersConfigMapIfNeeded(ps) {
        const allRouters = routersGetters.get.all(ps)

        if (_.isEmpty(allRouters)) {
            const routersPointer = ps.pointers.routers.getRoutersPointer()
            ps.dal.set(routersPointer, {
                configMap: {}
            })
        }
    }

    function generateRouterPages(pageRoles, pageId) {
        return _.transform(
            pageRoles,
            function (acc, role) {
                acc[role] = pageId
            },
            {}
        )
    }

    function getPageRoles(routerPages, pageId) {
        const pageRoles = _.invertBy(routerPages)
        return pageRoles[pageId]
    }

    /*****************************************  Public Functions  ****************************************************/

    function getRouterPointer(ps) {
        const allRouters = routersGetters.get.all(ps)
        const routerId = routersUtils.generateRouterId(allRouters)
        initRoutersConfigMapIfNeeded(ps) // todo: change to getConfigMap - get or create config map if neede
        const routersConfigMapPointer = ps.pointers.routers.getRoutersConfigMapPointer()
        ps.pointers.getInnerPointer(routersConfigMapPointer, routerId)
        return ps.pointers.routers.getRouterPointer(routerId)
    }

    /*newRouterPointer - received automatically from getRouterPointer*/
    function addRouter(ps, routerRef, newRouter) {
        const routerToAdd = _.clone(newRouter)
        //init config field to be an object is undefined
        if (routerToAdd.config) {
            if (_.isObject(routerToAdd.config)) {
                routerToAdd.config = JSON.stringify(routerToAdd.config)
            }
        } else {
            routerToAdd.config = JSON.stringify({})
        }
        routersUtils.validateNewRouter(ps, routerToAdd)
        ps.dal.set(routerRef, routerToAdd)
        hooks.executeHook(hooks.HOOKS.ROUTER.AFTER_ADD, ROUTER_TYPE, [ps, routerRef])
        return routerRef
    }

    function removeRouter(ps, routerRef) {
        const allRouters = routersGetters.get.all(ps)
        const routerData = routersGetters.get.byRef(ps, routerRef)
        const routerId = _.findKey(allRouters, {prefix: routerData.prefix})

        hooks.executeHook(hooks.HOOKS.ROUTER.BEFORE_REMOVE, ROUTER_TYPE, [ps, routerRef])

        _.forEach(routerData.pages, function (pageId) {
            const pageRef = page.getReference(ps, pageId)
            disconnectPageFromRouter(ps, routerRef, pageRef)
        })

        delete allRouters[routerId]
        const routersConfigMapPointer = ps.pointers.routers.getRoutersConfigMapPointer()
        ps.dal.set(routersConfigMapPointer, allRouters)
    }

    function updateRouter(ps, routerPtr, updateData) {
        const routerData = ps.dal.get(routerPtr)
        if (updateData.prefix && updateData.prefix !== routerData.prefix) {
            routersUtils.validateUpdatedRouter(ps, updateData)
            routerData.prefix = updateData.prefix
        }

        if (updateData.config) {
            if (_.isObject(updateData.config)) {
                routerData.config = JSON.stringify(updateData.config)
            } else {
                routerData.config = updateData.config
            }
        }
        if (_.isBoolean(updateData.hide) && updateData.hide !== routerData.hide) {
            routerData.hide = updateData.hide
        }
        hooks.executeHook(hooks.HOOKS.ROUTER.BEFORE_UPDATE, ROUTER_TYPE, [ps, routerPtr, routerData])
        ps.dal.set(routerPtr, routerData)
    }

    function getRouterSiteMap(ps, routerId, callback) {
        const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
        const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
        // eslint-disable-next-line promise/prefer-await-to-then
        wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_PAGE}).then(function () {
            routersBackendRequests.getInnerRoutesSiteMap(
                routerBackEndParamObj,
                function (siteMap) {
                    callback(siteMap)
                },
                function () {
                    callback()
                }
            )
        })
    }

    function getPageFromInnerRoute(ps, routerId, innerRoute, callback) {
        getRouterSiteMap(ps, routerId, siteMap => {
            if (siteMap) {
                const currRoute = _.find(siteMap, {url: innerRoute})
                callback(currRoute ? currRoute.pageName : null)
            } else {
                callback()
            }
        })
    }

    function getRouterInnerRoutes(ps, routerId, pageId, callback) {
        getRouterSiteMap(ps, routerId, siteMap => callback(siteMap ? _.filter(siteMap, {pageName: pageId}) : undefined))
    }

    function getRouterInnerRoutesCount(ps, routerId, pageId, callback) {
        const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
        const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
        // eslint-disable-next-line promise/prefer-await-to-then
        wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_ROUTES_COUNT}).then(function () {
            routersBackendRequests.getInnerRoutesSiteMapCount(
                routerBackEndParamObj,
                function (siteMapCount) {
                    callback(siteMapCount)
                },
                function () {
                    callback()
                }
            )
        })
    }

    function getTpaInnerRoutes(ps, appDefinitionId, subPage, callback) {
        routersBackendRequests.getTpaRoutesFromSiteStructure(
            ps,
            appDefinitionId,
            subPage,
            function (response) {
                const results = _.get(response, 'results', [])
                callback(results)
            },
            function () {
                callback([])
            }
        )
    }

    function getCurrentInnerRoute(ps) {
        const currentPageId = ps.siteAPI.getPrimaryPageId()
        const routerData = routersGetters.getRouterDataForPageIfExist(ps, currentPageId)
        if (!routerData) {
            return {isDynamic: false}
        }
        const pageInfo = ps.siteAPI.getRootNavigationInfo()
        if (pageInfo.routerDefinition) {
            if (!pageInfo.pageAdditionalData) {
                return {isDynamic: true, routerId: routerData.routerId}
            }
            const pageSuffix = pageInfo.pageAdditionalData.split('/')
            const innerRoute = _.drop(pageSuffix, 1).join('/')
            return {isDynamic: true, innerRoute, routerId: routerData.routerId}
        }
        return {isDynamic: false}
    }

    /****************************************** pages Functions ****************************/

    function getPageToAddPointer(ps) {
        const newItemPageRef = page.getPageIdToAdd(ps)
        return newItemPageRef
    }

    function addNewRouterPage(ps, newItemPageRef, routerPtr, pageTitle, pageRoles, serializedPage) {
        page.add(ps, newItemPageRef, pageTitle, serializedPage)
        connectPageToRouter(ps, routerPtr, newItemPageRef, pageRoles)
        return newItemPageRef
    }

    function findRouteByPageId(routerData, pageId) {
        const pageRole = _.findKey(routerData.pages, id => id === pageId)
        const {patterns} = JSON.parse(routerData.config)
        return _.findKey(patterns, definition => definition.pageRole === pageRole)
    }

    function getIsDynamicPageIndexable(routerData, route) {
        const config = JSON.parse(routerData.config)
        const robotsMetaTag = _.get(config.patterns[route].seoMetaTags, 'robots')
        return robotsMetaTag !== 'noindex'
    }

    function safelyGetIsDynamicPageIndexable(routerData, pageId) {
        try {
            const route = findRouteByPageId(routerData, pageId)
            return getIsDynamicPageIndexable(routerData, route)
        } catch (error) {
            return true
        }
    }

    function connectPageToRouter(ps, routerPtr, pagePtr, pageRoles) {
        pageRoles = _.isArray(pageRoles) ? pageRoles : [pageRoles]
        const currentPageId = _.get(ps.dal.get(pagePtr), 'id')
        //hide the dynamic page from the menu and site map
        const currentPageData = pageData.getPageData(ps, currentPageId)
        const updatedData = {hidePage: true, indexable: false}
        /*seeting just if boolean since if undefined that when the page is back to static it will keep original definition*/
        if (_.isBoolean(currentPageData.mobileHidePage)) {
            _.assign(updatedData, {mobileHidePage: true})
        }
        pageData.setPageData(ps, currentPageId, updatedData, false, true)

        const routerData = ps.dal.get(routerPtr)
        const routerInUse = routersGetters.get.byPage(ps, pagePtr)

        //todo: move to routersValidationsUtil
        if (routerInUse && !ps.pointers.isSamePointer(routerPtr, routerInUse)) {
            throw new Error('page already exist on another router')
        } else if (page.homePage.get(ps) === pagePtr.id) {
            throw new Error("home page can't become dynamic page")
        }

        if (routerInUse) {
            routerData.pages = _(routerData.pages)
                .omitBy(function (pageId) {
                    return pageId === currentPageId
                })
                .assign(generateRouterPages(pageRoles, currentPageId))
                .value()
        } else {
            //todo - if pages is empty obj by default so use assign instead of merge
            routerData.pages = _.merge(routerData.pages || {}, _.omitBy(generateRouterPages(pageRoles, currentPageId), _.isUndefined))
            //routerData.pages = _.merge(routerData.pages || {}, _.compact(generateRouterPages(pageRoles, currentPageId)));
        }
        makeSubPageMainPage(ps, pagePtr.id)
        removeSubPageForPage(ps, pagePtr.id)
        ps.dal.set(routerPtr, routerData)
    }

    function disconnectPageFromRouter(ps, routerPtr, pagePtr) {
        const pageId = _.get(ps.dal.get(pagePtr), 'id')
        const routerData = ps.dal.get(routerPtr)
        const isPageBelongsRouter = routerData.pages && _.includes(_.values(routerData.pages), pageId)

        //hide the dynamic page from the menu and site map
        if (!isPageBelongsRouter) {
            throw new Error('the page is not connected to this router')
        }
        removePageFromRouter(ps, routerPtr, pageId)
        const currPageData = pageData.getPageData(ps, pageId)
        const staticPageUriSeo = pageData.getValidPageUriSEO(ps, pageId, currPageData.title)
        if (ps.siteAPI.getPrimaryPageId() === pagePtr.id) {
            page.navigateTo(ps, pagePtr.id)
        }

        const indexable = safelyGetIsDynamicPageIndexable(routerData, pageId)

        pageData.setPageData(
            ps,
            pageId,
            {
                pageUriSEO: staticPageUriSeo,
                title: currPageData.title,
                hidePage: false,
                indexable
            },
            false,
            true
        )
    }

    function removePageFromRouter(ps, routerPtr, pageId) {
        const routerData = ps.dal.get(routerPtr)
        routerData.pages = _.omitBy(routerData.pages, function (currentPageId) {
            return pageId === currentPageId
        })
        ps.dal.set(routerPtr, routerData)
    }

    function movePageToNewRouter(ps, pagePtr, originRouterPtr, desRouterPtr) {
        const pageRouterPtr = routersGetters.get.byPage(ps, pagePtr)

        if (!pageRouterPtr) {
            throw new Error('cannot move a static page')
        }

        if (!ps.pointers.isSamePointer(originRouterPtr, pageRouterPtr)) {
            throw new Error('page is not related to the origin router')
        }

        const originRouterData = ps.dal.get(originRouterPtr)
        const desRouterData = ps.dal.get(desRouterPtr)

        if (originRouterData && desRouterData && originRouterData.appDefinitionId !== desRouterData.appDefinitionId) {
            throw new Error('cannot move a page that not related to app')
        }

        if (ps.pointers.isSamePointer(originRouterPtr, desRouterPtr)) {
            return
        }

        const pageRoles = getPageRoles(originRouterData.pages, pagePtr.id)
        disconnectPageFromRouter(ps, originRouterPtr, pagePtr)
        connectPageToRouter(ps, desRouterPtr, pagePtr, pageRoles)
    }

    function canBeDynamic(ps, currentPageData, homePageId, pageRef) {
        return (
            currentPageData.type === 'Page' && //it is a page (and not AppPage for example)
            !(currentPageData.tpaApplicationId > 0) && //page is not a TPA page
            !routersGetters.get.byPage(ps, pageRef) && //page is not a dynamic page
            homePageId !== currentPageData.id //page is not the home page
        )
    }

    function listConnectablePages(ps) {
        const pageList = pageData.getPagesDataItems(ps)
        const homePageId = page.homePage.get(ps)

        return _.reduce(
            pageList,
            function (res, currentPageData) {
                const pageRef = page.getReference(ps, currentPageData.id)
                if (canBeDynamic(ps, currentPageData, homePageId, pageRef)) {
                    res.push({pageRef, pageData: currentPageData})
                }
                return res
            },
            []
        )
    }

    function isConnectablePage(ps, pageId) {
        const homePageId = page.homePage.get(ps)
        const thePageData = pageData.getPageData(ps, pageId)
        if (!thePageData) {
            return false
        }
        const pageRef = page.getReference(ps, pageId)
        return canBeDynamic(ps, thePageData, homePageId, pageRef)
    }

    function getDynamicPagesList(ps) {
        // @ts-ignore
        return _.map(routersGetters.get.all(ps), ({appDefinitionId, pages, prefix, group, hide = false} = {}) => {
            const {appDefinitionName} = platform.getAppDataByAppDefId(ps, appDefinitionId) || {}
            return {
                appDefinitionId,
                appDefinitionName,
                prefix,
                group,
                pages: _(pages)
                    .values()
                    .uniq()
                    .map(pageId => pageData.getPageData(ps, pageId))
                    .value(),
                hide
            }
        })
    }

    function buildIsValidPrefixReturnVal(isValid, errorCode, metadata) {
        return {
            valid: isValid,
            message: errorCode,
            errorCode,
            metadata
        }
    }

    function getRouterAppDefinitionId(ps, prefix) {
        const routerPointer = routersGetters.get.byPrefix(ps, prefix)
        if (routerPointer) {
            const routerData = ps.dal.get(routerPointer)
            return routerData.appDefinitionId
        }
    }

    function isValidPrefix(ps, applicationId, prefix) {
        if (!prefix) {
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CAN_NOT_BE_EMPTY)
        }

        const appDefinitionId = _.get(platform.getAppDataByApplicationId(ps, applicationId), 'appDefinitionId')
        const routerAppDefinitionId = getRouterAppDefinitionId(ps, prefix)
        if (routerAppDefinitionId) {
            //existing prefix
            if (routerAppDefinitionId === appDefinitionId) {
                return buildIsValidPrefixReturnVal(true, '')
            }
            const routerAppDefinitionName = _.get(platform.getAppDataByAppDefId(ps, routerAppDefinitionId), 'appDefinitionName')
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION, {
                appDefinitionName: routerAppDefinitionName
            })
        }

        if (pageData.isPageUriSeoTooLong(prefix)) {
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_TOO_LONG)
        }
        if (pageData.isDuplicatePageUriSeo(ps, null, prefix)) {
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_DUPLICATE_OF_URI_SEO, {
                pageTitle: pageData.getDuplicatePageTitle(ps, null, prefix)
            })
        }
        if (pageData.hasIllegalChars(prefix)) {
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CONTAINS_INVALID_CHARACTERS)
        }

        const forbiddenWordsPointer = ps.pointers.general.getForbiddenPageUriSEOs()
        const forbiddenWordsArr = ps.dal.get(forbiddenWordsPointer) || {}
        if (forbiddenWordsArr[prefix]) {
            return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_FORBIDDEN_WORD)
        }
        return buildIsValidPrefixReturnVal(true, '')
    }

    function reloadRouterData(ps) {
        const currentNavigationInfo = ps.siteAPI.getRootNavigationInfo()
        if (!currentNavigationInfo || !currentNavigationInfo.routerDefinition) {
            return
        }

        const navInfo = {
            innerRoute: currentNavigationInfo.innerRoute,
            routerDefinition: currentNavigationInfo.routerDefinition,
            pageAdditionalData: currentNavigationInfo.pageAdditionalData
        }
        ps.siteAPI.navigateToPage(navInfo)
        ps.siteAPI.registerNavigationComplete(() => {
            hooks.executeHook(hooks.HOOKS.ROUTER.DATA_RELOADED)
        })
    }

    function subscribeToConcurrentRoutersInvalidation(ps, listener) {
        hooks.registerHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, listener)
    }

    function notifyConcurrentRoutersInvalidation(ps, currentRouteInfo, newSelectedRoute) {
        extensionsAPI.livePreviewSharedState.notifyRoutersConcurrentInvalidationStateChanged(ps, currentRouteInfo, newSelectedRoute)
    }

    const initialize = ps => {
        extensionsAPI.livePreviewSharedState.subscribeToConcurrentRoutersInvalidation(ps, (originEditorCurrentRouteInfo, selectedRoute) => {
            const routerIdChanged = originEditorCurrentRouteInfo.routerId
            const currentRouteInfo = getCurrentInnerRoute(ps)
            const routerId = _.get(currentRouteInfo, ['routerId'])
            if (routerId === routerIdChanged) {
                hooks.executeHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, undefined, [
                    _.isEqual(currentRouteInfo, originEditorCurrentRouteInfo) ? selectedRoute : null
                ])
            }
        })
    }

    return {
        initialize,
        add: addRouter,
        remove: removeRouter,
        get: {
            all: routersGetters.get.all,
            byApp: routersGetters.get.byApp,
            byRef: routersGetters.get.byRef,
            byId: routersGetters.get.byId
        },
        getRouterRef: {
            byPrefix: routersGetters.get.byPrefix,
            byPage: routersGetters.get.byPage
        },
        update: updateRouter,
        getRouterDataForPageIfExist: routersGetters.getRouterDataForPageIfExist,
        getPageFromInnerRoute,
        getRouterInnerRoutes,
        getRouterInnerRoutesCount,
        getRouterSiteMap,
        getTpaInnerRoutes,
        getCurrentInnerRoute,
        isValidPrefix,
        pages: {
            add: addNewRouterPage,
            connect: connectPageToRouter,
            disconnect: disconnectPageFromRouter,
            removePageFromRouter,
            listConnectablePages,
            isConnectablePage,
            getDynamicPagesList,
            move: movePageToNewRouter
        },
        getRouterPointer,
        getPageToAddPointer,
        reloadRouterData,
        subscribeToConcurrentRoutersInvalidation,
        notifyConcurrentRoutersInvalidation
    }
})
