import _ from 'lodash'

const OOI_PREVIEW_WIDGET_BLACKLIST = [
    // bookings list widget
    '621bc837-5943-4c76-a7ce-a0e38185301f',
    // stores - product gallery
    '13afb094-84f9-739f-44fd-78d036adb028',
    // stores
    '1380bba0-253e-a800-a235-88821cf3f8a4'
]

function mapInAlphabeticalOrder(sourceObj, func, context?) {
    const boundFunc = context ? _.bind(func, context) : func
    const orderedKeys = _.keys(sourceObj).sort()
    const orderedObj = _.reduce(
        orderedKeys,
        function (obj, key) {
            obj[key] = sourceObj[key]
            return obj
        },
        {}
    )

    return _.map(orderedObj, boundFunc)
}

function noAppsOnSite(siteData) {
    return _.isEmpty(siteData.pagesPlatformApplications) // User only opened platform-dev-tools
}

function hasPlatformAppOnRenderedRoots(siteData, rootIds) {
    rootIds = rootIds.concat('masterPage')
    const {pagesPlatformApplications} = siteData
    return _.some(pagesPlatformApplications, function (app) {
        // At least one rendered root has an app
        const rootIdsWithApp = _.keys(app)
        return _.some(rootIds, function (rootId) {
            return _.includes(rootIdsWithApp, rootId)
        })
    })
}

function isWidgetExcludedFromOOIPreview(widgetId) {
    return _.includes(widgetId, OOI_PREVIEW_WIDGET_BLACKLIST)
}

export default {
    mapInAlphabeticalOrder,
    noAppsOnSite,
    hasPlatformAppOnRenderedRoots,
    isWidgetExcludedFromOOIPreview
}
