import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixappsCore from '@wix/santa-ds-libs/src/wixappsCore'
import transformAndSetMetaData from '@wix/santa-ds-libs/src/wixappsClassics/src/core/transformAndSetMetaData'

const {urlUtils} = coreUtils
const {wixappsDataHandler} = wixappsCore

function getRequest(siteData, compData, appService) {
    if (appService.packageName !== 'blog') {
        return []
    }
    const PATH = ['wixapps', 'blog', 'hasSettingsRequested']

    if (_.get(siteData, PATH)) {
        return []
    }
    _.set(siteData, PATH, true)

    const url = `${urlUtils.baseUrl(siteData.getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`

    const data = {
        collectionId: 'Settings',
        filter: {_iid: 'blogSettings'},
        storeId: appService.datastoreId
    }

    const transformFunc = function (responseData, currentValue) {
        currentValue.settings = _.get(responseData.payload, ['items', '0', 'settingsObject'], {})
        return currentValue
    }

    return [
        {
            force: true,
            destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
            url,
            data,
            transformFunc: transformAndSetMetaData.bind(this, transformFunc, siteData, appService.packageName, compData.id)
        }
    ]
}

function getBlogSettings(siteData) {
    const blogData = wixappsDataHandler.getPackageData(siteData, 'blog')
    const defaultSettings = {
        locale: 'en',
        email: false
    }

    return _.get(blogData, ['settings'], defaultSettings)
}

export default {
    getRequest,
    get: getBlogSettings
}
