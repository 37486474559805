define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/appControllerData/appControllerStageData',
    'documentServices/dataModel/dataModel'
], function (_, coreUtils, metaDataUtils, appControllerStageData, dataModel) {
    'use strict'

    const ALLOWED_CONTAINER_TYPES = [
        'core.components.Page',
        'mobile.core.components.Page',
        'wixapps.integration.components.AppPage',
        'responsive.components.Section',
        'responsive.components.HeaderSection',
        'responsive.components.FooterSection',
        'responsive.components.MembersAreaSection',
        'wysiwyg.viewer.components.PopupContainer',
        'wysiwyg.viewer.components.ClassicSection'
    ]

    function isContainableByRef(ps, controllerRef, containerPointer) {
        const controllerData = dataModel.getDataItem(ps, controllerRef)
        const toggleShowOnAllPagesEnabled =
            getControllerStageData(ps, controllerRef.id, controllerData, 'enableShowOnAllPages') !== false &&
            getControllerStageData(ps, controllerRef.id, controllerData, 'toggleShowOnAllPagesEnabled') !== false
        return isContainable(ps, containerPointer, toggleShowOnAllPagesEnabled)
    }

    function isContainableByStructure(ps, controllerStructure, containerPointer) {
        return isContainable(ps, containerPointer, true)
    }

    function isContainable(ps, containerPointer, toggleShowOnAllPagesEnabled) {
        const potentialContainerCompType = metaDataUtils.getComponentType(ps, containerPointer)
        const showOnAllPages = toggleShowOnAllPagesEnabled && metaDataUtils.isHeaderOrFooterOrPageOrMasterPage(potentialContainerCompType)
        return (
            showOnAllPages || _.includes(ALLOWED_CONTAINER_TYPES, potentialContainerCompType) || metaDataUtils.isPopupPageOrPopupContainer(ps, containerPointer)
        )
    }

    function isRemovable(ps, controllerRef) {
        const controllerData = dataModel.getDataItem(ps, controllerRef)
        return getControllerStageData(ps, controllerRef.id, controllerData, 'removable') !== false
    }

    function isDuplicatable(ps, controllerRef) {
        const controllerData = dataModel.getDataItem(ps, controllerRef)
        return getControllerStageData(ps, controllerRef.id, controllerData, 'duplicatable') !== false
    }

    function getControllerStageData(ps, controllerId, controllerData, path) {
        const controllerStageData = appControllerStageData.getControllerStageData(ps, controllerId, controllerData.controllerType, controllerData.applicationId)
        return _.get(controllerStageData, path)
    }

    function isVisible(ps, controllerRef) {
        const controllerData = dataModel.getDataItem(ps, controllerRef)
        const {applicationId, controllerType} = controllerData
        const appManifestPointer = ps.pointers.platform.getAppManifestPointer(controllerData.applicationId)
        const appManifestExists = ps.dal.isExist(appManifestPointer)
        const controllerStageData = appControllerStageData.getControllerStageData(ps, controllerRef.id, controllerType, applicationId)
        const showControllersPointer = ps.pointers.general.getRenderFlag('showControllers')
        const showControllers = ps.dal.get(showControllersPointer)
        const componentViewMode = ps.dal.get(ps.pointers.general.getRenderFlag('componentViewMode'))
        return (
            componentViewMode === 'editor' &&
            !ps.siteAPI.isMobileView() &&
            coreUtils.appManifest.controllerStageData.isControllerVisible(controllerStageData, appManifestExists, showControllers)
        )
    }

    return {
        containable: isContainableByRef,
        containableByStructure: isContainableByStructure,
        container: false,
        canContain: false,
        canContainByStructure: false,
        resizableSides: [],
        alignable: false,
        hiddenable: false,
        collapsible: false,
        fullWidth: false,
        fullWidthByStructure: false,
        canBeFixedPosition: true,
        groupable: false,
        mobileConversionConfig: {
            nonLayoutComponent: true
        },
        removable: isRemovable,
        duplicatable: isDuplicatable,
        crossSiteDuplicatable: false,
        nickname: metaDataUtils.getControllerNickname,
        isVisible
    }
})
