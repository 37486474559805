import * as _ from 'lodash'
import * as conversionUtils from './conversionUtils'
import {conversionConfig} from './conversionConfig'
import {Component, MasterPageComponent, ConversionData, Layout, ComponentWithConversionData} from '../types'

export function flattenGroups(parent: Component | MasterPageComponent, comps: Component[]): void {
    if (!comps) {
        return
    }

    const virtualGroupsToRemove = []
    let childIndex = 0
    while (childIndex < comps.length) {
        const child = comps[childIndex]
        if (conversionUtils.isVirtualGroup(child)) {
            conversionUtils.translateComps(child.components, child.layout.x, child.layout.y)
            conversionUtils.addComponentsTo(parent, child.components, childIndex + 1)
            virtualGroupsToRemove.push(child)
        }
        childIndex++
    }
    conversionUtils.removeChildrenFrom(parent, virtualGroupsToRemove)
    _.forEach(comps, comp => flattenGroups(comp, comp.components))
}

let nextGroupId = 1000

export interface VirtualGroupData {
    componentType: string
    conversionData: ConversionData
    layout: Layout
    components: ComponentWithConversionData[]
}

export function createVirtualComponent(virtualGroupData: VirtualGroupData): ComponentWithConversionData {
    const virtualGroupComp = <ComponentWithConversionData>_.assign(virtualGroupData, {
        type: 'undefined',
        id: `${_.toLower(virtualGroupData.componentType)}_${nextGroupId++}`,
        skin: 'undefined',
        styleId: 'undefined'
    })

    return virtualGroupComp
}

export function createRescaleGroup(parent, components, conversionData, index?: number): ComponentWithConversionData {
    const virtualGroup = createVirtualComponent({
        layout: conversionUtils.getSnugLayout(components),
        conversionData: _.assign(conversionData, {
            hasTightYMargin: true,
            isTightContainer: true,
            marginX: 0
        }),
        componentType: conversionConfig.VIRTUAL_GROUP_TYPES.RESCALE,
        components
    })
    conversionUtils.translateComps(components, -virtualGroup.layout.x, -virtualGroup.layout.y)
    conversionUtils.removeChildrenFrom(parent, components)
    conversionUtils.addComponentsTo(parent, [virtualGroup], index)
    return virtualGroup
}

export function reset() {
    nextGroupId = 1000
}
