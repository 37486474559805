function getYoutubeId(url) {
    let videoId = ''

    // Test for long youtube url: http://youtube.com/watch?[...&]v=[VIDEO_ID]
    const YTLongUrl = /(?:youtube\.com\/watch[^\s]*[\?&]v=)([\w-]+)/g
    // Test for short youtube url: http://youtu.be/[VIDEO_ID]
    const YTShortUrl = /(?:youtu\.be\/)([\w-]+)/g

    const match = YTLongUrl.exec(url) || YTShortUrl.exec(url)
    if (match && match.length && match[1]) {
        //if there is a match, the second group is the id we want
        videoId = match[1]
    }
    return videoId
}

function getVimeoId(url) {
    let videoId = ''

    const VimeoUrl = /vimeo\.com\/(\d+)$/gi

    const match = VimeoUrl.exec(url)
    if (match && match.length && match[1]) {
        //if there is a match, the second group is the id we want
        videoId = match[1]
    }
    return videoId
}

function getVideoDataFromVideoUrl(url) {
    let videoType = null
    let videoId = getYoutubeId(url)
    if (videoId) {
        videoType = 'YOUTUBE'
    } else {
        videoId = getVimeoId(url)
        if (videoId) {
            videoType = 'VIMEO'
        }
    }

    if (videoId && videoType) {
        return {
            videoId,
            videoType
        }
    }
    return {}
}

function convertVideoUrl(videoURL) {
    const videoData = getVideoDataFromVideoUrl(videoURL)
    if (videoData && videoData.videoId && videoData.videoType) {
        // @ts-ignore
        videoData._type = 'wix:Video'
        return videoData
    }
    return {_type: 'MediaItem'}
}

export default {
    convertVideoUrl
}
