define([
    'lodash',
    '@wix/mobile-conversion',
    'documentServices/utils/utils',
    'documentServices/bi/errors',
    'documentServices/mobileConversion/bi/events',
    'documentServices/mobileConversion/modules/mobilePropertiesHandler',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsHandler',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/modules/mobileHints',
    'documentServices/mobileConversion/modules/conversionSettings',
    'documentServices/mobileConversion/modules/componentReducer',
    'documentServices/mobileConversion/modules/structuresComparer',
    'documentServices/mobileConversion/modules/modesConverter',
    'documentServices/mobileConversion/modules/tinyMenuHandler',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/santa-core-utils',
    'documentServices/hooks/hooks',
    'documentServices/constants/constants',
    'documentServices/mobileConversion/modules/textStats',
    'documentServices/mobileConversion/modules/mobileHintsValidator',
    'documentServices/mobileConversion/modules/nonLayoutComponentHandler',
    'documentServices/mobileConversion/modules/mobileOnlyComponents',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/dataModel/dataModel',
    'documentServices/mobileConversion/modules/userModifiedComponentHandler',
    'documentServices/mobileConversion/modules/mobileConversionFedops',
    'documentServices/mobileConversion/modules/utils',
    'experiment'
], function (
    _,
    mobileCore,
    dsUtils,
    biErrors,
    events,
    mobilePropertiesHandler,
    mobilePresetsHandler,
    mergeAggregator,
    mobileHints,
    conversionSettingsBuilder,
    componentReducer,
    structuresComparer,
    modesConverter,
    tinyMenuHandler,
    coreUtils,
    santaCoreUtils,
    hooks,
    constants,
    textStats,
    mobileHintsValidator,
    nonLayoutComponentHandler,
    mobileOnlyComponents,
    extensionsAPI,
    dataModel,
    userModifiedComponentHandler,
    mobileConversionFedops,
    utils,
    experiment
) {
    'use strict'

    function executePostConversionOperations(ps, parameters) {
        const updatedPagePointers = _.map(parameters.updatedPageIds, pageId => ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE))
        _.forEach(updatedPagePointers, _.partial(modesConverter.convertModes, ps))
        hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, null, [ps, updatedPagePointers, parameters.commitConversionResults])
        if (parameters.commitConversionResults) {
            mergeAggregator.commitResults(ps)
        }
        _.forEach(parameters.updatedPageIds, pageId =>
            ps.siteAPI.createPageAnchors(pageId, {
                forceMobileStructure: true,
                applyGroupingAnchors: true
            })
        )
    }

    function resolvePresets(ps, heuristicStrategy, comps, page) {
        mobilePresetsHandler.applyPresetsToConversionData(ps, comps, page.id)
        _.forEach(comps, component => {
            const preset = _.get(component, ['conversionData', 'preset'])
            if (!preset) {
                if (_.has(component, ['conversionData', 'mobileProps'])) {
                    mobilePropertiesHandler.createPropertiesAccordingToConversionData(ps, component, page.id)
                }
                return
            }
            const shouldReportBI = Math.random() < 0.1
            if (preset.conversionData.isCustomPreset && shouldReportBI) {
                ps.siteAPI.reportBI(events.events.CUSTOM_MOBILE_PRESET_APPLIED, {
                    componentId: component.id,
                    pageId: page.id
                })
            }
            const conversionSettings = conversionSettingsBuilder.getConversionSettings({
                conversionType: 'MERGE_PRESET',
                heuristicStrategy
            })
            mobilePropertiesHandler.createPropertiesAccordingToPreset(ps, preset, page.id)
            mobileCore.conversion.mergeDesktopStructureWithMobile(component, preset, conversionSettings)
            _.set(component, ['conversionData', 'preset'], preset)
        })
        return page
    }
    /**
     * After deleting components by mobile algo from the page it still exists in dal,
     * and this prevents us from deleting page of TPA page in the same session.
     * This is a reason for this issue https://jira.wixpress.com/browse/WEED-22664
     * @param {*} ps
     */
    const removeDeadComponents = ps => {
        if (!ps.pointers.data.getMobileComponentsByDataType) {
            return
        }
        const removedComponents = ps.pointers.data.getMobileComponentsByDataType(constants.DEAD_MOBILE_COMPONENT_TYPE)
        removedComponents.forEach(pointer => ps.dal.full.remove(pointer))
    }

    function applyConvertedComponentsToPage(ps, mobileComponents, pageId, postConversionParameters, removePreviousMobileStructure) {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        const mobileComponentsPointer = ps.pointers.getInnerPointer(pagePointer, 'mobileComponents')

        cleanUpShouldBeForceConvertedMark(ps, mobileComponents, pageId)

        if (removePreviousMobileStructure) {
            extensionsAPI.pages.removeMobileStructure(ps, pageId)
        }
        ps.dal.full.set(mobileComponentsPointer, mobileComponents)
        mobileHints.updateHiddenComponentsOnPage(ps, pageId)
        mobileHints.markPageAsInitialized(ps, pageId)
        removeDeadComponents(ps)
        if (pageId === constants.MASTER_PAGE_ID) {
            tinyMenuHandler.applyDataToTinyMenu(ps)
            tinyMenuHandler.addAdditionalStyles(ps)
        }
        if (postConversionParameters) {
            executePostConversionOperations(ps, {
                updatedPageIds: [pageId],
                commitConversionResults: postConversionParameters.commitConversionResults
            })
        }
    }

    function applyConvertedComponentsMap(ps, mobileComponentsMap, postConversionParameters, removePreviousMobileStructure) {
        _.forEach(mobileComponentsMap, (mobileComponents, pageId) =>
            applyConvertedComponentsToPage(ps, mobileComponents, pageId, undefined, removePreviousMobileStructure)
        )
        if (postConversionParameters) {
            executePostConversionOperations(ps, {
                updatedPageIds: _.keys(mobileComponentsMap),
                commitConversionResults: postConversionParameters.commitConversionResults
            })
        }
    }

    function prepareConversion(ps, heuristicStrategy, page, mobilePage) {
        const allComps = _.values(mobileCore.conversionUtils.getAllCompsInStructure(page)).concat(mobileCore.conversionUtils.isMasterPage(page) ? [] : [page])
        const override = !mobilePage
        page.mobileComponents = []
        _.forEach(allComps, component => {
            const mobileComponent = mobilePage ? mobileCore.conversionUtils.getComponentByIdFromStructure(component.id, mobilePage) || component : component
            if (mobileComponent !== component) {
                const designQueryProperty = 'designQuery'
                const desktopDesignQuery = component[designQueryProperty]
                const mobileDesignQuery = mobileComponent[designQueryProperty]
                if (desktopDesignQuery !== mobileDesignQuery) {
                    const designQueryPointer = ps.pointers.data.getDesignItem(dsUtils.stripHashIfExists(mobileDesignQuery))
                    if (!ps.dal.isExist(designQueryPointer)) {
                        mobileComponent.designQuery = desktopDesignQuery
                    }
                }
            }
            const modifiedMobilePropsQuery = mobilePropertiesHandler.modifyComponentProperties(ps, component, page.id, {override})
            const currentMobilePropsPointer =
                mobileComponent.propertyQuery && ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(mobileComponent.propertyQuery), page.id)
            const keepMobilePropsByDefault = !override && currentMobilePropsPointer && ps.dal.isExist(currentMobilePropsPointer)
            const defaultMobileProps = keepMobilePropsByDefault ? mobileComponent.propertyQuery : component.propertyQuery
            component.propertyQuery = mobileComponent.propertyQuery = modifiedMobilePropsQuery || defaultMobileProps
            componentReducer.createConversionData(ps, component, page.id)
        })

        if (mobilePage) {
            const allMobileComponents = mobileCore.conversionUtils.getAllCompsInStructure(mobilePage)
            const mobileOnlyComponentsThatNeedConversionData = _.filter(allMobileComponents, comp => mobileOnlyComponents.isNeedConversionData(ps, comp))
            _.forEach(mobileOnlyComponentsThatNeedConversionData, component => componentReducer.createConversionDataForMobileOnlyComp(ps, component, page.id))
        }

        textStats.reduceAllTextsData(ps, allComps, page.id)
        return resolvePresets(ps, heuristicStrategy, allComps, _.set(page, 'conversionData', _.get(page, 'conversionData', {})))
    }

    function addPagesMissingInMobileSite(ps, heuristicStrategy, fullConversionSettings, desktopSite, mobileSite) {
        const missingInMobile = _.difference(_.keys(desktopSite), _.keys(mobileSite))

        _.forEach(missingInMobile, pageId => {
            const desktopPage = desktopSite[pageId]
            const mobilePage = santaCoreUtils.objectUtils.cloneDeep(desktopPage)
            mobileSite[pageId] = mobilePage
            prepareConversion(ps, heuristicStrategy, mobilePage)
            const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage)
            mobileCore.conversion.convertDesktopPageToMobile(mobilePage, [], fullConversionSettings)
            nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
        })
    }

    function inspect(ps, heuristicStrategy, settings) {
        const allPageIds = extensionsAPI.pages.getAllPagesIds(ps)
        const pagesData = allPageIds.reduce((acc, pageId) => {
            acc[pageId] = ps.dal.get(ps.pointers.page.getPagePointer(pageId))
            return acc
        }, {})
        return _.transform(
            pagesData,
            (inspectionData, pageData, pageId) => {
                const compsMap = pageData.structure.DESKTOP
                const page = santaCoreUtils.mobileUtils.buildDeepStructure(compsMap[pageId], compsMap)
                prepareConversion(ps, heuristicStrategy, page)
                mobileCore.conversion.inspectStructure(page, mobileCore.conversionUtils.getChildren(page), settings)
                const compsOrder = _.transform(
                    mobileCore.conversionUtils.getAllCompsInStructure(page),
                    (res, comp) => {
                        if (!_.isEmpty(_.get(comp, ['conversionData', 'componentsOrder']))) {
                            res[comp.id] = comp.conversionData.componentsOrder
                        }
                        return res
                    },
                    {}
                )
                inspectionData.reducedPages.push(page)
                inspectionData.componentsOrder.push(compsOrder)
                return inspectionData
            },
            {reducedPages: [], componentsOrder: []}
        )
    }

    function getComponentsOrder(ps, pageId) {
        return utils.getComponentsOrderInStructure(ps, pageId)
    }

    function resetModifiedComponents(ps, desktopStructure) {
        const allChildren = mobileCore.conversionUtils.getAllCompsInStructure(desktopStructure, false)
        _.forOwn(allChildren, comp => {
            const mobileHintsItem = dataModel.getMobileHintsItem(ps, {id: comp.id, type: 'DESKTOP'})

            if (mobileHintsItem && userModifiedComponentHandler.isModifiedByUser(mobileHintsItem)) {
                userModifiedComponentHandler.unmarkComponentAsTouched(ps, comp.id, {id: comp.id, type: 'DESKTOP'})
            }
        })
    }

    function runMobileConversionOnPage(ps, heuristicStrategy, fullConversionSettings, page) {
        const enableNewMergeFlow = utils.shouldEnableNewMergeFlow(ps)
        coreUtils.loggingUtils.performance.start(coreUtils.loggingUtils.performanceMetrics.MOBILE.CONVERT_PAGE)

        mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_PAGE)

        const mobilePage = santaCoreUtils.objectUtils.cloneDeep(page)
        if (enableNewMergeFlow) {
            resetModifiedComponents(ps, mobilePage)
        }
        prepareConversion(ps, heuristicStrategy, mobilePage)
        const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(page, mobilePage)
        const components = mobileCore.conversionUtils.getChildren(mobilePage)
        const mobileComponents = mobileCore.conversion.convertDesktopPageToMobile(mobilePage, components, fullConversionSettings)
        nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
        applyConvertedComponentsToPage(ps, mobileComponents, page.id, {commitConversionResults: false}, true)
        coreUtils.loggingUtils.performance.finish(coreUtils.loggingUtils.performanceMetrics.MOBILE.CONVERT_PAGE)
        mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_PAGE)
    }

    function runMobileConversionOnAllPages(ps, heuristicStrategy, fullConversionSettings, commitConversionResults = true) {
        mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES)

        const desktopPages = _.get(mergeAggregator.getAggregatedPages(ps), 'desktop')
        const mobileComponentsMap = _.mapValues(desktopPages, page => {
            const mobilePage = prepareConversion(ps, heuristicStrategy, santaCoreUtils.objectUtils.cloneDeep(page))
            const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(page, mobilePage)
            const components = mobileCore.conversion.convertDesktopPageToMobile(
                mobilePage,
                mobileCore.conversionUtils.getChildren(mobilePage),
                fullConversionSettings
            )

            nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
            return components
        })
        applyConvertedComponentsMap(ps, mobileComponentsMap, {commitConversionResults}, true)
        mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES)
    }

    function syncMobileSite(ps, heuristicStrategy, settings) {
        const pages = mergeAggregator.getAggregatedPages(ps)
        if (_.isEmpty(pages)) {
            return
        }
        const pagesMissingInMobile = _.difference(_.keys(pages.desktop), _.keys(pages.mobile))
        _.forEach(pagesMissingInMobile, pageId => {
            const mobilePage = santaCoreUtils.objectUtils.cloneDeep(pages.desktop[pageId])
            pages.mobile[pageId] = mobilePage
        })
        const disableMobileHintsValidation =
            settings?.skipMobileHintsValidation || ps.config.disableMobileConversion || experiment.isOpen('dm_disableMobileHintsValidationInADI')
        if (!disableMobileHintsValidation) {
            mobileHintsValidator.validateMobileHintsOnPages(ps, _.keys(pages.desktop))
        }
        const nonLayoutCompsMap = {}
        _.forOwn(pages.desktop, (desktopPage, pageId) => {
            const mobilePage = pages.mobile[pageId]
            prepareConversion(ps, heuristicStrategy, desktopPage, mobilePage)
            nonLayoutCompsMap[pageId] = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage)
            mobileOnlyComponents.handleComponentsBeforeMobileAlgo(desktopPage, mobilePage)
        })
        const mobileComponentsMap = mobileCore.conversion.synchronizePages(pages.desktop, pages.mobile)
        _.forOwn(pages.desktop, (desktopPage, pageId) => {
            const mobilePage = pages.mobile[pageId]
            nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutCompsMap[pageId])
            mobileOnlyComponents.handleComponentsAfterMobileAlgo(desktopPage, mobilePage)
        })
        applyConvertedComponentsMap(ps, mobileComponentsMap, {commitConversionResults: true}, true)
    }

    function runMobileMergeOnPage(ps, heuristicStrategy, desktopPage, mobilePage, settings) {
        const enableNewMergeFlow = utils.shouldEnableNewMergeFlow(ps)

        mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ONE_PAGE)

        settings.enableNewMergeFlow = enableNewMergeFlow
        settings.enableImprovedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)

        prepareConversion(ps, heuristicStrategy, desktopPage, mobilePage)

        const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage)
        mobileOnlyComponents.handleComponentsBeforeMobileAlgo(desktopPage, mobilePage)
        const commitConversionResults = settings.commitConversionResults || false
        const mobileComponents = mobileCore.conversion.mergeDesktopStructureWithMobile(desktopPage, mobilePage, settings)

        nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
        mobileOnlyComponents.handleComponentsAfterMobileAlgo(desktopPage, mobilePage)
        applyConvertedComponentsToPage(ps, mobileComponents, desktopPage.id, {commitConversionResults}, true)

        mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ONE_PAGE)
    }

    function isValidDesktopStructure(ps) {
        const {DESKTOP} = constants.VIEW_MODES
        const COMP = ps.pointers.components
        return _.every([COMP.getFooter(DESKTOP), COMP.getHeader(DESKTOP), COMP.getPagesContainer(DESKTOP)], ps.dal.isExist)
    }

    /**
     * Removes shouldBeForceConverted mark after any mobile merge run
     * @param {*} ps
     * @param {*} rootMobileComponents
     * @param {*} pageId
     */
    const cleanUpShouldBeForceConvertedMark = (ps, rootMobileComponents, pageId) => {
        _.forOwn(rootMobileComponents, comp => {
            const children = mobileCore.conversionUtils.getChildren(comp)
            if (children) {
                cleanUpShouldBeForceConvertedMark(ps, children, pageId)
            }
            const mobileHintsItem = dataModel.getMobileHintsItemById(ps, comp.mobileHintsQuery, pageId)

            if (!mobileHintsItem || !mobileHintsItem.shouldBeForceConverted) {
                return
            }

            mobileHints.updateProperty(
                ps,
                {
                    shouldBeForceConverted: undefined
                },
                ps.pointers.getPointer(comp.id, constants.VIEW_MODES.DESKTOP),
                {},
                pageId
            )
        })
    }

    function runMobileMergeOnAllPages(ps, heuristicStrategy, fullConversionSettings, commitConversionResults) {
        mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)

        if (!isValidDesktopStructure(ps)) {
            ps.siteAPI.reportBI(biErrors.MOBILE_STRUCTURE_NOT_SAVED_DUE_TO_CORRUPTION)
            return
        }
        // in this case we run optimize layout to generate base mobile structure
        if (!structuresComparer.hasMobileStructure(ps)) {
            runMobileConversionOnAllPages(ps, heuristicStrategy, fullConversionSettings, commitConversionResults)
            mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)
            return
        }
        hooks.executeHook(hooks.HOOKS.MOBILE_MERGE.BEFORE, null, [ps])
        const pagesData = mergeAggregator.getAggregatedPages(ps)
        if (_.isEmpty(pagesData)) {
            return
        }
        const desktopPages = pagesData.desktop
        let mobilePages = pagesData.mobile

        if (fullConversionSettings.skipLayoutOverwrite) {
            /**
             * it means it was run for sectionMigration
             */
            mobilePages = {}
            _.forOwn(desktopPages, (value, key) => {
                /**
                 * Use mobile components form desktopPages because it contains actual mobile component instead of
                 * commitedPages that contains out of date mobile components with previous layout
                 */
                const mobilePage = mobileCore.conversionUtils.extractMobilePage(value)
                if (mobilePage) {
                    mobilePages[key] = mobilePage
                }
            })
        }

        addPagesMissingInMobileSite(ps, heuristicStrategy, fullConversionSettings, desktopPages, mobilePages)
        const nonLayoutCompsMap = new Map()
        // prepare all pages
        _.forOwn(desktopPages, (desktopPage, pageId) => {
            const mobilePage = mobilePages[pageId]
            prepareConversion(ps, heuristicStrategy, desktopPage, mobilePage)
            nonLayoutCompsMap.set(pageId, nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage))
            mobileOnlyComponents.handleComponentsBeforeMobileAlgo(desktopPage, mobilePage)
        })

        const fixMasterPageStructureOrder = shouldFixMasterPageStructureOrder(desktopPages, mobilePages)

        const settings = conversionSettingsBuilder.getConversionSettings({
            conversionType: 'MERGE_ALL_PAGES',
            heuristicStrategy
        })
        const enableNewMergeFlow = utils.shouldEnableNewMergeFlow(ps)

        settings.fixMasterPageStructureOrder = fixMasterPageStructureOrder
        settings.enableNewMergeFlow = enableNewMergeFlow
        settings.enableImprovedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)
        settings.skipLayoutOverwrite = fullConversionSettings.skipLayoutOverwrite
        settings.keepEmptyTextComponents = fullConversionSettings.keepEmptyTextComponents
        settings.keepNotRecommendedMobileComponents = fullConversionSettings.keepNotRecommendedMobileComponents

        const pagesIdsToMobileComponentsMap = mobileCore.conversion.mergeDesktopPagesWithMobile(desktopPages, mobilePages, settings)

        _.forOwn(desktopPages, (desktopPage, pageId) => {
            const mobilePage = mobilePages[pageId]
            nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutCompsMap.get(pageId))
            mobileOnlyComponents.handleComponentsAfterMobileAlgo(desktopPage, mobilePage, enableNewMergeFlow)
        })
        applyConvertedComponentsMap(ps, pagesIdsToMobileComponentsMap, {commitConversionResults}, fullConversionSettings.skipLayoutOverwrite)

        coreUtils.loggingUtils.performance.finish(coreUtils.loggingUtils.performanceMetrics.MOBILE.MERGE_ALL_PAGES)

        mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)
    }

    const shouldFixMasterPageStructureOrder = (desktopSite, mobileSite) => {
        const desktopMasterPage = _.get(desktopSite, constants.MASTER_PAGE_ID, null)
        if (!desktopMasterPage) {
            return false
        }

        const mobileMasterPage = _.get(mobileSite, constants.MASTER_PAGE_ID, null)
        if (!mobileMasterPage) {
            return true
        }
        const desktopChildren = mobileCore.conversionUtils.getChildren(desktopMasterPage)
        const mobileChildren = mobileCore.conversionUtils.getChildren(mobileMasterPage)
        const SOSP_COMP = {id: 'SOSP_CONTAINER_CUSTOM_ID'}
        return _.some(desktopChildren, SOSP_COMP) && !_.some(mobileChildren, SOSP_COMP)
    }

    const executeConversion = (fullConversionSettings, page) =>
        mobileCore.conversion.convertDesktopPageToMobile(page, mobileCore.conversionUtils.getChildren(page), fullConversionSettings)

    const runMobileMergeOnPageById = (ps, heuristicStrategy, fullConversionSettings, pageId) => {
        const desktopPage = mergeAggregator.getPage(ps, pageId)
        const mobilePage = mobileCore.conversionUtils.extractMobilePage(desktopPage)

        if (!mobilePage) {
            return
        }
        /**
         * This setting use for commit merge result to dal
         */
        const commitConversionResults = true

        runMobileMergeOnPage(ps, heuristicStrategy, desktopPage, mobilePage, {...fullConversionSettings, commitConversionResults})
    }

    const runMobileMergeOnAllPagesWithoutLayoutOverwrite = (ps, heuristicStrategy, fullConversionSettings) => {
        fullConversionSettings.commitConversionResults = true
        fullConversionSettings.skipLayoutOverwrite = true
        fullConversionSettings.keepEmptyTextComponents = false
        fullConversionSettings.keepNotRecommendedMobileComponents = true
        runMobileMergeOnAllPages(ps, heuristicStrategy, fullConversionSettings, fullConversionSettings.commitConversionResults)
    }

    function mobileConversion(options) {
        const {ps} = options

        const heuristicStrategy = options.heuristicStrategy || ps.dal.get(ps.pointers.general.getMobileConversionHeuristicStrategy())
        const fullConversionSettings = conversionSettingsBuilder.getConversionSettings({
            conversionType: 'FULL',
            heuristicStrategy
        })

        return {
            getComponentsOrder: getComponentsOrder.bind(null, ps),
            inspect: settings => inspect(ps, heuristicStrategy, settings || fullConversionSettings),
            executeConversion: executeConversion.bind(null, fullConversionSettings),
            runMobileMergeOnAllPages: runMobileMergeOnAllPages.bind(null, ps, heuristicStrategy, fullConversionSettings),
            runMobileMergeOnAllPagesWithoutLayoutOverwrite: runMobileMergeOnAllPagesWithoutLayoutOverwrite.bind(
                null,
                ps,
                heuristicStrategy,
                fullConversionSettings
            ),
            runMobileMergeOnPage: runMobileMergeOnPage.bind(null, ps, heuristicStrategy),
            runMobileMergeOnPageById: runMobileMergeOnPageById.bind(null, ps, heuristicStrategy, fullConversionSettings),
            runMobileConversionOnPage: runMobileConversionOnPage.bind(null, ps, heuristicStrategy, fullConversionSettings),
            runMobileConversionOnAllPages: runMobileConversionOnAllPages.bind(null, ps, heuristicStrategy, fullConversionSettings),
            syncMobileSite: syncMobileSite.bind(null, ps, heuristicStrategy),
            applyConvertedComponentsMap: applyConvertedComponentsMap.bind(null, ps),
            applyConvertedComponentsToPage: applyConvertedComponentsToPage.bind(null, ps),
            prepareConversion: prepareConversion.bind(null, ps, heuristicStrategy)
        }
    }

    return {
        mobileConversion
    }
})
