define(['documentServices/utils/contextAdapter', 'documentServices/mobileConversion/modules/utils'], (contextAdapter, utils) => {
    const INTERACTIONS = {
        RUN_MERGE_ON_ALL_PAGES: 'mobile-algorithm-run-merge-on-all-pages',
        RUN_MERGE_ON_ONE_PAGE: 'mobile-algorithm-run-merge-on-one-page',
        RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES: 'mobile-algorithm-run-optimize-on-all-pages',
        RUN_OPTIMIZE_LAYOUT_ON_ONE_PAGE: 'mobile-algorithm-run-optimize-on-one-page'
    }

    const getInteractionName = (ps, defaultInteractionName) => {
        const enableNewMergeFlow = utils.shouldEnableNewMergeFlow(ps)
        const improvedNewMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)

        let interactionName = defaultInteractionName
        if (enableNewMergeFlow) {
            interactionName = `${defaultInteractionName}-new`
        }
        if (improvedNewMergeFlow) {
            interactionName = `${defaultInteractionName}-new-improved`
        }

        return interactionName
    }
    const logInteractionStart = (ps, interactionName) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(getInteractionName(ps, interactionName))
    }

    const logInteractionEnd = (ps, interactionName) => {
        contextAdapter.utils.fedopsLogger.interactionEnded(getInteractionName(ps, interactionName))
    }

    return {
        logInteractionStart,
        logInteractionEnd,
        INTERACTIONS
    }
})
