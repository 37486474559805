define(['lodash', 'documentServices/mobileConversion/mobileEditorSettings/quickActionBarManager', 'documentServices/dataModel/dataModel'], function (
    _,
    quickActionBarManager,
    dataModel
) {
    'use strict'

    function enforceIconLimitations(ps, compPointer, updatedDataItem) {
        const compData = _.defaults({}, updatedDataItem, dataModel.getDataItem(ps, compPointer))
        quickActionBarManager.validateActionItemData(compData)
        _.assign(updatedDataItem, _.pick(compData, ['itemType', 'icon', 'text']))
    }

    return {
        enforceIconLimitations
    }
})
