import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import numericStepperProxy from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/proxies/numericStepperProxy'
import optionsListInputProxy from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/proxies/optionsListInputProxy'
import selectOptionsListProxy from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/proxies/selectOptionsListProxy'
import table from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/proxies/tableProxy'
import ecomCheckoutAspect from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/aspects/ecomCheckoutAspect'
import ecomDialogAspect from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/aspects/ecomDialogAspect'
import ecomCheckoutMessageDialog from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/ecomCheckoutMessageDialog'
import ecommerceSantaTypes from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/ecommerceSantaTypes'
import colorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/colorOption'
import textOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/textOption'
import mobileColorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileColorOption'
import mobileTextOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileTextOption'
import numericStepper from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/numericStepper'
import optionsListInput from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/optionsListInput'
import selectOptionsList from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/selectOptionsList'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/addToCartButtonLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/CheckoutButtonLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/EcomJoinedCartLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/ShoppingCartLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/viewCartLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/productPageLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/feedbackMessageLogic'
import '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/productListLogic'

wixapps.proxyFactory.register('NumericStepper', numericStepperProxy)
wixapps.proxyFactory.register('OptionsList', optionsListInputProxy)
wixapps.proxyFactory.register('SelectOptionsList', selectOptionsListProxy)
wixapps.proxyFactory.register('Table', table)
componentsCore.siteAspectsRegistry.registerSiteAspect('ecomCheckout', ecomCheckoutAspect)
componentsCore.siteAspectsRegistry.registerSiteAspect('ecomDialog', ecomDialogAspect)

componentsCore.compRegistrar
    .register('ecommerce.viewer.dialogs.EcomCheckoutMessageDialog', ecomCheckoutMessageDialog)
    .register('wysiwyg.viewer.components.inputs.ColorOption', colorOption)
    .register('wysiwyg.viewer.components.inputs.TextOption', textOption)
    .register('ecommerce.integration.components.MobileColorOption', mobileColorOption)
    .register('ecommerce.integration.components.MobileTextOption', mobileTextOption)
    .register('wysiwyg.common.components.NumericStepper', numericStepper)
    .register('wysiwyg.common.components.inputs.OptionsListInput', optionsListInput)
    .register('wysiwyg.common.components.inputs.SelectOptionsList', selectOptionsList)

export default {
    ecommerceSantaTypes
}
