define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/constants/constants',
    'documentServices/namespaces/namespaces',
    'documentServices/component/component',
    'documentServices/structure/structure',
    'document-services-schemas',
    'documentServices/utils/utils',
    'documentServices/dataModel/dataModel',
    'documentServices/refComponent/refComponent'
], (_, santaCoreUtils, constants, namespaces, component, structure, documentServicesSchemas, dsUtils, dataModel, refComponent) => {
    const {schemasService} = documentServicesSchemas.services
    const {getRootRefHostCompPointer} = refComponent
    const SLOTS_NAMESPACE = constants.DATA_TYPES.slots
    const {stripHashIfExists} = dsUtils
    const {isRefPointer} = santaCoreUtils.displayedOnlyStructureUtil

    /**
     * Checks whether the given compPointer is a DynamicSlots or not
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {Boolean}
     */
    const isDynamic = (ps, compPointer) => getCompDefinition(ps, compPointer).slotsDataType === 'DynamicSlots'

    /**
     * Gets the component definition for the comp pointer
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {object} The component's definition
     */
    const getCompDefinition = (ps, compPointer) => {
        const {componentType} = ps.dal.get(compPointer)
        return schemasService.getDefinition(componentType)
    }

    /**
     * Gets the slots and their data for the given slotted component
     *
     * @param {ps} ps
     * @param {Pointer} slottedComponentPointer
     * @returns {object} An object where the keys are the slot names, and the values are the components in those slots
     */
    const getSlotProperties = (ps, slottedComponentPointer) => {
        const {slotsDataType} = getCompDefinition(ps, slottedComponentPointer)
        return _.get(schemasService.getSchema('slots', slotsDataType), ['properties', 'slots', 'properties'])
    }

    const removeFromQuery = (ps, slottedCompPointer, slotName) => {
        const slotsQueryPointer = dataModel.getSlotsItemPointer(ps, slottedCompPointer)
        const slotsData = ps.dal.get(slotsQueryPointer)
        delete slotsData.slots[slotName]
        ps.dal.set(slotsQueryPointer, slotsData)
    }

    const verifySlotName = (ps, slottedComponent, slotName) => {
        const slotProperties = getSlotProperties(ps, slottedComponent)
        const allowedSlotNames = _.keys(slotProperties)
        const isDynamicSlots = isDynamic(ps, slottedComponent)

        if (!allowedSlotNames.includes(slotName) && !isDynamicSlots) {
            throw new Error(`Slot "${slotName}" is not a valid slot name`)
        }
    }

    const getOwnerOfComponentInSlot = (ps, slottedComponent) =>
        isRefPointer(slottedComponent) ? getRootRefHostCompPointer(ps, slottedComponent) : slottedComponent

    /**
     * Verifies that we can populate a slot with a given comp definition
     *
     * @param {ps} ps
     * @param {String} slotName
     * @param {Pointer} slottedComponent
     */
    const verifyCanPopulate = (ps, slotName, slottedComponent) => {
        verifySlotName(ps, slottedComponent, slotName)

        const currentSlots = getSlotNames(ps, slottedComponent)
        if (currentSlots.includes(slotName)) {
            throw new Error(`Slot "${slotName}" already exists`)
        }
    }

    const findSlotByValue = (ps, parentPointer, compPointer) => {
        const slotsData = getSlotsData(ps, parentPointer)
        return _.keys(slotsData).find(key => slotsData[key].id === compPointer.id)
    }

    const removeFromSlot = (ps, compToAddPointer) => {
        const parentPointer = ps.pointers.components.getParent(compToAddPointer)
        const originalSlotName = findSlotByValue(ps, parentPointer, compToAddPointer)

        if (originalSlotName) {
            removeFromQuery(ps, parentPointer, originalSlotName)
        }
    }

    const updateSlot = (ps, slottedComponent, slotName, compToAddPointer) => {
        verifyCanPopulate(ps, slotName, slottedComponent)
        const {slotsDataType} = getCompDefinition(ps, slottedComponent)
        const updatedData = namespaces.getNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE)

        if (updatedData?.slots) {
            updatedData.slots[slotName] = compToAddPointer.id
            namespaces.updateNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE, updatedData)
        } else {
            namespaces.updateNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE, {
                type: slotsDataType,
                slots: {[slotName]: compToAddPointer.id}
            })
        }
    }

    /**
     * Populates a specified `slotName` with the slot definition
     *
     * @param {ps} ps
     * @param {Pointer} slottedComponent
     * @param {string} slotName The name of the slot to add to
     * @param {object} componentDefinition The component definition (similar as with ds.components.add())
     */
    const populate = (ps, slottedComponent, slotName, componentDefinition) => {
        const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, slottedComponent)
        const componentToAddPointer = component.getComponentToAddRef(ps, ownerOfComponentInSlot, componentDefinition)
        component.addComponentInternal(ps, componentToAddPointer, ownerOfComponentInSlot, componentDefinition)
        updateSlot(ps, slottedComponent, slotName, componentToAddPointer)

        return componentToAddPointer
    }

    /**
     * Moves a component to another component's slot
     *
     * @param {ps} ps
     * @param {Pointer} slottedComponent The component with slot
     * @param {string} slotName The slot name to move to.
     * @param {Pointer} compToMove The component to be placed in the slot
     */
    const moveToSlot = (ps, slottedComponent, slotName, compToMove) => {
        if (compToMove.type === constants.VIEW_MODES.MOBILE) {
            throw new Error('Only DESKTOP components can move into or between slots')
        }

        const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, slottedComponent)

        removeFromSlot(ps, compToMove)
        updateSlot(ps, slottedComponent, slotName, compToMove)
        structure.addCompToContainer(ps, compToMove, ownerOfComponentInSlot)
        return compToMove
    }

    /**
     * Returns all the components' slot names
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Array<string>} An array of slots names for the component. Empty if no slots exist
     */
    const getSlotNames = (ps, componentPointer) => _.keys(getSlotsData(ps, componentPointer))

    /**
     * Removes a slot from a component
     *
     * @param {ps} ps
     * @param {Pointer} slottedCompPointer The component that has the slot
     * @param {string} slotName The slot to remove
     */
    const remove = (ps, slottedCompPointer, slotName) => {
        verifySlotName(ps, slottedCompPointer, slotName)
        const compPointer = _.get(getSlotsData(ps, slottedCompPointer), slotName)
        if (!compPointer) {
            return
        }

        if (ps.dal.full.isExist(compPointer)) {
            component.remove(ps, compPointer)
        }

        removeFromQuery(ps, slottedCompPointer, slotName)
    }

    /**
     * Retrieves the slots data of a given slotted component
     *
     * @param {ps} ps
     * @param {Pointer} slottedComponentPointer
     * @returns {object} Slot names and their component pointers
     */
    const getSlotsData = (ps, slottedComponentPointer) => {
        const slotsData = namespaces.getNamespaceData(ps, slottedComponentPointer, SLOTS_NAMESPACE)
        return _.mapValues(slotsData?.slots, compId => ps.pointers.getPointer(stripHashIfExists(compId), slottedComponentPointer.type))
    }

    return {
        populate,
        moveToSlot,
        remove,
        getSlotNames,
        getSlotsData
    }
})
