import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const slots = viewerLibrary.get('documentServices/slots/slots')

    return {
        methods: {
            components: {
                slots: {
                    populate: publicMethodUtils.defineDataManipulationAction(slots.populate),
                    moveToSlot: publicMethodUtils.defineDataManipulationAction(slots.moveToSlot),
                    remove: publicMethodUtils.defineDataManipulationAction(slots.remove),
                    getSlotNames: publicMethodUtils.defineGetter(slots.getSlotNames),
                    getSlotsData: publicMethodUtils.defineGetter(slots.getSlotsData)
                }
            }
        }
    }
}
