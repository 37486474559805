define(['documentServices/constants/constants', 'documentServices/theme/common/constants'], function (constants, themeConstants) {
    'use strict'

    const {THEME_DATA_ID} = themeConstants

    /**
     * @param {Object} ps
     * @returns {Pointer}
     */
    function getThemePointer(ps) {
        return ps.pointers.data.getThemeItem(THEME_DATA_ID, constants.MASTER_PAGE_ID)
    }

    /**
     * @param {Object} ps
     * @param {string} collectionType
     * @returns {Pointer}
     */
    function getCollectionPointer(ps, collectionType) {
        const theme = getThemePointer(ps)
        return ps.pointers.getInnerPointer(theme, collectionType)
    }

    /**
     * @param {Object} ps
     * @param {string} collectionType
     * @param {string|number} index
     * @returns {Pointer}
     */
    function getCollectionItemPointer(ps, collectionType, index) {
        const theme = getThemePointer(ps)
        return ps.pointers.getInnerPointer(theme, [collectionType, index])
    }

    /**
     * @param {Object} ps
     * @param {string} collectionType
     * @param {string|number} index
     * @param {any} value
     * @return {void}
     */
    function setCollectionItem(ps, collectionType, index, value) {
        const itemPointer = getCollectionItemPointer(ps, collectionType, index)
        ps.dal.set(itemPointer, value)
    }

    /**
     * @param {Object} ps
     * @param {string} collectionType
     * @return {any[]}
     */
    function getCollection(ps, collectionType) {
        const pointer = getCollectionPointer(ps, collectionType)
        return ps.dal.get(pointer)
    }

    /**
     * @param {Object} ps
     * @param {string} collectionType
     * @param {string|number} index
     * @return {any}
     */
    function getCollectionItem(ps, collectionType, index) {
        const itemPointer = getCollectionItemPointer(ps, collectionType, index)
        return ps.dal.get(itemPointer)
    }

    return {
        getCollection,
        getCollectionItem,
        setCollectionItem
    }
})
