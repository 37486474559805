define(['lodash', 'documentServices/mobileConversion/mobileEditorSettings/mobileMetaDataUtils'], function (_, mobileMetaDataUtils) {
    'use strict'
    function validatePreloaderPropSupport(props) {
        const unsupportedProps = _.difference(_.keys(props), ['companyName', 'logoUrl'])
        if (_.some(unsupportedProps)) {
            throw new Error(`"[${unsupportedProps}]" are unsupported.`)
        }
    }

    function initialize(ps) {
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        const fixedSiteMetaData = mobileMetaDataUtils.fixSiteMetaData(ps, siteMetaData)
        if (!_.isEqual(fixedSiteMetaData, siteMetaData)) {
            mobileMetaDataUtils.setSiteMetaData(ps, fixedSiteMetaData)
        }
    }

    function enablePreloader(ps, enabled) {
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        siteMetaData.preloader.enabled = enabled
        mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
    }

    function isPreloaderEnabled(ps) {
        return mobileMetaDataUtils.getSiteMetaData(ps).preloader.enabled
    }

    function setPreloaderProperties(ps, props) {
        validatePreloaderPropSupport(props)
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        siteMetaData.contactInfo.companyName = _.get(props, 'companyName', siteMetaData.contactInfo.companyName)
        siteMetaData.preloader.uri = _.get(props, 'logoUrl', siteMetaData.preloader.uri)
        mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
    }

    function getPreloaderProperties(ps) {
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        return {
            companyName: siteMetaData.contactInfo.companyName,
            logoUrl: siteMetaData.preloader.uri
        }
    }

    function enableOptimizedView(ps, enabled) {
        const siteMetaData = mobileMetaDataUtils.getSiteMetaData(ps)
        siteMetaData.adaptiveMobileOn = enabled
        mobileMetaDataUtils.setSiteMetaData(ps, siteMetaData)
    }

    function isMobileOptimizedViewOn(ps) {
        //TODO - make sure server scheme passes this parameter (['editorModel', 'metaSiteData', 'adaptiveMobileOn'])
        return mobileMetaDataUtils.getSiteMetaData(ps).adaptiveMobileOn
    }

    const mobileSettings = {
        initialize,
        PRELOADER_PROPS: {
            COMPANEY_NAME: 'companyName',
            LOGO_URL: 'logoUrl'
        },
        /**
         * Enables or disables the mobile optimized view based on enabled param that is passed.
         * when the optimized view is on you let the user see the optimized version of the website suitable for the device.
         * @member documentServices.mobile
         * @param {boolean} enabled - true - activate the optimize view , false - disables the optimize view
         */
        enableOptimizedView,
        /**
         * Returns whether the mobile optimized view is enabled or not.
         * @member documentServices.mobile
         * @return {boolean} returns if the mobile optimized view is enabled or not.
         * @example
         * // returns true
         */
        isOptimized: isMobileOptimizedViewOn,
        /**
         * @class documentServices.mobile.preloader
         */
        preloader: {
            /**
             * Enables or disables mobile preloader based on the enabled param that is passed.
             * when the preloder is on when the site loads, users will see a simplified site version or your logo or company name (based on preloader properties)
             * @param {boolean} true - enables the preloads, false- disables the preloader.
             */
            enable: enablePreloader,
            /**
             * Returns whether the mobile preloader is enabled or not.
             * @return {boolean}
             * @example
             * //returns true
             */
            isEnabled: isPreloaderEnabled,
            /**
             * sets the mobile preloader properties. the functions receive and object with the properties we want to update.
             * Possible properties are: companyName, logoUrl
             * @example {companyName: 'wix', logoUrl: 'urlToLogo'}
             * @param {Object.<string, string>} propsToSet  object with the properties we want to update. object key is the property we want to update (companyName/logo) and the value.
             */
            update: setPreloaderProperties,
            /**
             * Gœets the mobile preloader properties object. The preloder property is and object that contains companyName and logoUtl
             * @returns {object} object containing preloader properties
             * //returns {companyName: 'wix', logoUrl: 'urlToLogo'}
             */
            get: getPreloaderProperties
        }
    }
    return mobileSettings
})
