define(['lodash', 'layout', '@wix/santa-core-utils'], function (_, /** layout.layout */ layout, santaCoreUtils) {
    'use strict'

    function measureGroup(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
        //TODO - alissa - structureInfo.structure
        const children = santaCoreUtils.dataUtils.getChildrenData(structureInfo.structure, isMobileView())

        const childrenBorder = calcChildrenBoundingBorder(children, measureMap)
        measureMap.left[id] = structureInfo.layout.x

        if (children.length > 0) {
            measureMap.width[id] = childrenBorder.right - childrenBorder.left
            measureMap.height[id] = childrenBorder.bottom - childrenBorder.top

            //this is useful only during editing
            measureMap.top[id] = structureInfo.layout.y + childrenBorder.top
            measureMap.left[id] = structureInfo.layout.x + childrenBorder.left

            //this is useful only during editing
            _.forEach(children, function (child) {
                measureMap.top[child.id] = child.layout.y - childrenBorder.top
                measureMap.left[child.id] = child.layout.x - childrenBorder.left
            })
        }
    }

    function calcChildrenBoundingBorder(children, measureMap) {
        let mostLeft, mostRight, mostTop, mostBottom
        mostLeft = mostTop = Number.MAX_VALUE
        mostRight = mostBottom = -Number.MAX_VALUE

        _.forEach(children, function (child) {
            const childLayout = child.layout
            const childLeft = measureMap.left && measureMap.left[child.id] ? measureMap.left[child.id] : childLayout.x
            const childWidth = measureMap.width && measureMap.width[child.id] ? measureMap.width[child.id] : childLayout.width
            const childTop = measureMap.top && measureMap.top[child.id] ? measureMap.top[child.id] : childLayout.y
            const childHeight = measureMap.height && measureMap.height[child.id] ? measureMap.height[child.id] : childLayout.height

            const childBoundingLayout = santaCoreUtils.boundingLayout.getBoundingLayout({
                x: childLeft,
                y: childTop,
                width: childWidth,
                height: childHeight,
                rotationInDegrees: childLayout.rotationInDegrees
            })

            mostLeft = Math.min(mostLeft, childBoundingLayout.x)
            mostRight = Math.max(mostRight, childBoundingLayout.x + childBoundingLayout.width)
            mostTop = Math.min(mostTop, childBoundingLayout.y)
            mostBottom = Math.max(mostBottom, childBoundingLayout.y + childBoundingLayout.height)
        })

        return {left: mostLeft, right: mostRight, top: mostTop, bottom: mostBottom}
    }

    function patchGroup(id, patchers, measureMap, structureInfo, siteData) {
        patchers.css(id, {
            width: measureMap.width[id],
            height: measureMap.height[id],
            top: measureMap.top[id],
            left: measureMap.left[id]
        })

        const children = santaCoreUtils.dataUtils.getChildrenData(structureInfo.structure, siteData.isMobileView())
        _.forEach(children, function (child) {
            patchers.css(child.id, {
                top: measureMap.top[child.id],
                left: measureMap.left[child.id]
            })
        })
    }

    layout.registerMeasureChildrenFirst('wysiwyg.viewer.components.Group', true)
    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.Group')
    layout.registerCustomMeasure('wysiwyg.viewer.components.Group', measureGroup)
    layout.registerPatcher('wysiwyg.viewer.components.Group', patchGroup)
})
