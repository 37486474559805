import type {ReportableError} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {CreateExtArgs, DalValue, DmApis, Extension, ExtensionAPI, Pointer} from '@wix/document-manager-core'
import {schemaConfigs, namespaceMapping} from '@wix/document-services-json-schemas'
import type {RefInfo, ResolvedReference} from '@wix/document-services-types'
import {namespacesWithoutTypeAndId} from './namespacesWithoutTypeAndId'

export interface SafeRemovalError {
    readonly namespace: string
    readonly dataType: string
    readonly invalidData: any
    readonly exception: Error
}

export interface SafeRemovalResult {
    readonly error: SafeRemovalError | null
    readonly result: Record<string, Record<string, any>> | null
}

export interface SchemaAPI {
    isSystemStyle(id: string): boolean
    getReferences(namespace: string, value: DalValue): ReadonlyArray<ResolvedReference>
    validate(dataTypeName: string, data: any, namespace: string): void
    hasNamespace(namespace: string): boolean
    removeAdditionalProperties(namespace: string, value: DalValue): void
    removeWhitelistedProperties(namespace: string, value: DalValue, conservativeRemoval: boolean): void
    removeAdditionalPropertiesSafely(data: Record<string, Record<string, DalValue>>): SafeRemovalResult
    removeWhitelistedPropertiesSafely(data: Record<string, Record<string, DalValue>>, conservativeRemoval: boolean): Record<string, Record<string, any>>
    extractReferenceFieldsInfoForSchema(namespace: string, dataTypeName: string): ReadonlyArray<RefInfo>
    convertNamespaceFromServerStyle(name: string): string
    convertNamespaceToServerStyle(name: string): string
}

export type SchemaExtensionAPI = ExtensionAPI & {
    schemaAPI: SchemaAPI
}

const REMOVAL_EXCLUSIONS = {
    additionalProperties: ['SingleLayoutData', 'DynamicSlots', 'FixerVersions'],
    whitelist: ['SingleLayoutData']
}

const createExtensionAPI = ({dal, coreConfig}: CreateExtArgs): SchemaExtensionAPI => {
    const {schemaService} = coreConfig
    const {schema} = dal

    const shouldExcludeFromRemoveAdditionalProperties = (namespace: string, value: DalValue): boolean => {
        const type = schema.getSchemaType(namespace, value)
        return REMOVAL_EXCLUSIONS.additionalProperties.includes(type)
    }

    const removeAdditionalProperties = (namespace: string, value: DalValue) => {
        if (value?.type && schemaService.hasNamespace(namespace)) {
            if (shouldExcludeFromRemoveAdditionalProperties(namespace, value)) {
                return value
            }
            schemaService.removeAdditionalProperties(namespace, value.type, value)
        }
    }

    const {validateStrict, hasNamespace} = schemaService

    const shouldExcludeFromRemoveWhitelistedProperties = (namespace: string, value: DalValue): boolean => {
        const type = schema.getSchemaType(namespace, value)
        return REMOVAL_EXCLUSIONS.whitelist.includes(type)
    }

    const removeWhitelistedProperties = (namespace: string, value: DalValue, conservativeRemoval: boolean) => {
        if (value?.type && schemaService.hasNamespace(namespace)) {
            if (shouldExcludeFromRemoveWhitelistedProperties(namespace, value)) {
                return value
            }
            schemaConfigs.whitelistCleanup.removeWhitelistedProperties(namespace, value.type, value, conservativeRemoval)
        }
    }

    const removeAdditionalPropertiesSafely = (data: Record<string, Record<string, DalValue>>): SafeRemovalResult => {
        const result = _.cloneDeep(data)
        for (const [namespace, v] of _.toPairs(result)) {
            for (const [id, value] of _.toPairs(v)) {
                try {
                    removeAdditionalProperties(namespace, value)
                } catch (e) {
                    return {
                        result: null,
                        error: {
                            namespace,
                            dataType: value.type,
                            exception: e as Error,
                            invalidData: data[namespace][id]
                        }
                    }
                }
            }
        }
        return {error: null, result}
    }
    const removeWhitelistedPropertiesSafely = (
        data: Record<string, Record<string, DalValue>>,
        conservativeRemoval: boolean
    ): Record<string, Record<string, any>> => {
        const result = _.cloneDeep(data)
        _.forEach(result, (namespaceData, namespace) => {
            _.forEach(namespaceData, value => {
                removeWhitelistedProperties(namespace, value, conservativeRemoval)
            })
        })
        return result
    }

    return {
        schemaAPI: {
            isSystemStyle: schema.isSystemStyle,
            getReferences: schema.getReferences,
            validate: validateStrict,
            hasNamespace,
            removeAdditionalProperties,
            removeWhitelistedProperties,
            removeAdditionalPropertiesSafely,
            removeWhitelistedPropertiesSafely,
            extractReferenceFieldsInfoForSchema: schema.extractReferenceFieldsInfoForSchema,
            convertNamespaceFromServerStyle: namespaceMapping.convertNamespaceFromServerStyle,
            convertNamespaceToServerStyle: namespaceMapping.convertNamespaceToServerStyle
        }
    }
}

const createValidator = ({dal}: DmApis) => {
    const {schema} = dal
    return {
        validateSchema: (pointer: Pointer, value: DalValue) => {
            if (_.isNil(value)) {
                return
            }
            const namespace = pointer.type
            const schemaType = schema.getSchemaType(namespace, value)
            if (schema.hasNamespace(namespace)) {
                try {
                    schema.validate(schemaType, value, namespace)
                } catch (schemaError) {
                    const err = schemaError as ReportableError
                    return [
                        {
                            shouldFail: true,
                            type: err.errorType,
                            message: err.message,
                            tags: err.tags,
                            extras: err.extras
                        }
                    ]
                }
            }
            return
        },
        validateDalSetValue: (pointer: Pointer, setValue: DalValue) => {
            const isValueInvalid = (value: DalValue) => !(value.type && value.id)
            const shouldCheck = !namespacesWithoutTypeAndId.has(pointer.type)
            const hasProblem = shouldCheck && setValue && isValueInvalid(setValue)
            if (hasProblem) {
                return [
                    {
                        shouldFail: true,
                        type: 'invalidDalSetValues',
                        message: `pointer of type ${pointer.type} is missing id or type`,
                        extras: {
                            id: pointer.id,
                            type: pointer.type,
                            setValue
                        }
                    }
                ]
            }
        }
    }
}

const createExtension = (): Extension => ({
    name: 'schema',
    dependencies: new Set([]),
    createExtensionAPI,
    createValidator
})

export {createExtension}
