define([
    'lodash',
    'documentServices/wixCode/saveTasks/saveCodeConsts',
    'documentServices/wixCode/saveTasks/saveWixCodeApps',
    'documentServices/wixCode/services/fileSystemService',
    'wixCode',
    'experiment',
    'documentServices/wixCode/utils/codeAppInfo',
    'documentServices/wixCode/utils/constants',
    'documentServices/wixCode/utils/schemaUtils',
    'documentServices/wixCode/utils/clientSpecMapUtils',
    'documentServices/wixCode/utils/errors',
    'documentServices/wixCode/services/filesDAL',
    'documentServices/wixCode/services/wixDataSchemas',
    'documentServices/wixCode/services/userCodeCacheKillerService',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/utils/fileDescriptorUtils',
    '@wix/wix-immutable-proxy'
], function (
    _,
    saveCodeConsts,
    saveWixCodeApps,
    fileSystemService,
    wixCode,
    experiment,
    codeAppInfoUtils,
    constants,
    schemaUtils,
    clientSpecMapUtils,
    errors,
    filesDAL,
    wixDataSchemas,
    userCodeCacheKillerService,
    kibana,
    fileDescriptorUtils,
    wixImmutableProxy
) {
    'use strict'

    const {deepClone} = wixImmutableProxy

    const dataProviderFromSnapshotDal = snapshotDal => {
        const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshotDal)
        const getValue = (type, id, innerPath = []) => {
            const pointer = {type, id, innerPath}
            return deepClone(snapshotDal.getValue(pointer))
        }
        return {
            rendererModel: (id, innerPath) => getValue('rendererModel', id, innerPath),
            serviceTopology: key => getValue('serviceTopology', 'serviceTopology', [key]),
            wixCodeNonUndoable: (wixCodeInnerPath = []) => getValue('wixCode', 'wixCode', ['nonUndoable', ...wixCodeInnerPath]),
            wixCodeUndoable: (wixCodeInnerPath = []) => getValue('wixCodeUndoable', 'wixCode', ['undoable', ...wixCodeInnerPath]),
            pagesPlatformApplications: appId => getValue('pagesPlatformApplications', 'pagesPlatformApplications', [appId]),
            wixCodeSpec,
            wixCodeInstanceId: wixCodeSpec ? wixCodeSpec.instanceId : '',
            isFirstSave: getValue(['documentServicesModel', 'neverSaved'])
        }
    }

    const {wixCodeUserScriptsService} = wixCode

    const provisionWixCodeApps = (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) => {
        saveWixCodeApps(lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
    }

    function getWixCloudBaseUrl(dataProvider) {
        return dataProvider.serviceTopology('wixCodeIdeServerUrl')
    }

    /**
     * @returns {(function(*, *, *, *, *, *, *, *, *): void)|*}
     */
    const createRunTask = () => (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI) => {
        const currentDataProvider = dataProviderFromSnapshotDal(currentSnapshotDal)
        const previousDataProvider = lastSnapshotDal && dataProviderFromSnapshotDal(lastSnapshotDal)

        if (!currentDataProvider.wixCodeSpec) {
            resolve()
            return
        }

        const traceEnd = kibana.traceWithSnapshot(currentSnapshot, {action: 'saveCode'}, currentSnapshotDal)

        // @ts-ignore
        Promise.resolve()
            // @ts-ignore
            .then(function runSave() {
                const changes = filesDAL.getChangesBetweenSnapshotDataProviders(previousDataProvider, currentDataProvider)
                return saveCode(extensionsAPI, changes, currentDataProvider)
            })
            .then(
                // @ts-ignore
                function onSuccess({message, params, savedPaths, modifiedSchemas, directoryFlagByDeletedPath, pagesWithoutCode}) {
                    traceEnd({message, params})
                    resolve(createSaveResult(currentDataProvider, savedPaths, modifiedSchemas, directoryFlagByDeletedPath, pagesWithoutCode))
                },
                function onError(error) {
                    if (error && error.error && error.params) {
                        traceEnd({level: kibana.levels.ERROR, message: error.error, params: error.params})
                        reject(error.error)
                    } else {
                        error = _.isError(error) ? error : new Error(error)
                        traceEnd({level: kibana.levels.ERROR, message: error})
                        reject(error)
                    }
                }
            )
    }

    const createRunTaskWithProvision =
        runTask => (lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI) => {
            if (experiment.isOpen('ds_provisionBeforeSaveCode')) {
                provisionWixCodeApps(
                    lastSnapshot,
                    currentSnapshot,
                    provisionResult => {
                        const resolveAllChanges = (result = {changes: []}) => {
                            const provisionChanges = _.get(provisionResult, 'changes', [])
                            result.changes = (result.changes || []).concat(provisionChanges)
                            resolve(result)
                        }
                        runTask(lastSnapshot, currentSnapshot, resolveAllChanges, reject, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
                    },
                    reject,
                    bi,
                    options,
                    lastSnapshotDal,
                    currentSnapshotDal
                )
            } else {
                runTask(lastSnapshot, currentSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
            }
        }

    function collectChanges(...changes) {
        return changes.reduce((result, change) => result.concat(change || []), [])
    }

    function ensureChangesMarkedForApproval(taskChanges = []) {
        return experiment.isOpen('dm_wixCodeWaitForApprovalBeforeSave') && taskChanges.length > 0
            ? taskChanges.concat({
                  path: constants.paths.WIX_CODE_WAIT_FOR_APPROVAL,
                  value: {timestamp: Date.now().toString()}
              })
            : taskChanges
    }

    function createSaveResult(dataProvider, savedPaths = [], modifiedSchemas, directoryFlagByDeletedPath, pagesWithoutCode) {
        const modifiedSchemasSaveChanges = createModifiedSchemasSaveChanges(modifiedSchemas, directoryFlagByDeletedPath)
        const bundlesSaveChanges = createBundlesSaveChanges(dataProvider, savedPaths)
        const changesForResettingDeletions = createChangesForResettingDeletions(directoryFlagByDeletedPath)
        const changesForPagesWithoutCode = createChangesForPagesWithoutCode(dataProvider, pagesWithoutCode)
        if (modifiedSchemasSaveChanges || bundlesSaveChanges || changesForResettingDeletions || changesForPagesWithoutCode) {
            return {
                changes: collectChanges(bundlesSaveChanges, changesForPagesWithoutCode),
                historyAlteringChanges: ensureChangesMarkedForApproval(collectChanges(modifiedSchemasSaveChanges, changesForResettingDeletions))
            }
        }
    }

    function createModifiedSchemasSaveChanges(modifiedSchemas, directoryFlagByDeletedPath) {
        if (modifiedSchemas) {
            const deletedSchemaIds = getDeletedSchemaIds(directoryFlagByDeletedPath)
            const remainingModifiedSchemas = _.filter(
                // When a schema is deleted, it is listed as modified in server the response.
                modifiedSchemas,
                modifiedSchema => !_.includes(deletedSchemaIds, modifiedSchema.id)
            )
            return _.reduce(
                remainingModifiedSchemas,
                (changes, remainingModifiedSchema) =>
                    changes.concat({
                        path: constants.paths.MODIFIED_FILE_CONTENTS.concat(`.schemas/${remainingModifiedSchema.id}.json`),
                        value: JSON.stringify(remainingModifiedSchema)
                    }),
                []
            )
        }
    }

    function createChangesForPagesWithoutCode(dataProvider, pagesWithoutCode) {
        const wixCodeAppPath = ['pagesPlatformApplications', constants.WIX_CODE_APP_ID]
        if (pagesWithoutCode) {
            const pageCodeMap = dataProvider.pagesPlatformApplications(constants.WIX_CODE_APP_ID)
            if (pageCodeMap) {
                pagesWithoutCode.forEach(pageId => _.set(pageCodeMap, pageId, false))
                return {
                    path: wixCodeAppPath,
                    value: pageCodeMap
                }
            }
        }
    }

    function getDeletedSchemaIds(directoryFlagByDeletedPath) {
        return _.reduce(
            directoryFlagByDeletedPath,
            (deletedSchemaIds, isDirectory, deletedPath) =>
                !isDirectory && schemaUtils.isSchemaFile(deletedPath)
                    ? deletedSchemaIds.concat(schemaUtils.getSchemaIdFromFilePath(deletedPath))
                    : deletedSchemaIds,
            []
        )
    }

    function createBundlesSaveChanges(dataProvider, savedPaths) {
        const relevantPaths = savedPaths.filter(path => wixCodeUserScriptsService.filePathAffectsBundles(path))
        if (_.isEmpty(relevantPaths)) {
            return
        }
        const allSingleBundles = _.every(relevantPaths, path => wixCodeUserScriptsService.filePathAffectsSingleBundle(path))
        if (allSingleBundles) {
            return createPerBundleSaveChanges(dataProvider, relevantPaths)
        }
        return createResetAllBundlesSaveChanges(dataProvider)
    }

    function createPerBundleSaveChanges(dataProvider, filePaths = []) {
        return filePaths.reduce((changes, path) => {
            const bundleId = wixCodeUserScriptsService.bundleIdFromFilePath(path)
            const bundleCacheKillerPath = wixCodeUserScriptsService.paths.CACHE_KILLERS_MAP.concat(bundleId)
            const existingCacheKillerValue = dataProvider.wixCodeNonUndoable(['bundleCacheKillers', bundleId])
            changes.push({
                path: bundleCacheKillerPath,
                value: userCodeCacheKillerService.getNewCacheKillerValue(existingCacheKillerValue)
            })
            return changes
        }, [])
    }

    function createResetAllBundlesSaveChanges(dataProvider) {
        const changes = []

        const existingCacheKillerValue = dataProvider.wixCodeNonUndoable(['defaultBundleCacheKiller'])
        changes.push({
            path: wixCodeUserScriptsService.paths.GLOBAL_CACHE_KILLER,
            value: userCodeCacheKillerService.getNewCacheKillerValue(existingCacheKillerValue)
        })

        changes.push({
            path: wixCodeUserScriptsService.paths.CACHE_KILLERS_MAP,
            value: {}
        })

        return changes
    }

    function createChangesForResettingDeletions(directoryFlagByDeletedPath) {
        if (!_.isEmpty(directoryFlagByDeletedPath)) {
            return [
                {
                    path: constants.paths.DIRECTORY_FLAG_BY_DELETED_PATH,
                    value: {}
                }
            ]
        }
    }

    function saveCode(extensionsAPI, changes, dataProvider) {
        if (filesDAL.isChangesEmpty(changes)) {
            return Promise.resolve({message: 'no changes'})
        }

        const codeAppInfo = createCodeAppInfo(extensionsAPI, dataProvider)
        const fileIdsToSave = changes.toSave.map(({fileId}) => fileId)
        const params = {
            filesToSave: JSON.stringify(fileIdsToSave),
            filesToCopy: JSON.stringify(changes.toCopy),
            filesToDelete: JSON.stringify(changes.toDelete)
        }

        function rethrowErrorWithParams(error) {
            throw {error, params} //eslint-disable-line no-throw-literal
        }

        try {
            if (dataProvider.isFirstSave || !experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
                const readOnly = dataProvider.wixCodeNonUndoable(['isAppReadOnly'])
                if (readOnly) {
                    throw new errors.WixCodeNotWriteableError()
                }
            }

            const savePromises = createSavePromises(extensionsAPI, codeAppInfo, changes.toSave)
            const copyPromises = createCopyPromises(extensionsAPI, codeAppInfo, changes.toCopy)
            const deletionPromises = createDeletionPromises(extensionsAPI, codeAppInfo, changes.toDelete)
            const allPromises = savePromises.concat(copyPromises, deletionPromises)

            return Promise.all(allPromises)
                .then(results => {
                    const modifiedSchemas = _(results).filter('modifiedSchemas').flatMap('modifiedSchemas').value()
                    const pagesWithoutCode = _(results).filter('pagesWithoutCode').flatMap('pagesWithoutCode').value()
                    return {
                        message: 'changes saved',
                        params,
                        savedPaths: fileIdsToSave,
                        modifiedSchemas,
                        directoryFlagByDeletedPath: changes.toDelete,
                        pagesWithoutCode
                    }
                })
                .catch(rethrowErrorWithParams)
        } catch (error) {
            return Promise.reject({error, params})
        }
    }

    function createCodeAppInfo(extensionsAPI, dataProvider) {
        const {wixCodeSpec} = dataProvider
        const baseUrl = getWixCloudBaseUrl(dataProvider)
        const gridAppId = extensionsAPI.wixCode.getEditedGridAppId()
        return codeAppInfoUtils.createCodeAppInfo({
            baseUrl,
            appId: gridAppId,
            signedInstance: wixCodeSpec.instance,
            instanceId: wixCodeSpec.instanceId
        })
    }

    function createSavePromises(extensionsAPI, codeAppInfo, filesToSave) {
        const [schemaFiles, nonSchemaFiles] = _.partition(filesToSave, file => schemaUtils.isSchemaFile(file.fileId))
        const saveSchemas = schemaFiles.map(schemaFile => saveFile(extensionsAPI, codeAppInfo, schemaFile.fileId, schemaFile.content))

        if (_.isEmpty(nonSchemaFiles)) {
            return saveSchemas
        }

        const saveNonSchemas = fileSystemService.bulkWrite(extensionsAPI, codeAppInfo, nonSchemaFiles)

        return [...saveSchemas, saveNonSchemas]
    }

    function createCopyPromises(extensionsAPI, codeAppInfo, toCopy) {
        return _.map(toCopy, function (entry) {
            return copyFile(extensionsAPI, codeAppInfo, entry.srcFileId, entry.destFileId)
        })
    }

    function createDeletionPromises(extensionsAPI, codeAppInfo, directoryFlagByDeletedPath) {
        return _.map(directoryFlagByDeletedPath, (isDirectory, deletedPath) => deleteItem(extensionsAPI, codeAppInfo, deletedPath, isDirectory))
    }

    async function saveFile(extensionsAPI, codeAppInfo, fileId, content) {
        if (schemaUtils.isSchemaFile(fileId)) {
            const result = await wixDataSchemas.save(extensionsAPI, codeAppInfo, schemaUtils.getSchemaIdFromFilePath(fileId), JSON.parse(content))
            return result ? {modifiedSchemas: result} : result
        }
        const descriptor = fileSystemService.getVirtualDescriptor(fileId, false)
        return fileSystemService.writeFile(extensionsAPI, codeAppInfo, descriptor, content)
    }

    function copyFile(extensionsAPI, codeAppInfo, srcFileId, destFileId) {
        const srcFileDescriptor = fileSystemService.getVirtualDescriptor(srcFileId, false)
        const destFileDescriptor = fileSystemService.getVirtualDescriptor(destFileId, false)
        const lastSep = destFileId.lastIndexOf('/')
        const targetFolderPath = destFileId.substr(0, lastSep)
        const targetFolder = fileSystemService.getVirtualDescriptor(targetFolderPath, true)
        const newName = destFileId.substr(lastSep + 1)
        return fileSystemService.copy(extensionsAPI, codeAppInfo, srcFileDescriptor, targetFolder, newName).catch(e => {
            if (e.xhr && e.xhr.status === 404 && fileDescriptorUtils.isPageFile(srcFileDescriptor)) {
                return Promise.resolve({pagesWithoutCode: [srcFileDescriptor, destFileDescriptor].map(fileDescriptorUtils.getPageId)})
            }
            return Promise.reject(e)
        })
    }

    async function deleteItem(extensionsAPI, codeAppInfo, path, isDirectory) {
        if (!isDirectory && schemaUtils.isSchemaFile(path)) {
            const result = await wixDataSchemas.remove(codeAppInfo, schemaUtils.getSchemaIdFromFilePath(path))
            return result ? {modifiedSchemas: result} : result
        }
        const itemDescriptor = fileSystemService.getVirtualDescriptor(path, isDirectory)
        return fileSystemService.deleteItem(extensionsAPI, codeAppInfo, itemDescriptor)
    }

    const createTask = () => {
        const runTask = createRunTask()
        const runTaskWithProvision = createRunTaskWithProvision(runTask)
        const task = {
            partialSave: runTaskWithProvision,
            fullSave: runTaskWithProvision,
            firstSave: runTaskWithProvision,
            saveAsTemplate: runTaskWithProvision,
            autosave: runTask,
            // @ts-ignore
            publish(currentData, resolve) {
                resolve()
            },
            getTaskName: saveCodeConsts.getTaskName,
            getSnapshotTags: saveCodeConsts.getSnapshotTags
        }
        return task
    }

    const taskWithSnapshotDal = createTask()
    return () => taskWithSnapshotDal
})
