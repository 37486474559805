import type {InitConfig} from './mainInitialization'
import type {DmStore} from '@wix/document-manager-core'

const initialize = async ({documentManager, rendererModel}: InitConfig, initialState: DmStore) => {
    const pagesPlatformApplicationsPointer = documentManager.pointers.platform.getPagesPlatformApplicationsPointer()
    initialState.set(pagesPlatformApplicationsPointer, rendererModel.pagesPlatformApplications)
}

export default {
    initialize
}
