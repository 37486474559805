define([
    'lodash',
    'documentServices/appStudio/appStudioPresets',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/componentDetectorAPI/componentDetectorAPI'
], function (_, appStudioPresets, appStudioDataModel, componentDetectorAPI) {
    'use strict'

    const getOriginalPageIdFromIdMap = (ps, newPageId, oldToNewIdMap) => {
        const isPage = compId => {
            const compRef = componentDetectorAPI.getComponentById(ps, compId)
            return compRef && ps.pointers.components.isPage(compRef)
        }
        return _.findKey(oldToNewIdMap, (newId, oldId) => newId === newPageId && isPage(oldId))
    }

    const createPresetsForDuplicatedWidget = (ps, pagePointer, pageDefinition, optionalCustomId, oldToNewIdMap) => {
        const originalPageId = getOriginalPageIdFromIdMap(ps, pagePointer.id, oldToNewIdMap)
        const originalWidget = originalPageId && appStudioDataModel.findWidgetByPageId(ps, originalPageId)

        if (originalWidget) {
            const originalPresets = appStudioPresets.getWidgetPresets(ps, originalWidget.pointer)
            const newPresets = _.map(originalPresets, originalPreset => {
                const originalVariantId = appStudioPresets.getPresetVariantId(ps, originalPreset.pointer)
                const newVariantId = oldToNewIdMap[originalVariantId]

                const newPresetPointer = appStudioDataModel.getNewDataItemPointer(ps)
                const newPresetData = appStudioPresets.createDuplicatedPresetData(
                    ps,
                    newPresetPointer,
                    originalPreset.pointer,
                    originalPreset.name,
                    newVariantId
                )
                appStudioDataModel.setData(ps, newPresetPointer, newPresetData)

                return newPresetPointer
            })
            if (!_.isEmpty(newPresets)) {
                const widgetPointer = _.get(appStudioDataModel.findWidgetByPageId(ps, pagePointer.id), 'pointer')
                appStudioPresets.addPresetsToWidgetDataItem(ps, newPresets, widgetPointer)
            }
        }
    }

    return {
        createPresetsForDuplicatedWidget
    }
})
