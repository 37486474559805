/** Create a schema service that, under the hood, runs two schema services side by side
 *
 * This is used for a migration phase in which we run the new schemas in addition to the old ones and report any difference in results.
 * We do this by creating two services, the default one and the new one.
 * We then return an object with the same members as the default,
 * except each method is overridden to run the corresponding method in the new service.
 *
 * @typedef {{result: *|null, hasError: boolean, error: Error|null}} SingleExecutionResult
 * @typedef {{
 *  extras: *,
 *  tags: {
 *      schemaMismatch: boolean,
 *      schemaMismatchMethod: string,
 *      schemaMismatchStyle: null|string,
 *      schemaMismatchDefinition: null|string,
 *      schemaMismatchValidateType: null|string,
 *      schemaMismatchValidateNamespace: null|string
 *     }
 * }} SentryPayload
 */
define(['experiment', 'lodash', './createSchemasService', 'documentServices/utils/contextAdapter', '@wix/document-manager-utils'], (
    experiment,
    _,
    createSchemaService,
    contextAdapter,
    {ReportableError}
) => {
    /** Methods and argument that should not be reported when they trigger diffs
     *
     * The key is the method. The values represent the first arguments to the method.
     * For example, a diff that is triggered by a call to validate('TextTheme', arg2, arg3) will not be reported
     */
    const IGNORE_DIFFS = {
        isSystemStyle: ['MusicPlayer_1'],
        validate: ['TextTheme', 'InternalRef']
    }

    const shouldIgnoreDiffs = (method, args) => _.some(_.get(IGNORE_DIFFS, method, []), possibleArg => _.isEqual(possibleArg, _.head(args)))

    const runServiceFunction = (f, args) => {
        let result = null
        let error = null
        try {
            result = f(...args)
        } catch (/** @type any */ e) {
            error = e
        }

        return {
            result,
            error,
            hasError: error !== null
        }
    }

    /** Run a method in both services, return the execution information
     *
     * @param {{defaultService: *, newService: *, method: string, args: *[]}} dualExecutionParams
     * @returns {{args: *[], method: string, newResult: SingleExecutionResult, defaultResult: SingleExecutionResult}}
     */
    function runDualServiceFunction({defaultService, newService, method, args}) {
        const defaultResult = runServiceFunction(defaultService[method], args)
        const newResult = runServiceFunction(newService[method], args)
        return {
            defaultResult,
            newResult,
            args,
            method
        }
    }

    /** Check whether the results from the underlying services are equivalent.
     *
     * Ignore the error fields, they won't necessarily produce the same stack and message.
     *
     * @param {SingleExecutionResult} defaultResult
     * @param {SingleExecutionResult} newResult
     * @returns {boolean}
     */
    function areResultsEquivalent(defaultResult, newResult) {
        const adjustedResults = [defaultResult, newResult].map(result => _.pick(result, ['result', 'hasError']))
        return _.isEqual(...adjustedResults)
    }

    /** Create custom sentry payload
     *
     * The default event/error grouping of the sentry system is not useful.
     * This method builds a sentry payload with `tags` to address that.
     * Each mismatch will have the method that triggered it as tag, in addition:
     * If the method is `validate`, it will also have the type and namespace of the validated data as tags.
     * If the method is `isSystemStyle` it will also have the specific style that cause the mismatch as a tag.
     *
     * @param {*} executionInfo
     * @returns {SentryPayload}
     */
    function sentryPayload(executionInfo) {
        const methodName = executionInfo.method
        const payload = {
            tags: {
                schemaMismatch: true,
                schemaMismatchMethod: methodName,
                schemaMismatchValidateNamespace: null,
                schemaMismatchValidateType: null,
                schemaMismatchStyle: null,
                schemaMismatchDefinition: null
            },
            extras: executionInfo
        }

        if (methodName === 'validate') {
            payload.tags.schemaMismatchValidateNamespace = _.last(executionInfo.args)
            payload.tags.schemaMismatchValidateType = _.first(executionInfo.args)
        }

        if (methodName === 'isSystemStyle') {
            payload.tags.schemaMismatchStyle = _.first(executionInfo.args)
        }

        if (methodName === 'getDefinition') {
            payload.tags.schemaMismatchDefinition = _.first(executionInfo.args)
        }

        return payload
    }

    return {
        /** Create a dual service
         *
         * @param {*} clientOnlySchemas Input schemas for the default service
         * @param {*} newService Input schemas service for the dual service
         * @param {boolean} returnNew Which result will ultimately be returned, the result from the newService or from the oldService
         * @returns {{}}
         */
        createDualService: (clientOnlySchemas, newService, returnNew) => {
            const defaultService = createSchemaService(clientOnlySchemas)
            const runDual = (method, ...args) => runDualServiceFunction({defaultService, newService, method, args})

            const service = _.mapValues(defaultService, (v, k) => {
                const noConversion = ['validators', 'getSchema']
                if (_.includes(noConversion, k)) {
                    return v
                }
                if (!_.isFunction(v)) {
                    throw new Error(`All schemaService members should be functions, even ${k}`)
                }
                return (...args) => {
                    const info = runDual(k, ...args)
                    const {defaultResult, newResult} = info

                    const equivalentResults = areResultsEquivalent(defaultResult, newResult)
                    const ignoreDiff = shouldIgnoreDiffs(k, args)
                    const shouldReportDiff = !equivalentResults && !ignoreDiff

                    if (shouldReportDiff) {
                        const err = new ReportableError({
                            errorType: 'unequalDualSchemaError',
                            message: `Unequal result from dual schema service method ${k}`
                        })
                        contextAdapter.utils.fedopsLogger.captureError(err, sentryPayload(info))
                    }

                    const {hasError, result, error} = returnNew ? newResult : defaultResult
                    if (hasError) {
                        throw error
                    } else {
                        return result
                    }
                }
            })

            return {
                ...service,
                runDual,
                shouldIgnoreDiffs,
                // For now, don't break user code, even if the user is using internals
                getSchema: defaultService.getSchema,
                // These functions do not exist in the old service:
                ..._.pick(newService, ['isDraftDataSchema', 'isPermanentDataType', 'extractReferenceFieldsInfo'])
            }
        }
    }
})
