/**
 * Created by talm on 18/08/15.
 */
define([
    'lodash',
    'documentServices/slideshow/slideShowCommon',
    'documentServices/component/component',
    'documentServices/layouters/layouters',
    'documentServices/component/componentStylesAndSkinsAPI'
], function (_, slideShowCommon, component, layouters, componentStylesAndSkinsAPI) {
    'use strict'

    const STYLE_TO_OVERRIDE_FROM_BOX = ['rd', 'brd', 'brw', 'alpha-brd']

    const LEGAL_SLIDE_PARENT = {
        'wysiwyg.viewer.components.BoxSlideShowSlide': 'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.StateBoxState': 'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.StateBoxFormState': 'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.Repeater': 'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.StateStripState': 'wysiwyg.viewer.components.StateStrip',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide': 'wysiwyg.viewer.components.StripContainerSlideShow'
    }

    function mergeStyleWithProps(ps, compRef, updatedProps) {
        const compCurrStyleObj = componentStylesAndSkinsAPI.style.get(ps, compRef)
        let clonedStyleProperties = _.cloneDeep(compCurrStyleObj)
        clonedStyleProperties = _.merge(clonedStyleProperties, updatedProps)
        componentStylesAndSkinsAPI.style.fork(ps, compRef, clonedStyleProperties)
    }

    function updateSlidesStyle(privateServices, compPointer, oldBoxStyle, newBoxStyle) {
        const propsChanged = _.omitBy(newBoxStyle.style.properties, function (v, k) {
            return oldBoxStyle.style.properties[k] === v
        })
        if (_(propsChanged).keys().intersection(STYLE_TO_OVERRIDE_FROM_BOX).isEmpty()) {
            return
        }
        const boxSlides = layouters.getNonMasterChildren(privateServices, compPointer)
        const overrideProps = {style: {properties: {}}}
        _.forEach(STYLE_TO_OVERRIDE_FROM_BOX, function (propName) {
            overrideProps.style.properties[propName] = propsChanged[propName]
        })
        _.forEach(boxSlides, function (slide) {
            mergeStyleWithProps(privateServices, slide, overrideProps)
        })
    }

    function verifySlideShowStructureOnAdd(ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
        const slides = slideShowCommon.getSlidesFromChildrenByStructure(compDefinitionPrototype.components)
        const isAtLeastOneSlide = slides.length > 0
        const isSlideWithNonMatchingType = _.find(slides, function (slide) {
            return !_.includes(slideShowCommon.getMatchingChildSlideTypes(compDefinitionPrototype.componentType), slide.componentType)
        })

        if (!isAtLeastOneSlide || isSlideWithNonMatchingType) {
            throw new Error('Invalid slideShow structure definition')
        }
    }

    function isParentIllegal(ps, deletedCompPointer) {
        const slideParent = ps.pointers.components.getParent(deletedCompPointer)
        const slideType = component.getType(ps, deletedCompPointer)
        const slideParentType = component.getType(ps, slideParent)
        return slideParentType !== LEGAL_SLIDE_PARENT[slideType]
    }

    function verifySlideShowStructureOnDelete(ps, deletedCompPointer, deletingParent) {
        if (deletingParent || isParentIllegal(ps, deletedCompPointer)) {
            return
        }
        const slideParent = ps.pointers.components.getParent(deletedCompPointer) || ps.pointers.full.components.getParent(deletedCompPointer)
        const childrenPointers = ps.pointers.components.getChildren(slideParent) || ps.pointers.full.components.getChildren(slideParent)
        const childrenTypes = _.map(childrenPointers, function (child) {
            return {componentType: component.getType(ps, child)}
        })
        const slides = slideShowCommon.getSlidesFromChildrenByStructure(childrenTypes)
        if (slides.length === 1) {
            throw new Error("can't delete the last slide")
        }
    }

    return {
        updateSlidesStyle,
        verifySlideShowStructureOnAdd,
        verifySlideShowStructureOnDelete
    }
})
