import index from './src/tpaComponents'
export const {
    tpaWidgetNative,
    tpaWidgetNativeDeadComp,
    nativeCompRequireService,
    widget,
    section,
    multiSection,
    worker,
    gluedWidget,
    tpaMixins,
    gluedWidgetMeasuringUtils,
    GluedWidgetPatcher,
    tpaSectionPatcher,
    common,
    services,
    sitePages,
    bi
} = index
