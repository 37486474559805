import _ from 'lodash'

function isFacebookApp() {
    const ua = _.get(navigator, ['userAgent'])
    return _.includes(ua, 'FBAN') || _.includes(ua, 'FBAV')
}

function isIOS() {
    const platform = _.get(navigator, ['platform'])
    return !!platform && /iPad|iPhone|iPod/.test(platform)
}

export default {
    isFacebookApp,
    isIOS
}
