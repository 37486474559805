define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/routers/routersGetters',
    'documentServices/siteMetadata/language',
    'documentServices/utils/utils'
], function (_, coreUtils, dataModel, routersGetters, language, dsUtils) {
    'use strict'

    function sanitizeHash(str) {
        return dsUtils.stripHashIfExists(str)
    }

    function getIndexOfItemInParent(ps, parentId, itemId) {
        const parentPointer = getMenuDataItemPointer(ps, parentId)
        const parentItems = ps.dal.get(ps.pointers.getInnerPointer(parentPointer, 'items'))
        const itemIndex = parentItems.indexOf(`#${itemId}`)

        if (itemIndex < 0) {
            throw new Error(`Cannot find item #${itemId} at parent #${parentId}`)
        }

        return itemIndex
    }

    /*
     This functions throws error if one of the following scenarios happen:
     1. parentId refers to a non existing data item
     2. parentId refers to a data item of type other than BasicMenuItem or CustomMenu
     3. parentId refers to a data item which is already a sub item of another item
     4. itemId refers to a data item with sub items, and user tries to move it to item other than top level (CUSTOM_MAIN_MENU)
     */
    function validateParent(ps, parentId, itemId, menuIdToPlaceItemIn) {
        parentId = parentId ? sanitizeHash(parentId) : 'CUSTOM_MAIN_MENU'
        itemId = itemId && sanitizeHash(itemId)

        if (itemId && itemId === parentId) {
            throw new Error(`menu item ${itemId} cannot be a parent for itself`)
        }

        const parentItemPointer = getMenuDataItemPointer(ps, parentId)

        if (!ps.dal.isExist(parentItemPointer)) {
            throw new Error(`Parent "${parentId}" does not exist`)
        }

        // console.log(dataModel.getDataItemById(menuIdToPlaceItemIn));
        // This means that the menuIdToPlaceItemIn must already be in the data? does it make sense?
        // if (dataModel.getDataItemById(menuIdToPlaceItemIn).type !== 'CustomMenu') {
        //     throw new Error('menuIdToPlaceItemIn type must be CustomMenu');
        // }
        const parentType = ps.dal.get(ps.pointers.getInnerPointer(parentItemPointer, 'type'))

        if (parentType !== 'BasicMenuItem' && parentType !== 'CustomMenu') {
            throw new Error(`Parent of type "${parentType}" is not a legal parent. Please provide parent of types "BasicMenuItem" or "CustomMenu" only`)
        }

        if (isReparentingIntoASubItem(ps, parentId, menuIdToPlaceItemIn)) {
            throw new Error('Cannot add/move items into a sub item')
        }

        if (itemId) {
            const itemPointer = getMenuDataItemPointer(ps, itemId)
            const subItemsPointer = ps.pointers.getInnerPointer(itemPointer, 'items')
            const subItems = ps.dal.get(subItemsPointer)

            if (subItems && subItems.length > 0 && !_.includes(['CUSTOM_MAIN_MENU', menuIdToPlaceItemIn], parentId)) {
                throw new Error(`Cannot move item with sub items (#${itemId}) to anything other than  #CUSTOM_MAIN_MENU`)
            }
        }
    }

    function isCustomMenu(ps, menuId) {
        const typePointer = ps.pointers.getInnerPointer(ps.pointers.data.getDataItem(menuId, 'masterPage'), 'type')
        return ps.dal.get(typePointer) === 'CustomMenu'
    }

    function isReparentingIntoASubItem(ps, parentId, menuIdToPlaceItemIn) {
        if (isCustomMenu(ps, parentId)) {
            return false
        }

        menuIdToPlaceItemIn = menuIdToPlaceItemIn || 'CUSTOM_MAIN_MENU'
        const menuData = ps.dal.get(getMenuDataItemPointer(ps, menuIdToPlaceItemIn))
        return !_.includes(menuData.items, `#${parentId}`)
    }

    function getParentItemId(parent, itemId) {
        const {items} = parent
        let i, subItem
        for (i = 0; i < items.length; i++) {
            subItem = items[i]
            if (subItem.id === itemId) {
                return parent.id
            }

            const parentFromSubItem = getParentItemId(subItem, itemId)
            if (parentFromSubItem) {
                return parentFromSubItem
            }
        }

        return null
    }

    function getItemIdAndParent(parent, data) {
        let item, itemIdAndParent
        const {items} = parent
        for (let i = 0; i < items.length; i++) {
            item = items[i]
            if (item.link && isContained(item.link, data)) {
                return {
                    parentId: parent.id ? sanitizeHash(parent.id) : null,
                    itemId: item.id ? sanitizeHash(item.id) : null
                }
            }

            itemIdAndParent = getItemIdAndParent(item, data)
            if (itemIdAndParent) {
                return itemIdAndParent
            }
        }

        return null
    }

    function isContained(container, contained) {
        return _.every(contained, function (value, key) {
            const containerValue = container[key]
            if (_.isObject(containerValue)) {
                return _.isEqual(value, `#${containerValue.id}`)
            }
            return _.isEqual(value, containerValue)
        })
    }

    /**
     * Return the site menu items
     *
     * @param {ps} ps
     * @returns {Array.<object>}
     */
    function getSiteMenu(ps) {
        // TODO: refactor menu utils to stop using siteData and get the relevant data items as parameters.
        const siteData = {
            getDataByQuery(query, pageId) {
                return dataModel.getDataItemById(ps, query, pageId)
            },
            getRouters() {
                return routersGetters.get.all(ps)
            }
        }

        const siteMenu = coreUtils.menuUtils.getSiteMenuWithoutRenderedLinks(siteData, true)
        return siteMenu
    }

    function getLinkIdByMenuItemId(ps, menuItemId) {
        const menuItemPointer = getMenuDataItemPointer(ps, menuItemId)
        const linkPointer = ps.pointers.getInnerPointer(menuItemPointer, 'link')
        return ps.dal.get(linkPointer)
    }
    function getLinkItemByMenuItemId(ps, menuItemId) {
        const linkId = getLinkIdByMenuItemId(ps, menuItemId)
        if (!linkId) {
            return null
        }
        const linkItemPointer = getMenuDataItemPointer(ps, linkId)

        return ps.dal.get(linkItemPointer)
    }

    function getMenuDataItemPointer(ps, dataItemId) {
        dataItemId = sanitizeHash(dataItemId)
        return ps.pointers.data.getDataItem(dataItemId, 'masterPage')
    }

    function getPageMenuItemPointer(ps, pageId) {
        let menuItemPointer
        const pageLinkPointers = ps.pointers.data.getDataItemsWithPredicate({
            type: 'PageLink',
            pageId: `#${pageId}`
        })
        for (let i = 0; pageLinkPointers.length; i++) {
            menuItemPointer = ps.pointers.data.getDataItemWithPredicate({
                type: 'BasicMenuItem',
                link: `#${pageLinkPointers[i].id}`
            })
            if (menuItemPointer) {
                return menuItemPointer
            }
        }
    }

    function updateTranslatedMenuItems(ps, menuData) {
        if (language.multilingual.isEnabled(ps)) {
            const currentLanguageCode = language.current.get(ps)
            language.getTranslationLanguageCodes(ps).forEach(code => {
                const shouldSetToDisplayedDAL = code === currentLanguageCode
                updateTranslatedMenuItemsForLanguage(ps, code, shouldSetToDisplayedDAL, menuData)
            })
        }
    }

    function updateTranslatedMenuItemsForLanguage(ps, languageCode, shouldSetToDisplayedDAL, menuData) {
        const menuItemPointer = ps.pointers.data.getDataItem(menuData.id, 'masterPage')
        const menuItem = ps.dal.get(menuItemPointer)
        const itemsTranslationsPointers = menuData.items.map((item, itemIndex) => {
            const itemWithId = item.id ? item : {id: sanitizeHash(menuItem.items[itemIndex]), ...item}
            return updateTranslatedMenuItemsForLanguage(ps, languageCode, shouldSetToDisplayedDAL, itemWithId)
        })

        const pointer = ps.pointers.multilingualTranslations.translationDataItem('masterPage', languageCode, menuData.id)
        const translationDataItem = ps.dal.full.get(pointer)

        if (translationDataItem) {
            translationDataItem.items = itemsTranslationsPointers

            if (shouldSetToDisplayedDAL) {
                ps.dal.set(pointer, translationDataItem)
            } else {
                ps.dal.fullJsonDal.set(pointer, translationDataItem)
            }
        }

        return `#${_.get(translationDataItem, 'id') || menuData.id}`
    }

    const getAllMenuPointers = ps => {
        const customMenusDataItemPointer = ps.pointers.data.getDataItemFromMaster('CUSTOM_MENUS')
        const customMenusDataQueries = ps.dal.full.get(customMenusDataItemPointer).menus
        return _.map(customMenusDataQueries, dq => ps.pointers.data.getDataItemFromMaster(sanitizeHash(dq)))
    }

    const getMenusByFilter = (ps, filterObj) => {
        const allMenuPointers = getAllMenuPointers(ps)
        return _(allMenuPointers)
            .map(ptr => ps.dal.full.get(ptr))
            .filter(filterObj)
            .map(menu => ps.pointers.data.getDataItemFromMaster(menu.id))
            .value()
    }

    const getItemWithMetaData = (ps, lang, id, parent) => {
        let pointer = ps.pointers.multilingualTranslations.translationDataItem('masterPage', lang, id)
        const isTranslatedItem = ps.dal.isExist(pointer)
        pointer = isTranslatedItem ? pointer : ps.pointers.data.getDataItemFromMaster(id)
        const value = ps.dal.get(pointer)
        return {
            id,
            parent,
            pointer,
            isTranslatedItem,
            value
        }
    }

    const getFlatMenuWithMetaData = (ps, menuId, lang) => {
        const menuDataMap = {}
        const collectMenuInfo = parent => itemQuery => {
            const itemId = sanitizeHash(itemQuery)
            menuDataMap[itemId] = getItemWithMetaData(ps, lang, itemId, parent)
            const {link, items} = menuDataMap[itemId].value
            if (link) {
                const linkId = sanitizeHash(link)
                menuDataMap[itemId].link = getItemWithMetaData(ps, lang, linkId, itemId)
            }
            items.forEach(collectMenuInfo(itemId))
        }

        collectMenuInfo(null)(menuId)
        return menuDataMap
    }

    const buildSerializedMenuFromFlatMenuDataMap = (menuDataMap, itemId) => {
        const item = menuDataMap[itemId].value
        item.link = _.get(menuDataMap[itemId].link, 'value')
        item.items = _.map(item.items, itemQuery => buildSerializedMenuFromFlatMenuDataMap(menuDataMap, sanitizeHash(itemQuery)))
        return item
    }

    const isOnlyPartiallyTranslated = menuData => _.some(menuData, {isTranslatedItem: true}) && _.some(menuData, {isTranslatedItem: false})
    const splitMenusInSecondaryLanguagesIfAltered = (ps, menuId) => {
        language
            .getTranslationLanguageCodes(ps)
            .map(lang => ({lang, flatMenu: getFlatMenuWithMetaData(ps, menuId, lang)}))
            .filter(({flatMenu}) => isOnlyPartiallyTranslated(flatMenu))
            .forEach(({lang, flatMenu}) => {
                const serializedMenuData = buildSerializedMenuFromFlatMenuDataMap(flatMenu, menuId)
                //addSerializedDataItemToPage is just the easiest way to 'split' the whole menu
                dataModel.addSerializedDataItemToPage(ps, 'masterPage', serializedMenuData, menuId, lang)
            })
    }

    /**
     * @exports documentServices/menu/menuUtils
     */
    return {
        getIndexOfItemInParent,
        getItemIdAndParent,
        getLinkItemByMenuItemId,
        getLinkIdByMenuItemId,
        getParentItemId,
        getSiteMenu,
        sanitizeHash,
        validateParent,
        getMenuDataItemPointer,
        getPageMenuItemPointer,
        getAllMenuPointers,
        updateTranslatedMenuItems,
        getFlatMenuWithMetaData,
        splitMenusInSecondaryLanguagesIfAltered,
        getMenusByFilter
    }
})
