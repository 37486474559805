import santaComponents from '@wix/santa-components'

export default {
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
    },
    getBaseUrl() {
        const appData = this.getAppData()
        if (appData.widgets) {
            const {widgetId} = this.props.compData
            const widgetData = appData.widgets[widgetId]

            if (widgetData) {
                const isInDevMode = this.isInMobileDevMode && this.isInMobileDevMode()
                const widgetUrl = this.getTPAOverrideUrl('tpaWidgetUrlOverride', widgetId) || widgetData.widgetUrl

                if (this.isUnderMobileView()) {
                    const mobileReady = widgetData.mobileUrl && (isInDevMode || widgetData.mobilePublished)

                    if (mobileReady) {
                        return this.getTPAOverrideUrl('tpaMobileUrlOverride', widgetId) || widgetData.mobileUrl
                    }
                    return widgetUrl
                }

                return widgetUrl
            }
        }
        //TODO - report data error bi
        return ''
    }
}
