import optionInput from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/optionInput'

/**
 * @class components.MobileColorOption
 * @extends {optionInput}
 * @property {comp.properties} props
 */
export default {
    displayName: 'MobileColorOption',
    mixins: [optionInput],

    getSkinProperties() {
        const refData = {
            '': {
                style: {
                    backgroundColor: this.props.compData.text
                }
            }
        }

        // set a click callback only when the component is enabled.
        if (!this.props.compData.disabled) {
            // @ts-ignore
            refData[''].onClick = this.props.onClick
            // @ts-ignore
            refData[''].style.cursor = 'pointer'
        }
        return refData
    }
}
