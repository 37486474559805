define([
    'lodash',
    'documentServices/page/page',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/platform/common/constants',
    'documentServices/utils/utils',
    'platformUtils',
    '@wix/santa-ds-libs/src/coreUtils'
], function (_, pageDsAPI, extensionsAPI, constants, dsUtils, platformUtils, coreUtils) {
    'use strict'

    const {linkUtils} = platformUtils

    function getPagesStructureInfo(ps) {
        const pagesDataItems = pageDsAPI.getPagesDataItems(ps)
        const routers = ps.dal.get(ps.pointers.routers.getRoutersPointer())
        const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
        const isPopupPage = pageDsAPI.popupPages.isPopup.bind(pageDsAPI.popupPages, ps)
        const mainPageId = extensionsAPI.pages.getMainPageId(ps) || 'mainPage'
        return coreUtils.siteStructureUtils.getPages(pagesDataItems, isPopupPage, mainPageId, routers, clientSpecMap, {includeBlankUrl: true})
    }

    function navigateTo(ps, url, callback) {
        const routersMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
        const prefixes = coreUtils.siteStructureUtils.getRouterPrefixes({configMap: routersMap})
        const pages = getPagesStructureInfo(ps)
        const linkObject = linkUtils.convertUrlToLinkObject(prefixes, url, null, pages)
        if (linkObject.hasOwnProperty('pageId')) {
            linkObject.pageId = linkObject.pageId === '#' ? linkObject.pageId : dsUtils.stripHashIfExists(linkObject.pageId)
        }
        pageDsAPI.navigateTo(ps, linkObject, callback)
    }

    function setState(ps, pageStateMap) {
        const appStatePointer = ps.pointers.platform.getAppStatePointer()
        _.forEach(pageStateMap, function (pageRefs, state) {
            _.forEach(pageRefs, function (pageRef) {
                ps.dal.full.set(ps.pointers.getInnerPointer(appStatePointer, pageRef.id), state)
            })
        })
    }

    function getState(ps, pageRef) {
        const appStatePointer = ps.pointers.platform.getAppStatePointer()
        return ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, pageRef.id)) || constants.Page.DEFAULT_STATE
    }

    return {
        navigateTo,
        setState,
        getState
    }
})
