import $ from 'zepto'
import _ from 'lodash'
import layout from '../util/layout'
import imageLayout from './imageLayout'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import warmupUtilsLib from '@wix/santa-core-utils'

const convertedDisplayModes = {
    fitWidthStrict: imageClientLib.fittingTypes.LEGACY_FIT_WIDTH,
    fitHeightStrict: imageClientLib.fittingTypes.LEGACY_FIT_HEIGHT
}

function getConvertedDisplayMode(originalDisplayMode) {
    if (originalDisplayMode === imageClientLib.fittingTypes.LEGACY_FIT_WIDTH) {
        return imageClientLib.fittingTypes.LEGACY_FIT_HEIGHT
    }
    return convertedDisplayModes[originalDisplayMode] || originalDisplayMode
}

function getContainerOriginalSize(id, measureMap, displayMode) {
    const height = measureMap.height[id] - (measureMap.custom[id].marginHeight || 0)
    const width = measureMap.width[id] - (measureMap.custom[id].marginWidth || 0)
    const containerMeasuredSize = {
        width: width > 0 ? width : measureMap.width[id],
        height: height > 0 ? height : measureMap.height[id]
    }
    const exactHeight = measureMap.custom[id].exactHeight - (measureMap.custom[id].marginHeight || 0)

    if (displayMode === imageClientLib.fittingTypes.LEGACY_FIT_HEIGHT && Math.ceil(exactHeight) === containerMeasuredSize.height) {
        containerMeasuredSize.height = exactHeight
    }

    return containerMeasuredSize
}

//we have to calculate the margins in this stage, because during the patch the anchors might have change the height of the comp.
function measurePhoto(id, measureMap, nodesMap) {
    const $node = $(nodesMap[id])
    const contentPaddingHorizontal = parseInt($node.data('content-padding-horizontal'), 10)
    const contentPaddingVertical = parseInt($node.data('content-padding-vertical'), 10)
    const exactHeight = parseFloat($node.data('exact-height'))
    //the very ugly thisIsMyHeight field is here so that we won't override the wixapps comp height :`(
    //this is here because the image has to be proportional even if the server (structure data) thought otherwise
    measureMap.height[id] = (measureMap.custom[id] && measureMap.custom[id].thisIsMyHeight) || nodesMap[id].offsetHeight

    measureMap.custom[id] = {
        marginWidth: contentPaddingHorizontal,
        marginHeight: contentPaddingVertical,
        displayMode: $node.data('displayMode') || 'fill',
        overrideCrop: $node.data('overrideCrop'),
        exactHeight,
        disableImageAutoLayout: !!$node.data('disable-image-auto-layout')
    }
}

/**
 *
 * @param id
 * @param patchers
 * @param measureMap
 * @param {core.SiteData} siteData
 * @param {layout.structureInfo} structureInfo
 */
function patchNodePhoto(id, patchers, measureMap, structureInfo, siteData) {
    const linkId = `${id}link`
    const compData = structureInfo.dataItem
    const {overrideCrop, displayMode, disableImageAutoLayout} = measureMap.custom[id]
    const convertedDisplayMode = getConvertedDisplayMode(displayMode)

    const containerSize = warmupUtilsLib.imageUtils.getContainerSize(
        imageClientLib,
        getContainerOriginalSize(id, measureMap, convertedDisplayMode),
        {width: compData.width, height: compData.height},
        convertedDisplayMode
    )

    const isVerticallyStretched = warmupUtilsLib.layoutUtils.isVerticallyStretched(structureInfo.layout)
    const isHorizontallyStretched = warmupUtilsLib.layoutUtils.isHorizontallyStretched(structureInfo.layout)

    const compSize = {
        width: !isHorizontallyStretched ? containerSize.width + measureMap.custom[id].marginWidth : '',
        height: !isVerticallyStretched ? containerSize.height + measureMap.custom[id].marginHeight : ''
    }

    const imageData = {
        displayMode: convertedDisplayMode, // This doesn't really matter in case of fit, but I think this should change with the container converted display mode
        crop: overrideCrop || compData.crop
    }
    _.defaults(imageData, compData)

    if (disableImageAutoLayout) {
        const imgId = `${id}img`
        imageLayout.patchNodeImage(imgId, patchers, measureMap, siteData, imageData, containerSize)
    }

    patchers.css(id, compSize)
    patchers.css(linkId, containerSize)
}

const childImageComponent = {pathArray: ['img'], type: 'core.components.Image'}

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.WPhoto')
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.WPhoto', [childImageComponent, ['link']])
layout.registerPatcher('wysiwyg.viewer.components.WPhoto', patchNodePhoto)
layout.registerCustomMeasure('wysiwyg.viewer.components.WPhoto', measurePhoto)

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.ClipArt')
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.ClipArt', [childImageComponent, ['link']])
layout.registerPatcher('wysiwyg.viewer.components.ClipArt', patchNodePhoto)
layout.registerCustomMeasure('wysiwyg.viewer.components.ClipArt', measurePhoto)

export default {childImageComponent, patchNodePhoto, measurePhoto}
