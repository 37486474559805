define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/platform/services/notificationService',
    'platformEvents'
], function (
    _,
    santaCoreUtils,
    dataModel,
    component,
    installedTpaAppsOnSiteService,
    clientSpecMapService,
    tpaEventHandlersService,
    tpaUtils,
    notificationService,
    platformEvents
) {
    'use strict'

    const SCOPE = {
        APP: 'APP',
        COMPONENT: 'COMPONENT'
    }

    const MAX_SIZE_FOR_APP = 1000
    const MAX_SIZE_FOR_COMP = 400
    const MAX_SIZE_FOR_SUPER_APP_COMP = 4000
    const ONLY_TPA_COMPS_SUPPORTED = 'tpa.data APIs can only be used with TPA components (TPAWidget, TPASection, TPAMultiSection, TPAGluedWidget)'
    const PREFIX_TPA_DATA_ID = 'tpaData-'

    const setValue = function (ps, compPointer, key, value, scope, callback) {
        if (!isValidValue(value)) {
            handleFailure(callback, 'Invalid value: value should be of type: string, boolean, number or Json')
            return
        }

        const compData = component.data.get(ps, compPointer, null, true)
        if (isAppScope(scope)) {
            const appTpaData = getAppTpaData(ps, compData.applicationId)
            const oldAppTpaData = _.clone(appTpaData)
            setAppValue(ps, appTpaData, callback, compData.applicationId, compData.appDefinitionId, {[key]: value})
            notifyComponentDataChanged(ps, compData.applicationId, compPointer, oldAppTpaData)
        } else {
            const componentTpaData = getCompTpaData(compData)
            const oldComponentTpaData = _.clone(componentTpaData)
            setComponentValue(ps, componentTpaData, compPointer, callback, {[key]: value})
            notifyComponentDataChanged(ps, compData.applicationId, compPointer, oldComponentTpaData)
        }
    }

    const setMultipleValues = function (ps, compPointer, config, scope, callback) {
        const invalids = Object.values(config).filter(value => !isValidValue(value))
        if (invalids.length) {
            handleFailure(callback, `Invalid value/s: value should be of type: string, boolean, number or Json - values:${invalids.join(',')}`)
            return
        }
        const compData = component.data.get(ps, compPointer, null, true)
        if (isAppScope(scope)) {
            const appTpaData = getAppTpaData(ps, compData.applicationId)
            const oldAppTpaData = _.clone(appTpaData)
            setAppValue(ps, appTpaData, callback, compData.applicationId, compData.appDefinitionId, config)
            notifyComponentDataChanged(ps, compData.applicationId, compPointer, oldAppTpaData)
        } else {
            const componentTpaData = getCompTpaData(compData)
            const oldComponentTpaData = _.clone(componentTpaData)
            setComponentValue(ps, componentTpaData, compPointer, callback, config)
            notifyComponentDataChanged(ps, compData.applicationId, compPointer, oldComponentTpaData)
        }
    }

    const notifyComponentDataChanged = function (ps, applicationId, compRef, previousData) {
        notificationService.notifyApplication(
            ps,
            applicationId,
            platformEvents.factory.componentDataChanged({
                compRef,
                previousData
            })
        )
    }

    // TODO : remove once santa-editor is deployed with new data api
    const getValueOldAPI = function (ps, compPointer, key, scope, callback) {
        const compData = component.data.get(ps, compPointer, null, true)
        let returnObj

        if (isAppScope(scope)) {
            const appTpaData = getAppTpaData(ps, compData.applicationId)
            returnObj = appTpaData ? _.pick(appTpaData.content, key) : null
        } else {
            if (!tpaUtils.isTpaComp(ps, compPointer)) {
                //technically appController works for appScope, so this check is only for component scope
                handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
                return
            }
            const compTpaData = getCompTpaData(compData)
            returnObj = compTpaData ? _.pick(compTpaData.content, key) : null
        }

        if (!_.isEmpty(returnObj)) {
            callback(returnObj)
        } else {
            handleFailure(callback, `key ${key} not found in ${scope} scope`)
        }
    }

    const getMulti = function (ps, compPointer, keys, scope, callback) {
        const compData = component.data.get(ps, compPointer, null, true)
        let result

        keys = _.uniq(keys)

        if (isAppScope(scope)) {
            const appTpaData = getAppTpaData(ps, compData.applicationId)
            result = appTpaData ? _.pick(appTpaData.content, keys) : null
        } else {
            if (!tpaUtils.isTpaComp(ps, compPointer)) {
                //technically appController works for appScope, so this check is only for component scope
                handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
                return
            }
            const compTpaData = getCompTpaData(compData)
            result = compTpaData ? _.pick(compTpaData.content, keys) : null
        }

        const keysString = _.map(keys, key => `${key}`)
        if (!_.isEmpty(result) && _(result).keys().isEqual(keysString)) {
            callback(result)
        } else {
            const resultKeys = _.keys(result)
            const keysNotFound = _(resultKeys).xor(keys).intersection(keys).value()
            handleFailure(callback, `keys ${keysNotFound} not found in ${scope} scope`)
        }
    }

    const getCompTpaData = function (compData) {
        const componentTpaData = compData.tpaData
        if (componentTpaData) {
            componentTpaData.content = componentTpaData.content ? JSON.parse(componentTpaData.content) : {}
        }

        return componentTpaData
    }
    const getDefaultTpaAppData = tpaDataId => ({
        type: 'TPAGlobalData',
        id: tpaDataId,
        content: {}
    })

    const setAppValue = function (ps, appTpaData, callback, applicationId, appDefinitionId, config) {
        const pageId = 'masterPage'

        const tpaDataId = appTpaData ? appTpaData.id : PREFIX_TPA_DATA_ID + applicationId

        if (!appTpaData) {
            appTpaData = getDefaultTpaAppData(tpaDataId)
        }

        appTpaData.appDefinitionId = appDefinitionId
        const keyValue = setAndReturnConfig(ps, tpaDataId, appTpaData, pageId, config, MAX_SIZE_FOR_APP, appDefinitionId)
        if (!keyValue) {
            handleFailure(callback, `Your app has exceeded the provided ${MAX_SIZE_FOR_APP} bytes storage space`)
            return
        }

        if (callback) {
            callback(keyValue)
        }

        publicDataUpdated(ps, SCOPE.APP, applicationId, null, keyValue)
    }

    const publicDataUpdated = function (ps, scope, applicationId, compId, data) {
        if (scope === SCOPE.APP) {
            tpaEventHandlersService.callPublicDataChangedCallbackForAllAppRegisteredComps(applicationId, data)
        } else {
            tpaEventHandlersService.callPublicDataChangedCallback(compId, applicationId, data)
        }
    }

    const setComponentValue = function (ps, componentTpaData, compPointer, callback, config) {
        if (!tpaUtils.isTpaComp(ps, compPointer)) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
            return
        }

        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        const compData = component.data.get(ps, compPointer, null, true)

        const tpaDataId = componentTpaData ? componentTpaData.id : santaCoreUtils.guidUtils.getUniqueId(PREFIX_TPA_DATA_ID)
        const appData = clientSpecMapService.getAppData(ps, compData.applicationId)
        let limit = MAX_SIZE_FOR_COMP
        if (_.get(appData, 'isWixTPA')) {
            limit = MAX_SIZE_FOR_SUPER_APP_COMP
        }

        if (!componentTpaData) {
            componentTpaData = {
                type: 'TPAData',
                id: tpaDataId,
                content: {}
            }
        }
        const appDefinitionId = _.get(compData, 'appDefinitionId')

        const keyValue = setAndReturnConfig(ps, tpaDataId, componentTpaData, pageId, config, limit, appDefinitionId)

        if (!keyValue) {
            handleFailure(callback, `Your app has exceeded the provided ${limit} chars storage space`)
            return
        }

        compData.tpaData = `#${tpaDataId}`
        component.data.update(ps, compPointer, compData, true)

        if (callback) {
            callback(keyValue)
        }

        publicDataUpdated(ps, SCOPE.COMPONENT, compData.applicationId, compPointer.id, keyValue)
    }

    const getAppValue = function (ps, applicationId, key, callback) {
        const appTpaData = getAppTpaData(ps, applicationId)

        getValue(key, appTpaData, callback, SCOPE.APP)
    }

    const getComponentValue = function (ps, compPointer, key, callback) {
        const compData = component.data.get(ps, compPointer, null, true)
        const compTpaData = getCompTpaData(compData)

        getValue(key, compTpaData, callback, SCOPE.COMPONENT)
    }

    const getValue = function (key, tpaData, callback, scope) {
        const returnObj = tpaData ? _.pick(tpaData.content, key) : null

        if (!_.isEmpty(returnObj)) {
            callback(returnObj)
        } else {
            handleFailure(callback, `key ${key} not found in ${scope} scope`)
        }
    }

    const getPublicData = function (ps, applicationId, compPointer, callback) {
        if (!tpaUtils.isTpaComp(ps, compPointer)) {
            //technically appController works for appScope, so this check is only for component scope
            handleFailure(callback, ONLY_TPA_COMPS_SUPPORTED)
            return
        }
        const APP = _.get(getAppTpaData(ps, applicationId), 'content')
        const COMPONENT = _.get(getCompTpaData(component.data.get(ps, compPointer, null, true)), 'content')
        callback({
            APP,
            COMPONENT
        })
    }

    const getAppValues = function (ps, applicationId, keys, callback) {
        keys = _.uniq(keys)

        const appTpaData = getAppTpaData(ps, applicationId)
        const result = appTpaData ? _.pick(appTpaData.content, keys) : null

        getValues(keys, result, callback, SCOPE.APP)
    }

    const getComponentValues = function (ps, compPointer, keys, callback) {
        const compData = component.data.get(ps, compPointer, null, true)
        keys = _.uniq(keys)

        const compTpaData = getCompTpaData(compData)
        const result = compTpaData ? _.pick(compTpaData.content, keys) : null

        getValues(keys, result, callback, SCOPE.COMPONENT)
    }

    const removeValue = function (ps, compPointer, key, scope, callback) {
        const compData = component.data.get(ps, compPointer, null, true)

        if (isAppScope(scope)) {
            const appTpaData = getAppTpaData(ps, compData.applicationId)
            remove(ps, appTpaData, key, 'masterPage', callback, scope, compData.applicationId, compPointer.id)
        } else {
            const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
            const compTpaData = getCompTpaData(compData)
            remove(ps, compTpaData, key, pageId, callback, scope, compData.applicationId, compPointer.id)
        }
    }

    const remove = function (ps, tpaData, key, pageId, callback, scope, applicationId, compId) {
        if (isKeyExists(tpaData, key)) {
            const resultObj = _.pick(tpaData.content, key)
            tpaData.content = JSON.stringify(_.omit(tpaData.content, key))
            dataModel.addSerializedDataItemToPage(ps, pageId, tpaData, tpaData.id)
            callback(resultObj)

            publicDataUpdated(ps, scope, applicationId, compId, resultObj)
        } else {
            handleFailure(callback, `key ${key} not found in ${scope} scope`)
        }
    }

    const getValues = function (keys, result, callback, scope) {
        const keysString = _.map(keys, key => `${key}`)
        if (!_.isEmpty(result) && _(result).keys().isEqual(keysString)) {
            callback(result)
        } else {
            const resultKeys = _.keys(result)
            const keysNotFound = _(resultKeys).xor(keys).intersection(keys).value()
            handleFailure(callback, `keys ${keysNotFound} not found in ${scope} scope`)
        }
    }

    const handleFailure = function (callback, message) {
        callback({
            error: {
                message
            }
        })
    }

    const isAppScope = scope => scope === SCOPE.APP

    const isValidSize = function (tpaDataContent, maxSize) {
        try {
            return tpaDataContent.length <= maxSize
        } catch (e) {
            return false
        }
    }

    const isValidValue = function (value) {
        return _.isBoolean(value) || _.isString(value) || _.isNumber(value) || _.isPlainObject(value)
    }

    const isKeyExists = function (tpaData, key) {
        if (!tpaData) {
            return false
        }
        return _(tpaData.content).keys().includes(key.toString())
    }

    const getAppTpaData = function (ps, applicationId) {
        const appTpaDataId = PREFIX_TPA_DATA_ID + applicationId
        const dataPointer = ps.pointers.data.getDataItem(appTpaDataId, 'masterPage')
        const tpaData = ps.dal.get(dataPointer)
        if (tpaData) {
            tpaData.content = tpaData.content ? JSON.parse(tpaData.content) : {}
        }
        return tpaData
    }

    const setAndReturnConfig = function (ps, tpaDataId, tpaData, pageId, config, maxSize, appDefinitionId) {
        const currentContent = tpaData.content
        const tpaDataContentAsString = JSON.stringify({...tpaData.content, ...config})
        if (!_.isEqual(currentContent, JSON.parse(tpaDataContentAsString))) {
            tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
        }
        if (!isValidSize(tpaDataContentAsString, maxSize)) {
            return null
        }
        const useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
        tpaData.content = tpaDataContentAsString
        dataModel.addSerializedDataItemToPage(ps, pageId, tpaData, tpaData.id, useLanguage)
        return config
    }

    const isExistsAppTpaData = function (ps, tpaDataId) {
        const dataPointer = ps.pointers.data.getDataItem(tpaDataId, 'masterPage')
        return ps.dal.isExist(dataPointer)
    }

    // TODO: add test
    const getOrphanAppTpaData = function (ps, appIdsToDelete) {
        const deletedAppsIds = appIdsToDelete || installedTpaAppsOnSiteService.getDeletedAppsIds(ps)
        return _(deletedAppsIds)
            .map(applicationId => PREFIX_TPA_DATA_ID + applicationId)
            .filter(tpaDataId => isExistsAppTpaData(ps, tpaDataId))
            .value()
    }

    const runGarbageCollection = function (ps, appIdsToDelete) {
        const orphanTpaData = getOrphanAppTpaData(ps, appIdsToDelete)
        if (!_.isEmpty(orphanTpaData)) {
            let orphanDataNodes = ps.dal.get(ps.pointers.general.getOrphanPermanentDataNodes())
            orphanDataNodes = orphanDataNodes.concat(orphanTpaData)
            ps.dal.set(ps.pointers.general.getOrphanPermanentDataNodes(), orphanDataNodes)
            _.forEach(orphanTpaData, removeTpaData.bind(null, ps))
        }
    }

    const removeTpaData = function (ps, tpaDataId) {
        ps.dal.remove(ps.pointers.data.getDataItem(tpaDataId, 'masterPage'))
    }

    return {
        set: setValue,
        setMultiple: setMultipleValues,
        getAppValue,
        getAppValues,
        getPublicData,
        get: getValueOldAPI,
        getMulti,
        remove: removeValue,
        getComponentValue,
        getComponentValues,
        runGarbageCollection,
        SCOPE
    }
})
