define(['documentServices/constants/constants', 'documentServices/componentsMetaData/metaDataUtils'], function (constants, metaDataUtils) {
    'use strict'

    function isDirectChildOfRepeater(ps, component, pageId) {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const compPointer = ps.pointers.full.components.getComponent(component.id, pagePointer)
        const parentPointer = ps.pointers.full.components.getParent(compPointer)
        const parentCompType = parentPointer ? metaDataUtils.getComponentType(ps, parentPointer) : null

        return parentCompType === 'wysiwyg.viewer.components.Repeater'
    }

    return {
        mobileConversionConfig: {
            stretchHorizontally: isDirectChildOfRepeater
        }
    }
})
