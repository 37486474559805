import {getPointer} from './pointerUtils'
const pointerType = 'renderFlags'

const createPointersMethods = () => {
    const getRenderFlag = (flagName: string) => getPointer(pointerType, pointerType, [flagName])
    const getRenderFlags = () => getPointer(pointerType, pointerType)
    return {
        general: {
            getRenderFlag,
            getRenderFlags
        },
        renderFlags: {
            getRenderFlag,
            getRenderFlags
        }
    }
}

export {createPointersMethods}
