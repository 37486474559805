import _ from 'lodash'
import type {IdGenerator} from '../types'
const randomString = (length: number): string => _.times(length, () => Math.floor(Math.random() * 36).toString(36)).join('')
const createUniqueIdGenerator = (prefixLength: number = 3, seedOverride?: string): IdGenerator => {
    const seed = seedOverride ?? randomString(prefixLength)
    let index = 0
    return (label?: string) => {
        const id = `${seed}-${index++}`
        return label ? `${id}-${label}` : id
    }
}

export {createUniqueIdGenerator}
