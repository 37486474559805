import _ from 'lodash'
import type {DocumentManager, Pointer} from '@wix/document-manager-core'
import {removePrefix, addPrefix} from '@wix/document-manager-utils'
import {constants} from '@wix/document-manager-extensions'
const {DATA_TYPES_VALUES_WITH_HASH} = constants

export type ComponentMigrationFunction = (documentManager: DocumentManager, pageId: string, pointer: Pointer, viewMode: string) => void

const runOnPageComponents = (documentManager: DocumentManager, pageId: string, viewMode: string, func: ComponentMigrationFunction) => {
    const pageComponentPointers = documentManager.pointers.structure.getAllComponentPointers(viewMode, pageId)
    _.forEach(pageComponentPointers, pointer => func(documentManager, pageId, pointer, viewMode))
}

const REF_PREFIX = '#'
const cleanRef = (ref: string) => removePrefix(ref, REF_PREFIX)
const createRef = (ref: string, namespace: string) => (DATA_TYPES_VALUES_WITH_HASH[namespace] ? addPrefix(ref, REF_PREFIX) : ref)

export {runOnPageComponents, cleanRef, createRef}
