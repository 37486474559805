define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/structure/structure',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/sectionsLayoutMigrator/sectionsLayoutMigrator',
    'documentServices/documentServicesDataFixer/fixers/utils/isPageAnchorsRemoved',
    'documentServices/structure/utils/layoutSettingsUtils'
], function (_, constants, structure, coreUtils, sectionsLayoutMigrator, isPageAnchorsRemoved, layoutSettingsUtils) {
    'use strict'

    const getHeader = (ps, viewMode) => ps.pointers.full.components.getHeader(viewMode)

    const getPagesContainer = (ps, viewMode) => ps.pointers.full.components.getPagesContainer(viewMode)

    const getCompLayout = (ps, compPointer) => ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, ['layout']))

    const getCompTop = (ps, compPointer) => getCompLayout(ps, compPointer).y

    const isFixedPosition = (ps, compPointer) => _.get(getCompLayout(ps, compPointer), 'fixedPosition', false)

    const getCompBottom = (ps, compPointer) => {
        const compLayout = getCompLayout(ps, compPointer)

        return compLayout.y + compLayout.height
    }

    const canRunFixer = ps => isPageAnchorsRemoved(ps, ps.pointers.full.components.getMasterPage(constants.VIEW_MODES.DESKTOP))

    const getMasterPageDataItemPointer = ps => ps.pointers.data.getDataItemFromMaster('masterPage')
    const isSiteAlreadyFixed = ps => layoutSettingsUtils.getLayoutSettings(ps).soapCompsAroundPagesContainer

    const shouldRunFixer = ps => !isSiteAlreadyFixed(ps)

    const flagSiteAsFixed = ps => {
        const masterPageDataItemPointer = getMasterPageDataItemPointer(ps)
        const masterPageDataItem = ps.dal.full.get(masterPageDataItemPointer)
        ps.dal.full.set(
            masterPageDataItemPointer,
            _.merge({}, masterPageDataItem, {
                layoutSettings: {
                    soapCompsAroundPagesContainer: true
                }
            })
        )
    }

    const getCompType = (ps, comp) => ps.dal.full.get(ps.pointers.getInnerPointer(comp, 'componentType'))

    const isMasterPageSection = (ps, comp) => _.includes(coreUtils.masterPageLayoutUtils.MASTER_PAGES_SECTIONS[comp.type], getCompType(ps, comp))

    const isPagesContainer = (ps, comp) => ps.pointers.isSamePointer(comp, getPagesContainer(ps, comp.type))

    const filterSoapAndPagesContainer = (ps, components) =>
        _.reject(components, comp => (isMasterPageSection(ps, comp) && !isPagesContainer(ps, comp)) || isFixedPosition(ps, comp))

    const filterSoap = (ps, components) => _.filter(components, comp => !isMasterPageSection(ps, comp))

    const getChildren = (ps, container) => ps.pointers.full.components.getChildren(container)

    const getCompIndex = (ps, components, target) => _.findIndex(components, comp => ps.pointers.isSamePointer(comp, target))

    const getNewMasterPageChildrenOrder = (ps, masterPage) => {
        const masterPageChildren = getChildren(ps, masterPage)
        const masterPageSectionsAndFixedSoap = _.filter(masterPageChildren, comp => isMasterPageSection(ps, comp) || isFixedPosition(ps, comp))

        const pagesContainerIndex = getCompIndex(ps, masterPageSectionsAndFixedSoap, getPagesContainer(ps, masterPage.type))

        return [
            ...masterPageSectionsAndFixedSoap.slice(0, pagesContainerIndex),
            ...filterSoapAndPagesContainer(ps, masterPageChildren),
            ...masterPageSectionsAndFixedSoap.slice(pagesContainerIndex + 1, masterPageSectionsAndFixedSoap.length)
        ]
    }

    const isBehind = (ps, components, compA, compB) => getCompIndex(ps, components, compA) < getCompIndex(ps, components, compB)

    function moveChildrenFromEndToStart(ps, container, numberOfChildren) {
        const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(container)
        const childrenComps = ps.dal.full.get(childrenContainerPointer)
        const index = childrenComps.length - numberOfChildren
        const newChildrenComps = [...childrenComps.slice(index, childrenComps.length), ...childrenComps.slice(0, index)]

        ps.dal.full.set(childrenContainerPointer, newChildrenComps)
    }

    const attachToBack = (ps, comps, newParent) => {
        if (_.isEmpty(comps)) {
            return
        }
        attachToFront(ps, comps, newParent)

        moveChildrenFromEndToStart(ps, newParent, comps.length)
    }

    const canAttachToContainer = (ps, comp, newParent) => structure.canSetContainer(ps, comp, newParent)

    const attachToFront = (ps, comps, newParent) => _.forEach(comps, comp => structure.setContainer(ps, comp, comp, newParent))

    const getSoapComponents = (ps, viewMode) => filterSoap(ps, getChildren(ps, ps.pointers.full.components.getMasterPage(viewMode)))

    const attachMovingCompsToHeader = (ps, viewMode, masterPageChildrenOrder, newMasterPageChildrenOrder) => {
        const header = getHeader(ps, viewMode)

        const isOverlappedWithHeader = comp => getCompTop(ps, comp) < getCompBottom(ps, header)
        if (!header || isFixedPosition(ps, header)) {
            return []
        }

        const soapComps = getSoapComponents(ps, viewMode)
        const candidatesToAttachToHeader = _.filter(
            soapComps,
            comp => !isFixedPosition(ps, comp) && canAttachToContainer(ps, comp, header) && isOverlappedWithHeader(comp)
        )
        const {attachToBack: compsToAttachToBack, attachToFront: compsToAttachToFront} = _.reduce(
            candidatesToAttachToHeader,
            (result, comp) => {
                const wasBehindSection = isBehind(ps, masterPageChildrenOrder, comp, header)
                const isBehindSection = isBehind(ps, newMasterPageChildrenOrder, comp, header)

                if (wasBehindSection && !isBehindSection) {
                    result.attachToBack.push(comp)
                } else if (!wasBehindSection && isBehindSection) {
                    result.attachToFront.push(comp)
                }

                return result
            },
            {attachToBack: [], attachToFront: []}
        )

        attachToBack(ps, compsToAttachToBack, header)
        attachToFront(ps, compsToAttachToFront, header)

        return compsToAttachToBack.concat(compsToAttachToFront)
    }

    const reorderSOAP = (ps, masterPage, newMasterPageChildrenOrder) => {
        const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(masterPage)
        const children = ps.dal.full.get(childrenContainerPointer)
        const newChildren = _.sortBy(children, compId => _.findIndex(newMasterPageChildrenOrder, {id: compId}))

        ps.dal.full.set(childrenContainerPointer, newChildren)
    }

    const fixMasterPage = ps => {
        const viewMode = constants.VIEW_MODES.DESKTOP
        const masterPage = ps.pointers.full.components.getMasterPage(viewMode)
        const masterPageChildrenOrder = getChildren(ps, masterPage)
        const newMasterPageChildrenOrder = getNewMasterPageChildrenOrder(ps, masterPage)

        if (!_.isEqual(masterPageChildrenOrder, newMasterPageChildrenOrder)) {
            attachMovingCompsToHeader(ps, viewMode, masterPageChildrenOrder, newMasterPageChildrenOrder)
            reorderSOAP(ps, masterPage, newMasterPageChildrenOrder)
        }
    }

    const exec = ps => {
        if (!shouldRunFixer(ps) || !canRunFixer(ps)) {
            return false
        }

        sectionsLayoutMigrator(ps)
        fixMasterPage(ps)

        flagSiteAsFixed(ps)
        ps.siteAPI.createPageAnchors('masterPage')
    }

    return {
        exec,
        name: 'soapOrderFixer',
        version: 1
    }
})
