define(['documentServices/documentMode/documentModeInfo', 'documentServices/structure/structureUtils'], function (documentModeInfo, structureUtils) {
    'use strict'

    const siteGapMaps = {}

    function getSiteGapMap(ps) {
        const viewMode = documentModeInfo.getViewMode(ps)

        const header = ps.pointers.components.getHeader(viewMode)
        const footer = ps.pointers.components.getFooter(viewMode)
        const pagesContainer = ps.pointers.components.getPagesContainer(viewMode)

        const headerLayout = structureUtils.getComponentLayout(ps, header)
        const pagesContainerLayout = structureUtils.getComponentLayout(ps, pagesContainer)
        const footerLayout = structureUtils.getComponentLayout(ps, footer)

        const headerBottom = headerLayout.y + headerLayout.height
        const pagesContainerBottom = pagesContainerLayout.y + pagesContainerLayout.height

        return {
            aboveHeader: headerLayout.y,
            abovePagesContainer: pagesContainerLayout.y - headerBottom,
            aboveFooter: footerLayout.y - pagesContainerBottom
        }
    }

    function createInitialGapMap(ps) {
        const siteId = ps.siteAPI.getSiteId()

        if (!siteGapMaps[siteId]) {
            siteGapMaps[siteId] = getSiteGapMap(ps)
        }

        return siteGapMaps[siteId]
    }

    function getInitialGapMap(ps) {
        return siteGapMaps[ps.siteAPI.getSiteId()]
    }

    return {
        createInitialGapMap,
        getInitialGapMap
    }
})
