import type {RefInfo, ResolvedReference, SchemaFile, CoreSchemaConfig, WixReferenceAnalysis} from '@wix/document-services-types'
import _ from 'lodash'
import ajv, {Ajv, ErrorObject, Options} from 'ajv'
import draftSchema from './draftSchema.json'
import {SchemaValidationError, CannotFindSchemaError, SchemaValidationErrorDetails} from './errors'
import {combineAllOf, dereference} from './schemaUtils'
import * as referenceUtil from './referenceUtil'
export type ValidationFunction = (namespace: string, dataTypeName: string, data: any) => void

const convertAjvError = (error: ErrorObject): SchemaValidationErrorDetails =>
    _.pick(error, ['message', 'dataPath', 'keyword', 'schemaPath', 'params']) as SchemaValidationErrorDetails

const convertAjvErrors = (errors: ErrorObject[]): SchemaValidationError => new SchemaValidationError(_.map(errors, convertAjvError))

interface CoreValidators {
    general: Ajv
    validation: Ajv
    removing?: Ajv
}
const createSchemaCore = (coreSchemaConfig: CoreSchemaConfig, references: WixReferenceAnalysis) => {
    const {namespaces, restrictedSchemas, permanentDataTypes} = coreSchemaConfig
    const options: Options = {useDefaults: false, extendRefs: true, strictNumbers: true}
    const validators = {
        general: ajv({...options, useDefaults: true}),
        validation: ajv(options)
    } as CoreValidators
    if (restrictedSchemas) {
        validators.removing = ajv({...options, removeAdditional: 'all'})
    }

    function registerSchema(schema: SchemaFile, id: string) {
        _.forOwn(validators, validator => {
            validator!.addSchema(schema, id)
        })
    }

    function addInitialSchema(schema: SchemaFile, id: string, namespace: string) {
        _.forOwn(validators, (validator, validatorName) => {
            const schemaToRegister = (validatorName === 'removing' ? restrictedSchemas![namespace] : schema) ?? schema
            validator!.addSchema(schemaToRegister, id)
        })
    }

    _.forOwn(namespaces, (schema: SchemaFile, name: string) => {
        addInitialSchema(schema, schema.$id ?? name, name)
    })

    function removeSchema(id: string) {
        _.forOwn(validators, validator => {
            validator!.removeSchema(id)
        })
    }

    function getValidationKey(namespace: string, dataTypeName: string): string {
        const ns = namespaces[namespace]
        return `${ns.$id}#/${dataTypeName}`
    }

    function validateWithCustomValidator(validator: ajv.Ajv, namespace: string, dataTypeName: string, data: any): void {
        if (!(namespace in namespaces)) {
            throw new CannotFindSchemaError(namespace, dataTypeName)
        }
        const key = getValidationKey(namespace, dataTypeName)
        const validationFunction = validator.getSchema(key)
        const valid = validationFunction?.(data)
        if (!valid) {
            throwValidationErrors(validationFunction?.errors)
        }
    }

    const validate: ValidationFunction = (namespace: string, dataTypeName: string, data: any) => {
        validateWithCustomValidator(validators.general, namespace, dataTypeName, data)
    }

    const validateStrict: ValidationFunction = (namespace: string, dataTypeName: string, data: any) => {
        const dataToValidate = _.cloneDeep(data)
        validateWithCustomValidator(validators.removing ?? validators.general, namespace, dataTypeName, dataToValidate)
    }

    const validateNoDefaults: ValidationFunction = (namespace: string, dataTypeName: string, data: any) => {
        validateWithCustomValidator(validators.validation, namespace, dataTypeName, data)
    }

    function removeAdditionalProperties(namespace: string, dataTypeName: string, data: any): void {
        if (validators.removing) {
            validateWithCustomValidator(validators.removing, namespace, dataTypeName, data)
        }
    }

    function throwValidationErrors(errors?: ErrorObject[] | null) {
        throw convertAjvErrors(errors ?? [])
    }

    function hasNamespace(namespace: string) {
        return !!_.get(namespaces, [namespace])
    }

    function hasSchemaForDataType(namespace: string, dataTypeName: string) {
        return !!_.get(namespaces, [namespace, dataTypeName])
    }

    // This is a workaround to remove internal cache created by AJV fragments behaviour
    // We get to this flow only in "Schema Dev Mode"
    // See - https://github.com/ajv-validator/ajv/issues/1293
    function clearAjvFragmentCacheForSchema(namespace: string, schema: SchemaFile, schemaName: string) {
        const key = getValidationKey(namespace, schemaName)
        removeSchema(`#/${schemaName}`)
        removeSchema(key)
        registerSchema(schema, key)
        registerSchema(schema, `#/${schemaName}`)
    }

    function extendDraftSchema(schema: any) {
        if (schema.isDraftSchema) {
            if (schema.allOf) {
                return {
                    isDraftSchema: true,
                    ...schema,
                    allOf: [draftSchema, ...schema.allOf]
                }
            }

            if (schema.properties) {
                return {
                    ..._.omit(schema, ['properties']),
                    isDraftSchema: true,
                    properties: _.merge(schema.properties, draftSchema.properties),
                    required: _.union(schema.required || [], draftSchema.required)
                }
            }
            return {
                ...schema,
                isDraftSchema: true,
                properties: draftSchema.properties,
                required: _.union(schema.required || [], draftSchema.required)
            }
        }
        return schema
    }

    function extendSchemaAndCombineAllOf(ns: any, schema: any, name: string) {
        const extended = extendDraftSchema(schema)
        const dereferenced = dereference(extended, {ns, common: namespaces.common, name})
        return combineAllOf(dereferenced)
    }

    const REFERENCE_TYPES = ['ref', 'weakRef', 'refList']
    function isReference(val: any) {
        return _.intersection(REFERENCE_TYPES, val?.pseudoType).length > 0
    }

    function extractRefsFromSchema(namespace: string, schema: any, result: RefInfo[], parentPath: string[]) {
        const subSchemas = schema.allOf || schema.anyOf || schema.oneOf || []
        _.forEach(subSchemas, subSchema => extractRefsFromSchema(namespace, subSchema, result, parentPath))
        if (schema.properties) {
            const {$ref} = schema.properties
            const currentSchema = $ref ? namespaces[namespace][$ref] : schema
            _.forOwn(currentSchema.properties, (val, key) => {
                if (val?.$ref) {
                    val = namespaces[namespace][val.$ref]
                }
                if (isReference(val)) {
                    const path = parentPath.concat(key)
                    const isWeakRef = val?.pseudoType.includes('weakRef')
                    result.push({
                        path,
                        jsonPointer: `/${path.join('/')}`,
                        shouldCollect: !isWeakRef,
                        isRefOwner: !isWeakRef,
                        shouldValidate: !isWeakRef,
                        referencedMap: namespace,
                        refTypes: []
                    })
                } else if (val.type === 'object' && val.properties) {
                    extractRefsFromSchema(namespace, val, result, parentPath.concat(key))
                }
            })
        }
    }

    function addReferences(namespace: string, name: string) {
        const ns = namespaces[namespace]
        const schema = ns[name]
        const refs: RefInfo[] = []
        extractRefsFromSchema(namespace, schema, refs, [])
        if (refs.length) {
            references[namespace][name] = refs
        }
    }

    function addDataTypesToExistingNamespace(namespace: string, schemas: Record<string, SchemaFile>, override: boolean = false) {
        const ns = namespaces[namespace]
        const id = ns.$id
        removeSchema(id)
        _.forEach(schemas, (schema, name) => {
            const extended = extendSchemaAndCombineAllOf(ns, schema, name)
            ns[name] = extended
            if (restrictedSchemas) {
                restrictedSchemas[namespace][name] = extended
            }
            if (override) {
                clearAjvFragmentCacheForSchema(namespace, ns[name] as unknown as SchemaFile, name)
            }
            addReferences(namespace, name)
        })
        addInitialSchema(ns, id, namespace)
    }

    function registerNamespace(name: string, schemas: SchemaFile) {
        if (!namespaces[name]) {
            const ns = schemas.$id ? schemas : {...schemas, $id: name}
            namespaces[name] = ns
            addInitialSchema(ns, ns.$id, name)
        }
    }

    const isDraftDataSchema = (namespace: string, dataTypeName: string) => _.get(namespaces, [namespace, dataTypeName, 'isDraftSchema'], false)
    const isDraftItem = (dataItem: any) => !!dataItem?._isDraftItem

    function extractReferences(namespace: string, dataTypeName: string, data: any): ReadonlyArray<ResolvedReference> {
        return referenceUtil.extractReferences(references, namespace, dataTypeName, data)
    }

    //this function extracts the fields from the data item which are 'reference' fields according to the schema
    function extractReferenceFieldsInfo(namespace: string, dataTypeName: string, data?: any): ReadonlyArray<RefInfo> {
        const refs = _.get(references, [namespace, dataTypeName])
        if (data) {
            return _.filter(refs, (refInfo: RefInfo) => _.has(data, refInfo.path))
        }
        return refs
    }

    const isPermanentDataType = (namespace: string, type: string): boolean => _.get(permanentDataTypes, [namespace, type], false)

    return {
        validate,
        validateStrict,
        validateNoDefaults,
        hasNamespace,
        hasSchemaForDataType,
        isPermanentDataType,
        registerNamespace,
        addDataTypesToExistingNamespace,
        isDraftDataSchema,
        isDraftItem,
        extractReferenceFieldsInfo,
        extractReferences,
        removeAdditionalProperties
    }
}

export {createSchemaCore}
