import type {PS} from '@wix/document-services-types'
import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const component = viewerLibrary.get('documentServices/component/component')
    const dataModel = viewerLibrary.get('documentServices/dataModel/dataModel')
    const componentStylesAndSkinsAPI = viewerLibrary.get('documentServices/component/componentStylesAndSkinsAPI')
    const componentCode = viewerLibrary.get('documentServices/component/componentCode')
    const componentBehaviors = viewerLibrary.get('documentServices/component/componentBehaviors')
    const constants = viewerLibrary.get('documentServices/constants/constants')
    const componentStructureInfo = viewerLibrary.get('documentServices/component/componentStructureInfo')
    const componentScroll = viewerLibrary.get('documentServices/component/componentScroll')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    const documentServicesSchemas = viewerLibrary.get('document-services-schemas')
    const design = viewerLibrary.get('documentServices/variants/design')

    return {
        methods: {
            components: {
                /**
                 * @param {ComponentReference} containerReference
                 * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}
                 * @param {string} [optionalCustomId]
                 * @returns {*}
                 */
                add: publicMethodUtils.defineDataManipulationAction(component.add, {
                    isUpdatingAnchors: dsUtils.YES,
                    getReturnValue: component.getComponentToAddRef,
                    /**
                     * @param {ps} ps
                     * @param {AbstractComponent} componentToAddPointer
                     * @param {AbstractComponent} containerPointer
                     * @param {{componentType: string}} componentDefinition
                     * @return {{component_type: string}}
                     */
                    getInteractionParams: component.getAddComponentInteractionParams,
                    shouldLogInteraction: true
                }),
                addWithConstraints: publicMethodUtils.defineDataManipulationAction(component.addWithConstraints, {
                    isUpdatingAnchors: dsUtils.YES,

                    getReturnValue(ps: PS, pagePointer: any, componentDefinition: any, constraints: any, optionalCustomId: any) {
                        return component.getComponentToAddRef(ps, pagePointer, componentDefinition, optionalCustomId)
                    }
                }),
                addAndAdjustLayout: publicMethodUtils.defineDataManipulationAction(component.addAndAdjustLayout, {
                    isUpdatingAnchors: dsUtils.NO,
                    getReturnValue: component.getComponentToAddRef
                }),
                duplicate: publicMethodUtils.defineDataManipulationAction(component.duplicate, {
                    isUpdatingAnchors: dsUtils.YES,
                    getReturnValue: component.getComponentToDuplicateRef
                }),
                serialize: publicMethodUtils.defineGetter(component.serialize),
                remove: publicMethodUtils.defineDataManipulationAction(component.remove, {
                    isAsyncOperation: component.shouldDelayDeletion,
                    asyncPreDataManipulation: component.validateRemoval,
                    isUpdatingAnchors: dataModel.shouldUpdateAnchorsAfterRemove
                }),
                buildDefaultComponentStructure: publicMethodUtils.defineGetter(componentStructureInfo.buildDefaultComponentStructure),
                getContainer: publicMethodUtils.defineGetter(component.getContainer),
                getPage: publicMethodUtils.defineGetter(component.getPage),
                getAncestors: publicMethodUtils.defineGetter(component.getAncestors),
                getSiblings: publicMethodUtils.defineGetter(component.getSiblings),
                getRepeatedComponents: publicMethodUtils.defineGetter(component.getRepeatedComponents),
                getChildren: publicMethodUtils.defineGetter(component.getChildren),
                getChildrenFromFull: publicMethodUtils.defineGetter(component.getChildrenFromFull),
                getTpaChildren: publicMethodUtils.defineGetter(component.getTpaChildren),
                getBlogChildren: publicMethodUtils.defineGetter(component.getBlogChildren),
                getType: publicMethodUtils.defineGetter(component.getType),
                getDefinition: publicMethodUtils.defineGetter(component.getDefinition),
                getMobileRef: publicMethodUtils.defineGetter(component.getMobileRef),
                isRenderedOnSite: publicMethodUtils.defineGetter(component.isRenderedOnSite),
                migrate: publicMethodUtils.defineDataManipulationAction(component.migrate),
                properties: {
                    update: publicMethodUtils.defineDataManipulationAction(component.properties.update, {
                        singleComp: dataModel.shouldUpdatePropertiesWithSingleComp,
                        isUpdatingAnchors: dataModel.shouldUpdateAnchorsAfterPropertiesUpdate
                    }),
                    get: publicMethodUtils.defineGetter(component.properties.get)
                },
                skin: {
                    set: publicMethodUtils.defineDataManipulationAction(componentStylesAndSkinsAPI.skin.set),
                    get: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.skin.get),
                    getComponentSkinExports: publicMethodUtils.defineGetter(componentStylesAndSkinsAPI.skin.getComponentSkinExports)
                },
                layout: {
                    updateAndPushStart: publicMethodUtils.defineDataManipulationAction(component.layout.updateAndPushStart),
                    updateAndPushUpdate: publicMethodUtils.defineDataManipulationAction(component.layout.updateAndPushUpdate),
                    updateAndPushEnd: publicMethodUtils.defineDataManipulationAction(component.layout.updateAndPushEnd),
                    runtime: {
                        detachLayout: publicMethodUtils.defineDataManipulationAction(component.layout.runtime.detachLayout),
                        updateDetachedLayout: publicMethodUtils.defineDataManipulationAction(component.layout.runtime.updateDetachedLayout, {
                            disableLogInteraction: true
                        }),
                        reattachLayoutAndUpdate: publicMethodUtils.defineDataManipulationAction(component.layout.runtime.reattachLayoutAndUpdate),
                        updateAndPush: publicMethodUtils.defineAction(component.layout.runtime.updateAndPush),
                        remove: publicMethodUtils.defineAction(component.layout.runtime.remove),
                        detachComponents: publicMethodUtils.defineAction(component.layout.runtime.detachComponents),
                        reattachComponents: publicMethodUtils.defineAction(component.layout.runtime.reattachComponents)
                    },
                    measure: {
                        getBoundingClientRect: publicMethodUtils.defineGetter(component.layout.measure.getBoundingClientRect),
                        getInnerElementBoundingClientRects: publicMethodUtils.defineGetter(component.layout.measure.getInnerElementBoundingClientRects),
                        getRelativeToViewportBoundingBox: publicMethodUtils.defineGetter(component.layout.measure.getRelativeToViewportBoundingBox),
                        getPadding: publicMethodUtils.defineGetter(component.layout.measure.getPadding)
                    },
                    get: publicMethodUtils.defineGetter(component.layout.get),
                    RESIZE_SIDES: constants.RESIZE_SIDES
                },
                is: {
                    /**
                     * Checks if a component is duplicable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @param {AbstractComponent} pageReference
                     * @returns {boolean}
                     */
                    duplicatable: publicMethodUtils.defineGetter(component.isComponentDuplicatable),
                    /**
                     * Checks if a component is removable
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @param {AbstractComponent} pageReference
                     * @returns {boolean}
                     */
                    removable: publicMethodUtils.defineGetter(component.isComponentRemovable),
                    modal: publicMethodUtils.defineGetter(component.isComponentModal),
                    usingLegacyAppPartSchema: publicMethodUtils.defineGetter(component.isComponentUsingLegacyAppPartSchema),
                    exist: publicMethodUtils.defineGetter(component.isExist),
                    /**
                     * Checks if a component is visible on site
                     * @member documentServices.components.is
                     * @param {AbstractComponent} componentReference
                     * @returns {boolean}
                     */
                    visible: publicMethodUtils.defineGetter(component.isComponentVisible),
                    rendered: publicMethodUtils.defineGetter(componentStructureInfo.isRenderedOnSite)
                },
                COMPONENT_DEFINITION_MAP: documentServicesSchemas.schemas.componentsDefinitionsMap,
                behaviors: {
                    execute: publicMethodUtils.defineDataManipulationAction(componentBehaviors.executeBehavior),
                    getRuntimeState: publicMethodUtils.defineGetter(componentBehaviors.getRuntimeState)
                },
                scroll: {
                    to: publicMethodUtils.defineAction(componentScroll.scroll),
                    by: publicMethodUtils.defineAction(componentScroll.scrollBy)
                },
                code: {
                    generateNicknamesForSite: publicMethodUtils.defineAction(componentCode.generateNicknamesForSite),
                    getNickname: publicMethodUtils.defineGetter(componentCode.getNickname),
                    setNickname: publicMethodUtils.defineAction(componentCode.setNickname),
                    validateNickname: publicMethodUtils.defineGetter(componentCode.validateNickname),
                    getComponentsInPage: publicMethodUtils.defineGetter(componentCode.getComponentsInPage),
                    VALIDATIONS: componentCode.VALIDATIONS
                },
                modes: {
                    /** JSON getters **/
                    getTypes: publicMethodUtils.defineGetter(component.modes.getModeTypes),
                    getModeById: publicMethodUtils.defineGetter(component.modes.getComponentModeById),
                    getModes: publicMethodUtils.defineGetter(component.modes.getComponentModes),
                    getModesByType: publicMethodUtils.defineGetter(component.modes.getComponentModesByType),
                    getAllCompOverrides: publicMethodUtils.defineGetter(component.modes.overrides.getAllOverrides),
                    isDisplayedByDefault: publicMethodUtils.defineGetter(component.modes.isComponentDisplayedByDefault),
                    isComponentDisplayedInModes: publicMethodUtils.defineGetter(component.modes.isComponentDisplayedInModes),
                    getModesIdsAndParentNeededToShowComp: publicMethodUtils.defineGetter(component.modes.getModesIdsAndParentNeededToShowComp),

                    /** JSON setters **/
                    addModeDefinition: publicMethodUtils.defineDataManipulationAction(component.modes.addModeDefinition, {
                        getReturnValue: component.modes.getModeToAddId
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(component.modes.removeComponentMode, {
                        noBatchingAfter: true
                    }),

                    /** stateful getters (considering currently active modes) **/
                    getComponentActiveModeIds: publicMethodUtils.defineGetter(component.modes.getComponentActiveModeIds),
                    getFirstAncestorActiveModes: publicMethodUtils.defineGetter(component.modes.getFirstAncestorActiveModes),
                    getFirstAncestorWithActiveModes: publicMethodUtils.defineGetter(component.modes.getFirstAncestorWithActiveModes),
                    isComponentCurrentlyDisplayed: publicMethodUtils.defineGetter(component.modes.isComponentCurrentlyDisplayed),

                    /** stateful setters (considering currently active modes) **/
                    activateComponentMode: publicMethodUtils.defineDataManipulationAction(component.modes.activateComponentMode, {
                        noBatchingAfter: true
                    }),
                    deactivateComponentMode: publicMethodUtils.defineDataManipulationAction(component.modes.deactivateCompMode, {
                        noBatchingAfter: true
                    }),
                    resetAllActiveModes: publicMethodUtils.defineDataManipulationAction(component.modes.resetAllActiveModes, {
                        noBatchingAfter: true,
                        disableLogInteraction: true
                    }),
                    applyCurrentToAllModes: publicMethodUtils.defineDataManipulationAction(component.modes.overrides.applyCurrentToAllModes),
                    applyComponentToMode: publicMethodUtils.defineDataManipulationAction(component.applyComponentToMode),
                    showComponentOnlyInModesCombination: publicMethodUtils.defineDataManipulationAction(
                        component.modes.overrides.showComponentOnlyInModesCombination
                    ),
                    showComponentOnlyOnPagesGroup: publicMethodUtils.defineDataManipulationAction(component.modes.showComponentOnlyOnPagesGroup),
                    getComponentModesAvailableInView: publicMethodUtils.defineGetter(component.modes.getComponentModesAvailableInView),
                    removeDesignBehaviorsFromAllModes: publicMethodUtils.defineDataManipulationAction(component.modes.removeDesignBehaviorsFromAllModes)
                },
                design: {
                    update: publicMethodUtils.defineDataManipulationAction(component.design.updateDesignItem),
                    get: publicMethodUtils.defineGetter(design.getDesignItem),
                    getByModes: publicMethodUtils.defineGetter(design.getDesignItemByModes),
                    remove: publicMethodUtils.defineDataManipulationAction(design.removeComponentDesignItem),
                    isSectionDataMigrationOpen: publicMethodUtils.defineGetter(design.isSectionDataMigrationOpen),
                    behaviors: {
                        update: publicMethodUtils.defineDataManipulationAction(design.updateDesignItemBehaviors),
                        remove: publicMethodUtils.defineDataManipulationAction(design.removeDesignItemBehaviors)
                    }
                }
            },
            siteSegments: {
                /**
                 * @class documentServices.siteSegments.layout
                 * @link documentServices.components.layout
                 */
                layout: {
                    get: publicMethodUtils.defineGetter(component.layout.get)
                }
            }
        },
        initMethod: component.initialize
    }
}
