import _ from 'lodash'
import * as skinsPackage from '../../_internal/skins'
import wixTransitionGroup from './components/transitionGroup/wixTransitionGroup'
import activityTypes from './activities/activityTypes'
import activityService from './activities/activityService'
import uniquePageIdMixin from './mixins/uniquePageIdMixin'
import postMessageCompMixin from './mixins/postMessageCompMixin'
import blockOuterScrollMixin from './mixins/blockOuterScrollMixin'
import cyclicTabbingMixin from './mixins/cyclicTabbingMixin'
import galleryImageExpandedActionMixin from './mixins/galleryImageExpandedActionMixin'
import createChildComponentMixin from './mixins/createChildComponentMixin'
import mobileDropDownMenuMixin from './mixins/mobileDropDownMenuMixin'
import mediaZoomWrapperMixin from './mixins/mediaZoomWrapperMixin'
import accessibility from './utils/accessibility/accessibility'
import matrixAnimationManipulation from './utils/matrixAnimationManipulation'
import boxSlideShowCommon from './utils/boxSlideShowCommon'
import galleryPagingCalculations from './utils/galleryPagingCalculations'
import fullScreenOverlay from './utils/fullScreenOverlay'
import dataRequirementsCheckerRegistrar from './registrars/dataRequirementsCheckerRegistrar'
import siteAspectsRegistry from './registrars/siteAspectsRegistry'
import santaTypesFetchersRegistry from './registrars/santaTypesFetchersRegistry'
import compRegistrar from './registrars/compRegistrar'
import santaComponents from '@wix/santa-components'

export default {
    dataRequirementsCheckerRegistrar,
    compRegistrar,
    siteAspectsRegistry,
    santaTypesFetchersRegistry,
    activityTypes,
    activityService,
    mixins: {
        skinBasedComp: santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap),
        customSkinBasedComp: ({customCssFunc, customFontsFunc}) => {
            const mixin = santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap)

            if (customCssFunc) {
                const baseGetCompCss = mixin.statics.getCompCss
                mixin.statics.getCompCss = (styleId, props, styleClass = styleId) => {
                    const defaultCss = baseGetCompCss(styleId, props, styleClass)
                    const getSkin = skinId => skinsPackage.skinsMap.get(skinId)
                    const customCss = customCssFunc(getSkin, props, styleId, defaultCss, styleClass)

                    return _.assign(defaultCss, customCss)
                }
                mixin.statics.getCompCss.cssTypes = _.assign(customCssFunc.cssTypes, mixin.statics.getCompCss.cssTypes)
            }

            if (customFontsFunc) {
                const baseGetCompFonts = mixin.statics.getCompFonts
                mixin.statics.getCompFonts = (styleIds, props) => {
                    const defaultFonts = baseGetCompFonts(styleIds, props)
                    const customFonts = customFontsFunc(props)

                    return _.union(defaultFonts, customFonts)
                }
                mixin.statics.getCompFonts.fontsTypes = _.assign(customFontsFunc.fontsTypes, mixin.statics.getCompFonts.fontsTypes)
            }

            return mixin
        },
        skinInfo: santaComponents.mixins.skinInfoMixin(skinsPackage.skinsMap),
        cyclicTabbingMixin,
        blockOuterScrollMixin,
        uniquePageIdMixin,
        galleryImageExpandedActionMixin,
        createChildComponentMixin,
        mobileDropDownMenuMixin,
        mediaZoomWrapperMixin,
        postMessageCompMixin
    },
    utils: {
        accessibility,
        matrixAnimationManipulation,
        boxSlideShowCommon,
        galleryPagingCalculations,
        fullScreenOverlay
    },
    wixTransitionGroup
}
