/**
 * Created by eitanr on 6/23/14.
 */
import _ from 'lodash'
import utils from './svgScalerUtils'
import pathScaler from './svgPathScaler'
import polygonScaler from './svgPolygonScaler'
import circleScaler from './svgCircleScaler'
import rectScaler from './svgRectScaler'
import ellipseScaler from './svgEllipseScaler'
import polylineScaler from './svgPolylineScaler'
import lineScaler from './svgLineScaler'

const validSvgNodes = ['path', 'polygon', 'rect', 'circle', 'ellipse', 'polyline', 'line']

function isValidSvgChild(elementName) {
    return _.includes(validSvgNodes, elementName.toLowerCase())
}

function calculateScale(oldSize, newSize, maintainAspectRatio, originalAspectRatio) {
    const currentAspectRatio = newSize.width / newSize.height
    const growsInWidth = currentAspectRatio > originalAspectRatio
    const growsInHeight = currentAspectRatio < originalAspectRatio
    let scaleX
    let scaleY
    let newWidth
    let newHeight

    if (!oldSize.width) {
        scaleX = 1
    } else if (maintainAspectRatio && growsInWidth) {
        newWidth = newSize.height * originalAspectRatio
        scaleX = newWidth / oldSize.width
    } else {
        scaleX = newSize.width / oldSize.width
    }

    if (!oldSize.height) {
        scaleY = 1
    } else if (maintainAspectRatio && growsInHeight) {
        newHeight = newSize.width / originalAspectRatio
        scaleY = newHeight / oldSize.height
    } else {
        scaleY = newSize.height / oldSize.height
    }

    return {
        scaleX: utils.round(scaleX),
        scaleY: utils.round(scaleY)
    }
}

function translateShapePosition(stroke, box) {
    return {
        // @ts-ignore
        transform: `translate(${parseFloat(-1 * box.x + stroke * 0.5)},${parseFloat(-1 * box.y + stroke * 0.5)})`
    }
}

function getSvgNodesFragments(svgString) {
    const range = window.document.createRange()
    const svgFragment = range.createContextualFragment(svgString)
    const groupFragment = svgFragment.querySelector('g')
    return groupFragment.querySelectorAll(validSvgNodes.join(','))
}

function getSvgString(svgElem) {
    const tempDiv = window.document.createElement('div')
    tempDiv.appendChild(svgElem.cloneNode(true))
    return tempDiv.innerHTML
}

function measureScale(id, nodesMap, requestedSize, strokeWidth, maintainAspectRatio) {
    const svgElem = (nodesMap[`${id}svg`] = _.last(nodesMap[id].querySelectorAll('svg')))
    const boundingBox = svgElem ? svgElem.getBBox() : {}
    const svgString = getSvgString(svgElem)
    const groupElement = (nodesMap[`${id}svg-g`] = svgElem.getElementsByTagName('g')[0])
    const originalShapeSize = _.pick(boundingBox, ['width', 'height'])
    const currentPosition = _.pick(boundingBox, ['x', 'y'])
    const scalers = {
        path: pathScaler,
        polygon: polygonScaler,
        rect: rectScaler,
        circle: circleScaler,
        ellipse: ellipseScaler,
        polyline: polylineScaler,
        line: lineScaler
    }
    if (!groupElement) {
        return
    }
    const originalAspectRatio = boundingBox.width / boundingBox.height
    const scaleFactor = calculateScale(originalShapeSize, requestedSize, maintainAspectRatio, originalAspectRatio)
    const svgMeasures = {
        scalers: {},
        style: {},
        groupTransform: {}
    }
    if (scaleFactor.scaleX === 1 && scaleFactor.scaleY === 1) {
        return
    }

    const svgNodesFragments = getSvgNodesFragments(svgString)
    let nodeName
    let scaleKey

    //parse and scale fragments elements
    _.forEach(svgNodesFragments, function (elem, index) {
        nodeName = elem.nodeName.toLowerCase()
        scaleKey = `${id}svg${nodeName}${index}`
        svgMeasures.scalers[scaleKey] = scalers[nodeName].scale(elem, scaleFactor.scaleX, scaleFactor.scaleY)
    })

    //store live elements in nodes map
    _(groupElement.childNodes)
        .filter(elem => isValidSvgChild(elem.nodeName))
        .forEach(function (elem, index) {
            nodeName = elem.nodeName.toLowerCase()
            scaleKey = `${id}svg${nodeName}${index}`
            nodesMap[scaleKey] = elem
        })

    svgMeasures.groupTransform = translateShapePosition(strokeWidth, {
        x: currentPosition.x * scaleFactor.scaleX,
        y: currentPosition.y * scaleFactor.scaleY
    })

    svgMeasures.style = {
        strokeWidth,
        width: `${Math.ceil(originalShapeSize.width * scaleFactor.scaleX + strokeWidth)}px`,
        height: `${Math.ceil(originalShapeSize.height * scaleFactor.scaleY + strokeWidth)}px`
    }

    return svgMeasures
}

function patchScale(id, patchers, svgMeasures) {
    patchers.css(`${id}svg`, svgMeasures.style)
    patchers.attr(`${id}svg-g`, svgMeasures.groupTransform)

    _.forEach(svgMeasures.scalers, function (attributes, scaleKey) {
        patchers.attr(scaleKey, attributes)
    })

    patchers.attr(`${id}svg`, {viewBox: ' '})
}

export default {
    measure: measureScale,
    patch: patchScale
}
