define([
    'lodash',
    '@wix/mobile-conversion',
    '@wix/image-client-api/dist/imageClientApi',
    'documentServices/utils/utils',
    'documentServices/page/popupUtils',
    'document-services-schemas',
    'documentServices/componentsMetaData/legacyFullWidthContainers',
    'documentServices/dataModel/dataModel',
    'documentServices/appControllerData/appControllerStageData',
    'documentServices/variants/design',
    'documentServices/constants/constants'
], function (
    _,
    mobileCore,
    imageClientApi,
    dsUtils,
    popupUtils,
    documentServicesSchemas,
    legacyFullWidthContainers,
    dataModel,
    appControllerStageData,
    design,
    constants
) {
    'use strict'

    const masterPage = 'wysiwyg.viewer.components.WSiteStructure'

    const SITE_SEGMENTS = [
        'wysiwyg.viewer.components.SiteSegmentContainer',
        'wysiwyg.viewer.components.FooterContainer',
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.PagesContainer'
    ]

    const NON_CONTAINABLE_FULL_WIDTH = {
        'wysiwyg.viewer.components.ScreenWidthContainer': true,
        'wysiwyg.viewer.components.StripContainerSlideShow': true,
        'wysiwyg.viewer.components.StripContainerSlideShowSlide': true,
        'wysiwyg.viewer.components.StateStrip': true,
        'wysiwyg.viewer.components.StateStripState': true
    }

    const HEADER_FOOTER_PAGE_AND_MASTERPAGE = [
        'wysiwyg.viewer.components.FooterContainer',
        'wysiwyg.viewer.components.HeaderContainer',
        'mobile.core.components.Page',
        'core.components.Page',
        'wixapps.integration.components.AppPage',
        masterPage
    ]

    const COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE = [
        'wysiwyg.viewer.components.BoxSlideShowSlide',
        'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.StateBoxFormState',
        'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.StateStripState',
        'wysiwyg.viewer.components.StateStrip',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide',
        'wysiwyg.viewer.components.StripContainerSlideShow',
        'wysiwyg.viewer.components.StripColumnsContainer',
        'wysiwyg.viewer.components.HoverBox',
        'wysiwyg.common.components.anchor.viewer.Anchor',
        'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
        'wysiwyg.viewer.components.tpapps.TPAWidget',
        'wysiwyg.viewer.components.tpapps.TPASection',
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        'wixapps.integration.components.AppPart',
        'wixapps.integration.components.AppPart2'
    ]

    const CONTAINERS_ALLOWD_TO_HAVE_BREAKPOINTS = [
        'core.components.Page',
        'mobile.core.components.Page',
        'wixapps.integration.components.AppPage',
        'responsive.components.Section',
        'responsive.components.HeaderSection',
        'responsive.components.FooterSection',
        'responsive.components.MembersAreaSection',
        'wysiwyg.viewer.components.PopupContainer',
        'wysiwyg.viewer.components.tpapps.TPAWidget',
        'wysiwyg.viewer.components.tpapps.TPASection',
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        'wysiwyg.viewer.components.MenuContainer'
    ]

    const SITE_SEGMENT_CONTAINERS = ['wysiwyg.viewer.components.ClassicSection']

    const CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE = ['wysiwyg.viewer.components.FormContainer']

    function sanitizeQuery(dataQuery) {
        return _.isString(dataQuery) && dataQuery[0] === '#' ? dataQuery.slice(1) : dataQuery
    }

    const metaDataUtils = {
        COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,

        isSiteStructure(componentType) {
            return componentType === masterPage
        },

        isSiteSegment(componentType) {
            return _.includes(SITE_SEGMENTS, componentType)
        },

        isContainer(compType) {
            return documentServicesSchemas.services.isContainer(compType)
        },

        isLegacyFullWidthContainer(ps, compPtr) {
            const compType = dsUtils.getComponentType(ps, compPtr)
            const isLegacyFullWidthContainer = legacyFullWidthContainers[compType]

            if (_.isFunction(isLegacyFullWidthContainer)) {
                return isLegacyFullWidthContainer(ps, compPtr)
            }

            return !!isLegacyFullWidthContainer
        },

        isLegacyFullWidthContainerByType(compType) {
            return !!(legacyFullWidthContainers[compType] && !_.isFunction(legacyFullWidthContainers[compType]))
        },

        isHeaderOrFooterOrPageOrMasterPage(componentType) {
            return _.includes(HEADER_FOOTER_PAGE_AND_MASTERPAGE, componentType)
        },

        isAllowedToHaveBreakpoints(componentType) {
            return this.isHeaderOrFooterOrPageOrMasterPage(componentType) || _.includes(CONTAINERS_ALLOWD_TO_HAVE_BREAKPOINTS, componentType)
        },

        isSiteSegmentContainer(componentType) {
            return this.isHeaderOrFooterOrPageOrMasterPage(componentType) || _.includes(SITE_SEGMENT_CONTAINERS, componentType)
        },

        isComponentSuitableForNonRenderingState(componentType) {
            return !_.includes(COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
        },

        isContainerSuitableForNonRenderingState(componentType) {
            return _.includes(CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
        },

        isPopupPageOrPopupContainer(ps, compPointer) {
            return popupUtils.isPopup(ps, compPointer.id) || dsUtils.getComponentType(ps, compPointer) === 'wysiwyg.viewer.components.PopupContainer'
        },

        isNonContainableFullWidth(componentType) {
            return !!(NON_CONTAINABLE_FULL_WIDTH[componentType] && !_.isFunction(NON_CONTAINABLE_FULL_WIDTH[componentType]))
        },

        getComponentType: dsUtils.getComponentType,

        getComponentNickname(ps, compPointer) {
            const componentType = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            if (!componentType) {
                return ''
            }

            const baseNicknameFromDefinitionsMap = _.get(documentServicesSchemas.services.schemasService.getDefinition(componentType), 'nickname')

            if (baseNicknameFromDefinitionsMap) {
                return baseNicknameFromDefinitionsMap
            }
            return _.camelCase(_.last(componentType.split('.')))
        },

        getChildrenTypesDeep(descendants = [], ps) {
            if (!descendants.length) {
                return []
            }

            return _.flatMap(descendants, descendant => {
                if (_.isString(descendant)) {
                    const descendantPointer = ps.pointers.getPointer(descendant, constants.VIEW_MODES.DESKTOP)
                    descendant = ps?.dal.get(descendantPointer)
                }
                const {componentType} = descendant
                const childrenTypesDeep = metaDataUtils.getChildrenTypesDeep(descendant.components, ps)
                return [componentType].concat(childrenTypesDeep)
            })
        },

        containableByFullWidthPopup(ps, comp, containerPointer) {
            const pagePointer = ps.pointers.components.getPageOfComponent(containerPointer)

            return !(popupUtils.isPopup(ps, pagePointer.id) && !popupUtils.isPopupFullWidth(ps, pagePointer))
        },

        notContainableByPopup(ps, comp, containerPointer) {
            const pagePointer = ps.pointers.components.getPageOfComponent(containerPointer)

            return !popupUtils.isPopup(ps, pagePointer.id)
        },

        isMaintainSvgAspectRatio(ps, compPtr) {
            const compStructure = ps.dal.get(compPtr)
            const page = ps.pointers.components.getPageOfComponent(compPtr)
            const compPropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(compStructure.propertyQuery), page.id)
            const compProps = ps.dal.get(compPropsPointer)

            return compProps.displayMode === 'fit'
        },

        isMaintainWPhotoAspectRatio(ps, comp, pageId) {
            const {STRETCH, LEGACY_FULL} = imageClientApi.fittingTypes
            const propsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(comp.propertyQuery), pageId)
            const {autoFill, displayMode} = ps.dal.get(propsPointer) || {}

            return !autoFill && displayMode !== STRETCH && displayMode !== LEGACY_FULL
        },

        isMaintainVideoBoxAspectRatio(compDesign) {
            const mediaFeatures = _.get(compDesign, ['background', 'mediaRef', 'mediaFeatures'], [])

            return _.includes(mediaFeatures, 'alpha')
        },

        isMembersMenu(ps, compStructure, pageId) {
            const {dataQuery} = compStructure
            const dataItemPointer = ps.pointers.data.getDataItem(sanitizeQuery(dataQuery), pageId)
            const dataItem = ps.dal.get(dataItemPointer) || {}
            return dataItem.menuRef === '#MEMBERS_SUB_MENU'
        },

        getVideoBoxAspectRatio(compDesign) {
            const qualities = _.get(compDesign, ['background', 'mediaRef', 'qualities'], [])
            const {width, height} = _.maxBy(qualities, 'width') || {}

            return width / height
        },

        getControllerNickname(ps, controllerRef) {
            const {applicationId, controllerType} = dataModel.getDataItem(ps, controllerRef) || {}
            const {nickname} = appControllerStageData.getControllerStageData(ps, controllerRef.id, controllerType, applicationId) || {}

            return _.camelCase(nickname || controllerType)
        },

        getNicknameFromStageData(ps, compRef) {
            const nickname = _.get(appControllerStageData.getControllerStageDataByControllerRef(ps, compRef), 'nickname')
            return _.camelCase(nickname)
        },

        getFromStageData(ps, stageDataKey, compRef, defaultValue) {
            const hasAppManifest = appControllerStageData.hasAppManifestByControllerRef(ps, compRef)
            if (hasAppManifest) {
                const valueFromStageData = _.get(appControllerStageData.getControllerStageDataByControllerRef(ps, compRef), ['behavior', stageDataKey])
                return !_.isNil(valueFromStageData) ? valueFromStageData : defaultValue
            }
            return defaultValue
        },

        isTogglingShowOnAllPages(ps, compRef, potentialContainerPointer) {
            if (_.get(compRef, 'id') && _.get(potentialContainerPointer, 'id')) {
                return (
                    (ps.pointers.components.isInMasterPage(compRef) && !ps.pointers.components.isInMasterPage(potentialContainerPointer)) ||
                    (!ps.pointers.components.isInMasterPage(compRef) && ps.pointers.components.isInMasterPage(potentialContainerPointer))
                )
            }
        },
        getMobileFixedSizesForColumn(ps, comp, pageId) {
            const width = mobileCore.conversionConfig.MOBILE_WIDTH
            const compDesign = design.getComponentDefaultDesign(ps, comp, pageId)
            let media = _.get(compDesign, ['background', 'mediaRef'])

            media = (media && (media.posterImageRef || media)) || {}
            return media.height && media.width ? {width, height: (width * media.height) / media.width} : null
        },
        getControllerLayoutLimits(ps, compPointer) {
            const controllerStageData = appControllerStageData.getControllerStageDataByControllerRef(ps, compPointer)

            const isMobileView = compPointer.type === constants.VIEW_MODES.MOBILE

            return controllerStageData?.layoutLimits?.[isMobileView ? 'mobile' : 'desktop']
        }
    }

    return metaDataUtils
})
