define(['documentServices/constants/constants'], function (constants) {
    'use strict'

    return {
        mobileConversionConfig: {
            hideByDefault(ps) {
                const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
                const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, masterPagePointer)
                const menuContainerIsExist = ps.dal.isExist(menuContainerPointer)
                return !menuContainerIsExist
            },
            fixedSize: {
                width: 40,
                height: 26
            }
        },
        // has to return `defaultMobileProperties` otherwise the component will not splitted
        defaultMobileProperties: (ps, componentStructure, desktopProps) => desktopProps
    }
})
