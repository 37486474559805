import {debug} from '@wix/document-manager-core'
import {
    CreateTransactionRequest,
    CreateTransactionResponse,
    DuplexerChannel,
    GetDocumentResponse,
    GetTransactionResponse,
    csaveDuplexer
} from '@wix/document-manager-extensions'
import type {ContinuousSaveServer} from '@wix/document-manager-extensions/src/extensions/csave/csaveTypes'
import type {ViewsRESTRes, ViewsServer} from '@wix/document-manager-extensions/src/extensions/views/viewsTypes'
import type {CreateRevisionReq, CreateRevisionRes, GetTransactionsResponse, SaveRequest} from '@wix/document-manager-extensions/src/types'
import {deleteJson, getJsonWithParams, postJsonWithAuth} from '../utils/fetch'
import {HttpClient} from '@wix/http-client'
import {getSitePagesNamespaces} from '@wix/ambassador-document-management-pages-metadata-manager-v1-page-metadata/http' //eslint-disable-line wix-editor/no-internal-import

const log = debug('csave')

export const MAX_LONG = '9223372036854775807'

interface Urls {
    createRevision: string
    save: string
    save2: string
    transactions: string
    document: string
    views: string
}

/**
 * based on
 * https://github.com/wix-private/editor-server/blob/master/wix-html-server/editor-document-store/src/main/proto/com/wixpress/editor/api/document_store.proto
 */
export class ServerFacade implements ContinuousSaveServer, ViewsServer {
    protected lastTransactionId: string | undefined

    private instanceProvider: () => string = () => {
        throw new Error('Instance provider was called before `setInstanceProvider` was called')
    }

    private httpClient = new HttpClient({getAppToken: this.instanceProvider})
    protected urls: Urls = {
        createRevision: '',
        save: '',
        save2: '',
        transactions: '',
        document: '',
        views: ''
    }

    constructor(editorRootUrl: string) {
        this.initUrls(editorRootUrl)
    }

    initUrls(editorRootUrl: string) {
        const docStore = `${editorRootUrl}_api/editor-document-store/`
        const baseUrl = `${docStore}v1/`
        const baseUrl2 = `${docStore}v2/`
        this.urls.createRevision = `${editorRootUrl}html/v1/create_revision`
        this.urls.save = `${baseUrl}save`
        this.urls.save2 = `${baseUrl2}save`
        this.urls.transactions = `${baseUrl}transactions`
        this.urls.views = `${editorRootUrl}_api/editor-pages-metadata/v1/sitePagesNamespaces`
        this.urls.document = `${baseUrl}document`
    }

    setInstanceProvider(provider: () => string): void {
        this.instanceProvider = provider
    }

    async deleteTransactions(instance: string, to: string = MAX_LONG): Promise<void> {
        // deleteTransaction must include body param to => the transactions up until this ID will be deleted
        const payload = {to}
        const result = await deleteJson(this.urls.transactions, instance, payload)
        this.lastTransactionId = result.transactionId
        log.info('deleteTransactions done', result)
    }

    async createRevision(req: CreateRevisionReq): Promise<CreateRevisionRes> {
        log.info('createRevision')
        return await postJsonWithAuth(this.urls.createRevision, this.instanceProvider(), req)
    }

    async getTransactions(afterTransactionId: string, untilTransactionId: string, branchId: string): Promise<GetTransactionsResponse> {
        const queryParams = {afterTransactionId, untilTransactionId, branchId}
        return getJsonWithParams(this.urls.transactions, queryParams, this.instanceProvider())
    }

    async getJsonWithParams(url: string, params: Record<string, any>) {
        return await getJsonWithParams(url, params, this.instanceProvider())
    }

    async getViews(): Promise<ViewsRESTRes> {
        const httpResponse = await this.httpClient.request(getSitePagesNamespaces({}))
        return httpResponse.data as ViewsRESTRes
    }

    async getTransaction(transactionId: string, branchId?: string): Promise<GetTransactionResponse> {
        const params = {branchId}
        return getJsonWithParams(`${this.urls.transactions}/${transactionId}`, params, this.instanceProvider())
    }

    async getStore(branchId: string, afterTransactionId: string, untilTransactionId?: string): Promise<GetDocumentResponse> {
        const params = {afterTransactionId, untilTransactionId, branchId}
        return getJsonWithParams(this.urls.document, params, this.instanceProvider())
    }

    /**
     * first createTransaction should be sent without lastTransactionId, the subsequent calls must include the previous tx id as lastTransactionId
     * PendingServerTransaction
     */
    async save(payload: CreateTransactionRequest): Promise<CreateTransactionResponse> {
        log.info('sending tx')
        return await postJsonWithAuth<CreateTransactionResponse>(this.urls.save, this.instanceProvider(), payload)
    }

    async asyncSave(payload: SaveRequest): Promise<void> {
        log.info('sending pending tx')
        return await postJsonWithAuth<void>(this.urls.save2, this.instanceProvider(), payload)
    }

    setLast(transactionId: string) {
        this.lastTransactionId = transactionId
    }

    getLast(): string | undefined {
        return this.lastTransactionId
    }

    createDuplexer(wixInstanceProvider: csaveDuplexer.WixInstanceProvider, origin: string, branch?: string) {
        return new DuplexerChannel(wixInstanceProvider, origin, branch)
    }

    async onChannelReady(): Promise<void> {}
}
