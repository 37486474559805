import classicScrollAnchorsPublicAPI from '../modules/classic/scrollAnchorsPublicAPI'
import customElementsPublicAPI from '../modules/customElements/customElementsPublicAPI'
import breakpointsPublicAPI from '../modules/responsive/breakpointsPublicAPI'
import responsivePublicAPI from '../modules/responsive/responsivePublicAPI'
import responsiveStylesPublicAPI from '../modules/responsive/responsiveStylesPublicAPI'
import scrollAnchorsPublicAPI from '../modules/responsive/scrollAnchorsPublicAPI'
import variantsPublicAPI from '../modules/variants/variantsPublicAPI'
import accessibilityPublicAPI from './definitions/accessibilityPublicAPI'
import accountPublicAPI from './definitions/accountPublicAPI'
import actionsAndBehaviorsPublicAPI from './definitions/actionsAndBehaviorsPublicAPI'
import appControllerDataPublicAPI from './definitions/appControllerDataPublicAPI'
import appStudioPublicAPI from './definitions/appStudioPublicAPI'
import appStudioWidgetsPublicAPI from './definitions/appStudioWidgetsPublicAPI'
import atomicScopesPublicAPI from './definitions/atomicScopesPublicAPI'
import autosavePublicAPI from './definitions/autosavePublicAPI'
import biPublicAPI from './definitions/biPublicAPI'
import browserThemeColorPublicAPI from './definitions/browserThemeColorPublicAPI'
import componentDetectorPublicAPI from './definitions/componentDetectorPublicAPI'
import componentPublicAPI from './definitions/componentPublicAPI'
import componentsLoaderPublicAPI from './definitions/componentsLoaderPublicAPI'
import componentsMetaDataPublicAPI from './definitions/componentsMetaDataPublicAPI'
import componentStylePublicAPI from './definitions/componentStylePublicAPI'
import connectionsPublicAPI from './definitions/connectionsPublicAPI'
import dataModelPublicAPI from './definitions/dataModelPublicAPI'
import debugPublicAPI from './definitions/debugPublicAPI'
import deprecationPublicAPI from './definitions/deprecationPublicAPI'
import documentModePublicAPI from './definitions/documentModePublicAPI'
import environmentPublicAPI from './definitions/environmentPublicAPI'
import errorsPublicAPI from './definitions/errorsPublicAPI'
import featuresPublicAPI from './definitions/featuresPublicAPI'
import slotsPublicAPI from './definitions/slotsPublicAPI'
import feedbackPublicAPI from './definitions/feedbackPublicAPI'
import fontsPublicAPI from './definitions/fontsPublicAPI'
import inlinePopupPublicAPI from './definitions/inlinePopupPublicAPI'
import layoutersPublicAPI from './definitions/layoutersPublicAPI'
import mediaPublicAPI from './definitions/mediaPublicAPI'
import menuPublicAPI from './definitions/menuPublicAPI'
import mobileMigrationAPI from './definitions/mobileMigrationAPI'
import mobilePublicAPI from './definitions/mobilePublicAPI'
import multilingualPublicAPI from './definitions/multilingualPublicAPI'
import pagePublicAPI from './definitions/pagePublicAPI'
import pagesGroupPublicAPI from './definitions/pagesGroupPublicAPI'
import partnersPublicAPI from './definitions/partnersPublicAPI'
import platformPublicAPI from './definitions/platformPublicAPI'
import reactionsPublicAPI from './definitions/reactionsPublicAPI'
import refComponentPublicAPI from './definitions/refComponentPublicAPI'
import renderPluginsPublicApi from './definitions/renderPluginsPublicApi'
import routersPublicAPI from './definitions/routersPublicAPI'
import savePublicAPI from './definitions/savePublicAPI'
import siteMembersPublicAPI from './definitions/siteMembersPublicAPI'
import siteMetadataPublicAPI from './definitions/siteMetadataPublicAPI'
import siteSegmentsPublicAPI from './definitions/siteSegmentsPublicAPI'
import smartBoxesPublicAPI from './definitions/smartBoxesPublicAPI'
import snapshotPublicAPI from './definitions/snapshotPublicAPI'
import statesPublicAPI from './definitions/statesPublicAPI'
import structurePublicAPI from './definitions/structurePublicAPI'
import stylableEditorPublicAPI from './definitions/stylableEditorPublicAPI'
import themePublicAPI from './definitions/themePublicAPI'
import tpaPublicAPI from './definitions/tpaPublicAPI'
import transformationsPublicAPI from './definitions/transformationsPublicAPI'
import transitionsPublicAPI from './definitions/transitionsPublicAPI'
import triggersPublicAPI from './definitions/triggersPublicAPI'
import wix2xPublicAPI from './definitions/wix2xPublicAPI'
import wixappsPublicAPI from './definitions/wixappsPublicAPI'
import wixCodePublicAPI from './definitions/wixCodePublicAPI'
import variablesPublicAPI from './definitions/variablesPublicAPI'

export const fullAPI = {
    accountPublicAPI,
    accessibilityPublicAPI,
    actionsAndBehaviorsPublicAPI,
    appControllerDataPublicAPI,
    appStudioPublicAPI,
    appStudioWidgetsPublicAPI,
    autosavePublicAPI,
    biPublicAPI,
    environmentPublicAPI,
    breakpointsPublicAPI,
    browserThemeColorPublicAPI,
    componentDetectorPublicAPI,
    componentPublicAPI,
    refComponentPublicAPI,
    componentStylePublicAPI,
    componentsLoaderPublicAPI,
    componentsMetaDataPublicAPI,
    transformationsPublicAPI,
    transitionsPublicAPI,
    connectionsPublicAPI,
    dataModelPublicAPI,
    featuresPublicAPI,
    statesPublicAPI,
    triggersPublicAPI,
    reactionsPublicAPI,
    debugPublicAPI,
    deprecationPublicAPI,
    documentModePublicAPI,
    errorsPublicAPI,
    feedbackPublicAPI,
    fontsPublicAPI,
    slotsPublicAPI,
    atomicScopesPublicAPI,
    inlinePopupPublicAPI,
    layoutersPublicAPI,
    mediaPublicAPI,
    menuPublicAPI,
    mobilePublicAPI,
    pagePublicAPI,
    pagesGroupPublicAPI,
    platformPublicAPI,
    renderPluginsPublicApi,
    customElementsPublicAPI,
    responsivePublicAPI,
    responsiveStylesPublicAPI,
    variantsPublicAPI,
    scrollAnchorsPublicAPI,
    classicScrollAnchorsPublicAPI,
    routersPublicAPI,
    savePublicAPI,
    snapshotPublicAPI,
    siteMembersPublicAPI,
    siteMetadataPublicAPI,
    siteSegmentsPublicAPI,
    smartBoxesPublicAPI,
    structurePublicAPI,
    stylableEditorPublicAPI,
    themePublicAPI,
    tpaPublicAPI,
    wixappsPublicAPI,
    wixCodePublicAPI,
    multilingualPublicAPI,
    wix2xPublicAPI,
    partnersPublicAPI,
    mobileMigrationAPI,
    variablesPublicAPI
}
