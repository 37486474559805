import * as _ from 'lodash'
import {ConversionData, ComponentWithConversionData, MasterPageComponentWithConversionData, ObjMap} from '../../types'
import * as conversionUtils from '../conversionUtils'
import {createRescaleGroup} from '../virtualGroupHandler'
import {getSign, getOrderedComponents, sortComponentsByNaturalOrder} from './analyzerUtils'

const OVERLAY_MIN_WIDTH = 500

export interface OverlapGroup {
    rescaleMethod: 'proportional' | 'break' | 'h2v' | 'tight'
    overlapRatio: number
    layout: {x: number; y: number; width: number; height: number}
    conversionData: ConversionData
    components: ComponentWithConversionData[]
}

export interface OverlayGroup {
    rescaleMethod: 'reorder' | 'break'
    components: ComponentWithConversionData[]
}

// tslint:disable-next-line:no-shadowed-variable
function pickOverlapGroupRescaleMethod(cat1: string, cat2: string, ratio: number, width: number): string {
    if (ratio < 0.01) {
        return 'break'
    }

    if (ratio < 0.1) {
        return 'tight'
    }

    const textText = () => (ratio > 0.5 && width < 500 ? 'proportional' : 'break')
    const photoBg = () => (width > 500 ? 'h2v' : 'proportional')
    const photoPhoto = () => (ratio > 0.5 && width < 600 ? 'proportional' : 'tight')
    const multipleGroups = () => (width < 500 ? 'proportional' : 'tight')
    const simple = () => 'h2v'

    const byCategory = {
        photo: {text: photoBg, graphic: photoBg, photo: photoPhoto},
        graphic: {text: photoBg, graphic: photoBg, photo: photoBg},
        text: {text: textText},
        columns: {text: simple, photo: simple, graphic: simple},
        group: {
            text: multipleGroups,
            photo: multipleGroups,
            graphic: multipleGroups
        }
    }

    return _.get(byCategory, [cat1, cat2], () => 'break')()
}

const left = a => a.layout.x
const top = a => a.layout.y
const width = a => a.layout.width
const height = a => a.layout.height
const right = a => left(a) + width(a)
const bottom = a => top(a) + height(a)
const surface = c => width(c) * height(c)
const trbl = (t, r, b, l) => ({
    layout: {x: l, y: t, width: Math.max(0, r - l), height: Math.max(0, b - t)}
})
const intersection = (a, b) => trbl(Math.max(top(a), top(b)), Math.min(right(a), right(b)), Math.min(bottom(a), bottom(b)), Math.max(left(a), left(b)))
const union = (a, b) => trbl(Math.min(top(a), top(b)), Math.max(right(a), right(b)), Math.max(bottom(a), bottom(b)), Math.min(left(a), left(b)))

function createSingleComponentOverlapGroup(a: ComponentWithConversionData): OverlapGroup {
    return <OverlapGroup>{
        rescaleMethod: 'break',
        overlapRatio: 0,
        conversionData: _.pick(a.conversionData, 'category'),
        layout: a.layout,
        components: [a]
    }
}

function analyzeSingleOverlap(a: OverlapGroup, b: OverlapGroup): {rescaleMethod: string; overlapRatio: number} {
    const overlapRatio = surface(intersection(a, b)) / surface(b)
    return {
        rescaleMethod: pickOverlapGroupRescaleMethod(a.conversionData.category, b.conversionData.category, overlapRatio, width(union(a, b))),
        overlapRatio
    }
}

function getIndicesOfOverlapGroupsToMerge(
    overlapsConversionData: Array<{
        rescaleMethod: string
        overlapRatio: number
    }>
): number[] {
    const targetOverlapGroupsIndices = _(overlapsConversionData)
        .map((overlapConversionData, overlapConversionDataIndex) => (overlapConversionData.rescaleMethod !== 'break' ? overlapConversionDataIndex : null))
        .filter(_.isNumber.bind(_))
        .value()
    if (_.size(targetOverlapGroupsIndices) <= 1) {
        return targetOverlapGroupsIndices
    }
    const isMultipleGroupsMerge = _.every(targetOverlapGroupsIndices, index => overlapsConversionData[index].rescaleMethod === 'proportional')
    if (isMultipleGroupsMerge) {
        return targetOverlapGroupsIndices
    }
    return [_.maxBy(targetOverlapGroupsIndices, index => overlapsConversionData[index].overlapRatio)]
}

function mergeGroup(groups: OverlapGroup[], nextGroup: OverlapGroup): OverlapGroup[] {
    if (_.size(nextGroup.components) === 1 && _.get(nextGroup.components[0], ['conversionData', 'isInvisible'], false)) {
        return groups
    }

    const overlapsConversionData = _.map(groups, g => analyzeSingleOverlap(g, nextGroup))
    const targetOverlapGroupsIndices = getIndicesOfOverlapGroupsToMerge(overlapsConversionData)
    if (_.isEmpty(targetOverlapGroupsIndices)) {
        return [...groups, nextGroup]
    }
    const newGroup = _.reduce(
        targetOverlapGroupsIndices,
        (resGroup, targetOverlapGroupIndex) => {
            const groupToMerge = groups[targetOverlapGroupIndex]
            const group = <OverlapGroup>_.assign(
                {
                    components: [...groupToMerge.components, ...resGroup.components],
                    layout: union(resGroup, groupToMerge).layout,
                    conversionData: {
                        category: 'group',
                        isTightContainer: true,
                        marginX: 0
                    }
                },
                overlapsConversionData[targetOverlapGroupIndex]
            )
            return assignLayoutRules(group)
        },
        nextGroup
    )
    _.remove(groups, (_group, groupIndex) => _.includes(targetOverlapGroupsIndices, groupIndex))
    groups.push(newGroup)
    return _.reduce(groups, mergeGroup, [])
}

function assignLayoutRules(group: OverlapGroup): OverlapGroup {
    if (!_.includes(['h2v', 'tight'], group.rescaleMethod)) {
        return group
    }
    const yOffsetRatio = group.rescaleMethod === 'tight' ? 0 : group.overlapRatio

    _.forEach(group.components, (comp, compIndex) => {
        if (!compIndex) {
            return
        }
        _.assign(comp.conversionData, {
            tightWithPreviousSibling: true,
            yOffsetRatio
        })

        if (conversionUtils.isTextComponent(comp)) {
            comp.conversionData.yOffset = comp.conversionData.mobileScale * comp.conversionData.mobileAdjustedAverageFontSize * 0.5
        }
    })
    return group
}

const getOverlayGroups = (comps: ComponentWithConversionData[]): ComponentWithConversionData[][] => <ComponentWithConversionData[][]>_(comps)
        .groupBy('conversionData.overlayGroupId')
        .filter((groupComps, groupId) => groupId && _.size(groupComps) > 1)
        .value()

const toOverlapGroups = (siblings: ComponentWithConversionData[]): OverlapGroup[] => _(siblings).map(createSingleComponentOverlapGroup).reduce(mergeGroup, [])

function getCoverIndex(siblings: ComponentWithConversionData[]): number {
    const boundingRect = conversionUtils.getSnugLayout(siblings)
    return boundingRect.width >= OVERLAY_MIN_WIDTH ? _.findIndex(siblings, comp => _.isEqual(conversionUtils.getSnugLayout([comp]), boundingRect)) : -1
}

function toOverlayGroupsByCoverIndex(siblings: ComponentWithConversionData[], coverIndex): OverlayGroup[] {
    return _.reduce(
        siblings,
        (res, comp, compIndex) => {
            const targetGroup = getSign(compIndex - coverIndex) + (coverIndex ? 1 : 0)
            if (!res[targetGroup]) {
                return <OverlayGroup[]>_.set(res, targetGroup, {
                    rescaleMethod: 'break',
                    components: [comp]
                })
            }
            return <OverlayGroup[]>_.set(res, targetGroup, {
                components: [...res[targetGroup].components, comp],
                rescaleMethod: 'reorder'
            })
        },
        <OverlayGroup[]>[]
    )
}

function toOverlayGroups(siblings: ComponentWithConversionData[]): OverlayGroup[] {
    const coverIndex = getCoverIndex(siblings)
    return coverIndex === -1 ? [{rescaleMethod: 'break', components: siblings}] : toOverlayGroupsByCoverIndex(siblings, coverIndex)
}

function createGroups(
    parent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    groups: OverlapGroup[] | OverlayGroup[]
): ObjMap<ComponentWithConversionData> {
    const compsToGroupMap = {}
    _.forEach(groups, (group: OverlapGroup | OverlayGroup) => {
        const groupParent = createRescaleGroup(parent, group.components, {
            rescaleMethod: group.rescaleMethod,
            category: 'boxContainer'
        })
        const groupMap = _(group.components)
            .map('id')
            .reduce((res, compId) => _.set(res, compId, groupParent), {})
        _.assign(compsToGroupMap, groupMap)
    })
    return compsToGroupMap
}

function addGroups(parent: ComponentWithConversionData | MasterPageComponentWithConversionData, groups: OverlapGroup[] | OverlayGroup[]): void {
    const compsToGroupsMap = createGroups(parent, groups)
    updateComponentsOrder(parent, compsToGroupsMap)
}

function updateComponentsOrder(
    parent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    groupsCompsMap: ObjMap<ComponentWithConversionData>
): void {
    parent.conversionData.componentsOrder = _.reduce(
        parent.conversionData.componentsOrder,
        (newOrder, id) => {
            const target = _.get(groupsCompsMap, [id, 'id'], id)
            return !_.includes(newOrder, target) ? [...newOrder, target] : newOrder
        },
        []
    )
}

function processOverlays(parent: ComponentWithConversionData | MasterPageComponentWithConversionData, orderedChildren: ComponentWithConversionData[]): void {
    const overlayGroups = <OverlayGroup[]>_(getOverlayGroups(orderedChildren)).flatMap(toOverlayGroups).filter({rescaleMethod: 'reorder'}).value()
    addGroups(parent, overlayGroups)
}

function processOverlaps(parent: ComponentWithConversionData | MasterPageComponentWithConversionData, orderedChildren: ComponentWithConversionData[]): void {
    const overlapGroups = <OverlapGroup[]>_(getOverlayGroups(orderedChildren))
        .map(group => sortComponentsByNaturalOrder(parent, group))
        .flatMap(toOverlapGroups)
        .reject({rescaleMethod: 'break'})
        .value()
    addGroups(parent, overlapGroups)
}

export function execute(parent: ComponentWithConversionData | MasterPageComponentWithConversionData): void {
    processOverlays(parent, getOrderedComponents(parent))
    processOverlaps(parent, getOrderedComponents(parent))
}

export const testAPI = {
    analyzeSingleOverlap,
    toOverlapGroups,
    processOverlaps,
    toOverlayGroupsByCoverIndex,
    processOverlays,
    updateComponentsOrder,
    getCoverIndex,
    createGroups
}
