import layout from '../util/layout'

const patchFitToContentCompHeight = (id, patchers) => {
    patchers.css(id, {
        height: ''
    })
}

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.tpapps.TPAWidget')
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.tpapps.TPAGluedWidget')
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.tpapps.TPASection')
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.tpapps.TPAMultiSection')

layout.registerPatcher('wysiwyg.viewer.components.tpapps.TPAWidgetNative', patchFitToContentCompHeight)
layout.registerPureDomHeightMeasure('wysiwyg.viewer.components.tpapps.TPAWidgetNative')
