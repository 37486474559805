import _ from 'lodash'
import namespaceConfigs from '../../dist/namespaceConfigs.json'
import {whitelist} from './whitelist'

const unsetWithArraysInternal = (obj: any[], path: string[]) => {
    const pathSegment = path.shift()
    if (path.length === 0) {
        _.forEach(obj, x => _.unset(x, pathSegment!))
    } else if (pathSegment === '') {
        unsetWithArraysInternal(_(obj).filter(_.isArray).flatten().value(), path)
    } else {
        unsetWithArraysInternal(
            _.flatMap(obj, x => _.get(x, pathSegment!)),
            path
        )
    }
}

export const unsetWithArrays = (obj: any, path: string) => {
    const pp = path.split(/\.?\[]\.?/) // split on [] with/wo surrounding .
    unsetWithArraysInternal([obj], pp)
    return obj
}

const namespacesMaps = _.mapValues(namespaceConfigs, 'mapName')
_.forEach(namespaceConfigs, namespaceProps => {
    if ('aliases' in namespaceProps) {
        _.forEach(namespaceProps.aliases, alias => {
            namespacesMaps[alias] = namespaceProps.mapName
        })
    }
    if ('overrideNamespace' in namespaceProps) {
        namespacesMaps[namespaceProps.overrideNamespace] = namespaceProps.mapName
    }
})

export const removeWhitelistedProperties = (namespace: string, dataTypeName: string, data: any, conservativeRemoval: boolean) => {
    const mapName = namespacesMaps[namespace]
    if (!mapName) {
        return
    }

    const selectedWhitelist = conservativeRemoval ? whitelist.conservative : whitelist.radical
    const fixedWhiteListElement = selectedWhitelist[mapName]
    const removeProps = fixedWhiteListElement?.[dataTypeName]
    _.forEach(removeProps, prop => unsetWithArrays(data, prop))
}
