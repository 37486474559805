define(['@wix/santa-ds-libs/src/coreUtils', 'documentServices/namespaces/namespaces', 'documentServices/constants/constants'], function (
    coreUtils,
    namespaces,
    constants
) {
    'use strict'

    const initAnchorsAPI = () => {}

    const update = (ps, compPointer, anchorData) => {
        if (anchorData) {
            anchorData.type = 'AnchorInfo'
        }

        return namespaces.updateNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors, anchorData)
    }

    const remove = (ps, compPointer) => {
        namespaces.removeComponentNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)
    }

    const get = (ps, compPointer) => namespaces.getNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)

    const getPageAnchorsByPageRef = (ps, pageRef, pageTopLabel) => {
        const pagePointer = ps.pointers.components.getPage(pageRef.id, pageRef.type)
        const pageComponentPointers = ps.pointers.components.getChildrenRecursively(pagePointer)
        const anchorDataItems = pageComponentPointers
            .map(compPointer => {
                const anchorDataItem = namespaces.getNamespaceData(ps, compPointer, constants.DATA_TYPES.anchors)

                if (anchorDataItem) {
                    anchorDataItem.compId = compPointer.id
                }

                return anchorDataItem
            })
            .filter(Boolean)
        const pageTopAnchor = coreUtils.scrollAnchors.getPageTopAnchor(pageRef.id, pageTopLabel)
        return [pageTopAnchor].concat(anchorDataItems)
    }

    const getPageAnchors = (ps, pageId, pageTopLabel) => {
        const pageRef = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        return getPageAnchorsByPageRef(ps, pageRef, pageTopLabel)
    }

    return {
        init: initAnchorsAPI,
        getPageAnchorsByPageRef,
        getPageAnchors,
        update,
        remove,
        get
    }
})
