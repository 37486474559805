define(['core', 'documentServices/routers/utils/constants'], function (core, constants) {
    'use strict'

    const type = 'routers'

    const {pointerGeneratorsRegistry} = core
    pointerGeneratorsRegistry.registerPointerType(
        type,
        function () {
            return null
        },
        function () {
            return true
        }
    )

    const getterFunctions = {
        getRoutersPointer(getItemAt, cache) {
            return cache.getPointer('routers', type, constants.paths.ROUTERS_PATH)
        },
        getRoutersConfigMapPointer(getItemAt, cache) {
            return cache.getPointer('routers_config_map', type, constants.paths.ROUTERS_CONFIG_MAP)
        },
        getRouterPointer(getItemAt, cache, routerId) {
            return cache.getPointer(routerId, type, constants.paths.ROUTERS_CONFIG_MAP.concat(routerId))
        }
    }

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions)
})
