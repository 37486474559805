import _ from 'lodash'

const events = 'continuousSaveEvents'
const browserRendererModel = 'browserRendererModel'
const csave = 'continuousSave'
const clientCsaveExtensions: string[] = [browserRendererModel, csave, events, 'revisionManager']
const clientCsaveExtensionsWithoutBrowserRendererModel: string[] = _.without(clientCsaveExtensions, browserRendererModel)
const serverCsaveExtensions: string[] = [csave, 'continuousSaveForServer']

const allCsaveExtensions: string[] = _.uniq(_.concat(clientCsaveExtensions, serverCsaveExtensions))

const withoutCsaveExtensions = (extensionList: string[]): string[] => _.without(extensionList, ...allCsaveExtensions)

export {clientCsaveExtensions, serverCsaveExtensions, clientCsaveExtensionsWithoutBrowserRendererModel, withoutCsaveExtensions}
