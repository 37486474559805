import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const mobileActions = viewerLibrary.get('documentServices/mobileConversion/mobileActions')
    return {
        methods: {
            mobile: {
                migration: {
                    clearMobileReferences: publicMethodUtils.defineDataManipulationAction(mobileActions.clearMobileReferences)
                }
            }
        }
    }
}
