const pointerType = 'svgShapes'
import {getPointer} from './pointerUtils'

const createPointersMethods = () => ({
    svgShapes: {
        getSvgShapePointer: (id: string) => getPointer(id, pointerType)
    }
})

export {createPointersMethods}
