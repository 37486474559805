import _ from 'lodash'

const rePercent = /^[0-9]+%$/
function isPercentValue(measurement) {
    return rePercent.test(measurement)
}

function isValidValue(measurement) {
    return (_.isNumber(measurement) && measurement >= 0) || isPercentValue(measurement)
}

/**
 * @class tpa.mixins.tpaResizeWindowMixin
 */
export default {
    resizeWindow(width, height, callback) {
        let update = false
        const newState: any = {}

        if (isValidValue(height) && height !== this.state.height) {
            newState.height = height
            update = true
        }

        if (isValidValue(width) && width !== this.state.width) {
            newState.width = width
            update = true
        }

        if (update) {
            this.registerReLayout()
            this.setState(newState, callback)
        }
    }
}
