define(['lodash'], function (_) {
    'use strict'

    const experiments = {
        isEcomPagesMissingExperimentOn: _.noop
    }

    const setExperiments = function (_experiments) {
        _.merge(experiments, _experiments)
    }

    const getExperiments = function () {
        return experiments
    }

    return {
        setExperiments,
        getExperiments
    }
})
