import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {urlUtils} = coreUtils
const ajaxUtils = coreUtils.ajaxLibrary

const API_HUB_PATH = '/_api/app-integration-bus-web/v1/activities'

function reportActivity(baseUrl, activity, onSuccess, onError) {
    const query = `?${urlUtils.toQueryString(activity.getParams())}`
    const url = baseUrl + API_HUB_PATH + query

    ajaxUtils.ajax({
        type: 'POST',
        url,
        data: activity.getPayload(),
        dataType: 'json',
        contentType: 'application/json',
        success: onSuccess,
        error: onError
    })
}

export default {
    reportActivity
}
