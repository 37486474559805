import * as _ from 'lodash'
import {Component, ComponentWithConversionData, ConversionSettings, MasterPageComponentWithConversionData} from '../types'
import * as conversionUtils from './conversionUtils'
import {isMobileOnlyComponent} from './mobileOnlyComponents/mobileOnlyComponentsUtils'

function convertFixedPositionCompsToAbsolute(allComps) {
    _.forEach(allComps, comp => {
        if (comp.layout && !conversionUtils.isFixedPositionAllowed(comp)) {
            comp.layout.fixedPosition = false
        }
    })
}

export function preProcessStructureMinimal(page, allComponents) {
    convertFixedPositionCompsToAbsolute(allComponents)
    const keepNotRecommendedMobileComponents = true
    removeNonMobileComponentsFrom(page, conversionUtils.getChildren(page), keepNotRecommendedMobileComponents)
    if (conversionUtils.isMasterPage(page)) {
        handleMasterPageComponents(conversionUtils.getChildren(page))
    }
    return page
}

/**
 * Prepares structure for the mobile conversion
 *
 * @param {Object} page - page structure
 * @param {Array} comps - components list
 *
 */
export function preProcessStructure(page, comps: ComponentWithConversionData[], settings: ConversionSettings) {
    const allComps = conversionUtils.getAllCompsInStructure(page)

    if (settings.skipLayoutOverwrite) {
        return
    }

    if (!settings.keepEmptyTextComponents) {
        clearFromEmptyText(comps)
    }

    removeOccludedBackgroundStrips(comps)

    convertFixedPositionCompsToAbsolute(allComps)
    _.forEach(comps, packTextWidth)
    removeHiddenComponentsFrom(page, comps)
    removeNonMobileComponentsFrom(page, comps, settings.keepNotRecommendedMobileComponents)

    if (!settings.keepOutOfScreenComponents) {
        removeOutOfScreenComponentsFrom(page, comps, 0, 0)
    }

    nestOverlayingComponents(comps, settings)

    if (!settings.keepOccludedAndEmptyBackgrounds) {
        removeEmptyBackgroundContainers(comps)
    }

    if (conversionUtils.isMasterPage(page)) {
        handleMasterPageComponents(comps)
    }

    if (!settings.keepInvisibleComponents) {
        removeInvisibleComponents(comps)
    }

    return page
}

/**
 * Moves all other components in the master page to header footer or pages container
 *
 * @private
 *
 * @param {Array} comps - master page components list
 */
export function handleMasterPageComponents(comps) {
    const header = <Component>_.find(comps, {id: 'SITE_HEADER'})
    const footer = <Component>_.find(comps, {id: 'SITE_FOOTER'})
    _(comps)
        .remove(_.negate(conversionUtils.isAllowedToBeInMasterPage))
        .forEach((comp: Component) => {
            const container = getClosestComp(comp, [header, footer])
            conversionUtils.reparentComponent(container, comp)
        })
}

const buildComponentsMap = (components, initialCompsMap) => {
    return _.reduce(
        components,
        (aggr, comp) => {
            aggr[comp.id] = comp
            if (comp.components) {
                buildComponentsMap(comp.components, aggr)
            }
            return aggr
        },
        initialCompsMap
    )
}
export function handleMobileMasterPageIlligalSiblings(desktopComps, mobileComps) {
    const desktopCompsMap = buildComponentsMap(desktopComps, {})
    const isRegularComponent = comp =>
        !isMobileOnlyComponent(comp) &&
        desktopCompsMap[comp.id] &&
        !conversionUtils.isAllowedToBeInMasterPage(<ComponentWithConversionData>desktopCompsMap[comp.id])
    const illigalSiblings = <any>_.remove(mobileComps, isRegularComponent) //todo fix

    // add layout data & property query on conversion data of desktopComp, the algorithm will use it on re-add component into the mobile structure
    _.forEach(illigalSiblings, mobileComp => {
        const conversionData = _.get(desktopCompsMap, [mobileComp.id, 'conversionData'])
        _.set(conversionData, 'fixedSize', {
            width: mobileComp.layout.width,
            height: mobileComp.layout.height
        })
        _.set(conversionData, ['mobileHints', 'propertyQuery'], mobileComp.propertyQuery)
    })
}
/**
 * Removes empty text components from the passed components structure
 *
 * @private
 *
 * @param {Array} comps - the components list
 */
function clearFromEmptyText(comps: ComponentWithConversionData[]) {
    let i
    let child

    if (comps) {
        for (i = comps.length - 1; i >= 0; i--) {
            child = comps[i]

            if (conversionUtils.isTextComponent(child)) {
                if (_.get(child.conversionData, 'textLength', 0) === 0) {
                    comps.splice(i, 1)
                }
            } else {
                clearFromEmptyText(child.components)
            }
        }
    }
}

function packTextWidth(component: ComponentWithConversionData) {
    _.forEach(component.components, packTextWidth)
    const actualTextWidth: number = _.get(component, ['conversionData', 'actualTextWidth'])
    if (_.isUndefined(actualTextWidth) || actualTextWidth >= component.layout.width) {
        return
    }

    if (actualTextWidth === 0) {
        component.layout.width = 0
        return
    }

    const alignments = _.get(component, ['conversionData', 'textAlignments'], ['left'])
    if (_.size(alignments) !== 1) {
        return
    }

    switch (_.head(alignments)) {
        case 'center':
            component.layout.x += (component.layout.width - actualTextWidth) / 2
            break
        case 'right':
            component.layout.x += component.layout.width - actualTextWidth
            break
    }
    component.layout.width = actualTextWidth
}

/**
 * Removes explicitly hidden components
 *
 * @private
 *
 * @param {Object} parent - parent component's parent
 * @param {Array} comps - children components list
 */
function removeHiddenComponentsFrom(parent: ComponentWithConversionData | MasterPageComponentWithConversionData, comps: ComponentWithConversionData[]): void {
    let i = 0
    while (i < _.size(comps)) {
        const comp = comps[i]
        const children = <ComponentWithConversionData[]>comp.components
        if (!_.get(comp, ['conversionData', 'mobileHints', 'hidden'])) {
            if (conversionUtils.isColumnsContainerComponent(comp)) {
                _(children)
                    .filter('conversionData.mobileHints.hidden')
                    .forEach(column => conversionUtils.translateComps(column.components, comp.layout.x, comp.layout.y))
                removeHiddenComponentsFrom(parent, children)
            } else {
                removeHiddenComponentsFrom(comp, children)
            }
            i++
            continue
        }

        if (conversionUtils.isContainerComponent(comp)) {
            conversionUtils.translateComps(children, comp.layout.x, comp.layout.y)
            conversionUtils.addComponentsTo(parent, children)
            comp.components = []
        }
        comps.splice(i, 1)
    }
}

/**
 * Removes non mobile and not recommended components from the structure
 *
 * @private
 *
 * @param {Object} parent - parent component's parent
 * @param {Array} comps - children components list
 * @param {Object} keepNotRecommendedComps - should it keep not recommended for the mobile view components
 *
 * @returns {Object} parent - parent component's modified structure
 */
function removeNonMobileComponentsFrom(parent, comps, keepNotRecommendedComps: boolean) {
    if (conversionUtils.isMergeVirtualGroup(parent)) {
        return
    }
    _.remove(comps, (child: ComponentWithConversionData) => {
        const desktopOnly = _.get(child, ['conversionData', 'desktopOnly'])
        if (desktopOnly || (!keepNotRecommendedComps && _.get(child, ['conversionData', 'hideByDefault']))) {
            return true
        }
        removeNonMobileComponentsFrom(child, child.components, keepNotRecommendedComps)
    })
}

/**
 * Removes from the components structure components which are out of screen
 *
 * @private
 *
 * @param {Object} comp - component's structure
 * @param {Array} comps - children components list
 * @param {Number} x - horizontal coordinate
 * @param {Number} y - vertical coordinate
 *
 * @returns {Object} comp - component's structure
 */
function removeOutOfScreenComponentsFrom(comp: Component, comps: Component[], x, y) {
    let i = 0
    let child
    let childBottom
    let childRight
    let isOutOfScreen

    if (comps) {
        while (i < comps.length) {
            child = comps[i]
            childBottom = child.layout.y + child.layout.height
            childRight = child.layout.x + child.layout.width
            isOutOfScreen = childBottom + y < 0 || child.layout.x > 1500 || childRight + x < -500

            if (isOutOfScreen) {
                comps.splice(i, 1)
            } else {
                removeOutOfScreenComponentsFrom(child, child.components, x + child.layout.x, y + child.layout.y)
                i++
            }
        }
    }

    return comp
}

function removeEmptyBackgroundContainers(comps: ComponentWithConversionData[]) {
    const isHiddenableEmptyBackgroundCotainer = comp =>
        comp.conversionData.hideWhenEmptyBackgroundContainer && comp.conversionData.isSolidColorBackground && conversionUtils.isEmptyContainer(comp)
    _.remove(comps, (comp: ComponentWithConversionData) => {
        if (isHiddenableEmptyBackgroundCotainer(comp)) {
            return true
        }
        if (!conversionUtils.isColumnsContainerComponent(comp) || !_.every(comp.components, isHiddenableEmptyBackgroundCotainer)) {
            removeEmptyBackgroundContainers(<ComponentWithConversionData[]>comp.components)
        }
        return false
    })
}

function removeOccludedBackgroundStrips(comps: ComponentWithConversionData[]) {
    const isOccludedBySibling = (comp, siblings: ComponentWithConversionData[]) => {
        return _.find(
            siblings,
            sibling => conversionUtils.isScreenWidthComponent(sibling) && comp.layout.y === sibling.layout.y && comp.layout.height === sibling.layout.height
        )
    }
    _.remove(comps, (comp: ComponentWithConversionData, compIndex) => {
        if (
            conversionUtils.isScreenWidthComponent(comp) &&
            _.get(comp, ['conversionData', 'isSolidColorBackground']) &&
            isOccludedBySibling(comp, _.slice(comps, compIndex + 1))
        ) {
            return true
        }
        removeOccludedBackgroundStrips(<ComponentWithConversionData[]>comp.components)
        return false
    })
}

function canNestOverlayingSibling(comp, overlayingSibling) {
    return (
        overlayingSibling &&
        (_.get(overlayingSibling, ['conversionData', 'nestOverlayingSiblings'], true) ||
            conversionUtils.shouldReparentCompToChildOfContainer(comp, overlayingSibling)) &&
        !_.get(comp, ['conversionData', 'isNonContainableFullWidth'], false)
    )
}

function nestOverlayingComponents(comps: Component[], settings: ConversionSettings) {
    _.forEachRight(comps, (child, i) => {
        let overlayingSibling =
            getOverlayingSiblingOf(child, _.slice(comps, 0, i)) || getOverlayingSiblingOf(child, _.slice(comps, i), conversionUtils.isSemiTransparentContainer)

        if (!canNestOverlayingSibling(child, overlayingSibling)) {
            nestOverlayingComponents(child.components, settings)
            return
        }
        let yOffset = 0
        if (conversionUtils.isColumnsContainerComponent(overlayingSibling)) {
            yOffset = overlayingSibling.layout.y
            /** For case when we remove column overlaying by anchor
             * overlayingSibling.components would be empty so we should
             * keep prev overlaying sibling WEED-23282
             * */
            if (overlayingSibling.components.length > 0) {
                overlayingSibling = _.head(overlayingSibling.components)
            }
        }
        /**
         * HOT FIX for section migration
         */
        if (!settings.skipLayoutOverwrite) {
            conversionUtils.reparentComponent(overlayingSibling, child)
            child.layout.y -= yOffset
            comps.splice(i, 1)
        }
    })

    return comps
}

function removeInvisibleComponents(comps: ComponentWithConversionData[]): void {
    _.forEachRight(comps, (child, childIndex) => {
        const siblings = _.slice(comps, childIndex + 1)
        const skipSibling = comp =>
            _.get(comp, ['conversionData', 'isSemiTransparentContainer'], false) || _.get(comp, ['conversionData', 'structuralItem'], false)
        const hidingSibling = getOverlayingSiblingOf(child, siblings, _.negate(skipSibling), 1)
        if (hidingSibling) {
            _.set(child, ['conversionData', 'mobileHints', 'hidden'], true)
            comps.splice(childIndex, 1)
        } else {
            removeInvisibleComponents(<ComponentWithConversionData[]>child.components)
        }
    })
}

function getOverlayingSiblingOf(comp, siblings, siblingFilter?: (sibling: any) => boolean, overlappingAreaToMinAreaRatioThreshold = 0.75) {
    if (conversionUtils.isSiteSegmentOrPage(comp)) {
        return null
    }

    return _.find(siblings, (sibling: any) => {
        const notSameId = sibling.id !== comp.id
        const siblingNotPageContainer = sibling.id !== 'PAGES_CONTAINER'
        const filterResult = !siblingFilter || siblingFilter(sibling)
        const isConteinerOrPageComp = conversionUtils.isContainerComponent(sibling) || conversionUtils.isPageComponent(sibling)
        const haveSufficiantOverlap = conversionUtils.haveSufficientOverlap(sibling, comp, overlappingAreaToMinAreaRatioThreshold)
        const hasOverlapWithInvisible = comp.conversionData.isInvisible && conversionUtils.getYOverlap(sibling, comp) > 0
        const hasYOverlap = haveSufficiantOverlap || hasOverlapWithInvisible

        return notSameId && siblingNotPageContainer && filterResult && isConteinerOrPageComp && hasYOverlap && conversionUtils.hasGreaterArea(sibling, comp)
    })
}

function getClosestComp(comp: Component, comps: Component[]): Component | null {
    const getCenterY = c => c.layout.y + (c.layout.height || 0) / 2
    const compCenterY = getCenterY(comp)
    return _.size(comps)
        ? _.minBy(comps, c => {
              const distance = Math.abs(compCenterY - getCenterY(c))
              return _.isNaN(distance) ? 0 : distance
          })
        : null
}

export const testAPI = {
    removeHiddenComponentsFrom
}
