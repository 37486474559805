define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/saveAPI/monitoring',
    'documentServices/saveAPI/snapshotDalSaveUtils',
    'documentServices/utils/runtimeConfig'
], function (_, santaCoreUtils, extensionsAPI, monitoring, snapshotDalSaveUtils, runtimeConfig) {
    'use strict'

    let lastPromise = Promise.resolve()

    let saveCounter = 0

    const afterFunc = () => {
        saveCounter--
    }

    const runInQueue = func => {
        // When not during a save, run the first function/save synchronously
        // Otherwise, run it after the last promise in queue
        const funcPromise = saveCounter > 0 ? lastPromise.then(func) : func() // eslint-disable-line promise/prefer-await-to-then
        lastPromise = funcPromise.then(afterFunc, afterFunc)
        saveCounter++
        return funcPromise
    }

    class PrimaryTaskError extends Error {
        constructor(reason) {
            super('Document save has failed')
            this.name = 'PrimaryTaskError'
            this.reason = {document: reason}
        }
    }

    class SecondaryTasksError extends Error {
        constructor(errorMap) {
            super('One or more Secondary save tasks have failed')
            this.name = 'SecondarySaveError'
            this.reason = errorMap
        }
    }

    class RequiredTasksError extends Error {
        constructor(errorMap) {
            super('One or more required save tasks have failed')
            this.name = 'RequiredTaskSaveError'
            this.reason = errorMap
        }
    }

    function getFedopsInteractionName(methodName, taskName) {
        return taskName ? `SaveTasks_${methodName}_${taskName}` : `SaveTasks_${methodName}`
    }

    const executeTaskAsync = (task, biCallbacks, options, extraArgs) =>
        new Promise(function (resolve, reject) {
            const args = task.args().concat([resolve, reject, biCallbacks, options], extraArgs)
            task.execute.apply(null, args)
        })

    /**
     * @description executes the save task and returns a promise
     * @param task
     * @param {Object} biCallbacks
     * @param {Object} options
     * @returns {Promise} a Promise that the task will be executed. Rejects on save failure.
     */
    async function executeTask(task, biCallbacks, options) {
        const {name: taskName, methodName} = task
        const fedopsInteractionName = getFedopsInteractionName(methodName, taskName)

        try {
            const extraArgs = task.extraArgs ? task.extraArgs() : []
            monitoring.start(fedopsInteractionName)
            const result = await executeTaskAsync(task, biCallbacks, options, extraArgs)
            monitoring.end(fedopsInteractionName)
            if (task.onTaskSuccess) {
                return task.onTaskSuccess(result)
            }
            return result
        } catch (e) {
            monitoring.error(e, undefined, {
                saveTaskError: true,
                methodName,
                taskName,
                ds_csave: extensionsAPI.getAPI(task.ps).csave.isCSaveOpen()
            })
            task.onTaskFailure?.(e)
            throw e
        }
    }

    /**
     * @param {Object.<string, *>} tasks save tasks map
     * @param {Object} biCallbacks
     * @param {Object} options
     * @param {function} TasksError
     * @returns {Promise} a promise that all the tasks in the map will be executed. If the promise is rejected, it is rejected with a map of the rejection reasons of the task map.
     */
    async function executeTaskMap(tasks, biCallbacks, options, TasksError) {
        async function attempt(promise, name) {
            try {
                await promise
            } catch (e) {
                return {error: true, name, result: e}
            }
        }

        const promiseMap = _(tasks)
            .keyBy('name')
            .mapValues(task => attempt(executeTask(task, biCallbacks, options), task.name))
            .value()

        const resultsArr = await Promise.all(_.values(promiseMap))
        const result = _(resultsArr)
            .filter(finishedPromise => finishedPromise?.error)
            .keyBy('name')
            .mapValues('result')
            .value()

        if (!_.isEmpty(result)) {
            // @ts-ignore
            throw new TasksError(result)
        }
        return promiseMap
    }

    const onTaskSuccess = (task, DAL, result) => {
        if (task.definition.onTaskSuccess) {
            return task.definition.onTaskSuccess(result)
        }

        if (!result) {
            return result
        }

        if (!_.isEmpty(result.historyAlteringChanges)) {
            const shouldApplyChangesToDAL =
                runtimeConfig.isSanta(task.ps) && !task.useLastApprovedSnapshot && didDalChangedSinceTaskStarted.call(task, DAL, task.name, task.tags)

            applyChangesToSnapshot(DAL, result.historyAlteringChanges, task.name, task.tags)

            if (shouldApplyChangesToDAL) {
                result.changes.push(...result.historyAlteringChanges)
            }
        }

        result = _.omit(result, 'historyAlteringChanges')
        updateDALbyTaskResult(DAL, result.changes)
        return result.result
    }

    class BaseTask {
        /**
         * @param {ps} ps
         * @param taskDefinition
         * @param {string} methodName
         */
        constructor(ps, taskDefinition, methodName) {
            this.ps = ps
            this.DAL = ps.dal
            this.name = taskDefinition.getTaskName()
            this.methodName = methodName
            this.execute = taskDefinition[methodName]
            this.definition = taskDefinition
            this.tags = getSnapshotTags(taskDefinition, methodName)
            const api = extensionsAPI.getAPI(this.ps)
            const useLastOnCSave = api.csave.isCSaveOpen()
            const useLastOnCEdit = !taskDefinition.getCurrentState && api.csave.isCEditOpen()
            this.useLastApprovedSnapshot = useLastOnCSave || useLastOnCEdit
        }

        onTaskSuccess(result) {
            return onTaskSuccess(this, this.DAL, result)
        }
    }

    /**
     * Checks if the DAL has changed since the task started (by comparing tasks snapshot and a newly taken snapshot)
     * @param {*} DAL
     * @param {String} taskName
     * @param {Array} taskTags
     */
    function didDalChangedSinceTaskStarted(DAL, taskName, taskTags) {
        // @ts-ignore
        const {currentSavedSnapshotDal: snapshotDalWhenTaskStarted, currentState: snapshotWhenTaskStarted} = this
        if (snapshotWhenTaskStarted) {
            const snapshotTag = taskName + taskTags[0]
            DAL.takeSnapshot(snapshotTag)
            const snapshotWhenTaskEnded = DAL.full.immutable.getLastSnapshotByTagName(snapshotTag)
            DAL.removeLastSnapshot(snapshotTag)
            return snapshotWhenTaskStarted.equals(snapshotWhenTaskEnded)
        }
        const currentSnapshotDal = DAL.full.snapshot.getCurrentSnapshot()
        return currentSnapshotDal.equals(snapshotDalWhenTaskStarted)
    }

    /**
     * Updates the DAL and task snapshots with the deleted items from server
     * @param {DocumentServicesDal} DAL
     * @param {string[]} changes a collection of page ids, each containing a collection of data maps, each containing an array of deleted items ids
     * @param {String} taskName
     * @param {string[]} taskTags
     */
    function applyChangesToSnapshot(DAL, changes, taskName, taskTags) {
        _.forEach(taskTags, function (taskTag) {
            const snapshotTag = taskName + taskTag
            DAL.duplicateLastSnapshot(snapshotTag, changes)
        })
    }

    const getTaskTag = task => task.name + task.tags[0]
    const getLastState = (task, DAL) =>
        task.definition.getLastState
            ? task.definition.getLastState()
            : DAL.full.immutable.getLastSnapshotByTagName(getTaskTag(task)) || DAL.full.immutable.getInitialSnapshot() //VERY important to keep this first!
    const getCurrentState = (task, DAL) =>
        task.definition.getCurrentState ? task.definition.getCurrentState() : DAL.full.immutable.getLastSnapshotByTagName(getTaskTag(task))
    const getLastSnapshotDal = (task, DAL) => DAL.full.snapshot.getLastSnapshotByTagName(getTaskTag(task)) || DAL.full.snapshot.getInitialSnapshot()
    const getCurrentSnapshotDal = (task, DAL) => DAL.full.snapshot.getLastSnapshotByTagName(getTaskTag(task))

    const takeSnapshots = (task, DAL) => {
        if (task.definition.takeSnapshot) {
            task.definition.takeSnapshot()
        } else {
            _.forEach(task.tags, tag => {
                const tagName = task.name + tag
                if (task.useLastApprovedSnapshot) {
                    DAL.takeLastApprovedSnapshot(tagName)
                } else {
                    DAL.takeSnapshot(tagName)
                }
            })
        }
    }

    const rollback = (task, DAL, result) => {
        monitoring.start('saveRunnerRollback')
        if (task.definition.rollback) {
            task.definition.rollback(result)
        } else {
            _.forEach(task.tags, tag => {
                DAL.removeLastSnapshot(task.name + tag)
            })
            if (result && !_.isEmpty(result.changes)) {
                _.forEach(task.tags, tag => {
                    DAL.duplicateLastSnapshot(task.name + tag, result.changes)
                })
                updateDALbyTaskResult(DAL, result.changes)
            }
        }
        monitoring.end('saveRunnerRollback')
    }

    class TaskWithHistory extends BaseTask {
        constructor(ps, taskDefinition, methodName) {
            super(ps, taskDefinition, methodName)
            if (taskDefinition.getLastState) {
                this.lastSavedState = getLastState(this, this.DAL)
            } else {
                this.lastSavedSnapshotDal = getLastSnapshotDal(this, this.DAL)
            }

            takeSnapshots(this, this.DAL)

            if (taskDefinition.getCurrentState) {
                this.currentState = getCurrentState(this, this.DAL)
            } else {
                this.currentSavedSnapshotDal = getCurrentSnapshotDal(this, this.DAL)
                if (this.currentSavedSnapshotDal) {
                    // TODO maybe should be on last
                    this.currentSavedSnapshotDal.lastTransactionId = extensionsAPI.getAPI(ps).csave.getLastTransactionId()
                }
            }
        }

        args() {
            return [this.lastSavedState, this.currentState]
        }

        extraArgs() {
            return [this.lastSavedSnapshotDal, this.currentSavedSnapshotDal, extensionsAPI.getAPI(this.ps)]
        }

        rollBackSnapshot(result) {
            rollback(this, this.DAL, result)
        }

        onTaskFailure(result) {
            this.rollBackSnapshot(result)
        }

        cancel(result) {
            this.rollBackSnapshot(result)
        }
    }

    class AutosaveTask extends TaskWithHistory {
        onTaskSuccess(result) {
            if (result) {
                const {currentSavedSnapshotDal, currentState} = this

                const versionBeforeAutoSave = currentState
                    ? currentState.getIn(snapshotDalSaveUtils.santaVersionPath)
                    : currentSavedSnapshotDal.getValue(snapshotDalSaveUtils.versionPointer)

                const currentVersion = currentState
                    ? this.DAL.full.getByPath(snapshotDalSaveUtils.santaVersionPath)
                    : this.DAL.get(snapshotDalSaveUtils.versionPointer)

                if (versionBeforeAutoSave === currentVersion) {
                    // If there was a save (version updated) during the auto-save - don't update the previousDiffId (otherwise the next auto-save will fail)
                    updateDALbyTaskResult(this.DAL, result.changes)
                }
            }
        }
    }

    //see #SE-4087
    function updateSiteAndMetasiteIdsInSnapshot(DAL, snapshot) {
        const currentRendererModel = DAL.getByPath(['rendererModel'])
        return snapshot.mergeIn(['rendererModel'], {
            metaSiteId: currentRendererModel.metaSiteId,
            siteInfo: {
                siteId: currentRendererModel.siteInfo.siteId
            }
        })
    }

    const getCurrentStateForPublish = (task, DAL) => {
        const state = getLastState(task, DAL)
        if (task.definition.getLastState) {
            return state
        }
        return updateSiteAndMetasiteIdsInSnapshot(DAL, state)
    }

    const getCurrentSnapshotDalForPublish = (task, ps) => {
        if (task.definition.getLastState) {
            return null
        }
        const snapshotDal = getLastSnapshotDal(task, ps.dal)
        const metaSiteIdPointer = {type: 'rendererModel', id: 'metaSiteId'}
        const siteIdPointer = {type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'}
        const currentMetaSiteId = ps.dal.full.get(metaSiteIdPointer)
        const currentSiteId = ps.dal.full.get(siteIdPointer)
        return extensionsAPI.snapshots.createWithChanges(ps, snapshotDal, [
            {pointer: metaSiteIdPointer, value: currentMetaSiteId},
            {pointer: siteIdPointer, value: currentSiteId}
        ])
    }

    class PublishTask extends BaseTask {
        constructor(ps, taskDefinition, methodName) {
            super(ps, taskDefinition, methodName)
            this.currentState = getCurrentStateForPublish(this, this.DAL)
        }

        args() {
            return [this.currentState]
        }

        extraArgs() {
            return [getCurrentSnapshotDalForPublish(this, this.ps), extensionsAPI.getAPI(this.ps)]
        }
    }

    /**
     * Updates the DAL according to the result parameter.
     * @param DAL
     * @param {*} result Object of the changes needed in the DAL the key is the path and the value is the new value,
     *                                   undefined value will remove the path from DAL.
     */
    function updateDALbyTaskResult(DAL, result) {
        _.forEach(result, ({path, value}) => {
            if (_.isUndefined(value)) {
                DAL.full.removeByPathInHostModel(path)
                return
            }
            DAL.full.setByPath(path, value)
        })
    }

    function updateModelsForSecondaryTasks(ps, secondaryTasksMap) {
        const secondaryTasks = _.reject(secondaryTasksMap, task => task.definition.getCurrentState)
        updateModelsForSecondaryTasksWithSnapshotDal(ps, secondaryTasks)
    }

    function updateModelsForSecondaryTasksWithSnapshotDal(ps, secondaryTasksMap) {
        if (secondaryTasksMap.length) {
            const changes = _.concat(extensionsAPI.models.getDocumentServicesModel(ps), extensionsAPI.models.getRendererModel(ps))

            _.forOwn(secondaryTasksMap, function (task) {
                task.currentSavedSnapshotDal = extensionsAPI.snapshots.createWithChanges(ps, task.currentSavedSnapshotDal, changes)
            })
        }
    }

    /**
     *
     * @param {ps} ps
     * @param primaryTask
     * @param requiredTasksMap
     * @param secondaryTasksMap
     * @param onSuccess
     * @param onError
     * @param {Object} options
     * @param biCallbacks an object with "event" and "error" callback for sending bi events & errors
     * @param {string} methodName
     * @returns {Promise<*>}
     */
    function runTasks(ps, primaryTask, secondaryTasksMap, requiredTasksMap, onSuccess, onError, biCallbacks, options, methodName) {
        const interactionName = getFedopsInteractionName(methodName)
        monitoring.start(interactionName)
        const taskExecutionPromise = executeTaskMap(requiredTasksMap, biCallbacks, options, RequiredTasksError)
            .catch(e => {
                primaryTask.cancel()
                _.invokeMap(secondaryTasksMap, 'cancel')
                throw e
            })
            .then(() =>
                executeTask(primaryTask, biCallbacks, options)
                    .catch(function (reason) {
                        _.invokeMap(secondaryTasksMap, 'cancel')
                        throw new PrimaryTaskError(reason)
                    })
                    .then(taskResult => {
                        updateModelsForSecondaryTasks(ps, secondaryTasksMap)
                        return executeTaskMap(secondaryTasksMap, biCallbacks, options, SecondaryTasksError).then(() => taskResult)
                    })
            )

        taskExecutionPromise
            .then(function onAllTasksSuccess(result) {
                _.invoke(ps.dal, 'commitTransaction')
                _.invoke({onSuccess}, 'onSuccess', result)
                monitoring.end(interactionName)
            })
            .catch(function onTaskFailure(err) {
                santaCoreUtils.log.error('Save has failed - please see the failure details below:', err.reason)
                if (onError) {
                    const reason = err.reason || {documentServicesInternalError: err}
                    onError(reason)
                }
            })

        return taskExecutionPromise
    }

    function getSnapshotTags(taskDefinition, methodName) {
        const snapshotsTags = taskDefinition.getSnapshotTags(methodName)
        if (!snapshotsTags || !_.isArray(snapshotsTags) || _.isEmpty(snapshotsTags)) {
            return ['']
        }
        return snapshotsTags
    }

    /**
     * Actually runs the save tasks.
     * @param {string} methodName
     * @param {*} TaskCtor
     * @param {Object} tasksRegistry
     * @param {ps} ps
     * @param {function} onSuccess
     * @param {function} onError
     * @param {Object} options
     * @param {Object} biCallbacks an object with "event" and "error" callback for sending bi events & errors
     * @return {Promise} a promise that the save tasks will be executed. Will resolve when all have resolved.
     *         If the documentSave is rejected, the promise will be rejected immediately. If it succeeds and a secondary task rejects, then it will be rejected once all the tasks have been settled.
     */
    function buildAndRunTasks(methodName, TaskCtor, tasksRegistry, ps, onSuccess, onError, biCallbacks, options) {
        return runInQueue(() => {
            const documentSaveTask = new TaskCtor(ps, tasksRegistry.primaryTask, methodName)

            function buildTask(taskDefinition) {
                return new TaskCtor(ps, taskDefinition, methodName)
            }

            const requiredTasks = _.map(tasksRegistry.requiredTasks, buildTask)
            const secondaryTasks = _.map(tasksRegistry.secondaryTasks, buildTask)

            return runTasks(ps, documentSaveTask, secondaryTasks, requiredTasks, onSuccess, onError, biCallbacks, options, methodName)
        })
    }

    const shouldSaveBeforePublish = ({primaryTask}, ps) => {
        const methodName = 'partialSave'
        if (primaryTask.shouldRun) {
            const tag = primaryTask.getTaskName() + getSnapshotTags(primaryTask, methodName)[0]
            const lastSnapshotDal = ps.dal.full.snapshot.getLastSnapshotByTagName(tag) || ps.dal.full.snapshot.getInitialSnapshot()
            const currentSnapshotDal = ps.dal.full.snapshot.getCurrentSnapshot()
            return (
                primaryTask.shouldRun &&
                primaryTask.shouldRun(undefined, undefined, methodName, lastSnapshotDal, currentSnapshotDal, extensionsAPI.getAPI(ps), ps.pointers)
            )
        }
        return false
    }

    const buildAndRunTasksAsync = (methodName, TaskCtor, tasksRegistry, ps, biCallbacks, options) =>
        new Promise((resolve, reject) => {
            buildAndRunTasks(methodName, TaskCtor, tasksRegistry, ps, resolve, reject, biCallbacks, options)
        })

    const createTasks = func => ({
        runPartialSaveTasks: func.bind(null, 'partialSave', TaskWithHistory),
        runFullSaveTasks: func.bind(null, 'fullSave', TaskWithHistory),
        runFirstSaveTasks: func.bind(null, 'firstSave', TaskWithHistory),
        runSaveAsTemplate: func.bind(null, 'saveAsTemplate', TaskWithHistory),
        runPublishTasks: func.bind(null, 'publish', PublishTask),
        runAutosaveTasks: func.bind(null, 'autosave', AutosaveTask)
    })

    return {
        promises: createTasks(buildAndRunTasksAsync),
        ...createTasks(buildAndRunTasks),
        runFunctionInSaveQueue: runInQueue,
        shouldSaveBeforePublish
    }
})
