define(['lodash', 'experiment'], function (_) {
    'use strict'

    const isPageExist = (ps, pageId) =>
        ps.dal.full.isExist(ps.pointers.components.getPage(pageId, 'DESKTOP') || ps.dal.full.isExist(ps.pointers.components.getPage(pageId, 'MOBILE')))
    return {
        exec(ps) {
            const allPageDataItemsInMasterPage = ps.pointers.data.getDataItemsWithPredicate({type: 'Page'}, 'masterPage')
            const corruptedPagesDataItems = allPageDataItemsInMasterPage.filter(pageDataPointer => !isPageExist(ps, _.get(pageDataPointer, 'id')))
            _.forEach(corruptedPagesDataItems, badPageDataPointer => ps.dal.full.remove(badPageDataPointer))
        },
        name: 'badPagesDataItemsDataFixer',
        version: 1
    }
})
