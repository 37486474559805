define(['lodash'], function (_) {
    'use strict'

    function isCompResizing(oldLayout, newLayout) {
        return (_.isNumber(newLayout.width) && newLayout.width !== oldLayout.width) || (_.isNumber(newLayout.height) && newLayout.height !== oldLayout.height)
    }

    const isCompRescaling = (oldLayout, newLayout) => _.isNumber(newLayout.scale) && newLayout.scale !== oldLayout.scale

    const calculateDelta = (valueA, valueB) => (_.isUndefined(valueA) ? 0 : valueA - valueB)

    return {
        isCompRescaling,
        isCompResizing,
        calculateDelta
    }
})
