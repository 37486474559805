define([
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/page/popupUtils',
    'document-services-schemas'
], function (constants, metaDataUtils, popupUtils, documentServicesSchemas) {
    'use strict'

    const {schemasService} = documentServicesSchemas.services

    const isContainable = (ps, compPointer, containerPointer) => {
        const containerCompType = metaDataUtils.getComponentType(ps, containerPointer)
        const isContainerPage = schemasService.isPage(containerCompType)
        return isContainerPage && !popupUtils.isPopup(ps, containerPointer.id)
    }

    return {
        alignable: false,

        container: true,
        containable: isContainable,
        containableByStructure: isContainable,

        canBeFixedPosition: false,

        showMarginsIndicator: true,

        fullWidth: true,
        fullWidthByStructure: true,

        isRepeatable: false,
        groupable: false,

        resizableSides: [constants.RESIZE_SIDES.BOTTOM],
        moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],

        layoutLimits: {
            minHeight: 40
        },

        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: true,

        mobileConversionConfig: {
            structuralItem: true,
            filterChildrenWhenHidden: true,
            stretchHorizontally: true,
            isTightContainer: true,
            minHeight: 200,
            category: 'sections',
            preserveAspectRatio: false,
            hideWhenEmptyBackgroundContainer: true,
            fixedSize(ps, comp, pageId) {
                return metaDataUtils.getMobileFixedSizesForColumn(ps, comp, pageId)
            }
        },
        alwaysContainRecursively: true
    }
})
