define([
    'lodash',
    'documentServices/utils/utils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/platform/common/constants'
], function (_, dsUtils, componentDetectorAPI, constants) {
    'use strict'

    const sessionStateTypes = new Set(constants.SESSION_STATE_TYPES)

    function updateSessionState(ps, stateMap) {
        _.forEach(stateMap, (state, compId) => {
            const compRef = componentDetectorAPI.getComponentById(ps, compId)
            const compType = dsUtils.getComponentType(ps, compRef)
            if (!sessionStateTypes.has(compType)) {
                throw new Error(`Component of type ${compType} does not support session state`)
            }

            ps.dal.full.set(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compId]), state)
        })
    }

    function getSessionState(ps, compRef) {
        return ps.dal.full.get(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compRef.id]))
    }

    return {
        getSessionState,
        updateSessionState
    }
})
