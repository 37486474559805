define([
    'lodash',
    'documentServices/hooks/componentHooks/utils/platformAppComp',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel'
], function (_, platformAppComp, component, dataModel) {
    'use strict'

    const DIMENSIONS = ['width', 'height']

    function verifyAppWidgetStructure(serializedComp) {
        if (_.size(serializedComp.components) !== 1) {
            throw new Error('Invalid app widget structure - must have single child')
        }
    }

    function adjustLayout(serializedComp) {
        const isResponsiveLayout = serializedComp.layouts
        if (isResponsiveLayout) {
            return
        }
        const widgetRootStructure = serializedComp.components[0]
        _.merge(widgetRootStructure, {layout: {x: 0, y: 0}})
        _.forEach(DIMENSIONS, dim => {
            if (_.has(serializedComp, ['layout', dim])) {
                widgetRootStructure.layout[dim] = serializedComp.layout[dim]
            } else if (_.has(widgetRootStructure, ['layout', dim])) {
                _.set(serializedComp, ['layout', dim], widgetRootStructure.layout[dim])
            }
        })
    }

    function beforeAddHook(ps, compToAddPointer, containerPointer, serializedComp) {
        verifyAppWidgetStructure(serializedComp)
        adjustLayout(serializedComp)
    }

    function handleAppWidgetDeletion(ps, widgetRef) {
        platformAppComp.removePlatformApplicationFromPageIfLastController(ps, widgetRef)
        platformAppComp.removeControllerFromStateMap(ps, widgetRef)
    }

    function handleAppWidgetSerialization(ps, controllerRef, customStructureData) {
        platformAppComp.addControllerStateToSerializedStructure(ps, controllerRef, customStructureData)
    }

    function handleAppWidgetAddition(ps, widgetRef, widgetDefinition) {
        platformAppComp.setControllerStateFromSerializedStructure(ps, widgetRef, widgetDefinition)
        platformAppComp.addPlatformAppToPageIfFirstController(ps, widgetRef, widgetDefinition.data.applicationId)
    }

    function handleAppWidgetParentChange(ps, controllerRef, newParentPointer) {
        const {applicationId} = dataModel.getDataItem(ps, controllerRef)
        platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
        platformAppComp.addPlatformAppToPageIfFirstController(ps, controllerRef, applicationId, component.getPage(ps, newParentPointer))
    }

    return {
        beforeAddHook,
        handleAppWidgetAddition,
        handleAppWidgetDeletion,
        handleAppWidgetSerialization,
        handleAppWidgetParentChange
    }
})
