import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const states = viewerLibrary.get('documentServices/states/states')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')

    return {
        methods: {
            components: {
                states: {
                    add: publicMethodUtils.defineDataManipulationAction(states.add, {
                        isUpdatingAnchors: dsUtils.DONT_CARE,
                        getReturnValue: states.getStateVariantToAddRef
                    }),
                    update: publicMethodUtils.defineDataManipulationAction(states.update),
                    remove: publicMethodUtils.defineDataManipulationAction(states.remove),
                    get: publicMethodUtils.defineGetter(states.get),
                    getAll: publicMethodUtils.defineGetter(states.getAll)
                }
            }
        }
    }
}
