import _ from 'lodash'
import wixappsCore from '@wix/santa-ds-libs/src/wixappsCore'

const {wixappsDataHandler} = wixappsCore

function transformAndSetMetaData(transformFunc, siteData, packageName, compId, responseData, currentValue) {
    wixappsDataHandler.setCompFailedRequests(false, siteData, packageName, compId)
    transformFunc(responseData, currentValue, siteData)

    const updatedMetadata = {dataReady: true}
    const descriptor = wixappsDataHandler.getDescriptor(siteData, packageName)
    const metadata = wixappsDataHandler.getCompMetadata(siteData, packageName, compId)
    if (descriptor && (!_.has(metadata, 'videos') || metadata.videos === 0)) {
        wixappsDataHandler.clearCompMetadata(siteData, packageName, compId)
    } else {
        wixappsDataHandler.setCompMetadata(updatedMetadata, siteData, packageName, compId)
    }

    return currentValue
}

export default transformAndSetMetaData
