import _ from 'lodash'
import {
    CreateExtArgs,
    Extension,
    ExtensionAPI,
    DalValueChangeCallback,
    DAL,
    Pointer,
    DalValue,
    pointerUtils,
    CoreLogger,
    logDalValueChanges
} from '@wix/document-manager-core'
import type {FixerActions, FixerCategoryToFixerVersion} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel'
import {trimLongReports} from './fixerModsReporting'
import {DATA_TYPES, VIEW_MODES, COMP_DATA_QUERY_KEYS_WITH_STYLE} from '../../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'

const {getPointer} = pointerUtils

export enum FixerCategory {
    VIEWER = 'viewer_fixer',
    MIGRATOR = 'migration_fixer',
    DS = 'ds_fixer'
}

const fixerVersionsNamespace = DATA_TYPES.fixerVersions

const logFixers = typeof localStorage !== 'undefined' && localStorage.getItem('dm-log-fixers') === 'true'

const reportFixerActions =
    (logger: CoreLogger) =>
    (category: FixerCategory, fixerChangesOnReruns: FixerActions): void => {
        if (_.isEmpty(fixerChangesOnReruns)) {
            return
        }
        const report = {
            tags: {
                category,
                ..._.mapValues(fixerChangesOnReruns, 'ver')
            },
            extras: JSON.stringify(trimLongReports(fixerChangesOnReruns))
        }
        if (logFixers) {
            console.log(category, fixerChangesOnReruns)
        }
        logger.interactionStarted('fixer_version_mods', report)
    }

export interface DataFixerVersioningApi extends ExtensionAPI {
    dataFixerVersioning: {
        executeFixerAndSetModifications(fixerExecutor: Function, pageId: string, fixerName: string, fixerVersion: number): FixerActions
        hasFixerRunOnCurrentVersion(pageId: string, category: FixerCategory, fixerName: string, fixerVersion: number): boolean
        updatePageVersionData(pageId: string, versionData: FixerCategoryToFixerVersion): void
        reportFixerActions(category: FixerCategory, fixerChangesOnReruns: any): void
    }
}

const getFixerVersionsQuery = (dal: DAL, pageId: string) => {
    const fixerVersionsQueryPointer = getPointer(pageId, VIEW_MODES.DESKTOP, [COMP_DATA_QUERY_KEYS_WITH_STYLE.fixerVersions])
    return dal.get(fixerVersionsQueryPointer)
}

const hasFixerRunOnCurrentVersion =
    (dal: DAL, extensionAPI: ExtensionAPI) =>
    (pageId: string, category: FixerCategory, fixerName: string, fixerVersion: number): boolean => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const fixerVersionsQuery = getFixerVersionsQuery(dal, pageId)
        const pageFixerVersions = dataModel.getItem(fixerVersionsQuery, fixerVersionsNamespace, pageId)
        const lastVersionRun = pageFixerVersions?.[category]?.[fixerName]
        return lastVersionRun === fixerVersion
    }

const updatePageVersionData =
    (dal: DAL, extensionAPI: ExtensionAPI) =>
    (pageId: string, versionData: FixerCategoryToFixerVersion): void => {
        if (Object.values(versionData).every(_.isEmpty)) {
            return
        }
        const pagePointer = getPointer(pageId, VIEW_MODES.DESKTOP)
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const fixerVersionsQuery = getFixerVersionsQuery(dal, pageId)
        if (fixerVersionsQuery) {
            const fixerVersionsDataPointer = getPointer(fixerVersionsQuery, fixerVersionsNamespace)
            const currentData = dal.get(fixerVersionsDataPointer)
            versionData = _.merge(deepClone(currentData), versionData)
        }
        dataModel.components.addItem(pagePointer, fixerVersionsNamespace, {
            metaData: {pageId},
            type: 'FixerVersions',
            ...versionData
        })
    }

const executeFixerAndSetModifications =
    (dal: DAL, experimentEnabled: boolean) =>
    (fixerExecutor: Function, pageId: string, fixerName: string, fixerVersion: number): FixerActions => {
        if (!experimentEnabled) {
            fixerExecutor()
            return {}
        }
        const modifiedPaths: string[] = []

        const changeCallback: DalValueChangeCallback = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => {
            // this check ensures that deletion of a non existing data item in the dal won't be flagged as a fixer change
            if (oldValue !== undefined || newValue !== undefined) {
                modifiedPaths.push(`${pointer.type}/${pointer.id}`)
                if (logFixers) {
                    logDalValueChanges(pointer, oldValue, newValue, `"${fixerName}" `)
                }
            }
        }

        dal.registerForChangesCallback(changeCallback)

        try {
            fixerExecutor()
        } finally {
            dal.unregisterForChangesCallback(changeCallback)
        }

        if (modifiedPaths.length) {
            return {
                [fixerName]: {
                    ver: fixerVersion,
                    [pageId]: modifiedPaths
                }
            }
        }
        return {}
    }

const createExtensionAPI = ({dal, extensionAPI, coreConfig}: CreateExtArgs): DataFixerVersioningApi => {
    const experimentEnabled = !coreConfig.dontCollectFixerVersionData && coreConfig.experimentInstance.isOpen('dm_fixerVersioning')

    return {
        dataFixerVersioning: {
            executeFixerAndSetModifications: executeFixerAndSetModifications(dal, experimentEnabled),
            hasFixerRunOnCurrentVersion: experimentEnabled ? hasFixerRunOnCurrentVersion(dal, extensionAPI) : () => false,
            updatePageVersionData: experimentEnabled ? updatePageVersionData(dal, extensionAPI) : _.noop,
            reportFixerActions: experimentEnabled ? reportFixerActions(coreConfig.logger) : _.noop
        }
    }
}

const createExtension = (): Extension => ({
    name: 'dataFixerVersioning',
    createExtensionAPI
})

export {createExtension}
