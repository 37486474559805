define([
    'lodash',
    'documentServices/utils/runtimeConfig',
    'documentServices/saveAPI/preSaveOperations/plugins/meshLayoutReadyPreSaveOperation',
    'documentServices/saveAPI/preSaveOperations/plugins/mobilePreSaveOperation',
    'documentServices/saveAPI/preSaveOperations/plugins/markDataAsPresetOperation',
    'documentServices/saveAPI/preSaveOperations/plugins/disallowDuplicatePageUriSeoOperation',
    'documentServices/saveAPI/preSaveOperations/plugins/disallowForbiddenPageUriSeoOperation',
    'documentServices/saveAPI/preSaveOperations/plugins/accessibilityReorder',
    'documentServices/saveAPI/preSaveOperations/plugins/calculateSiteMetaDataRenderHints'
], function (
    _,
    runtimeConfig,
    meshLayoutReadyPreSaveOperation,
    mobilePreSaveOperation,
    markDataAsPresetOperation,
    disallowDuplicatePageUriSeoOperation,
    disallowForbiddenPageUriSeoOperation,
    accessibilityReorder,
    calculateSiteMetaDataRenderHints
) {
    'use strict'
    const save = {
        disallowDuplicatePageUriSeoOperation,
        disallowForbiddenPageUriSeoOperation,
        mobilePreSaveOperation,
        accessibilityReorder,
        calculateSiteMetaDataRenderHints,
        meshLayoutReadyPreSaveOperation
    }

    const saveResponsive = {disallowDuplicatePageUriSeoOperation, disallowForbiddenPageUriSeoOperation, calculateSiteMetaDataRenderHints}

    const saveAsTemplate = {disallowDuplicatePageUriSeoOperation, disallowForbiddenPageUriSeoOperation, markDataAsPresetOperation}

    const autosave = {mobilePreSaveOperation}

    const runSingle = (ps, preSaveOp, name) => {
        preSaveOp(ps)
        if (!runtimeConfig.isSanta(ps)) {
            ps.dal.commitTransaction(name)
        }
    }

    const runMultiple = preSaveOps => ps => _.forEach(preSaveOps, (op, name) => runSingle(ps, op, name))

    return {
        save: ps => {
            const runPreSaveOps = ps.runtimeConfig.supportMobile ? runMultiple(save) : runMultiple(saveResponsive)
            return runPreSaveOps(ps)
        },
        saveAsTemplate: runMultiple(saveAsTemplate),
        autosave: runMultiple(autosave)
    }
})
