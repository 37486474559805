define(['documentServices/constants/constants', 'documentServices/componentsMetaData/metaDataUtils'], function (consts, metaDataUtils) {
    'use strict'

    return {
        duplicatable(ps, comp, potentialContainer) {
            if (comp.type === consts.VIEW_MODES.MOBILE) {
                return false
            }

            const isDuplicatingAsSibling = ps.pointers.isSamePointer(ps.pointers.components.getParent(comp), potentialContainer)
            const numOfColumns = potentialContainer && ps.pointers.components.getChildren(potentialContainer).length

            return !!(isDuplicatingAsSibling && numOfColumns < 5)
        },
        groupable: false,
        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: true,
        anchors: {
            to: {allow: true, lock: consts.ANCHORS.LOCK_CONDITION.NEVER},
            from: {allow: false, lock: consts.ANCHORS.LOCK_CONDITION.ALWAYS}
        },
        isContainCheckRecursive: false,
        mobileConversionConfig: {
            nestOverlayingSiblings: false,
            structuralItem: true,
            filterChildrenWhenHidden: true,
            stretchHorizontally: true,
            minHeight: 200,
            category: 'column',
            preserveAspectRatio: false,
            hideWhenEmptyBackgroundContainer: true,
            fixedSize(ps, comp, pageId) {
                return metaDataUtils.getMobileFixedSizesForColumn(ps, comp, pageId)
            }
        }
    }
})
