define([
    'lodash',
    'documentServices/utils/contextAdapter',
    'documentServices/componentsMetaData/externalComponentsMetaData',
    'documentServices/componentsMetaData/metaDataMap',
    'documentServices/componentsMetaData/metaDataTypes',
    '@wix/document-manager-utils'
], function (_, contextAdapter, externalComponentsMetaData, metaDataMap, META_DATA_TYPES, documentManagerUtils) {
    'use strict'
    const {ReportableError} = documentManagerUtils

    const isComponentTypeExternal = componentType => _.get(metaDataMap, [componentType, 'isExternal'], false)

    const META_DATA_KEYS = _.invert(META_DATA_TYPES)

    const isMetaDataKeyLegal = key => META_DATA_KEYS[key]

    function getIllegalKeys(metaData) {
        return _(metaData).keys().reject(isMetaDataKeyLegal).value()
    }

    function informIllegalMetadataTypes(illegalKeys, componentType, metaDataToRegister) {
        const err = new ReportableError({
            errorType: 'illegalMetaDataKey',
            message: `Unable to use illegal meta data key(s) "${illegalKeys.join(', ')}" for component "${componentType}"`
        })
        contextAdapter.utils.fedopsLogger.captureError(err, {
            tags: {
                metaDataRegistration: true
            },
            extras: {
                illegalKeys,
                metaDataToRegister
            }
        })
    }

    function informUnableToRegisterAlreadyRegisteredComponent(componentType) {
        const err = new Error(`Unable to register a component metaData for type "${componentType}" that is already registered`)
        contextAdapter.utils.fedopsLogger.captureError(err, {
            tags: {
                metaDataRegistration: true,
                failedMetaDataRegistration: true
            },
            extras: {
                componentType
            }
        })
    }

    const metaDataRegistrar = {
        registerMetaData: (componentType, metaData) => {
            if (metaDataMap[componentType]) {
                informUnableToRegisterAlreadyRegisteredComponent(componentType)
                return
            }
            metaDataMap[componentType] = metaData
        },
        registerExternalMetaData: (componentType, metaData) => {
            const illegalKeys = getIllegalKeys(metaData)

            if (illegalKeys.length) {
                metaData = _.omit(metaData, illegalKeys)
                informIllegalMetadataTypes(illegalKeys, componentType, metaData)
            }

            const metaDataToRegister = externalComponentsMetaData.getMetaDataToRegister(metaData)

            return metaDataRegistrar.registerMetaData(componentType, metaDataToRegister)
        },
        unregisterExternalMetaData: componentType => {
            if (isComponentTypeExternal(componentType)) {
                delete metaDataMap[componentType]
            }
        }
    }

    return metaDataRegistrar
})
