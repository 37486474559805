import {ReportableError} from '@wix/document-manager-utils'

interface SchemaValidationErrorDetails {
    readonly message: string
    readonly dataPath: string
    readonly keyword: string
    readonly schemaPath: string
    readonly params: string
}

class SchemaValidationError extends ReportableError {
    constructor(readonly errors: ReadonlyArray<SchemaValidationErrorDetails>) {
        super({errorType: 'schemaValidationError', message: JSON.stringify(errors, null, 4)})
        // Recommended workaround for a TypeScript issue.
        // See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, SchemaValidationError.prototype)
    }
}

class CannotFindSchemaError extends ReportableError {
    constructor(namespace: string, dataTypeName: string) {
        super({errorType: 'cannotFindSchema', message: `Cannot find schema ${dataTypeName} for namespace: ${namespace}`})
        Object.setPrototypeOf(this, CannotFindSchemaError.prototype)
    }
}

export {SchemaValidationError, CannotFindSchemaError, SchemaValidationErrorDetails}
