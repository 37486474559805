import PropTypes from 'prop-types'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import baseImageProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseImageProxy'

/**
 * @class proxies.Image
 * @extends proxies.mixins.baseImageProxy
 */
export default {
    mixins: [baseImageProxy],

    propTypes: {
        viewProps: PropTypes.shape({
            resolveImageData: PropTypes.func.isRequired,
            serviceTopology: PropTypes.object.isRequired,
            packageName: PropTypes.string.isRequired,
            globalImageQuality: PropTypes.object
        })
    },

    getCompData() {
        return typesConverter.image(
            this.proxyData,
            this.props.viewProps.resolveImageData,
            this.props.viewProps.serviceTopology,
            this.props.viewProps.packageName,
            this.props.viewProps.globalImageQuality
        )
    }
}
