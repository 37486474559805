import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import ProductOptionsCalculator from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/logics/helpers/ProductOptionsCalculator'
import ecomLogger from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomLogger'

const SHOW_FEEDBACK = 'showFeedback'

function fixSetDataBatch(batch, pathToProduct) {
    _.forEach(batch, function (item) {
        item.path = pathToProduct.concat(item.path)
    })
}

function getProduct(partApi) {
    const pathToProduct = partApi.getRootDataItemRef()
    return pathToProduct && partApi.getDataByPath(partApi.getPackageName(), pathToProduct)
}

function getTextOptionsValueAndId(partApi) {
    const {options} = getProduct(partApi)
    return _(options)
        .filter({optionType: 'simpleText'})
        .map(function (textOption) {
            return {optionId: textOption.id, value: textOption.text}
        })
        .value()
}

function openFeedbackMessageIfNeeded(partApi) {
    const logicParams = partApi.getPartData().appLogicParams
    if (_.get(logicParams, ['afterAddProduct', 'value']) === SHOW_FEEDBACK && !partApi.isMobileView()) {
        const dataItem = _.clone(partApi.getPartData())
        dataItem.appPartName = '03946768-374D-4426-B885-A1A5C6F570B9'
        dataItem.viewName = 'FeedbackMessage'
        partApi.addComponent(
            'ecomFeedback',
            {
                id: 'ecomFeedback',
                styleId: 'zoom',
                dataQuery: dataItem.id,
                skin: 'wysiwyg.skins.AppPartZoomSkin',
                componentType: 'wixapps.integration.components.AppPartZoom'
            },
            {
                rootNavigationInfo: partApi.getRootNavigationInfo(),
                rootId: partApi.getRootId(),
                compData: dataItem,
                closeFunction() {
                    partApi.deleteComponent('ecomFeedback')
                }
            }
        )
    }
}

function createAddProductOnFailCallback(partApi) {
    return function (err) {
        partApi.deleteComponent('ecomFeedback')
        partApi.ecomShowMessage(err || {code: 2026})
    }
}

function setProductImageAndMedia(partApi, mediaItem, image) {
    const setDataBatch = [
        {path: ['productMedia'], value: mediaItem},
        {path: ['currentImage'], value: image}
    ]
    fixSetDataBatch(setDataBatch, partApi.getRootDataItemRef())
    partApi.setBatchedData(partApi.getPackageName(), setDataBatch)
}

function validateAndUpdatePrice() {
    const setDataBatch = []

    this.validateAndUpdatePrice(this.optionsCalculator.getProductItem(), setDataBatch)

    fixSetDataBatch(setDataBatch, this.partApi.getRootDataItemRef())
    this.partApi.setBatchedData(this.partApi.getPackageName(), setDataBatch)
}

function autoSelectSingleOptions() {
    const setDataBatch = []
    const product = getProduct(this.partApi)
    // if there is only one enabled option select it and set it in site data
    _.forEach(
        product.options,
        function (option, optionIndex) {
            const enabledItems = _.filter(option.items, 'enabled')
            if (enabledItems.length === 1) {
                this.optionsCalculator.selectOption(optionIndex, enabledItems[0].value, setDataBatch)
                setDataBatch.push({path: ['options', optionIndex, 'selectedValue'], value: enabledItems[0].value})
            }
        }.bind(this)
    )

    fixSetDataBatch(setDataBatch, this.partApi.getRootDataItemRef())
    this.partApi.setBatchedData(this.partApi.getPackageName(), setDataBatch)

    if (this.optionsCalculator.allOptionsSelected()) {
        validateAndUpdatePrice.call(this)
    }
}

/**
 * @class ecom.logics.ProductPageLogic
 * @param partApi
 * @constructor
 */
function ProductPageLogic(partApi) {
    this.isRedirectingToNewTpaEcom = partApi.isEcomTpaExistsAndEcomListNot()
    if (this.isRedirectingToNewTpaEcom) {
        const pageId = partApi.getEcomTpaProductPageId()
        const productId = partApi.getProductIdFromUrl()
        _.defer(_.partial(partApi.navigateToPage, {pageId, pageAdditionalData: productId}))
        return
    }

    this.partApi = partApi
    const product = getProduct(partApi)
    this.optionsCalculator = product && new ProductOptionsCalculator(product)
}

ProductPageLogic.prototype = {
    isReady() {
        if (this.isRedirectingToNewTpaEcom) {
            return true
        }
        autoSelectSingleOptions.call(this)
        return true
    },

    productOptionChanged(event) {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }
        const {payload} = event
        const optionListIndex = _.findIndex(getProduct(this.partApi).options, {id: payload.listData.id})
        const setDataBatch = []
        const productItem = this.optionsCalculator.selectOption(optionListIndex, payload.value, setDataBatch)
        this.validateAndUpdatePrice(productItem, setDataBatch)
        fixSetDataBatch(setDataBatch, this.partApi.getRootDataItemRef())
        this.partApi.setBatchedData(this.partApi.getPackageName(), setDataBatch)
    },

    textAreaOptionChanged() {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const setDataBatch = []
        this.validateAndUpdatePrice(this.optionsCalculator.getProductItem(), setDataBatch)
        fixSetDataBatch(setDataBatch, this.partApi.getRootDataItemRef())
        this.partApi.setBatchedData(this.partApi.getPackageName(), setDataBatch)
    },

    'buy-product'() {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const setDataBatch = []
        const productItem = this.optionsCalculator.getProductItem()
        this.validateAndUpdatePrice(productItem, setDataBatch)
        fixSetDataBatch(setDataBatch, this.partApi.getRootDataItemRef())
        this.partApi.setBatchedData(this.partApi.getPackageName(), setDataBatch)

        if (productItem && _.every(getProduct(this.partApi).options, 'valid')) {
            this.partApi.ecomReportEvent(ecomLogger.events.PRODUCT_PAGE_ADD_PRODUCT_TO_CART, {
                itemId: productItem.id
            })

            const options = getTextOptionsValueAndId(this.partApi)
            const onFailCallback = createAddProductOnFailCallback(this.partApi)
            this.partApi.addProduct(productItem.id, options, _.noop, onFailCallback)
            openFeedbackMessageIfNeeded(this.partApi)

            this.partApi.navigateToPage({pageId: this.partApi.getCurrentUrlPageId()})
        }
    },

    'image-selected'(event) {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const product = getProduct(this.partApi)
        const mediaItem = product.mediaItems[event.payload.itemIndex]
        const image = product.imageList[event.payload.itemIndex]
        setProductImageAndMedia(this.partApi, mediaItem, image)
    },

    'gallery-item-clicked'(event) {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const {mediaItem} = event.params
        const {image} = event.params
        setProductImageAndMedia(this.partApi, mediaItem, image)
    },

    shareProductPage(event) {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const product = getProduct(this.partApi)
        const {mediaItems} = product

        this.partApi.ecomReportEvent(ecomLogger.events.USER_SHARED_PRODUCT_PAGE, {
            productId: product.id,
            service: event.params.service
        })

        this.partApi.handleShareRequest(
            {
                url: this.partApi.getCurrentUrlObj().full,
                service: event.params.service,
                title: product.title,
                imageUrl: mediaItems.length ? product.imageList[0].src : '',
                addDeepLinkParam: true
            },
            true
        )
    },

    validateAndUpdatePrice(productItem, dataChangesBatch) {
        if (this.isRedirectingToNewTpaEcom) {
            return
        }

        const isValid = this.optionsCalculator.validateOptions(dataChangesBatch)
        if (isValid) {
            const productItemIndex = _.findIndex(getProduct(this.partApi).productItems, productItem)
            if (productItem) {
                dataChangesBatch.push({path: ['price'], value: productItem.price})
            }
            dataChangesBatch.push({path: ['selectedItemIndex'], value: productItemIndex})
        }
    }
}

wixapps.logicFactory.register('f72a3898-8520-4b60-8cd6-24e4e20d483d', ProductPageLogic)
