define([], function () {
    'use strict'

    return {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.ExpandableMenuSkin',
        layout: {
            width: 188,
            height: 392,
            x: 30,
            y: 100,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.ExpandableMenu',
        data: {
            type: 'CustomMenuDataRef',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            menuRef: '#CUSTOM_MAIN_MENU'
        },
        props: {
            type: 'ExpandableMenuProperties',
            metaData: {
                schemaVersion: '1.0',
                autoGenerated: false
            },
            itemsAlignment: 'left',
            menuArrowDirection: 'right',
            originalHeight: 225,
            menuItemHeight: 56
        },
        style: {
            type: 'ComponentStyle',
            styleType: 'custom',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            style: {
                properties: {
                    SKINS_fntSubmenu: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
                    SKINS_submenuBR: '90px',
                    SKINS_submenuMargin: '0',
                    'alpha-SKINS_bgSubmenu': '0',
                    'alpha-bg': '0',
                    'alpha-bgexpanded': '0',
                    'alpha-bgh': '1',
                    'alpha-bgs': '0',
                    'alpha-bgsSub': '0',
                    'alpha-brd': 0.2,
                    arrowColor: 'color_15',
                    bg: 'color_11',
                    bgexpanded: 'color_11',
                    bgh: '#E6EAF5',
                    bgs: 'color_11',
                    bgsSub: 'color_11',
                    'boxShadowToggleOn-shd': 'false',
                    brd: 'color_15',
                    brw: '0px 0px 0px 0px',
                    fnt: 'font_7',
                    fntSubMenu: 'font_7',
                    menuSpacing: '10',
                    rd: '90px',
                    separatorHeight: '15',
                    sepw: '1',
                    shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                    subMenuSpacing: 25,
                    textSpacing: '0',
                    txt: 'color_15',
                    txtexpanded: 'color_15',
                    txts: 'color_18',
                    txtsSub: 'color_18'
                },
                propertiesSource: {
                    SKINS_fntSubmenu: 'value',
                    SKINS_submenuBR: 'value',
                    SKINS_submenuMargin: 'value',
                    'alpha-SKINS_bgSubmenu': 'value',
                    'alpha-bg': 'value',
                    'alpha-bgexpanded': 'value',
                    'alpha-bgh': 'value',
                    'alpha-bgs': 'value',
                    'alpha-bgsSub': 'value',
                    'alpha-brd': 'value',
                    arrowColor: 'theme',
                    bg: 'theme',
                    bgexpanded: 'theme',
                    bgh: 'value',
                    bgs: 'theme',
                    bgsSub: 'theme',
                    'boxShadowToggleOn-shd': 'value',
                    brd: 'theme',
                    brw: 'value',
                    fnt: 'theme',
                    fntSubMenu: 'theme',
                    menuSpacing: 'value',
                    rd: 'value',
                    separatorHeight: 'value',
                    sepw: 'value',
                    shd: 'value',
                    subMenuSpacing: 'value',
                    textSpacing: 'value',
                    txt: 'theme',
                    txtexpanded: 'theme',
                    txts: 'theme',
                    txtsSub: 'theme'
                },
                groups: {}
            },
            componentClassName: 'wysiwyg.viewer.components.ExpandableMenu',
            pageId: '',
            compId: '',
            skin: 'wysiwyg.viewer.skins.ExpandableMenuSkin'
        },
        connections: {
            type: 'ConnectionList',
            items: [
                {
                    type: 'WixCodeConnectionItem',
                    role: 'expandableMenu1'
                }
            ]
        },
        activeModes: {}
    }
})
