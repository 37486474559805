import _ from 'lodash'
import {Pointer, CreateExtArgs, DalValue, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {generateUniqueIdByType, shouldMergeDataItems, addDefaultMetaData, generateItemIdWithPrefix} from '../utils/dataUtils'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, DATA_TYPES_VALUES_WITH_HASH} from '../constants/constants'
import {validateCompNamespaceType} from '../utils/schemaUtils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {RelationshipsAPI} from './relationships'
import {ReportableError} from '@wix/document-manager-utils'

const {getInnerPointer} = pointerUtils
export type AddCompItem = (compPointer: Pointer, namespace: string, data: DalValue, languageCode?: string) => Pointer
export type GetCompItem = (compPointer: Pointer, namespace: string, languageCode?: string, useOriginalLanguageFallback?: boolean) => DalValue | void
export type GenerateItemIdWithPrefix = (prefix: string) => string

export interface DataModelAPI {
    addItem(item: DalValue, itemType: string, pageId: string, customId?: string, languageCode?: string): Pointer
    getItem(id: string, namespace: string, pageId: string, languageCode?: string, useOriginalLanguageFallback?: boolean): DalValue | void
    generateItemIdWithPrefix: GenerateItemIdWithPrefix
    components: {
        addItem: AddCompItem
        getItem: GetCompItem
    }
}

export type DataModelExtensionAPI = ExtensionAPI & {
    dataModel: DataModelAPI
}

const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): DataModelExtensionAPI => {
    const {relationships} = extensionAPI as RelationshipsAPI

    const translateIfNeeded = (itemPointer: Pointer, languageCode: string | undefined) =>
        languageCode && DATA_TYPES.data === itemPointer.type ? pointers.data.getTranslatedData(itemPointer, languageCode) : itemPointer

    const addItem = (item: DalValue, namespace: string, pageId: string, customId?: string, languageCode?: string) => {
        const itemToUpdatePointer = pointers.data.getItem(namespace, item.id, pageId)
        const id = customId ?? (item.id && !dal.has(itemToUpdatePointer) ? item.id : generateUniqueIdByType(namespace, pageId, dal, pointers))
        const itemInDALPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
        const itemInDALPointer = translateIfNeeded(itemInDALPointerBeforeTranslation, languageCode)
        const itemInDAL = dal.get(itemInDALPointer)
        let newItem: DalValue = shouldMergeDataItems(itemInDAL, item)
            ? _.assign({}, itemInDAL, item, {id, metaData: {...item.metaData, pageId}})
            : {...item, id, metaData: {...item.metaData, pageId}}
        if (!newItem.type) {
            throw Error(`item ${id} from namespace ${namespace} does not have type the item passed is ${JSON.stringify(item)}`)
        }
        const {schema} = dal
        const schemaRefFieldsInfo = schema.extractReferenceFieldsInfoForSchema(namespace, item.type)
        const schemaRefFields = _(schemaRefFieldsInfo).map('path').flatMap().value()
        _.forEach(schemaRefFields, key => {
            const val = newItem[key]
            if (_.isArray(val)) {
                const refArr: string[] = []
                const oldRefs = _.keyBy(itemInDAL[key], refId => refId.replace(/^#/, ''))
                _.forEach(val, itemInArr => {
                    const reusedId = oldRefs[itemInArr.id] ? itemInArr.id : undefined
                    const addedPointer = addItem(itemInArr, namespace, pageId, reusedId, languageCode)
                    refArr.push(`#${addedPointer.id}`)
                })
                newItem[key] = refArr
            } else if (_.isObject(val) && _.has(val, ['type'])) {
                const currentDataItemId = _.get(itemInDAL, [key, 'id'])
                // @ts-ignore
                const reusedId = (val.id && currentDataItemId && currentDataItemId.replace(/^#/, '')) === val.id ? val.id : undefined
                const addedPointer = addItem(val, namespace, pageId, reusedId, languageCode)
                newItem[key] = `#${addedPointer.id}`
            }
        })
        addDefaultMetaData(newItem, namespace)
        newItem = _.omitBy(newItem, _.isNil)
        const itemPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
        const itemPointer = translateIfNeeded(itemPointerBeforeTranslation, languageCode)
        dal.set(itemPointer, newItem)
        return itemPointer
    }

    const updateComponentItem = (compPointer: Pointer, namespace: string, item: DalValue, languageCode?: string) => {
        const componentTypePointer = getInnerPointer(compPointer, 'componentType')
        const componentType = dal.get(componentTypePointer)
        if (item.type) {
            const validCompNamespaceType = validateCompNamespaceType(dal, componentType, item.type, namespace)
            if (!validCompNamespaceType.isValid) {
                throw new ReportableError({
                    message: validCompNamespaceType.message ?? 'validateCompNamespaceType',
                    errorType: 'invalidComponent'
                })
            }
        }
        const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const refPointer = getInnerPointer(compPointer, namespaceKey)
        const ref = dal.get(refPointer)
        const idBeforeUpdate = _.isString(ref) ? relationships.getIdFromRef(ref) : ref
        const pageId = _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
        const itemPointer = addItem(item, namespace, pageId, idBeforeUpdate, languageCode)
        if (!idBeforeUpdate) {
            const idAfterUpdate = _.get(dal.get(itemPointer), ['id'])
            const newRef = DATA_TYPES_VALUES_WITH_HASH[namespace] ? `#${idAfterUpdate}` : idAfterUpdate
            dal.set(refPointer, newRef)
        }

        return itemPointer
    }

    const getItemInternal = (id: string, namespace: string, pageId: string, languageCode?: string, useOriginalLanguageFallback: boolean = false) => {
        const itemPointerBeforeTranslate = pointers.data.getItem(namespace, id, pageId)
        const itemOrNull = deepClone(dal.get(translateIfNeeded(itemPointerBeforeTranslate, languageCode)))
        const item = !itemOrNull && useOriginalLanguageFallback ? deepClone(dal.get(itemPointerBeforeTranslate)) : itemOrNull
        const {schema} = dal
        if (item) {
            const references = _.filter(schema.getReferences(namespace, item), {refInfo: {shouldCollect: true}, referencedMap: namespace})
            _.forEach(references, reference => {
                const referredItem = getItemInternal(reference.id, namespace, pageId, languageCode, true)
                const oldReferred = _.get(item, reference.refInfo.path)
                if (_.isString(oldReferred)) {
                    _.set(item, reference.refInfo.path, referredItem)
                } else if (_.isArray(oldReferred)) {
                    _.remove(oldReferred, _.isString)
                    oldReferred.push(referredItem)
                }
            })
        }

        return item?.metaData ? _.omit(item, ['metaData']) : item
    }

    const getItem = (id: string, namespace: string, pageId: string, languageCode?: string, useOriginalLanguageFallback: boolean = false) => {
        const item = getItemInternal(id, namespace, pageId, languageCode)
        return !item && useOriginalLanguageFallback && languageCode ? getItemInternal(id, namespace, pageId) : item
    }

    const getComponentItem = (compPointer: Pointer, namespace: string, languageCode?: string, useOriginalLanguageFallback: boolean = false) => {
        const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const refPointer = getInnerPointer(compPointer, namespaceKey)
        const ref = dal.get(refPointer)
        if (!ref) {
            return undefined
        }
        const id = relationships.getIdFromRef(ref)
        const pageId = _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
        return getItem(id, namespace, pageId, languageCode, useOriginalLanguageFallback)
    }
    return {
        dataModel: {
            addItem,
            getItem,
            generateItemIdWithPrefix,
            components: {
                addItem: updateComponentItem,
                getItem: getComponentItem
            }
        }
    }
}

const createExtension = (): Extension => ({
    name: 'dataModel',
    dependencies: new Set(['data']),
    createExtensionAPI
})

export {createExtension}
