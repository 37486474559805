define([
    'lodash',
    'documentServices/appStudio/widgetConfigs/appWidgetDefinition.json',
    'documentServices/appStudio/widgetConfigs/classicWidgetOverrides.json',
    'documentServices/theme/theme',
    'documentServices/page/blankPageStructure'
], function (_, appWidgetDefinition, classicWidgetOverrides, theme, blankPageStructure) {
    const createGreyLandingPage = ps => {
        const color = '#f0f3f5'
        const blankPage = blankPageStructure.getBlankPageStructure(ps)
        const pageBackground = blankPageStructure.getBlankBackground()
        pageBackground.desktop.ref.color = color
        pageBackground.mobile.ref.color = color
        blankPage.data.pageBackgrounds = pageBackground
        delete blankPage.styleId
        blankPage.style = theme.styles.createStyleItemToAdd(ps, {styleType: 'custom', skin: 'wysiwyg.viewer.skins.page.BasicPageSkin'})
        blankPage.style.style.properties.bg = color
        blankPage.data.isLandingPage = true
        blankPage.data.isMobileLandingPage = true

        return blankPage
    }

    const appWidgetStructure = _.assign({}, appWidgetDefinition, classicWidgetOverrides)

    return {
        getPageStructure: createGreyLandingPage,
        appWidgetStructure
    }
})
