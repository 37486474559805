define([
    'lodash',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/structure/structure',
    'documentServices/componentDetectorAPI/componentDetectorAPI'
], function (_, componentsMetaData, structure, componentDetectorAPI) {
    'use strict'

    const GROUP_COMPONENT_TYPE = 'wysiwyg.viewer.components.Group'

    function isSiteFromOnBoarding(ps) {
        const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
        const onBoardingSpec = _.find(clientSpecMap, {type: 'onboarding'})
        return onBoardingSpec ? onBoardingSpec.inUse === false : false
    }

    function fixGroup(ps, groupPointer) {
        const groupedComponents = ps.pointers.components.getChildren(groupPointer)
        _.forEach(groupedComponents, function (componentPointer) {
            if (!componentsMetaData.public.isGroupable(ps, componentPointer)) {
                structure.reparentComponentToPage(ps, componentPointer, true)
            }
        })
    }

    return {
        exec(ps) {
            if (isSiteFromOnBoarding(ps)) {
                const allGroups = componentDetectorAPI.getAllComponentsFromFull(ps, null, function (pointer) {
                    const comp = ps.dal.get(pointer)
                    return comp && comp.componentType === GROUP_COMPONENT_TYPE
                })
                _.forEach(allGroups, function (pointer) {
                    fixGroup(ps, pointer)
                })
            }
        },
        name: 'adiGroupFixer',
        version: 1
    }
})
