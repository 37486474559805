import _ from 'lodash'

/**
 *
 * @constructor
 * @param {core.SiteAspectsSiteAPI} siteAspectsSiteAPI
 */

const Aspect = function (siteAspectsSiteAPI) {
    this.siteAspectsSiteAPI = siteAspectsSiteAPI

    _.bindAll(this, _.functionsIn(this))
}

Aspect.prototype = {
    updateSectionUrlState(compId, sectionUrlState) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.setState({
                sectionUrlState
            })
        }
    },
    updatePushState(compId, pushState) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.setState({
                pushState
            })
        }
    },
    updateSectionUrlParams(compId, sectionUrlParams) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.setState({
                sectionUrlParams
            })
        }
    },
    reportStateChanged(compId, state) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.reportStateChanged(state)
        }
    },
    updatePreventRefreshState(compId, preventRefresh) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.setState(preventRefresh)
        }
    },
    setCompState(compId, newState) {
        const comp = this.siteAspectsSiteAPI.getComponentById(compId)
        if (comp) {
            comp.setState(newState)
        }
    }
}

export default Aspect
