import _ from 'lodash'
import tpa from '../../../tpa'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixCodeHandlers from '../handlers/wixCodeHandlers'
import platformTpaApiService from '../services/platformTpaApiService'
import hostWorkerInit from '@wix/santa-host-platform-services/dist/host-worker-init'
import messageBuilder from '../utils/messageBuilder'
import wixCodeWidgetService from '../utils/wixCodeWidgetService'
import pmrpc from 'pm-rpc'

const constants = hostWorkerInit.constants.WIX_CODE

function sendDelayedMessage(siteAPI, message, handler) {
    siteAPI.getSiteAspect('WidgetAspect').sendDelayedWixCodePostMessage(message, handler)
}

function handleRequestAPIMessage(siteAPI, widgetHandler, msg) {
    if (!siteAPI.getSiteData().isAddPanelView) {
        const apiName = `${constants.MESSAGE_TYPE.PLATFORM_PUBLIC_API_PREFIX + msg.appDefId}_${msg.workerId}`
        pmrpc.api.request(apiName, {target: widgetHandler.getPostMessageTarget(msg.workerId)}).then(function (api) {
            pmrpc.api.set(apiName, api)
        })
    }
}

function preMessageHandlingHook(siteAPI, message, handler) {
    return siteAPI.getSiteData().isInSSR() ? message : queueDomDependentMessages(siteAPI, message, handler)
}

function queueDomDependentMessages(siteAPI, message, handler) {
    const messageHandlerRequiresDom = _.get(message, 'data.requiresDom')
    if (messageHandlerRequiresDom) {
        const messageQueueAspect = siteAPI.getSiteAspect('wixCodeMessageQueueAspect')
        if (messageQueueAspect.shouldQueueMessage(message)) {
            messageQueueAspect.addMessageToQueue(message.data.requiresDom.compId, message, handler)
            return null
        }
        delete message.data.requiresDom
    }
    return message
}
function modifyPostMessage(siteAPI, messageData) {
    const siteData = siteAPI.getSiteData()
    const {wixCodeModel} = siteData.rendererModel
    const wixCodeSpec = wixCodeWidgetService.getWixCodeSpec(siteData.getClientSpecMap())

    return messageBuilder.getExtendedMessage(messageData, wixCodeModel, wixCodeSpec, siteData)
}

function getAllHandlers(siteAPI) {
    const allHandlers = _.defaults({}, wixCodeHandlers, tpa.tpaHandlers)

    return _(allHandlers)
        .pickBy(_.isFunction)
        .mapValues(handler => _.partial(handler, siteAPI)) // Add siteAPI as the first param that is passed to the handlers
        .value()
}

function getWixCodePostMessagesOptions(siteAPI) {
    return {
        preMessageHandlingHook: (message, handler) => preMessageHandlingHook(siteAPI, message, handler),
        handleRequestAPIMessage: (widgetHandler, msg) => handleRequestAPIMessage(siteAPI, widgetHandler, msg),
        logsHandler: coreUtils.logWixCodeConsoleMessage,
        hostAPIHandlers: getAllHandlers(siteAPI),
        handleWixCodeAppApiMessage: (msg, callback) => platformTpaApiService.handleAppAPIMessage(siteAPI, msg, callback)
    }
}

export default {
    getWixCodePostMessagesOptions,
    sendDelayedMessage,
    modifyPostMessage
}
