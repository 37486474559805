import _ from 'lodash'
import * as mobx from 'mobx'
import mobxPageFactory from '../observableData/mobxPageFactory'

function transformPage(siteData, useStructureNode, pageObject, pageId) {
    const mobxPage = mobxPageFactory.createMobxPage(pageObject, useStructureNode)
    if (mobxPage.data && mobxPage.structure) {
        const resolvedDataMaps = mobxPageFactory.createResolvedDataMaps(mobxPage.data, siteData.resolveData, pageId)
        mobxPageFactory.observeDataMapsChanges(mobxPage.data, resolvedDataMaps, siteData.resolveData, pageId)

        siteData.resolvedDataMaps = siteData.resolvedDataMaps || mobx.observable.map()
        siteData.resolvedDataMaps.set(pageId, resolvedDataMaps)
    }

    return mobxPage
}

function isStructurePath(path) {
    return path.length === 5 && path[2] === 'structure'
}

function isStructureMapPath(path) {
    return path.length === 4 && path[2] === 'structure'
}

function getComponentsMap(value, useStructureNode, currentCompsMap) {
    const isObservable = mobx.isObservableMap(currentCompsMap)
    const compsMap = _.transform(
        value,
        (acc, structure) => {
            const compId = structure.id
            const structureNode = isObservable ? currentCompsMap.get(compId) : _.get(currentCompsMap, compId)
            if (structureNode) {
                _.assign(structureNode, structure)
                return _.set(acc, compId, structureNode)
            }
            return _.set(acc, compId, mobxPageFactory.createMobxStructure(structure, useStructureNode))
        },
        {}
    )

    if (isObservable) {
        _(currentCompsMap.toJS())
            .keys()
            .difference(_.keys(compsMap))
            .forEach(key => currentCompsMap.delete(key))
        currentCompsMap.merge(compsMap)

        return currentCompsMap
    }

    return compsMap
}

export default function transform(siteData, path, value) {
    const useStructureNode = !siteData.getBrowser().safari // Safari crashes when we use StructureNode - WEED-2304

    if (path.length === 1) {
        if (!mobx.isObservableMap(siteData.resolvedDataMaps)) {
            siteData.resolvedDataMaps = mobx.observable.map()
        }
        siteData.resolvedDataMaps.clear()
        return _.mapValues(value, _.partial(transformPage, siteData, useStructureNode))
    }

    if (path.length === 2) {
        const pageId = path[1]
        return transformPage(siteData, useStructureNode, value, pageId)
    }

    if (path.length === 3) {
        // Use the updated value from pagesDataRaw
        const newValue = _.get(siteData.pagesDataRaw, path)

        // Reuse the maps in order to trigger reactions on changes.
        const pageStructure = _.get(siteData, path)
        return _.reduce(
            newValue,
            (acc, comps, viewMode) => {
                // @ts-ignore
                const compMap = acc[viewMode] || mobx.observable.shallowMap()
                return _.set(acc, viewMode, getComponentsMap(comps, useStructureNode, compMap))
            },
            pageStructure
        )
    }

    if (isStructureMapPath(path)) {
        const comps = _.get(siteData, path)
        const newValue = _.get(siteData.pagesDataRaw, path)
        return getComponentsMap(newValue, useStructureNode, comps)
    }

    if (isStructurePath(path)) {
        const structureNode = _.get(siteData, path)
        if (structureNode) {
            _.assign(structureNode, value)
            return structureNode
        }
        return mobxPageFactory.createMobxStructure(value, useStructureNode)
    }

    return value
}
