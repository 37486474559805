import _ from 'lodash'
import type {DocumentManager, Pointer} from '@wix/document-manager-core'
import {constants, RelationshipsAPI} from '@wix/document-manager-extensions'
import {deepClone} from '@wix/wix-immutable-proxy'
import {ReportableError} from '@wix/document-manager-utils'

const {VIEW_MODES} = constants

const getRefs = (documentManager: DocumentManager, mobileCompPointer: Pointer) => {
    const {relationships} = documentManager.extensionAPI as RelationshipsAPI
    const refComponents = _.pickBy(
        relationships.getReferencesToPointerIndex(mobileCompPointer, VIEW_MODES.MOBILE) || {},
        value => value.parent !== mobileCompPointer.id
    )

    const refIds = _.keys(refComponents)
    return {refIds, refComponents}
}

const cleanUpRefs = (documentManager: DocumentManager, mobileCompPointer: Pointer, refComponents: any) => {
    const {pointers} = documentManager

    _(refComponents)
        .keys()
        .forEach(compId => {
            const refPointer = pointers.getPointer(compId, VIEW_MODES.MOBILE)
            const currentMobileComp = deepClone(documentManager.dal.get(refPointer))
            const components = currentMobileComp.components.filter((id: string) => id !== mobileCompPointer.id)
            _.set(currentMobileComp, ['components'], components)
            documentManager.dal.set(refPointer, currentMobileComp)
        })
}

const removeComponent = (documentManager: DocumentManager, mobilePointer: Pointer) => {
    documentManager.dal.remove(mobilePointer)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {pointers, logger} = documentManager

    const allMobileComponents = pointers.structure.getAllComponentPointers(VIEW_MODES.MOBILE, pageId)

    allMobileComponents.forEach((mobileCompPointer: Pointer) => {
        const desktopPointer = pointers.structure.getDesktopPointer(mobileCompPointer)
        if (!documentManager.dal.get(desktopPointer)) {
            return
        }
        const mobileCompPageId = pointers.structure.getPageOfComponent(mobileCompPointer)?.id
        const desktopCompPageId = pointers.structure.getPageOfComponent(desktopPointer)?.id
        const {refComponents} = getRefs(documentManager, mobileCompPointer)
        const wrongRefs: string[] = []

        _.forIn(refComponents, (ref, key) => {
            if (_.get(ref, ['metaData', 'pageId']) !== mobileCompPageId) {
                wrongRefs.push(key)
            }
        })

        if (mobileCompPageId !== desktopCompPageId || wrongRefs.length > 0) {
            const logMessage = `Wrong mobile comp page: compId ${mobileCompPointer.id} from: ${desktopCompPageId}`
            logger.captureError(new ReportableError({errorType: 'MobileComponentWithWrongParentFixer', message: logMessage}))
            cleanUpRefs(documentManager, mobileCompPointer, refComponents)
            removeComponent(documentManager, mobileCompPointer)
        }
    })
}

const name = 'fixMobileComponentsWithWrongPage'
const version = 1

export {migratePage, name, version}
