import _ from 'lodash'
import coreUtils from '../../../../../coreUtils'

const privates = new coreUtils.SiteDataPrivates()
const {layoutUtils} = coreUtils

function getMasterPageStructureFromFullJson(fullSiteData) {
    const fullPagesData = coreUtils.DALFactory.getFullPagesData(fullSiteData, _.pick(fullSiteData, 'pagesData'))

    return fullPagesData.pagesData.masterPage.structure
}

function shouldApplyMobileTightLayout(sitePrivates) {
    const {siteData} = sitePrivates
    const masterPageStructure = getMasterPageStructureFromFullJson(siteData)

    return coreUtils.masterPageLayoutUtils.shouldApplyMobileTightLayout(masterPageStructure)
}

function getMasterPageAnchors(sitePrivates, masterPageStructure, theme, flags) {
    const {siteData} = sitePrivates
    if (flags.forceMobileStructure && shouldApplyMobileTightLayout(sitePrivates)) {
        return coreUtils.layoutAnchors.createMobileTightMasterPageAnchors(masterPageStructure, theme, flags)
    }

    const componentsTemplateId = _.get(siteData, ['displayedOnlyComponents', 'componentTemplateId'])
    return coreUtils.layoutAnchors.createPageAnchors(masterPageStructure, theme, flags, componentsTemplateId)
}

function createMasterPageAnchors(sitePrivates, masterPageStructure, theme, flags, viewMode) {
    const {siteData} = sitePrivates
    const regularMasterPageAnchorsMap = getMasterPageAnchors(sitePrivates, masterPageStructure, theme, flags)
    const landingPageMasterPageAnchorsMap = getAnchorsForLandingPageMasterPage()

    _.set(siteData, ['anchorsMap', masterPageStructure.id, viewMode], regularMasterPageAnchorsMap)
    _.set(siteData, ['anchorsMap', 'defaultMasterPage', viewMode], regularMasterPageAnchorsMap)
    _.set(siteData, ['anchorsMap', 'landingPageMasterPage', viewMode], landingPageMasterPageAnchorsMap)
}

const isMeshSite = layoutSettings => layoutSettings.mechanism === coreUtils.constants.LAYOUT_MECHANISMS.MESH

function getLayoutAnchorsFlags(siteData, flagsOverrides) {
    const layoutSettings = siteData.getMasterPageLayoutSettings()

    return {
        headerPagesContainerLegacyGap: _.get(layoutSettings, ['headerToPagesGap']),
        pagesContainerFooterLegacyGap: _.get(layoutSettings, ['pagesToFooterGap']),
        useDesktopSectionsLayout: _.get(layoutSettings, ['useDesktopSectionsLayout']),
        pushRows: isMeshSite(layoutSettings),
        forceMobileStructure: _.get(flagsOverrides, ['forceMobileStructure'], siteData.isMobileView()),
        applyGroupingAnchors: _.get(flagsOverrides, ['applyGroupingAnchors'], siteData.isMobileView() && siteData.isViewerMode())
    }
}

function getAnchorsForLandingPageMasterPage() {
    return {
        PAGES_CONTAINER: [
            {
                distance: 0,
                locked: true,
                originalValue: 0,
                fromComp: 'PAGES_CONTAINER',
                targetComponent: 'masterPage',
                type: 'BOTTOM_PARENT'
            }
        ],
        SITE_PAGES: [
            {
                distance: 0,
                locked: true,
                originalValue: 0,
                fromComp: 'SITE_PAGES',
                targetComponent: 'PAGES_CONTAINER',
                type: 'BOTTOM_PARENT'
            }
        ]
    }
}

function generateChildrenAnchors(parentStructure, sitePrivates, layoutAnchorsFlags, rootId) {
    const {siteData} = sitePrivates

    if (
        parentStructure.id === coreUtils.siteConstants.MASTER_PAGE_ID &&
        layoutAnchorsFlags.forceMobileStructure &&
        shouldApplyMobileTightLayout(sitePrivates)
    ) {
        return coreUtils.layoutAnchors.createMobileTightSectionsAnchors(parentStructure)
    }

    const theme = siteData.getAllStylesFromPage('masterPage')
    _.merge(theme, siteData.getAllStylesFromPage(rootId))

    const componentsTemplateId = _.get(siteData, ['displayedOnlyComponents', 'componentTemplateId'])

    return coreUtils.layoutAnchors.createChildrenAnchors(parentStructure, theme, layoutAnchorsFlags, componentsTemplateId)
}

/**
 * AnchorsDataAPI
 * @param siteData
 * @param pointers
 * @param displayedDAL
 * @constructor
 */

function AnchorsDataAPI(siteData, pointers, displayedDAL) {
    this.privatesKey = siteData

    privates.set(siteData, {siteData, displayedDAL, pointers})
}

AnchorsDataAPI.prototype = {
    createPageAnchors(rootId, flags) {
        if (!rootId) {
            return
        }
        const sitePrivates = privates.get(this.privatesKey)
        const {siteData} = sitePrivates

        if (layoutUtils.getLayoutMechanism(siteData) === coreUtils.constants.LAYOUT_MECHANISMS.MESH) {
            return
        }

        const layoutAnchorsFlags = getLayoutAnchorsFlags(siteData, flags)
        const viewMode = layoutAnchorsFlags.forceMobileStructure ? coreUtils.constants.VIEW_MODES.MOBILE : coreUtils.constants.VIEW_MODES.DESKTOP
        const flatStructure = siteData.getPageData(rootId, ['structure'], true)
        if (!flatStructure || _.isEmpty(flatStructure[viewMode])) {
            return
        }

        const theme = siteData.getAllStylesFromPage('masterPage')
        _.merge(theme, siteData.getAllStylesFromPage(rootId))

        const pageStructure = coreUtils.flatStructureUtil.getDeepStructureForComp(siteData, rootId, null, viewMode)
        const corruptedPageWithoutLayout = !pageStructure.layout
        if (corruptedPageWithoutLayout) {
            return
        }
        if (pageStructure.id === coreUtils.siteConstants.MASTER_PAGE_ID) {
            createMasterPageAnchors(sitePrivates, pageStructure, theme, layoutAnchorsFlags, viewMode)
        } else {
            const componentsTemplateId = _.get(siteData, ['displayedOnlyComponents', 'componentTemplateId'])
            const rootAnchorsMap = coreUtils.layoutAnchors.createPageAnchors(pageStructure, theme, layoutAnchorsFlags, componentsTemplateId)
            _.set(siteData, ['anchorsMap', pageStructure.id, viewMode], rootAnchorsMap)
        }
    },

    removePageOriginalValues(rootId, forceMobileStructure) {
        const sitePrivates = privates.get(this.privatesKey)
        const {siteData} = sitePrivates
        const pageStructure = siteData.getPageData(rootId, ['structure'])
        const viewMode = forceMobileStructure ? coreUtils.constants.VIEW_MODES.MOBILE : siteData.getViewMode()

        _.set(siteData, ['originalValuesMap', pageStructure.id, viewMode], {})
    },

    /**
     * Non recursive creation of anchors for children of given structure.
     * @param parentStructure - component structure
     * @param parentPageId - the page that contains the parent component
     */
    createChildrenAnchors(rootPageStructure, rootId, flags) {
        const sitePrivates = privates.get(this.privatesKey)
        const {siteData} = sitePrivates

        if (layoutUtils.getLayoutMechanism(siteData) === coreUtils.constants.LAYOUT_MECHANISMS.MESH) {
            return
        }

        if (!siteData.anchorsMap[rootId]) {
            return
        }

        const layoutAnchorsFlags = getLayoutAnchorsFlags(siteData, flags)

        const parentRootStructureId = rootId
        const viewMode = layoutAnchorsFlags.forceMobileStructure ? coreUtils.constants.VIEW_MODES.MOBILE : coreUtils.constants.VIEW_MODES.DESKTOP

        const structure = coreUtils.flatStructureUtil.getDeepStructureForComp(siteData, rootId, rootPageStructure.id, viewMode)
        const generatedAnchorsMap = generateChildrenAnchors(structure, sitePrivates, layoutAnchorsFlags, rootId)
        _.assign(siteData.anchorsMap[parentRootStructureId][viewMode], generatedAnchorsMap)
    }
}

export default AnchorsDataAPI
