define(['lodash', 'experiment', 'documentServices/bi/bi', 'documentServices/bi/events.json', 'documentServices/utils/contextAdapter'], function (
    _,
    experiment,
    bi,
    biEvents,
    contextAdapter
) {
    'use strict'
    function reportAPICallBI(ps, message) {
        if (experiment.isOpen('dm_platformSdkApiCallBI')) {
            const callPath = message.call.split('.')
            const module = callPath.slice(0, -1).join('.')
            const func = _.last(callPath)
            const args = message.args.map(JSON.stringify).join(',')
            bi.event(ps, biEvents.PLATFORM_API_CALL, {
                app_name: message.args[0].appDefinitionId,
                module,
                function: func,
                arguments: args,
                pageId: ps.siteAPI.getPrimaryPageId()
            })
        }
    }

    function reportAPICallFedOps(ps, message) {
        if (experiment.isOpen('dm_platformSdkApiCallBI')) {
            const appDefinitionId = _.get(message, ['args', 0, 'value', 'appDefinitionId'])

            /*
               "interactionEnded" BI event is in use here only as a "container" to track which EditorSDK methods are called.
               "interactionStarted" event was added just for consistency because interaction should be described by a pair of these events
             */
            contextAdapter.utils.fedopsLogger.interactionStarted(message.call, {extras: {appDefinitionId}})
            contextAdapter.utils.fedopsLogger.interactionEnded(message.call, {extras: {appDefinitionId}})
        }
    }

    return {
        reportAPICallBI,
        reportAPICallFedOps
    }
})
