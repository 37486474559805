define([], function () {
    'use strict'

    return {
        id: 'MediaContainer0',
        style: {
            type: 'ComponentStyle',
            styleType: 'custom',
            compId: '',
            componentClassName: 'wysiwyg.viewer.components.MenuContainer',
            pageId: '',
            skin: 'wysiwyg.viewer.skins.inlinePopup.DefaultInlinePopup',
            style: {
                groups: {},
                properties: {
                    bg: 'color_15',
                    'alpha-bg': 0.6
                },
                propertiesSource: {
                    bg: 'theme',
                    'alpha-bg': 'value'
                }
            },
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            }
        },
        type: 'Container',
        components: [],
        skin: 'wysiwyg.viewer.skins.inlinePopup.DefaultInlinePopup',
        layout: {
            width: 260,
            height: 200,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: true,
            docked: {
                top: {
                    px: 0
                },
                bottom: {
                    px: 0
                },
                right: {
                    px: 0
                }
            }
        },
        componentType: 'wysiwyg.viewer.components.MenuContainer',
        behaviors: {
            type: 'ObsoleteBehaviorsList',
            items: '[{"name":"FloatIn","delay":0,"duration":0.5,"params":{"direction":"right"},"action":"inlinePopupIn","targetId":"","viewMode":"MOBILE"},{"name":"Conceal","delay":0,"duration":0.5,"params":{"direction":"right"},"action":"inlinePopupOut","targetId":"","viewMode":"MOBILE"}]'
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            background: {
                type: 'BackgroundMedia',
                metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false
                },
                color: '{color_11}',
                colorOpacity: 1,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0,
                showOverlayForMediaType: 'WixVideo',
                mediaRef: null,
                imageOverlay: null,
                filterEffect: null
            },
            charas: 'design-jr0840d9',
            dataChangeBehaviors: []
        }
    }
})
