define(['@wix/document-manager-utils'], function ({ReportableError}) {
    /**
     * @param name
     * @param fullMethodName
     * @param value
     * @param expectedValue
     * @constructor
     */
    class ArgumentError extends Error {
        constructor(name, fullMethodName, value, expectedValue) {
            super()
            const expectedValueStr = expectedValue ? ` Expecting ${expectedValue}.` : ''
            this.name = 'ArgumentError'
            this.message = `Illegal Argument '${name}' in ${fullMethodName}. '${value}' is invalid.${expectedValueStr}`
            this.stack = new Error().stack
        }
    }

    /**
     * @param message
     * @constructor
     */
    class FileSystemError extends Error {
        constructor(message) {
            super()
            this.name = 'FileSystemError'
            this.message = message
            this.stack = new Error().stack
        }
    }

    /**
     * @constructor
     */
    class WixCodeNotWriteableError extends ReportableError {
        constructor() {
            super({
                message: 'Wix Code App is not writeable',
                errorType: 'WixCodeNotWriteableError'
            })
            this.name = 'WixCodeNotWriteableError'
            this.stack = new Error().stack
        }
    }

    class WixCodeSaveError extends Error {
        constructor({name, errorCode, errorType, errorDescription, severity, extra}) {
            super()
            this.name = name
            this.message = errorDescription
            this.errorCode = errorCode
            this.errorType = errorType
            this.errorDescription = errorDescription
            this.severity = severity
            this.extra = extra
        }
    }

    class WixCodeMarkImmutableError extends WixCodeSaveError {
        constructor(extra) {
            super({
                name: 'WixCodeMarkImmutableError',
                errorCode: -10138,
                errorType: 'WIX_CODE_FAILED_MARK_APP_IMMUTABLE',
                errorDescription: 'wix-code failed mark app immutable',
                severity: 'fatal',
                extra
            })
        }
    }

    class WixCodeCloneForRevisionError extends WixCodeSaveError {
        constructor(extra) {
            super({
                name: 'WixCodeCloneForRevisionError',
                errorCode: -10139,
                errorType: 'WIX_CODE_FAILED_CLONE_IMMUTABLE_APP',
                errorDescription: 'wix-code failed clone from immutable',
                severity: 'fatal',
                extra
            })
        }
    }

    class WixCodeDisabledSaveError extends Error {
        constructor() {
            super()
            this.name = 'WixCodeDisabledSaveError'
            this.message = 'Wix code cannot work in disabled save mode'
            this.errorCode = -10140
            this.errorType = 'WIX_CODE_DISABELD_SAVE'
            this.errorDescription = this.message
            this.severity = 'warning'
        }
    }

    class WixCodeMissingOpenGridAppError extends Error {
        constructor({openGridAppId, lastSavedGridAppId}) {
            super()
            this.name = 'WixCodeMissingOpenGridAppError'
            this.message = 'Editor loaded without an "open grid app" but has a grid app saved in the previous revision'
            this.errorCode = -10141
            this.errorType = 'WIX_CODE_MISSING_OPEN_GRID_APP'
            this.errorDescription = this.message
            this.severity = 'error'
            this.extra = {
                openGridAppId,
                lastSavedGridAppId
            }
        }
    }

    function sendBiError(bi, error) {
        try {
            bi.error(
                {
                    errorName: error.name || 'UNKNOWN_WIX_CODE_ERROR',
                    errorCode: error.errorCode || 77777,
                    desc: error.errorDescription,
                    severity: error.severity || 'error',
                    params: {
                        p1: 'extra'
                    }
                },
                {
                    extra: JSON.stringify(error.extra)
                }
            )
        } catch (_) {
            // don't break if failed sending bi
        }
    }

    return {
        ArgumentError,
        FileSystemError,
        WixCodeNotWriteableError,
        WixCodeMarkImmutableError,
        WixCodeCloneForRevisionError,
        WixCodeDisabledSaveError,
        WixCodeMissingOpenGridAppError,
        sendBiError
    }
})
