define(['lodash', 'immutable', 'documentServices/utils/visitableData', 'documentServices/saveAPI/extractDataDeltaFromSnapshotDiff'], function (
    _,
    Immutable,
    visitableData,
    extractDataDeltaFromSnapshotDiff
) {
    'use strict'

    const {
        createFromImmutable: createVisitableFromImmutable,
        createFromSnapshotDal: createVisitableFromSnapshotDal,
        createFromSnapshotDiff: createVisitableFromSnapshotDiff,
        createFromJS,
        createEmpty
    } = visitableData

    const pageStructureChanged = function (lastImmutablePagesData, currentImmutablePage, pageId) {
        const currentImmutablePageStructure = currentImmutablePage.get('structure')
        return (
            (!lastImmutablePagesData && currentImmutablePageStructure) ||
            !currentImmutablePageStructure.equals(lastImmutablePagesData.getIn([pageId, 'structure']))
        )
    }

    const createFromImmutable = (lastImmutableSnapshot, currentImmutableSnapshot) => {
        lastImmutableSnapshot = lastImmutableSnapshot || Immutable.fromJS({pagesData: {}})
        const currentImmutablePagesData = currentImmutableSnapshot.get('pagesData')
        const lastImmutablePagesData = lastImmutableSnapshot.get('pagesData')
        const semanticAppVerions = _.invoke(currentImmutableSnapshot.getIn(['semanticAppVersions']), 'toJS')
        const clientSpecMap = currentImmutableSnapshot.getIn(['rendererModel', 'clientSpecMap']).toJS()
        const routerConfigMap = _.invoke(currentImmutableSnapshot.getIn(['routers', 'configMap']), 'toJS')
        const appsState = _.invoke(currentImmutableSnapshot.getIn(['appsState']), 'toJS')
        const branchId = currentImmutableSnapshot.getIn(['documentServicesModel', 'branchId'])

        const updatedPages = currentImmutablePagesData.filter((currentImmutablePage, pageId) =>
            pageStructureChanged(lastImmutablePagesData, currentImmutablePage, pageId)
        )
        const deletedPages = lastImmutablePagesData.filter((lastImmutablePage, pageId) => !currentImmutablePagesData.getIn([pageId, 'structure']))
        const lastUpdatedPages = lastImmutablePagesData.filter((immutable, pageId) => !!updatedPages.get(pageId))
        const isMasterPageUpdated = !!updatedPages.get('masterPage')
        const updatedPagesVisitable = createVisitableFromImmutable(updatedPages)
        const currentPagesVisitable = createVisitableFromImmutable(currentImmutablePagesData)
        const lastPagesVisitable = createVisitableFromImmutable(lastUpdatedPages)
        const deletedPagesVisitable = createVisitableFromImmutable(deletedPages)
        return {
            clientSpecMap,
            isMasterPageUpdated,
            updatedPagesVisitable,
            currentPagesVisitable,
            lastPagesVisitable,
            deletedPagesVisitable,
            semanticAppVerions,
            routerConfigMap,
            appsState,
            branchId
        }
    }

    const createFromSnapshotDal = (lastSnapshotDal, currentSnapshotDal) => {
        const diff = currentSnapshotDal.diff(lastSnapshotDal)
        const {changedData, deletedData, changedDataPageIds, deletedDataPageIds} = extractDataDeltaFromSnapshotDiff(diff, lastSnapshotDal, currentSnapshotDal)
        const isMasterPageUpdated = _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
        return createFromSnapshotDiff(diff, lastSnapshotDal, currentSnapshotDal, changedData.document_data, deletedData.document_data, isMasterPageUpdated)
    }

    const createFromSnapshotDiff = (diff, lastSnapshotDal, currentSnapshotDal, changedData, deletedData, isMasterPageUpdated) => {
        const clientSpecMap = currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        const updatedPagesVisitable = createVisitableFromSnapshotDiff(diff)
        const currentPagesVisitable = createFromJS(currentSnapshotDal.toJSForType('data'))
        const lastPagesVisitable = lastSnapshotDal ? createVisitableFromSnapshotDal(_.keys(changedData), lastSnapshotDal) : createEmpty()
        const deletedPagesVisitable = lastSnapshotDal ? createVisitableFromSnapshotDal(_.keys(deletedData), lastSnapshotDal) : createEmpty()
        const semanticAppVerions = currentSnapshotDal.getValue({type: 'semanticAppVersions', id: 'semanticAppVersions'})
        const routerConfigMap = _.get(currentSnapshotDal.getValue({type: 'rendererModel', id: 'routers'}), 'configMap')
        const appsState = currentSnapshotDal.getValue({type: 'appsState', id: 'appsState'})
        const branchId = currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'})

        return {
            clientSpecMap,
            isMasterPageUpdated,
            updatedPagesVisitable,
            currentPagesVisitable,
            lastPagesVisitable,
            deletedPagesVisitable,
            semanticAppVerions,
            routerConfigMap,
            appsState,
            branchId
        }
    }

    return {
        createFromImmutable,
        createFromSnapshotDal,
        createFromSnapshotDiff
    }
})
