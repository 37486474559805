/**
 * Created by eitanr on 6/24/14.
 */
import _ from 'lodash'
import basic from './svgBasicScaler'
import parser from './svgPolylineParser'

export default {
    scale(polygonElement, scaleX, scaleY) {
        let parsedPolygonPoints = parser.getParsedPoints(polygonElement.getAttribute('points'))

        parsedPolygonPoints = _.map(parsedPolygonPoints, function (polygonPoint) {
            return _.map(polygonPoint, function (coord, index) {
                return basic.scaleSingleValue(coord, index ? scaleY : scaleX)
            })
        })

        return !_.isEmpty(parsedPolygonPoints)
            ? {
                  points: parser.stringifyPoints(parsedPolygonPoints)
              }
            : {}

        //polygonElement.setAttribute('points', parser.stringifyPoints(parsedPolygonPoints));
    }
}
