import viewsRenderer from '@wix/santa-ds-libs/src/wixappsCore/src/core/viewsRenderer'
import viewsCustomizer from '@wix/santa-ds-libs/src/wixappsCore/src/core/viewsCustomizer'
import proxyFactory from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxyFactory'
import logicFactory from '@wix/santa-ds-libs/src/wixappsCore/src/core/logicFactory'
import styleCollector from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleCollector'
import appPartCustomCss from '@wix/santa-ds-libs/src/wixappsCore/src/core/appPartCustomCss'
import styleData from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleData'
import ViewContextMap from '@wix/santa-ds-libs/src/wixappsCore/src/core/ViewContextMap'
import wixappsDataHandler from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsDataHandler'
import wixappsLogger from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsLogger'
import wixappsSantaTypesUtil from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsSantaTypesUtil'
import wixappsCoreSantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsCoreSantaTypesDefinitions'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'
import inputProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/inputProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import videoThumbDataHandler from '@wix/santa-ds-libs/src/wixappsCore/src/util/videoThumbDataHandler'
import baseCompositeProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseCompositeProxy'
import spacersCalculator from '@wix/santa-ds-libs/src/wixappsCore/src/util/spacersCalculator'
import wixappsConfiguration from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsConfiguration'
import linksConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/linksConverter'
import localizer from '@wix/santa-ds-libs/src/wixappsCore/src/util/localizer'
import FunctionLibrary from '@wix/santa-ds-libs/src/wixappsCore/src/core/expressions/functionLibrary'
import viewsUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/viewsUtils'
import memoizedViewsUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/memoizedViewsUtils'
import richTextUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/richTextUtils'
import wixappsUrlParser from '@wix/santa-ds-libs/src/wixappsCore/src/util/wixappsUrlParser'
import typeNameResolver from '@wix/santa-ds-libs/src/wixappsCore/src/util/typeNameResolver'
import styleMapping from '@wix/santa-ds-libs/src/wixappsCore/src/util/styleMapping'
import expression from '@wix/santa-ds-libs/src/wixappsCore/src/core/expressions/expression'
import wixappsCoreSantaTypes from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsCoreSantaTypes'
import wixappsProxySantaTypes from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsProxySantaTypes'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import * as skinsPackage from '../../_internal/skins'
// @ts-ignore
import skinsJson from '@wix/santa-ds-libs/src/wixappsCore/src/skins/skins.json'
import '@wix/santa-ds-libs/src/wixappsCore/src/proxies/proxyMap'
import '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsDataAspect'
// import 'wixappsLayout'
import '@wix/santa-ds-libs/src/wixappsCore/src/components/icon/icon'
import '@wix/santa-ds-libs/src/wixappsCore/src/components/toggle/toggle'
import '@wix/santa-ds-libs/src/wixappsCore/src/components/wixAppsImageButton/wixAppsImageButton'
import '@wix/santa-ds-libs/src/wixappsCore/src/components/imageButtonWithText/imageButtonWithText'
// import 'textArea'
// import 'imageZoom'
// import 'matrixGallery'
// import 'slideShowGallery'
// import 'comboBoxInput'
// import 'dialogs'

skinsPackage.skinsMap.addBatch(skinsJson)
componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixappsCoreSantaTypes', wixappsCoreSantaTypes)
componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('wixappsProxySantaTypes', wixappsProxySantaTypes)

/**
 * @class wixappsCore
 */
export default {
    viewsRenderer,
    viewsCustomizer,
    proxyFactory,
    baseProxy,
    inputProxy,
    baseCompositeProxy,
    typesConverter,
    logicFactory,
    ViewContextMap,
    CoreSantaTypes: wixappsCoreSantaTypesDefinitions,
    wixappsDataHandler,
    wixappsLogger,
    wixappsSantaTypesUtil,
    videoThumbDataHandler,
    styleCollector,
    appPartCustomCss,
    spacersCalculator,
    wixappsConfiguration,
    linksConverter,
    localizer,
    FunctionLibrary,
    viewsUtils,
    memoizedViewsUtils,
    wixappsUrlParser,
    typeNameResolver,
    styleData,
    richTextUtils,
    convertStringToPrimitive: expression.convertStringToPrimitive,
    styleMapping,
    expression: {
        evaluate: expression.evaluate,
        isExpression: expression.isExpression
    }
}
