define([], function () {
    'use strict'

    return {
        mobileConversionConfig: {
            category: 'graphic',
            isSuitableForProportionGrouping: true
        },
        rotatable: true
    }
})
