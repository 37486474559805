import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const {
        getStylableEditorInstance,
        update,
        createEmptyStyle,
        removeRefIfEmptyStyle,
        setQuickChange,
        revertQuickChange,
        forceState,
        revertForceState,
        mergeStylesheets
    } = viewerLibrary.get('documentServices/stylableEditor/stylableEditorAPI')
    return {
        methods: {
            components: {
                stylable: {
                    update: publicMethodUtils.defineDataManipulationAction(update),
                    createEmptyStyle: publicMethodUtils.defineDataManipulationAction(createEmptyStyle),
                    removeRefIfEmptyStyle: publicMethodUtils.defineDataManipulationAction(removeRefIfEmptyStyle),
                    getEditorInstance: publicMethodUtils.defineGetter(getStylableEditorInstance),
                    quickChange: publicMethodUtils.defineAction(setQuickChange),
                    revertQuickChange: publicMethodUtils.defineAction(revertQuickChange),
                    forceState: publicMethodUtils.defineAction(forceState),
                    revertForceState: publicMethodUtils.defineAction(revertForceState),
                    mergeStylesheets: publicMethodUtils.defineGetter(mergeStylesheets)
                }
            }
        }
    }
}
