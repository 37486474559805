/**
 * Created by eitanr on 8/20/14.
 */
import _ from 'lodash'
import skinsMap from './skinsMap'
import defaultEvaluators from './evals'
import skinUtils from '@wix/santa-skin-utils'

function renderParam(paramName, skinData, styleData, colors) {
    return skinUtils.renderParam(paramName, skinData, styleData, colors, defaultEvaluators)
}

function getSkinDefaultParams(skinName) {
    const skinData = skinsMap.get(skinName)
    return skinData.paramsDefaults
}

function getSkinExports(skinName) {
    const skinData = skinsMap.get(skinName)
    return _.get(skinData, 'exports')
}

export default {
    /***
     *
     * @param paramName
     * @param skinData
     * @param styleData
     * @param themeData
     * @param evals
     * @returns {*}
     */
    renderParam,
    /**
     * @param skinName
     * @returns {*}
     */
    getSkinDefaultParams,
    /**
     * @param skinName
     * @returns {*}
     */
    getSkinExports
}
