import imageZoom from './components/imageZoom'
import imageZoomDisplayer from './components/imageZoomDisplayer'
import mediaZoom from './components/mediaZoom'
import mobileMediaZoom from './components/mobileMediaZoom'
import touchMediaZoom from './components/touchMediaZoom'
import touchMediaZoomItem from './components/touchMediaZoomItem'
import * as skinsPackage from '@wix/santa-ds-libs/src/_internal/skins'
// @ts-ignore
import skinsJson from './skins/skins.json'

skinsPackage.skinsMap.addBatch(skinsJson)

export const imageZoomIndex = {
    imageZoom,
    imageZoomDisplayer,
    mediaZoom,
    mobileMediaZoom,
    touchMediaZoom,
    touchMediaZoomItem
}
