/**
 * Created by eitanr on 6/25/14.
 */
import basic from './svgBasicScaler'

export default {
    scale(circleElement, scaleX, scaleY) {
        const cxAttribute = circleElement.getAttribute('cx')
        const cyAttribute = circleElement.getAttribute('cy')
        const rAttribute = circleElement.getAttribute('r')
        const actualScale = Math.min(scaleX, scaleY)

        return cxAttribute && cyAttribute
            ? {
                  cx: basic.scaleSingleValue(cxAttribute, actualScale),
                  cy: basic.scaleSingleValue(cyAttribute, actualScale),
                  r: basic.scaleSingleValue(rAttribute, actualScale)
              }
            : {}
    }
}
