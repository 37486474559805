define(['documentServices/dataModel/dataModel', 'documentServices/constants/constants'], function (dataModel, constants) {
    'use strict'

    return {
        isRepeatable: false,
        resizableSides(ps, componentPointer) {
            const compProps = dataModel.getPropertiesItem(ps, componentPointer)
            const sides = [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]

            if (compProps.orientation === 'horizontal') {
                return sides.concat([constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM])
            }

            return sides
        }
    }
})
