import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import versionUtils from './versionUtils'
import media from './media'

const commonFontUtils = coreUtilsLib.fonts
const joinUrl = coreUtilsLib.urlUtils.joinURL
const GOOGLE_FONT_SERVICE_URL = '//fonts.googleapis.com/css?family='
const POSSIBLE_CHARACTERS_SETS = ['latin-ext', 'cyrillic', 'japanese', 'korean', 'arabic', 'hebrew', 'latin']
const ALL_FORMATS = ['woff2', 'woff', 'ttf', 'svg', 'eot']
const LANGUAGES_CSS_RELATIVE_PATH = 'user-site-fonts/v16'
const fontSupportTable = [
    {browser: 'chrome', os: 'android', firstSupportedVersions: {woff2: '66', woff: '66', ttf: '66'}},
    {browser: 'chrome', os: 'mac', firstSupportedVersions: {woff2: '36', woff: '5', ttf: '4'}},
    {browser: 'safari', os: 'iphone', firstSupportedVersions: {woff2: '10.2', woff: '5.1', ttf: '4.3', svg: '3.2'}},
    {browser: 'safari', os: 'mac', firstSupportedVersions: {woff: '5.1', ttf: '3.1', svg: '3.2'}}
]
const stylesWithSpecificFontFaces = [
    {id: 'languages', supportLevel: 'woff2', relativePath: joinUrl(LANGUAGES_CSS_RELATIVE_PATH, 'languages-woff2.css')},
    {id: 'languages', supportLevel: 'woff', relativePath: joinUrl(LANGUAGES_CSS_RELATIVE_PATH, 'languages-woff.css')},
    {id: 'languages', supportLevel: 'ttf', relativePath: joinUrl(LANGUAGES_CSS_RELATIVE_PATH, 'languages-truetype.css')}
]

const styleFontsRegex = /<[^>]+class="[^"]*(font_[0-9]+)[^"]*"/g

function getMetadata(fontNames) {
    return _.compact(_.map(fontNames, fontName => commonFontUtils.getFontsMetaData()[fontName]))
}

function getGoogleFontsUrl(fonts, characterSets) {
    if (fonts.length === 0) {
        return undefined
    }

    const fontQuery = _.map(fonts, font => `${font.cdnName}:n,b,i,bi|`)
    if (characterSets) {
        fontQuery.push('&subset=')
        fontQuery.push(characterSets.join(','))
    }

    return GOOGLE_FONT_SERVICE_URL + fontQuery.join('')
}

function getPageFontsMetaData(siteData, pageId) {
    const usedFontsList = _.filter(getPageUsedFontsList(siteData, pageId), fontFamilyName => _.has(commonFontUtils.getFontsMetaData(), fontFamilyName))
    return getMetadata(usedFontsList)
}

function getPageUsedFontsList(siteData, pageId) {
    // @ts-ignore
    return computePageUsedFontsList(siteData, pageId)
}

function collectFontFamiliesFromStyles(siteData) {
    const styles = _.times(_.size(siteData.getFontsMap()), n => `font_${n}`)
    return getFontFamilyFromStyleIds(siteData, styles)
}

function computePageUsedFontsList(siteData) {
    const currentPageData = siteData.getDataByQuery(siteData.MASTER_PAGE_ID, siteData.MASTER_PAGE_ID, siteData.dataTypes.DATA)
    const fontFamiliesFromSiteStructure = _.get(currentPageData, ['usedFonts'], [])

    return _.union(collectFontFamiliesFromStyles(siteData), fontFamiliesFromSiteStructure)
}

function getFontFamilyFromStyleIds(siteData, fontStylesIds) {
    const generalThemeData = siteData.getGeneralTheme()
    return _.map(fontStylesIds, fontStyleId => commonFontUtils.getFontFamilyByStyleId(generalThemeData, fontStyleId))
}

function getWixStoredFontsCssUrls(serviceTopology, documentType) {
    const fontUrls = getWixStaticsFontsLinks(serviceTopology, documentType)
    return _.values(fontUrls)
}

function getFontsUrlWithParams(fontNamesObject, documentType, characterSets) {
    const fontsFamiliesArray = _.isArray(fontNamesObject) ? fontNamesObject : _.keys(fontNamesObject)
    const query = getFontsQuery(fontsFamiliesArray, documentType, characterSets)
    if (query) {
        return GOOGLE_FONT_SERVICE_URL + query
    }

    return ''
}

function getFontsPermissions(documentType) {
    const permissions = ['all', 'legacy']
    if (documentType === 'WixSite') {
        permissions.push('studio')
    }
    return permissions
}

function getFontsQuery(fontsFamiliesArray, documentType, characterSets) {
    let fontQuery = ''
    const permissions = getFontsPermissions(documentType)
    _.forEach(fontsFamiliesArray, function (fontFamily) {
        const font = commonFontUtils.getFontsMetaData()[fontFamily]
        if (font && font.cdnName && _.includes(permissions, font.permissions)) {
            fontQuery += font.cdnName
            fontQuery += ':n,b,i,bi|'
        }
    })
    if (fontQuery === '') {
        return null
    }
    fontQuery = _.trimEnd(fontQuery, '|')
    if (characterSets) {
        fontQuery += `&subset=${characterSets.join(',')}`
    }
    return fontQuery
}

function getFontClassName(text) {
    styleFontsRegex.lastIndex = 0
    const match = styleFontsRegex.exec(text)
    return match ? match[1] : undefined
}

//seems that no one is using this, and the url is not updated
function getWixHelveticaUrl(serviceTopology) {
    return `${serviceTopology.publicStaticsUrl}/css/Helvetica/fontFace.css`
}

function getWixStaticsFontsLinks(serviceTopology, documentType) {
    const result: Record<string, any> = {
        languages: media.getMediaUrl(serviceTopology, joinUrl(LANGUAGES_CSS_RELATIVE_PATH, 'languages.css'))
    }
    if (documentType === 'WixSite') {
        result.helveticas = media.getMediaUrl(serviceTopology, 'user-site-fonts/v7/helvetica.css')
        result.wixmadefor = media.getMediaUrl(serviceTopology, 'user-site-fonts/v16/wixMadefor.css')
    }
    return result
}

function getCssUrls(documentType, serviceTopology) {
    const cssLinks: Record<string, any> = {}

    cssLinks.googleFonts = getFontsUrlWithParams(commonFontUtils.getFontsMetaData(), documentType, POSSIBLE_CHARACTERS_SETS)
    _.merge(cssLinks, getWixStaticsFontsLinks(serviceTopology, documentType))

    return cssLinks
}

function getBrowserFontSupport(browser, os) {
    const fontSupportEntry = _.find(fontSupportTable, fs => browser[fs.browser] && os[fs.os])
    if (!fontSupportEntry) {
        return null
    }

    return _.find(ALL_FORMATS, format => {
        // @ts-ignore
        const firstSupportedVersion = _.get(fontSupportEntry.firstSupportedVersions, format)
        return firstSupportedVersion && versionUtils.versionGreaterEqual(browser.version, firstSupportedVersion)
    })
}

function getStyleWithSpecificFontFaces(serviceTopology, id, supportLevel) {
    const specificFontFaces = _.find(stylesWithSpecificFontFaces, {id, supportLevel})
    if (!specificFontFaces) {
        return null
    }

    return media.getMediaUrl(serviceTopology, specificFontFaces.relativePath)
}

export default {
    parseFontStr: commonFontUtils.parseFontStr,
    parseStyleFont: commonFontUtils.parseStyleFont,
    getMetadata,
    getGoogleFontsUrl,
    getFontFamily: commonFontUtils.getFontFamily,
    getFontFallback: commonFontUtils.getFontFallback,
    getPageFontsMetaData,
    getFontFamilyWithFallbacks: commonFontUtils.getFontFamilyWithFallbacks,
    getWixStoredFontsCssUrls,
    getWixStoredFontsCssUrlsWithParams: commonFontUtils.getWixStoredFontsCssUrlsWithParams,
    getCurrentSelectablefontsWithParams: commonFontUtils.getCurrentSelectablefontsWithParams,
    getFontsUrlWithParams: commonFontUtils.getFontsUrlWithParams,
    getThemeFontsCss: commonFontUtils.getThemeFontsCss,
    getFontClassName,
    getPageUsedFontsList,
    getWixHelveticaUrl,
    getCssUrls,
    getBrowserFontSupport,
    getStyleWithSpecificFontFaces,
    fontToCSSWithColor: commonFontUtils.fontToCSSWithColor,
    getFontFamilyPermissions: commonFontUtils.getFontFamilyPermissions
}
