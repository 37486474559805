import _ from 'lodash'

function ensureLeadingSharp(str) {
    return str.startsWith('#') ? str : `#${str}`
}

function jsonToCss(obj) {
    const styleRulesArray = _.map(obj, function (val, key) {
        return `${key}:${val}`
    })
    return `{${styleRulesArray.join(';')}}`
}

function renderSingleRule(compId, viewId, ruleDef) {
    const prefix = `${compId} ${viewId} `

    const ret = []
    _.forOwn(ruleDef, function (rules, classNames) {
        _.forEach(classNames.split(/\s*,\s*/), function (cssClass) {
            const ruleStr = prefix + cssClass + jsonToCss(rules)
            ret.push(ruleStr)
        })
    })

    return ret
}

function render(stylesheetDef, compId, viewId) {
    compId = ensureLeadingSharp(compId)
    viewId = ensureLeadingSharp(viewId)

    if (_.isEmpty(stylesheetDef)) {
        return null
    }
    if (!_.isArray(stylesheetDef)) {
        throw new Error('stylesheetRenderer:: stylesheet definition must be an array')
    }

    return _.flattenDeep(_.map(stylesheetDef, renderSingleRule.bind(null, compId, viewId))).join('')
}

/**
 * @class wixappsCore.stylesheetRenderer
 */
export default {
    render
}
