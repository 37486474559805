define([
    'lodash',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/dataModel/dataModel',
    'documentServices/page/pageUtils',
    'documentServices/page/popupUtils',
    'documentServices/constants/constants',
    'documentServices/routers/routersGetters',
    'documentServices/hooks/hooks',
    'documentServices/siteMetadata/language',
    'documentServices/menu/menuUtils',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/multilingual',
    'documentServices/utils/utils'
], function (_, utils, dataModel, pageUtils, popupUtils, constants, routersGetters, hooks, language, menuUtils, extensionsAPI, mlUtils, dsUtils) {
    'use strict'

    const {DATA_TYPES} = utils.constants
    const DEVICES = ['desktop', 'mobile']

    function getFilteredPagesList(ps, includeMasterPage, getOnlyPopups) {
        const allPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
        const whatIsPage = function (pageId) {
            const isPopup = popupUtils.isPopup(ps, pageId)
            return getOnlyPopups ? isPopup : !isPopup
        }

        return _(allPagesPointers).map('id').filter(whatIsPage).value()
    }

    function getPagesAndPopupsList(ps, includeMasterPage) {
        const allPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
        return _.map(allPagesPointers, 'id')
    }

    function updatePageBackgrounds(ps, pageId, data) {
        const pageDataInMasterPagePointer = ps.pointers.data.getDataItemFromMaster(pageId)
        const pageDataItem = dataModel.serializeDataItem(ps, DATA_TYPES.data, pageDataInMasterPagePointer, false)

        if (data.pageBackgrounds) {
            _.forEach(DEVICES, function (device) {
                const deviceBackground = data.pageBackgrounds[device]
                if (deviceBackground && deviceBackground.ref) {
                    let refId

                    if (pageDataItem) {
                        const currentRefId = _.get(pageDataItem, ['pageBackgrounds', device, 'ref'])
                        const newRefId = _.get(data, ['pageBackgrounds', device, 'ref', 'id'])

                        // Saving homepage data has quirks, it has a duplicated data item on both page and masterPage structures.
                        // Basically - We need to trick the server to save the data on both page and masterPage structures
                        // or we get data divergence between to dataItems with the same id but different contents.
                        // see https://jira.wixpress.com/browse/SE-6816
                        refId = newRefId ? newRefId : currentRefId && dsUtils.stripHashIfExists(currentRefId)
                    } else {
                        refId = `${pageId}_${device}_bg`
                    }

                    deviceBackground.ref = `#${dataModel.addSerializedDataItemToPage(ps, pageId, deviceBackground.ref, refId)}`
                }
            })

            data.pageBackgrounds = _.assign((pageDataItem && pageDataItem.pageBackgrounds) || {}, data.pageBackgrounds)
        }

        return data
    }

    function getPageDataById(ps, pageId, deleteIds, useOriginalLanguage = false) {
        const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
        const dataItem = dataModel.serializeDataItem(ps, DATA_TYPES.data, pageDataItemPointer, deleteIds, useOriginalLanguage)

        if (dataItem && dataItem.pageBackgrounds) {
            _.forEach(DEVICES, function (device) {
                const deviceBackground = dataItem.pageBackgrounds[device]
                if (deviceBackground && deviceBackground.ref) {
                    const devicePointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(deviceBackground.ref), pageId)
                    deviceBackground.ref = dataModel.serializeDataItem(ps, DATA_TYPES.data, devicePointer, deleteIds, useOriginalLanguage)
                }
            })
        }
        return dataItem
    }

    function doesRouterPrefixExist(ps, prefix) {
        const routerRef = routersGetters.get.byPrefix(ps, prefix)
        return !!routerRef
    }

    function isDuplicatePageUriSeo(ps, excludePageId, pageUriSEO) {
        if (doesRouterPrefixExist(ps, pageUriSEO)) {
            return true
        }

        const pageIds = pageDataModule.getPagesList(ps, false, true)
        return _(pageIds)
            .pull(excludePageId)
            .some(pageId => pageDataModule.getPageUriSEO(ps, pageId) === pageUriSEO)
    }

    function getDuplicatePageTitle(ps, excludePageId, pageUriSEO) {
        const routerRef = routersGetters.get.byPrefix(ps, pageUriSEO)
        if (routerRef) {
            return pageUriSEO
        }

        const pageIds = pageDataModule.getPagesList(ps, false)
        const duplicatePageId = _(pageIds)
            .pull(excludePageId)
            .find(pageId => pageDataModule.getPageUriSEO(ps, pageId) === pageUriSEO)

        if (duplicatePageId) {
            return getPageInnerItem(ps, duplicatePageId, 'title')
        }
    }

    function isForbiddenPageUriSeo(ps, pageId, pageUriSEO) {
        const forbiddenWords = pageDataModule.getForbiddenPageUriSEOs(ps)
        const currentPageUriSEO = pageDataModule.getPageUriSEO(ps, pageId)

        delete forbiddenWords[currentPageUriSEO]

        return forbiddenWords.hasOwnProperty(pageUriSEO.toLowerCase())
    }

    function isPageUriSeoTooLong(pageUriSEO, marginForError) {
        marginForError = marginForError || 0
        return _.size(pageUriSEO) > constants.URLS.MAX_LENGTH - marginForError
    }

    function hasIllegalChars(pageUriSEO) {
        return pageUtils.uriHasIllegalCharacters(pageUriSEO)
    }

    function getPairsCheckValue(pairs, pageUriSEO) {
        return _.chain(pairs)
            .find(pair => pair[0](pageUriSEO))
            .get(1, '')
            .value()
    }

    function getPageUriSeoContentErrorMessage(ps, pageId, pageUriSEO) {
        return getPairsCheckValue(
            [
                [isDuplicatePageUriSeo.bind(null, ps, pageId), 'pageUriSEO is invalid: not unique across site'],
                [isForbiddenPageUriSeo.bind(null, ps, pageId), 'pageUriSEO is invalid: reserved word']
            ],
            pageUriSEO
        )
    }

    function getPageUriSeoFormatErrorMessage(pageUriSEO) {
        return getPairsCheckValue(
            [
                [isPageUriSeoTooLong, `pageUriSEO is invalid: over ${constants.URLS.MAX_LENGTH} chars`],
                [hasIllegalChars, 'pageUriSEO is invalid: must only be alphanumeric or hyphen']
            ],
            pageUriSEO
        )
    }

    function getPageUriSeoErrorMessage(ps, pageId, pageUriSEO) {
        const urlFormatPointer = ps.pointers.general.getUrlFormat()
        const urlFormat = ps.dal.get(urlFormatPointer)

        if (urlFormat === utils.siteConstants.URL_FORMATS.HASH_BANG) {
            return ''
        }

        return getPageUriSeoContentErrorMessage(ps, pageId, pageUriSEO) || getPageUriSeoFormatErrorMessage(pageUriSEO)
    }

    function validatePageUriSeoAllowed(ps, pageId, pageUriSEO) {
        const error = getPageUriSeoErrorMessage(ps, pageId, pageUriSEO)

        if (!_.isEmpty(error)) {
            throw new Error(error)
        }
    }

    const isValidPageUriSeo = (ps, pageId, pageUriSEO) => {
        const error = getPageUriSeoErrorMessage(ps, pageId, pageUriSEO)

        return _.isEmpty(error)
    }

    const SAFE_PADDING_FOR_URI_LENGTH = 5

    function getValidPageUriSEO(ps, pageId, initialPageUriSEO) {
        initialPageUriSEO = pageUtils.convertPageNameToUrl(ps, initialPageUriSEO)
        if (isPageUriSeoTooLong(initialPageUriSEO, SAFE_PADDING_FOR_URI_LENGTH)) {
            initialPageUriSEO = initialPageUriSEO.slice(0, constants.URLS.MAX_LENGTH - SAFE_PADDING_FOR_URI_LENGTH) //allow extra space for duplicates so we can append an index
        }
        let pageUriSEO = initialPageUriSEO
        for (let index = 1; getPageUriSeoContentErrorMessage(ps, pageId, pageUriSEO); index++) {
            pageUriSEO = `${initialPageUriSEO}-${index}`
        }
        return pageUriSEO
    }

    function getValidatedAndUpdatedPageData(ps, pageId, data) {
        if (_.isString(data.pageUriSEO) && _.isEmpty(data.pageUriSEO)) {
            data.pageUriSEO = getValidPageUriSEO(ps, pageId, data.title) //convertPageNameToUrl handles blank titles
        }
        if (data.pageUriSEO) {
            validatePageUriSeoAllowed(ps, pageId, data.pageUriSEO)
            if (!_.get(data, ['translationData', 'uriSEOTranslated'])) {
                data.translationData = {
                    uriSEOTranslated: false
                }
            }
        }
        return updatePageBackgrounds(ps, pageId, data)
    }

    /**
     * Adds page data to an existing page
     * @param  {ps} ps
     * @param  {String} pageId
     * @param  {object} data
     * @param  {boolean} useOriginalLanguage
     * @returns undefined
     */
    function addPageData(ps, pageId, data, useOriginalLanguage = false) {
        data = getValidatedAndUpdatedPageData(ps, pageId, data)
        if (data.pageUriSEO) {
            language.patchPageTranslations(ps, pageId, {pageUriSEO: data.pageUriSEO})
        }
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
        dataModel.addSerializedDataItemToPage(ps, constants.MASTER_PAGE_ID, data, pageId, useLanguage)
    }

    /**
     * Copies translation data from one page to another (keeps it in the original page)
     * @param  {ps} ps
     * @param  {string} pageId
     * @param  {string} newPageId
     * @param  {boolean} isPopup
     */
    function copyTranslationData(ps, pageId, newPageId, isPopup = false) {
        if (!language.multilingual.isEnabled(ps)) {
            return
        }

        const newPageData = ps.dal.full.get(ps.pointers.data.getDataItemFromMaster(newPageId))
        const newTranslationData = {
            id: newPageId,
            pageBackgrounds: _.cloneDeep(newPageData.pageBackgrounds)
        }
        language.copyDataItemTranslations(ps, constants.MASTER_PAGE_ID, pageId, constants.MASTER_PAGE_ID, newTranslationData)
        copyPageComponentsTranslationData(ps, pageId, newPageId)
        copyPageBackgroundTranslationData(ps, pageId, newPageId)

        if (!isPopup) {
            copyPageMenuItemTranslationData(ps, pageId, newPageId)
        }
    }

    function copyPageComponentsTranslationData(ps, pageId, newPageId) {
        const sourcePagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const newPagePointer = ps.pointers.components.getPage(newPageId, constants.VIEW_MODES.DESKTOP)
        const dataItemIdMap = _.zipObject(_getPageComponentsDataIds(ps, sourcePagePointer), _getPageComponentsDataIds(ps, newPagePointer))

        const translationData = language.component.getTranslations(ps, sourcePagePointer)
        language.copyTranslationData(ps, newPagePointer, translationData, dataItemIdMap)
    }

    function _getPageComponentsDataIds(ps, pagePointer) {
        return _(ps.pointers.components.getChildrenRecursively(pagePointer))
            .map(componentPointer => dataModel.getComponentDataItemId(ps, componentPointer, 'dataQuery'))
            .reject(_.isNull)
            .value()
    }

    function copyPageBackgroundTranslationData(ps, pageId, newPageId) {
        const {pageBackgrounds} = ps.dal.full.get(ps.pointers.data.getDataItemFromMaster(pageId))
        const {pageBackgrounds: newPageBackgrounds} = ps.dal.full.get(ps.pointers.data.getDataItemFromMaster(newPageId))

        const translationsPageId = pageId
        const newTranslationsPageId = newPageId

        _.forEach(constants.DEVICES, device => {
            if (!pageBackgrounds[device] || !pageBackgrounds[device].ref) {
                return
            }
            const dataItemId = dsUtils.stripHashIfExists(pageBackgrounds[device].ref)
            const newTranslationData = {id: dsUtils.stripHashIfExists(newPageBackgrounds[device].ref)}
            language.copyDataItemTranslations(ps, translationsPageId, dataItemId, newTranslationsPageId, newTranslationData)
            duplicateBackgroundMediaItemsForTranslations(ps, translationsPageId, newTranslationsPageId, newTranslationData.id)
        })
    }

    function duplicateBackgroundMediaItemsForTranslations(ps, originalItemPageId, backgroundDataPageId, backgroundDataItemId) {
        language.patchDataItemTranslations(
            ps,
            backgroundDataPageId, //changed to page instead of masterPage
            backgroundDataItemId,
            translationData => {
                if (!translationData.mediaRef) {
                    return translationData
                }
                const mediaDataItemId = dsUtils.stripHashIfExists(translationData.mediaRef)
                //mediaDataPointer is referring to the original item, which is referenced by the new 'copied' translation item, and is now being fixed.
                //at the bottom of the function we patch the mediaRef to be the new data item, which exists in the new page
                const mediaDataPointer = ps.pointers.data.getDataItem(mediaDataItemId, originalItemPageId)
                if (!ps.dal.fullJsonDal.isExist(mediaDataPointer)) {
                    return translationData
                }
                const mediaDataItem = dataModel.serializeDataItem(ps, DATA_TYPES.data, mediaDataPointer, true)
                const newMediaDataItemId = dataModel.addSerializedDataItemToPage(ps, backgroundDataPageId, mediaDataItem)
                return _.assign({}, translationData, {mediaRef: `#${newMediaDataItemId}`})
            }
        )
    }

    function copyPageMenuItemTranslationData(ps, pageId, newPageId) {
        const menuItemPointer = menuUtils.getPageMenuItemPointer(ps, pageId)
        const newMenuItemPointer = menuUtils.getPageMenuItemPointer(ps, newPageId)
        const newTranslationData = {
            id: newMenuItemPointer.id,
            link: menuUtils.getLinkIdByMenuItemId(ps, newMenuItemPointer.id)
        }
        language.copyDataItemTranslations(ps, constants.MASTER_PAGE_ID, menuItemPointer.id, constants.MASTER_PAGE_ID, newTranslationData)
    }

    function getPageInnerItem(ps, pageId, key) {
        const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
        const pageInnerItemPointer = ps.pointers.getInnerPointer(pageDataItemPointer, key)

        return ps.dal.get(pageInnerItemPointer)
    }

    /** @class documentServices.pages*/

    const pageDataModule = {
        getStaticPagesCount(ps) {
            const dynamicPagesIdMap = {}
            _.values(routersGetters.get.all(ps)).forEach(r => {
                _.values(r.pages).forEach(pageId => {
                    dynamicPagesIdMap[pageId] = true
                })
            })
            const pageIds = pageDataModule.getPagesList(ps, false)
            let staticPageCount = 0
            pageIds.forEach(pageId => {
                const pointer = ps.pointers.data.getDataItemFromMaster(pageId)
                const pageData = extensionsAPI.data.getFullNoClone(ps, pointer)
                const isStaticPage = !dynamicPagesIdMap[pageData.id] && !pageData.isPopup && !pageData.tpaApplicationId && !pageData.managingAppDefId
                if (isStaticPage) {
                    ++staticPageCount
                }
            })
            return staticPageCount
        },

        getPagesDataItems(ps) {
            const pageIds = pageDataModule.getPagesList(ps, false)

            return _.map(pageIds, pageId => pageDataModule.getPageData(ps, pageId))
        },

        getPopupsDataItems(ps) {
            return pageDataModule.getPopupsList(ps).map(popupId => pageDataModule.getPageData(ps, popupId))
        },

        getNumberOfPages(ps) {
            return pageDataModule.getPagesList(ps, false).length
        },

        getPagesList(ps, includeMasterPage, includingPopups) {
            if (includingPopups) {
                return getPagesAndPopupsList(ps, includeMasterPage)
            }
            return getFilteredPagesList(ps, includeMasterPage, false)
        },

        getPopupsList(ps) {
            return getFilteredPagesList(ps, false, true)
        },

        doesPageExist(ps, pageId) {
            const pagePointer = ps.pointers.page.getPagePointer(pageId)

            return Boolean(pagePointer)
        },

        addPageData(ps, pageId, data, shouldAddMenuItem = true) {
            addPageData(ps, pageId, data)
            pageUtils.executePageAddedCallback(ps, pageId, shouldAddMenuItem)
        },

        setPageData(ps, pageId, data, useOriginalLanguage = false, applyChangeToAllLanguages = false) {
            addPageData(ps, pageId, data, useOriginalLanguage)
            pageUtils.executePageDataChangedCallback(ps, pageId, data, useOriginalLanguage, applyChangeToAllLanguages)
        },

        getPageData(ps, pageId, useOriginalLanguage = false) {
            return getPageDataById(ps, pageId, false, useOriginalLanguage)
        },

        getPageDataWithoutIds(ps, pageId, useOriginalLanguage = false) {
            return getPageDataById(ps, pageId, true, useOriginalLanguage)
        },

        getBgDataItem(ps, pageId, device) {
            if (!_.includes(DEVICES, device)) {
                throw new Error('unknown device for background')
            }
            const pageData = pageDataModule.getPageData(ps, pageId)
            return _.get(pageData, ['pageBackgrounds', device])
        },

        updateBgDataItem(ps, pageId, bgData, device) {
            if (!_.includes(DEVICES, device)) {
                throw new Error('unknown device for background')
            }
            const data = {pageBackgrounds: {}}
            data.pageBackgrounds[device] = _.cloneDeep(bgData)
            pageDataModule.setPageData(ps, pageId, data)

            hooks.executeHook(hooks.HOOKS.SITE_BACKGROUND_UPDATE.AFTER, null, [ps, pageId, device])
        },

        removePageData(ps, dataPointer) {
            const pageDataItem = ps.dal.get(dataPointer)
            const pageId = ps.pointers.data.getPageIdOfData(dataPointer)
            if (pageDataItem.pageBackgrounds) {
                _.forEach(DEVICES, function (device) {
                    const deviceBackground = pageDataItem.pageBackgrounds[device]
                    if (deviceBackground && deviceBackground.ref) {
                        const innerDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(deviceBackground.ref), pageId)
                        dataModel.removeItemRecursivelyByType(ps, innerDataPointer)
                    }
                })
            }
            dataModel.removeItemRecursivelyByType(ps, dataPointer)
        },

        getPageUriSEO(ps, pageId) {
            return getPageInnerItem(ps, pageId, 'pageUriSEO')
        },

        getForbiddenPageUriSEOs(ps) {
            const forbiddenWordsPointer = ps.pointers.general.getForbiddenPageUriSEOs()
            return ps.dal.get(forbiddenWordsPointer) || {}
        },

        isDuplicatePageUriSeo,
        getDuplicatePageTitle,
        isForbiddenPageUriSeo,
        getValidPageUriSEO,
        isPageUriSeoTooLong,
        hasIllegalChars,
        isValidPageUriSeo,
        copyTranslationData,
        convertPageNameToUrl: pageUtils.convertPageNameToUrl
    }

    return pageDataModule
})
