define(['lodash', 'documentServices/wixapps/utils/pathUtils'], function (_, pathUtils) {
    'use strict'

    function normalizeDate(ps, now) {
        const path = pathUtils.getOffsetFromServerTimePath()
        const offset = ps.wixappsDAL.getByPath(path)
        return new Date(now - offset).toISOString()
    }

    return {
        getCurrentTime(ps) {
            return normalizeDate(ps, _.now())
        },
        normalizeDate
    }
})
