/**
 * This fixer changes existing hoverboxes that do not have design overrides for both modes
 * This is needed since Bolt, as setting a design value does not create a new override, ubt rather only update existing overrides
 */

define(['lodash', 'documentServices/constants/constants', 'documentServices/dataModel/dataModel', 'documentServices/utils/utils'], function (
    _,
    constants,
    dataModel,
    dsUtils
) {
    'use strict'

    const hoverboxType = 'wysiwyg.viewer.components.HoverBox'
    const regularModeType = 'DEFAULT'
    const hoverModeType = 'HOVER'

    const getPageCompPointer = (ps, pagePointer) => ps.pointers.components.getPage(pagePointer.id, constants.VIEW_MODES.DESKTOP)
    const getComponentType = (ps, pointer) => ps.dal.get(ps.pointers.getInnerPointer(pointer, 'componentType'))

    const cleanRef = ref => dsUtils.stripHashIfExists(ref)

    const getDefinitionForMode = (structure, modeType) => _.find(structure.modes.definitions, mode => mode.type === modeType) || {}

    const getOverrideAndAddIfMissing = (structure, modeType) => {
        const {modeId} = getDefinitionForMode(structure, modeType)
        let override = _.find(structure.modes.overrides, mode => mode.modeIds.includes(modeId))
        if (!override) {
            override = {modeIds: [modeId]} // Nested modes were not implemented, and never will
            const overrides = structure.modes.overrides || []
            overrides.push(override)
            structure.modes.overrides = overrides
        }

        return override
    }

    const duplicateDesignItem = (ps, dataItemId, pageId) => {
        const regularDesignPointer = ps.pointers.data.getDesignItem(dataItemId, pageId)
        const serializedDesign = dataModel.getDataByPointer(ps, constants.DATA_TYPES.design, regularDesignPointer, true)

        return dataModel.addSerializedDesignItemToPage(ps, pageId, serializedDesign)
    }

    //This method assumes that the regular mode was fixed first, and copying it
    const fixHoverMode = (ps, compPointer, pageId) => {
        const structure = ps.dal.full.get(compPointer)
        const regularOverride = getOverrideAndAddIfMissing(structure, regularModeType)
        const regularDesignId = cleanRef(regularOverride.designQuery)

        const duplicateDesignItemId = duplicateDesignItem(ps, regularDesignId, pageId)
        const override = getOverrideAndAddIfMissing(structure, hoverModeType)
        override.designQuery = `#${duplicateDesignItemId}`
        ps.dal.full.set(compPointer, structure)
    }

    const fixRegularMode = (ps, compPointer, pageId) => {
        const structure = ps.dal.full.get(compPointer)
        const rootDesignId = cleanRef(structure.designQuery)

        const duplicateDesignItemId = duplicateDesignItem(ps, rootDesignId, pageId)
        const override = getOverrideAndAddIfMissing(structure, regularModeType)
        override.designQuery = `#${duplicateDesignItemId}`
        ps.dal.full.set(compPointer, structure)
    }

    const fixHoverbox = (ps, compPointer, pageId) => {
        const structure = ps.dal.full.get(compPointer)

        if (!_.get(structure, ['modes', 'definitions'])) {
            return // as the code assumes a very rigid structure, exiting if that does not exist
        }

        const regularModeDesignQuery = getOverrideAndAddIfMissing(structure, regularModeType).designQuery
        if (!regularModeDesignQuery) {
            fixRegularMode(ps, compPointer, pageId)
        }

        const hoverModeDesignQuery = getOverrideAndAddIfMissing(structure, hoverModeType).designQuery
        if (!hoverModeDesignQuery) {
            fixHoverMode(ps, compPointer, pageId)
        }
    }

    const fixHoverboxesOnPage = (ps, pagePointer) => {
        const pageCompPointer = getPageCompPointer(ps, pagePointer)
        const allComponentsPointers = ps.pointers.full.components.getChildrenRecursively(pageCompPointer)
        const hoverboxPointers = _.filter(allComponentsPointers, pointer => getComponentType(ps, pointer) === hoverboxType)
        const pageId = pagePointer.id
        _.forEach(hoverboxPointers, pointer => fixHoverbox(ps, pointer, pageId))
    }

    return {
        exec(ps) {
            const pagePointers = ps.pointers.page.getNonDeletedPagesPointers(true)
            _.forEach(pagePointers, pagePointer => fixHoverboxesOnPage(ps, pagePointer))
        },
        name: 'hoverboxSplitOverrideFixer',
        version: 1
    }
})
