define([
    'lodash',
    '@wix/santa-core-utils',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/componentsMetaData/metaDataMap',
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/componentsMetaData/metaDataTypes',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/page/popupUtils',
    'documentServices/hooks/hooks',
    'documentServices/theme/theme',
    'documentServices/accessibility/componentA11yAPI',
    'documentServices/dataModel/dataModel',
    'documentServices/variants/design',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment'
], function (
    _,
    santaCoreUtils,
    utils,
    metaDataMap,
    constants,
    metaDataUtils,
    META_DATA_TYPES,
    documentModeInfo,
    appStudioDataModel,
    popupUtils,
    hooks,
    theme,
    componentA11yAPI,
    dataModel,
    design,
    extensionsAPI,
    experiment
) {
    'use strict'

    const METADATA_TYPES_HOOKS = {}

    // link metaData hooks to metaData types:
    METADATA_TYPES_HOOKS[META_DATA_TYPES.MOVE_DIRECTIONS] = hooks.HOOKS.METADATA.MOVE_DIRECTIONS
    METADATA_TYPES_HOOKS[META_DATA_TYPES.REMOVABLE] = hooks.HOOKS.METADATA.REMOVABLE
    METADATA_TYPES_HOOKS[META_DATA_TYPES.DUPLICATABLE] = hooks.HOOKS.METADATA.DUPLICATABLE
    METADATA_TYPES_HOOKS[META_DATA_TYPES.CAN_REPARENT] = hooks.HOOKS.METADATA.CAN_REPARENT
    METADATA_TYPES_HOOKS[META_DATA_TYPES.CAN_BE_STRETCHED] = hooks.HOOKS.METADATA.CAN_BE_STRETCHED
    METADATA_TYPES_HOOKS[META_DATA_TYPES.ROTATABLE] = hooks.HOOKS.METADATA.ROTATABLE
    METADATA_TYPES_HOOKS[META_DATA_TYPES.FIXED_POSITION] = hooks.HOOKS.METADATA.FIXED_POSITION
    METADATA_TYPES_HOOKS[META_DATA_TYPES.RESIZABLE_SIDES] = hooks.HOOKS.METADATA.RESIZABLE_SIDES
    METADATA_TYPES_HOOKS[META_DATA_TYPES.CONTAINABLE] = hooks.HOOKS.METADATA.CONTAINABLE
    METADATA_TYPES_HOOKS[META_DATA_TYPES.LAYOUT_LIMITS] = hooks.HOOKS.METADATA.LAYOUT_LIMITS
    METADATA_TYPES_HOOKS[META_DATA_TYPES.SHOULD_AUTO_SET_NICKNAME] = hooks.HOOKS.METADATA.SHOULD_AUTO_SET_NICKNAME
    METADATA_TYPES_HOOKS[META_DATA_TYPES.A11Y_CONFIGURABLE] = hooks.HOOKS.METADATA.A11Y_CONFIGURABLE
    METADATA_TYPES_HOOKS[META_DATA_TYPES.MAXIMUM_CHILDREN_NUMBER] = hooks.HOOKS.METADATA.MAXIMUM_CHILDREN_NUMBER
    METADATA_TYPES_HOOKS[META_DATA_TYPES.HIDE_AS_GHOST] = hooks.HOOKS.METADATA.HIDE_AS_GHOST

    function isTryingToInsertParentIntoChild(ps, parentComponentPointer, childComponentPointer) {
        let parent = ps.pointers.components.getParent(childComponentPointer)
        while (parent) {
            if (_.isEqual(parent, parentComponentPointer)) {
                return true
            }
            parent = ps.pointers.components.getParent(parent)
        }

        return false
    }

    function isComponentAContainer(ps, componentPointer) {
        return metaDataUtils.isContainer(metaDataUtils.getComponentType(ps, componentPointer)) || ps.pointers.components.isMasterPage(componentPointer)
    }

    const isPointer = pointer => pointer && pointer.id && pointer.type

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} potentialContainerPointer
     * @returns {boolean}
     */
    function isSwitchingScopesAllowed(ps, componentPointer, potentialContainerPointer) {
        const compPageId = ps.pointers.components.getPageOfComponent(componentPointer).id
        const containerPageId = ps.pointers.components.getPageOfComponent(potentialContainerPointer).id

        if (compPageId !== containerPageId) {
            if (!ps.pointers.components.isInMasterPage(componentPointer) && !ps.pointers.components.isInMasterPage(potentialContainerPointer)) {
                return false
            } else if (documentModeInfo.isMobileView(ps)) {
                return isValidPageSwitch(ps, componentPointer, potentialContainerPointer)
            }
        }
        return true
    }

    function getContainedCompType(isByStructure, ps, containedComp) {
        return isByStructure ? containedComp.componentType : metaDataUtils.getComponentType(ps, containedComp)
    }
    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {Pointer} potentialContainerPointer
     * @returns {boolean}
     */
    const isValidPageSwitch = (ps, compPointer, potentialContainerPointer) => {
        const {components} = ps.pointers

        if (!components.isMobile(compPointer) && !components.isMobile(potentialContainerPointer)) {
            return true
        }
        const desktopPageOfComp = components.getPageOfComponent(components.getDesktopPointer(compPointer))?.id
        const newPage = components.getPageOfComponent(potentialContainerPointer)?.id

        return isMobileOnly(ps, compPointer) || desktopPageOfComp === newPage
    }

    function isPopupContainer(ps, potentialContainerPointer) {
        const potentialContainerCompType = metaDataUtils.getComponentType(ps, potentialContainerPointer)
        return popupUtils.isPopupContainer(potentialContainerCompType)
    }

    function isContainerWideEnoughForComp(isByStructure, ps, containedComp, potentialContainerPointer) {
        const potentialContainerCompType = metaDataUtils.getComponentType(ps, potentialContainerPointer)

        const isContainedFullWidth = isCompFullWidth(ps, containedComp, isByStructure)
        const isPotentialContainerFullWidth = isCompFullWidth(ps, potentialContainerPointer)
        const isPotentialContainerSiteSegment = metaDataUtils.isSiteSegmentContainer(potentialContainerCompType)

        return !isContainedFullWidth || isPotentialContainerFullWidth || isPotentialContainerSiteSegment
    }

    function isContainable(isByStructure, ps, comp, potentialContainerPointer) {
        const canBeContained =
            isComponentAContainer(ps, potentialContainerPointer) &&
            (isContainerWideEnoughForComp(isByStructure, ps, comp, potentialContainerPointer) || isPopupContainer(ps, potentialContainerPointer))

        if (isByStructure) {
            return canBeContained
        }
        return canBeContained && isSwitchingScopesAllowed(ps, comp, potentialContainerPointer)
    }

    function defaultContainable(isByStructure, ps, comp, potentialContainerPointer) {
        if (potentialContainerPointer) {
            return isContainable(isByStructure, ps, comp, potentialContainerPointer)
        } else if (documentModeInfo.isMobileView(ps) || isCompFullWidth(ps, comp, isByStructure) || isHorizontalDockToScreen(ps, comp)) {
            return false
        }
        return true
    }

    function defaultGroupable(ps, comp) {
        return getMetaData(ps, META_DATA_TYPES.CONTAINABLE, comp)
    }

    function defaultDuplicatable(ps, comp) {
        const isNative = isPointer(comp) && ps.dal.full.isExist(comp) ? isNativeMobileOnlyByPointer : isNativeMobileOnlyByStructure

        return !isNative(ps, comp) || !documentModeInfo.isMobileView(ps)
    }

    function getComponentStyle(ps, compPointer) {
        //Better use componentStylesAndSkinsAPI, but can't due to circular dependency
        const styleId = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'styleId'))
        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id

        if (styleId) {
            return theme.styles.get(ps, styleId, pageId)
        }
        return null
    }

    function getShouldKeepChildrenInPlace(ps, compPointer) {
        return getMetaData(ps, META_DATA_TYPES.SHOULD_KEEP_CHILDREN_IN_PLACE, compPointer)
    }

    function shouldBeRemovedByParent(ps, componentPointer) {
        const parentPointer = ps.pointers.components.getParent(componentPointer)
        const parentType = parentPointer && metaDataUtils.getComponentType(ps, parentPointer)
        return (
            !!parentType && parentType === 'wysiwyg.viewer.components.RefComponent' && santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(componentPointer)
        )
    }

    function shouldRemoveAsGhost(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.HIDE_AS_GHOST, componentPointer)
    }

    function getAnchorableHeight(ps, compPointer) {
        const compHeight = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout.height'))
        if (!isContainer(ps, compPointer)) {
            return compHeight
        }
        const compStyle = getComponentStyle(ps, compPointer)
        const compSkin = (compStyle && compStyle.skin) || ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'skin'))

        return compHeight - utils.layoutAnchors.getNonAnchorableHeightForSkin(compSkin, compStyle)
    }

    function defaultIsAlignable(ps, compPointer) {
        const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        return !(compLayout.fixedPosition || compLayout.docked)
    }

    const DEFAULTS = {
        containable: defaultContainable.bind(null, false),
        containableByStructure: defaultContainable.bind(null, true),
        container: isComponentAContainer,
        canContain: isComponentAContainer,
        canContainByStructure: isComponentAContainer,
        isPublicContainer: isComponentAContainer,
        shouldKeepChildrenInPlace: true,
        disableable: false,
        isRepeater: false,
        isRepeatable: true,
        isContainCheckRecursive: true,
        moveDirections: [constants.MOVE_DIRECTIONS.HORIZONTAL, constants.MOVE_DIRECTIONS.VERTICAL],
        resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT],
        rotatable: false,
        flippable: false,
        anchors: {
            to: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD},
            from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD}
        },
        canBeFixedPosition: true,
        canBeStretched: false,
        showMarginsIndicator: false,
        removable: true,
        alignable: defaultIsAlignable,
        duplicatable: defaultDuplicatable,
        crossSiteDuplicatable: defaultDuplicatable,
        hiddenable: true,
        minimalChildrenNumber: 0,
        maximumChildrenNumber: Number.MAX_VALUE,
        collapsible: true,
        dockable: true,
        fullWidth: defaultIsFullWidth,
        fullWidthByStructure: defaultIsFullWidthByStructure,
        styleCanBeApplied: false,
        groupable: defaultGroupable,
        hideAsGhost: false,
        enforceContainerChildLimitsByWidth: true,
        enforceContainerChildLimitsByHeight: true,
        shouldAutoSetNickname: true,
        nickname: metaDataUtils.getComponentNickname,
        layoutLimits: {
            minWidth: utils.siteConstants.COMP_SIZE.MIN_WIDTH,
            minHeight: utils.siteConstants.COMP_SIZE.MIN_HEIGHT,
            maxWidth: utils.siteConstants.COMP_SIZE.MAX_WIDTH,
            maxHeight: utils.siteConstants.COMP_SIZE.MAX_HEIGHT,
            aspectRatio: null
        },
        isProportionallyResizable,
        ignoreChildrenOnProportionalResize: false,
        enforceMaxDimensionsOnProportionalResize: false,
        resizeOnlyProportionally: false,
        isVisible: true,
        enforceResizableCorners: false,
        forceMaintainIDsOnSerialize: false,
        canReparent: true,
        heightAuto: false,
        widthAuto: false,
        a11yConfigurable: true,
        canConnectToCode: true
    }

    let createCompDriver

    const isExternalKey = (compType, metaDataKey) => {
        const isExternalComponent = _.get(metaDataMap, [compType, 'isExternal'], false)
        const hasExternalMetaData = _.get(metaDataMap, [compType, metaDataKey])
        return isExternalComponent && !_.isUndefined(hasExternalMetaData)
    }

    function getCompOrDefaultMetaData(compType, metaDataKey) {
        const metadataValue = _.get(metaDataMap, [compType, metaDataKey])
        return _.isUndefined(metadataValue) ? DEFAULTS[metaDataKey] : metadataValue
    }

    function defaultIsFullWidth(ps, compPointer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const compLayout = ps.dal.get(compLayoutPointer)

        return utils.dockUtils.isHorizontalDockToScreen(compLayout) || metaDataUtils.isLegacyFullWidthContainer(ps, compPointer)
    }

    function defaultIsFullWidthByStructure(ps, compStructure) {
        return utils.dockUtils.isHorizontalDockToScreen(compStructure.layout) || metaDataUtils.isLegacyFullWidthContainerByType(compStructure.componentType)
    }

    /**
     * @param {ps} ps
     * @param {string} metaDataKey
     * @param {Pointer} componentPointer
     * @param {...Array} additionalArguments additional params per specific meta-data type requirements - will be passed on to the meta-data function
     * @returns {boolean|string|number}
     */
    function getMetaData(ps, metaDataKey, componentPointer, ...additionalArguments) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)
        let metaDataValue = getCompOrDefaultMetaData(compType, metaDataKey)
        const args = [componentPointer, ...additionalArguments] // remove metaDataKey from arguments, leave additional trailing optional params]

        if (_.isFunction(metaDataValue)) {
            const isMetaDataExternal = isExternalKey(compType, metaDataKey)
            let metaDataApi

            if (isMetaDataExternal) {
                if (!createCompDriver) {
                    return DEFAULTS[metaDataKey]
                }
                metaDataApi = createCompDriver(ps, componentPointer)
            } else {
                metaDataApi = ps
            }

            const argsForMetaData = [metaDataApi].concat(args)
            // @ts-ignore
            metaDataValue = metaDataValue.apply(this, argsForMetaData)
        }

        if (METADATA_TYPES_HOOKS[metaDataKey]) {
            return hooks.executeHookAndUpdateValue(ps, METADATA_TYPES_HOOKS[metaDataKey], compType, args, metaDataValue)
        }
        return metaDataValue
    }

    function getMetaDataByStructure(ps, metaDataKey, componentStructure, ...additionalArguments) {
        const compType = componentStructure.componentType
        let metaData = getCompOrDefaultMetaData(compType, metaDataKey)
        if (_.isFunction(metaData)) {
            metaData = metaData(ps, componentStructure, ...additionalArguments)
        }
        return metaData
    }

    function getCompResizableSides(ps, component) {
        return getMetaData(ps, META_DATA_TYPES.RESIZABLE_SIDES, component)
    }

    function isCompFullWidth(ps, comp, isByStructure) {
        if (isByStructure) {
            return getMetaDataByStructure(ps, META_DATA_TYPES.FULL_WIDTH_BY_STRUCTURE, comp)
        }
        return getMetaData(ps, META_DATA_TYPES.FULL_WIDTH, comp)
    }

    function isContainer(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.CONTAINER, componentPointer)
    }

    function isPublicContainer(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.IS_PUBLIC_CONTAINER, componentPointer)
    }

    function isMovable(ps, componentPointer) {
        // @ts-ignore
        return !!getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer).length
    }

    function isResizable(ps, componentPointer) {
        // @ts-ignore
        return !!getCompResizableSides(ps, componentPointer).length
    }

    function isAlignable(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ALIGNABLE, componentPointer) && isMovable(ps, componentPointer)
    }

    function isRotated(ps, componentPointer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
        const compLayout = ps.dal.get(compLayoutPointer)

        return isRotatedByLayout(compLayout)
    }

    function isRotatedByLayout(layoutData) {
        return _.isObject(layoutData) && layoutData.rotationInDegrees !== 0
    }

    function canBeStretched(ps, componentPointer) {
        const page = ps.pointers.components.getPageOfComponent(componentPointer)
        if (popupUtils.isPopup(ps, page.id) && !popupUtils.isPopupFullWidth(ps, page)) {
            return false
        }

        return getMetaData(ps, META_DATA_TYPES.CAN_BE_STRETCHED, componentPointer) && !isRotated(ps, componentPointer)
    }

    function canBeStretchedByStructure(ps, componentStructure) {
        return getMetaDataByStructure(ps, META_DATA_TYPES.CAN_BE_STRETCHED, componentStructure) && !isRotatedByLayout(componentStructure.layout)
    }

    function isStretched(ps, componentPointer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
        const compLayout = ps.dal.get(compLayoutPointer)

        return utils.dockUtils.isStretched(compLayout)
    }

    function isHorizontalDockToScreen(ps, componentPointer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
        const compLayout = ps.dal.get(compLayoutPointer)

        return utils.dockUtils.isHorizontalDockToScreen(compLayout)
    }

    function isRotatable(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ROTATABLE, componentPointer) && !isStretched(ps, componentPointer)
    }

    function isFlippable(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.FLIPPABLE, componentPointer)
    }

    function isChildTypeAllowed(ps, potentialContainerPointer, childComponentType) {
        const childTypesAllowed = getMetaData(ps, META_DATA_TYPES.ALLOWED_CHILD_TYPES, potentialContainerPointer)
        if (!childTypesAllowed) {
            return true
        }
        return _.includes(childTypesAllowed, childComponentType)
    }

    function isParentTypeAllowed(ps, comp, potentialContainerType, isByStructure) {
        const parentTypesAllowed = isByStructure
            ? getMetaDataByStructure(ps, META_DATA_TYPES.ALLOWED_PARENT_TYPES, comp)
            : getMetaData(ps, META_DATA_TYPES.ALLOWED_PARENT_TYPES, comp)
        if (!parentTypesAllowed) {
            return true
        }
        return _.includes(parentTypesAllowed, potentialContainerType)
    }

    function isPotentialContainerForScreenWidthComp(ps, potentialContainerPointer) {
        const compType = metaDataUtils.getComponentType(ps, potentialContainerPointer)
        return metaDataUtils.isLegacyFullWidthContainer(ps, potentialContainerPointer) || popupUtils.isPopupContainer(compType)
    }

    function isComponentContainableRecursively(ps, compToAddParam, potentialContainerPointer, containCheckFunc) {
        let isRecursive
        let res = true
        const targetedContainerPointer = potentialContainerPointer
        while (potentialContainerPointer && res) {
            isRecursive = getMetaData(ps, META_DATA_TYPES.IS_CONTAIN_CHECK_RECURSIVE, potentialContainerPointer)
            res = containCheckFunc(ps, compToAddParam, potentialContainerPointer, targetedContainerPointer)
            if (isRecursive) {
                potentialContainerPointer = ps.pointers.components.getParent(potentialContainerPointer)
            } else {
                potentialContainerPointer = null
            }
        }
        return res
    }

    function isComponentCanContain(ps, componentPointer, potentialContainerPointer, targetedContainerPointer) {
        return getMetaData(ps, META_DATA_TYPES.CAN_CONTAIN, potentialContainerPointer, componentPointer, targetedContainerPointer)
    }

    function isContainableForDisplayedOnlyComps(ps, componentPointer, potentialContainerPointer) {
        if (!documentModeInfo.isMobileView(ps)) {
            return true
        }

        if (!isRepeatedComponent(ps, componentPointer) && !isRepeatedComponent(ps, potentialContainerPointer)) {
            return true
        }

        const compItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        const potentialContainerItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(potentialContainerPointer.id)
        return compItemId === potentialContainerItemId
    }

    const isCompRepeater = (ps, compPointer) => getMetaData(ps, META_DATA_TYPES.IS_REPEATER, compPointer)

    const isCompTypeRepeatable = compType => getCompOrDefaultMetaData(compType, META_DATA_TYPES.IS_REPEATABLE)
    const isCompRepeatable = (ps, componentPointer) => getMetaData(ps, META_DATA_TYPES.IS_REPEATABLE, componentPointer)

    const hasChildren = (ps, componentPointer) => {
        const children = ps.pointers.full.components.getChildren(componentPointer)
        return _.size(children) > 0
    }

    const allDescendantsAllowedInRepeater = (ps, componentPointer) => {
        const compRepeatable = isCompRepeatable(ps, componentPointer)
        const children = ps.pointers.components.getChildren(componentPointer)
        const recursiveChildrenAllowedInRepeater = () => _.every(children, childPointer => allDescendantsAllowedInRepeater(ps, childPointer))
        return compRepeatable && recursiveChildrenAllowedInRepeater()
    }

    const allowContainmentForRepeater = (ps, componentPointer, potentialContainerPointer) => {
        const isPotentialContainerRepeater = isCompRepeater(ps, potentialContainerPointer)
        const isPotentialContainerRepeaterWithChildren = isPotentialContainerRepeater && hasChildren(ps, potentialContainerPointer)
        if (isPotentialContainerRepeaterWithChildren) {
            return false
        }
        const isRepeaterOrInsideRepeater = isPotentialContainerRepeater || isRepeatedComponent(ps, potentialContainerPointer)
        return !isRepeaterOrInsideRepeater || allDescendantsAllowedInRepeater(ps, componentPointer)
    }

    const isChildOfSlottedComponent = (ps, componentPointer) => {
        const {getRootRefHostCompId} = santaCoreUtils.displayedOnlyStructureUtil
        const refHostId = getRootRefHostCompId(componentPointer.id)
        const parentPointer = refHostId ? ps.pointers.getPointer(refHostId, componentPointer.type) : ps.pointers.components.getParent(componentPointer)

        if (parentPointer && ps.dal.get(parentPointer).slotsQuery) {
            const {slotsQuery} = ps.dal.get(parentPointer)
            const slotsDataPointer = ps.pointers.getPointer(slotsQuery, 'slots')
            const {slots} = ps.dal.get(slotsDataPointer)
            return Object.keys(slots)
                .map(slotName => slots[slotName])
                .includes(componentPointer.id)
        }

        return false
    }

    function canReparent(ps, componentPointer) {
        if (componentPointer.type === constants.VIEW_MODES.MOBILE && isChildOfSlottedComponent(ps, componentPointer)) {
            return false
        }

        return getMetaData(ps, META_DATA_TYPES.CAN_REPARENT, componentPointer)
    }

    function isReparent(ps, componentPointer, potentialContainerPointer) {
        return !ps.pointers.isSamePointer(ps.pointers.components.getParent(componentPointer), potentialContainerPointer)
    }

    function areChildAndParentTypesMatching(ps, componentPointer, potentialContainerPointer, isByStructure) {
        return (
            !!potentialContainerPointer &&
            isChildTypeAllowed(ps, potentialContainerPointer, getContainedCompType(isByStructure, ps, componentPointer)) &&
            isParentTypeAllowed(ps, componentPointer, metaDataUtils.getComponentType(ps, potentialContainerPointer), isByStructure)
        )
    }

    function isComponentContainable(ps, componentPointer, potentialContainerPointer) {
        if (
            !potentialContainerPointer ||
            !isContainer(ps, potentialContainerPointer) ||
            !allowContainmentForRepeater(ps, componentPointer, potentialContainerPointer) ||
            santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(potentialContainerPointer)
        ) {
            return false
        }

        if (!canReparent(ps, componentPointer) && isReparent(ps, componentPointer, potentialContainerPointer)) {
            return false
        }
        if (!isValidPageSwitch(ps, componentPointer, potentialContainerPointer)) {
            return false
        }

        return (
            getMetaData(ps, META_DATA_TYPES.CONTAINABLE, componentPointer, potentialContainerPointer) &&
            !isTryingToInsertParentIntoChild(ps, componentPointer, potentialContainerPointer) &&
            areChildAndParentTypesMatching(ps, componentPointer, potentialContainerPointer, false) &&
            isComponentContainableRecursively(ps, componentPointer, potentialContainerPointer, isComponentCanContain) &&
            isContainableForDisplayedOnlyComps(ps, componentPointer, potentialContainerPointer) &&
            (!isHorizontalDockToScreen(ps, componentPointer) || isPotentialContainerForScreenWidthComp(ps, potentialContainerPointer))
        )
    }

    function isTryingToRepeatNonRepeatableComponentsInsideRepeater(ps, componentStructure, potentialContainerPointer, targetedContainerPointer) {
        const isCheckingTarget = targetedContainerPointer.id === potentialContainerPointer.id
        if (!isCheckingTarget) {
            return false
        }

        // if parent is a repeater
        const isTargetCompRepeater = isCompRepeater(ps, targetedContainerPointer)
        const repeaterAlreadyHaveDirectChild = isTargetCompRepeater && hasChildren(ps, targetedContainerPointer)
        if (repeaterAlreadyHaveDirectChild) {
            // early return, to prevent expensive calculations
            return true
        }

        // if descendant of a repeater
        const isTargetedContainerInsideRepeater = isTargetCompRepeater || isRepeatedComponent(ps, targetedContainerPointer)
        if (isTargetedContainerInsideRepeater) {
            const childrenTypes = metaDataUtils.getChildrenTypesDeep([componentStructure], ps)
            return !_.every(childrenTypes, isCompTypeRepeatable)
        }

        return false
    }

    function isContainableByStructure(ps, componentStructure, potentialContainerPointer, targetedContainerPointer) {
        if (isTryingToRepeatNonRepeatableComponentsInsideRepeater(ps, componentStructure, potentialContainerPointer, targetedContainerPointer)) {
            return false
        }

        const alwaysContainRecursively = getMetaData(ps, META_DATA_TYPES.ALWAYS_CONTAIN_RECURSIVELY, potentialContainerPointer)
        const indirectParent = potentialContainerPointer.id !== targetedContainerPointer.id
        if (alwaysContainRecursively && indirectParent) {
            return true
        }

        let isCompCanContainByStructure = getMetaData(
            ps,
            META_DATA_TYPES.CAN_CONTAIN_BY_STRUCTURE,
            potentialContainerPointer,
            componentStructure,
            targetedContainerPointer
        )

        if (utils.dockUtils.isHorizontalDockToScreen(componentStructure.layout)) {
            isCompCanContainByStructure = isCompCanContainByStructure && isPotentialContainerForScreenWidthComp(ps, potentialContainerPointer)
        }

        const isCompContainableByStructure = getMetaDataByStructure(ps, META_DATA_TYPES.CONTAINABLE_BY_STRUCTURE, componentStructure, potentialContainerPointer)

        return Boolean(isCompCanContainByStructure && isCompContainableByStructure)
    }

    function isComponentContainableByStructure(ps, componentStructure, potentialContainerPointer) {
        return (
            areChildAndParentTypesMatching(ps, componentStructure, potentialContainerPointer, true) &&
            isComponentContainableRecursively(ps, componentStructure, potentialContainerPointer, isContainableByStructure)
        )
    }

    function isRepeatedComponent(ps, componentPointer) {
        return santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)
    }

    function isEnforcingContainerChildLimitationsByWidth(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH, componentPointer)
    }

    function isEnforcingContainerChildLimitationsByHeight(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT, componentPointer)
    }

    function resizeOnlyProportionally(ps, componentPointer) {
        const layoutLimits = _.defaults(getMetaData(ps, META_DATA_TYPES.LAYOUT_LIMITS, componentPointer), DEFAULTS.layoutLimits)
        return !_.isNull(layoutLimits.aspectRatio)
    }

    function enforceResizableCorners(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ENFORCE_RESIZABLE_CORNERS, componentPointer)
    }

    function isHorizontallyResizable(ps, componentPointer) {
        return (
            !!_.intersection(getCompResizableSides(ps, componentPointer), [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]).length ||
            resizeOnlyProportionally(ps, componentPointer)
        )
    }

    function isVerticallyResizable(ps, componentPointer) {
        return (
            !!_.intersection(getCompResizableSides(ps, componentPointer), [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM]).length ||
            resizeOnlyProportionally(ps, componentPointer)
        )
    }

    function isProportionallyResizable(ps, componentPointer) {
        return isHorizontallyResizable(ps, componentPointer) && isVerticallyResizable(ps, componentPointer)
    }

    function isIgnoreChildrenOnProportionalResize(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.IGNORE_CHILDREN_ON_PROPORTINAL_RESIZE, componentPointer)
    }

    function enforceMaxDimensionsOnProportionalResize(ps, componentPointer) {
        return getMetaData(ps, META_DATA_TYPES.ENFORCE_MAX_DIM_ON_PROPORTIONAL_RESIZE, componentPointer)
    }

    function isAnchorableFrom(ps, componentPointer) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)
        const compAnchorsMetaData = utils.getComponentsAnchorsMetaData()
        return _.get(compAnchorsMetaData[compType], 'from.allow', compAnchorsMetaData.default.from.allow)
    }

    function isAnchorableTo(ps, componentPointer) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)
        const compAnchorsMetaData = utils.getComponentsAnchorsMetaData()
        return _.get(compAnchorsMetaData[compType], 'to.allow', compAnchorsMetaData.default.to.allow)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} comp
     * @param {string} pageId
     * @returns {Object}
     */
    function getMobileOnlyMetaData(ps, comp, pageId) {
        const mobilePagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        if (!mobilePagePointer) {
            return {}
        }
        const mobileComponentPointer = ps.pointers.components.getComponent(comp.id, mobilePagePointer)
        if (!ps.dal.isExist(mobileComponentPointer)) {
            return {}
        }
        const mobileOnly = isMobileOnly(ps, mobileComponentPointer)
        return {mobileOnly}
    }

    function getMobileConversionConfig(ps, comp, pageId) {
        const compType = comp.componentType || comp.documentType
        const mobileConversionConfig = _.get(metaDataMap, [compType, META_DATA_TYPES.MOBILE_CONVERSION_CONFIG])
        const resolvedMobileConversionConfig = _.mapValues(mobileConversionConfig, function (value) {
            return _.isFunction(value) ? value(ps, comp, pageId) : value
        })
        let layoutLimits = _.get(metaDataMap, [compType, META_DATA_TYPES.LAYOUT_LIMITS])
        if (_.isFunction(layoutLimits)) {
            const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
            const compPointer = ps.pointers.components.getComponent(comp.id, pagePointer)
            layoutLimits = compPointer && ps.dal.isExist(compPointer) ? layoutLimits(ps, compPointer) : layoutLimits
        }
        const hiddenable = _.get(metaDataMap, [compType, META_DATA_TYPES.HIDDENABLE], true)
        return _.defaults(resolvedMobileConversionConfig, layoutLimits ? {layoutLimits} : {}, {hiddenable}, getMobileOnlyMetaData(ps, comp, pageId))
    }

    /**
     * get a mobile conversion config specific attribute
     * @param {ps} ps Private Services
     * @param {Object} comp component structure
     * @param {String} attributeName the wanted attribute from the mobile conversion config
     * @param {String} pageId the component pageId
     * @returns {*} value of the attribute conversion meta-data for comp
     *
     * @example
     * const isDesktopOnly = getMobileConversionConfigByName(ps, ps.dal.get(componentPointer), 'desktopOnly', pageId);
     */
    function getMobileConversionConfigByName(ps, comp, attributeName, pageId) {
        const compType = comp.componentType || comp.documentType
        const value = _.get(metaDataMap, [compType, META_DATA_TYPES.MOBILE_CONVERSION_CONFIG, attributeName])
        return _.isFunction(value) ? value(ps, comp, pageId) : value
    }

    /**
     * Checks if component's height determined by its content
     * @param {ps} ps
     * @param {*} compPointer
     * @returns {boolean}
     */
    // @ts-ignore
    const heightAuto = (ps, compPointer) => getMetaData(ps, META_DATA_TYPES.HEIGHT_AUTO, compPointer)

    /**
     * Checks if component's height determined by its content
     * @param {ps} ps
     * @param {*} compStructure
     * @returns {boolean}
     */
    const heightAutoByStructure = (ps, compStructure) => getMetaDataByStructure(ps, META_DATA_TYPES.HEIGHT_AUTO, compStructure)

    /**
     * Checks if component's width determined by its content
     * @param {ps} ps
     * @param {*} compPointer
     * @returns {boolean}
     */
    // @ts-ignore
    const widthAuto = (ps, compPointer) => getMetaData(ps, META_DATA_TYPES.WIDTH_AUTO, compPointer)

    /**
     * Checks if component's width determined by its content
     * @param {ps} ps
     * @param {*} compStructure
     * @returns {boolean}
     */
    const widthAutoByStructure = (ps, compStructure) => getMetaDataByStructure(ps, META_DATA_TYPES.WIDTH_AUTO, compStructure)

    /**
     * Returns whether the given pointer is a native mobile only component
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {boolean}
     */
    function isNativeMobileOnlyByPointer(ps, componentPointer) {
        return !!getMetaData(ps, META_DATA_TYPES.MOBILE_ONLY, componentPointer)
    }

    /**
     * Returns whether the given comp structure is a native mobile only component
     *
     * @param {ps} ps
     * @param {Object} componentStructure
     * @returns {boolean}
     */
    function isNativeMobileOnlyByStructure(ps, componentStructure) {
        return !!constants.ALLOWED_MOBILE_COMPONENTS[componentStructure.componentType]
    }

    /**
     * Checks if a given pointer is a mobile only component, or a regular one (both mobile and desktop)
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {boolean}
     */
    function isMobileOnly(ps, componentPointer) {
        if (!componentPointer || componentPointer.type !== constants.VIEW_MODES.MOBILE) {
            return false
        }

        const mobileOnlyTypes = _.keys(constants.ALLOWED_MOBILE_COMPONENTS)
        const compType = _.get(ps.dal.get(componentPointer), ['componentType'])
        if (_.includes(mobileOnlyTypes, compType)) {
            return isNativeMobileOnlyByPointer(ps, componentPointer)
        }

        const desktopPointer = ps.pointers.components.getDesktopPointer(componentPointer)
        const existOnlyInMobile = ps.dal.full.isExist(componentPointer) && !ps.dal.full.isExist(desktopPointer)
        if (!existOnlyInMobile) {
            return false
        }

        const isPlaceholder = compType === constants.DEAD_MOBILE_COMPONENT_TYPE
        if (isPlaceholder) {
            return false
        }

        const hasBrokenRefs = _([dataModel.getDataItemPointer(ps, componentPointer), design.getDesignItemPointer(ps, componentPointer)])
            .compact()
            .some(ptr => !ps.dal.isExist(ptr))
        if (hasBrokenRefs) {
            return false
        }

        return true
    }

    return {
        init(createCompDriverFunction) {
            createCompDriver = createCompDriverFunction
        },

        public: {
            /**
             * Checks if a component can be contained in a potential container
             * @param {ps} ps
             * @param {AbstractComponent} componentPointer the component that will be contained
             * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
             * @returns {boolean}
             */
            isContainable(ps, componentPointer, potentialContainerPointer) {
                return (
                    !!potentialContainerPointer &&
                    isPublicContainer(ps, potentialContainerPointer) &&
                    isComponentContainable(ps, componentPointer, potentialContainerPointer)
                )
            },

            /**
             * Checks if a component can be contained in a potential container, using the component's serialized structure
             * @param {ps} ps
             * @param {Pointer} componentPointer the component pointer we want to check
             * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
             * @returns {boolean}
             */
            isContainableByStructure(ps, componentPointer, potentialContainerPointer) {
                return (
                    !!potentialContainerPointer &&
                    isPublicContainer(ps, potentialContainerPointer) &&
                    isComponentContainableByStructure(ps, componentPointer, potentialContainerPointer)
                )
            },

            /**
             * Checks if a component is a valid container
             * @param {ps} ps
             * @param {AbstractComponent} componentPointer
             * @returns {boolean}
             */
            isContainer: isPublicContainer,

            /**
             *
             * @param {ps} ps
             * @param componentPointer
             * @returns {boolean|*}
             */
            isAlignable,

            /**
             * Checks if a component is movable
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isMovable,

            /**
             * Checks if a component is horizontally movable
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isHorizontallyMovable: function isHorizontallyMovable(ps, componentPointer) {
                return _.includes(getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer), constants.MOVE_DIRECTIONS.HORIZONTAL)
            },

            /**
             * Checks if a component is vertically movable
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isVerticallyMovable: function isVerticallyMovable(ps, componentPointer) {
                return _.includes(getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer), constants.MOVE_DIRECTIONS.VERTICAL)
            },

            /**
             * Returns component settings in mobile view
             * @param {ps} ps
             * @param {Component} comp
             * @param {string} pageId
             * @returns {MobileConversionConfig}
             */
            getMobileConversionConfig,

            /**
             * Used internally in documentServices by mobileHintsValidator
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {string} mobile conversion config
             */
            getMobileConversionConfigByName,

            /**
             * Used internally in documentServices by mobileHintsValidator
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {number} Minimal number of children.
             */
            getMinimalChildrenNumber(ps, componentPointer) {
                // @ts-ignore
                return getMetaData(ps, META_DATA_TYPES.MINIMAL_CHILDREN_NUMBER, componentPointer)
            },

            allowedToContainMoreChildren(ps, componentPointer) {
                const maximumChildrenNumber = getMetaData(ps, META_DATA_TYPES.MAXIMUM_CHILDREN_NUMBER, componentPointer)

                if (maximumChildrenNumber === Number.MAX_VALUE) {
                    return true
                }

                const componentPointers = ps.pointers.components

                const childrenPointers = componentPointers.getChildren(componentPointer)

                return maximumChildrenNumber > childrenPointers.length
            },

            getDefaultMobileProperties: function getDefaultMobileProperties(ps, componentStructure, desktopProps) {
                const compType = componentStructure.componentType
                const metaData = getCompOrDefaultMetaData(compType, META_DATA_TYPES.DEFAULT_MOBILE_PROPERTIES)
                return _.isFunction(metaData) ? metaData(ps, componentStructure, desktopProps) : metaData
            },

            /**
             * Gets a component's resizable sides
             * @member documentServices.components.layout
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {Array}
             */
            getResizableSides: function getResizableSides(ps, componentPointer) {
                // @ts-ignore
                return getCompResizableSides(ps, componentPointer)
            },

            /**
             * Checks if a component is horizontally resizable
             * @param {PrivateDocumentServices} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isHorizontallyResizable,

            /**
             * Checks if a component is vertically resizable
             * @param {PrivateDocumentServices} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isVerticallyResizable,

            /**
             * Checks if a component is proportionally resizable
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isProportionallyResizable(ps, componentPointer) {
                // @ts-ignore
                return getMetaData(ps, META_DATA_TYPES.IS_PROPORTIONALLY_RESIZABLE, componentPointer)
            },

            /**
             * If resizing a container proportionally, don't try to proportionally resize its children
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isIgnoreChildrenOnProportionalResize,

            /**
             * If resizing a comp proportionally, don't ignore maxWidth/maxHeight defined in component Meta Data
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            enforceMaxDimensionsOnProportionalResize,

            /**
             * Checks if a component is resizable
             * @param {PrivateDocumentServices} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isResizable,

            /**
             * Checks if a component is rotatable
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isRotatable,

            /**
             * Checks if a component can be flipped
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            isFlippable,

            isGroupable: function isGroupable(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.GROUPABLE, componentPointer) && canReparent(ps, componentPointer)
            },

            /**
             * Checks if a component can be fixed position
             * @param {ps} ps
             * @param {Pointer} componentPointer
             * @returns {boolean}
             */
            canBeFixedPosition: function canBeFixedPosition(ps, componentPointer) {
                // @ts-ignore
                return getMetaData(ps, META_DATA_TYPES.FIXED_POSITION, componentPointer)
            },

            isModal: function isModal(ps, componentPointer) {
                return !!getMetaData(ps, META_DATA_TYPES.MODAL, componentPointer)
            },

            isMobileOnly,
            isNativeMobileOnlyByPointer,
            isNativeMobileOnlyByStructure,

            isUsingLegacyAppPartSchema: function isUsingLegacyAppPartSchema(ps, componentPointer) {
                return !!getMetaData(ps, META_DATA_TYPES.USING_LEGACY_APP_PART_SCHEMA, componentPointer)
            },

            /**
             * Checks if a component can be horizontal dock to screen
             * @param {PrivateDocumentServices} ps
             * @param {AbstractComponent} componentPointer
             * @returns {boolean}
             */
            canBeStretched,

            canBeStretchedByStructure,

            resizeOnlyProportionally,

            enforceResizableCorners,

            isRepeatedComponent,

            isEnforcingContainerChildLimitations: function isEnforcingContainerChildLimitations(ps, componentPointer) {
                return isEnforcingContainerChildLimitationsByWidth(ps, componentPointer) || isEnforcingContainerChildLimitationsByHeight(ps, componentPointer)
            },

            isEnforcingContainerChildLimitationsByWidth,

            isEnforcingContainerChildLimitationsByHeight,

            /**
             * Checks if a component is rendered in a full width mode
             * @param {ps} ps
             * @param {AbstractComponent} componentPointer
             * @returns {boolean}
             */
            isFullWidth: function isFullWidth(ps, componentPointer) {
                return isCompFullWidth(ps, componentPointer, false)
            },

            /**
             * Checks if a component is rendered in a full width mode, using the component's serialized structure
             * @param {ps} ps
             * @param {object} componentStructure the serialized structure of the component
             * @returns {boolean}
             */
            isFullWidthByStructure: function isFullWidthByStructure(ps, componentStructure) {
                return isCompFullWidth(ps, componentStructure, true)
            },

            isRemovable: function isRemovable(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.REMOVABLE, componentPointer)
            },

            isDuplicatable: function isDuplicatable(ps, componentPointer, potentialParentPointer) {
                return getMetaData(ps, META_DATA_TYPES.DUPLICATABLE, componentPointer, potentialParentPointer)
            },

            isCrossSiteDuplicatable: function isCrossSiteDuplicatable(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.CROSS_SITE_DUPLICATABLE, componentPointer)
            },

            isCrossSiteDuplicatableByStructure: function isCrossSiteDuplicatableByStructure(ps, componentStructure) {
                return getMetaDataByStructure(ps, META_DATA_TYPES.CROSS_SITE_DUPLICATABLE, componentStructure)
            },

            isStyleCanBeApplied: function isStyleCanBeApplied(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.STYLE_CAN_BE_APPLIED, componentPointer)
            },

            /**
             *
             * @param {ps} ps
             * @param compPointer
             * @param [newLayout] - optionally pass newLayout, for components which calculate limits dynamically
             * @returns {*}
             */
            getLayoutLimits(ps, compPointer, newLayout) {
                const layoutLimits = _.defaults(getMetaData(ps, META_DATA_TYPES.LAYOUT_LIMITS, compPointer, newLayout), DEFAULTS.layoutLimits)
                // TODO: WEED-15708 - Remove this monstrosity to a more robust mechanism
                const isMobileWidgetRoot =
                    ps.pointers.components.isMobile(compPointer) &&
                    appStudioDataModel.isWidgetPage(ps, _.get(ps.pointers.components.getParent(compPointer), 'id'))
                layoutLimits.maxWidth = Math.min(layoutLimits.maxWidth, DEFAULTS.layoutLimits.maxWidth, isMobileWidgetRoot ? 320 : Number.MAX_SAFE_INTEGER)
                layoutLimits.maxHeight = Math.min(layoutLimits.maxHeight, DEFAULTS.layoutLimits.maxHeight)
                layoutLimits.minWidth = Math.max(layoutLimits.minWidth, DEFAULTS.layoutLimits.minWidth)
                layoutLimits.minHeight = Math.max(layoutLimits.minHeight, DEFAULTS.layoutLimits.minHeight)
                return layoutLimits
            },
            isHiddenable(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.HIDDENABLE, componentPointer)
            },
            isCollapsible(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.COLLAPSIBLE, componentPointer)
            },

            isDockable(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.DOCKABLE, componentPointer)
            },
            isDisableable(ps, componentPointer) {
                return !!getMetaData(ps, META_DATA_TYPES.DISABLEABLE, componentPointer)
            },
            isAnchorableFrom,
            isAnchorableTo,
            isVisible(ps, componentPointer) {
                return getMetaData(ps, META_DATA_TYPES.VISIBLE, componentPointer)
            },
            isPotentialContainerForScreenWidthComp(ps, compPointer) {
                return isPotentialContainerForScreenWidthComp(ps, compPointer)
            },
            canReparent,

            heightAuto,
            heightAutoByStructure,

            widthAuto,
            widthAutoByStructure,
            /**
             * @param {ps} ps
             * @param {AbstractComponent} componentPointer
             * @returns {Boolean}
             */
            isA11yConfigurable(ps, componentPointer) {
                // @ts-ignore
                return getMetaData(ps, META_DATA_TYPES.A11Y_CONFIGURABLE, componentPointer) && !!componentA11yAPI.getA11ySchema(ps, componentPointer)
            }
        },

        /**
         * Checks if a component can be contained in a potential container
         * @param {PrivateDocumentServices} ps
         * @param {AbstractComponent} componentPointer the component that will be contained
         * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
         * @returns {boolean}
         */
        isContainable: isComponentContainable,

        /**
         * Checks if a component can be contained in a potential container, using the component's serialized structure
         * @param {ps} ps
         * @param {object} componentStructure the serialized structure of the component that will be contained
         * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
         * @returns {boolean}
         */
        isContainableByStructure: isComponentContainableByStructure,

        /**
         * Checks if a component is a valid container
         * @param {PrivateDocumentServices} ps
         * @param {AbstractComponent} componentPointer
         * @returns {boolean}
         */
        isContainer,
        // Internal methods for use within DocumentServices
        /**
         * Used internally in documentServices by the structure module
         * @param {Object} compPointer
         * @returns {boolean} shouldKeepChildrenInPlace metaData of the component (not to be confused with the renderFlag 'enforceShouldKeepChildrenInPlace')
         */
        getShouldKeepChildrenInPlace,
        /**
         * Used internally in documentServices by the anchors module
         * @param {Object} compPointer
         * @returns {Number} height that can be used to calculate distance when creating bottom_parent anchors
         */
        getAnchorableHeight,

        /**
         * Used internally in documentServices by componentCode module to generate nicknames for the components
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {string} The default nickname that should be used for the component. Adds a 'mobile' prefix if the component is MobileOnly
         */
        getDefaultNickname: function getDefaultNickname(ps, componentPointer) {
            const shouldAddPrefix = isMobileOnly(ps, componentPointer) && !isNativeMobileOnlyByPointer(ps, componentPointer)
            const prefix = shouldAddPrefix ? 'mobile ' : ''
            const defaultNickname = getMetaData(ps, META_DATA_TYPES.NICKNAME, componentPointer)

            return _.camelCase(`${prefix}${defaultNickname}`)
        },

        /**
         * Used internally in documentServices by componentCode module to decide if we should auto generate nickname for the given component
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        shouldAutoSetNickname(ps, componentPointer) {
            // @ts-ignore
            return getMetaData(ps, META_DATA_TYPES.SHOULD_AUTO_SET_NICKNAME, componentPointer)
        },

        canConnectToCode(ps, componentPointer) {
            if (experiment.isOpen('dm_canConnectToCodeFromExtension')) {
                return extensionsAPI.componentsMetadata.canConnectToCode(ps, componentPointer)
            }
            return getMetaData(ps, META_DATA_TYPES.CAN_CONNECT_TO_CODE, componentPointer)
        },

        /**
         * Used internally in documentServices by serialization to decide if this component forces maintiaining IDs
         * even if the `serialize` function did not pass `maintainIdentifiers` as true.
         * @param {ps} ps
         * @param componentPointer
         * @returns {boolean}
         */
        shouldForceMaintainIDsOnSerialize(ps, componentPointer) {
            // @ts-ignore
            return getMetaData(ps, META_DATA_TYPES.FORCE_MAINTAIN_IDS_ON_SERIALIZE, componentPointer)
        },

        /**
         * Used internally in documentServices to define if component deletion should be processed through its parent deletion.
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {Boolean}
         */
        shouldBeRemovedByParent,

        /**
         * Used internally in documentServices to define if component should be hidden instead of removed.
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {Boolean}
         */
        shouldRemoveAsGhost,
        /**
         * Used internally in documentServices by the component module
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer
         * @returns {string} component type name
         */
        getComponentType: metaDataUtils.getComponentType
    }
})
