define([
    'documentServices/componentsMetaData/metaDataRegistrar',
    'documentServices/hooks/componentHooksRegistrar',
    'document-services-schemas',
    'documentServices/utils/contextAdapter',
    'experiment'
], function (metaDataRegistrar, componentHooksRegistrar, documentServicesSchemas, contextAdapter, experiment) {
    'use strict'
    const DEFAULT_OPTIONS = {
        registerComponentDefinitionAndSchemas: (...args) => documentServicesSchemas.services.schemasService.registerComponentDefinitionAndSchemas(...args), //wrapper function due to spies in dsCompRegistrar.spec
        registerSchemas: true,
        overrideComp: false
    }

    function informNoComponentType() {
        contextAdapter.utils.fedopsLogger.captureError(new Error('Could not dynamically register a component without componentType'), {
            tags: {dsComponentRegistration: true}
        })
    }

    const getRegistrarOptions = (ps, {overrideComp = true} = {overrideComp: true}) => ({
        ...DEFAULT_OPTIONS,
        registerSchemas: ps.config.schemaDevMode,
        overrideComp
    })

    function registerComponent(ps, component) {
        registerComponentWithOptions(component, DEFAULT_OPTIONS)
    }

    function registerComponentWithOptions({componentType, componentDefinition, hooks, metaData, dataSchema, propertiesSchema}, options = DEFAULT_OPTIONS) {
        const {registerComponentDefinitionAndSchemas, registerSchemas, overrideComp} = {...DEFAULT_OPTIONS, ...options}

        if (!componentType) {
            informNoComponentType()
            return
        }
        if (metaData) {
            contextAdapter.utils.fedopsLogger.breadcrumb(`registering metaData for component of type ${componentType}`)
            if (overrideComp) {
                metaDataRegistrar.unregisterExternalMetaData(componentType)
            }
            metaDataRegistrar.registerExternalMetaData(componentType, metaData)
        }

        if (hooks) {
            contextAdapter.utils.fedopsLogger.breadcrumb(`registering hooks for component of type ${componentType}`)
            componentHooksRegistrar.registerExternalHooks(componentType, hooks)
        }

        if (componentDefinition && componentDefinition[componentType].skins) {
            const {skins, responsiveSkins} = componentDefinition[componentType]
            contextAdapter.utils.fedopsLogger.breadcrumb(`registering skinByComponent type for component of type ${componentType}`)
            documentServicesSchemas.services.registerSkinsByCompType(componentType, {skins, responsiveSkins})
        }
        if (registerSchemas) {
            registerComponentDefinitionAndSchemas(
                componentType,
                {
                    componentDefinition,
                    dataSchemas: dataSchema,
                    propertiesSchemas: propertiesSchema
                },
                registerSchemas
            )
        }
    }

    async function loadAndRegisterComponent(componentType, loader, options) {
        const comp = await loader()
        registerComponentWithOptions({componentType, ...comp}, options)
    }

    async function registerComponentsFromExternalRegistryWithOptions(registry, {libraries, mode}, options = {registerSchemas: false}) {
        const optionsWithDefaults = {...DEFAULT_OPTIONS, ...options}

        if (experiment.isOpen('dm_newRegistry')) {
            return (
                registry
                    .default({
                        mode,
                        libraries
                    })
                    // eslint-disable-next-line promise/prefer-await-to-then
                    .then(async registryAPI => {
                        const componentLoaders = registryAPI.getComponentsLoaders()
                        Object.entries(componentLoaders).map(([componentType, loader]) => loadAndRegisterComponent(componentType, loader, optionsWithDefaults))
                    })
            )
        }

        const asyncLoadOps = []
        registry.default({
            mode,
            libraries,
            registerComponent: (componentType, loader) => {
                const loadOp = loadAndRegisterComponent(componentType, loader, optionsWithDefaults)
                asyncLoadOps.push(loadOp)
            }
        })

        await Promise.all(asyncLoadOps)
    }

    /** Register all components of an external registry
     *
     * The registry should comply with the interface specified in @wix/editor-elements-registry/2.0/documentManagement
     * Namely, the registry should provide a `default` method which accepts an object with a `registerComponent` method.
     * The registry will call `registerComponent` for each component it holds. It will pass as params the name
     * of the components and an async loader function that resolves to the component specification.
     * The component specification is the same object that `registerComponent` expects.
     *
     * @param registry
     * @param registryOptions
     * @returns {Promise<void>}
     */
    async function registerComponentsFromExternalRegistry(registry, registryOptions) {
        await registerComponentsFromExternalRegistryWithOptions(registry, registryOptions, DEFAULT_OPTIONS)
    }

    return {
        getRegistrarOptions,
        registerComponent,
        registerComponentWithOptions,
        registerComponentsFromExternalRegistry,
        registerComponentsFromExternalRegistryWithOptions
    }
})
