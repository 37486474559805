export {createDMCore, DocumentManager, CoreConfig} from './documentManagerCore'
export type {ValidateValue, ValidatorResult} from './dal/validation/dalValidation'
export {Getter, SetterSet, DAL, TransactionRejectionError, TransactionEvents, DalValueChangeCallback} from './dal/documentManagerDal'
export * as pointerUtils from './utils/pointerUtils'
export {pickBy, findBy} from './utils/pickBy'
export * as store from './dal/store'
export {DalValue, DalItem, DalStore, DalJsStore, DalJsNamespace, DmStore, createStoreFromJS, createStore} from './dal/store'
export * from './dal/snapshot/snapshotDal'
export * from './utils/deepCompare'
export * from './utils/deepCompareIgnoreSignature'
export * from './utils/dalValueChangesLogger'
export {debug, setDebug} from './utils/debug'
export * from './types'
export * from './eventEmitter'
export * from './ExperimentInstance'
export {Events} from './eventEmitter'
export {QueryIndex, FilterFactory, ValueToIndexKeys, ValueToIndexIds} from './dal/queryIndex/queryIndex'
export * from './dal/signatures/isConflicting'
