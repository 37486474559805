import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import wixBI from '../bi/wixBI'
import beat from './beat'
import imageContext from './services/imageContext'

const MAX_FAILED_ITEMS = 8

function getFailedImagesDetails(failDetails) {
    return JSON.stringify({a: _.take(failDetails, MAX_FAILED_ITEMS)})
}

function getCommonEventParams(siteData, context) {
    const images = context.getImages()
    const totalSizeKb = images.totalSize >= 0 ? Math.round(images.totalSize / 1024) : ''
    const {biData} = siteData

    return {
        src: 29,
        d: biData.getTime().totalLoadingTime,
        tn: images.total,
        sid: siteData.siteId,
        msid: siteData.getMetaSiteId(),
        pid: context.getPageId(),
        pn: biData.getPageNumber(),
        vsi: siteData.wixBiSession.viewerSessionId,
        vid: warmupUtilsLib.cookieUtils.getCookie('svSession'),
        s: totalSizeKb
    }
}

function buildParams(siteData, context, more) {
    const eventParams = _.defaults(getCommonEventParams(siteData, context), more)

    return {
        adapter: 'm',
        queryString: warmupUtilsLib.urlUtils.toQueryString(eventParams)
    }
}

function reportProgressEvent(siteData, context, index) {
    const images = context.getImages()

    wixBI.report(
        siteData,
        buildParams(siteData, context, {
            evid: 11,
            i: index,
            sn: images.success,
            fn: images.fail,
            fd: getFailedImagesDetails(images.failDetails[index - 1])
        })
    )
}

function reportFinishSuccessEvent(siteData, context) {
    wixBI.report(
        siteData,
        buildParams(siteData, context, {
            evid: 12
        })
    )
}

function reportFinishFailEvent(siteData, context) {
    const images = context.getImages()

    wixBI.report(
        siteData,
        buildParams(siteData, context, {
            evid: 13,
            fn: images.fail,
            fd: getFailedImagesDetails(_.flatten(images.failDetails))
        })
    )
}

function isEnabled(siteData, sampleRatio) {
    return (
        siteData.isViewerMode() &&
        beat.shouldIncludeInSampleRatio(siteData, sampleRatio) &&
        !warmupUtilsLib.stringUtils.isTrue(siteData.currentUrl.query.suppressbi)
    )
}

export default function (siteData, imageItems, sampleRatio?) {
    if (!isEnabled(siteData, sampleRatio || 10)) {
        return
    }

    const currentPageId = siteData.biData.getPageId()
    let context = imageContext.getCurrent(siteData)

    if (!context || context.getPageId() !== currentPageId) {
        if (context) {
            context.destroy()
        }

        context = imageContext.create(siteData, currentPageId, reportProgressEvent, reportFinishSuccessEvent, reportFinishFailEvent)
    }

    context = context.setImages(imageItems)

    return context
}
