import displayDataTransformer from './displayDataTransformPlugins/displayDataTransformer'
import aspectDataTransformPlugin from './displayDataTransformPlugins/aspectDataTransformPlugin'
import pageDataTransformPlugin from './displayDataTransformPlugins/pageDataTransformPlugin'
import renderFlagsTransformPlugin from './displayDataTransformPlugins/renderFlagsTransformPlugin'
import initRootMapTransformPlugin from './displayDataTransformPlugins/initRootMapTransformPlugin'
import platformTransformPlugin from './displayDataTransformPlugins/platformTransformPlugin'
import renderRealtimeConfigTransformPlugin from './displayDataTransformPlugins/renderRealtimeConfigTransformPlugin'
import rendererModelTransformPlugin from './displayDataTransformPlugins/rendererModelTransformPlugin'

const deepMapRootTransformPlugin = initRootMapTransformPlugin(false)
const shallowMapRootTransformPlugin = initRootMapTransformPlugin(true)

displayDataTransformer.registerTransformPlugin('siteAspectsData', aspectDataTransformPlugin)
displayDataTransformer.registerTransformPlugin('pagesData', pageDataTransformPlugin)
displayDataTransformer.registerTransformPlugin('renderFlags', renderFlagsTransformPlugin)
displayDataTransformer.registerTransformPlugin('pageStubComponents', shallowMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('nativeComponents', shallowMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('wixappsRenderCounters', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('customUrlMapping', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('svgShapes', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('platformWidgetsState', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('platform', platformTransformPlugin)
displayDataTransformer.registerTransformPlugin('mediaQualityStore', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('activeModes', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('resolvedDataMaps', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('mobileStructures', deepMapRootTransformPlugin)
displayDataTransformer.registerTransformPlugin('rendererModel', rendererModelTransformPlugin)
displayDataTransformer.registerTransformPlugin('renderRealtimeConfig', renderRealtimeConfigTransformPlugin)
