define([
    'lodash',
    'documentServices/constants/constants',
    'experiment',
    '@wix/santa-core-utils',
    'documentServices/utils/utils',
    'documentServices/utils/runtimeConfig'
], function (_, constants, experiment, santaCoreUtils, dsUtils, runtimeConfig) {
    'use strict'
    const isMobileSupported = ps => (ps ? ps.runtimeConfig.supportMobile : true)
    const {stripHashIfExists} = dsUtils
    const {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES, DATA_TYPES, DATA_TYPES_VALUES_WITH_HASH} = constants

    const syncMobileAndDesktopByDataType = (ps, sourceComponentPointer, dataType, id, isFull) => {
        const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[dataType] ? `#${id}` : id
        if (!isMobileSupported(ps)) {
            return
        }

        const dataTypeQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
        const dal = isFull ? ps.dal.full : ps.dal
        const pointers = isFull ? ps.pointers.full : ps.pointers

        const targetTypePointer =
            sourceComponentPointer.type === VIEW_MODES.DESKTOP
                ? pointers.components.getMobilePointer(sourceComponentPointer)
                : pointers.components.getDesktopPointer(sourceComponentPointer)

        if (!dal.isExist(targetTypePointer)) {
            return
        }

        const targetDataTypeIdPointer = ps.pointers.getInnerPointer(targetTypePointer, dataTypeQueryKey)
        dal.set(targetDataTypeIdPointer, itemIdWithHashIfNeeded)
    }

    const syncMobileAndDesktopStyleId = (ps, sourceComponentPointer, styleId, isFull) =>
        syncMobileAndDesktopByDataType(ps, sourceComponentPointer, DATA_TYPES.theme, styleId, isFull)

    const syncMobileAndDesktopSkin = (ps, compPtr, skin) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const mobileCompPtr = ps.pointers.components.getMobilePointer(compPtr)
        const compSkinPointer = ps.pointers.getInnerPointer(mobileCompPtr, 'skin')
        if (ps.dal.get(compSkinPointer)) {
            ps.dal.set(compSkinPointer, skin)
        }
    }

    const deleteForkedData = (ps, desktopPointer, componentData, dataModel, design) => {
        if (!isMobileSupported(ps)) {
            return
        }
        const mobilePointer = ps.pointers.components.getMobilePointer(desktopPointer)

        if (ps.dal.isExist(mobilePointer)) {
            const isDesignForked =
                runtimeConfig.isSanta(ps) && !_.isEqual(design.getDesignItemPointer(ps, desktopPointer), design.getDesignItemPointer(ps, mobilePointer))
            const arePropsForked = componentData.isMobileComponentPropertiesSplit(ps, mobilePointer)
            if (isDesignForked) {
                dataModel.deleteDesignItem(ps, mobilePointer)
            }
            if (arePropsForked) {
                dataModel.deletePropertiesItem(ps, mobilePointer)
            }
        }
    }

    const updateMobilePropertyIfNeeded = (ps, pointer, propertyItem, updatePropertyFn) => {
        if (!isMobileSupported(ps)) {
            return false
        }
        if (!ps.pointers.components.isMobile(pointer)) {
            const mobilePtr = ps.pointers.components.getMobilePointer(pointer)
            updatePropertyFn(ps, mobilePtr, propertyItem)
        }
    }

    const removeMobilePropertyOverrideIfNeeded = (ps, compPointer, removePropertyFn) => {
        if (!isMobileSupported(ps)) {
            return false
        }
        if (!ps.pointers.components.isMobile(compPointer)) {
            const mobilePtr = ps.pointers.components.getMobilePointer(compPointer)
            removePropertyFn(ps, mobilePtr)
        }
    }

    const serializeMobileStructure = (
        ps,
        componentPointer,
        compStructure,
        pageId,
        flatMobileStructuresMap,
        structureEnricher,
        flags,
        resolveDataItems,
        serializeComponentStructureAndData,
        {componentStructureInfo, mobileConversionFacade, dataModel}
    ) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const propertyQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.prop]
        const mobileStructurePropertyQuery = _.get(compStructure, ['mobileStructure', propertyQueryKey])
        if (mobileStructurePropertyQuery) {
            resolveMobilePropertiesOnStructure(ps, compStructure, pageId, dataModel, mobileStructurePropertyQuery)
            return
        }

        const mobileComponentPointer = ps.pointers.components.getMobilePointer(componentPointer)
        //if this comp already has a mobile instance, simply serialize it but without its children
        if (experiment.isOpen('presetUploaderAddOn')) {
            if (componentStructureInfo.isExist(ps, mobileComponentPointer)) {
                const mobileFlags = {ignoreChildren: true, maintainIdentifiers: false}
                compStructure.mobileStructure = serializeComponentStructureAndData(ps, mobileComponentPointer, null, null, structureEnricher, mobileFlags)
            }
            return
        }
        if (ps.pointers.components.isPage(componentPointer)) {
            return
        }
        //otherwise, check if the comp being serialized has a mobileStructure preset saved for later when switching to mobile mode, and if so then take it
        const mobileStructure = flatMobileStructuresMap[componentPointer.id]
        if (mobileStructure && !mobileStructure.isCustomPreset) {
            resolveDataItems(ps, mobileStructure, undefined, flags, pageId, structureEnricher)
            compStructure.mobileStructure = {
                layout: _.cloneDeep(mobileStructure.layout),
                props: _.cloneDeep(mobileStructure.props)
            }
            return
        }
        if (flags.shouldAddMobilePresets) {
            mobileConversionFacade.createMobilePreset(ps, pageId, componentPointer, compStructure)
        }
    }

    function resolveMobilePropertiesOnStructure(ps, structure, pageId, dataModel, mobileStructurePropertyQuery) {
        const propertyQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.prop]
        if (mobileStructurePropertyQuery !== _.get(structure, [propertyQueryKey])) {
            const sanitiziedPropertyQuery = stripHashIfExists(mobileStructurePropertyQuery)
            const propsPointer = ps.pointers.data.getPropertyItem(sanitiziedPropertyQuery, pageId)
            structure.mobileStructure.props = dataModel.serializeDataItem(ps, DATA_TYPES.prop, propsPointer, false)
            delete structure.mobileStructure.propertyQuery
        }
    }

    const linkMobileComponentToDesktopDesignItem = (ps, compPointer, designId) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const designQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design]

        const getCompDesktopDesignQueries = () => {
            const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
            const overrides = ps.dal.full.isExist(overridesPointer) && ps.dal.full.get(overridesPointer)
            const queriesFromOverrides = _.map(overrides, designQueryKey)
            const designQueryPointer = ps.pointers.getInnerPointer(compPointer, designQueryKey)
            let structureDesignQuery
            if (santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)) {
                structureDesignQuery = ps.dal.get(designQueryPointer)
            } else {
                structureDesignQuery = ps.dal.full.get(designQueryPointer)
            }
            return queriesFromOverrides.concat(structureDesignQuery)
        }

        if (compPointer.type === VIEW_MODES.MOBILE) {
            return
        }
        const compDesktopDesignQueries = getCompDesktopDesignQueries()

        const mobileCompPointer = ps.pointers.components.getMobilePointer(compPointer)
        if (ps.dal.isExist(mobileCompPointer)) {
            const mobileDesignPointer = ps.pointers.getInnerPointer(mobileCompPointer, designQueryKey)
            const mobileDesignQuery = ps.dal.get(mobileDesignPointer)
            if (!_.includes(compDesktopDesignQueries, mobileDesignQuery)) {
                ps.dal.set(mobileDesignPointer, `#${designId}`)
            }
        }
    }

    const removeComponentByDesktopComp = (ps, desktopPointer, isFull) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const pointers = isFull ? ps.pointers.full : ps.pointers
        const dal = isFull ? ps.dal.full : ps.dal

        const mobileComponentPointer = pointers.components.getMobilePointer(desktopPointer)
        if (dal.isExist(mobileComponentPointer)) {
            dal.remove(mobileComponentPointer)
        }
    }

    const removeMobileCropOverrideIfNeeded = (ps, compRef, imageSourceWasChanged, {dataModel, component}) => {
        if (!isMobileSupported(ps)) {
            return
        }
        const mobileCompRef = ps.pointers.components.getMobilePointer(compRef)

        if (!imageSourceWasChanged || !component.isExist(ps, mobileCompRef)) {
            return
        }

        const mobileCompProperties = dataModel.getPropertiesItem(ps, mobileCompRef)
        dataModel.setPropertiesItem(ps, mobileCompRef, _.assign(mobileCompProperties, {overrideCrop: null}))
    }

    const syncDockData = (ps, desktopRootComp, desktopDockData, structure) => {
        if (!isMobileSupported(ps)) {
            return
        }
        structure.setDock(ps, ps.pointers.components.getMobilePointer(desktopRootComp), desktopDockData)
    }

    const prepareComponentStructureForMobileAlgorithm = (ps, componentModes, compPointer) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const designQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design]
        const compMobileMode = componentModes.getCompMobileMode(ps, compPointer)
        componentModes.activateComponentMode(ps, compPointer, compMobileMode.modeId)

        const mobileCompPointer = ps.pointers.components.getMobilePointer(compPointer)
        if (ps.dal.isExist(mobileCompPointer)) {
            const designQueryInDesktop = ps.dal.get(ps.pointers.getInnerPointer(compPointer, designQueryKey))
            ps.dal.set(ps.pointers.getInnerPointer(mobileCompPointer, designQueryKey), designQueryInDesktop)
        }
    }

    const setMobileHiddenComponentListIfNeeded = (ps, originalPageId, newPageId, originalToNewIdMap, page, mobileActions) => {
        if (!isMobileSupported(ps)) {
            return
        }

        if (page.mobileComponents) {
            const originalPageHiddenCompIds = mobileActions.hiddenComponents.get(ps, originalPageId)
            const newPageHiddenCompIds = _(originalToNewIdMap).pick(originalPageHiddenCompIds).values().value()
            mobileActions.hiddenComponents.set(ps, newPageId, newPageHiddenCompIds)
        }
    }

    const serializeMobileChildren = (
        ps,
        compStructure,
        pageId,
        flags,
        flatMobileStructuresMap,
        structureEnricher,
        useOriginalLanguage,
        serializeComponentStructureAndData
    ) => {
        if (!isMobileSupported(ps)) {
            return
        }

        if (ps.pointers.page.isExists(compStructure.id)) {
            const mobilePagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
            const mobileChildrenPointers = ps.pointers.full.components.getChildren(mobilePagePointer)
            compStructure.mobileComponents = _.map(mobileChildrenPointers, function (mobileChildPointer) {
                return serializeComponentStructureAndData(ps, mobileChildPointer, null, flatMobileStructuresMap, structureEnricher, flags, useOriginalLanguage)
            })
        }
    }

    const initMobileComponents = (ps, pageCompPointer) => {
        if (!isMobileSupported(ps)) {
            return
        }
        const mobileComponentsPointer = ps.pointers.getInnerPointer(pageCompPointer, 'mobileComponents')
        if (ps.dal.full.isExist(mobileComponentsPointer)) {
            return
        }
        ps.dal.full.set(mobileComponentsPointer, [])
    }

    const setMobileViewMode = (ps, hooks) => {
        if (isMobileSupported(ps)) {
            hooks.executeHook(hooks.HOOKS.SWITCH_VIEW_MODE.MOBILE)
        }
    }

    const getChildComponent = (ps, componentStructure) =>
        isMobileSupported(ps) && componentStructure.mobileComponents && !_.isEmpty(componentStructure.mobileComponents)
            ? componentStructure.mobileComponents
            : []

    const resetMobileComponentsForClonedComp = (ps, serializedComp, clonedSerializedComp) => {
        if (serializedComp.mobileComponents) {
            clonedSerializedComp.mobileComponents = []
        }
    }

    const setMobileComponentChildrenDefinition = (
        ps,
        serializedComp,
        pageId,
        oldToNewIdMap,
        modeIdsInHierarchy,
        activeModes,
        setComponentChildrenDefinition
    ) => {
        if (!isMobileSupported(ps)) {
            return
        }

        const mobileChildren = serializedComp.mobileComponents
        if (mobileChildren) {
            const mobilePageComponentPointer = ps.pointers.components.getPage(pageId, VIEW_MODES.MOBILE)
            setComponentChildrenDefinition(ps, pageId, mobileChildren, null, mobilePageComponentPointer, oldToNewIdMap, modeIdsInHierarchy, activeModes)
        }
    }

    const getViewMode = (ps, viewMode, documentModeInfo) => {
        if (!isMobileSupported(ps)) {
            return VIEW_MODES.DESKTOP
        }
        return viewMode || documentModeInfo.getViewMode(ps)
    }

    const getSupportedViewModes = ps => (!isMobileSupported(ps) ? [VIEW_MODES.DESKTOP] : [VIEW_MODES.DESKTOP, VIEW_MODES.MOBILE])

    return {
        isMobileSupported,
        syncMobileAndDesktopStyleId,
        syncMobileAndDesktopSkin,
        syncMobileAndDesktopByDataType,
        deleteForkedData,
        updateMobilePropertyIfNeeded,
        removeMobilePropertyOverrideIfNeeded,
        serializeMobileStructure,
        linkMobileComponentToDesktopDesignItem,
        removeComponentByDesktopComp,
        syncDockData,
        prepareComponentStructureForMobileAlgorithm,
        setMobileHiddenComponentListIfNeeded,
        serializeMobileChildren,
        getChildComponent,
        resetMobileComponentsForClonedComp,
        setMobileViewMode,
        setMobileComponentChildrenDefinition,
        getViewMode,
        getSupportedViewModes,
        wPhoto: {
            removeMobileCropOverrideIfNeeded
        },
        page: {
            initMobileComponents
        }
    }
})
