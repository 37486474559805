import _ from 'lodash'
import tpaPostMessageCommon from '@wix/santa-ds-libs/src/tpa/src/common/tpaPostMessageCommon'
import messageHandlers from '@wix/santa-ds-libs/src/tpa/src/handlers/tpaHandlers'
import tpaPubSubHandlers from '@wix/santa-ds-libs/src/tpa/src/handlers/tpaPubSubHandlers'
import tpaHandlersCommon from '@wix/santa-ds-libs/src/tpa/src/handlers/tpaHandlersCommon'
import * as tpaComponents from '../../../_internal/tpaComponents'
import tpaBi from '@wix/santa-ds-libs/src/tpa/src/common/tpaBi'
import pmrpc from 'pm-rpc'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const superAppsOnlyHandlers = {
    getCtToken: true,
    refreshCurrentMember: true
}

function bindApiMethods(dataStructure, boundArgs) {
    if (_.isFunction(dataStructure)) {
        return _.partial(dataStructure, boundArgs)
    }
    if (_.isObject(dataStructure)) {
        return _.mapValues(dataStructure, childData => bindApiMethods(childData, boundArgs))
    }

    return dataStructure
}

const isPubSubMessage = function (msg) {
    if (_.has(msg, 'data') && !_.isNull(msg.data)) {
        return msg.data.eventKey && coreUtils.stringUtils.startsWith(msg.data.eventKey, tpaComponents.common.utils.Constants.TPA_PUB_SUB_PREFIX)
    }
}

const getHandlers = function (msg) {
    if (isPubSubMessage(msg)) {
        return tpaPubSubHandlers
    } else if (isTPAWorkerId(msg.compId)) {
        return messageHandlers.tpaWorker
    }

    return messageHandlers
}

const callHandler = function (siteAPI, msg, response) {
    if (shouldHandleMessage(siteAPI, msg.compId, msg.type) && !messagePostponed(siteAPI, msg, response)) {
        const msgType = tpaPostMessageCommon.fixOldPingPongMessageType(msg.type)
        const handlers = getHandlers(msg)
        if (handlers[msgType]) {
            handlers[msgType].call(this, siteAPI, msg, response)
            if (isEventSupported(siteAPI, msg)) {
                const comp = siteAPI.getComponentById(msg.compId)
                tpaBi.sendBIEvent(
                    msg,
                    msg.origin || 'viewer',
                    _.get(comp, 'props.compData'),
                    tpaComponents.common.utils.getClientSpecMap(siteAPI),
                    siteAPI.reportBI
                )
            }
        }
    }
}

const getRpcHandlers = function (siteAPI) {
    return bindApiMethods(
        {
            openModal: tpaHandlersCommon.openModal,
            openPopup: tpaHandlersCommon.openPopup
        },
        siteAPI
    )
}

function isTPAWorkerId(id) {
    return id && coreUtils.stringUtils.startsWith(id, 'tpaWorker_')
}

function shouldHandleMessage(siteAPI, compId, msgType) {
    const comp = compId && siteAPI.getComponentById(compId)

    return (
        (msgType !== 'appStateChanged' || (comp && _.includes(siteAPI.getAllRenderedRootIds(), comp.props.rootId))) &&
        isHandlerAllowedForApp(siteAPI, compId, msgType)
    )
}

function isHandlerAllowedForApp(siteAPI, compId, msgType) {
    return !superAppsOnlyHandlers[msgType] || tpaComponents.services.clientSpecMapService.isSuperAppByCompId(siteAPI, compId)
}

function isEventSupported(siteAPI, msg) {
    if (msg.type === 'registerEventListener' && !isPubSubMessage(msg)) {
        return _.includes(tpaComponents.common.supportedSiteEvents, msg.data.eventKey)
    }
    return true
}

function messagePostponed(siteAPI, msg, response) {
    if (!msg.compId) {
        return false
    }
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp) {
        return false
    }
    siteAPI.getSiteAspect('tpaPostMessageAspect').queueMessage({msg, response})
    return true
}

/**
 *
 * @constructor
 * @param {core.SiteAspectsSiteAPI} siteAPI
 */
const Aspect = function (siteAPI) {
    _.bindAll(this, _.functionsIn(this))
    siteAPI.registerToMessage(this.handleTPAMessage)
    //TODO isAddPanelView check should be removed when addPanel preview will be moved out of Santa-Editor(SE-21355)
    if (!siteAPI.getSiteData().isInSSR() && !siteAPI.getSiteData().isAddPanelView) {
        pmrpc.api.set('tpaHandlers', getRpcHandlers(siteAPI))
    }
    this._siteAPI = siteAPI

    this._messageQueue = []
    siteAPI.registerToOnFullyRendered(() => {
        _.forEach(this._messageQueue, ({msg, response}) => {
            callHandler(this._siteAPI, msg, response)
        })
        this._messageQueue.length = 0
    })
}

Aspect.prototype = {
    handleTPAMessage(event) {
        tpaPostMessageCommon.handleTPAMessage.call(this, undefined, this._siteAPI, callHandler, event)
    },

    sendPostMessage(comp, data) {
        const iframe = comp.getIframe()

        if (iframe) {
            tpaPostMessageCommon.callPostMessage(iframe, data)
        } else if (this._siteAPI.getSiteData().isDebugMode()) {
            // @ts-ignore
            throw new Error('No iframe found in TPA component', comp)
        }
    },

    queueMessage(item) {
        this._messageQueue.push(item)
    },

    callHandler
}

export default Aspect
