define([
    'lodash',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/theme/theme',
    'documentServices/constants/constants',
    'documentServices/mobileUtilities/mobileUtilities',
    'document-services-schemas',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, componentStylesAndSkinsAPI, theme, constants, mobileUtil, documentServicesSchemas, extensionsAPI) {
    'use strict'

    const pageSystemStyles = documentServicesSchemas.services.schemasService.getDefinition('mobile.core.components.Page').styles

    const setDefaultStyle = (ps, pagePtr) => {
        const styleId = _.keys(pageSystemStyles).sort()[0]
        const stylePointer = ps.pointers.data.getThemeItem(styleId, constants.MASTER_PAGE_ID)
        if (!ps.dal.isExist(stylePointer)) {
            const skin = pageSystemStyles[styleId]
            theme.styles.createAndAddStyleItem(ps, {styleType: constants.STYLES.TYPES.SYSTEM, skin}, styleId, constants.MASTER_PAGE_ID)
        }
        componentStylesAndSkinsAPI.style.setId(ps, pagePtr, styleId)
    }

    const isStyleExist = (ps, styleId, pageId) =>
        ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, pageId)) || ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, constants.MASTER_PAGE_ID))

    function exec(ps) {
        const allPageIds = extensionsAPI.pages.getAllPagesIds(ps)
        const viewModes = mobileUtil.getSupportedViewModes(ps)
        _.forEach(allPageIds, pageId => {
            _.forEach(viewModes, viewMode => {
                const pagePtr = ps.pointers.components.getPage(pageId, viewMode)
                const pageStyleId = componentStylesAndSkinsAPI.style.getId(ps, pagePtr)
                if (pageStyleId && !isStyleExist(ps, pageStyleId, pageId)) {
                    setDefaultStyle(ps, pagePtr)
                }
            })
        })
    }

    return {
        exec,
        name: 'addMissingPageStyleFixer',
        version: 1
    }
})
