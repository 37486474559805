import _ from 'lodash'
import $ from 'zepto'
import logger from '../loggingUtils/logger/logger'
import biEvents from '../loggingUtils/bi/events'

const getCurrentScrollY = () => _.get(window, ['pageYOffset'], window.scrollY)
const getCurrentBottomY = () => Math.round(getCurrentScrollY() + window.innerHeight)

const onScroll = () => {
    const currentPixelReached = getCurrentBottomY()
    // @ts-ignore
    window.sssr.maxScrollData.maxPixelReached = Math.max(window.sssr.maxScrollData.maxPixelReached, currentPixelReached)
}

const reportMaxScroll = siteData => {
    // @ts-ignore
    const scrollData = window.sssr.maxScrollData
    logger.reportBI(siteData, biEvents.MAX_SCROLL_BTWN_BEAT2_BEAT3, {
        maxScroll: scrollData.maxPixelReached,
        pageLength: scrollData.pageLength
    })
}

export default {
    register: siteData => {
        // @ts-ignore
        window.sssr.maxScrollData = {
            pageLength: _.get(siteData, ['measureMap', 'height', 'masterPage']),
            maxPixelReached: getCurrentBottomY()
        }
        $(window).on('scroll', onScroll)
    },
    unregister: () => $(window).off('scroll', onScroll),
    reportMaxScroll
}
