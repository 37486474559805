import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

/**
 * @class components.Toggle
 * @extends {core.skinBasedComp}
 */

const toggle = {
    displayName: 'Toggle',
    mixins: [componentsCore.mixins.skinBasedComp],

    getInitialState() {
        return this.getCssState(this.props)
    },

    componentWillReceiveProps(props) {
        this.setState(this.getCssState(props))
    },

    getCssState(props) {
        return {$default: props.initialState || 'off'}
    },

    getSkinProperties() {
        const onProxyInstance = this.props.children[0]
        const offProxyInstance = this.props.children[1]

        return {
            on: {children: this.props.initialState === 'on' ? [onProxyInstance] : []},
            off: {children: this.props.initialState === 'off' ? [offProxyInstance] : []}
        }
    }
}

componentsCore.compRegistrar.register('wixapps.integration.components.Toggle', toggle)

export default toggle
