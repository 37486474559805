define(['documentServices/componentsMetaData/metaDataUtils', 'documentServices/constants/constants'], function (metaDataUtils, consts) {
    'use strict'

    function isContainable(ps, compPointer, containerPointer) {
        const potentialContainerCompType = metaDataUtils.getComponentType(ps, containerPointer)
        return metaDataUtils.isSiteStructure(potentialContainerCompType)
    }

    return {
        canBeFixedPosition: true,
        removable: false,
        resizableSides(ps, pointer) {
            return pointer.type === consts.VIEW_MODES.MOBILE
                ? [consts.RESIZE_SIDES.BOTTOM]
                : [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT, consts.RESIZE_SIDES.TOP, consts.RESIZE_SIDES.BOTTOM]
        },
        moveDirections(ps, pointer) {
            return pointer.type === consts.VIEW_MODES.MOBILE ? [] : [consts.MOVE_DIRECTIONS.VERTICAL, consts.MOVE_DIRECTIONS.HORIZONTAL]
        },
        duplicatable: false,
        containable: isContainable,
        containableByStructure: isContainable,
        fullWidth(ps, pointer) {
            return pointer.type === consts.VIEW_MODES.MOBILE
        },
        enforceContainerChildLimitsByHeight: true,
        styleCanBeApplied: false,
        hiddenable: true,
        collapsible: true,
        mobileConversionConfig: {
            preserveAspectRatio: false,
            siteSegmentRole: 'SUBHEADER',
            category: 'siteSegments',
            isTightContainer: true,
            hasTightMarginBetweenChildren: true
        }
    }
})
