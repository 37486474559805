define(['lodash', 'documentServices/theme/theme'], function (_, theme) {
    'use strict'

    const supportedEvents = [
        'COMPONENT_DISCONNECT',
        'COMPONENT_DELETED',
        'EDIT_MODE_CHANGE',
        'SITE_PUBLISHED',
        'SETTINGS_UPDATED',
        'WINDOW_PLACEMENT_CHANGED',
        'THEME_CHANGE',
        'DEVICE_TYPE_CHANGED',
        'STYLE_PARAMS_CHANGE',
        'SITE_SAVED',
        'ON_MESSAGE_RESPONSE',
        'PUBLIC_DATA_CHANGED',
        'REVISION_CHANGED'
    ]

    let editModeChangeCallbacks = {}
    let editorEventCallbacks = {}
    let settingsUpdatedCallbacks = {}
    let windowPlacementChangedCallbacks = {}
    let sitePublishedCallbacks = {}
    let deviceTypeChangeCallbacks = {}
    let themeChangeCallbacks = {}
    let siteSavedCallbacks = {}
    let deleteHandlers = {}
    let disconnectHandlers = {}
    let historyChangeHandlers = {}
    const publicDataChangedCallbacks = {}
    const compLoadedCallbacks = []
    const compsLoaded = []

    const registerDeleteCompHandler = function (compId, callback) {
        deleteHandlers[compId] = callback
    }

    const registerDisconnectCompHandler = function (compId, callback) {
        disconnectHandlers[compId] = callback
    }

    const registerHistoryChangeHandler = function (compId, callback) {
        historyChangeHandlers[compId] = callback
    }

    const executeDeleteHandler = function (compId) {
        if (isDeleteHandlerExists(compId)) {
            deleteHandlers[compId]()
            delete deleteHandlers[compId]
        }
    }

    const executeDisconnectHandler = function (compId) {
        if (isDisconnectHandlerExists(compId)) {
            disconnectHandlers[compId]()
            delete disconnectHandlers[compId]
        }
    }

    const executeHistoryChangeHandler = function (compId) {
        if (isHistoryChangeHandlerExists(compId)) {
            historyChangeHandlers[compId]()
        }
    }

    const isDeleteHandlerExists = function (compId) {
        return !_.isUndefined(deleteHandlers[compId])
    }

    const isDisconnectHandlerExists = function (compId) {
        return !_.isUndefined(disconnectHandlers[compId])
    }

    const isHistoryChangeHandlerExists = function (compId) {
        return !_.isUndefined(historyChangeHandlers[compId])
    }

    const unRegisterHandlers = function (ps, compId) {
        editModeChangeCallbacks = _.omit(editModeChangeCallbacks, compId)
        editorEventCallbacks = _.omit(editorEventCallbacks, compId)
        settingsUpdatedCallbacks = _.omit(settingsUpdatedCallbacks, compId)
        windowPlacementChangedCallbacks = _.omit(windowPlacementChangedCallbacks, compId)
        sitePublishedCallbacks = _.omit(sitePublishedCallbacks, compId)
        deviceTypeChangeCallbacks = _.omit(deviceTypeChangeCallbacks, compId)
        siteSavedCallbacks = _.omit(siteSavedCallbacks, compId)
        deleteHandlers = _.omit(deleteHandlers, compId)
        disconnectHandlers = _.omit(disconnectHandlers, compId)
        historyChangeHandlers = _.omit(historyChangeHandlers, compId)

        if (!_.isUndefined(themeChangeCallbacks[compId])) {
            theme.events.onChange.removeListener(ps, themeChangeCallbacks[compId])
            themeChangeCallbacks = _.omit(themeChangeCallbacks, compId)
        }
    }

    const registerEditModeChangeHandler = function (compId, callback) {
        editModeChangeCallbacks[compId] = callback
    }

    const registerEditorEventHandler = function (compId, callback) {
        editorEventCallbacks[compId] = callback
    }

    const registerSitePublishedHandler = function (compId, callback) {
        sitePublishedCallbacks[compId] = callback
    }

    const registerThemeChangeHandler = function (ps, compId, callback) {
        if (_.isUndefined(themeChangeCallbacks[compId])) {
            const listenerId = theme.events.onChange.addListener(ps, callback)
            themeChangeCallbacks[compId] = listenerId
        }
    }

    const registerPublicDataChangedHandler = function (compId, applicationId, callback) {
        if (!publicDataChangedCallbacks[applicationId]) {
            publicDataChangedCallbacks[applicationId] = {}
        }
        const callbacksForApp = publicDataChangedCallbacks[applicationId]
        callbacksForApp[compId] = callback
    }

    const registerSettingsUpdatedHandler = function (compId, callback) {
        settingsUpdatedCallbacks[compId] = callback
    }

    const registerWindowPlacementChangedHandler = function (compId, callback) {
        windowPlacementChangedCallbacks[compId] = callback
    }

    const registerDeviceTypeChangeHandler = function (compId, callback) {
        deviceTypeChangeCallbacks[compId] = callback
    }

    const registerSiteSavedHandler = function (compId, callback) {
        siteSavedCallbacks[compId] = callback
    }

    const editModeChange = function (editorMode) {
        _.forEach(editModeChangeCallbacks, function (callback) {
            callback(editorMode)
        })
    }

    const triggerEditorEvent = function (msg) {
        _.forEach(editorEventCallbacks, function (callback) {
            callback(msg)
        })
    }

    const sitePublished = function () {
        _.forEach(sitePublishedCallbacks, function (callback) {
            callback()
        })
    }

    const siteSaved = function () {
        _.forEach(siteSavedCallbacks, function (callback) {
            callback()
        })
    }

    const deviceTypeChange = function (deviceType) {
        _.forEach(deviceTypeChangeCallbacks, function (callback) {
            callback(deviceType)
        })
    }

    const triggerOnWindowPlacementChanged = function (msg) {
        const {placement} = msg.data
        const {compId} = msg
        const callback = windowPlacementChangedCallbacks[compId]
        if (callback) {
            callback(placement)
        }
    }

    const callSettingsUpdateCallback = function (ps, compId, message, appDefinitionId) {
        const callback = settingsUpdatedCallbacks[compId]
        if (callback) {
            callback(message)
        }

        if (ps.siteAPI.triggerOnAppSettingsUpdate) {
            ps.siteAPI.triggerOnAppSettingsUpdate({compId, appDefinitionId, updates: message})
        }
    }

    const callPublicDataChangedCallbackForAllAppRegisteredComps = function (applicationId, data) {
        const callbacks = publicDataChangedCallbacks[applicationId]
        _.forEach(callbacks, function (callback) {
            callback(data)
        })
    }

    const callPublicDataChangedCallback = function (compId, applicationId, data) {
        const callbacks = publicDataChangedCallbacks[applicationId]
        const callback = _.get(callbacks, compId)
        if (callback) {
            callback(data)
        }
    }

    const callCompLoaded = function (compPointer) {
        compsLoaded.push(compPointer)
        _.forEach(compLoadedCallbacks, function (callback) {
            callback({
                compPointer
            })
        })
    }

    const registerCompLoaded = function (callback) {
        _.forEach(compsLoaded, function (compPointer) {
            callback({
                compPointer
            })
        })
        compLoadedCallbacks.push(callback)
    }

    return {
        supportedEvents,
        unRegisterHandlers,
        registerEditModeChangeHandler,
        registerSettingsUpdatedHandler,
        registerWindowPlacementChangedHandler,
        registerSitePublishedHandler,
        registerDeviceTypeChangeHandler,
        registerSiteSavedHandler,
        registerCompLoaded,
        registerHistoryChangeHandler,
        callCompLoaded,
        editModeChange,
        sitePublished,
        siteSaved,
        deviceTypeChange,
        triggerOnWindowPlacementChanged,
        registerThemeChangeHandler,
        callSettingsUpdateCallback,
        registerDeleteCompHandler,
        registerDisconnectCompHandler,
        executeDeleteHandler,
        executeDisconnectHandler,
        executeHistoryChangeHandler,
        isDisconnectHandlerExists,
        isDeleteHandlerExists,
        registerPublicDataChangedHandler,
        callPublicDataChangedCallback,
        callPublicDataChangedCallbackForAllAppRegisteredComps,
        registerEditorEventHandler,
        triggerEditorEvent
    }
})
