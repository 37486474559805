define(['documentServices/appControllerData/appControllerData'], function (appControllerData) {
    'use strict'

    const updateWidgetConfig = (ps, controllerRef, config) => {
        const currentConfig = appControllerData.getSettings(ps, controllerRef)
        appControllerData.setSettings(ps, controllerRef, {...currentConfig, ...config}, true)
    }

    return {
        update: updateWidgetConfig
    }
})
