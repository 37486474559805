import optionsProxy from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/proxies/mixins/optionsProxy'

/**
 * @class proxies.OptionsListInput
 * @extends proxies.mixins.optionProxy
 */
export default {
    mixins: [optionsProxy],
    useSkinInsteadOfStyles: true,
    getSkinName() {
        return 'wixapps.integration.skins.ecommerce.options.OptionsListInputSkin'
    },
    getComponentName() {
        return 'wysiwyg.common.components.inputs.OptionsListInput'
    }
}
