define(['lodash', 'documentServices/component/component'], function (_, component) {
    'use strict'

    function isMainSideResizeEvent(newLayout, currLayout, sizeKey) {
        if (newLayout && !_.isUndefined(newLayout[sizeKey]) && currLayout) {
            return newLayout[sizeKey] !== currLayout[sizeKey]
        }
    }

    return function (ps, compPointer, newLayout) {
        const currLayout = component.layout.get(ps, compPointer)
        const isVerticalText = component.properties.get(ps, compPointer).verticalText
        const sizeKey = isVerticalText ? 'width' : 'height'

        if (isMainSideResizeEvent(newLayout, currLayout, sizeKey)) {
            const isPacked = newLayout[sizeKey] === 0

            component.properties.update(ps, compPointer, {
                minHeight: isVerticalText ? null : newLayout.height,
                minWidth: isVerticalText ? newLayout.width : null,
                packed: isPacked
            })
        }
    }
})
