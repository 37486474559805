define([
    'lodash',
    'documentServices/hooks/hooks',
    'documentServices/hooks/layoutHooks/wphoto',
    'documentServices/hooks/layoutHooks/mediaPlayer',
    'documentServices/hooks/layoutHooks/wFacebookComment',
    'documentServices/hooks/layoutHooks/documentMedia',
    'documentServices/hooks/layoutHooks/verticalMenu',
    'documentServices/hooks/layoutHooks/expandableMenu',
    'documentServices/hooks/layoutHooks/slideShow',
    'documentServices/hooks/layoutHooks/stateBox',
    'documentServices/hooks/layoutHooks/tinyMenu',
    'documentServices/hooks/layoutHooks/columns',
    'documentServices/hooks/layoutHooks/column',
    'documentServices/hooks/layoutHooks/verticalAnchorsMenu',
    'documentServices/hooks/componentHooks/repeaterHooks',
    'documentServices/hooks/layoutHooks/popoverLayoutHooks',
    'documentServices/hooks/layoutHooks/wRichText',
    'documentServices/hooks/layoutHooks/fixedInProps',
    'documentServices/hooks/layoutHooks/appWidget',
    'documentServices/hooks/layoutHooks/matrixGallery',
    'documentServices/hooks/layoutHooks/refComponent'
], function (
    _,
    hooks,
    wphoto,
    mediaPlayer,
    wFacebookComment,
    documentMedia,
    verticalMenu,
    expandableMenu,
    slideshow,
    stateBox,
    tinyMenu,
    columns,
    column,
    verticalAnchorsMenu,
    repeaterHooks,
    popoverHooks,
    wRichText,
    fixedInProps,
    appWidget,
    matrixGallery,
    refComponent
) {
    'use strict'

    init()

    function init() {
        //before
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, wphoto, 'wysiwyg.viewer.components.WPhoto')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, documentMedia, 'wysiwyg.viewer.components.documentmedia.DocumentMedia')

        _.forEach(['wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.StripContainerSlideShow'], type =>
            hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, slideshow, type)
        )

        _.forEach(['wysiwyg.viewer.components.StateBox', 'wysiwyg.viewer.components.StateStrip'], type =>
            hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, stateBox.applyLayoutToStateBoxChildrenBeforeUpdate, type)
        )

        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, columns, 'wysiwyg.viewer.components.StripColumnsContainer')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, tinyMenu, 'wysiwyg.viewer.components.mobile.TinyMenu')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, wRichText, 'wysiwyg.viewer.components.WRichText')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, verticalMenu.before, 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, fixedInProps, 'wysiwyg.viewer.components.PopupContainer')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, mediaPlayer.positionBigPlay, 'wysiwyg.viewer.components.MediaOverlayControls')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, mediaPlayer.positionControlsBar, 'wysiwyg.viewer.components.MediaControls')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, popoverHooks.updateChildLayout, 'wysiwyg.viewer.components.Popover')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, appWidget.applyLayoutToWidgetRootOnLayoutUpdate, 'platform.components.AppWidget')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, matrixGallery.before, 'wysiwyg.viewer.components.MatrixGallery')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, refComponent, 'wysiwyg.viewer.components.RefComponent')

        //after
        _.forEach(['wysiwyg.viewer.components.StateBox', 'wysiwyg.viewer.components.StateStrip'], type =>
            hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, stateBox.applyLayoutToStateBoxChildrenAfterUpdate, type)
        )

        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, appWidget.applyLayoutToWidgetRootAfterLayoutUpdate, 'platform.components.AppWidget')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, repeaterHooks.afterLayoutChanged, 'wysiwyg.viewer.components.Repeater')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, wFacebookComment, 'wysiwyg.viewer.components.WFacebookComment')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, expandableMenu.after, 'wysiwyg.viewer.components.ExpandableMenu')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, verticalAnchorsMenu, 'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, column, 'wysiwyg.viewer.components.Column')
        hooks.registerHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, mediaPlayer.layoutPlayerChildren, 'wysiwyg.viewer.components.MediaPlayer')
    }
})
