define(['lodash', '@wix/santa-ds-libs/src/coreUtils', 'documentServices/wixCode/utils/fetchResponseErrorObject'], function (
    _,
    coreUtils,
    fetchResponseErrorObject
) {
    'use strict'

    const requestTypes = {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        DELETE: 'DELETE'
    }

    function sendRequest(url, type, data) {
        const params = {
            type,
            url,
            dataType: 'json',
            contentType: 'application/json'
        }

        if (data) {
            params.data = JSON.stringify(data)
        }

        return sendRequestObj(params)
    }

    function sendRequestObj(request) {
        return new Promise(function (resolve, reject) {
            request.success = resolve
            request.error = function (xhr, errorType /*, error*/) {
                const error = fetchResponseErrorObject.build({
                    errorType,
                    xhr
                })
                error.request = request

                reject(error)
            }

            if (request.data && !_.isString(request.data)) {
                request.data = JSON.stringify(request.data)
            }

            coreUtils.ajaxLibrary.ajax(request)
        })
    }

    /***
     * @description Extracts data from the snapshot in the given path.
     * @param {object} snapshot The snapshot- ImmutableJS object
     * @param {array} pathArray The path elements to traverse in order to get to the requested data
     * @param {boolean} isCompound true if the expected data is not a simple type (this will call toJS() before returning the result)
     * @returns {any}
     */
    function extractFromSnapshot(snapshot, pathArray, isCompound = false) {
        let ret = snapshot.getIn(pathArray)
        if (isCompound && ret) {
            ret = ret.toJS()
        }
        return ret
    }

    function extractFromSnapshotDal(snapshotDal, pathArray) {
        const [type, id, ...innerPath] = pathArray
        return snapshotDal.getValue({type, id, innerPath})
    }

    return {
        sendRequest,
        sendRequestObj,
        extractFromSnapshot,
        extractFromSnapshotDal,
        requestTypes
    }
})
