const _ = require('lodash')
const featuresData = require('../dist/featuresData.json')
const CONSTANTS = require('../dist/constants.json')

const namespaceConfigs = require('../dist/namespaceConfigs.json')

const clientStyleToServerStyle = CONSTANTS.NAMESPACE_MAPPING
const serverStyleToClientStlye = _.invert(clientStyleToServerStyle)

const featureNamespaces = _.keys(featuresData)
const defaultNamespaceConfig = Object.freeze({
    supportsVariants: false,
    isPublicViewerData: false,
    hasFeaturePublicAPI: false
})

/**
 * @param {string} ns
 */
const getNamespaceConfig = ns => _.defaults(namespaceConfigs[ns], defaultNamespaceConfig)
const getNamespaces = () => _.keys(namespaceConfigs)

/** @param {string} name */
const convertNamespaceFromServerStyle = name => serverStyleToClientStlye[name] ?? name
/** @param {string} name */
const convertNamespaceToServerStyle = name => clientStyleToServerStyle[name] ?? name

module.exports = {
    ...CONSTANTS,
    featureNamespaces,
    featuresData,
    getNamespaceConfig,
    getNamespaces,
    convertNamespaceFromServerStyle,
    convertNamespaceToServerStyle
}
