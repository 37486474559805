import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const wix2x = viewerLibrary.get('documentServices/wix2x/wix2x')
    const responsiveLayout = viewerLibrary.get('documentServices/responsiveLayout/responsiveLayout')
    const breakpoints = viewerLibrary.get('documentServices/responsiveLayout/breakpoints')

    return {
        methods: {
            responsive: {
                migration: {
                    /**
                     * @param {boolean} isResponsive - sets the isResponsive flag of the site
                     */
                    setResponsive: publicMethodUtils.defineDataManipulationAction(wix2x.setResponsive),
                    responsiveLayout: {
                        update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update)
                    },
                    breakpoints: {
                        update: publicMethodUtils.defineDataManipulationAction(breakpoints.update)
                    }
                }
            }
        }
    }
}
