define(['lodash', 'documentServices/errors/errors', '@wix/document-manager-utils'], (_, documentServicesErrors, dmUtils) => {
    const presaveOperationsRejectedMsg = 'DocumentServices presave operations were rejected by server.'
    const saveDisabledMsg = 'DocumentServices was created with parameter disableSave=true, so save is disabled.'
    const saveInProgressMsg = 'Save is already in progress. The last save request is being ignored.'

    /**
     * @param errorType
     * @param errorDescription
     * @param errorCode
     * @returns {{documentServices: {errorDescription, errorType, errorCode}}}
     */
    const error = (errorType, errorDescription, errorCode) => ({
        documentServices: {
            errorCode,
            errorType,
            errorDescription
        }
    })

    const USER_NOT_AUTHORIZED_FOR_SITE = error(documentServicesErrors.save.USER_NOT_AUTHORIZED_FOR_SITE, saveDisabledMsg, 'USER_NOT_AUTHORIZED_FOR_SITE')
    const SAVE_DISABLED_IN_DOCUMENT_SERVICES = error(
        documentServicesErrors.save.SAVE_DISABLED_IN_DOCUMENT_SERVICES,
        saveDisabledMsg,
        dmUtils.clientSaveErrors.SAVE_DISABLED_IN_DOCUMENT_SERVICES
    )
    const SAVE_IN_PROGRESS = error(documentServicesErrors.save.SAVE_IN_PROGRESS, saveInProgressMsg, dmUtils.clientSaveErrors.SAVE_IN_PROGRESS)

    const createPresaveError = e => {
        const msg = e.message || _.get(e, ['details', 'applicationError', 'code']) || presaveOperationsRejectedMsg
        return error(documentServicesErrors.save.PRESAVE_OPERATIONS_REJECTED, msg, dmUtils.clientSaveErrors.PRESAVE_OPERATIONS_REJECTED)
    }

    function isSaveValidationError(errorDescription) {
        return _.get(errorDescription, ['document', 'errorCode']) === -10104
    }

    function normalizeError(e) {
        if (!_.has(e, ['documentServices', 'errorCode']) && !_.has(e, ['document', 'errorCode'])) {
            _.set(e, ['documentServices', 'errorCode'], dmUtils.clientSaveErrors.UNKNOWN_SERVER_ERROR)
        }
        return e
    }

    return {
        USER_NOT_AUTHORIZED_FOR_SITE,
        SAVE_DISABLED_IN_DOCUMENT_SERVICES,
        SAVE_IN_PROGRESS,
        createPresaveError,
        isSaveValidationError,
        normalizeError
    }
})
