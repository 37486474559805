import type {ComponentSchemasDefinition, CoreSchemaConfig, SchemaConfig, SchemaFile} from '@wix/document-services-types'
import _ from 'lodash'
import common from '../dist/schemas/common.json'
import cssSchemas from '../dist/schemas/cssSchemas.json'
import theSchemasFormerlyKnownAsCommon from '../dist/schemas/commonSchemas.json'
import {extendDataNodeSchemas} from './schemas/schemaUtils'
import {constants} from '@wix/santa-core-utils'
import {CannotFindSchemaError, SchemaValidationError, SchemaValidationErrorDetails} from './schemas/errors'
import {createSchemaCore, ValidationFunction} from './schemas/schemaCore'
import {getUnifiedSchemasConfig} from './configs/schemaConfigs'

const {DATA_TYPES} = constants

const notActuallySchemas = [
    'definition',
    'componentsDefinitionsMap',
    'allComponentsDefinitionsMap',
    'componentTypeAliases',
    'skinsByComponentType',
    'containers'
]

function isExcludedFromValidation(id: string, type: string): boolean {
    return ['THEME_DATA'].includes(id) || ['AppVars'].includes(type)
}

const getSchemasFromConfig = (schemasAndDefinitionsArrangedByDataType: any) => ({
    ..._.omit(schemasAndDefinitionsArrangedByDataType, notActuallySchemas),
    cssSchemas,
    theSchemasFormerlyKnownAsCommon,
    common
})

function createService(schemaConfig: SchemaConfig) {
    const coreSchemaConfig = {
        namespaces: getSchemasFromConfig(schemaConfig.schemas),
        permanentDataTypes: schemaConfig.permanentDataTypes
    } as CoreSchemaConfig
    if (schemaConfig.restrictedSchemas) {
        coreSchemaConfig.restrictedSchemas = getSchemasFromConfig(schemaConfig.restrictedSchemas)
    }
    const compDefs = {...schemaConfig.schemas.definition}

    const aliasToCompKeyMap = new Map<string, string>()

    const getComponentType = (compKey: string) => {
        // @ts-ignore
        const compDef = compDefs[compKey] || compDefs[aliasToCompKeyMap.get(compKey)]
        return compDef?.type
    }

    const updateAliasMap = (compKey: string) => {
        const compDef = compDefs[compKey]
        if (compDef?.aliases) {
            compDef.aliases.forEach((alias: string) => {
                aliasToCompKeyMap.set(alias, compKey)
            })
        }
    }

    Object.keys(compDefs).forEach((compKey: string) => {
        updateAliasMap(compKey)
    })

    const core = createSchemaCore(coreSchemaConfig, schemaConfig.references)

    function getDefinitionByPredicate(lodashPredicate: any) {
        return _.find(compDefs, lodashPredicate)
    }

    /** Return a boolean representing whether or not the given `id` is the id of a system style
     *
     * A system style is any style that exists in a component definitions's `styles` property.
     * Note that while most components are supplied at service initialization, some
     * may be registered at runtime, which might change the results of this function on certain inputs.
     *
     * @param {string} id The id of the style
     * @returns {boolean} true if `id` is the id of a system style, false otherwise
     */
    function isSystemStyle(id: string): boolean {
        return _.some(compDefs, definition => _.has(definition.styles, id))
    }

    function getDefinition(name: string) {
        return compDefs[name]
    }

    const createValidator = (coreValidator: ValidationFunction) => (dataTypeName: string, data: any, namespace: string) => {
        const designDt = DATA_TYPES.design
        const dataDt = DATA_TYPES.data

        if (isExcludedFromValidation(data?.id, data?.type)) {
            return
        }

        // To preserve the legacy API, data schemas override design schemas when there's a naming conflict
        if (namespace === designDt && core.hasSchemaForDataType(designDt, dataTypeName) && core.hasSchemaForDataType(dataDt, dataTypeName)) {
            coreValidator(dataDt, dataTypeName, data)
            return
        }

        const namespacesToTry = [namespace, 'mobileHints', 'theSchemasFormerlyKnownAsCommon']
        const ns = _.find(namespacesToTry, possibleNamespace => core.hasSchemaForDataType(possibleNamespace, dataTypeName))
        if (!ns) {
            throw new CannotFindSchemaError(namespace, dataTypeName)
        }
        coreValidator(ns, dataTypeName, data)
    }

    const validate = createValidator(core.validate)
    const validateNoDefaults = createValidator(core.validateNoDefaults)
    const validateStrict = createValidator(core.validateStrict)

    function isValid(dataTypeName: string, data: any, namespace: string): boolean {
        try {
            validate(dataTypeName, data, namespace)
            return true
        } catch (e) {
            return false
        }
    }

    function registerComponentDefinitionAndSchemas(
        compType: string,
        {componentDefinition, dataSchemas, propertiesSchemas}: ComponentSchemasDefinition,
        allowOverrides = false
    ) {
        const schemaExists = !!compDefs[compType]
        if (schemaExists && !allowOverrides) {
            return
        }
        const newCompDef = componentDefinition[compType]
        if (newCompDef.type !== 'Container') {
            newCompDef.type = 'Component'
        }
        compDefs[compType] = newCompDef
        updateAliasMap(compType)
        core.addDataTypesToExistingNamespace(DATA_TYPES.data, extendDataNodeSchemas(dataSchemas, DATA_TYPES.data), schemaExists)
        core.addDataTypesToExistingNamespace(DATA_TYPES.prop, extendDataNodeSchemas(propertiesSchemas, DATA_TYPES.prop), schemaExists)
    }

    function registerDataTypeSchema(schemasToRegister: SchemaFile, namespace: string) {
        core.registerNamespace(namespace, schemasToRegister)
    }

    function removeAdditionalProperties(namespace: string, dataTypeName: string, data: any): void {
        if (isExcludedFromValidation(data?.id, data?.type)) {
            return
        }

        core.removeAdditionalProperties(namespace, dataTypeName, data)
    }

    const containerTypesSet = new Set(['Page', 'Container', 'Document', 'RepeaterContainer', 'RefComponent'])

    const isContainer = (compKey: string) => containerTypesSet.has(getComponentType(compKey))
    const isPage = (compKey: string) => getComponentType(compKey) === 'Page'
    const isRepeater = (compKey: string) => getComponentType(compKey) === 'RepeaterContainer'
    const isRefComponent = (compKey: string) => getComponentType(compKey) === 'RefComponent'

    return {
        getDefinitionByPredicate,
        isSystemStyle,
        registerComponentDefinitionAndSchemas,
        registerDataTypeSchema,
        getDefinition,
        isDraftDataSchema: core.isDraftDataSchema,
        isDraftItem: core.isDraftItem,
        extractReferences: core.extractReferences,
        extractReferenceFieldsInfo: core.extractReferenceFieldsInfo,
        hasNamespace: core.hasNamespace,
        hasSchemaForDataType: core.hasSchemaForDataType,
        isPermanentDataType: core.isPermanentDataType,
        removeAdditionalProperties,
        validate,
        validateStrict,
        validateNoDefaults,
        isValid, // TODO: Adjust API to include errors like in old service
        isContainer,
        isPage,
        isRepeater,
        isRefComponent,
        getComponentType
    }
}

const staticInstance = createService(getUnifiedSchemasConfig())

export {SchemaValidationErrorDetails, SchemaValidationError, createSchemaCore, createService, staticInstance, common}
