import _ from 'lodash'
import Activity from './activity'

const getActivityInfo = function (fields) {
    const info: any = {fields: []}

    Object.keys(fields).forEach(function (key) {
        info.fields.push({name: key, value: fields[key]})
    })

    info.email = fields.email
    if (fields.first || fields.last) {
        info.name = _.pickBy({first: fields.first, last: fields.last})
    }
    if (fields.phone) {
        info.phone = fields.phone
    }

    return info
}

const getContactUpdate = function (fields) {
    return {
        name: {
            first: fields.first || '',
            last: fields.last || ''
        },
        emails: [
            {
                tag: 'main',
                email: fields.email || ''
            }
        ],
        phones: fields.phone
            ? [
                  {
                      tag: 'main',
                      phone: fields.phone || ''
                  }
              ]
            : [],
        emailSubscriptionPolicy: 'RECURRING'
    }
}

const SubscribeFormActivity = function (activityInfo, fields) {
    Activity.call(this, activityInfo, fields)
}

SubscribeFormActivity.prototype = new Activity()

SubscribeFormActivity.prototype.getParams = function (params) {
    const baseParams = Activity.prototype.getParams.call(this, params)
    let subFormParams = {
        'component-name': 'subscribeForm'
    }

    subFormParams = _.assign(baseParams, subFormParams)

    return subFormParams
}

SubscribeFormActivity.prototype.getPayload = function (payload) {
    const basePayload = Activity.prototype.getPayload.call(this, payload)
    let subFormPayload = {
        contactUpdate: getContactUpdate(this._fields),
        activityInfo: getActivityInfo(this._fields),
        activityType: 'contact/subscription-form'
    }

    subFormPayload = _.assign(basePayload, subFormPayload)

    return subFormPayload
}

export default SubscribeFormActivity
