import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import experiment from 'experiment'

const createExperimentFn = siteData => name => experiment.isOpen(name, siteData)

export default _.assign({}, warmupUtilsLib.layoutUtils, {
    getLayoutMechanism(siteData) {
        return warmupUtilsLib.layoutUtils.getLayoutMechanism(siteData, createExperimentFn(siteData))
    }
})
