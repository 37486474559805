import {getFontSizeByFontName, convertFontSizeByTextLength} from './textUtils/fontSizeConverter'
import {conversionConfig} from './conversionConfig'
import * as mobileHints from './mobilePresetHandler'
import * as mobileOnlyComponentsHandler from './mobileOnlyComponents/mobileOnlyComponentsHandler'
import * as mobileOnlyComponentsUtils from './mobileOnlyComponents/mobileOnlyComponentsUtils'
import * as conversionUtils from './conversionUtils'
import * as mobileConversion from './mobileConversion'
import * as validators from './validators'
import {removeConversionDataRecursively} from './structurePostprocessor'

import {getComponentIdsAddedToWebStructure as getComponentIdsAddedToDesktopStructure} from './mobileMerge/mergeUtils'

export {conversionUtils, conversionConfig, mobileHints, validators, removeConversionDataRecursively}

export const textUtils = {
    getFontSizeByFontName,
    convertFontSizeByTextLength
}

export const structuresComparer = {
    getComponentIdsAddedToDesktopStructure
}

export const mobileOnlyComponents = {
    isMobileOnlyComponentId: mobileOnlyComponentsUtils.isMobileOnlyFixedComponentId,
    addComponent: mobileOnlyComponentsHandler.addComponent,
    getAdditionalStyles: mobileOnlyComponentsHandler.getAdditionalStyles
}

export const conversion = {
    inspectStructure: mobileConversion.inspectStructure,
    synchronizePages: mobileConversion.synchronizePages,
    mergeDesktopPagesWithMobile: mobileConversion.mergePages,
    mergeDesktopStructureWithMobile: mobileConversion.mergeStructure,
    convertDesktopPageToMobile: mobileConversion.convertPage
}
