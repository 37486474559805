function isNavigatorAvailable() {
    return typeof window !== 'undefined' && typeof window.navigator !== 'undefined'
}

function isIpadIOS13() {
    if (!isNavigatorAvailable()) {
        return false
    }
    // @ts-ignore
    return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream
}

function isIOS13orHigherDevice() {
    const isIphoneIOS13 = /iP(hone|od|ad).*OS 1[3-9]/.test(navigator.userAgent)

    return isIpadIOS13() || isIphoneIOS13
}

export default {
    isIOS13orHigherDevice,
    isIpadIOS13
}
