import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import warmupUtilsLib from '@wix/santa-core-utils'
import socialCounterDatabaseAPI from './socialCounterDatabaseAPI'

const GOOGLE_SHARE_URL = 'https://plus.google.com/share?url='
const FANCY_SHARE_URL = 'http://www.fancy.com/fancyit/fancy?ItemURL='
const PINTEREST_SHARE_URL = 'http://pinterest.com/pin/create/button/?url='
const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?url='
const FACEBOOK_SHARE_URL = 'http://www.facebook.com/sharer.php?u='
const WHATSAPP_SHARE_URL = 'whatsapp://send?text='
const GOOGLE_URL_SHORTENER = 'https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyDjvIfgLnQJsOxcV01kJae48WSynmXmZ2I'
const LINKEDIN_SHARE_URL = 'https://www.linkedin.com/shareArticle?mini=true&url='

function addQueryParamToUrl(url, param) {
    const urlParts = url.split('#')
    const delimiter = _.includes(url, '?') ? '&' : '?'
    urlParts[0] += delimiter + param
    return urlParts.join('#')
}

function getSharePageURL(url, hashToSlash?) {
    let shareUrl = url

    const urlParts = shareUrl.split('#!')
    if (urlParts.length > 1) {
        urlParts[1] = decodeURIComponent(urlParts[1]) //prevent double encoding of product title in hash
        shareUrl = urlParts.join('#!')
    }
    if (hashToSlash) {
        shareUrl = shareUrl.replace('#!', '/#!') // facebook sharer requires escape url
    }
    return encodeURIComponent(shareUrl)
}

function getShareTitle(title) {
    if (title) {
        return encodeURIComponent(_.unescape(title))
    }
    return ''
}

function getShareDescription(description) {
    if (description) {
        return encodeURIComponent(_.unescape(description))
    }
    return ''
}

function getShareHashTags(tags) {
    if (tags) {
        tags = tags.replace(/#/g, encodeURIComponent('#'))
        return ` ${tags}`
    }
    return ''
}

function getImageUrl(imageUrl, siteData) {
    let url = imageUrl || ''
    if (imageUrl && !warmupUtilsLib.urlUtils.isExternalUrl(imageUrl)) {
        url = siteData.getMediaFullStaticUrl(imageUrl)
    }
    url = encodeURIComponent(url)
    return url
}

/*
 * requires:
 *  url = encoded share page url
 * */
function getGoogleShareUrl(shareInfo) {
    let shareUrl = GOOGLE_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url)
    return shareUrl
}

/**
 *
 * requires:
 *  ItemURL = encoded share page url
 *  Title = share title
 *  ImageURL = share image url
 * @param {wixapps.shareInfo} shareInfo
 * @param siteData
 * @returns {string}
 */
function getFancyShareUrl(shareInfo, siteData) {
    let shareUrl = FANCY_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url)
    shareUrl += `&Title=${getShareTitle(shareInfo.title)}`
    shareUrl += `&ImageURL=${getImageUrl(shareInfo.imageUrl, siteData)}`
    return shareUrl
}

/**
 * requires:
 *  url = encoded share page url
 *  media = encoded share image url
 *  description = share title
 *
 * @param {wixapps.shareInfo} shareInfo
 * @param siteData
 * @returns {string}
 */
function getPinterestShareUrl(shareInfo, siteData) {
    let shareUrl = PINTEREST_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url)
    shareUrl += `&media=${getImageUrl(shareInfo.imageUrl, siteData)}`
    shareUrl += `&description=${getShareTitle(shareInfo.title)}`
    return shareUrl
}

/**
 * requires:
 *  url = encoded share page url
 *  text = share title
 *
 *  @param {wixapps.shareInfo} shareInfo
 */
function getTwitterShareUrl(shareInfo) {
    let shareUrl = TWITTER_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url)
    shareUrl += `&text=${getShareTitle(shareInfo.title)}${getShareHashTags(shareInfo.hashTags)}${encodeURIComponent('\n')}` // prettify line wrap
    return shareUrl
}

/**
 * requires:
 *  u = encoded share page url
 *  t = share title
 *
 *  @param {wixapps.shareInfo} shareInfo
 */
function getFacebookShareUrl(shareInfo) {
    let shareUrl = FACEBOOK_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url) //add slash before hash
    shareUrl += `&title=${getShareTitle(shareInfo.title)}`
    shareUrl += `&description=${getShareDescription(shareInfo.description)}`
    return shareUrl
}

/**
 * requires:
 *  u = encoded share page url
 *  t = share title
 *
 *  @param {wixapps.shareInfo} shareInfo
 */
function getWhatsAppShareUrl(shareInfo) {
    let shareUrl = WHATSAPP_SHARE_URL
    shareUrl += getShareTitle(shareInfo.title)
    shareUrl += ` - ${getSharePageURL(shareInfo.url)}` //add slash before hash
    return shareUrl
}

/**
 * requires:
 *  url = encoded share page url
 *  title = share title
 *
 *  @param {wixapps.shareInfo} shareInfo
 */
function getLinkedInShareUrl(shareInfo) {
    let shareUrl = LINKEDIN_SHARE_URL
    shareUrl += getSharePageURL(shareInfo.url) //add slash before hash
    shareUrl += `&title=${getShareTitle(shareInfo.title)}`
    return shareUrl
}

function getEmailShareUrl(shareInfo) {
    let url = 'mailto:?'
    url += `&subject=${getShareTitle(shareInfo.title)}`
    url += `&body=${getShareDescription(shareInfo.description)}`
    return url
}
/**
 *
 * @param {wixapps.shareInfo} shareInfo
 * @param siteData
 */
function getServiceShareUrl(shareInfo, siteData) {
    const serviceFunctions = {
        google: getGoogleShareUrl,
        fancy: getFancyShareUrl,
        pinterest: getPinterestShareUrl,
        twitter: getTwitterShareUrl,
        facebook: getFacebookShareUrl,
        whatsapp: getWhatsAppShareUrl,
        email: getEmailShareUrl,
        linkedin: getLinkedInShareUrl
    }

    return serviceFunctions[shareInfo.service](shareInfo, siteData) || ''
}

/**
 *
 * @param {wixapps.shareInfo} shareInfo
 * @param siteAPI
 */
function handleShareRequest(siteAPI, shareInfo, disableCounters) {
    let shareUrl = getServiceShareUrl(shareInfo, siteAPI.getSiteData())
    if (shareInfo.addDeepLinkParam) {
        shareUrl = addQueryParamToUrl(shareUrl, `deeplink_referrer=socialB_${shareInfo.service}`)
    }
    if (shareInfo.service === 'email') {
        window.location.href = shareUrl
        return
    }
    if (!disableCounters) {
        // @ts-ignore
        socialCounterDatabaseAPI.updateCounter('shares', shareInfo.service, 1, shareInfo.storeId, shareInfo.postId)
    }
    siteAPI.openPopup(shareUrl, 'wixapps_share', 'width=635,height=346,scrollbars=no,status=no,toolbar=no,menubar=no,location=no')
}

function shortenURL(url, timeout, onSuccess, onError) {
    warmupUtils.ajaxLibrary.ajax({
        url: GOOGLE_URL_SHORTENER,
        type: 'POST',
        contentType: 'application/json',
        data: `{"longUrl": "${url}"}`,
        timeout: timeout || 0,
        async: false, // it must be sync in safari, or otherwise onSuccess won't work (because sharePost does window.open)
        success(res) {
            onSuccess(res.id)
        },
        error: onError
    })
}

/**
 * @class wixapps.socialShareHandler
 */
export default {
    handleShareRequest,
    shortenURL
}

/**
 * @typedef {{
 *  service: string,
 *  url: string,
 *  title: string,
 *  imageUrl: string,
 *  addDeepLinkParam: boolean
 * }} wixapps.shareInfo
 */
