define(['@wix/santa-core-utils'], function (santaCoreUtils) {
    'use strict'

    function setRenderFlag(ps, flagName, value) {
        const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
        ps.dal.set(renderFlagPointer, value)
    }

    /**
     * @param {{pointers:*, dal:*}} op
     * @param {*} compPointer
     */
    const hideComp = ({pointers, dal}, compPointer) => {
        const viewMode = pointers.components.getViewMode(compPointer)
        const isCompHiddenPointer = pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode)
        dal.set(isCompHiddenPointer, true)
    }

    /**
     * @param {{pointers:*, dal:*}} op
     * @param {*} compPointer
     */
    const showComp = ({pointers, dal}, compPointer) => {
        const viewMode = pointers.components.getViewMode(compPointer)
        const isCompHiddenPointer = pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode)
        if (dal.isExist(isCompHiddenPointer)) {
            dal.remove(isCompHiddenPointer)
        }
    }

    const isCompHidden = ({pointers, dal}, compPointer) =>
        !!dal.get(pointers.general.getIsCompHiddenPointer(compPointer.id, pointers.components.getViewMode(compPointer)))

    /**
     * Set value to siteData.compToShowOnTop
     * @param {ps} ps
     * @param compIds
     */
    function setCompsToShowOnTop(ps, compIds) {
        const compsToShowOnTopPointer = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowOnTop')
        ps.dal.set(compsToShowOnTopPointer, compIds)
    }

    /**
     * Check value to siteData.compToShowOnTop
     * @param {ps} ps
     * @param compIds
     * @returns {Boolean} Is compId different from current siteData.compToShowOnTop
     */
    function checkCompsToShowOnTop(ps, compIds) {
        const compsToShowOnTopPointer = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowOnTop')
        return santaCoreUtils.objectUtils.isDifferent(compIds, ps.dal.get(compsToShowOnTopPointer))
    }

    /**
     * Set value to siteData.compToShowOnTop
     * @param {ps} ps
     * @param compIds
     * @param opacity
     */
    function setCompsToShowWithOpacity(ps, compIds, opacity) {
        const compsToShowWithOpacity = ps.pointers.general.getRenderRealtimeConfigItem('compsToShowWithOpacity')
        ps.dal.set(compsToShowWithOpacity, {
            opacity,
            compIds
        })
    }

    /**
     * Set value to siteData.hideTextComponent
     * @param {ps} ps
     * @param compId
     */
    function setHideTextComponent(ps, compId) {
        const hideTextComponentPointer = ps.pointers.general.getRenderRealtimeConfigItem('hideTextComponent')
        ps.dal.set(hideTextComponentPointer, compId)
    }

    /**
     * Check value to siteData.hideTextComponent
     * @param {ps} ps
     * @param compId
     * @returns {Boolean} Is compId different from current siteData.hideTextComponent
     */
    function checkHideTextComponent(ps, compId) {
        const hideTextComponentPointer = ps.pointers.general.getRenderRealtimeConfigItem('hideTextComponent')
        return santaCoreUtils.objectUtils.isDifferent(compId, ps.dal.get(hideTextComponentPointer))
    }

    /**
     * Set value to siteData.renderFlags.allowSiteOverflow
     * @param {ps} ps
     * @param {boolean} allow
     */
    function allowSiteOverflow(ps, allow) {
        setRenderFlag(ps, 'allowSiteOverflow', allow)
    }

    /**
     * Set value to siteData.renderFlags.extraSiteHeight
     * @param {ps} ps
     * @param {number} extraSiteHeight
     */
    function setExtraSiteHeight(ps, extraSiteHeight) {
        setRenderFlag(ps, 'extraSiteHeight', extraSiteHeight)
    }

    function setErrorPagesPopUp(ps, callback) {
        ps.siteAPI.setErrorPagesPopUp(callback)
    }

    function setPreviewTooltipCallback(ps, callback) {
        ps.siteAPI.setPreviewTooltipCallback(callback)
    }

    function setBlockingLayer(ps, style) {
        setRenderFlag(ps, 'blockingLayer', style)
    }

    function setBlockingPopupLayer(ps, style) {
        setRenderFlag(ps, 'blockingPopupLayer', style)
    }

    function setPremiumNavigationHandler(ps, callback) {
        ps.siteAPI.setPremiumNavigationHandler(callback)
    }

    const renderPlugins = {
        setCompsToShowWithOpacity,
        setCompsToShowOnTop,
        checkCompsToShowOnTop,
        hideComp,
        showComp,
        isCompHidden,
        setHideTextComponent,
        checkHideTextComponent,
        allowSiteOverflow,
        setExtraSiteHeight,
        setPreviewTooltipCallback,
        setErrorPagesPopUp,
        setBlockingLayer,
        setBlockingPopupLayer,
        setPremiumNavigationHandler
    }

    return renderPlugins
})
