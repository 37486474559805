define(['lodash', 'documentServices/routers/routersGetters', 'documentServices/extensionsAPI/extensionsAPI'], function (_, routersGetters, extensionsAPI) {
    'use strict'

    let pageDataChangedCallback, pageAddedCallback

    // checking if environment supports unicode. IE11 does not
    // eslint-disable-next-line prefer-regex-literals
    const unicodeSupported = !_.isUndefined(RegExp('').unicode)
    const getIllegalCharacterPattern = () => (unicodeSupported ? /[^\p{L}0-9\-]/gu : /[^a-zA-Z0-9\-]/g)
    const getStartingIllegalCharactersPattern = () => (unicodeSupported ? /^[^\p{L}0-9]*/gu : /^[a-zA-Z0-9]*/g)
    const getEndingIllegalCharactersPattern = () => (unicodeSupported ? /[^\p{L}0-9]*$/gu : /[^a-zA-Z0-9]*$/g)

    const uriHasIllegalCharacters = pageUriSEO => getIllegalCharacterPattern().test(pageUriSEO)

    function registerPageDataChangedCallback(callback) {
        pageDataChangedCallback = callback
    }

    function executePageDataChangedCallback(ps, pageId, data, useOriginalLanguage = false, applyChangeToAllLanguges = false) {
        if (_.isFunction(pageDataChangedCallback)) {
            pageDataChangedCallback(ps, pageId, data, useOriginalLanguage, applyChangeToAllLanguges)
        }
    }

    function registerPageAddedCallback(callback) {
        pageAddedCallback = callback
    }

    function executePageAddedCallback(ps, pageId, addMenuItem) {
        if (pageAddedCallback) {
            pageAddedCallback(ps, pageId, addMenuItem)
        }
    }

    function getHomepageId(ps) {
        return extensionsAPI.pages.getMainPageId(ps)
    }

    function isHomepage(ps, pageId) {
        return pageId === getHomepageId(ps)
    }

    function isMasterPage(ps, pageId) {
        return pageId === 'masterPage'
    }

    function convertPageNameToUrl(ps, title) {
        const fixedTitle = title ? title : '' // defaulting to '' for every falsy value
        const uri = fixedTitle
            .toLowerCase()
            .trim()
            .replace(getStartingIllegalCharactersPattern(), '')
            .replace(getEndingIllegalCharactersPattern(), '')
            .replace(/\s+/g, '-')
            .replace(getIllegalCharacterPattern(), '-')
            .replace(/-+/gu, '-')

        return uri || 'blank'
    }

    function getCurrentUrl(ps, urlFormat, baseUrl) {
        return getPageUrl(ps, {pageId: ps.siteAPI.getCurrentUrlPageId()}, urlFormat, baseUrl)
    }

    function getMainPageUrl(ps, urlFormat, baseUrl) {
        return getPageUrl(ps, {pageId: getHomepageId(ps)}, urlFormat, baseUrl)
    }

    function getPageUrl(ps, pageInfo, urlFormat, baseUrl) {
        return ps.siteAPI.getPageUrl(pageInfo, urlFormat, baseUrl)
    }

    function isDynamicPage(ps, pageId) {
        return !!routersGetters.get.byPage(ps, ps.pointers.data.getDataItemFromMaster(pageId))
    }

    function isLandingPage(ps, pageId) {
        const pageDataPointer = ps.pointers.data.getDataItemFromMaster(pageId)
        const isLandingPagePointer = ps.pointers.getInnerPointer(pageDataPointer, 'isLandingPage')
        const isMobileLandingPagePointer = ps.pointers.getInnerPointer(pageDataPointer, 'isMobileLandingPage')
        const isMobile = ps.siteAPI.isMobileView()
        const isLandingPageVal = ps.dal.isExist(isLandingPagePointer) && ps.dal.get(isLandingPagePointer)
        const isMobileLandingPage = isMobile && ps.dal.isExist(isMobileLandingPagePointer) && ps.dal.get(isMobileLandingPagePointer)

        return isMobile ? isMobileLandingPage : isLandingPageVal
    }

    return {
        getHomepageId,
        isMasterPage,
        isHomepage,
        registerPageDataChangedCallback,
        executePageDataChangedCallback,
        registerPageAddedCallback,
        executePageAddedCallback,
        convertPageNameToUrl,
        getCurrentUrl,
        getMainPageUrl,
        getPageUrl,
        isDynamicPage,
        uriHasIllegalCharacters,
        isLandingPage
    }
})
