import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import PropTypes from 'prop-types'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import coreUtilsLib from '@wix/santa-core-utils'
import santaComponents from '@wix/santa-components'
import colorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/colorOption'
import mobileColorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileColorOption'
import mobileTextOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileTextOption'
import textOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/textOption'

function selectItem(itemData, event, domID) {
    if (this.props.onSelectionChange) {
        event.type = 'selectionChanged'
        event.payload = itemData
        this.props.onSelectionChange(event, domID)
    }
}

/**
 * @class components.SelectOptionsList
 * @extends {core.skinBasedComp}
 * @extends {coreUtilsLib.timersMixins.timeoutsMixin}
 * @property {{$validity: string, $tooltip: string}}} state
 */
export default {
    displayName: 'SelectOptionsList',
    mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, componentsCore.mixins.createChildComponentMixin],

    propType: _.defaults(
        {
            itemClassName: PropTypes.string.isRequired,
            itemSkin: PropTypes.string.isRequired,
            selectedItem: PropTypes.object,
            // @ts-ignore
            valid: PropTypes.boolean,

            // Events
            onSelectionChange: PropTypes.func
        },
        // @ts-ignore
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(colorOption),
        // @ts-ignore
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileColorOption),
        // @ts-ignore
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileTextOption),
        // @ts-ignore
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(textOption)
    ),

    getInitialState() {
        return {
            $validity: this.props.valid === false ? 'invalid' : 'valid',
            $tooltip: this.props.selectedItem && this.props.selectedItem.description ? 'displayed' : 'hidden'
        }
    },

    componentWillMount() {
        if (this.state.$tooltip === 'displayed') {
            const self = this
            this.setTimeout(function () {
                self.setState({$tooltip: 'hidden'})
            }, 1500)
        }
    },

    componentWillReceiveProps(props) {
        const oldProps = this.props
        const newState = {
            $validity: props.valid === false ? 'invalid' : 'valid'
        }

        if (!oldProps.selectedItem || (oldProps.selectedItem && props.selectedItem && oldProps.selectedItem.description !== props.selectedItem.description)) {
            // @ts-ignore
            newState.$tooltip = props.selectedItem && props.selectedItem.description ? 'displayed' : 'hidden'
        }

        this.setState(newState)
        // @ts-ignore
        if (this.props.selectedItem !== props.selectedItem && newState.$tooltip === 'displayed') {
            const self = this
            this.setTimeout(function () {
                self.setState({$tooltip: 'hidden'})
            }, 1500)
        }
    },

    getSkinProperties() {
        const children = _.map(
            this.props.compData.options,
            function (itemData, index) {
                const extraProperties = {
                    selected: _.isEqual(this.props.selectedItem, itemData),
                    onClick: selectItem.bind(this, itemData),
                    ref: index
                }

                return this.createChildComponent(
                    itemData,
                    this.props.itemClassName,
                    {
                        //TODO: this should be in skin exports
                        skin: this.props.itemSkin,
                        styleId: coreUtils.santaTypes.shortenStyleId(this.props.itemSkin)
                    },
                    extraProperties
                )
            }.bind(this)
        )

        const skinPartsProps = {
            itemsContainer: {
                children
            }
        }

        if (this.props.selectedItem) {
            // @ts-ignore
            skinPartsProps.tooltip = {
                children: [this.props.selectedItem.description]
            }
        }

        return skinPartsProps
    }
}
