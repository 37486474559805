define([
    'lodash',
    'documentServices/structure/utils/layoutConstraintsUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/component/component'
], function (_, layoutConstraintsUtils, componentsMetaData, component) {
    'use strict'

    function getRowMargin(ps, columnsContainerCompPtr) {
        return component.properties.get(ps, columnsContainerCompPtr).rowMargin
    }

    function isShortening(currColumnsContainerLayout, newLayout) {
        return _.isNumber(newLayout.height) && newLayout.height < currColumnsContainerLayout.height
    }

    function isShorteningFromBottom(currColumnsContainerLayout, newLayout) {
        if (!isShortening(currColumnsContainerLayout, newLayout)) {
            return false
        }

        return !_.isNumber(newLayout.y) || currColumnsContainerLayout.y === newLayout.y
    }

    function getUpdatedLayoutForIndividualColumns(ps, columnsContainerCompPtr, updatedColumnsContainerLayout) {
        const currColumnsContainerLayout = component.layout.get(ps, columnsContainerCompPtr)
        const rowMargin = getRowMargin(ps, columnsContainerCompPtr)

        const layout = {
            height: updatedColumnsContainerLayout.height - rowMargin * 2 // eslint-disable-line no-mixed-operators
        }

        if (_.isNumber(updatedColumnsContainerLayout.y)) {
            layout.y = updatedColumnsContainerLayout.y - currColumnsContainerLayout.y + rowMargin
        }

        return layout
    }

    return function changeChildrenColumnsHeightBeforeContainer(ps, columnsContainerCompPtr, newLayoutToUpdate, updateCompLayoutFn /*, isTriggeredByHook*/) {
        const columnPtrs = component.getChildren(ps, columnsContainerCompPtr)
        const currColumnsContainerLayout = component.layout.get(ps, columnsContainerCompPtr)
        const isMultiColumns = columnPtrs.length > 1

        const isChangingHeight = _.isNumber(newLayoutToUpdate.height) && newLayoutToUpdate.height !== currColumnsContainerLayout.height
        if (!isChangingHeight || (isMultiColumns && ps.pointers.components.isMobile(columnsContainerCompPtr))) {
            return
        }

        const isShorteningContainer = isShortening(currColumnsContainerLayout, newLayoutToUpdate)

        const updatedColumnsLayout = getUpdatedLayoutForIndividualColumns(ps, columnsContainerCompPtr, newLayoutToUpdate)
        updatedColumnsLayout.y = _.isUndefined(updatedColumnsLayout.y) ? component.layout.get(ps, columnPtrs[0]).y : updatedColumnsLayout.y

        _.forEach(columnPtrs, function (columnPtr) {
            const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(ps, columnPtr)
            const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(ps, columnPtr)

            layoutConstraintsUtils.constrainByChildrenLayout(ps, columnPtr, updatedColumnsLayout, !isEnforcingByWidth, !isEnforcingByHeight)
        })

        _.forEach(columnPtrs, function (columnPtr) {
            updateCompLayoutFn(ps, columnPtr, updatedColumnsLayout)

            if (isShorteningContainer) {
                const rowMargin = getRowMargin(ps, columnsContainerCompPtr)

                // constrain the parent columnsContainer when it resizes
                if (isShorteningFromBottom(currColumnsContainerLayout, newLayoutToUpdate)) {
                    updateCompLayoutFn(ps, columnPtr, {y: updatedColumnsLayout.y + rowMargin})
                } else {
                    updateCompLayoutFn(ps, columnPtr, {y: updatedColumnsLayout.y - rowMargin})
                }
            }
        })
    }
})
