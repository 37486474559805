define(['lodash'], function (_) {
    'use strict'

    function matrixGalleryRelativeToScreenPlugin(siteAPI, layout, compProperties, compPointer) {
        const measureMap = siteAPI.getSiteMeasureMap()
        const itemsContainerHeight = _.get(measureMap.custom, [compPointer.id, 'itemsContainer', 'height'])
        const showMoreHeight = _.get(measureMap.custom, [compPointer.id, 'showMore', 'height'])
        return itemsContainerHeight && showMoreHeight
            ? {
                  height: itemsContainerHeight + showMoreHeight
              }
            : {}
    }

    return matrixGalleryRelativeToScreenPlugin
})
