import type {Experiment, Pointers} from '@wix/document-services-types'
import {EventEmitter} from 'events'
import _ from 'lodash'
import {createDal, CustomGetter, DAL} from './dal/documentManagerDal'
import {createStore} from './dal/store'
import type {Events} from './eventEmitter'
import type {CoreLogger, DmApis, DocumentDataTypes, DSConfig, Extension, ExtensionAPI, PostSetOperation, ServiceAPIs, UndoRedoConfig} from './types'
import {getPointer} from './utils/pointerUtils'

const assignWithoutDuplicates = (target: Record<string, any>, sources: Record<string, any>, type: string) => {
    _.forOwn(sources, (value, key) => {
        if (target[key]) {
            throw new Error(`Trying to add an existing "${type}" with key "${key}"`)
        }

        target[key] = value
    })
}

export interface CoreConfig {
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    strictModeFailDefault: boolean
    signatureSeed?: string
    checkConflicts: boolean
    undoRedoConfig?: UndoRedoConfig
    dontCollectFixerVersionData?: boolean
}

export interface DocumentManager {
    dal: DAL
    pointers: Pointers
    extensionAPI: ExtensionAPI
    serviceAPIs: ServiceAPIs
    EVENTS: Record<string, any>
    eventEmitter: EventEmitter
    events: Events
    registerExtension(ext: Extension): void
    initialize(): Promise<void>
    createServiceAPIs(args: CreateServiceArgs): Promise<void>
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    config: CoreConfig
}

export interface CreateServiceArgs {
    config: DSConfig
}

export const createDMCore = (coreConfig: CoreConfig): DocumentManager => {
    const {experimentInstance, logger, schemaService} = coreConfig
    const extensions: Extension[] = []
    const postSetOperations: PostSetOperation[] = []
    const customGetters: Record<string, CustomGetter> = {}
    const dmTypes: DocumentDataTypes = {}
    const pointers: Pointers = {} as Pointers
    const extensionAPI: ExtensionAPI = {}
    const serviceAPIs: ServiceAPIs = {} as ServiceAPIs
    const eventEmitter: EventEmitter = new EventEmitter()
    const EVENTS = {}
    const events: Events = {
        emitter: eventEmitter
    }
    const dal: DAL = createDal({coreConfig, postSetOperations, dmTypes, customGetters, eventEmitter})
    const dmApis: DmApis = {coreConfig, dal, pointers, extensionAPI, serviceAPIs}

    const validate = (extension: Extension): void => {
        if (extension.name) {
            if (_(extensions).map('name').includes(extension.name)) {
                throw new Error(`extension name should be unique ${extension.name}`)
            }
        } else {
            throw new Error('extension should have a name')
        }
    }

    const registerExtension = (extension: Extension): void => {
        validate(extension)
        extensions.push(extension)

        assignWithoutDuplicates(customGetters, _.invoke(extension, ['createGetters'], dmApis), 'customGetter')
        _.merge(dmTypes, _.invoke(extension, ['getDocumentDataTypes'], dmApis))
        _.merge(pointers, _.invoke(extension, ['createPointersMethods'], dmApis))
        _.merge(extensionAPI, _.invoke(extension, ['createExtensionAPI'], {...dmApis, eventEmitter}))
        _.merge(EVENTS, extension.EVENTS)
        _.merge(events, extension.EVENTS)

        const filters = _.invoke(extension, ['createFilters'], dmApis)
        _.forEach(filters, (filter, name) => dal.registrar.registerFilter(name, filter))

        const validators = _.invoke(extension, ['createValidator'], dmApis)
        _.forEach(validators, (validator, name) => dal.registrar.registerValidator(name, validator))

        const rebaseValidators = _.invoke(extension, ['createRebaseValidator'], dmApis)
        _.forEach(rebaseValidators, (validator, name) => dal.registrar.registerRebaseValidator(name, validator))

        const postTransactionOperations = _.invoke(extension, ['createPostTransactionOperations'], dmApis)
        _.forEach(postTransactionOperations, (op, name) => dal.registrar.registerPostTransactionOperation(name, op))

        if (extension.createPostSetOperation) {
            postSetOperations.push(extension.createPostSetOperation(dmApis))
        }

        if (extension.initialState) {
            const initialTypeState = createStore()
            _.forEach(extension.initialState, (state, type) => {
                _.forEach(state, (value, key) => {
                    initialTypeState.set(getPointer(key, type), value)
                })
            })
            dal.mergeToApprovedStore(initialTypeState, extension.name)
        }
    }

    const registerService = (serviceName: string, service: any): void => {
        serviceAPIs[serviceName] = service
    }

    const invokeOnExtensions = (fnName: string, ...args: any[]): Promise<any> =>
        _(extensions)
            .filter(fnName)
            .map((ex: Extension) => _.invoke(ex, fnName, ...args))
            .thru(arr => Promise.all(arr))
            .value()

    const createServiceAPIs = async ({config}: CreateServiceArgs): Promise<void> => {
        await invokeOnExtensions('createServiceAPI', registerService, {config})
    }

    const initialize = async () => {
        await invokeOnExtensions('initialize', {...dmApis, eventEmitter})
    }

    return {
        registerExtension,
        initialize,
        createServiceAPIs,
        extensionAPI,
        serviceAPIs,
        dal,
        eventEmitter,
        EVENTS,
        events,
        pointers,
        experimentInstance,
        logger,
        schemaService,
        config: coreConfig
    }
}
