define([
    'lodash',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/constants/constants',
    'documentServices/variants/design',
    'documentServices/utils/utils',
    'experiment'
], function (_, metaDataUtils, constants, design, dsUtils, experiment) {
    'use strict'

    const allowedMediaPlayerChildrenTypeList = ['wysiwyg.viewer.components.MediaControls', 'wysiwyg.viewer.components.MediaOverlayControls']

    const MIN_MOBILE_HEIGHT = constants.MEDIA_PLAYER_MOBILE_LAYOUT_LIMITS.minHeight
    // eslint-disable-next-line no-mixed-operators
    const MAX_MOBILE_WIDTH = constants.MEDIA_PLAYER_MOBILE_LAYOUT_LIMITS.maxWidth - 2 * 20 // Margins

    const getMinHeight = aspectRatio => Math.round(Math.min(MIN_MOBILE_HEIGHT, MAX_MOBILE_WIDTH / aspectRatio))

    return {
        isRepeatable: Boolean(experiment.isOpen('dm_mediaPlayerInRepeater')),
        rotatable: true,
        flippable: true,
        canContain(ps, compPointer, potentialChildPointer) {
            const compType = metaDataUtils.getComponentType(ps, potentialChildPointer)
            return _.includes(allowedMediaPlayerChildrenTypeList, compType)
        },
        canContainByStructure(ps, compPointer, potentialChildStructure) {
            const compType = potentialChildStructure.componentType
            return _.includes(allowedMediaPlayerChildrenTypeList, compType)
        },
        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: false,
        enforceMaxDimensionsOnProportionalResize: true,
        preserveAspectRatio: true,
        ignoreChildrenOnProportionalResize: ['wysiwyg.viewer.components.MediaControls'],
        resizableSides(ps, compPointer) {
            const compDesign = design.getDesignItem(ps, compPointer)

            if (metaDataUtils.isMaintainVideoBoxAspectRatio(compDesign)) {
                return []
            }
            return [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT]
        },
        layoutLimits(ps, compPointer) {
            const compDesign = design.getDesignItem(ps, compPointer)

            const aspectRatio = metaDataUtils.getVideoBoxAspectRatio(compDesign)
            const limits = metaDataUtils.isMaintainVideoBoxAspectRatio(compDesign) ? {aspectRatio} : {}

            return ps.pointers.components.isMobile(compPointer)
                ? _.assign(limits, constants.MEDIA_PLAYER_MOBILE_LAYOUT_LIMITS, {minHeight: getMinHeight(aspectRatio)})
                : _.assign(limits, constants.MEDIA_PLAYER_LAYOUT_LIMITS)
        },
        mobileConversionConfig: {
            filterChildrenWhenHidden: true,
            nestOverlayingSiblings: false,
            fixedSize(ps, compStructure, pageId) {
                // For some reason this doesn't work as expected,
                // mobile algo (and reset layout) is using minHeight and not the calculated one.
                const compDesign = design.getDesignItemById(ps, dsUtils.stripHashIfExists(compStructure.designQuery), pageId)

                const aspectRatio = metaDataUtils.getVideoBoxAspectRatio(compDesign)
                const isMaintainAspect = metaDataUtils.isMaintainVideoBoxAspectRatio(compDesign)
                const width = MAX_MOBILE_WIDTH
                const height = isMaintainAspect
                    ? Math.round(width / aspectRatio)
                    : Math.max(compStructure.layout.height * (width / compStructure.layout.width), getMinHeight(aspectRatio))
                return {width, height}
            }
        }
    }
})
