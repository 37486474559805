import _ from 'lodash'

const Intents = {
    TPA_MESSAGE: 'TPA',
    TPA_MESSAGE_PREVIEW: 'TPA_PREVIEW',
    TPA_MESSAGE2: 'TPA2',
    TPA_RESPONSE: 'TPA_RESPONSE',
    TPA_PREVIEW_RESPONSE: 'TPA_PREVIEW_RESPONSE',
    PINGPONG_PREFIX: 'pingpong:'
}

const shouldCallViewerHandler = function (viewMode, msg) {
    const ignoreViewerHandlers = {
        getSectionUrl: true,
        siteInfo: true,
        navigateToPage: true,
        getExternalId: true,
        smRequestLogin: true,
        getValue: true,
        getValues: true,
        getPublicData: true,
        getCurrentPageAnchors: true,
        getComponentInfo: true,
        navigateToSectionPage: true,
        isAppSectionInstalled: true,
        getStateUrl: true,
        getSitePages: true,
        getSiteMap: true,
        smCurrentMember: true,
        refreshCurrentMember: true,
        onReady: true,
        revalidateSession: true
    }
    return !(viewMode === 'preview' && ignoreViewerHandlers[msg.type])
}

const isDocumentServicesHandlersInitialized = function (siteAPI) {
    return (
        siteAPI.getSiteData().viewMode === 'preview' &&
        _.get(window, 'documentServices.tpa.__privates.areDocumentServicesHandlersReady') &&
        // @ts-ignore
        window.documentServices.tpa.__privates.areDocumentServicesHandlersReady()
    )
}

const callPostMessage = function (source, data, targetOrigin?) {
    let msgStr = ''
    try {
        msgStr = JSON.stringify(data)
    } catch (e) {
        return
    }

    if (!source.postMessage) {
        source = source.contentWindow
    }
    source.postMessage(msgStr, targetOrigin || '*')
}

const generateResponseFunction = function (source, msg, intent?) {
    return function (data) {
        try {
            callPostMessage(source, {
                intent: intent || Intents.TPA_RESPONSE,
                callId: msg.callId,
                type: msg.type,
                res: data,
                status: true
            })
        } catch (e) {
            // empty
        }
    }
}

const isTPAMessage = function isTPAMessage(msgIntent) {
    return msgIntent === Intents.TPA_MESSAGE || msgIntent === Intents.TPA_MESSAGE2
}

const isTPAPreviewMessage = function isTPAPreviewMessage(msgIntent) {
    return msgIntent === Intents.TPA_MESSAGE_PREVIEW
}

const fixOldPingPongMessageType = function (msgType) {
    return msgType.replace(Intents.PINGPONG_PREFIX, '')
}

const getSiteViewMode = function (siteAPI) {
    return siteAPI.getSiteData().viewMode
}

/**
 ************** DEV BE AWARE ****************************
 * This is a security bug fix outlined here:
 * https://jira.wixpress.com/browse/WEED-15265
 *
 * It limits any messages to TPA Handlers to originate from iFrames that
 * are part of the actual TPAWidget components, calls from the current domain and calls
 * from the any *wix.com domain
 *
 */
const isValidOrigin = function (compId, siteAPI, origin) {
    return isValidComponentDomain(compId, siteAPI, origin) || isValidPageDomain(siteAPI, origin) || isValidWixDomain(siteAPI, origin)
}

const isValidPageDomain = function (siteAPI, origin) {
    const currentPage = siteAPI.getPageUrl() || ''
    return currentPage.indexOf(origin) === 0
}

const isValidComponentDomain = function (compId, siteAPI, origin) {
    const component = siteAPI.getComponentById(compId)
    const iFrameSrc = _.get(component, 'refs.iframe.src', '')
    return iFrameSrc.indexOf(origin) === 0
}

const isValidWixDomain = function (siteAPI, origin) {
    let wixBase = siteAPI.getSiteData().getServiceTopologyProperty('baseDomain') || ''
    wixBase = wixBase.replace(/\./gi, '\\.')
    const regex = new RegExp(`[\.\/]{1}${wixBase}$`, 'ig')
    return regex.test(origin)
}

const parseMessage = function (event) {
    let msg
    try {
        if (event.data) {
            msg = JSON.parse(event.data)
        } else if (event.originalEvent && event.originalEvent.data) {
            event = event.originalEvent
            msg = JSON.parse(event.data)
        }
    } catch (e) {
        //linty linter linted something
    }
    return msg
}

const handleTPAMessage = function (ps, siteAPI, callHandler, event) {
    const msg = parseMessage(event)
    const origin = event.originalEvent ? event.originalEvent.origin : event.origin

    if (msg && isTPAMessage(msg.intent)) {
        if (ps) {
            callHandler(ps, msg, generateResponseFunction(event.source, msg))
        } else if (isValidOrigin(msg.compId, siteAPI, origin)) {
            const viewMode = getSiteViewMode(siteAPI)
            if (shouldCallViewerHandler(viewMode, msg) || !isDocumentServicesHandlersInitialized(siteAPI)) {
                msg.origin = viewMode === 'site' ? 'viewer' : viewMode
                callHandler(siteAPI, msg, generateResponseFunction(event.source, msg))
            }
        }
    } else if (msg && isTPAPreviewMessage(msg.intent)) {
        if (ps) {
            callHandler(ps, msg, generateResponseFunction(event.source, msg, Intents.TPA_PREVIEW_RESPONSE))
        } else if (isValidOrigin(msg.compId, siteAPI, origin)) {
            callHandler(siteAPI, msg, generateResponseFunction(event.source, msg, Intents.TPA_PREVIEW_RESPONSE))
        }
    }
}

export default {
    Intents,
    callPostMessage,
    generateResponseFunction,
    isTPAMessage,
    fixOldPingPongMessageType,
    handleTPAMessage
}
