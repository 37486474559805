import {PageComponent, MasterPageComponent} from '../types'
import {getFlattenListOfComponents, getParent} from './conversionUtils'

export const getDuplicates = (page: PageComponent | MasterPageComponent): string[] => {
    const comps = getFlattenListOfComponents(page)
    const duplicates = []
    const uniq = {}
    for (const component of comps) {
        const {id} = component

        if (uniq[id]) {
            const parent = getParent(id, page)
            duplicates.push({...component, parent: parent.id})
        } else {
            uniq[id] = id
        }
    }
    return duplicates
}

export const containsDuplicates = (page: PageComponent | MasterPageComponent): boolean => {
    const duplicates = getDuplicates(page)

    return duplicates.length > 0
}
