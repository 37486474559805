import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const {OVERLAY, BG_COLOR} = warmupUtilsLib.mediaConsts.balataConsts

function measure(id, measureMap, nodesMap) {
    const overlayId = id + OVERLAY
    const bgColorId = id + BG_COLOR
    if (nodesMap[overlayId]) {
        measureMap.custom[overlayId] = measureMap.custom[overlayId] || {}
        measureMap.custom[overlayId].overlayImage = nodesMap[overlayId].dataset.backgroundImage
        measureMap.custom[overlayId].position = nodesMap[overlayId].dataset.position
    }
    if (nodesMap[bgColorId]) {
        measureMap.custom[bgColorId] = measureMap.custom[bgColorId] || {}
        measureMap.custom[bgColorId].position = nodesMap[bgColorId].dataset.position
    }
}

function patch(id, patchers, measureMap) {
    const overlayId = id + OVERLAY
    const bgColorId = id + BG_COLOR
    const overlayImage = _.get(measureMap.custom, [overlayId, 'overlayImage'])
    if (overlayImage) {
        patchers.css(overlayId + OVERLAY, {
            backgroundImage: measureMap.custom[overlayId].overlayImage
        })
    }
    //chrome specific issue #CLNT-6532 , chrome smooth scrolling isnt synced with animation frame.
    //workaround: placing the background color as fixed layer
    if (!_.isEmpty(measureMap.custom[bgColorId])) {
        patchers.css(bgColorId, {
            position: measureMap.custom[bgColorId].position
        })
    }
}

export default {
    measure,
    patch
}
