define(['lodash', '@wix/santa-ds-libs/src/coreUtils', '@wix/document-services-json-schemas', '@wix/santa-core-utils'], function (
    _,
    coreUtils,
    jsonSchemas,
    santaCoreUtils
) {
    'use strict'
    const {
        namespaceMapping: {
            DATA_TYPES,
            PAGE_DATA_DATA_TYPES,
            VIEWER_PAGE_DATA_TYPES,
            COMP_DATA_QUERY_KEYS,
            COMP_DATA_QUERY_KEYS_WITH_STYLE,
            SERIALIZED_SCOPED_DATA_KEYS,
            DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT,
            getNamespaceConfig
        }
    } = jsonSchemas

    const DATA_TYPES_VALUES_MAP = _.mapKeys(DATA_TYPES, _.identity)

    const VARIANTS_DATA_TYPES = _.filter(DATA_TYPES, namespace => {
        const {supportsVariants} = getNamespaceConfig(namespace)
        return supportsVariants === true
    })

    /**
     *  @exports documentServices/constants/constants
     */
    return {
        /**
         * @enum {string} documentServices.viewMode.VIEW_MODES
         */
        VIEW_MODES: {
            /**
             * @property {string}
             */
            DESKTOP: 'DESKTOP',
            /**
             * @property {string}
             */
            MOBILE: 'MOBILE'
        },
        MULTILINGUAL_TYPES: {
            multilingualTranslations: 'multilingualTranslations'
        },
        PAGE_DATA_DATA_TYPES,
        VIEWER_PAGE_DATA_TYPES,
        DATA_TYPES,
        COMP_DATA_QUERY_KEYS,
        COMP_DATA_QUERY_KEYS_WITH_STYLE,
        DATA_TYPES_VALUES_MAP,
        DATA_TYPES_VALUES_WITH_HASH: _.pick(DATA_TYPES_VALUES_MAP, [DATA_TYPES.design, DATA_TYPES.layout, DATA_TYPES.data, DATA_TYPES.breakpoints]),
        SERIALIZED_SCOPED_DATA_KEYS,
        DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT,
        SERIALIZED_DATA_KEYS: _.omit(
            {
                ..._.mapKeys(DATA_TYPES, _.identity),
                anchors: 'anchor',
                layout: 'layouts'
            },
            [DATA_TYPES.variants]
        ),
        RELATION_DATA_TYPES: {
            VARIANTS: 'VariantRelation',
            BREAKPOINTS: 'BreakpointRelation'
        },
        REF_ARRAY_DATA_TYPE: 'RefArray',
        BREAKPOINTS_TYPES: {
            DATA: 'BreakpointsData',
            RANGE: 'BreakpointRange'
        },
        LAYOUT_TYPES: {
            SINGLE_LAYOUT: 'SingleLayoutData',
            COMPONENT_LAYOUT: 'componentLayout',
            CONTAINER_LAYOUT: 'containerLayout',
            ITEM_LAYOUT: 'itemLayout',
            SINGLE_LAYOUT_KEYS: ['itemLayout', 'componentLayout', 'containerLayout'],
            LAYOUT_DATA_KEYS: ['itemLayouts', 'componentLayouts', 'containerLayouts']
        },
        PRESETS: {
            PRESET_DATA_TYPE: 'PresetData'
        },
        VARIANTS: {
            VALID_VARIANTS_DATA_TYPES: VARIANTS_DATA_TYPES,
            SINGLE_VARIANT_PER_COMP_TYPES: ['Hover', 'Mobile', 'BreakpointsData'],
            VALID_TRIGGER_TYPES: ['click', 'tap', 'mouse-in', 'mouse-out', 'hover'],
            CHILDREN_VARIANT_TO_SERIALIZE: ['Trigger'],
            TYPES: {
                HOVER: 'Hover',
                MOBILE: 'Mobile',
                STATE: 'State',
                TRIGGER: 'Trigger',
                BREAKPOINTS: 'BreakpointsData',
                PRESET: 'Preset'
            }
        },
        TRIGGER_TYPES: {
            CLICK: 'click',
            TAP: 'tap',
            MOUSE_IN: 'mouse-in',
            MOUSE_OUT: 'mouse-out',
            HOVER: 'hover'
        },
        REACTIONS: {
            TYPE: 'Reactions',
            TYPES_WITH_STATE: {
                TOGGLE: 'ToggleState',
                REMOVE: 'RemoveState',
                ADD: 'AddState'
            },
            VALID_TYPES: {
                TOGGLE: 'ToggleState',
                REMOVE: 'RemoveState',
                ADD: 'AddState',
                REMOVE_ALL: 'RemoveAllStates',
                SCROLL_BACKWARD: 'ScrollBackward',
                SCROLL_FORWARD: 'ScrollForward'
            }
        },
        DEVICES: {
            DESKTOP: 'desktop',
            MOBILE: 'mobile'
        },
        ANCHORS: {
            LOCK_THRESHOLD: 70,
            LOCK_CONDITION: {
                ALWAYS: 'always',
                NEVER: 'never',
                THRESHOLD: 'threshold'
            }
        },
        DOM_ID_PREFIX: {
            DESKTOP: '',
            MOBILE: 'mobile_',
            DEAD_MOBILE_COMP: 'dead-'
        },
        RESIZE_SIDES: {
            TOP: 'RESIZE_TOP',
            LEFT: 'RESIZE_LEFT',
            BOTTOM: 'RESIZE_BOTTOM',
            RIGHT: 'RESIZE_RIGHT'
        },
        MOVE_DIRECTIONS: {
            HORIZONTAL: 'HORIZONTAL_MOVE',
            VERTICAL: 'VERTICAL_MOVE'
        },
        COMP_TYPES: {
            PAGE: 'Page',
            MASTER_PAGE: 'Document',
            HEADER: 'wysiwyg.viewer.components.HeaderContainer',
            FOOTER: 'wysiwyg.viewer.components.FooterContainer'
        },
        PAGE_TYPES: {
            MOBILE_PAGE: 'mobile.core.components.Page',
            PAGE: 'core.components.Page',
            APP_PAGE: 'wixapps.integration.components.AppPage'
        },
        REPEATER_TYPES: {
            REPEATER: 'wysiwyg.viewer.components.Repeater'
        },
        REF_COMPONENT_TYPES: {
            REF_COMPONENT: 'wysiwyg.viewer.components.RefComponent'
        },
        COMP_IDS: {
            PAGE_GROUP: 'SITE_PAGES',
            PAGES_CONTAINER: 'PAGES_CONTAINER',
            HEADER: 'SITE_HEADER',
            FOOTER: 'SITE_FOOTER',
            BACKGROUND: 'SITE_BACKGROUND'
        },
        MOBILE_ONLY_COMPONENTS: {
            TINY_MENU: 'TINY_MENU',
            MENU_AS_CONTAINER: 'MENU_AS_CONTAINER',
            MENU_AS_CONTAINER_TOGGLE: 'MENU_AS_CONTAINER_TOGGLE',
            BACK_TO_TOP_BUTTON: 'BACK_TO_TOP_BUTTON',
            MENU_AS_CONTAINER_EXPANDABLE_MENU: 'MENU_AS_CONTAINER_EXPANDABLE_MENU'
        },
        DEAD_MOBILE_COMPONENT_TYPE: 'to.be.removed.by.MobileMerge',
        ALLOWED_MOBILE_COMPONENTS: {
            'wysiwyg.viewer.components.mobile.TinyMenu': 'wysiwyg.viewer.components.mobile.TinyMenu',
            'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': 'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
            'wysiwyg.viewer.components.BackToTopButton': 'wysiwyg.viewer.components.BackToTopButton',
            'wysiwyg.viewer.components.mobile.ExitMobileModeButton': 'wysiwyg.viewer.components.mobile.ExitMobileModeButton',
            'wysiwyg.viewer.components.QuickActionBar': 'wysiwyg.viewer.components.QuickActionBar',
            'wysiwyg.viewer.components.QuickActionBarItem': 'wysiwyg.viewer.components.QuickActionBarItem',
            'wysiwyg.viewer.components.MenuToggle': 'wysiwyg.viewer.components.MenuToggle',
            'wysiwyg.viewer.components.MenuContainer': 'wysiwyg.viewer.components.MenuContainer',
            'wysiwyg.viewer.components.ExpandableMenu': 'wysiwyg.viewer.components.ExpandableMenu'
        },
        MOBILE_ONLY_COMPONENT_TYPES: {
            BACK_TO_TOP_BUTTON: 'wysiwyg.viewer.components.BackToTopButton',
            BACK_TO_TOP_BUTTON_LEGACY: 'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton'
        },
        PERMISSIONS: {
            CONNECT_DOMAIN: 'my-account.connect-domain',
            EDIT_REVISIONS: 'html-editor.edit-revisions',
            EDIT: 'html-editor.edit',
            SAVE: 'html-editor.save',
            PREVIEW: 'html-editor.preview',
            PUBLISH: 'html-editor.publish',
            UNPUBLISH: 'html-editor.unpublish',
            UPGRADE: 'my-account.upgrade-site',
            VIEW_REVISIONS: 'html-editor.view-revisions'
        },
        POPUPS: {
            DEFAULT_DELAY: 2
        },
        HERO_IMAGE: {
            MIN_HEIGHT: 7
        },
        /**
         * @class documentServices.mainMenu.ITEM_TYPES
         * @enum {string} item types enum
         */
        MENU_ITEM_TYPES: {
            /** @property {string}*/
            PAGE: 'page',
            /** @property {string}*/
            LINK: 'link',
            /** @property {string}*/
            HEADER: 'header'
        },
        SITE_STRUCTURE: santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        MASTER_PAGE_ID: santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        SITE_SEGMENTS_GAP_THRESHOLD: 20,
        JSON_TYPES: {
            FULL: 'fullJson',
            DISPLAYED: 'siteData'
        },
        COMP_ALIGNMENT_OPTIONS: {
            LEFT: 'left',
            RIGHT: 'right',
            CENTER: 'center',
            TOP: 'top',
            BOTTOM: 'bottom',
            MIDDLE: 'middle'
        },
        COMP_DISTRIBUTION_OPTIONS: {
            BOTH: 'verticalAndHorizontal',
            VERTICAL: 'vertical',
            HORIZONTAL: 'horizontal'
        },
        COMP_MATCH_SIZE_OPTIONS: {
            WIDTH: 'width',
            HEIGHT: 'height',
            BOTH: 'heightAndWidth'
        },
        URLS: {
            MAX_LENGTH: 100
        },
        SNAPSHOT_TAGS: {
            MOBILE_MERGE: 'mobileMerge'
        },
        ATOMIC_SCOPES: {
            TYPE: 'AtomicScope',
            URI_PREFIX: 'URI'
        },
        STYLES: {
            THEME_DATA_ID: 'THEME_DATA',
            COMPONENT_STYLE: 'ComponentStyle',
            TOP_LEVEL_STYLE: 'TopLevelStyle',
            FLAT_THEME: 'WFlatTheme',
            TYPES: {
                SYSTEM: 'system',
                CUSTOM: 'custom'
            },
            STYLES_PER_PAGE_VER: santaCoreUtils.constants.STYLES_PER_PAGE_VER
        },
        MEDIA_PLAYER_MOBILE_LAYOUT_LIMITS: {
            minWidth: 240,
            maxWidth: 320,
            minHeight: 120,
            maxHeight: 480
        },
        MEDIA_PLAYER_LAYOUT_LIMITS: {
            minWidth: 240,
            maxWidth: 1920,
            minHeight: 240,
            maxHeight: 1920
        },
        INTERACTIONS: coreUtils.loggingUtils.fedopsLogger.INTERACTIONS,
        REF_COMPONENT: {
            REF_COMPONENT_TYPE: 'wysiwyg.viewer.components.RefComponent'
        },
        PLATFORM_INTERACTIONS: {
            PROVISION: 'platform_provision',
            SETTLE_ACTIONS: 'save.settleActions',
            ADD_APPS_ALL_PROCESS: 'platform_addApps_allProcess',
            ADD_APPS_PROVISION: 'platform_addApps_provision',
            ADD_APP_AFTER_PROVISION: 'platform_addApp_after_provision',
            SET_MANIFEST: 'workerService.setManifest',
            WORKER_ADD_APP: 'workerService.addApp',
            ADD_APP_GET_APP_TYPE: 'platform_addApp_after_provision_get_app_type',
            AFTER_APP_ADDED_ACTIONS: 'workerService.afterAppAddedActions',
            ADD_WIDGET_AFTER_PROVISION: 'tpaAddService.addWidgetAfterProvision',
            ADD_SECTION_AFTER_PROVISION: 'tpaAddService.addSectionAfterProvision',
            ON_PRE_SAVE_PROVISION: 'provision.onPreSaveProvisionSuccess',
            INSTALL_CODE_REUSE: 'addApps.installCodeReuse'
        },
        PLATFORM_ERRORS: {
            ON_PRE_SAVE_PROVISION: 'provision.onPreSaveProvisionSuccess.AddAppResponse',
            ADD_SECTION_AFTER_PROVISION: 'tpaAddService.addSectionAfterProvision',
            ADD_WIDGET_AFTER_PROVISION: 'tpaAddService.addWidgetAfterProvision',
            AFTER_APP_ADDED_ACTIONS: 'workerService.afterAppAddedActions'
        },
        TPA_WIDGET_ADDITION: {
            GET_CONTAINER: 'tpaWidgetService.getParentContainer',
            CREATE_WIDGET_REF: 'tpaWidgetService.getWidgetRef',
            ADD_WIDGET: 'tpaWidgetService.addWidget'
        },
        TPA_SECTION_ADDITION: {
            ADD_PAGE: 'tpaSectionService.addPage',
            ADD_SECTION: 'tpaSectionService.addSection'
        },
        VIEWER_NAMES: {
            BOLT: 'bolt',
            THUNDERBOLT: 'tb',
            SANTA: 'santa',
            MOCK: 'mock'
        }
    }
})
