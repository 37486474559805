define([
    'lodash',
    'editorSkinsData',
    'wix-ui-santa/components-editor-params',
    '@wix/editor-elements-preview-utils/componentsEditorParams',
    'experiment'
], function (_, generatedEditorSkinsData, wixUiSantaComponentsEditorParams, editorElementsComponentsEditorParams, experiment) {
    'use strict'

    const editorParams = experiment.isOpen('sv_inputElementsA11y')
        ? _.merge(generatedEditorSkinsData, wixUiSantaComponentsEditorParams, editorElementsComponentsEditorParams.default)
        : _.merge(generatedEditorSkinsData, wixUiSantaComponentsEditorParams)

    return editorParams
})
