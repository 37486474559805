define([
    'lodash',
    'documentServices/variants/variants',
    'documentServices/namespaces/namespaces',
    'documentServices/constants/constants',
    'documentServices/reactions/reactionsUtils',
    'documentServices/utils/utils'
], (_, variants, namespaces, constants, reactionsUtils, dsUtils) => {
    const STATE_TYPE = constants.VARIANTS.TYPES.STATE
    const STATES_TYPE = 'States'
    const STATES_NAMESPACE = constants.DATA_TYPES.states

    const getStateVariantToAddRef = (ps, compPointer) => variants.getVariantToAddRef(ps, compPointer, STATE_TYPE)

    const validateStateData = stateData => {
        if (!stateData || !_.has(stateData, 'name')) {
            throw new Error('State data should be an object with a "name" property')
        }
    }

    /**
     * Adds a new state to a component
     *
     * @param {ps} ps
     * @param {Pointer} variantToAddRef
     * @param {Pointer} componentPointer
     * @param {object} stateData
     */
    const add = (ps, variantToAddRef, componentPointer, stateData) => {
        validateStateData(stateData)

        variants.createInternal(ps, variantToAddRef, componentPointer, STATE_TYPE, stateData)
        const variantsList = namespaces.getNamespaceData(ps, componentPointer, STATES_NAMESPACE)?.values || []
        variantsList.push(`#${variantToAddRef.id}`)

        namespaces.updateNamespaceData(ps, componentPointer, STATES_NAMESPACE, {type: STATES_TYPE, values: variantsList})
    }

    /**
     * Removes a state from a component
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} statePointer
     */
    const remove = (ps, componentPointer, statePointer) => {
        if (!ps.dal.isExist(statePointer) || ps.dal.get(statePointer).type !== STATE_TYPE) {
            throw new Error('Cannot remove a non-state pointer')
        }

        const currentStates = namespaces.getNamespaceData(ps, componentPointer, STATES_NAMESPACE)
        const statesAfterRemoval = _.without(currentStates.values, `#${statePointer.id}`)

        namespaces.updateNamespaceData(ps, componentPointer, STATES_NAMESPACE, {type: STATES_TYPE, values: statesAfterRemoval})
        reactionsUtils.removeReactionsByState(ps, componentPointer, statePointer)
        variants.remove(ps, statePointer)
    }

    /**
     * Updates an existing state
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} variantPointer
     * @param {object} newStateData
     */
    const update = (ps, componentPointer, variantPointer, newStateData) => {
        validateStateData(newStateData)
        const originalVariantData = ps.dal.get(variantPointer)
        const newData = _.defaults(newStateData, originalVariantData)
        ps.dal.set(variantPointer, newData)
    }

    /**
     * Gets the state data for a given variant
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} variantPointer
     * @returns {{name: string, type: string}} State data for the given state variant
     */
    const get = (ps, componentPointer, variantPointer) => {
        const stateData = ps.dal.get(variantPointer)
        if (!stateData || stateData.type !== STATE_TYPE) {
            throw new Error('Cannot get missing or non-state variant')
        }

        return _.pick(stateData, ['name', 'type'])
    }

    /**
     * Gets all the stateVariant references we have in the components' statesData
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Array} An array of all State variants of the component
     */
    const getAll = (ps, componentPointer) => {
        const statesData = namespaces.getNamespaceData(ps, componentPointer, STATES_NAMESPACE)

        if (statesData?.values) {
            const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id
            return statesData.values.map(variantId => ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(variantId), pageId))
        }

        return []
    }

    return {
        getStateVariantToAddRef,
        add,
        remove,
        update,
        get,
        getAll
    }
})
