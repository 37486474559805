import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {constants} from '@wix/document-manager-extensions'
const {VIEW_MODES} = constants
import {pointerUtils} from '@wix/document-manager-core'
import type {ViewerManagerDAL} from '@wix/viewer-manager-adapter'
const {getPointerFromPointer} = pointerUtils

const COMPONENTS_TO_UPDATE_GRAND_CHILDREN_WITH_DOM_ONLY = {
    'wysiwyg.viewer.components.BoxSlideShow': true,
    'wysiwyg.viewer.components.StripContainerSlideShow': true,
    'wysiwyg.viewer.components.StripColumnsContainer': true
}

const COMPONENTS_TO_UPDATE_PARENT_WITH_DOM_ONLY = {
    'wysiwyg.viewer.components.Column': (isMobile: boolean) => !isMobile
}
const COMPONENTS_TO_UPDATE_SIBLINGS_WITH_DOM_ONLY = {
    'wysiwyg.viewer.components.Column': (isMobile: boolean) => !isMobile
}

function shouldLockParent(componentType: string, parentComponentType: string, isMobile: boolean) {
    return parentComponentType === 'wysiwyg.viewer.components.Group' || _.invoke(COMPONENTS_TO_UPDATE_PARENT_WITH_DOM_ONLY, componentType, isMobile)
}

function shouldLockSiblings(componentType: string, isMobile: boolean) {
    return _.invoke(COMPONENTS_TO_UPDATE_SIBLINGS_WITH_DOM_ONLY, componentType, isMobile)
}

function shouldLockGrandChildren(componentType: string) {
    return COMPONENTS_TO_UPDATE_GRAND_CHILDREN_WITH_DOM_ONLY[componentType]
}

export function getAdditionalComps(compPointer: Pointer, displayedDal: ViewerManagerDAL) {
    const comp = displayedDal.get(compPointer, false)
    const childrenIds = comp.components
    const compsToApplyDOMStyleMap = {}

    const addCompsToApplyDOMStyle = (components: string[]) => {
        _.forEach(components, compId => {
            _.setWith(compsToApplyDOMStyleMap, [compId], true, Object)
        })
    }

    addCompsToApplyDOMStyle([comp.id])
    addCompsToApplyDOMStyle(childrenIds)

    const parentId = comp.parent
    if (parentId) {
        const parentComp = displayedDal.get(getPointerFromPointer(parentId, compPointer), false)
        const isMobile = compPointer.type === VIEW_MODES.MOBILE
        const {componentType} = comp
        const parentComponentType = parentComp.componentType

        if (shouldLockParent(componentType, parentComponentType, isMobile)) {
            addCompsToApplyDOMStyle([parentId])
        }

        if (shouldLockSiblings(componentType, isMobile)) {
            const compSiblings = parentComp.components
            addCompsToApplyDOMStyle(compSiblings)
        }

        if (shouldLockGrandChildren(componentType)) {
            _.forEach(childrenIds, childId => {
                const childComp = displayedDal.get(getPointerFromPointer(childId, compPointer), false)
                const grandChildren = childComp.components
                addCompsToApplyDOMStyle(grandChildren)
            })
        }
    }

    return compsToApplyDOMStyleMap
}
