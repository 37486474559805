define(['documentServices/constants/constants', 'documentServices/utils/utils', 'experiment'], function (consts, dsUtils, experiment) {
    'use strict'

    const MIN_WIDTH = 50
    const MIN_HEIGHT = 50

    const res = {
        mobileConversionConfig: {
            createMobilePresets: false
        },
        hiddenable: false,
        styleCanBeApplied: false,
        moveDirections: [],
        canBeFixedPosition: false,
        duplicatable: false,
        groupable: false,
        removable: true,
        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: false,
        layoutLimits: {
            minWidth: MIN_WIDTH,
            minHeight: MIN_HEIGHT
        },
        resizableSides(ps, pointer) {
            const {LEFT, RIGHT, BOTTOM, TOP} = consts.RESIZE_SIDES,
                compStructure = ps.dal.get(pointer),
                page = ps.pointers.components.getPageOfComponent(pointer),
                pageId = page && page.id,
                compPropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(compStructure.propertyQuery), pageId),
                compProps = ps.dal.get(compPropsPointer),
                nineGridMap = {
                    left: {
                        top: [BOTTOM, RIGHT],
                        center: [BOTTOM, RIGHT],
                        bottom: [TOP, RIGHT]
                    },
                    center: {
                        top: [BOTTOM, RIGHT, LEFT],
                        center: [TOP, BOTTOM, RIGHT, LEFT],
                        bottom: [TOP, RIGHT, LEFT]
                    },
                    right: {
                        top: [BOTTOM, LEFT],
                        center: [BOTTOM, LEFT],
                        bottom: [TOP, LEFT]
                    }
                },
                fullHeightMap = {
                    left: [RIGHT],
                    center: [RIGHT, LEFT],
                    right: [LEFT]
                },
                fullWidth = {
                    top: [BOTTOM],
                    center: [TOP, BOTTOM],
                    bottom: [TOP]
                },
                sidesMap = {
                    nineGrid() {
                        return nineGridMap[compProps.horizontalAlignment][compProps.verticalAlignment]
                    },
                    fullHeight() {
                        return fullHeightMap[compProps.horizontalAlignment]
                    },
                    fullWidth() {
                        return fullWidth[compProps.verticalAlignment]
                    }
                }

            return sidesMap[compProps.alignmentType]()
        },
        defaultMobileProperties(ps, comp, desktopProps) {
            return {
                horizontalAlignment: desktopProps.alignmentType === 'nineGrid' ? 'center' : desktopProps.horizontalAlignment,
                horizontalOffset: 0,
                verticalOffset: 0
            }
        }
    }
    if (experiment.isOpen('sv_movePopup')) {
        // @ts-ignore
        delete res.moveDirections
    }
    return res
})
