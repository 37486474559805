define([
    'lodash',
    'documentServices/platform/common/constants',
    'documentServices/utils/utils',
    'documentServices/appControllerData/appControllerDataItem',
    'documentServices/appControllerData/appControllerUtils',
    'documentServices/appControllerData/appControllerState'
], function (_, constants, dsUtils, appControllerDataItem, appControllerUtils, appControllerState) {
    'use strict'

    function getControllerTypeAndApplicationId(ps, controllerRef) {
        const {applicationId, controllerType, widgetId, appDefinitionId} = appControllerDataItem.getControllerDataItem(ps, controllerRef) || {}
        const ooiController = appControllerUtils.isOOIController(dsUtils.getComponentType(ps, controllerRef))
        return {
            controllerType: ooiController ? widgetId : controllerType,
            applicationId: ooiController ? appDefinitionId : applicationId
        }
    }

    function getDisabledActionsConfig() {
        const behavior = {
            duplicatable: false,
            canReparent: false,
            rotatable: false,
            pinnable: false,
            resizable: false,
            toggleShowOnAllPagesEnabled: false
        }
        return {
            behavior,
            connections: {
                '*': {
                    behavior
                }
            }
        }
    }

    /**
     * @param {ps} ps
     * @param {string} controllerId
     * @param controllerType
     * @param applicationId
     * @returns {any|{behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}, connections: {'*': {behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}}}}}
     */
    function getControllerStageData(ps, controllerId, controllerType, applicationId) {
        const applicationHasAppManifest = hasAppManifest(ps, applicationId)
        if (!applicationHasAppManifest) {
            return getDisabledActionsConfig()
        }

        const setDefaultConnectionIntoConnections = state => {
            const stateConnections = _.get(state, constants.Controller.CONNECTIONS)
            const stateDefaultConnection = _.get(stateConnections, constants.Controller.WILDCARD_ROLE)
            const setDefaultConnection = connection => _.defaultsDeep({}, connection, stateDefaultConnection)
            if (stateDefaultConnection) {
                _.set(state, constants.Controller.CONNECTIONS, _.mapValues(stateConnections, setDefaultConnection))
            }
            return state
        }

        const nonDefaultStateWithDefaults = (state, defaultState) => {
            const stateConnections = _.get(state, constants.Controller.CONNECTIONS)
            const defaultStateConnections = _.get(defaultState, constants.Controller.CONNECTIONS)
            const setDefaultsIntoOverrides = (connection, connectionKey) => _.defaultsDeep({}, connection, defaultStateConnections[connectionKey])
            if (stateConnections && defaultStateConnections) {
                const stateConnectionsWithDefaults = _.mapValues(stateConnections, setDefaultsIntoOverrides)
                _.set(state, constants.Controller.CONNECTIONS, _.defaultsDeep(stateConnectionsWithDefaults, defaultStateConnections))
            }
            return _.defaultsDeep({}, state, defaultState)
        }

        const controllerState = appControllerState.getState(ps, controllerId)
        const controllerStageDataPointer = ps.pointers.platform.getControllerStageDataPointer(applicationId, controllerType, controllerState)
        const stateStageData = setDefaultConnectionIntoConnections(ps.dal.get(controllerStageDataPointer))

        if (controllerState === constants.Controller.DEFAULT_STATE) {
            return stateStageData
        }

        const defaultStageDataPointer = ps.pointers.platform.getControllerStageDataPointer(applicationId, controllerType, constants.Controller.DEFAULT_STATE)
        const defaultStageData = setDefaultConnectionIntoConnections(ps.dal.get(defaultStageDataPointer))

        return nonDefaultStateWithDefaults(stateStageData, defaultStageData)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {boolean}
     */
    function hasAppManifestByControllerRef(ps, controllerRef) {
        const {applicationId} = getControllerTypeAndApplicationId(ps, controllerRef)
        return hasAppManifest(ps, applicationId)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {*}
     */
    function getControllerStageDataByControllerRef(ps, controllerRef) {
        if (!controllerRef) {
            return
        }
        const {applicationId, controllerType} = getControllerTypeAndApplicationId(ps, controllerRef)
        return getControllerStageData(ps, controllerRef.id, controllerType, applicationId)
    }

    /**
     * @param {ps} ps
     * @param {string} controllerId
     * @param controllerType
     * @param applicationId
     * @param role
     * @param subRole
     * @returns {any| {behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}}}
     */
    function getControllerStageDataByRole(ps, controllerId, controllerType, applicationId, role, subRole) {
        const applicationHasAppManifest = hasAppManifest(ps, applicationId)
        if (!applicationHasAppManifest) {
            return null
        }

        const getSubRoleData = state =>
            subRole ? ps.dal.get(ps.pointers.platform.getControllerRolePointer(applicationId, controllerType, state, subRole)) : null

        const roleWithDefault = state => {
            const roleStageDataPointer = ps.pointers.platform.getControllerRolePointer(applicationId, controllerType, state, role)
            const defaultRoleStageDataPointer = ps.pointers.platform.getControllerRolePointer(
                applicationId,
                controllerType,
                state,
                constants.Controller.WILDCARD_ROLE
            )
            const roleStageData = ps.dal.get(roleStageDataPointer)
            const defaultRoleStageData = ps.dal.get(defaultRoleStageDataPointer)
            const subRoleData = getSubRoleData(state)
            if (!roleStageData && !defaultRoleStageData && !subRoleData) {
                return null
            }
            return _.defaultsDeep({}, subRoleData, roleStageData, defaultRoleStageData)
        }

        const controllerState = appControllerState.getState(ps, controllerId)
        const roleStageDataWithDefaultRoleData = roleWithDefault(controllerState)

        if (controllerState === constants.Controller.DEFAULT_STATE) {
            return roleStageDataWithDefaultRoleData
        }

        const roleInDefaultStateWithDefaultRoleData = roleWithDefault(constants.Controller.DEFAULT_STATE)
        if (!roleStageDataWithDefaultRoleData && !roleInDefaultStateWithDefaultRoleData) {
            return null
        }
        return _.defaultsDeep({}, roleStageDataWithDefaultRoleData, roleInDefaultStateWithDefaultRoleData)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     * @param role
     * @param subRole
     * @returns {*}
     */
    function getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role, subRole) {
        if (!controllerRef || !role) {
            return
        }
        const {applicationId, controllerType} = getControllerTypeAndApplicationId(ps, controllerRef)
        return getControllerStageDataByRole(ps, controllerRef.id, controllerType, applicationId, role, subRole)
    }

    /**
     * @param {ps} ps
     * @param {string} applicationId
     * @returns {boolean}
     */
    function hasAppManifest(ps, applicationId) {
        const appManifestPointer = ps.pointers.platform.getAppManifestPointer(applicationId)
        return ps.dal.isExist(appManifestPointer)
    }

    return {
        getControllerStageDataByControllerRef,
        getControllerRoleStageDataByControllerRefAndRole,
        // privateAPI
        getControllerStageData,
        hasAppManifestByControllerRef
    }
})
