define([
    'lodash',
    'experiment',
    'documentServices/extensionsAPI/santaSeoExtensionAPI',
    'documentServices/extensionsAPI/santaDataExtensionAPI',
    'documentServices/extensionsAPI/santaUtilExtensionAPI',
    'documentServices/extensionsAPI/santaPlatformExtensionAPI',
    'documentServices/utils/multilingual',
    'documentServices/utils/runtimeConfig',
    '@wix/wix-immutable-proxy',
    'documentServices/constants/constants',
    'documentServices/siteMetadata/generalInfo'
], function (
    _,
    experiment,
    santaSeoExtensionAPI,
    santaDataExtensionAPI,
    santaUtilExtensionAPI,
    santaPlatformExtensionAPI,
    mlUtils,
    runtimeConfig,
    wixImmutableProxy,
    constants,
    generalInfo
) {
    'use strict'
    const INCLUDE_MASTER_PAGE = true
    const {deepClone, referenceCompare} = wixImmutableProxy

    const executorByContext =
        funcs =>
        (ps, ...args) =>
            !runtimeConfig.isSanta(ps) ? funcs.default(ps, ...args) : funcs.santa(ps, ...args)
    const wrapAPI = api => _.mapValues(api, executorByContext)

    const SEO_ERRORS = {
        SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN: 'SE_ENABLE_INDEX_PARAM_IS_NOT_BOOLEN',
        TEXT_IS_NOT_STRING: 'TEXT_IS_NOT_STRING',
        TEXT_TOO_LONG: 'TEXT_TOO_LONG',
        TEXT_INVALID_CHARS: 'TEXT_INVALID_CHARS',
        KEYWORDS_INVALID_CHARS: 'KEYWORDS_INVALID_CHARS',
        METATAGS_INVALID_FORMAT: 'METATAGS_INVALID_FORMAT',
        METATAGS_INVALID_CHARS: 'METATAGS_INVALID_CHARS',
        METATAGS_SERVER_INVALID_CODE: 'METATAGS_SERVER_INVALID_CODE',
        METATAGS_SERVER_INVALID_TAG: 'METATAGS_SERVER_INVALID_TAG',
        REDIRECT_INVALID_CHARS: 'REDIRECT_INVALID_CHARS',
        REDIRECT_URI_MAPPING_IS_NOT_OBJECT: 'REDIRECT_URI_MAPPING_IS_NOT_OBJECT',
        REDIRECT_FROM_URIS_IS_NOT_ARRAY: 'REDIRECT_FROM_URIS_IS_NOT_ARRAY',
        REDIRECT_MAPPING_INVALID_FORMAT: 'REDIRECT_MAPPING_INVALID_FORMAT',
        REDIRECT_MAPPING_URIS_NOT_STRING: 'REDIRECT_MAPPING_URIS_NOT_STRING',
        SERVER_VALIDATION_TIMEOUT: 'SERVER_VALIDATION_TIMEOUT'
    }

    const pagesExtensionAPI = {
        getAllPagesIds: {
            default: ps => ps.siteAPI.getAllPagesIds(true),
            santa: ps => ps.dal.getKeys(ps.pointers.page.getAllPagesPointer())
        },
        getNonDeletedChangedPagePointersSinceLastSnapshot: {
            default: (ps, tag) => {
                const nonDeletedPagePointers = ps.pointers.page.getNonDeletedPagesPointers(INCLUDE_MASTER_PAGE)
                const changedPages = ps.dal.getChangedPagesSinceLastSnapshot(tag)
                return nonDeletedPagePointers.filter(pointer => changedPages.includes(pointer.id))
            },
            santa: (ps, tag) => {
                const fromSnapshot = ps.dal.full.immutable.getLastSnapshotByTagName(tag)
                if (!fromSnapshot) {
                    tag = 'initialState'
                }
                return ps.pointers.page
                    .getNonDeletedPagesPointers(INCLUDE_MASTER_PAGE)
                    .filter(pagePointer => ps.dal.wasValueChangedSinceLastSnapshot(tag, pagePointer))
            }
        },
        getMainPageId: {
            default: ps => ps.extensionAPI.page.getMainPageId(),
            santa: ps => ps.siteAPI.getMainPageId()
        },
        removeMobileStructure: {
            default: (ps, pageId) => ps.extensionAPI.page.removeMobileStructure(pageId),
            santa: _.noop
        },
        getAllCompsOnPage: {
            default: (ps, pageId, viewMode) => ps.extensionAPI.page.getAllCompsOnPage(pageId, viewMode),
            santa: (ps, pageId) => ps.siteAPI.getComponentsByPageIdForWixCode(pageId)
        }
    }

    let originalLanguagePointer

    const dataExtensionAPI = {
        getNoClone: {
            default: (ps, pointer) => ps.dal.getNoClone(pointer),
            santa: (ps, pointer) => ps.dal.get(pointer)
        },
        getFullNoClone: {
            default: (ps, pointer) => ps.dal.full.getNoClone(pointer),
            santa: (ps, pointer) => ps.dal.full.get(pointer)
        },
        getItemWithMultilingualOverridesInLang: {
            default: (ps, dataItemPointer, useLanguage) => {
                originalLanguagePointer = originalLanguagePointer || ps.pointers.multilingual.originalLanguage()
                const originalLanguageCode = _.get(ps.dal.get(originalLanguagePointer), 'languageCode')
                dataItemPointer = {
                    ...dataItemPointer,
                    useLanguage: originalLanguageCode
                }
                const dataItem = ps.dal.full.getNoClone(dataItemPointer)
                if (dataItem && useLanguage !== originalLanguageCode) {
                    dataItemPointer.useLanguage = useLanguage
                    const translatedDataItem = ps.dal.full.getNoClone(dataItemPointer)
                    if (!referenceCompare(translatedDataItem, dataItem)) {
                        return _.defaults(_.omitBy(deepClone(translatedDataItem), _.isNil), deepClone(dataItem))
                    }
                }
                return deepClone(dataItem)
            },
            santa: (ps, dataItemPointer, useLanguage) => {
                const originalLanguageCode = mlUtils.getLanguageByUseOriginal(ps, true)
                dataItemPointer = {
                    ...dataItemPointer,
                    useLanguage: originalLanguageCode
                }
                const dataItem = ps.dal.full.get(dataItemPointer)
                if (dataItem && useLanguage !== originalLanguageCode) {
                    dataItemPointer.useLanguage = useLanguage
                    const translatedDataItem = ps.dal.full.get(dataItemPointer)
                    return _.defaults(_.omitBy(translatedDataItem, _.isNil), dataItem)
                }
                return dataItem
            }
        },
        getVariantItemsWithPredicate: {
            default: (ps, predicate, pageId) => ps.pointers.data.getVariantItemsWithPredicate(predicate, pageId),
            santa: () => []
        },
        query: {
            santa: (ps, type, pageId, predicate) => {
                const pageIds = pageId ? [pageId] : pagesExtensionAPI.getAllPagesIds.santa(ps)
                const dataMapName = constants.PAGE_DATA_DATA_TYPES[type]
                if (!dataMapName) {
                    throw new Error(`no data map exists for data type ${type}`)
                }
                return _.reduce(
                    pageIds,
                    (result, id) => {
                        const dataMap = ps.dal.full.get(ps.pointers.getInnerPointer(ps.pointers.page.getPagePointer(id), ['data', dataMapName]))
                        if (predicate) {
                            return _.assign(result, _.pickBy(dataMap, predicate))
                        }
                        return _.assign(result, dataMap)
                    },
                    {}
                )
            },
            default: (ps, type, pageId, predicate) => deepClone(ps.extensionAPI.data.query(type, pageId, predicate))
        },
        queryKeys: {
            santa: (ps, type, pageId, predicate) => {
                const pageIds = pageId ? [pageId] : pagesExtensionAPI.getAllPagesIds.santa(ps)
                const dataMapName = constants.PAGE_DATA_DATA_TYPES[type]
                if (!dataMapName) {
                    throw new Error(`no data map exists for data type ${type}`)
                }
                return _.reduce(
                    pageIds,
                    (result, id) => {
                        const dataItemsKeys = ps.dal.full.getKeys(ps.pointers.getInnerPointer(ps.pointers.page.getPagePointer(id), ['data', dataMapName]))
                        if (predicate) {
                            return _.union(result, _.filter(dataItemsKeys, predicate))
                        }
                        return _.union(result, dataItemsKeys)
                    },
                    []
                )
            },
            default: (ps, type, pageId, predicate) => ps.extensionAPI.data.queryKeys(type, pageId, predicate)
        }
    }

    const seoIndexingExtensionAPI = {
        enable: {
            default: (ps, ...args) => ps.extensionAPI.seo.indexing.enable(...args),
            santa: santaSeoExtensionAPI.indexing.enable
        },
        isEnabled: {
            default: (ps, ...args) => ps.extensionAPI.seo.indexing.isEnabled(...args),
            santa: santaSeoExtensionAPI.indexing.isEnabled
        }
    }

    const seoTitleExtensionAPI = {
        set: {
            default: (ps, ...args) => ps.extensionAPI.seo.title.set(...args),
            santa: santaSeoExtensionAPI.title.set
        },
        get: {
            default: (ps, ...args) => ps.extensionAPI.seo.title.get(...args),
            santa: santaSeoExtensionAPI.title.get
        },
        validate: {
            default: (ps, ...args) => ps.extensionAPI.seo.title.validate(...args),
            santa: santaSeoExtensionAPI.title.validate
        }
    }

    const seoDescriptionExtensionAPI = {
        set: {
            default: (ps, ...args) => ps.extensionAPI.seo.description.set(...args),
            santa: santaSeoExtensionAPI.description.set
        },
        get: {
            default: (ps, ...args) => ps.extensionAPI.seo.description.get(...args),
            santa: santaSeoExtensionAPI.description.get
        },
        validate: {
            default: (ps, ...args) => ps.extensionAPI.seo.description.validate(...args),
            santa: santaSeoExtensionAPI.description.validate
        }
    }

    const seoKeywordsExtensionAPI = {
        set: {
            default: (ps, ...args) => ps.extensionAPI.seo.keywords.set(...args),
            santa: santaSeoExtensionAPI.keywords.set
        },
        get: {
            default: (ps, ...args) => ps.extensionAPI.seo.keywords.get(...args),
            santa: santaSeoExtensionAPI.keywords.get
        },
        validate: {
            default: (ps, ...args) => ps.extensionAPI.seo.keywords.validate(...args),
            santa: santaSeoExtensionAPI.keywords.validate
        }
    }

    const seoHeadTagsExtensionAPI = {
        set: {
            default: (ps, ...args) => ps.extensionAPI.seo.headTags.set(...args),
            santa: santaSeoExtensionAPI.headTags.set
        },
        get: {
            default: (ps, ...args) => ps.extensionAPI.seo.headTags.get(...args),
            santa: santaSeoExtensionAPI.headTags.get
        },
        validate: {
            default: (ps, ...args) => ps.extensionAPI.seo.headTags.validate(...args),
            santa: santaSeoExtensionAPI.headTags.validate
        },
        clientSideValidation: {
            default: (ps, ...args) => ps.extensionAPI.seo.headTags.clientSideValidation(...args),
            santa: santaSeoExtensionAPI.headTags.clientSideValidation
        }
    }

    const seoRedirectUrlsExtensionAPI = {
        update: {
            default: (ps, ...args) => ps.extensionAPI.seo.redirectUrls.update(...args),
            santa: santaSeoExtensionAPI.redirectUrls.update
        },
        remove: {
            default: (ps, ...args) => ps.extensionAPI.seo.redirectUrls.remove(...args),
            santa: santaSeoExtensionAPI.redirectUrls.remove
        },
        get: {
            default: (ps, ...args) => ps.extensionAPI.seo.redirectUrls.get(...args),
            santa: santaSeoExtensionAPI.redirectUrls.get
        },
        validate: {
            default: (ps, ...args) => ps.extensionAPI.seo.redirectUrls.validate(...args),
            santa: santaSeoExtensionAPI.redirectUrls.validate
        }
    }

    const documentServicesModelExtensionAPI = {
        getRevision: {
            default: ps => ps.extensionAPI.siteAPI.getSiteRevision(),
            santa: ps => ps.dal.full.immutable.getByPath(['documentServicesModel', 'revision'])
        },
        getBranchId: {
            default: ps => ps.extensionAPI.siteAPI.getBranchId(),
            santa: ps => {
                try {
                    return ps.dal.full.immutable.getByPath(['documentServicesModel', 'branchId'])
                } catch (e) {
                    return null
                }
            }
        }
    }

    const invokeCsave = (ps, name, ...args) => _.invoke(ps, ['extensionAPI', 'continuousSave', name], ...args)

    const csave = {
        createRevision: async (ps, createRevArgs, updateSiteDto) => {
            return await invokeCsave(ps, 'createRevision', createRevArgs, updateSiteDto)
        },
        setSaving: (ps, isSaving) => {
            invokeCsave(ps, 'setSaving', isSaving)
        },
        initHooks: (ps, config) => {
            invokeCsave(ps, 'initHooks', config)
        },
        getWrappedHooks: (ps, config) => invokeCsave(ps, 'getWrappedHooks', config),
        save: async ps => {
            await invokeCsave(ps, 'save')
        },
        saveFromLastCSaveSnapshot: async (ps, snapshot) => {
            await invokeCsave(ps, 'saveFromLastCSaveSnapshot', snapshot)
        },
        forceSaveAndWaitForResult: async ps => {
            await invokeCsave(ps, 'forceSaveAndWaitForResult')
        },
        setEnabled: (ps, enabled = true) => {
            invokeCsave(ps, 'setEnabled', enabled)
        },
        getLastTransactionId: ps => invokeCsave(ps, 'getLastTransactionId'),
        isCSaveOpen: ps => invokeCsave(ps, 'isCSaveOpen'),
        isCreateRevisionOpen: ps => invokeCsave(ps, 'isCreateRevisionOpen'),
        isCEditOpen: ps => invokeCsave(ps, 'isCEditOpen'),
        isValidationRecovery: ps => invokeCsave(ps, 'isValidationRecovery')
    }

    const schema = {
        removeAdditionalProperties: {
            default: (ps, namespace, value) => ps.extensionAPI.schemaAPI.removeAdditionalProperties(namespace, value),
            santa: _.noop
        },
        removeAdditionalPropertiesSafely: {
            default: (ps, data) => ps.extensionAPI.schemaAPI.removeAdditionalPropertiesSafely(data),
            santa: _.noop
        },
        removeWhitelistedPropertiesSafely: {
            default: (ps, data, conservativeRemoval) => ps.extensionAPI.schemaAPI.removeWhitelistedPropertiesSafely(data, conservativeRemoval),
            santa: (ps, data) => data
        },
        extractReferenceFieldsInfoForSchema: {
            default: (ps, namespace, dataTypeName) => ps.extensionAPI.schemaAPI.extractReferenceFieldsInfoForSchema(namespace, dataTypeName),
            santa: _.noop
        }
    }

    const seo = {
        ERRORS: SEO_ERRORS,
        indexing: {...wrapAPI(seoIndexingExtensionAPI)},
        title: {...wrapAPI(seoTitleExtensionAPI)},
        description: {...wrapAPI(seoDescriptionExtensionAPI)},
        keywords: {...wrapAPI(seoKeywordsExtensionAPI)},
        headTags: {...wrapAPI(seoHeadTagsExtensionAPI)},
        redirectUrls: {...wrapAPI(seoRedirectUrlsExtensionAPI)}
    }

    const dataRefArrayExtensionAPI = {
        extractValues: {
            default: (ps, ...args) => ps.extensionAPI.data.refArray.extractValues(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.refArray.extractValues(...args)
        },
        extractValuesWithoutHash: {
            default: (ps, ...args) => ps.extensionAPI.data.refArray.extractValuesWithoutHash(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.refArray.extractValuesWithoutHash(...args)
        },
        create: {
            default: (ps, ...args) => ps.extensionAPI.data.refArray.create(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.refArray.create(...args)
        },
        update: {
            default: (ps, ...args) => ps.extensionAPI.data.refArray.update(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.refArray.update(...args)
        },
        isRefArray: {
            default: (ps, ...args) => ps.extensionAPI.data.refArray.isRefArray(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.refArray.isRefArray(...args)
        }
    }

    const dataBreakpointRelationExtensionAPI = {
        extractBreakpoint: {
            default: (ps, ...args) => ps.extensionAPI.data.breakpointRelation.extractBreakpoint(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.breakpointRelation.extractBreakpoint(...args)
        },
        extractRef: {
            default: (ps, ...args) => ps.extensionAPI.data.breakpointRelation.extractRef(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.breakpointRelation.extractRef(...args)
        },
        extractRefWithoutHash: {
            default: (ps, ...args) => ps.extensionAPI.data.breakpointRelation.extractRefWithoutHash(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.breakpointRelation.extractRefWithoutHash(...args)
        },
        create: {
            default: (ps, ...args) => ps.extensionAPI.data.breakpointRelation.create(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.breakpointRelation.create(...args)
        },
        isBreakpointRelation: {
            default: (ps, ...args) => ps.extensionAPI.data.breakpointRelation.isBreakpointRelation(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.breakpointRelation.isBreakpointRelation(...args)
        }
    }

    const dataVariantRelationExtensionAPI = {
        extractVariants: {
            default: (ps, ...args) => ps.extensionAPI.data.variantRelation.extractVariants(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.variantRelation.extractVariants(...args)
        },
        extractTo: {
            default: (ps, ...args) => ps.extensionAPI.data.variantRelation.extractTo(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.variantRelation.extractTo(...args)
        },
        extractFrom: {
            default: (ps, ...args) => ps.extensionAPI.data.variantRelation.extractFrom(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.variantRelation.extractFrom(...args)
        },
        create: {
            default: (ps, ...args) => ps.extensionAPI.data.variantRelation.create(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.variantRelation.create(...args)
        },
        isVariantRelation: {
            default: (ps, ...args) => ps.extensionAPI.data.variantRelation.isVariantRelation(...args),
            santa: (ps, ...args) => santaDataExtensionAPI.variantRelation.isVariantRelation(...args)
        }
    }

    const tpaExtensionAPI = {
        setSyncer: {
            default: (ps, ...args) => ps.extensionAPI.tpa.setSyncer(...args),
            santa: _.noop
        },
        sync: {
            default: (ps, ...args) => ps.extensionAPI.tpa.sync(...args),
            santa: _.noop
        }
    }

    const data = {
        ...wrapAPI(dataExtensionAPI),
        refArray: {...wrapAPI(dataRefArrayExtensionAPI)},
        breakpointRelation: {...wrapAPI(dataBreakpointRelationExtensionAPI)},
        variantRelation: {...wrapAPI(dataVariantRelationExtensionAPI)}
    }

    const wixCodeSharedFileStateExtensionAPI = {
        subscribeToPathChanges: {
            default: (ps, ...args) => ps.extensionAPI.wixCodeSharedFileState.subscribeToPathChanges(...args),
            santa: _.noop
        },
        notifyLocalPathsChanged: {
            default: (ps, ...args) => ps.extensionAPI.wixCodeSharedFileState.notifyLocalPathsChanged(...args),
            santa: _.noop
        }
    }

    const platformSharedStateAPI = {
        subscribeToManifestWasChanges: {
            default: (ps, ...args) => ps.extensionAPI.platformSharedState.subscribeToManifestWasChanges(...args),
            santa: _.noop
        },
        notifyManifestWasChanged: {
            default: (ps, ...args) => ps.extensionAPI.platformSharedState.notifyManifestWasChanged(...args),
            santa: _.noop
        },
        subscribeToPlatformAPICalls: {
            default: (ps, ...args) => ps.extensionAPI.platformSharedState.subscribeToPlatformAPICalls(...args),
            santa: _.noop
        },
        unsubscribeToPlatformAPICalls: {
            default: (ps, ...args) => ps.extensionAPI.platformSharedState.unsubscribeToPlatformAPICalls(...args),
            santa: _.noop
        },
        notifyPlatformAPIWasCalled: {
            default: (ps, ...args) => ps.extensionAPI.platformSharedState.notifyPlatformAPIWasCalled(...args),
            santa: _.noop
        }
    }

    const wixCodeExtensionAPI = {
        subscribeToWixCodeProvision: {
            default: (ps, ...args) => ps.extensionAPI.wixCode.subscribeToWixCodeProvision(...args),
            santa: _.noop
        },
        updateWixCodeLocalProvisionState: {
            default: (ps, ...args) => ps.extensionAPI.wixCode.updateWixCodeLocalProvisionState(...args),
            santa: _.noop
        },
        updateWixCodeModelLocalState: {
            default: (ps, ...args) => ps.extensionAPI.wixCode.updateWixCodeModelLocalState(...args),
            santa: _.noop
        },
        updateSiteExtensionState: {
            default: (ps, ...args) => ps.extensionAPI.wixCode.updateSiteExtensionState(...args),
            santa: _.noop
        },
        getEditedGridAppId: {
            default: ps => ps.extensionAPI.wixCode.getEditedGridAppId(),
            santa: ps => {
                const isolatedGridAppId = ps.dal.get(ps.pointers.wixCode.getIsolatedGridAppId())
                if (isolatedGridAppId) {
                    return isolatedGridAppId
                }

                if (generalInfo.isFirstSave(ps)) {
                    return ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())
                }

                if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
                    return ps.dal.get(ps.pointers.wixCode.getOpenWixCodeAppId())
                }

                return ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())
            }
        },
        getOpenGridAppId: {
            default: ps => ps.extensionAPI.wixCode.getOpenGridAppId(),
            santa: ps => ps.dal.get(ps.pointers.wixCode.getOpenWixCodeAppId())
        },
        getRevisionGridAppId: {
            default: ps => ps.extensionAPI.wixCode.getRevisionGridAppId(),
            santa: ps => ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())
        },
        syncGridAppToViewer: {
            default: ps => ps.extensionAPI.wixCode.syncGridAppToViewer(),
            santa: _.noop
        }
    }

    const createWixDataSchemasAPI = () => {
        const lastModifiedSchemaVersion = new Map()
        return {
            setLastModifiedVersion: (fileId, version) => lastModifiedSchemaVersion.set(fileId, version),
            getLastModifiedVersion: fileId => lastModifiedSchemaVersion.get(fileId)
        }
    }

    const withSantaTestsFallbackAPI =
        (extensionName, apiCreator, method) =>
        (ps, ...args) => {
            if (!_.has(ps.extensionAPI, extensionName)) {
                _.set(ps, ['extensionAPI', extensionName], apiCreator(ps))
            }
            return method(ps, ...args)
        }
    const wixDataSchemasAPI = {
        getLastModifiedVersion: {
            default: (ps, ...args) => ps.extensionAPI.wixDataSchemas.getLastModifiedVersion(...args),
            santa: withSantaTestsFallbackAPI('wixDataSchemas', createWixDataSchemasAPI, (ps, ...args) =>
                ps.extensionAPI.wixDataSchemas.getLastModifiedVersion(...args)
            )
        },
        setLastModifiedVersion: {
            default: (ps, ...args) => ps.extensionAPI.wixDataSchemas.setLastModifiedVersion(...args),
            santa: withSantaTestsFallbackAPI('wixDataSchemas', createWixDataSchemasAPI, (ps, ...args) =>
                ps.extensionAPI.wixDataSchemas.setLastModifiedVersion(...args)
            )
        }
    }

    const livePreviewSharedStateAPI = {
        subscribeToLivePreviewDataChanges: {
            default: (ps, ...args) => ps.extensionAPI.livePreviewSharedState.subscribeToLivePreviewDataChanges(...args),
            santa: _.noop
        },
        notifyLivePreviewDataChanged: {
            default: (ps, ...args) => ps.extensionAPI.livePreviewSharedState.notifyLivePreviewDataChanged(...args),
            santa: _.noop
        },
        notifyRoutersConcurrentInvalidationStateChanged: {
            default: (ps, ...args) => ps.extensionAPI.livePreviewSharedState.notifyRoutersConcurrentInvalidationStateChanged(...args),
            santa: _.noop
        },
        subscribeToConcurrentRoutersInvalidation: {
            default: (ps, ...args) => ps.extensionAPI.livePreviewSharedState.subscribeToConcurrentRoutersInvalidation(...args),
            santa: _.noop
        }
    }

    const siteDataImmutableFromSnapshot = {
        getPartialSiteDataImmutable: (ps, ...args) => ps.extensionAPI.siteDataImmutableFromSnapshot.getPartialSiteDataImmutable(...args),
        getSiteDataJson: (ps, ...args) => ps.extensionAPI.siteDataImmutableFromSnapshot.getSiteDataJson(...args)
    }

    const snapshots = {
        createWithChanges: (ps, snapshotDal, changes) => ps.extensionAPI.snapshots.createWithChanges(snapshotDal, changes),
        clearUndoRedo: ps => {
            _.invoke(ps, ['extensionAPI', 'undoRedo', 'clear'])
        },
        approveCurrentSnapshot: ps => _.invoke(ps, ['extensionAPI', 'snapshots', 'approveCurrentSnapshot'])
    }

    const models = {
        getDocumentServicesModel: ps => ps.extensionAPI.siteAPI.getDocumentServicesModel(),
        getRendererModel: ps => ps.extensionAPI.siteAPI.getRendererModel()
    }

    const logger = {
        getLogger: {
            default: ps => ps.logger,
            santa: santaUtilExtensionAPI.logger.getLogger
        }
    }

    const rendererModel = {
        askRemoteEditorsToRefreshClientSpecMap: {
            default: ps => ps.extensionAPI.rendererModel.askRemoteEditorsToRefreshClientSpecMap(),
            santa: _.noop
        },
        onAppsInstalledRemotely: {
            default: (ps, cbAsync) => ps.extensionAPI.rendererModel.onAppsInstalledRemotely(cbAsync),
            santa: _.noop
        }
    }

    const saveState = {
        setSaveProgress: {
            default: (ps, isSaving) => ps.extensionAPI.saveState.setSaveProgress(isSaving),
            santa: (ps, isSaving) => ps.dal.set(ps.pointers.general.getIsSaveInProgress(), isSaving)
        },
        isSaveInProgress: {
            default: ps => ps.extensionAPI.saveState.isSaveInProgress(),
            santa: ps => ps.dal.get(ps.pointers.general.getIsSaveInProgress())
        },
        canSave: {
            default: ps => ps.extensionAPI.saveState.canSave(),
            santa: ps => ps.dal.get(ps.pointers.general.getIsSaveAllowedByDSconfig()) && !ps.dal.get(ps.pointers.general.getIsSaveInProgress())
        },
        isSaveAllowed: {
            default: ps => ps.extensionAPI.saveState.isSaveAllowed(),
            santa: ps => ps.dal.get(ps.pointers.general.getIsSaveAllowedByDSconfig())
        },
        setSaveAllowed: {
            default: (ps, isAllowed) => ps.extensionAPI.saveState.setSaveAllowed(isAllowed),
            santa: (ps, isAllowed) => ps.dal.set(ps.pointers.general.getIsSaveAllowedByDSconfig(), isAllowed)
        },
        setPublishProgress: {
            default: (ps, isSaving) => ps.extensionAPI.saveState.setPublishProgress(isSaving),
            santa: () => {}
        },
        isPublishInProgress: {
            default: ps => ps.extensionAPI.saveState.isPublishInProgress(),
            santa: () => {}
        }
    }

    const relationships = {
        getReferencesToPointer: {
            default: (ps, pointer, namespace) => ps.extensionAPI.relationships.getReferencesToPointer(pointer, namespace),
            santa: () => []
        },
        isReferenced: {
            default: (ps, pointer, namespace) => ps.extensionAPI.relationships.isReferenced(pointer, namespace),
            santa: () => false
        },
        getOwningReferencesToPointer: {
            default: (ps, pointer, namespace) => ps.extensionAPI.relationships.getOwningReferencesToPointer(pointer, namespace),
            santa: _.noop
        }
    }

    const multilingualTranslationsAPI = {
        removeByComponentRef: {
            default: (ps, ...args) => ps.extensionAPI.multilingualTranslations.removeByComponentRef(...args),
            santa: _.noop
        },
        remove: {
            default: (ps, ...args) => ps.extensionAPI.multilingualTranslations.remove(...args),
            santa: _.noop
        },
        removeAll: {
            default: ps => ps.extensionAPI.multilingualTranslations.removeAll(),
            santa: _.noop
        },
        hasTranslations: {
            default: (ps, ...args) => ps.extensionAPI.multilingualTranslations.hasTranslations(...args),
            santa: _.noop
        },
        getPresentLanguages: {
            default: (ps, ...args) => ps.extensionAPI.multilingualTranslations.getPresentLanguages(...args),
            santa: _.noop
        }
    }

    const platform = {
        getControllerInPageByDataId: {
            default: (ps, pagePointer, isMobileView, controllerDataId) =>
                ps.pointers.platform.getControllerInPageByDataId(pagePointer, isMobileView, controllerDataId),
            santa: (ps, pagePointer, isMobileView, controllerDataId) =>
                santaPlatformExtensionAPI.getControllerInPageByDataId(ps, pagePointer, isMobileView, controllerDataId)
        }
    }

    const validation = {
        validateOpenTransactionAndReportOnly: {
            default: (ps, tag) => ps.extensionAPI.validation.validateOpenTransactionAndReportOnly(tag),
            santa: _.noop
        }
    }

    const views = {
        get: (ps, namespace, id) => ps.extensionAPI.views.get(namespace, id),
        getAll: (ps, namespace, specificPageIds) => ps.extensionAPI.views.getAll(namespace, specificPageIds)
    }

    const dataFixerVersioning = {
        executeFixerAndSetModifications: {
            default: (ps, fixerExecutor, pageId, fixerName, fixerVersion) =>
                ps.extensionAPI.dataFixerVersioning.executeFixerAndSetModifications(fixerExecutor, pageId, fixerName, fixerVersion),
            santa: _.noop
        },
        hasFixerRunOnCurrentVersion: {
            default: (ps, pageId, category, fixerName, fixerVersion) =>
                ps.extensionAPI.dataFixerVersioning.hasFixerRunOnCurrentVersion(pageId, category, fixerName, fixerVersion),
            santa: () => false
        },
        updatePageVersionData: {
            default: (ps, pageId, versionData) => ps.extensionAPI.dataFixerVersioning.updatePageVersionData(pageId, versionData),
            santa: _.noop
        },
        reportFixerActions: {
            default: (ps, category, fixerChangesOnReruns) => ps.extensionAPI.dataFixerVersioning.reportFixerActions(category, fixerChangesOnReruns),
            santa: _.noop
        }
    }

    const variables = {
        getComponentsUsingVariable: {
            default: (ps, variablePointer, viewMode) => ps.extensionAPI.variables.getComponentsUsingVariable(variablePointer, viewMode),
            santa: _.noop
        }
    }

    const slots = {
        getPluginParent: {
            default: (ps, pointer) => ps.extensionAPI.slots.getPluginParent(pointer),
            santa: () => null
        }
    }

    const componentsMetadata = {
        canConnectToCode: {
            default: (ps, pointer) => ps.extensionAPI.componentsMetadata.canConnectToCode(pointer),
            santa: _.noop
        }
    }

    const extensionsAPI = {
        pages: wrapAPI(pagesExtensionAPI),
        relationships: wrapAPI(relationships),
        platform: wrapAPI(platform),
        validation: wrapAPI(validation),
        data,
        seo,
        slots: wrapAPI(slots),
        documentServicesModel: wrapAPI(documentServicesModelExtensionAPI),
        csave,
        schema: wrapAPI(schema),
        siteDataImmutableFromSnapshot,
        snapshots,
        models,
        wixCodeSharedFileState: wrapAPI(wixCodeSharedFileStateExtensionAPI),
        tpa: wrapAPI(tpaExtensionAPI),
        platformSharedState: wrapAPI(platformSharedStateAPI),
        logger: wrapAPI(logger),
        rendererModel: wrapAPI(rendererModel),
        wixCode: wrapAPI(wixCodeExtensionAPI),
        wixDataSchemas: wrapAPI(wixDataSchemasAPI),
        saveState: wrapAPI(saveState),
        livePreviewSharedState: wrapAPI(livePreviewSharedStateAPI),
        multilingualTranslations: wrapAPI(multilingualTranslationsAPI),
        views,
        dataFixerVersioning: wrapAPI(dataFixerVersioning),
        variables: wrapAPI(variables),
        componentsMetadata: wrapAPI(componentsMetadata)
    }

    const bindPs = (funcObj, ps) => {
        if (_.isFunction(funcObj)) {
            return _.partial(funcObj, ps)
        }

        if (_.isObject(funcObj)) {
            return _.mapValues(funcObj, val => bindPs(val, ps))
        }

        return funcObj
    }

    const getAPI = ps => bindPs(extensionsAPI, ps)

    return {
        ...extensionsAPI,
        getAPI
    }
})
