import _ from 'lodash'
import * as mobx from 'mobx'

function transformAspectData(aspectData) {
    // @ts-ignore
    return mobx.observable.shallowObject({
        globalData: _.isUndefined(aspectData.globalData) ? undefined : aspectData.globalData,
        // @ts-ignore
        dataByCompId: mobx.observable.shallowMap(aspectData.dataByCompId || {})
    })
}

export default function transform(siteData, path, value) {
    if (path.length === 1) {
        const transformedAspectDatas = _.mapValues(value, transformAspectData)
        // @ts-ignore
        return mobx.observable.shallowMap(transformedAspectDatas)
    }

    if (path.length === 2) {
        return transformAspectData(value)
    }

    return value
}
