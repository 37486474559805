define([
    'documentServices/siteMetadata/generalInfo',
    'documentServices/appStudio/services/wixBlocksService',
    'documentServices/appStudio/isAppStudio',
    'documentServices/appStudio/getAppStudioData'
], function (generalInfo, wixBlocksService, isAppStudio, getAppStudioData) {
    'use strict'

    async function onPreSave(ps) {
        const isDraft = generalInfo.isDraft(ps)
        if (!isDraft) {
            return
        }

        const appStudioData = getAppStudioData(ps)

        const {appDefId} = await wixBlocksService.unDraftSite(ps, {
            metaSiteId: ps.dal.get(ps.pointers.general.getMetaSiteId()),
            appName: appStudioData.name
        })
        const devSiteAppDefIdPointer = ps.pointers.appBuilder.getDevSiteAppDefIdPointer()
        ps.dal.set(devSiteAppDefIdPointer, appDefId)
    }

    function createPreSaveTask(ps) {
        const runPreSaveTask = (lastSnapshot, currentSnapshot, resolve, reject) => {
            // eslint-disable-next-line promise/prefer-await-to-then
            onPreSave(ps).then(resolve, reject)
        }

        const noopTask = (lastSnapshot, currentSnapshot, resolve) => {
            resolve()
        }

        const runTask = isAppStudio(ps) ? runPreSaveTask : noopTask

        const task = {
            partialSave: runTask,
            fullSave: runTask,
            firstSave: runTask,
            saveAsTemplate: runTask,
            autosave: runTask,
            publish: (currentData, resolve) => {
                resolve()
            },
            getTaskName: () => 'appStudioTask',
            getSnapshotTags: () => ['primary']
        }

        return task
    }

    /**
     * @exports documentServices/appStudio/appStudioSaveTask
     */
    return {
        createPreSave: createPreSaveTask
    }
})
