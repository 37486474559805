define(['documentServices/componentsMetaData/metaDataUtils'], function (metaDataUtils) {
    'use strict'

    const {isMembersMenu} = metaDataUtils

    return {
        isRepeatable: false,
        styleCanBeApplied: true,
        mobileConversionConfig: {
            isScreenWidth: true,
            desktopOnly(ps, compStructure, pageId) {
                return !isMembersMenu(ps, compStructure, pageId)
            },
            fixedSize: {
                height: 48
            }
        }
    }
})
