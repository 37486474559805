define([
    'lodash',
    'wixappsCore',
    'documentServices/dataModel/dataModel',
    'documentServices/wixapps/utils/pathUtils',
    'documentServices/wixapps/services/metadata'
], function (_, wixappsCore, dataModel, pathUtils, metadata) {
    'use strict'

    return {
        componentsClasses: ['wixapps.integration.components.AppPart2'],
        collect(ps, comp) {
            function getViewDef(descriptor, viewName, type, formatName) {
                const idWithFormat = [type, viewName, formatName].join('|')
                const idWithoutFormat = [type, viewName].join('|')

                return descriptor.views[idWithFormat] || descriptor.views[idWithoutFormat]
            }

            function getPartViews(descriptor, partDefinition, formatName) {
                const compViews = {
                    Array: getViewDef(descriptor, partDefinition.viewName, 'Array', formatName)
                }
                compViews[partDefinition.type] = getViewDef(descriptor, partDefinition.viewName, partDefinition.type, formatName)
                return compViews
            }

            const collectedStyleIds = []
            const data = dataModel.getDataItem(ps, comp)

            if (metadata.isPartErroneous(ps, data.appPartName)) {
                return []
            }

            const descriptor = ps.wixappsDAL.getByPath(pathUtils.getRepoPath())
            const partDefinition = ps.wixappsDAL.getByPath(pathUtils.getPartPath(data.appPartName))
            const views = partDefinition && getPartViews(descriptor, partDefinition, '*')

            if (views) {
                _.forEach(views, viewDef => {
                    wixappsCore.viewsUtils.traverseViews(viewDef, currentViewDef => {
                        if (currentViewDef) {
                            const styleId = _.get(currentViewDef, 'comp.style')
                            if (styleId) {
                                collectedStyleIds.push(styleId)
                            }
                        }
                    })
                })
            }
            return collectedStyleIds
        }
    }
})
