define([
    'lodash',
    'documentServices/wixCode/pointers/wixCodePointers' // load for registrations
], function (_) {
    'use strict'

    function initPaths(ps) {
        const pointersToSet = [
            ps.pointers.wixCode.getRoot(),
            ps.pointers.wixCode.getNonUndoableRoot(),
            ps.pointers.wixCode.getUndoableRoot(),
            ps.pointers.wixCode.getModifiedFileContentMap(),
            ps.pointers.wixCode.getLoadedFileContentMap(),
            ps.pointers.wixCode.getDuplicatedFilesInfoMap(),
            ps.pointers.wixCode.getUndoableModifiedFileContentMap(),
            ps.pointers.wixCode.getFilePathToIdMap(),
            ps.pointers.wixCode.getBundleCacheKillerMap(),
            ps.pointers.wixCode.getWixCodeModel(),
            ps.pointers.wixCode.getWixCodeAppData(),
            ps.pointers.wixCode.getAreChildrenLoadedMap(),
            ps.pointers.wixCode.getLoadedChildrenMap(),
            ps.pointers.wixCode.getDirectoryFlagByDeletedPathMap()
        ]

        _.forEach(pointersToSet, function (pointer) {
            if (!ps.dal.isExist(pointer)) {
                // DAL does not support deep merge
                ps.dal.set(pointer, {})
            }
        })
    }

    return {
        initPaths
    }
})
