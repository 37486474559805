// This configuration is used while DS over Bolt project is running, and not all APIs are functional.
// Should be removed once everything works
import baseConfig from './baseConfig'
import {clientCsaveExtensions} from './csaveConfigs'

const modulesList = [
    'siteSegmentsPublicAPI',
    'pagePublicAPI',
    'pagesGroupPublicAPI',
    'themePublicAPI',
    'fontsPublicAPI',
    'componentPublicAPI',
    'refComponentPublicAPI',
    'componentStylePublicAPI',
    'smartBoxesPublicAPI',
    'dataModelPublicAPI',
    'featuresPublicAPI',
    'slotsPublicAPI',
    'statesPublicAPI',
    'triggersPublicAPI',
    'reactionsPublicAPI',
    'componentsMetaDataPublicAPI',
    // 'componentsLoaderPublicAPI',
    'actionsAndBehaviorsPublicAPI',
    'siteMetadataPublicAPI',
    'feedbackPublicAPI',
    'savePublicAPI',
    'structurePublicAPI',
    'documentModePublicAPI',
    'menuPublicAPI',
    'componentDetectorPublicAPI',
    'wixappsPublicAPI',
    'tpaPublicAPI',
    'biPublicAPI',
    'environmentPublicAPI',
    'renderPluginsPublicApi',
    'mobilePublicAPI',
    'errorsPublicAPI',
    'layoutersPublicAPI',
    'mediaPublicAPI',
    'wixCodePublicAPI',
    'siteMembersPublicAPI',
    'connectionsPublicAPI',
    'appControllerDataPublicAPI',
    'platformPublicAPI',
    'routersPublicAPI',
    'autosavePublicAPI',
    'deprecationPublicAPI',
    'accessibilityPublicAPI',
    'debugPublicAPI',
    'inlinePopupPublicAPI',
    'browserThemeColorPublicAPI',
    'multilingualPublicAPI',
    'accountPublicAPI',
    'partnersPublicAPI',
    'variablesPublicAPI'
]

const extensionsList = [
    ...baseConfig.extensionsList,
    'undoRedo',
    'serviceTopology',
    'metadata',
    'wixCode',
    'save',
    'lifecycle',
    'layoutCircuitBreaker',
    'viewerRuntime',
    'contactFormMetaData',
    'appBuilder',
    'documentToViewer',
    'wixapps',
    'accountInfo',
    'commonConfig',
    'seo',
    ...clientCsaveExtensions,
    'variants',
    'componentsByType',
    'partners',
    'platformWithViewer'
]

export default {
    modulesList,
    extensionsList,
    shouldRender: true,
    isReadOnly: false,
    noUndo: false,
    runStylesGC: false,
    supportMobile: true,
    postUpdateOperation: true
    // componentsToLoad
}
