define([], function () {
    'use strict'

    return {
        beforeAdd(ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
            compDefinitionPrototype.componentType = 'wysiwyg.viewer.components.WPhoto'
            compDefinitionPrototype.props.displayMode = 'fill'
        }
    }
})
