import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const getActivityDetails = function () {
    return {
        additionalInfoUrl: null,
        summary: ''
    }
}

const getActivityLocationUrl = function (activityInfo) {
    return activityInfo.currentUrl.full
}

const getActivityId = function () {
    // @ts-ignore
    return coreUtils.guidUtils.getUniqueId()
}

const Activity = function (activityInfo?, fields?) {
    this._activityInfo = activityInfo
    this._fields = fields
}

Activity.prototype = {
    getParams() {
        return {
            hs: this._activityInfo.hubSecurityToken,
            'activity-id': getActivityId(),
            'metasite-id': this._activityInfo.metaSiteId,
            svSession: this._activityInfo.svSession,
            version: '1.0.0'
        }
    },
    getPayload() {
        return {
            activityDetails: getActivityDetails(),
            activityInfo: 'activityInfo',
            activityLocationUrl: getActivityLocationUrl(this._activityInfo),
            activityType: 'activityType',
            contactUpdate: {},
            createdAt: new Date().toISOString()
        }
    }
}

export default Activity
