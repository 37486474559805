define([
    '@wix/santa-core-utils',
    'lodash',
    'documentServices/component/component',
    'documentServices/appControllerData/appControllerData',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    'experiment'
], function (santaCoreUtils, _, component, appControllerData, refComponentUtils, componentsMetaData, experiment) {
    'use strict'

    const {isRefPointer: isReferredPointer} = santaCoreUtils.displayedOnlyStructureUtil
    const {isRefHost: isRefComponent} = refComponentUtils

    const isReferredRoot = (ps, compPtr) => {
        const parentPtr = component.getContainer(ps, compPtr)
        return !isReferredPointer(parentPtr)
    }

    const isAppWidget = (ps, compPtr) => component.getType(ps, compPtr) === 'platform.components.AppWidget'

    const isRefferedRootAppWidget = (ps, compPtr) => isReferredRoot(ps, compPtr) && isAppWidget(ps, compPtr)

    const disableForAllButRoot = (ps, metaDataValue, compPtr) => (isReferredPointer(compPtr) && !isReferredRoot(ps, compPtr) ? false : metaDataValue)

    const disableForAll = (ps, metaDataValue, compPtr) => (isReferredPointer(compPtr) ? false : metaDataValue)

    const disableForAllButWidgetRoot = (ps, metaDataValue, compPtr) =>
        isReferredPointer(compPtr) && !(isReferredRoot(ps, compPtr) && isAppWidget(ps, compPtr)) ? false : metaDataValue

    const disableForAllButWidgetRootAndItsFirstChild = (ps, metaDataValue, compPtr) => {
        if (!isReferredPointer(compPtr)) {
            return metaDataValue
        }

        if (!experiment.isOpen('dm_allowResizeFirstChildOfRefAppWidget')) {
            return false
        }

        if (isRefferedRootAppWidget(ps, compPtr)) {
            return metaDataValue
        }

        const parent = component.getContainer(ps, compPtr)
        if (isRefferedRootAppWidget(ps, parent)) {
            return metaDataValue
        }

        return false
    }

    const enableForAllButRoot = (ps, metaDataValue, compPtr) => (isReferredPointer(compPtr) && !isReferredRoot(ps, compPtr) ? true : metaDataValue)

    function isRemovable(ps, metaDataValue, compPointer) {
        if (componentsMetaData.shouldRemoveAsGhost(ps, compPointer)) {
            const compForStageData = isRefComponent(ps, compPointer) ? component.getChildren(ps, compPointer)[0] : compPointer
            const componentStageData = appControllerData.getConnectedComponentStageData(ps, compForStageData)
            const isEssential = _.get(componentStageData, ['behavior', 'essential', 'enabled'], false)
            const preventHide = _.get(componentStageData, ['behavior', 'preventHide'], false)
            return metaDataValue && !isEssential && !preventHide
        }
        return metaDataValue
    }

    return {
        disableForAllButWidgetRoot,
        disableForAllButWidgetRootAndItsFirstChild,
        disableForAllButRoot,
        disableForAll,
        enableForAllButRoot,
        isRemovable
    }
})
