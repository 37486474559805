define([
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/dataModel/dataModel',
    'documentServices/wixapps/utils/getBlogNewSocialShareButtonsCustomizationForView',
    'lodash',
    '@wix/santa-core-utils'
], function (componentDetectorAPI, dataModel, getBlogNewSocialShareButtonsCustomizationForView, _, coreUtils) {
    'use strict'

    return useNewBlogSocialShareButtons

    function useNewBlogSocialShareButtons(ps) {
        _(componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart'))
            .filter(function (pointer) {
                const data = dataModel.getDataItem(ps, pointer)
                return data.appPartName === coreUtils.blogAppPartNames.SINGLE_POST
            })
            .forEach(function (pointer) {
                const data = dataModel.getDataItem(ps, pointer)
                const customization = getBlogNewSocialShareButtonsCustomizationForView(data.viewName)
                data.appLogicCustomizations.push(customization)
                dataModel.updateDataItem(ps, pointer, data)
            })
    }
})
