function getTabbableElements(element, shouldIncludeMinusOneTabIndex?) {
    const minusOneTabIndexed = []
    const basicTabbables = []
    const orderedTabbables = []

    // A node is "available" if
    // - it's computed style
    const isUnavailable = createIsUnavailable()

    const candidateSelectors = ['input', 'select', 'a[href]', 'textarea', 'button', '[tabindex]']

    const candidates = element.querySelectorAll(candidateSelectors)

    let candidateElement
    let candidateTabIndex
    for (let i = 0, l = candidates.length; i < l; i++) {
        candidateElement = candidates[i]
        candidateTabIndex = parseInt(candidateElement.getAttribute('tabindex'), 10) || candidateElement.tabIndex

        if (
            (candidateTabIndex < 0 && !shouldIncludeMinusOneTabIndex) ||
            (candidateElement.tagName === 'INPUT' && candidateElement.type === 'hidden') ||
            candidateElement.disabled ||
            isUnavailable(candidateElement)
        ) {
            continue
        }

        if (candidateTabIndex === -1 && shouldIncludeMinusOneTabIndex) {
            minusOneTabIndexed.push(candidateElement)
        }

        if (candidateTabIndex === 0) {
            basicTabbables.push(candidateElement)
        } else {
            orderedTabbables.push({
                tabIndex: candidateTabIndex,
                node: candidateElement
            })
        }
    }

    const tabbableNodes = orderedTabbables
        .sort(function (a, b) {
            return a.tabIndex - b.tabIndex
        })
        .map(function (a) {
            return a.node
        })

    minusOneTabIndexed.concat(basicTabbables)
    Array.prototype.push.apply(tabbableNodes, basicTabbables)

    return tabbableNodes
}

function createIsUnavailable() {
    // Node cache must be refreshed on every check, in case
    // the content of the element has changed
    const isOffCache = []

    // "off" means `display: none;`, as opposed to "hidden",
    // which means `visibility: hidden;`. getComputedStyle
    // accurately reflects visiblity in context but not
    // "off" state, so we need to recursively check parents.

    function isOff(node, nodeComputedStyle?) {
        if (node === window.document.documentElement) {
            return false
        }

        // Find the cached node (Array.prototype.find not available in IE9)
        for (let i = 0, {length} = isOffCache; i < length; i++) {
            if (isOffCache[i][0] === node) {
                return isOffCache[i][1]
            }
        }

        nodeComputedStyle = nodeComputedStyle || window.getComputedStyle(node)

        let result = false

        if (nodeComputedStyle.display === 'none') {
            result = true
        } else if (node.parentNode) {
            result = isOff(node.parentNode)
        }

        isOffCache.push([node, result])

        return result
    }

    return function isUnavailable(node) {
        if (node === window.document.documentElement) {
            return false
        }

        const computedStyle = window.getComputedStyle(node)

        if (isOff(node, computedStyle)) {
            return true
        }

        return computedStyle.visibility === 'hidden'
    }
}

export default {
    getTabbableElements
}
