define([
    'lodash',
    '@wix/santa-core-utils',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/siteMetadata/dataManipulation',
    'documentServices/platform/common/constants',
    'documentServices/utils/contextAdapter',
    '@wix/wix-immutable-proxy',
    'experiment'
], function (_, santaCoreUtils, coreUtils, clientSpecMap, dataManipulation, platformConstants, contextAdapter, wixImmutableProxy, experiment) {
    const {deepClone} = wixImmutableProxy
    const {ajaxLibrary} = coreUtils

    const createUrlTemplate =
        (topologyEntry, path, withSiteId) =>
        ({serviceTopology, siteId}) =>
            `${serviceTopology[topologyEntry]}${path}${withSiteId ? `/${siteId}` : ''}`

    const MAPPINGS = {
        PARTIAL_SAVE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/partially_update')
        },
        OVERRIDE_SAVE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/new_override_save')
        },
        VALIDATE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/validate_site')
        },
        FIRST_SAVE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/first_save')
        },
        SAVE_AS_TEMPLATE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/save_as_template', true)
        },
        PUBLISH: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/publish', true)
        },
        PUBLISH_RC: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/publish-rc', true)
        },
        PUBLISH_TEST_SITE: {
            urlBuilder: ({serviceTopology, metaSiteId}) =>
                `${serviceTopology.editorServerRoot}/${'_api/release-manager-server/v1/revisions/'}${metaSiteId}/create-rc`
        },
        PUBLISH_WITH_OVERRIDES: {
            urlBuilder: ({serviceTopology}) => `${serviceTopology.editorRootUrl}/_api/editor-deployments/v1/deployments`
        },
        PUBLISHED_SITE_DETAILS: {
            urlBuilder: ({serviceTopology}) => `${serviceTopology.editorRootUrl}/_api/wix-public-html-info-webapp/v1/published-site-details`,
            requestType: 'GET'
        },
        PASSWORD_PROTECTION: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/page_protection/new'),
            forceHTTPS: true
        },
        PASSWORD_PROTECTION_DUPLICATE: {
            urlBuilder: createUrlTemplate('editorServerRoot', '/api/page_protection/duplicate'),
            forceHTTPS: true
        },
        GET_FREE_SITE_NAME: {
            urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/meta-site-proxy/get-free-site-name'),
            requestType: 'GET',
            ignoreAuthorizationHeader: true
        },
        IS_SITE_NAME_FREE: {
            urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/meta-site-proxy/is-site-name-free'),
            requestType: 'GET',
            ignoreAuthorizationHeader: true
        },
        GET_APPS: {
            urlBuilder: createUrlTemplate('editorRootUrl', '/_api/app-service/v1/apps'),
            requestType: 'GET',
            ignoreAuthorizationHeader: true
        },
        SITE_PROPERTIES_INFO: {
            urlBuilder: () => '/_api/site-properties-service/properties',
            requestType: 'GET'
        },
        SETTLE: {
            urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/settle'),
            requestType: 'POST'
        },
        LIST_BRANCHES: {
            urlBuilder: createUrlTemplate('editorRootUrl', '/_api/site-branches-registry/v1/site-branches'),
            requestType: 'GET'
        }
    }

    const ENDPOINTS = _.mapValues(MAPPINGS, (v, k) => k)

    /**
     *
     * @param {string} endpoint
     * @param {ServerRequestContextOptions} serverRequestContextOptions
     * @param {object} additionalQueryParams additional query parameters
     * @return {string}
     */
    const getURL = (endpoint, serverRequestContextOptions, additionalQueryParams) => {
        const {serviceTopology, siteId, metaSiteId, editorSessionId} = serverRequestContextOptions
        const {urlBuilder, queryParams = [], forceHTTPS} = MAPPINGS[endpoint]
        let url = urlBuilder({
            serviceTopology,
            siteId,
            metaSiteId
        })
        if (forceHTTPS) {
            url = url.replace('http://', 'https://')
        }

        const serverContextQueryParams = _.without(queryParams, _.keys(additionalQueryParams)).reduce((acc, param) => {
            acc[param] = serverRequestContextOptions[param]
            return acc
        }, {})

        const queryString = santaCoreUtils.urlUtils.toQueryString({
            metaSiteId,
            editorSessionId,
            esi: editorSessionId,
            ...serverContextQueryParams,
            ...additionalQueryParams
        })

        return `${url}?${queryString}`
    }

    const getEditorServerHeaders = ({editorSessionId, origin, signedInstance}) => ({
        Authorization: signedInstance,
        'X-Wix-Editor-Version': 'new',
        'X-Wix-DS-Origin': origin,
        'X-Editor-Session-Id': editorSessionId
    })

    /**
     *
     * @param {ServerRequestContextOptions} serverRequestContextOptions
     * @param {string} endpoint
     * @param {Object} data?
     * @param {function} onSuccess
     * @param {function} onError
     */
    const send = (serverRequestContextOptions, endpoint, data, onSuccess, onError) => {
        const {editorSessionId, origin, signedInstance} = serverRequestContextOptions
        const {requestType = 'POST', ignoreAuthorizationHeader = false} = MAPPINGS[endpoint]

        const additionalQueryParams = requestType === 'GET' ? data : {}
        const url = getURL(endpoint, serverRequestContextOptions, additionalQueryParams)

        contextAdapter.utils.fedopsLogger.breadcrumb('server request', {endpoint, url})

        const headers = getEditorServerHeaders({editorSessionId, origin, signedInstance})
        const jsonData = {dataType: 'json', headers: ignoreAuthorizationHeader ? _.without(headers, ['Authorization']) : headers}
        if (experiment.isOpen('dm_ajaxFetchInstance') && !ignoreAuthorizationHeader && ajaxLibrary.supportsAutoAuth) {
            delete jsonData.headers.Authorization
            jsonData.autoAuth = true
        }
        if (requestType === 'POST') {
            jsonData.xhrFields = {
                withCredentials: true
            }
        }

        const bodyData = requestType === 'POST' ? data : null
        contextAdapter.actions.sendHttpRequest(url, requestType, jsonData, bodyData, onSuccess, onError)
    }

    /**
     * @typedef {{serviceTopology: *, signedInstance: *, siteVersion: *, origin: *, siteId: *, metaSiteId: *, editorSessionId: *}} ServerRequestContextOptions
     */

    /**
     *
     * @param {ps} ps
     * @return {ServerRequestContextOptions}
     */
    const getEditorServerOptionsFromPS = ps => ({
        serviceTopology: ps.dal.get(ps.pointers.general.getServiceTopology()),
        metaSiteId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.META_SITE_ID),
        siteId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_ID),
        siteVersion: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_VERSION),
        editorSessionId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.EDITOR_SESSION_ID),
        origin: ps.config.origin,
        signedInstance: clientSpecMap.getAppData(ps, platformConstants.APPS.META_SITE.applicationId).instance
    })

    /**
     *
     * @param snapshot
     * @return {ServerRequestContextOptions}
     */
    const getEditorServerOptionsFromSnapshot = snapshot => ({
        serviceTopology: snapshot.get('serviceTopology').toJS(),
        metaSiteId: snapshot.getIn(['rendererModel', 'metaSiteId']),
        siteId: snapshot.getIn(['rendererModel', 'siteInfo', 'siteId']),
        siteVersion: snapshot.getIn(['documentServicesModel', 'version']),
        editorSessionId: snapshot.getIn(['documentServicesModel', 'editorSessionId']),
        origin: snapshot.get('origin'),
        signedInstance: snapshot.getIn(['rendererModel', 'clientSpecMap', '-666', 'instance'])
    })

    /**
     *
     * @param snapshotDal
     * @param {string} editorOrigin
     * @return {ServerRequestContextOptions}
     */
    const getEditorServerOptionsFromSnapshotDal = (snapshotDal, editorOrigin) => ({
        serviceTopology: deepClone(snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'})),
        metaSiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
        siteId: snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'}),
        siteVersion: snapshotDal.getValue({type: 'documentServicesModel', id: 'version'}),
        editorSessionId: snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
        origin: editorOrigin,
        signedInstance: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap', innerPath: ['-666', 'instance']})
    })

    const sendWithPs = (ps, endpoint, data, onSuccess, onError) => send(getEditorServerOptionsFromPS(ps), endpoint, data, onSuccess, onError)

    const sendWithPsAsync = (ps, endpoint, data) => new Promise((res, rej) => sendWithPs(ps, endpoint, data, res, rej))

    const sendWithImmutable = (snapshot, endpoint, data, onSuccess, onError) => {
        const serverOptions = getEditorServerOptionsFromSnapshot(snapshot)
        return send(serverOptions, endpoint, data, onSuccess, onError)
    }

    const sendWithImmutableAsync = (snapshot, endpoint, data) => new Promise((res, rej) => sendWithImmutable(snapshot, endpoint, data, res, rej))

    const sendWithSnapshotDal = (snapshotDal, endpoint, data, onSuccess, onError, editorOrigin) => {
        const serverOptions = getEditorServerOptionsFromSnapshotDal(snapshotDal, editorOrigin)
        return send(serverOptions, endpoint, data, onSuccess, onError)
    }

    const sendWithSnapshotDalAsync = (snapshotDal, endpoint, data, editorOrigin) =>
        new Promise((res, rej) => sendWithSnapshotDal(snapshotDal, endpoint, data, res, rej, editorOrigin))

    return {
        ENDPOINTS,
        sendWithPs,
        sendWithPsAsync,
        sendWithImmutable,
        sendWithImmutableAsync,
        sendWithSnapshotDal,
        sendWithSnapshotDalAsync
    }
})
