define(['@wix/santa-core-utils', 'documentServices/page/pageData'], (santaCoreUtils, pageData) => {
    'use strict'

    function getAppStudioData(ps) {
        const masterPageData = pageData.getPageData(ps, santaCoreUtils.siteConstants.MASTER_PAGE_ID, true) || {}

        if (!masterPageData.appStudioData) {
            return undefined
        }

        if (!masterPageData.appStudioData.dependencies) {
            return {
                ...masterPageData.appStudioData,
                dependencies: []
            }
        }

        return masterPageData.appStudioData
    }

    return getAppStudioData
})
