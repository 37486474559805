import fullFunctionality from './fullFunctionalityConfig'
import responsive from './responsiveConfig'
import responsiveBlocks from './responsiveBlocksConfig'
import mobileConfig from './mobileConfig'
import test from './testConfig'
import testResponsive from './testResponsiveConfig'
import testResponsiveBlocks from './testResponsiveBlocksConfig'
import appStudio from './appStudioConfig'
import serverApi from './serverAPIConfig'
import testFullFunctionality from './test-fullFunctionalityConfig'
import testFullFunctionalityWithDisableAutoSave from './test-fullFunctionalityWithDisableAutoSaveConfig'
import testFullFunctionalityWithDisableCSaveConfig from './testFullFunctionalityWithDisableCSaveConfig'
import testResponsiveWithDisableCSaveConfig from './testResponsiveWithDisableCSaveConfig'
import wix2x from './wix2xConfig'
import dsLegacyServerConfig from './dsLegacyServerConfig'
import * as getFeedbackConfig from './getFeedbackConfig'

const configs = {
    fullFunctionality,
    responsive,
    responsiveBlocks,
    mobileConfig,
    test,
    testFullFunctionality,
    testFullFunctionalityWithDisableAutoSave,
    testFullFunctionalityWithDisableCSaveConfig,
    testResponsiveWithDisableCSaveConfig,
    testResponsive,
    testResponsiveBlocks,
    appStudio,
    serverApi,
    wix2x,
    dsLegacyServerConfig,
    getFeedback: getFeedbackConfig.classic,
    getFeedbackResponsive: getFeedbackConfig.responsive
}

export type ConfigName = keyof typeof configs

export default configs
