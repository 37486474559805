import _ from 'lodash'

const pluginsMap = {}

function registerTransformPlugin(storeName, transformFn) {
    if (_.has(pluginsMap, storeName)) {
        throw new Error(`path ${storeName} already registered in display data transform plugins`)
    }

    pluginsMap[storeName] = transformFn
}

function transformData(jsonData, path, data) {
    const storeName: string = _.head(path)
    const transformFunc = pluginsMap[storeName]

    if (transformFunc) {
        return transformFunc(jsonData, path, data)
    }

    return data
}

export default {
    transformData,
    registerTransformPlugin
}
