import _ from 'lodash'
import type {DAL, DalValue, DocumentManager, Pointer} from '@wix/document-manager-core'
import {cleanRef} from './utils/migrationUtil'
import {refArrayUtils, extensions} from '@wix/document-manager-extensions'

const {breakpointRelation, variantRelation} = refArrayUtils

type ShouldRemoveScopedItem = (id: string) => boolean

interface MigrationConfig {
    namespace: string
    shouldRemoveScopedItem: ShouldRemoveScopedItem
}

const queryPointers = (dal: DAL, ns: string, pageId: string, filter: (value: DalValue) => boolean): Pointer[] => {
    const dalValues = dal.query(ns, dal.queryFilterGetters.getPageCompFilter(pageId), filter)
    return _.map(dalValues, (v, k) => ({
        id: k,
        type: ns,
        pageId: v.metaData.pageId
    }))
}

const getRefArraysFromNamespace = (dal: DAL, ns: string, pageId: string): Pointer[] => queryPointers(dal, ns, pageId, refArrayUtils.refArray.isRefArray)

const getRelationsFromNamespace = (dal: DAL, ns: string, pageId: string): Pointer[] =>
    queryPointers(dal, ns, pageId, _.overSome<DalValue>([breakpointRelation.isBreakpointRelation, variantRelation.isVariantRelation]))

const removeValueFromRefArray = (documentManager: DocumentManager, refArrayPointer: Pointer, idToRemove: string) => {
    const refArray = documentManager.dal.get(refArrayPointer)
    const values = refArrayUtils.refArray.extractValuesWithoutHash(refArray)
    const newValues = _.difference(values, [idToRemove])
    const updatedRefArray = refArrayUtils.refArray.update(refArray, newValues)
    documentManager.dal.set(refArrayPointer, updatedRefArray)
}

const hasCorruptedRelation = (documentManager: DocumentManager, relation: any, pageId: string) => {
    if (breakpointRelation.isBreakpointRelation(relation)) {
        const breakpointId = _.get(relation, ['breakpoint'])
        const bpPointer = documentManager.pointers.data.getBreakpointsDataItem(cleanRef(breakpointId), pageId)
        return !documentManager.dal.has(bpPointer)
    } else if (variantRelation.isVariantRelation(relation)) {
        const variants = (refArrayUtils.variantRelation.extractVariants(relation) || []) as string[]
        return _.some(variants, variantId => {
            const varPointer = documentManager.pointers.data.getVariantsDataItem(cleanRef(variantId), pageId)
            return !documentManager.dal.has(varPointer)
        })
    }
}

const cleanBrokenVariantRelations = (
    documentManager: DocumentManager,
    namespace: string,
    pageId: string,
    valuesToRefArrayMap: any,
    shouldRemoveScopedItem: ShouldRemoveScopedItem
) => {
    const relationPointers = getRelationsFromNamespace(documentManager.dal, namespace, pageId)

    _.forEach(relationPointers, relationPointer => {
        const relation = documentManager.dal.get(relationPointer)

        const scopedId = breakpointRelation.isBreakpointRelation(relation)
            ? breakpointRelation.extractRefWithoutHash(relation)
            : variantRelation.extractTo(relation)
        const refArrayId = valuesToRefArrayMap[relation.id]
        const refArrayPointer = documentManager.pointers.getPointer(refArrayId, namespace)
        const scopedStylePointer = documentManager.pointers.getPointer(scopedId, namespace)

        if (hasCorruptedRelation(documentManager, relation, pageId) || !documentManager.dal.has(scopedStylePointer)) {
            if (shouldRemoveScopedItem(cleanRef(scopedId))) {
                documentManager.dal.remove(scopedStylePointer)
            }
            documentManager.dal.remove(relationPointer)
            removeValueFromRefArray(documentManager, refArrayPointer, relationPointer.id)
        }
    })
}

const getValuesToRefArrayMap = (documentManager: DocumentManager, namespace: string, pageId: string) => {
    const refArrayPointers = getRefArraysFromNamespace(documentManager.dal, namespace, pageId)
    return _.reduce(
        refArrayPointers,
        (acc, pointer) => {
            const refArray = documentManager.dal.get(pointer)
            const values = refArrayUtils.refArray.extractValuesWithoutHash(refArray)
            _.forEach(values, dataId => {
                acc[dataId] = refArray.id
            })
            return acc
        },
        {}
    )
}

const migrateNamespace = (documentManager: DocumentManager, namespace: string, shouldRemoveScopedItem: ShouldRemoveScopedItem, pageId: string): void => {
    const valuesToRefArrayMap = getValuesToRefArrayMap(documentManager, namespace, pageId)
    cleanBrokenVariantRelations(documentManager, namespace, pageId, valuesToRefArrayMap, shouldRemoveScopedItem)
}

const shouldRemoveScopedStyle = (documentManager: DocumentManager, styleId: string): boolean => {
    const {schemaAPI} = documentManager.extensionAPI as extensions.schema.SchemaExtensionAPI
    return !schemaAPI.isSystemStyle(styleId)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const configs: MigrationConfig[] = [
        {namespace: 'style', shouldRemoveScopedItem: _.partial(shouldRemoveScopedStyle, documentManager)},
        {namespace: 'transformations', shouldRemoveScopedItem: _.stubTrue}
    ]
    configs.forEach(({namespace, shouldRemoveScopedItem}) => {
        migrateNamespace(documentManager, namespace, shouldRemoveScopedItem, pageId)
    })
}

const name = 'removeBrokenVariantRelations'
const version = 1

export {migratePage, name, version}
