define(['lodash', 'documentServices/constants/constants'], function (_, constants) {
    'use strict'

    const {COMP_DATA_QUERY_KEYS} = constants

    function sanitizeSerializedComponent(componentDefinition) {
        _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
            delete componentDefinition[compDataQueryKey]
        })

        _.forEach(componentDefinition.components, sanitizeSerializedComponent)
    }

    return sanitizeSerializedComponent
})
