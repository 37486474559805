define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/tpa/constants',
    'documentServices/componentsMetaData/components/baseTpaMetaData',
    'documentServices/utils/utils'
], function (_, dataModel, metaDataUtils, extensionsAPI, constants, baseTpaMetaData, dsUtils) {
    'use strict'

    function getWidgetData(ps, compData) {
        if (!_.has(compData, 'applicationId')) {
            return {}
        }
        const clientSpecMapPtr = ps.pointers.general.getClientSpecMap()
        const csm = extensionsAPI.data.getNoClone(ps, clientSpecMapPtr)
        const appData = _.get(csm, compData.applicationId)
        const result = _.get(appData, ['widgets', compData.widgetId])
        return _.cloneDeep(result)
    }

    function getCompData(component, ps, pageId) {
        const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
        const dataPointer = ps.pointers.data.getDataItem(dataQuery, pageId)
        return ps.dal.get(dataPointer)
    }

    return {
        removable: false,
        duplicatable: false,
        isRepeatable: false,
        layoutLimits: metaDataUtils.getControllerLayoutLimits,
        mobileConversionConfig: {
            convertFixedPositionToAbsolute: true,
            stretchHorizontally(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = getWidgetData(ps, compData)
                return _.get(widgetData, 'shouldBeStretchedByDefaultMobile') === true
            },
            minHeight(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = getWidgetData(ps, compData)
                return _.get(widgetData, 'componentFields.minHeightInMobile')
            }
        },
        crossSiteDuplicatable: false,
        containable(ps, compPointer, containerPointer) {
            const isContainable = _.get(ps.runtimeConfig, ['tpa', 'section', 'defaults', 'isContainable'], false)
            if (!isContainable) {
                return false
            }

            const targetPagePointer = ps.pointers.components.getPageOfComponent(containerPointer)
            if (targetPagePointer.id === 'masterPage') {
                return false
            }

            const targetPageChildrenComps = ps.pointers.components.getChildren(targetPagePointer)
            const hasTPASection = _.some(targetPageChildrenComps, function (childComp) {
                if (_.isEqual(childComp, compPointer)) {
                    return false
                }

                const componentType = metaDataUtils.getComponentType(ps, childComp)
                return componentType === constants.COMP_TYPES.TPA_SECTION || componentType === constants.COMP_TYPES.TPA_MULTI_SECTION
            })

            const pageData = dataModel.getDataItem(ps, targetPagePointer)
            const isCompOnAppPage = pageData.tpaApplicationId && dataModel.getDataItem(ps, compPointer).applicationId === pageData.tpaApplicationId.toString()

            return !hasTPASection && isCompOnAppPage
        },
        canBeStretched(ps, compPointer) {
            const compData = dataModel.getDataItem(ps, compPointer)
            const widgetData = getWidgetData(ps, compData)
            return _.get(widgetData, 'canBeStretched') === true
        },
        getWidgetData,
        nickname(ps, compPointer) {
            const nicknameFromStageData = metaDataUtils.getNicknameFromStageData(ps, compPointer)
            if (nicknameFromStageData) {
                return nicknameFromStageData
            }

            const compData = dataModel.getDataItem(ps, compPointer)
            const widgetData = getWidgetData(ps, compData)
            if (widgetData) {
                const appPageId = _.get(widgetData, 'appPage.id')
                return _.camelCase(appPageId)
            }
            return metaDataUtils.getComponentNickname(ps, compPointer)
        },
        rotatable(ps, compPointer) {
            return baseTpaMetaData.rotatable(ps, compPointer)
        },
        canBeFixedPosition(ps, compPointer) {
            return baseTpaMetaData.canBeFixedPosition(ps, compPointer)
        },
        resizable(ps, compRef) {
            return baseTpaMetaData.resizable(ps, compRef)
        },
        canReparent(ps, compRef) {
            return baseTpaMetaData.canReparent(ps, compRef)
        }
    }
})
