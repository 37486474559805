define(['lodash', 'documentServices/appStudio/appStudioAppDataUtils'], function (_, appStudioAppDataUtils) {
    'use strict'

    const PLATFORM_COMPONENT = 'PLATFORM'
    const APP_STUDIO_COMPONENT = 'STUDIO'

    const isBobApp = async (ps, appDefinitionId) => {
        const appData = await appStudioAppDataUtils.fetchAppData(ps, appDefinitionId)
        return _.some(appData.components, {compType: PLATFORM_COMPONENT}) && _.some(appData.components, {compType: APP_STUDIO_COMPONENT})
    }

    return {
        isBobApp
    }
})
