define(['@wix/santa-core-utils', 'lodash'], function (santaCoreUtils, _) {
    'use strict'

    function getScale(ps, comp) {
        const layoutPointer = ps.pointers.getInnerPointer(comp, 'layout.scale')
        return ps.dal.get(layoutPointer) || 1
    }

    return {
        rotatable: true,
        styleCanBeApplied: true,
        layoutLimits(ps, compPointer, newLayout) {
            const measureMap = ps.siteAPI.getSiteMeasureMap()
            const limits = {
                minWidth: _.get(measureMap, ['minWidth', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH),
                minHeight: _.get(measureMap, ['minHeight', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT)
            }

            if (newLayout && newLayout.scale) {
                const currentScale = getScale(ps, compPointer)
                if (currentScale !== newLayout.scale) {
                    const ratio = newLayout.scale / currentScale
                    return _.mapValues(limits, limit => limit * ratio)
                }
            }
            return limits
        },
        disableable: true
    }
})
