import layout from '../util/layout'
import elementQueries from '../util/elementQueries'

const controlsElementQueries = {
    width: [
        {min: 0, max: 350, name: 'minimal'},
        {min: 0, max: 450, name: 'small'},
        {min: 450, max: 1200, name: 'normal'},
        {min: 1200, max: Infinity, name: 'large'}
    ]
}

function patchMediaControls(id, patchers, measureMap) {
    patchers.attr(id, elementQueries(id, measureMap, controlsElementQueries))
}

layout.registerPatcher('wysiwyg.viewer.components.MediaControls', patchMediaControls)
