define(['lodash', '@wix/santa-core-utils', 'documentServices/componentsMetaData/components/baseInputMetadata'], function (
    _,
    santaCoreUtils,
    baseInputMetadata
) {
    'use strict'

    const datePickerMetadata = {
        layoutLimits(ps, compPointer) {
            const measureMap = ps.siteAPI.getSiteMeasureMap() || {}
            const {id} = compPointer

            return {
                minWidth: _.get(measureMap, ['minWidth', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH),
                minHeight: _.get(measureMap, ['minHeight', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT)
            }
        }
    }

    return _.merge(datePickerMetadata, baseInputMetadata)
})
