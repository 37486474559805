import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {dataResolver} = coreUtils

function createDataItemsForNewItems(siteAPI, repeaterPointer, newItems) {
    if (_.isEmpty(newItems)) {
        return
    }

    const siteData = siteAPI.getSiteData()
    const pointers = siteAPI.getPointers()
    const runtimeDal = siteAPI.getRuntimeDal()
    const pageId = pointers.components.getPageOfComponent(repeaterPointer).id

    const templateComps = pointers.full.components.getChildrenRecursively(repeaterPointer)
    _.forEach(templateComps, function (compInTemplatePointer) {
        const compDataQuery = _.replace(siteAPI.getSiteDataAPI().document.getFullStructureProperty(compInTemplatePointer, 'dataQuery'), '#', '')
        const compDesignQuery = _.replace(siteAPI.getSiteDataAPI().document.getFullStructureProperty(compInTemplatePointer, 'designQuery'), '#', '')

        if (compDataQuery) {
            const templateData = _.defaults({}, runtimeDal.getCompDataOverrides(compInTemplatePointer.id), siteData.getDataByQuery(compDataQuery, pageId))
            _.forEach(newItems, function (itemId) {
                const displayedItemId = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compInTemplatePointer.id, itemId)
                const newData = dataResolver.cloneDataItemWithNewRefIdsRecursively(
                    templateData,
                    siteData.dataTypes.DATA,
                    '_runtime_',
                    siteData.getClonedDataItemsIdsMap(displayedItemId)
                )
                newData.id = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compDataQuery, itemId)
                const flatMap = dataResolver.getFlatMapOfNestedItem(newData, siteData.dataTypes.DATA)
                _.forOwn(flatMap, function (dataToSet) {
                    siteAPI.getDisplayedDAL().set(pointers.data.getDataItem(dataToSet.id, pageId), dataToSet)
                })
            })
        }

        if (compDesignQuery) {
            const templateDesign = _.defaults(
                {},
                runtimeDal.getCompDesignOverrides(compInTemplatePointer.id),
                siteData.getDataByQuery(compDesignQuery, pageId, siteData.dataTypes.DESIGN)
            )
            _.forEach(newItems, function (itemId) {
                const displayedItemId = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compInTemplatePointer.id, itemId)
                const newDesign = dataResolver.cloneDataItemWithNewRefIdsRecursively(
                    templateDesign,
                    siteData.dataTypes.DESIGN,
                    '_runtime_',
                    siteData.getClonedDataItemsIdsMap(displayedItemId)
                )
                newDesign.id = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compDesignQuery, itemId)
                const flatMap = dataResolver.getFlatMapOfNestedItem(newDesign, siteData.dataTypes.DESIGN)
                _.forOwn(flatMap, function (dataToSet) {
                    siteAPI.getDisplayedDAL().set(pointers.data.getDesignItem(dataToSet.id, pageId), dataToSet)
                })
            })
        }

        const templateBehaviorOverrides = runtimeDal.getCompBehaviorsOverrides(compInTemplatePointer.id)
        if (!_.isEmpty(templateBehaviorOverrides)) {
            _.forEach(newItems, function (itemId) {
                runtimeDal.addActionsAndBehaviors(
                    coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compInTemplatePointer.id, itemId),
                    templateBehaviorOverrides
                )
            })
        }

        const propsOverrides = runtimeDal.getCompPropsOverrides(compInTemplatePointer.id)
        if (!_.isEmpty(propsOverrides)) {
            _.forEach(newItems, function (itemId) {
                runtimeDal.setCompProps(coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compInTemplatePointer.id, itemId), propsOverrides)
            })
        }
    })
}

function deleteRuntimeCreatedDataAndOverridesForDeletedItems(siteAPI, repeaterPointer, deletedItems) {
    if (_.isEmpty(deletedItems)) {
        return
    }

    const siteData = siteAPI.getSiteData()
    const pointers = siteAPI.getPointers()
    const pageId = pointers.components.getPageOfComponent(repeaterPointer).id

    const templateComps = pointers.full.components.getChildrenRecursively(repeaterPointer)
    _.forEach(templateComps, function (compInTemplatePointer) {
        const compDataQuery = _.replace(siteAPI.getSiteDataAPI().document.getFullStructureProperty(compInTemplatePointer, 'dataQuery'), '#', '')
        const compDesignQuery = _.replace(siteAPI.getSiteDataAPI().document.getFullStructureProperty(compInTemplatePointer, 'designQuery'), '#', '')
        const runtimeDal = siteAPI.getRuntimeDal()

        _.forEach(deletedItems, function (itemId) {
            const itemCompId = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compInTemplatePointer.id, itemId)
            runtimeDal.clearCompRuntimeOverrides(itemCompId)

            if (compDataQuery) {
                const itemDataQuery = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compDataQuery, itemId)
                const itemData = siteData.getDataByQuery(itemDataQuery, pageId)
                const flatMapData = dataResolver.getFlatMapOfNestedItem(itemData, siteData.dataTypes.DATA, true)
                _.forOwn(flatMapData, function (data, dataId) {
                    siteAPI.getDisplayedDAL().remove(pointers.data.getDataItem(dataId, pageId))
                })
            }

            if (compDesignQuery) {
                const itemDesignQuery = coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(compDesignQuery, itemId)
                const itemDesign = siteData.getDataByQuery(itemDesignQuery, pageId, siteData.dataTypes.DESIGN)
                const flatMapDesign = dataResolver.getFlatMapOfNestedItem(itemDesign, siteData.dataTypes.DESIGN)
                _.forOwn(flatMapDesign, function (data, dataId) {
                    siteAPI.getDisplayedDAL().remove(pointers.data.getDesignItem(dataId, pageId))
                })
            }
        })
    })
}

function handleRepeaterDataChange(repeaterCompId, changeObject, dataBeforeChange) {
    const {siteAPI} = this
    const newData = changeObject.value

    const repeaterPointer = siteAPI.getRuntimeDal().getCompPointer(repeaterCompId)

    const newItems = _.difference(newData.items, dataBeforeChange.items)
    createDataItemsForNewItems(siteAPI, repeaterPointer, newItems)

    const deletedItems = _.difference(dataBeforeChange.items, newData.items)
    deleteRuntimeCreatedDataAndOverridesForDeletedItems(siteAPI, repeaterPointer, deletedItems)

    siteAPI.getSiteDataAPI().createDisplayedNode(repeaterPointer)
}

export default {
    handleRepeaterDataChange
}
