import {DocumentDataTypes, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils
const pointerType = 'appBuilder'

const createPointersMethods = (): PointerMethods => {
    const getDevSiteAppDefIdPointer = () => getPointer('appBuilder', 'devSiteAppDefId')
    const getLastBuildIdPointer = () => getPointer('appBuilder', 'lastBuildId')
    return {
        appBuilder: {
            getDevSiteAppDefIdPointer,
            getLastBuildIdPointer
        },
        general: {
            getDevSiteAppDefIdPointer
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

const createExtension = (): Extension => ({
    name: 'appBuilder',
    createPointersMethods,
    getDocumentDataTypes
})

export {createExtension}
