import $ from 'zepto'
import layout from '../util/layout'

layout.registerCustomLayoutFunction('wysiwyg.viewer.components.inputs.Checkbox', function (id, nodesMap, measureMap) {
    const node = nodesMap[id]
    const hasLabel = $(node).data('hasLabel')
    if (!hasLabel) {
        return
    }

    measureMap.height[id] = node.offsetHeight
    measureMap.width[id] = node.offsetWidth
    return patchers => patchers.css(id, {height: ''})
})
