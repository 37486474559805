/*
    This file is purely due to backward compatibility reasons with Santa.
    Don't use it. It will be removed as soon as we are done with Santa.
 */
define(['@wix/santa-ds-libs/src/coreUtils', '@wix/santa-ds-libs/src/utils', 'experiment'], function (coreUtils, utils, experiment) {
    'use strict'

    /**
     * type {FedopsLogger2}
     */
    const fedopsLogger = {
        interactionStarted: coreUtils.loggingUtils.fedopsLogger.interactionStarted,
        interactionEnded: coreUtils.loggingUtils.fedopsLogger.interactionEnded,
        breadcrumb: utils.integrations.ravenUtils.breadcrumb,
        captureError: utils.integrations.ravenUtils.captureError
    }

    /**
     * @type {ContextAdapter}
     */
    return {
        actions: {
            // eslint-disable-next-line no-unused-vars
            sendHttpRequest: (url, type, jsonData, body, success, error) => {
                throw new Error('contextAdapter.actions.sendHttpRequest was called before an implementation was registered')
            }
        },
        utils: {
            fedopsLogger
        },
        viewerName: 'santa',
        experiment
    }
})
