define(['lodash', 'documentServices/stylableEditor/stylableUtils', 'documentServices/component/componentStylesAndSkinsAPI'], function (
    _,
    utils,
    componentStylesAndSkinsAPI
) {
    'use strict'

    const getStylableEditor = ps => ps.siteAPI.getStylableEditorInstance()

    const mergeStylesheets = (ps, destination, source) => ps.siteAPI.mergeStylesheets(destination, source)

    const updateComponentStyle = (ps, compPointer, styleData) => {
        componentStylesAndSkinsAPI.style.update(ps, compPointer, styleData)
        ps.siteAPI.revertQuickChanges(compPointer.id)
    }

    const createEmptyStyle = (ps, compPointerWithVariant, originalStyle) => {
        const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointerWithVariant)
        if (!styleItem) {
            const emptyStylableStyle = _.clone(originalStyle, true)
            emptyStylableStyle.style.properties['$st-css'] = utils.createEmptyStylableStylesheet(emptyStylableStyle.componentClassName)
            return updateComponentStyle(ps, compPointerWithVariant, emptyStylableStyle)
        }
    }

    const removeRefIfEmptyStyle = (ps, compPointerWithVariant) => {
        const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointerWithVariant)
        if (styleItem && _.get(styleItem, ['style', 'properties', '$st-css']) === utils.createEmptyStylableStylesheet(styleItem.componentClassName)) {
            componentStylesAndSkinsAPI.style.removeScoped(ps, compPointerWithVariant) // will remove the scoped style
        }
    }

    const setQuickChange = (ps, compPointer, selector, cssMap) => {
        ps.siteAPI.setQuickChanges(compPointer.id, selector, cssMap)
    }

    const revertQuickChange = (ps, compPointer) => {
        ps.siteAPI.revertQuickChanges(compPointer.id)
    }

    const forceState = (ps, compPointer, forceStateParams) => {
        ps.siteAPI.forceState(compPointer.id, forceStateParams)
    }

    const revertForceState = (ps, compPointer) => {
        ps.siteAPI.revertForceState(compPointer.id)
    }

    return {
        getStylableEditorInstance: getStylableEditor,
        update: updateComponentStyle,
        createEmptyStyle,
        removeRefIfEmptyStyle,
        setQuickChange,
        revertQuickChange,
        forceState,
        revertForceState,
        mergeStylesheets
    }
})
