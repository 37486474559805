import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import checkoutManager from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/data/checkoutManager'

/**
 * @class ecom.logics.CheckoutButtonLogic
 * @param partApi
 * @constructor
 */
function CheckoutButtonLogic(partApi) {
    this.partApi = partApi
}

CheckoutButtonLogic.prototype = {
    'click-checkout'(/*evt*/) {
        checkoutManager.handleCheckout(this.partApi)
    }
}

wixapps.logicFactory.register('cd54a28f-e3c9-4522-91c4-15e6dd5bc514', CheckoutButtonLogic)
