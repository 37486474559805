define([
    'lodash',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/constants/constants',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/structure/utils/layoutSettingsUtils'
], function (_, documentModeInfo, constants, mobileUtil, layoutSettingsUtils) {
    'use strict'

    function getViewMode(ps, viewMode) {
        return mobileUtil.getViewMode(ps, viewMode, documentModeInfo)
    }

    const DEFAULT_GAP_DATA = {
        pagesToFooterGap: 0,
        headerToPagesGap: 0
    }
    const SUPPORTED_GAPS = _.keys(DEFAULT_GAP_DATA)

    function getGapValues(layoutSettings) {
        return _(layoutSettings).pick(SUPPORTED_GAPS).defaults(DEFAULT_GAP_DATA).value()
    }

    function sanitizeGapsData(layoutSettings, newGapData) {
        const existingGaps = getGapValues(layoutSettings)

        return _(newGapData)
            .pick(SUPPORTED_GAPS)
            .mapValues((value, gapName) => Math.min(existingGaps[gapName], Math.max(value, 0)))
            .defaults(existingGaps)
            .pickBy(_.identity)
            .value()
    }

    return {
        getGaps(ps) {
            const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)

            return layoutSettings ? _.pick(layoutSettings, SUPPORTED_GAPS) : {}
        },
        updateGaps(ps, gapData) {
            const layoutSettingsPointer = ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('masterPage'), 'layoutSettings')
            const layoutSettings = ps.dal.get(layoutSettingsPointer)

            if (!layoutSettings) {
                return
            }

            const gapsToUpdate = sanitizeGapsData(layoutSettings, gapData)

            const newLayoutSettings = _(layoutSettings).omit(SUPPORTED_GAPS).assign(gapsToUpdate).value()
            ps.dal.set(layoutSettingsPointer, newLayoutSettings)
            ps.siteAPI.createPageAnchors('masterPage')
        },
        isUsingSectionsLayout(ps) {
            const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
            return _.get(layoutSettings, 'useDesktopSectionsLayout', false)
        },
        getFooter(ps, viewMode) {
            return ps.pointers.components.getFooter(getViewMode(ps, viewMode))
        },
        getHeader(ps, viewMode) {
            return ps.pointers.components.getHeader(getViewMode(ps, viewMode))
        },
        getPagesContainer(ps, viewMode) {
            return ps.pointers.components.getPagesContainer(getViewMode(ps, viewMode))
        },
        getSiteStructure(ps, viewMode) {
            return ps.pointers.components.getMasterPage(getViewMode(ps, viewMode))
        },
        getSiteStructureId() {
            return constants.MASTER_PAGE_ID
        }
    }
})
