import _ from 'lodash'
import type {Pointer} from '../types'

const getPointer = (id: string, type: string, innerPath?: string[]): Pointer => {
    if (!id && !type) {
        console.log('illegal pointer creation !')
    }
    return innerPath ? {id, type, innerPath} : {id, type}
}

const getInnerPointer = (pointer: Pointer, innerPath: any) => {
    const newPointer = getPointer(pointer.id, pointer.type)
    if (_.isString(innerPath)) {
        innerPath = innerPath.split('.')
    }
    newPointer.innerPath = pointer.innerPath ? pointer.innerPath.concat(innerPath) : innerPath
    return newPointer
}

export {getPointer, getInnerPointer}
