define([
    'lodash',
    '@wix/santa-core-utils',
    'wixappsCore',
    'wixappsClassics',
    'documentServices/dataModel/dataModel',
    'documentServices/constants/constants',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/tpa/services/provisionService',
    'documentServices/wixapps/utils/blogPageJsonGetter',
    'documentServices/wixapps/utils/blogPaginationCustomizationsGetter',
    'documentServices/wixapps/utils/classicsPathUtils',
    'documentServices/wixapps/utils/getBlogNewSocialShareButtonsCustomizationForView',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/page/page',
    'documentServices/page/pageData',
    'documentServices/hooks/hooks',
    'documentServices/wixapps/utils/classicsUtils',
    'documentServices/wixapps/services/blogUtils',
    'documentServices/theme/theme',
    'documentServices/utils/runtimeConfig',
    'documentServices/utils/utils',
    'experiment'
], function (
    _,
    coreUtils,
    wixappsCore,
    wixappsClassics,
    dataModel,
    constants,
    clientSpecMap,
    siteMetadata,
    provisionService,
    blogPageJsonGetter,
    blogPaginationCustomizationsGetter,
    classicsPathUtils,
    getBlogNewSocialShareButtonsCustomizationForView,
    componentDetectorAPI,
    component,
    page,
    pageDataModule,
    hooks,
    classicsUtils,
    blogUtils,
    theme,
    runtimeConfig,
    dsUtils,
    experiment
) {
    'use strict'
    const {blogAppPartNames} = coreUtils

    /**
     * @param {ps} privateServices
     * @param {string} componentId
     */
    function invalidateComponentViewCache(privateServices, componentId) {
        privateServices.setOperationsQueue.waitForChangesApplied(function () {
            wixappsClassics.viewCacheUtils.removeComponentViewDefs(componentId)
        })
    }

    /**
     * Gets a list of all appPart components
     * @param {ps} ps Private Services
     * @param packageName
     */
    function getAllAppPartComps(ps, packageName) {
        const appId = packageName && getApplicationId(ps, packageName)
        if (_.isNull(appId)) {
            return []
        }
        const appPartPointers = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart')
        return appPartPointers
            .map(appPartPointer => dataModel.getDataItem(ps, appPartPointer))
            .filter(appPartData => appPartData.appInnerID === appId || !appId)
    }

    /**
     * @param {ps} privateServices
     * @param {string} packageName
     */
    function reloadApp(privateServices, packageName) {
        if (!runtimeConfig.isSanta(privateServices)) {
            privateServices.siteAPI.reloadWixappsPackage(packageName)
        } else if (_.size(classics.getAllAppPartComps(privateServices, packageName)) > 0) {
            const packageDescriptorPath = classicsPathUtils.getPackageDescriptorPath(packageName)
            const packageDescriptor = privateServices.wixappsDAL.getByPath(packageDescriptorPath)

            const packagePath = classicsPathUtils.getPackagePath(packageName)

            privateServices.wixappsDAL.setByPath(packagePath, {})
            privateServices.wixappsDAL.setByPath(packageDescriptorPath, packageDescriptor)
        }
    }

    function getApplicationId(ps, packageName) {
        const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
        const clientSpecMapData = ps.dal.get(clientSpecMapPointer)
        const blogAppId = _.findLast(clientSpecMapData, {packageName})

        return blogAppId ? `${blogAppId.applicationId}` : null
    }

    function getDataStoreId(ps, packageName) {
        return clientSpecMap.getAppData(ps, getApplicationId(ps, packageName)).datastoreId
    }

    function getBlogClientSpecMap(ps) {
        return clientSpecMap.getAppData(ps, getApplicationId(ps, 'blog'))
    }

    function getBlogInstanceId(ps) {
        return getBlogClientSpecMap(ps).datastoreId
    }

    function provision(ps, packageName, appDefinitionId, onComplete) {
        const appId = classics.getApplicationId(ps, packageName)
        if (!appId) {
            if (isSiteSaved(ps)) {
                provisionService.provisionAppAfterSave(ps, appDefinitionId, onComplete)
            } else {
                provisionService.provisionAppBeforeSave(ps, appDefinitionId, onComplete)
            }
        } else {
            onComplete({applicationId: appId})
        }
        activateApp(ps, 'true')
    }

    function provisionFromTemplate(ps, packageName, appDefinitionId, sourceTemplateId, onComplete, onError) {
        const appId = classics.getApplicationId(ps, packageName)
        if (!appId) {
            if (isSiteSaved(ps)) {
                provisionService.provisionAppFromSourceTemplate(ps, appDefinitionId, sourceTemplateId, onComplete, onError)
            } else {
                onError({success: false})
            }
        } else {
            onComplete({applicationId: appId})
        }
        activateApp(ps, 'true')
    }

    function isSiteSaved(ps) {
        return !siteMetadata.generalInfo.isFirstSave(ps)
    }

    /**
     * @param {ps} ps
     */
    function deleteAllRemainingAppParts(ps) {
        ps.setOperationsQueue.runSetOperation(function () {
            const pages = getNonAppPagePages(ps)

            _.forEach(pages, function (pageData) {
                const pagePointer = page.getPage(ps, pageData.id)
                const partsToDelete = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart', pagePointer)
                _(partsToDelete)
                    .union(componentDetectorAPI.getComponentByType(ps, 'wysiwyg.common.components.rssbutton.viewer.RSSButton', pagePointer))
                    .forEach(function (partPointer) {
                        component.remove(ps, partPointer)
                    })
            })

            const masterPagePointer = page.getPage(ps, 'masterPage')
            const partsToDeleteFromMasterPage = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart', masterPagePointer)
            _(partsToDeleteFromMasterPage)
                .union(componentDetectorAPI.getComponentByType(ps, 'wysiwyg.common.components.rssbutton.viewer.RSSButton', masterPagePointer))
                .forEach(function (partPointer) {
                    component.remove(ps, partPointer)
                })

            activateApp(ps, 'false')
        })
    }

    function getNonAppPagePages(ps) {
        const pages = page.getPagesDataItems(ps)
        return _(pages).compact().reject({appPageType: 'AppPage'}).value()
    }

    function canDeleteComp(ps, compPointer) {
        const appPart = dataModel.getDataItem(ps, compPointer)
        const undeletableAppParts = [blogAppPartNames.FEED, blogAppPartNames.SINGLE_POST]

        if (experiment.isOpen('sv_blogHeroImage')) {
            undeletableAppParts.push(blogAppPartNames.HERO_IMAGE)
        }

        if (experiment.isOpen('sv_blogStudioExperiment')) {
            //give ability for studio to delete component from page
            _.pull(undeletableAppParts, blogAppPartNames.HERO_IMAGE)
        }

        if (_.includes(undeletableAppParts, appPart.appPartName)) {
            return false
        }
    }

    function registerHooks() {
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, deleteAllRemainingAppParts, 'wixapps.integration.components.AppPage')
        hooks.registerHook(hooks.HOOKS.REMOVE.IS_OPERATION_ALLOWED, canDeleteComp, 'wixapps.integration.components.AppPart')
        hooks.registerHook(hooks.HOOKS.DUPLICATE.IS_OPERATION_ALLOWED, canDeleteComp, 'wixapps.integration.components.AppPart')
    }

    const createStyleItemToFork = (ps, styleValue) => {
        const defaultFields = {
            styleType: constants.STYLES.TYPES.CUSTOM,
            type: constants.STYLES.TOP_LEVEL_STYLE
        }
        const styleDefToAdd = _(styleValue).omit(['id']).assign(defaultFields).value()
        return theme.styles.createStyleItemToAdd(ps, styleDefToAdd)
    }

    function activateApp(ps, activeState) {
        let pointer = ps.pointers.page.getPageData('masterPage')
        const innerPath = ['appVars_61f33d50-3002-4882-ae86-d319c1a249ab', 'vars', 'applicationActiveState']
        while (!_.isEmpty(innerPath)) {
            pointer = ps.pointers.getInnerPointer(pointer, innerPath.splice(0, 1)[0])
            if (!ps.dal.isExist(pointer)) {
                ps.dal.set(pointer, {})
            }
        }

        ps.dal.set(pointer, {type: 'AppPartParam', value: activeState})
    }

    function getComponentTypeByProxyName(ps, proxyName) {
        return wixappsClassics.componentTypeUtil.getComponentTypeByProxyName(proxyName)
    }

    function styleToFontClass(ps, style) {
        return wixappsCore.styleMapping.styleToFontClass(style)
    }

    function fontClassToStyle(ps, fontClass) {
        return wixappsCore.styleMapping.fontClassToStyle(fontClass)
    }

    function getLegacyAppPartContent(ps, compId) {
        const compData = ps.dal.get(compId)
        const dataItemId = dsUtils.stripHashIfExists(compData.dataQuery)
        const dataItem = dataModel.getDataItemById(ps, dataItemId)
        const appName = classicsUtils.getPackageName(ps, dataItem.appInnerID)
        const dataItemPath = classicsPathUtils.getAppPartDataPath(appName, dataItemId)
        const dataItems = ps.wixappsDAL.getByPath(classicsPathUtils.getAppPartItemsPath(appName))
        const itemTypes = ps.wixappsDAL.getByPath(dataItemPath)

        return {
            itemTypes,
            items: dataItems
        }
    }

    function getBlogPaginationCustomizationsByAppPartName(ps, appPartName) {
        return blogPaginationCustomizationsGetter.getBlogPaginationCustomizationsByAppPartName(appPartName)
    }

    function addBlog(ps, sourceTemplateId, feedComponents, singlePostComponents, callback) {
        const provisionCallback = function (appDefinition) {
            const blogPageRef = page.getPageIdToAdd(ps)

            const blogFeedJSON = blogPageJsonGetter.getBlogFeedPageJson(ps, feedComponents, appDefinition.applicationId)
            blogFeedJSON.data.pageUriSEO = pageDataModule.getValidPageUriSEO(ps, '', blogFeedJSON.data.pageUriSEO)

            const blogSinglePostJSON = blogPageJsonGetter.getSinglePostPageJson(ps, singlePostComponents, appDefinition.applicationId)
            blogSinglePostJSON.data.pageUriSEO = pageDataModule.getValidPageUriSEO(ps, '', blogSinglePostJSON.data.pageUriSEO)

            ps.setOperationsQueue.runSetOperation(page.add, [ps, blogPageRef, 'Blog', blogFeedJSON], {isUpdatingAnchors: true})
            ps.setOperationsQueue.runSetOperation(page.add, [ps, page.getPageIdToAdd(ps), 'Single Post', blogSinglePostJSON], {isUpdatingAnchors: true})
            if (_.isFunction(callback)) {
                callback(blogPageRef.id)
            }
        }

        if (!sourceTemplateId) {
            provision(ps, 'blog', '61f33d50-3002-4882-ae86-d319c1a249ab', provisionCallback)
        } else {
            provisionFromTemplate(ps, 'blog', '61f33d50-3002-4882-ae86-d319c1a249ab', sourceTemplateId, function () {
                provisionService.provisionAppAfterSave(ps, '61f33d50-3002-4882-ae86-d319c1a249ab', provisionCallback)
            })
        }
    }

    function getBlogSinglePostPageData(ps) {
        const pageIdLists = page.getPageIdList(ps)
        let blogSinglePostPageData
        _.forEach(pageIdLists, function (item) {
            if (classics.blog.isSinglePost(ps, item)) {
                blogSinglePostPageData = pageDataModule.getPageData(ps, item)
            }
        })
        return blogSinglePostPageData
    }

    function convertAppPageStructure(ps, pageRef) {
        const pageData = pageDataModule.getPageData(ps, pageRef.id)
        const isAppPage = pageData.appPageType === 'AppPage'
        if (isAppPage) {
            const pagetPointerCompType = _.assign({innerPath: ['componentType']}, pageRef)
            ps.dal.set(pagetPointerCompType, 'mobile.core.components.Page')
        }
    }

    const classics = {
        blog: {
            addBlog,
            deleteAllAppParts: deleteAllRemainingAppParts,
            getBlogSinglePostPageData,
            isFeed: blogUtils.isFeed,
            isSinglePost: blogUtils.isSinglePost
        },
        canDeleteComp,
        getAllAppPartComps,
        getApplicationId,
        getLegacyAppPartContent,
        getBlogInstanceId,
        getBlogNewSocialShareButtonsCustomizationForView(ps, name) {
            return getBlogNewSocialShareButtonsCustomizationForView(name)
        },
        getBlogPaginationCustomizationsByAppPartName,
        getComponentTypeByProxyName,
        getDataStoreId,
        getPackageName: classicsUtils.getPackageName,
        invalidateComponentViewCache,
        isBlogPage: blogUtils.isBlogPage,
        provisionFromTemplate,
        registerHooks,
        reloadApp,
        styleMapping: {
            fontClassToStyle,
            styleToFontClass
        },
        style: {
            createItemToFork: createStyleItemToFork
        },
        convertAppPage: convertAppPageStructure
    }
    return classics
})
