import * as _ from 'lodash'
import {conversionConfig} from './conversionConfig'
import {objectUtils} from '@wix/santa-core-utils'
import {isMobileOnly} from './mobileOnlyComponents/mobileOnlyComponentsUtils'
import {
    Component,
    ComponentWithConversionData,
    MasterPageComponent,
    MasterPageComponentWithConversionData,
    StructureWithConversionData,
    IdToComponentMap,
    ConversionSettings,
    PageComponent,
    Layout,
    ObjMap
} from '../types'

export const isMergeVirtualGroup = (comp: Component): boolean => _.get(comp, 'componentType') === conversionConfig.VIRTUAL_GROUP_TYPES.MERGE
export const isRescaleVirtualGroup = (comp: Component): boolean => _.get(comp, 'componentType') === conversionConfig.VIRTUAL_GROUP_TYPES.RESCALE
export const isVirtualGroup = (comp: Component): boolean => isRescaleVirtualGroup(comp) || isMergeVirtualGroup(comp)

export function isDesktopOnlyComponent(component: ComponentWithConversionData): boolean {
    return _.get(component, ['conversionData', 'desktopOnly'], false)
}

export function isTextComponent(component: ComponentWithConversionData): boolean {
    return _.get(component, ['conversionData', 'category']) === 'text'
}

export function isVerticalText(component: ComponentWithConversionData) {
    return _.get(component, ['conversionData', 'isVerticalText'], false)
}

export function isGraphicComponent(component: ComponentWithConversionData): boolean {
    return _.get(component, ['conversionData', 'category']) === 'graphic' || isImageComponent(component)
}

export function isGroupComponent(component: Component): boolean {
    return component && component.componentType === 'wysiwyg.viewer.components.Group'
}

export function isAppWidgetComponent(component: Component): boolean {
    return component && component.componentType === 'platform.components.AppWidget'
}

export function isColumnsContainerComponent(component: ComponentWithConversionData): boolean {
    return _.get(component, ['conversionData', 'category']) === 'columns'
}

export const isColumnComponent = (component: ComponentWithConversionData) => {
    return _.get(component, ['conversionData', 'category']) === 'column'
}

export const isClassicSectionComponentByType = componentType => {
    return componentType === 'wysiwyg.viewer.components.ClassicSection'
}

export const isClassicSectionComponent = (component: ComponentWithConversionData) =>
    isClassicSectionComponentByType(_.get(component, ['componentType'])) || _.get(component, ['conversionData', 'category']) === 'section'

export function isImageComponent(component: ComponentWithConversionData): boolean {
    return _.get(component, ['conversionData', 'category']) === 'photo'
}

export function isMasterPage(component: Component | MasterPageComponent): component is MasterPageComponent {
    return _.get(component, 'id') === 'masterPage'
}

export function isMasterPageChild(component: Component): boolean {
    return _.includes(['SITE_FOOTER', 'SITE_HEADER', 'SITE_PAGES', 'PAGES_CONTAINER'], component.id)
}

export function isDockingAllowed(comp: Component | MasterPageComponent): boolean {
    return _.get(comp, ['conversionData', 'isDockingAllowed'])
}

export function isFixedPositionAllowed(comp: ComponentWithConversionData | MasterPageComponent): boolean {
    return _.get(comp, ['conversionData', 'isFixedPositionAllowed'])
}

export function isFixedPositionElement(comp: Component | MasterPageComponent): boolean {
    return _.get(comp, ['layout', 'fixedPosition'], false)
}

export function shouldConvertFixedPositionToAbsolute(comp: ComponentWithConversionData | MasterPageComponent) {
    return _.get(comp, ['conversionData', 'convertFixedPositionToAbsolute'], false)
}

export function isExistsBeforeMerge(mobileComp: ComponentWithConversionData): boolean {
    return mobileComp.conversionData ? mobileComp.conversionData.existsBeforeMerge : false
}

export function shouldStretchToScreenWidth(component: ComponentWithConversionData): boolean {
    const shouldStretchHorizontally = comp =>
        _.get(comp.conversionData, 'isScreenWidth', false) ||
        _.get(comp.conversionData, 'stretchHorizontally', false) ||
        _.some(comp.components, shouldStretchHorizontally)

    return isSiteSegmentOrPage(component) || shouldStretchHorizontally(component)
}

export function extractComponentsFromStructureByType(root: Component | MasterPageComponent, compTypes: string[]) {
    const children = getChildren(root)
    const extractedChildrenByType = _.remove(children, child => {
        const widgetRootChild = isAppWidgetComponent(child) && getRootChild(child)
        return _.includes(compTypes, child.componentType) || (widgetRootChild && _.includes(compTypes, widgetRootChild.componentType))
    })
    return _.concat(
        extractedChildrenByType,
        _.flatMap(children, child => extractComponentsFromStructureByType(child, compTypes))
    )
}

export function isScreenWidthComponent(component: ComponentWithConversionData): boolean {
    return _.get(component.conversionData, 'isScreenWidth', false) || _.some(component.components, isScreenWidthComponent)
}

export function translateComps(comps: Component[], x = 0, y = 0): void {
    _.forEach(comps, comp => {
        comp.layout.x += x
        comp.layout.y += y
    })
}

export function reparentComponent(parent: Component | MasterPageComponent, newChild: Component, index?: number): void {
    addComponentsTo(parent, [newChild], index)
    translateComps([newChild], -parent.layout.x, -parent.layout.y)
}

export function addComponentsTo(container: Component | MasterPageComponent, components: Component[], index?: number): void {
    const children = getChildren(container)

    if (!components.length || !children) {
        return
    }
    index = index !== undefined ? index : children.length
    // eslint-disable-next-line prefer-spread
    children.splice.apply(children, (<any[]>[index, 0]).concat(components))
}

export function removeChildrenFrom(container: Component | MasterPageComponent, componentsToRemove: Component[]): void {
    const children = getChildren(container)
    if (children && children.length > 0) {
        _.remove(children, child => _.includes(componentsToRemove, child))
    }
}

export function removeChildrenFromById(container: Component | MasterPageComponent, componentsToRemoveIds: string[]): void {
    const children = getChildren(container)
    if (children && children.length > 0) {
        _.remove(children, child => _.includes(componentsToRemoveIds, child.id))
    }
}

export function removeGroup(group: Component, groupParent: Component) {
    if (!isGroupComponent(group)) {
        return
    }

    const groupIndex = _.findIndex(groupParent.components, {id: group.id})

    _.forEach(group.components.reverse(), curGroupedComponent => {
        addComponentsTo(groupParent, [curGroupedComponent], groupIndex)
        translateComps([curGroupedComponent], group.layout.x, group.layout.y)
    })
    _.remove(groupParent.components, group)
}

export function containsComponent(container: ComponentWithConversionData, componentMatcher: (comp: ComponentWithConversionData) => boolean): boolean {
    return _.some(
        getChildren(container),
        (child: ComponentWithConversionData) => componentMatcher(child) || (child && containsComponent(child, componentMatcher))
    )
}

export function getHeightAccordingToChildren(
    container: ComponentWithConversionData,
    children?: ComponentWithConversionData[],
    enforceShrinkEvenWithNoChildren?: boolean
): number | undefined {
    if (isModifiedComponent(container)) {
        return
    }
    if (isMasterPage(container)) {
        return
    }

    if (!children || (!enforceShrinkEvenWithNoChildren && _.isEmpty(children))) {
        return
    }

    const lowestChildBottom = <number>_(children)
        .reject(['conversionData.isInvisible', true])
        .reduce((lowest, child) => _.max([lowest, child.layout.y + child.layout.height, 0]), 0)

    if (_.get(container, ['conversionData', 'hasTightYMargin']) || _.get(container, ['conversionData', 'hasTightBottomMargin'])) {
        return lowestChildBottom
    }

    const bottomMargin = shouldStretchToScreenWidth(container)
        ? conversionConfig.ROOT_COMPONENT_MARGIN_Y
        : conversionConfig.COMPONENT_MOBILE_MARGIN_Y + _.get(container.conversionData, 'borderWidth', 0)

    return lowestChildBottom + bottomMargin
}

export function ensureContainerTightlyWrapsChildren(
    container: ComponentWithConversionData | MasterPageComponentWithConversionData,
    children?: Component[],
    enforceShrinkEvenWithNoChildren?: boolean,
    defaultMinHeight = 0
): void {
    const heightByChildren = getHeightAccordingToChildren(
        <ComponentWithConversionData>container,
        <ComponentWithConversionData[]>children,
        enforceShrinkEvenWithNoChildren
    )
    if (_.isNumber(heightByChildren)) {
        const minHeight = _.get(container, ['conversionData', 'minHeight'], defaultMinHeight)
        container.layout.height = Math.max(minHeight, heightByChildren)
    }
}

export function isSiteSegmentOrPage(component: ComponentWithConversionData): boolean {
    return isPageComponent(component) || isSiteSegment(component)
}

export function isPageComponent(component: StructureWithConversionData): boolean {
    return component.type === 'Page'
}

export function isMobileOnlyElement(mobileComponent: StructureWithConversionData): boolean {
    return _.get(mobileComponent, ['conversionData', 'mobileHints', 'mobileOnlyElement'])
}

export function isModifiedComponent(component: StructureWithConversionData) {
    return _.get(component, ['conversionData', 'mobileHints', 'modifiedByUser'], false)
}

export const isNewlyComponent = (component: ComponentWithConversionData) => {
    return _.get(component, [
        'conversionData',
        'existsOnMobileBeforeMerge',
        //@ts-expect-error
        false
    ])
}

export const isDirectParentOfMOC = (component: StructureWithConversionData) => _.get(component, ['conversionData', 'isDirectParentOfMobileOnly'], false)

export const componentListToMap = (compList): IdToComponentMap =>
    compList.reduce((map, comp) => map.set(comp.id, comp) && map, new Map<string, ComponentWithConversionData>())

export const isReparentedComponent = (comp: StructureWithConversionData) => {
    return _.get(comp, ['conversionData', 'mobileHints', 'reparented'])
}

export const shouldKeepOnMobile = (component: StructureWithConversionData, settings: ConversionSettings) => {
    /**
     * skipLayoutOverwrite use only for section migration and mobile layout
     * should be saved only for existing components and
     * adjust layout for components were created
     * in migration(classicSections)
     */
    if (settings.skipLayoutOverwrite) {
        return isExistsBeforeMerge(component as ComponentWithConversionData)
    }
    return isModifiedComponent(component) || isMobileOnly(component) || isDirectParentOfMOC(component)
}

export function isContainerComponent(component: StructureWithConversionData): boolean {
    return component.type === 'Container'
}

export function isSiteSegment(component: ComponentWithConversionData): boolean {
    return _.has(component.conversionData, 'siteSegmentRole')
}

export function isAllowedToBeInMasterPage(component: ComponentWithConversionData): boolean {
    return isSiteSegment(component) || _.has(component.conversionData, 'isAllowedToBeChildOfMasterPage')
}

export function extractMobilePage(desktopPage: PageComponent | MasterPageComponent): PageComponent | MasterPageComponent {
    const mobilePage = objectUtils.cloneDeep(desktopPage)
    const childrenPropertyName = _.has(mobilePage, 'components') ? 'components' : 'children'
    return <PageComponent | MasterPageComponent>_.set(mobilePage, childrenPropertyName, desktopPage.mobileComponents || [])
}

export function getComponentByIdFromStructure(componentId: string, component: Component | MasterPageComponent): Component | MasterPageComponent | null {
    if (component.id === componentId) {
        return <Component>component
    }
    let res = null
    _.find(getChildren(component), comp => {
        res = getComponentByIdFromStructure(componentId, comp)
        return res
    })
    return res
}

export function unifyGroups(groups: string[][], groupOverflowThreshold?: number): void {
    if (groups.length > groupOverflowThreshold) {
        groups.length = 0
        return
    }
    const haveCommonElements = (arr1, arr2) => _.size(_.intersection(arr1, arr2)) > 0
    for (let i = groups.length - 1; i >= 0; i--) {
        const j = _.findLastIndex(groups, (group, index) => index < i && haveCommonElements(groups[i], group))
        if (j !== -1) {
            groups[j] = groups[j].concat(_.difference(groups[i], groups[j]))
            groups.splice(i, 1)
        }
    }
}

export function getComponentsByIds(container: Component | MasterPageComponent, compIds: string[]): Component[] {
    const children = getChildren(container)
    return _.map(compIds, id => _.find(children, {id}) || null)
}

export function getParent(componentId: string, root: Component | MasterPageComponent): Component | MasterPageComponent | null {
    const children = getChildren(root)
    if (_.find(children, {id: componentId})) {
        return root
    }
    let parent = null
    _.find(children, child => {
        parent = getParent(componentId, child)
        return parent
    })
    return parent
}

export const getPresetFromConversionData = comp => {
    return _.get(comp, ['conversionData', 'preset'], undefined)
}

export const getParentIdFromMobileHints = (component: ComponentWithConversionData) => component.conversionData?.mobileHints?.parent

export const setParentIdToMobileHints = (component, parentId) => _.set(component, ['conversionData', 'mobileHints', 'parent'], parentId)

export function getSnugLayout(components: Component[]): Layout {
    if (!components || components.length === 0) {
        return undefined
    }
    const mostLeft = <number>_.min(_.map(components, 'layout.x'))
    const mostTop = <number>_.min(_.map(components, 'layout.y'))
    const mostRight = _.max(_.map(components, c => c.layout.x + c.layout.width))
    const mostBottom = _.max(_.map(components, c => c.layout.y + c.layout.height))
    return {
        x: mostLeft,
        y: mostTop,
        width: mostRight - mostLeft,
        height: mostBottom - mostTop,
        rotationInDegrees: 0
    }
}
/**
 * TODO: INVESTIGATE IS TINY MENU TOTALY DEPRECATED AND REMOVE EVERITHYNG RELATED TO TINY MENU
 * https://jira.wixpress.com/browse/WEED-25100
 */
export function getTinyMenuDefaultPosition(): {
    x: number
    y: number
    rotationInDegrees: number
} {
    return {
        x: conversionConfig.MOBILE_WIDTH - (conversionConfig.TINY_MENU_SIZE + conversionConfig.SITE_SEGMENT_PADDING_X),
        y: conversionConfig.ROOT_COMPONENT_MARGIN_Y,
        rotationInDegrees: 0
    }
}
// TODO: fix types
export const getChildren = (comp: Component | MasterPageComponent): any[] | undefined => {
    return (<Component>comp).components || (<MasterPageComponent>comp).children
}

export const getRootChild = (comp: Component | MasterPageComponent): Component => _.get(comp, ['components', 0]) || _.get(comp, ['children', 0])

export function getRangesOverlap(range1: number[] = [], range2: number[] = []) {
    const getSortedRangesOverlap = (r1, r2) => Math.min(r1[1], r2[1]) - r2[0]
    return range1[0] <= range2[0] ? getSortedRangesOverlap(range1, range2) : getSortedRangesOverlap(range2, range1)
}

export function getYOverlap(comp1: Component, comp2: Component): number {
    const getYProjection = comp => [comp.layout.y, comp.layout.height + comp.layout.y]
    return getRangesOverlap(getYProjection(comp1), getYProjection(comp2))
}

export function getXOverlap(comp1: ComponentWithConversionData, comp2: ComponentWithConversionData): number {
    if (isScreenWidthComponent(comp1)) {
        return comp2.layout.width
    }
    if (isScreenWidthComponent(comp2)) {
        return comp1.layout.width
    }
    const getXProjection = comp => [comp.layout.x, comp.layout.width + comp.layout.x]
    return getRangesOverlap(getXProjection(comp1), getXProjection(comp2))
}

const getArea = (component: Component) => component.layout.width * component.layout.height

export function hasGreaterArea(comp1, comp2) {
    if (isScreenWidthComponent(comp1)) {
        return comp1.layout.height >= comp2.layout.height || getArea(comp1) > getArea(comp2)
    }
    if (isScreenWidthComponent(comp2)) {
        return false
    }
    return getArea(comp1) > getArea(comp2)
}

export function haveSufficientOverlap(comp1: ComponentWithConversionData, comp2: ComponentWithConversionData, overlapToMinAreaRationThreshold?): boolean {
    const xOverlap = getXOverlap(comp1, comp2)
    const yOverlap = getYOverlap(comp1, comp2)
    if (xOverlap <= 0 || yOverlap <= 0) {
        return false
    }
    const overlapArea = xOverlap * yOverlap
    const minCompArea = Math.min(getArea(comp1), getArea(comp2))
    return overlapArea > 0 && overlapArea / minCompArea >= overlapToMinAreaRationThreshold
}

export function isEmptyContainer(component: ComponentWithConversionData): boolean {
    if (!isContainerComponent(component)) {
        return false
    }
    if (component.conversionData.category === 'columns') {
        return _.size(component.components) === 1 && _.isEmpty(component.components[0].components)
    }
    return _.isEmpty(component.components)
}

export function shouldReparentCompToChildOfContainer(comp, container): boolean {
    return isColumnsContainerComponent(container) && _.get(comp, ['conversionData', 'isInvisible'], false)
}

export const getFlattenListOfComponents = (compStructure: Component | MasterPageComponent, isMobile = false): ComponentWithConversionData[] => {
    const queue = [[compStructure]]
    for (const innerQueue of queue) {
        _.forEach(innerQueue, child => {
            const children = isMobile ? _.get(child, 'mobileComponents') : getChildren(child)
            if (!_.isEmpty(children)) {
                queue.push(<Component[]>children)
            }
        })
    }
    //@ts-expect-error
    return _.flatten(queue)
}

export function getAllCompsInStructure(
    compStructure: Component | MasterPageComponent,
    isMobile = false,
    filterFunc?: (comp: Component | MasterPageComponent) => boolean
): ObjMap<ComponentWithConversionData> {
    const listOfComponents = getFlattenListOfComponents(compStructure, isMobile)
    const filteredListOfComponents: ObjMap<ComponentWithConversionData> = _(listOfComponents).remove(filterFunc).keyBy('id').value()

    return filteredListOfComponents
}

export const isSemiTransparentContainer = comp => _.get(comp, ['conversionData', 'isSemiTransparentContainer'], false)
