define(['lodash', '@wix/santa-core-utils', '@wix/wix-json-schema-utils', 'documentServices/appStudio/appStudioDataModel'], function (
    _,
    santaCoreUtils,
    jsonSchemaUtils,
    appStudioDataModel
) {
    'use strict'

    const interpretDefinition = definitionData => {
        const definitionName = getSchemaStructureKey(definitionData)
        return {
            name: definitionName,
            data: _.get(definitionData, [definitionName]),
            default: _.get(definitionData, [definitionName, 'default']),
            properties: _.get(definitionData, [definitionName, 'properties'])
        }
    }

    const getSchemaStructureKey = schema => _.head(_.keys(schema))

    const isPropertyArray = property => _.get(property, [getSchemaStructureKey(property), 'allOf', 0, 'type']) === 'array'

    const getPropertyRef = property =>
        isPropertyArray(property)
            ? _.get(property, [getSchemaStructureKey(property), 'allOf', 0, 'items', 'allOf', 0, '$ref'])
            : _.get(property, [getSchemaStructureKey(property), 'allOf', 0, '$ref'])

    const getPropertyRestrictions = property =>
        _.pick(
            isPropertyArray(property)
                ? _.get(property, [getSchemaStructureKey(property), 'allOf', 0, 'items', 'allOf', 1])
                : _.get(property, [getSchemaStructureKey(property), 'allOf', 1]),
            ['minimum', 'maximum', 'multipleOf', 'minLength', 'maxLength']
        )

    const mergeExistingDefinitionWithDefinitionData = (existingDefinition, newDefinitionData) => {
        const existingDefinitionName = getSchemaStructureKey(existingDefinition.structure)
        const newDefinitionName = getSchemaStructureKey(newDefinitionData)
        const mergedDefinitionData = _.assign({}, existingDefinition.structure[existingDefinitionName], newDefinitionData[newDefinitionName])
        const definitionMetaData = _.omit(existingDefinition, 'structure')
        return _.assign({}, definitionMetaData, {structure: {[newDefinitionName]: mergedDefinitionData}})
    }

    const createNewDefinitionWithData = (ps, dataModel, newDefinitionData) => {
        const newDefinitionName = getSchemaStructureKey(newDefinitionData)
        newDefinitionData[newDefinitionName].$id = santaCoreUtils.guidUtils.getUniqueId()
        return _.assign(dataModel.createDataItemByType(ps, 'CustomDefinitionDescriptor'), {structure: newDefinitionData})
    }

    const getDefinitionPropertiesDifference = (oldDefinitionData, updatedDefinitionData) => {
        const interpretedOldDefinition = interpretDefinition(oldDefinitionData)
        const interpretedUpdatedDefinition = interpretDefinition(updatedDefinitionData)

        const addedProperties = _(interpretedUpdatedDefinition.properties)
            .keys()
            .difference(_.keys(interpretedOldDefinition.properties))
            .map(name => ({name, default: _.get(updatedDefinitionData, [interpretedUpdatedDefinition.name, 'default', name])}))
            .value()

        const removedProperties = _(interpretedOldDefinition.properties)
            .keys()
            .difference(_.keys(interpretedUpdatedDefinition.properties))
            .map(name => ({name}))
            .value()

        const restrictedProperties = []
        _.forOwn(interpretedUpdatedDefinition.properties, (updatedPropData, updatedPropName) => {
            if (!_.isUndefined(interpretedOldDefinition.properties[updatedPropName])) {
                const didRefChange =
                    getPropertyRef({[updatedPropName]: updatedPropData}) !==
                    getPropertyRef({[updatedPropName]: interpretedOldDefinition.properties[updatedPropName]})
                const didArrayChange =
                    isPropertyArray({[updatedPropName]: updatedPropData}) !==
                    isPropertyArray({[updatedPropName]: interpretedOldDefinition.properties[updatedPropName]})

                if (didRefChange || didArrayChange) {
                    addedProperties.push({
                        name: updatedPropName,
                        default: _.get(updatedDefinitionData, [interpretedUpdatedDefinition.name, 'default', updatedPropName])
                    })
                    removedProperties.push({name: updatedPropName})
                }

                const updatedPropRestrictions = getPropertyRestrictions({[updatedPropName]: updatedPropData})
                const oldPropRestrictions = getPropertyRestrictions({[updatedPropName]: interpretedOldDefinition.properties[updatedPropName]})
                if (!_.isEqual(updatedPropRestrictions, oldPropRestrictions)) {
                    restrictedProperties.push({name: updatedPropName, restrictions: updatedPropRestrictions})
                }
            }
        })

        return {addedProperties, removedProperties, restrictedProperties}
    }

    const getDefinitionUsagesInProperties = (properties, refId) =>
        _.reduce(
            properties,
            (acc, property) => {
                const propertyName = getSchemaStructureKey(property)
                const ref = isPropertyArray(property)
                    ? _.get(property, [propertyName, 'allOf', 0, 'items', 'allOf', 0, '$ref'], '')
                    : _.get(property, [propertyName, 'allOf', 0, '$ref'], '')

                if (ref === refId) {
                    acc.push({property, name: propertyName})
                }
                return acc
            },
            []
        )

    const getDefinitionUsagesInWidgets = (ps, getWidgetPropertiesSchema, refId) => {
        const widgets = appStudioDataModel.getAllWidgets(ps)
        return _.reduce(
            widgets,
            (acc, widget) => {
                const widgetProperties = getWidgetPropertiesSchema(ps, widget.pointer)
                const dependantProperties = getDefinitionUsagesInProperties(widgetProperties, refId)
                if (dependantProperties.length > 0) {
                    acc.push({widget, dependantProperties})
                }
                return acc
            },
            []
        )
    }

    const getDefinitionUsagesInDefinitions = (ps, refId) => {
        const definitions = appStudioDataModel.getAllCustomDefinitions(ps)
        return _.reduce(
            definitions,
            (acc, definition) => {
                const definitionData = appStudioDataModel.getSerializedCustomDefinition(ps, definition.pointer)
                const interpretedDefinition = interpretDefinition(definitionData)
                const definitionPropertiesArray = _.map(_.keys(interpretedDefinition.properties), key => ({[key]: interpretedDefinition.properties[key]}))
                const dependantProperties = getDefinitionUsagesInProperties(definitionPropertiesArray, refId)
                if (dependantProperties.length > 0) {
                    acc.push({definition, dependantProperties})
                }
                return acc
            },
            []
        )
    }

    const updateDefinitionDefault = (ps, dependantDefinition, difference) => {
        let dependantDefinitionData = appStudioDataModel.getSerializedCustomDefinition(ps, dependantDefinition.definition.pointer)

        _.forEach(dependantDefinition.dependantProperties, dependantDefinitionProperty => {
            const defaultValuePath = [dependantDefinition.definition.name, 'default', dependantDefinitionProperty.name]
            dependantDefinitionData = updateSchemaDefaultDifferenceByPath(
                dependantDefinitionData,
                isPropertyArray(dependantDefinitionProperty.property),
                defaultValuePath,
                defaultValuePath,
                difference
            )
        })

        return dependantDefinitionData
    }

    function getDifference(oldPropertyData, updatedPropertyData) {
        const title = oldPropertyData.title || updatedPropertyData.title
        const difference = getDefinitionPropertiesDifference({[title]: oldPropertyData}, {[title]: updatedPropertyData})
        const addedPropertiesData = _.mapValues(_.keyBy(difference.addedProperties, 'name'), 'default')
        const removedPropertiesNames = _.map(difference.removedProperties, 'name')
        const {restrictedProperties} = difference
        return {restrictedProperties, addedPropertiesData, removedPropertiesNames}
    }

    function getUpdatedWidgetPropertiesDefaults(ps, oldPropertyData, updatedPropertyData) {
        if (updatedPropertyData.type === 'array' && oldPropertyData.type === 'array') {
            if (oldPropertyData.items.$id === updatedPropertyData.items.$id) {
                const difference = getDifference(oldPropertyData.items, updatedPropertyData.items)
                return _.map(oldPropertyData.default, item => getUpdatedDefaultData(item, difference))
            }
            return updatedPropertyData.default
        }
        if (oldPropertyData.$id === updatedPropertyData.$id) {
            const difference = getDifference(oldPropertyData, updatedPropertyData)
            return getUpdatedDefaultData(oldPropertyData.default, difference)
        }
        return updatedPropertyData.default
    }

    const updateWidgetPropertiesDefault = (ps, dependantWidget, difference, getWidgetPropertiesSchema) => {
        const dependantWidgetProperties = getWidgetPropertiesSchema(ps, dependantWidget.widget.pointer)

        _.forEach(dependantWidget.dependantProperties, dependantWidgetProperty => {
            const changedPropertyIndex = _.findIndex(dependantWidgetProperties, property => getSchemaStructureKey(property) === dependantWidgetProperty.name)
            const changedProperty = dependantWidgetProperties[changedPropertyIndex]

            if (changedProperty) {
                const defaultValuePath = [dependantWidgetProperty.name, 'allOf', 1, 'default']
                const defaultValuePathArray = [dependantWidgetProperty.name, 'allOf', 0, 'default']
                dependantWidgetProperties[changedPropertyIndex] = updateSchemaDefaultDifferenceByPath(
                    changedProperty,
                    isPropertyArray(changedProperty),
                    defaultValuePath,
                    defaultValuePathArray,
                    difference
                )
            }
        })
        return dependantWidgetProperties
    }

    const updateSchemaDefaultDifferenceByPath = (schema, isArray, defaultValuePath, defaultValuePathArray, difference) => {
        const addedPropertiesData = _.mapValues(_.keyBy(difference.addedProperties, 'name'), 'default')
        const removedPropertiesNames = _.map(difference.removedProperties, 'name')
        const {restrictedProperties} = difference

        if (isArray) {
            _.forEach(_.get(schema, defaultValuePathArray, []), (item, index) => {
                const defaultData = _.get(schema, [...defaultValuePathArray, index], {})
                const newDefaultData = getUpdatedDefaultData(defaultData, {addedPropertiesData, removedPropertiesNames, restrictedProperties})
                _.set(schema, [...defaultValuePathArray, index], newDefaultData)
            })
        } else {
            const defaultData = _.get(schema, defaultValuePath, {})
            const newDefaultData = getUpdatedDefaultData(defaultData, {addedPropertiesData, removedPropertiesNames, restrictedProperties})
            _.set(schema, defaultValuePath, newDefaultData)
        }

        return schema
    }

    const getUpdatedDefaultData = (defaultData, {addedPropertiesData, removedPropertiesNames, restrictedProperties}) =>
        _.assign(updateSchemaDefaultAfterRestrictionsChange(_.omit(defaultData, removedPropertiesNames), restrictedProperties), addedPropertiesData)

    const updateSchemaDefaultAfterRestrictionsChange = (defaultData, dataRestrictions) => {
        _.forOwn(defaultData, (value, name) => {
            const restrictions = _.get(_.find(dataRestrictions, ['name', name]), 'restrictions')
            if (_.has(restrictions, 'minimum') || _.has(restrictions, 'maximum') || _.has(restrictions, 'multipleOf')) {
                defaultData[name] = updateNumberByRestrictions(value, restrictions)
            } else if (_.has(restrictions, 'minLength') || _.has(restrictions, 'maxLength')) {
                defaultData[name] = updateTextByRestrictions(value, restrictions)
            }
        })

        return defaultData
    }

    const updateNumberByRestrictions = (number, restrictions) => {
        const minimum = _.get(restrictions, 'minimum', Number.MIN_SAFE_INTEGER)
        const maximum = _.get(restrictions, 'maximum', Number.MAX_SAFE_INTEGER)
        let validNumber = getNumberInRange(number, minimum, maximum)
        if (_.has(restrictions, 'multipleOf')) {
            validNumber = roundByStep(validNumber, restrictions.multipleOf)
            if (validNumber > maximum) {
                validNumber -= restrictions.multipleOf
            } else if (validNumber < minimum) {
                validNumber += restrictions.multipleOf
            }
            validNumber = roundByStep(validNumber, restrictions.multipleOf)
        }
        return validNumber
    }

    const updateTextByRestrictions = (text, restrictions) => {
        const maxLength = _.get(restrictions, 'maxLength', Number.MAX_SAFE_INTEGER)
        if (_.size(text) > maxLength) {
            return _.truncate(text, {length: maxLength, omission: ''})
        }
        const minLength = _.get(restrictions, 'minLength', -1)
        if (_.size(text) < minLength) {
            return _.padEnd(text, minLength, '?')
        }
        return text
    }

    const roundByStep = (number, step) => Math.round(number / step) * step

    const getNumberInRange = (number, minimum, maximum) => Math.min(Math.max(number, minimum), maximum)

    const setPropertiesOrder = definition => {
        const definitionName = getSchemaStructureKey(definition)
        const propertiesNames = _.keys(_.get(definition, [definitionName, 'properties'], {}))
        let propertiesOrder = _.get(definition, [definitionName, 'propertiesOrder'], [])
        propertiesOrder = _(propertiesOrder)
            .reject(propertyName => !_.includes(propertiesNames, propertyName))
            .concat(_.difference(propertiesNames, propertiesOrder))
            .uniq()
            .value()

        return _.set(definition, [definitionName, 'propertiesOrder'], propertiesOrder)
    }

    function validateDataAgainstSchema(parsedCustomDefinition, propertiesSchemaData, data) {
        const validator = jsonSchemaUtils.createValidator(parsedCustomDefinition, propertiesSchemaData)
        if (!validator.validate(data)) {
            throw new Error('app studio: Data validation failed')
        }
    }

    function validateDefinitionDefaultValue(customDefinitions, newDefinitionData) {
        const parsedData = _.mapValues(newDefinitionData, 'default')
        const parsedCustomDefinition = _.merge({}, ..._.map(customDefinitions, 'structure'))

        validateDataAgainstSchema(parsedCustomDefinition, newDefinitionData, parsedData)
    }

    function validatePropertyDefaultValue(customDefinitions, newData) {
        const parsedCustomDefinition = _.merge({}, ..._.map(customDefinitions, 'structure'))
        const parsedData = _.merge({}, ...newData)

        const driver = jsonSchemaUtils.createDriver(parsedCustomDefinition)
        const data = _.merge(
            {},
            ..._.map(newData, dataItem => {
                driver.set.schema(dataItem)
                return {[driver.get.name()]: driver.get.default()}
            })
        )

        validateDataAgainstSchema(parsedCustomDefinition, parsedData, data)
    }

    function validateDefinitionHasProperties(definitionData) {
        const interpretedDefinition = interpretDefinition(definitionData)
        if (_.isEmpty(interpretedDefinition.properties)) {
            throw new Error('app studio: Definition cannot be set without properties')
        }
    }

    function validateNewDefinitionName(allDefinitions, newName) {
        // NOTE:: currently preventing using names reserved to base definitions and to tern schema (widget, props),
        // after wix code will add display name to tern.js api we need to remove this and allow 'reserved' names
        const allNames = _.concat(
            _.map(allDefinitions, item => getSchemaStructureKey(item.structure)),
            ['text', 'number', 'boolean', 'image', 'url', 'dateTime', 'array', 'object', 'string', 'widget', 'props']
        )

        if (_.includes(allNames, newName)) {
            throw new Error('appStudio.definitions: definition name is already in use for this app')
        }
    }

    return {
        getUpdatedWidgetPropertiesDefaults,
        getSchemaStructureKey,
        mergeExistingDefinitionWithDefinitionData,
        createNewDefinitionWithData,
        getDefinitionPropertiesDifference,
        getDefinitionUsagesInWidgets,
        getDefinitionUsagesInDefinitions,
        updateDefinitionDefault,
        updateWidgetPropertiesDefault,
        setPropertiesOrder,
        validatePropertyDefaultValue,
        validateDefinitionDefaultValue,
        validateDefinitionHasProperties,
        validateNewDefinitionName
    }
})
