define(['lodash'], function (_) {
    'use strict'

    const idsWithSignaturesInSeparateMap = new Set(['siteMetaData', 'metaSiteData', 'routers'])

    const EXCLUDED_KEYS = ['pageId', 'basedOnSignature']
    const EXCLUDED_KEYS_WITH_SIGNATURE = ['pageId', 'basedOnSignature', 'sig']

    const cloneWithoutAdditionalProperties = (id, value) => {
        if (!value) {
            return value
        }
        const newValue = _.clone(value)
        if (newValue.metaData) {
            newValue.metaData = _.omit(newValue.metaData, idsWithSignaturesInSeparateMap.has(id) ? EXCLUDED_KEYS_WITH_SIGNATURE : EXCLUDED_KEYS)
            if (_.isEmpty(newValue.metaData)) {
                delete newValue.metaData
            }
        }
        return newValue
    }

    return cloneWithoutAdditionalProperties
})
