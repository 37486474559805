define([], function () {
    'use strict'

    return {
        MINIMAL_CHILDREN_NUMBER: 'minimalChildrenNumber',
        MAXIMUM_CHILDREN_NUMBER: 'maximumChildrenNumber',
        ANCHORS: 'anchors',
        CONTAINABLE: 'containable',
        CONTAINABLE_BY_STRUCTURE: 'containableByStructure',
        CAN_CONTAIN: 'canContain',
        CAN_CONTAIN_BY_STRUCTURE: 'canContainByStructure',
        ALWAYS_CONTAIN_RECURSIVELY: 'alwaysContainRecursively',
        CAN_REPARENT: 'canReparent',
        SHOULD_KEEP_CHILDREN_IN_PLACE: 'shouldKeepChildrenInPlace',
        IS_REPEATER: 'isRepeater',
        IS_REPEATABLE: 'isRepeatable',
        IS_CONTAIN_CHECK_RECURSIVE: 'isContainCheckRecursive',
        IS_EXTERNAL: 'isExternal',
        GROUPABLE: 'groupable',
        ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH: 'enforceContainerChildLimitsByWidth',
        ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT: 'enforceContainerChildLimitsByHeight',
        CONTAINER: 'container',
        IS_PUBLIC_CONTAINER: 'isPublicContainer',
        MOVE_DIRECTIONS: 'moveDirections',
        RESIZABLE_SIDES: 'resizableSides',
        ROTATABLE: 'rotatable',
        FLIPPABLE: 'flippable',
        FIXED_POSITION: 'canBeFixedPosition',
        MODAL: 'modal',
        MOBILE_ONLY: 'mobileOnly',
        CAN_BE_STRETCHED: 'canBeStretched',
        REMOVABLE: 'removable',
        DUPLICATABLE: 'duplicatable',
        CROSS_SITE_DUPLICATABLE: 'crossSiteDuplicatable',
        HIDDENABLE: 'hiddenable',
        COLLAPSIBLE: 'collapsible',
        DOCKABLE: 'dockable',
        LAYOUT_LIMITS: 'layoutLimits',
        FULL_WIDTH: 'fullWidth',
        FULL_WIDTH_BY_STRUCTURE: 'fullWidthByStructure',
        STYLE_CAN_BE_APPLIED: 'styleCanBeApplied',
        NICKNAME: 'nickname',
        SHOULD_AUTO_SET_NICKNAME: 'shouldAutoSetNickname',
        ALIGNABLE: 'alignable',
        DISABLEABLE: 'disableable',
        RESIZE_ONLY_PROPORTIONALLY: 'resizeOnlyProportionally',
        ENFORCE_RESIZABLE_CORNERS: 'enforceResizableCorners',
        IS_PROPORTIONALLY_RESIZABLE: 'isProportionallyResizable',
        IGNORE_CHILDREN_ON_PROPORTINAL_RESIZE: 'ignoreChildrenOnProportionalResize',
        ENFORCE_MAX_DIM_ON_PROPORTIONAL_RESIZE: 'enforceMaxDimensionsOnProportionalResize',
        USING_LEGACY_APP_PART_SCHEMA: 'usingLegacyAppPartSchema',
        DEFAULT_MOBILE_PROPERTIES: 'defaultMobileProperties',
        VISIBLE: 'isVisible',
        MOBILE_CONVERSION_CONFIG: 'mobileConversionConfig',
        SHOULD_GENERATE_NICKNAME: 'shouldGenerateNickname',
        FORCE_MAINTAIN_IDS_ON_SERIALIZE: 'forceMaintainIDsOnSerialize',
        HEIGHT_AUTO: 'heightAuto',
        WIDTH_AUTO: 'widthAuto',
        A11Y_CONFIGURABLE: 'a11yConfigurable',
        HIDE_AS_GHOST: 'hideAsGhost',
        CAN_CONNECT_TO_CODE: 'canConnectToCode',
        ALLOWED_CHILD_TYPES: 'allowedChildTypes',
        ALLOWED_PARENT_TYPES: 'allowedParentTypes'
    }
})
