const getSubDomain = (domain: string) => {
    if (!domain) {
        return ''
    }

    const domainParts = domain.split('.')

    if (domainParts.length <= 2) {
        return domain
    }

    const beforeLastPart = domainParts[domainParts.length - 2]
    const topLevelDomains = {
        com: true,
        org: true,
        net: true,
        edu: true,
        gov: true,
        mil: true,
        info: true,
        co: true,
        ac: true
    }

    if (topLevelDomains[beforeLastPart]) {
        return `${domainParts[domainParts.length - 3]}.${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`
    }

    return `${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`
}

export {getSubDomain}
