import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

function getCompProp() {
    return {
        showAutoplay: this.getCompProp('showAutoplay') || false,
        loop: this.getCompProp('loop') || false,
        showCounter: this.getCompProp('showCounter') || false,
        expandEnabled: this.getCompProp('expandEnabled') || false,
        galleryImageOnClickAction: this.getCompProp('galleryImageOnClickAction'),
        imageMode: this.getCompProp('imageMode'),
        maxSpeed: this.getCompProp('maxSpeed') || 5,
        margin: this.getCompProp('margin'),
        aspectRatio: 1.333
    }
}

function convertData() {
    const items = _.map(
        this.proxyData,
        function (item) {
            const newItem = _.clone(item)
            return this.props.viewProps.resolveImageData(newItem, this.props.viewProps.serviceTopology, this.props.viewProps.packageName)
        }.bind(this)
    )

    return {
        items
    }
}

/**
 * @class proxies.SliderGallery
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],
    useSkinInsteadOfStyles: true,
    propsTypes: {
        viewProps: PropTypes.shape({
            resolveImageData: PropTypes.func.isRequired,
            serviceTopology: PropTypes.object.isRequired,
            packageName: PropTypes.string.isRequired,
            globalImageQuality: PropTypes.object.isRequired,
            linkRenderInfo: PropTypes.object.isRequired
        })
    },

    renderProxy() {
        const componentType = 'wysiwyg.viewer.components.SliderGallery'
        const data = convertData.call(this)
        const compProp = getCompProp.call(this)

        const sliderProps = _.merge(this.getChildCompProps(componentType), {
            compData: typesConverter.imageList(
                data,
                this.props.viewProps.resolveImageData,
                this.props.viewProps.serviceTopology,
                this.props.viewProps.globalImageQuality,
                this.props.viewProps.linkRenderInfo.pagesDataItemsMap
            ),
            compProp,
            onImageSelected: this.handleViewEvent,
            structure: this.props.structure
        })

        const props = {
            'data-proxy-name': 'SliderGallery',
            'data-aspect-ratio': compProp.aspectRatio,
            'data-image-mode': compProp.imageMode,
            id: `${sliderProps.id}_container`,
            style: sliderProps.style,
            ref: 'container'
        }

        return santaComponents.utils.createReactElement('div', props, componentsCore.compRegistrar.getCompClass(componentType, true)(sliderProps))
    }
}
