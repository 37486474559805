define([
    'lodash',
    'experiment',
    'documentServices/component/component',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/structure/structureUtils',
    'documentServices/page/pageData',
    'documentServices/page/page',
    'documentServices/page/blankPageStructure',
    'documentServices/dataModel/dataModel'
], function (_, experiment, component, componentDetectorAPI, structureUtils, pageData, page, blankPageStructure, dataModel) {
    const exec = ps => {
        if (experiment.isOpen('se_oldBlogDeprecation')) {
            const fillerPreset = {
                componentType: 'wixui.OldBlogDeprecation',
                type: 'Component',
                style: {
                    type: 'ComponentStyle',
                    styleType: 'custom',
                    style: {
                        properties: {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            bg: 'rgba(255,255,255,1)',
                            'boxShadowToggleOn-shd': 'false',
                            brd: '#ffffff',
                            brw: '0px',
                            rd: '0px',
                            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        propertiesSource: {
                            'alpha-bg': 'value',
                            'alpha-brd': 'value',
                            bg: 'value',
                            'boxShadowToggleOn-shd': 'value',
                            brd: 'value',
                            brw: 'value',
                            rd: 'value',
                            shd: 'value'
                        },
                        groups: {}
                    },
                    componentClassName: 'wixui.OldBlogDeprecation',
                    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
                }
            }

            const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
            const clientSpecMapData = ps.dal.get(clientSpecMapPointer)
            const blogAppId = _.find(clientSpecMapData, {packageName: 'blog'})

            if (blogAppId) {
                const applicationId = `${blogAppId.applicationId}`
                const blogPagesIds = pageData
                    .getPagesDataItems(ps)
                    .filter(data => `${data.appInnerID}` === applicationId)
                    .map(({id}) => id)

                blogPagesIds.forEach(pageId => {
                    if (page.isRemovable(ps, pageId)) {
                        page.remove(ps, pageId)
                    } else {
                        const pagePointer = page.getPage(ps, pageId)

                        const blogAppPartsChildren = component.getChildren(ps, pagePointer, true).filter(child => {
                            if (component.getType(child) === 'wixapps.integration.components.AppPart') {
                                const childData = ps.dal.get(dataModel.getDataItemPointer(ps, child))
                                return `${childData?.appInnerID}` === applicationId
                            }
                            return false
                        })

                        const blankSerialized = blankPageStructure.getBlankPageStructure(ps, pagePointer.id)

                        if (blogAppPartsChildren.length) {
                            const pageChildren = component.getChildren(ps, pagePointer)
                            pageChildren.forEach(compPointer => {
                                component.remove(ps, compPointer)
                            })
                            page.data.set(ps, pageId, blankSerialized.data)
                            page.style.connectToThemeStyle(ps, pagePointer.id, 'p2')
                        } else {
                            const dataItemPointer = dataModel.getDataItemPointer(ps, pagePointer)
                            const pageDataItem = ps.dal.get(dataModel.getDataItemPointer(ps, pagePointer))
                            dataModel.setDataItemByPointer(
                                ps,
                                dataItemPointer,
                                {
                                    ...pageDataItem,
                                    type: 'Page',
                                    appInnerID: undefined,
                                    appPageId: undefined,
                                    appPageType: undefined,
                                    tpaPageId: undefined,
                                    tpaApplicationId: undefined,
                                    managingAppDefId: undefined
                                },
                                'data'
                            )
                        }

                        const pageCompTypePointer = Object.assign({innerPath: ['componentType']}, pagePointer)
                        ps.dal.set(pageCompTypePointer, blankSerialized.componentType)
                    }
                })

                const allAppPartsPointers = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart')
                allAppPartsPointers.forEach(pointer => {
                    const appPartData = ps.dal.get(dataModel.getDataItemPointer(ps, pointer))
                    const isBlogAppPart = `${appPartData?.appInnerID}` === applicationId

                    if (isBlogAppPart) {
                        const replacement = {
                            ...fillerPreset,
                            layout: structureUtils.getComponentLayout(ps, pointer)
                        }
                        component.migrate(ps, pointer, replacement)
                    }
                })
            }
        }
    }

    return {
        exec,
        name: 'oldBlogDeprecationFixer',
        version: 1
    }
})
