import type {ViewerLibrary} from '../../publicAPI/apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary /*, publicMethodUtils: PublicMethodUtils*/) {
    const {initMethod} = viewerLibrary.get('documentServices/customElements/customElements')

    return {
        initMethod,
        methods: {}
    }
}
