define([], function () {
    'use strict'

    return {
        resizableSides: [],
        moveDirections: [],
        fullWidth: true,
        containable: false,
        duplicatable: false,
        crossSiteDuplicatable: false,
        removable: false,
        mobileConversionConfig: {
            marginX: 20
        }
    }
})
