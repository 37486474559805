import _ from 'lodash'

export default {
    getNumberOfPostsPerPage(compData, format?, defaultPageSize?) {
        const predicate: any = {
            fieldId: 'vars',
            key: 'itemsPerPage',
            view: compData.viewName
        }
        if (!_.isUndefined(format)) {
            predicate.format = format
        }
        return _.result(_.find(compData.appLogicCustomizations, predicate), 'value', defaultPageSize || 10)
    }
}
