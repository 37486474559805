import {getPointer} from './pointerUtils'
const pointerType = 'multilingual'

const createPointersMethods = () => {
    const currentLanguageCode = () => getPointer('currentLanguageCode', pointerType)

    return {
        multilingual: {
            currentLanguageCode
        }
    }
}

export {createPointersMethods}
