define(['lodash', '@wix/santa-core-utils'], function (_, santaCoreUtils) {
    'use strict'
    const {joinURL} = santaCoreUtils.urlUtils

    /**
     * @param {ps} ps
     * @returns {*|string}
     */
    const getEditorSdkUrl = function (ps) {
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const sdkSource = currentUrl && currentUrl.query ? currentUrl.query.EditorSdkSource || currentUrl.query.editorSdkSource : null

        if (sdkSource) {
            return sdkSource
        }

        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        return joinURL(_.get(serviceTopology, ['scriptsLocationMap', 'js-platform-editor-sdk'], ''), 'lib', 'editorSDK.min.js')
    }

    return getEditorSdkUrl
})
