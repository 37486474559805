define([
    'lodash',
    'documentServices/accessibility/reordering',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/accessibility/autoDomReorderData'
], function (_, reordering, extensionsAPI, autoDomReorderData) {
    'use strict'

    return function (ps) {
        const shouldReorderDOM = autoDomReorderData.get(ps)
        if (shouldReorderDOM) {
            const changedPagesIds = extensionsAPI.pages.getNonDeletedChangedPagePointersSinceLastSnapshot(ps, 'reordering').map(pointer => pointer.id)
            ps.dal.takeSnapshot('reordering')
            //TODO reordering receives onSuccess and onFailure sent functions are meaningless, need to change to BI event.
            reordering.reorderComponents(ps, _.noop, _.noop, changedPagesIds)
        }
    }
})
