define(['documentServices/platform/services/platformEventsService', 'documentServices/siteMetadata/clientSpecMap'], function (
    platformEventsService,
    clientSpecMapDS
) {
    const getEditorReadyCachedParams = ({firstInstall, silentInstallation, origin}) => ({firstInstall, silentInstallation, origin})

    const reloadApplication = (ps, {appDefinitionId, cachedEditorReadyOptions, registeredEvents}, editorScriptResolver) => {
        // Get applicationId from appDefintionId
        const clientSpecMapAppData = clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId)

        // Unregister old events
        platformEventsService.unRegisterAppFromEvents(clientSpecMapAppData.applicationId, registeredEvents)

        // Part from platform.loadEditorApps ()
        const appToLoad = editorScriptResolver(clientSpecMapAppData)
        const appToLoadMerged = {...appToLoad, ...getEditorReadyCachedParams(cachedEditorReadyOptions)}
        return appToLoadMerged
    }

    return {
        reloadApplication
    }
})
