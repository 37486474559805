define([], function () {
    'use strict'

    return {
        mobileOnly: true,
        anchors: {
            to: false,
            from: false
        },
        moveDirections: [],
        resizableSides: [],
        dockable: false,
        alignable: false,
        groupable: false,
        containableByStructure: true,
        hiddenable: false
    }
})
