define([], function () {
    'use strict'

    return {
        canBeStretched: true,
        styleCanBeApplied: true,

        mobileConversionConfig: {
            preserveAspectRatio: false
        }
    }
})
