import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const triggers = viewerLibrary.get('documentServices/triggers/triggers')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')

    return {
        methods: {
            components: {
                triggers: {
                    add: publicMethodUtils.defineDataManipulationAction(triggers.addTrigger, {
                        isUpdatingAnchors: dsUtils.DONT_CARE,
                        getReturnValue: triggers.getTriggerVariantToAddRef
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(triggers.removeComponentTrigger),
                    get: publicMethodUtils.defineGetter(triggers.getTrigger),
                    getAll: publicMethodUtils.defineGetter(triggers.getAllTriggers)
                }
            }
        }
    }
}
