define([
    'lodash',
    'documentServices/saveAPI/lib/saveRunner',
    'documentServices/wixapps/utils/pathUtils',
    'documentServices/wixapps/services/savePublishTaskFactory',
    'documentServices/bi/bi',
    'documentServices/utils/runtimeConfig'
], function (_, taskRunner, pathUtils, savePublishTaskFactory, bi, runtimeConfig) {
    'use strict'

    const getSaveOnlyWixappsRegistry = ps => ({
        primaryTask: savePublishTaskFactory.create(ps),
        secondaryTasks: []
    })

    /**
     * @param {ps} privateServices
     * @returns {{event: *, error: *}}
     */
    function getBiCallbacks(privateServices) {
        return {
            event: _.partial(bi.event, privateServices),
            error: _.partial(bi.error, privateServices)
        }
    }

    /**
     * @param {ps} ps
     */
    function reload(ps) {
        ps.wixappsDAL.removeByPath(pathUtils.getAppbuilderPath())
        let acc = []
        _.forEach(pathUtils.getAppbuilderMetadataPath('requestedPartNames'), function (pathPart) {
            const newVal = pathPart !== 'requestedPartNames' ? {} : []
            acc = acc.concat(pathPart)
            ps.wixappsDAL.setByPath(acc, newVal)
        })
        if (!runtimeConfig.isSanta(ps)) {
            ps.wixappsDAL.takeSnapshot()
        } else {
            ps.dal.takeSnapshot(savePublishTaskFactory.getTaskName())
        }
    }

    /**
     * @param {ps} privateServices
     * @param onSuccess
     * @param onError
     * @param isFullSave
     */
    function save(privateServices, onSuccess, onError, isFullSave) {
        privateServices.setOperationsQueue.flushQueueAndExecute(function () {
            const args = [getSaveOnlyWixappsRegistry(privateServices), privateServices.dal, onSuccess, onError, getBiCallbacks(privateServices)]

            if (isFullSave) {
                taskRunner.runFullSaveTasks(...args)
            } else {
                taskRunner.runPartialSaveTasks(...args)
            }
        })
    }

    return {
        reload,
        save
    }
})
