import type {
    RendererModelFromServer,
    PagesPlatformApplications,
    ClientSpecMap,
    SiteExtensionClientSpecMapEntry,
    MetaSiteClientSpecEntry
} from '@wix/document-services-types'

const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

function getWixCodeAppDefId(clientSpecMap: ClientSpecMap): string | null {
    for (const spec in clientSpecMap) {
        if (clientSpecMap[spec].type === 'siteextension') {
            return (clientSpecMap[spec] as SiteExtensionClientSpecMapEntry).appDefinitionId.toString()
        }
    }
    return null
}

function hasAppWithAppDefId(clientSpecMap: ClientSpecMap, appDefId: string): boolean {
    for (const appId in clientSpecMap) {
        if (clientSpecMap.hasOwnProperty(appId) && (clientSpecMap[appId] as MetaSiteClientSpecEntry).appDefinitionId === appDefId) {
            return true
        }
    }
    return false
}

function isGuid(appId: string): boolean {
    return guidRegex.test(appId)
}

//compare appDefId with current and legacy wix code app def ids - needed for old automation site
function isLegacyWixCodeAppDefId(rendererModel: RendererModelFromServer, appDefId: string): boolean {
    return isGuid(appDefId) && !hasAppWithAppDefId(rendererModel.clientSpecMap, appDefId)
}

export const getPagesPlatformApplications = (rendererModel: RendererModelFromServer): PagesPlatformApplications => {
    const wixCodeAppDefId = getWixCodeAppDefId(rendererModel.clientSpecMap)
    const {pagesPlatformApplications} = rendererModel
    const result = {}
    for (const pageId in pagesPlatformApplications) {
        if (pagesPlatformApplications.hasOwnProperty(pageId)) {
            pagesPlatformApplications[pageId].forEach((appDefId: string) => {
                if (appDefId === wixCodeAppDefId || isLegacyWixCodeAppDefId(rendererModel, appDefId)) {
                    appDefId = 'wixCode'
                }
                result[appDefId] = result[appDefId] || {}
                result[appDefId][pageId] = true
            })
        }
    }
    return result
}
