define([
    'lodash',
    'documentServices/hooks/componentsExternalHooks/externalCompReadAPI',
    'documentServices/hooks/componentsExternalHooks/externalCompWriteAPI'
], function (_, externalCompReadAPI, externalCompWriteAPI) {
    'use strict'

    return (ps, compPointer, {overrides = undefined, read = true, write = false} = {}) =>
        _.merge({}, write && externalCompWriteAPI(ps, compPointer), read && externalCompReadAPI(ps, compPointer), overrides)
})
