import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import TPAWorkerAspect from './TPAWorkerAspect'
import TPAPostMessageAspect from './TPAPostMessageAspect'
import TPAPopupAspect from './TPAPopupAspect'
import TPAPubSubAspect from './TPAPubSubAspect'
import TPAPixelTrackerAspect from './TPAPixelTrackerAspect'
import TPAModalAspect from './TPAModalAspect'
import TPAMobileViewportAspect from './TPAMobileViewportAspect'
import TPACompStateAspect from './TPACompStateAspect'
import TPAComponentsDomAspect from './TPAComponentsDomAspect'
import TPAPageNavigationAspect from './TPAPageNavigationAspect'
import TPARPCAspect from './TPARPCAspect'
import tpaWidgetNativeAspect from '../native/aspects/tpaWidgetNativeAspect'

const {siteAspectsRegistry} = componentsCore

siteAspectsRegistry.registerSiteAspect('tpaWorkerAspect', TPAWorkerAspect)
siteAspectsRegistry.registerSiteAspect('tpaPostMessageAspect', TPAPostMessageAspect)
siteAspectsRegistry.registerSiteAspect('tpaPopupAspect', TPAPopupAspect)
siteAspectsRegistry.registerSiteAspect('tpaPubSubAspect', TPAPubSubAspect)
siteAspectsRegistry.registerSiteAspect('tpaPixelTrackerAspect', TPAPixelTrackerAspect)
siteAspectsRegistry.registerSiteAspect('tpaModalAspect', TPAModalAspect)
siteAspectsRegistry.registerSiteAspect('tpaMobileViewportAspect', TPAMobileViewportAspect)
siteAspectsRegistry.registerSiteAspect('tpaCompStateAspect', TPACompStateAspect)
siteAspectsRegistry.registerSiteAspect('tpaComponentsDomAspect', TPAComponentsDomAspect)
siteAspectsRegistry.registerSiteAspect('tpaPageNavigationAspect', TPAPageNavigationAspect)
siteAspectsRegistry.registerSiteAspect('TPARPCAspect', TPARPCAspect)
siteAspectsRegistry.registerSiteAspect('tpaWidgetNativeAspect', tpaWidgetNativeAspect)
