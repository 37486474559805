import index from './src/wixappsCore'
export default index
export const {
    viewsRenderer,
    viewsCustomizer,
    proxyFactory,
    baseProxy,
    inputProxy,
    baseCompositeProxy,
    typesConverter,
    logicFactory,
    ViewContextMap,
    CoreSantaTypes,
    wixappsDataHandler,
    wixappsLogger,
    wixappsSantaTypesUtil,
    videoThumbDataHandler,
    styleCollector,
    appPartCustomCss,
    spacersCalculator,
    wixappsConfiguration,
    linksConverter,
    localizer,
    FunctionLibrary,
    viewsUtils,
    memoizedViewsUtils,
    wixappsUrlParser,
    typeNameResolver,
    styleData,
    richTextUtils,
    convertStringToPrimitive,
    styleMapping,
    expression
} = index
