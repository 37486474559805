define([
    'lodash',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/constants/constants',
    'documentServices/media/vectorImageAPI'
], function (_, metaDataUtils, constants, vectorImageAPI) {
    'use strict'

    function getMinDimensions(ps, compPtr) {
        //Better use componentStylesAndSkinsAPI, but can't due to circular dependency
        const styleId = ps.dal.get(ps.pointers.getInnerPointer(compPtr, 'styleId'))
        const pageId = ps.pointers.components.getPageOfComponent(compPtr).id
        const style = ps.dal.get(ps.pointers.data.getThemeItem(styleId, pageId))

        const strokeWidth = parseInt(_.get(style, ['style', 'properties', 'strokewidth'], 0), 10)

        let minWidth = Math.max(strokeWidth + 1, 5)
        let minHeight = Math.max(strokeWidth + 1, 5)

        const originalAspectRatio = vectorImageAPI.getOriginalAspectRatio(ps, compPtr)
        if (originalAspectRatio) {
            if (originalAspectRatio > 1) {
                minHeight = strokeWidth + 1
                minWidth = minHeight * originalAspectRatio
            } else {
                minWidth = strokeWidth + 1
                minHeight = minWidth / originalAspectRatio
            }
        }

        return {
            minWidth: Math.ceil(minWidth),
            minHeight: Math.ceil(minHeight)
        }
    }

    return {
        mobileConversionConfig: {
            category: 'graphic',
            isSuitableForProportionGrouping: true
        },
        rotatable: true,
        flippable: true,
        resizableSides(ps, compPtr) {
            if (metaDataUtils.isMaintainSvgAspectRatio(ps, compPtr)) {
                return []
            }
            return [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT]
        },
        layoutLimits(ps, compPtr) {
            const aspectRatio = metaDataUtils.isMaintainSvgAspectRatio(ps, compPtr) ? vectorImageAPI.getOriginalAspectRatio(ps, compPtr) : null
            const minDimensions = getMinDimensions(ps, compPtr)
            return {
                aspectRatio: aspectRatio || undefined,
                minWidth: minDimensions.minWidth,
                minHeight: minDimensions.minHeight,
                maxHeight: 2000
            }
        }
    }
})
