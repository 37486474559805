define(['lodash', 'experiment', '@wix/santa-ds-libs/src/coreUtils', '@wix/santa-core-utils', 'documentServices/siteMetadata/siteMetadata'], function (
    _,
    experiment,
    coreUtils,
    santaCoreUtils,
    siteMetadata
) {
    'use strict'

    /**
     * @param {ps} ps
     */
    function initialize(ps) {
        const dsQTrace = ps.siteAPI.getQueryParam('dsQTrace')
        const ds_recordActionsToCompareWithRC = experiment.isOpen('ds_recordActionsToCompareWithRC')

        if (dsQTrace || ds_recordActionsToCompareWithRC) {
            startTrace(ps)
        }
    }

    /**
     * @param {ps} ps
     * @returns {Promise<void>}
     */
    function upload(ps) {
        return new Promise((resolve, reject) => {
            const ds_recordActionsToCompareWithRC_local = experiment.isOpen('ds_recordActionsToCompareWithRC_local')

            const basePublicUrl = ds_recordActionsToCompareWithRC_local
                ? 'http://localhost:3000/api/public'
                : `${ps.dal
                      .get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), ['basePublicUrl']))
                      .replace('http://', 'https://')}_api/autopilot`

            const records = get(ps)
                .filter(e => e.type === 'Q OPERATION ADDED')
                // @ts-ignore
                .filter(e => e.handle > ps.runtimeConfig.trace.lastRecordedUploadedHandleIndex)

            if (records.length > 0) {
                coreUtils.ajaxLibrary.ajax({
                    url: `${basePublicUrl}/recordings`,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        siteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID),
                        metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
                        initialSeed: santaCoreUtils.guidUtils.getInitialSeed(),
                        records: records.map(e => ({
                            method: e.methodName,
                            args: _.keys(e.args)
                                .sort()
                                .map(k => e.args[k])
                        }))
                    },
                    success(data) {
                        santaCoreUtils.guidUtils.setInitialSeed(Date.now())
                        resolve(data)
                    },
                    error(jqXHR) {
                        reject(jqXHR)
                    }
                })

                ps.runtimeConfig.trace.lastRecordedUploadedHandleIndex = _.get(_.last(records), 'handle') || 0
            } else {
                resolve()
            }
        })
    }

    /**
     * @param {ps} ps
     * @param {{shouldLogConsoleTraces: boolean}} [config]
     */
    const startTrace = (ps, {shouldLogConsoleTraces = false} = {shouldLogConsoleTraces: false}) => {
        ps.runtimeConfig.trace.isTracing = true
        ps.runtimeConfig.trace.shouldLogConsoleTraces = shouldLogConsoleTraces
    }

    const endTrace = ps => {
        ps.runtimeConfig.trace.isTracing = false
    }

    const cleanTrace = ps => {
        ps.runtimeConfig.trace.log = []
        ps.runtimeConfig.trace.readLog = []
    }

    /**
     * @param {ps} ps
     * @param {boolean} [read]
     * @returns {*[]}
     */
    const get = (ps, read) => (read ? ps.runtimeConfig.trace.readLog : ps.runtimeConfig.trace.log)

    const isTracing = ps => ps.runtimeConfig.trace.isTracing
    const shouldLogTrace = (ps, {methodName = ''}) => isTracing(ps) && !methodName.startsWith('debug.trace')

    /**
     * @param {ps} ps
     * @param type
     * @param logData
     */
    const logTrace = (ps, type, logData) => {
        if (shouldLogTrace(ps, logData)) {
            const ts = window.performance ? window.performance.now() : _.now()
            ps.runtimeConfig.trace.log.push(_.merge({type, ts}, logData))
        }
    }

    /**
     * @param {ps} ps
     * @param logData
     */
    const logReadTrace = (ps, logData) => {
        if (shouldLogTrace(ps, logData)) {
            const ts = window.performance ? window.performance.now() : _.now()
            ps.runtimeConfig.trace.readLog.push(_.assign({type: 'Q OPERATION READ', ts}, logData))
        }
    }

    const shouldLogConsoleTrace = ps => ps.runtimeConfig.trace.shouldLogConsoleTraces

    /**
     * @param {ps} ps
     * @return {Array} array of only the actions, without the rendering.
     */
    const getActions = ps => _.reject(get(ps), {type: 'VIEWER RENDERED FROM Q'})

    /**
     * @param {ps} ps
     * @return {Object.<string, object[]>} a map from <methodName, allMethodCallsData>
     */
    const getCalledMethods = ps => _.groupBy(getActions(ps).concat(get(ps, true)), 'methodName')

    return {
        initialize,
        startTrace,
        endTrace,
        getActions,
        getCalledMethods,
        get,
        logReadTrace,
        logTrace,
        shouldLogConsoleTrace,
        isTracing,
        cleanTrace,
        upload
    }
})
