import tpa from './src/tpa'
export default tpa

export const {
    tpaWidgetNative,
    tpaWidgetNativeDeadComp,
    nativeCompRequireService,
    widget,
    section,
    multiSection,
    worker,
    gluedWidget,
    tpaMixins,
    gluedWidgetMeasuringUtils,
    GluedWidgetPatcher,
    tpaSectionPatcher,
    common,
    services,
    sitePages,
    bi,
    tpaHandlers,
    tpaStyleUtils
} = tpa
