define([
    'lodash',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/utils/utils',
    'documentServices/page/page',
    'documentServices/features/features',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/appControllerData/appControllerData',
    'documentServices/appControllerData/appControllerDataItem',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/component/component',
    'documentServices/refComponent/refComponent',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/componentCode',
    'documentServices/appStudioWidgets/constants',
    'documentServices/constants/constants',
    'documentServices/appStudioWidgets/appStudioWidgetUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/platform/livePreview/livePreview',
    'documentServices/platform/common/constants',
    '@wix/santa-core-utils'
], function (
    _,
    utils,
    dsUtils,
    page,
    features,
    clientSpecMapService,
    appControllerData,
    appControllerDataItem,
    documentModeInfo,
    component,
    refComponent,
    refComponentUtils,
    componentDetectorAPI,
    componentCode,
    appStudioWidgetsConstants,
    constants,
    appStudioWidgetUtils,
    dataModel,
    livePreview,
    platformCommonConstants,
    coreUtilsLib
) {
    'use strict'

    const {REF_COMPONENT_TYPE} = constants.REF_COMPONENT
    const {APP_WIDGET} = platformCommonConstants.CONTROLLER_TYPES

    const {displayedOnlyStructureUtil, remoteStructureFetcher} = coreUtilsLib
    const {getReferredCompId} = displayedOnlyStructureUtil
    const {switchAppWidgetStructure} = appStudioWidgetUtils
    const {WidgetInstallationTypes, ROLE_PATH, LIVE_PREVIEW_REFRESH_SOURCE} = appStudioWidgetsConstants
    const OPTIONAL_OVERRIDE_TYPES_TO_KEEP = {
        data: true,
        style: true,
        design: true,
        connections: true
    }

    const PERMANENT_OVERRIDE_TYPES_TO_KEEP = {
        connections: true
    }

    const isWidgetClosable = (ps, widgetRef) => _.get(appControllerData.getControllerStageDataByControllerRef(ps, widgetRef), ['behavior', 'closable'], true)

    async function changeVariationOpen(ps, widgetRef, newComponentRef, variationId) {
        const {applicationId} = component.data.get(ps, widgetRef)
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, applicationId)
        const widgetId = appControllerData.getSettingsIn(ps, widgetRef, 'type')

        const widgetStructure = await remoteStructureFetcher.getWidgetStructureByAppData(appData, widgetId, variationId)
        switchAppWidgetStructure(ps, widgetRef, newComponentRef, widgetStructure)
        return newComponentRef
    }

    function changeVariationForAppWidget(ps, widgetRef, variationId, onSuccess, onError) {
        const newCompRef = refComponent.getComponentToCreateRef(ps, widgetRef)
        if (isWidgetClosable(ps, widgetRef)) {
            refComponent.closeWidgetToReferredComponent(ps, newCompRef, widgetRef)
            changeVariationClosed(ps, newCompRef, variationId, undefined, undefined, onSuccess)
        } else {
            changeVariationOpen(ps, widgetRef, newCompRef, variationId).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
        }
    }

    const isComponentRefNotInflatedInternalRef = (ps, componentRef) =>
        refComponentUtils.isInternalRef(ps, componentRef) && !refComponentUtils.isRefComponentInflated(ps, componentRef)

    const isGhostCompOverride = ({itemType, dataItem}) => itemType === 'props' && !!dataItem.ghost

    function changeVariationClosed(ps, componentRef, variationId, customOverrides, keepOverrides = true, onSuccess) {
        const overridesWithPrimaryRole = customOverrides || getOverridesWithPrimaryRole(ps, componentRef)
        const filteredOverridesWithPrimaryRole = filterAllowedOverrides(overridesWithPrimaryRole, keepOverrides)

        refComponentUtils.removeConnectionOverride(ps, componentRef)

        if (!customOverrides || !keepOverrides) {
            refComponentUtils.removeAllOverrides(ps, componentRef)
        }

        refComponentUtils.updateVariation(ps, componentRef, variationId)

        ps.setOperationsQueue.waitForChangesApplied(() => {
            const newVariationCompsWithPrimaryRole = getCompsWithPrimaryRole(ps, componentRef)
            copyOverridesByRole(ps, filteredOverridesWithPrimaryRole, componentRef, newVariationCompsWithPrimaryRole)
            onSuccess(componentRef)
        })
    }

    function filterAllowedOverrides(overrides, keepOverrides) {
        return _.filter(overrides, override => {
            if (keepOverrides) {
                return OPTIONAL_OVERRIDE_TYPES_TO_KEEP[override.itemType] || isGhostCompOverride(override)
            }
            return PERMANENT_OVERRIDE_TYPES_TO_KEEP[override.itemType]
        })
    }

    function getComponentUnderAncestor(ps, componentRef) {
        if (refComponentUtils.isRefComponentInflated(ps, componentRef)) {
            return componentDetectorAPI.getComponentsUnderAncestor(ps, componentRef)
        }
        return refComponent.getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps, componentRef)
    }

    const getComponentId = inflatedCompRef => _.defaults({id: _.last(inflatedCompRef.id.split('_r_'))}, inflatedCompRef)

    function getCompsWithPrimaryRole(ps, componentRef) {
        const comps = getComponentUnderAncestor(ps, componentRef)
        const isNotInflatedInternalRef = isComponentRefNotInflatedInternalRef(ps, componentRef)
        return _.map(comps, compRef => {
            const rolePath = createRolePath(ps, compRef, isNotInflatedInternalRef)
            return _.merge({rolePath}, compRef)
        })
    }

    /**
     * Copy overrides to new comps
     * @param {ps} ps
     * @param overrides - overrides to copy, should contain comp's primary role
     * @param refComp - ref compRef for the override creation
     * @param internalComps - ref comp's internal components
     */
    function copyOverridesByRole(ps, overrides, refComp, internalComps) {
        const pageId = ps.pointers.full.components.getPageOfComponent(refComp).id
        _.forEach(overrides, ({itemType, dataItem, rolePath, isMobile}) => {
            let compId = _.get(_.find(internalComps, {rolePath}), 'id')
            if (compId) {
                compId = getReferredCompId(compId)
                refComponentUtils.createOverrideDataItem(ps, itemType, refComp, compId, pageId, dataItem, isMobile)
            }
        })
    }

    /**
     * @param {ps} ps
     * @param componentRef
     * @param variationId
     * @param [onSuccess]
     * @param [onError]
     * @param [options]
     */
    function changeVariation(ps, componentRef, variationId, onSuccess = _.noop, onError = _.noop, options = {}) {
        const {customOverrides, keepOverrides} = options
        if (component.getType(ps, componentRef) === APP_WIDGET) {
            changeVariationForAppWidget(ps, componentRef, variationId, onSuccess, onError)
        } else if (component.getType(ps, componentRef) === REF_COMPONENT_TYPE) {
            changeVariationClosed(ps, componentRef, variationId, customOverrides, keepOverrides, onSuccess)
        } else {
            throw new Error('Change variation is not available for this component')
        }
    }

    function changePreset(ps, componentRef, stylePresetId, layoutPresetId) {
        if (component.getType(ps, componentRef) !== REF_COMPONENT_TYPE) {
            throw new Error('Change preset is available only for ref components')
        }

        features.updateFeatureData(ps, componentRef, constants.DATA_TYPES.presets, {
            type: constants.PRESETS.PRESET_DATA_TYPE,
            ...(stylePresetId ? {style: dsUtils.stripHashIfExists(stylePresetId)} : {}),
            ...(layoutPresetId ? {layout: dsUtils.stripHashIfExists(layoutPresetId)} : {})
        })
    }

    function getPreset(ps, componentRef) {
        const componentType = component.getType(ps, componentRef)
        if (componentType === APP_WIDGET) {
            return getPresetByRefComponent(ps, ps.pointers.components.getParent(componentRef))
        } else if (componentType === REF_COMPONENT_TYPE) {
            return getPresetByRefComponent(ps, componentRef)
        }

        throw new Error('Get preset is available only for blocks apps - ref components or app widget')
    }

    function getPresetByRefComponent(ps, componentRef) {
        const componentType = component.getType(ps, componentRef)
        if (componentType === REF_COMPONENT_TYPE) {
            return features.getFeatureData(ps, componentRef, constants.DATA_TYPES.presets)
        }
    }

    function getDefaultWidgetX(ps, width) {
        const pageWidth = ps.siteAPI.getSiteWidth()
        return (pageWidth - width) / 2
    }

    function getWidgetLayout(ps, structureLayout, layoutOverrides = {}) {
        const defaultLayout = {
            x: getDefaultWidgetX(ps, layoutOverrides.width || structureLayout.width)
        }
        return _.defaultsDeep(layoutOverrides, defaultLayout)
    }

    function buildRefWidgetStructure(ps, appDefinitionId, widgetId, options) {
        const {variationPageId, presets, scopedPresets, layout, layouts, overriddenData} = options
        return refComponent.generateRefComponentStructure(appDefinitionId, widgetId, variationPageId, {
            overriddenData,
            layout,
            layouts,
            presets,
            scopedPresets
        })
    }

    function addWidgetClosed(ps, componentToAddRef, appDefinitionId, widgetId, options = {}) {
        const {containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId()), onSuccess = _.noop} = options
        const widgetRefStructure = buildRefWidgetStructure(ps, appDefinitionId, widgetId, options)
        component.add(ps, componentToAddRef, containerRef, widgetRefStructure)
        onSuccess(componentToAddRef)
    }

    function addWidgetOpen(ps, componentToAddRef, appData, widgetId, options = {}) {
        const {variationPageId, layout, containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId()), onSuccess = _.noop, onError = _.noop} = options

        const widgetPageId = _.get(appData, ['widgets', widgetId, 'componentFields', 'appStudioFields', 'id'])

        remoteStructureFetcher
            .getWidgetStructureByAppData(appData, widgetPageId, variationPageId)
            .then(widgetStructure => {
                const updatedLayout = getWidgetLayout(ps, widgetStructure.layout, layout)
                const updatedWidgetStructure = _.assign({}, widgetStructure, {layout: updatedLayout})
                component.add(ps, componentToAddRef, containerRef, updatedWidgetStructure)

                onSuccess(componentToAddRef)
            })
            .catch(onError)
    }

    function getComponentToAddRef(ps, appDefinitionId, widgetId, options = {}) {
        const {containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId())} = options
        return component.getComponentToAddRef(ps, containerRef)
    }

    function addWidget(ps, componentToAddRef, appDefinitionId, widgetId, options = {}) {
        const {installationType = WidgetInstallationTypes.CLOSED} = options

        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        const widgetData = _.get(appData, ['widgets', widgetId])
        if (_.isNil(widgetData)) {
            throw new Error(`WidgetId '${widgetId}' does not exist in app '${appDefinitionId}'`)
        }

        if (installationType === WidgetInstallationTypes.CLOSED) {
            addWidgetClosed(ps, componentToAddRef, appDefinitionId, widgetId, options)
        } else if (installationType === WidgetInstallationTypes.OPEN) {
            addWidgetOpen(ps, componentToAddRef, appData, widgetId, options)
        } else {
            throw new Error('Unsupported installationType')
        }
    }

    const getCompNickname = (ps, compRef) => {
        if (!component.isExist(ps, compRef)) {
            return
        }

        let refComp = refComponent.getRefHostCompPointer(ps, compRef)

        if (component.getType(ps, compRef) === APP_WIDGET) {
            refComp = refComponent.getRefHostCompPointer(ps, refComp)
            if (!refComp) {
                return ROLE_PATH.ROOT
            }
        }

        const ghostCompsRolesMap = _.mapValues(refComponent.getAllGhostRefComponentsPrimaryConnection(ps, refComp), 'role')
        const [upperAppWidgetRef] = ps.pointers.components.getChildren(refComp)
        return componentCode.getNickname(ps, compRef, upperAppWidgetRef) || ghostCompsRolesMap[compRef.id]
    }

    const getContext = (ps, compRef) => {
        let outerCompRef = refComponent.getRefHostCompPointer(ps, compRef)
        if (component.getType(ps, compRef) === APP_WIDGET) {
            outerCompRef = refComponent.getRefHostCompPointer(ps, outerCompRef)
        }

        const [appWidgetContainer] = ps.pointers.components.getChildren(outerCompRef)

        return appWidgetContainer
    }

    const getContextNotRenderedRefComponent = (ps, compRef) => {
        let outerCompRef = refComponent.getRefHostCompPointer(ps, compRef)
        const appWidgetId = dataModel.getDataItem(ps, getComponentId(outerCompRef)).rootCompId
        const masterCompPtr = {id: appWidgetId, type: documentModeInfo.getViewMode(ps)}
        const currentComp = getComponentId(compRef)

        if (component.getType(ps, currentComp) === APP_WIDGET) {
            outerCompRef = refComponent.getRefHostCompPointer(ps, outerCompRef)
            if (!outerCompRef) {
                return
            }
        }

        return refComponent.getUniqueRefCompPointer(ps, outerCompRef, masterCompPtr)
    }

    const createRolePath = (ps, compRef, isNotInflatedInternalRef) => {
        let getCompNicknameFunc = getCompNickname
        let getContextFunc = getContext

        if (isNotInflatedInternalRef) {
            getCompNicknameFunc = getCompNicknameNotRenderedRefComponent
            getContextFunc = getContextNotRenderedRefComponent
        }

        let path
        let curNickname
        let currentCompRef = compRef
        while (currentCompRef && (curNickname = getCompNicknameFunc(ps, currentCompRef))) {
            const isRepeatedComponent = displayedOnlyStructureUtil.isRepeatedComponent(currentCompRef.id)
            const itemId = isRepeatedComponent ? utils.displayedOnlyStructureUtil.getRepeaterItemId(currentCompRef.id) : ''

            path = curNickname + (itemId ? ROLE_PATH.REPEATED_COMP_DELIMITER + itemId : '') + (path ? ROLE_PATH.DELIMITER + path : '')
            currentCompRef = getContextFunc(ps, currentCompRef)
        }

        return path
    }

    const isRootAppWidget = (ps, appWidgetRef) => {
        const refComp = refComponent.getRefHostCompPointer(ps, appWidgetRef)
        return !refComponent.getRefHostCompPointer(ps, refComp)
    }

    const getNicknameOfAppWidget = (ps, appWidget) => {
        if (isRootAppWidget(ps, appWidget)) {
            return ROLE_PATH.ROOT
        }
        const refComp = refComponent.getRefHostCompPointer(ps, appWidget)
        const refCompOnly = getComponentId(refComp)
        const allOverrides = refComponentUtils.getOverriddenData(ps, refCompOnly)

        const connectionItemOverride = _(allOverrides).find({itemType: 'connections'})

        const item = _.find(connectionItemOverride.dataItem.items, {isPrimary: true})

        return item.role
    }

    const getCompNicknameNotRenderedRefComponent = (ps, compRef) => {
        const compPointer = getComponentId(compRef)

        if (!component.isExist(ps, compPointer)) {
            return
        }

        if (component.getType(ps, compPointer) === APP_WIDGET) {
            return getNicknameOfAppWidget(ps, compRef)
        }

        return componentCode.getNickname(ps, compPointer)
    }

    const getOverridesWithPrimaryRole = (ps, refComponentPtr) => {
        const overriddenData = refComponentUtils.getOverriddenData(ps, refComponentPtr)

        return _(overriddenData)
            .map(override => {
                const masterCompPtr = {id: override.compId, type: documentModeInfo.getViewMode(ps)}
                const inflatedCompRef = refComponent.getUniqueRefCompPointer(ps, refComponentPtr, masterCompPtr)

                const rolePath = createRolePath(ps, inflatedCompRef, isComponentRefNotInflatedInternalRef(ps, refComponentPtr))

                return rolePath ? _.merge({rolePath}, override) : undefined
            })
            .compact()
            .value()
    }

    /**
     * Get ref primary connection items from overrides
     * @param ps
     * @param compRef - comp of type wysiwyg.viewer.components.RefComponent
     * @return connectionItems
     */
    function getPrimaryConnectionItems(ps, compRef) {
        if (component.getType(ps, compRef) !== REF_COMPONENT_TYPE) {
            return []
        }
        const [refConnectionItemPointer] = ps.pointers.referredStructure.getAllOverrides(compRef).filter(item => item.type === 'connections')
        const connectionPointerNoFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(refConnectionItemPointer)
        const refConnectionItems = _.get(dataModel.getDataByPointer(ps, 'connections', connectionPointerNoFallbacks), 'items', [])
        return refConnectionItems.filter(item => item.type === 'ConnectionItem' && item.isPrimary)
    }

    /**
     * Returns stageData of the ref direct appWidget child
     * @param ps
     * @param compRef - comp ref of type wysiwyg.viewer.components.RefComponent
     * @return stageData from manifest
     */
    function getStageData(ps, compRef) {
        const [connectionItem] = getPrimaryConnectionItems(ps, compRef)
        const role = _.get(connectionItem, 'role')
        const controllerRef = appControllerData.getControllerRefByConnectionItem(ps, connectionItem, compRef)
        return appControllerData.getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role)
    }

    /**
     * Get the remote structure of an app builder widget
     * @param ps
     * @param {string} appDefinitionId
     * @param {string} widgetId the widget id in dev center
     * @returns A promise which resolves to the widget structure
     */
    function getRemoteWidgetStructure(ps, appDefinitionId, widgetId) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

        if (!appData) {
            return Promise.reject(`App with id "${appDefinitionId}" not found`)
        }

        const widgetPageId = _.get(appData, ['widgets', widgetId, 'componentFields', 'appStudioFields', 'id'])

        if (!widgetPageId) {
            return Promise.reject(`widget with id "${widgetId}" not found for app with id "${appDefinitionId}"`)
        }

        return remoteStructureFetcher.getWidgetStructureByAppData(appData, widgetPageId)
    }

    /**
     * Get the remote app descriptor of an app builder application
     * @param ps
     * @param {string} appDefinitionId
     * @returns A promise which resolves to the app descriptor
     */
    function getAppDescriptor(ps, appDefinitionId) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

        if (!appData) {
            return Promise.reject(`App with id "${appDefinitionId}" not found`)
        }

        const siteHeaderUrl = _.get(appData, 'appFields.platform.studio.siteHeaderUrl')

        if (!siteHeaderUrl) {
            return Promise.reject(`${appDefinitionId} - not a valid blocks app`)
        }

        return remoteStructureFetcher.getAppDescriptor(siteHeaderUrl)
    }

    const getWidgetAppDefinitionId = (ps, widgetRef) => {
        const {applicationId: appDefinitionId} = appControllerDataItem.getControllerDataItem(ps, widgetRef)
        return appDefinitionId
    }

    const getProps = (ps, widgetRef) => appControllerData.getSettingsIn(ps, widgetRef, 'props') ?? {}

    const getPropsToSet = (ps, widgetRef, newProps) => {
        if (!_.isNil(newProps)) {
            const oldProps = getProps(ps, widgetRef)
            return {
                ...oldProps,
                ...newProps
            }
        }

        return null
    }

    const setProps = (ps, widgetRef, newProps, options = {}) => {
        const {shouldFetchData = false} = options

        const newPropsToSet = getPropsToSet(ps, widgetRef, newProps)
        appControllerData.setSettingsIn(ps, widgetRef, 'props', newPropsToSet)

        livePreview.debouncedRefresh(ps, {
            apps: [getWidgetAppDefinitionId(ps, widgetRef)],
            source: LIVE_PREVIEW_REFRESH_SOURCE,
            shouldFetchData
        })
    }

    function setInitialAppWidgetData(ps, widgetRef, controllerType) {
        const settings = {
            type: controllerType,
            behaviors: []
        }
        const data = {
            controllerType,
            settings: JSON.stringify(settings)
        }
        component.data.update(ps, widgetRef, data, true)
    }

    return {
        getPrimaryConnectionItems,
        getStageData,
        changePreset,
        getPreset,
        changeVariation,
        addWidget,
        buildRefWidgetStructure,
        getRemoteWidgetStructure,
        getAppDescriptor,
        getOverridesWithPrimaryRole,
        getComponentToAddRef,
        setInitialAppWidgetData,
        props: {
            get: getProps,
            set: setProps
        }
    }
})
