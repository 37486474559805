define(['lodash', 'componentsCore'], function (_, componentsCore) {
    'use strict'
    const {siteAspectsRegistry} = componentsCore

    class DocumentServicesAspect {
        constructor(aspectSiteApi) {
            if (!aspectSiteApi) {
                // for testing purposes
                return
            }
            this.siteAPI = aspectSiteApi
            /** @type {Function[]} */
            this.pageChangeCallbacks = []
            this.siteAPI.registerToUrlPageChange(this.handlePageChange.bind(this))
        }

        handlePageChange(evt) {
            _.forEach(this.pageChangeCallbacks, callback => {
                callback(evt)
            })
            this.pageChangeCallbacks = []
        }

        registerToUrlPageChange(callback) {
            this.pageChangeCallbacks.push(callback)
        }
    }

    siteAspectsRegistry.registerSiteAspect('DocumentServicesAspect', DocumentServicesAspect)
    return DocumentServicesAspect
})
