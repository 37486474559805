define([
    'experiment',
    '@wix/santa-core-utils',
    'documentServices/wixCode/utils/codeAppInfo',
    'documentServices/wixCode/services/wixCodeLifecycleService',
    'documentServices/wixCode/wixCode'
], function (experiment, coreUtilsLib, codeAppInfoUtils, wixCodeLifecycleService, wixCode) {
    'use strict'

    const installCollectionInWixData = async (ps, packageToken) => {
        await wixCodeLifecycleService.ensureAppIsWriteable(ps)
        const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
        return coreUtilsLib.requestsUtil.fetchJson('https://www.wix.com/_api/cloud-data-packaging/v1/install-data', {
            mode: 'cors',
            method: 'POST',
            credentials: 'include',
            headers: {Authorization: codeAppInfo.signedInstance},
            body: JSON.stringify({
                signedInstance: codeAppInfo.signedInstance,
                gridAppId: codeAppInfo.appId,
                packageToken
            })
        })
    }

    const getTokenFromMockedServer = async (appDefId, appVersion) => {
        const res = await coreUtilsLib.requestsUtil.fetchJson(`https://apps.wix.com/app-studio-server/getToken/${appDefId}/${appVersion}`)
        if (!res) {
            throw new Error('bad response')
        }
        return res.token
    }

    /**
     * @param {ps} ps
     * @returns {Promise<void>}
     */
    const provision = ps =>
        new Promise((res, rej) => {
            wixCode.provision(ps, {onSuccess: res, onError: rej})
        })

    /**
     * @param {ps} ps
     * @returns {Promise<void>}
     */
    const installWixDataIfNeeded = async ps => {
        if (!wixCodeLifecycleService.isProvisioned(ps)) {
            await provision(ps)
        }
    }

    const installCollections = async (ps, appDefId, appVersion) => {
        if (!experiment.isOpen('ds_provision_with_collections')) {
            return
        }
        const token = await getTokenFromMockedServer(appDefId, appVersion)
        if (token) {
            await installWixDataIfNeeded(ps)
            await installCollectionInWixData(ps, token)
        }
    }

    return {
        installCollections
    }
})
