import * as _ from 'lodash'
import * as conversionUtils from './conversionUtils'
import {conversionConfig} from './conversionConfig'
import * as mobileMerge from './mobileMerge/mobileMerge'
import {syncPage} from './mobileMerge/mobileSync'
import * as mergeUtils from './mobileMerge/mergeUtils'
import {rescaleComponent} from './structureConverter/structureConverter'
import {preProcessStructure} from './structurePreprocessor'
import {removeConversionDataRecursively, postProcessStructure} from './structurePostprocessor'
import {analyzeStructure} from './analyzer/structureAnalyzer'
import {convertMasterPage, fixMasterPageLayout, fixMasterPageLayoutAndOrder} from './masterPageConverter'
import {ComponentWithConversionData, MasterPageComponentWithConversionData, Component, MasterPageComponent, ObjMap, ConversionSettings} from '../types'

function synchronizeStructure(
    desktopPage: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobilePage: Component | MasterPageComponent
): Component[] {
    syncPage(desktopPage, mobilePage)
    mergeUtils.synchronizeDataQueries(desktopPage, mobilePage)
    if (mobilePage.id === 'masterPage') {
        const mobileComps = conversionUtils.getChildren(mobilePage)
        const desktopComps = conversionUtils.getChildren(desktopPage)
        _.forEach(mobileComps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        fixMasterPageLayout(mobileComps)
    }
    removeConversionDataRecursively(<ComponentWithConversionData | MasterPageComponentWithConversionData>mobilePage)
    return <Component[]>conversionUtils.getChildren(mobilePage)
}

export function synchronizePages(
    desktopPages: ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>,
    mobilePages: ObjMap<Component | MasterPageComponent>
): ObjMap<Component[]> {
    mergeUtils.deletePagesMissingInDesktop(desktopPages, mobilePages)
    return _.mapValues(desktopPages, (desktopPage, pageId) => {
        const mobilePage = mobilePages[pageId]
        return synchronizeStructure(
            <ComponentWithConversionData | MasterPageComponentWithConversionData>desktopPage,
            <ComponentWithConversionData | MasterPageComponentWithConversionData>mobilePage
        )
    })
}

export function convertPage(
    page: ComponentWithConversionData | MasterPageComponentWithConversionData,
    components: ComponentWithConversionData[],
    settings: ConversionSettings
): Component[] {
    inspectStructure(page, components, settings)
    if (conversionUtils.isMasterPage(page)) {
        convertMasterPage(<MasterPageComponentWithConversionData>page)
    } else {
        rescaleComponent(<ComponentWithConversionData>page, conversionConfig.MOBILE_WIDTH)
    }
    postProcessStructure(<ComponentWithConversionData>page, components)
    removeConversionDataRecursively(page)
    return <Component[]>conversionUtils.getChildren(page)
}

export function mergeStructure(
    desktopStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobileStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    settings: ConversionSettings
): Component[] {
    mergeUtils.synchronizeDataQueries(desktopStructure, mobileStructure)
    mobileMerge.mergeStructure(desktopStructure, mobileStructure, settings)
    if (mobileStructure.id === 'masterPage') {
        const comps = conversionUtils.getChildren(mobileStructure)
        const desktopComps = conversionUtils.getChildren(desktopStructure)
        _.forEach(comps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        if (settings.fixMasterPageStructureOrder) {
            fixMasterPageLayoutAndOrder(comps)
        } else {
            fixMasterPageLayout(comps)
        }
    }

    removeConversionDataRecursively(mobileStructure)
    return <Component[]>conversionUtils.getChildren(mobileStructure)
}

export function mergePages(
    desktopPages: ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>,
    mobilePages: ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>,
    settings: ConversionSettings
): ObjMap<Component[]> {
    mergeUtils.deletePagesMissingInDesktop(desktopPages, mobilePages)
    const mobileComponentsMap = _.mapValues(desktopPages, (desktopPage, pageId) => {
        const mobilePage = mobilePages[pageId]
        return mergeStructure(
            <ComponentWithConversionData | MasterPageComponentWithConversionData>desktopPage,
            <ComponentWithConversionData | MasterPageComponentWithConversionData>mobilePage,
            settings
        )
    })

    return mobileComponentsMap
}

export function inspectStructure(
    structure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    children: ComponentWithConversionData[],
    settings: ConversionSettings
): void {
    if (!settings.analyzeOnly) {
        preProcessStructure(structure, children, settings)
    }
    analyzeStructure(structure, settings)
}
