import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const checkerMap = {}
const allCompsOfTypeCheckerMap = {}
const appDefIdCheckerMap = {}

coreUtils.sessionFreezer.freeze(allCompsOfTypeCheckerMap)
coreUtils.sessionFreezer.freeze(checkerMap)
coreUtils.sessionFreezer.freeze(appDefIdCheckerMap)

const registerCheckerForCompType = (type, requestsGetter) => {
    checkerMap[type] = requestsGetter
}

/**
 *
 * @param {string} type
 * @param {function} requestGetter
 */
const registerCheckerForAllCompsOfType = (type, requestGetter) => {
    allCompsOfTypeCheckerMap[type] = requestGetter
}

/**
 *
 * @param {string} appDefId
 * @param {function} requestGetter
 */
const registerCheckerForAppDefId = (appDefId, requestGetter) => {
    appDefIdCheckerMap[appDefId] = requestGetter
}

const getAppDefIdCheckerMap = () => appDefIdCheckerMap

const getCheckerForCompType = type => checkerMap[type]

const getAllCompsOfTypeChecker = type => allCompsOfTypeCheckerMap[type]

export default {
    registerCheckerForAppDefId,
    registerCheckerForCompType,
    registerCheckerForAllCompsOfType,
    getAppDefIdCheckerMap,
    getAllCompsOfTypeChecker,
    getCheckerForCompType
}
