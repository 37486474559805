define([
    'lodash',
    'tpa',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/utils/utils',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/tpa/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, tpa, coreUtils, dsUtils, generalInfo, tpaConstants, dataModel, extensionsAPI) {
    'use strict'

    const isSiteSaved = function (ps) {
        return !generalInfo.isFirstSave(ps)
    }

    const isTpaByCompType = function (compType) {
        return _.includes(tpaConstants.COMP_TYPES, compType) || _.includes(tpaConstants.TPA_COMP_TYPES, compType)
    }

    const isTpaComp = (ps, compPointer) => {
        const componentType = dsUtils.getComponentType(ps, compPointer)
        return isTpaByCompType(componentType)
    }

    const isTpaByDataType = function (dataType) {
        return _.includes(tpaConstants.DATA_TYPE, dataType)
    }

    const sendBIEvent = function (ps, msg, origin) {
        const viewMode = ps.pointers.components.getViewMode(ps.siteAPI.getFocusedRootId())
        const pagePointer = ps.pointers.components.getPage(ps.siteAPI.getFocusedRootId(), viewMode)
        const compPointer = ps.pointers.components.getComponent(msg.compId, pagePointer)
        const compData = dataModel.getDataItem(ps, compPointer)
        const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
        tpa.common.bi.sendBIEvent(msg, origin, compData, clientSpecMap, ps.siteAPI.reportBI.bind(ps.siteAPI))
    }

    const getModalCompData = function (ps, compId) {
        const modalData = ps.siteAPI.getModalCompData()
        return compId === _.get(modalData, 'modalStructure.id') ? modalData.modalData : null
    }

    const getPopupCompData = function (ps, compId) {
        const popupsData = ps.siteAPI.getPopupsCompData()
        const popupData = _.find(popupsData, ['structure.id', compId])
        return popupData ? popupData.popupData : null
    }

    const notifyTPAAPICalledFromPanel = (ps, appDefinitionId) => {
        extensionsAPI.platformSharedState.notifyPlatformAPIWasCalled(ps, appDefinitionId, {})
    }

    return {
        isSiteSaved,
        isTpaComp,
        isTpaByCompType,
        isTpaByAppType: coreUtils.coreTpaUtils.isTpaByAppType,
        isTpaByDataType,
        sendBIEvent,
        getModalCompData,
        getPopupCompData,
        notifyTPAAPICalledFromPanel
    }
})
