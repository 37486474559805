define(['lodash', 'platformEvents'], function (_, platformEvents) {
    'use strict'

    const customEventsRegistry = {}
    const EVENTS_DEBOUNCE = 10
    const EVENTS_THROTTLE = 1000

    const throttleMiddlewares = [func => _.debounce(func, EVENTS_DEBOUNCE), func => _.throttle(func, EVENTS_THROTTLE)]

    const notifyAllAppsOnCustomEvent = (ps, appsToNotify, eventType, eventPayload, notifyAppsFunc) => {
        appsToNotify.forEach(applicationId =>
            notifyAppsFunc(ps, applicationId, {
                eventType,
                eventPayload
            })
        )
    }

    const getAppsRegisteredToEventType = eventType => (customEventsRegistry[eventType] ? Array.from(customEventsRegistry[eventType]) : null)

    const registerAppToEvents = (applicationId, eventTypes) => {
        _.forEach(eventTypes, type => {
            if (!customEventsRegistry[type]) {
                customEventsRegistry[type] = new Set()
            }
            customEventsRegistry[type].add(applicationId)
        })
    }

    const unRegisterAppFromEvents = (applicationId, eventTypes) => {
        eventTypes.forEach(eventType => {
            if (customEventsRegistry[eventType] && customEventsRegistry[eventType].has(applicationId)) {
                customEventsRegistry[eventType].delete(applicationId)
            }
        })
    }

    const originsToPropagate = new Set(['Editor1.4', 'editor_x', 'onboarding'])

    let propagateEvent = _.noop

    function init(ps) {
        if (originsToPropagate.has(ps.config.origin)) {
            propagateEvent = platformEvents.propagation.initSender().propagateEvent
        }
    }

    const notifyAppsOnCustomEvent = (ps, eventType, eventPayload, notifyAppsFunc) => {
        propagateEvent(eventType, eventPayload)
        const appsToNotify = getAppsRegisteredToEventType(eventType)
        if (!appsToNotify || !appsToNotify.length) {
            return
        }
        const throttledNotify = _.flow(throttleMiddlewares)(notifyAllAppsOnCustomEvent)
        return throttledNotify(ps, appsToNotify, eventType, eventPayload, notifyAppsFunc)
    }

    const propagateGeneralEvent = (applicationId, eventType, eventPayload) => {
        propagateEvent(eventType, eventPayload, {onlyTo: {applicationId}})
    }

    return {
        init,
        propagateGeneralEvent,
        notifyAppsOnCustomEvent,
        registerAppToEvents,
        getAppsRegisteredToEventType,
        unRegisterAppFromEvents
    }
})
