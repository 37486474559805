import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const WIXAPPS_DATA_TYPE = 'wixapps'

pointerGeneratorsRegistry.registerPointerType(WIXAPPS_DATA_TYPE, _.constant(null), _.constant(true))

const getterFunctions = {
    getCounterData(getItemAt, cache, packageName, type) {
        type = type || 'general'
        return cache.getPointer(`${WIXAPPS_DATA_TYPE}_${packageName}_${type}`, WIXAPPS_DATA_TYPE, ['wixappsRenderCounters', packageName, type])
    },
    getPackageCounterData(getItemAt, cache, packageName) {
        return cache.getPointer(`${WIXAPPS_DATA_TYPE}_${packageName}`, WIXAPPS_DATA_TYPE, ['wixappsRenderCounters', packageName])
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(WIXAPPS_DATA_TYPE, getterFunctions)
