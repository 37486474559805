//created By NirM :)
import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import jsonUpdaterRegistrar from './jsonUpdaterRegistrar'

function isCompDisplayed(nodeModes, overridingStructure) {
    let isDisplayed = true
    if (nodeModes) {
        const isDisplayedByDefault = !nodeModes.isHiddenByModes
        const isDisplayedOverridden = overridingStructure && !_.isUndefined(overridingStructure.isHiddenByModes)
        isDisplayed = isDisplayedOverridden ? !overridingStructure.isHiddenByModes : isDisplayedByDefault
    }
    return isDisplayed
}

function getRootActiveModesByPageId(activeModes, pageId) {
    activeModes = activeModes || {}
    return _.omitBy(activeModes[pageId], function (value) {
        return !value
    })
}

function isOverrideActive(override, pageActiveModes) {
    return (
        !_.isEmpty(pageActiveModes) &&
        _.every(override.modeIds, function (modeId) {
            return pageActiveModes[modeId]
        })
    )
}

function getOverridingStructure(overrides, pageActiveModes) {
    const activeOverridingStructure = _.transform(
        overrides,
        function (accumulator, override) {
            if (isOverrideActive(override, pageActiveModes)) {
                _.assign(accumulator, override)
            }
        },
        {}
    )
    // @ts-ignore
    delete activeOverridingStructure.modeIds
    return activeOverridingStructure
}

function getChildrenKey(compStructure, isMobile) {
    const key = isMobile && compStructure.mobileComponents ? 'mobileComponents' : 'children'
    return compStructure[key] ? key : 'components'
}

function generateDisplayedStructureFromComponent(pageActiveModes, compStructure, getNodeInfo?, pointersMapApi?, siteModel?) {
    const desktopChildrenKey = getChildrenKey(compStructure, false)
    const mobileChildrenKey = getChildrenKey(compStructure, true)
    const {modes} = compStructure
    const overrides = _.get(compStructure, ['modes', 'overrides'])
    // @ts-ignore
    let overridingStructure = getOverridingStructure(overrides || [], pageActiveModes, compStructure)

    if (!isCompDisplayed(modes, overridingStructure)) {
        return undefined
    }

    // @ts-ignore
    const displayedComp = warmupUtils.objectUtils.cloneDeep(_.omit(compStructure, ['modes', desktopChildrenKey, mobileChildrenKey]))

    const updaterPlugins = jsonUpdaterRegistrar.getCompPlugin(compStructure.componentType)
    if (updaterPlugins && getNodeInfo) {
        compStructure = updaterPlugins(compStructure, getNodeInfo, pointersMapApi, siteModel)
        displayedComp.layout = compStructure.layout
        displayedComp.componentType = compStructure.componentType
        displayedComp.skin = compStructure.skin
    }

    if (modes) {
        if (modes.overrides) {
            overridingStructure = _.omit(overridingStructure, 'isHiddenByModes')
            _.assign(displayedComp, overridingStructure)
        }

        if (modes.definitions) {
            _.set(displayedComp, ['modes', 'definitions'], modes.definitions)
        }
    }

    generateDisplayedStructureChildren(displayedComp, desktopChildrenKey, pageActiveModes, compStructure, getNodeInfo, pointersMapApi, siteModel)
    if (desktopChildrenKey !== mobileChildrenKey) {
        generateDisplayedStructureChildren(displayedComp, mobileChildrenKey, pageActiveModes, compStructure, getNodeInfo, pointersMapApi, siteModel)
    }

    return displayedComp
}

function generateDisplayedStructureChildren(displayedComp, childrenKey, pageActiveModes, compStructure, getNodeInfo, pointersMapApi, siteModel) {
    if (!_.isUndefined(compStructure[childrenKey])) {
        displayedComp[childrenKey] = _(compStructure[childrenKey])
            .map(function (childStructure) {
                return generateDisplayedStructureFromComponent(pageActiveModes, childStructure, getNodeInfo, pointersMapApi, siteModel)
            })
            .compact()
            .value()
    }
}

function generateDisplayedJsonFromPage(fullPage, activeModes, getNodeInfo, pointersMapApi, siteModel?) {
    const rootId = fullPage.structure.id
    const rootActiveModes = getRootActiveModesByPageId(activeModes, rootId)
    // @ts-ignore
    const displayedPage = warmupUtils.objectUtils.cloneDeep(_.omit(fullPage, ['structure', 'data']))
    displayedPage.structure = generateDisplayedStructureFromComponent(rootActiveModes, fullPage.structure, getNodeInfo, pointersMapApi, siteModel)
    // @ts-ignore
    displayedPage.data = warmupUtils.objectUtils.cloneDeep(fullPage.data)
    return displayedPage
}

function generateDisplayedFromPagesData(pagesData, activeModes, getNodeInfo, pointersMapApi) {
    activeModes = activeModes || {}
    const pagesDataToReturn = {}
    _.forEach(pagesData, function (root, rootId) {
        pagesDataToReturn[rootId] = generateDisplayedJsonFromPage(root, activeModes, getNodeInfo, pointersMapApi)
    })
    return pagesDataToReturn
}

function isPageJson(jsonFragment) {
    return !_.isUndefined(jsonFragment.structure)
}

function isMasterPageStructure(jsonFragment) {
    return _.get(jsonFragment, ['type']) === 'Document'
}

function isComponentJson(jsonFragment) {
    return !_.isUndefined(jsonFragment.componentType)
}

/**
 * @class fullToDisplayed
 */
export default {
    /**
     *
     * @param fullJsonFragment
     * @param {{}} activeModes
     * @param {string} [rootId]
     * @param getNodeInfo
     * @param pointersMapApi
     * @param siteModel
     * @returns {{structure: *, data: *}}
     */
    getDisplayedJson(fullJsonFragment, activeModes, rootId, getNodeInfo, pointersMapApi, siteModel) {
        let displayedJson = {}

        if (fullJsonFragment) {
            if (isComponentJson(fullJsonFragment) || isMasterPageStructure(fullJsonFragment)) {
                // page-component or component
                const rootActiveModes = getRootActiveModesByPageId(activeModes, rootId)
                // @ts-ignore
                displayedJson.structure = generateDisplayedStructureFromComponent(rootActiveModes, fullJsonFragment, getNodeInfo, pointersMapApi, siteModel)
            } else if (isPageJson(fullJsonFragment)) {
                // page
                displayedJson = generateDisplayedJsonFromPage(fullJsonFragment, activeModes, getNodeInfo, pointersMapApi, siteModel)
            } else {
                // pages data
                displayedJson = generateDisplayedFromPagesData(fullJsonFragment, activeModes, getNodeInfo, pointersMapApi)
            }
        }
        return displayedJson
    },

    applyModesOnSerializedStructure(fullSerializedJSON, activeModes) {
        activeModes = activeModes || {}
        return generateDisplayedStructureFromComponent(activeModes, fullSerializedJSON)
    }
}
