define(['wix-ui-santa/baseUIDSComponentsService.bundle', 'documentServices/dsCompRegistrar/dsCompRegistrar', 'experiment'], function (
    baseUIDSCompsService,
    dsCompRegistrar,
    experiment
) {
    'use strict'

    const getBaseUrl = () => `${requirejs.toUrl('wix-ui-santa')}/`

    /**
     * @memberOf appStudioComponents
     * @function loadAndRegisterAppStudioComponents
     * @param {ps} ps
     * @return {Promise<string|any[]>|undefined}
     */
    const loadAndRegisterAppStudioComponents = ps => {
        if (experiment.isOpen('sv_appStudioWithBaseUI')) {
            const loadedBaseUIDSCompsPromise = baseUIDSCompsService
                .loadAll(getBaseUrl())
                .then(components => components.forEach(component => dsCompRegistrar.registerComponent(ps, component)))
            return Promise.all([loadedBaseUIDSCompsPromise]).catch(error => `Error: could not load base ui DS components. ${error}`)
        }
    }
    return {
        loadAndRegisterAppStudioComponents
    }
})
