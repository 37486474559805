import _ from 'lodash'
import * as mobx from 'mobx'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {DESKTOP} = coreUtils.constants.VIEW_MODES
const {MOBILE} = coreUtils.constants.VIEW_MODES

export default function transform(siteData, path, value) {
    if (path.length > 1 || !_.get(value, ['compsToHide'])) {
        return value
    }

    const transformedRenderRealtimeConfig = _.defaults(
        {
            compsToHide: {
                // @ts-ignore
                [DESKTOP]: mobx.observable.shallowMap(value.compsToHide[DESKTOP], 'compsToHideDesktop'),
                // @ts-ignore
                [MOBILE]: mobx.observable.shallowMap(value.compsToHide[MOBILE], 'compsToHideMobile')
            }
        },
        value
    )

    return transformedRenderRealtimeConfig
}
