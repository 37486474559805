import optionInput from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/optionInput'

/**
 * @class components.TextOption
 * @extends {optionInput}
 * @property {comp.properties} props
 */
export default {
    displayName: 'TextOption',
    mixins: [optionInput],

    getSkinProperties() {
        const refData = {
            size: {
                children: this.props.compData.text
            },
            tooltip: this.createInfoTipChildComponent()
        }

        // set a click callback only when the component is enabled.
        if (!this.props.compData.disabled) {
            refData[''] = {
                onClick: this.props.onClick,
                onMouseEnter: this.onMouseEnter,
                onMouseLeave: this.onMouseLeave
            }
        }
        return refData
    }
}
