define([
    'lodash',
    'documentServices/namespaces/namespaces',
    'documentServices/constants/constants',
    'documentServices/variants/relationsUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/triggers/triggers'
], (_, namespaces, constants, relationsUtils, dataModel, triggers) => {
    const {REACTIONS, DATA_TYPES, VARIANTS, RELATION_DATA_TYPES} = constants
    const REACTIONS_TYPE = REACTIONS.TYPE
    const {CHILDREN_VARIANT_TO_SERIALIZE} = VARIANTS

    /**
     * Removes all reactions from a given component
     *
     * @param {ps} ps
     * @param {Pointer} compPointerWithVariants
     */
    const removeAllReactionsDataFromComp = (ps, compPointerWithVariants) => {
        const hasReactions = namespaces.getNamespaceData(ps, compPointerWithVariants, DATA_TYPES.reactions)

        if (hasReactions) {
            namespaces.removeComponentNamespaceData(ps, compPointerWithVariants, DATA_TYPES.reactions)
        }
    }

    /**
     * Returns all reactions that reference the given state
     *
     * @param {ps} ps
     * @param {Pointer} statePointer
     * @returns {Array} Array of reactions that reference the given state
     */
    const getReactionsByState = (ps, statePointer) => {
        const pageId = ps.pointers.data.getPageIdOfData(statePointer)
        return ps.pointers.data.getReactionItemsWithPredicate({state: `#${statePointer.id}`}, pageId)
    }

    /**
     * Removes a reaction from the dal along with all its relations
     *
     * @param {ps} ps
     * @param {Pointer} reactionPointer
     * @param {string} pageId
     */
    const removeReaction = (ps, reactionPointer, pageId) => {
        if (!ps.dal.isExist(reactionPointer)) {
            return
        }

        const reactionListPointer = _.head(
            ps.pointers.data.getReactionItemsWithPredicate(item => item.type === REACTIONS_TYPE && item.values.includes(`#${reactionPointer.id}`), pageId)
        )
        const reactionsArray = ps.dal.get(reactionListPointer)

        reactionsArray.values = _.without(reactionsArray.values, `#${reactionPointer.id}`)

        if (_.isEmpty(reactionsArray.values)) {
            const relationToRemove = _.head(
                ps.pointers.data.getReactionItemsWithPredicate({type: RELATION_DATA_TYPES.VARIANTS, to: `#${reactionsArray.id}`}, pageId)
            )
            relationsUtils.removeRelation(ps, relationToRemove, DATA_TYPES.reactions, pageId)
        } else {
            ps.dal.set(reactionListPointer, reactionsArray)
            ps.dal.remove(reactionPointer)
        }
    }

    const isValidTrigger = (ps, triggerPointer) => ps.dal.isExist(triggerPointer) && ps.dal.get(triggerPointer).type === 'Trigger'
    const isValidState = (ps, statePointer) => ps.dal.isExist(statePointer) && ps.dal.get(statePointer).type === 'State'

    /**
     * Removes the reactions that have a reference to the provided trigger pointer
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} triggerPointer
     */
    const removeReactionsByTrigger = (ps, componentPointer, triggerPointer) => {
        if (isValidTrigger(ps, triggerPointer)) {
            const pageId = ps.pointers.data.getPageIdOfData(triggerPointer)
            const variantRelations = relationsUtils.getRelationsByVariantsAndPredicate(ps, [triggerPointer], DATA_TYPES.reactions, false)

            _.forEach(variantRelations, relation => {
                relationsUtils.removeRelation(ps, relation, DATA_TYPES.reactions, pageId)
            })
        }
    }

    /**
     * Removes the reactions that have a reference to the provided state pointer
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} statePointer
     */
    const removeReactionsByState = (ps, componentPointer, statePointer) => {
        if (isValidState(ps, statePointer)) {
            const pageId = ps.pointers.data.getPageIdOfData(statePointer)
            const allReactionsWithThisState = getReactionsByState(ps, statePointer)

            _.forEach(allReactionsWithThisState, reactionPointer => {
                removeReaction(ps, reactionPointer, pageId)
            })
        }
    }

    const serializeVariantsOnParentComponentIfNeeded = (ps, compPointer, componentStructure, flags, pageId) => {
        const children = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
        const variantsToSerialize = _.reduce(
            children,
            (result, comp) => {
                const affectingVaraints = relationsUtils.getAllAffectingVariantsGroupedByVariantType(ps, comp)
                const affectingVaraintsArr = _(affectingVaraints).pick(CHILDREN_VARIANT_TO_SERIALIZE).values().flattenDeep().value()
                result.push(...affectingVaraintsArr)
                return result
            },
            []
        )

        const variantIds = _(variantsToSerialize)
            .filter(variantPtr => {
                const variant = ps.dal.get(variantPtr)
                return _.some(children, child => child.id === variant.componentId)
            })
            .map('id')
            .value()

        if (!_.isEmpty(variantIds)) {
            dataModel.serializeVariantsData(ps, componentStructure, flags.maintainIdentifiers, variantIds, pageId)
        }
    }

    const getComponentByReaction = (ps, reactionPointer, pageId) => {
        const reactionListPointer = _.head(
            ps.pointers.data.getReactionItemsWithPredicate(item => item.type === REACTIONS_TYPE && item.values.includes(`#${reactionPointer.id}`), pageId)
        )
        const relationPointer = _.head(
            ps.pointers.data.getReactionItemsWithPredicate({type: RELATION_DATA_TYPES.VARIANTS, to: `#${reactionListPointer.id}`}, pageId)
        )
        const relationData = ps.dal.get(relationPointer)
        return relationsUtils.getComponentFromRelation(ps, relationData, pageId)
    }

    const removeReactionsIfNeeded = (ps, componentPointer, newContainerPointer) => {
        const currentPage = ps.pointers.components.getPageOfComponent(componentPointer)
        const newPage = ps.pointers.components.getPageOfComponent(newContainerPointer)
        if (newPage.id === currentPage.id) {
            return
        }

        const getRelationsOfOtherCompsByTrigger = triggerPointer =>
            relationsUtils.getRelationsByVariantsAndPredicate(
                ps,
                [triggerPointer],
                DATA_TYPES.reactions,
                false,
                item => item.from !== `#${componentPointer.id}`
            )

        const allCompTriggers = triggers.getAllTriggers(ps, componentPointer)
        const relationsToRemove = allCompTriggers.map(triggerPointer => getRelationsOfOtherCompsByTrigger(triggerPointer)).flat()
        relationsToRemove.forEach(relation => relationsUtils.removeRelation(ps, relation, DATA_TYPES.reactions, currentPage.id))
    }

    return {
        getReactionsByState,
        getComponentByReaction,
        serializeVariantsOnParentComponentIfNeeded,
        removeAllReactionsDataFromComp,
        removeReactionsByTrigger,
        removeReactionsByState,
        removeReaction,
        removeReactionsIfNeeded
    }
})
