import _ from 'lodash'
import $ from 'zepto'
import experiment from 'experiment'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import biEvents from '../bi/events.json'
import rootLayoutUtils from '../util/rootLayoutUtils'
import layout from '../util/layout'
import warmupUtilsLib from '@wix/santa-core-utils'

const {references} = rootLayoutUtils
const {measureNodeImage} = rootLayoutUtils
const {reportBI} = warmupUtils.loggingUtils.logger
const backgroundPart = 'balata'
const backgroundSelector = id => id + backgroundPart
const componentsWithBackground = {
    'wysiwyg.viewer.components.MediaPlayer': backgroundSelector,
    'wysiwyg.viewer.components.SiteBackground': '',
    'wysiwyg.viewer.components.Column': backgroundSelector,
    'wysiwyg.viewer.components.HoverBox': backgroundSelector,
    'wysiwyg.viewer.components.MediaContainer': backgroundSelector,
    'wysiwyg.viewer.components.RichContainer': backgroundSelector,
    'wysiwyg.viewer.components.MediaBox': backgroundSelector,
    'wysiwyg.viewer.components.StripColumnsContainer': backgroundSelector,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': backgroundSelector,
    'wysiwyg.viewer.components.BoxSlideShowSlide': backgroundSelector,
    'wysiwyg.viewer.components.StateBoxState': backgroundSelector,
    'wysiwyg.viewer.components.StateBoxFormState': backgroundSelector,
    'wysiwyg.viewer.components.StateStripState': backgroundSelector,
    'wysiwyg.viewer.components.PopupContainer': backgroundSelector,
    'wysiwyg.viewer.components.MenuContainer': backgroundSelector,
    'mobile.core.components.Page': (id, nodesMap) => {
        const isPagePopup = nodesMap[id].getAttribute('data-ispopup') === 'true'
        return isPagePopup ? id + backgroundPart : ''
    }
}

const getViewBox = ({x, y, width, height}) => `${x} ${y} ${width} ${height}`

const isBolt = () => typeof window === 'object' && _.get(window, 'wixBiSession.renderType') === 'bolt'

function getComputedProperties(imageInfo, siteData, nodeType) {
    const devicePixelRatio = _.get(imageInfo.imageData, 'devicePixelRatio', siteData.mobile.getDevicePixelRatio())
    return warmupUtilsLib.imageUtils.getImageComputedProperties(
        imageClientLib,
        imageInfo,
        siteData.getMediaFullStaticUrl.bind(siteData),
        siteData.currentUrl,
        devicePixelRatio,
        nodeType
    )
}

function patchImagePart(id, patchers, imageComputedProperties) {
    patchers.css(id + references.IMAGE_REF, _.get(imageComputedProperties, ['css', 'img']))
}

function patchSvgPart(id, patchers, measureMap, imageInfo, alignType, svgComputedProperties) {
    const isSvgImage = measureMap.custom[id] && measureMap.custom[id].isSvgImage
    const filterId = measureMap.custom[id] && measureMap.custom[id].filterId
    const svgWidth = imageInfo.containerWidth
    const svgHeight = imageInfo.containerHeight

    if (!imageInfo.containerWidth || !imageInfo.containerHeight || !imageInfo.imageData.uri) {
        return null
    }

    patchers.css(id + references.SVG_REF, {
        width: svgWidth,
        height: svgHeight
    })

    if (isSvgImage) {
        patchers.attr(id + references.SVG_REF, svgComputedProperties.attr.container)

        if (measureMap.custom[id].hasPattern) {
            const rectAttrs: Record<string, any> = {
                width: svgComputedProperties.attr.container.width,
                height: svgComputedProperties.attr.container.height,
                x: 0,
                y: 0
            }

            if (filterId) {
                // TODO - Tom B. 22/06/2017 remove this when moving to react 15
                rectAttrs.filter = `url(#${filterId})`
            }

            patchers.attr(id + references.RECT_REF, rectAttrs)
            patchers.attr(id + references.PATTERN_REF, svgComputedProperties.attr.img)
            patchers.attr(id + references.IMAGE_REF, {
                width: svgComputedProperties.attr.img.width,
                height: svgComputedProperties.attr.img.height
            })
        } else {
            patchers.attr(
                id + references.IMAGE_REF,
                _.assign(
                    filterId
                        ? {
                              filter: `url(#${filterId})` // TODO - Tom B. 22/06/2017 remove this when moving to react 15
                          }
                        : {},
                    svgComputedProperties.attr.img
                )
            )
        }

        if (measureMap.custom[id].hasMask) {
            const bbox = measureMap.custom[id].bBox
            const flip = _.get(imageInfo.imageData, ['crop', 'flip'], '')

            patchers.attr(id + references.MASK_SVG_REF, {
                viewBox: getViewBox(bbox)
            })

            const flipAttr = getMaskFlipTransformAttr(flip, {width: svgWidth, height: svgHeight})

            patchers.attr(id + references.MASK_USE_REF, flipAttr)
        }
    }
}

function getMaskFlipTransformAttr(flip, dimensions) {
    let transform = ''
    const {width, height} = dimensions
    switch (flip) {
        case 'x':
            transform = `translate(0 ${height}) scale(1 -1)`
            break
        case 'y':
            transform = `translate(${width} 0) scale(-1 1)`
            break
        case 'xy':
            transform = `translate(${width} ${height}) scale(-1 -1)`
            break
    }
    return {transform: transform || null}
}

function getDefaultStyles(style) {
    return _.pickBy(style, function (value) {
        return value !== ''
    })
}

function getContainerStyle(imageComputedProperties, style, containerSize, opacity) {
    return _.assign(
        getDefaultStyles(style),
        containerSize
            ? {
                  width: containerSize.width,
                  height: containerSize.height
              }
            : {},
        _.isNumber(opacity) ? {opacity} : {},
        _.omit(imageComputedProperties.css.container, 'position')
    )
}

function cssStringToObject(styleStr) {
    if (!styleStr || !styleStr.split) {
        return {}
    }

    return styleStr.split(';').reduce(function (ruleMap, ruleString) {
        const rulePair = ruleString.split(':')
        if (rulePair[0] && rulePair[1]) {
            ruleMap[rulePair[0].trim()] = rulePair[1].trim()
        }

        return ruleMap
    }, {})
}

function sendBiEvent(biService, siteData, imageInfo, imageToLoad, imageComputedProperties) {
    // should send Bi Event if we are on viewer, url includes upscale value lg_
    const upscaleMatcher = /,lg_(\d)/
    const upscaleResult = imageComputedProperties.uri.match(upscaleMatcher)
    const shouldSendBiEvent = siteData.isViewerMode() && imageComputedProperties.uri !== imageToLoad.currentSrc && upscaleResult
    if (shouldSendBiEvent) {
        biService.reportBI(biEvents.IMAGE_UPSCALING, {
            originalWidth: imageInfo.imageData.width,
            originalHeight: imageInfo.imageData.height,
            targetWidth: Math.round(imageInfo.containerWidth),
            targetHeight: Math.round(imageInfo.containerHeight),
            upscaleMethod: upscaleResult[1] === '1' ? 'classic' : 'super',
            devicePixelRatio: Math.floor(siteData.mobile.getDevicePixelRatio() * 100),
            url: imageToLoad.src
        })
    }
}

/**
 *
 * @param id
 * @param patchers
 * @param measureMap
 * @param {core.SiteData} siteData
 * @param imageData
 * @param alignType
 * @param containerSize
 */
function patchNodeImage(id, patchers, measureMap, siteData, imageData, containerSize, alignType?) {
    const style = cssStringToObject(measureMap.custom[id].renderedStyles)
    const isZoomed = measureMap.custom[id] && measureMap.custom[id].isZoomed
    const mergedContainerSize = containerSize || {width: measureMap.width[id], height: measureMap.height[id]}

    const imageInfo = {
        imageData,
        containerWidth: isZoomed ? imageData.width : mergedContainerSize.width,
        containerHeight: isZoomed ? imageData.height : mergedContainerSize.height,
        displayMode: imageData.displayMode,
        alignType
    }
    let imageComputedProperties

    if (measureMap.custom[id].isSvgImage) {
        imageComputedProperties = getComputedProperties(imageInfo, siteData, 'svg')
        patchSvgPart(id, patchers, measureMap, imageInfo, alignType, imageComputedProperties)
    } else {
        imageComputedProperties = getComputedProperties(imageInfo, siteData, 'img')
        patchImagePart(id, patchers, imageComputedProperties)
    }

    const containerStyle = getContainerStyle(imageComputedProperties, style, containerSize, imageData.opacity)
    patchers.css(id, containerStyle)

    const imageToLoad = {
        patchers,
        id,
        isSvg: measureMap.custom[id].isSvgImage,
        src: _.get(imageComputedProperties, 'uri'), //this was always like this (using _.get) and it looks like videoThumb images for wixapps explodes here since they dont have a uri -> no imageTransformProps
        absoluteTop: measureMap.absoluteTop[measureMap.custom[id].parentId] || 0, // ||parentId is a non structure component (wixapps) -> absoluteTop is 0 for now
        height: mergedContainerSize.height || measureMap.height[id],
        currentSrc: measureMap.custom[id].imgSrc
    }
    const biService = {reportBI: reportBI.bind(null, siteData)}
    sendBiEvent(biService, siteData, imageInfo, imageToLoad, imageComputedProperties)
    siteData.imageLoader.loadImage(imageToLoad, siteData)
}

function layoutChildNodeImages(id, nodesMap, measureMap, siteData, structureInfo) {
    const backgroundSelectorFunc = componentsWithBackground[structureInfo.structure.componentType] || ''
    const backgroundId = !isBolt() && backgroundSelectorFunc && backgroundSelectorFunc(id, nodesMap)
    const node = nodesMap[backgroundId || id]
    const wixImages = node ? node.querySelectorAll('*[data-image-info]') : []

    const ids = []
    const imageInfoMap = {}

    _.forEach(wixImages, function (image) {
        const imageId = image.getAttribute('id')
        nodesMap[imageId] = image
        const imageInfo = $(image).data('imageInfo')
        imageInfo.imageData.displayMode = imageInfo.displayMode
        imageInfoMap[imageId] = imageInfo
        measureNodeImage(imageId, measureMap, nodesMap, structureInfo)
        ids.push(imageId)
    })

    const patcher = patchers => {
        _.forEach(ids, function (imageId) {
            const imageInfo = imageInfoMap[imageId]
            patchNodeImage(imageId, patchers, measureMap, siteData, imageInfo.imageData, null, imageInfo.alignType)
        })
    }

    return patcher
}

function getAutoLayoutCompTypesToRegister() {
    const wixImageCompsPhase1 = ['wysiwyg.viewer.components.LinkBar', 'wysiwyg.viewer.components.FlashComponent']

    const wixImageCompsPhase2 = ['wysiwyg.viewer.components.WPhoto']

    if (!isBolt()) {
        Object.keys(componentsWithBackground).forEach(compType => {
            if (compType !== 'mobile.core.components.Page') {
                wixImageCompsPhase2.push(compType)
            }
        })
    }

    if (typeof window === 'object' && _.get(window, 'wixBiSession.renderType', 'santa') === 'santa') {
        return _.concat(wixImageCompsPhase1, wixImageCompsPhase2)
    }

    return _.concat(experiment.isOpen('bv_wixImage') ? [] : wixImageCompsPhase1, experiment.isOpen('bv_wixImagePhaseTwo') ? [] : wixImageCompsPhase2)
}

layout.registerCustomMeasure('core.components.Image', measureNodeImage)
_.forEach(getAutoLayoutCompTypesToRegister(), compType => layout.registerCustomLayoutFunction(compType, layoutChildNodeImages))

export default {
    patchNodeImage,
    layoutChildNodeImages,
    measureNodeImage
}
