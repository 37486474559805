import type {DocumentServicesImplementation, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {boundingLayout} from '@wix/santa-core-utils'
const {getBoundingY, getBoundingHeight} = boundingLayout

const isAnchoredToPage = (ps: PS, compPointer: Pointer, pageId: string) => {
    const compAnchors = ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout', 'anchors']))
    return _.some(compAnchors, {targetComponent: pageId, type: 'BOTTOM_PARENT'})
}

const miniPackText = (ps: PS, implementation: DocumentServicesImplementation, textComponentDescriptors: ComponentDescriptor[] = []) => {
    const {page, dataModel} = implementation
    let properties
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    const getMinSize = (compId: string, minSizeKey: string) => measureMap[minSizeKey][compId]
    if (!ps.siteAPI.hasPendingFonts()) {
        textComponentDescriptors.forEach(({pointer: textCompPointer, pageId}) => {
            properties = dataModel.getPropertiesItem(ps, textCompPointer) || dataModel.createPropertiesItemByType(ps, 'WRichTextProperties')

            const isVerticalText = properties.verticalText
            const sizeKey = isVerticalText ? 'width' : 'height'
            const minSizeKey = isVerticalText ? 'minWidth' : 'minHeight'

            if (!properties.packed && !properties[minSizeKey]) {
                const layoutPointer = ps.pointers.getInnerPointer(textCompPointer, ['layout'])
                ps.dal.merge(layoutPointer, {[sizeKey]: getMinSize(textCompPointer.id, minSizeKey)})
                dataModel.updatePropertiesItem(ps, textCompPointer, {...properties, packed: true})
                if (isAnchoredToPage(ps, textCompPointer, pageId)) {
                    //TODO: fixme - if we removed anchors, need to see that this has a 'runtime' anchor to page
                    const pageRef = ps.pointers.components.getPageOfComponent(textCompPointer)
                    const {height} = ps.dal.get(ps.pointers.getInnerPointer(pageRef, 'layout'))
                    page.properties.update(ps, pageRef, {minHeight: height})
                }
            }
            if (properties.packed && properties[minSizeKey]) {
                dataModel.updatePropertiesItem(ps, textCompPointer, {...properties, packed: false})
            }
        })
    }
}

const fixAnchorComponentParent = (ps: PS, implementation: DocumentServicesImplementation, anchorComponentDescriptors: ComponentDescriptor[] = []) => {
    const {structure} = implementation
    anchorComponentDescriptors
        .filter(({pointer: anchorPointer}) => {
            const parent = ps.pointers.components.getParent(anchorPointer)
            const parentTypePointer = ps.pointers.getInnerPointer(parent, 'componentType')
            return parent && ps.dal.get(parentTypePointer) === 'wysiwyg.viewer.components.Column'
        })
        .forEach(({pointer: anchorPointer}) => structure.reparentComponentToPage(ps, anchorPointer, true))
}

function getBottomTopToPages(compAnchors: any) {
    return _.find(compAnchors, {
        targetComponent: 'PAGES_CONTAINER',
        type: 'BOTTOM_TOP'
    })
}

function getDistanceFromHeaderToPagesFromAnchors(ps: PS, masterPageComps: any, masterPageLayoutPointer: Pointer) {
    const masterPageLayout = ps.dal.get(masterPageLayoutPointer)
    const anchor = masterPageLayout.anchors && _.find(masterPageLayout.anchors, {targetComponent: 'PAGES_CONTAINER'})
    if (anchor) {
        return anchor.distance
    }
    const masterPageCompsLayouts = _.map(masterPageComps, compPointer => {
        const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        return ps.dal.get(compLayoutPointer)
    })
    const bottomMostCompLayoutAbovePagesContainer = _.maxBy(masterPageCompsLayouts, compLayout => {
        if (getBottomTopToPages(compLayout.anchors)) {
            return getBoundingY(compLayout) + getBoundingHeight(compLayout)
        }

        return -10000
    })
    const bottomMostAnchorToPagesContainer = bottomMostCompLayoutAbovePagesContainer && getBottomTopToPages(bottomMostCompLayoutAbovePagesContainer.anchors)

    if (!bottomMostAnchorToPagesContainer) {
        return 0
    }
    const bottomOfClosestCompAbovePagesContainer =
        getBoundingY(bottomMostCompLayoutAbovePagesContainer) + getBoundingHeight(bottomMostCompLayoutAbovePagesContainer)
    const deducedPagesContainerTop: number = bottomMostAnchorToPagesContainer.distance + bottomOfClosestCompAbovePagesContainer

    const headerPointer = _.find(masterPageComps, {id: 'SITE_HEADER'})
    const headerLayoutPointer = ps.pointers.getInnerPointer(headerPointer, 'layout')
    const headerLayout = ps.dal.get(headerLayoutPointer)
    return deducedPagesContainerTop - (headerLayout.y + headerLayout.height)
}

function updateAnchorToPagesContainer(ps: PS) {
    const masterPagePointer = ps.pointers.components.getMasterPage('DESKTOP')
    const masterPageComps = ps.pointers.components.getChildren(masterPagePointer)
    const pagesContainerPointer = _.find(masterPageComps, {id: 'PAGES_CONTAINER'})
    const pagesContainerTopPointer = ps.pointers.getInnerPointer(pagesContainerPointer as Pointer, 'layout.y')
    const pagesContainerTop = ps.dal.full.get(pagesContainerTopPointer)
    const masterPageLayoutPointer = ps.pointers.getInnerPointer(masterPagePointer, 'layout')
    const headerPointer = _.find(masterPageComps, {id: 'SITE_HEADER'})
    const headerLayoutPointer = ps.pointers.getInnerPointer(headerPointer as Pointer, 'layout')
    const headerLayout = ps.dal.full.get(headerLayoutPointer)
    const distanceFromHeaderToPages = pagesContainerTop - (headerLayout.y + headerLayout.height)

    const isLandingPage = distanceFromHeaderToPages < 0
    const updatedAnchor: {
        targetComponent: string
        type: string
        locked: boolean
        distance: number | undefined
    } = {
        targetComponent: 'PAGES_CONTAINER',
        type: 'BOTTOM_TOP',
        locked: false,
        distance: undefined
    }
    if (isLandingPage) {
        updatedAnchor.distance = getDistanceFromHeaderToPagesFromAnchors(ps, masterPageComps, masterPageLayoutPointer)
    } else {
        updatedAnchor.distance = distanceFromHeaderToPages
    }

    const currentAnchor = _.get(ps.dal.get(masterPageLayoutPointer), ['anchors', '0'])
    if (_.isEqual(currentAnchor, updatedAnchor)) {
        return
    }

    ps.dal.full.merge(masterPageLayoutPointer, {
        anchors: [updatedAnchor]
    })
}

/**
 * @param {ps} ps
 * @param compPointers
 * @returns {boolean}
 */
function removeCompsJsonAnchors(ps: PS, compPointers: any) {
    let res = false
    _(compPointers)
        .map(compPointer => ps.pointers.getInnerPointer(compPointer, ['layout', 'anchors']))
        .filter(ps.dal.full.isExist)
        .forEach(anchorsPointer => {
            res = true
            ps.dal.full.remove(anchorsPointer)
        })
    return res
}

const removeJsonAnchors = (ps: PS, implementation: DocumentServicesImplementation, componentDescriptorsMap: any) => {
    const allPages = ps.siteAPI.getAllPagesIds(true)
    const pagesToLayoutedComponents = _.groupBy(componentDescriptorsMap, component => _.get(component, ['pageId']))
    const pageComponentInfosByPageId = _.pick(pagesToLayoutedComponents, allPages)

    const isMobileView = ps.siteAPI.isMobileView()
    const rootsForAnchorsRecreation: string[] = []

    pageComponentInfosByPageId.masterPage = pageComponentInfosByPageId.masterPage || []

    _.forEach(pageComponentInfosByPageId, (compInfosInPage, pageId) => {
        if (pageId === 'masterPage') {
            const masterPagePointer = ps.pointers.components.getMasterPage(implementation.documentMode.getViewMode(ps))
            const allMasterPageChildren = ps.pointers.components.getChildrenRecursively(masterPagePointer)

            compInfosInPage = compInfosInPage.concat(
                allMasterPageChildren.map(child => ({
                    pointer: child,
                    componentType: implementation.component.getType(ps, child),
                    pageId: 'masterPage'
                }))
            ) as [any, any[]]

            if (!isMobileView) {
                updateAnchorToPagesContainer(ps)
            }
        }
        const anchorsWereRemoved = removeCompsJsonAnchors(ps, _.map(compInfosInPage, 'pointer'))
        if (anchorsWereRemoved) {
            rootsForAnchorsRecreation.push(pageId)
        }
    })
}

const RichText = 'wysiwyg.viewer.components.WRichText'
const Anchor = 'wysiwyg.common.components.anchor.viewer.Anchor'

const COMPONENT_SPECIFIC_MIGRATORS = {
    [RichText]: miniPackText,
    [Anchor]: fixAnchorComponentParent
}

interface ComponentDescriptor {
    pointer: Pointer
    componentType: string
    pageId: string
}

export const runMigrators = (implementation: DocumentServicesImplementation, ps: PS, componentDescriptorsMap: Record<string, ComponentDescriptor>) => {
    const {sectionsLayoutMigrator} = implementation
    const compDescriptorsByCompType = _.groupBy(componentDescriptorsMap, 'componentType')

    // Mesh Migrator
    removeJsonAnchors(ps, implementation, componentDescriptorsMap)

    // Component Specific Migrators
    _.forOwn(COMPONENT_SPECIFIC_MIGRATORS, (migrator, componentType) => migrator(ps, implementation, compDescriptorsByCompType[componentType]))

    sectionsLayoutMigrator(ps)
}
