define([], function () {
    const MOBILE_HINTS_AUTHORS = {
        STUDIO: 'studio',
        DUPLICATE: 'duplicate'
    }
    const PRESET_CONVERSION_CONFIG = {
        nestOverlayingSiblings: false,
        keepIfVerticalDivider: false,
        isSuitableForProportionGrouping: false,
        hideByDefault: false
    }
    const HINTS_PROPERTIES = {
        GENERAL_HINTS_PROPERTIES: ['id', 'metaData', 'type'],
        GENERAL_PRESET_DATA: ['author'],
        SIZE_DATA: ['recommendedHeight', 'recommendedWidth', 'recommendedScale', 'originalCompId'],
        OFFSET_DATA: ['offsetX', 'offsetY', 'offsetOrigin', 'orderIndex']
    }

    const ALIASES = {styleId: 'style'}
    const PROPERTIES_FOR_MOBILE_PRESETS = ['layout', 'styleId', 'components', 'componentType', 'type', 'id', 'metaData']

    const OFFSET_DELTA_TO_RESET_COORDINATES = 40
    const SIZE_DELTA_TO_RESET = 50
    const FEDOPS = {
        MOBILE_HINTS_MIGRATION: 'mobile-algo-mobile-hints-migration'
    }
    return {
        MOBILE_HINTS_AUTHORS,
        PRESET_CONVERSION_CONFIG,
        HINTS_PROPERTIES,
        OFFSET_DELTA_TO_RESET_COORDINATES,
        SIZE_DELTA_TO_RESET,
        PROPERTIES_FOR_MOBILE_PRESETS,
        ALIASES,
        FEDOPS
    }
})
