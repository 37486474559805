import _ from 'lodash'
import widgetPreLoader from '@wix/santa-ds-libs/src/platformInit/src/utils/widgetsPreLoader'
import hostWorkerInit from '@wix/santa-host-platform-services/dist/host-worker-init'
import workerPropsBuilder from '@wix/santa-ds-libs/src/platformInit/src/api/workerPropsBuilder'

const {workerMessagesService} = hostWorkerInit
function shouldActivateWixCode(siteModel) {
    const clientSpecMap = siteModel.rendererModel.clientSpecMap.toJS ? siteModel.rendererModel.clientSpecMap.toJS() : siteModel.rendererModel.clientSpecMap
    return siteModel.rendererModel.wixCodeModel && _.some(clientSpecMap, {type: 'siteextension'})
}

/**
 * @returns {{init: function, sendMessage: function, registerMessageHandler: function}}
 */
function getAppApi() {
    let alreadyPreloaded = false
    let alreadyPreLoadedUserCode = false
    let alreadyPreInit = false
    let hasPlatformApp
    const workerMessagesAPI = workerMessagesService.getAPI()

    function init(siteModel, clientSpecMap, options, fetchScripts, hasUserGeneratedApps?) {
        if (_.isUndefined(options.applications)) {
            options.applications = []
        }
        if (workerMessagesAPI.isAppInitiated()) {
            return
        }

        hasPlatformApp = options.applications.length > 0
        if (shouldActivateWixCode(siteModel) || hasPlatformApp || hasUserGeneratedApps) {
            const workerProps = workerPropsBuilder.build(siteModel, clientSpecMap, options)
            options.workerProps = workerProps
            options.fetchScripts = fetchScripts
            workerMessagesAPI.init(options)
        }
    }

    function loadUserGeneratedApps(siteModel, currentUrl, userGeneratedApps) {
        if (userGeneratedApps.length === 0) {
            return
        }

        widgetPreLoader.asyncGetLoadUserGeneratedAppsMessage(siteModel, currentUrl, userGeneratedApps, message => {
            workerMessagesAPI.sendOrHoldMessage(message)
        })
    }

    function preLoadWidgets(siteModel, currentUrl) {
        if (!alreadyPreloaded && shouldActivateWixCode(siteModel)) {
            widgetPreLoader.asyncGetPreLoadMessage(siteModel, currentUrl, message => {
                if (!alreadyPreloaded) {
                    alreadyPreloaded = true
                    workerMessagesAPI.sendOrHoldMessage(message)
                }
            })
        }
    }

    function preLoadUserCode(siteModel, currentUrl) {
        if ((!alreadyPreLoadedUserCode && shouldActivateWixCode(siteModel)) || hasPlatformApp) {
            widgetPreLoader.asyncGetPreLoadUserCodeMessage(siteModel, currentUrl, message => {
                if (!alreadyPreLoadedUserCode) {
                    alreadyPreLoadedUserCode = true
                    workerMessagesAPI.sendOrHoldMessage(message)
                }
            })
        }
    }

    function preInitWidgets(siteData, currentUrl) {
        if (!alreadyPreInit && shouldActivateWixCode(siteData)) {
            widgetPreLoader.asyncGetPreInitMessage(siteData, currentUrl, message => {
                if (!alreadyPreInit) {
                    alreadyPreInit = true
                    workerMessagesAPI.sendOrHoldMessage(message)
                }
            })
        }
    }

    return {
        init,
        sendMessage: workerMessagesAPI.sendMessage,
        registerMessageHandler: workerMessagesAPI.registerMessageHandler,
        registerMessageModifier: workerMessagesAPI.registerMessageModifier,
        isAppInitiated: workerMessagesAPI.isAppInitiated,
        getWorkerById: workerMessagesAPI.getWorkerById,
        destroyAppsContainer: workerMessagesAPI.destroyAppsContainer,
        preLoadWidgets,
        preLoadUserCode,
        loadUserGeneratedApps,
        preInitWidgets
    }
}

export default {
    getApi: getAppApi
}
