import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const references = {
    IMAGE_REF: 'image',
    SVG_REF: 'svg',
    RECT_REF: 'rect',
    PATTERN_REF: 'pattern',
    MASK_SVG_REF: 'mask-svg',
    MASK_USE_REF: 'mask-use'
}
function getBoundingObjectFromString(str) {
    const [x = 0, y = 0, width = 0, height = 0] = str.split(' ')
    return {x, y, width, height}
}

function getSvgBounding(id, nodesMap) {
    const svgNode = nodesMap[id + references.MASK_SVG_REF]
    const boundingBoxString = svgNode && svgNode.getAttribute('data-bbox')
    if (boundingBoxString) {
        return getBoundingObjectFromString(boundingBoxString)
    }
    let bBox
    try {
        bBox = svgNode && svgNode.getBBox()
    } catch (e) {
        if (window && window.document) {
            const clone = svgNode.cloneNode(true)
            clone.style.visibility = 'hidden'
            clone.style.position = 'absolute'
            window.document.body.appendChild(clone)
            bBox = clone.getBBox()
            clone.parentElement.removeChild(clone)
        }
    }
    return bBox
}

function hasSvgImage(id, nodesMap) {
    return nodesMap[id + references.IMAGE_REF] && nodesMap[id + references.IMAGE_REF].nodeName.toLowerCase() === 'image'
}

function measureNodeImage(id, measureMap, nodesMap, parentStructureInfo) {
    let bBox
    let filterId
    let isSvgImage = false

    nodesMap[id + references.IMAGE_REF] = nodesMap[id].querySelector('img') || nodesMap[id].querySelector('image')
    if (!nodesMap[id + references.IMAGE_REF]) {
        return
    }

    const svgMaskId = nodesMap[id + references.IMAGE_REF].getAttribute('data-svg-mask')

    nodesMap[id + references.SVG_REF] = nodesMap[id].ownerDocument.getElementById(`svg${id}`) // Optional
    nodesMap[id + references.RECT_REF] = nodesMap[id + references.SVG_REF] && nodesMap[id + references.SVG_REF].querySelector('rect') // Optional
    nodesMap[id + references.PATTERN_REF] = nodesMap[id].querySelector('pattern') // Optional
    nodesMap[id + references.MASK_SVG_REF] = nodesMap[id].ownerDocument.getElementById(`${svgMaskId}`) // Optional
    nodesMap[id + references.MASK_USE_REF] = nodesMap[id].ownerDocument.getElementById(`${svgMaskId}-use`) // Optional

    // <image> is either for SVG filters IE/Edge fallback, or for SVG masks
    if (hasSvgImage(id, nodesMap)) {
        isSvgImage = true
        filterId = _.get(nodesMap[id].querySelector('filter'), 'id')
        bBox = getSvgBounding(id, nodesMap)
    }

    const imgSrc = nodesMap[id + references.IMAGE_REF].getAttribute(isSvgImage ? 'xlink:href' : 'src')

    measureMap.width[id] = nodesMap[id].offsetWidth
    measureMap.height[id] = nodesMap[id].offsetHeight
    const hasBgScrollEffect = nodesMap[id].getAttribute('data-has-bg-scroll-effect')

    if (hasBgScrollEffect) {
        measureMap.width[id] = nodesMap[parentStructureInfo.id].offsetWidth
        measureMap.height[id] = Math.max(measureMap.height.screen, nodesMap[parentStructureInfo.id].offsetHeight)
    }
    measureMap.custom[id] = {
        isZoomed: nodesMap[id].getAttribute('data-image-zoomed'),
        isSvgImage,
        filterId,
        bBox,
        hasMask: Boolean(nodesMap[id + references.MASK_SVG_REF]),
        hasSvgNode: Boolean(nodesMap[id + references.SVG_REF]),
        imgSrc,
        parentId: parentStructureInfo.id,
        renderedStyles: nodesMap[id].getAttribute('data-style'),
        hasPattern: !!nodesMap[id + references.PATTERN_REF]
    }
}

export default {
    measureNodeImage,
    references,
    getRootWidth: function getRootWidth(siteWidth, measureMap, rootId) {
        return warmupUtilsLib.layoutUtils.getRootWidth(measureMap, rootId, siteWidth)
    },
    getRootLeft: function getRootLeft(siteData, measureMap, rootId) {
        return warmupUtilsLib.layoutUtils.getRootLeft(measureMap, rootId, siteData.getSiteX())
    }
}
