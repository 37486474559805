define([
    'lodash',
    'documentServices/utils/utils',
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/dataModel/dataModel'
], function (_, dsUtils, constants, componentsMetaData, dataModel) {
    'use strict'

    const isHiddenAccordingToMobileHints = (ps, desktopCompPointer) => _.get(dataModel.getMobileHintsItem(ps, desktopCompPointer), 'hidden', false)

    function createMobilePropertyQuery(desktopPropertyQuery) {
        const mobileCompPropertyQuery = dsUtils.stripHashIfExists(desktopPropertyQuery)
        return (_.startsWith(mobileCompPropertyQuery, constants.DOM_ID_PREFIX.MOBILE) ? '' : constants.DOM_ID_PREFIX.MOBILE) + mobileCompPropertyQuery
    }

    function modifyComponentProperties(ps, desktopComponent, pageId, options) {
        const {propertyQuery, componentType} = desktopComponent
        if (!propertyQuery || !componentType) {
            return null
        }
        const desktopPropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propertyQuery), pageId)
        if (!desktopPropsPointer) {
            return null
        }
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const desktopComponentPointer = ps.pointers.components.getComponent(desktopComponent.id, pagePointer)
        if (isHiddenAccordingToMobileHints(ps, desktopComponentPointer)) {
            return null
        }
        const desktopCompProperties = ps.dal.get(desktopPropsPointer)
        if (!desktopCompProperties) {
            return null
        }
        const propsToModify = componentsMetaData.public.getDefaultMobileProperties(ps, desktopComponent, desktopCompProperties)
        if (!propsToModify) {
            return null
        }
        const mobileCompPropertyQuery = createMobilePropertyQuery(propertyQuery)
        const mobilePropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(mobileCompPropertyQuery), pageId)
        const mobileCompProperties = ps.dal.get(mobilePropsPointer) || {}
        if (!_.get(mobileCompProperties, 'metaData.autoGenerated', true) && !options.override) {
            return mobileCompPropertyQuery
        }
        _.mergeWith(mobileCompProperties, desktopCompProperties, {metaData: {autoGenerated: true}}, propsToModify, (dest, source) => {
            if (_.isArray(source) && _.isArray(dest)) {
                return source
            }
        })
        mobileCompProperties.id = mobileCompPropertyQuery
        ps.dal.set(mobilePropsPointer, mobileCompProperties)
        return mobileCompPropertyQuery
    }

    function createPropertiesAccordingToPreset(ps, preset, pageId) {
        if (createPropertiesAccordingToConversionData(ps, preset, pageId)) {
            delete preset.conversionData.mobileProps
        }
        if (preset.components) {
            _.forEach(preset.components, comp => createPropertiesAccordingToPreset(ps, comp, pageId))
        }
    }

    function createPropertiesAccordingToConversionData(ps, component, pageId) {
        const mobileProps = _.get(component, ['conversionData', 'mobileProps'], {})
        if (_.isEmpty(mobileProps)) {
            return false
        }
        const newMobilePropsItemId = dataModel.generateNewPropertiesItemId()
        const newMobilePropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(newMobilePropsItemId), pageId)
        const newMobileProps = _.set(mobileProps, 'id', newMobilePropsItemId)
        ps.dal.set(newMobilePropsPointer, newMobileProps)
        component.propertyQuery = newMobilePropsItemId
        return true
    }

    return {
        modifyComponentProperties,
        createPropertiesAccordingToPreset,
        createPropertiesAccordingToConversionData
    }
})
