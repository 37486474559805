define(['lodash', 'documentServices/page/page', 'documentServices/tpa/services/tpaWidgetService'], function (_, page, tpaWidgetService) {
    'use strict'

    function removePage(ps, tpaComps, _pageId, completeCallback) {
        _.forEach(tpaComps, tpaComp => tpaWidgetService.deleteWidget(ps, tpaComp))
        page.remove(ps, _pageId, completeCallback)
    }

    function validatePageRemoval(ps, _pageId) {
        const widgetCompRefs = page.validatePageRemovalInternal(ps, _pageId)
        _.forEach(widgetCompRefs, widgetCompRef => tpaWidgetService.notifyBeforeWidgetDeleteInternal(ps, widgetCompRef))
        ps.setOperationsQueue.asyncPreDataManipulationComplete(widgetCompRefs)
    }

    const exports = {
        remove: removePage,
        validatePageRemoval
    }

    return exports
})
