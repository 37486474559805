import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import PropTypes from 'prop-types'

/**
 * @class components.MobileMediaZoom
 * @extends {core.skinBasedComp}
 */
const mobileMediaZoom = {
    displayName: 'MobileMediaZoom',
    mixins: [componentsCore.mixins.skinBasedComp],
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        forceBackground: santaComponents.santaTypesDefinitions.forceBackground.isRequired,
        disableForcedBackground: santaComponents.santaTypesDefinitions.disableForcedBackground.isRequired,
        isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
        isZoomAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isZoomAllowed.isRequired,
        siteWidth: santaComponents.santaTypesDefinitions.siteWidth.isRequired,
        siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker.isRequired,
        navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
        enterFullScreenMode: santaComponents.santaTypesDefinitions.enterFullScreenMode.isRequired,
        exitFullScreenMode: santaComponents.santaTypesDefinitions.exitFullScreenMode.isRequired,

        // not santa types
        isDataChangedFunc: PropTypes.func.isRequired,
        getPrevAndNextStateFunc: PropTypes.func.isRequired,
        getChildCompFunc: PropTypes.func.isRequired,
        enableInnerScrolling: PropTypes.bool.isRequired,
        actualNavigateToItemFunc: PropTypes.func.isRequired,
        closeFunction: PropTypes.func,
        rootNavigationInfo: PropTypes.object
    },
    getInitialState() {
        const state = this.props.getPrevAndNextStateFunc()

        _.assign(state, {
            $viewerType: this.props.isMobileDevice ? 'mobile' : 'tablet', // 'desktop' only relevant in editor
            $buttonState: ''
        })

        if (this.props.enableInnerScrolling) {
            state.$scrollState = 'scrollEnabled'
        }
        return state
    },
    componentWillReceiveProps(nextProps) {
        if (!this.props.isZoomAllowed) {
            setTimeout(this.closeMediaZoom, 0)
            return
        }
        if (this.props.isDataChangedFunc(this.props, nextProps)) {
            this.setState(this.props.getPrevAndNextStateFunc())
        }
    },
    createOverlay(refs) {
        return componentsCore.utils.fullScreenOverlay.createOverlay(refs, {
            siteWidth: this.props.siteWidth,
            isMobileDevice: this.props.isMobileDevice,
            siteScrollingBlocker: this.props.siteScrollingBlocker,
            forceBackground: this.props.forceBackground,
            disableForcedBackground: this.props.disableForcedBackground
        })
    },
    getSkinProperties() {
        //TODO: the swipes shouldn't be on the image..
        const childComp = this.props.getChildCompFunc(
            {
                key: this.props.compData.id,
                hideMediaZoomButtons: this.hideButtons,
                showMediaZoomButtons: this.showButtons
            },
            {width: 0, height: 0}
        )

        const isWithSingleItem = !this.state.next

        let refs: any = {
            '': {
                onSwipeLeft: this.clickOnNextButton,
                onSwipeRight: this.clickOnPreviousButton
            },
            itemsContainer: {
                children: childComp
            },
            xButton: {
                onClick: this.closeMediaZoom,
                style: {}
            },
            buttonPrev: {
                onClick: this.clickOnPreviousButton,
                style: {}
            },
            buttonNext: {
                onClick: this.clickOnNextButton,
                style: {}
            }
        }

        refs = this.props.enableInnerScrolling ? refs : this.createOverlay(refs)

        // hide arrows if we got no gallery items
        if (isWithSingleItem || this.props.enableInnerScrolling) {
            refs.buttonNext.style.display = 'none'
            refs.buttonPrev.style.display = 'none'
        }

        return refs
    },

    clickOnNextButton(event) {
        this.showButtons()
        this.props.actualNavigateToItemFunc(this.state.next)
        if (event) {
            // Swipe is recognized by Touchy.js which clones the initial touch event and pass it in the onSwipe callback
            // In the process we lose functions from the event prototype like 'preventDefault' and 'stopPropagation'
            if (event.preventDefault) {
                event.preventDefault()
            }
            if (event.stopPropagation) {
                event.stopPropagation()
            }
        }
    },

    closeMediaZoom() {
        if (this.props.closeFunction) {
            this.props.closeFunction()
        } else {
            this.props.navigateToPage({pageId: this.props.rootNavigationInfo.pageId})
        }
    },

    /**
     * Handle left arrow click
     */
    clickOnPreviousButton(event) {
        this.showButtons()
        this.props.actualNavigateToItemFunc(this.state.prev)
        if (event) {
            // Swipe is recognized by Touchy.js which clones the initial touch event and pass it in the onSwipe callback
            // In the process we lose functions from the event prototype like 'preventDefault' and 'stopPropagation'
            if (event.preventDefault) {
                event.preventDefault()
            }
            if (event.stopPropagation) {
                event.stopPropagation()
            }
        }
    },

    /**
     * Hide buttons ( arrows / close button )
     */
    hideButtons() {
        this.setState({$buttonState: 'hideButtons'})
    },

    /**
     * Show buttons ( arrows / close button )
     */
    showButtons() {
        this.setState({$buttonState: ''})
    },

    componentDidMount() {
        // block site scrolling
        this.props.enterFullScreenMode({scrollable: this.props.enableInnerScrolling})
    },

    componentWillUnmount() {
        // enable site scrolling
        this.props.exitFullScreenMode()
    }
}

export default mobileMediaZoom
