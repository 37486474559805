const extensionsList: string[] = [
    'schema',
    'environment',
    'relationships',
    'dataModel',
    'snapshots',
    'documentServicesModel',
    'data',
    'dataAccess',
    'rendererModel',
    'structure',
    'page',
    'multilingual',
    'platform',
    'tpa',
    'saveState',
    'wixCodeSharedFileState',
    'platformSharedState',
    'mobile',
    'livePreviewSharedState',
    'validationWhitelist',
    'dataFixerVersioning',
    'validations',
    'variables',
    'slots',
    'componentsMetadata'
]

export default {
    extensionsList
}
