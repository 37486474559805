import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import baseCompositeProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseCompositeProxy'

/**
 * @class proxies.Css
 * @extends proxies.mixins.baseComposite
 */
export default {
    mixins: [baseCompositeProxy],

    renderProxy() {
        const childrenDefinitions = this.getCompProp('items')

        const children = _.map(
            childrenDefinitions,
            function (childDef, i) {
                return this.renderChildProxy(childDef, i)
            }.bind(this)
        )

        const props = this.getChildCompProps()

        return santaComponents.utils.createReactElement('div', props, children)
    }
}
