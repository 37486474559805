import _ from 'lodash'

const majorVersion = version => Number((version || '').split('.')[0] || 0)
// const minorVersion = version => Number((version || '').split('.')[1] || 0);
//const patchVersion = version => Number((version || '').split('.')[2] || 0);

const versionAtLeast = (current, needed) => {
    const neededVersionParts = needed.split('.').map(i => parseInt(i, 10))
    const currentVersionParts = current.split('.').map(i => parseInt(i, 10))
    for (let i = 0; i < neededVersionParts.length; i++) {
        if (currentVersionParts[i] < neededVersionParts[i]) {
            return false
        }
        if (currentVersionParts[i] > neededVersionParts[i]) {
            return true
        }
        // neededVersionParts[i] === currentVersionParts[i] continue
    }
    return true
}

const cssGridSupportedVersions = {
    os: {
        ios: '10.3'
    },
    browser: {
        chrome: '57',
        firefox: '52',
        ie: '10',
        safari: '10.1'
    }
}

const cssGridSupported = (os, browser) => {
    const minOsVersion = _.find(cssGridSupportedVersions.os, (v, k) => os[k])
    if (minOsVersion) {
        return !!os.version && versionAtLeast(os.version, minOsVersion)
    }
    const minBrowserVersion = _.find(cssGridSupportedVersions.browser, (v, k) => browser[k])
    if (minBrowserVersion) {
        return !!browser.version && versionAtLeast(browser.version, minBrowserVersion)
    }
    return true
}

export default {
    create(options) {
        const {os} = options
        const {browser} = options

        if (!os || !browser) {
            return {}
        }

        return {
            highlightAnchorsInMenu: !(os.ipad && browser.chrome),
            fixedSiteBackground: (!os.tablet && !os.phone) || (os.ipad && browser.safari) || ((browser.ie || browser.edge) && os.tablet),
            animateRevealScrubAction: !!(browser.ie || browser.edge || browser.firefox),
            animateParallaxScrubAction: !!browser.edge,
            animateTinyMenuIcon: !(browser.safari && os.mac),
            preserve3DParallaxScrubAction: !((browser.safari && majorVersion(browser.version) >= 9) || browser.firefox),
            positionFixedShouldBeAbsoluteAtPageBottom: os.ios && majorVersion(os.version) >= 11 && (browser.safari || browser.chrome),
            clipParallaxWithWebkitClipPath: browser.safari,
            fixedBackgroundColorBalata: !!(browser.chrome || browser.firefox),
            forceOverflowScroll: !!os.iphone,
            fixOverflowScrollEdges: os.iphone || browser.safari,
            doubleResetMobileViewport: options.tpaMobileViewportFix && browser.chrome && !os.iphone && !os.ipad,
            shouldDisableSmoothScrolling: !!(browser.chrome || browser.edge),
            mixBlendModeSupported: !(browser.ie || browser.edge),
            cssGridSupported: cssGridSupported(os, browser),
            svgImageOnLoadEvent: (browser.firefox || browser.chrome) && !os.ios,
            ios: os.ios,
            cssFiltersSupported: !(browser.ie || browser.edge),
            webpImageSupported: browser.chrome,
            webglCrossOriginSupported: !(browser.safari && majorVersion(browser.version) <= 10),
            webglVideoTextureSupported: !browser.ie,
            useDropShadowFilterOnVideo: !(browser.firefox && !os.ios) && !(browser.chrome && !os.ios),
            cssFeatureQueriesSupported: !browser.ie
        }
    }
}
