define([
    'documentServices/constants/constants',
    'documentServices/bi/errors',
    'documentServices/bi/bi',
    'documentServices/account/account',
    'documentServices/partners/partners',
    'documentServices/environment/environment',
    'documentServices/bi/events',
    'documentServices/bi/events.json',
    'documentServices/pathHelpers/siteMetadataPropertiesInfo',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/component/componentStructureInfo',
    'documentServices/component/componentModes',
    'documentServices/componentsLoader/componentsLoader',
    'documentServices/customElements/customElements',
    'documentServices/responsiveLayout/responsiveLayout',
    'documentServices/responsiveLayout/breakpoints',
    'documentServices/responsiveLayout/validations',
    'documentServices/scrollAnchors/scrollAnchors',
    'documentServices/variants/variants',
    'documentServices/variants/design',
    'documentServices/variants/transformations',
    'documentServices/variants/transitions',
    'documentServices/documentServicesDataFixer/documentServicesDataFixer',
    'documentServices/documentServicesDataFixer/dataFixersConfig',
    'documentServices/saveAPI/preSaveOperations/plugins/mobilePreSaveOperation',
    'documentServices/autosave/autosave',
    'documentServices/atomicScopes/atomicScopes',
    'documentServices/structure/utils/componentLayout',
    'documentServices/structure/structure',
    'documentServices/documentMode/documentMode',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/componentsDataModel',
    'documentServices/features/features',
    'documentServices/slots/slots',
    'documentServices/states/states',
    'documentServices/triggers/triggers',
    'documentServices/reactions/reactions',
    'documentServices/anchors/anchors',
    'documentServices/page/page',
    'documentServices/page/pageRemoval',
    'documentServices/page/pageAdd',
    'documentServices/sectionsLayoutMigrator/sectionsLayoutMigrator',
    'documentServices/utils/setOperationQueueUtils',
    'documentServices/debug/dsQTrace',
    'documentServices/modes/modesUtils',
    'documentServices/dataModel/dataIds',
    'documentServices/jsonConfig/jsonConfig',
    'documentServices/utils/utils',
    'documentServices/utils/contextAdapter',
    'documentServices/hooks/hooks',
    'documentServices/hooks/hooksRegistrarLoader',
    'documentServices/aspects/DocumentServicesAspect',
    //Temporay required to be sent to document-services package
    'documentServices/errors/errors',
    'documentServices/utils/idGenerator',
    'documentServices/accessibility/skipToContentData',
    'documentServices/accessibility/visualFocusData',
    'documentServices/accessibility/autoDomReorderData',
    'documentServices/accessibility/componentA11yAPI',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/appControllerData/appControllerData',
    'documentServices/appStudio/appStudioComponents',
    'documentServices/appStudio/appStudio',
    'documentServices/appStudioWidgets/appStudioWidgets',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/refComponent/refComponent',
    'documentServices/snapshotAPI/snapshotAPI',
    'documentServices/stylableEditor/stylableEditorAPI',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/component/componentCode',
    'documentServices/component/componentBehaviors',
    'documentServices/component/componentScroll',
    'documentServices/mobileConversion/mobileActions',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/hooks/componentsExternalHooks/createCompDriver',
    'documentServices/connections/connections',
    'documentServices/feedback/feedback',
    'documentServices/fonts/fonts',
    'documentServices/inlinePopup/inlinePopup',
    'documentServices/browserThemeColor/browserThemeColor',
    'documentServices/layouters/layouters',
    'documentServices/media/globalImageQualityData',
    'documentServices/media/vectorImageAPI',
    'documentServices/media/svgFiltersDefinitions',
    'documentServices/menu/mainMenu',
    'documentServices/menu/menuAPI',
    'documentServices/menu/basicMenuItemMethods',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/mobileConversion/mobileEditorSettings/mobileSettings',
    'documentServices/mobileConversion/mobileEditorSettings/quickActions',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionsVersionUtils',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionBarManager',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/modules/mobileHintsValidator',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsHandler',
    'documentServices/mobileConversion/modules/menuContainer/menuContainer',
    'documentServices/wix2x/wix2x',
    'documentServices/page/pageData',
    'documentServices/pagesGroup/pagesGroup',
    'documentServices/platform/platform',
    'documentServices/platform/provision',
    'documentServices/platform/addApps',
    'documentServices/platform/uninstall',
    'documentServices/platform/pages',
    'documentServices/platform/serialization',
    'documentServices/platform/sessionState',
    'documentServices/platform/services/viewerInfoChangedEmitter',
    'documentServices/platform/services/apiCallBiService',
    'documentServices/platform/livePreview/livePreview',
    'documentServices/platform/common/constants',
    'documentServices/renderPlugins/renderPlugins',
    'documentServices/routers/routers',
    'documentServices/routers/pointers/routersPointers',
    'documentServices/saveAPI/saveAPI',
    'documentServices/siteMembers/siteMembers',
    'documentServices/siteSegments/siteSegments',
    'documentServices/smartBoxes/smartBoxes',
    'documentServices/smartBoxes/multiComponentsUtils',
    'documentServices/smartBoxes/multiComponentsUtilsValidations',
    'documentServices/smartBoxes/grouping',
    'documentServices/smartBoxes/groupingUtils',
    'documentServices/structure/structureUtils',
    'documentServices/structure/utils/arrangement',
    'documentServices/structure/meshMigrationUtil',
    'documentServices/theme/theme',
    'documentServices/tpa/tpa',
    'documentServices/tpa/core',
    'documentServices/validation/validation',
    'documentServices/wixCode/wixCode',
    'documentServices/wixapps/wixapps',
    'documentServices/wixCode/utils/constants',
    'documentServices/hooks/componentHooks/wFacebookComment',
    'documentServices/variables/variables'
], function (
    constants,
    errors,
    bi,
    account,
    partners,
    environment,
    events,
    biEventTypes,
    siteMetadataPropertiesInfo,
    siteMetadata,
    componentStructureInfo,
    componentModes,
    componentsLoader,
    customElements,
    responsiveLayout,
    breakpoints,
    responsiveLayoutValidations,
    scrollAnchors,
    variants,
    design,
    transformations,
    transitions,
    documentServicesDataFixer,
    dataFixersConfig,
    mobilePreSaveOperation,
    autosave,
    atomicScopes,
    componentLayout,
    structure,
    documentMode,
    dataModel,
    componentsDataModel,
    features,
    slots,
    states,
    triggers,
    reactions,
    anchors,
    page,
    pageRemoval,
    pageAdd,
    sectionsLayoutMigrator,
    setOperationQueueUtils,
    dsQTrace,
    modesUtils,
    dataIds,
    jsonConfig,
    utils,
    contextAdapter,
    hooks,
    //temporary code!!!
    // SIDE EFFECTS!!!
    hooksRegistrarLoader, // eslint-disable-line @wix/santa/no-unused-imports
    DocumentServicesAspect, // eslint-disable-line @wix/santa/no-unused-imports

    tmpErrors,
    idGenerator,
    skipToContentData,
    visualFocusData,
    autoDomReorderData,
    componentA11yAPI,
    actionsAndBehaviors,
    appControllerData,
    appStudioComponents,
    appStudio,
    appStudioWidgets,
    componentDetectorAPI,
    component,
    refComponent,
    snapshotAPI,
    stylableEditorAPI,
    componentStylesAndSkinsAPI,
    componentCode,
    componentBehaviors,
    componentScroll,
    mobileActions,
    componentsMetaData,
    createCompDriver,
    connections,
    feedback,
    fonts,
    inlinePopup,
    browserThemeColor,
    layouters,
    globalImageQualityData,
    vectorImageAPI,
    svgFiltersDefinitions,
    mainMenu,
    menuAPI,
    basicMenuItemMethods,
    mobileConversionFacade,
    mobileSettings,
    quickActions,
    quickActionsVersionUtils,
    quickActionBarManager,
    mergeAggregator,
    mobileHintsValidator,
    mobilePresetsHandler,
    menuContainer,
    wix2x,
    pageData,
    pagesGroup,
    platform,
    provision,
    addApps,
    uninstall,
    pages,
    serialization,
    sessionState,
    viewerInfoChangedEmitter,
    apiCallBiService,
    livePreview,
    platformConstants,
    renderPlugins,
    routers,
    routersPointers,
    saveAPI,
    siteMembers,
    siteSegments,
    smartBoxes,
    multiComponentsUtils,
    multiComponentsUtilsValidations,
    grouping,
    groupingUtils,
    structureUtils,
    arrangement,
    meshMigrationUtil,
    theme,
    tpa,
    tpaCore,
    validation,
    wixCode,
    wixapps,
    wixCodeConstants,
    wFacebookComment,
    variables
) {
    'use strict'

    return {
        constants,
        biErrors: errors,
        bi,
        account,
        partners,
        environment,
        biEvents: events,
        biEventTypes,
        siteMetadataPropertiesInfo,
        siteMetadata,
        componentStructureInfo,
        componentModes,
        componentsLoader,
        customElements,
        responsiveLayout,
        breakpoints,
        responsiveLayoutValidations,
        variants,
        design,
        transformations,
        transitions,
        scrollAnchors,
        documentServicesDataFixer,
        dataFixersConfig,
        mobilePreSaveOperation,
        autosave,
        atomicScopes,
        componentLayout,
        structure,
        documentMode,
        dataModel,
        componentsDataModel,
        features,
        slots,
        states,
        triggers,
        reactions,
        anchors,
        page,
        pageRemoval,
        pageAdd,
        sectionsLayoutMigrator,
        setOperationQueueUtils,
        dsQTrace,
        modesUtils,
        dataIds,
        jsonConfig,
        utils,
        contextAdapter,
        hooks,
        temp: {
            tmpErrors,
            idGenerator,
            skipToContentData,
            visualFocusData,
            autoDomReorderData,
            componentA11yAPI,
            actionsAndBehaviors,
            appControllerData,
            appStudioComponents,
            appStudio,
            appStudioWidgets,
            componentDetectorAPI,
            component,
            refComponent,
            snapshotAPI,
            stylableEditorAPI,
            componentStylesAndSkinsAPI,
            componentCode,
            componentBehaviors,
            componentScroll,
            mobileActions,
            componentsMetaData,
            createCompDriver,
            connections,
            feedback,
            fonts,
            inlinePopup,
            browserThemeColor,
            layouters,
            globalImageQualityData,
            vectorImageAPI,
            svgFiltersDefinitions,
            mainMenu,
            menuAPI,
            basicMenuItemMethods,
            mobileConversionFacade,
            mobileSettings,
            quickActions,
            quickActionsVersionUtils,
            quickActionBarManager,
            mergeAggregator,
            mobileHintsValidator,
            mobilePresetsHandler,
            menuContainer,
            wix2x,
            pageData,
            pagesGroup,
            platform,
            provision,
            addApps,
            uninstall,
            pages,
            serialization,
            sessionState,
            viewerInfoChangedEmitter,
            apiCallBiService,
            livePreview,
            platformConstants,
            renderPlugins,
            routers,
            routersPointers,
            saveAPI,
            siteMembers,
            siteSegments,
            smartBoxes,
            multiComponentsUtils,
            multiComponentsUtilsValidations,
            grouping,
            groupingUtils,
            structureUtils,
            arrangement,
            meshMigrationUtil,
            theme,
            tpa,
            tpaCore,
            validation,
            wixCode,
            wixapps
        },
        wixCodeDS: wixCode,
        platform,
        wixCodeConstants,
        wFacebookComment,
        component,
        variables
    }
})
