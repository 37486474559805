define(['lodash'], function (_) {
    'use strict'
    return schemasService => {
        function validateItem(dataItem, schemaOrigin, options) {
            if (!dataItem) {
                throw new Error('Data is not set')
            }
            const schemaName = _.get(dataItem, 'type') || _.get(options, 'schemaName')
            if (!schemaName) {
                throw new Error("dataItem has no 'type' nor options has 'schemaName'")
            }
            schemasService.validate(schemaName, dataItem, schemaOrigin)
        }

        function isItemValid(dataItem, fieldName, fieldValue, origin) {
            const schemaName = dataItem && dataItem.type
            if (!schemaName) {
                throw new Error("dataItem has no 'type' nor options has 'schemaName'")
            }
            const newValue = _.cloneDeep(dataItem)
            newValue[fieldName] = fieldValue
            return schemasService.isValid(schemaName, newValue, origin)
        }

        function resolveDefaultItem(schemaName, emptyItem) {
            //change to schemasService.createDefaultItem
            let validator = null
            if (schemasService.validators.data.getSchema(schemaName)) {
                validator = schemasService.validators.data
            } else if (schemasService.validators.props.getSchema(schemaName)) {
                validator = schemasService.validators.props
            }
            if (validator) {
                emptyItem = emptyItem || {}
                validator.validate(schemaName, emptyItem)
            }
            return emptyItem
        }

        return {
            validateDataBySchema: validateItem,
            validateItem,
            resolveDefaultItem,
            isItemValid
        }
    }
})
