import {
    pointerUtils,
    HistoryItem,
    DocumentDataTypes,
    Extension,
    Transaction,
    CreateExtArgs,
    PointerMethods,
    ExtensionAPI,
    CreateExtensionArgument
} from '@wix/document-manager-core'
import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'

const {getGUID: generateGUID} = guidUtils

const {getPointer} = pointerUtils

const livePreviewSharedState = 'livePreviewSharedState'

const routerConcurrentInvalidationState = 'routersConcurrentInvalidationState'

type LivePreviewDataChangeListener = (data: any) => void
type ConcurrentRoutersInvalidationListener = (currentRouteInfo: CurrentRouteInfo, selectedRoute?: string) => void

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [livePreviewSharedState]: {}
})

const pointerDataTypes = {
    livePreviewSharedState,
    routerConcurrentInvalidationState
}

const initialState = {}

export interface LivePreviewRefreshOptions {
    source?: string
    shouldFetchData?: boolean
    restartWorker?: boolean
    resetRuntime?: boolean
    skipAppsCheck?: boolean
    sendLoad?: boolean
    sendInitAndStart?: boolean
    immediate?: boolean
    controllersToRefresh?: string[]
    compsIdsToReset?: string[]
    sharedStateSyncOptions?: {shouldSync?: boolean; pageId?: string}
}

export interface CurrentRouteInfo {
    routerId: string
    innerRoute?: string
    isDynamic?: boolean
}

export interface LivePreviewSharedState extends ExtensionAPI {
    livePreviewSharedState: {
        notifyLivePreviewDataChanged(livePreviewRefreshOptions: LivePreviewRefreshOptions): void
        subscribeToLivePreviewDataChanges(listener: LivePreviewDataChangeListener): void
        notifyRoutersConcurrentInvalidationStateChanged(currentRouteInfo: CurrentRouteInfo, selectedNewRoute: string): void
        subscribeToConcurrentRoutersInvalidation(listener: ConcurrentRoutersInvalidationListener): void
    }
}

interface LivePreviewChangedStateObject {
    livePreviewRefreshOptions: LivePreviewRefreshOptions
    guid: string
    type: string
}

interface RouterStateObject {
    currentRouteInfo: CurrentRouteInfo
    selectedRoute?: string
    guid: string
    type: string
}

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const localChangesMap = new Map()

    const dataChangesListeners: LivePreviewDataChangeListener[] = []
    const concurrentRoutersInvalidationListeners: ConcurrentRoutersInvalidationListener[] = []
    const notifyChangeListeners = (livePreviewRefreshOptions: LivePreviewRefreshOptions) => {
        dataChangesListeners.forEach(listener => {
            try {
                listener(livePreviewRefreshOptions)
            } catch (e) {
                console.error(e)
                logger.captureError(e as Error, {
                    tags: {
                        livePreviewRefreshError: true
                    }
                })
            }
        })
    }

    const notifyRouterInvalidationListeners = (currentRouteInfo: CurrentRouteInfo, selectedNewRoute?: string) => {
        concurrentRoutersInvalidationListeners.forEach(listener => {
            try {
                listener(currentRouteInfo, selectedNewRoute)
            } catch (e) {
                console.error(e)
                logger.captureError(e as Error, {tags: {routerInvalidationError: true}})
            }
        })
    }

    const findTransactionFromType = (sharedChangesUpdates: HistoryItem[], typeToFilter: string) =>
        _(sharedChangesUpdates)
            .map((item: HistoryItem) => {
                const {
                    value: updatedSharedState,
                    key: {id: pointerId}
                } = item
                const localGuid: string = _.get(localChangesMap.get(livePreviewSharedState), ['guid'])
                const sharedGuid: string = _.get(updatedSharedState, ['guid'])
                const dataPointerType: string = _.get(updatedSharedState, ['type'])
                const diffFromLocalState = localGuid !== sharedGuid
                if (dataPointerType === typeToFilter && diffFromLocalState) {
                    localChangesMap.set(pointerId, updatedSharedState)
                    return updatedSharedState
                }
                return null
            })
            .compact()
            .value()

    const pointers = {
        getSharedLivePreviewState: () => getPointer(livePreviewSharedState, livePreviewSharedState),
        getRoutersConcurrentInvalidationState: (routerId: string) => getPointer(`${routerConcurrentInvalidationState}_${routerId}`, livePreviewSharedState)
    }

    const createPointersMethods = (): PointerMethods => ({
        livePreview: pointers
    })

    const createExtensionAPI = ({dal}: CreateExtArgs): LivePreviewSharedState => ({
        livePreviewSharedState: {
            notifyLivePreviewDataChanged: (livePreviewRefreshOptions: LivePreviewRefreshOptions) => {
                const changedGuid = generateGUID()
                const sharedPointer = pointers.getSharedLivePreviewState()
                const dataToSet = {guid: changedGuid, livePreviewRefreshOptions, type: pointerDataTypes.livePreviewSharedState}
                dal.set(sharedPointer, dataToSet)
                localChangesMap.set(livePreviewSharedState, dataToSet)
            },

            subscribeToLivePreviewDataChanges: (listener: LivePreviewDataChangeListener) => {
                if (_.isFunction(listener)) {
                    dataChangesListeners.push(listener)
                }
            },
            subscribeToConcurrentRoutersInvalidation: (listener: ConcurrentRoutersInvalidationListener) => {
                if (_.isFunction(listener)) {
                    concurrentRoutersInvalidationListeners.push(listener)
                }
            },
            notifyRoutersConcurrentInvalidationStateChanged: (currentRouteInfo: CurrentRouteInfo, selectedRoute: string) => {
                const changedGuid = generateGUID()
                const sharedPointer = pointers.getRoutersConcurrentInvalidationState(currentRouteInfo.routerId)
                const dataToSet = {guid: changedGuid, currentRouteInfo, selectedRoute, type: pointerDataTypes.routerConcurrentInvalidationState}
                dal.set(sharedPointer, dataToSet)
                localChangesMap.set(livePreviewSharedState, dataToSet)
            }
        }
    })

    const createPostTransactionOperations = () => ({
        livePreview: (documentTransaction: Transaction) => {
            const sharedPathChangesUpdates = documentTransaction.items.filter(e => e?.key?.type === livePreviewSharedState)

            const changedLivePreviewChanges: LivePreviewChangedStateObject[] = findTransactionFromType(
                sharedPathChangesUpdates,
                pointerDataTypes.livePreviewSharedState
            )
            if (!_.isEmpty(changedLivePreviewChanges)) {
                _.forEach(changedLivePreviewChanges, updatedState => {
                    notifyChangeListeners(updatedState.livePreviewRefreshOptions)
                })
            }

            const routerInvalidationChanges: RouterStateObject[] = findTransactionFromType(
                sharedPathChangesUpdates,
                pointerDataTypes.routerConcurrentInvalidationState
            )
            if (!_.isEmpty(routerInvalidationChanges)) {
                _.forEach(routerInvalidationChanges, updatedState => {
                    notifyRouterInvalidationListeners(updatedState.currentRouteInfo, updatedState.selectedRoute)
                })
            }
        }
    })

    return {
        name: 'livePreviewSharedState',
        getDocumentDataTypes,
        createPointersMethods,
        initialState,
        createExtensionAPI,
        createPostTransactionOperations
    }
}

export {createExtension}
