import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {siteDataUtils} = coreUtils

function getApplicationDataStore(siteData, packageName) {
    if (!siteData) {
        return null
    }
    siteData.wixapps = siteData.wixapps || {}
    siteData.wixapps[packageName] = siteData.wixapps[packageName] || {}

    return siteData.wixapps[packageName]
}

function setApplicationDataStore(siteData, packageName, dataStore) {
    if (!siteData) {
        return null
    }
    siteData.wixapps = siteData.wixapps || {}
    siteData.wixapps[packageName] = dataStore
    siteDataUtils.incrementWixappsRenderCounter(siteData, packageName)
}

//

function getDescriptor(siteData, packageName) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    if (dataStore.descriptor) {
        return dataStore.descriptor
    }
    return null
}

function getBlogCategoriesFromPackageData(dataStore) {
    return _.get(dataStore, 'categories', null)
}

function getBlogTagNames(siteData) {
    const dataStore = getApplicationDataStore(siteData, 'blog')
    return _.get(dataStore, 'tagNames', null)
}

function getBlogCategories(siteData) {
    const dataStore = getApplicationDataStore(siteData, 'blog')
    return getBlogCategoriesFromPackageData(dataStore)
}

function getBlogCategoryByName(siteData, name) {
    const categoryStore = getBlogCategories(siteData)
    let category
    if (categoryStore) {
        category = _.find(categoryStore.categoryById, {name})
    }
    return category || null
}

function getDataByCompId(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    if (dataStore[compId]) {
        return dataStore[compId]
    }
    return null
}

function setDataForCompId(siteData, packageName, compId, path) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore[compId] = path
    siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
}

function clearDataForCompId(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    delete dataStore[compId]
    siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
}

function getExtraDataByCompId(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    return dataStore[`${compId}_extraData`] || null
}

function getDataByPath(siteData, packageName, path) {
    if (!_.isArray(path) || path.length === 0) {
        return []
    }

    // if the path is an array of arrays resolve each inner array as an item.
    if (_.every(path, _.isArray)) {
        return _.map(path, function (itemPath) {
            return getDataByPath(siteData, packageName, itemPath)
        })
    }

    const pathParts = _.clone(path)
    const dataStore = getApplicationDataStore(siteData, packageName)
    if (!dataStore.items) {
        return null
    }

    let currentScope = dataStore.items
    while (pathParts.length) {
        const prop = pathParts.shift()
        currentScope = currentScope[prop]
    }
    return currentScope
}

function setDataByPath(siteData, packageName, path, value) {
    const pathParts = _.clone(path)
    const dataStore = getApplicationDataStore(siteData, packageName)
    let currentScope = dataStore.items
    while (pathParts.length > 1) {
        const prop = pathParts.shift()
        currentScope = currentScope[prop]
    }

    const propertyName = pathParts.shift()
    if (!_.isEqual(currentScope[propertyName], value)) {
        currentScope[propertyName] = value
        siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, path[0])
        return true
    }

    return false
}

function getSiteDataDestination(packageName) {
    return ['wixapps', packageName]
}

function setCompMetadata(metadata, siteData, packageName, compId) {
    const currentMetadata = getCompMetadata(siteData, packageName, compId)
    _.merge(currentMetadata, metadata)
    siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
}

function getCompMetadata(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore.metadata = dataStore.metadata || {}
    dataStore.metadata[compId] = dataStore.metadata[compId] || {}
    return dataStore.metadata[compId]
}

function setCompFailedRequests(value, siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore.failedRequests = dataStore.failedRequests || {}
    dataStore.failedRequests[compId] = value
}

function setPackageRequestTimedout(value, siteData, packageName) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore.packageHadRequestsTimeout = value
}

function didCompHadFailedRequests(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore.failedRequests = dataStore.failedRequests || {}
    return !!dataStore.failedRequests[compId]
}

function didCompHadFailedRequestsInSSR(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    return _.get(dataStore, `ssr.failedRequests.${compId}`, false)
}

function didPackageHadRequestsTimeoutInSSR(siteData, packageName) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    return !!dataStore.packageHadRequestsTimeout
}

function clearCompMetadata(siteData, packageName, compId) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    if (dataStore.metadata && dataStore.metadata[compId]) {
        dataStore.metadata[compId] = {}
        siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
    }
}

function getPackageMetadata(siteData, packageName) {
    const dataStore = getApplicationDataStore(siteData, packageName)
    dataStore.metadata = dataStore.metadata || {}
    dataStore.metadata[`${packageName}_metadata`] = dataStore.metadata[`${packageName}_metadata`] || {}
    return dataStore.metadata[`${packageName}_metadata`]
}

function setPackageMetadata(metadata, siteData, packageName) {
    const currentMetadata = getPackageMetadata(siteData, packageName)
    _.merge(currentMetadata, metadata)
    siteDataUtils.incrementWixappsRenderCounter(siteData, packageName)
}

/**
 * @class wixappsCore.wixappsDataHandler
 */
export default {
    getDescriptor,

    getDataByCompId,

    setDataForCompId,

    clearDataForCompId,

    getExtraDataByCompId,

    getDataByPath,

    setDataByPath,

    getPackageData: getApplicationDataStore,

    setPackageData: setApplicationDataStore,

    getSiteDataDestination,

    setCompMetadata,

    getCompMetadata,

    setCompFailedRequests,

    setPackageRequestTimedout,

    didCompHadFailedRequests,

    didCompHadFailedRequestsInSSR,

    didPackageHadRequestsTimeoutInSSR,

    getPackageMetadata,

    setPackageMetadata,

    clearCompMetadata,

    getBlogCategoriesFromPackageData,

    getBlogTagNames,

    getBlogCategories,

    getBlogCategoryByName
}
