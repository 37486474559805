/**
 * This module use for add some specific conversion data e.g. some calculated values from desktop properties,
 * specific values calculated on the fly, during merge/optimize runs
 * */
define([
    'lodash',
    'documentServices/mobileConversion/modules/componentSpecificConversionData/buttonConversionData',
    'documentServices/mobileConversion/modules/componentSpecificConversionData/vectorImageConversionData',
    'documentServices/mobileConversion/modules/componentSpecificConversionData/wRichTextConversionData'
], function (_, buttonConversionData, vectorImageConversionData, wRichTextConversionData) {
    'use strict'

    const vectorImageHandlerWrapper = (ps, component) => vectorImageConversionData.getVectorImageConversionData(component)

    const specificComponentHandlers = {
        'wixui.StylableButton': buttonConversionData.getButtonConversionData,
        'wysiwyg.viewer.components.SiteButton': buttonConversionData.getButtonConversionData,
        'wysiwyg.viewer.components.VectorImage': vectorImageHandlerWrapper,
        'wysiwyg.viewer.components.WPhoto': vectorImageHandlerWrapper,
        'wysiwyg.viewer.components.ClipArt': vectorImageHandlerWrapper,
        'wysiwyg.viewer.components.WRichText': wRichTextConversionData.getWRichTextConversionData
    }

    function getComponentSpecificConversionData(ps, component, pageId) {
        const componentConversionDataHandler = _.get(specificComponentHandlers, component.componentType, _.noop)
        return componentConversionDataHandler(ps, component, pageId) || null
    }

    return {
        getComponentSpecificConversionData
    }
})
