define([
    'lodash',
    'tpa',
    'documentServices/componentsMetaData/components/baseTpaMetaData',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/utils/utils'
], function (_, tpa, baseTpaMetaData, metaDataUtils, dsUtils) {
    'use strict'

    const PERMITTED_APPLICATIONS = tpa.common.metaData.PERMITTED_GLUED_TPAS_IN_MOBILE

    function isApplicationShownInMobile(ps, applicationId) {
        if (applicationId) {
            const clientSpecMapPtr = ps.pointers.general.getClientSpecMap()
            const clientSpecMap = ps.dal.get(clientSpecMapPtr)
            const appData = clientSpecMap[applicationId] || {}
            return _.includes(PERMITTED_APPLICATIONS, appData.appDefinitionId)
        }
        return false
    }

    function sanitizeQuery(dataQuery) {
        return _.isString(dataQuery) && dataQuery[0] === '#' ? dataQuery.slice(1) : dataQuery
    }

    function getCompData(component, ps, pageId) {
        const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
        const dataPointer = ps.pointers.data.getDataItem(dataQuery, pageId)
        return ps.dal.get(dataPointer)
    }

    return {
        isRepeatable: false,
        layoutLimits: metaDataUtils.getControllerLayoutLimits,
        resizableSides: [],
        moveDirections: [],
        containable: false,
        fullWidth: false,
        crossSiteDuplicatable: false,
        mobileConversionConfig: {
            structuralItem: true,
            convertFixedPositionToAbsolute: true, // if falsy, is thrown in the mobile "hidden" elements map
            nonLayoutComponent: true,
            desktopOnly(ps, compStructure, pageId) {
                const {dataQuery} = compStructure
                const dataItemPointer = ps.pointers.data.getDataItem(sanitizeQuery(dataQuery), pageId)
                const dataItem = ps.dal.get(dataItemPointer)
                return !isApplicationShownInMobile(ps, _.get(dataItem, 'applicationId'))
            },
            minHeight(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = baseTpaMetaData.getWidgetData(ps, compData)
                return _.get(widgetData, 'componentFields.minHeightInMobile')
            }
        },
        nickname(ps, compPointer) {
            return baseTpaMetaData.nickname(ps, compPointer)
        }
    }
})
