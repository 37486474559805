import _ from 'lodash'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import boxProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/boxProxy'
import layoutHelper from '@wix/santa-ds-libs/src/wixappsCore/src/core/layoutBasedHelper'

// Inherit flex style
const flexStyle = {display: 'inherit', 'flex-direction': 'inherit'}

/**
 * @class proxies.Area
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [boxProxy],
    propTypes: {
        orientation: PropTypes.string
    },
    getChildrenOrientation() {
        return this.getCompProp('orientation') || 'vertical'
    },

    getComponentName() {
        return 'wixapps.integration.components.Area'
    },

    getReactClass() {
        return componentsCore.compRegistrar.getCompClass(this.getComponentName(), true)
    },

    transformSkinProperties(refData) {
        const proxyStyleDef = this.getStyleDef()
        if (layoutHelper.isLayoutBasedHeight(proxyStyleDef, this.props.orientation)) {
            refData.bg = refData.bg || {}
            refData.bg.style = _.merge({}, refData.bg.style, {height: '100%'})
        }
        refData.inlineContent.style = _.merge({}, refData.inlineContent.style, flexStyle, {position: 'relative', width: '100%'})

        return refData
    }
}
