define(['documentServices/tpa/services/tpaEventHandlersService'], function (tpaEventHandlersService) {
    'use strict'

    const TASK_NAME = 'saveEventDispatcher'

    const triggerSaveEvent = function () {
        tpaEventHandlersService.siteSaved()
    }

    const createTask = () => ({
        partialSave(lastSavedData, currentData, resolve) {
            resolve()
            triggerSaveEvent()
        },
        fullSave(lastSavedData, currentData, resolve) {
            resolve()
            triggerSaveEvent()
        },
        firstSave(lastSavedData, currentData, resolve) {
            resolve()
            triggerSaveEvent()
        },
        saveAsTemplate(lastSavedData, currentData, resolve) {
            resolve()
        },
        publish(currentData, resolve) {
            resolve()
        },
        autosave(lastSnapshot, currentSnapshot, resolve) {
            tpaEventHandlersService.siteSaved()
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        }
    })

    return () => createTask()
})
