import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const variables = viewerLibrary.get('documentServices/variables/variables')
    return {
        methods: {
            components: {
                variables: {
                    list: publicMethodUtils.defineGetter(variables.getComponentVariablesList),
                    get: publicMethodUtils.defineGetter(variables.getVariable),
                    add: publicMethodUtils.defineDataManipulationAction(variables.addVariable, {
                        getReturnValue: variables.getVariableToAddRef
                    }),
                    remove: publicMethodUtils.defineDataManipulationAction(variables.removeVariable),
                    update: publicMethodUtils.defineDataManipulationAction(variables.updateVariableDefinition),
                    getVariableConnection: publicMethodUtils.defineGetter(variables.getVariableConnection),
                    getVariablePointerFromConnection: publicMethodUtils.defineGetter(variables.getVariablePointerFromConnection),
                    getComponentsUsingVariable: publicMethodUtils.defineGetter(variables.getComponentsUsingVariable),
                    value: {
                        get: publicMethodUtils.defineGetter(variables.getVariableData),
                        remove: publicMethodUtils.defineDataManipulationAction(variables.removeVariableData),
                        update: publicMethodUtils.defineDataManipulationAction(variables.updateVariableData)
                    }
                }
            }
        }
    }
}
