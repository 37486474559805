define(['@wix/santa-core-utils', 'lodash', 'documentServices/constants/constants'], function (santaCoreUtils, _, constants) {
    'use strict'

    return {
        layoutLimits(ps, compPointer) {
            const measureMap = ps.siteAPI.getSiteMeasureMap() || {}
            const {id} = compPointer

            return {
                minWidth: _.get(measureMap, ['minWidth', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH),
                minHeight: _.get(measureMap, ['minHeight', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT)
            }
        },

        mobileConversionConfig: {
            hideByDefault(ps, component, pageId) {
                const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
                const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, masterPagePointer)
                const menuContainerIsExist = ps.dal.isExist(menuContainerPointer)
                return pageId === 'masterPage' && !menuContainerIsExist
            }
        }
    }
})
