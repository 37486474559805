define(['core', 'wixCode', 'documentServices/wixCode/utils/constants'], function (core, wixCode, constants) {
    'use strict'

    const type = 'wixCode'

    const {pointerGeneratorsRegistry} = core
    pointerGeneratorsRegistry.registerPointerType(
        type,
        function () {
            return null
        },
        function () {
            return true
        }
    )
    pointerGeneratorsRegistry.registerPointerType(
        'wixCodeWaitForApproval',
        function () {
            return null
        },
        function () {
            return true
        }
    )

    const getterFunctions = {
        getRoot(getItemAt, cache) {
            return cache.getPointer('wixCodeRoot', type, constants.paths.BASE)
        },
        getNonUndoableRoot(getItemAt, cache) {
            return cache.getPointer('wixCodeNonUndoableRoot', type, constants.paths.NON_UNDOABLE_BASE_PATH)
        },
        getUndoableRoot(getItemAt, cache) {
            return cache.getPointer('wixCodeUndoableRoot', type, constants.paths.UNDOABLE_BASE_PATH)
        },
        getModifiedFileContentMap(getItemAt, cache) {
            return cache.getPointer('modifiedFileContents', type, constants.paths.MODIFIED_FILE_CONTENTS)
        },
        getModifiedFileContent(getItemAt, cache, filePath) {
            return cache.getPointer(`modifiedFileContents_${filePath}`, type, constants.paths.MODIFIED_FILE_CONTENTS.concat(filePath))
        },
        getLoadedFileContentMap(getItemAt, cache) {
            return cache.getPointer('loadedFileContents', type, constants.paths.LOADED_FILE_CONTENTS)
        },
        getLoadedFileContent(getItemAt, cache, filePath) {
            return cache.getPointer(`loadedFileContents_${filePath}`, type, constants.paths.LOADED_FILE_CONTENTS.concat(filePath))
        },
        getUndoableModifiedFileContentMap(getItemAt, cache) {
            return cache.getPointer('undoableModifiedFileContents', type, constants.paths.UNDOABLE_MODIFIED_FILE_CONTENTS)
        },
        getUndoableModifiedFileContent(getItemAt, cache, filePathId) {
            return cache.getPointer(`undoableModifiedFileContents_${filePathId}`, type, constants.paths.UNDOABLE_MODIFIED_FILE_CONTENTS.concat(filePathId))
        },
        getFilePathToIdMap(getItemAt, cache) {
            return cache.getPointer('filePathToIdMap', type, constants.paths.FILE_PATH_TO_ID_MAP)
        },
        getFilePathId(getItemAt, cache, filePath) {
            return cache.getPointer(`filePathToIdMap_${filePath}`, type, constants.paths.FILE_PATH_TO_ID_MAP.concat(filePath))
        },
        getDuplicatedFilesInfoMap(getItemAt, cache) {
            return cache.getPointer('duplicatedFilesInfo', type, constants.paths.DUPLICATED_FILES_INFO)
        },
        getGlobalBundleCacheKiller(getItemAt, cache) {
            return cache.getPointer('globalBundleCacheKiller', type, wixCode.wixCodeUserScriptsService.paths.GLOBAL_CACHE_KILLER)
        },
        getBundleCacheKillerMap(getItemAt, cache) {
            return cache.getPointer('bundleCacheKillers', type, wixCode.wixCodeUserScriptsService.paths.CACHE_KILLERS_MAP)
        },
        getBundleCacheKiller(getItemAt, cache, bundleId) {
            return cache.getPointer(`bundleCacheKillers_${bundleId}`, type, wixCode.wixCodeUserScriptsService.paths.CACHE_KILLERS_MAP.concat(bundleId))
        },
        getLoadedChildrenMap(getItemAt, cache) {
            return cache.getPointer('loadedChildren', type, constants.paths.LOADED_CHILDREN)
        },
        getLoadedChildren(getItemAt, cache, parentId) {
            return cache.getPointer(`loadedChildren_${parentId}`, type, constants.paths.LOADED_CHILDREN.concat(parentId))
        },
        getAreChildrenLoadedMap(getItemAt, cache) {
            return cache.getPointer('areChildrenLoaded', type, constants.paths.ARE_CHILDREN_LOADED)
        },
        getAreChildrenLoaded(getItemAt, cache, folderPath) {
            return cache.getPointer(`areChildrenLoaded_${folderPath}`, type, constants.paths.ARE_CHILDREN_LOADED.concat(folderPath))
        },
        getWixCodeModel(getItemAt, cache) {
            return cache.getPointer('wixCodeModel', type, constants.paths.WIX_CODE_MODEL)
        },
        getWixCodeAppData(getItemAt, cache) {
            return cache.getPointer('appData', type, constants.paths.WIX_CODE_APP_DATA)
        },
        getRevisionGridAppId(getItemAt, cache) {
            return cache.getPointer('gridAppId', type, constants.paths.REVISION_GRID_APP_ID)
        },
        getOpenWixCodeAppId(getItemAt, cache) {
            return cache.getPointer('openWixCodeAppId', type, constants.paths.OPEN_WIX_CODE_APP_ID)
        },
        getIsolatedGridAppId(getItemAt, cache) {
            return cache.getPointer('ISOLATED_GRID_APP', type)
        },
        getIsAppReadOnly(getItemAt, cache) {
            return cache.getPointer('isAppReadOnly', type, constants.paths.IS_APP_READ_ONLY)
        },
        getDirectoryFlagByDeletedPathMap(getItemAt, cache) {
            return cache.getPointer('directoryFlagByDeletedPath', type, constants.paths.DIRECTORY_FLAG_BY_DELETED_PATH)
        },
        getWaitForApproval(getItemAt, cache) {
            return cache.getPointer('wixCodeWaitForApproval', 'wixCodeWaitForApproval', ['wixCodeWaitForApproval'])
        }
    }

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions)
})
