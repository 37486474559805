import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {siteDataUtils} = warmupUtils
const {siteConstants} = coreUtilsLib
const SCROLL_PAGE_TOP_Y_POS = -99999
const SCROLL_PAGE_TOP_Y_POS_WITH_OFFSET = 0

function toNumber(measure) {
    const parsedMeasure = parseInt(measure, 10)
    return isNaN(parsedMeasure) ? 0 : parsedMeasure
}

function getWixTopAdsHeight(measureMap) {
    return toNumber(measureMap.height.WIX_ADStop)
}

function calcYOffset(anchorCompData, siteData) {
    let yOffset = 0
    const {measureMap} = siteData

    if (measureMap && anchorCompData) {
        const anchorCompId = anchorCompData.compId
        const isHeaderFixed = siteDataUtils.isHeaderFixedPosition(siteData)
        const topFromMeasureMap = measureMap.top[anchorCompId]
        const absoluteTopFromMeasureMap = measureMap.absoluteTop[anchorCompId]

        const compTop = isHeaderFixed ? topFromMeasureMap : absoluteTopFromMeasureMap
        const wixADSOffset = !siteData.isMobileView() || isHeaderFixed ? 0 : getWixTopAdsHeight(measureMap)
        yOffset = _.isFinite(compTop) ? compTop + wixADSOffset : 0
    }

    return yOffset
}

function getMaxScroll(siteData) {
    if (!siteData.measureMap) {
        return Infinity
    }
    const {measureMap} = siteData

    const wixTopAdsHeight = getWixTopAdsHeight(measureMap)
    const pageHeight = measureMap.height.masterPage + wixTopAdsHeight + siteData.renderFlags.extraSiteHeight
    const windowHeight = measureMap.height.screen
    return Math.max(pageHeight - windowHeight, 0)
}

function normalizeYOffset(anchorYOffset, siteData) {
    return Math.min(anchorYOffset, getMaxScroll(siteData))
}

function getAnchor(anchorQuery, pageId, siteData) {
    const compsInPage = siteData.getPageData(pageId, ['structure', siteData.getViewMode()])
    return _.find(compsInPage, function (comp) {
        const {connectionQuery} = comp
        const connectionData = connectionQuery && siteData.getDataByQuery(connectionQuery, pageId, siteData.dataTypes.CONNECTIONS)
        return connectionData && _.find(connectionData.items, {role: anchorQuery})
    })
}

function getAnchorQuery(anchorQuery, siteAPI) {
    let fetchedAnchorQuery //anchorQuery = nickname/samePageAnchor
    if (!_.startsWith(anchorQuery, 'dataItem') && siteAPI) {
        const siteData = siteAPI.getSiteData()
        const anchor = getAnchor(anchorQuery, siteData.getPrimaryPageId(), siteData)
        if (anchor) {
            fetchedAnchorQuery = anchor.dataQuery
        }
    }
    return fetchedAnchorQuery || anchorQuery
}

function calcAnchorScrollToPosition(anchorQuery, siteAPI) {
    const siteData = siteAPI.getSiteData()
    const anchorPosition = calcAnchorPosition(getAnchorQuery(anchorQuery, siteAPI), siteData)
    anchorPosition.y = normalizeYOffset(anchorPosition.y, siteData)
    return anchorPosition
}

function calcAnchorPosition(anchorQuery, siteData) {
    let anchorYOffset = 0

    if (anchorQuery === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID) {
        anchorYOffset = SCROLL_PAGE_TOP_Y_POS_WITH_OFFSET
    } else if (anchorQuery === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID) {
        anchorYOffset = toNumber(_.get(siteData.measureMap, ['height', 'masterPage']))
    } else if (anchorQuery) {
        const anchorCompData = siteData.getDataByQuery(anchorQuery, siteData.getPrimaryPageId())
        anchorYOffset = calcYOffset(anchorCompData, siteData)
    }

    return {
        x: 0,
        y: anchorYOffset,
        anchorQuery
    }
}

function getPageAnchors(siteData, pageId, pageTopLabel?) {
    const pageData = siteData.getPageDataMap(pageId, siteData.dataTypes.DATA)
    if (!pageData) {
        return []
    }

    const topAnchorData = getPageTopAnchor(siteData.getPrimaryPageId(), pageTopLabel)
    const pageComponents = siteData.getPageData(pageId, ['structure', siteData.getViewMode()])

    const pageAnchors = _(pageData)
        .filter({type: 'Anchor'})
        .sortBy(pageAnchor => _.get(pageComponents, [pageAnchor.compId, 'layout', 'y']))
        .value()

    return [topAnchorData, ...pageAnchors]
}

function getPageTopAnchor(pageId, pageTopLabel) {
    return {
        compId: siteConstants.PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
        id: siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID,
        name: pageTopLabel || '',
        type: 'Anchor',
        pageId
    }
}

function getShowingPageAnchorsSortedByStructureY(siteData, hiddenAnchorsIds?) {
    hiddenAnchorsIds = hiddenAnchorsIds || []
    //I think it should collect anchors from all pages, though now we have only on primary
    const primaryPageId = siteData.getPrimaryPageId()
    const pageAnchors = getPageAnchors(siteData, primaryPageId)
    if (!pageAnchors.length) {
        return pageAnchors
    }
    const filteredPageAnchors = _.reject(pageAnchors, function (anchor) {
        return _.includes(hiddenAnchorsIds, anchor.compId)
    })
    return _.size(filteredPageAnchors) ? filteredPageAnchors : [pageAnchors[0]]
}

function getSortedAnchorsByMeasureMapY(siteData, anchorsToSort) {
    return _(anchorsToSort)
        .map(function (anchor) {
            const position = calcAnchorPosition(anchor.id, siteData)
            const {id, compId} = anchor
            return _.assign(position, {id, compId})
        })
        .sortBy('y')
        .value()
}

function formatActiveAnchorResult(activeAnchorComp, index, total) {
    return {
        activeAnchorComp,
        index,
        total
    }
}

function getFormattedObjects(anchors, scrollY) {
    return _.map(anchors, function (anchor, i) {
        const o = formatActiveAnchorResult(anchor, i, anchors.length)
        // @ts-ignore
        o.isActive = anchor.y <= Math.ceil(scrollY)
        return o
    })
}

function findLastAnchorWithYBiggerThan(sortedAnchorsByYPos, yPos) {
    const everyYZero = _.every(sortedAnchorsByYPos, {y: 0})
    if (everyYZero) {
        return null
    }

    let activeAnchorComp = null
    let i = 0
    for (; i < sortedAnchorsByYPos.length; i++) {
        const anchor = sortedAnchorsByYPos[i]
        if (yPos >= anchor.y) {
            activeAnchorComp = anchor
        } else {
            break
        }
    }
    if (activeAnchorComp) {
        return formatActiveAnchorResult(activeAnchorComp, i - 1, sortedAnchorsByYPos.length)
    }
    return null
}

function getAnchorsDataSortedByY(siteData, scrollY) {
    if (!siteData.measureMap) {
        return []
    }

    const shownPageAnchors = getShowingPageAnchorsSortedByStructureY(siteData)
    const sortedAnchorsByY = getSortedAnchorsByMeasureMapY(siteData, shownPageAnchors)
    return getFormattedObjects(sortedAnchorsByY, scrollY)
}

function getActiveAnchor(siteData, scrollPosition, hiddenAnchorsIds) {
    if (!siteData.measureMap) {
        return null
    }

    const shownPageAnchors = getShowingPageAnchorsSortedByStructureY(siteData, hiddenAnchorsIds)
    const pageAnchors = getSortedAnchorsByMeasureMapY(siteData, shownPageAnchors)
    const activeAnchor = findLastAnchorWithYBiggerThan(pageAnchors, scrollPosition)
    if (activeAnchor && getMaxScroll(siteData) <= scrollPosition) {
        return formatActiveAnchorResult(_.last(pageAnchors), pageAnchors.length - 1, pageAnchors.length)
    }

    return activeAnchor
}

function getHighestAnchorByStructure(siteData, scrollPosition, hiddenAnchorsIds) {
    const shownPageAnchors = getShowingPageAnchorsSortedByStructureY(siteData, hiddenAnchorsIds)
    const pageAnchorsWithY = getPageAnchorsWithYPosition(siteData, shownPageAnchors)
    return formatActiveAnchorResult(_.head(pageAnchorsWithY), 0, shownPageAnchors.length)
}

function getPageAnchorsWithYPosition(siteData, anchors) {
    anchors = anchors || []
    const pageComponents = siteData.getPageData(siteData.getPrimaryPageId(), ['structure', siteData.getViewMode()])
    return anchors.map((anchor, idx) => {
        const yFromStructure = _.get(pageComponents, [anchor.compId, 'layout', 'y'], Number.MAX_VALUE)
        const positionY = anchor.id === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID ? 0 : yFromStructure
        return _.assign({x: 0, y: positionY}, {id: anchor.id, compId: anchor.compId, isActive: idx === 0})
    })
}

function isSpecialAnchor(anchorDataId) {
    return anchorDataId === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID || anchorDataId === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID
}

export default {
    /**
     * calc the anchor position by the given anchor data
     * @param anchorQuery
     * @param siteData
     * @returns {{x: number, y: *}}
     */
    getPageTopAnchor,
    calcAnchorScrollToPosition,
    getPageAnchors,
    getActiveAnchor,
    getAnchorsDataSortedByY,
    getAnchor,
    getHighestAnchorByStructure,
    normalizeYOffset,
    isSpecialAnchor,
    getSortedAnchorsByY: getSortedAnchorsByMeasureMapY,
    SCROLL_PAGE_TOP_Y_POS
}
