define(['lodash', 'documentServices/dataModel/dataModel'], function (_, dataModel) {
    'use strict'

    return {
        componentsClasses: ['wixapps.integration.components.AppPart'],
        collect(ps, comp) {
            const compData = dataModel.getDataItem(ps, comp)

            if (!compData) {
                return []
            }

            const partData = compData.appPartName ? compData : compData.dataItemRef

            return _.get(partData, 'appLogicCustomizations', []).reduce((styles, rule) => {
                if (rule.key === 'comp.style' && rule.value) {
                    styles.push(rule.value)
                }

                return styles
            }, [])
        }
    }
})
