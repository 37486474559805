import {fullAPI} from './index'
import {ViewerLibrary, createPublicMethodUtils} from './apiUtils/publicMethods'
import {deprecationUtil} from './apiUtils/deprecation'
import _ from 'lodash'

function initPublicModules(viewerLibrary: ViewerLibrary, modulesList?: string[]) {
    const publicMethodUtils = createPublicMethodUtils(viewerLibrary)
    const deprecation = deprecationUtil(viewerLibrary, publicMethodUtils)
    modulesList = modulesList ?? Object.keys(fullAPI)
    return _.map(modulesList, moduleName => {
        const moduleInitializer = fullAPI[moduleName]
        if (moduleInitializer) {
            return moduleInitializer(viewerLibrary, publicMethodUtils, deprecation)
        }
        throw new Error(`Cannot find public module "${moduleName}".`)
    })
}

export {initPublicModules, fullAPI}
