define(['lodash', 'experiment', 'documentServices/saveAPI/lib/saveRunner', 'documentServices/bi/bi', 'documentServices/extensionsAPI/extensionsAPI'], function (
    _,
    experiment,
    taskRunner,
    bi,
    extensionsAPI
) {
    'use strict'

    function getBiCallbacks(privateServices) {
        return {
            event: _.partial(bi.event, privateServices),
            error: _.partial(bi.error, privateServices)
        }
    }

    function runSaveTask(privateServices, task, isFullSave, onSuccess, onError) {
        const onlyWixCodeTasksRegistry = {
            primaryTask: task,
            secondaryTasks: []
        }

        function onTaskRunnerError(e) {
            onError(e.document)
        }

        if (experiment.isOpen('dm_wixCodeWaitForApprovalBeforeSave')) {
            taskRunner.runFunctionInSaveQueue(async () => {
                await extensionsAPI.csave.forceSaveAndWaitForResult(privateServices)
            })
        }

        const runSave = isFullSave ? taskRunner.runFullSaveTasks : taskRunner.runPartialSaveTasks
        runSave(onlyWixCodeTasksRegistry, privateServices, onSuccess, onTaskRunnerError, getBiCallbacks(privateServices))
    }

    return runSaveTask
})
