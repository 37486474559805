import * as tpaComponents from '../../../_internal/tpaComponents'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const dummyForBIEvents = function () {}

export default {
    /*Dummy handlers for BI*/
    toWixDate: dummyForBIEvents,
    getCompId: dummyForBIEvents,
    getOrigCompId: dummyForBIEvents,
    getWidth: dummyForBIEvents,
    isInModal: dummyForBIEvents,
    getLocale: dummyForBIEvents,
    getCacheKiller: dummyForBIEvents,
    getTarget: dummyForBIEvents,
    getInstanceId: dummyForBIEvents,
    getSignDate: dummyForBIEvents,
    getUid: dummyForBIEvents,
    getPermissions: dummyForBIEvents,
    getIpAndPort: dummyForBIEvents,
    getDemoMode: dummyForBIEvents,
    getDeviceType: dummyForBIEvents,
    getInstanceValue: dummyForBIEvents,
    getSiteOwnerId: dummyForBIEvents,
    getImageUrl: dummyForBIEvents,
    getResizedImageUrl: dummyForBIEvents,
    getAudioUrl: dummyForBIEvents,
    getDocumentUrl: dummyForBIEvents,
    getSwfUrl: dummyForBIEvents,
    getPreviewSecureMusicUrl: dummyForBIEvents,
    getStyleParams: (siteAPI, msg) => {
        const appDefId = tpaComponents.common.utils.getAppDefId(siteAPI, msg.compId)
        coreUtils.loggingUtils.performance.start(`getStyleParams ${appDefId}`, tpaComponents.common.metaData.PERF_CATEGORY)
    },
    getStyleColorByKey: dummyForBIEvents,
    getColorByreference: dummyForBIEvents,
    getEditorFonts: dummyForBIEvents,
    getSiteTextPresets: dummyForBIEvents,
    getFontsSpriteUrl: dummyForBIEvents,
    getStyleFontByKey: dummyForBIEvents,
    getStyleFontByReference: dummyForBIEvents,
    getSiteColors: dummyForBIEvents,
    setUILIBParamValue: dummyForBIEvents,

    tpaWorker: {
        /**/
        getViewMode: dummyForBIEvents,
        getDeviceType: dummyForBIEvents,
        getLocale: dummyForBIEvents,
        getInstanceId: dummyForBIEvents,
        getIpAndPort: dummyForBIEvents
    }
}
