define(['lodash', '@wix/wix-immutable-proxy', '@wix/document-services-json-schemas', 'documentServices/utils/utils'], function (
    _,
    wixImmutableProxy,
    documentServicesJsonSchemas,
    utils
) {
    'use strict'

    const {
        namespaceMapping: {NAMESPACE_MAPPING}
    } = documentServicesJsonSchemas
    const {deepClone} = wixImmutableProxy
    const {getPointer} = utils

    class SnapshotDalDataProvider {
        constructor(snapshotDal) {
            this.snapshotDal = snapshotDal
        }

        toJS() {
            return this.snapshotDal.toJS()
        }

        toJSForType(type) {
            return this.snapshotDal.toJSForType(type)
        }

        exists(pointer) {
            return this.snapshotDal.exists(pointer)
        }

        getValue(pointer) {
            return this.snapshotDal.getValue(pointer)
        }

        diff(from) {
            return this.snapshotDal.diff(from.snapshotDal)
        }

        getAndCloneValue(pointer) {
            return deepClone(this.snapshotDal.getValue(pointer))
        }

        rendererModel(id, innerPath) {
            return this.getAndCloneValue(getPointer('rendererModel', id, innerPath))
        }

        documentServicesModel(id, innerPath) {
            return this.getAndCloneValue(getPointer('documentServicesModel', id, innerPath))
        }

        serviceTopology(key) {
            return this.getAndCloneValue(getPointer('serviceTopology', 'serviceTopology', key))
        }

        pagesPlatformApplications(appId) {
            return this.getAndCloneValue(getPointer('pagesPlatformApplications', 'pagesPlatformApplications', appId))
        }

        wixCodeNonUndoable(innerPath) {
            return this.getAndCloneValue(getPointer('wixCode', 'wixCode', innerPath))
        }

        wixCodeUndoable(innerPath) {
            return this.getAndCloneValue(getPointer('wixCodeUndoable', 'wixCode', innerPath))
        }
    }

    const savePaths = [
        {path: ['multilingualTranslations']},
        {path: ['DESKTOP']},
        {path: ['MOBILE']},
        {path: ['orphanPermanentDataNodes']},
        {path: ['routers']},

        {path: ['pagesPlatformApplications', 'pagesPlatformApplications']},
        {path: ['semanticAppVersions', 'semanticAppVersions']},

        {path: ['rendererModel'], id: 'siteMetaData'},
        {path: ['rendererModel'], id: 'pageToHashedPassword'},
        {path: ['rendererModel'], id: 'siteInfo'},
        {path: ['rendererModel'], id: 'metaSiteId'},
        {path: ['rendererModel'], id: 'premiumFeatures'},
        {path: ['rendererModel'], id: 'clientSpecMap'},
        {path: ['rendererModel'], id: 'wixCodeModel'},

        {path: ['documentServicesModel'], id: 'siteName'},
        {path: ['documentServicesModel'], id: 'editorSessionId'},
        {path: ['documentServicesModel'], id: 'customHeadTags'},
        {path: ['documentServicesModel'], id: 'metaSiteData'}
    ].concat(Object.keys(NAMESPACE_MAPPING).map(namespace => ({path: [namespace]})))

    const shouldSaveDiff = (diff, additionalPaths = []) => {
        for (const {path, id} of _.concat(savePaths, additionalPaths)) {
            const value = _.get(diff, path)
            if (typeof value === 'object' && value !== null) {
                if (id) {
                    if (value.hasOwnProperty(id)) {
                        return true
                    }
                } else if (!_.isEmpty(value)) {
                    return true
                }
            }
        }
        return false
    }

    const revisionPointer = {type: 'documentServicesModel', id: 'versionInfo', innerPath: ['revision']}
    const versionPointer = {type: 'documentServicesModel', id: 'versionInfo', innerPath: ['version']}

    const santaVersionPath = ['documentServicesModel', 'version']
    const santaRevisionPath = ['documentServicesModel', 'revision']

    const versionPath = ['documentServicesModel', 'versionInfo', 'version']
    const revisionPath = ['documentServicesModel', 'versionInfo', 'revision']

    return {
        santaVersionPath,
        santaRevisionPath,
        versionPath,
        revisionPath,
        revisionPointer,
        versionPointer,
        shouldSaveDiff,
        SnapshotDalDataProvider
    }
})
