define([
    'lodash',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/wixapps/utils/pathUtils',
    'documentServices/wixapps/utils/classicsPathUtils',
    'documentServices/wixCode/utils/constants',
    'documentServices/constants/constants'
], function (_, siteMetaData, wixappsPathUtils, classicsPathUtils, wixCodeConstants, constants) {
    'use strict'

    let whiteList = [
        'pagesData',
        'wixapps',
        'editorData',
        'committedMobilePages',
        'textRuntimeLayout',
        'deletedPagesMap',
        'orphanPermanentDataNodes',
        'dockedRuntimeLayout',
        'displayedOnlyComponents',
        'mobileStructures',
        'contactforms_metadata',
        'pagesPlatformApplications',
        'routers',
        'platform',
        'siteAspectsData',
        'wixCode',
        'mobileConversionHeuristicStrategy'
    ]

    const fullJsonPaths = ['pagesData']
    const wixappsApplicationInstanceVersion = {
        path: wixappsPathUtils.getApplicationInstanceVersionPath(),
        optional: true
    }
    const wixappsMetaData = _.map(['blog', 'ecommerce', 'faq', 'menu'], function (packageName) {
        return {
            path: classicsPathUtils.getPackageMetaDataPath(packageName),
            optional: true
        }
    })
    const wixappsApplicationMetaData = {
        path: wixappsPathUtils.getPackageMetaDataPath(),
        optional: true
    }
    const documentServicesOrigin = {
        path: ['origin'],
        optional: true
    }

    const pagesWixCodeApplication = {
        path: ['pagesPlatformApplications', 'wixCode'],
        optional: true
    }

    const appManifest = {
        path: ['platform', 'appManifest'],
        innerDepth: 1
    }

    const appPublicApiName = {
        path: ['platform', 'appPublicApiName'],
        innerDepth: 1
    }

    const appState = {
        path: ['platform', 'appState'],
        innerDepth: 1
    }

    const wixCodeNonUndoableBasePath = {
        path: wixCodeConstants.paths.NON_UNDOABLE_BASE_PATH,
        optional: true
    }

    const ghostStructureDataPath = {
        path: ['ghostStructureData'],
        optional: true
    }

    const ghostControllersDataPath = {
        path: ['ghostControllersData'],
        optional: true
    }

    const devSiteAppDefId = {
        path: ['devSiteAppDefId'],
        optional: true
    }

    const semanticAppVersionsPath = {
        path: ['semanticAppVersions'],
        optional: true
    }

    let nonUndoableList = [
        'documentServicesModel',
        'rendererModel',
        'serviceTopology',
        'urlFormatModel',
        'runtime',
        'compsToUpdateAnchors',
        'svgShapes',
        'renderFlags',
        'renderRealtimeConfig',
        'rootsRenderedInMobileEditor',
        wixappsApplicationInstanceVersion,
        wixappsApplicationMetaData,
        documentServicesOrigin,
        wixCodeNonUndoableBasePath,
        pagesWixCodeApplication,
        ghostStructureDataPath,
        ghostControllersDataPath,
        devSiteAppDefId,
        semanticAppVersionsPath
    ].concat(wixappsMetaData)

    const nonUndoableInitialDataPaths = [appManifest, appState, appPublicApiName]

    // concatenate MetaData paths
    whiteList = whiteList.concat(siteMetaData.getRevertibleMetaDataInfo())
    nonUndoableList = nonUndoableList.concat(siteMetaData.getNonRevertibleMetaDataInfo())

    function getPath(item) {
        if (_.isString(item)) {
            return [item]
        } else if (_.isPlainObject(item)) {
            return item.path
        }
        return item
    }

    function convertToPathsArray(list) {
        return _.map(list, getPath)
    }

    function isOptional(item) {
        return !!item.optional
    }

    return {
        getWhiteList() {
            return convertToPathsArray(whiteList)
        },
        getNonUndoableInitialDataPaths() {
            return nonUndoableInitialDataPaths
        },
        getNonUndoableList() {
            return convertToPathsArray(nonUndoableList)
        },
        getPathsInJsonData() {
            const pathsConfig = {}
            // @ts-ignore
            const allPaths = whiteList.concat(nonUndoableList).concat('isSaveInProgress', 'isSaveAllowedByDSconfig')
            const displayedJsonPaths = _.without(allPaths, fullJsonPaths)
            pathsConfig[constants.JSON_TYPES.DISPLAYED] = _.map(displayedJsonPaths, item => ({
                path: getPath(item),
                optional: isOptional(item)
            }))
            pathsConfig[constants.JSON_TYPES.FULL] = [
                {
                    path: fullJsonPaths,
                    optional: false
                }
            ]
            return pathsConfig
        },
        getAutosavePaths() {
            return [
                'rendererModel.pageToHashedPassword',
                'rendererModel.passwordProtectedPages',
                'rendererModel.siteMetaData',
                'pagesData',
                'orphanPermanentDataNodes',
                'documentServicesModel.metaSiteData',
                'documentServicesModel.customHeadTags',
                'routers',
                'pagesPlatformApplications',
                'rendererModel.wixCodeModel'
            ]
        },

        getSavePaths() {
            return [
                ['pagesData'],
                ['rendererModel', 'siteMetaData'],
                ['rendererModel', 'pageToHashedPassword'],
                ['rendererModel', 'siteInfo'],
                ['rendererModel', 'metaSiteId'],
                ['rendererModel', 'premiumFeatures'],
                ['rendererModel', 'clientSpecMap'],
                ['rendererModel', 'wixCodeModel', 'appData'],
                ['documentServicesModel', 'siteName'],
                ['documentServicesModel', 'editorSessionId'],
                ['documentServicesModel', 'customHeadTags'],
                ['documentServicesModel', 'metaSiteData'],
                ['orphanPermanentDataNodes'],
                ['routers'],
                ['pagesPlatformApplications'],
                ['semanticAppVersions']
            ]
        }
    }
})
