define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/component/sospModes',
    'documentServices/modes/modesUtils',
    'documentServices/dataModel/dataIds',
    'documentServices/dataModel/dataModel',
    'documentServices/utils/utils'
], function (_, coreUtils, sospModes, modesUtils, dataIds, dataModel, dsUtils) {
    'use strict'

    const {DATA_TYPES} = coreUtils.constants
    const PAGES_GROUP_COLLECTION_ID = 'PAGES_GROUP_COLLECTION'
    const PAGES_GROUP_COLLECTION_TYPE = 'PagesGroupCollection'
    const PAGES_GROUP_TYPE = 'PagesGroupData'

    function isPagesGroupCollectionExist(ps) {
        return Boolean(dataModel.getDataItemById(ps, PAGES_GROUP_COLLECTION_ID))
    }

    function getDataItemPointer(ps, dateQuery) {
        return ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(dateQuery), 'masterPage')
    }

    function getAllPagesGroups(ps) {
        const pagesGroupCollectionPointer = ps.pointers.data.getDataItem(PAGES_GROUP_COLLECTION_ID, 'masterPage')

        if (!pagesGroupCollectionPointer) {
            return []
        }

        const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')
        const allPagesGroupsQueries = ps.dal.full.get(groupsPointer)

        return _.map(allPagesGroupsQueries, getDataItemPointer.bind(null, ps))
    }

    function getAllPagesGroupsSerialized(ps) {
        return _.map(getAllPagesGroups(ps), serializePagesGroup.bind(null, ps))
    }

    function createPagesGroupCollection(ps) {
        const pagesGroupCollection = dataModel.createDataItemByType(ps, PAGES_GROUP_COLLECTION_TYPE)

        dataModel.addSerializedDataItemToPage(ps, 'masterPage', pagesGroupCollection, PAGES_GROUP_COLLECTION_ID)
    }

    function addGroupToPagesGroupCollection(ps, newGroupId) {
        const pagesGroupCollectionPointer = ps.pointers.data.getDataItemFromMaster(PAGES_GROUP_COLLECTION_ID)
        const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')

        ps.dal.push(groupsPointer, `#${newGroupId}`)
    }

    function hasPagesGroupsIntersection(pagesGroupToCheck, pagesIds) {
        return _.some(pagesGroupToCheck, pagesGroup => _.some(pagesIds, pageId => _.includes(pagesGroup.pages, pageId)))
    }

    function validateGroupsIntersection(ps, updatedPagesGroupId, newPages) {
        const allPagesGroups = getAllPagesGroupsSerialized(ps)
        const pagesGroupToCheck = _.reject(allPagesGroups, {id: updatedPagesGroupId})

        if (hasPagesGroupsIntersection(pagesGroupToCheck, newPages)) {
            throw new Error('pagesGroup: page already exists in another pagesGroup')
        }
    }

    function validatePagesGroup(ps, pagesGroup, pagesGroupId) {
        const allPagesGroups = getAllPagesGroupsSerialized(ps)

        if (_.isEmpty(pagesGroup.groupName)) {
            throw new Error('pagesGroup: groupName is required')
        }

        if (_.some(allPagesGroups, ['groupName', pagesGroup.groupName])) {
            throw new Error('pagesGroup: groupName must be unique')
        }

        if (_.has(pagesGroup, 'appId') && _.some(allPagesGroups, ['appId', pagesGroup.appId])) {
            throw new Error('pagesGroup: appId must be unique')
        }

        validateGroupsIntersection(ps, pagesGroupId, pagesGroup.pages)
    }

    function getNewPagesGroupData(ps, pagesGroup) {
        const pagesGroupDefaults = dataModel.createDataItemByType(ps, PAGES_GROUP_TYPE)

        return _.defaults(pagesGroup, pagesGroupDefaults)
    }

    function createPagesGroup(ps, pagesGroupId, pagesGroup) {
        const newPagesGroup = getNewPagesGroupData(ps, pagesGroup)
        validatePagesGroup(ps, newPagesGroup, pagesGroupId)
        dataModel.addSerializedDataItemToPage(ps, 'masterPage', newPagesGroup, pagesGroupId)

        if (!isPagesGroupCollectionExist(ps)) {
            createPagesGroupCollection(ps)
        }

        addGroupToPagesGroupCollection(ps, pagesGroupId)
    }

    function removeSOSPModeIfExist(ps, pagesGroupPointer) {
        const sospMode = modesUtils.getSospModeByPagesGroup(ps, pagesGroupPointer)

        if (sospMode) {
            sospModes.removeSOSPMode(ps, sospMode.modeId)
        }
    }

    function removeFromPagesGroupCollection(ps, pagesGroupPointer) {
        const pagesGroupCollectionPointer = ps.pointers.data.getDataItemFromMaster(PAGES_GROUP_COLLECTION_ID)
        const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')
        const groups = ps.dal.get(groupsPointer)
        const newGroups = _.pull(groups, `#${pagesGroupPointer.id}`)

        ps.dal.set(groupsPointer, newGroups)
    }

    function removePagesGroup(ps, pagesGroupPointer) {
        if (!ps.dal.isExist(pagesGroupPointer)) {
            return
        }

        removeSOSPModeIfExist(ps, pagesGroupPointer)
        removeFromPagesGroupCollection(ps, pagesGroupPointer)
        dataModel.removeItemRecursivelyByType(ps, pagesGroupPointer)
    }

    function getPagesGroupByPredicate(ps, predicate) {
        const pagesGroup = _.find(getAllPagesGroupsSerialized(ps), predicate)
        return pagesGroup ? ps.pointers.data.getDataItem(pagesGroup.id, 'masterPage') : null
    }

    function getPagesGroupByPageId(ps, pageId) {
        const newPageQuery = `#${pageId}`

        return getPagesGroupByPredicate(ps, function (pagesGroupData) {
            return _.includes(pagesGroupData.pages, newPageQuery)
        })
    }

    function getPagesGroupById(ps, pagesGroupId) {
        return getPagesGroupByPredicate(ps, {id: pagesGroupId})
    }

    function getPagesGroupByGroupName(ps, groupName) {
        return getPagesGroupByPredicate(ps, {groupName})
    }

    function getPagesGroupByAppId(ps, appId) {
        return getPagesGroupByPredicate(ps, {appId})
    }

    function serializePagesGroup(ps, pagesGroupPointer) {
        if (!pagesGroupPointer) {
            return null
        }
        return ps.dal.get(pagesGroupPointer)
    }

    function addPageToPagesGroup(ps, pagesGroupPointer, pageId) {
        const groupPagesPointer = ps.pointers.getInnerPointer(pagesGroupPointer, 'pages')
        const groupPages = ps.dal.get(groupPagesPointer)
        const newPageQuery = `#${pageId}`

        if (groupPages && !_.includes(groupPages, newPageQuery)) {
            validateGroupsIntersection(ps, pagesGroupPointer.id, [newPageQuery])
            ps.dal.push(groupPagesPointer, newPageQuery)
            ps.siteAPI.updateActiveSOSPModes()
        }
    }

    function removePageFromPagesGroup(ps, pagesGroupPointer, pageId) {
        const groupPagesPointer = ps.pointers.getInnerPointer(pagesGroupPointer, 'pages')
        const groupPages = ps.dal.get(groupPagesPointer)
        const pageToRemoveQuery = `#${pageId}`

        if (_.includes(groupPages, pageToRemoveQuery)) {
            const newPages = _.without(groupPages, pageToRemoveQuery)
            if (_.isEmpty(newPages)) {
                removePagesGroup(ps, pagesGroupPointer)
            } else {
                ps.dal.set(groupPagesPointer, newPages)
            }
            ps.siteAPI.updateActiveSOSPModes()
        }
    }

    function getComponentPagesGroup(ps, compPointer) {
        const pagesGroupId = sospModes.getComponentPagesGroupId(ps, compPointer)

        return pagesGroupId ? getPagesGroupById(ps, pagesGroupId) : null
    }

    function getNewPagesGroupId() {
        return dataIds.generateNewId(DATA_TYPES.data)
    }

    return {
        createPagesGroup,
        removePagesGroup,
        getNewPagesGroupId,
        getAll: getAllPagesGroups,
        getPagesGroupById,
        getPagesGroupByGroupName,
        getPagesGroupByAppId,
        getPagesGroupByPageId,
        getComponentPagesGroup,
        serializePagesGroup,
        addPageToPagesGroup,
        removePageFromPagesGroup
    }
})
