define(['lodash', 'documentServices/hooks/layoutHooks/utils/layoutHooksUtils'], function (_, layoutHooksUtils) {
    'use strict'

    function updateChildLayout(ps, compPointer, layoutToUpdate, updateCompLayoutFn, isTriggeredByHook, previousLayout) {
        const [childCompPointer] = ps.pointers.components.getChildren(compPointer)
        if (childCompPointer && layoutHooksUtils.isCompResizing(previousLayout, layoutToUpdate) && _.isFunction(updateCompLayoutFn)) {
            const dx = _.isUndefined(layoutToUpdate.x) ? 0 : layoutToUpdate.x - previousLayout.x
            const dy = _.isUndefined(layoutToUpdate.y) ? 0 : layoutToUpdate.y - previousLayout.y
            const rootLayoutUpdate = _.defaults({x: dx, y: dy}, layoutToUpdate)
            updateCompLayoutFn(ps, childCompPointer, rootLayoutUpdate)
        }
    }

    return {
        updateChildLayout
    }
})
