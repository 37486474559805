import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import wixApps from '@wix/santa-ds-libs/src/wixappsCore'

const {typesConverter} = wixApps
const {baseProxy} = wixApps

/**
 * @class proxies.NumericStepper
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],

    renderProxy() {
        const data = this.proxyData
        const componentType = 'wysiwyg.common.components.NumericStepper'
        const props = this.getChildCompProps(componentType)
        props.compData = typesConverter.numeric(data.value)

        props.compProp = {
            minValue: data.minValue,
            maxValue: data.maxValue
        }
        props.onInputChange = this.handleViewEvent
        props.onInputChangedFailed = this.handleViewEvent
        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
