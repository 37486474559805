import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const browserThemeColor = viewerLibrary.get('documentServices/browserThemeColor/browserThemeColor')
    return {
        methods: {
            browserThemeColor: {
                get: publicMethodUtils.defineGetter(browserThemeColor.getBrowserThemeColor),
                set: publicMethodUtils.defineDataManipulationAction(browserThemeColor.setBrowserThemeColor),
                reset: publicMethodUtils.defineDataManipulationAction(browserThemeColor.resetBrowserThemeColor),
                isSet: publicMethodUtils.defineGetter(browserThemeColor.isBrowserThemeColorSet)
            }
        }
    }
}
