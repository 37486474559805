import _ from 'lodash'

/**
 * NOTE: Do nto use! will be replaced with whatever responsive solution we have.
 * Element queries for layout
 * Uses data attributes in the form of 'data-element-query-<measure>-<name>'
 * @example
 * const controlsElementQueries = {
 *    width: [
 *       {min: 0, max: 350, name: 'minimal'},
 *       {min: 0, max: 450, name: 'small'},
 *       {min: 450, max: 1200, name: 'normal'},
 *       {min: 1200, max: Infinity, name: 'large'}
 *    ]
 * };
 * patchers.attr(id, getElementQueries(id, measureMap, controlsElementQueries));
 *
 * @param id
 * @param measureMap
 * @param elementQueries
 * @returns {object}
 */
function getElementQueries(id, measureMap, elementQueries) {
    const queryPrefix = 'data-element-query-'
    const queryAttributes = {}
    _.forEach(elementQueries, function (queries, type) {
        const measure = _.get(measureMap, [type, id])
        if (measure) {
            queryAttributes[queryPrefix + type] = ''
            _.forEach(queries, function (query) {
                if (query.min < measure && query.max > measure) {
                    if (queryAttributes[queryPrefix + type]) {
                        queryAttributes[queryPrefix + type] += ' '
                    }
                    queryAttributes[queryPrefix + type] += query.name
                }
            })
        }
    })
    return queryAttributes
}

export default getElementQueries
