import _ from 'lodash'
import linksConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/linksConverter'

/**
 * @class wixappsCore.typesConverter
 */
function getPageLink(getDataById, pageId) {
    if (!pageId) {
        return null
    }

    pageId = pageId.id || pageId
    const pageLinkData = linksConverter(
        {
            _type: 'wix:PageLink',
            pageId
        },
        getDataById
    )

    return _.omit(pageLinkData, 'id')
}

export default {
    richText(data, links, pagesDataItem, refDataMap?) {
        return {
            type: 'StyledText',
            text: _.isString(data) ? data : data.text,
            linkList: _.map(links, function (linkData) {
                return linksConverter(linkData, pagesDataItem)
            }),
            innerCompsData: refDataMap
        }
    },

    image(data, dataResolver, serviceTopology, packageName, imageQuality) {
        data = dataResolver(data, serviceTopology, packageName)
        const imageData = {
            type: 'Image',
            uri: data.src,
            title: data.title,
            width: parseInt(data.width, 10),
            height: parseInt(data.height, 10)
        }

        if (!_.isEmpty(imageQuality)) {
            // @ts-ignore
            imageData.quality = imageQuality
        }

        return imageData
    },

    videoThumb(data, dataResolver, serviceTopology) {
        const imageData = {
            src: data.imageSrc,
            title: '',
            width: 480,
            height: 360
        }

        return this.image(imageData, dataResolver, serviceTopology)
    },

    table(columnsDefinition) {
        return {
            type: 'Table',
            columnsStyle: _.map(columnsDefinition, function (def, index) {
                if (!def.item) {
                    throw new Error(`missing definition for item in column ${index}`)
                }

                return def.item.styleData
            })
        }
    },

    video(data) {
        return {
            type: 'Video',
            videoId: data.videoId,
            videoType: data.videoType
        }
    },

    linkableButton(data, pagesDataItems, siteData?) {
        const link = data && (data.linkId ? siteData.getDataByQuery(data.linkId) : getPageLink(pagesDataItems, data.pageId))
        const label = _.isString(data) ? data : data && data.label
        return {
            type: 'LinkableButton',
            label: _.unescape(label),
            link: link || null
        }
    },

    selectableList(data) {
        return {
            type: 'SelectableList',
            options: _.map(data.items, function (item) {
                return {
                    type: 'SelectOption',
                    value: item.value,
                    text: _.isFunction(item.getText) ? item.getText(data.selectedValue) : item.text,
                    disabled: item.enabled === false,
                    description: item.description
                }
            }),
            value: data.selectedValue
        }
    },

    text(data, maxLength?) {
        const compData: any = {
            type: 'TextInput',
            value: _.unescape(data)
        }

        if (maxLength) {
            compData.maxLength = maxLength
        }

        return compData
    },

    numeric(data) {
        return {
            type: 'NumericStepper',
            value: _.unescape(data)
        }
    },

    icon(data, dataResolver, serviceTopology, packageName) {
        data = dataResolver(data, serviceTopology, packageName)
        return {
            type: 'Icon',
            url: data.src,
            width: data.width,
            height: data.height,
            title: data.title
        }
    },

    link: linksConverter,

    imageList(data, dataResolver, serviceTopology, globalImageQuality, pagesDataItem) {
        return {
            type: 'ImageList',
            items: _.map(
                data.items,
                function (item) {
                    return _.merge(this.image(item, dataResolver, serviceTopology, globalImageQuality), {
                        description: item.description || '',
                        link: this.link(item.link, pagesDataItem)
                    })
                }.bind(this)
            )
        }
    }
}
