define(['documentServices/extensionsAPI/extensionsAPI'], function (extensionsAPI) {
    'use strict'

    const setSaveProgress = (ps, isSaving) => extensionsAPI.saveState.setSaveProgress(ps, isSaving)
    const isSaveInProgress = ps => extensionsAPI.saveState.isSaveInProgress(ps)
    const setSaveAllowed = (ps, isAllowed) => extensionsAPI.saveState.setSaveAllowed(ps, isAllowed)
    const isEnabled = ps => extensionsAPI.saveState.isSaveAllowed(ps)
    const canSave = ps => extensionsAPI.saveState.canSave(ps)
    const setPublishProgress = (ps, isPublishing) => extensionsAPI.saveState.setSaveProgress(ps, isPublishing)
    const isPublishInProgress = ps => extensionsAPI.saveState.isSaveInProgress(ps)
    return {
        setSaveProgress,
        isSaveInProgress,
        canSave,
        isEnabled,
        setSaveAllowed,
        setPublishProgress,
        isPublishInProgress
    }
})
