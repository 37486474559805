define([
    'documentServices/saveAPI/saveTasks/saveDocument',
    'documentServices/wixapps/services/savePublishTaskFactory',
    'documentServices/wixapps/services/save/wixappsBlogSavePlugin',
    'documentServices/wixCode/saveTasks/saveWixCodeAppsTask',
    'documentServices/wixCode/saveTasks/saveCode',
    'documentServices/wixCode/saveTasks/markAppImmutable',
    'documentServices/tpa/saveTasks/saveUnprovisionedApps',
    'documentServices/tpa/saveTasks/saveUnprovisionedAppsForAppFlows',
    'documentServices/tpa/saveTasks/saveEventDispatcher',
    'documentServices/siteMetadata/saveTasks/reloadSitePropertyInfoTask',
    'documentServices/appStudio/appStudioSaveTask',
    'experiment'
], function (
    saveDocument,
    savePublishTaskFactory,
    wixappsBlogSavePlugin,
    createSaveWixCodeAppsTask,
    createSaveCodeTask,
    createMarkAppImmutableTask,
    createSaveUnprovisionedApps,
    createSaveUnprovisionedAppsForAppFlows,
    createSaveEventDispatcher,
    createReloadSitePropertyInfoTask,
    appStudioSaveTask,
    experiment
) {
    'use strict'

    function getSaveTasksConfig(ps) {
        return {
            requiredTasks: [createSaveCodeTask(), appStudioSaveTask.createPreSave(ps)],
            primaryTask: saveDocument,
            secondaryTasks: [
                createSaveWixCodeAppsTask(),
                ...(experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled') ? [] : [createMarkAppImmutableTask()]),
                savePublishTaskFactory.create(ps),
                createSaveUnprovisionedApps(),
                createSaveUnprovisionedAppsForAppFlows(),
                createSaveEventDispatcher(),
                createReloadSitePropertyInfoTask(),
                wixappsBlogSavePlugin.create(ps)
            ]
        }
    }

    return {
        getSaveTasksConfig
    }
})
