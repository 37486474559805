/* eslint-disable prefer-rest-params */
import type {DSConfig} from '@wix/document-manager-core'
import type {DocumentServicesObject, PS, SetOpParams, ContextAdapter} from '@wix/document-services-types'
import {createPromiseFromAsyncPartOfPublicMethod} from './publicMethodsUtils'
import {DEFINITIONS_TYPES, METHOD_TYPES, LOG_TYPES} from './constants'
import * as _ from 'lodash'
import {wSpy} from '@wix/santa-core-utils'

interface RunAndGetHandleParams {
    documentServices: DocumentServicesObject
    privateServices: PS
    apiDefinition: PublicMethodDefinition
    setOperationParams: any
    args: any[]
}

// shouldLogAll is just for passing dm_bigBrotherLogging. Should be removed when merged
export const createPublicMethodUtils = (viewerLibrary: ViewerLibrary): PublicMethodUtils => {
    const setOperationQueueUtils = viewerLibrary.get('documentServices/utils/setOperationQueueUtils')
    const dsQTrace = viewerLibrary.get('documentServices/debug/dsQTrace')
    const contextAdapter: ContextAdapter = viewerLibrary.get('documentServices/utils/contextAdapter')

    const startInteraction = (interaction: string, options: Record<string, any>) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(interaction, options)
    }
    const endInteraction = (interaction: string, options: Record<string, any>) => {
        contextAdapter.utils.fedopsLogger.interactionEnded(interaction, options)
    }

    const getArgValue = (arg: any): string => {
        if (_.isFunction(arg)) {
            return 'function'
        }

        try {
            const value = JSON.stringify(arg) // just verifying that this is a serializable structure
            if (value.length > 200) {
                return `${value.substr(0, 200)}...`
            }
            return value
        } catch (e: any) {
            return 'error'
        }
    }

    const defaultApiParams = (...args: any[]) => {
        const argsWithNoPs = (args || []).slice(1)

        return {
            args: argsWithNoPs.map(getArgValue)
        }
    }

    const getInteractionParams = (isStarted: boolean, currentContext: any, identifier: number, apiDefinition: any, args: any) => {
        const getApiParamsFunction = apiDefinition.getInteractionParams ?? (isStarted ? defaultApiParams : _.noop)
        const apiParams = getApiParamsFunction(...args)
        const contextValue = currentContext ? {context: currentContext} : {}
        return {
            extras: _.merge(
                {
                    randIdentifier: identifier
                },
                apiParams,
                contextValue
            )
        }
    }

    const DEFAULT_OPTIONS_BY_DEFINITION_TYPE: {
        GETTER: Opts
        ACTION: Opts
        DATA_MANIPULATION_ACTION: Opts
    } = {
        GETTER: {},
        ACTION: {
            isUpdatingData: true
        },
        DATA_MANIPULATION_ACTION: {
            getReturnValue: undefined,
            asyncPreDataManipulation: undefined,
            isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.NO,
            singleComp: false,
            shouldLockComp: false,
            noRefresh: false,
            noBatching: false,
            noBatchingAfter: false,
            shouldExecOp: true,
            isAsyncOperation: false,
            waitingForTransition: false,
            nonUndoable: false,
            getInteractionParams: undefined,
            shouldLogInteraction: false,
            disableLogInteraction: false
        }
    }

    const logDocumentServicesOperation = (_type: string, rec: any[]) => wSpy.log(`ds_${_type}`, rec)

    function validateOptionsByType(options: Opts | undefined, definitionType: string) {
        const supportedOptions = _.keys(DEFAULT_OPTIONS_BY_DEFINITION_TYPE[definitionType])
        const hasNotSupportedProp = _.some(options, (value, propName) => !_.includes(supportedOptions, propName))
        if (hasNotSupportedProp) {
            throw new Error(`${definitionType} options are not valid`)
        }
    }

    function defineMethod(methodType: string, method: Function, options: Opts | undefined, definitionType: string): PublicMethodDefinition {
        const methodDefinitionKey = 'method'
        if (!_.isFunction(method)) {
            throw new Error(`${methodDefinitionKey} Function is required`)
        }

        validateOptionsByType(options, definitionType)

        const definition = _.set(
            {
                isPublicAPIDefinition: true,
                methodType,
                type: definitionType
            },
            methodDefinitionKey,
            method
        )

        return _.assign(definition, DEFAULT_OPTIONS_BY_DEFINITION_TYPE[definitionType], options) as PublicMethodDefinition
    }

    const defineGetter = (method: Function, options?: Opts) => defineMethod(METHOD_TYPES.READ, method, options, DEFINITIONS_TYPES.GETTER)
    const defineAction = (method: Function, options?: Opts) => defineMethod(METHOD_TYPES.ACTION, method, options, DEFINITIONS_TYPES.ACTION)
    const defineDataManipulationAction = (method: Function, options?: Opts) =>
        defineMethod(METHOD_TYPES.ACTION, method, options, DEFINITIONS_TYPES.DATA_MANIPULATION_ACTION)

    const runMethodInTransaction = (privateServices: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        apiDefinition.method(...args)
        privateServices.setOperationsQueue.executeOperationCallbacksInTransaction()
    }

    const runAsyncMethodInTransaction = async (privateServices: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        const [, ...argsWithoutPS] = args
        const asyncResultPromise = createPromiseFromAsyncPartOfPublicMethod(privateServices, apiDefinition, argsWithoutPS)
        const asyncResult = await asyncResultPromise
        const argsForMethod = !_.isNil(asyncResult) ? [privateServices, asyncResult, ...argsWithoutPS] : args
        runMethodInTransaction(privateServices, apiDefinition, argsForMethod)
    }

    const isAsync = (apiDefinition: PublicMethodDefinition, args: any[]): boolean =>
        apiDefinition.asyncPreDataManipulation &&
        ((_.isFunction(apiDefinition.isAsyncOperation) && apiDefinition.isAsyncOperation(...args)) || apiDefinition.isAsyncOperation === true)

    const runMethodAndGetHandleInTransaction = (privateServices: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        if (isAsync(apiDefinition, args)) {
            const asyncMethodPromise = runAsyncMethodInTransaction(privateServices, apiDefinition, args)
            privateServices.setOperationsQueue.registerToWaitForChangesAppliedInTransaction(asyncMethodPromise)
        } else {
            runMethodInTransaction(privateServices, apiDefinition, args)
        }
        return -1
    }

    function runMethodAndGetHandle({documentServices, privateServices, apiDefinition, setOperationParams, args}: RunAndGetHandleParams) {
        if (documentServices.transactions.isRunning()) {
            return runMethodAndGetHandleInTransaction(privateServices, apiDefinition, args)
        }
        return privateServices.setOperationsQueue.runSetOperation(apiDefinition.method, args, setOperationParams)
    }

    function waitForChangesIfNotInTransaction(ds: DocumentServicesObject, ps: PS, action: () => void): void {
        if (ds.transactions.isRunning()) {
            action()
        } else {
            ps.setOperationsQueue.waitForChangesApplied(action)
        }
    }

    function getDataManipulation(
        apiDefinition: PublicMethodDefinition,
        documentServices: DocumentServicesObject,
        privateServices: PS,
        methodName: string,
        documentServicesConfigs: DSConfig
    ) {
        return function () {
            const newArgs = _.toArray(arguments)
            newArgs.unshift(privateServices)

            let returnValue = null
            if (apiDefinition.getReturnValue) {
                returnValue = apiDefinition.getReturnValue.apply(null, newArgs)
                newArgs.splice(1, 0, returnValue)
            }

            logDocumentServicesOperation(apiDefinition.type, [apiDefinition.path, apiDefinition, ...arguments])

            const currentContext = privateServices.setOperationsQueue.getCurrentContext()
            const setOperationParams = setOperationQueueUtils.getDataManipulationParams(privateServices, apiDefinition, methodName, currentContext, newArgs)

            let interactionName: string
            const shouldLogAll = privateServices?.runtimeConfig?.shouldLogAllApis
            // Remove all references to shouldLogInteraction when merging dm_bigBrotherLogging
            const shouldLogInteraction = apiDefinition.shouldLogInteraction || (shouldLogAll && !apiDefinition.disableLogInteraction)
            const identifier = _.random(10000)

            if (shouldLogInteraction) {
                interactionName = `api.${apiDefinition.path}`
                const interactionParams = getInteractionParams(true, currentContext, identifier, apiDefinition, newArgs)
                startInteraction(interactionName, interactionParams)
            }

            const handle = runMethodAndGetHandle({
                documentServices,
                privateServices,
                apiDefinition,
                setOperationParams,
                args: newArgs
            })

            if (shouldLogInteraction) {
                const interactionParams = getInteractionParams(false, currentContext, identifier, apiDefinition, newArgs)
                waitForChangesIfNotInTransaction(documentServices, privateServices, () => endInteraction(interactionName, interactionParams))
            }

            if (apiDefinition.nonUndoable && !documentServicesConfigs.noUndo) {
                privateServices.setOperationsQueue.runSetOperation(() => documentServices.history.clear())
            }

            if (dsQTrace.isTracing(privateServices)) {
                if (dsQTrace.shouldLogConsoleTrace(privateServices)) {
                    /*eslint no-console:0*/
                    if (console.trace) {
                        console.trace()
                    }
                }
                dsQTrace.logTrace(privateServices, LOG_TYPES.DATA_MANIPULATION_ACTION, {handle, methodName, args: arguments})
            }

            return returnValue
        }
    }

    function getImmediateAction(apiDefinition: PublicMethodDefinition, privateServices: PS, methodPath: string) {
        return (...args: any[]) => {
            logDocumentServicesOperation(apiDefinition.type, [apiDefinition.path, apiDefinition, ...args])
            const currentContext = privateServices.setOperationsQueue.getCurrentContext()
            const setOperationsParams = setOperationQueueUtils.getImmediateActionParams(
                privateServices,
                methodPath,
                currentContext,
                apiDefinition.isUpdatingData
            )
            const result = privateServices.setOperationsQueue.runImmediateSetOperation
                ? privateServices.setOperationsQueue.runImmediateSetOperation(apiDefinition.method, setOperationsParams, [privateServices].concat(args))
                : apiDefinition.method(privateServices, ...args)

            if (dsQTrace.isTracing(privateServices)) {
                if (dsQTrace.shouldLogConsoleTrace(privateServices)) {
                    /*eslint no-console:0*/
                    if (console.trace) {
                        console.trace()
                    }
                }
                dsQTrace.logTrace(privateServices, LOG_TYPES.ACTION, {
                    result,
                    methodName: methodPath,
                    args
                })
            }

            return result
        }
    }

    function getRead(apiDefinition: PublicMethodDefinition, privateServices: PS, methodPath: string) {
        return (...args: any[]) => {
            logDocumentServicesOperation(apiDefinition.type, [apiDefinition.path, apiDefinition, ...args])
            if (dsQTrace.isTracing(privateServices)) {
                const start = window.performance ? window.performance.now() : _.now()
                const result = apiDefinition.method(privateServices, ...args)
                const end = window.performance ? window.performance.now() : _.now()
                dsQTrace.logReadTrace(privateServices, {
                    duration: end - start,
                    result,
                    methodName: methodPath,
                    args
                })
                return result
            }

            return apiDefinition.method(privateServices, ...args)
        }
    }

    function resolvePublicAPIDefinition(
        documentServices: DocumentServicesObject,
        documentServicesConfigs: DSConfig,
        privateServices: PS,
        apiDefinition: PublicMethodDefinition,
        methodPath: string
    ) {
        if (apiDefinition.type === DEFINITIONS_TYPES.DATA_MANIPULATION_ACTION) {
            return getDataManipulation(apiDefinition, documentServices, privateServices, methodPath, documentServicesConfigs)
        }

        if (apiDefinition.type === DEFINITIONS_TYPES.ACTION) {
            return getImmediateAction(apiDefinition, privateServices, methodPath)
        }

        return getRead(apiDefinition, privateServices, methodPath)
    }

    const isPublicAPIDefinition = (methodDefinition: PublicMethodDefinition): boolean => _.get(methodDefinition, ['isPublicAPIDefinition'], false)

    const dataManipulationWithFlags =
        (flags: {isUpdatingAnchors: string}) =>
        (method: Function, opts: Opts = {}): PublicMethodDefinition =>
            defineDataManipulationAction(method, Object.assign(opts, flags))

    const dontCare = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.DONT_CARE
    })

    const enforcingOnly = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.NO
    })

    const updatingOnly = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.YES
    })

    const wrap = (methodDef: PublicMethodDefinition, wrapper: Function) => {
        const {method} = methodDef
        if (!_.isFunction(method)) {
            throw new Error('You can only wrap a method definition') //TODO: perhaps in future, use defineConst + proxy in order to support consts
        }

        return {
            ...methodDef,
            method: (...args: any[]) => wrapper(() => methodDef.method(...args))
        }
    }

    const deprecate = (publicAPIDefinition: PublicMethodDefinition, deprecationMessage?: string): PublicMethodDefinition => {
        if (!isPublicAPIDefinition(publicAPIDefinition)) {
            throw new Error('You can only deprecate a publicAPI definition, such as a defined getter or action')
        }
        if (!_.isString(deprecationMessage) || !deprecationMessage.length) {
            throw new Error('You must provide a deprecation message')
        }
        return {
            ...publicAPIDefinition,
            deprecated: true,
            deprecationMessage
        }
    }

    const isDeprecated = (publicAPIDefinition: PublicMethodDefinition) => _.get(publicAPIDefinition, ['deprecated'], false)

    return {
        METHOD_TYPES,
        DEFINITIONS_TYPES,
        defineGetter,
        defineAction,
        defineDataManipulationAction,
        resolvePublicAPIDefinition,
        isPublicAPIDefinition,
        deprecate,
        isDeprecated,
        wrap,
        actions: {
            dataManipulation: defineDataManipulationAction,
            dontCare,
            enforcingOnly,
            updatingOnly,
            immediate: defineAction
        }
    }
}

export interface ViewerLibrary {
    get(n: string): any
}

export interface Opts extends SetOpParams {
    singleComp?: boolean
    shouldExecOp?: boolean
    getInteractionParams?(...args: any): Record<string, any>
    shouldLogInteraction?: boolean
    disableLogInteraction?: boolean
    getReturnValue?(...args: any): any
    isUpdatingAnchors?: boolean | 'yes'
    shouldLockComp?: boolean
    noRefresh?: boolean
    waitingForTransition?: boolean
    nonUndoable?: boolean
    isUpdatingData?: boolean
}

export interface PublicMethodDefinition {
    isPublicAPIDefinition: true
    methodType: 'read'
    type: 'GETTER'
    method: Function
    path?: string
    deprecated?: boolean
    deprecationMessage?: string
    getReturnValue?(...args: any): any
    shouldLogInteraction?: boolean
    disableLogInteraction?: boolean
    nonUndoable?: boolean
    isUpdatingData?: boolean
    isAsyncOperation?: boolean | Function
    asyncPreDataManipulation?: Function
}

export interface PublicMethodUtils {
    METHOD_TYPES: typeof METHOD_TYPES
    DEFINITIONS_TYPES: typeof DEFINITIONS_TYPES
    defineGetter(method: Function, options?: Opts): PublicMethodDefinition
    defineAction(method: Function, options?: Opts): PublicMethodDefinition
    defineDataManipulationAction(method: Function, options?: Opts): PublicMethodDefinition
    resolvePublicAPIDefinition(
        documentServices: DocumentServicesObject,
        documentServicesConfigs: DSConfig,
        privateServices: PS,
        apiDefinition: PublicMethodDefinition,
        methodPath?: string
    ): Function
    isPublicAPIDefinition(methodDefinition: PublicMethodDefinition): boolean
    deprecate(publicAPIDefinition: PublicMethodDefinition, deprecationMessage?: string): PublicMethodDefinition
    isDeprecated(methodDefinition: PublicMethodDefinition): boolean
    wrap(methodDefinition: PublicMethodDefinition, wrapper: Function): PublicMethodDefinition
    actions: {
        dataManipulation(method: Function, options?: Opts): PublicMethodDefinition
        dontCare(method: Function, options?: Opts): PublicMethodDefinition
        enforcingOnly(flags: any, opts?: Opts): PublicMethodDefinition
        updatingOnly(flags: any, opts?: Opts): PublicMethodDefinition
        immediate(method: Function, options?: Opts): PublicMethodDefinition
    }
}
