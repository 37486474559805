import PropTypes from 'prop-types'
import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {inputProxy} = wixapps
const listTypes = {
    text: {compType: 'wysiwyg.viewer.components.inputs.TextOption', compSkin: 'wixapps.integration.skins.ecommerce.options.TextOptionSkin'},
    color: {compType: 'wysiwyg.viewer.components.inputs.ColorOption', compSkin: 'wixapps.integration.skins.ecommerce.options.ColorOptionSkin'}
}

/**
 * @class proxies.mixins.optionProxy
 * @extends proxies.mixins.inputProxy
 * @property {function(): string} getSkinName
 * @property {function(): string} getComponentName
 */
export default {
    mixins: [inputProxy],

    propTypes: {
        viewProps: PropTypes.shape({
            loadedStyles: PropTypes.object.isRequired
        }).isRequired,
        skin: PropTypes.string
    },

    renderProxy() {
        const data = this.proxyData
        const listType = data.optionType || 'text'
        const self = this

        const compData = wixapps.typesConverter.selectableList(data)
        const selectedItem = _.find(compData.options, {value: compData.value})

        const skinName = this.props.skin || this.getSkinName()
        const props = _.merge(this.getChildCompProps(this.getComponentName()), {
            itemClassName: this.getCompProp('optionComp') || listTypes[listType].compType,
            itemSkin: this.getCompProp('optionSkin') || listTypes[listType].compSkin,
            skin: skinName,
            styleId: coreUtils.santaTypes.shortenStyleId(skinName),
            compData,
            selectedItem,
            onSelectionChange: function onSelectionChange(event, domID) {
                event.payload.listData = data
                self.setData(event.payload.value, 'selectedValue')
                self.handleViewEvent(event, domID)
            },
            valid: data.valid
        })

        return componentsCore.compRegistrar.getCompClass(this.getComponentName(), true)(props)
    }
}
