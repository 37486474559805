define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/component/component'
], function (_, dataModel, clientSpecMapService, component) {
    'use strict'

    function appsOfComp(ps, dataItem, componentPointer) {
        let apps = []
        if (dataItem && dataItem.type === 'AppController') {
            apps = appsOfAppController(ps, dataItem, componentPointer)
        } else if (dataItem && dataItem.type === 'TPAWidget') {
            apps = appsOfTPAWidgetDataItem(ps, dataItem, componentPointer)
        } else if (dataItem && dataItem.type === 'ConnectionList') {
            apps = appsOfConnectionItem(ps, dataItem)
        } else if (!dataItem && componentPointer) {
            apps = appsOfControllerDataItem(dataModel.getDataItem(ps, componentPointer))
        }
        if (_.isEmpty(apps) && componentPointer) {
            apps = _([...component.getAncestorsFromFull(ps, componentPointer), componentPointer])
                .flatMap(pointer => dataModel.getConnectionsItem(ps, pointer))
                .compact()
                .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
                .flatMap(controllerObj => appsOfControllerDataItem(dataModel.getDataItem(ps, _.get(controllerObj, 'controllerRef'))))
                .uniq()
                .value()
        }
        return apps
    }

    function appsOfAppController(ps, dataItem, componentPointer) {
        if (_.has(dataItem, 'applicationId')) {
            return appsOfControllerDataItem(dataItem)
        }
        return appsOfControllerDataItem(dataModel.getDataItem(ps, componentPointer))
    }

    function appsOfControllerDataItem(dataItem) {
        return _.compact([_.get(dataItem, 'applicationId')])
    }

    function appsOfConnections(ps, componentPointer) {
        const connectionItemPointer = dataModel.getConnectionsItemPointer(ps, componentPointer)
        if (connectionItemPointer) {
            const connectionItem = ps.dal.get(connectionItemPointer)
            const appsOfConnectionItems = appsOfConnectionItem(ps, connectionItem)
            if (!_.isEmpty(appsOfConnectionItems)) {
                const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
                const appsOfControllers = _(_.get(connectionItem, 'items'))
                    .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
                    .map('controllerId')
                    .compact()
                    .map(controllerId => dataModel.getControllerRefFromId(ps, controllerId, pagePointer))
                    .map(controllerRef => appsOfConnections(ps, controllerRef))
                    .compact()
                    .value()
                appsOfConnectionItems.push(appsOfControllers)
            }
            return appsOfConnectionItems || []
        }
        return []
    }

    function appsOfTPAWidgetDataItem(ps, dataItem, componentPointer) {
        const applicationId = _.get(dataItem, 'applicationId')
        const widgetData = clientSpecMapService.getWidgetData(ps, applicationId, _.get(dataItem, 'widgetId'))
        const apps = _.has(widgetData, 'componentFields.componentUrl') ? [dataItem.appDefinitionId] : []
        return apps.length ? apps.concat(appsOfConnections(ps, componentPointer)) : apps
    }

    function appsOfControllerStateMap(ps, controllerStateMap) {
        return _(_.get(controllerStateMap, 'configured'))
            .flatMap(controllerRef => appsOfControllerDataItem(dataModel.getDataItem(ps, controllerRef)))
            .compact()
            .sortedUniq()
            .value()
    }

    function appsOfConnectionItem(ps, connectionItem) {
        return _(_.get(connectionItem, 'items'))
            .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
            .map('controllerId')
            .compact()
            .flatMap(controllerId => appsOfControllerDataItem(dataModel.getDataItemById(ps, controllerId)))
            .value()
    }

    function getAppOfController(ps, controllerRef) {
        const controllerData = component.data.get(ps, controllerRef)
        return _.get(controllerData, 'applicationId')
    }

    return {
        appsOfComp,
        appsOfControllerStateMap,
        appsOfConnectionItem,
        getAppOfController
    }
})
