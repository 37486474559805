import {CreateExtArgs, Extension, ExtensionAPI, IndexKey, pointerUtils} from '@wix/document-manager-core'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {MULTILINGUAL_TYPES, VIEWER_DATA_TYPE_TO_POINTER_TYPE} from '../constants/constants'
import {getPointerWithoutFallbacks} from '../utils/refStructureUtils'
import {getLanguageFromKey} from '../utils/translationUtils'
import type {PageAPI} from './page'

const {getPointer} = pointerUtils

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): ExtensionAPI => {
        const pageAPI = extensionAPI.page as PageAPI

        /**
         * @param {function} pageCompFilter
         * @returns {*}
         */
        const getTranslationsForPage = (pageCompFilter: IndexKey) => {
            const allTranslatedDataItems = deepClone(dal.query(MULTILINGUAL_TYPES.multilingualTranslations, pageCompFilter))

            const wrapItemWithKey = (value: any, key: any) => ({value, key})

            return _(allTranslatedDataItems)
                .mapValues(wrapItemWithKey)
                .groupBy(({key}) => getLanguageFromKey(key))
                .mapValues(wrappedDataItems => ({
                    data: {
                        document_data: _(wrappedDataItems).map('value').keyBy('id').value()
                    }
                }))
                .value()
        }

        /**
         * @param {string} pageId
         * @param {string} viewMode
         * @returns {*}
         */
        const getPageFlattenedJson = (pageId: string, viewMode: string): any => {
            const pageCompFilter = pageAPI.getPageIndexId(pageId)
            const structureMap = dal.queryKeys(viewMode, pageCompFilter).reduce((obj: any, id: string) => {
                const pointer = getPointer(id, viewMode)
                const value = deepClone(dal.get(pointer))
                obj[value.id] = value
                return obj
            }, {})

            const dataMaps = _.mapValues(VIEWER_DATA_TYPE_TO_POINTER_TYPE, pointerType =>
                dal.queryKeys(pointerType, pageCompFilter).reduce((obj: any, id: string) => {
                    const pointer = getPointerWithoutFallbacks(id, pointerType)
                    const value = deepClone(dal.get(pointer))
                    obj[value.id] = value
                    return obj
                }, {})
            )

            const translations = getTranslationsForPage(pageCompFilter)
            const pageDataItem = dal.get(pointers.data.getDataItem(pageId, pageId))

            return {
                structure: structureMap,
                data: dataMaps,
                translations,
                title: _.get(pageDataItem, ['title']),
                pageUriSEO: _.get(pageDataItem, ['pageUriSEO'])
            }
        }

        return {
            /**
             *
             * @param {string} pageId
             * @param {string} viewMode
             * @return {Promise<*>}
             */
            fetchPageFromDM: (pageId: string, viewMode: string): Promise<any> => Promise.resolve(getPageFlattenedJson(pageId, viewMode))
        }
    }
    return {
        name: 'documentToViewer',
        createExtensionAPI
    }
}
export {createExtension}
