import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const fonts = viewerLibrary.get('documentServices/fonts/fonts')
    const {defineGetter, deprecate} = publicMethodUtils

    return {
        methods: {
            fonts: {
                css: {
                    getUrls: defineGetter(fonts.css.getCssUrls)
                },
                getFontsOptions: deprecate(defineGetter(fonts.getSiteFontsOptions), 'old function use getSiteFontsOptions instead'),
                getSiteFontsOptions: defineGetter(fonts.getSiteFontsOptions),
                getAllFontsOptions: defineGetter(fonts.getAllFontsOptions)
            }
        }
    }
}
