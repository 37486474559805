define(['lodash', '@wix/santa-core-utils', 'layout'], function (_, santaCoreUtils, /** layout.layout*/ layout) {
    'use strict'

    function measureAppWidget(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
        const [child] = santaCoreUtils.dataUtils.getChildrenData(structureInfo.structure, isMobileView())
        if (child) {
            measureMap.width[id] = _.get(measureMap, ['width', child.id], child.layout.width)
            measureMap.height[id] = _.get(measureMap, ['height', child.id], child.layout.height)
        }
    }

    function patchAppWidget(id, patchers, measureMap) {
        patchers.css(id, {
            width: measureMap.width[id],
            height: measureMap.height[id]
        })
    }

    const componentType = 'platform.components.AppWidget'
    layout.registerMeasureChildrenFirst(componentType, true)
    layout.registerRequestToMeasureDom(componentType)
    layout.registerCustomMeasure(componentType, measureAppWidget)
    layout.registerPatcher(componentType, patchAppWidget)
})
