define(['lodash', 'documentServices/editorServerFacade/editorServerFacade', 'documentServices/appStudio/appStudioDataModel'], function (
    _,
    editorServerFacade,
    appStudioDataModel
) {
    'use strict'

    const ERRORS = {
        INVALID_CHARS: 'INVALID_CHARS',
        EMPTY_NAME: 'EMPTY_NAME',
        INVALID_LENGTH: 'INVALID_LENGTH',
        ONLY_SPACES: 'ONLY_SPACES'
    }

    const hasEmojis = str => str.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)

    const onlySpaces = str => _.trim(str) === ''

    const isValidAppName = appName => {
        if (_.size(appName) === 0) {
            return {success: false, errorCode: ERRORS.EMPTY_NAME}
        }
        if (_.size(appName) < 1 || _.size(appName) > 30) {
            return {success: false, errorCode: ERRORS.INVALID_LENGTH}
        }
        if (hasEmojis(appName)) {
            return {success: false, errorCode: ERRORS.INVALID_CHARS}
        }
        if (onlySpaces(appName)) {
            return {success: false, errorCode: ERRORS.ONLY_SPACES}
        }

        return {success: true}
    }

    const setAppName = (ps, appName) => {
        if (!isValidAppName(appName).success) {
            throw new Error('invalid appName')
        }
        setAppNameData(ps, appName)
    }

    const setAppNameData = (ps, appName) => {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        appStudioData.name = appName
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    const clearAppName = ps => setAppNameData(ps)

    const getAppName = ps => {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        return appStudioData.name
    }

    const getUsedAppNames = (ps, onSuccess, onError) => {
        editorServerFacade.sendWithPs(
            ps,
            editorServerFacade.ENDPOINTS.GET_APPS,
            {},
            async response => {
                if (response.apps) {
                    const appNames = _.map(response.apps, 'name')
                    onSuccess(appNames)
                }
            },
            error => onError(error)
        )
    }

    return {
        set: setAppName,
        get: getAppName,
        clear: clearAppName,
        validate: (ps, appName) => isValidAppName(appName),
        getUsedAppNames,
        getAppName,
        ERRORS
    }
})
