define(['documentServices/structure/structure'], function (structure) {
    'use strict'

    const ALIGNMENT = {
        LEFT: 'left',
        RIGHT: 'right',
        FULL_SCREEN: 'fullScreen'
    }

    const DEFAULT_WIDTH = 200

    const isVerticalRightAlignment = (ps, compPtr) => {
        const docking = structure.getDock(ps, compPtr)
        return docking && docking.top && docking.bottom && docking.right
    }

    const isVerticalLeftAlignment = (ps, compPtr) => {
        const docking = structure.getDock(ps, compPtr)
        return docking && docking.top && docking.bottom && docking.left
    }

    const setVerticalRightAlignment = (ps, compPtr) => {
        const dockData = {
            top: {px: 0},
            right: {px: 0},
            bottom: {px: 0}
        }
        structure.setDock(ps, compPtr, dockData)

        if (!isVerticalLeftAlignment(ps, compPtr)) {
            const newX = ps.siteAPI.getScreenWidth() - DEFAULT_WIDTH
            structure.updateCompLayout(ps, compPtr, {width: DEFAULT_WIDTH, x: newX})
        }
    }

    const setVerticalLeftAlignment = (ps, compPtr) => {
        const dockData = {
            top: {px: 0},
            left: {px: 0},
            bottom: {px: 0}
        }
        structure.setDock(ps, compPtr, dockData)
        if (!isVerticalRightAlignment(ps, compPtr)) {
            structure.updateCompLayout(ps, compPtr, {width: DEFAULT_WIDTH})
        }
    }

    const setFullScreenAlignment = (ps, compPtr) => {
        const dockData = {
            top: {px: 0},
            left: {px: 0},
            right: {px: 0},
            bottom: {px: 0}
        }
        structure.setDock(ps, compPtr, dockData)
    }

    const setAlignment = (ps, compPtr, alignment) => {
        switch (alignment) {
            case ALIGNMENT.FULL_SCREEN:
                setFullScreenAlignment(ps, compPtr)
                break
            case ALIGNMENT.LEFT:
                setVerticalLeftAlignment(ps, compPtr)
                break
            case ALIGNMENT.RIGHT:
                setVerticalRightAlignment(ps, compPtr)
                break
        }
    }

    return {
        setAlignment
    }
})
