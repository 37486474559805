define([
    'lodash',
    'documentServices/page/pageData',
    'documentServices/page/popupUtils',
    'documentServices/theme/theme',
    'documentServices/validation/validators/compPropValidator',
    'documentServices/mobileConversion/modules/mobileHintsValidator',
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/component/componentDeprecation',
    'documentServices/utils/utils',
    '@wix/santa-core-utils',
    'documentServices/utils/contextAdapter',
    'document-services-schemas',
    '@wix/document-manager-utils',
    'experiment'
], function (
    _,
    pageData,
    popupUtils,
    theme,
    compPropValidator,
    mobileHintsValidator,
    constants,
    componentsMetaData,
    deprecation,
    dsUtils,
    santaCoreUtils,
    contextAdapter,
    documentServicesSchemas,
    documentManagerUtils,
    experiment
) {
    'use strict'

    const {ReportableError} = documentManagerUtils
    const {schemasService} = documentServicesSchemas.services

    const ERRORS = {
        COMPONENT_DOES_NOT_EXIST: 'component param does not exist',
        COMPONENT_DOES_NOT_HAVE_TYPE: 'component does not have type',
        INVALID_COMPONENT_STRUCTURE: 'invalid component structure',
        UNKNOWN_COMPONENT_TYPE: 'unknown component type',
        INVALID_COMPONENT_PROPERTIES: 'invalid component properties',
        INVALID_COMPONENT_DATA: 'invalid component data',
        COMPONENT_MISSING_STYLE_OR_SKIN: 'component missing style or skin',
        INVALID_MOBILE_HINTS: 'invalid mobile hints',
        INVALID_CONTAINER_STRUCTURE: 'invalid container structure',
        INVALID_CONTAINER_POINTER: 'invalid container pointer',
        MAXIMUM_CHILDREN_NUMBER_REACHED: 'maximum number of child components reached',
        CANNOT_ADD_COMPONENT_TO_MOBILE_PATH: 'cannot add component to mobile path',
        CANNOT_DELETE_MASTER_PAGE: 'cannot delete master page',
        SITE_MUST_HAVE_AT_LEAST_ONE_PAGE: 'site must have at least one page',
        CANNOT_DELETE_MOBILE_COMPONENT: 'cannot delete mobile component',
        CUSTOM_ID_MUST_BE_STRING: 'customId must be a string',
        COMPONENT_IS_NOT_CONTAINER: 'component is not a container',
        LAYOUT_PARAM_IS_INVALID: 'layout param is invalid',
        LAYOUT_PARAM_IS_NOT_ALLOWED: 'layout param is not allowed',
        LAYOUT_PARAM_MUST_BE_NUMERIC: 'layout param must be numeric',
        LAYOUT_PARAM_MUST_BE_BOOLEAN: 'layout param must be boolean',
        LAYOUT_PARAM_ROTATATION_INVALID_RANGE: 'rotationInDegrees must be a valid range (0-360)',
        LAYOUT_PARAM_CANNOT_BE_NEGATIVE: 'layout param cannot be a negative value',
        CANNOT_DELETE_HEADER_COMPONENT: 'cannot delete a header component',
        CANNOT_DELETE_FOOTER_COMPONENT: 'cannot delete a footer component',
        CANNOT_DELETE_NON_EXISTING_COMPONENT: 'cannot delete a non existing component',
        CANNOT_DELETE_NON_DISPLAYED_COMPONENT: 'cannot delete a non displayed component',
        SKIN_PARAM_MUST_BE_STRING: 'skin name param must be a string',
        CANNOT_SET_BOTH_SKIN_AND_STYLE: 'skin cannot be set if style already exists',
        STYLE_ID_PARAM_MUST_BE_STRING: 'style id param must be a string',
        STYLE_ID_PARAM_DOES_NOT_EXIST: 'style id param does not exist and cannot be set',
        STYLE_ID_PARAM_ALREADY_EXISTS: 'style id param already exists and cannot be overridden with custom style',
        STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT: 'style properties param must be an object',
        COMPONENT_IS_DEPRECATED: 'cannot add because component was deprecated',
        INVALID_COMPONENT_CONNECTION_ROLE: 'invalid connection role - cannot be *',
        CANNOT_ADD_COMPONENT_WITH_VARIANT: 'cannot add component with variants',
        CANNOT_DELETE_COMPONENT_WITH_VARIANT: 'cannot delete component with variants',
        UNKNOWN_SYSTEM_STYLE: 'Adding unknown system style',
        INVALID_DESKTOP_POINTER: 'invalid desktop pointer'
    }

    const ALLOWED_LAYOUT_PARAMS = ['x', 'y', 'width', 'height', 'scale', 'rotationInDegrees', 'fixedPosition', 'docked', 'aspectRatio']

    /**
     * Perform component validation not including children, should be called recursively for children
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Object} componentDefinition
     * @param {String} [optionalCustomId]
     * @param {Pointer} [containerPointer]
     * @param {boolean} [isPage]
     * @returns {Object}
     */
    function validateComponentToSet(ps, componentPointer, componentDefinition, optionalCustomId, containerPointer, isPage) {
        try {
            validateCustomId(optionalCustomId)
            validateStructure(componentDefinition)
            if (!isPage) {
                validateContainer(ps, componentDefinition, containerPointer)
            }
            if (experiment.isOpen('dm_reportUnknownSystemStyle')) {
                reportUnknownSystemStyle(componentDefinition)
            }
        } catch (/** @type any */ e) {
            sendBreadCrumbOnValidationError(e.message, {id: componentDefinition.id, componentType: componentDefinition.componentType})
            return {success: false, error: e.message}
        }

        return {success: true}
    }

    function validateComponentToAdd(ps, componentPointer, componentDefinition, containerPointer, optionalIndex) {
        if (deprecation.isComponentDeprecated(componentDefinition.componentType)) {
            sendBreadCrumbOnValidationError(deprecation.getDeprecationMessage(componentDefinition.componentType), {
                id: componentDefinition.id,
                componentType: componentDefinition.componentType
            })
            return {success: false, error: deprecation.getDeprecationMessage(componentDefinition.componentType)}
        }
        if (deprecation.shouldWarnForDeprecation(componentDefinition.componentType)) {
            if (ps.runtimeConfig.shouldThrowOnDeprecation) {
                sendBreadCrumbOnValidationError(deprecation.getDeprecationMessage(componentDefinition.componentType), {
                    id: componentDefinition.id,
                    componentType: componentDefinition.componentType
                })
                return {success: false, error: deprecation.getDeprecationMessage(componentDefinition.componentType)}
            }
            santaCoreUtils.log.warnDeprecation(deprecation.getDeprecationMessage(componentDefinition.componentType))
        }
        if (!isValidContainerDefinition(ps, componentDefinition, containerPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.INVALID_CONTAINER_POINTER, {id: componentDefinition.id, componentType: componentDefinition.componentType})
            return {success: false, error: ERRORS.INVALID_CONTAINER_POINTER}
        }
        if (!isValidIndexOfChild(ps, containerPointer, optionalIndex)) {
            sendBreadCrumbOnValidationError(ERRORS.INVALID_CONTAINER_POINTER, {id: componentDefinition.id, componentType: componentDefinition.componentType})
            return {success: false, error: ERRORS.INVALID_CONTAINER_POINTER}
        }

        if (!canContainMoreChildren(ps, containerPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED, {
                id: componentDefinition.id,
                componentType: componentDefinition.componentType
            })
            return {success: false, error: ERRORS.MAXIMUM_CHILDREN_NUMBER_REACHED}
        }

        if (ps.pointers.components.isWithVariants(componentPointer)) {
            return {success: false, error: ERRORS.CANNOT_ADD_COMPONENT_WITH_VARIANT}
        }

        return {success: true}
    }

    function reportUnknownSystemStyle(componentDefinition) {
        if (
            _.get(componentDefinition, 'style.styleType') === 'system' ||
            _.some(_.get(componentDefinition, 'style.stylesInBreakpoints', []), style => _.get(style, 'styleType') === 'system') ||
            _.some(_.get(componentDefinition, 'scopedStyles', []), style => _.get(style, 'styleType') === 'system')
        ) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    errorType: 'unknownSystemStyle',
                    message: 'Adding unknown system style',
                    extras: {
                        id: componentDefinition.id,
                        componentType: componentDefinition.componentType
                    }
                })
            )
            sendBreadCrumbOnValidationError('Adding unknown system style', {id: componentDefinition.id, componentType: componentDefinition.componentType})
        }
    }

    function canContainMoreChildren(ps, containerPointer) {
        return componentsMetaData.public.allowedToContainMoreChildren(ps, containerPointer)
    }

    function isValidIndexOfChild(ps, containerPointer, optionalIndex) {
        if (!_.isNumber(optionalIndex)) {
            return true
        }
        const componentPointers = ps.pointers.full.components
        const childrenPointers = componentPointers.getChildren(containerPointer)
        if (!_.isFinite(optionalIndex) || optionalIndex < 0 || optionalIndex > childrenPointers.length) {
            return false
        }
        return true
    }

    function isContainableByStructure(ps, componentDefinition, containerPointer, isPublic) {
        return isPublic
            ? componentsMetaData.public.isContainableByStructure(ps, componentDefinition, containerPointer)
            : componentsMetaData.isContainableByStructure(ps, componentDefinition, containerPointer)
    }

    function validateContainer(ps, componentDefinition, containerPointer, isPublic) {
        if (!containerPointer) {
            throw createValidationError(`containerPointer ${containerPointer} is illegal`, {
                componentType: componentDefinition.componentType
            })
        }

        if (!ps.dal.isExist(containerPointer) && !ps.dal.full.isExist(containerPointer)) {
            throw createValidationError('container does not exist', {
                containerPointer,
                componentType: componentDefinition.componentType
            })
        }

        if (!isContainableByStructure(ps, componentDefinition, containerPointer, isPublic)) {
            throw createValidationError(`component ${componentDefinition.componentType} is not containable in ${containerPointer.id}`, {
                containerPointer,
                componentType: componentDefinition.componentType
            })
        }
    }

    function isValidContainerDefinition(ps, componentDefinition, containerPointer) {
        try {
            validateContainer(ps, componentDefinition, containerPointer, true)
            return true
        } catch (e) {
            return false
        }
    }

    function validateSetSkinParams(ps, componentPointer, skinName) {
        if (!_.isString(skinName)) {
            sendBreadCrumbOnValidationError(ERRORS.SKIN_PARAM_MUST_BE_STRING, {skinName})
            return {success: false, error: ERRORS.SKIN_PARAM_MUST_BE_STRING}
        }
        if (getComponentStyleId(ps, componentPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.SKIN_PARAM_MUST_BE_STRING, {styleId: getComponentStyleId(ps, componentPointer)})
            return {success: false, error: ERRORS.CANNOT_SET_BOTH_SKIN_AND_STYLE}
        }
        //todo Shimi_Liderman 12/14/14 18:58 should add a validation that the skin is compatible with the component when @moranw will add the relevant mapping

        return {success: true}
    }

    function validateSetStyleIdParams(ps, styleId, pageId = constants.MASTER_PAGE_ID) {
        if (!_.isString(styleId)) {
            sendBreadCrumbOnValidationError(ERRORS.STYLE_ID_PARAM_MUST_BE_STRING, {styleId})
            return {success: false, error: ERRORS.STYLE_ID_PARAM_MUST_BE_STRING}
        }
        if (!theme.styles.get(ps, styleId, pageId)) {
            sendBreadCrumbOnValidationError(ERRORS.STYLE_ID_PARAM_MUST_BE_STRING, {pageId, styleId})
            return {success: false, error: ERRORS.STYLE_ID_PARAM_DOES_NOT_EXIST}
        }

        return {success: true}
    }

    function validateExistingComponent(ps, componentPointer, isFull = false) {
        const exists = isFull ? ps.dal.full.isExist(componentPointer) : ps.dal.isExist(componentPointer)
        if (!exists) {
            sendBreadCrumbOnValidationError(ERRORS.COMPONENT_DOES_NOT_EXIST, {componentPointer})
            return {success: false, error: ERRORS.COMPONENT_DOES_NOT_EXIST}
        }
        return {success: true}
    }

    function validateComponentCustomStyleParams(ps, optionalSkinName, optionalStyleProperties) {
        function isUsed(value) {
            return !_.isNil(value)
        }
        if (isUsed(optionalSkinName) && !_.isString(optionalSkinName)) {
            //todo Shimi_Liderman 12/14/14 18:58 should add a validation that the skin is compatible with the component when @moranw will add the relevant mapping
            sendBreadCrumbOnValidationError(ERRORS.SKIN_PARAM_MUST_BE_STRING, {optionalSkinName})
            return {success: false, error: ERRORS.SKIN_PARAM_MUST_BE_STRING}
        }
        if (isUsed(optionalStyleProperties) && (!_.isObject(optionalStyleProperties) || _.isArray(optionalStyleProperties))) {
            sendBreadCrumbOnValidationError(ERRORS.STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT, {optionalStyleProperties})
            return {success: false, error: ERRORS.STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT}
        }

        return {success: true}
    }

    function getComponentStyleId(ps, componentPointer) {
        let result = null
        if (ps && componentPointer) {
            const compStylePointer = ps.pointers.getInnerPointer(componentPointer, 'styleId')
            result = ps.dal.get(compStylePointer)
        }
        return result
    }

    function validateComponentTypeToDelete(ps, componentPointer) {
        if (isMasterPage(componentPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.CANNOT_DELETE_MASTER_PAGE)
            return {success: false, error: ERRORS.CANNOT_DELETE_MASTER_PAGE}
        }

        const compType = dsUtils.getComponentType(ps, componentPointer)
        const type = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'type')) || ps.dal.full.get(ps.pointers.getInnerPointer(componentPointer, 'type'))

        if (!compType) {
            sendBreadCrumbOnValidationError(ERRORS.COMPONENT_DOES_NOT_HAVE_TYPE, {componentPointer})
            return {success: false, error: ERRORS.COMPONENT_DOES_NOT_HAVE_TYPE}
        }

        if (isPageComponent(type)) {
            if (popupUtils.isPopup(ps, componentPointer.id)) {
                return {success: true}
            } else if (siteHasOnlyOnePage(ps)) {
                sendBreadCrumbOnValidationError(ERRORS.SITE_MUST_HAVE_AT_LEAST_ONE_PAGE, {componentPointer})
                return {success: false, error: ERRORS.SITE_MUST_HAVE_AT_LEAST_ONE_PAGE}
            }
            return {success: true}
        }

        if (isHeaderComponent(compType)) {
            sendBreadCrumbOnValidationError(ERRORS.CANNOT_DELETE_HEADER_COMPONENT, {componentPointer, compType})
            return {success: false, error: ERRORS.CANNOT_DELETE_HEADER_COMPONENT}
        }

        if (isFooterComponent(compType)) {
            sendBreadCrumbOnValidationError(ERRORS.CANNOT_DELETE_FOOTER_COMPONENT, {componentPointer, compType})
            return {success: false, error: ERRORS.CANNOT_DELETE_FOOTER_COMPONENT}
        }

        return {success: true}
    }

    function validateComponentExistToDelete(ps, componentPointer, deletedParentFromFull) {
        if (!deletedParentFromFull && !ps.dal.isExist(componentPointer) && !ps.dal.full.isExist(componentPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.CANNOT_DELETE_NON_EXISTING_COMPONENT, {componentPointer})
            return {success: false, error: ERRORS.CANNOT_DELETE_NON_EXISTING_COMPONENT}
        }
        return {success: true}
    }

    function validateComponentExistOnFull(ps, componentPointer) {
        if (!ps.dal.full.isExist(componentPointer)) {
            sendBreadCrumbOnValidationError(ERRORS.COMPONENT_DOES_NOT_EXIST, {componentPointer})
            return {success: false, error: ERRORS.COMPONENT_DOES_NOT_EXIST}
        }
        return {success: true}
    }

    function setIfUndefined(obj, path, value) {
        if (_.has(obj, path)) {
            return false
        }
        _.setWith(obj, path, value, Object)
        return true
    }

    function validateWithFakeId(obj, validate) {
        const ID_PATH = 'id'
        const FAKE_ID = '<FAKE_ID_FOR_NEW_COMP_VALIDATIONS>'
        const idWasFaked = setIfUndefined(obj, ID_PATH, FAKE_ID)
        validate(obj)

        if (idWasFaked) {
            _.unset(obj, ID_PATH)
        }
    }

    /**
     * @param {ps} ps
     * @param {Array} componentPointer
     * @returns {Object}
     */
    function validateComponentToDelete(ps, componentPointer, deletedParentFromFull) {
        const res = validateComponentTypeToDelete(ps, componentPointer)
        if (!res.success) {
            return res
        }
        return validateComponentExistToDelete(ps, componentPointer, deletedParentFromFull)
    }

    /**
     * @param {Pointer} componentPointer
     * @returns {Boolean}
     */
    function isMasterPage(componentPointer) {
        return componentPointer.id === 'masterPage'
    }

    /**
     * @param {string} compType
     * @returns {Boolean}
     */
    function isHeaderComponent(compType) {
        return !!compType && compType === constants.COMP_TYPES.HEADER
    }

    /**
     * @param {string} componentType
     * @returns {Boolean}
     */
    function isFooterComponent(componentType) {
        return componentType === constants.COMP_TYPES.FOOTER
    }

    /**
     * @param {ps} ps
     * @returns {Boolean}
     */
    function siteHasOnlyOnePage(ps) {
        return pageData.getNumberOfPages(ps) === 1
    }

    /**
     * @param {Object} componentStructure
     */
    function validateStructure(componentStructure) {
        validateType(componentStructure)
        validateStyle(componentStructure)
        validateDataType(componentStructure.componentType, componentStructure.data)
        validatePropsType(componentStructure)
        validateSlotsType(componentStructure)
        validMobileHints(componentStructure)
    }

    /**
     * @param {Object} componentStructure
     */
    function validMobileHints(componentStructure) {
        validateWithFakeId(componentStructure.mobileHints, mobileHintsValidator.validateMobileHintsBySchema)
    }

    /**
     * @param {Object} componentStructure
     */
    function validateType(componentStructure) {
        if (!_.isObject(componentStructure)) {
            throw createValidationError('component structure is not an object')
        }

        const {componentType} = componentStructure
        if (!componentType) {
            throw createValidationError('componentType is missing')
        }

        if (!schemasService.getDefinition(componentType)) {
            throw createValidationError(`componentType ${componentType} has no schema`)
        }
    }

    /**
     * @param {Object} compData
     * @param {String} compType
     */
    function validateDataType(compType, compData) {
        const compDefinitions = schemasService.getDefinition(compType)

        if (_.isObject(compData) && !compData.type) {
            throw createValidationError('component data is missing a type', {compData})
        }

        const dataType = _.get(compData, 'type', compData || '')
        const dataTypes = compDefinitions.dataTypes || [''] //support no data by default

        if (dataType === 'RepeatedData') {
            validateRepeatedData(compType, compData)
        } else if (!_.includes(dataTypes, dataType)) {
            throw createValidationError(`data type ${dataType} is not allowed for componentType ${compType}`, {compData})
        }
    }

    function validateRepeatedData(compType, compData) {
        validateDataType(compType, compData.original)
        _.forEach(compData.overrides, _.partial(validateDataType, compType))
    }

    /**
     * @param {Object} componentStructure
     */
    function validatePropsType(componentStructure) {
        validateWithFakeId(componentStructure.props, props => compPropValidator.validateProperties(componentStructure.componentType, props))
    }

    /**
     *
     * @param componentStructure
     */
    function validateSlotsType(componentStructure) {
        if (componentStructure.slots) {
            const compType = componentStructure.componentType
            const compDefinition = schemasService.getDefinition(compType)
            const targetSlotsType = componentStructure.slots.type
            if (compDefinition.slotsDataType !== targetSlotsType) {
                throw createValidationError(`slots type ${targetSlotsType} is not allowed for componentType ${compType}`, {compType, targetSlotsType})
            }
        }
    }

    /**
     * @param {Object} componentStructure
     */
    function validateStyle(componentStructure) {
        const {componentType, skin, style} = componentStructure
        const compDefinition = schemasService.getDefinition(componentType)
        const compNeedsStyling = !_.isEmpty(compDefinition.styles) || !_.isEmpty(compDefinition.skins)
        if (compNeedsStyling && !skin && !style) {
            throw createValidationError('missing style/skin in component structure', {componentType})
        }
    }

    /**
     * @param {String|null|undefined} customId
     */
    function validateCustomId(customId) {
        if (!_.isNil(customId) && !_.isString(customId)) {
            throw createValidationError('customId must be a string', {customId})
        }
    }

    function createValidationError(msg, extras) {
        return new ReportableError({
            errorType: 'componentValidationError',
            message: msg,
            extras
        })
    }

    /**
     * @param {Object} compType
     * @returns {Boolean}
     */
    function isPageComponent(compType) {
        return compType === 'Page'
    }

    /**
     * @param {String} param
     * @param {Number} value
     * @returns {Object}
     */
    function validateLayoutParam(param, value) {
        if (!_.includes(ALLOWED_LAYOUT_PARAMS, param)) {
            sendBreadCrumbOnValidationError(ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED, {param, value})
            return {success: false, error: ERRORS.LAYOUT_PARAM_IS_NOT_ALLOWED}
        }

        if (param === 'fixedPosition' && _.isBoolean(value)) {
            return {success: true}
        }

        // TODO: Add validations for docking
        if (param === 'docked') {
            return {success: true}
        }

        if (!_.isNumber(value)) {
            sendBreadCrumbOnValidationError(ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC, {param, value})
            return {success: false, error: ERRORS.LAYOUT_PARAM_MUST_BE_NUMERIC}
        }

        const nonNegativeParams = _.without(ALLOWED_LAYOUT_PARAMS, 'x', 'y')

        if (_.includes(nonNegativeParams, param) && value < 0) {
            sendBreadCrumbOnValidationError(ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE, {param, value})
            return {success: false, error: ERRORS.LAYOUT_PARAM_CANNOT_BE_NEGATIVE}
        }

        // TODO evgenyb: refactor degrees validation
        if (param === 'rotationInDegrees' && value > 360) {
            sendBreadCrumbOnValidationError(ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE, {param, value})
            return {success: false, error: ERRORS.LAYOUT_PARAM_ROTATATION_INVALID_RANGE}
        }

        return {success: true}
    }

    /**
     * @param {Object} connectionItem
     * @returns {{success:boolean, error?:string}}
     */
    function validateComponentConnection(connectionItem) {
        if (connectionItem.role === '*') {
            sendBreadCrumbOnValidationError(ERRORS.INVALID_COMPONENT_CONNECTION_ROLE, {role: connectionItem.role})
            return {success: false, error: ERRORS.INVALID_COMPONENT_CONNECTION_ROLE}
        }

        return {success: true}
    }

    function sendBreadCrumbOnValidationError(validationError, extras) {
        contextAdapter.utils.fedopsLogger.breadcrumb(validationError, {extras})
    }

    /** @exports documentServices.component.componentValidations */
    return {
        validateComponentToSet,
        validateComponentToAdd,
        validateComponentTypeToDelete,
        validateComponentToDelete,
        validateComponentExistOnFull,
        validateLayoutParam,
        validateSetSkinParams,
        validateSetStyleIdParams,
        validateExistingComponent,
        validateComponentCustomStyleParams,
        validateComponentConnection,
        ALLOWED_LAYOUT_PARAMS,
        ERRORS
    }
})
