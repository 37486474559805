import tpaCompBaseMixin from '../mixins/tpaCompBaseMixin'
import tpaUrlBuilderMixin from '../mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '../mixins/tpaCompApiMixin'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {compRegistrar} = componentsCore

/**
 * @class components.TPAWidget
 * @extends {tpa.mixins.tpaCompBase}
 * @extends {tpa.mixins.tpaUrlBuilder}
 * @extends {tpa.mixins.tpaCompApiMixin}
 */
const TPAWorker = {
    displayName: 'TPAWorker',
    mixins: [componentsCore.mixins.skinBasedComp, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin],
    getBaseUrl() {
        const appData = this.getAppData()

        return appData.appWorkerUrl
    },

    mutateIframeUrlQueryParam(queryParamsObj) {
        queryParamsObj.endpointType = 'worker'
        return queryParamsObj
    },

    mutateSkinProperties(skinProps) {
        if (skinProps.iframe && skinProps.iframe.style) {
            skinProps.iframe.style.display = 'none'
        }

        return skinProps
    },

    isTPAWorker() {
        return true
    }
}

compRegistrar.register('tpa.viewer.classes.TPAWorker', TPAWorker)

export default TPAWorker
