define([
    'lodash',
    '@wix/document-services-json-schemas',
    'experiment',
    'documentServices/constants/constants',
    'documentServices/saveAPI/cloneWithoutAdditionalProperties',
    'documentServices/utils/utils',
    'document-services-schemas'
], function (_, documentServicesJsonSchemas, experiment, constants, cloneWithoutAdditionalProperties, utils, documentServicesSchemas) {
    'use strict'
    const {
        namespaceMapping: {NAMESPACE_MAPPING}
    } = documentServicesJsonSchemas
    const {
        services: {schemasService}
    } = documentServicesSchemas
    const {DESKTOP} = constants.VIEW_MODES

    const isNodeRefOverride = nodeId => _.includes(nodeId, '_r_')
    //temporary until we are ready to send the rest of the permanent nodes
    const isRemovableNode = (namespace, value, id) =>
        isNodeRefOverride(id) || (schemasService.isPermanentDataType(namespace, value.type) && (id === 'SITE_STRUCTURE' || value.type === 'AtomicScope'))

    const isPageDataItem = (dataMapName, value) => dataMapName === 'document_data' && value.type === 'Page'

    return (diff, lastSnapshot, currentSnapshot, boundExtensionsAPI) => {
        const changedData = {}
        const deletedData = {}
        const deletedDataForSave = {}
        const changedDataPageIds = new Set()
        const deletedDataPageIds = new Set()
        const deletedDataPageIdsForSave = new Set()
        const shouldFilterOrphanedPageDataItems = experiment.isOpen('dm_dontSendOrphanedPageDataNodes')
        const doesPageExistMap = {}
        const ignoredDeletions = []

        _.forEach(NAMESPACE_MAPPING, (dataMapName, namespace) => {
            changedData[dataMapName] = {}
            deletedData[dataMapName] = {}
            deletedDataForSave[dataMapName] = {}

            _.forEach(diff[namespace], (value, id) => {
                if (value === undefined) {
                    const prevValue = lastSnapshot.getValue({type: namespace, id})
                    const isRemovableByDefault = isRemovableNode(namespace, prevValue, id)
                    const isReferencedFn = _.get(boundExtensionsAPI, ['relationships', 'isReferenced'])
                    const shouldSendDeletedItem =
                        // pay attention that this is potentially may be a source of bug,
                        // because we check for references on current dm state and not on snapshot
                        (_.isFunction(isReferencedFn) && !isReferencedFn(utils.getPointer(namespace, id))) || isRemovableByDefault
                    deletedData[dataMapName][id] = cloneWithoutAdditionalProperties(id, prevValue)
                    const {pageId} = prevValue.metaData
                    deletedDataPageIds.add(pageId)

                    if (shouldSendDeletedItem) {
                        deletedDataForSave[dataMapName][id] = deletedData[dataMapName][id]
                        deletedDataPageIdsForSave.add(pageId)
                    } else {
                        ignoredDeletions.push({namespace, id})
                    }
                } else {
                    const {pageId} = value.metaData
                    if (!_.has(doesPageExistMap, pageId)) {
                        //avoid recursively checking snapshots every time
                        doesPageExistMap[pageId] = currentSnapshot.exists({type: DESKTOP, id: pageId})
                    }

                    const isOrphan = doesPageExistMap[pageId] === false
                    if (!isOrphan || !shouldFilterOrphanedPageDataItems) {
                        changedData[dataMapName][id] = cloneWithoutAdditionalProperties(id, value)
                        changedDataPageIds.add(pageId)
                    }

                    if (isPageDataItem(dataMapName, value)) {
                        changedDataPageIds.add(value.id)
                    }
                }
            })
        })

        return {
            changedData,
            deletedData: _.omitBy(deletedData, _.isEmpty),
            deletedDataForSave: _.omitBy(deletedDataForSave, _.isEmpty),
            deletedDataPageIdsForSave: Array.from(deletedDataPageIdsForSave),
            changedDataPageIds: Array.from(changedDataPageIds),
            deletedDataPageIds: Array.from(deletedDataPageIds),
            ignoredDeletions
        }
    }
})
