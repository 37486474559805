import layout from '../../util/layout'

function measureScreenWidthContainer(id, measureMap, nodesMap) {
    measureMap.left[id] = 0
    measureContainer(id, measureMap, nodesMap)
}

function measureContainer(id, measureMap, nodesMap) {
    //we take the actual offsetHeight for the case the height written in the structure is bigger
    const inlineContentHeight = measureMap.height[`${id}inlineContent`] || 0
    measureMap.containerHeightMargin[id] = inlineContentHeight ? nodesMap[id].offsetHeight - inlineContentHeight : 0
}

const COMP_TYPE = 'mobile.core.components.Container'

layout.registerRequestToMeasureDom(COMP_TYPE)
layout.registerRequestToMeasureChildren(COMP_TYPE, [['inlineContent']])
layout.registerCustomMeasure(COMP_TYPE, measureContainer)

layout.registerCustomMeasure('wysiwyg.viewer.components.ScreenWidthContainer', measureScreenWidthContainer)

export default {
    measureContainer,
    measureScreenWidthContainer
}
