define(['lodash', 'documentServices/component/componentModes', '@wix/santa-core-utils', 'documentServices/mobileUtilities/mobileUtilities'], function (
    _,
    componentModes,
    coreUtils,
    mobileUtil
) {
    'use strict'

    function activatePageMobileHoverModes(ps, pagePointer) {
        const pageComponentPointer = ps.pointers.components.getPage(pagePointer.id, coreUtils.constants.VIEW_MODES.DESKTOP)
        const pageChildren = ps.pointers.components.getChildrenRecursively(pageComponentPointer)

        _(pageChildren)
            .filter(isHoverModeFoundOnComponent.bind(null, ps))
            .forEach(mobileUtil.prepareComponentStructureForMobileAlgorithm.bind(null, ps, componentModes))
    }

    function isHoverModeFoundOnComponent(ps, componentPointer) {
        return !!getComponentHoverMode(ps, componentPointer)
    }

    function getComponentHoverMode(ps, componentPointer) {
        const compModeDefinitions = componentModes.getComponentModes(ps, componentPointer)
        return _.find(compModeDefinitions, {type: 'HOVER'})
    }

    return {
        activatePageMobileHoverModes
    }
})
