define(['lodash'], function (_) {
    'use strict'

    function isNamePrefixWithNumber(name, prefix) {
        const r = new RegExp(`^${prefix}[0-9]*$`)
        return r.test(name)
    }

    function generateName(existingNames = [], prefix) {
        const allNumbersSorted = _(existingNames)
            .map('name')
            .filter(name => isNamePrefixWithNumber(name, prefix))
            .map(name => _.replace(name, prefix, ''))
            .map(Number)
            .sortBy()
            .value()

        const maxValue = _.last(allNumbersSorted) || 0

        return prefix + (maxValue + 1)
    }

    return {
        generateName
    }
})
