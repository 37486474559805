/**
 * @typedef {object} ActionsTriggerTypes
 * @type {ActionsTriggerTypes}
 **/
export default {
    DID_LAYOUT: 'didLayout',
    SCROLL: 'scroll',
    RESIZE: 'resize',
    PAGE_CHANGED: 'pageChanged',
    PAGE_RELOADED: 'pageReloaded',
    SITE_READY: 'siteReady',
    TRANSITION_ENDED: 'transitionEnded',
    TRANSITION_STARTED: 'transitionEnded',
    MODE_CHANGED_INIT: 'modeChangedInit',
    MODE_CHANGED_EXECUTE: 'modeChangedExecute',
    ACTIONS_ADDED_LAYOUTED: 'actionsAddedLayouted',
    ACTIONS_REMOVED: 'actionsRemoved'
}
