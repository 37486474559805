import _ from 'lodash'
import hostWorkerInit from '@wix/santa-host-platform-services/dist/host-worker-init'
import wixCodeHandlers from '../handlers/wixCodeHandlers'
import tpa from '../../../tpa'

const constants = hostWorkerInit.constants.WIX_CODE
/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
 * @implements {core.SiteAspectInterface}
 * @constructor
 */

function isMessageFromParent(msgIntent) {
    return msgIntent === constants.MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE
}

function generateResponseFunction(msg) {
    return function (data, error, ports) {
        const message = _.assign(data, {
            intent: constants.MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
            type: msg.data.type,
            error,
            callbackId: msg.data.callbackId
        })
        window.parent.postMessage(message, '*', ports)
    }
}

function handleMessageChannelMessages(msg) {
    let messageData = msg.data
    if (_.isString(messageData)) {
        try {
            messageData = JSON.parse(msg.data)
        } catch (e) {
            return
        }
    }
    if (!isMessageFromParent(messageData.intent)) {
        return
    }
    switch (messageData.type) {
        case constants.MESSAGE_TYPE.OPEN_MESSAGE_CHANNEL:
            tpa.tpaHandlers.waitForWixCodeWorkerToBeReady(this.siteAPI, msg, () => {
                wixCodeHandlers[messageData.type](this.siteAPI, msg, generateResponseFunction(msg), true)
            })
            break
    }
}

function sendSiteReadyToParent() {
    const message = {
        intent: constants.MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
        type: constants.MESSAGE_TYPE.SANTA_READY
    }
    window.parent.postMessage(message, '*')
}

function clearMessageChannelOnPageChange() {
    this.messageChannel = {}
    const message = {
        intent: constants.MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
        type: constants.MESSAGE_TYPE.SANTA_PAGE_CHANGE
    }
    window.parent.postMessage(message, '*')
}

function wixCodeMessageChannelAspect(aspectSiteAPI) {
    this.aspectSiteAPI = aspectSiteAPI
    this.siteAPI = aspectSiteAPI.getSiteAPI()
    this.messageChannel = {}
    if (this.siteAPI.getSiteData().isInSSR()) {
        return
    }
    if (window.parent !== window && this.siteAPI.getSiteData().isViewerMode()) {
        this.aspectSiteAPI.registerToMessage(handleMessageChannelMessages.bind(this))
        this.aspectSiteAPI.registerToSiteReady(sendSiteReadyToParent.bind(this))
        this.aspectSiteAPI.registerToUrlPageChange(clearMessageChannelOnPageChange.bind(this))
    }
}

wixCodeMessageChannelAspect.prototype = {
    getMessageChannel(appDefinitionId) {
        if (this.messageChannel[appDefinitionId]) {
            return this.messageChannel[appDefinitionId]
        }
        this.messageChannel[appDefinitionId] = new MessageChannel()
        return this.messageChannel[appDefinitionId]
    }
}

export default wixCodeMessageChannelAspect
