define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/image-client-api',
    'documentServices/component/component',
    'documentServices/component/componentStylesAndSkinsAPI'
], function (_, coreUtils, imageClientLib, component, componentStylesAndSkinsAPI) {
    'use strict'

    function parseSkinParams(privateServices, compPointer, skinExports) {
        const paddingLeft = parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(privateServices, compPointer, 'contentPaddingLeft'), 10) || 0
        const paddingRight = parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(privateServices, compPointer, 'contentPaddingRight'), 10) || 0
        const paddingTop = parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(privateServices, compPointer, 'contentPaddingTop'), 10) || 0
        const paddingBottom = parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(privateServices, compPointer, 'contentPaddingBottom'), 10) || 0
        const parsedParams = {}
        const contentPaddingLeft = paddingLeft + parseInt(skinExports.contentPaddingLeft || 0, 10)
        const contentPaddingRight = paddingRight + parseInt(skinExports.contentPaddingRight || 0, 10)
        const contentPaddingTop = paddingTop + parseInt(skinExports.contentPaddingTop || 0, 10)
        const contentPaddingBottom = paddingBottom + parseInt(skinExports.contentPaddingBottom || 0, 10)

        parsedParams.contentPaddingHorizontal = contentPaddingLeft + contentPaddingRight
        parsedParams.contentPaddingVertical = contentPaddingTop + contentPaddingBottom

        return parsedParams
    }

    function getImageCompSizeBySkinParams(photoOriginalSize, parsedSkinParams) {
        const width = photoOriginalSize.width - parsedSkinParams.contentPaddingHorizontal,
            height = photoOriginalSize.height - parsedSkinParams.contentPaddingVertical
        return {
            width: width > 0 ? width : 16,
            height: height > 0 ? height : 16
        }
    }

    function isImageSizeChanged(privateServices, compPointer, newLayout) {
        if (_.isUndefined(newLayout.height) && _.isUndefined(newLayout.width)) {
            return false
        }
        const layoutPointer = privateServices.pointers.getInnerPointer(compPointer, 'layout')
        const currentLayout = privateServices.dal.get(layoutPointer)
        return (
            (!_.isUndefined(newLayout.height) && newLayout.height !== currentLayout.height) ||
            (!_.isUndefined(newLayout.width) && newLayout.width !== currentLayout.width)
        )
    }

    /**
     *
     * @param {ps} privateServices
     * @param compPointer
     * @param {*} newLayout
     */
    return function (privateServices, compPointer, /** layoutObject */ newLayout) {
        if (!isImageSizeChanged(privateServices, compPointer, newLayout)) {
            return
        }
        const compProperties = component.properties.get(privateServices, compPointer)
        const compData = component.data.get(privateServices, compPointer)
        const compLayout = component.layout.get(privateServices, compPointer)
        if (compProperties.displayMode === 'fitWidth') {
            const componentOriginalSize = {
                width: newLayout.width ? newLayout.width : compLayout.width,
                height: newLayout.height ? newLayout.height : compLayout.height
            }
            const imageOriginalSize = {width: compData.width, height: compData.height}
            const parsedSkinParams = parseSkinParams(privateServices, compPointer, componentStylesAndSkinsAPI.skin.getComponentSkinExports)
            if (newLayout.height) {
                const resFitHeight = coreUtils.imageUtils.getContainerSize(
                    imageClientLib,
                    getImageCompSizeBySkinParams(componentOriginalSize, parsedSkinParams),
                    imageOriginalSize,
                    imageClientLib.fittingTypes.LEGACY_FIT_HEIGHT
                )
                newLayout.width = resFitHeight.width + parsedSkinParams.contentPaddingHorizontal
            } else {
                const resFitWidth = coreUtils.imageUtils.getContainerSize(
                    imageClientLib,
                    getImageCompSizeBySkinParams(componentOriginalSize, parsedSkinParams),
                    imageOriginalSize,
                    imageClientLib.fittingTypes.LEGACY_FIT_WIDTH
                )
                newLayout.height = resFitWidth.height + parsedSkinParams.contentPaddingVertical
            }
        }
    }
})
