define([
    'lodash',
    '@wix/wix-json-schema-utils',
    '@wix/santa-core-utils',
    'documentServices/utils/utils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/component/component',
    'documentServices/component/componentCode',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/wixCode/services/fileSystemAPI'
], function (
    _,
    jsonSchemaUtils,
    coreUtilsLib,
    dsUtils,
    componentDetectorAPI,
    actionsAndBehaviors,
    component,
    componentCode,
    appStudioDataModel,
    fileSystemAPI
) {
    'use strict'
    const {eventsMap} = coreUtilsLib

    const isEventBehavior = ({behavior}) => actionsAndBehaviors.isCodeBehavior(behavior)

    const pascalCase = str => _.upperFirst(_.camelCase(str))

    const createCustomEventName = name => `on${pascalCase(name)}`

    const getRepeaterTemplatePointer = (ps, repeatedComponentId) => {
        const templateId = coreUtilsLib.displayedOnlyStructureUtil.getRepeaterTemplateId(repeatedComponentId)
        return componentDetectorAPI.getComponentById(ps, templateId)
    }

    const toTemplateItemPointer = (ps, compPointer) => {
        const isRepeated = coreUtilsLib.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)
        return isRepeated ? getRepeaterTemplatePointer(ps, compPointer.id) : compPointer
    }

    // returns template item for repeated comps, and the actual pointer otherwise
    const getChildrenForStaticEvents = (ps, rootCompId) => {
        const comps = component.getChildren(ps, componentDetectorAPI.getComponentById(ps, rootCompId), true)

        return _(comps)
            .map(compRef => toTemplateItemPointer(ps, compRef))
            .uniqBy('id')
            .value()
    }

    // returns event name based on eventsMap from santa-core-utils if exists, and creates a dynamic name for custom events that are not in the map
    const getEventFromAction = actionName => {
        const eventName = eventsMap[actionName]

        return eventName || createCustomEventName(actionName)
    }

    /**
     * @param {ps} ps
     * @param rootCompId
     * @returns {object[]}
     */
    const getChildrenStaticEvents = (ps, rootCompId) => {
        const comps = getChildrenForStaticEvents(ps, rootCompId)
        const compsStaticEventDefinitions = _.flatMap(comps, comp => {
            const role = componentCode.getNickname(ps, comp)
            const behaviors = actionsAndBehaviors.getBehaviors(ps, comp)
            return _(behaviors)
                .filter(isEventBehavior)
                .map(({action, behavior}) => ({
                    role,
                    event: getEventFromAction(action.name),
                    callbackId: behavior.params.callbackId
                }))
                .value()
        })

        return compsStaticEventDefinitions
    }

    /**
     * @param {ps} ps
     * @param propertiesSchemas
     * @returns {*}
     */
    const serializeDefaultValues = (ps, propertiesSchemas) => {
        const customDefinitions = _.assign({}, ...appStudioDataModel.getAllSerializedCustomDefinitions(ps))
        const resolver = jsonSchemaUtils.createResolver(jsonSchemaUtils.baseDefinitions, customDefinitions)
        const defaultValues = _(propertiesSchemas)
            .map(propSchema => resolver.resolve(propSchema.structure))
            .thru(resolvedProperty => _.assign(...resolvedProperty))
            .mapValues('default')
            .value()

        return defaultValues
    }

    /**
     * @param {ps} ps
     * @param widget
     * @returns {*}
     */
    const serializeWidgetAPI = (ps, widget) => {
        const {widgetApi} = widget
        const rootCompId = dsUtils.stripHashIfExists(widget.rootCompId)
        const widgetAPIDescriptor = _(widgetApi)
            .pick(['functions', 'events'])
            .mapValues(property => _.map(property, 'name'))
            .value()
        const propertiesNames = _.flatMap(widgetApi.propertiesSchemas, property => _.keys(property.structure))
        widgetAPIDescriptor.defaultValues = serializeDefaultValues(ps, widgetApi.propertiesSchemas)
        widgetAPIDescriptor.behaviors = getChildrenStaticEvents(ps, rootCompId)
        return _.defaults({properties: propertiesNames}, widgetAPIDescriptor)
    }

    /**
     * @param {ps} ps
     * @returns {Promise<unknown>}
     */
    const saveMetadata = ps => {
        const fileLocation = fileSystemAPI.getRoots(ps).public.location
        const fileName = `${fileLocation}app-metadata.json`
        const fileDescriptor = fileSystemAPI.getVirtualDescriptor(ps, fileName, false)
        const widgets = appStudioDataModel.getAllWidgets(ps)
        const widgetsApi = {}
        widgets.forEach(widget => {
            const widgetData = appStudioDataModel.getData(ps, widget.pointer)
            const rootCompId = dsUtils.stripHashIfExists(widgetData.rootCompId)
            widgetsApi[rootCompId] = serializeWidgetAPI(ps, widgetData)
        })
        return fileSystemAPI.writeFile(ps, fileDescriptor, JSON.stringify(widgetsApi))
    }

    return {
        getChildrenStaticEvents,
        serializeWidgetAPI,
        saveMetadata
    }
})
