define(['documentServices/wixCode/saveTasks/saveWixCodeApps', 'experiment'], function (saveWixCodeApps, experiment) {
    'use strict'

    const TASK_NAME = 'saveWixCodeTask'

    const saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode = (last, current, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) => {
        if (experiment.isOpen('ds_provisionBeforeSaveCode')) {
            resolve()
        } else {
            saveWixCodeApps(last, current, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
        }
    }

    const saveWixCodeAppsAsync = (last, current, bi, options, lastSnapshotDal, currentSnapshotDal) =>
        new Promise((resolve, reject) => {
            saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode(last, current, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
        })

    const createTask = () => ({
        partialSave: saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode,
        fullSave: saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode,
        fullSaveAsync: saveWixCodeAppsAsync,
        firstSave: saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode,
        saveAsTemplate: saveWixCodeAppsOnlyIfNotDoingItTogetherWithSaveCode,
        publish(currentData, resolve) {
            resolve()
        },
        autosave(lastSnapshot, currentSnapshot, resolve) {
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags(methodName) {
            switch (methodName) {
                case 'autosave':
                    return ['autosave']
                default:
                    return ['primary']
            }
        }
    })

    return () => createTask()
})
