import listProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/listProxy'

/**
 * @class proxies.FlowList
 * @extends proxies.list
 */
export default {
    mixins: [listProxy],

    getDefaultProps() {
        return {
            additionalStyle: {
                display: 'block',
                whiteSpace: 'normal'
            },
            childAdditionalStyle: {
                display: 'inline-block',
                width: 'auto'
            }
        }
    }
}
