define(['documentServices/saveAPI/saveDataFixer/plugins/platformAppsSaveDataFixer', '@wix/santa-ds-libs/src/coreUtils'], function (
    platformAppsSaveDataFixer,
    coreUtils
) {
    'use strict'
    const plugins = [
        platformAppsSaveDataFixer
        /*
            STOP!
            If you are adding a save-data-fixer that means that you have a bug during the editing session.
            Please fix the bug and don't patch it here.
            AUTOSAVE DOESN'T GO THROUGH THIS CODE - so you have to make sure the data in the snapshots is always correct and ready to be saved.
        */
    ]

    /**
     *
     * @param {{component_properties:object, document_data:object, theme_data:object}} dataToSave
     * @param args
     * @param fixerPlugins
     * @returns {*}
     */
    function fixDataInternal(dataToSave, args, fixerPlugins) {
        coreUtils.loggingUtils.performance.start(coreUtils.loggingUtils.performanceMetrics.SAVE.SAVE_DATA_FIXER)
        fixerPlugins.forEach(function (plugin) {
            plugin.exec(dataToSave, args)
        })
        coreUtils.loggingUtils.performance.start(coreUtils.loggingUtils.performanceMetrics.SAVE.SAVE_DATA_FIXER)
        return dataToSave
    }

    /**
     * @exports utils/saveDataFixer/saveDataFixer
     */
    const exports = {
        fixData: (dataToSave, args) => fixDataInternal(dataToSave, args, plugins)
    }

    return exports
})
