define([
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/page/page',
    'documentServices/page/popupUtils'
], function (actionsAndBehaviors, documentInfo, page, popupUtils) {
    'use strict'

    function getContainerToAddTo(ps, containerPointer) {
        if (ps.pointers.components.isPage(containerPointer) && page.popupPages.isPopup(ps, containerPointer.id)) {
            return popupUtils.getPopupContainer(ps, containerPointer)
        }

        return containerPointer
    }

    function removeDeadBehaviors(ps, popupPointer) {
        const isPopup = page.popupPages.isPopup(ps, popupPointer.id)
        if (!isPopup) {
            return
        }

        const viewMode = documentInfo.getViewMode(ps)
        const pagePointers = page.getPageIdList(ps, true).map(pageId => ps.pointers.components.getPage(pageId, viewMode))

        pagePointers.forEach(function (pagePointer) {
            actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, pagePointer, {behavior: {targetId: popupPointer.id}})
        })
    }

    function removeBehaviorsFromAddedPage(ps, pagePointer) {
        actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, pagePointer, {behavior: {name: 'openPopup'}})
    }

    return {
        getContainerToAddTo,
        removeDeadBehaviors,
        removeBehaviorsFromAddedPage
    }
})
