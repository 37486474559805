import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const appControllerData = viewerLibrary.get('documentServices/appControllerData/appControllerData')
    return {
        methods: {
            /** @class documentServices.platform*/
            platform: {
                /** @class documentServices.platform.controllers*/
                controllers: {
                    /** @class documentServices.platform.controllers.settings*/
                    settings: {
                        /**
                         * Sets the passed settings to the passed controller
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to set settings to
                         * @param {object} settingsItem - the settings to set to the controller
                         */
                        set: publicMethodUtils.defineDataManipulationAction(appControllerData.setSettings),
                        /**
                         * Gets the settings of the passed controller
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to get settings of
                         */
                        get: publicMethodUtils.defineGetter(appControllerData.getSettings),
                        /**
                         * Sets the settings of the passed controller in the given path
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to set settings of
                         * @param {string} path - the path of the property to set
                         * @param {object} settingsItem - the value to set
                         */
                        setIn: publicMethodUtils.defineDataManipulationAction(appControllerData.setSettingsIn),
                        /**
                         * Gets the settings of the passed controller in the given path
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to get settings of
                         * @param {string} path - the path of the property to get.
                         */
                        getIn: publicMethodUtils.defineGetter(appControllerData.getSettingsIn),
                        /**
                         * Sets the passed external settings id to the passed controller
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to set settings to
                         * @param {string} settingsItem - the external settings id to set to the controller
                         */
                        setExternalId: publicMethodUtils.defineDataManipulationAction(appControllerData.setExternalId),
                        /**
                         * Resets the external settings id of the passed controller
                         * @member documentServices.platform.controllers.settings
                         * @param {AbstractComponent} controllerRef - the controller to reset its settings
                         */
                        getExternalId: publicMethodUtils.defineGetter(appControllerData.getExternalId)
                    },
                    /**
                     * Gets the name of the passed controller
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to get the name of
                     */
                    getName: publicMethodUtils.defineGetter(appControllerData.getName),
                    /**
                     * Sets the name of the passed controller
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to set the name of
                     * @param {string} controllerName - the name to set
                     */
                    setName: publicMethodUtils.defineDataManipulationAction(appControllerData.setName),
                    /**
                     * Returns the state of the passed controller
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to get the state of
                     */
                    getState: publicMethodUtils.defineGetter(appControllerData.getState),
                    /**
                     * Sets the state of the passed controller
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to set the state of
                     * @param {string} state - the state to set
                     */
                    setState: publicMethodUtils.defineDataManipulationAction(appControllerData.setState),

                    /**
                     * Returns the stageData of the passed controller
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to get the stageData of
                     */
                    getStageData: publicMethodUtils.defineGetter(appControllerData.getControllerStageDataByControllerRef),

                    /**
                     * Returns the stageData of the passed controller, and role
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} controllerRef - the controller to get the stageData of
                     * @param {string} role - the role to get the stageData of
                     */
                    getStageDataByRole: publicMethodUtils.defineGetter(appControllerData.getControllerRoleStageDataByControllerRefAndRole),

                    /**
                     * Returns the stageData of the passed connected component
                     * @member documentServices.platform.controllers
                     * @param {AbstractComponent} compPointer - the pointer of the connected component to get the stageData of
                     * @param {object} options
                     * options.usePrimaryRoleOnly - If provided, only primary role of the connection is considerd. defaults to false.
                     */
                    getConnectedComponentStageData: publicMethodUtils.defineGetter(appControllerData.getConnectedComponentStageData)
                }
            }
        }
    }
}
