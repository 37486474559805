import siteButtonProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/siteButtonProxy'
import _ from 'lodash'

/**
 * @class proxies.Button2
 * @extends proxies.mixins.siteButton
 */
export default {
    mixins: [siteButtonProxy],

    transformSkinProperties(refData) {
        const proxyStyle = this.getProxyStyle()
        const height = proxyStyle.height || this.getCompProp('height') || 30
        const rootProps = {
            onClick: this.handleViewEvent,
            'data-proxy-name': 'Button2',
            style: {height, maxWidth: proxyStyle.width}
        }

        if (this.getCompProp('shouldNotExceedParentWidth')) {
            // A cross-browser CSS-trick to make the actual width be the minimum of `max-width` and `width`.
            // @ts-ignore
            rootProps.style.width = '100%'

            // Tell the button's layout to not measure and overwrite the inline width from above, but use the actual
            // width as is.
            rootProps['data-should-prevent-width-measurement'] = true
        }

        refData[''] = _.merge({}, refData[''], rootProps)

        const style = {overflow: 'hidden'}
        refData.link.style = _.merge({}, refData.link.style, style)

        return refData
    },

    getDefaultLabel() {
        return ''
    }
}
