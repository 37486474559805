import santaComponents from '@wix/santa-components'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

/**
 * @class proxies.spacers.inlineSpacer
 * @extends proxies.mixins.baseProxy
 * @property {proxy.properties} props
 */
export default {
    mixins: [baseProxy],
    getCustomStyle() {
        const size = this.getCompProp('size')
        return {
            wordSpacing: size,
            display: 'inline'
        }
    },
    renderProxy() {
        const childCompProps = this.getChildCompProps()

        return santaComponents.utils.createReactElement('div', childCompProps, ' ')
    }
}
