import {loggingUtils} from '@wix/santa-ds-libs/src/warmupUtils'

function report(siteData, params) {
    loggingUtils.logger.reportBI(
        siteData,
        {
            reportType: 'event',
            adapter: 'pa',
            sampleRatio: 0
        },
        params
    )
}

export default {
    report
}
