import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {createGlobalSantaTypesFetcher} = coreUtils.propsSelectorsUtils

const linkRenderInfo = createGlobalSantaTypesFetcher(function (state) {
    return coreUtils.wixUrlParser.utils.getResolvedSiteData(state.siteData)
}, 'linkRenderInfo')

export default {
    linkRenderInfo
}
