import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import santaComponents from '@wix/santa-components'

const wixTransitionItem = createReactClass({
    displayName: 'wixTransitionItem',
    mixins: [santaComponents.mixins.animatableMixin],
    propTypes: {
        onWillEnter: PropTypes.func.isRequired,
        onWillLeave: PropTypes.func.isRequired,
        refInParent: PropTypes.string,
        children: PropTypes.node
    },
    render() {
        return this.props.children
    },
    componentWillEnter(callback) {
        this.props.onWillEnter(this.props.refInParent, callback)
    },
    componentWillLeave(callback) {
        this.props.onWillLeave(this.props.refInParent, callback)
    }
})

export default wixTransitionItem
