import {urlUtils} from '@wix/santa-core-utils'
import appsUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/appsUtils'
import workerPropsBuilder from '@wix/santa-ds-libs/src/platformInit/src/api/workerPropsBuilder'
import _ from 'lodash'

let _utils = null
let _hostPlatformAPI = null
let _siteWidgets = null
let _wixCode = null

const pendingMessages = []

function asyncGetMessage(currentUrl, buildMessageFunc, callback, getSiteDataFunc) {
    if (missingDeps()) {
        pendingMessages.push([currentUrl, buildMessageFunc, callback, getSiteDataFunc])
        requirejs(['@wix/santa-ds-libs/src/utils', 'host-platform-api', 'site-widgets', 'wixCode'], registerDeps)
        return
    }
    /*
     TODO:
     utils package is currently required so we don't duplicate the code for url parsing.
     hostPlatformAPI package is currently required so we don't duplicate the code that knows how to build a load_widgets message.
     wixCode package is currently required so we don't duplicate the code for extending message with wix code specific data.
     these requirements postpone the pre-loading of hostPlatformAPI by a few seconds.
     we should refactor the code in such a way that will allow us to only load very small packages to make pre-loading relevant.
     */
    const siteData = getSiteDataFunc(_utils)
    const parsedUrl = _utils.wixUrlParser.parseUrl(siteData, currentUrl)
    const currentPageId = parsedUrl && parsedUrl.pageId
    if (!currentPageId || _.includes(_.get(siteData, 'publicModel.siteMembersProtectedPages'), currentPageId)) {
        return
    }
    const message = buildMessageFunc(_hostPlatformAPI, siteData, currentPageId)
    const wixCodeSpec = _wixCode.wixCodeWidgetService.getWixCodeSpec(siteData.getClientSpecMap())
    const extendedLoadMessage = _wixCode.messageBuilder.getExtendedMessage(message, siteData.rendererModel.wixCodeModel || {}, wixCodeSpec, siteData)
    callback(extendedLoadMessage)
}

function asyncGetPreLoadMessage(siteModel, currentUrl, callback) {
    const buildLoadMessage = function (hostPlatformAPI, dummySiteData, currentPageId) {
        const {rendererModel, wixBiSession, biData} = siteModel
        const widgetDefs = appsUtils.getApplications(siteModel.rendererModel.clientSpecMap, [currentPageId], dummySiteData)
        const routers = siteModel.routers || {configMap: {}}
        const dataAPI = hostPlatformAPI.modelBuilderDataHelper.getApi(dummySiteData)
        const rgi = hostPlatformAPI.globalsBuilder.build(dataAPI, true, currentPageId)
        const biSessionData = workerPropsBuilder.buildBiSession({wixBiSession, rendererModel, currentUrl, biData})
        return hostPlatformAPI.messageBuilder.loadWidgetsMessage(widgetDefs, routers.configMap, [currentPageId], {[currentPageId]: rgi.toJson()}, biSessionData)
    }

    asyncGetMessage(currentUrl, buildLoadMessage, callback, _.partial(getSiteData, siteModel, currentUrl))
}

function asyncGetLoadUserGeneratedAppsMessage(siteModel, currentUrl, applications, callback) {
    const buildLoadMessage = (hostPlatformAPI, dummySiteData, rootId) => hostPlatformAPI.messageBuilder.loadUserGeneratedAppsMessage(applications, [rootId])

    asyncGetMessage(currentUrl, buildLoadMessage, callback, _.partial(getSiteData, siteModel, currentUrl))
}

function getSiteData(siteModel, currentUrl, utils) {
    siteModel.currentUrl = urlUtils.parseUrl(currentUrl)

    return new utils.FullSiteData(siteModel, function () {}) // dummy site data as a hack for using wixUrlParser
}

function asyncGetPreLoadUserCodeMessage(siteModel, currentUrl, callback) {
    const buildUserCodeLoadMessage = function (hostPlatformAPI, dummySiteData, currentPageId) {
        const widgetDefs = appsUtils.getUserCodeDefinitions(siteModel.rendererModel.clientSpecMap, [currentPageId], dummySiteData)
        return hostPlatformAPI.messageBuilder.loadUserCodesMessage(widgetDefs, [currentPageId])
    }

    asyncGetMessage(currentUrl, buildUserCodeLoadMessage, callback, _.partial(getSiteData, siteModel, currentUrl))
}

function asyncGetPreInitMessage(siteData, currentUrl, callback) {
    const buildInitMessage = function (hostPlatformAPI, dummySiteData, currentPageId) {
        const controllerToInit = {}
        controllerToInit[currentPageId] = appsUtils.getContextInitData(dummySiteData, currentPageId, _utils.styleUtils)
        return hostPlatformAPI.messageBuilder.initWidgetsMessage(controllerToInit)
    }
    const returnSiteData = function () {
        return siteData
    }
    asyncGetMessage(currentUrl, buildInitMessage, callback, returnSiteData)
}

function registerDeps(utils, hostPlatformAPI, siteWidgets, wixCode) {
    if (missingDeps()) {
        _utils = utils
        _hostPlatformAPI = hostPlatformAPI
        _siteWidgets = siteWidgets
        _wixCode = wixCode
        // @ts-ignore
        pendingMessages.forEach(messageArgs => asyncGetMessage(...messageArgs))
        pendingMessages.length = 0
    }
}

function missingDeps() {
    return !_utils || !_hostPlatformAPI || !_siteWidgets || !_wixCode
}

export default {
    asyncGetPreLoadMessage,
    asyncGetPreLoadUserCodeMessage,
    asyncGetPreInitMessage,
    asyncGetLoadUserGeneratedAppsMessage,
    registerDeps
}
