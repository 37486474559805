define([
    'lodash',
    'experiment',
    '@wix/santa-core-utils',
    'documentServices/structure/structure',
    'documentServices/dataModel/dataModel',
    'documentServices/anchors/anchors',
    'documentServices/component/componentStructureInfo',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/utils/utils',
    'documentServices/constants/constants',
    'documentServices/variants/design'
], function (_, experiment, santaCoreUtils, structure, dataModel, anchors, componentStructureInfo, componentsMetaData, utils, constants, design) {
    'use strict'

    const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'
    const {DATA_TYPES} = constants

    function createDisplayedJsonForComponent(ps, componentRef) {
        ps.siteAPI.createDisplayedNode(componentRef)
    }

    function afterLayoutChanged(ps, compPointer, updatedLayout, updateCompLayoutCallbackForHooks, isTriggeredByHook, previousLayout) {
        if (updatedLayout.width !== previousLayout.width) {
            const itemPointer = _.head(ps.pointers.components.getChildren(compPointer))
            const itemLayout = ps.dal.get(ps.pointers.getInnerPointer(itemPointer, ['layout']))
            structure.updateCompLayout(ps, itemPointer, itemLayout, true)
        }
    }

    function addRepeatedDataItems(compStructure, flags) {
        flags.repeatedItemIds = compStructure.data.items
    }

    const dataModelMethods = {
        dataQuery: {
            add: dataModel.addSerializedDataItemToPage,
            get(ps, compPointer) {
                const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
                return dataModel.serializeDataItem(ps, DATA_TYPES.data, dataItemPointer, true)
            },
            getItemById: dataModel.getDataItemById,
            delete: dataModel.deleteDataItem
        },
        designQuery: {
            add: dataModel.addSerializedDesignItemToPage,
            get(ps, compPointer) {
                const designItemPointer = design.getDesignItemPointer(ps, compPointer)
                return dataModel.serializeDataItem(ps, DATA_TYPES.design, designItemPointer, true)
            },
            getItemById: design.getDesignItemById,
            delete: dataModel.deleteDesignItem
        }
    }

    function duplicateCompsDataItems(ps, pagePointer, itemId, compPointer) {
        _.forEach(dataModelMethods, function (func, dataType) {
            let query = ps.dal.get(ps.pointers.getInnerPointer(compPointer, dataType))
            if (query) {
                query = utils.stripHashIfExists(query)
                const originalDataId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(query)
                const api = dataModelMethods[dataType]
                const duplicatedDataItem = api.get(ps, compPointer)
                if (duplicatedDataItem) {
                    api.add(ps, pagePointer.id, duplicatedDataItem, santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(originalDataId, itemId))
                }
            }
        })
    }

    /**
     *
     * @param newItems The items array of the new data
     * @param currentItems The items array of the current data
     * @param itemId The new item id that needs to be duplicated
     * @returns {number} The index of the item in the current data that needs to be duplicated
     */
    function getOriginalItemIndexToDuplicate(newItems, currentItems, itemId) {
        let itemIndex = Math.max(_.indexOf(newItems, itemId), 0)
        while (itemIndex > 0) {
            itemIndex--
            const currentItemIndex = _.indexOf(currentItems, newItems[itemIndex])
            if (currentItemIndex >= 0) {
                return currentItemIndex
            }
        }

        return 0
    }

    function deleteCompsDataItems(ps, compPointer) {
        _.forEach(dataModelMethods, function (api) {
            api.delete(ps, compPointer)
        })
    }

    function beforeUpdateRepeaterData(ps, compPointer, dataItem) {
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer) || ps.pointers.full.components.getPageOfComponent(compPointer)
        const currentItems = _.get(dataModel.getDataItem(ps, compPointer), 'items')
        const newItems = _.filter(dataItem.items, function (id) {
            return !_.includes(currentItems, id)
        })
        const removedItems = _.filter(currentItems, function (id) {
            return !_.includes(dataItem.items, id)
        })
        const children = ps.pointers.components.getChildren(compPointer)
        _.forEach(newItems, function (itemId) {
            const originalDataIndex = getOriginalItemIndexToDuplicate(dataItem.items, currentItems, itemId)
            const duplicatedChildPointer = children[originalDataIndex]
            const allDuplicatedComps = [duplicatedChildPointer].concat(ps.pointers.components.getChildrenRecursively(duplicatedChildPointer))
            _.forEach(allDuplicatedComps, _.partial(duplicateCompsDataItems, ps, pagePointer, itemId))
        })

        _.forEach(currentItems, function (itemId, index) {
            if (!_.includes(dataItem.items, itemId)) {
                const deletedChildPointer = children[index]
                const allDuplicatedComps = [deletedChildPointer].concat(ps.pointers.components.getChildrenRecursively(deletedChildPointer))
                _.forEach(allDuplicatedComps, _.partial(deleteCompsDataItems, ps))
            }
        })

        const componentTemplateIdPointer = ps.pointers.displayedOnlyComponents.getComponentTemplateId(compPointer.id)

        if (!_.isEmpty(removedItems) && _.includes(removedItems, ps.dal.get(componentTemplateIdPointer))) {
            ps.dal.set(componentTemplateIdPointer, _.head(dataItem.items))
        }
    }

    function afterUpdateRepeaterData(ps, compPointer) {
        ps.siteDataAPI.createDisplayedNode(compPointer)
        syncAllRepeaterTemplateCompsWithFirstItem(ps, compPointer)
    }

    function afterUpdateRepeaterProperties(ps, compPointer) {
        ps.siteDataAPI.updateDisplayedNodesLayout(compPointer)
        anchors.updateAnchorsForCompChildren(ps, compPointer)
    }

    function overrideItemIdToOriginalId(dataItem, displayedId) {
        if (dataItem) {
            return _.assign(dataItem, {id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(displayedId)})
        }
        return dataItem
    }

    function updateDataItemFromDisplayedToFull(ps, pageId, sourcePointer, destPointer, propertyToUpdate) {
        let dataQuery = ps.dal.get(ps.pointers.getInnerPointer(sourcePointer, propertyToUpdate))
        if (!dataQuery) {
            return
        }
        dataQuery = utils.stripHashIfExists(dataQuery)
        const newDataItem = overrideItemIdToOriginalId(dataModelMethods[propertyToUpdate].getItemById(ps, dataQuery, pageId, true), dataQuery)
        if (newDataItem) {
            const templateDataQuery = utils.stripHashIfExists(ps.dal.full.get(ps.pointers.getInnerPointer(destPointer, propertyToUpdate)))
            dataModelMethods[propertyToUpdate].delete(ps, destPointer)
            dataModelMethods[propertyToUpdate].add(ps, pageId, newDataItem, templateDataQuery)
        }
    }

    function updateTemplateAccordingToFirstRepeaterItem(ps, displayedPointer, dataPropsToUpdate) {
        const pagePointer = ps.pointers.components.getPageOfComponent(displayedPointer)
        const templateCompId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(displayedPointer.id)
        const templateCompPointer = ps.pointers.full.components.getComponent(templateCompId, pagePointer)

        _.forEach(dataPropsToUpdate, function (propToUpdate) {
            updateDataItemFromDisplayedToFull(ps, pagePointer.id, displayedPointer, templateCompPointer, propToUpdate)
        })
    }

    function isDescendantOfFirstRepeaterItem(ps, repeaterPointer, compPointer) {
        const repeaterItems = ps.pointers.components.getChildren(repeaterPointer)
        if (_.size(repeaterItems) > 0) {
            const firstDisplayedItem = repeaterItems[0]
            return (
                santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(firstDisplayedItem.id) ===
                santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id)
            )
        }
        return false
    }

    const getRepeaterAncestor = (ps, comp) =>
        componentStructureInfo.getAncestorByPredicate(ps, comp, ancestor => utils.getComponentType(ps, ancestor) === REPEATER_TYPE)

    function isFirstDisplayedItemOfRepeater(ps, compPointer) {
        if (santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)) {
            const repeaterPointer = getRepeaterAncestor(ps, compPointer)
            if (repeaterPointer) {
                if (isDescendantOfFirstRepeaterItem(ps, repeaterPointer, compPointer)) {
                    return true
                }
            }
        }
        return false
    }

    function syncRepeaterTemplateDataWithFirstItem(propertyToUpdate, ps, compPointer) {
        if (isFirstDisplayedItemOfRepeater(ps, compPointer)) {
            updateTemplateAccordingToFirstRepeaterItem(ps, compPointer, [propertyToUpdate])
        }
    }

    function syncAllRepeaterTemplateCompsWithFirstItem(ps, repeaterPointer) {
        const children = ps.pointers.components.getChildren(repeaterPointer)
        if (_.size(children) > 0) {
            const compsToSync = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(children[0])
            _.forEach(compsToSync, function (compPointer) {
                updateTemplateAccordingToFirstRepeaterItem(ps, compPointer, ['dataQuery', 'designQuery'])
            })
        }
    }
    function afterAdd(ps, componentRef) {
        createDisplayedJsonForComponent(ps, componentRef)
        syncAllRepeaterTemplateCompsWithFirstItem(ps, componentRef)
    }

    const getRepeatedItemPointer = (pointer, itemId) => {
        return {...pointer, id: santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(pointer.id, itemId)}
    }

    const isRemovingMobileInstanceOfDesktopComp = (ps, compPointer) =>
        ps.pointers.components.isMobile(compPointer) && !componentsMetaData.public.isMobileOnly(ps, compPointer)

    const beforeRemove = (ps, component) => {
        if (!experiment.isOpen('dm_alwaysRemoveFromFull') || isRemovingMobileInstanceOfDesktopComp(ps, component)) {
            return
        }
        const repeaterAncestor = getRepeaterAncestor(ps, component)
        if (repeaterAncestor) {
            const repeaterData = dataModel.getDataItem(ps, repeaterAncestor)
            const {items} = repeaterData
            const templateDataItemPointer = dataModel.getDataItemPointer(ps, component)
            const templateDesignItemPointer = dataModel.getDesignItemPointer(ps, component)
            items.forEach(itemId => {
                if (ps.dal.isExist(templateDataItemPointer)) {
                    const repeatedDataItemPointer = getRepeatedItemPointer(templateDataItemPointer, itemId)
                    dataModel.removeItemRecursivelyByType(ps, repeatedDataItemPointer)
                }

                if (ps.dal.isExist(templateDesignItemPointer)) {
                    const repeatedDesignItemPointer = getRepeatedItemPointer(templateDesignItemPointer, itemId)
                    dataModel.removeItemRecursivelyByType(ps, repeatedDesignItemPointer)
                }
            })
        }
    }

    return {
        afterAdd,
        beforeRemove,
        addRepeatedDataItems,
        afterLayoutChanged,
        beforeUpdateRepeaterData,
        afterUpdateRepeaterData,
        // @ts-ignore
        syncRepeaterTemplateDataWithFirstItem: syncRepeaterTemplateDataWithFirstItem.bind(this, 'dataQuery'),
        // @ts-ignore
        syncRepeaterTemplateDesignWithFirstItem: syncRepeaterTemplateDataWithFirstItem.bind(this, 'designQuery'),
        afterUpdateRepeaterProperties
    }
})
