define([], function () {
    'use strict'

    return {
        styleCanBeApplied: true,
        mobileConversionConfig: {
            category: 'boxContainer',
            minHeight: 50,
            isSuitableForProportionGrouping: true,
            filterChildrenWhenHidden: true
        },
        heightAuto: true
    }
})
