import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import bgImageLayout from './bgImageLayout'
import bgOverlayLayout from './bgOverlayLayout'
import html5VideoLayout from './html5VideoLayout'

const {BALATA, MEDIA, MEDIA_PADDING, WEBGL, CANVAS, VIDEO, IMAGE, OVERLAY, POSTER, BG_COLOR, BG_IMAGE} = warmupUtilsLib.mediaConsts.balataConsts
const {containerBackgroundUtils} = warmupUtilsLib
/**
 * Paths to pass to registerToMeasureChildren of balata parents
 * @type {*[]}
 */
const BALATA_PATHS_TO_REQUEST_MEASURE = [
    [BALATA],
    [BALATA, MEDIA],
    [BALATA, MEDIA_PADDING],
    [BALATA, MEDIA, IMAGE],
    [BALATA, MEDIA, VIDEO],
    [BALATA, MEDIA, WEBGL],
    [BALATA, MEDIA, WEBGL, CANVAS],
    [BALATA, MEDIA, VIDEO, VIDEO],
    [BALATA, MEDIA, VIDEO, POSTER],
    [BALATA, OVERLAY],
    [BALATA, OVERLAY, OVERLAY],
    [BALATA, BG_COLOR],
    [BALATA, BG_COLOR, BG_COLOR]
]
/**
 * Helper to build id names of balata and children
 * @param {string} parentId
 * @returns {{balataId: string, mediaId: string, imageId: string, videoId: string}}
 */
function getBalataIds(parentId) {
    const balataId = parentId + BALATA
    const mediaId = balataId + MEDIA
    const imageId = mediaId + IMAGE
    const videoId = mediaId + VIDEO
    const posterId = mediaId + VIDEO + POSTER
    const webglId = mediaId + WEBGL
    const canvasId = mediaId + WEBGL + CANVAS
    const mediaPaddingId = balataId + MEDIA_PADDING
    return {balataId, mediaId, imageId, videoId, webglId, canvasId, mediaPaddingId, posterId}
}

/**
 * Default measures by parent measureMap and DOM
 * @param {object} measureMap
 * @param {HTMLElement} balataNode
 * @param {string} parentId
 * @param {number} pageLeft compensate for css transforms on the page
 * @returns {{top: number, left: number, width: *, height: *, absoluteLeft: number}}
 */
function getDefaultMeasures(measureMap, balataNode, parentId, pageLeft) {
    const top = 0
    const left = 0
    // We have to get height from measureMap since it might be different than dom height
    const height = _.defaultTo(measureMap.height[parentId], balataNode.offsetHeight)

    return {
        top,
        left,
        height,
        width: balataNode.offsetWidth,
        absoluteLeft: balataNode.getBoundingClientRect().left - pageLeft
    }
}

/**
 * Default measures by self DOM measures
 * @param {HTMLElement} node
 * @param {HTMLElement} pageNode
 * @returns {{top: number, left: number, width, height, absoluteLeft: number}}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getDomMeasures(node, pageNode) {
    return {
        top: 0,
        left: 0,
        width: node.offsetWidth,
        height: node.offsetHeight,
        absoluteLeft: node.getBoundingClientRect().left - pageNode.getBoundingClientRect().left
    }
}

/**
 * Get dimension saved to measuremap in measure phase
 * @param {object} measureMap
 * @param {string} balataId
 * @returns {{top: number, left: number, width: number, height: number, absoluteLeft: number}}
 */
function getSavedMeasures(measureMap, balataId) {
    return {
        top: measureMap.top[balataId],
        left: measureMap.left[balataId],
        width: measureMap.width[balataId],
        height: measureMap.height[balataId],
        absoluteLeft: measureMap.absoluteLeft[balataId]
    }
}

/**
 * Get style to patch
 * @param {{width: number, height: number}} measures
 * @param {boolean} useClipPath
 * @param {boolean} needsClipping
 * @returns {{clip: string} || {}} clip key and value
 */
function getClipStyle(measures, useClipPath, needsClipping) {
    const balataStyle: {clip?: string} = {}
    if (!useClipPath && needsClipping) {
        balataStyle.clip = `rect(0px,${measures.width}px,${measures.height}px,0px)`
    }
    return balataStyle
}

/**
 * Measure function to call from DOM Only compliant parent component
 * @param parentId
 * @param measureMap
 * @param nodesMap
 * @param siteData
 * @param structureInfo
 */
function measureDomOnly(parentId, measureMap, nodesMap, siteData, structureInfo) {
    // const {balataId} = getBalataIds(parentId)
    // const {pageId} = nodesMap[balataId].dataset
    measure(
        parentId,
        measureMap,
        nodesMap,
        siteData,
        structureInfo
        // , getDomMeasures(nodesMap[balataId], nodesMap[pageId])
    )
}

/**
 * Measure function to call from parent component
 * @param parentId
 * @param measureMap
 * @param nodesMap
 * @param structureInfo
 * @param parentDimensions
 */

function measure(parentId, measureMap, nodesMap, structureInfo, parentDimensions?) {
    const {balataId, mediaId, videoId, posterId} = getBalataIds(parentId)
    const hasBalata = !!nodesMap[balataId]
    if (!hasBalata) {
        return
    }

    const {enableVideo, bgEffectName, mediaType, useClipPath, needsClipping, pageId, renderType} = nodesMap[balataId].dataset
    const {mediaCompType, isFullHeight} = mediaType ? nodesMap[mediaId].dataset : ({} as any)
    const pageLeft = nodesMap[pageId] ? nodesMap[pageId].getBoundingClientRect().left : 0
    const measures = parentDimensions || getDefaultMeasures(measureMap, nodesMap[balataId], parentId, pageLeft)
    const isMediaFixed = nodesMap[mediaId] && nodesMap[mediaId].style.position === 'fixed'
    measureMap.custom[balataId] = {
        hasBalata,
        isFullHeight,
        isMediaFixed,
        enableVideo,
        bgEffectName,
        mediaType,
        useClipPath,
        needsClipping,
        pageId,
        mediaCompType,
        renderType
    }

    _.forEach(measures, function (value, key) {
        measureMap[key][balataId] = value
    })

    if (!mediaType) {
        return
    }

    if (renderType !== 'bolt') {
        if (bgEffectName) {
            measureMap.width[mediaId] = measureMap.width[balataId]
        }

        if (mediaCompType === BG_IMAGE) {
            bgImageLayout.measureBgImageBalata(balataId, measureMap, nodesMap)
        }

        bgOverlayLayout.measure(balataId, measureMap, nodesMap)

        if (mediaType === 'WixVideo' && enableVideo) {
            html5VideoLayout.measureBgVideo(mediaId, videoId, posterId, measureMap, nodesMap)
        }
    }
}

/**
 * Patch function to call from parent layout
 * @param parentId
 * @param patchers
 * @param measureMap
 * @param structureInfo
 * @param siteData
 * @param parentDimensions
 */
function patch(parentId, patchers, measureMap, structureInfo, siteData, parentDimensions?) {
    const {balataId, mediaId, imageId, canvasId, videoId, mediaPaddingId} = getBalataIds(parentId)
    const {hasBalata, isFullHeight, enableVideo, bgEffectName, isMediaFixed, mediaType, useClipPath, needsClipping, mediaCompType, renderType} =
        measureMap.custom[balataId] || {}
    if (!hasBalata) {
        return
    }

    const measures = parentDimensions || getSavedMeasures(measureMap, balataId)

    const tempEffectNameOverride = isFullHeight ? 'BackgroundParallax' : bgEffectName

    //TODO: this re-takes parent height to make sure we know about anchors changes, will be irrelevant in mesh.
    if (!parentDimensions) {
        measures.height = _.defaultTo(measureMap.height[parentId], measures.height)
    }
    //patch balata
    const balataClip = getClipStyle(measures, useClipPath, needsClipping)
    patchers.css(balataId, balataClip)

    if (!mediaType) {
        return
    }
    const isMediaPadding = _.isNumber(measureMap.height[mediaPaddingId])
    const mediaContainerHeight = isMediaPadding ? measureMap.height[mediaPaddingId] : measures.height
    const mediaHeight = containerBackgroundUtils.getHeightByEffect(tempEffectNameOverride, measureMap, mediaContainerHeight)
    //patch media wrapper
    if (measureMap.height[mediaPaddingId]) {
        const paddingClip = getClipStyle({width: measures.width, height: mediaHeight}, useClipPath, needsClipping)
        patchers.css(mediaPaddingId, paddingClip)
    }

    if (renderType !== 'bolt') {
        bgOverlayLayout.patch(balataId, patchers, measureMap)

        //patch media
        if (isFullHeight && isMediaFixed) {
            patchers.css(mediaId, {
                height: mediaHeight,
                width: measureMap.width[balataId],
                left: measures.absoluteLeft || 0
            })
        } else {
            //since React doesnt know about layout changes, we need to update back when removing effect
            patchers.css(mediaId, {
                height: '100%',
                width: '100%',
                left: 0
            })
        }

        if (mediaCompType === BG_IMAGE) {
            bgImageLayout.patchBgImage(balataId, imageId, patchers, measureMap, siteData)
        }

        if (mediaType === 'WixVideo' && enableVideo) {
            html5VideoLayout.patchBgVideo(videoId, canvasId, patchers, measureMap, siteData.prefersReducedMotion)
        }
    }
}

export default {
    BALATA_PATHS_TO_REQUEST_MEASURE,
    measure,
    measureDomOnly,
    patch
}
