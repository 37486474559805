define(['lodash', 'documentServices/component/component'], function (_, component) {
    'use strict'

    return function (ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
        const children = ps.pointers.components.getChildren(containerPointer)
        function isSameCompType(child) {
            return component.getType(ps, child) === compDefinitionPrototype.componentType
        }

        if (_.find(children, isSameCompType)) {
            throw new Error('Only one SiteRegionContainer allowed')
        }
    }
})
