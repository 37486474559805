define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/component/componentData',
    'documentServices/hooks/layoutHooks/mediaPlayer',
    'documentServices/variants/design',
    'documentServices/utils/utils'
], function (_, dataModel, component, componentData, mediaPlayerLayout, design, dsUtils) {
    'use strict'

    /**
     * Call layout hooks for children of layer
     * @param {ps} ps
     * @param compPointer
     */
    function layoutPlayerChildren(ps, compPointer) {
        const layout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        mediaPlayerLayout.layoutPlayerChildren(ps, compPointer, layout)
    }

    /**
     * Set playerId to Media Player children
     * Call hook for both desktop and mobile structures
     * @param {ps} ps
     * @param componentPointer
     * @param clonedSerializedComp
     * @param optionalCustomId
     * @param oldToNewIdMap
     * @param containerPointer
     */
    function setPlayerIdAfterAddHook(ps, componentPointer, clonedSerializedComp, optionalCustomId, oldToNewIdMap, containerPointer) {
        setPlayerIdToComponent(ps, componentPointer, containerPointer)
    }

    /**
     * Iterate over all media player children and update the ones which support playerID in comp props with the
     * Media Player comp id
     * @param {ps} ps
     * @param componentPointer
     * @param containerPointer
     */
    function setPlayerIdToComponent(ps, componentPointer, containerPointer) {
        const containerType = component.getType(ps, containerPointer)
        if (containerType === 'wysiwyg.viewer.components.MediaPlayer') {
            const playerId = containerPointer.id
            const compType = component.getType(ps, componentPointer)
            const propSchema = componentData.getComponentPropertiesByType(ps, compType)
            if (_.get(propSchema, ['properties', 'playerId'])) {
                dataModel.updatePropertiesItem(ps, componentPointer, {playerId})
            }
        }
    }

    const flipBoxShadow = {
        horizontal: boxShadow => {
            boxShadow.offsetX.value *= -1
        },
        vertical: boxShadow => {
            boxShadow.offsetY.value *= -1
        },
        both: boxShadow => {
            boxShadow.offsetY.value *= -1
            boxShadow.offsetX.value *= -1
        }
    }

    const flipBorderRadius = {
        horizontal: cssBorderRadius => {
            const {topLeft, topRight, bottomLeft, bottomRight} = cssBorderRadius
            cssBorderRadius.topLeft = topRight
            cssBorderRadius.bottomLeft = bottomRight
            cssBorderRadius.topRight = topLeft
            cssBorderRadius.bottomRight = bottomLeft
        },
        vertical: cssBorderRadius => {
            const {topLeft, topRight, bottomLeft, bottomRight} = cssBorderRadius
            cssBorderRadius.topLeft = bottomLeft
            cssBorderRadius.bottomLeft = topLeft
            cssBorderRadius.topRight = bottomRight
            cssBorderRadius.bottomRight = topRight
        },
        both: cssBorderRadius => {
            const {topLeft, topRight, bottomLeft, bottomRight} = cssBorderRadius
            cssBorderRadius.topLeft = bottomRight
            cssBorderRadius.bottomLeft = topRight
            cssBorderRadius.topRight = bottomLeft
            cssBorderRadius.bottomRight = topLeft
        }
    }

    function layoutChildrenOnMobileConversion(ps, pagesPointers) {
        _.forEach(pagesPointers, pagePointer => {
            const childrenPointers = component.getChildren(ps, pagePointer, true)
            const playersPointers = _.filter(childrenPointers, childPointer => component.getType(ps, childPointer) === 'wysiwyg.viewer.components.MediaPlayer')
            _.forEach(playersPointers, playerPointer => layoutPlayerChildren(ps, playerPointer))
        })
    }

    function getPlayerProperties(ps, playerPointer) {
        const playerPropertyQuery = ps.dal.get(ps.pointers.getInnerPointer(playerPointer, 'propertyQuery'))
        const pagePointer = ps.pointers.components.getPageOfComponent(playerPointer)
        const propertiesPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(playerPropertyQuery), pagePointer.id)
        return ps.dal.get(propertiesPointer)
    }

    /**
     * gets the diff between the new and prev flip value
     * @param {string} prevFlip
     * @param {string} newFlip
     * @returns {string}
     */
    function getDiffFlip(prevFlip, newFlip) {
        const swapValues = {vertical: 'horizontal', horizontal: 'vertical'}
        // no prev return new
        if (!prevFlip || prevFlip === 'none') {
            return newFlip
        }

        // new flip is empty
        if (newFlip === 'none') {
            return prevFlip
        }
        // prev had 2 values - should support toggle and/or direct override of both options
        if (prevFlip === 'both') {
            return swapValues[newFlip]
        }

        // new flip has 2 values - - can be toggle or direct override of both options
        if (newFlip === 'both') {
            return swapValues[prevFlip] || newFlip
        }
        return newFlip
    }

    function flipCssStyle(ps, playerPointer, updatedProperties) {
        const designData = design.getDesignItem(ps, playerPointer)
        const {cssStyle} = designData
        const currentProperties = getPlayerProperties(ps, playerPointer)

        if (cssStyle && updatedProperties.flip !== currentProperties.flip) {
            const diffFlip = getDiffFlip(currentProperties.flip, updatedProperties.flip)
            if (cssStyle.cssBoxShadow) {
                const {cssBoxShadow} = cssStyle
                flipBoxShadow[diffFlip](cssBoxShadow[0])
            }
            if (cssStyle.cssBorderRadius) {
                const {cssBorderRadius} = cssStyle
                flipBorderRadius[diffFlip](cssBorderRadius)
            }
            design.updateDesignItem(ps, playerPointer, {
                background: designData.background,
                cssStyle
            })
        }
    }

    return {
        setPlayerIdAfterAddHook,
        layoutPlayerChildren,
        flipCssStyle,
        layoutChildrenOnMobileConversion
    }
})
