define([
    'lodash',
    '@wix/document-services-json-schemas',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/dataSerialization',
    'documentServices/variants/variantsUtils',
    'documentServices/namespaces/namespaces'
], (_, jsonSchemas, dataModel, dataSerialization, variantsUtils, namespaces) => {
    const {
        namespaceMapping: {featuresData, featureNamespaces}
    } = jsonSchemas

    function getNamespaces() {
        return featureNamespaces
    }

    function getNamespaceQuery(namespace) {
        return featuresData[namespace].query
    }

    /**
     * @typedef FeatureData
     * @property {String} type
     */

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {String} featureName the data namespace
     * @param {FeatureData} featureData the data itself, the default type if unspecified will be the namespace
     */
    function updateFeatureData(ps, compPointer, featureName, featureData) {
        const queryName = getNamespaceQuery(featureName)
        if (!queryName) {
            throw new Error(`feature ${featureName} doesn't exists`)
        }

        return namespaces.updateNamespaceData(ps, compPointer, featureName, featureData)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {String} featureName
     * @returns
     */
    function getFeatureData(ps, compPointer, featureName) {
        if (!getNamespaces().includes(featureName)) {
            throw new Error(`feature ${featureName} doesn't exists`)
        }
        return namespaces.getNamespaceData(ps, compPointer, featureName)
    }

    return {
        getNamespaces,
        getNamespaceQuery,
        getFeatureData,
        updateFeatureData,
        removeComponentFeatureData: namespaces.removeComponentNamespaceData
    }
})
