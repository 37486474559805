define(['lodash'], function (_) {
    'use strict'

    function replaceWixCodeAppId(pagesPlatformApplications, currentSnapshotDal) {
        const clientSpecMap = currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        const wixCodeAppId = _.chain(clientSpecMap).find({type: 'siteextension'}).get('appDefinitionId').value()
        pagesPlatformApplications[wixCodeAppId] = _.assign({}, pagesPlatformApplications.wixCode)
        delete pagesPlatformApplications.wixCode
    }

    /**
     * @exports utils/saveDataFixer/plugins/pagesPlatformApplicationsDataFixer
     * @type {{exec: function}}
     */
    const exports = {
        exec(dataToSave, {currentSnapshotDal}) {
            const {pagesPlatformApplications} = dataToSave
            if (pagesPlatformApplications) {
                replaceWixCodeAppId(pagesPlatformApplications, currentSnapshotDal)
                dataToSave.pagesPlatformApplications = _.transform(
                    pagesPlatformApplications,
                    function (result, pageIds, appId) {
                        _.forOwn(pageIds, function (value, pageId) {
                            result[pageId] = result[pageId] || []
                            result[pageId].push(appId)
                        })
                    },
                    {}
                )
            }
        }
    }

    return exports
})
