import _ from 'lodash'
import * as mobx from 'mobx'

function transformClientSpecMap(clientSpecMap) {
    return mobx.observable.map(_.assign({}, clientSpecMap))
}

function initClientSpecMapIfNeeded(siteData, rendererModel) {
    if (!rendererModel.clientSpecMap && !mobx.isObservableMap(_.get(siteData, ['rendererModel', 'clientSpecMap']))) {
        return _.defaults({clientSpecMap: {}}, rendererModel)
    }

    return rendererModel
}

function transformRendererModel(siteData, rendererModel) {
    rendererModel = initClientSpecMapIfNeeded(siteData, rendererModel)

    if (rendererModel.clientSpecMap) {
        return _.defaults(
            {
                clientSpecMap: transformClientSpecMap(rendererModel.clientSpecMap)
            },
            rendererModel
        )
    }

    return rendererModel
}

export default function transform(siteData, path, value) {
    if (path.length === 1) {
        return transformRendererModel(siteData, value)
    } else if (path.length === 2 && path[1] === 'clientSpecMap') {
        return transformClientSpecMap(value)
    }

    return value
}
