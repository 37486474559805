define(['lodash', 'documentServices/mobileConversion/modules/componentSpecificConversionData/valueScaleHelper'], function (_, valueScaleHelper) {
    'use strict'

    const SMALL_SIZE_BREAK_POINT = 60
    const MEDIUM_SIZE_BREAK_POINT = 400
    const MAX_SIZE_BREAK_POINT = 980

    const smallImage = {
        upToSize: SMALL_SIZE_BREAK_POINT,
        convert: valueScaleHelper.roundToNearestDecade
    }

    const mediumImage = {
        upToSize: MEDIUM_SIZE_BREAK_POINT,
        convert: valueScaleHelper.scaleByFactor,
        paramsForConvert: [valueScaleHelper.buildScaleFactorDataStructure(0.7, SMALL_SIZE_BREAK_POINT, valueScaleHelper.roundToNearestDecade)]
    }

    const largeImage = {
        upToSize: MAX_SIZE_BREAK_POINT,
        convert: _.constant(280)
    }

    const getImageSize = (desktopWidth, desktopHeight) => {
        const sizesRange = [
            valueScaleHelper.buildRangeDataStructure(smallImage.upToSize, smallImage.convert),
            valueScaleHelper.buildRangeDataStructure(mediumImage.upToSize, mediumImage.convert, mediumImage.paramsForConvert),
            valueScaleHelper.buildRangeDataStructure(largeImage.upToSize, largeImage.convert)
        ]

        const width = valueScaleHelper.scaleByRange(desktopWidth, sizesRange)
        const height = valueScaleHelper.scaleProportionally(desktopHeight, width, desktopWidth)

        return {width, height}
    }

    function getVectorImageConversionData(component) {
        const desktopWidth = _.get(component, ['layout', 'width'], 0)
        const desktopHeight = _.get(component, ['layout', 'height'], 0)
        const fixedSize = getImageSize(desktopWidth, desktopHeight)
        return {
            fixedSize
        }
    }

    return {
        getVectorImageConversionData
    }
})
