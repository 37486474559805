define(['lodash', 'documentServices/siteMetadata/dataManipulation'], function (_, dataManipulation) {
    'use strict'

    function getAppsData(privateServices) {
        return dataManipulation.getProperty(privateServices, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP) || {}
    }

    /**
     * @param {ps} ps
     * @param newAppData
     */
    function registerAppData(ps, newAppData) {
        const appsData = getAppsData(ps)
        if (newAppData.applicationId) {
            appsData[newAppData.applicationId] = newAppData
            dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP, appsData)
        }
    }

    /**
     * @param {ps} ps
     * @param {string} applicationId
     * @param instance
     */
    function updateAppInstance(ps, applicationId, instance) {
        const appData = getAppData(ps, applicationId)
        if (appData) {
            appData.instance = instance
            registerAppData(ps, appData)
        }
    }

    /**
     * @param {ps} ps
     * @param predicate
     * @returns {Array}
     */
    function getAppsDataWithPredicate(ps, predicate) {
        return _.map(ps.pointers.general.getClientSpecMapEntriesByPredicate(predicate), ps.dal.get)
    }

    /**
     * @param {ps} ps
     * @param {string} applicationId
     * @returns {*}
     */
    function getAppData(ps, applicationId) {
        return ps.dal.get(ps.pointers.general.getClientSpecMapEntry(applicationId)) || {}
    }

    /**
     * @param {ps} ps
     * @param {string} appDefinitionId
     * @returns {*}
     */
    function getAppDataByAppDefinitionId(ps, appDefinitionId) {
        return ps.dal.get(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
    }

    function filterAppsDataByType(ps, type) {
        return getAppsDataWithPredicate(ps, csm => _.filter(csm, {type}))
    }

    return {
        registerAppData,
        updateAppInstance,
        getAppData,
        getAppDataByAppDefinitionId,
        getAppsData,
        filterAppsDataByType,
        getAppsDataWithPredicate
    }
})
