define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/hooks/hooks',
    'documentServices/saveAPI/saveAPI',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/page/pageData',
    'documentServices/utils/contextAdapter',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, santaCoreUtils, hooks, saveAPI, generalInfo, pageData, contextAdapter, extensionsAPI) {
    'use strict'

    let performAutosaveDebounced
    let autosaveTimeoutId

    const defaultConfig = {
        AUTOSAVE_ACTION_COUNT: 2,
        SAVE_AFTER_AUTOSAVE_COUNT: 3,
        DEBOUNCE_WAIT: 2,
        AUTOSAVE_TIMEOUT: 10
    }
    let config = {}
    const innerPaths = {
        SHOULD_AUTOSAVE: 'shouldAutoSave',
        ACTIONS_COUNT: 'actionsCount',
        AUTOSAVES_COUNT: 'autosaveCount',
        AUTO_FULL_SAVE_FLAG: 'autoFullSaveFlag',
        REPORTING_INFO: 'reportingInfo'
    }
    const counterOps = {
        INCREASE: 'increase',
        DECREASE: 'decrease',
        RESET: 'reset'
    }
    const INIT_AUTOSAVE_INTERACTION = 'autosave-init'
    const {HOOKS} = hooks
    let hooksMap = {}

    function autosaveDebouncedWithDefaultCallbacks(ps, trigger) {
        performAutosaveDebounced(ps, _.noop, _.noop, trigger)
    }

    function handleUserAction(ps) {
        if (!canSave(ps)) {
            return
        }
        performAutosaveDebounced = performAutosaveDebounced || _.debounce(performAutosave, config.DEBOUNCE_WAIT * 1000)
        setAutosaveTimeout(ps)
        const newCount = increaseCounter(ps, innerPaths.ACTIONS_COUNT)
        if (newCount >= config.AUTOSAVE_ACTION_COUNT) {
            autosaveDebouncedWithDefaultCallbacks(ps, 'num of actions')
        }
    }

    function performAutosave(ps, onSuccess, onError, trigger) {
        const autosaveCount = increaseCounter(ps, innerPaths.AUTOSAVES_COUNT)
        clear(ps)

        if (autosaveCount < config.SAVE_AFTER_AUTOSAVE_COUNT) {
            const reportingInfoPointer = getAutoSaveInnerPointer(ps, innerPaths.REPORTING_INFO)

            ps.dal.set(reportingInfoPointer, {
                actionsPerAutosave: config.AUTOSAVE_ACTION_COUNT,
                autosavesPerFull: config.SAVE_AFTER_AUTOSAVE_COUNT,
                pagesCount: pageData.getNumberOfPages(ps),
                trigger
            })

            _.invoke(config, 'onDiffSaveStarted', trigger)

            extensionsAPI.csave.save(ps).then(onSuccess, e => {
                onError(e, trigger)
            })
        } else {
            const onPartialSaveSuccess = getPartialSaveCompletedCallback(ps, trigger, onSuccess)
            const onPartialSaveFail = getPartialSaveCompletedCallback(ps, trigger, onError)
            const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
            ps.dal.set(autoFullSaveFlagPointer, true)
            _.invoke(config, 'onPartialSaveStarted', trigger)
            const isFullSave = extensionsAPI.csave.isValidationRecovery(ps)
            saveAPI.save(ps, onPartialSaveSuccess, onPartialSaveFail, isFullSave, {origin: 'autosave'})
        }
    }

    /**
     * @param {ps} ps
     * @param {string} trigger
     * @returns {Promise<void>}
     */
    async function partialSave(ps, trigger) {
        const onFinish = e => {
            ps.dal.remove(autoFullSaveFlagPointer)
            _.invoke(config, 'onPartialSaveFinished', e, trigger)
            resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
        }
        const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
        ps.dal.set(autoFullSaveFlagPointer, true)
        _.invoke(config, 'onPartialSaveStarted', trigger)
        try {
            await saveAPI.promises.save(ps, false, {trigger})
            onFinish()
        } catch (e) {
            onFinish(e)
        }
    }

    function getPartialSaveCompletedCallback(ps, trigger, callback) {
        return function (error) {
            const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
            ps.dal.remove(autoFullSaveFlagPointer)
            _.invoke(config, 'onPartialSaveFinished', error, trigger)
            resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
            if (_.isFunction(callback)) {
                callback(error, trigger)
            }
        }
    }

    function handleValidationError(ps) {
        disableAutosave(ps)
    }

    function disableAutosave(ps) {
        init(ps, {enabled: false})
    }

    function setAutosaveTimeout(ps) {
        clearAutosaveTimeout()
        autosaveTimeoutId = setTimeout(() => {
            autosaveDebouncedWithDefaultCallbacks(ps, 'idle')
        }, config.AUTOSAVE_TIMEOUT * 1000)
    }

    function clearAutosaveTimeout() {
        if (autosaveTimeoutId) {
            clearTimeout(autosaveTimeoutId)
            autosaveTimeoutId = null
        }
        if (performAutosaveDebounced) {
            performAutosaveDebounced.cancel()
        }
    }

    function updateCounter(ps, key, operation) {
        const counterPointer = getAutoSaveInnerPointer(ps, key)
        const currentCount = ps.dal.get(counterPointer) || 0
        let newCount

        switch (operation) {
            case counterOps.INCREASE:
                newCount = currentCount + 1
                break
            case counterOps.DECREASE:
                newCount = currentCount - 1
                break
            case counterOps.RESET:
                newCount = 0
                break
        }

        ps.dal.set(counterPointer, newCount)

        return newCount
    }

    function increaseCounter(ps, key) {
        return updateCounter(ps, key, counterOps.INCREASE)
    }

    function resetCounter(ps, key) {
        return updateCounter(ps, key, counterOps.RESET)
    }

    function clear(ps) {
        clearAutosaveTimeout()
        resetCounter(ps, innerPaths.ACTIONS_COUNT)
    }

    function clearAll(ps) {
        clear(ps)
        resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
    }

    function getAutoSaveInnerPointer(ps, key) {
        return ps.pointers.general.getAutoSaveInnerPointer(key)
    }

    function isNeverSaved(ps) {
        const neverSavedPointer = ps.pointers.general.getNeverSaved()
        return ps.dal.get(neverSavedPointer)
    }

    function isSiteFromOnBoarding(ps) {
        return generalInfo.isSiteFromOnBoarding(ps)
    }

    const registerHook = (name, hook) => {
        hooksMap[name] = hooks.registerHook(name, hook)
    }

    function registerHooks(ps) {
        registerHook(HOOKS.AUTOSAVE.ACTION, _.partial(handleUserAction, ps))
        registerHook(HOOKS.SAVE.SITE_SAVED, _.partial(clearAll, ps))
        registerHook(HOOKS.SAVE.VALIDATION_ERROR, _.partial(handleValidationError, ps))
    }

    function unregisterHooks() {
        _.forEach(hooksMap, (hookValue, hookKey) => hooks.unregisterHook(hookKey, hookValue))
        hooksMap = {}
    }

    function isAutoSaveAllowed(ps) {
        const autoSaveInfo = getAutoSaveInfo(ps)
        return autoSaveInfo?.shouldAutoSave && !ps.config.disableAutoSave
    }

    function canSave(ps) {
        return (
            config.enabled !== false &&
            !!(isAutoSaveAllowed(ps) && !isNeverSaved(ps) && (config.allowOnBoarding || !isSiteFromOnBoarding(ps)) && saveAPI.saveState.canSave(ps))
        )
    }

    function getAutoSaveInfo(ps) {
        const autosaveInfoPointer = ps.pointers.general.getAutosaveInfo()
        return ps.dal.get(autosaveInfoPointer)
    }

    /**
     * @param {import("@wix/document-services-types").PS} ps
     * @param {import("@wix/document-services-types").AutosaveConfig} _config
     * @returns {boolean}
     */
    function init(ps, _config) {
        if (!_config) {
            santaCoreUtils.log.error(new Error('Missing autosave config object'))
            return false
        }

        const wrappedHooks = extensionsAPI.csave.getWrappedHooks(ps, _config)
        config = _.defaults(wrappedHooks, _config, defaultConfig)
        contextAdapter.utils.fedopsLogger.interactionStarted(INIT_AUTOSAVE_INTERACTION, {tags: {enable: config.enabled}})

        unregisterHooks()

        extensionsAPI.csave.setEnabled(ps, config.enabled)
        extensionsAPI.csave.initHooks(ps, config)
        registerHook(HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR, () => {
            partialSave(ps, HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR)
        })

        clearAutosaveTimeout()

        if (_config.enabled !== true || !saveAPI.saveState.isEnabled(ps)) {
            return false
        }

        registerHooks(ps)
        contextAdapter.utils.fedopsLogger.interactionEnded(INIT_AUTOSAVE_INTERACTION, {tags: {enable: config.enabled}})
        return true
    }

    /**
     * @param {ps} ps
     * @param disableAutoSave
     */
    const initMethod = (ps, {disableAutoSave = false} = {disableAutoSave: false}) => {
        if (disableAutoSave) {
            const shouldAutoSavePtr = getAutoSaveInnerPointer(ps, innerPaths.SHOULD_AUTOSAVE)
            ps.dal.set(shouldAutoSavePtr, false)
        }
    }

    return {
        initMethod,
        init,
        autosave(ps, onSuccess, onError, triggerName) {
            if (!canSave(ps)) {
                return onError({document: 'CSave not available'})
            }
            performAutosave(ps, onSuccess, onError, triggerName)
        },
        canAutosave: canSave,
        getAutoSaveInfo,
        clearAll,
        disableAutosave,
        handleValidationError,
        isAllowed: isAutoSaveAllowed
    }
})
