import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import flatStructureUtil from './flatStructureUtil'

const {constants} = warmupUtilsLib
function getInner(siteData, getDomNodeFunc) {
    const currentRenderedPageId = siteData.getCurrentUrlPageId()

    return {
        structure: flatStructureUtil.getDeepStructureForComp(siteData, currentRenderedPageId),
        pageId: currentRenderedPageId,
        getDomNodeFunc
    }
}

function getOuter(siteData, getDomNodeFunc) {
    const masterPageStructure = flatStructureUtil.getDeepStructureForComp(siteData, 'masterPage')
    let masterPageAnchors
    const viewMode = siteData.getViewMode()
    const currentRenderedPageId = siteData.getCurrentUrlPageId()

    const structuresDesc = {
        structure: masterPageStructure,
        getDomNodeFunc
    }

    if (siteData.isPageLandingPage(currentRenderedPageId)) {
        structuresDesc.structure = adjustMasterPageStructureForLandingPage(masterPageStructure)
        masterPageAnchors = _.get(siteData, ['anchorsMap', 'landingPageMasterPage', viewMode])
    } else {
        masterPageAnchors = _.get(siteData, ['anchorsMap', 'defaultMasterPage', viewMode])
    }

    return _.defaults({}, structuresDesc, {anchorsMap: {[masterPageStructure.id]: {[viewMode]: masterPageAnchors}}})
}

function getSiteBackground(getDomNodeFunc) {
    return {
        structure: getSiteBackgroundStructure(),
        getDomNodeFunc
    }
}

function getWixAds(siteData) {
    return {
        structure: {
            id: siteData.WIX_ADS_ID,
            componentType: 'wysiwyg.viewer.components.WixAds',
            styleId: 'wixAds'
        },
        getDomNodeFunc(rootId, childId) {
            const id = childId ? childId : rootId
            return window.document.getElementById(id)
        }
    }
}

function getSiteBackgroundStructure() {
    return {
        id: 'SITE_BACKGROUND',
        skin: 'wysiwyg.viewer.skins.siteBackgroundSkin',
        componentType: 'wysiwyg.viewer.components.SiteBackground',
        styleId: 'siteBackground'
    }
}

function filterComponentsForLandingPage(components) {
    return _.filter(components, function (comp) {
        return comp.id !== 'PAGES_CONTAINER' && constants.LANDING_PAGES_COMP_IDS[comp.id]
    })
}

function adjustMasterPageStructureForLandingPage(masterPageStructure) {
    masterPageStructure = _.clone(masterPageStructure)
    _.forEach(['children', 'mobileComponents'], property => {
        const components = masterPageStructure[property]
        if (components) {
            const pagesContainer = _.chain(components).find({id: 'PAGES_CONTAINER'}).cloneDeep().value()
            pagesContainer.layout.y = 0
            alterPagesContainerAnchorsForLandingPage(pagesContainer)

            const componentsForLandingPage = filterComponentsForLandingPage(components)
            masterPageStructure[property] = componentsForLandingPage.concat(pagesContainer)
        }
    })
    return masterPageStructure
}

function alterPagesContainerAnchorsForLandingPage(pagesContainer) {
    if (pagesContainer.layout.anchors) {
        // This is relevant for sites that were not saved in the editor since we moved the anchors from json to dynamic (removeJsonAnchors experiment)
        const siteAnchor = {
            distance: 0,
            locked: true,
            originalValue: pagesContainer.layout.height,
            targetComponent: 'masterPage',
            topToTop: pagesContainer.layout.height,
            type: 'BOTTOM_PARENT'
        }

        while (pagesContainer.layout.anchors.length) {
            pagesContainer.layout.anchors.pop()
        }
        pagesContainer.layout.anchors.push(siteAnchor)
    }
}

const getRootDescriptor = (siteData, rootId, getDomNodeFunc) => ({
    structure: flatStructureUtil.getDeepStructureForComp(siteData, rootId),
    pageId: rootId,
    getDomNodeFunc
})

export default {
    getRootDescriptor,
    getInner,
    getOuter,
    getSiteBackground,
    getWixAds,
    getSiteBackgroundStructure
}
