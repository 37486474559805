define([
    'lodash',
    'documentServices/wixapps/services/savePublishUtils',
    'documentServices/wixapps/services/clientSpecMap',
    'documentServices/wixapps/utils/appBuilder'
], function (_, savePublishUtils, clientSpecMap, appBuilderUtils) {
    'use strict'

    const TASK_NAME = 'appbuilder'

    return ps => {
        const getInitialInstanceId = () => {
            const initialSnapshot = ps.dal.full.snapshot.getInitialSnapshot()
            return appBuilderUtils.getAppInstanceIdFromSnapshotDal(initialSnapshot)
        }

        const getInstanceId = () => clientSpecMap.getInstanceId(ps)
        const getAppInstance = snapshot => ({
            applicationInstanceId: getInstanceId(),
            applicationInstanceVersion: snapshot.getIn(['wixapps', 'appbuilder', 'descriptor', 'applicationInstanceVersion'])
        })
        const saveAll = (lastSavedData, currentData, resolve, reject, biCallbacks) =>
            savePublishUtils.saveAll(() => getAppInstance(currentData), lastSavedData, currentData, resolve, reject, biCallbacks)

        const taskLifecycleInterface = {
            getLastState() {
                return ps.wixappsDAL.getSnapshots().last
            },
            getCurrentState() {
                return ps.wixappsDAL.getSnapshots().current
            },
            onTaskSuccess(result) {
                ps.wixappsDAL.commitSnapshot()
                const changes = _.get(result, 'changes', [])
                _.forEach(changes, ({path, value}) => {
                    if (_.isUndefined(value)) {
                        ps.wixappsDAL.removeByPath(path)
                        return
                    }
                    ps.wixappsDAL.setByPath(path, value)
                })
            },
            takeSnapshot() {
                ps.wixappsDAL.takeSnapshot()
            },
            rollback() {
                ps.wixappsDAL.rollbackSnapshot()
            }
        }

        return {
            partialSave: saveAll,
            fullSave: saveAll,
            autosave: saveAll,
            publish(currentData, resolve, reject, biCallbacks) {
                savePublishUtils.publish(() => getAppInstance(currentData), currentData, resolve, reject, biCallbacks)
            },
            firstSave(lastSavedData, currentData, resolve, reject, biCallbacks) {
                const lastInstanceId = getInitialInstanceId()

                const currentInstanceId = getInstanceId()
                if (lastInstanceId && lastInstanceId === currentInstanceId) {
                    reject({
                        errorCode: 1970,
                        errorDescription: 'application instance id is the template id',
                        changes: null
                    })
                } else {
                    saveAll(lastSavedData, currentData, resolve, reject, biCallbacks)
                }
            },
            saveAsTemplate(lastSavedData, currentData, resolve) {
                resolve()
            },
            getTaskName() {
                return TASK_NAME
            },
            getSnapshotTags() {
                return ['primary']
            },
            ...taskLifecycleInterface
        }
    }
})
