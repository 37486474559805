define([], function () {
    'use strict'

    const onComponentsScroll = (ps, callback) => ps.siteAPI.setOnComponentsScrollListener(callback)

    const scroll = (ps, componentPointer, x, y, callback) => ps.siteAPI.scroll(componentPointer.id, x, y, callback)

    const scrollBy = (ps, componentPointer, x, y, callback) => ps.siteAPI.scrollBy(componentPointer.id, x, y, callback)

    const getTotalScrolls = (ps, componentPointer) => ps.siteAPI.getTotalScroll(componentPointer.id)

    return {
        onComponentsScroll,
        scroll,
        scrollBy,
        getTotalScrolls
    }
})
