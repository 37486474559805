define(['lodash', 'documentServices/platform/common/constants'], function (_, constants) {
    'use strict'

    // Registrations
    const registrationsPrivateScopes = {}

    function getRegistrations(ps) {
        return registrationsPrivateScopes[ps.siteAPI.getSiteId()] || []
    }

    function addRegistration(ps, appId, callback) {
        const siteId = ps.siteAPI.getSiteId()
        const registration = {
            appId,
            callback
        }

        registrationsPrivateScopes[siteId] = _.compact([registration].concat(registrationsPrivateScopes[siteId]))
        return registration
    }

    /**
     * Register the given callback to viewerInfoChanged events
     * @param {ps} ps - privateServices
     * @param {string} appId - id of the registering app
     * @param {function} callback - will be called with viewerInfoChanged event object
     * @returns {function} unregister -
     *      invoking will unregister the given callback from viewerinfoChanged events
     *      UNREGISTER TO AVOID MEMORY LEAKS!
     */
    function register(ps, appId, callback) {
        const registration = addRegistration(ps, appId, callback)
        return function unregister() {
            _.pull(getRegistrations(ps), registration)
        }
    }

    function emit(ps, payload) {
        getRegistrations(ps).forEach(function (registration) {
            registration.callback({
                eventType: constants.MessageTypes.VIEWER_INFO_CHANGED,
                eventPayload: payload
            })
        })
    }

    return {
        /**
         * Register the given callback to viewerInfoChanged events
         * @param {ps} ps - privateServices
         * @param {string} appId - id of the registering app
         * @param {function} callback - will be called with viewerInfoChanged event object
         * @returns {function} unregister -
         *      invoking will unregister the given callback from viewerinfoChanged events
         *      UNREGISTER TO AVOID MEMORY LEAKS!
         */
        register,
        /**
         * Emit viewerInfoChanged event to all registered callbacks with given payload
         * @param {ps} ps - privateServices
         * @param {object} payload
         */
        emit
    }
})
