define([
    'experiment',
    'documentServicesSchemas/services/componentStructureInfoBuilder',
    'documentServicesSchemas/services/dataModelBuilder',
    'documentServicesSchemas/services/dataValidatorsBuilder',
    'documentServicesSchemas/services/skinsRegistrar',
    'documentServicesSchemas/services/themeValidationHelper',
    'documentServicesSchemas/util/createSchemasService',
    'documentServicesSchemas/util/dualSchemaService',
    '@wix/document-services-json-schemas'
], function (
    experiment,
    componentStructureInfoBuilder,
    dataModelBuilder,
    dataValidatorsBuilder,
    skinsRegistrar,
    themeValidationHelper,
    createSchemasService,
    dualSchemaService,
    {componentTypeAliases, skinsByComponentType, newSchemaService}
) {
    /** Create the underlying schema service with new schema approach
     *
     * @param {*} clientOnlySchemas
     * @param {*} newSchemaServiceInstance
     * @returns {*} A schema service
     */
    const createMainSchemaService = (clientOnlySchemas, newSchemaServiceInstance = newSchemaService.staticInstance) =>
        dualSchemaService.createDualService(clientOnlySchemas, newSchemaServiceInstance, true)

    return (clientOnlySchemas, newSchemaServiceInstance) => {
        const schemasService = createMainSchemaService(clientOnlySchemas, newSchemaServiceInstance)
        const componentStructureInfo = componentStructureInfoBuilder(schemasService)
        const dataValidators = dataValidatorsBuilder(schemasService)
        const dataModel = dataModelBuilder(schemasService)

        const extraSkins = experiment.isOpen('brandButtonSkin') ? {'wysiwyg.viewer.components.SiteButton': ['wysiwyg.viewer.skins.button.BrandButton']} : {}

        const skinsByComponentTypeInstance = skinsByComponentType.createSkinsByComponentType(clientOnlySchemas.componentsDefinitionsMap, extraSkins)
        const skinsRegistrarInstance = skinsRegistrar.createRegistrar(skinsByComponentTypeInstance, componentTypeAliases)

        return {
            schemas: clientOnlySchemas,
            util: {
                createSchemasService
            },
            services: {
                buildDefaultComponentStructure: componentStructureInfo.buildDefaultComponentStructure,
                createDataItemByType: dataModel.createDataItemByType,
                createStyleItemByType: dataModel.createStyleItemByType,
                createPropertiesItemByType: dataModel.createPropertiesItemByType,
                dataValidators,
                schemasService,
                getComponentSkins: skinsRegistrarInstance.getComponentSkins,
                getComponentResponsiveSkins: skinsRegistrarInstance.getComponentResponsiveSkins,
                registerSkinsByCompType: skinsRegistrarInstance.registerSkinsByCompType,
                isContainer: schemasService.isContainer,
                themeValidationHelper
            }
        }
    }
})
