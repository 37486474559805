import type {CreateExtArgs, DocumentDataTypes, Extension, ExtensionAPI, PointerMethods} from '@wix/document-manager-core'

const dataType = 'serviceTopology'
const createPointersMethods = (): PointerMethods => {
    const getServiceTopology = () => ({id: dataType, type: dataType})
    return {
        serviceTopology: {
            getServiceTopology
        },
        general: {
            getServiceTopology
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[dataType]: {}})

export interface STExtApi extends ExtensionAPI {
    serviceTopology: {
        getServiceTopology(): any
    }
}

const createExtensionAPI = ({dal, pointers}: CreateExtArgs): STExtApi => ({
    serviceTopology: {
        getServiceTopology: (): any => dal.get(pointers.serviceTopology.getServiceTopology())
    }
})

const createExtension = (): Extension => ({
    name: 'serviceTopology',
    createPointersMethods,
    getDocumentDataTypes,
    createExtensionAPI
})

export type ServiceTopology = Record<string, string>

export {createExtension}
