import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import cartConverter from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/data/converters/cartConverter'
import shippingConverter from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/data/converters/shippingConverter'
import ecomRequestBuilder from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomRequestBuilder'
import ecomDataUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomDataUtils'
import responseTransformation from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/responseTransformation'
import ecomRequestSender from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomRequestSender'

const CART_KEY = 'eCommerce_'
const CART_ACTION_ID = 'dynamicstore_order_cart.getCart'
const CART_LOCATION_ID = 'cart'
const JOINED_CART_LOCATION_ID = 'joinedCart'
const fetchingCart = []
const {wixappsDataHandler} = wixapps
let countryListCache = null

function validateNotInSSR(siteData) {
    if (siteData.isInSSR()) {
        throw new Error('Somethign is wrong with the flow if we reached this method in the server')
    }
}

function getCartId(siteData) {
    validateNotInSSR(siteData)
    return siteData.requestModel.storage.local.getItem(CART_KEY + siteData.siteId)
}

function isReturnFromSuccessCheckout(siteData) {
    const queryParams = siteData.currentUrl.query
    const result = _.isArray(queryParams.f_checkoutResult) ? queryParams.f_checkoutResult[0] : queryParams.f_checkoutResult
    const successfulCheckout = result && result === 'success'

    if (successfulCheckout) {
        delete queryParams.f_checkoutResult
        const {urlUtils} = coreUtils
        urlUtils.updateUrl(urlUtils.buildFullUrl(siteData.currentUrl, true))
        return true
    }

    return false
}

function hasCart(siteData) {
    if (isReturnFromSuccessCheckout(siteData)) {
        clearCart(siteData)
    }

    return !!getCartId(siteData)
}

function setCartId(siteData, cartId) {
    validateNotInSSR(siteData)
    siteData.requestModel.storage.local.setItem(CART_KEY + siteData.siteId, cartId)
}

function clearCart(siteData) {
    validateNotInSSR(siteData)
    siteData.requestModel.storage.local.removeItem(CART_KEY + siteData.siteId)
}

function cartNeeded(siteData) {
    return !wixappsDataHandler.getDataByPath(siteData, 'ecommerce', ['cart'])
}

function _wrapJoinedCart(cart) {
    return {
        _type: 'JoinedCart',
        cart,
        checkout: {_type: 'CheckoutButton'}
    }
}

function updateCart(cart, wixappsDataAspect) {
    wixappsDataAspect.setBatchedData(ecomDataUtils.packageName, [
        {path: [CART_LOCATION_ID], value: cart},
        {path: [JOINED_CART_LOCATION_ID], value: _wrapJoinedCart(cart)}
    ])
}

function buildCartRequest(siteData, compData) {
    const requestMetadata = {
        action: CART_ACTION_ID,
        transformFunc: getCartResponseFunc({siteData, dontMergeWithOldData: true}),
        customTransform: true,
        onError() {
            _.forEach(fetchingCart, function (componentData) {
                wixappsDataHandler.setCompMetadata({hasError: 2024}, siteData, ecomDataUtils.packageName, componentData.id)
            })
        }
    }
    if (!cartNeeded(siteData)) {
        return []
    }

    const requests = []

    // Send request only for the first component that needs the cart
    if (_.isEmpty(fetchingCart)) {
        const additionalParams = {
            cartId: getCartId(siteData)
        }

        const request = ecomRequestBuilder.buildRequest(siteData, compData, ecomDataUtils.packageName, additionalParams, requestMetadata)

        requests.push(request)
    }

    fetchingCart.push(compData)
    return requests
}

function getCart(siteData, compData, onFailCallback) {
    if (!hasCart(siteData)) {
        //cart is empty so we push an empty cart to siteData directly.
        setCartData(siteData, null, null, ecomDataUtils.getApplicationDataStore(siteData), onFailCallback)

        ecomDataUtils.handleFailedRequestInClientAfterSSR(siteData, wixappsDataHandler, ecomDataUtils.packageName, compData)
        return []
    }
    return buildCartRequest(siteData, compData)
}

function buildCartPaths(path) {
    const cartPath = _.clone(path)
    cartPath.unshift(CART_LOCATION_ID)
    const joinedCartPath = _.clone(path)
    joinedCartPath.unshift(CART_LOCATION_ID)
    joinedCartPath.unshift(JOINED_CART_LOCATION_ID)
    return [cartPath, joinedCartPath]
}

function setCartItemDataAndUpdate(path, value, wixappsDataAspect) {
    _.forEach(buildCartPaths(path), function (cartPath) {
        wixappsDataAspect.setDataByPath(ecomDataUtils.packageName, cartPath, value)
    })
}

function setCartItemData(path, value, siteData) {
    _.forEach(buildCartPaths(path), function (cartPath) {
        wixapps.wixappsDataHandler.setDataByPath(siteData, ecomDataUtils.packageName, cartPath, value)
    })
}

function setCartData(siteData, wixappsDataAspect, responseData, currentValue, onFailCallback, couponCode?) {
    const strippedResponseData = responseTransformation.stripJsonRpc(responseData)

    if (strippedResponseData.result && strippedResponseData.result.cart.clearCart === 'true') {
        clearCart(siteData)
        setCartData(siteData, null, null, ecomDataUtils.getApplicationDataStore(siteData), onFailCallback, couponCode)
        return currentValue
    }

    const cart = cartConverter.convertCart(strippedResponseData.result, couponCode)

    currentValue.items[CART_LOCATION_ID] = cart
    currentValue.items[JOINED_CART_LOCATION_ID] = _wrapJoinedCart(cart)

    if (wixappsDataAspect) {
        updateCart(cart, wixappsDataAspect)
    }

    if (cart.id && cart.id !== cartConverter.NULL_CART_ID) {
        setCartId(siteData, cart.id)
    }

    const descriptor = wixappsDataHandler.getDescriptor(siteData, ecomDataUtils.packageName)
    _.forEach(fetchingCart, function (compData) {
        if (descriptor) {
            wixappsDataHandler.setCompFailedRequests(false, siteData, ecomDataUtils.packageName, compData.id)
            wixappsDataHandler.clearCompMetadata(siteData, ecomDataUtils.packageName, compData.id)
        } else {
            wixappsDataHandler.setCompMetadata({dataReady: true}, siteData, ecomDataUtils.packageName, compData.id)
        }
    })

    // @ts-ignore
    if (cart.preloadShipping && wixappsDataAspect) {
        getShipping(siteData, wixappsDataAspect, onFailCallback)
    }

    return currentValue
}

function getCouponCode(siteData) {
    return wixappsDataHandler.getDataByPath(siteData, ecomDataUtils.packageName, ['cart', 'coupon', 'couponCode'])
}

function getCartResponseFunc(params) {
    const {siteData} = params
    const couponCode = params.dontMergeWithOldData ? '' : getCouponCode(siteData)
    return function (res, err) {
        const currentStoreValue = ecomDataUtils.getApplicationDataStore(siteData)
        if (err && params.siteApi) {
            if (err.code === 1001) {
                clearCart(siteData)
                setCartData(null, null, null, currentStoreValue, _.noop)
            }
            if (params.onFailCallback) {
                params.onFailCallback(err)
                return currentStoreValue
            }
            const ecomDialogAspect = params.siteApi.getSiteAspect('ecomDialog')
            ecomDialogAspect.showMessage({code: 2024})
            return currentStoreValue
        }
        return setCartData(siteData, params.wixappsDataAspect, res, currentStoreValue, null, couponCode)
    }
}

function sendRequest(siteData, params, requestMetadata, wixappsDataAspect, successCallback, failCallback) {
    wixappsDataAspect.setMetadata({updatingCart: true}, ecomDataUtils.packageName)

    ecomRequestSender.sendRequest(
        siteData,
        params,
        requestMetadata,
        function onSuccessCallback() {
            wixappsDataAspect.setMetadata({updatingCart: false}, ecomDataUtils.packageName)
            if (successCallback) {
                successCallback()
            }
        },
        failCallback
    )
}

function getShipping(siteData, wixappsDataAspect, onFailCallback) {
    const dataPath = ['fees', 'destination']
    let countryList
    if (countryListCache) {
        countryList = shippingConverter.convertCountriesList(countryListCache)
        setCartItemDataAndUpdate(dataPath, countryList, wixappsDataAspect)
        return
    }

    const requestMetadata = {
        action: 'dynamicstore_order_cart.getCountries',
        transformFunc(res) {
            countryListCache = _.cloneDeep(res)
            countryList = shippingConverter.convertCountriesList(res)
            setCartItemDataAndUpdate(dataPath, countryList, wixappsDataAspect)
        }
    }

    sendRequest(siteData, null, requestMetadata, wixappsDataAspect, null, onFailCallback)
}

function setShipping(countryId, regionId, siteData, wixappsDataAspect, dontMergeWithOldData, onSuccessCallback, onFailCallback) {
    const requestMetadata = {
        action: 'dynamicstore_order_cart.setDestination',
        transformFunc: getCartResponseFunc({siteData, dontMergeWithOldData, wixappsDataAspect})
    }

    const shippingObj = {
        destination: {
            countryId,
            regionId
        },
        cartId: getCartId(siteData)
    }
    sendRequest(siteData, shippingObj, requestMetadata, wixappsDataAspect, onSuccessCallback, onFailCallback)
}

function setCoupon(couponCode, siteData, wixappsDataAspect, onError) {
    if (couponCode === '' || couponCode === undefined) {
        return
    }

    const cartResponseFunc = getCartResponseFunc({siteData, wixappsDataAspect})
    const requestMetadata = {
        action: 'dynamicstore_order_cart.setCoupon',
        transformFunc(res, error) {
            if (error) {
                onError(error)
                return null
            }
            return cartResponseFunc(res, error)
        }
    }
    const couponObj = {
        cartId: getCartId(siteData),
        couponCode
    }
    sendRequest(siteData, couponObj, requestMetadata, wixappsDataAspect, null, onError)
}

function clearCoupon(siteData, wixappsDataAspect, onFailCallback) {
    const requestMetadata = {
        action: 'dynamicstore_order_cart.clearCoupon',
        transformFunc: getCartResponseFunc({siteData, wixappsDataAspect})
    }
    sendRequest(siteData, {cartId: getCartId(siteData)}, requestMetadata, wixappsDataAspect, null, onFailCallback)
}

function updateProduct(product, siteData, wixappsDataAspect, onFailCallback) {
    const requestMetadata = {
        action: 'dynamicstore_order_cart.updateProduct',
        transformFunc: getCartResponseFunc({siteData, wixappsDataAspect})
    }

    const params = {
        options: [],
        cartId: getCartId(siteData),
        quantity: product.quantity,
        productId: product.id
    }

    sendRequest(siteData, params, requestMetadata, wixappsDataAspect, null, onFailCallback)
}

function removeProduct(product, siteData, wixappsDataAspect, onFailCallback) {
    const requestMetadata = {
        action: 'dynamicstore_order_cart.removeProduct',
        transformFunc: getCartResponseFunc({siteData, wixappsDataAspect})
    }

    sendRequest(siteData, {productId: product.id, cartId: getCartId(siteData)}, requestMetadata, wixappsDataAspect, null, onFailCallback)
}

function addProduct(productId, siteAPI, wixappsDataAspect, options, callback, onFailCallback) {
    const siteData = siteAPI.getSiteData()
    const requestMetadata = {
        action: 'dynamicstore_order_cart.addProduct',
        transformFunc: getCartResponseFunc({siteData, wixappsDataAspect, siteApi: siteAPI, onFailCallback})
    }

    const cartId = getCartId(siteData)

    const params = {
        quantity: 1,
        productId,
        cartId,
        options: options || null
    }

    sendRequest(siteData, params, requestMetadata, wixappsDataAspect, callback, onFailCallback)
}

/**
 * @class ecommerce.cartManager
 */
export default {
    getCartId,
    getCart,
    updateCart,
    setCartData,
    clearCart,
    getCartResponseFunc,
    setCartItemData,
    setCartItemDataAndUpdate,
    getShipping,
    setShipping,
    setCoupon,
    clearCoupon,
    addProduct,
    updateProduct,
    removeProduct
}
