define(['documentServices/componentsMetaData/metaDataUtils'], function (metaDataUtils) {
    'use strict'

    const BOX_SLIDE_SHOW = 'wysiwyg.viewer.components.BoxSlideShow'

    function canContain(isByStructure, ps, componentPointer, potentialContainee) {
        if (potentialContainee) {
            const containeeCompType = isByStructure ? potentialContainee.componentType : metaDataUtils.getComponentType(ps, potentialContainee)
            return metaDataUtils.isComponentSuitableForNonRenderingState(containeeCompType)
        }

        return false
    }

    return {
        mobileConversionConfig: {
            nestOverlayingSiblings: false,
            stackLayout: true,
            structuralItem: true
        },
        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: false,
        canContain: canContain.bind(null, false),
        canContainByStructure: canContain.bind(null, true),
        allowedParentTypes: [BOX_SLIDE_SHOW]
    }
})
