define([
    'lodash',
    'experiment',
    'documentServices/hooks/hooks',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/platform/services/workerService',
    'documentServices/platform/services/originService',
    'documentServices/platform/services/sdkAPIService',
    'documentServices/platform/services/platformAppDataGetter',
    'documentServices/platform/services/platformEventsService',
    'documentServices/platform/pages',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/utils/provisionUtils',
    'documentServices/tpa/utils/permissionsUtils',
    'documentServices/platform/hooks/connectedComponentsHooks',
    'documentServices/platform/hooks/platformHooks',
    'documentServices/platform/common/constants',
    'documentServices/platform/livePreview/livePreview',
    'documentServices/platform/services/notificationService',
    'documentServices/platform/services/platformStateService',
    'documentServices/platform/componentAddedToStageCompatability',
    'platformEvents',
    '@wix/app-definition-ids',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/theme/theme'
], function (
    _,
    experiment,
    hooks,
    clientSpecMap,
    workerService,
    originService,
    sdkAPIService,
    platformAppDataGetter,
    platformEventsService,
    platformPages,
    clientSpecMapService,
    provisionUtils,
    permissionsUtils,
    connectedComponentsHooks,
    platformHooks,
    constants,
    livePreview,
    notificationService,
    platformStateService,
    componentAddedToStageCompatability,
    platformEvents,
    platformAppDefIds,
    extensionsAPI,
    theme
) {
    'use strict'

    function init(ps, api, config, options) {
        const wasWorkerInitiated = workerService.isInitiated()
        originService.setOrigin(_.get(options, 'origin'))
        initBasePaths(ps)
        platformEventsService.init(ps)
        const worker = workerService.init(ps, api, config)
        clientSpecMapService.registerToClientSpecMapOnLoad(ps, loadEditorApps)
        ps.siteAPI.registerToNotifyApplicationRequestFromViewerWorker((appDefinitionId, data) => {
            const applicationId = _.get(platformAppDataGetter.getAppDataByAppDefId(ps, appDefinitionId), 'applicationId')
            notificationService.notifyApplication(ps, applicationId, data)
        })
        ps.siteAPI.registerToAppInstanceUpdate(appInstanceMap => {
            updateClientSpecMap(ps, appInstanceMap)
        })
        if (!wasWorkerInitiated) {
            registerConnectedComponentHooks()
            registerPlatformHooks(ps)
        }
        extensionsAPI.platformSharedState.subscribeToManifestWasChanges(ps, appDefinitionId => {
            const applicationId = _.get(platformAppDataGetter.getAppDataByAppDefId(ps, appDefinitionId), 'applicationId')
            workerService.loadManifest(ps, {
                applicationId,
                appDefinitionId
            })
        })
        theme.events.onChange.addListener(ps, ({type, values}) => {
            notifyAppsOnCustomEvent(ps, platformEvents.factory.themeChanged({changeType: type, values}))
        })
        return worker
    }

    function initialize(ps) {
        if (livePreview.isActive(ps)) {
            livePreview.autoRun(ps)
        }

        if (experiment.isOpen('dm_documentOperationError')) {
            ps.setOperationsQueue.registerToErrorThrown(({appDefinitionId, error, methodName}) => {
                if (!appDefinitionId) {
                    return
                }

                const applicationId = platformAppDataGetter.getAppDataByAppDefId(ps, appDefinitionId)?.applicationId

                if (!applicationId) {
                    return
                }

                notifyApplication(
                    ps,
                    applicationId,
                    platformEvents.factory.documentOperationError({
                        error: {
                            name: error.name,
                            message: error.message,
                            stack: error.stack
                        },
                        methodName
                    })
                )
            })
        }
    }

    function updateClientSpecMap(ps, appInstanceMap) {
        _.forEach(appInstanceMap, (instance, applicationId) => {
            clientSpecMap.updateAppInstance(ps, applicationId, instance)
            notificationService.notifyApplication(ps, applicationId, {
                eventType: 'instanceChanged',
                eventPayload: {
                    instance
                }
            })
        })
        _.attempt(ps.dal.commitTransaction)
    }

    function registerConnectedComponentHooks() {
        hooks.registerHook(hooks.HOOKS.METADATA.DUPLICATABLE, connectedComponentsHooks.isDuplicatable)
        hooks.registerHook(hooks.HOOKS.METADATA.CAN_REPARENT, connectedComponentsHooks.canReparent)
        hooks.registerHook(hooks.HOOKS.METADATA.ROTATABLE, connectedComponentsHooks.isRotatable)
        hooks.registerHook(hooks.HOOKS.METADATA.FIXED_POSITION, connectedComponentsHooks.canBeFixedPosition)
        hooks.registerHook(hooks.HOOKS.METADATA.RESIZABLE_SIDES, connectedComponentsHooks.isResizableSides)
        hooks.registerHook(hooks.HOOKS.METADATA.LAYOUT_LIMITS, connectedComponentsHooks.layoutLimitsHook)
        hooks.registerHook(hooks.HOOKS.METADATA.CONTAINABLE, connectedComponentsHooks.isContainable)
    }

    function registerPlatformHooks(ps) {
        hooks.registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, platformHooks.removeGhostStructureForApp)
        hooks.registerHook(hooks.HOOKS.SAVE.SITE_SAVED, isFirstSave => {
            if (isFirstSave) {
                ps.siteAPI.reloadAppsContainer()
                platformHooks.notifyOnFirstSaved(ps, getInstalledEditorApps(ps), notificationService.notifyApplication)
            }

            notifyAppsOnCustomEvent(ps, platformEvents.factory.siteWasSaved({isAutosave: false}))

            platformStateService.clearPendingActions(ps)
        })
        hooks.registerHook(hooks.HOOKS.DUPLICATE_ROOT.AFTER, notifyApplicationOfPageDuplicated, 'mobile.core.components.Page')
        hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, notifyApplicationsOnPageAdded, 'mobile.core.components.Page')
        hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, platformHooks.notifyAddToAppWidget)
        hooks.registerHook(hooks.HOOKS.ADD_ROOT.AFTER, platformHooks.getComponentAddedToStageHook(notifyApplication))
        hooks.registerHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, platformHooks.notifyComponentDisconnected)
        hooks.registerHook(hooks.HOOKS.CONNECTION.AFTER_CONNECT, platformHooks.notifyComponentConnected)
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, platformHooks.getOnDeleteHook(notificationService.notifyApplication))
        hooks.registerHook(hooks.HOOKS.GHOSTIFY.AFTER, platformHooks.getOnDeleteHook(notificationService.notifyApplication))
    }

    function notifyApplicationOfPageDuplicated(ps, newPageId, pageId) {
        const newPagePointer = ps.pointers.page.getPagePointer(newPageId)
        const pagePointer = ps.pointers.page.getPagePointer(pageId)

        notifyAppsOnCustomEvent(
            ps,
            platformEvents.factory.pageDuplicated({
                originalPageRef: ps.pointers.components.getDesktopPointer(pagePointer),
                duplicatedPageRef: ps.pointers.components.getDesktopPointer(newPagePointer)
            })
        )
    }

    function notifyApplicationsOnPageAdded(ps, pagePointer) {
        notifyAppsOnCustomEvent(
            ps,
            platformEvents.factory.pageAdded({
                pageRef: pagePointer
            })
        )
    }

    function initBasePaths(ps) {
        const platformPointer = ps.pointers.platform.getPlatformPointer()
        const semanticAppVersionsPointer = ps.pointers.platform.getSemanticAppVersionsPointer()
        const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
        const platformValue = ps.dal.full.get(platformPointer)
        const appsInstallationStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
        const appsStatePointer = ps.pointers.platform.getAppStatePointer()
        ps.dal.full.set(
            platformPointer,
            _.assign(
                {
                    appManifest: {},
                    appPublicApiName: {}
                },
                platformValue
            )
        )

        if (!ps.dal.full.isExist(appsStatePointer)) {
            ps.dal.full.set(appsStatePointer, {})
        }

        if (!ps.dal.full.isExist(pagesPlatformApplicationsPointer)) {
            ps.dal.full.set(pagesPlatformApplicationsPointer, {})
        }

        if (!ps.dal.full.isExist(semanticAppVersionsPointer)) {
            ps.dal.full.set(semanticAppVersionsPointer, {})
        }

        if (!ps.dal.full.isExist(appsInstallationStatePointer)) {
            ps.dal.full.set(appsInstallationStatePointer, {})
        }
    }

    function getInstalledAppsData(ps) {
        // later - change to getConnectableAppIds(compRef) which will query the worker about apps that the comp can connect to (according to their manifests)
        const dataBindingAppData = platformAppDataGetter.getAppDataByAppDefId(ps, constants.APPS.DATA_BINDING.appDefId)
        return [dataBindingAppData]
    }

    // TODO: remove temp functions once platform apps provision flow has been decided
    function _tempInitApp(ps, appDefinition, appUrlQueryParams) {
        let appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinition.appDefinitionId)

        if (!appData) {
            appData = registerApp(ps, appDefinition)
        }

        if (workerService.isInitiated()) {
            if (appUrlQueryParams) {
                appData.appUrlQueryParams = appUrlQueryParams
            }
            appData.firstInstall = true
            appData.origin = originService.getOrigin()
            appData.settings = appUrlQueryParams?.settings

            workerService.addApp(ps, appData, _.noop, {internalOrigin: '_tempInitApp'})
        }

        const localApp = _tempGetLocalAppResources(ps)
        if (localApp) {
            const localAppData = registerApp(ps, localApp)
            localAppData.settings = appUrlQueryParams?.settings
            workerService.addApp(ps, localAppData, _.noop, {internalOrigin: '_tempInitAppLocalApp'})
        }
    }

    function registerApp(ps, appDefinition) {
        const csm = clientSpecMap.getAppsData(ps)
        const currentLargestId = clientSpecMapService.getLargestApplicationId(csm)
        const newId = provisionUtils.generateAppFlowsLargestAppId(currentLargestId)
        const appData = {
            type: 'Application',
            displayName: appDefinition.appDefinitionId,
            appDefinitionId: appDefinition.appDefinitionId,
            applicationId: newId,
            editorArtifact: appDefinition.editorArtifact,
            appFields: {
                platform: {
                    editorScriptUrl: _.get(appDefinition, 'appFields.platform.editorScriptUrl')
                }
            }
        }
        if (_.get(appDefinition, 'appFields.platform.viewerScriptUrl')) {
            //TODO remove this from here, move it to _tmpInit___ under wixCode
            _.set(appData, 'appFields.platform.viewerScriptUrl', appDefinition.appFields.platform.viewerScriptUrl)
        }
        clientSpecMap.registerAppData(ps, appData)
        return appData
    }

    function getInstalledEditorApps(ps) {
        const installedApps = clientSpecMap.getAppsData(ps)
        return _.filter(installedApps, appData => hasActiveEditorPlatformApp(ps, appData))
    }

    function _tempGetLocalAppResources(ps) {
        function getLocalAppLocation(appSource) {
            return `http://localhost:${appSource.port}/${appSource.path}`
        }

        function parseAppSources(type) {
            const currentUrl = ps.siteAPI.getCurrentUrl()
            const appSources = _.get(currentUrl, ['query', type])
            return _(appSources || '')
                .split(',')
                .invokeMap('split', ':')
                .fromPairs()
                .value()
        }

        const editorAppSources = parseAppSources('editorPlatformAppSources')
        const viewerAppSources = parseAppSources('viewerPlatformAppSources')
        const appId = editorAppSources.id

        if (appId) {
            return {
                appDefinitionId: appId,
                appFields: {
                    platform: {
                        viewerScriptUrl: getLocalAppLocation(viewerAppSources),
                        editorScriptUrl: getLocalAppLocation(editorAppSources)
                    }
                }
            }
        }
    }

    function getAPIForSDK(ps, api) {
        return sdkAPIService.getAPIForSDK(api)
    }

    function pageHasPlatformApp(ps, pageId, applicationId) {
        const pagesPlatformApplicationPointer = ps.pointers.platform.getPagesPlatformApplicationPointer(applicationId)
        const applicationPages = ps.dal.full.get(pagesPlatformApplicationPointer) || {}
        return !!applicationPages[pageId]
    }

    function updatePagePlatformApp(ps, pageRef, applicationId, value) {
        const pageId = pageRef.id
        const pagesPlatformApplicationPointer = ps.pointers.platform.getPagesPlatformApplicationPointer(applicationId)
        const applicationPages = ps.dal.full.get(pagesPlatformApplicationPointer) || {}
        delete applicationPages[pageId]
        if (value) {
            _.set(applicationPages, pageId, true)
        }
        //TODO : remove once members use new uninstall
        if (_.isEmpty(applicationPages) && applicationId === '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9') {
            if (ps.dal.full.isExist(pagesPlatformApplicationPointer)) {
                ps.dal.full.remove(pagesPlatformApplicationPointer)
            }
            return
        }
        ps.dal.full.set(pagesPlatformApplicationPointer, applicationPages)
    }

    function removePageFromPlatformApps(ps, pageRef) {
        const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
        const pagesPlatformApplications = ps.dal.full.get(pagesPlatformApplicationsPointer)
        _.forEach(pagesPlatformApplications, function (pages, appId) {
            updatePagePlatformApp(ps, pageRef, appId, false)
        })
    }

    function prefetchScripts(scripts) {
        _.forEach(scripts, function (script) {
            const id = `prefetch-editor-${script.id}`
            if (!window.document.getElementById(id)) {
                const link = window.document.createElement('link')
                link.setAttribute('rel', 'prefetch')
                link.setAttribute('href', script.url)
                link.setAttribute('id', id)
                window.document.head.appendChild(link)
            }
        })
    }
    function loadEditorApps(ps) {
        const migrationId = ps.siteAPI.getQueryParam('migrationId')
        const appDefinitionId = ps.siteAPI.getQueryParam('appDefinitionId')
        const isMigratingApp = (_ps, appData) => appData.appDefinitionId === appDefinitionId
        const filterFn = migrationId !== undefined ? isMigratingApp : hasActiveEditorPlatformApp
        const appsToLoad = _(clientSpecMapService.getAppsDataWithPredicate(ps, csm => _.filter(csm, appData => filterFn(ps, appData))))
            .map(workerService.enhanceAppWithEditorScript(ps))
            .map(val => {
                val.origin = originService.getOrigin()
                val.firstInstall = false
                return val
            })
            .value()
        if (appsToLoad.length) {
            const appsToPrefetch = appsToLoad.map(app => ({id: app.applicationId, url: app.appFields.platform.editorScriptUrl}))
            prefetchScripts(appsToPrefetch)

            workerService.addApps(ps, appsToLoad)
        }
    }

    function hasActiveEditorPlatformApp(ps, appData) {
        return _.get(appData, ['appFields', 'platform', 'editorScriptUrl']) && clientSpecMapService.isAppActive(ps, appData)
    }

    function getPagesDataFromAppManifest(ps, appDefinitionId, key, states, pageRef) {
        const appManifest = platformAppDataGetter.getAppManifest(ps, appDefinitionId)

        const pageState = pageRef ? platformPages.getState(ps, pageRef) : 'default'
        const hasManifestByState = !!_.get(appManifest, ['pages', key, pageState])
        const manifestByState = _.get(appManifest, ['pages', key, hasManifestByState ? pageState : 'default'])
        const overridesFormat = !!_.get(manifestByState, ['defaultValues'])

        const data = overridesFormat ? _.get(manifestByState, 'defaultValues') : manifestByState

        const overrides = overridesFormat ? _.get(manifestByState, 'overrides') : _.get(appManifest, ['pages', key, 'overrides'])

        if (_.isEmpty(overrides)) {
            return data
        }

        return _.reduce(
            overrides,
            (res, overrideDef) => {
                if (_.isMatch(states, overrideDef.condition)) {
                    if (_.isArray(overrideDef.override)) {
                        res = overrideDef.override
                    } else {
                        _.assign(res, overrideDef.override)
                    }
                }
                return res
            },
            data
        )
    }

    function getAppDescriptor(ps, appDefinitionId) {
        const appManifest = platformAppDataGetter.getAppManifest(ps, appDefinitionId)

        return _.get(appManifest, 'appDescriptor')
    }

    async function remove(ps, applicationId, {intent = null} = {}) {
        const appData = clientSpecMapService.getAppData(ps, applicationId)

        if (await permissionsUtils.shouldAvoidRevoking({ps}, {intent})) {
            platformStateService.setUnusedApps(ps, [appData])
        } else {
            platformStateService.setAppPendingAction(ps, appData.appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
        }

        return workerService.notifyRemoveApp(ps, applicationId).catch(() => {
            throw new Error(`Failed to remove applicationId ${applicationId}`)
        })
    }

    function registerToAppsCompleted(ps, cb) {
        workerService.registerToAppsCompleted(cb)
    }

    function notifyApplication(ps, applicationId, options, isAmendableAction) {
        const compatEventType = componentAddedToStageCompatability.getGeneralEventCompatibleType(options && options.eventType)
        // ignoring events that were moved to DM from editor
        if (componentAddedToStageCompatability.shouldIgnoreEvent(options.eventType, options.eventOrigin)) {
            return
        }
        if (compatEventType) {
            notificationService.notifyApplication(ps, applicationId, {...options, eventType: compatEventType}, isAmendableAction)
        }

        return notificationService.notifyApplication(ps, applicationId, options, isAmendableAction)
    }

    function notifyAppsOnCustomEvent(ps, {eventType, eventPayload, eventOrigin}) {
        const compatEventType = componentAddedToStageCompatability.getCustomEventCompatibleType(eventType)
        // ignoring events that were moved to DM from editor
        if (componentAddedToStageCompatability.shouldIgnoreEvent(eventType, eventOrigin)) {
            return
        }
        if (compatEventType) {
            platformEventsService.notifyAppsOnCustomEvent(ps, compatEventType, eventPayload, workerService.triggerEvent)
        }

        return platformEventsService.notifyAppsOnCustomEvent(ps, eventType, eventPayload, workerService.triggerEvent)
    }

    function migrate(ps, appDefId, payload) {
        const appData = platformAppDataGetter.getAppDataByAppDefId(ps, appDefId)
        if (!appData) {
            return Promise.reject(new Error(`Migration failed: Unknown appDefinitionId: ${appDefId}`))
        }

        return workerService.notifyMigrate(ps, appData.applicationId, payload).catch(e => {
            throw new Error(e.message || `Failed to migrate applicationId ${appData.applicationId}`)
        })
    }

    function setGhostStructure(ps, value) {
        const ghostStructurePointer = ps.pointers.general.getGhostStructure()
        const currentGhostStructure = ps.dal.get(ghostStructurePointer)
        ps.dal.set(ghostStructurePointer, _.defaults(value, currentGhostStructure))
    }

    function setGhostControllers(ps, value) {
        const ghostControllersPointer = ps.pointers.general.getGhostControllers()
        const currentGhostControllers = ps.dal.get(ghostControllersPointer)
        ps.dal.set(ghostControllersPointer, _.defaults(value, currentGhostControllers))
    }

    const getAppsDependenciesWithPredicate = (ps, appDefinitionIds, predicate) => {
        if (predicate && typeof predicate !== 'function') {
            throw new Error('predicate should be a function')
        }
        const dependencies = []
        appDefinitionIds.forEach(appDefId => {
            platformAppDefIds.getAppDependencies(appDefId).forEach(dependency => {
                const existingDep = dependencies.find(({appDefinitionId}) => appDefinitionId === dependency.appDefinitionId)
                if (existingDep) {
                    existingDep.isRequired = dependency.isRequired || existingDep.isRequired
                } else {
                    const didPass = predicate ? predicate(dependency) : true
                    if (didPass) {
                        dependencies.unshift(dependency)
                    }
                }
            })
        })
        return dependencies
    }

    const getInstalledApps = ps =>
        clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
            Object.values(csm).filter(
                appData => appData.appDefinitionId && clientSpecMapService.isAppActive(ps, appData) && !clientSpecMapService.isDashboardAppOnly(appData)
            )
        )

    const isAppActive = (ps, appDefinitionId) => {
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        return clientSpecMapService.isAppActive(ps, existingAppData)
    }

    const getAppPublicApi = (ps, appDefinitionId) => getAppApi(ps, platformAppDataGetter.getAppPublicApiName(ps, appDefinitionId))

    const getAppPrivateApi = (ps, appDefinitionId) => getAppApi(ps, platformAppDataGetter.getAppPrivateApiName(ps, appDefinitionId))

    const getAppEditorApi = (ps, appDefinitionId) => getAppApi(ps, platformAppDataGetter.getAppEditorApiName(ps, appDefinitionId))

    const getAppApi = (ps, apiName) =>
        new Promise(resolve => {
            workerService.requestAPIFromWorker(ps, apiName).then(resolve, () => resolve(null)) // eslint-disable-line promise/prefer-await-to-then
        })

    const registerToPlatformAPIChange = (ps, appDefinitionId, callback) => {
        if (!appDefinitionId) {
            throw new Error('appDefinitionId is mandatory')
        }
        if (!_.isFunction(callback)) {
            throw new Error('callback is mandatory and should be function')
        }
        extensionsAPI.platformSharedState.subscribeToPlatformAPICalls(ps, appDefinitionId, callback)
    }

    const unregisterToPlatformAPIChange = (ps, appDefinitionId) => {
        extensionsAPI.platformSharedState.unsubscribeToPlatformAPICalls(ps, appDefinitionId)
    }

    return {
        initialize,
        init,
        setManifest: platformAppDataGetter.setManifest,
        registerToManifestAdded: platformAppDataGetter.registerToManifestAdded,
        initApp: _tempInitApp,
        remove,
        migrate,
        notifyApplication,
        getAPIForSDK,
        getInstalledAppsData,
        pageHasPlatformApp,
        updatePagePlatformApp,
        removePageFromPlatformApps,
        getAppManifest: platformAppDataGetter.getAppManifest,
        hasAppManifest: platformAppDataGetter.hasAppManifest,
        getAppDataByAppDefId: platformAppDataGetter.getAppDataByAppDefId,
        getAppDataByApplicationId: platformAppDataGetter.getAppDataByApplicationId,
        getAppPublicApi,
        getAppPrivateApi,
        getAppEditorApi,
        getAppDescriptor,
        requestAPIFromWorker: workerService.requestAPIFromWorker,
        getInstalledEditorApps,
        notifyAppsOnCustomEvent,
        registerAppToCustomEvents: (ps, applicationId, eventTypes) => platformEventsService.registerAppToEvents(applicationId, eventTypes),
        registerToPublicApiSet: platformAppDataGetter.registerToPublicApiSet,
        registerToPrivateApiSet: platformAppDataGetter.registerToPrivateApiSet,
        isPlatformAppInstalled: platformAppDataGetter.isPlatformAppInstalled,
        getPagesDataFromAppManifest,
        registerToAppsCompleted,
        getEditorSdkUrl: workerService.getEditorSdkUrl,
        setGhostStructure,
        setGhostControllers,
        getAppsDependenciesWithPredicate,
        isAppActive,
        getInstalledApps,
        concurrentEditing: {
            registerToPlatformAPIChange,
            unregisterToPlatformAPIChange
        }
    }
})
