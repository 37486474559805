// @ts-nocheck
;(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], factory)
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory()
    } else {
        // Browser globals (root is window)
        root.returnExports = factory()
    }
})(typeof self !== 'undefined' ? self : this, function () {
    // Just return a value to define the module export.
    // This example returns an object, but the module
    // can return a function as the exported value.
    'use strict'
    const skins = {}
    skins['wixapps.integration.skins.ClippedParagraphSkin'] = {
        react: [['div', 'richTextContainer', [], {}]],
        css: {
            '%': 'word-wrap:break-word;',
            '% ul': 'list-style-type:disc;padding-left:1.3em;margin-left:.5em;line-height:normal;letter-spacing:normal;',
            '% ol': 'list-style-type:decimal;padding-left:1.3em;margin-left:.5em;line-height:normal;letter-spacing:normal;',
            '% ul[data-dir="rtl"]': 'padding-right:1.3em;margin-right:.5em;',
            '% ol[data-dir="rtl"]': 'padding-right:1.3em;margin-right:.5em;',
            '% ul ul': 'list-style-type:circle;line-height:normal;',
            '% ol ul': 'list-style-type:circle;line-height:normal;',
            '% ol ol ul': 'list-style-type:square;line-height:normal;',
            '% ol ul ul': 'list-style-type:square;line-height:normal;',
            '% ul ol ul': 'list-style-type:square;line-height:normal;',
            '% ul ul ul': 'list-style-type:square;line-height:normal;',
            '% li': 'color:inherit;font-size:inherit;font-family:inherit;font-style:inherit;font-weight:inherit;line-height:inherit;letter-spacing:normal;',
            '% p': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h1': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h2': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h3': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h4': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h5': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% h6': 'margin:0;line-height:normal;letter-spacing:normal;',
            '% a': 'color:inherit;'
        }
    }
    skins['wixapps.integration.skins.DropTargetSkin'] = {
        react: [['div', 'inlineContent', [], {}]],
        params: {
            $BorderRadius: 'BORDER_RADIUS'
        },
        paramsDefaults: {
            $BorderRadius: '3px'
        },
        css: {
            '%': '[$BorderRadius]  position:relative;height:34px;line-height:34px;border:dashed 1px #A0A0A0;background:#f8f8f8;',
            '%inlineContent': 'margin:0 10px 10px 10px;line-height:30px;',
            '%inlineContent %_disabled': 'opacity:0.4;'
        }
    }
    skins['wixapps.integration.skins.ecommerce.options.ColorOptionSkin'] = {
        react: [
            ['div', 'tooltip', [], {}],
            ['div', null, ['_olo'], {}]
        ],
        exports: {
            tooltip: {
                skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin'
            }
        },
        css: {
            '%_olo': 'height:20px;position:relative;',
            '%[data-state~="selected"] %_olo': 'box-shadow:inset 0 0 0 1px #444;',
            '%[data-state~="selected"] %_olo:after':
                'content:"\\25BC";position:absolute;left:50%;bottom:-7px;margin-left:-4px;color:#444;font-weight:bold;font-size:8px;font-family:Arial;line-height:1;',
            '%[data-state~="disabled"]': 'display:none;',
            '%tooltip': 'min-width:100px !important;margin:-4px 0 0 14px;font-size:12px;text-transform:uppercase;text-align:center;',
            '%tooltip:after':
                'content:"\\25BC";position:absolute;left:50%;bottom:-10px;margin-left:-6px;color:#fffedf;font-size:12px;font-family:Arial;line-height:1;text-shadow:0 1px 2px rgba(0, 0, 0, 0.5);'
        }
    }
    skins['wixapps.integration.skins.ecommerce.options.OptionsListInputSkin'] = {
        react: [],
        css: {
            '%': 'position:relative;white-space:normal;',
            '% > div':
                'border:1px solid #444;display:inline-block;min-width:26px;line-height:20px;height:20px;margin:0 3px;text-align:center;text-transform:uppercase;cursor:pointer;',
            '% > div:last-child': 'margin-right:0;',
            '%[data-state~="invalid"]:before':
                'content:"!";position:absolute;text-align:center;bottom:50%;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;color:#fff;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
            '%[data-state~="invalid"]:after':
                'content:"►";position:absolute;bottom:50%;left:3px;margin:0 0 -7px -2px;color:#fff;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;'
        }
    }
    skins['wixapps.integration.skins.ecommerce.options.SelectOptionsListSkin'] = {
        react: [
            ['p', 'tooltip', [], {}],
            ['div', 'itemsContainer', [], {}]
        ],
        css: {
            '%[data-state~="hidden"] %tooltip': 'display:none;',
            '%[data-state~="displayed"] %tooltip': 'display:block;width:100px;'
        }
    }
    skins['wixapps.integration.skins.ecommerce.options.TextOptionSkin'] = {
        react: [
            ['div', 'tooltip', [], {}],
            ['div', 'size', [], {}]
        ],
        exports: {
            tooltip: {
                skin: 'wixapps.integration.skins.ecommerce.options.InfoTipSkin'
            }
        },
        css: {
            '%': 'background:#fff;margin-top:3px !important;',
            '%size': 'position:relative;',
            '%[data-state~="selected"] %size': 'box-shadow:inset 0 0 0 1px #444;',
            '%[data-state~="selected"] %size:after':
                'content:"▼";position:absolute;left:50%;bottom:-7px;margin-left:-4px;color:#444;font-weight:bold;font-size:8px;font-family:Arial;line-height:1;',
            '%[data-state~="disabled"]': 'display:none;',
            '%tooltip': 'min-width:100px !important;margin:-4px 0 0 14px;font-size:12px;text-transform:uppercase;text-align:center;',
            '%tooltip:after':
                'content:"▼";position:absolute;left:50%;bottom:-10px;margin-left:-6px;color:#fffedf;font-size:12px;font-family:Arial;line-height:1;text-shadow:0 1px 2px rgba(0, 0, 0, 0.5);'
        }
    }
    skins['wixapps.integration.skins.ecommerce.PaginatedGridSimple'] = {
        react: [
            ['div', 'inlineContent', [], {}],
            ['ul', null, [], {}, ['li', 'buttonPrev', [], {}], ['li', 'counter', [], {}], ['li', 'buttonNext', [], {}]]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            bg: 'BG_COLOR_ALPHA',
            itemBrw: 'BORDER_SIZE',
            itemBrc: 'BORDER_COLOR_ALPHA',
            rbn: 'BG_COLOR_ALPHA',
            strip: 'BG_COLOR_ALPHA',
            txth: 'TEXT_COLOR',
            bgh: 'BG_COLOR_ALPHA',
            itemBrch: 'BORDER_COLOR_ALPHA',
            tdr: 'URL',
            paging: 'TEXT_COLOR'
        },
        paramsDefaults: {
            rd: '0',
            bg: 'color_11',
            itemBrw: '0',
            itemBrc: 'color_16',
            rbn: 'color_23',
            strip: 'color_18',
            txth: 'color_18',
            bgh: 'color_11',
            itemBrch: 'color_18',
            tdr: 'BASE_THEME_DIRECTORY',
            paging: 'color_15'
        },
        css: {
            '%_hid': 'display:none;',
            '%_galleryDisplayer': 'box-sizing:border-box;[rd]  background-color:[bg];border:[itemBrw] solid [itemBrc];cursor:pointer !important;',
            '%_galleryDisplayer %_rolloverStrip': 'background:transparent;',
            '%_galleryDisplayer %_ribbon': 'background-color:[rbn];',
            '%_galleryDisplayer:hover %_rolloverStrip': 'background:[strip];',
            '%_galleryDisplayer:hover %_txtHoverColor *': 'color:[txth];',
            '%_galleryDisplayer:hover': 'background-color:[bgh];border-color:[itemBrch];',
            '%_galleryDisplayer:hover %_pic:after':
                'content:"";position:absolute;top:0;right:0;bottom:-6px;left:0;background:transparent url([tdr]wix_apps/magnify.png) right top no-repeat;border-bottom:solid 3px [txth];',
            '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
            '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
        }
    }
    skins['wixapps.integration.skins.HelpQuestionMarkIconSkin'] = {
        react: [['img', 'img', [], {}]],
        css: {}
    }
    skins['wixapps.integration.skins.inputs.BUISelectorSkin'] = {
        react: [
            ['div', 'bold', [], {}],
            ['div', 'underline', [], {}],
            ['div', 'italic', [], {}]
        ],
        css: {
            '%': 'margin-left:4px;',
            '%bold,%underline,%italic': 'display:inline-block;'
        }
    }
    skins['wixapps.integration.skins.inputs.CheckBoxInputInlineSkin'] = {
        react: [
            ['fieldset', null, ['_fieldSet'], {}, ['input', 'checkBox', [], {}]],
            ['label', 'label', [], {}]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            shd: 'BOX_SHADOW',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR_ALPHA',
            bg: 'BG_COLOR_ALPHA',
            tdr: 'URL',
            trns: 'TRANSITION'
        },
        paramsDefaults: {
            rd: '3px',
            shd: '0 0 0 rgba(0, 0, 0, 0)',
            brw: '1px',
            brd: '#999999',
            bg: '#ffffff',
            tdr: 'BASE_THEME_DIRECTORY',
            trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s'
        },
        css: {
            '%_fieldSet': 'display:inline-block;margin-right:10px;vertical-align:middle;',
            '%': 'position:relative;cursor:pointer;',
            '% input[data-type="checkbox"]':
                '-webkit-appearance:none !important;-moz-appearance:none !important;box-sizing:border-box;[rd]  [shd]  width:18px;height:18px;border:[brw] solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;margin:0;cursor:pointer;',
            '% input[data-type="checkbox"]:checked': 'background-position:50% 50%;',
            '% p': '[trns]  [rd]  opacity:0;position:absolute;right:0;bottom:18px;width:140px;padding:10px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
            '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
            '%[data-state~="invalid"]:hover + p': 'opacity:1;color:#d00;'
        }
    }
    skins['wixapps.integration.skins.inputs.CheckBoxInputSkin'] = {
        react: [
            ['input', 'checkBox', [], {}],
            ['div', null, [], {}],
            ['label', 'label', [], {}]
        ],
        params: {
            brd: 'BORDER_COLOR_ALPHA',
            bg: 'BG_COLOR_ALPHA',
            tdr: 'URL',
            rd: 'BORDER_RADIUS'
        },
        paramsDefaults: {
            brd: '#aaaaaa',
            bg: '#ffffff',
            tdr: 'BASE_THEME_DIRECTORY',
            rd: '3px'
        },
        css: {
            '%': 'position:relative;width:16px;height:16px;display:inline-block;overflow:visible;',
            '% input[data-type="checkbox"]': 'position:absolute;top:-9999px;left:-9999px;overflow:visible;',
            '% label':
                'height:16px;display:block;position:absolute;top:0;padding-left:22px;cursor:pointer;font-family:Helvetica, Arial, sans-serif;font-size:12px;',
            '% div':
                'width:16px;height:16px;border:1px solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;[rd]  box-sizing:border-box !important;',
            '% input[data-type="checkbox"]:checked ~ div': 'background-position:50% 50%;',
            '% input%input%_isPlaceholder': 'color:#999;'
        }
    }
    skins['wixapps.integration.skins.inputs.InlineTextInputSkin'] = {
        react: [['div', null, [], {}, ['div', 'preview', [], {}], ['input', 'input', [], {}]]],
        css: {
            '%[data-state~="preview"] %input': 'display:none;',
            '%[data-state~="input"] %preview': 'display:none;',
            '%preview:hover': 'border:1px solid #C3C3C3;padding:1px 3px;'
        }
    }
    skins['wixapps.integration.skins.inputs.LocationSelectorSkin'] = {
        react: [
            [
                'div',
                null,
                ['_clearfix'],
                {},
                ['div', 'container', [], {}, ['input', 'address', [], {}], ['div', 'viewMode', [], {}]],
                ['div', 'message', [], {}]
            ]
        ],
        params: {
            baseThemeDir: 'URL'
        },
        paramsDefaults: {
            baseThemeDir: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%container': 'height:30px;line-height:30px;padding-right:35px;position:relative;',
            '%address': 'width:100%;',
            '%viewMode': 'width:30px;height:30px;position:absolute;right:0;top:0;',
            '%[data-state~="loading"] %viewMode': 'background:transparent url([baseThemeDir]wix_apps/loading.gif) center center no-repeat;',
            '%[data-state~="error"] %viewMode': 'background:transparent url([baseThemeDir]wix_apps/icon_x.png) center center no-repeat;',
            '%[data-state~="found"] %viewMode': 'background:transparent url([baseThemeDir]wix_apps/icon_v.png) center center no-repeat;',
            '%[data-state~="search"] %viewMode': 'background:transparent url([baseThemeDir]wix_apps/spy_glass.png) center center no-repeat;cursor:pointer;',
            '%[data-state~="invalid"] %message': 'height:20px;line-height:20px;color:#d00;font-size:12px;'
        }
    }
    skins['wixapps.integration.skins.inputs.RichTextEditorSkin'] = {
        react: [['div', 'frame', [], {}, ['div', 'richTextContainer', ['_richTextContainer'], {}]]],
        params: {
            $borderRadius: 'BORDER_RADIUS',
            shadowColor: 'BOX_SHADOW_COLOR_ALPHA'
        },
        paramsDefaults: {
            $borderRadius: '3px',
            shadowColor: '0, 0, 0, 0.1'
        },
        css: {
            '%': 'background-color:#fff;',
            '%frame': '[$borderRadius]  border:1px solid #b4b4b4;box-shadow:0 1px 1px 0 [shadowColor] inset;padding:2px;position:relative;',
            '%richTextContainer': 'position:relative;width:100%;height:100%;overflow-y:auto;word-wrap:break-word;white-space:normal;'
        }
    }
    skins['wixapps.integration.skins.inputs.SingleLineCheckBoxInputInlineSkin'] = {
        react: [
            ['fieldset', null, ['_fieldSet'], {}, ['input', 'checkBox', [], {}]],
            ['label', 'label', [], {}]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            shd: 'BOX_SHADOW',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR_ALPHA',
            bg: 'BG_COLOR_ALPHA',
            tdr: 'URL',
            trns: 'TRANSITION'
        },
        paramsDefaults: {
            rd: '3px',
            shd: '0 0 0 rgba(0, 0, 0, 0)',
            brw: '1px',
            brd: '#999999',
            bg: '#ffffff',
            tdr: 'BASE_THEME_DIRECTORY',
            trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s'
        },
        css: {
            '%_fieldSet': 'float:left;display:inline-block;margin-right:10px;vertical-align:middle;',
            '%': 'position:relative;cursor:pointer;',
            '% input[data-type="checkbox"]':
                '-webkit-appearance:none !important;-moz-appearance:none !important;box-sizing:border-box;[rd]  [shd]  width:18px;height:18px;border:[brw] solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;margin:0;cursor:pointer;',
            '% input[data-type="checkbox"]:checked': 'background-position:50% 50%;',
            '% p': '[trns]  [rd]  opacity:0;position:absolute;right:0;bottom:18px;width:140px;padding:10px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
            '%label': 'white-space:nowrap;display:inline-block;overflow:hidden;text-overflow:ellipsis;word-wrap:normal;vertical-align:middle;',
            '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
            '%[data-state~="invalid"]:hover + p': 'opacity:1;color:#d00;'
        }
    }
    skins['wixapps.integration.skins.inputs.SubmitInputMessageAboveButtonSkin'] = {
        react: [
            [
                'div',
                null,
                ['_clearfix'],
                {},
                ['label', 'label', [], {}],
                ['input', 'input', [], {}],
                ['div', 'message', [], {}],
                ['div', null, ['_submitInputContainer'], {}, ['div', 'button', [], {}]]
            ]
        ],
        exports: {
            button: {
                skin: 'wysiwyg.editor.skins.buttons.ButtonSmallerSkin'
            }
        },
        params: {
            $borderRadius: 'BORDER_RADIUS',
            shadowColor: 'BOX_SHADOW_COLOR_ALPHA'
        },
        paramsDefaults: {
            $borderRadius: '3px',
            shadowColor: '0, 0, 0, 0.1'
        },
        css: {
            '%[data-state~="hasLabel"] %label': 'display:block;padding-bottom:5px;',
            '% input%input':
                'width:100%;margin:0 0 5px 0;padding:0 3px;line-height:1.1em;height:1.8em;font-size:1em;[$borderRadius]  border:1px solid #b4b4b4;box-shadow:0 1px 1px 0 [shadowColor] inset;',
            '%input:hover': 'border-color:#a3d9f6;',
            '%input:focus': 'border-color:#19a0e9;',
            '%_submitInputContainer': 'text-align:right;',
            '%button': 'display:inline-block;',
            '%[disabled] %label': 'opacity:.5;',
            '%[data-state~="invalid"] input%input': 'background:#f6e0dd;border-color:#de4c3a;',
            '%[data-state~="invalid"] %message': 'color:#de4c3a;font-size:.916em;',
            '% input%input%_isPlaceholder': 'color:#999;'
        }
    }
    skins['wixapps.integration.skins.inputs.TextInputWithIconSkin'] = {
        react: [
            [
                'div',
                null,
                ['_clearfix'],
                {},
                ['label', 'label', [], {}],
                ['p', null, ['_container'], {}, ['span', null, ['_sepLine'], {}], ['input', 'input', [], {}]],
                ['div', 'message', [], {}]
            ]
        ],
        params: {
            webThemeDir: 'URL'
        },
        paramsDefaults: {
            webThemeDir: 'WEB_THEME_DIRECTORY'
        },
        css: {
            '%_singleLine': 'white-space:nowrap;display:block;overflow:hidden;text-overflow:ellipsis;word-wrap:normal;',
            '%_container':
                'position:relative;background:transparent url([webThemeDir]lists/appbuilder_sprite.png) -558px -19px no-repeat;font-size:1em;border-radius:3px;border:1px solid #B4B4B4;-webkit-border-image:initial;border-image:initial;box-shadow:rgba(0, 0, 0, 0.0980392) 0 1px 1px 0 inset;background-color:#FFF;margin:0 0 5px;cursor:pointer;padding:0 3px;padding-left:20px;min-width:1px;min-height:23px;line-height:23px;vertical-align:middle;',
            '%input': 'border:0;width:100%;padding-left:10px;font-size:14px;',
            '%_sepLine': 'position:absolute;height:19px;top:3px;left:24px;width:1px;border-left:solid 1px #B4B4B4;margin-right:5px;'
        }
    }
    skins['wixapps.integration.skins.inputs.TextLinkSkin'] = {
        react: [],
        css: {
            '%': 'position:relative;cursor:pointer;color:#09f;width:100%;word-wrap:break-word;',
            '% span': 'color:#09f;',
            '% span:hover': 'text-decoration:underline;',
            '% p': 'color:#09f;',
            '% p:hover': 'text-decoration:underline;'
        }
    }
    skins['wixapps.integration.skins.inputs.TimeInputSkin'] = {
        react: [
            [
                'div',
                null,
                ['_clearfix'],
                {},
                ['select', 'hours24', [], {}],
                ['select', 'hours12', [], {}],
                ['select', 'minutes', [], {}],
                ['select', 'seconds', [], {}],
                ['select', 'ampm', [], {}],
                ['div', 'message', [], {}]
            ]
        ],
        css: {
            '%[data-state~="24h"] %hours24': 'display:inline;',
            '%[data-state~="24h"] %hours12': 'display:none;',
            '%[data-state~="24h"] %ampm': 'display:none;',
            '%[data-state~="12h"] %hours24': 'display:none;',
            '%[data-state~="12h"] %hours12': 'display:inline;',
            '%[data-state~="12h"] %ampm': 'display:inline;',
            '%[data-state~="hours"] %minutes': 'display:none;',
            '%[data-state~="hours"] %seconds': 'display:none;',
            '%[data-state~="half-hour"] %seconds': 'display:none;',
            '%[data-state~="quarter-hour"] %seconds': 'display:none;',
            '%[data-state~="minutes"] %minutes option': 'display:inline;',
            '%[data-state~="minutes"] %seconds': 'display:none;',
            '%[data-state~="allowEmpty"] option%_empty': 'display:inline;',
            '%[data-state~="hideEmpty"] option%_empty': 'display:none;',
            '%message': 'visibility:hidden;height:20px;line-height:20px;color:#d00;font-size:12px;',
            '%[data-state~="invalid"] %message': 'visibility:visible;'
        }
    }
    skins['wixapps.integration.skins.LinkSelectorSkin'] = {
        react: [['p', 'description', [], {}]],
        params: {
            baseThemeDir: 'URL'
        },
        paramsDefaults: {
            baseThemeDir: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%description':
                'background:transparent url([baseThemeDir]wix_apps/link-selector-button.png) left center no-repeat;cursor:pointer;padding-bottom:3px;padding-left:20px;min-width:1px;min-height:16px;'
        }
    }
    skins['wixapps.integration.skins.LinkSelectorSkinII'] = {
        react: [['p', null, ['_container', '_singleLine'], {}, ['span', null, ['_sepLine'], {}], ['span', 'description', [], {}]]],
        params: {
            baseThemeDir: 'URL'
        },
        paramsDefaults: {
            baseThemeDir: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%_singleLine': 'white-space:nowrap;display:block;overflow:hidden;text-overflow:ellipsis;word-wrap:normal;',
            '%_container':
                'width:100%;background:transparent url([baseThemeDir]wix_apps/link_sprite.png) 2px top no-repeat;font-size:1em;border-radius:3px;border:1px solid #B4B4B4;-webkit-border-image:initial;border-image:initial;box-shadow:rgba(0, 0, 0, 0.0980392) 0 1px 1px 0 inset;background-color:#FFF;margin:0 0 5px;cursor:pointer;padding:0 3px;padding-left:20px;min-width:1px;min-height:23px;line-height:23px;vertical-align:middle;',
            '%[data-state="nonEditable"] %_container': 'cursor:default;',
            '%description': 'font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;',
            '%_sepLine': 'width:1px;border-left:solid 1px #B4B4B4;margin-right:5px;margin-left:4px;'
        }
    }
    skins['wixapps.integration.skins.TextAreaSkin'] = {
        react: [['div', null, ['_clearfix'], {}, ['label', 'label', [], {}], ['textarea', 'input', [], {}], ['div', 'message', [], {}]]],
        params: {
            shadowColor: 'BOX_SHADOW_COLOR_ALPHA'
        },
        paramsDefaults: {
            shadowColor: '0, 0, 0, 0.3'
        },
        css: {
            '%_clearfix': 'height:100%;',
            '%input':
                'width:100%;height:100%;padding:12px;line-height:1.3em;font-size:1em;border:1px solid #e3e3e3;box-shadow:0 1px 1px 0 [shadowColor] inset;',
            '%input:hover': 'border-color:#a3d9f6;',
            '%input:focus': 'border-color:#19a0e9;',
            '%[data-state~="hasLabel"] %label': 'display:block;padding-bottom:5px;',
            '%[disabled] %label': 'opacity:0.5;',
            '%[data-state~="invalid"] input%input': 'background:#f6e0dd;border-color:#de4c3a;',
            '%[data-state~="invalid"] %message': 'color:#de4c3a;font-size:.916em;',
            '% input%input%_isPlaceholder': 'color:#999;'
        }
    }
    skins['wixapps.integration.skins.ToggleSkin2'] = {
        react: [
            ['div', 'on', ['_label'], {}],
            ['div', 'off', [], {}]
        ],
        css: {
            '%': 'cursor:default;padding:10px 20px;border:1px solid #e6e6e6;border-top:1px solid #ccc;border-radius:5px;',
            '%[data-state="on"] > %off': 'display:none;',
            '%[data-state="off"] > %off': 'display:block;margin-top:3px;',
            '%[data-state="on"] > %on': 'display:block;',
            '%[data-state="off"] > %on': 'display:none;'
        }
    }
    skins['wixapps.integration.skins.WixAppsButtonSkin'] = {
        react: [
            ['div', 'icon', [], {}],
            ['div', 'label', [], {}],
            ['div', 'link', [], {}]
        ],
        params: {
            borderColor: 'BORDER_COLOR',
            $borderRadius: 'BORDER_RADIUS',
            bgColor: 'BG_COLOR_ALPHA',
            webThemeDir: 'URL',
            borderColorOver: 'BORDER_COLOR',
            bgColorOver: 'BG_COLOR_ALPHA',
            shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
            shadowInsetColorOver: 'BOX_SHADOW_COLOR_ALPHA',
            bgColorPressed: 'BG_COLOR_ALPHA',
            shadowInsetColor: 'BOX_SHADOW_COLOR_ALPHA',
            labelColor: 'TEXT_COLOR'
        },
        paramsDefaults: {
            borderColor: '#c4c4c4',
            $borderRadius: '4px',
            bgColor: '#f9fafc',
            webThemeDir: 'WEB_THEME_DIRECTORY',
            borderColorOver: '#89a4af',
            bgColorOver: '#f2f3f5',
            shadowColor: '0, 0, 0, 0.2',
            shadowInsetColorOver: '255, 255, 255, 0.7',
            bgColorPressed: '#f3f3f3',
            shadowInsetColor: '0, 0, 0, 0.7',
            labelColor: '#24a0c4'
        },
        css: {
            '%': 'padding:0 10px;border:1px solid [borderColor];[$borderRadius]  background:[bgColor] url([webThemeDir]button/button-gradient.png) repeat-x 0 50%;position:relative;text-align:center;cursor:pointer;',
            '%[data-state~="over"]':
                'border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;',
            '%[data-state~="selected"]': 'background:[bgColorPressed] none;border-color:transparent;box-shadow:0 1px 3px 0 [shadowInsetColor] inset;',
            '%[data-state~="pressed"]': 'background:[bgColorPressed] none;border-color:transparent;box-shadow:0 1px 3px 0 [shadowInsetColor] inset;',
            '%icon': 'position:absolute;left:5px;top:50%;width:24px;height:24px;margin-top:-12px;',
            '%label': 'color:[labelColor];height:30px;line-height:30px;',
            '%[disabled]': 'opacity:0.5;'
        }
    }
    skins['wysiwyg.viewer.skins.paginatedgrid.counter.CounterGraphicSkin'] = {
        react: [['ul', 'graphic', [], {}]],
        params: {
            insetShadow: 'BOX_SHADOW'
        },
        paramsDefaults: {
            insetShadow: 'inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2)'
        },
        css: {
            '%graphic li': 'background-color:#CCC;display:inline-block;border-radius:50%;height:8px;width:8px;margin:0 0 0 7px;[insetShadow]',
            '%graphic li%_selected': 'background-color:#0099FF;'
        }
    }
    skins['wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridDefaultSkin'] = {
        react: [
            ['h3', 'title', ['_hid'], {}],
            ['p', 'description', ['_hid'], {}],
            ['a', 'link', ['_hid'], {}],
            ['div', 'autoplay', [], {}],
            ['div', 'itemsContainer', [], {}],
            ['ul', null, [], {}, ['li', 'buttonPrev', [], {}], ['li', 'counter', [], {}], ['li', 'buttonNext', [], {}]],
            ['div', 'rolloverHolder', [], {}]
        ],
        params: {
            bg: 'BG_COLOR_ALPHA',
            itemBg: 'BG_COLOR_ALPHA',
            rd: 'BORDER_RADIUS',
            shd: 'BOX_SHADOW',
            itemBrc: 'BORDER_COLOR_ALPHA',
            itemBrw: 'BORDER_SIZE',
            bgh: 'BG_COLOR_ALPHA',
            paging: 'TEXT_COLOR'
        },
        paramsDefaults: {
            bg: 'color_15',
            itemBg: 'color_17',
            rd: '0',
            shd: '1px 1px 1px rgba(0, 0, 0, 0.15)',
            itemBrc: 'color_17',
            itemBrw: '0',
            bgh: 'color_17',
            paging: 'color_15'
        },
        css: {
            '%_hid': 'display:none;',
            '%itemsContainer': 'box-sizing:border-box !important;background-color:[bg];overflow:hidden;height:100% !important;cursor:pointer !important;',
            '%itemsContainer %_galleryDisplayer': 'box-sizing:border-box !important;background-color:[itemBg];[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
            '%itemsContainer %_galleryDisplayer:hover': 'background-color:[bgh];',
            '%rolloverHolder': 'background-color:[bgh];box-sizing:border-box !important;[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
            '%_multiline span': 'white-space:normal;',
            '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
            '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
        }
    }
    skins['wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridSimple'] = {
        react: [
            ['h3', 'title', ['_hid'], {}],
            ['p', 'description', ['_hid'], {}],
            ['a', 'link', ['_hid'], {}],
            ['div', 'autoplay', [], {}],
            ['div', 'itemsContainer', [], {}],
            ['ul', null, [], {}, ['li', 'buttonPrev', [], {}], ['li', 'counter', [], {}], ['li', 'buttonNext', [], {}]],
            ['div', 'rolloverHolder', [], {}]
        ],
        exports: {
            heightDiff: 40
        },
        params: {
            rd: 'BORDER_RADIUS',
            bg: 'BG_COLOR_ALPHA',
            itemBrw: 'BORDER_SIZE',
            itemBrc: 'BORDER_COLOR_ALPHA',
            rbn: 'BG_COLOR_ALPHA',
            strip: 'BG_COLOR_ALPHA',
            txth: 'TEXT_COLOR',
            bgh: 'BG_COLOR_ALPHA',
            itemBrch: 'BORDER_COLOR_ALPHA',
            tdr: 'URL',
            paging: 'TEXT_COLOR'
        },
        paramsDefaults: {
            rd: '0',
            bg: 'color_11',
            itemBrw: '0',
            itemBrc: 'color_16',
            rbn: 'color_23',
            strip: 'color_18',
            txth: 'color_18',
            bgh: 'color_11',
            itemBrch: 'color_18',
            tdr: 'BASE_THEME_DIRECTORY',
            paging: 'color_15'
        },
        css: {
            '%itemsContainer': 'overflow:hidden;',
            '%_hid': 'display:none;',
            '%_galleryDisplayer': 'box-sizing:border-box;[rd]  background-color:[bg];border:[itemBrw] solid [itemBrc];cursor:pointer !important;',
            '%_galleryDisplayer %_rolloverStrip': 'background:transparent;',
            '%_galleryDisplayer %_ribbon': 'background-color:[rbn];',
            '%_galleryDisplayer:hover %_rolloverStrip': 'background:[strip];',
            '%_galleryDisplayer:hover %_txtHoverColor *': 'color:[txth];',
            '%_galleryDisplayer:hover': 'background-color:[bgh];border-color:[itemBrch];',
            '%_galleryDisplayer:hover %_pic:after':
                'content:"";position:absolute;top:0;right:0;bottom:-6px;left:0;background:transparent url([tdr]wix_apps/magnify.png) right top no-repeat;border-bottom:solid 3px [txth];',
            '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
            '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
        }
    }
    skins['wysiwyg.viewer.skins.paginatedgrid.wixapps.TemplateChooserGallerySkin'] = {
        react: [
            ['div', 'rolloverHolder', [], {}],
            ['div', 'itemsContainer', [], {}],
            ['div', 'graphicCounter', [], {}],
            ['div', null, ['_buttons'], {}, ['a', 'buttonPrev', [], {}], ['a', 'buttonNext', [], {}]]
        ],
        exports: {
            graphicCounter: {
                skin: 'wysiwyg.viewer.skins.paginatedgrid.counter.CounterGraphicSkin'
            }
        },
        params: {
            webThemeDir: 'URL'
        },
        paramsDefaults: {
            webThemeDir: 'WEB_THEME_DIRECTORY'
        },
        css: {
            '%itemsContainer': 'margin:auto;padding-bottom:10px;',
            '%graphicCounter': 'position:static;margin-top:10px;width:100%;text-align:center;',
            '%counter': 'position:absolute;bottom:6px;width:100%;text-align:center;',
            '%_buttons': 'display:block !important;position:absolute;left:10px;right:10px;top:50%;margin-top:-35px;',
            '%_buttons a':
                'width:40px;height:43px;background-image:url([webThemeDir]button/arrows-template-picker.gif);background-repeat:no-repeat;cursor:pointer;position:absolute;',
            '%buttonPrev': 'left:0;background-position:19px 0;',
            '%buttonNext': 'right:0;background-position:0 -130px;',
            '%buttonPrev:hover': 'left:0;background-position:19px -43px;',
            '%buttonNext:hover': 'right:0;background-position:0 -174px;',
            '%buttonPrev:active': 'left:0;background-position:19px -86px;',
            '%buttonNext:active': 'right:0;background-position:0 -81px;'
        }
    }

    return skins
})
