define([], function () {
    'use strict'

    return {
        layoutLimits: {
            minWidth: 26,
            minHeight: 26
        }
    }
})
