define(['lodash'], function (_) {
    'use strict'

    const deleteAppCallbacks = {}
    const addAppCallbacks = {}

    const registerOnAppDeleted = function (appDefId, callback) {
        if (_.isUndefined(deleteAppCallbacks[appDefId])) {
            deleteAppCallbacks[appDefId] = []
        }
        deleteAppCallbacks[appDefId].push(callback)
    }

    const clearOnAppDeleted = function (appDefId) {
        if (!_.isUndefined(deleteAppCallbacks[appDefId])) {
            deleteAppCallbacks[appDefId] = []
        }
    }

    const registerOnAppInstalled = function (appDefId, callback) {
        if (_.isUndefined(addAppCallbacks[appDefId])) {
            addAppCallbacks[appDefId] = []
        }
        addAppCallbacks[appDefId].push(callback)
    }

    const invokeDeleteAppCallbacks = function (appDefinitionId) {
        if (deleteAppCallbacks[appDefinitionId]) {
            _.forEach(deleteAppCallbacks[appDefinitionId], function (cb) {
                cb()
            })
        }
    }

    const invokeAddAppCallbacks = function (appDefinitionId, options) {
        if (addAppCallbacks[appDefinitionId]) {
            _.forEach(addAppCallbacks[appDefinitionId], function (cb) {
                cb(options)
            })
        }
    }

    return {
        registerOnAppDeleted,
        registerOnAppInstalled,
        clearOnAppDeleted,
        invokeDeleteAppCallbacks,
        invokeAddAppCallbacks
    }
})
