import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import PropTypes from 'prop-types'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import colorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/colorOption'
import mobileColorOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileColorOption'
import mobileTextOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/mobileTextOption'
import textOption from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/textOption'

function selectItem(itemData, event, domID) {
    if (this.props.onSelectionChange) {
        event.type = 'selectionChanged'
        event.payload = itemData
        this.props.onSelectionChange(event, domID)
    }
}
/**
 * @class components.OptionsListInput
 * @extends {core.skinBasedComp}
 * @property {{$validity: string}} state
 */
export default {
    displayName: 'OptionsListInput',
    mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

    propType: _.defaults(
        {
            itemClassName: PropTypes.string.isRequired,
            itemSkin: PropTypes.string.isRequired,
            selectedItem: PropTypes.object,
            // @ts-ignore
            valid: PropTypes.boolean,

            // Events
            // @ts-ignore
            onSelectionChange: PropTypes.function
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(colorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileColorOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(mobileTextOption),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(textOption)
    ),

    getInitialState() {
        return this.getCssState(this.props)
    },

    getCssState(props) {
        return {
            $validity: props.valid === false ? 'invalid' : 'valid'
        }
    },

    componentWillReceiveProps(props) {
        if (props.valid !== this.props.valid) {
            this.setState(this.getCssState(props))
        }
    },

    getSkinProperties() {
        const children = _.map(
            this.props.compData.options,
            function (itemData, index) {
                const extraProperties = {
                    selected: this.props.selectedItem === itemData,
                    onClick: selectItem.bind(this, itemData),
                    id: this.props.id + index,
                    ref: index
                }
                return this.createChildComponent(
                    itemData,
                    this.props.itemClassName,
                    {
                        //TODO: this should be in skin exports
                        skin: this.props.itemSkin,
                        styleId: coreUtils.santaTypes.shortenStyleId(this.props.itemSkin)
                    },
                    extraProperties
                )
            }.bind(this)
        )

        return {
            '': {
                children
            }
        }
    }
}
