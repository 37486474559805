import _ from 'lodash'
import * as tpaComponents from '../../../_internal/tpaComponents'
import * as utils from '@wix/santa-ds-libs/src/utils'

const biEventsMap = {}

const getEventDescriptor = function (appId) {
    return appId ? tpaComponents.bi.events.JS_SDK_FUNCTION_CALL : tpaComponents.bi.events.GALLERY_FUNCTION_CALL
}

const getPublished = function (appData, widgetId) {
    const {widgets} = appData
    const widget = widgets[widgetId]
    const isPublished = widget ? widget.published || widget.santaEditorPublished : appData.sectionPublished
    return isPublished ? 1 : 0
}

const getAppParams = function (msg, appsData) {
    const instance = tpaComponents.common.utils.getInstanceFromClientSpecMap(appsData, msg.appId)
    if (!appsData[msg.appId]) {
        throw new Error('app definition could not be found by the given appId')
    }
    return {
        appId: appsData[msg.appId].appDefinitionId,
        instanceId: instance.instanceId,
        isPublished: getPublished(appsData[msg.appId], msg.widgetId)
    }
}

const extendMsg = function (msg, compData) {
    msg.widgetId = _.get(compData, ['widgetId'])
    msg.appId = _.get(compData, ['applicationId'])
    return msg
}

const shouldSendBIEvent = function (msg, compData) {
    return compData && msg.version && msg.type && msg.namespace
}

const sendBIEvent = function (msg, origin, compData, clientSpecMap, reportBiFunc) {
    const extendedMsg = extendMsg(msg, compData)
    if (!shouldSendBIEvent(extendedMsg, compData)) {
        return
    }

    const applicationId = _.get(compData, ['applicationId'])
    let storedEvent = {
        count: 1,
        target: 1
    }

    if (_.has(biEventsMap, [applicationId, msg.type])) {
        storedEvent = _.get(biEventsMap, [applicationId, msg.type])
        ++storedEvent.count
    } else {
        if (!biEventsMap[applicationId]) {
            biEventsMap[applicationId] = {}
        }
        biEventsMap[applicationId][msg.type] = storedEvent
    }

    if (storedEvent.count === storedEvent.target) {
        const eventParams = {
            visitorUuid: tpaComponents.common.utils.getVisitorUuid(utils),
            sdkVersion: extendedMsg.version,
            origin,
            fnName: extendedMsg.type,
            param: JSON.stringify(msg.data),
            namespace: extendedMsg.namespace,
            count: storedEvent.count
        }

        if (extendedMsg.appId) {
            const appParams = getAppParams(extendedMsg, clientSpecMap)
            _.merge(eventParams, appParams)
        }

        const eventDescriptor = getEventDescriptor(extendedMsg.appId)
        reportBiFunc(eventDescriptor, eventParams)

        storedEvent.target *= 10
    }
}

export default {
    sendBIEvent
}
