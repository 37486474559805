define([
    'lodash',
    'platformInit',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/platform/services/platformUtils',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants'
], function (_, platformInit, clientSpecMapService, platformUtils, extensionsAPI, contextAdapter, dsConstants) {
    'use strict'

    const _onManifestAddedCallbacks = []
    const _onAppPublicApiSetCallbacks = []
    const _onAppPrivateApiSetCallbacks = []

    function resolveEditorScriptUrl(ps, appData) {
        const serviceTopology = extensionsAPI.data.getNoClone(ps, ps.pointers.general.getServiceTopology())
        return platformInit.specMapUtils.resolveEditorScriptUrl(appData, {clientSpec: appData, serviceTopology})
    }

    function getAppDataByAppDefId(ps, appDefId) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
        return resolveEditorScriptUrl(ps, appData)
    }

    function getAppDataByApplicationId(ps, applicationId) {
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        return resolveEditorScriptUrl(ps, appData)
    }

    function registerToManifestAdded(ps, cb) {
        _onManifestAddedCallbacks.push(cb)
        const appManifests = getAppManifests(ps)
        if (!_.isEmpty(appManifests)) {
            appManifests.forEach(function (appManifest) {
                cb(appManifest.manifest, appManifest.appData)
            })
        }
    }

    function getApiNames(ps, getNameFn) {
        const apps = clientSpecMapService.getAppsDataWithPredicate(ps, csm => _.filter(csm, app => hasAppManifest(ps, app.appDefinitionId)))
        return _.map(apps, function (app) {
            return {
                appDefinitionId: app.appDefinitionId,
                apiName: getNameFn(ps, app.appDefinitionId)
            }
        })
    }

    function getAppPublicApiName(ps, appDefinitionId) {
        const appPublicApiNamePointer = ps.pointers.platform.appPublicApiNamePointer(appDefinitionId)
        return ps.dal.get(appPublicApiNamePointer)
    }

    function getAppPrivateApiName(ps, appDefinitionId) {
        const appPrivateApiNamePointer = ps.pointers.platform.appPrivateApiNamePointer(appDefinitionId)
        return ps.dal.get(appPrivateApiNamePointer)
    }

    function getAppEditorApiName(ps, appDefinitionId) {
        const appEditorApiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
        return ps.dal.get(appEditorApiNamePointer)
    }

    function setAppPublicAPI(ps, appDefinitionId, apiName) {
        const publicApiNamePointer = ps.pointers.platform.appPublicApiNamePointer(appDefinitionId)
        ps.dal.set(publicApiNamePointer, apiName)
        _onAppPublicApiSetCallbacks.forEach(cb => cb({appDefinitionId, apiName}))
    }

    function setAppPrivateAPI(ps, appDefinitionId, apiName) {
        const privateApiNamePointer = ps.pointers.platform.appPrivateApiNamePointer(appDefinitionId)
        ps.dal.set(privateApiNamePointer, apiName)
        _onAppPrivateApiSetCallbacks.forEach(cb => cb({appDefinitionId, apiName}))
    }

    function setAppEditorAPI(ps, appDefinitionId, apiName) {
        const editorApiNamePointer = ps.pointers.platform.appEditorApiNamePointer(appDefinitionId)
        ps.dal.set(editorApiNamePointer, apiName)
    }

    function registerToPublicApiSet(ps, cb) {
        _onAppPublicApiSetCallbacks.push(cb)
        const apiNames = getApiNames(ps, getAppPublicApiName)
        if (!_.isEmpty(apiNames)) {
            apiNames.forEach(apiNameObj => cb(apiNameObj))
        }
    }

    function registerToPrivateApiSet(ps, cb) {
        _onAppPrivateApiSetCallbacks.push(cb)
        const apiNames = getApiNames(ps, getAppPrivateApiName)
        if (!_.isEmpty(apiNames)) {
            apiNames.forEach(apiNameObj => cb(apiNameObj))
        }
    }

    function getAppManifest(ps, appDefinitionId) {
        const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
        const r = ps.dal.get(appManifestPointer)
        return _.isEmpty(r) ? undefined : r
    }

    function hasAppManifest(ps, appDefinitionId) {
        const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
        return ps.dal.isExist(appManifestPointer)
    }

    function getAppManifests(ps) {
        const apps = clientSpecMapService.getAppsDataWithPredicate(ps, csm => _.filter(csm, app => hasAppManifest(ps, app.appDefinitionId)))
        return _(apps)
            .map(app => ({appData: app, manifest: getAppManifest(ps, app.appDefinitionId)}))
            .filter(appManfiest => !_.isNil(appManfiest.manifest))
            .value()
    }

    function setManifest(ps, appDefinitionId, manifest) {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.SET_MANIFEST, {extras: {appDefinitionId}})
        const appManifestPointer = ps.pointers.platform.getAppManifestPointer(appDefinitionId)
        const appData = getAppDataByAppDefId(ps, appDefinitionId)
        ps.dal.set(appManifestPointer, manifest)
        _.forEach(_onManifestAddedCallbacks, function (onManifestAdded) {
            onManifestAdded(manifest, appData)
        })
        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.SET_MANIFEST, {extras: {appDefinitionId}})
    }

    function isPlatformAppInstalled(ps, appDefinitionId) {
        const pagesPlatformApplicationsPointer = ps.pointers.platform.getPagesPlatformApplicationsPointer()
        const platformApps = ps.dal.get(pagesPlatformApplicationsPointer)

        const appData = getAppDataByAppDefId(ps, appDefinitionId)

        return platformUtils.isPlatformAppInstalled(platformApps, appData)
    }

    function setAppExportedAPIs(ps, appDefinitionId, apisNames = {}) {
        setAppPublicAPI(ps, appDefinitionId, apisNames.public)
        setAppPrivateAPI(ps, appDefinitionId, apisNames.private)
        setAppEditorAPI(ps, appDefinitionId, apisNames.editor)
    }

    return {
        registerToManifestAdded,
        setManifest,
        getAppManifest,
        getAppDataByAppDefId,
        getAppDataByApplicationId,
        isPlatformAppInstalled,
        hasAppManifest,
        registerToPublicApiSet,
        registerToPrivateApiSet,
        setAppExportedAPIs,
        getAppPublicApiName,
        getAppPrivateApiName,
        getAppEditorApiName
    }
})
