import _ from 'lodash'

function behaviorsListDataResolver(data) {
    const items = _.get(data, ['items'])
    if (_.isString(items)) {
        return _.assign({}, data, {items: JSON.parse(items)})
    }
    return data
}

export default {
    resolve: behaviorsListDataResolver
}
