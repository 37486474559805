import baseConfig from './baseConfig'

export default {
    shouldRender: true,
    runStylesGC: false,
    supportMobile: true,
    postUpdateOperation: false,
    disableAutoSave: true,
    modulesList: [
        'siteSegmentsPublicAPI',
        'pagePublicAPI',
        'themePublicAPI',
        'componentPublicAPI',
        'componentStylePublicAPI',
        'dataModelPublicAPI',
        'componentsMetaDataPublicAPI',
        'actionsAndBehaviorsPublicAPI',
        'siteMetadataPublicAPI',
        'structurePublicAPI',
        'documentModePublicAPI',
        'menuPublicAPI',
        'mobilePublicAPI',
        'appStudioPublicAPI'
    ],
    extensionsList: [
        ...baseConfig.extensionsList,
        'undoRedo',
        'serviceTopology',
        'metadata',
        'lifecycle',
        'contactFormMetaData',
        'variants',
        'appBuilder',
        'save',
        'commonConfig',
        'componentsByType'
    ]
}
