import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const inlinePopup = viewerLibrary.get('documentServices/inlinePopup/inlinePopup')
    return {
        methods: {
            inlinePopup: {
                setAlignment: publicMethodUtils.defineDataManipulationAction(inlinePopup.setAlignment)
            }
        }
    }
}
