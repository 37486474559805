import PropTypes from 'prop-types'
import _ from 'lodash'
import zoomProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/zoomProxy'

/**
 * @class proxies.ZoomLink
 * @extends proxies.mixins.zoomProxy
 */
export default {
    mixins: [zoomProxy],

    propTypes: {
        viewProps: PropTypes.shape({
            getPartDefinition: PropTypes.func.isRequired,
            getPartData: PropTypes.func.isRequired,
            getNormalizedDataPath: PropTypes.func.isRequired,
            getAppPartZoomUrl: PropTypes.func.isRequired,
            descriptor: PropTypes.object.isRequired
        })
    },

    getCustomProps() {
        const partDef = this.props.viewProps.getPartDefinition()
        const zoomPartDef = _.find(this.props.viewProps.descriptor.parts, {id: partDef.zoomPartName[0]})
        const partData = this.props.viewProps.getPartData()
        const permaLinkId = zoomPartDef.zoomParams.urlIdPrefix + partData.appInnerID

        const props: any = {}
        props.href = this.props.viewProps.getAppPartZoomUrl(permaLinkId, this.proxyData._iid || this.proxyData.id, this.proxyData.title)

        props.pathToItems = this.props.viewProps.getNormalizedDataPath(this.contextPath, this.getCompProp('listExpression')).join('.')
        return props
    }
}
