define(['lodash', '@wix/santa-ds-libs/src/coreUtils'], function (_, coreUtils) {
    'use strict'

    const HOOKS = {
        LAYOUT: {
            UPDATE_BEFORE: 'before_layout_update',
            UPDATE_AFTER: 'after_layout_update'
        },
        RESPONSIVE_LAYOUT: {
            BEFORE_UPDATE: 'responsive_layout_before_update'
        },
        COMPONENT: {
            STYLE_UPDATE_AFTER: 'after_style_update'
        },
        ADD: {
            BEFORE: 'before_add',
            AFTER: 'after_add',
            IS_OPERATION_ALLOWED: 'isOperationAllowed_add'
        },
        DATA: {
            UPDATE_BEFORE: 'data_update_before',
            UPDATE_AFTER: 'data_update_after',
            SET_BY_POINTER_AFTER: 'data_set_by_pointer_after',
            AFTER_GET: 'after_get_data',
            AFTER_UPDATE_CONNECTIONS: 'data_after_update_connections'
        },
        DESIGN: {
            UPDATE_BEFORE: 'design_update_before',
            UPDATE_AFTER: 'design_update_after'
        },
        MOBILE_HINTS: {
            UPDATE_BEFORE: 'mobile_hints_update_before'
        },
        SITE_BACKGROUND_UPDATE: {
            AFTER: 'after_site_background_update'
        },
        PROPERTIES: {
            UPDATE_BEFORE: 'properties_update_before',
            UPDATE_AFTER: 'properties_update_after'
        },
        BEHAVIORS: {
            UPDATE_AFTER: 'behaviors_update_after'
        },
        VARIANTS: {
            REMOVE_BEFORE: 'variants_remove_before',
            CHANGE_PARENT_AFTER: 'variants_change_parent_after'
        },
        ADD_ROOT: {
            BEFORE: 'before_add_root',
            AFTER: 'after_add_root',
            IS_OPERATION_ALLOWED: 'isOperationAllowed_add_root',
            GET_CONTAINER_TO_ADD_TO: 'get_container_to_add_to'
        },
        ADD_PAGE: {
            AFTER: 'after_add_page'
        },
        DUPLICATE_ROOT: {
            BEFORE: 'before_duplicate_root',
            AFTER: 'after_duplicate_root'
        },
        REMOVE: {
            BEFORE: 'before_remove',
            AFTER: 'after_remove',
            IS_OPERATION_ALLOWED: 'isOperationAllowed_remove'
        },
        GHOSTIFY: {
            AFTER: 'after_ghostify'
        },
        DUPLICATE: {
            BEFORE: 'before_duplicate',
            IS_OPERATION_ALLOWED: 'isOperationAllowed_duplicate'
        },
        DESERIALIZE: {
            BEFORE: 'before_deserialize'
        },
        SET_SCOPED_VALUE: {
            BEFORE: 'before_set_scoped_value'
        },
        NS_ITEM: {
            GET_QUERY_ID: 'get_query_id'
        },
        SERIALIZE: {
            SET_CUSTOM: 'set_custom_serialize',
            CHILDREN_BEFORE: 'before_serialize_children',
            DATA_AFTER: 'after_serialize_comp_data'
        },
        CHANGE_PARENT: {
            BEFORE: 'before_change_parent',
            AFTER: 'after_change_parent'
        },
        CHANGE_COMPONENT_VIEW_MODE: {
            BEFORE: 'before_change_component_view_mode',
            AFTER: 'after_change_component_view_mode'
        },
        SWITCH_VIEW_MODE: {
            AFTER: 'after_switch_view_mode',
            MOBILE: 'switch_to_mobile_view'
        },
        MOBILE_CONVERSION: {
            BEFORE: 'before_mobile_conversion',
            AFTER: 'after_mobile_conversion'
        },
        MOBILE_MERGE: {
            BEFORE: 'before_mobile_merge'
        },
        RELAYOUT_MOBILE_PAGE: {
            AFTER: 'after_relayout_page'
        },
        PUBLISH: {
            BEFORE: 'before_site_publish'
        },
        AUTOSAVE: {
            ACTION: 'autosave_action',
            CSAVE_NON_RECOVERABLE_ERROR: 'CSAVE_NON_RECOVERABLE_ERROR'
        },
        SAVE: {
            SITE_SAVED: 'site_saved',
            VALIDATION_ERROR: 'validation_error'
        },
        WIX_CODE: {
            FLUSH: 'wixcode_flush',
            SET_NICKNAME_BEFORE: 'wixcode_set_nickname_before',
            SET_NICKNAME_AFTER: 'wixcode_set_nickname_after',
            FILE_CONTENT_CHANGED: 'wixcode_file_content_changed',
            FILE_OR_FOLDER_CHANGED: 'wixcode_file_or_folder_changed',
            CONCURRENT_FILES_CHANGED: 'wixcode_concurrent_files_changed'
        },
        METADATA: {
            MOVE_DIRECTIONS: 'meta_data_move_directions',
            REMOVABLE: 'meta_data_removable',
            DUPLICATABLE: 'meta_data_duplicatable',
            CAN_REPARENT: 'meta_data_can_reparent',
            CAN_BE_STRETCHED: 'meta_data_can_be_stretched',
            RESIZABLE_SIDES: 'meta_data_resizable_sides',
            ROTATABLE: 'meta_data_rotatable',
            FIXED_POSITION: 'meta_data_fixed_position',
            CONTAINABLE: 'meta_data_containable',
            LAYOUT_LIMITS: 'meta_data_layout_limits',
            SHOULD_AUTO_SET_NICKNAME: 'meta_data_should_auto_set_nickname',
            A11Y_CONFIGURABLE: 'meta_data_a11y_configurable',
            MAXIMUM_CHILDREN_NUMBER: 'meta_data_maximum_children_number',
            HIDE_AS_GHOST: 'hideAsGhost'
        },
        MULTILINGUAL: {
            AFTER_CHANGE_LANGUAGE: 'after_change_language'
        },
        PLATFORM: {
            APP_PROVISIONED: 'platform_app_provisioned',
            APP_UPDATED: 'platform_app_updated',
            AFTER_CONTROLLERS_SET_STATE: 'after_platform_controllers_set_state'
        },
        CONNECTION: {
            AFTER_DISCONNECT: 'after_connection_disconnect',
            AFTER_CONNECT: 'after_connect'
        },
        ADD_TPA: {
            AFTER: 'after_tpa_app_add',
            COMPONENT_DEFINITION_MODIFIER: 'component_definition_modifier_before_add_tpa'
        },
        PAGE: {
            AFTER_NAVIGATE_TO_PAGE: 'after_navigate_to_page',
            AFTER_NAVIGATE_TO_PAGE_DONE: 'after_navigate_to_page_done'
        },
        UNDO_REDO: {
            AFTER_APPLY_SNAPSHOT: 'after_apply_snapshot'
        },
        REMOVE_COMP_MODE: {
            BEFORE: 'before_remove_comp_mode'
        },
        ROUTER: {
            DATA_RELOADED: 'router_data_reloaded',
            BEFORE_UPDATE: 'router_before_update',
            BEFORE_REMOVE: 'router_before_remove',
            AFTER_ADD: 'router_after_add',
            CONCURRENT_ROUTER_INVALIDATION: 'concurrent_router_invalidation'
        },
        WIXCODE: {
            UPDATE_MODEL: 'wix_code_update_model'
        }
    }

    const VALIDATION_MAP = _(HOOKS)
        .flatMap(function (hook) {
            return _.flatMap(hook)
        })
        .reduce(function (validationMap, hookName) {
            validationMap[hookName] = true
            return validationMap
        }, Object.create(null))

    function validateHookName(hookName) {
        if (!VALIDATION_MAP[hookName]) {
            throw new Error(`Invalid Hook Name ${hookName}`)
        }
    }

    function createHooksHandler() {
        const hooks = Object.create(null)
        const state = {isRunningRecursiveHooksAllowed: true}

        /**
         * @param {string} hookName
         * @param {string} compType
         * @param callback
         * @param {boolean} isExternal
         * @return {*}
         */
        function setHook(hookName, compType = '', callback, isExternal = false) {
            // eslint-disable-next-line prefer-rest-params
            coreUtils.wSpy.logCallBackRegistration(callback, 'setHook', [...arguments], 'at setHook')
            if (!callback) {
                return
            }

            let hook = hooks[hookName]
            if (!hook) {
                hooks[hookName] = hook = Object.create(null)
            }

            let hooksForComponentType = hook[compType]
            if (!hooksForComponentType) {
                hook[compType] = hooksForComponentType = []
            } else if (isExternal && _.find(hooksForComponentType, 'isExternal')) {
                return // not more than one external hook per type per compType
            }

            const newHook = {func: callback, isExternal}
            const hookAlreadyRegistered = _(hooksForComponentType)
                .map('func')
                .some(func => callback === func)
            if (!hookAlreadyRegistered) {
                hooksForComponentType.push(newHook)
            }

            return callback
        }

        /**
         * Unregister only hooks that are passed
         * @param {string|string[]} _hooksToRemove - hook name or array of hooks names
         */
        function unregisterHooks(_hooksToRemove) {
            const hooksToRemove = _.isArray(_hooksToRemove) ? _hooksToRemove : [_hooksToRemove]

            _.forEach(hooksToRemove, function (hookName) {
                hooks[hookName] = {}
            })
        }

        function unregisterComponentHook(hookName, compType) {
            if (hooks[hookName] && hooks[hookName][compType]) {
                hooks[hookName][compType] = []
            }
        }

        function unregisterHook(hookName, hookReference, compType) {
            compType = compType || ''
            if (hooks[hookName] && hooks[hookName][compType]) {
                const hookedList = hooks[hookName][compType]
                _.remove(hookedList, ['func', hookReference])
            }
        }

        /**
         * Register callback to run the function specified in when executing the hook specified in the hookName parameter according to the hook type.
         * @param {string} hookName The name of the hook to registered (i.e HOOKS.ADD.BEFORE)
         * @param {function} callback function to execute.
         * @param {string=} compType The component type that will use the callback while running the operation according to the hookType.
         */
        function registerHook(hookName, callback, compType) {
            validateHookName(hookName)
            return setHook(hookName, compType, callback, false)
        }

        /**
         * @param {string} hookName
         * @param callback
         * @param {string} compType
         */
        function registerExternalHook(hookName, callback, compType) {
            validateHookName(hookName)
            setHook(hookName, compType, callback, true)
        }

        /**
         * @param {string} hookName
         * @param {string?} compType
         * @param executeHookFunction
         */
        function runHooksExecution(hookName, compType, executeHookFunction) {
            validateHookName(hookName)
            if (state.isRunningRecursiveHooksAllowed) {
                const hook = hooks[hookName]
                if (hook) {
                    if (compType) {
                        _.forEach(hook[compType], executeHookFunction)
                    }
                    _.forEach(hook[''], executeHookFunction)
                }
            }
        }

        /**
         * @param hook
         * @param args
         * @return {*}
         */
        function applyHook(hook, args) {
            if (hook.isExternal) {
                state.isRunningRecursiveHooksAllowed = false
            }
            // eslint-disable-next-line prefer-rest-params
            coreUtils.wSpy.logCallBackExecution(hook.func, 'applyHook', [...arguments])
            // @ts-ignore
            const callbackReturnValue = hook.func.apply(this, args)
            state.isRunningRecursiveHooksAllowed = true
            return callbackReturnValue
        }

        /**
         * Execute all callbacks of the specified hook for the specified component.
         * @param {string} hookName The name of the hook to execute (i.e HOOKS.ADD.BEFORE).
         * @param {string?} compType The component type or none to execute only hooks that were registered without a component type.
         * @param {Array?} args The arguments that will be passed to the hook callbacks.
         * @param {function(object) : boolean?} stopCondition stop running callbacks if one of the callbacks return value apply to the condition.
         * @returns {boolean} true if none of the callbacks return value returns true to the stopCondition.
         */
        function executeHooksAndReturnIfAllPassed(hookName, compType, args, stopCondition = _.noop) {
            let stopped = false
            function executeHook(hook) {
                const callbackReturnValue = applyHook(hook, args)

                if (stopped || stopCondition(callbackReturnValue)) {
                    stopped = true
                    return false
                }
            }
            runHooksExecution(hookName, compType, executeHook)

            return !stopped
        }

        /**
         * @param {string} hookName
         * @param {string} compType
         * @param args
         * @return {*[]}
         */
        function executeHooksAndCollectValues(hookName, compType, args) {
            const values = []
            function executeHook(hook) {
                const callbackReturnValue = applyHook(hook, args)

                if (!_.isUndefined(callbackReturnValue)) {
                    values.push(callbackReturnValue)
                }
            }
            runHooksExecution(hookName, compType, executeHook)

            return values
        }

        /**
         * @param {ps} ps
         * @param {string} hookName
         * @param {string} compType
         * @param additionalArgs
         * @param value
         * @return {*}
         */
        function executeHookAndUpdateValue(ps, hookName, compType, additionalArgs, value) {
            let curResultValue = _.cloneDeep(value)
            function executeHook(hook) {
                curResultValue = applyHook(hook, [ps, curResultValue].concat(additionalArgs))
            }
            runHooksExecution(hookName, compType, executeHook)

            return curResultValue
        }

        return {
            HOOKS,
            unregisterHooks,
            unregisterComponentHook,
            unregisterHook,
            registerHook,
            registerExternalHook,
            executeHook: executeHooksAndReturnIfAllPassed,
            executeHooksAndCollectValues,
            executeHookAndUpdateValue
        }
    }

    /**
     * @exports documentServices/hooks/hooks
     */
    return {
        createHandler: createHooksHandler
    }
})
