define(['documentServices/variants/relationsUtils', 'documentServices/extensionsAPI/extensionsAPI'], function (relationsUtils, extensionsAPI) {
    'use strict'

    function removeUnknownDataNodeVariantsAndData(ps) {
        if (!ps.pointers.variants) {
            return
        }

        const allPages = extensionsAPI.pages.getAllPagesIds(ps)

        allPages.forEach(pageId => {
            const corruptedVariants = ps.pointers.data.getVariantItemsWithPredicate(x => x.type === 'UnknownDataNode', pageId)

            if (corruptedVariants.length > 0) {
                relationsUtils.removeScopedValuesByVariants(ps, corruptedVariants)
            }
        })
    }

    return {
        exec(ps) {
            removeUnknownDataNodeVariantsAndData(ps)
        },
        name: 'unknownTypeVariantsFixer',
        version: 1
    }
})
