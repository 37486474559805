import _ from 'lodash'

const trackingReporter = {
    register(tr) {
        _.assign(trackingReporter, tr)
    },
    reportDuration() {}
}

export default trackingReporter
