import _ from 'lodash'
import layout from '../util/layout'
import mediaContainerLayout from './mediaContainerLayout'
import balataLayout from './balataLayout'

const CONTAINER_POSTFIX = 'container'
const CONTENT_PARENT_POSTFIX = 'inlineContentParent'
const CONTENT_POSTFIX = 'inlineContent'
const OVERLAY_POSTFIX = 'overlay'

function isVerticalInlinePopup(structureInfo) {
    return _.has(structureInfo, 'layout.docked.top') && _.has(structureInfo, 'layout.docked.bottom')
}

function isHorizontalInlinePopup(structureInfo) {
    return _.has(structureInfo, 'layout.docked.left') && _.has(structureInfo, 'layout.docked.right')
}

function resetMeasures(measureMap, id) {
    if (measureMap.shrinkableContainer[id]) {
        delete measureMap.shrinkableContainer[id]
    }

    if (measureMap.minHeight[id]) {
        delete measureMap.minHeight[id]
    }
}

function measureInlinePopup(id, measureMap, nodesMap, structureInfo) {
    mediaContainerLayout.measure(id, measureMap, nodesMap, structureInfo)
    resetMeasures(measureMap, id)
    if (isVerticalInlinePopup(structureInfo)) {
        measureMap.height[id] = measureMap.minHeight[id] = measureMap.height.screen
        measureMap.shrinkableContainer[id] = true
    }

    if (isHorizontalInlinePopup(structureInfo)) {
        measureMap.width[id] = nodesMap[id].clientWidth || measureMap.width[id]
    }

    measureMap.custom[id] = measureMap.custom[id] || {}

    if (measureMap.height[id]) {
        _.set(measureMap, ['relativeToScreenOverrides', id, 'height'], measureMap.height[id])
    }
}

function patchInlinePopup(id, patchers, measureMap, structureInfo, siteData) {
    const wixAdsHeight = _.get(measureMap, ['height', 'WIX_ADStop'], 0)

    mediaContainerLayout.patch(id, patchers, measureMap, structureInfo, siteData)

    if (siteData.isMobileView()) {
        patchers.css(id + OVERLAY_POSTFIX, {
            height: '100vh',
            width: measureMap.width.screen,
            left: `calc((100% - ${measureMap.width.screen}px) / 2)`
        })

        if (isHorizontalInlinePopup(structureInfo)) {
            patchers.css(id, {
                left: `calc((100% - ${measureMap.width.screen}px) / 2)`
            })
        }
    }

    if (isVerticalInlinePopup(structureInfo)) {
        patchers.css(id + CONTENT_PARENT_POSTFIX, {
            height: measureMap.height.screen - wixAdsHeight
        })

        patchers.css(id + CONTENT_POSTFIX, {
            height: measureMap.height[id] - wixAdsHeight,
            marginTop: `${wixAdsHeight}px`
        })

        patchers.css(id, {
            height: siteData.isMobileDevice() ? '100vh' : measureMap.minHeight[id],
            top: 0
        })
    }
}

const registeredComponentTypes = ['wysiwyg.viewer.components.MenuContainer']

_.forEach(registeredComponentTypes, function (compType) {
    layout.registerCustomMeasure(compType, measureInlinePopup)
    layout.registerPureDomHeightMeasure(compType)
    layout.registerRequestToMeasureChildren(
        compType,
        balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE.concat([[CONTAINER_POSTFIX], [CONTENT_PARENT_POSTFIX], [CONTENT_POSTFIX], [OVERLAY_POSTFIX]])
    )
    layout.registerPatcher(compType, patchInlinePopup)
})
