define(['lodash', 'documentServices/menu/menuUtils', 'documentServices/utils/contextAdapter'], function (_, menuUtils, contextAdapter) {
    'use strict'

    const getItem = (ps, itemId) => ps.dal.full.get(ps.pointers.data.getDataItemFromMaster(itemId))
    const updateItem = (ps, itemId, item) => ps.dal.full.set(ps.pointers.data.getDataItemFromMaster(itemId), item)

    function fixCrappyReferences(ps, menuPointer) {
        const reverse_refs = {} //<itemId, parentId>
        const toFix = {} //<parent, itemQueriesToRemove>
        const missingReferences = {} //<parent, itemQueriesToRemove>
        let item
        let itemId
        const items = [menuPointer.id]
        while (items.length) {
            itemId = menuUtils.sanitizeHash(items.shift())
            item = getItem(ps, itemId)
            //eslint-disable-next-line no-loop-func
            item.items.forEach(query => {
                if (!getItem(ps, menuUtils.sanitizeHash(query))) {
                    missingReferences[itemId] = missingReferences[itemId] || []
                    missingReferences[itemId].push(query)
                } else {
                    if (reverse_refs[query]) {
                        toFix[itemId] = toFix[itemId] || []
                        toFix[itemId].push(query)
                    }
                    reverse_refs[query] = itemId
                    items.push(query)
                }
            })
        }
        _.forEach([toFix, missingReferences], invalidStuffToFix => {
            _.forOwn(invalidStuffToFix, (itemQueriesToRemove, parentId) => {
                const parentItem = getItem(ps, parentId)
                _.pullAll(parentItem.items, itemQueriesToRemove)
                updateItem(ps, parentId, parentItem)
            })
        })
        if (!_.isEmpty(missingReferences)) {
            contextAdapter.utils.fedopsLogger.captureError(new Error('Missing references to menu items'), {
                tags: {
                    menuReferencesFixer: true
                },
                extras: {
                    missingItems: {
                        [menuPointer.id]: missingReferences
                    }
                }
            })
        }
    }

    return {
        exec(ps) {
            const allMenuPointers = menuUtils.getAllMenuPointers(ps)
            _.forEach(allMenuPointers, menuPointer => {
                fixCrappyReferences(ps, menuPointer) //crappy = double or missing
            })
        },
        name: 'menuReferencesFixer',
        version: 1
    }
})
