import type {PS} from '@wix/document-services-types'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {PageExtensionAPI} from '@wix/document-manager-extensions/src/extensions/page'

import _ from 'lodash'

const createSiteData = (viewerManager: ViewerManager, ps: PS, extensionAPI: ExtensionAPI) => ({
    isQaMode: ps.siteAPI.isDebugMode,
    getViewMode: viewerManager.viewerSiteAPI.getViewMode,
    isInSSR: () => false,
    currentUrl: viewerManager.viewerSiteAPI.getCurrentUrl(),
    getRootNavigationInfo: viewerManager.viewerSiteAPI.getRootNavigationInfo,
    anchorsMap: {},
    getClientSpecMap: () => ps.dal.get(ps.pointers.general.getClientSpecMap()),
    getMainPageId: () => (extensionAPI as PageExtensionAPI).page.getMainPageId(),
    removeResolvedDataMapForPage: _.noop,
    getDataByQuery: (query: string, pageId: string, dataType: string) => {
        const dataQueryPointer = ps.pointers.data.getDataByQueryPointer(query, pageId, dataType)
        return ps.dal.get(dataQueryPointer)
    },
    removeGhostStructureData: _.noop,
    animations: viewerManager.viewerSiteAPI.getSiteAnimationsService()
})

export {createSiteData}
