define([
    'lodash',
    'documentServices/appControllerData/appControllerData',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/component/component',
    'documentServices/component/componentCode'
], function (_, appControllerData, actionsAndBehaviors, component, componentCode) {
    'use strict'

    const UPDATABLE_LAYOUT_PROPS = ['width', 'height']
    const UPDATABLE_SETTINGS = ['variationPageId', 'structureVersion']
    const NON_UPDATABLE_ROOT_ATTRIBUTES = ['connections', 'behaviors']

    function preserveRootInvariants(ps, widgetRef, widgetStructure, newAppWidgetStructure) {
        const layout = component.layout.get(ps, widgetRef)
        _.assign(newAppWidgetStructure.layout, _.omit(layout, UPDATABLE_LAYOUT_PROPS))
        _.assign(newAppWidgetStructure, _.pick(widgetStructure, NON_UPDATABLE_ROOT_ATTRIBUTES))
    }

    function preserveInvariantSettings(ps, widgetRef, newAppWidgetStructure) {
        const currentSettings = appControllerData.getSettings(ps, widgetRef)
        const newSettings = JSON.parse(newAppWidgetStructure.data.settings)
        _.assign(newSettings, _.omit(currentSettings, UPDATABLE_SETTINGS))

        newAppWidgetStructure.data.settings = JSON.stringify(newSettings)
    }

    const flattenCompTree = comp => _.union([comp], _.flatMap(comp.components, flattenCompTree))

    const getStaticEventBehaviors = behaviors => {
        const behaviorItems = _.get(behaviors, 'items')
        if (behaviorItems) {
            const parsedBehaviorItems = JSON.parse(behaviorItems)
            return _.filter(parsedBehaviorItems, ({behavior}) => actionsAndBehaviors.isCodeBehavior(behavior))
        }
    }

    function createRoleToAttributesMap(ps, widgetRef, widgetStructure) {
        const childrenStructures = flattenCompTree(_.head(widgetStructure.components))

        return _(childrenStructures)
            .map(componentStructure => {
                const connectionItems = _.get(componentStructure, ['connections', 'items'])
                const {role = null} = componentCode.findSerializedConnectionByContext(ps, widgetRef, connectionItems) || {}
                const wixCodeConnectionItem = componentCode.findSerializedConnectionByContext(ps, null, connectionItems)

                return {
                    role,
                    wixCodeConnectionItem,
                    behaviors: componentStructure.behaviors
                }
            })
            .filter(({role}) => !_.isNil(role))
            .keyBy('role')
            .value()
    }

    function preserveInvariants(ps, widgetRef, newAppWidgetStructure) {
        const currentWidgetStructure = component.serialize(ps, widgetRef)
        const compsAttributeMap = createRoleToAttributesMap(ps, widgetRef, currentWidgetStructure)
        preserveRootInvariants(ps, widgetRef, currentWidgetStructure, newAppWidgetStructure)
        preserveInvariantSettings(ps, widgetRef, newAppWidgetStructure)
        preserveInvariantsRecursive(ps, compsAttributeMap, _.head(newAppWidgetStructure.components))
    }

    function preserveNickname(newComponentStructure, componentAttributes) {
        const {wixCodeConnectionItem} = componentAttributes
        const connectionItems = newComponentStructure.connections.items
        newComponentStructure.connections.items = [wixCodeConnectionItem, ...connectionItems]
    }

    function concatBehaviors(newBehaviors, currentBehaviorItems) {
        const newBehaviorItems = JSON.parse(newBehaviors.items)
        return JSON.stringify(_.compact(_.concat(newBehaviorItems, currentBehaviorItems)))
    }

    function preserveStaticEvents(newComponentStructure, componentAttributes) {
        const {behaviors} = componentAttributes
        if (newComponentStructure.behaviors) {
            const staticEventBehaviors = getStaticEventBehaviors(behaviors)
            const newBehaviors = newComponentStructure.behaviors
            newBehaviors.items = concatBehaviors(newBehaviors, staticEventBehaviors)
            newComponentStructure.behaviors = newBehaviors
        } else {
            newComponentStructure.behaviors = behaviors
        }
    }

    function findPrimaryRole(componentStructure) {
        const connectionItems = _.get(componentStructure, 'connections.items')
        const primaryConnectionItem = _.find(connectionItems, {isPrimary: true})

        return _.get(primaryConnectionItem, 'role')
    }

    function preserveComponentInvariantsByRole(componentStructure, attributeMap, role) {
        const componentAttributes = attributeMap[role]
        if (componentAttributes) {
            preserveNickname(componentStructure, componentAttributes)
            preserveStaticEvents(componentStructure, componentAttributes)
        }
    }

    function preserveInvariantsRecursive(ps, attributeMap, componentStructure) {
        const primaryRole = findPrimaryRole(componentStructure)
        if (primaryRole) {
            preserveComponentInvariantsByRole(componentStructure, attributeMap, primaryRole)
        }
        _.forEach(componentStructure.components, childStructure => preserveInvariantsRecursive(ps, attributeMap, childStructure))
    }

    function findComponentIndex(ps, {id}, parentRef) {
        const children = component.getChildren(ps, parentRef)
        return _.findIndex(children, {id})
    }

    function switchAppWidgetStructure(ps, widgetRef, newWidgetRef, newAppWidgetStructure) {
        const updatedWidgetStructure = _.cloneDeep(newAppWidgetStructure)
        preserveInvariants(ps, widgetRef, updatedWidgetStructure)

        const parentRef = ps.pointers.components.getParent(widgetRef)
        const widgetIndexInContainer = findComponentIndex(ps, widgetRef, parentRef)
        component.remove(ps, widgetRef)
        component.add(ps, newWidgetRef, parentRef, updatedWidgetStructure, undefined, widgetIndexInContainer)
    }

    return {
        switchAppWidgetStructure
    }
})
