define([
    'lodash',
    'documentServices/multilingual/multilingual',
    'documentServices/dataModel/dataModel',
    'documentServices/hooks/hooks',
    'documentServices/menu/basicMenuItemMethods',
    'documentServices/menu/menuUtils',
    'documentServices/page/pageUtils',
    'documentServices/page/pageData',
    'documentServices/page/popupUtils',
    'documentServices/constants/constants',
    'documentServices/siteMetadata/language',
    'documentServices/utils/multilingual',
    'documentServices/tpa/tpa',
    'documentServices/extensionsAPI/extensionsAPI'
], function (
    _,
    multilingual,
    dataModel,
    hooks,
    basicMenuItemMethods,
    menuUtils,
    pageUtils,
    pageDataModule,
    popupUtils,
    constants,
    language,
    mlUtils,
    tpa,
    extensionsAPI
) {
    'use strict'

    const {PointerOperation} = multilingual
    const {DATA_TYPES} = constants

    initialize()

    const CUSTOM_MAIN_MENU = 'CUSTOM_MAIN_MENU'

    function initialize() {
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, menuHookDeletePage, 'mobile.core.components.Page')
        hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, menuHookDeletePage, 'wixapps.integration.components.AppPage')
        hooks.registerHook(hooks.HOOKS.ADD.BEFORE, updateMenuRefData, 'wysiwyg.viewer.components.menus.DropDownMenu')
        hooks.registerHook(hooks.HOOKS.ADD.BEFORE, updateMenuRefData, 'wysiwyg.viewer.components.ExpandableMenu')
        hooks.registerHook(hooks.HOOKS.ADD.BEFORE, updateMenuRefData, 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu')

        pageUtils.registerPageDataChangedCallback(pageDataChanged)
        pageUtils.registerPageAddedCallback(newPageAdded)
    }

    function isReferenceToCustomMenuData(ps, dataRef, pageId) {
        if (!_.isString(dataRef) || dataRef[0] !== '#') {
            return false
        }
        const data = dataModel.getDataItemById(ps, menuUtils.sanitizeHash(dataRef), pageId)
        return _.get(data, 'type') === 'CustomMenu'
    }

    function updateMenuRefData(ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
        let menuRef = _.get(compDefinitionPrototype, 'data.menuRef')
        const pageIdOfComponent = ps.pointers.components.getPageOfComponent(containerPointer).id
        if (menuRef) {
            compDefinitionPrototype.data.type = 'CustomMenuDataRef'
            if (!isReferenceToCustomMenuData(ps, menuRef, pageIdOfComponent)) {
                compDefinitionPrototype.data.menuRef = `#${CUSTOM_MAIN_MENU}`
            }
        } else {
            menuRef = compDefinitionPrototype.dataQuery
            compDefinitionPrototype.data = {
                menuRef: isReferenceToCustomMenuData(ps, menuRef, pageIdOfComponent) ? menuRef : `#${CUSTOM_MAIN_MENU}`,
                type: 'CustomMenuDataRef'
            }
            delete compDefinitionPrototype.dataQuery
        }
    }

    function getAllBasicMenuItemsForMenu(ps, menuPointer) {
        const menuData = dataModel.getDataItemById(ps, menuPointer.id, 'masterPage')
        return _(menuData.items).flatMap('items').concat(menuData.items).compact().value()
    }

    const getAllSyncableMenuPointers = ps => {
        const syncableMenuPointers = menuUtils.getMenusByFilter(ps, {syncWithPages: true})
        return [ps.pointers.data.getDataItemFromMaster(CUSTOM_MAIN_MENU)].concat(syncableMenuPointers)
    }

    function updatePageItemFromTranslatedMenu(ps, allSyncableMenus, lang, pageId, useLanguage, changedData, pageData) {
        _.forEach(allSyncableMenus, menuPointer => {
            const menuId = menuPointer.id
            const menuData = menuUtils.getFlatMenuWithMetaData(ps, menuId, lang)
            const itemsToUpdate = _.pickBy(menuData, _.matches({link: {value: {type: 'PageLink', pageId: `#${pageId}`}}}))
            _.forEach(itemsToUpdate, ({pointer}) => {
                menuHookChangePageData(ps, pointer, changedData, useLanguage, pageData)
            })
        })
    }

    function pageDataChanged(ps, pageId, changedData, useOriginalLanguage = false, applyChangeToAllLanguages = false) {
        if (!popupUtils.isPopup(ps, pageId)) {
            const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
            const pageData = pageDataModule.getPageData(ps, menuUtils.sanitizeHash(pageId))
            const translationLanguages = language.getTranslationLanguageCodes(ps)
            const allSyncableMenus = getAllSyncableMenuPointers(ps)
            if (applyChangeToAllLanguages) {
                _.flatMap(translationLanguages, lang =>
                    updatePageItemFromTranslatedMenu(ps, allSyncableMenus, lang, pageId, useLanguage, changedData, pageData)
                )
            }
            const allBasicMenuItems = _.flatMap(allSyncableMenus, menuPointer => getAllBasicMenuItemsForMenu(ps, menuPointer))
            _.forEach(allBasicMenuItems, basicMenuItemData => {
                const basicMenuItemPointer = ps.pointers.data.getDataItemFromMaster(basicMenuItemData.id)
                if (basicMenuItemData.link) {
                    const linkData = basicMenuItemData.link
                    if (linkData.type === 'PageLink' && linkData.pageId && linkData.pageId === `#${pageId}`) {
                        menuHookChangePageData(ps, basicMenuItemPointer, changedData, useLanguage, pageData)
                    }
                }
            })
        }
    }

    function newPageAdded(ps, pageId, shouldCreatePageMenuItem = true) {
        if (!popupUtils.isPopup(ps, pageId) && shouldCreatePageMenuItem) {
            menuHookAddPage(ps, pageId)
        }
    }

    /*********/
    function menuHookChangePageData(ps, basicMenuItemPointer, changedData, useLanguage, pageData) {
        const BMI = ps.dal.full.get(basicMenuItemPointer)
        const modifiedMenuItemData = _.reduce(
            changedData,
            function (res, value, key) {
                switch (key) {
                    case 'title':
                        res.label = value
                        break
                    case 'hidePage':
                        res.isVisible = !value
                        if (_.isNil(pageData.mobileHidePage)) {
                            res.isVisibleMobile = !value
                        }
                        break
                    case 'mobileHidePage':
                        res.isVisibleMobile = !value
                        break
                }
                return res
            },
            {}
        )

        basicMenuItemPointer.multilingual = PointerOperation.SET
        basicMenuItemPointer.useLanguage = useLanguage

        dataModel.setDataItemByPointer(ps, basicMenuItemPointer, _.assign(BMI, modifiedMenuItemData), 'data')
    }

    function menuHookAddPage(ps, pageId) {
        //page node and data ids the same (if we fix it, this will stop working)
        const pageDataPointer = ps.pointers.data.getDataItemFromMaster(pageId)
        pageDataPointer.useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
        const pageData = ps.dal.get(pageDataPointer)

        addPageItem(ps, `#${pageData.id}`, pageData.title, pageData.hidePage, pageData.mobileHidePage)
        return {success: true}
    }

    function deleteItemFromParent(ps, itemId, parentItemId) {
        const itemPointer = menuUtils.getMenuDataItemPointer(ps, itemId)

        const item = ps.dal.get(itemPointer)
        const subItems = item.items

        const parentPointer = menuUtils.getMenuDataItemPointer(ps, parentItemId)
        const parent = ps.dal.get(parentPointer)
        const parentItems = parent.items

        const itemIndex = parentItems.indexOf(`#${itemId}`)
        parentItems.splice(itemIndex, 1)
        if (subItems) {
            parentItems.splice(itemIndex, 0, ...subItems)
        }

        parentPointer.useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')

        ps.dal.set(parentPointer, parent)
        ps.dal.remove(itemPointer)
    }

    function removePageItemFromMenu(ps, menuPtr, pageId) {
        const menuToDeleteFrom = dataModel.getDataByPointer(ps, DATA_TYPES.data, menuPtr)
        const itemIdAndParent = menuUtils.getItemIdAndParent(_.pick(menuToDeleteFrom, ['items', 'id']), {
            type: 'PageLink',
            pageId: `#${pageId}`
        })

        if (itemIdAndParent) {
            deleteItemFromParent(ps, itemIdAndParent.itemId, itemIdAndParent.parentId)
        }
    }

    function removePageItemFromTranslatedMenu(ps, menuId, lang, pageId) {
        const menuData = menuUtils.getFlatMenuWithMetaData(ps, menuId, lang)
        const itemsToRemove = _.pickBy(menuData, _.matches({link: {value: {type: 'PageLink', pageId: `#${pageId}`}}}))
        const pointersToRemove = []
        _.forEach(itemsToRemove, ({pointer, value, parent, isTranslatedItem, id, link}) => {
            if (isTranslatedItem) {
                //technically this could have been removed now, as this is a translated item and the removal would not conflict with anything
                pointersToRemove.push(pointer)
            }
            if (link) {
                // The link, however, is usually not translated, so we must only remove it at the very end.
                // So we remove all pointers (including items) at the end, for consistency
                pointersToRemove.push(link.pointer)
            }
            const parentItem = menuData[parent]
            if (parentItem && parentItem.isTranslatedItem) {
                const parentItems = parentItem.value.items
                const itemIndex = parentItems.indexOf(`#${id}`)
                parentItems.splice(itemIndex, 1)
                const subItems = value.items
                if (subItems.length) {
                    parentItems.splice(itemIndex, 0, ...subItems)
                }
                ps.dal.set(parentItem.pointer, parentItem.value)
            }
        })
        return pointersToRemove
    }

    function menuHookDeletePage(ps, pageComponentPointer) {
        const {id: pageId} = pageComponentPointer
        const allMenuPointers = menuUtils.getAllMenuPointers(ps)
        _.forEach(allMenuPointers, menuPtr => {
            const presentLanguages = extensionsAPI.multilingualTranslations.getPresentLanguages(ps, menuPtr.id)
            const pointersToRemove = _.flatMap(presentLanguages, lang => removePageItemFromTranslatedMenu(ps, menuPtr.id, lang, pageId))
            removePageItemFromMenu(ps, menuPtr, pageId)
            _.uniqBy(pointersToRemove, ptr => `${ptr.type}**${ptr.id}`).forEach(ptr => {
                if (ps.dal.isExist(ptr)) {
                    ps.dal.remove(ptr)
                }
            })
        })

        return {success: true}
    }

    function addHeaderItem(ps, dataId, label, parentId, hideItem, hideItemMobile) {
        return addMenuItem(ps, dataId, label, null, parentId || CUSTOM_MAIN_MENU, hideItem, hideItemMobile, CUSTOM_MAIN_MENU)
    }

    function assertPageLink(ps, linkData) {
        if (basicMenuItemMethods.isPageLink(ps, linkData)) {
            throw new Error('Explicitly adding a LinkItem of type "PageLink" is not allowed')
        }
    }

    function validateLinkTypeBeforeDelete(ps, itemId) {
        itemId = menuUtils.sanitizeHash(itemId)
        const linkItem = menuUtils.getLinkItemByMenuItemId(ps, itemId)

        if (linkItem) {
            const sanitizedPageId = linkItem.pageId ? menuUtils.sanitizeHash(linkItem.pageId) : null
            const isPopupLink = popupUtils.isPopup(ps, sanitizedPageId)

            if (linkItem.type === 'PageLink' && !isPopupLink) {
                throw new Error('Explicitly deleting a page link item is not allowed')
            }
        }
    }

    function isPageMarkedAsHideFromMenu(ps, linkObject) {
        let pageId = _.get(linkObject, 'pageId')
        if (pageId && _.get(linkObject, 'type') === 'PageLink') {
            pageId = menuUtils.sanitizeHash(pageId)
            const pageData = dataModel.getDataItemById(ps, pageId)
            const tpaApplicationId = _.get(pageData, 'tpaApplicationId')
            const tpaPageId = _.get(pageData, 'tpaPageId')
            return tpa.isPageMarkedAsHideFromMenu(ps, tpaApplicationId, tpaPageId)
        }
        return false
    }

    function addMenuDataItem(ps, dataItemId, label, link, hideItem, hideItemMobile) {
        const dataItem = dataModel.createDataItemByType(ps, 'BasicMenuItem')
        const menuItemPointer = ps.pointers.data.getDataItemFromMaster(dataItemId)

        dataModel.setDataItemByPointer(
            ps,
            menuItemPointer,
            _.assign(dataItem, {
                id: dataItemId,
                label,
                isVisible: !hideItem,
                isVisibleMobile: !(typeof hideItemMobile === 'boolean' ? hideItemMobile : hideItem),
                link: link ? `#${link}` : undefined
            }),
            'data'
        )

        return dataItemId
    }

    function addMenuItem(ps, dataId, label, linkId, parentItemId, hideItem, hideItemMobile, menuIdToPlaceItemIn) {
        menuUtils.validateParent(ps, parentItemId, dataId, menuIdToPlaceItemIn)
        addMenuDataItem(ps, dataId, label, linkId, hideItem, hideItemMobile)
        const parentPointer = menuUtils.getMenuDataItemPointer(ps, parentItemId)

        const parentItemsPointer = ps.pointers.getInnerPointer(parentPointer, 'items')

        parentItemsPointer.useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')

        if (!ps.dal.isExist(parentItemsPointer)) {
            ps.dal.set(parentItemsPointer, [`#${dataId}`])
        } else {
            ps.dal.push(parentItemsPointer, `#${dataId}`)
        }
    }

    function addLinkDataItemAndReturnId(ps, linkData) {
        const dataItemId = dataModel.generateNewDataItemId()
        const linkPointer = ps.pointers.data.getDataItemFromMaster(dataItemId)
        dataModel.setDataItemByPointer(ps, linkPointer, _.assign(linkData, {id: dataItemId}), 'data')

        return dataItemId
    }

    function addLinkItem(ps, dataId, linkData, label, parentItemId, hideItem, hideItemMobile, menuIdToPlaceItemIn) {
        const linkId = addLinkDataItemAndReturnId(ps, linkData)
        addMenuItem(ps, dataId, label, linkId, parentItemId, hideItem, hideItemMobile, menuIdToPlaceItemIn)
    }

    function addNonPageLinkItem(ps, dataId, linkData, label, parentItemId, hideItem, hideItemMobile) {
        assertPageLink(ps, linkData)
        return addLinkItem(ps, dataId, linkData, label, parentItemId || CUSTOM_MAIN_MENU, hideItem, hideItemMobile, CUSTOM_MAIN_MENU)
    }

    const addMenuItemWithPageLink = (ps, menuId, pageId, label, hideItem, hideItemMobile) => {
        const pageLinkRawData = dataModel.createDataItemByType(ps, 'PageLink')
        const dataId = dataModel.generateNewDataItemId()
        addLinkItem(ps, dataId, _.assign(pageLinkRawData, {pageId}), label, menuId, hideItem, hideItemMobile)
        return dataId
    }

    function addPageItem(ps, pageId, label, hideItem, hideItemMobile) {
        const translationLanguages = language.getTranslationLanguageCodes(ps)
        _.forEach(getAllSyncableMenuPointers(ps), function (menuPointer) {
            const menuId = menuPointer.id
            const menuItemId = addMenuItemWithPageLink(ps, menuId, pageId, label, hideItem, hideItemMobile)
            const menuItem = translationLanguages.length ? ps.dal.get(ps.pointers.data.getDataItemFromMaster(menuItemId)) : null
            _.forEach(translationLanguages, lang => {
                const translatedMenuPointer = ps.pointers.multilingualTranslations.translationDataItem('masterPage', lang, menuId)
                if (ps.dal.isExist(translatedMenuPointer)) {
                    ps.dal.push(ps.pointers.getInnerPointer(translatedMenuPointer, 'items'), `#${menuItemId}`)
                    ps.dal.set(ps.pointers.multilingualTranslations.translationDataItem('masterPage', lang, menuItemId), menuItem)
                }
            })
        })
    }

    function deleteItemFromMainMenu(ps, itemId) {
        const siteMenu = menuUtils.getSiteMenu(ps)
        const parentItemId = menuUtils.getParentItemId({items: siteMenu, id: CUSTOM_MAIN_MENU}, itemId)
        deleteItemFromParent(ps, itemId, parentItemId)
    }

    function deleteNonPageItem(ps, itemId) {
        validateLinkTypeBeforeDelete(ps, itemId)
        deleteItemFromMainMenu(ps, itemId)
    }

    /**
     * Move item from old parent to new parent at specified index
     *
     * @param {ps} ps
     * @param {string} itemId item to move
     * @param {string} newParentId the id of the new parent item
     * @param {number} newIndex the desired position in the new parent item
     */
    function moveItem(ps, itemId, newParentId, newIndex) {
        itemId = menuUtils.sanitizeHash(itemId)
        const siteMenu = menuUtils.getSiteMenu(ps)
        const oldParentId = menuUtils.getParentItemId({items: siteMenu}, itemId) || 'CUSTOM_MAIN_MENU'
        newParentId = newParentId || 'CUSTOM_MAIN_MENU'

        menuUtils.validateParent(ps, newParentId, itemId, 'CUSTOM_MAIN_MENU')

        const oldIndex = menuUtils.getIndexOfItemInParent(ps, oldParentId, itemId)
        const oldParentPointer = menuUtils.getMenuDataItemPointer(ps, oldParentId)
        const oldParent = ps.dal.get(oldParentPointer)
        oldParent.items.splice(oldIndex, 1)
        ps.dal.set(oldParentPointer, oldParent)

        const newParentPointer = menuUtils.getMenuDataItemPointer(ps, newParentId)
        const newParent = ps.dal.get(newParentPointer)
        newParent.items = newParent.items || []
        newParent.items.splice(newIndex, 0, `#${itemId}`)
        ps.dal.set(newParentPointer, newParent)
    }

    function getMenu(ps, filterHideFromMenuPages) {
        const menuItems = menuUtils.getSiteMenu(ps)
        if (filterHideFromMenuPages) {
            return _.filter(menuItems, function (dataItem) {
                return !isPageMarkedAsHideFromMenu(ps, dataItem.link)
            })
        }
        return menuItems
    }

    /** @class documentServices.mainMenu */
    return {
        /**
         * Add a dropdown item to the menu
         *
         * @param {string} label the label of the dropdown item
         * @param {string=} parentId the parent item id under-which to place the dropdown item
         * @returns {*}
         */
        addHeaderItem,
        /**
         * Add a link item to the menu
         *
         * @param {object} linkData the link data, must contain type and relevant info of that type (e.g. {type: 'ExternalLink', url: 'http://www.wix.com'})
         * @param {string} label the label of the link item
         * @param {string=} parentItemId the parent item id under-which to place the link item
         * @returns {*}
         */
        addLinkItem: addNonPageLinkItem,
        addPageItem,
        /**
         * Remove item from menu, while flattening its children
         *
         * @param {string} itemId item to delete
         */
        deleteItem: deleteNonPageItem,
        /**
         * Return the site menu items
         *
         * @param {boolean} filterHiddenFromMenuPages filter out tpa pages which their 'hideFromMenu' flag in the client spec map is true
         * @returns {Array.object}
         */
        getMenu,
        initialize,
        /**
         * Move item from old parent to new parent at specified index
         *
         * @param {string} itemId item to move
         * @param {string} newParentId the id of the new parent item
         * @param {number} newIndex the desired position in the new parent item
         */
        moveItem
    }
})
