import {
    CreateExtArgs,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils,
    DalValue,
    Namespace,
    IndexKey,
    ValueToIndexIds,
    CoreConfig
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
const {getRepeaterTemplateId, getUniqueDisplayedId} = displayedOnlyStructureUtil
import {COMP_IDS, LANDING_PAGES_COMP_IDS, MASTER_PAGE_ID, VIEW_MODES} from '../constants/constants'
import {findCompBFS} from '../utils/findCompBFS'
import {getRepeaterItemId, isRepeater} from '../utils/repeaterUtils'
import {getChildrenRecursivelyRightLeftRoot} from '../utils/structureUtils'
import type {PageAPI} from './page'
import type {ValidateValue} from '@wix/document-manager-core/src/dal/validation/dalValidation'
import type {RelationshipsAPI} from './relationships'

const {getPagePointer, getPointer, getRepeatedItemPointerIfNeeded, isSamePointer} = pointerUtils

const masterPageId = MASTER_PAGE_ID
const pagesTypes = ['wixapps.integration.components.AppPage', 'mobile.core.components.Page']
const CHILD_INDEX = 'childIndex'
const NO_MATCH: string[] = []

type Predicate = (a: any) => boolean

const createPointersMethods = ({dal, extensionAPI}: DmApis): PointerMethods => {
    const getComponent = (id: string, pagePointer: Pointer) => {
        const pointer = getPointer(id, pagePointer.type)
        return getRepeatedItemPointerIfNeeded(pointer)
    }

    /**
     * @param {Pointer} compPointer
     * @returns {Pointer|null}
     */
    const getPageOfComponent = (compPointer: Pointer): Pointer | null => {
        if (!compPointer) {
            return null
        }
        const pointer = getRepeatedItemPointerIfNeeded(compPointer)

        const component = dal.get(pointer)
        if (!component) {
            return null
        }
        return getPointer(component.metaData.pageId, compPointer.type)
    }

    const isMasterPage = (pointer: Pointer) => pointer.id === 'masterPage'
    const getChildren = (pointer: Pointer) => {
        const pointerToGetFrom = getRepeatedItemPointerIfNeeded(pointer)
        const childrenIds = dal.getWithPath(pointerToGetFrom, ['components'])
        return _.map(childrenIds, id => getPointer(id, pointer.type))
    }

    const getParent = (pointer: Pointer) => {
        if (!pointer) {
            return null
        }

        const pointerToGetFrom = getRepeatedItemPointerIfNeeded(pointer)
        const parentId = dal.get(getPointer(pointerToGetFrom.id, pointer.type, ['parent']))
        return _.isUndefined(parentId) ? null : getPointer(parentId, pointerToGetFrom.type)
    }

    const getChildrenContainer = ({id, type}: Pointer) => ({id, type, innerPath: ['components']})
    const getChildrenRecursively = (pointer: Pointer): Pointer[] => {
        const children = getChildren(pointer)
        return children.concat(_.flatMap(children, getChildrenRecursively))
    }

    const getChildrenRecursivelyRightLeftRootIncludingRoot = (pointer: Pointer) => getChildrenRecursivelyRightLeftRoot(getChildren, pointer)

    const findDescendant = (componentPointer: Pointer, predicate: Predicate) => {
        const comp = findCompBFS(componentPointer, getChildren, compPointer => predicate(dal.get(compPointer)))
        return comp ? getComponent(comp.id, componentPointer) : null
    }

    const findComponentInPage = (pagePointer: Pointer, isMobileView: boolean, predicate: Predicate) => findDescendant(pagePointer, predicate)

    const getDesktopPointer = (pointer: Pointer) => _.assign(_.clone(pointer), {type: VIEW_MODES.DESKTOP})
    const getMobilePointer = (pointer: Pointer) => _.defaults({type: VIEW_MODES.MOBILE}, pointer)

    const getMasterPage = (viewMode: string) => getPointer(masterPageId, viewMode)
    const getPage = (id: string, viewMode: string) => (_.isString(id) ? getPointer(id, viewMode) : null)
    const getNewPage = (id: string, viewMode = VIEW_MODES.DESKTOP) => {
        const pointer = getPagePointer(id, viewMode)
        if (dal.has(pointer)) {
            throw new Error(`there is already a page with id ${id}`)
        }
        return pointer
    }

    const getPagesContainer = (viewMode: string) => getPointer(COMP_IDS.PAGES_CONTAINER, viewMode)
    const getFooter = (viewMode: string) => getPointer(COMP_IDS.FOOTER, viewMode)
    const getHeader = (viewMode: string) => getPointer(COMP_IDS.HEADER, viewMode)
    const isMobile = (p: Pointer) => p?.type === VIEW_MODES.MOBILE
    const isWithVariants = (pointer: Pointer) => !!_.get(pointer, ['variants'])
    const getViewMode = (pointer: Pointer) => (isMobile(pointer) ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP)
    const isPage = (pointer: Pointer): boolean => {
        const compType = dal.getWithPath(pointer, 'componentType')
        return pointer.id === masterPageId || _.includes(pagesTypes, compType)
    }
    const isPagesContainer = ({id}: Pointer) => id === COMP_IDS.PAGES_CONTAINER
    const getLandingPageComponents = (viewMode: string) =>
        _(LANDING_PAGES_COMP_IDS)
            .mapValues(id => getPointer(id, viewMode))
            .filter(pointer => dal.has(pointer))
            .value()

    const getAncestorByPredicate = (compPointer: Pointer, predicate: Predicate) => {
        let ancestorPointer = getParent(compPointer)
        while (ancestorPointer && !predicate(ancestorPointer)) {
            ancestorPointer = getParent(ancestorPointer)
        }

        return ancestorPointer
    }

    /**
     * Checks if two pointers belong to repeated items, that their item ID matches. There is no reason to continue otherwise
     * @param  {Pointer} compPointer pointer of the component we want to check against
     * @param  {Pointer} possibleAncestorPointer the possible ancestor pointer
     * @returns {boolean} true if both pointers belong to the same repeater item. false otherwise.
     */
    const checkIfRepeaterItemsMatch = (compPointer: Pointer, possibleAncestorPointer: Pointer): boolean => {
        if (!compPointer || !possibleAncestorPointer) {
            return false
        }

        const firstItemId = getRepeaterItemId(compPointer.id)
        const secondItemId = getRepeaterItemId(possibleAncestorPointer.id)

        return !(firstItemId !== undefined && secondItemId !== undefined && firstItemId !== secondItemId)
    }

    const isDescendant = (compPointer: Pointer, possibleAncestorPointer: Pointer): boolean => {
        if (!checkIfRepeaterItemsMatch(compPointer, possibleAncestorPointer)) {
            return false
        }

        return !!getAncestorByPredicate(compPointer, (ancestorPointer: Pointer) =>
            isSamePointer(ancestorPointer, getRepeatedItemPointerIfNeeded(possibleAncestorPointer))
        )
    }

    const isInMasterPage = (pointer: Pointer): boolean => dal.getWithPath(getRepeatedItemPointerIfNeeded(pointer), ['metaData', 'pageId']) === masterPageId

    const getAncestors = (pointer: Pointer) => {
        const result: Pointer[] = []
        let ancestor = getParent(pointer)

        while (ancestor) {
            result.push(ancestor)
            ancestor = getParent(ancestor)
        }

        return result
    }

    const getSiblings = (pointer: Pointer) => {
        const parent = getParent(pointer)
        if (parent) {
            return getChildren(parent).filter(sibling => !isSamePointer(pointer, sibling))
        }
        return []
    }

    const getAllDisplayedOnlyComponents = (pointer: Pointer) => {
        const repeaterPointer = getAncestorByPredicate(pointer, (ancestorPointer: Pointer) => isRepeater(dal, ancestorPointer))

        if (repeaterPointer) {
            const templateId = getRepeaterTemplateId(pointer.id)
            const {relationships} = extensionAPI as RelationshipsAPI
            const repeaterDataQuery = relationships.getIdFromRef(dal.getWithPath(repeaterPointer, 'dataQuery'))
            const repeaterDataPointer = getPointer(repeaterDataQuery, 'data')
            const repeaterData = dal.get(repeaterDataPointer)

            return _.map(repeaterData.items, itemId => getPointer(getUniqueDisplayedId(templateId, itemId), pointer.type))
        }

        return [pointer]
    }

    const getAllComponentPointers = (viewMode: string, pageId: string | null = null): Pointer[] => {
        const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
        const result = dal.query(viewMode, pageCompFilter)
        return _(result)
            .keys()
            .map(id => getPointer(id, viewMode))
            .value()
    }

    return {
        structure: {
            // @ts-ignore
            getAncestorByPredicate,
            getAncestors,
            getChildren,
            getChildrenContainer,
            getChildrenRecursively,
            getChildrenRecursivelyRightLeftRootIncludingRoot,
            getComponent,
            getDesktopPointer,
            getFooter,
            getHeader,
            getLandingPageComponents,
            getMasterPage,
            getMobilePointer,
            getNewPage,
            // @ts-ignore
            getPage,
            // @ts-ignore
            getPageOfComponent,
            getPagesContainer,
            // @ts-ignore
            getParent,
            getSiblings,
            getUnattached: getPointer,
            // @ts-ignore
            getViewMode,
            isDescendant,
            isInMasterPage,
            isMasterPage,
            isMobile,
            isPage,
            isPagesContainer,
            getAllDisplayedOnlyComponents,
            // @ts-ignore
            findComponentInPage,
            // @ts-ignore
            findDescendant,
            isWithVariants,
            getAllComponentPointers
        }
    }
}

const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): ExtensionAPI => {
    const getDeepStructure = (compPointer: Pointer): any => {
        const pointerToGetFrom = getRepeatedItemPointerIfNeeded(compPointer)
        const comp = dal.get(pointerToGetFrom)
        const components = _.map(pointers.full.components.getChildren(pointerToGetFrom), getDeepStructure)
        return {...comp, components}
    }

    const getDeepPageStructure = (pageId: string) => {
        const pageStructure = getDeepStructure(getPointer(pageId, 'DESKTOP'))
        pageStructure.mobileComponents = getDeepStructure(getPointer(pageId, 'MOBILE')).components
        return pageStructure
    }

    const getAllDesktopComponents = () => dal.query('DESKTOP', (extensionAPI.page as PageAPI).getAllPagesIndexId())
    const getAllMobileComponents = () => dal.query('MOBILE', (extensionAPI.page as PageAPI).getAllPagesIndexId())

    return {
        components: {
            getAllDesktopComponents,
            getAllMobileComponents
        },
        siteAPI: {
            getDeepStructure,
            getDeepPageStructure,
            getAllDesktopComponents,
            getAllMobileComponents
        }
    }
}

export interface ComponentsAPI extends ExtensionAPI {
    getAllDesktopComponents(): Record<string, DalValue>
    getAllMobileComponents(): Record<string, DalValue>
}

export interface StructureExtensionAPI extends ExtensionAPI {
    components: ComponentsAPI
    siteAPI: {
        getDeepStructure(compPointer: Pointer): any
        getDeepPageStructure(pageId: string): any
        getAllDesktopComponents(): Record<string, DalValue>
        getAllMobileComponents(): Record<string, DalValue>
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => _.mapValues(VIEW_MODES, _.constant({hasSignature: true}))
const isStructure = (namespace: string) => !!VIEW_MODES[namespace]

const shouldValidateSingleParent = ({experimentInstance}: CoreConfig) => experimentInstance.isOpen('dm_strictModeSingleParentValidation')

const createFilters = ({coreConfig}: DmApis) => {
    if (!shouldValidateSingleParent(coreConfig)) {
        return {} as Record<string, ValueToIndexIds>
    }

    return {
        [CHILD_INDEX]: (namespace: Namespace, value: DalValue): string[] => {
            if (!value || !isStructure(namespace)) {
                return NO_MATCH
            }
            return value.components ?? NO_MATCH
        }
    }
}

const createValidator = ({dal, coreConfig, pointers}: DmApis): Record<string, ValidateValue> => ({
    validateSingleParent: (pointer: Pointer, value: DalValue) => {
        if (!shouldValidateSingleParent(coreConfig)) {
            return undefined
        }

        const {strictModeFailDefault} = coreConfig

        if (!value || !isStructure(pointer.type)) {
            return undefined
        }

        return _.compact(
            _.map(value.components, (childId: string) => {
                const indexKey: IndexKey = dal.queryFilterGetters[CHILD_INDEX](childId)
                const parents = _.values(dal.query(pointer.type, indexKey))
                if (parents.length > 1) {
                    return {
                        shouldFail: strictModeFailDefault,
                        type: 'duplicateParentError',
                        message: `${childId} was referenced from ${_.map(parents, 'id')}`,
                        extras: {
                            namespace: pointer.type,
                            otherParent: _.find(parents, ({id}) => id !== pointer.id)
                        }
                    }
                }
                return undefined
            })
        )
    },
    validateMobileOnSamePage: (pointer: Pointer, value: DalValue) => {
        if (
            !coreConfig.experimentInstance.isOpen('dm_validateSamePageMobileReport') &&
            !coreConfig.experimentInstance.isOpen('dm_validateSamePageMobileFail')
        ) {
            return undefined
        }

        if (!value || !isStructure(pointer.type)) {
            return undefined
        }

        const otherModePointer = pointers.structure.isMobile(pointer)
            ? pointers.structure.getDesktopPointer(pointer)
            : pointers.structure.getMobilePointer(pointer)

        const changedStructurePage = value.metaData.pageId
        const otherModeStructurePage = pointers.structure.getPageOfComponent(otherModePointer)

        if (otherModeStructurePage && otherModeStructurePage.id !== changedStructurePage) {
            return [
                {
                    shouldFail: coreConfig.experimentInstance.isOpen('dm_validateSamePageMobileFail'),
                    type: 'differentMobilePageError',
                    message: `{${pointer.id},${pointer.type}} was changed to a different page than {${otherModePointer.id},${otherModePointer.type}}`,
                    extras: {
                        otherPointer: otherModePointer
                    }
                }
            ]
        }

        return undefined
    }
})

const createExtension = (): Extension => ({
    name: 'structure',
    createPointersMethods,
    createExtensionAPI,
    getDocumentDataTypes,
    createValidator,
    createFilters
})

const isStructurePointer = (pointer: Pointer): boolean => !!VIEW_MODES[pointer.type]

export {createExtension, isStructurePointer, CHILD_INDEX}
