define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/components/baseInputMetadata'
], function (_, dataModel, constants, baseInputMetadata) {
    'use strict'

    const radioGroupMetaData = {
        defaultMobileProperties: {
            layout: 'vertical'
        },
        layoutLimits(ps, compPointer) {
            const buttonsAmount = dataModel.getDataItem(ps, compPointer).options.length
            const isVerticalLayout = dataModel.getPropertiesItem(ps, compPointer).layout === 'vertical'
            const minButtonWidth = 60
            return {
                minWidth: isVerticalLayout ? minButtonWidth : minButtonWidth * buttonsAmount
            }
        },
        resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
    }

    return _.merge(radioGroupMetaData, baseInputMetadata)
})
