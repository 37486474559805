define([], function () {
    'use strict'

    return {
        isRepeatable: false,
        canBeStretched: true,
        mobileConversionConfig: {
            fixedSize: {width: 280, height: 240}
        }
    }
})
