import _ from 'lodash'
import experiment from 'experiment'
import {scriptsOverridesService} from '@wix/santa-ds-libs/src/coreUtils'
import specMapUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/specMapUtils'
import nativeDataHelpers from '@wix/santa-ds-libs/src/platformInit/src/api/nativeDataHelpers'
import {platformAppsUtils} from '@wix/santa-ds-libs/src/warmupUtils'

const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'
const {widgetTypes, joinURL, hasWixCode, addSiteExtensionsApps, getSiteExtensionAppDataIfExist} = platformAppsUtils

const addApplicationType = specs => specs.map(spec => _.assign({type: widgetTypes.APPLICATION}, spec))

function filterWixCode(specs) {
    const isWixCodeSpec = {displayName: 'siteextension'}
    return _(specs)
        .reject(isWixCodeSpec)
        .map(function (spec) {
            return _.assign({type: widgetTypes.APPLICATION}, spec)
        })
        .value()
}

function addWixCodeDef(specs, isWixCodeSpec, rootIds, siteData, platformApps) {
    if (hasWixCode(siteData.rendererModel) && _.find(specs, isWixCodeSpec)) {
        const shouldLoadMasterPage = siteData.isPlatformAppOnPage('masterPage', 'wixCode')

        _.forEach(rootIds, function (rootId) {
            const shouldLoadPageCode = siteData.isPlatformAppOnPage(rootId, 'wixCode')
            const pageData = siteData.getDataByQuery(rootId)
            const isPopup = _.get(pageData, 'isPopup')
            if (shouldLoadPageCode) {
                platformApps.push({
                    id: rootId,
                    type: isPopup ? widgetTypes.POPUP : widgetTypes.PAGE,
                    displayName: siteData.getPageTitle(rootId)
                })
            }
            if (!isPopup && shouldLoadMasterPage) {
                platformApps.push({
                    id: rootId,
                    type: widgetTypes.MASTER_PAGE
                })
            }
        })
    }
}

function getApplicationsToLoad(rootIds, siteData, specs) {
    //TODO Shahar - move to plugin
    rootIds = _.without(rootIds, 'masterPage')
    const isWixCodeSpec = {displayName: 'siteextension'}
    const platformApps = experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel: siteData.rendererModel})
        ? addApplicationType(specs)
        : filterWixCode(specs)
    addWixCodeDef(specs, isWixCodeSpec, rootIds, siteData, platformApps)
    return platformApps
}

function addLocalApps(viewerPlatformAppVersions, applications, santaBase) {
    if (_.get(viewerPlatformAppVersions, 'port') && _.get(viewerPlatformAppVersions, 'path') && _.get(viewerPlatformAppVersions, 'id')) {
        santaBase = _.endsWith(santaBase, '/') ? santaBase.slice(0, -1) : santaBase
        const viewerLocalAppTemplate = _.template('<%= santaBase %><%= port %>/<%= path %>')
        let viewerLocalApp
        if (/^(http(s)?:)?\/\//.test(viewerPlatformAppVersions.path)) {
            viewerLocalApp = viewerPlatformAppVersions.path
        } else if (viewerPlatformAppVersions.port === '80') {
            viewerLocalApp = viewerLocalAppTemplate({
                santaBase,
                port: '',
                path: viewerPlatformAppVersions.path
            })
        } else {
            viewerLocalApp = viewerLocalAppTemplate({
                santaBase,
                port: `:${viewerPlatformAppVersions.port}`,
                path: viewerPlatformAppVersions.path
            })
        }
        applications.push({
            type: widgetTypes.APPLICATION,
            id: viewerPlatformAppVersions.id,
            url: viewerLocalApp,
            displayName: viewerPlatformAppVersions.id
        })
    }
}

function hasPlatformApp(clientSpec) {
    const viewerScriptUrl = _.get(clientSpec, 'appFields.platform.viewerScriptUrl', '')
    return specMapUtils.validateUrl(viewerScriptUrl) && !_.get(clientSpec, 'permissions.revoked')
}

function getPlatformAppControllersScriptUrlMap(appSpec, scriptsLocation, queryParams, controllersUrlOverrideValue) {
    const CONTROLLER_URL_PATH = 'componentFields.controllerUrl'
    controllersUrlOverrideValue = controllersUrlOverrideValue || scriptsOverridesService.getControllersOverride(queryParams)

    const widgetToControllerScriptUrlMap = _(appSpec.widgets).filter(CONTROLLER_URL_PATH).mapKeys('widgetId').mapValues(CONTROLLER_URL_PATH).value()

    const overrides = scriptsOverridesService.getValidScriptOverrides(controllersUrlOverrideValue, scriptsLocation)

    return _.assign(widgetToControllerScriptUrlMap, overrides)
}

function getPlatformAppViewerScriptUrl(appSpec, rendererModel, serviceTopology, viewerPlatformOverrides) {
    //If there's a version failure, load the fallback viewer script that manages the fallback viewer experience
    const fallbackViewerAppUrl = serviceTopology.scriptsLocationMap['fallback-viewer-app']
    if (appSpec.versionFailure && fallbackViewerAppUrl) {
        return joinURL(fallbackViewerAppUrl, 'fallback-viewer-app.js')
    }

    const viewerScriptUrl = _.get(appSpec, 'appFields.platform.viewerScriptUrl')
    const viewerVerboseScriptUrl = _.get(appSpec, 'appFields.platform.viewerVerboseScriptUrl')
    const {appDefinitionId} = appSpec
    if (rendererModel.previewMode && viewerVerboseScriptUrl) {
        return viewerVerboseScriptUrl
    }
    if (viewerPlatformOverrides) {
        const app = _.find(viewerPlatformOverrides, {appDefinitionId})
        if (app && validateUrl(app.bundle, serviceTopology.staticServerUrl)) {
            return app.bundle
        }
    }

    return viewerScriptUrl
}

function validateUrl(url, scriptsLocation) {
    return url.indexOf(scriptsLocation) === 0 || url.search('https?://localhost') === 0
}

function getApplicationsFromClientSpecMap(appInstanceMap, clientSpecMap, rendererModel, serviceTopology, currentUrl, overrideParams) {
    const {viewerPlatformOverrides, queryParams, controllersUrlOverride} = overrideParams
    return _(clientSpecMap)
        .filter(clientSpec => {
            if (clientSpec.appDefinitionId === WIX_CODE_APP_DEF_ID && !experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel})) {
                return hasWixCode(rendererModel)
            }

            return hasPlatformApp(clientSpec)
        })
        .map(appData => {
            const applicationData = _.omitBy(
                {
                    id: appData.appDefinitionId,
                    displayName: appData.type,
                    appInnerId: appData.applicationId,
                    instanceId: appData.instanceId,
                    instance: _.get(appInstanceMap, [appData.applicationId]) || appData.instance,
                    shouldUseWixCodeScripts: !!_.get(appData, 'appFields.platform.studio'),
                    isWixTPA: appData.isWixTPA,
                    optionalApplication: _.get(appData, 'appFields.platform.optionalApplication')
                },
                _.isUndefined
            )

            const appViewerUrl = getPlatformAppViewerScriptUrl(appData, rendererModel, serviceTopology, viewerPlatformOverrides)
            if (appViewerUrl) {
                applicationData.url = specMapUtils.resolveUrl(appViewerUrl, {serviceTopology, clientSpecMap, clientSpec: appData, currentUrl, rendererModel})
            }

            const scriptsLocation = serviceTopology.staticServerUrl
            const controllerScriptsUrlsMap = getPlatformAppControllersScriptUrlMap(appData, scriptsLocation, queryParams, controllersUrlOverride)
            if (!_.isEmpty(controllerScriptsUrlsMap)) {
                applicationData.controllerScriptMap = controllerScriptsUrlsMap
            }

            const baseUrls = _.get(appData, 'appFields.platform.baseUrls')
            if (baseUrls) {
                applicationData.baseUrls = baseUrls
            }

            return applicationData
        })
        .value()
}

function clearAppsIfAllStandalone(applications) {
    const standalone = _.filter(applications, 'optionalApplication')
    return _.size(standalone) === _.size(applications) ? [] : _.map(applications, app => _.omit(app, 'optionalApplication'))
}

function getAllApps(options) {
    const {
        rendererModel,
        serviceTopology,
        viewerPlatformOverrides,
        viewerPlatformAppSources,
        clientSpecMap,
        currentUrl,
        queryParams,
        controllersUrlOverride,
        santaBase,
        appInstanceMap
    } = options
    const viewerPlatformAppVersions = parseAppSources(viewerPlatformAppSources)
    const clientSpecMapWithWixCodeApps = addSiteExtensionsApps(clientSpecMap, serviceTopology, viewerPlatformAppVersions, rendererModel)
    let applications = getApplicationsFromClientSpecMap(appInstanceMap, clientSpecMapWithWixCodeApps, rendererModel, serviceTopology, currentUrl, {
        viewerPlatformOverrides,
        queryParams,
        controllersUrlOverride
    })

    applications = clearAppsIfAllStandalone(applications)
    addLocalApps(viewerPlatformAppVersions, applications, santaBase)
    return applications
}

function getAppsBaseInfo(options) {
    const applications = getAllApps(options)
    const {rendererModel} = options
    return experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel})
        ? _.filter(addApplicationType(applications), 'url')
        : _.filter(filterWixCode(applications), 'url')
}

function getApplications(clientSpecMap, rootIds, siteData) {
    const viewerPlatformAppSources = _.get(siteData, ['currentUrl', 'query', 'viewerPlatformAppSources'])
    const applications = getAllApps({
        clientSpecMap,
        queryParams: siteData.getQueryParams(),
        viewerPlatformAppSources,
        serviceTopology: siteData.serviceTopology,
        santaBase: siteData.santaBase,
        rendererModel: siteData.rendererModel,
        appInstanceMap: siteData.getAppInstance()
    })
    return getApplicationsToLoad(rootIds, siteData, applications)
}

function parseAppSources(appSources) {
    return _(appSources || '')
        .split(',')
        .invokeMap('split', /:(.+)/)
        .fromPairs()
        .value()
}

function getUserCodeDefinitions(clientSpecMap, rootIds, siteData) {
    return _.reject(getApplications(clientSpecMap, rootIds, siteData), {type: widgetTypes.APPLICATION})
}

function getRootPages(rootId, siteData) {
    const pageData = siteData.getPageData(rootId)
    const rootPages = [{id: rootId, json: pageData}]
    if (_.get(siteData.getDataByQuery(rootId), 'isPopup')) {
        return rootPages
    }
    const masterPageId = siteData.MASTER_PAGE_ID
    return rootPages.concat({id: masterPageId, json: siteData.getMasterPageData()})
}

function getConnections(pageJsons, siteData) {
    return _(pageJsons)
        .transform(function (acc, json) {
            _.assign(acc, _.get(json, 'data.connections_data'))
        }, {})
        .values()
        .flatMap(function (connectionData) {
            const resolvedData = siteData.resolveData(connectionData, null, siteData.dataTypes.CONNECTIONS)
            return _.get(resolvedData, 'items')
        })
        .groupBy('controllerId')
        .value()
}

function isTypeController(compStructure, siteData, pageId) {
    return (
        _.includes(['platform.components.AppController', 'platform.components.AppWidget'], compStructure.componentType) ||
        isTPAController(compStructure, siteData, pageId)
    )
}

function getControllerDependencies(controller, page, siteData, wixCodeAppController) {
    const res = wixCodeAppController ? [wixCodeAppController.controllerId] : []
    const connectionsList = siteData.getDataByQuery(controller.connectionQuery, page.id, siteData.dataTypes.CONNECTIONS)
    if (!connectionsList) {
        return res
    }
    const dependenciesArray = _(connectionsList.items).filter({type: 'ConnectionItem'}).map('controllerId').value()
    return dependenciesArray.concat(res)
}

function getWidgetIdFromAppData(componentData, siteData) {
    const appData = siteData.getClientSpecMapEntry(componentData.applicationId)
    return appData ? componentData.widgetId || _.findKey(appData.widgets, ['appPage.hidden', false]) : null
}

function isTypeTPAController(componentType) {
    return _.startsWith(componentType, 'wysiwyg.viewer.components.tpapps.') || _.startsWith(componentType, 'native.components')
}

function isTPAController(compStructure, siteData, pageId) {
    if (!isTypeTPAController(compStructure.componentType)) {
        return false
    }
    const componentDataQuery = compStructure.dataQuery.replace('#', '')
    const compData = siteData.getDataByQuery(componentDataQuery, pageId)
    const appData = siteData.getClientSpecMapEntry(compData.applicationId)
    return _.get(appData, 'appFields.platform.viewerScriptUrl')
}

function buildControllerAndTPAsArray(pages, siteData, styleUtils, wixCodeAppController) {
    const viewMode = siteData.getViewMode()
    const csm = siteData.getClientSpecMap()
    const loadedPages = _.filter(pages, 'json')
    const connections = getConnections(_.map(loadedPages, 'json'), siteData)
    return _.flatMap(loadedPages, function (page) {
        const pageControllersAndTPA = _.filter(_.get(page, ['json', 'structure', viewMode]), compStructure =>
            isTypeController(compStructure, siteData, page.id)
        )
        return _.map(pageControllersAndTPA, function (component) {
            const componentDataQuery = component.dataQuery.replace('#', '')
            const componentData = _.assign({}, siteData.getDataByQuery(componentDataQuery, page.id))
            const {applicationId} = componentData
            if (isTypeTPAController(component.componentType)) {
                componentData.controllerType = getWidgetIdFromAppData(componentData, siteData)
                componentData.applicationId = csm[applicationId].appDefinitionId
                componentData.settings = {
                    externalId: nativeDataHelpers.getExternalId(siteData, componentData),
                    style: nativeDataHelpers.getStyleParams(siteData, styleUtils, component.styleId, page.id),
                    publicData: nativeDataHelpers.getPublicData(siteData, applicationId, componentData.tpaData, page.id)
                }
            }
            return _.omitBy(
                {
                    controllerBehaviors: _.get(siteData.getDataByQuery(component.behaviorQuery, page.id, siteData.dataTypes.BEHAVIORS), 'items', []),
                    controllerData: componentData,
                    controllerId: componentDataQuery,
                    compId: component.id,
                    connections: _.get(connections, componentDataQuery),
                    dependencies: getControllerDependencies(component, page, siteData, wixCodeAppController)
                },
                _.isUndefined
            )
        })
    })
}

function buildWixCodeAppController(pages, siteData, rootId) {
    const clientSpecMap = siteData.getClientSpecMap()
    const wixCodeAppData = getSiteExtensionAppDataIfExist(clientSpecMap)
    if (hasWixCode(siteData.rendererModel) && wixCodeAppData) {
        return {
            controllerData: {id: rootId, controllerId: rootId, applicationId: wixCodeAppData.appDefinitionId},
            controllerId: rootId,
            controllerBehaviors: [],
            dependencies: []
        }
    }
}

function buildControllersToInitMap(controllerArray) {
    return _(controllerArray)
        .groupBy('controllerData.applicationId')
        .mapValues(function (controllers) {
            return _(controllers)
                .keyBy('controllerId')
                .mapValues(function (controllerObjArray) {
                    return _.pick(controllerObjArray, ['controllerData', 'controllerBehaviors', 'connections', 'compId', 'dependencies'])
                })
                .value()
        })
        .value()
}

function isAppBuilderPlatformAppInstalled(siteData) {
    const appData = _.find(siteData.getClientSpecMap(), ['appDefinitionId', '46b2ad43-5720-41d2-8436-2058979cb53f'])
    return hasPlatformApp(appData)
}

function getContextInitData(siteData, rootId, styleUtils) {
    const pages = getRootPages(rootId, siteData)
    const wixCodeAppController = experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel: siteData.rendererModel})
        ? buildWixCodeAppController(pages, siteData, rootId)
        : null
    let controllerArray = buildControllerAndTPAsArray(pages, siteData, styleUtils, wixCodeAppController)
    const shouldRunWixCode = !(siteData.isApplicationStudio() && isAppBuilderPlatformAppInstalled(siteData))
    if (wixCodeAppController && shouldRunWixCode) {
        controllerArray = [...controllerArray, wixCodeAppController]
    }
    const controllersToInitMap = buildControllersToInitMap(controllerArray)
    return _.mapValues(controllersToInitMap, controllers => ({controllers}))
}

export default {
    isTypeController,
    hasWixCode,
    getApplications,
    getUserCodeDefinitions,
    getAppsBaseInfo,
    getContextInitData
}
