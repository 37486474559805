define([
    'lodash',
    'platformUtils',
    '@wix/santa-core-utils',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/connections/connections',
    'documentServices/wixCode/utils/tpaConfigurationUtils',
    'documentServices/page/popupUtils',
    'documentServices/component/componentCode',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/componentsMetaData/components/tpaSectionMetaData',
    'documentServices/appStudio/appStudioDataModel',
    'js-platform-apps-configuration-editor',
    'documentServices/platform/platform',
    'documentServices/wixCode/utils/typeDefUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/utils/utils'
], function (
    _,
    platformUtils,
    utils,
    actionsAndBehaviors,
    constants,
    dataModel,
    connections,
    tpaConfigurationUtils,
    popupUtils,
    componentCode,
    componentDetectorAPI,
    tpaSectionMetaData,
    appStudioDataModel,
    platformAppConfiguration,
    platform,
    typeDefUtils,
    componentsMetaData,
    dsUtils
) {
    'use strict'

    const isDatabindingApp = appId => appId === 'dataBinding'

    function _getWidgetIdIfExist(compData) {
        if (!_.has(compData, 'applicationId') || !_.has(compData, 'widgetId')) {
            return
        }
        return compData.widgetId
    }

    function _getPageNicknamesToTypeMap(ps, pageId) {
        const components = ps.siteAPI.getComponentsByPageIdForWixCode(pageId)
        return _.transform(
            components,
            (result, comp, compId) => {
                const compPointer = componentDetectorAPI.getComponentById(ps, compId, pageId)
                const nickname = componentCode.getNickname(ps, compPointer)
                if (result[nickname] || !componentsMetaData.canConnectToCode(ps, compPointer)) {
                    return
                }
                const compType = comp.componentType
                const compData = comp.dataQuery && dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(comp.dataQuery))

                if (connections.isControllerType(compType)) {
                    // controller OR app widget
                    const applicationId = _.get(compData, 'applicationId')
                    const controllerType = _.get(compData, 'controllerType')
                    const appManifest = platform.getAppManifest(ps, applicationId)
                    const controllerExports = _.get(appManifest, ['exports', controllerType])

                    if (controllerExports && controllerExports.typeDef) {
                        result[nickname] = controllerExports.typeDef
                    } else if (isDatabindingApp(applicationId)) {
                        result[nickname] = `$w.${controllerType}`
                    } else if (controllerExports) {
                        result[nickname] = typeDefUtils.controllerExportsToTypeDef(controllerExports)
                    } else if (connections.isAppWidgetType(compType)) {
                        result[nickname] = '$w.Node'
                    }
                    // otherwise it's a controller that does not want to be selected
                } else {
                    const widgetId = _getWidgetIdIfExist(compData)
                    const tpaWidgetDisplayName = widgetId && tpaConfigurationUtils.getWidgetDisplayName(platformAppConfiguration, widgetId)
                    if (tpaWidgetDisplayName) {
                        // specialy treated widgets from https://github.com/wix-private/js-platform-apps-configuration/tree/master/json/editor/api
                        result[nickname] = `$w.${tpaWidgetDisplayName}`
                    } else {
                        // regular components
                        const sdkType = platformUtils.typeUtils.getFullSdkType(compType, widgetId)
                        result[nickname] = sdkType
                    }
                }
            },
            {}
        )
    }

    function getCompSdkType(ps, compPointer, compType) {
        const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType')) || compType
        const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
        const data = dataItemPointer && ps.dal.get(dataItemPointer)
        const widgetId = _.get(tpaSectionMetaData.getWidgetData(ps, data), 'widgetId') || null
        const tpaWidgetDisplayName = tpaConfigurationUtils.getWidgetDisplayName(platformAppConfiguration, widgetId)
        return tpaWidgetDisplayName ? tpaWidgetDisplayName : platformUtils.typeUtils.getFullSdkType(componentType, widgetId)
    }

    function getNicknameToTypeMap(ps, pageIds) {
        const pagesNicknamesMap = _.transform(
            pageIds,
            (result, pageId) => {
                result[pageId] = _getPageNicknamesToTypeMap(ps, pageId)
            },
            {}
        )

        const masterPageNicknamesMap = _getPageNicknamesToTypeMap(ps, constants.MASTER_PAGE_ID)

        return _.transform(
            pagesNicknamesMap,
            (result, pageNicknamesMap, pageId) => {
                if (pageId === constants.MASTER_PAGE_ID) {
                    result[pageId] = masterPageNicknamesMap
                } else if (popupUtils.isPopup(ps, pageId)) {
                    result[pageId] = pageNicknamesMap
                } else {
                    result[pageId] = _.merge({}, pageNicknamesMap, masterPageNicknamesMap)
                }
            },
            {}
        )
    }

    function _shouldAddMasterPageComponents(ps, pageId) {
        return pageId !== constants.MASTER_PAGE_ID && !popupUtils.isPopup(ps, pageId) && !appStudioDataModel.isWidgetPage(ps, pageId)
    }

    function _isSupportedAppController(compType, applicationId) {
        return connections.isAppControllerType(compType) && !isDatabindingApp(applicationId)
    }

    function getPageComponentsCodeModel(ps, pageId) {
        const pageComponents = ps.siteAPI.getComponentsByPageIdForWixCode(pageId)
        let components = pageComponents

        if (_shouldAddMasterPageComponents(ps, pageId)) {
            const masterPageComponents = ps.siteAPI.getComponentsByPageIdForWixCode(constants.MASTER_PAGE_ID)
            components = _.merge(pageComponents, masterPageComponents)
        }
        return _.transform(
            components,
            (result, comp, compId) => {
                const compPointer = componentDetectorAPI.getComponentById(ps, compId, pageId)
                const nickname = componentCode.getNickname(ps, compPointer)
                if (!nickname || utils.displayedOnlyStructureUtil.isRepeatedComponent(compId)) {
                    return
                }

                const compType = comp.componentType
                let type = compType
                let sdkType = getCompSdkType(ps, compPointer, compType)

                if (connections.isControllerType(compType)) {
                    const compData = comp.dataQuery && dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(comp.dataQuery))
                    const applicationId = _.get(compData, 'applicationId')
                    const controllerType = _.get(compData, 'controllerType')

                    if (_isSupportedAppController(compType, applicationId)) {
                        return
                    }

                    if (controllerType) {
                        type = controllerType
                        sdkType = controllerType
                    }
                }

                const compActionsAndBehaviors = actionsAndBehaviors.getBehaviors(ps, compPointer)
                const events = _.map(compActionsAndBehaviors, actionAndBehavior =>
                    _.assign({eventType: _.get(actionAndBehavior, 'action.name')}, _.get(actionAndBehavior, 'behavior.params'))
                )
                result.push({compId, compName: nickname, events, type, sdkType, children: []})
            },
            []
        )
    }

    return {
        getNicknameToTypeMap,
        getPageComponentsCodeModel,
        getCompSdkType
    }
})
