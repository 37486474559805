define(['@wix/document-manager-utils', 'documentServices/errors/constants/buildErrors', 'documentServices/siteMetadata/siteMetadata'], function (
    dmUtils,
    buildErrors,
    siteMetaData
) {
    'use strict'

    return {
        save: dmUtils.saveErrors,
        build: buildErrors,
        seo: siteMetaData.seo.ERRORS,
        social: {
            facebook: siteMetaData.social.facebook.ERRORS
        },
        favicon: siteMetaData.favicon.ERRORS,
        siteName: siteMetaData.siteName.ERRORS
    }
})
