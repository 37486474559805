define([
    'documentServices/wixCode/utils/runSaveTask',
    'documentServices/wixCode/saveTasks/saveCode',
    'documentServices/wixCode/services/appSaveState',
    '@wix/santa-core-utils'
], function (runSaveTask, createSaveCodeTask, appSaveState, santaCoreUtils) {
    'use strict'

    const finalizeSave = function () {
        try {
            appSaveState.setState(null)
            appSaveState.setAnotherSaveRequested(false)
        } catch (e) {
            santaCoreUtils.log.error(e)
        }
    }

    function saveWhileThereArePendingSaveRequests(privateServices, isFullSave, saveCode) {
        /** @type {Promise<void>} */
        let saveInProgressPromise = appSaveState.getState()
        if (saveInProgressPromise) {
            appSaveState.setAnotherSaveRequested(true)
            return saveInProgressPromise
        }

        saveInProgressPromise = new Promise(function (resolve, reject) {
            runSaveTask(privateServices, saveCode, isFullSave, onSuccess, onError)

            function onSuccess() {
                if (!appSaveState.getAnotherSaveRequested()) {
                    resolve()
                    return
                }

                appSaveState.setAnotherSaveRequested(false)
                runSaveTask(privateServices, saveCode, isFullSave, onSuccess, onError)
            }

            function onError(err) {
                if (!appSaveState.getAnotherSaveRequested()) {
                    reject(err)
                    return
                }
                appSaveState.setAnotherSaveRequested(false)
                runSaveTask(privateServices, saveCode, isFullSave, onSuccess, onError)
            }
        })
        saveInProgressPromise.then(finalizeSave, finalizeSave) // eslint-disable-line promise/prefer-await-to-then
        appSaveState.setState(saveInProgressPromise)
        return saveInProgressPromise
    }

    function save(privateServices, isFullSave) {
        const saveCode = createSaveCodeTask()

        return saveWhileThereArePendingSaveRequests(privateServices, isFullSave, saveCode)
        //TODO handle reject upon ensureAppIsWritable fail
    }

    return {
        save
    }
})
