define(['documentServices/appStudio/appStudioDataModel'], function (appStudioDataModel) {
    'use strict'

    const setAppDescription = (ps, description) => {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        appStudioData.description = description
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    const clearAppDescription = ps => setAppDescription(ps)

    const getAppDescription = ps => appStudioDataModel.getAppStudioData(ps).description

    return {
        set: setAppDescription,
        get: getAppDescription,
        clear: clearAppDescription
    }
})
