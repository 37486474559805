define([
    'lodash',
    'documentServices/component/component',
    'documentServices/layouters/layouters',
    'documentServices/hooks/layoutHooks/utils/layoutHooksUtils'
], function (_, component, layouters, layoutHooksUtils) {
    'use strict'

    return (ps, compPointer, slideShowNewLayout, updateCompLayoutCallback) => {
        if (!_.isFunction(updateCompLayoutCallback)) {
            return
        }

        const slideShowCurrentLayout = component.layout.get(ps, compPointer)
        if (layoutHooksUtils.isCompResizing(slideShowCurrentLayout, slideShowNewLayout)) {
            //no need for slides changes on position change
            const boxSlides = layouters.getNonMasterChildren(ps, compPointer)
            const slideNewLayout = _.pick(slideShowNewLayout, ['width', 'height'])
            _.forEach(boxSlides, slide => updateCompLayoutCallback(ps, slide, slideNewLayout))
        }
    }
})
