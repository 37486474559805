define([
    'lodash',
    '@wix/santa-core-utils',
    'experiment',
    'documentServices/platform/services/workerService',
    'documentServices/platform/appComponents',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/appStoreService',
    'documentServices/tpa/services/pendingAppsService',
    'documentServices/tpa/services/tpaAddService',
    'documentServices/platform/common/constants',
    'documentServices/wixCode/wixCode',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/platform/provision',
    'documentServices/platform/platform',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/documentMode/documentModeInfo'
], function (
    _,
    santaCoreUtils,
    experiment,
    workerService,
    appComponents,
    clientSpecMapService,
    appStoreService,
    pendingAppsService,
    tpaAddService,
    constants,
    wixCode,
    contextAdapter,
    dsConstants,
    provision,
    platform,
    generalInfo,
    documentModeInfo
) {
    'use strict'

    const errNames = Object.freeze({
        PROVISION_ERR: 'PROVISION_ERR',
        TEMPLATE_ERR: 'TEMPLATE_ERR',
        EMPTY_APP_DEFS_ERR: 'EMPTY_APP_DEFS_ERR',
        WORKER_NOT_INIT_ERR: 'WORKER_NOT_INIT_ERR',
        ADD_ON_MOBILE: 'ADD_ON_MOBILE',
        ADD_APP_ERR: 'ADD_APP_ERR'
    })

    const {getAppComponents, hasCodePackage} = appComponents

    const isPlatformType = app => {
        const components = getAppComponents(app)
        return _.get(app, 'appFields.platform') || components.some(comp => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM')
    }

    const isWidgetType = app => {
        const appWidgets = _.filter(app.widgets, widget => _.isNil(widget.appPage))
        return _.find(appWidgets, {default: true}, false)
    }

    const isSectionType = app => {
        const appPages = _(app.widgets)
            .filter(widget => !_.isNil(widget.appPage))
            .map('appPage')
            .value()
        return _.find(appPages, {hidden: false}, false)
    }

    const getAppType = app => {
        if (isSectionType(app)) {
            return constants.APP.TYPE.SECTION
        } else if (isBuilderType(app)) {
            return constants.APP.TYPE.BLOCKS
        } else if (isWidgetType(app)) {
            return constants.APP.TYPE.WIDGET
        } else if (isPlatformType(app)) {
            return constants.APP.TYPE.PLATFORM_ONLY
        }

        return constants.APP.TYPE.WIDGET
    }

    const isBuilderType = app => {
        const components = getAppComponents(app)
        return components.some(comp => comp.type === 'STUDIO' || comp.compType === 'STUDIO_WIDGET')
    }

    function promisifiedProvisionFunction(ps, options, appData) {
        return new Promise((resolve, reject) => {
            provision.onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
        })
    }

    async function addApp(ps, appData, options = {}) {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_APP_GET_APP_TYPE, {
            tags: {
                appDefinitionId: appData.appDefinitionId
            },
            extras: {app_id: appData.appDefinitionId, options}
        })
        const appType = getAppType(appData)
        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_APP_GET_APP_TYPE, {
            tags: {
                appDefinitionId: appData.appDefinitionId
            },
            extras: {app_id: appData.appDefinitionId, appType, options}
        })
        if (options.headlessInstallation) {
            return new Promise((resolve, reject) => {
                provision.extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APPS_SILENT_INSTALL)
                provision.onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
            })
        }

        switch (appType) {
            case constants.APP.TYPE.BLOCKS: {
                provision.extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APPS_BLOCKS)
                const provisionResponse = await promisifiedProvisionFunction(ps, options, appData)
                if (hasCodePackage(appData)) {
                    contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.INSTALL_CODE_REUSE, {
                        tags: {
                            appDefinitionId: appData.appDefinitionId
                        },
                        extras: {app_id: appData.appDefinitionId}
                    })
                    await wixCode.codePackages.installCodeReusePkg(ps, appData.appDefinitionId, options.appVersion)
                    contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.INSTALL_CODE_REUSE, {
                        tags: {
                            appDefinitionId: appData.appDefinitionId
                        },
                        extras: {app_id: appData.appDefinitionId}
                    })
                }

                return provisionResponse
            }
            case constants.APP.TYPE.PLATFORM_ONLY:
                return new Promise((resolve, reject) => {
                    provision.extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APPS_PLATFORM_ONLY)
                    provision.onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                })
            case constants.APP.TYPE.WIDGET:
                return new Promise((resolve, reject) => {
                    tpaAddService.addWidgetAfterProvision(ps, appData, appData.appDefinitionId, options, resolve, reject)
                })
            case constants.APP.TYPE.SECTION:
                return new Promise((resolve, reject) => {
                    tpaAddService.addSectionAfterProvision(ps, appData, appData.appDefinitionId, options, resolve, reject)
                })
        }
    }

    const provisionApp = (ps, appDefinitionId, options) => {
        if (appDefinitionId === constants.APPS.WIX_CODE.appDefId) {
            return new Promise((resolve, reject) =>
                wixCode.provision(ps, {
                    onSuccess: resolve,
                    onError: reject
                })
            )
        }

        return new Promise((resolve, reject) => {
            const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            if (!clientSpecMapService.isAppActive(existingAppData)) {
                const appSourceTemplateId = _.get(options, 'sourceTemplateId')
                if (appSourceTemplateId) {
                    appStoreService.provisionAppFromSourceTemplate(ps, appDefinitionId, appSourceTemplateId, resolve, reject)
                } else {
                    appStoreService.preSaveAddApp(ps, appDefinitionId, resolve, reject, _.get(options, 'appVersion'))
                }
            } else {
                if (pendingAppsService.isPending(ps, existingAppData)) {
                    pendingAppsService.add(existingAppData)
                }
                resolve(existingAppData)
            }
        })
    }

    const splitAppsByProvisionType = (appDefinitionIds, options) => {
        const monoProvisionAppsFilter = appDefinitionId =>
            _.get(options, [appDefinitionId, 'sourceTemplateId']) || appDefinitionId === constants.APPS.WIX_CODE.appDefId
        const monoProvisionApps = _.filter(appDefinitionIds, monoProvisionAppsFilter)
        const bulkProvisionApps = _.difference(appDefinitionIds, monoProvisionApps)
        return {monoProvisionApps, bulkProvisionApps}
    }

    /**
     * @param {ps} ps
     * @param appDefinitionIds
     * @param options
     * @returns {Promise<void>}
     */
    const bulkProvision = (ps, appDefinitionIds, options) =>
        new Promise((resolve, reject) =>
            _.isEmpty(appDefinitionIds)
                ? resolve()
                : appStoreService.provision(
                      ps,
                      _.map(appDefinitionIds, appDefinitionId => ({appDefinitionId, version: _.get(options, [appDefinitionId, 'appVersion'])})),
                      resolve,
                      reject
                  )
        )

    const provisionAppsSerially = async (ps, monoProvisionApps, options) => {
        const monoProvisionResults = []
        for (const appDefinitionId of monoProvisionApps) {
            const appData = await provisionApp(ps, appDefinitionId, _.get(options, appDefinitionId))
            monoProvisionResults.push(appData)
        }
        return monoProvisionResults
    }

    const provisionApps = async (ps, appDefinitionIds, options) => {
        const appDefinitionIdsToProvision = _.filter(appDefinitionIds, appDefinitionId => !platform.isAppActive(ps, appDefinitionId))
        const appsData = _(appDefinitionIds)
            .filter(appDefinitionId => platform.isAppActive(ps, appDefinitionId))
            .map(appDefinitionId => clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId))
            .value()
        const {monoProvisionApps, bulkProvisionApps} = splitAppsByProvisionType(appDefinitionIdsToProvision, options)
        const monoProvisionResults = await provisionAppsSerially(ps, monoProvisionApps, options)
        const bulkProvisionResult = await bulkProvision(ps, bulkProvisionApps, options)

        _.forEach(bulkProvisionApps, appDefinitionId => appsData.push(_.find(bulkProvisionResult, {appDefinitionId})))
        return [...appsData, ...monoProvisionResults]
    }

    function onAddAppsError(ps, err, {onError}, errName, appDefId) {
        contextAdapter.utils.fedopsLogger.captureError(err, {
            tags: {
                addApps: true,
                appDefinitionId: appDefId
            },
            extras: {
                errName,
                originalError: err
            }
        })
        validateAndRunFunction(onError, err, errName, appDefId)
        throw err
    }

    function validateAndRunFunction(fn, ...args) {
        if (fn && typeof fn === 'function') {
            fn(...args)
        }
    }

    const getOriginInfo = (appDefinitionId, installOptions) => {
        if (!appDefinitionId) {
            return null
        }
        if (installOptions[appDefinitionId]?.platformOrigin) {
            const info = installOptions[appDefinitionId]?.platformOrigin?.info
            return info?.type || info?.appDefinitionId
        }
        if (installOptions[appDefinitionId]?.origin) {
            const info = installOptions[appDefinitionId]?.origin?.info
            return info?.type || info?.appDefinitionId
        }
    }

    /**
     *
     * multiple application installer function that can receive widget, section, platform, app-builder apps
     * the main phases of the func:
     * when dm_useProvisionApi is closed
     * 1. receive all apps data parallel
     * 2. distinguish the app type and add the app accordingly one-by-one
     *
     * when dm_useProvisionApi is open
     * 1. distinguish between apps that retrieved on separate requests and apps that retrieved with single request (CSM)
     * 2. receive all apps data + CSM in parallel
     * 3. distinguish the app type and add the app accordingly one-by-one
     *
     * @param ps
     * @param {String[]} appDefinitionIds
     * @param {Object} options - object with app definition id's as keys and each key holds option object that related to the app all keys should be included on the appDefinitionIds param
     * options.singleAppCallback - will be called after app is added with [appDefId, addApp result]
     * options.onError - will be called with the error in case an error is thrown
     * options.finishAllCallback - will be called with appsData after installation is finished
     * options.skipActiveApps - true by default, determines whether to skip installation of an active app or not
     * options['appDefinitionId'].headlessInstallation - if true, install only the platform part of the application.
     */
    const addApps = async (ps, appDefinitionIds, options = {}) => {
        const flowId = santaCoreUtils.guidUtils.getUniqueId()
        if (_.isNil(options.skipActiveApps)) {
            options.skipActiveApps = true
        }
        const installationOriginInfo = getOriginInfo(_.last(appDefinitionIds), options)
        let err
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
            extras: {app_ids: appDefinitionIds, installation_id: flowId},
            tags: {origin_info: installationOriginInfo}
        })
        if (generalInfo.isTemplate(ps)) {
            err = new Error('cannot add apps on template')
            onAddAppsError(ps, err, options, errNames.TEMPLATE_ERR)
        }

        if (documentModeInfo.getViewMode(ps) === dsConstants.VIEW_MODES.MOBILE) {
            err = new Error('cannot add apps on MOBILE view mode')
            onAddAppsError(ps, err, options, errNames.ADD_ON_MOBILE)
        }

        if (_.isEmpty(appDefinitionIds)) {
            err = new Error('Add Apps should receive at least one app')
            onAddAppsError(ps, err, options, errNames.EMPTY_APP_DEFS_ERR)
        }

        if (options.skipActiveApps) {
            appDefinitionIds = _.filter(appDefinitionIds, appDefinitionId => !platform.isAppActive(ps, appDefinitionId))
        }

        if (_.isEmpty(appDefinitionIds)) {
            validateAndRunFunction(options.finishAllCallback, [])
            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
                extras: {app_ids: appDefinitionIds, installation_id: flowId},
                tags: {origin_info: installationOriginInfo}
            })
            return []
        }

        let appsData = []

        try {
            contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_APPS_PROVISION, {
                extras: {app_ids: appDefinitionIds, installation_id: flowId},
                tags: {origin_info: installationOriginInfo}
            })
            if (!experiment.isOpen('dm_useProvisionApi')) {
                const provisionAppPromises = _(appDefinitionIds)
                    .filter(appDefinitionId => !platform.isAppActive(ps, appDefinitionId))
                    .map(appDefinitionId => provisionApp(ps, appDefinitionId, _.get(options, appDefinitionId)))
                    .value()
                appsData = [
                    ..._(appDefinitionIds)
                        .filter(appDefinitionId => platform.isAppActive(ps, appDefinitionId))
                        .map(appDefinitionId => clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId))
                        .values(),
                    ...(await Promise.all(provisionAppPromises))
                ]
            } else {
                appsData = await provisionApps(ps, appDefinitionIds, options)
            }
            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_APPS_PROVISION, {
                extras: {app_ids: appDefinitionIds, installation_id: flowId},
                tags: {origin_info: installationOriginInfo}
            })
        } catch (e) {
            onAddAppsError(ps, e, options, errNames.PROVISION_ERR)
        }

        const platformAppIds = _(appsData)
            .filter(
                appData =>
                    appData.appDefinitionId === constants.APPS.WIX_CODE.appDefId ||
                    [constants.APP.TYPE.PLATFORM_ONLY, constants.APP.TYPE.BLOCKS].includes(getAppType(appData))
            )
            .map('appDefinitionId')
            .value()

        if (!_.isEmpty(platformAppIds) && !workerService.isInitiated()) {
            err = new Error(`Add apps failed to install platform apps: ${appDefinitionIds} cause worker is not initiated`)
            onAddAppsError(ps, err, options, errNames.WORKER_NOT_INIT_ERR)
        }

        for (const appData of appsData) {
            if (appData.appDefinitionId !== constants.APPS.WIX_CODE.appDefId) {
                const appOptions = _.get(options, appData.appDefinitionId)
                try {
                    contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_APP_AFTER_PROVISION, {
                        extras: {app_id: appData.appDefinitionId, installation_id: flowId},
                        tags: {origin_info: installationOriginInfo}
                    })
                    const addResponse = await addApp(ps, appData, appOptions)
                    validateAndRunFunction(options.singleAppCallback, appData.appDefinitionId, addResponse)
                    contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_APP_AFTER_PROVISION, {
                        extras: {app_id: appData.appDefinitionId, installation_id: flowId},
                        tags: {origin_info: installationOriginInfo}
                    })
                } catch (e) {
                    onAddAppsError(ps, e, options, errNames.ADD_APP_ERR, appData.appDefinitionId)
                }
            }
        }

        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
            extras: {app_ids: appDefinitionIds, installation_id: flowId},
            tags: {origin_info: installationOriginInfo}
        })

        _.attempt(ps.dal.commitTransaction)
        validateAndRunFunction(options.finishAllCallback, appsData)
    }

    const getAddAppsParams = (ps, appDefinitionIds) => ({apps_ids: appDefinitionIds.join(',')})

    return {
        getAddAppsParams,
        addApps
    }
})
