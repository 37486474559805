define([], function () {
    'use strict'

    return {
        isRepeatable: false,
        resizableSides: [],
        defaultMobileProperties: {
            size: 'standard'
        }
    }
})
