define(['lodash', 'documentServices/constants/constants', 'experiment'], function (_, constants, experiment) {
    'use strict'

    function someCompAnchorsWasAlreadyRemoved(ps, pageCompPointer) {
        function someCompAnchorsWasAlreadyRemovedRec(compPointer) {
            const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
            if (!ps.dal.isExist(jsonAnchorsPointer)) {
                return true
            }

            const children = ps.pointers.components.getChildren(compPointer)
            return _.some(children, someCompAnchorsWasAlreadyRemovedRec)
        }

        return someCompAnchorsWasAlreadyRemovedRec(pageCompPointer)
    }

    function getPageCompPointer(ps, pagePointer) {
        return ps.pointers.components.getPage(pagePointer.id, constants.VIEW_MODES.DESKTOP)
    }

    const getCompsWithWrongAnchors = function (ps, pageCompPointer) {
        const pageComponentsIncludingRoot = [pageCompPointer].concat(ps.pointers.components.getChildrenRecursively(pageCompPointer))

        const compsWithJsonAnchors = _.filter(pageComponentsIncludingRoot, function (compPointer) {
            const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
            return ps.dal.isExist(jsonAnchorsPointer)
        })

        const compsWithJsonAnchorsIds = _.map(compsWithJsonAnchors, 'id')

        return _.filter(compsWithJsonAnchors, function (compPointer) {
            const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
            const anchors = ps.dal.get(jsonAnchorsPointer)

            return _.some(anchors, function (anchor) {
                return anchor.type !== 'BOTTOM_PARENT' && !_.includes(compsWithJsonAnchorsIds, anchor.targetComponent)
            })
        })
    }

    function getPagesToCheck(ps) {
        const allPages = ps.pointers.page.getNonDeletedPagesPointers(false)

        return _(allPages)
            .map(function (page) {
                return getPageCompPointer(ps, page)
            })
            .filter(function (pageCompPointer) {
                return someCompAnchorsWasAlreadyRemoved(ps, pageCompPointer)
            })
            .value()
    }

    function exec(ps) {
        if (experiment.isOpen('sv_fixBrokenAnchors')) {
            const pagesToCheck = getPagesToCheck(ps)

            _.forEach(pagesToCheck, function (pageCompPointer) {
                const compsWithWrongAnchors = getCompsWithWrongAnchors(ps, pageCompPointer)

                if (!_.isEmpty(compsWithWrongAnchors)) {
                    _.forEach(compsWithWrongAnchors, function (compPointer) {
                        const jsonAnchorsPointer = ps.pointers.getInnerPointer(compPointer, 'layout.anchors')
                        ps.dal.full.remove(jsonAnchorsPointer)
                    })
                }
            })
        }
    }

    return {
        exec,
        name: 'brokenAnchorsFixer',
        version: 1
    }
})
