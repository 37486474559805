define([], function () {
    'use strict'

    const getMasterPageDataItemPointer = ps => ps.pointers.data.getDataItemFromMaster('masterPage')
    const getLayoutSettings = ps => ps.dal.get(ps.pointers.getInnerPointer(getMasterPageDataItemPointer(ps), 'layoutSettings')) || {}

    return {
        getLayoutSettings
    }
})
