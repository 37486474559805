export const saveErrors = {
    UNKNOWN_SERVER_ERROR: 'UNKNOWN_SERVER_ERROR',
    HTTP_REQUEST_ERROR: 'HTTP_REQUEST_ERROR',

    PRESAVE_OPERATIONS_REJECTED: 'PRESAVE_OPERATIONS_REJECTED',
    SAVE_DISABLED_IN_DOCUMENT_SERVICES: 'SAVE_DISABLED_IN_DOCUMENT_SERVICES',
    SAVE_IN_PROGRESS: 'SAVE_IN_PROGRESS',
    NOT_LOGGED_IN: 'NOT_LOGGED_IN',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    USER_NOT_AUTHORIZED_FOR_SITE: 'USER_NOT_AUTHORIZED_FOR_SITE',
    PARTIAL_SAVE_THROTTLE: 'PARTIAL_SAVE_THROTTLE',
    SITE_DELETED: 'SITE_DELETED',
    FAILED_TO_PARSE_REVIEW_TOKEN: 'FAILED_TO_PARSE_REVIEW_TOKEN',
    REVIEW_TOKEN_EXPIRED: 'REVIEW_TOKEN_EXPIRED',
    CONCURRENT_SAVE: 'CONCURRENT_SAVE_ERROR',
    SITE_NAME_TAKEN: 'SITE_NAME_TAKEN_ERROR',
    DUPLICATE_COMPONENTS: 'DUPLICATE_COMPONENTS',
    DATA_REFERENCE_MISMATCH: 'DATA_REFERENCE_MISMATCH',
    MISSING_CONTAINERS: 'MISSING_CONTAINERS',
    APP_CONTROLLER_REFERENCE_MISMATCH: 'APP_CONTROLLER_REFERENCE_MISMATCH',
    DESIGN_REFERENCE_MISMATCHES: 'DESIGN_REFERENCE_MISMATCHES',
    MISSING_REFERENCE: 'MISSING_REFERENCE',
    BEHAVIOR_REFERENCE_MISMATHCHES: 'BEHAVIOR_REFERENCE_MISMATHCHES',
    PROPERTY_REFERENCE_MISMATCHES: 'PROPERTY_REFERENCE_MISMATCHES',
    STYLE_REFERENCE_MISMATCHES: 'STYLE_REFERENCE_MISMATCHES',
    CONNECTION_LIST_REFERENCE_MISMATCHES: 'CONNECTION_LIST_REFERENCE_MISMATCHES',
    SITE_DESERIALIZATION_ERROR: 'SITE_DESERIALIZATION_ERROR',
    SAVE_PUBLISH_DISABLED_ON_SERVER: 'SAVE_PUBLISH_DISABLED_ON_SERVER',
    SAVE_PUBLISH_RC_FAILED_ON_SERVER: 'SAVE_PUBLISH_RC_FAILED_ON_SERVER',
    CONCURRENT_AUTO_SAVE: 'CONCURRENT_AUTO_SAVE',
    SITE_STALE_STATE_FROM_AUTO_SAVE: 'SITE_STALE_STATE_FROM_AUTO_SAVE',
    AUTOSAVE_DISABLE_FROM_SERVER: 'AUTOSAVE_DISABLE_FROM_SERVER',
    TOO_MANY_SITES_FOR_FREE_USER: 'TOO_MANY_SITES_FOR_FREE_USER',
    USER_BLOCKED_FOR_PUBLISH: 'USER_BLOCKED_FOR_PUBLISH',
    OUTDATED_LAST_TX_ID: 'OUTDATED_LAST_TX_ID',
    RC_EXISTS: 'RC_EXISTS',
    RC_ROLLED_OUT: 'RC_ROLLED_OUT'
}

export const clientSaveErrors = {
    SAVE_DISABLED_IN_DOCUMENT_SERVICES: -10900,
    SAVE_IN_PROGRESS: -10901,
    PRESAVE_OPERATIONS_REJECTED: -10902,
    UNKNOWN_SERVER_ERROR: -10903,
    HTTP_REQUEST_ERROR: -10000
}
