define([
    'lodash',
    'tpa',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/theme/theme',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/tpa/utils/tpaUtils'
], function (_, tpa, componentStylesAndSkinsAPI, componentDetectorAPI, component, theme, generalInfo, tpaUtils) {
    'use strict'

    const COLOR_PARAM_KEY_PREFIX = 'param_color_',
        NUMBER_PARAM_KEY_PREFIX = 'param_number_',
        BOOLEAN_PARAM_KEY_PREFIX = 'param_boolean_',
        FONT_PARAM_KEY_PREFIX = 'param_font_'

    const setCompStyleParam = function (ps, compRef, data, callback) {
        const currentStyle = getCurrentStyle(ps, compRef) || getCurrentStyle(ps, ps.pointers.getOriginalPointerFromInner(compRef))
        const newStyle = _.cloneDeep(currentStyle)

        setStyleParamData(newStyle, data)
        componentStylesAndSkinsAPI.style.update(ps, compRef, newStyle, function (styleProperties) {
            const appDefinitionId = _.get(component.data.get(ps, compRef), 'appDefinitionId')
            if (!_.isEqual(newStyle, currentStyle)) {
                tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
            }
            callback(styleProperties)
        })
    }

    const setStyleParam = function (ps, compId, data, callback) {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        setCompStyleParam(ps, compPointer, data, callback)
    }

    const getSiteThemeData = ps => theme.styles.get(ps, 'THEME_DATA')

    const mapWixParamsToCssValues = function (ps, wixParams, callback) {
        const {tpaStyleUtils} = tpa
        tpaStyleUtils.getValueForWixParams(getSiteThemeData(ps), wixParams, callback)
    }

    const getCompStyle = function (ps, compRef) {
        return componentStylesAndSkinsAPI.style.get(ps, compRef)
    }

    const setStyleParamData = function (currentStyle, data) {
        if (_.isArray(data)) {
            _.forEach(data, function (item) {
                setStyleParamDataItem(currentStyle, item)
            })
        } else {
            setStyleParamDataItem(currentStyle, data)
        }
    }

    const setStyleParamDataItem = function (currentStyle, data) {
        let {key} = data
        switch (data.type) {
            case 'color':
                key = COLOR_PARAM_KEY_PREFIX + key
                setColorParam(currentStyle, key, data)
                break
            case 'font':
                key = FONT_PARAM_KEY_PREFIX + key
                setFontParam(currentStyle, key, data)
                break
            case 'number':
                key = NUMBER_PARAM_KEY_PREFIX + key
                setNumberParam(currentStyle, key, data)
                break
            case 'boolean':
                key = BOOLEAN_PARAM_KEY_PREFIX + key
                setBooleanParam(currentStyle, key, data)
                break
        }
    }

    const getCurrentStyle = function (ps, compPointer) {
        const currentStyle = getCompStyle(ps, compPointer)
        _.defaults(currentStyle, {
            style: {
                groups: {},
                properties: {},
                propertiesSource: {}
            }
        })
        if (currentStyle) {
            currentStyle.styleType = 'custom'
        }
        return currentStyle
    }

    const setBooleanParam = function (currentStyle, key, data) {
        const {value} = data.param
        currentStyle.style.properties[key] = value
        currentStyle.style.propertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
        currentStyle.style.propertiesSource[key] = 'value'
    }

    const validateNumberParam = (key, value) => {
        if (!_.isString(value) && !_.isNumber(value)) {
            return {success: false, error: `number param should be of type string or number, key: ${key}, value ${value}`}
        }

        if (_.isString(value) && !_.isNumber(JSON.parse(value))) {
            return {success: false, error: `number param of type string should hold a number, key: ${key}, value ${value}`}
        }

        return {success: true}
    }

    const setNumberParam = function (currentStyle, key, data) {
        const {value} = data.param
        const validationResult = validateNumberParam(key, value)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }
        currentStyle.style.properties[key] = value
        currentStyle.style.propertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
        currentStyle.style.propertiesSource[key] = 'value'
    }

    const setColorParam = function (currentStyle, key, data) {
        const isColorFromTheme = data.param.value.color && typeof data.param.value.color === 'object'
        const value = isColorFromTheme ? data.param.value.color.name : data.param.value.rgba || data.param.value.cssColor

        currentStyle.style.properties[key] = value
        currentStyle.style.propertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
        currentStyle.style.propertiesSource[key] = isColorFromTheme ? 'theme' : 'value'

        if (data.param.value.hasOwnProperty('opacity')) {
            currentStyle.style.properties[`alpha-${key}`] = data.param.value.opacity
        }
    }

    const setFontParam = function (currentStyle, key, data) {
        const {value} = data.param
        if (currentStyle && currentStyle.style) {
            currentStyle.style.properties[key] = JSON.stringify(value)
            currentStyle.style.propertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
            currentStyle.style.propertiesSource[key] = 'value'
        }
    }

    const getTpaColorsToSiteColors = function () {
        const COLOR_PREFIX = 'color'
        const PRIME_COLORS_REFERENCES = ['white/black', 'black/white', 'primery-1', 'primery-2', 'primery-3']
        const colorNamesByColorReferences = _.reduce(
            PRIME_COLORS_REFERENCES,
            function (result, value, key) {
                result[value] = `${COLOR_PREFIX}_${++key}`
                return result
            },
            {}
        )

        const arr = _.range(1, 26)
        return _.reduce(
            arr,
            function (result, value) {
                result[`${COLOR_PREFIX}-${value}`] = `${COLOR_PREFIX}_${value + 10}`
                return result
            },
            colorNamesByColorReferences
        )
    }

    const getStyleId = function (ps, compRef) {
        const compData = component.data.get(ps, compRef)
        const origCompId = _.get(compData, 'origCompId')
        const compPointer = _.isUndefined(origCompId) ? compRef : componentDetectorAPI.getComponentById(ps, origCompId)
        if (compPointer) {
            return componentStylesAndSkinsAPI.style.getId(ps, compRef)
        }
        return null
    }

    const getCompStyleDataToPassIntoApp = function (ps, compRef) {
        const styleId = getStyleId(ps, compRef)
        const themes = {
            THEME_DATA: getSiteThemeData(ps)
        }

        if (styleId) {
            themes[styleId] = theme.styles.get(ps, styleId)
        }

        const documentType = generalInfo.getDocumentType(ps)
        const characterSets = theme.fonts.getCharacterSet(ps)
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        return tpa.common.styleUtils.getStyleDataToPassIntoApp(styleId, themes, false, documentType, characterSets, serviceTopology, false)
    }

    const getStyleDataToPassIntoApp = function (ps, compId) {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        return getCompStyleDataToPassIntoApp(ps, compPointer)
    }

    return {
        setStyleParam,
        setCompStyleParam,
        setStyleParamData: (ps, currentStyle, data) => setStyleParamData(currentStyle, data),
        mapWixParamsToCssValues,
        getTpaColorsToSiteColors,
        getStyleDataToPassIntoApp,
        getCompStyleDataToPassIntoApp
    }
})
