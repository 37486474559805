import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

/**
 * @class ecom.EcomJoinedCartLogic
 * @param partApi
 * @constructor
 */
function ViewCartLogic(partApi) {
    this.partApi = partApi
}

ViewCartLogic.prototype = {
    getViewVars() {
        const partData = this.partApi.getPartData()
        const params = _.mapValues(partData.appLogicParams, 'value')

        return {
            viewCartLink: {
                _type: 'wix:PageLink',
                pageId: params.cartPageLink
            }
        }
    },
    getUserCustomizations(customizations) {
        const ret = _.cloneDeep(customizations)
        const partData = this.partApi.getPartData()
        const formatName = this.partApi.getFormatName()
        const params = _.mapValues(partData.appLogicParams, 'value')

        const buttonTypeCustomization = _.find(ret, {view: partData.viewName, fieldId: 'vars', key: 'buttonType'})
        const cartTextCustomization = _.find(ret, {view: partData.viewName, fieldId: 'vars', key: 'cartText'})

        const buttonType = (buttonTypeCustomization && buttonTypeCustomization.value) || params.viewCartType || 'itemsCount'
        const cartText =
            (cartTextCustomization && cartTextCustomization.value) ||
            params.viewCartText ||
            wixapps.localizer.localize('@ECOM_VIEW_CART_BUTTON_DEFAULT_TEXT@', this.partApi.getLocalizationBundle())

        if (buttonTypeCustomization) {
            buttonTypeCustomization.value = buttonType
            buttonTypeCustomization.format = formatName
        } else {
            ret.push({forType: 'Cart', view: partData.viewName, fieldId: 'vars', format: formatName, key: 'buttonType', value: buttonType})
        }

        if (cartTextCustomization) {
            cartTextCustomization.value = cartText
            cartTextCustomization.format = formatName
        } else {
            ret.push({forType: 'Cart', view: partData.viewName, fieldId: 'vars', format: formatName, key: 'cartText', value: cartText})
        }

        return ret
    }
}

// Register for FAQ part
wixapps.logicFactory.register('c029b3fd-e8e4-44f1-b1f0-1f83e437d45c', ViewCartLogic)
