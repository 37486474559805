import {DmApis, Extension, pointerUtils, CreateExtensionArgument} from '@wix/document-manager-core'
import _ from 'lodash'
import {createImmutableDAL} from './immutableDAL'
import {loadWixappsData} from './wixappsLoadUtils'
import type {PageAPI} from '../page'
const {getPointer} = pointerUtils
import {getIdFromRef} from '../../utils/dataUtils'

/**
 * Creates the wixapps extension that will provide the wixappsDAL service to privateServices
 */
const createExtension = ({environmentContext}: CreateExtensionArgument): Extension => {
    const {fetchFn} = environmentContext
    const wixappsDAL = createImmutableDAL({})
    let resolveWait: Function, rejectWait: Function //eslint-disable-line one-var
    let waitingForData: Promise<unknown> | null = new Promise((resolve, reject) => {
        resolveWait = resolve
        rejectWait = reject
    })

    const createExtensionAPI = () => ({
        fetchWixappsDataFromDM: async (requestDescriptor: any) => {
            if (waitingForData) {
                await waitingForData
            }
            const {destination, applicationInstanceId} = requestDescriptor
            if (wixappsDAL.isPathExist(destination)) {
                const appbuilderRepo = wixappsDAL.getByPath(['wixapps', 'appbuilder']) //TODO: use by destination?
                const repo = _.pick(appbuilderRepo.descriptor, ['dataProviders', 'dataSelectors', 'pages', 'views', 'parts'])
                const {types} = appbuilderRepo.descriptor
                const items = _.flatMap(appbuilderRepo.items, v => _.values(v))

                return {
                    appInstanceId: applicationInstanceId,
                    ownerId: '', //TODO: do we need? How do we retrieve real value?
                    tags: {},
                    version: appbuilderRepo.descriptor.applicationInstanceVersion,
                    types,
                    items,
                    repo
                }
            }
            return null
        },
        services: {
            wixappsDAL
        }
    })

    const initialize = async ({dal, pointers, extensionAPI}: DmApis) => {
        try {
            const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
            const appBuilderCSMEEntry = _.find(clientSpecMap, {type: 'appbuilder'})
            const siteMetaData = dal.get(pointers.rendererModel.getSiteMetaData())
            const avoidAppPart2 = siteMetaData.renderHints && siteMetaData.renderHints.containsAppPart2 === false

            if (appBuilderCSMEEntry && !avoidAppPart2) {
                const isFirstSave = dal.get(pointers.save.getNeverSaved())
                const applicationInstanceId = appBuilderCSMEEntry.instanceId
                const appParts = dal.query(
                    'DESKTOP',
                    (extensionAPI.page as PageAPI).getAllPagesIndexId(),
                    ({componentType}) => componentType === 'wixapps.integration.components.AppPart2'
                )

                const appPartNames = _.map(appParts, ({dataQuery}) => dal.get(getPointer(getIdFromRef(dataQuery), 'data', ['appPartName'])))

                await loadWixappsData(wixappsDAL, appPartNames, applicationInstanceId, {isFirstSave}, fetchFn)
                wixappsDAL.takeSnapshot()
            }

            waitingForData = null
            resolveWait()
        } catch (e) {
            console.error('failed loading wixapps data', e)
            rejectWait()
        }
    }

    return {
        name: 'wixapps',
        dependencies: new Set(['page']),
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
