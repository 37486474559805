define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/theme/theme',
    'documentServices/component/component',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/page/page',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/tpaSectionService',
    'documentServices/tpa/services/tpaWidgetService',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/appMarketService',
    'documentServices/tpa/constants',
    'documentServices/tpa/services/tpaComponentService',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants'
], function (
    _,
    santaCoreUtils,
    theme,
    component,
    componentStylesAndSkinsAPI,
    componentDetectorAPI,
    page,
    clientSpecMapService,
    tpaSectionService,
    tpaWidgetService,
    installedTpaAppsOnSiteService,
    appMarketService,
    tpaConstants,
    tpaComponentService,
    contextAdapter,
    dsConstants
) {
    'use strict'

    const compTypes = {
        WIDGET: 'WIDGET',
        PAGE: 'PAGE'
    }

    const getNewCompStyleId = function (ps, copyStyle, styleId, compId) {
        let newStyleId
        if (copyStyle) {
            if (styleId) {
                if (_.includes(theme.styles.getAllIds(ps), styleId)) {
                    newStyleId = styleId
                }
            } else {
                const comp = componentDetectorAPI.getComponentById(ps, compId)
                newStyleId = componentStylesAndSkinsAPI.style.getId(ps, comp)
            }
        }
        return newStyleId
    }

    function getWidgetParams(ps, appData, options, compId) {
        const curPageId = ps.siteAPI.getFocusedRootId()
        const pageId = _.get(options, 'widget.wixPageId') || curPageId
        const widget = clientSpecMapService.getWidgetDataFromTPAWidgetId(ps, appData.appDefinitionId, _.get(options, 'widget.tpaWidgetId'))
        return {
            pageId,
            widgetId: _.get(widget, 'widgetId'),
            showOnAllPages: _.get(options, 'widget.allPages'),
            styleId: getNewCompStyleId(ps, options.copyStyle, options.styleId, compId)
        }
    }

    function provisionWidget(options, ps, compId, componentToAddRef, appData) {
        _.assign(options, getWidgetParams(ps, appData, options, compId))
        tpaComponentService.provisionWidget(ps, componentToAddRef, appData.appDefinitionId, options)
    }

    const provisionWidgetComponent = function (ps, componentToAddRef, appData, compId, options) {
        const curPageId = ps.siteAPI.getFocusedRootId()
        const pageId = _.get(options, 'widget.wixPageId') || curPageId
        if (ps.pointers.page.isExists(pageId) || options.widget.allPages) {
            const widget = clientSpecMapService.getWidgetDataFromTPAWidgetId(ps, appData.appDefinitionId, _.get(options, 'widget.tpaWidgetId'))
            if (widget) {
                provisionWidget(options, ps, compId, componentToAddRef, appData)
            } else {
                ps.setOperationsQueue.asyncPreDataManipulationComplete({
                    onError: 'No widget found.'
                })
            }
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'pageId was not found.'
            })
        }
    }

    const provisionComponentImpl = function (ps, componentToAddRef, compId, appData, options) {
        if (appData) {
            switch (options.componentType) {
                case compTypes.WIDGET:
                    provisionWidgetComponent(ps, componentToAddRef, appData, compId, options)
                    break
                case compTypes.PAGE:
                    provisionPageComponent(ps, componentToAddRef, appData, compId, options)
                    break
                default:
                    ps.setOperationsQueue.asyncPreDataManipulationComplete({
                        onError: 'componentType not supported.'
                    })
            }
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'General Error.'
            })
        }
    }

    const getSectionParams = function (ps, options, compId) {
        return {
            pageId: _.get(options, 'page.pageId'),
            title: _.get(options, 'page.title'),
            styleId: getNewCompStyleId(ps, options.copyStyle, options.styleId, compId),
            layout: getLayoutFromOptions(options),
            isHidden: _.get(options, 'page.isHidden'),
            requireLogin: _.get(options, 'page.requireLogin')
        }
    }

    function provisionMultiSection(ps, pageToAddRef, options, appData, compId) {
        _.assign(options, getSectionParams(ps, options, compId))
        tpaComponentService.provisionMultiSection(ps, pageToAddRef, appData.appDefinitionId, options)
    }

    function handleSubSection(ps, options, compId, appData) {
        _.assign(options, getSectionParams(ps, options, compId))
        ps.setOperationsQueue.asyncPreDataManipulationComplete(appData)
    }

    const provisionPageComponent = function (ps, pageToAddRef, appData, compId, options) {
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, _.get(options, 'page.pageId'))
        if (widgetData) {
            if (clientSpecMapService.isMultiSectionInstanceEnabled(appData, widgetData.widgetId)) {
                provisionMultiSection(ps, pageToAddRef, options, appData, compId)
            } else if (!installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId(ps, appData.applicationId, _.get(widgetData, 'appPage.id'))) {
                handleSubSection(ps, options, compId, appData)
            } else {
                ps.setOperationsQueue.asyncPreDataManipulationComplete({
                    onError: 'Page already installed.'
                })
            }
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'No page found.'
            })
        }
    }

    const getLayoutFromOptions = function (options) {
        const layout = {}
        if (_.isNumber(options.x)) {
            layout.x = options.x
        }
        if (_.isNumber(options.y)) {
            layout.y = options.y
        }
        if (_.isNumber(options.width)) {
            layout.width = options.width
        }
        if (_.isNumber(options.height)) {
            layout.height = options.height
        }
        return layout
    }

    const getCompRef = function (ps, appDefId, options) {
        if (options.componentType === compTypes.WIDGET) {
            const pageId = ps.siteAPI.getPrimaryPageId()
            const pagePointer = ps.pointers.page.getPagePointer(pageId)
            return component.getComponentToAddRef(ps, pagePointer, options, _.get(options, 'optionalCustomId'))
        }
        return page.getPageIdToAdd(ps)
    }

    const provisionComp = function (ps, options = {}) {
        const {appDefinitionId} = options
        if (appDefinitionId) {
            const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            if (clientSpecMapService.isAppActive(ps, appData)) {
                options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
                provisionComponentImpl(ps, options.componentToAddRef, options.compId, appData, options)
            } else {
                const compOptions = _.clone(options)
                _.forEach(_.keys(options), function (key) {
                    delete options[key]
                })
                options.appFlow = true
                options.componentOptions = compOptions
                options.componentOptions.layout = getLayout(compOptions)
                options.callback = compOptions.callback
                provisionApp(ps, appDefinitionId, options)
            }
        } else {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'appDefinitionId is mandatory.'
            })
        }
    }

    const isWidgetInstalled = function (appData, options) {
        const widgetData = _.find(appData.widgets, function (widget) {
            if (options.componentType === compTypes.WIDGET) {
                return widget.tpaWidgetId === options.widget.tpaWidgetId
            } else if (options.componentType === compTypes.PAGE) {
                return _.get(widget, 'appPage.id') === options.page.pageId
            }
            return null
        })
        return !!_.get(widgetData, 'autoAddToSite')
    }

    const getCompAdded = function (ps, appData, widgetData, callback) {
        if (widgetData) {
            const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppId(ps, appData.applicationId)
            return _.find(comps, {widgetId: _.get(widgetData, 'widgetId')}) || _.find(comps, {widgetId: null})
        }
        callback({
            onError: 'No widget found.'
        })
        return null
    }

    const triggerCallback = function (ps, options, appData, callback) {
        let widgetData, comp
        if (options.componentType === compTypes.WIDGET) {
            widgetData = clientSpecMapService.getWidgetDataFromTPAWidgetId(ps, appData.appDefinitionId, options.widget.tpaWidgetId)
            comp = getCompAdded(ps, appData, widgetData, callback)
            if (comp) {
                const compRef = componentDetectorAPI.getComponentById(ps, comp.id)
                tpaWidgetService.invokeWidgetCallback({callback}, compRef, true)
            }
        } else if (options.componentType === compTypes.PAGE) {
            widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, options.page.pageId)
            comp = getCompAdded(ps, appData, widgetData, callback)
            if (comp) {
                const pageRef = ps.pointers.components.getPage(comp.pageId, ps.siteDataAPI.siteData.getViewMode())
                tpaSectionService.invokeSectionCallback({callback}, pageRef, comp.id, true)
            }
        }
    }

    const addComponent = function (ps, data, options) {
        if (data.onError) {
            if (_.isFunction(options.callback)) {
                options.callback(data)
            }
        } else {
            if (options.appFlow) {
                const callback = options.callback || _.noop
                options.callback = () => {
                    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, options.componentOptions.appDefinitionId)
                    if (isWidgetInstalled(appData, options.componentOptions)) {
                        triggerCallback(ps, options.componentOptions, appData, callback)
                    } else {
                        const params = {
                            componentType: options.componentOptions.componentType,
                            componentToAddRef: getCompRef(ps, options.componentOptions.appDefinitionId, options.componentOptions),
                            callback
                        }
                        if (params.componentType === compTypes.WIDGET) {
                            _.assign(params, getWidgetParams(ps, appData, options.componentOptions, options.compId))
                        } else if (params.componentType === compTypes.PAGE) {
                            _.assign(params, getSectionParams(ps, options.componentOptions, options.compId))
                        }
                        addComponent(ps, data, params)
                    }
                }
            }

            switch (options.componentType) {
                case compTypes.WIDGET:
                    tpaComponentService.addAppWrapper(tpaConstants.TYPE.TPA_WIDGET, ps, data, options.componentToAddRef, data.appDefinitionId, options)
                    break
                case compTypes.PAGE:
                    if (options.appFlow) {
                        tpaComponentService.addSection(ps, data, options.componentToAddRef, data.appDefinitionId, options)
                    } else {
                        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, data.appDefinitionId, _.get(options, 'pageId'))
                        if (widgetData) {
                            if (clientSpecMapService.isMultiSectionInstanceEnabled(data, widgetData.widgetId)) {
                                tpaComponentService.addMultiSection(ps, data, options.componentToAddRef, data.appDefinitionId, options)
                            } else if (!installedTpaAppsOnSiteService.isSectionInstalledByTpaPageId(ps, data.applicationId, _.get(widgetData, 'appPage.id'))) {
                                tpaComponentService.addSubSection(ps, options.componentToAddRef, data.appDefinitionId, options)
                            }
                        }
                    }
                    break
            }
        }
    }

    const provisionApp = function (ps, appDefinitionId, options = {}) {
        if (!appDefinitionId) {
            ps.setOperationsQueue.asyncPreDataManipulationComplete({
                onError: 'appDefinitionId is mandatory.'
            })
            return
        }
        // eslint-disable-next-line promise/prefer-await-to-then
        appMarketService.getAppMarketDataAsync(ps, appDefinitionId).then(function (marketData) {
            if (!marketData || marketData.error) {
                ps.setOperationsQueue.asyncPreDataManipulationComplete({
                    onError: `Application with ${appDefinitionId} appDefinitionId do not exist.`
                })
                return
            }
            options.componentType = marketData.hasSection ? compTypes.PAGE : compTypes.WIDGET
            options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
            if (options.componentType === compTypes.WIDGET) {
                options.layout = getWidgetLayout(options)
                if (options.pageId && !ps.pointers.page.isExists(options.pageId)) {
                    delete options.pageId
                }
                tpaComponentService.provisionWidget(ps, options.componentToAddRef, appDefinitionId, options)
            } else if (options.componentType === compTypes.PAGE) {
                if (tpaSectionService.alreadyInstalled(ps, appDefinitionId)) {
                    ps.setOperationsQueue.asyncPreDataManipulationComplete({
                        onError: 'Section already installed'
                    })
                } else {
                    options.layout = getSectionLayoutFromOptions(options)
                    tpaComponentService.provisionSection(ps, options.componentToAddRef, appDefinitionId, options, options.callback)
                }
            }
        })
    }

    const addApp = function (ps, data, appDefinitionId, options) {
        if (data.onError) {
            if (_.isFunction(options.callback)) {
                options.callback(data)
            }
        } else {
            switch (options.componentType) {
                case compTypes.WIDGET:
                    tpaComponentService.addAppWrapper(tpaConstants.TYPE.TPA_WIDGET, ps, data, options.componentToAddRef, appDefinitionId, options)
                    break
                case compTypes.PAGE:
                    tpaComponentService.addSection(ps, data, options.componentToAddRef, appDefinitionId, options)
                    break
            }
        }
    }

    const getWidgetLayout = function (options) {
        let layout = {}
        if (options && _.isNumber(options.x) && _.isNumber(options.y)) {
            layout = {
                x: options.x,
                y: options.y
            }
        }
        return layout
    }

    const getLayout = function (componentOptions) {
        if (_.get(componentOptions, 'componentType') === compTypes.WIDGET) {
            return getWidgetLayout(componentOptions)
        }
        return getSectionLayoutFromOptions(componentOptions)
    }

    const getSectionLayoutFromOptions = function (options) {
        const layout = {}
        if (options) {
            if (_.isNumber(options.x)) {
                layout.x = options.x
            }
            if (_.isNumber(options.y)) {
                layout.y = options.y
            }
            if (_.isNumber(options.width)) {
                layout.width = options.width
            }
            if (_.isNumber(options.height)) {
                layout.height = options.height
            }
        }
        return layout
    }

    const getAddTPAInteractionParams = (ps, data, appDefinitionId) => ({app_id: appDefinitionId})

    const getAddTPAAppInteractionParams = (ps, appDefinitionId) => ({app_id: appDefinitionId})

    const addWidgetAfterProvision = (ps, data, appDefinitionId, options, onSuccess, onError) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_WIDGET_AFTER_PROVISION, {
            tags: {
                appDefinitionId
            },
            extras: {app_id: appDefinitionId, options}
        })
        options.componentType = compTypes.WIDGET
        options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
        options.layout = getWidgetLayout(options)
        if (options.pageId && !ps.pointers.page.isExists(options.pageId)) {
            delete options.pageId
        }

        const wrapOnSuccess = (...args) => {
            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_WIDGET_AFTER_PROVISION, {
                tags: {
                    appDefinitionId
                },
                extras: {app_id: appDefinitionId, options}
            })
            onSuccess(...args)
        }

        const wrapOnError = (...args) => {
            contextAdapter.utils.fedopsLogger.captureError(args, {
                tags: {
                    appDefinitionId
                },
                extras: {
                    errName: dsConstants.PLATFORM_ERRORS.ADD_WIDGET_AFTER_PROVISION,
                    app_id: appDefinitionId,
                    originalError: {...args}
                }
            })
            onError(...args)
        }

        tpaComponentService.addAppWrapper(
            tpaConstants.TYPE.TPA_WIDGET,
            ps,
            data,
            options.componentToAddRef,
            appDefinitionId,
            options,
            wrapOnSuccess,
            wrapOnError,
            false
        )
    }

    const addSectionAfterProvision = (ps, data, appDefinitionId, options, onSuccess, onError) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ADD_SECTION_AFTER_PROVISION, {
            tags: {
                appDefinitionId
            },
            extras: {app_id: appDefinitionId, options}
        })
        options.componentType = compTypes.PAGE
        options.componentToAddRef = getCompRef(ps, appDefinitionId, options)
        options.layout = getSectionLayoutFromOptions(options)
        const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${santaCoreUtils.guidUtils.getUniqueId()}`
        options.sectionId = sectionId
        options = _.merge(options, _.get(data, 'additionalOptions'))
        const appData = _.omit(data, 'additionalOptions')
        _.assign(options.componentToAddRef, {sectionId})

        const wrapOnSuccess = (...args) => {
            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ADD_SECTION_AFTER_PROVISION, {
                tags: {
                    appDefinitionId
                },
                extras: {app_id: appDefinitionId, options}
            })
            onSuccess(...args)
        }

        const wrapOnError = (...args) => {
            contextAdapter.utils.fedopsLogger.captureError(args, {
                tags: {
                    appDefinitionId
                },
                extras: {
                    errName: dsConstants.PLATFORM_ERRORS.ADD_SECTION_AFTER_PROVISION,
                    app_id: appDefinitionId,
                    originalError: {...args}
                }
            })
            onError(...args)
        }

        tpaComponentService.addAppWrapper(
            tpaConstants.TYPE.TPA_SECTION,
            ps,
            appData,
            options.componentToAddRef,
            appDefinitionId,
            options,
            wrapOnSuccess,
            wrapOnError,
            false
        )
    }

    return {
        provisionComp,
        addComponent,
        addWidgetAfterProvision,
        addSectionAfterProvision,
        provisionApp,
        getAddTPAInteractionParams,
        getAddTPAAppInteractionParams,
        addApp
    }
})
