define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/componentsMetaData/metaDataUtils'
], function (_, dataModel, extensionsAPI, documentModeInfo, metaDataUtils) {
    'use strict'

    const WIDGET_TO_NICKNAMES = {
        '142bb34d-3439-576a-7118-683e690a1e0d': 'gallery',
        '14517f3f-ffc5-eced-f592-980aaa0bbb5c': 'wixChat'
    }

    function getWidgetData(ps, compData) {
        if (!_.has(compData, 'applicationId') || !_.has(compData, 'widgetId')) {
            return {}
        }
        const clientSpecMapPtr = ps.pointers.general.getClientSpecMap()
        const csm = extensionsAPI.data.getNoClone(ps, clientSpecMapPtr)
        const result = _.get(csm, [compData.applicationId, 'widgets', compData.widgetId]) || {}
        return _.cloneDeep(result)
    }

    const getFromStageData = (ps, compRef, stageDataKey, defaultValue) => metaDataUtils.getFromStageData(ps, stageDataKey, compRef, defaultValue)

    return {
        isRepeatable: false,
        getWidgetData,
        nickname(ps, compPointer) {
            const nicknameFromStageData = metaDataUtils.getNicknameFromStageData(ps, compPointer)
            if (nicknameFromStageData) {
                return nicknameFromStageData
            }

            const compData = dataModel.getDataItem(ps, compPointer)
            const widgetData = getWidgetData(ps, compData)
            let nickname = ''
            const widgetId = _.get(widgetData, 'widgetId')

            if (WIDGET_TO_NICKNAMES.hasOwnProperty(widgetId)) {
                nickname = WIDGET_TO_NICKNAMES[widgetId]
            } else {
                nickname = _.camelCase(widgetData.tpaWidgetId)
            }

            return nickname
        },
        rotatable(ps, compRef) {
            return getFromStageData(ps, compRef, 'rotatable', false)
        },
        canBeFixedPosition(ps, compRef) {
            return getFromStageData(ps, compRef, 'pinnable', true)
        },
        removable(ps, compRef) {
            return getFromStageData(ps, compRef, 'removable', true)
        },
        duplicatable(ps, compRef) {
            return getFromStageData(ps, compRef, 'duplicatable', !documentModeInfo.isMobileView(ps))
        },
        resizable(ps, compRef) {
            return getFromStageData(ps, compRef, 'resizable', true)
        },
        canReparent(ps, compRef) {
            return getFromStageData(ps, compRef, 'canReparent', true)
        }
    }
})
