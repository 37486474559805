define(['lodash'], function (_) {
    'use strict'

    function getWidgetDisplayName(platformAppConfiguration, widgetId) {
        if (!platformAppConfiguration || !platformAppConfiguration.api) {
            return null
        }
        const tpaConfigurationFile = platformAppConfiguration.api
        const platformAppConfigurationObj = _.assign({}, _.assign({}, ...tpaConfigurationFile))
        if (_.has(platformAppConfigurationObj, widgetId)) {
            return _.get(platformAppConfigurationObj[widgetId], 'widgetDisplayName') || widgetId
        }
        return null
    }

    return {
        getWidgetDisplayName
    }
})
