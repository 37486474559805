import santaComponents from '@wix/santa-components'
import boxProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/boxProxy'

/**
 * @class proxies.VBox
 * @extends proxies.mixins.boxProxy
 */
export default {
    mixins: [boxProxy],
    getChildrenOrientation() {
        return 'vertical'
    },

    getReactClass() {
        return santaComponents.utils.createReactElement.bind(null, 'div')
    }
}
