define([
    'documentServices/metaSiteProvisioner/metaSiteProvisioner',
    'documentServices/wixCode/services/wixCodeLifecycleService',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/utils/utils',
    'wixCode'
], function (metaSiteProvisioner, wixCodeLifecycleService, kibana, wixCodeUtils, wixCode) {
    'use strict'

    function reportResultToKibana(traceEnd, serverResponse) {
        if (!serverResponse) {
            traceEnd({message: 'no wix code apps to save'})
        } else if (serverResponse.success) {
            traceEnd({message: serverResponse.payload.clientSpecMap})
        } else {
            traceEnd({message: serverResponse, level: kibana.levels.ERROR})
        }
    }

    function getClientSpecMap(snapshot, snapshotDal) {
        return snapshotDal
            ? snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
            : wixCodeUtils.extractFromSnapshot(snapshot, ['rendererModel', 'clientSpecMap'], true)
    }

    const saveWixCodeApps = metaSiteProvisioner(
        (lastSnapshot, currentSnapshot, concurrencyResolver, reject, bi, options, lastSnapshotDal, currentSnapshotDal) => {
            const clientSpecMap = getClientSpecMap(currentSnapshot, currentSnapshotDal)
            const wixCodeApp = wixCode.wixCodeWidgetService.getWixCodeSpec(clientSpecMap)

            if (!wixCodeApp) {
                concurrencyResolver()
                return
            }

            const traceEnd = kibana.traceWithSnapshot(
                currentSnapshot,
                {
                    action: 'saveWixCodeInMetasite'
                },
                currentSnapshotDal
            )

            function resolveFunc(serverResponse) {
                reportResultToKibana(traceEnd, serverResponse)
                concurrencyResolver(serverResponse)
            }

            function rejectFunc(error) {
                traceEnd({message: error, level: kibana.levels.ERROR})
                reject(error)
            }

            wixCodeLifecycleService.save(currentSnapshot, resolveFunc, rejectFunc, currentSnapshotDal)
        }
    )

    return saveWixCodeApps
})
