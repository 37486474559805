import _ from 'lodash'
import {DocumentManager, Pointer, pointerUtils} from '@wix/document-manager-core'
import {dataUtils} from '@wix/document-manager-extensions'
import {cleanRef} from './utils/migrationUtil'
const {getPointer} = pointerUtils

const removeLinksThatDontAppearInText = (textDataItem: any) =>
    _.filter(textDataItem.linkList, (linkHashId: any) => !_.isEmpty(linkHashId) && textDataItem.text.includes(linkHashId))

const removeLinksThatDontPointToDataItem = (documentManager: DocumentManager, textDataItem: any) =>
    _.filter(textDataItem.linkList, (linkHashId: string) => {
        const linkPointer = getPointer(cleanRef(linkHashId), 'data')
        const link = documentManager.dal.get(linkPointer)
        if (!link) {
            return false
        }
        return true
    })

const cleanLinksThatDontAppearInText = (documentManager: DocumentManager, textDataPointer: Pointer) => {
    const textDataItem = documentManager.dal.get(textDataPointer)
    if (!textDataItem || _.isEmpty(textDataItem.linkList)) {
        return
    }

    const newLinkList = removeLinksThatDontAppearInText(textDataItem)
    if (newLinkList.length !== textDataItem.linkList.length) {
        documentManager.dal.set(textDataPointer, {
            ...textDataItem,
            linkList: newLinkList
        })
    }
}

const cleanLinksThatDontPointToDataItem = (documentManager: DocumentManager, textDataPointer: Pointer) => {
    const textDataItem = documentManager.dal.get(textDataPointer)
    if (!textDataItem || _.isEmpty(textDataItem.linkList)) {
        return
    }

    const newLinkList = removeLinksThatDontPointToDataItem(documentManager, textDataItem)
    if (newLinkList.length !== textDataItem.linkList.length) {
        documentManager.dal.set(textDataPointer, {
            ...textDataItem,
            linkList: newLinkList
        })
    }
}

const isLinkInCurrentPage = (documentManager: DocumentManager, linkHashId: string, pageId: string) => {
    const linkPointer = getPointer(cleanRef(linkHashId), 'data')
    const link = documentManager.dal.get(linkPointer)
    return link && link.metaData.pageId === pageId
}

const cloneLinkToPage = (documentManager: DocumentManager, linkHashIdToBeCloned: string, pageId: string) => {
    const linkPointerToBeCloned = getPointer(cleanRef(linkHashIdToBeCloned), 'data')
    const linkToBeCloned = documentManager.dal.get(linkPointerToBeCloned)

    const newId = `textLink${dataUtils.generateUniqueIdByType('', pageId, documentManager.dal, documentManager.pointers)}`
    const newLinkPointer = getPointer(newId, 'data')
    const newLink = {...linkToBeCloned, metaData: {...linkToBeCloned.metaData, pageId}, id: newId}
    documentManager.dal.set(newLinkPointer, newLink)
    return newId
}

const replaceSharedLinksBetweenPagesWithNewLinks = (documentManager: DocumentManager, textDataPointer: Pointer) => {
    const textDataItem = documentManager.dal.get(textDataPointer)
    if (!textDataItem || _.isEmpty(textDataItem.linkList)) {
        return
    }
    let corruptionFound = false
    let newText = _.clone(textDataItem.text)
    const newLinkList: string[] = []
    textDataItem.linkList.forEach((linkHashId: string) => {
        if (isLinkInCurrentPage(documentManager, linkHashId, textDataItem.metaData.pageId)) {
            newLinkList.push(linkHashId)
            return
        }
        corruptionFound = true
        const newId = cloneLinkToPage(documentManager, linkHashId, textDataItem.metaData.pageId)
        const newLinkHashId = `#${newId}`
        newLinkList.push(newLinkHashId)
        newText = newText.replace(linkHashId, newLinkHashId)
    })
    if (corruptionFound) {
        documentManager.dal.set(textDataPointer, {
            ...textDataItem,
            ...{
                text: newText,
                linkList: newLinkList
            }
        })
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const textDataPointers = documentManager.pointers.data.getDataItemsWithPredicate(dataItem => dataItem.type === 'StyledText', pageId)
    _.forEach(textDataPointers, textDataPointer => {
        cleanLinksThatDontAppearInText(documentManager, textDataPointer)
        cleanLinksThatDontPointToDataItem(documentManager, textDataPointer)
        if (documentManager.experimentInstance.isOpen('dm_replaceSharedLinksBetweenPagesWithNewLinks')) {
            replaceSharedLinksBetweenPagesWithNewLinks(documentManager, textDataPointer)
        }
    })
}

const name = 'removeCorruptedLinksFromStyledText'
const version = 1

export {migratePage, name, version}
