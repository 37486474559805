import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'
import proxyFactory from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxyFactory'
import styleTranslator from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleTranslator'

const spacerCompSizeProp = {
    HSpacer: 'width',
    VSpacer: 'min-height'
}

const SPACER_COMP_NAMES = ['HSpacer', 'VSpacer']

function isSpacer(componentName) {
    return _.includes(SPACER_COMP_NAMES, componentName)
}

function getSpacerStyle(spacerViewDef, props) {
    const spacerStyle = this.getStyleDef(spacerViewDef, props)
    spacerStyle.boxSizing = 'border-box'
    const size = this.getCompProp('size', spacerViewDef)
    if (size === '*') {
        spacerStyle.boxFlex = 1
    } else {
        const compName = this.getCompProp('name', spacerViewDef)
        spacerStyle[spacerCompSizeProp[compName]] = size
    }
    return styleTranslator.translate(spacerStyle, props.orientation)
}

function getSpacerElement(spacerViewDef, props) {
    if (this.getCompProp('hidden', spacerViewDef)) {
        return null
    }
    props.style = getSpacerStyle.call(this, spacerViewDef, props)
    return santaComponents.utils.createReactElement('div', props)
}

/**
 * @class proxies.mixins.baseComposite
 * @extends proxies.mixins.baseProxy
 * @property {proxy.properties} props
 */
export default {
    mixins: [baseProxy],

    renderChildProxy(childDef, key, childProxyStyle, props) {
        props = props || this.getChildProxyProps(childDef)
        key = String(key || '0')
        props.key = key
        props.ref = key
        props.refInParent = key
        props.proxyLayout = childProxyStyle

        if (this.getChildrenOrientation) {
            props.orientation = this.getChildrenOrientation()
        }

        const childCompName = this.getCompProp('name', childDef)

        if (isSpacer(childCompName)) {
            return getSpacerElement.call(this, childDef, props)
        }

        return proxyFactory.getProxyClass(childCompName)(props)
    }
}
