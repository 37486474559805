import componentsCore from './src/componentsCore'
export default componentsCore
export const {
    dataRequirementsCheckerRegistrar,
    compRegistrar,
    siteAspectsRegistry,
    santaTypesFetchersRegistry,
    activityTypes,
    activityService,
    mixins,
    utils,
    wixTransitionGroup
} = componentsCore
