define(['documentServices/utils/contextAdapter'], function (contextAdapter) {
    'use strict'

    const logger = {
        getLogger: () => contextAdapter.utils.fedopsLogger
    }

    return {
        logger
    }
})
