import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'
import _ from 'lodash'

const componentType = 'wysiwyg.viewer.components.FiveGridLine'

function transformSkinProperties(refData) {
    refData[''] = _.merge({}, refData[''], {style: {position: 'relative'}})

    return refData
}

/**
 * @class proxies.HorizontalLine
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],
    statics: {
        componentType
    },
    renderProxy() {
        const props = this.getChildCompProps(componentType, transformSkinProperties)
        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
