import _ from 'lodash'
import newrelic from './newrelic'

const START_MARKER = ' start'
const FINISH_MARKER = ' finish'
const PERFORMANCE_PARAM = 'performance'
const VERBOSE = 'verbose'

function dummyGetResourceSize() {
    return -1
}

const queryUtil = typeof window !== 'undefined' && window.queryUtil
// eslint-disable-next-line import/no-mutable-exports
let api = null

if (!queryUtil || queryUtil.isParameterTrue('suppressperformance')) {
    api = {
        VERBOSE,

        mark: _.noop,
        measure: _.noop,

        start: _.noop,
        startOnce: _.noop,
        finish: _.noop,

        time(name, code) {
            return code.apply(this, Array.prototype.slice.call(arguments, 2))
        },

        getMark: _.noop,
        getMeasure: _.noop,

        clearMarks: _.noop,
        clearMeasures: _.noop,

        now: _.now,

        getResourceSize: dummyGetResourceSize
    }
} else {
    const categoryEnabled = (function () {
        const categoryParam = queryUtil.getParameterByName(PERFORMANCE_PARAM)
        const fixedParam = categoryParam ? categoryParam.toLowerCase() : ''
        const categories = fixedParam.split(',')
        return _.includes(categories, 'all') ? () => true : category => !category || _.includes(categories, category)
    })()

    let now
    const {performance} = window
    if (performance && performance.now) {
        now = performance.now.bind(performance)
    } else {
        const initialTimestamp = (window.wixBiSession && window.wixBiSession.initialTimestamp) || 0
        now = function () {
            return _.now() - initialTimestamp
        }
    }

    const sendReport =
        queryUtil.getParameterByName('debug') && queryUtil.getParameterByName('sampleratio') !== 'force'
            ? _.noop
            : function (measure, additionalData) {
                  if (measure) {
                      additionalData = _.defaults(
                          {
                              measureName: measure.name,
                              startTime: Math.round(measure.startTime),
                              duration: Math.round(measure.duration)
                          },
                          additionalData
                      )
                      newrelic.addPageAction('measure', additionalData)
                  }
              }

    if (performance && performance.measure) {
        const getEntry = function (entryType, name) {
            return _.find(performance.getEntriesByName(name), {entryType})
        }

        api = {
            mark(name, {category}: any = {}) {
                if (categoryEnabled(category)) {
                    performance.mark(name)
                }
            },
            measure(name, startMark, endMark, {category, additionalData, report}: any = {}) {
                if (categoryEnabled(category)) {
                    try {
                        performance.measure(name, startMark, endMark)
                        if (report) {
                            sendReport(api.getMeasure(name), additionalData)
                        }
                    } catch (e) {
                        // Do nothing
                    }
                }
            },
            getMark: getEntry.bind(this, 'mark'),
            getMeasure: getEntry.bind(this, 'measure'),
            clearMarks: performance.clearMarks.bind(performance),
            clearMeasures: performance.clearMeasures.bind(performance)
        }
    } else {
        const marks = {
            domLoading: {
                name: 'domLoading',
                startTime: 0
            }
        }
        const measures = {}
        const clear = function (map, name) {
            if (name) {
                delete map[name]
            } else {
                _(map).keys().forEach(clear.bind(null, map))
                map = {}
            }
        }

        api = {
            mark(name, {category}: any = {}) {
                if (categoryEnabled(category)) {
                    marks[name] = {
                        name,
                        startTime: now()
                    }
                }
            },
            measure(name, startMark, endMark, {category, additionalData, report}: any = {}) {
                if (categoryEnabled(category)) {
                    const s = api.getMark(startMark)
                    const e = api.getMark(endMark)
                    if (s && e) {
                        const m = {
                            name,
                            startTime: s.startTime,
                            duration: e.startTime - s.startTime
                        }
                        if (!isNaN(m.duration)) {
                            measures[name] = m
                            if (report) {
                                sendReport(m, additionalData)
                            }
                        }
                    }
                }
            },
            getMark(name) {
                return marks[name]
            },
            getMeasure(name) {
                return measures[name]
            },
            clearMarks: clear.bind(this, marks),
            clearMeasures: clear.bind(this, measures)
        }
    }

    api.timeOrigin = performance && performance.timeOrigin ? performance.timeOrigin : _.now() - now()

    api.start = function (name, options) {
        api.clearMeasures(name)
        api.mark(name + START_MARKER, options)
    }
    api.startOnce = function (name, options) {
        if (!api.getMark(name + START_MARKER)) {
            api.start(name, options)
        }
    }
    api.finish = function (name, options: any = {}) {
        const nameStart = name + START_MARKER
        if (api.getMark(nameStart)) {
            const nameFinish = name + FINISH_MARKER
            api.mark(nameFinish, options)
            api.measure(name, nameStart, nameFinish, options)
            if (!options.retainMarks) {
                api.clearMarks(nameFinish)
                api.clearMarks(nameStart)
            }
        }
        return api.getMeasure(name)
    }
    api.time = function (name, code, options) {
        try {
            api.start(name, options)
            return code.apply(this, Array.prototype.slice.call(arguments, 4))
        } finally {
            api.finish(name, options)
        }
    }
    api.now = now

    if (performance && window.performance.getEntriesByName) {
        api.getResourceSize = function (url) {
            const resources = performance.getEntriesByName(url)
            if (resources && resources.length) {
                const resource = resources[0]
                // @ts-ignore
                const size = resource.transferSize || resource.encodedBodySize
                if (_.isNumber(size)) {
                    return size
                }
            }
            return -1
        }
    } else {
        api.getResourceSize = dummyGetResourceSize
    }

    api.VERBOSE = VERBOSE
}

export default api
