import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const ERROR_TEMPLATE_MISSING_ITEM_VIEW = 'The template is missing an item view'

function replaceNestedFieldRefs(fieldMap, viewDefPart) {
    if (!_.isObject(viewDefPart)) {
        return viewDefPart
    }

    _.forEach(viewDefPart, function (innerViewDefPart) {
        replaceNestedFieldRefs(fieldMap, innerViewDefPart)
    })

    // @ts-ignore
    if (_.has(viewDefPart, 'data') && fieldMap[viewDefPart.data]) {
        // @ts-ignore
        viewDefPart.data = fieldMap[viewDefPart.data]
    }
}

function isSameFieldType(firstField, secondField) {
    const firstShowAsHint = firstField.metadata && firstField.metadata.showAsHint
    const secondShowAsHint = secondField.metadata && secondField.metadata.showAsHint
    return firstField.type === secondField.type && firstShowAsHint === secondShowAsHint
}

function matchFieldsByType(sourceFields, targetFields) {
    const ignoreFields = ['title', 'links']
    const fieldMap = _.zipObject(ignoreFields, ignoreFields)

    sourceFields = _.reject(sourceFields, function (field) {
        return _.includes(ignoreFields, field.name)
    })
    targetFields = _.reject(targetFields, function (field) {
        return _.includes(ignoreFields, field.name)
    })

    _.forEach(sourceFields, function (sourceField) {
        const matchingTargetFieldIndex = _.findIndex(targetFields, isSameFieldType.bind(null, sourceField))
        if (matchingTargetFieldIndex !== -1) {
            fieldMap[sourceField.name] = targetFields[matchingTargetFieldIndex].name
            targetFields.splice(matchingTargetFieldIndex, 1)
        } else {
            fieldMap[sourceField.name] = null
        }
    })

    return fieldMap
}

function getMatchingViewsForType(views, templateTypeDef, existingTypeDef) {
    if (views.length === 0) {
        throw new Error(ERROR_TEMPLATE_MISSING_ITEM_VIEW)
    }

    const fieldMap = matchFieldsByType(templateTypeDef.fields, existingTypeDef.fields)

    return _.map(views, function (view) {
        const clonedView = _.cloneDeep(view)
        replaceNestedFieldRefs(fieldMap, clonedView)
        return clonedView
    })
}

function getViewTemplate(template, possibleView) {
    const viewsForType = _.filter(template.views, function (view) {
        return possibleView.forType === 'Array' ? view.forType === 'Array' : view.forType !== 'Array'
    })

    return _.find(viewsForType, {format: possibleView.format}) || _.head(viewsForType)
}

function generateView(template, viewType, partType) {
    const viewTemplate = getViewTemplate(template, viewType)

    let newView
    if (viewType.forType === 'Array') {
        newView = _.cloneDeep(viewTemplate)
        // @ts-ignore
        const itemViewRefs = coreUtils.objectUtils.filter(newView, o => o.id === 'listItem')
        _.forEach(itemViewRefs, function (v) {
            v.comp.name = viewType.name
        })
    } else {
        newView = getMatchingViewsForType([viewTemplate], template.type, partType)[0]
    }

    return _.assign(newView, viewType)
}

export default {
    getMatchingViewsForType,
    generateView
}
