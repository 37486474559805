define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/mobile-conversion',
    'documentServices/component/componentModes',
    'documentServices/structure/structure',
    'documentServices/dataModel/dataModel',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsConstants',
    'documentServices/constants/constants'
], function (_, coreUtils, mobileCore, componentModes, structureApi, dataModel, mobileHintsConstants, constants) {
    const {HINTS_PROPERTIES, OFFSET_DELTA_TO_RESET_COORDINATES, SIZE_DELTA_TO_RESET} = mobileHintsConstants

    const hasSizeData = mobileHints => mobileHints && _.some(HINTS_PROPERTIES.SIZE_DATA, prop => _.has(mobileHints, prop))
    const hasOffsetData = mobileHints => mobileHints && _.some(HINTS_PROPERTIES.OFFSET_DATA, prop => _.has(mobileHints, prop))
    const hasGeneralData = mobileHints => mobileHints && _.some(HINTS_PROPERTIES.GENERAL_PRESET_DATA, prop => _.has(mobileHints, prop))
    const isMobileHintsPreset = mobileHints => hasSizeData(mobileHints) || hasOffsetData(mobileHints)
    const getOrderIndex = (ps, pageId, component) => _.get(dataModel.getMobileHintsItemById(ps, component.mobileHintsQuery, pageId), 'orderIndex')
    const isOffsetChanged = (newValue, oldValue) => Math.abs(newValue - oldValue) > OFFSET_DELTA_TO_RESET_COORDINATES
    const isSizeChanged = (newValue, oldValue) => Math.abs(newValue - oldValue) > SIZE_DELTA_TO_RESET
    const shouldRemoveOffset = (newLayout, previousLayout) => isOffsetChanged(newLayout.x, previousLayout.x) || isOffsetChanged(newLayout.y, previousLayout.y)
    const shouldRemovePresetSize = (newLayout, previousLayout) =>
        isSizeChanged(newLayout.width, previousLayout.width) || isSizeChanged(newLayout.height, previousLayout.height)
    const getComponentStructure = (ps, fullComponentStructure, pageId) => {
        const pageActiveModesMap = _.set({}, pageId, componentModes.getMobileActiveModesMap(ps, pageId))
        return coreUtils.fullToDisplayedJson.getDisplayedJson(fullComponentStructure, pageActiveModesMap, pageId).structure
    }
    const getComponentsMap = (structure, map = {}) => {
        map[structure.id] = structure
        _.forEach(structure.components, childStructure => getComponentsMap(childStructure, map))
        return map
    }
    const getCommonMobileHints = preset => {
        const originalCompId = _.get(preset, ['metaData', 'originalCompId'])
        const author = 'studio'
        const {
            layout: {width: recommendedWidth, height: recommendedHeight, scale: recommendedScale}
        } = preset
        const mobileHints = {
            recommendedWidth,
            recommendedHeight,
            recommendedScale,
            author
        }
        if (originalCompId) {
            mobileHints.originalCompId = originalCompId
        }
        return mobileHints
    }

    const filterChildren = (ps, children, mobilePresetsMap) =>
        children.reduce((arr, pointer) => {
            if (structureApi.isFixedPosition(ps, pointer)) {
                return arr
            }
            const preset = mobilePresetsMap.get(pointer.id)
            if (!preset) {
                return arr
            }
            return [...arr, preset]
        }, [])

    const convertMobilePresetToMobileHints = (ps, rootPointer, mobilePresetsMap, mobileHintsMap) => {
        const children = ps.pointers.components.getChildren(rootPointer)

        if (children) {
            const childrenPresets = filterChildren(ps, children, mobilePresetsMap)
            const sortedPresetsWithOffsetOrigins = mobileCore.mobileHints.sortPresets(childrenPresets)

            sortedPresetsWithOffsetOrigins.forEach(({preset, offsetOrigin}, orderIndex) => {
                const previousPreset = _.get(sortedPresetsWithOffsetOrigins, [orderIndex - 1, 'preset'], null)
                const offsetPresets = mobileCore.mobileHints.calculateOffsetsMobileHints(previousPreset, preset, offsetOrigin)

                const mobileHints = {
                    ...getCommonMobileHints(preset),
                    ...offsetPresets,
                    orderIndex
                }
                mobileHintsMap.set(preset.id, mobileHints)
            })
            children.forEach(child => convertMobilePresetToMobileHints(ps, child, mobilePresetsMap, mobileHintsMap))
        }
    }

    const convertMobilePresetsToMobileHints = (ps, rootPointer, mobilePresetsMap) => {
        const mobileHintsMap = new Map()
        convertMobilePresetToMobileHints(ps, rootPointer, mobilePresetsMap, mobileHintsMap)
        return mobileHintsMap
    }

    const shouldMarkAsDirty = (newLayout, previousLayout) =>
        isOffsetChanged(newLayout.x, previousLayout.x) ||
        isOffsetChanged(newLayout.y, previousLayout.y) ||
        isSizeChanged(newLayout.width, previousLayout.width) ||
        isSizeChanged(newLayout.height, previousLayout.height)

    const isDeadComponentById = id => {
        const regEx = new RegExp(`^${constants.DOM_ID_PREFIX.DEAD_MOBILE_COMP}`)
        return regEx.test(id)
    }
    return {
        hasGeneralData,
        hasSizeData,
        hasOffsetData,
        isMobileHintsPreset,
        getOrderIndex,
        getComponentStructure,
        getComponentsMap,
        shouldRemoveOffset,
        shouldRemovePresetSize,
        convertMobilePresetsToMobileHints,
        shouldMarkAsDirty,
        isDeadComponentById
    }
})
