define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/santa-core-utils',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/platform/common/constants',
    'experiment',
    'documentServices/appStudio/appType',
    'documentServices/appStudio/appStudioDependencies',
    'documentServices/wixCode/utils/codeAppInfo'
], function (_, coreUtils, santaCoreUtils, clientSpecMap, platformConstants, experiment, appType, appStudioDependencies, codeAppInfoUtils) {
    'use strict'
    const baseUrl = '_api/wix-blocks-service/api/v1'
    const {ajaxLibrary} = coreUtils

    /**
     * @param {import("@wix/document-services-types").PS} ps
     * @param {Object} options
     * @param {string} options.metaSiteId
     * @param {string} options.appName
     * @returns {Promise<{appDefId: string}>}
     */
    async function unDraftSite(ps, {metaSiteId, appName}) {
        const {instance} = clientSpecMap.getAppData(ps, platformConstants.APPS.META_SITE.applicationId)

        try {
            const response = await santaCoreUtils.requestsUtil.fetchJson(`/${baseUrl}/sites/${metaSiteId}/undraft`, {
                credentials: 'include',
                method: 'POST',
                headers: new Headers({
                    Authorization: instance,
                    'X-XSRF-TOKEN': santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN'),
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json'
                }),
                body: JSON.stringify({
                    appName,
                    appType: experiment.isOpen('dm_blocksUndraftWithAppType') ? appType.getAppType(ps) : undefined
                })
            })
            return {appDefId: response.appDefId}
        } catch (/** @type any */ e) {
            if (!e) {
                throw new Error('Cannot unDraft site with unknown reason')
            }
            if (e.headers && e.headers.get('Content-Type').startsWith('application/json')) {
                const body = await e.json()
                const message = _.get(body, 'error.message', body.message || '')
                throw new Error(`Cannot unDraft site: status ${e.status}, ${message}`)
            }
            const message = 'status' in e ? `status ${e.status}` : e.message || e
            throw new Error(`Cannot unDraft site: ${message}`)
        }
    }

    function getRequestHeaders(ps, instance) {
        return {
            'X-Wix-Editor-Version': 'new',
            'X-Wix-DS-Origin': ps.config.origin,
            'X-Editor-Session-Id': ps.siteAPI.getEditorSessionId(),
            Authorization: instance,
            'X-XSRF-TOKEN': santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN')
        }
    }

    function getBlocksServerBaseUrl(ps) {
        const blocksServerBaseUrl = ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'editorRootUrl'))
        return `${blocksServerBaseUrl}${baseUrl}`
    }

    function getBuildUrl(ps, versionType, appDefId, isAsyncBuild) {
        const blocksServerBaseUrl = getBlocksServerBaseUrl(ps)
        const buildUrlPath = `${blocksServerBaseUrl}/apps/${appDefId}`

        if (isAsyncBuild) {
            return `${buildUrlPath}/async-build`
        }

        return `${buildUrlPath}/build`
    }

    function updateLastBuildId(ps, response) {
        const pointer = ps.pointers.appBuilder.getLastBuildIdPointer()
        ps.dal.set(pointer, response.buildRecord?.id)
    }

    function isSuccessResponse(ps, response, isAsyncBuild) {
        if (isAsyncBuild) {
            return Boolean(response.buildRecord?.status)
        }

        return Boolean(response.version && response.revision)
    }

    function getAsyncBuildPayload({appMarketingName, blocksVersion, widgetsMap, codePackage, versionType, dependencies, releaseNotes, publishRc}) {
        return {
            appMarketingName,
            blocksVersion,
            components: {
                widgetsMap,
                codePackage
            },
            versionType: versionType.toUpperCase(),
            dependencies,
            releaseNotes,
            publishRc
        }
    }

    function getBuildPayload({appMarketingName, blocksVersion, widgetsMap, versionType, withVersionType, publishRc}) {
        return {
            appMarketingName,
            blocksVersion,
            widgetsMap,
            skipDataFor: [],
            versionType: withVersionType ? versionType.toUpperCase() : undefined,
            publishRc
        }
    }

    function getSignedInstance(ps) {
        const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
        return codeAppInfo.signedInstance
    }

    function getErrorData({response, getBuildError}) {
        const plainBuildError = getBuildError({
            errorCode: response.status,
            errorDescription: response.responseText
        })

        try {
            const data = JSON.parse(response.response)
            return data.errorCode
                ? getBuildError({
                      errorCode: data.errorCode,
                      errorDescription: data.errorDescription,
                      applicationError: data.details?.applicationError
                  })
                : plainBuildError
        } catch {
            return plainBuildError
        }
    }

    function performGetBuildById(ps, buildId, appDefinitionId, getBuildError) {
        return new Promise((resolve, reject) => {
            const blocksServerBaseUrl = getBlocksServerBaseUrl(ps)
            const url = `${blocksServerBaseUrl}/apps/${appDefinitionId}/builds/${buildId}`
            const instance = getSignedInstance(ps)

            ajaxLibrary.ajax({
                type: 'GET',
                url,
                headers: getRequestHeaders(ps, instance),
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                success(response) {
                    return response.buildRecord?.status
                        ? resolve(response.buildRecord)
                        : reject(
                              getErrorData({
                                  response,
                                  getBuildError
                              })
                          )
                },
                error: response =>
                    reject(
                        getErrorData({
                            response,
                            getBuildError
                        })
                    )
            })
        })
    }

    function postBuildRequest(
        ps,
        {widgetsMap, appMarketingName, blocksVersion, versionType, codePackage, appDefId, isAsyncBuild, releaseNotes, publishRc},
        onSuccess,
        onError,
        getBuildError
    ) {
        const instance = getSignedInstance(ps)
        const buildUrl = getBuildUrl(ps, versionType, appDefId, isAsyncBuild)

        const payloadData = isAsyncBuild
            ? getAsyncBuildPayload({
                  appMarketingName,
                  blocksVersion,
                  widgetsMap,
                  codePackage,
                  versionType,
                  dependencies: experiment.isOpen('dm_appStudioDependencies') ? appStudioDependencies.getAllDependencies(ps) : undefined,
                  releaseNotes,
                  publishRc
              })
            : getBuildPayload({
                  appMarketingName,
                  blocksVersion,
                  widgetsMap,
                  versionType,
                  withVersionType: true,
                  publishRc
              })

        ajaxLibrary.ajax({
            type: 'POST',
            url: buildUrl,
            headers: getRequestHeaders(ps, instance),
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            data: payloadData,
            success(response) {
                if (isAsyncBuild) {
                    updateLastBuildId(ps, response)
                }

                return isSuccessResponse(ps, response, isAsyncBuild)
                    ? onSuccess()
                    : onError(
                          getErrorData({
                              response,
                              getBuildError
                          })
                      )
            },
            error: response =>
                onError(
                    getErrorData({
                        response,
                        getBuildError
                    })
                )
        })
    }

    /**
     * @exports documentServices/appStudio/services/wixBlocksService
     */
    return {
        unDraftSite,
        performGetBuildById,
        postBuildRequest
    }
})
