import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import appPart2 from '@wix/santa-ds-libs/src/wixappsBuilder/src/comps/appPart2'
import fieldBox from '@wix/santa-ds-libs/src/wixappsBuilder/src/proxies/fieldBoxProxy'
import field from '@wix/santa-ds-libs/src/wixappsBuilder/src/proxies/fieldProxy'
import textField from '@wix/santa-ds-libs/src/wixappsBuilder/src/proxies/textFieldProxy'
import appRepo from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appRepo'
import builderDataHandler from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/builderDataHandler'
import appPart2DataFetchingStateManager from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appPart2DataFetchingStateManager'
import viewsTemplatesUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/viewsTemplatesUtils'
import viewsTemplatesData from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/viewsTemplatesData.json'
import fieldBoxProxyUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/fieldBoxProxyUtils'
import appbuilderUrlUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/appbuilderUrlUtils'
import appBuilderSantaTypes from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appBuilderSantaTypes'
import appBuilderSantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appBuilderSantaTypesDefinitions'
import '@wix/santa-ds-libs/src/wixappsBuilder/src/util/fontUtils'
import '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appPart2DataRequirementsChecker'
import '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appPart2PreviewDataRequirementsChecker'
import '@wix/santa-ds-libs/src/wixappsBuilder/src/core/appPart2StyleCollector'

componentsCore.compRegistrar.register('wixapps.integration.components.AppPart2', appPart2)
componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('listBuilderSantaTypes', appBuilderSantaTypes)

wixapps.proxyFactory.register('FieldBox', fieldBox)
wixapps.proxyFactory.register('Field', field)
wixapps.proxyFactory.register('TextField', textField)

/**
 * @class wixappsBuilder
 */
export default {
    appBuilderSantaTypes,
    appBuilderSantaTypesDefinitions,
    appRepo,
    builderDataHandler,
    dataFetchingStateManager: appPart2DataFetchingStateManager,
    viewsTemplatesUtils,
    fieldBoxProxyUtils,
    viewsTemplatesData,
    resolveImageData: appbuilderUrlUtils.resolveImageData
}
