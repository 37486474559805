import wixCodeWidgetService from './utils/wixCodeWidgetService'
//don't remove aspects so that they will register
import './aspects/wixCodeAspectCollector'
import messageBuilder from './utils/messageBuilder'
import kibanaReporter from './utils/kibanaReporter'
import wixCodeUserScriptsService from './services/wixCodeUserScriptsService'
import WixCodePostMessageService from './aspects/wixCodePostMessageService'
import {skinsMap} from '../../_internal/skins'
// @ts-ignore
import skinsJson from './skins/skins.json'

skinsMap.addBatch(skinsJson)

export default {
    wixCodeWidgetService,
    log: kibanaReporter,
    messageBuilder,
    wixCodeUserScriptsService,
    WixCodePostMessageService
}
