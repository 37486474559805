define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'tpa',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/tpa/services/clientSpecMapService'
], function (_, coreUtils, tpa, siteMetadata, clientSpecMapService) {
    'use strict'

    const premiumAppsUrl = _.template('<%= premiumStateAPIUrl %>?siteGuid=<%= metasiteId %>')
    const YEARLY = 6

    const getPremiumStateTopology = function (ps) {
        const serviceTopology = ps.pointers.general.getServiceTopology()
        const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'premiumStateApiUrl')
        return ps.dal.get(premiumStatePointer) // 'https://editor.wix.com/_api/wix/getTpaPremiumState'
    }

    const getPremiumApps = function (ps, metasiteId, onSuccess, onError) {
        const base = getPremiumStateTopology(ps)
        const url = premiumAppsUrl({
            premiumStateAPIUrl: base,
            metasiteId
        })
        coreUtils.ajaxLibrary.ajax({
            type: 'POST',
            url,
            data: {},
            dataType: 'json',
            success(data) {
                if (_.isFunction(onSuccess)) {
                    onSuccess(data.payload)
                }
            },
            error: onError
        })
    }

    const isYearly = function (packageData) {
        return packageData.cycle === YEARLY
    }

    const extractYearlyPremiumApps = function (json) {
        const yearlyAppDefIds = []
        const packageData = _.get(json, 'tpaPackages') || []
        if (_.isArray(packageData)) {
            _.forEach(packageData, function (packag) {
                if (isYearly(packag)) {
                    yearlyAppDefIds.push(packag.appDefinitionId)
                }
            })
        } else if (_.isObject(packageData)) {
            if (isYearly(packageData)) {
                yearlyAppDefIds.push(packageData.appDefinitionId)
            }
        }
        return yearlyAppDefIds
    }

    const getSiteUpgradeUrl = function (ps, queryParameters) {
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        const baseUpgradeUrl = `${serviceTopology.premiumServerUrl}/wix/api/premiumStart`
        const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
        queryParameters = queryParameters || {}
        queryParameters.siteGuid = metaSiteId

        return new tpa.common.TPABaseUrlBuilder(baseUpgradeUrl).addMultipleQueryParams(queryParameters).build()
    }

    const getAppUpgradeUrl = function (ps, applicationId, vendorProductId, paymentCycle, options) {
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        const baseUpgradeUrl = `${serviceTopology.premiumServerUrl}/wix/api/tpaStartPurchase`
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
        options = options || {}
        const params = {
            applicationId,
            vendorProductId,
            paymentCycle: paymentCycle || 'MONTHLY',
            appDefinitionId: appData.appDefinitionId,
            metaSiteId,
            pp_type: options.pp_type,
            referralAdditionalInfo: options.referralAdditionalInfo
        }
        if (options.premiumIntent) {
            params.premiumIntent = options.premiumIntent
        }

        return new tpa.common.TPABaseUrlBuilder(baseUpgradeUrl).addMultipleQueryParams(params).build()
    }

    return {
        getPremiumApps,
        extractYearlyPremiumApps,
        isYearly,
        getSiteUpgradeUrl,
        getAppUpgradeUrl
    }
})
