/**
 * Created by eitanr on 6/24/14.
 */
import utils from './svgScalerUtils'

const scaleSingleValue = function (value, scale) {
    return utils.round(parseFloat(value) * scale)
}
const scalePairString = function (pairString, scaleX, scaleY) {
    const commandValues = pairString.split(/[\s,]+/)
    return `${scaleSingleValue(commandValues[0], scaleX)} ${scaleSingleValue(commandValues[1], scaleY)}`
}
const scaleMultiplePairStrings = function (multiplePairStrings, scaleX, scaleY) {
    if (multiplePairStrings === '') {
        return ''
    }
    const commandValues = multiplePairStrings.split(',').map(function (val) {
        return scalePairString(utils.trim(val), scaleX, scaleY)
    })
    return commandValues.join(',')
}
const scaleMultipleSingleStrings = function (multiplePairStrings, scale) {
    if (multiplePairStrings === '') {
        return ''
    }
    const commandValues = multiplePairStrings.split(/[\s,]+/).map(function (val) {
        return scaleSingleValue(utils.trim(val), scale)
    })
    return commandValues.join(',')
}

export default {
    scaleSingleValue,
    scalePairString,
    scaleMultiplePairStrings,
    scaleMultipleSingleStrings
}
