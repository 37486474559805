define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'experiment',
    'documentServices/utils/utils',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/modes/modesUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/component/componentStructureInfo',
    'documentServices/hooks/hooks',
    '@wix/santa-core-utils',
    'documentServices/variants/design'
], function (
    _,
    coreUtils,
    experiment,
    dsUtils,
    documentModeInfo,
    modesUtils,
    dataModel,
    actionsAndBehaviors,
    componentStructureInfo,
    hooks,
    santaCoreUtils,
    design
) {
    'use strict'

    const NOT_DISPLAYED_PROPERTY = 'isHiddenByModes'
    const SITE_REGION_CONTAINER_TYPE = 'wysiwyg.viewer.components.SiteRegionContainer'
    const {COMP_MODES_TYPES} = santaCoreUtils.siteConstants
    const {constants} = coreUtils

    const modeTypeMetaData = {
        SHOW_ON_SOME_PAGES: {
            mobileConversionConfig: 'copyOverrides'
        },
        POPOVER: {
            mobileConversionConfig: 'copyOverrides'
        },
        HOVER: {
            mobileConversionConfig: 'chooseMobileMode'
        }
    }

    function getModeTypes() {
        return _.cloneDeep(COMP_MODES_TYPES)
    }

    function getComponentModesDefinitions(ps, compPointer) {
        if (_.isEmpty(compPointer)) {
            return null
        }
        const modesDefinitionsPointer = ps.pointers.componentStructure.getModesDefinitions(compPointer)
        return ps.dal.full.get(modesDefinitionsPointer)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {*}
     */
    function getComponentModesAvailableInView(ps, compPointer) {
        return ps.pointers.components.isMobile(compPointer) ? [getCompMobileMode(ps, compPointer)] : getComponentModesDefinitions(ps, compPointer)
    }

    function resolveHoverMobileMode(ps, modeDefinitions, compPointer) {
        const hoverMode = _.find(modeDefinitions, {type: COMP_MODES_TYPES.HOVER})
        if (hoverMode) {
            const desktopCompPointer = ps.pointers.full.components.getDesktopPointer(compPointer)
            const propertyPointer = dataModel.getPropertyItemPointer(ps, desktopCompPointer)
            let modeFromProps

            if (propertyPointer) {
                const {mobileDisplayedModeId} = ps.dal.get(propertyPointer)
                modeFromProps = mobileDisplayedModeId && _.find(modeDefinitions, {modeId: mobileDisplayedModeId})
            }

            return modeFromProps || hoverMode
        }
    }

    function resolvePopoverMobileModes(ps, modeDefinitions) {
        return _(modeDefinitions)
            .filter(definition => _.get(modeTypeMetaData, [definition.type, 'mobileConversionConfig']) === 'copyOverrides')
            .find({type: COMP_MODES_TYPES.POPOVER})
    }

    function getCompMobileMode(ps, compPointer) {
        const modeDefinitions = getComponentModesDefinitions(ps, compPointer)

        return resolveHoverMobileMode(ps, modeDefinitions, compPointer) || resolvePopoverMobileModes(ps, modeDefinitions)
    }

    function getComponentModesByType(ps, compPointer, type) {
        if (!type) {
            return null
        }
        const allModes = getComponentModesDefinitions(ps, compPointer)
        return _.filter(allModes, {type})
    }

    function getComponentModeById(ps, compPointer, modeId) {
        const allModes = getComponentModesDefinitions(ps, compPointer)
        if (allModes && modeId) {
            return _.find(allModes, {modeId})
        }
        return null
    }

    function getModeToAddId(ps, compPointer) {
        return createUniqueModeId(compPointer.id)
    }

    /**
     * Adds a new Mode to a component.
     *
     * @param {ps} ps
     * @param {string} modeId
     * @param {Pointer} compPointer A component pointer to match a corresponding Component.
     * @param {string} type A type of a certain mode.
     * @param {*} settings
     * @param {string} label
     * @returns undefined
     *
     *      @exaample
     *      const myBoxRef = ...;
     *      documentServices.components.modes.add(myBoxRef, 'HOVER');
     */
    function addModeDefinition(ps, modeId, compPointer, type, settings, label) {
        if (!isValidModeToAdd(type)) {
            throw new Error('added component mode is invalid')
        }
        initializeModesObjectForComponentIfMissing(ps, compPointer)
        const modeToAdd = createModeDefToAdd(compPointer.id, type, settings, modeId, label)
        const updatedModes = addModeAndValidate(ps, compPointer, modeToAdd)
        const compModesDefinitionsPointer = ps.pointers.componentStructure.getModesDefinitions(compPointer)
        ps.dal.full.set(compModesDefinitionsPointer, updatedModes)

        if (modeToAdd.type === COMP_MODES_TYPES.SHOW_ON_SOME_PAGES) {
            ps.siteAPI.updateActiveSOSPModes()
        }
    }

    function initializeModesObjectForComponentIfMissing(ps, compPointer) {
        const modesPointer = ps.pointers.componentStructure.getModes(compPointer)
        const definitionsPointer = ps.pointers.componentStructure.getModesDefinitions(compPointer)
        const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        if (!ps.dal.full.isExist(modesPointer)) {
            ps.dal.full.set(modesPointer, {
                definitions: [],
                overrides: []
            })
        } else {
            if (!ps.dal.full.isExist(overridesPointer)) {
                ps.dal.full.set(overridesPointer, [])
            }
            if (!ps.dal.full.isExist(definitionsPointer)) {
                ps.dal.full.set(definitionsPointer, [])
            }
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {*} modeToAdd
     */
    function addModeAndValidate(ps, compPointer, modeToAdd) {
        const compModesDefinitionsPointer = ps.pointers.componentStructure.getModesDefinitions(compPointer)
        const currentModes = ps.dal.full.get(compModesDefinitionsPointer)
        switch (modeToAdd.type) {
            case COMP_MODES_TYPES.DEFAULT:
            case COMP_MODES_TYPES.HOVER:
                return addAndValidateHoverModes(currentModes, modeToAdd, compPointer.id)
            case COMP_MODES_TYPES.SCROLL:
                return addAndValidateScrollMode(currentModes, modeToAdd, compPointer.id)
            case COMP_MODES_TYPES.WIDTH:
                return addAndValidateWidthMode(currentModes, modeToAdd, compPointer.id)
            case COMP_MODES_TYPES.APPLICATIVE:
                return addAndValidateApplicativeMode(currentModes, modeToAdd)
            case COMP_MODES_TYPES.SHOW_ON_SOME_PAGES:
                return addAndValidateSOSPMode(ps, compPointer, currentModes, modeToAdd)
        }
    }

    function addAndValidateHoverModes(currentModeDefs, modeToAdd, compId) {
        const hasDefaultMode = _.find(currentModeDefs, {type: COMP_MODES_TYPES.DEFAULT})
        const hasHoverMode = _.find(currentModeDefs, {type: COMP_MODES_TYPES.HOVER})
        const addingDefaultMode = modeToAdd.type === COMP_MODES_TYPES.DEFAULT
        const addingHoverMode = modeToAdd.type === COMP_MODES_TYPES.HOVER
        if ((addingDefaultMode && hasDefaultMode) || (addingHoverMode && hasHoverMode) || (hasDefaultMode && hasHoverMode)) {
            return currentModeDefs
        }

        if (addingDefaultMode) {
            return currentModeDefs.concat(modeToAdd, createModeDefToAdd(compId, COMP_MODES_TYPES.HOVER))
        } else if (addingHoverMode) {
            return currentModeDefs.concat(modeToAdd, createModeDefToAdd(compId, COMP_MODES_TYPES.DEFAULT))
        }
        return currentModeDefs
    }

    function addAndValidateScrollMode(currentModeDefs, modeToAdd, compId) {
        if (_.isUndefined(modeToAdd.settings.scrollPos)) {
            throw new Error('scroll mode must have a scroll pos')
        }
        let scrollModesToAdd = []
        const scrollModes = _.filter(currentModeDefs, {type: COMP_MODES_TYPES.SCROLL})
        const initialScrollMode = _.find(scrollModes, 'settings.scrollPos', 0)
        if (!initialScrollMode && modeToAdd.settings.scrollPos !== 0) {
            scrollModesToAdd = [createModeDefToAdd(compId, COMP_MODES_TYPES.SCROLL, {scrollPos: 0})]
        }
        const modeWithPosExists = _.find(scrollModes, ['settings.scrollPos', modeToAdd.settings.scrollPos])
        if (!modeWithPosExists) {
            return scrollModesToAdd.concat(modeToAdd, currentModeDefs)
        }

        return scrollModesToAdd.concat(currentModeDefs)
    }

    function addAndValidateWidthMode(currentModeDefs, modeToAdd, compId) {
        if (_.isUndefined(modeToAdd.settings.width)) {
            throw new Error('width mode must have a width pos')
        }
        let widthModesToAdd = []
        const widthModes = _.filter(currentModeDefs, {type: COMP_MODES_TYPES.WIDTH})
        const initialWidthMode = _.find(widthModes, 'settings.width', 0)
        if (!initialWidthMode && modeToAdd.settings.width !== 0) {
            widthModesToAdd = [createModeDefToAdd(compId, COMP_MODES_TYPES.WIDTH, {width: 0})]
        }
        const modeWithPosExists = _.find(widthModes, ['settings.width', modeToAdd.settings.width])
        if (!modeWithPosExists) {
            return widthModesToAdd.concat(modeToAdd, currentModeDefs)
        }

        return widthModesToAdd.concat(currentModeDefs)
    }

    function addAndValidateApplicativeMode(currentModeDefs, modeToAdd) {
        return [modeToAdd].concat(currentModeDefs)
    }

    function addAndValidateSOSPMode(ps, compPointer, currentModeDefs, modeToAdd) {
        if (!ps.pointers.components.isMasterPage(compPointer)) {
            throw new Error('SOSP mode can be added only to masterPage')
        }

        //todo - add test
        if (_.isUndefined(modeToAdd.settings.pagesGroupId)) {
            throw new Error('SOSP mode must have a pagesGroupId')
        }

        return [modeToAdd].concat(currentModeDefs)
    }

    function createModeDefToAdd(compId, modeType, settings, modeId, label) {
        return {
            modeId: modeId || createUniqueModeId(compId.id),
            type: modeType,
            label: label || null,
            settings: settings || null
        }
    }

    function isValidModeToAdd(modeType) {
        return _.includes(_.values(COMP_MODES_TYPES), modeType)
    }

    function createUniqueModeId(compId) {
        const prefix = `${compId ? `${compId}-` : ''}mode`
        const delim = '-'
        return santaCoreUtils.guidUtils.getUniqueId(prefix, delim, {bucket: 'componentModeId'})
    }

    function removeComponentMode(ps, compPointer, modeId) {
        const componentActiveModes = getComponentActiveModeIds(ps, compPointer)
        if (componentActiveModes[modeId]) {
            deactivateCompMode(ps, compPointer, modeId)
        }

        const componentType = componentStructureInfo.getType(ps, compPointer)

        hooks.executeHook(hooks.HOOKS.REMOVE_COMP_MODE.BEFORE, componentType, [ps, compPointer, modeId])

        const modesDefinitionsPointer = ps.pointers.componentStructure.getModesDefinitions(compPointer)
        const modesDefinitions = ps.dal.full.get(modesDefinitionsPointer)
        const updatedModesDefinitions = _.reject(modesDefinitions, {modeId})
        ps.dal.full.set(modesDefinitionsPointer, updatedModesDefinitions)
    }

    /**
     * @param {ps} ps
     * @param compPointer
     * @returns {null|*}
     */
    function getComponentActiveModeIds(ps, compPointer) {
        if (!compPointer) {
            return null
        }
        const activeModes = ps.dal.get(ps.pointers.activeModes.getAllActiveModes())
        const compPagePointer = ps.pointers.components.getPageOfComponent(compPointer) || ps.pointers.full.components.getPageOfComponent(compPointer)
        const compModeDefinitions = getComponentModesDefinitions(ps, compPointer)

        return coreUtils.modesUtils.getActiveComponentModeIds(activeModes[compPagePointer.id], compModeDefinitions)
    }

    function resetAllActiveModes(ps) {
        ps.siteAPI.resetAllActiveModes()
    }

    function activateCompMode(ps, compPointer, modeIdToActivate) {
        ps.siteAPI.activateMode(compPointer, modeIdToActivate)
    }

    function deactivateCompMode(ps, compPointer, modeIdToDeactivate) {
        ps.siteAPI.deactivateMode(compPointer, modeIdToDeactivate)
    }

    function getComponentOverrides(ps, compPointer) {
        const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        return ps.dal.full.isExist(overridesPointer) && ps.dal.full.get(overridesPointer)
    }

    function removeComponentOverrides(ps, compPointer) {
        const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        if (ps.dal.full.isExist(overridesPointer)) {
            ps.dal.full.set(overridesPointer, [])
        }
    }

    function updateComponentOverrides(ps, compPointer, overrides) {
        const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        if (ps.dal.full.isExist(overridesPointer)) {
            ps.dal.full.set(overridesPointer, overrides)
        }
    }

    const duplicateDesignItem = (ps, dataItemId, pageId) => {
        const regularDesignPointer = ps.pointers.data.getDesignItem(dataItemId, pageId)
        const serializedDesign = dataModel.getDataByPointer(ps, constants.DATA_TYPES.design, regularDesignPointer, true)

        return dataModel.addSerializedDesignItemToPage(ps, pageId, serializedDesign)
    }

    const cleanRef = ref => dsUtils.stripHashIfExists(ref)

    /**
     * Copies the values of the active override to the other overrides
     * @param {ps} ps
     * @param compPointer
     */
    function applyCurrentToAllModesLeaf(ps, compPointer) {
        const modesPointer = ps.pointers.componentStructure.getModes(compPointer)
        const compModes = ps.dal.full.get(modesPointer)
        if (compModes) {
            const activeOverridesPointer = getOverridePointerByActiveModes(ps, compPointer)
            if (activeOverridesPointer) {
                const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
                const pageId = pagePointer.id
                const fullActiveOverride = ps.dal.full.get(activeOverridesPointer)
                const activeOverride = _.omit(fullActiveOverride, ['modeIds'])
                const modesRootOverride = _.pick(activeOverride, ['isHiddenByModes'])
                ps.dal.full.merge(modesPointer, modesRootOverride)
                const componentOverride = _.omit(activeOverride, ['isHiddenByModes'])
                ps.dal.full.merge(compPointer, componentOverride)
                const compOverridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
                const compOverrides = ps.dal.full.get(compOverridesPointer)
                _.forEach(compOverrides, (override, index) => {
                    if (_.isEqual(override.modeIds, fullActiveOverride.modeIds)) {
                        return
                    }
                    const overridePointer = ps.pointers.getInnerPointer(compOverridesPointer, index)
                    const update = _.cloneDeep(componentOverride)
                    update.modeIds = override.modeIds
                    if (update.designQuery) {
                        const regularDesignId = cleanRef(update.designQuery)
                        const duplicateDesignItemId = duplicateDesignItem(ps, regularDesignId, pageId)
                        update.designQuery = `#${duplicateDesignItemId}`
                    }

                    ps.dal.full.set(overridePointer, update)
                })
            }
        }

        removeSingleModeBehaviors(ps, compPointer)
    }

    function applyCurrentToAllModes(ps, compPointer) {
        applyCurrentToAllModesLeaf(ps, compPointer)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     */
    function removeSingleModeBehaviors(ps, compPointer) {
        actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, compPointer, {action: 'modeOut'})
        actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, compPointer, {action: 'modeIn'})
    }

    function getFirstAncestorWithActiveModes(ps, compPointer) {
        let ancestorPointer = ps.pointers.full.components.getParent(compPointer)
        let compActiveModes = getComponentActiveModeIds(ps, ancestorPointer)
        while (ancestorPointer && _.isEmpty(compActiveModes)) {
            ancestorPointer = ps.pointers.components.getParent(ancestorPointer)
            compActiveModes = ancestorPointer ? getComponentActiveModeIds(ps, ancestorPointer) : null
        }

        return ancestorPointer
    }

    /**
     * @param {ps} ps
     * @param compPointer a component pointer corresponding a component to show only in a certain modes combination
     * @param modeIdsCombination an array of mode Ids, whose combination (&&) determines when to show a component.
     */
    function showComponentOnlyInModesCombination(ps, compPointer, modeIdsCombination) {
        if (_.isEmpty(modeIdsCombination) || !compPointer) {
            return
        }
        const compModesPointer = ps.pointers.componentStructure.getModes(compPointer)
        const compModes = ps.dal.full.get(compModesPointer) || {}
        compModes.isHiddenByModes = true
        compModes.overrides = createMatchingOverridesToShowComp(compModes.overrides, modeIdsCombination)
        compModes.overrides = filterNonMatchingOverrides(compModes.overrides, modeIdsCombination)

        ps.dal.full.set(compModesPointer, compModes)
    }

    function canShowOnSomePages(ps, componentPointer) {
        const compType = dsUtils.getComponentType(ps, componentPointer)

        return compType === SITE_REGION_CONTAINER_TYPE && ps.pointers.components.isInMasterPage(componentPointer)
    }

    function getNewSospModeLabel(ps, pagesGroupPointer) {
        const pagesGroup = ps.dal.get(pagesGroupPointer)

        return `SOSP-${pagesGroup.groupName}`
    }

    function createSospMode(ps, pagesGroupPointer) {
        const viewMode = documentModeInfo.getViewMode(ps)
        const masterPagePointer = ps.pointers.components.getMasterPage(viewMode)
        const newModeId = getModeToAddId(ps, masterPagePointer)
        const newSospModeSettings = {
            pagesGroupId: `#${pagesGroupPointer.id}`
        }

        addModeDefinition(
            ps,
            newModeId,
            masterPagePointer,
            COMP_MODES_TYPES.SHOW_ON_SOME_PAGES,
            newSospModeSettings,
            getNewSospModeLabel(ps, pagesGroupPointer)
        )

        return newModeId
    }

    function getOrCreateSospMode(ps, pagesGroupPointer) {
        const sospMode = modesUtils.getSospModeByPagesGroup(ps, pagesGroupPointer)

        if (sospMode) {
            return sospMode.modeId
        }

        return createSospMode(ps, pagesGroupPointer)
    }

    function validateShowComponentOnlyOnPagesGroup(ps, componentPointer, pagesGroupPointer) {
        if (!ps.dal.isExist(pagesGroupPointer)) {
            throw new Error('componentModes: pagesGroup not exist')
        }

        if (!canShowOnSomePages(ps, componentPointer)) {
            throw new Error('componentModes: cant show component only on some pages')
        }
    }

    function showComponentOnlyOnPagesGroup(ps, componentPointer, pagesGroupPointer) {
        validateShowComponentOnlyOnPagesGroup(ps, componentPointer, pagesGroupPointer)

        const sospModeId = getOrCreateSospMode(ps, pagesGroupPointer)

        showComponentOnlyInModesCombination(ps, componentPointer, [sospModeId])
    }

    function filterNonMatchingOverrides(compModesOverrides, modeIdsToKeep) {
        return _.filter(compModesOverrides, function (override) {
            return areSetsEqual(override.modeIds, modeIdsToKeep)
        })
    }

    function createMatchingOverridesToShowComp(compModesOverrides, visibleModeIds) {
        compModesOverrides = compModesOverrides || []
        let matchingOverride = _.find(compModesOverrides, function (override) {
            return areSetsEqual(override.modeIds, visibleModeIds)
        })
        if (!matchingOverride) {
            matchingOverride = {
                modeIds: visibleModeIds
            }
            compModesOverrides.push(matchingOverride)
        }
        matchingOverride.isHiddenByModes = false
        return compModesOverrides
    }

    function areSetsEqual(array1, array2) {
        return array1.length === array2.length && _.intersection(array1, array2).length === array1.length
    }

    /**
     * @param {Pointer} compPointer a compPointer
     * @returns {*} the mode first parent pointer with active mode, or undefined if there isn't such parent.
     */
    function getFirstAncestorActiveModes(ps, compPointer) {
        const ancestorWithActiveModes = getFirstAncestorWithActiveModes(ps, compPointer)
        if (ancestorWithActiveModes) {
            return getComponentActiveModeIds(ps, ancestorWithActiveModes)
        }

        return {}
    }

    function getOwnOrFirstAncestorActiveModes(ps, compPointer) {
        const activeModesIds = getComponentActiveModeIds(ps, compPointer)
        return !_.isEmpty(activeModesIds) ? activeModesIds : getFirstAncestorActiveModes(ps, compPointer)
    }

    function getOverridePointerByActiveModes(ps, compPointer) {
        const activeModesIds = getOwnOrFirstAncestorActiveModes(ps, compPointer)
        const compOverrides = getComponentOverrides(ps, compPointer)
        const compOverrideIndex = _.findIndex(compOverrides, function (modeOverride) {
            return _.every(modeOverride.modeIds, modeId => activeModesIds[modeId])
        })

        if (compOverrideIndex >= 0) {
            const compOverridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
            return ps.pointers.getInnerPointer(compOverridesPointer, compOverrideIndex)
        }
        return null
    }

    function isComponentDisplayedByDefault(ps, compPointer) {
        const firstAncestorWithMode = getFirstAncestorWithActiveModes(ps, compPointer)
        if (firstAncestorWithMode) {
            const defaultMode = _.head(getComponentModesByType(ps, firstAncestorWithMode, COMP_MODES_TYPES.DEFAULT))
            if (!defaultMode) {
                return false
            }

            return isComponentDisplayedInModes(ps, compPointer, [defaultMode.modeId])
        }
        return true
    }

    function isComponentDisplayedInModes(ps, compPointer, modeIds) {
        if (!compPointer) {
            throw new Error('missing component pointer')
        }
        if (modeIds && !_.isArray(modeIds)) {
            modeIds = [modeIds]
        }
        const compOverrides = getComponentOverrides(ps, compPointer)
        const overrideInMode = _.find(compOverrides, function (override) {
            return _.isEqual(_.sortBy(override.modeIds), _.sortBy(modeIds))
        })
        if (overrideInMode && !_.isUndefined(overrideInMode[NOT_DISPLAYED_PROPERTY])) {
            return !overrideInMode[NOT_DISPLAYED_PROPERTY]
        }

        const compModes = ps.dal.full.get(ps.pointers.componentStructure.getModes(compPointer))
        if (compModes && !_.isUndefined(compModes[NOT_DISPLAYED_PROPERTY])) {
            return !compModes[NOT_DISPLAYED_PROPERTY]
        }

        return true
    }

    function isComponentCurrentlyDisplayed(ps, compPointer) {
        return ps.dal.isExist(compPointer)
    }

    function shouldDeleteComponentFromFullJson(ps, compPointer) {
        if (experiment.isOpen('dm_alwaysRemoveFromFull')) {
            return true
        }
        if (!isComponentCurrentlyDisplayed(ps, compPointer) && ps.dal.full.isExist(compPointer)) {
            return true
        }

        const ancestorWithActiveModes = getFirstAncestorWithActiveModes(ps, compPointer)
        if (!ancestorWithActiveModes) {
            return true
        }
        const activeModeId = _(getComponentActiveModeIds(ps, ancestorWithActiveModes)).keys().head()
        return !coreUtils.modesUtils.isCompVisibleAfterRemovalFromMode(ps.pointers, ps.dal.full, compPointer, ancestorWithActiveModes, activeModeId)
    }

    function removeDesignItemBehaviors(designItem, designParts) {
        if (designItem.dataChangeBehaviors) {
            designItem.dataChangeBehaviors = _.reject(designItem.dataChangeBehaviors, function (designBehavior) {
                return _.includes(designParts, designBehavior.part)
            })
            _.set(designItem, ['background', 'mediaTransforms'], {scale: 1})
        }
        return designItem
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Array<string>} modes - array of mode ids
     * @param {Array<string>} designParts
     */
    function removeDesignBehaviorInModes(ps, componentPointer, modes, designParts) {
        const designItem = design.getDesignItemByModes(ps, componentPointer, modes)
        const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id

        const updatedDesign = removeDesignItemBehaviors(designItem, designParts)

        dataModel.addSerializedDesignItemToPage(ps, pageId, updatedDesign, updatedDesign.id)
    }

    function removeDesignBehaviorsFromAllModes(ps, componentPointer, designParts) {
        const compModeDefinitions = getComponentModesDefinitions(ps, componentPointer)
        _.forEach(compModeDefinitions, function (modeDef) {
            removeDesignBehaviorInModes(ps, componentPointer, [modeDef.modeId], designParts)
        })
    }

    /**
     * This method returns the possible design items of given component, considering all modes, not just the current displayed mode
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {Array} designItems
     */
    function getAllCompDesignItems(ps, compPointer) {
        const modesPointer = ps.pointers.componentStructure.getModes(compPointer)
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const structureDesignPointer = ps.pointers.getInnerPointer(compPointer, 'designQuery')
        const structureDesignId = dsUtils.stripHashIfExists(ps.dal.full.get(structureDesignPointer))
        const structureDesignItem = design.getDesignItemById(ps, structureDesignId, pagePointer.id)
        if (!ps.dal.full.isExist(modesPointer)) {
            return [structureDesignItem]
        }
        let res = []
        const compModes = ps.dal.full.get(modesPointer)
        const compOverrides = compModes.overrides
        const compDefinitions = compModes.definitions || []

        const overridesWithDesignQuery = _.filter(compOverrides, 'designQuery')
        if (overridesWithDesignQuery.length !== compDefinitions.length) {
            res.push(structureDesignItem)
        }
        res = res.concat(
            _.map(overridesWithDesignQuery, function (override) {
                return design.getDesignItemByModes(ps, compPointer, override.modeIds)
            })
        )
        return res
    }

    function getMobileActiveModesMap(/** ps*/ ps, pageId) {
        const pagePointer = ps.pointers.components.getPage(pageId, coreUtils.constants.VIEW_MODES.DESKTOP)
        const copyableModes = _.filter(getComponentModesDefinitions(ps, pagePointer), function (definition) {
            return _.get(modeTypeMetaData, [definition.type, 'mobileConversionConfig']) === 'copyOverrides'
        })

        const explicitMobileModes = _.map(ps.pointers.components.getChildrenRecursively(pagePointer), _.partial(getCompMobileMode, ps))
        return _(explicitMobileModes)
            .concat(copyableModes)
            .map('modeId')
            .compact()
            .uniq()
            .map(function (definition) {
                return [definition, true]
            })
            .fromPairs()
            .value()
    }

    function getModeIdsNeededToShowComp(modes) {
        return _.get(_.find(modes.overrides, {isHiddenByModes: false}), 'modeIds')
    }

    function findParentWithModeDefinition(ps, compRefWidthModes) {
        let modesPointer = ps.pointers.componentStructure.getModes(compRefWidthModes)
        let modes = ps.dal.full.get(modesPointer)
        let hasModeDef = modes && modes.definitions && !_.isEmpty(modes.definitions)
        if (hasModeDef) {
            return compRefWidthModes
        }
        let parentRef = ps.pointers.full.components.getParent(compRefWidthModes)
        while (!hasModeDef && parentRef) {
            modesPointer = ps.pointers.componentStructure.getModes(parentRef)
            modes = ps.dal.full.get(modesPointer)
            hasModeDef = modes && modes.definitions && !_.isEmpty(modes.definitions)
            if (!hasModeDef) {
                parentRef = ps.pointers.full.components.getParent(parentRef)
            }
        }
        return parentRef
    }

    function getModesIdsAndParentNeededToShowComp(ps, componentPointer) {
        let modes, compWithModesOverride
        if (!ps.dal.isExist(componentPointer) && ps.dal.full.isExist(componentPointer)) {
            let modesPointer = ps.pointers.componentStructure.getModes(componentPointer)
            let parentRef = ps.pointers.full.components.getParent(componentPointer)
            if (ps.dal.full.isExist(modesPointer)) {
                modes = ps.dal.full.get(modesPointer)
                compWithModesOverride = componentPointer
            }
            while (!modes && parentRef) {
                if (ps.dal.full.isExist(parentRef)) {
                    modesPointer = ps.pointers.componentStructure.getModes(parentRef)
                    if (ps.dal.full.isExist(modesPointer)) {
                        modes = ps.dal.full.get(modesPointer)
                        compWithModesOverride = parentRef
                    }
                }
                if (!modes) {
                    parentRef = ps.pointers.full.components.getParent(parentRef)
                }
            }
            const modeIdsToActivate = getModeIdsNeededToShowComp(modes)
            const parentWithModesDef = findParentWithModeDefinition(ps, compWithModesOverride)
            return {
                modeIdsToActivate,
                parentWithModesDef
            }
        }
    }

    /**
     * @class componentModes
     */
    return {
        /**
         * Adds a new Mode to a component.
         *
         * @param {Pointer} compPointer A component pointer to match a corresponding Component.
         * @param {string} type A type of a certain mode.
         * @returns undefined
         *
         *      @exaample
         *      const myBoxRef = ...;
         *      documentServices.components.modes.add(myBoxRef, 'HOVER');
         */
        addModeDefinition,

        /**
         * @param {Pointer} compPointer A ComponentReference to match a corresponding Component.
         * @param {string} modeId an ID of a Mode defined on the Component.
         * @returns a mode object corresponding the component ref and mode id.
         */
        getComponentModeById,

        /**
         * @returns an array of all available modes to use on components.
         */
        getModeTypes,

        /**
         * @param {Pointer} compPointer A component pointer to match a corresponding Component.
         * @returns an array of all possible modes on the matching component.
         */
        getComponentModes: getComponentModesDefinitions,

        /**
         * @param {Pointer} compPointer A ComponentReference to match a corresponding Component.
         * @param {Pointer} type the mode type to get.
         * @returns an array of all available modes to use on components of a certain type.
         */
        getComponentModesByType,

        /**
         * Removes an existing mode of a component.
         *
         * @param {Pointer} compPointer A ComponentReference to match a corresponding Component.
         * @param {string} modeId a mode ID to remove.
         * @returns undefined
         *
         *      @example
         *      const myBoxRef = ...;
         *      documentServices.components.modes.remove(myBoxRef, 'compXYZ-hoverid-azxs14');
         */
        removeComponentMode,
        /**
         * @param {Pointer} compPointer A ComponentReference to match a corresponding Component.
         * @returns a map of all active modes IDs on the corresponding component (ref) keys are modeIds, values are booleans.
         */
        getComponentActiveModeIds,

        /**
         * Resets all active modes to non-active.
         */
        resetAllActiveModes,
        // TODO GuyR 24/04/2016 17:40 - rename to resetCompsToDefaultModes

        /**
         * @param {string} modeIdToActivate a mode ID to set as active.
         * @returns undefined
         */
        activateComponentMode: activateCompMode,

        /**
         * @param {string} modeIdToDeactivate a mode ID to deactivate.
         */
        deactivateCompMode,

        /**
         * @param {Pointer} a compPointer to create a modeID for.
         * @returns a unique ID for a mode
         */
        createUniqueModeId,

        showComponentOnlyOnPagesGroup,

        overrides: {
            getAllOverrides: getComponentOverrides,
            removeAllOverrides: removeComponentOverrides,
            getOverridePointerByActiveModes,
            applyCurrentToAllModes,
            updateComponentOverrides,

            /**
             * @param {ps} ps
             * @param compPointer a component pointer corresponding a component to show only in a certain modes combination
             * @param modeIdsCombination an array of mode Ids, whose combination (&&) determines when to show a component.
             */
            showComponentOnlyInModesCombination
        },

        /**
         * @param {Pointer} a compPointer
         * @returns the mode first parent pointer with active mode, or undefined if there isn't such parent.
         */
        getFirstAncestorActiveModes,

        getFirstAncestorWithActiveModes,

        isComponentDisplayedByDefault,

        isComponentDisplayedInModes,

        isComponentCurrentlyDisplayed,

        shouldDeleteComponentFromFullJson,

        getModeToAddId,

        getComponentModesAvailableInView,

        getCompMobileMode,

        removeDesignBehaviorsFromAllModes,

        getAllCompDesignItems,

        getMobileActiveModesMap,

        removeSingleModeBehaviors,

        metaData: modeTypeMetaData,

        getModesIdsAndParentNeededToShowComp
    }
})
