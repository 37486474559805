import _ from 'lodash'
import rpc from 'pm-rpc'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

function invokeApi(compId, compIFrameId, fn, fnArgs, callback) {
    return (
        rpc.api
            // @ts-ignore
            .request('publicApi', {initiator: compIFrameId})
            .then(function (api) {
                if (!api[fn]) {
                    throw new Error(`Api for component with id ${compId} does not contain function ${fn}`)
                }
                return api[fn].apply(api, fnArgs)
            })
            .then(callback)
            .catch(function (e) {
                coreUtils.log.error(e.message)
                callback(null, e.message)
            })
    )
}

function getCompIFrameId(comp) {
    const iFrame = comp.getIframe()
    return iFrame && iFrame.id
}

function handleAppAPIMessage(siteAPI, msg, callback) {
    const tpaComponentsDomAspect = siteAPI.getSiteAspect('tpaComponentsDomAspect')
    const compId = _.get(msg.data, 'compId')
    tpaComponentsDomAspect
        .getComponentWhenReady(compId)
        .then(function (comp) {
            if (!comp) {
                throw new Error(`comp ${compId} does not exist`)
            }
            const fn = _.get(msg.data, 'fn')
            const fnArgs = _.get(msg.data, 'fnArgs')
            switch (msg.type) {
                case 'invokeAppFn':
                    invokeApi(compId, getCompIFrameId(comp), fn, fnArgs, callback)
                    break
                case 'invokeAppOn':
                    invokeApi(compId, getCompIFrameId(comp), fn, [callback], callback)
                    break
                default:
                    break
            }
        })
        .catch(function (e) {
            coreUtils.log.error(e.message)
            callback(null, e.message)
        })
}

export default {
    handleAppAPIMessage
}
