define(['lodash', '@wix/santa-core-utils', 'layout'], function (_, coreUtilsLib, layout) {
    'use strict'

    function getFromViewBox(node) {
        const [x, y, width, height] = node.getAttribute('data-viewbox').split(' ')
        return {x, y, width, height}
    }
    function getFromBoundaries(svgElem) {
        const {x, y, width, height} = svgElem.getBBox()
        return {x, y, width, height}
    }

    function storeSVGMeasures(ps, id, measureMap, nodesMap) {
        const node = nodesMap[id]
        const svgId = node.getAttribute('data-svg-id')
        const svgElem = node.querySelector('svg')

        if (!svgId || !svgElem) {
            return
        }

        const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const boxBoundariesPointer = ps.pointers.getInnerPointer(storePointer, coreUtilsLib.svgUtils.SVG_STORES.BOX_BOUNDARIES)

        if (!ps.dal.get(storePointer)) {
            return
        }

        if (_.isEmpty(ps.dal.get(boxBoundariesPointer))) {
            const preserveViewBox = node.getAttribute('data-preserve-viewbox') === 'preserve'
            const viewBoxBoundaries = getFromViewBox(node)
            const boxBoundaries = preserveViewBox && _.some(viewBoxBoundaries) ? viewBoxBoundaries : getFromBoundaries(svgElem)
            if (_.some(boxBoundaries)) {
                ps.dal.set(boxBoundariesPointer, boxBoundaries)
            }
        }
    }

    const compsToRegisterMeasure = [
        'wysiwyg.viewer.components.VectorImage',
        'wysiwyg.viewer.components.svgshape.SvgShape',
        'wysiwyg.viewer.components.PopupCloseIconButton',
        'wysiwyg.viewer.components.MediaControls',
        'wysiwyg.viewer.components.MediaOverlayControls',
        'wysiwyg.viewer.components.mobile.TinyMenu',
        'wysiwyg.viewer.components.LoginSocialBar',
        'wysiwyg.viewer.components.BackToTopButton'
    ]

    return {
        initialize(ps) {
            layout.registerShapesMeasureFunction(compsToRegisterMeasure, _.partial(storeSVGMeasures, ps))
        }
    }
})
