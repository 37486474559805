import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import inputProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/inputProxy'

/**
 * @class proxies.TextInput
 * @extends proxies.mixins.inputProxy
 */
export default {
    mixins: [inputProxy],
    renderProxy() {
        const data = this.proxyData
        const componentType = 'wysiwyg.viewer.components.inputs.TextInput'
        const props = this.getChildCompProps(componentType)
        props.compData = typesConverter.text(data)
        props.compProp = {
            label: this.getCompProp('label'),
            placeholder: this.getCompProp('placeholder')
        }
        props.message = this.getCompProp('message')
        props.isValid = this.getCompProp('isValid')
        const self = this
        props.onChange = function (e, domID) {
            self.setData(e.target.value)
            e.type = 'inputChanged'
            self.handleViewEvent(e, domID)
        }

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
