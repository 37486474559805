import ReactDOM from 'react-dom'
import _ from 'lodash'
import $ from 'zepto'

function getTPAModalAspect(siteAPI) {
    return siteAPI.getSiteAspect('tpaModalAspect')
}

function getTPAPopupAspect(siteAPI) {
    return siteAPI.getSiteAspect('tpaPopupAspect')
}

const getValueAsNumber = function (val) {
    if (_.isString(val)) {
        const x = parseInt(val, 10)
        if (!_.isNaN(x)) {
            return x
        }
        return 0
    } else if (_.isNumber(val)) {
        return val
    }
    return 0
}

const getCompTpaData = function (comp) {
    return _.get(comp, 'props.compData.tpaData')
}

const getContainerCompData = function (siteAPI, compId, url, width, height, theme, title?) {
    const comp = siteAPI.getComponentById(compId)
    return {
        origCompId: compId,
        applicationId: _.get(comp, 'props.compData.applicationId'),
        externalId: _.get(comp, 'props.compData.referenceId'),
        tpaData: getCompTpaData(comp),
        url,
        width,
        height,
        theme,
        title,
        windowSize: {
            width: $(window).width(),
            height: $(window).height()
        }
    }
}

const getModalCompData = function (siteAPI, compId, url, width, height, theme, title) {
    const modalData = getContainerCompData(siteAPI, compId, url, width, height, theme, title)
    return _.omitBy(modalData, _.isUndefined)
}

const openModal = function (siteAPI, compId, url, width, height, theme, onClose, title) {
    const tpaModalAspect = getTPAModalAspect(siteAPI)
    const modalData = getModalCompData(siteAPI, compId, url, width, height, theme, title)
    tpaModalAspect.showModal(modalData, onClose)
}

const shouldAllowOpenPopup = function (comp) {
    const notAllowedTypes = ['wysiwyg.viewer.components.tpapps.TPAModal']

    return !_.includes(notAllowedTypes, _.get(comp, 'props.structure.componentType'))
}

const getPopupCompData = function (siteAPI, compId, url, width, height, theme, position, persistent) {
    const comp = siteAPI.getComponentById(compId)

    const popupData: any = getContainerCompData(siteAPI, compId, url, width, height, theme)
    const node = ReactDOM.findDOMNode(comp)
    if (node) {
        popupData.origCompStyle = $(node).offset()
        const clientRect = node.getBoundingClientRect()
        popupData.origCompStyle.actualTop = clientRect.top
        popupData.origCompStyle.actualLeft = clientRect.left
    }
    popupData.position = _.defaults(position || {}, {
        origin: 'FIXED',
        placement: 'CENTER',
        x: 0,
        y: 0
    })
    popupData.position.x = getValueAsNumber(popupData.position.x)
    popupData.position.y = getValueAsNumber(popupData.position.y)
    popupData.persistent = persistent

    return _.omitBy(popupData, _.isUndefined)
}

const openPopup = function (siteAPI, compId, url, width, height, theme, position, persistent, onClose) {
    const comp = siteAPI.getComponentById(compId)

    if (!shouldAllowOpenPopup(comp)) {
        const err = new Error()
        err.name = 'Operation not supported'
        err.message = 'An app can not open a popup from a modal.'
        throw err
    }

    const tpaPopupAspect = getTPAPopupAspect(siteAPI)
    tpaPopupAspect.showPopup(getPopupCompData(siteAPI, compId, url, width, height, theme, position, persistent), onClose)
}

export default {
    openModal,
    openPopup
}
