define(['lodash', 'documentServices/wixCode/utils/schemaUtils', '@wix/document-manager-utils', '@wix/santa-core-utils'], function (
    _,
    schemaUtils,
    {ReportableError},
    santaCoreUtils
) {
    function readPointerIfExists(ps, pointer, defaultValue) {
        return ps.dal.isExist(pointer) ? ps.dal.get(pointer) : defaultValue
    }

    function isUndoableFile(filePath) {
        return schemaUtils.isSchemaFile(filePath)
    }

    function assembleUndoableFile(filePath, loadedContent, modifiedContent, undoableModifiedContent) {
        if (_.isUndefined(loadedContent)) {
            throw new ReportableError({
                message: `Cannot read undoable file which has not been loaded [${filePath}]`,
                errorType: 'undoableFileNotLoaded',
                tags: {}
            })
        }
        if (schemaUtils.isSchemaFile(filePath)) {
            return schemaUtils.assembleSchema(loadedContent, modifiedContent, undoableModifiedContent)
        }
    }

    function generateUndoableId(filePath) {
        return santaCoreUtils.guidUtils.getUniqueId(filePath, '-')
    }

    function setUndoableContent(ps, filePath, content) {
        const filePathIdPointer = ps.pointers.wixCode.getFilePathId(filePath)
        let filePathId = readPointerIfExists(ps, filePathIdPointer, null)
        if (filePathId === null) {
            filePathId = generateUndoableId(filePath)
            ps.dal.set(filePathIdPointer, filePathId)
        }

        const undoableContentPointer = ps.pointers.wixCode.getUndoableModifiedFileContent(filePathId)
        ps.dal.set(undoableContentPointer, content)
    }

    function getUndoableContent(ps, filePath) {
        const filePathIdPointer = ps.pointers.wixCode.getFilePathId(filePath)
        const filePathId = readPointerIfExists(ps, filePathIdPointer, null)
        if (filePathId === null) {
            return null
        }
        const undoableContentPointer = ps.pointers.wixCode.getUndoableModifiedFileContent(filePathId)
        return readPointerIfExists(ps, undoableContentPointer, null)
    }

    function getUndoableContentByPath(undoableContentByIdMap, undoablePathIdMap) {
        return _.mapValues(undoablePathIdMap, function (id) {
            return undoableContentByIdMap[id]
        })
    }

    return {
        isUndoableFile,
        assembleUndoableFile,
        setUndoableContent,
        getUndoableContent,
        getUndoableContentByPath
    }
})
