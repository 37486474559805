define([
    'lodash',
    'documentServices/hooks/hooks',
    'documentServices/component/component',
    'documentServices/structure/structure',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/tpa/services/appInstallationAndDeletionEvents',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/tpaComponentCommonService',
    'documentServices/tpa/compStructure',
    'documentServices/tpa/services/tpaWidgetLayoutService',
    'documentServices/tpa/services/tpaDeleteService',
    'documentServices/tpa/services/tpaDataService',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/tpa/utils/responsiveUtils',
    'documentServices/platform/services/workerService',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants'
], function (
    _,
    hooks,
    component,
    structure,
    componentDetectorAPI,
    appInstallationAndDeletionEvents,
    installedTpaAppsOnSiteService,
    clientSpecMapService,
    tpaComponentCommonService,
    compStructure,
    tpaWidgetLayoutHelper,
    tpaDeleteService,
    tpaDataService,
    documentModeInfo,
    responsiveUtils,
    workerService,
    contextAdapter,
    dsConstants
) {
    'use strict'

    const isGlued = function (widgetData) {
        return _.has(widgetData, 'gluedOptions') && !_.isNull(widgetData.gluedOptions)
    }

    const addWidgetAfterProvision = function (ps, componentToAddPointer, options, responseDefinitionData, completeCallback, onError, waitForSOQ = true) {
        options = options || {}

        let invokeAddAppCallbacks = true
        if (_.isBoolean(_.get(options, 'invokeAddAppCallbacks'))) {
            invokeAddAppCallbacks = _.get(options, 'invokeAddAppCallbacks')
        }
        let addedCompsRef = [componentToAddPointer]
        let addedHiddenPages, essentialWidgets
        const firstAdd = !installedTpaAppsOnSiteService.isApplicationIdExists(ps, responseDefinitionData.applicationId)
        if (firstAdd) {
            if (clientSpecMapService.hasSections(ps, responseDefinitionData)) {
                if (clientSpecMapService.hasMainSection(ps, responseDefinitionData)) {
                    if (_.isFunction(onError)) {
                        onError()
                    }
                    return
                }
                addedHiddenPages = tpaComponentCommonService.addHiddenPages(ps, responseDefinitionData, options.componentOptions)
            }
            essentialWidgets = addEssentialWidgets(ps, responseDefinitionData, options.componentOptions)
        }

        addWidget(ps, componentToAddPointer, options, responseDefinitionData)
        addedCompsRef = addedCompsRef.concat(addedHiddenPages, essentialWidgets)
        if (firstAdd && invokeAddAppCallbacks) {
            appInstallationAndDeletionEvents.invokeAddAppCallbacks(responseDefinitionData.appDefinitionId, options)
        }

        const addPlatformFunc = () =>
            tpaComponentCommonService
                .addPlatformAppIfNeeded(ps, responseDefinitionData, options)
                .then(_.partial(notifyAfterWidgetAddedIfNeeded, ps, componentToAddPointer, options, responseDefinitionData)) // eslint-disable-line promise/prefer-await-to-then
                .then(_.partial(invokeWidgetCallback, options, componentToAddPointer, true, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
                .catch(_.partial(invokeWidgetCallback, options, componentToAddPointer, false, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then

        if (waitForSOQ) {
            if (ps.setOperationsQueue.isRunningSetOperation() && firstAdd) {
                ps.setOperationsQueue.executeAfterCurrentOperationDone(addPlatformFunc)
            } else {
                Promise.resolve()
                    .then(_.partial(notifyAfterWidgetAddedIfNeeded, ps, componentToAddPointer, options, responseDefinitionData)) // eslint-disable-line promise/prefer-await-to-then
                    .then(_.partial(invokeWidgetCallback, options, componentToAddPointer, true, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
                    .catch(_.partial(invokeWidgetCallback, options, componentToAddPointer, false, addedCompsRef)) // eslint-disable-line promise/prefer-await-to-then
            }

            if (completeCallback) {
                completeCallback(ps)
            }
        } else {
            addPlatformFunc().then(() => completeCallback(ps)) // eslint-disable-line promise/prefer-await-to-then
        }

        return componentToAddPointer
    }

    /*
        NOTE: this is a temporary solution for pr-gallery app to be able to unblock TB rollout.
        API of notifyBeforeWidgetAdded should be removed as soon as experiment dm_moveComponentAddedToStageToDm is merged.
        Issue is that adding app platform part is done AFTER widget structure is added, so api of beforeWidgetAdded is not possible
        if app is not installed yet (unless we change order of execution which will probably lead to bigger issues).
    */
    const notifyAfterWidgetAddedIfNeeded = function (ps, componentToAddPointer, options, responseDefinitionData) {
        const applicationId = responseDefinitionData?.applicationId
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        const shouldNotifyBeforeWidgetAdded = clientSpecMapService.hasEditorPlatformPart(appData) && clientSpecMapService.isAppActive(ps, appData)

        if (shouldNotifyBeforeWidgetAdded) {
            const payload = _.pickBy(
                {
                    compId: componentToAddPointer.id,
                    originCompId: options?.componentDefinition?.originCompId,
                    instanceId: appData.instanceId
                },
                _.identity
            )

            return workerService.notifyBeforeWidgetAdded(ps, applicationId, payload)
        }
    }

    const invokeWidgetCallback = function (options, componentToAddPointer, success, addedCompsRef) {
        if (options.callback) {
            options.callback({
                comp: componentToAddPointer,
                addedCompsRef,
                success
            })
        }
    }

    const addWidget = function (ps, componentToAddPointer, options, responseDefinitionData) {
        const {applicationId, appDefinitionId} = responseDefinitionData
        const widgetId = options.widgetId || getDefaultWidgetId(ps, responseDefinitionData)
        const widgetData = _.get(responseDefinitionData, `widgets.${widgetId}`)
        const tpaWidgetId = _.get(widgetData, 'tpaWidgetId')
        let tpaWidgetStyle = options.styleId || options.style
        if (tpaWidgetId && _.get(options, 'componentOptions.widget.tpaWidgetId') === tpaWidgetId) {
            options.pageId = options.componentOptions.widget.wixPageId || options.pageId
            options.showOnAllPages = options.componentOptions.widget.allPages || options.showOnAllPages
            tpaWidgetStyle = options.componentOptions.styleId || tpaWidgetStyle
        }

        let pageId = options.pageId || ps.siteAPI.getFocusedRootId()

        if (!widgetId || !pageId || !widgetData) {
            throw new Error('invalid params')
        }

        let componentDefinition
        let layout

        if (options.componentDefinition) {
            componentDefinition = options.componentDefinition

            if (
                (componentDefinition.data && componentDefinition.data.applicationId && componentDefinition.data.applicationId !== String(applicationId)) ||
                (componentDefinition.data && !componentDefinition.data.applicationId)
            ) {
                // NOTE: applicationId could be different if componentDefinition is from another side
                componentDefinition = Object.assign({}, componentDefinition, {
                    data: Object.assign({}, componentDefinition.data, {
                        applicationId: String(applicationId)
                    })
                })
            }
        } else {
            const defaultSize = {
                width: widgetData.defaultWidth,
                height: widgetData.defaultHeight
            }
            if (options.layout) {
                _.defaults(options.layout, defaultSize)
            }

            layout = {
                width: widgetData.defaultWidth || 0,
                height: widgetData.defaultHeight || 0,
                defaultPosition: widgetData.defaultPosition || _.get(options, 'layout.defaultPosition') || {}
            }

            if (
                widgetData.defaultShowOnAllPages ||
                options.showOnAllPages ||
                isGlued(widgetData) ||
                layout.defaultPosition.region === 'header' ||
                layout.defaultPosition.region === 'footer'
            ) {
                pageId = 'masterPage'

                if (options.useRelativeToContainerLayout) {
                    // in case if component should be attached to master page, ignore containerRef
                    delete options.containerRef
                    delete options.useRelativeToContainerLayout
                }
            }

            if (isGlued(widgetData)) {
                componentDefinition = compStructure.getGluedWidgetStructure(applicationId, appDefinitionId, widgetData, layout, tpaWidgetStyle)
            } else {
                componentDefinition = compStructure.getWidgetStructure(
                    applicationId,
                    appDefinitionId,
                    widgetData.widgetId,
                    tpaWidgetLayoutHelper.getCompLayoutFrom(ps, layout, options.layout, options.useRelativeToContainerLayout ? options.containerRef : null),
                    tpaWidgetStyle
                )
            }

            if (options.responsiveLayout) {
                responsiveUtils.replaceLayout(componentDefinition, options.responsiveLayout)
            }
        }
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.TPA_WIDGET_ADDITION.GET_CONTAINER, {
            extras: {options, viewMode: documentModeInfo.getViewMode(ps), pageId, layout, componentToAddPointer}
        })
        const containerPointer = options.containerRef || getContainerPointer(ps, pageId, options, layout)
        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.TPA_WIDGET_ADDITION.GET_CONTAINER, {
            extras: {viewMode: documentModeInfo.getViewMode(ps), containerPointer, componentToAddPointer}
        })
        if (!componentToAddPointer) {
            componentToAddPointer = component.getComponentToAddRef(ps, containerPointer)
        }

        compStructure.convertStyleIdToStyleDef(ps, componentDefinition)

        if (componentDefinition) {
            hooks.executeHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, undefined, [componentDefinition, containerPointer])
        }
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.TPA_WIDGET_ADDITION.ADD_WIDGET, {
            extras: {containerPointer, componentDefinition}
        })
        component.add(ps, componentToAddPointer, containerPointer, componentDefinition)
        contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.TPA_WIDGET_ADDITION.ADD_WIDGET, {})
        stretchToFullWidthIfNeeded(ps, componentToAddPointer, widgetData, options)

        tpaComponentCommonService.setPrefetchPageBehaviorIfNeeded(ps, componentToAddPointer, responseDefinitionData)

        if (_.isString(options.presetId) && !_.isEmpty(options.presetId)) {
            tpaDataService.set(ps, componentToAddPointer, 'presetId', options.presetId, tpaDataService.SCOPE.COMPONENT)
        }
        return componentToAddPointer
    }

    const getContainerPointer = function (ps, pageId, options, layout) {
        const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
        let containerPointer = pagePointer
        if (options.parentContainerRef && ps.pointers.isSamePointer(ps.pointers.components.getPageOfComponent(options.parentContainerRef), pagePointer)) {
            containerPointer = options.parentContainerRef
        }
        if (_.get(layout, 'defaultPosition.region') === 'header') {
            containerPointer = componentDetectorAPI.getSiteHeader(ps)
        }
        return containerPointer
    }

    const stretchToFullWidthIfNeeded = function (ps, componentToAddPointer, widgetData, options) {
        const shouldStretchCase1 = widgetData.canBeStretched && widgetData.shouldBeStretchedByDefault && !options.dontStretch
        const shouldStretchCase2 = options.dontStretch === false
        const shouldStretchComp = shouldStretchCase1 || shouldStretchCase2
        if (shouldStretchComp) {
            structure.setDock(ps, componentToAddPointer, {left: {vw: 0}, right: {vw: 0}})
        }
    }

    const addEssentialWidgets = function (ps, appData, componentOptions) {
        const widgets = clientSpecMapService.widgetsToAutoAddToSite(ps, appData)
        const addedWidgets = []
        _.forEach(widgets, function (widget) {
            if (!widget.default) {
                const compRef = addWidget(
                    ps,
                    undefined,
                    {
                        widgetId: widget.widgetId,
                        componentOptions
                    },
                    appData
                )
                addedWidgets.push(compRef)
            }
        })
        return addedWidgets
    }

    const getDefaultWidgetId = function (privateServices, appData) {
        const appWidgets = _.filter(appData.widgets, function (widget) {
            return _.isNil(widget.appPage) || _.isUndefined(widget.appPage)
        })

        const defaultWidget = _.find(appWidgets, {default: true})
        return _.get(defaultWidget, 'widgetId') || (appWidgets && !_.isEmpty(appWidgets) && appWidgets[0].widgetId) || null
    }

    const duplicateWidget = function (ps, compPointer, pageId) {
        pageId = pageId || ps.siteAPI.getFocusedRootId(ps)
        const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
        if (!ps.dal.get(pagePointer)) {
            throw new Error('no such component')
        }
        const dupCompPointer = component.getComponentToDuplicateRef(ps, compPointer, pagePointer)
        component.duplicate(ps, dupCompPointer, compPointer, pagePointer)
        // TODO: this method should probably return a ref to the new comp (see component.duplicateComponent)
    }

    const notifyBeforeWidgetDeleteInternal = function (ps, compPointer, onComplete = _.noop) {
        const compData = component.data.get(ps, compPointer)
        const {applicationId} = compData
        tpaDeleteService.notifyWidgetAboutToDelete(ps, compPointer, applicationId, onComplete)
    }

    const notifyBeforeWidgetDelete = function (ps, compPointer) {
        notifyBeforeWidgetDeleteInternal(ps, compPointer, ps.setOperationsQueue.asyncPreDataManipulationComplete.bind(ps.setOperationsQueue))
    }

    const deleteWidget = function (ps, componentPointer, onComplete) {
        const pagesToBeRemoved = getPagesToBeRemovedWithWidget(ps, componentPointer)
        const compNode = component.data.get(ps, componentPointer)
        const {applicationId} = compNode
        const completeCallback = () => {
            tpaDeleteService.deleteTpaCompleteCallback(ps, [applicationId])
            if (_.isFunction(onComplete)) {
                onComplete(pagesToBeRemoved)
            }
        }
        return tpaDeleteService.deleteWidget.apply(tpaComponentCommonService, [ps, componentPointer, applicationId, completeCallback])
    }

    const getPagesToBeRemovedWithWidget = function (ps, compPointer) {
        const compData = component.data.get(ps, compPointer)
        let applicationId = _.get(compData, 'applicationId')
        if (applicationId) {
            applicationId = parseInt(applicationId, 10)
        }
        const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppId(ps, applicationId)
        const lastInstalledMainWidget = tpaDeleteService.isLastInstalledMainWidget(installedWidgets, ps, applicationId, compPointer)
        if (lastInstalledMainWidget) {
            const appIdsToDelete = _.union(
                [applicationId],
                _.map(installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, applicationId), 'applicationId')
            )
            return installedTpaAppsOnSiteService.getPagesByApplicationIds(ps, appIdsToDelete)
        }
        return []
    }
    const getTPAWidgetDeleteInteractionParams = (ps, compPointer) => {
        const compData = component.data.get(ps, compPointer)
        const applicationId = _.get(compData, 'applicationId')
        const appDefinitionId = _.get(clientSpecMapService.getAppData(ps, applicationId), 'appDefinitionId')

        return {app_id: appDefinitionId, comp_pointer: compPointer}
    }

    return {
        isGlued,
        addWidgetAfterProvision,
        duplicateWidget,
        notifyBeforeWidgetDelete,
        notifyBeforeWidgetDeleteInternal,
        deleteWidget,
        getPagesToBeRemovedWithWidget,
        invokeWidgetCallback,
        getTPAWidgetDeleteInteractionParams
    }
})
