import type {PS} from '@wix/document-services-types'
import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const imageQuality = viewerLibrary.get('documentServices/media/globalImageQualityData')
    const vectorImage = viewerLibrary.get('documentServices/media/vectorImageAPI')
    const svgFiltersDefinitions = viewerLibrary.get('documentServices/media/svgFiltersDefinitions')
    return {
        methods: {
            media: {
                mediaState: {
                    /**
                     * Register to a player's state updates
                     * @param listenerId
                     * @param compRef
                     * @param callback
                     */
                    register: publicMethodUtils.defineAction((ps: PS, listenerId: string, compRef: any, callback: any) => {
                        // @ts-ignore
                        ps.siteAPI.registerStateChange(listenerId, compRef.id, callback)
                    }),
                    /**
                     * Stop listening to player updates
                     * @param listenerId
                     * @param compRef
                     */
                    unregister: publicMethodUtils.defineAction((ps: PS, listenerId: string, compRef: any) => {
                        // @ts-ignore
                        ps.siteAPI.unregisterStateChange(listenerId, compRef.id)
                    }),
                    /**
                     * fetch relevant video data for controls view
                     * @param compRef
                     */
                    getControlsData: publicMethodUtils.defineGetter((ps: PS, compRef: any) => {
                        const mediaAspectPointer = ps.pointers.siteAspects.getAspectComponentData('mediaAspect', compRef.id)
                        const {controlsData} = ps.dal.get(mediaAspectPointer)
                        return controlsData
                    }),
                    /**
                     * Get supported features
                     * @returns {object}
                     */
                    getSupportedFeatures: publicMethodUtils.defineGetter((ps: PS) => {
                        const mediaAspectPointer = ps.pointers.siteAspects.getAspectGlobalData('mediaAspect')
                        const {featureDetections} = ps.dal.get(mediaAspectPointer)
                        return featureDetections || {}
                    })
                },
                imageQuality: {
                    /**
                     * Get the global quality data item
                     * @returns {object} of schema type GlobalImageQuality
                     */
                    get: publicMethodUtils.defineGetter(imageQuality.get),
                    /**
                     * Update (or create new) the global image quality data item of the site
                     * @param {object} data a GlobalImageQuality data item
                     */
                    update: publicMethodUtils.defineDataManipulationAction(imageQuality.update),
                    /**
                     * Reset the global quality data item (We never remove the item, only its content)
                     */
                    reset: publicMethodUtils.defineDataManipulationAction(imageQuality.reset)
                },
                svgFilters: {
                    getDefinitions: publicMethodUtils.defineGetter(svgFiltersDefinitions.getDefinitions),
                    /**
                     * Get filter definition names as array
                     * @returns {Array}
                     */
                    getNames: publicMethodUtils.defineGetter(svgFiltersDefinitions.getNames)
                },
                vectorImage: {
                    /**
                     * Get svg string by svg id, fetch the file if not in cache and put in cache
                     * Might be async.
                     * @param {string} svgId
                     * @param {function} callback
                     */
                    fetchSvg: publicMethodUtils.defineDataManipulationAction(vectorImage.getSvgFromCache, {
                        isAsyncOperation: true,
                        asyncPreDataManipulation: vectorImage.cacheSvgString
                    }),

                    /**
                     * Get svg string by svg id, only if in cache
                     * @param {string} svgId
                     * @returns {string}
                     */
                    getSvgFromCache: publicMethodUtils.defineGetter(vectorImage.getSvgFromCache),
                    getSvgInfoFromCache: publicMethodUtils.defineGetter(vectorImage.getSvgInfoFromCache),
                    /**
                     * Get original aspect ratio of an onstage SVG
                     * SVG must be rendered to stage or aspect ratio will return 0
                     * @param {Pointer} compRef
                     * @returns {number}
                     */
                    getSvgOriginalAspectRatio: publicMethodUtils.defineGetter(vectorImage.getOriginalAspectRatio)
                }
            }
        }
    }
}
