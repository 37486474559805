define([
    'documentServices/wixapps/services/savePublish',
    'documentServices/wixapps/services/savePublishBolt',
    'documentServices/utils/runtimeConfig'
], function (savePublish, savePublishBolt, runtimeConfig) {
    'use strict'

    return {
        create(ps) {
            if (!runtimeConfig.isSanta(ps)) {
                return savePublishBolt(ps)
            }
            return savePublish
        },
        getTaskName: savePublish.getTaskName
    }
})
