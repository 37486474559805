define(['lodash', 'documentServices/mobileConversion/mobileEditorSettings/quickActionsDefinitions', '@wix/santa-ds-libs/src/coreUtils'], function (
    _,
    quickActionsDefinitions,
    utils
) {
    'use strict'

    const {linkTypes} = utils

    function createPhoneActionFromSiteMetaData(siteMetaData) {
        if (_.isEmpty(siteMetaData.contactInfo.phone)) {
            return null
        }
        return {
            itemType: 'phone',
            link: {
                type: linkTypes.PHONE_LINK,
                phoneNumber: siteMetaData.contactInfo.phone
            },
            text: 'Phone',
            icon: _.get(quickActionsDefinitions.quickActionIconsData, 'phone.hash'),
            color: _.get(quickActionsDefinitions.quickActionIconsData, 'phone.color')
        }
    }

    function createEmailActionFromSiteMetaData(siteMetaData) {
        if (_.isEmpty(siteMetaData.contactInfo.email)) {
            return null
        }
        return {
            itemType: 'email',
            link: {
                type: linkTypes.EMAIL_LINK,
                recipient: siteMetaData.contactInfo.email,
                subject: ''
            },
            text: 'Email',
            icon: _.get(quickActionsDefinitions.quickActionIconsData, 'email.hash'),
            color: _.get(quickActionsDefinitions.quickActionIconsData, 'email.color')
        }
    }

    function createAddressActionFromSiteMetaData(siteMetaData) {
        if (_.isEmpty(siteMetaData.contactInfo.address)) {
            return null
        }
        return {
            itemType: 'address',
            link: {
                type: linkTypes.EXTERNAL_LINK,
                target: '_blank',
                url: `http://www.google.co.il/maps/search/${encodeURI(siteMetaData.contactInfo.address)}`
            },
            text: 'Address',
            icon: _.get(quickActionsDefinitions.quickActionIconsData, 'address.hash'),
            color: _.get(quickActionsDefinitions.quickActionIconsData, 'address.color')
        }
    }

    function createSocialActionsFromSiteMetaData(siteMetaData) {
        return _(siteMetaData.quickActions.socialLinks)
            .filter(link => _.includes(quickActionsDefinitions.actionTypesMap, link.id))
            .map(link => ({
                itemType: link.id,
                link: {
                    type: linkTypes.EXTERNAL_LINK,
                    target: '_blank',
                    url: link.url
                },
                text: _.get(quickActionsDefinitions.socialLinkTexts, link.id, link.id),
                icon: _.get(quickActionsDefinitions.quickActionIconsData, `${link.id}.hash`),
                color: _.get(quickActionsDefinitions.quickActionIconsData, `${link.id}.color`)
            }))
            .value()
    }

    function createFromSiteMetaData(siteMetaData) {
        return _([
            createPhoneActionFromSiteMetaData(siteMetaData),
            createEmailActionFromSiteMetaData(siteMetaData),
            createAddressActionFromSiteMetaData(siteMetaData),
            createSocialActionsFromSiteMetaData(siteMetaData)
        ])
            .flatten()
            .compact()
            .value()
    }

    return {
        createFromSiteMetaData
    }
})
