import PropTypes from 'prop-types'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import baseImageProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseImageProxy'

/**
 * @class proxies.VideoThumb
 * @extends proxies.mixins.baseImageProxy
 */
export default {
    mixins: [baseImageProxy],
    propTypes: {
        viewProps: PropTypes.shape({
            classSet: PropTypes.func.isRequired,
            resolveImageData: PropTypes.func.isRequired,
            serviceTopology: PropTypes.object.isRequired
        })
    },

    getProxyCustomCssClass() {
        return this.props.viewProps.classSet({videoIndicator: true})
    },

    getCompData() {
        return typesConverter.videoThumb(this.proxyData, this.props.viewProps.resolveImageData, this.props.viewProps.serviceTopology)
    }
}
