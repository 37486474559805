import layout from '../util/layout'

const patchFitToContentCompHeight = (id, patchers) => {
    patchers.css(id, {
        height: ''
    })
}

const patchFitToContentComp = (id, patchers) => {
    patchers.css(id, {
        width: '',
        height: ''
    })
}

layout.registerPatcher('wysiwyg.viewer.components.FacebookShare', patchFitToContentComp)
layout.registerPatcher('wysiwyg.viewer.components.PinterestFollow', patchFitToContentComp)
layout.registerPatcher('wysiwyg.viewer.components.PayPalButton', patchFitToContentComp)
layout.registerPatcher('platform.components.AppController', patchFitToContentComp)
layout.registerPatcher('wysiwyg.viewer.components.inputs.RadioGroup', patchFitToContentCompHeight)
layout.registerPatcher('wysiwyg.viewer.components.inputs.CheckboxGroup', patchFitToContentCompHeight)
layout.registerPatcher('wysiwyg.viewer.components.LoginButton', patchFitToContentCompHeight)
layout.registerPatcher('wixui.Pagination', patchFitToContentCompHeight)
layout.registerPatcher('wixui.Tags', patchFitToContentCompHeight)
layout.registerPatcher('wixui.LineShareButton', patchFitToContentComp)
export default {}
