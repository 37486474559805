define(['lodash', 'documentServices/wixCode/utils/systemFolders'], function (_, systemFolders) {
    'use strict'

    function isFileSystemItem(fileSystemItem) {
        return _.isObject(fileSystemItem) && fileSystemItem.name && fileSystemItem.location
    }

    function isFolder(fileSystemItem) {
        return isFileSystemItem(fileSystemItem) && fileSystemItem.directory
    }

    function isFile(fileSystemItem) {
        return isFileSystemItem(fileSystemItem) && !fileSystemItem.directory
    }

    function isInsideFolder(folder, item) {
        return isFolder(folder) && item.location.indexOf(folder.location) === 0
    }

    function getFileName(file) {
        return _.last(file.location.split('/'))
    }

    function getParentFolderPath(item) {
        const path = item.location

        const parts = path
            .replace(/\/$/, '') // remove trailing slash in case of folder
            .split('/')

        parts.pop() // remove item name
        return parts.join('/')
    }

    function isPagesFolder(descriptor) {
        return isFolder(descriptor) && _.startsWith(descriptor.location, systemFolders.PAGES)
    }

    function isPageFile(descriptor) {
        return isFile(descriptor) && descriptor.location.split('/').length === 3 && _.startsWith(descriptor.location, systemFolders.PAGES)
    }

    function isSystemFolder(descriptor) {
        return isFolder(descriptor) && _.includes(_.values(systemFolders), descriptor.location.toLowerCase().replace(/\/*$/, ''))
    }

    function getPageId(descriptor) {
        if (isPageFile(descriptor)) {
            return getFileName(descriptor).replace('.js', '')
        }
    }

    return {
        isFileSystemItem,
        isFolder,
        isFile,
        isInsideFolder,
        getFileName,
        isPagesFolder,
        isPageFile,
        isSystemFolder,
        getParentFolderPath,
        getPageId
    }
})
