import santaComponents from '@wix/santa-components'
import proxySantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxySantaTypesDefinitions'
import PropTypes from 'prop-types'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import boxProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/boxProxy'

const {santaTypesDefinitions} = santaComponents

/**
 * @class proxies.Link
 * @extends proxies.mixins.boxProxy
 */
export default {
    mixins: [boxProxy],

    propsTypes: {
        viewProps: PropTypes.shape({
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
            rootNavigationInfo: santaTypesDefinitions.Component.rootNavigationInfo.isRequired
        })
    },

    getChildrenOrientation() {
        return this.getCompProp('orientation') || 'vertical'
    },

    getReactClass() {
        return santaComponents.utils.createReactElement.bind(null, 'a')
    },

    getCustomProps() {
        const data = typesConverter.link(this.proxyData, this.props.viewProps.linkRenderInfo.pagesDataItemsMap)
        const props = coreUtils.linkRenderer.renderLink(data, this.props.viewProps.linkRenderInfo, this.props.viewProps.rootNavigationInfo)
        props.className = 'wixAppsLink'

        return props
    }
}
