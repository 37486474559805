define(['@wix/santa-ds-libs/src/utils', 'documentServices/theme/theme', 'documentServices/siteMetadata/generalInfo'], function (utils, theme, generalInfo) {
    'use strict'

    const {fonts} = utils

    /**
     * @param {ps} ps
     * returns ['googleApiUrl', 'languageStaticsUrl', 'helveticaStaticsUrl']
     * @returns {string[]}
     */
    function getCssUrls(ps) {
        const documentType = generalInfo.getDocumentType(ps)
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        return fonts.fontUtils.getCssUrls(documentType, serviceTopology)
    }

    /**
     * @param {ps} ps
     * returns [{lang: 'latin', fonts:[{fontMetaData}]}]
     * @returns {{lang:string, fonts: [{fontMetaData}]}}
     */
    function getSiteFontsOptions(ps) {
        const documentType = generalInfo.getDocumentType(ps)
        const characterSets = theme.fonts.getCharacterSet(ps)
        return fonts.fontUtils.getCurrentSelectablefontsWithParams(documentType, characterSets)
    }

    /**
     * @param {ps} ps
     * @returns {*}
     */
    function getAllFontsOptions(ps) {
        const POSSIBLE_CHARACTERS_SETS = ['latin-ext', 'cyrillic', 'japanese', 'korean', 'arabic', 'hebrew', 'latin']
        const documentType = generalInfo.getDocumentType(ps)
        return fonts.fontUtils.getCurrentSelectablefontsWithParams(documentType, POSSIBLE_CHARACTERS_SETS)
    }

    return {
        css: {
            getCssUrls
        },
        getSiteFontsOptions,
        getAllFontsOptions
    }
})
