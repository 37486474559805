define(['lodash', 'documentServices/constants/constants', 'documentServices/componentsMetaData/metaDataUtils'], function (_, constants, metaDataUtils) {
    'use strict'

    const {
        LAYOUT_TYPES: {COMPONENT_LAYOUT, CONTAINER_LAYOUT, ITEM_LAYOUT, SINGLE_LAYOUT, SINGLE_LAYOUT_KEYS}
    } = constants

    const fixedLayoutType = 'FixedItemLayout'

    const layoutTypesMap = {
        [COMPONENT_LAYOUT]: ['ComponentLayout'],
        [CONTAINER_LAYOUT]: ['FlexContainerLayout', 'GridContainerLayout', 'StackContainerLayout', 'OrganizerContainerLayout'],
        [ITEM_LAYOUT]: ['FlexItemLayout', 'GridItemLayout', 'StackItemLayout', 'OrganizerItemLayout', 'FixedItemLayout', 'AbsoluteItemLayout']
    }

    const ERRORS = {
        BREAKPOINT_EXIST_ON_ANOTHER_PAGE: 'Cant add breakpoint because its exist on another page already',
        PINNED_COMP_NOT_FIXED: 'Cannot update pinned component itemLayout to anything other then FixedItemLayout',
        NOT_PINNED_NOT_FIXED: 'Cannot pin component with itemLayout that is not FixedItemLayout, use update instead',
        NOT_VALID_LAYOUT_TYPE: 'Cant update not compatible layout type',
        NOT_SINGLE_LAYOUT_TYPE: 'Cant update non SingleLayout type',
        UNPIN_NOT_PINNED: 'Cannot unpin a component that is not pinned',
        PINNED_SHOULD_BE_FIXED: 'Given layout.itemLayout.type must not be FixedItemLayout',
        EMPTY_LAYOUT_OBJECT: 'Cannot update empty SingleLayout object',
        CANT_ADD_BREAKPOINTS_DATA: 'Cannot add breakpoints data for this component type'
    }

    const validateNewBreakpointsIds = (ps, breakpoints, pageId) => {
        _.forEach(breakpoints, breakpoint => {
            const breakpointPointer = ps.pointers.data.getBreakpointsDataItem(breakpoint.id)
            const breakpointPageId = ps.pointers.data.getPageIdOfData(breakpointPointer)
            if (breakpointPageId && breakpointPageId !== pageId) {
                throw new Error(`${ERRORS.BREAKPOINT_EXIST_ON_ANOTHER_PAGE}. id: ${breakpoint.id} source page: ${breakpointPageId} target page: ${pageId}`)
            }
        })
    }

    const isFixedItemLayout = layoutItem => _.get(layoutItem, 'type') === fixedLayoutType

    const validatedPinnedComponent = (currentLayout, newLayout) => {
        const isCurrentFixed = currentLayout && isFixedItemLayout(currentLayout.itemLayout)
        const isUpdateToFixed = isFixedItemLayout(newLayout.itemLayout)
        if (isCurrentFixed && !isUpdateToFixed) {
            throw new Error(ERRORS.PINNED_COMP_NOT_FIXED)
        }
        if (!isCurrentFixed && !isUpdateToFixed) {
            throw new Error(ERRORS.NOT_PINNED_NOT_FIXED)
        }
    }

    const validateUnPinnedComponent = (currentLayout, newLayout) => {
        if (!isFixedItemLayout(currentLayout.itemLayout)) {
            throw new Error(ERRORS.UNPIN_NOT_PINNED)
        }
        if (isFixedItemLayout(newLayout.itemLayout)) {
            throw new Error(ERRORS.PINNED_SHOULD_BE_FIXED)
        }
    }

    const isOfLayoutType = (layoutItem, layoutKey) => _.includes(layoutTypesMap[layoutKey], layoutItem.type)

    const validateSingleLayoutType = layoutItem => {
        if (_.has(layoutItem, 'type') && layoutItem.type !== SINGLE_LAYOUT) {
            throw new Error(`${ERRORS.NOT_SINGLE_LAYOUT_TYPE}. layoutType: ${layoutItem.type} }`)
        }
    }

    const validateInnerLayoutTypes = newLayout => {
        _.forEach(SINGLE_LAYOUT_KEYS, layoutKey => {
            const layoutItem = newLayout[layoutKey]
            if (_.has(layoutItem, 'type')) {
                const isValidLayoutType = isOfLayoutType(layoutItem, layoutKey)
                if (!isValidLayoutType) {
                    throw new Error(`${ERRORS.NOT_VALID_LAYOUT_TYPE}. layoutType: ${newLayout.type} in ${layoutKey}`)
                }
            }
        })
    }

    const validateNotEmptySingleLayout = newLayout => {
        const isEmptyObject = _(newLayout).pick(SINGLE_LAYOUT_KEYS).every(_.isEmpty)
        if (isEmptyObject) {
            throw new Error(`${ERRORS.EMPTY_LAYOUT_OBJECT}`)
        }
    }

    const validateSingleLayoutObject = newLayout => {
        validateSingleLayoutType(newLayout)
        validateInnerLayoutTypes(newLayout)
        validateNotEmptySingleLayout(newLayout)
    }

    const validateBreakpointsDataAdd = (ps, compPointer) => {
        const isPage = ps.pointers.structure.isPage(compPointer)
        const compType = metaDataUtils.getComponentType(ps, compPointer)
        const isAllowedToHaveBreakpoints = metaDataUtils.isAllowedToHaveBreakpoints(compType)

        if (!isPage && !isAllowedToHaveBreakpoints) {
            throw new Error(`${ERRORS.CANT_ADD_BREAKPOINTS_DATA}`)
        }
    }

    return {
        ERRORS,
        validateNewBreakpointsIds,
        validateUnPinnedComponent,
        validateSingleLayoutObject,
        validatedPinnedComponent,
        validateBreakpointsDataAdd
    }
})
