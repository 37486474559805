import $ from 'zepto'
import _ from 'lodash'
import coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import PropTypes from 'prop-types'
import biEvents from '../bi/events.json'
import santaComponents from '@wix/santa-components'

const {linkRenderer} = coreUtils
const {touchMediaZoomUtils} = coreUtils
const ITEM_OVERLAP = 0.2
const MOBILE_SAFARI_LEFT_SWIPE_MARGIN_IN_PIXELS = 29

/**
 * @class components.TouchMediaZoom
 * @extends {core.skinBasedComp}
 */
const touchMediaZoom = {
    displayName: 'TouchMediaZoom',
    mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
    propTypes: {
        browser: santaComponents.santaTypesDefinitions.Browser.browser.isRequired,
        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
        rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
        styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
        devicePixelRatio: santaComponents.santaTypesDefinitions.Device.devicePixelRatio.isRequired,
        isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
        screenSize: santaComponents.santaTypesDefinitions.screenSize.isRequired,
        linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
        isLandscape: santaComponents.santaTypesDefinitions.mobile.isLandscape.isRequired,
        isZoomed: santaComponents.santaTypesDefinitions.mobile.isZoomed.isRequired,
        isZoomedIn: santaComponents.santaTypesDefinitions.mobile.isZoomedIn.isRequired,
        navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
        isZoomAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isZoomAllowed.isRequired,
        siteId: santaComponents.santaTypesDefinitions.RendererModel.siteId.isRequired,
        reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
        staticMediaUrl: santaComponents.santaTypesDefinitions.ServiceTopology.staticMediaUrl.isRequired,
        windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent.isRequired,
        updateUrlIfNeeded: santaComponents.santaTypesDefinitions.Navigation.updateUrlIfNeeded.isRequired,
        currentZoomItem: santaComponents.santaTypesDefinitions.NonPageItemZoom.currentItem,
        unzoom: santaComponents.santaTypesDefinitions.NonPageItemZoom.unzoom,
        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
        enterMediaZoomMode: santaComponents.santaTypesDefinitions.enterMediaZoomMode.isRequired,
        exitMediaZoomMode: santaComponents.santaTypesDefinitions.exitMediaZoomMode.isRequired,
        isInSSR: santaComponents.santaTypesDefinitions.isInSSR.isRequired,

        // not santa types
        pageItemAdditionalData: PropTypes.object
    },

    getSwipeOffset() {
        return this.nonReactState.swipeOffset
    },

    setSwipeOffset(swipeOffset) {
        this.nonReactState.swipeOffset = swipeOffset
        const transformString = touchMediaZoomUtils.generateTransformString(swipeOffset, this.stageData.fullWidth)
        this.refs.swipeStage.style.transform = transformString
        this.refs.swipeStage.style.webkitTransform = transformString
    },

    getCurrentSlideIndex() {
        return this.nonReactState.currentSlideIndex
    },

    setCurrentSlideIndex(value) {
        this.nonReactState.currentSlideIndex = value
    },

    getIsZoomed() {
        return this.nonReactState.isZoomed
    },

    setIsZoomed(value) {
        this.nonReactState.isZoomed = value
    },

    getItems() {
        return _.get(this.props.pageItemAdditionalData, 'items', [this.props.compData])
    },

    enterZoomMode() {
        this.isZoomed = true
        this.refs.xButton.classList.add(this.classSet({'force-hide': true}))
        this.fireZoomInExpandModeEvent('pinch')
    },

    exitZoomMode() {
        this.isZoomed = false
        this.refs.xButton.classList.remove(this.classSet({'force-hide': true}))
    },

    onTouchMove(event) {
        if (event.touches.length === 1 && !this.isZoomed) {
            event.preventDefault()
            return
        }

        if (event.touches.length === 1 || this.isZoomed) {
            return
        }

        this.enterZoomMode()
    },

    onTouchEnd() {
        if (!this.isZoomed) {
            return
        }

        this._touchEndTimeoutHandle = window.setTimeout(
            function () {
                if (!this.props.isZoomedIn()) {
                    this.exitZoomMode()
                }
            }.bind(this),
            300
        )
    },

    fireEnterExpandModeEvent() {
        this.props.reportBI(biEvents.ENTER_EXPAND_MODE, {
            site_id: this.props.siteId
        })
    },

    fireZoomInExpandModeEvent(zoomType) {
        this.props.reportBI(biEvents.ZOOM_IN_EXPAND_MODE, {
            site_id: this.props.siteId,
            zoomType
        })
    },

    componentWillMount() {
        this.fireEnterExpandModeEvent()
        this.props.windowKeyboardEvent.registerToArrowLeftKey(this)
        this.props.windowKeyboardEvent.registerToArrowRightKey(this)
        Object.defineProperty(this, 'swipeOffset', {get: this.getSwipeOffset, set: this.setSwipeOffset})
        Object.defineProperty(this, 'currentSlideIndex', {get: this.getCurrentSlideIndex, set: this.setCurrentSlideIndex})
        Object.defineProperty(this, 'isZoomed', {get: this.getIsZoomed, set: this.setIsZoomed})
        this.stageData = touchMediaZoomUtils.createStageData(ITEM_OVERLAP)
        this.stagePercentFactor = 100 / this.stageData.fullWidth
        this.resetNonReactState()
        this.setState({
            showInfo: true,
            isZoomed: false
        })
        this.pageScroll = this.props.isInSSR ? 0 : window.scrollY
        this.props.enterMediaZoomMode()
    },

    componentDidMount() {
        this.nonReactState.firstRender = false
        this.setSwipeOffset(this.swipeOffset)
        this.refs.swipeStage.addEventListener('transitionend', this.onSwipeEnd)
        this.pageMarginTop = $('html').css('marginTop')
        $('html').css({marginTop: 0})
        if (!this.props.isMobileDevice) {
            return
        }

        const self = this
        requirejs(['hammer'], function (Hammer) {
            self.setupTouchHandlers(Hammer)
        })

        this.refs[''].addEventListener('touchend', this.onTouchEnd)
        this.refs[''].addEventListener('touchcancel', this.onTouchEnd)
        this.refs[''].addEventListener('touchmove', this.onTouchMove)
    },

    componentWillUnmount() {
        window.clearTimeout(this._touchEndTimeoutHandle)
        this.props.exitMediaZoomMode()
        $('html').css({marginTop: this.pageMarginTop})
        window.scrollTo(0, this.pageScroll)
    },

    setupTouchHandlers(Hammer) {
        this.hammertime = new Hammer.Manager(this.refs[''], {
            cssProps: {
                touchCallout: 'default'
            },
            touchAction: 'auto'
        })

        this.hammertime.add(new Hammer.Pan({event: 'pan', direction: Hammer.DIRECTION_HORIZONTAL}))
        this.hammertime.add(new Hammer.Pan({event: 'panstart', direction: Hammer.DIRECTION_HORIZONTAL}))
        this.hammertime.add(new Hammer.Pan({event: 'panend', direction: Hammer.DIRECTION_HORIZONTAL}))
        this.hammertime.on('panstart pan panend', this.onSwipe)
        this.hammertime.add(new Hammer.Tap({event: 'doubletap', taps: 2, threshold: 30, posThreshold: 30}))
        this.hammertime.on('doubletap', _.partial(this.fireZoomInExpandModeEvent, 'doubleTap'))
    },

    componentWillUpdate() {
        if (this.props.isMobileDevice) {
            this.setSwipeOffset(this.stageData.centerPart_begin)
        }
    },

    componentWillReceiveProps() {
        if (!this.props.isZoomAllowed) {
            setTimeout(this.closeMediaZoom, 0)
            return
        }
    },

    resetNonReactState(compDataOverride) {
        const compData = compDataOverride || this.props.compData
        const itemIndex = _.findIndex(this.getItems(), {id: compData.id})
        const zoomMode = this.props.isZoomed()
        this.nonReactState = {
            currentSlideIndex: itemIndex,
            swipeOffset: this.stageData.centerPart_begin,
            isZoomed: zoomMode,
            firstRender: true
        }
    },

    onSwipe: function onSwipe(ev) {
        if (this.isZoomed) {
            return
        }

        if (ev.center.x - ev.deltaX < MOBILE_SAFARI_LEFT_SWIPE_MARGIN_IN_PIXELS) {
            return
        }

        switch (ev.type) {
            case 'panstart':
                break

            case 'pan':
                this.swipeOffset = _.clamp(
                    this.stageData.centerPart_begin - ev.deltaX / window.innerWidth,
                    this.currentSlideIndex > 0 ? this.stageData.leftPart_leftMargin : this.stageData.centerPart_leftMargin,
                    this.currentSlideIndex < this.getItems().length - 1 ? this.stageData.rightPart_rightMargin : this.stageData.centerPart_rightMargin
                )
                break

            case 'panend':
                this.startTransition()
                break

            default:
                throw new Error(`unsupported event type: ${ev.type}`)
        }
    },

    calcTransitionTarget() {
        if (this.swipeOffset < this.stageData.centerPart_leftMargin) {
            return {
                offset: this.stageData.leftPart_begin,
                index: this.currentSlideIndex - 1
            }
        }
        if (this.swipeOffset > this.stageData.centerPart_rightMargin) {
            return {
                offset: this.stageData.rightPart_begin,
                index: this.currentSlideIndex + 1
            }
        }
        return {
            offset: this.stageData.centerPart_begin,
            index: this.currentSlideIndex
        }
    },

    startTransition(transitionTarget) {
        const target = transitionTarget || this.calcTransitionTarget()
        this.refs.swipeStage.classList.add(`${this.props.styleId}_animate`)
        this.swipeOffset = target.offset
        this.currentSlideIndex = target.index
    },

    onSwipeEnd: function onSwipeEnd() {
        this.refs.swipeStage.classList.remove(`${this.props.styleId}_animate`)
        this.swipeOffset = this.stageData.centerPart_begin
        this.navigateToSlide(this.currentSlideIndex)
        this.setState({})
    },

    navigateToSlide(index) {
        const itemId = this.getItems()[index].id
        const navigationInfo = _.clone(this.props.rootNavigationInfo)
        navigationInfo.pageItemId = itemId
        this.props.updateUrlIfNeeded(navigationInfo)
    },

    closeMediaZoom() {
        if (this.props.currentZoomItem) {
            this.props.unzoom()
        } else {
            const navInfo = _.omit(this.props.rootNavigationInfo, ['imageZoom', 'pageItemId', 'title'])
            this.props.navigateToPage(navInfo)
        }
    },

    onXButton(event) {
        this.closeMediaZoom()
        event.preventDefault()
    },

    onArrowLeftKey() {
        if (this.currentSlideIndex > 0) {
            this.startTransition({
                offset: this.stageData.leftPart_begin,
                index: this.currentSlideIndex - 1
            })
        }
    },

    onArrowRightKey() {
        if (this.currentSlideIndex < this.getItems().length - 1) {
            this.startTransition({
                offset: this.stageData.rightPart_begin,
                index: this.currentSlideIndex + 1
            })
        }
    },

    toggleInfoPanel(event) {
        if (event.target === this.refs.stage_CENTER.refs.link) {
            return
        }

        this.setState({showInfo: !this.isManipulated() && !this.state.showInfo})
    },

    getGotoLink(imageData) {
        const linkData = imageData.link
        if (!linkData) {
            return
        }

        const link = linkRenderer.renderLink(linkData, this.props.linkRenderInfo, this.props.rootNavigationInfo)
        link.children = _.get(this.props.compProp, 'goToLinkText', 'Go to link')
        return link
    },

    getStageParts() {
        return _.filter(
            [
                {id: 'LEFT', imageIndex: this.currentSlideIndex - 1, offset: this.stageData.leftPart_leftMargin},
                {id: 'CENTER', imageIndex: this.currentSlideIndex, offset: this.stageData.centerPart_leftMargin},
                {id: 'RIGHT', imageIndex: this.currentSlideIndex + 1, offset: this.stageData.rightPart_leftMargin}
            ],
            function (obj) {
                return obj.imageIndex >= 0 && obj.imageIndex < this.getItems().length
            }.bind(this)
        )
    },

    isManipulated() {
        const isLandscape = this.props.isMobileDevice && this.props.isLandscape()
        return isLandscape || this.state.isZoomed
    },

    buildStageChildren() {
        const stageChildren = []

        _.forEach(
            this.getStageParts(),
            function (part) {
                const itemLeft = part.offset * this.stagePercentFactor
                const partId = `stage_${part.id}`
                const imageData = this.getItems()[part.imageIndex]
                const clientScreenSize = this.nonReactState.firstRender ? null : this.props.screenSize
                const clientData = {
                    pixelAspectRatio: this.props.devicePixelRatio,
                    browser: this.props.browser,
                    staticMediaUrl: this.props.staticMediaUrl,
                    screenSize: clientScreenSize
                }
                const parallaxGroup = this.createChildComponent(imageData, 'wysiwyg.viewer.components.TouchMediaZoomItem', 'image', {
                    key: `${this.props.id}_item${part.imageIndex}`,
                    id: partId,
                    ref: partId,
                    imageData,
                    clientData,
                    link: this.getGotoLink(imageData),
                    screenSize: clientScreenSize,
                    itemToScreenRatio: 1 + 2 * ITEM_OVERLAP,
                    showInfo: this.state.showInfo && !this.isManipulated(),
                    itemLeft,
                    firstRender: this.nonReactState.firstRender
                })

                stageChildren.push(parallaxGroup)
            }.bind(this)
        )

        return stageChildren
    },

    getSkinProperties() {
        return {
            '': {
                onClick: this.toggleInfoPanel
            },
            swipeStage: {
                style: {
                    width: `${100 * this.stageData.fullWidth}%`
                },
                children: this.buildStageChildren()
            },
            xButton: {
                onClick: this.onXButton,
                className: this.classSet({hidden: this.isManipulated()})
            }
        }
    }
}

componentsCore.compRegistrar.register('wysiwyg.viewer.components.TouchMediaZoom', touchMediaZoom)

export default touchMediaZoom
