import * as _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import * as virtualGroupHandler from '../virtualGroupHandler'
import * as structurePreprocessor from '../structurePreprocessor'
import * as structurePostprocessor from '../structurePostprocessor'
import * as mergeUtils from './mergeUtils'
import * as structureAnalyzer from '../analyzer/structureAnalyzer'
import * as structureConverter from '../structureConverter/structureConverter'
import {objectUtils} from '@wix/santa-core-utils'
import {isMobileOnly, isMobileOnlyFixedComponentId} from '../mobileOnlyComponents/mobileOnlyComponentsUtils'
import * as blocksUtils from '../utils/blocksUtils'
import * as conversionDataUtils from '../utils/conversionDataUtils'
import {DEAD_COMP_TYPE} from '../constants'
import {
    ClusteredComponentsToAdd,
    Component,
    ComponentWithConversionData,
    ConversionSettings,
    MasterPageComponent,
    MasterPageComponentWithConversionData,
    ObjMap,
    PageComponent,
    StructureWithConversionData
} from '../../types'

/**
 * @description Adjusts the height of containers that should always have the same height as their direct children
 */
export const adjustTightlyWrappingContainers = (mobileParentComponent: ComponentWithConversionData, desktopParentComponent: ComponentWithConversionData) => {
    const componentTypes = ['wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.StripContainerSlideShow', 'platform.components.AppWidget']
    _.forEach(conversionUtils.getChildren(mobileParentComponent), (mobileComp: StructureWithConversionData) => {
        if (_.includes(componentTypes, mobileComp.componentType)) {
            if (!mobileComp.components || mobileComp.components.length === 0) {
                return
            }

            const desktopComp = <ComponentWithConversionData>conversionUtils.getComponentByIdFromStructure(mobileComp.id, desktopParentComponent)
            const childWithMaxHeight = _.maxBy(mobileComp.components, 'layout.height')
            //@ts-expect-error
            const maxDirectChildHeight = childWithMaxHeight.layout.height

            _.forEach(mobileComp.components, child =>
                _.assign(child.layout, {
                    x: 0,
                    y: 0,
                    height: maxDirectChildHeight
                })
            )
            mobileComp.layout.height = maxDirectChildHeight

            if (!isMobileOnly(mobileComp) && desktopComp !== null) {
                mobileComp.components = _.sortBy(mobileComp.components, child => _.findIndex(desktopComp.components, {id: child.id}))
            }
        }
        adjustTightlyWrappingContainers(mobileComp as ComponentWithConversionData, desktopParentComponent as ComponentWithConversionData)
    })
}

function flattenComponentsToInsertFromVirtualGroups(componentsToInsertMap: ClusteredComponentsToAdd[], componentIdsAddedToWebStructure: string[]): void {
    _.forEach(componentsToInsertMap, comp => {
        const firstCompToAdd = <Component>_.head(comp.componentToAdd)
        if (conversionUtils.isRescaleVirtualGroup(firstCompToAdd)) {
            conversionUtils.translateComps(firstCompToAdd.components, firstCompToAdd.layout.x, firstCompToAdd.layout.y)
            //@ts-expect-error
            comp.componentToAdd = firstCompToAdd.components
        }
        virtualGroupHandler.flattenGroups(firstCompToAdd, firstCompToAdd.components)
    })

    // if virtual groups consisted present components -> remove them
    _.forEach(componentsToInsertMap, comp => {
        _.remove(comp.componentToAdd, (compToAdd: Component) => !_.includes(componentIdsAddedToWebStructure, compToAdd.id))
    })
}

function createMergeVirtualGroup(groupComps: ComponentWithConversionData[]): ComponentWithConversionData {
    const {tightWithPreviousSibling} = groupComps[0].conversionData

    const virtualGroupData = {
        layout: conversionUtils.getSnugLayout(groupComps),
        conversionData: {
            rescaleMethod: 'default',
            hasTightYMargin: true,
            isTightContainer: true,
            tightWithPreviousSibling
        },
        componentType: conversionConfig.VIRTUAL_GROUP_TYPES.MERGE,
        components: groupComps
    }
    const virtualGroup = virtualGroupHandler.createVirtualComponent(virtualGroupData)
    conversionUtils.translateComps(groupComps, -virtualGroupData.layout.x, -virtualGroupData.layout.y)
    return virtualGroup
}

function getCompsIds(comp: Component | MasterPageComponent, res: string[] = []): string[] {
    res.push(comp.id)
    _.forEach(conversionUtils.getChildren(comp), child => {
        res = res.concat(getCompsIds(child))
    })
    return res
}

function areDirectParentAndChild(component: ComponentWithConversionData, container: ComponentWithConversionData | MasterPageComponent): boolean {
    return _.some(conversionUtils.getChildren(container), {id: component.id})
}

function insertAddedComponentBetweenBlocks(
    container: ComponentWithConversionData | MasterPageComponentWithConversionData,
    componentToAdd: ComponentWithConversionData,
    previousComponentId: string,
    settings
): void {
    blocksUtils.addMissedBlocksDataIfNeeded(container)

    const components = conversionUtils.getChildren(container) || []

    if (!components) {
        container.components = components
    }

    const blockIndexOfPreviousComponent = blocksUtils.findBlockNumberOfComponent(previousComponentId, container)
    const componentToAddClone: ComponentWithConversionData = objectUtils.cloneDeep(componentToAdd)

    componentToAddClone.layout.y = blocksUtils.calculateYFromBlockLayout(
        blockIndexOfPreviousComponent,
        container as ComponentWithConversionData,
        componentToAdd as ComponentWithConversionData
    )

    components.push(componentToAddClone)

    const blockIndexOfCurrentComponent = blockIndexOfPreviousComponent + 1
    const containerBlocksLayout = blocksUtils.getBlocksLayoutFromConversionData(container)

    const nextBlockTopY: number | undefined = blocksUtils.getBlocksLayoutData(containerBlocksLayout[blockIndexOfCurrentComponent], 'y')

    if (_.isNumber(nextBlockTopY)) {
        const children = conversionUtils.getChildren(container)
        const nextBlockComponent = children[blockIndexOfCurrentComponent]
        if (!settings.skipLayoutOverwrite) {
            const nextBlockMargin = blocksUtils.getYGapsBetweenBlocks(nextBlockComponent) - <number>nextBlockTopY

            const shiftValue = componentToAddClone.layout.y + componentToAddClone.layout.height + nextBlockMargin

            shiftComponentsLowerThanBlock(container, blockIndexOfPreviousComponent, shiftValue)
        }
    }

    blocksUtils.insertBlockBetweenExisting(container, blockIndexOfPreviousComponent + 1, componentToAddClone)
}

function ensureContainerWrapsChildren(container: StructureWithConversionData): void {
    const components = <ComponentWithConversionData[]>conversionUtils.getChildren(container)

    if (conversionUtils.isMasterPage(container) || _.isEmpty(components)) {
        return
    }

    const lowestChildBottom = _.reduce(components, (bottom, comp) => Math.max(bottom, comp.layout.y + comp.layout.height), 0)
    const shouldBeTightAccordingToParent = _.get(container, ['conversionData', 'hasTightYMargin'], false)

    const bottomMargin = !conversionUtils.isPageComponent(container) && !shouldBeTightAccordingToParent ? conversionConfig.COMPONENT_MOBILE_MARGIN_Y : 0
    container.layout.height = Math.max(container.layout.height || 0, lowestChildBottom + bottomMargin)
}

function shiftComponentsLowerThanBlock(
    container: ComponentWithConversionData | MasterPageComponentWithConversionData,
    blockNumber: number,
    shiftValue: number
): void {
    const containerChildren = conversionUtils.getChildren(container)
    const blocksLength = _.get(container, ['conversionData', 'blockIds', 'length'], 0)
    let j
    let i
    let curBlock
    let curComponent

    for (i = blockNumber + 1; i < blocksLength; i++) {
        curBlock = container.conversionData.blockIds[i]

        for (j = 0; j < curBlock.length; j++) {
            curComponent = _.find(containerChildren, {id: curBlock[j]})
            curComponent.layout.y += shiftValue
        }
        const curBlocksLayout = blocksUtils.getBlocksLayoutFromConversionData(container)
        const curBlockY = blocksUtils.getBlocksLayoutData(curBlocksLayout[i], 'y')

        blocksUtils.setBlockLayoutData(curBlocksLayout[i], 'y', curBlockY + shiftValue)
    }

    if (blockNumber > -1) {
        curBlock = _.get(container, ['conversionData', 'blockIds', blockNumber])

        for (j = 0; j < curBlock.length; j++) {
            curComponent = _.find(containerChildren, {id: curBlock[j]})
        }
    }
}

export function mergeStructure(
    desktopStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobileStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    settings: ConversionSettings
) {
    if (conversionUtils.isMasterPage(desktopStructure)) {
        const desktopComps = conversionUtils.getChildren(desktopStructure)
        structurePreprocessor.handleMasterPageComponents(desktopComps)
        if (settings.reparentMobileSOAP) {
            structurePreprocessor.handleMobileMasterPageIlligalSiblings(desktopComps, conversionUtils.getChildren(mobileStructure))
        }
    }

    const MOBILE = true
    const desktopComponents = <ObjMap<ComponentWithConversionData>>(
        conversionUtils.getAllCompsInStructure(desktopStructure, !MOBILE, _.negate(conversionUtils.isDesktopOnlyComponent))
    )
    const mobileComponents = conversionUtils.getAllCompsInStructure(mobileStructure)

    syncConversionDataWithDesktop(desktopStructure, mobileStructure)
    updateComponentsStyles(desktopStructure, mobileStructure)
    mergeUtils.setParentToComponentConversionData(desktopStructure as ComponentWithConversionData)
    mergeUtils.setParentToComponentConversionData(mobileStructure as ComponentWithConversionData)

    mergeUtils.preprocessExistingComponentsBeforeMerge(mobileStructure as ComponentWithConversionData, desktopComponents, settings)

    const componentsDiff = mergeUtils.getComponentsDiff(desktopComponents, mobileComponents, settings)
    const shouldUpdateStructure = componentsDiff.idsOfComponentsToDelete.length > 0 || componentsDiff.idsOfComponentsToAdd.length > 0

    if (shouldUpdateStructure) {
        const addList = settings.keepNotRecommendedMobileComponents
            ? componentsDiff.idsOfComponentsToAdd
            : rejectFixedPositionCompsNonConvertibleToMobile(componentsDiff.idsOfComponentsToAdd, desktopComponents)

        mergeUtils.preprocessReparentedComponentsBeforeMerge(
            desktopComponents as ObjMap<ComponentWithConversionData>,
            mobileComponents as ObjMap<ComponentWithConversionData>
        )

        if (settings.enableNewMergeFlow) {
            mergeUtils.moveMOCtoDesktopStructure(
                desktopComponents as ObjMap<ComponentWithConversionData>,
                mobileComponents as ObjMap<ComponentWithConversionData>,
                desktopStructure.id
            )
        }

        addAndDeleteComponentsFromMobileStructure(
            mobileStructure,
            componentsDiff.idsOfComponentsToDelete,
            settings,
            addList,
            desktopStructure,
            desktopComponents
        )

        adjustTightlyWrappingContainers(mobileStructure as ComponentWithConversionData, desktopStructure as ComponentWithConversionData)

        if (settings.skipLayoutOverwrite) {
            mergeUtils.removeDuplicatesAfterMigration(mobileStructure as StructureWithConversionData)
        }
    }
}

function rejectFixedPositionCompsNonConvertibleToMobile(compIdList: string[], desktopComponents) {
    const nonConvertibleFixedPositionComponents = _.reduce(
        desktopComponents,
        (res, component: ComponentWithConversionData | MasterPageComponent) => {
            const isFixedPositionElements = conversionUtils.isFixedPositionElement(component)
            const shouldConvertFixedPositionToAbsolute = conversionUtils.shouldConvertFixedPositionToAbsolute(component)
            const isNonConvertibleFixedPositionComp = isFixedPositionElements && !shouldConvertFixedPositionToAbsolute
            const isFixedPositionNotAllowed = !conversionUtils.isFixedPositionAllowed(component)
            return isNonConvertibleFixedPositionComp && isFixedPositionNotAllowed
                ? _.concat(res, _.keys(conversionUtils.getAllCompsInStructure(component)))
                : res
        },
        []
    )
    return _.difference(compIdList, nonConvertibleFixedPositionComponents)
}

function addAndDeleteComponentsFromMobileStructure(
    mobileSiteStructure: StructureWithConversionData,
    componentIdsDeletedFromDesktopStructure: string[],
    settings: ConversionSettings,
    addList: string[],
    desktopStructure: StructureWithConversionData,
    desktopComponents: ObjMap<StructureWithConversionData>
) {
    if (settings.enableNewMergeFlow) {
        removeModifiedFlagFromDuplicates(desktopStructure)
    }

    identifyBlocks(mobileSiteStructure, settings.enableNewMergeFlow)

    deleteComponentsFromMobileStructure(mobileSiteStructure, componentIdsDeletedFromDesktopStructure, addList)

    addComponentsToMobileStructure(mobileSiteStructure, desktopStructure, addList, settings, desktopComponents)

    if (settings.enableImprovedMergeFlow) {
        mergeUtils.insureOrdersByY(mobileSiteStructure)
        mergeUtils.insureOverlapedRootContainers(mobileSiteStructure, settings.enableImprovedMergeFlow)
    }

    mergeUtils.insureTightlyWrapsForContainersChildren(mobileSiteStructure)
}

const removeModifiedFlagFromDuplicates = (desktopStructure: StructureWithConversionData) => {
    const allDesktopComponents = conversionUtils.getAllCompsInStructure(desktopStructure)
    _.forOwn(allDesktopComponents, comp => {
        if (conversionUtils.isModifiedComponent(comp) && !conversionUtils.isExistsBeforeMerge(comp)) {
            /**
             * Remove temporarary just for one merge run, it's not save to structure in DM
             */
            delete comp.conversionData.mobileHints.modifiedByUser
            // To force calculate layout form recommended sizes
            comp.conversionData.mobileHints.author = 'studio'
        }
    })
}

function syncConversionDataWithDesktop(
    desktopStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobileComponent: ComponentWithConversionData | MasterPageComponentWithConversionData
) {
    const desktopComponent = <ComponentWithConversionData>conversionUtils.getComponentByIdFromStructure(mobileComponent.id, desktopStructure)

    // it can be mobile only component
    if (!desktopComponent) {
        return
    }

    const desktopConversationData = desktopComponent.conversionData
    if (desktopConversationData) {
        mobileComponent.conversionData = mobileComponent.conversionData || {}

        mobileComponent.conversionData.hasTightYMargin = desktopConversationData.isTightContainer === true

        mobileComponent.conversionData.hasTightMarginBetweenChildren = desktopConversationData.hasTightMarginBetweenChildren === true
        mobileComponent.conversionData.mobileHints = {author: desktopConversationData.mobileHints?.author}
    }

    _.forEach(conversionUtils.getChildren(mobileComponent), mobileChildComp => syncConversionDataWithDesktop(desktopStructure, mobileChildComp))
}

function addComponentsToMobileStructure(
    mobileSiteStructure,
    desktopStructure,
    componentIdsAddedToWebStructure,
    settings: ConversionSettings,
    desktopComponents: ObjMap<StructureWithConversionData>
) {
    const desktopStructureClone = objectUtils.cloneDeep(desktopStructure)

    structurePreprocessor.preProcessStructure(
        desktopStructureClone,
        conversionUtils.getChildren(desktopStructureClone) as ComponentWithConversionData[],
        settings
    )

    const addedGroupIds = addGroupsOfPartlyExistingComponentsInMobile(componentIdsAddedToWebStructure, desktopStructureClone, mobileSiteStructure)

    if (!_.isEmpty(addedGroupIds)) {
        identifyBlocks(mobileSiteStructure, settings.enableNewMergeFlow)
    }

    const componentForestToBeAdded = convertAddedComponentIdsListToComponentForest(componentIdsAddedToWebStructure, desktopStructureClone)

    structureAnalyzer.analyzeStructure(desktopStructureClone, settings)

    if (_.isEmpty(componentIdsAddedToWebStructure)) {
        return
    }

    const componentsToInsertMap = mapComponentsToAddToTheirFutureContainerAndPreceder(
        desktopStructureClone,
        desktopStructureClone,
        mobileSiteStructure,
        componentForestToBeAdded,
        componentIdsAddedToWebStructure,
        settings
    )

    flattenComponentsToInsertFromVirtualGroups(componentsToInsertMap, componentIdsAddedToWebStructure)

    const componentsToInsertClusteredMap = clusterComponentsToInsertMap(componentsToInsertMap)

    deleteExistingComponentsWhichWillBeAddedWithTheirNewContainer(
        mobileSiteStructure,
        componentsToInsertClusteredMap,
        componentIdsAddedToWebStructure,
        settings
    )
    insertComponentsToMobileStructure(componentsToInsertClusteredMap, desktopStructureClone, settings, desktopComponents)
}

function addGroupsOfPartlyExistingComponentsInMobile(
    componentIdsAddedToWebStructure: string[],
    desktopStructureClone: PageComponent | MasterPageComponent,
    mobileSiteStructure: PageComponent | MasterPageComponent
) {
    const addedGroupIds = []
    let i
    let j
    for (i = componentIdsAddedToWebStructure.length - 1; i >= 0; i--) {
        const curCompIdToBeAdded = componentIdsAddedToWebStructure[i]
        const curCompToBeAdded = <Component>conversionUtils.getComponentByIdFromStructure(curCompIdToBeAdded, desktopStructureClone)
        if (conversionUtils.isGroupComponent(curCompToBeAdded)) {
            const allComps: Component[][] = _.partition(curCompToBeAdded.components, groupedComp => _.includes(componentIdsAddedToWebStructure, groupedComp.id))
            const componentsOfGroupThatAlreadyExistInMobile = <Component[]>_(allComps[1])
                .map(groupedComp => conversionUtils.getComponentByIdFromStructure(groupedComp.id, mobileSiteStructure))
                .compact()
                .value()

            let allMobileCompsHaveSameParent = true
            for (j = 1; j < componentsOfGroupThatAlreadyExistInMobile.length; j++) {
                const parent1 = conversionUtils.getParent(componentsOfGroupThatAlreadyExistInMobile[j - 1].id, mobileSiteStructure)
                const parent2 = conversionUtils.getParent(componentsOfGroupThatAlreadyExistInMobile[j].id, mobileSiteStructure)
                if (parent1.id !== parent2.id) {
                    allMobileCompsHaveSameParent = false
                }
            }

            // caseA: some of grouped Components exist in mobile, but got different parents -> don't add group to mobile
            if (!allMobileCompsHaveSameParent) {
                componentIdsAddedToWebStructure.splice(i, 1)
                continue
            }

            // caseC: no grouped comp exists in mobile - add them all as a whole:
            if (componentsOfGroupThatAlreadyExistInMobile.length === 0) {
                continue
            }

            // caseD: some of the grouped comps already in mobile, and at the end there will be more than 2 --> add the group now.
            const clonedGroupComp = <Component>_.clone(curCompToBeAdded)
            clonedGroupComp.components = []
            clonedGroupComp.layout = conversionUtils.getSnugLayout(componentsOfGroupThatAlreadyExistInMobile)
            const groupMobileParent = conversionUtils.getParent(componentsOfGroupThatAlreadyExistInMobile[0].id, mobileSiteStructure)
            conversionUtils.addComponentsTo(groupMobileParent, [clonedGroupComp])

            for (j = 0; j < componentsOfGroupThatAlreadyExistInMobile.length; j++) {
                const mobileComponent = componentsOfGroupThatAlreadyExistInMobile[j]
                conversionUtils.reparentComponent(clonedGroupComp, mobileComponent)
                _.remove(conversionUtils.getChildren(groupMobileParent), mobileComponent)
            }

            addedGroupIds.push(componentIdsAddedToWebStructure[i])
            componentIdsAddedToWebStructure.splice(i, 1)
        }
    }

    return addedGroupIds
}

function deleteExistingComponentsWhichWillBeAddedWithTheirNewContainer(
    mobileSiteStructure: ComponentWithConversionData,
    componentsToInsertClusteredMap,
    componentIdsAddedToWebStructure,
    settings: ConversionSettings
) {
    if (settings.skipLayoutOverwrite) {
        return
    }
    const existingComponentIdsThatWereAttachedToNewContainer = getExistingComponentIdsThatWereAttachedToNewContainer(
        componentsToInsertClusteredMap,
        componentIdsAddedToWebStructure
    )

    deleteComponentsFromMobileStructure(mobileSiteStructure, existingComponentIdsThatWereAttachedToNewContainer)
}

function getExistingComponentIdsThatWereAttachedToNewContainer(
    componentsToInsertClusteredMap: {
        componentToAdd: ComponentWithConversionData[]
    }[],
    componentIdsAddedToWebStructure: string[]
) {
    let res = []
    componentsToInsertClusteredMap.forEach(cluster => {
        const ret = cluster.componentToAdd.reduce((acc, comp) => {
            const componentsToAddAlreadyExistInMobile = _.difference(getCompsIds(comp), componentIdsAddedToWebStructure)
            const allDesktopChildren = conversionUtils.getAllCompsInStructure(comp)

            componentsToAddAlreadyExistInMobile.forEach(id => {
                const desktopComp = allDesktopChildren[id]
                if (conversionUtils.isModifiedComponent(desktopComp)) {
                    desktopComp.conversionData.mobileHints.modifiedByUser = false
                }
            })
            return [...acc, ...componentsToAddAlreadyExistInMobile]
        }, [])
        res = [...res, ...ret]
    })

    return res
}

function getLowestChild(container: Component | MasterPageComponent): Component {
    const children = conversionUtils.getChildren(container)
    let i
    let lowestChild
    let lowestChildBottom
    let curChild

    if (children) {
        lowestChildBottom = -Number.MAX_VALUE

        for (i = 0; i < children.length; i++) {
            curChild = children[i]

            if (curChild.layout.y + curChild.layout.height > lowestChildBottom) {
                lowestChild = curChild
                lowestChildBottom = curChild.layout.y + curChild.layout.height
            }
        }

        return lowestChild
    }
}
/**
 *
 * @param componentsToInsertClustered
 * @param desktopStructure skipped param POSSIBLE SHOULD BE REMOVED
 * @param settings
 */
function insertComponentsToMobileStructure(
    componentsToInsertClustered: ClusteredComponentsToAdd[],
    desktopStructure: StructureWithConversionData,
    settings: ConversionSettings,
    comps
) {
    const allDesktopComponents = comps
    const reorganizeChildrenSettings = {
        imageScaleFactor: 1,
        applyFontScaling: settings.applyFontScaling,
        enableNewMergeFlow: settings.enableNewMergeFlow,
        skipLayoutOverwrite: settings.skipLayoutOverwrite,
        enableImprovedMergeFlow: settings.enableImprovedMergeFlow
    }

    for (let i = 0; i < componentsToInsertClustered.length; i++) {
        const componentsToAdd = componentsToInsertClustered[i].componentToAdd
        const {futureMobileContainerOfAddedComponent} = componentsToInsertClustered[i]

        const {previousComponentIdThatAlsoExistInMobile} = componentsToInsertClustered[i]

        const virtualGroup = createMergeVirtualGroup(componentsToAdd)

        structurePreprocessor.preProcessStructure(virtualGroup, virtualGroup.components as ComponentWithConversionData[], settings)
        structureAnalyzer.analyzeStructure(virtualGroup, settings)

        virtualGroup.layout.x = 0
        virtualGroup.layout.width =
            conversionUtils.isPageComponent(futureMobileContainerOfAddedComponent) ||
            conversionUtils.isScreenWidthComponent(futureMobileContainerOfAddedComponent) ||
            conversionUtils.isMasterPage(futureMobileContainerOfAddedComponent)
                ? conversionConfig.MOBILE_WIDTH
                : futureMobileContainerOfAddedComponent.layout.width

        structureConverter.reorganizeChildren(
            virtualGroup,
            futureMobileContainerOfAddedComponent,
            virtualGroup.layout.width,
            reorganizeChildrenSettings,
            allDesktopComponents,
            desktopStructure as ComponentWithConversionData
        )

        structureConverter.rescaleComponentHeight(virtualGroup, {scaleFactor: 1})

        insertAddedComponentBetweenBlocks(futureMobileContainerOfAddedComponent, virtualGroup, previousComponentIdThatAlsoExistInMobile, settings)
        ensureContainerWrapsChildren(futureMobileContainerOfAddedComponent)

        structurePostprocessor.postProcessStructure(futureMobileContainerOfAddedComponent, conversionUtils.getChildren(futureMobileContainerOfAddedComponent))

        identifyBlocks(futureMobileContainerOfAddedComponent, settings.enableNewMergeFlow)
    }
}

export const deleteComponentsFromMobileStructure = (
    component: StructureWithConversionData,
    componentIdsDeletedFromWebStructure: string[],
    componentIdsAddedToWebStructure: string[] = []
) => {
    const children = conversionUtils.getChildren(component) as ComponentWithConversionData[]

    if (children) {
        removeGroupChildren(componentIdsDeletedFromWebStructure, component, children)
        const childrenIds = _.map(children, 'id')
        const componentIdsToDeleteFromCurrentComponent = _.intersection(componentIdsDeletedFromWebStructure, childrenIds)
        const componentsToDeleteFromCurrentComponent: ComponentWithConversionData[] = conversionUtils.getComponentsByIds(
            component,
            componentIdsToDeleteFromCurrentComponent
        ) as ComponentWithConversionData[]

        mergeUtils.addChildrenOfDeletedContainersToAddList(
            componentsToDeleteFromCurrentComponent,
            componentIdsAddedToWebStructure,
            componentIdsDeletedFromWebStructure
        )

        if (componentsToDeleteFromCurrentComponent.length > 0) {
            const willLowestChildBeDeleted =
                componentsToDeleteFromCurrentComponent
                    .map(comp => {
                        return comp.id
                    })
                    .indexOf(getLowestChild(component).id) > -1

            conversionUtils.removeChildrenFrom(component, componentsToDeleteFromCurrentComponent)

            updateBlocksAndModifyLayoutIfNeeded(component, componentIdsToDeleteFromCurrentComponent)

            if (willLowestChildBeDeleted) {
                const minHeight = 50
                conversionUtils.ensureContainerTightlyWrapsChildren(component, children, true, minHeight)
            }
        }

        _.forEach(children, child => {
            deleteComponentsFromMobileStructure(child, componentIdsDeletedFromWebStructure, componentIdsAddedToWebStructure)
        })
    }
}

const removeGroupChildren = (componentIdsDeletedFromWebStructure: string[], parent: StructureWithConversionData, children: ComponentWithConversionData[]) => {
    const childrenIds = _.map(children, 'id')
    _.forEachRight(componentIdsDeletedFromWebStructure, curCompIdToBeDeleted => {
        if (_.includes(childrenIds, curCompIdToBeDeleted)) {
            return
        }

        const curCompToBeDeleted = conversionUtils.getComponentsByIds(parent, [curCompIdToBeDeleted])[0]
        if (!conversionUtils.isGroupComponent(curCompToBeDeleted)) {
            return
        }

        conversionUtils.removeGroup(curCompToBeDeleted, <Component>parent)
        identifyBlocks(parent)
    })
}

/////////////////////////////////////////////////////////////
// calculating add list
/////////////////////////////////////////////////////////////

function clusterComponentsToInsertMap(componentsToInsert) {
    let j
    const componentsToInsertClustered = []
    let wasClustered
    let isSimilarCluster

    _.forEach(componentsToInsert, comp => {
        wasClustered = false

        for (j = 0; j < componentsToInsertClustered.length; j++) {
            isSimilarCluster =
                comp.futureMobileContainerOfAddedComponent === componentsToInsertClustered[j].futureMobileContainerOfAddedComponent &&
                comp.previousComponentIdThatAlsoExistInMobile === componentsToInsertClustered[j].previousComponentIdThatAlsoExistInMobile

            if (!isSimilarCluster) {
                continue
            }

            componentsToInsertClustered[j].componentToAdd = [...componentsToInsertClustered[j].componentToAdd, ...comp.componentToAdd]
            wasClustered = true
            break
        }

        if (!wasClustered) {
            componentsToInsertClustered.push(comp)
        }
    })

    return componentsToInsertClustered
}
/**
 *
 * @param currentComponentRoot
 * @param desktopStructure
 * @param mobileSiteStructure
 * @param componentForestToBeAdded
 */
const mapComponentsToAddToTheirFutureContainerAndPreceder = (
    currentComponentRoot: StructureWithConversionData,
    desktopStructure: StructureWithConversionData,
    mobileSiteStructure: StructureWithConversionData,
    componentForestToBeAdded: ComponentWithConversionData[],
    componentIdsWillBeConvertedToMobile: string[],
    settings: ConversionSettings
): ClusteredComponentsToAdd[] => {
    const currentComponentRootChildren = conversionUtils.getChildren(currentComponentRoot)
    let componentsToInsert = []

    if (currentComponentRootChildren) {
        const componentForestIds = componentForestToBeAdded.map(comp => comp.id)

        for (let i = 0; i < currentComponentRootChildren.length; i++) {
            const curChild = currentComponentRootChildren[i]
            const curChildId = curChild.id
            let isCurComponentInAddList = false
            let idInForestToBeAdded

            if (componentForestIds.indexOf(curChildId) >= 0) {
                isCurComponentInAddList = true
                idInForestToBeAdded = -1

                for (let j = 0; j < componentForestToBeAdded.length; j++) {
                    if (componentForestToBeAdded[j].id === curChildId) {
                        idInForestToBeAdded = j
                        break
                    }
                }
                componentForestToBeAdded.splice(idInForestToBeAdded, 1)
            }

            if (conversionUtils.isVirtualGroup(curChild)) {
                for (let j = 0; j < curChild.components.length; j++) {
                    if (componentForestIds.indexOf(curChild.components[j].id) > -1) {
                        isCurComponentInAddList = true
                        idInForestToBeAdded = -1

                        for (let k = 0; k < componentForestToBeAdded.length; k++) {
                            if (componentForestToBeAdded[k].id === curChild.components[j].id) {
                                idInForestToBeAdded = k
                                break
                            }
                        }

                        componentForestToBeAdded.splice(idInForestToBeAdded, 1)
                    }
                }
            }

            if (isCurComponentInAddList) {
                const componentToAdd = curChild
                const previousComponentIdThatAlsoExistInMobile = getPreviousComponentIdThatAlsoExistInMobile(
                    currentComponentRoot,
                    <Component>desktopStructure,
                    curChildId,
                    <Component>mobileSiteStructure
                )

                const futureMobileContainerOfAddedComponent = previousComponentIdThatAlsoExistInMobile
                    ? conversionUtils.getParent(previousComponentIdThatAlsoExistInMobile, mobileSiteStructure)
                    : getAncestorContainerExistingInMobile(curChild, desktopStructure, mobileSiteStructure, settings)

                componentsToInsert.push({
                    componentToAdd: [componentToAdd],
                    futureMobileContainerOfAddedComponent,
                    previousComponentIdThatAlsoExistInMobile
                })
            }
        }

        for (let i = 0; i < currentComponentRootChildren.length; i++) {
            const childrenComponentsToInsert = mapComponentsToAddToTheirFutureContainerAndPreceder(
                <ComponentWithConversionData>currentComponentRootChildren[i],
                desktopStructure,
                mobileSiteStructure,
                componentForestToBeAdded,
                componentIdsWillBeConvertedToMobile,
                settings
            )
            componentsToInsert = [...componentsToInsert, ...childrenComponentsToInsert]
        }
    }

    return componentsToInsert
}

// used once
/**
 *
 * @param {string[]} componentIdsAddedToWebStructure
 * @param {ComponentWithConversionData} desktopStructure
 * @returns {ComponentWithConversionData[]} component structure with reparented child
 */
const convertAddedComponentIdsListToComponentForest = (
    componentIdsAddedToWebStructure: string[],
    desktopStructure: StructureWithConversionData
): ComponentWithConversionData[] => {
    let clonedComponentsAddedToWebStructure = []
    const componentForest = []

    let componentsAddedToWebStructure = componentIdsAddedToWebStructure.map(compId => {
        return conversionUtils.getComponentByIdFromStructure(compId, desktopStructure)
    })

    componentsAddedToWebStructure = _.without(componentsAddedToWebStructure, null)

    if (_.isEmpty(componentsAddedToWebStructure)) {
        return componentForest
    }

    clonedComponentsAddedToWebStructure = componentsAddedToWebStructure.map(comp => {
        const compClone = objectUtils.cloneDeep(comp)
        clearIdentificationsAndChildrenFromAddedComponent(compClone as ComponentWithConversionData)
        return compClone
    })

    _.forEachRight(clonedComponentsAddedToWebStructure, potentialChild => {
        let hasParent = false
        for (let i = clonedComponentsAddedToWebStructure.length - 1; i >= 0; i--) {
            const potentialParent = clonedComponentsAddedToWebStructure[i]
            const potentialParentWithChildrenInfo = componentsAddedToWebStructure[i]

            if (areDirectParentAndChild(potentialChild, potentialParentWithChildrenInfo as ComponentWithConversionData)) {
                hasParent = true
                conversionUtils.reparentComponent(potentialParent, potentialChild)
                break
            }
        }
        if (!hasParent) {
            componentForest.push(potentialChild)
        }
    })

    return componentForest
}

function clearIdentificationsAndChildrenFromAddedComponent(component: ComponentWithConversionData) {
    if (!conversionUtils.getChildren(component)) {
        return
    }
    component.components = []
    _.assign(component.conversionData, {
        blockIds: [],
        blockLayout: [],
        componentsOrder: []
    })
}

function updateBlocksAndModifyLayoutIfNeeded(component: StructureWithConversionData, componentIdsToDeleteFromCurrentComponent: string[]) {
    const compBlocks: string[] = blocksUtils.getBlockIdsFromConversionData(component)
    const updatedBlocks = compBlocks.reduce((result, block, i) => {
        const blockSizeBeforeUpdate = block.length

        component.conversionData.blockIds[i] = _.difference(block, componentIdsToDeleteFromCurrentComponent)

        if (blockSizeBeforeUpdate !== component.conversionData.blockIds[i].length) {
            result.push(i)
        }
        return result
    }, [])

    _.forEachRight(compBlocks, (_block, i) => {
        if (_.includes(updatedBlocks, i)) {
            if (_.isEmpty(component.conversionData.blockIds[i])) {
                shiftUpComponentsLowerThanDeletedBlock(component, i)

                blocksUtils.removeBlocksFromConversionData(component, i)
            }
            return
        }

        const oldBlockLayout = component.conversionData.blockLayout[i]
        const blockComps = conversionUtils.getComponentsByIds(component, component.conversionData.blockIds[i])
        const newBlockLayout = conversionUtils.getSnugLayout(blockComps)
        component.conversionData.blockLayout[i] = newBlockLayout
        const blockLayoutYDiff = newBlockLayout.y - oldBlockLayout.y

        if (blockLayoutYDiff !== 0) {
            shiftComponentsLowerThanBlock(component, i - 1, -blockLayoutYDiff)
        }

        const blockLayoutHeightDiff = oldBlockLayout.height - component.conversionData.blockLayout[i].height
        const shiftNeededAfterYChange = blockLayoutHeightDiff - blockLayoutYDiff

        if (blockLayoutHeightDiff !== 0) {
            shiftComponentsLowerThanBlock(component, i, -shiftNeededAfterYChange)
        }
    })
}

function shiftUpComponentsLowerThanDeletedBlock(container: StructureWithConversionData, deletedBlockNumber: number) {
    const blockLayout = blocksUtils.getBlocksLayoutFromConversionData(container)
    const deletedBlockHeight = blocksUtils.getBlocksLayoutData(blockLayout[deletedBlockNumber], 'height')
    let blockComponentType = blocksUtils.getBlockComponentType(container as ComponentWithConversionData, deletedBlockNumber)

    let targetBlockNumber
    /**
     * It looks not oncomponentType of deleted comp,
     * but on previous one because deleted comp has to.be.removed.by.MobileMerge
     * so it need to check previous comp type to make sure it should add margin or not
     */
    if (blockComponentType === DEAD_COMP_TYPE) {
        targetBlockNumber = deletedBlockNumber - 1 >= 0 ? deletedBlockNumber - 1 : deletedBlockNumber

        blockComponentType = blocksUtils.getBlockComponentType(container as ComponentWithConversionData, targetBlockNumber)
    }

    const defaultMargin = blocksUtils.getYGapsBetweenBlocks({
        componentType: blockComponentType
    } as ComponentWithConversionData)

    const shiftValue = -(deletedBlockHeight + defaultMargin)

    shiftComponentsLowerThanBlock(container, deletedBlockNumber, shiftValue)
}

function updateComponentsStyles(
    webStructure: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobileComponentJson: ComponentWithConversionData | MasterPageComponentWithConversionData
) {
    const webComponent = <Component>conversionUtils.getComponentByIdFromStructure(mobileComponentJson.id, webStructure)

    const styleId = _.get(webComponent, 'styleId')
    if (styleId) {
        _.set(mobileComponentJson, 'styleId', styleId)
    }

    _.forEach(conversionUtils.getChildren(mobileComponentJson), updateComponentsStyles.bind(null, webStructure))
}

function identifyBlocks(component: ComponentWithConversionData | MasterPageComponentWithConversionData, enableNewMergeFlow = false): void {
    conversionDataUtils.createConversionDataIfNotExists(component)

    const children = conversionUtils.getChildren(component)

    if (!children) {
        return
    }

    blocksUtils.setBlocksToConversionData(component as ComponentWithConversionData, [], [], [])

    const childrenClone = <ComponentWithConversionData[]>objectUtils.cloneDeep(children)
    childrenClone.sort(compareByRows)

    let currentBlockIds = []
    let firstIndexInCurrentBlock = 0

    _.forEach(childrenClone, (child, i) => {
        currentBlockIds.push(child.id)

        if (enableNewMergeFlow && isMobileOnlyFixedComponentId(child.id)) {
            return
        }
        if (i === childrenClone.length - 1) {
            addCurrentBlockData(component, currentBlockIds)
            return
        }
        const otherBlockMembers = _.slice(childrenClone, firstIndexInCurrentBlock, i + 1)

        const haveSufficientYOverlapWithOtherBlockMember = _.some(otherBlockMembers, sibling => haveSufficientYOverlap(sibling, childrenClone[i + 1]))
        if (!haveSufficientYOverlapWithOtherBlockMember) {
            addCurrentBlockData(component, currentBlockIds)
            currentBlockIds = []
            firstIndexInCurrentBlock = i + 1
        }
    })

    _.forEach(children, (child: ComponentWithConversionData) => identifyBlocks(child, enableNewMergeFlow))
}

function addCurrentBlockData(component: ComponentWithConversionData | MasterPageComponentWithConversionData, currentBlockIds) {
    const blockComps = conversionUtils.getComponentsByIds(component, currentBlockIds)
    const blockLayout = conversionUtils.getSnugLayout(blockComps)
    const blockTypes = blockComps.map(comp => comp.componentType)

    component.conversionData.blockLayout.push(blockLayout)
    component.conversionData.blockIds.push(currentBlockIds)
    component.conversionData.blockComponentTypes.push(blockTypes)
}

const haveSufficientYOverlap = (comp1, comp2) => {
    const yOverlap = conversionUtils.getYOverlap(comp1, comp2)
    const minHeight = Math.min(comp1.layout.height, comp2.layout.height)
    const sufficientOverlap = yOverlap / minHeight > conversionConfig.OVERLAP_TO_MIN_WIDTH_RATIO_THRESHOLD

    return sufficientOverlap
}

function compareByRows(comp1: ComponentWithConversionData, comp2: ComponentWithConversionData): number {
    if (!haveSufficientYOverlap(comp1, comp2) && comp1.layout.y !== comp2.layout.y) {
        return comp1.layout.y < comp2.layout.y ? -1 : 1
    }

    const isComp1ScreenWidth = conversionUtils.isScreenWidthComponent(comp1)
    const isComp2ScreenWidth = conversionUtils.isScreenWidthComponent(comp2)

    if (isComp1ScreenWidth) {
        return isComp2ScreenWidth ? 0 : -1
    }

    if (isComp2ScreenWidth) {
        return 1
    }

    if (comp1.layout.x < comp2.layout.x) {
        return -1
    }

    if (comp1.layout.x > comp2.layout.x) {
        return 1
    }

    return 0
}

function canReparentTo(componentId: string, parent: Component | MasterPageComponent): boolean {
    if (_.get(parent, ['conversionData', 'nestOverlayingSiblings'], true)) {
        return true
    }
    const childrenIds = _.map(conversionUtils.getChildren(parent), 'id')
    return _.includes(childrenIds, componentId)
}

function getComponentIdToItsContainerIdMap(component: Component | MasterPageComponent) {
    let ret = {}
    const children = conversionUtils.getChildren(component)
    if (children) {
        for (const curChild of children) {
            ret[curChild.id] = component.id
            ret = _.merge(ret, getComponentIdToItsContainerIdMap(curChild))
        }
    }
    return ret
}

function getAncestorContainerExistingInMobile(
    component: ComponentWithConversionData,
    websiteStructure: StructureWithConversionData,
    mobileSiteStructure: StructureWithConversionData,
    settings: ConversionSettings
): Component | MasterPageComponent {
    const componentId = component.id
    // TODO: refactore, can be passed as param instead to be calculated for each component
    const desktopComponentIdToItsContainerIdMap = getComponentIdToItsContainerIdMap(websiteStructure)

    let currentParentId = desktopComponentIdToItsContainerIdMap[componentId]
    let mobileParent
    let desktopParent
    while (!mobileParent || !canReparentTo(component.id, desktopParent)) {
        /**
         * HERE IS a place whare algo deside where should component render if it's parent hidden
         */
        mobileParent = conversionUtils.getComponentByIdFromStructure(currentParentId, mobileSiteStructure)
        if (settings.enableNewMergeFlow) {
            /**
             * In case when desktop has modified containers and added components in one of them, mobile strutcure can be empty,
             * so we need to check if component exists before merge in desktop structure, if it does it should add as futureCOntainer component
             * */
            if (desktopParent && conversionUtils.isExistsBeforeMerge(desktopParent)) {
                mobileParent = desktopParent
                break
            }
        }
        const mobileChildren = mobileParent ? conversionUtils.getChildren(mobileParent) : []
        desktopParent = conversionUtils.getComponentByIdFromStructure(currentParentId, websiteStructure)
        currentParentId = desktopComponentIdToItsContainerIdMap[currentParentId]
        if (conversionUtils.shouldReparentCompToChildOfContainer(component, desktopParent) && !_.isEmpty(mobileChildren)) {
            return _.head(mobileChildren)
        }
    }
    return mobileParent
}

function getPreviousComponentIdThatAlsoExistInMobile(
    desktopComponent: Component | MasterPageComponent,
    mobileComponent: Component,
    componentId: string,
    mobileSiteStructure: Component
): string | null {
    if (conversionUtils.isVirtualGroup(desktopComponent as Component)) {
        return getPreviousComponentIdThatAlsoExistInMobile(
            <ComponentWithConversionData | MasterPageComponent>conversionUtils.getParent(desktopComponent.id, mobileComponent),
            mobileComponent,
            desktopComponent.id,
            mobileSiteStructure
        )
    }

    const componentsOrder = <string[]>_.get(<ComponentWithConversionData>desktopComponent, ['conversionData', 'componentsOrder'], [])
    const componentIndexInOrder = componentsOrder.indexOf(componentId)
    for (let i = componentIndexInOrder - 1; i >= 0; i--) {
        const curComponentId = componentsOrder[i]

        if (curComponentId === 'PAGES_CONTAINER') {
            continue
        }

        const curComponent = _.find(conversionUtils.getChildren(desktopComponent), {
            id: curComponentId
        })

        if (conversionUtils.getComponentByIdFromStructure(curComponentId, mobileSiteStructure)) {
            return curComponentId
        } else if (conversionUtils.isVirtualGroup(curComponent)) {
            for (let j = 0; j < curComponent.components.length; j++) {
                const componentOrder = <string>_.get(curComponent, ['conversionData', 'componentsOrder', j])
                if (conversionUtils.getComponentByIdFromStructure(componentOrder, mobileSiteStructure)) {
                    return componentOrder
                }
            }
        }
    }

    return null
}

export const testAPI = {
    rejectFixedPositionCompsNonConvertibleToMobile
}
