import type {PS, Rect, Experiment} from '@wix/document-services-types'
import _ from 'lodash'
import {repeaterUtils, variantsUtils} from '@wix/document-manager-extensions'
import type {LayoutDoneCbArgs} from '@wix/viewer-manager-interface'
const {isRepeatedComponent, isRepeaterMasterItem, getRepeaterTemplateId} = repeaterUtils
const {hasActiveVariants} = variantsUtils
import {pointerUtils, CoreLogger} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
const {getPointer} = pointerUtils

interface CompMeasure {
    height: number
    width: number
    relativeToContainerLeft?: number
    relativeToContainerTop: number
}

const VALID_PX_THRESHOLD = 0.5
const isNumber = _.isFinite
const isNumberOrUndefined = (n: number | undefined) => _.isUndefined(n) || _.isFinite(n)
const isPositive = (n: number) => isNumber(n) && n >= 0
const areAllNumbers = (...args: any[]) => _.every(args, isNumber)
const areAllPositive = (...args: any[]) => _.every(args, isPositive)

const areValidMeasures = (compId: string, {height, width, relativeToContainerLeft, relativeToContainerTop}: CompMeasure) =>
    areAllPositive(height, width) && isNumber(relativeToContainerTop) && isNumberOrUndefined(relativeToContainerLeft)

const reportIncorrectMeasures = (logger: CoreLogger, compId: string, compMeasure: CompMeasure) =>
    logger.captureError(
        new ReportableError({
            errorType: 'invalidLayoutArgs',
            message: `postUpdateOperation ${compId} got incorrect values`,
            extras: {
                compMeasure: _.pick(compMeasure, ['height', 'width', 'relativeToContainerLeft', 'relativeToContainerTop'])
            }
        })
    )

const fixMeasure = (n: number, pred: (n: number) => boolean, fallback: number) => (pred(n) ? n : fallback)
const fixMeasures = (compId: string, compMeasure: CompMeasure) => {
    const fixedMeasures = {
        ...compMeasure,
        height: fixMeasure(compMeasure.height, isPositive, 0),
        width: fixMeasure(compMeasure.width, isPositive, 0),
        relativeToContainerTop: fixMeasure(compMeasure.relativeToContainerTop, isNumber, 0)
    }

    if (!_.isUndefined(compMeasure.relativeToContainerLeft)) {
        fixedMeasures.relativeToContainerLeft = fixMeasure(compMeasure.relativeToContainerLeft!, isNumber, 0)
    }

    return fixedMeasures
}

const fixLayoutValues = (
    logger: CoreLogger,
    experimentInstance: Experiment,
    {report, dontReportMasterPageLayout}: Record<string, boolean>,
    compId: string,
    compMeasure: CompMeasure
) => {
    if (!dontReportMasterPageLayout && compId === 'masterPage' && compMeasure.relativeToContainerTop !== 0) {
        reportIncorrectMeasures(logger, compId, compMeasure)
    }

    if (areValidMeasures(compId, compMeasure)) {
        return compMeasure
    }
    if (report) {
        reportIncorrectMeasures(logger, compId, compMeasure)
    }
    return fixMeasures(compId, compMeasure)
}

function hasValuesChanged(origObject: any, newObject: any) {
    if (!origObject) {
        return true
    }

    return _.some(newObject, (value, key) => {
        if (key !== 'x') {
            return !_.isEqual(origObject[key], value)
        }
        return Math.abs(origObject.x - value) > VALID_PX_THRESHOLD
    })
}

const updateWithMeasuredValues = (
    {dal, pointers}: any,
    {layoutedCompsMeasureMap, structure, isMobile}: any,
    layoutFlags: Record<string, boolean>,
    logger: CoreLogger,
    experimentInstance: Experiment
) => {
    const viewMode = isMobile ? 'MOBILE' : 'DESKTOP'
    const updatedComps = {}
    _(layoutedCompsMeasureMap)
        .pickBy(compMeasure => areAllNumbers(compMeasure.height, compMeasure.relativeToContainerTop, compMeasure.width))
        .pickBy((compMeasure, compId) => {
            const notRepeatedComp = !isRepeatedComponent(compId)
            const notWithActiveVariant = !hasActiveVariants({dal, pointers}, compId)
            const shouldUpdateRepeated = notWithActiveVariant && isRepeaterMasterItem({dal, pointers}, getPointer(compId, viewMode))
            return notRepeatedComp || shouldUpdateRepeated
        })
        .mapValues((compMeasure, compId) => fixLayoutValues(logger, experimentInstance, layoutFlags, compId, compMeasure))
        .mapValues((compMeasure: CompMeasure, compId) => {
            const newLayout: Partial<Rect> = {
                width: compMeasure.width,
                height: compMeasure.height,
                y: compMeasure.relativeToContainerTop
            }
            const isFixed = _.get(structure, [compId, 'layout', 'fixedPosition'], false)
            if (!_.isUndefined(compMeasure.relativeToContainerLeft) && !(isMobile && isFixed)) {
                newLayout.x = compMeasure.relativeToContainerLeft
            }
            return newLayout
        })
        .pickBy((newLayout, compId) => {
            const compIdToGet = isRepeatedComponent(compId) ? compId : getRepeaterTemplateId(compId)
            return hasValuesChanged(structure[compIdToGet].layout, newLayout)
        })
        .forEach((newLayout, compId) => {
            const pointer = getPointer(compId, viewMode, ['layout'])
            if (dal.isExist(pointer)) {
                dal.merge(pointer, newLayout)
                updatedComps[compId] = {
                    compId,
                    componentType: structure[compId].componentType,
                    viewMode: viewMode.toLowerCase(),
                    pageId: _.get(structure[compId], ['metaData', 'pageId']),
                    newLayout
                }
            }
        })

    return updatedComps
}

const shouldRunPostUpdate = ({dal, pointers}: any, postUpdateConfig: any) =>
    postUpdateConfig && dal.get(pointers.general.getRenderFlag('shouldUpdateJsonFromMeasureMap'))

export function runPostUpdateOperation(
    ps: PS,
    postUpdateOperationConfig: any,
    runMigrators: any,
    layoutCircuitBreaker: any,
    experimentInstance: Experiment,
    logger: CoreLogger,
    didLayoutArgs: LayoutDoneCbArgs
) {
    if (!shouldRunPostUpdate(ps, postUpdateOperationConfig)) {
        return
    }

    if (!layoutCircuitBreaker.isPostLayoutOperationAllowed()) {
        if (experimentInstance.isOpen('dm_reportCircuitBreaker')) {
            logger.captureError(
                new ReportableError({
                    errorType: 'LAYOUT_CIRCUIT_BREAKER_ERROR',
                    message: `Post update operation ran for more than ${layoutCircuitBreaker.getMaxPostLayoutOperationsAllowed()} times`,
                    extras: {callbackData: _.pick(didLayoutArgs, ['layoutedCompsMeasureMap', 'isMobile'])}
                })
            )
            layoutCircuitBreaker.reportCircuitUpdates(logger)
        }
        return
    }

    const {structure, layoutedCompsMeasureMap, isMobile} = didLayoutArgs
    const layoutFlags = {
        report: experimentInstance.isOpen('dm_reportPostUpdateLayoutChanges'),
        dontReportMasterPageLayout: experimentInstance.isOpen('dm_dontReportMasterPageLayout')
    }
    const updatedComps = updateWithMeasuredValues(ps, {layoutedCompsMeasureMap, structure, isMobile}, layoutFlags, logger, experimentInstance)

    const viewMode = isMobile ? 'MOBILE' : 'DESKTOP'
    const layoutedComponentPointers = _(layoutedCompsMeasureMap)
        .pickBy((v, k) => structure[k])
        .mapValues((v, k) => ({
            pointer: getPointer(k, viewMode),
            componentType: structure[k].componentType,
            pageId: _.get(structure[k], ['metaData', 'pageId'])
        }))
        .value()

    runMigrators(layoutedComponentPointers)

    ps.dal.commitTransaction('postUpdateOp')
    layoutCircuitBreaker.increasePostLayoutCount(updatedComps)
}
