define(['documentServices/mobileConversion/bi/events.json'], function (events) {
    'use strict'
    /**
     * Please ctrl/cmd + click on biEvents to see the schema :)
     * @type {Object.<String, BIError>}
     */

    return {
        events: events.events
    }
})
