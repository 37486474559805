define([
    'lodash',
    'tpa',
    '@wix/santa-core-utils',
    'experiment',
    '@wix/document-manager-utils',
    'documentServices/component/component',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/page/page',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/appStoreService',
    'documentServices/tpa/services/tpaWidgetService',
    'documentServices/tpa/services/tpaSectionService',
    'documentServices/tpa/services/pendingAppsService',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/appMarketService',
    'documentServices/tpa/constants',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/services/tpaStyleService',
    'documentServices/tpa/services/tpaComponentCommonService',
    'documentServices/hooks/hooks',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/platform/services/platformStateService'
], function (
    _,
    tpa,
    santaCoreUtils,
    experiment,
    {ReportableError},
    component,
    componentStylesAndSkinsAPI,
    componentDetectorAPI,
    page,
    clientSpecMapService,
    appStoreService,
    tpaWidgetService,
    tpaSectionService,
    pendingAppsService,
    installedTpaAppsOnSiteService,
    appMarketService,
    tpaConstants,
    tpaEventHandlersService,
    tpaStyleService,
    tpaComponentCommonService,
    hooks,
    extensionsAPI,
    contextAdapter,
    tpaUtils,
    platformStateService
) {
    const provisionApp = function (ps, componentToAddPointer, type, appDefinitionId, params, onSuccess, onError) {
        params = params || {}
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        appMarketService.requestAppMarketDataToBeCached(ps, appDefinitionId)

        //when coming from ADI
        if (params.sourceTemplateId && !existingAppData) {
            appStoreService.provisionAppFromSourceTemplate(ps, appDefinitionId, params.sourceTemplateId, onSuccess, onError)
            return
        }

        if (!existingAppData) {
            if (experiment.isOpen('dm_useProvisionApi')) {
                appStoreService.provision(
                    ps,
                    [{appDefinitionId, version: _.get(params, 'appVersion')}],
                    clientSpecMap => {
                        onSuccess(_.find(clientSpecMap, {appDefinitionId}))
                    },
                    onError
                )
            } else {
                appStoreService.preSaveAddApp(ps, appDefinitionId, onSuccess, onError, _.get(params, 'appVersion'))
            }
        } else {
            if (pendingAppsService.isPending(ps, existingAppData)) {
                pendingAppsService.add(existingAppData)
            }
            platformStateService.clearAppPendingAction(ps, appDefinitionId)
            if (_.get(params, 'appVersion')) {
                ps.dal.set(ps.pointers.platform.getSemanticAppVersionPointer(appDefinitionId), _.get(params, 'appVersion'))
            }
            onSuccess(existingAppData)
        }
    }

    const addApp = function (privateServices, componentToAddPointer, type, params, appData, onSuccess, onError, waitForSOQ = true) {
        clientSpecMapService.registerAppData(privateServices, appData)
        try {
            getAddCompFunctionByType(type)(privateServices, componentToAddPointer, params, appData, onSuccess, onError, waitForSOQ)
        } catch (e) {
            if (onError) {
                onError(e)
            }
        }
    }

    const getAddCompFunctionByType = function (type) {
        const compTpaAddFunctions = {
            TPAWidget: tpaWidgetService.addWidgetAfterProvision,
            TPASection: tpaSectionService.addSectionAfterProvision
        }

        return compTpaAddFunctions[type]
    }

    const settingsUpdated = function (ps, applicationId, targetCompId, message) {
        if (targetCompId === '*') {
            const comps = installedTpaAppsOnSiteService.getAllAppCompsByAppId(ps, applicationId)
            _.forEach(comps, function (comp) {
                const appDefinitionId = _.get(component.data.get(ps, comp), 'appDefinitionId')
                tpaEventHandlersService.callSettingsUpdateCallback(ps, comp.id, message, appDefinitionId)
            })
        } else {
            const compRef = componentDetectorAPI.getComponentById(ps, targetCompId)
            const appDefinitionId = _.get(component.data.get(ps, compRef), 'appDefinitionId')
            tpaEventHandlersService.callSettingsUpdateCallback(ps, targetCompId, message, appDefinitionId)
        }
    }

    const refreshApp = function (ps, comps, queryParams) {
        queryParams = _.merge(queryParams || {}, {
            cacheKiller: `${_.now()}`
        })

        _.forEach(comps, function (comp) {
            const id = _.get(comp, 'id')
            if (id) {
                const data = ps.siteAPI.getTpaCompPreviewData(id) || {}
                const previewData = _.defaults(
                    {
                        queryParams
                    },
                    data
                )
                extensionsAPI.tpa.sync(ps, 'TPA_COMP_PREVIEW_DATA', id, previewData)

                const appDefinitionId = _.get(comp, 'appDefinitionId')
                tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
            }
        })
    }

    const isSection = function (ps, compPointer) {
        const compType = component.getType(ps, compPointer)
        return compType === tpaConstants.COMP_TYPES.TPA_SECTION || compType === tpaConstants.TPA_COMP_TYPES.TPA_SECTION
    }

    const getSectionRefByPageId = function (ps, pageId) {
        const pagePointers = page.getPage(ps, pageId)
        const tpaChildrenPointers = component.getTpaChildren(ps, pagePointers)
        return _.find(tpaChildrenPointers, function (pointer) {
            return isSection(ps, pointer)
        })
    }

    const setExternalId = function (ps, compPointer, newReferenceId, callback, preventRefresh) {
        extensionsAPI.tpa.sync(ps, 'COMP_STATE', compPointer.id, {preventRefresh: Boolean(preventRefresh)})
        const appDefinitionId = _.get(component.data.get(ps, compPointer), 'appDefinitionId')
        const componentData = component.data.get(ps, compPointer)
        const referenceId = componentData?.referenceId
        if (referenceId !== newReferenceId) {
            tpaUtils.notifyTPAAPICalledFromPanel(ps, appDefinitionId)
        }

        component.data.update(ps, compPointer, {referenceId: newReferenceId}, true)
        if (newReferenceId) {
            callback(`ExternalId: ${newReferenceId} will be saved when the site will be saved`)
        } else {
            callback('ExternalId: will reset when the site will be saved')
        }
    }

    const getExternalId = function (ps, compPointer) {
        const compData = component.data.get(ps, compPointer, null, true)
        return compData && compData.referenceId
    }

    const postBackThemeData = function (ps, compId, changedData) {
        const compStyleId = componentStylesAndSkinsAPI.style.getId(ps, componentDetectorAPI.getComponentById(ps, compId))
        //make sure to register theme once per iframe
        if (changedData.type !== 'STYLE' || (changedData.type === 'STYLE' && compStyleId === changedData.values)) {
            //post changes only to the relevant iframe
            const data = tpaStyleService.getStyleDataToPassIntoApp(ps, compId)
            postMessageBackToApp(ps, compId, 'THEME_CHANGE', data)
        }
    }

    const notifyTpaComponent = (ps, compId, eventType, params) => {
        if (_.isEmpty(_.trim(compId))) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    message: 'notifyTpaComponent has been called with empty compId',
                    errorType: 'notifyTpaComponentCompIdMissing',
                    tags: {
                        empty_notifyTpaComponent: true,
                        eventType
                    }
                })
            )
            return
        }

        const excludeSyncingOtherClients = _.get(params, ['metaData', 'excludeSyncingOtherClients'], false)
        if (!excludeSyncingOtherClients && !['EDIT_MODE_CHANGE', 'COMPONENT_DELETED'].includes(eventType)) {
            extensionsAPI.tpa.sync(ps, 'POST_MESSAGE_TO_APP', [compId, eventType], params)
        } else {
            postMessageToAppSyncer([compId, eventType], params)
        }
    }

    const getComponentIdsToNotify = (ps, compId) => {
        const compPtr = componentDetectorAPI.getComponentById(ps, compId)

        if (compPtr) {
            return ps.pointers.referredStructure.getInternallyReferredComponents(compPtr) || [compId]
        }

        return [compId]
    }

    const postMessageBackToApp = (ps, compId, eventKey, params) => {
        notifyTpaComponents(ps, compId, eventKey, params)
    }

    const notifyTpaComponents = (ps, compId, eventKey, params) => {
        const compsToNotify = getComponentIdsToNotify(ps, compId)
        _.forEach(compsToNotify, compIdToNotify => {
            if (!compIdToNotify) {
                contextAdapter.utils.fedopsLogger.captureError(
                    new ReportableError({
                        message: 'notifyTpaComponents called with no id',
                        errorType: 'notifyTpaComponentsMissingId',
                        extras: {
                            compId,
                            compsToNotify: JSON.stringify(compIdToNotify)
                        }
                    })
                )
            }
            notifyTpaComponent(ps, compIdToNotify, eventKey, params)
        })
    }

    const tpaCompPreviewDataSyncer = (ps, compId, data) => {
        ps.siteAPI.setTpaCompPreviewData(compId, data)
    }

    const compStateSyncer = (ps, compId, data) => {
        ps.siteAPI.setCompState(compId, data)
    }

    const postMessageToAppSyncer = ([compId, eventType], params) => {
        const domComponent = window.document.querySelector(`#${compId}`)
        if (!domComponent) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    message: `postMessage back to app was called for ${compId} but iframe not found`,
                    errorType: 'postMessageToAppError',
                    tags: {
                        noIframePostMessageToApp: true
                    },
                    extras: {
                        compId,
                        eventType
                    }
                })
            )
            return
        }
        const iframe = domComponent.querySelector('iframe')

        if (!iframe) {
            return
        }

        try {
            tpa.common.tpaPostMessageCommon.callPostMessage(iframe, {
                intent: 'addEventListener',
                eventType,
                params
            })
        } catch (e) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    message: `postMessage back to app was called for ${compId} but failed`,
                    errorType: 'postMessageToAppError',
                    tags: {
                        errorOnPostMessageBackToApp: true
                    },
                    extras: {
                        error: e,
                        compId,
                        eventType
                    }
                })
            )
        }
    }

    const getDefaultLayout = function (ps, applicationId, widgetId) {
        const widgetData = clientSpecMapService.getWidgetData(ps, applicationId, widgetId)

        return {
            height: _.get(widgetData, 'defaultHeight') || 500,
            width: _.get(widgetData, 'defaultWidth') || 980,
            x: 0
        }
    }

    const provisionWidget = function (ps, componentToAddRef, appDefinitionId, options) {
        const onComplete = function (data, err) {
            ps.setOperationsQueue.asyncPreDataManipulationComplete(data, err)
        }
        const onError = function () {
            if (options.onError) {
                options.onError()
            }
            onComplete(null, new Error('addWidget - provision failed'))
        }
        provisionApp(ps, componentToAddRef, tpaConstants.TYPE.TPA_WIDGET, appDefinitionId, options, onComplete, onError)
    }

    /**
     * @param {ps} ps
     * @param pageToAddRef
     * @param appDefinitionId
     * @param options
     * @returns {void|*}
     */
    const provisionMultiSection = function (ps, pageToAddRef, appDefinitionId, options) {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (clientSpecMapService.isAppActive(ps, appData)) {
            const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${santaCoreUtils.guidUtils.getUniqueId()}`
            options.sectionId = sectionId
            const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appDefinitionId, options.pageId)
            if (widgetData && clientSpecMapService.isMultiSectionInstanceEnabled(appData, widgetData.widgetId)) {
                options.applicationId = appData.applicationId
                options.appDefinitionId = appData.appDefinitionId
                options.widgetData = widgetData

                ps.setOperationsQueue.asyncPreDataManipulationComplete(appData)
                return _.assign(pageToAddRef, {
                    sectionId
                })
            }
            return ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('Creating this section is not allowed'))
        }
        return ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('Main section is not installed'))
    }

    const addMultiSection = function (ps, newAppData, pageToAddRef, appDefinitionId, options = {}) {
        clientSpecMapService.registerAppData(ps, newAppData)

        const originalCallback = options.callback
        options.callback = (...args) => {
            hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps, newAppData])

            if (originalCallback) {
                originalCallback(...args)
            }
        }
        tpaSectionService.addMultiSection(ps, pageToAddRef, options)
    }

    const addSubSection = function (ps, pageToAddPointer, appDefinitionId, options) {
        options = options || {}
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (appData) {
            const widgetData = _.find(appData.widgets, ['appPage.id', options.pageId])
            if (widgetData) {
                const sectionId = tpaComponentCommonService.addSubSection(ps, pageToAddPointer, widgetData, appData, options)
                _.assign(pageToAddPointer, {
                    sectionId
                })
                if (ps.setOperationsQueue.isRunningSetOperation()) {
                    ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
                        tpaSectionService.invokeSectionCallback(options, pageToAddPointer, sectionId, true)
                        hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps])
                    })
                } else {
                    tpaSectionService.invokeSectionCallback(options, pageToAddPointer, sectionId, true)
                    hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps])
                }
            }
        }
    }

    const provisionSection = function (ps, pageToAddRef, appDefinitionId, options, onError) {
        const sectionId = `${tpaConstants.TYPE.TPA_SECTION}_${santaCoreUtils.guidUtils.getUniqueId()}`
        options = options || {}
        options.sectionId = sectionId
        const onComplete = function (data, err) {
            data = data || {}
            data.additionalOptions = options
            ps.setOperationsQueue.asyncPreDataManipulationComplete(data, err)
        }
        const completeOnError = function () {
            if (onError) {
                onError()
            }
            ps.setOperationsQueue.asyncPreDataManipulationComplete(null, new Error('addSection - provision failed'))
        }
        provisionApp(ps, pageToAddRef, tpaConstants.TYPE.TPA_SECTION, appDefinitionId, options, onComplete, completeOnError)
        return _.assign(pageToAddRef, {
            sectionId
        })
    }

    const addSection = function (ps, appData, componentToAddRef, appDefinitionId, options, onError) {
        options = _.merge(options, _.get(appData, 'additionalOptions'))
        appData = _.omit(appData, 'additionalOptions')
        addAppWrapper(tpaConstants.TYPE.TPA_SECTION, ps, appData, componentToAddRef, appDefinitionId, options, _.noop, onError)
    }

    const addAppWrapper = function (type, ps, appData, componentToAddRef, appDefinitionId, options = {}, onSuccess, onError, waitForSOQ = true) {
        if (appData && !appData.dontAdd) {
            const originalCallback = options.callback
            options.callback = (...args) => {
                hooks.executeHook(hooks.HOOKS.ADD_TPA.AFTER, null, [ps, appData])

                if (originalCallback) {
                    originalCallback(...args)
                }
            }
            addApp(ps, componentToAddRef, type, options, appData, onSuccess, _.get(options, 'onError') || onError, waitForSOQ)
        }
    }

    const setCompPreviewDataForAllTPA = function (ps, key, value) {
        const sharedQueryParams = ps.siteAPI.getTpaCompPreviewData('sharedQueryParams') || {}
        ps.siteAPI.setTpaCompPreviewData('sharedQueryParams', _.merge(sharedQueryParams, {[key]: value}))
    }

    return {
        provisionApp,
        provisionWidget,
        provisionSection,
        provisionMultiSection,

        addApp,
        addAppWrapper,
        addSection,
        addMultiSection,
        addSubSection,
        settingsUpdated,
        refreshApp,
        getSectionRefByPageId,
        setExternalId,
        getExternalId,
        postBackThemeData,
        postMessageBackToApp,
        getDefaultLayout,
        tpaCompPreviewDataSyncer,
        compStateSyncer,
        postMessageToAppSyncer,
        setCompPreviewDataForAllTPA
    }
})
