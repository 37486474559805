export const skins: any = {}
skins['wysiwyg.viewer.skins.input.ComboBoxInputSkinNoValidation'] = {
    react: [
        ['select', 'collection', [], {}],
        ['div', null, ['_err'], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        fnt: 'FONT',
        bg: 'BG_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        txt2: 'TEXT_COLOR',
        brwh: 'BORDER_SIZE',
        brdh: 'BORDER_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        brwf: 'BORDER_SIZE',
        brdf: 'BORDER_COLOR_ALPHA',
        bgf: 'BG_COLOR_ALPHA',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        bge: 'BG_COLOR_ALPHA',
        brwe: 'BORDER_SIZE',
        brde: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        rd: '5px',
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        fnt: 'font_7',
        bg: 'color_8',
        txt: '#888888',
        brw: '2px',
        brd: '#F9F9F9',
        txt2: 'color_15',
        brwh: '2px',
        brdh: '#F9F9F9',
        bgh: 'color_8',
        brwf: '2px',
        brdf: '#F9F9F9',
        bgf: 'color_8',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF',
        bge: 'color_8',
        brwe: '2px',
        brde: '#F9F9F9'
    },
    css: {
        '%': 'position:relative;display:inline-block !important;',
        '%:after':
            'content:"▼";position:absolute;width:25px;pointer-events:none;color:#515151;text-align:center;line-height:30px;border-bottom-left-radius:0;border-top-left-radius:0;top:calc(50% - 15px);',
        '%_left-direction': 'text-align-last:left;',
        '%_left-direction:after': 'right:5px;',
        '%_right-direction': 'text-align-last:right;direction:rtl;',
        '%_right-direction:after': 'left:5px;',
        '%_center-direction': 'text-align-last:center;',
        '%_center-direction:after': 'right:5px;',
        '% select':
            '[rd]  -webkit-appearance:none;-moz-appearance:none;[shd]  [fnt]  background-color:[bg];color:[txt];border:solid [brw] [brd];margin:0;padding:0 12px;cursor:pointer;position:relative;max-width:100%;min-width:100%;min-height:100%;text-overflow:ellipsis;',
        '% select%_placeholder-style': 'color:[txt2];',
        '% select:hover,% select[data-preview~="hover"]': 'border:solid [brwh] [brdh];background-color:[bgh];',
        '% select:focus,% select[data-preview~="focus"]': 'border:solid [brwf] [brdf];background-color:[bgf];',
        '%_err': 'position:absolute;top:50%;left:0;height:1px;width:1px;',
        '%[data-state~="invalid"] %_err:before':
            'color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:"!";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;',
        '%[data-state~="invalid"] %_err:after':
            'color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:"►";position:absolute;top:10px;left:8px;',
        '%[data-disabled="true"] select,%[data-preview~="disabled"] select': 'background-color:[bgd];border-color:[brdd];color:[txtd];',
        '%[data-error="true"] select,%[data-preview~="error"] select': 'background-color:[bge];border:solid [brwe] [brde];'
    }
}
skins['skins.core.InlineSkin'] = {
    react: [['div', 'inlineContent', [], {}]],
    css: {}
}
skins['skins.core.VerySimpleSkin'] = {
    react: []
}
skins['wysiwyg.viewer.skins.AppPartSkin'] = {
    react: [
        ['div', 'inlineContent', [], {}],
        ['div', 'error', [], {}, 'Please reload'],
        ['div', 'loading', [], {}, ['p', null, [], {}]]
    ],
    params: {
        videoThumbColor: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        videoThumbColor: '#FFFFFF'
    },
    paramsMutators: {
        videoThumbColor: {
            type: 'alpha',
            value: 0.7
        }
    },
    css: {
        '%[data-state~="content"] %error': 'display:none;',
        '%[data-state~="content"] %loading': 'display:none;',
        '%[data-state~="error"] %inlineContent': 'display:none;',
        '%[data-state~="error"] %loading': 'display:none;',
        '%[data-state~="loading"] %inlineContent': 'opacity:0.1;',
        '%[data-state~="loading"] %loading':
            'position:absolute;top:0;right:0;bottom:0;left:0;-webkit-animation:semi-rotate 1s 1ms linear infinite;animation:semi-rotate 1s 1ms linear infinite;height:30px;left:50%;margin-left:-15px;margin-top:-15px;overflow:hidden;position:absolute;top:50%;-webkit-transform-origin:100% 50%;transform-origin:100% 50%;width:15px;',
        '%[data-state~="loading"] %loading::before':
            "content:'';top:0;left:0;right:-100%;bottom:0;border:3px solid currentColor;border-color:currentColor transparent transparent currentColor;border-radius:50%;position:absolute;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-animation:inner-rotate 0.5s 1ms linear infinite alternate;animation:inner-rotate 0.5s 1ms linear infinite alternate;color:#f0f0f0;",
        '%[data-state~="loading"] %loading::after':
            "content:'';top:0;left:0;right:-100%;bottom:0;border:3px solid currentColor;border-color:currentColor transparent transparent currentColor;border-radius:50%;position:absolute;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-animation:inner-rotate 0.5s 1ms linear infinite alternate;animation:inner-rotate 0.5s 1ms linear infinite alternate;color:#dcdcdc;opacity:0;",
        '%[data-state~="loading"] %error': 'display:none;',
        '%inlineContent > div': 'width:100%;',
        '%inlineContent': '-webkit-transform-style:preserve-3d;transform-style:preserve-3d;',
        '%_singleLine': 'white-space:nowrap;display:block;overflow:hidden;text-overflow:ellipsis;word-wrap:normal;',
        '%_videoIndicator:after':
            'content:"";position:absolute;top:50%;left:50%;width:0;height:0;margin-top:-10px;margin-left:-9px;border:10px solid transparent;border-left:18px solid [videoThumbColor];',
        '@-webkit-keyframes %inner-rotate': '% to{opacity:1;-webkit-transform:rotate(115deg);transform:rotate(115deg);}',
        '@keyframes %inner-rotate': '% to{opacity:1;-webkit-transform:rotate(115deg);transform:rotate(115deg);}',
        '@-webkit-keyframes %semi-rotate':
            '% from{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;-webkit-transform:rotate(180deg);transform:rotate(180deg);}45%{-webkit-transform:rotate(198deg);transform:rotate(198deg);}55%{-webkit-transform:rotate(234deg);transform:rotate(234deg);}% to{-webkit-transform:rotate(540deg);transform:rotate(540deg);}',
        '@keyframes %semi-rotate':
            '% from{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;-webkit-transform:rotate(180deg);transform:rotate(180deg);}45%{-webkit-transform:rotate(198deg);transform:rotate(198deg);}55%{-webkit-transform:rotate(234deg);transform:rotate(234deg);}% to{-webkit-transform:rotate(540deg);transform:rotate(540deg);}'
    }
}
skins['wysiwyg.viewer.skins.input.TextInputSquareSkin'] = {
    react: [
        [
            'label',
            'label',
            ['_clearfix'],
            {},
            [
                'input',
                'input',
                [],
                {
                    type: 'text'
                }
            ]
        ],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        tdr: 'URL'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%': 'font-size:1em;',
        '% label': 'float:none;font-size:17px;line-height:25px;color:#585858;',
        '%[data-state~="mobile"] label': 'float:none;font-size:14px;line-height:20px;color:#585858;',
        '%input':
            'padding:0 15px;width:100%;height:42px;font-size:19px;line-height:42px;color:#0198ff;margin:0 -3px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -170px;border:solid 1px #a1a1a1;box-sizing:border-box;',
        '%[data-state~="mobile"] %input':
            'padding:0 15px;width:100%;height:30px;font-size:14px;line-height:30px;color:#0198ff;margin:0 -3px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -170px;border:solid 1px #a1a1a1;box-sizing:border-box;',
        '%input[data-type="password"]': 'font-size:38px;',
        '%[data-state~="mobile"] %input[data-type="password"]': 'font-size:14px;',
        '%errorMessage': 'display:block;font-size:12px;color:#d74401;text-align:right;height:15px;',
        '%:not([data-state~="invalid"]) %errorMessage': 'visibility:hidden;',
        '%[data-state~="invalid"] %errorMessage': 'visibility:visible;',
        '%[data-state~="invalid"] input': 'border-color:#d74401;',
        '%[data-state~="invalid"] %input': 'border-color:#ff0000;'
    }
}
skins['wysiwyg.viewer.skins.PasswordLoginSkin'] = {
    react: [
        ['div', 'blockingLayer', [], {}],
        [
            'div',
            'dialog',
            ['_content'],
            {},
            [
                'div',
                null,
                ['_wrapper'],
                {},
                ['div', 'xButton', [], {}],
                [
                    'div',
                    'header',
                    [],
                    {},
                    [
                        'a',
                        'faveIconAhref',
                        [],
                        {
                            href: 'http://www.wix.com',
                            target: '_blank'
                        },
                        [
                            'img',
                            'favIcon',
                            [],
                            {
                                src: 'http://www.wix.com/favicon.ico',
                                alt: 'Administator Login'
                            }
                        ]
                    ],
                    ['h3', 'title', [], {}]
                ],
                ['div', 'passwordInput', [], {}],
                [
                    'div',
                    null,
                    ['_footer'],
                    {},
                    ['a', 'cancel', [], {}],
                    [
                        'input',
                        'submitButton',
                        [],
                        {
                            type: 'button',
                            value: 'Submit'
                        }
                    ]
                ]
            ]
        ]
    ],
    exports: {
        passwordInput: {
            skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'
        }
    },
    params: {
        txt: 'TEXT_COLOR',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        shd: 'BOX_SHADOW',
        'bg-block': 'BG_COLOR',
        'bg-border': 'BG_COLOR'
    },
    paramsDefaults: {
        txt: '#9c9c9c',
        bg: '#ffffff',
        tdr: 'BASE_THEME_DIRECTORY',
        shd: '0 1px 3px rgba(0, 0, 0, 0.5)',
        'bg-block': '#555555',
        'bg-border': '#aaaaaa'
    },
    paramsMutators: {
        'bg-block': {
            type: 'alpha',
            value: 0.75
        },
        'bg-border': {
            type: 'alpha',
            value: 0.7
        }
    },
    css: {
        '%': 'position:fixed;width:100%;height:100%;z-index:99;font-family:Arial, sans-serif;font-size:1em;color:[txt];',
        '%blockingLayer': 'background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;',
        '%dialog': 'background-color:[bg-border];width:455px;position:fixed;padding:20px;',
        '%_wrapper': 'background-color:[bg];padding:45px 40px 0 40px;',
        '%xButton':
            'position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;',
        '%xButton:hover': 'background-position:right -80px;',
        '%header': 'padding-bottom:25px;line-height:30px;',
        '%favIcon': 'float:left;margin:7px 7px 0 0;width:16px;height:16px;',
        '%title': 'font-size:20px;font-weight:bold;color:#555555;',
        '%[data-state~="mobile"]': 'position:absolute;width:100%;height:100%;z-index:99;font-family:Arial, sans-serif;font-size:1em;color:[txt];top:0;',
        '%[data-state~="mobile"] %header': 'padding-bottom:10px;line-height:30px;',
        '%[data-state~="mobile"] %favIcon': 'display:none;',
        '%[data-state~="mobile"] %title': 'font-size:14px;',
        '%[data-state~="mobile"] %dialog': 'width:260px;padding:10px;position:absolute;',
        '%[data-state~="mobile"] %_footer': 'margin-top:0;padding-bottom:10px;',
        '%[data-state~="mobile"] %cancel': 'font-size:14px;line-height:30px;',
        '%[data-state~="mobile"] %_wrapper': 'padding:14px 12px 0 12px;',
        '%[data-state~="mobile"] %submitButton': 'height:30px;width:100px;font-size:14px;',
        '%_forgot': 'text-align:left;font-size:12px;',
        '%_forgot a': 'color:#0198ff;border-bottom:1px solid #0198ff;',
        '%_forgot a:hover': 'color:#0044ff;border-bottom:1px solid #0044ff;',
        '%_error': 'font-size:12px;color:#d74401;text-align:right;',
        '%_footer': 'width:100%;margin-top:27px;padding-bottom:40px;',
        '%cancel': 'color:[txt];font-size:18px;text-decoration:underline;line-height:36px;',
        '%cancel:hover': 'color:#9c3c3c;',
        '%passwordInput label': 'font-size:14px;',
        '%passwordInput label[data-type="password"]': 'font-size:14px;line-height:30px;height:30px;',
        '%submitButton':
            'float:right;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#ffffff;font-size:24px;font-weight:bold;[shd]',
        '%submitButton:hover': 'background-position:right -352px;border-color:#004286;',
        '%[data-state="normal"] %error': 'display:none;',
        '%[data-state="error"] %error': 'display:block;font-size:12px;color:#d74401;text-align:right;',
        '%[data-state="error"] %passwordInput': 'border-color:#d74401;'
    }
}
skins['skins.viewer.balata.balataBaseSkin'] = {
    react: []
}
skins['skins.viewer.bgimagestrip.BevelScreenSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', null, ['_bgtwo'], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        brwt: 'BORDER_TOP_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        brwb: 'BORDER_BOTTOM_SIZE',
        shd: 'BOX_SHADOW',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: 'color_13',
        brwt: '0',
        brd: 'color_15',
        brwb: '0',
        shd: 'rgba(255, 255, 255, 0.592157) 0 4px 6px -4px inset, rgba(255, 255, 255, 0.592157) 0 1px 0 0 inset, rgba(255, 255, 255, 0.901961) 0 -5px 5px -5px inset',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%bg,%_bgtwo,%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%bg': 'background-color:[bg];border-top:[brwt] solid [brd];border-bottom:[brwb] solid [brd];box-sizing:border-box;',
        '%_bgtwo': '[shd]  top:[brwt];bottom:[brwb];background-image:url([tdr]bevel_300.png);background-repeat:repeat-x;'
    }
}
skins['skins.viewer.bgimagestrip.BgImageStripSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        brwt: 'BORDER_TOP_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        brwb: 'BORDER_BOTTOM_SIZE'
    },
    paramsDefaults: {
        bg: 'color_11',
        shd: '0 0 5px rgba(0, 0, 0, 0.7)',
        brwt: '0',
        brd: 'color_15',
        brwb: '0'
    },
    css: {
        '%bg': 'background-color:[bg];[shd]  box-sizing:border-box;position:absolute;top:0;right:0;bottom:0;left:0;border-top:[brwt] solid [brd];border-bottom:[brwb] solid [brd];',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['skins.viewer.bgimagestrip.DefaultScreenSkin'] = {
    react: [
        ['div', null, ['_bgbottom'], {}],
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        brwt: 'BORDER_TOP_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        brwb: 'BORDER_BOTTOM_SIZE',
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        tdr: 'URL'
    },
    paramsDefaults: {
        brwt: '0',
        brd: 'color_15',
        brwb: '0',
        bg: 'color_11',
        shd: 'inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.6), 0 0 5px rgba(0, 0, 0, 0.6)',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;border-top:[brwt] solid [brd];border-bottom:[brwb] solid [brd];box-sizing:border-box;',
        '%_bgbottom':
            'background-color:[bg];[shd]  position:absolute;left:0;right:0;top:[brwt];bottom:[brwb];background-image:url([tdr]bevel_300.png);background-repeat:repeat-x;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['skins.viewer.bgimagestrip.DoubleBorderScreenSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', null, ['_outer'], {}, ['div', null, ['_inner'], {}]],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        bgPosition: 'SIZE',
        shd: 'BOX_SHADOW',
        bordersPosition: 'SIZE',
        outerLineSize: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        lineGap: 'SIZE',
        innerLineSize: 'BORDER_SIZE',
        brd2: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11',
        bgPosition: '0',
        shd: '0 0 5px rgba(0, 0, 0, 0.7)',
        bordersPosition: '6',
        outerLineSize: '3',
        brd: 'color_15',
        lineGap: '5',
        innerLineSize: '1',
        brd2: 'color_14'
    },
    css: {
        '%bg': 'background-color:[bg];position:absolute;top:[bgPosition];right:0;bottom:[bgPosition];left:0;[shd]',
        '%_outer':
            'position:absolute;top:[bordersPosition];right:0;bottom:[bordersPosition];left:0;border-top:[outerLineSize] solid [brd];border-bottom:[outerLineSize] solid [brd];',
        '%_inner':
            'position:absolute;top:[lineGap];right:0;bottom:[lineGap];left:0;border-top:[innerLineSize] solid [brd2];border-bottom:[innerLineSize] solid [brd2];',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['skins.viewer.bgimagestrip.IronScreenSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', null, ['_bgtwo'], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        shadow: 'BOX_SHADOW',
        shd: 'BOX_SHADOW',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: 'color_11',
        shadow: 'rgba(255, 255, 255, 0.592157) 0 4px 6px -4px inset, rgba(255, 255, 255, 0.592157) 0 1px 0 0 inset, rgba(255, 255, 255, 0.901961) 0 -5px 5px -5px inset',
        shd: '0 0 5px rgba(0, 0, 0, 0.7)',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[shadow]',
        '%_bgtwo': 'position:absolute;top:0;right:0;bottom:0;left:0;[shd]  background-image:url([tdr]ironpatern.png);',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.ArrowRightMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            [
                'ul',
                'itemsContainer',
                [],
                {
                    'data-marginallchildren': 'false'
                }
            ],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        maxH: 280,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ArrowRightMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'MARGIN_RIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        padLeft: 'MARGIN_LEFT_SIZE'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '1',
        bgDrop: 'color_11',
        rdDrop: '10px',
        padLeft: '70'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;margin-right:calc([pad] - 70px);background-color:[bgDrop];[rdDrop]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;box-sizing:border-box;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[drophposition="left"] %moreContainer': 'margin-right:0;margin-left:0;left:0;',
        '%dropWrapper[data-dropalign="left"][drophposition="center"] %moreContainer': 'margin-right:0;margin-left:[padLeft];',
        '%dropWrapper[data-dropalign="left"][drophposition="left"] %moreContainer': 'margin-right:0;margin-left:0 !important;',
        '%dropWrapper[data-dropalign="right"]': 'text-align:right;',
        '%dropWrapper[data-dropalign="right"] %moreContainer': 'margin-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.IndentedMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_wrapper'], {}, ['ul', 'itemsContainer', [], {}]],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        maxH: 430,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.IndentedMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bgDrop: 'color_11',
        rdDrop: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;background:transparent url([tdr]indented_bg_inverted.png) center bottom repeat-x;[rd]',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;box-sizing:border-box;',
        '%_wrapper': 'background:[bg] url([tdr]indented_bg_inverted.png) center top repeat-x;position:relative;overflow:hidden;[rd]  [shd]',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonBorderRadiusFixSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNBorderRadiusFixSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'SIZE',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: ['brw', 'brw'],
        brw: '1px',
        brd: 'color_15',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bgDrop: 'color_11',
        rd: '0'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;border-top:solid [brw] [brd];border-bottom:solid [brw] [brd];',
        '%moreContainer':
            'overflow:visible;display:inherit;white-space:nowrap;width:auto;overflow:visible;display:inherit;white-space:nowrap;width:auto;z-index:99999;margin:7px 0;[shd]  background-color:[bgDrop];[rd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'SIZE',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: ['brw', 'brw'],
        brw: '1px',
        brd: 'color_15',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bgDrop: 'color_11',
        rd: '0'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;border-top:solid [brw] [brd];border-bottom:solid [brw] [brd];',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;z-index:99999;margin:7px 0;[shd]  background-color:[bgDrop];[rd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonHorizontalMenuAdaptationSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonHorizontalMenuAdaptationSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bgDrop: 'transparent',
        rd: '0',
        shd: 'none'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;',
        '%dropWrapper':
            'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:15px 5px 0 5px;background-color:[bgDrop];[rd]  [shd]background-color:[bgDrop];[rd]  [shd]',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bgDrop: 'color_11',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;',
        '%dropWrapper':
            'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:15px 5px 0 5px;background-color:[bgDrop];[rd]  [shd]',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonHorizontalMenuAdaptationSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_back'], {}],
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        menuTotalBordersY: 1,
        labelPad: 13,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonHorizontalMenuAdaptationSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        brd: 'COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        brd: 'color_15',
        bgDrop: 'color_11',
        rd: '0',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;',
        '%moreContainer':
            'overflow:visible;display:inherit;white-space:nowrap;width:auto;overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]  [shd]',
        '%dropWrapper':
            'z-index:99999;display:block;opacity:1;z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;box-sizing:border-box;',
        '%_back':
            'height:calc(100% - [menuTotalBordersY] - 13px);background-color:[bg];position:absolute;top:0;right:0;bottom:13px;left:0;border-bottom:solid 1px [brd];',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.PointerMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_back'], {}],
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        menuTotalBordersY: 1,
        labelPad: 13,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        brd: 'COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        brd: 'color_15',
        bgDrop: 'color_11',
        rd: '0',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;box-sizing:border-box;',
        '%_back':
            'height:calc(100% - [menuTotalBordersY] - 13px);background-color:[bg];position:absolute;top:0;right:0;bottom:13px;left:0;border-bottom:solid 1px [brd];',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.RibbonsMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_fl', '_ribbon'], {}],
            ['div', null, ['_fr', '_ribbon'], {}],
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.RibbonsMenuButtonNSkin',
            styleGroup: 'inherit'
        },
        ribbonExtra: 10
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        els: 'BORDER_SIZE',
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        elm: 'COLOR_ALPHA',
        elm2: 'COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        ribbonEls: 'BORDER_SIZE'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        els: '0',
        bg: 'color_11',
        shd: '0 2px 3px rgba(0, 0, 0, 0.7)',
        elm: 'color_15',
        elm2: 'color_15',
        bgDrop: 'color_11',
        ribbonEls: ['els']
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;height:calc(100% - [menuTotalBordersY] - [els] - 10px);margin:0 0 calc([els] + 10px);position:relative;background-color:[bg];[shd]',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:-10px;box-sizing:border-box;',
        '%_ribbon': 'position:absolute;bottom:calc(-1 * ([els] + 10px));height:0;width:0;border:calc([els] + 10px) solid transparent;',
        '%_fr': 'border-top-color:[elm];border-left-width:0;right:0;',
        '%_fl': 'border-top-color:[elm2];border-right-width:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateBasicMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        shd: 'BOX_SHADOW',
        rdDrop: 'BORDER_RADIUS',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        rdDrop: '10px',
        brw: '0',
        brd: 'color_15',
        bgDrop: 'color_11'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;border:solid [brw] [brd];background-color:[bgDrop];[rdDrop]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;box-sizing:border-box;[shd]  [rdDrop]',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateIndentedMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateIndentedMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rdDrop: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]  overflow:hidden;',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonHorizontalMenuAdaptationSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateLinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rd: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer':
            'overflow:visible;display:inherit;white-space:nowrap;width:auto;overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]border-radius:0;',
        '%dropWrapper':
            'z-index:99999;display:block;opacity:1;z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateLinesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rd: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonBorderRadiusFixSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNBorderRadiusFixSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rdDrop: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer':
            'overflow:visible;display:inherit;white-space:nowrap;width:auto;overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]  overflow:hidden;',
        '%dropWrapper':
            'z-index:99999;display:block;opacity:1;z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIIMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rdDrop: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]  overflow:hidden;',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateShinyIMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        pad: '5px',
        bgDrop: 'color_11',
        rdDrop: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-drophposition="left"]': 'padding-left:0;',
        '%dropWrapper[data-drophposition="right"]': 'padding-right:0;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bgDrop: 'color_11',
        rdDrop: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;background:[bg] url([tdr]shiny1button_bg.png) center center repeat-x;[rd]  [shd]',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.ShinyMenuIIButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_wrapper'], {}, ['ul', 'itemsContainer', [], {}]],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIIButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bgDrop: 'color_11',
        rdDrop: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;background:transparent url([tdr]shiny2button_bg.png) center bottom repeat-x;[rd]',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;',
        '%_wrapper': 'position:relative;overflow:hidden;background:[bg] url([tdr]shiny2button_bg.png) center top repeat-x;[rd]  [shd]',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SloppyBorderMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_wrapper'], {}, ['ul', 'itemsContainer', [], {}]],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SloppyBorderMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bg: 'BG_COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bg: 'color_11',
        bgDrop: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;background-color:[bg];',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:15px;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:15px;',
        '%_wrapper:before': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 0 0;left:-7px;top:0;bottom:0;width:7px;',
        '%_wrapper:after': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 100% 0;right:-7px;top:0;bottom:0;width:7px;',
        '%:before': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 0;left:-7px;top:-7px;right:-7px;height:7px;',
        '%:after': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 100%;left:-7px;bottom:-7px;right:-7px;height:7px;',
        '%dropWrapper:before': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 0 0;left:-7px;top:0;bottom:0;width:7px;',
        '%dropWrapper:after': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 100% 0;right:-7px;top:0;bottom:0;width:7px;',
        '%moreContainer:before': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 0;left:-7px;top:-7px;right:-7px;height:7px;',
        '%moreContainer:after': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 100%;left:-7px;bottom:-7px;right:-7px;height:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SolidColorMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'SIZE',
        menuTotalBordersY: 'SIZE',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        menuTotalBordersX: ['brw', 'brw'],
        menuTotalBordersY: ['brw', 'brw'],
        bg: 'color_11',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brw: '0',
        brd: 'color_15',
        bgDrop: 'color_11',
        rdDrop: '10px'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;width:calc(100% - [menuTotalBordersX]);background-color:[bg];[rd]  [shd]  border:solid [brw] [brd];position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rdDrop]  [shd]  border:solid [brw] [brd];',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;margin-top:7px;visibility:hidden;position:absolute;box-sizing:border-box;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonBgFixSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextOnlyMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;',
        '%dropWrapper[data-dropMode="dropUp"]': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextOnlyMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bgDrop: 'color_11',
        rd: '0',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%dropWrapper[data-dropMode="dropUp"]': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.TextSeparatorsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        bgDrop: 'color_11',
        rd: '0',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:absolute;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[rd]  [shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;',
        '% > nav': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.common.components.dropdownmenu.viewer.skins.VerticalRibbonsMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_bar'], {}],
            ['ul', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['ul', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.VerticalRibbonsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        menuTotalBordersX: 'WIDTH_SIZE',
        menuTotalBordersY: 'HEIGHT_SIZE',
        els: 'SIZE',
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        bgDrop: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        menuTotalBordersX: '0',
        menuTotalBordersY: '0',
        els: '10px',
        bg: 'color_11',
        shd: '0 2px 3px rgba(0, 0, 0, 0.7)',
        bgDrop: 'color_11'
    },
    css: {
        '%itemsContainer':
            'width:calc(100% - [menuTotalBordersX]);height:calc(100% - [menuTotalBordersY]);white-space:nowrap;display:inline-block;overflow:visible;position:relative;',
        '%moreContainer': 'overflow:visible;display:inherit;white-space:nowrap;width:auto;background-color:[bgDrop];[shd]',
        '%dropWrapper': 'z-index:99999;display:block;opacity:1;visibility:hidden;position:absolute;margin-top:7px;box-sizing:border-box;',
        '%_bar': 'position:absolute;top:[els];right:0;bottom:[els];left:0;background-color:[bg];[shd]',
        '%[data-dropmode="dropUp"] %dropWrapper': 'margin-top:0;margin-bottom:7px;'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.DiagonalMenuSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
        ]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.DiagonalMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        bg: 'color_11',
        brw: '0',
        brd: 'color_15',
        bgDrop: 'color_11',
        rdDrop: '10px'
    },
    css: {
        '%itemsContainer': 'background-color:[bg];border:solid [brw] [brd];position:relative;overflow:hidden;',
        '%dropWrapper': 'margin-top:7px;visibility:hidden;position:absolute;box-sizing:border-box;',
        '%dropWrapper[data-dropMode="dropUp"]': 'margin-top:0;margin-bottom:7px;',
        '%moreContainer': 'background-color:[bgDrop];[rdDrop]  border:solid [brw] [brd];'
    }
}
skins['wysiwyg.viewer.skins.menu.ArrowsMenuSkin'] = {
    react: [['nav', 'navContainer', [], {}, ['div', 'itemsContainer', [], {}], ['div', 'moreButton', [], {}], ['div', 'moreContainer', [], {}]]],
    exports: {
        minH: 40,
        maxH: 40,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.menubutton.ArrowsMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    css: {
        '%itemsContainer': 'overflow:hidden;',
        '%moreContainer': 'position:absolute;z-index:99999;'
    }
}
skins['wysiwyg.viewer.skins.menu.CirclesMenuSkin'] = {
    react: [['nav', 'navContainer', [], {}, ['div', 'itemsContainer', [], {}], ['div', 'moreButton', [], {}], ['div', 'moreContainer', [], {}]]],
    exports: {
        minH: 140,
        maxH: 140,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.menubutton.CirclesMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    css: {
        '%itemsContainer': 'position:relative;overflow:hidden;',
        '%moreContainer': 'position:absolute;z-index:99999;'
    }
}
skins['wysiwyg.viewer.skins.menu.SeparateArrowDownMenuSkin'] = {
    react: [['nav', 'navContainer', [], {}, ['div', 'itemsContainer', [], {}], ['div', 'moreButton', [], {}], ['div', 'moreContainer', [], {}]]],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.menubutton.SeparateArrowDownMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        pad: 'PADDING_SIZE'
    },
    paramsDefaults: {
        pad: '5px'
    },
    css: {
        '*': 'box-sizing:border-box;',
        '%itemsContainer': 'position:relative;overflow:hidden;',
        '%moreContainer': 'position:absolute;z-index:99999;',
        '%moreContainer > *': 'margin-top:10px;padding:0 [pad] !important;'
    }
}
skins['wysiwyg.viewer.skins.menu.SeparatedArrowsMenuSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', 'itemsContainer', [], {}],
            ['div', 'moreButton', [], {}],
            ['div', null, ['_deprect'], {}],
            ['div', 'moreContainer', [], {}]
        ]
    ],
    exports: {
        minH: 40,
        maxH: 40,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.menubutton.SeparatedArrowsMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        tdr: 'URL'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%_deprect': 'position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;',
        '%itemsContainer': 'overflow:hidden;background:transparent !important;',
        '%moreContainer': 'position:absolute;z-index:99999;'
    }
}
skins['wysiwyg.viewer.skins.menubutton.ArrowsMenuButtonSkin'] = {
    react: [
        ['nav', 'navContainer', [], {}, ['div', null, ['_arrowHead'], {}], ['div', null, ['_arrowTail'], {}], ['div', 'bg', [], {}, ['div', 'label', [], {}]]]
    ],
    exports: {
        minH: 40,
        maxH: 40
    },
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1: 'TRANSITION',
        pad: 'MARGIN_SIZE',
        trans3: 'TRANSITION',
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11',
        fnt: 'font_1',
        txt: 'color_15',
        trans1: 'color 0.5s ease 0s, background 0.5s ease 0s',
        pad: '5px',
        trans3: 'color 0.5s ease 0s, border-color 0.5s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18'
    },
    css: {
        '%': 'display:inline-block;cursor:pointer;padding:0 15px;background:[bg];[fnt]  color:[txt];[trans1]  width:auto !important;height:40px !important;min-height:40px !important;line-height:40px !important;margin:0 [pad] 0 11px;text-align:left;',
        '%_arrowHead':
            'border:20px solid transparent;border-left-color:[bg];border-left-width:10px;border-right-width:10px;position:absolute;top:0;right:-20px;[trans3]',
        '%_arrowTail':
            'border:20px solid [bg];border-left-color:transparent;border-left-width:10px;border-right-width:10px;position:absolute;top:0;left:-10px;[trans3]',
        '%[data-listposition="first"] %_arrowTail': 'display:none;',
        '%[data-listposition="first"]': 'margin-left:-5px;border-radius:3px 0 0 3px;',
        '%[data-listposition="last"] %_arrowHead': 'display:none;',
        '%[data-listposition="last"]': 'border-radius:0 3px 3px 0;',
        '%label': 'height:40px !important;line-height:40px !important;position:relative;',
        '%:hover': 'background:[bgh];color:[txth];',
        '%:hover %_arrowHead': 'border-left-color:[bgh];',
        '%:hover %_arrowTail': 'border-color:[bgh];border-left-color:transparent;',
        '%[data-state="selected"]': 'background:[bgs];color:#ff0000;',
        '%[data-state="selected"] %_arrowHead': 'border-left-color:[bgs];',
        '%[data-state="selected"] %_arrowTail': 'border-color:[bgs];border-left-color:transparent;'
    }
}
skins['wysiwyg.viewer.skins.menubutton.CirclesMenuButtonSkin'] = {
    react: [['nav', 'navContainer', [], {}, ['div', null, ['_bg'], {}], ['div', 'label', [], {}]]],
    exports: {
        minH: 140,
        maxH: 140
    },
    params: {
        fnt: 'FONT',
        trans: 'TRANSITION',
        txt: 'TEXT_COLOR',
        clr5: 'BG_COLOR_ALPHA',
        clr2: 'BG_COLOR_ALPHA',
        clr3: 'BG_COLOR_ALPHA',
        clr4: 'BG_COLOR_ALPHA',
        clr1: 'BG_COLOR_ALPHA',
        clr6: 'BG_COLOR_ALPHA',
        txtac: 'TEXT_COLOR'
    },
    paramsDefaults: {
        fnt: 'font_2',
        trans: 'color 0.4s ease 0s, background-color 0.4s ease 0s',
        txt: 'color_15',
        clr5: 'color_9',
        clr2: 'color_2',
        clr3: 'color_3',
        clr4: 'color_4',
        clr1: 'color_1',
        clr6: 'color_15',
        txtac: 'color_7'
    },
    css: {
        '%': 'display:inline-block;cursor:pointer;[fnt]  font-size:150%;text-transform:uppercase;[trans]  margin:0 0 0 -3%  !important;color:[txt];',
        '%_bg': 'border-radius:50%;cursor:pointer;display:inline-block;position:absolute;min-height:140px !important;min-width:140px !important;',
        '%_bg:hover': 'opacity:1;',
        '%:nth-child(n+1) %_bg': 'background:[clr5];',
        '%:nth-child(n+2) %_bg': 'background:[clr2];',
        '%:nth-child(n+3) %_bg': 'background:[clr3];',
        '%:nth-child(n+4) %_bg': 'background:[clr4];',
        '%:nth-child(n+5) %_bg': 'background:[clr1];',
        '%:nth-child(n+6) %_bg': 'background:[clr6];',
        '%_bg:active': 'color:[txtac];opacity:0.5;'
    }
}
skins['wysiwyg.viewer.skins.menubutton.SeparateArrowDownMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            [
                'div',
                null,
                ['_wrapper'],
                {},
                ['div', null, ['_sq'], {}],
                [
                    'div',
                    null,
                    ['_arrowUp'],
                    {},
                    ['div', null, ['_leftMask'], {}, ['div', null, ['_left'], {}]],
                    ['div', null, ['_rightMask'], {}, ['div', null, ['_right'], {}]]
                ],
                [
                    'div',
                    null,
                    ['_arrowDown'],
                    {},
                    ['div', null, ['_leftMask'], {}, ['div', null, ['_left'], {}]],
                    ['div', null, ['_rightMask'], {}, ['div', null, ['_right'], {}]]
                ]
            ],
            ['div', 'bg', [], {}, ['div', 'label', [], {}]]
        ]
    ],
    params: {
        pad: 'MARGIN_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        trans3: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        brds: 'BORDER_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        trans3: 'border-color 0.4s ease 0s',
        bgh: 'color_17',
        trans: 'background-color 0.4s ease 0s',
        brdh: 'color_15',
        txth: 'color_15',
        bgs: 'color_18',
        brds: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'display:inline-block;position:relative;margin:0 [pad];',
        '%bg': 'width:100%;height:100%;padding:75px;',
        '%label': 'padding:0 10px;[fnt]  color:[txt];[trans2]  position:relative;',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_sq': 'position:absolute;top:0;left:0;right:0;bottom:70px;background:#ff0000;',
        '%_arrowUp': 'position:absolute;bottom:35px;left:0;right:0;height:35px;',
        '%_arrowUp %_leftMask': 'position:absolute;right:50%;left:0;top:0;bottom:0;background:green;overflow:hidden;',
        '%_arrowUp %_left':
            'border-left:140px solid #ff0000;border-bottom:35px solid transparent;width:0;height:0;position:absolute;right:0;top:0;bottom:0;[trans3]',
        '%_arrowUp %_rightMask': 'position:absolute;right:0;left:50%;top:0;bottom:0;background:yellow;overflow:hidden;',
        '%_arrowUp %_right':
            'border-right:140px solid #ff0000;border-bottom:35px solid transparent;width:0;height:0;position:absolute;left:0;top:0;bottom:0;[trans3]',
        '%_arrowDown': 'position:absolute;bottom:0;left:0;right:0;height:35px;',
        '%_arrowDown %_leftMask': 'position:absolute;right:50%;left:0;top:0;bottom:0;background:#000000;overflow:hidden;',
        '%_arrowDown %_left':
            'border-right:140px solid #ff0000;border-bottom:35px solid transparent;width:0;height:0;position:absolute;left:0;top:0;bottom:0;[trans3]',
        '%_arrowDown %_rightMask': 'position:absolute;right:0;left:50%;top:0;bottom:0;background:#0000ff;overflow:hidden;',
        '%_arrowDown %_right':
            'border-left:140px solid #ff0000;border-bottom:35px solid transparent;width:0;height:0;position:absolute;right:0;top:0;bottom:0;[trans3]',
        '%:first-child': 'padding-left:0;',
        '%:last-child': 'padding-right:0;',
        '%:hover %_wrapper': 'background-color:[bgh];[trans]',
        '%:hover %borderWrapper': 'border-color:[brdh];[trans3]',
        '%:hover %label': 'color:[txth];[trans2]',
        '%[data-state="selected"] %_wrapper': 'background-color:[bgs];[trans]',
        '%[data-state="selected"] %borderWrapper': 'border-color:[brds];[trans3]',
        '%[data-state="selected"] %label': 'color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.menubutton.SeparatedArrowsMenuButtonSkin'] = {
    react: [
        [
            'nav',
            'navContainer',
            [],
            {},
            ['div', null, ['_head'], {}, ['div', null, ['_arrowHead'], {}]],
            ['div', null, ['_tail'], {}, ['div', null, ['_arrowTail'], {}]],
            ['div', 'label', [], {}]
        ]
    ],
    exports: {
        minH: 40,
        maxH: 40
    },
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1: 'TRANSITION',
        brw: 'BORDER_SIZE',
        pad: 'MARGIN_SIZE',
        shd: 'BOX_SHADOW',
        trans3: 'TRANSITION',
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BORDER_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        brwA: 'SIZE'
    },
    paramsDefaults: {
        bg: 'color_11',
        fnt: 'font_1',
        txt: 'color_15',
        trans1: 'color 0.4s ease 0s, background 0.4s ease 0s',
        brw: '10px',
        pad: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans3: 'color 0.4s ease 0s, border-color 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        brwA: ['brw']
    },
    paramsMutators: {
        brwA: {
            type: 'multiply',
            value: 2,
            param: 'brw'
        }
    },
    css: {
        '%': 'display:inline-block;cursor:pointer;background:[bg];[fnt]  color:[txt];[trans1]  margin:0 [brw] 0 [pad] !important;[shd]',
        '%_head': 'position:absolute;top:0;bottom:0;right:calc(-1 * [brw]);width:[brw];',
        '%_tail': 'position:absolute;top:0;bottom:0;left:calc(-1 * [brw]);width:[brwA];',
        '%_arrowHead': 'border:20px solid transparent;border-left-color:[bg];border-left-width:[brw];border-right-width:[brw];[trans3]',
        '%_arrowTail': 'border:20px solid [bg];border-left-color:transparent;border-left-width:[brw];border-right-width:[brw];[trans3]',
        '%[data-listposition="first"] %_arrowTail': 'display:none;',
        '%[data-listposition="first"]': 'border-bottom-right-radius:0;border-top-right-radius:0;margin-left:-5px;',
        '%[data-listposition="last"] %_arrowHead': 'display:none;',
        '%[data-listposition="last"]': 'border-bottom-left-radius:0;border-top-left-radius:0;',
        '%label': 'height:40px !important;line-height:40px !important;position:relative;padding:0 15px;',
        '%:hover': 'background:[bgh];color:[txth];',
        '%:hover %_arrowHead': 'border-left-color:[bgh];',
        '%:hover %_arrowTail': 'border-color:[bgh];border-left-color:transparent;',
        '%[data-state="selected"]': 'background:[bgs];color:[txts];',
        '%[data-state="selected"] %_arrowHead': 'border-left-color:[bgs];',
        '%[data-state="selected"] %_arrowTail': 'border-color:[bgs];border-left-color:transparent;'
    }
}
skins['wixapps.integration.skins.ecommerce.options.InfoTipSkin'] = {
    react: [['div', null, ['_toolTipContainer'], {}, ['p', 'content', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        txtcolor: 'TEXT_COLOR',
        bgcolor: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        rd: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, .6);',
        txtcolor: '#656565',
        bgcolor: '#fffedf'
    },
    css: {
        '%': 'text-align:center;font-family:"Helvetica Neue", "HelveticaNeueW01-55Roma", "HelveticaNeueW02-55Roma", "HelveticaNeueW10-55Roma", Helvetica, Arial, sans-serif;font-size:12px;line-height:16px;[rd]  [shd]  color:[txtcolor];background:[bgcolor];position:absolute;padding:5px;top:0;left:0;min-height:10px;min-width:10px;max-width:300px;',
        '% p': 'display:block;margin:0 0 5px 0;',
        '% strong,% b,% i,% big': 'font-size:12px;',
        '%[data-state~="hidden"]': 'visibility:hidden !important;'
    }
}
skins['wysiwyg.viewer.skins.option.InfoTipArrowSkin'] = {
    react: [['div', null, ['_toolTipContainer'], {}, ['p', 'content', ['_content'], {}], ['div', null, ['_arrow'], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        tdr: 'URL'
    },
    paramsDefaults: {
        rd: '5px',
        shd: '0 1px 3px rgba(0, 0, 0, 0.25)',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '% p': 'display:block;margin:0;',
        '% p%_content':
            'font-family:"Helvetica Neue", "HelveticaNeueW01-55Roma", "HelveticaNeueW02-55Roma", "HelveticaNeueW10-55Roma", Helvetica, Arial, sans-serif;font-size:11px;line-height:16px;[rd]    [shd]    color:#666;padding:20px 10px;top:0;left:0;box-shadow:rgba(0, 0, 0, 0.247059) 0 1px 3px;border:1px solid #999;background:-webkit-linear-gradient(bottom, rgba(207, 229, 250, 0.1) 0, #fff 10px);background:linear-gradient(to top, rgba(207, 229, 250, 0.1) 0, #fff 10px);background-position:bottom;max-width:400px;position:relative;display:inline-block;overflow:hidden;',
        '%_arrow': 'position:absolute;bottom:-6px;left:1px;width:14px;height:9px;background:url([tdr]light-arrows.png) no-repeat -5px -5px;',
        '% strong,% b,% i,% big': 'font-size:12px;',
        '%[data-state~="hidden"]': 'visibility:hidden !important;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ArrowRightMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_arrow'],
                {},
                ['div', null, ['_tail'], {}, ['div', null, ['_tailTop'], {}], ['div', null, ['_tailBottom'], {}]],
                ['div', null, ['_sq', '_dropSizeTarget'], {}],
                ['div', null, ['_head', '_dropSizeTarget'], {}, ['div', null, ['_headTop'], {}], ['div', null, ['_headBottom'], {}]]
            ],
            ['div', 'bg', [], {}, ['p', 'label', [], {}]],
            ['div', 'hitArea', [], {}]
        ]
    ],
    params: {
        pad: 'MARGIN_LEFT_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bg: 'BORDER_COLOR_ALPHA',
        trans: 'TRANSITION',
        trans3: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        bgh: 'BORDER_COLOR_ALPHA',
        bgs: 'BORDER_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '1',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        trans3: 'border-color 0.4s ease 0s',
        rd: '10px',
        bgh: 'color_17',
        bgs: 'color_18',
        rdDrop: '10px',
        txth: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;position:relative;margin-left:calc([pad] - 70px);box-sizing:border-box;height:100%;pointer-events:none;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'height:100%;position:relative;',
        '%label': 'display:inline-block;margin:0 20px;padding:0 10px;[fnt]  color:[txt];[trans2]  text-indent:60px;',
        '%hitArea': 'position:absolute;top:0;left:70px;bottom:0;right:0;cursor:pointer;  background:#fff;opacity:0;pointer-events:auto;',
        '%_arrow': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_sq': 'position:absolute;top:0;left:70px;bottom:0;right:70px;background-color:[bg];[trans]',
        '%_tail': 'background-color:transparent;[trans]  width:70px;position:absolute;top:0;left:0;bottom:0;overflow:hidden;',
        '%_tailTop': 'border-top:140px solid [bg];border-left:70px solid transparent;width:0;height:0;position:absolute;bottom:50%;right:0;[trans3]',
        '%_tailBottom': 'border-bottom:140px solid [bg];border-left:70px solid transparent;width:0;height:0;position:absolute;top:50%;right:0;[trans3]',
        '%_head': 'background-color:transparent;[trans]  width:70px;position:absolute;top:0;right:0;bottom:0;overflow:hidden;',
        '%_headTop': 'border-bottom:140px solid [bg];border-right:70px solid transparent;width:0;height:0;position:absolute;bottom:50%;left:0;[trans3]',
        '%_headBottom': 'border-top:140px solid [bg];border-right:70px solid transparent;width:0;height:0;position:absolute;top:50%;left:0;[trans3]',
        '%[data-listposition="left"]': 'margin-left:0;',
        '%[data-listposition="left"] %label': 'display:inline-block;text-indent:0;',
        '%[data-listposition="left"] %_tail': 'background-color:[bg];[rd]  border-bottom-right-radius:0;border-top-right-radius:0;width:auto;right:70px;',
        '%[data-listposition="left"] %_tailTop,%[data-listposition="left"] %_tailBottom': 'display:none;',
        '%[data-listposition="left"] %hitArea': 'left:0;border:solid 1px #f00;',
        '%[data-listposition="right"] %_head': 'background-color:[bg];[rd]  border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-listposition="right"] %_headTop,%[data-listposition="right"] %_headBottom': 'display:none;',
        '%[data-state~="over"][data-listposition="right"] %_head,%[data-preview~="hover"] %_head': 'background-color:[bgh];[trans]',
        '%[data-state~="selected"][data-listposition="right"] %_head,%[data-preview~="active"] %_head': 'background-color:[bgs];[trans]',
        '%[data-state~="drop"]': 'display:block;background-color:[bg];[trans]  margin:0 !important;background-color:transparent;overflow:hidden;',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;text-indent:0 !important;margin:0 !important;',
        '%[data-state~="drop"] %_arrow': 'display:none !important;',
        '%[data-state~="drop"] %_head': 'display:none;',
        '%[data-state~="drop"] %hitArea': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-listposition="dropLonely"]': '[rdDrop]',
        '%[data-listposition="top"]': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"]': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"],%[data-preview~="hover"]': '[trans]  background-color:[bgh];',
        '%[data-state~="drop"][data-state~="selected"],%[data-preview~="active"]': '[trans]  background-color:[bgs];',
        '%[data-state~="over"] %_sq,%[data-preview~="hover"],%[data-preview~="hover"] %_sq': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %_tailTop,%[data-preview~="hover"] %_tailTop': 'border-top-color:[bgh];[trans3]',
        '%[data-state~="over"] %_tailBottom,%[data-preview~="hover"] %_tailBottom': 'border-bottom-color:[bgh];[trans3]',
        '%[data-state~="over"] %_headTop,%[data-preview~="hover"] %_headTop': 'border-bottom-color:[bgh];[trans3]',
        '%[data-state~="over"] %_headBottom,%[data-preview~="hover"] %_headBottom': 'border-top-color:[bgh];[trans3]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-listposition="left"][data-state~="over"] %_tail,%[data-preview~="hover"] %_tail': 'background-color:[bgh];[trans]',
        '%[data-state~="selected"] %_sq,%[data-preview~="active"] %_sq': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %_tailTop,%[data-preview~="active"] %_tailTop': 'border-top-color:[bgs];[trans3]',
        '%[data-state~="selected"] %_tailBottom,%[data-preview~="active"] %_tailBottom': 'border-bottom-color:[bgs];[trans3]',
        '%[data-state~="selected"] %_headTop,%[data-preview~="active"] %_headTop': 'border-bottom-color:[bgs];[trans3]',
        '%[data-state~="selected"] %_headBottom,%[data-preview~="active"] %_headBottom': 'border-top-color:[bgs];[trans3]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-state~="selected"][data-listposition="left"] %_tail,%[data-preview~="active"] %_tail': 'background-color:[bgs];[trans]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ArrowsMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_arrowHead'], {}], ['div', null, ['_arrowTail'], {}], ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    exports: {
        minH: 40,
        maxH: 40
    },
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1: 'TRANSITION',
        pad: 'MARGIN_SIZE',
        trans3: 'TRANSITION',
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11',
        fnt: 'font_1',
        txt: 'color_15',
        trans1: 'color 0.5s ease 0s, background 0.5s ease 0s',
        pad: '5px',
        trans3: 'color 0.5s ease 0s, border-color 0.5s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;padding:0 15px;background:[bg];[fnt]  color:[txt];[trans1]  width:auto !important;height:40px !important;min-height:40px !important;line-height:40px !important;margin:0 [pad] 0 11px;text-align:left;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_arrowHead':
            'border:20px solid transparent;border-left-color:[bg];border-left-width:10px;border-right-width:10px;position:absolute;top:0;right:-20px;[trans3]',
        '%_arrowTail':
            'border:20px solid [bg];border-left-color:transparent;border-left-width:10px;border-right-width:10px;position:absolute;top:0;left:-10px;[trans3]',
        '%[data-listposition="left"] %_arrowTail': 'display:none;',
        '%[data-listposition="left"]': 'margin-left:-5px;border-radius:3px 0 0 3px;',
        '%[data-listposition="right"] %_arrowHead': 'display:none;',
        '%[data-listposition="right"]': 'border-radius:0 3px 3px 0;',
        '%label': 'display:inline-block;height:40px !important;line-height:40px !important;position:relative;',
        '%[data-state="over"],%[data-preview~="hover"]': 'background:[bgh];color:[txth];',
        '%[data-state="over"] %_arrowHead,%[data-preview~="hover"] %_arrowHead': 'border-left-color:[bgh];',
        '%[data-state="over"] %_arrowTail,%[data-preview~="hover"] %_arrowTail': 'border-color:[bgh];border-left-color:transparent;',
        '%[data-state="selected"],%[data-preview~="active"]': 'background:[bgs];color:#f00;',
        '%[data-state="selected"] %_arrowHead,%[data-preview~="active"] %_arrowHead': 'border-left-color:[bgs];',
        '%[data-state="selected"] %_arrowTail,%[data-preview~="active"] %_arrowTail': 'border-color:[bgs];border-left-color:transparent;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.CirclesMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'borderWrapper', [], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]]],
    params: {
        pad: 'PADDING_SIZE',
        rd: 'BORDER_RADIUS',
        brw: 'PADDING_SIZE',
        brd: 'BG_COLOR_ALPHA',
        trans2: 'TRANSITION',
        bg: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1: 'TRANSITION',
        brdh: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        brds: 'BG_COLOR_ALPHA',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        nord: 'BORDER_RADIUS',
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        pad: '5px',
        rd: '50%',
        brw: '0',
        brd: 'color_15',
        trans2: 'background-color 0.4s ease 0s',
        bg: 'color_11',
        fnt: 'font_1',
        txt: 'color_15',
        trans1: 'color 0.4s ease 0s',
        brdh: 'color_15',
        bgh: 'color_17',
        txth: 'color_15',
        brds: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        nord: '0',
        rdDrop: '10px'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'margin:0 0 0 -5%;padding:0 [pad];',
        '%borderWrapper': '[rd]  cursor:pointer;padding:[brw];background-color:[brd];[trans2]',
        '%bg': '[rd]  background-color:[bg];[trans2]',
        '%label': 'display:inline-block;[fnt]  padding:0 5%;color:[txt];[trans1]',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-state="over"] %borderWrapper,%[data-preview~="hover"] %borderWrapper': 'background-color:[brdh];[trans2]',
        '%[data-state="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];[trans2]',
        '%[data-state="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans1]',
        '%[data-state="selected"] %borderWrapper,%[data-preview~="active"] %borderWrapper': 'background-color:[brds];[trans2]',
        '%[data-state="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];[trans2]',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans1]',
        '%[data-container="drop"]': 'width:100%;display:block;',
        '%[data-container="drop"] %_gapper': '[nord]  margin:0;padding:0;',
        '%[data-container="drop"] %borderWrapper': 'background:none;padding:0;[nord]',
        '%[data-container="drop"] %bg': 'background-color:transparent;[nord]  [trans2]',
        '%[data-container="drop"] %label': 'padding:0 .5em;',
        '%[data-listposition="dropLonely"] %bg': '[rdDrop]',
        '%[data-listposition="top"] %bg': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %bg': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-container="drop"][data-state="over"] %borderWrapper,%[data-preview~="hover"] %borderWrapper': 'background:none;',
        '%[data-container="drop"][data-state="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];[trans2]',
        '%[data-container="drop"][data-state="selected"] %borderWrapper,%[data-preview~="active"] %borderWrapper': 'background:none;',
        '%[data-container="drop"][data-state="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.DiagonalMenuButtonSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_arrow'],
                {},
                ['div', null, ['_tail'], {}, ['div', null, ['_tailTop'], {}]],
                ['div', null, ['_sq', '_dropSizeTarget'], {}],
                ['div', null, ['_head', '_dropSizeTarget'], {}, ['div', null, ['_headTop'], {}]]
            ],
            ['div', 'bg', [], {}, ['p', 'label', [], {}]],
            ['div', 'hitArea', [], {}]
        ]
    ],
    params: {
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        txts: 'BORDER_COLOR_ALPHA',
        trans3: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BORDER_COLOR_ALPHA',
        bgs: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        txts: 'color_15',
        trans3: 'border-color 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        bgs: 'color_18',
        txth: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;position:relative;pointer-events:none;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'height:100%;position:relative;',
        '%label': 'display:inline-block;margin:0 20px;padding:0 10px;[fnt]  color:[txt];[trans2]  text-indent:60px;',
        '%hitArea': 'position:absolute;top:0;left:30px;bottom:0;right:0;cursor:pointer;  background:#fff;opacity:0;pointer-events:auto;',
        '%_arrow': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_sq': 'position:absolute;top:0;left:30px;bottom:0;right:0;background-color:[bg];[trans]',
        '%_tail': 'background-color:transparent;[trans]  width:60px;position:absolute;top:0;left:-30px;bottom:0;overflow:hidden;',
        '%_tailTop': 'border-bottom:150px solid [txts];border-left:60px solid transparent;width:0;height:0;position:absolute;[trans3]',
        '%_head': 'background-color:transparent;[trans]  width:30px;position:absolute;top:0;right:0;bottom:0;overflow:hidden;',
        '%_headTop': 'border-top:150px solid [txts];border-right:60px solid transparent;width:0;height:0;position:absolute;bottom:50%;left:0;[trans3]',
        '%[data-container="drop"]': 'width:100%;display:block;background-color:[bg];[trans]  margin-left:0;background-color:transparent;',
        '%[data-container="drop"] %label': 'display:inline-block;padding:0 .5em;text-indent:0;margin:0;',
        '%[data-container="drop"] %_arrow,%[data-container="drop"] %_head': 'display:none;',
        '%[data-container="drop"] %hitArea': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-listposition="dropLonely"]': '[rdDrop]',
        '%[data-listposition="top"]': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"]': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-container="drop"][data-state="over"],%[data-preview~="hover"]': '[trans]  background-color:[bgh];',
        '%[data-container="drop"][data-state="selected"],%[data-preview~="active"]': '[trans]  background-color:[bgs];',
        '%[data-state="over"] %_sq,%[data-preview~="hover"] %_sq': 'background-color:[bgh];[trans]',
        '%[data-state="over"] %_tailTop,%[data-preview~="hover"] %_tailTop': 'border-top-color:[bgh];[trans3]',
        '%[data-state="over"] %_headTop,%[data-preview~="hover"] %_headTop': 'border-bottom-color:[bgh];[trans3]',
        '%[data-state="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-listposition="left"][data-state="over"] %_tail,%[data-preview~="hover"] %_tail': 'background-color:[bgh];[trans]',
        '%[data-state="selected"] %_sq,%[data-preview~="active"] %_sq': 'background-color:[bgs];[trans]',
        '%[data-state="selected"] %_tailTop,%[data-preview~="active"] %_tailTop': 'border-top-color:[bgs];[trans3]',
        '%[data-state="selected"] %_headTop,%[data-preview~="active"] %_headTop': 'border-bottom-color:[bgs];[trans3]',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-state="selected"][data-listposition="left"] %_tail,%[data-preview~="active"] %_tail': 'background-color:[bgs];[trans]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ForkedBannerMenuButtonSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    params: {
        trans: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        bgs: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        brw: 'BORDER_TOP_SIZE',
        brd: 'COLOR',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        bgh: 'color_17',
        bgs: 'color_18',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        brw: '1px',
        brd: 'color_17',
        txth: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'background-color:transparent;[trans]',
        '%[data-state="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];',
        '%[data-state="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];background-color:[bgs];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-container="drop"]': 'display:block;background-color:transparent;[trans]',
        '%[data-container="drop"]:after': 'content:"";position:absolute;top:0;left:0;right:0;border-top:[brw] solid [brd];',
        '%[data-container="drop"]:first-child:after': 'border:0;',
        '%[data-state="over"][data-container="drop"],%[data-preview~="hover"]': 'background-color:[bgh];[trans]',
        '%[data-state="over"][data-container="drop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%bg:hover': 'background-color:[bgh];',
        '%bg:hover %label': 'color:[txth];',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];',
        '%[data-state="selected"][data-container="drop"],%[data-preview~="active"]': 'background-color:[bgs];',
        '%[data-state="selected"][data-container="drop"] %label,%[data-preview~="active"] %label': 'color:[txts];background-color:[bgs];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ForkedRibbonMenuButtonSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    params: {
        trans: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        bgs: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        brw: 'BORDER_TOP_SIZE',
        brd: 'COLOR',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        bgh: 'color_17',
        bgs: 'color_18',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        brw: '1px',
        brd: 'color_17',
        txth: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'background-color:transparent;[trans]',
        '%[data-state="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];',
        '%[data-state="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];background-color:[bgs];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-container="drop"]': 'display:block;background-color:transparent;[trans]',
        '%[data-container="drop"]:after': 'content:"";position:absolute;top:0;left:0;right:0;border-top:[brw] solid [brd];',
        '%[data-container="drop"]:first-child:after': 'border:0;',
        '%[data-state="over"][data-container="drop"],%[data-preview~="hover"]': 'background-color:[bgh];[trans]',
        '%[data-state="over"][data-container="drop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%bg:hover': 'background-color:[bgh];',
        '%bg:hover %label': 'color:[txth];',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];',
        '%[data-state="selected"][data-container="drop"],%[data-preview~="active"]': 'background-color:[bgs];',
        '%[data-state="selected"][data-container="drop"] %label,%[data-preview~="active"] %label': 'color:[txts];background-color:[bgs];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.IndentedMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_extra'], {}, ['div', null, ['_wrapper'], {}]], ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    params: {
        sep: 'COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        tdr: 'URL',
        trans3: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        rdDrop: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        childrenSpacing: 'PADDING_SIZE'
    },
    paramsDefaults: {
        sep: 'color_15',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        tdr: 'BASE_THEME_DIRECTORY',
        trans3: 'background-image 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        rd: '10px',
        rdDrop: '10px',
        trans1: 'opacity 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        childrenSpacing: ['pad']
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;border-left:1px solid [sep];background:[bg];[trans]  padding:0 [pad];box-sizing:border-box;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%:first-child': 'border:0;',
        '%_extra,%_wrapper': 'background:transparent url([tdr]indented_bg_inverted.png) repeat-x;[trans3]  position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_extra': 'background-position:center top;',
        '%_wrapper': 'background-position:center bottom;',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%bg': '[rd]',
        '%[data-listposition="lonely"],%[data-listposition="left"],%[data-listposition="right"]': '[rd]',
        '%[data-listposition="lonely"] %_wrapper,%[data-listposition="lonely"] %_extra,%[data-listposition="left"] %_wrapper,%[data-listposition="left"] %_extra,%[data-listposition="right"] %_wrapper,%[data-listposition="right"] %_extra':
            '[rd]',
        '%[data-listposition="left"],%[data-listposition="left"] %_wrapper,%[data-listposition="left"] %_extra':
            'border-bottom-right-radius:0;border-top-right-radius:0;',
        '%[data-listposition="right"],%[data-listposition="right"] %_wrapper,%[data-listposition="right"] %_extra':
            'border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-state~="drop"]': 'display:block;border:0;background-color:transparent;padding:0;[trans]',
        '%[data-state~="drop"] %bg': '[rdDrop]',
        '%[data-state~="drop"] %_wrapper,%[data-state~="drop"] %_extra': 'opacity:0;',
        '%[data-state~="drop"] %_extra': '[trans1]    width:auto;',
        '%[data-state~="drop"] %label': 'padding:0 .5em;',
        '%[data-listposition="dropLonely"],%[data-listposition="top"],%[data-listposition="bottom"]': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_extra,%[data-listposition="dropLonely"] %_wrapper,%[data-listposition="top"] %_extra,%[data-listposition="top"] %_wrapper,%[data-listposition="bottom"] %_extra,%[data-listposition="bottom"] %_wrapper':
            '[rdDrop]',
        '%[data-listposition="top"],%[data-listposition="top"] %_extra,%[data-listposition="top"] %_wrapper':
            'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"],%[data-listposition="bottom"] %_extra,%[data-listposition="bottom"] %_wrapper':
            'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_extra,%[data-state~="drop"][data-state~="over"] %_wrapper,%[data-state~="drop"][data-preview~="hover"] %_extra,%[data-state~="drop"][data-preview~="hover"] %_wrapper':
            'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_extra,%[data-state~="drop"][data-state~="selected"] %_wrapper,%[data-state~="drop"][data-preview~="active"] %_extra,%[data-state~="drop"][data-preview~="active"] %_wrapper':
            'opacity:1;[trans1]',
        '%[data-state~="over"],%[data-preview~="hover"]': '[trans]  background:[bgh];',
        '%[data-state~="over"] %_extra,%[data-state~="over"] %_wrapper,%[data-preview~="hover"] %_extra,%[data-preview~="hover"] %_wrapper':
            '[trans3]    background:transparent url([tdr]indented_bg_inverted.png) repeat-x;',
        '%[data-state~="over"] %_extra,%[data-preview~="hover"] %_extra': 'background-position:center top;',
        '%[data-state~="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'background-position:center bottom;',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': '[trans2]    color:[txth];',
        '%[data-state~="selected"],%[data-preview~="active"]': '[trans]  background:[bgs];',
        '%[data-state~="selected"] %_extra,%[data-state~="selected"] %_wrapper,%[data-preview~="active"] %_extra,%[data-preview~="active"] %_wrapper':
            '[trans3]    background:transparent url([tdr]indented_bg.png) repeat-x;',
        '%[data-state~="selected"] %_extra,%[data-preview~="active"] %_extra': 'background-position:center top;',
        '%[data-state~="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'background-position:center bottom;',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': '[trans2]    color:[txts];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNBorderRadiusFixSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        rd: '10px'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;background:transparent;[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-listposition="top"]': '[rd]  border-bottom-left-radius:0;border-bottom-right-radius:0;border-radius:0;',
        '%[data-listposition="bottom"]': '[rd]  border-top-left-radius:0;border-top-right-radius:0;border-radius:0;',
        '%[data-listposition="dropLonely"]': '[rd]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.LinesMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        rd: '10px'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;background:transparent;[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-listposition="top"]': '[rd]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"]': '[rd]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="dropLonely"]': '[rd]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonHorizontalMenuAdaptationSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', null, ['_wrapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]]],
    params: {
        pad: 'PADDING_SIZE',
        fnt: 'FONT',
        brd: 'COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brdh: 'COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        brds: 'COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        fnt: 'font_1',
        brd: 'color_15',
        txt: 'color_15',
        brdh: 'color_15',
        txth: 'color_15',
        brds: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%_wrapper': 'cursor:pointer;[fnt]  border-top:1px solid [brd];',
        '%label': 'color:[txt];display:inline-block;padding:8px;',
        '%[data-listposition="left"]': 'padding-left:0;',
        '%[data-listposition="right"]': 'padding-right:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'border-top-width:4px;border-top-color:[brdh];',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];display:inline-block;padding-top:5px;color:[txt];',
        '%[data-state~="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'border-top-width:4px;border-top-color:[brds];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];display:inline-block;padding-top:5px;color:[txt];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.OverlineMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', null, ['_wrapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]]],
    params: {
        pad: 'PADDING_SIZE',
        fnt: 'FONT',
        brd: 'COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brdh: 'COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        brds: 'COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        fnt: 'font_1',
        brd: 'color_15',
        txt: 'color_15',
        brdh: 'color_15',
        txth: 'color_15',
        brds: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%_wrapper': 'cursor:pointer;[fnt]  border-top:1px solid [brd];',
        '%label': 'color:[txt];display:inline-block;padding:8px;',
        '%[data-listposition="left"]': 'padding-left:0;',
        '%[data-listposition="right"]': 'padding-right:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'border-top-width:4px;border-top-color:[brdh];',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];display:inline-block;padding-top:5px;',
        '%[data-state~="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'border-top-width:4px;border-top-color:[brds];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];display:inline-block;padding-top:5px;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonHorizontalMenuAdaptationSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            ['div', 'bg', [], {}, ['p', 'label', [], {}]],
            ['div', 'labelPad', [], {}, ['div', null, ['_outer', '_arr'], {}], ['div', null, ['_inner', '_arr'], {}]]
        ]
    ],
    exports: {
        addToMinH: 14
    },
    params: {
        brd: 'COLOR_ALPHA',
        clrh: 'COLOR_ALPHA',
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        clrs: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        brd: 'color_15',
        clrh: ['bgh'],
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        txt: 'color_15',
        fnt: 'font_1',
        trans2: 'color 0.4s ease 0s',
        rd: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        clrs: ['bgs']
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%labelPad': 'height:13px;text-align:center;width:100%;border-top:solid 1px [brd];',
        '%_arr': 'width:0;height:0;margin:0 auto;',
        '%_outer': 'border:solid 11px transparent;border-top-color:[brd];margin-top:-1px;visibility:hidden;border-bottom-width:3px;',
        '%_inner': 'border:solid 10px transparent;border-top-color:[clrh];margin-top:-14px;visibility:hidden;border-bottom-width:3px;',
        '%bg': 'background-color:transparent;[trans]',
        '%label': 'display:inline-block;padding:0 calc([pad] + 10px);color:[txt];[fnt]  [trans2]',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-state~="drop"] %labelPad': 'display:none;',
        '%[data-listposition="dropLonely"] %bg,%[data-listposition="top"] %bg,%[data-listposition="bottom"] %bg': '[rd]',
        '%[data-listposition="top"] %bg': 'border-bottom-left-radius:0;border-bottom-right-radius:0;border-radius:0;',
        '%[data-listposition="bottom"] %bg': 'border-top-left-radius:0;border-top-right-radius:0;border-radius:0;',
        '%[data-state~="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans2]',
        '%[data-state~="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans2]',
        '%[data-state~="selected"] %_outer,%[data-state~="selected"] %_inner,%[data-preview~="active"] %_outer,%[data-preview~="active"] %_inner':
            'visibility:inherit;',
        '%[data-state~="selected"] %_inner,%[data-preview~="active"] %_inner': 'border-top-color:[clrs];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.PointerMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            ['div', 'bg', [], {}, ['p', 'label', [], {}]],
            ['div', 'labelPad', [], {}, ['div', null, ['_outer', '_arr'], {}], ['div', null, ['_inner', '_arr'], {}]]
        ]
    ],
    exports: {
        addToMinH: 14
    },
    params: {
        brd: 'COLOR_ALPHA',
        clrh: 'COLOR_ALPHA',
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        clrs: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        brd: 'color_15',
        clrh: ['bgh'],
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        txt: 'color_15',
        fnt: 'font_1',
        trans2: 'color 0.4s ease 0s',
        rd: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        clrs: ['bgs']
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%labelPad': 'height:13px;text-align:center;width:100%;border-top:solid 1px [brd];',
        '%_arr': 'width:0;height:0;margin:0 auto;',
        '%_outer': 'border:solid 11px transparent;border-top-color:[brd];margin-top:-1px;visibility:hidden;border-bottom-width:3px;',
        '%_inner': 'border:solid 10px transparent;border-top-color:[clrh];margin-top:-14px;visibility:hidden;border-bottom-width:3px;',
        '%bg': 'background-color:transparent;[trans]',
        '%label': 'display:inline-block;padding:0 calc([pad] + 10px);color:[txt];[fnt]  [trans2]',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-state~="drop"] %labelPad': 'display:none;',
        '%[data-listposition="dropLonely"] %bg,%[data-listposition="top"] %bg,%[data-listposition="bottom"] %bg': '[rd]',
        '%[data-listposition="top"] %bg': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %bg': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="over"] %bg,%[data-preview~="hover"] %bg': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans2]',
        '%[data-state~="selected"] %bg,%[data-preview~="active"] %bg': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans2]',
        '%[data-state~="selected"] %_outer,%[data-state~="selected"] %_inner,%[data-preview~="active"] %_outer,%[data-preview~="active"] %_inner':
            'visibility:inherit;',
        '%[data-state~="selected"] %_inner,%[data-preview~="active"] %_inner': 'border-top-color:[clrs];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.RibbonsMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        pad: 'PADDING_SIZE',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        trans: 'background-color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;padding:0 [pad];background-color:transparent;[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-state~="drop"]': 'width:100%;display:block;padding:0;',
        '%[data-state~="drop"] %_gapper': 'width:auto;',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-state~="over"],%[data-preview~="hover"]': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateArrowDownMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_wrapper'],
                {},
                ['div', null, ['_sq'], {}],
                [
                    'div',
                    null,
                    ['_arrowUp'],
                    {},
                    ['div', null, ['_leftMask'], {}, ['div', null, ['_left'], {}]],
                    ['div', null, ['_rightMask'], {}, ['div', null, ['_right'], {}]]
                ],
                [
                    'div',
                    null,
                    ['_arrowDown'],
                    {},
                    ['div', null, ['_leftMask'], {}, ['div', null, ['_left'], {}]],
                    ['div', null, ['_rightMask'], {}, ['div', null, ['_right'], {}]]
                ]
            ],
            ['div', 'bg', [], {}, ['p', 'label', [], {}]]
        ]
    ],
    params: {
        pad: 'MARGIN_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        trans3: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        brds: 'BORDER_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        trans3: 'border-color 0.4s ease 0s',
        bgh: 'color_17',
        trans: 'background-color 0.4s ease 0s',
        brdh: 'color_15',
        txth: 'color_15',
        bgs: 'color_18',
        brds: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;position:relative;margin:0 [pad];',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'width:100%;height:100%;padding:75px;',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]  position:relative;',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_sq': 'position:absolute;top:0;left:0;right:0;bottom:70px;background:#f00;',
        '%_arrowUp': 'position:absolute;bottom:35px;left:0;right:0;height:35px;',
        '%_arrowUp %_leftMask': 'position:absolute;right:50%;left:0;top:0;bottom:0;background:#008000;overflow:hidden;',
        '%_arrowUp %_left':
            'border-left:140px solid #f00;border-bottom:35px solid transparent;width:0;height:0;position:absolute;right:0;top:0;bottom:0;[trans3]',
        '%_arrowUp %_rightMask': 'position:absolute;right:0;left:50%;top:0;bottom:0;background:#ffff00;overflow:hidden;',
        '%_arrowUp %_right':
            'border-right:140px solid #f00;border-bottom:35px solid transparent;width:0;height:0;position:absolute;left:0;top:0;bottom:0;[trans3]',
        '%_arrowDown': 'position:absolute;bottom:0;left:0;right:0;height:35px;',
        '%_arrowDown %_leftMask': 'position:absolute;right:50%;left:0;top:0;bottom:0;background:#000;overflow:hidden;',
        '%_arrowDown %_left':
            'border-right:140px solid #f00;border-bottom:35px solid transparent;width:0;height:0;position:absolute;left:0;top:0;bottom:0;[trans3]',
        '%_arrowDown %_rightMask': 'position:absolute;right:0;left:50%;top:0;bottom:0;background:#00f;overflow:hidden;',
        '%_arrowDown %_right':
            'border-left:140px solid #f00;border-bottom:35px solid transparent;width:0;height:0;position:absolute;right:0;top:0;bottom:0;[trans3]',
        '%[data-listposition="left"]': 'padding-left:0;',
        '%[data-listposition="right"]': 'padding-right:0;',
        '%[data-state="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'background-color:[bgh];[trans]',
        '%[data-state="over"] %borderWrapper,%[data-preview~="hover"] %borderWrapper': 'border-color:[brdh];[trans3]',
        '%[data-state="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'background-color:[bgs];[trans]',
        '%[data-state="selected"],%[data-preview~="active"] %borderWrapper': 'border-color:[brds];[trans3]',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateBasicMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_wrapper'], {}, ['div', 'borderWrapper', [], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]]],
    params: {
        pad: 'MARGIN_SIZE',
        trans2: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        trans3: 'TRANSITION',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        noshd: 'BOX_SHADOW',
        nord: 'BORDER_RADIUS',
        rdDrop: 'BORDER_RADIUS',
        txth: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        brds: 'BORDER_COLOR_ALPHA',
        menuButtonBorders: 'SIZE',
        childrenSpacing: 'MARGIN_SIZE'
    },
    paramsDefaults: {
        pad: '5px',
        trans2: 'color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans: 'background-color 0.4s ease 0s',
        rd: '10px',
        bg: 'color_11',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans3: 'border-color 0.4s ease 0s',
        brw: '0',
        brd: 'color_15',
        noshd: 'none',
        nord: '0',
        rdDrop: '10px',
        txth: 'color_15',
        bgh: 'color_17',
        brdh: 'color_15',
        txts: 'color_15',
        bgs: 'color_18',
        brds: 'color_15',
        childrenSpacing: ['pad'],
        menuButtonBorders: ['brw', 'brw']
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;margin:0 [pad];',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%label': '[trans2]  [fnt]  color:[txt];display:inline-block;',
        '%_wrapper': '[trans]  [rd]  background-color:[bg];[shd]  cursor:pointer;',
        '%borderWrapper': '[trans3]  [rd]  border:solid [brw] [brd];',
        '%[data-state~="drop"]': 'display:block;margin:0;',
        '%[data-state~="drop"] %_wrapper': 'background-color:transparent;[trans]    [noshd]    [nord]',
        '%[data-state~="drop"] %borderWrapper': 'border:0;',
        '%[data-listposition="dropLonely"] %_wrapper,%[data-listposition="top"] %_wrapper,%[data-listposition="bottom"] %_wrapper': '[rdDrop]',
        '%[data-listposition="top"] %_wrapper': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %_wrapper': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="over"] %_wrapper,%[data-state~="selected"] %_wrapper,%[data-preview~="hover"] %_wrapper,%[data-preview~="active"] %_wrapper': '[trans]',
        '%[data-state~="over"] %label,%[data-state~="selected"] %label,%[data-preview~="hover"] %label,%[data-preview~="active"] %label': '[trans2]',
        '%[data-state~="over"] %borderWrapper,%[data-state~="selected"] %borderWrapper,%[data-preview~="hover"] %borderWrapper,%[data-preview~="active"] %borderWrapper':
            '[trans3]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%[data-state~="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'background-color:[bgh];',
        '%[data-state~="over"] %borderWrapper,%[data-preview~="hover"] %borderWrapper': 'border-color:[brdh];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];',
        '%[data-state~="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'background-color:[bgs];',
        '%[data-state~="selected"] %borderWrapper,%[data-preview~="active"] %borderWrapper': 'border-color:[brds];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparatedArrowsMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            ['div', null, ['_head'], {}, ['div', null, ['_arrowHead'], {}]],
            ['div', null, ['_tail'], {}, ['div', null, ['_arrowTail'], {}]],
            ['p', 'label', [], {}]
        ]
    ],
    exports: {
        minH: 40,
        maxH: 40
    },
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1: 'TRANSITION',
        brw: 'BORDER_SIZE',
        pad: 'MARGIN_SIZE',
        shd: 'BOX_SHADOW',
        trans3: 'TRANSITION',
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BORDER_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        brwA: 'SIZE'
    },
    paramsDefaults: {
        bg: 'color_11',
        fnt: 'font_1',
        txt: 'color_15',
        trans1: 'color 0.4s ease 0s, background 0.4s ease 0s',
        brw: '10px',
        pad: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans3: 'color 0.4s ease 0s, border-color 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        brwA: ['brw']
    },
    paramsMutators: {
        brwA: {
            type: 'multiply',
            value: 2,
            param: 'brw'
        }
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;background:[bg];[fnt]  color:[txt];[trans1]  margin:0 [brw] 0 [pad] !important;[shd]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_head': 'position:absolute;top:0;bottom:0;right:calc(-1 * [brw]);width:[brw];',
        '%_tail': 'position:absolute;top:0;bottom:0;left:calc(-1 * [brw]);width:[brwA];',
        '%_arrowHead': 'border:20px solid transparent;border-left-color:[bg];border-left-width:[brw];border-right-width:[brw];[trans3]',
        '%_arrowTail': 'border:20px solid [bg];border-left-color:transparent;border-left-width:[brw];border-right-width:[brw];[trans3]',
        '%[data-listposition="left"] %_arrowTail': 'display:none;',
        '%[data-listposition="left"]': 'border-bottom-right-radius:0;border-top-right-radius:0;margin-left:-5px;',
        '%[data-listposition="right"] %_arrowHead': 'display:none;',
        '%[data-listposition="right"]': 'border-bottom-left-radius:0;border-top-left-radius:0;',
        '%label': 'display:inline-block;height:40px !important;line-height:40px !important;position:relative;padding:0 15px;',
        '%[data-state="over"],%[data-preview~="hover"]': 'background:[bgh];color:[txth];',
        '%[data-state="over"] %_arrowHead,%[data-preview~="hover"] %_arrowHead': 'border-left-color:[bgh];',
        '%[data-state="over"] %_arrowTail,%[data-preview~="hover"] %_arrowTail': 'border-color:[bgh];border-left-color:transparent;',
        '%[data-state="selected"],%[data-preview~="active"]': 'background:[bgs];color:[txts];',
        '%[data-state="selected"] %_arrowHead,%[data-preview~="active"] %_arrowHead': 'border-left-color:[bgs];',
        '%[data-state="selected"] %_arrowTail,%[data-preview~="active"] %_arrowTail': 'border-color:[bgs];border-left-color:transparent;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateIndentedMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_gapper'],
                {},
                ['div', null, ['_color'], {}, ['div', null, ['_shade'], {}, ['div', null, ['_shade2'], {}]]],
                ['div', 'bg', [], {}, ['p', 'label', [], {}]]
            ]
        ]
    ],
    params: {
        pad: 'PADDING_SIZE',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        trans: 'TRANSITION',
        tdr: 'URL',
        trans3: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        no_rd: 'BORDER_RADIUS',
        noshd: 'BOX_SHADOW',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        bg: 'color_11',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans: 'background-color 0.4s ease 0s',
        tdr: 'BASE_THEME_DIRECTORY',
        trans3: 'background-image 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        no_rd: '0',
        noshd: 'none',
        trans1: 'opacity 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%_color': 'background-color:[bg];[rd]  [shd]  position:absolute;top:0;bottom:0;left:[pad];right:[pad];[trans]  overflow:hidden;',
        '%_shade': 'background:transparent url([tdr]indented_bg_inverted.png) center top repeat-x;[rd]  [trans3]  width:100%;height:100%;',
        '%_shade2': 'background:transparent url([tdr]indented_bg_inverted.png) center bottom repeat-x;[rd]  [trans3]  width:100%;height:100%;',
        '%bg': 'position:relative;cursor:pointer;',
        '%label': 'display:inline-block;[fnt]  color:[txt];padding:0 10px;[trans2]',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="left"] %_color': 'left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="right"] %_color': 'right:0;',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-listposition="lonely"] %_color': 'right:0;left:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-state~="drop"] %_color': '[no_rd]  background:transparent;[noshd]  [trans]  left:0;right:0;',
        '%[data-state~="drop"] %_shade2': '[no_rd]  opacity:0;[trans1]',
        '%[data-state~="drop"] %_shade': '[no_rd]  opacity:0;[trans1]',
        '%[data-listposition="dropLonely"] %_color': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_shade': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_shade2': '[rdDrop]',
        '%[data-listposition="top"] %_color': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_shade': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_shade2': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %_color': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_shade': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_shade2': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="over"] %_shade2,%[data-preview~="hover"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade2,%[data-preview~="active"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="over"] %_color,%[data-preview~="hover"] %_color': 'background-color:[bgh];[trans]  overflow:hidden;',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"] %_color,%[data-preview~="active"] %_color': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %_shade,%[data-preview~="active"] %_shade,%[data-state~="selected"] %_shade2,%[data-preview~="active"] %_shade2':
            'background-image:url([tdr]indented_bg.png);[trans3]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateLinesMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'borderWrapper', [], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]]],
    params: {
        pad: 'PADDING_SIZE',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        trans: 'TRANSITION',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        brdh: 'BORDER_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        brds: 'BORDER_COLOR_ALPHA',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR',
        menuButtonBorders: 'SIZE'
    },
    paramsDefaults: {
        pad: '5px',
        brw: '1px',
        brd: 'color_15',
        txt: 'color_15',
        fnt: 'font_1',
        trans: 'background-color 0.4s ease 0s, border-color 0.4s ease 0s',
        trans2: 'color 0.4s ease 0s',
        rd: '0',
        brdh: 'color_15',
        bgh: 'color_17',
        txth: 'color_15',
        brds: 'color_15',
        bgs: 'color_18',
        txts: 'color_15',
        menuButtonBorders: ['brw', 'brw']
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%borderWrapper': 'border-bottom:solid [brw] [brd];border-top:solid [brw] [brd];cursor:pointer;color:[txt];background:transparent;[fnt]  [trans]',
        '%label': 'display:inline-block;color:[txt];[fnt]  [trans2]',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-state~="drop"] %borderWrapper': 'border:0;',
        '%[data-state~="drop"] %label': 'padding:0 .5em;',
        '%[data-listposition="dropLonely"] %borderWrapper,%[data-listposition="top"] %borderWrapper,%[data-listposition="bottom"] %borderWrapper': '[rd]',
        '%[data-listposition="top"] %borderWrapper': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %borderWrapper': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="over"] %borderWrapper,%[data-state~="selected"] %borderWrapper,%[data-preview~="hover"] %borderWrapper,%[data-preview~="active"] %borderWrapper':
            '[trans]',
        '%[data-state~="over"] %label,%[data-state~="selected"] %label,%[data-preview~="hover"] %label,%[data-preview~="active"] %label':
            'display:inline-block;',
        '%[data-state~="over"] %borderWrapper,%[data-preview~="hover"] %borderWrapper': 'border-color:[brdh];background-color:[bgh];',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%[data-state~="selected"] %borderWrapper,%[data-preview~="active"] %borderWrapper': 'border-color:[brds];background-color:[bgs];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNBorderRadiusFixSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_gapper'],
                {},
                ['div', null, ['_color'], {}, ['div', null, ['_shade'], {}, ['div', null, ['_shade2'], {}]]],
                ['div', 'bg', [], {}, ['p', 'label', [], {}]]
            ]
        ]
    ],
    params: {
        pad: 'PADDING_SIZE',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        tdr: 'URL',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        noshd: 'BOX_SHADOW',
        no_rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        bg: 'color_11',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'background-color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        noshd: 'none',
        no_rd: '0',
        trans1: 'opacity 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];overflow:hidden;',
        '%_color': 'background-color:[bg];[rd]  [shd]  position:absolute;top:0;bottom:0;left:[pad];right:[pad];',
        '%_shade': 'background:transparent url([tdr]shiny2button_bg.png) center top repeat-x;[rd]  [trans]  width:100%;height:100%;',
        '%_shade2': 'background:transparent url([tdr]shiny2button_bg.png) center bottom repeat-x;[rd]  width:100%;height:100%;',
        '%bg': 'position:relative;cursor:pointer;',
        '%label': 'display:inline-block;[fnt]  color:[txt];padding:0 10px;[trans2]',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="left"] %_color': 'left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="right"] %_color': 'right:0;',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-listposition="lonely"] %_color': 'right:0;left:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-state~="drop"] %_color': 'background:transparent;[noshd]  [no_rd]  [trans]  left:0;right:0;',
        '%[data-state~="drop"] %_shade2': '[no_rd][rd]',
        '%[data-state~="drop"] %_shade': 'opacity:0;[trans1]  [no_rd][rd]',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-listposition="dropLonely"] %_shade': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_shade2': '[rdDrop]',
        '%[data-listposition="top"] %_shade': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_shade2': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %_shade': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_shade2': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="over"] %_shade2,%[data-preview~="hover"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade2,%[data-preview~="active"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-listposition="dropCenter"] %_shade2': 'border-radius:0;',
        '%[data-listposition="dropCenter"] %_shade': 'border-radius:0;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIIMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_gapper'],
                {},
                ['div', null, ['_color'], {}, ['div', null, ['_shade'], {}, ['div', null, ['_shade2'], {}]]],
                ['div', 'bg', [], {}, ['p', 'label', [], {}]]
            ]
        ]
    ],
    params: {
        pad: 'PADDING_SIZE',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        tdr: 'URL',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        noshd: 'BOX_SHADOW',
        no_rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        bg: 'color_11',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'background-color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        noshd: 'none',
        no_rd: '0',
        trans1: 'opacity 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];overflow:hidden;',
        '%_color': 'background-color:[bg];[rd]  [shd]  position:absolute;top:0;bottom:0;left:[pad];right:[pad];',
        '%_shade': 'background:transparent url([tdr]shiny2button_bg.png) center top repeat-x;[rd]  [trans]  width:100%;height:100%;',
        '%_shade2': 'background:transparent url([tdr]shiny2button_bg.png) center bottom repeat-x;[rd]  width:100%;height:100%;',
        '%bg': 'position:relative;cursor:pointer;',
        '%label': 'display:inline-block;[fnt]  color:[txt];padding:0 10px;[trans2]',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="left"] %_color': 'left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="right"] %_color': 'right:0;',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-listposition="lonely"] %_color': 'right:0;left:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-state~="drop"] %_color': 'background:transparent;[noshd]  [no_rd]  [trans]  left:0;right:0;',
        '%[data-state~="drop"] %_shade2': '[no_rd]',
        '%[data-state~="drop"] %_shade': 'opacity:0;[trans1]  [no_rd]',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-listposition="dropLonely"] %_shade': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_shade2': '[rdDrop]',
        '%[data-listposition="top"] %_shade': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_shade2': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %_shade': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_shade2': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="over"] %_shade2,%[data-preview~="hover"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_shade2,%[data-preview~="active"] %_shade2': 'opacity:1;[trans1]',
        '%[data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SeparateShinyIMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', null, ['_shade'], {}], ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        pad: 'PADDING_SIZE',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        noshd: 'BOX_SHADOW',
        no_rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgs: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        bg: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans: 'background-color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        noshd: 'none',
        no_rd: '0',
        trans1: 'all 0.4s ease 0s',
        rdDrop: '10px',
        bgs: 'color_18',
        bgh: 'color_17',
        txth: 'color_15',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%_shade':
            'cursor:pointer;background:[bg] url([tdr]shiny1button_bg.png) center center repeat-x;[rd]  [shd]  [trans]  position:absolute;top:0;bottom:0;left:[pad];right:[pad];',
        '%bg': 'position:relative;cursor:pointer;',
        '%label': 'display:inline-block;[fnt]  color:[txt];[trans2]  padding:0 10px;',
        '%[data-listposition="left"] %_gapper': 'padding-left:0;',
        '%[data-listposition="left"] %_shade': 'left:0;',
        '%[data-listposition="right"] %_gapper': 'padding-right:0;',
        '%[data-listposition="right"] %_shade': 'right:0;left:[pad];',
        '%[data-listposition="lonely"] %_gapper': 'padding:0;',
        '%[data-listposition="lonely"] %_shade': 'right:0;left:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %_gapper': 'padding:0;',
        '%[data-state~="drop"] %_shade': 'opacity:0;[noshd]  [no_rd]  background-color:transparent;left:0;right:0;[trans1]',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-listposition="dropLonely"] %_shade': '[rdDrop]',
        '%[data-listposition="top"] %_shade': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"] %_shade': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'background-color:[bgs];opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'background-color:[bgh];opacity:1;[trans1]',
        '%[data-state~="over"] %_shade,%[data-preview~="hover"] %_shade': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"] %_shade,%[data-preview~="active"] %_shade': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_shade'], {}], ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    params: {
        sep: 'COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        pad: 'PADDING_SIZE',
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        sep: 'color_15',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        pad: '5px',
        rd: '10px',
        trans1: 'opacity 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;border-left:1px solid [sep];background:[bg];[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_shade': 'background:transparent url([tdr]shiny1button_bg.png) center center repeat-x;position:absolute;top:0;right:0;bottom:0;left:0;',
        '%label': 'display:inline-block;[fnt]  color:[txt];[trans2]  padding:0 calc([pad] + 10px);',
        '%[data-listposition="lonely"],%[data-listposition="lonely"] %_shade': 'border:0;[rd]',
        '%:first-child[data-direction="ltr"]': 'border:0;',
        '%:last-child[data-direction="rtl"]': 'border:0;',
        '%[data-listposition="left"],%[data-listposition="left"] %_shade': '[rd]  border-bottom-right-radius:0;border-top-right-radius:0;',
        '%[data-listposition="right"],%[data-listposition="right"] %_shade': '[rd]  border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;border:0;background-color:transparent;[trans]',
        '%[data-state~="drop"] %_shade': 'opacity:0;[trans1]',
        '%[data-listposition="dropLonely"],%[data-listposition="dropLonely"] %_shade': '[rdDrop]',
        '%[data-listposition="top"],%[data-listposition="top"] %_shade': '[rdDrop]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"],%[data-listposition="bottom"] %_shade': '[rdDrop]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_shade,%[data-preview~="hover"] %_shade,%[data-state~="drop"][data-state~="selected"] %_shade,%[data-preview~="active"] %_shade':
            'opacity:1;[trans1]',
        '%[data-state~="over"],%[data-preview~="hover"]': 'background:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.ShinyMenuIIButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_extra'], {}, ['div', null, ['_wrapper'], {}]], ['div', 'bg', [], {}, ['p', 'label', [], {}]]]],
    params: {
        sep: 'COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        sep: 'color_15',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        rd: '10px',
        trans1: 'opacity 0.4s ease 0s',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;border-left:1px solid [sep];background:[bg];[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_extra': 'background:transparent url([tdr]shiny2button_bg.png) center top repeat-x;position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_wrapper': 'background:transparent url([tdr]shiny2button_bg.png) center bottom repeat-x;width:100%;height:100%;',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-listposition="lonely"]': '[rd]  border:0;',
        '%[data-listposition="lonely"] %_wrapper': '[rd]  border:0;',
        '%[data-listposition="lonely"] %_extra': '[rd]  border:0;',
        '%:first-child[data-direction="ltr"]': 'border:0;',
        '%:last-child[data-direction="rtl"]': 'border:0;',
        '%[data-listposition="left"]': '[rd]  border-bottom-right-radius:0;border-top-right-radius:0;border:0;',
        '%[data-listposition="left"] %_wrapper': '[rd]  border-bottom-right-radius:0;border-top-right-radius:0;border:0;',
        '%[data-listposition="left"] %_extra': '[rd]  border-bottom-right-radius:0;border-top-right-radius:0;border:0;',
        '%[data-listposition="right"]': '[rd]  border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-listposition="right"] %_wrapper': '[rd]  border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-listposition="right"] %_extra': '[rd]  border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;border:0;background-color:transparent;[trans]',
        '%[data-state~="drop"] %_extra': 'opacity:0;[trans1]',
        '%[data-state~="drop"] %_wrapper': 'opacity:0;',
        '%[data-listposition="dropLonely"]': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_extra': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_wrapper': '[rdDrop]',
        '%[data-listposition="top"]': '[rd]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_extra': '[rd]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="top"] %_wrapper': '[rd]  border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"]': '[rd]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_extra': '[rd]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-listposition="bottom"] %_wrapper': '[rd]  border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-state~="drop"][data-state~="over"] %_extra,%[data-preview~="hover"] %_extra': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="selected"] %_extra,%[data-preview~="active"] %_extra': 'opacity:1;[trans1]',
        '%[data-state~="drop"][data-state~="over"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'opacity:1;',
        '%[data-state~="drop"][data-state~="selected"] %_wrapper,%[data-preview~="active"] %_wrapper': 'opacity:1;',
        '%[data-state~="over"],%[data-preview~="hover"]': '[trans]  background:[bgh];',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': '[trans2]  color:[txth];',
        '%[data-state~="selected"],%[data-preview~="active"]': '[trans]  background:[bgs];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': '[trans2]  color:[txts];'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SlantedMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            [
                'div',
                null,
                ['_slant'],
                {},
                ['div', null, ['_topTail', '_tailTest'], {}],
                ['div', null, ['_topSq', '_sq'], {}],
                ['div', null, ['_topHead', '_headTest'], {}],
                ['div', null, ['_centerTail', '_tailTest'], {}],
                ['div', null, ['_centerSq', '_sq'], {}],
                ['div', null, ['_centerHead', '_headTest'], {}],
                ['div', null, ['_bottomTail', '_tailTest'], {}],
                ['div', null, ['_bottomSq', '_sq'], {}],
                ['div', null, ['_bottomHead', '_headTest'], {}]
            ],
            ['div', 'bg', [], {}, ['p', 'label', [], {}]],
            ['div', 'hitArea', [], {}]
        ]
    ],
    params: {
        pad: 'MARGIN_LEFT_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bg: 'BORDER_COLOR_ALPHA',
        bgh: 'BORDER_COLOR_ALPHA',
        trans: 'TRANSITION',
        bgs: 'BORDER_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        txth: 'TEXT_COLOR',
        trans3: 'TRANSITION',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '1',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bg: 'color_11',
        bgh: 'color_17',
        trans: 'background-color 0.4s ease 0s',
        bgs: 'color_18',
        rdDrop: '10px',
        txth: 'color_15',
        trans3: 'border-color 0.4s ease 0s',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;position:relative;margin-left:calc([pad] - 211px);overflow:hidden;pointer-events:none;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'height:100%;position:relative;min-width:280px;',
        '%label': 'display:inline-block;margin:0 105px;padding:0 1em;[fnt]  color:[txt];[trans2]',
        '%hitArea': 'position:absolute;top:0;left:105px;bottom:0;right:105px;cursor:pointer;  background:#fff;opacity:0;pointer-events:auto;',
        '%_slant': 'position:absolute;height:210px;top:50%;margin-top:-105px;left:0;right:0;',
        '%_tailTest': 'position:absolute;border-bottom:70px solid [bg];border-left:70px solid transparent;width:0;height:0;',
        '%_topTail': 'top:0;left:140px;',
        '%_centerTail': 'top:70px;left:70px;',
        '%_bottomTail': 'top:140px;left:0;',
        '%_headTest': 'position:absolute;border-top:70px solid [bg];border-right:70px solid transparent;width:0;height:0;',
        '%_topHead': 'top:0;right:0;',
        '%_centerHead': 'top:70px;right:70px;',
        '%_bottomHead': 'top:140px;right:140px;',
        '%_sq': 'position:absolute;background-color:[bg];',
        '%_topSq': 'top:0;bottom:140px;left:210px;right:70px;',
        '%_centerSq': 'top:70px;bottom:70px;left:140px;right:140px;',
        '%_bottomSq': 'top:140px;bottom:0;left:70px;right:210px;',
        '%[data-listposition="left"]': 'margin-left:0;',
        '%[data-listposition="left"] %label': 'display:inline-block;text-indent:0;',
        '%[data-listposition="left"] %_tail': 'background-color:[bg];border-bottom-right-radius:0;border-top-right-radius:0;width:auto;right:70px;',
        '%[data-listposition="left"] %_tailTop,%[data-listposition="left"] %_tailBottom': 'display:none;',
        '%[data-listposition="left"] %hitArea': 'left:0;border:solid 1px #f00;',
        '%[data-listposition="right"] %_head': 'background-color:[bg];border-bottom-left-radius:0;border-top-left-radius:0;',
        '%[data-listposition="right"] %_headTop,%[data-listposition="right"] %_headBottom': 'display:none;',
        '%[data-state="over"][data-listposition="right"] %_head,%[data-preview~="hover"] %_head': 'background-color:[bgh];[trans]',
        '%[data-state="selected"][data-listposition="right"] %_head,%[data-preview~="active"] %_head': 'background-color:[bgs];[trans]',
        '%[data-container="drop"]': 'width:100%;display:block;background-color:[bg];[trans]  margin-left:0;background-color:transparent;',
        '%[data-container="drop"] %label': 'display:inline-block;padding:0 .5em;text-indent:0;margin:0;',
        '%[data-container="drop"] %_slant,%[data-container="drop"] %_head': 'display:none;',
        '%[data-container="drop"] %hitArea': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-listposition="dropLonely"]': '[rdDrop]',
        '%[data-listposition="top"]': 'border-bottom-left-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="bottom"]': 'border-top-left-radius:0;border-top-right-radius:0;',
        '%[data-container="drop"][data-state="over"],%[data-preview~="hover"]': '[trans]  background-color:[bgh];',
        '%[data-container="drop"][data-state="selected"],%[data-preview~="active"]': '[trans]  background-color:[bgs];',
        '%[data-state="over"] %_sq,%[data-preview~="hover"] %_sq': 'background-color:[bgh];',
        '%[data-state="over"] %_tailTest,%[data-preview~="hover"] %_tailTest': 'border-bottom-color:[bgh];',
        '%[data-state="over"] %_headTest,%[data-preview~="hover"] %_headTest': 'border-top-color:[bgh];',
        '%[data-state="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-listposition="left"][data-state="over"] %_tail,%[data-preview~="hover"] %_tail': 'background-color:[bgh];[trans]',
        '%[data-state="selected"] %_sq,%[data-preview~="active"] %_sq': 'background-color:[bgs];',
        '%[data-state="selected"] %_tailTest,%[data-preview~="active"] %_tailTest': 'border-bottom-color:[bgs];',
        '%[data-state="selected"] %_headTest,%[data-preview~="active"] %_headTest': 'border-top-color:[bgs];',
        '%[data-state="selected"] %_headTop,%[data-preview~="active"] %_headTop': 'border-bottom-color:[bgs];[trans3]',
        '%[data-state="selected"] %_headBottom,%[data-preview~="active"] %_headBottom': 'border-top-color:[bgs];[trans3]',
        '%[data-state="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]',
        '%[data-state="selected"][data-listposition="left"] %_tail,%[data-preview~="active"] %_tail': 'background-color:[bgs];[trans]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SloppyBorderMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        sep: 'COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bg: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        sep: 'color_15',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bg: 'color_11',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;background-color:transparent;[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];border-left:1px solid [sep];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%:first-child[data-direction="ltr"] %_gapper': 'border:0;',
        '%:last-child[data-direction="rtl"] %_gapper': 'border:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;background-color:[bg];[trans]',
        '%[data-state~="drop"] %_gapper': 'border:0;',
        '%[data-state~="over"],%[data-preview~="hover"]': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'display:inline-block;color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'display:inline-block;color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.SolidColorMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        sep: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        rd: 'BORDER_RADIUS',
        rdDrop: 'BORDER_RADIUS',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        sep: 'color_15',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        rd: '10px',
        rdDrop: '10px',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;background-color:transparent;[trans]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];border-left:1px solid [sep];',
        '%label': 'display:inline-block;padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%[data-listposition="left"]': '[rd]  border-top-right-radius:0;border-bottom-right-radius:0;',
        '%[data-listposition="left"] %_gapper': 'border:0;border-left:none;',
        '%[data-listposition="right"]': '[rd]  border-top-left-radius:0;border-bottom-left-radius:0;',
        '%[data-listposition="lonely"]': '[rd]',
        '%[data-listposition="lonely"] %_gapper': 'border:0;',
        '%[data-state~="drop"]': 'width:100%;display:block;background-color:transparent;[trans]',
        '%[data-state~="drop"] %_gapper': 'border:0;border-top:1px solid [sep];padding:0;width:auto;',
        '%[data-state~="drop"] %label': 'padding:0 .5em;',
        '%[data-listposition="top"]': '[rdDrop]  border-bottom-right-radius:0;border-bottom-left-radius:0;',
        '%[data-listposition="top"] %_gapper': 'border:0;',
        '%[data-listposition="bottom"]': '[rdDrop]  border-top-right-radius:0;border-top-left-radius:0;',
        '%[data-listposition="dropLonely"]': '[rdDrop]',
        '%[data-listposition="dropLonely"] %_gapper': 'border:0;',
        '%[data-state~="over"],%[data-preview~="hover"]': 'background-color:[bgh];[trans]',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans2]',
        '%[data-state~="selected"],%[data-preview~="active"]': 'background-color:[bgs];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.TextOnlyMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_gapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        fnt: 'FONT',
        pad: 'PADDING_SIZE',
        txt: 'TEXT_COLOR',
        trans: 'TRANSITION',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        fnt: 'font_1',
        pad: '5px',
        txt: 'color_15',
        trans: 'color 0.4s ease 0s',
        txth: 'color_14',
        txts: 'color_14'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;[fnt]',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%_gapper': 'padding:0 [pad];',
        '%label': 'display:inline-block;padding:0 10px;color:[txt];[trans]',
        '%[data-state~="drop"]': 'width:100%;display:block;',
        '%[data-state~="drop"] %label': 'padding:0 .5em;',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans]'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.TextSeparatorsMenuButtonNSkin'] = {
    react: [['a', 'linkElement', [], {}, ['div', null, ['_wrapper'], {}, ['div', 'bg', [], {}, ['p', 'label', [], {}]]]]],
    params: {
        fnt: 'FONT',
        sep: 'BORDER_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        fnt: 'font_1',
        sep: 'color_15',
        txt: 'color_15',
        trans: 'color 0.4s ease 0s',
        pad: '5px',
        txth: 'color_14',
        txts: 'color_14'
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;display:inline-block;cursor:pointer;[fnt]  border-left:1px solid [sep];',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%label': 'color:[txt];[trans]  display:inline-block;padding:0 10px;',
        '%_wrapper': 'padding:0 [pad];',
        '%:first-child[data-direction="ltr"]': 'border:0;',
        '%:last-child[data-direction="rtl"]': 'border:0;',
        '%[data-listposition="lonely"]': 'border:0;',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans]',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];[trans]',
        '%[data-state~="drop"]': 'width:100%;display:block;border:0;border-top:1px solid [sep];',
        '%[data-state~="drop"] %label': 'display:inline-block;padding:0 .5em;',
        '%[data-state~="drop"] %_wrapper': 'padding:0;',
        '%[data-listposition="top"]': 'border:0;',
        '%[data-listposition="dropLonely"]': 'border:0;'
    }
}
skins['wysiwyg.viewer.skins.dropmenubutton.VerticalRibbonsMenuButtonNSkin'] = {
    react: [
        [
            'a',
            'linkElement',
            [],
            {},
            ['div', null, ['_bgWrapper'], {}, ['div', null, ['_ribbon'], {}], ['div', null, ['_fold', '_top'], {}], ['div', null, ['_fold', '_bottom'], {}]],
            ['div', 'bg', [], {}, ['p', 'label', [], {}]]
        ]
    ],
    params: {
        pad: 'SIZE',
        els: 'BORDER_SIZE',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans1R: 'TRANSITION',
        bgDrop: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        bgs: 'BG_COLOR_ALPHA',
        ribbonShd: 'BOX_SHADOW',
        trans1: 'TRANSITION',
        trans3: 'TRANSITION',
        trans2: 'TRANSITION',
        txth: 'TEXT_COLOR',
        txts: 'TEXT_COLOR',
        elmOver: 'COLOR_ALPHA',
        elmSelected: 'COLOR_ALPHA',
        childrenSpacing: 'SIZE'
    },
    paramsDefaults: {
        pad: '5px',
        els: '10',
        fnt: 'font_1',
        txt: 'color_15',
        trans1R: 'background-color 0.2s ease 0s, top 0.2s ease 0s, bottom 0.2s ease 0s',
        bgDrop: 'color_11',
        trans: 'background-color 0.2s ease 0s',
        bgh: 'color_17',
        bgs: 'color_18',
        ribbonShd: '2px 0 2px 0 rgba(0, 0, 0, 0.2)',
        trans1: 'background-color 0.2s ease 0s, top 0.2s ease 0s, bottom 0.2s ease 0s',
        trans3: 'border-width 0.2s ease 0.2s',
        trans2: 'color 0.2s ease 0s',
        txth: 'color_15',
        txts: 'color_15',
        elmOver: ['bgh'],
        elmSelected: ['bgs'],
        childrenSpacing: ['pad']
    },
    paramsMutators: {
        elmOver: {
            type: 'brightness',
            value: 0.7,
            param: 'bgh'
        },
        elmSelected: {
            type: 'brightness',
            value: 0.7,
            param: 'bgs'
        }
    },
    css: {
        '%': 'height:100%;position:relative;box-sizing:border-box;cursor:pointer;display:inline-block;padding-left:[pad];box-sizing:border-box;',
        '%[data-state~="header"] a,%[data-state~="header"] div': 'cursor:default !important;',
        '%linkElement': 'display:inline-block;height:100%;width:100%;',
        '%bg': 'margin:0 [els];',
        '%label': 'position:relative;display:inline-block;padding:0 .5em;[fnt]  color:[txt];',
        '%_bgWrapper': 'position:absolute;top:0;right:0;bottom:0;left:[pad];',
        '%_ribbon': 'background-color:transparent;position:absolute;top:[els];right:[els];bottom:[els];left:[els];[trans1R]',
        '%_fold': 'position:absolute;margin-left:0;width:0;height:0;border:solid 0 transparent;border-left-width:[els];',
        '%_top': 'position:absolute;top:0;left:0;border-bottom:solid 0 [elmOver];',
        '%_bottom': 'position:absolute;bottom:0;left:0;border-top:solid 0 [elmOver];',
        '%[data-state~="drop"]': 'width:100%;display:block;padding:0;',
        '%[data-state~="drop"] %_bgWrapper': 'display:none;',
        '%[data-state~="drop"] %bg': 'background-color:[bgDrop];[trans]    margin:0;',
        '%[data-state~="drop"][data-state~="over"],%[data-state~="drop"][data-state~="selected"],%[data-state~="drop"][data-preview~="hover"],%[data-state~="drop"][data-preview~="active"]':
            '[trans]',
        '%[data-state~="drop"][data-state~="over"] %bg,%[data-state~="drop"][data-preview~="hover"] %bg': 'background-color:[bgh];',
        '%[data-state~="drop"][data-state~="selected"],%[data-state~="drop"][data-preview~="active"] %bg': 'background-color:[bgs];',
        '%[data-state~="over"] %_ribbon,%[data-state~="selected"] %_ribbon,%[data-preview~="hover"] %_ribbon,%[data-preview~="active"] %_ribbon':
            '[ribbonShd]  [trans1]  top:0;bottom:0;',
        '%[data-state~="over"] %_top,%[data-state~="over"] %_bottom,%[data-state~="selected"] %_top,%[data-state~="selected"] %_bottom,%[data-preview~="hover"] %_top,%[data-preview~="hover"] %_bottom,%[data-preview~="active"] %_top,%[data-preview~="active"] %_bottom':
            '[trans3]',
        '%[data-state~="over"] %_top,%[data-state~="selected"] %_top,%[data-preview~="hover"] %_top,%[data-preview~="active"] %_top':
            'border-bottom-width:[els];',
        '%[data-state~="over"] %_bottom,%[data-state~="selected"] %_bottom,%[data-preview~="hover"] %_bottom,%[data-preview~="active"] %_bottom':
            'border-top-width:[els];',
        '%[data-state~="over"] %label,%[data-state~="selected"] %label,%[data-preview~="hover"] %label,%[data-preview~="active"] %label': '[trans2]',
        '%[data-state~="over"] %_ribbon,%[data-preview~="hover"] %_ribbon': 'background-color:[bgh];',
        '%[data-state~="over"] %_top,%[data-preview~="hover"] %_top': 'border-bottom-color:[elmOver];',
        '%[data-state~="over"] %_bottom,%[data-preview~="hover"] %_bottom': 'border-top-color:[elmOver];',
        '%[data-state~="over"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%[data-state~="selected"] %_ribbon,%[data-preview~="active"] %_ribbon': 'background-color:[bgs];',
        '%[data-state~="selected"] %_top,%[data-preview~="active"] %_top': 'border-bottom-color:[elmSelected];',
        '%[data-state~="selected"] %_bottom,%[data-preview~="active"] %_bottom': 'border-top-color:[elmSelected];',
        '%[data-state~="selected"] %label,%[data-preview~="active"] %label': 'color:[txts];'
    }
}
skins['wysiwyg.viewer.skins.page.BasicPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11'
    },
    css: {
        '%bg': 'overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];',
        '%[data-state~="mobileView"] %bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;'
    }
}
skins['wysiwyg.viewer.skins.page.BorderPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        brw: '1px',
        brd: 'color_15',
        bg: 'color_11',
        rd: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%bg': 'border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]  box-sizing:border-box;',
        '%[data-state~="mobileView"] %bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;'
    }
}
skins['wysiwyg.viewer.skins.page.InnerShadowPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        rd: '5px',
        bg: 'color_11',
        brd: 'color_15',
        brw: '1px',
        shd: 'inset 0 1px 2px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.75)'
    },
    css: {
        '%bg': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:solid [brd] [brw];[shd]',
        '%[data-state~="mobileView"] %bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;width:100%;height:100%;'
    }
}
skins['wysiwyg.viewer.skins.page.LiftedBottomPageSkin'] = {
    react: [
        [
            'div',
            'bg',
            [],
            {},
            ['div', null, ['_shadow', '_leftBottom'], {}],
            ['div', null, ['_shadow', '_centerBottom'], {}],
            ['div', null, ['_shadow', '_rightBottom'], {}],
            ['div', null, ['_bg'], {}]
        ],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_11',
        brw: '1px',
        brd: 'color_15'
    },
    css: {
        '%_shadow': 'position:absolute;top:-15px;bottom:-15px;background-image:url([tdr]shdbottom.png);background-repeat:no-repeat;pointer-events:none;',
        '%_leftBottom': 'left:-15px;background-position:left bottom;width:50px;',
        '%_rightBottom': 'right:-15px;background-position:right bottom;width:50px;',
        '%_centerBottom': 'right:35px;left:35px;background-position:center bottom;',
        '%_bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] solid [brd];',
        '%[data-state~="mobileView"] %_bg': 'left:10px;right:10px;',
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;'
    }
}
skins['wysiwyg.viewer.skins.page.LiftedShadowPageSkin'] = {
    react: [
        ['div', 'bg', [], {}, ['div', null, ['_bg'], {}], ['div', null, ['_left', '_shd'], {}], ['div', null, ['_right', '_shd'], {}]],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: 'color_11',
        rd: '5px',
        brw: '1px',
        brd: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%_bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  border:[brw] solid [brd];',
        '%[data-state~="mobileView"] %_bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;',
        '%_shd': 'position:absolute;bottom:-26px;width:165px;height:26px;background:url([tdr]liftedshadow_medium.png) no-repeat;pointer-events:none;',
        '%_left': 'left:-20px;background-position:0 0;',
        '%_right': 'right:-20px;background-position:100% 0;'
    }
}
skins['wysiwyg.viewer.skins.page.LiftedTopPageSkin'] = {
    react: [
        [
            'div',
            'bg',
            [],
            {},
            ['div', null, ['_shadow', '_leftTop'], {}],
            ['div', null, ['_shadow', '_centerTop'], {}],
            ['div', null, ['_shadow', '_rightTop'], {}],
            ['div', null, ['_bg'], {}]
        ],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_11',
        brw: '1px',
        brd: 'color_15'
    },
    css: {
        '%_shadow': 'position:absolute;top:-15px;bottom:-15px;background-image:url([tdr]shdtop.png);background-repeat:no-repeat;pointer-events:none;',
        '%_leftTop': 'left:-15px;background-position:left top;width:50px;',
        '%_rightTop': 'right:-15px;background-position:right top;width:50px;',
        '%_centerTop': 'right:35px;left:35px;background-position:center top;',
        '%_bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] solid [brd];',
        '%[data-state~="mobileView"] %_bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;',
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.viewer.skins.page.NoMarginPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11'
    },
    css: {
        '%bg': 'overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;'
    }
}
skins['wysiwyg.viewer.skins.page.ResponsivePageWithColorBG'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_11'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];'
    }
}
skins['wysiwyg.viewer.skins.page.ShinyIPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    exports: {
        paddingBottom: '20px'
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: 'color_11',
        rd: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brw: '1px',
        brd: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  [shd]  border:[brw] solid [brd];background-image:url([tdr]apple_box.png);background-repeat:repeat-x;background-position:0 0;box-sizing:border-box;',
        '%[data-state~="mobileView"] %bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;bottom:20px;'
    }
}
skins['wysiwyg.viewer.skins.page.SloopyPageSkin'] = {
    react: [
        ['div', 'bg', [], {}, ['div', null, ['_bg'], {}], ['div', null, ['_bg2'], {}]],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_11'
    },
    css: {
        '%_bg2:before': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 0 0;left:-8px;top:0;bottom:0;width:10px;',
        '%_bg2:after': 'position:absolute;content:"";background:url([tdr]sloppyvertical.png) repeat-y 100% 0;right:-8px;top:0;bottom:0;width:10px;',
        '%_bg:before': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 0;left:-8px;top:-8px;right:-8px;height:10px;',
        '%_bg:after': 'position:absolute;content:"";background:url([tdr]sloppyhoriz.png) repeat-x 0 100%;left:-8px;bottom:-8px;right:-8px;height:10px;',
        '%_bg': 'position:absolute;top:3px;bottom:3px;left:3px;right:3px;background:[bg];',
        '%_bg2': 'position:absolute;top:3px;bottom:3px;left:3px;right:3px;',
        '%inlineContent': 'position:absolute;top:3px;bottom:3px;left:3px;width:100%;',
        '%bg': 'position:absolute;left:0;right:0;top:0;bottom:0;'
    }
}
skins['wysiwyg.viewer.skins.page.ThreeDeePageSkin'] = {
    react: [
        ['div', 'bg', [], {}, ['div', null, ['_innerbg'], {}]],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        brd: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        brw: 'SIZE',
        bg: 'BG_COLOR_ALPHA',
        shc: 'COLOR'
    },
    paramsDefaults: {
        brd: 'color_15',
        rd: '5px',
        brw: '1px',
        bg: 'color_11',
        shc: ['bg']
    },
    paramsMutators: {
        shc: {
            type: 'brightness',
            value: 0.5,
            param: 'bg'
        }
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;background:[brd];[rd]  box-shadow:1px 1px [shc], 3px 3px [shc], 5px 5px [shc], 7px 7px [shc], 9px 9px [shc];',
        '%_innerbg': 'position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];background-color:[bg];[rd]',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.viewer.skins.page.TransparentPageSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%[data-state~="mobileView"] %bg': 'left:10px;right:10px;',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'rolloverHolder', [], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}], ['a', 'link', [], {}]],
        ['div', 'counter', ['_hlp'], {}],
        ['div', 'autoplay', ['_hlp'], {}, ['span', null, [], {}]],
        ['div', null, ['_buttons'], {}, ['a', 'buttonPrev', [], {}], ['a', 'buttonNext', [], {}]],
        ['div', 'emptyDivToFillMatrix', [], {}]
    ],
    exports: {
        heightDiff: 20,
        widthDiff: 100,
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        fntds: 'FONT',
        bg: 'BG_COLOR_ALPHA',
        trans2: 'TRANSITION',
        txt: 'BORDER_COLOR',
        trans: 'TRANSITION',
        fntt: 'FONT',
        linkColor: 'TEXT_COLOR',
        tdr: 'URL'
    },
    paramsDefaults: {
        fntds: 'font_8',
        bg: 'color_11',
        trans2: 'opacity 0s',
        txt: 'color_15',
        trans: 'opacity 0.3s ease 0s',
        fntt: 'font_6',
        linkColor: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%[data-state~="touchRollOver"] %_opc': 'opacity:1;',
        '%emptyDivToFillMatrix': 'width:1px;height:1px;opacity:0;',
        '%itemsContainer': 'margin:0 50px;',
        '%rolloverHolder':
            'box-sizing:border-box !important;[fntds]  background-color:[bg];[trans2]  color:[txt];opacity:0;padding:10px !important;overflow:hidden;cursor:pointer;',
        '%[data-state~="rollover"] %rolloverHolder': '[trans]  opacity:1;',
        '%title': 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;[fntt]',
        '%description': 'white-space:pre-line;overflow:hidden;max-height:60%;',
        '%link': 'overflow:hidden;white-space:nowrap;text-decoration:underline;color:[linkColor];position:absolute;bottom:10px;left:10px;right:10px;',
        '%_buttons': 'position:absolute;left:0;right:0;top:50%;margin-top:-35px;',
        '%_buttons a':
            '[trans]  opacity:0.6;width:45px;height:65px;background-image:url([tdr]arrows_white_new3.png);background-repeat:no-repeat;cursor:pointer;position:absolute;',
        '%buttonPrev': 'left:0;background-position:0 0;',
        '%buttonNext': 'right:0;background-position:100% 0;',
        '%_buttons a:hover': 'opacity:1;',
        '%_hlp': 'position:absolute;color:[txt];',
        '%_hlp span': 'display:block;',
        '%autoplay': 'right:80px;bottom:0;',
        '%counter': 'right:50px;bottom:-2px;font-size:13px;',
        '%[data-state~="noLink"] %link': 'display:none;',
        '%[data-state~="autoplayOff"] %autoplay > span': 'border:6px solid transparent;border-left:6px solid [txt];',
        '%[data-state~="autoplayOn"] %autoplay > span': 'border-left:3px solid [txt];border-right:3px solid [txt];margin-right:5px;height:12px;width:1px;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'rolloverHolder', [], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}], ['a', 'link', [], {}]],
        ['div', null, ['_buttons'], {}, ['a', 'buttonPrev', [], {}], ['a', 'buttonNext', [], {}]],
        ['div', 'counter', ['_hlp', '_opc'], {}],
        ['div', 'autoplay', ['_hlp', '_opc'], {}, ['span', null, [], {}]],
        ['div', 'emptyDivToFillMatrix', [], {}]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        trans: 'TRANSITION',
        txt: 'BORDER_COLOR',
        fntds: 'FONT',
        bg: 'BG_COLOR_ALPHA',
        trans2: 'TRANSITION',
        fntt: 'FONT',
        linkColor: 'TEXT_COLOR',
        tdr: 'URL'
    },
    paramsDefaults: {
        trans: 'opacity 0.3s ease 0s',
        txt: 'color_15',
        fntds: 'font_8',
        bg: 'color_5',
        trans2: 'opacity 0s',
        fntt: 'font_6',
        linkColor: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%[data-state~="touchRollOver"] %_opc': 'opacity:1;',
        '%emptyDivToFillMatrix': 'width:1px;height:1px;opacity:0;',
        '%_opc': '[trans]  opacity:0;color:[txt];',
        '%:hover[data-state~="notMobile"] %_opc': 'opacity:1;',
        '%itemsContainer': 'overflow:hidden;height:100% !important;',
        '%rolloverHolder':
            'box-sizing:border-box !important;[fntds]  background-color:[bg];[trans2]  color:[txt];opacity:0;padding:10px !important;overflow:hidden;cursor:pointer;',
        '%rolloverHolder > div': 'width:100% !important;',
        '%[data-state~="rollover"] %rolloverHolder': '[trans]  opacity:1;',
        '%title': 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;[fntt]',
        '%description': 'white-space:pre-line;overflow:hidden;max-height:60%;',
        '%link': 'overflow:hidden;white-space:nowrap;text-decoration:underline;color:[linkColor];position:absolute;bottom:10px;left:10px;right:10px;',
        '%_buttons': '[trans]  opacity:0;position:absolute;left:0;right:0;top:50%;margin-top:-15px;',
        '%[data-state~="mobile"] %_buttons': 'opacity:1;',
        '%_buttons a':
            '[trans]  opacity:0.6;width:45px;height:65px;background-image:url([tdr]arrows_white_new3.png);background-repeat:no-repeat;cursor:pointer;position:absolute;',
        '%[data-state~="mobile"] %_buttons a': 'opacity:1;',
        '%buttonPrev': 'left:20px;background-position:0 0;left:20px;background-position:0 0;',
        '%buttonNext': 'right:20px;background-position:100% 0;right:20px;background-position:100% 0;',
        '%:hover > %_buttons': 'opacity:1;',
        '%_buttons a:hover': 'opacity:1;',
        '%_hlp': 'position:absolute;color:[txt];',
        '%_hlp span': 'display:block;',
        '%autoplay': 'right:35px;bottom:10px;',
        '%counter': 'right:10px;bottom:8px;font-size:13px;',
        '%[data-state~="noLink"] %link': 'display:none;',
        '%[data-state~="autoplayOff"] %autoplay > span': 'border:6px solid transparent;border-left:6px solid [txt];',
        '%[data-state~="autoplayOn"] %autoplay > span': 'border-left:3px solid [txt];border-right:3px solid [txt];margin-right:5px;height:12px;width:1px;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'rolloverHolder', [], {}, ['a', 'link', [], {}]],
        ['div', 'textWrapper', ['_rolloverHolder', '_opc'], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}]],
        ['div', null, ['_buttons'], {}, ['a', 'buttonPrev', ['_btn'], {}], ['a', 'buttonNext', ['_btn'], {}]],
        ['div', 'counter', ['_hlp', '_opc'], {}],
        ['div', 'autoplay', ['_hlp', '_opc'], {}, ['span', 'autoplayImg', [], {}]],
        ['div', 'emptyDivToFillMatrix', [], {}]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        trans: 'TRANSITION',
        txt: 'BORDER_COLOR',
        fntds: 'FONT',
        bg: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        fntt: 'FONT',
        linkColor: 'TEXT_COLOR',
        tdr: 'URL'
    },
    paramsDefaults: {
        trans: 'opacity 0.3s ease 0s, color 0.3s ease 0s, background-color 0.3s ease 0s',
        txt: 'color_15',
        fntds: 'font_8',
        bg: 'color_11',
        bgh: 'color_11',
        fntt: 'font_6',
        linkColor: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%[data-state~="touchRollOver"] %_opc': 'opacity:1;',
        '%emptyDivToFillMatrix': 'width:1px;height:1px;opacity:0;',
        '%itemsContainer': 'overflow:hidden;height:100% !important;',
        '%_opc': '[trans]  opacity:0;color:[txt];',
        '%:hover[data-state~="notMobile"] %_opc': 'opacity:1;',
        '%rolloverHolder':
            'box-sizing:border-box !important;[fntds]  background-color:[bg];color:[txt];opacity:0;padding:10px !important;overflow:hidden;cursor:pointer;',
        '%[data-state~="rollover"] %rolloverHolder': 'opacity:1;',
        '%_rolloverHolder': 'background-color:[bgh];[fntds]  position:absolute;bottom:0;left:0;right:0;padding:10px;cursor:pointer;min-height:15px;',
        '%title': 'white-space:nowrap;text-overflow:ellipsis;display:block;overflow:hidden;[fntt]',
        '%description': 'white-space:pre-line;text-overflow:ellipsis;display:block;overflow:hidden;margin-right:70px;',
        '%link': 'white-space:nowrap;text-overflow:ellipsis;position:absolute;display:block;overflow:hidden;text-decoration:underline;color:[linkColor];',
        '%_btn':
            '[trans]  opacity:0.4;height:65px;background-image:url([tdr]arrows_white.png);background-repeat:no-repeat;width:33px;position:absolute;top:50%;margin-top:-22px;cursor:pointer;',
        '%[data-state~="mobile"] %_btn': 'opacity:1;',
        '%buttonPrev': 'left:20px;background-position:0 0;',
        '%buttonNext': 'right:20px;background-position:100% 0;',
        '%[data-state~="notMobile"] %_buttons': '[trans]  opacity:0;',
        '%:hover > %_buttons': 'opacity:1;',
        '%_btn:hover': 'opacity:1;',
        '%_hlp': 'position:absolute;color:[txt];',
        '%_hlp span': 'display:block;',
        '%autoplay': 'right:25px;width:35px;height:35px;bottom:0px;',
        '%autoplayImg': 'margin-top:40%;margin-left:40%;',
        '%counter': 'right:5px;bottom:8px;font-size:13px;',
        '%[data-state~="noLink"] %link': 'display:none;',
        '%[data-state~="autoplayOff"] %autoplay > span': 'border:6px solid transparent;border-left:6px solid [txt];',
        '%[data-state~="autoplayOn"] %autoplay > span': 'border-left:3px solid [txt];border-right:3px solid [txt];margin-right:5px;height:12px;width:1px;',
        '%textWrapper': 'pointer-events:none;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'rolloverHolder', [], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}], ['a', 'link', [], {}]],
        ['div', 'counter', ['_hlp'], {}],
        ['div', 'autoplay', ['_hlp'], {}, ['span', null, [], {}]],
        ['a', 'buttonPrev', ['_btn'], {}, ['span', null, [], {}]],
        ['a', 'buttonNext', ['_btn'], {}, ['span', null, [], {}]],
        ['div', 'emptyDivToFillMatrix', [], {}]
    ],
    exports: {
        heightDiff: 20,
        widthDiff: 20,
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        fntds: 'FONT',
        bg: 'BG_COLOR_ALPHA',
        trans2: 'TRANSITION',
        txt: 'BORDER_COLOR',
        trans: 'TRANSITION',
        fntt: 'FONT',
        linkColor: 'TEXT_COLOR',
        bg1: 'BG_COLOR_ALPHA',
        bg2: 'COLOR'
    },
    paramsDefaults: {
        fntds: 'font_8',
        bg: 'color_11',
        trans2: 'opacity 0s',
        txt: 'color_15',
        trans: 'opacity 0.3s ease 0s',
        fntt: 'font_6',
        linkColor: 'color_15',
        bg1: 'color_18',
        bg2: ['bg1']
    },
    paramsMutators: {
        bg2: {
            type: 'brightness',
            value: 0.5,
            param: 'bg1'
        }
    },
    css: {
        '%[data-state~="touchRollOver"] %_opc': 'opacity:1;',
        '%emptyDivToFillMatrix': 'width:1px;height:1px;opacity:0;',
        '%itemsContainer': 'overflow:hidden;margin:0 10px;',
        '%rolloverHolder':
            'box-sizing:border-box !important;[fntds]  background-color:[bg];[trans2]  color:[txt];opacity:0;padding:10px !important;overflow:hidden;cursor:pointer;',
        '%[data-state~="rollover"] %rolloverHolder': '[trans]  opacity:1;',
        '%title': 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;[fntt]',
        '%description': 'white-space:pre-line;overflow:hidden;max-height:60%;',
        '%link': 'overflow:hidden;white-space:nowrap;text-decoration:underline;color:[linkColor];position:absolute;bottom:10px;left:10px;right:10px;',
        '%_btn':
            'background-color:[bg1];box-shadow:0 1px 4px rgba(0, 0, 0, 0.6);line-height:40px;height:40px;width:40px;margin-top:-30px;position:absolute;top:50%;cursor:pointer;text-align:center;display:block;',
        '%_btn span': 'border:6px solid transparent;width:0;height:0;display:block;position:absolute;top:50%;left:50%;',
        '%_btn:after': 'position:absolute;content:"";bottom:-10px;border:transparent solid 10px;border-top:0;width:0;height:0;',
        '%buttonPrev': 'left:0;',
        '%buttonPrev span': 'border-right:5px solid [txt];margin:-4px 0 0 -8px;',
        '%buttonPrev:after': 'left:-10px;border-right-color:[bg2];',
        '%buttonNext': 'right:0;',
        '%buttonNext span': 'border-left:5px solid [txt];margin:-5px 0 0 -2px;',
        '%buttonNext:after': 'right:-10px;border-left-color:[bg2];',
        '%_hlp': 'position:absolute;color:[txt];',
        '%_hlp span': 'display:block;',
        '%autoplay': 'right:35px;bottom:0;',
        '%counter': 'right:10px;bottom:-2px;font-size:13px;',
        '%[data-state~="noLink"] %link': 'display:none;',
        '%[data-state~="autoplayOff"] %autoplay > span': 'border:6px solid transparent;border-left-color:[txt];',
        '%[data-state~="autoplayOn"] %autoplay > span': 'border-left:3px solid [txt];border-right:3px solid [txt];margin-right:5px;height:12px;width:1px;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'rolloverHolder', [], {}, ['a', 'link', [], {}]],
        ['div', null, ['_rolloverHolder', '_opc'], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}]],
        ['a', 'buttonNext', ['_btn'], {}, '>'],
        ['a', 'buttonPrev', ['_btn'], {}, '<'],
        ['div', 'counter', ['_hlp'], {}],
        ['div', 'autoplay', ['_hlp'], {}, ['span', null, [], {}]],
        ['div', 'emptyDivToFillMatrix', [], {}]
    ],
    exports: {
        heightDiff: 60,
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        fntds: 'FONT',
        bgh: 'BG_COLOR_ALPHA',
        trans2: 'TRANSITION',
        trans: 'TRANSITION',
        txt: 'BORDER_COLOR',
        fntt: 'FONT',
        linkColor: 'TEXT_COLOR'
    },
    paramsDefaults: {
        fntds: 'font_8',
        bgh: 'color_11',
        trans2: 'opacity 0s',
        trans: 'opacity 0.3s ease 0s',
        txt: 'color_15',
        fntt: 'font_6',
        linkColor: 'color_15'
    },
    css: {
        '%[data-state~="touchRollOver"] %_opc': 'opacity:1;',
        '%emptyDivToFillMatrix': 'width:1px;height:1px;opacity:0;',
        '%rolloverHolder':
            'box-sizing:border-box !important;[fntds]  background-color:[bgh];[trans2]  opacity:0;padding:10px !important;overflow:hidden;cursor:pointer;',
        '%[data-state~="rollover"] %rolloverHolder': '[trans]  opacity:1;',
        '%_rolloverHolder': '[fntds]  color:[txt];position:absolute;bottom:0;left:0;right:100px;padding:10px 0 0 0;height:55px;',
        '%title': 'white-space:nowrap;text-overflow:ellipsis;display:block;overflow:hidden;[fntt]',
        '%description': 'white-space:pre-line;text-overflow:ellipsis;display:block;overflow:hidden;',
        '%link': 'white-space:nowrap;text-overflow:ellipsis;display:block;overflow:hidden;text-decoration:underline;color:[linkColor];',
        '%_btn': 'position:absolute;bottom:41px;color:[txt];display:block;font-size:14px;width:10px;height:10px;line-height:10px;text-align:center;',
        '%:hover > %_buttons': 'opacity:1;',
        '%_btn:hover': 'opacity:1;',
        '%_hlp': 'position:absolute;color:[txt];',
        '%_hlp span': 'display:block;',
        '%autoplay': 'right:50px;bottom:40px;',
        '%counter': 'right:25px;bottom:38px;font-size:13px;',
        '%[data-state~="noLink"] %link': 'display:none;',
        '%[data-state~="autoplayOff"] %autoplay > span': 'border:6px solid transparent;border-left:6px solid [txt];',
        '%[data-state~="autoplayOn"] %autoplay > span': 'border-left:3px solid [txt];border-right:3px solid [txt];margin-right:5px;height:12px;width:1px;',
        '%buttonPrev,%buttonNext': 'cursor:pointer;',
        '%buttonPrev': 'border-width:1px 0 0 1px;right:80px;',
        '%buttonNext': 'border-width:0 1px 1px 0;right:0;',
        '%[data-state~="mobileView"] %autoplay,%[data-state~="mobileView"] %title,%[data-state~="mobileView"] %description,%[data-state~="mobileView"] %link':
            'display:none;',
        '%[data-state~="mobileView"] %counter': 'font-size:20px;width:34px;left:50%;margin-left:-14px;bottom:24px;',
        '%[data-state~="mobileView"] %buttonPrev,%[data-state~="mobileView"] %buttonNext': 'left:50%;bottom:27px;height:12px;width:12px;',
        '%[data-state~="mobileView"] %buttonPrev': 'border-width:5px 0 0 5px;margin-left:-50px;',
        '%[data-state~="mobileView"] %buttonNext': 'border-width:0 5px 5px 0;margin-left:35px;'
    }
}
skins['wysiwyg.viewer.skins.siteBackgroundSkin'] = {
    react: [
        ['div', 'previous', [], {}, ['div', 'previousImage', [], {}], ['div', 'previousVideo', [], {}], ['div', 'previousOverlay', [], {}]],
        ['div', 'current', [], {}, ['div', 'currentImage', [], {}], ['div', 'currentVideo', [], {}], ['div', 'currentOverlay', [], {}]]
    ],
    css: {
        '%': 'width:100%;position:absolute;',
        '%bgBeforeTransition': 'position:absolute;top:0;',
        '%bgAfterTransition': 'position:absolute;top:0;'
    }
}
skins['wysiwyg.viewer.skins.button.BasicButton'] = {
    react: [['a', 'link', ['_g!g-transparent-a'], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        brw: 'BORDER_SIZE',
        bg: 'BG_COLOR_ALPHA',
        brd: 'BORDER_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        brw: '0',
        bg: 'color_17',
        brd: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;[trans1]  [shd]',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%[data-state~="shouldUseFlex"] %label': 'margin:0;',
        '%[data-disabled="false"] %link': 'background-color:[bg];border:solid [brd] [brw];cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.BorderButton'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        rd2: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        xxx: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        brdh: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        brdd: 'BORDER_COLOR_ALPHA',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        brd: 'BG_COLOR'
    },
    paramsDefaults: {
        rd: '10px',
        rd2: '5px',
        bg: 'color_1',
        xxx: 'color_1',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_5',
        txt: 'color_5',
        brdh: 'color_6',
        bgh: 'color_2',
        txth: 'color_6',
        brdd: '#CCCCCC',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF',
        brd: 'color_5'
    },
    paramsMutators: {
        brd: {
            type: 'alpha',
            value: 0.6
        }
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': '[rd]  background-color:[brd];',
        '%_bg': '[rd2]  background-color:[bg];position:absolute;top:5px;bottom:5px;left:5px;right:5px;background:[xxx] url([tdr]net.png) center center repeat;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%label': 'position:relative;[fnt]  color:[txt];white-space:nowrap;padding:15px;',
        '%[data-disabled="false"]': 'cursor:pointer;',
        '%[data-disabled="false"]:active[data-state~="mobile"],%[data-disabled="false"]:hover[data-state~="desktop"],%[data-disabled="false"][data-preview~="hover"]':
            'background-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_bg,%[data-disabled="false"]:hover[data-state~="desktop"] %_bg,%[data-disabled="false"][data-preview~="hover"] %_bg':
            'background-color:[bgh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="true"],%[data-preview~="disabled"]': 'border-color:[brdd];',
        '%[data-disabled="true"] %_bg,%[data-preview~="disabled"] %_bg': 'background-color:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.BrandButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
        shadowColor2: 'BOX_SHADOW_COLOR_ALPHA',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        trans1: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bga: 'BG_COLOR_ALPHA',
        brda: 'BORDER_COLOR_ALPHA',
        txta: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        shadowColor: '#000102',
        shadowColor2: '#000102',
        brd: 'color_15',
        brw: '0',
        trans1: 'border-color 0.2s ease 0s, background-color 0.2s ease 0s',
        fnt: 'font_5',
        txt: 'color_15',
        trans2: 'color 0.2s ease 0s',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bga: 'color_17',
        brda: 'color_15',
        txta: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];box-shadow:0px 1px 1px 0px [shadowColor], 0px 4px 6px 0px [shadowColor2];border:solid [brd] [brw];[trans1]',
        '%label': '[fnt]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;[trans2]',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="false"]:active[data-state~="desktop"] %link': 'background-color:[bga];border-color:[brda];-webkit-transition:none;transition:none;',
        '%[data-disabled="false"]:active[data-state~="desktop"] %label': 'color:[txta];-webkit-transition:none;transition:none;',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonArrow'] = {
    react: [
        ['div', null, ['_arr', '_top'], {}],
        ['div', null, ['_arr', '_bottom'], {}],
        ['div', null, ['_bg'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    exports: {
        maxH: 100
    },
    params: {
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bgh: 'color_18',
        txth: 'color_15',
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'overflow:hidden;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_bg,%[data-disabled="false"]:hover[data-state~="desktop"] %_bg,%[data-disabled="false"][data-preview~="hover"] %_bg':
            'background:[bgh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_top,%[data-disabled="false"]:hover[data-state~="desktop"] %_top,%[data-disabled="false"][data-preview~="hover"] %_top':
            'border-bottom-color:[bgh] !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_bottom,%[data-disabled="false"]:hover[data-state~="desktop"] %_bottom,%[data-disabled="false"][data-preview~="hover"] %_bottom':
            'border-top-color:[bgh] !important;',
        '%_bg': 'background:[bg];position:absolute;top:0;right:50px;bottom:0;left:0;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%label': '[fnt]  color:[txt];display:inline-block;position:relative;white-space:nowrap;',
        '%_top,%_bottom': 'border-left:0;border-right:50px solid transparent;position:absolute;right:0;',
        '%[data-state~="mobile"] %_top,%[data-state~="mobile"] %_bottom': 'right:1px;',
        '%_top': 'border-bottom:50px solid [bg];bottom:50% !important;',
        '%[data-state~="mobile"] %_top': '-webkit-transform:scale(1.01);transform:scale(1.01);',
        '%_bottom': 'border-top:50px solid [bg];top:50% !important;',
        '%[data-disabled="true"] %_bg,%[data-preview~="disabled"] %_bg': 'background:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];',
        '%[data-disabled="true"] %_top,%[data-preview~="disabled"] %_top': 'border-bottom-color:[bgd];',
        '%[data-disabled="true"] %_bottom,%[data-preview~="disabled"] %_bottom': 'border-top-color:[bgd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonArrowLeft'] = {
    react: [
        ['div', null, ['_arr', '_top'], {}],
        ['div', null, ['_arr', '_bottom'], {}],
        ['div', null, ['_bg'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bgh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bgh: 'color_18',
        txth: 'color_15',
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'overflow:hidden;-webkit-transform:rotate(360deg);transform:rotate(360deg);',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_bg,%[data-disabled="false"]:hover[data-state~="desktop"] %_bg,%[data-disabled="false"][data-preview~="hover"] %_bg':
            'background:[bgh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_top,%[data-disabled="false"]:hover[data-state~="desktop"] %_top,%[data-disabled="false"][data-preview~="hover"] %_top':
            'border-bottom-color:[bgh] !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_bottom,%[data-disabled="false"]:hover[data-state~="desktop"] %_bottom,%[data-disabled="false"][data-preview~="hover"] %_bottom':
            'border-top-color:[bgh] !important;',
        '%_bg': 'background:[bg];position:absolute;top:0;right:0;bottom:0;left:50px;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%label': '[fnt]  color:[txt];display:inline-block;position:relative;white-space:nowrap;',
        '%_top,%_bottom': 'border-left:50px solid transparent;border-right:0;left:0;position:absolute;',
        '%[data-state~="mobile"] %_top,%[data-state~="mobile"] %_bottom': 'left:1px;',
        '%_top': 'border-bottom:50px solid [bg];bottom:50%;',
        '%[data-state~="mobile"] %_top': '-webkit-transform:scale(1.01);transform:scale(1.01);',
        '%_bottom': 'border-top:50px solid [bg];top:50%;',
        '%[data-disabled="true"] %_bg,%[data-preview~="disabled"] %_bg': 'background:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];',
        '%[data-disabled="true"] %_top,%[data-preview~="disabled"] %_top': 'border-bottom-color:[bgd];',
        '%[data-disabled="true"] %_bottom,%[data-preview~="disabled"] %_bottom': 'border-top-color:[bgd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonDoubleArrowLeft'] = {
    react: [
        [
            'div',
            null,
            ['_wrapper'],
            {},
            ['div', null, ['_container', '_left'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_top', '_right'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom', '_right'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_filler'], {}]
        ],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txth: 'TEXT_COLOR',
        bgh: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        txth: 'color_15',
        bgh: 'color_18'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;width:50%;',
        '%_container': 'position:absolute;height:50%;overflow:hidden;width:80%;',
        '%_left': 'height:100%;',
        '%_left %_ribbon': 'height:100%;border:0;',
        '%_right': 'left:120%;',
        '%_bottom': 'bottom:0;',
        '%_ribbon': 'position:absolute;width:100%;height:0;border-style:solid;',
        '%_right %_ribbon': 'right:0;',
        '%_top %_ribbon': 'top:0;',
        '%_bottom %_ribbon': 'bottom:0;',
        '%_top%_right %_ribbon': 'border-color:[bg] transparent transparent transparent;border-width:500px 500px 0 0;',
        '%_bottom%_right %_ribbon': 'border-color:transparent transparent [bg] transparent;border-width:0 500px 500px 0;',
        '%_left %_ribbon:before':
            'border-width:500px;position:absolute;content:"";overflow:hidden;left:0;width:100%;border-style:solid;border-color:[bg] transparent;top:50%;',
        '%_left %_ribbon:after':
            'border-width:500px;position:absolute;content:"";overflow:hidden;left:0;width:100%;border-style:solid;border-color:[bg] transparent;bottom:50%;',
        '%_filler': 'position:absolute;background-color:[bg];width:40%;height:100%;left:80%;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_top%_right %_ribbon,%:hover[data-state~="desktop"] %_top%_right %_ribbon,%[data-preview~="hover"] %_top%_right %_ribbon':
            'border-top-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom%_right %_ribbon,%:hover[data-state~="desktop"] %_bottom%_right %_ribbon,%[data-preview~="hover"] %_bottom%_right %_ribbon':
            'border-bottom-color:[bgh];',
        '%:active[data-state~="mobile"] %_left %_ribbon:before,%:hover[data-state~="desktop"] %_left %_ribbon:before,%[data-preview~="hover"] %_left %_ribbon:before':
            'border-color:[bgh] transparent;',
        '%:active[data-state~="mobile"] %_left %_ribbon:after,%:hover[data-state~="desktop"] %_left %_ribbon:after,%[data-preview~="hover"] %_left %_ribbon:after':
            'border-color:[bgh] transparent;',
        '%:active[data-state~="mobile"] %_filler,%:hover[data-state~="desktop"] %_filler,%[data-preview~="hover"] %_filler': 'background-color:[bgh];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonDoubleArrowRight'] = {
    react: [
        [
            'div',
            null,
            ['_wrapper'],
            {},
            ['div', null, ['_container', '_top', '_left'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom', '_left'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_right'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_filler'], {}]
        ],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txth: 'TEXT_COLOR',
        bgh: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        txth: 'color_15',
        bgh: 'color_18'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;width:50%;',
        '%_container': 'position:absolute;height:50%;overflow:hidden;width:80%;',
        '%_right': 'left:120%;height:100%;',
        '%_bottom': 'bottom:0;',
        '%_ribbon': 'position:absolute;width:0;height:0;border-style:solid;',
        '%_right %_ribbon': 'right:0;height:100%;',
        '%_top %_ribbon': 'top:0;',
        '%_bottom %_ribbon': 'bottom:0;',
        '%_top%_left %_ribbon': 'border-color:transparent [bg] transparent transparent;border-width:0 500px 500px 0;',
        '%_bottom%_left %_ribbon': 'border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;',
        '%_right %_ribbon:before':
            'position:absolute;content:"";overflow:hidden;right:0;width:100%;border-style:solid;border-color:[bg] transparent;border-width:500px 500px 0 0;top:50%;',
        '%_right %_ribbon:after':
            'position:absolute;content:"";overflow:hidden;right:0;width:100%;border-style:solid;border-color:[bg] transparent;border-width:0 500px 500px 0;bottom:50%;',
        '%_filler': 'position:absolute;width:50%;background-color:[bg];width:40%;height:100%;left:80%;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_top%_left %_ribbon,%:hover[data-state~="desktop"] %_top%_left %_ribbon,%[data-preview~="hover"] %_top%_left %_ribbon':
            'border-right-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom%_left %_ribbon,%:hover[data-state~="desktop"] %_bottom%_left %_ribbon,%[data-preview~="hover"] %_bottom%_left %_ribbon':
            'border-bottom-color:[bgh];',
        '%:active[data-state~="mobile"] %_right %_ribbon:before,%:hover[data-state~="desktop"] %_right %_ribbon:before,%[data-preview~="hover"] %_right %_ribbon:before':
            'border-color:[bgh] transparent;',
        '%:active[data-state~="mobile"] %_right %_ribbon:after,%:hover[data-state~="desktop"] %_right %_ribbon:after,%[data-preview~="hover"] %_right %_ribbon:after':
            'border-color:[bgh] transparent;',
        '%:active[data-state~="mobile"] %_filler,%:hover[data-state~="desktop"] %_filler,%[data-preview~="hover"] %_filler': 'background-color:[bgh];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonForkedLeft'] = {
    react: [
        [
            'div',
            null,
            ['_wrapper'],
            {},
            ['div', null, ['_container', '_top'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom'], {}, ['div', null, ['_ribbon'], {}]]
        ],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txth: 'TEXT_COLOR',
        bgh: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        txth: 'color_15',
        bgh: 'color_18'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%_wrapper': 'position:absolute;top:0;bottom:0;right:0;width:50%;background-color:[bg];',
        '%_container': 'position:absolute;height:50%;overflow:hidden;width:100%;right:100%;',
        '%_bottom': 'bottom:0;',
        '%_ribbon': 'position:absolute;width:0;height:0;left:0;border-style:solid;',
        '%_top %_ribbon': 'top:0;border-width:0 500px 500px 0;border-color:transparent [bg] transparent transparent;',
        '%_bottom %_ribbon': 'bottom:0;border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_top %_ribbon,%:hover[data-state~="desktop"] %_top %_ribbon,%[data-preview~="hover"] %_top %_ribbon':
            'border-right-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom %_ribbon,%:hover[data-state~="desktop"] %_bottom %_ribbon,%[data-preview~="hover"] %_bottom %_ribbon':
            'border-bottom-color:[bgh];',
        '%:active[data-state~="mobile"] %_wrapper,%:hover[data-state~="desktop"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'background-color:[bgh];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonForkedRight'] = {
    react: [
        [
            'div',
            null,
            ['_wrapper'],
            {},
            ['div', null, ['_container', '_top'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom'], {}, ['div', null, ['_ribbon'], {}]]
        ],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txth: 'TEXT_COLOR',
        bgh: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        txth: 'color_15',
        bgh: 'color_18'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;width:50%;background-color:[bg];',
        '%_container': 'position:absolute;height:50%;overflow:hidden;width:100%;left:100%;',
        '%_bottom': 'bottom:0;',
        '%_ribbon': 'position:absolute;width:0;height:0;right:0;border-style:solid;',
        '%_top %_ribbon': 'top:0;border-width:500px 500px 0 0;border-color:[bg] transparent transparent transparent;',
        '%_bottom %_ribbon': 'bottom:0;border-color:transparent transparent transparent [bg];border-width:500px 0 0 500px;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_top %_ribbon,%:hover[data-state~="desktop"] %_top %_ribbon,%[data-preview~="hover"] %_top %_ribbon':
            'border-top-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom %_ribbon,%:hover[data-state~="desktop"] %_bottom %_ribbon,%[data-preview~="hover"] %_bottom %_ribbon':
            'border-left-color:[bgh];',
        '%:active[data-state~="mobile"] %_wrapper,%:hover[data-state~="desktop"] %_wrapper,%[data-preview~="hover"] %_wrapper': 'background-color:[bgh];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonInnerShadow'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];box-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.75);',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonLiftedShadow'] = {
    react: [
        ['div', null, ['_left', '_shd'], {}],
        ['div', null, ['_right', '_shd'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        tdr: 'URL',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%_shd': 'background:url([tdr]liftedshadow_medium.png) no-repeat;bottom:-26px;height:26px;position:absolute;width:165px;pointer-events:none;',
        '%_left': 'background-position:0 0;left:-20px;',
        '%_right': 'background-position:100% 0;right:-20px;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonSandclock'] = {
    react: [
        [
            'div',
            null,
            ['_wrapper'],
            {},
            ['div', null, ['_container', '_top', '_left'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom', '_left'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_top', '_right'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_container', '_bottom', '_right'], {}, ['div', null, ['_ribbon'], {}]],
            ['div', null, ['_filler'], {}]
        ],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bg: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txth: 'TEXT_COLOR',
        bgh: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        txth: 'color_15',
        bgh: 'color_18'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': '-webkit-transform:rotate(360deg);transform:rotate(360deg);',
        '%_wrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;width:50%;',
        '%_container': 'position:absolute;height:50%;overflow:hidden;width:80%;',
        '%_right': 'left:120%;',
        '%_bottom': 'bottom:0;',
        '%_ribbon': 'position:absolute;width:0;height:0;border-style:solid;',
        '%_right %_ribbon': 'right:0;',
        '%_top %_ribbon': 'top:0;',
        '%_bottom %_ribbon': 'bottom:0;',
        '%_top%_left %_ribbon': 'border-color:transparent [bg] transparent transparent;border-width:0 500px 500px 0;',
        '%_bottom%_left %_ribbon': 'border-color:transparent transparent [bg] transparent;border-width:0 0 500px 500px;',
        '%_top%_right %_ribbon': 'border-color:[bg] transparent transparent transparent;border-width:500px 500px 0 0;',
        '%_bottom%_right %_ribbon': 'border-color:transparent transparent transparent [bg];border-width:500px 0 0 500px;',
        '%_filler': 'position:absolute;width:50%;background-color:[bg];width:40%;height:100%;left:80%;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;height:100%;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_top%_left %_ribbon,%:hover[data-state~="desktop"] %_top%_left %_ribbon,%[data-preview~="hover"] %_top%_left %_ribbon':
            'border-right-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom%_left %_ribbon,%:hover[data-state~="desktop"] %_bottom%_left %_ribbon,%[data-preview~="hover"] %_bottom%_left %_ribbon':
            'border-bottom-color:[bgh];',
        '%:active[data-state~="mobile"] %_top%_right %_ribbon,%:hover[data-state~="desktop"] %_top%_right %_ribbon,%[data-preview~="hover"] %_top%_right %_ribbon':
            'border-top-color:[bgh];',
        '%:active[data-state~="mobile"] %_bottom%_right %_ribbon,%:hover[data-state~="desktop"] %_bottom%_right %_ribbon,%[data-preview~="hover"] %_bottom%_right %_ribbon':
            'border-left-color:[bgh];',
        '%:active[data-state~="mobile"] %_filler,%:hover[data-state~="desktop"] %_filler,%[data-preview~="hover"] %_filler': 'background-color:[bgh];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonShadowLeft'] = {
    react: [
        ['div', null, ['_shd'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        tdr: 'URL',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%_shd': 'background:url([tdr]liftedshadow_medium.png) no-repeat 0 0;bottom:-26px;height:26px;left:-20px;position:absolute;width:165px;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonShadowRight'] = {
    react: [
        ['div', null, ['_shd'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        tdr: 'URL',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%_shd': 'background:url([tdr]liftedshadow_medium.png) no-repeat 100% 0;bottom:-26px;height:26px;position:absolute;right:-20px;width:165px;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ButtonThreeD'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        shc: 'COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        bg: 'color_17',
        brd: 'color_15',
        brw: '0',
        trans: 'all 0.12s ease 0s',
        fnt: 'font_5',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF',
        shc: ['bg']
    },
    paramsMutators: {
        shc: {
            type: 'brightness',
            value: 0.5,
            param: 'bg'
        }
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:solid [brd] [brw];box-shadow:1px 1px [shc], 3px 3px [shc], 5px 5px [shc], 7px 7px [shc], 9px 9px [shc];[trans]',
        '%label': '[fnt]  [trans]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;',
        '%[data-state~="shouldUseFlex"] %label': 'margin-top:0;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];bottom:-9px;box-shadow:0 0 [shc];left:9px;right:-9px;top:9px;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.CircleButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        s: 'SIZE'
    },
    paramsDefaults: {
        bg: 'color_17',
        trans1: 'border 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brd: 'color_15',
        brw: '0',
        rd: '50%',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF',
        s: ['brw']
    },
    paramsMutators: {
        s: {
            type: 'decrease',
            value: 5,
            param: 'brw'
        }
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];[rd]',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:calc(-1 * [brw]) [brw] 0;position:relative;white-space:nowrap;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border:solid [brdh] [s];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.DisabledLayerButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  border:solid [brd] [brw];cursor:pointer !important;',
        '%label': '[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:1px [brw] 0 [brw];display:inline-block;position:relative;',
        '%:active[data-state~="mobile"] %link,%:hover[data-state~="desktop"] %link,%[data-preview~="hover"] %link': '[trans1]',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': '[trans2]',
        '%[data-disabled~="disabled"] %link': 'opacity:0.5;cursor:not-allowed !important;'
    }
}
skins['wysiwyg.viewer.skins.button.FixedFontButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        txt: 'TEXT_COLOR',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        txth: 'TEXT_COLOR',
        brdh: 'BORDER_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        bg: '#ffffff',
        rd: '0',
        txt: '#000000',
        brd: '#000000',
        brw: '0',
        txth: '#000000',
        brdh: '#000000',
        bgh: '#ffffff',
        trans: 'color 0.4s ease 0s, border-color 0.4s ease 0s, background-color 0.4s ease 0s'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  color:[txt];border:solid [brd] [brw];cursor:pointer !important;',
        '%label':
            'display:block;margin:-10px 0 0 -10px;text-align:center;width:20px;height:20px;line-height:20px;position:absolute;top:50%;left:50%;white-space:nowrap;font-size:12px !important;font-family:Arial;',
        '%:active[data-state~="mobile"] %link,%:hover[data-state~="desktop"] %link,%[data-preview~="hover"] %link':
            'color:[txth];border-color:[brdh];background-color:[bgh];[trans]',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];[trans]',
        '%_justx': 'width:20px;height:20px;',
        '%_justx %link': 'font-size:16px !important;',
        '%_circle': 'width:20px;height:20px;',
        '%_circle %link': 'border-radius:50% !important;'
    }
}
skins['wysiwyg.viewer.skins.button.GamingButton'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            ['span', null, ['_left'], {}],
            ['span', null, ['_right'], {}],
            ['span', 'label', [], {}],
            ['div', null, ['_shineOnYouCrazyDiamond'], {}]
        ]
    ],
    params: {
        bgh: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        txth: 'TEXT_COLOR',
        rd: 'BORDER_RADIUS',
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bgh: 'color_18',
        trans1: 'background-color 0.4s ease 0s',
        txth: 'color_15',
        rd: '12px',
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_17',
        fnt: 'font_6',
        txt: 'color_15',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%[data-disabled="false"]': 'overflow:visible !important;cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  background:url([tdr]gaming_pattern.png) repeat 0 0;background-color:[bg];border:solid #000 2px;box-shadow:rgba(255, 255, 255, 0.3) 0 3px 6px 0 inset, rgba(0, 0, 0, 0.6) -2px -3px 6px 0 inset, rgba(0, 0, 0, 0.6) 1px 1px 6px 1px, rgba(255, 255, 255, 0.3) 1px 1px 3px 0;width:100%;',
        '%_left,%_right': 'background-position:0 center;background-repeat:no-repeat;height:100%;position:absolute;width:66px;',
        '%_left': 'background-image:url([tdr]darknessleft.png);border-radius:10px 0 0 10px;left:0;',
        '%_right': 'background-image:url([tdr]darknessright.png);border-radius:0 10px 10px 0;right:0;',
        '%label': '[fnt]  color:[txt];display:inline-block;margin-top:-1px;position:relative;white-space:nowrap;',
        '%_shineOnYouCrazyDiamond':
            "bottom:50%;top:0;left:0;background:url([tdr]whitegradient.png) repeat-x 0 100%;overflow:hidden;border-radius:12px 12px 0 0;width:100%;content:'';position:absolute;",
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.IronButton'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            ['div', null, ['_glow'], {}],
            ['div', null, ['_screw-tl', '_screw'], {}],
            ['div', null, ['_screw-tr', '_screw'], {}],
            ['div', null, ['_screw-bt', '_screw'], {}],
            ['div', null, ['_screw-br', '_screw'], {}],
            ['span', 'label', [], {}]
        ]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        tdr: 'URL',
        txt: 'TEXT_COLOR',
        ishd: 'BOX_SHADOW',
        fnt: 'FONT',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '2',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s, color 0.4s ease 0s',
        shd: '0 2px 5px rgba(0, 0, 0, 0.53)',
        tdr: 'BASE_THEME_DIRECTORY',
        txt: 'color_15',
        ishd: 'rgba(255, 255, 255, 0.59) 0 0 6px 0 inset, rgba(255, 255, 255, 0.92) 0 1px 0 0 inset, rgba(255, 255, 255, 0.2) 0 0 5px 0 inset',
        fnt: 'font_5',
        bgh: 'color_17',
        txth: 'color_15',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  [shd]  background-image:url([tdr]ironpatern.png);color:[txt];',
        '%_glow': 'position:absolute;top:0;right:0;bottom:0;left:0;[ishd]  [rd]',
        '%label': '[fnt]  [trans1]  color:[txt];display:inline-block;position:relative;white-space:nowrap;',
        '%_screw': 'width:15px;height:15px;background-image:url([tdr]skrew.png);background-repeat:no-repeat;display:inline-block;',
        '%_screw-tl,%_screw-tr,%_screw-bt,%_screw-br': 'position:absolute;',
        '%_screw-tl': 'left:2px;top:2px;',
        '%_screw-tr': 'right:2px;top:2px;',
        '%_screw-bt': 'bottom:2px;left:2px;',
        '%_screw-br': 'bottom:2px;right:2px;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.PillButton'] = {
    react: [['a', 'link', ['_lnk'], {}, ['span', 'label', [], {}]]],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        xxx: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_1',
        rd: '80px',
        xxx: 'color_1',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_5',
        txt: 'color_5',
        bgh: 'color_2',
        txth: 'color_6'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background:[xxx] url([tdr]net.png) center center repeat;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;padding:0 10px;',
        '%:active[data-state~="mobile"] %link,%:hover[data-state~="desktop"] %link,%[data-preview~="hover"] %link': 'background-color:[bgh];',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];'
    }
}
skins['wysiwyg.viewer.skins.button.PlasticButton'] = {
    react: [
        ['div', null, ['_pose', '_mainColor'], {}],
        ['div', null, ['_pose', '_grd1'], {}],
        ['div', null, ['_pose', '_topper'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]],
        ['div', null, ['_pose', '_bord'], {}]
    ],
    exports: {
        _maxWidth: 400,
        _maxHeight: 400
    },
    params: {
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        txth: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        xxx: 'BG_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        txt: 'color_5',
        fnt: 'font_5',
        txth: 'color_6',
        bgh: 'color_2',
        rd: '15px',
        bg: 'color_1',
        xxx: 'color_1',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'text-align:center;',
        '%label': 'position:relative;color:[txt];[fnt]  white-space:nowrap;padding:15px 10px;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_mainColor,%:hover[data-state~="desktop"] %_mainColor,%[data-preview~="hover"] %_mainColor': 'background-color:[bgh];',
        '%_pose': 'position:absolute;top:0;right:0;bottom:0;left:0;[rd]',
        '%_mainColor': 'background-color:[bg];background:[xxx] url([tdr]net.png) center center repeat;',
        '%_topper': 'background:url([tdr]transparent_arc.png) center center;',
        '%_oval': 'background:#00f;width:200%;height:50%;',
        '%_bord':
            'border-width:11px;-webkit-border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;'
    }
}
skins['wysiwyg.viewer.skins.button.RibbonButton'] = {
    react: [
        ['a', 'link', [], {}, ['span', 'label', [], {}]],
        [
            'div',
            null,
            ['_tail'],
            {},
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}],
            ['div', null, ['_tri'], {}]
        ]
    ],
    exports: {
        maxH: 170
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        clrh: 'COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        clr: 'COLOR_ALPHA',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        clrd: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_17',
        bgh: 'color_18',
        txth: 'color_15',
        clrh: ['bgh'],
        fnt: 'font_5',
        txt: 'color_15',
        clr: ['bg'],
        bgd: '#CCCCCC',
        txtd: '#FFFFFF',
        clrd: ['bgd']
    },
    css: {
        '% button%link': 'width:100%;width:calc(100% - 5px);',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': 'background-color:[bg];position:absolute;top:0;right:5px;bottom:0;left:0;',
        '%[data-disabled="false"]': 'cursor:pointer !important;max-height:170px !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %_tri,%[data-disabled="false"]:hover[data-state~="desktop"] %_tri,%[data-disabled="false"][data-preview~="hover"] %_tri':
            'border-left-color:[clrh];',
        '%label': '[fnt]  color:[txt];display:inline-block;max-height:170px !important;padding:5px 10px;white-space:nowrap;',
        '%_tail': 'overflow:hidden;width:5px;position:absolute;top:0;right:0;bottom:0;left:auto;',
        '%_tri': 'border:solid 5px transparent;border-left-color:[clr];',
        '%_tri:first-child': 'margin-top:-5px;',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];',
        '%[data-disabled="true"] %_tri,%[data-preview~="disabled"] %_tri': 'border-left-color:[clrd];'
    }
}
skins['wysiwyg.viewer.skins.button.ScotchTapeButton'] = {
    react: [
        ['div', null, ['_left'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]],
        ['div', null, ['_right'], {}]
    ],
    params: {
        txth: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txtd: 'TEXT_COLOR',
        brd2: 'COLOR_ALPHA',
        bg: 'COLOR_ALPHA',
        brd: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        txth: 'color_15',
        trans2: 'color 0.4s ease 0s',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_5',
        txt: 'color_15',
        txtd: '#FFFFFF',
        brd2: '#fff',
        bg: '#000',
        brd: '#000'
    },
    paramsMutators: {
        brd2: {
            type: 'alpha',
            value: 0.12
        },
        bg: {
            type: 'alpha',
            value: 0.01
        },
        brd: {
            type: 'alpha',
            value: 0.13
        }
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%:before,%:after': "border-bottom:solid 1px [brd2];content:'';display:block;height:7px;left:1px;position:absolute;right:1px;",
        '%:before': 'top:-7px;',
        '%:after': 'bottom:-7px;',
        '%[data-disabled="false"]': 'cursor:pointer;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;background:[bg];border-bottom:solid 1px [brd];border-top:solid 1px [brd];display:block;',
        '%_left,%_right':
            "background:transparent url([tdr]scotchtape_edges.png) repeat-y;bottom:0;content:'';display:block;position:absolute;top:0;width:10px;",
        '%_left': 'background-position:right -2px;left:-7px;',
        '%_right': 'background-position:left -2px;right:-7px;',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;position:relative;white-space:nowrap;',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ShineButton'] = {
    react: [['a', 'link', ['_lnk'], {}, ['span', 'label', [], {}]]],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        tdr: 'URL',
        xxx: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_1',
        rd: '5px',
        tdr: 'BASE_THEME_DIRECTORY',
        xxx: 'color_1',
        fnt: 'font_5',
        txt: 'color_5',
        bgh: 'color_2',
        txth: 'color_6'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background-image:url([tdr]apple_menu.png);background-position:0 50%;background-repeat:repeat-x;background:[xxx] url([tdr]net.png) center center repeat;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;padding:0 10px;',
        '%:active[data-state~="mobile"] %link,%:hover[data-state~="desktop"] %link,%[data-preview~="hover"] %link': 'background-color:[bgh];',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];'
    }
}
skins['wysiwyg.viewer.skins.button.ShinyButtonIISkin'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bg: 'color_17',
        tdr: 'BASE_THEME_DIRECTORY',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background:[bg] url([tdr]shiny2button_bg.png) center top repeat-x;border:solid [brd] [brw];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;',
        '%[data-state~="shouldUseFlex"] %label': 'margin-top:0;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ShinyButtonInverted'] = {
    react: [['a', 'link', [], {}, ['div', 'labelwrapper', [], {}, ['span', 'label', [], {}]]]],
    params: {
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bg: 'color_17',
        tdr: 'BASE_THEME_DIRECTORY',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background-color:[bg];background-image:url([tdr]indented_bg.png);background-position:0 0;background-repeat:repeat-x;border:solid [brd] [brw];',
        '%labelwrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  background:url([tdr]indented_bg.png) 0 100% repeat-x;',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;',
        '%[data-state~="shouldUseFlex"] %label': 'margin-top:0;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];border-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            '[trans2]    color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ShinyButtonISkin'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        trans1: 'TRANSITION',
        shd: 'BOX_SHADOW',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        brdd: 'BORDER_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        bg: 'color_17',
        tdr: 'BASE_THEME_DIRECTORY',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_5',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15',
        bgd: '#CCCCCC',
        brdd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [trans1]  [shd]  background:[bg] url([tdr]shiny1button_bg.png) 50% 50% repeat-x;border:solid [brd] [brw];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin-top:calc(-1 * [brw]);position:relative;white-space:nowrap;',
        '%[data-state~="shouldUseFlex"] %label': 'margin-top:0;',
        '%[data-disabled="false"]': 'cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            '[trans1]    background-color:[bgh];border-color:[brdh];',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            '[trans2]    color:[txth];',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];border-color:[brdd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ShinyGradientButton'] = {
    react: [
        ['div', null, ['_pose', '_mainColor'], {}],
        ['div', null, ['_pose', '_grd1'], {}],
        ['div', null, ['_pose', '_topper'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]],
        ['div', null, ['_pose', '_bord'], {}]
    ],
    exports: {
        _maxWidth: 400,
        _maxHeight: 400
    },
    params: {
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        txth: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        xxx: 'BG_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        txt: 'color_5',
        fnt: 'font_5',
        txth: 'color_6',
        bgh: 'color_2',
        rd: '15px',
        xxx: 'color_1',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'text-align:center;',
        '%label': 'position:relative;color:[txt];[fnt]  white-space:nowrap;padding:15px 10px;display:inline-block;',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];',
        '%:active[data-state~="mobile"] %_mainColor,%:hover[data-state~="desktop"] %_mainColor,%[data-preview~="hover"] %_mainColor': 'background-color:[bgh];',
        '%_pose': 'position:absolute;top:0;right:0;bottom:0;left:0;[rd]',
        '%_mainColor': 'background:[xxx] url([tdr]net.png) center center repeat;',
        '%_topper': 'background:url([tdr]transparent_arc.png) center center;',
        '%_oval': 'background:#00f;width:200%;height:50%;',
        '%_bord':
            'border-width:11px;-webkit-border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;border-image:url([tdr]plastic_highlight_border.png) 11 stretch stretch;'
    }
}
skins['wysiwyg.viewer.skins.button.ShinyPillButton'] = {
    react: [['a', 'link', ['_lnk'], {}, ['span', 'label', [], {}]]],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        tdr: 'URL',
        xxx: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_1',
        rd: '80px',
        tdr: 'BASE_THEME_DIRECTORY',
        xxx: 'color_1',
        fnt: 'font_5',
        txt: 'color_5',
        bgh: 'color_2',
        txth: 'color_6'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link':
            'position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[rd]  background-image:url([tdr]apple_menu.png);background-position:0 50%;background-repeat:repeat-x;background:[xxx] url([tdr]net.png) center center repeat;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;padding:0 10px;display:inline-block;',
        '%:active[data-state~="mobile"] %link,%:hover[data-state~="desktop"] %link,%[data-preview~="hover"] %link': 'background-color:[bgh];',
        '%:active[data-state~="mobile"] %label,%:hover[data-state~="desktop"] %label,%[data-preview~="hover"] %label': 'color:[txth];'
    }
}
skins['wysiwyg.viewer.skins.button.SiteButtonBlueSkin'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        borderColor: 'BORDER_COLOR',
        rd: 'BORDER_RADIUS',
        bgColor: 'BG_COLOR_ALPHA',
        borderColorOver: 'BORDER_COLOR',
        bgColorOver: 'BG_COLOR_ALPHA',
        shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
        shadowInsetColorOver: 'BOX_SHADOW_COLOR_ALPHA',
        labelColor: 'TEXT_COLOR'
    },
    paramsDefaults: {
        borderColor: '#5b8fa7',
        rd: '4px',
        bgColor: '#2aa7ea',
        borderColorOver: '#237aa7',
        bgColorOver: '#179ce1',
        shadowColor: '0, 0, 0, 0.2',
        shadowInsetColorOver: '68, 175, 233, 0.7',
        labelColor: '#ffffff'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'padding:0 8px;border:1px solid [borderColor];[rd]  background-color:[bgColor];position:relative;text-align:center;cursor:pointer;',
        '%:active[data-state~="mobile"],%:hover[data-state~="desktop"],%[data-preview~="hover"]':
            'border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;',
        '%label': 'color:[labelColor];height:1.8em;line-height:1.8em;display:inline-block;'
    }
}
skins['wysiwyg.viewer.skins.button.SiteButtonSkin'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        xxx: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        fnt: 'FONT',
        clr: 'TEXT_COLOR'
    },
    paramsDefaults: {
        xxx: 'color_1',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_5',
        clr: 'color_1'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;background:[xxx] url([tdr]net.png) center center repeat;',
        '%label': '[fnt]  color:[clr];white-space:nowrap;display:inline-block;'
    }
}
skins['wysiwyg.viewer.skins.button.SloopyButton'] = {
    react: [
        ['div', null, ['_brd', '_top'], {}],
        ['div', null, ['_brd', '_bot'], {}],
        ['a', 'link', [], {}, ['span', 'label', [], {}]]
    ],
    params: {
        bgh: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        txth: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bgh: 'color_18',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        txth: 'color_15',
        trans2: 'color 0.4s ease 0s',
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_17',
        fnt: 'font_5',
        txt: 'color_15',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%[data-disabled="false"]': 'overflow:visible !important;cursor:pointer !important;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %link,%[data-disabled="false"]:hover[data-state~="desktop"] %link,%[data-disabled="false"][data-preview~="hover"] %link':
            'background-color:[bgh];[trans1]',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans2]',
        '%_brd': 'background:url([tdr]sloppyframe.png) no-repeat;position:absolute;',
        '%_top': 'top:-4px;right:0;bottom:0;left:-4px;background-position:0 0;',
        '%_bot': 'top:0;right:-4px;bottom:-4px;left:0;background-position:100% 100%;',
        '%link': 'position:absolute;top:4px;right:4px;bottom:4px;left:4px;[trans1]  background-color:[bg];',
        '%label': '[fnt]  [trans2]  color:[txt];display:inline-block;margin:-4px 0 0 -4px;position:relative;white-space:nowrap;',
        '%[data-disabled="true"] %link,%[data-preview~="disabled"] %link': 'background-color:[bgd];',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.TextOnlyButtonSkin'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        txth: 'TEXT_COLOR',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        txtd: 'TEXT_COLOR'
    },
    paramsDefaults: {
        txth: 'color_15',
        trans: 'color 0.4s ease 0s',
        fnt: 'font_5',
        txt: 'color_15',
        txtd: '#FFFFFF'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%[data-disabled="false"]': 'cursor:pointer;',
        '%[data-disabled="false"]:active[data-state~="mobile"] %label,%[data-disabled="false"]:hover[data-state~="desktop"] %label,%[data-disabled="false"][data-preview~="hover"] %label':
            'color:[txth];[trans]',
        '%link': 'position:absolute;top:0;right:0;bottom:0;left:0;',
        '%label': '[fnt]  [trans]  color:[txt];white-space:nowrap;display:inline-block;',
        '%[data-disabled="true"] %label,%[data-preview~="disabled"] %label': 'color:[txtd];'
    }
}
skins['wysiwyg.viewer.skins.button.ViewerButtonBlueSkin'] = {
    react: [['div', 'caption', [], {}]],
    params: {
        borderColor: 'BORDER_COLOR',
        $borderRadius: 'BORDER_RADIUS',
        bgColor: 'BG_COLOR_ALPHA',
        borderColorOver: 'BORDER_COLOR',
        bgColorOver: 'BG_COLOR_ALPHA',
        shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
        shadowInsetColorOver: 'BOX_SHADOW_COLOR_ALPHA',
        shadowInsetColor: 'BOX_SHADOW_COLOR_ALPHA',
        labelColor: 'TEXT_COLOR',
        borderGrayColor: 'BORDER_COLOR',
        bgGrayColor: 'BG_COLOR_ALPHA',
        borderGrayColorOver: 'BORDER_COLOR',
        bgGrayColorOver: 'BG_COLOR_ALPHA',
        labelGrayColor: 'TEXT_COLOR',
        borderDisabledColor: 'BORDER_COLOR',
        bgDisabledColor: 'BG_COLOR_ALPHA',
        labelDisabledColor: 'TEXT_COLOR'
    },
    paramsDefaults: {
        borderColor: '#5b8fa7',
        $borderRadius: '4px',
        bgColor: '#2aa7ea',
        borderColorOver: '#237aa7',
        bgColorOver: '#179ce1',
        shadowColor: '0, 0, 0, 0.2',
        shadowInsetColorOver: '68, 175, 233, 0.7',
        shadowInsetColor: '0, 0, 0, 0.7',
        labelColor: '#ffffff',
        borderGrayColor: '#c4c4c4',
        bgGrayColor: '#f9fafc',
        borderGrayColorOver: '#89a4af',
        bgGrayColorOver: '#f2f3f5',
        labelGrayColor: '#24a0c4',
        borderDisabledColor: '#d5d5d5',
        bgDisabledColor: '#f9fafc',
        labelDisabledColor: '#c4c4c4'
    },
    css: {
        '% button%link': 'width:100%;',
        '%[data-state~="shouldUseFlex"] %link,%[data-state~="shouldUseFlex"] %labelwrapper':
            'text-align:initial;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;',
        '%[data-state~="shouldUseFlex"][data-state~="center"] %link,%[data-state~="shouldUseFlex"][data-state~="center"] %labelwrapper':
            '-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%[data-state~="shouldUseFlex"][data-state~="left"] %link,%[data-state~="shouldUseFlex"][data-state~="left"] %labelwrapper':
            '-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;',
        '%[data-state~="shouldUseFlex"][data-state~="right"] %link,%[data-state~="shouldUseFlex"][data-state~="right"] %labelwrapper':
            '-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;',
        '%': 'padding:0 8px;border:1px solid [borderColor];[$borderRadius]  background-color:[bgColor];position:relative;text-align:center;cursor:pointer;',
        '%[data-state~="over"]':
            'border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;',
        '%[data-state~="pressed"]': 'background:[bgColor] none;border-color:transparent;box-shadow:0 1px 3px 0 [shadowInsetColor] inset;',
        '%caption': 'color:[labelColor];height:1.8em;line-height:1.8em;',
        '%[data-state~="grayed"]': 'border-color:[borderGrayColor];background-color:[bgGrayColor];',
        '%[data-state~="grayed"][data-state~="over"]':
            'border-color:[borderGrayColorOver];background-color:[bgGrayColorOver];box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 #ffffff inset;',
        '%[data-state~="grayed"][data-state~="pressed"]': 'border-color:transparent;background-color:[bgGrayColor];',
        '%[data-state~="grayed"] %caption': 'color:[labelGrayColor];',
        '%[disabled]': 'border-color:[borderDisabledColor];background-color:[bgDisabledColor];',
        '%[disabled] %caption': 'color:[labelDisabledColor];'
    }
}
skins['skins.viewer.galleryslider.SliderDisplayerIronSkin2'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            [
                'div',
                'imageWrapper',
                [],
                {},
                [
                    'div',
                    null,
                    ['_imgBorder'],
                    {},
                    ['div', 'image', [], {}],
                    ['div', null, ['_imgGlow'], {}],
                    ['div', 'zoom', [], {}, ['div', 'title', [], {}], ['div', 'description', [], {}], ['div', null, ['_imgGlow'], {}]]
                ]
            ]
        ]
    ],
    exports: {
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        shd: 'BOX_SHADOW',
        rd: 'BORDER_RADIUS',
        tdr: 'URL',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        ishd: 'BOX_SHADOW',
        fntds: 'FONT',
        txt: 'TEXT_COLOR'
    },
    paramsDefaults: {
        shd: '0 1px 3px rgba(0, 0, 0, 0.5)',
        rd: '2px',
        tdr: 'BASE_THEME_DIRECTORY',
        bg: 'color_11',
        trans: 'opacity 0.4s ease 0s',
        ishd: 'inset 0 0 6px 0 rgba(255, 255, 255, 0.59), inset 0 1px 0 0 rgba(255, 255, 255, 0.92), inset 0 0 5px 0 0 rgba(255, 255, 255, 0.2)',
        fntds: 'font_8',
        txt: 'color_15'
    },
    css: {
        '%imageWrapper':
            '[shd]  position:absolute;top:0;right:0;bottom:0;left:0;[shd]  [rd]  position:relative;[rd]  background-image:url([tdr]ironpatern.png);',
        '%_imgBorder': 'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  box-sizing:border-box;',
        '%image': '[rd]',
        '%zoom': 'position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:10px;background:[bg];opacity:0;[trans]',
        '%title': 'display:none;',
        '%description': 'display:none;',
        '%_imgGlow': '[ishd]  [rd]  position:absolute;top:0;right:0;bottom:0;left:0;',
        '%link': '[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:pointer !important;',
        '%zoom:hover': 'opacity:1;',
        '%[data-state~="rollover"] %zoom': 'opacity:1;'
    }
}
skins['wysiwyg.viewer.skins.gallery.SliderGalleryBorder'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', null, ['_xxx'], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    params: {
        tdr: 'URL',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'opacity 0.4s ease 0s'
    },
    css: {
        '%itemsContainer': 'position:absolute;left:35px !important;right:35px !important;top:0;bottom:0;border:solid 1px #ff0000;',
        '%_arr': 'position:absolute;top:20%;bottom:20%;width:30px;background:url([tdr]arrows_white_new3.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:0;background-position:0 50%;opacity:0.5;[trans]',
        '%swipeRightHitArea': 'right:0;background-position:100% 50%;opacity:0.5;[trans]',
        '%swipeLeftHitArea:hover': 'opacity:1;[trans]',
        '%swipeRightHitArea:hover': 'opacity:1;[trans]',
        '%_xxx': 'position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;'
    }
}
skins['wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderGalleryDefaultSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', 'counter', [], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    params: {
        trans: 'TRANSITION',
        brd: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        trans: 'opacity 0.4s ease 0s',
        brd: 'color_15'
    },
    css: {
        '%': 'position:relative;',
        '%itemsContainer': 'margin-left:35px;margin-right:35px;height:100%;position:relative;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%_arr': 'position:absolute;top:0;bottom:0;width:30px;cursor:pointer;opacity:0.5;[trans]',
        '%swipeLeftHitArea': 'left:0;',
        '%swipeRightHitArea': 'right:0;',
        '%swipeLeftHitArea:after':
            'content:"";border:10px solid transparent;border-right-color:[brd];width:0;height:0;position:absolute;top:50%;left:0;margin-top:-10px;',
        '%swipeRightHitArea:after':
            'content:"";border:10px solid transparent;border-left-color:[brd];width:0;height:0;position:absolute;top:50%;right:0;margin-top:-10px;',
        '%_arr:hover': 'opacity:1;[trans]',
        '%autoplay': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerVintageSkin'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            [
                'div',
                'imageWrapper',
                [],
                {},
                ['div', null, ['_shadowImgTL', '_bgShadow'], {}],
                ['div', null, ['_shadowImgTR', '_bgShadow'], {}],
                ['div', null, ['_shadowImgBL', '_bgShadow'], {}],
                ['div', null, ['_shadowImgBR', '_bgShadow'], {}],
                ['div', null, ['_bg'], {}],
                ['div', null, ['_imgBorder'], {}, ['div', 'image', [], {}]],
                ['div', 'zoom', [], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
            ]
        ]
    ],
    exports: {
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        shd: 'BOX_SHADOW',
        bg: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        trans: 'TRANSITION',
        fntds: 'FONT',
        txt: 'TEXT_COLOR',
        tdr: 'URL'
    },
    paramsDefaults: {
        shd: '0 1px 5px rgba(0, 0, 0, 0.1)',
        bg: 'color_11',
        brw: '5px',
        trans: 'opacity 0.4s ease 0s',
        fntds: 'font_8',
        txt: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%_bg': 'position:absolute;top:0;right:0;bottom:0;left:0;[shd]  background:[bg];',
        '%imageWrapper': 'position:relative;width:93% !important;height:93% !important;margin-left:10px !important;margin-top:4px !important;[shd]',
        '%_imgBorder': 'position:absolute;top:0;right:0;bottom:0;left:0;border:solid [brw] [bg];background:[bg];padding:0 0 50px 0;',
        '%zoom': 'position:absolute;top:0;right:0;bottom:0;left:0;padding:10px;background:[bg];opacity:0;[trans]',
        '%title': 'display:none;',
        '%description': 'display:none;',
        '%link': '[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:posinter !important;',
        '%_bgShadow': 'background-image:url([tdr]shadow_photo.png);background-repeat:no-repeat;width:168px;height:154px;',
        '%_shadowImgTL': 'background-position:0 0;position:absolute;left:-15px;top:-18px;',
        '%_shadowImgTR': 'background-position:100% 0;position:absolute;right:-17px;top:-20px;',
        '%_shadowImgBL': 'background-position:0 100%;position:absolute;left:-16px;bottom:-16px;',
        '%_shadowImgBR': 'background-position:100% 100%;position:absolute;right:-19px;bottom:-16px;',
        '%zoom:hover': 'opacity:1;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        itemContainerTotalOffset: 70,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerCircleSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        tdr: 'URL',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'opacity 0.4s ease 0s'
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;left:35px !important;right:35px !important;top:0;bottom:0;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%[data-state~="mobileView"] %itemsContainer': 'left:0 !important;right:0 !important;',
        '%_arr': 'position:absolute;top:20%;bottom:20%;width:45px;background:url([tdr]arrows_white_new3.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:-5px;background-position:0 50%;opacity:0.5;[trans]',
        '%swipeRightHitArea': 'right:-5px;background-position:100% 50%;opacity:0.5;[trans]',
        '%swipeLeftHitArea:hover': 'opacity:1;[trans]',
        '%swipeRightHitArea:hover': 'opacity:1;[trans]',
        '%[data-state~="mobileView"] %swipeRightHitArea': 'right:0;opacity:0.5;[trans]',
        '%[data-state~="mobileView"] %swipeLeftHitArea': 'left:0;opacity:0.5;[trans]',
        '%autoplay': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        itemContainerTotalOffset: 70,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        tdr: 'URL',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'opacity 0.4s ease 0s'
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;left:35px !important;right:35px !important;top:0;bottom:0;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%[data-state~="mobileView"] %itemsContainer': 'position:absolute;left:0 !important;right:0 !important;top:0;bottom:0;',
        '%_arr': 'position:absolute;top:20%;bottom:20%;width:45px;background:url([tdr]arrows_white_new3.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:-5px;background-position:0 50%;opacity:0.5;[trans]',
        '%swipeRightHitArea': 'right:-5px;background-position:100% 50%;opacity:0.5;[trans]',
        '%swipeLeftHitArea:hover': 'opacity:1;[trans]',
        '%swipeRightHitArea:hover': 'opacity:1;[trans]',
        '%autoplay': 'display:none;',
        '%[data-state~="mobileView"] %swipeRightHitArea': 'background-position:100% 50%;opacity:0.5;[trans]',
        '%[data-state~="mobileView"] %swipeLeftHitArea': 'background-position:0 50%;opacity:0.5;[trans]'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        [
            'div',
            'swipeLeftHitArea',
            ['_arr'],
            {},
            ['div', null, ['_arrowLeft'], {}],
            ['div', null, ['_screwTL', '_screw'], {}],
            ['div', null, ['_screwTR', '_screw'], {}],
            ['div', null, ['_screwBL', '_screw'], {}],
            ['div', null, ['_screwBR', '_screw'], {}]
        ],
        [
            'div',
            'swipeRightHitArea',
            ['_arr'],
            {},
            ['div', null, ['_arrowRight'], {}],
            ['div', null, ['_screwTL', '_screw'], {}],
            ['div', null, ['_screwTR', '_screw'], {}],
            ['div', null, ['_screwBL', '_screw'], {}],
            ['div', null, ['_screwBR', '_screw'], {}]
        ],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerIronSkin',
            styleGroup: 'inherit'
        },
        itemContainerAdditionalHeight: -7,
        itemContainerAdditionalwidth: 114,
        itemContainerTotalOffset: 114
    },
    params: {
        tdr: 'URL',
        ishd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        ishd: 'rgba(255, 255, 255, 0.4) 0px 0px 6px 0px inset, rgba(255, 255, 255, 0.7) 0px 1px 0px 0px inset, rgba(255, 255, 255, 0.2) 0px 0px 5px 0px inset, rgba(0, 0, 0, 0.4) 0px 1px 3px 0px'
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;left:57px !important;right:57px !important;top:-3px !important;bottom:-4px !important;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%_arr': 'position:absolute;top:0;bottom:0;margin:auto;width:45px;background:url([tdr]ironpatern.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:0;[ishd]  width:45px;height:43px;border-radius:2px;',
        '%_arrowLeft':
            'position:relative;top:27%;left:33%;width:0;height:0;border-top:10px solid rgba(0, 0, 0, 0);border-bottom:10px solid rgba(0, 0, 0, 0);border-right:12px solid #202020;',
        '%swipeRightHitArea': 'right:0;[ishd]  width:45px;height:43px;border-radius:2px;',
        '%_arrowRight':
            'position:relative;top:27%;left:43%;width:0;height:0;border-top:10px solid rgba(0, 0, 0, 0);border-bottom:10px solid rgba(0, 0, 0, 0);border-left:12px solid #202020;',
        '%_screw': 'display:inline-block;background-image:url([tdr]skrew.png);background-repeat:no-repeat;width:15px;height:15px;',
        '%_screwTL': 'position:absolute;top:2px;left:2px;',
        '%_screwTR': 'position:absolute;top:2px;right:2px;',
        '%_screwBL': 'position:absolute;bottom:2px;left:2px;',
        '%_screwBR': 'position:absolute;bottom:2px;right:2px;',
        '%autoplay': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryMinimal'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    params: {
        tdr: 'URL',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'opacity 0.4s ease 0s'
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;left:35px !important;right:35px !important;top:0;bottom:0;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%_arr': 'position:absolute;top:20%;bottom:20%;width:30px;background:url([tdr]arrows_white.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:0;background-position:0 50%;opacity:0.5;[trans]',
        '%swipeRightHitArea': 'right:0;background-position:100% 50%;opacity:0.5;[trans]',
        '%swipeLeftHitArea:hover': 'opacity:1;[trans]',
        '%swipeRightHitArea:hover': 'opacity:1;[trans]',
        '%_xxx': 'position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerNoArrows',
            styleGroup: 'inherit'
        }
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;top:0;right:0;bottom:0;left:0;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%_arr': 'position:absolute;top:0;bottom:0;width:50px;cursor:pointer;',
        '%autoplay': 'display:none;',
        '%swipeLeftHitArea': 'left:0;',
        '%swipeRightHitArea': 'right:0;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerScotchTapeSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 80
    },
    params: {
        tdr: 'URL'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%itemsContainer':
            'position:absolute;top:0;right:0;bottom:0;left:0;border:0;overflow:hidden;position:absolute;left:40px !important;right:40px !important;top:0;bottom:0;white-space:nowrap;overflow:hidden;',
        '%images': 'position:absolute;white-space:nowrap;',
        '%_arr': 'position:absolute;top:0;bottom:0;margin:auto;width:76px;height:45px;cursor:pointer;',
        '%swipeLeftHitArea': 'left:-5px;background:url([tdr]scotch_left_arrow.png) no-repeat;',
        '%swipeRightHitArea': 'right:-5px;background:url([tdr]scotch_right_arrow.png) no-repeat;',
        '%autoplay': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.galleryslider.SliderGalleryVintageSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'counter', [], {}],
        ['div', 'swipeLeftHitArea', ['_arr'], {}],
        ['div', 'swipeRightHitArea', ['_arr'], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 0,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.galleryslider.SliderDisplayerVintageSkin',
            styleGroup: 'inherit'
        },
        itemContainerTotalOffset: 70
    },
    params: {
        tdr: 'URL',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        trans: 'opacity 0.4s ease 0s'
    },
    css: {
        '%itemsContainer': 'position:absolute;left:35px !important;right:35px !important;top:0;bottom:0;border:solid 1px #ff0000;',
        '%_arr': 'position:absolute;top:20%;bottom:20%;width:45px;background:url([tdr]arrows_white_new3.png) no-repeat;cursor:pointer;',
        '%swipeLeftHitArea': 'left:-5px;background-position:0 50%;opacity:0.5;[trans]',
        '%swipeRightHitArea': 'right:-5px;background-position:100% 50%;opacity:0.5;[trans]',
        '%swipeLeftHitArea:hover': 'opacity:1;[trans]',
        '%swipeRightHitArea:hover': 'opacity:1;[trans]',
        '%autoplay': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.AppsTextInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['p', 'message', [], {}]
    ],
    params: {
        shd: 'BOX_SHADOW',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        brw: 'BORDER_SIZES',
        bg: 'BG_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brd: 'BORDER_COLOR_ALPHA',
        txt2: 'TEXT_COLOR',
        brwh: 'BORDER_SIZES',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        brwf: 'BORDER_SIZES',
        bgf: 'BG_COLOR_ALPHA',
        brdf: 'BORDER_COLOR_ALPHA',
        brwe: 'BORDER_SIZES',
        bge: 'BG_COLOR_ALPHA',
        brde: 'BORDER_COLOR_ALPHA',
        trns: 'TRANSITION',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        brwd: 'BORDER_SIZES',
        brdd: 'BORDER_COLOR_ALPHA',
        fntlbl: 'FONT',
        txtlbl: 'TEXT_COLOR',
        txtlblrq: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        rd: '0',
        fnt: 'font_8',
        brw: '1px',
        bg: '#ffffff',
        txt: 'color_15',
        brd: '#e3e3e3',
        txt2: 'color_15',
        brwh: '1px',
        bgh: '#ffffff',
        brdh: '#a3d9f6',
        brwf: '1px',
        bgf: '#ffffff',
        brdf: '#a3d9f6',
        brwe: '1px',
        bge: '#ffffff',
        brde: '#a3d9f6',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF',
        brwd: '1px',
        brdd: '#a3d9f6',
        fntlbl: 'font_8',
        txtlbl: 'color_15',
        txtlblrq: 'transparent'
    },
    css: {
        '%': 'position:relative;min-height:25px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;',
        '%input':
            '[shd]  [rd]  [fnt]  -webkit-appearance:none;-moz-appearance:none;[brw]  background-color:[bg];box-sizing:border-box !important;color:[txt];border-style:solid;border-color:[brd];padding:3px;margin:0;max-width:100%;-webkit-box-flex:1;-webkit-flex:1;flex:1;text-overflow:ellipsis;',
        '%input[type="number"]': '-moz-appearance:textfield;width:100%;',
        '%input::-webkit-input-placeholder': 'color:[txt2];',
        '%input::-ms-input-placeholder': 'color:[txt2];',
        '%input::placeholder': 'color:[txt2];',
        '%input:hover,%input[data-preview~="hover"]': '[brwh]    background-color:[bgh];border-style:solid;border-color:[brdh];',
        ':not(%_with-validation-indication) %input:focus,:not(%_with-validation-indication) %input[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%_with-validation-indication %input:invalid': '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];',
        '%_with-validation-indication %input:not(:invalid):focus,%_with-validation-indication %input[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%_left-direction %input': 'text-align:left;',
        '%_right-direction %input': 'text-align:right;direction:rtl;',
        '%_center-direction %input': 'text-align:center;',
        '% p': '[trns]  opacity:0;[rd]  display:inline-block;position:absolute;right:0;bottom:100%;width:140px;padding:10px;font-size:13px;line-height:16px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);[rd]',
        '%[data-disabled="true"] %input,%[data-preview~="disabled"] %input':
            'background-color:[bgd];color:[txtd];[brwd]  border-style:solid;border-color:[brdd];',
        '%[data-error="true"] %input,%[data-state~="invalid"] %input,%[data-preview~="error"] %input':
            '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];',
        '%label': '[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;',
        '%_required %label::after': 'content:" *";color:[txtlblrq];'
    }
}
skins['wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin'] = {
    react: [
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ]
    ],
    params: {
        shd: 'BOX_SHADOW',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        brw: 'BORDER_SIZES',
        bg: 'BG_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brd: 'BORDER_COLOR_ALPHA',
        txt2: 'TEXT_COLOR',
        brwh: 'BORDER_SIZES',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        brwf: 'BORDER_SIZES',
        bgf: 'BG_COLOR_ALPHA',
        brdf: 'BORDER_COLOR_ALPHA',
        brwe: 'BORDER_SIZES',
        bge: 'BG_COLOR_ALPHA',
        brde: 'BORDER_COLOR_ALPHA',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        brwd: 'BORDER_SIZES',
        brdd: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        rd: '0',
        fnt: 'font_8',
        brw: '1px',
        bg: '#ffffff',
        txt: 'color_15',
        brd: '#e3e3e3',
        txt2: 'color_15',
        brwh: '1px',
        bgh: '#ffffff',
        brdh: '#a3d9f6',
        brwf: '1px',
        bgf: '#ffffff',
        brdf: '#a3d9f6',
        brwe: '1px',
        bge: '#ffffff',
        brde: '#a3d9f6',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF',
        brwd: '1px',
        brdd: '#a3d9f6'
    },
    css: {
        '%': 'display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;',
        '%input':
            '[shd]  [rd]  [fnt]  -webkit-appearance:none;-moz-appearance:none;[brw]  background-color:[bg];box-sizing:border-box !important;color:[txt];border-style:solid;border-color:[brd];padding:3px;margin:0;max-width:100%;-webkit-box-flex:1;-webkit-flex:1;flex:1;text-overflow:ellipsis;',
        '%input[type="number"]': '-moz-appearance:textfield;',
        '%input::-webkit-input-placeholder': 'color:[txt2];',
        '%input::-ms-input-placeholder': 'color:[txt2];',
        '%input::placeholder': 'color:[txt2];',
        '%input:hover,%input[data-preview~="hover"]': '[brwh]    background-color:[bgh];border-style:solid;border-color:[brdh];',
        ':not(%_with-validation-indication) %input:focus,:not(%_with-validation-indication) %input[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%_with-validation-indication %input:invalid': '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];',
        '%_with-validation-indication %input:not(:invalid):focus,%_with-validation-indication %input[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%_left-direction %input': 'text-align:left;',
        '%_right-direction %input': 'text-align:right;direction:rtl;',
        '%_center-direction %input': 'text-align:center;',
        '%[data-disabled="true"] %input,%[data-preview~="disabled"] %input':
            'background-color:[bgd];color:[txtd];[brwd]  border-style:solid;border-color:[brdd];',
        '%[data-error="true"] %input,%[data-state~="invalid"] %input,%[data-preview~="error"] %input':
            '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];'
    }
}
skins['wysiwyg.common.components.basicmenu.viewer.skins.BasicMenuSkin'] = {
    react: [['ul', 'menuContainer', [], {}]],
    css: {
        '% li a': 'white-space:nowrap;'
    }
}
skins['wysiwyg.common.components.multiselection.viewer.skins.MultiSelectionSkin'] = {
    react: [
        [
            'div',
            'content',
            [],
            {},
            ['div', 'items', [], {}],
            [
                'div',
                'input',
                [],
                {
                    contenteditable: true
                }
            ],
            ['div', 'placeholder', [], {}],
            ['div', 'positionholder', [], {}]
        ],
        ['div', 'dropdown', [], {}]
    ],
    css: {
        '%content': 'position:relative;border:1px solid #b4b4b4;border-radius:4px;background-color:#fff;min-height:30px;padding-bottom:5.5px;',
        '%content:hover': 'border-color:#a3d9f6;cursor:text;',
        '%content:focus': 'border-color:#19a0e9;cursor:text;',
        '%content%_focused': 'border-color:#19a0e9;cursor:text;',
        '%items': 'display:inline;white-space:normal;',
        '%items > *': 'display:inline-block;padding-left:5px;padding-top:5.5px;',
        '%input': 'display:inline-block;vertical-align:text-top;padding-left:5px;min-width:1px;max-width:100%;',
        '%placeholder': 'display:inline-block;padding-top:5.5px;opacity:0.5;',
        '%positionholder': 'display:inline-block;padding-top:5.5px;width:1px;height:18px;',
        '%dropdown':
            'position:fixed;z-index:1;max-height:225px;overflow-y:auto;padding-top:2.5px;border-radius:0 0 3px 3px;border:solid 1px #c4c4c4;border-top:0;box-shadow:0 0 3px rgba(0, 0, 0, 0.2);',
        '%dropdown *%_selected': 'background:#24a1e2;color:#ffffff;',
        '%dropdown *%_theNewOption': 'font-weight:bold;',
        '%dropdown > *': 'list-style-type:none;padding-left:12px;padding-top:6.5px;height:25px;background-color:#fff;'
    }
}
skins['wysiwyg.common.components.multiselectionitem.viewer.skins.MultiSelectionItemSkin'] = {
    react: [['div', 'content', [], {}, ['div', 'text', [], {}], ['div', 'button', [], {}, ['span', null, [], {}]]]],
    css: {
        '%': 'position:relative;',
        '%content': 'border-radius:2px;border:solid 1px #d4d4d4;',
        '%content > *': 'height:15px;backgroud-color:#ffffff;display:inline-block;',
        '%text': 'padding-left:5px;padding-right:5px;',
        '%button': 'cursor:pointer;',
        '%button span': 'display:block;padding-left:4px;padding-right:4px;color:#737373;border-left:solid 1px #d4d4d4;',
        '%button span:hover': 'color:#0099ff;',
        '%button:hover': 'background:#ddd;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.AppsTextInputSkinNoValidation'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_12',
        rd: '0',
        brw: '1px',
        brd: 'color_14'
    },
    css: {
        '%': 'position:relative;',
        '%input': 'background-color:[bg];[rd]  border:[brw] solid [brd];padding:5px;min-height:20px;height:100%;width:100%;',
        '%[data-state~="invalid"] %input': 'border-color:#d00;',
        '%errorMessage': 'display:none;',
        '%[data-state~="invalid"] %errorMessage': 'display:block;color:#d00;padding:8px;background:#fff;',
        '%[data-state~="invalid"] %errorMessage:before':
            'color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:"!";position:absolute;top:2px;left:-23px;width:20px;height:20px;line-height:20px !important;',
        '%[data-state~="invalid"] %errorMessage:after':
            'color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:"►";position:absolute;top:7px;left:0;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.DateInputSkin'] = {
    react: [['div', null, ['_clearfix'], {}, ['select', 'month', [], {}], ['select', 'day', [], {}], ['select', 'year', [], {}], ['p', 'message', [], {}]]],
    css: {
        '%': 'position:relative;',
        '%[data-state~="allowEmpty"] option%_empty': 'display:inline;',
        '%[data-state~="hideEmpty"] option%_empty': 'display:none;',
        '%message': 'visibility:hidden;height:20px;line-height:20px;color:#d00;font-size:12px;',
        '%[data-state~="invalid"] %message': 'visibility:visible;'
    }
}
skins['wysiwyg.viewer.skins.AreaSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'inlineContent', [], {}]
    ],
    params: {
        $BorderRadius30: 'BORDER_RADIUS',
        themeDir: 'URL'
    },
    paramsDefaults: {
        $BorderRadius30: '30px',
        themeDir: 'WEB_THEME_DIRECTORY'
    },
    css: {
        '%bg': 'position:absolute;top:0;right:0;bottom:0;left:0;[$BorderRadius30]  background-image:url([themeDir]bg_wood1.jpg);background-repeat:repeat;background-position:0 0;box-shadow:0 3px 3px rgba(255, 255, 255, 0.7) inset, -3px -3px 3px rgba(0, 0, 0, 0.6) inset, 0 1px 3px rgba(0, 0, 0, 0.7);',
        '%inlineContent': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.viewer.skins.displayers.BorderDisplayer'] = {
    react: [
        ['div', 'imageWrapper', [], {}, ['a', 'link', [], {}, ['div', 'image', [], {}]]],
        ['div', 'panel', ['_panel'], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
    ],
    exports: {
        widthDiff: 10,
        heightDiff: 10,
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        bg: 'color_11',
        rd: '5px'
    },
    css: {
        '%': 'background-color:[bg];[rd]',
        '%imageWrapper': 'margin:5px;'
    }
}
skins['wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            ['div', 'imageWrapper', [], {}, ['div', 'image', [], {}]],
            ['div', 'panel', [], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
        ]
    ],
    exports: {
        heightDiff: 50,
        widthDiff: 0,
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_11',
        fnt: 'font_7',
        txt: 'color_15'
    },
    css: {
        '%': 'background-color:[bg];',
        '%panel': 'position:absolute;bottom:0;padding:10px;white-space:nowrap;left:0;right:0;[fnt]  color:[txt];'
    }
}
skins['wysiwyg.viewer.skins.displayers.LiftedShadowDisplayer'] = {
    react: [
        ['div', null, ['_left', '_shd'], {}],
        ['div', null, ['_right', '_shd'], {}],
        ['a', 'link', [], {}],
        ['div', 'imageWrapper', [], {}, ['div', 'image', [], {}]],
        ['div', 'panel', [], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
    ],
    exports: {
        heightDiff: 50,
        widthDiff: 10,
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: 'color_11',
        rd: '3px',
        fnt: 'font_7',
        txt: 'color_15',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%': 'background-color:[bg];[rd]  overflow:visible !important;',
        '%imageWrapper': 'position:absolute;top:5px;left:5px;',
        '%panel': 'position:absolute;bottom:0;padding:5px;[fnt]  color:[txt];',
        '%_shd': 'position:absolute;bottom:-7px;width:50%;height:7px;background:url([tdr]lifted_shadow.png) no-repeat;pointer-events:none;',
        '%_left': 'left:0;background-position:0 -7px;',
        '%_right': 'right:0;background-position:100% -7px;'
    }
}
skins['wysiwyg.viewer.skins.displayers.MinimalDisplayer'] = {
    react: [
        ['a', 'link', [], {}],
        ['div', 'imageWrapper', [], {}, ['div', 'image', [], {}]],
        ['div', 'title', [], {}],
        ['div', 'description', [], {}]
    ],
    exports: {
        heightDiff: 0,
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        rd: 'BORDER_RADIUS',
        trans: 'TRANSITION'
    },
    paramsDefaults: {
        rd: '0',
        trans: 'opacity 0.5s ease 0s'
    },
    css: {
        '%': 'cursor:pointer;',
        '%image': '[rd]  opacity:0.7;[trans]',
        '%:hover %image': 'opacity:1;[trans]',
        '%title,%description': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.displayers.SimpleDetailedDisplayer'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            ['div', 'imageWrapper', [], {}, ['div', 'image', [], {}]],
            ['div', 'panel', ['_panel'], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
        ]
    ],
    exports: {
        widthDiff: 20,
        heightDiff: 20,
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        transitionOp2: 'TRANSITION'
    },
    paramsDefaults: {
        transitionOp2: 'opacity 1s'
    },
    css: {
        '%:hover > %panel': 'opacity:1;',
        '%link': 'background:#f00;padding:10px;color:#000;position:absolute;top:50%;right:0;bottom:10px;left:0;',
        '%panel': 'padding:0 10px;color:#fff;position:absolute;top:0;right:0;bottom:10px;left:0;opacity:0;[transitionOp2]'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.ArrowsMenuNSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        minH: 40,
        maxH: 40,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ArrowsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        rdDrop: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        rdDrop: '3px'
    },
    css: {
        '%itemsContainer': 'overflow:hidden;',
        '%moreContainer': 'position:absolute;z-index:99999;[rdDrop]'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.CirclesMenuNSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.CirclesMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        pad: 'PADDING_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        pad: '5px',
        bgDrop: 'color_11',
        rdDrop: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%itemsContainer': 'position:relative;overflow:hidden;',
        '%dropWrapper': 'visibility:hidden;position:absolute;margin-top:7px;padding:0 [pad];box-sizing:border-box;',
        '%dropWrapper[data-dropMode="dropUp"]': 'margin-top:0;margin-bottom:7px;',
        '%dropWrapper[data-dropHPosition="left"]': 'padding-left:0;',
        '%dropWrapper[data-dropHPosition="right"]': 'padding-right:0;',
        '%moreContainer': 'background-color:[bgDrop];[rdDrop]  [shd]'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.ForkedBannerMenuSkin'] = {
    react: [
        ['b', null, ['_top'], {}],
        ['b', null, ['_bot'], {}],
        ['b', null, ['_ctr'], {}],
        ['div', null, ['_bg'], {}],
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ForkedBannerMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        rb3: 'BORDER_COLOR_ALPHA',
        rb4: 'BORDER_COLOR_ALPHA',
        rb1: 'BORDER_COLOR_ALPHA',
        rb2: 'BORDER_COLOR_ALPHA',
        bgDrop: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_14',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        brw: '2px',
        brd: 'color_14',
        rb3: 'color_15',
        rb4: 'color_15',
        rb1: 'color_14',
        rb2: 'color_14',
        bgDrop: 'color_11'
    },
    css: {
        '%itemsContainer': 'position:absolute;margin:0 50px 14px 50px;',
        '%_bg': 'box-sizing:border-box;position:absolute;top:0;bottom:14px;left:50px;right:50px;background:[bg];display:inline-block;[shd]  border:[brw] solid [brd];',
        '% b%_ctr': 'position:absolute;top:0;right:0;bottom:0;left:0;margin:0 50px 20px 50px;',
        '% b%_ctr:after':
            'left:0;border-width:0 20px 14px 0;border-color:transparent [rb3] transparent transparent;position:absolute;content:"";overflow:hidden;bottom:-20px;border-style:solid;',
        '% b%_ctr:before':
            'right:0;border-width:0 0 14px 20px;border-color:transparent transparent transparent [rb4];position:absolute;content:"";overflow:hidden;bottom:-20px;border-style:solid;',
        '% b%_top': 'overflow:hidden;position:absolute;left:0;right:0;top:14px;bottom:50%;margin-bottom:-7px;',
        '% b%_bot': 'overflow:hidden;position:absolute;left:0;right:0;top:50%;bottom:0;margin-top:7px;',
        '% b%_top:after':
            'position:absolute;content:"";overflow:hidden;width:10px;top:0;left:0;border-top:100px solid [rb1];border-left:60px solid transparent;border-right:0;',
        '% b%_top:before':
            'position:absolute;content:"";overflow:hidden;width:10px;top:0;right:0;border-top:100px solid [rb2];border-right:60px solid transparent;border-left:0;',
        '% b%_bot:after':
            'position:absolute;content:"";overflow:hidden;width:10px;bottom:0;left:0;border-bottom:100px solid [rb1];border-left:60px solid transparent;border-right:0;',
        '% b%_bot:before':
            'position:absolute;content:"";overflow:hidden;width:10px;bottom:0;right:0;border-bottom:100px solid [rb2];border-right:60px solid transparent;border-left:0;',
        '%dropWrapper': 'position:absolute;top:100%;margin-top:-14px;',
        '%dropWrapper:after': 'content:"⬆";position:absolute;font-size:24px;top:-14px;left:50%;margin-left:-6px;color:[bgDrop];overflow:hidden;height:14px;',
        '%moreContainer': 'background-color:[bgDrop];'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.ForkedRibbonMenuSkin'] = {
    react: [
        ['b', null, ['_tl'], {}],
        ['b', null, ['_tr'], {}],
        ['b', null, ['_bl'], {}],
        ['b', null, ['_br'], {}],
        ['div', 'itemsContainer', ['_needHorizontalMargin'], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.ForkedRibbonMenuButtonSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        bg: 'BORDER_COLOR',
        bgDrop: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_14',
        bgDrop: 'color_11'
    },
    css: {
        '% b': 'position:absolute;width:50%;height:50%;overflow:hidden;',
        '%itemsContainer': 'position:absolute;margin:0 50px;background-color:[bg];',
        '% b%_tr': 'right:0;',
        '% b%_bl': 'bottom:0;',
        '% b%_br': 'bottom:0;right:0;',
        '% b:after':
            'width:100%;height:100%;position:absolute;content:"";border:1000px solid transparent;-webkit-transform:rotate(360deg);transform:rotate(360deg);',
        '% b%_tl:after': 'top:0;left:0;border-top-color:[bg];border-left-width:500px;',
        '% b%_tr:after': 'top:0;right:0;border-top-color:[bg];border-right-width:500px;',
        '% b%_bl:after': 'bottom:0;left:0;border-bottom-color:[bg];border-left-width:500px;',
        '% b%_br:after': 'bottom:0;right:0;border-bottom-color:[bg];border-right-width:500px;',
        '%dropWrapper': 'position:absolute;top:100%;margin-bottom:-42px;',
        '%dropWrapper:after': 'content:"⬆";position:absolute;font-size:20px;top:-13px;left:50%;margin-left:-6px;color:[bgDrop];overflow:hidden;height:13px;',
        '%moreContainer': 'background-color:[bgDrop];'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.SeparateArrowDownMenuNSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparateArrowDownMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        bgDrop: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        pad: 'PADDING_SIZE'
    },
    paramsDefaults: {
        bgDrop: 'color_11',
        rd: '10px',
        pad: '5px'
    },
    css: {
        '*': 'box-sizing:border-box;',
        '%itemsContainer': 'position:relative;overflow:hidden;',
        '%dropWrapper': 'visibility:hidden;position:absolute;',
        '%moreContainer': 'position:absolute;z-index:99999;margin:15px 0;background-color:[bgDrop];[rd]  padding:10px;',
        '%moreContainer > *': 'margin-top:10px;padding:0 [pad] !important;'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.SeparatedArrowsMenuNSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', null, ['_deprect'], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        minH: 40,
        maxH: 40,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SeparatedArrowsMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        tdr: 'URL'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%_deprect': 'position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;',
        '%itemsContainer': 'overflow:hidden;background:transparent !important;',
        '%moreContainer': 'position:absolute;z-index:99999;'
    }
}
skins['wysiwyg.viewer.skins.dropmenu.SlantedMenuNSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'moreButton', [], {}],
        ['div', 'dropWrapper', [], {}, ['div', 'moreContainer', [], {}]]
    ],
    exports: {
        maxH: 210,
        repeaterButton: {
            skin: 'wysiwyg.viewer.skins.dropmenubutton.SlantedMenuButtonNSkin',
            styleGroup: 'inherit'
        }
    },
    params: {
        pad: 'MARGIN_RIGHT_SIZE',
        bgDrop: 'BG_COLOR_ALPHA',
        rdDrop: 'BORDER_RADIUS',
        padLeft: 'MARGIN_LEFT_SIZE'
    },
    paramsDefaults: {
        pad: '1',
        bgDrop: 'color_11',
        rdDrop: '10px',
        padLeft: '70'
    },
    css: {
        '%itemsContainer': 'position:relative;overflow:hidden;',
        '%dropWrapper': 'visibility:hidden;position:absolute;margin-top:7px;box-sizing:border-box;',
        '%dropWrapper[data-dropMode="dropUp"]': 'margin-top:0;margin-bottom:7px;',
        '%moreContainer': 'margin-right:calc([pad] - 70px);background-color:[bgDrop];[rdDrop]',
        '%dropWrapper[data-dropHPosition="left"] %moreContainer': 'margin-right:0;margin-left:0;left:0;',
        '%dropWrapper[data-dropAlign="left"][data-dropHPosition="center"] %moreContainer': 'margin-right:0;margin-left:[padLeft];',
        '%dropWrapper[data-dropAlign="left"][data-dropHPosition="left"] %moreContainer': 'margin-right:0;margin-left:0 !important;',
        '%dropWrapper[data-dropAlign="right"]': 'text-align:right;',
        '%dropWrapper[data-dropAlign="right"] %moreContainer': 'margin-right:0;'
    }
}
skins['wysiwyg.viewer.skins.gallery.LiftedShadowMatrixGallery'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'showMore', [], {}]
    ],
    exports: {
        widthDiff: 0,
        heightDiff: 40,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.LiftedShadowDisplayer',
            styleGroup: 'displayer'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        bg: 'color_1',
        rd: '3px'
    },
    css: {
        '%showMore': 'position:absolute;cursor:pointer;height:20px;line-height:20px;padding:0 5px;bottom:0;background-color:[bg];[rd]',
        '%[data-state~="fullView"] %showMore': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.gallery.MatrixGalleryMinimal'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'showMore', [], {}]
    ],
    exports: {
        heightDiff: 25,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.MinimalDisplayer'
        }
    },
    params: {
        rd: 'BORDER_RADIUS',
        'lnk-h': 'BORDER_COLOR',
        lnk: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        rd: '0',
        'lnk-h': 'color_6',
        lnk: ['lnk-h']
    },
    paramsMutators: {
        lnk: {
            type: 'alpha',
            value: 0.6,
            param: 'lnk-h'
        }
    },
    css: {
        '%showMore': 'position:absolute;cursor:pointer;bottom:0;color:[lnk];border:solid 1px [lnk];padding:3px 10px;[rd]',
        '%showMore:hover': 'color:[lnk-h];border-color:[lnk-h];',
        '%[data-state~="fullView"] %showMore': 'display:none;',
        '%itemsContainer': 'position:absolute;top:0;right:0;bottom:0;left:0;'
    }
}
skins['wysiwyg.viewer.skins.gallery.MatrixGalleryTextBelowSkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'showMore', [], {}]
    ],
    exports: {
        heightDiff: 40,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.TextBelowDisplayerSkin',
            styleGroup: 'displayer'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        bg: 'color_1'
    },
    css: {
        '%showMore': 'position:absolute;cursor:pointer;height:20px;line-height:20px;padding:0 5px;bottom:0;background-color:[bg];',
        '%[data-state~="fullView"] %showMore': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.gallery.TextOnRollMatrixGallerySkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'showMore', [], {}]
    ],
    exports: {
        heightDiff: 50,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.TextOnRollDisplayerSkin',
            styleGroup: 'displayer'
        }
    },
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        bg: 'color_11',
        rd: '5px',
        shd: '0 1px 2px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%showMore': 'position:absolute;cursor:pointer;bottom:10px;height:36px;line-height:36px;padding:0 10px;background-color:[bg];[rd]  [shd]',
        '%[data-state~="fullView"] %showMore': 'display:none;',
        '%itemsContainer': 'padding:10px;'
    }
}
skins['wysiwyg.viewer.skins.gallerymatrix.GlowLineMatrixGallery'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', null, ['_pos'], {}, ['div', 'showMore', [], {}]]
    ],
    exports: {
        heightDiff: 25,
        imageItem: {
            skin: 'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerGlowLine',
            styleGroup: 'inherit'
        }
    },
    params: {
        txt: 'TEXT_COLOR',
        fntds: 'FONT'
    },
    paramsDefaults: {
        txt: 'color_15',
        fntds: 'font_8'
    },
    css: {
        '%_pos': 'height:2.3em;position:absolute;right:0;bottom:0;left:0;text-align:center;',
        '%showMore': 'cursor:pointer;display:inline-block;line-height:2.3em !important;padding:0 10px;text-decoration:underline !important;color:[txt];[fntds]',
        '%itemsContainer': 'position:absolute;top:0;right:0;bottom:2.3em;left:0;',
        '%[data-state~="fullView"] %showMore': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerGlowLine'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            [
                'div',
                'imageWrapper',
                [],
                {},
                ['div', 'image', [], {}],
                ['div', null, ['_imgGlow'], {}],
                ['div', 'zoom', [], {}, ['div', 'title', [], {}], ['div', 'description', [], {}]]
            ]
        ]
    ],
    exports: {
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        shd: 'BOX_SHADOW',
        rd: 'BORDER_RADIUS',
        ishd: 'BOX_SHADOW',
        bgh: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        fntt: 'FONT',
        ttl: 'TEXT_COLOR',
        txt: 'TEXT_COLOR',
        fntds: 'FONT'
    },
    paramsDefaults: {
        shd: '0 2px 5px rgba(0, 0, 0, 0.53)',
        rd: '2px',
        ishd: 'inset 0 2px 0 rgba(255, 255, 255, 0.5), inset 0 1px 10px 1px rgba(255, 255, 255, 0.4), inset 0 0 59px rgba(0, 0, 0, 0.59)',
        bgh: 'color_17',
        trans: 'opacity 0.4s ease 0s',
        fntt: 'font_6',
        ttl: 'color_15',
        txt: 'color_15',
        fntds: 'font_8'
    },
    css: {
        '%imageWrapper': 'position:absolute;top:0;right:0;bottom:0;left:0;[shd]  [rd]',
        '%image': '[rd]',
        '%_imgGlow': '[ishd]  [rd]  position:absolute;top:0;right:0;bottom:0;left:0;',
        '%zoom': 'position:absolute;top:0;right:0;bottom:0;left:0;background:[bgh];[rd]  padding-top:30%;padding-left:10%;opacity:0;[trans]  overflow:hidden;',
        '%title': '[fntt]  color:[ttl];white-space:nowrap;',
        '%description': 'color:[txt];[fntds]  white-space:normal;',
        '%link': 'color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;',
        '%[data-state~="alignLeft"] %title': 'text-align:left;',
        '%[data-state~="alignCenter"] %title': 'text-align:center;',
        '%[data-state~="alignRight"] %title': 'text-align:right;',
        '%[data-state~="alignLeft"] %description': 'text-align:left;',
        '%[data-state~="alignCenter"] %description': 'text-align:center;',
        '%[data-state~="alignRight"] %description': 'text-align:right;',
        '%[data-state~="alignLeft"] %link': 'text-align:left;',
        '%[data-state~="alignCenter"] %link': 'text-align:center;',
        '%[data-state~="alignRight"] %link': 'text-align:right;',
        '%:hover %zoom': 'opacity:1;[trans]'
    }
}
skins['wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin'] = {
    react: [
        ['div', 'imageWrapper', [], {}, ['div', 'image', [], {}], ['div', null, ['_hover'], {}], ['div', 'title', [], {}], ['div', 'description', [], {}]],
        ['a', 'link', [], {}]
    ],
    exports: {
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        brw: 'BORDER_SIZE',
        rd: 'BORDER_RADIUS',
        trans: 'TRANSITION',
        brd: 'BORDER_COLOR_ALPHA',
        brs: 'BORDER_COLOR_ALPHA',
        imageWrapperBottom: 'BORDER_SIZE',
        imageWrapperTop: 'BORDER_SIZE',
        imageWrapperRight: 'BORDER_SIZE',
        imageWrapperLeft: 'BORDER_SIZE',
        contentPaddingLeft: 'BORDER_SIZE',
        contentPaddingRight: 'BORDER_SIZE',
        contentPaddingBottom: 'BORDER_SIZE',
        contentPaddingTop: 'BORDER_SIZE'
    },
    paramsDefaults: {
        brw: '1px',
        rd: '0',
        trans: 'all 0.4s ease 0s',
        brd: 'color_13',
        brs: 'color_15',
        imageWrapperBottom: ['brw'],
        imageWrapperTop: ['brw'],
        imageWrapperRight: ['brw'],
        imageWrapperLeft: ['brw'],
        contentPaddingLeft: ['brw'],
        contentPaddingRight: ['brw'],
        contentPaddingBottom: ['brw'],
        contentPaddingTop: ['brw']
    },
    css: {
        '%title': 'display:none;',
        '%description': 'display:none;',
        '%link': 'display:none;',
        '%imageWrapper': 'border:[brw] solid transparent;[rd]  [trans]  cursor:pointer;box-sizing:border-box;height:100% !important;',
        '%imageWrapper:hover': 'border:[brw] solid [brd];',
        '%[data-state~="selected"] %imageWrapper': 'border:[brw] solid [brs];'
    }
}
skins['wysiwyg.viewer.skins.IFrameComponentSkin'] = {
    react: [['div', 'iFrameHolder', [], {}]]
}
skins['wysiwyg.viewer.skins.input.CheckBoxInputSkin'] = {
    react: [
        [
            'fieldset',
            null,
            [],
            {},
            [
                'input',
                'checkBox',
                [],
                {
                    type: 'checkbox'
                }
            ]
        ],
        ['label', 'label', [], {}],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        trns: 'TRANSITION'
    },
    paramsDefaults: {
        rd: '3px',
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        brw: '1px',
        brd: '#999999',
        bg: '#ffffff',
        tdr: 'BASE_THEME_DIRECTORY',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s'
    },
    css: {
        '%': 'position:relative;cursor:pointer;',
        '% input[data-type="checkbox"]':
            '-webkit-appearance:none !important;-moz-appearance:none !important;box-sizing:border-box;[rd]  [shd]  width:18px;height:18px;border:[brw] solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;margin:0;cursor:pointer;',
        '% input[data-type="checkbox"]:checked': 'background-position:50% 50%;',
        '% p': '[trns]  [rd]  opacity:0;position:absolute;right:0;bottom:18px;width:140px;padding:10px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '%[data-state~="invalid"]:hover + p': 'opacity:1;color:#d00;'
    }
}
skins['wysiwyg.viewer.skins.input.CheckBoxInputSkinNoValidation'] = {
    react: [
        [
            'input',
            'checkBox',
            [],
            {
                type: 'checkbox'
            }
        ],
        ['div', null, [], {}],
        ['label', 'label', [], {}]
    ],
    params: {
        brd: 'BORDER_COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS'
    },
    paramsDefaults: {
        brd: '#aaa',
        bg: '#fff',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '3px'
    },
    css: {
        '%': 'position:relative;width:16px;height:16px;margin-right:16px;display:inline-block;overflow:visible;',
        '% input[data-type="checkbox"]': 'position:absolute;top:-9999px;left:-9999px;overflow:visible;',
        '% label': 'width:16px;height:16px;display:block;position:absolute;top:0;left:0;cursor:pointer;',
        '% div':
            'width:16px;height:16px;border:1px solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;[rd]  box-sizing:border-box !important;',
        '% input[data-type="checkbox"]:checked ~ div': 'background-position:50% 50%;',
        '% input%input%_isPlaceholder': 'color:#999;'
    }
}
skins['wysiwyg.viewer.skins.input.ComboBoxInputSimpleSkin'] = {
    react: [['div', null, ['_clearfix'], {}, ['label', 'label', [], {}], ['select', 'collection', [], {}]]],
    params: {
        $borderRadius: 'BORDER_RADIUS',
        shadowColor: 'BOX_SHADOW_COLOR_ALPHA'
    },
    paramsDefaults: {
        $borderRadius: '3px',
        shadowColor: '0, 0, 0, 0.1'
    },
    css: {
        '%': 'padding-top:5px;',
        '%label': 'margin-bottom:5px;display:block;',
        '%[data-state~="noLabel"] %label': 'display:none;',
        '%[disabled] %label': 'opacity:0.5;',
        '% select%collection':
            'width:100%;margin:0 0 5px 0;padding:1px 3px;line-height:1.1em;height:1.8em;font-size:1em;[$borderRadius]  border:1px solid #b4b4b4;box-shadow:0 1px 1px 0 [shadowColor] inset;',
        '%collection:hover': 'border-color:#a3d9f6;',
        '%collection:focus': 'border-color:#19a0e9;'
    }
}
skins['wysiwyg.viewer.skins.input.SMLoginCheckBoxSkin'] = {
    react: [
        [
            'input',
            'checkBox',
            [],
            {
                type: 'checkbox'
            }
        ],
        ['label', 'label', [], {}]
    ],
    css: {
        '%label': 'font-size:12px;vertical-align:2px;',
        '%checkBox': 'margin:0 5px 0 0;'
    }
}
skins['wysiwyg.viewer.skins.input.TextAreaInputSkinNoValidation'] = {
    react: [
        ['label', 'label', [], {}],
        ['textarea', 'textarea', [], {}],
        ['p', 'errorMessage', [], {}],
        ['div', null, ['_err'], {}]
    ],
    css: {
        '%': 'position:relative;',
        '%textarea': 'resize:none;width:100% !important;min-height:100px;overflow:auto;',
        '% p': 'display:none;position:absolute;',
        '%[data-state~="invalid"] %textarea': 'color:#d00;border-color:#d00;',
        '% textarea:focus': 'color:#444  !important;border-color:#000 !important;',
        '%_err': 'position:absolute;top:50%;left:0;height:1px;width:1px;',
        '%[data-state~="invalid"] %_err:before':
            'color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:"!";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;',
        '%[data-state~="invalid"] %_err:after':
            'color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:"►";position:absolute;top:10px;left:8px;'
    }
}
skins['wysiwyg.viewer.skins.input.TextInputRoundSkin'] = {
    react: [
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        boxShadow: 'BOX_SHADOW'
    },
    paramsDefaults: {
        rd: '10px',
        boxShadow: 'inset 1px 1px 4px #CCCCCC'
    },
    css: {
        '%': 'height:40px;',
        '%input': 'margin-bottom:20px;width:290px;height:32px;line-height:20px;padding:0 40px 0 10px;background:#fff;[rd]  [boxShadow]  position:relative;',
        '%[data-state="invalid"] %input': 'border-color:red;'
    }
}
skins['wysiwyg.viewer.skins.input.TextInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['p', 'errorMessage', [], {}],
        ['div', null, ['_err'], {}]
    ],
    css: {
        '%': 'position:relative;',
        '%[data-state~="invalid"] %input': 'color:#d00;border-color:#d00;',
        '%_err': 'position:absolute;top:50%;left:0;height:1px;width:1px;',
        '%[data-state~="invalid"] %_err:before':
            'color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:"!";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;',
        '%[data-state~="invalid"] %_err:after':
            'color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:"►";position:absolute;top:10px;left:8px;'
    }
}
skins['wysiwyg.viewer.skins.menubutton.SeparateShinyIMenuButtonSkin'] = {
    react: [['div', null, ['_gapper'], {}, ['div', null, ['_wrapper'], {}, ['div', 'bg', [], {}, ['div', 'label', [], {}]]]]],
    params: {
        pad: 'PADDING_SIZE',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        trans: 'TRANSITION',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        pad: '5px',
        bg: 'color_11',
        tdr: 'BASE_THEME_DIRECTORY',
        rd: '10px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        trans: 'background-color 0.4s ease 0s',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'display:inline-block;',
        '%_gapper': 'padding:0 [pad];',
        '%_wrapper': 'cursor:pointer;background:[bg] url([tdr]shiny1button_bg.png) center center repeat-x;[rd]  [shd]  [trans]',
        '%label': 'padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%:first-child': 'padding-left:0;',
        '%:last-child': 'padding-right:0;',
        '%:hover %_wrapper': 'background-color:[bgh];[trans]',
        '%:hover %label': 'color:[txth];[trans2]',
        '%[data-state="selected"] %_wrapper': 'background-color:[bgs];[trans]',
        '%[data-state="selected"] %label': 'color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.menubutton.ShinyMenuIButtonSkin'] = {
    react: [['div', 'bg', [], {}, ['div', null, ['_gapper'], {}, ['div', 'label', [], {}]]]],
    params: {
        brd: 'COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        trans: 'TRANSITION',
        pad: 'PADDING_SIZE',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trans2: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        bgs: 'BG_COLOR_ALPHA',
        txts: 'TEXT_COLOR'
    },
    paramsDefaults: {
        brd: 'color_15',
        bg: 'color_11',
        trans: 'background-color 0.4s ease 0s',
        pad: '5px',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_1',
        txt: 'color_15',
        trans2: 'color 0.4s ease 0s',
        bgh: 'color_17',
        txth: 'color_15',
        bgs: 'color_18',
        txts: 'color_15'
    },
    css: {
        '%': 'display:inline-block;cursor:pointer;border-left:1px solid [brd];background:[bg];[trans]',
        '%_gapper': 'padding:0 [pad];',
        '%bg': 'background:transparent url([tdr]shiny1button_bg.png) center center repeat-x;',
        '%label': 'padding:0 10px;[fnt]  color:[txt];[trans2]',
        '%:first-child': 'border:0;',
        '%[data-listposition="first"],%[data-listposition="first"] %bg': 'border-bottom-right-radius:0;border-top-right-radius:0;',
        '%[data-listposition="last"],%[data-listposition="last"] %bg': 'border-bottom-left-radius:0;border-top-left-radius:0;',
        '%:hover': 'background:[bgh];[trans]',
        '%:hover %label': 'color:[txth];[trans2]',
        '%[data-state="selected"]': 'background:[bgs];[trans]',
        '%[data-state="selected"] %label': 'color:[txts];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.MobileTryAgainMessageViewSkin'] = {
    react: [
        [
            'div',
            'blockingLayer',
            [],
            {},
            ['div', 'dialog', [], {}, ['strong', 'title', [], {}], ['p', 'description', [], {}], ['div', 'okButton', [], {}], ['div', 'backButton', [], {}]]
        ]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        bg: '#000000'
    },
    paramsMutators: {
        bg: {
            type: 'alpha',
            value: 0.75
        }
    },
    css: {
        '%': 'position:fixed;width:100%;height:100%;z-index:99;color:#404040;font-size:14px;font-family:"Helvetica Neue","HelveticaNeueW01-55Roma","HelveticaNeueW02-55Roma","HelveticaNeueW10-55Roma", Helvetica, Arial, sans-serif;',
        '%blockingLayer': 'background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;',
        '%dialog': '[rd]  position:absolute;top:35%;left:50%;margin-left:-140px;width:280px;background:#fdfdfd;',
        '%title':
            'padding:0 10px;line-height:34px;border-bottom:1px solid #ccc;display:block;font-size:15px;font-style:normal;font-variant:normal;font-weight:700;',
        '%description': 'max-height:650px;min-height:10px;height:auto;padding:10px;',
        '%okButton': 'float:right;margin:10px;',
        '%backButton': 'float:left;margin:10px;text-decoration:underline;cursor:pointer;'
    }
}
skins['wysiwyg.viewer.skins.ModalSkin'] = {
    react: [
        ['div', 'blockingLayer', [], {}],
        ['div', 'innerComponent', [], {}],
        ['div', 'close', [], {}]
    ],
    params: {
        tdr: 'URL',
        'bg-block': 'BG_COLOR'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        'bg-block': '#555555'
    },
    paramsMutators: {
        'bg-block': {
            type: 'alpha',
            value: 0.75
        }
    },
    css: {
        '%blockingLayer': 'background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;width:100%;height:100%;overflow:hidden;',
        '%close': 'position:fixed;cursor:pointer;background:transparent url([tdr]modal_x_sprite.png) no-repeat right top;height:50px;width:50px;',
        '%close:hover': 'background-position:right -50px;',
        '%innerComponent iframe': 'border-radius:5px;'
    }
}
skins['wysiwyg.viewer.skins.option.InfoTipSkin'] = {
    react: [['div', null, ['_toolTipContainer'], {}, ['p', 'content', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW'
    },
    paramsDefaults: {
        rd: '5px',
        shd: '0 1px 4px rgba(0, 0, 0, 0.6)'
    },
    css: {
        '%': 'font-family:"Helvetica Neue","HelveticaNeueW01-55Roma","HelveticaNeueW02-55Roma","HelveticaNeueW10-55Roma", Helvetica, Arial,sans-serif;font-size:12px;line-height:16px;[rd]  [shd]  min-height:10px;min-width:10px;max-width:300px;color:#656565;background:#fffedf;position:absolute;padding:5px;top:0;left:0;',
        '% p': 'display:block;margin:0 0 5px 0;',
        '% strong,% b,% i,% big': 'font-size:12px;',
        '%[data-state~="hidden"]': 'visibility:hidden !important;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.counter.CounterTextSkin'] = {
    react: [['div', 'text', [], {}]],
    css: {
        '%text': 'font-size:13px;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridPolaroid'] = {
    react: [
        ['h3', 'title', ['_hid'], {}],
        ['p', 'description', ['_hid'], {}],
        ['a', 'link', ['_hid'], {}],
        ['div', 'autoplay', [], {}],
        ['div', 'itemsContainer', [], {}],
        ['ul', null, ['_pages'], {}, ['li', 'buttonPrev', [], {}], ['li', null, [], {}], ['li', 'buttonNext', [], {}], ['li', 'counter', [], {}]],
        ['div', 'rolloverHolder', [], {}]
    ],
    exports: {
        heightDiff: 40
    },
    params: {
        tdr: 'URL',
        txt: 'TEXT_COLOR',
        txtHi: 'BG_COLOR_ALPHA',
        txtGrayed: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY',
        txt: 'color_2',
        txtHi: 'color_4',
        txtGrayed: ['txt']
    },
    paramsMutators: {
        txtGrayed: {
            type: 'alpha',
            value: 0.5,
            param: 'txt'
        }
    },
    css: {
        '%itemsContainer': 'overflow:hidden;',
        '%_hid': 'display:none;',
        '%_bg': 'padding:6px;background:#000;',
        '%_galleryDisplayer:after': 'content:"";position:absolute;top:0;right:0;bottom:0;left:0;cursor:pointer;',
        '%_galleryDisplayer:hover %_pic:after':
            'content:"";position:absolute;top:0;right:0;bottom:-6px;left:0;background:transparent url([tdr]wix_apps/magnify.png) right top no-repeat;border-bottom:solid 3px [txt];',
        '%_galleryDisplayer %_desc span': 'text-transform:uppercase;color:[txt];',
        '%_galleryDisplayer %_price span': 'color:[txt];',
        '%_galleryDisplayer %_retail span': 'color:[txtGrayed];',
        '%_galleryDisplayer %_salePrice span': 'color:[txtHi];',
        '%_sale': 'position:relative;',
        '%_sale:after':
            'content:"SALE";position:absolute;top:-90px;right:0;height:22px;line-height:22px;text-align:center;font-size:12px;width:40px;background:[txtHi];color:#fff;',
        '%_pages': 'position:absolute;bottom:10px;left:0;right:0;text-align:left;color:[txt];',
        '%_pages li': 'padding:0 3px;display:inline-block !important;font-size:14px;',
        '%counter': 'margin-left:10px;',
        '%buttonPrev,%buttonNext': 'color:[txtHi];',
        '%buttonPrev:hover,%buttonNext:hover': 'color:[txt];'
    }
}
skins['wysiwyg.viewer.skins.photo.LfShwDBPhoto'] = {
    react: [
        ['div', null, ['_left', '_shd'], {}],
        ['div', null, ['_right', '_shd'], {}],
        ['a', 'link', [], {}, ['div', 'img', [], {}]]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageNewSkin'
        }
    },
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brd: 'BORDER_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        tdr: 'URL',
        contentPaddingLeft: 'BORDER_SIZE',
        contentPaddingRight: 'BORDER_SIZE',
        contentPaddingBottom: 'BORDER_SIZE',
        contentPaddingTop: 'BORDER_SIZE'
    },
    paramsDefaults: {
        rd: '3px',
        shd: '0 0 3px rgba(0, 0, 0, 0.5)',
        brd: 'color_15',
        brw: '2px',
        tdr: 'BASE_THEME_DIRECTORY',
        contentPaddingLeft: ['brw'],
        contentPaddingRight: ['brw'],
        contentPaddingBottom: ['brw'],
        contentPaddingTop: ['brw']
    },
    css: {
        '%link': 'display:block;[rd]  [shd]  background-color:[brd];border:[brw] solid [brd];overflow:hidden;',
        '%img': '[rd]  overflow:hidden;',
        '%_shd': 'position:absolute;bottom:-26px;width:165px;height:26px;background-image:url([tdr]liftedshadow_medium.png);background-repeat:no-repeat;',
        '%_left': 'left:-20px;background-position:0 0;',
        '%_right': 'right:-20px;background-position:100% 0;'
    }
}
skins['wysiwyg.viewer.skins.SliderGallerySkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
        ['div', 'counter', [], {}],
        ['div', 'hitAreas', [], {}, ['div', 'swipeLeftHitArea', [], {}], ['div', 'swipeRightHitArea', [], {}]]
    ],
    exports: {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin'
        }
    },
    css: {
        '%counter': 'position:relative;margin-bottom:0;background-color:#FFF;opacity:0.3;cursor:pointer;',
        '%hitAreas': 'position:absolute;width:100%;height:100%;',
        '%swipeLeftHitArea': 'position:absolute;left:0;width:100px;height:100%;',
        '%swipeRightHitArea': 'position:absolute;right:0;width:100px;height:100%;'
    }
}
skins['wysiwyg.viewer.skins.SlideShowGallerySkin'] = {
    react: [
        ['div', 'itemsContainer', [], {}],
        ['div', 'buttonPrev', [], {}],
        ['div', 'buttonNext', [], {}],
        ['div', 'counter', [], {}],
        ['div', 'autoplay', [], {}]
    ],
    exports: {
        imageItem: {
            skin: 'wysiwyg.viewer.skins.displayers.DetailedDisplayerSkin'
        }
    },
    css: {
        '%buttonPrev': 'position:relative;margin-bottom:0;background-color:#FFF;opacity:0.3;cursor:pointer;',
        '%buttonNext': 'position:relative;margin-bottom:0;background-color:#FFF;opacity:0.3;cursor:pointer;',
        '%counter': 'position:relative;margin-bottom:0;background-color:#FFF;opacity:0.3;cursor:pointer;',
        '%[data-state~="autoplayOn"] %autoplay': 'background-color:#0F0;position:relative;margin-bottom:0;opacity:0.3;cursor:pointer;',
        '%[data-state~="autoplayOff"] %autoplay': 'background-color:#F00;position:relative;margin-bottom:0;opacity:0.3;cursor:pointer;'
    }
}
skins['wysiwyg.viewer.skins.sm.SMApplyForMembershipSkin'] = {
    react: [
        ['div', 'email', [], {}],
        ['div', 'password', [], {}],
        ['div', 're_password', [], {}]
    ]
}
skins['wysiwyg.viewer.skins.sm.SMContainerSkin'] = {
    react: [
        ['div', 'blockingLayer', [], {}],
        [
            'div',
            'dialog',
            [],
            {},
            [
                'div',
                null,
                ['_wrapper'],
                {},
                ['div', 'xButton', [], {}],
                ['div', null, ['_header'], {}, ['p', 'infoTitle', [], {}], ['p', 'note', [], {}], ['h3', 'title', [], {}]],
                ['div', 'content', [], {}, ['div', 'contentInner', [], {}]],
                [
                    'div',
                    null,
                    ['_confirm'],
                    {},
                    ['p', 'confirmationTitleText', [], {}],
                    ['p', 'confirmationText', [], {}],
                    [
                        'input',
                        'okButton',
                        [],
                        {
                            type: 'submit',
                            value: 'OK'
                        }
                    ]
                ],
                [
                    'div',
                    null,
                    ['_footer'],
                    {},
                    ['p', 'errMsg', [], {}],
                    ['a', 'cancel', [], {}],
                    [
                        'input',
                        'submitButton',
                        [],
                        {
                            type: 'submit'
                        }
                    ]
                ]
            ]
        ]
    ],
    exports: {
        register: {
            skin: 'wysiwyg.viewer.skins.sm.SMRegisterSkin'
        }
    },
    params: {
        txt: 'TEXT_COLOR',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        'bg-block': 'BG_COLOR',
        'bg-border': 'BG_COLOR'
    },
    paramsDefaults: {
        txt: '#9c9c9c',
        bg: '#ffffff',
        tdr: 'BASE_THEME_DIRECTORY',
        'bg-block': '#555555',
        'bg-border': '#aaaaaa'
    },
    paramsMutators: {
        'bg-block': {
            type: 'alpha',
            value: 0.75
        },
        'bg-border': {
            type: 'alpha',
            value: 0.7
        }
    },
    css: {
        '%': 'position:fixed;width:100%;height:100%;z-index:99;font-size:1em;color:[txt];',
        '%[data-state~="mobile"]': 'position:absolute;width:100%;height:100%;z-index:99;font-size:1em;color:[txt];',
        '%blockingLayer': 'background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;',
        '%dialog': 'background-color:[bg-border];width:500px;position:fixed;padding:20px;top:50%;left:50%;box-sizing:border-box;',
        '%_wrapper': 'background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;',
        '%[data-state~="mobile"] %dialog': 'background-color:[bg-border];width:260px;position:absolute;padding:10px;top:240px;left:50%;box-sizing:border-box;',
        '%[data-state~="mobile"] %_wrapper': 'background-color:[bg];width:100%;height:100%;padding:10px 20px;box-sizing:border-box;',
        '%xButton':
            'position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;',
        '%xButton:hover': 'background-position:right -80px;',
        '%note': 'font-size:12px;text-align:right;padding-bottom:10px;',
        '%note a': 'color:#0198ff;border-bottom:1px solid #0198ff;',
        '%note a:hover': 'color:#0044ff;border-bottom:1px solid #0044ff;',
        '%title': 'font-size:25px;font-weight:bold;color:#555555;padding-bottom:15px;',
        '%infoTitle': 'display:none;color:#0198FF;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;',
        '%[data-state~="mobile"] %title': 'font-size:14px;font-weight:bold;color:#555555;padding-bottom:15px;',
        '%[data-state~="mobile"] %infoTitle': 'display:none;color:#0198FF;font-size:14px;margin-bottom:18px;margin-top:10px;font-weight:normal;',
        '%_footer': 'width:100%;padding-bottom:10px;',
        '%cancel': 'color:[txt];font-size:18px;text-decoration:underline;line-height:36px;',
        '%cancel:hover': 'color:#9c3c3c;',
        '%[data-state~="mobile"] %cancel': 'color:[txt];font-size:14px;text-decoration:underline;line-height:30px;',
        '%[data-state~="mobile"] %passwordInput label': 'font-size:14px;',
        '%[data-state~="mobile"] %passwordInput input[data-type="password"]': 'font-size:14px;line-height:30px;height:30px;',
        '%errMsg': 'font-size:12px;color:#d74401;height:34px;margin-top:5px;',
        '%[data-state~="mobile"] %errMsg': 'font-size:12px;color:#d74401;height:24px;margin-top:5px;',
        '%submitButton':
            'float:right;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#ffffff;font-size:24px;font-weight:bold;',
        '%[data-state~="mobile"] %submitButton':
            'float:right;cursor:pointer;border:solid 2px #0064a8;height:36px;width:100px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#ffffff;font-size:14px;font-weight:bold;',
        '%submitButton:hover': 'background-position:right -352px;border-color:#004286;'
    }
}
skins['wysiwyg.viewer.skins.sm.SMLoginSkin'] = {
    react: [
        ['div', 'email', [], {}],
        ['div', 'password', [], {}],
        ['div', 'rememberMe', [], {}],
        ['a', null, [], {}, ['div', 'forgotPass', [], {}]]
    ],
    exports: {
        email: {
            skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'
        }
    },
    css: {
        '% a': 'cursor:pointer;font-size:12px;line-height:25px;color:#0198FF;text-decoration:underline;',
        '% a %forgotPass': 'margin-top:10px;'
    }
}
skins['wysiwyg.viewer.skins.sm.SMProfileSkin'] = {
    react: [
        [
            'label',
            null,
            [],
            {
                for: 'name'
            }
        ],
        ['div', 'name', [], {}]
    ]
}
skins['wysiwyg.viewer.skins.sm.SMRegisterSkin'] = {
    react: [
        ['div', 'email', [], {}],
        ['div', 'password', [], {}],
        ['div', 're_password', [], {}]
    ]
}
skins['wysiwyg.viewer.skins.sm.SMResetPasswordEmailSkin'] = {
    react: [['div', 'email', [], {}]]
}
skins['wysiwyg.viewer.skins.sm.SMResetPasswordSkin'] = {
    react: [
        ['p', 'formMessage', [], {}],
        ['div', 'password', [], {}],
        ['div', 'passwordRetype', [], {}]
    ],
    exports: {
        password: {
            skin: 'wysiwyg.viewer.skins.input.TextInputSquareSkin'
        }
    },
    css: {
        '% p': 'float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;'
    }
}
skins['wysiwyg.viewer.skins.TryAgainMessageViewSkin'] = {
    react: [
        [
            'div',
            'blockingLayer',
            [],
            {},
            ['div', 'dialog', [], {}, ['strong', 'title', [], {}], ['p', 'description', [], {}], ['div', 'okButton', [], {}], ['div', 'backButton', [], {}]]
        ]
    ],
    exports: {
        okButton: {
            skin: 'wysiwyg.viewer.skins.button.ViewerButtonBlueSkin'
        }
    },
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR'
    },
    paramsDefaults: {
        rd: '5px',
        bg: '#000'
    },
    paramsMutators: {
        bg: {
            type: 'alpha',
            value: 0.75
        }
    },
    css: {
        '%': 'position:fixed;width:100%;height:100%;z-index:99;color:#404040;font-size:14px;font-family:"Helvetica Neue", "HelveticaNeueW01-55Roma", "HelveticaNeueW02-55Roma", "HelveticaNeueW10-55Roma", Helvetica, Arial, sans-serif;',
        '%blockingLayer': 'background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;',
        '%dialog': '[rd]  position:absolute;top:35%;left:50%;margin-left:-240px;width:480px;background:#fdfdfd;',
        '%title':
            'padding:0 10px;line-height:34px;border-bottom:1px solid #ccc;display:block;font-size:15px;font-style:normal;font-variant:normal;font-weight:700;',
        '%description': 'max-height:650px;min-height:10px;height:auto;padding:10px;',
        '%okButton': 'float:right;margin:10px;',
        '%backButton': 'float:left;margin:10px;text-decoration:underline;cursor:pointer;'
    }
}
skins['wysiwyg.viewer.skins.wphoto.PaperclipWPhotoSkin'] = {
    react: [['a', 'link', [], {}, ['span', 'outerFrame', [], {}, ['span', 'wrp', [], {}, ['span', 'img', [], {}]], ['span', 'clip', [], {}]]]],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        $boxShadow: 'BOX_SHADOW',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL'
    },
    paramsDefaults: {
        $boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
        $bgColor: 'color_1',
        themeDir: 'WEB_THEME_DIRECTORY'
    },
    css: {
        '% span': 'display:block;',
        '%link': 'cursor:default;',
        '%wrp': 'position:absolute;top:0;right:0;bottom:0;left:0;padding:10px;[$boxShadow]  background-color:[$bgColor];',
        '%img': 'position:absolute;width:100%;height:100%;',
        '%clip': 'position:absolute;top:-65px;right:50%;margin-right:-32px;width:65px;height:87px;background-image:url([themeDir]icon_clip.png);'
    }
}
skins['wysiwyg.viewer.skins.wphoto.PolaroidWPhotoSkin'] = {
    react: [
        [
            'a',
            'link',
            [],
            {},
            ['span', 'outerFrame', [], {}, ['span', 'left', [], {}], ['span', 'right', [], {}], ['span', 'wrp', [], {}, ['span', 'img', [], {}]]]
        ]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        $boxShadow: 'BOX_SHADOW',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL'
    },
    paramsDefaults: {
        $boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
        $bgColor: 'color_1',
        themeDir: 'WEB_THEME_DIRECTORY'
    },
    css: {
        '% span': 'display:block;',
        '%link': 'cursor:default;',
        '%wrp': 'position:absolute;top:0;right:0;bottom:0;left:0;padding:10px 10px 20px 10px;[$boxShadow]  background-color:[$bgColor];',
        '%img': 'height:100%;',
        '%left,%right': 'position:absolute;bottom:-30px;width:127px;height:36px;background-image:url([themeDir]pic_shadow.png);background-repeat:no-repeat;',
        '%left': 'left:-10px;background-position:0 0;',
        '%right': 'right:-10px;background-position:100% 0;'
    }
}
skins['wysiwyg.viewer.skins.input.CheckBoxGroupInputSkin'] = {
    react: [
        ['fieldset', 'collection', [], {}],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        tdr: 'URL',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        trns: 'TRANSITION'
    },
    paramsDefaults: {
        rd: '3px',
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        brw: '1px',
        brd: '#999999',
        bg: '#ffffff',
        tdr: 'BASE_THEME_DIRECTORY',
        fnt: 'font_7',
        txt: 'color_15',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s'
    },
    css: {
        '%': 'position:relative;',
        '% fieldset input[data-type="checkbox"]':
            '-webkit-appearance:none !important;-moz-appearance:none !important;box-sizing:border-box;[rd]  [shd]  width:18px;height:18px;border:[brw] solid [brd];background:[bg] url([tdr]vi.png) no-repeat -999px -999px;margin:0;cursor:pointer;',
        '% fieldset input[data-type="checkbox"]:checked': 'background-position:50% 50%;',
        '%collection': '[fnt]  color:[txt];',
        '% p': '[trns]  [rd]  opacity:0;position:absolute;right:0;bottom:18px;width:140px;padding:10px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '% p:after': 'content:"?";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '%[data-state~="invalid"]:hover + p': 'opacity:1;color:#d00;'
    }
}
skins['wysiwyg.viewer.skins.input.NumberInputSkin'] = {
    react: [
        [
            'input',
            'input',
            [],
            {
                type: 'number',
                step: '1'
            }
        ],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        brd: 'BORDER_COLOR',
        txt: 'TEXT_COLOR',
        bg: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        brd: '#cccccc',
        txt: '#000000',
        bg: '#ffffff'
    },
    css: {
        '% input': 'position:relative;padding:5px;border:1px solid [brd];color:[txt];background-color:[bg];width:100%;'
    }
}
skins['wysiwyg.viewer.skins.input.RadioGroupInputSkin'] = {
    react: [['div', null, [], {}, ['fieldset', 'collection', [], {}], ['p', 'errorMessage', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        bg: 'BG_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        fnt: 'FONT',
        trns: 'TRANSITION'
    },
    paramsDefaults: {
        rd: '50%',
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        brw: '2px',
        brd: '#999999',
        bg: '#ffffff',
        txt: 'color_15',
        fnt: 'font_7',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s'
    },
    css: {
        '%': 'position:relative;',
        '% fieldset input[data-type="radio"]':
            'width:16px;height:16px;-webkit-appearance:none !important;-moz-appearance:none !important;box-sizing:border-box;[rd]  [shd]  border:[brw] solid [brd];background-color:[bg];margin:0;cursor:pointer;position:relative;',
        '% fieldset input[data-type="radio"]:checked:after': 'position:absolute;top:-7px;color:[txt];content:"●";text-align:center;font-size:19px;',
        '%collection': '[fnt]  color:[txt];',
        '% p': '[trns]  [rd]  opacity:0;position:absolute;right:0;bottom:18px;width:140px;padding:10px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '%[data-state~="invalid"]:hover + p': 'opacity:1;color:#d00;'
    }
}
skins['skins.core.ButtonSkin'] = {
    react: [
        ['span', null, ['_buttonLeft', '_buttonStates'], {}],
        ['span', 'caption', ['_buttonCenter', '_buttonStates'], {}],
        ['span', null, ['_buttonRight', '_buttonStates'], {}]
    ],
    params: {
        themeDir: 'URL'
    },
    paramsDefaults: {
        themeDir: 'THEME_DIRECTORY'
    },
    css: {
        '%': 'cursor:pointer;display:inline;',
        '%[disabled]': 'cursor:default;',
        '%_buttonLeft': 'display:inline-block;background:url([themeDir]btns_left.png) no-repeat;height:35px;width:9px;',
        '%_buttonCenter':
            'display:inline-block;font-weight:bold;height:35px;background:url([themeDir]btns_mid.png) repeat-x top;vertical-align:top;line-height:34px;',
        '%_buttonRight': 'display:inline-block;background:url([themeDir]btns_right.png) no-repeat;height:35px;width:9px;',
        '%_buttonStates': 'background-position:0 -72px;color:#444;',
        '%[data-state="grayed"] %_buttonStates': 'background-position:0 -146px !important;color:#555;',
        '%[disabled] %_buttonStates': 'background-position:0 -109px !important;color:#888;'
    }
}
skins['skins.core.ContactItemSkin'] = {
    react: [
        ['div', null, ['_btnIcon'], {}, ['img', 'icon', [], {}]],
        ['div', null, ['_btnInfo'], {}, ['div', 'label', ['_btnText'], {}], ['div', 'description', ['_btnText2'], {}]]
    ],
    params: {
        fontColor: 'TEXT_COLOR',
        itemSpacing: 'CSSString',
        $ButtonIconBgColor: 'BG_COLOR_ALPHA',
        iconSize: 'WIDTH_SIZE',
        $font: 'FONT'
    },
    paramsDefaults: {
        fontColor: 'textColor',
        itemSpacing: 'itemSpacing',
        $ButtonIconBgColor: 'iconBgColor',
        iconSize: 'iconSize',
        $font: 'fontButton'
    },
    css: {
        '%': 'position:relative;color:[fontColor];margin-bottom:[itemSpacing];',
        '%[data-class~="lastItem"]': 'margin-bottom:0;',
        '% > %_btnIcon': 'position:absolute;vertical-align:top;left:0;top:0;background-color:[$ButtonIconBgColor];',
        '% > %_btnIcon img': 'width:[iconSize];display:block;',
        '% > %_btnInfo': 'position:relative;overflow:visible;padding-left:3.6em;padding-bottom:.2em;',
        '% > %_btnInfo > %_btnText': '[$font]  font-weight:bold;margin-left:1em;line-height:1.2em !important;word-wrap:break-word;',
        '% > %_btnInfo > %_btnText2': '[$font]  font-weight:normal;margin-left:1em;line-height:1.15em !important;word-wrap:break-word;'
    }
}
skins['skins.core.GlobalMenuSkin'] = {
    react: [['div', 'itemsContainer', ['_linksList'], {}]]
}
skins['skins.core.HeaderSkin'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['div', 'image', ['_mediumHeaderImage'], {}],
        ['h1', 'title', [], {}]
    ],
    params: {
        headerSpacing: 'MARGIN_TOP_SIZE',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        fontColor: 'TEXT_COLOR',
        $font: 'FONT'
    },
    paramsDefaults: {
        headerSpacing: 'headerSpacing',
        $bgColor: 'headerBgColor',
        themeDir: 'THEME_DIRECTORY',
        fontColor: 'headerTextColor',
        $font: 'fontHeader'
    },
    css: {
        '%': 'position:relative;margin-top:[headerSpacing];overflow:hidden;padding:1em 0 2em;word-wrap:break-word;',
        '%_bg': 'position:absolute;width:100%;height:100%;left:0;top:0;background-color:[$bgColor];background-image:url([themeDir]headerBg.png);background-position:bottom;background-repeat:repeat;',
        '% > h1': 'position:relative;display:inline;color:[fontColor];[$font]  word-wrap:break-word;',
        '%[data-state="showImage"]': 'text-align:left;',
        '%[data-state="showImage"] > [data-skinPart="image"]': 'margin:0 1em;',
        '%[data-state="hideImage"]': 'text-align:center;',
        '%[data-state="hideImage"] > [data-skinPart="image"]': 'display:none;',
        '% > [data-skinPart="image"]': 'position:relative;margin:0 1em;float:left;',
        '% > [data-skinPart="image"]%_smallHeaderImage': 'width:3em;height:3em;',
        '% > [data-skinPart="image"]%_mediumHeaderImage': 'width:5em;height:5em;',
        '% > [data-skinPart="image"]%_largeHeaderImage': 'width:7em;height:7em;'
    }
}
skins['skins.core.HomeButtonSkin'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['div', 'text', [], {}]
    ],
    params: {
        ButtonBorder: 'CSSString',
        sitePadding: 'CSSString',
        componentSpacing: 'CSSString',
        $BorderRadius: 'BORDER_RADIUS',
        $ButtonBgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        padding: 'CSSString',
        fontColor: 'TEXT_COLOR',
        $font: 'FONT'
    },
    paramsDefaults: {
        ButtonBorder: 'borderButton',
        sitePadding: 'padding1',
        componentSpacing: 'componentSpacing',
        $BorderRadius: 'radiusButton',
        $ButtonBgColor: 'areaBgColor',
        themeDir: 'THEME_DIRECTORY',
        padding: 'padding2',
        fontColor: 'textColor',
        $font: 'fontButton'
    },
    css: {
        '%': 'position:relative;border:[ButtonBorder];overflow:hidden;margin:[sitePadding];margin-top:[componentSpacing];margin-bottom:[componentSpacing];[$BorderRadius]',
        '%_bg': 'position:absolute;width:100%;height:100%;background-color:[$ButtonBgColor];background-image:url([themeDir]inlineShadow.png);background-repeat:repeat-x;[$BorderRadius]',
        '%text': 'position:relative;padding:[padding];color:[fontColor];[$font]'
    }
}
skins['skins.core.MenuButtonSkin'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['div', null, ['_btnIcon'], {}, ['img', 'icon', [], {}]],
        ['div', null, ['_btnBullet'], {}, ['div', null, [], {}, ['div', null, [], {}, ['div', null, [], {}]]]],
        ['div', 'label', ['_btnText'], {}]
    ],
    params: {
        ButtonBorder: 'CSSString',
        ItemSpacing: 'CSSString',
        $BorderRadius: 'BORDER_RADIUS',
        iconSize: 'PADDING_SIZE',
        fontColor: 'TEXT_COLOR',
        $ButtonBgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        $ButtonIconBorder: 'CSSString',
        $ButtonIconBgColor: 'BG_COLOR_ALPHA',
        $ButtonIconBorderRadius: 'BORDER_RADIUS',
        bulletSize: 'PADDING_SIZE',
        $font: 'FONT'
    },
    paramsDefaults: {
        ButtonBorder: 'borderButton',
        ItemSpacing: 'itemSpacing',
        $BorderRadius: 'radiusButton',
        iconSize: 'iconSize',
        fontColor: 'textColor',
        $ButtonBgColor: 'areaBgColor',
        themeDir: 'THEME_DIRECTORY',
        $ButtonIconBorder: 'borderIcon',
        $ButtonIconBgColor: 'iconBgColor',
        $ButtonIconBorderRadius: 'radiusIcon',
        bulletSize: 'bulletSize',
        $font: 'fontButton'
    },
    css: {
        '%': 'position:relative;border:[ButtonBorder];overflow:hidden;margin:[ItemSpacing] 0;[$BorderRadius]  min-height:[iconSize];color:[fontColor];',
        '% > %_bg':
            'position:absolute;top:0;left:0;width:100%;height:100%;background-color:[$ButtonBgColor];[$BorderRadius]  background-image:url([themeDir]inlineShadow.png);background-repeat:repeat-x;',
        '% > %_btnIcon':
            'z-index:1;position:absolute;top:0;bottom:0;left:0;border-right:[$ButtonIconBorder];background-color:[$ButtonIconBgColor];[$ButtonIconBorderRadius]',
        '% > %_btnIcon img': 'position:relative;[$ButtonIconBorderRadius]  width:[iconSize];height:[iconSize];',
        '% > %_btnBullet': 'z-index:1;position:absolute;top:0;bottom:0;right:.3em;line-height:inherit;',
        '% > %_btnBullet > div': 'display:table;height:100%;',
        '% > %_btnBullet > div > div': 'display:table-cell;vertical-align:middle;',
        '% > %_btnBullet > div > div > div': 'background:url([themeDir]rightPointer.png) no-repeat right center;height:[iconSize];width:[bulletSize];',
        '% > %_btnText': '[$font]  position:relative;padding:0 [bulletSize] 0 [iconSize];word-wrap:break-word;'
    }
}
skins['skins.core.NetworkItemSkin'] = {
    react: [
        ['div', null, ['_btnIcon'], {}, ['img', 'icon', [], {}]],
        ['div', null, ['_btnText'], {}, ['span', 'label', [], {}]]
    ],
    params: {
        itemSpacing: 'CSSString',
        $ButtonIconBgColor: 'BG_COLOR_ALPHA',
        iconSize: 'WIDTH_SIZE',
        $font: 'FONT',
        fontColor: 'TEXT_COLOR'
    },
    paramsDefaults: {
        itemSpacing: 'itemSpacing',
        $ButtonIconBgColor: 'iconBgColor',
        iconSize: 'iconSize',
        $font: 'fontButton',
        fontColor: 'textColor'
    },
    css: {
        '%': 'position:relative;margin-bottom:[itemSpacing];',
        '%[data-class~="lastItem"]': 'margin-bottom:0;',
        '% > %_btnIcon': 'position:absolute;background-color:[$ButtonIconBgColor];',
        '% > %_btnIcon img': 'width:[iconSize];display:block;',
        '% > %_btnText': 'position:relative;[$font]  color:[fontColor];padding-left:3.6em;padding-bottom:.2em;'
    }
}
skins['skins.core.NetworkListSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'itemsContainer', [], {}]
    ],
    exports: {
        itemSkinClassName: 'mobile.core.skins.NetworkItemSkin'
    },
    params: {
        componentSpacing: 'CSSString',
        $BorderRadius: 'BORDER_RADIUS',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        padding: 'CSSString',
        border: 'CSSString'
    },
    paramsDefaults: {
        componentSpacing: 'componentSpacing',
        $BorderRadius: 'radiusContainer',
        $bgColor: 'areaBgColor',
        themeDir: 'THEME_DIRECTORY',
        padding: 'padding1',
        border: 'borderContainer'
    },
    css: {
        '%': 'position:relative;margin-bottom:[componentSpacing];[$BorderRadius]',
        '%bg': 'position:absolute;width:100%;height:100%;background-color:[$bgColor];[$BorderRadius]  background-image:url([themeDir]inlineShadow.png);background-repeat:repeat-x;',
        '%itemsContainer': 'position:relative;padding:[padding];[$BorderRadius]  border:[border];'
    }
}
skins['skins.core.PhotoFullScreenSkin'] = {
    react: [
        ['div', 'img', [], {}],
        [
            'div',
            'controls',
            ['_hidden'],
            {},
            ['div', null, ['_opacity'], {}],
            ['div', null, ['_holder'], {}, ['h2', 'title', [], {}], ['div', 'description', [], {}]]
        ],
        ['img', 'infoButton', [], {}],
        ['img', 'exitButton', [], {}]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        }
    },
    params: {
        $overlayBgColor: 'BG_COLOR_ALPHA',
        padding: 'CSSString',
        overlayFontColor: 'TEXT_COLOR',
        $fontTitle: 'FONT',
        $fontDesc: 'FONT'
    },
    paramsDefaults: {
        $overlayBgColor: '0, 0, 0, 0.8',
        padding: 'padding1',
        overlayFontColor: '255, 255, 255, 1',
        $fontTitle: 'fontSubTitle',
        $fontDesc: 'fontText'
    },
    css: {
        '%': 'position:relative;width:100%;height:100%;overflow:hidden;',
        '%img': 'position:absolute;',
        '%img > div': 'margin:0;padding:0;position:absolute !important;',
        '%controls': 'position:absolute;bottom:0;width:100%;height:100%;',
        '%_opacity': 'position:absolute;width:100%;height:100%;background-color:[$overlayBgColor];',
        '%_holder': 'position:absolute;bottom:0;padding:[padding];color:[overlayFontColor];',
        '%infoButton,%exitButton': 'position:absolute;top:5px;width:2.5em;height:2.5em;',
        '%infoButton': 'right:5px;',
        '%exitButton': 'left:5px;',
        '%title': '[$fontTitle]',
        '%description': '[$fontDesc]'
    }
}
skins['skins.core.PhotoGalleryFullScreenDefaultSkin'] = {
    react: [
        ['div', 'imagesContainer', [], {}],
        ['div', null, ['_nav'], {}, ['a', 'nextButton', [], {}], ['a', 'prevButton', [], {}]],
        [
            'div',
            'controls',
            ['_hidden'],
            {},
            ['div', null, ['_opac'], {}],
            [
                'div',
                null,
                ['_holder'],
                {},
                ['div', null, ['_hide'], {}, ['a', null, [], {}, ['img', 'exitButton', [], {}]]],
                ['div', null, ['_meta'], {}, ['h2', 'title', [], {}], ['div', 'description', [], {}]]
            ]
        ]
    ],
    exports: {
        imageSkinClassName: 'skins.core.ImageSkin'
    },
    params: {
        themeDir: 'URL',
        $overlayBgColor: 'BG_COLOR_ALPHA',
        padding: 'CSSString',
        overlayFontColor: 'TEXT_COLOR',
        $fontTitle: 'FONT',
        $fontDesc: 'FONT'
    },
    paramsDefaults: {
        themeDir: 'THEME_DIRECTORY',
        $overlayBgColor: '0, 0, 0, 0.8',
        padding: 'padding1',
        overlayFontColor: '255, 255, 255, 1',
        $fontTitle: 'fontSubTitle',
        $fontDesc: 'fontText'
    },
    css: {
        '%': 'width:100%;height:100%;overflow:hidden;',
        '%imagesContainer': 'position:absolute;',
        '%imagesContainer > div': 'margin:0;padding:0;position:absolute !important;',
        '%_nav': 'position:relative;height:100%;',
        '%_nav a': 'position:absolute;top:0;bottom:0;width:50px;',
        '%nextButton': 'right:0;background:url([themeDir]gallery-arrow-right.png) no-repeat center;',
        '%prevButton': 'left:0;background:url([themeDir]gallery-arrow-left.png) no-repeat center;',
        '%exitButton': 'width:5.5em;height:2.5em;',
        '%controls': 'position:absolute;top:0;width:100%;height:100%;',
        '%_opac': 'position:absolute;width:100%;height:100%;background-color:[$overlayBgColor];',
        '%_holder': 'position:relative;padding:[padding];',
        '%_meta': 'color:[overlayFontColor];',
        '%title': '[$fontTitle]',
        '%description': '[$fontDesc]'
    }
}
skins['skins.core.PhotoGalleryGridDefaultSkin'] = {
    react: [['span', 'imagesContainer', [], {}]],
    exports: {
        itemSkinClassName: 'skins.core.ImageSkin',
        fullScreenViewSkinClassName: 'mobile.core.skins.PhotoGalleryFullScreenDefaultSkin'
    },
    params: {
        componentSpacing: 'CSSString',
        thumbSpacing: 'CSSString',
        $thumbBorderRadius: 'BORDER_RADIUS',
        borderThumb: 'CSSString'
    },
    paramsDefaults: {
        componentSpacing: 'componentSpacing',
        thumbSpacing: 'thumbSpacing',
        $thumbBorderRadius: 'radiusThumb',
        borderThumb: 'borderThumb'
    },
    css: {
        '%': 'text-align:center;overflow:hidden;margin-bottom:[componentSpacing];',
        '%imagesContainer': 'margin:0 auto;',
        '%imagesContainer div': 'float:left;',
        '%imagesContainer div[comp="core%_components%_Image"]':
            'margin:0 [thumbSpacing] [thumbSpacing] [thumbSpacing];[$thumbBorderRadius]  border:[borderThumb];'
    }
}
skins['skins.core.PhotoSkin'] = {
    react: [
        ['div', 'img', [], {}],
        ['div', 'photoFullScreen', [], {}]
    ],
    exports: {
        img: {
            skin: 'skins.core.ImageSkin'
        },
        photoFullScreen: {
            skin: 'mobile.core.skins.PhotoFullScreenSkin'
        }
    },
    css: {
        '%': 'margin:7px auto;width:70%;',
        '%img': 'margin:0 auto;',
        '%[data-state="small"]': 'width:50%;',
        '%[data-state="medium"]': 'width:70%;',
        '%[data-state="large"]': 'width:100%;'
    }
}
skins['skins.core.RichTextImageSkin'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['div', null, ['_content'], {}, ['div', 'image', ['_image'], {}], ['div', 'text', ['_text'], {}]]
    ],
    params: {
        itemSpacing: 'CSSString',
        $borderRadius: 'BORDER_RADIUS',
        border: 'CSSString',
        $bgColor: 'BG_COLOR_ALPHA',
        $font: 'FONT',
        fontColor: 'TEXT_COLOR'
    },
    paramsDefaults: {
        itemSpacing: 'itemSpacing',
        $borderRadius: 'radiusContainer',
        border: 'borderContainer',
        $bgColor: 'areaBgColor',
        $font: 'fontText',
        fontColor: 'textColor'
    },
    css: {
        '%': 'position:relative;margin-bottom:[itemSpacing];[$borderRadius]  border:[border];',
        '% > %_bg': 'position:absolute;[$borderRadius]  background-color:[$bgColor];left:0;width:100%;height:100%;',
        '% > %_content': 'position:relative;',
        '% > %_content > %_image': 'float:left;[$borderRadius]  padding:0 !important;margin:0 !important;',
        '% > %_content > %_image img': '[$borderRadius]  padding:0 !important;margin:0 !important;',
        '% > %_content > %_text': '[$font]  color:[fontColor];padding:0 1em 1em;',
        '%[data-state="imageOnLeft"] > %_content > %_image': 'float:left;',
        '%[data-state="imageOnRight"] > %_content > %_image': 'float:right;',
        '%[data-state="noImage"] > %_content > %_image': 'display:none;'
    }
}
skins['skins.core.RichTextSkin'] = {
    react: [
        ['div', 'bg', [], {}],
        ['div', 'richTextContainer', ['_richTextContainer'], {}]
    ],
    params: {
        $borderRadius: 'BORDER_RADIUS',
        border: 'CSSString',
        componentSpacing: 'CSSString',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        padding: 'CSSString',
        fontColor: 'TEXT_COLOR',
        $font: 'FONT'
    },
    paramsDefaults: {
        $borderRadius: 'radiusContainer',
        border: 'borderContainer',
        componentSpacing: 'componentSpacing',
        $bgColor: 'areaBgColor',
        themeDir: 'THEME_DIRECTORY',
        padding: 'padding1',
        fontColor: 'textColor',
        $font: 'fontText'
    },
    css: {
        '%': 'position:relative;[$borderRadius]  border:[border];margin-bottom:[componentSpacing];',
        '%bg': 'position:absolute;width:100%;height:100%;background-color:[$bgColor];background-image:url([themeDir]inlineShadow.png);background-repeat:repeat-x;[$borderRadius]',
        '%richTextContainer': 'position:relative;padding:[padding];color:[fontColor] !important;',
        '% p,% li': '[$font]  color:[fontColor] !important;',
        '% span': '[$font]  color:[fontColor] !important;display:inline;'
    }
}
skins['skins.core.ServiceItemSkin'] = {
    react: [
        ['div', null, ['_bg'], {}],
        ['div', 'icon', ['_btnIcon'], {}],
        ['div', 'allText', ['_info'], {}, ['div', 'label', ['_label'], {}], ['div', 'description', ['_richTextContainer'], {}]],
        ['div', null, ['_readMore'], {}, ['div', null, ['_topCornerFix'], {}], ['div', 'readMore', [], {}]]
    ],
    params: {
        itemSpacing: 'CSSString',
        border: 'CSSString',
        $borderRadius: 'BORDER_RADIUS',
        $bgColor: 'BG_COLOR_ALPHA',
        themeDir: 'URL',
        fontColor: 'TEXT_COLOR',
        $fontTitle: 'FONT',
        $font: 'FONT',
        $moreBgColor: 'BG_COLOR_ALPHA',
        moreFontColor: 'TEXT_COLOR',
        bgColor: 'COLOR'
    },
    paramsDefaults: {
        itemSpacing: 'itemSpacing',
        border: 'borderContainer',
        $borderRadius: 'radiusContainer',
        $bgColor: 'areaBgColor',
        themeDir: 'THEME_DIRECTORY',
        fontColor: 'textColor',
        $fontTitle: 'fontSubTitle',
        $font: 'fontText',
        $moreBgColor: 'textColor',
        moreFontColor: 'areaBgColor',
        bgColor: 'areaBgColor'
    },
    css: {
        '%': 'position:relative;padding:.5em;-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out;min-height:7.5em;max-height:1000%;overflow:hidden;margin-bottom:[itemSpacing];border:[border];[$borderRadius]',
        '% > %_bg':
            'position:absolute;left:0;top:0;width:100%;height:100%;background-color:[$bgColor];background-image:url([themeDir]inlineShadow.png);background-repeat:repeat-x;[$borderRadius]',
        '% > %_btnIcon': 'z-index:1;position:relative;float:left;overflow:hidden;vertical-align:top;margin-right:.5em;width:6em;height:6em;',
        '% > %_btnIcon img': '[$borderRadius]  padding:0 !important;margin:0 !important;',
        '% > %_info': 'position:relative;[$borderRadius]  min-height:7em;margin-top:0em;margin-bottom:1em;',
        '% > %_info > %_label': 'color:[fontColor];[$fontTitle]  margin-bottom:.1em;',
        '% > %_info > %_richTextContainer': 'display:block;[$font]  color:[fontColor];word-wrap:break-word;',
        '% > %_info > %_richTextContainer p': '[$font]  color:[fontColor] !important;',
        '% > %_info > %_richTextContainer span': '[$font]  color:[fontColor] !important;',
        '% > %_readMore': 'position:absolute;left:0;bottom:0;width:100%;cursor:pointer;background-color:[$moreBgColor];[$borderRadius]',
        '% > %_readMore > div[data-skinPart="readMore"]':
            'position:relative;[$font]  font-weight:bold;text-align:center;color:[moreFontColor];padding:.25em 0;',
        '% > %_readMore > div%_topCornerFix': 'position:absolute;background-color:[$moreBgColor];height:50%;width:100%;border-top:2px solid [bgColor];',
        '%[data-state="cutClosed"]': 'max-height:7.5em;height:7.5em;',
        '%[data-state="cutOpened"]': 'padding-bottom:1.5em;',
        '%[data-state="uncut"] %_info': 'min-height:0 !important;',
        '%[data-state="uncut"]': 'min-height:0 !important;',
        '%[data-state="uncut"] %_readMore': 'display:none;'
    }
}
skins['skins.core.ServiceListSkin'] = {
    react: [['div', 'itemsContainer', [], {}]]
}
skins['skins.core.SimpleButtonSkin'] = {
    react: [['div', 'hitArea', [], {}, ['span', 'label', [], {}]]]
}
skins['wysiwyg.viewer.skins.contactform.ButtonBaseSkinDefault'] = {
    react: [
        ['div', 'icon', [], {}],
        ['div', 'label', ['_lbl'], {}]
    ],
    css: {
        '%label': 'min-height:20px;'
    }
}
skins['wysiwyg.viewer.skins.contactform.ButtonInputSkinDefault'] = {
    react: [
        ['label', 'label', [], {}],
        ['div', 'button', ['_btn'], {}]
    ],
    exports: {
        button: {
            skin: 'wysiwyg.viewer.skins.contactform.ButtonBaseSkinDefault'
        }
    },
    css: {}
}
skins['wysiwyg.viewer.skins.contactform.InputSkinDefault'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['div', 'message', [], {}]
    ],
    css: {}
}
skins['wysiwyg.viewer.skins.contactform.LabelSkinDefault'] = {
    react: [['p', 'label', [], {}]],
    css: {}
}
skins['wysiwyg.viewer.skins.contactform.TextAreaSkinDefault'] = {
    react: [
        ['label', 'label', [], {}],
        ['textarea', 'textarea', [], {}]
    ],
    css: {
        '%textarea': 'resize:none;'
    }
}
skins['wysiwyg.viewer.skins.ContactFormSkin'] = {
    react: [
        ['span', 'nameLabel', [], {}],
        [
            'input',
            'name',
            ['_field'],
            {
                type: 'text'
            }
        ],
        ['span', 'emailLabel', [], {}],
        [
            'input',
            'email',
            ['_field'],
            {
                type: 'text'
            }
        ],
        ['span', 'subjectLabel', [], {}],
        [
            'input',
            'subject',
            ['_field'],
            {
                type: 'text'
            }
        ],
        ['span', 'messageLabel', [], {}],
        ['textarea', 'message', ['_field'], {}],
        ['span', 'notifications', [], {}],
        ['button', 'send', [], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bgc1: 'BG_COLOR_ALPHA',
        fnt: 'FONT',
        c1: 'TEXT_COLOR',
        bgc2: 'BG_COLOR_ALPHA',
        c2: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '3px',
        bgc1: 'color_3',
        fnt: 'font_7',
        c1: 'color_8',
        bgc2: 'color_3',
        c2: 'color_8'
    },
    css: {
        '%_field': '[rd]  background-color:[bgc1];margin:0 0 5px 0;padding:5px;overflow:hidden;',
        '% input,% textarea': '[fnt]  color:[c1];border:0;padding:0;margin:0;background:none;width:100% !important;',
        '%button':
            '[rd]  background-color:[bgc2];[fnt]  color:[c2];width:35%;float:right;height:30px;line-height:30px;margin:5px 0 0 0;text-align:center;cursor:pointer;',
        '%notifications p': '[rd]  padding:5px;background-color:#fff;',
        '%[data-state="errors"] %notifications *': 'color:#8B0000;'
    }
}
skins['wysiwyg.viewer.skins.TouchMediaZoom'] = {
    react: [
        ['div', 'centeredDiv', [], {}, ['div', 'swipeStage', [], {}]],
        ['div', 'xButton', ['_btn'], {}]
    ],
    exports: {
        image: {
            skin: 'wysiwyg.viewer.skins.TouchMediaZoomItem'
        }
    },
    params: {
        tdr: 'URL'
    },
    paramsDefaults: {
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%': 'position:fixed;width:100%;height:100%;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);background-color:#000;z-index:100;will-change:transform;top:0;right:0;bottom:0;left:0;',
        '%centeredDiv': 'overflow:visible;width:100%;height:100%;-webkit-perspective:1px;perspective:1px;',
        '%centeredDiv > %swipeStage':
            'display:-webkit-box;display:-webkit-flex;display:flex;position:relative;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;height:100%;',
        '%centeredDiv > %swipeStage%_animate':
            '-webkit-transition:-webkit-transform 0.15s ease-out;transition:-webkit-transform 0.15s ease-out;transition:transform 0.15s ease-out;transition:transform 0.15s ease-out, -webkit-transform 0.15s ease-out;',
        '%xButton':
            'position:absolute;overflow:hidden;cursor:pointer;background-size:cover;background-image:url([tdr]x_button.png);background-repeat:no-repeat;background:no-repeat center/100% url([tdr]x_button.png);width:48px;height:48px;top:5px;right:5px;',
        '%xButton%_hidden': 'display:none;',
        '%xButton%_force-hide': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.TouchMediaZoomItem'] = {
    react: [
        ['div', 'leftFrame', [], {}],
        ['div', 'imageContainer', [], {}, ['img', 'lowRes', [], {}], ['img', 'hirRes', [], {}]],
        ['div', 'rightFrame', [], {}],
        [
            'div',
            'info',
            [],
            {},
            ['p', 'title', ['_info-field'], {}],
            ['p', 'description', ['_info-field'], {}],
            ['a', 'link', ['_info-field', '_info-link'], {}]
        ]
    ],
    exports: {
        image: {
            skin: 'skins.core.ImageSkin'
        }
    },
    css: {
        '%': 'position:absolute;height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;',
        '%imageContainer': 'height:100%;will-change:transform;',
        '%imageContainer img': 'position:absolute;margin:auto;top:0;right:0;bottom:0;left:0;will-change:transform;',
        '%_parallax %imageContainer': '-webkit-transform:translateZ(-0.2px) scale(1.2);transform:translateZ(-0.2px) scale(1.2);',
        '%leftFrame,%rightFrame': 'will-change:transform;background:#000;height:110vh;top:-5vh;z-index:1;-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;',
        '%info':
            'white-space:pre-wrap;position:absolute;top:60%;left:0;right:0;bottom:0;margin:auto;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;-webkit-align-content:flex-end;align-content:flex-end;-webkit-flex-wrap:wrap;flex-wrap:wrap;background:-webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);background:linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);will-change:transform;',
        '%info > %_info-field':
            '-webkit-flex-basis:100%;flex-basis:100%;text-align:center;color:#fff;margin:0 10px 10px 10px;font:normal normal 100 14px/1.5 Helvetica;-webkit-text-size-adjust:100%;-moz-text-size-adjust:100%;-ms-text-size-adjust:100%;overflow:hidden;overflow-wrap:break-word;',
        '%info > %_info-field%_info-link': "text-decoration:underline;font-style:italic;font-family:'Times New Roman';",
        '%info > %_info-field%_hidden': 'display:none;',
        '%info%_hidden': 'opacity:0;'
    }
}
skins['wysiwyg.viewer.skins.table.TableComponentDefaultSkin'] = {
    react: [['table', 'table', [], {}, ['thead', 'tableHeader', [], {}], ['tbody', 'tableBody', [], {}], ['tfoot', 'tableFooter', [], {}]]],
    params: {
        shd: 'BOX_SHADOW',
        bg: 'BG_COLOR_ALPHA',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        trans: 'TRANSITION',
        bgh: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        shd: '0 0 3px rgba(0, 0, 0, 0.5)',
        bg: 'color_11',
        brd: 'color_14',
        brw: '0',
        trans: 'background-color 0.5s ease 0s',
        bgh: 'color_13'
    },
    css: {
        '%': '[shd]  background-color:[bg];box-sizing:border-box !important;padding:20px;border:solid [brd] [brw];',
        '% table': 'width:100%;border-collapse:collapse;font-family:"Arial";font-size:11px;',
        '% table *': 'vertical-align:middle;',
        '% tbody': 'vertical-align:middle;',
        '% tbody td': 'background-color:[bg];border-bottom:solid 1px [brd];padding:5px;[trans]',
        '% tbody input[type="number"]': 'max-width:50px;text-align:center;',
        '% tbody tr:hover td': 'background-color:[bgh];',
        '% tbody tr%_spacer:hover td': 'background-color:[bg];',
        '% tr%_spacer': 'height:100%;',
        '% tr%_spacer td': 'padding:0 !important;border:0 none !important;'
    }
}
skins.TextAreaDefaultSkin = {
    react: [
        ['label', 'label', [], {}],
        ['textarea', 'textarea', [], {}]
    ],
    params: {
        shd: 'BOX_SHADOW',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        brw: 'BORDER_SIZES',
        bg: 'BG_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brd: 'BORDER_COLOR_ALPHA',
        txt2: 'TEXT_COLOR',
        brwh: 'BORDER_SIZES',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        bgd: 'BG_COLOR_ALPHA',
        txtd: 'TEXT_COLOR',
        brwd: 'BORDER_SIZES',
        brdd: 'BORDER_COLOR_ALPHA',
        brwf: 'BORDER_SIZES',
        bgf: 'BG_COLOR_ALPHA',
        brdf: 'BORDER_COLOR_ALPHA',
        brwe: 'BORDER_SIZES',
        bge: 'BG_COLOR_ALPHA',
        brde: 'BORDER_COLOR_ALPHA',
        fntlbl: 'FONT',
        txtlbl: 'TEXT_COLOR',
        txtlblrq: 'COLOR_ALPHA'
    },
    paramsDefaults: {
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        rd: '0',
        fnt: 'font_8',
        brw: '1px',
        bg: '#ffffff',
        txt: 'color_15',
        brd: '#e3e3e3',
        txt2: 'color_15',
        brwh: '1px',
        bgh: '#ffffff',
        brdh: '#a3d9f6',
        bgd: '#CCCCCC',
        txtd: '#FFFFFF',
        brwd: '1px',
        brdd: '#a3d9f6',
        brwf: '1px',
        bgf: '#ffffff',
        brdf: '#a3d9f6',
        brwe: '1px',
        bge: '#ffffff',
        brde: '#a3d9f6',
        fntlbl: 'font_8',
        txtlbl: 'color_15',
        txtlblrq: 'transparent'
    },
    css: {
        '%': 'display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;',
        '%textarea':
            '[shd]  [rd]  [fnt]  [brw]  resize:none;background-color:[bg];box-sizing:border-box !important;color:[txt];border-style:solid;border-color:[brd];padding:3px;margin:0;padding-top:0.75em;max-width:100%;min-width:100%;overflow-y:auto;-webkit-box-flex:1;-webkit-flex:1;flex:1;',
        '%textarea::-webkit-input-placeholder': 'color:[txt2];',
        '%textarea::-ms-input-placeholder': 'color:[txt2];',
        '%textarea::placeholder': 'color:[txt2];',
        '%textarea:hover,%textarea[data-preview~="hover"]': '[brwh]    background-color:[bgh];border-style:solid;border-color:[brdh];',
        '%_left-direction %textarea': 'text-align:left;',
        '%_right-direction %textarea': 'text-align:right;direction:rtl;',
        '%_center-direction %textarea': 'text-align:center;',
        '%[data-disabled="true"] %textarea,%[data-preview~="disabled"] %textarea':
            'background-color:[bgd];color:[txtd];[brwd]  border-style:solid;border-color:[brdd];',
        ':not(%_with-validation-indication) %textarea:focus,:not(%_with-validation-indication) %textarea[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%_with-validation-indication %textarea:invalid': '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];',
        '%_with-validation-indication %textarea:not(:invalid):focus,%_with-validation-indication %textarea[data-preview~="focus"]':
            '[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];',
        '%[data-error="true"] %textarea,%[data-preview~="error"] %textarea': '[brwe]  background-color:[bge];border-style:solid;border-color:[brde];',
        '%label': '[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;',
        '%_required %label::after': 'content:" *";color:[txtlblrq];'
    }
}
skins['wysiwyg.viewer.skins.input.TextAreaInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        ['textarea', 'textarea', [], {}],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        txt: 'TEXT_COLOR',
        brdh: 'BORDER_COLOR_ALPHA',
        brdf: 'BORDER_COLOR_ALPHA',
        fntlbl: 'FONT',
        txtlbl: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: '#ffffff',
        rd: '0',
        brw: '1px',
        brd: '#e3e3e3',
        txt: '#000000',
        brdh: '#a3d9f6',
        brdf: '#19a0e9',
        fntlbl: 'font_8',
        txtlbl: 'color_15'
    },
    css: {
        '%': 'position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;',
        '% textarea':
            'box-sizing:border-box;background-color:[bg];[rd]  position:relative;resize:none;width:100%;min-height:100px;-webkit-box-flex:1;-webkit-flex:1;flex:1;overflow:auto;font-size:14px;border:[brw] solid [brd];color:[txt];',
        '% textarea:hover': 'border-color:[brdh];',
        '% textarea:focus': 'border-color:[brdf];',
        '% p': 'display:none;top:0;bottom:50%;left:0;right:100%;position:absolute;margin:0;padding:0;',
        '%[data-state~="invalid"] p': 'display:block;',
        '%[data-state~="invalid"] p:before':
            'content:"!";visibility:visible;position:absolute;text-align:center;bottom:50%;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;color:#fff;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
        '%[data-state~="invalid"] p:after':
            'content:"►";visibility:visible;position:absolute;bottom:50%;left:3px;margin:0 0 -7px -2px;color:#fff;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;',
        '%[data-state~="invalid"] textarea:focus + p:before': 'content:"";visibility:hidden;',
        '%[data-state~="invalid"] textarea:focus + p:after': 'content:"";visibility:hidden;',
        '%label': '[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;'
    }
}
skins['wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperSimpleSkin'] = {
    react: [
        [
            'div',
            'inputNumberWrapper',
            [],
            {},
            [
                'input',
                'inputNumberInput',
                [],
                {
                    type: 'number',
                    step: '1'
                }
            ],
            ['div', 'controls', [], {}]
        ]
    ],
    params: {
        bordercolor: 'BORDER_COLOR_ALPHA',
        textcolor: 'TEXT_COLOR',
        backgroundcolor: 'BG_COLOR_ALPHA'
    },
    paramsDefaults: {
        bordercolor: 'color_14',
        textcolor: 'color_13',
        backgroundcolor: 'color_1'
    },
    css: {
        '%inputNumberWrapper': 'width:100%;height:38px;',
        '% input':
            'width:100%;position:relative;padding:5px;border:1px solid [bordercolor];color:[textcolor];background:[backgroundcolor];display:inline-block;margin-top:6px;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.EcomComboBoxInputSkin'] = {
    react: [['select', 'collection', [], {}]],
    css: {
        '%': 'position:relative;width:100% !important;',
        '% select': 'position:absolute;width:100%;',
        '%[data-state~="invalid"]:before,%[data-state~="invalid"]:after': 'position:absolute;bottom:50%;color:#fff;',
        '%[data-state~="invalid"]:before':
            'content:"!";text-align:center;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
        '%[data-state~="invalid"]:after': 'content:"►";left:3px;margin:0 0 -7px -2px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.EcomErasableTextInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['span', 'erase', [], {}],
        ['p', 'message', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        trns: 'TRANSITION',
        txt: 'TEXT_COLOR',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR'
    },
    paramsDefaults: {
        bg: '#e3e3e3',
        rd: '0',
        fnt: 'font_8',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s',
        txt: '#000000',
        brw: '1px',
        brd: '#e3e3e3'
    },
    css: {
        '%': 'position:relative;',
        '%input':
            'background-color:[bg];[rd]  box-sizing:border-box !important;[fnt]  [trns]  color:[txt];border:[brw] solid [brd];padding:6px;margin:0;width:100%;min-height:100%;',
        '% input::-ms-clear': 'display:none;',
        '%erase': 'position:absolute;font-size:12px;top:5px;right:5px;cursor:pointer;width:20px;height:20px;text-align:center;line-height:20px;color:[txt];',
        '%message': 'margin-top:13px;color:#d00;',
        '%[data-state~="showButton"] %erase': 'display:block;',
        '%[data-state~="showButton"] %input': 'padding-right:30px;',
        '%[data-state~="hideButton"] %erase': 'display:none;',
        '%[data-state~="invalid"] %input': 'border-color:#d00;'
    }
}
skins['wysiwyg.viewer.skins.appinputs.EcomTextInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        [
            'input',
            'input',
            [],
            {
                type: 'text'
            }
        ],
        ['p', 'message', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        fnt: 'FONT',
        trns: 'TRANSITION',
        txt: 'TEXT_COLOR',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR',
        brdh: 'BORDER_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        tdr: 'URL'
    },
    paramsDefaults: {
        bg: '#e3e3e3',
        rd: '0',
        fnt: 'font_8',
        trns: 'opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s',
        txt: '#000000',
        brw: '1px',
        brd: '#e3e3e3',
        brdh: '#a3d9f6',
        bgh: '#e3e3e3',
        tdr: 'BASE_THEME_DIRECTORY'
    },
    css: {
        '%': 'position:relative;min-height:25px;',
        '%input':
            'background-color:[bg];[rd]  box-sizing:border-box !important;[fnt]  [trns]  color:[txt];border:[brw] solid [brd];padding:6px;margin:0;width:100%;min-height:100%;',
        '%input:hover,%input:focus': 'border-color:[brdh];background-color:[bgh];',
        '%[data-state~="invalid"] %input': 'border-color:#d00;background:url([tdr]error.png) no-repeat 100% 50%;background-color:[bg];padding-right:40px;',
        '%[data-state~="invalid"] %input:hover + p': 'opacity:1;',
        '%[data-state~="invalid"] p': 'color:#d00;',
        '% p': '[trns]  opacity:0;[rd]  display:inline-block;position:absolute;right:0;bottom:100%;width:140px;padding:10px;white-space:normal;font-size:13px;line-height:16px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);',
        '% p:after': 'content:"▼";position:absolute;bottom:-12px;right:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);[rd]'
    }
}
skins['wysiwyg.viewer.skins.button.AddProductButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_7',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_16',
        txth: 'color_15'
    },
    css: {
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;',
        '%label': '[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;',
        '%:hover %link': 'background-color:[bgh];border-color:[brdh];[trans1]',
        '%:hover %label': 'color:[txth];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.button.ApplyButtonEcom'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        fnt: 'FONT',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_17',
        brw: '0',
        brd: 'color_15',
        fnt: 'font_8',
        txt: 'color_15',
        bgh: 'color_18',
        brdh: 'color_15',
        txth: 'color_15'
    },
    css: {
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];border:[brw] [brd] solid;cursor:pointer !important;',
        '%label': '[fnt]  color:[txt];white-space:nowrap;margin:1px [brw] 0 [brw];display:inline-block;position:relative;',
        '%:hover %link': 'background-color:[bgh];border-color:[brdh];',
        '%:hover %label': 'color:[txth];'
    }
}
skins['wysiwyg.viewer.skins.button.EcomFeedbackCheckoutButton'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_15',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        brd: 'color_15',
        brw: '0',
        fnt: 'font_8',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_11',
        bgh: 'color_14',
        brdh: 'color_14',
        txth: 'color_11'
    },
    css: {
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;',
        '%label': '[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;',
        '%:hover %link': 'background-color:[bgh];border-color:[brdh];[trans1]',
        '%:hover %label': 'color:[txth];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.button.EcomFeedbackContinueShopping'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_14',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        brd: 'color_14',
        brw: '0',
        fnt: 'font_8',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_11',
        bgh: 'color_13',
        brdh: 'color_13',
        txth: 'color_11'
    },
    css: {
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;',
        '%label': '[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;',
        '%:hover %link': 'background-color:[bgh];border-color:[brdh];[trans1]',
        '%:hover %label': 'color:[txth];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.button.EcomFeedbackContinueShopping2'] = {
    react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        trans1: 'TRANSITION',
        brd: 'BORDER_COLOR',
        brw: 'BORDER_SIZE',
        fnt: 'FONT',
        trans2: 'TRANSITION',
        txt: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        brdh: 'BORDER_COLOR',
        txth: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_11',
        trans1: 'border-color 0.4s ease 0s, background-color 0.4s ease 0s',
        brd: 'color_11',
        brw: '0',
        fnt: 'font_8',
        trans2: 'color 0.4s ease 0s',
        txt: 'color_15',
        bgh: 'color_11',
        brdh: 'color_11',
        txth: 'color_14'
    },
    css: {
        '%link': '[rd]  position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bg];[trans1]  border:solid [brd] [brw];cursor:pointer !important;',
        '%label': '[fnt]  [trans2]  color:[txt];white-space:nowrap;margin:calc(-1 * [brw]) [brw] 0 [brw];display:inline-block;position:relative;',
        '%:hover %link': 'background-color:[bgh];border-color:[brdh];[trans1]',
        '%:hover %label': 'color:[txth];[trans2]'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridNoBG'] = {
    react: [
        ['h3', 'title', ['_hid'], {}],
        ['p', 'description', ['_hid'], {}],
        ['a', 'link', ['_hid'], {}],
        ['div', 'autoplay', [], {}],
        ['div', 'itemsContainer', [], {}],
        [
            'ul',
            null,
            [],
            {},
            ['li', 'buttonPrev', [], {}, ['span', null, [], {}]],
            ['li', 'counter', [], {}],
            ['li', 'buttonNext', [], {}, ['span', null, [], {}]]
        ],
        ['div', 'rolloverHolder', ['_rolloverHolder'], {}]
    ],
    params: {
        itemBg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        itemBrc: 'BORDER_COLOR_ALPHA',
        itemBrw: 'BORDER_SIZE',
        bgh: 'BG_COLOR_ALPHA',
        txtHoverOutOfStock: 'TEXT_COLOR',
        paging: 'TEXT_COLOR'
    },
    paramsDefaults: {
        itemBg: 'color_11',
        rd: '0',
        shd: '0 0 0 rgba(0, 0, 0, 0.15)',
        itemBrc: 'color_12',
        itemBrw: '1px',
        bgh: 'color_17',
        txtHoverOutOfStock: '228, 82, 63, 1',
        paging: 'color_15'
    },
    css: {
        '%_hid': 'display:none;',
        '%itemsContainer': 'box-sizing:border-box !important;overflow:hidden;cursor:pointer !important;',
        '%itemsContainer%_show-counter': 'height:calc(100% - 40px) !important;',
        '%itemsContainer %_galleryDisplayer': 'box-sizing:border-box !important;background-color:[itemBg];[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%itemsContainer %_galleryDisplayer:hover': 'background-color:[bgh];',
        '%itemsContainer %_galleryDisplayer:hover %_txtHoverColorOutOfStock *': 'color:[txtHoverOutOfStock] !important;',
        '%_rolloverHolder': 'background-color:[bgh];box-sizing:border-box !important;[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%_multiline span': 'white-space:normal;',
        '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
        '%buttonPrev': 'cursor:pointer !important;',
        '%buttonPrev > span:before': "content:'◄';",
        '%buttonNext > span:before': "content:'►';",
        '%buttonNext': 'cursor:pointer !important;',
        '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridNoDetail'] = {
    react: [
        ['h3', 'title', ['_hid'], {}],
        ['p', 'description', ['_hid'], {}],
        ['a', 'link', ['_hid'], {}],
        ['div', 'autoplay', [], {}],
        ['div', 'itemsContainer', [], {}],
        [
            'ul',
            null,
            [],
            {},
            ['li', 'buttonPrev', [], {}, ['span', null, [], {}]],
            ['li', 'counter', [], {}],
            ['li', 'buttonNext', [], {}, ['span', null, [], {}]]
        ],
        ['div', 'rolloverHolder', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        itemBg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        itemBrc: 'BORDER_COLOR_ALPHA',
        itemBrw: 'BORDER_SIZE',
        bgh: 'BG_COLOR_ALPHA',
        txtHoverOutOfStock: 'TEXT_COLOR',
        paging: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_11',
        itemBg: 'color_11',
        rd: '0',
        shd: '0 0 0 rgba(0, 0, 0, 0)',
        itemBrc: 'color_12',
        itemBrw: '1px',
        bgh: 'color_12',
        txtHoverOutOfStock: '228, 82, 63, 1',
        paging: 'color_15'
    },
    css: {
        '%': 'background-color:[bg];',
        '%_hid': 'display:none;',
        '%itemsContainer': 'box-sizing:border-box !important;overflow:hidden;cursor:pointer !important;',
        '%itemsContainer%_show-counter': 'height:calc(100% - 40px) !important;',
        '%itemsContainer %_galleryDisplayer': 'box-sizing:border-box !important;background-color:[itemBg];[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%itemsContainer %_galleryDisplayer:hover': 'background-color:[bgh];',
        '%itemsContainer %_galleryDisplayer:hover %_txtHoverColorOutOfStock *': 'color:[txtHoverOutOfStock] !important;',
        '%itemsContainer %_galleryDisplayer %_txtHoverColorOutOfStockMobile *': 'color:[txtHoverOutOfStock] !important;',
        '%rolloverHolder': 'background-color:[bgh];box-sizing:border-box !important;[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%_multiline span': 'white-space:normal;',
        '%buttonPrev': 'cursor:pointer !important;',
        '%buttonPrev > span:before': "content:'◄';",
        '%buttonNext > span:before': "content:'►';",
        '%buttonNext': 'cursor:pointer !important;',
        '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
        '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.PaginatedGridSimple'] = {
    react: [
        ['h3', 'title', ['_hid'], {}],
        ['p', 'description', ['_hid'], {}],
        ['a', 'link', ['_hid'], {}],
        ['div', 'autoplay', [], {}],
        ['div', 'itemsContainer', [], {}],
        [
            'ul',
            null,
            [],
            {},
            ['li', 'buttonPrev', [], {}, ['span', null, [], {}]],
            ['li', 'counter', [], {}],
            ['li', 'buttonNext', [], {}, ['span', null, [], {}]]
        ],
        ['div', 'rolloverHolder', [], {}]
    ],
    params: {
        rd: 'BORDER_RADIUS',
        bg: 'BG_COLOR_ALPHA',
        itemBrw: 'BORDER_SIZE',
        itemBrc: 'BORDER_COLOR_ALPHA',
        rbn: 'BG_COLOR_ALPHA',
        strip: 'BG_COLOR_ALPHA',
        txth: 'TEXT_COLOR',
        txtHoverOutOfStock: 'TEXT_COLOR',
        bgh: 'BG_COLOR_ALPHA',
        itemBrch: 'BORDER_COLOR_ALPHA',
        tdr: 'URL',
        paging: 'TEXT_COLOR'
    },
    paramsDefaults: {
        rd: '0',
        bg: 'color_11',
        itemBrw: '1px',
        itemBrc: 'color_12',
        rbn: 'color_18',
        strip: 'color_12',
        txth: 'color_15',
        txtHoverOutOfStock: '228, 82, 63, 1',
        bgh: 'color_12',
        itemBrch: 'color_12',
        tdr: 'BASE_THEME_DIRECTORY',
        paging: 'color_15'
    },
    css: {
        '%itemsContainer': 'overflow:hidden;',
        '%itemsContainer%_show-counter': 'height:calc(100% - 40px) !important;',
        '%_hid': 'display:none;',
        '%_galleryDisplayer': 'box-sizing:border-box;[rd]  background-color:[bg];border:[itemBrw] solid [itemBrc];cursor:pointer !important;',
        '%_galleryDisplayer %_rolloverStrip': 'background:transparent;',
        '%_galleryDisplayer %_ribbon': 'background-color:[rbn];',
        '%_galleryDisplayer:hover %_rolloverStrip': 'background:[strip];',
        '%_galleryDisplayer:hover %_txtHoverColor *': 'color:[txth] !important;',
        '%_galleryDisplayer:hover %_txtHoverColorOutOfStock *': 'color:[txtHoverOutOfStock];',
        '%_galleryDisplayer %_txtHoverColorOutOfStockMobile *': 'color:[txtHoverOutOfStock];',
        '%_galleryDisplayer:hover': 'background-color:[bgh];border-color:[itemBrch];',
        '%_galleryDisplayer:hover %_pic:after':
            'content:"";position:absolute;top:0;right:0;bottom:-6px;left:0;background:transparent url([tdr]wix_apps/magnify.png) right top no-repeat;border-bottom:solid 3px [txth];',
        '%buttonPrev': 'cursor:pointer !important;',
        '%buttonPrev > span:before': "content:'◄';",
        '%buttonNext > span:before': "content:'►';",
        '%buttonNext': 'cursor:pointer !important;',
        '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;',
        '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
    }
}
skins['wixapps.integration.skins.ecommerce.inputs.ComboBoxInputSkin'] = {
    react: [['select', 'collection', [], {}]],
    css: {
        '%': 'position:relative;',
        '%[data-state~="invalid"]:before':
            'content:"!";position:absolute;text-align:center;bottom:50%;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;color:#fff;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
        '%[data-state~="invalid"]:after':
            'content:"►";position:absolute;bottom:50%;left:3px;margin:0 0 -7px -2px;color:#fff;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;'
    }
}
skins['wixapps.integration.skins.ecommerce.inputs.TextAreaInputSkin'] = {
    react: [
        ['label', 'label', [], {}],
        ['textarea', 'textarea', [], {}],
        ['p', 'errorMessage', [], {}]
    ],
    params: {
        txt: 'TEXT_COLOR',
        bg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        brw: 'BORDER_SIZE',
        brd: 'BORDER_COLOR_ALPHA',
        brdh: 'BORDER_COLOR_ALPHA',
        brdf: 'BORDER_COLOR_ALPHA'
    },
    paramsDefaults: {
        txt: '#000000',
        bg: '#ffffff',
        rd: '0',
        brw: '1px',
        brd: '#e3e3e3',
        brdh: '#a3d9f6',
        brdf: '#19a0e9'
    },
    css: {
        '%': 'position:relative;',
        '%textarea':
            'color:[txt];box-sizing:border-box;background-color:[bg];[rd]  resize:none;width:100%  !important;min-height:100px;height:100%;overflow:auto;font-size:14px;border:[brw] solid [brd];box-shadow:0 1px 1px 0 rgba(0, 0, 0, 0.3) inset;',
        '%textarea:hover': 'border-color:[brdh];',
        '%textarea:focus': 'border-color:[brdf];',
        '%[data-state~="invalid"]:before':
            'content:"!";position:absolute;text-align:center;bottom:50%;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;color:#fff;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
        '%[data-state~="invalid"]:after':
            'content:"►";position:absolute;bottom:50%;left:3px;margin:0 0 -7px -2px;color:#fff;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;'
    }
}
skins['wixapps.integration.skins.IconSkin'] = {
    react: [['img', 'img', [], {}]]
}
skins['wixapps.integration.skins.ImageButtonSkin'] = {
    react: [],
    css: {}
}
skins['wixapps.integration.skins.inputs.ComboBoxInputSkin'] = {
    react: [['select', 'collection', [], {}]],
    params: {
        borderColor: 'BORDER_COLOR',
        $borderRadius: 'BORDER_RADIUS',
        bgColor: 'BG_COLOR_ALPHA',
        webThemeDir: 'URL',
        borderColorOver: 'BORDER_COLOR',
        bgColorOver: 'BG_COLOR_ALPHA',
        shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
        shadowInsetColorOver: 'BOX_SHADOW_COLOR_ALPHA'
    },
    paramsDefaults: {
        borderColor: '#c4c4c4',
        $borderRadius: '4px',
        bgColor: '#f9fafc',
        webThemeDir: 'WEB_THEME_DIRECTORY',
        borderColorOver: '#89a4af',
        bgColorOver: '#f2f3f5',
        shadowColor: '0, 0, 0, 0.2',
        shadowInsetColorOver: '255, 255, 255, 0.7'
    },
    css: {
        '%::after': 'content:"▾";position:absolute;right:3px;top:2px;color:#3095CB;font-size:25px;',
        '%collection': 'width:200px;border:0;-webkit-appearance:none;-moz-appearance:none;background-color:transparent;padding:5px;cursor:pointer;',
        '%': 'width:200px;border:1px solid [borderColor];[$borderRadius]  background:[bgColor] url([webThemeDir]button/button-gradient.png) repeat-x 0 50%;position:relative;text-align:center;',
        '%:hover':
            'border:1px solid [borderColorOver];background:[bgColorOver] none;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;',
        '%[data-state~="invalid"]:before':
            'content:"!";position:absolute;text-align:center;bottom:50%;left:-20px;width:20px;height:20px;line-height:20px !important;margin-bottom:-11px;color:#fff;background:#f00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);font-size:12px;font-weight:bold;',
        '%[data-state~="invalid"]:after':
            'content:"►";position:absolute;bottom:50%;left:3px;margin:0 0 -7px -2px;color:#fff;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);font-size:10px;'
    }
}
skins['wixapps.integration.skins.ToggleSkin'] = {
    react: [
        ['div', 'on', ['_label'], {}],
        ['div', 'off', [], {}]
    ],
    css: {
        '%[data-state="on"] > %off': 'display:none;',
        '%[data-state="off"] > %off': 'display:block;padding-top:3px;cursor:pointer;',
        '%[data-state="on"] > %on': 'display:block;padding-top:3px;cursor:pointer;',
        '%[data-state="off"] > %on': 'display:none;'
    }
}
skins['wysiwyg.viewer.skins.IconLeftImageButtonWithText'] = {
    react: [
        [
            'div',
            'button',
            [],
            {},
            ['img', 'buttonIcon', [], {}],
            ['div', 'buttonTextWrapper', [], {}, ['span', 'buttonLabel', [], {}], ['span', 'buttonExtraInfo', [], {}]]
        ]
    ],
    css: {
        '%button': '-webkit-appearance:none;-moz-appearance:none;',
        '%_size-28%button': 'border-radius:4px;height:28px;position:relative;',
        '%_direction-ltr%_size-28%button': 'padding-left:28px;',
        '%_direction-rtl%_size-28%button': 'padding-right:28px;',
        '%_border-radius-editable%_size-28%button': 'border-radius:inherit;',
        '%_size-28%button:hover': 'cursor:pointer;',
        '%_size-28%button %buttonIcon': 'height:100%;position:absolute;width:28px;',
        '%_direction-ltr%_size-28%button %buttonIcon': 'left:0;',
        '%_direction-rtl%_size-28%button %buttonIcon': 'right:0;',
        '%_type-like-clicked%_size-28%button %buttonIcon':
            '-webkit-animation-name:heartbeat;animation-name:heartbeat;-webkit-animation-duration:0.5s;animation-duration:0.5s;',
        '%_size-28%button %buttonTextWrapper': 'font-size:9.8px;line-height:28px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#fff;',
        '%_direction-ltr%_size-28%button %buttonTextWrapper %buttonLabel': 'padding-right:9.8px;',
        '%_direction-rtl%_size-28%button %buttonTextWrapper %buttonLabel': 'padding-left:9.8px;',
        '%_label-empty%_size-28%button %buttonTextWrapper %buttonLabel': 'display:none;',
        '%_type-like%_size-28%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-like-clicked%_size-28%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-unlike%_size-28%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_direction-ltr%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'padding-right:9.8px;margin-left:1px;',
        '%_direction-rtl%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'padding-left:9.8px;margin-right:1px;',
        '%_extraInfo-empty%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'display:none;',
        '%_type-like%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-like-clicked%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-unlike%_size-28%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_size-32%button': 'border-radius:4px;height:32px;position:relative;',
        '%_direction-ltr%_size-32%button': 'padding-left:32px;',
        '%_direction-rtl%_size-32%button': 'padding-right:32px;',
        '%_border-radius-editable%_size-32%button': 'border-radius:inherit;',
        '%_size-32%button:hover': 'cursor:pointer;',
        '%_size-32%button %buttonIcon': 'height:100%;position:absolute;width:32px;',
        '%_direction-ltr%_size-32%button %buttonIcon': 'left:0;',
        '%_direction-rtl%_size-32%button %buttonIcon': 'right:0;',
        '%_type-like-clicked%_size-32%button %buttonIcon':
            '-webkit-animation-name:heartbeat;animation-name:heartbeat;-webkit-animation-duration:0.5s;animation-duration:0.5s;',
        '%_size-32%button %buttonTextWrapper': 'font-size:11.2px;line-height:32px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#fff;',
        '%_direction-ltr%_size-32%button %buttonTextWrapper %buttonLabel': 'padding-right:11.2px;',
        '%_direction-rtl%_size-32%button %buttonTextWrapper %buttonLabel': 'padding-left:11.2px;',
        '%_label-empty%_size-32%button %buttonTextWrapper %buttonLabel': 'display:none;',
        '%_type-like%_size-32%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-like-clicked%_size-32%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-unlike%_size-32%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_direction-ltr%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'padding-right:11.2px;margin-left:1px;',
        '%_direction-rtl%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'padding-left:11.2px;margin-right:1px;',
        '%_extraInfo-empty%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'display:none;',
        '%_type-like%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-like-clicked%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-unlike%_size-32%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_size-36%button': 'border-radius:4px;height:36px;position:relative;',
        '%_direction-ltr%_size-36%button': 'padding-left:36px;',
        '%_direction-rtl%_size-36%button': 'padding-right:36px;',
        '%_border-radius-editable%_size-36%button': 'border-radius:inherit;',
        '%_size-36%button:hover': 'cursor:pointer;',
        '%_size-36%button %buttonIcon': 'height:100%;position:absolute;width:36px;',
        '%_direction-ltr%_size-36%button %buttonIcon': 'left:0;',
        '%_direction-rtl%_size-36%button %buttonIcon': 'right:0;',
        '%_type-like-clicked%_size-36%button %buttonIcon':
            '-webkit-animation-name:heartbeat;animation-name:heartbeat;-webkit-animation-duration:0.5s;animation-duration:0.5s;',
        '%_size-36%button %buttonTextWrapper': 'font-size:12.6px;line-height:36px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#fff;',
        '%_direction-ltr%_size-36%button %buttonTextWrapper %buttonLabel': 'padding-right:12.6px;',
        '%_direction-rtl%_size-36%button %buttonTextWrapper %buttonLabel': 'padding-left:12.6px;',
        '%_label-empty%_size-36%button %buttonTextWrapper %buttonLabel': 'display:none;',
        '%_type-like%_size-36%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-like-clicked%_size-36%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-unlike%_size-36%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_direction-ltr%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'padding-right:12.6px;margin-left:1px;',
        '%_direction-rtl%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'padding-left:12.6px;margin-right:1px;',
        '%_extraInfo-empty%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'display:none;',
        '%_type-like%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-like-clicked%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-unlike%_size-36%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_size-40%button': 'border-radius:4px;height:40px;position:relative;',
        '%_direction-ltr%_size-40%button': 'padding-left:40px;',
        '%_direction-rtl%_size-40%button': 'padding-right:40px;',
        '%_border-radius-editable%_size-40%button': 'border-radius:inherit;',
        '%_size-40%button:hover': 'cursor:pointer;',
        '%_size-40%button %buttonIcon': 'height:100%;position:absolute;width:40px;',
        '%_direction-ltr%_size-40%button %buttonIcon': 'left:0;',
        '%_direction-rtl%_size-40%button %buttonIcon': 'right:0;',
        '%_type-like-clicked%_size-40%button %buttonIcon':
            '-webkit-animation-name:heartbeat;animation-name:heartbeat;-webkit-animation-duration:0.5s;animation-duration:0.5s;',
        '%_size-40%button %buttonTextWrapper': 'font-size:14px;line-height:40px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#fff;',
        '%_direction-ltr%_size-40%button %buttonTextWrapper %buttonLabel': 'padding-right:14px;',
        '%_direction-rtl%_size-40%button %buttonTextWrapper %buttonLabel': 'padding-left:14px;',
        '%_label-empty%_size-40%button %buttonTextWrapper %buttonLabel': 'display:none;',
        '%_type-like%_size-40%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-like-clicked%_size-40%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_type-unlike%_size-40%button %buttonTextWrapper %buttonLabel': 'color:#000000;',
        '%_direction-ltr%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'padding-right:14px;margin-left:1px;',
        '%_direction-rtl%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'padding-left:14px;margin-right:1px;',
        '%_extraInfo-empty%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'display:none;',
        '%_type-like%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-like-clicked%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%_type-unlike%_size-40%button %buttonTextWrapper %buttonExtraInfo': 'color:#000000;',
        '%button%_type-facebook': 'background-color:#395799;',
        '%button%_type-facebook:hover:not(:active)': 'background-color:#405fa2;',
        '%button%_type-google-plus': 'background-color:#e33800;',
        '%button%_type-google-plus:hover:not(:active)': 'background-color:#ed3e05;',
        '%button%_type-linkedin': 'background-color:#007fb1;',
        '%button%_type-linkedin:hover:not(:active)': 'background-color:#048abf;',
        '%button%_type-pinterest': 'background-color:#ce1e1f;',
        '%button%_type-pinterest:hover:not(:active)': 'background-color:#d92526;',
        '%button%_type-twitter': 'background-color:#42c8f6;',
        '%button%_type-twitter:hover:not(:active)': 'background-color:#59cef6;',
        '%button%_type-whatsapp': 'background-color:#25d366;',
        '%button%_type-whatsapp:hover:not(:active)': 'background-color:#1fdf67;',
        '%button%_type-like': 'background-color:#FFFFFF;',
        '%button%_type-like:hover:not(:active)': 'background-color:#FFFFFF;',
        '%button%_type-like-clicked': 'background-color:#FFFFFF;',
        '%button%_type-like-clicked:hover:not(:active)': 'background-color:#FFFFFF;',
        '%button%_type-unlike': 'background-color:#FFFFFF;',
        '%button%_type-unlike:hover:not(:active)': 'background-color:#FFFFFF;',
        '@-webkit-keyframes %heartbeat':
            '% from{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}50%{-webkit-transform:scale(1.1);transform:scale(1.1);}% to{-webkit-transform:scale(1);transform:scale(1);}',
        '@keyframes %heartbeat':
            '% from{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}50%{-webkit-transform:scale(1.1);transform:scale(1.1);}% to{-webkit-transform:scale(1);transform:scale(1);}'
    }
}
skins['wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridNoDetail'] = {
    react: [
        ['h3', 'title', ['_hid'], {}],
        ['p', 'description', ['_hid'], {}],
        ['a', 'link', ['_hid'], {}],
        ['div', 'autoplay', [], {}],
        ['div', 'itemsContainer', [], {}],
        [
            'ul',
            null,
            [],
            {},
            ['li', 'buttonPrev', [], {}, ['span', null, [], {}]],
            ['li', 'counter', [], {}],
            ['li', 'buttonNext', [], {}, ['span', null, [], {}]]
        ],
        ['div', 'rolloverHolder', [], {}]
    ],
    params: {
        bg: 'BG_COLOR_ALPHA',
        itemBg: 'BG_COLOR_ALPHA',
        rd: 'BORDER_RADIUS',
        shd: 'BOX_SHADOW',
        itemBrc: 'BORDER_COLOR_ALPHA',
        itemBrw: 'BORDER_SIZE',
        bgh: 'BG_COLOR_ALPHA',
        paging: 'TEXT_COLOR'
    },
    paramsDefaults: {
        bg: 'color_15',
        itemBg: 'color_17',
        rd: '0',
        shd: '1px 1px 1px rgba(0, 0, 0, 0.15)',
        itemBrc: 'color_17',
        itemBrw: '0',
        bgh: 'color_17',
        paging: 'color_15'
    },
    css: {
        '%_hid': 'display:none;',
        '%itemsContainer':
            'box-sizing:border-box !important;background-color:[bg];overflow:hidden;height:calc(100% - 40px) !important;cursor:pointer !important;',
        '%itemsContainer %_galleryDisplayer': 'box-sizing:border-box !important;background-color:[itemBg];[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%itemsContainer %_galleryDisplayer:hover': 'background-color:[bgh];',
        '%rolloverHolder': 'background-color:[bgh];box-sizing:border-box !important;[rd]  [shd]  border:[itemBrc] solid [itemBrw];',
        '%_multiline span': 'white-space:normal;',
        '%buttonPrev': 'cursor:pointer !important;',
        '%buttonPrev > span:before': "content:'◄';",
        '%buttonNext > span:before': "content:'►';",
        '%buttonNext': 'cursor:pointer !important;',
        '% ul': 'position:absolute;bottom:10px;left:0;right:0;text-align:center;color:[paging] !important;height:26px;',
        '% li': 'padding:0 5px;display:inline !important;font-size:14px;color:[paging] !important;'
    }
}
