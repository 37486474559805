import $ from 'zepto'
import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import layout from '../util/layout'

const {touchMediaZoomUtils} = warmupUtils
const ITEM_OVERLAP = 0.2

function measureTouchMediaZoomItem(id, measureMap, nodesMap) {
    const nodeRef = $(nodesMap[id])
    if (!nodeRef.data('firstrender')) {
        return
    }

    measureMap.custom[id] = {imageIds: []}

    const imageElements = _.toArray(nodeRef.find('img'))
    _.forEach(imageElements, function (imgElement, ind) {
        const key = `${id}_image${ind}`
        nodesMap[key] = imgElement
        measureMap.custom[id].imageIds.push(key)
        measureMap.custom[key] = {
            imageData: $(imgElement).data('imgdata'),
            scale: $(imgElement).data('imgscale')
        }
    })

    const itemPrefix = nodeRef.attr('id')
    nodesMap[`${id}imageContainer`] = nodeRef.find(`#${itemPrefix}imageContainer`).get(0)
    nodesMap[`${id}info`] = nodeRef.find(`#${itemPrefix}info`).get(0)
}

function patchTouchMediaZoomItem(id, patchers, measureMap, structureInfo, siteData) {
    if (!measureMap.custom[id]) {
        return
    }

    _.forEach(measureMap.custom[id].imageIds, function (imgId) {
        const clientData = {
            pixelAspectRatio: siteData.mobile.getDevicePixelRatio(),
            browser: siteData.browser,
            staticMediaUrl: siteData.getStaticMediaUrl(),
            screenSize: {
                width: measureMap.width.screen,
                height: measureMap.height.screen
            }
        }

        const measures = measureMap.custom[imgId]
        const imageApiData = touchMediaZoomUtils.getImageApiData(measures.imageData, clientData, measures.scale)
        patchers.css(imgId, imageApiData.css.img)
        patchers.attr(imgId, {src: imageApiData.uri})
    })

    patchers.css(id, {
        width: (1 + 2 * ITEM_OVERLAP) * measureMap.width.screen
    })

    patchers.css(`${id}imageContainer`, {
        width: measureMap.width.screen
    })

    patchers.css(`${id}info`, {
        width: measureMap.width.screen
    })
}

function patchTouchMediaZoom(id, patchers, measureMap, structureInfo, siteData) {
    if (siteData.isFirstRenderAfterSSR() && siteData.isMobileDevice()) {
        const stageData = touchMediaZoomUtils.createStageData(ITEM_OVERLAP)
        const transformString = touchMediaZoomUtils.generateTransformString(stageData.centerPart_begin, stageData.fullWidth)

        patchers.css(`${id}swipeStage`, {
            transform: transformString,
            '-webkit-transform': transformString
        })
    }

    _.forEach(['stage_LEFT', 'stage_CENTER', 'stage_RIGHT'], function (itemId) {
        patchTouchMediaZoomItem(id + itemId, patchers, measureMap, structureInfo, siteData)
    })
}

layout.registerCustomMeasure('wysiwyg.viewer.components.TouchMediaZoomItem', measureTouchMediaZoomItem)
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.TouchMediaZoom', [
    {pathArray: ['stage_LEFT'], type: 'wysiwyg.viewer.components.TouchMediaZoomItem'},
    {pathArray: ['stage_CENTER'], type: 'wysiwyg.viewer.components.TouchMediaZoomItem'},
    {pathArray: ['stage_RIGHT'], type: 'wysiwyg.viewer.components.TouchMediaZoomItem'},
    {pathArray: ['swipeStage'], type: 'wysiwyg.viewer.components.TouchMediaZoomItem'}
])
layout.registerPatcher('wysiwyg.viewer.components.TouchMediaZoom', patchTouchMediaZoom)
