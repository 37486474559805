import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import ecomLogger from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomLogger'

const {logicFactory} = wixapps

/**
 * @class ecom.AddToCartButtonLogic
 * @param partApi
 * @constructor
 */
function AddToCartButtonLogic(partApi) {
    this.partApi = partApi
}

AddToCartButtonLogic.prototype = {
    'add-product-to-cart'() {
        const productId = this.partApi.getPartData().appLogicParams.productId.value

        this.partApi.ecomReportEvent(ecomLogger.events.ADD_TO_CART_BTN_ADD_PRODUCT_TO_CART, {c1: productId})
        const options = null
        const successCallback = null
        const failCallback = createAddProductOnFailCallback(this.partApi, {code: 2026})
        this.partApi.addProduct(productId, options, successCallback, failCallback)
    }
}

function createAddProductOnFailCallback(partApi, errorParams) {
    return function (err) {
        partApi.ecomShowMessage(err || errorParams)
    }
}

logicFactory.register('c614fb79-dbec-4ac7-b9b0-419669fadecc', AddToCartButtonLogic)
