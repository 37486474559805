import type {DSConfig} from '@wix/document-manager-core'
import type {BootstrapConfig} from '../types'
import _ from 'lodash'
import {default as configs, ConfigName} from './configs'

const DEFAULT_CONFIG_NAME: ConfigName = 'fullFunctionality'
const NO_ORIGIN = 'none'

const defaultOptions: DSConfig = {
    origin: NO_ORIGIN,
    modulesList: [],
    extensionsList: [],
    shouldRender: true,
    isReadOnly: false,
    noUndo: false,
    disableSave: false,
    disableAutoSave: false,
    disableCSaveValidationOnInitialization: false,
    closeSectionGaps: false,
    runStylesGC: false,
    disableMobileConversion: false,
    selectiveCompsDS: false,
    supportMobile: true,
    initialSeed: false,
    localServerPort: undefined,
    disableCommonConfig: false,
    schemaDevMode: false,
    autosaveRestore: 'true',
    dontCollectFixerVersionData: false
}

const optionalParams = ['signatureSeed', 'isDebugMode', 'undoRedoConfig', 'untilTransactionId', 'isReadOnly']

const getDsConfig = (bootstrapConfig: BootstrapConfig, overrides: Partial<DSConfig> = {}): DSConfig => {
    const isQueryConfigNameValid = bootstrapConfig.configName && !!configs[bootstrapConfig.configName]
    const configName = isQueryConfigNameValid ? bootstrapConfig.configName : DEFAULT_CONFIG_NAME

    if (bootstrapConfig.configName && !isQueryConfigNameValid) {
        console.warn(`Config name provided by query parameters is invalid, fallback on "${DEFAULT_CONFIG_NAME}"`)
    }

    const configOptions = _(bootstrapConfig)
        .pick([...optionalParams, ..._.keys(defaultOptions)])
        .assign({
            origin: bootstrapConfig.dsOrigin
        })
        .defaults(configs[configName!], defaultOptions)
        .assign(overrides)
        .value()

    if (!configOptions.origin || configOptions.origin === NO_ORIGIN) {
        throw new Error('You must define dsOrigin parameter in order to use the documentServices - please speak to html-server team for a key')
    }

    if (bootstrapConfig.firstSaveExtraPayload) {
        configOptions.firstSaveExtraPayload = JSON.parse(bootstrapConfig.firstSaveExtraPayload)
    }

    if (typeof localStorage !== 'undefined' && localStorage.getItem('dm-debug')) {
        configOptions.isDebugMode = true
    }

    return {
        configName,
        ...configOptions
    }
}

export {getDsConfig}
