import {DAL, DocumentManager, DSConfig, Null, pointerUtils, SnapshotDal} from '@wix/document-manager-core'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import {constants, extensions} from '@wix/document-manager-extensions'
import type {FTDExtApi} from '@wix/document-manager-extensions/src/extensions/fullToDisplay'
import type {Change, SnapshotExtApi} from '@wix/document-manager-extensions/src/extensions/snapshots'
import {constants as hostConstants, initialize} from '@wix/document-manager-host-common'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {transformGet, transformRemove, transformSet} from './dalTransformer'
import events from './events.json'
import {createPathMapper} from './services/pathMapper'

const {VIEW_MODES} = constants
const {getSiteDataImmutable: getSiteDataImmutableFromSnapshot} = initialize.siteDataImmutableFromSnapshot
const {DATA_TYPES} = hostConstants
const {getRepeatedItemPointerIfNeeded, getPointer, getInnerPointer} = pointerUtils
const {isStructurePointer} = extensions.structure
const PAGE_DATA_TYPES = _.values({...DATA_TYPES, ...VIEW_MODES})

const shouldReportMissingPath = (path: string[]) => !path.includes('wixCode') && !path.includes('pagesData')

function getResolvers(documentManager: DocumentManager) {
    const {dal, extensionAPI} = documentManager
    return {
        getterResolver: (isFull: boolean) => (isFull ? dal.get : (extensionAPI as FTDExtApi).displayed.getDisplayedValue),
        setterResolver: (isFull: boolean) => (isFull ? dal.set : (extensionAPI as FTDExtApi).displayed.setDisplayedValue),
        removerResolver: () => dal.remove
    }
}

const updateArray = (arr: any, itemToInsert: any, index: number) => {
    if (_.isNumber(index)) {
        if (index > arr.size || index < 0) {
            throw new Error('Index out of bound')
        }
        return arr.splice(index, 0, itemToInsert)
    }
    return arr.push(itemToInsert)
}

export interface DAL2 extends DAL {
    getNoClone(pointer: Pointer): any
    getCurrentSnapshot(): any
    removeLastSnapshot(): any
    duplicateLastSnapshot(): any
    getChangedPagesSinceLastSnapshot(tag: string): string[]
    wasPointerValueChangedSinceLastSnapshot(tag: string, pointer: Pointer): boolean
    isPathExist(): any
    setByPath(path: any, value: any): any
    removeByPathInHostModel(path: string[]): any
    merge(): void
    push(pointerToArray: Pointer, item: any, pointerToPush: Pointer, index: number): void
    getKeys(): void
    isExist(): void
    hasSetter(pointer: Pointer): boolean
}

export interface FullJsonDAL {}

export interface DisplayedJsonDal {}

export interface GSDAL extends DAL2 {
    full: DAL2
    displayedJsonDal: DisplayedJsonDal
    fullJsonDal: FullJsonDAL
}

/**
 * The adapter DAL is there for backward compatibility with the way DS works today, that does not declare the difference
 * between the document and viewer. Over time, DS implementations will declare that directly, and not need this DAL
 *
 * The structure of this DAL is also served for compliance with the Santa structure. As long as the implementations
 * need to work against both Santa and Bolt, we'll use this implementation to create a uniform API, but the underlining
 * dals can start shifting into more appropriate APIs
 *
 * @param {DocumentManager} documentManager
 * @param {ViewerManager} viewerManager
 * @param {{origin}} o
 * @returns {GSDAL}
 */
const createDal = (documentManager: DocumentManager, viewerManager: ViewerManager, {origin}: DSConfig): GSDAL => {
    const {getterResolver, setterResolver, removerResolver} = getResolvers(documentManager)
    const {extensionAPI} = documentManager
    const {snapshots} = extensionAPI as SnapshotExtApi

    const snapshotToSiteDataImmutable = (snapshotDal: Null<SnapshotDal>) => {
        if (!snapshotDal) {
            return null
        }
        return getSiteDataImmutableFromSnapshot(snapshotDal, origin)
    }

    const {getPointerByPath, getImmutablePath} = createPathMapper(documentManager)

    const reportBI = (eventType: string, params: any) => {
        // if (extensionAPI.siteAPI.isDebugMode()) {
        //     console.warn('Missing path', params.methodName, params.path)
        // }
        const reportDefOptions = events[eventType]
        viewerManager.viewerSiteAPI.reportBI(reportDefOptions, params)
    }

    const getPointerByPathWithReporting = (path: string[], methodName: string): Pointer | undefined => {
        const pointer = getPointerByPath(path)
        if (!pointer && shouldReportMissingPath(path)) {
            reportBI('PATH_TO_POINTER', {methodName, path: path.toString()})
        }
        return pointer
    }

    const get = (isFull: boolean, pointer: Pointer): any => {
        const getter = getterResolver(isFull)
        const transformedPointer = transformGet(pointer, getter, documentManager.pointers)
        const valueFromGetter = getter(transformedPointer)

        if (isFull && _.isNil(valueFromGetter)) {
            if (isStructurePointer(pointer)) {
                const pointerToGet = getRepeatedItemPointerIfNeeded(pointer)
                return getter(pointerToGet)
            }
        }

        return valueFromGetter
    }

    const set = (isFull: boolean, pointer: Pointer, value: any) => {
        const getter = getterResolver(isFull)
        const setter = setterResolver(isFull)
        const {value: transformedValue, pointer: transformedPointer} = transformSet(pointer, value, getter, _.partial(set, isFull), documentManager.pointers)

        if (transformedPointer) {
            setter(transformedPointer, deepClone(transformedValue))
        }
    }

    const isDirty = (pointer: Pointer): boolean => documentManager.dal.isDirty(pointer)

    const merge = (isFull: boolean, pointer: Pointer, value: any) => {
        const currentValue = get(isFull, pointer)
        const newValue = _.merge(deepClone(currentValue), value)

        set(isFull, pointer, newValue)
    }

    const remove = (isFull: boolean, pointer: Pointer) => {
        const transformedPointer = transformRemove(pointer, isFull)

        if (!transformedPointer) {
            return
        }

        const remover = removerResolver()
        const value = get(true, transformedPointer)
        if (value?.parent) {
            const parentPointer = getPointer(value.parent, transformedPointer.type)
            if (get(true, parentPointer)) {
                // Defensive code for cases where the parent is missing, cases with mobile
                const parentChildrenPointer = getInnerPointer(parentPointer, ['components'])
                const oldChildren = get(true, parentChildrenPointer)
                const newChildren = _.difference(oldChildren, [transformedPointer.id]) //IMPORTANT! creates a shallow clone
                set(true, parentChildrenPointer, newChildren)
            }
        }
        remover(transformedPointer)
    }

    const getKeys = (pointer: Pointer) => {
        const object = get(true, pointer)
        return _.keys(object)
    }

    const push = (isFull: boolean, pointerToArray: Pointer, item: any, pointerToPush: Pointer, index: number) => {
        const pointer = getRepeatedItemPointerIfNeeded(pointerToArray)
        const value = deepClone(get(isFull, pointer)) //IMPORTANT: clone since we mutate in next step
        updateArray(value, item, index)
        set(isFull, pointer, value)
    }

    const commitTransaction = (committer?: string, skipValidations?: boolean) => {
        documentManager.dal.commitTransaction(committer, skipValidations)
    }
    const validatePendingCommit = (tags?: Record<string, any>) => {
        documentManager.dal.validatePendingCommit(tags)
    }

    const dropUncommittedTransaction = (e?: string) => {
        documentManager.dal.dropUncommittedTransaction(e)
    }

    const enableCommits = () => {
        documentManager.dal.enableCommits()
    }

    const disableCommits = () => {
        documentManager.dal.disableCommits()
    }

    const isExist = (isFull: boolean, pointer: Pointer | undefined) => !_.isNil(pointer && get(isFull, pointer))

    const transaction = (applyChangesFunc: () => void) => {
        try {
            applyChangesFunc()
        } catch (err) {
            console.error(`transaction crashed at document-services adapter: ${err}`)
        }
    }

    const setByPath = (path: string[], value: any) => {
        const pointer = getPointerByPathWithReporting(path, 'setByPath')
        return pointer ? set(true, pointer, value) : undefined
    }

    const isPathExist = (path: string[]): boolean => {
        const pointer = getPointerByPathWithReporting(path, 'isPathExist')
        return isExist(false, pointer)
    }

    const getByPath = (isFull: boolean, path: string[]): any => {
        const pointer = getPointerByPathWithReporting(path, 'getByPath')
        if (pointer) {
            return get(isFull, pointer)
        }
        // @todo decide if we need to throw instead @see {@link https://jira.wixpress.com/browse/DM-516}
        const dataFromSnapshot = snapshotToSiteDataImmutable(snapshots.getCurrentSnapshot()).getIn(path)

        if (dataFromSnapshot && _.isFunction(dataFromSnapshot.toJS)) {
            return dataFromSnapshot.toJS()
        }

        return dataFromSnapshot
    }

    const removeByPathInHostModel = (path: string[]): void => {
        const pointer = getPointerByPathWithReporting(path, 'removeByPathInHostModel')
        if (pointer) {
            remove(true, pointer)
        }
    }

    const getChangedPagesSinceLastSnapshot = (tag: string) => {
        const fromSnapshot = snapshots.getLastSnapshotByTagName(tag) ?? snapshots.getInitialSnapshot()
        const diff = snapshots.getChangesFromSnapshot?.(fromSnapshot)
        const pageIdSet: Set<string> = new Set<string>()
        for (const type of PAGE_DATA_TYPES) {
            const typeValues = diff?.[type]
            if (typeValues) {
                for (const id of Object.keys(typeValues)) {
                    let obj = typeValues[id]
                    const wasDeleted = obj === undefined
                    if (wasDeleted) {
                        obj = fromSnapshot.getValue({type, id})
                    }
                    const pageId = obj.metaData?.pageId
                    if (pageId) {
                        pageIdSet.add(pageId)
                    }
                }
            }
        }
        return [...pageIdSet.values()]
    }

    const wasPointerValueChangedSinceLastSnapshot = (tag: string, pointer: Pointer): boolean => snapshots.hasPointerValueChanged?.(tag, pointer) as boolean

    const duplicateLastSnapshot = (tag: string, changes: Change[]) => {
        const boltImmutableChanges: Change[] = _.map(changes, ({path, value}) => {
            const immutablePath = getImmutablePath(path)
            if (!immutablePath && shouldReportMissingPath(path)) {
                reportBI('PATH_TO_POINTER', {methodName: 'duplicateLastSnapshot', path: path.toString()})
            }
            return {pointer: getPointerByPath(path), value}
        })

        return snapshots.duplicateLastSnapshot(tag, boltImmutableChanges)
    }

    const commitAndGetCurrentSnapshot = () => snapshotToSiteDataImmutable(snapshots.getCurrentSnapshot())

    const displayedJsonDal = {
        get: (pointer: Pointer) => deepClone(get(false, pointer)),
        getNoClone: (pointer: Pointer) => get(false, pointer),
        set: _.partial(set, false),
        push: _.partial(push, false),
        isExist: _.partial(isExist, false),
        merge: _.partial(merge, false),
        remove: _.partial(remove, false),
        isDirty,
        getKeys,
        commitTransaction,
        validatePendingCommit,
        getByPath: (path: string[]) => deepClone(getByPath(false, path)),
        setByPath,
        takeSnapshot: (tag: string) => snapshots.takeSnapshot(tag),
        takeLastApprovedSnapshot: (tag: string) => snapshots.takeLastApprovedSnapshot(tag),
        getCurrentSnapshot: commitAndGetCurrentSnapshot,
        removeLastSnapshot: snapshots.removeLastSnapshot,
        duplicateLastSnapshot,
        getChangedPagesSinceLastSnapshot,
        wasPointerValueChangedSinceLastSnapshot,
        isPathExist,
        transaction,
        dropUncommittedTransaction,
        enableCommits,
        disableCommits
    }

    const fullJsonDal = {
        get: (pointer: Pointer) => deepClone(get(true, pointer)),
        getNoClone: (pointer: Pointer) => get(true, pointer),
        set: _.partial(set, true),
        push: _.partial(push, true),
        isExist: _.partial(isExist, true),
        merge: _.partial(merge, true),
        remove: _.partial(remove, true),
        isDirty,
        getKeys,
        setByPath,
        getByPath: (path: string[]) => deepClone(getByPath(true, path)),
        removeByPathInHostModel,
        immutable: {
            getCurrentSnapshot: commitAndGetCurrentSnapshot,
            getSnapshotByTagAndIndex: (tag: string, index: number) => snapshotToSiteDataImmutable(snapshots.getSnapshotByTagAndIndex(tag, index)),
            getLastSnapshotByTagName: (tag: string) => snapshotToSiteDataImmutable(snapshots.getLastSnapshotByTagName(tag)),
            getInitialSnapshot: () => snapshotToSiteDataImmutable(snapshots.getInitialSnapshot()),
            getByPath: (path: string) => commitAndGetCurrentSnapshot().getIn(path)
        },
        snapshot: {
            getLastSnapshotByTagName: (tag: string) => snapshots.getLastSnapshotByTagName(tag),
            getInitialSnapshot: () => snapshots.getInitialSnapshot(),
            getCurrentSnapshot: () => snapshots.getCurrentSnapshot()
        },
        isPathExistInHostModel: _.constant(true)
    }

    return {
        touch: documentManager.dal.touch,
        ...displayedJsonDal,
        displayedJsonDal,
        fullJsonDal,
        // @ts-ignore
        full: fullJsonDal
    }
}

export {createDal}
