define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/theme/theme',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/mobileUtilities/mobileUtilities',
    '@wix/santa-components',
    'documentServices/documentServicesDataFixer/fixers/utils/variantThemeUtils',
    'documentServices/variants/variantsUtils',
    'documentServices/constants/constants'
], function (
    _,
    dataModel,
    componentStylesAndSkinsAPI,
    themeApi,
    componentDetectorAPI,
    componentApi,
    mobileUtil,
    santaComponents,
    variantThemeUtils,
    variantsUtils,
    constants
) {
    'use strict'

    const NEW_TEXT_SKIN = 'wysiwyg.viewer.skins.WRichTextNewSkin'
    const NEW_TEXT_THEME_SKIN = 'wysiwyg.viewer.skins.WRichTextThemeSkin'
    const NEW_TEXT_STYLE = 'txtNew'
    const NEW_TEXT_THEME_STYLE = 'txtTheme'
    const OLD_TEXT_STYLE = 'txt1'
    const OLD_TEXT_SKIN = 'wysiwyg.viewer.skins.WRichTextSkin'
    const COMPONENT_TYPE = 'wysiwyg.viewer.components.WRichText'

    function fixComponentsIfNeeded(ps, componentsPtrs, wasStyleFixed, styleHadNoSkin) {
        const fontsGetter = generateFontGetter(ps)

        _(componentsPtrs)
            .filter(compPtr => componentApi.isExist(ps, compPtr))
            .forEach(compPtr => fixComponentDataAndStructureIfNeeded(compPtr, fontsGetter, ps, wasStyleFixed, styleHadNoSkin))
    }

    function generateFontGetter(ps) {
        const fonts = themeApi.fonts.getAll(ps)

        return function (font) {
            return fonts[font]
        }
    }

    function fixComponentData(compPtr, themeFontsGetter, ps) {
        if (compPtr) {
            const compData = componentApi.data.get(ps, compPtr)

            if (compData) {
                compData.text = santaComponents.utils.textComponentsUtils.applyTextStyleMigrationAdjustments(compData.text, themeFontsGetter)

                componentApi.data.update(ps, compPtr, compData)
            }
        }
    }
    function setComponentSkin(compPtr, skin, ps) {
        const compSkinPointer = ps.pointers.getInnerPointer(compPtr, 'skin')
        ps.dal.set(compSkinPointer, skin)
        mobileUtil.syncMobileAndDesktopSkin(ps, compPtr, skin)
    }

    function revertComponentToOldSkinAndStyle(compPtr, ps) {
        componentStylesAndSkinsAPI.style.connectToThemeStyle(ps, compPtr, OLD_TEXT_STYLE)
        setComponentSkin(compPtr, OLD_TEXT_SKIN, ps)
    }

    function fixComponentSkin(compPtr, ps) {
        setComponentSkin(compPtr, NEW_TEXT_SKIN, ps)
    }

    function getComponentSkin(ps, compPtr) {
        const skinPtr = ps.pointers.getInnerPointer(compPtr, 'skin')

        return ps.dal.get(skinPtr)
    }

    function fixMigrated(ps, compPtr, componentSkin, styleHadNoSkin, wasStyleFixed, themeFontsGetter) {
        if (styleHadNoSkin) {
            if (componentSkin === OLD_TEXT_SKIN) {
                fixComponentData(compPtr, themeFontsGetter, ps)
            }
        } else if (wasStyleFixed) {
            fixComponentData(compPtr, themeFontsGetter, ps)
        }

        if (componentSkin !== NEW_TEXT_SKIN) {
            fixComponentSkin(compPtr, ps)
        }
    }

    function fixWithVariants(ps, compPtr, compStyleId, compSkin, themeFontsGetter, wasStyleFixed, styleHadNoSkin) {
        const refArray = ps.dal.full.get(ps.pointers.data.getThemeItem(compStyleId))
        const pagePointer = ps.pointers.components.getPageOfComponent(compPtr)
        const pageId = pagePointer.id
        const variantsToFix = []
        const relations = dataModel.refArray.extractValuesWithoutHash(ps, refArray)
        let styleToFixInRefArray

        variantThemeUtils.applyToEachComponentStyleAndReference(ps, compPtr, style => {
            if (dataModel.refArray.isRefArray(ps, style)) {
                relations.forEach(relationId => {
                    if (relationId === NEW_TEXT_STYLE) {
                        fixMigrated(ps, compPtr, compSkin, styleHadNoSkin, wasStyleFixed, themeFontsGetter)
                    }

                    if (relationId === OLD_TEXT_STYLE) {
                        styleToFixInRefArray = relationId
                    }
                })
            }

            if (dataModel.variantRelation.isVariantRelation(ps, style)) {
                const variantStyleId = dataModel.variantRelation.extractTo(ps, style)

                if (variantStyleId === NEW_TEXT_STYLE) {
                    fixMigrated(ps, compPtr, compSkin, styleHadNoSkin, wasStyleFixed, themeFontsGetter)
                }

                if (variantStyleId === OLD_TEXT_STYLE) {
                    variantsToFix.push(style)
                }
            }
        })

        if (styleToFixInRefArray) {
            const newRelations = _.concat(_.without(relations, styleToFixInRefArray), NEW_TEXT_STYLE)
            const newRefArray = dataModel.refArray.update(ps, refArray, newRelations)

            ps.dal.full.set(ps.pointers.data.getThemeItem(refArray.id, pageId), newRefArray)
        }

        variantsToFix.forEach(variant => {
            const newVariant = {
                ...variant,
                to: `#${NEW_TEXT_STYLE}`
            }

            ps.dal.full.set(ps.pointer.data.getThemeItem(variant.id, pageId), newVariant)
        })

        if (compSkin === OLD_TEXT_SKIN) {
            fixComponentSkin(compPtr, ps)
            fixComponentData(compPtr, themeFontsGetter, ps)
        }
    }

    function fixComponentDataAndStructureIfNeeded(compPtr, themeFontsGetter, ps, wasStyleFixed, styleHadNoSkin) {
        const componentStyleId = componentStylesAndSkinsAPI.style.getId(ps, compPtr)
        const componentSkin = getComponentSkin(ps, compPtr)

        if (componentStyleId === NEW_TEXT_THEME_STYLE && componentSkin === NEW_TEXT_THEME_SKIN) {
            return
        }

        if (variantsUtils.shouldConsiderVariants(ps, compPtr, constants.DATA_TYPES.theme)) {
            fixWithVariants(ps, compPtr, componentStyleId, componentSkin, themeFontsGetter, wasStyleFixed, styleHadNoSkin)
            return
        }

        try {
            if (componentStyleId === NEW_TEXT_STYLE) {
                fixMigrated(ps, compPtr, componentSkin, styleHadNoSkin, wasStyleFixed, themeFontsGetter)
            } else {
                componentStylesAndSkinsAPI.style.connectToThemeStyle(ps, compPtr, NEW_TEXT_STYLE)

                if (componentSkin === OLD_TEXT_SKIN) {
                    fixComponentSkin(compPtr, ps)
                    fixComponentData(compPtr, themeFontsGetter, ps)
                }
            }
        } catch (e) {
            revertComponentToOldSkinAndStyle(compPtr, ps)
        }
    }

    function createOrFixTxtStyle(ps, existingTxtStyle) {
        let styleToSave

        if (existingTxtStyle) {
            existingTxtStyle.skin = NEW_TEXT_SKIN

            styleToSave = existingTxtStyle
        } else {
            componentStylesAndSkinsAPI.style.createSystemStyle(ps, NEW_TEXT_STYLE, COMPONENT_TYPE)
            const createTxtStyle = themeApi.styles.get(ps, NEW_TEXT_STYLE)

            if (createTxtStyle && createTxtStyle.metaData) {
                createTxtStyle.metaData.isPreset = true

                styleToSave = createTxtStyle
            }
        }

        themeApi.styles.update(ps, NEW_TEXT_STYLE, styleToSave)
    }

    function exec(ps) {
        const componentsPtrs = componentDetectorAPI.getComponentByType(ps, COMPONENT_TYPE)
        let wasStyleFixed = false
        let styleHadNoSkin = false
        const txtNewStyle = themeApi.styles.get(ps, NEW_TEXT_STYLE)

        if (txtNewStyle && txtNewStyle.skin === NEW_TEXT_THEME_SKIN) {
            txtNewStyle.skin = NEW_TEXT_SKIN
            themeApi.styles.update(ps, NEW_TEXT_STYLE, txtNewStyle)
        }

        if (!txtNewStyle || !txtNewStyle.skin || txtNewStyle.skin === OLD_TEXT_SKIN) {
            if (txtNewStyle) {
                wasStyleFixed = true

                if (!txtNewStyle.skin) {
                    styleHadNoSkin = true
                }
            } else {
                styleHadNoSkin = true
            }

            createOrFixTxtStyle(ps, txtNewStyle)
        }

        fixComponentsIfNeeded(ps, componentsPtrs, wasStyleFixed, styleHadNoSkin)
    }

    return {
        exec,
        name: 'textComponentDataFixer',
        version: 1
    }
})
