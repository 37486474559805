define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataIds',
    'documentServices/dataModel/dataModel',
    'documentServices/responsiveLayout/validations'
], function (_, constants, dataIds, dataModel, validations) {
    'use strict'

    const {DATA_TYPES, VARIANTS} = constants

    const createBreakpointsData = (ps, currentId, compPointer, rangeArray, pageId) => {
        validations.validateBreakpointsDataAdd(ps, compPointer)
        const id = currentId || dataIds.generateNewId(DATA_TYPES.variants)
        const breakpointPointerDataPointer = ps.pointers.data.getVariantsDataItem(id, pageId)
        const values = getBreakpointRangesArray(rangeArray)
        validations.validateNewBreakpointsIds(ps, values, pageId)
        return dataModel.createVariantData(ps, breakpointPointerDataPointer, VARIANTS.TYPES.BREAKPOINTS, {compPointer, values})
    }

    const getBreakpointRangesArray = rangeArray =>
        rangeArray.map(item => {
            _.assign(item, {metaData: {schemaVersion: '2.0'}})
            _.assign(item, {id: item.id || dataIds.generateNewId(DATA_TYPES.variants)})
            return item
        })

    return {
        createBreakpointsData
    }
})
