/**
 * Created by Talm on 14/07/2014.
 */
import _ from 'lodash'

function getStaggerValue() {
    return 0.1
}

const sortingFunctions = {
    getDefultAnimationValues(sourceNode, destinationNode, timingFunctionIndex, transName) {
        return {
            sourceNodesArrSorted: [sourceNode],
            destNodesArrSorted: [destinationNode],
            stagger: 0,
            timingFunctionIndex,
            transName,
            sporadicallyRandom: false
        }
    },
    getUpdatedTimingFunctionIndex(arr, timingFunctionIndex) {
        if (timingFunctionIndex > arr.length - 1) {
            timingFunctionIndex = 0
        }
        return timingFunctionIndex
    },
    getCrossFadePossibleTransitionByName(name) {
        switch (name) {
            case 'crossFadeHorizWave':
                return this.sortMatrixByRows
            case 'crossFadeVertWave':
                return this.sortMatrixByCols
            case 'seq_crossFade_Diagonal':
                return this.convertMatrixToDiagonal
        }
    },
    convertArrayToMatrix(arr, rowNum, colsNum) {
        let index = 0
        const matrix = new Array(rowNum)
        _.times(rowNum, function (row) {
            if (!matrix[row]) {
                matrix[row] = []
            }
            _.times(colsNum, function (col) {
                if (index < arr.length) {
                    matrix[row][col] = arr[index]
                    index++
                } else {
                    matrix[row][col] = -1
                }
            })
        })
        return matrix
    },
    sortMatrixByRows(arr) {
        return [arr]
    },

    sortMatrixByCols(arr, rowNum, colsNum) {
        const matrix = this.convertArrayToMatrix(arr, rowNum, colsNum)
        const sortedArr = []
        let index = 0
        _.times(colsNum, function (col) {
            _.times(rowNum, function (row) {
                if (matrix[row][col] !== -1) {
                    sortedArr[index] = matrix[row][col]
                    index++
                }
            })
        })
        return [sortedArr]
    },
    reverseMatrix(arr) {
        return arr.reverse()
    },

    convertMatrixToDiagonal(arr, rowNum, colsNum) {
        const matrix = this.convertArrayToMatrix(arr, rowNum, colsNum)
        const sortedArr = []
        let index = 0
        _.times(rowNum + colsNum, function (line) {
            const startCol = Math.max(0, line - rowNum)
            const count = Math.min(colsNum - startCol, line, rowNum)
            _.times(count, function (j) {
                const val = matrix[Math.min(rowNum, line) - j - 1][startCol + j]
                if (val !== -1) {
                    sortedArr[index] = val
                    index++
                }
            })
        })
        return [sortedArr]
    },

    convertMatrixToReverseDiagonal(arr, rowNum, colsNum) {
        const sortedArr = this.convertMatrixToDiagonal(arr, rowNum, colsNum)
        return this.reverseMatrix(sortedArr)
    },

    getMatrixByRows(arr, rowNum, colsNum) {
        let index = 0
        const matrix = new Array(rowNum)
        _.times(rowNum, function (row) {
            if (!matrix[row]) {
                matrix[row] = []
            }
            _.times(colsNum, function (col) {
                if (index < arr.length) {
                    matrix[row][col] = arr[index]
                    index++
                }
            })
        })
        return matrix
    },
    getMatrixByRowsReverse(arr, rowNum, colsNum) {
        const sortedArr = this.getMatrixByRows(arr, rowNum, colsNum)
        return this.reverseMatrix(sortedArr)
    },
    getMatrixByColsReverse(arr, rowNum, colsNum) {
        const sortedArr = this.getMatrixByCols(arr, rowNum, colsNum)
        return this.reverseMatrix(sortedArr)
    },
    getMatrixByCols(arr, rowNum, colsNum) {
        const matrix = this.getMatrixByRows(arr, rowNum, colsNum)
        const sortedMatrix = new Array(colsNum)
        _.times(colsNum, function (col) {
            if (!sortedMatrix[col]) {
                sortedMatrix[col] = []
            }
            _.times(rowNum, function (row) {
                if (matrix[row][col]) {
                    sortedMatrix[col][row] = matrix[row][col]
                }
            })
        })
        return sortedMatrix
    }
}
const matrixAnimationManipulation = {
    getSortedArrayAndStagger(transName, sourceNode, destinationNode, rowNum, colsNum, timingFunctionIndex) {
        switch (transName) {
            case 'none':
                return {
                    sourceNodesArrSorted: [sourceNode],
                    destNodesArrSorted: [destinationNode],
                    stagger: 0,
                    transName: 'NoTransition'
                }
            case 'seq_shrink_All':
            case 'seq_crossFade_All':
                const sortedArrFunctions = [
                    sortingFunctions.sortMatrixByRows,
                    sortingFunctions.sortMatrixByCols,
                    sortingFunctions.reverseMatrix,
                    sortingFunctions.convertMatrixToDiagonal,
                    sortingFunctions.convertMatrixToReverseDiagonal,
                    sortingFunctions.sortMatrixByRows
                ]
                timingFunctionIndex = sortingFunctions.getUpdatedTimingFunctionIndex(sortedArrFunctions, timingFunctionIndex)
                const selectedSortFunction = sortedArrFunctions[timingFunctionIndex]
                return {
                    sourceNodesArrSorted: selectedSortFunction.call(sortingFunctions, sourceNode, rowNum, colsNum),
                    destNodesArrSorted: selectedSortFunction.call(sortingFunctions, destinationNode, rowNum, colsNum),
                    stagger: timingFunctionIndex === sortedArrFunctions.length - 1 || sourceNode.length !== destinationNode.length ? 0 : 0.1,
                    timingFunctionIndex,
                    transName: transName === 'seq_shrink_All' ? 'Shrink' : 'CrossFade'
                }
            case 'swipe_horiz_All':
                const sortedArrFunctionsHorizontal = [sortingFunctions.getMatrixByRows, sortingFunctions.getMatrixByRowsReverse]
                timingFunctionIndex = sortingFunctions.getUpdatedTimingFunctionIndex(sortedArrFunctionsHorizontal, timingFunctionIndex)
                const selectedSortFunctionHorizontal = sortedArrFunctionsHorizontal[timingFunctionIndex]

                return {
                    sourceNodesArrSorted: selectedSortFunctionHorizontal.call(sortingFunctions, sourceNode, rowNum, colsNum),
                    destNodesArrSorted: selectedSortFunctionHorizontal.call(sortingFunctions, destinationNode, rowNum, colsNum),
                    stagger: getStaggerValue(),
                    timingFunctionIndex,
                    transName: 'SlideHorizontal',
                    sporadicallyRandom: rowNum > 1
                }
            case 'swipe_vert_All':
                const sortedArrFunctionsVertical = [sortingFunctions.getMatrixByCols, sortingFunctions.getMatrixByColsReverse]
                timingFunctionIndex = sortingFunctions.getUpdatedTimingFunctionIndex(sortedArrFunctionsVertical, timingFunctionIndex)
                const selectedSortFunctionVertical = sortedArrFunctionsVertical[timingFunctionIndex]
                return {
                    sourceNodesArrSorted: selectedSortFunctionVertical.call(sortingFunctions, sourceNode, rowNum, colsNum),
                    destNodesArrSorted: selectedSortFunctionVertical.call(sortingFunctions, destinationNode, rowNum, colsNum),
                    stagger: getStaggerValue(),
                    timingFunctionIndex,
                    transName: 'SlideVertical',
                    sporadicallyRandom: colsNum > 1
                }
            case 'seq_random':
                const transitionMapKeys = ['seq_shrink_All', 'seq_crossFade_All', 'swipe_horiz_All', 'swipe_vert_All']
                const rndAnimationName = transitionMapKeys[Math.floor(Math.random() * transitionMapKeys.length)]
                return this.getSortedArrayAndStagger(rndAnimationName, sourceNode, destinationNode, rowNum, colsNum, timingFunctionIndex)

            case 'horizSwipeAllAtOnce':
                return sortingFunctions.getDefultAnimationValues(sourceNode, destinationNode, timingFunctionIndex, 'SlideHorizontal')

            case 'vertSwipeAllAtOnce':
                return sortingFunctions.getDefultAnimationValues(sourceNode, destinationNode, timingFunctionIndex, 'SlideVertical')

            case 'crossFadeAllAtOnce':
                return sortingFunctions.getDefultAnimationValues(sourceNode, destinationNode, timingFunctionIndex, 'CrossFade')
            case 'crossFadeHorizWave':
            case 'crossFadeVertWave':
            case 'seq_crossFade_Diagonal':
                const sortFunction = sortingFunctions.getCrossFadePossibleTransitionByName(transName)
                return {
                    sourceNodesArrSorted: sortFunction.call(sortingFunctions, sourceNode, rowNum, colsNum),
                    destNodesArrSorted: sortFunction.call(sortingFunctions, destinationNode, rowNum, colsNum),
                    stagger: 0.1,
                    timingFunctionIndex,
                    transName: 'CrossFade'
                }
            case 'seq_swipe_alternate':
                const possibleSortedFunctions = [sortingFunctions.getMatrixByRows, sortingFunctions.getMatrixByCols]
                timingFunctionIndex = sortingFunctions.getUpdatedTimingFunctionIndex(possibleSortedFunctions, timingFunctionIndex)
                return {
                    sourceNodesArrSorted: sourceNode,
                    destNodesArrSorted: destinationNode,
                    stagger: getStaggerValue(),
                    timingFunctionIndex,
                    transName: timingFunctionIndex === 0 ? 'SlideHorizontal' : 'SlideVertical'
                }
        }
    }
}
export default matrixAnimationManipulation
