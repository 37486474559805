define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/componentsMetaData/componentsMetaData',
    'document-services-schemas',
    'documentServices/hooks/hooks',
    'documentServices/constants/constants',
    '@wix/document-manager-utils'
], function (_, dataModel, componentsMetaData, documentServicesSchemas, hooks, constants, {ReportableError}) {
    const SCHEMA_ORIGIN = 'data'

    function getUnhiddenChildren(ps, componentPointer) {
        const children = ps.pointers.components.getChildren(componentPointer)
        return _.reject(children, function (childPointer) {
            return _.get(dataModel.getMobileHintsItem(ps, childPointer), 'hidden', false)
        })
    }

    const beforeUpdateValidators = [
        {
            error: 'Cannot unhide desktop only component',
            isValid(ps, componentPointer, mobileHints, pageId) {
                const explicitlyHidden = _.get(mobileHints, 'hidden') !== false
                return explicitlyHidden || !componentsMetaData.public.getMobileConversionConfigByName(ps, ps.dal.get(componentPointer), 'desktopOnly', pageId)
            }
        },
        {
            error: 'Cannot hide not hiddenable component',
            isValid(ps, componentPointer, mobileHints) {
                return !_.get(mobileHints, 'hidden', false) || componentsMetaData.public.isHiddenable(ps, componentPointer)
            }
        }
    ]
    const finalStructureValidators = [
        {
            error: 'Unhidden container with children number less than allowed',
            isValid(ps, componentPointer, mobileHints) {
                const minimalChildrenNumber = componentsMetaData.public.getMinimalChildrenNumber(ps, componentPointer)
                if (minimalChildrenNumber === 0 || _.get(mobileHints, 'hidden', false)) {
                    return true
                }
                const unhiddenChildren = getUnhiddenChildren(ps, componentPointer)
                return _.size(unhiddenChildren) >= minimalChildrenNumber
            }
        },
        {
            error: 'Unhidden structuralItem component with hidden parent',
            isValid(ps, componentPointer, mobileHints, pageId) {
                const structuralItem = componentsMetaData.public.getMobileConversionConfigByName(ps, ps.dal.get(componentPointer), 'structuralItem', pageId)
                if (!structuralItem || _.get(mobileHints, 'hidden', false)) {
                    return true
                }
                const parentPointer = ps.pointers.components.getParent(componentPointer)
                return !_.get(dataModel.getMobileHintsItem(ps, parentPointer), 'hidden', false)
            }
        }
    ]

    function validateComponentMobileHints(ps, componentPointer, pageId) {
        const componentPageId =
            pageId ||
            _.get(ps.pointers.full.components.getPageOfComponent(componentPointer) || ps.pointers.components.getPageOfComponent(componentPointer), 'id', '')
        const mobileHintsItem = dataModel.getMobileHintsItem(ps, componentPointer)
        runAllValidators(ps, componentPointer, mobileHintsItem, componentPageId)
        validateMobileHintsBySchema(mobileHintsItem)
    }

    function validateMobileHintsBySchema(mobileHints) {
        if (mobileHints) {
            documentServicesSchemas.services.dataValidators.validateDataBySchema(mobileHints, SCHEMA_ORIGIN)
        }
    }

    function runBeforeUpdateValidators(ps, componentPointer, mobileHintsItem, pageId) {
        runValidators(ps, componentPointer, mobileHintsItem, pageId, beforeUpdateValidators)
    }

    function runAllValidators(ps, componentPointer, mobileHintsItem, pageId) {
        const validators = _.union(beforeUpdateValidators, finalStructureValidators)
        runValidators(ps, componentPointer, mobileHintsItem, pageId, validators)
    }

    function runValidators(ps, componentPointer, mobileHintsItem, pageId, validators) {
        _.forEach(validators, function (validator) {
            if (!validator.isValid(ps, componentPointer, mobileHintsItem, pageId)) {
                throw new ReportableError({
                    errorType: 'invalidMobileHints',
                    message: validator.error
                })
            }
        })
    }

    function validateMobileHintsOnPage(ps, pageId) {
        const validationErrors = []
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        if (!pagePointer) {
            return []
        }
        const allComponentsOnPage = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(pagePointer)
        _.forEach(allComponentsOnPage, function (componentPointer) {
            try {
                validateComponentMobileHints(ps, componentPointer, pageId)
            } catch (/** @type any */ error) {
                validationErrors.push({
                    componentId: componentPointer.id,
                    componentType: ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'componentType')),
                    error: error.message || error.toString()
                })
            }
        })
        return validationErrors
    }

    function validateMobileHintsOnPages(ps, pageIds) {
        const validationErrors = {}
        _.forEach(pageIds, function (pageId) {
            const pageValidationResult = validateMobileHintsOnPage(ps, pageId)
            if (!_.isEmpty(pageValidationResult)) {
                _.set(validationErrors, pageId, pageValidationResult)
            }
        })
        if (!_.isEmpty(validationErrors)) {
            const e = new ReportableError({
                errorType: 'invalidMobileHints',
                message: 'invalid mobileHints are found',
                extras: validationErrors
            })
            e.error = validationErrors
            throw e
        }
    }

    function initialize() {
        hooks.unregisterHooks([hooks.HOOKS.MOBILE_HINTS.UPDATE_BEFORE])
        hooks.registerHook(hooks.HOOKS.MOBILE_HINTS.UPDATE_BEFORE, runBeforeUpdateValidators)
    }

    const testAPI = {
        beforeUpdateValidators,
        finalStructureValidators
    }

    function validateAllMobileHints(ps) {
        const pageIds = _.map(ps.pointers.page.getNonDeletedPagesPointers(true), 'id')
        try {
            validateMobileHintsOnPages(ps, pageIds)
        } catch (error) {
            return error
        }

        return null
    }

    return {
        validateComponentMobileHints,
        validateMobileHintsBySchema,
        validateMobileHintsOnPages,
        validateAllMobileHints,
        initialize,
        testAPI
    }
})
