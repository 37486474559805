import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

const {compRegistrar} = componentsCore

/**
 * @class components.TPAPreloader
 * @extends {tpa.mixins.tpaCompBase}
 */
const tpaPreloader = {
    mixins: [componentsCore.mixins.skinBasedComp],
    getSkinProperties() {
        return {
            preloader: {
                className: 'circle-preloader'
            }
        }
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAPreloaderOverlay', tpaPreloader)
