define([], function () {
    'use strict'

    const STATE_BOX = 'wysiwyg.viewer.components.StateBox'

    return {
        mobileConversionConfig: {
            nestOverlayingSiblings: false,
            stackLayout: true,
            structuralItem: true
        },
        enforceContainerChildLimitsByWidth: true,
        enforceContainerChildLimitsByHeight: true,
        allowedParentTypes: [STATE_BOX]
    }
})
