import type {FetchFn} from '@wix/document-manager-extensions'
import type {ClientSpecMap, MetaSiteClientSpecEntry, RendererModel} from '@wix/document-services-types'

const META_SITE_APP_ID = '-666'
const META_SITE_CLIENT_SPEC_MAP_URL = '/_api/msm/v1/meta-site/editor-client-spec-map/'

const createMetaSiteClientSpecMapUrl = (msid: string): string => `${META_SITE_CLIENT_SPEC_MAP_URL}${msid}?https=true`

const fetchCsmResponse = async (fetchFn: FetchFn, msid: string): Promise<Response> => await fetchFn(createMetaSiteClientSpecMapUrl(msid))

export const FIVE_MINUTES = 1000 * 60 * 5

const getWixInstanceExpirationTime = (now: number, expirationTimestamp: string): number => {
    if (!expirationTimestamp) {
        return now
    }
    const expirationDate = new Date(expirationTimestamp)
    return expirationDate.getTime() - FIVE_MINUTES
}

const getTimeUntilWixInstanceExpiration = (now: number, expirationTimestamp: string) => getWixInstanceExpirationTime(now, expirationTimestamp) - now

export interface AuthorizationStatus {
    get instance(): string
    get metaSiteId(): string
    get expirationDate(): string
    isExpired(): boolean
}

export class AuthorizationInstance implements AuthorizationStatus {
    constructor(public readonly instance: string, public expirationDate: string, public readonly metaSiteId: string) {}

    isExpired(): boolean {
        const timeUntil = getTimeUntilWixInstanceExpiration(Date.now(), this.expirationDate)
        return timeUntil <= 0
    }
}

export class InvalidAuthorizationInstance implements AuthorizationStatus {
    constructor() {}

    isExpired(): boolean {
        return true
    }

    get metaSiteId(): string {
        throw new Error('InvalidAuthorizationInstance')
    }

    get expirationDate(): string {
        throw new Error('InvalidAuthorizationInstance')
    }

    get instance(): string {
        throw new Error('InvalidAuthorizationInstance')
    }
}

export class TestNonExpiringAuthorizationStatus implements AuthorizationStatus {
    constructor() {}

    isExpired(): boolean {
        return false
    }

    get metaSiteId(): string {
        return 'msid'
    }

    get expirationDate(): string {
        return 'expirationDate'
    }

    get instance(): string {
        return 'instance'
    }
}

export const extractMetaSiteInstance = (clientSpecMap: ClientSpecMap) => {
    const {metaSiteId, expirationDate, instance} = clientSpecMap[META_SITE_APP_ID] as MetaSiteClientSpecEntry
    return new AuthorizationInstance(instance, expirationDate!, metaSiteId)
}

export const extractMetaSite = (rendererModel: RendererModel) => {
    return extractMetaSiteInstance(rendererModel.clientSpecMap)
}

export const fetchClientSpecMap = async (fetchFn: FetchFn, msid: string) => {
    const response = await fetchCsmResponse(fetchFn, msid)
    const refreshedClientSpecMap = await response.json()
    return extractMetaSiteInstance(refreshedClientSpecMap)
}
