define(['lodash', '@wix/santa-core-utils', 'documentServices/dataModel/dataModel', 'document-services-schemas'], function (
    _,
    coreUtils,
    dataModel,
    documentServicesSchemas
) {
    'use strict'

    const {schemasService} = documentServicesSchemas.services
    /**
     * Validate the compProps of a component type for type correctness and schema correctness.
     * @param {String} componentType The type of the component the compProps are going to be used with
     * @param {object} compProps The compProps that are going to be set
     */
    function validateProperties(componentType, compProps) {
        const compDefinition = schemasService.getDefinition(componentType)
        if (!compDefinition) {
            throw new Error(`${componentType} has no component definition.`)
        }
        if (!compDefinition.propertyTypes && !compDefinition.propertyType && !compProps) {
            return
        }

        const types = compDefinition.propertyTypes ? compDefinition.propertyTypes : [compDefinition.propertyType || coreUtils.constants.BASE_PROPS_SCHEMA_TYPE]
        if (_.includes(types, '') || (_.isString(compProps) && _.includes(types, compProps))) {
            return
        }

        const msg = `${componentType} must get a compProps of one of the types [${types}]`
        if (!compProps) {
            throw new Error(msg)
        }

        if (!_.includes(types, compProps.type)) {
            throw new Error(`${msg} but got ${compProps.type}`)
        }
        documentServicesSchemas.services.dataValidators.validateDataBySchema(compProps, 'props')
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     */
    function validateCompProps(ps, compPointer) {
        const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
        const propertyItemPointer = dataModel.getPropertyItemPointer(ps, compPointer)
        const properties = propertyItemPointer && ps.dal.get(propertyItemPointer)
        validateProperties(componentType, properties)
    }

    return {
        validateProperties,
        validateCompProps
    }
})
