import PropTypes from 'prop-types'
import proxySantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxySantaTypesDefinitions'
import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import textProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/textProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'

function transformSkinProperties(refData) {
    refData[''] = _.merge({}, refData[''], {
        'data-proxy-name': 'MediaLabel'
    })
    return refData
}

function getMediaDataByQuery(data) {
    if (data) {
        if (data._type === 'wix:Image') {
            const imageQuality = this.props.viewProps.globalImageQuality
            return typesConverter.image(
                data,
                this.props.viewProps.resolveImageData,
                this.props.viewProps.serviceTopology,
                this.props.viewProps.packageName,
                imageQuality
            )
        }
        if (data._type === 'wix:Video') {
            return typesConverter.video(data)
        }
    }

    return null
}

/**
 * @class proxies.MediaLabel
 * @extends proxies.mixins.textProxy
 */
export default {
    mixins: [textProxy],

    propsTypes: {
        viewProps: PropTypes.shape({
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
            globalImageQuality: PropTypes.object,
            resolveImageData: PropTypes.func.isRequired,
            serviceTopology: PropTypes.object.isRequired,
            packageName: PropTypes.string.isRequired,
            registerReLayout: PropTypes.func.isRequired
        })
    },

    componentDidMount() {
        // re-render so that images don't appear as small and then grow (see BLOG-3354)
        this.forceUpdate()
    },

    renderProxy() {
        const data = this.proxyData
        const formattedText = this.createFormattedText(data)
        const componentType = 'wysiwyg.viewer.components.MediaRichText'
        const refDataMap = _.mapValues(data.refMap, getMediaDataByQuery.bind(this))
        const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap, refDataMap)
        const props = this.getRichTextChildCompProps(componentType, transformSkinProperties, compData)
        props.id = _.get(this.props, ['logic', 'currentPostId']) + props.id
        props.registerReLayout = this.props.viewProps.registerReLayout

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
