import _ from 'lodash'
import * as mobx from 'mobx'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const nonMobxStore = _.keyBy([
    'wixapps',
    'serviceTopology',
    'compBehavioursMap',
    'routers',
    'mapFromPageUriSeoToPageId',
    'displayedOnlyComponents',
    'wixCode',
    'orphanPermanentDataNodes',
    'committedMobilePages',
    'compFactoryRuntimeState',
    'ssr'
])

function safeGetValue(item, key) {
    if (_.isArray(item)) {
        if (key < 0 || key >= item.length) {
            return // don't access out of bound array items as they are untracked by mobx
        }
    } else if (item.WixMap || mobx.isObservableMap(item)) {
        return item.get(key)
    }
    return item[key]
}

function getByPath(obj, path) {
    let item = obj

    for (let i = 0; i < path.length; i++) {
        item = safeGetValue(item, path[i])
        if (_.isNil(item)) {
            break
        }
    }

    return item
}

function cloneDeep(obj) {
    return coreUtils.objectUtils.cloneDeep(obj, function (value) {
        if (mobx.isObservable(value)) {
            // @ts-ignore
            return mobx.toJS(value, false)
        } else if (_.has(value, ['WixMap'])) {
            return value.toJS()
        }
    })
}

function getKeys(object) {
    if (mobx.isObservableMap(object) || _.has(object, ['WixMap'])) {
        return object.keys()
    }

    if (object.isStructureNode) {
        return _.keysIn(object)
    }

    if (_.isPlainObject(object) || mobx.isObservableObject(object)) {
        return _.keys(object)
    }

    throw new Error('Can not get keys of object')
}

function mergeObservable(targetObj, newValue) {
    coreUtils.wSpy.log('mobx', ['mergeObservable', ...arguments])
    if (mobx.isObservableMap(targetObj) || _.has(targetObj, ['WixMap'])) {
        targetObj.merge(newValue)
    } else if (targetObj.isStructureNode || _.get(newValue, ['structure', 'isStructureNode'])) {
        _.assign(targetObj, newValue)
    } else if (mobx.isObservableObject(targetObj) || _.isPlainObject(targetObj)) {
        if (mobx.isObservableObject(newValue)) {
            newValue = _.clone(newValue) // extend observable can't be called with an observable object
        }

        mobx.extendObservable(targetObj, newValue)
    } else {
        throw new Error('Failed to merge - target object is not an observable object or map')
    }

    return false
}

function removeFromObj(obj, key) {
    coreUtils.wSpy.log('mobx', ['removeFromObj', ...arguments])
    if (mobx.isObservableMap(obj) || _.has(obj, ['WixMap'])) {
        obj.delete(key)
    } else if (mobx.isObservableObject(obj) && key in obj) {
        obj[key] = undefined
    } else {
        delete obj[key]
    }
}

function isObservableArray(arr) {
    return mobx.isObservableArray(arr)
}

function setByPath(jsonData, path, value) {
    coreUtils.wSpy.log('mobx', ['setByPath', path.join('.'), ...arguments])
    if (_.isEmpty(path)) {
        return
    }
    const parentPath = _.initial(path)
    const parentObject = path.length === 1 ? jsonData : getByPath(jsonData, parentPath)
    const key: string = _.last(path)

    if (!parentObject) {
        const obj = {}
        obj[key] = value
        return setByPath(jsonData, parentPath, obj)
    }

    if (value && value.isStructureNode) {
        parentObject.set(value.id, value)
    } else if (mobx.isObservableMap(parentObject) || _.has(parentObject, ['WixMap'])) {
        parentObject.set(key, value)
    } else if (mobx.isObservableArray(parentObject)) {
        parentObject[key] = value
        // @ts-ignore
    } else if (nonMobxStore[_.head(path)] || parentObject.isStructureNode) {
        parentObject[key] = value
    } else {
        const objToSet = {}
        objToSet[key] = value
        mobx.extendObservable(parentObject, objToSet)
    }
}

function touch(obj) {
    if (mobx.isObservableArray(obj)) {
        obj.forEach(_.noop)
    } else if (mobx.isObservableMap(obj)) {
        obj.forEach(_.noop)
    } else if (mobx.isObservableObject(obj)) {
        _.values(obj)
    } else if (mobx.isObservable(obj)) {
        obj.get()
    } else if (_.has(obj, ['WixMap'])) {
        obj.touch()
    }
}

function touchDeep(obj) {
    if (mobx.isObservableArray(obj)) {
        obj.forEach(touchDeep)
    } else if (mobx.isObservableMap(obj)) {
        obj.forEach(touchDeep)
    } else if (mobx.isObservableObject(obj)) {
        _.forEach(touchDeep)
    } else if (mobx.isObservable(obj)) {
        obj.get()
    } else if (_.has(obj, ['WixMap'])) {
        obj.touch()
    }
}

export default {
    setByPath,
    getByPath,
    getKeys,
    mergeObservable,
    isObservableArray,
    removeFromObj,
    cloneDeep,
    touch,
    touchDeep
}
