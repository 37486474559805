import core from './src/core'
export default core

export const {
    RuntimeDal,
    MobileDeviceAnalyzer,
    styleCollector,
    requireCompCode,
    behaviorHandlersFactory,
    behaviorsService,
    biEvents,
    DataAccessPointers,
    PointersCache,
    pointerGeneratorsRegistry,
    SimpleDisplayedJsonUpdater,
    storageAPI,
    aspects,
    wixCode,
    errors,
    structureInfoGetter,
    animationsService,
    viewerCompsService,
    externalComponentsServicesRegistry
} = core
