import _ from 'lodash'
import type {DocumentManager, Pointer} from '@wix/document-manager-core'
import type {CalcSizeType, UnitSizeType} from '@wix/document-services-types'

const unitSizes: (UnitSizeType | CalcSizeType)[] = ['px', 'percentage', 'fr', 'vh', 'vw', 'rem', 'vmin', 'vmax', 'aspectRatio', 'Calc']

const isMinMaxSize = (size: any) => size.type === 'MinMaxSize'

const isUnitSizeMissingValue = (unitSize: any) => !!unitSize && unitSizes.includes(unitSize.type) && _.isNil(unitSize.value)

const getBrokenMargins = (itemLayout: any): string[] => {
    const marginKeys = _.keys(itemLayout.margins)
    return _.filter(marginKeys, marginKey => isUnitSizeMissingValue(itemLayout.margins[marginKey]))
}

const isSingleLayout = (layout: any) => layout.type === 'SingleLayoutData'

const getItemLayout = (layout: any) => {
    if (isSingleLayout(layout)) {
        return layout.itemLayout
    }
    return layout
}

const getComponentLayout = (layout: any) => {
    if (isSingleLayout(layout)) {
        return layout.componentLayout
    }
    return layout
}

const getContainerLayout = (layout: any) => {
    if (isSingleLayout(layout)) {
        return layout.containerLayout
    }
    return layout
}

const cleanBrokenMarginsFromItemLayout = (documentManager: DocumentManager, layoutPointer: Pointer) => {
    const layout = documentManager.dal.get(layoutPointer)
    if (!layout) {
        return
    }
    const itemLayout = getItemLayout(layout)
    if (!itemLayout) {
        return
    }
    if (itemLayout.margins) {
        const brokenMargins: string[] = getBrokenMargins(itemLayout)
        if (brokenMargins.length) {
            const newMargins = _.omit(itemLayout.margins, brokenMargins)
            const newItemLayout = {
                ...itemLayout,
                margins: newMargins
            }
            const newLayout = isSingleLayout(layout)
                ? {
                      ...layout,
                      itemLayout: newItemLayout
                  }
                : newItemLayout
            documentManager.dal.set(layoutPointer, newLayout)
        }
    }
}

const cleanBrokenDimentionsFromComponentLayout = (documentManager: DocumentManager, layoutPointer: Pointer) => {
    const layout = documentManager.dal.get(layoutPointer)
    if (!layout) {
        return
    }
    const componentLayout = getComponentLayout(layout)
    if (!componentLayout) {
        return
    }

    const potentialCorruptedProperties = ['height', 'width', 'minHeight', 'maxHeight', 'minWidth', 'maxWidth']
    const newComponentLayout = _.omitBy(componentLayout, (value, key) => _.includes(potentialCorruptedProperties, key) && isUnitSizeMissingValue(value))
    const newLayout = isSingleLayout(layout)
        ? {
              ...layout,
              componentLayout: newComponentLayout
          }
        : newComponentLayout
    documentManager.dal.set(layoutPointer, newLayout)
}

const cleanBrokenRowsColumnsInContainerLayout = (documentManager: DocumentManager, layoutPointer: Pointer) => {
    const layout = documentManager.dal.get(layoutPointer)
    if (!layout) {
        return
    }
    const containerLayout = getContainerLayout(layout)
    if (!containerLayout || (!containerLayout.rows && !containerLayout.cols)) {
        return
    }

    const potentialCorruptedProperties = ['min', 'max']
    const isMinMaxSizeCorrupted = (rowOrCol: any) =>
        !!_(rowOrCol)
            .pick(potentialCorruptedProperties)
            .findKey(size => isUnitSizeMissingValue(size))
    const filterOutBrokenRowOrColumns = (rowsOrColumns: any[]) =>
        _.reject(rowsOrColumns, rowOrColumn => (isMinMaxSize(rowOrColumn) && isMinMaxSizeCorrupted(rowOrColumn)) || isUnitSizeMissingValue(rowOrColumn))
    const fixRowsOrColumns = (rowsOrColumns: any[]) => {
        if (_.isEmpty(rowsOrColumns)) {
            return rowsOrColumns
        }
        const newRowsOrColumns = filterOutBrokenRowOrColumns(rowsOrColumns)
        if (_.isEmpty(newRowsOrColumns)) {
            const firstRowOrColumn = rowsOrColumns[0]
            return [
                {
                    ...firstRowOrColumn,
                    ...(isMinMaxSize(firstRowOrColumn) && isUnitSizeMissingValue(firstRowOrColumn.min) && {min: {type: 'px', value: 0}}),
                    ...(isMinMaxSize(firstRowOrColumn) && isUnitSizeMissingValue(firstRowOrColumn.max) && {max: {type: 'maxContent'}}),
                    ...(!isMinMaxSize(firstRowOrColumn) && {type: firstRowOrColumn.type, value: 0})
                }
            ]
        }
        return newRowsOrColumns
    }

    const newContainerLayout = {
        ...containerLayout,
        rows: fixRowsOrColumns(containerLayout.rows),
        columns: fixRowsOrColumns(containerLayout.columns)
    }
    const newLayout = isSingleLayout(layout)
        ? {
              ...layout,
              containerLayout: newContainerLayout
          }
        : newContainerLayout
    documentManager.dal.set(layoutPointer, newLayout)
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const layoutTypesToIgnore = ['BreakpointRelation', 'RefArray', 'VariantRelation']
    const layoutPointers = documentManager.pointers.data.getLayoutItemsWithPredicate(
        (layoutItem: any) => !_.includes(layoutTypesToIgnore, layoutItem.type),
        pageId
    )
    _.forEach(layoutPointers, layoutPointer => {
        cleanBrokenMarginsFromItemLayout(documentManager, layoutPointer)
        cleanBrokenDimentionsFromComponentLayout(documentManager, layoutPointer)
        cleanBrokenRowsColumnsInContainerLayout(documentManager, layoutPointer)
    })
}

const name = 'removeBrokenUnitSizesFromLayout'
const version = 1

export {migratePage, name, version}
