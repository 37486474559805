define([
    'lodash',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/documentServicesDataFixer/fixers/hoverToStateReactionFixer'
], function (_, contextAdapter, constants, hoverToStateReactionFixer) {
    'use strict'

    function fix(ps) {
        const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS
        const fixers = [{fixer: hoverToStateReactionFixer, config: {fixMobileOnly: false, fedopsTag: 'hoverToStateReactionFixer'}}]

        contextAdapter.utils.fedopsLogger.interactionStarted(dsDataFixersFedOpsTags.ALL)
        _.forEach(fixers, function (fixerData) {
            const fedopsTag = _.get(fixerData, ['config', 'fedopsTag'])
            contextAdapter.utils.fedopsLogger.interactionStarted(fedopsTag)
            fixerData.fixer.exec(ps)
            contextAdapter.utils.fedopsLogger.interactionEnded(fedopsTag)
        })
        contextAdapter.utils.fedopsLogger.interactionEnded(dsDataFixersFedOpsTags.ALL)
    }

    return {
        fix
    }
})
