import layout from '../util/layout'

function wrapperWidthToColumnCount(wrapperWidth) {
    if (wrapperWidth < 240) {
        return 1
    }
    if (wrapperWidth < 365) {
        return 2
    }
    if (wrapperWidth < 490) {
        return 3
    }
    return 4
}

function customFormMixinMeasure(id, measureMap) {
    const wrapperHeight = measureMap.height[`${id}wrapper`]
    if (wrapperHeight) {
        measureMap.height[id] = wrapperHeight
    }
    /*measureMap.maxWidth[id] = 980;
         measureMap.maxHeight[id] = 1024;*/
}

function customMeasureContactForm(id, measureMap /*, nodesMap*/) {
    customFormMixinMeasure(id, measureMap /*, nodesMap*/)
    const contactFormMinWidth = 180
    measureMap.width[id] = Math.max(measureMap.width[id], contactFormMinWidth)
    measureMap.minWidth[id] = contactFormMinWidth
}

function customMeasureSubscribeForm(id, measureMap) {
    const wrapperHeight = measureMap.height[`${id}wrapper`]
    if (wrapperHeight) {
        measureMap.height[id] = wrapperHeight
    }
}

;['wysiwyg.viewer.components.ContactForm', 'wysiwyg.viewer.components.DynamicContactForm'].forEach(function (contactFormCompType) {
    layout.registerCustomMeasure(contactFormCompType, customMeasureContactForm)
    layout.registerRequestToMeasureChildren(contactFormCompType, [['wrapper']])
    layout.registerPatcher(contactFormCompType, function (id, patchers, measureMap) {
        patchers.attr(id, {
            'data-dcf-columns': wrapperWidthToColumnCount(measureMap.width[id])
        })
    })
})

layout.registerRequestToMeasureDom('wysiwyg.common.components.subscribeform.viewer.SubscribeForm')
layout.registerRequestToMeasureChildren('wysiwyg.common.components.subscribeform.viewer.SubscribeForm', [['wrapper']])
layout.registerCustomMeasure('wysiwyg.common.components.subscribeform.viewer.SubscribeForm', customMeasureSubscribeForm)
