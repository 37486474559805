import PropTypes from 'prop-types'
import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import templateBasedChildrenProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/templateBasedChildrenProxy'
import baseCompositeProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseCompositeProxy'

const {santaTypesDefinitions} = santaComponents

/**
 * @class proxies.list
 * @extends proxies.baseComposite
 */
export default {
    mixins: [baseCompositeProxy, templateBasedChildrenProxy],

    propsTypes: {
        viewDef: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        childAdditionalStyle: PropTypes.object,
        additionalStyle: PropTypes.object,
        viewProps: PropTypes.shape({
            registerReLayoutPending: santaTypesDefinitions.Layout.registerReLayoutPending.isRequired
        })
    },

    getDefaultProps() {
        return {}
    },

    getInitialState() {
        return {
            currentPage: 1
        }
    },

    prevPageClicked() {
        setCurrentPage.call(this, getPrevPage.call(this))
    },
    nextPageClicked() {
        setCurrentPage.call(this, getNextPage.call(this))
    },

    renderProxy() {
        const data = this.proxyData
        const childrenCount = data.length
        const templatesVars = this.getCompProp('templates') ? this.getCompProp('templates.vars') : {}

        let pageSize
        const isHidingPagination = this.getCompProp('hidePagination')
        if (!isHidingPagination) {
            pageSize = this.getCompProp('itemsPerPage')
        }
        // no pagination
        let first = 0
        let last = childrenCount
        let totalPages = 1
        const {currentPage} = this.state

        // yes pagination
        if (!_.isUndefined(pageSize)) {
            first = (currentPage - 1) * pageSize
            last = Math.min(first + pageSize, childrenCount)
            totalPages = Math.ceil(childrenCount / pageSize)
        }

        const pageData = data.slice(first, last)
        const pageDataLength = pageData.length
        const children = _.map(
            pageData,
            function (child, i) {
                let positionInParent = 'middle'
                if (i === 0 && pageDataLength > 1) {
                    positionInParent = 'first'
                } else if (i === pageDataLength - 1) {
                    positionInParent = 'last'
                }

                let positionInParentVars = {
                    indexInParent: i,
                    indexOneInParent: i + 1,
                    positionInParent,
                    isOddIndexInParent: i % 2 === 1,

                    // pagination vars
                    currentPage,
                    maxPage: totalPages,
                    hasNext: currentPage !== totalPages,
                    hasPrev: currentPage !== 1,
                    hidePagination: this.getCompProp('hidePagination')
                }
                positionInParentVars = _.merge({}, templatesVars, positionInParentVars)

                const paginationEventHandlers = {
                    prevPageClicked: this.prevPageClicked,
                    nextPageClicked: this.nextPageClicked,
                    numberedPageClicked: numberedPageClicked.bind(this)
                }

                const dataPath = this.getViewDefProp('data') || 'this'
                const childViewDef = this.getChildTemplateDefinition(i, pageDataLength)

                const extraContextProps = {
                    vars: {
                        proxy: positionInParentVars
                    },
                    events: paginationEventHandlers
                }
                const indexInData = first + i
                const childProps = this.getChildProxyProps(childViewDef, [dataPath, indexInData], extraContextProps)

                childProps['data-isfirst'] = i === 0
                childProps['data-iseven'] = i % 2 === 1
                childProps['data-islast'] = i === pageDataLength - 1

                childProps.proxyParentId = `${this.props.viewDef.id}_${indexInData}`

                return this.renderChildProxy(childViewDef, indexInData, this.props.childAdditionalStyle, childProps)
            }.bind(this)
        )

        const props = this.getChildCompProps('wysiwyg.viewer.components.VerticalRepeater')
        props.style = _.merge(props.style, this.props.additionalStyle)

        return componentsCore.compRegistrar.getCompClass('wysiwyg.viewer.components.VerticalRepeater', true)(props, children)
    }
}

function numberedPageClicked(event) {
    setCurrentPage.call(this, event.params.page)
}

function setCurrentPage(page) {
    this.props.viewProps.registerReLayout()
    this.setState({currentPage: page})
}

function getPrevPage() {
    return this.state.currentPage === 1 ? getTotal.call(this) : this.state.currentPage - 1
}

function getNextPage() {
    return this.state.currentPage === getTotal.call(this) ? 1 : this.state.currentPage + 1
}

function getTotal() {
    const childrenCount = this.proxyData.length
    const pageSize = this.getCompProp('itemsPerPage')
    return Math.ceil(childrenCount / pageSize)
}
