define([], function () {
    'use strict'

    const THEME_DATA_ID = 'THEME_DATA'

    const PROPERTY_TYPE = {
        TEXT_THEME: 'textTheme',
        COLOR: 'color',
        FONT: 'font'
    }

    return {
        THEME_DATA_ID,
        PROPERTY_TYPE
    }
})
