import * as _ from 'lodash'

import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const dataModel = viewerLibrary.get('documentServices/dataModel/dataModel')
    const mainMenu = viewerLibrary.get('documentServices/menu/mainMenu')
    const menuAPI = viewerLibrary.get('documentServices/menu/menuAPI')
    const basicMenuItemMethods = viewerLibrary.get('documentServices/menu/basicMenuItemMethods')
    const constants = viewerLibrary.get('documentServices/constants/constants')

    const {defineDataManipulationAction, defineGetter} = publicMethodUtils
    return {
        methods: {
            mainMenu: _.assign(
                {ITEM_TYPES: constants.MENU_ITEM_TYPES},
                {
                    updateLinkItem: defineDataManipulationAction(basicMenuItemMethods.updateLinkItem),
                    getItemType: defineGetter(basicMenuItemMethods.getItemType),
                    isItemRemovable: defineGetter(basicMenuItemMethods.isItemRemovable),
                    setItemLabel: defineDataManipulationAction(basicMenuItemMethods.setItemLabel),
                    setItemVisibility: defineDataManipulationAction(basicMenuItemMethods.setItemVisibility),
                    addHeaderItem: defineDataManipulationAction(mainMenu.addHeaderItem, {
                        getReturnValue: dataModel.generateNewDataItemId
                    }),
                    addLinkItem: defineDataManipulationAction(mainMenu.addLinkItem, {
                        getReturnValue: dataModel.generateNewDataItemId
                    }),
                    getMenu: defineGetter(mainMenu.getMenu),
                    moveItem: defineDataManipulationAction(mainMenu.moveItem),
                    removeItem: defineDataManipulationAction(mainMenu.deleteItem)
                }
            ),
            menu: {
                /**
                 * creates a new menu and adds it the menu collection in the site
                 * @member documentServices.menu
                 * @param {menuData} [menuData]
                 * @returns {String} newMenuId
                 */
                create: defineDataManipulationAction(menuAPI.create, {
                    getReturnValue: menuAPI.getMenuIdToCreate
                }),
                /**
                 * @member documentServices.menu
                 * @param {Pointer} menuCompPointer
                 * @param {String} menuId
                 */
                connect: defineDataManipulationAction(menuAPI.connect),
                /**
                 * @member documentServices.menu
                 * @param {String} menuId
                 * @param {menuData} menuData
                 */
                update: defineDataManipulationAction(menuAPI.update),
                /**
                 *
                 */
                remove: defineDataManipulationAction(menuAPI.remove),
                /**
                 * returns the menus collection
                 * @member documentServices.menu
                 * @returns {menuData[]}
                 */
                getAll: defineGetter(menuAPI.getAll),
                /**
                 * @member documentServices.menu
                 * @param {String} menuId
                 * @returns {menuData}
                 */
                getById: defineGetter(menuAPI.getById),
                /**
                 * @member documentServices.menu
                 * @param {String} type
                 * @returns {[menuData]}
                 */
                getByType: defineGetter(menuAPI.getByType),
                /**
                 * @member documentServices.menu
                 * @param {menuItem} menuItem
                 * @returns {String} itemId
                 */
                addItem: defineDataManipulationAction(menuAPI.addItem, {
                    getReturnValue: dataModel.generateNewDataItemId
                }),
                /**
                 * @member documentServices.menu
                 * @param {String} menuId
                 * @param {String} itemId
                 */
                removeItem: defineDataManipulationAction(menuAPI.removeItem),
                /**
                 * @member documentServices.menu
                 * @param {String} menuId
                 * @param {String} itemId
                 * @param {menuItem} menuItem
                 */
                updateItem: defineDataManipulationAction(menuAPI.updateItem),
                /**
                 * @member documentServices.menu
                 * @param {String} menuId
                 * @returns {Boolean}
                 */
                hasConnectedComps: defineGetter(menuAPI.hasConnectedComps)
            }
        }
    }
}
