import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const MAX_DATA_COUNT = 1000

const PubSubHub = function (hub?) {
    this.hub = hub || {}
}

PubSubHub.prototype = {
    persistData(appDefId, eventKey, compId, data) {
        const dataToPersist = {
            data,
            name: eventKey,
            origin: compId
        }
        const event = this._addEvent(appDefId, eventKey)

        if (event.data.length >= MAX_DATA_COUNT) {
            coreUtils.log.warn('You have exceeded the data limit, and some of the data will be lost! Please make sure you are not persisting unneeded data.')
            event.data.shift()
        }
        event.data.push(dataToPersist)
    },
    addEventListener(appDefId, eventKey, compId) {
        const event = this._addEvent(appDefId, eventKey)

        if (!_.includes(event.listeners, compId)) {
            event.listeners.push(compId)
        }
    },
    addWorkerEventListener(appDefId, eventKey, workerContextId) {
        const event = this._addEvent(appDefId, eventKey)

        if (!_.includes(event.workerListeners, workerContextId)) {
            event.workerListeners.push(workerContextId)
        }
    },
    removeEventListener(appDefId, eventKey, compId) {
        const event = this._getEvent(appDefId, eventKey)

        if (event) {
            event.listeners = _.without(event.listeners, compId)
        }
    },
    removeWorkerEventListener(appDefId, eventKey, compId) {
        const event = this._getEvent(appDefId, eventKey)

        if (event) {
            event.workerListeners = _.without(event.workerListeners, compId)
        }
    },
    getPersistedData(appDefId, eventKey) {
        const event = this._getEvent(appDefId, eventKey)

        return event && event.data
    },
    getEventListeners(appDefId, event) {
        const eventObj = this._getEvent(appDefId, event)

        return eventObj && eventObj.listeners
    },
    getWorkerEventListeners(appDefId, event) {
        const eventObj = this._getEvent(appDefId, event)

        return eventObj && eventObj.workerListeners
    },
    deleteCompListeners(appDefId, compId) {
        const self = this
        const events = this._getAppEvents(appDefId)
        _.forOwn(events, function (event, eventKey) {
            self.removeEventListener(appDefId, eventKey, compId)
        })
    },
    /**
     * @private
     */
    _addEvent(appDefId, event) {
        let events = this.hub[appDefId]

        if (!events) {
            events = this.hub[appDefId] = {}
        }

        let eventObj = events[event]

        if (!eventObj) {
            eventObj = events[event] = {
                data: [],
                listeners: [],
                workerListeners: []
            }
        }

        return eventObj
    },
    /**
     * @private
     */
    _getEvent(appDefId, event) {
        const events = this.hub[appDefId]

        if (events) {
            return events[event]
        }
    },
    /**
     * @private
     */
    _getAppEvents(appDefId) {
        return this.hub[appDefId]
    }
}

export default PubSubHub
