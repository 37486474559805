define(['lodash', 'layout'], function (_, layout) {
    'use strict'

    function getOverallBorders(el) {
        let dimensions = el.getBoundingClientRect()
        const children = _.toArray(el.children)
        const initialLeft = dimensions.left
        const initialTop = dimensions.top
        dimensions = {
            left: dimensions.right,
            right: dimensions.left,
            top: dimensions.bottom,
            bottom: dimensions.top
        }
        dimensions = getDimensions(children, dimensions)
        return {
            dx: Math.floor(dimensions.left) - initialLeft,
            dy: Math.floor(dimensions.top) - initialTop,
            width: Math.ceil(dimensions.right - dimensions.left),
            height: Math.ceil(dimensions.bottom - dimensions.top)
        }
    }

    function getDimensions(nodes, dims) {
        dims = dims || {
            left: -Number.MAX_VALUE,
            right: Number.MAX_VALUE,
            top: -Number.MAX_VALUE,
            bottom: Number.MAX_VALUE
        }
        const range = window.document.createRange()
        dims = _.reduce(
            nodes,
            function (dimensions, node) {
                let rect
                const textContent = getTextContent(node)
                if (textContent) {
                    range.selectNodeContents(node)
                    rect = range.getBoundingClientRect()
                    if (rect.width && rect.height) {
                        dimensions.left = Math.min(dimensions.left, rect.left)
                        dimensions.top = Math.min(dimensions.top, rect.top)
                        dimensions.right = Math.max(dimensions.right, rect.right)
                        dimensions.bottom = Math.max(dimensions.bottom, rect.bottom)
                    }
                } else {
                    dimensions = getDimensions(node.children, dimensions)
                }
                return dimensions
            },
            dims
        )
        return dims
    }
    function hasText(node) {
        return node.textContent.trim()
    }
    function getTextContent(node) {
        let i
        let child
        const {childNodes} = node
        let textContent = ''
        for (i = 0; i < childNodes.length; i++) {
            child = childNodes[i]
            if (child.nodeType === 3) {
                textContent += child.textContent.trim()
            }
        }
        return textContent
    }

    function measureTextRuntimeBorders(ps, id, measureMap, nodesMap) {
        const el = nodesMap[id]
        const overallBordersPointer = ps.pointers.general.getTextRuntimeOverallBorders()

        const isVerticalText = el.dataset.verticalText === 'true'
        const minSizeKey = isVerticalText ? 'minWidth' : 'minHeight'
        const oppositeMinSizeKey = isVerticalText ? 'minHeight' : 'minWidth'

        const offsetPositionKey = isVerticalText ? 'offsetLeft' : 'offsetTop'
        const offsetSizeKey = isVerticalText ? 'offsetWidth' : 'offsetHeight'

        const savedMinSize = _.isUndefined(el.dataset[minSizeKey]) ? 0 : parseInt(el.dataset[minSizeKey], 10)
        const actualTextMeasurer = el.querySelector('.measurer')

        measureMap[minSizeKey][id] = Math.max(savedMinSize, _.get(actualTextMeasurer, offsetPositionKey, el[offsetSizeKey]))
        measureMap[oppositeMinSizeKey][id] = 0

        if (hasText(el) && !ps.siteAPI.isMobileView()) {
            const borders = getOverallBorders(el)

            if (!ps.siteAPI.isMobileView()) {
                ps.dal.set(ps.pointers.getInnerPointer(overallBordersPointer, id), borders)
            }
        }
    }

    return {
        initialize(ps) {
            layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.WRichText', _.partial(measureTextRuntimeBorders, ps))
        }
    }
})
