import _ from 'lodash'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import santaComponents from '@wix/santa-components'
import textProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/textProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import proxySantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxySantaTypesDefinitions'
import textClipper from '@wix/santa-ds-libs/src/wixappsCore/src/util/textClipper'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

function lineHeightToFloat(lineHeight) {
    let ret = parseFloat(lineHeight)
    if (isNaN(ret) && _.includes(['normal', 'initial', 'inherit'], lineHeight)) {
        ret = 1.2
    }
    return ret
}

function getMinHeight(formattedText) {
    const minLines = this.getCompProp('minLines') || (this.getCompProp('singleLine') && 1)
    if (!minLines) {
        return 0
    }

    const fontClass = coreUtils.fontUtils.getFontClassName(formattedText)
    const fontStr = fontClass && this.props.viewProps.fontsMap[fontClass.split('_')[1]]
    const font = coreUtils.fontUtils.parseFontStr(fontStr)
    let lineHeight = 1.25 // this is the default value (=> line-height:normal)

    // can only be overridden by explicitly setting line-height on the comp
    if (this.getCompProp('line-height')) {
        lineHeight = lineHeightToFloat(this.getCompProp('line-height'))
    }

    return parseInt(font.size, 10) * lineHeight * minLines
}

/**
 * @class proxies.ClippedParagraph
 * @extends proxies.mixins.textProxy
 */
export default {
    mixins: [textProxy],
    propsTypes: {
        viewProps: PropTypes.shape({
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired,
            fontsMap: santaComponents.santaTypesDefinitions.Fonts.fontsMap.isRequired
        })
    },
    renderProxy() {
        const data = this.proxyData

        let formattedText = this.createFormattedText(data)

        const minHeight = getMinHeight.call(this, formattedText)

        const transformSkinProperties = function (refData) {
            refData[''].style = refData[''].style || {}
            refData[''].style.overflow = 'hidden'
            if (minHeight) {
                refData[''].style['min-height'] = minHeight
            }

            return _.set(refData, ['richTextContainer', 'style', 'height'], 'initial')
        }

        const componentType = 'wysiwyg.viewer.components.WRichText'

        const maxChars = this.getCompProp('max-chars') || 150
        const removeAnchors = this.getCompProp('remove-anchors') || false
        if (removeAnchors) {
            formattedText = formattedText.replace(/(<a[^>]*>)/g, '').replace(/(<\/a>)/g, '')
        }

        formattedText = textClipper.clipText(formattedText, maxChars)

        const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap)
        const props = this.getRichTextChildCompProps(componentType, transformSkinProperties, compData)

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
