/*eslint no-useless-concat:0*/
import _ from 'lodash'
import $ from 'zepto'
import imageLayout from './imageLayout'

function measure(id, measureMap, nodesMap, structureInfo, skinPartName?) {
    const newId = id + (skinPartName || '')
    measureMap.custom[newId] = _.merge(measureMap.custom[newId] || {}, {
        dataStates: nodesMap[newId].getAttribute('data-state') || '',
        isLoggedIn: isLoggedIn(nodesMap, newId),
        iconSize: $(nodesMap[newId]).data('iconSize')
    })
}

function patchMemberAvatar(id, patchers, measureMap, structureInfo, siteData) {
    const imageNodeId = `${id}avatar` + 'image'
    const {iconSize} = measureMap.custom[id]

    if (hasAvatar(structureInfo)) {
        const imageUri = getMemberImageUri(siteData)
        if (measureMap.width[imageNodeId]) {
            imageLayout.patchNodeImage(
                imageNodeId,
                patchers,
                measureMap,
                siteData,
                {uri: imageUri},
                {
                    width: iconSize,
                    height: iconSize
                }
            )
        }
    }
}

function patchIconItems(id, patchers, measureMap, structureInfo, siteData) {
    if (measureMap.custom[id].isLoggedIn) {
        const iconItems = getIconItems(structureInfo) || []
        const {iconSize} = measureMap.custom[id]

        _.forEach(iconItems, function (item, index) {
            const imageNodeId = `${id}button${index}icon` + 'image'
            const svgNodeId = `${id}button${index}icon` + 'svg'

            if (item.iconRef) {
                if (measureMap.width[imageNodeId]) {
                    imageLayout.patchNodeImage(imageNodeId, patchers, measureMap, siteData, item.iconRef, {
                        width: iconSize,
                        height: iconSize
                    })
                } else if (measureMap.width[svgNodeId]) {
                    // shapeLayout.layoutShape(svgNodeId, patchers, measureMap);
                }
            }
        })
    }
}

function patch(id, patchers, measureMap, structureInfo, siteData, skinPartName?) {
    const newId = id + (skinPartName || '')
    patchMemberAvatar(newId, patchers, measureMap, structureInfo, siteData)
    patchIconItems(newId, patchers, measureMap, structureInfo, siteData)
}

function getChildrenIdToMeasure(siteData, id, nodesMap, structureInfo, skinPartName?) {
    const res = []
    if (hasAvatar(structureInfo)) {
        if (isLoggedIn(nodesMap, id) && getMemberImageUri(siteData)) {
            res.push({pathArray: ['avatar', 'image'], type: 'core.components.Image'})
        }
    }

    if (isLoggedIn(nodesMap, id)) {
        const iconItems = getIconItems(structureInfo)
        _.forEach(iconItems, function (item, index) {
            if (item.iconRef) {
                if (item.iconRef.type === 'Image') {
                    res.push({pathArray: [`button${index}`, 'icon', 'image'], type: 'core.components.Image'})
                }
            }
        })
    }

    if (skinPartName) {
        _.forEach(res, function (child) {
            if (_.isArray(child.pathArray)) {
                child.pathArray.splice(0, 0, skinPartName)
            }
        })
    }

    return res
}

function isLoggedIn(nodesMap, id) {
    return nodesMap[id].getAttribute('data-is-logged-in') === 'true'
}

function getDataItemFromStructureInfo(structureInfo) {
    const dataItem = _.get(structureInfo, 'dataItem', null)
    if (dataItem) {
        if (dataItem.type === 'LoginSocialBar') {
            return dataItem
        }

        if (dataItem.loginSocialBarRef) {
            return dataItem.loginSocialBarRef
        }
    }
    return null
}

function getIconItems(structureInfo) {
    const dataItem = getDataItemFromStructureInfo(structureInfo)
    return _.get(dataItem, 'iconItemsRef.menuRef.items', null)
}

function hasAvatar(structureInfo) {
    const dataItem = getDataItemFromStructureInfo(structureInfo)
    return _.get(dataItem, 'loggedInMember') !== 'textOnly'
}

function getMemberImageUri(siteData) {
    return _.get(siteData.getSiteMemberDetails(), 'attributes.imageUrl', null)
}

export default {
    measure,
    patch,
    getChildrenIdToMeasure
}
