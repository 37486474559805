define([
    'lodash',
    'documentServices/appStudio/panelConfigs/panelConfig',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/appStudio/nameGenerator',
    'documentServices/page/page',
    'documentServices/dataModel/dataModel',
    'documentServices/appStudio/appBuilderPlatformApp',
    'documentServices/page/pageData',
    'documentServices/utils/utils',
    'documentServices/component/component',
    'documentServices/appStudio/widgetConfigs/widgetConfigs',
    'documentServices/appStudioWidgets/appStudioWidgets'
], function (
    _,
    panelConfig,
    appStudioDataModel,
    nameGenerator,
    page,
    dataModel,
    appBuilderPlatformApp,
    pageData,
    dsUtils,
    component,
    widgetConfigs,
    appStudioWidgets
) {
    'use strict'

    const NEW_PANEL_NAME_PREFIX = 'Panel'
    const PANEL_TYPE = 'PanelDescriptor'
    const PANEL_KIND = {
        SETTINGS: 'settings'
    }

    const PANEL_ERRORS = {
        EMPTY_NAME: 'EMPTY_NAME',
        NAME_ALREADY_EXISTS: 'NAME_ALREADY_EXISTS',
        INVALID_NAME: 'INVALID_NAME',
        INVALID_LENGTH: 'INVALID_LENGTH',
        ROOT_COMP_ID_DOES_NOT_EXIST: 'appStudio.panels: root comp id does not exists',
        INVALID_POINTER: 'appStudio.panels: provided panel pointer does not exists'
    }

    const PANEL_TITLE_ERRORS = {
        EMPTY_NAME: 'EMPTY_NAME',
        INVALID_LENGTH: 'INVALID_LENGTH'
    }

    const PANEL_HELP_ID_ERRORS = {
        NOT_GUID: 'NOT_GUID'
    }

    const PANEL_HEIGHT_ERRORS = {
        OUT_OF_RANGE: 'OUT_OF_RANGE'
    }

    const DEFAULT_PANEL_HEIGHT = 243

    const PANEL_PROPS_DUPLICATION_BLOCK_LIST = ['id', 'name', 'rootCompId', 'metaData', 'pageUrl']

    function generateNewPanelName(ps, prefix = NEW_PANEL_NAME_PREFIX, divider = ' ') {
        return nameGenerator.generateName(appStudioDataModel.getAllPanels(ps), `${prefix}${divider}`)
    }

    function validatePanelName(ps, name, widgetPointer) {
        if (_.isEmpty(name)) {
            return {success: false, errorCode: PANEL_ERRORS.EMPTY_NAME}
        }

        if (_.size(name) > 100) {
            return {success: false, errorCode: PANEL_ERRORS.INVALID_LENGTH}
        }

        if (_.includes(_.map(appStudioDataModel.getWidgetPanelsData(ps, widgetPointer), 'name'), name)) {
            return {success: false, errorCode: PANEL_ERRORS.NAME_ALREADY_EXISTS}
        }

        return {success: true}
    }

    function updateNameData(ps, dataPointer, name) {
        const panelData = appStudioDataModel.getData(ps, dataPointer)
        panelData.name = name
        appStudioDataModel.setData(ps, dataPointer, panelData)
    }

    function setPageTitle(ps, pageId, pageTitle) {
        const panelPageData = pageData.getPageData(ps, pageId)
        panelPageData.title = pageTitle
        pageData.setPageData(ps, pageId, panelPageData)
    }

    function syncPanelNameWithPage(ps, panelPointer, panelName) {
        const pageId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
        setPageTitle(ps, pageId, panelName)
    }

    function createBlankPanelData(ps, panelPointer, name, newPageRef) {
        const panel = dataModel.createDataItemByType(ps, PANEL_TYPE)
        panel.id = panelPointer.id
        panel.name = name
        panel.title = name
        panel.kind = PANEL_KIND.SETTINGS
        panel.rootCompId = `#${_.get(newPageRef, 'id')}`
        panel.height = DEFAULT_PANEL_HEIGHT

        return panel
    }

    function addPanelToAppStudio(ps, panelPointer, panelData) {
        appStudioDataModel.setData(ps, panelPointer, panelData)
    }

    function createPanelPageWithStructure(ps, panelName, pageRef) {
        page.add(ps, pageRef, panelName, panelConfig.getPageStructure())
        const stageContainerRef = component.getComponentToAddRef(ps, pageRef)

        const panelStructure = panelConfig.getPanelStructure()
        const {appWidgetStructure} = widgetConfigs.createNewResponsiveWidgetConfig()
        const wrappedPanelStructure = _.defaultsDeep({components: [panelStructure]}, appWidgetStructure)

        component.add(ps, stageContainerRef, pageRef, wrappedPanelStructure)
    }

    function assignPanelToWidget(ps, widgetPointer, panelPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        const panelId = `#${panelPointer.id}`

        if (!widgetData.panels.includes(`#${panelPointer.id}`)) {
            widgetData.panels = [...widgetData.panels, panelId]
        }

        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }

    function createPanel(ps, panelPointer, widgetPointer, name) {
        const panelName = name || generateNewPanelName(ps)
        const {success, errorCode} = validatePanelName(ps, panelName, widgetPointer)

        if (!success) {
            throw new Error(errorCode)
        }

        const newPageRef = page.getPageIdToAdd(ps)
        const newPanel = createBlankPanelData(ps, panelPointer, panelName, newPageRef)
        addPanelToAppStudio(ps, panelPointer, newPanel)

        createPanelPageWithStructure(ps, panelName, newPageRef)

        assignPanelUrl(ps, newPageRef, panelPointer)
        assignPanelToWidget(ps, widgetPointer, panelPointer)

        const rootAppWidget = appStudioDataModel.getRootWidgetByPage(ps, newPageRef)
        appStudioWidgets.setInitialAppWidgetData(ps, rootAppWidget, newPageRef.id)
    }

    function assignPanelUrl(ps, pageRef, panelPointer) {
        const pageUrl = pageData.getPageUriSEO(ps, pageRef.pageId)

        const panelData = appStudioDataModel.getData(ps, panelPointer)
        panelData.pageUrl = pageUrl
        appStudioDataModel.setData(ps, panelPointer, panelData)
    }

    function displayPanel(ps, panelPointer, callback) {
        const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)

        if (!rootCompId) {
            throw new Error(PANEL_ERRORS.ROOT_COMP_ID_DOES_NOT_EXIST)
        }

        page.navigateTo(ps, rootCompId, callback)
    }

    function removePanel(ps, panelPointer, widgetPointer, callback) {
        const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
        removePanelFromWidget(ps, panelPointer.id, widgetPointer)
        page.remove(ps, rootCompId, callback)
    }

    function removePanelFromWidget(ps, panelId, widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        const panels = widgetData.panels.filter(panel => dsUtils.stripHashIfExists(panel) !== panelId)

        if (panels.length !== widgetData.panels.length) {
            widgetData.panels = panels
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        }
    }

    function getPanelPointerByRootCompId(ps, rootCompId) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps) || {}
        const panelData = _(appStudioData.widgets)
            .map(widget => widget.panels)
            .flatten()
            .find(currentPanel => dsUtils.stripHashIfExists(currentPanel?.rootCompId) === rootCompId)

        return panelData && ps.pointers.data.getDataItemFromMaster(panelData.id)
    }

    function getRootCompIdByPanelPointer(ps, panelPointer) {
        return appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
    }

    function duplicatePanel(ps, newPointer, panelPointer, widgetPointer) {
        const panelToCopy = appStudioDataModel.getData(ps, panelPointer)

        if (!panelToCopy) {
            throw new Error(PANEL_ERRORS.INVALID_POINTER)
        }

        const newPanelName = generateNewPanelName(ps, panelToCopy.name)
        const newPageRef = page.getPageIdToAdd(ps)
        const blankPanelData = createBlankPanelData(ps, newPointer, newPanelName, newPageRef)

        const duplicatedPanel = _.assign(blankPanelData, _.omit(panelToCopy, PANEL_PROPS_DUPLICATION_BLOCK_LIST))

        addPanelToAppStudio(ps, newPointer, duplicatedPanel)

        page.duplicate(ps, newPageRef, dsUtils.stripHashIfExists(panelToCopy.rootCompId))
        syncPanelNameWithPage(ps, newPointer, newPanelName)

        assignPanelUrl(ps, newPageRef, newPointer)
        assignPanelToWidget(ps, widgetPointer, newPointer)
    }

    function renamePanel(ps, panelPointer, widgetPointer, name) {
        validatePanelName(ps, name, widgetPointer)
        updateNameData(ps, panelPointer, name)
        syncPanelNameWithPage(ps, panelPointer, name)
        appBuilderPlatformApp.updateApp(ps)
    }

    function validateHelpId(helpId) {
        if (!_.isEmpty(helpId) && !dsUtils.isGuid(helpId)) {
            return {success: false, errorCode: PANEL_HELP_ID_ERRORS.NOT_GUID}
        }
        return {success: true}
    }

    function setHelpId(ps, panelPointer, helpId) {
        const validationResult = validateHelpId(helpId)
        if (!validationResult.success) {
            throw new Error(`appStudio.panels.helpid: ${validationResult.errorCode}`)
        }
        const panelData = appStudioDataModel.getData(ps, panelPointer)
        panelData.helpId = helpId
        appStudioDataModel.setData(ps, panelPointer, panelData)
    }

    function validateTitle(panelTitle) {
        if (_.isEmpty(panelTitle)) {
            return {success: false, errorCode: PANEL_TITLE_ERRORS.EMPTY_NAME}
        }

        if (_.size(panelTitle) > 100) {
            return {success: false, errorCode: PANEL_TITLE_ERRORS.INVALID_LENGTH}
        }

        return {success: true}
    }

    function setTitle(ps, panelPointer, title) {
        const validationResult = validateTitle(title)
        if (!validationResult.success) {
            throw new Error(`appStudio.panels.title: ${validationResult.errorCode}`)
        }

        const panelData = appStudioDataModel.getData(ps, panelPointer)
        panelData.title = title
        appStudioDataModel.setData(ps, panelPointer, panelData)
    }

    function validateHeight(height) {
        if (height < 0) {
            return {success: false, errorCode: PANEL_HEIGHT_ERRORS.OUT_OF_RANGE}
        }
        return {success: true}
    }

    function setHeight(ps, panelPointer, height) {
        const validationResult = validateHeight(height)
        if (!validationResult.success) {
            throw new Error(`appStudio.panels.height: ${validationResult.errorCode}`)
        }

        const panelData = appStudioDataModel.getData(ps, panelPointer)
        panelData.height = height
        appStudioDataModel.setData(ps, panelPointer, panelData)
    }

    return {
        generateNewPanelName,
        createPanel,
        removePanel,
        duplicatePanel,
        renamePanel,
        displayPanel,
        getPanelPointerByRootCompId,
        getRootCompIdByPanelPointer,
        setHelpId,
        setTitle,
        setHeight,
        validateTitle: (ps, title) => validateTitle(title),
        validateHelpId: (ps, helpId) => validateHelpId(helpId),
        validatePanelName
    }
})
