import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import textProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/textProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import proxySantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxySantaTypesDefinitions'
import santaComponents from '@wix/santa-components'
import PropTypes from 'prop-types'

function transformSkinProperties(refData) {
    refData[''].parentConst = santaComponents.utils.createReactElement.bind(null, 'span')
    return _.set(refData, ['richTextContainer', 'style', 'height'], 'initial')
}

/**
 * @class proxies.InlineText
 * @extends proxies.mixins.textProxy
 */
export default {
    mixins: [textProxy],
    propsTypes: {
        viewProps: PropTypes.shape({
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired
        })
    },
    renderProxy() {
        const data = this.proxyData

        const formattedText = this.createFormattedText(data, 'span')

        const componentType = 'wysiwyg.viewer.components.WRichText'

        const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap)
        const props = this.getRichTextChildCompProps(componentType, transformSkinProperties, compData)

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
