import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import wixappsDataHandler from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsDataHandler'

/**
 * @type {core.siteAspectsRegistry}
 */
const aspectsRegistry = componentsCore.siteAspectsRegistry

/**
 * @typedef {ClassicsDataAspect} wixappsClassics.ClassicsDataAspect
 *
 * @property {core.SiteAspectsSiteAPI} aspectSiteApi
 * @property {core.SiteAPI} siteApi
 */

/**
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @constructor
 */
function DataAspect(aspectSiteApi) {
    this.aspectSiteApi = aspectSiteApi
    _.bindAll(this, _.keys(DataAspect.prototype))
}

DataAspect.prototype = {
    getDescriptor(packageName) {
        return wixappsDataHandler.getDescriptor(this.aspectSiteApi.getSiteData(), packageName)
    },

    getDataByCompId(packageName, compId) {
        return wixappsDataHandler.getDataByCompId(this.aspectSiteApi.getSiteData(), packageName, compId)
    },

    getExtraDataByCompId(packageName, compId) {
        return wixappsDataHandler.getExtraDataByCompId(this.aspectSiteApi.getSiteData(), packageName, compId)
    },

    getDataByPath(packageName, path) {
        return wixappsDataHandler.getDataByPath(this.aspectSiteApi.getSiteData(), packageName, path)
    },

    setDataByPath(packageName, path, value) {
        wixappsDataHandler.setDataByPath(this.aspectSiteApi.getSiteData(), packageName, path, value)
    },

    setBatchedData(packageName, batch) {
        if (batch.length === 0) {
            return
        }
        const siteData = this.aspectSiteApi.getSiteData()
        this.aspectSiteApi.getActionQueue().runImmediately(
            function () {
                const didUpdateData = _.reduce(
                    batch,
                    function (acc, dataToSet) {
                        return wixappsDataHandler.setDataByPath(siteData, packageName, dataToSet.path, dataToSet.value)
                    },
                    false
                )

                if (didUpdateData) {
                    const pointers = this.aspectSiteApi.getPointers()
                    const packageCounterDataPointer = pointers.wixapps.getCounterData(packageName)
                    const displayedDAL = this.aspectSiteApi.getDisplayedDAL()
                    const currentCounter = displayedDAL.get(packageCounterDataPointer) || 0
                    displayedDAL.set(packageCounterDataPointer, currentCounter + 1)
                }
            }.bind(this)
        )
    },

    /**
     * Gets the metadata of the comp iff compId is set, otherwise the metadata of the package.
     * @param {string} packageName
     * @param {string?} compId
     * @returns {object} The metadata of the comp iff compId is set, otherwise the metadata of the package.
     */
    getMetadata(packageName, compId) {
        if (!compId) {
            return wixappsDataHandler.getPackageMetadata(this.aspectSiteApi.getSiteData(), packageName)
        }
        return wixappsDataHandler.getCompMetadata(this.aspectSiteApi.getSiteData(), packageName, compId)
    },

    /**
     * Gets wether or not the package had failed requests
     * @param {string} packageName
     * @returns {boolean} true if package had failed requests in SSR
     */
    didPackageHadRequestsTimeoutInSSR(packageName) {
        return packageName ? wixappsDataHandler.didPackageHadRequestsTimeoutInSSR(this.aspectSiteApi.getSiteData(), packageName) : false
    },

    /**
     * Gets the failed requests of the component of the comp iff compId is set, otherwise returns false
     * @param {string} packageName
     * @param {string?} compId
     * @returns {boolean} true if comp had failed requests in SSR // TODO this value changes throughout client side rendering. clarify the purpose of this function.
     */
    didCompHadFailedRequests(packageName, compId) {
        return compId ? wixappsDataHandler.didCompHadFailedRequests(this.aspectSiteApi.getSiteData(), packageName, compId) : false
    },

    /**
     * @param {string} packageName
     * @param {string?} compId
     * @returns {boolean} true iff comp had failed requests in SSR
     */
    didCompHadFailedRequestsInSSR(packageName, compId) {
        return compId ? wixappsDataHandler.didCompHadFailedRequestsInSSR(this.aspectSiteApi.getSiteData(), packageName, compId) : false
    },

    /**
     * Sets the metadata on the component iff compId is set, otherwise sets the package metadata.
     * @param {object} metadata
     * @param {string} packageName
     * @param {string?} compId
     */
    setMetadata(metadata, packageName, compId) {
        // @ts-ignore
        wixappsDataHandler.setPackageMetadata(metadata, this.aspectSiteApi.getSiteData(), packageName, compId)
    }
}

aspectsRegistry.registerSiteAspect('wixappsDataAspect', DataAspect)
