define([
    'lodash',
    '@wix/santa-core-utils',
    '@wix/document-manager-core',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/component/componentCode',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/constants/constants',
    'documentServices/variants/design',
    'documentServices/utils/repeater'
], function (_, santaCoreUtils, dmCore, dataModel, component, componentCode, documentModeInfo, mobileUtil, constants, design, repeaterUtils) {
    'use strict'
    const {displayedOnlyStructureUtil} = santaCoreUtils
    const {addRepeatedItemsDataOverridesIfNeeded} = repeaterUtils
    const {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES} = constants
    const CONNECTIONS_ITEM_TYPE = 'connections'
    const PROPERTIES_ITEM_TYPE = 'props'

    const INTERNAL_REF_TYPE = 'InternalRef'

    const isMobilePointer = compPointer => compPointer.type === constants.VIEW_MODES.MOBILE

    const cleanAndAddOverridenConnections = (ps, pageId, dataItem, newDataItemId, compToAddPointer, originalNicknameContext) => {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        if (originalNicknameContext) {
            componentCode.updateConnectionItemsNickname(ps, dataItem.items, compToAddPointer, pagePointer, originalNicknameContext)
        }

        dataItem.items = _.reject(
            dataItem.items,
            ({type, role}) => type === 'WixCodeConnectionItem' && componentCode.hasComponentWithThatNickname(ps, pagePointer, role)
        )

        if (!_.isEmpty(dataItem.items)) {
            dataModel.addSerializedConnectionsItemToPage(ps, pageId, dataItem, newDataItemId)
        }
    }

    const DATA_SETTERS = {
        data: dataModel.addSerializedDataItemToPage,
        props: dataModel.addSerializedPropertyItemToPage,
        design: dataModel.addSerializedDesignItemToPage,
        behaviors: dataModel.addSerializedBehaviorsItemToPage,
        connections: cleanAndAddOverridenConnections,
        mobileHints: dataModel.addSerializedMobileHintsItemToPage,
        style: dataModel.addSerializedStyleItemToPage
    }

    const MOBILE_SPLIT_SUFFIX = '-mobile'

    const extractBaseComponentId = dataItemPointer => {
        const dataQuery = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataItemPointer.type]
        if (_.isNil(dataQuery)) {
            throw new Error('Unkown data type')
        }

        return _.replace(dataItemPointer.id, new RegExp(`-${dataQuery}(${MOBILE_SPLIT_SUFFIX})?`), '')
    }

    const {getDataItemPointer, getPropertyItemPointer, getBehaviorsItemPointer, getConnectionsItemPointer, getMobileHintsItemPointer, getStyleItemPointer} =
        dataModel

    const {getDesignItemPointer} = design

    const DATA_POINTER_GETTERS = {
        data: getDataItemPointer,
        props: getPropertyItemPointer,
        design: getDesignItemPointer,
        behaviors: getBehaviorsItemPointer,
        connections: getConnectionsItemPointer,
        mobileHints: getMobileHintsItemPointer,
        style: getStyleItemPointer
    }

    const MOBILE_SPLITTABLE_TYPE = {
        props: true
    }

    const isMobileOverride = ({id, type}) => !!MOBILE_SPLITTABLE_TYPE[type] && _.endsWith(id, MOBILE_SPLIT_SUFFIX)

    const serializeCustomOverriddenData = (ps, overridePointers) =>
        _.map(overridePointers, overridePointer => {
            const compId = extractBaseComponentId(overridePointer)
            const referredCompId = santaCoreUtils.displayedOnlyStructureUtil.getReferredCompId(compId)
            const itemType = overridePointer.type
            const dataItem = dataModel.getDataByPointer(ps, itemType, overridePointer, true)
            const isMobile = isMobileOverride(overridePointer)

            return {
                itemType,
                dataItem,
                isMobile,
                compId: referredCompId
            }
        })

    function getOverriddenData(ps, refComponentPointer) {
        const overrides = ps.pointers.referredStructure.getAllOverrides(refComponentPointer)
        const overridePointersWithoutFallbacks = _.map(overrides, overridePointer => ps.pointers.referredStructure.getPointerWithoutFallbacks(overridePointer))

        return serializeCustomOverriddenData(ps, overridePointersWithoutFallbacks)
    }

    function getRemoteOverriddenData(ps, contextId, refComponentId) {
        const overrides = ps.pointers.referredStructure.getRemoteOverrides(contextId, refComponentId)

        return serializeCustomOverriddenData(ps, overrides)
    }

    function setCustomSerializeData(ps, refComponentPointer, customStructureData) {
        customStructureData.overriddenData = getOverriddenData(ps, refComponentPointer)
    }

    function unwrapOverriddenCompIds(overriddenData) {
        if (overriddenData) {
            return _.map(overriddenData, item => _.assign({}, item, {compId: displayedOnlyStructureUtil.getReferredCompId(item.compId)}))
        }
    }

    function getOverridenDataItemPointer(ps, compPointer, itemType) {
        const pointerGetter = DATA_POINTER_GETTERS[itemType]
        if (pointerGetter) {
            const dataItemPointer = pointerGetter(ps, compPointer)
            if (dataItemPointer) {
                return ps.pointers.referredStructure.getPointerWithoutFallbacks(dataItemPointer)
            }
        }
    }

    function removeAllOverrides(ps, compRef, excludeConnectionItems = true, excludeNonPropertiesItemsForMobile = false) {
        const overrides = getAllOverridesToBeRemoved(ps, compRef, excludeConnectionItems, excludeNonPropertiesItemsForMobile)
        removeOverrides(ps, overrides)
    }

    function removeConnectionOverride(ps, compRef) {
        const overrides = getAllOverridesToBeRemoved(ps, compRef, false)
        const connectionOverride = _.filter(overrides, override => override.type === CONNECTIONS_ITEM_TYPE)
        removeOverrides(ps, connectionOverride)
    }

    function getAllOverridesToBeRemoved(ps, compRef, excludeConnectionItems = true, excludeNonPropertiesItemsForMobile = false) {
        const compType = component.getType(ps, compRef)
        if (compType !== 'wysiwyg.viewer.components.RefComponent') {
            return []
        }

        const overrides = ps.pointers.referredStructure.getAllOverrides(compRef)
        return _(overrides)
            .filter(override => (isMobilePointer(compRef) && excludeNonPropertiesItemsForMobile ? override.type === PROPERTIES_ITEM_TYPE : true))
            .reject(override => (excludeConnectionItems ? override.type === CONNECTIONS_ITEM_TYPE : false))
            .map(pointer => ps.pointers.referredStructure.getPointerWithoutFallbacks(pointer))
            .value()
    }

    function hasOverridesToBeRemoved(...args) {
        return Boolean(getAllOverridesToBeRemoved(...args).length)
    }

    function removeOverrides(ps, overrides) {
        overrides.forEach(pointer => dataModel.removeItemRecursivelyByType(ps, pointer))
    }

    /**
     * Create a new component ref by existing component parent
     *
     * @param {ps} ps privateServices
     * @param compRef component ref that will be opened/closed
     * @returns {Pointer} new pointer for added component
     */
    const getComponentToCreateRef = (ps, compRef) => {
        const parentRef = ps.pointers.components.getParent(compRef)
        return component.getComponentToDuplicateRef(ps, compRef, parentRef)
    }

    const removePropertyOverride = (ps, compPointer) => {
        const propertyOverridePointer = getOverridenDataItemPointer(ps, compPointer, constants.DATA_TYPES.prop)
        if (ps.dal.isExist(propertyOverridePointer)) {
            removeOverrides(ps, [propertyOverridePointer])
        }
    }

    const unGhostifyComponent = (ps, compPointer) => {
        const referredComponent = displayedOnlyStructureUtil.getRefHostCompId(compPointer.id)
        const ghostRefComps = ps.pointers.referredStructure.getGhostRefComponents(referredComponent)
        const propertyQuery = _.get(ghostRefComps, [compPointer.id, 'propertyQuery'])
        if (propertyQuery) {
            const propertyItem = ps.dal.get(ps.pointers.data.getPropertyItem(propertyQuery))
            const newPropertyItem = _.assign({}, propertyItem, {ghost: undefined})
            dataModel.updatePropertiesItem(ps, compPointer, newPropertyItem)
            mobileUtil.updateMobilePropertyIfNeeded(ps, compPointer, newPropertyItem, dataModel.updatePropertiesItem)
        } else {
            removePropertyOverride(ps, compPointer)
            mobileUtil.removeMobilePropertyOverrideIfNeeded(ps, compPointer, removePropertyOverride)
        }
    }

    const createOverrideDataItem = (ps, itemType, compToAddPointer, compId, pageId, dataItem, isMobile, originalNicknameContext) => {
        const dataSetter = DATA_SETTERS[itemType]
        const shouldAddMobileSuffix = isMobile && MOBILE_SPLITTABLE_TYPE[itemType]
        const repeatedTemplatePointer = dmCore.pointerUtils.getRepeatedItemPointerIfNeeded(compToAddPointer)

        const dataItemSuffix = `-${COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]}${shouldAddMobileSuffix ? MOBILE_SPLIT_SUFFIX : ''}`

        if (dataSetter) {
            const newDataItemId = displayedOnlyStructureUtil.createRefIdWithSuffix(repeatedTemplatePointer.id, compId, dataItemSuffix)
            if (itemType === DATA_TYPES.theme) {
                dataSetter(ps, pageId, dataItem, newDataItemId, repeatedTemplatePointer.id)
            } else if (itemType === DATA_TYPES.data || itemType === DATA_TYPES.design) {
                addRepeatedItemsDataOverridesIfNeeded(ps, compToAddPointer, newDataItemId, dataItem, pageId, dataSetter)
            } else {
                dataSetter(ps, pageId, dataItem, newDataItemId, repeatedTemplatePointer, originalNicknameContext)
            }
        }
    }

    const isRefHost = (ps, compPointer) => component.getType(ps, compPointer) === constants.REF_COMPONENT.REF_COMPONENT_TYPE

    const isInternalRef = (ps, componentRef) => {
        const compData = component.data.get(ps, componentRef)
        const refType = _.get(compData, 'type')
        return refType === INTERNAL_REF_TYPE
    }

    const updateVariation = (ps, refComponent, variationId) => {
        if (isInternalRef(ps, refComponent)) {
            const variationPage = ps.pointers.components.getPage(variationId, documentModeInfo.getViewMode(ps))
            const [newVariationWidgetRoot] = ps.pointers.full.components.getChildren(variationPage)
            component.data.update(ps, refComponent, {pageId: variationId, rootCompId: newVariationWidgetRoot.id})
        } else {
            component.data.update(ps, refComponent, {variationId})
        }
    }

    const getSerializedConnectionOverrideData = connectionsItem => {
        const compId = extractBaseComponentId({id: connectionsItem.id, type: CONNECTIONS_ITEM_TYPE})
        const referredCompId = displayedOnlyStructureUtil.getReferredCompId(compId)
        return {
            itemType: CONNECTIONS_ITEM_TYPE,
            dataItem: connectionsItem,
            compId: referredCompId
        }
    }

    const createOverrideKey = ({compId, itemType}) => `${itemType}_${compId}`

    const isRefComponentInflated = (ps, refComp) => !_.isEmpty(ps.pointers.components.getChildren(refComp))

    return {
        DATA_SETTERS,
        removeConnectionOverride,
        isRefComponentInflated,
        getOverridenDataItemPointer,
        getAllOverridesToBeRemoved,
        extractBaseComponentId,
        getOverriddenData,
        getRemoteOverriddenData,
        getSerializedConnectionOverrideData,
        setCustomSerializeData,
        removeAllOverrides,
        hasOverridesToBeRemoved,
        removeOverrides,
        createOverrideDataItem,
        getComponentToCreateRef,
        unGhostifyComponent,
        isRefHost,
        getReferredCompId: displayedOnlyStructureUtil.getReferredCompId,
        updateVariation,
        isInternalRef,
        unwrapOverriddenCompIds,
        createOverrideKey
    }
})
