define([
    'lodash',
    '@wix/mobile-conversion',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/santa-core-utils',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/constants/constants',
    'documentServices/component/componentModes'
], function (_, mobileCore, coreUtils, santaCoreUtils, componentsMetaData, constants, componentModes) {
    'use strict'

    const isDesktopOnlyComponent = (ps, pageId, comp) => !!componentsMetaData.public.getMobileConversionConfigByName(ps, comp, 'desktopOnly', pageId)

    function isMobileOnlyComponent(ps, pagePointer, component) {
        const componentPointer = ps.pointers.full.components.getComponent(component.id, pagePointer)
        return componentsMetaData.public.isMobileOnly(ps, componentPointer)
    }

    function getHiddenAndShownComponents(ps, pageId) {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        const pageActiveModesMap = componentModes.getMobileActiveModesMap(ps, pageId)
        const activeModesMap = _.set({}, pageId, pageActiveModesMap)
        const pageStructure = ps.siteAPI.getDeepPageStructure ? ps.siteAPI.getDeepPageStructure(pageId) : ps.dal.full.get(pagePointer)
        const page = coreUtils.fullToDisplayedJson.getDisplayedJson(pageStructure, activeModesMap, pageId).structure
        if (!page.mobileComponents) {
            return
        }
        const components = santaCoreUtils.dataUtils.getAllCompsInStructure(page, false, _.negate(isDesktopOnlyComponent.bind(null, ps, pageId)))
        const mobileComponents = santaCoreUtils.dataUtils.getAllCompsInStructure(page, true, _.negate(isMobileOnlyComponent.bind(null, ps, pagePointer)))
        return {
            hiddenComponents: mobileCore.structuresComparer.getComponentIdsAddedToDesktopStructure(components, mobileComponents),
            shownComponents: _.intersection(_.keys(mobileComponents), _.keys(components))
        }
    }

    function hasMobileStructure(ps) {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const mobileChildren = ps.pointers.components.getChildren(masterPagePointer)
        return !_.isEmpty(mobileChildren)
    }

    return {
        getHiddenAndShownComponents,
        hasMobileStructure
    }
})
