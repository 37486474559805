import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import baseCompositeProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseCompositeProxy'

/**
 * @class proxies.Stack
 * @extends proxies.mixins.baseCompositeProxy
 */
export default {
    mixins: [baseCompositeProxy],

    getCustomStyle() {
        return {
            position: 'relative'
        }
    },

    renderProxy() {
        const childrenDefinitions = this.getCompProp('items')

        const children = _.map(
            childrenDefinitions,
            function (childDef, i) {
                const childLayoutDef = this.getCompProp('layout', childDef)
                const positionProp = i === 0 && this.getCompProp('stackType') !== 'layout' ? 'static' : 'absolute'
                const childLayout = {
                    position: positionProp,
                    top: childLayoutDef.top || '0px',
                    left: childLayoutDef.left || '0px'
                }

                return this.renderChildProxy(childDef, i, childLayout)
            }.bind(this)
        )

        const props = this.getChildCompProps()
        props['data-proxy-name'] = 'Stack'

        return santaComponents.utils.createReactElement('div', props, children)
    }
}
