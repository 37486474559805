define([
    'lodash',
    'platformInit',
    'experiment',
    'documentServices/hooks/hooks',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/platform/services/wixDataService',
    'documentServices/platform/services/workerService',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/appStoreService',
    'documentServices/platform/services/originService',
    'documentServices/platform/common/constants',
    'documentServices/platform/appComponents',
    'documentServices/wixCode/wixCode',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/platform/services/platformStateService',
    'documentServices/platform/services/copyDataFromTemplate',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/platform/services/platformAppDataGetter',
    'documentServices/tpa/utils/permissionsUtils'
], function (
    _,
    platformInit,
    experiment,
    hooks,
    clientSpecMap,
    wixDataService,
    workerService,
    clientSpecMapService,
    appStoreService,
    originService,
    constants,
    appComponents,
    wixCode,
    contextAdapter,
    dsConstants,
    platformStateService,
    copyDataFromTemplate,
    generalInfo,
    platformAppDataGetter,
    permissionsUtils
) {
    'use strict'

    const getOriginInfo = (appDefinitionId, installOptions) => {
        if (!appDefinitionId) {
            return null
        }
        if (installOptions?.platformOrigin) {
            const info = installOptions?.platformOrigin?.info
            return info?.type || info?.appDefinitionId
        }
        if (installOptions?.origin) {
            const info = installOptions?.origin?.info
            return info?.type || info?.appDefinitionId
        }
        return null
    }

    const {hasCodePackage} = appComponents

    const getProvisionInteractionParams = (appDefinitionId, installationOriginInfo) => ({
        tags: {
            origin_info: installationOriginInfo,
            appDefinitionId
        },
        extras: {app_id: appDefinitionId}
    })

    const extendOptionWithInternalOrigin = (options, internalOrigin) => _.setWith(options, ['internalOrigin'], internalOrigin, Object)

    function provision(ps, appDefinitionId, options) {
        const installationOriginInfo = getOriginInfo(appDefinitionId, options)
        contextAdapter.utils.fedopsLogger.interactionStarted(
            dsConstants.PLATFORM_INTERACTIONS.PROVISION,
            getProvisionInteractionParams(appDefinitionId, installationOriginInfo)
        )
        return new Promise(function (resolve, reject) {
            if (generalInfo.isTemplate(ps)) {
                reject(new Error('cannot add apps on template'))
                return
            }
            if (!appDefinitionId) {
                reject(new Error('options must contain appDefinitionId'))
                return
            }
            if (appDefinitionId === constants.APPS.WIX_CODE.appDefId) {
                if (!workerService.isInitiated()) {
                    reject(workerService.getNotInitError(ps, 'failed provisioning wix code'))
                    return
                }
                wixCode.provision(ps, {
                    onSuccess: resolve,
                    onError: reject
                })
                return
            }

            const resolveWithEnd = arg => {
                contextAdapter.utils.fedopsLogger.interactionEnded(
                    dsConstants.PLATFORM_INTERACTIONS.PROVISION,
                    getProvisionInteractionParams(appDefinitionId, installationOriginInfo)
                )
                resolve(arg)
            }
            addApp(ps, appDefinitionId, options, resolveWithEnd, reject)
        })
    }

    async function update(ps, applicationId, applicationVersion, options) {
        if (workerService.isInitiated()) {
            return await updateApp(ps, applicationId, applicationVersion, options)
        }

        return Promise.reject('workerService is not initiated')
    }

    async function updateApp(ps, applicationId, applicationVersion, options) {
        const existingAppData = clientSpecMapService.getAppData(ps, applicationId)
        if (!existingAppData) {
            return Promise.reject('Application with the given applicationId is not installed. Please provision before updating')
        }

        if (applicationVersion === existingAppData.version) {
            return onUpdateSuccess(ps, options, existingAppData)
        }

        if (experiment.isOpen('dm_useProvisionApi')) {
            const updatedClientSpecMap = await appStoreService.update(ps, [
                {
                    applicationId,
                    appVersion: applicationVersion
                }
            ])

            return onUpdateSuccess(ps, options, _.find(updatedClientSpecMap, {appDefinitionId: existingAppData.appDefinitionId}))
        }

        const appData = await new Promise((resolve, reject) =>
            appStoreService.preSaveAddApp(ps, existingAppData.appDefinitionId, resolve, reject, applicationVersion)
        )

        return onUpdateSuccess(ps, options, appData)
    }

    function wasAppVersionUpdated(ps, {appDefinitionId, version}) {
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

        return !existingAppData || existingAppData.version !== version
    }

    async function onUpdateSuccess(ps, options, appData) {
        const reportProgress = _.get(options, 'reportProgress')
        if (reportProgress) {
            reportProgress({step: 'clientSpecMap'})
        }
        if (wasAppVersionUpdated(ps, appData) && !experiment.isOpen('dm_useProvisionApi')) {
            platformStateService.setAppPendingAction(ps, appData.appDefinitionId, constants.APP_ACTION_TYPES.UPDATE)
        }

        clientSpecMap.registerAppData(ps, appData)

        if (experiment.isOpen('dm_uninstallCodePackages') && hasCodePackage(appData)) {
            await wixCode.codePackages.installCodeReusePkg(ps, appData.appDefinitionId, appData.version)
        }

        try {
            await workerService.notifyAppUpdated(ps, appData.applicationId, options)

            try {
                const {appDefinitionId, version: appVersion} = appData

                await handleAppProvisionedOrUpdatad({success: true}, ps, appDefinitionId, appVersion)

                return appData
            } catch (e) {
                throw appData
            }
        } finally {
            hooks.executeHook(hooks.HOOKS.PLATFORM.APP_UPDATED, '', [ps, appData])
        }
    }

    function addApp(ps, appDefinitionId, options, resolve, reject) {
        if (experiment.isOpen('dm_useProvisionApi')) {
            const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
                //when coming from presets
                if (_.get(options, 'sourceTemplateId')) {
                    extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_WITH_SOURCE_TEMPLATE_ID)
                    appStoreService.provisionAppFromSourceTemplate(
                        ps,
                        appDefinitionId,
                        options.sourceTemplateId,
                        _.partial(onPreSaveProvisionSuccess, ps, resolve, reject, options),
                        reject
                    )
                } else {
                    appStoreService.provision(
                        ps,
                        [
                            {
                                appDefinitionId,
                                version: _.get(options, 'appVersion')
                            }
                        ],
                        updatedClientSpecMap => {
                            const appData = _.find(updatedClientSpecMap, {appDefinitionId})
                            if (appData) {
                                const origin_instance_id = _.get(options, 'origin_instance_id')
                                if (origin_instance_id) {
                                    const {copyDataFromOriginTemplateByApp} = copyDataFromTemplate
                                    copyDataFromOriginTemplateByApp(ps, appDefinitionId, appData, {origin_instance_id})
                                        .then(() => {
                                            extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_SUCC)
                                            onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                        })
                                        .catch(() => {
                                            extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_ERROR)
                                            onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                        })
                                } else {
                                    extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP)
                                    onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                }
                            } else {
                                reject('Application wasnt installed')
                            }
                        },
                        reject
                    )
                }
            } else {
                extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_EXISTING)
                onPreSaveProvisionSuccess(ps, resolve, reject, options, existingAppData)
            }
        } else {
            const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
                //when coming from presets
                if (_.get(options, 'sourceTemplateId')) {
                    extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_WITH_SOURCE_TEMPLATE_ID_OLD)
                    appStoreService.provisionAppFromSourceTemplate(
                        ps,
                        appDefinitionId,
                        options.sourceTemplateId,
                        _.partial(onPreSaveProvisionSuccess, ps, resolve, reject, options),
                        reject
                    )
                    return
                }
                extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_PRE_SAVE)
                appStoreService.preSaveAddApp(
                    ps,
                    appDefinitionId,
                    _.partial(onPreSaveProvisionSuccess, ps, resolve, reject, options),
                    reject,
                    _.get(options, 'appVersion')
                )
            } else {
                extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_EXISTING_OLD)
                onPreSaveProvisionSuccess(ps, resolve, reject, options, existingAppData)
            }
        }
    }

    const handleAppProvisionedOrUpdatad = (resultData, ps, appDefinitionId, appVersion) => {
        if (_.get(resultData, 'success')) {
            return wixDataService.installCollections(ps, appDefinitionId, appVersion)
        }
        return Promise.reject(new Error('handleAppProvisionedOrUpdatad'))
    }

    function onPreSaveProvisionSuccess(ps, resolve, reject, options = {}, appData) {
        const appDefinitionId = _.get(appData, 'appDefinitionId')
        const installationOriginInfo = getOriginInfo(appDefinitionId, options)
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
            tags: {
                origin_info: installationOriginInfo,
                appDefinitionId
            },
            extras: {app_id: appDefinitionId}
        })

        if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
            if (!experiment.isOpen('dm_useProvisionApi')) {
                appData.notProvisioned = true
            }
            clientSpecMap.registerAppData(ps, appData)
        }

        if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
            const addAppResponse = _.assign(
                {
                    success: true,
                    type: 'addAppCompleted'
                },
                appData
            )

            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
                tags: {
                    origin_info: installationOriginInfo,
                    appDefinitionId
                },
                extras: {app_id: appDefinitionId, addAppResponse: addAppResponse?.success}
            })
            resolve(addAppResponse)
        } else {
            if (!workerService.isInitiated()) {
                const err = workerService.getNotInitError(ps, 'onPreSaveProvisionSuccess')
                reject(err)
                return
            }
            appData.firstInstall = true
            if (options.isSilent) {
                appData.silentInstallation = true
            }

            if (options.headlessInstallation) {
                appData.headlessInstallation = true
            }

            appData.origin = _.get(options, 'origin')
            if (!appData.origin || typeof appData.origin !== 'object') {
                appData.origin = {}
            }
            appData.origin.type = appData.origin.type || originService.getEditorType()
            appData.biData = _.get(options, 'biData')

            appData.settings = _.get(options, 'settings')

            workerServiceAddAppWrapper(ps, appData, options)
                .then(addAppResponse => {
                    contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
                        tags: {
                            origin_info: installationOriginInfo,
                            appDefinitionId
                        },
                        extras: {app_id: appDefinitionId, addAppResponse: addAppResponse?.success}
                    })
                    resolve(addAppResponse)
                })
                .catch(addAppError => {
                    contextAdapter.utils.fedopsLogger.captureError(addAppError, {
                        tags: {
                            appDefinitionId,
                            origin_info: installationOriginInfo
                        },
                        extras: {
                            errName: dsConstants.PLATFORM_ERRORS.ON_PRE_SAVE_PROVISION,
                            app_id: appDefinitionId,
                            originalError: addAppError
                        }
                    })
                    reject(addAppError)
                })
        }
    }

    async function onRemoteProvision(ps, appData) {
        if (platformAppDataGetter.hasAppManifest(ps, appData.appDefinitionId)) {
            return
        }

        if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
            return
        }

        if (!workerService.isInitiated()) {
            return Promise.reject(workerService.getNotInitError(ps, 'onRemoteProvision'))
        }

        appData.firstInstall = false
        appData.silentInstallation = true

        if (!appData.origin) {
            appData.origin = {type: originService.getEditorType()}
        }

        await workerServiceAddAppWrapper(ps, appData, {origin: 'onRemoveProvision'})
    }

    async function workerServiceAddAppWrapper(ps, appData, options) {
        const installationOriginInfo = getOriginInfo(appData.appDefinitionId, options)
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        const appDataWithResolvedUrl = platformInit.specMapUtils.resolveEditorScriptUrl(appData, {
            clientSpec: appData,
            serviceTopology
        })

        return await new Promise((resolve, reject) => {
            contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
                tags: {
                    origin_info: installationOriginInfo,
                    appDefinitionId: appData.appDefinitionId
                },
                extras: {
                    appDefinitionId: appData.appDefinitionId,
                    origin: _.get(options, 'origin'),
                    internalOrigin: _.get(options, 'internalOrigin')
                }
            })
            workerService.addApp(
                ps,
                appDataWithResolvedUrl,
                function (data) {
                    const resultData = _.assign(appDataWithResolvedUrl, data || {})
                    const appVersion = _.get(appData, 'version')
                    contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
                        tags: {
                            origin_info: installationOriginInfo,
                            appDefinitionId: appData.appDefinitionId
                        },
                        extras: {
                            appDefinitionId: appData.appDefinitionId,
                            resultData: data
                        }
                    })
                    contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                        tags: {
                            origin_info: installationOriginInfo,
                            appDefinitionId: appData.appDefinitionId
                        },
                        extras: {
                            appDefinitionId: appData.appDefinitionId
                        }
                    })
                    handleAppProvisionedOrUpdatad(resultData, ps, appData.appDefinitionId, appVersion).then(
                        () => {
                            hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                            contextAdapter.utils.fedopsLogger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                                tags: {
                                    origin_info: installationOriginInfo,
                                    appDefinitionId: appData.appDefinitionId
                                },
                                extras: {
                                    appDefinitionId: appData.appDefinitionId
                                }
                            })
                            resolve(resultData)
                        },
                        async () => {
                            if (await permissionsUtils.shouldAvoidRevoking({ps})) {
                                platformStateService.setUnusedApps(ps, [appData])
                            } else {
                                platformStateService.setAppPendingAction(ps, appData.appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
                            }

                            clientSpecMap.registerAppData(ps, appData)
                            hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                            contextAdapter.utils.fedopsLogger.captureError(new Error('workerService.addApp error'), {
                                tags: {
                                    origin_info: installationOriginInfo,
                                    appDefinitionId: appData.appDefinitionId,
                                    handleAppProvisionedOrUpdated: true
                                },
                                extras: {
                                    errName: dsConstants.PLATFORM_ERRORS.AFTER_APP_ADDED_ACTIONS,
                                    appDefinitionId: appData.appDefinitionId,
                                    resultData: data
                                }
                            })
                            reject(_.defaults({success: false}, resultData))
                        }
                    )
                },
                options
            )
        })
    }

    return {
        provision,
        update,
        onPreSaveProvisionSuccess,
        onRemoteProvision,
        extendOptionWithInternalOrigin
    }
})
