define(['lodash', '@wix/santa-core-utils', '@wix/santa-ds-libs/src/coreUtils', 'documentServices/utils/utils'], function (_, coreUtilsLib, coreUtils, dsUtils) {
    'use strict'
    const {requestsUtil, svgUtils} = coreUtilsLib
    const {SVG_STORES, EMPTY_SHAPE, svgIdToUrl} = svgUtils
    const {svgStringToStoreData} = coreUtils.svg
    const fetchOtions = {
        method: 'GET',
        headers: {'content-type': 'text/plain'},
        mode: 'cors'
    }
    /**
     * Cache an SVG to siteData and notify asyncPreDataManipulationComplete
     * @param {ps} ps
     * @param {string} svgId
     */
    function cacheSvgString(ps, svgId) {
        getSvgString(ps, svgId, function () {
            ps.setOperationsQueue.asyncPreDataManipulationComplete()
        })
    }

    /**
     *
     * @param {ps} ps
     * @param {string} svgId
     * @param {function(*):*} callback
     */
    function getSvgString(ps, svgId, callback) {
        const svgString = getSvgStringFromCache(ps, svgId)
        if (svgString) {
            callback(svgString)
        } else {
            fetchSvgString(ps, svgId, callback)
        }
    }

    /**
     * Ajax get the contents of an SVG file and add it to cache
     * @param {ps} ps
     * @param {string} svgId
     */
    async function fetchSvgStringAsync(ps, svgId) {
        const url = getSvgFullUrl(ps, svgId)
        const response = await requestsUtil.fetch(url, fetchOtions)
        const svgString = await response.text()
        setSvgToCache(ps, svgId, svgString)
        return svgString
    }

    /**
     * Ajax get the contents of an SVG file and add it to cache
     * @param {ps} ps
     * @param {string} svgId
     * @param {function(*): *} callback
     */
    function fetchSvgString(ps, svgId, callback) {
        fetchSvgStringAsync(ps, svgId).then(callback) // eslint-disable-line promise/prefer-await-to-then
        //TODO: handle errors?
    }

    /**
     * Get a url from a publicMedia SVG id
     * @param {ps} ps
     * @param {string} svgId
     * @returns {string}
     */
    function getSvgFullUrl(ps, svgId) {
        const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
        const mediaRootUrlPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, 'mediaRootUrl')
        const mediaRootUrl = ps.dal.get(mediaRootUrlPointer)
        return svgIdToUrl(mediaRootUrl, svgId, {rendererModel: ps.siteDataAPI.siteData.rendererModel})
    }

    /**
     *
     * @param {ps} ps
     * @param {string} svgId
     * @param {function} [callback]
     * @returns {string}
     */
    function getSvgStringFromCache(ps, svgId, callback) {
        const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const contentPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.CONTENT)
        const svgString = ps.dal.get(contentPointer)
        if (callback && _.isFunction(callback)) {
            callback(svgString)
        }
        return svgString
    }

    /**
     *
     * @param {ps} ps
     * @param {string} svgId
     * @returns {string}
     */
    function getSvgInfoFromCache(ps, svgId) {
        const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const infoPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.INFO)
        return ps.dal.get(infoPointer)
    }

    /**
     *
     * @param {ps} ps
     * @param {string} svgId
     * @param {string} svgString
     */
    function setSvgToCache(ps, svgId, svgString) {
        const svgStorePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const storeData = svgStringToStoreData(svgString || EMPTY_SHAPE)
        ps.dal.set(svgStorePointer, storeData)
    }

    /**
     * Get the original aspect ration of the currently rendered SVG
     * @param {ps} ps
     * @param {Pointer} compRef
     * @returns {number}
     */
    function getOriginalAspectRatio(ps, compRef) {
        const compStructure = ps.dal.get(compRef)
        const page = ps.pointers.components.getPageOfComponent(compRef)
        const compDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(compStructure.dataQuery), page.id)
        const compData = ps.dal.get(compDataPointer)
        const svgId = _.get(compData, ['svgId'])
        const storePointer = ps.pointers.svgShapes.getSvgShapePointer(svgId)
        const boxBoundariesPointer = ps.pointers.getInnerPointer(storePointer, SVG_STORES.BOX_BOUNDARIES)
        const boxBoundaries = ps.dal.get(boxBoundariesPointer)
        if (_.isEmpty(boxBoundaries)) {
            return 0
        }
        return boxBoundaries.width / boxBoundaries.height
    }

    return {
        cacheSvgString,
        getSvgFromCache: getSvgStringFromCache,
        getSvgInfoFromCache,
        getOriginalAspectRatio
    }
})
