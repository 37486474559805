import _ from 'lodash'
import * as utils from '@wix/santa-ds-libs/src/utils'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as thirdPartyAnalytics from '../../../_internal/thirdPartyAnalytics'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import {platformizedEndpointsUtils} from '@wix/santa-platform-utils'
import pubSubHandlers from './pubSubHandlers'

const {linkTypes} = coreUtils

const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'

const _extractPageByURI = function (siteAPI, pageId) {
    return siteAPI.getSiteData().findDataOnMasterPageByPredicate(function (pageData) {
        return pageData.pageUriSEO === pageId || pageData.id === pageId
    })
}

const _getCenterScreenCoordinates = function (popupWidth, popupHeight) {
    // Fixes dual-screen position                         Most browsers      Firefox
    // @ts-ignore
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
    // @ts-ignore
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
    let width
    let height
    if (window.innerWidth) {
        width = window.innerWidth
    } else {
        width = window.document.documentElement.clientWidth ? window.document.documentElement.clientWidth : window.screen.width
    }
    if (window.innerHeight) {
        height = window.innerHeight
    } else {
        height = window.document.documentElement.clientHeight ? window.document.documentElement.clientHeight : window.screen.height
    }
    const left = width / 2 - popupWidth / 2 + dualScreenLeft
    const top = height / 2 - popupHeight / 2 + dualScreenTop

    return {top, left}
}

const _checkExternalNavigationAllowed = function (siteAPI) {
    const isExternalNavigationAllowed = siteAPI.getRenderFlag('isExternalNavigationAllowed')

    if (!isExternalNavigationAllowed) {
        const previewTooltipCallback = siteAPI.getRenderRealtimeConfigItem('previewTooltipCallback')
        previewTooltipCallback(_getCenterScreenCoordinates(50, 20), 'text_editor_inactive_link_on_preview')
        return false
    }

    return true
}

const _navigateToPage = function (siteAPI, data) {
    let navigationInfo = {
        pageId: siteAPI.getSiteData().getPrimaryPageId()
    }

    if (data.pageId) {
        if (data.pageId === '#') {
            _.assign(navigationInfo, {
                pageId: siteAPI.getSiteData().getMainPageId()
            })
        } else {
            const pageData = _extractPageByURI(siteAPI, data.pageId)
            _.assign(navigationInfo, {
                pageId: pageData.id === 'masterPage' ? navigationInfo.pageId : pageData.id
            })
        }
    }

    if (data.routerId) {
        const dynamicRouter = _.get(siteAPI.getSiteData(), ['routers', 'configMap', data.routerId])
        const pageAdditionalData = data.innerRoute ? `${dynamicRouter.prefix}/${data.innerRoute}` : dynamicRouter.prefix
        if (dynamicRouter) {
            navigationInfo = {
                // @ts-ignore
                routerDefinition: dynamicRouter,
                innerRoute: data.innerRoute,
                pageAdditionalData
            }
        }
    }

    if (data.anchorDataId) {
        _.assign(navigationInfo, {
            anchorData: data.anchorDataId
        })
    }

    if (data.queryParams) {
        _.assign(navigationInfo, {
            queryParams: data.queryParams
        })
    }

    if (data.tpaInnerRoute) {
        _.assign(navigationInfo, {
            tpaInnerRoute: data.tpaInnerRoute
        })
        if (navigationInfo.pageId === siteAPI.getSiteData().getPrimaryPageId()) {
            siteAPI.updatePageNavInfo(navigationInfo)
        }
    }

    siteAPI.handleNavigation(navigationInfo, '#', true)
}

const smCurrentUser = function (siteAPI, msg, callback) {
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')
    if (siteMemberAspect.isLoggedIn()) {
        siteMemberAspect.getMemberDetails(callback, function (error) {
            callback(null, error)
        })
    } else if (siteAPI.getSiteData().isPreviewMode()) {
        siteMemberAspect.getMemberDetailsInPreview(callback, function (error) {
            callback(null, error)
        })
    } else {
        callback(null, 'No user is currently logged in')
    }
}

const scrollToComponent = function (siteAPI, msg, callback) {
    siteAPI.scrollToComponent(msg.data.compId, {
        onComplete() {
            callback()
        }
    })
}

const navigateTo = function (siteAPI, msg) {
    if (siteAPI.getSiteData().isInSSR()) {
        // TODO propagate a message to the client indicating that navigation in SSR is not allowed via user code.
        // TODO suggest user to use router code for navigation.
        return
    }
    switch (msg.data.type) {
        case linkTypes.EXTERNAL_LINK:
            if (msg.data.url && _checkExternalNavigationAllowed(siteAPI)) {
                window.open(msg.data.url, '_self')
            }
            break
        case linkTypes.EMAIL_LINK:
            const email = coreUtils.linkUtils.getEmailLink(msg.data)
            if (email && _checkExternalNavigationAllowed(siteAPI)) {
                window.open(email, '_self')
            }
            break
        case linkTypes.PHONE_LINK:
            window.open(coreUtils.linkUtils.getPhoneLink(msg.data), '_self')
            break
        case linkTypes.DOCUMENT_LINK:
            const docLink = coreUtils.linkUtils.getDocumentLink(msg.data, siteAPI.getSiteData())
            window.open(docLink, '_self')
            break
        default:
            _navigateToPage(siteAPI, msg.data)
    }
}

const windowBoundingRect = function (siteAPI, msg, callback) {
    const windowScrollAspect = siteAPI.getSiteAspect('windowScrollEvent')
    callback({
        window: siteAPI.getWindowSize(),
        scroll: _.pick(windowScrollAspect.getScrollPosition(), ['x', 'y']),
        document: siteAPI.getDocumentSize()
    })
}

const startFileUpload = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.data.compId)
    comp.uploadFiles(callback)
}
/**
 * we activate the wix captcha resetCaptcha instance method
 * @param siteAPI
 * @param msg
 */
const resetCaptcha = function (siteAPI, msg) {
    const pageId = siteAPI.getSiteData().getPrimaryPageId()
    const comp = siteAPI.getComponentByPageAndCompId(pageId, msg.data.compId)
    comp.resetCaptcha()
}

const postHTMLMessage = function (siteAPI, msg) {
    const {data} = msg
    const htmlAspect = siteAPI.getSiteAspect('HtmlPostMessageAspect')
    htmlAspect.postMessage(data.id, data.msg)
}

const openLightbox = function (siteAPI, msg, callback) {
    const popupId = msg.data.id
    const popupPageAspect = siteAPI.getSiteAspect('PopupPageAspect')

    siteAPI.getRuntimeDal().setPopupContext(popupId, msg.data.context)

    if (callback) {
        popupPageAspect.registerCloseHandler(popupId, callback)
    }

    popupPageAspect.openPopupPage(popupId)
}

const closeLightbox = function (siteAPI, msg) {
    const popupId = msg.data.id
    if (siteAPI.getCurrentPopupId() !== popupId) {
        return
    }

    siteAPI.getSiteAspect('PopupPageAspect').closePopupPage(msg.data.returnedData)
}

const getRoutersSiteMap = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    const routerDefinition = _.find(siteData.routers.configMap, {prefix: msg.data.prefix}) || {}
    const paramsObj = utils.routersBackEndRequests.makeParamObjFromSiteData(siteData, routerDefinition, {})
    utils.routersBackEndRequests.getInnerRoutesSiteMap(
        paramsObj,
        function (siteMap) {
            callback(siteMap)
        },
        err => callback(null, _.get(err, 'message', err)),
        siteData
    )
}

const getCurrentGeolocation = function (siteAPI, msg, callback) {
    window.navigator.geolocation.getCurrentPosition(
        function (position) {
            callback({
                timestamp: _.get(position, 'timestamp'),
                coords: _.toPlainObject(_.get(position, 'coords'))
            })
        },
        function (error) {
            callback(null, error && error.message)
        }
    )
}

const smLogout = function (siteAPI, msg, callback) {
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')
    const isLoggedIn = siteMemberAspect.isLoggedIn()

    if (isLoggedIn) {
        const navigateToPage = _.isEmpty(msg.data)
            ? null
            : () => {
                  if (msg.data.type === linkTypes.EXTERNAL_LINK && msg.data.url && _checkExternalNavigationAllowed(siteAPI)) {
                      window.open(msg.data.url, '_self')
                  } else {
                      const linkData = {
                          pageId: {
                              id: msg.data.pageId
                          },
                          type: msg.data.type,
                          queryParams: msg.data.queryParams
                      }
                      const siteData = siteAPI.getSiteData()
                      const link = coreUtils.linkRenderer.renderLink(linkData, siteData, siteData.getRootNavigationInfo())
                      siteAPI.href(link.href)
                  }
              }
        siteMemberAspect.logout(null, callback, error => callback(null, error), {navigateToPage})
    } else {
        callback(null, 'No member is logged in')
    }
}

const smForgotPassword = function (siteAPI, msg, callback) {
    const language = _.get(msg, ['data', 'language'])
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')
    const isLoggedIn = siteMemberAspect.isLoggedIn()
    let succeedPasswordReset = false

    if (!isLoggedIn) {
        siteMemberAspect.showResetPasswordDialog(
            language || null,
            function () {
                succeedPasswordReset = true
            },
            function () {
                if (succeedPasswordReset) {
                    callback()
                } else {
                    callback(null, 'The user closed the forgot password dialog')
                }
            }
        )
    } else {
        callback(null, 'User is already logged in')
    }
}

const smCurrentUserRoles = function (siteAPI, msg, callback) {
    // FIXME - remove wixCode appDefinitionId to constant when moving into wix code
    const wixCodeApp = _.find(siteAPI.getSiteData().getClientSpecMap(), {appDefinitionId: WIX_CODE_APP_DEF_ID})
    if (!_.get(wixCodeApp, 'instance')) {
        callback(null, 'wix code is not enabled')
        return
    }
    const baseUrl = siteAPI.getSiteData().getExternalBaseUrl().replace(/\/$/, '')
    coreUtils.ajaxLibrary.ajax({
        url: `${baseUrl}/_api/members-groups-web/v1/groups/users/current?include_implicit_groups=true`,
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        processData: false,
        headers: {
            Authorization: wixCodeApp.instance
        },
        success(data) {
            const memberRoles = platformizedEndpointsUtils.members.serializeMemberRoles(data)
            callback(memberRoles)
        },
        error(xhrError) {
            processXhrError(xhrError, callback)
        }
    })
}

const googleAnalytics = function (siteAPI, msg) {
    thirdPartyAnalytics.reportGoogleAnalytics.apply(null, [siteAPI.getSiteData()].concat(msg.data))
}

const sendMessageToParent = function (siteAPI, msg) {
    if (window.parent !== window && siteAPI.getSiteData().isViewerMode()) {
        window.parent.postMessage(msg.data, '*')
    }
}

const processXhrError = (xhrError, callback) => {
    const {status, responseText} = xhrError || {}
    callback(null, {status, responseText})
}

const openMessageChannel = function (siteAPI, msg, cb, fromApp) {
    if (window.parent !== window && siteAPI.getSiteData().isViewerMode()) {
        let messageData = msg.data
        if (_.isString(messageData)) {
            messageData = JSON.parse(msg.data)
        }
        const wixCodeMessageChannelAspect = siteAPI.getSiteAspect('wixCodeMessageChannelAspect')
        const channel = wixCodeMessageChannelAspect.getMessageChannel(messageData.appDefinitionId)
        cb({appDefinitionId: messageData.appDefinitionId}, null, fromApp ? [channel.port2] : [channel.port1])
    } else {
        cb(null, 'DID NOT CREATE MESSAGE CHANNEL')
    }
}

const sendUserEmail = function (siteAPI, msg, callback) {
    // FIXME - remove shoutout appDefinitionId to constant when moving into wix code
    const shoutoutApp = _.find(siteAPI.getSiteData().getClientSpecMap(), {appDefinitionId: '135c3d92-0fea-1f9d-2ba5-2a1dfb04297e'})
    if (!_.get(shoutoutApp, 'instance')) {
        callback(null, 'shoutout app not installed')
        return
    }
    const {emailId, contactId, options, type} = msg.data
    if (type === 'user' && !siteAPI.getSiteAspect('siteMembers').isLoggedIn()) {
        callback(null, 'User must be logged-in in order to send an email')
        return
    }
    const baseUrl = siteAPI.getSiteData().getExternalBaseUrl().replace(/\/$/, '')
    coreUtils.ajaxLibrary.ajax({
        url: `${baseUrl}/_api/shoutout/v1/emailContact`,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({emailId, contactId, options}),
        processData: false,
        headers: {Authorization: shoutoutApp.instance},
        success() {
            callback()
        },
        error(xhr) {
            processXhrError(xhr, callback)
        }
    })
}

const smRegisterUser = function (siteAPI, msg, callback) {
    const email = _.get(msg, ['data', 'email'])
    const password = _.get(msg, ['data', 'password'])
    const defaultFlow = _.get(msg, ['data', 'defaultFlow'])
    const rawContactInfo = _.get(msg, ['data', 'contactInfo'], {})
    const contactInfo = platformizedEndpointsUtils.contacts.serializeContactInfo(rawContactInfo)
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')

    siteMemberAspect.registerUser({email, password, contactInfo, defaultFlow}, (data, xhrError) => {
        if (!data) {
            processXhrError(xhrError, callback)
            return
        }
        const res: any = {
            status: data.member.status === 'ACTIVE' ? 'ACTIVE' : 'PENDING',
            memberData: _.omit(data.member, 'status')
        }
        if (data.approvalToken) {
            res.approvalToken = data.approvalToken
        }
        callback(res)
    })
}

const smLoginUser = function (siteAPI, msg, callback) {
    const userEmail = _.get(msg, ['data', 'email'])
    const userPassword = _.get(msg, ['data', 'password'])
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')
    siteMemberAspect.loginUser(userEmail, userPassword, (sessionToken, xhrError) => {
        if (xhrError) {
            processXhrError(xhrError, callback)
            return
        }
        callback()
    })
}

const smApplySessionToken = function (siteAPI, msg, callback) {
    const token = _.get(msg, ['data', 'token'])
    const siteMemberAspect = siteAPI.getSiteAspect('siteMembers')
    siteMemberAspect.authenticateSession(token, err => {
        if (err) {
            callback(null, 'sessionToken token is invalid')
            return
        }
        callback()
    })
}

const mlSetLanguageCode = function (siteAPI, msg) {
    const {multilingual} = warmupUtils

    const siteDataAPI = siteAPI.getSiteDataAPI()
    const dal = siteAPI.getDisplayedDAL()
    const pointers = siteAPI.getPointers()
    const setCurrentLanguage = multilingual.setCurrentLanguage({
        siteDataAPI,
        dal,
        pointers,
        siteAPI
    })

    const {languageCode} = msg.data
    setCurrentLanguage(languageCode)
}

const crmApplySession = function (siteAPI, msg, callback) {
    const {data} = msg
    siteAPI.setUserSession(data.svSession)
    const dynamicClientSpecMapAspect = siteAPI.getSiteAspect('dynamicClientSpecMap')
    dynamicClientSpecMapAspect.reloadClientSpecMap(() => callback(_.omit(data, 'svSession')))
}

const crmCreateContact = function (siteAPI, msg, callback) {
    const wixCodeApp = _.find(siteAPI.getSiteData().getClientSpecMap(), {appDefinitionId: WIX_CODE_APP_DEF_ID})
    if (!_.get(wixCodeApp, 'instance')) {
        callback(null, 'wix code is not enabled')
        return
    }
    const {contactInfo} = msg.data
    coreUtils.ajaxLibrary.ajax({
        url: '/_api/wix-contacts-webapp/v3/contacts',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({contact: platformizedEndpointsUtils.contacts.serializeContactInfo(contactInfo)}),
        processData: false,
        headers: {
            Authorization: wixCodeApp.instance
        },
        success(data) {
            if (!data.svSession) {
                callback(data)
                return
            }
            siteAPI.setUserSession(data.svSession)
            const dynamicClientSpecMapAspect = siteAPI.getSiteAspect('dynamicClientSpecMap')
            dynamicClientSpecMapAspect.reloadClientSpecMap(() => callback(_.omit(data, 'svSession')))
        },
        error(xhrError) {
            processXhrError(xhrError, callback)
        }
    })
}

const setPageMetadata = function (siteAPI, msg, callback) {
    const {title, description, jsonLd, metaTags} = msg.data

    if (title || description) {
        const pageData = siteAPI.getPageData()
        const currentPageTitle = _.get(pageData, 'title', '')
        const newDescriptionSeo = description || _.get(pageData, 'descriptionSEO', '')

        let newPageTitleSeo = _.get(pageData, 'pageTitleSEO', '')

        if (title) {
            const {addSiteName = true} = title
            let {text} = title
            text = (text || '').trim()

            newPageTitleSeo = text

            const siteName = _.invoke(siteAPI.getSiteData(), 'getSiteDisplayName')
            if (text.length === 0) {
                newPageTitleSeo = siteName
            } else if (addSiteName) {
                newPageTitleSeo = `${text} | ${siteName}`
            }
        }

        siteAPI.setRunTimePageTitle(currentPageTitle, newDescriptionSeo, newPageTitleSeo)
    }

    if (jsonLd) {
        siteAPI.setPageJsonldImmediate(jsonLd)
    }

    if (metaTags) {
        siteAPI.setPageMetaOgTags(metaTags)
    }

    callback()
}

const getComponentViewportState = function (siteAPI, msg, callback) {
    const viewportState = siteAPI.getSiteAspect('viewportChange').get(msg.compId)
    callback({in: _.get(viewportState, 'in')})
}

const setTPANativeComponentProps = function (siteAPI, msg) {
    const {controllerId, props} = msg.data
    siteAPI.getSiteAspect('tpaWidgetNativeAspect').setControllerProps(controllerId, props)
}

const notifyEventToEditorApp = function (siteAPI, msg) {
    const {appDefinitionId, eventData} = msg.data
    siteAPI.getSiteAspect('WidgetAspect').triggerNotifyApplicationRequest(appDefinitionId, eventData)
}

const resetBehaviors = function (siteAPI, msg, callback) {
    const behaviorsAspect = siteAPI.getSiteAspect('behaviorsAspect')
    behaviorsAspect.resetBehaviorsForActions(msg.data.compId)
    callback()
}

// Timeline API
const animTimelineCreate = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.createTimeline(_.get(message, ['data', 'id']), _.get(message, 'data')).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const animTimelineAdd = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.addToTimeline(_.get(message, ['data', 'id']), _.get(message, 'data')).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const animTimelinePlay = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.playTimeline(_.get(message, ['data', 'id'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}
const animTimelinePlayReverse = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.playReverseTimeline(_.get(message, ['data', 'id'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const animTimelinePause = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.pauseTimeline(_.get(message, ['data', 'id'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const animTimelineClear = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.clearTimeline(_.get(message, ['data', 'id'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const animTimelineSeek = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.seekTimeline(_.get(message, ['data', 'id']), _.get(message, ['data', 'progress'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}
//TODO: Timeline Events
const animTimelineAddEvent = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    const widgetHandler = siteAPI.getSiteAspect('WidgetAspect').getWidgetHandler()
    const {data = {}, contextId} = message
    const {id, eventName, type} = data
    const timelineEventDispatch = () => {
        widgetHandler._sendMessage({
            intent: 'WIX_CODE_ANIMATE',
            type,
            contextId
        })
    }
    timelineAspect.addTimelineEvent(id, eventName, timelineEventDispatch).then(
        success => callback(success),
        error => callback(null, error)
    )
    //() => widgetHandler.sendTimelineEventMessage(_.get(message, ['contextId']), _.get(message, ['data', 'type']))
    //getTimelineEventDispatch(message.getIn(['data', 'type']))
}
const animTimelineRemoveEvent = function (siteAPI, message, callback) {
    const timelineAspect = siteAPI.getSiteAspect('TimelineAspect')
    timelineAspect.removeTimelineEvent(_.get(message, ['data', 'id']), _.get(message, ['data', 'eventName'])).then(
        success => callback(success),
        error => callback(null, error)
    )
}

const buildCustomizedUrl = function (siteAPI, message, callback) {
    callback(undefined)
}

const getCustomizedUrlSegments = function (siteAPI, message, callback) {
    callback(undefined)
}

export default {
    windowBoundingRect,
    navigateTo,
    postHtmlMessage: postHTMLMessage,
    openLightbox,
    closeLightbox,
    getRoutersSiteMap,
    startFileUpload,
    getCurrentGeolocation,
    scrollToComponent,
    smCurrentUser,
    smCurrentUserRoles,
    smLogout,
    smForgotPassword,
    googleAnalytics,
    sendMessageToParent,
    sendUserEmail,
    openMessageChannel,
    smRegisterUser,
    smLoginUser,
    smApplySessionToken,
    mlSetLanguageCode,
    crmCreateContact,
    resetBehaviors,
    crmApplySession,
    setPageMetadata,
    publishPubSubEvent: pubSubHandlers.publish,
    subscribePubSubEvent: pubSubHandlers.subscribe,
    unsubscribePubSubEvent: pubSubHandlers.unsubscribe,
    getComponentViewportState,
    setTPANativeComponentProps,
    resetCaptcha,
    notifyEventToEditorApp,
    animTimelineCreate,
    animTimelineAdd,
    animTimelinePlay,
    animTimelinePlayReverse,
    animTimelinePause,
    animTimelineSeek,
    animTimelineClear,
    animTimelineAddEvent,
    animTimelineRemoveEvent,
    buildCustomizedUrl,
    getCustomizedUrlSegments
}
