import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import siteStructureUtils from './siteStructureUtils'

function getPageDataForRmi() {
    const currPageId = this.getPrimaryPageId()
    const rootNavigationInfo = this.getRootNavigationInfo()
    const currPageSEOMetaData = this.getCurrentPageSEOMetaData(currPageId)
    const pageInfo = {
        currentNavigationInfo: rootNavigationInfo,
        currentUrlPageTitle: this.getCurrentUrlPageTitle(),
        currentPageSEODescription: currPageSEOMetaData.description || '',
        currentPageSEOKeywords: currPageSEOMetaData.keywords || ''
    }

    return pageInfo
}

function getSiteStructureForRmi() {
    const routers = this.routers || {}
    const pages = siteStructureUtils.getPages(this.getPagesDataItems(), this.isPopupPage, this.getMainPageId(), routers, this.getClientSpecMap(), {
        includeBlankUrl: true,
        includeAppsHiddenPages: true
    })
    const lightboxes = siteStructureUtils.getLightboxes(this.getAllPageIds(), this.getDataByQuery)
    const routerPrefixes = siteStructureUtils.getRouterPrefixes(routers)
    const appPrefixes = siteStructureUtils.getAppPrefixes(pages)
    // @ts-ignore
    const prefixes = routerPrefixes.concat(appPrefixes)
    return {
        pages,
        prefixes,
        lightboxes
    }
}

function getAppStudioWidgetProperties(contextId) {
    const masterPageData = this.getMasterPageData(['data', this.dataTypes.DATA])
    const widgetData = _.find(masterPageData, {
        type: 'WidgetDescriptor',
        rootCompId: `#${contextId}`
    })

    if (!widgetData) {
        return {}
    }

    const propertiesSchemas = _.get(widgetData, ['widgetApi', 'propertiesSchemas'], [])
    return _(propertiesSchemas)
        .keyBy(prop => _.head(_.keys(prop.structure)))
        .mapValues(prop => {
            const key = _.head(_.keys(prop.structure))
            const data = _.assign({}, ..._.get(prop, ['structure', key, 'allOf'], []))
            return warmupUtilsLib.parseValueByPropertyType(_.get(data, ['default']), getPropertiesSchemaType(data))
        })
        .value()
}

const getPropertiesSchemaType = data => {
    const type = _.get(data, ['$ref'], '').substring(1) || data.type
    if (type === 'boolean') {
        return 'bool'
    }
    return type
}

export default {
    bind: ['getPageDataForRmi', 'getSiteStructureForRmi', 'getAppStudioWidgetProperties'],
    getPageDataForRmi,
    getSiteStructureForRmi,
    getAppStudioWidgetProperties
}
