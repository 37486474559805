function getAnimationsAspect(siteAPI) {
    return siteAPI.getSiteAspect('animationsAspect')
}

function playAnimations(siteAPI, animationGroup, animations, clear, callback) {
    const animationsAspect = getAnimationsAspect(siteAPI)

    if (animationsAspect) {
        return animationsAspect.playAnimations(animationGroup, animations, clear, callback)
    }

    return callback && callback()
}

function hideElementsByAnimationType(siteAPI, animations) {
    const animationsAspect = getAnimationsAspect(siteAPI)

    if (animationsAspect) {
        animationsAspect.hideElementsByAnimationType(animations)
    }
}

function revertHideElementsByAnimations(siteAPI, animations) {
    const animationsAspect = getAnimationsAspect(siteAPI)

    if (animationsAspect) {
        animationsAspect.revertHideElementsByAnimations(animations)
    }
}

function stopAndClearAnimations(siteAPI, animationGroup, seek) {
    const animationsAspect = getAnimationsAspect(siteAPI)

    if (animationsAspect) {
        animationsAspect.stopAndClearAnimations(animationGroup, seek)
    }
}

function isAnimationPlayable(siteAPI, animation) {
    const animationsAspect = getAnimationsAspect(siteAPI)
    return animationsAspect.isAnimationPlayable(animation)
}

export default {
    playAnimations,
    hideElementsByAnimationType,
    revertHideElementsByAnimations,
    stopAndClearAnimations,
    isAnimationPlayable
}
