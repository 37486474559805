define([], function () {
    'use strict'

    const TASK_NAME = 'saveCode'

    return {
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        }
    }
})
