define(['lodash'], function (_) {
    'use strict'

    const IS_UPDATING_ANCHORS = {
        YES: 'yes',
        NO: 'no',
        DONT_CARE: "don't care"
    }

    const ENFORCE_TYPES = {
        NONE: 'NONE',
        INNER: 'INNER',
        FULL: 'FULL',
        DONT_CARE: 'DONT_CARE'
    }

    function getEnforceTypeForDataManipulation(isUpdatingAnchors) {
        switch (isUpdatingAnchors) {
            case IS_UPDATING_ANCHORS.YES:
                return ENFORCE_TYPES.NONE
            case IS_UPDATING_ANCHORS.NO:
                return ENFORCE_TYPES.FULL
            default:
                return ENFORCE_TYPES.DONT_CARE
        }
    }

    /**
     * @param {ps} ps
     * @param components
     * @param methodDefinition
     * @returns {boolean|*}
     */
    function shouldUpdateSingleComp(ps, components, methodDefinition) {
        if (components) {
            const singleComp = _.isFunction(methodDefinition.singleComp) ? methodDefinition.singleComp(ps, components) : methodDefinition.singleComp
            if (singleComp) {
                if (_.isArray(components) && components.length === 1) {
                    components = components[0]
                }
                if (components.id) {
                    return components.id
                }
                if (_.isString(components)) {
                    return components
                }
            }
        }
        return false
    }

    function getShouldUpdateSingleCompFunc(ps, methodDefinition, methodArgs) {
        const components = methodArgs[1]

        return shouldUpdateSingleComp.bind(null, ps, components, methodDefinition)
    }

    function getLockedComp(methodDefinition, methodArgs) {
        const components = methodArgs[1]

        return methodDefinition.shouldLockComp && components ? components.id : null
    }

    function shouldUpdateAnchors(ps, isUpdatingAnchors, methodArgs) {
        const components = methodArgs[1]

        const updatingAnchors = _.isFunction(isUpdatingAnchors) ? isUpdatingAnchors(ps, components, methodArgs) : isUpdatingAnchors

        if (_.isString(updatingAnchors)) {
            return updatingAnchors
        }

        return updatingAnchors ? IS_UPDATING_ANCHORS.YES : IS_UPDATING_ANCHORS.NO
    }

    function getDataManipulationParams(ps, dataManipulationDefinition, methodName, currentContext, args) {
        const isUpdatingAnchors = shouldUpdateAnchors(ps, dataManipulationDefinition.isUpdatingAnchors, args)

        return {
            methodName,
            asyncPreDataManipulation: dataManipulationDefinition.asyncPreDataManipulation,
            noRefresh: dataManipulationDefinition.noRefresh,
            noBatching: dataManipulationDefinition.noBatching,
            noBatchingAfter: dataManipulationDefinition.noBatchingAfter,
            shouldExecOp: dataManipulationDefinition.shouldExecOp,
            isAsyncOperation: dataManipulationDefinition.isAsyncOperation,
            waitingForTransition: dataManipulationDefinition.waitingForTransition,
            lockedComp: getLockedComp(dataManipulationDefinition, args),
            singleComp: getShouldUpdateSingleCompFunc(ps, dataManipulationDefinition, args),
            isUpdatingAnchors,
            enforceType: getEnforceTypeForDataManipulation(isUpdatingAnchors),
            currentContext,
            updateData: true
        }
    }

    function getImmediateActionParams(ps, methodName, currentContext, isUpdatingData) {
        return {
            methodName,
            currentContext,
            isUpdatingData
        }
    }

    return {
        IS_UPDATING_ANCHORS,
        ENFORCE_TYPES,
        getDataManipulationParams,
        getImmediateActionParams
    }
})
