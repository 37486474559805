import _ from 'lodash'
import wixappsConfiguration from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsConfiguration'

/**
 * @class wixappsCore.wixappsPlugins
 */
export default {
    getAdditionalDomAttributes(proxy) {
        let additionalDomProperties = {}
        if (wixappsConfiguration.shouldApplyAutomationAttributes(proxy.props.viewProps)) {
            additionalDomProperties = _.merge(additionalDomProperties, {
                'data-vcview': proxy.props.viewName,
                'data-vcfield': proxy.getViewDefProp('id'),
                'data-vctype': proxy.props.forType,
                'data-proxy': proxy.constructor.displayName,
                'data-field-name': proxy.getViewDefProp('data')
            })
        }
        return additionalDomProperties
    }
}
