define(['documentServices/appStudio/appStudioDataModel', 'documentServices/dataModel/dataModel'], function (appStudioDataModel, dataModel) {
    'use strict'

    const CODE_PACKAGES_TYPE = 'CodePackagesDescriptor'

    function createCodePackagesData(ps, {displayName, name, description}) {
        const codePackage = dataModel.createDataItemByType(ps, CODE_PACKAGES_TYPE)
        codePackage.displayName = displayName
        codePackage.name = name
        codePackage.description = description

        return codePackage
    }

    function prepareCodePackagesData(ps, codePackages) {
        return codePackages.map(codePackage => createCodePackagesData(ps, codePackage))
    }

    function getCodePackagesData(ps) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)

        return appStudioData.codePackages
    }

    function addCodePackagesData(ps, codePackages) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        const codePackagesData = prepareCodePackagesData(ps, codePackages)
        appStudioData.codePackages = appStudioData.codePackages ? [...appStudioData.codePackages, ...codePackagesData] : codePackagesData

        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function removeCodePackagesData(ps, packageName) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        appStudioData.codePackages = appStudioData.codePackages.filter(codePackage => codePackage.name !== packageName)

        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function setCodePackagesData(ps, codePackages) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        appStudioData.codePackages = prepareCodePackagesData(ps, codePackages)

        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    return {
        getCodePackagesData,
        addCodePackagesData,
        setCodePackagesData,
        removeCodePackagesData
    }
})
