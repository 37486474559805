import _ from 'lodash'
import type {CoreLogger} from '@wix/document-manager-core'
import type {BIEvt, FedopsLogger, TagsAndExtras} from '@wix/document-services-types'

const MAX_CONTEXT_LENGTH = 2000

type GetErrorContext = () => any

type GetInteractionContext = Record<string, GetErrorContext>

const verifyContextSize = (context: any) => {
    try {
        const value = JSON.stringify(context) // just verifying that this is a serializable structure
        if (value.length > MAX_CONTEXT_LENGTH) {
            return {badContext: `Context is bigger than ${MAX_CONTEXT_LENGTH} characters: ${value.substr(0, 50)}`}
        }
        return context
    } catch (e: any) {
        return {badContext: e.message}
    }
}

const getInteractionContext = (interactionContextProvider: GetInteractionContext[]) => {
    const getContexts = (obj: GetInteractionContext) => _.mapValues(obj, f => verifyContextSize(f()))
    return _(interactionContextProvider).map(getContexts).reduce(_.merge)
}

export interface AdapterLoggerOptions {
    defaultTags?: Record<string, string | boolean | undefined>
    defaultOverrides?: Record<string, string | undefined>
}

const EVENT_KEYS = ['tags', 'extras', 'isDraft']
const PROPS_TO_PROMOTE_FROM_EXTRAS = ['component_type']

type ReportBI = (event: BIEvt, params: Record<string, any>) => void

export interface AdapterLogger extends CoreLogger {
    registerSendBiFunction(func: ReportBI): void

    registerContextProvider(contextProvider: GetErrorContext): void

    registerInteractionContextProvider(interactionContextProvider: GetInteractionContext): void
}

interface ErrorParams {
    errorType: string
    tags: Record<string, any>
    extras: Record<string, any>
    fingerprint?: ReadonlyArray<string>
    defaultOverrides?: Record<string, string>
}

const createAdapterLogger = (fedopsLogger: FedopsLogger, options: AdapterLoggerOptions): AdapterLogger => {
    let reportBIFunc: ReportBI
    const interactionContextProviders: GetInteractionContext[] = []
    const contextProviders: GetErrorContext[] = []

    const registerSendBiFunction = (func: ReportBI) => {
        reportBIFunc = func
    }

    const registerInteractionContextProvider = (interactionContextProvider: GetInteractionContext) => {
        interactionContextProviders.push(interactionContextProvider)
    }

    const registerContextProvider = (contextProvider: GetErrorContext) => {
        contextProviders.push(contextProvider)
    }

    const reportBI = (event: BIEvt, params: Record<string, any>) => reportBIFunc?.(event, params)

    const defaultTags = (): Record<string, any> => options.defaultTags ?? {}

    const defaultOverrides = (): Record<string, any> => options.defaultOverrides ?? {}

    const defaultParamsOverrides = (): Record<string, any> => ({
        paramsOverrides: _.defaultsDeep({}, getInteractionContext(interactionContextProviders), {
            tags: defaultTags()
        })
    })

    const getErrorContext = () => {
        const safeContextValues = _.map(contextProviders, (getContextError: GetErrorContext) => {
            const context = getContextError()
            return verifyContextSize(context)
        })

        return _.merge({}, ...safeContextValues)
    }

    // This function is aware of ReportableError, but not enforcing it for backward compatibility
    const getErrorParams = (error: Error, op?: TagsAndExtras): ErrorParams => ({
        errorType: _.get(error, ['errorType'], 'unknown'),
        tags: _.assign({}, defaultTags(), _.get(error, ['tags']), op?.tags),
        extras: _.assign({}, _.get(error, ['extras']), op?.extras),
        fingerprint: op?.fingerprint
    })

    const sendInteractionError = (error: Error, params: ErrorParams) => {
        const errorContext = getErrorContext()

        const payload = {
            paramsOverrides: {
                evid: 36,
                errorInfo: error.message,
                errorType: params.errorType,
                errorTags: params.tags,
                errorExtra: params.extras,
                errorContext,
                ...defaultOverrides()
            }
        }

        fedopsLogger.interactionStarted('error', payload)
    }

    const sendSentryError = (error: Error, params: ErrorParams): void => {
        const paramsWithErrorTypeTag = {
            ...params,
            tags: _.assign({}, params.tags, {errorType: params.errorType}),
            groupErrorsBy: 'values'
        }
        fedopsLogger.captureError(error, paramsWithErrorTypeTag)
    }

    const captureError = (error: Error, op?: TagsAndExtras): void => {
        const params = getErrorParams(error, op)
        sendSentryError(error, params)
        sendInteractionError(error, params)
    }

    type InteractionFunc = (name: string, options?: Record<string, any>) => void

    const propagateFromExtras = (params?: Record<string, any>) => ({
        paramsOverrides: _.pick(params?.extras, PROPS_TO_PROMOTE_FROM_EXTRAS)
    })

    const interactionWithParams = (func: InteractionFunc, name: string, params?: Record<string, any>) => {
        const paramsWithTagsAndExtras = _.merge(
            {},
            defaultParamsOverrides(),
            propagateFromExtras(params),
            {
                paramsOverrides: _.pick(params, EVENT_KEYS)
            },
            {
                paramsOverrides: defaultOverrides()
            },
            params
        )

        func(name, paramsWithTagsAndExtras)
    }

    return {
        captureError,
        interactionStarted: (name: string, params?: Record<string, any>) => interactionWithParams(fedopsLogger.interactionStarted, name, params),
        interactionEnded: (name: string, params?: Record<string, any>) => interactionWithParams(fedopsLogger.interactionEnded, name, params),
        breadcrumb: fedopsLogger.breadcrumb,
        flush: fedopsLogger.flushAllFedOpsLoggers,
        registerInteractionContextProvider,
        reportBI,
        registerSendBiFunction,
        registerContextProvider
    }
}

export {createAdapterLogger}
