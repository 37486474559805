define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'platformInit',
    'documentServices/page/pageData',
    'experiment',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter'
], function (_, coreUtils, platformInit, pageData, experiment, extensionsAPI, contextAdapter) {
    'use strict'

    const MEMBERS_APP_DEFINITION_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'

    const SITE_STRUCTURE_SERVICE_ENDPOINT = '/_api/site-structure-server/v1/site-structure'

    const REQUEST_TYPES = {
        PAGES: 'PAGES',
        SITE_MAP: 'SITE_MAP',
        SITE_MAP_COUNT: 'SITE_MAP_COUNT'
    }

    const REQUEST_ROUTES = {
        PAGES: '/pages',
        SITE_MAP: '/sitemap',
        SITE_MAP_COUNT: '/sitemapCount'
    }

    const isDprEnabled = () => experiment.isOpen('specs.wixData.DynamicPagesRouter', null)

    const getRoutersPathConfig = instanceId => ({
        'wix-code': `https://${instanceId}.dev.wix-code.com/routers/custom`,
        dataBinding: isDprEnabled() ? '/_api/dynamic-pages-router/v1' : `https://${instanceId}.dev.wix-code.com/routers/data-binding`
    })

    function getWixCodeRouterPath({appDefinitionId, instanceId}) {
        const resolvedRoutersPaths = getRoutersPathConfig(instanceId)
        return resolvedRoutersPaths[appDefinitionId]
    }

    function getRouterPath(currentUrl, clientSpecMap, appDefinitionId) {
        const clientSpecMapForApplication = _.find(clientSpecMap, {appDefinitionId})
        const routerPath = clientSpecMapForApplication.appFields.platform.routerServiceUrl

        if (appDefinitionId === MEMBERS_APP_DEFINITION_ID) {
            return routerPath
        }

        return routerPath
    }

    function getBackendRequestUrl(routerPath, currentUrl, requestType) {
        const domainForRouter = currentUrl.host

        const requestRoute = REQUEST_ROUTES[requestType]

        if (/^\//.test(routerPath)) {
            return `${currentUrl.protocol}//${domainForRouter}${routerPath}${requestRoute}`
        }
        return `${routerPath}${requestRoute}`
    }

    function getUrlParams(clientSpecMap, appDefinitionId) {
        const params = ['viewMode=editor']

        const appSpec = platformInit.specMapUtils.getAppSpec(clientSpecMap, appDefinitionId)
        if (appSpec) {
            params.push(`instance=${appSpec.instance}`)
        }

        return `?${params.join('&')}`
    }

    function getBackendRequestObject(pageRoles, suffix, routerDefinition, publicBaseUrl, formFactor, queryParams) {
        const routerConfig = _.isString(routerDefinition.config) ? JSON.parse(routerDefinition.config) : routerDefinition.config
        const routerSuffix = `/${suffix}`.replace(/^\/\//, '/')
        let fullUrl = `${publicBaseUrl}/${routerDefinition.prefix}${routerSuffix}`
        if (queryParams) {
            fullUrl = `${fullUrl}?${queryParams}`
        }
        //todo - temporarly send the /<prefix>/<suffix> in the fullUrl parameter, just until the router backend will be fixed to use the right parameters
        const result = {
            fullUrl,
            routerPrefix: `/${routerDefinition.prefix}`,
            routerSuffix,
            requestInfo: {
                formFactor
            },
            config: routerConfig,
            pageRoles
        }

        return result
    }

    function getCSRFTokenValue() {
        return coreUtils.cookieUtils.getCookie('XSRF-TOKEN')
    }

    function getWixCodeUrlParams(instance, gridAppId, appDefinitionId) {
        const viewMode = 'viewMode=editor'
        const _instance = `instance=${instance}`
        const _gridAppId = `gridAppId=${gridAppId}`

        const params = appDefinitionId === 'dataBinding' && isDprEnabled() ? [viewMode, _gridAppId] : [viewMode, _instance, _gridAppId]

        return `?${params.join('&')}`
    }

    function getWixCodeAjaxParameters({paramObj, requestType}) {
        const {
            currentUrl,
            clientSpecMap,
            routerDefinition: {appDefinitionId},
            rendererModel_wixCodeModel_appData_codeAppId
        } = paramObj

        const {instanceId, instance} = platformInit.specMapUtils.getAppSpec(clientSpecMap)
        const routerPath = getWixCodeRouterPath({appDefinitionId, instanceId})
        const urlParams = getWixCodeUrlParams(instance, rendererModel_wixCodeModel_appData_codeAppId, appDefinitionId)
        const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams

        const successCallback = routerResponse => {
            if (_.get(routerResponse, 'result._elementoryError')) {
                coreUtils.logWixCodeConsoleMessage(`Internal server error:${routerResponse.result.stack}`)
            }
        }

        const extraAjaxParams = {
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        }

        if (appDefinitionId === 'dataBinding' && isDprEnabled()) {
            _.assign(extraAjaxParams, {headers: {Authorization: instance}})
        }

        return {
            url,
            successCallback,
            extraAjaxParams
        }
    }

    function getAjaxParametersForRouterRequest({paramObj, requestType}) {
        const {appDefinitionId} = paramObj.routerDefinition

        if (/(wix-code|dataBinding)/.test(appDefinitionId)) {
            return getWixCodeAjaxParameters({paramObj, requestType})
        }

        const {currentUrl, clientSpecMap} = paramObj

        const routerPath = getRouterPath(currentUrl, clientSpecMap, appDefinitionId)
        const urlParams = getUrlParams(clientSpecMap, appDefinitionId)
        const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams

        return {
            url
        }
    }

    function getFromBackEnd(paramObj, requestType, onSuccess, onError) {
        const {url, successCallback, extraAjaxParams} = getAjaxParametersForRouterRequest({paramObj, requestType})

        const ajaxParams = {
            type: 'POST',
            dataType: 'json',
            url,
            data: getBackendRequestObject(
                paramObj.pageRoles,
                paramObj.suffix,
                paramObj.routerDefinition,
                paramObj.publicBaseUrl,
                paramObj.formFactor,
                paramObj.queryParams
            ),
            success(routerResponse) {
                if (successCallback) {
                    successCallback(routerResponse)
                }

                onSuccess(routerResponse)
            },
            error: onError,
            headers: {}
        }

        _.assign(ajaxParams, extraAjaxParams)
        _.assign(ajaxParams.headers, {'X-XSRF-TOKEN': getCSRFTokenValue()})

        coreUtils.ajaxLibrary.ajax(ajaxParams)
    }

    function makeParamObjFromPs(ps, routerDefinition) {
        const pages = _.map(pageData.getPagesList(ps, true), function (_pageId) {
            return {
                pageId: _pageId,
                title: pageData.getPageData(ps, _pageId).title
            }
        })
        const paramObj = {
            currentUrl: ps.siteAPI.getCurrentUrl(),
            clientSpecMap: ps.siteAPI.getClientSpecMap(),
            rendererModel_wixCodeModel_appData_codeAppId: extensionsAPI.wixCode.getEditedGridAppId(ps),
            routerDefinition,
            suffix: '',
            pageRoles: _.reduce(
                routerDefinition.pages,
                function (result, value, key) {
                    result[key] = {
                        id: value,
                        title: _.find(pages, {pageId: value}).title
                    }
                    return result
                },
                {}
            ),
            publicBaseUrl: ps.siteAPI.getPublicBaseUrl()
        }
        return paramObj
    }

    function cleanPrefixesFromSiteMap(siteMapEntries, prefix) {
        const reg = new RegExp(`.*?\/${prefix}`)
        _.forEach(siteMapEntries, function (entry) {
            if (entry && entry.url) {
                entry.url = entry.url.replace(reg, '')
                if (entry.url.charAt(0) === '/' && entry.url.length > 1) {
                    entry.url = entry.url.substring(1)
                }
            }
        })
    }

    function getInnerRoutesSiteMap(paramObj, onSuccess, onError) {
        getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP, onSuccess, onError)
    }

    function getInnerRoutesSiteMapCount(paramObj, onSuccess, onError) {
        getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP_COUNT, onSuccess, onError)
    }

    function getInnerRoutesSiteMapByRequestType(paramObj, requestType, onSuccess, onError) {
        function onSiteMapResponse(routerResponse) {
            if (routerResponse.exception) {
                onError(routerResponse.result)
                return
            }
            const siteMap = routerResponse.result
            cleanPrefixesFromSiteMap(siteMap, paramObj.routerDefinition.prefix)
            onSuccess(routerResponse.result)
        }

        getFromBackEnd(paramObj, requestType, onSiteMapResponse, onError)
    }

    /*
     * Expected request/result params can be viewed here:
     * https://github.com/wix-private/editor-server/blob/master/wix-site-structure/src/main/proto/com/wixpress/structure/api/site_structure_service.proto
     */
    function getTpaRoutesFromSiteStructure(ps, appDefinitionId, subPage, success, error) {
        const clientSpecMap = ps.siteAPI.getClientSpecMap()
        const instance = _.get(platformInit.specMapUtils.getAppSpec(clientSpecMap, appDefinitionId), 'instance', '')
        const {protocol, host} = ps.siteAPI.getCurrentUrl()
        const url = `${protocol}//${host}${SITE_STRUCTURE_SERVICE_ENDPOINT}`
        const data = {
            pageVersion: 1,
            filter: {
                itemTypeIdentifier: subPage,
                sourceId: appDefinitionId,
                configId: ''
            }
        }
        contextAdapter.actions.sendHttpRequest(
            url,
            'POST',
            {
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                headers: {
                    'X-XSRF-TOKEN': getCSRFTokenValue(),
                    Authorization: instance
                },
                data
            },
            data,
            success,
            error
        )
    }

    return {
        getInnerRoutesSiteMap,
        getInnerRoutesSiteMapCount,
        makeParamObjFromPs,
        getTpaRoutesFromSiteStructure
    }
})
