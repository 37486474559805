import _ from 'lodash'
import type {Pointer, PossibleViewModes} from '@wix/document-services-types'
import type {CreateExtArgs, Extension, HistoryItem, Transaction, ExtensionAPI, DeepFunctionMap, DmApis} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../../types'
import {VIEWER_PAGE_DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES} from '../../constants/constants'
import type {PageAPI} from '../page'
import {ReportableError} from '@wix/document-manager-utils'
import {viewerTransformSet} from './viewerTransformers'

const documentToViewerTypes = _.assign({}, VIEWER_PAGE_DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES, {
    rendererModel: 'rendererModel',
    platform: 'platform',
    wixCode: 'wixCode',
    documentServicesModel: 'documentServicesModel',
    pagesPlatformApplications: 'pagesPlatformApplications'
})

const NAVIGATION_ERROR_IDENTIFIER = 'viewer_extension_navigation_error_identifier'

const viewerManagerTypes = _.keyBy([
    'activeVariants',
    'activeModes',
    'renderFlags',
    'displayedOnlyComponents',
    'customElementsPointer',
    'renderRealTimeConfigPointer',
    'runtime',
    'svgShapes',
    'multilingual',
    'ghostStructure',
    'ghostControllers',
    'blocksPreviewData'
])

export interface InnerViewerExtensionAPI extends DeepFunctionMap {
    getPrimaryPageId(): string
    navigateToPage(pageId: string): Promise<void>
    getCurrentViewMode(): string
    syncViewer(pagesToSync?: PagesToSync, syncOnlyItemsWithoutPage?: boolean): void
    syncViewerFromOpenTransaction(): void
    syncPagesToViewer(pageIds: string[], viewMode?: PossibleViewModes): void
    getViewerName(): string
    getViewerVersion(): string
}

type ViewerSiteAPI = Record<string, Function>

export interface ViewerExtensionAPI extends ExtensionAPI {
    viewer: InnerViewerExtensionAPI
    siteAPI: ViewerSiteAPI
}

interface PagesToSync {
    [pageId: string]: boolean
}

const createExtension = ({viewerManager}: CreateViewerExtensionArgument): Extension => {
    const {dal: viewerManagerDal, pointers, viewerSiteAPI, actions} = viewerManager
    let pages: PageAPI | null = null

    const pagesApi = () => {
        if (!pages) {
            throw new Error('pagesApi accessed before it was initialized')
        }
        return pages
    }

    const getViewerActions = (documentTransaction: Transaction, pagesToSync: PagesToSync = {}, syncOnlyItemsWithoutPage: boolean = false): HistoryItem[] =>
        documentTransaction.items.filter(action => {
            const pageId = _.get(action, ['value', 'metaData', 'pageId'])
            if (syncOnlyItemsWithoutPage) {
                return !pageId
            }
            return !pageId || pageId === 'masterPage' || (!_.isEmpty(pagesToSync) ? pagesToSync[pageId] : viewerSiteAPI.getViewerLoadedPagesIds()[pageId])
        })

    const updateViewerManager = (dmApis: DmApis, viewerActions: HistoryItem[], viewMode?: PossibleViewModes) => {
        // Excluding non current view modes
        const currentViewMode = viewerManager.viewerSiteAPI.getViewMode()
        const viewModeToFilter = viewMode ?? currentViewMode
        const ignoredViewModes = _(VIEW_MODES)
            .values()
            .filter(_viewMode => _viewMode !== viewModeToFilter)
            .value()

        // TODO: {@see DM-3109} runInBatch impl in viewer-manager-adapter
        actions.runInBatch(() => {
            viewerActions.forEach(action => {
                // Only sending specific types and relevant types
                const {key, value} = action
                const updateDocumentToViewerType = documentToViewerTypes[key.type] && !ignoredViewModes.includes(key.type)
                const updateViewerOnlyType = viewerManagerTypes[key.type]
                if (updateDocumentToViewerType || updateViewerOnlyType) {
                    if (!_.isNil(value)) {
                        viewerTransformSet(dmApis, viewerManagerDal, key, value)
                    } else {
                        viewerManagerDal.remove(key)
                    }
                }
            })
        })
    }

    const getMainPageId = (): string => {
        if (!pages) {
            throw new ReportableError({
                errorType: 'PAGES_API_NOT_AVAILABLE',
                message: "The pages api isn't available yet, so we can't get the id of the main page"
            })
        }
        return pagesApi().getMainPageId()
    }

    const getCurrentPageId = (): string => viewerSiteAPI.getPrimaryPageId()

    const getFocusId = (): string => viewerSiteAPI.getFocusedRootId()

    const isFocusedOnPopup = (): boolean => getFocusId() === getCurrentPageId()

    const isFocusBeingDeleted = (viewerActions: HistoryItem[]): boolean =>
        _.some(viewerActions, {
            key: {
                id: getFocusId(),
                type: viewerManager.viewerSiteAPI.getViewMode()
            },
            value: undefined
        })

    const getCurrentViewMode = (): string => viewerSiteAPI.getViewMode()

    const navigateToPage = (pageId: string): Promise<void> => {
        const navInfoPointer = pointers.runtime.getWantedNavInfo()

        return new Promise<void>((resolve, reject) => {
            viewerManagerDal.set(navInfoPointer, {pageId})
            viewerSiteAPI.registerNavigationComplete(resolve)
            viewerSiteAPI.registerNavigationError(NAVIGATION_ERROR_IDENTIFIER, reject)
        }).finally(() => {
            viewerSiteAPI.unregisterNavigationError(NAVIGATION_ERROR_IDENTIFIER)
        })
    }

    const chooseAutoNavigationDestination = (viewerActions: HistoryItem[]): string | null => {
        if (isFocusBeingDeleted(viewerActions)) {
            return isFocusedOnPopup() ? getMainPageId() : getCurrentPageId()
        }
        return null
    }

    const createPostTransactionOperations = (dmApis: DmApis) => ({
        updateViewer: (documentTransaction: Transaction) => {
            const viewerActions = getViewerActions(documentTransaction, {}, false)
            const autoNavigationDestination = chooseAutoNavigationDestination(viewerActions)
            if (autoNavigationDestination) {
                return async () => {
                    await navigateToPage(autoNavigationDestination)
                    updateViewerManager(dmApis, viewerActions)
                }
            }
            updateViewerManager(dmApis, viewerActions)
        }
    })

    const getViewerActionsAndUpdateViewerManager = (
        dmApi: DmApis,
        documentTransaction: Transaction,
        pagesToSync: PagesToSync = {},
        syncOnlyItemsWithoutPage: boolean = false
    ) => {
        const viewerActions = getViewerActions(documentTransaction, pagesToSync, syncOnlyItemsWithoutPage)
        updateViewerManager(dmApi, viewerActions)
    }
    const createPointersMethods = () => pointers

    const createExtensionAPI = (createExtArgs: CreateExtArgs): ViewerExtensionAPI => {
        const {extensionAPI, dal} = createExtArgs
        pages = extensionAPI.page as PageAPI
        const siteAPI = viewerSiteAPI as unknown as ViewerSiteAPI

        const syncViewer = (pagesToSync?: PagesToSync, syncOnlyItemsWithoutPage?: boolean) =>
            getViewerActionsAndUpdateViewerManager(createExtArgs, dal.getTentativeAndAcceptedAsTransaction(), pagesToSync, syncOnlyItemsWithoutPage)

        /* The clone is needed here since bolt is sending these data through window.postMessage, which cannot serialize proxies.
           See ticket https://jira.wixpress.com/browse/DM-5124.
           This can and should be removed when thunderbolt is open to all.
           If the performance impact is too severe, we can relax the restriction in the dal that always exports proxies,
           since this is the only flow that uses `dal.getCurrentOpenTransaction`
         */
        const syncViewerFromOpenTransaction = () => getViewerActionsAndUpdateViewerManager(createExtArgs, _.cloneDeep(dal.getCurrentOpenTransaction()))

        const syncPagesToViewer = (pageIds: string[], viewMode?: PossibleViewModes) => {
            const values = _.flatMap(pageIds, pageId => {
                const pageCompFilter = pagesApi().getPageIndexId(pageId)
                const pageCompInStoreIndex = dal.getIndexed(pageCompFilter)
                return _.flatMap(pageCompInStoreIndex, (namespaceResults, type: string) => _.map(namespaceResults, (value, id) => ({key: {id, type}, value})))
            })
            updateViewerManager(createExtArgs, values, viewMode)
        }

        const getViewerName = () => viewerManager.viewerConfig.viewerName

        const getViewerVersion = () => viewerManager.viewerConfig.viewerVersion

        return {
            viewer: {
                getPrimaryPageId: getCurrentPageId,
                navigateToPage,
                getCurrentViewMode,
                syncViewer,
                syncViewerFromOpenTransaction,
                syncPagesToViewer,
                getViewerName,
                getViewerVersion
            },
            siteAPI
        }
    }

    const getter = ({}, pointer: Pointer) => viewerManagerDal.get(pointer, false) //purposely not cloned, as the clone will happen in the GeneralSuperDAL
    const setter = ({}, pointer: Pointer, value: any) => viewerManagerDal.set(pointer, value)
    setter.avoidInConflictDetection = true

    const createGetters = () =>
        _(viewerManagerTypes)
            .keyBy(val => val)
            .mapValues(() => getter)
            .value()

    return {
        name: 'viewerExtension',
        createPointersMethods,
        createPostTransactionOperations,
        createExtensionAPI,
        createGetters
    }
}

export {createExtension, documentToViewerTypes, viewerManagerTypes}
