import _ from 'lodash'
import $ from 'zepto'
import warmupUtilsLib from '@wix/santa-core-utils'
import experiment from 'experiment'

const siteUtilsLayout = warmupUtilsLib.layoutUtils
const {unitize} = warmupUtilsLib.style

const classBasedPatchers = {}
const classBasedCustomMeasures = {}
const classBasedDomMeasure = {}
const classBasedPureDomWidthMeasure = {}
const classBasedPureDomHeightMeasure = {}
const classBasedMeasureChildren = {}

const classBasedAdditionalMeasure = {}

const additionalPatchersWeakMap = new WeakMap()
const {ANCHORS} = warmupUtilsLib.siteConstants.LAYOUT_MECHANISMS

const setConcat =
    weakMap =>
    key =>
    (arr = []) => {
        const prev = weakMap.get(key) || []
        weakMap.set(key, prev.concat(arr))
    }

const additionalPatchersMapSetConcat = setConcat(additionalPatchersWeakMap)

const classBasedCustomLayoutFunctions = {}

function isCompRenderedInFixedPosition(style) {
    return style.position === 'fixed'
}

function shouldOnlyMeasureDomWidth(structure) {
    return classBasedPureDomWidthMeasure[structure.componentType] || siteUtilsLayout.isHorizontallyStretched(structure.layout)
}

function shouldClassBasedMeasureDomWidth(structure) {
    return classBasedDomMeasure[structure.componentType]
}

function shouldOnlyMeasureDomHeight(structure) {
    return classBasedPureDomHeightMeasure[structure.componentType] || siteUtilsLayout.isVerticallyStretched(structure.layout)
}

function shouldClassBasedMeasureDomHeight(structure) {
    return classBasedDomMeasure[structure.componentType]
}

function measureComponentZIndex(measureMap, compId, style) {
    let {zIndex} = style
    if (zIndex !== 'auto') {
        zIndex = parseFloat(zIndex)
        if (!isNaN(zIndex)) {
            measureMap.zIndex[compId] = zIndex
        }
    }
}

function measurePositionForFixedComp(measureMap, compId, domNode, style) {
    if (isCompRenderedInFixedPosition(style)) {
        measureMap.fixed[compId] = true
        measureMap.top[compId] = domNode.offsetTop
        measureMap.left[compId] = domNode.offsetLeft
    }
}

function measureComponentWidth(measureMap, compId, domNode, structure) {
    const structureWidth = _.get(structure, 'layout.width', 0)

    if (shouldOnlyMeasureDomWidth(structure)) {
        measureMap.width[compId] = domNode.offsetWidth
    } else if (shouldClassBasedMeasureDomWidth(structure) || !_.get(structure, 'layout.width')) {
        measureMap.width[compId] = Math.max(domNode.offsetWidth, structureWidth)
    } else {
        measureMap.width[compId] = structureWidth
    }
}

function measureComponentTop(measureMap, compId, domNode) {
    measureMap.top[compId] = domNode.offsetTop
}

function measureComponentHeight(measureMap, compId, domNode, structure) {
    let minHeight = _.get(structure, 'layout.height', 0)
    const aspectRatio = _.get(structure, 'layout.aspectRatio', 0)
    if (aspectRatio) {
        minHeight = aspectRatio * measureMap.width[compId] //aspect ratio is a minimum height, domNode height should override for a dynamically resizing component
    }
    if (shouldOnlyMeasureDomHeight(structure)) {
        measureMap.height[compId] = domNode.offsetHeight
    } else if (shouldClassBasedMeasureDomHeight(structure) || !_.get(structure, 'layout.height')) {
        measureMap.height[compId] = Math.max(domNode.offsetHeight, minHeight)
    } else {
        measureMap.height[compId] = minHeight
    }
}

function measureDeadComp(measureMap, compId, domNode, structure) {
    const structureWidth = _.get(structure, 'layout.width', 0)
    const structureHeight = _.get(structure, 'layout.height', 0)
    measureMap.width[compId] = structureWidth
    measureMap.height[compId] = structureHeight
}

function getLeft(structure, domNode) {
    const structureX = _.get(structure, 'layout.x', 0)

    let {offsetLeft} = domNode
    const measurer = warmupUtilsLib.contentAreaUtil.getContentAreaMarkerForElement(domNode)
    if (measurer) {
        offsetLeft -= measurer.offsetLeft
    }

    const diff = Math.abs(structureX - offsetLeft)
    return diff <= 0.5 ? structureX : offsetLeft
}

function updateStructureCompMeasures(compId, domNode, structure, measureMap, layoutAPI) {
    if (measureMap.isDeadComp[compId]) {
        measureDeadComp(measureMap, compId, domNode, structure)
        return
    }
    const computedStyle = window.getComputedStyle(domNode)
    measurePositionForFixedComp(measureMap, compId, domNode, computedStyle)
    measureComponentZIndex(measureMap, compId, computedStyle)
    measureComponentWidth(measureMap, compId, domNode, structure)
    measureComponentHeight(measureMap, compId, domNode, structure)

    if (layoutAPI.getLayoutMechanism() === warmupUtilsLib.siteConstants.LAYOUT_MECHANISMS.MESH) {
        measureComponentTop(measureMap, compId, domNode)
    }

    if (!isCompRenderedInFixedPosition(computedStyle)) {
        measureMap.left[compId] = getLeft(structure, domNode)
    }
}

function isComponentDead(domNode) {
    return domNode.getAttribute('data-dead-comp')
}

function getDomNode(compId, getDomNodeFunc) {
    if (!compId) {
        return false
    }
    const domNode = getDomNodeFunc(compId)
    if (!domNode) {
        return false
    }
    return domNode
}

// language - array of { type: 'attr'/'css', node:dome node, changes(to patch):object }
const applyLayoutFuncResult = (measureMap, domNode, changesRequest) => {
    const addPatchers = additionalPatchersMapSetConcat(domNode)

    const {CHANGES_TYPES} = warmupUtilsLib.constants.COMP_LAYOUT_OPTIONS
    const noObjectSupportInZeptoTypes = [CHANGES_TYPES.data]

    const createPatchers = changesArr => {
        const typesArray = Object.keys(CHANGES_TYPES)

        const createPatcher = ({type, node, changes}) => {
            if (!typesArray.includes(type)) {
                throw new Error(`can not use type ${type} for patching. only types ${typesArray} are allowed`)
            }

            if (noObjectSupportInZeptoTypes.includes(type)) {
                return () => {
                    const $node = $(node)
                    _.forOwn(changes, function (val, key) {
                        $node[type](key, val)
                    })
                }
            }

            return () => $(node)[type](changes)
        }

        const patchersArr = changesArr.map(createPatcher)
        addPatchers(patchersArr)
    }

    const updateMeasureMap = changesArr => {
        const MEASURE_MAP_TYPES = {
            height: 'height',
            'min-height': 'minHeight',
            'min-width': 'minWidth',
            width: 'width',
            top: 'top'
        }
        const measureMapTypesInCssStyle = Object.keys(MEASURE_MAP_TYPES)
        const merge = to => from => _.merge(to, from)

        const createPartialMeasureMap = ({id, changes}) => {
            const changesToMeasureMap = _.pick(changes, measureMapTypesInCssStyle)
            return _(changesToMeasureMap)
                .mapKeys((value, prop) => MEASURE_MAP_TYPES[prop])
                .transform((partialMeasureMap, value, measureMapProp) => {
                    partialMeasureMap[measureMapProp] = partialMeasureMap[measureMapProp] || {}
                    partialMeasureMap[measureMapProp][id] = value
                }, {})
                .value()
        }

        _(changesArr).filter({type: CHANGES_TYPES.css}).map(createPartialMeasureMap).forEach(merge(measureMap))
    }

    if (_.isFunction(changesRequest)) {
        addPatchers([changesRequest])
    } else if (_.isArray(changesRequest)) {
        const isEmpty = change => _.isEmpty(change.changes)
        const addId = change => {
            change.id = change.node.getAttribute('id')
            return change
        }

        const fixedChangesArr = _(changesRequest).reject(isEmpty).map(addId).value()

        createPatchers(fixedChangesArr)
        updateMeasureMap(fixedChangesArr)
    }
}

function isFixedInMobile(layoutAPI, structure) {
    const isMobileView = layoutAPI.isMobileView()
    return isMobileView && _.get(structure, ['layout', 'fixedPosition'])
}

function measureComponent(structure, structureInfo, getDomNodeFunc, measureMap, nodesMap, layoutAPI) {
    const compId = structureInfo.id
    const prevMeasuredValues = {
        left: measureMap.left[compId]
    }
    const domNode = getDomNode(compId, getDomNodeFunc)
    if (!domNode) {
        return
    }
    additionalPatchersWeakMap.set(domNode, [])
    nodesMap[compId] = domNode

    measureMap.isDeadComp[compId] = isComponentDead(domNode)
    updateStructureCompMeasures(compId, domNode, structure, measureMap, layoutAPI)
    if (measureMap.isDeadComp[compId]) {
        return
    }

    if (layoutAPI.isExperimentOpen('sv_noSpecificCompLayout')) {
        return
    }

    const docked = _.get(structure, ['layout', 'docked'])
    const isHorizontallyStretched = docked && docked.right && docked.left

    if (measureMap.relativeToScreenOverrides && measureMap.relativeToScreenOverrides[compId]) {
        delete measureMap.relativeToScreenOverrides[compId]
    }

    if (isHorizontallyStretched && !isFixedInMobile(layoutAPI, structure)) {
        _.merge(measureMap, {
            relativeToScreenOverrides: {
                [compId]: {
                    x: domNode.getBoundingClientRect().left
                }
            }
        })
    }

    if (classBasedCustomMeasures[structureInfo.type]) {
        classBasedCustomMeasures[structureInfo.type](compId, measureMap, nodesMap, structureInfo, layoutAPI, prevMeasuredValues)
    }

    runAdditionalMeasurers(structureInfo.type, compId, measureMap, nodesMap, layoutAPI, structureInfo)

    const layoutFunc = layoutAPI.getLayoutFunc(domNode)
    if (layoutFunc) {
        applyLayoutFuncResult(measureMap, domNode, layoutFunc(domNode, $))
    }

    if (classBasedCustomLayoutFunctions[structureInfo.type]) {
        applyLayoutFuncResult(measureMap, domNode, classBasedCustomLayoutFunctions[structureInfo.type](compId, nodesMap, measureMap, layoutAPI, structureInfo))
    }
}

function runAdditionalMeasurers(classType, compId, measureMap, nodesMap, layoutAPI, structureInfo) {
    if (classBasedAdditionalMeasure[classType]) {
        const additionalMeasurers = classBasedAdditionalMeasure[classType]
        if (_.isFunction(additionalMeasurers)) {
            additionalMeasurers(compId, measureMap, nodesMap, layoutAPI, structureInfo)
        } else if (_.isPlainObject(additionalMeasurers)) {
            _.forEach(_.values(additionalMeasurers), multiMeasurer => multiMeasurer(compId, measureMap, nodesMap, layoutAPI, structureInfo))
        }
    }
}

function measureComponentChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, layoutAPI) {
    const compId = structureInfo.id
    const domNode = getDomNode(compId, getDomNodeFunc)
    if (!domNode || isComponentDead(domNode)) {
        return
    }

    if (classBasedMeasureChildren[structureInfo.type]) {
        let childrenSelectors = classBasedMeasureChildren[structureInfo.type]
        if (typeof childrenSelectors === 'function') {
            childrenSelectors = childrenSelectors(compId, nodesMap, structureInfo, layoutAPI)
        }
        _.forEach(childrenSelectors, function (selector) {
            const isChildComponent = _.isPlainObject(selector)
            const childIdPath = isChildComponent ? selector.pathArray : selector
            const childNode =
                getDomNodeFunc.apply(undefined, [compId].concat(childIdPath)) || getDomNodeFunc.apply(undefined, [compId, 'component'].concat(childIdPath))
            const childIdString = childIdPath.join('')
            const childId = compId + childIdString
            if (childNode) {
                nodesMap[childId] = childNode
                measureMap.height[childId] = childNode.offsetHeight
                measureMap.width[childId] = childNode.offsetWidth

                if (isChildComponent && classBasedCustomMeasures[selector.type]) {
                    classBasedCustomMeasures[selector.type](childId, measureMap, nodesMap, structureInfo, layoutAPI)
                }
            } else {
                //remove child from maps
                delete measureMap.height[childId]
                delete measureMap.width[childId]
                delete measureMap.custom[childId]
                delete nodesMap[childId]
            }
        })
    }
}

function patchNodeDefault(id, componentType, patch, measureMap, layout) {
    const style: Record<string, any> = {}

    if (layout) {
        let shouldPatchStyleTop =
            componentType !== 'wysiwyg.viewer.components.MediaContainer' &&
            (!siteUtilsLayout.isVerticallyDocked(layout) || siteUtilsLayout.isVerticallyStretchedToScreen(layout))

        if (experiment.isOpen('tpaPopup_anchors')) {
            shouldPatchStyleTop = componentType !== 'wysiwyg.viewer.components.tpapps.TPAPopup' && shouldPatchStyleTop
        }

        if (shouldPatchStyleTop) {
            // @ts-ignore
            style.top = unitize(measureMap.top[id])
        }
        if (!siteUtilsLayout.isVerticallyStretched(layout) || siteUtilsLayout.isVerticallyStretchedToScreen(layout)) {
            // @ts-ignore
            style.height = unitize(measureMap.height[id])
        }
        if (!_.isEmpty(style)) {
            patch.css(id, style)
        }
    }
}

function patchComponent(structureInfo, patchers, nodesMap, measureMap, layoutAPI) {
    const layoutMechanism = layoutAPI.getLayoutMechanism()
    const {id} = structureInfo
    const {layout} = structureInfo
    const componentType = structureInfo.type
    if (layoutMechanism === ANCHORS) {
        patchNodeDefault(id, componentType, patchers, measureMap, layout)
    }
    if (experiment.isOpen('sv_noSpecificCompLayout', layoutAPI)) {
        return false
    }

    const domNode = nodesMap[structureInfo.id]

    const patchersToExecute = additionalPatchersWeakMap.get(domNode)
    _.forEach(patchersToExecute, patcher => patcher(patchers))

    const isDeadComp = measureMap.isDeadComp[id]

    if (!isDeadComp && classBasedPatchers[structureInfo.type]) {
        classBasedPatchers[structureInfo.type](id, patchers, measureMap, structureInfo, layoutAPI)
    }
}

function registerCustomLayoutFunction(className, fix) {
    if (classBasedCustomLayoutFunctions[className]) {
        console.warn(`${className} is already registered to 'registerCustomLayoutFunction'`) //eslint-disable-line no-console
    }
    classBasedCustomLayoutFunctions[className] = fix
}

export default {
    patchComponent,
    measureComponent,
    measureComponentChildren,
    isComponentDead,
    componentHasCustomMeasure: className => !!classBasedCustomMeasures[className],
    componentHasCustomPatcher: className => !!classBasedPatchers[className],

    /**
     * Allows to plugin a patching method to component that needs it
     * @param {string} className The component class name
     * @param {function(string, Object.<string, Element>,  layout.measureMap, layout.structureInfo, core.SiteData)} patcher The patching method
     */
    registerPatcher(className, patcher) {
        classBasedPatchers[className] = patcher
    },

    /**
     * @param {string} className
     * @param {function(string, Object.<string, Element>, layout.measureMap, layout.structureInfo, core.SiteData)[]}patchersArray
     */
    registerPatchers(className, patchersArray) {
        classBasedPatchers[className] = function () {
            const args = arguments
            _.forEach(patchersArray, function (patcher) {
                patcher.apply(null, args)
            })
        }
    },

    /**
     * the fix will run after the measure but before enforce anchors.
     * use this if you need to update the comp size according to some inner element size (example in site-button)
     * @param {String} className
     * @param {function(string, layout.measureMap, Object.<string, Element>, core.SiteData, layout.structureInfo)} fix
     * a function that runs during measure, you can change only the measure map there
     */
    registerCustomMeasure(className, fix) {
        classBasedCustomMeasures[className] = fix
    },

    registerCustomLayoutFunction,

    registerCustomDomChangesFunction: (className, fix) => {
        registerCustomLayoutFunction(className, (id, nodesMap) => fix(nodesMap[id]))
    },

    /**
     * Allows to request to be measured during layout
     * @param className The component class name
     */
    registerRequestToMeasureDom(className) {
        classBasedDomMeasure[className] = true
    },

    registerPureDomWidthMeasure(className) {
        classBasedPureDomWidthMeasure[className] = true
    },

    registerPureDomHeightMeasure(className) {
        classBasedPureDomHeightMeasure[className] = true
    },

    unregisterPureDomHeightMeasure(className) {
        classBasedPureDomHeightMeasure[className] = false
    },

    /**
     * Allows to request to measure children during layout
     * @param className The component class name
     * @param {(Array.<String|{pathArray: Array.<String>, type: string}>|function|)} pathArray An array of children paths (array of strings) to be measured.
     *  This can also be a callback method that returns the pathArray
     */
    registerRequestToMeasureChildren(className, pathArray) {
        classBasedMeasureChildren[className] = pathArray
    },

    registerAdditionalMeasureFunction(className, measureFunction, optionalMultiMeasurerId?) {
        if (_.isString(optionalMultiMeasurerId) && !_.isEmpty(optionalMultiMeasurerId)) {
            classBasedAdditionalMeasure[className] = _.isPlainObject(classBasedAdditionalMeasure[className]) ? classBasedAdditionalMeasure[className] : {}
            classBasedAdditionalMeasure[className][optionalMultiMeasurerId] = measureFunction
        } else {
            classBasedAdditionalMeasure[className] = measureFunction
        }
    }
}
