define(['lodash'], function (_) {
    'use strict'

    function getAppVersion(immutableSiteData) {
        return immutableSiteData.getIn(['wixapps', 'appbuilder', 'descriptor', 'applicationInstanceVersion'])
    }

    function getAppInstanceIdFromSnapshotDal(snapshotDal) {
        const clientSpecMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        const appbuilderService = _.find(clientSpecMap, e => e.type === 'appbuilder')
        return _.get(appbuilderService, 'instanceId')
    }

    function getAppInstanceIdFromImmutable(immutableSiteData) {
        const appbuilderService = immutableSiteData.getIn(['rendererModel', 'clientSpecMap']).find(function (service) {
            return service.get('type') === 'appbuilder'
        })
        return appbuilderService && appbuilderService.get('instanceId')
    }

    function getAppInstance(siteData) {
        return {
            applicationInstanceId: getAppInstanceIdFromImmutable(siteData),
            applicationInstanceVersion: getAppVersion(siteData)
        }
    }

    return {
        getAppInstance,
        getAppInstanceIdFromSnapshotDal,
        getAppInstanceIdFromImmutable
    }
})
