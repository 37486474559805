/**
 * Created by eitanr on 6/24/14.
 */
import _ from 'lodash'
import parser from './svgPathParser'
import basic from './svgBasicScaler'
import warmupUtilsLib from '@wix/santa-core-utils'

const emptyString = function () {
    return ''
}
const scaleAngleValue = function (value, scaleX, scaleY) {
    return basic.scaleSingleValue(value, scaleY / (scaleX * 360))
}

const scaleArcString = function (commandValues, scaleX, scaleY) {
    if (commandValues.length !== 7) {
        warmupUtilsLib.log.verbose(`incorrect arc string, should have exactly 7 parameters. (value was ${commandValues.join(' ')}`)
        return commandValues.join(' ')
    }

    commandValues[0] = basic.scaleSingleValue(commandValues[0], scaleX)
    commandValues[1] = basic.scaleSingleValue(commandValues[1], scaleY)
    commandValues[2] = scaleAngleValue(commandValues[2], scaleX, scaleY)
    commandValues[5] = basic.scaleSingleValue(commandValues[5], scaleX)
    commandValues[6] = basic.scaleSingleValue(commandValues[6], scaleY)

    return commandValues.join(' ')
}

const scaleMultipleArcString = function (arcString, scaleX, scaleY) {
    const commandValues = arcString.split(/[\s,]+/)
    const commandChunks = _.chunk(commandValues, 7)
    const commandMultipleValues = []

    _.forEach(commandChunks, function (commandValueArr) {
        commandMultipleValues.push(scaleArcString(commandValueArr, scaleX, scaleY))
    })

    return commandMultipleValues.join(' ')
}

const scale = function (pathElement, scaleX, scaleY) {
    const parsedPath = parser.getParsedPath(pathElement.getAttribute('d'))
    _.forEach(parsedPath, function (commandValueArr) {
        const args = [commandValueArr[1]]
        const pathFunction = commandValueArr[0].toUpperCase()

        if (pathFunction === 'V') {
            args.push(scaleY)
        } else if (pathFunction === 'H') {
            args.push(scaleX)
        } else {
            args.push(scaleX)
            args.push(scaleY)
        }
        commandValueArr[1] = scalers[commandValueArr[0].toUpperCase()].apply(null, args)
    })
    return {d: parser.stringifyParsedPath(parsedPath)}
}
const scalers = {
    M: basic.scaleMultiplePairStrings,
    L: basic.scaleMultiplePairStrings,
    H: basic.scaleMultipleSingleStrings,
    V: basic.scaleMultipleSingleStrings,
    Z: emptyString,
    C: basic.scaleMultiplePairStrings,
    S: basic.scaleMultiplePairStrings,
    Q: basic.scaleMultiplePairStrings,
    T: basic.scaleMultiplePairStrings,
    A: scaleMultipleArcString
}

export default {
    scale
}
