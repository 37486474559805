import type {DAL} from '@wix/document-manager-core'
import {guidUtils} from '@wix/santa-core-utils'
const {getUniqueId} = guidUtils
import type {Pointers, MetaData} from '@wix/document-services-types'
import {DATA_TYPES} from '../constants/constants'
import _ from 'lodash'
import {removePrefix} from '@wix/document-manager-utils'

const ITEM_TO_PREFIX_MAP = {
    style: 'style',
    mobileHints: 'mobileHints',
    layout: 'layout',
    anchors: 'anchors',
    breakpoints: 'breakpoints',
    transformations: 'transformations',
    transitions: 'transitions',
    variants: 'variants',
    data: 'dataItem',
    props: 'propItem',
    connections: 'connection',
    design: 'design',
    behaviors: 'behavior',
    fixerVersions: 'fixerVersions'
}

const generateItemIdWithPrefix = (prefix: string) => getUniqueId(prefix, '-', {bucket: `generateItemIdWithPrefix_${prefix}`})

const generateUniqueIdByType = (type: string, pageId: string, dal: DAL, pointers: Pointers) => {
    let newId = generateItemIdWithPrefix(ITEM_TO_PREFIX_MAP[type])
    const retries = 3
    for (let i = 0; i < retries && dal.has(pointers.data.getItem(type, newId, pageId)); i++) {
        newId = generateItemIdWithPrefix(ITEM_TO_PREFIX_MAP[type])
    }

    if (dal.has(pointers.data.getDataItem(newId, pageId))) {
        throw new Error('Failed to generate unique Id')
    }

    return newId
}

const GET_DEFAULT_META_DATA = {
    [DATA_TYPES.prop]: () => ({schemaVersion: '1.0'}),
    DEFAULT: () => ({isPreset: false, schemaVersion: '1.0', isHidden: false})
}

const createDefaultMetaData = (metaDataOverrides?: MetaData, itemType: string = 'DEFAULT') => {
    const defaultMetaDataCreator = GET_DEFAULT_META_DATA[itemType] || GET_DEFAULT_META_DATA.DEFAULT

    return _.assign(defaultMetaDataCreator(), metaDataOverrides)
}

const addDefaultMetaData = (newDataItem: {metaData?: MetaData}, itemType: string = 'DEFAULT') => {
    newDataItem.metaData = createDefaultMetaData(newDataItem.metaData, itemType)
}

const shouldMergeDataItems = (existingDataItem: any, newDataItem: any) =>
    existingDataItem && (!existingDataItem.type || !newDataItem.type || _.isEqual(existingDataItem.type, newDataItem.type))

const REF_PREFIX = '#'
const getIdFromRef = (ref: string) => removePrefix(ref, REF_PREFIX)

export {shouldMergeDataItems, createDefaultMetaData, addDefaultMetaData, generateUniqueIdByType, getIdFromRef, generateItemIdWithPrefix}
