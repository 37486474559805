define([], function () {
    'use strict'

    return {
        Intents: {
            PLATFORM_WORKER: 'PLATFORM_WORKER',
            USER_ACTION: 'USER_ACTION'
        },
        AppIds: {
            EDITOR: 'editor'
        },
        MessageTypes: {
            INITIALIZE: 'initialize',
            WORKER_ALIVE: 'workerAlive',
            SDK_READY: 'sdkReady',
            SET_MANIFEST: 'setManifest',
            LOAD_MANIFEST: 'loadManifest',
            TRIGGER_EVENT: 'triggerEvent',
            ADD_APPS: 'addApps',
            VIEWER_INFO_CHANGED: 'viewerInfoChanged',
            ADD_APP_COMPLETED: 'addAppCompleted',
            GET_INSTALLED_APPS: 'getInstalledApps',
            SET_APP_EXPORTED_APIS: 'setAppExportedAPIs',
            GET_IS_PLATFORM_APP_INSTALLED: 'getIsPlatformAppInstalled',
            ADD_ALL_APPS_COMPLETED: 'addAllAppsCompleted',
            RELOAD_PLATFORM_APPLICATION: 'reloadPlatformApplication'
        },
        NotifyMethods: {
            APP_INSTALLED: 'appInstalled',
            APP_UPDATED: 'appUpdated',
            REMOVE_APP: 'removeApp',
            REMOVE_APP_COMPLETED: 'removeAppCompleted',
            BEFORE_REMOVE_APP: 'beforeRemoveApp',
            MIGRATE: 'migrate',
            BEFORE_WIDGET_ADDED: 'beforeWidgetAdded'
        },
        EVENTS: {
            COMPONENT_DELETED: 'componentDeleted'
        },
        Controller: {
            DEFAULT_STATE: 'default',
            CONNECTIONS: 'connections',
            WILDCARD_ROLE: '*'
        },
        Page: {
            DEFAULT_STATE: 'default'
        },
        APPS: {
            DATA_BINDING: {
                appDefId: 'dataBinding'
            },
            DYNAMIC_PAGES: {
                appDefId: 'wix-code'
            },
            SANTA_MEMBERS: {
                appDefId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'
            },
            WIX_CODE: {
                appDefId: '675bbcef-18d8-41f5-800e-131ec9e08762'
            },
            META_SITE: {
                applicationId: '-666'
            }
        },
        APP_ACTION_TYPES: {
            REMOVE: 'REMOVE',
            UPDATE: 'UPDATE'
        },
        SESSION_STATE_TYPES: [
            'wysiwyg.viewer.components.StateBoxState',
            'wysiwyg.viewer.components.StateBoxFormState',
            'wysiwyg.viewer.components.StateStripState'
        ],
        CONTROLLER_TYPES: {
            APP_CONTROLLER: 'platform.components.AppController',
            APP_WIDGET: 'platform.components.AppWidget'
        },
        APP: {
            TYPE: {
                SECTION: 'tpaSection',
                WIDGET: 'tpaWidget',
                APP_PART: 'wixappsPart',
                PLATFORM_ONLY: 'platformOnly',
                BLOCKS: 'blocks'
            }
        },
        ADD_APP_ORIGINS: {
            ADD_APP_WITH_SOURCE_TEMPLATE_ID: 'addApp_withSourceTemplateId',
            ADD_APP_WITH_COPY_DATA_SUCC: 'addApp_copyDataSuccess',
            ADD_APP_WITH_COPY_DATA_ERROR: 'addApp_copyDataError',
            ADD_APP: 'addApp',
            ADD_APP_EXISTING: 'addApp_existingApp',
            ADD_APP_WITH_SOURCE_TEMPLATE_ID_OLD: 'addApp_withSourceTemplateId_old',
            ADD_APP_PRE_SAVE: 'addApp_preSave_old',
            ADD_APP_EXISTING_OLD: 'addApp_existingApp_old',
            ADD_APPS_BLOCKS: 'addApps_blocks',
            ADD_APPS_PLATFORM_ONLY: 'addApps_platform_only',
            ADD_APPS_SILENT_INSTALL: 'addApps_silentInstallation'
        }
    }
})
