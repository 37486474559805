import _ from 'lodash'
import ecomLogger from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomLogger'

/**
 * In case a user does not have a premium ecom account we need to display a popup saying the store is unavailable.
 * (This is done by adding a MessageView compononet.)
 * @param partApi
 */
function handleBlockingPopupDialog(partApi) {
    function showDialog(siteMemberDetails) {
        if (partApi.isMobileView()) {
            showCheckoutError(partApi, 2034)
        } else if (partApi.isInTemplatePublicViewer()) {
            showCheckoutError(partApi, 2035)
        } else if (siteMemberDetails && siteMemberDetails.owner) {
            partApi.ecomShowCheckoutDialogForOwner(_.partial(handleCheckout, partApi))
        } else {
            showCheckoutError(partApi, 2034)
        }
    }

    partApi.getMemberDetails(showDialog)
}

function showCheckoutError(partApi, errorCode) {
    const params = {
        code: errorCode
    }
    partApi.ecomShowMessage(params)
}

function reportCheckoutBi(partApi, checkoutTarget) {
    const checkoutSource = checkoutTarget === '_blank' ? 'from popup' : 'from same page'
    partApi.ecomReportEvent(ecomLogger.events.USER_PROCEEDED_TO_CHECKOUT, {
        cartId: partApi.getEcomCart().id,
        checkoutSource
    })
}

function getCheckoutTarget(partApi) {
    const {appLogicParams} = partApi.getPartData()
    if (partApi.isMobileView() || partApi.isMobileDevice()) {
        return '_blank'
    }
    if (appLogicParams.checkoutTarget) {
        return appLogicParams.checkoutTarget.value
    }
    return '_self'
}

/**
 * If the user chose "open in new window" with a local handled checkout, we open an iframe for checkout
 * @param partApi
 * @param checkoutTarget
 * @returns {boolean}
 */
function shouldOpenInIframe(partApi, checkoutTarget) {
    const sameWindow = checkoutTarget === '_self'
    return shouldOpenCheckoutInWixScreen(partApi) && !sameWindow
}

function shouldOpenCheckoutInWixScreen(partApi) {
    return partApi.getEcomCart().hasExternalCheckoutUrl === true
}

function handleCheckout(partApi) {
    const checkoutUrl = partApi.getCheckoutUrl()
    const checkoutTarget = getCheckoutTarget(partApi)
    reportCheckoutBi(partApi, checkoutTarget)
    const isMobile = partApi.isMobileView() || partApi.isMobileDevice()
    if (!isMobile && shouldOpenInIframe(partApi, checkoutTarget)) {
        partApi.ecomShowModal(partApi, checkoutUrl)
    } else {
        window.open(checkoutUrl, checkoutTarget)
    }
}

function handleCheckoutClicked(partApi) {
    const ownerHasEcom = partApi.hasPremiumEcom()
    const cart = partApi.getEcomCart()
    if (ownerHasEcom) {
        if (cart.items.length) {
            handleCheckout(partApi)
        }
    } else {
        handleBlockingPopupDialog(partApi)
    }
}

export default {
    handleCheckout: handleCheckoutClicked
}
