define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/responsiveLayout/utils',
    'documentServices/variants/variants',
    'documentServices/utils/utils'
], function (_, constants, dataModel, responsiveLayoutUtils, variants, dsUtils) {
    'use strict'

    const {DATA_TYPES, BREAKPOINTS_TYPES} = constants

    const {createBreakpointsData} = responsiveLayoutUtils

    const getBreakpointsFromVariantsMap = (ps, compPointer) => {
        const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))
        const breakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)
        if (breakpointData) {
            const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
            const pointers = _.map(breakpointData.values, bpRange => ps.pointers.data.getItem(DATA_TYPES.variants, dsUtils.stripHashIfExists(bpRange), pageId))
            const breakpointRanges = _.map(pointers, ps.dal.get)
            return {
                pointers,
                values: breakpointRanges
            }
        }
    }

    const getComponentBreakpoints = (ps, compPointer) => getBreakpointsFromVariantsMap(ps, compPointer)

    const updateComponentBreakpoints = (ps, compPointer, bpRangesArray) => {
        if (!ps || !compPointer || !bpRangesArray || !bpRangesArray.length) {
            throw new Error('invalid args')
        }

        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))

        const currentBreakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)

        if (currentBreakpointData && bpRangesArray.length < currentBreakpointData.values.length) {
            throw new Error('cant remove breakpoints with update API, please use breakpoints.remove')
        }

        const bpDataId = currentBreakpointData && currentBreakpointData.id

        const compBreakpointId = createBreakpointsData(ps, bpDataId, compPointer, bpRangesArray, pageId)

        if (!currentBreakpointData && compBreakpointId) {
            dataModel.linkComponentToItem(ps, compPointer, compBreakpointId, 'breakpointVariantsQuery')
        }
    }

    const removeBreakpointsFromImageXData = (ps, breakpointRangePointer) => {
        const pageId = ps.pointers.data.getPageIdOfData(breakpointRangePointer)
        const isImageX = imageData => imageData.type === 'ImageX'
        const hasScopedData = imageData => imageData.scopedData && imageData.scopedData.length > 0
        const imageXWithScopedDataPointers = ps.pointers.data.getDataItemsWithPredicate(imageData => isImageX(imageData) && hasScopedData(imageData), pageId)
        _.forEach(imageXWithScopedDataPointers, dataPointer => {
            const currentData = ps.dal.get(dataPointer)
            const dataToRemove = _.find(currentData.scopedData, x => x.breakpoint === `#${breakpointRangePointer.id}`)
            const updatedImageXData = _.assign({}, currentData, {scopedData: _.without(currentData.scopedData, dataToRemove)})
            ps.dal.set(dataPointer, updatedImageXData)
        })
    }

    const removeBreakpointsRange = (ps, compPointer, breakpointRangePointer) => {
        const breakpointDataPointer = _.head(variants.getByComponentAndType(ps, compPointer, BREAKPOINTS_TYPES.DATA))
        const currentBreakpointData = breakpointDataPointer && ps.dal.get(breakpointDataPointer)
        if (currentBreakpointData) {
            const updatedBreakpointsData = _.assign({}, currentBreakpointData, {
                values: _.reject(currentBreakpointData.values, bp => dsUtils.stripHashIfExists(bp) === breakpointRangePointer.id)
            })
            removeBreakpointsFromImageXData(ps, breakpointRangePointer)
            variants.remove(ps, breakpointRangePointer)
            ps.dal.set(breakpointDataPointer, updatedBreakpointsData)
        }
    }

    return {
        get: getComponentBreakpoints,
        update: updateComponentBreakpoints,
        remove: removeBreakpointsRange,
        wasBreakpointsMigratedToVariants: () => true
    }
})
