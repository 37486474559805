define(['lodash', 'documentServices/appStudio/appStudioDataModel'], function (_, appStudioDataModel) {
    'use strict'

    function getAllDependencies(ps) {
        return appStudioDataModel.getAppStudioData(ps).dependencies
    }

    function addDependency(ps, appDefId, dependencyInfo) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)

        const dependency = _.find(appStudioData.dependencies, {appDefId})
        if (dependency) {
            dependency.dependencyData = _.uniq([...dependency.dependencyData, ...dependencyInfo.dependencyData])
        } else {
            appStudioData.dependencies.push({
                appDefId,
                ...dependencyInfo
            })
        }

        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function removeDependency(ps, appDefId) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)

        appStudioData.dependencies = appStudioData.dependencies.filter(dependency => dependency.appDefId !== appDefId)
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function setDependencies(ps, dependencies) {
        const appStudioData = appStudioDataModel.getAppStudioData(ps)

        appStudioData.dependencies = dependencies
        appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
    }

    function hasDependency(ps, appDefId) {
        return getAllDependencies(ps).some(dependency => dependency.appDefId === appDefId)
    }

    return {
        hasDependency,
        addDependency,
        removeDependency,
        setDependencies,
        getAllDependencies
    }
})
