import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import SUPPORTED_SITE_EVENTS from '../common/supportedSiteEvents'

/**
 * @class tpa.mixins.tpaCompAPI
 */
export default {
    propTypes: {
        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
        clientSpecMap: santaComponents.santaTypesDefinitions.RendererModel.clientSpecMap.isRequired,
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
        isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
        isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
        sendPostMessage: santaComponents.santaTypesDefinitions.TPA.sendPostMessage.isRequired,
        windowScrollEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowScrollEvent.isRequired,
        windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent.isRequired,
        svSessionChangeEvent: santaComponents.santaTypesDefinitions.SiteAspects.svSessionChangeEvent.isRequired,
        consentPolicyUpdateEvent: santaComponents.santaTypesDefinitions.SiteAspects.consentPolicyUpdateEvent.isRequired,
        commonConfigUpdateEvent: santaComponents.santaTypesDefinitions.SiteAspects.commonConfigUpdateEvent.isRequired,
        deleteCompListeners: santaComponents.santaTypesDefinitions.TPA.deleteCompListeners.isRequired,
        siteMetadataChangeAspect: santaComponents.santaTypesDefinitions.SiteAspects.siteMetadataChangeAspect.isRequired,
        tpaPageNavigationAspect: santaComponents.santaTypesDefinitions.SiteAspects.tpaPageNavigationAspect.isRequired,
        dynamicClientSpecMapAspect: santaComponents.santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
        registerToMemberDetailsChange: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.registerToMemberDetailsChange.isRequired,
        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
        unRegisterMemberDetailsChange: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.unRegisterMemberDetailsChange.isRequired,
        aspectData: santaComponents.santaTypesDefinitions.TPA.data
    },

    isEventSupported(event) {
        return !!SUPPORTED_SITE_EVENTS[event]
    },

    isCompListensTo(event) {
        return _.includes(this.state.registeredEvents, event)
    },

    getAppData() {
        return this.props.clientSpecMap[this.props.compData.applicationId] || {}
    },

    getDeviceType() {
        const isMobile = this.props.isResponsive ? this.props.isMobileDevice : this.props.isMobileView
        const deviceType = isMobile ? 'mobile' : 'desktop'
        if (deviceType === 'mobile') {
            return deviceType
        }

        const tpaDeviceType = _.get(this.props, 'aspectData.queryParams.deviceTypeForTPA')
        const deviceTypes = ['desktop', 'mobile']
        return deviceTypes.includes(tpaDeviceType) ? tpaDeviceType : deviceType
    },

    iframeMessageQueue: [],
    sendPostMessage(data) {
        if (!this.iframeLoaded && data.waitForIFrameToLoad) {
            /* TODO normally waitForIFrameToLoad flag will not be required and 'else' branch need to always be triggered.
                    this flag was added to allow backwards compatibility with ecom due to issue PLAT-211 */
            this.enqueueMessage(data)
        } else {
            this.props.sendPostMessage(this, data)
        }
    },

    getIframe() {
        return this.refs.iframe && ReactDOM.findDOMNode(this.refs.iframe)
    },

    startListen(event) {
        if (this.isEventSupported(event)) {
            this.setState({registeredEvents: this.state.registeredEvents.concat(event)})

            if (this.props) {
                switch (event) {
                    case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION:
                    case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_IN:
                    case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_OUT:
                    case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_CHANGE:
                        this.props.tpaPageNavigationAspect.registerToPageChanged(this, event)
                        break
                    case SUPPORTED_SITE_EVENTS.SCROLL:
                        this.props.windowScrollEvent.registerToScroll(this)
                        break
                    case SUPPORTED_SITE_EVENTS.SESSION_CHANGED:
                        this.props.svSessionChangeEvent.registerToSessionChanged(this)
                        break
                    case SUPPORTED_SITE_EVENTS.INSTANCE_CHANGED:
                        this.props.dynamicClientSpecMapAspect.registerToInstanceChanged(this)
                        break
                    case SUPPORTED_SITE_EVENTS.MEMBER_DETAILS_UPDATED:
                        this.props.registerToMemberDetailsChange(this)
                        break
                    case SUPPORTED_SITE_EVENTS.SITE_METADATA_CHANGED:
                        this.props.siteMetadataChangeAspect.registerToSiteMetadataChange(this)
                        break
                    case SUPPORTED_SITE_EVENTS.KEY_DOWN:
                        this.props.windowKeyboardEvent.registerToKeyDown(this)
                        break
                    case SUPPORTED_SITE_EVENTS.KEY_UP:
                        this.props.windowKeyboardEvent.registerToKeyUp(this)
                        break
                    case SUPPORTED_SITE_EVENTS.COMMON_CONFIG_UPDATE:
                        this.props.commonConfigUpdateEvent.registerToCommonConfigUpdated(this)
                        break
                    case SUPPORTED_SITE_EVENTS.CONSENT_POLICY_UPDATE:
                        this.props.consentPolicyUpdateEvent.registerToConsentPolicyUpdated(this)
                        break
                }
            }
        }
    },

    stopListen(event) {
        this.setState({registeredEvents: _.without(this.state.registeredEvents, event)})

        if (this.props) {
            switch (event) {
                case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION:
                case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_IN:
                case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_OUT:
                case SUPPORTED_SITE_EVENTS.PAGE_NAVIGATION_CHANGE:
                    this.props.tpaPageNavigationAspect.unregisterToPageChanged(this)
                    break
                case SUPPORTED_SITE_EVENTS.SCROLL:
                    this.props.windowScrollEvent.unregisterToScroll(this, event)
                    break
                case SUPPORTED_SITE_EVENTS.SESSION_CHANGED:
                    this.props.svSessionChangeEvent.unRegisterToSessionChanged(this)
                    break
                case SUPPORTED_SITE_EVENTS.INSTANCE_CHANGED:
                    this.props.dynamicClientSpecMapAspect.unRegisterToInstanceChanged(this)
                    break
                case SUPPORTED_SITE_EVENTS.MEMBER_DETAILS_UPDATED:
                    this.props.unRegisterMemberDetailsChange(this)
                    break
                case SUPPORTED_SITE_EVENTS.SITE_METADATA_CHANGED:
                    this.props.siteMetadataChangeAspect.unRegisterToSiteMetadataChange(this)
                    break
                case SUPPORTED_SITE_EVENTS.KEY_DOWN:
                    this.props.windowKeyboardEvent.unRegisterKeyDown(this)
                    break
                case SUPPORTED_SITE_EVENTS.KEY_UP:
                    this.props.windowKeyboardEvent.unRegisterKeyUp(this)
                    break
                case SUPPORTED_SITE_EVENTS.COMMON_CONFIG_UPDATE:
                    this.props.commonConfigUpdateEvent.unRegisterToCommonConfigUpdated(this)
                    break
                case SUPPORTED_SITE_EVENTS.CONSENT_POLICY_UPDATE:
                    this.props.consentPolicyUpdateEvent.unRegisterToConsentPolicyUpdated(this)
                    break
            }
        }
    },

    setSiteMemberDataState(params) {
        this.setState({
            shouldGetSiteMemberDetails: params
        })
    },

    hasOrigComponent() {
        return !_.isUndefined(this.props.compData.origCompId)
    },

    getPageId() {
        return this.props.pageId
    },

    componentDidMount() {
        const iframe = this.getIframe()
        if (iframe) {
            iframe.addEventListener('load', () => {
                this.iframeLoaded = true
                this.flushMessageQueue()
            })
        }
    },

    enqueueMessage(data) {
        this.iframeMessageQueue.push(data)
    },

    flushMessageQueue() {
        this.iframeMessageQueue.forEach(msg => this.sendPostMessage(msg))
        this.iframeMessageQueue = []
    },

    componentWillUnmount() {
        const compId = this.props.id
        const appDefId = this.getAppData().appDefinitionId
        this.props.deleteCompListeners(appDefId, compId)
        _.forEach(
            _.get(this.state, 'registeredEvents', []),
            function (event) {
                this.stopListen(event)
            }.bind(this)
        )
    }
}
