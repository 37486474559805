define([
    'lodash',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsHooks',
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsAPI',
    'documentServices/mobileUtilities/mobileUtilities'
], function (_, componentsMetaData, mergeAggregator, mobileHintPresetsHooks, mobileHintsAPI, mobileUtil) {
    'use strict'

    const {applyPresetsToConversionData, createDuplicateMobilePreset: createMobilePreset} = mobileHintsAPI

    function isHidden(ps, pageId, compStructure) {
        if (!compStructure.mobileHintsQuery) {
            return false
        }
        const mobileHintsPointer = ps.pointers.data.getMobileHintsItem(compStructure.mobileHintsQuery, pageId)
        return mobileHintsPointer && ps.dal.full.isExist(mobileHintsPointer) ? _.get(ps.dal.full.get(mobileHintsPointer), 'hidden', false) : false
    }

    function shouldCreateMobilePreset(ps, componentPointer) {
        if (!mobileUtil.isMobileSupported(ps)) {
            return false
        }
        const pageId = _.get(ps.pointers.full.components.getPageOfComponent(componentPointer), 'id')
        const desktopComponent = ps.siteAPI.getDeepStructure ? ps.siteAPI.getDeepStructure(componentPointer) : ps.dal.full.get(componentPointer)
        if (!desktopComponent || isHidden(ps, pageId, desktopComponent)) {
            return false
        }
        const createMobilePresets = componentsMetaData.public.getMobileConversionConfigByName(ps, desktopComponent, 'createMobilePresets', pageId)
        if (createMobilePresets === false) {
            return false
        }
        const mobileComponentPointer = ps.pointers.components.getMobilePointer(componentPointer)
        const mobileComponent = mergeAggregator.getCommittedMobileComponent(ps, pageId, mobileComponentPointer)
        if (!mobileComponent) {
            return false
        }
        return !componentChangedSinceLastMerge(desktopComponent, mobileComponent, isHidden.bind(null, ps, pageId))
    }

    function componentChangedSinceLastMerge(comp, mobileComp, isHiddenComponent) {
        const desktopCompsPresentInMobile = _.reject(comp.components || [], child => isHiddenComponent(child)) || []
        const xor = _.xor(_.map(desktopCompsPresentInMobile, 'id'), _.map(mobileComp.components || [], 'id'))
        if (!_.isEmpty(xor)) {
            return true
        }
        return _.some(desktopCompsPresentInMobile, desktopChild => {
            const mobileChild = _.find(mobileComp.components, {id: desktopChild.id})
            return !mobileChild || componentChangedSinceLastMerge(desktopChild, mobileChild, isHiddenComponent)
        })
    }

    const initialize = () => {
        mobileHintPresetsHooks.initialize()
    }

    return {
        initialize,
        createMobilePreset,
        applyPresetsToConversionData,
        shouldCreateMobilePreset
    }
})
