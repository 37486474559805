import _ from 'lodash'
import pmrpc from 'pm-rpc'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as tpaComponents from '../../../_internal/tpaComponents'

const API_NAME_PREFIX = 'tpa_public_api_'
const REQUEST_EXPIRY_TIME = 30000
const componentTypes = {
    Page: 'Page',
    Widget: 'Widget'
}
const compTypeValidator = {
    [componentTypes.Page]: tpaComponents.common.utils.isTPASection,
    [componentTypes.Widget]: tpaComponents.common.utils.isGluedWidget
}
const errors = {
    TIMEOUT: 'Callback Timeout Expired',
    COMP_NOT_FOUND: 'ComponentId Not Found',
    APP_NOT_FOUND: 'ApplicationId Not Found',
    COMP_NOT_SUPPORTED: 'Component Not Supported'
}

function getAPIName(compId) {
    return API_NAME_PREFIX + compId
}

function filterComponentByType(comp, type) {
    return _.get(compTypeValidator, type, () => false)(comp)
}

function filterComponentsByApp(comp, applicationId) {
    return _.get(comp, 'applicationId') === applicationId.toString()
}

function getSingleCompIdByApp(siteAPI, appDefId, componentType) {
    const currentPageId = siteAPI.getPageData().id
    const masterPageId = coreUtils.siteConstants.MASTER_PAGE_ID
    const applicationId = _.get(tpaComponents.common.utils.getAppDataByAppDefinitionId(siteAPI, appDefId), 'applicationId')
    if (!applicationId) {
        return {compId: null, error: errors.APP_NOT_FOUND}
    }
    const componentsOnPage = siteAPI.getCompsDataOnPage(currentPageId).concat(siteAPI.getCompsDataOnPage(masterPageId))
    const appsComponents = componentsOnPage.filter(comp => filterComponentsByApp(comp, applicationId))
    if (appsComponents.length === 0) {
        return {compId: null, error: errors.COMP_NOT_FOUND}
    }
    const appsComponentByType = appsComponents.filter(comp => filterComponentByType(comp, componentType))
    if (appsComponentByType.length === 0) {
        return {compId: null, error: errors.COMP_NOT_SUPPORTED}
    }
    return appsComponentByType.length > 1 ? {compId: null, error: errors.COMP_NOT_SUPPORTED} : {compId: _.get(appsComponentByType[0], 'compId')}
}

function getCurrentSectionState(siteAPI) {
    return _.get(siteAPI.getSiteData().getExistingRootNavigationInfo(), 'tpaInnerRoute')
}

function isTPASectionComponent(siteAPI, compId) {
    return tpaComponents.common.utils.isTPASection({componentType: siteAPI.getComponentType(compId)})
}

class CompRPCState {
    private subscribers: any[]
    private compId: any
    private isTPASection: any
    private getCurrentSectionStateFunc: any
    private resolved: boolean
    private rejected: boolean
    private tpaSectionState: undefined
    private timeoutId: number
    constructor(compId, isTPASection, getCurrentSectionStateFunc) {
        this.compId = compId
        this.isTPASection = isTPASection
        this.getCurrentSectionStateFunc = getCurrentSectionStateFunc
        this.resolved = false
        this.rejected = false
        this.tpaSectionState = undefined
        this.subscribers = []
        this.timeoutId = setTimeout(() => this.reject(), REQUEST_EXPIRY_TIME) as unknown as number
    }

    resolve() {
        this.resolved = true
        this.invokeSubscribers(getAPIName(this.compId))
        clearTimeout(this.timeoutId)
        if (this.isTPASection) {
            this.tpaSectionState = this.getCurrentSectionStateFunc()
        }
    }

    reject() {
        this.rejected = true
        this.invokeSubscribers(null, errors.TIMEOUT)
    }

    reset() {
        this.resolved = false
        this.rejected = false
        this.subscribers = []
    }

    subscribe(handler) {
        this.subscribers.push(handler)
    }

    invokeSubscribers(apiName, error?) {
        _.invokeMap(this.subscribers, _.call, null, apiName, error)
        this.subscribers = []
    }
}

class TPARPCAspect {
    private siteAPI: any
    private apiStore: any
    private getCurrentSectionStateFunc: any
    constructor(siteAspectsSiteAPI) {
        this.siteAPI = siteAspectsSiteAPI
        this.apiStore = {}
        this.getCurrentSectionStateFunc = _.partial(getCurrentSectionState, this.siteAPI)
    }

    /**
     * Register an API to be used by other TPAs or WixCode.
     * the registeration is using pmrpc.request and pmrpc.set
     * @param iframeId - the iframeId of the Iframe that exposed the API.
     * @param compId - the componentId of the component that exposed the API.
     */
    registerApi(iframeId, compId) {
        if (!this.apiStore[compId]) {
            const isTPASection = isTPASectionComponent(this.siteAPI, compId)
            this.apiStore[compId] = new CompRPCState(compId, isTPASection, this.getCurrentSectionStateFunc)
        }
        this.apiStore[compId].resolve()

        // @ts-ignore
        pmrpc.api.request(getAPIName(compId), {initiator: iframeId}).then(api => pmrpc.api.set(getAPIName(compId), api))
    }

    /**
     * Require a component API by compId.
     * Callback will be invoked when the API is registered by the component,
     * or will be resolved immediately if API is already registered.
     * @param compId (String) - the compId of the component to require the API from
     * @param callback (function (apiName, error)) - a function that invokes once the API is ready to be requires with pmrpc.request.
     * apiName (String) - The name of the API. should be used with pmrpc.request(apiName).
     * error (String) - an error massage describing the error.
     * The callback will be invoked when the API is registered by the component.
     * The callback will be invoked immediately if the API is already registered.
     * The callback will be invoked with a TIMEOUT error if the viewer waiting to long (REQUEST_EXPIRY_TIME seconds) for the component to register the API.
     */
    requireComponentAPI(compId, callback) {
        const isTPASection = isTPASectionComponent(this.siteAPI, compId)

        if (!this.apiStore[compId]) {
            this.apiStore[compId] = new CompRPCState(compId, isTPASection, this.getCurrentSectionStateFunc)
        }

        if (isTPASection && this.getCurrentSectionStateFunc() !== this.apiStore[compId].tpaSectionState) {
            this.apiStore[compId].reset()
        }

        if (this.apiStore[compId].resolved) {
            callback(getAPIName(compId))
            return
        }
        if (this.apiStore[compId].rejected) {
            callback(null, errors.TIMEOUT)
            return
        }
        this.apiStore[compId].subscribe(callback)
    }

    /**
     * Require an API of a component by appDefId and componentType. Component needs to be in the same page or in the master page.
     * Callback will be invoked when the API is registered by the component,
     * or will be resolved immediately if API is already registered.
     * @param appDefId (String) - The application definition Id of the application to require the API from
     * @param componentType (String) - The type of the component to require the API from ('Page' or 'Widget')
     * @param callback (function (apiName, error)) - a function that invokes once the API is ready to be requires with pmrpc.request.
     * apiName (String) - The name of the API. should be used with pmrpc.request(apiName).
     * error (String) - an error massage describing the error
     * The callback will be invoked when the API is registered by the component.
     * The callback will be invoked immediately if the API is already registered.
     * The callback will be invoked with a APP_NOT_FOUND error if the application was not found on the page
     * The callback will be invoked with a COMP_NOT_FOUND error if the component was not found on the page
     * The callback will be invoked with a COMP_NOT_SUPPORTED error if the component cannot expose API to TPA
     */
    requireSingletonAPI(appDefId, componentType, callback) {
        const singleComp = getSingleCompIdByApp(this.siteAPI, appDefId, componentType)
        if (singleComp.compId) {
            this.requireComponentAPI(singleComp.compId, callback)
        } else {
            callback(null, singleComp.error)
        }
    }
}

export default TPARPCAspect
