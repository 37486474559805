define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/component/component',
    'documentServices/connections/connections',
    'documentServices/refComponent/refComponentUtils'
], function (_, santaCoreUtils, component, connections, refComponentUtils) {
    'use strict'

    const COMPONENT_TYPES = {
        REF_COMPONENT: 'wysiwyg.viewer.components.RefComponent',
        APP_WIDGET: 'platform.components.AppWidget',
        GHOST_REF: 'GHOST_REF_COMP'
    }

    const ERRORS = {
        COMPONENT_IS_NOT_OF_TYPE_REF: 'Component is not of type refComponent',
        COMPONENT_IS_NOT_OF_TYPE_WIDGET: 'Component is not of type appWidget',
        MISSING_WIDGET_ID: 'Missing devCenterWidgetId in appWidget settings'
    }

    const presetIdsToPresetData = presetIds => ({
        type: 'PresetData',
        ...presetIds
    })

    /**
     * Create RefComponent structure template
     * Insert app and widget data for the component to fetch its data from remote app
     *
     * @param appDefinitionId
     * @param widgetId
     * @param variationId
     * @param options
     * @returns Object RefComponent structure
     */
    const generateRefComponentStructure = (appDefinitionId, widgetId, variationId, options = {}) => {
        const {presets, scopedPresets, layout, layouts, overriddenData} = options
        const widgetRefStructure = {
            componentType: 'wysiwyg.viewer.components.RefComponent',
            custom: {overriddenData},
            layout: {rotationInDegrees: 0, width: 100, height: 100, scale: 1, x: 0, y: 0, fixedPosition: false},
            type: 'RefComponent',
            style: 'ref1',
            data: {
                type: 'WidgetRef',
                appDefinitionId,
                widgetId,
                variationId
            }
        }

        return {
            ...widgetRefStructure,
            ...(presets ? {presets: presetIdsToPresetData(presets)} : {}),
            ...(scopedPresets ? {scopedPresets: _.mapValues(scopedPresets, presetIdsToPresetData)} : {}),
            ...(layout ? {layout} : {}),
            ...(layouts ? {layouts} : {})
        }
    }

    /**
     * Extracts root component of referred component
     * Removes the referred component
     * Adds the root component extracted without the referred wrapper
     * Filter out components with componentType GHOST_REF_COM since these comps are hidden when the widget is closed and removed when open
     *
     * @param {ps} ps privateServices
     * @param componentToAddRef new generated ref for the added root component
     * @param refComponentPointer referred component to  open
     * @returns {Object} pointer to the newly created component
     * @throws an exception in case the pointer isn't a to refContainer
     */
    const openReferredComponent = (ps, componentToAddRef, refComponentPointer) => {
        const serializedRef = component.serialize(ps, refComponentPointer, null, null, true)
        if (serializedRef.componentType !== COMPONENT_TYPES.REF_COMPONENT) {
            throw new Error(ERRORS.COMPONENT_IS_NOT_OF_TYPE_REF)
        }
        const [rootRef] = ps.pointers.components.getChildren(refComponentPointer)
        const parentRef = ps.pointers.components.getParent(refComponentPointer)
        const serializedRoot = component.serialize(ps, rootRef, null, null, true)
        serializedRoot.layout.x = serializedRef.layout.x
        serializedRoot.layout.y = serializedRef.layout.y
        updateRefComponentsStructures(ps, serializedRoot)
        sanitizeMobileStructure(serializedRoot)
        const serializedWithoutGhosts = removeGhostComponentsFromSerializedStructure(serializedRoot)
        component.remove(ps, refComponentPointer)
        component.add(ps, componentToAddRef, parentRef, serializedWithoutGhosts)
    }

    /**
     * Wraps an appWidget with referred component
     * Removes the appWidget component
     * Adds refComponent with reference to the widget
     * Handles persisting appWidget data, layout and nickname
     *
     * @param {ps} ps privateServices
     * @param componentToAddRef new generated ref for the added ref component
     * @param {Pointer} appWidgetPointer appWidget to wrap
     * @throws an exception in case the pointer isn't to an appWidget
     * @throws an exception in case appWidget does not contain devCenterWidgetId
     */
    const closeWidgetToReferredComponent = (ps, componentToAddRef, appWidgetPointer) => {
        const serializedAppWidget = component.serialize(ps, appWidgetPointer, null, null, true)
        if (serializedAppWidget.componentType !== COMPONENT_TYPES.APP_WIDGET) {
            throw new Error(ERRORS.COMPONENT_IS_NOT_OF_TYPE_WIDGET)
        }
        const parentRef = ps.pointers.components.getParent(appWidgetPointer)
        const settings = JSON.parse(serializedAppWidget.data.settings)
        if (!settings.devCenterWidgetId) {
            throw new Error(ERRORS.MISSING_WIDGET_ID)
        }
        const refComponentStructure = _.assign(
            generateRefComponentStructure(serializedAppWidget.data.applicationId, settings.devCenterWidgetId, settings.variationPageId),
            {layout: serializedAppWidget.layout},
            {
                custom: {
                    overrideRootConnections: connections.get(ps, appWidgetPointer),
                    overrideRootData: serializedAppWidget.data
                }
            }
        )
        component.remove(ps, appWidgetPointer)
        component.add(ps, componentToAddRef, parentRef, refComponentStructure)
    }

    /**
     * Removes mobileStructure props from components if it is not a valid props
     * Since each component in refComponent us created a mobileStructure.propertyQuery to allow overrides with naming conventions
     * this needs to be removed in case the actual props were not overriden and contains only the generated ID
     *
     * @param compStructure to sanitize its mobileStructure
     */
    const sanitizeMobileStructure = compStructure => {
        const props = _.get(compStructure, ['mobileStructure', 'props'])
        if (_.isEmpty(_.omit(props, ['id']))) {
            _.unset(compStructure, ['mobileStructure', 'props'])
        }
        if (_.isEmpty(compStructure.mobileStructure)) {
            _.unset(compStructure, 'mobileStructure')
        }
        _.forEach(compStructure.components, sanitizeMobileStructure)
    }

    /**
     * Remove ghost components from a serialized structure
     * When removing inner component from refComponent the comp is being hidden by turning to GHOST_REF_COMP
     * When adding the comp serialized to document we need to remove these components
     *
     * @param compStructure serialized structure to remove ghosts from
     * @returns {Object} compStructure with removed ghost components
     */
    const removeGhostComponentsFromSerializedStructure = compStructure => {
        if (_.has(compStructure, 'components')) {
            compStructure.components = _(compStructure.components)
                .reject(comp => _.get(comp, ['props', 'ghost']) === 'COLLAPSED')
                .map(removeGhostComponentsFromSerializedStructure)
                .value()
        }
        return compStructure
    }

    const unwrapOverriddenCompIds = serializedRefComponent => {
        const {custom} = serializedRefComponent
        if (custom) {
            custom.overriddenData = refComponentUtils.unwrapOverriddenCompIds(custom.overriddenData)
        }
    }

    /**
     * Removes components property from all serialized ref components
     * Removes outer refComp prefix from inner refComps overridden comp ids
     * so the override items will be added correctly
     *
     * @param {ps} ps
     * @param compStructure serialized structure to update
     */
    const updateRefComponentsStructures = (ps, compStructure) => {
        const rootControllerDataId = compStructure.componentType === COMPONENT_TYPES.APP_WIDGET ? _.get(compStructure, ['data', 'id']) : undefined
        updateRefComponentsStructuresRecursively(ps, compStructure, rootControllerDataId, compStructure.id)
    }

    const setCustomOverriddenData = (refComponent, overriddenData) => {
        refComponent.custom = refComponent.custom || {}
        refComponent.custom.overriddenData = overriddenData
    }

    const isConnectedToContainingAppWidget = (compStructure, rootControllerDataId) => {
        const connectionItems = _.get(compStructure, ['connections', 'items'])
        return _.some(connectionItems, {controllerId: rootControllerDataId})
    }

    const createConnectionOverride = (compStructure, rootControllerDataId) => {
        if (rootControllerDataId) {
            const [childRoot] = compStructure.components
            if (isConnectedToContainingAppWidget(childRoot, rootControllerDataId)) {
                const overriddenData = _.get(compStructure, ['custom', 'overriddenData'], [])
                const overrideItem = refComponentUtils.getSerializedConnectionOverrideData(childRoot.connections)
                setCustomOverriddenData(compStructure, [...overriddenData, overrideItem])
            }
        }
    }

    const setRemoteOverridesToCustomOverriddenData = (ps, refComponent, rootId) => {
        const referredRootId = santaCoreUtils.displayedOnlyStructureUtil.getReferredCompId(rootId)
        const referredInnerRefCompId = santaCoreUtils.displayedOnlyStructureUtil.getReferredCompId(refComponent.id)
        const remoteOverrides = _.reject(refComponentUtils.getRemoteOverriddenData(ps, referredRootId, referredInnerRefCompId), {itemType: 'connections'})

        const overriddenData = _.get(refComponent, ['custom', 'overriddenData'], [])
        setCustomOverriddenData(
            refComponent,
            _.unionBy(overriddenData, remoteOverrides, item => refComponentUtils.createOverrideKey(item))
        )
    }

    const updateRefComponentsStructuresRecursively = (ps, compStructure, rootControllerDataId, rootId) => {
        const {components} = compStructure

        _.forEach(components, childComp => {
            if (childComp.componentType === COMPONENT_TYPES.REF_COMPONENT) {
                createConnectionOverride(childComp, rootControllerDataId)
                delete childComp.components
                unwrapOverriddenCompIds(childComp)
                setRemoteOverridesToCustomOverriddenData(ps, childComp, rootId)
            } else {
                updateRefComponentsStructuresRecursively(ps, childComp, rootControllerDataId, rootId)
            }
        })
    }

    return {
        openReferredComponent,
        closeWidgetToReferredComponent,
        generateRefComponentStructure
    }
})
