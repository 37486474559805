define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/theme/theme',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/variants/variantsUtils'
], function (_, constants, dataModel, theme, componentStylesAndSkinsAPI, variantUtils) {
    'use strict'

    const {
        STYLES: {COMPONENT_STYLE, TOP_LEVEL_STYLE}
    } = constants

    /**
     * @callback styleCallback
     * @param {StyleDef} style - style definition
     */

    /**
     * Pay attention that this might be not complete definition
     *
     * @typedef {Object} StyleDef
     * @property {string} type - style type
     * @property {string} id
     * @property {Object} metaData
     * @property {Object} style - style properties definition
     * @property {string} componentClassName
     * @property {string} pageId
     * @property {string} compId
     * @property {string} styleType
     * @property {string} skin
     */

    /**
     * Get any object related to theme data, procceed it recursively
     * and applies callback to component or toplevel style (if params.includeReferences is true will apply also to
     * all references like refArray, breakpoint, variant)
     *
     * @param {ps} ps
     * @param {StyleDef|RefArray|object} themeItem - can be style definition, refArray, breakpoint or variant definition
     * @param {object} params
     * @param {boolean} params.includeReferences - force to callback also with reference items
     * @param {string} pageId
     * @param {styleCallback} callback
     */
    const applyToEachStyleReference = (ps, themeItem, pageId, params, callback) => {
        if (!themeItem) {
            return
        }

        if ([COMPONENT_STYLE, TOP_LEVEL_STYLE].includes(themeItem.type)) {
            callback(themeItem)
            return
        }

        if (dataModel.refArray.isRefArray(ps, themeItem)) {
            const relations = dataModel.refArray.extractValuesWithoutHash(ps, themeItem)

            _.forEach(relations, refId => {
                const relation = theme.styles.get(ps, refId, pageId)

                if (relation) {
                    applyToEachStyleReference(ps, relation, pageId, params, callback)
                }
            })
        }

        if (dataModel.variantRelation.isVariantRelation(ps, themeItem)) {
            const relationId = dataModel.variantRelation.extractTo(ps, themeItem)
            const relation = theme.styles.get(ps, relationId, pageId)

            if (relation) {
                applyToEachStyleReference(ps, relation, pageId, params, callback)
            }
        }

        if (dataModel.breakpointRelation.isBreakpointRelation(ps, themeItem)) {
            const refId = dataModel.breakpointRelation.extractRefWithoutHash(ps, themeItem)
            const relation = theme.styles.get(ps, refId, pageId)

            if (relation) {
                applyToEachStyleReference(ps, relation, pageId, params, callback)
            }
        }

        if (params.includeReferences) {
            callback(themeItem)
        }
    }

    /**
     * Checks component's style type and if it is plain style -> applies callback
     * otherwise proceed reference types with {@see applyToEachStyleReference}
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {styleCallback} callback
     */
    const applyToEachComponentStyle = (ps, compPointer, callback) => {
        const componentThemeData = componentStylesAndSkinsAPI.style.internal.get(ps, compPointer, true)
        const isVariantOrRefArray =
            variantUtils.shouldConsiderVariants(ps, compPointer, constants.DATA_TYPES.theme) || dataModel.refArray.isRefArray(ps, componentThemeData)
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const pageId = pagePointer && pagePointer.id

        if (isVariantOrRefArray) {
            applyToEachStyleReference(ps, componentThemeData, pageId, {includeReferences: false}, callback)
            return
        }

        if (componentThemeData) {
            callback(componentThemeData)
        }
    }

    /**
     * Applies callback to any connected style of reference(refArray, variant, breakpoint)
     * otherwise proceed reference types with {@see applyToEachStyleReference}
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {styleCallback} callback
     */
    const applyToEachComponentStyleAndReference = (ps, compPointer, callback) => {
        const componentThemeData = componentStylesAndSkinsAPI.style.internal.get(ps, compPointer, true)
        const isVariantOrRefArray =
            variantUtils.shouldConsiderVariants(ps, compPointer, constants.DATA_TYPES.theme) || dataModel.refArray.isRefArray(ps, componentThemeData)
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const pageId = pagePointer && pagePointer.id

        if (isVariantOrRefArray) {
            applyToEachStyleReference(ps, componentThemeData, pageId, {includeReferences: true}, callback)
            return
        }

        if (componentThemeData) {
            callback(componentThemeData)
        }
    }

    return {
        applyToEachComponentStyle,
        applyToEachStyleReference,
        applyToEachComponentStyleAndReference
    }
})
