define(['lodash', '@wix/santa-core-utils', 'documentServices/platform/common/constants', 'documentServices/hooks/hooks'], function (
    _,
    santaCoreUtils,
    constants,
    hooks
) {
    'use strict'

    /**
     * @param {ps} ps
     * @param {string} controllerId
     * @returns {any | string}
     */
    function getState(ps, controllerId) {
        const appStatePointer = ps.pointers.platform.getAppStatePointer()

        const controllerState = ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, controllerId))

        if (controllerState) {
            return controllerState
        }

        const templateControllerId = santaCoreUtils.displayedOnlyStructureUtil.getReferredCompId(controllerId)

        return templateControllerId
            ? ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, templateControllerId)) || constants.Controller.DEFAULT_STATE
            : constants.Controller.DEFAULT_STATE
    }

    /**
     * @param {ps} ps
     * @param stateMap
     */
    function setState(ps, stateMap) {
        const appStatePointer = ps.pointers.platform.getAppStatePointer()
        _.forEach(stateMap, function (controllerRefs, state) {
            _.forEach(controllerRefs, function (controllerRef) {
                ps.dal.full.set(ps.pointers.getInnerPointer(appStatePointer, controllerRef.id), state)
            })
        })
        hooks.executeHook(hooks.HOOKS.PLATFORM.AFTER_CONTROLLERS_SET_STATE, 'controller', [stateMap])
    }

    return {
        getState,
        setState
    }
})
