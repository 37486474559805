define(['documentServices/theme/common/constants', 'documentServices/theme/common/themeDAL'], function (themeConstants, themeDAL) {
    'use strict'

    const {PROPERTY_TYPE} = themeConstants

    function getAll(ps) {
        return themeDAL.getCollection(ps, PROPERTY_TYPE.COLOR)
    }

    function get(ps, index) {
        return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.COLOR, index)
    }

    function set(ps, index, val) {
        return themeDAL.setCollectionItem(ps, PROPERTY_TYPE.COLOR, index, val)
    }

    return {
        set,
        get,
        getAll
    }
})
