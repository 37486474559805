define(['lodash', 'documentServices/component/component'], function (_, component) {
    'use strict'

    function isVerticalResize(newLayout, currLayout) {
        if (newLayout && !_.isUndefined(newLayout.height) && currLayout) {
            return newLayout.height !== currLayout.height
        }
    }

    return function (ps, compPointer, newLayout) {
        if (isVerticalResize(newLayout, component.layout.get(ps, compPointer))) {
            component.properties.update(ps, compPointer, {
                minHeight: newLayout.height
            })
        }
    }
})
