define(['lodash', 'documentServices/metaSiteProvisioner/clientSpecMapChanger'], function (_, clientSpecMapChanger) {
    'use strict'

    const isConcurrencyError = metaSiteResponse => metaSiteResponse.success === false && metaSiteResponse.errorCode === -40103

    const run = function (func, changers = []) {
        function concurrentRunner(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) {
            let tries = 1
            const concurrentResolver = function (metaSiteResponse) {
                if (!metaSiteResponse) {
                    resolve()
                    return
                }

                if (metaSiteResponse.success === true) {
                    resolve({
                        changes: _([clientSpecMapChanger, ...changers])
                            .map(changer => changer(currentImmutableSnapshot, metaSiteResponse, currentSnapshotDal))
                            .flatten()
                            .value()
                    })
                } else if (isConcurrencyError(metaSiteResponse) && tries === 1) {
                    tries += 1
                    func(lastImmutableSnapshot, currentImmutableSnapshot, concurrentResolver, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
                } else {
                    resolve()
                }
            }

            func(lastImmutableSnapshot, currentImmutableSnapshot, concurrentResolver, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
        }

        return function (lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) {
            concurrentRunner(lastImmutableSnapshot, currentImmutableSnapshot, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal)
        }
    }

    return run
})
