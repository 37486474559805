import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import santaTypes from './santaTypes'
import coreUtilsLib from '@wix/santa-core-utils'

const classBasedStyleCollector = {}
warmupUtils.sessionFreezer.freeze(classBasedStyleCollector)

function getCompInfo(siteData, pageId, compStructure) {
    const styleItem = compStructure.styleId && siteData.getDataByQuery(compStructure.styleId, pageId, siteData.dataTypes.THEME)
    return {
        dataItem: compStructure.dataQuery && siteData.getDataByQuery(compStructure.dataQuery, pageId),
        propertiesItem: compStructure.propertyQuery && siteData.getDataByQuery(compStructure.propertyQuery, pageId, siteData.dataTypes.PROPERTIES),
        styleItem,
        structure: compStructure
    }
}

function addLoadedStyle(loadedStyles, styleId, skinName, componentType) {
    if (!loadedStyles[styleId]) {
        loadedStyles[styleId] = {skinName, componentType}
    }
}

function styleHasSkin(getStyleData, styleId) {
    return getStyleData(styleId)
}

function addStructureStylesAndSkins(structure, getStyleData, loadedStyles) {
    if (structure.styleId) {
        if (styleHasSkin(getStyleData, structure.styleId)) {
            addLoadedStyle(loadedStyles, structure.styleId, getStyleData(structure.styleId).skin, structure.componentType)
        } else {
            addLoadedStyle(loadedStyles, structure.styleId, structure.skin, structure.componentType)
        }
    } else if (structure.skin) {
        addLoadedStyle(loadedStyles, santaTypes.shortenStyleId(structure.skin), structure.skin, structure.componentType)
    }
}

function addClassBasedStyles(structure, getStyleData, siteData, loadedStyles, pageId) {
    if (structure.componentType && classBasedStyleCollector[structure.componentType]) {
        const compInfo = getCompInfo(siteData, pageId, structure)
        classBasedStyleCollector[structure.componentType](compInfo, getStyleData, siteData, loadedStyles, pageId)
    }
}

function addOverrideStyles(structure, getStyleData, loadedStyles) {
    if (structure.modes && structure.modes.overrides) {
        _.forEach(structure.modes.overrides, function (override) {
            if (override.styleId && styleHasSkin(getStyleData, override.styleId)) {
                addLoadedStyle(loadedStyles, override.styleId, getStyleData(override.styleId).skin, structure.componentType)
            }
        })
    }
}

function collectStyleIdsFromFullStructure(structure, getStyleData, siteData, loadedStyles, pageId, collectFromMobile?) {
    addStructureStylesAndSkins(structure, getStyleData, loadedStyles)
    addOverrideStyles(structure, getStyleData, loadedStyles)
    addClassBasedStyles(structure, getStyleData, siteData, loadedStyles, pageId)
    const children = coreUtilsLib.dataUtils.getChildrenData(structure, !!collectFromMobile)
    _(children)
        .compact()
        .forEach(function (child) {
            collectStyleIdsFromFullStructure(child, getStyleData, siteData, loadedStyles, pageId)
        })
}

/**
 *
 * @param {string} className
 * @param {function(data.compStructure, data.themeData, core.SiteData, Object.<string, string>, string)} collector
 */
function registerClassBasedStyleCollector(className, collector) {
    classBasedStyleCollector[className] = collector
}

/**
 * @class core.styleCollector
 */
export default {
    collectStyleIdsFromFullStructure,
    registerClassBasedStyleCollector
}
