define(['documentServices/constants/constants', 'documentServices/componentsMetaData/metaDataUtils'], function (constants, metaDataUtils) {
    'use strict'

    const STRIP_CONTAINER_SLIDE_SHOW = 'wysiwyg.viewer.components.StripContainerSlideShow'

    function canContain(isByStructure, ps, componentPointer, potentialContainee) {
        if (potentialContainee) {
            const containeeCompType = isByStructure ? potentialContainee.componentType : metaDataUtils.getComponentType(ps, potentialContainee)
            return metaDataUtils.isComponentSuitableForNonRenderingState(containeeCompType)
        }

        return false
    }

    return {
        enforceContainerChildLimitsByWidth: false,
        enforceContainerChildLimitsByHeight: false,
        moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
        resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
        fullWidth: true,
        fullWidthByStructure: true,
        canContain: canContain.bind(null, false),
        canContainByStructure: canContain.bind(null, true),
        mobileConversionConfig: {
            nestOverlayingSiblings: false,
            structuralItem: true,
            stackLayout: true,
            isScreenWidth: true,
            filterChildrenWhenHidden: true,
            preserveAspectRatio: false
        },
        allowedParentTypes: [STRIP_CONTAINER_SLIDE_SHOW]
    }
})
