define([
    'lodash',
    'documentServices/bi/bi',
    'documentServices/bi/errors.json',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/documentServicesDataFixer/fixers/styles/utils',
    'documentServices/utils/contextAdapter',
    'documentServices/variants/variantsUtils',
    'experiment'
], function (
    _,
    bi,
    biErrors,
    componentStyleAndSkinAPI,
    componentDetectorAPI,
    constants,
    dataModel,
    styleFixerUtils,
    contextAdapter,
    variantsUtils,
    experiment
) {
    const {getComponentType, getDefaultSystemStyleForComp} = styleFixerUtils

    const reportComponentWithoutDefaultStyleAndThrowError = (ps, compPointer, componentType) => {
        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        bi.error(ps, biErrors.COMPONENT_WITHOUT_DEFAULT_STYLE, {
            componentType,
            pageId
        })

        throw new Error(`componentType: ${componentType} does not have default system style, pageId: ${pageId}`)
    }

    const fixComponentsWithoutDefaultStyle = ps => compPointer => {
        const componentType = getComponentType(ps, compPointer)
        const styleIdByCompType = getDefaultSystemStyleForComp(ps, compPointer)
        if (styleIdByCompType) {
            return componentStyleAndSkinAPI.style.connectToThemeStyle(ps, compPointer, styleIdByCompType, _.noop)
        }

        reportComponentWithoutDefaultStyleAndThrowError(ps, compPointer, componentType)
    }

    const withoutDefaultStyle = ps => compPointer => {
        const isVariantsStyle = dataModel.isComponentPointsToRefArray(ps, compPointer, constants.DATA_TYPES.theme)
        if (!isVariantsStyle) {
            return false
        }

        const nonScopedStylePointer = variantsUtils.getComponentDataPointerConsideringVariants(ps, compPointer, constants.DATA_TYPES.theme)
        return !nonScopedStylePointer || !ps.dal.full.isExist(nonScopedStylePointer)
    }

    const getCompsWithoutDefaultStyle = ps =>
        _.uniqBy(componentDetectorAPI.getAllComponentsFromFull(ps, null, withoutDefaultStyle(ps), constants.VIEW_MODES.DESKTOP), 'id')

    return {
        exec(ps) {
            if (experiment.isOpen('dm_fixMissingDefaultStyles')) {
                try {
                    const compsWithoutDefaultStyle = getCompsWithoutDefaultStyle(ps)
                    _.forEach(compsWithoutDefaultStyle, fixComponentsWithoutDefaultStyle(ps))
                } catch (e) {
                    const err = new Error('Failed to migrate site add missing default style')
                    contextAdapter.utils.fedopsLogger.captureError(err, {
                        tags: {
                            addMissingDefaultStylesDataFixer: true
                        },
                        extras: {
                            originalError: e
                        }
                    })
                    throw e //should not get here
                }
            }
        },
        name: 'addMissingDefaultStylesDataFixer',
        version: 1
    }
})
