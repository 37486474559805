define(['lodash', 'documentServices/componentsMetaData/components/baseInputMetadata'], function (_, baseInputMetadata) {
    'use strict'

    return _.assign(
        {
            heightAuto: true
        },
        baseInputMetadata
    )
})
