/**
 * Created by bogdanl on 8/12/16.
 */

import _ from 'lodash'
import mediaPostConverter from '@wix/santa-ds-libs/src/wixappsClassics/src/core/data/converters/mediaPostConverter'

function transformMediaItemsResponse(compId, collectionId, responseData, currentValue, lang) {
    currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['items', 'referencedItems', 'unreferencedItems'])
    currentValue[compId] = _.map(responseData.payload.items, function (item) {
        return mediaPostConverter.getMediaPostCollection(item, collectionId)
    })

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}
    currentValue.items.converted = currentValue.items.converted || {}
    currentValue.items.converted[collectionId] = currentValue.items.converted[collectionId] || {}

    _.forEach(responseData.payload.items, function (item) {
        let fixedItem = mediaPostConverter.fixMediaPostDataRefs(item)
        fixedItem = mediaPostConverter.translateDefaultPosts(fixedItem, lang)

        fixedItem = mediaPostConverter.getPostWithoutCertainExcerptStyling(fixedItem)

        const currentItem = currentValue.items[collectionId][item._iid] || {}
        fixedItem = _.assign(currentItem, fixedItem)

        fixedItem = mediaPostConverter.addAuthorFieldWhenMissing(fixedItem)
        fixedItem = mediaPostConverter.getPostWithConvertedMobileTitle(fixedItem)

        mediaPostConverter.fixMasterPageIdInLinksInside(fixedItem)

        currentValue.items[collectionId][item._iid] = fixedItem
        mediaPostConverter.resolveCategories(currentValue, fixedItem)

        if (fixedItem._type === 'MediaPost') {
            const convertedItem = mediaPostConverter.convertMediaPost(fixedItem)
            currentValue.items.converted[collectionId][convertedItem._iid] = convertedItem
            mediaPostConverter.resolveCategories(currentValue, convertedItem)
        }

        const videoData = _.get(fixedItem, 'coverImageData.video', null)
        if (videoData && videoData.videoType === 'YOUTUBE') {
            _.set(fixedItem, 'coverImageData.video.imageSrc', mediaPostConverter.getYouTubePreviewUrl(videoData))
        }
    })

    _.forEach(responseData.payload.referencedItems, function (refItem, refItemKey) {
        const colId = refItemKey.split('/')[0]
        const iid = refItemKey.split('/')[1]
        currentValue.items[colId] = currentValue.items[colId] || {}
        currentValue.items[colId][iid] = refItem
    })

    return currentValue
}

export default transformMediaItemsResponse
