define([
    'lodash',
    '@wix/santa-core-utils',
    'core',
    'experiment',
    'wix-ui-santa/dsComponentsService.bundle',
    'documentServices/dsCompRegistrar/dsCompRegistrar',
    '@wix/editor-elements-registry/documentManagement',
    '@wix/editor-elements-registry/2.0/documentManagement',
    '@wix/editor-elements-registry/2.0/toolbox'
], function (_, santaCoreUtils, core, experiment, dsComponentsService, dsCompRegistrar, oldEditorElementsRegistry, newEditorElementsRegistry, registryToolbox) {
    'use strict'

    const {viewerCompsService} = core
    const LOAD_ALL_COMPONENTS = 'ALL'

    const loadAll = wixUiSantaBaseUrl => dsComponentsService.loadAll(wixUiSantaBaseUrl)
    const loadComponents = (wixUiSantaBaseUrl, componentsToLoad) =>
        Promise.all(
            _(componentsToLoad)
                .filter(dsComponentsService.exists)
                .map(componentType => dsComponentsService.load(componentType, wixUiSantaBaseUrl))
                .value()
        )

    // if linked when running tests in wix-ui-santa puppeteer, you'll get your local node_modules/wix-ui-santa
    const getWixUiSantaBaseUrl = () => `${requirejs.toUrl('wix-ui-santa')}/`

    const {getComponentsLibraries} = experiment.isOpen('dm_newRegistry') ? registryToolbox : oldEditorElementsRegistry

    const loadExternalComponentsFromEditorElements = ps => {
        const ptr = ps.pointers.general.getServiceTopology()
        const serviceTopology = ps.dal.get(ptr)
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const libraries = getComponentsLibraries(serviceTopology, currentUrl.full)
        const registryOptions = {libraries, dev: ps.siteAPI.isDebugMode(), mode: 'eager'}
        const dmCompRegistrarOptions = dsCompRegistrar.getRegistrarOptions(ps)
        if (!_.isEmpty(libraries)) {
            const editorElementsRegistry = experiment.isOpen('dm_newRegistry') ? newEditorElementsRegistry : oldEditorElementsRegistry
            return dsCompRegistrar.registerComponentsFromExternalRegistryWithOptions(editorElementsRegistry, registryOptions, dmCompRegistrarOptions)
        }
    }

    const loadExternalComponentsFromWixUiSanta = (ps, {componentsToLoad}) => {
        if (experiment.isOpen('registerComponentsDynamically') || ps.config.schemaDevMode) {
            const wixUiSantaBaseUrl = getWixUiSantaBaseUrl()
            const loadEverything = componentsToLoad === LOAD_ALL_COMPONENTS
            if (!loadEverything && !_.isArray(componentsToLoad)) {
                if (componentsToLoad) {
                    throw new Error(
                        `componentsToLoad in DS config must be either the string 'ALL' or an array of component types. received ${typeof componentsToLoad} instead`
                    )
                }
                return
            }

            const dmCompRegistrarOptions = dsCompRegistrar.getRegistrarOptions(ps)
            const loader = () => (loadEverything ? loadAll(wixUiSantaBaseUrl) : loadComponents(wixUiSantaBaseUrl, componentsToLoad))
            return loader().then(components => {
                _(components)
                    .flatMap()
                    .forEach(component => dsCompRegistrar.registerComponentWithOptions(component, dmCompRegistrarOptions))
            })
        }
    }

    const loadExternalComponentsForDS = async (ps, {componentsToLoad}) =>
        Promise.all([loadExternalComponentsFromEditorElements(ps), loadExternalComponentsFromWixUiSanta(ps, {componentsToLoad})]).catch(err =>
            santaCoreUtils.log.error('Could not load external components from external component registry', err)
        )

    function loadViewerComponents(ps, componentsToLoad) {
        return Promise.all(componentsToLoad.map(componentType => viewerCompsService.loadAndRegister(componentType))).catch(err => {
            santaCoreUtils.log.error(`Error loading viewer components during DS init: ${componentsToLoad}`, err)
        })
    }

    return {
        loadExternalComponentsForDS,
        loadViewerComponents
    }
})
