define(['documentServices/utils/utils', 'documentServices/tpa/utils/tpaUtils'], function (dsUtils, tpaUtils) {
    'use strict'

    const CONTROLLER_TYPE = 'platform.components.AppController'
    const APP_WIDGET_TYPE = 'platform.components.AppWidget'

    function isOOIController(componentType) {
        return tpaUtils.isTpaByCompType(componentType)
    }

    /**
     * @param {ps} ps
     * @param controllerRef
     */
    function verifyController(ps, controllerRef) {
        const componentType = dsUtils.getComponentType(ps, controllerRef)
        if (componentType !== CONTROLLER_TYPE && componentType !== APP_WIDGET_TYPE && !isOOIController(componentType)) {
            throw new Error('controllerRef component type is invalid - should be an AppController or AppWidget')
        }
    }

    return {
        isOOIController,
        verifyController
    }
})
