define(['ajv', '@wix/document-services-json-schemas', 'documentServicesSchemas/services/themeValidationHelper', '@wix/santa-core-utils', 'lodash'], function (
    ajv,
    documentServicesJsonSchemas,
    themeValidationHelper,
    warmupUtilsLib,
    _
) {
    'use strict'
    const {
        newSchemaService: {common},
        schemas: {
            default: {cssSchemas, commonSchemas}
        },
        namespaceMapping: {DATA_TYPES},
        schemaUtils: {convertSchemasToOldFormat}
    } = documentServicesJsonSchemas

    return schemasMap => {
        const {
            fonts: {getFontFamilyPermissions}
        } = warmupUtilsLib
        /** @type {{data: *, props: *}} */
        const validators = {data: null, props: null}
        const dataNamespaces = _.omit(DATA_TYPES, ['prop'])
        const dataSchemaNamespaces = _.values(dataNamespaces)

        const DATA_SCHEMAS_BY_DATA_TYPE = _.mapValues(dataNamespaces, ns => schemasMap[ns])

        //Hold this in a map:
        const PROPERTIES_SCHEMAS_TO_VALIDATE = schemasMap[DATA_TYPES.prop]
        const CSS_SCHEMAS_TO_VALIDATE = cssSchemas
        const COMMON_SCHEMA_TO_VALIDATE = commonSchemas

        function isReference(value) {
            return !value || _.isString(value)
        }

        const PSEUDO_TYPES_VALIDATIONS = {
            ref: isReference,
            weakRef: isReference,
            refList(value) {
                return _.every(value, isReference)
            },
            list(value) {
                return !value || _.isArray(value)
            },
            stringifyObject(value) {
                try {
                    JSON.parse(value)
                    return true
                } catch (e) {
                    return false
                }
            }
        }

        const CUSTOM_FORMAT_VALIDATIONS = {
            color(value) {
                return _.isNull(value) || themeValidationHelper.validateColor(value)
            },
            hexColor(value) {
                return _.isNull(value) || themeValidationHelper.validateHexColor(value)
            },
            numeric(value) {
                return /^\d+$/.test(value)
            },
            uri(value) {
                return _.isNull(value) || /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/.test(value)
            },
            font(value) {
                return _.isNull(value) || themeValidationHelper.validateFont(value)
            },
            'font-family'(value) {
                if (_.isEmpty(value)) {
                    return true
                }
                const fontPermissions = getFontFamilyPermissions(value)
                return fontPermissions === 'all'
            },
            border(value) {
                return isValidBorderWithColor(value)
            },
            padding(value) {
                return isValidBorderOrPaddingOrRadius(value)
            },
            radius(value) {
                return isValidBorderOrPaddingOrRadius(value)
            },
            webThemeUrl(value) {
                return this.themeUrl(value)
            },
            themeUrl(value) {
                return _.isEmpty(value) || /^[\/\-|0-9|a-z|A-Z]*$/.test(value)
            },
            cssMeasure(value) {
                return value && /^\d+(.\d+)?(em|px)?$/.test(value)
            },
            cssMeasureWithUnit(value) {
                return value && /^(-?\d+(.\d+)?(px|em|pt|ex|in|cm|mm|pc))$|^normal$/.test(value)
            }
        }

        const CUSTOM_KEYWORDS_VALIDATIONS = {
            pseudoType: {
                validate(pseudoTypeNames, value) {
                    return _.some(pseudoTypeNames, function (pseudoTypeName) {
                        return PSEUDO_TYPES_VALIDATIONS[pseudoTypeName] ? PSEUDO_TYPES_VALIDATIONS[pseudoTypeName](value) : true
                    })
                }
            }
        }

        const aliasToCompKeyMap = new Map()

        const getComponentType = compKey => {
            const compDefs = schemasMap.definition
            const compDef = compDefs[compKey] || compDefs[aliasToCompKeyMap.get(compKey)]
            return compDef?.type
        }

        const updateAliasMap = compKey => {
            const compDef = schemasMap.definition[compKey]
            if (compDef?.aliases) {
                compDef.aliases.forEach(alias => {
                    aliasToCompKeyMap.set(alias, compKey)
                })
            }
        }

        Object.keys(schemasMap.definition || {}).forEach(compKey => {
            updateAliasMap(compKey)
        })

        function isValidBorderWithColor(value) {
            if (_.isEmpty(value)) {
                return true
            }
            value = value.toLowerCase()
            const borderSizeExp = '(0|([0-9]*([.][0-9]+){0,1}(px|em)[\\s]*){1,4})'
            const borderStyleExp = '(solid|dashed|dotted|double|groove|inset|none|outset|ridge){0,1}[\\s]*'
            const borderColorExp = '([\\[{]color_([0-9]{1,2}|100)[}\\]]|(#(([0-9|a-f]){3}){1,2}){0,1})'
            const expression = `^${borderSizeExp}${borderStyleExp}${borderColorExp}$`
            return new RegExp(expression).test(value)
        }

        function isValidBorderOrPaddingOrRadius(value) {
            if (_.isEmpty(value)) {
                return true
            }
            value = value.split(' ')
            return _.includes([1, 2, 3, 4], value.length) && !_.includes(_.map(value, isValidCssSize), false)
        }

        function isValidCssSize(value, index, collection) {
            if (collection.length === 1) {
                return /^([0-9]*)(px|em){0,1}$/.test(value)
            }
            return /^([0-9]*)(px|em){1}$/.test(value)
        }

        function addKeywordToValidator(validator, keywordDefinitionObject, keywordName) {
            validator.addKeyword(keywordName, keywordDefinitionObject)
        }

        function addFormatToValidator(validator, formatFunctionValidationFunction, formatName) {
            validator.addFormat(formatName, formatFunctionValidationFunction)
        }

        function createAjv() {
            const validator = ajv({useDefaults: true})
            validator.addSchema(common, 'common.json')
            return validator
        }

        function initialize() {
            validators.data = createAjv()
            validators.props = createAjv()

            _.forEach([validators.data, validators.props], function (validator) {
                _.forEach(CUSTOM_KEYWORDS_VALIDATIONS, addKeywordToValidator.bind(null, validator))
                _.forEach(CUSTOM_FORMAT_VALIDATIONS, addFormatToValidator.bind(null, validator))
            })
            // because duplicate schema names are not tolerated and data and design schemas have common schema names:
            const dataSchemasToAdd = _.clone(DATA_SCHEMAS_BY_DATA_TYPE) //shallow clone
            dataSchemasToAdd.design = _.omit(DATA_SCHEMAS_BY_DATA_TYPE.design, _.keys(DATA_SCHEMAS_BY_DATA_TYPE.data))

            const schemasToValidate = _.map([COMMON_SCHEMA_TO_VALIDATE, CSS_SCHEMAS_TO_VALIDATE, ..._.values(dataSchemasToAdd)], schemaMapItem =>
                _.omit(schemaMapItem, ['$id'])
            )
            _.forEach(schemasToValidate, function (schema) {
                _.forEach(schema, validators.data.addSchema.bind(validators.data))
            })

            _.forEach(PROPERTIES_SCHEMAS_TO_VALIDATE, validators.props.addSchema.bind(validators.props))
        }

        function initializeIfNeeded() {
            if (!validators.data) {
                initialize()
            }
        }

        /**
         * Validate an object against a schema name.
         * @param schemaName the schema name to validate against.
         * @param dataItem the dataItem to validate
         * @param schemaType data/props
         */
        function validate(schemaName, dataItem, schemaType) {
            const {errors} = validateDataItem(schemaName, dataItem, schemaType)
            if (errors) {
                throw new Error(
                    JSON.stringify(
                        _.map(errors, function (e) {
                            return _.pick(e, ['message', 'dataPath', 'keyword', 'schemaPath'])
                        })
                    )
                )
            }
        }

        function isDataSchema(schemaOrigin) {
            return _.includes(dataSchemaNamespaces, schemaOrigin)
        }

        function isPropertySchema(schemaOrigin) {
            return schemaOrigin === 'props' || schemaOrigin === 'properties'
        }

        function validateDataItem(schemaName, dataItem, validationType) {
            initializeIfNeeded()
            if (isDataSchema(validationType)) {
                return {isValid: validators.data.validate(schemaName, dataItem), errors: validators.data.errors}
            }
            if (isPropertySchema(validationType)) {
                return {isValid: validators.props.validate(schemaName, dataItem), errors: validators.props.errors}
            }
            return {isValid: false, errors: [new Error(`Invalid validationType: '${validationType}'`)]}
        }

        function isDataValid(schemaName, dataItem, origin) {
            return validateDataItem(schemaName, dataItem, origin).isValid
        }

        const systemStyles = _(schemasMap.definition).reduce((acc, definition) => _.assign(acc, definition.styles), {})

        function registerComponentDefinition(componentType, componentDefinition) {
            const newCompDef = componentDefinition[componentType]
            if (newCompDef.type !== 'Container') {
                newCompDef.type = 'Component'
            }
            schemasMap.definition[componentType] = newCompDef
            _.assign(systemStyles, newCompDef.styles)
            updateAliasMap(componentType)
        }

        const isSystemStyle = id => !!systemStyles[id]

        function getDefinition(componentType) {
            return schemasMap.definition[componentType]
        }

        function registerDataSchemasIfNeeded(_dataSchemas) {
            initializeIfNeeded()
            _.forOwn(_dataSchemas, (schema, schemaName) => {
                validators.data.removeSchema(schemaName)
                validators.data.addSchema(schema, schemaName)
                schemasMap[DATA_TYPES.data][schemaName] = schema
            })
        }

        function registerPropertiesSchemasIfNeeded(_propertiesSchemas) {
            _.forOwn(_propertiesSchemas, (schema, schemaName) => {
                validators.props.removeSchema(schemaName)
                validators.props.addSchema(schema, schemaName)
                schemasMap[DATA_TYPES.prop][schemaName] = schema
            })
        }

        const registerDataTypeSchema = (schemaToRegister, schemaType) => {
            initializeIfNeeded()
            schemasMap[schemaType] = schemaToRegister
            _.forEach(schemaToRegister, (schema, schemaName) => {
                validators.data.addSchema(schema, schemaName)
            })
            dataSchemaNamespaces.push(schemaType)
        }

        const getSchema = (schemaType, schemaName) =>
            schemasMap[schemaType][schemaName] || commonSchemas[schemaName] || common[_.replace(schemaName, 'common.json#/', '')]

        const containerTypesSet = new Set(['Page', 'Container', 'Document', 'RepeaterContainer', 'RefComponent'])
        const isContainer = compKey => containerTypesSet.has(getComponentType(compKey))
        const isPage = compKey => getComponentType(compKey) === 'Page'
        const isRepeater = compKey => getComponentType(compKey) === 'RepeaterContainer'
        const isRefComponent = compKey => getComponentType(compKey) === 'RefComponent'

        return {
            getDefinitionByPredicate(lodashPredicate) {
                return _.find(schemasMap.definition, lodashPredicate)
            },
            isSystemStyle,
            //createDefaultItem, //replaces resolveDefaultItem in dataValidators

            registerComponentDefinitionAndSchemas(componentType, {componentDefinition, dataSchemas: _dataSchemas, propertiesSchemas: _propertiesSchemas}) {
                registerComponentDefinition(componentType, componentDefinition)
                registerDataSchemasIfNeeded(convertSchemasToOldFormat(_dataSchemas))
                registerPropertiesSchemasIfNeeded(convertSchemasToOldFormat(_propertiesSchemas))
            },
            getSchema,
            hasSchemaForDataType(namespace, dataTypeName) {
                return !_.isNil(getSchema(namespace, dataTypeName))
            },
            registerDataTypeSchema,
            getDefinition,
            validate,
            isValid: isDataValid,
            isContainer,
            isPage,
            isRepeater,
            isRefComponent,

            //remove validators from being exposed, do it on validate
            get validators() {
                initializeIfNeeded()
                return validators
            },

            reset: initialize // only for tests
        }
    }
})
