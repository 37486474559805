import layout from '@wix/santa-ds-libs/src/layout'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {Preloader as Composite} from '@wix/wix-base-ui/lib/composites/composites'
import Preloader from '@wix/wix-base-ui/lib/controls/preloader'
import systemStyle from './systemStyles.json'

const {createDOMPatchers, specificComponents} = layout
const {menuLayout} = specificComponents

const SPECIFIC_LAYOUTS = {
    'wysiwyg.viewer.components.menus.DropDownMenu': menuLayout.dropDown,
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': menuLayout.vertical
}

const MINI_SITE_CONTAINER_ID_PREFIX = 'miniSiteContainer_'

const MINI_SITE_CLASS = 'mini-site-container-css-class'

const miniSiteSelector = `.${MINI_SITE_CLASS}`

const zeroMinWidth = 'min-width: 0px !important;'

const css = `
    ${miniSiteSelector} [id$="__more__"]: {
        position: absolute;
        visibility: hidden;
    }

    ${miniSiteSelector} footer {
        ${zeroMinWidth}
    }

    ${miniSiteSelector} header {
        ${zeroMinWidth}
        margin-top: 0px !important;
    }
`

const addCssNode = _.once((context, cssContent) => {
    const doc = context.document
    const node = doc.createElement('style')
    node.type = 'text/css'
    node.innerHTML = cssContent
    doc.head.appendChild(node)
})

const mapFromPairs = (collection: any, func: any) => _(collection).map(func).fromPairs().value()

const isPlainObject = (obj: any) => _.isObject(obj) && !_.isFunction(obj) && !_.isArrayLike(obj)

const extractRefs = (data: any) => {
    const refs = {}
    _.forEach(data, (v, k) => {
        if (isPlainObject(v) && k !== 'metaData') {
            refs[k] = v
        }
    })

    const newData = _.omit(data, Object.keys(refs))
    const newRefs = {}

    _.forEach(refs, (v, k) => {
        const newKey = _.uniqueId('dataItem-')
        newData[k] = `#${newKey}`
        newRefs[newKey] = refs[k]
    })

    return {refs: newRefs, data: newData}
}

const unResolveDocumentDataRefs = (docData: any) => {
    const newData = {}
    _.forEach(docData, (v, k) => {
        if (isPlainObject(v)) {
            const {refs, data} = extractRefs(v)
            newData[k] = data
            _.assign(newData, refs)
        } else {
            newData[k] = v
        }
    })
    return newData
}

const convertData = ({previewData, compClasses, themeData, containerId}: any) => {
    const {comps} = previewData
    const compIds = comps.map((comp: any) => comp.compFullStructure.id)

    const container = {
        id: containerId,
        componentType: 'wysiwyg.viewer.components.Group',
        layout: {
            x: 198,
            y: 88.5,
            fixedPosition: false,
            width: 0,
            height: 0,
            scale: 1,
            rotationInDegrees: 0
        },
        type: 'Container',
        components: compIds,
        skin: 'wysiwyg.viewer.components.GroupSkin',
        activeModes: {}
    }

    const structure = mapFromPairs(comps, (comp: any) => [comp.compFullStructure.id, comp.compFullStructure])
    structure[containerId] = container
    const componentStyles = mapFromPairs(comps, (comp: any) => [comp.styleId, comp.customStyle])
    const customComponentStyles = _.pickBy(componentStyles, style => !_.has(themeData, style.id))

    const baseDocData = unResolveDocumentDataRefs(previewData.data)
    const additionalDocData = _.mapKeys(previewData.additionalMatserPageData, 'id')
    const documentData = {
        ...baseDocData,
        ...additionalDocData
    }

    const theme_data = {
        ...systemStyle,
        ...themeData,
        ...customComponentStyles
    }

    const component_properties = {
        ...previewData.props,
        // Emulate the vertical menu fixer functionality
        // If this constant will not suffice for the mini sites, we might have to perform the actual
        // calculation that the fixer performs.
        // Specifically, the calculation in question is this line from
        // `@wix/wix-santa/packages/dataFixer/src/main/plugins/verticalMenuFixer.js`:
        // const newItemHeight = coreUtils.verticalMenuCalculations.getItemHeight(comp.layout.height, separator, menuItemsCount, currentSkinExports);
        verticalDefaultProp: {
            menuItemHeight: 49
        }
    }

    return {
        structure,
        data: {
            theme_data,
            document_data: documentData,
            component_properties
        },
        compClasses,
        rootCompIds: [containerId],
        rootStyleIds: [] as any[]
    }
}

const getElement = (id: string) => window.top?.document.getElementById(id)
const getHeight = (id: string) => getElement(id)?.offsetHeight
const getTop = (id: string) => getElement(id)?.offsetTop
const scrollHeight = (id: string) => getElement(id)?.scrollHeight

const replaceThemeColor = (color: string, categoryId: string, replaceColorFunction: (a: string, b: string) => any) => {
    const hexColor = replaceColorFunction(color, categoryId)

    if (hexColor?.wasReplaced) {
        return hexColor.value
    }

    return color
}

const adjustColorsInThemeData = ({original, replaceColorFunction, categoryId}: any) => {
    const theme = original.THEME_DATA
    const newColors = theme.color.map((color: any) => replaceThemeColor(color, categoryId, replaceColorFunction))

    return {
        ...original,
        THEME_DATA: {
            ...theme,
            color: newColors
        }
    }
}

function createNodeMap(root: any): any {
    const {id, children} = root
    const base = id ? {[id]: root} : {}
    return _.assign({}, base, ..._.map(children, createNodeMap))
}

function createMeasureMap(nodeMap: any, compStructure: any) {
    const height = _.mapValues(nodeMap, (v, k) => compStructure[k]?.layout?.height ?? v.offsetHeight)
    const width = _.mapValues(nodeMap, 'offsetWidth')
    const left = _.mapValues(nodeMap, 'offsetLeft')
    const absoluteTop = _.mapValues(nodeMap, 'offsetTop')
    return {
        custom: {},
        height: {
            ...height,
            screen: window.top?.document.documentElement.clientHeight
        },
        width,
        left,
        absoluteTop
    }
}

function performMenuLayout({id, containerId, fullStructuresByIds, measure, patch}: any) {
    const node = getElement(id)
    const nodeMap = createNodeMap(node)
    const menuContainerId = `${id}menuContainer`
    if (!nodeMap[menuContainerId]) {
        nodeMap[menuContainerId] = window.top?.document.querySelector(`#${containerId} ul`)
    }
    const measureMap = createMeasureMap(nodeMap, fullStructuresByIds)

    measure(window, id, measureMap, nodeMap, {}, {isMobileView: _.stubFalse})

    const patchers = createDOMPatchers(nodeMap)
    patch(id, patchers, measureMap, {}, {isMobileView: _.stubFalse})
}

/** Emulate the layout phase for menus
 *
 * Unlike other mini site components, the menus are heavily reliant on the layout phase in order to render their
 * internal structure correctly.
 *
 * This function emulates the layout phase for those components by creating basic `nodeMap` and `measureMap` inputs,
 * then passing them to the individual `measure` and `patch` functions
 */
function performMenuLayouts(rawData: any, containerId: string) {
    const fullStructuresByIds = _(rawData.comps).map('compFullStructure').keyBy('id').value()

    _.forEach(SPECIFIC_LAYOUTS, ({measure, patch}, compType) => {
        _(fullStructuresByIds)
            .filter({componentType: compType})
            .map('id')
            .forEach(id => performMenuLayout({id, containerId, fullStructuresByIds, measure, patch}))
    })
}

const createMiniSitePreview = ({getBoltFragment, hostReact, themeData, serviceTopology, getCompClasses}: any): any => {
    const containerId = _.uniqueId(MINI_SITE_CONTAINER_ID_PREFIX)
    addCssNode(window.top, css)

    const BoltFragment = getBoltFragment(hostReact, serviceTopology)

    class Preview extends hostReact.Component {
        constructor(props: any) {
            super(props)
            this.state = {
                compClasses: {},
                doneLoadingClasses: false,
                ready: false
            }
        }

        componentDidMount() {
            const rawData = this.props.preview()
            const compsClassesToDownload = _(rawData.comps).map('compFullStructure.componentType').uniq().value()
            getCompClasses(compsClassesToDownload)
                .then((classes: any) => {
                    this.setState({
                        compClasses: classes,
                        doneLoadingClasses: true
                    })
                })
                .catch((e: any) => {
                    console.error(`Error when trying to load comp classes ${compsClassesToDownload} for mini site: ${e}`)
                })
        }

        render() {
            const rawData = this.props.preview()
            const loader = hostReact.createElement(Composite, {height: rawData.totalSectionHeight}, hostReact.createElement(Preloader, null))
            if (!this.state.doneLoadingClasses) {
                return loader
            }

            const adjustedThemeData = adjustColorsInThemeData({
                original: themeData,
                replaceColorFunction: this.props.getColorToReplaceIfNeeded,
                categoryId: rawData.categoryId
            })

            const dataProps = convertData({
                previewData: rawData,
                compClasses: this.state.compClasses,
                themeData: adjustedThemeData,
                containerId
            })

            const props: Record<string, any> = {
                ...dataProps,
                extraEventsHandlers: this.props.extraEventsHandlers,
                rendererModel: {
                    runningExperiments: {}
                }
            }

            const compIds = rawData.comps.map((comp: any) => comp.compFullStructure.id)
            const onReady = () => {
                const container = getElement(containerId)
                if (!container) {
                    return
                }
                container.className += ` ${MINI_SITE_CLASS}`

                performMenuLayouts(rawData, containerId)

                if (this.props.onSiteReady) {
                    this.props.onSiteReady(scrollHeight(containerId), {
                        top: mapFromPairs(compIds, (id: string) => [id, getTop(id)]),
                        height: mapFromPairs(compIds, (id: string) => [id, getHeight(id)])
                    })
                }
                this.setState({ready: true})
            }

            const fragmentAndLoader = this.renderFragmentAndLoaderIfNeeded(
                props,
                onReady,
                !this.state.doneLoadingClasses || !this.state.ready ? loader : undefined,
                this.state.ready
            )
            return fragmentAndLoader
        }

        private renderFragmentAndLoaderIfNeeded(props: Record<string, any>, onReady: () => void, loader?: HTMLElement, isReady = false) {
            const containerProps: Record<string, any> = {}
            if (!isReady) {
                containerProps.style = {visibility: 'hidden'}
            }
            const fragment = hostReact.createElement(BoltFragment, {...props, onReady})
            const fragmentContainer = hostReact.createElement('div', containerProps, fragment)
            return hostReact.createElement('div', {}, fragmentContainer, loader)
        }
    }

    Preview.propTypes = {
        preview: PropTypes.func.isRequired
    }

    return Preview
}

const createMiniSitePreviewTB = ({hostReact, themeData, serviceTopology, getViewerFragment}: any): any => {
    const containerId = _.uniqueId(MINI_SITE_CONTAINER_ID_PREFIX)
    addCssNode(window.top, css)

    class Preview extends hostReact.Component {
        constructor(props: any) {
            super(props)
            this.state = {
                tbFragment: null,
                ready: false
            }
        }

        buildProps(rawData: any) {
            const adjustedThemeData = adjustColorsInThemeData({
                original: themeData,
                replaceColorFunction: this.props.getColorToReplaceIfNeeded,
                categoryId: rawData.categoryId
            })

            const dataProps = convertData({
                previewData: rawData,
                compClasses: this.state.compClasses,
                themeData: adjustedThemeData,
                containerId
            })

            const compIds = rawData.comps.map((comp: any) => comp.compFullStructure.id)
            const onReady = () => {
                const container = getElement(containerId)
                if (!container) {
                    return
                }
                container.className += ` ${MINI_SITE_CLASS}`

                // performMenuLayouts(rawData, containerId)

                if (this.props.onSiteReady) {
                    this.props.onSiteReady(scrollHeight(containerId), {
                        top: mapFromPairs(compIds, (id: string) => [id, getTop(id)]),
                        height: mapFromPairs(compIds, (id: string) => [id, getHeight(id)])
                    })
                }
                this.setState({ready: true})
            }

            return {
                ...dataProps,
                extraEventsHandlers: this.props.extraEventsHandlers,
                rendererModel: {
                    runningExperiments: {}
                },
                onReady
            }
        }

        componentDidMount() {
            const rawData = this.props.preview()
            const props = this.buildProps(rawData)
            getViewerFragment(hostReact, serviceTopology, props)
                .then((tbFragment: any) => {
                    this.setState({tbFragment})
                })
                .catch((e: any) => {
                    console.error(`Error when trying to load tbFragment ${e}`)
                })
        }

        render() {
            const rawData = this.props.preview()
            const Loader = hostReact.createElement(Composite, {height: rawData.totalSectionHeight}, hostReact.createElement(Preloader, null))
            const TBFragmentContainer = hostReact.createElement('div', this.state.ready ? {} : {style: {visibility: 'hidden'}}, this.state.tbFragment)

            return this.state.tbFragment ? TBFragmentContainer : Loader
        }
    }

    Preview.propTypes = {
        preview: PropTypes.func.isRequired
    }

    return Preview
}

export {convertData, createMiniSitePreview, createMiniSitePreviewTB}
