/**
 * Created by eitanr on 6/24/14.
 */

export default {
    trim(str) {
        return str.replace(/^\s+|\s+$/g, '')
    },
    round(val) {
        return Math.round(val * 1000000) / 1000000
    }
}
