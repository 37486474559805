define(['lodash', 'documentServices/component/component', 'documentServices/componentDetectorAPI/componentDetectorAPI'], function (
    _,
    component,
    componentDetectorAPI
) {
    'use strict'

    function fixOrphanSlide(ps, slidesContainerType, slideType) {
        const allSlidesPointers = componentDetectorAPI.getComponentByType(ps, slideType)

        _.forEach(allSlidesPointers, slidePointer => {
            const slideParent = ps.dal.full.get(ps.pointers.full.components.getParent(slidePointer))
            const isSlideParentIllegal = _.get(slideParent, 'componentType') !== slidesContainerType

            if (isSlideParentIllegal) {
                if (ps.dal.isExist(slidePointer)) {
                    component.remove(ps, slidePointer)
                } else {
                    component.removeFromFull(ps, slidePointer)
                }
            }
        })
    }

    function exec(ps) {
        fixOrphanSlide(ps, 'wysiwyg.viewer.components.StripContainerSlideShow', 'wysiwyg.viewer.components.StripContainerSlideShowSlide')
        fixOrphanSlide(ps, 'wysiwyg.viewer.components.BoxSlideShow', 'wysiwyg.viewer.components.BoxSlideShowSlide')
    }

    return {
        exec,
        name: 'orphanSlideDataFixer',
        version: 1
    }
})
