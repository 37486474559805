define(['lodash'], function (_) {
    'use strict'

    const setAppPendingAction = (ps, appDefinitionId, pendingAction) => {
        const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)
        const state = ps.dal.get(appStatePointer) || {}
        state.pendingAction = pendingAction

        ps.dal.set(appStatePointer, state)
    }

    const getAppPendingAction = (ps, appDefinitionId) => {
        const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)

        return _.get(ps.dal.get(appStatePointer), 'pendingAction')
    }

    const clearPendingActions = ps => {
        const appsStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
        const appsState = ps.dal.get(appsStatePointer) || {}
        _.forEach(appsState, state => {
            delete state.pendingAction
        })

        ps.dal.set(appsStatePointer, appsState)
    }

    const clearAppPendingAction = (ps, appDefinitionId) => {
        const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefinitionId)
        const state = ps.dal.get(appStatePointer) || {}
        delete state.pendingAction

        ps.dal.set(appStatePointer, state)
    }

    const setUnusedApps = (ps, unusedApps) => {
        _.forEach(unusedApps, unusedApp => {
            const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(unusedApp.appDefinitionId)
            const state = ps.dal.get(appStatePointer) || {}
            state.unused = true
            ps.dal.set(appStatePointer, state)
        })
    }

    const getAppsState = ps => {
        const appsStatePointer = ps.pointers.platform.getAppsInstallationStatePointer()
        const appsState = ps.dal.get(appsStatePointer) || {}

        return appsState
    }

    const setAppAsUsed = (ps, appDefId) => {
        const appStatePointer = ps.pointers.platform.getAppInstallationStatePointer(appDefId)
        const state = ps.dal.get(appStatePointer) || {}
        delete state.unused
        ps.dal.set(appStatePointer, state)
    }

    return {
        getAppPendingAction,
        setAppPendingAction,
        clearPendingActions,
        clearAppPendingAction,
        setUnusedApps,
        setAppAsUsed,
        getAppsState
    }
})
