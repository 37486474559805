import _ from 'lodash'

export default id => {
    const {document} = window
    const node = document.getElementById(`${id}`)
    if (!node) {
        return _.noop
    }
    const placeholder = document.getElementById(`${id}-placeholder`)
    const isFixed = (node.dataset.state || '').match(/\d*fixedPosition\d*/)
    const headerTop = _.toNumber(node.dataset.headerTop) || 0
    const height = isFixed ? node.offsetHeight : node.offsetHeight + headerTop
    return node && placeholder && isFixed
        ? () => {
              placeholder.style.height = `${height}px`
          }
        : _.noop
}
