define([
    'documentServices/layoutPlugins/shapeMeasuring',
    'documentServices/layoutPlugins/textMeasuring',
    'documentServices/layoutPlugins/groupMeasuring',
    'documentServices/layoutPlugins/contentAreaMeasuring',
    'documentServices/layoutPlugins/verticalLineMeasuring',
    'documentServices/layoutPlugins/appWidgetMeasuring',
    'documentServices/layoutPlugins/pagePlugins'
], function (shapeMeasuring, textMeasuring) {
    'use strict'

    return {
        initialize(ps) {
            textMeasuring.initialize(ps)
            shapeMeasuring.initialize(ps)
        }
    }
})
