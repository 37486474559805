define([
    'lodash',
    'documentServices/tpa/handlers/tpaHandlers',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/tpa/services/tpaPostMessageCommon',
    'documentServices/utils/contextAdapter'
], function (_, handlers, clientSpecMapService, tpaEventHandlersService, tpaUtils, tpaPostMessageCommon, contextAdapter) {
    'use strict'
    const documentServicesHandlersThatAreCalledFromAppIframe = {
        isSupported: true,
        registerEventListener: true,
        getInstalledInstance: true,
        resizeComponent: true,
        revalidateSession: true
    }

    const tpaEditorPreviewHandlers = {
        openSettingsDialog: true,
        isApplicationInstalled: true,
        isFullWidth: true,
        onReady: true,
        isCustomApplicationPermissionsGranted: true,
        isGroupApplicationPermissionsGranted: true
    }

    const documentServicesHandlersThatAvailableOnlyInEditor = {
        setValue: true,
        removeValue: true,
        addApplication: true
    }

    const superAppsOnlyHandlers = {
        getInstalledInstance: true,
        addApplication: true
    }

    const shouldHandleMessage = function (ps, msg) {
        const documentServicesHandlersThatOverridesViewerHandlers = _.get(contextAdapter, 'documentServicesHandlersThatOverridesViewerHandlers')
        const editorPreviewHandler = tpaEditorPreviewHandlers[msg.type]
        const documentServicesHandlers = documentServicesHandlersThatAreCalledFromAppIframe[msg.type]
        const documentServicesHandlersThatOverrideViewer = documentServicesHandlersThatOverridesViewerHandlers[msg.type]
        const fromSettings = isFromSettings(msg)
        const componentViewModePointer = ps.pointers.general.getRenderFlag('componentViewMode')
        const componentViewMode = ps.dal.get(componentViewModePointer)
        const documentServicesHandlersAvailableInEditor = documentServicesHandlersThatAvailableOnlyInEditor[msg.type] && componentViewMode === 'editor'
        const isAppAllowedMethod = !superAppsOnlyHandlers[msg.type] || clientSpecMapService.isSuperAppByCompId(ps, msg.compId)

        return (
            (documentServicesHandlersThatOverrideViewer ||
                documentServicesHandlers ||
                editorPreviewHandler ||
                fromSettings ||
                documentServicesHandlersAvailableInEditor) &&
            isAppAllowedMethod &&
            handlers[msg.type]
        )
    }

    const isFromSettings = msg => msg.originFrame === 'editor'

    const callHandler = function (ps, msg, response) {
        const msgType = tpaPostMessageCommon.fixOldPingPongMessageType(msg.type)

        if (shouldHandleMessage(ps, msg)) {
            // @ts-ignore
            handlers[msgType].apply(this, [ps, msg, response])
            if (isEventSupported(msg)) {
                const origin = isFromSettings(msg) ? 'editor' : 'preview'
                tpaUtils.sendBIEvent(ps, msg, origin)
            }
        }
    }

    const isEventSupported = function (msg) {
        if (msg.type === 'registerEventListener') {
            return _.includes(tpaEventHandlersService.supportedEvents, msg.data.eventKey)
        }
        return true
    }

    const init = function (ps) {
        if (typeof window !== 'undefined') {
            // @ts-ignore
            window.addEventListener('message', tpaPostMessageCommon.handleTPAMessage.bind(this, ps, null, callHandler), false)
            ps.siteAPI.flushDsBeforeLoadMessagesQueue(event =>
                tpaPostMessageCommon.handleTPAMessage.call(null, ps, null, callHandler, event, tpaEditorPreviewHandlers)
            )
        }
    }

    return {
        init,
        callHandler
    }
})
