define([
    'lodash',
    'santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/constants/constants',
    'documentServices/component/component'
], function (_, santaCoreUtils, dataModel, constants, component) {
    'use strict'

    const {displayedOnlyStructureUtil} = santaCoreUtils
    const {REPEATER_TYPES} = constants

    const isRepeater = (ps, compPointer) => component.getType(ps, compPointer) === REPEATER_TYPES.REPEATER

    const getRepeaterItems = (ps, compPointer) => {
        const repeaterPointer = ps.pointers.components.getAncestorByPredicate(compPointer, parentPointer => isRepeater(ps, parentPointer))
        return _.get(dataModel.getDataItem(ps, repeaterPointer), 'items', [])
    }

    const addRepeatedItemsDataOverridesIfNeeded = (ps, compToAddPointer, newDataItemId, dataItem, pageId, dataSetter) => {
        const isRepeatedItem = displayedOnlyStructureUtil.isRepeatedComponent(compToAddPointer.id)
        const repeaterItems = isRepeatedItem && getRepeaterItems(ps, compToAddPointer)
        const dataIdsToHandle = isRepeatedItem ? _.map(repeaterItems, itemId => displayedOnlyStructureUtil.getUniqueDisplayedId(newDataItemId, itemId)) : []
        _.forEach([newDataItemId, ...dataIdsToHandle], dataId => {
            dataSetter(ps, pageId, dataItem, dataId)
        })
    }

    return {
        isRepeater,
        getRepeaterItems,
        addRepeatedItemsDataOverridesIfNeeded
    }
})
