define(['lodash', 'document-services-schemas'], function (_, documentServicesSchemas) {
    'use strict'

    const {schemasService} = documentServicesSchemas.services

    const removeDraftAnnotations = item => {
        delete item._isDraftItem
        delete item._draftRefs
    }

    const addDraftAnnotations = (namespace, dataItem) => {
        if (schemasService.isDraftDataSchema(namespace, dataItem.type)) {
            dataItem._isDraftItem = true
            const refInfos = schemasService.extractReferenceFieldsInfo(namespace, dataItem.type, dataItem)
            if (refInfos && refInfos.length) {
                dataItem._draftRefs = _.map(refInfos, info => _.pick(info, ['jsonPointer', 'path']))
            }
        }
    }

    return {
        removeDraftAnnotations,
        addDraftAnnotations
    }
})
