let shouldApplyAutomationAttributes = false

/**
 * @class wixappsCore.wixappsConfiguration
 */
export default {
    shouldApplyAutomationAttributes(props) {
        return shouldApplyAutomationAttributes && props.isQAMode
    },

    applyAutomationAttributes() {
        shouldApplyAutomationAttributes = true
    }
}
