import shouldShowDebugInformation from './shouldShowDebugInformation'

let containerElm
let viewerName = ''
const STATES = {
    PREWARMUP: 'prewarmup',
    WARMUP: 'warmup',
    SUCCESS: 'success',
    DIVERGE: 'diverge',
    RENDERING: 'rendering',
    CLIENT: 'client',
    CLIENTFALLBACK: 'client fallback',
    STANDBY: 'standby'
}

function init(name, onClick) {
    viewerName = name
    if (containerElm || shouldShowDebugInformation() === false) {
        return
    }
    createStyles()
    createIndicator(onClick)
    updateState(STATES.PREWARMUP)
}

function createStyles() {
    const style = window.document.createElement('style')
    style.appendChild(window.document.createTextNode('')) // WebKit hack
    window.document.body.appendChild(style)
    style.sheet.insertRule(
        '#warmup-indicator { padding: 6px; font-size: 12px; position: fixed; bottom: 0; left: 0; z-index: 2147483647; border: 2px solid black; text-align: center; border-radius: 4px; text-transform: capitalize; }',
        0
    )
    style.sheet.insertRule('#warmup-indicator.prewarmup {  background-color: deeppink; }', 0)
    style.sheet.insertRule('#warmup-indicator.warmup  {  background-color: yellow; }', 0)
    style.sheet.insertRule('#warmup-indicator.client  {  background-color: yellow; }', 0)
    style.sheet.insertRule('#warmup-indicator.layout { background-color: orange; }', 0)
    style.sheet.insertRule('#warmup-indicator.success { background-color: green; }', 0)
    style.sheet.insertRule('#warmup-indicator.diverge { background-color: red; }', 0)
    style.sheet.insertRule('#warmup-indicator.clientfallback { background-color: red; }', 0)
    style.sheet.insertRule('#warmup-indicator.standby { background-color: cyan; }', 0)
    style.sheet.insertRule('#warmup-indicator.rendering { background-color: white; }', 0)
}

function createIndicator(onClick) {
    containerElm = window.document.createElement('div')
    containerElm.setAttribute('id', 'warmup-indicator')
    containerElm.addEventListener('click', onClick)

    window.document.body.appendChild(containerElm)
}

function updateState(state) {
    if (!containerElm) {
        return
    }

    containerElm.innerHTML = `${viewerName} - ${state}`
    containerElm.setAttribute('class', state)
}

function updateStateOnClientSide() {
    // @ts-ignore
    const stateToSet = typeof window.santaRenderingError === 'undefined' ? STATES.CLIENT : STATES.CLIENTFALLBACK
    updateState(stateToSet)
}

function getState() {
    return containerElm.getAttribute('class')
}

export default {
    init,
    updateState,
    updateStateOnClientSide,
    STATES,
    getState
}
