import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

/**
 * @class proxies.VerticalLine
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],
    renderProxy() {
        const componentType = 'wysiwyg.viewer.components.VerticalLine'
        const props = this.getChildCompProps(componentType)
        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
