import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import {deepCompare} from './deepCompare'
import type {DalValue} from '../dal/store'

export type ChangesLogger = (pointer: Pointer, oldValue: DalValue, newValue: DalValue, prefix: string | undefined) => void

const change = (oldVal: any, newVal: any): any => ({OLD: oldVal, NEW: newVal})

const comparingObjects = (a: any, b: any): boolean => _.isObject(a) && _.isObject(b) && !Array.isArray(a) && !Array.isArray(b)

const getKeysUnion = (keys1: string[], keys2: string[]): string[] => {
    keys2.forEach((k: string) => {
        if (!keys1.includes(k)) {
            keys1.push(k)
        }
    })
    return keys1
}

const getDalValuePropertyChanges = (oldValue: any, newValue: any) => {
    const diff = {}
    const keys = getKeysUnion(Object.keys(newValue), Object.keys(oldValue))
    keys.forEach((k: string) => {
        if (k === 'sig' || k === 'basedOnSignature') {
            return
        }
        if (!deepCompare(oldValue[k], newValue[k])) {
            diff[k] = change(oldValue[k], newValue[k])
        }
    })
    return diff
}

const getDalValueChanges = (oldValue: DalValue, newValue: DalValue): any => {
    const diff = {}
    const keys = getKeysUnion(Object.keys(newValue), Object.keys(oldValue))
    keys.forEach((k: string) => {
        const oldKeyValue = oldValue[k]
        const newKeyValue = newValue[k]
        if (!deepCompare(oldKeyValue, newKeyValue)) {
            if (oldValue === undefined || newValue === undefined) {
                diff[k] = change(oldKeyValue, newKeyValue)
            } else if (comparingObjects(newKeyValue, oldKeyValue)) {
                const propValChanges = getDalValuePropertyChanges(oldKeyValue, newKeyValue)
                if (!_.isEmpty(propValChanges)) {
                    diff[k] = propValChanges
                }
            } else {
                diff[k] = change(oldKeyValue, newKeyValue)
            }
        }
    })
    return diff
}

const logDalValueChanges: ChangesLogger = (pointer: Pointer, oldValue: DalValue, newValue: DalValue, prefix: string = ''): void => {
    const ptr = `{ ${pointer.type} : ${pointer.id} }`
    if (oldValue === undefined) {
        if (newValue !== undefined) {
            console.log(`%c${prefix}ADDED ${ptr}`, 'color: lime', newValue)
        }
    } else if (newValue === undefined) {
        if (oldValue !== undefined) {
            console.log(`%c${prefix}REMOVED ${ptr}`, 'color: red', oldValue)
        }
    } else if (comparingObjects(newValue, oldValue)) {
        const diff = getDalValueChanges(oldValue, newValue)
        if (!_.isEmpty(diff)) {
            console.log(`%c${prefix}CHANGED ${ptr} [${Object.keys(diff)}]`, 'color: yellow', diff)
        }
    } else if (!deepCompare(oldValue, newValue)) {
        console.log(`%c${prefix}CHANGED ${ptr}`, 'color: yellow', change(oldValue, newValue))
    }
}

export {logDalValueChanges}
