define(['@wix/santa-core-utils', 'lodash'], function (santaCoreUtils, _) {
    'use strict'

    return {
        isRepeatable: false,
        rotatable: true,
        a11yConfigurable: false,
        layoutLimits(ps, compPointer) {
            const measureMap = ps.siteAPI.getSiteMeasureMap()

            return {
                minWidth: _.get(measureMap, ['minWidth', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH)
            }
        }
    }
})
