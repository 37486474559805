define(['lodash', 'documentServices/dataModel/dataModel', 'documentServices/mobileConversion/modules/mobileHints'], function (_, dataModel, mobileHints) {
    'use strict'
    const getComponentAuthor = (ps, comp, pageId) => {
        const mobileHintsItemId = _.get(comp, 'mobileHintsQuery', '')
        const mobileHintsItem = dataModel.getMobileHintsItemById(ps, mobileHintsItemId, pageId)
        return _.get(mobileHintsItem, 'author')
    }

    const updateModifiedByUser = (ps, pageId, compPointer, modifiedByUser, shouldMarkParent = true) => {
        const property = {modifiedByUser}
        if (shouldMarkParent && !ps.pointers.full.components.isPage(compPointer)) {
            /**
             * In case when it updates component it also should update
             * it's parent to avoid recalculations
             */
            const parent = ps.pointers.full.components.getParent(compPointer)
            updateModifiedByUser(ps, pageId, parent, modifiedByUser, false)
        }
        mobileHints.updateProperty(ps, property, compPointer, {}, pageId)
    }

    const markComponentAsTouched = (ps, pageId, compPointer, shouldUpdateParent) => {
        const modifiedByUser = true
        updateModifiedByUser(ps, pageId, compPointer, modifiedByUser, shouldUpdateParent)
    }

    const unmarkComponentAsTouched = (ps, pageId, compPointer, shouldUpdateParent) => {
        const modifiedByUser = false
        updateModifiedByUser(ps, pageId, compPointer, modifiedByUser, shouldUpdateParent)
    }
    /**
     * @param ps
     * @param {object} comp
     * @param {string} pageId
     * @returns {boolean}
     */
    const getModifiedByUser = (ps, comp, pageId) => {
        const mobileHintsItemId = _.get(comp, 'mobileHintsQuery', '')
        const mobileHintsItem = dataModel.getMobileHintsItemById(ps, mobileHintsItemId, pageId)
        return isModifiedByUser(mobileHintsItem)
    }
    /**
     * @param {Object} mobileHintsItem
     * @returns {boolean}
     */
    const isModifiedByUser = mobileHintsItem => _.get(mobileHintsItem, 'modifiedByUser', false)
    return {
        getComponentAuthor,
        getModifiedByUser,
        markComponentAsTouched,
        unmarkComponentAsTouched,
        isModifiedByUser
    }
})
