define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/component/component',
    'documentServices/utils/utils'
], function (_, santaCoreUtils, dataModel, componentStylesAndSkinsAPI, component, dsUtils) {
    'use strict'

    const MIN_HEIGHT_PER_ITEM = 25

    const afterLayout = (ps, compPointer, updatedLayout, updateCompLayoutCallbackForHooks, isTriggeredByHook, previousLayout) => {
        const heightDidNotChange = _.isUndefined(updatedLayout.height) || previousLayout.height === updatedLayout.height
        if (heightDidNotChange) {
            return
        }

        const compData = dataModel.getDataItem(ps, compPointer)
        const menuRef = _.isString(compData.menuRef) ? dsUtils.stripHashIfExists(compData.menuRef) : compData.menuRef.id
        const menuItems = dataModel.getDataItemById(ps, menuRef).items
        const itemsCount = _.filter(menuItems, 'isVisible').length

        const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
        const borderWidth = componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'brw')
        const separatorHeight = parseInt(borderWidth, 10) || 0

        const menuItemHeight = Math.max(
            santaCoreUtils.verticalMenuCalculations.getItemHeight(updatedLayout.height, separatorHeight, itemsCount, skinExports),
            MIN_HEIGHT_PER_ITEM
        )

        component.properties.update(ps, compPointer, {menuItemHeight})
    }

    return {
        after: afterLayout
    }
})
