import * as _ from 'lodash'
import {ConversionData, ComponentWithConversionData, MasterPageComponentWithConversionData, StructureWithConversionData} from '../../types'
import * as conversionUtils from '../conversionUtils'
import * as virtualGroupHandler from '../virtualGroupHandler'
import {getOrderedComponents, shouldUseNaturalOrder} from './analyzerUtils'

export interface Range {
    min: number
    max: number
}

const MARGIN_RANGES = {
    LOGO: {min: -10, max: 25},
    ROW: {min: -10, max: 80}
}

const MAX_TEXT_LENGTH = {
    MARKER: 3,
    LOGO_TITLE: 20
}

const GRAPHIC_MARKER_MAX_SIZE = 100
const MARGIN_DEVIATION_THRESHOLD = 20
const ROW_SIZE = 2
const LIST_MIN_ROWS_NUMBER = 2

interface SemanticGroup {
    conversionData: ConversionData
    groupComps: ComponentWithConversionData[]
}

const CONVERSION_DATA = {
    ROW: {rescaleMethod: 'row'},
    LOGO: {rescaleMethod: 'logo'}
}

const supportedByGroupingAnchors = (comp: ComponentWithConversionData): boolean => !comp.layout.fixedPosition && !comp.layout.rotationInDegrees

const getMarginX = (comps: ComponentWithConversionData[]): number => -conversionUtils.getXOverlap(comps[0], comps[1])

const meetMarginRange = (comps: ComponentWithConversionData[], range: Range): boolean => _.inRange(getMarginX(comps), range.min, range.max)

const isTypographicMarker = (comp: ComponentWithConversionData): boolean =>
    conversionUtils.isTextComponent(comp) && comp.conversionData.textLength <= MAX_TEXT_LENGTH.MARKER

const isGraphicMarker = (comp: ComponentWithConversionData): boolean => conversionUtils.isGraphicComponent(comp) && comp.layout.width <= GRAPHIC_MARKER_MAX_SIZE

const isMarker = (comp: ComponentWithConversionData): boolean => isGraphicMarker(comp) || isTypographicMarker(comp)

const findMarker = (comps: ComponentWithConversionData[]) => <ComponentWithConversionData>_.find(comps, isMarker)

const findInlineComponent = (comps: ComponentWithConversionData[]) => _.find(comps, ['conversionData.inlineWhenGrouped', true])

const isLogo = (comps: ComponentWithConversionData[]): boolean => (isTypographicLogo(comps) || isGraphicLogo(comps)) && isRow(comps)

const getSemanticGroupComponentsOrder = (comps: ComponentWithConversionData[]): string[] => <string[]>_(comps).sortBy('layout.x').map('id').value()

const isRow = (comps: ComponentWithConversionData[]): boolean =>
    _.size(comps) === ROW_SIZE && comps[0] && comps[1] && conversionUtils.getYOverlap(comps[0], comps[1]) > 0

function isTypographicLogo(comps: ComponentWithConversionData[]): boolean {
    if (!_.every(comps, conversionUtils.isTextComponent) || !meetMarginRange(comps, MARGIN_RANGES.LOGO)) {
        return false
    }
    const similarityRate = _(['textColors', 'averageFontSize', 'fontNames'])
        .map(prop => _.isEqual(_.get(comps, [0, 'conversionData', prop]), _.get(comps, [1, 'conversionData', prop])))
        .compact()
        .size()
    const totalTextLength = _.sumBy(comps, 'conversionData.textLength')
    return similarityRate <= 1 && totalTextLength <= MAX_TEXT_LENGTH.LOGO_TITLE
}

function isGraphicLogo(comps: ComponentWithConversionData[]): boolean {
    const text = _.find(comps, ['conversionData.category', 'text'])
    const graphicMark = _.find(comps, ['conversionData.category', 'graphic'])
    if (!text || !graphicMark) {
        return false
    }
    return (
        meetMarginRange(comps, MARGIN_RANGES.LOGO) &&
        text.conversionData.textLength <= MAX_TEXT_LENGTH.LOGO_TITLE &&
        graphicMark.layout.width <= GRAPHIC_MARKER_MAX_SIZE
    )
}

function defineLogoSemanticGroup(
    component: ComponentWithConversionData,
    componentIndex: number,
    siblings: ComponentWithConversionData[]
): SemanticGroup | void {
    const groupComps = [siblings[componentIndex - 1], component]
    const nextGroupComps = [component, siblings[componentIndex + 1]]
    if (!isLogo(groupComps) || isLogo(nextGroupComps)) {
        return
    }
    return {
        groupComps,
        conversionData: CONVERSION_DATA.LOGO
    }
}

function defineLogoLayoutRules(
    parent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    orderedChildren: ComponentWithConversionData[]
): void {
    _(orderedChildren)
        .map((component, componentIndex) => defineLogoSemanticGroup(component, componentIndex, orderedChildren))
        .compact()
        .forEachRight((semanticGroup: SemanticGroup) => addSemanticGroup(parent, orderedChildren, semanticGroup))
}

function addSemanticGroup(
    parent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    orderedChildren,
    semanticGroup: SemanticGroup
): ComponentWithConversionData {
    const index = parent.conversionData.componentsOrder.indexOf(semanticGroup.groupComps[0].id)
    const conversionData = _.assign({}, semanticGroup.conversionData, {
        componentsOrder: getSemanticGroupComponentsOrder(semanticGroup.groupComps)
    })
    const virtualGroup = virtualGroupHandler.createRescaleGroup(parent, semanticGroup.groupComps, conversionData, index)
    parent.conversionData.componentsOrder.splice(index, semanticGroup.groupComps.length, virtualGroup.id)
    orderedChildren.splice(index, semanticGroup.groupComps.length, virtualGroup)
    return virtualGroup
}

function isTypographicListEntry(comps: ComponentWithConversionData[], allowedMarginRange: Range): boolean {
    const listItemMarker = findMarker(comps)
    return meetMarginRange(comps, allowedMarginRange) && _.every(comps, conversionUtils.isTextComponent) && isTypographicMarker(listItemMarker)
}

function isGraphicListEntry(comps: ComponentWithConversionData[], allowedMarginRange: Range): boolean {
    const listItemMarker = findMarker(comps)
    const listItem = _.reject(comps, listItemMarker)[0]
    return meetMarginRange(comps, allowedMarginRange) && conversionUtils.isTextComponent(listItem) && isGraphicMarker(listItemMarker)
}

function getListEntriesAllowedMarginRange(listComps: ComponentWithConversionData[]): Range {
    const listPattern = _.take(listComps, ROW_SIZE)
    const margin = getMarginX(listPattern)
    return {
        min: _.max([margin - MARGIN_DEVIATION_THRESHOLD, MARGIN_RANGES.ROW.min]),
        max: _.min([margin + MARGIN_DEVIATION_THRESHOLD, MARGIN_RANGES.ROW.max])
    }
}

function isList(comps: ComponentWithConversionData[]): boolean {
    if (!_.every(comps, supportedByGroupingAnchors)) {
        return false
    }
    const firstEntry = _.take(comps, ROW_SIZE)
    const lastEntry = _.takeRight(comps, ROW_SIZE)
    const allowedMargin = getListEntriesAllowedMarginRange(firstEntry)
    return (
        ((isGraphicListEntry(firstEntry, MARGIN_RANGES.ROW) && isGraphicListEntry(lastEntry, allowedMargin)) ||
            (isTypographicListEntry(firstEntry, MARGIN_RANGES.ROW) && isTypographicListEntry(lastEntry, allowedMargin))) &&
        isRow(firstEntry) &&
        isRow(lastEntry)
    )
}

function isListEntry(listComps: ComponentWithConversionData[], newEntryComps: ComponentWithConversionData[]): boolean {
    const listEntryPattern = _.take(listComps, ROW_SIZE)
    return isList([...listEntryPattern, ...newEntryComps])
}

function createRowSemanticGroups(listComps: ComponentWithConversionData[]): SemanticGroup[] {
    return <SemanticGroup[]>_(listComps)
        .map((comp, compIndex) =>
            compIndex % ROW_SIZE
                ? {
                      conversionData: CONVERSION_DATA.ROW,
                      groupComps: [listComps[compIndex - 1], comp]
                  }
                : null
        )
        .compact()
        .value()
}

function findListItemSemanticGroups(orderedComponents: ComponentWithConversionData[]): SemanticGroup[] {
    const listMinSize = LIST_MIN_ROWS_NUMBER * ROW_SIZE
    const listsData = {foundLists: [], curChunk: []}
    _.forEach(orderedComponents, comp => {
        listsData.curChunk.push(comp)
        const lastList = _.last(listsData.foundLists)
        if (lastList && _.size(listsData.curChunk) === ROW_SIZE && isListEntry(lastList, listsData.curChunk)) {
            lastList.splice(lastList.length, 0, ...listsData.curChunk)
            return _.assign(listsData, {curChunk: []})
        }
        if (_.size(listsData.curChunk) < listMinSize) {
            return
        }
        if (isList(listsData.curChunk)) {
            listsData.foundLists.push(listsData.curChunk)
            return _.assign(listsData, {curChunk: []})
        }
        return _.assign(listsData, {curChunk: _.tail(listsData.curChunk)})
    })
    return <SemanticGroup[]>_.flatMap(listsData.foundLists, listComps => createRowSemanticGroups(listComps))
}

function defineListLayoutRules(
    parent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    orderedChildren: ComponentWithConversionData[]
): void {
    const listItems = findListItemSemanticGroups(orderedChildren)
    _.forEachRight(listItems, listItem => addSemanticGroup(parent, orderedChildren, listItem))
}

const shouldSkipLayoutGrouping = (component: StructureWithConversionData, orderedChildren) => {
    return (
        !component.conversionData.isTightContainer ||
        orderedChildren.length !== ROW_SIZE ||
        conversionUtils.shouldStretchToScreenWidth(component as ComponentWithConversionData) ||
        !meetMarginRange(orderedChildren, MARGIN_RANGES.ROW) ||
        !findInlineComponent(orderedChildren)
    )
}

function defineRowLayoutRules(parent: StructureWithConversionData, orderedChildren: ComponentWithConversionData[]): void {
    if (shouldSkipLayoutGrouping(parent, orderedChildren)) {
        return
    }
    _.assign(parent.conversionData, CONVERSION_DATA.ROW, {
        componentsOrder: getSemanticGroupComponentsOrder(orderedChildren)
    })
}

export function analyze(parent: StructureWithConversionData): void {
    if (shouldUseNaturalOrder(parent)) {
        return
    }
    const orderedComponents = getOrderedComponents(parent)
    if (!_.size(orderedComponents)) {
        return
    }
    defineRowLayoutRules(parent, orderedComponents)
    defineListLayoutRules(parent, orderedComponents)
    defineLogoLayoutRules(parent, orderedComponents)
}

export const testAPI = {
    getMarginX,
    meetMarginRange
}
