import propsSelectorsUtils from './propsSelectorsUtils'

const {createComponentSantaType} = propsSelectorsUtils
/**
 * @memberOf coreUtils
 * @deprecated
 */
const staticValue = function (propType, value) {
    return createComponentSantaType(propType, () => value)
}

export default staticValue
