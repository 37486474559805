//don't remove aspects so that they will register
import _ from 'lodash'
import tpaPostMessageCommon from '@wix/santa-ds-libs/src/tpa/src/common/tpaPostMessageCommon'
import tpaHandlers from '@wix/santa-ds-libs/src/tpa/src/handlers/tpaHandlers'
import * as utils from '@wix/santa-ds-libs/src/utils'
import tpaNavigationService from '@wix/santa-ds-libs/src/tpa/src/services/tpaNavigationService'
import tpaBi from '@wix/santa-ds-libs/src/tpa/src/common/tpaBi'
import '@wix/santa-ds-libs/src/tpa/src/aspects/tpaAspectCollector'
import * as tpaComponents from '../../_internal/tpaComponents'

export default _.assign({}, tpaComponents, {
    tpaHandlers,
    common: _.assign({}, tpaComponents.common, {
        tpaPostMessageCommon,
        styleUtils: utils.styleUtils,
        bi: tpaBi
    }),
    services: _.assign({}, tpaComponents.services, {
        tpaNavigationService
    }),
    tpaStyleUtils: utils.styleUtils
})
