import _ from 'lodash'
import santaComponents from '@wix/santa-components'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

export default {
    propTypes: {
        navigateToRenderedLink: santaComponents.santaTypesDefinitions.Navigation.navigateToRenderedLink.isRequired,
        linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
        rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
        previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback
    },

    flattenMenuItems(items) {
        const flatItems = _.reduce(
            items,
            function (result, item) {
                result.push(item)
                if (item.items) {
                    result = result.concat(this.flattenMenuItems(item.items))
                }

                return result
            }.bind(this),
            []
        )

        return _.filter(flatItems, 'link')
    },

    getMenuItemValue(item) {
        if (item.link && item.link.type === 'PageLink') {
            return item.link.pageId.id
        }

        if (item.link && item.link.type === 'DynamicPageLink') {
            return item.link.innerRoute
        }
        return item.label
    },

    getItemText(item, itemsAlignment) {
        if (_.isNumber(item.displayCount)) {
            if (itemsAlignment === 'right') {
                return `(${item.displayCount}) ${item.label}`
            }
            return `${item.label} (${item.displayCount})`
        }

        return item.label
    },

    convertMenuItemsToComboBoxItems(menuItems, itemsAlignment) {
        return _.map(
            menuItems,
            function (item) {
                const itemText = this.getItemText(item, itemsAlignment)
                const properties = {
                    isSelected: item.isSelected,
                    text: itemText,
                    key: item.id,
                    value: this.getMenuItemValue(item),
                    link: item.link ? coreUtils.linkRenderer.renderLink(item.link, this.props.linkRenderInfo, this.props.rootNavigationInfo) : item.label
                }
                return properties
            }.bind(this)
        )
    },

    getSelectedPage(menuItems) {
        const selectedMenuItem = _.find(menuItems, 'isSelected')
        return selectedMenuItem ? selectedMenuItem.value : ''
    },

    onSelectionChange(event) {
        const linkToNavigate = event.payload.link
        const navigationBlocked = !this.props.navigateToRenderedLink(linkToNavigate)
        if (navigationBlocked && this.props.previewTooltipCallback) {
            const targetClientRect = event.target.getBoundingClientRect()
            this.props.previewTooltipCallback(targetClientRect, 'text_editor_inactive_link_on_preview')
        }
    }
}
