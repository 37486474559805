import {PublishMethods, factory, Factory} from '@wix/web-bi-logger/dist/src/logger' //JIRA: FEDINF-4927
import type {DocumentServicesModel} from '@wix/document-manager-host-common'
import type {RendererModelFromServer, WixBiSession} from '@wix/document-services-types'

interface BIStore {
    dm_version?: string
    origin?: string
    visitorId?: string
    is_rollout?: boolean | number | null
    msid?: string
    esi?: string
    isPublished: boolean
    host?: string
    config?: string
    initialTimestamp: number
    pages_count?: number
    isDraft?: boolean
    viewer_version?: number
    viewerSessionId?: string
    uuid?: string
    is_dac_rollout: 1 | 0
    checkVisibility?: any
}

const boolToNum = (b: any) => (b ? 1 : 0)

const biLoggerFactory = (biStore: BIStore) =>
    factory({
        publishMethod: PublishMethods.Auto,
        useBatch: true
    })
        .setMuted(false)
        .withUoUContext({
            msid: biStore.msid,
            visitorId: biStore.visitorId
        })
        .updateDefaults({
            is_rollout: biStore.is_rollout,
            uuid: biStore.uuid,
            ts: () => Date.now() - biStore.initialTimestamp,
            esi: biStore.esi,
            dm_version: biStore.dm_version,
            origin: biStore.origin,
            pv: biStore.checkVisibility
        })

const getNumberOfPages = (rendererModel: RendererModelFromServer) => rendererModel.pageList.pages.length

const extractVersion = (base: string) => base.split('/').pop()

const getConfig = (rawUrl: string) => {
    const configMatches = rawUrl.match(/config=([^&]*)/)
    return configMatches?.length ? configMatches[1] : 'fullFunctionality'
}

export const createBIStore = (
    rendererModel: RendererModelFromServer,
    documentServicesModel: DocumentServicesModel,
    dmBase: string,
    rawUrl: string,
    biContext: WixBiSession
): BIStore => ({
    msid: rendererModel.metaSiteId,
    is_rollout: biContext.is_rollout,
    is_dac_rollout: boolToNum(documentServicesModel.isDacRollout),
    pages_count: getNumberOfPages(rendererModel),
    initialTimestamp: biContext.initialTimestamp,
    viewerSessionId: biContext.viewerSessionId,
    dm_version: extractVersion(dmBase),
    origin: biContext.origin,
    uuid: rendererModel.siteOwnerCoBranding.accountOwnerId,
    esi: documentServicesModel.editorSessionId,
    visitorId: documentServicesModel.editorSessionId,
    isPublished: documentServicesModel.isPublished,
    host: 'documentServices',
    config: getConfig(rawUrl),
    checkVisibility: biContext.checkVisibility
})

export const getBILoggerFactoryForFedops = (
    rendererModel: RendererModelFromServer,
    documentServicesModel: DocumentServicesModel,
    dmBase: string,
    rawUrl: string,
    biContext: WixBiSession
): Factory => {
    const biStore = createBIStore(rendererModel, documentServicesModel, dmBase, rawUrl, biContext)
    return biLoggerFactory(biStore).updateDefaults({
        is_dac_rollout: biStore.is_dac_rollout,
        pages_count: biStore.pages_count
    })
}

export const getBILoggerFactory = (
    rendererModel: RendererModelFromServer,
    documentServicesModel: DocumentServicesModel,
    dmBase: string,
    rawUrl: string,
    biContext: WixBiSession
): Factory => {
    const biStore = createBIStore(rendererModel, documentServicesModel, dmBase, rawUrl, biContext)
    return biLoggerFactory(biStore)
}
