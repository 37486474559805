define(['lodash'], function (_) {
    'use strict'

    const dataPathErrorTemplate = _.template('Expected comp {id: <%= compId %>} to have {id: <%= query %>} in the <%= type %> map, in page <%= pageId %>')

    class DataPathError extends Error {
        /**
         * @param {{
         *  compId: string,
         *  query: string,
         *  type: string,
         *  pageId: string
         * }} errorOptions
         */
        constructor(errorOptions) {
            super()
            this.name = 'DataPathError'
            this.message = dataPathErrorTemplate(errorOptions)
            this.stack = new Error().stack
        }
    }

    return {
        DataPathError
    }
})
