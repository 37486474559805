define([
    'documentServices/siteMetadata/generalInfo',
    'documentServices/wixCode/utils/errors',
    'documentServices/autosave/autosave',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment',
    'documentServices/appStudio/isAppStudio'
], function (generalInfo, errors, autosave, extensionsAPI, experiment, isAppStudio) {
    function isWixCodeSaveAllowed(ps) {
        if (isAppStudio(ps) && !experiment.isOpen('dm_blockWixCodeOnDisabledSaveAndAutoSaveInBlocks')) {
            return true
        }
        const isAllowedToModifySite = extensionsAPI.saveState.isSaveAllowed(ps) && autosave.isAllowed(ps)
        const isTemplate = generalInfo.isFirstSave(ps)
        return isAllowedToModifySite || isTemplate
    }

    function ensureWixCodeSaveAllowed(ps) {
        if (!isWixCodeSaveAllowed(ps)) {
            throw new errors.WixCodeDisabledSaveError()
        }
    }

    return {
        isWixCodeSaveAllowed,
        ensureWixCodeSaveAllowed
    }
})
