import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const dataById = {}
coreUtils.sessionFreezer.freeze(dataById)

export default {
    getAppPartCommonDataItem(id, key, defaultValue) {
        return _.get(dataById, [id, key], defaultValue)
    },

    setAppPartCommonDataItem(id, key, value) {
        return _.set(dataById, [id, key], value)
    },

    removeAppPartCommonData(id) {
        delete dataById[id]
    }
}
