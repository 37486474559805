define(['layout', '@wix/santa-core-utils'], function (/** layout.layout */ layout, coreUtils) {
    'use strict'

    function measurePageBasedSizes(id, measureMap, nodesMap, siteData, structureInfo) {
        // IMPORTANT! the containerHeightMargin must be here or we will have an endless loop with anchors resizing the page
        measureMap.pageBottomByComponents[id] =
            coreUtils.layoutUtils.getPageBottomChildEnd(measureMap, nodesMap, siteData.isMobileView(), structureInfo) + measureMap.containerHeightMargin[id]
    }

    layout.registerAdditionalMeasureFunction('mobile.core.components.Page', measurePageBasedSizes, 'pageBottomPlugin')
})
