define(['lodash', 'experiment', 'documentServices/componentsMetaData/metaDataUtils', 'documentServices/documentMode/documentModeInfo'], function (
    _,
    experiment,
    metaDataUtils,
    documentModeInfo
) {
    'use strict'

    const UNSUPPORTED_WIDGET_ROOT_COMPONENT_TYPES = [
        'wysiwyg.viewer.components.HoverBox',
        'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.StripContainerSlideShow',
        'wysiwyg.viewer.components.Repeater',
        'wysiwyg.viewer.components.StripContainer',
        'wysiwyg.viewer.components.StripColumnsContainer'
    ]

    const EXCLUDED_COMPONENT_TYPES = [
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.StateBox',
        'wysiwyg.viewer.components.BoxSlideShow',
        'wysiwyg.viewer.components.BoxSlideShowSlide'
    ]

    function getUnsupportedComponentInAppWidget() {
        const excludedComponentTypes = experiment.isOpen('dm_allowTpaWidgetInAppWidget')
            ? [...EXCLUDED_COMPONENT_TYPES, 'wysiwyg.viewer.components.tpapps.TPAWidget']
            : EXCLUDED_COMPONENT_TYPES
        return _.without(metaDataUtils.COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE, ...excludedComponentTypes)
    }

    function allChildrenAllowedInAppWidget(ps, potentialChild) {
        const recusiveChildrenPointers = ps.pointers.components.getChildrenRecursively(potentialChild)
        return _.every(recusiveChildrenPointers.concat(potentialChild), function (componentPointer) {
            const compType = metaDataUtils.getComponentType(ps, componentPointer)

            return !_.includes(getUnsupportedComponentInAppWidget(), compType)
        })
    }

    function allChildrenStructureAllowedInAppWidget(potentialChildStructure) {
        if (!potentialChildStructure.components) {
            return metaDataUtils.isComponentSuitableForNonRenderingState(potentialChildStructure.componentType)
        }

        const childrenTypes = metaDataUtils.getChildrenTypesDeep([potentialChildStructure])
        return _.every(childrenTypes, metaDataUtils.isComponentSuitableForNonRenderingState)
    }

    return {
        groupable: false,
        layoutLimits: metaDataUtils.getControllerLayoutLimits,
        minimalChildrenNumber: 1,
        maximumChildrenNumber: 1,
        forceMaintainIDsOnSerialize: true,
        container: true,
        get isRepeatable() {
            return experiment.isOpen('dm_widgetInRepeater')
        },
        canContainByStructure(ps, compRef, potentialChildStructure, targetCompRef) {
            return (
                !ps.pointers.isSamePointer(compRef, targetCompRef) ||
                !_.includes(UNSUPPORTED_WIDGET_ROOT_COMPONENT_TYPES, potentialChildStructure.componentType) ||
                !allChildrenStructureAllowedInAppWidget(ps)
            )
        },
        canContain(ps, componentPointer, potentialChild) {
            return allChildrenAllowedInAppWidget(ps, potentialChild)
        },
        canBeFixedPosition(ps, compRef) {
            return metaDataUtils.getFromStageData(ps, 'pinnable', compRef) !== false
        },
        containable(ps, compRef, potentialContainerPointer) {
            if (metaDataUtils.isTogglingShowOnAllPages(ps, compRef, potentialContainerPointer)) {
                return !documentModeInfo.isMobileView(ps) && metaDataUtils.getFromStageData(ps, 'toggleShowOnAllPagesEnabled', compRef) !== false
            }
            return true
        },
        duplicatable(ps, compRef) {
            return metaDataUtils.getFromStageData(ps, 'duplicatable', compRef) !== false
        },
        crossSiteDuplicatable: false,
        nickname: metaDataUtils.getControllerNickname,
        mobileConversionConfig: {
            category: 'appWidget',
            isTightContainer: true,
            marginX: 0,
            filterChildrenWhenHidden: true
        }
    }
})
