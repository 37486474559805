define([
    'lodash',
    '@wix/document-services-json-schemas',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/dataSerialization',
    'documentServices/variants/variantsUtils'
], (_, jsonSchemas, dataModel, dataSerialization, variantsUtils) => {
    const {
        namespaceMapping: {getNamespaceConfig}
    } = jsonSchemas

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {String} namespace
     * @param {*} dataItem
     * @returns {String} the dataItemId of the updated data
     */
    function updateNamespaceData(ps, compPointer, namespace, dataItem) {
        const config = getNamespaceConfig(namespace)
        if (config.supportsVariants) {
            const type = dataItem.type || namespace
            const dataItemWithType = _.assign(dataItem, {type})
            return variantsUtils.updateComponentDataConsideringVariants(ps, compPointer, dataItemWithType, namespace)
        }
        return updateSingleNamespaceData(ps, compPointer, namespace, dataItem, config.query)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {String} namespace
     * @param {*} dataItem
     * @param {String} queryName
     * @returns {String} The dataItemId of the updated dataItem
     */
    function updateSingleNamespaceData(ps, compPointer, namespace, dataItem, queryName) {
        dataItem.type = dataItem.type || namespace
        let dataItemId = dataModel.getComponentDataItemId(ps, compPointer, queryName)

        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const doesDataItemExists = Boolean(dataItemId)

        dataItemId = dataSerialization.addSerializedFeatureItemToPage(ps, pageId, dataItem, namespace, dataItemId)
        if (!doesDataItemExists) {
            dataModel.linkComponentToItemByType(ps, compPointer, dataItemId, namespace)
        }

        return dataItemId
    }

    /**
     * Get the components' namespace data
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} namespace
     * @returns
     */
    function getNamespaceData(ps, componentPointer, namespace) {
        const config = getNamespaceConfig(namespace)
        if (config.supportsVariants) {
            return variantsUtils.getComponentDataConsideringVariants(ps, componentPointer, namespace)
        }
        return dataModel.getComponentDataItemByType(ps, componentPointer, namespace)
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} namespace
     */
    function removeComponentNamespaceData(ps, componentPointer, namespace) {
        const config = getNamespaceConfig(namespace)
        if (config.supportsVariants) {
            variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, namespace)
        } else {
            removeComponentSingleNamespaceData(ps, componentPointer, namespace)
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} namespace
     */
    function removeComponentSingleNamespaceData(ps, componentPointer, namespace) {
        const desktopComponentPointer = ps.pointers.full.components.getDesktopPointer(componentPointer)
        const mobileComponentPointer = ps.pointers.full.components.getMobilePointer(componentPointer)
        const {query} = getNamespaceConfig(namespace)

        _.forEach([desktopComponentPointer, mobileComponentPointer], compPointer => {
            if (ps.dal.full.isExist(compPointer)) {
                const queryItemPointer = ps.pointers.getInnerPointer(compPointer, query)

                const itemToRemove = dataModel.getComponentDataPointerByType(ps, compPointer, namespace)
                if (ps.dal.full.isExist(itemToRemove)) {
                    ps.dal.full.remove(itemToRemove)
                }

                ps.dal.full.remove(queryItemPointer)
            }
        })
    }

    return {
        getNamespaceData,
        updateNamespaceData,
        removeComponentNamespaceData,
        updateSingleNamespaceData
    }
})
