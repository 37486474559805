define([
    'lodash',
    'documentServices/appControllerData/appControllerData',
    'documentServices/connections/connections',
    'documentServices/component/component',
    'documentServices/componentDetectorAPI/componentDetectorAPI'
], function (_, appControllerData, connections, component, componentDetectorAPI) {
    'use strict'

    function getAttributeByPrimaryConnection(ps, attribute, compPointer, metaDataValue, manifestOptions) {
        const componentStageData = appControllerData.getConnectedComponentStageData(ps, compPointer)
        const manifestValue = _.get(componentStageData, ['behavior', attribute])
        if (_.isUndefined(manifestValue) || _.isUndefined(manifestOptions[manifestValue])) {
            return metaDataValue
        }
        return _.isFunction(manifestOptions[manifestValue]) ? manifestOptions[manifestValue](metaDataValue) : manifestOptions[manifestValue]
    }

    function getAttributeFromManifest(ps, attribute, compPointer, metaDataValue, manifestOptions) {
        if (metaDataValue === false) {
            return false
        }

        return getAttributeByPrimaryConnection(ps, attribute, compPointer, metaDataValue, manifestOptions)
    }

    function isDuplicatable(ps, metaDataValue, compPointer) {
        return getAttributeFromManifest(ps, 'duplicatable', compPointer, metaDataValue, {
            false: false,
            true: metaDataValue
        })
    }

    function canReparent(ps, metaDataValue, compPointer) {
        return getAttributeFromManifest(ps, 'canReparent', compPointer, metaDataValue, {
            false: false,
            true: metaDataValue
        })
    }

    function isRotatable(ps, metaDataValue, compPointer) {
        return getAttributeFromManifest(ps, 'rotatable', compPointer, metaDataValue, {
            false: false,
            true: metaDataValue
        })
    }

    function isConnectionToWidgetAncestor(ps, connection, ancestors) {
        return connection && _.some(ancestors, ancestorRef => ps.pointers.isSamePointer(ancestorRef, connection.controllerRef))
    }

    function canBeFixedPosition(ps, metaDataValue, compRef) {
        if (metaDataValue === false) {
            return false
        }

        const ancestors = _.filter(component.getAncestors(ps, compRef), ancestorRef => component.getType(ps, ancestorRef) === 'platform.components.AppWidget')
        const descendants = [compRef, ...ps.pointers.full.components.getChildrenRecursively(compRef)]
        if (_.some(descendants, descRef => isConnectionToWidgetAncestor(ps, connections.getPrimaryConnection(ps, descRef), ancestors))) {
            return false
        }

        return getAttributeFromManifest(ps, 'pinnable', compRef, metaDataValue, {
            false: false,
            true: metaDataValue
        })
    }

    function isResizableSides(ps, metaDataValue, compPointer) {
        return getAttributeFromManifest(ps, 'resizable', compPointer, metaDataValue, {false: [], true: metaDataValue})
    }

    function layoutLimitsHook(ps, metaDataValue, compPointer) {
        function removeAspectRatio(value) {
            return value.aspectRatio ? _.omit(value, 'aspectRatio') : value
        }

        return getAttributeFromManifest(ps, 'resizable', compPointer, metaDataValue, {
            false: removeAspectRatio,
            true: metaDataValue
        })
    }

    function isTogglingShowOnAllPages(ps, potentialContainerPointer, compPointer) {
        return (
            potentialContainerPointer &&
            ((ps.pointers.components.isInMasterPage(potentialContainerPointer) && !ps.pointers.components.isInMasterPage(compPointer)) ||
                (!ps.pointers.components.isInMasterPage(potentialContainerPointer) && ps.pointers.components.isInMasterPage(compPointer)))
        )
    }

    function isLeavingAppWidget(ps, appWidgetsInHierarchy, connection, newContainerPointer) {
        return (
            component.getType(ps, connection.controllerRef) === 'platform.components.AppWidget' &&
            !componentDetectorAPI.isDescendantOfComp(ps, newContainerPointer, connection.controllerRef) &&
            !appWidgetsInHierarchy.some(compRef => ps.pointers.isSamePointer(compRef, connection.controllerRef))
        )
    }

    function someConnectedChildrenLeavingAppWidget(ps, compPointer, potentialContainerPointer) {
        const compsInHierarchy = [compPointer, ...ps.pointers.full.components.getChildrenRecursively(compPointer)]
        const primaryConnections = _(compsInHierarchy)
            .map(candidateRef => connections.getPrimaryConnection(ps, candidateRef))
            .compact()
            .value()
        const appWidgetsInHierarchy = _.filter(compsInHierarchy, candidateRef => component.getType(ps, candidateRef) === 'platform.components.AppWidget')
        return _.some(primaryConnections, connection => isLeavingAppWidget(ps, appWidgetsInHierarchy, connection, potentialContainerPointer))
    }

    function isContainable(ps, metaDataValue, compPointer, potentialContainerPointer) {
        if (metaDataValue === false) {
            return false
        }
        if (someConnectedChildrenLeavingAppWidget(ps, compPointer, potentialContainerPointer)) {
            return false
        }

        if (isTogglingShowOnAllPages(ps, potentialContainerPointer, compPointer)) {
            return getAttributeByPrimaryConnection(ps, 'toggleShowOnAllPagesEnabled', compPointer, metaDataValue, {
                false: false,
                true: metaDataValue
            })
        }
        return metaDataValue
    }

    return {
        getAttributeFromManifest,
        isDuplicatable,
        canReparent,
        isRotatable,
        canBeFixedPosition,
        isResizableSides,
        layoutLimitsHook,
        isContainable
    }
})
