import {BASE_PROPS_SCHEMA_TYPE, DATA_TYPES} from '../constants/constants'
import type {DAL, Pointer} from '@wix/document-manager-core'
import _ from 'lodash'

interface CompNamespaceTypeValidation {
    isValid: boolean
    message?: string
}

const getCompNamespaceTypes = (compDefinition: any, namespace: string) => {
    switch (namespace) {
        case DATA_TYPES.prop:
            if (compDefinition.propertyTypes) {
                return [...compDefinition.propertyTypes, BASE_PROPS_SCHEMA_TYPE]
            }
            if (compDefinition.propertyType && !_.isArray(compDefinition.propertyType)) {
                return [compDefinition.propertyType, BASE_PROPS_SCHEMA_TYPE]
            }
            return [BASE_PROPS_SCHEMA_TYPE, '']
        case DATA_TYPES.design:
            if (compDefinition?.designDataTypes?.length) {
                return compDefinition.designDataTypes.concat('RefArray')
            }
            return ['']
        case DATA_TYPES.data:
            return compDefinition.dataTypes ?? ['']
        case DATA_TYPES.slots:
            return compDefinition.slotsDataType
    }
}

const validateCompNamespaceType = (dal: DAL, componentType: string, itemType: string, namespace: string): CompNamespaceTypeValidation => {
    const {schema} = dal
    const componentDefinition = schema.getComponentDefinition(componentType)
    if (!componentDefinition) {
        return {isValid: true}
    }
    const types = getCompNamespaceTypes(componentDefinition, namespace)
    if (types && !_.includes(types, itemType)) {
        return {
            isValid: false,
            message: `${componentType} ${namespace}, should be one of the following [${types}], but got: ${itemType ? itemType : 'null (no item)'}`
        }
    }
    return {
        isValid: true
    }
}

const DEAD_MOBILE_COMPONENT_TYPE = 'to.be.removed.by.MobileMerge'

const isComponentDefinitionValid = (dal: DAL, pointer: Pointer, comp: any = {}) => {
    const {schema} = dal
    const {componentType} = comp
    const componentDefinition = schema.getComponentDefinition(componentType)
    return !!(componentDefinition || (pointer.type === 'MOBILE' && componentType === DEAD_MOBILE_COMPONENT_TYPE))
}

export {isComponentDefinitionValid, validateCompNamespaceType, CompNamespaceTypeValidation}
