import _ from 'lodash'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import ecomLogger from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomLogger'

function getOptionsDescription(productOptions) {
    const selectedOptionsList = []
    _.forEach(productOptions, function (option) {
        if (option.isSelectableList && option.items && option.items.length) {
            const selectedOption = _.find(option.items, {value: option.selectedValue})
            selectedOptionsList.push(selectedOption.description || selectedOption.text)
        } else if (option.text) {
            selectedOptionsList.push(option.text)
        }
    })
    return selectedOptionsList
}

function getVarsFromCustomizations(userCustomizations) {
    let vars = {
        msgOrientation: 'vertical',
        msgImageMode: 'fill',
        betweenButtonsSpacer: 10,
        gotoCheckoutBtnLbl: '',
        continueShoppingBtnLbl: ''
    }

    const feedbackViewCustomizations = _.filter(userCustomizations, {view: 'FeedbackMessage', fieldId: 'vars'})
    vars = _.transform(
        feedbackViewCustomizations,
        function (result, customizatoin) {
            result[customizatoin.key] = customizatoin.value
        },
        vars
    )
    return vars
}

function FeedbackMessageLogic(partApi) {
    this.partApi = partApi
}

FeedbackMessageLogic.prototype = {
    getViewVars() {
        const pathToProduct = this.partApi.getRootDataItemRef()
        const productBundle = this.partApi.getDataByPath(this.partApi.getPackageName(), pathToProduct)

        //we apply the user customizations here, because we changed the type of the FeedbackMessage view
        const vars = getVarsFromCustomizations(this.partApi.getPartData().appLogicCustomizations)

        // @ts-ignore
        vars.optionDescriptions = getOptionsDescription(productBundle.options).join(', ')

        return vars
    },

    'continue-shopping'() {
        const logicParams = this.partApi.getPartData().appLogicParams
        this.partApi.ecomReportEvent(ecomLogger.events.FEEDBACK_MSG_CONTINUE_SHOPPING_BTN_CLICKED, {
            itemValue: logicParams.itemId.value
        })
        this.closeFeedbackMessage()
    },

    'goto-checkout'() {
        const cartPageData = this.partApi.getCartPageData()
        this.closeFeedbackMessage()
        if (cartPageData) {
            const logicParams = this.partApi.getPartData().appLogicParams
            const cartPageId = _.get(logicParams, 'cartPageID.value')

            this.partApi.ecomReportEvent(ecomLogger.events.FEEDBACK_MSG_CHECKOUT_BTN_CLICKED, {
                itemValue: logicParams.itemId.value
            })
            this.partApi.navigateToPage({pageId: cartPageId})
        }
    },

    closeFeedbackMessage() {
        this.partApi.deleteComponent('ecomFeedback')
    }
}
wixapps.logicFactory.register('03946768-374D-4426-B885-A1A5C6F570B9', FeedbackMessageLogic)
