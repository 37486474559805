define(['documentServices/appStudio/appStudioDataModel'], appStudioDataModel => {
    'use strict'

    const AppType = {
        WIDGETS: 'WIDGETS',
        CODE_PACKAGE: 'CODE_PACKAGE'
    }

    function getAppType(ps) {
        const widgets = appStudioDataModel.getAllWidgets(ps)

        if (widgets.length > 0) {
            return AppType.WIDGETS
        }

        return AppType.CODE_PACKAGE
    }

    return {
        AppType,
        getAppType
    }
})
