import type {DSConfig} from '@wix/document-manager-core'
import fullFunctionalityConfig from './fullFunctionalityConfig'
import responsiveConfig from './responsiveConfig'
import {withoutCsaveExtensions} from './csaveConfigs'

const createFeedbackConfig = (base: Omit<DSConfig, 'origin'>): DSConfig => ({
    ...base,
    modulesList: base.modulesList,
    extensionsList: withoutCsaveExtensions(base.extensionsList),
    disableAutoSave: true,
    isReadOnly: false,
    autosaveRestore: 'false',
    cedit: false,
    origin: 'experts_feedback',
    disableSave: true
})

const responsive = createFeedbackConfig(responsiveConfig)
const classic = createFeedbackConfig(fullFunctionalityConfig)

export {responsive, classic}
