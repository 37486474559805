import _ from 'lodash'
import optionInput from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/components/optionInput'

/**
 * @class components.ColorOption
 * @extends {optionInput}
 * @property {comp.properties} props
 */
export default {
    displayName: 'ColorOption',
    mixins: [optionInput],

    getSkinProperties() {
        const refData = {
            '': {
                style: {backgroundColor: this.props.compData.text}
            },
            tooltip: this.createInfoTipChildComponent()
        }

        // set a click callback only when the component is enabled.
        if (!this.props.compData.disabled) {
            refData[''] = _.merge(refData[''], {
                onClick: this.props.onClick,
                onMouseEnter: this.onMouseEnter,
                onMouseLeave: this.onMouseLeave
            })
        }
        return refData
    }
}
