import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import mediaPostConverter from '@wix/santa-ds-libs/src/wixappsClassics/src/core/data/converters/mediaPostConverter'

function getCoverImage(post, partApi) {
    const image: any = {}
    const pseudoType = mediaPostConverter.getMediaPostPseudoType(post)

    if (pseudoType !== 'TextPost') {
        const coverImageObj = mediaPostConverter.getCoverImageObjFromPost(post, pseudoType)
        if (coverImageObj._type === 'PhotoPost') {
            image.height = coverImageObj.photo.height
            image.width = coverImageObj.photo.width
            image.src = partApi.resolveImageData(coverImageObj.photo).src
        } else if (coverImageObj._type === 'VideoPost') {
            image.src = coverImageObj.video && coverImageObj.video.thumbnail
        }

        if (image.src && !coreUtils.urlUtils.isExternalUrl(image.src)) {
            image.src = partApi.getMediaFullStaticUrl(image.src)
        }
    }

    return image
}

function getOgTags(post, partApi) {
    const tags = []
    const coverImage = getCoverImage(post, partApi)

    if (coverImage.src) {
        tags.push({
            property: 'og:image',
            content: coverImage.src
        })
    }

    return tags
}

function generateJsonLd(post, partApi) {
    const jsonld: any = {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        '@id': post._iid,
        mainEntityOfPage: partApi.getCurrentUrlObj(),
        headline: partApi.getCurrentUrlPageTitle(),
        datePublished: post.date.iso,
        dateModified: post._updatedAt,
        publisher: {
            '@type': 'Organization',
            name: partApi.getSiteTitleSEO()
        }
    }

    const coverImage = getCoverImage(post, partApi)
    if (coverImage.src) {
        jsonld.image = {
            '@type': 'ImageObject',
            url: coverImage.src,
            height: coverImage.height,
            width: coverImage.width
        }
    }

    if (post.author) {
        jsonld.author = {
            '@type': 'Person',
            name: post.author
        }
    }

    return jsonld
}

function getSinglePost(partApi) {
    const packageName = partApi.getPackageName()
    const path = partApi.getRootDataItemRef()
    return path ? partApi.getDataByPath(packageName, path) : null
}

function transformPostIfPreview(post, partApi) {
    if (coreUtils.stringUtils.isTrue(partApi.getCurrentUrlObj().query.preview)) {
        return mediaPostConverter.overrideWithPreviewData(post)
    }
    return post
}

function _getPageTitle(partAPI, post) {
    const title = (partAPI.isMobileView() && post.mobileTitle) || post.metaTitle || post.title || ''
    return _.unescape(title).replace(/(<|>)/g, '').replace(/(")/g, '\u201c')
}

function _getPageDescription(post) {
    const description = post.metaDescription || _.get(post, ['text', 'text']) || ''
    return description.replace(/(<([^>]+)>)/gi, '').substring(0, 159)
}

function _getPageTitleSEO(partAPI, post) {
    return `${_getPageTitle(partAPI, post)} | ${partAPI.getSiteTitleSEO()}`
}

function _getTwitterMetaTags(post, partApi) {
    const tags = [
        {
            name: 'twitter:card',
            content: 'summary'
        },
        {
            name: 'twitter:title',
            content: _getPageTitleSEO(partApi, post)
        },
        {
            name: 'twitter:description',
            content: _getPageDescription(post)
        }
    ]
    const coverImage = getCoverImage(post, partApi)

    if (coverImage.src) {
        tags.push({
            name: 'twitter:image',
            content: coverImage.src
        })
    }

    return tags
}

function updatePageTitleAndMetaTags(post, partApi) {
    const pageTitle = _getPageTitle(partApi, post)
    const metaDescription = _getPageDescription(post)
    const pageTitleSEO = _getPageTitleSEO(partApi, post)
    const metaKeywords = post.tags ? post.tags.toString() : ''
    const ogTags = getOgTags(post, partApi)
    const twitterTags = _getTwitterMetaTags(post, partApi)
    const jsonld = generateJsonLd(post, partApi)

    partApi.updatePageTitleAndMetaTags(pageTitle, metaDescription, pageTitleSEO, metaKeywords, ogTags, jsonld, twitterTags) // triggers head tag render
}

export default {
    getSinglePost,
    transformPostIfPreview,
    updatePageTitleAndMetaTags
}
