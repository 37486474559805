import _ from 'lodash'
import type {SchemaConfig} from '@wix/document-services-types'
import * as schemaArrangement from '../schemaArrangement'
import * as whitelistCleanup from './whitelistCleanup'
import * as restrictiveSchemas from './restrictiveSchemas'
import * as clientOnlySchemas from './schemas'
import * as genericSchemas from './newSchemas'
import * as refData from '../../dist/refData.json'
import * as namespaceMapping from '../../dist/namespaceMapping.json'

const getFriendlyReferenceMap = (typeReferences: any, invertedMapping: any) =>
    _.map(typeReferences, ref => {
        const referencedMap = invertedMapping[ref.referencedMap] || ref.referencedMap
        return _.assign({}, ref, {referencedMap})
    })

const getFriendlyReferenceType = (namespaceReferences: any, invertedMapping: any) =>
    _.mapValues(namespaceReferences, typeReferences => getFriendlyReferenceMap(typeReferences, invertedMapping))

/**
 * converts references[].referencedMap to a namespace (i.e. data instead of document_data)
 * @param refs
 * @param {<String, String>} mapping from namespace to dataMapName (aka NAMESPACE_MAPPING)
 */
const getFriendlyReferences = (refs: any, mapping: any) => {
    const invertedMapping = _.invert(mapping)

    const references = _.mapKeys(refs, (v, k) => {
        const name = k.replace('.json', '')
        return invertedMapping[name] || name
    })
    return _.mapValues(references, namespaceReferences => getFriendlyReferenceType(namespaceReferences, invertedMapping))
}

const getClientOnlySchemas = () => schemaArrangement.arrangeSchemasByDataType(clientOnlySchemas.default)

interface UnifiedSchemasConfigArgs {
    schemas?: any
    restrictedSchemas?: any
    clientOnlySchemas?: any
    refData?: any
}

const getPermanentDataTypes = (schemas: any) =>
    _.mapValues(schemas, schemasInNs => _(schemasInNs).pickBy('isPermanentDataNode').mapValues(_.constant(true)).value())

const getUnifiedSchemasConfig = (unifiedSchemasArg: UnifiedSchemasConfigArgs = {}): SchemaConfig => {
    const {schemas: genericSchemaFiles, restrictedSchemas: restrictedSchemaFiles, clientOnlySchemas: clientOnlySchemasFile, refData: refs} = unifiedSchemasArg
    const schemas = schemaArrangement.arrangeSchemasByDataType(genericSchemaFiles ?? genericSchemas.default)
    const restrictedSchemas = schemaArrangement.arrangeSchemasByDataType(restrictedSchemaFiles ?? restrictiveSchemas.default)
    const references = getFriendlyReferences(refs ?? refData, namespaceMapping)
    const permanentDataTypes = getPermanentDataTypes(clientOnlySchemasFile ?? clientOnlySchemas.default)
    return {
        schemas,
        restrictedSchemas,
        references,
        permanentDataTypes
    }
}

export {getClientOnlySchemas, getUnifiedSchemasConfig, whitelistCleanup}
