define(['lodash', 'documentServices/platform/services/workerService', 'documentServices/platform/services/platformEventsService'], function (
    _,
    workerService,
    platformEventsService
) {
    'use strict'

    function notifyApplication(ps, applicationId, options, isAmendableAction) {
        if (isAmendableAction) {
            _.set(options, 'eventPayload.historySnapshotId', ps.runtimeConfig.nextHistorySnapshotId)
        }
        workerService.triggerEvent(ps, applicationId, options)
        platformEventsService.propagateGeneralEvent(applicationId, options.eventType, options.eventPayload)
    }

    return {
        notifyApplication
    }
})
