import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import checkoutUrlUtil from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/checkoutUrlUtil'
import ecomDataUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomDataUtils'
import cartManager from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/data/cartManager'

const ASPECT_NAME = 'ecomCheckout'

function handleEcomFrameMessage(evt) {
    const checkoutOrigin = this.aspectSiteApi.getSiteData().serviceTopology.ecommerceCheckoutUrl
    if (!_.includes(checkoutOrigin, evt.origin)) {
        return
    }
    const payload = JSON.parse(evt.data) || {}

    switch (payload.eventType) {
        case 'doSuccessCheckout':
            doSuccessCheckout.call(this)
            break
        case 'doCancelCheckout':
            closeCheckoutIframe.call(this)
            break
        case 'goToCustomURL':
            this.aspectSiteApi.href(payload.newURL)
            break
        default:
            coreUtils.log.error('Received unhandled message from ecom iframe: ', evt)
            closeCheckoutIframe.call(this)
            break
    }
}

function closeCheckoutIframe() {
    this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, {modalOpened: false})
}

function doSuccessCheckout() {
    const aspectGlobalData = this.aspectSiteApi.getAspectGlobalData(ASPECT_NAME)
    const returnPageData = _.get(aspectGlobalData, ['returnPageData'])
    cartManager.clearCart(this.aspectSiteApi.getSiteData())
    this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, {modalOpened: false})
    ecomDataUtils.clearApplicationDataStore(this.aspectSiteApi.getSiteData())
    this.aspectSiteApi.navigateToPage(returnPageData)
}

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function EcomCheckoutAspect(aspectSiteApi) {
    /** @type core.SiteAspectsSiteAPI */
    this.aspectSiteApi = aspectSiteApi
    this.aspectSiteApi.registerToMessage(handleEcomFrameMessage.bind(this))
    this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, {modalOpened: false})

    _.bindAll(this, _.functionsIn(this))
}

EcomCheckoutAspect.prototype = {
    showModal(partApi, src) {
        const siteData = this.aspectSiteApi.getSiteData()
        const data = {
            modalOpened: true,
            src,
            returnPageData: coreUtils.wixUrlParser.parseUrl(siteData, checkoutUrlUtil.getSuccessURL(siteData, partApi.getAppPartDefinition()))
        }

        this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, data)
    }
}

export default EcomCheckoutAspect
