import _ from 'lodash'
import platformInit from '@wix/santa-ds-libs/src/platformInit'

const REACT_URL_BASE = '//unpkg.parastorage.com/react@16.0.0/umd/react'
const REACT_DOM_URL_BASE = '//unpkg.parastorage.com/react-dom@16.0.0/umd/react-dom'

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function WixCodeWidgetAspect(aspectSiteAPI) {
    this._aspectSiteAPI = aspectSiteAPI
    this._aspectSiteAPI.registerToSiteReady(() => {
        const siteMembersAspect = this._aspectSiteAPI.getSiteAspect('siteMembers')
        this.callbackId = siteMembersAspect.registerToUserLogin(() => {
            if (!this.isAppInitiated()) {
                this.initApp()
            }
            siteMembersAspect.unRegisterToUserLogin(this.callbackId)
        })
    })
}

WixCodeWidgetAspect.prototype = {
    initApp() {
        // TODO: move to document services
        const siteData = this._aspectSiteAPI.getSiteData()
        const wixCodeAppApi = this._aspectSiteAPI.getWixCodeAppApi()
        const viewerPlatformAppSources = _.get(siteData, ['currentUrl', 'query', 'viewerPlatformAppSources'])

        const applications = platformInit.appsUtils.getAppsBaseInfo({
            rendererModel: siteData.rendererModel,
            clientSpecMap: siteData.getClientSpecMap(),
            serviceTopology: siteData.serviceTopology,
            viewerPlatformAppSources,
            queryParams: siteData.getQueryParams()
        })

        const {namespacesSdkSource, externalComponentsSource, wixCodeNamespacesAndElementorySupportSource, wixCodeViewerAppSource} =
            platformInit.wixCodeUrlUtils.buildScriptsSources(siteData.serviceTopology, {isDebug: siteData.hasDebugQueryParam()})
        const reactSource = REACT_URL_BASE + (siteData.hasDebugQueryParam() ? '.development.js' : '.production.min.js')
        const reactDomSource = REACT_DOM_URL_BASE + (siteData.hasDebugQueryParam() ? '.development.js' : '.production.min.js')

        wixCodeAppApi.init(siteData, siteData.getClientSpecMap(), {
            isMobileView: siteData.isMobileView(),
            debug: siteData.isDebugMode(),
            namespacesSdkSource,
            externalComponentsSource,
            wixCodeNamespacesAndElementorySupportSource,
            wixCodeViewerAppSource,
            runtimeSource: _.get(siteData, ['currentUrl', 'query', 'WixCodeRuntimeSource']),
            ReactLibSource: reactSource,
            ReactDomSource: reactDomSource,
            ReactSource: _.get(siteData, ['currentUrl', 'query', 'ReactSource']),
            applications
        })
    },
    isAppInitiated() {
        const wixCodeAppApi = this._aspectSiteAPI.getWixCodeAppApi()
        return wixCodeAppApi.isAppInitiated()
    },
    destroyAppsContainer() {
        const wixCodeAppApi = this._aspectSiteAPI.getWixCodeAppApi()
        wixCodeAppApi.destroyAppsContainer()
    },
    reloadAppsContainer() {
        const wixCodeAppApi = this._aspectSiteAPI.getWixCodeAppApi()
        wixCodeAppApi.destroyAppsContainer()
        this.initApp()
    }
}

export default WixCodeWidgetAspect
