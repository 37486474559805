// import _ from 'lodash'
import ecomRequestBuilder from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomRequestBuilder'
import ecomDataUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomDataUtils'

function sendRequest(siteData, params, requestMetadata, onSuccessCallback, onFailCallback) {
    requestMetadata = requestMetadata || {}
    requestMetadata.onError = onFailCallback
    const {store} = siteData
    const request = ecomRequestBuilder.buildRequest(siteData, null, ecomDataUtils.packageName, params, requestMetadata)

    delete request.timeout
    store.loadBatch([request], onSuccessCallback)
}

export default {
    sendRequest
}
