define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentStructureInfo',
    'documentServices/component/nicknameContextRegistrar',
    'documentServices/component/customNicknameRegistrar',
    'documentServices/page/pageData',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/connections/connections',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/hooks/hooks',
    'documentServices/mobileUtilities/mobileUtilities'
], function (
    _,
    constants,
    dataModel,
    componentStructureInfo,
    nicknameContextRegistrar,
    customNicknameRegistrar,
    pageData,
    componentsMetaData,
    connections,
    documentModeInfo,
    hooks,
    mobileUtil
) {
    'use strict'

    const MAX_NICKNAME_LENGTH = 128

    const VALIDATIONS = {
        VALID: 'VALID',
        ALREADY_EXISTS: 'ALREADY_EXISTS',
        TOO_SHORT: 'TOO_SHORT',
        TOO_LONG: 'TOO_LONG',
        INVALID_NAME: 'INVALID_NAME'
    }

    const ORIGINAL_CONTEXT_FIELD = 'originalNicknameContext'

    /**
     * @param {string} compNickname
     * @param comps
     * @return {*}
     */
    function getNextSuffixIndex(compNickname, comps) {
        const regex = new RegExp(`${compNickname}(\\d+)`) //will match the number in the end of the nickname
        const maxSuffixOfDefaultNickname = _(comps)
            .map(nickname => {
                const match = regex.exec(nickname)
                return match ? _.parseInt(match[1]) : null
            })
            .concat(0)
            .max()

        return maxSuffixOfDefaultNickname + 1
    }

    function setNicknamesForComponentsWithoutNickname(ps, comps, defaultCompNickname, maxSuffixOfDefaultNickname, context) {
        return _(comps)
            .filter(compPointer => shouldSetNickname(ps, compPointer, context))
            .map(function (comp) {
                const newNickname = defaultCompNickname + maxSuffixOfDefaultNickname++
                setNickname(ps, comp, newNickname, context)
                return comp
            })
            .value()
    }

    function getNicknames(ps, comps, context) {
        return _(comps)
            .map(c => getComponentNickname(ps, c, context))
            .reduce(_.assign)
    }

    function getComponentsInContainer(ps, containerPointer) {
        return ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(containerPointer)
    }

    function getComponentsInContext(ps, pagePointer, context) {
        return context ? _.reject(getComponentsInContainer(ps, context), context) : getComponentsInContainer(ps, pagePointer)
    }

    function generateNicknamesForPage(ps, usedNicknames, pagePointer) {
        const context = getNicknameContext(ps, pagePointer)
        const allCompsInPageContext = getComponentsInContext(ps, pagePointer, context)
        const usedNicknamesInPage = getNicknames(ps, allCompsInPageContext, context)
        const allUsedNickNames = _.assign({}, usedNicknamesInPage, usedNicknames)

        const componentsWithNewNicknamesInPage = generateNicknamesForComponentsImpl(ps, allCompsInPageContext, allUsedNickNames, context)

        return _.assign(usedNicknamesInPage, getNicknames(ps, componentsWithNewNicknamesInPage, context))
    }

    function generateNicknamesForComponentsImpl(ps, compsPointers, usedNickNames, context) {
        const compGroupsByBaseNickname = _.groupBy(compsPointers, compPointer => componentsMetaData.getDefaultNickname(ps, compPointer))

        return _.flatMap(compGroupsByBaseNickname, function (comps, defaultCompNickname) {
            const maxSuffixOfDefaultNickname = getNextSuffixIndex(defaultCompNickname, usedNickNames)
            return setNicknamesForComponentsWithoutNickname(ps, comps, defaultCompNickname, maxSuffixOfDefaultNickname, context)
        })
    }

    function generateNicknamesForComponents(ps, compsPointers, pagePointer, viewMode) {
        const context = getNicknameContext(ps, pagePointer)
        const masterPagePointer = ps.pointers.components.getPage('masterPage', viewMode)
        const allCompsInContext = getComponentsInContext(ps, pagePointer, context).concat(getComponentsInContext(ps, masterPagePointer, context))
        const usedNicknames = getNicknames(ps, allCompsInContext, context)

        generateNicknamesForComponentsImpl(ps, compsPointers, usedNicknames, context)
    }

    function generateNicknamesForPagesInViewMode(ps, pageIdList, viewMode) {
        //TODO: split this to private and public for pages and site
        const masterPagePointer = ps.pointers.components.getPage('masterPage', viewMode)
        const masterPageNickNames = getNicknames(ps, getComponentsInContainer(ps, masterPagePointer), null)
        const nicknamesInAllSite = _.reduce(
            pageIdList,
            function (nickNames, pageId) {
                const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
                const nicknamesForPage = generateNicknamesForPage(ps, masterPageNickNames, pagePointer)
                return _.assign(nickNames, nicknamesForPage)
            },
            {}
        )

        generateNicknamesForPage(ps, nicknamesInAllSite, masterPagePointer)
    }

    /**
     *
     * @param {ps} ps
     * @param pageIdList
     * @param viewMode default is current view mode
     */
    function generateNicknamesForPages(ps, pageIdList, viewMode) {
        if (_.includes(pageIdList, 'masterPage')) {
            generateNicknamesForSite(ps, viewMode)
            return
        }

        viewMode = mobileUtil.getViewMode(ps, viewMode, documentModeInfo)

        generateNicknamesForPagesInViewMode(ps, pageIdList, constants.VIEW_MODES.DESKTOP)

        if (viewMode !== constants.VIEW_MODES.DESKTOP) {
            copyNicknamesFromDesktopToViewMode(ps, pageIdList, viewMode)
            generateNicknamesForPagesInViewMode(ps, pageIdList, viewMode)
        }
    }

    /**
     * @param {ps} ps
     * @param pageIdList
     * @param viewMode
     */
    function copyNicknamesFromDesktopToViewMode(ps, pageIdList, viewMode) {
        _(pageIdList)
            .concat('masterPage')
            .uniq()
            .forEach(function (pageId) {
                const viewModePagePointer = ps.pointers.components.getPage(pageId, viewMode)
                const context = getNicknameContext(ps, viewModePagePointer)
                const viewModePageComponents = getComponentsInContainer(ps, viewModePagePointer)
                const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
                _(viewModePageComponents)
                    .filter(compPointer => shouldSetNickname(ps, compPointer, context))
                    .forEach(function (viewModeCompPointer) {
                        const desktopCompPointer = ps.pointers.components.getComponent(viewModeCompPointer.id, desktopPagePointer)
                        if (desktopCompPointer) {
                            const desktopCompNickname = getNickname(ps, desktopCompPointer, context)
                            if (desktopCompNickname) {
                                setNickname(ps, viewModeCompPointer, desktopCompNickname, context)
                            }
                        }
                    })
            })
    }

    //TODO: split this to private and public for pages and site
    /**
     *
     * @param {ps} ps
     * @param {string} [viewMode] default is current view mode
     */
    function generateNicknamesForSite(ps, viewMode) {
        const popupIdList = _.map(pageData.getPopupsDataItems(ps), 'id')
        const pageIdList = pageData.getPagesList(ps)
        generateNicknamesForPages(ps, pageIdList.concat(popupIdList), viewMode)
    }

    function getNicknameContext(ps, compPointer) {
        return nicknameContextRegistrar.getNicknameContext(ps, compPointer)
    }

    function findConnectionByContext(context, compConnections) {
        return context ? _.find(compConnections, {controllerRef: {id: context.id}}) : getWixCodeConnectionItem(compConnections)
    }

    function findSerializedConnectionByContext(ps, context, compConnections) {
        if (context) {
            const {id: controllerId} = dataModel.getDataItem(ps, context) || {}
            if (controllerId) {
                return _.find(compConnections, {controllerId})
            }
        }
        return getWixCodeConnectionItem(compConnections)
    }

    function getNicknameFromConnectionList(connectionList, context) {
        const connectionItem = findConnectionByContext(context, connectionList)
        if (connectionItem) {
            return connectionItem.role
        }
    }

    function getNickname(ps, compPointer, context = getNicknameContext(ps, compPointer)) {
        const compConnections = connections.get(ps, compPointer)

        return getNicknameFromConnectionList(compConnections, context)
    }

    function getNicknameByConnectionPointer(ps, connectionPtr, pagePointer, context) {
        const connectionItem = connections.getByConnectionPointer(ps, connectionPtr, pagePointer)

        return getNicknameFromConnectionList(connectionItem, context)
    }

    function getComponentNickname(ps, compPointer, context = getNicknameContext(ps, compPointer)) {
        const compConnections = connections.get(ps, compPointer)

        const nickname = getNicknameFromConnectionList(compConnections, context)

        const componentType = componentStructureInfo.getType(ps, compPointer)

        const customNicknameGetter = customNicknameRegistrar.getCustomGetter(componentType)

        if (customNicknameGetter) {
            return customNicknameGetter(ps, compPointer, nickname, context)
        }

        return nickname ? {[compPointer.id]: nickname} : {}
    }

    function getWixCodeConnectionItem(currentConnections) {
        return _.find(currentConnections, {type: 'WixCodeConnectionItem'})
    }

    function createConnectionItem(context, nickname) {
        return context
            ? {
                  type: 'ConnectionItem',
                  role: nickname,
                  controllerRef: context,
                  isPrimary: true
              }
            : {
                  type: 'WixCodeConnectionItem',
                  role: nickname
              }
    }

    function setNickname(ps, compPointer, nickname, context = getNicknameContext(ps, compPointer)) {
        if (validateNickname(ps, compPointer, nickname) !== VALIDATIONS.VALID) {
            throw new Error('The new nickname you provided is invalid')
        }
        hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_BEFORE, 'updateConnectionsItem', [ps, compPointer, nickname])

        if (_.get(compPointer, 'id') === _.get(context, 'id')) {
            throw new Error('Cannot set nickname of context component to itself')
        }

        let currentConnections = connections.get(ps, compPointer)
        const connection = findConnectionByContext(context, currentConnections)
        if (connection) {
            connection.role = nickname
        } else {
            const newConnectionItem = createConnectionItem(context, nickname)

            currentConnections = [newConnectionItem].concat(currentConnections)
        }

        dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
        hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_AFTER, 'updateConnectionsItem', [ps, compPointer, nickname])
    }

    function removeNickname(ps, compPointer, context = getNicknameContext(ps, compPointer)) {
        const currentConnections = connections.get(ps, compPointer)
        const connection = findConnectionByContext(context, currentConnections)
        if (connection) {
            _.remove(currentConnections, connection)
            if (currentConnections.length > 0) {
                dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
            } else {
                dataModel.removeConnectionsItem(ps, compPointer)
            }
        }
    }

    function getPagePointersInSameContext(ps, pagePointer) {
        const viewMode = pagePointer.type
        if (pagePointer.id === 'masterPage') {
            const nonDeletedPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(true)
            return _.map(nonDeletedPagesPointers, nonDeletedPagePointer => ps.pointers.full.components.getPage(nonDeletedPagePointer.id, viewMode))
        }
        return [pagePointer, ps.pointers.full.components.getMasterPage(viewMode)]
    }

    function hasComponentWithThatNickname(ps, containingPagePointer, searchedNickname, compPointerToExclude) {
        if (!searchedNickname) {
            return false
        }

        compPointerToExclude = compPointerToExclude ? ps.pointers.full.components.getComponent(compPointerToExclude.id, containingPagePointer) : undefined
        const pagesSharingNicknames = getPagePointersInSameContext(ps, containingPagePointer)

        return _.some(pagesSharingNicknames, pagePointer => {
            const context = getNicknameContext(ps, pagePointer)
            const compsInPage = ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(pagePointer)
            return !_(compsInPage)
                .thru(comps => getNicknames(ps, comps, context))
                .pickBy((nickname, compId) => (compPointerToExclude ? compPointerToExclude.id !== compId : true))
                .pickBy(nickname => nickname === searchedNickname)
                .isEmpty()
        })
    }

    /**
     * @param {string} nickname
     * @return {boolean}
     */
    function hasInvalidCharacters(nickname) {
        const validName = /^[a-zA-Z0-9]+$/
        return !validName.test(nickname)
    }

    function validateNickname(ps, compPointer, nickname) {
        if (_.isEmpty(nickname)) {
            return VALIDATIONS.TOO_SHORT
        }
        if (nickname.length > MAX_NICKNAME_LENGTH) {
            return VALIDATIONS.TOO_LONG
        }

        const componentPagePointer = componentStructureInfo.getPage(ps, compPointer)
        if (hasComponentWithThatNickname(ps, componentPagePointer, nickname, compPointer)) {
            return VALIDATIONS.ALREADY_EXISTS
        }

        if (hasInvalidCharacters(nickname)) {
            return VALIDATIONS.INVALID_NAME
        }

        return VALIDATIONS.VALID
    }

    function shouldSetNickname(ps, compPointer, context) {
        return (
            !ps.pointers.components.isMasterPage(compPointer) &&
            !getNickname(ps, compPointer, context) &&
            componentsMetaData.shouldAutoSetNickname(ps, compPointer)
        )
    }

    function removeNicknameFromComponentIfInvalid(ps, compPointer, containerPointer) {
        const pagePointer = componentStructureInfo.isPageComponent(ps, containerPointer)
            ? containerPointer
            : componentStructureInfo.getPage(ps, containerPointer)
        const context = getNicknameContext(ps, pagePointer)
        _.forEach(ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer), function (comp) {
            const nickname = getNickname(ps, comp, context)
            if (hasComponentWithThatNickname(ps, pagePointer, nickname, comp)) {
                removeNickname(ps, comp, context)
            }
        })
    }

    function fixConnections(ps, context, compDefinition) {
        const items = _.get(compDefinition, ['connections', 'items'], [])
        const wixCodeConnectionItem = _.find(items, {type: 'WixCodeConnectionItem'})
        if (context && wixCodeConnectionItem) {
            const {role} = wixCodeConnectionItem
            const controllerId = dataModel.getDataItem(ps, context).id
            const connectionItem = connections.createConnectionItem(role, controllerId)

            compDefinition.connections.items = _(items).without(wixCodeConnectionItem).concat([connectionItem]).value()
        }
    }

    function removeConnectionFromSerializedComponentIfInvalidNickname(ps, compPointer, context, connectionItems, pagePointer) {
        const nicknameConnectionItem = findSerializedConnectionByContext(ps, context, connectionItems)
        if (nicknameConnectionItem && hasComponentWithThatNickname(ps, pagePointer, nicknameConnectionItem.role, compPointer)) {
            _.remove(connectionItems, nicknameConnectionItem)
        }
    }

    function updateConnectionsIfNeeded(ps, compPointer, containerPointer, rootCompDefinition) {
        const pagePointer = ps.pointers.full.components.getPageOfComponent(containerPointer)
        const nicknameContext = getNicknameContext(ps, pagePointer)
        const compsQueue = [rootCompDefinition]

        while (compsQueue.length) {
            const compDefinition = compsQueue.shift()

            fixConnections(ps, nicknameContext, compDefinition)
            removeConnectionFromSerializedComponentIfInvalidNickname(
                ps,
                compPointer,
                nicknameContext,
                _.get(compDefinition, ['connections', 'items']),
                pagePointer
            )

            compsQueue.push(...(compDefinition.components || []))
        }
    }

    function getContextControllerId(ps, context) {
        return _.get(dataModel.getDataItem(ps, context), 'id')
    }

    function updateNicknameContextByNewContainer(ps, compPointer, componentDefinition, newContainerPointer) {
        const connectionItems = _.get(componentDefinition, ['connections', 'items'])
        if (_.isEmpty(connectionItems)) {
            return
        }

        const contextInCurrentContainer = _.get(componentDefinition, ['custom', ORIGINAL_CONTEXT_FIELD])
        if (contextInCurrentContainer) {
            const pagePointer = ps.pointers.full.components.getPageOfComponent(newContainerPointer)
            updateConnectionItemsNickname(ps, connectionItems, compPointer, pagePointer, contextInCurrentContainer)
        }
    }
    function updateConnectionItemsNickname(ps, connectionItems, compPointer, pagePointer, contextInCurrentContainer) {
        const connectionItem = findSerializedConnectionByContext(ps, contextInCurrentContainer, connectionItems)
        if (connectionItem) {
            const context = getNicknameContext(ps, pagePointer)
            connectionItem.controllerId = getContextControllerId(ps, context)
            removeConnectionFromSerializedComponentIfInvalidNickname(ps, compPointer, context, connectionItems, pagePointer)
        }
    }

    function setOriginalContextToSerializedComponent(ps, compPointer, customStructureData) {
        const context = getNicknameContext(ps, compPointer)

        if (context) {
            customStructureData[ORIGINAL_CONTEXT_FIELD] = context
        }
    }

    return {
        getNicknameByConnectionPointer,
        generateNicknamesForComponents,
        generateNicknamesForSite,
        getNickname,
        setNickname,
        removeNickname,
        removeNicknameFromComponentIfInvalid,
        updateConnectionsIfNeeded,
        updateNicknameContextByNewContainer,
        setOriginalContextToSerializedComponent,
        updateConnectionItemsNickname,
        validateNickname,
        generateNicknamesForPages,
        hasComponentWithThatNickname,
        getComponentsInPage: getComponentsInContainer,
        findSerializedConnectionByContext,
        VALIDATIONS
    }
})
