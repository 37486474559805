import {DAL, DmApis, Extension, PointerMethods, pointerUtils, DocumentDataTypes, CreateExtensionArgument} from '@wix/document-manager-core'
import type {Pointers} from '@wix/document-services-types'
import _ from 'lodash'
import {tryFetch} from '../utils/fetchUtils'

const {getPointer} = pointerUtils

/**
 * Creates the accountInfo extension that will provide the accountInfo and coBranding service to privateServices
 */
const coBrandingPointerType = 'coBranding'
const ACCOUNT_SERVER_BASE_URL = '/_api/account-server/v1/accounts/'

const createExtension = ({logger, environmentContext}: CreateExtensionArgument): Extension => {
    const {fetchFn} = environmentContext
    const getDocumentDataTypes = (): DocumentDataTypes => ({[coBrandingPointerType]: {}})

    const createPointersMethods = (): PointerMethods => {
        const getCoBranding = () => getPointer(coBrandingPointerType, coBrandingPointerType)
        return {
            accountInfo: {getCoBranding}
        }
    }

    const fetchAndWriteCoBrandingData = async (dal: DAL, pointers: Pointers, accountId: string) => {
        const errorDescriptor = {
            message: 'failed to fetch coBranding data',
            tags: {fetchAndWriteCoBrandingData: true}
        }
        try {
            const jsonData = await tryFetch(`${ACCOUNT_SERVER_BASE_URL}${accountId}`, 'GET', fetchFn, logger, errorDescriptor)
            const coBrandingInfoFromServer = _.get(jsonData, ['account', 'accountProperties', 'coBranding'])
            const coBrandedValue = _.isString(coBrandingInfoFromServer) ? coBrandingInfoFromServer : 'None'
            dal.set(pointers.accountInfo.getCoBranding(), coBrandedValue)
        } catch (e) {
            dal.set(pointers.accountInfo.getCoBranding(), 'None')
        }
    }

    const initialize = async ({dal, pointers}: DmApis) => {
        const userInfo = dal.get(pointers.documentServicesModel.getUserInfo())
        const accountId = _.get(userInfo, ['accountId'])
        await fetchAndWriteCoBrandingData(dal, pointers, accountId)
    }

    return {
        name: 'accountInfo',
        createPointersMethods,
        getDocumentDataTypes,
        initialize
    }
}

export {createExtension}
