import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const visualFocusData = viewerLibrary.get('documentServices/accessibility/visualFocusData')
    const autoDomReorderData = viewerLibrary.get('documentServices/accessibility/autoDomReorderData')
    const componentA11yAPI = viewerLibrary.get('documentServices/accessibility/componentA11yAPI')
    const skipToContent = viewerLibrary.get('documentServices/accessibility/skipToContentData')
    return {
        methods: {
            components: {
                getA11ySchema: publicMethodUtils.defineGetter(componentA11yAPI.getA11ySchema)
            },
            accessibility: {
                visualFocus: {
                    get: publicMethodUtils.defineGetter(visualFocusData.get),
                    set: publicMethodUtils.defineDataManipulationAction(visualFocusData.set)
                },
                autoDomReorder: {
                    get: publicMethodUtils.defineGetter(autoDomReorderData.get),
                    set: publicMethodUtils.defineDataManipulationAction(autoDomReorderData.set)
                },
                skipToContent: {
                    get: publicMethodUtils.defineGetter(skipToContent.get),
                    set: publicMethodUtils.defineDataManipulationAction(skipToContent.set)
                }
            }
        }
    }
}
