import layout from '../util/layout'

function patchQuickActionBarItem(id, patchers) {
    patchers.css(id, {
        top: '',
        left: '',
        width: '',
        height: '',
        position: '',
        'z-index': ''
    })
}

layout.registerPatcher('wysiwyg.viewer.components.QuickActionBarItem', patchQuickActionBarItem)
