import type {ViewerManager, ViewerSiteAPI} from '@wix/viewer-manager-adapter'
import _ from 'lodash'
import {SetOperationsQueueBatch} from './SOQBatch'
import type {QItemParams} from './SOQItem'
import type {CoreLogger} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import {constants} from '@wix/document-manager-host-common'
import type {Experiment} from '@wix/document-services-types'

const {INTERACTIONS} = constants

interface Args {
    logger: CoreLogger
    viewerManager: ViewerManager
    errorMessage: string
    batchItems: QItemParams[]
    tagName: string
    errorName: string
    errorCode: number
    waitingFor: any
    batchWaitingForTransition: boolean
}

const getViewerInstanceName = () => (_.has(window, ['boltInstance']) ? 'boltInstance' : 'tbInstance')
const getInfoFromViewerInstance = () => ({
    dataRequirementCheckers: JSON.stringify(_.omitBy(_.get(window, [getViewerInstanceName(), 'dataRequirementCheckers'], {})), null, 4)
})

const reportError = ({logger, viewerManager, errorMessage, batchItems, tagName, errorName, errorCode, waitingFor, batchWaitingForTransition}: Args) => {
    const appLoaded = _.get(window, ['APP_LOADED'], false)
    const methodNames = JSON.stringify(_.map(batchItems, 'methodName'), null, 4)
    const debugInfoFromViewerAPI = viewerManager.viewerSiteAPI.getDebugInfo?.()
    const viewerDebugInfo = debugInfoFromViewerAPI ? debugInfoFromViewerAPI : getInfoFromViewerInstance()

    logger.captureError(
        new ReportableError({
            errorType: errorName,
            message: errorMessage,
            tags: {[tagName]: true, appLoaded, waitingFor, batchWaitingForTransition},
            extras: {methodNames, ...viewerDebugInfo}
        })
    )

    // Bundle these together because there's a 4 parameter limit in reportBIError (not counting errorCode & errorName)
    const loadedAndWaiting = JSON.stringify({appLoaded, waitingFor})
    viewerManager.viewerSiteAPI.reportBIError(errorCode, errorName, methodNames, viewerDebugInfo.dataRequirementCheckers as string, loadedAndWaiting)
}

function isBatchWaitingForTransition(batch: SetOperationsQueueBatch) {
    return _.some(batch.activeItems, {waitingForTransition: true} as any)
}

const promise = (register: (reason?: any) => void) => () => new Promise(register)
const waitIfNeeded = (tester: () => boolean, promiseFactory: () => Promise<any>) => (tester() ? promiseFactory() : Promise.resolve())

/** Wait for view mode switch, if one in pending
 *
 * @param {ViewerSiteAPI}  viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForViewModeSwitch = (viewerSiteAPI: ViewerSiteAPI) =>
    waitIfNeeded(viewerSiteAPI.isDuringViewModeSwitch, promise(viewerSiteAPI.registerToNextSwitchViewModeDone))

/** Wait for navigation, if one is pending
 *
 * @param {SetOperationsQueueBatch} batch
 * @param {ViewerSiteAPI}  viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForNavigation = (viewerSiteAPI: ViewerSiteAPI, batch: SetOperationsQueueBatch) =>
    waitIfNeeded(() => isBatchWaitingForTransition(batch) && viewerSiteAPI.isDuringNavigationInteraction(), promise(viewerSiteAPI.registerNavigationComplete))

/**
 * Wait for layout, if one is pending
 * @param viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForLayout = (viewerSiteAPI: ViewerSiteAPI) => {
    return waitIfNeeded(viewerSiteAPI.isLayoutPending, promise(viewerSiteAPI.registerToNextRenderDone))
}

/** Wait for pending async viewer updates to finish, if necessary
 *
 * The aforementioned viewer updates are:
 * - View mode switch
 * - Navigation
 * - Data requirements
 * - Layouts
 *
 * @param {ViewerSiteAPI} viewerSiteAPI
 * @param {SetOperationsQueueBatch} batch
 * @returns {Promise<void>}
 */
const waitForViewer = async (viewerSiteAPI: ViewerSiteAPI, batch: SetOperationsQueueBatch) => {
    await waitForViewModeSwitch(viewerSiteAPI)
    await waitForNavigation(viewerSiteAPI, batch)
    await viewerSiteAPI.waitForDataRequirements()
    await waitForLayout(viewerSiteAPI)
}

/** Wait for pending async viewer updates to finish, if necessary
 *
 * The aforementioned viewer updates are:
 * - View mode switch
 * - Navigation
 * - Data requirements
 * - Layout
 */
export async function waitForViewerLoaded(viewerSiteAPI: ViewerSiteAPI, logger: CoreLogger, experimentInstance: Experiment) {
    if (experimentInstance.isOpen('dm_waitForViewerFromViewerAPI')) {
        await viewerSiteAPI.waitForViewer()
    } else {
        logger.interactionStarted(INTERACTIONS.VIEWER_MODE_SWITCH)
        await waitForViewModeSwitch(viewerSiteAPI)
        logger.interactionEnded(INTERACTIONS.VIEWER_MODE_SWITCH)
        logger.interactionStarted(INTERACTIONS.VIEWER_NAVIGATION)
        await waitForNavigation(viewerSiteAPI, new SetOperationsQueueBatch())
        logger.interactionEnded(INTERACTIONS.VIEWER_NAVIGATION)
        logger.interactionStarted(INTERACTIONS.VIEWER_DATA_REQUIREMENTS)
        await viewerSiteAPI.waitForDataRequirements()
        logger.interactionEnded(INTERACTIONS.VIEWER_DATA_REQUIREMENTS)
        logger.interactionStarted(INTERACTIONS.VIEWER_LAYOUT)
        await waitForLayout(viewerSiteAPI)
        logger.interactionEnded(INTERACTIONS.VIEWER_LAYOUT)
    }
}

//in santa we always listen to did layout, and if there is no batch in progress do stuff
//in bolt, if we have layout we probably have to do the same, and if we don't I'm not sure
export async function flushBatch(
    commit: () => void,
    viewerManager: ViewerManager,
    logger: CoreLogger,
    experimentInstance: Experiment,
    batch: SetOperationsQueueBatch
) {
    const {viewerSiteAPI} = viewerManager
    let waitingFor = 'nothing'
    let wasQueueWaitingTooLong = false
    const timeoutHandle = setTimeout(() => {
        wasQueueWaitingTooLong = true
        reportError({
            logger,
            viewerManager,
            errorMessage: 'ds soq timeout',
            batchItems: batch.items,
            tagName: 'ds_soq',
            errorName: 'DS_SOQ_TIMEOUT',
            errorCode: 65536,
            waitingFor,
            batchWaitingForTransition: isBatchWaitingForTransition(batch)
        })
    }, 35000)

    //what happens if undo triggers a page transition - we can compare currentPage and pageToNavigateTo
    try {
        commit()
    } catch (exception) {
        clearTimeout(timeoutHandle)
        throw exception
    }

    waitingFor = 'viewer'
    if (experimentInstance.isOpen('dm_waitForViewerFromViewerAPI')) {
        await viewerSiteAPI.waitForViewer()
    } else {
        await waitForViewer(viewerSiteAPI, batch)
    }

    waitingFor = 'nothing'
    clearTimeout(timeoutHandle)

    if (wasQueueWaitingTooLong) {
        wasQueueWaitingTooLong = false
        reportError({
            logger,
            viewerManager,
            errorMessage: 'ds soq timeout ended',
            batchItems: batch.items,
            tagName: 'ds_soq_timeout_ended',
            errorName: 'DS_SOQ_TIMEOUT_ENDED',
            errorCode: 65537,
            waitingFor,
            batchWaitingForTransition: isBatchWaitingForTransition(batch)
        })
    }
}
