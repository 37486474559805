define([
    'lodash',
    '@wix/santa-core-utils',
    '@wix/document-services-json-schemas',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/hooks/hooks',
    'documentServices/component/componentStructureInfo',
    'documentServices/component/componentModes',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/utils/utils',
    'documentServices/theme/isSystemStyle',
    'documentServices/siteMetadata/language',
    'experiment'
], function (
    _,
    santaCoreUtils,
    jsonSchemas,
    constants,
    dataModel,
    hooks,
    componentStructureInfo,
    componentModes,
    mobileConversionFacade,
    componentsMetaData,
    mobileUtil,
    dsUtils,
    isSystemStyle,
    language,
    experiment
) {
    'use strict'

    const {
        namespaceMapping: {getNamespaceConfig, featureNamespaces}
    } = jsonSchemas
    const {
        DATA_TYPES,
        COMP_DATA_QUERY_KEYS_WITH_STYLE,
        SERIALIZED_SCOPED_DATA_KEYS,
        SERIALIZED_DATA_KEYS,
        RELATION_DATA_TYPES,
        REF_ARRAY_DATA_TYPE,
        DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT
    } = constants
    const CUSTOM_COMP_DEFINITION_ATTRIBUTE = 'custom'
    const SPLITTED_DATA_TYPES = ['dataQuery', 'designQuery']
    const {stripHashIfExists} = dsUtils

    function serializeComponent(
        ps,
        componentPointer,
        dataItemPointer,
        ignoreChildren,
        maintainIdentifiers,
        flatMobileStructuresMap,
        structureEnricher,
        useOriginalLanguage
    ) {
        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
        structureEnricher = structureEnricher || _.noop

        const flags = {
            shouldAddMobilePresets: mobileConversionFacade.shouldCreateMobilePreset(ps, actualComponentPointer),
            ignoreChildren,
            maintainIdentifiers
        }
        const serializedComponent = serializeComponentStructureAndData(
            ps,
            actualComponentPointer,
            dataItemPointer,
            flatMobileStructuresMap,
            structureEnricher,
            flags,
            useOriginalLanguage
        )
        if (serializedComponent) {
            serializedComponent.activeModes = getActiveModesOfComponentAncestors(ps, actualComponentPointer)
        }
        return serializedComponent
    }

    function shouldMaintainIdentifiers(ps, componentPointer, currentValue) {
        return currentValue || componentsMetaData.shouldForceMaintainIDsOnSerialize(ps, componentPointer)
    }

    function getActiveModesOfComponentAncestors(ps, componentPointer) {
        const activeModesThatCanAffectSerializedComp = {}

        let componentAncestor = ps.pointers.full.components.getParent(componentPointer)
        while (componentAncestor) {
            const compActiveModesInPage = componentModes.getComponentActiveModeIds(ps, componentAncestor)
            _.merge(activeModesThatCanAffectSerializedComp, compActiveModesInPage)
            componentAncestor = ps.pointers.full.components.getParent(componentAncestor)
        }
        return activeModesThatCanAffectSerializedComp
    }

    function serializeComponentStructureAndData(ps, componentPointer, dataItemPointer, flatMobileStructuresMap, structureEnricher, flags, useOriginalLanguage) {
        flags = _.defaults(
            {
                maintainIdentifiers: shouldMaintainIdentifiers(ps, componentPointer, flags.maintainIdentifiers)
            },
            flags
        )
        flatMobileStructuresMap = flatMobileStructuresMap || getFlatMobileStructuresMap(ps)
        let compStructure

        if (ps.pointers.page.isExists(componentPointer.id)) {
            const pagePointer = ps.pointers.page.getPagePointer(componentPointer.id)
            const pageInnerPointer = ps.pointers.getInnerPointer(pagePointer, ['structure'])
            compStructure = ps.dal.full.get({...pageInnerPointer, source: 'serialize'})
        } else {
            compStructure = ps.dal.full.get(componentPointer)
        }

        if (!compStructure) {
            return null
        }

        const isRepeatedComponent = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)

        compStructure = _.reduce(
            SPLITTED_DATA_TYPES,
            function (res, dataType) {
                if (res[dataType]) {
                    if (flags.itemId) {
                        res[dataType] = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(res[dataType], flags.itemId)
                    } else if (isRepeatedComponent) {
                        res[dataType] = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, dataType))
                    }
                }
                return res
            },
            compStructure
        )

        const pagePointer = ps.pointers.full.components.getPageOfComponent(componentPointer)
        const pageId = pagePointer.id

        resolveDataItems(ps, compStructure, dataItemPointer, flags, pageId, structureEnricher, useOriginalLanguage)
        hooks.executeHook(hooks.HOOKS.SERIALIZE.DATA_AFTER, compStructure.componentType, [ps, componentPointer, compStructure, flags, pageId])

        if (!flags.ignoreChildren) {
            const childrenFlags = _.clone(flags)
            if (isRepeatedComponent) {
                childrenFlags.itemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
            }
            hooks.executeHook(hooks.HOOKS.SERIALIZE.CHILDREN_BEFORE, compStructure.componentType, [compStructure, childrenFlags])
            serializeChildren(ps, compStructure, componentPointer, pagePointer, flatMobileStructuresMap, structureEnricher, childrenFlags, useOriginalLanguage)
            if (flags.maintainIdentifiers) {
                mobileUtil.serializeMobileChildren(
                    ps,
                    compStructure,
                    pageId,
                    childrenFlags,
                    flatMobileStructuresMap,
                    structureEnricher,
                    useOriginalLanguage,
                    serializeComponentStructureAndData
                )
            }
        } else {
            compStructure.components = []
        }

        if (!flags.maintainIdentifiers) {
            delete compStructure.mobileComponents
            delete compStructure.id
        }

        const customStructureData = {}
        const hookArguments = [ps, componentPointer, customStructureData]
        hooks.executeHook(hooks.HOOKS.SERIALIZE.SET_CUSTOM, compStructure.componentType, hookArguments)
        if (!_.isEmpty(customStructureData)) {
            compStructure[CUSTOM_COMP_DEFINITION_ATTRIBUTE] = _.defaults(compStructure[CUSTOM_COMP_DEFINITION_ATTRIBUTE] || {}, customStructureData)
        }
        // TODO: should a failed hook fail the whole serialization process ?

        if (componentPointer.type === constants.VIEW_MODES.DESKTOP) {
            mobileUtil.serializeMobileStructure(
                ps,
                componentPointer,
                compStructure,
                pageId,
                flatMobileStructuresMap,
                structureEnricher,
                flags,
                resolveDataItems,
                serializeComponentStructureAndData,
                {
                    componentStructureInfo,
                    mobileConversionFacade,
                    dataModel
                }
            )
        }

        return compStructure
    }

    /**
     * @param {ps} ps
     * @param compStructure
     * @param componentPointer
     * @param pagePointer
     * @param flatMobileStructuresMap
     * @param structureEnricher
     * @param flags
     * @param useOriginalLanguage
     */
    function serializeChildren(ps, compStructure, componentPointer, pagePointer, flatMobileStructuresMap, structureEnricher, flags, useOriginalLanguage) {
        const {slotsQuery} = compStructure
        const childrenPointers = ps.pointers.full.components.getChildren(componentPointer)
        const hadChildren = childrenPointers && childrenPointers.length

        if (slotsQuery) {
            const slotsData = ps.dal.get(ps.pointers.getPointer(slotsQuery, constants.DATA_TYPES.slots))
            const slotNamesAndValues = slotsData.slots

            const serializeComponentById = id => {
                const slotPointer = ps.pointers.components.getComponent(stripHashIfExists(id), pagePointer)
                return serializeComponentStructureAndData(ps, slotPointer, null, flatMobileStructuresMap, structureEnricher, flags, useOriginalLanguage)
            }

            compStructure.slots = {
                type: slotsData.type,
                slots: {
                    ..._.mapValues(slotNamesAndValues, compId => {
                        const idWithoutHash = stripHashIfExists(compId)

                        _.remove(childrenPointers, {id: idWithoutHash})
                        return serializeComponentById(idWithoutHash)
                    })
                }
            }

            delete compStructure.slotsQuery
        }

        if (hadChildren) {
            compStructure.components = _.map(childrenPointers, function (compPointer) {
                return serializeComponentStructureAndData(ps, compPointer, null, flatMobileStructuresMap, structureEnricher, flags, useOriginalLanguage)
            })
        }
    }

    function getFlatMobileStructuresMap(ps) {
        const mobileStructuresPointer = ps.pointers.general.getMobileStructuresPointer()
        const mobileStructures = ps.dal.get(mobileStructuresPointer)
        const map = {}

        function populateMap(component) {
            map[component.id] = _.omit(_.clone(component), 'components')
            _.forEach(component.components, populateMap)
        }

        _.forOwn(mobileStructures, populateMap)

        return map
    }

    function shouldUseDesignOverVariants(ps, compStructure, pageId) {
        const designQuery = _.get(compStructure, COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design])
        const designPointer = ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
        const compDesign = ps.dal.get(designPointer)
        return experiment.isOpen('dm_designOverVariants') || dataModel.refArray.isRefArray(ps, compDesign)
    }

    function resolveDataItems(ps, compStructure, dataItemPointer, flags, pageId, structureEnricher, useOriginalLanguage) {
        structureEnricher(compStructure)
        serializeDataOnStructure(ps, flags, compStructure, pageId, dataItemPointer, useOriginalLanguage)
        serializePropertiesOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeMobileHintsOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        if (!shouldUseDesignOverVariants(ps, compStructure, pageId)) {
            serializeDesignOnStructure(ps, flags, compStructure, pageId)
        }
        serializeAnchorDataOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeFeaturesOnStructure(ps, flags, compStructure, pageId)
        serializeBehaviorOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeConnectionDataOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeStatesOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeTriggersOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeVariantsOnStructure(ps, flags.maintainIdentifiers, compStructure, pageId)
        serializeVariablesOnStructure(ps, flags, compStructure, pageId)
        serializeAllRelationalDataOnStructure(ps, flags, compStructure, pageId)
        serializeTranslationDataOnStructure(ps, flags.maintainIdentifiers, compStructure, dataItemPointer, pageId)

        if (_.get(compStructure, 'modes.overrides')) {
            resolveDataItemsInOverrides(ps, compStructure.modes.overrides, flags, pageId, structureEnricher)
        }
    }

    function resolveDataItemsInOverrides(ps, componentModesOverrides, flags, pageId, structureEnricher) {
        _.forEach(componentModesOverrides, function (override) {
            structureEnricher(override)
            serializeRelationalDataOnStructureByType(ps, flags, override, pageId, DATA_TYPES.theme)
            if (shouldUseDesignOverVariants(ps, componentModesOverrides, pageId)) {
                serializeRelationalDataOnStructureByType(ps, flags, override, pageId, DATA_TYPES.design)
            } else {
                serializeDesignOnStructure(ps, flags, override, pageId)
            }
            serializePropertiesOnStructure(ps, flags.maintainIdentifiers, override, pageId)
        })
    }

    /**
     * Checks if dataPointer has a translation in lang
     *
     * @param {ps} ps
     * @param {string} dataItemId
     * @param {string} lang
     * @returns {boolean} true if translation exists, false if it doesn't
     */
    const hasTranslation = (ps, dataItemId, lang, pageId) => {
        const dataItemTranslationPointer = ps.pointers.multilingualTranslations.translationDataItem(pageId, lang, dataItemId)

        return ps.dal.isExist(dataItemTranslationPointer)
    }

    /**
     * Retrieves the serialized representation of component translations.
     *
     * @param {ps} ps
     * @param {Pointer} dataPointer
     * @param {boolean} maintainIdentifiers
     * @param {string} pageId
     * @returns {object}
     */
    const getSerializedTranslations = (ps, dataPointer, maintainIdentifiers, pageId) => {
        if (!dataPointer) {
            return
        }

        const langs = language.getFull(ps).map(lang => lang.languageCode)
        const langsForData = _.filter(langs, lang => hasTranslation(ps, dataPointer.id, lang, pageId))

        if (langsForData.length) {
            return _.reduce(
                langsForData,
                (result, langCode) => {
                    const data = dataModel.getDataItemByIdInLang(ps, dataPointer.id, pageId, !maintainIdentifiers, langCode)
                    result[langCode] = data
                    return result
                },
                {}
            )
        }
    }

    /**
     *
     *
     * @param {ps} ps
     * @param {boolean} maintainIdentifiers
     * @param {object} compStructure
     * @param {Pointer} dataItemPointer
     * @param {string} pageId
     */
    function serializeTranslationDataOnStructure(ps, maintainIdentifiers, compStructure, dataItemPointer, pageId) {
        if (!dataItemPointer) {
            const viewMode = ps.siteAPI.isMobileView() ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
            const compPointer = ps.pointers.components.getComponent(compStructure.id, ps.pointers.components.getPage(pageId, viewMode))
            dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
        }

        const translations = getSerializedTranslations(ps, dataItemPointer, maintainIdentifiers, pageId)

        if (translations) {
            compStructure.translations = translations
        }
    }

    function generateRepeatedData(ps, query, pageId, dataItemPointer, repeatedItemIds, getItemFunc) {
        const dataItem = dataModel.createDataItemByType(ps, 'RepeatedData')
        dataItem.original = getItemFunc(ps, query, pageId, dataItemPointer)
        dataItem.overrides = _.zipObject(
            repeatedItemIds,
            _.map(repeatedItemIds, function (itemId) {
                const displayedQuery = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(query, itemId)
                return getItemFunc(ps, displayedQuery, pageId)
            })
        )
        return dataItem
    }

    function serializeDataOnStructure(ps, flags, compStructure, pageId, dataItemPointer, useOriginalLanguage) {
        const {dataQuery} = compStructure
        if (dataQuery) {
            compStructure.data = serializeDataItem(ps, dataQuery, flags, pageId, DATA_TYPES.data, dataItemPointer, useOriginalLanguage)
            delete compStructure.dataQuery
        }
    }

    function getSerializedDesignItem(ps, designQuery, pageId, designItemPointer) {
        const designPointer = designItemPointer || ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
        return dataModel.serializeDataItem(ps, DATA_TYPES.design, designPointer, true)
    }

    function serializeDesignOnStructure(ps, flags, structureWithDesign, pageId) {
        const {designQuery} = structureWithDesign
        if (designQuery) {
            const {repeatedItemIds} = flags
            const shouldGenerateRepeatedData = hasRepeatedItems(repeatedItemIds)
            const designPointer = ps.pointers.data.getDesignItem(stripHashIfExists(designQuery), pageId)
            structureWithDesign.design = shouldGenerateRepeatedData
                ? generateRepeatedData(ps, designQuery, pageId, designPointer, repeatedItemIds, getSerializedDesignItem)
                : getSerializedDesignItem(ps, designQuery, pageId, designPointer)
            if (flags.maintainIdentifiers && structureWithDesign.design) {
                structureWithDesign.design.id = stripHashIfExists(designQuery)
            }
            delete structureWithDesign.designQuery
        }
    }

    /**
     * @param ps
     * @param {string} itemId
     * @param flags
     * @param {string} pageId
     * @param itemType
     * @param [itemPointer]
     * @param [useOriginalLanguage]
     * @returns {*}
     */
    function serializeDataItem(ps, itemId, flags, pageId, itemType, itemPointer, useOriginalLanguage) {
        const {repeatedItemIds} = flags
        const nameSpaceSupportRepeaters = dataModel.doesItemTypeSupportsRepeatedItem(itemType)
        const shouldGenerateRepeatedData = nameSpaceSupportRepeaters && hasRepeatedItems(repeatedItemIds)
        const _itemPointer = itemPointer || ps.pointers.data.getItem(itemType, stripHashIfExists(itemId), pageId)
        const item = shouldGenerateRepeatedData
            ? generateRepeatedData(ps, itemId, pageId, _itemPointer, repeatedItemIds, (_ps, _query, _pageId, _pointer) =>
                  getSerializedItem(_ps, _query, _pageId, _pointer, itemType)
              )
            : getSerializedItem(ps, itemId, pageId, _itemPointer, itemType, useOriginalLanguage)
        if (flags.maintainIdentifiers && item) {
            item.id = stripHashIfExists(itemId)
        }
        return item
    }

    /**
     * @param {string[]} repeatedItemIds
     * @returns {boolean}
     */
    function hasRepeatedItems(repeatedItemIds) {
        return _.isArray(repeatedItemIds) && repeatedItemIds.length > 0
    }

    function getSerializedItem(ps, itemId, pageId, itemPointer, itemType, useOriginalLanguage) {
        const _itemPointer = itemPointer || ps.pointers.data.getItem(itemType, stripHashIfExists(itemId), pageId)
        return dataModel.serializeDataItem(ps, itemType, _itemPointer, true, useOriginalLanguage)
    }

    function serializeFeaturesOnStructure(ps, flags, structureWithFeatures, pageId) {
        _(featureNamespaces)
            .map(namespace => getNamespaceConfig(namespace))
            .reject({supportsVariants: true})
            .forEach(({query, namespace}) => {
                const featureId = structureWithFeatures[query]
                if (featureId && query) {
                    structureWithFeatures[namespace] = serializeDataItem(ps, featureId, flags, pageId, namespace)
                    delete structureWithFeatures[query]
                }
            })
    }
    function serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, dataType) {
        const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
        if (compStructure[compDataQueryKey]) {
            const serializedCompDataKey = SERIALIZED_DATA_KEYS[dataType]
            const currentDataId = stripHashIfExists(compStructure[compDataQueryKey])
            const dataPointer = ps.pointers.data.getItem(dataType, currentDataId, pageId)
            const removeIds = !maintainIdentifiers
            compStructure[serializedCompDataKey] = dataModel.serializeDataItem(ps, dataType, dataPointer, removeIds)
            if (maintainIdentifiers && compStructure[serializedCompDataKey]) {
                compStructure[serializedCompDataKey].id = currentDataId
            }
            delete compStructure[compDataQueryKey]
        }
    }

    function serializePropertiesOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.prop)
    }

    function serializeAnchorDataOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.anchors)
    }

    function serializeMobileHintsOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.mobileHints)
    }

    function serializeBehaviorOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.behaviors)
    }

    function serializeStatesOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.states)
    }

    function serializeTriggersOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.triggers)
    }

    function serializeConnectionDataOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        serializeDataOnStructureByType(ps, maintainIdentifiers, compStructure, pageId, DATA_TYPES.connections)
    }

    function serializeSingleStyle(style, flags) {
        if (isSystemStyle(style.id)) {
            return style.id
        }

        if (!flags.maintainIdentifiers) {
            return _.omit(style, 'id')
        }

        return style
    }

    const getLayoutObjectKey = layout => {
        switch (layout.type) {
            case 'FlexContainerLayout':
            case 'GridContainerLayout':
            case 'StackContainerLayout':
            case 'OrganizerContainerLayout':
                return 'containerLayout'
            case 'FlexItemLayout':
            case 'GridItemLayout':
            case 'StackItemLayout':
            case 'OrganizerItemLayout':
            case 'FixedItemLayout':
                return 'itemLayout'
            case 'ComponentLayout':
                return 'componentLayout'
            default:
                return 'componentLayout'
        }
    }

    function serializeSingleLayout(layout, flags, currentScopedValue) {
        let singleLayoutObject = _.merge(dataModel.createLayoutObject(), currentScopedValue)

        if (layout.type !== 'SingleLayoutData') {
            const layoutKey = getLayoutObjectKey(layout)
            singleLayoutObject.metaData = layout.metaData
            layout = _.omit(layout, 'id', 'metaData', 'breakpoint')
            singleLayoutObject[layoutKey] = layout
        } else {
            singleLayoutObject = layout
        }

        if (!flags.maintainIdentifiers) {
            return _.omit(singleLayoutObject, 'id')
        }

        return singleLayoutObject
    }

    function serializeSingleValue(ps, scopedValue, flags, dataType, pageId, currentScopedValue) {
        switch (dataType) {
            case DATA_TYPES.layout:
                return serializeSingleLayout(scopedValue, flags, currentScopedValue) //TODO: remove once oldLayoutItems are migrated to SingleLayoutData via autopilot or dataFIxer
            case DATA_TYPES.theme:
                return serializeSingleStyle(scopedValue, flags)
        }

        return serializeDataItem(ps, _.get(scopedValue, 'id'), flags, pageId, dataType)
    }
    function serializeVariantsOnStructure(ps, maintainIdentifiers, compStructure, pageId) {
        delete compStructure.breakpointVariantsQuery

        if (!compStructure.id) {
            return
        }
        const variantPointers = ps.pointers.data.getVariantDataItemsByComponentId(compStructure.id)
        const variantIds = _.keys(variantPointers)
        dataModel.serializeVariantsData(ps, compStructure, maintainIdentifiers, variantIds, pageId)
    }

    function serializeVariablesOnStructure(ps, flags, compStructure, pageId) {
        serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, DATA_TYPES.variables)
        if (_.get(compStructure, 'variables.id')) {
            delete compStructure.variables.id
        }
    }

    const shouldSerializeRelationalData = (ps, compStructure, pageId, dataType) =>
        dataType !== DATA_TYPES.design || shouldUseDesignOverVariants(ps, compStructure, pageId)

    function serializeAllRelationalDataOnStructure(ps, flags, compStructure, pageId) {
        _.forEach(_.keys(SERIALIZED_SCOPED_DATA_KEYS), dataType => {
            if (shouldSerializeRelationalData(ps, compStructure, pageId, dataType)) {
                //TODO: remove this line when dm_designOverVariants experiment is open
                serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, dataType)
            }
        })
    }

    function serializeRelationalDataOnStructureByType(ps, flags, compStructure, pageId, dataType) {
        const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
        if (!compStructure[compDataQueryKey]) {
            return
        }
        const itemId = dsUtils.stripHashIfExists(compStructure[compDataQueryKey])
        const itemPointer = ps.pointers.data.getItem(dataType, itemId, pageId)
        const serializeItem = dataModel.serializeDataItem(ps, dataType, itemPointer, false)
        const scopedDataKey = SERIALIZED_SCOPED_DATA_KEYS[dataType]
        const nonScopedDataKey = SERIALIZED_DATA_KEYS[dataType]
        if (serializeItem) {
            if (serializeItem.type === REF_ARRAY_DATA_TYPE) {
                serializeRefArray(ps, serializeItem, scopedDataKey, nonScopedDataKey, flags, compStructure, pageId, dataType)
            } else if (DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[dataType]) {
                serializeRefArrayRecursively(ps, serializeItem, flags, pageId, dataType)
                compStructure[nonScopedDataKey] = serializeItem
            } else {
                compStructure[nonScopedDataKey] = serializeSingleValue(ps, serializeItem, flags, dataType, pageId, compStructure[nonScopedDataKey])
            }
        }

        delete compStructure[compDataQueryKey]
    }

    function serializeRefArray(ps, serializeItem, scopedDataKey, nonScopedDataKey, flags, compStructure, pageId, dataType) {
        const values = dataModel.refArray.extractValues(ps, serializeItem)
        _.reduce(
            values,
            (structure, value) => {
                if (value.type === RELATION_DATA_TYPES.VARIANTS) {
                    structure[scopedDataKey] = structure[scopedDataKey] || {}
                    const variants = dataModel.variantRelation.extractVariants(ps, value)
                    const scopedValue = value.to
                    if (!_.isEmpty(variants)) {
                        const scopedKey = _.orderBy(variants).toString()
                        structure[scopedDataKey][scopedKey] = serializeSingleValue(
                            ps,
                            scopedValue,
                            flags,
                            dataType,
                            pageId,
                            structure[scopedDataKey][scopedKey]
                        )
                    }
                } else if (dataType === DATA_TYPES.theme || dataType === DATA_TYPES.layout) {
                    structure[scopedDataKey] = structure[scopedDataKey] || {}
                    structure[nonScopedDataKey] = serializeSingleValue(ps, value, flags, dataType, pageId, structure[nonScopedDataKey])
                } else {
                    structure[nonScopedDataKey] = serializeSingleValue(ps, value, flags, dataType, pageId, structure[nonScopedDataKey])
                }
                return structure
            },
            compStructure
        )
    }

    function serializeRefArrayRecursively(ps, serializeItem, flags, pageId, dataType) {
        _.keys(serializeItem).forEach(key => {
            if (_.get(serializeItem, [key, 'type']) === REF_ARRAY_DATA_TYPE) {
                const serializedRefArray = {}
                serializeRefArray(ps, serializeItem[key], 'scoped', 'default', flags, serializedRefArray, pageId, dataType)
                serializeItem[key] = serializedRefArray
                return
            }

            if (_.isObject(serializeItem[key])) {
                serializeRefArrayRecursively(ps, serializeItem[key], flags, pageId, dataType)
            }
        })
    }

    return {
        /**
         * Returns the Component as a Serialized ComponentDefinition Object.
         * @param {ps} ps
         * @param {Pointer} componentPointer - The component Reference to serialize the corresponding Component.
         * @param dataItemPointer
         * @param ignoreChildren
         * @param maintainIdentifiers
         * @param flatMobileStructuresMap
         * @returns a fully serialized   with its Data & Properties from the document. null is returned
         * in case no corresponding component is found.
         *
         *      @example
         *      const myPhotoReference = ...;
         *      ...
         *      const serializedComp = documentServices.components.serialize(myPhotoReference);
         */
        serializeComponent
    }
})
