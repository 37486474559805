define([
    'lodash',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/appStudio/constants',
    'documentServices/platform/common/constants',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors'
], function (_, appStudioDataModel, appStudioConstants, platformConstants, actionsAndBehaviors) {
    'use strict'

    const REF_COMP_TYPE = 'wysiwyg.viewer.components.RefComponent'
    const INTERNAL_REF_TYPE = 'InternalRef'
    const WIDGET_REF_DATA_TYPE = 'WidgetRef'

    const setComponentMobileStructure = (desktopComp, mobileComp) => {
        const mobileCompStructurePicks = _.pick(mobileComp, ['layout', 'props'])
        if (!_.isEmpty(mobileCompStructurePicks)) {
            desktopComp.mobileStructure = _.assign(desktopComp.mobileStructure, mobileCompStructurePicks)
            if (desktopComp.mobileStructure.props && !desktopComp.props) {
                desktopComp.props = _.pick(desktopComp.mobileStructure.props, ['id', 'metaData', 'type'])
            }
        }
    }

    const flattenCompTree = comp => _.union([comp], _.flatMap(comp.components, flattenCompTree))

    const getFlatComponentsStructuresMap = components => _(components).flatMap(flattenCompTree).union().keyBy('id').value()

    const setComponentsMobileStructureByMobileMap = (components, map) => {
        _.forEach(components, comp => {
            setComponentMobileStructure(comp, map[comp.id])
            setComponentsMobileStructureByMobileMap(comp.components, map)
        })
    }

    const setComponentsMobileStructureByMobileComponents = (desktopComps, mobileComps) => {
        setComponentsMobileStructureByMobileMap(desktopComps, getFlatComponentsStructuresMap(mobileComps))
    }

    const getDevCenterId = (ps, widgetPageId) => {
        const {pointer} = appStudioDataModel.findWidgetByPageId(ps, widgetPageId) || {}
        if (pointer) {
            return appStudioDataModel.getWidgetDevCenterId(ps, pointer)
        }
    }

    const hasVariations = (ps, widgetPageId) => {
        const {pointer} = appStudioDataModel.findWidgetByPageId(ps, widgetPageId) || {}
        const widgetData = appStudioDataModel.getData(ps, pointer) || {}
        return !_.isEmpty(widgetData.variations)
    }

    const fixAppDefIdInControllerOverrides = (refComponent, appDefinitionId) => {
        const overrides = _.get(refComponent, ['custom', 'overriddenData'])
        _.forEach(overrides, overrideItem => {
            if (overrideItem.dataItem.type === 'AppController' && overrideItem.dataItem.applicationId === appStudioConstants.APP_BUILDER_PREVIEW_APP_ID) {
                overrideItem.dataItem.applicationId = appDefinitionId
            }
        })
    }

    const resolveInnerRef = (ps, structure, appDefinitionId) => {
        if (structure.componentType === REF_COMP_TYPE && structure.data.type === INTERNAL_REF_TYPE) {
            const {data} = structure
            const devCenterWidgetId = getDevCenterId(ps, data.pageId)

            const widgetRefData = _.assign(
                {
                    type: WIDGET_REF_DATA_TYPE,
                    widgetId: devCenterWidgetId,
                    appDefinitionId
                },
                hasVariations(ps, data.pageId) ? {variationId: data.pageId} : null
            )

            structure.data = widgetRefData

            fixAppDefIdInControllerOverrides(structure, appDefinitionId)
        }
    }

    const resolveStructureRecursive = (ps, structure, appDefinitionId) => {
        removeStaticEventBehaviors(structure)
        removeWixCodeConnectionItems(structure)
        removeOriginalNicknameContext(structure)
        resolveInnerRef(ps, structure, appDefinitionId)

        _.forEach(structure.components, childStructure => resolveStructureRecursive(ps, childStructure, appDefinitionId))
    }

    const removeStaticEventBehaviors = structure => {
        const items = _.get(structure, ['behaviors', 'items'])
        if (items) {
            const filteredItems = _.reject(JSON.parse(items), ({behavior}) => actionsAndBehaviors.isCodeBehavior(behavior))
            structure.behaviors.items = JSON.stringify(filteredItems)
        }
    }

    const removeWixCodeConnectionItems = structure => {
        if (_.has(structure, 'connections')) {
            structure.connections.items = _.reject(structure.connections.items, {type: 'WixCodeConnectionItem'})
        }
    }

    const removeOriginalNicknameContext = structure => {
        _.unset(structure, ['custom', 'originalNicknameContext'])
    }

    const resetAppWidgetLayout = structure => {
        if (structure.layout) {
            structure.layout.x = 0
            structure.layout.y = 0
            _.unset(structure.layout, 'docked')
        }
    }

    const setAppWidgetData = (appWidgetStructure, {appDefinitionId = appWidgetStructure.data.applicationId, devCenterWidgetId, variationId}) => {
        const {data} = appWidgetStructure
        const appWidgetControllerType = _.get(data, 'controllerType', '')
        const appWidgetSettings = JSON.parse(_.get(data, 'settings', '{}'))
        const appWidgetUpdatedSettings = _.assign(appWidgetSettings, {devCenterWidgetId, variationPageId: variationId})

        _.assign(
            data,
            {
                controllerType: `${appDefinitionId}-${appWidgetControllerType}`,
                settings: JSON.stringify(appWidgetUpdatedSettings)
            },
            appDefinitionId ? {applicationId: appDefinitionId} : null
        )
    }

    const getAppWidget = comp => {
        if (!comp || comp.componentType === platformConstants.CONTROLLER_TYPES.APP_WIDGET) {
            return comp
        }
        return comp.components && getAppWidget(comp.components[0])
    }

    const getFixedAppWidgetStructure = (ps, widgetPageStructure, {appDefinitionId, devCenterWidgetId, variationId}) => {
        const appWidgetStructure = getAppWidget(widgetPageStructure.components[0]) || widgetPageStructure.components[0]
        if (appWidgetStructure) {
            setAppWidgetData(appWidgetStructure, {
                appDefinitionId,
                devCenterWidgetId,
                variationId
            })

            resetAppWidgetLayout(appWidgetStructure)
            resolveStructureRecursive(ps, widgetPageStructure, appDefinitionId)
            setComponentsMobileStructureByMobileComponents(widgetPageStructure.components, widgetPageStructure.mobileComponents)

            return appWidgetStructure
        }
    }

    return {
        getFixedAppWidgetStructure
    }
})
