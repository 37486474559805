import _ from 'lodash'
import experiment from 'experiment'
import layout from '../util/layout'
import imageLayout from './imageLayout'

const isBolt = () => typeof window === 'object' && _.get(window, 'wixBiSession.renderType') === 'bolt'
const isUsingWixImage = () => experiment.isOpen('bv_wixImagePhaseTwo') && isBolt()

if (!isUsingWixImage()) {
    layout.registerCustomLayoutFunction('wysiwyg.common.components.imagebutton.viewer.ImageButton', imageLayout.layoutChildNodeImages)
}
