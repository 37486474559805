import type {PublicMethodUtils, ViewerLibrary} from '../../publicAPI/apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const scrollAnchors = viewerLibrary.get('documentServices/scrollAnchors/scrollAnchors')

    return {
        initMethod: scrollAnchors.init,
        methods: {
            components: {
                anchor: {
                    get: publicMethodUtils.defineGetter(scrollAnchors.get),
                    update: publicMethodUtils.defineDataManipulationAction(scrollAnchors.update),
                    remove: publicMethodUtils.defineDataManipulationAction(scrollAnchors.remove)
                }
            },
            pages: {
                anchors: {
                    getPageAnchors: publicMethodUtils.defineGetter(scrollAnchors.getPageAnchors)
                }
            }
        }
    }
}
