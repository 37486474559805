define([], function () {
    function replaceLayout(definition, responsiveLayout) {
        if (definition.layout && responsiveLayout) {
            delete definition.layout
        }

        definition.responsiveLayout = responsiveLayout
    }

    return {
        replaceLayout
    }
})
