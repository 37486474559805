/**
 * Created by eitanr on 6/24/14.
 */
import _ from 'lodash'
import basic from './svgBasicScaler'
import parser from './svgPolygonParser'

export default {
    scale(polygonElement, scaleX, scaleY) {
        const parsedPolygonPoints = parser.getParsedPoints(polygonElement.getAttribute('points'))

        _.forEach(parsedPolygonPoints, function (polygonPoint) {
            polygonPoint[0] = basic.scaleSingleValue(polygonPoint[0], scaleX)
            polygonPoint[1] = basic.scaleSingleValue(polygonPoint[1], scaleY)
        })

        return !_.isEmpty(parsedPolygonPoints)
            ? {
                  points: parser.stringifyPoints(parsedPolygonPoints)
              }
            : {}

        //polygonElement.setAttribute('points', parser.stringifyPoints(parsedPolygonPoints));
    }
}
