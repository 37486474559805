define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataSerialization',
    'documentServices/dataModel/dataIds',
    'documentServices/dataModel/common',
    'documentServices/dataModel/remove',
    'documentServices/constants/constants',
    'documentServices/hooks/hooks',
    'documentServices/utils/utils',
    'documentServices/utils/multilingual',
    '@wix/document-services-json-schemas',
    'document-services-schemas',
    'documentServices/extensionsAPI/extensionsAPI',
    '@wix/wix-immutable-proxy'
], function (
    _,
    santaCoreUtils,
    dataSerialization,
    dataIds,
    common,
    remove,
    constants,
    hooks,
    dsUtils,
    mlUtils,
    jsonSchemas,
    documentServicesSchemas,
    extensionsAPI,
    wixImmutableProxy
) {
    'use strict'

    const {
        RELATION_DATA_TYPES: {VARIANTS, BREAKPOINTS},
        REF_ARRAY_DATA_TYPE,
        COMP_DATA_QUERY_KEYS_WITH_STYLE,
        DATA_TYPES,
        DATA_TYPES_VALUES_WITH_HASH
    } = constants

    const {dataValidators, schemasService} = documentServicesSchemas.services

    const DATA_TYPES_TO_UPDATE_ALL_COMPS = {
        Repeater: 'Repeater',
        VerticalAnchorsMenu: 'VerticalAnchorsMenu',
        Anchor: 'Anchor'
    }

    const PROPERTIES_TYPES_TO_UPDATE_ALL_COMPS = {
        StripColumnsContainerProperties: true,
        ColumnProperties: true
    }

    const COMPS_TO_UPDATE_ANCHORS_AFTER_PROPERTIES_CHANGE = {
        'wysiwyg.viewer.components.StripColumnsContainer'(ps, compPtr, newPropItem) {
            return !_.isUndefined(newPropItem.fullWidth) ? dsUtils.YES : dsUtils.NO
        },
        'wysiwyg.viewer.components.VectorImage': dsUtils.DONT_CARE
    }

    const {BASE_PROPS_SCHEMA_TYPE} = santaCoreUtils.constants
    const createDesignItemByType = (ps, schemaName) => {
        let itemWithDefaults = null
        if (schemasService.getSchema(DATA_TYPES.design, schemaName)) {
            itemWithDefaults = createItemAccordingToSchema(schemaName)
        }
        return itemWithDefaults
    }

    function addLink(privateServices, linkType, optionalLinkData, optionalPageId) {
        const linkDataItem = createDataItemByType(privateServices, linkType)
        if (linkDataItem) {
            _.assign(linkDataItem, optionalLinkData)
            const pageId = optionalPageId || 'masterPage'
            return addDataItem(privateServices, linkDataItem, pageId)
        }
        return null
    }

    /**
     * @typedef LayoutData
     * @property a
     */

    /**
     * Creates a Data Item corresponding a data type.
     * @param {ps} ps
     * @param {string} dataType a type of data to create corresponding data item.
     */
    function createDataItemByType(ps, dataType) {
        return documentServicesSchemas.services.createDataItemByType(dataType)
    }

    function createStyleItemByType(styleType) {
        return documentServicesSchemas.services.createStyleItemByType(styleType)
    }

    function createBehaviorsItem(behaviors) {
        return createItemAccordingToSchema('ObsoleteBehaviorsList', {items: behaviors})
    }

    function createConnectionsItem(connections) {
        return createItemAccordingToSchema('ConnectionList', {items: connections})
    }

    function createMobileHintsItem(mobileHints) {
        return createItemAccordingToSchema('MobileHints', mobileHints)
    }

    function createItemAccordingToSchema(schemaName, overrides) {
        let item = null
        if (schemaName) {
            item = {type: schemaName}
            dataValidators.resolveDefaultItem(schemaName, item)
        }
        return item ? _.assign(item, overrides) : item
    }

    /**
     * Creates a Properties Item according to a given type.
     * @param {ps} privateServices
     * @param propertiesType
     * @returns {*}
     */
    function createPropertiesItemByType(privateServices, propertiesType) {
        return documentServicesSchemas.services.createPropertiesItemByType(propertiesType)
    }

    function addDataItem(privateServices, dataItem, pageId) {
        if (privateServices && dataItem && pageId) {
            return dataSerialization.addSerializedDataItemToPage(privateServices, pageId, dataItem)
        }
        return null
    }

    function getComponentDataItemId(ps, componentPointer, propName) {
        if (!ps || !componentPointer) {
            return null
        }
        const dataQueryPointer = ps.pointers.getInnerPointer(componentPointer, propName)
        const dataQuery = ps.dal.get(dataQueryPointer) || ps.dal.full.get(dataQueryPointer)
        const id = dataQuery ? dsUtils.stripHashIfExists(dataQuery) : null
        return hooks.executeHookAndUpdateValue(ps, hooks.HOOKS.NS_ITEM.GET_QUERY_ID, undefined, [componentPointer, propName], id)
    }

    function linkComponentToItem(ps, componentPointer, dataItemId, itemQuery, isFull = true) {
        const dal = isFull ? ps.dal.full : ps.dal
        const compDataPointer = ps.pointers.getInnerPointer(componentPointer, itemQuery)
        dal.set(compDataPointer, dataItemId)
    }

    /**
     * links the component's item query to the given ID. It adds the '#' to the item Query, when the namespace type requires it.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {string} itemId
     * @param {string} itemType
     * @param {boolean} isFull - using ps.dal.full or ps.dal
     */
    function linkComponentToItemByType(ps, componentPointer, itemId, itemType, isFull = true) {
        const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${itemId}` : itemId
        return linkComponentToItem(ps, componentPointer, itemIdWithHashIfNeeded, COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType], isFull)
    }

    function linkComponentToDataItem(ps, componentPointer, dataItemId) {
        linkComponentToItemByType(ps, componentPointer, dataItemId, DATA_TYPES.data)
    }

    function linkComponentToMobileHintsItem(ps, componentPointer, mobileHintsItemId) {
        linkComponentToItemByType(ps, componentPointer, mobileHintsItemId, DATA_TYPES.mobileHints)
    }

    function linkComponentToBehaviorsItem(ps, componentPointer, dataItemId) {
        linkComponentToItemByType(ps, componentPointer, dataItemId, DATA_TYPES.behaviors)
    }

    function linkComponentToConnectionsItem(ps, componentPointer, dataItemId) {
        linkComponentToItemByType(ps, componentPointer, dataItemId, DATA_TYPES.connections)
    }

    function linkComponentToPropertiesItem(ps, componentPointer, propertyItemId) {
        linkComponentToItemByType(ps, componentPointer, propertyItemId, DATA_TYPES.prop, false)
    }

    function getItemOfComponent(privateServices, componentPointer, propName, getDataPointerFunction) {
        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(privateServices, componentPointer)
        const dataId = getComponentDataItemId(privateServices, actualComponentPointer, propName)
        if (!dataId) {
            return null
        }
        const pagePointer =
            privateServices.pointers.full.components.getPageOfComponent(actualComponentPointer) ||
            privateServices.pointers.components.getPageOfComponent(actualComponentPointer)
        const dataPointer = getDataPointerFunction(dataId, pagePointer.id)
        return dataPointer
    }

    function getDataItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'dataQuery', privateServices.pointers.data.getDataItem)
    }

    function getSlotsItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'slotsQuery', privateServices.pointers.data.getSlotsItem)
    }

    function getPropertyItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'propertyQuery', privateServices.pointers.data.getPropertyItem)
    }

    function getDesignItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'designQuery', privateServices.pointers.data.getDesignItem)
    }

    function getBehaviorsItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'behaviorQuery', privateServices.pointers.data.getBehaviorsItem)
    }

    function getConnectionsItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'connectionQuery', privateServices.pointers.data.getConnectionsItem)
    }

    function getMobileHintsItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'mobileHintsQuery', privateServices.pointers.data.getMobileHintsItem)
    }

    function getStyleItemPointer(privateServices, componentPointer) {
        return getItemOfComponent(privateServices, componentPointer, 'styleId', privateServices.pointers.data.getThemeItem)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {Pointer} componentPointer
     * @param {boolean} deleteId
     * @param {boolean} useOriginalLanguage
     */
    function getDataItem(privateServices, componentPointer, deleteId = false, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(privateServices, useOriginalLanguage)
        return getDataItemInLang(privateServices, componentPointer, deleteId, useLanguage)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {Pointer} componentPointer
     * @param {boolean} deleteId
     * @param {string|undefined} useLanguage
     */
    function getDataItemInLang(privateServices, componentPointer, deleteId = false, useLanguage = undefined) {
        const dataPointer = getDataItemPointer(privateServices, componentPointer)
        return getDataByPointerInLang(privateServices, DATA_TYPES.data, dataPointer, deleteId, useLanguage)
    }

    function getRuntimeDataItem(privateServices, componentPointer) {
        return privateServices.siteAPI.getRuntimeDal().getCompData(componentPointer.id)
    }

    function hasRuntimeChanges(privateServices, componentPointer) {
        return privateServices.siteAPI.getRuntimeDal().hasRuntimeChanges(componentPointer.id)
    }

    function getPropertiesItem(privateServices, componentPointer) {
        const dataPointer = getPropertyItemPointer(privateServices, componentPointer)
        return getDataByPointer(privateServices, DATA_TYPES.prop, dataPointer)
    }

    function getRuntimePropertiesItem(privateServices, componentPointer) {
        return privateServices.siteAPI.getRuntimeDal().getCompProps(componentPointer.id)
    }

    function doesItemTypeSupportsRepeatedItem(itemType) {
        return jsonSchemas.namespaceMapping.getNamespaceConfig(itemType).supportsRepeaterItem
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {string} itemType constants.DATA_TYPES
     * @returns {Pointer|null}
     */
    function getComponentDataPointerByType(ps, compPointer, itemType) {
        const itemQuery = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
        if (doesItemTypeSupportsRepeatedItem(itemType)) {
            return getItemOfComponent(ps, compPointer, itemQuery, (dataId, pageId) => ps.pointers.data.getItem(itemType, dataId, pageId))
        }
        const dataId = getComponentDataItemId(ps, compPointer, itemQuery)
        if (!dataId) {
            return null
        }
        const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)
        const pageId = pagePointer && pagePointer.id
        if (pageId) {
            return ps.pointers.data.getItem(itemType, dataId, pageId)
        }
        return null
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {string} itemType constants.DATA_TYPES
     * @param {boolean} [deleteIds]
     * @returns {*}
     */
    function getComponentDataItemByType(ps, compPointer, itemType, deleteIds = false) {
        const dataPointer = getComponentDataPointerByType(ps, compPointer, itemType)
        return dataPointer && getDataByPointer(ps, itemType, dataPointer, deleteIds)
    }

    function convertNewLayoutToOld(refArrayItem) {
        if (!refArrayItem || refArrayItem.type !== REF_ARRAY_DATA_TYPE) {
            return refArrayItem
        }

        const {componentLayouts, containerLayouts, itemLayouts} = refArrayItem.values.reduce(
            (acc, layoutItem) => {
                const breakpoint = layoutItem.type === VARIANTS ? layoutItem.variants[0] : layoutItem.breakpoint //TODO: change variants[0] to function that gets the variant of type breakpoint

                let atomicLayoutItem = layoutItem
                switch (layoutItem.type) {
                    case BREAKPOINTS:
                        atomicLayoutItem = layoutItem.ref
                        break
                    case VARIANTS:
                        atomicLayoutItem = layoutItem.to
                }

                let arrayToAddTo = null
                switch (atomicLayoutItem.type) {
                    case 'FlexContainerLayout':
                    case 'GridContainerLayout':
                    case 'StackContainerLayout':
                    case 'OrganizerContainerLayout':
                        arrayToAddTo = acc.containerLayouts
                        break
                    case 'FlexItemLayout':
                    case 'GridItemLayout':
                    case 'StackItemLayout':
                    case 'FixedItemLayout':
                    case 'OrganizerItemLayout':
                        arrayToAddTo = acc.itemLayouts
                        break
                    case 'ComponentLayout':
                        arrayToAddTo = acc.componentLayouts
                        break
                    case 'SingleLayoutData':
                        if (atomicLayoutItem.containerLayout.type) {
                            acc.containerLayouts.push({
                                ...atomicLayoutItem.containerLayout,
                                breakpoint
                            })
                        }
                        if (atomicLayoutItem.itemLayout.type) {
                            acc.itemLayouts.push({
                                ...atomicLayoutItem.itemLayout,
                                breakpoint
                            })
                        }
                        if (atomicLayoutItem.componentLayout.type) {
                            acc.componentLayouts.push({
                                ...atomicLayoutItem.componentLayout,
                                breakpoint
                            })
                        }
                        break
                }

                if (arrayToAddTo) {
                    arrayToAddTo.push({...atomicLayoutItem, breakpoint})
                }
                return acc
            },
            {componentLayouts: [], containerLayouts: [], itemLayouts: []}
        )
        return {
            type: 'LayoutData',
            componentLayouts,
            containerLayouts,
            itemLayouts,
            metaData: refArrayItem.metaData
        }
    }

    function getComponentAnchorData(ps, compPointer) {
        return getComponentDataItemByType(ps, compPointer, DATA_TYPES.anchors)
    }

    function createLayoutObject(compLayout = {}) {
        return _.merge({}, {componentLayout: {}, itemLayout: {}, containerLayout: {}, type: 'SingleLayoutData'}, compLayout)
    }

    /**
     * @typedef breakpointData
     * @property values
     */

    /**
     * get variants data by variant type according to variants data schemas
     * f.i variantType = 'hover' , options = {compPointer: 'compId'}, matches Hover required fields in variantsSchemas.js
     *
     * @param {ps} ps
     * @param {string} variantType
     * @param {Object} options  - {compPointer}
     * @returns {Pointer[]} component variants pointers array
     */
    function getVariantsDataByVariantType(ps, variantType, {compPointer}) {
        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const variantsDataByType = _.filter(ps.pointers.data.getVariantDataItemsByComponentId(compPointer.id), variant => variant.type === variantType)
        return _.map(variantsDataByType, variant => ps.pointers.data.getItem(DATA_TYPES.variants, variant.id, pageId))
    }

    /**
     * get all variants data related to a specific component
     *
     * @param {ps} ps
     * @param {Object} options  - {compPointer}
     * @returns {Pointer[]} component variants pointers array
     */
    function getComponentVariantsData(ps, {compPointer}) {
        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const variantsDataByType = ps.pointers.data.getVariantDataItemsByComponentId(compPointer.id)
        return _.map(variantsDataByType, variant => ps.pointers.data.getItem(DATA_TYPES.variants, variant.id, pageId))
    }

    /**
     * remove variants data by variant type according to variants data schemas
     * f.i variantType = 'hover' , options = {compPointer: 'compId'}, matches Hover required fields in variantsSchemas.js
     *
     *
     * @param {ps} ps
     * @param {string} variantType
     * @param {Object} options  - {compPointer}
     * @returns {*}
     */
    function removeVariantsDataByVariantType(ps, variantType, {compPointer}) {
        if (!ps || !compPointer) {
            throw new Error('invalid args')
        }
        const variantsPointers = getVariantsDataByVariantType(ps, variantType, {compPointer})
        _.forEach(variantsPointers, ps.dal.remove)
    }

    /**
     * get variant predicate by variantType according to variants data schemas
     * f.i variantType = 'Hover' , options = {compPointer: 'compId'}, matches Hover required fields in variantsSchemas.js
     *
     * @param {string} variantType
     * @param {Object} options  - {compPointer, values}
     * @returns {*} predicate by variant type
     */
    const getDefaultVariantDataByVariantType = (variantType, {compPointer, values}) => {
        switch (variantType) {
            case constants.VARIANTS.TYPES.MOBILE:
            case constants.VARIANTS.TYPES.HOVER:
            case constants.VARIANTS.TYPES.PRESET:
                return {componentId: compPointer.id, type: variantType}
            case constants.VARIANTS.TYPES.STATE:
                return {componentId: compPointer.id, type: variantType, name: ''}
            case constants.VARIANTS.TYPES.TRIGGER:
                return {componentId: compPointer.id, type: variantType, trigger: ''}
            case constants.VARIANTS.TYPES.BREAKPOINTS:
                return {componentId: compPointer.id, type: variantType, values: values || []}
        }
    }

    const serializeVariantsData = (ps, compStructure, maintainIdentifiers, variantIds, pageId) => {
        const variants = {}
        _.forEach(variantIds, variantId => {
            const variantPointer = ps.pointers.data.getItem(DATA_TYPES.variants, variantId, pageId)
            let variantValue = dataSerialization.serializeDataItem(ps, DATA_TYPES.variants, variantPointer)
            if (!maintainIdentifiers) {
                variantValue = _.omit(variantValue, 'id')
            }
            variants[variantId] = variantValue
        })
        if (!_.isEmpty(variants)) {
            _.merge(compStructure, {variants})
        }
    }

    /**
     * create variants data by variant type according to variants data schemas
     * f.i variantType = 'hover' , options = {compPointer: 'compId'}, matches Hover required fields in variantsSchemas.js
     *
     * @param {ps} ps
     * @param {Pointer} variantToAddRef
     * @param {string} variantType
     * @param {Object} options  - {compPointer, values}
     * @param {Object} variantData - Data to add to the variant on creation
     */
    function createVariantData(ps, variantToAddRef, variantType, {compPointer, values}, variantData) {
        const data = _.defaults(variantData, getDefaultVariantDataByVariantType(variantType, {compPointer, values}))
        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        return dataSerialization.addSerializedVariantItemToPage(ps, pageId, data, variantToAddRef.id, DATA_TYPES.variants)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {string} dataType
     * @param dataItemPointer
     * @param {boolean} [deleteId]
     * @param {boolean} [useOriginalLanguage]
     * @returns {*}
     */
    function getDataByPointer(privateServices, dataType, dataItemPointer, deleteId, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(privateServices, useOriginalLanguage)
        return getDataByPointerInLang(privateServices, dataType, dataItemPointer, deleteId, useLanguage)
    }

    /**
     * Removes translation while in multilingual and original otherwise
     * @param {ps} ps
     * @param dataItemPointer
     */
    const removeDataByPointer = (ps, dataItemPointer) => {
        if (mlUtils.isMultilingual(ps)) {
            const currentLang = ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
            const translationPtr = ps.pointers.multilingualTranslations.getTranslation(dataItemPointer, currentLang)
            ps.dal.remove(translationPtr)
        } else {
            ps.dal.remove(dataItemPointer)
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {string} dataType
     * @param {Pointer} dataItemPointer
     * @param {boolean} [deleteId]
     * @param {string|undefined} [useLanguage]
     * @returns {*}
     */
    function getDataByPointerInLang(ps, dataType, dataItemPointer, deleteId, useLanguage = undefined) {
        if (ps && dataItemPointer) {
            const dataItem = extensionsAPI.data.getFullNoClone(ps, dataItemPointer)
            const type = dataItem && dataItem.type
            if (type && schemasService.hasSchemaForDataType(dataType, type)) {
                const serializedDataItem = dataSerialization.serializeDataItemInLang(ps, dataType, dataItemPointer, deleteId, useLanguage)
                hooks.executeHook(hooks.HOOKS.DATA.AFTER_GET, type, [ps, serializedDataItem])
                return serializedDataItem
            }
        }
        return null
    }

    function getDataItemById(privateServices, dataItemId, pageId, deleteId = false, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(privateServices, useOriginalLanguage)
        return getDataItemByIdInLang(privateServices, dataItemId, pageId, deleteId, useLanguage)
    }

    function getDataItemByIdInLang(privateServices, dataItemId, pageId, deleteId = false, useLanguage = undefined) {
        if (privateServices && dataItemId) {
            const dataPointer = privateServices.pointers.data.getDataItem(dataItemId, pageId || 'masterPage')
            return getDataByPointerInLang(privateServices, DATA_TYPES.data, dataPointer, deleteId, useLanguage)
        }
        return null
    }

    function getPropertiesItemById(privateServices, dataItemId, pageId) {
        if (privateServices && dataItemId) {
            const propertiesPointer = privateServices.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(dataItemId), pageId || 'masterPage')
            return getDataByPointer(privateServices, DATA_TYPES.prop, propertiesPointer)
        }
        return null
    }

    function getMobileHintsItemById(privateServices, dataItemId, pageId) {
        if (!privateServices || !dataItemId) {
            return null
        }
        const mobileHintsPointer = privateServices.pointers.data.getMobileHintsItem(dataItemId, pageId || 'masterPage')
        return getDataByPointer(privateServices, DATA_TYPES.mobileHints, mobileHintsPointer)
    }

    const getFirstValidDataType = (ps, compPointer) => {
        const compType = dsUtils.getComponentType(ps, compPointer)
        const compDef = schemasService.getDefinition(compType)
        return (compDef.dataTypes || []).find(v => v !== '')
    }

    /**
     * @param {ps} ps
     * @param componentPointer
     * @param dataItem
     * @param useLanguage language code
     */
    function updateDataItemInLang(ps, componentPointer, dataItem, useLanguage) {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }

        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
        const compType = dsUtils.getComponentType(ps, actualComponentPointer)
        hooks.executeHook(hooks.HOOKS.DATA.UPDATE_BEFORE, compType, [ps, actualComponentPointer, dataItem])

        const pageId = ps.pointers.full.components.getPageOfComponent(actualComponentPointer).id
        let dataId = getComponentDataItemId(ps, actualComponentPointer, 'dataQuery')

        const doesComponentHaveData = Boolean(dataId)
        if (!doesComponentHaveData && !dataItem.type) {
            dataItem.type = getFirstValidDataType(ps, componentPointer)
        }

        dataId = dataSerialization.addSerializedDataItemToPage(ps, pageId, dataItem, dataId, useLanguage)

        if (!doesComponentHaveData) {
            linkComponentToDataItem(ps, actualComponentPointer, dataId)
        }
        hooks.executeHook(hooks.HOOKS.DATA.UPDATE_AFTER, compType, [ps, actualComponentPointer, dataItem])

        return dataId
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {any} dataItem
     * @param {boolean} useOriginalLanguage
     */
    function updateDataItem(ps, componentPointer, dataItem, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
        return updateDataItemInLang(ps, componentPointer, dataItem, useLanguage)
    }

    /**
     *
     * @param {ps} ps
     * @param {string|undefined} languageCode
     * @param {Pointer} componentPointer
     * @param {boolean} deleteId
     */
    function multilingualComponentsGet(ps, languageCode, componentPointer, deleteId = false) {
        const dataItemPointer = getDataItemPointer(ps, componentPointer)
        const page = ps.pointers.components.getPageOfComponent(componentPointer)
        const pageId = _.get(page, ['id'])
        const translationPointer = ps.pointers.multilingualTranslations.translationDataItem(pageId, languageCode, dataItemPointer.id)
        if (!ps.dal.isExist(translationPointer)) {
            return undefined
        }
        return getDataItemInLang(ps, componentPointer, deleteId, languageCode)
    }

    /**
     *
     * @param {ps} ps
     * @param {string|undefined} languageCode
     * @param {Pointer} componentPointer
     * @param {any} data
     */
    function multilingualComponentsUpdate(ps, languageCode, componentPointer, data) {
        return updateDataItemInLang(ps, componentPointer, data, languageCode)
    }

    function multilingualComponentsRemove(ps, languageCode, componentPointer) {
        return extensionsAPI.multilingualTranslations.removeByComponentRef(ps, componentPointer, languageCode)
    }

    function multilingualComponentsRemoveAll(ps, componentPointer) {
        return extensionsAPI.multilingualTranslations.removeByComponentRef(ps, componentPointer)
    }

    function multilingualHasTranslations(ps, languageCode) {
        return extensionsAPI.multilingualTranslations.hasTranslations(ps, languageCode)
    }

    function removeTranslations(ps, languageCode) {
        return extensionsAPI.multilingualTranslations.remove(ps, languageCode)
    }

    function removeAllTranslations(ps) {
        return extensionsAPI.multilingualTranslations.removeAll(ps)
    }

    function updateBehaviorsItem(ps, componentPointer, behaviorsItem) {
        let behaviorsId = getComponentDataItemId(ps, componentPointer, 'behaviorQuery')
        const pageId = ps.pointers.full.components.getPageOfComponent(componentPointer).id

        const doesComponentHaveBehaviorsData = Boolean(behaviorsId)
        const itemToUpdate = createBehaviorsItem(behaviorsItem)
        behaviorsId = dataSerialization.addSerializedBehaviorsItemToPage(ps, pageId, itemToUpdate, behaviorsId)

        if (!doesComponentHaveBehaviorsData) {
            linkComponentToBehaviorsItem(ps, componentPointer, behaviorsId)
        }
        const compType = dsUtils.getComponentType(ps, componentPointer)
        hooks.executeHook(hooks.HOOKS.BEHAVIORS.UPDATE_AFTER, compType, [ps])

        return behaviorsId
    }

    function updateConnectionsItem(ps, componentPointer, connectionsItem) {
        let connectionsId = getComponentDataItemId(ps, componentPointer, 'connectionQuery')
        const pageId = ps.pointers.full.components.getPageOfComponent(componentPointer).id

        const doesComponentHaveConnectionsData = Boolean(connectionsId)
        const newConnectionsItem = serializeConnectionsItem(ps, connectionsItem)
        const itemToUpdate = createConnectionsItem(newConnectionsItem)
        connectionsId = dataSerialization.addSerializedConnectionsItemToPage(ps, pageId, itemToUpdate, connectionsId)

        if (!doesComponentHaveConnectionsData) {
            linkComponentToConnectionsItem(ps, componentPointer, connectionsId)
        }
        hooks.executeHook(hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS, 'updateConnectionsItem', [ps, itemToUpdate, componentPointer])

        return connectionsId
    }

    function updateMobileHintsHidden(ps, componentPointer, mobileHintsItem) {
        return updateMobileHintsItem(ps, componentPointer, _.pick(mobileHintsItem, ['id', 'type', 'hidden']))
    }

    function updateMobileHintsItem(ps, componentPointer, mobileHintsItem) {
        let mobileHintsId = getComponentDataItemId(ps, componentPointer, 'mobileHintsQuery')
        const doesComponentHaveMobileHints = !!mobileHintsId
        const pageId = ps.pointers.full.components.getPageOfComponent(componentPointer).id
        const itemToUpdate = createMobileHintsItem(mobileHintsItem)
        mobileHintsId = dataSerialization.addSerializedMobileHintsItemToPage(ps, pageId, itemToUpdate, mobileHintsId, componentPointer)
        if (!doesComponentHaveMobileHints) {
            linkComponentToMobileHintsItem(ps, componentPointer, mobileHintsId)
        }
        return mobileHintsId
    }

    function updateComponentAnchorData(ps, compPointer, anchorData) {
        let compAnchorId = getComponentDataItemId(ps, compPointer, COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.anchors])
        const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
        const doesComponentHaveAnchorData = Boolean(compAnchorId)

        if (anchorData) {
            anchorData.type = 'AnchorInfo'
        }

        compAnchorId = dataSerialization.addSerializedAnchorDataItemToPage(ps, pageId, anchorData, compAnchorId)
        if (!doesComponentHaveAnchorData) {
            linkComponentToItemByType(ps, compPointer, compAnchorId, DATA_TYPES.anchors)
        }

        return compAnchorId
    }

    function serializeConnectionsItem(ps, connectionsItem) {
        return _.map(connectionsItem, function (connectionItem) {
            if (connectionItem.type === 'WixCodeConnectionItem') {
                return connectionItem
            }

            const controllerDataItemId = getComponentDataItemId(ps, connectionItem.controllerRef, 'dataQuery')
            const newConnectionItem = _.assign({}, _.omit(connectionItem, 'controllerRef'), {controllerId: controllerDataItemId})
            if (!_.has(newConnectionItem, 'config')) {
                return newConnectionItem
            }
            try {
                newConnectionItem.config = JSON.stringify(newConnectionItem.config)
            } catch (e) {
                throw new Error('Invalid connection configuration - should be JSON stringifiable')
            }
            return newConnectionItem
        })
    }

    function getConnectionsItem(ps, componentPointer) {
        const dataPointer = getConnectionsItemPointer(ps, componentPointer)

        if (!dataPointer) {
            return null
        }

        const pagePointer = santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(componentPointer.id)
            ? ps.pointers.components.getPageOfComponent(componentPointer)
            : ps.pointers.full.components.getPageOfComponent(componentPointer)

        return getConnectionsItemByPointer(ps, dataPointer, pagePointer)
    }

    function getConnectionsItemByPointer(ps, connectionPtr, pagePointer) {
        let connectionsItem
        const connectionsData = extensionsAPI.data.getFullNoClone(ps, connectionPtr)
        connectionsItem = wixImmutableProxy.deepClone(_.get(connectionsData, 'items'))
        connectionsItem = deserializeConnectionsItem(ps, pagePointer, connectionsItem)
        return _.isEmpty(connectionsItem) ? null : connectionsItem
    }

    function getMobileHintsItem(ps, componentPointer) {
        const dataPointer = getMobileHintsItemPointer(ps, componentPointer)
        return getDataByPointer(ps, DATA_TYPES.mobileHints, dataPointer)
    }

    function getControllerInPageByDataId(ps, pagePointer, controllerDataId) {
        const isMobileView = pagePointer.type === constants.VIEW_MODES.MOBILE
        return extensionsAPI.platform.getControllerInPageByDataId(ps, pagePointer, isMobileView, controllerDataId)
    }

    function getControllerRefFromMasterPage(ps, controllerDataId, pagePointer) {
        const viewMode = pagePointer.type
        const masterPagePointer = ps.pointers.components.getMasterPage(viewMode)
        return getControllerInPageByDataId(ps, masterPagePointer, controllerDataId)
    }

    function getControllerRefFromId(ps, controllerDataId, pagePointer) {
        const controller = getControllerInPageByDataId(ps, pagePointer, controllerDataId)
        if (controller) {
            return controller
        }

        if (ps.pointers.components.isMasterPage(pagePointer)) {
            return null
        }

        return getControllerRefFromMasterPage(ps, controllerDataId, pagePointer)
    }

    function deserializeConnectionsItem(ps, pagePointer, connections) {
        return _.map(connections, function (connectionItem) {
            if (connectionItem.type === 'WixCodeConnectionItem') {
                return connectionItem
            }

            const controllerRef = getControllerRefFromId(ps, connectionItem.controllerId, pagePointer)
            const newConnectionItem = _.assign({}, _.omit(connectionItem, 'controllerId'), {controllerRef})
            if (!_.has(newConnectionItem, 'config')) {
                return newConnectionItem
            }
            newConnectionItem.config = JSON.parse(newConnectionItem.config)
            return newConnectionItem
        })
    }

    function getBehaviorsItem(ps, componentPointer) {
        const dataPointer = getBehaviorsItemPointer(ps, componentPointer)
        return _.get(getDataByPointer(ps, DATA_TYPES.behaviors, dataPointer), 'items')
    }

    function setDataItemByPointer(ps, dataItemPointer, dataItem, schemaOrigin) {
        _.set(dataItem, ['metaData', 'isPreset'], false)
        common.addDefaultMetaData(dataItem)
        dataValidators.validateDataBySchema(dataItem, schemaOrigin)
        if (!dataItem.id) {
            dataItem.id = dataItemPointer.id
        }
        ps.dal.full.set(dataItemPointer, dataItem)
        hooks.executeHook(hooks.HOOKS.DATA.SET_BY_POINTER_AFTER, 'setDataItem', [ps, dataItemPointer, dataItem])
    }

    function updatePropertiesItem(ps, componentPointer, propertiesItem) {
        setPropertiesItem(ps, componentPointer, propertiesItem)
    }

    function setPropertiesItem(ps, componentPointer, propertiesItem, propertiesId) {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }
        const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
        let dataId = getComponentDataItemId(ps, actualComponentPointer, 'propertyQuery')
        const pageId = ps.pointers.full.components.getPageOfComponent(actualComponentPointer).id

        const compType = dsUtils.getComponentType(ps, actualComponentPointer)
        hooks.executeHook(hooks.HOOKS.PROPERTIES.UPDATE_BEFORE, compType, [ps, actualComponentPointer, propertiesItem])

        propertiesItem.type = propertiesItem.type || getPropertyTypeByCompType(compType)
        _.set(propertiesItem, ['metaData', 'autoGenerated'], false)

        const doesComponentHaveProp = Boolean(dataId)
        dataId = dataSerialization.addSerializedPropertyItemToPage(ps, pageId, propertiesItem, propertiesId || dataId, actualComponentPointer)

        if (!doesComponentHaveProp) {
            linkComponentToPropertiesItem(ps, actualComponentPointer, dataId)
        }

        hooks.executeHook(hooks.HOOKS.PROPERTIES.UPDATE_AFTER, compType, [ps, actualComponentPointer, propertiesItem])
        return dataId
    }

    function getPropertyTypeByCompType(compType) {
        const compDefinition = schemasService.getDefinition(compType)
        return compDefinition.propertyType || _.find(compDefinition.propertyTypes) || BASE_PROPS_SCHEMA_TYPE
    }

    function removeComponentDataItem(ps, componentPointer) {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }
        const compDefinition = schemasService.getDefinition(dsUtils.getComponentType(ps, componentPointer))

        if (compDefinition.dataTypes && !_.includes(compDefinition.dataTypes, '')) {
            throw new Error("component's data can't be deleted")
        }
        const dataItemPointer = ps.pointers.getInnerPointer(componentPointer, 'dataQuery')

        //do not handle deleting of data items themselves (e.g deleteDataItem),
        //can be risky and will be handled via garbage collection
        ps.dal.full.remove(dataItemPointer)
    }

    function removeComponentMobileHintsItem(ps, componentPointer) {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }
        const mobileHintsItemPointer = ps.pointers.getInnerPointer(componentPointer, 'mobileHintsQuery')
        ps.dal.full.remove(mobileHintsItemPointer)
    }

    const removeDataItem = itemQuery => (ps, componentPointer) => {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }
        const queryItemPointer = ps.pointers.getInnerPointer(componentPointer, itemQuery)
        ps.dal.full.remove(queryItemPointer)
    }

    const removeComponentAnchorData = removeDataItem('anchorQuery')

    function removeComponentPropertyItem(ps, componentPointer) {
        if (!ps || !componentPointer) {
            throw new Error('invalid args')
        }

        const compDefinition = schemasService.getDefinition(dsUtils.getComponentType(ps, componentPointer))
        const propertyTypes = compDefinition.propertyType ? [compDefinition.propertyType] : compDefinition.propertyTypes
        if (propertyTypes && !_.includes(propertyTypes, '')) {
            throw new Error("component's property can't be deleted")
        }
        const propertyQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'propertyQuery')
        ps.dal.remove(propertyQueryPointer)
    }

    function getPropertiesItemFields(propertiesItem) {
        if (propertiesItem) {
            return _.keys(propertiesItem)
        }
        return []
    }

    /***
     * @param {ps} privateServices
     * @param dataSchemaType
     * @returns {*}
     */
    function getDataSchemaByType(privateServices, dataSchemaType) {
        if (dataSchemaType) {
            const schema = schemasService.getSchema(DATA_TYPES.data, dataSchemaType)
            return schema && schema.properties
        }
        return null
    }

    /**
     * @param {ps} privateServices
     * @param propertiesSchemaType
     * @returns {*}
     */
    function getPropertiesSchemaByType(privateServices, propertiesSchemaType) {
        if (propertiesSchemaType) {
            const schema = schemasService.getSchema(DATA_TYPES.prop, propertiesSchemaType)
            return _.get(schema, ['allOf', 0], schema)
        }
        return null
    }

    function isDataItemValid(privateServices, dataItem, fieldName, fieldValue) {
        const deserializedDataItem = dataSerialization.deserializeDataItem(privateServices, dataItem, 'data')
        return dataValidators.isItemValid(deserializedDataItem, fieldName, fieldValue, 'data')
    }

    function isPropertiesItemValid(privateServices, propertiesItem, fieldName, fieldValue) {
        return dataValidators.isItemValid(propertiesItem, fieldName, fieldValue, 'properties')
    }

    function shouldUpdateDataWithSingleComp(privateServices, componentPointer) {
        const dataItem = getDataItem(privateServices, componentPointer)
        if (!dataItem) {
            return true
        }

        return !DATA_TYPES_TO_UPDATE_ALL_COMPS[dataItem.type]
    }

    function shouldUpdatePropertiesWithSingleComp(privateServices, componentPointer) {
        const propertiesItem = getPropertiesItem(privateServices, componentPointer)
        if (propertiesItem) {
            return !PROPERTIES_TYPES_TO_UPDATE_ALL_COMPS[propertiesItem.type]
        }

        return true
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPtr
     * @param methodArgs
     * @returns {string} dsUtils.NO|dsUtils.YES|dsUtils.DONT_CARE
     */
    function shouldUpdateAnchorsAfterPropertiesUpdate(ps, compPtr, methodArgs) {
        if (!ps.dal.isExist(compPtr)) {
            return dsUtils.YES
        }

        const compType = dsUtils.getComponentType(ps, compPtr)
        const shouldUpdateAnchors = COMPS_TO_UPDATE_ANCHORS_AFTER_PROPERTIES_CHANGE[compType]

        if (!shouldUpdateAnchors) {
            return dsUtils.NO
        }

        // @ts-ignore
        return _.isFunction(shouldUpdateAnchors) ? shouldUpdateAnchors.apply(this, methodArgs) : shouldUpdateAnchors
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPtr
     * @returns {string} dsUtils.NO|dsUtils.YES|dsUtils.DONT_CARE
     */
    function shouldUpdateAnchorsAfterRemove(ps, compPtr) {
        return santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPtr) ? dsUtils.NO : dsUtils.YES
    }

    function getConnections(ps, compRef) {
        const connections = getConnectionsItem(ps, compRef)
        return connections || []
    }

    function getPlatformAppConnections(ps, compRef) {
        return _.reject(getConnections(ps, compRef), {type: 'WixCodeConnectionItem'})
    }

    /**
     * returns true if current component data item is of type refArr
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {string} itemType
     * @returns {boolean}
     */
    const isComponentPointsToRefArray = (ps, componentPointer, itemType) => {
        const dataPointer = getComponentDataPointerByType(ps, componentPointer, itemType)
        let dataItem = dataPointer && extensionsAPI.data.getFullNoClone(ps, dataPointer)
        if (!dataItem || dataItem.type !== REF_ARRAY_DATA_TYPE) {
            return false
        }
        dataItem = dataSerialization.serializeDataItem(ps, itemType, dataPointer)

        //temp hack because responsiveStyles are using the same style APIs but working differently
        const hasVariantRelation = dataItem && (dataItem.values.length === 1 || _.some(dataItem.values, {type: VARIANTS}))
        return hasVariantRelation
    }

    /**
     * @param {ps} ps
     * @param {string} connectionQuery
     * @param {Pointer} pagePointer
     * @returns {*} all the connectionItem of the requested query
     */
    const getConnectionItemsByQuery = (ps, connectionQuery, pagePointer) => {
        const connectionPtr = ps.pointers.data.getConnectionsItem(connectionQuery, pagePointer.id)
        return getConnectionsItemByPointer(ps, connectionPtr, pagePointer)
    }

    const addSerializedStyleItemToPage = (ps, pageId = 'masterPage', dataItem, customId) =>
        dataSerialization.addSerializedStyleItemToPage(ps, pageId, dataItem, customId)

    return {
        doesItemTypeSupportsRepeatedItem,
        cleanLayoutAdditionalProps: dataSerialization.cleanLayoutAdditionalProps,
        shouldUpdateDataWithSingleComp,
        shouldUpdatePropertiesWithSingleComp,
        shouldUpdateAnchorsAfterPropertiesUpdate,
        shouldUpdateAnchorsAfterRemove,
        getPlatformAppConnections,
        /**
         * Creates a link and adds it to the data of the Master Page.
         * @param {string} linkType the type of the link to create.
         * @param {Object} optionalLinkData optional data to set upon creation.
         * @returns {Object} a reference to the Link Data Item.
         */
        addLink,
        /**
         * Creates a Data Item for a given type.
         *
         * @function
         * @memberof documentServices.dataModel
         *
         * @param {string} dataType the name of the Data Type to create a suiting instance.
         * @returns {Object} a DataItem corresponding the given <i>dataType</i>.
         */
        createDataItemByType,
        createStyleItemByType,
        createDesignItemByType,
        createBehaviorsItem,
        createMobileHintsItem,
        createItemAccordingToSchema,
        addDataItem,
        /**
         * Gets a DataItem instance corresponding a Component Reference from the document.
         *
         * @param {AbstractComponent} componentReference a reference of a component in the document.
         * @returns {Object} a Data Item corresponding the componentReference. 'null' if not found.
         */
        getDataItem,
        getRuntimeDataItem,
        hasRuntimeChanges,
        getDataItemById,
        getDataItemByIdInLang,
        getPropertiesItemById,
        getMobileHintsItemById,
        getDataItemPointer,
        getSlotsItemPointer,
        getPropertyItemPointer,
        getBehaviorsItemPointer,
        getConnectionsItemPointer,
        getMobileHintsItemPointer,
        getStyleItemPointer,
        getFirstValidDataType,
        getDesignItemPointer,
        /**
         * Merges the given data item to the component data item
         *
         * @param {AbstractComponent} componentRef A ComponentReference to match a corresponding Component.
         * @param {Object} dataItem A partial DataItem corresponding the type of the Component's Data to update.
         * @returns undefined
         *
         *      @example
         *      const myPhotoRef = ...;
         *      documentServices.components.data.update(myPhotoRef, {uri: "http://static.host.com/images/image-B.png"});
         */
        updateDataItem,
        updateDataItemInLang,
        /**
         * Deletes the reference from the component to the data item it's pointing at
         *
         * @param {AbstractComponent} componentRef A ComponentReference to match a corresponding Component.
         */
        removeComponentDataItem,
        /**
         * Deletes the reference from the component to the property item it's pointing at
         *
         * @param {AbstractComponent} componentRef A ComponentReference to match a corresponding Component.
         */
        removeComponentPropertyItem,

        removeComponentMobileHintsItem,

        setDataItemByPointer,
        deleteTransitionsItem: remove.deleteTransitionsItem,
        deleteTransformationsItem: remove.deleteTransformationsItem,
        deleteVariantsItems: remove.deleteVariantsItems,
        deleteReactionsItem: remove.deleteReactionsItem,
        deleteTriggersItem: remove.deleteTriggersItem,
        deleteStatesItem: remove.deleteStatesItem,
        deleteDataItem: remove.deleteDataItem,
        deleteDesignItem: remove.deleteDesignItem,
        deleteLayoutItem: remove.deleteLayoutItem,
        deleteFeatureItem: remove.deleteFeatureItem,
        deleteMobileHintsItem: remove.removeMobileHintsItem,

        /**
         * recursively removes from the dal data item and all items with same type it points to
         * @param Pointer
         */
        removeItemRecursivelyByType: remove.removeItemRecursivelyByType,
        /**
         * Returns a DataSchema (DataItem description object) given a type.
         *
         * @function
         * @memberof documentServices.dataModel
         *
         * @param {string} dataSchemaType a name of a DataSchema Type.
         * @returns {Object} a DataSchema instance corresponding the data schema type.
         *
         *      @example
         *      const imageDataSchema = documentServices.data.getSchema("Image");
         */
        getDataSchemaByType,

        /**
         * Creates a Properties (Data) Item for a given type.
         *
         * @function
         * @memberof documentServices.dataModel
         *
         * @param {string} propertiesType the name of the Properties (Data) Type to create a suiting instance.
         * @returns {Object} a Properties (Data) Item corresponding the <i>propertiesType</i>.
         */
        createPropertiesItemByType,
        /**
         * Gets a Properties(Data)Item instance corresponding a Component Reference from the document.
         *
         * @param {AbstractComponent} componentReference a reference of a component in the document.
         * @returns {Object} a Properties (Data)Item corresponding the componentReference. 'null' if not found.
         */
        getPropertiesItem,
        getRuntimePropertiesItem,
        getPropertiesItemFields,
        /**
         * Updates component's Properties (Data)Item.
         *
         * @function
         * @memberof documentServices.dataModel
         *
         * @param {Object} componentRef A ComponentReference to match a corresponding Component in the document.
         * @param {Object} propertiesItem A partial Properties (Data)Item corresponding the properties type of the
         * Component's Data to update.
         * @returns undefined
         *
         *
         *      @example
         *      const myPhotoRef = ...;
         *      documentServices.components.properties.update(myPhotoRef, {displayMode: "full"});
         */
        updatePropertiesItem,
        setPropertiesItem,
        deletePropertiesItem: remove.deletePropertiesItem,
        /**
         * Returns a PropertiesSchema (Properties Data Item description object) given a type.
         *
         * @function
         * @memberof documentServices.dataModel
         *
         * @param {string} propertiesSchemaType a name of a PropertiesSchema Type.
         * @returns {Object} a PropertiesSchema instance corresponding the <i>propertiesSchemaType</i>. 'undefined' otherwise.
         *
         *      @example
         *      const photoPropertiesSchema = documentServices.properties.getSchema("WPhotoProperties");
         */
        getPropertiesSchemaByType,

        updateBehaviorsItem,
        getBehaviorsItem,
        removeBehaviorsItem: remove.removeBehaviorsItem,

        updateConnectionsItem,
        getConnectionsItem,
        getConnectionsItemByPointer,
        getConnectionItemsByQuery,
        removeConnectionsItem: remove.removeConnectionsItem,

        updateMobileHintsItem,
        updateMobileHintsHidden,
        getMobileHintsItem,

        /*dataIds*/
        generateNewId: dataIds.generateNewId,
        generateNewDataItemId: dataIds.generateNewDataItemId,
        generateNewPropertiesItemId: dataIds.generateNewPropertiesItemId,
        generateNewDesignItemId: dataIds.generateNewDesignId,

        /*dataSerialization*/
        addDeserializedStyleItemToPage: dataSerialization.addDeserializedStyleItemToPage,
        addDeserializedItemToPage: dataSerialization.addDeserializedItemToPage,
        addSerializedStyleItemToPage,
        addSerializedDataItemToPage: dataSerialization.addSerializedDataItemToPage,
        addSerializedDesignItemToPage: dataSerialization.addSerializedDesignItemToPage,
        addSerializedBehaviorsItemToPage: dataSerialization.addSerializedBehaviorsItemToPage,
        addSerializedAnchorDataItemToPage: dataSerialization.addSerializedAnchorDataItemToPage,
        addSerializedBreakpointItemToPage: dataSerialization.addSerializedBreakpointItemToPage,
        addSerializedConnectionsItemToPage: dataSerialization.addSerializedConnectionsItemToPage,
        addSerializedPropertyItemToPage: dataSerialization.addSerializedPropertyItemToPage,
        addSerializedMobileHintsItemToPage: dataSerialization.addSerializedMobileHintsItemToPage,
        addSerializedVariantItemToPage: dataSerialization.addSerializedVariantItemToPage,
        addSerializedItemToPage: dataSerialization.addSerializedItemToPage,
        serializeDataItem: dataSerialization.serializeDataItem,

        /**
         * Executes callback for all refs in data item
         *
         * @param {object} schema - the actual schema to check
         * @param {string} dataItem
         * @param {function} callback
         */
        executeForDataItemRefs: common.executeForDataItemRefs,
        isOfType: common.isOfType,
        isPropertiesItemValid,
        isDataItemValid,
        getComponentDataItemId,
        createVariantData,
        getVariantsDataByVariantType,
        getComponentVariantsData,
        serializeVariantsData,
        removeVariantsDataByVariantType,
        convertNewLayoutToOld,
        getComponentDataPointerByType,
        linkComponentToItemByType,
        linkComponentToItem,
        removeComponentDataByType: remove.removeComponentDataByType,
        getDataByPointer,
        removeDataByPointer,
        isComponentPointsToRefArray,
        createLayoutObject,
        getComponentDataItemByType,
        refArray: extensionsAPI.data.refArray,
        breakpointRelation: extensionsAPI.data.breakpointRelation,
        variantRelation: extensionsAPI.data.variantRelation,

        // used in componentSerialization
        getComponentAnchorData,
        updateComponentAnchorData,
        getControllerRefFromId,
        removeComponentAnchorData,
        multilingual: {
            get: multilingualComponentsGet,
            update: multilingualComponentsUpdate,
            remove: removeTranslations,
            removeAll: removeAllTranslations,
            removeByComponent: multilingualComponentsRemove,
            hasTranslations: multilingualHasTranslations,
            removeAllByComponent: multilingualComponentsRemoveAll
        }
    }
})
