define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/component/component',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/utils/tpaUtils',
    'experiment'
], function (_, santaCoreUtils, component, componentDetectorAPI, clientSpecMapService, tpaUtils, experiment) {
    'use strict'

    const Intents = {
        TPA_MESSAGE: 'TPA',
        TPA_MESSAGE_PREVIEW: 'TPA_PREVIEW',
        TPA_MESSAGE2: 'TPA2',
        TPA_NATIVE_MESSAGE: 'TPA_NATIVE',
        TPA_RESPONSE: 'TPA_RESPONSE',
        TPA_PREVIEW_RESPONSE: 'TPA_PREVIEW_RESPONSE',
        PINGPONG_PREFIX: 'pingpong:'
    }

    const callPostMessage = function (source, data, targetOrigin) {
        let msgStr = ''
        try {
            msgStr = JSON.stringify(data)
        } catch (e) {
            return
        }

        if (!source.postMessage) {
            source = source.contentWindow
        }
        source.postMessage(msgStr, targetOrigin || '*')
    }

    const generateResponseFunction = function (source, msg, intent) {
        return function (data) {
            try {
                callPostMessage(source, {
                    intent: intent || Intents.TPA_RESPONSE,
                    callId: msg.callId,
                    type: msg.type,
                    res: data,
                    status: true
                })
            } catch (e) {
                // empty
            }
        }
    }

    const isTPAMessage = function isTPAMessage(msgIntent) {
        return msgIntent === Intents.TPA_MESSAGE || msgIntent === Intents.TPA_MESSAGE2 || msgIntent === Intents.TPA_NATIVE_MESSAGE
    }

    const isTPAPreviewMessage = function isTPAPreviewMessage(msgIntent) {
        return msgIntent === Intents.TPA_MESSAGE_PREVIEW
    }

    const isTPANativeMessage = function isTPANativeMessage(msgIntent) {
        return msgIntent === Intents.TPA_NATIVE_MESSAGE
    }

    const fixOldPingPongMessageType = function (msgType) {
        return msgType.replace(Intents.PINGPONG_PREFIX, '')
    }

    const parseMessage = function (event) {
        let msg
        try {
            if (event.data) {
                msg = JSON.parse(event.data)
            } else if (event.originalEvent && event.originalEvent.data) {
                event = event.originalEvent
                msg = JSON.parse(event.data)
            }
        } catch (e) {
            //linty linter linted something
        }
        return msg
    }

    const getWorkerUrl = (ps, workerCompId) => {
        const splits = workerCompId.split('_')
        const applicationId = splits[1]
        return clientSpecMapService.getAppWorkerUrl(ps, applicationId)
    }

    const getModalOrPopupUrl = (ps, modalOrPopupId) => {
        const modalPopupCompData = tpaUtils.getModalCompData(ps, modalOrPopupId) || tpaUtils.getPopupCompData(ps, modalOrPopupId)
        return _.get(modalPopupCompData, 'url')
    }

    // NOTE: tpaWidgetUrlOverride query param is considered as a source of truth for widgetUrl if defined
    const TPA_WIDGET_URL_OVERRIDE_PARAM = 'tpaWidgetUrlOverride'

    function getWidgetUrlOverridesMap(ps, paramName) {
        const tpaWidgetUrlOverrideString = ps.siteAPI.getQueryParam(paramName)
        return tpaWidgetUrlOverrideString ? santaCoreUtils.urlUtils.getOverridesMap(tpaWidgetUrlOverrideString) : null
    }

    const getWidgetOrSectionUrl = (ps, {appDefinitionId, widgetId}) => {
        const tpaWidgetUrlOverrideMap = getWidgetUrlOverridesMap(ps, TPA_WIDGET_URL_OVERRIDE_PARAM)
        if (tpaWidgetUrlOverrideMap && tpaWidgetUrlOverrideMap[widgetId]) {
            return tpaWidgetUrlOverrideMap[widgetId]
        }
        const widgets = _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId), 'widgets', {})
        return _.get(widgets, [widgetId, 'widgetUrl'], '')
    }

    const verifyOrigin = (ps, msg, eventOrigin) => {
        if (!experiment.isOpen('ds_tpaPostMessagesOriginValidation')) {
            return true
        }

        let originUrl = null

        if (msg && msg.compId) {
            const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
            const compData = compPointer && ps.dal.isExist(compPointer) && component.data.get(ps, compPointer)

            if (compData) {
                const tpaWidgetOrSection = tpaUtils.isTpaByDataType(compData.type)

                if (tpaWidgetOrSection && compData.widgetId) {
                    originUrl = getWidgetOrSectionUrl(ps, compData)
                }
            } else {
                const TPA_WORKER_ID_PREFIX = 'tpaWorker_'
                const isTPAWorkerId = id => id.indexOf(TPA_WORKER_ID_PREFIX) === 0

                if (isTPAWorkerId(msg.compId)) {
                    originUrl = getWorkerUrl(ps, msg.compId)
                } else {
                    originUrl = getModalOrPopupUrl(ps, msg.compId)
                }
            }
        }

        return _.startsWith(originUrl, eventOrigin)
    }

    const handleTPAMessage = function (ps, siteAPI, callHandler, event, tpaHandlersToExclude = {}) {
        const msg = parseMessage(event)
        if (msg && isTPAMessage(msg.intent) && !tpaHandlersToExclude[msg.type]) {
            const isVerifiedOrigin = verifyOrigin(ps, msg, event.origin)
            if (isVerifiedOrigin || isTPANativeMessage(msg.intent)) {
                callHandler(ps, msg, generateResponseFunction(event.source, msg), event)
            }
        } else if (msg && isTPAPreviewMessage(msg.intent)) {
            const isVerifiedOrigin = verifyOrigin(ps, msg, event.origin)
            if (isVerifiedOrigin) {
                callHandler(ps, msg, generateResponseFunction(event.source, msg, Intents.TPA_PREVIEW_RESPONSE), event)
            }
        }
    }

    return {
        Intents,
        callPostMessage,
        generateResponseFunction,
        isTPAMessage,
        fixOldPingPongMessageType,
        handleTPAMessage
    }
})
