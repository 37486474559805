import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {positionAndSize} = warmupUtils

function getInitialDimensionsMap(rootStructure) {
    const dimensionsMap = {}
    dimensionsMap[rootStructure.id] = _.pick(rootStructure.layout, ['x', 'y', 'width', 'height']) //TODO: what if root is docked? i.e page is docked
    return dimensionsMap
}

function addDimensionsRecursively(structure, dimensionsMap, clientSize, parentId, siteWidth) {
    const parentDimensions = dimensionsMap[parentId]

    const children = structure.components || structure.children
    _.forEach(children, function (comp) {
        // @ts-ignore
        dimensionsMap[comp.id] = positionAndSize.getPositionAndSize(comp.layout, parentDimensions, clientSize, siteWidth)
        addDimensionsRecursively(comp, dimensionsMap, clientSize, comp.id, siteWidth)
    })
}

function createDesktopDimensionsMap(structure, clientSize, siteWidth) {
    const dimensionsMap = getInitialDimensionsMap(structure)

    addDimensionsRecursively(structure, dimensionsMap, clientSize, structure.id, siteWidth)

    return dimensionsMap
}

function createMobileDimensionsMap(structure, clientSize, siteWidth) {
    const dimensionsMap = getInitialDimensionsMap(structure)
    const parentDimensions = dimensionsMap[structure.id]

    _.forEach(structure.mobileComponents, function (mobileComp) {
        // @ts-ignore
        dimensionsMap[mobileComp.id] = positionAndSize.getPositionAndSize(mobileComp.layout, parentDimensions, clientSize, siteWidth)
        addDimensionsRecursively(mobileComp, dimensionsMap, clientSize, mobileComp.id, siteWidth)
    })

    return dimensionsMap
}

function createDimensionsMap(structure, clientSize, siteWidth, isMobileView) {
    return isMobileView ? createMobileDimensionsMap(structure, clientSize, siteWidth) : createDesktopDimensionsMap(structure, clientSize, siteWidth)
}

export default {
    createDimensionsMap
}
