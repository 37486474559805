define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/hooks/hooks',
    '@wix/mobile-conversion',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/mobileConversion/modules/conversionSettings'
], function (_, dataModel, hooks, mobileCore, mergeAggregator, mobileConversionFacade, conversionSettings) {
    'use strict'

    function canSwitchDisplayMode(ps, desktopCompPointer) {
        const compModeDefinitions = ps.dal.full.get(ps.pointers.componentStructure.getModesDefinitions(desktopCompPointer))
        return _.size(compModeDefinitions) > 1
    }

    function removeComponent(components, predicate) {
        const removedChildren = _.remove(components, predicate)
        return !_.isEmpty(removedChildren) || _.some(components, child => removeComponent(child.components, predicate))
    }

    function setComponentDisplayMode(ps, compPointer, mobileDisplayedModeId) {
        const desktopCompPointer = ps.pointers.components.getDesktopPointer(compPointer)
        if (!canSwitchDisplayMode(ps, desktopCompPointer)) {
            return null
        }
        const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
        const pagePointer = ps.pointers.page.getPagePointer(pageId)
        dataModel.updatePropertiesItem(ps, desktopCompPointer, {mobileDisplayedModeId})
        const type = ps.dal.get(ps.pointers.getInnerPointer(pagePointer, 'componentType'))
        hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.BEFORE, type, [ps, pagePointer])
        const desktopPage = mergeAggregator.getPage(ps, pageId)
        const mobilePage = mobileCore.conversionUtils.extractMobilePage(desktopPage)
        removeComponent(mobileCore.conversionUtils.getChildren(mobilePage), {id: compPointer.id})
        const settings = conversionSettings.getConversionSettings({conversionType: 'MERGE_DISPLAY_MODE'})
        mobileConversionFacade.runMobileMergeOnPage(ps, desktopPage, mobilePage, settings)
    }

    return {setComponentDisplayMode}
})
