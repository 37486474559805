define([], function () {
    'use strict'

    const getComponentStylableName = compType => compType.split('.').pop()

    const STYLABLE_SKIN_NAME = 'wixui.skins.Skinless'
    const createEmptyStylableStylesheet = compType => {
        const compName = getComponentStylableName(compType)
        return `:import {
    -st-from: 'wix-ui-santa/index.st.css';
    -st-named: ${compName};
}
.root{
    -st-extends: ${compName};
}`
    }

    const getStylableSkinName = () => STYLABLE_SKIN_NAME

    const createEmptyStylableStyleItem = componentType => ({
        skin: getStylableSkinName(),
        style: {
            properties: {
                '$st-css': createEmptyStylableStylesheet(componentType)
            }
        }
    })

    return {
        createEmptyStylableStyleItem,
        createEmptyStylableStylesheet,
        getComponentStylableName,
        getStylableSkinName
    }
})
