define([
    'lodash',
    'documentServices/dataModel/common',
    'documentServices/utils/utils',
    'documentServices/hooks/hooks',
    'documentServices/constants/constants',
    'documentServices/dataModel/persistentItemsValidations',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter',
    '@wix/document-manager-utils'
], function (_, common, dsUtils, hooks, constants, persistentItemsValidations, extensionsAPI, contextAdapter, documentManagerUtils) {
    'use strict'

    const {ReportableError} = documentManagerUtils
    const {DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = constants
    const {isItemPersistent} = persistentItemsValidations

    function getComponentDataItemId(ps, componentPointer, dataQueryPointer) {
        const dal = ps.dal.isExist(dataQueryPointer) ? ps.dal : ps.dal.full
        const dataQuery = dal.get(dataQueryPointer)
        if (!dataQuery) {
            return null
        }
        return dsUtils.stripHashIfExists(dataQuery)
    }

    function getPageId(privateServices, componentPointer) {
        const pageOfComponent =
            privateServices.pointers.components.getPageOfComponent(componentPointer) ||
            privateServices.pointers.full.components.getPageOfComponent(componentPointer)
        return pageOfComponent.id
    }

    function removeItemRecursively(privateServices, dataType, dataItemPointer, pageId, forceRemoveRootItem) {
        if (!privateServices.dal.isExist(dataItemPointer)) {
            return
        }

        const dataItem = privateServices.dal.get(dataItemPointer)

        if (!dataItem || isItemPersistent(dataType, dataItemPointer.id)) {
            return
        }

        const isReferenced = extensionsAPI.relationships.isReferenced(privateServices, dataItemPointer)

        if (
            isReferenced &&
            !forceRemoveRootItem
            // is not original item that we want to remove
        ) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    errorType: 'attemptedRemoveOfReferencedDataError',
                    message: `${JSON.stringify(dataItemPointer)} was removed through it has references to it`,
                    extras: {
                        dataItemPointer
                    }
                })
            )
            return
        }

        const pointersToProcess = []

        common.executeForDataItemRefs(dataType, dataItem, function (dataId) {
            if (dataId) {
                const innerDataPointer = privateServices.pointers.data.getItem(dataType, dsUtils.stripHashIfExists(dataId), pageId)

                pointersToProcess.push(innerDataPointer)
            }
        })

        privateServices.dal.remove(dataItemPointer)

        _.forEach(pointersToProcess, pointer => {
            removeItemRecursively(privateServices, dataType, pointer, pageId, false)
        })
    }

    /**
     *
     * @param {ps} ps
     * @param dataPointer
     */
    function removeItemRecursivelyByType(ps, dataPointer) {
        if (!ps.dal.isExist(dataPointer)) {
            return
        }
        const pageId = ps.pointers.data.getPageIdOfData(dataPointer)
        removeItemRecursively(ps, dataPointer.type, dataPointer, pageId, true)
    }

    /**
     * delete recursively item by compPointer and item Type
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} itemType
     */
    function deleteItemByType(ps, componentPointer, itemType) {
        if (ps && componentPointer && itemType) {
            const propName = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
            const dataQueryPointer = ps.pointers.getInnerPointer(componentPointer, propName)
            const itemId = getComponentDataItemId(ps, componentPointer, dataQueryPointer)
            if (!itemId) {
                return null
            }
            const pageId = getPageId(ps, componentPointer)
            const itemPointer = ps.pointers.data.getItem(itemType, itemId, pageId)

            removeItemRecursivelyByType(ps, itemPointer)

            return itemId
        }
    }

    /**
     * delete item key from comp by compPointer and item Type
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} itemType
     */
    function deleteCompKeyByType(ps, componentPointer, itemType) {
        if (ps && componentPointer && itemType) {
            const propName = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
            const compDataQueryKeyPointer = ps.pointers.getInnerPointer(componentPointer, propName)
            if (ps.dal.full.isExist(compDataQueryKeyPointer)) {
                ps.dal.full.remove(compDataQueryKeyPointer)
            }
        }
    }

    function deleteCompKeyByTypeDesktopAndMobile(ps, componentPointer, itemType) {
        const desktopPointer = ps.pointers.full.components.getDesktopPointer(componentPointer)
        const mobilePointer = ps.pointers.full.components.getMobilePointer(componentPointer)
        deleteCompKeyByType(ps, desktopPointer, itemType)
        deleteCompKeyByType(ps, mobilePointer, itemType)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deletePropertiesItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.prop)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteTransformationsItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.transformations, true)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteTransitionsItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.transitions, true)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteReactionsItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.reactions, true)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteStatesItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.states, true)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteTriggersItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.triggers, true)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function deleteVariantsItems(ps, componentPointer) {
        if (ps && componentPointer) {
            const pageId = getPageId(ps, componentPointer)
            const componentVariantsData = ps.pointers.data.getVariantDataItemsByComponentId(componentPointer.id)
            _.forEach(componentVariantsData, variantData => {
                const variantPointer = ps.pointers.data.getItem(DATA_TYPES.variants, variantData.id, pageId)
                hooks.executeHook(hooks.HOOKS.VARIANTS.REMOVE_BEFORE, '', [ps, componentPointer, variantPointer])
                ps.dal.remove(variantPointer)
            })
        }
    }

    function deleteDesignItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.design)
    }

    function removeBehaviorsItem(ps, componentPointer) {
        const deletedItem = removeComponentDataByType(ps, componentPointer, DATA_TYPES.behaviors, true)
        if (!deletedItem) {
            return
        }

        const compType = dsUtils.getComponentType(ps, componentPointer)
        hooks.executeHook(hooks.HOOKS.BEHAVIORS.UPDATE_AFTER, compType, [ps])
    }

    function removeConnectionsItem(ps, componentPointer) {
        const deletedItem = deleteItemByType(ps, componentPointer, DATA_TYPES.connections)
        if (!deletedItem) {
            return
        }
        deleteCompKeyByType(ps, componentPointer, DATA_TYPES.connections)
    }

    function deleteDataItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.data)
    }

    function deleteLayoutItem(ps, componentPointer) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.layout)
    }

    function deleteFeatureItem(ps, componentPointer, featureNamespace) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES[featureNamespace])
    }

    function removeMobileHintsItem(ps, componentPointer, shouldRemoveQuery = false) {
        removeComponentDataByType(ps, componentPointer, DATA_TYPES.mobileHints, shouldRemoveQuery)
    }

    const removeComponentDataByType = (ps, componentPointer, itemType, shouldRemoveQuery = false) => {
        const deletedItem = deleteItemByType(ps, componentPointer, itemType)
        if (!deletedItem) {
            return
        }

        if (shouldRemoveQuery) {
            deleteCompKeyByTypeDesktopAndMobile(ps, componentPointer, itemType)
        }
        return deletedItem
    }

    return {
        removeComponentDataByType,
        removeItemRecursivelyByType,
        removeConnectionsItem,
        removeBehaviorsItem,
        removeMobileHintsItem,
        deleteDesignItem,
        deletePropertiesItem,
        deleteDataItem,
        deleteTransitionsItem,
        deleteTransformationsItem,
        deleteVariantsItems,
        deleteLayoutItem,
        deleteFeatureItem,
        deleteReactionsItem,
        deleteStatesItem,
        deleteTriggersItem
    }
})
