import _ from 'lodash'

function getOpenDirection(viewPortHeight, menuPosY) {
    const viewPortMiddle = Math.floor(viewPortHeight / 2)
    return viewPortMiddle > menuPosY ? 'down' : 'up'
}

function patchVerticalMenuDesktop(measureMap, id, patchers) {
    const custom = measureMap.custom[id]
    const menuPosY = measureMap.absoluteTop[id] - custom.pageYOffset
    const openDirection = `subMenuOpenDir-${getOpenDirection(custom.screenHeight, menuPosY)}`

    patchers.attr(id, {'data-open-direction': openDirection})
}

/**
 *
 * @param id
 * @param patchers
 * @param measureMap
 * @param structureInfo
 * @param {core.SiteData} siteData
 */
function patchVerticalMenu(id, patchers, measureMap, structureInfo, siteData) {
    const listId = `${id}menuContainer`

    if (!measureMap.height[listId] || !measureMap.width[listId]) {
        return
    }

    if (siteData.isMobileView()) {
        patchers.css(listId, {
            height: measureMap.height[listId],
            width: measureMap.width[listId]
        })
    } else {
        patchVerticalMenuDesktop(measureMap, id, patchers)
    }
}

function measureVerticalMenuDesktop(win, id, measureMap, nodesMap) {
    const listId = `${id}menuContainer`

    if (!nodesMap[listId]) {
        return
    }

    measureMap.custom[id] = {
        screenHeight: measureMap.height.screen,
        pageYOffset: win.pageYOffset
    }
    measureMap.width[id] = nodesMap[listId].offsetWidth
    measureMap.height[id] = nodesMap[listId].offsetHeight
}

/**
 * @param {Window} win
 * @param id
 * @param measureMap
 * @param nodesMap
 * @param structureInfo
 * @param isMobileView
 */
function measureVerticalMenuCustom(win, id, measureMap, nodesMap, structureInfo, {isMobileView}) {
    if (isMobileView()) {
        measureMap.height[`${id}menuContainer`] = measureMap.height[id]
        measureMap.width[`${id}menuContainer`] = measureMap.width[id]
    } else {
        measureVerticalMenuDesktop(win, id, measureMap, nodesMap)
    }
}

// In ssr the `window` object will not be available and simpler ways to check for its existence,
// such as `_.isUndefined`, will fail.
const isInSsr = typeof window !== 'object'
const measureWithDefaultWindow = isInSsr ? _.noop : _.partial(measureVerticalMenuCustom, window)

function register(layoutUtils) {
    layoutUtils.registerRequestToMeasureChildren('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', [['menuContainer']])
    layoutUtils.registerCustomMeasure('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', measureWithDefaultWindow)
    layoutUtils.registerPatcher('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', patchVerticalMenu)
}

export default {
    register,
    measure: measureVerticalMenuCustom,
    patch: patchVerticalMenu
}
