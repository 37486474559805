define([
    'lodash',
    'documentServices/editorServerFacade/editorServerFacade',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/bi/bi',
    'documentServices/bi/errors'
], function (_, editorServerFacade, siteMetadata, bi, biErrors) {
    'use strict'

    function getPasswordProtectedPages(ps) {
        return siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES) || []
    }

    function getSessionPages(ps) {
        return siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD) || {}
    }

    function setSessionPages(ps, data) {
        return siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD, data)
    }

    function updatePasswordProtectedPages(ps, pageId, hasPassword) {
        const currentProtectedPages = getPasswordProtectedPages(ps)
        const nextProtectedPages = hasPassword ? _.union(currentProtectedPages, [pageId]) : _.difference(currentProtectedPages, [pageId])
        siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES, nextProtectedPages)
    }

    function isPageProtected(ps, pageId) {
        const passwordProtectedPages = getPasswordProtectedPages(ps)
        return _.includes(passwordProtectedPages, pageId)
    }

    function setPageToNoRestriction(ps, pageId) {
        setPagePassword(ps, pageId, {value: null})
    }

    function setPagePassword(ps, pageId, originalPassword) {
        const password = _.isObject(originalPassword) ? originalPassword.value : originalPassword
        if (_.isNull(password)) {
            return _syncSiteMetadata(ps, pageId, null)
        }

        const wasPageProtectedBeforeAjaxRequest = isPageProtected(ps, pageId)
        updatePasswordProtectedPages(ps, pageId, true)

        const payload = {
            siteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID),
            metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
            pageId,
            password
        }
        sendRequest(ps, editorServerFacade.ENDPOINTS.PASSWORD_PROTECTION, pageId, wasPageProtectedBeforeAjaxRequest, payload)
    }

    function updatePasswordProtectedMaps(ps, sessionPages, pageId, sourcePageId) {
        sessionPages[pageId] = sessionPages[sourcePageId]
        setSessionPages(ps, sessionPages)
        const currentProtectedPages = getPasswordProtectedPages(ps)
        const nextProtectedPages = _.union(currentProtectedPages, [pageId])
        siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.PASSWORD_PROTECTED_PAGES, nextProtectedPages)
    }

    function duplicatePagePassword(ps, pageId, sourcePageId, successCallback, errorCallback) {
        if (!isPageProtected(ps, sourcePageId)) {
            if (errorCallback) {
                errorCallback()
            }
            return
        }
        const sessionPages = getSessionPages(ps)
        if (_.has(sessionPages, sourcePageId)) {
            updatePasswordProtectedMaps(ps, sessionPages, pageId, sourcePageId)
            if (successCallback) {
                successCallback()
            }
            return
        }
        const wasPageProtectedBeforeAjaxRequest = isPageProtected(ps, pageId)
        updatePasswordProtectedPages(ps, pageId, true)
        const payload = {
            siteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID),
            metaSiteId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID),
            pageId,
            sourcePageId
        }
        sendRequest(
            ps,
            editorServerFacade.ENDPOINTS.PASSWORD_PROTECTION_DUPLICATE,
            pageId,
            wasPageProtectedBeforeAjaxRequest,
            payload,
            successCallback,
            errorCallback
        )
    }

    function sendRequest(ps, url, pageId, wasPageProtectedBeforeAjaxRequest, payload, successCallback, errorCallback) {
        editorServerFacade.sendWithPs(
            ps,
            url,
            payload,
            function success(data) {
                if (data.result) {
                    _syncSiteMetadata(ps, pageId, data.result)
                    if (successCallback) {
                        successCallback()
                    }
                } else {
                    _handleError(ps, pageId, wasPageProtectedBeforeAjaxRequest, data.errorCode, data.errorDescription, errorCallback)
                }
            },
            function error(jqXHR, textStatus) {
                _handleError(ps, pageId, wasPageProtectedBeforeAjaxRequest, -1, textStatus, errorCallback)
            }
        )
    }

    function _syncSiteMetadata(ps, pageId, passwordToSet) {
        const sessionPages = getSessionPages(ps)
        sessionPages[pageId] = passwordToSet
        siteMetadata.setProperty(ps, siteMetadata.PROPERTY_NAMES.SESSION_PAGES_TO_HASH_PASSWORD, sessionPages)
        updatePasswordProtectedPages(ps, pageId, passwordToSet)
    }

    function _handleError(ps, pageId, wasPageProtectedBeforeAjaxRequest, errorCode, errorDescription, errorCallback) {
        _rollback(ps, pageId, wasPageProtectedBeforeAjaxRequest)
        bi.error(ps, biErrors.PASSWORD_PROTECTION_HASH_GENERATION_FAILED, {
            serverErrorCode: errorCode,
            errorDescription
        })
        if (errorCallback) {
            errorCallback()
        }
        throw new Error(`Remote error, cannot update password for page with id ${pageId}: ${errorDescription}. Error code: ${errorCode}`)
    }

    function _rollback(ps, pageId, wasPageProtectedBeforeAjaxRequest) {
        updatePasswordProtectedPages(ps, pageId, wasPageProtectedBeforeAjaxRequest)
    }

    function isPagesProtectionOnServerOn() {
        return true
    }

    return {
        getPasswordProtectedPages,
        isPageProtected,
        setPagePassword,
        duplicatePagePassword,
        setPageToNoRestriction,
        isPagesProtectionOnServerOn
    }
})
