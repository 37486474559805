/**
 * Created by eitanr on 6/25/14.
 */
import _ from 'lodash'
import basic from './svgBasicScaler'

function scaleAttribute(attributeName, originalValue, scale) {
    return originalValue ? _.set({}, attributeName, basic.scaleSingleValue(originalValue, scale)) : null
}

export default {
    scale(rectElement, scaleX, scaleY) {
        const width = rectElement.getAttribute('width')
        const height = rectElement.getAttribute('height')
        const rx = rectElement.getAttribute('rx')
        const ry = rectElement.getAttribute('ry')
        const x = rectElement.getAttribute('x')
        const y = rectElement.getAttribute('y')

        return _.assign(
            {},
            scaleAttribute('width', width, scaleX),
            scaleAttribute('height', height, scaleY),
            scaleAttribute('rx', rx, scaleX),
            scaleAttribute('ry', ry, scaleY),
            scaleAttribute('x', x, scaleX),
            scaleAttribute('y', y, scaleY)
        )
    }
}
