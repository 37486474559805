define(['lodash'], function (_) {
    'use strict'

    /*eslint no-unused-vars:0*/

    const FEEDBACK_SERVER = 'http://wixfeedbackserver.appspot.com'
    const FEEDBACK_LOCAL_SERVER = 'http://localhost:8888'

    const FEEDBACK_SERVER_MARK_AS_SHARED = '/markSiteShared'
    const FEEDBACK_SERVER_MARK_AS_READ = '/markAsRead'
    const FEEDBACK_SERVER_LOAD_COMMENTS = '/list'
    const FEEDBACK_SERVER_APPEND = '/append'
    const FEEDBACK_SERVER_DELETE = '/delete'
    const BITLY_SERVER = 'https://api-ssl.bitly.com/v3/shorten?access_token=6e283dc921f56df2eff5951bd2bf5bab041d8ac2&longUrl='
    const CAMPAIGN_PARAMS = '&utm_campaign=vir_wixad_feedback'

    const COMMENT_MOODS = ['happy', 'sad', 'noMood']

    let _useMockedData = false
    let _useLocalServer = false

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    /**
     * generates a short url for a dedicated feedback site.
     * call wix server to create a token for shared site, generate a dedicated url,
     * run it through bitly service and return the shortened url.
     * If no editorLanguage is given - uses 'en' by default.
     *
     * @param {ps} privateServices
     * @param {string} [editorLanguage=en] editor language (default = en)
     * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
     */
    function getSharedSiteURL(privateServices, editorLanguage, callbacks) {
        // EDITOR ONLY! (so... what should we do with it? deprecated?)
        const siteId = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteId'])
        const metaSiteId = privateServices.dal.getByPath(['rendererModel', 'metaSiteId'])

        editorLanguage = editorLanguage || 'en'

        __createPreviewSite(siteId, metaSiteId, {
            onSuccess(response) {
                const previewSiteURL = response.payload && response.payload.url
                const paramsPrefix = _.includes(previewSiteURL, '?') ? '&' : '?'
                const feedbackSiteURL = `${previewSiteURL + paramsPrefix}feedback=true&lang=${editorLanguage}${CAMPAIGN_PARAMS}`
                __createBitlyAddress(feedbackSiteURL, {
                    onSuccess(res) {
                        if (hasHandler(callbacks, 'onSuccess')) {
                            callbacks.onSuccess(res.data.url)
                        }
                    },
                    onError() {
                        // TODO: handle errors
                    }
                })
            },
            onError() {
                // TODO: handle errors
            }
        })
    }

    function __createPreviewSite(siteId, metaSiteId, callbacks) {
        const origin = `//${window.location.host}` // editor.wix.com
        const previewTokenUrl = `http:${origin}/html/editor/web/review/createToken/${siteId}?metaSiteId=${metaSiteId}`
        __sendCORSRequest('GET', previewTokenUrl, null, {
            onSuccess(response) {
                const obj = JSON.parse(response)
                if (hasHandler(callbacks, 'onSuccess')) {
                    callbacks.onSuccess(obj)
                }
            },
            onError() {
                // TODO: handle errors
            }
        })
    }

    function __createBitlyAddress(previewSiteURL, callbacks) {
        const bitlyRequestURL = BITLY_SERVER + encodeURIComponent(previewSiteURL)
        __sendCORSRequest('GET', bitlyRequestURL, null, {
            onSuccess(response) {
                const obj = JSON.parse(response)
                if (hasHandler(callbacks, 'onSuccess')) {
                    callbacks.onSuccess(obj)
                }
            },
            onError() {
                // TODO: handle errors
            }
        })
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function loadSiteComments(privateServices, callbacks) {
        const siteId = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteId'])
        const requestURL = `${__getFeedbackServer() + FEEDBACK_SERVER_LOAD_COMMENTS}?siteId=${siteId}`
        __sendCORSRequest('GET', requestURL, null, {
            onSuccess(response) {
                const commentsByPage = __parseServerComment(JSON.parse(response))
                if (hasHandler(callbacks, 'onSuccess')) {
                    callbacks.onSuccess(commentsByPage)
                }
            },
            onError() {
                // TODO: handle errors
            }
        })
    }

    function __parseServerComment(comments) {
        _.forEach(comments, function (comment) {
            comment.commentId = comment.key.id
            comment.x = comment.propertyMap.x.value
            comment.y = comment.propertyMap.y.value
            comment.face = comment.propertyMap.face.value
            comment.submitter = comment.propertyMap.submitter.value
            comment.text = comment.propertyMap.text.value.value
            comment.pageId = comment.propertyMap.pageId
            comment.unread = comment.propertyMap.unread
            comment.time = new Date(comment.propertyMap.time)
        })
        const commentsByPage = _.groupBy(comments, 'pageId')
        return commentsByPage
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function saveSiteFeedback(privateServices, commentsDataArray, generalComment, submitter, callbacks) {
        const siteId = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteId'])
        const metaSiteId = privateServices.dal.getByPath(['rendererModel', 'metaSiteId'])
        const userId = privateServices.dal.getByPath(['rendererModel', 'userId'])
        const siteName = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteTitleSEO'])

        const dataString = JSON.stringify({
            siteId,
            data: commentsDataArray,
            generalComment,
            submitter,
            userGuid: userId,
            metaSiteId,
            siteName
        })

        __sendCORSRequest('POST', __getFeedbackServer() + FEEDBACK_SERVER_APPEND, dataString, callbacks)
    }

    function createCommentData(privateServices, pageId, x, y, text, submitter, face) {
        /** @class feedbackComment */
        const comment = {
            pageId: pageId || 'mainPage',
            x: x || '100px',
            y: y || '100px',
            text: text || 'untitled',
            submitter: submitter || 'anonymous',
            time: Date.now(),
            face: face || COMMENT_MOODS[COMMENT_MOODS.length - 1],
            unread: true
        }
        return comment
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function isSiteShared(privateServices, callbacks) {
        const siteId = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteId'])
        const requestURL = `${__getFeedbackServer() + FEEDBACK_SERVER_MARK_AS_SHARED}?siteId=${siteId}`
        __sendCORSRequest('GET', requestURL, {}, callbacks)
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function markSiteAsShared(privateServices, callbacks) {
        const siteId = privateServices.dal.getByPath(['rendererModel', 'siteInfo', 'siteId'])
        const requestURL = __getFeedbackServer() + FEEDBACK_SERVER_MARK_AS_SHARED
        __sendCORSRequest('POST', requestURL, JSON.stringify({siteId}), callbacks)
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function markCommentAsRead(privateServices, commentId, callbacks) {
        const requestURL = __getFeedbackServer() + FEEDBACK_SERVER_MARK_AS_READ
        __sendCORSRequest('POST', requestURL, JSON.stringify({commentId}), callbacks)
    }

    // ======================----------------------------------------------------------------------
    // ======================----------------------------------------------------------------------

    function deleteComment(privateServices, commentId, callbacks) {
        const requestURL = __getFeedbackServer() + FEEDBACK_SERVER_DELETE
        __sendCORSRequest('POST', requestURL, JSON.stringify({commentId}), callbacks)
    }

    // ==============================================================================================
    // UTILS ========================================================================================
    // ==============================================================================================

    function __getFeedbackServer() {
        return _useLocalServer ? FEEDBACK_LOCAL_SERVER : FEEDBACK_SERVER
    }

    /**
     * @param {string} method
     * @param {string} url
     * @param data
     * @param callbacksObj
     * @private
     */
    function __sendCORSRequest(method, url, data, callbacksObj) {
        if (_useMockedData) {
            // removing 'this' because WTF ???
            if (hasHandler(callbacksObj, 'onSuccess')) {
                callbacksObj.onSuccess('MOCKED')
            }
            return
        }

        const {XDomainRequest} = window
        /** @type {XMLHttpRequest|null} */
        let xhr = new window.XMLHttpRequest()
        if ('withCredentials' in xhr) {
            xhr.open(method, url, true)
        } else if (typeof XDomainRequest !== 'undefined') {
            // handle IE
            xhr = new XDomainRequest()
            // @ts-ignore
            xhr.open(method, url)
        } else {
            xhr = null
        }

        if (!xhr) {
            return
        }

        if (method === 'POST') {
            xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
        }

        xhr.onload = function () {
            if (hasHandler(callbacksObj, 'onSuccess')) {
                // @ts-ignore
                callbacksObj.onSuccess(xhr.responseText)
            }
        }

        xhr.onerror = function () {
            const errResponse = {error: true}
            if (hasHandler(callbacksObj, 'onError')) {
                callbacksObj.onError(errResponse)
            }
        }

        xhr.send(data || null)
    }

    function hasHandler(callbacks, eventName) {
        return _.isFunction(_.get(callbacks, eventName))
    }

    // ==============================================================================================
    // MOCKED =======================================================================================
    // ==============================================================================================

    /**
     * for tests only!
     *
     * @param {ps} privateServices
     */
    function enableMockedData(privateServices) {
        _useMockedData = true
    }

    /**
     * for tests only!
     *
     * @param {ps} privateServices
     */
    function disableMockedData(privateServices) {
        _useMockedData = false
    }

    /**
     * for tests only!
     *
     * @param {ps} privateServices
     */
    function enableLocalTestServer(privateServices) {
        _useLocalServer = true
    }

    /**
     * for tests only!
     *
     * @param {ps} privateServices
     */
    function disableLocalTestServer(privateServices) {
        _useLocalServer = false
    }

    /**
     * @param {ps} privateServices
     * @param {string} submitter
     * @returns {{submitter: string, face: string, unread: boolean, x: number, y: number, text: string, time: number, pageId: string}}
     */
    function getUnreadMockedComment(privateServices, submitter) {
        const comment = {
            pageId: 'mainPage',
            // @ts-ignore
            x: parseInt(Math.random() * 500, 10),
            // @ts-ignore
            y: parseInt(Math.random() * 500, 10),
            text: `title [${Date.now()}]`,
            submitter: submitter || 'anonymous',
            time: Date.now(),
            // @ts-ignore
            face: COMMENT_MOODS[parseInt(Math.random() * COMMENT_MOODS.length, 10)],
            unread: true
        }
        return comment
    }

    const testApi = {
        // public for tests!
        enableMockedData,
        disableMockedData,
        enableLocalTestServer,
        disableLocalTestServer,
        getUnreadMockedComment,
        __sendCORSRequest
    }
    /**
     * @class documentServices.feedback
     */
    return _.merge(
        {
            /**
             * generates a short url for a dedicated feedback site.
             * call wix server to create a token for shared site, generate a dedicated url,
             * run it through bitly service and return the shortened url.
             * If no editorLanguage is given - uses 'en' by default.
             *
             * @param {string} [editorLanguage=en] editor language (default = en)
             * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
             */
            generateShareURL: getSharedSiteURL,
            /**
             * check if site is shared.
             *
             * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
             */
            isSiteShared,
            /**
             * mark site as shared.
             *
             * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
             */
            markSiteAsShared,
            /**
             * @class documentServices.feedback.comments
             */
            comments: {
                /**
                 * @param pageId
                 * @param x
                 * @param y
                 * @param text
                 * @param submitter
                 * @param face
                 * @returns {{pageId: (*|string), x: (*|string), y: (*|string), text: (*|string), submitter: (*|string), time: number, face: (*|string), unread: boolean}}
                 */
                create: createCommentData,
                /**
                 * save new feedback for a specific site.
                 *
                 * @param {[feedbackComment]} commentsDataArray array of comments (u can use createCommentData to fill this array)
                 * @param {string} [generalComment] general description for group of comments
                 * @param {string} [submitter] the submitter name
                 * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
                 */
                add: saveSiteFeedback,
                /**
                 * load all comments for a specific site.
                 *
                 * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
                 */
                get: loadSiteComments,
                /**
                 * delete comment by id.
                 *
                 * @param {string} commentId comment identifier for deletion
                 * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
                 */
                remove: deleteComment,
                /**
                 * mark comment as read.
                 *
                 * @param {string} commentId comment identifier for deletion
                 * @param {Object} [callbacks] callbacks pattern: {onSuccess:function, onError:function}
                 */
                markAsRead: markCommentAsRead
            }
        },
        testApi
    )
})
