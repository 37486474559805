define(['lodash', 'documentServices/constants/constants', 'documentServices/componentsMetaData/components/inlinePopupToggleMetaData'], function (
    _,
    constants,
    inlinePopupToggleMetaData
) {
    'use strict'

    function isContainable(ps, comp, containerPointer) {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const menuContainer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, masterPagePointer)
        return containerPointer && !ps.pointers.isSamePointer(containerPointer, menuContainer)
    }

    return _.defaultsDeep(
        {
            containable: isContainable,
            mobileOnly: true,
            duplicatable: false,
            styleCanBeApplied: true
        },
        inlinePopupToggleMetaData
    )
})
