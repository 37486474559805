define(['lodash', '@wix/santa-core-utils', 'documentServices/tpa/services/appMarketService', 'documentServices/tpa/bi/errors'], function (
    _,
    santaCoreUtils,
    appMarketService,
    tpaErrors
) {
    'use strict'

    const cacheAppMarketDataAfterProvision = function (ps, appData) {
        appMarketService.getAppMarketDataAsync(ps, appData.appDefinitionId).catch(function () {
            ps.siteAPI.reportBI(tpaErrors.FAIL_TO_GET_APP_MARKET_DATA)
        })
    }

    const generateAppFlowsLargestAppId = function (currentLargest) {
        // eslint-disable-next-line no-mixed-operators
        const randomNumber = (santaCoreUtils.guidUtils.random() * 999 + 1 + santaCoreUtils.guidUtils.random() * 99 + 1) | 0
        return _.max([currentLargest, 999]) + randomNumber
    }

    return {
        cacheAppMarketDataAfterProvision,
        generateAppFlowsLargestAppId
    }
})
