import DALFactory from '../../dal/DALFactory'

function init(siteData) {
    const displayedJsonDal = DALFactory.getInstance(siteData)

    function register({widgetId, component, error}) {
        displayedJsonDal.setByPath(['nativeComponents', widgetId], {
            component,
            error
        })
    }

    function isRegistered(widgetId) {
        return !!displayedJsonDal.getByPath(['nativeComponents', widgetId])
    }

    function getComponent(widgetId) {
        const widgetData = displayedJsonDal.getByPath(['nativeComponents', widgetId])
        return widgetData && widgetData.component
    }

    return {
        register,
        getComponent,
        isRegistered
    }
}

export default {
    init
}
