import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import _ from 'lodash'
import fieldBoxProxyUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/fieldBoxProxyUtils'

/**
 * @class proxies.fieldBox
 * @extends proxies.mixins.baseCompositeProxy
 * @property {proxy.properties} props
 */
export default {
    mixins: [wixapps.baseCompositeProxy],

    renderProxy() {
        const {orientation} = this.props
        const fieldBoxDef = fieldBoxProxyUtils.getFieldBoxDef(this.getCompProp, orientation, this.props.viewDef)

        const childViewDef = _.cloneDeep(this.props.viewDef)
        childViewDef.comp.items = fieldBoxDef.comp.items
        _.merge(childViewDef, fieldBoxDef)

        return this.renderChildProxy(childViewDef)
    }
}
