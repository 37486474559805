define(['@wix/santa-ds-libs/src/coreUtils', 'documentServices/component/componentStructureInfo', 'experiment'], function (
    coreUtils,
    componentStructureInfo,
    experiment
) {
    'use strict'

    return function fixPopupContainerLayout(ps, compPointer, newLayout) {
        const layout = {
            width: newLayout.width,
            height: newLayout.height
        }
        const popupProps = componentStructureInfo.getPropertiesItem(ps, compPointer)
        const roundIfNeeded = value => (experiment.isOpen('dm_roundPopupContainerLayout') ? Math.round(value) : value)
        layout.x = roundIfNeeded(coreUtils.compAlignmentUtils.getLeft(popupProps, layout.width, ps.siteAPI.getScreenWidth(), ps.siteAPI.getSiteWidth()))
        if (popupProps.alignmentType === 'fullWidth') {
            layout.x = Math.max(layout.x, 0)
        }
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        layout.y = roundIfNeeded(coreUtils.compAlignmentUtils.getTop(popupProps, layout.height, measureMap.innerHeight.screen))

        return layout
    }
})
