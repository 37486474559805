define(['lodash', 'documentServices/utils/contextAdapter', '@wix/document-manager-utils'], function (_, contextAdapter, documentManagerUtils) {
    'use strict'

    const {ReportableError} = documentManagerUtils

    /**
     *
     * @param {ps} ps
     * @param {boolean} useOriginal
     * @returns {string} languageCode of current or original language
     */
    function getLanguageByUseOriginal(ps, useOriginal) {
        return useOriginal
            ? _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')
            : ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
    }

    function getNonTranslatablePointer(ps, pointer) {
        const originalLanguage = getLanguageByUseOriginal(ps, true)
        return {...pointer, useLanguage: originalLanguage}
    }

    const isMultilingual = ps => {
        const currentLang = ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
        if (!currentLang) {
            return false
        }

        const originalLang = ps.dal.get(ps.pointers.multilingual.originalLanguage())?.languageCode
        if (!originalLang) {
            const error = new ReportableError({
                errorType: 'originalLanguageUndefined',
                message: 'Original language is undefined'
            })
            contextAdapter.utils.fedopsLogger.captureError(error)
            throw error
        }

        return currentLang !== originalLang
    }

    return {
        getLanguageByUseOriginal,
        getNonTranslatablePointer,
        isMultilingual
    }
})
