const pointerType = 'activeModes'
import {getPointer, getInnerPointer} from './pointerUtils'
import type {Pointer} from '../types'

const createPointersMethods = () => {
    const getPageActiveModes = (pageId: string) => getPointer('activeModes', pointerType, [pageId])
    const getAllActiveModes = () => getPointer('activeModes', pointerType)

    const getModes = (pointer: Pointer) => getInnerPointer(pointer, ['modes'])
    const getModesDefinitions = (pointer: Pointer) => getInnerPointer(pointer, ['modes', 'definitions'])
    const getModesOverrides = (pointer: Pointer) => getInnerPointer(pointer, ['modes', 'overrides'])

    return {
        activeModes: {
            getPageActiveModes,
            getAllActiveModes
        },
        general: {
            getActiveModes: getAllActiveModes
        },
        componentStructure: {
            getModes,
            getModesDefinitions,
            getModesOverrides
        }
    }
}

export {createPointersMethods}
