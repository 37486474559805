define(['lodash', '@wix/santa-ds-libs/src/coreUtils', 'experiment', '@wix/santa-core-utils'], function (_, coreUtils, experiment, santaCoreUtils) {
    'use strict'

    const isZepto = xhr => _.get(xhr, 'getResponseHeader')
    const isFetchResponse = xhr => !!_.get(xhr, ['headers', 'get'])

    const levels = {
        INFO: 'info',
        WARN: 'warn',
        ERROR: 'error'
    }
    const requestIdRegex = /\[request-id:\s(\d+\.\d+)\]/

    const sessionTimeoutCodes = [-12, -15, -16]

    const missingMessageOnErrorText = 'Message was not specified although level is error. See stack_trace'

    const DEV_QUERY_PARAMS = ['ReactSource', 'EditorSource', 'experiments', 'petri_ovr', 'WixCodeRuntimeSource', 'debug', 'debugViewer']

    const BATCH_MAX_SIZE = 10
    const BATCH_MAX_TIME = 2000 // milliseconds

    function createQueue(timeout, maxBatchSize, onBatchReady) {
        const q = {
            data: []
        }

        function addToQueue(logData) {
            clearTimeout(q.timeout)

            // @ts-ignore
            q.data.push(logData)

            if (q.data.length >= maxBatchSize) {
                _drainQueue()
            } else {
                q.timeout = setTimeout(() => {
                    _drainQueue()
                }, timeout)
            }
        }

        function _drainQueue() {
            const batchData = q.data
            q.data = []
            onBatchReady(batchData)
        }

        return addToQueue
    }

    const onBatchReady = logs => _sendBatchLogsRequest(logs)
    const logQueued = createQueue(BATCH_MAX_TIME, BATCH_MAX_SIZE, onBatchReady)
    let batchEndpointUrl

    function _sendBatchLogsRequest(logs) {
        return _sendRequest(batchEndpointUrl, logs) //RETRIES
    }

    function _sendSingleLogRequest(baseUrl, data) {
        const url = `${baseUrl}/logstash/events`
        return _sendRequest(url, data)
    }

    function _sendRequest(url, data) {
        const request = {
            type: 'POST',
            url,
            dataType: 'text',
            contentType: 'application/json',
            data: JSON.stringify(data),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        }

        return new Promise(function (resolve, reject) {
            request.success = resolve
            request.error = function (xhr, errorType, error) {
                reject({
                    xhr,
                    errorType,
                    error
                })
            }

            coreUtils.ajaxLibrary.ajax(request)
        })
    }

    function hasDevQueryParameterInUrl(href) {
        const url = santaCoreUtils.urlUtils.parseUrl(href)
        return Object.keys(url.query).some(key => _.includes(DEV_QUERY_PARAMS, key))
    }

    function shouldReportToKibana() {
        try {
            const href = _.get(window.parent, 'location.href')
            const url = santaCoreUtils.urlUtils.parseUrl(href)

            const isWixCodeForceKibanaReport = Object.keys(url.query).some(key => key === 'wixCodeForceKibanaReport')
            const isDevMode = !!href && (isLocalhost(href) || hasDevQueryParameterInUrl(href))

            return isWixCodeForceKibanaReport || !isDevMode
        } catch (e) {
            return true
        }
    }

    function _reportKibana(params, baseUrl, attemptNumber) {
        if (typeof window === 'undefined') {
            return
        }

        if (!shouldReportToKibana()) {
            return
        }

        if (
            window.isMockWindow &&
            // @ts-ignore
            typeof global !== 'undefined' &&
            // @ts-ignore
            !global.jasmine.isSpy(coreUtils.ajaxLibrary.ajax)
        ) {
            return
        }

        if (!attemptNumber) {
            attemptNumber = 1
        }

        const transformedParams = _transform(params)

        if (experiment.isOpen('se_wixCodeBatchLogs')) {
            batchEndpointUrl = `${baseUrl}/logstash/batch/events`
            logQueued(transformedParams)
            return
        }

        _sendSingleLogRequest(baseUrl, transformedParams).catch(function () {
            if (attemptNumber < 3) {
                _reportKibana(params, baseUrl, attemptNumber + 1)
            }
        })
    }

    function isLocalhost(href) {
        return _.includes(href, '://localhost')
    }

    function _extractErrorInfo(params) {
        if (!_.isError(params.message)) {
            return params
        }

        const error = params.message
        const newParams = {}
        if (error.stack) {
            newParams.stackTrace = error.stack
            newParams.message = error.message
        } else {
            newParams.message = error.toString()
        }

        return _.merge({}, params, newParams)
    }

    function isRequestAbortError(message) {
        return _.isError(message) && message.name === 'RequestAbortError'
    }

    function isRequestTimeoutError(message) {
        return _.isError(message) && message.name === 'RequestTimeoutError'
    }

    function _isServerError(message) {
        return _.isError(message) && !!message.xhr && (isZepto(message.xhr) || isFetchResponse(message.xhr))
    }

    function _addHeaders(params) {
        if (!_isServerError(params.message)) {
            return params
        }

        const headers = _.assign(
            {
                x_seen_by: isZepto(params.message.xhr) ? params.message.xhr.getResponseHeader('x-seen-by') : params.message.xhr.headers.get('x-seen-by')
            },
            _.mapKeys(_.get(params, 'message.request.headers'), (v, k) => _.snakeCase(k))
        )

        return _.merge({}, params, {params: headers})
    }

    function _addRequestId(params) {
        if (!_isServerError(params.message)) {
            return params
        }
        const {responseText} = params.message.xhr
        const parsedResponse = parseResponse(responseText)
        const requestId = _getRequestId(parsedResponse.errorDescription)

        return requestId ? _.merge({}, params, {requestId}) : params

        function _getRequestId(errorDescription) {
            const matches = requestIdRegex.exec(errorDescription)
            if (matches && matches[1]) {
                return matches[1]
            }
        }
    }

    function _addErrorCode(params) {
        if (!_isServerError(params.message)) {
            return params
        }

        const {errorCode} = parseResponse(params.message.xhr.responseText)

        return !_.isNil(errorCode) ? _.merge({}, params, {errorCode: errorCode.toString()}) : params
    }

    function isSessionTimeout(message) {
        if (!_isServerError(message)) {
            return false
        }

        const {errorCode} = parseResponse(message.xhr.responseText)

        return errorCode && _.includes(sessionTimeoutCodes, errorCode)
    }

    function isWixCodeNotWriteableError(message) {
        return _.isError(message) && message.name === 'WixCodeNotWriteableError'
    }

    function _shouldSetToWarn(message) {
        return isRequestAbortError(message) || isRequestTimeoutError(message) || isSessionTimeout(message) || isWixCodeNotWriteableError(message)
    }

    function _setLevelToWarnIfNeeded(params) {
        return _shouldSetToWarn(params.message) ? _.merge({}, params, {level: levels.WARN}) : params
    }

    function parseResponse(maybeResponseText) {
        if (!maybeResponseText) {
            return {}
        }

        try {
            return JSON.parse(maybeResponseText)
        } catch (e) {
            return {}
        }
    }

    function _handleMissingMessageOnErrors(params) {
        if (params.message || params.level !== levels.ERROR) {
            return params
        }

        return _.merge({}, params, {message: new Error(missingMessageOnErrorText)})
    }

    function _stringifyMessage(params) {
        return !_.isString(params.message) ? _.merge({}, params, {message: JSON.stringify(params.message)}) : params
    }

    function _addUrl(params) {
        return _.merge({}, params, {params: {url: _.get(window, 'location.href', '')}})
    }

    function _addOpenGridAppExperiment(params) {
        return _.merge({}, params, {
            params: {
                'specs.WixCodeOpenCodeAppIdEnabled': experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')
            }
        })
    }

    function _transform(params) {
        return _.flow(
            _addHeaders,
            _addRequestId,
            _addErrorCode,
            _setLevelToWarnIfNeeded,
            _handleMissingMessageOnErrors,
            _extractErrorInfo,
            _stringifyMessage,
            _addUrl,
            _addOpenGridAppExperiment
        )(params)
    }

    function _getDefaultParams() {
        return {
            source: 'wix-code-client',
            level: levels.INFO,
            userActionId: santaCoreUtils.guidUtils.getGUID()
        }
    }

    function _validateBaseUrl(baseUrl) {
        if (!_.isString(baseUrl) || baseUrl.length === 0) {
            throw new Error(`parameter \`baseUrl\` is invalid, received: ${baseUrl}`)
        }
    }

    /**
     * Sends tracing information to Kibana.
     * The default parameters that are sent are:
     *  source='wix-code-client'
     *  level='info'
     *  actionPosition='start'
     *  timestamp (ms from 1/1/1970)
     *  userActionId (GUID string)
     *
     * @param params Can be overriding the default parameters or the following:
     *  action (string)
     *  appId (string)
     *  userId (string)
     *  message (string|Error)
     * @param baseUrl The base url for the monitoring hub endpoint
     * @returns {Function} A traceEnd function that automatically sends the following parameters:
     *  timestamp
     *  duration (calculated from the time the trace function was called)
     *  actionPosition='end'
     */
    function traceStart(params, baseUrl) {
        _validateBaseUrl(baseUrl)
        const fullBaseUrl = `${baseUrl}/v1`
        const date = new Date()
        const start = date.getTime()
        const extendedParams = _.merge({}, _getDefaultParams(), params, {
            timestamp: date.toJSON(),
            actionPosition: 'start'
        })
        _reportKibana(extendedParams, fullBaseUrl)
        return function (/*_traceEnd*/ endParams) {
            const endDate = new Date()
            const current = endDate.getTime()
            const duration = (current - start) / 1000
            endParams = _.merge({}, extendedParams, endParams, {
                timestamp: endDate.toJSON(),
                duration,
                actionPosition: 'end'
            })
            _reportKibana(endParams, fullBaseUrl)
        }
    }

    return {
        levels,
        trace: traceStart
    }
})
