define(['lodash', 'documentServices/utils/runtimeConfig'], function (_, runtimeConfig) {
    'use strict'

    /**
     * @param {ps} ps
     * @param x
     * @param y
     */
    function setScroll(ps, x, y) {
        if (ps.siteAPI.isPopupOpened()) {
            const popupRootDOMNode = window.document.getElementById('POPUPS_ROOT')

            // @ts-ignore
            popupRootDOMNode.scrollLeft = x
            // @ts-ignore
            popupRootDOMNode.scrollTop = y
        } else {
            window.scrollTo(x, y)
        }
    }

    /**
     * @param {ps} ps
     * @param {Boolean} ignorePopups
     * @returns {{x: (number), y: (number)}|{x: *, y: *}}
     */
    function getScroll(ps, ignorePopups = false) {
        if (ps.siteAPI.isPopupOpened() && !ignorePopups) {
            const popupRootDOMNode = window.document.getElementById('POPUPS_ROOT')

            return {
                x: _.get(popupRootDOMNode, 'scrollLeft', 0),
                y: _.get(popupRootDOMNode, 'scrollTop', 0)
            }
        }

        return {
            x: _.isFinite(window.pageXOffset) ? window.pageXOffset : window.scrollX,
            y: _.isFinite(window.pageYOffset) ? window.pageYOffset : window.scrollY
        }
    }

    /**
     * @param {ps} ps
     * @param x
     * @param y
     * @param scale
     * @param duration
     * @param originLeft
     * @param {Function} callback
     * @returns {*}
     */
    function setScrollAndScale(ps, x, y, scale, duration, originLeft, callback) {
        const animationContainers = runtimeConfig.isThunderbolt(ps) ? ['#site-root', '#popups-wrapper'] : ['#SITE_ROOT', '#POPUPS_WRAPPER']
        const animations = ps.siteAPI.getSiteAnimationsService()
        const callbacks = {onComplete: callback}
        const sequence = animations.sequence({callbacks})
        const left = !_.isUndefined(originLeft) ? originLeft.toString() : '50%'
        sequence
            .add(animations.animate('BaseNone', animationContainers, 0, 0, {transformOrigin: `${left} 0`}), 0)
            .add(
                animations.animate('BaseScale', animationContainers, duration, 0, {
                    to: {scale},
                    clearProps: scale === 1 ? 'transform, transform-origin' : ''
                }),
                0
            )
            .add(animations.animate('BaseScroll', window, duration, 0, {x, y}), 0)

        return sequence.get()
    }

    /**
     * @param {ps} ps
     * @param x
     * @param y
     * @param duration
     * @param callback
     * @returns {*}
     */
    function animateScroll(ps, x, y, duration = 0, callback = _.noop) {
        const animations = ps.siteAPI.getSiteAnimationsService()
        const callbacks = {onComplete: callback}
        const sequence = animations.sequence({callbacks})
        sequence.add(animations.animate('BaseScroll', window, duration, 0, {x, y}), 0)
        return sequence.get()
    }

    return {
        setScrollAndScale,
        animateScroll,
        setScroll,
        getScroll
    }
})
