define(['lodash'], function (_) {
    'use strict'

    const PointerOperation = {
        GET: 'get',
        SET: 'set'
    }
    const EMPTY_TRANSLATION_DATA = {
        data: {
            document_data: {}
        }
    }

    const getCurrentLanguageCode = ps => ps.dal.get(ps.pointers.multilingual.currentLanguageCode()) || null //backwards compatibility of API. See the test for more info
    const getTranslationLanguages = ps => ps.dal.get(ps.pointers.multilingual.translationLanguages()) ?? []

    const generatePageTranslationsData = ps =>
        _.reduce(
            getTranslationLanguages(ps),
            (acc, {languageCode}) => {
                acc[languageCode] = _.cloneDeep(EMPTY_TRANSLATION_DATA)
                return acc
            },
            {}
        )

    const setCurrentLanguage = (ps, languageCode) => {
        const {dal, pointers} = ps

        if (getCurrentLanguageCode(ps) === languageCode) {
            return
        }

        const currentLanguagePointer = pointers.multilingual.currentLanguageCode()
        dal.set(currentLanguagePointer, languageCode)
        dal.touch(currentLanguagePointer)
    }

    return {
        PointerOperation,
        EMPTY_TRANSLATION_DATA,
        getCurrentLanguageCode,
        setCurrentLanguage,
        generatePageTranslationsData
    }
})
