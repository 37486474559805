define(['lodash', 'documentServices/utils/contextAdapter', './partnersUtils'], function (_, contextAdapter, partnersUtils) {
    'use strict'

    /**
     * @param {ps} ps
     * @returns {Promise}
     */
    let currentPartnersRequest = false

    const isPotentialPartnerAsync = async ps => {
        const isPotentialPartnerPointer = ps.pointers.partners.getIsPotentialPartnerPointer()
        const isPotentialPartnerVal = ps.dal.get(isPotentialPartnerPointer)
        //lazy fetching partners data
        if (!_.isBoolean(isPotentialPartnerVal)) {
            if (currentPartnersRequest) {
                return currentPartnersRequest
            }
            currentPartnersRequest = partnersUtils.fetchAndWritePartnersData(ps, contextAdapter)
            return currentPartnersRequest
        }
        return !!isPotentialPartnerVal
    }
    return {
        isPotentialPartnerAsync,
        initState: () => {
            currentPartnersRequest = false
        }
    }
})
