define(['lodash', 'documentServices/theme/isSystemStyle'], function (_, isSystemStyle) {
    'use strict'

    const PERSISTENT_DATA_IDS = ['MAIN_MENU', 'CUSTOM_MENUS', 'CUSTOM_MAIN_MENU', 'IMAGE_QUALITY']
    const PERSISTENT_VALIDATIONS_BY_TYPE = {
        data: id => _.includes(PERSISTENT_DATA_IDS, id),
        style: isSystemStyle
    }

    function isItemPersistent(type, id) {
        return _.invoke(PERSISTENT_VALIDATIONS_BY_TYPE, type, id)
    }

    return {
        isItemPersistent,
        PERSISTENT_DATA_IDS
    }
})
