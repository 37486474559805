import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const componentFetchers = {}
coreUtils.sessionFreezer.freeze(componentFetchers)

/**
 * @class santaTypesFetchersRegistry
 */
export default {
    registerFetchersNamespace(ns, fetchers) {
        componentFetchers[ns] = fetchers
    },

    getRegisteredFetchers() {
        return componentFetchers
    }
}
