define(['lodash', 'documentServices/dataModel/dataModel'], function (_, dataModel) {
    'use strict'

    function getConnections(ps, compRef) {
        const connections = dataModel.getConnectionsItem(ps, compRef)
        return connections || []
    }

    function getPlatformAppConnections(ps, compRef) {
        return _.reject(getConnections(ps, compRef), {type: 'WixCodeConnectionItem'})
    }

    function isCompConnectedToController(ps, compRef, controllerRef) {
        return _.some(getConnections(ps, compRef), {controllerRef})
    }

    function getPrimaryConnection(ps, compRef) {
        return _.find(getPlatformAppConnections(ps, compRef), {isPrimary: true})
    }

    function getConnectionsByPointer(ps, connectionPtr, pagePtr) {
        return dataModel.getConnectionsItemByPointer(ps, connectionPtr, pagePtr)
    }

    return {
        getConnections,
        getConnectionsByPointer,
        getPlatformAppConnections,
        isCompConnectedToController,
        getPrimaryConnection
    }
})
