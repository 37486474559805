import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

// TODO: This code is for editor - so we'll fix these variables later
const callbacks = []
let callId = 1
const {siteAspectsRegistry} = componentsCore

const tpaPreviewEditorAspect = function (aspectSiteAPI) {
    if (isInSSR()) {
        return
    }

    aspectSiteAPI.registerToMessage(receiver)
}

siteAspectsRegistry.registerSiteAspect('tpaPreviewEditorAspect', tpaPreviewEditorAspect)

const doPostMessage = function (msgType, params, compId?, callback?) {
    const blob = getBlob(msgType, params, compId, callback)

    let target
    if (window.parent.postMessage) {
        target = window.parent
        // @ts-ignore
    } else if (window.parent.document.postMessage) {
        target = window.parent.document
    }

    if (target && typeof target !== 'undefined') {
        target.postMessage(JSON.stringify(blob), '*')
    }
}

const getBlob = function (msgType, params, compId, onResponseCallback) {
    const blob = {
        intent: 'TPA_PREVIEW',
        callId: callId++,
        type: msgType,
        compId,
        data: params
    }

    if (onResponseCallback) {
        callbacks[blob.callId] = onResponseCallback
    }

    return blob
}

const receiver = function (event) {
    let data
    try {
        data = JSON.parse(event.data)
    } catch (e) {
        return
    }

    switch (data.intent) {
        case 'TPA_PREVIEW':
        case 'TPA_PREVIEW_RESPONSE':
            if (data.callId && callbacks[data.callId]) {
                callbacks[data.callId](data.res)
                delete callbacks[data.callId]
            }
            break
    }
}

function isInSSR() {
    return typeof window === 'undefined'
}

export default {
    doPostMessage
}
