import {
    getValueByIdentifier,
    getMetaByIdentifier,
    IDENTIFIERS,
    // eslint-disable-next-line import/named
    Identifier,
    resolve,
    updateValueByIdentifier,
    // eslint-disable-next-line import/named
    SeoSchema,
    getIndexableValue
} from '@wix/advanced-seo-utils'
import type {PageOptions, PageAPI, OgImage} from '../extensions/page'
import {createDefaultMetaData} from './dataUtils'
import {MASTER_PAGE_ID, DATA_TYPES} from '../constants/constants'
import {isEmpty, isString} from 'lodash'
import type {CoreLogger} from '@wix/document-manager-core'
const {TITLE, DESCRIPTION, ROBOTS, OG_IMAGE} = IDENTIFIERS
const NO_INDEX = 'noindex'

const safelyParseJsonData = (data: string, logger: CoreLogger): SeoSchema => {
    let result: SeoSchema = {tags: []}

    if (!isString(data)) {
        return result
    }

    try {
        result = JSON.parse(data)
    } catch (e) {
        logger.captureError(e as Error, {tags: {safelyParseJsonData: true, data}})
    }

    return result
}

const getLegacyOgImage = (blob: SeoSchema): OgImage => {
    const uri = getValueByIdentifier(blob, OG_IMAGE)
    const meta = getMetaByIdentifier(blob, OG_IMAGE)
    const metaData = createDefaultMetaData({pageId: MASTER_PAGE_ID}, DATA_TYPES.data)
    // @ts-ignore
    return (
        uri &&
        meta && {
            uri,
            width: meta?.width || 0,
            height: meta?.height || 0,
            type: 'Image',
            alt: '',
            title: '',
            metaData
        }
    )
}

const updateBlobWithValue = (blob: SeoSchema, identifier: Identifier, value?: any) => {
    if (!value) {
        return blob
    }
    if (identifier === OG_IMAGE) {
        const {uri, width, height} = value
        return uri === '' || width === 0 || height === 0 ? blob : updateValueByIdentifier(blob, identifier, uri, {width, height})
    }
    return updateValueByIdentifier(blob, identifier, value)
}

const getLegacyData = (pageApi: PageAPI, pageId: string, options: PageOptions): SeoSchema => {
    const identifierMap: {[x in Identifier]?: string | OgImage} = {
        [TITLE]: pageApi.getPageTitleSEO(pageId, options),
        [DESCRIPTION]: pageApi.getDescriptionSEO(pageId, options),
        // @ts-ignore
        [ROBOTS]: pageApi.getIndexable(pageId) === false && NO_INDEX,
        [OG_IMAGE]: pageApi.getOgImageRef(pageId, options)
    }

    // @ts-ignore
    return (
        Object.keys(identifierMap)
            // @ts-ignore
            .reduce((acc, curr: Identifier) => updateBlobWithValue(acc, curr, identifierMap[curr]), {} as SeoSchema)
    )
}

const setLegacyData = (pageApi: PageAPI, logger: CoreLogger, pageId: string, advancedObject: string, options: PageOptions): void => {
    const advancedBlob = safelyParseJsonData(advancedObject, logger)
    const title = getValueByIdentifier(advancedBlob, IDENTIFIERS.TITLE)
    if (title) {
        pageApi.setPageTitleSEO(pageId, title, options)
    }
    const description = getValueByIdentifier(advancedBlob, IDENTIFIERS.DESCRIPTION)
    if (description) {
        pageApi.setDescriptionSEO(pageId, description, options)
    }
    const indexable = getIndexableValue(getValueByIdentifier(advancedBlob, IDENTIFIERS.ROBOTS))
    if (indexable) {
        pageApi.setIndexable(pageId, indexable !== NO_INDEX)
    }
    const ogImage = getLegacyOgImage(advancedBlob)
    if (ogImage) {
        pageApi.setOgImageRef(pageId, ogImage, options)
    }
}

const enrichLegacyData = (pageApi: PageAPI, logger: CoreLogger, pageId: string, advancedObject: string, options: PageOptions) => {
    const legacyData = getLegacyData(pageApi, pageId, options)
    if (isEmpty(legacyData)) {
        return advancedObject
    }
    const advancedBlob = safelyParseJsonData(advancedObject, logger)
    const resolvedObj = resolve([legacyData, advancedBlob], {}, {withDefaults: false})
    return JSON.stringify(resolvedObj)
}

export {setLegacyData, enrichLegacyData}
