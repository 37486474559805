define(['lodash', 'documentServices/dataModel/dataModel', 'documentServices/utils/utils'], function (_, dataModel, dsUtils) {
    'use strict'

    const MIN_HEIGHT_PER_ITEM = 25
    const MIN_WIDTH = 100

    const getItemsCount = (ps, compPointer) => {
        const compData = dataModel.getDataItem(ps, compPointer)
        const menuRef = _.isString(compData.menuRef) ? dsUtils.stripHashIfExists(compData.menuRef) : compData.menuRef.id
        const menuItems = dataModel.getDataItemById(ps, menuRef).items
        return _.filter(menuItems, 'isVisible').length
    }

    const getLayoutLimits = (ps, compPointer) => {
        const itemsCount = getItemsCount(ps, compPointer)

        return {
            minHeight: itemsCount * MIN_HEIGHT_PER_ITEM,
            minWidth: MIN_WIDTH
        }
    }

    return {
        isRepeatable: false,
        duplicatable: false,
        layoutLimits: getLayoutLimits
    }
})
