define(['documentServices/componentsMetaData/metaDataUtils'], function (metaDataUtils) {
    'use strict'

    const STATE_BOX = 'wysiwyg.viewer.components.StateBox'

    function canBeStretched(ps, metaDataValue, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === STATE_BOX) {
            return false
        }
        return metaDataValue
    }

    return {
        canBeStretched
    }
})
