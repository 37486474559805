define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/componentsMetaData/components/baseTpaMetaData',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/utils/utils',
    'experiment'
], function (_, dataModel, baseTpaMetaData, metaDataUtils, dsUtils, experiment) {
    'use strict'

    function getCompData(component, ps, pageId) {
        const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
        const dataPointer = ps.pointers.data.getDataItem(dataQuery, pageId)
        return ps.dal.get(dataPointer)
    }

    return {
        isRepeatable: experiment.isOpen('tpaInRepeaterPoc-doNotOpen'),
        crossSiteDuplicatable: false,
        isPublicContainer: false,
        layoutLimits: metaDataUtils.getControllerLayoutLimits,
        mobileConversionConfig: {
            hideByDefault(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = baseTpaMetaData.getWidgetData(ps, compData)
                return _.isEmpty(widgetData.mobileUrl)
            },
            minHeight(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = baseTpaMetaData.getWidgetData(ps, compData)
                return _.get(widgetData, 'componentFields.minHeightInMobile')
            },
            stretchHorizontally(ps, component, pageId) {
                const compData = getCompData(component, ps, pageId)
                const widgetData = baseTpaMetaData.getWidgetData(ps, compData)
                return _.get(widgetData, 'shouldBeStretchedByDefaultMobile') === true
            }
        },
        canBeStretched(ps, compPointer) {
            const compData = dataModel.getDataItem(ps, compPointer)
            const widgetData = baseTpaMetaData.getWidgetData(ps, compData)
            return _.get(widgetData, 'canBeStretched') === true
        },
        nickname(ps, compPointer) {
            return baseTpaMetaData.nickname(ps, compPointer)
        },
        rotatable(ps, compPointer) {
            return baseTpaMetaData.rotatable(ps, compPointer)
        },
        canBeFixedPosition(ps, compPointer) {
            return baseTpaMetaData.canBeFixedPosition(ps, compPointer)
        },
        removable(ps, compPointer) {
            return baseTpaMetaData.removable(ps, compPointer)
        },
        duplicatable(ps, compPointer) {
            return baseTpaMetaData.duplicatable(ps, compPointer)
        },
        resizable(ps, compRef) {
            return baseTpaMetaData.resizable(ps, compRef)
        },
        canReparent(ps, compRef) {
            return baseTpaMetaData.canReparent(ps, compRef)
        }
    }
})
