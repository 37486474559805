define(['documentServices/dataModel/dataModel', 'documentServices/constants/constants'], function (dataModel, constants) {
    'use strict'

    return {
        isRepeatable: false,
        resizableSides(ps, compPointer) {
            const {uri} = dataModel.getPropertiesItem(ps, compPointer)

            if (!uri) {
                return []
            }

            return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
        },

        mobileConversionConfig: {
            preserveAspectRatio: false
        }
    }
})
