const pointerType = 'activeVariants'
import {getPointer} from './pointerUtils'

const createPointersMethods = () => ({
    activeVariants: {
        getActiveVariant: (compId: string) => getPointer(compId, pointerType)
    }
})

export {createPointersMethods}
