/*
At the moment (December 2020), `editorSDK` has two distinct "flavors" of events:
"general" (addressed to a specific TPA; do not need a "subscription") and
"custom" (broadcasted to everyone listening; TPA needs to explicitly subscribe
(or "register") to these). What "flavor" any event has is determined by what
callback editor has called to trigger it:
`platform.notifyApplication` ("general" events) or `platform.notifyAppsOnCustomEvent`
("custom" events)

Somewhen someone had an idea to use `componentAddedToStage` in context of both
"general" and "custom" events:
if TPA subscribed to `componentAddedToStage`, it would receive notifications‡
about _any_ added component, if not — only about it's own connected components

‡ - via calling exported by TPA `onEvent` function

Some time later, `editorSDK` needed to implement `editorSDK.addEventListener` method
to handle editor events outside TPAs. `addEventListener` is explicit subscription,
so it registers to "custom" events

"Hybrid" behavior of `componentAddedToStage` makes it difficult to expose it in
`addEventListener` while not breaking `onEvent` callbacks in existing TPAs:
if TPA has `onEvent` when it listens only for "general" `componentAddedToStage`,
then calling `editorSDK.addEventListener` would result in also triggering "custom"
`componentAddedToStage` events

To work around that, `componentAddedToStage` "hybrid" event was split into two:
- "general" `connectedComponentAddedToStage` and
- "custom" `anyComponentAddedToStage`

To keep existing TPAs working, when editor triggers one of these new events,
DS needs to also trigger the right "flavor" of `componentAddedToStage`. This file
tries to help with that
*/

define(['experiment'], function (experiment) {
    'use strict'

    const componentAddedToStage = 'componentAddedToStage'
    const connectedComponentAddedToStage = 'connectedComponentAddedToStage'
    const anyComponentAddedToStage = 'anyComponentAddedToStage'
    const eventsToIgnoreFromEditor = [componentAddedToStage, connectedComponentAddedToStage, anyComponentAddedToStage]

    // Migrating componentAddedToStage event from editors to DM, until migration is over need to ignore events from editors
    // Todo: delete when migration is over and editors do not send componentAddedToStage event
    function shouldIgnoreEvent(eventType, eventOrigin) {
        if (experiment.isOpen('dm_moveComponentAddedToStageToDm') && eventsToIgnoreFromEditor.includes(eventType) && eventOrigin !== 'DM') {
            return true
        }
    }

    function getGeneralEventCompatibleType(eventType) {
        if (eventType === connectedComponentAddedToStage) {
            return componentAddedToStage
        }

        // TODO: delete this check when `santa-editor` stops sending `componentAddedToStage` events
        if (eventType === componentAddedToStage) {
            return connectedComponentAddedToStage
        }

        return null
    }

    function getCustomEventCompatibleType(eventType) {
        if (eventType === anyComponentAddedToStage) {
            return componentAddedToStage
        }

        // TODO: delete this check when `santa-editor` stops sending `componentAddedToStage` events
        if (eventType === componentAddedToStage) {
            return anyComponentAddedToStage
        }

        return null
    }

    return {
        shouldIgnoreEvent,
        getGeneralEventCompatibleType,
        getCustomEventCompatibleType
    }
})
