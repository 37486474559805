import errors from './errors.json'
import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {logger} = warmupUtils.loggingUtils

/**
 * Please ctrl/cmd + click on biError to see the schema :)
 * @type {Object.<String, biError>}
 */

_.forEach(errors, function (error, key) {
    // @ts-ignore
    error.errorName = key
})

logger.register('core', 'error', errors)

export default errors
