import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import experiment from 'experiment'
import * as tpaComponents from '../../../_internal/tpaComponents'

const ASPECT_NAME = 'tpaMobileViewportAspect'

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @constructor
 */
const TPAMobileViewportAspect = function (aspectSiteAPI) {
    /** @type core.SiteAspectsSiteAPI */
    this.aspectSiteAPI = aspectSiteAPI
    _.bindAll(this, _.functionsIn(this))

    this.aspectSiteAPI.registerToSiteReady(this.fixMobileViewportIfNeeded)
    this.aspectSiteAPI.registerToUrlPageChange(this.fixMobileViewportIfNeeded)
}

TPAMobileViewportAspect.prototype = {
    fixMobileViewportIfNeeded() {
        const siteData = this.aspectSiteAPI.getSiteData()

        if (!experiment.isOpen('sv_tpaMobileViewportFix', siteData)) {
            return
        }

        const currentData = this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME)

        if (tpaComponents.services.clientSpecMapService.isCurrentPageFullResponsive(siteData)) {
            if (!currentData || !currentData.isResponsive) {
                const data = {isResponsive: true}
                this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, data)
                coreUtils.mobileViewportFixer.fixViewportTag(siteData, data)
            }
        } else if (currentData && currentData.isResponsive) {
            this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, null)
            coreUtils.mobileViewportFixer.fixViewportTag(siteData, null)
        }
    }
}

export default TPAMobileViewportAspect
