define(['lodash', '@wix/santa-core-utils', 'documentServices/page/pageData'], function (_, coreUtils, pageData) {
    'use strict'

    return function (ps) {
        const urlFormatPointer = ps.pointers.general.getUrlFormat()
        const urlFormat = ps.dal.get(urlFormatPointer)

        if (urlFormat === coreUtils.siteConstants.URL_FORMATS.HASH_BANG) {
            return
        }

        const pageIds = pageData.getPagesList(ps)
        const forbidden = pageData.getForbiddenPageUriSEOs(ps)

        const forbiddenPageUriSEO = _.reduce(
            pageIds,
            function (res, pageId) {
                const pageUriSEO = pageData.getPageUriSEO(ps, pageId)
                if (pageUriSEO && forbidden[pageUriSEO.toLowerCase()]) {
                    res.push(pageUriSEO)
                }
                return res
            },
            []
        )

        if (!_.isEmpty(forbiddenPageUriSEO)) {
            throw new Error(`Found pages with forbidden url title: ${JSON.stringify(forbiddenPageUriSEO)}`)
        }
    }
})
