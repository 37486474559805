import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const trace = viewerLibrary.get('documentServices/debug/dsQTrace')
    return {
        initMethod: trace.initialize,
        methods: {
            /**
             * DO NOT USE THIS IN PRODUCTION
             */
            debug: {
                trace: {
                    start: publicMethodUtils.defineAction(trace.startTrace),
                    stop: publicMethodUtils.defineAction(trace.endTrace),
                    clean: publicMethodUtils.defineAction(trace.cleanTrace),
                    get: publicMethodUtils.defineGetter(trace.get),
                    getActions: publicMethodUtils.defineGetter(trace.getActions),
                    getCalledMethods: publicMethodUtils.defineGetter(trace.getCalledMethods),
                    upload: publicMethodUtils.defineAction(trace.upload)
                }
            }
        }
    }
}
