define(['documentServices/dataModel/dataModel', 'documentServices/constants/constants'], function (dataModel, constants) {
    'use strict'

    const resizeOptions = [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT]

    return {
        isRepeatable: false,
        mobileConversionConfig: {
            hideByDefault: true
        },
        resizableSides(ps, compPointer) {
            const {displayMode} = dataModel.getPropertiesItem(ps, compPointer)

            if (displayMode === 'original') {
                return []
            }

            return resizeOptions
        }
    }
})
