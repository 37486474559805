define([
    'lodash',
    'documentServices/page/page',
    'documentServices/component/component',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/constants',
    'documentServices/platform/common/constants',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/tpa/services/appInstallationAndDeletionEvents',
    'documentServices/platform/platform',
    'documentServices/tpa/services/tpaDataService',
    'documentServices/tpa/utils/permissionsUtils',
    'documentServices/platform/services/platformStateService'
], function (
    _,
    page,
    component,
    clientSpecMapService,
    installedTpaAppsOnSiteService,
    tpaConstants,
    constants,
    componentDetectorAPI,
    appInstallationAndDeletionEvents,
    platform,
    tpaDataService,
    permissionsUtils,
    platformStateService
) {
    'use strict'

    const notifyHiddenSectionsAboutToDelete = function (ps, applicationId) {
        const hiddenPages = installedTpaAppsOnSiteService.getHiddenSections(ps, applicationId)

        _.forEach(hiddenPages, function (hiddenPage) {
            page.validatePageRemovalInternal(ps, hiddenPage.pageId) //notify on remove
        })
    }

    const actualDeleteHiddenSections = function (ps, applicationId) {
        const hiddenPages = installedTpaAppsOnSiteService.getHiddenSections(ps, applicationId)
        _.forEach(hiddenPages, function (hiddenPage) {
            page.remove(ps, hiddenPage.pageId)
        })
    }

    const notifyDeleteApplication = function (ps, applicationId, pageIdsToDelete) {
        const widgetCompsToDelete = getWidgetCompsToDelete(ps, applicationId, pageIdsToDelete)
        _.forEach(widgetCompsToDelete, function (compMap) {
            notifyWidgetToDelete(ps, compMap.compPointer, compMap.applicationId)
        })
        _.forEach(pageIdsToDelete, function (pageId) {
            page.validatePageRemovalInternal(ps, pageId)
        })
    }

    const deleteApplication = async function (ps, applicationId, pagesToDelete, isPlatformRemoval, intent) {
        const widgetsDeletionPromises = []
        const {appDefinitionId} = clientSpecMapService.getAppData(ps, applicationId)

        const pageIdsToDelete = _.map(pagesToDelete, 'id')
        const widgetCompsToDelete = getWidgetCompsToDelete(ps, applicationId, pageIdsToDelete)
        _.forEach(widgetCompsToDelete, function (compMap) {
            widgetsDeletionPromises.push(new Promise(res => deleteWidget(ps, compMap.compPointer, compMap.applicationId, res)))
        })

        await Promise.all(widgetsDeletionPromises)
        if (widgetsDeletionPromises.length > 0) {
            _.attempt(ps.dal.commitTransaction)
        }

        _.forEach(pageIdsToDelete, function (pageId) {
            if (ps.pointers.page.isExists(pageId)) {
                page.remove(ps, pageId)
            }
        })

        const shouldAvoidRevokingPromise = permissionsUtils.shouldAvoidRevoking({ps}, {intent})

        return (
            Promise.resolve()
                // eslint-disable-next-line promise/prefer-await-to-then
                .then(() => removeAppPlatformPartIfNeeded(ps, applicationId, isPlatformRemoval, intent))
                // eslint-disable-next-line promise/prefer-await-to-then
                .then(async () => {
                    const shouldAvoidRevoking = await shouldAvoidRevokingPromise

                    if (!shouldAvoidRevoking) {
                        platformStateService.setAppPendingAction(ps, appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
                    }
                })
        )
    }

    const removeAppPlatformPartIfNeeded = function (ps, applicationId, isPlatformRemoval, intent) {
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        const shouldRemoveAppPlatformPart =
            clientSpecMapService.hasEditorPlatformPart(appData) && !isPlatformRemoval && clientSpecMapService.isAppActive(ps, appData)

        if (shouldRemoveAppPlatformPart) {
            return platform.remove(ps, applicationId, {intent})
        }
    }

    const getAppWidgetsOnOtherPages = function (ps, applicationId, pageIdsToBeDeleted) {
        const appWidgetComps = installedTpaAppsOnSiteService.getWidgetsByAppId(ps, applicationId)
        return _.reject(appWidgetComps, function (comp) {
            return _.includes(pageIdsToBeDeleted, comp.pageId)
        })
    }

    const getWidgetsOnPagesToDelete = function (ps, pageIdsToBeDeleted) {
        return _.reduce(
            pageIdsToBeDeleted,
            function (result, pageId) {
                const tpasOnPage = installedTpaAppsOnSiteService.getAllTpaCompsOnPage(ps, pageId)
                const widgetsOnPage = _.filter(tpasOnPage, {type: tpaConstants.DATA_TYPE.TPA_WIDGET})
                return _.union(result, widgetsOnPage)
            },
            []
        )
    }

    const getWidgetCompsToDelete = function (ps, applicationId, pageIdsToBeDeleted) {
        const widgetsOnPagesToDelete = getWidgetsOnPagesToDelete(ps, pageIdsToBeDeleted)
        const appWidgetsOnOtherPages = getAppWidgetsOnOtherPages(ps, applicationId, pageIdsToBeDeleted)
        const widgetCompsToDelete = _.union(widgetsOnPagesToDelete, appWidgetsOnOtherPages)
        return getWidgetCompsToDeleteMap(ps, widgetCompsToDelete)
    }

    const getWidgetCompsToDeleteMap = function (ps, widgetCompsToDelete) {
        return _.map(widgetCompsToDelete, function (comp) {
            const pagePointer = page.getPage(ps, comp.pageId)
            const compPointer = ps.pointers.components.getComponent(comp.id, pagePointer)
            return {
                compPointer,
                applicationId: comp.applicationId
            }
        })
    }

    const notifyAppsToDelete = function (ps, applicationId, includeCurrentApp) {
        let appIdsToDelete = _.map(installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, applicationId), 'applicationId')
        if (includeCurrentApp) {
            appIdsToDelete = _.union([applicationId], appIdsToDelete)
        }
        const appsPagesToDelete = installedTpaAppsOnSiteService.getPagesByApplicationIds(ps, appIdsToDelete)
        _.forEach(appIdsToDelete, function (appId) {
            const pageIdsToDelete = _(appsPagesToDelete).filter({tpaApplicationId: appId}).map('id').value()
            notifyDeleteApplication(ps, appId, pageIdsToDelete)
        })
        return {
            appsPagesToDelete,
            appIdsToDelete
        }
    }

    const isLastInstalledMainWidget = function (installedWidgets, ps, applicationId, compPointer) {
        const installedMainWidgets = _.filter(installedWidgets, {widgetId: getMainWidgetId(ps, applicationId)})
        return installedMainWidgets && installedMainWidgets.length === 1 && installedMainWidgets[0].id === compPointer.id
    }

    const notifyWidgetToDelete = function (ps, compPointer, applicationId, onComplete) {
        const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppId(ps, applicationId)
        if (isLastInstalledMainWidget(installedWidgets, ps, applicationId, compPointer)) {
            notifyHiddenSectionsAboutToDelete(ps, applicationId)
            notifyOnOtherWidgetsDelete(ps, applicationId, installedWidgets)
            notifyAppsToDelete(ps, applicationId)
        }
        component.validateRemovalInternal(ps, compPointer, onComplete)
    }

    const notifyOnOtherWidgetsDelete = function (ps, applicationId, installedWidgets) {
        const widgetsToDelete = _.reject(installedWidgets, {widgetId: getMainWidgetId(ps, applicationId)})

        _.forEach(widgetsToDelete, function (widget) {
            const compPointer = componentDetectorAPI.getComponentById(ps, widget.id, widget.pageId)
            component.validateRemovalInternal(ps, compPointer)
        })
    }

    const getMainWidgetId = function (ps, applicationId) {
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        if (appData) {
            const widgetData = _.find(appData.widgets, {default: true})
            return _.get(widgetData, 'widgetId')
        }
        return undefined
    }

    const deleteApplicationWidgets = (ps, applicationId) => {
        const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppId(ps, applicationId)
        _.forEach(installedWidgets, widget => {
            const compPointer = componentDetectorAPI.getComponentById(ps, widget.id, widget.pageId)
            if (ps.dal.isExist(compPointer)) {
                component.deleteComponent(ps, compPointer)
            }
        })
    }

    /**
     * @param {ps} ps
     * @param {{appsPagesToDelete?, appIdsToDelete?, isPlatformRemoval?, intent?}} p
     * @param applicationId
     * @returns {Promise<*>}
     */
    const actualDeleteApps = async function (ps, {appsPagesToDelete, appIdsToDelete, isPlatformRemoval, intent}, applicationId) {
        if (!appsPagesToDelete) {
            const dependentAppsIds = _.map(installedTpaAppsOnSiteService.getInstalledDependentAppsData(ps, applicationId), 'applicationId')
            appsPagesToDelete = installedTpaAppsOnSiteService.getPagesByApplicationIds(ps, dependentAppsIds)
            appIdsToDelete = dependentAppsIds
        }

        const promises = []

        _.forEach(appIdsToDelete, tpaApplicationId => {
            const appPagesToDelete = _.filter(appsPagesToDelete, {tpaApplicationId})
            const deleteApplicationPromise = deleteApplication(ps, tpaApplicationId, appPagesToDelete, isPlatformRemoval, intent)
            promises.push(deleteApplicationPromise)
        })

        tpaDataService.runGarbageCollection(ps, _.uniq(appIdsToDelete.concat([applicationId])))

        await Promise.all(promises)

        if (!appIdsToDelete.includes(applicationId)) {
            await removeAppPlatformPartIfNeeded(ps, applicationId, isPlatformRemoval, intent)
        }

        deleteApplicationWidgets(ps, applicationId)
    }

    const deleteWidget = async function (ps, compPointer, applicationId, callback) {
        const installedWidgets = installedTpaAppsOnSiteService.getWidgetsByAppId(ps, applicationId)
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        if (isLastInstalledMainWidget(installedWidgets, ps, applicationId, compPointer) && clientSpecMapService.isAppActive(ps, appData)) {
            actualDeleteHiddenSections(ps, applicationId)
            deleteAppOtherWidgets(ps, applicationId, installedWidgets)
            await actualDeleteApps(ps, {}, applicationId)
        }
        if (ps.dal.isExist(compPointer)) {
            component.deleteComponent(ps, compPointer)
        }
        if (_.isFunction(callback)) {
            callback()
        }
    }

    const deleteAppOtherWidgets = function (ps, applicationId, installedWidgets) {
        const widgetsToDelete = _.reject(installedWidgets, {widgetId: getMainWidgetId(ps, applicationId)})
        _.forEach(widgetsToDelete, function (widget) {
            const compPointer = componentDetectorAPI.getComponentById(ps, widget.id, widget.pageId)
            component.deleteComponent(ps, compPointer)
        })
    }

    const deleteTpaCompleteCallback = function (ps, applicationIds) {
        _.forEach(applicationIds, function (applicationId) {
            if (!installedTpaAppsOnSiteService.isApplicationIdExists(ps, applicationId)) {
                const appData = clientSpecMapService.getAppData(ps, applicationId)
                appInstallationAndDeletionEvents.invokeDeleteAppCallbacks(appData.appDefinitionId)
            }
        })
    }

    return {
        notifyAppsToDelete,
        actualDeleteApps,

        notifyWidgetAboutToDelete: notifyWidgetToDelete,
        deleteWidget,

        deleteTpaCompleteCallback,
        getWidgetCompsToDeleteMap,
        getWidgetsOnPagesToDelete,
        isLastInstalledMainWidget
    }
})
