import _ from 'lodash'
import appsUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/appsUtils'
import wixCodeUrlUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/wixCodeUrlUtils'
import experiment from 'experiment'

/**
 * This is just as an helper function that helps keep main-r cleaner.
 * It calls appApi.init after extracting required information from basic main-r utils
 * and ensuring the dynamic model has been fetched.
 *
 * @param appApi
 * @param siteModel
 * @param isMobileView
 * @param queryUtil
 * @param currentUrl
 * @param isBot
 * @param fetchScripts
 * @param preload
 */
function initMainR({appApi, siteModel, isMobileView, queryUtil, currentUrl, isBot, fetchScripts, preload = true, hostArtifact = 'santa'}) {
    currentUrl = currentUrl || _.get(siteModel, 'currentUrl.full', window.document.location.href)
    const isDebug = !_.isEmpty(queryUtil.getParameterByName('debug'))
    const {namespacesSdkSource, externalComponentsSource, wixCodeNamespacesAndElementorySupportSource, wixCodeViewerAppSource} =
        wixCodeUrlUtils.buildScriptsSources(siteModel.serviceTopology, {isDebug})

    const useWixCodeFallbackParameter = queryUtil.getParameterByName('useWixCodeFallback')
    const useWixCodeFallback = determineWixCodeFallback(useWixCodeFallbackParameter)
    const initOptions: Record<string, any> = {
        hostArtifact,
        isMobileView,
        isDebug,
        namespacesSdkSource,
        externalComponentsSource,
        wixCodeNamespacesAndElementorySupportSource,
        wixCodeViewerAppSource,
        runtimeSource: queryUtil.getParameterByName('WixCodeRuntimeSource'),
        useWixCodeFallback,
        currentUrl
    }
    const viewerPlatformAppSources = queryUtil.getParameterByName('viewerPlatformAppSources')
    let viewerPlatformOverrides = queryUtil.getParameterByName('viewerPlatformOverrides')
    const controllersUrlOverride = queryUtil.getParameterByName('controllersUrlOverride')
    if (viewerPlatformOverrides) {
        try {
            viewerPlatformOverrides = JSON.parse(viewerPlatformOverrides)
        } catch (error) {
            //this is for local testing so not reporting the error here
        }
    }

    initOptions.applications = appsUtils.getAppsBaseInfo({
        rendererModel: siteModel.rendererModel,
        clientSpecMap: siteModel.rendererModel.clientSpecMap,
        serviceTopology: siteModel.serviceTopology,
        viewerPlatformAppSources,
        viewerPlatformOverrides,
        currentUrl,
        santaBase: siteModel.santaBase,
        controllersUrlOverride
    })

    initOptions.shouldUseWixCodeScripts = _.some(initOptions.applications, app => app.id === 'dataBinding' || app.shouldUseWixCodeScripts)

    function shouldPreLoadWidgets(clientSpecMap) {
        return preload && (_.some(clientSpecMap, {type: 'siteextension'}) || initOptions.applications.length)
    }

    function initApi(clientSpecMap) {
        const [userGeneratedApps, wixApps] = _.partition(initOptions.applications, {isWixTPA: false})
        initOptions.applications = wixApps

        const hasUserGeneratedApps = userGeneratedApps.length > 0
        appApi.init(siteModel, clientSpecMap, initOptions, fetchScripts, hasUserGeneratedApps)

        appApi.loadUserGeneratedApps(siteModel, currentUrl, userGeneratedApps)

        if (shouldPreLoadWidgets(clientSpecMap)) {
            if (!experiment.isOpen('sv_moveWixCodeToViewerApp', siteModel)) {
                appApi.preLoadUserCode(siteModel, currentUrl)
            }
            const isViewerMode = !!siteModel.publicModel
            if (isViewerMode) {
                appApi.preLoadWidgets(siteModel, currentUrl)
            }
        }
    }

    function determineWixCodeFallback(FallbackQueryParam) {
        if (FallbackQueryParam === 'false') {
            return false
        }
        return FallbackQueryParam === 'true' || !window.Worker || isBot
    }

    initApi(siteModel.rendererModel.clientSpecMap)
}

export default initMainR
