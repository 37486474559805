define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/dataSerialization',
    'documentServices/variants/relationsUtils',
    'documentServices/theme/isSystemStyle',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/utils/contextAdapter',
    '@wix/document-manager-utils',
    'documentServices/utils/utils',
    '@wix/document-services-json-schemas',
    'documentServices/extensionsAPI/extensionsAPI'
], function (
    _,
    constants,
    dataModel,
    dataSerialization,
    relationsUtils,
    isSystemStyle,
    mobileUtils,
    contextAdapter,
    documentManagerUtils,
    dsUtils,
    jsonSchemas,
    extensionsAPI
) {
    'use strict'

    const {
        namespaceMapping: {getNamespaceConfig}
    } = jsonSchemas

    const {DATA_TYPES, VARIANTS} = constants
    const {ReportableError} = documentManagerUtils

    const isTheReferenceARelationalSplit = (ps, itemType, dataItem, referenceName) => {
        const referenceFieldsInfo = extensionsAPI.schema.extractReferenceFieldsInfoForSchema(ps, itemType, dataItem.type)
        const reference = _.find(referenceFieldsInfo, ref => _.isEqual(ref.path, [referenceName]))
        return reference?.isRelationalSplit
    }

    const validateNamespaceIsDefinedWithRelationalSplit = namespace => {
        const config = getNamespaceConfig(namespace)
        if (!config.isRelationalSplitFromQuery) {
            throw new Error(`${namespace} namespace should have isRelationalSplitFromQuery`)
        }
    }

    const getComponentVariantsData = (ps, compPointerWithVariants, itemType) => {
        const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, compPointerWithVariants, itemType)
        return getPointerVariantsData(ps, compPointerWithVariants, itemType, pointerResolver)
    }

    const getPointerVariantsData = (ps, pointerWithVariants, itemType, refArrayOrValuePointerResolver) => {
        const pagePointer = ps.pointers.components.getPageOfComponent(pointerWithVariants)
        const pageId = pagePointer && pagePointer.id

        const refArrayOrValuePointer = refArrayOrValuePointerResolver()
        const refArrayOrValue = ps.dal.isExist(refArrayOrValuePointer) ? ps.dal.get(refArrayOrValuePointer) : null

        const variants = ps.pointers.components.isWithVariants(pointerWithVariants) ? _.map(pointerWithVariants.variants, 'id') : undefined
        const relationPointer = relationsUtils.getRelationPointerFromRefArrayByVariants(ps, itemType, refArrayOrValue, variants, pageId)
        const isRefArray = dataModel.refArray.isRefArray(ps, refArrayOrValue)
        return {
            pageId,
            variants,
            relationPointer,
            refArrayOrValue,
            refArrayOrValuePointer,
            isRefArray
        }
    }

    const createAndAddScopedValueToRelation = (ps, pointer, valueToUpdate, itemType, refArrayOrValue, variants, pageId) => {
        const newDataItemID = dataModel.generateNewId(itemType)
        const dataItemID = dataSerialization.addSerializedItemToPage(ps, pageId, valueToUpdate, newDataItemID, itemType)
        const refArrayId = relationsUtils.addScopedValueToRelation(ps, pointer, dataItemID, itemType, refArrayOrValue, variants, pageId)
        return {dataItemID, refArrayId}
    }

    /**
     * update data - it will create or override scoped value that referenced by the variants and return the scopedDataId
     *
     * @param ps
     * @param {Pointer} pointerWithVariants
     * @param {Object} valueToUpdate
     * @param {string} itemType
     * @param {UpdateScopedAndNonScopedDataHandlers} handlers
     * @returns {string} scopedDataId
     */
    const updateScopedData = (ps, pointerWithVariants, valueToUpdate, itemType, handlers) => {
        const {refArrayOrValuePointerResolver, setReferenceToRefArray, shouldUpdateDataId: shouldUpdateDataIdHandler} = handlers
        const {pageId, refArrayOrValue, relationPointer, variants, isRefArray} = getPointerVariantsData(
            ps,
            pointerWithVariants,
            itemType,
            refArrayOrValuePointerResolver
        )

        if (!relationPointer) {
            const {dataItemID, refArrayId} = createAndAddScopedValueToRelation(
                ps,
                pointerWithVariants,
                valueToUpdate,
                itemType,
                refArrayOrValue,
                variants,
                pageId
            )
            if (!isRefArray) {
                setReferenceToRefArray(refArrayId)
            }
            return dataItemID
        }
        const scopedValuePointer = relationsUtils.getScopedValuePointerByVariants(ps, itemType, refArrayOrValue, variants, pageId, relationPointer)
        const shouldUpdateDataId = shouldUpdateDataIdHandler(scopedValuePointer)
        const dataIdToUpdate = shouldUpdateDataId ? valueToUpdate.id : scopedValuePointer && scopedValuePointer.id
        const dataItemID = dataSerialization.addSerializedItemToPage(ps, pageId, valueToUpdate, dataIdToUpdate, itemType)

        if (shouldUpdateDataId) {
            ps.dal.set(ps.pointers.getInnerPointer(relationPointer, 'to'), `#${dataIdToUpdate}`)
        }
        return dataItemID
    }

    /**
     * update data - it will create or override non scoped value and returns non scoped data id
     *
     * @param {ps} ps
     * @param {Pointer} pointer
     * @param {Object} valueToUpdate
     * @param {string} itemType
     * @param {UpdateScopedAndNonScopedDataHandlers} handlers
     * @returns {string} nonScopedDataId
     */
    const updateNonScopedData = (ps, pointer, valueToUpdate, itemType, handlers) => {
        const {refArrayOrValuePointerResolver, setReferenceToRefArray, shouldUpdateDataId: shouldUpdateDataIdHandler} = handlers
        const {pageId, refArrayOrValue, refArrayOrValuePointer, isRefArray} = getPointerVariantsData(ps, pointer, itemType, refArrayOrValuePointerResolver)

        // create refArr & scope the data
        //may need to handle regular update as well (without refArr)
        if (!isRefArray && itemType !== DATA_TYPES.theme) {
            const dataItemID = dataSerialization.addSerializedItemToPage(ps, pageId, valueToUpdate, null, itemType)
            const refArr = dataModel.refArray.create(ps, [dataItemID])
            const refArrayId = dataSerialization.addDeserializedItemToPage(ps, pageId, itemType, refArr)
            setReferenceToRefArray(refArrayId)
            return dataItemID
        }
        const nonScopedValuePointer = relationsUtils.nonScopedValuePointer(ps, itemType, refArrayOrValue, pageId)
        const shouldUpdateDataId = shouldUpdateDataIdHandler(nonScopedValuePointer)
        const dataIdToUpdate = shouldUpdateDataId ? valueToUpdate.id : nonScopedValuePointer && nonScopedValuePointer.id
        const dataItemID = dataSerialization.addSerializedItemToPage(ps, pageId, valueToUpdate, dataIdToUpdate, itemType)

        if (!nonScopedValuePointer || shouldUpdateDataId) {
            const currentScopedId = shouldUpdateDataId ? nonScopedValuePointer.id : ''
            const currentValues = _.without(dataModel.refArray.extractValues(ps, refArrayOrValue), `#${currentScopedId}`)
            const newValues = [`#${dataItemID}`, ...currentValues]
            ps.dal.set(ps.pointers.getInnerPointer(refArrayOrValuePointer, 'values'), newValues)
        }
        return dataItemID
    }

    const validateVariants = (ps, compPointerWithVariants) => {
        _.forEach(compPointerWithVariants.variants, variant => {
            if (!ps.dal.isExist(variant)) {
                const error = new ReportableError({
                    errorType: 'nonExistingVariant',
                    message: `Update with non-existing variant ${variant.id}`,
                    extras: {
                        compPointer: compPointerWithVariants
                    }
                })
                contextAdapter.utils.fedopsLogger.captureError(error)
                throw error
            }
        })
    }

    /**
     * get the handlers for updating component scoped and non scoped data
     *
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {string} itemType
     * @returns {UpdateScopedAndNonScopedDataHandlers} handlers
     */
    const getHandlersForUpdatingComponentScopedAndNonScopedData = (ps, componentPointer, itemType) => {
        const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, componentPointer, itemType)
        const setReferenceToRefArray = refArrayId => {
            dataModel.linkComponentToItemByType(ps, componentPointer, refArrayId, itemType)
            mobileUtils.syncMobileAndDesktopByDataType(ps, componentPointer, itemType, refArrayId, true)
        }
        const shouldUpdateDataId = scopedOrNonScopedValuePointer => {
            return scopedOrNonScopedValuePointer && isSystemStyle(scopedOrNonScopedValuePointer.id)
        }
        return {
            refArrayOrValuePointerResolver: pointerResolver,
            setReferenceToRefArray,
            shouldUpdateDataId
        }
    }

    /**
     * get the handlers for updating scoped and non scoped data
     *
     * @param {ps} ps
     * @param {Pointer} pointer
     * @param {string} referenceName
     * @param {string} namespace
     * @returns {UpdateScopedAndNonScopedDataHandlers} handlers
     */
    const getHandlersForUpdatingScopedAndNonScopedData = (ps, pointer, referenceName, namespace) => {
        const dataItem = ps.dal.get(pointer)
        const referencePointer = ps.pointers.getPointer(dsUtils.stripHashIfExists(dataItem[referenceName]), namespace)
        const referencePointerWithVariants = pointer.variants ? getPointerWithVariants(ps, referencePointer, pointer.variants) : referencePointer
        const setReferenceToRefArray = refArrayId => ps.dal.set(ps.pointers.getInnerPointer(pointer, referenceName), `#${refArrayId}`)
        return {
            refArrayOrValuePointerResolver: () => referencePointerWithVariants,
            setReferenceToRefArray,
            shouldUpdateDataId: () => false
        }
    }

    /**
     * update component data with 2 modes
     * 1. comp pointer with variants - it will create or override scoped value that referenced by the variants
     * 2. regular comp pointer - it will create or override non scoped value
     *
     * @param ps
     * @param {Pointer} compPointerWithVariants
     * @param {Object} valueToUpdate
     * @param {string} itemType
     */
    const updateComponentDataConsideringVariants = (ps, compPointerWithVariants, valueToUpdate, itemType) => {
        validateNamespaceIsDefinedWithRelationalSplit(itemType)
        validateVariants(ps, compPointerWithVariants)
        if (_.isEmpty(_.omit(valueToUpdate, 'type'))) {
            console.warn('please pass non empty object, for full delete use remove api')
            return
        }

        const handlers = getHandlersForUpdatingComponentScopedAndNonScopedData(ps, compPointerWithVariants, itemType)
        if (ps.pointers.components.isWithVariants(compPointerWithVariants)) {
            return updateScopedData(ps, compPointerWithVariants, valueToUpdate, itemType, handlers)
        }
        return updateNonScopedData(ps, compPointerWithVariants, valueToUpdate, itemType, handlers)
    }

    const updateDataConsideringVariants = (ps, pointerWithVariants, referenceName, valueToUpdate, namespace) => {
        validateVariants(ps, pointerWithVariants)

        const dataItem = ps.dal.get(pointerWithVariants)
        if (!isTheReferenceARelationalSplit(ps, namespace, dataItem, referenceName)) {
            throw new ReportableError({
                message: `the ${dataItem.id} dataItem should have isRelationalSplit at ${referenceName} property`,
                errorType: 'relationalSplitValidation'
            })
        }

        const handlers = getHandlersForUpdatingScopedAndNonScopedData(ps, pointerWithVariants, referenceName, namespace)
        if (ps.pointers.components.isWithVariants(pointerWithVariants)) {
            return updateScopedData(ps, pointerWithVariants, valueToUpdate, namespace, handlers)
        }

        return updateNonScopedData(ps, pointerWithVariants, valueToUpdate, namespace, handlers)
    }

    /**
     * get component data with 2 modes
     * 1. comp pointer with variants - it will get the scoped value that referenced by the variants
     * 2. regular comp pointer - it will get the non scoped value
     *
     * @param ps
     * @param {Pointer} compPointerWithVariants
     * @param {string} itemType
     * @param {boolean} deleteIds
     */
    const getComponentDataConsideringVariants = (ps, compPointerWithVariants, itemType, deleteIds = false) => {
        validateNamespaceIsDefinedWithRelationalSplit(itemType)
        const pointer = getComponentDataPointerConsideringVariants(ps, compPointerWithVariants, itemType)
        return pointer && dataModel.getDataByPointer(ps, itemType, pointer, deleteIds)
    }

    /**
     * get pointer data with 2 modes
     * 1. pointer with variants - it will get the scoped value that referenced by the variants
     * 2. regular pointer - it will get the non scoped value
     *
     * @param ps
     * @param {Pointer} pointerWithVariants
     * @param {string} referenceName
     * @param {string} namespace
     * @param {boolean} deleteIds
     */
    const getDataConsideringVariants = (ps, pointerWithVariants, referenceName, namespace, deleteIds = false) => {
        const pointer = getDataPointerConsideringVariants(ps, pointerWithVariants, referenceName, namespace)
        return pointer && dataModel.getDataByPointer(ps, namespace, pointer, deleteIds)
    }

    const getComponentDataPointerConsideringVariants = (ps, compPointerWithVariants, itemType) => {
        const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, compPointerWithVariants, itemType)
        const handlers = {
            refArrayOrValuePointerResolver: pointerResolver
        }
        return getDataPointerConsideringVariantsUsingHandlers(ps, compPointerWithVariants, itemType, handlers)
    }

    const getDataPointerConsideringVariants = (ps, pointerWithVariants, referenceName, namespace) => {
        const dataItem = ps.dal.get(pointerWithVariants)
        const referencePointer = ps.pointers.getPointer(dsUtils.stripHashIfExists(dataItem[referenceName]), namespace)
        const referencePointerWithVariants = pointerWithVariants.variants
            ? getPointerWithVariants(ps, referencePointer, pointerWithVariants.variants)
            : referencePointer
        const handlers = {
            refArrayOrValuePointerResolver: () => referencePointerWithVariants
        }
        return getDataPointerConsideringVariantsUsingHandlers(ps, pointerWithVariants, namespace, handlers)
    }

    /**
     * get data pointer data considering variants
     *
     * @param ps
     * @param {Pointer} pointerWithVariants
     * @param {string} itemType
     * @param {GetScopedAndNonScopedDataHandlers} handlers
     */
    const getDataPointerConsideringVariantsUsingHandlers = (ps, pointerWithVariants, itemType, handlers) => {
        const {refArrayOrValuePointerResolver} = handlers
        const {pageId, refArrayOrValue, variants, refArrayOrValuePointer, relationPointer, isRefArray} = getPointerVariantsData(
            ps,
            pointerWithVariants,
            itemType,
            refArrayOrValuePointerResolver
        )
        if (isRefArray) {
            if (variants) {
                const scopedValuePointer = relationsUtils.getScopedValuePointerByVariants(ps, itemType, refArrayOrValue, variants, pageId, relationPointer)
                return scopedValuePointer
            }

            const nonScopedValuePointer = relationsUtils.nonScopedValuePointer(ps, itemType, refArrayOrValue, pageId)
            return nonScopedValuePointer
        }

        return !variants ? refArrayOrValuePointer : null
    }

    /**
     * removing non scoped value
     * it will remove the whole ref array including relations
     *
     * @param {ps} ps
     * @param {RemoveScopedAndNonScopedDataHandlers} handlers
     */
    const removeNonScoped = (ps, handlers) => {
        const {refArrayOrValuePointerResolver, removeRefArrayFromReferenceResolver} = handlers
        const refArrayOrValuePointer = refArrayOrValuePointerResolver()
        const refArrayOrValueData = ps.dal.isExist(refArrayOrValuePointer) ? ps.dal.get(refArrayOrValuePointer) : null

        const isRefArray = dataModel.refArray.isRefArray(ps, refArrayOrValueData)
        if (isRefArray) {
            dataModel.removeItemRecursivelyByType(ps, refArrayOrValuePointer)
            removeRefArrayFromReferenceResolver()
        }
    }

    /**
     * remove value recursively the relation and scoped value
     * it will remove the ref array and the component ref to it if the ref array is empty after the removal
     *
     * @param {ps} ps
     * @param {Pointer} pointerWithVariants
     * @param {string} itemType
     * @param {RemoveScopedAndNonScopedDataHandlers} handlers
     */
    const removeScoped = (ps, pointerWithVariants, itemType, handlers) => {
        const {refArrayOrValuePointerResolver} = handlers
        const {pageId, relationPointer, isRefArray} = getPointerVariantsData(ps, pointerWithVariants, itemType, refArrayOrValuePointerResolver)
        if (isRefArray && relationPointer) {
            relationsUtils.removeRelation(ps, relationPointer, itemType, pageId, handlers)
        }
    }

    /**
     * remove value recursively with 2 modes
     * 1. comp pointer with variants - it will remove relation and scoped value
     * 2. regular comp pointer - it will remove non scoped value
     * either way it will remove the ref array and the component ref to it if the ref array is empty after the removal
     *
     * @param ps
     * @param {Pointer} compPointerWithVariants
     * @param {string} itemType
     */
    const removeComponentDataConsideringVariants = (ps, compPointerWithVariants, itemType) => {
        validateNamespaceIsDefinedWithRelationalSplit(itemType)
        const pointerResolver = () => dataModel.getComponentDataPointerByType(ps, compPointerWithVariants, itemType)
        const handlers = {
            refArrayOrValuePointerResolver: pointerResolver,
            removeRefArrayFromReferenceResolver: () => dataModel.removeComponentDataByType(ps, compPointerWithVariants, itemType, true)
        }
        if (ps.pointers.components.isWithVariants(compPointerWithVariants)) {
            removeScoped(ps, compPointerWithVariants, itemType, handlers)
        } else {
            removeNonScoped(ps, handlers)
        }
    }

    /**
     * remove value recursively with 2 modes
     * 1. pointer with variants - it will remove relation and scoped value
     * 2. regular pointer - it will remove non scoped value
     * either way it will remove the ref array and the component ref to it if the ref array is empty after the removal
     *
     * @param ps
     * @param {Pointer} pointerWithVariants
     * @param {string} referenceName
     * @param {string} namespace
     */
    const removeDataConsideringVariants = (ps, pointerWithVariants, referenceName, namespace) => {
        const dataItem = ps.dal.get(pointerWithVariants)
        const referencePointer = ps.pointers.getPointer(dsUtils.stripHashIfExists(dataItem[referenceName]), namespace)
        const referencePointerWithVariants = pointerWithVariants.variants
            ? getPointerWithVariants(ps, referencePointer, pointerWithVariants.variants)
            : referencePointer
        const handlers = {
            refArrayOrValuePointerResolver: () => referencePointerWithVariants,
            removeRefArrayFromReferenceResolver: () => ps.dal.remove(ps.pointers.getInnerPointer(pointerWithVariants, referenceName))
        }
        if (ps.pointers.components.isWithVariants(pointerWithVariants)) {
            removeScoped(ps, pointerWithVariants, namespace, handlers)
        } else {
            removeNonScoped(ps, handlers)
        }
    }

    /**
     * update current scoped data id to be a system style id
     *
     * @param ps
     * @param {Pointer} compPointerWithVariants
     * @param {string} systemStyleId
     */
    const connectToThemeScoped = (ps, compPointerWithVariants, systemStyleId) => {
        const {pageId, refArrayOrValue, variants, relationPointer, isRefArray} = getComponentVariantsData(ps, compPointerWithVariants, DATA_TYPES.theme)

        if (!relationPointer) {
            const refArrayId = relationsUtils.addScopedValueToRelation(
                ps,
                compPointerWithVariants,
                systemStyleId,
                DATA_TYPES.theme,
                refArrayOrValue,
                variants,
                pageId
            )
            if (!isRefArray) {
                dataModel.linkComponentToItemByType(ps, compPointerWithVariants, refArrayId, DATA_TYPES.theme)
                mobileUtils.syncMobileAndDesktopByDataType(ps, compPointerWithVariants, DATA_TYPES.theme, refArrayId, true)
            }

            return
        }
        const scopedValuePointer = relationsUtils.getScopedValuePointerByVariants(ps, DATA_TYPES.theme, refArrayOrValue, variants, pageId, relationPointer)
        const isCustomStyle = scopedValuePointer && !isSystemStyle(scopedValuePointer.id)
        if (isCustomStyle) {
            ps.dal.remove(scopedValuePointer)
        }

        ps.dal.set(ps.pointers.getInnerPointer(relationPointer, 'to'), `#${systemStyleId}`)
    }

    /**
     * update current non scoped data id to be a system style id
     *
     * @param ps
     * @param {Pointer} componentPointer
     * @param {string} systemStyleId
     */
    const connectToThemeNonScoped = (ps, componentPointer, systemStyleId) => {
        const {pageId, refArrayOrValue, isRefArray, refArrayOrValuePointer} = getComponentVariantsData(ps, componentPointer, DATA_TYPES.theme)

        if (isRefArray) {
            const nonScopedValuePointer = relationsUtils.nonScopedValuePointer(ps, DATA_TYPES.theme, refArrayOrValue, pageId)
            const isCustomStyle = nonScopedValuePointer && !isSystemStyle(nonScopedValuePointer.id)

            if (isCustomStyle) {
                ps.dal.remove(nonScopedValuePointer)
            }

            const currentValues = dataModel.refArray.extractValues(ps, refArrayOrValue)
            const currentValuesWithoutNonScopedValue = nonScopedValuePointer ? _.without(currentValues, `#${nonScopedValuePointer.id}`) : currentValues
            const newValues = [`#${systemStyleId}`, ...currentValuesWithoutNonScopedValue]
            ps.dal.set(ps.pointers.getInnerPointer(refArrayOrValuePointer, 'values'), newValues)
        }
    }

    const connectToThemeStyleConsideringVariants = (ps, compPointerWithVariants, systemStyleId) => {
        if (!isSystemStyle(systemStyleId)) {
            throw new Error('connectToThemeStyle called with custom style')
        }
        if (ps.pointers.components.isWithVariants(compPointerWithVariants)) {
            connectToThemeScoped(ps, compPointerWithVariants, systemStyleId)
        } else {
            connectToThemeNonScoped(ps, compPointerWithVariants, systemStyleId)
        }
    }

    /**
     * return true if pointer with variant or the component currenlty points to a refArray
     *
     * @param ps
     * @param {Pointer} componentPointer
     * @param {string} itemType
     * @returns {boolean}
     */
    const shouldConsiderVariants = (ps, componentPointer, itemType) => {
        const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
        const isCurrentDataRefArray = dataModel.isComponentPointsToRefArray(ps, componentPointer, itemType)
        return isWithVariants || isCurrentDataRefArray
    }

    /**
     * collect component's variants into variants input array
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     */
    const collectCompVariants = (ps, componentPointer) => {
        const variants = []
        const variantItems = ps.pointers.data.getVariantDataItemsByComponentId(componentPointer.id)
        _.forEach(variantItems, variant => {
            variants.push(variant.id)
            if (variant.type === constants.BREAKPOINTS_TYPES.DATA) {
                const variantValuesWithNoHash = _.map(variant.values, value => dsUtils.stripHashIfExists(value))
                variants.push(...variantValuesWithNoHash)
            }
        })
        return variants
    }

    /**
     * this function collect variants that are valid when performeing repearent of component with variants
     * 1. collect all variants of new container and his ancestors + comp variants
     * 2. collect all variants from previous container and his ancestors + comp variants
     * 3. filter the variants according to the relevant view port so that desktop related overrides won't remove when repearenting in mobile view
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} newParentPointer
     * @returns {string[]} validVariantIds
     */

    const getValidVariantsForReparenting = (ps, componentPointer, newParentPointer, compChildren) => {
        const isMobile = ps.pointers.components.isMobile(componentPointer)
        const otherViewModeComp = isMobile
            ? ps.pointers.components.getDesktopPointer(componentPointer)
            : ps.pointers.components.getMobilePointer(componentPointer)
        const oldParentPointerOtherViewMode = ps.pointers.components.getParent(otherViewModeComp)

        const newParentVariantIds = collectAncestorsVariants(ps, newParentPointer)
        const compAndChildrenVariantIds = _.map(compChildren, comp => collectCompVariants(ps, comp))
        const oldParentVariantsIdsFromOtherViewMode = collectAncestorsVariants(ps, oldParentPointerOtherViewMode)

        return _([...compAndChildrenVariantIds, ...newParentVariantIds, ...oldParentVariantsIdsFromOtherViewMode])
            .flatten()
            .uniq()
            .value()
    }

    /**
     * this function removes variant overrides when reparenting a component with variant overrides
     * it deletes scoped data from the same viewMode that has variant that is not related anymore due to reparenting
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} newParentPointer
     */
    function removeVariantsOverridesIfNeeded(ps, componentPointer, newParentPointer) {
        const compChildren = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(componentPointer)
        const validVariantIds = getValidVariantsForReparenting(ps, componentPointer, newParentPointer, compChildren)
        const currentPage = ps.pointers.components.getPageOfComponent(componentPointer)
        const newPage = ps.pointers.components.getPageOfComponent(newParentPointer)

        const namespacesToRemoveFrom =
            currentPage.id === newPage.id ? _.without(VARIANTS.VALID_VARIANTS_DATA_TYPES, DATA_TYPES.reactions) : VARIANTS.VALID_VARIANTS_DATA_TYPES
        _.forEach(compChildren, compPointer => {
            _.forEach(namespacesToRemoveFrom, dataType => relationsUtils.removeIllegalRelationsFromRefArray(ps, compPointer, dataType, validVariantIds))
        })
    }

    /**
     * collect all variants of component and his ancestor returns all variants id's array
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     * returns {string []} variants id's array
     */
    const collectAncestorsVariants = (ps, componentPointer) => {
        let compPointer = componentPointer
        const variants = []
        while (compPointer) {
            const compVariants = collectCompVariants(ps, compPointer)
            variants.push(...compVariants)
            compPointer = ps.pointers.full.components.getParent(compPointer)
        }

        return variants
    }

    const getPointerWithVariants = (ps, pointer, variants) => {
        if (!ps || !pointer || !variants) {
            throw new Error('invalid args')
        }
        const variantsArray = _.isArray(variants) ? variants : [variants]
        return {...pointer, variants: _.unionWith(pointer.variants, variantsArray, _.isEqual)}
    }

    return {
        updateComponentDataConsideringVariants,
        connectToThemeStyleConsideringVariants,
        getComponentDataConsideringVariants,
        getComponentDataPointerConsideringVariants,
        removeComponentDataConsideringVariants,
        shouldConsiderVariants,
        removeVariantsOverridesIfNeeded,
        collectCompVariants,
        collectAncestorsVariants,
        updateDataConsideringVariants,
        removeDataConsideringVariants,
        getDataConsideringVariants,
        getPointerWithVariants,
        getDataPointerConsideringVariants
    }
})
