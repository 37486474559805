define([
    'lodash',
    'documentServices/wixCode/utils/fetchResponseErrorObject',
    'documentServices/wixCode/services/wixCodeServiceFacade',
    'documentServices/wixCode/services/kibanaReporterWrapper',
    'documentServices/wixCode/utils/clientSpecMapUtils'
], function (_, fetchResponseErrorObject, wixCodeServiceFacade, kibana, clientSpecMapUtils) {
    'use strict'

    function isAlreadySavedError(error) {
        const errorCode = fetchResponseErrorObject.safeGetErrorCode(error)
        return errorCode === -409 || errorCode === -409003
    }

    async function markAppImmutable({traceFunc, isAppReadOnly, wixCodeApp, baseUrl, metasiteId, gridAppId}) {
        const traceEnd = traceFunc({action: 'markAppImmutable'})

        if (isAppReadOnly) {
            traceEnd({message: 'no changes'})
            return
        }

        if (!wixCodeApp) {
            throw new Error('no wix code app')
        }

        const options = {
            baseUrl,
            metasiteId,
            gridAppId,
            signedInstance: wixCodeApp.instance
        }

        try {
            await wixCodeServiceFacade.save(options)
            traceEnd()
        } catch (error) {
            if (isAlreadySavedError(error)) {
                traceEnd({level: kibana.levels.WARN, message: 'The grid app was already saved on server'})
            } else {
                traceEnd({level: kibana.levels.ERROR, message: error})
                throw error
            }
        }
    }

    function getMetaSiteIdFromPS(ps) {
        const pointer = ps.pointers.general.getMetaSiteId()
        return ps.dal.get(pointer)
    }

    function getIsAppReadOnlyFromPS(ps) {
        const pointer = ps.pointers.wixCode.getIsAppReadOnly()
        return ps.dal.get(pointer)
    }

    function runUsingPS(ps) {
        const traceFunc = _.partial(kibana.trace, ps)
        const isAppReadOnly = getIsAppReadOnlyFromPS(ps)
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        const baseUrl = wixCodeServiceFacade.getBaseUrlFromPS(ps)
        const metasiteId = getMetaSiteIdFromPS(ps)
        const gridAppId = ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())

        return markAppImmutable({
            traceFunc,
            isAppReadOnly,
            wixCodeApp,
            baseUrl,
            metasiteId,
            gridAppId
        })
    }

    function runUsingSnapshot(currentSnapshotDataProvider) {
        return markAppImmutable(currentSnapshotDataProvider)
    }

    return {
        runUsingPS,
        runUsingSnapshot
    }
})
