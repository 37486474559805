import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'

const migrateMasterPage = (documentManager: DocumentManager) => {
    _.forEach(
        [documentManager.pointers.rendererModel.getPageToHashedPassword(), documentManager.pointers.platform.getPagesPlatformApplicationsPointer()],
        pointer => {
            const value = documentManager.dal.get(pointer)
            if (value?.metaData) {
                const updatedValue = _.cloneDeep(value)
                delete updatedValue.metaData
                documentManager.dal.remove(pointer)
                documentManager.dal.set(pointer, updatedValue)
            }
        }
    )
}

const name = 'removeMetaDataFromMaps'
const version = 1

export {migrateMasterPage, name, version}
