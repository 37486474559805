define([], function () {
    'use strict'

    function pluginFn(siteAPI, compLayoutRelativeToStructure, compProperties, compPointer) {
        const isVertical = compProperties && compProperties.verticalText

        return isVertical
            ? {
                  width: siteAPI.getSiteMeasureMap().minWidth[compPointer.id]
              }
            : {
                  height: siteAPI.getSiteMeasureMap().minHeight[compPointer.id]
              }
    }

    return pluginFn
})
