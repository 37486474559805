define(['lodash', '@wix/santa-core-utils', 'documentServices/structure/utils/componentLayout'], function (_, santaCoreUtils, componentLayout) {
    'use strict'

    const NON_MOVABLE_DATA_ITEMS = ['MAIN_MENU', 'CUSTOM_MAIN_MENU', 'CUSTOM_MENUS', 'masterPage']

    const canMoveData = ({id}) => !_.includes(NON_MOVABLE_DATA_ITEMS, id)
    const canMoveStyle = ({styleType}) => styleType !== 'system'

    const canMoveDataItemToAnotherPage = (dataItem, dataType) => {
        switch (dataType) {
            case santaCoreUtils.constants.DATA_TYPES.data:
                return canMoveData(dataItem)
            case santaCoreUtils.constants.DATA_TYPES.theme:
                return canMoveStyle(dataItem)
        }
        return true
    }

    function getLayoutFromBoundingLayout(privateServices, boundingLayout, currentRotationInDegrees) {
        return santaCoreUtils.boundingLayout.getLayoutFromBoundingLayout(boundingLayout, currentRotationInDegrees)
    }

    function getChildrenToPreserveProportionsByType(ps, componentsMetaData, structureChildren, ignoreChildren) {
        return _.reject(structureChildren, function (child) {
            const compType = componentsMetaData.getComponentType(ps, child.component)
            return _.includes(ignoreChildren, compType)
        })
    }

    function ensureWithinLimits(value, min, max) {
        min = _.isUndefined(min) ? value : min
        max = _.isUndefined(max) ? value : max
        if (min > max) {
            throw new Error('min limit is greater than max limit')
        }

        if (value < min) {
            return min
        }
        if (value > max) {
            return max
        }

        return value
    }

    /**
     *  @exports documentServices/structure/structureUtils
     */
    return {
        isHorizontallyDocked: santaCoreUtils.layoutUtils.isHorizontallyDocked,
        isVerticallyDocked: santaCoreUtils.layoutUtils.isVerticallyDocked,
        getBoundingY: santaCoreUtils.boundingLayout.getBoundingY,
        getBoundingX: santaCoreUtils.boundingLayout.getBoundingX,
        getBoundingHeight: santaCoreUtils.boundingLayout.getBoundingHeight,
        getBoundingWidth: santaCoreUtils.boundingLayout.getBoundingWidth,
        getBoundingLayout: componentLayout.getBoundingLayout,
        getLayoutFromBoundingLayout,
        canMoveDataItemToAnotherPage,
        ensureWithinLimits,
        getChildrenToPreserveProportionsByType,
        getComponentLayout: componentLayout.getComponentLayout,
        getPositionAndSize: componentLayout.getPositionAndSize
    }
})
