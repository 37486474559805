define(['documentServices/bi/errors.json', 'documentServices/bi/bi'], function (errors, bi) {
    'use strict'

    /**
     * Please ctrl/cmd + click on biError to see the schema :)
     * @type {Object.<String, BIError>}
     */

    bi.register('documentServices', 'error', errors)

    return errors
})
