define(['documentServices/dataModel/dataModel', 'documentServices/mobileConversion/modules/textStats'], function (dataModel, textStats) {
    const getProps = (ps, component, pageId) => {
        const propsPointer = ps.pointers.data.getPropertyItem(component.propertyQuery, pageId)
        return dataModel.getPropertiesItemById(ps, propsPointer.id, pageId) || {}
    }

    const getWRichTextConversionData = (ps, component, pageId) => {
        const {verticalText} = getProps(ps, component, pageId)

        const conversionData = {
            isVerticalText: verticalText
        }
        if (verticalText) {
            const {approximateMobileHeight, approximateMobileWidth} = textStats.getTextsData(ps, component, pageId, verticalText)
            conversionData.mobileHints = {
                recommendedHeight: approximateMobileHeight,
                recommendedWidth: approximateMobileWidth
            }
        }
        return conversionData
    }

    return {
        getWRichTextConversionData
    }
})
