define(['documentServices/dataModel/dataModel', 'documentServices/mobileUtilities/mobileUtilities', 'documentServices/component/component'], function (
    dataModel,
    mobileUtil,
    component
) {
    'use strict'

    function removeMobileCropOverrideIfNeeded(ps, compRef, updatedDataItem) {
        const compData = dataModel.getDataItem(ps, compRef)
        const imageSourceWasChanged = updatedDataItem.uri && compData.uri !== updatedDataItem.uri

        mobileUtil.wPhoto.removeMobileCropOverrideIfNeeded(ps, compRef, imageSourceWasChanged, {dataModel, component})
    }

    return {
        removeMobileCropOverrideIfNeeded
    }
})
