import PropTypes from 'prop-types'
import santaComponents from '@wix/santa-components'

const {utils: santaUtils} = santaComponents
export default santaUtils.createSantaTypesDefinitions(
    {
        linkRenderInfo: PropTypes.shape({
            // TODO: KADURI - split these to own object
            primaryPageId: PropTypes.string,
            currentUrl: PropTypes.object,
            currentUrlPageId: PropTypes.string,

            // TODO: KADURI - split these to own object
            urlFormat: PropTypes.string,
            mainPageId: PropTypes.string,
            externalBaseUrl: PropTypes.string,
            unicodeExternalBaseUrl: PropTypes.string,
            publicBaseUrl: PropTypes.string,
            isFeedbackEndpoint: PropTypes.bool,
            isSiteHistoryEndpoint: PropTypes.bool,
            isViewerMode: PropTypes.bool,
            isWixSite: PropTypes.bool,
            isTemplate: PropTypes.bool,
            isUsingSlashUrlFormat: PropTypes.bool,
            isPremiumDomain: PropTypes.bool,
            serviceTopology: PropTypes.shape({
                staticDocsUrl: PropTypes.string,
                basePublicUrl: PropTypes.string,
                baseDomain: PropTypes.string
            }),

            // TODO: KADURI - split these to own object
            routersConfigMap: PropTypes.object,
            allPageIds: PropTypes.array,
            pagesDataItemsMap: PropTypes.object,
            mapFromPageUriSeoToPageId: PropTypes.object,
            permalinksMap: PropTypes.object,
            rootNavigationInfo: PropTypes.object
        })
    },
    'wixappsProxySantaTypes'
)
