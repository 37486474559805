import type {Extension, PointerMethods, DalValue, DmApis} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {RelationshipsAPI} from './relationships'

const createPointersMethods = ({pointers, dal, extensionAPI}: DmApis): PointerMethods => {
    const {relationships} = extensionAPI as RelationshipsAPI
    const controllerIdCache = {}

    const getControllerInPageByDataId = (pagePointer: Pointer, isMobileView: boolean, controllerDataId: string): Pointer => {
        const cacheKey = `${pagePointer.id}-${isMobileView}-${controllerDataId}`
        const cached = controllerIdCache[cacheKey]
        if (cached && dal.has(cached)) {
            return cached
        }

        const predicate = (comp: DalValue) => relationships.getIdFromRef(comp?.dataQuery) === controllerDataId

        const viewModePagePointer = isMobileView ? pointers.components.getMobilePointer(pagePointer) : pointers.components.getDesktopPointer(pagePointer)

        let result = pointers.components.findComponentInPage(viewModePagePointer, isMobileView, predicate)
        if (!result) {
            result = pointers.full.components.findComponentInPage(viewModePagePointer, isMobileView, predicate)
        }
        if (result) {
            controllerIdCache[cacheKey] = result
        }

        return result
    }

    return {
        platform: {
            getControllerInPageByDataId
        }
    }
}

const createExtension = (): Extension => ({
    name: 'platformWithViewer',
    dependencies: new Set(['relationships', 'structure', 'page', 'fullToDisplay']),
    createPointersMethods
})

export {createExtension}
