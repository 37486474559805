import type {Data} from '@wix/document-services-types'
import _ from 'lodash'
import {VIEW_MODES, PAGE_DATA_TYPES, MODELS_TYPES, POINTER_TYPES} from './constants/constants'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import {
    setRenderFlags,
    setSingleRenderFlag,
    setRealTimeConfig,
    setRuntime,
    setCurrentLanguage,
    setGhostStructure,
    setGhostControllers,
    setBlocksPreviewData,
    setCacheKillerCounter,
    updateRendererModel,
    updateDocumentServicesModel,
    setStructure
} from './customSetters'

/**
 * @returns {function}
 */
const getSetterForTypeFn = (viewer: ViewerAPI): Function => {
    const dataSetters = _.mapValues(PAGE_DATA_TYPES, type => _.partial(viewer.data.updateData, type as keyof Data))
    const structureSetters = _.mapValues(VIEW_MODES, () => _.partial(setStructure, viewer))
    const typesToSetterMap = _.assign(
        {
            [MODELS_TYPES.DOCUMENT_SERVICES_MODEL]: _.partial(updateDocumentServicesModel, viewer),
            [MODELS_TYPES.RENDERER_MODEL]: _.partial(updateRendererModel, viewer),
            [POINTER_TYPES.PAGES_PLATFORM_APPLICATIONS]: _.partial(updateRendererModel, viewer),
            [POINTER_TYPES.PLATFORM]: viewer.platformDsModel.updatePlatformDsModel,
            [POINTER_TYPES.MULTILINGUAL_TRANSLATIONS]: viewer.data.updateTranslationDataItem,
            [POINTER_TYPES.WIX_CODE]: viewer.wixCode.setWixCodeDsModel,
            [POINTER_TYPES.SVG_SHAPES]: viewer.svg.addSvgShape,
            [POINTER_TYPES.RENDER_FLAGS]: _.partial(setRenderFlags, viewer.renderFlags.setRenderFlag, viewer.renderFlags.getRenderFlags),
            [POINTER_TYPES.ACTIVE_VARIANT]: viewer.variants.setVariantForComp,
            [POINTER_TYPES.RENDER_REAL_TIME_CONFIG_POINTER]: _.partial(setRealTimeConfig, viewer),
            [POINTER_TYPES.RUNTIME]: _.partial(setRuntime, viewer),
            [POINTER_TYPES.MULTILINGUAL]: _.partial(setCurrentLanguage, viewer),
            [POINTER_TYPES.GHOST_STRUCTURE]: _.partial(setGhostStructure, viewer),
            [POINTER_TYPES.GHOST_CONTROLLERS]: _.partial(setGhostControllers, viewer),
            [POINTER_TYPES.BLOCKS]: _.partial(setBlocksPreviewData, viewer),
            [POINTER_TYPES.DISPLAYED_ONLY_COMPONENTS]: viewer.displayedOnlyComponents.setRepeaterToTemplateItem
        },
        dataSetters,
        structureSetters
    )
    return (type: string) => typesToSetterMap[type]
}

const getSetterForInnerPath = (viewer: ViewerAPI) => {
    const specificInnerItemSetters = {
        [POINTER_TYPES.CUSTOM_ELEMENTS]: _.partial(setCacheKillerCounter, viewer),
        [POINTER_TYPES.RENDER_FLAGS]: _.partial(setSingleRenderFlag, viewer.renderFlags.setRenderFlag, viewer.renderFlags.getRenderFlags)
    }

    return (type: string) => specificInnerItemSetters[type]
}
export {getSetterForTypeFn, getSetterForInnerPath}
