define(['lodash', '@wix/santa-ds-libs/src/coreUtils', 'documentServices/theme/theme'], function (_, coreUtils, theme) {
    'use strict'
    const {vectorImageDefaults, convertStyleToDesignData, convertStyle, convertData, convertProperties, convertStructure} = coreUtils.vectorImageConversionUtils

    function convertModes(compDefinitionPrototype) {
        const modesOverrides = _.get(compDefinitionPrototype, ['modes', 'overrides'])
        _.forEach(modesOverrides, function (override) {
            if (override.style) {
                override.design = {}
                convertStyleToDesignData(override.design, override.style, vectorImageDefaults)
                _.unset(override, 'style')
            }
        })
    }

    function convertRepeaters(compDefinitionPrototype) {
        if (compDefinitionPrototype.data.type === 'RepeatedData') {
            const repeatedDesign = {
                type: 'RepeatedData',
                original: compDefinitionPrototype.design,
                overrides: {}
            }
            _.forEach(compDefinitionPrototype.data.overrides, (override, key) => {
                repeatedDesign.overrides[key] = _.clone(compDefinitionPrototype.design)
            })
            compDefinitionPrototype.design = repeatedDesign
        }
    }

    return {
        beforeAdd(ps, compToAddPointer, containerPointer, compDefinitionPrototype) {
            compDefinitionPrototype.design = compDefinitionPrototype.design || {type: 'VectorImageDesignData'}

            const svgId = compDefinitionPrototype.skin

            // If style is a string so the style was already added to the document and we need to
            // manually update it, else it is an object and will be added with the component
            const pagePtr = ps.pointers.components.getPageOfComponent(containerPointer) || ps.pointers.full.components.getPageOfComponent(containerPointer)
            const pageId = pagePtr.id
            const isStyleInDocument = _.isString(compDefinitionPrototype.style)

            const style = isStyleInDocument ? theme.styles.get(ps, compDefinitionPrototype.style, pageId) : compDefinitionPrototype.style
            const {data, design, props: properties, mobileStructure} = compDefinitionPrototype

            convertStructure(compDefinitionPrototype, vectorImageDefaults)
            convertStyle(style, vectorImageDefaults)
            convertStyleToDesignData(design, style, vectorImageDefaults)
            convertData(data, vectorImageDefaults, svgId)
            convertProperties(properties, vectorImageDefaults)

            if (mobileStructure?.props) {
                convertProperties(mobileStructure.props, vectorImageDefaults)
            }

            convertModes(compDefinitionPrototype)
            convertRepeaters(compDefinitionPrototype)

            if (isStyleInDocument) {
                theme.styles.update(ps, compDefinitionPrototype.style, style, pageId)
            }
        }
    }
})
