define(['experiment', 'documentServices/platform/common/constants', 'documentServices/branches/siteBranchesUtils'], function (
    experiment,
    constants,
    siteBranchesUtils
) {
    const shouldAvoidRevoking = async ({ps, immutable, snapshotDal}, {intent = null} = {}) => {
        if (experiment.isOpen('dm_avoidRevokingApps')) {
            return true
        }

        if (intent !== constants.Intents.USER_ACTION) {
            const hasBranches = await siteBranchesUtils.doesHaveBranches({ps, immutable, snapshotDal})

            return hasBranches
        }

        return false
    }
    return {
        shouldAvoidRevoking
    }
})
