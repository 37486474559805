define([
    'lodash',
    'documentServices/component/component',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/dataModel/dataModel',
    'documentServices/hooks/hooks',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/connections/connections',
    'documentServices/constants/constants',
    '@wix/santa-core-utils',
    'documentServices/tpa/utils/tpaUtils'
], function (_, component, clientSpecMapService, dataModel, hooks, componentDetectorAPI, connections, constants, santaCoreUtils, tpaUtils) {
    'use strict'

    function addDependantApps(ps, apps) {
        const refreshedAppsDataWithBuilderWidgets = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
            _.filter(csm, appData => _.includes(apps, appData.appDefinitionId) && appHasBuilderWidgets(appData))
        )
        if (_.includes(apps, 'dataBinding') || !_.isEmpty(refreshedAppsDataWithBuilderWidgets)) {
            const wixCodeAppDefId = getWixCodeAppDefIdIfExists(ps)
            if (wixCodeAppDefId && !_.includes(apps, wixCodeAppDefId)) {
                apps.push(wixCodeAppDefId)
            }
        }
        return apps
    }

    function getWixCodeAppDefIdIfExists(ps) {
        const wixCodeAppData = clientSpecMapService.filterAppsDataByType(ps, 'siteextension')[0]
        return wixCodeAppData ? wixCodeAppData.appDefinitionId : null
    }

    function hasAppsWithViewerScript(ps) {
        return clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
            _.some(csm, appData => _.get(appData, ['appFields', 'platform', 'viewerScriptUrl']) || _.get(appData, 'type') === 'siteextension')
        )
    }

    function appHasBuilderWidgets(appData) {
        const widgets = _.values(_.get(appData, 'widgets', {}))
        return _.some(widgets, widget => _.has(widget, ['componentFields', 'appStudioFields']))
    }

    function addResetRuntimeOverridesForRepeatedItemTemplate(ps, componentPointer) {
        const hasRepeaterAncestor = component
            .getAncestorsFromFull(ps, componentPointer)
            .some(ancestor => component.getType(ps, ancestor) === 'wysiwyg.viewer.components.Repeater')

        if (hasRepeaterAncestor) {
            return {resetRuntime: true}
        }
    }

    function getCompsIdsToReset(ps, componentPointer) {
        if (component.getType(ps, componentPointer) === 'wysiwyg.viewer.components.Repeater') {
            const compsIdsToReset = _.map(component.getChildren(ps, componentPointer, true), 'id').concat([componentPointer.id])
            return {compsIdsToReset, resetRuntime: true}
        }
        const compAncestors = component.getAncestors(ps, componentPointer)
        const repeaterAncestorPointer = compAncestors.find(
            ancestor =>
                component.isExist(ps, ancestor) && // ref component ancestor on mobile is not available until mobile editor create it
                component.getType(ps, ancestor) === 'wysiwyg.viewer.components.Repeater'
        )
        /*can be temple item or repeated item*/
        if (repeaterAncestorPointer) {
            const templateId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(componentPointer.id)
            const repeaterData = dataModel.getRuntimeDataItem(ps, repeaterAncestorPointer)
            if (repeaterData) {
                const items = _.map(repeaterData.items, itemId => santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(templateId, itemId))
                return {compsIdsToReset: items.concat(repeaterAncestorPointer.id), resetRuntime: true}
            }
        }
        return {compsIdsToReset: [componentPointer.id], resetRuntime: true}
    }

    function getAppsPartsToRefresh(ps, apps) {
        const controllersToRefresh = apps.reduce(
            (flatControllers, appDefinitionId) =>
                flatControllers.concat(
                    getAppControllersOnPage(ps, appDefinitionId, ps.siteAPI.getPrimaryPageId()),
                    getAppControllersOnPage(ps, appDefinitionId, constants.MASTER_PAGE_ID)
                ),
            []
        )
        const compsIdsToReset = controllersToRefresh.reduce((flatComponents, controllerRef) => {
            if (tpaUtils.isTpaByCompType(component.getType(ps, controllerRef))) {
                flatComponents.push(controllerRef)
            }
            return flatComponents.concat(_.map(getControllerConnectedComponents(ps, controllerRef), 'id'))
        }, [])

        const controllersDataItemsToRefresh = _.map(controllersToRefresh, componentPointer => {
            const controllerData = dataModel.getDataItem(ps, componentPointer)
            return controllerData.id
        })
        return {apps, compsIdsToReset, controllersToRefresh: controllersDataItemsToRefresh, resetRuntime: true}
    }

    function getAppControllersOnPage(ps, appDefId, pageId) {
        const isControllerOnPage = compRef => {
            const compType = component.getType(ps, compRef)
            const isController = connections.isControllerType(compType) || connections.isOOIController(compType)
            const {appDefinitionId, applicationId} = component.data.get(ps, compRef) || {}
            return isController && (appDefinitionId === appDefId || applicationId === appDefId)
        }
        return componentDetectorAPI.getAllComponents(ps, pageId, compRef => isControllerOnPage(compRef))
    }

    function getControllerConnectedComponents(ps, controllerRef) {
        return connections.getConnectedComponents(ps, controllerRef)
    }

    return {
        addDependantApps,
        getWixCodeAppDefIdIfExists,
        hasAppsWithViewerScript,
        optionsModifierSelfRefresh: getAppsPartsToRefresh,
        optionsModifiersAllApps: {
            [hooks.HOOKS.DATA.UPDATE_AFTER]: getCompsIdsToReset,
            [hooks.HOOKS.PROPERTIES.UPDATE_AFTER]: getCompsIdsToReset,
            [hooks.HOOKS.CONNECTION.AFTER_DISCONNECT]: getCompsIdsToReset,
            [hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS]: getCompsIdsToReset
        },
        optionsModifiers: {
            dataBinding: addResetRuntimeOverridesForRepeatedItemTemplate
        }
    }
})
