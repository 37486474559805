define(['lodash', 'documentServices/utils/runtimeConfig'], function (_, runtimeConfig) {
    'use strict'

    const createFromImmutable = pagesDataImmutable => {
        const getter = (data, propName) => data.get(propName)
        const accept = visitor =>
            pagesDataImmutable.forEach(page => {
                page.getIn(['data', 'document_data']).forEach(data => visitor(getter, data))
            })
        return {accept}
    }

    const createFromPrivateServices = ps => {
        if (runtimeConfig.isSanta(ps)) {
            return createFromImmutable(ps.dal.full.immutable.getByPath(['pagesData']))
        }

        const getter = (pointer, propName) => ps.dal.get(ps.pointers.getInnerPointer(pointer, propName))
        const accept = visitor => {
            const allDataPointers = ps.pointers.data.getItemsWithPredicate('data', {}, null)
            allDataPointers.forEach(pointer => visitor(getter, pointer))
        }
        return {accept}
    }

    const createFromSnapshotDiff = diff => {
        const getter = (value, propName) => value[propName]
        const accept = visitor => {
            _.forEach(diff.data, value => {
                if (value) {
                    visitor(getter, value)
                }
            })
        }
        return {accept}
    }

    const createFromSnapshotDal = (ids, snapshotDal) => {
        const getter = (value, propName) => value[propName]
        const accept = visitor => {
            _.forEach(ids, id => {
                const value = snapshotDal.getValue({type: 'data', id})
                if (value) {
                    visitor(getter, value)
                }
            })
        }
        return {accept}
    }

    const createFromJS = obj => {
        const getter = (value, propName) => value[propName]
        const accept = visitor => {
            _.forEach(obj, value => visitor(getter, value))
        }
        return {accept}
    }

    const createEmpty = () => ({accept: () => {}})

    return {
        createFromImmutable,
        createFromPrivateServices,
        createFromSnapshotDiff,
        createFromSnapshotDal,
        createFromJS,
        createEmpty
    }
})
