import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const IS_BROWSER = typeof window !== 'undefined'
const IS_PREVIEW = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isEdited')
const IS_QA_DEBUG = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isqa')
const MAX_BI_CALLS_KEPT = 1000

if (IS_QA_DEBUG && IS_PREVIEW) {
    // @ts-ignore
    window._biCalls = []
}

const defaultOptions = {
    biUrl: '//frog.wixpress.com',
    adapter: '',
    params: {}
}

function readCookie(name) {
    if (!IS_BROWSER) {
        return null
    }

    const regex = new RegExp(`^\\s*${name}=\\s*(.*?)\\s*$`)
    let cookies = []

    try {
        cookies = window.document.cookie.split(';')
    } catch (e) {
        return null
    }

    for (let i = 0; i < cookies.length; i++) {
        const match = cookies[i].match(regex)

        if (match) {
            return match[1]
        }
    }

    return null
}

function sendBI(siteData, host, adapter, queryString) {
    let url = warmupUtilsLib.urlUtils.joinURL(host, adapter)
    if (queryString) {
        url += `?${queryString}`
    }
    if (siteData.wixBiSession.sendBeacon) {
        siteData.wixBiSession.sendBeacon(url)
    }
    if (IS_QA_DEBUG && IS_PREVIEW) {
        logBICallToWindow(url)
    }
}

function logBICallToWindow(url) {
    if (IS_BROWSER) {
        // @ts-ignore
        const biCalls = window._biCalls
        if (biCalls) {
            const length = biCalls.push(warmupUtilsLib.urlUtils.parseUrl(url))
            if (length > MAX_BI_CALLS_KEPT) {
                biCalls.splice(0, length >> 2) // Delete a fourth
            }
        }
    }
}

function getCurrentTimeStamp(siteData, options) {
    // TODO: get mainLoaded value through DS and not through parent
    // @ts-ignore
    let start = IS_PREVIEW && options.adapter === 'editor' ? window.parent.mainLoaded : 0
    start = start || siteData.wixBiSession.initialTimestamp || siteData.wixBiSession.mainLoaded
    return _.now() - start
}

function getConsentPolicy() {
    if (!IS_BROWSER) {
        return null
    }

    // @ts-ignore
    const policyDetails = window.consentPolicyManager && window.consentPolicyManager.getCurrentConsentPolicy()
    return policyDetails && policyDetails.policy
}

function getBrand() {
    if (!IS_BROWSER) {
        return 'wix'
    }

    const brand = window.commonConfig && window.commonConfig.brand

    return brand || 'wix'
}

function getBsi() {
    if (!IS_BROWSER) {
        return null
    }

    // @ts-ignore
    if (window.bsiManager && typeof window.bsiManager.getBsi === 'function') {
        // @ts-ignore
        window.bsiManager.getBsi()
    }

    return readCookie('bSession')
}

function report(siteData, options) {
    const policy = getConsentPolicy()

    if (policy && (!policy.analytics || !policy.functional)) {
        return
    }

    const biUrl = _.get(siteData, ['serviceTopology', 'biServerUrl'])
    if (biUrl) {
        defaultOptions.biUrl = biUrl
    }
    _.defaults(options, defaultOptions)
    const queryString = _.isString(options.queryString)
        ? options.queryString
        : warmupUtilsLib.urlUtils.toQueryString(
              _.defaults(options.params, {
                  ts: getCurrentTimeStamp(siteData, options),
                  rid: siteData.wixBiSession.requestId,
                  bsi: getBsi(),
                  _brandId: getBrand()
              })
          )
    sendBI(siteData, options.biUrl, options.adapter, queryString)
}

export default {
    report
}
