define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/wixapps/utils/classicsUtils',
    'documentServices/componentsMetaData/metaDataUtils',
    'wixappsClassics',
    'documentServices/wixapps/services/blogUtils',
    '@wix/santa-core-utils',
    'documentServices/utils/utils'
], function (_, consts, dataModel, classicsUtils, metaDataUtils, wixappsClassics, blogUtils, coreUtils, dsUtils) {
    'use strict'

    const {blogAppPartNames} = coreUtils

    function getResizableSides(ps, compPointer) {
        let resizableSides = [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT]
        const data = dataModel.getDataItem(ps, compPointer)
        const packageName = classicsUtils.getPackageName(ps, data.appInnerID)
        const partDefinition = classicsUtils.getAppPartDefinition(ps, packageName, data.appPartName)
        if (wixappsClassics.descriptorUtils.doesAllowHeightResize(partDefinition, data.viewName)) {
            resizableSides = resizableSides.concat([consts.RESIZE_SIDES.TOP, consts.RESIZE_SIDES.BOTTOM])
        }
        return resizableSides
    }

    function isMasterPageScope(ps, comp) {
        return ps.pointers.components.isMasterPage(comp) || ps.pointers.components.isInMasterPage(comp)
    }

    function isChangingScope(ps, compPointer, potentialContainer) {
        return isMasterPageScope(ps, compPointer) !== isMasterPageScope(ps, potentialContainer)
    }

    function isCompInList(ps, compPointer, list) {
        const compData = dataModel.getDataItem(ps, compPointer)
        const packageName = classicsUtils.getPackageName(ps, compData.appInnerID)

        return packageName === 'blog' && _.includes(list, classicsUtils.getAppPartRole(ps, packageName, compData.appPartName))
    }

    function isAppPartPageFixed(ps, compPointer) {
        return isCompInList(ps, compPointer, ['BLOG_FEED', 'SINGLE_POST', 'RELATED_POSTS'])
    }

    function isContainable(ps, compPointer, potentialContainer) {
        return potentialContainer && !(isChangingScope(ps, compPointer, potentialContainer) && isAppPartPageFixed(ps, compPointer))
    }

    function isStretchable(ps, compPointer) {
        const strechableComps = ['POST_GALLERY', 'BLOG_FEED', 'CUSTOM_FEED']
        return isCompInList(ps, compPointer, strechableComps.concat(['RELATED_POSTS']))
    }

    function containableByStructure(ps, comp, potentialContainerPointer) {
        const isOnPopup = !metaDataUtils.notContainableByPopup(ps, comp, potentialContainerPointer)

        const pagePointer = ps.pointers.components.getPageOfComponent(potentialContainerPointer)
        const isSinglePostPage = blogUtils.isSinglePost(ps, pagePointer.id)
        if (comp.data.appPartName === 'ea63bc0f-c09f-470c-ac9e-2a408b499f22') {
            // is singlePost Part
            const {type, appPartName} = comp.data
            return isSinglePostPage && !ps.pointers.data.getDataItemWithPredicate({type, appPartName}, pagePointer.id)
        }

        const isRelatedPostComponent = comp.data.appPartName === blogAppPartNames.RELATED_POSTS

        return !(isOnPopup || (isRelatedPostComponent && !isSinglePostPage))
    }

    function getLayoutLimits(ps, compPointer) {
        const {appPartName} = dataModel.getDataItem(ps, compPointer)

        if (appPartName === blogAppPartNames.HERO_IMAGE) {
            return {minHeight: consts.HERO_IMAGE.MIN_HEIGHT}
        }

        return {}
    }

    function getFixedSize(ps, component, pageId) {
        const compData = dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(component.dataQuery), pageId)
        const appPartName = _.get(compData, 'appPartName')
        if (appPartName === blogAppPartNames.POSTS_GALLERY || appPartName === blogAppPartNames.RELATED_POSTS) {
            return {height: component.layout.height}
        }

        return {}
    }

    return {
        layoutLimits: getLayoutLimits,
        resizableSides: getResizableSides,
        containable: isContainable,
        containableByStructure,
        canBeStretched: isStretchable,
        mobileConversionConfig: {
            fixedSize: getFixedSize
        }
    }
})
