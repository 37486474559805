import * as galleriesCommon from '../../../../_internal/galleriesCommon'
import {matrixCalculations} from '@wix/santa-ds-libs/src/coreUtils'

function setCompLayoutOnDataChange(compId, changeObject, dataBeforeChange) {
    if (changeObject.value.type !== 'ImageList') {
        return
    }
    const runtimeDal = this.siteAPI.getRuntimeDal()
    const compData = runtimeDal.getCompData(compId)
    const compProp = runtimeDal.getCompProps(compId)
    const compLayout = runtimeDal.getCompLayout(compId)
    const compStructure = runtimeDal.getCompStructure(compId)
    const itemsBeforeChange = dataBeforeChange.items
    const itemsAfterChange = compData.items
    const galleryHeight = compLayout.height
    const numberOfRows = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, itemsBeforeChange.length)
    const newNumberOfRows = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, itemsAfterChange.length)
    const newGalleryHeight = Math.max(
        galleriesCommon.MIN_GALLERY_HEIGHT,
        galleriesCommon.getGalleryHeight(numberOfRows, newNumberOfRows, compProp.margin, compStructure.skin, galleryHeight)
    )
    runtimeDal.updateCompLayout(compId, {height: newGalleryHeight})
}

export default {
    setCompLayoutOnDataChange
}
