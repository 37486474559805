define([
    'lodash',
    'documentServices/dataModel/dataModel',
    'documentServices/constants/constants',
    'documentServices/componentsMetaData/components/baseInputMetadata'
], function (_, dataModel, constants, baseInputMetadata) {
    'use strict'

    const isWithLabel = (ps, componentPointer) => {
        const compData = dataModel.getDataItem(ps, componentPointer)
        return !!compData.label
    }

    const metadata = {
        mobileConversionConfig: {
            category: 'graphic',
            inlineWhenGrouped: true
        },
        resizableSides(ps, componentPointer) {
            return isWithLabel(ps, componentPointer) ? [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT] : []
        },
        layoutLimits(ps, componentPointer) {
            const layoutLimits = {
                minWidth: 60
            }
            if (!isWithLabel(ps, componentPointer)) {
                _.assign(layoutLimits, {
                    minWidth: 12,
                    minHeight: 12,
                    aspectRatio: 1
                })
            }
            return layoutLimits
        }
    }

    return _.assign({}, baseInputMetadata, metadata)
})
