define(['documentServices/component/component'], function (component) {
    'use strict'

    return function (ps, compPointer, layout) {
        let siteWidth, siteCenterX, menuOrientation
        if (layout.docked) {
            menuOrientation = layout.docked.right ? 'right' : 'left'
        } else {
            siteWidth = ps.siteAPI.getSiteWidth()
            siteCenterX = siteWidth / 2
            menuOrientation = layout.x + layout.width / 2 > siteCenterX ? 'right' : 'left' // eslint-disable-line no-mixed-operators
        }
        const propertiesToUpdate = {orientation: menuOrientation}
        propertiesToUpdate.itemsAlignment = menuOrientation
        component.properties.update(ps, compPointer, propertiesToUpdate)
    }
})
