define([
    'lodash',
    'documentServices/hooks/hooks',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/santa-core-utils',
    'documentServices/component/componentModes',
    'documentServices/mobileConversion/modules/structuresComparer',
    'documentServices/constants/constants',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, hooks, coreUtils, santaCoreUtils, componentModes, structuresComparer, constants, extensionsAPI) {
    'use strict'

    function initialize(ps) {
        hooks.unregisterHooks([hooks.HOOKS.PUBLISH.BEFORE])
        hooks.registerHook(hooks.HOOKS.PUBLISH.BEFORE, () => resetCommittedMobilePages(ps))
        const mobileMergeSnapshot = ps.dal.full.immutable.getLastSnapshotByTagName(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
        if (!mobileMergeSnapshot) {
            ps.dal.takeSnapshot(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
        }
    }

    function resetCommittedMobilePages(ps) {
        const committedMobilePagesPointer = ps.pointers.general.getCommittedMobilePages()

        ps.dal.set(committedMobilePagesPointer, null)
    }

    function getChangedPagesPointers(ps) {
        if (!structuresComparer.hasMobileStructure(ps)) {
            return ps.pointers.page.getNonDeletedPagesPointers(true)
        }
        return extensionsAPI.pages.getNonDeletedChangedPagePointersSinceLastSnapshot(ps, constants.SNAPSHOT_TAGS.MOBILE_MERGE)
    }

    function getChangedPages(ps) {
        const pagePointers = getChangedPagesPointers(ps)
        return _.transform(
            pagePointers,
            (acc, pagePointer) => {
                const pageId = pagePointer.id
                const pageComponent = getPage(ps, pageId)
                _.set(acc, pageId, pageComponent)
            },
            {}
        )
    }

    function getAggregatedPages(ps) {
        const desktopPages = getChangedPages(ps)
        if (_.isEmpty(desktopPages)) {
            return null
        }
        const committedMobilePagesPointer = ps.pointers.general.getCommittedMobilePages()
        const committedMobilePages = ps.dal.get(committedMobilePagesPointer) || {}

        _.forOwn(desktopPages, (page, pageId) => {
            if (!committedMobilePages[pageId]) {
                const mobilePage = santaCoreUtils.objectUtils.cloneDeep(page)
                _.set(mobilePage, mobilePage.components ? 'components' : 'children', page.mobileComponents || [])
                committedMobilePages[pageId] = mobilePage
            }
        })
        ps.dal.set(committedMobilePagesPointer, committedMobilePages)
        return {
            desktop: desktopPages,
            mobile: getAffectedMobilePages(committedMobilePages, desktopPages)
        }
    }

    function getAffectedMobilePages(committedMobilePages, desktopPages) {
        const changedPageIds = _.keys(desktopPages)
        return _.pickBy(committedMobilePages, (page, pageId) => _.includes(changedPageIds, pageId))
    }

    function getPage(ps, pageId) {
        const pagePointer = ps.pointers.page.getPagePointer(pageId)
        const pageActiveModesMap = componentModes.getMobileActiveModesMap(ps, pageId)
        const activeModesMap = _.set({}, pageId, pageActiveModesMap)
        return coreUtils.fullToDisplayedJson.getDisplayedJson(ps.dal.full.get(pagePointer), activeModesMap, pageId).structure
    }

    function commitResults(ps) {
        resetCommittedMobilePages(ps)
        ps.dal.takeSnapshot(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
    }

    function getCommittedMobileComponent(ps, pageId, mobileCompPointer) {
        if (!pageId || !mobileCompPointer) {
            return null
        }
        const committedMobilePagesPointer = ps.pointers.general.getCommittedMobilePages()
        const committedMobilePages = ps.dal.get(committedMobilePagesPointer)
        const mobilePage = _.get(committedMobilePages, pageId)
        if (_.isEmpty(mobilePage)) {
            return ps.siteAPI.getDeepStructure ? ps.siteAPI.getDeepStructure(mobileCompPointer) : ps.dal.full.get(mobileCompPointer)
        }
        return santaCoreUtils.dataUtils.findCompInStructure(mobilePage, false, comp => comp.id === mobileCompPointer.id)
    }

    return {
        initialize,
        resetCommittedMobilePages,
        getAggregatedPages,
        getChangedPagesPointers,
        getPage,
        commitResults,
        getCommittedMobileComponent
    }
})
