import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

function translate(lang, key) {
    const valueInSelectedLang = coreUtils.translationsLoader.isLoaded(lang) && coreUtils.translationsLoader.getTranslation('blogTranslations', lang, key)
    const valueInDefaultLang = coreUtils.translationsLoader.getTranslation('blogTranslations', 'en', key)
    return valueInSelectedLang || valueInDefaultLang || key
}

export default {
    translate
}
