define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/utils/multilingual',
    'documentServices/constants/constants',
    '@wix/document-manager-utils',
    'experiment'
], function (_, santaCoreUtils, mlUtils, constants, documentManagerUtils, experiment) {
    'use strict'

    const {removePrefix, ReportableError} = documentManagerUtils
    const masterPage = 'wysiwyg.viewer.components.WSiteStructure'
    const repeaterType = 'wysiwyg.viewer.components.Repeater'

    const REF_PREFIX = '#'

    const SPECIAL_COMP_TYPES = {
        masterPage
    }

    const {DATA_TYPES, COMP_DATA_QUERY_KEYS} = constants

    /**
     * @param {Pointer} componentPointer
     * @return {string}
     */
    function pointerToString(componentPointer) {
        if (!componentPointer) {
            return '(null)'
        }
        let s = `type: ${componentPointer.type} id: ${componentPointer.id}`
        if ('innerPath' in componentPointer) {
            s += ` inner: ${componentPointer.innerPath}`
        }
        return s
    }

    let prevPointer
    let prevType

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @return {any}
     */
    function getComponentType(ps, componentPointer) {
        if (componentPointer) {
            const specialCompType = SPECIAL_COMP_TYPES[componentPointer.id]
            if (specialCompType) {
                return specialCompType
            }

            if (componentPointer === prevPointer) {
                return prevType
            }

            const typePointer = ps.pointers.getInnerPointer(componentPointer, 'componentType')
            const result = ps.dal.full.get(typePointer) || ps.dal.get(typePointer)
            if (result || ps.dal.full.isExist(componentPointer)) {
                prevPointer = componentPointer
                prevType = result
                return result
            }
        }
        throw new ReportableError({
            errorType: 'nonExistingCompPointer',
            message: `non existing component pointer: ${pointerToString(componentPointer)}`
        })
    }

    function isLivePreviewActive(ps) {
        return experiment.isOpen('sv_livePreview') && ps.config.origin !== 'appBuilder'
    }

    function replaceRuntimeRefWithOriginal(ps, compPointer) {
        if (!ps || !compPointer || !isLivePreviewActive(ps)) {
            return compPointer
        }
        const itemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id)
        if (itemId) {
            if (isRepeaterRuntimeItem(ps, itemId, compPointer)) {
                const templateId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
                return {...compPointer, id: templateId}
            }
        }
        return compPointer
    }

    function getCompData(ps, compPointer) {
        const dataQueryPtr = ps.pointers.getInnerPointer(compPointer, COMP_DATA_QUERY_KEYS[DATA_TYPES.data])
        const dataQuery = stripHashIfExists(ps.dal.get(dataQueryPtr))

        if (dataQuery) {
            const originalLanguage = mlUtils.getLanguageByUseOriginal(ps, true)

            const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)

            const dataItemPtr = ps.pointers.data.getItem(DATA_TYPES.data, dataQuery, pagePointer.id)
            //This is a temp fix for DM-3181 should be removed when issue is really fixed
            dataItemPtr.useLanguage = originalLanguage

            return ps.dal.full.get(dataItemPtr)
        }
    }

    /**
     * @param {ps} ps
     * @param {string} itemId
     * @param {Pointer} compPointer
     * @returns {*}
     */
    function isRepeaterRuntimeItem(ps, itemId, compPointer) {
        const repeaterPointer = ps.pointers.components.getAncestorByPredicate(
            compPointer,
            parentPointer => getComponentType(ps, parentPointer) === repeaterType
        )

        if (!repeaterPointer) {
            return false
        }

        const staticItems = _.get(getCompData(ps, repeaterPointer), 'items', [])

        return !_.includes(staticItems, itemId)
    }

    /**
     * @param {string} input
     * @param {boolean} strict - if true, will return null incase input is not a string (default true)
     * @return {string|null}
     */
    function stripHashIfExists(input, strict = true) {
        const defaultValue = strict ? null : input

        return _.isString(input) ? removePrefix(input, REF_PREFIX) : defaultValue
    }

    /**
     * @param {string[]} keys
     * @param {object} object - object to modify
     * @param {function} modifyFunc - modify function to run on the value
     * @return {object} - modified object according to specific keys
     */

    function mapSpecificValues(keys, object, modifyFunc) {
        const changes = _(object).pick(keys).mapValues(modifyFunc).value()
        return _.assign({}, object, changes)
    }

    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    function isGuid(value) {
        return guidRegex.test(value)
    }

    return {
        YES: 'yes',
        NO: 'no',
        DONT_CARE: "don't care",

        stripHashIfExists,
        getComponentType,
        replaceRuntimeRefWithOriginal,
        mapSpecificValues,
        /**
         * @param {string} type
         * @param {string} id
         * @param {string[]} [innerPath]
         * @returns {Pointer}
         */
        getPointer: (type, id, innerPath) => {
            if (innerPath) {
                if (typeof innerPath === 'string') {
                    innerPath = [innerPath]
                }
                return {type, id, innerPath}
            }
            return {type, id}
        },
        isGuid
    }
})
