import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import WixCodeWidgetAspect from './WixCodeWidgetAspect'
import wixCodeChangeHooksAspect from './wixCodeChangeHooksAspect'
import wixCodeMessageQueueAspect from './wixCodeMessageQueueAspect'
import wixCodeMessageChannelAspect from './wixCodeMessageChannelAspect'

const {siteAspectsRegistry} = componentsCore

siteAspectsRegistry.registerSiteAspect('wixCodeWidgetAspect', WixCodeWidgetAspect)
siteAspectsRegistry.registerSiteAspect('wixCodeChangeHooksAspect', wixCodeChangeHooksAspect)
siteAspectsRegistry.registerSiteAspect('wixCodeMessageQueueAspect', wixCodeMessageQueueAspect)
siteAspectsRegistry.registerSiteAspect('wixCodeMessageChannelAspect', wixCodeMessageChannelAspect)
