import type {PublicMethodUtils, ViewerLibrary} from '../../publicAPI/apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const breakpoints = viewerLibrary.get('documentServices/responsiveLayout/breakpoints')

    return {
        initMethod: breakpoints.init,
        methods: {
            breakpoints: {
                update: publicMethodUtils.defineDataManipulationAction(breakpoints.update),
                get: publicMethodUtils.defineGetter(breakpoints.get),
                remove: publicMethodUtils.defineDataManipulationAction(breakpoints.remove),
                wasBreakpointsMigratedToVariants: publicMethodUtils.defineGetter(breakpoints.wasBreakpointsMigratedToVariants)
            }
        }
    }
}
