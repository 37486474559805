import $ from 'zepto'
import layout from '../util/layout'
import popupContainerDocking from '../util/popupContainerDocking'
import screenWidthContainerLayout from './screenWidthLayouts/screenWidthContainerLayout'
import balataLayout from './balataLayout'

function measurePopupContainer(id, measureMap, nodesMap, structureInfo, {siteWidth, siteX}) {
    screenWidthContainerLayout.measureContainer(id, measureMap, nodesMap)
    popupContainerDocking.measure(id, measureMap, nodesMap, structureInfo, {siteWidth})

    //the size and position of the root
    const compProps = $(nodesMap[id]).data('docking')
    const isFullWidthPopup = compProps.alignmentType === 'fullWidth'
    const popupContainerRootID = `ROOT_${structureInfo.rootId}`
    measureMap.width[popupContainerRootID] = measureMap.width[id]
    measureMap.left[popupContainerRootID] = isFullWidthPopup ? 0 : Math.floor(measureMap.left[id] - siteX)
}

function patchPopupContainer(id, patchers, measureMap, structureInfo, siteData) {
    popupContainerDocking.patch(id, patchers, measureMap, structureInfo, siteData)
}

layout.registerMeasureChildrenFirst('wysiwyg.viewer.components.PopupContainer', true)
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.PopupContainer', [['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE))
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.PopupContainer')
layout.registerCustomMeasure('wysiwyg.viewer.components.PopupContainer', measurePopupContainer)
layout.registerPatcher('wysiwyg.viewer.components.PopupContainer', patchPopupContainer)
