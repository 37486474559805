define(['lodash'], function (_) {
    'use strict'

    const getRangeSize = range => range[1] - range[0] + 1

    const swapGroups = (arr, firstGroupRange, secondGroupRange) => {
        const firstGroupStart = firstGroupRange[0]
        const firstGroupEnd = firstGroupRange[1]
        const secondGroupStart = secondGroupRange[0]
        const secondGroupEnd = secondGroupRange[1]

        return [
            ...arr.slice(0, firstGroupStart),
            ...arr.slice(secondGroupStart, secondGroupEnd + 1),
            ...arr.slice(firstGroupEnd + 1, secondGroupStart),
            ...arr.slice(firstGroupStart, firstGroupEnd + 1),
            ...arr.slice(secondGroupEnd + 1)
        ]
    }

    const moveGroupToIndex = (arr, groupRange, to) => {
        const newArr = _.clone(arr)
        newArr.splice(to, 0, ...newArr.splice(groupRange[0], getRangeSize(groupRange)))

        return newArr
    }

    const moveGroupToEndOfArray = (arr, groupRang) => moveGroupToIndex(arr, groupRang, arr.length - getRangeSize(groupRang))

    const moveGroupToStartOfArray = (arr, groupRang) => moveGroupToIndex(arr, groupRang, 0)

    const findNext = (arr, predicate, index) => _.findIndex(arr, predicate, index + 1)

    const findPrev = (arr, predicate, index) => {
        const prevIndex = index - 1

        if (prevIndex === -1) {
            return prevIndex
        }

        return _.findLastIndex(arr, predicate, prevIndex)
    }

    const isInRange = (index, [startIndex, endIndex]) => index >= startIndex && index <= endIndex

    const isBeforeRange = (index, [startIndex]) => index < startIndex

    const getStartIndex = ([startIndex]) => startIndex

    const getEndIndex = ([, endIndex]) => endIndex

    const findNearestValidIndex = (array, index, validIndexRanges) => {
        validIndexRanges = _.isEmpty(validIndexRanges) ? [[0, array.length - 1]] : validIndexRanges
        const sortedRanges = _.sortBy(validIndexRanges, ([start]) => start)
        let i

        for (i = 0; i < sortedRanges.length; i++) {
            const currRange = sortedRanges[i]
            if (isInRange(index, currRange)) {
                return index
            }

            if (isBeforeRange(index, currRange)) {
                if (i === 0) {
                    return getStartIndex(currRange)
                }

                const prevRange = sortedRanges[i - 1]
                return getEndIndex(prevRange)
            }
        }

        return getEndIndex(_.last(sortedRanges))
    }

    return {
        swapGroups,
        moveGroupToIndex,
        moveGroupToEndOfArray,
        moveGroupToStartOfArray,
        findNearestValidIndex,
        findNext,
        findPrev
    }
})
