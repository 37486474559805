define([
    'lodash',
    'documentServices/variants/relationsUtils',
    'documentServices/utils/utils',
    'documentServices/namespaces/namespaces',
    'documentServices/triggers/triggers',
    'documentServices/constants/constants',
    'documentServices/variants/variants',
    'documentServices/refComponent/refComponent',
    './reactionsUtils',
    '@wix/document-manager-core',
    '@wix/document-manager-utils'
], (_, relationsUtils, dsUtils, namespaces, triggers, constants, variants, refComponent, reactionsUtils, dmCore, dmUtils) => {
    const {REACTIONS, VARIANTS, DATA_TYPES} = constants
    const {ReportableError} = dmUtils
    const {pointerUtils} = dmCore
    const REACTIONS_TYPE = REACTIONS.TYPE
    const REACTIONS_NAMESPACE = DATA_TYPES.reactions
    const reactionTypesWithState = _.map(REACTIONS.TYPES_WITH_STATE)
    const validReactionDataTypes = _.map(REACTIONS.VALID_TYPES)
    const reactionTypesWithoutState = _.difference(validReactionDataTypes, reactionTypesWithState)

    const convertReactionDataToRef = reactionDataItem => {
        const {type, state} = reactionDataItem
        if (state) {
            return {type, state: `#${state.id}`}
        }
        return {type}
    }

    /**
     * Adds a new reaction to the given trigger variant
     *
     * @param {ps} ps
     * @param {Pointer} targetCompWithVariants
     * @param {Pointer} triggerPointer
     * @param {{state: Pointer, type: string}} reactionsDataItem
     */
    const add = (ps, targetCompWithVariants, triggerPointer, reactionsDataItem) => {
        validateReaction(ps, reactionsDataItem, targetCompWithVariants)
        validateTrigger(ps, triggerPointer, targetCompWithVariants)

        const validatedReactionsDataItem = convertReactionDataToRef(reactionsDataItem)
        const validatedCompWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
        const reactionsData = namespaces.getNamespaceData(ps, validatedCompWithVariants, REACTIONS_NAMESPACE)
        const values = reactionsData ? reactionsData.values.concat([validatedReactionsDataItem]) : [validatedReactionsDataItem]

        namespaces.updateNamespaceData(ps, pointerUtils.getRepeatedItemPointerIfNeeded(validatedCompWithVariants), REACTIONS_NAMESPACE, {
            type: REACTIONS_TYPE,
            values
        })
    }

    const validateReaction = (ps, reactionsDataItem, targetCompWithVariants) => {
        const {type, state} = reactionsDataItem

        if (!validReactionDataTypes.includes(type)) {
            throw new ReportableError({message: 'Invalid reaction type found in one or more items of the reaction data', errorType: 'reactionValidation'})
        } else if (reactionTypesWithoutState.includes(type)) {
            if (state) {
                throw new ReportableError({message: "You can't provide a state with this reaction type", errorType: 'reactionValidation'})
            }
        } else if (reactionTypesWithState.includes(type)) {
            if (!state) {
                throw new ReportableError({message: 'You must provide a state as part of the reaction', errorType: 'reactionValidation'})
            }

            if (!ps.dal.isExist(state)) {
                throw new ReportableError({message: 'The provided state does not exist', errorType: 'reactionValidation'})
            }

            const {type: stateType, componentId} = ps.dal.get(state)

            if (stateType !== VARIANTS.TYPES.STATE) {
                throw new ReportableError({message: 'Invalid state in reactions data', errorType: 'reactionValidation'})
            }

            const templateCompPointer = refComponent.getTemplateCompPointer(ps, targetCompWithVariants)
            const ownPointer = templateCompPointer ? templateCompPointer : targetCompWithVariants
            const ownCompId = pointerUtils.getRepeatedItemPointerIfNeeded(ownPointer).id

            if (componentId !== ownCompId) {
                throw new ReportableError({message: "Cannot add a reaction to a component whose state doesn't belong to it", errorType: 'reactionValidation'})
            }
        }
    }

    /**
     * Validates that the trigger is valid. Will throw an Error if it's not.
     *
     * @param {ps} ps
     * @param {Pointer} triggerPointer The trigger to validate
     * @param {Pointer} compWithVariants The scoped component with the trigger
     */
    const validateTrigger = (ps, triggerPointer, compWithVariants) => {
        if (!ps.dal.isExist(triggerPointer) || !variants.getData(ps, triggerPointer)) {
            throw new ReportableError({message: 'Invalid trigger pointer', errorType: 'triggerValidation'})
        }

        const triggerPageId = ps.pointers.data.getPageIdOfData(triggerPointer)
        const targetCompPageId = ps.pointers.components.getPageOfComponent(compWithVariants).id

        if (triggerPageId !== targetCompPageId) {
            throw new ReportableError({message: 'Target component and trigger must belong to the same page', errorType: 'triggerValidation'})
        }
    }

    /**
     * Updates a reaction with a new trigger
     *
     * @param {ps} ps
     * @param {Pointer} targetCompWithVariants
     * @param {Pointer} triggerPointer
     * @param {Pointer} reactionPointer
     * @param {object} reactionDataItem
     */
    const update = (ps, targetCompWithVariants, triggerPointer, reactionPointer, reactionDataItem) => {
        validateReaction(ps, reactionDataItem, targetCompWithVariants)
        validateTrigger(ps, triggerPointer, targetCompWithVariants)

        const reactionDataToUpdate = convertReactionDataToRef(reactionDataItem)
        const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
        const compReactionsData = namespaces.getNamespaceData(ps, compWithVariants, REACTIONS_NAMESPACE)

        if (!compReactionsData) {
            throw new ReportableError({message: 'No reactions found for the given component', errorType: 'reactionsUpdate'})
        } else if (!compReactionsData.values.some(reaction => reaction.id === reactionPointer.id)) {
            throw new ReportableError({message: "The provided reaction doesn't exist on the given component", errorType: 'reactionsUpdate'})
        }

        ps.dal.set(reactionPointer, {...reactionDataToUpdate, id: reactionPointer.id})
    }

    /**
     * Disables all reactions for a component
     *
     * @param {ps} ps
     * @param {Pointer} targetCompWithVariants
     * @param {Pointer} triggerPointer
     */
    const disable = (ps, targetCompWithVariants, triggerPointer) => {
        removeAll(ps, targetCompWithVariants, triggerPointer)

        const comp = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
        namespaces.updateNamespaceData(ps, comp, REACTIONS_NAMESPACE, {type: REACTIONS_TYPE, values: []})
    }

    /**
     * Gets all related triggers on a given triggering component pointer
     *
     * @param {ps} ps
     * @param {Pointer} triggeringCompWithVariants
     * @returns {Array<{triggerType: string, component: Pointer, reactionsList: Array<{id: string, state: string, type: string}>}> | undefined} An object with the target component, trigger type and reaction data
     */
    const get = (ps, triggeringCompWithVariants) => {
        if (!ps.dal.isExist(triggeringCompWithVariants)) {
            return undefined
        }

        const allComponentTriggers = triggers.getAllTriggers(ps, triggeringCompWithVariants)
        if (!allComponentTriggers.length) {
            return undefined
        }

        const allTriggers = allComponentTriggers.map(triggerPtr => {
            const allVariantsArray = _.unionWith(triggeringCompWithVariants.variants, [triggerPtr], _.isEqual)
            const triggerType = variants.getData(ps, triggerPtr).trigger
            const pageId = ps.pointers.data.getPageIdOfData(triggerPtr)
            const relationPointers = _.uniqBy(
                relationsUtils.getRelationsByVariantsAndPredicate(ps, allVariantsArray, REACTIONS_NAMESPACE),
                pointer => pointer.id
            )

            if (!relationPointers.length) {
                return undefined
            }

            return relationPointers.map(relationPtr => {
                const relationData = ps.dal.get(relationPtr)
                const relatedComp = relationsUtils.getComponentFromRelation(ps, relationData, pageId)
                relationsUtils.scopedValuePointer(ps, REACTIONS_NAMESPACE, relationPtr)
                const scopedDataPointer = relationsUtils.scopedValuePointer(ps, REACTIONS_NAMESPACE, relationPtr)
                const scopedReactionsList = ps.dal.get(scopedDataPointer)

                return scopedReactionsList.values
                    .map(reactionId => ps.pointers.data.getReactionsDataItem(dsUtils.stripHashIfExists(reactionId), pageId))
                    .map(reactionPointer => {
                        const reactionData = ps.dal.get(reactionPointer)
                        const state = reactionData.state
                            ? ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(reactionData.state), pageId)
                            : undefined
                        const result = {
                            type: reactionData.type,
                            component: relatedComp,
                            pointer: reactionPointer,
                            triggerType
                        }
                        if (state) {
                            result.state = state
                        }

                        return result
                    })
            })
        })

        return _.compact(allTriggers).length === 0 ? undefined : _.flatten(allTriggers.map(triggerArray => _.flatten(triggerArray)))
    }

    /**
     * Removes all reaction data for a trigger from a given component
     *
     * @param {ps} ps
     * @param {Pointer} targetCompWithVariants
     * @param {Pointer} triggerPointer
     */
    const removeAll = (ps, targetCompWithVariants, triggerPointer) => {
        validateTrigger(ps, triggerPointer, targetCompWithVariants)

        const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)

        reactionsUtils.removeAllReactionsDataFromComp(ps, compWithVariants)
    }

    const doesComponentHaveReaction = (ps, compPointerWithVariants, reactionPointer) => {
        const scopedReactions = namespaces.getNamespaceData(ps, compPointerWithVariants, REACTIONS_NAMESPACE)
        return ps.dal.isExist(reactionPointer) && scopedReactions?.values.some(reaction => reaction.id === reactionPointer.id)
    }

    /**
     * Removes a specific reaction from scoped reaction list
     *
     * @param {ps} ps
     * @param {Pointer} targetCompWithVariants
     * @param {Pointer} triggerPointer
     * @param {Pointer} reactionPointer
     */
    const remove = (ps, targetCompWithVariants, triggerPointer, reactionPointer) => {
        validateTrigger(ps, triggerPointer, targetCompWithVariants)
        const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
        if (doesComponentHaveReaction(ps, compWithVariants, reactionPointer)) {
            const pageId = ps.pointers.components.getPageOfComponent(compWithVariants).id
            reactionsUtils.removeReaction(ps, reactionPointer, pageId)
        }
    }

    return {
        add,
        update,
        disable,
        get,
        removeAll,
        remove
    }
})
