import {DAL, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
const {isRefPointer} = displayedOnlyStructureUtil
import {PAGE_DATA_TYPES} from '../constants/constants'

const {getPointer} = pointerUtils

const REF_SUFFIX = '-ref'
const REF_HOST_COMPONENT_TYPES = {
    'wysiwyg.viewer.components.RefComponent': true
}

const RefDelimiter = '_r_'
const GhostRefCompType = 'GHOST_REF_COMP'

const isRefHost = (pointer: Pointer, dal: DAL): boolean => REF_HOST_COMPONENT_TYPES[dal.getWithPath(pointer, ['componentType'])]

const STRUCTURE_TYPE_TO_REF_TYPE_MAP = {
    DESKTOP: `DESKTOP${REF_SUFFIX}`,
    MOBILE: `MOBILE${REF_SUFFIX}`
}

const DATA_TYPE_TO_REF_TYPE_MAP = _.mapValues(PAGE_DATA_TYPES, (v, k) => `${k}${REF_SUFFIX}`)

const TYPE_TO_REF_TYPE_MAP = {...STRUCTURE_TYPE_TO_REF_TYPE_MAP, ...DATA_TYPE_TO_REF_TYPE_MAP}

const structureRefTypes = _.values(STRUCTURE_TYPE_TO_REF_TYPE_MAP)
const dataRefTypes = _.values(DATA_TYPE_TO_REF_TYPE_MAP)

const getRefPointerType = (pointer: Pointer): Pointer => {
    const refType = TYPE_TO_REF_TYPE_MAP[pointer.type]

    if (refType) {
        return {...pointer, type: refType}
    }

    return pointer
}

export interface PointerNoRef extends Pointer {
    noRefFallbacks: boolean
}

const getPointerWithoutFallbacksFromPointer = (pointer: Pointer): PointerNoRef => ({...pointer, noRefFallbacks: true})

const getPointerWithoutFallbacks = (id: string, type: string): PointerNoRef => getPointerWithoutFallbacksFromPointer(getPointer(id, type))

const getOverridePrefix = (id: string): string => `${id}_r_`

export {
    REF_SUFFIX,
    RefDelimiter,
    GhostRefCompType,
    isRefHost,
    getRefPointerType,
    isRefPointer,
    structureRefTypes,
    getPointerWithoutFallbacksFromPointer,
    getPointerWithoutFallbacks,
    getOverridePrefix,
    dataRefTypes
}
