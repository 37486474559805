import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import santaComponents from '@wix/santa-components'
import coreUtilsLib from '@wix/santa-core-utils'
import tpaCompBaseMixin from '../mixins/tpaCompBaseMixin'
import tpaUrlBuilderMixin from '../mixins/tpaUrlBuilderMixin'
import tpaCompApiMixin from '../mixins/tpaCompApiMixin'
import tpaSectionMixin from '../mixins/tpaSectionMixin'
import tpaResizeWindowMixin from '../mixins/tpaResizeWindowMixin'

const {compRegistrar} = componentsCore

/**
 * @class components.TPASection
 * @extends {tpa.mixins.tpaCompBase}
 * @extends {tpa.mixins.tpaUrlBuilder}
 * @extends {tpa.mixins.tpaCompAPI}
 * @extends {ReactCompositeComponent}
 * @property {comp.properties} props
 */
const TPASection = {
    displayName: 'TPASection',
    propTypes: {
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
    },
    mixins: [
        componentsCore.mixins.skinBasedComp,
        coreUtilsLib.timersMixins.timeoutsMixin,
        tpaCompBaseMixin,
        tpaUrlBuilderMixin,
        tpaCompApiMixin,
        tpaSectionMixin,
        tpaResizeWindowMixin
    ],
    getBaseUrl() {
        const appData = this.getAppData()
        const {widgetId} = this.props.compData
        let {sectionUrl} = appData
        let {sectionDefaultPage} = appData
        const isMobileView = this.isUnderMobileView() && this.isMobileReady()

        if (widgetId) {
            const widget = _.find(appData.widgets, {widgetId})

            if (widget) {
                sectionDefaultPage = widget.appPage.defaultPage
                if (isMobileView) {
                    sectionUrl = this.getTPAOverrideUrl('tpaMobileUrlOverride', widgetId) || widget.mobileUrl
                } else {
                    sectionUrl = this.getTPAOverrideUrl('tpaWidgetUrlOverride', widgetId) || widget.widgetUrl
                }
            }
        } else if (isMobileView) {
            sectionUrl = appData.sectionMobileUrl
        }

        if (sectionDefaultPage && !_.isEmpty(sectionDefaultPage)) {
            if (sectionUrl.slice(-1) !== '/') {
                sectionUrl += '/'
            }

            sectionUrl += sectionDefaultPage
        }

        return sectionUrl
    }
}

compRegistrar.register('wysiwyg.viewer.components.tpapps.TPASection', TPASection)
export default TPASection
