define(['documentServices/appStudio/widgetConfigs/responsiveWidgetConfig', 'documentServices/appStudio/widgetConfigs/classicWidgetConfig'], function (
    responsiveWidgetConfig,
    classicWidgetConfig
) {
    const createNewWidgetConfig = () => classicWidgetConfig
    const createNewResponsiveWidgetConfig = () => responsiveWidgetConfig

    return {
        createNewWidgetConfig,
        createNewResponsiveWidgetConfig
    }
})
