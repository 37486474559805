define(['lodash', 'documentServices/wixapps/bi/events', 'documentServices/wixapps/bi/errors', '@wix/santa-core-utils'], function (
    _,
    EVENTS,
    ERRORS,
    santaCoreUtils
) {
    'use strict'

    class BIService {
        constructor(eventCallback, errorCallback) {
            this._eventCallback = eventCallback
            this._errorCallback = errorCallback
        }

        sendEvent(event, eventProps) {
            try {
                this._eventCallback(event, eventProps)
            } catch (e) {
                santaCoreUtils.log.error('Error sending BI event', e)
            }
        }

        sendError(error, errorProps) {
            try {
                this._errorCallback(error, errorProps)
            } catch (e) {
                santaCoreUtils.log.error('Error sending BI error event', e)
            }
        }

        // -- Saving Items

        beforeSavingItems({applicationInstanceId, applicationInstanceVersion}, {created, updated, deleted}) {
            this.sendEvent(EVENTS.BEFORE_SAVING_ITEMS, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion,
                item_ids_to_create: _.map(created, '_iid').join(','),
                item_ids_to_update: _.map(updated, '_iid').join(','),
                item_ids_to_delete: deleted.join(',')
            })
        }

        successSavingItems({applicationInstanceId, applicationInstanceVersion}, responseData) {
            let savedIds = []
            if (_.get(responseData, 'payload.results')) {
                const savedObjects = _.isArray(responseData.payload.results)
                    ? _.map(responseData.payload.results, 'payload')
                    : [responseData.payload.results.payload]
                savedIds = savedObjects && _.map(savedObjects, 'id')
            }

            this.sendEvent(EVENTS.SUCCESS_SAVING_ITEMS, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion,
                saved_item_ids: savedIds.join(',')
            })
        }

        skippedSavingItems({applicationInstanceId, applicationInstanceVersion}) {
            this.sendEvent(EVENTS.SKIPPED_SAVING_ITEMS, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion
            })
        }

        errorSavingItems({applicationInstanceId, applicationInstanceVersion}, responseData, err) {
            this.sendError(ERRORS.LIST_BUILDER_ERROR_SAVING_ITEMS, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion,
                error_code: responseData ? responseData.errorCode : err,
                error_description: responseData && responseData.errorDescription
            })
        }

        // -- Saving Repo

        beforeSavingRepo({applicationInstanceId, applicationInstanceVersion}) {
            this.sendEvent(EVENTS.BEFORE_SAVING_REPO, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion
            })
        }

        successSavingRepo({applicationInstanceId, applicationInstanceVersion}, responseData) {
            this.sendEvent(EVENTS.SUCCESS_SAVING_REPO, {
                app_instance_id: applicationInstanceId,
                old_app_instance_version: applicationInstanceVersion,
                new_app_instance_version: _.get(responseData, 'payload.applicationInstanceVersion')
            })
        }

        skippedSavingRepo({applicationInstanceId, applicationInstanceVersion}) {
            this.sendEvent(EVENTS.SKIPPED_SAVING_REPO, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion
            })
        }

        errorSavingRepo({applicationInstanceId, applicationInstanceVersion}, responseData, err) {
            this.sendError(ERRORS.LIST_BUILDER_ERROR_SAVING_REPO, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion,
                error_code: responseData ? responseData.errorCode : err,
                error_description: responseData && responseData.errorDescription
            })
        }

        // -- Publish

        beforePublish({applicationInstanceId, applicationInstanceVersion}) {
            this.sendEvent(EVENTS.BEFORE_PUBLISH, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion
            })
        }

        successPublish({applicationInstanceId, applicationInstanceVersion}) {
            this.sendEvent(EVENTS.SUCCESS_PUBLISH, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion
            })
        }

        errorPublish({applicationInstanceId, applicationInstanceVersion}, responseData, err) {
            this.sendError(ERRORS.LIST_BUILDER_ERROR_PUBLISH, {
                app_instance_id: applicationInstanceId,
                app_instance_version: applicationInstanceVersion,
                error_code: responseData ? responseData.errorCode : err,
                error_description: responseData && responseData.errorDescription
            })
        }
    }

    return BIService
})
