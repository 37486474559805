import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'
import responseTransformation from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/responseTransformation'
import ecomLogger from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomLogger'
import ecomDataUtils from '@wix/santa-ds-libs/src/wixappsClassics/src/ecommerce/util/ecomDataUtils'
import experiment from 'experiment'

const {wixappsDataHandler} = wixapps
const API_URL_SEGMENT = '/apps/ecommerce/api/json'
const FALLBACK_API_URL_SEGMENT = 'https://fallback.wix.com/_api/ecommerce/api/json'
const MIGRATION_API_URL_SEGMENT = '/_api/wix-ecommerce-migration-web/apps/ecommerce/api/json'
const MIGRATION_FALLBACK_API_URL_SEGMENT = 'https://fallback.wix.com/_api/wix-ecommerce-migration-web/_api/ecommerce/api/json'
const OUTER_DEFAULT_CATEGORY = 'defaultCategory'
const INNER_DEFAULT_CATEGORY = '-1'
const TIMEOUT = 150

function getApiUrl(siteData, isReadOnly, isFallback) {
    const eComMigrationViewerExp = experiment.isOpen('ecommigrationviewer', siteData)
    const apiUrl = eComMigrationViewerExp ? MIGRATION_API_URL_SEGMENT : API_URL_SEGMENT
    const fallbackApiUrl = eComMigrationViewerExp ? MIGRATION_FALLBACK_API_URL_SEGMENT : FALLBACK_API_URL_SEGMENT
    const baseUrl = isFallback ? fallbackApiUrl : coreUtils.urlUtils.baseUrl(siteData.currentUrl.full) + apiUrl
    let siteUrl = `${baseUrl}?metaSiteId=${siteData.getMetaSiteId()}&svSession=${siteData.getSvSession()}`
    if (isReadOnly) {
        siteUrl += '&ro=true'
    }
    return siteUrl
}

function getDefaultCompMetadata() {
    return {
        isReadOnly: false,
        isOnline: true
    }
}

function transformAndSetMetaData(transformFunc, siteData, packageName, compId, responseData, currentValue) {
    transformFunc(responseData, currentValue)

    const descriptor = wixappsDataHandler.getDescriptor(siteData, packageName)
    const compData = wixappsDataHandler.getDataByPath(siteData, packageName, wixappsDataHandler.getDataByCompId(siteData, packageName, compId))
    if (descriptor && compData) {
        wixappsDataHandler.setCompFailedRequests(false, siteData, packageName, compId)
        wixappsDataHandler.clearCompMetadata(siteData, packageName, compId)
    } else {
        wixappsDataHandler.setCompMetadata({dataReady: true}, siteData, packageName, compId)
    }

    return currentValue
}

function buildRequest(siteData, compData, packageName, additionalParams, compMetadata) {
    compMetadata = compMetadata || getDefaultCompMetadata()

    let transformFunc

    if (compMetadata.transformFunc) {
        if (compMetadata.customTransform) {
            transformFunc = compMetadata.transformFunc
        } else {
            transformFunc = responseTransformation.transformResponse.bind(this, compData && compData.id, compMetadata.transformFunc)
        }
    }

    const urls = [getApiUrl(siteData, compMetadata.isReadOnly, false), getApiUrl(siteData, compMetadata.isReadOnly, true)]
    const request = {
        force: true,
        urls,
        data: buildParams(siteData, compMetadata, compData, additionalParams),
        name: packageName,
        destination: wixappsDataHandler.getSiteDataDestination(packageName),
        transformFunc: transformAndSetMetaData.bind(this, transformFunc, siteData, packageName, compData && compData.id),
        timeout: TIMEOUT,
        callback: onEcomRequestSuccess.bind(this, compMetadata.action, siteData),
        error: createFailCallback(siteData, compMetadata.onError, packageName, _.get(compData, 'id')),
        onUrlRequestFailure(url) {
            if (url === urls[0]) {
                ecomLogger.reportError(siteData, ecomLogger.errors.ATNT_FIX)
            }
        }
    }
    return request
}

function createFailCallback(siteData, onErrorCallback, packageName, compId) {
    return function (error, response) {
        if (onErrorCallback) {
            onErrorCallback({code: response, statusText: error})
        }
        if (compId) {
            wixappsDataHandler.setCompFailedRequests(true, siteData, packageName, compId)
        }
        onEcomRequestFailure(siteData)
    }
}

function onEcomRequestSuccess(action, siteData /*, currentValue, response*/) {
    ecomLogger.reportEvent(siteData, ecomLogger.events.MAGENTO_CLIENT_SUCCESS, {
        action
    })
}

function onEcomRequestFailure(siteData, err?, response?) {
    ecomLogger.reportError(siteData, err, response)
}

function transformParamsForDefaultStore(params) {
    const {categoryId} = params

    //default data has -1 on wixId to notify we have to use default collection
    if (params && params.wixId && params.wixId === '-1') {
        params.wixId = categoryId && categoryId !== INNER_DEFAULT_CATEGORY ? categoryId : OUTER_DEFAULT_CATEGORY
    }

    //some templates do not have 'categoryId' so if the field is still on params also use default collection
    if (params && params.wixId && params.wixId === 'categoryId') {
        params.wixId = categoryId && categoryId !== INNER_DEFAULT_CATEGORY ? categoryId : OUTER_DEFAULT_CATEGORY
    }
}

function buildParams(siteData, compMetadata, compData, additionalParams) {
    const storeId = wixappsDataHandler.getDataByPath(siteData, ecomDataUtils.packageName, ['storeId'])
    const compDataParams = compData && compData.appLogicParams
    const params = {
        storeId
    }
    if (compMetadata.params) {
        _.forOwn(compMetadata.params, function (value, key) {
            params[key] = (compDataParams[value] && compDataParams[value].value) || value
        })
    }
    if (additionalParams) {
        _.forOwn(additionalParams, function (value, key) {
            params[key] = value
        })
    }

    //if we need custom transformations on the params object (i.e. on default store)
    transformParamsForDefaultStore(params)

    return {
        jsonrpc: '2.0',
        id: 1,
        method: 'frontend',
        params: [compMetadata.action, [params]]
    }
}

export default {
    buildRequest
}
