define(['lodash', 'documentServices/utils/runtimeConfig', 'documentServices/utils/utils'], function (_, runtimeConfig, dsUtils) {
    const exec = ps => {
        if (runtimeConfig.isSanta(ps)) {
            return
        }

        const translatedPagesData = _(ps.siteAPI.getPageDataTranslations())
            .flatMap(({languageCode, pageId}) => {
                const pointer = ps.pointers.multilingualTranslations.translationDataItem('masterPage', languageCode, pageId)
                if (!ps.dal.full.isExist(pointer)) {
                    return []
                }

                return _.map(['desktop', 'mobile'], viewMode => ({
                    pointer,
                    pageId,
                    viewMode
                }))
            })
            .value()

        translatedPagesData.forEach(({pointer, pageId, viewMode}) => {
            const translatedBgRefPointer = ps.pointers.page.getInnerBackgroundRefPointer(pointer, viewMode)
            const translatedBgRef = ps.dal.full.get(translatedBgRefPointer)
            if (!translatedBgRef) {
                return
            }

            const translatedBgPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(translatedBgRef), pageId)
            if (translatedBgRef && ps.dal.full.isExist(translatedBgPointer)) {
                return
            }

            const originalPageDataPointer = ps.pointers.data.getDataItem(pageId, 'masterPage')
            const originalBgRefPointer = ps.pointers.page.getInnerBackgroundRefPointer(originalPageDataPointer, viewMode)
            const originalBgRef = ps.dal.full.get(originalBgRefPointer)
            ps.dal.full.set(translatedBgRefPointer, originalBgRef)
        })
    }

    return {
        exec,
        name: 'pageBackgroundTranslationFixer',
        version: 1
    }
})
