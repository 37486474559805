import TPAActivity from './tpaActivity'
import ContactFormActivity from './contactFormActivity'
import DynamicContactFormActivity from './dynamicContactFormActivity'
import SubscribeFormActivity from './subscribeFormActivity'

export default {
    TPAActivity,
    ContactFormActivity,
    DynamicContactFormActivity,
    SubscribeFormActivity
}
