define([], function () {
    'use strict'

    const getWixDataSchemasClient = ({signedInstance, appId}) => {
        const {createDataSchemasClientForBrowser} = require('@wix/wix-data-schemas-client')
        return createDataSchemasClientForBrowser(signedInstance, appId, {
            baseUrl: '/_api/cloud-data/v1/schemas'
        })
    }

    return {
        list: codeAppInfo => getWixDataSchemasClient(codeAppInfo).list({includeDeletedCollections: true}),

        get: (codeAppInfo, schemaId) => getWixDataSchemasClient(codeAppInfo).get(schemaId),

        save: async (boundExtensionsAPI, codeAppInfo, schemaId, schema) => {
            const currentKnownVersion = boundExtensionsAPI.wixDataSchemas.getLastModifiedVersion(schemaId)
            if (currentKnownVersion) {
                schema.version = Math.max(currentKnownVersion, schema.version || 0)
            }
            const result = await getWixDataSchemasClient(codeAppInfo).save(schemaId, schema, {returnUpdated: true})

            if (result?.length) {
                result.forEach(updatedSchema => {
                    const {id, version} = updatedSchema
                    if (version) {
                        boundExtensionsAPI.wixDataSchemas.setLastModifiedVersion(id, version)
                    }
                })
            }
            return result
        },

        remove: (codeAppInfo, schemaId) => getWixDataSchemasClient(codeAppInfo).remove(schemaId, {returnUpdated: true})
    }
})
