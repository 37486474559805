define(['lodash', '@wix/santa-core-utils', 'documentServices/page/pageData'], function (_, coreUtils, pageData) {
    'use strict'

    function largerThanOne(value) {
        return value > 1
    }

    return function (ps) {
        const urlFormatPointer = ps.pointers.general.getUrlFormat()
        const urlFormat = ps.dal.get(urlFormatPointer)

        if (urlFormat === coreUtils.siteConstants.URL_FORMATS.HASH_BANG) {
            return
        }

        const pageIds = pageData.getPagesList(ps)
        const duplicateUriSEO = _(pageIds).map(pageData.getPageUriSEO.bind(null, ps)).countBy().pickBy(largerThanOne).value()
        if (!_.isEmpty(duplicateUriSEO)) {
            throw new Error(`Found pages with duplicate url title: ${JSON.stringify(duplicateUriSEO)}`)
        }
    }
})
