import _ from 'lodash'
import googleTagManagerLoader from './googleTagManagerLoader'

const DELAY = 200

function runGoogleTagManagerScript(accountId, callback) {
    if (window.dataLayer) {
        callback()
    } else {
        /**
         * More info at:
         * https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/
         */
        googleTagManagerLoader.load(accountId, function () {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({'gtm.start': _.now(), event: 'gtm.js'})
            callback()
        })
    }
}

export default {
    /**
     * Report a page event with google tag manager
     * @param {string} pagePath
     * @param {string} accountId
     */
    reportPageEvent(pagePath, accountId) {
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                runGoogleTagManagerScript(accountId, function () {
                    window.dataLayer.push({
                        event: 'Pageview',
                        url: pagePath
                    })
                })
            }, DELAY)
        }
    },

    report(accountId, eventParams) {
        runGoogleTagManagerScript(accountId, function () {
            window.dataLayer.push(...eventParams)
        })
    }
}
