import _ from 'lodash'
import PropTypes from 'prop-types'
import proxySantaTypesDefinitions from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxySantaTypesDefinitions'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import textProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/textProxy'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'

const COMPONENT_TYPE = 'wysiwyg.viewer.components.WRichText'

function transformSkinProperties(refData) {
    const skinProperties = {
        richTextContainer: {
            style: {height: 'initial'}
        }
    }

    return _.defaultsDeep(skinProperties, refData)
}

/**
 * @class proxies.Label
 * @extends proxies.mixins.textProxy
 */
export default {
    mixins: [textProxy],
    statics: {
        componentType: COMPONENT_TYPE
    },
    propsTypes: {
        viewProps: PropTypes.shape({
            linkRenderInfo: proxySantaTypesDefinitions.linkRenderInfo.isRequired
        })
    },
    renderProxy() {
        const data = this.proxyData

        const elementTag = this.getCompProp('elementTag')
        const formattedText = this.createFormattedText(data, elementTag)

        const compData = typesConverter.richText(formattedText, data.links, this.props.viewProps.linkRenderInfo.pagesDataItemsMap)
        const props = this.getRichTextChildCompProps(COMPONENT_TYPE, transformSkinProperties, compData)

        return componentsCore.compRegistrar.getCompClass(COMPONENT_TYPE, true)(props)
    }
}
