define([
    'documentServices/component/component',
    'documentServices/smartBoxes/multiComponentsUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/page/page'
], function (component, multiComponentsUtils, componentsMetaData, page) {
    'use strict'

    //TODO not tested
    function alignInContainer(ps, compPointer, alignment) {
        if (!componentsMetaData.public.isAlignable(ps, compPointer)) {
            throw new Error('cannot align component')
        }

        const compContainer = component.getContainer(ps, compPointer)
        const containerLayout = component.isPageComponent(ps, compContainer) ? page.getLayout(ps, compContainer.id) : component.layout.get(ps, compContainer)

        multiComponentsUtils.alignComponentsToOuterLayout(ps, [compPointer], containerLayout, alignment, true)
    }

    return {
        alignInContainer
    }
})
