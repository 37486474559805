define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/component/component',
    'documentServices/utils/utils'
], function (_, santaCoreUtils, dataModel, componentStylesAndSkinsAPI, component, dsUtils) {
    'use strict'

    function beforeLayout(ps, compPointer, newLayout) {
        if (_.isUndefined(newLayout.height)) {
            return
        }

        const currentLayout = component.layout.get(ps, compPointer)
        const currentHeight = _.get(currentLayout, 'height')

        if (_.isEqual(currentHeight, newLayout.height)) {
            return
        }

        const compData = dataModel.getDataItem(ps, compPointer)
        const menuRef = _.isString(compData.menuRef) ? dsUtils.stripHashIfExists(compData.menuRef) : compData.menuRef.id
        const menuItems = dataModel.getDataItemById(ps, menuRef).items

        const itemsCount = _.filter(menuItems, 'isVisible').length

        if (newLayout.height < itemsCount * 3) {
            return
        }

        const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
        const separatorHeight =
            parseInt(
                componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'sepw') ||
                    componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'separatorHeight'),
                10
            ) || 0

        const menuItemHeight = Math.max(santaCoreUtils.verticalMenuCalculations.getItemHeight(newLayout.height, separatorHeight, itemsCount, skinExports), 1)
        component.properties.update(ps, compPointer, {menuItemHeight})
    }

    return {
        before: beforeLayout
    }
})
