define([
    'lodash',
    'documentServices/actionsAndBehaviors/allowedGroupsByCompType',
    'documentServices/actionsAndBehaviors/actionsEditorSchema',
    'documentServices/actionsAndBehaviors/behaviorsEditorSchema',
    'documentServices/actionsAndBehaviors/pageTransitionsEditorSchema',
    'documentServices/component/componentStructureInfo',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    '@wix/santa-core-utils',
    'experiment'
], function (
    _,
    allowedGroups,
    actionsEditorSchema,
    behaviorsEditorSchema,
    pageTransitionsEditorSchema,
    componentStructureInfo,
    constants,
    dataModel,
    santaCoreUtils,
    experiment
) {
    'use strict'

    const ACTION_PROPS_TO_COMPARE = ['sourceId', 'type', 'name']

    const BEHAVIOR_PROPS_TO_COMPARE = ['targetId', 'type', 'name', 'part', 'viewMode']

    const CODE_BEHAVIOR_NAMES = {runCode: true, runAppCode: true}
    const CODE_BEHAVIOR_TYPE = 'widget'

    /**
     * Structure of behaviors object that can be saved to a component:
     * @example
     * [
     *      {
     *          "action":"screenIn"
     *          "targetId":"Clprt0-wl2"
     *          "name":"SpinIn",
     *          "duration":"2.45",
     *          "delay":"1.60",
     *          "params":{"cycles":5,"direction":"cw"},
     *          "playOnce":true
     *      }
     * ]
     *
     * @typedef {Array<SavedBehavior>} SavedBehaviorsList
     * @property {String} actionName
     * @property {String} targetId
     */

    const allowedBehaviorKeys = ['action', 'targetId', 'name', 'duration', 'delay', 'params', 'playOnce', 'type', 'viewMode']

    // Static getters

    /**
     * Names of available actions, sorted a-z
     * @returns {string[]}
     */
    function getActionNames() {
        return _.sortBy(_.keys(actionsEditorSchema.getSchema()))
    }

    /**
     * Return the definition containing settings and parameters of an action
     * @param {ps} privateServices
     * @param {String} actionName
     * @returns {Object|undefined}
     */
    function getActionDefinition(privateServices, actionName) {
        if (_.has(actionsEditorSchema.getSchema(), actionName)) {
            // TODO: obviously that type is not always 'comp', needs to be finished
            return {
                type: 'comp',
                name: actionName
            }
        }
    }

    /**
     * Return the definition containing settings and parameters of a behavior
     * @param {ps} privateServices
     * @param {String} behaviorName
     * @returns {Object|undefined}
     */
    function getBehaviorDefinition(privateServices, behaviorName) {
        const behavior = _.cloneDeep(_.find(behaviorsEditorSchema, {name: behaviorName}))
        // Need to set default value equals true for popup in mobile
        if (experiment.isOpen('se_lightBoxMobileDesktop') && behaviorName === 'openPopup') {
            behavior.params.openInMobile = behavior.params.openInDesktop
        }

        return behavior
    }

    /**
     * Get names of behaviors.
     * Filter by optional compType and/or actionName
     * @param {ps} privateServices
     * @param {String|null} [compType]
     * @param {String} [actionName]
     * @returns {string[]}
     */
    function getBehaviorNames(privateServices, compType, actionName) {
        let behaviors = behaviorsEditorSchema

        if (compType) {
            const shortCompType = _.last(compType.split('.'))
            const compTypeGroups = allowedGroups.getSchema()[shortCompType] || allowedGroups.getSchema().AllComponents
            behaviors = _.isEmpty(compTypeGroups)
                ? []
                : _.filter(behaviors, function (behavior) {
                      return _(behavior.groups).difference(compTypeGroups).isEmpty()
                  })
        }

        if (actionName) {
            behaviors = _.filter(behaviors, function (behavior) {
                return _(actionsEditorSchema.getSchema()[actionName].groups).difference(behavior.groups).isEmpty()
            })
        }

        return _(behaviors).map('name').sortBy().value()
    }

    /**
     * A wrapper for getBehaviorNames that returns false if the list of behaviors returns empty, and true otherwise
     * @param {ps} privateServices
     * @param {AbstractComponent} compRef
     * @param {String} [actionName]
     * @returns {Boolean}
     */
    function isBehaviorable(privateServices, compRef, actionName) {
        const compType = componentStructureInfo.getType(privateServices, compRef)
        const hasBehaviors = !_.isEmpty(getBehaviorNames(privateServices, compType, actionName))
        return hasBehaviors
    }

    /**
     * for each element inside a repeater the behavior registration should be on the template id and not on the item id (the static behavior query is shared between the items)
     * @param {ps} privateServices
     * @param actionSourceRef
     * @return {Pointer|*}
     */
    function getOriginalCompRef(privateServices, actionSourceRef) {
        if (santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(actionSourceRef.id)) {
            const originalId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(actionSourceRef.id)
            const pagePointer = privateServices.pointers.components.getPageOfComponent(actionSourceRef)
            const originalCompPointer = privateServices.pointers.full.components.getComponent(originalId, pagePointer)
            return originalCompPointer
        }
        return actionSourceRef
    }

    // Component Getters

    /**
     * Returns the behaviors saved on a component structure
     * @param {ps} privateServices
     * @param {AbstractComponent} componentPointer
     * @returns {Array<SavedBehavior>|null}
     */
    function getComponentBehaviors(privateServices, componentPointer) {
        const compBehaviors = getBehaviors(privateServices, componentPointer)
        return _.isEmpty(compBehaviors) ? null : compBehaviors
    }

    /**
     * @param {ps} privateServices
     * @param {Pointer} componentPointer
     * @returns {boolean|*}
     */
    function isMobileAndHasDesktopComponent(privateServices, componentPointer) {
        return _.get(componentPointer, 'type') === constants.VIEW_MODES.MOBILE && privateServices.dal.isExist(toDesktopComponent(componentPointer))
    }

    function toDesktopComponent(componentPointer) {
        return _.defaults({type: constants.VIEW_MODES.DESKTOP}, componentPointer)
    }

    // Component Setters

    /**
     * Set a behavior to a component structure,
     * will override any previous behavior with same name and action (one type of behavior per one type of action)
     * @param {ps} privateServices
     * @param {AbstractComponent} componentPointer
     * @param {SavedBehavior} behavior
     * @param {String} [actionName]
     */
    function setComponentBehavior(privateServices, componentPointer, behavior, actionName) {
        if (actionName) {
            behavior.action = actionName
        }
        const compType = componentStructureInfo.getType(privateServices, componentPointer)
        const validation = validateBehavior(privateServices, behavior, compType)
        if (validation.type === 'error') {
            throw new Error(validation.message)
        }

        /*
         * Part of fix for https://jira.wixpress.com/browse/WEED-11138
         * TODO: convert old behaviors to real data structure and on the way kill the flag
         */
        if (behavior.action === 'screenIn') {
            _.set(behavior, ['params', 'doubleDelayFixed'], true)
        }

        if (isMobileAndHasDesktopComponent(privateServices, componentPointer)) {
            // if setting a mobile component's behavior, set it's desktop behavior first..
            setComponentBehavior(privateServices, toDesktopComponent(componentPointer), behavior, actionName)
        }

        let behaviors = getComponentBehaviors(privateServices, componentPointer) || []
        const isSameBehavior = _.partial(areBehaviorsEqual, behavior)
        behaviors = _.reject(behaviors, isSameBehavior)

        updateBehaviorsInDAL(privateServices, componentPointer, behaviors.concat(behavior))
    }

    /**
     * @param {ps} privateServices
     * @param actionSourceRef
     * @param action
     * @param behaviorTargetRef
     * @param behavior
     */
    function updateBehavior(privateServices, actionSourceRef, action, behaviorTargetRef, behavior) {
        const compRef = getOriginalCompRef(privateServices, actionSourceRef)
        const existingBehaviors = getBehaviors(privateServices, compRef)
        const updatedBehaviorObject = {
            action: _.defaults({sourceId: compRef.id}, action),
            behavior: _.defaults({targetId: behaviorTargetRef.id}, behavior)
        }

        const isEqualToUpdatedBehaviorObject = _.partial(areBehaviorObjectsEqual, updatedBehaviorObject)
        const updatedBehaviors = _.reject(existingBehaviors, isEqualToUpdatedBehaviorObject).concat(updatedBehaviorObject)
        updateBehaviorsInDAL(privateServices, compRef, updatedBehaviors)
    }

    function isAnimationBehavior(behavior) {
        return _.get(behavior, 'type', 'animation') === 'animation'
    }

    function isCodeBehavior(behavior) {
        const {type, name} = behavior || {}
        return type === CODE_BEHAVIOR_TYPE && _.has(CODE_BEHAVIOR_NAMES, name)
    }

    function areActionsEqual(action1, action2) {
        return _.isEqual(_.pick(action1, ACTION_PROPS_TO_COMPARE), _.pick(action2, ACTION_PROPS_TO_COMPARE))
    }

    function areBehaviorsEqual(behavior1, behavior2) {
        return _.isEqual(_.pick(behavior1, BEHAVIOR_PROPS_TO_COMPARE), _.pick(behavior2, BEHAVIOR_PROPS_TO_COMPARE))
    }

    function areBehaviorObjectsEqual(behaviorObj1, behaviorObj2) {
        return areActionsEqual(behaviorObj1.action, behaviorObj2.action) && areBehaviorsEqual(behaviorObj1.behavior, behaviorObj2.behavior)
    }

    function removeBehavior(privateServices, actionSourceRef, action, behaviorTargetRef, behavior) {
        const compRef = getOriginalCompRef(privateServices, actionSourceRef)
        const existingBehaviors = getBehaviors(privateServices, compRef)
        const behaviorObjectToRemove = {
            action: _.defaults({sourceId: compRef.id}, action),
            behavior: _.defaults({targetId: behaviorTargetRef.id}, behavior)
        }

        const shouldRemoveBehavior = _.partial(areBehaviorObjectsEqual, behaviorObjectToRemove)
        const updatedBehaviors = _.reject(existingBehaviors, shouldRemoveBehavior)

        if (updatedBehaviors.length !== existingBehaviors.length) {
            updateBehaviorsInDAL(privateServices, compRef, updatedBehaviors)
        }
    }

    function hasBehavior(privateServices, actionSourceRef, action, behaviorTargetRef, behavior) {
        const compRef = getOriginalCompRef(privateServices, actionSourceRef)
        const existingBehaviors = getBehaviors(privateServices, compRef)
        const behaviorObjectToSeek = {
            action: _(compRef && {sourceId: compRef.id})
                .defaults(action)
                .pick(ACTION_PROPS_TO_COMPARE)
                .value(),
            behavior: _(behaviorTargetRef && {targetId: behaviorTargetRef.id})
                .defaults(behavior)
                .pick(BEHAVIOR_PROPS_TO_COMPARE)
                .value()
        }

        return _.some(existingBehaviors, behaviorObjectToSeek)
    }

    function getBehaviors(privateServices, actionSourceRef) {
        const compRef = getOriginalCompRef(privateServices, actionSourceRef)
        const behaviors = dataModel.getBehaviorsItem(privateServices, compRef)
        return behaviors ? JSON.parse(behaviors) : []
    }

    function updateBehaviorsInDAL(privateServices, componentPointer, behaviors) {
        const behaviorsToSet = JSON.stringify(behaviors)
        if (_.isEmpty(behaviors)) {
            dataModel.removeBehaviorsItem(privateServices, componentPointer)
        } else {
            dataModel.updateBehaviorsItem(privateServices, componentPointer, behaviorsToSet)
        }
    }

    /**
     * Remove a single behavior from a component structure
     * @param {ps} privateServices
     * @param {AbstractComponent} componentPointer
     * @param {SavedBehavior|String} [behavior]
     * @param {String} [actionName]
     * @deprecated
     */
    function removeComponentSingleBehavior(privateServices, componentPointer, behavior, actionName) {
        if (isMobileAndHasDesktopComponent(privateServices, componentPointer)) {
            // if removing a mobile component's behavior explicitly, remove the desktop behavior first..
            removeComponentSingleBehavior(privateServices, toDesktopComponent(componentPointer), behavior, actionName)
        }

        let behaviors = getComponentBehaviors(privateServices, componentPointer)
        let toRemove = {
            action: actionName
        }

        if (_.isString(behavior)) {
            toRemove.name = behavior
        } else if (_.isObject(behavior)) {
            toRemove = _.assign(toRemove, behavior)
        }

        behaviors = behaviors ? _.reject(behaviors, toRemove) : []
        updateBehaviorsInDAL(privateServices, componentPointer, behaviors)
    }

    /**
     * Remove behaviors from a component structure
     * @param {ps} privateServices
     * @param {AbstractComponent} componentPointer
     */
    function removeComponentBehaviors(privateServices, componentPointer) {
        updateBehaviorsInDAL(privateServices, componentPointer, [])
    }

    /**
     *
     * @param {ps} privateServices
     * @param {AbstractComponent} componentPointer
     * @param {{action: {}|undefined, behavior: {}|undefined}} filterObject this will be used to filter  behaviors to remove
     */
    function removeComponentsBehaviorsWithFilter(privateServices, componentPointer, filterObject) {
        if (isMobileAndHasDesktopComponent(privateServices, componentPointer)) {
            // if removing a mobile component's behaviors explicitly, remove the desktop behaviors first..
            removeComponentsBehaviorsWithFilter(privateServices, toDesktopComponent(componentPointer), filterObject)
        }

        let behaviors = getComponentBehaviors(privateServices, componentPointer)

        behaviors = behaviors ? _.reject(behaviors, filterObject) : []
        updateBehaviorsInDAL(privateServices, componentPointer, behaviors)
    }

    // Page

    function getPageGroupTransitionsPointer(privateServices, viewMode) {
        const page = privateServices.pointers.components.getMasterPage(viewMode)
        const pageGroupPointer = privateServices.pointers.components.getComponent(constants.COMP_IDS.PAGE_GROUP, page)
        const propertiesPointer = dataModel.getPropertyItemPointer(privateServices, pageGroupPointer)
        return privateServices.pointers.getInnerPointer(propertiesPointer, 'transition')
    }

    /**
     * Set the pages transition
     * @param {ps} privateServices
     * @param {String} transitionName
     */
    function setPagesTransition(privateServices, transitionName) {
        if (!_.includes(getPageTransitionsNames(), transitionName)) {
            throw new Error(`No such transition ${transitionName}`)
        }
        const transitionPointer = getPageGroupTransitionsPointer(privateServices, constants.VIEW_MODES.DESKTOP)

        privateServices.dal.set(transitionPointer, transitionName)
    }

    /**
     * Get the current pages transition
     * @param {ps} privateServices
     * @returns {String}
     */
    function getPagesTransition(privateServices) {
        const transitionPointer = getPageGroupTransitionsPointer(privateServices, constants.VIEW_MODES.DESKTOP)
        return privateServices.dal.get(transitionPointer)
    }

    /**
     * Returns the names of *legacy* transitions sorted a-z
     * @todo: this will change when we will have transition per page
     * @returns {string[]}
     */
    function getPageTransitionsNames() {
        return _.sortBy(_.map(pageTransitionsEditorSchema, 'legacyName'))
    }

    // Preview

    /**
     * Trigger an action
     * @param {ps} privateServices
     * @param {String} actionName
     */
    function executeAction(privateServices, actionName) {
        privateServices.siteAPI.executeAction(actionName)
    }

    /**
     * Stop all animations
     * @param {ps} privateServices
     */
    function stopAndClearAllAnimations(privateServices) {
        privateServices.siteAPI.stopAndClearAllAnimations()
    }

    /**
     * @deprecated
     */
    function deprecatedPreviewAnimation(privateServices, componentReference, animationDef, transformationsToRestore, onComplete) {
        return previewAnimation(privateServices, componentReference, animationDef, onComplete)
    }

    /**
     * Preview an animation on a component
     * @param {ps} privateServices
     * @param {AbstractComponent} componentReference
     * @param {{name:string, duration:number, delay:number, params:object}} animationDef
     * @param {function} onComplete a callback to run at the end of the preview animation
     * @returns {string} sequence id (to be used with stopPreview)
     */
    function previewAnimation(privateServices, componentReference, animationDef, onComplete) {
        const componentPointers = privateServices.pointers.components
        return privateServices.siteAPI.previewAnimation(
            componentReference.id,
            componentPointers.getPageOfComponent(componentReference).id,
            animationDef,
            onComplete
        )
    }

    /**
     * Preview a transition on 2 or more components
     * @param {ps} ps
     * @param {AbstractComponent|Array<AbstractComponent>} srcCompReference (or an array)
     * @param {AbstractComponent|Array<AbstractComponent>} targetCompReference (or an array)
     * @param {{name:string, duration:number, delay:number, params:object}} transitionDef
     * @param {function} onComplete a callback to run at the end of the preview animation
     * @returns {string} sequence id (to be used with stopPreview)
     */
    function previewTransition(ps, srcCompReference, targetCompReference, transitionDef, onComplete) {
        const componentPointers = ps.pointers.components
        /** @type {AbstractComponent[]} */
        // @ts-ignore
        const srcRefs = _.isArray(srcCompReference) ? srcCompReference : [srcCompReference]
        const targetRefs = _.isArray(targetCompReference) ? targetCompReference : [targetCompReference]
        const firstCompRef = srcRefs.length > 0 ? srcRefs[0] : targetRefs[0]
        return ps.siteAPI.previewTransition(
            _.map(srcRefs, 'id'),
            _.map(targetRefs, 'id'),
            componentPointers.getPageOfComponent(firstCompRef).id,
            transitionDef,
            onComplete
        )
    }

    /**
     * @deprecated
     */
    function deprecatedStopPreviewAnimation(privateServices, componentReference, sequenceId) {
        stopPreviewAnimation(privateServices, sequenceId)
    }

    /**
     * Stop animation preview by sequence id returned by previewAnimation
     * @param {ps} privateServices
     * @param {string} sequenceId
     * @param [seekTo]
     */
    function stopPreviewAnimation(privateServices, sequenceId, seekTo) {
        privateServices.siteAPI.stopPreviewAnimation(sequenceId, seekTo)
    }

    // Privates

    /**
     * Validate a behavior structure.
     * @example
     *
     *      {
     *          "action":"screenIn"
     *          "targetId":"Clprt0-wl2"
     *          "name":"SpinIn",
     *          "duration":"2.45",
     *          "delay":"1.60",
     *          "params":{"cycles":5,"direction":"cw"},
     *          "playOnce":true
     *      }
     *
     *
     * @param {ps} privateServices
     * @param {SavedBehavior} behavior
     * @param {String} compType
     */
    function validateBehavior(privateServices, behavior, compType) {
        const message = {
            type: 'ok',
            message: ''
        }

        const compTypeBehaviors = getBehaviorNames(privateServices, compType)

        if (!_.includes(compTypeBehaviors, behavior.name)) {
            message.type = 'error'
            message.message += `Behavior of type ${behavior.name} is not allowed on component of type ${compType}`
        } else if (!_.isPlainObject(behavior)) {
            // Check if action is an object
            message.type = 'error'
            message.message += 'Each behavior should be an object\n'
        } else if (_.isEmpty(behavior)) {
            message.type = 'error'
            message.message += 'Behavior can not be empty\n'
        } else {
            const actionNames = getActionNames()
            if (!_.includes(actionNames, behavior.action)) {
                message.type = 'error'
                message.message += `Action of type ${behavior.action} does not exist\n`
            }

            const actionBehaviors = getBehaviorNames(privateServices, null, behavior.action)
            if (!_.includes(actionBehaviors, behavior.name)) {
                message.type = 'error'
                message.message += `Behavior of type ${behavior.name} is not allowed on action ${behavior.action}`
            }
            if (isAnimationBehavior(behavior) && _.isNaN(Number(behavior.duration)) && _.isNaN(Number(behavior.params.duration))) {
                message.type = 'error'
                message.message += 'Animation duration must be a number\n'
            }

            if (isAnimationBehavior(behavior) && _.isNaN(Number(behavior.delay)) && _.isNaN(Number(behavior.params.delay))) {
                message.type = 'error'
                message.message += 'Animation delay must be a number\n'
            }

            if (behavior.viewMode && (!_.isString(behavior.viewMode) || !_.includes(constants.VIEW_MODES, behavior.viewMode))) {
                message.type = 'error'
                message.message += "Behavior viewMode must be undefined or a string: 'DESKTOP', 'MOBILE' or 'BOTH' \n"
            }

            if (behavior.params && !_.isPlainObject(behavior.params)) {
                message.type = 'error'
                message.message += 'Animation params property are optional, but if they exist params must be an object\n'
            }

            if (behavior.playOnce && !_.isBoolean(behavior.playOnce)) {
                message.type = 'error'
                message.message += 'Animation playOnce property is optional, but if is exists playOnce must be a boolean\n'
            }

            const redundantKeys = _.difference(_.uniq(_.keys(behavior).concat(allowedBehaviorKeys)), allowedBehaviorKeys)
            if (!_.isEmpty(redundantKeys)) {
                message.type = 'error'
                message.message += `The keys [${redundantKeys}] are not allowed values of a Behavior`
            }
        }

        return message
    }

    /**
     * @param {ps} ps
     */
    function executeAnimationsInPage(ps) {
        ps.siteAPI.reloadPageAnimations()
    }

    return {
        // Static lists
        getActionNames,
        getActionDefinition,
        getBehaviorDefinition,
        getBehaviorNames,
        isBehaviorable,
        isCodeBehavior,

        // Component getters
        getComponentBehaviors,

        // component setters
        setComponentBehavior,
        /** @deprecated */
        removeComponentSingleBehavior,
        removeComponentBehaviors,
        removeComponentsBehaviorsWithFilter,

        updateBehavior,
        removeBehavior,
        getBehaviors,
        hasBehavior,

        // Page transitions
        getPageTransitionsNames,
        getPagesTransition,
        setPagesTransition,

        // Preview
        executeAction,
        deprecatedPreviewAnimation,
        deprecatedStopPreviewAnimation,
        previewAnimation,
        previewTransition,
        stopPreviewAnimation,

        executeAnimationsInPage,
        stopAndClearAllAnimations
    }
})
