define(['experiment'], function (experiment) {
    //define(['lodash', 'documentServices/constants/constants', 'documentServices/dataModel/dataModel'], function (_, consts, dataModel) {
    'use strict'

    return {
        anchors: {
            to: false,
            from: false
        },
        dockable: false,
        alignable: false,
        removable: false,
        groupable: false,
        rotatable: true,
        duplicatable: false,
        canConnectToCode: false,
        shouldAutoSetNickname: false,
        canBeFixedPosition: false,
        isRepeatable: Boolean(experiment.isOpen('dm_mediaPlayerInRepeater')),
        mobileConversionConfig: {
            minHeight: 44
        }
    }
})
