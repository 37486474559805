define([
    'lodash',
    'experiment',
    '@wix/mobile-conversion',
    '@wix/santa-core-utils',
    'documentServices/constants/constants',
    'documentServices/mobileConversion/modules/componentReducer',
    'documentServices/mobileConversion/modules/conversionSettings',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/platform/services/originService'
], (_, experiment, mobileCore, santaCoreUtils, constants, componentReducer, conversionSettings, mobileUtilities, originService) => {
    const shouldEnableNewMergeFlow = ps => (experiment.isOpen('dm_mobileAlgoImprovements') || experiment.isOpen('dm_newMobileMergeFlow')) && shouldRunMerge(ps)

    const shouldEnableImprovedMergeFlow = ps => experiment.isOpen('dm_mobileAlgoImprovements') && shouldRunMerge(ps)

    const shouldRunMerge = ps => {
        const origin = originService.getOrigin(ps)
        const isADI = _.get(origin, ['type']) === 'ADI'
        const isEditorX = !mobileUtilities.isMobileSupported(ps)
        return !isADI && !isEditorX
    }

    function prepareConversionForAccessibility(ps, page) {
        page.mobileComponents = []
        const allComps = _.values(mobileCore.conversionUtils.getAllCompsInStructure(page)).concat(mobileCore.conversionUtils.isMasterPage(page) ? [] : [page])
        _.forEach(allComps, component => {
            componentReducer.createConversionData(ps, component, page.id)
        })
        return page
    }

    function getComponentsOrderInStructure(ps, id) {
        const settings = conversionSettings.getConversionSettings({
            heuristicStrategy: 'accessibility',
            conversionType: 'FULL'
        })
        const getChildrenNaturalOrder = container => _.map(container.components, 'id')
        const getChildrenOrder = container => _.get(container, ['conversionData', 'componentsOrder'], getChildrenNaturalOrder(container))
        const componentsMapPointer = ps.pointers.page.getComponentsMapPointer(id, constants.VIEW_MODES.DESKTOP)
        const compsMap = ps.dal.get(componentsMapPointer, true)
        const page = santaCoreUtils.mobileUtils.buildDeepStructure(compsMap[id], compsMap)

        prepareConversionForAccessibility(ps, page)
        mobileCore.conversion.inspectStructure(page, mobileCore.conversionUtils.getChildren(page), settings)

        const componentsMap = mobileCore.conversionUtils.getAllCompsInStructure(page)
        const isVirtualGroup = childId => componentsMap[childId].componentType === 'RESCALE_VIRTUAL_GROUP'
        const order = _.reduce(
            componentsMap,
            (componentsOrderMap, component) => {
                if (!_.has(component, 'components') || isVirtualGroup(component.id)) {
                    return componentsOrderMap
                }
                const childrenOrder = _.reduce(
                    getChildrenOrder(component),
                    (res, childId) => res.concat(isVirtualGroup(childId) ? getChildrenOrder(componentsMap[childId]) : [childId]),
                    []
                )
                return _.set(componentsOrderMap, component.id, childrenOrder)
            },
            {}
        )

        return order
    }

    return {
        shouldEnableNewMergeFlow,
        shouldEnableImprovedMergeFlow,
        getComponentsOrderInStructure,
        shouldRunMerge
    }
})
