define(['lodash'], function (_) {
    'use strict'

    function hasChildren(ps, repeaterPointer) {
        const children = ps.pointers.full.components.getChildren(repeaterPointer)
        return _.size(children) > 0
    }

    function canContain(ps, popoverPointer, potentialChild, targetedContainerPointer) {
        const isTargetedContainerAPopover = targetedContainerPointer.id === popoverPointer.id
        return !(isTargetedContainerAPopover && hasChildren(ps, popoverPointer))
    }

    return {
        isRepeatable: false,
        defaultMobileProperties: {
            closeMethod: 'outerClick'
        },
        canContain,
        canContainByStructure: canContain
    }
})
