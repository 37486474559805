import type {Deprecation} from '../apiUtils/deprecation'
import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils, deprecation: Deprecation) {
    return {
        methods: {
            deprecation: {
                setThrowOnDeprecation: publicMethodUtils.defineAction(deprecation.setThrowOnDeprecation)
            }
        }
    }
}
