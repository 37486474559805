define([
    'lodash',
    '@wix/editor-elements-preview-utils/skins',
    'documentServices/anchors/anchors',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/component/componentValidations',
    'documentServices/constants/constants',
    'documentServices/theme/theme',
    'document-services-schemas',
    'documentServices/theme/isSystemStyle',
    'documentServices/variants/variantsUtils',
    'documentServices/mobileUtilities/mobileUtilities',
    '@wix/santa-core-utils',
    'experiment',
    'documentServices/bi/bi',
    'documentServices/bi/events.json'
], function (
    _,
    editorElementsSkinsUtils,
    anchors,
    metaDataUtils,
    componentValidations,
    constants,
    theme,
    documentServicesSchemas,
    isSystemStyle,
    variantsUtils,
    mobileUtil,
    santaCoreUtils,
    experiment,
    bi,
    biEvents
) {
    'use strict'

    function fixStyleIdIfStyleIsMissing(privateServices, componentPointer, isFull, origStyleId) {
        if (!origStyleId || experiment.isOpen('dm_removeFixMissingStyleHack')) {
            return origStyleId
        }

        const pageId =
            !privateServices.runtimeConfig.stylesPerPage || isSystemStyle(origStyleId)
                ? constants.MASTER_PAGE_ID
                : _.get(privateServices.pointers.components.getPageOfComponent(componentPointer), 'id')
        const stylePointer = privateServices.pointers.data.getThemeItem(origStyleId, pageId)
        if (!privateServices.dal.isExist(stylePointer)) {
            const componentType = metaDataUtils.getComponentType(privateServices, componentPointer)
            const systemStyles = documentServicesSchemas.services.schemasService.getDefinition(componentType).styles
            const newStyleId = _.keys(systemStyles).sort()[0]
            if (!newStyleId) {
                return
            }
            setComponentStyleIdInternal(privateServices, componentPointer, newStyleId, _.noop, isFull)

            const params = {
                dsOrigin: privateServices.config.origin,
                componentType,
                componentId: componentPointer.id,
                style: origStyleId
            }
            bi.event(privateServices, biEvents.FIX_MISSING_COMPONENT_STYLE, params)
            return newStyleId
        }
        return origStyleId
    }

    const getThemeStyleIds = componentType => {
        const systemStyles = documentServicesSchemas.services.schemasService.getDefinition(componentType).styles
        const themeStyleIds = _.keys(systemStyles).sort()
        return themeStyleIds || []
    }

    function getComponentStyleIdInternal(privateServices, componentPointer, isFull) {
        let styleId = null
        if (privateServices && componentPointer) {
            const dal = isFull ? privateServices.dal.full : privateServices.dal
            styleId = dal.get(privateServices.pointers.getInnerPointer(componentPointer, 'styleId'))
            if (!experiment.isOpen('dm_fixMissingDefaultStyles')) {
                const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(privateServices, componentPointer, constants.DATA_TYPES.theme)
                const isWithVariants = privateServices.pointers.components.isWithVariants(componentPointer)
                //fix default style if needed
                if (!isWithVariants && shouldConsiderVariants) {
                    const nonScopedStylePointer = variantsUtils.getComponentDataPointerConsideringVariants(
                        privateServices,
                        componentPointer,
                        constants.DATA_TYPES.theme
                    )
                    if (!nonScopedStylePointer) {
                        return null
                    }
                    if (!dal.get(nonScopedStylePointer)) {
                        return fixStyleIdIfStyleIsMissing(privateServices, componentPointer, isFull, nonScopedStylePointer.id)
                    }
                }
            }

            styleId = fixStyleIdIfStyleIsMissing(privateServices, componentPointer, isFull, styleId)
        }
        return styleId
    }
    const getComponentStyleId = (privateServices, componentPointer) => {
        const compStyleId = getComponentStyleIdInternal(privateServices, componentPointer, false)
        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(privateServices, componentPointer, constants.DATA_TYPES.theme)
        if (compStyleId && shouldConsiderVariants) {
            const styleData = variantsUtils.getComponentDataConsideringVariants(privateServices, componentPointer, constants.DATA_TYPES.theme)
            return styleData && styleData.id
        }
        return compStyleId
    }

    const connectToThemeStyle = (ps, componentPointer, styleId, callback) => {
        if (!isSystemStyle(styleId)) {
            throw new Error('connectToThemeStyle called with custom style')
        }

        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)
        if (shouldConsiderVariants) {
            variantsUtils.connectToThemeStyleConsideringVariants(ps, componentPointer, styleId)
        } else {
            setComponentStyleId(ps, componentPointer, styleId, callback)
        }
    }

    const shouldOverrideExistingStyle = (componentPointer, shouldConsiderVariants) =>
        !shouldConsiderVariants && santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(componentPointer)

    /**
     * The function forks a style to a new custom style.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {object} newStyleItem When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
     * @param {boolean} isFull True to use full dal
     * @throws an exception if no corresponding component exists or invalid style value.
     * @return {String} New style Id
     */
    const forkStyleInternal = (ps, componentPointer, newStyleItem, isFull) => {
        const pointers = isFull ? ps.pointers.full : ps.pointers
        const pageId = pointers.components.getPageOfComponent(componentPointer).id

        const styleItemToUpdate = newStyleItem || getComponentStyle(ps, componentPointer)
        styleItemToUpdate.componentClassName = styleItemToUpdate.componentClassName || metaDataUtils.getComponentType(ps, componentPointer)

        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)

        const newStyleIdOverride = shouldOverrideExistingStyle(componentPointer, shouldConsiderVariants) ? styleItemToUpdate.id : undefined

        const newStyleId = theme.styles.internal.fork(ps, styleItemToUpdate, pageId, isFull, newStyleIdOverride)

        if (shouldConsiderVariants) {
            const forkedStyleData = theme.styles.get(ps, newStyleId)
            return variantsUtils.updateComponentDataConsideringVariants(ps, componentPointer, forkedStyleData, constants.DATA_TYPES.theme)
        }

        setComponentStyleIdInternal(ps, componentPointer, newStyleId, undefined, isFull)
        return newStyleId
    }

    const forkStyle = (ps, componentPointer, newStyleItem) => forkStyleInternal(ps, componentPointer, newStyleItem, false)

    /**
     * set a component's style
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {String} styleId
     * @param {Function} [callback]
     * @param {boolean} [isFull] True to use full dal
     * @throws an exception if no corresponding component exists or invalid style name.
     */
    function setComponentStyleIdInternal(ps, componentPointer, styleId, callback, isFull) {
        const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
        if (isWithVariants) {
            throw new Error("set component styleId isn't supported for scoped style, use style.connectToTheme or style.update instead")
        }

        let pageId = constants.MASTER_PAGE_ID
        if (ps.runtimeConfig.stylesPerPage) {
            const pointers = isFull ? ps.pointers.full : ps.pointers
            pageId = pointers.components.getPageOfComponent(componentPointer).id
        }

        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)
        if (shouldConsiderVariants) {
            variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, constants.DATA_TYPES.theme)
        }

        let styleDef = theme.styles.internal.get(ps, styleId, pageId, isFull)
        if (!styleDef) {
            styleId = createSystemStyle(ps, styleId, metaDataUtils.getComponentType(ps, componentPointer))
            styleDef = theme.styles.internal.get(ps, styleId, constants.MASTER_PAGE_ID, isFull)
        }
        const validationResult = componentValidations.validateSetStyleIdParams(ps, styleId, pageId)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        mobileUtil.syncMobileAndDesktopStyleId(ps, componentPointer, styleId, isFull)

        const dal = isFull ? ps.dal.full : ps.dal
        dal.set(ps.pointers.getInnerPointer(componentPointer, 'styleId'), styleId)

        ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
            if (callback) {
                callback({styleProperties: theme.styles.internal.get(ps, styleId, pageId, isFull).style.properties})
            }
            theme.events.onChange.executeListeners({type: 'STYLE', values: styleId})
        })

        // make sure compStructure.skin && the skin inside the style are aligned
        setComponentInnerPointerSkin(ps, componentPointer, styleDef.skin, isFull)
    }

    /**
     * set a component's style
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {string} styleId
     * @param [callback]
     * @throws an exception if no corresponding component exists or invalid style name. or if the component has variantRelated stuff
     */
    const setComponentStyleId = (ps, componentPointer, styleId, callback) => {
        if (variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)) {
            throw new Error("set component styleId isn't supported for scoped style, use style.connectToTheme or style.update instead")
        }
        setComponentStyleIdInternal(ps, componentPointer, styleId, callback, false)
    }

    function createSystemStyle(ps, styleId, componentType) {
        return theme.styles.createDefaultThemeStyle(ps, componentType, styleId)
    }

    function createComponentStyleDef(ps, skinName, compType) {
        return {
            compId: '',
            componentClassName: compType,
            pageId: '',
            styleType: constants.STYLES.TYPES.CUSTOM,
            type: constants.STYLES.COMPONENT_STYLE,
            skin: skinName,
            style: {
                groups: {},
                properties: getDefaultSkinParams(ps, skinName),
                propertiesSource: {}
            }
        }
    }

    function getDefaultSkinParams(ps, skinName) {
        return _.mapValues(theme.skins.getSkinDefinition(ps, skinName), 'defaultValue')
    }

    function setComponentInnerPointerSkin(ps, componentPointer, skinName, isFull) {
        const compSkinPointer = ps.pointers.getInnerPointer(componentPointer, 'skin')
        const dal = isFull ? ps.dal.full : ps.dal
        dal.set(compSkinPointer, skinName)
    }

    /**
     * set a new custom style for a component.
     * If no styleId is given - generates a new one.
     * If no skin is given - tries to use the current skin.
     * If no styleProperties are given - style will use the current styleProperties.
     * @param {ps} ps
     * @param {string} [newStyleId] the requested id of the new custom style
     * @param {Pointer} componentPointer
     * @param {string} [optionalSkinName] the name of the skin the style will use
     * @param {Object} [optionalStyleProperties] the skin parameters that the style wants to override
     * return {string} the id of the created custom style
     */
    // @ts-ignore
    function setComponentCustomStyle(ps, newStyleId, componentPointer, optionalSkinName, optionalStyleProperties) {
        if (!ps.dal.isExist(componentPointer)) {
            throw new Error('component param does not exist')
        }

        if (variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)) {
            throw new Error("set component custom style isn't supported for scoped styles, use style.update instead")
        }

        const validationResult = componentValidations.validateComponentCustomStyleParams(ps, optionalSkinName, optionalStyleProperties)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        const compId = componentPointer.id
        const componentClassName = metaDataUtils.getComponentType(ps, componentPointer)

        let newStyleProperties
        let newStylePropertiesSource

        if (optionalStyleProperties) {
            newStyleProperties = optionalStyleProperties
            newStylePropertiesSource = generateStylePropertiesSource(ps, newStyleProperties)
        } else if (optionalSkinName) {
            // currentStyleProperties no longer relevant for the new skin
            newStyleProperties = {}
            newStylePropertiesSource = {}
        } else {
            const currentStyle = getComponentStyle(ps, componentPointer)
            // use the current, or a default empty object if no style is currently used
            newStyleProperties = _.get(currentStyle, 'style.properties', {})
            newStylePropertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
        }

        const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id
        const newStyle = {
            id: newStyleId,
            compId,
            componentClassName,
            pageId: '',
            styleType: 'custom',
            type: 'TopLevelStyle',
            skin: optionalSkinName || getComponentSkin(ps, componentPointer),
            style: {
                groups: {},
                properties: newStyleProperties,
                propertiesSource: newStylePropertiesSource
            }
            //todo Shimi_Liderman 12/14/14 15:42 current custom style have metadata. Is it needed for new styles?
        }

        theme.styles.update(ps, newStyleId, newStyle, pageId)
        setComponentStyleId(ps, componentPointer, newStyleId || '')
    }

    /**
     * @param {ps} ps
     * @param {Object} properties style properties
     * @return {Object.<string, string>} the style properties mapped to a "value" or "theme" source
     */
    function generateStylePropertiesSource(ps, properties) {
        return _.mapValues(properties, function (value) {
            if (_.isNumber(value) || _.isBoolean(value) || (_.isString(value) && (_.includes(value, '#') || _.includes(value, 'rgb')))) {
                return 'value'
            }
            return /^(font|color)_[0-9]+$/.test(value) ? 'theme' : 'value'
        })
    }

    function updateComponentStyleInternal(ps, componentPointer, styleValue, callback, isFull = false) {
        updateComponentStyleAndAdjustLayout(ps, componentPointer, styleValue, callback, isFull)
        anchors.updateAnchors(ps, componentPointer)
    }

    function updateComponentStyle(ps, componentPointer, styleValue, callback) {
        const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
        if (isWithVariants) {
            return updateComponentStyleAndAdjustLayout(ps, componentPointer, styleValue, callback, false)
        }
        return updateComponentStyleInternal(ps, componentPointer, styleValue, callback, false)
    }

    function updateSingleStyle(ps, componentPointer, currentStyle, newStyleValue) {
        const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id

        _.set(newStyleValue, ['style', 'propertiesSource'], generateStylePropertiesSource(ps, newStyleValue.style.properties))

        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)
        if (shouldConsiderVariants) {
            return variantsUtils.updateComponentDataConsideringVariants(ps, componentPointer, newStyleValue, constants.DATA_TYPES.theme)
        }
        theme.styles.update(ps, currentStyle.id, newStyleValue, pageId)
        return currentStyle.id
    }

    function updateComponentStyleAndAdjustLayout(ps, componentPointer, styleValue, callback, isFull) {
        const validationResult = componentValidations.validateExistingComponent(ps, componentPointer, isFull)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        const currentStyle = getComponentStyle(ps, componentPointer)

        let updatedStyleId
        if (!currentStyle) {
            updatedStyleId = forkStyleInternal(ps, componentPointer, styleValue, isFull)
        } else {
            updatedStyleId = currentStyle.id
            if (currentStyle.styleType === 'system') {
                updatedStyleId = forkStyle(ps, componentPointer, styleValue)
            } else {
                styleValue.componentClassName =
                    styleValue.componentClassName || currentStyle.componentClassName || metaDataUtils.getComponentType(ps, componentPointer)
                updateSingleStyle(ps, componentPointer, currentStyle, styleValue)
            }
        }
        ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
            if (callback) {
                callback({styleProperties: styleValue.style.properties})
            }
            theme.events.onChange.executeListeners({type: 'STYLE', values: updatedStyleId})
        })

        // make sure compStructure.skin && the skin inside the style are aligned

        setComponentInnerPointerSkin(ps, componentPointer, styleValue.skin, isFull)
    }

    /**
     * set a component's skin
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {string} skinName
     */
    function setComponentSkin(ps, componentPointer, skinName) {
        const validationResult = componentValidations.validateSetSkinParams(ps, componentPointer, skinName)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }
        setComponentInnerPointerSkin(ps, componentPointer, skinName)
    }

    /**
     * gets the skin name from style if exists, otherwise - from structure
     * @param {ps} privateServices
     * @param {Pointer} componentPointer
     * @return {String} the name of the Skin corresponding the Component Reference. 'null' otherwise.
     */
    function getComponentSkin(privateServices, componentPointer) {
        let skinName = null
        if (componentPointer) {
            const componentStyle = getComponentStyle(privateServices, componentPointer)
            if (componentStyle && componentStyle.skin) {
                skinName = componentStyle.skin
            } else {
                // get skin name from structure.
                skinName = privateServices.dal.get(privateServices.pointers.getInnerPointer(componentPointer, 'skin'))
            }
        }
        return skinName
    }

    function getComponentSkinExports(privateServices, componentPointer) {
        const skinName = getComponentSkin(privateServices, componentPointer)
        return editorElementsSkinsUtils.getSkinExports(skinName)
    }

    function getComponentSkinDefaults(privateServices, componentPointer) {
        const skinName = getComponentSkin(privateServices, componentPointer)
        return editorElementsSkinsUtils.getSkinDefaultParams(skinName)
    }

    function getCompSkinParamValue(privateServices, componentPointer, paramValue) {
        const compStyle = getComponentStyle(privateServices, componentPointer).style.properties || {}
        const skinDefaults = getComponentSkinDefaults(privateServices, componentPointer)
        if (_.isArray(skinDefaults[paramValue]) && skinDefaults[paramValue].length === 1) {
            return compStyle[skinDefaults[paramValue]]
        }
        return skinDefaults[paramValue]
    }

    function getComponentStyleInternal(ps, componentPointer, isFull) {
        const compStyleId = getComponentStyleIdInternal(ps, componentPointer, isFull)
        if (compStyleId) {
            const pointers = isFull ? ps.pointers.full : ps.pointers
            const pageId = pointers.components.getPageOfComponent(componentPointer).id
            return theme.styles.internal.get(ps, compStyleId, pageId, isFull)
        }
        return null
    }
    const getComponentStyle = (ps, componentPointer) => {
        const componentStyleInternal = getComponentStyleInternal(ps, componentPointer, false)
        const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)

        if (shouldConsiderVariants) {
            return variantsUtils.getComponentDataConsideringVariants(ps, componentPointer, constants.DATA_TYPES.theme)
        }
        return componentStyleInternal
    }

    const removeScopedStyle = (ps, compPointer) => {
        const isWithVariants = ps.pointers.components.isWithVariants(compPointer)
        if (!isWithVariants) {
            throw new Error('cannot remove non scoped style')
        }
        variantsUtils.removeComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.theme)
    }

    return {
        style: {
            /**
             * set a component's style
             * @param {Pointer} componentReference
             * @param {String} styleId
             * @throws an exception if no corresponding component exists or invalid style name.
             */
            setId: setComponentStyleId,

            /**
             * get the component's style id
             * @param {Pointer} componentReference
             * @return {String} the Style ID of the corresponding component. 'null' otherwise.
             */
            getId: getComponentStyleId,

            /**
             * get the component's style
             * @param {Pointer} componentReference
             * @return {object} the Style of the corresponding component.
             */
            get: getComponentStyle,

            /**
             * set a new custom style for a component.
             * If no styleId is given - generates a new one.
             * If no skin is given - tries to use the current skin.
             * If no styleProperties are given - style will use the current styleProperties.
             * @param {Pointer} componentReference
             * @param {string} [optionalSkinName] the name of the skin the style will use
             * @param {Object} [optionalStyleProperties] the skin parameters that the style wants to override
             * @param {string} [optionalStyleId] the requested id of the new custom style
             * @return {string} the id of the created custom style
             */
            setCustom: setComponentCustomStyle,

            /**
             * The function updates a component's style definition value
             * @param {Pointer} componentReference
             * @param {object} styleValue style objects we want to update. system style will be cloned to custom style
             * @throws an exception if no corresponding component exists or invalid style value.
             */
            update: updateComponentStyle,

            /**
             * The function updates a component's style definition value without updating anchors
             * @param {Pointer} componentReference
             * @param {object} styleValue style objects we want to update
             * @throws an exception if no corresponding component exists or invalid style value.
             */
            updateAndAdjustLayout: updateComponentStyleAndAdjustLayout,

            /**
             * The function creates and add a new system style
             * @param {object} styleValue style objects we want to update
             * @param {object} component type
             * @throws an exception if no corresponding component exists or invalid style value.
             */
            createSystemStyle,

            /**
             * connect component to system style
             * @param {Pointer} componentReference
             * @param {String} system styleId
             * @throws an exception if no style isn't a system style
             */
            connectToThemeStyle,

            /**
             * The function forks a style to a new custom style.
             * @param {Pointer} componentReference
             * @param {object} styleValue. When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
             * @throws an exception if no corresponding component exists or invalid style value.
             * * @return {String} New style Id
             */
            fork: forkStyle,

            /**
             * The function removes the matching variant's scoped style if exists
             * @param {Pointer} componentPointerWithVariants
             * @throws an exception if current component pointer is without variants
             */
            removeScoped: removeScopedStyle,
            /**
             * @param {string} componentType
             * @returns {string[]} an array of the possible theme style ids
             */
            getThemeStyleIds,

            internal: {
                /**
                 * set a component's style
                 * @param {Pointer} componentReference
                 * @param {String} styleId
                 * @param {function} callback - will be called with { styleProperties: object }
                 * @param {boolean} True to use full dal
                 * @throws an exception if no corresponding component exists or invalid style name.
                 */
                setId: setComponentStyleIdInternal,

                /**
                 * get the component's style id
                 * @param {Pointer} componentReference
                 * @param {boolean} True to use full dal
                 * @return {String} the Style ID of the corresponding component. 'null' otherwise.
                 */
                getId: getComponentStyleIdInternal,

                /**
                 * get the component's style
                 * @param {Pointer} componentReference
                 * @param {boolean} True to use full dal
                 * @return {object} the Style of the corresponding component.
                 */
                get: getComponentStyleInternal,

                /**
                 * The function forks a style to a new custom style.
                 * @param {Pointer} componentReference
                 * @param {object} styleValue. When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
                 * @param {boolean} True to use full dal
                 * @throws an exception if no corresponding component exists or invalid style value.
                 * * @return {String} New style Id
                 */
                fork: forkStyleInternal,

                update: updateComponentStyleInternal,

                generateStylePropertiesSource,
                createComponentStyleDef
            }
        },
        /** @class documentServices.components.skin*/
        skin: {
            /**
             * set a component's skin
             * @param {Pointer} componentReference
             * @param {String} skinName
             * @example documentServices.components.skin.set(photoCompRef, "wysiwyg.viewer.skins.photo.RoundPhoto");
             */
            set: setComponentSkin,
            /**
             * gets the skin name from style if exists, otherwise - from structure
             * @param {Pointer} componentReference
             * @return {String} the name of the Skin corresponding the Component Reference. 'null' otherwise.
             */
            get: getComponentSkin,

            getComponentSkinExports,
            getComponentSkinDefaults, //no one is calling this - need to remove
            getCompSkinParamValue
        }
    }
})
