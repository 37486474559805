define([
    'lodash',
    'documentServices/structure/structure',
    'documentServices/component/component',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/mobileUtilities/mobileUtilities'
], function (_, structure, component, appStudioDataModel, mobileUtil) {
    'use strict'

    function copyRootComponentDockedLayout(ps, pagesPointers) {
        _.forEach(
            _.filter(pagesPointers, ({id}) => appStudioDataModel.isWidgetPage(ps, id)),
            pagePointer => {
                const desktopRootComp = _.head(component.getChildren(ps, ps.pointers.components.getDesktopPointer(pagePointer)))
                const desktopDockData = structure.getDock(ps, desktopRootComp)

                if (!_.isEmpty(desktopDockData)) {
                    mobileUtil.syncDockData(ps, desktopRootComp, desktopDockData, structure)
                }
            }
        )
    }

    return {
        copyRootComponentDockedLayout
    }
})
