define([
    'lodash',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/theme/theme',
    'documentServices/component/componentModes',
    'documentServices/dataModel/dataModel',
    'documentServices/bi/bi',
    'documentServices/constants/constants',
    'documentServices/theme/isSystemStyle',
    'documentServices/bi/events.json',
    'documentServices/documentServicesDataFixer/fixers/styles/utils',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/documentServicesDataFixer/fixers/utils/variantThemeUtils',
    'documentServices/variants/variantsUtils',
    'documentServices/dataModel/dataIds'
], function (
    _,
    componentDetectorAPI,
    componentStyleAndSkinAPI,
    theme,
    componentModes,
    dataModel,
    bi,
    constants,
    isSystemStyle,
    biEvents,
    styleFixerUtils,
    mobileUtil,
    extensionsAPI,
    variantThemeUtils,
    variantsUtils,
    dataIds
) {
    'use strict'

    const numStylesInPagesAfterMigration = {}
    const {DATA_TYPES} = constants

    const splitStyle = (ps, styleToMigrate, pageId, comp, stylesToRemove) => {
        const originStyleDef = theme.styles.get(ps, styleToMigrate, pageId)
        const newStyleId = theme.styles.internal.fork(ps, originStyleDef, pageId, true)

        const compOverrides = componentModes.overrides.getAllOverrides(ps, comp)
        const overrideOfCurrentStyle = _.find(compOverrides, ['styleId', styleToMigrate])
        if (overrideOfCurrentStyle) {
            overrideOfCurrentStyle.styleId = newStyleId
            componentModes.overrides.updateComponentOverrides(ps, comp, compOverrides)
        } else {
            componentStyleAndSkinAPI.style.internal.setId(ps, comp, newStyleId, null, true)
        }

        if (!stylesToRemove[styleToMigrate]) {
            stylesToRemove[styleToMigrate] = styleToMigrate
        }

        numStylesInPagesAfterMigration[pageId]++

        return newStyleId
    }

    const checkStyleAndMigrateIfNeeded = (ps, mode, styleToMigrate, pageId, comp, compIdToStyleIds, stylesToRemove) => {
        const isDesktop = mode === constants.VIEW_MODES.DESKTOP
        const currCompIdToStyleId = compIdToStyleIds[mode]
        const desktopCompIdToStyleId = compIdToStyleIds[constants.VIEW_MODES.DESKTOP]
        const styleAlreadyMigratedInDesktop =
            desktopCompIdToStyleId && Array.isArray(desktopCompIdToStyleId[comp.id]) && desktopCompIdToStyleId[comp.id].includes(styleToMigrate)

        if (!isSystemStyle(styleToMigrate)) {
            if (isDesktop || !styleAlreadyMigratedInDesktop) {
                const newStyleId = splitStyle(ps, styleToMigrate, pageId, comp, stylesToRemove)
                currCompIdToStyleId[comp.id] = (currCompIdToStyleId[comp.id] || []).concat(newStyleId)
            }
        }
    }

    /**
     *
     * @param ps
     * @param comp - pointer
     * @param compStyleId - will point to refArray
     * @param pageId
     * @param stylesToRemove
     * @param compIdToStyleIds - map consider if comp was already migrated
     * @param mode - desktop/mobile
     */
    const migrateComponentVariantStyles = (ps, comp, compStyleId, pageId, stylesToRemove, compIdToStyleIds, mode) => {
        const styleIdToNew = {}
        const currCompIdToStyleId = compIdToStyleIds[mode]
        const desktopCompIdToStyleId = compIdToStyleIds[constants.VIEW_MODES.DESKTOP]
        const refArray = theme.styles.get(ps, compStyleId, pageId)
        const variantRelations = []
        const breakpointRelations = []

        // just init to work with array always
        if (!desktopCompIdToStyleId[comp.id]) {
            desktopCompIdToStyleId[comp.id] = []
        }

        // if component was already fixed for desktop, we don't need to fix it once more on mobile
        if (desktopCompIdToStyleId[comp.id].includes(refArray.id)) {
            return
        }

        /**
         * here we iterate for all possible styles and references in theme data connect to component
         */
        variantThemeUtils.applyToEachComponentStyleAndReference(ps, comp, style => {
            if (isSystemStyle(style.id) || desktopCompIdToStyleId[comp.id].includes(style.id)) {
                return
            }
            // we will do things with everything here except of real system styles
            currCompIdToStyleId[comp.id] = (currCompIdToStyleId[comp.id] || []).concat(style.id)

            // we don't collect refArray, we already have it
            if (dataModel.refArray.isRefArray(ps, style)) {
                return
            }

            // we collect all variant relations to fix them later
            if (dataModel.variantRelation.isVariantRelation(ps, style)) {
                variantRelations.push(style)
                return
            }

            // we don't want to fix it, it should be impossible to get here
            // but in case it happened somehow we don't want to fork it
            if (dataModel.breakpointRelation.isBreakpointRelation(ps, style)) {
                breakpointRelations.push(style)
                return
            }

            // I don't use componentStyleAndSkin because we don't have pointers for variant data on santa
            // by forking styles we fix pageId for them
            styleIdToNew[style.id] = theme.styles.internal.fork(ps, style, pageId, true)
            if (!stylesToRemove[style.id]) {
                desktopCompIdToStyleId[comp.id].push(style.id)
                stylesToRemove[style.id] = style
            }
        })

        // for all breakpoints of component we update `ref` reference if needed and pageId
        _.forEach(breakpointRelations, breakpoint => {
            const newBreakpoint = {
                ..._.cloneDeep(breakpoint),
                id: dataIds.generateNewId(DATA_TYPES.theme)
            }

            _.set(newBreakpoint, ['metaData', 'pageId'], pageId)

            const refStyleId = dataModel.breakpointRelation.extractRefWithoutHash(ps, breakpoint)

            if (styleIdToNew[refStyleId]) {
                desktopCompIdToStyleId[comp.id].push(refStyleId)
                newBreakpoint.ref = `#${styleIdToNew[refStyleId]}`
            }

            styleIdToNew[breakpoint.id] = newBreakpoint.id

            ps.dal.full.set(ps.pointers.data.getThemeItem(newBreakpoint.id, pageId), newBreakpoint)
            if (!stylesToRemove[breakpoint.id]) {
                stylesToRemove[breakpoint.id] = breakpoint
            }
        })

        // for all variants of component we update `to` reference if needed and pageId
        _.forEach(variantRelations, variant => {
            const newVariant = {
                ..._.cloneDeep(variant),
                id: dataIds.generateNewId(DATA_TYPES.theme)
            }

            _.set(newVariant, ['metaData', 'pageId'], pageId)

            const toStyleId = dataModel.variantRelation.extractTo(ps, variant)

            if (styleIdToNew[toStyleId]) {
                desktopCompIdToStyleId[comp.id].push(toStyleId)
                newVariant.to = `#${styleIdToNew[toStyleId]}`
            }

            styleIdToNew[variant.id] = newVariant.id

            ps.dal.full.set(ps.pointers.data.getThemeItem(newVariant.id, pageId), newVariant)
            if (!stylesToRemove[variant.id]) {
                stylesToRemove[variant.id] = variant
            }
        })

        // updating ref array relations to new if needed and pageId
        const updatedValues = dataModel.refArray.extractValuesWithoutHash(ps, refArray).map(styleId => {
            if (styleIdToNew[styleId]) {
                return styleIdToNew[styleId]
            }

            return styleId
        })
        const updatedRefArray = {
            ...dataModel.refArray.update(ps, refArray, updatedValues),
            id: dataIds.generateNewId(DATA_TYPES.theme)
        }
        _.set(updatedRefArray, ['metaData', 'pageId'], pageId)
        ps.dal.full.set(ps.pointers.data.getThemeItem(updatedRefArray.id, pageId), updatedRefArray)

        // this doesn't work for santa with refArray?
        // since we have tests for it and it is not dead yet will be done directly
        // componentStyleAndSkinAPI.style.internal.setId(ps, comp, updatedRefArray.id, _.noop, true)
        const styleIdPointer = ps.pointers.getInnerPointer(comp, 'styleId')
        ps.dal.full.set(styleIdPointer, updatedRefArray.id)
        // we will be here just in desktop, but just in case I prefer to be sure
        if (comp.type === constants.VIEW_MODES.DESKTOP) {
            mobileUtil.syncMobileAndDesktopStyleId(ps, comp, updatedRefArray.id, true)
        }
        desktopCompIdToStyleId[comp.id].push(updatedRefArray.id)

        if (!stylesToRemove[refArray.id]) {
            stylesToRemove[refArray.id] = refArray
        }
    }

    const migrateStyles = (ps, mode, compIdToStyles, stylesToRemove) => {
        const comps = componentDetectorAPI.getAllComponentsFromFull(
            ps,
            null,
            comp => {
                const isPage = ps.pointers.components.isPage(comp)
                const isExist = ps.dal.full.isExist(comp)
                return !isPage && isExist
            },
            mode
        )

        _.forEach(comps, comp => {
            const pagePointer = ps.pointers.full.components.getPageOfComponent(comp)
            const pageId = pagePointer.id
            const overrides = componentModes.overrides.getAllOverrides(ps, comp)
            const styleIds = []
            const compStyleId = ps.dal.full.get(ps.pointers.getInnerPointer(comp, 'styleId'))
            const compStyleItem = theme.styles.get(ps, compStyleId, pageId)

            if (variantsUtils.shouldConsiderVariants(ps, comp, DATA_TYPES.theme) || dataModel.refArray.isRefArray(ps, compStyleItem)) {
                migrateComponentVariantStyles(ps, comp, compStyleId, pageId, stylesToRemove, compIdToStyles, mode)
                return
            }

            if (compStyleItem && !isSystemStyle(compStyleId)) {
                styleIds.push(compStyleId)
            }
            // component with refArray can not have overrides
            const hasOverriddenCustomStyle = _.some(overrides, ovr => !isSystemStyle(ovr.styleId))

            if (hasOverriddenCustomStyle) {
                styleIds.push(..._.compact(_.map(overrides, 'styleId')))
            }

            if (styleIds.length > 0) {
                _(styleIds)
                    .uniq()
                    .forEach(styleToMigrate => {
                        checkStyleAndMigrateIfNeeded(ps, mode, styleToMigrate, pageId, comp, compIdToStyles, stylesToRemove)
                    })
            }
        })
    }

    const reportMigrationResult = (ps, numStylesInMasterPagesBefore, duration) => {
        numStylesInPagesAfterMigration[constants.MASTER_PAGE_ID] = theme.styles.getAllIds(ps, 'masterPage').length
        const allPageIds = extensionsAPI.pages.getAllPagesIds(ps)
        const NUM_PAGES_IN_BI_EVENT = 100
        let pagesToReport = allPageIds.splice(0, NUM_PAGES_IN_BI_EVENT)
        const dsOrigin = ps.config.origin
        while (pagesToReport.length) {
            const pagesStylesAfter = _.reduce(
                pagesToReport,
                (stylesInPage, pageId) => {
                    stylesInPage[pageId] = numStylesInPagesAfterMigration[pageId]
                    return stylesInPage
                },
                {}
            )

            const params = {
                mp_styles_before: numStylesInMasterPagesBefore,
                mp_styles_after: numStylesInPagesAfterMigration[constants.MASTER_PAGE_ID],
                page_styles_after: JSON.stringify(pagesStylesAfter),
                errorReason: 'succeed',
                duration,
                dsOrigin
            }
            bi.event(ps, biEvents.MOVE_STYLE_TO_PAGES_MIGRATION, params)
            pagesToReport = allPageIds.splice(0, NUM_PAGES_IN_BI_EVENT)
        }
    }

    const removeStylesFromMasterPage = (ps, stylesToRemove) => {
        _.forEach(_.keys(stylesToRemove), styleId => theme.styles.remove(ps, styleId, 'masterPage'))
    }

    const initNumStylesMap = ps => {
        const allPageIds = extensionsAPI.pages.getAllPagesIds(ps)
        allPageIds.forEach(pageId => {
            numStylesInPagesAfterMigration[pageId] = 0
        })
    }

    return {
        /**
         * In general fixer's logic is:
         * - Get all existing components
         * - Find every connected theme data (styles, references) that has to be moved (1)
         * - Migrate every item(2) to component's page
         * - Remove old style items from store
         *
         * 1 - every theme item that is not in component's page and is not real system style (isSystemStyle from SchemaService)
         * 2 - in case when we have variants it is an different flow and work closer with component
         *
         * @param {ps} ps
         */
        exec(ps) {
            styleFixerUtils.markSiteAsMigrated(ps)

            const numStylesInMasterPagesBefore = theme.styles.getAllIds(ps, constants.MASTER_PAGE_ID).length

            initNumStylesMap(ps)

            const orphanPointer = ps.pointers.general.getOrphanPermanentDataNodes()
            let orphanStyles = ps.dal.get(orphanPointer)

            const stylesToRemove = {}
            const compIdToStyles = {
                [constants.VIEW_MODES.DESKTOP]: {},
                [constants.VIEW_MODES.MOBILE]: {}
            }

            mobileUtil.getSupportedViewModes(ps).forEach(mode => migrateStyles(ps, mode, compIdToStyles, stylesToRemove))

            orphanStyles = orphanStyles.concat(..._.keys(stylesToRemove))
            removeStylesFromMasterPage(ps, stylesToRemove)
            ps.dal.set(orphanPointer, orphanStyles)

            reportMigrationResult(ps, numStylesInMasterPagesBefore, 0)
        },
        name: 'moveCustomStylesToPagesFixer',
        version: 1
    }
})
