define([], function () {
    'use strict'

    return {
        isRepeatable: false,
        resizableSides: [],

        mobileConversionConfig: {
            preserveAspectRatio: false
        }
    }
})
