import {
    pointerUtils,
    HistoryItem,
    DocumentDataTypes,
    Extension,
    Transaction,
    CreateExtArgs,
    PointerMethods,
    ExtensionAPI,
    InitializeExtArgs,
    DAL
} from '@wix/document-manager-core'
import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'
const {getGUID: generateGUID} = guidUtils

const {getPointer} = pointerUtils

const platformSharedManifestState = 'platformSharedManifestState'
const platformSharedAPIState = 'platformSharedAPIState'

type AppStateManifestListener = (appDefId: string) => void
type PlatformAPICallsListener = (appDefId: string) => void

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [platformSharedAPIState]: {},
    [platformSharedManifestState]: {}
})

const EVENTS = {
    CONTEXT: {
        API_CALLED: 'API_CALLED'
    }
}

export interface PlatformSharedState extends ExtensionAPI {
    platformSharedState: {
        notifyManifestWasChanged(appDefId: string): void
        subscribeToManifestWasChanges(listener: AppStateManifestListener): void
        notifyPlatformAPIWasCalled(appDefId: string): void
        subscribeToPlatformAPICalls(appDefId: string, listener: PlatformAPICallsListener): void
        unsubscribeToPlatformAPICalls(appDefId: string): void
    }
}

const createExtension = (): Extension => {
    const localChangesMap = new Map()

    const appManifestUpdatedListeners: AppStateManifestListener[] = []
    const platformAPICallsListeners: Record<string, PlatformAPICallsListener> = {}
    const notifyManifestChangeListeners = (appDefIds: string[]) => {
        appManifestUpdatedListeners.forEach(listener => {
            try {
                _.forEach(appDefIds, appDefId => listener(appDefId))
            } catch (e) {
                console.error(e)
            }
        })
    }

    const notifyAPIChangeListeners = (appDefIds: string[]) => {
        appDefIds.forEach(appDefId => {
            try {
                if (platformAPICallsListeners[appDefId]) {
                    platformAPICallsListeners[appDefId](appDefId)
                }
            } catch (e) {
                console.error(e)
            }
        })
    }

    const findTransactionFromType = (sharedChangesUpdates: HistoryItem[], keyToFind: string) =>
        _(sharedChangesUpdates)
            .map((item: HistoryItem) => {
                const {
                    value: updatedSharedState,
                    key: {id: appDefId}
                } = item
                const keyInLocalMap = `${keyToFind}_${appDefId}`
                const localGuid = localChangesMap.get(keyInLocalMap)
                const sharedGuid: string = _.get(updatedSharedState, ['guid'])
                const diffFromLocalState = localGuid !== sharedGuid
                localChangesMap.set(keyInLocalMap, sharedGuid)
                return diffFromLocalState ? appDefId : null
            })
            .compact()
            .value()

    const pointers = {
        getPlatformSharedManifestState: (appDefId: string) => getPointer(appDefId, platformSharedManifestState),
        getPlatformAPISharedState: (appDefId: string) => getPointer(appDefId, platformSharedAPIState)
    }

    const notifyPlatformAPIWasCalledInternal = (dal: DAL, appDefId: string) => {
        const changeGuid = generateGUID()
        const sharedPointer = pointers.getPlatformAPISharedState(appDefId)
        dal.set(sharedPointer, {guid: changeGuid})
        localChangesMap.set(`${platformSharedAPIState}_${appDefId}`, changeGuid)
    }

    const createPointersMethods = (): PointerMethods => ({
        platform: pointers
    })
    const initialState = {}

    const createExtensionAPI = ({dal}: CreateExtArgs): PlatformSharedState => ({
        platformSharedState: {
            notifyManifestWasChanged: (appDefId: string) => {
                const changeGuid = generateGUID()
                const sharedPointer = pointers.getPlatformSharedManifestState(appDefId)
                dal.set(sharedPointer, {guid: changeGuid})
                localChangesMap.set(`${platformSharedManifestState}_${appDefId}`, changeGuid)
            },

            subscribeToManifestWasChanges: (listener: AppStateManifestListener) => {
                if (_.isFunction(listener)) {
                    appManifestUpdatedListeners.push(listener)
                }
            },
            notifyPlatformAPIWasCalled: appDefId => notifyPlatformAPIWasCalledInternal(dal, appDefId),
            subscribeToPlatformAPICalls: (appDefinitionId: string, listener: PlatformAPICallsListener) => {
                if (_.isFunction(listener)) {
                    platformAPICallsListeners[appDefinitionId] = listener
                }
            },
            unsubscribeToPlatformAPICalls: (appDefinitionId: string) => {
                delete platformAPICallsListeners[appDefinitionId]
            }
        }
    })

    const createPostTransactionOperations = () => ({
        platform: (documentTransaction: Transaction) => {
            const sharedAppManifestChangesUpdates = documentTransaction.items.filter(e => e?.key?.type === platformSharedManifestState)
            const appsWithUpdatedManifest = findTransactionFromType(sharedAppManifestChangesUpdates, platformSharedManifestState)

            const sharedAppAPIChangesUpdates = documentTransaction.items.filter(e => e?.key?.type === platformSharedAPIState)

            const appsWithPlatformAPICalls = _.uniq(findTransactionFromType(sharedAppAPIChangesUpdates, platformSharedAPIState))
            if (!_.isEmpty(appsWithUpdatedManifest)) {
                notifyManifestChangeListeners(appsWithUpdatedManifest)
            }

            if (!_.isEmpty(appsWithPlatformAPICalls)) {
                notifyAPIChangeListeners(appsWithPlatformAPICalls)
            }
        }
    })

    const initialize = async ({eventEmitter, dal}: InitializeExtArgs) => {
        eventEmitter.on(EVENTS.CONTEXT.API_CALLED, (appDefId: string) => notifyPlatformAPIWasCalledInternal(dal, appDefId))
    }

    return {
        name: 'platformSharedState',
        getDocumentDataTypes,
        createPointersMethods,
        initialState,
        createExtensionAPI,
        createPostTransactionOperations,
        initialize,
        EVENTS
    }
}

export {createExtension, EVENTS}
