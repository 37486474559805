import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixappsLogger from '@wix/santa-ds-libs/src/wixappsCore/src/core/wixappsLogger'

const appPages = {
    '79f391eb-7dfc-4adf-be6e-64434c4838d9': {
        parse(siteData, urlExtraData) {
            const splitUri = urlExtraData.split('/')
            const params: any = {}
            params.filter = {}
            if (splitUri[0] === 'Featured') {
                params.filter.featured = true
                splitUri.shift()
            }
            for (let i = 0; i < splitUri.length; i += 2) {
                switch (splitUri[i]) {
                    case 'Type':
                        params.filter._type = splitUri[i + 1]
                        break
                    case 'Tag':
                    case 'tag':
                        const tagsFilter = decodeURIComponent(splitUri[i + 1])
                        if (_.includes(tagsFilter, '-')) {
                            params.filter.tags = {$in: [tagsFilter, tagsFilter.replace(/-/g, ' ')]}
                        } else {
                            params.filter.tags = tagsFilter
                        }
                        break
                    case 'Category':
                    case 'category':
                        const categoryNameWithDashes = decodeURIComponent(splitUri[i + 1])
                        const categoryNameWithSpaces = categoryNameWithDashes.replace(/-/g, ' ')
                        params.categoryNames = JSON.stringify([categoryNameWithDashes, categoryNameWithSpaces])
                        break
                    case 'Page':
                    case 'page':
                        params.page = splitUri[i + 1]
                        break
                    case 'Author':
                    case 'author':
                        const authorFilter = decodeURIComponent(splitUri[i + 1])
                        if (_.includes(authorFilter, '-')) {
                            params.filter.author = {$in: [authorFilter, authorFilter.replace(/-/g, ' ')]}
                        } else {
                            params.filter.author = authorFilter
                        }
                        break
                    case 'Sort':
                        params.sort = splitUri[i + 1]
                        break
                    case 'Date':
                    case 'date':
                        const date = splitUri[i + 1]
                        params.filter['date.iso'] = {$regex: `^${date}`}
                }
            }
            return params
        }
    },
    '7326bfbb-4b10-4a8e-84c1-73f776051e10': {
        parse(siteData, urlExtraData) {
            const params: any = {}
            params.limit = 1

            params.filter = {}
            params.filter.permalink = {$in: [urlExtraData, decodeURIComponent(urlExtraData)]}

            return params
        }
    }
}

/**
 * @class wixappsCore.wixappsUrlParser
 */
export default {
    /**
     *
     * @param {core.SiteData} siteData
     * @param {site.pageUrlInfo} [urlData]
     * @returns {object}
     */
    getAppPageParams(siteData, urlData?) {
        //will work as long as no item pages in popup
        const pageInfo = urlData || siteData.getExistingRootNavigationInfo(siteData.getPrimaryPageId())
        const pageDataItem = siteData.getDataByQuery(pageInfo.pageId)

        if (pageDataItem.type === 'AppPage' && appPages[pageDataItem.appPageId]) {
            const urlExtraData = pageInfo.pageAdditionalData || ''
            return appPages[pageDataItem.appPageId].parse(siteData, urlExtraData)
        }
        return null
    },

    /**
     *
     * @param {core.SiteData} siteData
     * @param {site.pageUrlInfo} [urlData]
     */
    getPageSubItemId(siteData, urlData?) {
        //will work as long as no item pages in popup
        const primaryRootInfo = siteData.getExistingRootNavigationInfo(siteData.getPrimaryPageId())
        const pageInfo = urlData || primaryRootInfo
        if (!pageInfo.pageAdditionalData) {
            return null
        }
        const pageDataItem = siteData.getDataByQuery(pageInfo.pageId)
        const pageItemDataItem = pageInfo.pageItemId && siteData.getDataByQuery(pageInfo.pageItemId)

        if (pageDataItem.type === 'AppPage' || (pageItemDataItem && pageItemDataItem.type === 'PermaLink')) {
            const match = pageInfo.pageAdditionalData.match(/(.+?)($|\/.*$)/i)
            return match[1]
        }
        return null
    },

    getAppPartZoomAdditionalDataPart(siteData, itemId, itemTitle, mutableSeoTitle) {
        const encodedTitle = encodeURIComponent(itemTitle.toLowerCase().replace(/[\s|\/]+/gi, '-')) //replace spaces and slashes with dash
        if (siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH)) {
            return [itemId, mutableSeoTitle, encodedTitle].join('/')
        }
        return `${itemId}/${encodedTitle}`
    },

    /**
     *
     * @param {core.SiteData} siteData
     * @param permaLinkId
     * @param itemId
     * @param itemTitle
     */
    getAppPartZoomUrl(siteData, permaLinkId, itemId, itemTitle) {
        const permaLink = siteData.getDataByQuery(permaLinkId)
        if (!permaLink) {
            wixappsLogger.reportError(siteData, wixappsLogger.errors.MISSING_PERMALINK)
            return siteData.currentUrl.full
        }
        const pageInfo: any = {
            pageItemId: permaLinkId,
            pageAdditionalData: this.getAppPartZoomAdditionalDataPart(siteData, itemId, itemTitle, permaLink.mutableSeoTitle)
        }

        if (!siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH)) {
            pageInfo.title = permaLink.mutableSeoTitle
        }

        return coreUtils.wixUrlParser.getUrl(siteData, pageInfo)
    },

    /**
     *
     * @param {core.SiteData} siteData
     * @param pageId
     * @param itemId
     * @param itemTitle
     */
    getAppPageUrl(siteData, pageId, itemId, itemTitle) {
        const pageInfo = {
            pageId,
            title: itemTitle,
            pageAdditionalData: itemId
        }

        return coreUtils.wixUrlParser.getUrl(siteData, pageInfo)
    }
}
