import {VIEW_MODES, DATA_TYPES} from '../constants/constants'
import {DmApis, Extension, PointerMethods, pointerUtils, Pointer} from '@wix/document-manager-core'
const {getPointer} = pointerUtils
const NO_MATCH: string[] = []
import {mapValues} from 'lodash'
import {getIdFromRef} from '../utils/dataUtils'

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getComponentsDataByType = (componentType: string) => {
        const allCompsFromType = dal.query(VIEW_MODES.DESKTOP, dal.queryFilterGetters.getComponentsTypeFilter(componentType))
        return mapValues(allCompsFromType, (structure: any) => {
            const dataPointer = getPointer(getIdFromRef(structure.dataQuery), DATA_TYPES.data)
            const data = dal.get(dataPointer)
            return data
        })
    }

    const getMobileComponentsByDataType = (componentType: string): Pointer[] => {
        const allComponentsFromType = dal.query(VIEW_MODES.MOBILE, dal.queryFilterGetters.getComponentsTypeFilter(componentType))
        return Object.keys(allComponentsFromType).map(id => getPointer(id, VIEW_MODES.MOBILE))
    }

    return {
        data: {
            getComponentsDataByType,
            getMobileComponentsByDataType
        }
    }
}

const createFilters = () => ({
    getComponentsTypeFilter: (namespace: string, value: any): string[] => {
        if ((namespace === VIEW_MODES.DESKTOP || namespace === VIEW_MODES.MOBILE) && value.componentType) {
            return [value.componentType]
        }
        return NO_MATCH
    }
})

const createExtension = (): Extension => ({
    name: 'componentsByType',
    createFilters,
    createPointersMethods
})

export {createExtension}
