import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {logger} = warmupUtils.loggingUtils

const SAMPLE_RATIOS = {
    ALWAYS: 1,
    ONCE_PER_APP_PART: 10,
    MANY_PER_APP_PART: 30
}

const classifications = {
    CLASSICS_EVENT_SOURCE: 12,
    Type: {ERROR: 10, TIMING: 20, FUNNEL: 30, USER_ACTION: 40},
    Category: {EDITOR: 1, VIEWER: 2, CORE: 3, SERVER: 4},
    Issue: {SERVER_EDITOR_ERROR: 0, SERVER_VIEWER_ERROR: 1, CLIENT_EDITOR_ERROR: 2, CLIENT_VIEWER_ERROR: 4},
    Severity: {RECOVERABLE: 10, WARNING: 20, ERROR: 30, FATAL: 40}
}

const errors = {
    /**
     * Add new ERRORS here!
     */
    GENERIC_ERROR: {code: -20000, description: 'classics unspecified error'},
    APP_PART_FAILED_TO_LOAD: {code: -20011, description: 'Failed to load app part', issue: classifications.Issue.CLIENT_VIEWER_ERROR}
}

Object.freeze(errors)

logger.register('{%= name %}', 'error', errors)

const events = {
    /**
     * Add new EVENTS here!
     */
    APP_PART_LOADING_STAGES: {
        adapter: 'blog-ugc',
        description: 'measure time for a stage',
        eventId: 511,
        params: {
            component_id: 'component_id',
            component_type: 'component_type',
            startTime: 'startTime',
            endTime: 'endTime',
            stage_process: 'stage_process',
            site_id: 'site_id'
        },
        src: 12
    },
    SITE_PUBLISHED_WITH_BLOG: {eventId: 64, description: 'site published with blog'},
    CATEGORY_CLICKED: {
        eventId: 502,
        description: 'category clicked',
        params: {
            site_id: 'site_id'
        }
    },
    TAG_CLICKED: {
        eventId: 503,
        description: 'tag clicked',
        params: {
            site_id: 'site_id'
        }
    },
    SHARE_CLICKED: {
        eventId: 504,
        description: 'share clicked',
        params: {
            type: 'type',
            post_id: 'post_id',
            site_id: 'site_id'
        },
        sampleRatio: SAMPLE_RATIOS.ALWAYS
    },
    SINGLE_POST_LINK_CLICKED: {
        eventId: 505,
        description: 'single post link clicked',
        params: {
            post_id: 'post_id',
            site_id: 'site_id',
            msid: 'msid'
        }
    },
    SELECTION_SHARER_OPENED: {
        eventId: 507,
        description: 'share part of text popup',
        params: {
            post_id: 'post_id',
            site_id: 'site_id'
        }
    },
    SELECTION_SHARER_CLICKED: {
        eventId: 508,
        description: 'share part of text click',
        params: {
            type: 'type',
            post_id: 'post_id',
            site_id: 'site_id'
        }
    },
    LIKE_CLICKED: {
        eventId: 512,
        description: 'User click on like',
        params: {
            like_status: 'like_status',
            post_id: 'post_id'
        }
    },
    APP_PART_RENDERED: {
        adapter: 'blog-ugc',
        description: 'appPart has rendered',
        eventId: 513,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            duration: 'duration', // milliseconds
            app_part_state: 'app_part_state', // 'loading', 'error', 'content'
            occurrence_count: 'occurrence_count',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.MANY_PER_APP_PART
    },
    APP_PART_DATA_REQUEST_FINISHED: {
        adapter: 'blog-ugc',
        description: 'appPart data request returned from server',
        eventId: 514,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            duration: 'duration', // milliseconds
            is_batched: 'is_batched',
            occurrence_count: 'occurrence_count',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    },
    FIRST_TIME_APP_PART_RENDER_START: {
        adapter: 'blog-ugc',
        description: 'appPart started first time render',
        eventId: 516,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    },
    FIRST_TIME_APP_PART_RENDER_FINISH: {
        adapter: 'blog-ugc',
        description: 'appPart finished rendering with content/error for the first time',
        eventId: 517,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            app_part_state: 'app_part_state', // error / content
            duration: 'duration', // milliseconds
            render_duration: 'render_duration',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    }
}

// /////////////////////////////////////////////////////////

function reportError(siteData, errorData, params) {
    try {
        const error = {
            desc: errorData.description || errors.GENERIC_ERROR.description,
            errorCode: errorData.code || errors.GENERIC_ERROR.code,
            type: classifications.Type.ERROR,
            issue: errorData.issue || classifications.Issue.CLIENT_VIEWER_ERROR,
            severity: errorData.severity || classifications.Severity.ERROR,
            category: errorData.category || classifications.Category.VIEWER,
            reportType: 'error',
            packageName: 'blog',
            src: classifications.CLASSICS_EVENT_SOURCE
        }

        params = params || {}
        logger.reportBI(siteData, error, params)
    } catch (e) {
        // empty
    }
}

Object.freeze(events)

function reportEvent(siteData, eventData, params) {
    try {
        const defaultEvent = {
            type: classifications.Type.USER_ACTION,
            adapter: 'blog-ugc',
            category: classifications.Category.VIEWER,
            reportType: 'event',
            packageName: 'blog',
            params: {},
            src: classifications.CLASSICS_EVENT_SOURCE
        }

        const event = _.assign({}, defaultEvent, eventData)

        params = params || {}
        logger.reportBI(siteData, event, params)
    } catch (e) {
        // empty
    }
}

// {compId: boolean}
const hasFiredFirstTimeRenderStarted = {}
const hasFiredFirstTimeRenderFinished = {}

function reportFirstTimeRenderStart(siteData, compId, appPartName) {
    if (!siteData.isViewerMode()) {
        return
    }

    if (!hasFiredFirstTimeRenderStarted[compId]) {
        reportEvent(siteData, events.FIRST_TIME_APP_PART_RENDER_START, {
            component_id: compId,
            app_part_name: appPartName,
            site_id: siteData.siteId
        })

        hasFiredFirstTimeRenderStarted[compId] = true
    }
}

function reportFirstTimeRenderFinish(siteData, compId, appPartName, state, duration, renderDuration) {
    if (!siteData.isViewerMode()) {
        return
    }

    if (hasFiredFirstTimeRenderStarted[compId] && !hasFiredFirstTimeRenderFinished[compId]) {
        reportEvent(siteData, events.FIRST_TIME_APP_PART_RENDER_FINISH, {
            component_id: compId,
            app_part_name: appPartName,
            app_part_state: state,
            duration,
            render_duration: renderDuration,
            site_id: siteData.siteId
        })

        hasFiredFirstTimeRenderFinished[compId] = true
    }
}

function reportAppPartMetadataError(siteData) {
    reportError(siteData, errors.APP_PART_FAILED_TO_LOAD, {
        cid: logger.getVisitorId(siteData)
    })
}

/**
 * @class wixappsCore.wixappsClassicsLogger
 */
export default {
    events,
    errors,
    reportError,
    reportEvent,
    reportFirstTimeRenderStart,
    reportFirstTimeRenderFinish,
    reportAppPartMetadataError
}
