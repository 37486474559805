import _ from 'lodash'

function isLocalhost(urlBase) {
    return /^https?:\/\/localhost(:[0-9]+)?\/?$/.test(urlBase)
}

function removeProtocol(url) {
    return url && url.replace(/^https?\:/, '')
}

function extractVersion(url, normalizedScriptsDomain, regExpToExtractVersion) {
    const normalizedUrl = removeProtocol(url)
    if (_.startsWith(normalizedUrl, normalizedScriptsDomain)) {
        const match = normalizedUrl.slice(normalizedScriptsDomain.length).match(regExpToExtractVersion)
        return match && match[1]
    }
}

function getSantaVersionToLoad(runtimeSource, santaBase, santaArtifactVersion, scriptsDomainUrl, rendererArtifact) {
    if (runtimeSource) {
        return runtimeSource
    }
    const regExpToExtractVersion = new RegExp(`\\/?services\\/${rendererArtifact}\\/([\\w.]+)`)
    const normalizedScriptsDomain = removeProtocol(scriptsDomainUrl)
    const santaBaseVersion = extractVersion(santaBase, normalizedScriptsDomain, regExpToExtractVersion)
    return santaBaseVersion || extractVersion(santaArtifactVersion, normalizedScriptsDomain, regExpToExtractVersion)
}

export default {
    buildUrl(siteModel, {runtimeSource, hostArtifact = 'santa'}) {
        const {serviceTopology} = siteModel
        const url = '/node_modules/@wix/viewer-platform-worker/dist/wixcode-worker.js'

        if (isLocalhost(serviceTopology.scriptsLocationMap[hostArtifact])) {
            return url
        }

        const version = getSantaVersionToLoad(
            runtimeSource,
            siteModel.santaBase,
            serviceTopology.scriptsLocationMap[hostArtifact],
            serviceTopology.scriptsDomainUrl,
            hostArtifact
        )
        return `/_partials/${hostArtifact}/${version}${url}`
    }
}
