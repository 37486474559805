import thirdPartyAnalytics from './thirdPartyAnalytics'

export const {
    reportGoogleAnalytics,
    reportBiAnalytics,
    reportPageEvent,
    initFacebookRemarketingUserPixel,
    initFacebookRemarketingPixelId,
    fireFacebookRemarketingPixel,
    fireFacebookCustomEvent,
    initGoogleRemarketingPixel,
    fireGoogleRemarketingPixel,
    initYandexMetrika,
    reportYandexPageHit,
    reportGoogleTagManager,
    hasAccount,
    decorateReporter
} = thirdPartyAnalytics
