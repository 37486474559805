/* eslint prefer-rest-params:0 */
define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    '@wix/document-services-json-schemas',
    'documentServices/component/nicknameContextRegistrar',
    'documentServices/utils/contextAdapter',
    'documentServices/utils/utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/componentData',
    'documentServices/hooks/hooks',
    'documentServices/anchors/anchors',
    'documentServices/theme/theme',
    'documentServices/component/componentValidations',
    'documentServices/component/componentSerialization',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/component/componentModes',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/structure/structureUtils',
    'documentServices/component/componentStructureInfo',
    'documentServices/structure/structure',
    'documentServices/structure/utils/arrangement',
    'documentServices/connections/connectionsDataGetter',
    'documentServices/bi/errors',
    'documentServices/bi/bi',
    'documentServices/theme/isSystemStyle',
    'experiment',
    'documentServices/constants/constants',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/accessibility/componentA11yAPI',
    'documentServices/component/sanitizeSerializedComponent',
    'documentServices/variants/variantsUtils',
    'documentServices/siteMetadata/language',
    'document-services-schemas',
    '@wix/document-manager-core',
    'documentServices/utils/runtimeConfig',
    'documentServices/variants/design',
    'documentServices/structure/utils/layoutConstraintsUtils',
    '@wix/santa-core-utils'
], function (
    _,
    coreUtils,
    jsonSchemas,
    nicknameContextRegistrar,
    contextAdapter,
    dsUtils,
    dataModel,
    componentData,
    hooks,
    anchors,
    theme,
    componentValidations,
    componentSerialization,
    componentsMetaData,
    componentStylesAndSkinsAPI,
    componentModes,
    tpaEventHandlersService,
    tpaUtils,
    structureUtils,
    componentStructureInfo,
    structure,
    arrangement,
    connectionsDataGetter,
    biErrors,
    bi,
    isSystemStyle,
    experiment,
    constants,
    mobileUtil,
    a11yAPI,
    sanitizeSerializedComponent,
    variantsUtils,
    language,
    documentServicesSchemas,
    dmCore,
    runtimeConfig,
    design,
    layoutConstraintsUtils,
    santaCoreUtils
) {
    'use strict'

    const {
        namespaceMapping: {getNamespaceConfig, featureNamespaces}
    } = jsonSchemas
    const {pointerUtils} = dmCore
    const MOBILE_COMP_DATA_PREFIX = constants.DOM_ID_PREFIX.MOBILE

    const {DATA_TYPES, SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS, COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES_VALUES_WITH_HASH} = constants

    const SERIALIZED_PROPERTIES_TO_OMIT = [
        'custom',
        'activeModes',
        'layoutResponsive',
        'variants',
        ..._.values(SERIALIZED_DATA_KEYS),
        ..._.values(SERIALIZED_SCOPED_DATA_KEYS)
    ]

    const {stripHashIfExists} = dsUtils

    function initialize() {
        nicknameContextRegistrar.setContextProvider(null)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} containerReference
     * @param {string} [optionalCustomId]
     * @returns {Pointer}
     */
    function createCompRef(ps, containerReference, optionalCustomId) {
        const id = optionalCustomId || generateNewComponentId()
        const viewMode = ps.pointers.components.getViewMode(containerReference)
        const {displayedOnlyStructureUtil} = santaCoreUtils
        if (displayedOnlyStructureUtil.isRepeatedComponent(containerReference.id)) {
            const itemId = displayedOnlyStructureUtil.getRepeaterItemId(containerReference.id)
            return ps.pointers.components.getUnattached(displayedOnlyStructureUtil.getUniqueDisplayedId(id, itemId), viewMode)
        }
        return ps.pointers.components.getUnattached(id, viewMode)
    }

    function getComponentToAddRef(ps, containerReference, componentDefinition, optionalCustomId) {
        return createCompRef(ps, containerReference, optionalCustomId)
    }

    function validateConstraintsFormat(constraints) {
        if (_.isNull(constraints) || (_.isPlainObject(constraints) && _.isEmpty(constraints))) {
            return true
        }

        if (!_.isObject(constraints)) {
            throw new Error('Non valid constraints value. Expected null or object')
        }

        let ownPropertiesArr = Object.getOwnPropertyNames(constraints)
        const numOfOwnProperties = ownPropertiesArr.length

        if (numOfOwnProperties !== 1 || _.isUndefined(constraints.under)) {
            throw new Error('Constrains should be empty {} or have only property "under"')
        }

        if (!_.isPlainObject(constraints.under)) {
            throw new Error('Constrains "under" property should have plain object value')
        }

        ownPropertiesArr = Object.getOwnPropertyNames(constraints.under)

        if (ownPropertiesArr.length !== 2 || _.isUndefined(constraints.under.margin) || _.isUndefined(constraints.under.comp)) {
            throw new Error('Constraints "under" property should has the schema {comp: compRef, margin: number}')
        }

        if (!_.isNumber(constraints.under.margin)) {
            throw new Error('Constrains "margin" should be a number')
        }
    }

    function getLayoutByConstraints(ps, constraints) {
        const compAbovePointer = constraints.under.comp
        const compAboveLayout = getComponentLayout(ps, compAbovePointer)
        const compAboveBottom = compAboveLayout.y + compAboveLayout.height
        const compTop = compAboveBottom + constraints.under.margin
        return {y: compTop}
    }

    function addWithConstraints(ps, componentToAddPointer, pagePointer, componentDefinition, constraints, optionalCustomId) {
        validateConstraintsFormat(constraints)
        if (!ps.pointers.components.isPage(pagePointer)) {
            throw new Error('this API works only for page parent for now')
        }
        if (!structure.isSimpleLayout(componentDefinition.layout)) {
            throw new Error('the layout of the added comp should be simple, no fixed, docked, rotated and such')
        }

        let compDef = componentDefinition
        if (!_.isEmpty(constraints)) {
            const compAboveLayout = ps.dal.get(ps.pointers.getInnerPointer(constraints.under.comp, 'layout'))
            if (!structure.isSimpleLayout(compAboveLayout)) {
                throw new Error('you cannot place a component below a fixed, rotated, docked and such comps.')
            }
            compDef = _.clone(componentDefinition)
            compDef.layout = _.assign({}, compDef.layout, getLayoutByConstraints(ps, constraints))
        }

        addComponentToContainer(ps, componentToAddPointer, pagePointer, compDef, optionalCustomId)
    }

    const getAddComponentInteractionParams = (ps, componentToAddPointer, containerPointer, componentDefinition) => ({
        component_type: componentDefinition.componentType
    })

    /**
     * @param {ps} ps
     * @param {Pointer} componentToAddPointer - passed automatically from Site
     * @param {Pointer} containerPointer
     * @param {{componentType: String, styleId: String, data: String|Object, properties: String|Object}} componentDefinition
     * @param {string} [optionalCustomId]
     * @param {number} [optionalIndex]
     * @param {Object} [optionalOldToNewMap]
     * @returns {*}
     */
    function addComponentToContainer(ps, componentToAddPointer, containerPointer, componentDefinition, optionalCustomId, optionalIndex, optionalOldToNewMap) {
        if (componentToAddPointer.type !== containerPointer.type) {
            throw new Error(`Component ${componentToAddPointer} and Container ${componentToAddPointer} have different types`)
        }
        const compType = _.get(componentDefinition, 'componentType')
        const containerType = _.get(ps.dal.get(containerPointer), 'componentType')
        contextAdapter.utils.fedopsLogger.breadcrumb(
            `Adding comp ${compType}, id: "${componentToAddPointer.id}" , to container: ${containerType}, id: "${containerPointer.id}"`
        )

        if (componentToAddPointer.id && ps.dal.isExist(componentToAddPointer)) {
            throw new Error(`Failed to add component to container "${containerPointer.id}" because component "${componentToAddPointer.id}" already exists.`)
        }

        addComponent(ps, componentToAddPointer, containerPointer, componentDefinition, optionalCustomId, optionalIndex, optionalOldToNewMap)
        updateAnchorsAfterOperationDone(ps, componentToAddPointer)
    }

    function addComponentInternal(
        ps,
        componentToAddRef,
        containerReference,
        componentDefinition,
        optionalCustomId,
        optionalIndex,
        optionalOldToNewMap,
        optionalContainerToAddTo
    ) {
        hooks.executeHook(hooks.HOOKS.ADD_ROOT.BEFORE, componentDefinition.componentType, [
            ps,
            componentToAddRef,
            containerReference,
            componentDefinition,
            optionalCustomId,
            optionalIndex
        ])
        setComponent(ps, componentToAddRef, containerReference, componentDefinition, optionalCustomId, false, optionalOldToNewMap || {})

        //TODO: we can change it to just change the definition, if we can extract the getAdjustedLayout from the updateAdjustedLayout..
        if (optionalContainerToAddTo && !_.isEqual(optionalContainerToAddTo, containerReference)) {
            structure.adjustCompLayoutToNewContainer(ps, componentToAddRef, optionalContainerToAddTo)
            structure.addCompToContainer(ps, componentToAddRef, optionalContainerToAddTo, optionalIndex)
        } else if (_.isFinite(optionalIndex)) {
            arrangement.moveToIndex(ps, componentToAddRef, optionalIndex)
        }

        hooks.executeHook(hooks.HOOKS.ADD_ROOT.AFTER, componentDefinition.componentType, [ps, componentToAddRef, containerReference])
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentToAddRef  - passed automatically from Site
     * @param {Pointer} containerReference
     * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}
     * @param {string} [optionalCustomId]
     * @param {number} [optionalIndex]
     * @param {Object} [optionalOldToNewMap]
     * @returns {*}
     */
    function addComponent(ps, componentToAddRef, containerReference, componentDefinition, optionalCustomId, optionalIndex, optionalOldToNewMap) {
        const containerToAddTo = componentStructureInfo.getContainerToAddComponentTo(ps, containerReference)
        const validationResult = componentValidations.validateComponentToAdd(ps, componentToAddRef, componentDefinition, containerToAddTo, optionalIndex)

        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        addComponentInternal(
            ps,
            componentToAddRef,
            containerReference,
            componentDefinition,
            optionalCustomId,
            optionalIndex,
            optionalOldToNewMap,
            containerToAddTo
        )
    }

    function addComponentDefinitionAnchor(ps, anchorData, pageId, customId) {
        if (!anchorData) {
            return null
        }
        return dataModel.addSerializedAnchorDataItemToPage(ps, pageId, anchorData, customId)
    }

    function addVariantItemToPage(ps, variants, pageId, customId) {
        if (!variants) {
            return null
        }
        return dataModel.addSerializedVariantItemToPage(ps, pageId, variants, customId)
    }

    /**
     *
     * @param {*} ps
     * @param {*} data
     * @param {*} itemType
     * @param {*} pageId
     * @param {*} [customId]
     */
    function addComponentDefinitionDataByType(ps, data, itemType, pageId, customId) {
        if (!data) {
            return null
        }

        if (dataModel.doesItemTypeSupportsRepeatedItem(itemType)) {
            return addRepeatedSupportedNameSpaceDefinition(ps, data, pageId, customId, itemType)
        }

        return dataModel.addSerializedItemToPage(ps, pageId, data, customId, itemType)
    }

    function addComponentDefinitionBehaviors(ps, behaviors, pageId, customId) {
        if (!behaviors) {
            return null
        }
        return dataModel.addSerializedBehaviorsItemToPage(ps, pageId, behaviors, customId)
    }

    function addComponentMobileHintsDefinition(ps, mobileHints, pageId, customId) {
        if (!mobileHints) {
            return null
        }
        return dataModel.addSerializedMobileHintsItemToPage(ps, pageId, mobileHints, customId)
    }

    function addComponentDefinitionConnections(ps, serializedConnections, pageId, customId) {
        if (!serializedConnections) {
            return null
        }
        return dataModel.addSerializedConnectionsItemToPage(ps, pageId, serializedConnections, customId)
    }

    function addRepeatedSupportedNameSpaceDefinition(ps, item, pageId, customId, itemType) {
        let itemQuery = null
        if (item) {
            if (_.isObject(item)) {
                if (item.type === 'RepeatedData') {
                    itemQuery = dataModel.addSerializedItemToPage(ps, pageId, item.original, customId, itemType)
                    _.forEach(item.overrides, function (overriddenItem, itemId) {
                        const uniqueId = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(itemQuery, itemId)
                        dataModel.addSerializedItemToPage(ps, pageId, overriddenItem, uniqueId, itemType)
                    })
                } else {
                    itemQuery = dataModel.addSerializedItemToPage(ps, pageId, item, customId, itemType)
                }
            } else if (_.isString(item)) {
                const defaultDataItem = dataModel.createDataItemByType(ps, item)
                itemQuery = dataModel.addSerializedItemToPage(ps, pageId, defaultDataItem, customId, itemType)
            }
        }
        return itemQuery
    }

    function updateLayoutPropsForDockedComponent(componentLayout) {
        if (componentLayout.docked.right || componentLayout.docked.left || componentLayout.docked.hCenter) {
            if (componentLayout.docked.right && componentLayout.docked.left) {
                delete componentLayout.width
            }

            delete componentLayout.x
        }
        if (componentLayout.docked.top || componentLayout.docked.bottom || componentLayout.docked.vCenter) {
            if (componentLayout.docked.top && componentLayout.docked.bottom) {
                delete componentLayout.height
            }

            delete componentLayout.y
        }
    }

    function validateCompConnections(componentDefinition) {
        const connectionItems = _.get(componentDefinition, ['connections', 'items'], [])
        _.forEach(connectionItems, function (connectionItem) {
            const validationResult = componentValidations.validateComponentConnection(connectionItem)
            if (!validationResult.success) {
                throw new Error(validationResult.error)
            }
        })
    }

    function sanitizeCompLayout(componentDefinition) {
        componentDefinition.layout = componentDefinition.layout || {}
        const defaultLayout = {
            x: 0,
            y: 0,
            fixedPosition: false,
            width: 160,
            height: 90,
            scale: 1.0,
            rotationInDegrees: 0.0
        }
        const sanitizedComponentDefLayout = {}
        const allowedLayoutParams = componentValidations.ALLOWED_LAYOUT_PARAMS

        _.forEach(allowedLayoutParams, function (layoutParam) {
            const value = componentDefinition.layout[layoutParam]
            if (value) {
                const validationResult = componentValidations.validateLayoutParam(layoutParam, value)
                if (!validationResult.success) {
                    throw new Error(validationResult.error)
                }
                sanitizedComponentDefLayout[layoutParam] = value
            }
        })
        delete sanitizedComponentDefLayout.anchors

        componentDefinition.layout = _.assign(defaultLayout, sanitizedComponentDefLayout)

        if (componentDefinition.layout.docked) {
            updateLayoutPropsForDockedComponent(componentDefinition.layout)
        }
    }

    function omitPropertiesFromSerializedComp(compDefinition) {
        return _.omit(compDefinition, SERIALIZED_PROPERTIES_TO_OMIT)
    }

    /**
     * @param {ps} ps
     * @param compToAddPointer
     * @param containerPointer if is null it means that we add a page
     * @param serializedComp
     * @param optionalCustomId
     * @param isPage
     * @param oldToNewIdMap
     * @param [modeIdsInHierarchy]
     * @param [isAncestorsChecked]
     * @returns {*}
     */
    function setComponent(
        ps,
        compToAddPointer,
        containerPointer,
        serializedComp,
        optionalCustomId,
        isPage,
        oldToNewIdMap,
        modeIdsInHierarchy,
        isAncestorsChecked
    ) {
        function updateDisplayedOnlyComponentsDefs(pageId, structureToAdd) {
            const addComponents = ps.pointers.components.getAllDisplayedOnlyComponents(compToAddPointer)
            const shouldUpdateDisplayedOnlyComps =
                !_.isEmpty(addComponents) && santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compToAddPointer.id)
            if (shouldUpdateDisplayedOnlyComps && (structureToAdd.dataQuery || structureToAdd.designQuery)) {
                _.forEach(addComponents, function (displayedComponent) {
                    const itemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(displayedComponent.id)
                    if (structureToAdd.dataQuery) {
                        const uniqueDataQueryForItem = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(
                            dsUtils.stripHashIfExists(structureToAdd.dataQuery),
                            itemId
                        )
                        if (!ps.dal.full.isExist(ps.pointers.data.getDataItem(uniqueDataQueryForItem, pageId))) {
                            updateComponentDataDefinition(ps, pageId, uniqueDataQueryForItem, isPage, oldToNewIdMap, _.clone(serializedComp))
                        }
                    }
                    if (structureToAdd.designQuery) {
                        const uniqueDesignQueryForItem = santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(
                            dsUtils.stripHashIfExists(structureToAdd.designQuery),
                            itemId
                        )
                        if (!ps.dal.full.isExist(ps.pointers.data.getDesignItem(uniqueDesignQueryForItem, pageId))) {
                            updateComponentDesignDefinition(ps, pageId, uniqueDesignQueryForItem, oldToNewIdMap, _.clone(serializedComp))
                        }
                    }
                })
            }
        }

        sanitizeSerializedComponent(serializedComp)

        hooks.executeHook(hooks.HOOKS.ADD.BEFORE, serializedComp.componentType, arguments)
        const validationResult = componentValidations.validateComponentToSet(ps, compToAddPointer, serializedComp, optionalCustomId, containerPointer, isPage)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        if (serializedComp.layout) {
            delete serializedComp.layout.anchors
        }

        let clonedSerializedComp = santaCoreUtils.objectUtils.cloneDeep(serializedComp)

        if (oldToNewIdMap && serializedComp.id) {
            oldToNewIdMap[serializedComp.id] = compToAddPointer.id
        }
        clonedSerializedComp.id = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compToAddPointer.id)

        updateDefinitionTypeByComponentType(clonedSerializedComp)
        sanitizeCompLayout(clonedSerializedComp)
        validateCompConnections(clonedSerializedComp)

        modeIdsInHierarchy = modeIdsInHierarchy || {
            ancestorsModes: collectAncestorsModeIds(ps, containerPointer, {}),
            oldModeIdsToNew: {}
        }
        modeIdsInHierarchy.ancestorsModes = _.merge(modeIdsInHierarchy.ancestorsModes, getComponentModeIdsFromStructure(clonedSerializedComp))
        clonedSerializedComp = sanitizeComponentModes(ps, clonedSerializedComp, compToAddPointer, modeIdsInHierarchy)
        updateComponentModesIdsInStructure(clonedSerializedComp, modeIdsInHierarchy.oldModeIdsToNew)

        const pageId = isPage ? compToAddPointer.id : ps.pointers.full.components.getPageOfComponent(containerPointer).id
        updateVariantsInStructure(ps, clonedSerializedComp, containerPointer, pageId, oldToNewIdMap, isAncestorsChecked)
        deserializeComponentData(ps, clonedSerializedComp, pageId, optionalCustomId, isPage, oldToNewIdMap, modeIdsInHierarchy)

        const children = serializedComp.components
        if (children) {
            clonedSerializedComp.components = []
        }

        mobileUtil.resetMobileComponentsForClonedComp(ps, serializedComp, clonedSerializedComp)

        const structureToAdd = omitPropertiesFromSerializedComp(clonedSerializedComp)
        if (!isPage) {
            const childrenPointer = ps.pointers.full.components.getChildrenContainer(containerPointer)
            const newCompIndex = arrangement.getNewIndexForNewComp(ps, structureToAdd, containerPointer)
            ps.dal.full.push(childrenPointer, structureToAdd, compToAddPointer, newCompIndex)
            updateDisplayedOnlyComponentsDefs(pageId, structureToAdd)
        } else {
            ps.dal.full.set(compToAddPointer, structureToAdd)
        }

        if (clonedSerializedComp.translations) {
            const siteTranslations = _.map(language.getFull(ps), lang => lang.languageCode)
            const relevantLanguages = _.intersection(Object.keys(clonedSerializedComp.translations), siteTranslations)

            relevantLanguages.forEach(langCode => {
                dataModel.updateDataItemInLang(ps, compToAddPointer, clonedSerializedComp.translations[langCode], langCode)
            })
        }

        const {slots} = clonedSerializedComp
        setComponentChildrenDefinition(ps, pageId, children, slots, compToAddPointer, oldToNewIdMap, modeIdsInHierarchy, clonedSerializedComp.activeModes)
        mobileUtil.setMobileComponentChildrenDefinition(
            ps,
            serializedComp,
            pageId,
            oldToNewIdMap,
            modeIdsInHierarchy,
            clonedSerializedComp.activeModes,
            setComponentChildrenDefinition
        )

        // send the actual compDefinition, instead of its prototype)
        const afterArgs = [ps, compToAddPointer, clonedSerializedComp, optionalCustomId, oldToNewIdMap, containerPointer]
        hooks.executeHook(hooks.HOOKS.ADD.AFTER, clonedSerializedComp.componentType, afterArgs)

        return compToAddPointer
    }

    function updateComponentModesIdsInStructure(serializedComp, oldModeIdsToNew) {
        _.defaults(oldModeIdsToNew, createNewModeIds(serializedComp))
        updateCompDefinitionModes(serializedComp, oldModeIdsToNew)
        updateCompOverrideModes(serializedComp, oldModeIdsToNew)
        updateCompPropertiesModes(serializedComp, oldModeIdsToNew)
    }

    function deserializeComponentData(ps, serializedComp, pageId, optionalCustomId, isPage, oldToNewIdMap, modeIdsInHierarchy) {
        updateComponentBreakpointDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        updateComponentDataDefinition(ps, pageId, optionalCustomId, isPage, oldToNewIdMap, serializedComp)
        updateComponentPropsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        updateComponentDesignDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        updateComponentBehaviorsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, modeIdsInHierarchy, serializedComp)
        updateComponentMobileHintsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        updateComponentLayoutDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        updateComponentVariablesDefinition(ps, pageId, oldToNewIdMap, serializedComp)
        updateComponentAnchorDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        setComponentDefinitionStyle(ps, pageId, oldToNewIdMap, serializedComp)
        updateComponentTransformationsDefinition(ps, pageId, oldToNewIdMap, serializedComp)
        updateComponentReactionsDefinition(ps, pageId, oldToNewIdMap, serializedComp)
        updateVariantsArrayDefinitionByType(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp, DATA_TYPES.states)
        updateVariantsArrayDefinitionByType(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp, DATA_TYPES.triggers)
        updateComponentTransitionsDefinition(ps, pageId, oldToNewIdMap, serializedComp)
        updateComponentFeaturesDefinitions(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComp)
        deserializeComponentOverridesData(ps, serializedComp, pageId, optionalCustomId, oldToNewIdMap)
        updateComponentConnectionsDefinition(serializedComp, ps, pageId, optionalCustomId, oldToNewIdMap)
    }

    /**
     * Check if compStructure contains modes that are still relevant under the new container.
     * If no relevant modes, return the displayed json of the component, under the modes that were active when the component was serialized
     * If there are relevant modes, remove only those that are no longer relevant.
     */
    function sanitizeComponentModes(ps, compStructure, compToAddPointer, modeIdsInHierarchy) {
        // all potential active modes and component's definition modes Ids.
        const potentialModesToAffectComponentInContainer = modeIdsInHierarchy.ancestorsModes

        const shouldCreateDisplayedStructure = _.isEmpty(potentialModesToAffectComponentInContainer)
        if (shouldCreateDisplayedStructure) {
            return createDisplayedComponentStructure(ps, compToAddPointer, compStructure)
        }
        return removeAllUnusedOverrides(compStructure, potentialModesToAffectComponentInContainer)
    }

    function getComponentModeIdsFromStructure(compStructure) {
        const modeIdsMap = {}
        _.forEach(_.get(compStructure, ['modes', 'definitions']), function (modeDefinition) {
            modeIdsMap[modeDefinition.modeId] = true
        })
        return modeIdsMap
    }

    function createDisplayedComponentStructure(ps, compToAddPointer, compStructure) {
        const {activeModes} = compStructure
        const displayedComp = coreUtils.fullToDisplayedJson.applyModesOnSerializedStructure(compStructure, activeModes)
        if (!_.isEmpty(activeModes)) {
            updateLayoutXYOnStructure(displayedComp, compStructure.layout)
        }
        return displayedComp
    }

    function updateLayoutXYOnStructure(structureToUpdate, layout) {
        structureToUpdate.layout.x = _.get(layout, 'x') || _.get(structureToUpdate, ['layout', 'x'])
        structureToUpdate.layout.y = _.get(layout, 'y') || _.get(structureToUpdate, ['layout', 'y'])
    }

    function collectAncestorsModeIds(ps, componentPointer, newModeIdsToOld) {
        let collectedModeIds = []
        while (componentPointer) {
            const ancestorModeIds = getOldComponentModesIds(ps, componentPointer, newModeIdsToOld)
            collectedModeIds = collectedModeIds.concat(ancestorModeIds)
            componentPointer = ps.pointers.full.components.getParent(componentPointer)
        }

        return _(collectedModeIds).groupBy(_.identity).mapValues(Boolean).value()
    }

    /**
     * @param {ps} ps
     * @param {Pointer} component
     * @param newModeIdsToOld
     * @return {Array}
     */
    function getOldComponentModesIds(ps, component, newModeIdsToOld) {
        const componentModesIds = []
        const compModes = componentModes.getComponentModes(ps, component)
        _.forEach(compModes, function (compMode) {
            const oldComponentModeID = newModeIdsToOld[compMode.modeId]
            if (oldComponentModeID) {
                componentModesIds.push(oldComponentModeID)
            } else {
                componentModesIds.push(compMode.modeId)
            }
        })
        return componentModesIds
    }

    function removeAllUnusedOverrides(compDefinition, modesToMaintain) {
        if (compDefinition && modesToMaintain) {
            let compOverrides = _.get(compDefinition, ['modes', 'overrides'])
            if (compOverrides) {
                compOverrides = _.filter(compOverrides, function (override) {
                    return _.every(override.modeIds, modeId => modesToMaintain[modeId])
                })
                compDefinition.modes.overrides = compOverrides
            }
        }
        return compDefinition
    }

    function updateDefinitionTypeByComponentType(serializedComp) {
        const {schemasService} = documentServicesSchemas.services
        const {componentType} = serializedComp

        if (componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget') {
            serializedComp.type = experiment.isOpen('dm_migrateTpaWidgetToContainer') ? 'Container' : 'Component'
            serializedComp.components = experiment.isOpen('dm_migrateTpaWidgetToContainer') && !serializedComp.components ? [] : serializedComp.components
            return
        }

        if (componentType === 'wixui.StylableHorizontalMenu') {
            serializedComp.type = experiment.isOpen('dm_migrateStylableMenuToContainer') ? 'Container' : 'Component'
            serializedComp.components = experiment.isOpen('dm_migrateStylableMenuToContainer') && !serializedComp.components ? [] : serializedComp.components
            return
        }

        if (schemasService.isRepeater(componentType)) {
            serializedComp.type = 'RepeaterContainer'
        } else if (schemasService.isPage(componentType)) {
            serializedComp.type = 'Page'
        } else if (schemasService.isRefComponent(componentType)) {
            serializedComp.type = 'RefComponent'
        } else if (schemasService.isContainer(componentType)) {
            serializedComp.type = 'Container'
            serializedComp.components = serializedComp.components || []
        } else {
            serializedComp.type = 'Component'
        }
    }

    function setComponentDefinitionProps(ps, props, pageId, customId) {
        let propertyQuery = null
        if (props) {
            if (_.isObject(props)) {
                propertyQuery = dataModel.addSerializedPropertyItemToPage(ps, pageId, props, customId)
            } else if (_.isString(props)) {
                const defaultPropsItem = dataModel.createPropertiesItemByType(ps, props)
                propertyQuery = dataModel.addSerializedPropertyItemToPage(ps, pageId, defaultPropsItem, customId)
            }
        }
        return propertyQuery
    }

    const migrateStylesInBreakpointsToScopedStyles = serializedComp => {
        const scopedStyles = {}
        if (_.get(serializedComp, ['style', 'type']) === 'StylesInBreakpoint') {
            _.forEach(serializedComp.style.stylesInBreakpoints, styleItem => {
                if (styleItem.breakpoint) {
                    const breakpointId = dsUtils.stripHashIfExists(styleItem.breakpoint)
                    scopedStyles[breakpointId] = _.omit(styleItem, 'breakpoint')
                }
            })
            serializedComp[SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.theme]] = scopedStyles
            serializedComp[SERIALIZED_DATA_KEYS[DATA_TYPES.theme]] = _.find(serializedComp.style.stylesInBreakpoints, styleItem => !styleItem.breakpoint)
        }
    }

    function setComponentDefinitionStyle(ps, pageId, oldToNewIdMap, serializedComp) {
        migrateStylesInBreakpointsToScopedStyles(serializedComp)
        updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedComp, DATA_TYPES.theme)
    }

    function addSingleStyleOrSystemStyleToPage(ps, pageId, componentTypeForSystemStyle, styleOrSystemStyle) {
        let styleId = styleOrSystemStyle

        if (_.isObject(styleOrSystemStyle)) {
            let pageIdToAddStyle = pageId

            if (styleOrSystemStyle.type !== 'StylesInBreakpoint') {
                styleId = styleOrSystemStyle.id
                if (styleId && isSystemStyle(styleId)) {
                    if (!ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, 'masterPage'))) {
                        _.set(styleOrSystemStyle, 'type', constants.STYLES.TOP_LEVEL_STYLE)
                        _.set(styleOrSystemStyle, 'styleType', 'system')
                        _.set(styleOrSystemStyle, 'componentClassName', componentTypeForSystemStyle)
                        dataModel.addSerializedStyleItemToPage(ps, 'masterPage', styleOrSystemStyle, styleId)
                    }
                    return styleId
                }
                const isComponentStyle = ps.runtimeConfig.stylesPerPage && styleOrSystemStyle.styleType !== 'system'
                pageIdToAddStyle = isComponentStyle ? pageId : 'masterPage'
                _.set(styleOrSystemStyle, 'type', isComponentStyle ? constants.STYLES.COMPONENT_STYLE : constants.STYLES.TOP_LEVEL_STYLE)
                _.set(styleOrSystemStyle, 'componentClassName', componentTypeForSystemStyle)
            } else {
                bi.error(ps, biErrors.COMPONENT_WITH_STYLES_IN_BREAKPOINTS, {
                    compType: componentTypeForSystemStyle,
                    styleId,
                    pageId
                })
            }
            return dataModel.addSerializedStyleItemToPage(ps, pageIdToAddStyle, styleOrSystemStyle, undefined)
        }
        const isSystemStyleId = isSystemStyle(styleId)
        const existingStyle = theme.styles.get(ps, styleId)
        if (!isSystemStyleId) {
            const isADI = _.get(ps.config, ['origin']) === 'onboarding'
            if (!isADI || experiment.isOpen('reportBiErrorInADIAddingCompWithCustomeStyleId')) {
                bi.error(ps, biErrors.ADD_COMPONENT_WITH_CUSTOM_STYLE_ID, {
                    compType: componentTypeForSystemStyle,
                    styleId,
                    existingStyle
                })
            }
            santaCoreUtils.log.warnDeprecation(
                'You tried to create a component with an unknown system style Id. It should be either called with a string for system style, or an object for custom style'
            )
            if (existingStyle) {
                styleId = theme.styles.internal.fork(ps, existingStyle, pageId, true)
            } else {
                const styleIds = componentStylesAndSkinsAPI.style.getThemeStyleIds(componentTypeForSystemStyle)
                if (styleIds.length) {
                    styleId = styleIds[0]
                }
            }
        } else if (!existingStyle) {
            componentStylesAndSkinsAPI.style.createSystemStyle(ps, styleId, componentTypeForSystemStyle)
        }
        return styleId
    }

    /**
     * deserialize scoped styles, if its scoped by variants it will create all relations and ref arrays and connect them to the new variant
     * that was created on updateVariantsInStructure.
     * @param {ps} ps privateServices
     * @param {string} pageId
     * @param {string} componentTypeForSystemStyle
     * @param {object} serializedComp
     */

    function setStructureDefinitionStyle(ps, pageId, componentTypeForSystemStyle, serializedComp) {
        if (serializedComp.style) {
            serializedComp.styleId = addSingleStyleOrSystemStyleToPage(ps, pageId, componentTypeForSystemStyle, serializedComp.style)
        }
    }

    function updateAnchorsAfterOperationDone(ps, compPointer) {
        anchors.updateAnchors(ps, compPointer)
    }

    function setComponentChildrenDefinition(ps, pageId, children, slots, containerRef, idMap, oldModeIdsToNew, activeModes) {
        if (slots) {
            updateComponentSlotsDefinition(ps, pageId, slots, containerRef, idMap)
        }

        if (children && containerRef) {
            _.forEach(children, function (componentDef) {
                const newId = idMap && idMap[componentDef.id]
                const childCompRef = getComponentToAddRef(ps, containerRef, componentDef, newId)
                componentDef.activeModes = activeModes
                setComponent(ps, childCompRef, containerRef, componentDef, newId, false, idMap, oldModeIdsToNew, true)
                updateAnchorsAfterOperationDone(ps, childCompRef)
            })
        }
    }

    function updateComponentDataDefinition(ps, pageId, optionalCustomId, isPage, oldToNewIdMap, compDefinition) {
        if (isPage) {
            compDefinition.dataQuery = `#${pageId}`
        } else {
            optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.data, optionalCustomId)
            const dataQuery = addRepeatedSupportedNameSpaceDefinition(ps, compDefinition.data, pageId, optionalCustomId, DATA_TYPES.data)
            if (dataQuery) {
                compDefinition.dataQuery = `#${dataQuery}`
                addIdToMap(compDefinition.data, dataQuery, oldToNewIdMap)
            }
        }
    }

    function shouldUseDesignOverVariants(ps, serializedCompOrModeOverride) {
        const scopedDesigns = _.get(serializedCompOrModeOverride, SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.design])
        return experiment.isOpen('dm_designOverVariants') || !_.isEmpty(scopedDesigns)
    }

    function updateComponentDesignDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        if (shouldUseDesignOverVariants(ps, serializedCompOrModeOverride)) {
            updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride, DATA_TYPES.design)
        } else {
            optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.design, optionalCustomId)
            const designQuery = addRepeatedSupportedNameSpaceDefinition(ps, serializedCompOrModeOverride.design, pageId, optionalCustomId, DATA_TYPES.design)
            if (designQuery) {
                serializedCompOrModeOverride.designQuery = `#${designQuery}`
                addIdToMap(serializedCompOrModeOverride.design, designQuery, oldToNewIdMap)
            }
        }
    }

    function updateComponentAnchorDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.anchor, optionalCustomId)
        const anchorQuery = addComponentDefinitionAnchor(ps, serializedCompOrModeOverride.anchor, pageId, optionalCustomId)
        if (anchorQuery) {
            serializedCompOrModeOverride.anchorQuery = `${anchorQuery}`
            addIdToMap(serializedCompOrModeOverride.anchor, anchorQuery, oldToNewIdMap)
        }
    }

    const addSingleItem = (ps, pageId, serializedComp, itemType, item) =>
        itemType === DATA_TYPES.theme
            ? addSingleStyleOrSystemStyleToPage(ps, pageId, serializedComp.componentType, item)
            : addComponentDefinitionDataByType(ps, item, itemType, pageId)

    const updateVariantRelation = (ps, pageId, oldToNewIdMap, serializedComp, itemType, scopedValue, variants) => {
        const oldVariants = variants.split(',')
        const newVariants = _.compact(_.map(oldVariants, oldVariant => oldToNewIdMap[oldVariant]))
        const ancestorIncludesAllVariants = newVariants.length === oldVariants.length
        if (ancestorIncludesAllVariants) {
            const scopedValueId = addSingleItem(ps, pageId, serializedComp, itemType, scopedValue)
            const variantRelation = dataModel.variantRelation.create(ps, newVariants, serializedComp.id, scopedValueId)
            return dataModel.addDeserializedItemToPage(ps, pageId, itemType, variantRelation)
        }
        return null
    }

    const updateScopedValues = (ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues, scopedSerializeKey = SERIALIZED_SCOPED_DATA_KEYS[itemType]) => {
        const scopedValues = serializedComp[scopedSerializeKey]

        if (scopedValues) {
            const relationsIds = _(scopedValues)
                .map((scopedValue, key) => updateVariantRelation(ps, pageId, oldToNewIdMap, serializedComp, itemType, scopedValue, key))
                .compact()
            refValues.splice(refValues.length, 0, ...relationsIds)
        }
    }

    const updateNonScopedValue = (ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues, serializeKey = SERIALIZED_DATA_KEYS[itemType]) => {
        const nonScopedValue = serializedComp[serializeKey]
        if (nonScopedValue) {
            const nonScopedId = addSingleItem(ps, pageId, serializedComp, itemType, nonScopedValue)
            refValues.push(nonScopedId)
        }
    }

    const getIdentifierForVariantsQuery = (id, itemType) => `${id},${itemType}`

    const createAndAddRefArray = (ps, refValues, pageId, itemType) => {
        const refArray = dataModel.refArray.create(ps, refValues)
        return dataModel.addDeserializedItemToPage(ps, pageId, itemType, refArray)
    }

    const setCompQueryValue = (serializedComp, id, itemType) => {
        const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
        serializedComp[compDataQueryKey] = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${id}` : id
    }

    const linkRefArrayOrNonScopedValue = (ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues) => {
        const isThereRefArrayOrStyleObjectToLink = !_.isEmpty(refValues)
        if (isThereRefArrayOrStyleObjectToLink) {
            const scopedSerializeKey = SERIALIZED_SCOPED_DATA_KEYS[itemType]
            const shouldLinkNonScopedValue = !serializedComp[scopedSerializeKey] && [DATA_TYPES.theme, DATA_TYPES.design].includes(itemType)

            const dataQueryId = shouldLinkNonScopedValue ? refValues[0] : createAndAddRefArray(ps, refValues, pageId, itemType)

            addIdToMap({id: getIdentifierForVariantsQuery(serializedComp.id, itemType)}, dataQueryId, oldToNewIdMap)
            setCompQueryValue(serializedComp, dataQueryId, itemType)
        }
    }

    /**
     * deserialize scoped values like (transition, transformations..) if its non scoped value its will deserialize it under non scoped key
     * f.i transitions: {...}
     * if the comp have scoped value f.i scopedTransitions each key is combination of variant id's
     * it will deserialize the value under the key only if all of the variants in the key are mentioned on oldToNewIdMap map
     *
     * @param {ps} ps privateServices
     * @param pageId
     * @param oldToNewIdMap
     * @param serializedComp
     * @param itemType
     */
    function updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedComp, itemType) {
        const refValues = []
        const dataQueryId = getCustomId(oldToNewIdMap, {id: getIdentifierForVariantsQuery(serializedComp.id, itemType)})
        if (dataQueryId) {
            setCompQueryValue(serializedComp, dataQueryId, itemType)
            return
        }
        updateNonScopedValue(ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues)
        updateScopedValues(ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues)
        linkRefArrayOrNonScopedValue(ps, pageId, oldToNewIdMap, serializedComp, itemType, refValues)
    }

    function updateComponentNonRootScopedValuesDefinition(ps, pageId, serializedComp, itemType) {
        const item = serializedComp[itemType]
        if (!_.isObject(item)) return

        updateComponentNonRootScopedValuesDefinitionRecursively(ps, item, itemType, pageId)
    }

    function updateComponentNonRootScopedValuesDefinitionRecursively(ps, item, itemType, pageId) {
        const referenceFieldsInfo = item.type && ps.extensionAPI.schemaAPI.extractReferenceFieldsInfoForSchema(itemType, item.type)
        if (!referenceFieldsInfo?.length) return
        for (const info of referenceFieldsInfo) {
            updateFieldNonRootScopedValuesDefinition(ps, item, info, itemType, pageId)
        }
    }

    function updateFieldNonRootScopedValuesDefinition(ps, item, info, itemType, pageId) {
        const {isRelationalSplit, path} = info
        const field = _.get(item, path)
        if (!_.isObject(field) && !_.isArray(field)) return

        if (isRelationalSplit) {
            const refValues = []
            updateNonScopedValue(ps, pageId, {}, field, itemType, refValues, 'default')
            updateScopedValues(ps, pageId, {}, field, itemType, refValues, 'scoped')
            const refArrayId = createAndAddRefArray(ps, refValues, pageId, itemType)
            _.set(item, path, `#${refArrayId}`)
        } else {
            _.forEach(field, childField => updateComponentNonRootScopedValuesDefinitionRecursively(ps, childField, itemType, pageId))
        }
    }

    function updateComponentTransformationsDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride) {
        updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride, DATA_TYPES.transformations)
    }

    function updateComponentReactionsDefinition(ps, pageId, oldToNewIdMap, serializedComponent) {
        const key = SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.reactions]

        _.forEach(serializedComponent[key], reaction => {
            reaction.values.map(reactionData => {
                const oldId = dsUtils.stripHashIfExists(reactionData.state)
                if (reactionData.state) {
                    reactionData.state = `#${getCustomId(oldToNewIdMap, {id: oldId})}`
                }
                return reactionData
            })
        })

        updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedComponent, DATA_TYPES.reactions)
    }

    function updateComponentSlotsDefinition(ps, pageId, slots, containerRef, oldToNewIdMap) {
        const {type, slots: slotComponents} = slots
        const addedSlotComponents = _.mapValues(slotComponents, childSlotDefinition => {
            const compPointer = getComponentToAddRef(ps, containerRef, childSlotDefinition)
            setComponent(ps, compPointer, containerRef, childSlotDefinition, null, false, oldToNewIdMap)
            return compPointer.id
        })

        const slotsData = {slots: {...addedSlotComponents}, type}
        const slotsDataId = dataModel.addDeserializedItemToPage(ps, pageId, DATA_TYPES.slots, slotsData)
        slotsData.id = slotsDataId
        addIdToMap(slotsData, slotsDataId, oldToNewIdMap)

        ps.dal.set(ps.pointers.getInnerPointer(containerRef, ['slotsQuery']), slotsDataId)
    }

    function updateVariantsArrayDefinitionByType(ps, pageId, optionalCustomId, oldToNewIdMap, serializedComponent, namespace) {
        const key = SERIALIZED_DATA_KEYS[namespace]
        const {query} = getNamespaceConfig(namespace)

        if (!serializedComponent[key]) {
            return
        }

        optionalCustomId = getCustomId(oldToNewIdMap, serializedComponent[key], optionalCustomId)
        const values = _(serializedComponent[key].values)
            .map(id => {
                const oldId = dsUtils.stripHashIfExists(id)
                return oldToNewIdMap[oldId] && `#${oldToNewIdMap[oldId]}`
            })
            .compact()
            .value()

        if (!values.length) {
            return
        }

        const toUpdate = {...serializedComponent[key], values}
        const dataItemId = addComponentDefinitionDataByType(ps, toUpdate, namespace, pageId, optionalCustomId)

        if (dataItemId) {
            serializedComponent[query] = dataItemId
            addIdToMap(serializedComponent[key], dataItemId, oldToNewIdMap)
        }
    }

    function updateComponentTransitionsDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride) {
        updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride, DATA_TYPES.transitions)
    }

    function updateComponentFeaturesDefinitions(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        _.forEach(featureNamespaces, featureNamespace => {
            const {query, supportsVariants} = getNamespaceConfig(featureNamespace)
            if (supportsVariants) {
                updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride, DATA_TYPES[featureNamespace])
            } else {
                optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride[featureNamespace], optionalCustomId)
                const feature = addComponentDefinitionDataByType(ps, serializedCompOrModeOverride[featureNamespace], featureNamespace, pageId, optionalCustomId)
                if (feature && query) {
                    serializedCompOrModeOverride[query] = feature
                    addIdToMap(serializedCompOrModeOverride[featureNamespace], feature, oldToNewIdMap)
                }
            }
        })
    }

    function migrateLayoutResponsiveToScopedLayouts(ps, serializedCompOrModeOverride) {
        const layouts = dataModel.createLayoutObject()
        const scopedLayouts = {}
        if (serializedCompOrModeOverride.layoutResponsive) {
            _.forEach(['componentLayout', 'containerLayout', 'itemLayout'], key => {
                _.forEach(serializedCompOrModeOverride.layoutResponsive[`${key}s`], val => {
                    if (val.breakpoint) {
                        const breakpointId = dsUtils.stripHashIfExists(val.breakpoint)
                        if (scopedLayouts[breakpointId]) {
                            scopedLayouts[breakpointId][key] = _.isEmpty(scopedLayouts[breakpointId][key]) ? val : scopedLayouts[breakpointId][key]
                        } else {
                            scopedLayouts[breakpointId] = dataModel.createLayoutObject({[key]: val})
                        }
                    } else {
                        layouts[key] = _.isEmpty(layouts[key]) ? val : layouts[key]
                    }
                })
            })
        } else {
            cleanSerializedLayout(ps, serializedCompOrModeOverride)
            return
        }
        serializedCompOrModeOverride[SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.layout]] = scopedLayouts
        serializedCompOrModeOverride[SERIALIZED_DATA_KEYS[DATA_TYPES.layout]] = layouts
        cleanSerializedLayout(ps, serializedCompOrModeOverride)
    }

    function cleanSerializedLayout(ps, serializedComp) {
        const nonScopedKey = SERIALIZED_DATA_KEYS[DATA_TYPES.layout]
        const scopedKey = SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.layout]

        const cleanLayoutData = layout => (_.isEmpty(layout) ? layout : dataModel.cleanLayoutAdditionalProps(ps, layout))
        serializedComp[nonScopedKey] = cleanLayoutData(serializedComp[nonScopedKey])
        serializedComp[scopedKey] = _.mapValues(serializedComp[scopedKey], cleanLayoutData)
    }

    function updateComponentLayoutDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        migrateLayoutResponsiveToScopedLayouts(ps, serializedCompOrModeOverride)
        updateComponentScopedValuesDefinition(ps, pageId, oldToNewIdMap, serializedCompOrModeOverride, DATA_TYPES.layout)
    }

    function updateComponentVariablesDefinition(ps, pageId, oldToNewIdMap, serializedComp) {
        const itemType = DATA_TYPES.variables
        const variablesList = serializedComp[itemType]
        if (!_.isObject(variablesList)) return

        updateComponentNonRootScopedValuesDefinition(ps, pageId, serializedComp, itemType)

        const variableValues = []
        for (const variable of variablesList.variables) {
            const variableId = addComponentDefinitionDataByType(ps, variable, itemType, pageId)
            variableValues.push(variableId)
        }

        variablesList.variables = variableValues
        const id = dataModel.addDeserializedItemToPage(ps, pageId, itemType, variablesList)
        setCompQueryValue(serializedComp, id, itemType)
    }

    function updateComponentBreakpointDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        if (serializedCompOrModeOverride && serializedCompOrModeOverride.breakpoints) {
            optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.breakpoints, optionalCustomId)
            const values = _.get(serializedCompOrModeOverride.breakpoints, 'values')
            _.forEach(values, val => {
                const newId = dataModel.generateNewId(DATA_TYPES.variants)
                addIdToMap({id: val.id}, newId, oldToNewIdMap)
                val.id = newId
            })

            const componentId = serializedCompOrModeOverride.id
            const breakpointsDataToAdd = _.merge(serializedCompOrModeOverride.breakpoints, {componentId})

            const newVariantId = addVariantItemToPage(ps, breakpointsDataToAdd, pageId, optionalCustomId)
            if (newVariantId) {
                serializedCompOrModeOverride.breakpointVariantsQuery = newVariantId
                addIdToMap(serializedCompOrModeOverride.breakpointVariantsQuery, newVariantId, oldToNewIdMap)
            }
        }
    }

    /**
     * collect all variants of component and his ancestor and save it to map
     * it will help us later on to link scoped values to the variant that triggers the scoped value
     * when deserializing scoped values like (transform, transition, ...)
     *
     * @param {ps} ps privateServices
     * @param {Pointer} componentPointer
     * @param oldToNewIdMap - ids map
     * @param {boolean} isAncestorsChecked
     */
    function collectAncestorsVariants(ps, componentPointer, oldToNewIdMap, isAncestorsChecked) {
        if (!isAncestorsChecked) {
            const variantsIds = variantsUtils.collectAncestorsVariants(ps, componentPointer)
            _.forEach(variantsIds, id => addIdToMap({id}, id, oldToNewIdMap))
        }
    }

    /**
     * deserialize variants of component to the page, and replacing the ref from old comp id to new comp id
     *
     * @param {ps} ps privateServices
     * @param {object} clonedSerializedComp
     * @param {Pointer} containerPointer
     * @param {string} pageId
     * @param oldToNewIdMap
     * @param {boolean} isAncestorsChecked
     */
    function updateVariantsInStructure(ps, clonedSerializedComp, containerPointer, pageId, oldToNewIdMap, isAncestorsChecked) {
        collectAncestorsVariants(ps, containerPointer, oldToNewIdMap, isAncestorsChecked)

        _.forEach(clonedSerializedComp.variants, (variant, oldId) => {
            const variantCustomId = getCustomId(oldToNewIdMap, {id: oldId}, dataModel.generateNewId(DATA_TYPES.variants))
            const variantWithNewCompId = _.defaults({componentId: clonedSerializedComp.id}, variant)

            const {values} = variant
            _.forEach(values, val => {
                if (val.id) {
                    const newId = dataModel.generateNewId(DATA_TYPES.variants)
                    addIdToMap({id: val.id}, newId, oldToNewIdMap)
                    val.id = newId
                }
            })

            const newVariantId = addVariantItemToPage(ps, variantWithNewCompId, pageId, variantCustomId)
            if (newVariantId) {
                addIdToMap({id: oldId}, newVariantId, oldToNewIdMap)
                if (variantWithNewCompId.type === 'BreakpointsData') {
                    clonedSerializedComp.breakpointVariantsQuery = newVariantId
                }
            }
        })
    }

    function updateComponentBehaviorsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, modeIdsInHierarchy, compDefinition) {
        if (!compDefinition.behaviors) {
            return
        }
        if (_.isString(compDefinition.behaviors)) {
            //supporting old add panel data
            compDefinition.behaviors = dataModel.createBehaviorsItem(compDefinition.behaviors)
        }
        compDefinition.behaviors = fixBehaviorsIds(compDefinition.behaviors, modeIdsInHierarchy, oldToNewIdMap)
        optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.behaviors, optionalCustomId)
        const behaviorQuery = addComponentDefinitionBehaviors(ps, compDefinition.behaviors, pageId, optionalCustomId)
        if (behaviorQuery) {
            compDefinition.behaviorQuery = behaviorQuery
            addIdToMap(compDefinition.behaviors, behaviorQuery, oldToNewIdMap)
        }
    }

    function updateComponentMobileHintsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, compDefinition) {
        optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.mobileHints, optionalCustomId)
        const mobileHintsQuery = addComponentMobileHintsDefinition(ps, compDefinition.mobileHints, pageId, optionalCustomId)
        if (!mobileHintsQuery) {
            return
        }
        compDefinition.mobileHintsQuery = mobileHintsQuery
        addIdToMap(compDefinition.mobileHints, mobileHintsQuery, oldToNewIdMap)
    }

    function fixBehaviorItemModeIds(behaviorItem, modeIdsInHierarchy) {
        const behaviorItemModeIds = _.get(behaviorItem, ['params', 'modeIds'])
        if (behaviorItemModeIds) {
            _.set(
                behaviorItem,
                ['params', 'modeIds'],
                _.map(behaviorItemModeIds, function (modeId) {
                    return modeIdsInHierarchy.oldModeIdsToNew[modeId] || modeId
                })
            )
        }
    }

    function fixBehaviorItemCompIdsAndTargetIds(behaviorItem, oldToNewIdMap) {
        const sourceIdPath = ['action', 'sourceId']
        if (_.get(behaviorItem, ['action', 'type']) === 'comp') {
            const oldSourceId = _.get(behaviorItem, sourceIdPath)
            _.set(behaviorItem, sourceIdPath, _.get(oldToNewIdMap, oldSourceId, oldSourceId))
        }
        const runCodeCompIdPath = ['behavior', 'params', 'compId']
        const runCodeTargetIdPath = ['behavior', 'targetId']
        if (_.get(behaviorItem, ['behavior', 'name']) === 'runCode' && _.has(behaviorItem, runCodeCompIdPath)) {
            const oldRunCodeCompId = _.get(behaviorItem, runCodeCompIdPath)
            const oldRunCodeTargetId = _.get(behaviorItem, runCodeTargetIdPath)
            _.set(behaviorItem, runCodeCompIdPath, _.get(oldToNewIdMap, oldRunCodeCompId, oldRunCodeCompId))
            _.set(behaviorItem, runCodeTargetIdPath, _.get(oldToNewIdMap, oldRunCodeTargetId, oldRunCodeTargetId))
        }
    }

    function fixBehaviorsIds(behaviors, modeIdsInHierarchy, oldToNewIdMap) {
        if (behaviors && behaviors.items) {
            let behaviorsItems = behaviors.items
            if (_.isString(behaviorsItems)) {
                behaviorsItems = JSON.parse(behaviorsItems)
            }
            const fixedBehaviorItems = _.map(behaviorsItems, function (behaviorItem) {
                fixBehaviorItemModeIds(behaviorItem, modeIdsInHierarchy)
                fixBehaviorItemCompIdsAndTargetIds(behaviorItem, oldToNewIdMap)
                return behaviorItem
            })
            const behaviorResult = _.omit(behaviors, 'items')
            behaviorResult.items = JSON.stringify(fixedBehaviorItems)
            return behaviorResult
        }
        return behaviors
    }

    function updateComponentConnectionsDefinition(compDefinition, ps, pageId, optionalCustomId, oldToNewIdMap) {
        optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.connections, optionalCustomId)
        const connectionQuery = addComponentDefinitionConnections(ps, compDefinition.connections, pageId, optionalCustomId)
        if (connectionQuery) {
            compDefinition.connectionQuery = connectionQuery
            addIdToMap(compDefinition.connections, connectionQuery, oldToNewIdMap)
        }
    }

    function updateComponentPropsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, serializedCompOrModeOverride) {
        optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.props, optionalCustomId)
        const propertyQuery = setComponentDefinitionProps(ps, serializedCompOrModeOverride.props, pageId, optionalCustomId)
        if (propertyQuery) {
            serializedCompOrModeOverride.propertyQuery = propertyQuery
            addIdToMap(serializedCompOrModeOverride.props, propertyQuery, oldToNewIdMap)
        }
    }

    function createNewModeIds(compDefinition) {
        const modeDefinitions = _.get(compDefinition, ['modes', 'definitions'])
        if (modeDefinitions) {
            const compId = compDefinition.id
            return _.reduce(
                modeDefinitions,
                function (result, modeDef) {
                    result[modeDef.modeId] = componentModes.createUniqueModeId(compId)
                    return result
                },
                {}
            )
        }
        return {}
    }

    function updateCompDefinitionModes(serializedComp, oldModeIdsToNew) {
        if (serializedComp.modes && serializedComp.modes.definitions) {
            serializedComp.modes.definitions = _.map(serializedComp.modes.definitions, function (modeDef) {
                return _.defaults(
                    {
                        modeId: oldModeIdsToNew[modeDef.modeId]
                    },
                    modeDef
                )
            })
        }
    }

    function updateCompOverrideModes(serializedComp, oldModeIdsToNew) {
        if (_.get(serializedComp, ['modes', 'overrides'])) {
            serializedComp.modes.overrides = _.map(serializedComp.modes.overrides, function (modeOverride) {
                const newModeIds = _.map(modeOverride.modeIds, function (oldModeId) {
                    return oldModeIdsToNew[oldModeId] || oldModeId
                })
                return _.defaults(
                    {
                        modeIds: newModeIds
                    },
                    modeOverride
                )
            })
        }
    }

    function updateCompPropertiesModes(serializedComp, oldModeIdsToNew) {
        if (_.get(serializedComp, ['props', 'mobileDisplayedModeId'])) {
            serializedComp.props.mobileDisplayedModeId = oldModeIdsToNew[serializedComp.props.mobileDisplayedModeId]
        }
    }

    function deserializeComponentOverridesData(ps, serializedComponent, pageId, optionalCustomId, oldToNewIdMap) {
        let overrides = _.get(serializedComponent, ['modes', 'overrides'])
        if (!_.isEmpty(overrides)) {
            _.forEach(overrides, function (override) {
                setStructureDefinitionStyle(ps, pageId, serializedComponent.componentType, override)
                updateComponentDesignDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, override)
                updateComponentPropsDefinition(ps, pageId, optionalCustomId, oldToNewIdMap, override)
            })
            overrides = _.map(overrides, omitPropertiesFromSerializedComp)
            _.set(serializedComponent, ['modes', 'overrides'], overrides)
        }
    }

    function getCustomId(oldToNewIdMap, dataObject, customId) {
        let newId = customId
        if (!oldToNewIdMap || !dataObject) {
            return newId
        }
        const serializedDataId = stripHashIfExists(dataObject.id) || ''
        if (oldToNewIdMap[serializedDataId]) {
            newId = oldToNewIdMap[serializedDataId]
        } else if (_.startsWith(serializedDataId, MOBILE_COMP_DATA_PREFIX)) {
            const idSuffix = serializedDataId.substring(MOBILE_COMP_DATA_PREFIX.length)
            const mobilePropsMappedId = oldToNewIdMap[idSuffix]
            if (mobilePropsMappedId) {
                newId = MOBILE_COMP_DATA_PREFIX + mobilePropsMappedId
            }
        }
        return newId
    }

    function addIdToMap(dataObject, newId, oldToNewIdMap) {
        if (!oldToNewIdMap) {
            return
        }
        const serializedDataId = stripHashIfExists(dataObject.id) || ''
        newId = stripHashIfExists(newId)
        if (dataObject.id && oldToNewIdMap && !oldToNewIdMap[serializedDataId]) {
            oldToNewIdMap[serializedDataId] = newId
        }
    }

    function validateRemoval(ps, componentPointer) {
        validateRemovalInternal(ps, componentPointer, ps.setOperationsQueue.asyncPreDataManipulationComplete.bind(ps.setOperationsQueue))
    }

    function validateRemovalInternal(ps, componentPointer, onComplete, tpaCompRefs) {
        onComplete = onComplete || _.noop
        if (!componentModes.shouldDeleteComponentFromFullJson(ps, componentPointer)) {
            onComplete()
            return
        }

        if (ps.pointers.components.isMobile(componentPointer) && constants.MOBILE_ONLY_COMPONENTS.hasOwnProperty(componentPointer.id)) {
            throw new Error(componentValidations.ERRORS.CANNOT_DELETE_MOBILE_COMPONENT)
        }

        notifyComponentReadyForDeletion(ps, componentPointer, onComplete, tpaCompRefs)
    }

    function deleteCompFromDisplayedJson(ps, compPointer, completeCallback) {
        ps.dal.remove(compPointer)
        if (completeCallback) {
            completeCallback(ps)
        }
    }

    function getAllTpaComps(ps, componentPointer) {
        const tpaChildRefs = componentStructureInfo.getTpaChildren(ps, componentPointer)

        if (tpaUtils.isTpaByCompType(componentStructureInfo.getType(ps, componentPointer))) {
            tpaChildRefs.push(componentPointer)
        }
        return tpaChildRefs
    }

    function executeAllTpaDeleteHandlers(ps, componentPointer) {
        const tpaChildRefs = getAllTpaComps(ps, componentPointer)

        _.forEach(tpaChildRefs, function (childRef) {
            if (tpaEventHandlersService.isDeleteHandlerExists(childRef.id)) {
                tpaEventHandlersService.executeDeleteHandler(childRef.id)
            }
        })
    }

    function shouldDelayDeletionTpa(ps, componentPointer, tpaCompRefs) {
        if (!componentStructureInfo.isExist(ps, componentPointer)) {
            return false // we do that
        }
        const tpaChildRefs = tpaCompRefs || getAllTpaComps(ps, componentPointer)

        return _.some(tpaChildRefs, function (childRef) {
            return tpaEventHandlersService.isDeleteHandlerExists(childRef.id)
        })
    }

    function notifyComponentReadyForDeletion(ps, componentPointer, onComplete, tpaCompRefs) {
        onComplete = onComplete || _.noop
        const delayDelete = shouldDelayDeletionTpa(ps, componentPointer, tpaCompRefs)

        if (delayDelete) {
            executeAllTpaDeleteHandlers(ps, componentPointer)

            _.delay(function () {
                tpaEventHandlersService.unRegisterHandlers(ps, componentPointer.id)
                onComplete()
            }, 500)
        } else {
            onComplete()
        }
    }

    function removeStyleItem(ps, componentPointer) {
        const desktopStyle = componentStylesAndSkinsAPI.style.internal.get(ps, componentPointer)
        if (_.get(desktopStyle, 'type') === constants.STYLES.COMPONENT_STYLE) {
            const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer) || ps.pointers.full.components.getPageOfComponent(componentPointer)
            const pageId = pagePointer.id
            theme.styles.remove(ps, desktopStyle.id, pageId)
        }
        if (dataModel.refArray.isRefArray(ps, desktopStyle)) {
            variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, DATA_TYPES.theme)
        }
    }

    function deleteComponentData(ps, componentPointer) {
        const comps = [componentPointer]
        if (santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)) {
            const originalId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(componentPointer.id)
            const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
            const originalCompPointer = ps.pointers.full.components.getComponent(originalId, pagePointer)
            const existInFullAndNotInDisplayed = ps.dal.full.isExist(originalCompPointer) && !ps.dal.isExist(originalCompPointer)
            if (existInFullAndNotInDisplayed) {
                comps.push(originalCompPointer)
            }
        }

        const isDesktop = !ps.pointers.components.isMobile(componentPointer)
        _.forEach(comps, function (pointer) {
            const desktopPointer = isDesktop ? pointer : ps.pointers.components.getDesktopPointer(pointer)

            if (isDesktop) {
                // Data is never forked
                dataModel.deleteDataItem(ps, desktopPointer)
                dataModel.removeBehaviorsItem(ps, desktopPointer)
                dataModel.deleteMobileHintsItem(ps, desktopPointer)
                dataModel.deleteLayoutItem(ps, desktopPointer)
                dataModel.deleteDesignItem(ps, desktopPointer)
                dataModel.deletePropertiesItem(ps, desktopPointer)
                dataModel.deleteTransitionsItem(ps, desktopPointer)
                dataModel.deleteTransformationsItem(ps, desktopPointer)
                dataModel.deleteStatesItem(ps, desktopPointer)
                dataModel.deleteTriggersItem(ps, desktopPointer)
                dataModel.deleteReactionsItem(ps, desktopPointer)
                _.forEach(featureNamespaces, featureNamespace => dataModel.deleteFeatureItem(ps, desktopPointer, featureNamespace))
                dataModel.removeConnectionsItem(ps, desktopPointer)
                dataModel.deleteVariantsItems(ps, desktopPointer)
                removeStyleItem(ps, desktopPointer)
            }

            mobileUtil.deleteForkedData(ps, desktopPointer, componentData, dataModel, design)
        })
    }

    function deleteComponentDataAndInvokeHooks(
        ps,
        componentPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        completeCallback = _.noop,
        deleteCompFunc
    ) {
        const parentPointer = ps.pointers.components.getParent(componentPointer)
        const compType = componentStructureInfo.getType(ps, componentPointer)

        //TODO: check hooks pointers
        hooks.executeHook(hooks.HOOKS.REMOVE.BEFORE, compType, arguments)

        const childrenRefs = ps.pointers.full.components.getChildren(componentPointer)
        const shouldDeleteComponentFromFullJson = deletedParentFromFull || componentModes.shouldDeleteComponentFromFullJson(ps, componentPointer)
        _.forEachRight(childrenRefs, function (child) {
            return deleteCompFunc(ps, child, true, removeArgs, shouldDeleteComponentFromFullJson)
        })

        const copyDataItem = dataModel.getDataItem(ps, componentPointer)
        const componentConnections = connectionsDataGetter.getPlatformAppConnections(ps, componentPointer)
        if (!ps.pointers.components.isPage(componentPointer)) {
            //TODO: remove the !isPage and make the page delete flow work better. I will fix this. -Etai
            deleteComponentData(ps, componentPointer)
        }

        const argsForHooks = [ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, copyDataItem, parentPointer, componentConnections]
        const dal = ps.dal.isExist(componentPointer) ? ps.dal : ps.dal.full

        dal.remove(componentPointer)

        //TODO: check hooks pointers
        hooks.executeHook(hooks.HOOKS.REMOVE.AFTER, compType, argsForHooks)

        if (anchors && parentPointer && !deletingParent && ps.dal.get(parentPointer)) {
            anchors.updateAnchors(ps, parentPointer)
        }
        completeCallback(ps)
    }

    function deleteComponentImpl(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback) {
        const removeByParent = componentsMetaData.shouldBeRemovedByParent(ps, componentPointer)
        if (removeByParent) {
            componentPointer = ps.pointers.components.getParent(componentPointer) || ps.pointers.full.components.getParent(componentPointer)
        }

        const shouldDeleteComponentFromFullJson = deletedParentFromFull || componentModes.shouldDeleteComponentFromFullJson(ps, componentPointer)
        if (!shouldDeleteComponentFromFullJson) {
            deleteCompFromDisplayedJson(ps, componentPointer, completeCallback)
            return
        }

        const validationResult = componentValidations.validateComponentToDelete(ps, componentPointer, deletedParentFromFull)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        deleteComponentDataAndInvokeHooks(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback, deleteComponent)
    }

    function deleteComponentImplFromFull(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback) {
        let validationResult = componentValidations.validateComponentTypeToDelete(ps, componentPointer)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        validationResult = componentValidations.validateComponentExistOnFull(ps, componentPointer)
        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        deleteComponentDataAndInvokeHooks(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback, deleteComponentFromFull)
    }

    const getComponentsToRemove = (ps, componentPointer) => {
        const componentsToRemove = ps.pointers.full.components.getAllDisplayedOnlyComponents(componentPointer)
        const isRepeatedComponent = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)

        if (isRepeatedComponent) {
            const templateId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(componentPointer.id)
            const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
            const templateCompPointer = ps.pointers.full.components.getComponent(templateId, pagePointer)
            if (ps.dal.isExist(templateCompPointer)) {
                componentsToRemove.push(templateCompPointer)
            }
        } else if (ps.dal.isExist(componentPointer)) {
            componentsToRemove.push(componentPointer)
        }
        return _.uniqBy(componentsToRemove, 'id')
    }

    /**
     * Removes a component from display for a given defined path in the document.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Boolean} deletingParent is this component is deleted as part of deletion of it's parent
     * @param {Object} removeArgs - additional deletion arguments
     * @param deletedParentFromFull
     * @param completeCallback
     * returns {boolean} true iff the component was removed successfully.
     */
    function deleteComponent(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback) {
        if (componentsMetaData.shouldRemoveAsGhost(ps, componentPointer)) {
            return ghostifyComp(ps, componentPointer, completeCallback)
        }
        if (experiment.isOpen('dm_alwaysRemoveFromFull')) {
            const compToRemove = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
            completeCallback = completeCallback || _.noop
            deleteComponentImpl(ps, compToRemove, deletingParent, removeArgs, deletedParentFromFull, completeCallback)
        } else {
            const componentsToRemove = getComponentsToRemove(ps, componentPointer)
            completeCallback = completeCallback || _.noop
            const onAllCompleteCallback = _.after(componentsToRemove.length, completeCallback)
            _.forEach(componentsToRemove, function (compPointer) {
                deleteComponentImpl(ps, compPointer, deletingParent, removeArgs, deletedParentFromFull, onAllCompleteCallback)
            })
        }
    }

    /**
     * Removes a component from full for a given defined path in the document.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Boolean} deletingParent is this component is deleted as part of deletion of it's parent
     * @param {Object} removeArgs - additional deletion arguments
     * @param deletedParentFromFull
     * @param completeCallback
     * returns {boolean} true iff the component was removed successfully.
     */
    function deleteComponentFromFull(ps, componentPointer, deletingParent, removeArgs, deletedParentFromFull, completeCallback) {
        if (experiment.isOpen('dm_alwaysRemoveFromFull')) {
            const compToRemove = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
            completeCallback = completeCallback || _.noop
            deleteComponentImplFromFull(ps, compToRemove, deletingParent, removeArgs, deletedParentFromFull, completeCallback)
        } else {
            const allDisplayedOnlyComponents = ps.pointers.full.components.getAllDisplayedOnlyComponents(componentPointer)
            completeCallback = completeCallback || _.noop
            const onAllCompleteCallback = _.after(allDisplayedOnlyComponents.length, completeCallback)
            _.forEach(allDisplayedOnlyComponents, function (compPointer) {
                deleteComponentImplFromFull(ps, compPointer, deletingParent, removeArgs, deletedParentFromFull, onAllCompleteCallback)
            })
        }
    }

    function getComponentToDuplicateRef(ps, componentPointer, newContainerPointer, customId) {
        return createCompRef(ps, newContainerPointer, customId)
    }

    /**
     * Duplicate a component and place it under a given path.
     * This method duplicates child components recursively.
     * @param {ps} ps privateServices
     * @param {Pointer} compRefToAdd
     * @param {Pointer} componentPointer the component to be duplicated
     * @param {Pointer} newContainerPointer the path for the duplicated component
     * @param {String=} customId
     * @returns {*} the duplicated component
     */
    function duplicateComponent(ps, compRefToAdd, componentPointer, newContainerPointer, customId) {
        const serializedComponent = componentSerialization.serializeComponent(ps, componentPointer)
        hooks.executeHook(hooks.HOOKS.DUPLICATE.BEFORE, serializedComponent.componentType, [ps, componentPointer, serializedComponent, newContainerPointer])
        addComponentToContainer(ps, compRefToAdd, newContainerPointer, serializedComponent, customId)
    }

    /** Checks whether the component is removable
     * @param  {ps} ps
     * @param  {Pointer} compPointer
     * @returns {boolean} true if component is removable, false otherwise
     */
    function isComponentRemovable(ps, compPointer) {
        const isRemovable = componentsMetaData.public.isRemovable(ps, compPointer)
        if (!isRemovable) {
            return false
        }
        const compType = componentStructureInfo.getType(ps, compPointer)
        return hooks.executeHook(hooks.HOOKS.REMOVE.IS_OPERATION_ALLOWED, compType, arguments, function (isAllowed) {
            return isAllowed === false
        })
    }

    function isComponentDuplicatable(ps, compPointer, potentialParentPointer) {
        const isDuplicatable = componentsMetaData.public.isDuplicatable(ps, compPointer, potentialParentPointer)
        if (!isDuplicatable) {
            return false
        }
        const compType = componentStructureInfo.getType(ps, compPointer)
        return hooks.executeHook(hooks.HOOKS.DUPLICATE.IS_OPERATION_ALLOWED, compType, arguments, function (isAllowed) {
            return isAllowed === false
        })
    }

    /**
     * Returns the Layout Object of a Component.
     *
     * @param {ps} privateServices
     * @param {Pointer} compPointer a Component Reference matching a component in the document.
     * @returns {Object} a Layout object of the corresponding Component.
     * @throws an exception in case the compReference doesn't correspond any component in the document.
     *
     *      @example
     *      const myPhotoLayout = documentServices.components.layout.get(myPhotoRef);
     *      // perform some changes on the layout
     *      myPhotoLayout.x += 20;
     *      ...
     *      // update the document.
     *      documentServices.components.layout.update(myPhotoRef, myPhotoLayout);
     */
    function getComponentLayout(privateServices, compPointer) {
        return structureUtils.getComponentLayout(privateServices, compPointer)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {Pointer} compRef a Component Reference matching a component in the document.
     * @returns {ClientRect} - native browser boundingClientRect
     */
    function getCompBoundingClientRect(privateServices, compRef) {
        return privateServices.siteAPI.getComponentBoundingBox(compRef.id)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {Pointer} compRef a Component Reference matching a component in the document.
     * @param {string} selector the selector for the inner element
     * @returns {ClientRect[]} - native browser boundingClientRect array
     */
    function getCompInnerElementBoundingClientRects(privateServices, compRef, selector) {
        return privateServices.siteAPI.getComponentInnerElementBoundingBoxes(compRef.id, selector)
    }

    /**
     * @param {ps} privateServices
     * @param {Pointer} compRef a Component Reference matching a component in the document.
     * @returns {ClientRect} - native browser boundingClientRect relative to the viewport
     */
    function getRelativeToViewportBoundingBox(privateServices, compRef) {
        return privateServices.siteAPI.getRelativeToViewportBoundingBox(compRef.id)
    }

    /**
     *
     * @param {ps} privateServices
     * @param {Pointer} compRef a Component Reference matching a component in the document.
     * @returns {Padding} - native browser element padding (in px)
     */
    function getPadding(privateServices, compRef) {
        return privateServices.siteAPI.getPadding(compRef.id)
    }

    const getRenderFlag = (ps, flag) => ps.dal.get(ps.pointers.general.getRenderFlag(flag))

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {boolean} true if the component is visible, false otherwise
     */
    function isComponentVisible(ps, compPointer) {
        /*this is for supporting editor testkit still uses santa - there the plugins were not registered and it was always true*/
        if (runtimeConfig.isSanta(ps)) {
            return true
        }
        const viewMode = ps.pointers.components.getViewMode(compPointer)
        const isCompHiddenByRenderPlugin = ps.dal.get(ps.pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode))
        if (getRenderFlag(ps, 'componentViewMode') !== 'preview' && isCompHiddenByRenderPlugin) {
            return false
        }
        const compProperties = componentStructureInfo.getPropertiesItem(ps, compPointer)
        const ignoreHiddenProperty = _.includes(getRenderFlag(ps, 'ignoreComponentsHiddenProperty'), compPointer.id)

        const showHiddenComponents = getRenderFlag(ps, 'showHiddenComponents')
        const isHidden = _.get(compProperties, 'isHidden')
        return componentsMetaData.public.isVisible(ps, compPointer) && (!isHidden || ignoreHiddenProperty || (isHidden && showHiddenComponents))
    }

    /**
     * Generates a new random component Id to be used under a parent's path.
     * @returns {string} Unique ID
     */
    function generateNewComponentId() {
        return santaCoreUtils.guidUtils.getUniqueId('comp', '-', {bucket: 'componentIds'})
    }

    /** Migrates a component from one type to another type
     * @param  {ps} ps Private Services instance.
     * @param  {Pointer} compPointer Pointer to the component you wish to have migrated.
     * @param  {object} componentDefinition The JSON representing the component to be migrated to.
     */
    function migrate(ps, compPointer, componentDefinition) {
        const mobilePtr = ps.pointers.components.getMobilePointer(compPointer)
        const mobileParentPointer = ps.pointers.components.getParent(mobilePtr)
        const mobilePagePointer = ps.dal.isExist(mobilePtr) ? ps.pointers.components.getPageOfComponent(mobilePtr) : undefined
        const mobileContainerCompLayout =
            componentDefinition.mobileStructure ||
            (ps.dal.isExist(mobileParentPointer) ? ps.dal.get(ps.pointers.getInnerPointer(mobileParentPointer, ['layout'])) : null)
        const mobileCompLayout = ps.dal.isExist(mobilePtr) ? ps.dal.get(ps.pointers.getInnerPointer(mobilePtr, ['layout'])) : undefined
        const compParent = ps.pointers.full.components.getParent(compPointer)

        // Fix mobile layouts to be as before (if mobile exist)
        if (!componentDefinition.mobileStructure && mobileContainerCompLayout) {
            componentDefinition.mobileStructure = {layout: mobileContainerCompLayout}
        }

        const originalZIndex = arrangement.getCompIndex(ps, compPointer)

        //Remove original comp and add the new one instead
        removeComponent(ps, compPointer, _.noop, {isReplacingComp: true})

        addComponentToContainer(ps, compPointer, compParent, componentDefinition, undefined, originalZIndex)

        // Adjustments for mobile comp
        if (mobileContainerCompLayout && mobileCompLayout) {
            const desktopComp = ps.dal.get(compPointer)
            const mobileComp = {...desktopComp, layout: mobileCompLayout, components: []}
            const mobileParentsChildrenPointer = ps.pointers.full.components.getChildrenContainer(mobileParentPointer)
            ps.dal.full.remove(ps.pointers.components.getComponent(constants.DOM_ID_PREFIX.DEAD_MOBILE_COMP + compPointer.id, mobilePagePointer))
            const mobileParentsChildren = ps.dal.full.get(mobileParentsChildrenPointer)
            if (!mobileParentsChildren.includes(mobilePtr.id)) {
                ps.dal.full.push(mobileParentsChildrenPointer, mobileComp, mobilePtr)
            }
        }
    }

    function isComponentModal(ps, compPointer) {
        return componentsMetaData.public.isModal(ps, compPointer)
    }

    function isComponentUsingLegacyAppPartSchema(ps, compPointer) {
        return componentsMetaData.public.isUsingLegacyAppPartSchema(ps, compPointer)
    }

    /** @class documentServices.components */

    function applyComponentToMode(ps, components, activeModeId) {
        _.forEach(components, function (component) {
            const {compRef} = component
            componentModes.removeSingleModeBehaviors(ps, compRef)
            const modesPointer = ps.pointers.componentStructure.getModes(compRef)
            const compModes = ps.dal.full.get(modesPointer)

            const hasTargetMode = _.some(compModes.overrides, override => _.includes(override.modeIds, activeModeId))

            if (hasTargetMode) {
                compModes.overrides = compModes.overrides.map(function (override) {
                    if (_.includes(override.modeIds, activeModeId)) {
                        override.isHiddenByModes = false
                    }
                    return override
                })
            } else {
                compModes.overrides.push({modeIds: [activeModeId], isHiddenByModes: false})
            }

            ps.dal.full.set(modesPointer, compModes)

            const sourceOverride = _.find(component.compDefinition.modes.overrides, function (override) {
                const sourceActiveModeId = _(component.compDefinition.activeModes).keys().head()
                return _.includes(override.modeIds, sourceActiveModeId)
            })

            const sourceLayout = sourceOverride && sourceOverride.layout ? sourceOverride.layout : component.compDefinition.layout
            const layoutPointer = ps.pointers.getInnerPointer(compRef, 'layout')
            ps.dal.set(layoutPointer, sourceLayout)

            const sourceProps = sourceOverride && sourceOverride.props ? sourceOverride.props : component.compDefinition.props
            if (sourceProps) {
                dataModel.updatePropertiesItem(ps, compRef, sourceProps)
            }

            if (sourceOverride && sourceOverride.style) {
                componentStylesAndSkinsAPI.style.setId(ps, compRef, sourceOverride.previousStyleId)
            }
        })
    }

    function updateDesignItem(ps, componentPointer, designItem, retainCharas) {
        const currentCompDesignItem = design.getDesignItem(ps, componentPointer) || {}
        const backgroundChange = designItem.background && !_.isEqual(designItem.background, currentCompDesignItem.background)
        const designId = design.updateDesignItem(ps, componentPointer, designItem, retainCharas)
        if (backgroundChange && !design.shouldUseDesignInVariants(ps, componentPointer)) {
            removeInvalidMediaTransforms(ps, componentPointer)
        }
        return designId
    }

    function removeInvalidMediaTransforms(ps, compPointer) {
        const compDesignItems = componentModes.getAllCompDesignItems(ps, compPointer)
        const mediaRefs = _.map(compDesignItems, designItem => _.pick(design.getMediaRef(designItem), ['uri', 'videoId', 'type']))

        const sameMediaRefs = _.every(mediaRefs, mediaRef => _.isEqual(mediaRef, _.head(mediaRefs)))

        if (!sameMediaRefs || mediaRefs.length <= 1) {
            componentModes.removeDesignBehaviorsFromAllModes(ps, compPointer, ['media'])
        }
    }

    function validateComponentPointerRemoval(ps, componentPointer) {
        if (ps.pointers.components.isMobile(componentPointer) && constants.MOBILE_ONLY_COMPONENTS.hasOwnProperty(componentPointer.id)) {
            throw new Error(componentValidations.ERRORS.CANNOT_DELETE_MOBILE_COMPONENT)
        }

        if (ps.pointers.components.isWithVariants(componentPointer)) {
            throw new Error(componentValidations.ERRORS.CANNOT_DELETE_COMPONENT_WITH_VARIANT)
        }
    }

    function removeComponentFromFull(ps, componentPointer, completeCallback, removeArgs) {
        validateComponentPointerRemoval(ps, componentPointer)
        deleteComponentFromFull(ps, componentPointer, false, removeArgs, false, completeCallback)
    }

    function removeComponent(ps, componentPointer, completeCallback, removeArgs) {
        validateComponentPointerRemoval(ps, componentPointer)
        deleteComponent(ps, componentPointer, false, removeArgs, false, completeCallback)
    }

    const getMobileRef = (ps, componentPointer) => {
        const validationResult = componentValidations.validateExistingComponent(ps, componentPointer)

        if (!validationResult.success) {
            throw new Error(validationResult.error)
        }

        if (componentPointer.type !== constants.VIEW_MODES.DESKTOP) {
            throw new Error(componentValidations.ERRORS.INVALID_DESKTOP_POINTER)
        }

        return ps.pointers.components.getMobilePointer(componentPointer)
    }

    const ghostifyComp = (ps, compPointer, completeCallback = _.noop) => {
        if (componentsMetaData.shouldBeRemovedByParent(ps, compPointer)) {
            compPointer = ps.pointers.components.getParent(compPointer) || ps.pointers.full.components.getParent(compPointer)
        }

        const compType = componentStructureInfo.getType(ps, compPointer)
        const copyDataItem = dataModel.getDataItem(ps, compPointer)
        const componentConnections = connectionsDataGetter.getPlatformAppConnections(ps, compPointer)
        const newPropertyItem = {ghost: 'COLLAPSED'}

        dataModel.updatePropertiesItem(ps, compPointer, newPropertyItem)
        mobileUtil.updateMobilePropertyIfNeeded(ps, compPointer, newPropertyItem, dataModel.updatePropertiesItem)

        const argsForHooks = [ps, compPointer, undefined, undefined, undefined, copyDataItem, undefined, componentConnections]
        hooks.executeHook(hooks.HOOKS.GHOSTIFY.AFTER, compType, argsForHooks)

        completeCallback()
    }

    const getConstrainingComponents = (ps, compRef) => {
        const needsConstraining =
            componentsMetaData.public.isContainer(ps, compRef) && componentsMetaData.public.isEnforcingContainerChildLimitations(ps, compRef)
        if (!needsConstraining) {
            return []
        }

        const compType = componentStructureInfo.getType(ps, compRef)
        const children = componentStructureInfo.getChildren(ps, compRef)
        const hasChildren = children.length > 0
        // The reference to the specific comp type 'wysiwyg.viewer.components.StripColumnsContainer' should be temporary.
        // If we continue with this direction, this logic should be generalized. See https://jira.wixpress.com/browse/DM-5713
        const shouldSkipGenerationWhenConstraining = compType === 'wysiwyg.viewer.components.StripColumnsContainer' && hasChildren

        if (shouldSkipGenerationWhenConstraining) {
            return children
        }
        return [compRef]
    }

    const constrainRuntimeLayout = (ps, compRef, layout) => {
        const clonedLayout = _.cloneDeep(layout)
        const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(ps, compRef)
        const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(ps, compRef)
        const compsToConstrainBy = getConstrainingComponents(ps, compRef)

        for (const target of compsToConstrainBy) {
            layoutConstraintsUtils.constrainByChildrenLayout(ps, target, clonedLayout, !isEnforcingByWidth, !isEnforcingByHeight)
        }
        return clonedLayout
    }

    return _.merge(
        {
            initialize,

            /**
             * returns true if one of the component offspring type included in supplied types.
             *
             * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
             * @param {string|array} types component types to check against.
             * taken from the parent's reference or from the current view mode of the document.
             * @returns {boolean} true if one of the component offspring type included in supplied types.
             *
             *      @example
             *      const types = ['wysiwyg.viewer.components.tpapps.TPASection', 'wysiwyg.viewer.components.tpapps.TPAMultiSection'];
             *      const isContainTPASection = documentServices.components.isContainsCompWithType(mainPageRef, types);
             */
            isContainsCompWithType: componentStructureInfo.isContainsCompWithType,
            isRenderedOnSite: componentStructureInfo.isRenderedOnSite,
            getComponentToAddRef,
            getComponentToDuplicateRef,
            /**
             * Add a component to a container in the document.
             *
             * @param {Pointer} containerPointer of the container to add the component to.
             * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}<br>
             * componentDefinition is a javascript object containing data such as:
             * 'componentType', 'skin', 'style', 'layout', 'components'(children components), 'data' & 'props'.<br>
             * Notice that the 'componentDefinition' object may or may not contain 'layout' object.<br>
             * Notice that the 'componentDefinition' object must contain 'data' & 'props' either being of a 'string'
             * type matching the componentType, or a dataItem/propertiesItem matching the componentType.
             * These can be created by the documentServices.data.createItem("MyDataItemType") and
             * the documentServices.properties.. respectively;
             * @param {string} [optionalCustomId] An optional ID to give the component which will be added. in case
             * componentDefinition holds 'components' (holding componentDefinitions recursively), the 'id' key, will be
             * passed recursively as the childrens' Ids.
             * @returns the added component Reference, or 'null' if failed.
             * @throws an exception in case the parent container isn't a valid one.
             *
             *      @example
             *      const mainPageRef = documentServices.pages.getReference('mainPage');
             *      const photoCompDef = {
             *          "style": "wp1",
             *          "componentType": "wysiwyg.viewer.components.WPhoto",
             *          "data": "Image",
             *          "props": "WPhotoProperties"
             *      };
             *
             *      const addedPhotoRef = documentServices.components.add(mainPageRef, photoCompDef);
             *      documentServices.components.data.update(addedPhotoRef, {"uri": "http://www.company.domain/path-to/image.png"});
             */
            add: addComponentToContainer,
            getAddComponentInteractionParams,
            addWithConstraints,
            /**
             * Add a component to a container in the document.
             *
             * @param {Pointer} containerReference of the container to add the component to.
             * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}<br>
             * componentDefinition is a javascript object containing data such as:
             * 'componentType', 'skin', 'style', 'layout', 'components'(children components), 'data' & 'props'.<br>
             * Notice that the 'componentDefinition' object may or may not contain 'layout' object.<br>
             * Notice that the 'componentDefinition' object must contain 'data' & 'props' either being of a 'string'
             * type matching the componentType, or a dataItem/propertiesItem matching the componentType.
             * These can be created by the documentServices.data.createItem("MyDataItemType") and
             * the documentServices.properties.. respectively;
             * @param {string} [optionalCustomId] An optional ID to give the component which will be added. in case
             * componentDefinition holds 'components' (holding componentDefinitions recursively), the 'id' key, will be
             * passed recursively as the childrens' Ids.
             * @returns the added component Reference, or 'null' if failed.
             * @throws an exception in case the parent container isn't a valid one.
             *
             *      @example
             *      const mainPageRef = documentServices.pages.getReference('mainPage');
             *      const photoCompDef = {
             *          "style": "wp1",
             *          "componentType": "wysiwyg.viewer.components.WPhoto",
             *          "data": "Image",
             *          "props": "WPhotoProperties"
             *      };
             *
             *      const addedPhotoRef = documentServices.components.add(mainPageRef, photoCompDef);
             *      documentServices.components.data.update(addedPhotoRef, {"uri": "http://www.company.domain/path-to/image.png"});
             */
            addAndAdjustLayout: addComponent,
            addComponentInternal,
            /**
             * Duplicate a component and place it under a given path.<br>
             * This method duplicates child components recursively.
             * @param {Pointer} componentReference the reference to the component to duplicate.
             * @param {Pointer} newContainerReference the reference to the container to contain the new duplicated component.
             * @param {string} [customId] an optional specific custom ID to be given to the duplicated component.
             * @returns the added component Reference, or 'null' if failed.
             * @throws an exception in case the parent container isn't a valid one.
             *
             *      @example
             *      const duplicatedComponent = documentServices.components.duplicate(compReference, targetContainerReference, "compDouble");
             */
            duplicate: duplicateComponent,
            /**
             * @see componentSerialization.serializeComponent
             */
            serialize: componentSerialization.serializeComponent,
            /**
             * Deletes a component from the Document.
             *
             * @param {Pointer} componentReference the reference to the component to delete.
             * @param {Function} completeCallback - callback to be called when done
             * @param {Object} removeArgs - additional deletion arguments
             * @returns true iff the component was deleted successfully from the document.
             * @throws an exception in case ComponentReference view mode is NOT 'Desktop'.
             *
             *      @example
             *      const compReference = ...;
             *      const removeArgs = {isReplacingComp: true}
             *      const isDeleted = documentServices.components.remove(compReference, callBack, removeArgs);
             */
            remove: removeComponent,
            removeFromFull: removeComponentFromFull,
            deleteComponent,
            validateRemoval,
            validateRemovalInternal,
            migrateLayoutResponsiveToScopedLayouts,
            updateComponentScopedValuesDefinition,
            shouldDelayDeletion: shouldDelayDeletionTpa,
            /**
             * Returns the parent Component of a component.
             *
             * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
             * @returns {Pointer} the Component Reference of the parent component, or null if no parent component (example - for page)
             * @throws an error in case the '<i>componentReference</i>' is invalid.
             *
             *      @example
             *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
             */
            getContainer: componentStructureInfo.getContainer,
            /**
             * Returns all parent Components of a component.
             *
             * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
             * @returns [{Pointer}] array of the the Components Reference of the parent components, or empty array when there is no parent component (example - for page)
             * @throws an error in case the '<i>componentReference</i>' is invalid.
             *
             *      @example
             *      const buttonContainerCompRef = documentServices.components.getAncestors(buttonComponentRef);
             */
            getAncestors: componentStructureInfo.getAncestors,
            getAncestorsFromFull: componentStructureInfo.getAncestorsFromFull,
            /**
             * Returns the parent Component of a component.
             *
             * @returns {Pointer} the page the the component is in
             * @throws an error in case the '<i>componentReference</i>' is invalid.
             *
             *      @example
             *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
             * @param {Pointer} compPointer
             */
            getPage: componentStructureInfo.getPage,
            /**
             * Returns the Siblings array of a component.
             *
             * @param {Pointer} compReference a Component Reference corresponding a component in the document.
             * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's siblings.
             * @throws an Error in case the compReference isn't valid.
             */
            getSiblings: componentStructureInfo.getSiblings,
            /**
             * Returns an array of the repeated items of a component.
             *
             * @param {Pointer} compReference a Component Reference corresponding a component in the document.
             * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's repeated components.
             * @throws an Error in case the compReference isn't valid.
             */
            getRepeatedComponents: componentStructureInfo.getRepeatedComponents,
            /**
             * returns the children components of a parent component, that should be displayed on render.
             * If a site exists, these are the currently rendered children.
             *
             * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
             * taken from the parent's reference or from the current view mode of the document.
             * @param {boolean} [isRecursive] if true, will return all parentCompReference descendants (recursively)
             * @returns {Pointer[]} an array of the Component's Children (Component) References.
             * @throws an error in case the <i>parentCompReference</i> is invalid.
             *
             *      @example
             *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
             */
            getChildren: componentStructureInfo.getChildren,

            /**
             * returns all the children components of a parent component, from full json (both displayed and not).
             *
             * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
             * taken from the parent's reference or from the current view mode of the document.
             * @param {boolean} [isRecursive] if true, will return all parentCompReference descendants (recursively)
             * @returns {Pointer[]} an array of the Component's Children (Component) References.
             * @throws an error in case the <i>parentCompReference</i> is invalid.
             *
             *      @example
             *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
             */
            getChildrenFromFull: componentStructureInfo.getChildrenFromFull,
            getAllTpaComps,
            /**
             * returns the children components of a parent component.
             *
             * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
             * taken from the parent's reference or from the current view mode of the document.
             * @returns {Pointer[]} an array of the Component's Children (Component) References.
             * @throws an error in case the <i>parentCompReference</i> is invalid.
             *
             *      @example
             *      const mainPageChildren = documentServices.components.getAllJsonChildren(mainPageRef);
             */
            getAllJsonChildren: componentStructureInfo.getAllJsonChildren,

            /**
             * returns the children tpa components recurse of a parent component.
             *
             * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
             * @param {string} [viewMode] is the view mode of the document (DESKTOP|MOBILE), if not specified will be
             * taken from the parent's reference or from the current view mode of the document.
             * @returns {Pointer[]} an array of the Component's Children (Component) References.
             * @throws an error in case the <i>parentCompReference</i> is invalid.
             *
             *      @example
             *      const viewMode = 'DESKTOP'; // This is optional
             *      const mainPageChildren = documentServices.components.layout.getChildComponents(mainPageRef, viewMode);
             */
            getTpaChildren: componentStructureInfo.getTpaChildren,
            getBlogChildren: componentStructureInfo.getBlogChildren,
            /**
             * returns the type/"class" of a component.
             *
             * @function
             * @memberOf documentServices.components.component
             *
             * @param {Pointer} componentReference the reference to the component to get its type.
             * @returns {string} the name of the component Type/"class". 'null' if no corresponding component was found.
             *
             *      @example
             *      const photoType = documentServices.components.getType(myPhotoReference);
             */
            getType: componentStructureInfo.getType,
            getDefinition: componentStructureInfo.getDefinition,
            /**
             * returns the mobile pointers
             * @returns {Pointer} the mobile pointer of the component
             * @throws an error in case the Pointer is not desktop or the Pointer is not exist.
             * @param {Pointer} desktop component pointer
             */
            getMobileRef,
            setComponent,
            isComponentRemovable,
            isComponentVisible,
            isComponentModal,
            isComponentUsingLegacyAppPartSchema,
            isComponentDuplicatable,
            isPageComponent: componentStructureInfo.isPageComponent,
            generateNewComponentId,
            migrate,

            /** @class documentServices.components.properties*/
            properties: {
                /**
                 * Updates component's Properties (Data)Item.
                 * @param {Pointer} componentRef A ComponentReference to match a corresponding Component in the document.
                 * @param {Object} propertiesItem A partial Properties (Data)Item corresponding the properties type of the
                 * Component's Data to update.
                 *
                 *      @example
                 *      const myPhotoRef = ...;
                 *      documentServices.components.properties.update(myPhotoRef, {displayMode: "full"});
                 */
                update: dataModel.updatePropertiesItem,
                /**
                 * Gets a Properties(Data)Item instance corresponding a Component Reference from the document.
                 *
                 * @param {Pointer} componentReference a reference of a component in the document.
                 * @returns {Object} a Properties (Data)Item corresponding the componentReference. 'null' if not found.
                 */
                get: dataModel.getPropertiesItem,
                /** @class documentServices.components.properties.mobile*/
                mobile: {
                    /**
                     * Creates a copy of the desktop component properties and set it to the mobile component
                     * @param {Pointer} mobileCompRef
                     */
                    fork: componentData.splitMobileComponentProperties,
                    /**
                     * Returns the mobile component properties to their default mode.<br>
                     * In most cases it means that the mobile and desktop component properties will point to the same object.
                     * @param {Pointer} mobileCompRef
                     */
                    join: componentData.resetMobileComponentProperties,
                    /**
                     * Checks if component has split properties - mobile and desktop.
                     * @param {Pointer} mobileCompRef
                     * @returns {boolean} true if component has split properties
                     */
                    isForked: componentData.isMobileComponentPropertiesSplit
                }
            },
            /** @class documentServices.components.data*/
            data: {
                /**
                 * Gets a DataItem instance corresponding a Component Reference from the document.
                 *
                 * @param {Pointer} componentReference a reference of a component in the document.
                 * @returns {Object} a Data Item corresponding the componentReference. 'null' if not found.
                 */
                get: dataModel.getDataItem,
                /**
                 * Merges the given data item to the component data item
                 *
                 * @param {Pointer} componentRef A ComponentReference to match a corresponding Component.
                 * @param {Object} dataItem A partial DataItem corresponding the type of the Component's Data to update.
                 * @param {Boolean} [useOriginalLanguage] A boolean which holds whether to maintain original language data-Item. Tpa's defaults to true
                 * @returns undefined
                 *
                 *      @example
                 *      const myPhotoRef = ...;
                 *      documentServices.components.data.update(myPhotoRef, {uri: "http://static.host.com/images/image-B.png"});
                 */
                update: dataModel.updateDataItem,
                /**
                 * Merges the given data item to the component data item
                 *
                 * @param {Pointer} componentRef A ComponentReference to match a corresponding Component.
                 * @param {Object} dataItem A partial DataItem corresponding the type of the Component's Data to update.
                 * @param {String} [useLanguage] language code to use when setting the dataItem. e.g. he/de/es...
                 * @returns undefined
                 *
                 *      @example
                 *      const myPhotoRef = ...;
                 *      documentServices.components.data.updateInLang(myPhotoRef, {uri: "http://static.host.com/images/image-B.png"},'he');
                 */
                updateInLang: dataModel.updateDataItemInLang
            },
            feature: {
                update: dataModel.updateLayerData,
                get: dataModel.getLayerData
            },
            design: {
                updateDesignItem
            },
            /** @class documentServices.components.layout*/
            layout: {
                /**
                 * Returns the Layout Object of a Component.
                 *
                 * @param {Pointer} compReference a Component Reference matching a component in the document.
                 * @returns {Object} a Layout object of the corresponding Component.
                 * @throws an exception in case the compReference doesn't correspond any component in the document.
                 *
                 *      @example
                 *      const myPhotoLayout = documentServices.components.layout.get(myPhotoRef);
                 *      // perform some changes on the layout
                 *      myPhotoLayout.x += 20;
                 *      ...
                 *      // update the document.
                 *      documentServices.components.layout.update(myPhotoRef, myPhotoLayout);
                 */
                get: getComponentLayout,
                measure: {
                    /**
                     * Returns boundingClientRect - native browser function
                     *
                     * @param {Pointer} CompRef
                     */
                    getBoundingClientRect: getCompBoundingClientRect,
                    /**
                     * Returns boundingClientRect array - native browser function
                     *
                     * @param {Pointer} CompRef
                     * @param {string} selector
                     */
                    getInnerElementBoundingClientRects: getCompInnerElementBoundingClientRects,
                    /**
                     * Returns relative to viewport boundingBox
                     *
                     * @param {Pointer} CompRef
                     */
                    getRelativeToViewportBoundingBox,
                    getPadding
                },
                updateAndPushStart: (ps, compRef) => {
                    return ps.siteAPI.updateAndPushStart(compRef.id)
                },
                updateAndPushUpdate: (ps, compRef, runtimeLayout) => {
                    const constrainedLayout = constrainRuntimeLayout(ps, compRef, runtimeLayout)
                    return ps.siteAPI.updateAndPushUpdate(compRef.id, constrainedLayout)
                },
                updateAndPushEnd: (ps, compRef) => {
                    return ps.siteAPI.updateAndPushEnd(compRef.id)
                },
                runtime: {
                    /** Return the components that should be used to constrain the layout of the component represented by `compRef`.
                     *
                     * For most comps this is the comp itself.
                     * For most containers this is comp itself, if it has one of the relevant metaData flags:
                     *  - enforceContainerChildLimitsByHeight
                     *  - enforceContainerChildLimitsByWidth
                     * For StripColumnsContainers with children these are the children. Most likely, this case will be
                     *  generalized into a metaData flag in the future. See JIRA DM-5713
                     *
                     * @param {ps} ps
                     * @param {Pointer} compRef
                     * @returns {Pointer[]}
                     */
                    getConstrainingComponents,
                    /**
                     * update the comp position only on viewer and not on json. needed for optimistic drag using mesh for TB
                     *
                     * This function enforces layout constraints according to the result of the `getConstrainingComponents`
                     * function in this module before sending the command to the viewer.
                     *
                     * @param {ps} ps
                     * @param {Pointer} compRef
                     * @param {object} runtimeLayout - object containing y/height
                     */
                    updateAndPush: (ps, compRef, runtimeLayout) => {
                        const constrainedLayout = constrainRuntimeLayout(ps, compRef, runtimeLayout)
                        return ps.siteAPI.updateRuntimeLayout(compRef.id, constrainedLayout)
                    },
                    /**
                     * update the comp position only on viewer and not on json. needed for optimistic drag using mesh for TB
                     *
                     * @param {ps} ps
                     * @param {Pointer} compRef
                     */
                    remove: (ps, compRef) => ps.siteAPI.removeRuntimeLayout(compRef.id),
                    /**
                     * Detach component from layout and define absolute position to it
                     *
                     * @param {ps} ps
                     * @param {Pointer} compPointer
                     */
                    detachLayout: (ps, compPointer) => {
                        const parentPointer = ps.pointers.components.getParent(compPointer)
                        ps.siteAPI.detach(compPointer.id, parentPointer.id)
                    },
                    /**
                     * Detach components from layout and define absolute position to it
                     *
                     * @param {ps} ps
                     * @param {Pointer[]} componentsPointers
                     */
                    detachComponents: (ps, componentsPointers) => ps.siteAPI.detachMulti(_.map(componentsPointers, 'id')),
                    /**
                     * Change component inner style
                     *
                     * @param {ps} ps
                     * @param {Pointer} detachedCompPointer
                     * @param {object} boundingBox
                     */
                    updateDetachedLayout: (ps, detachedCompPointer, boundingBox) => ps.siteAPI.updateDetached(detachedCompPointer.id, boundingBox),
                    reattachLayout: (ps, detachedCompPointer) => ps.siteAPI.clearDetached(detachedCompPointer.id),
                    reattachLayoutAndUpdate: (ps, detachedCompPointer, layout, cb) => {
                        structure.updateCompLayout(ps, detachedCompPointer, layout)
                        ps.siteAPI.clearDetached(detachedCompPointer.id)
                        //should be called synchronously after viewer api call, because editor need to disable reading layout deltas from viewer
                        cb()
                    },
                    reattachComponents: ps => ps.siteAPI.clearDetached()
                }
            },

            isExist: componentStructureInfo.isExist,
            /**
             * this should be used in every method that adds a component to a container,
             * some containers have other containers in them that the component should be added to
             * @param {ps} privateServices
             * @param {Pointer} containerPointer the container that we want to add a component to
             * @returns {Pointer} a pointer to the container
             */
            getContainerToAddComponentTo: componentStructureInfo.getContainerToAddComponentTo,
            applyComponentToMode,
            getA11ySchema: a11yAPI.getA11ySchema
        },
        {
            modes: componentModes
        }
    )
})
