import _ from 'lodash'
import santaComponents from '@wix/santa-components'

function getCustomCss(appPartStyleCollector) {
    function appPartCustomCss(getSkin, props) {
        const {getStyleData, themeData, siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom, scriptsLocationMap} = props
        const mobileData = {siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom}
        const serviceTopology = {scriptsLocationMap}

        const loadedSkins = appPartStyleCollector(props)

        return _(loadedSkins)
            .mapValues((skinObj, styleId) => {
                const styleData = getStyleData(styleId, skinObj.skinName)
                return santaComponents.utils.skinsRenderer.createSkinCss(
                    getSkin(styleData.skin),
                    _.get(styleData, 'style.properties', {}),
                    themeData,
                    styleId,
                    mobileData,
                    serviceTopology,
                    getSkin
                )
            })
            .omitBy(_.isNil)
            .value()
    }

    appPartCustomCss.cssTypes = _.defaults(
        {
            getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData,
            themeData: santaComponents.santaTypesDefinitions.Theme.THEME_DATA,
            siteZoomRatio: santaComponents.santaTypesDefinitions.Mobile.siteZoomRatio,
            invertedZoomRatio: santaComponents.santaTypesDefinitions.Mobile.invertedZoomRatio,
            orientationZoomFix: santaComponents.santaTypesDefinitions.Mobile.orientationZoomFix,
            mobileZoom: santaComponents.santaTypesDefinitions.Mobile.mobileZoom,
            scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap
        },
        appPartStyleCollector.cssTypes
    )

    return appPartCustomCss
}

export default {
    getCustomCss
}
