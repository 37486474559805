import _ from 'lodash'

const getComponent = function (aspectSiteAPI, compId) {
    return aspectSiteAPI.getComponentById(compId)
}

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function TPAComponentsStatusAspect(aspectSiteApi) {
    /** @type core.SiteAspectsSiteAPI */
    this.aspectSiteApi = aspectSiteApi
    this.aliveTpaComponentPromises = {}
    this.handleComponentIsAlive = {}

    _.bindAll(this, _.functionsIn(this))
}

TPAComponentsStatusAspect.prototype = {
    clearComponentIsAlive(compId) {
        delete this.handleComponentIsAlive[compId]
        delete this.aliveTpaComponentPromises[compId]
    },
    markComponentIsAlive(compId) {
        const component = getComponent(this.aspectSiteApi, compId)
        if (this.handleComponentIsAlive[compId]) {
            this.handleComponentIsAlive[compId](component)
        } else {
            this.aliveTpaComponentPromises[compId] = Promise.resolve(component)
        }
    },

    getComponentWhenReady(compId) {
        const {aliveTpaComponentPromises} = this

        aliveTpaComponentPromises[compId] =
            aliveTpaComponentPromises[compId] ||
            new Promise(
                function (resolve) {
                    this.handleComponentIsAlive[compId] = resolve
                }.bind(this)
            )

        return aliveTpaComponentPromises[compId]
    }
}

export default TPAComponentsStatusAspect
