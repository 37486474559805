import _ from 'lodash'

let loaded = false

const remarketingParams = {
    google_custom_params: {},
    google_remarketing_only: true
}

function initRemarketingPixel(accountId) {
    if (loaded || !isValidAccountIdParam(accountId)) {
        return
    }

    _.assign(remarketingParams, {google_conversion_id: accountId[0]})
    insertGoogleRemarketingScript()
    loaded = true
}

function isValidAccountIdParam(accountId) {
    return _.isArray(accountId) && accountId.length === 1 && Number(accountId[0])
}

function insertGoogleRemarketingScript() {
    const scriptElem = window.document.createElement('script')
    scriptElem.type = 'text/javascript'
    scriptElem.src = '//www.googleadservices.com/pagead/conversion_async.js'
    scriptElem.async = true
    scriptElem.setAttribute('onload', `google_trackConversion(${JSON.stringify(remarketingParams)})`)

    const s = window.document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(scriptElem, s)
}

function fireRemarketingPixel() {
    if (loaded && window.google_trackConversion) {
        window.google_trackConversion(remarketingParams)
    }
}

export default {
    initRemarketingPixel,
    fireRemarketingPixel
}
