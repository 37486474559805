import {CoreLogger, DAL, pointerUtils} from '@wix/document-manager-core'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import type {Pointer, Pointers, Layout} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {ReportableError} from '@wix/document-manager-utils'
const {getRepeaterItemId, getRepeaterTemplateId, getUniqueDisplayedId, getUniqueStructure, isRepeatedComponent, isRefPointer} = displayedOnlyStructureUtil

interface DalGetPointers {
    dal: Pick<DAL, 'get'>
    pointers: Pointers
}

const {getInnerPointer, getRepeatedItemPointerIfNeeded} = pointerUtils

const isRepeater = ({get}: Pick<DAL, 'get'>, pointer: Pointer) => {
    const compTypePointer = getInnerPointer(pointer, ['componentType'])
    return get(compTypePointer) === 'wysiwyg.viewer.components.Repeater'
}

const isRepeaterMasterItem = ({dal, pointers}: DalGetPointers, pointer: Pointer) => {
    if (!isRepeatedComponent(pointer.id)) {
        return false
    }
    const repeaterPointer = pointers.components.getAncestorByPredicate(pointer, parentPtr => isRepeater(dal, parentPtr))
    const itemId = getRepeaterItemId(pointer.id)
    const templateItemPointer = pointers.displayedOnlyComponents.getComponentTemplateId(repeaterPointer.id)
    const currentMasterItem = dal.get(templateItemPointer)

    return itemId === currentMasterItem
}

const mergeLayout = (pointer: Pointer, currentLayout: Layout, newLayout: Layout, logger: CoreLogger) => {
    const mergedLayout = _.merge({}, currentLayout, newLayout)
    if (!_.isFinite(mergedLayout.x) || !_.isFinite(mergedLayout.y)) {
        logger.captureError(
            new ReportableError({
                message: 'illegal layout when update master item',
                errorType: 'RepeaterTemplateLayoutError',
                extras: {
                    pointer,
                    currentLayout,
                    newLayout
                }
            })
        )
        mergedLayout.x = _.isFinite(newLayout.x) ? newLayout.x : currentLayout.x
        mergedLayout.y = _.isFinite(newLayout.y) ? newLayout.y : currentLayout.y
    }
    return mergedLayout
}

const updateRepeaterMasterItem = (
    {pointers, dal, viewerManager, logger}: {dal: DAL; pointers: Pointers; viewerManager: ViewerManager; logger: CoreLogger},
    pointer: Pointer
) => {
    if (!isRepeatedComponent(pointer.id)) {
        return
    }

    //get the parent repeater
    let componentPointer = pointers.getOriginalPointerFromInner(pointer)
    let parentPointer = pointers.components.getParent(componentPointer)
    while (isRepeatedComponent(parentPointer.id)) {
        componentPointer = pointers.getOriginalPointerFromInner(parentPointer)
        parentPointer = pointers.components.getParent(componentPointer)
    }

    /// update repeater template item map
    const itemId = getRepeaterItemId(componentPointer.id)
    const templateItemPointer = pointers.displayedOnlyComponents.getComponentTemplateId(parentPointer.id)
    const currentTemplateItem = dal.get(templateItemPointer)
    if (currentTemplateItem === itemId) {
        return
    }
    dal.set(templateItemPointer, itemId)

    //update siblings template layout
    const descendants = pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(componentPointer)
    _(descendants)
        .reject(isRefPointer)
        .map(descendantPointer => [descendantPointer, getRepeatedItemPointerIfNeeded(descendantPointer)])
        .filter(([, descendantPointerTemplate]) => dal.has(descendantPointerTemplate))
        .forEach(([descendantPointer, descendantPointerTemplate]) => {
            if (!viewerManager.dal.isExist(descendantPointer)) {
                logger.captureError(
                    new ReportableError({
                        message: 'missing descendant when updating master item',
                        errorType: 'RepeaterTemplateMissingDescendantError',
                        extras: {
                            pointer: descendantPointer
                        }
                    })
                )
                return
            }
            const layoutPointerToUpdate = getInnerPointer(descendantPointerTemplate, 'layout')
            const currentLayout = viewerManager.dal.get(getInnerPointer(descendantPointer, 'layout'))
            const compMeasures = viewerManager.viewerSiteAPI.getBasicMeasureForComp(descendantPointer.id)
            const newLayout = mergeLayout(descendantPointer, currentLayout, compMeasures, logger)
            dal.set(layoutPointerToUpdate, newLayout)
        })
}

export {
    isRepeater,
    isRepeatedComponent,
    getRepeaterTemplateId,
    getRepeaterItemId,
    getUniqueDisplayedId,
    getUniqueStructure,
    isRepeaterMasterItem,
    updateRepeaterMasterItem
}
