import _ from 'lodash'
import Activity from './activity'

const TPAActivity = function (activityInfo, fields) {
    Activity.call(this, activityInfo, fields)
}

TPAActivity.prototype = new Activity()

TPAActivity.prototype.getParams = function (params) {
    const baseParams = Activity.prototype.getParams.call(this, params)
    let tpaParams = {
        'application-id': this._fields.appDefinitionId,
        instance: this._fields.instance
    }

    tpaParams = _.assign(baseParams, tpaParams)

    return tpaParams
}

TPAActivity.prototype.getPayload = function (payload) {
    const basePayload = Activity.prototype.getPayload.call(this, payload)
    let tpaPayload = {
        contactUpdate: this._fields.contactUpdate,
        activityInfo: this._fields.info,
        activityType: this._fields.type,
        activityDetails: this._fields.details
    }

    tpaPayload = _.assign(basePayload, tpaPayload)

    return tpaPayload
}

export default TPAActivity
