import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import DataAccessPointers from './DataAccessPointers'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'
import jsonItemsFinders from './jsonItemsFinders'

const {constants} = warmupUtilsLib
const pointers = new DataAccessPointers()
const masterPageId = 'masterPage'
//var masterPagePath = ['pagesData', 'masterPage', 'structure'];

function getFlatPagePath(pageId, viewMode) {
    return getFlatComponentPath(pageId, viewMode, pageId)
}

function getFlatComponentPath(pageId, viewMode, compId) {
    return ['pagesData', pageId, 'structure', viewMode, compId]
}

function getFlatPageStructurePath(pageId, viewMode) {
    return ['pagesData', pageId, 'structure', viewMode]
}

function isFlatPagePath(path) {
    return _.isArray(path) && path[1] === path[4]
}

function getPagePath(pageId) {
    return ['pagesData', pageId, 'structure']
}

function isPagePath(path, isFlat?) {
    return _.isArray(path) && (isFlat ? path[1] === path[4] : path[path.length - 3] === 'pagesData')
}

function findCompInPage(getItemAtPath, pointer, pageId) {
    let page = getItemAtPath(getFlatPagePath(pageId, pointer.type))
    if (page) {
        const compPath = getFlatComponentPath(pageId, pointer.type, pointer.id)
        return getItemAtPath(compPath) ? compPath : null
    }

    const pagePath = getPagePath(pageId)
    page = getItemAtPath(pagePath)
    if (!page) {
        return undefined
    }
    let compPath = jsonItemsFinders.getComponentPath(page, pointer.id, pointer.type)
    if (compPath) {
        compPath = getPagePath(pageId).concat(compPath)
    }
    return compPath
}

function findComponent(currentRootIds, getItemAtPath, pointer, shouldNotTryOtherPages) {
    let compPath
    _.forEach(currentRootIds, function (rootId) {
        compPath = compPath || findCompInPage(getItemAtPath, pointer, rootId)
    })

    if (!compPath) {
        if (!shouldNotTryOtherPages) {
            const currentRootIdsMap = _.keyBy(currentRootIds)
            _.forOwn(getItemAtPath(['pagesData']), function (page, pageId) {
                if (currentRootIdsMap[pageId]) {
                    return true
                }
                compPath = findCompInPage(getItemAtPath, pointer, pageId)
                if (compPath) {
                    return false
                }
            })
        }
    }
    return compPath
}

pointerGeneratorsRegistry.registerPointerType(constants.VIEW_MODES.DESKTOP, findComponent, jsonItemsFinders.isComponentWithId, true, true, true)
pointerGeneratorsRegistry.registerPointerType(constants.VIEW_MODES.MOBILE, findComponent, jsonItemsFinders.isComponentWithId, true, true, true)

const getterFunctions = {
    getMobilePointer(getItemAtPath, cache, pointer) {
        return _.assign(_.clone(pointer), {type: constants.VIEW_MODES.MOBILE})
    },

    getDesktopPointer(getItemAtPath, cache, pointer) {
        return _.assign(_.clone(pointer), {type: constants.VIEW_MODES.DESKTOP})
    },

    isMobile(getItemAtPath, cache, pointer) {
        return pointer.type === constants.VIEW_MODES.MOBILE
    },

    isWithVariants(getItemAtPath, cache, pointer) {
        return !!_.get(pointer, ['variants'])
    },

    isPage(getItemAtPath, cache, pointer) {
        const path = cache.getPath(pointer)
        return cache.flat ? isFlatPagePath(path) : isPagePath(path)
    },

    isPagesContainer(getItemAtPath, cache, pointer) {
        return pointer.id === constants.COMP_IDS.PAGES_CONTAINER
    },

    isMasterPage(getItemAtPath, cache, pointer) {
        const compPath = cache.getPath(pointer)
        if (!compPath) {
            return false
        }
        const pageId = compPath[1]
        return pageId === masterPageId && this.isPage(getItemAtPath, cache, pointer)
    },

    isInMasterPage(getItemAtPath, cache, pointer) {
        const compPath = cache.getPath(pointer)
        const pageId = compPath[1]
        return pageId === masterPageId
    },

    getViewMode(getItemAtPath, cache, pointer) {
        return this.isMobile(getItemAtPath, cache, pointer) ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
    },

    getChildrenContainer(getItemAtPath, cache, pointer) {
        const path = cache.getPath(pointer)
        const comp = getItemAtPath(path)
        const childrenProperty = jsonItemsFinders.getChildrenPropertyName(comp, pointer.type)
        const newPointer = pointers.getOriginalPointerFromInner(pointer)
        return pointers.getInnerPointer(newPointer, childrenProperty)
    },

    getChildren(getItemAtPath, cache, pointer) {
        const path = cache.getPath(pointer)
        if (!path) {
            return []
        }
        const comp = getItemAtPath(path)
        const childrenProperty = jsonItemsFinders.getChildrenPropertyName(comp, pointer.type)

        const children = comp[childrenProperty]
        const pagePopinter = this.getPageOfComponent(getItemAtPath, cache, pointer)
        return _.map(children, function (child, index) {
            const childPath = cache.flat ? getFlatComponentPath(pagePopinter.id, pointer.type, child) : path.concat([childrenProperty, index])
            return cache.getPointer(cache.flat ? child : child.id, pointer.type, childPath)
        })
    },

    getChildrenRecursively(getItemAtPath, cache, pointer) {
        const comps = this.getChildren(getItemAtPath, cache, pointer)
        let index = 0
        while (index < comps.length) {
            comps.push.apply(comps, this.getChildren(getItemAtPath, cache, comps[index]))
            index++
        }
        return comps
    },

    /**
     * will return the children, including root, the order is right left root, from the higher z order to lower
     * @param getItemAtPath
     * @param cache
     * @param pointer
     * @param {function(jsonDataPointer): boolean} predicate
     */
    getChildrenRecursivelyRightLeftRootIncludingRoot(getItemAtPath, cache, pointer, predicate) {
        let nodes = [pointer]
        const orderedNodes = []
        while (nodes.length) {
            const current = nodes.shift()

            if (!_.isFunction(predicate) || predicate(current)) {
                orderedNodes.unshift(current)
            }

            const children = this.getChildren(getItemAtPath, cache, current)
            nodes = children.concat(nodes)
        }

        return orderedNodes
    },

    findComponentInPage(getItemAtPath, cache, pagePointer, isMobileView, predicate) {
        const pagePath = cache.flat ? getFlatPageStructurePath(pagePointer.id, pagePointer.type) : cache.getPath(pagePointer)
        const page = getItemAtPath(pagePath)

        const comp = cache.flat ? _(page).values().find(predicate) : warmupUtilsLib.dataUtils.findCompInStructure(page, isMobileView, predicate)

        return comp ? this.getComponent(getItemAtPath, cache, comp.id, pagePointer) : null
    },

    getParent(getItemAtPath, cache, pointer) {
        const path = cache.getPath(pointer)
        if (!path || isPagePath(path, cache.flat)) {
            return null
        }
        if (cache.flat) {
            const {parent} = getItemAtPath(path)
            return parent && cache.getPointer(parent, pointer.type, getFlatComponentPath(path[1], pointer.type, parent))
        }

        const parentPath = _.dropRight(path, 2)
        const parent = getItemAtPath(parentPath)
        return parent && cache.getPointer(parent.id, pointer.type, parentPath)
    },

    getSiblings(getItemAtPath, cache, pointer) {
        const parent = this.getParent(getItemAtPath, cache, pointer)
        if (!parent) {
            return []
        }
        const siblings = this.getChildren(getItemAtPath, cache, parent)
        _.remove(siblings, {id: pointer.id})
        return siblings
    },

    getComponent(getItemAtPath, cache, id, pagePointer) {
        const mode = pagePointer.type
        id = cache.resolveId(id, mode)
        let pointer = cache.getPointer(id, mode)
        if (!pointer) {
            const pagePath = cache.getPath(pagePointer)
            const flatComponentPath = getFlatComponentPath(pagePointer.id, mode, id)
            const compInPage = cache.flat ? getItemAtPath(flatComponentPath) : jsonItemsFinders.getComponentPath(getItemAtPath(pagePath), id, mode)
            if (compInPage) {
                pointer = cache.getPointer(id, mode, cache.flat ? flatComponentPath : pagePath.concat(compInPage))
            }
        }
        return pointer
    },

    getMasterPage(getItemAtPath, cache, viewMode) {
        return this.getPage(getItemAtPath, cache, masterPageId, viewMode)
    },

    getPage(getItemAtPath, cache, id, viewMode) {
        const mode = viewMode
        let pointer = cache.getPointer(id, mode)
        if (!pointer) {
            const path = cache.flat ? getFlatPagePath(id, viewMode) : getPagePath(id)
            const page = getItemAtPath(path)
            if (!page) {
                return null
            }
            pointer = cache.getPointer(id, mode, path)
        }
        return pointer
    },

    getNewPage(getItemAtPath, cache, id, viewMode) {
        const mode = viewMode || constants.VIEW_MODES.DESKTOP
        const path = cache.flat ? getFlatPagePath(id, mode) : getPagePath(id)
        const page = getItemAtPath(path)
        if (page) {
            throw new Error(`there is already a page with id ${id}`)
        }
        return cache.getPointer(id, mode, path)
    },

    getLandingPageComponents(getItemAtPath, cache, viewMode) {
        const masterPagePointer = this.getMasterPage(getItemAtPath, cache, viewMode)
        return _(constants.LANDING_PAGES_COMP_IDS)
            .map(compId => this.getComponent(getItemAtPath, cache, compId, masterPagePointer))
            .compact()
            .value()
    },

    getPagesContainer(getItemAtPath, cache, viewMode) {
        const master = this.getMasterPage(getItemAtPath, cache, viewMode)
        return this.getComponent(getItemAtPath, cache, constants.COMP_IDS.PAGES_CONTAINER, master)
    },

    getFooter(getItemAtPath, cache, viewMode) {
        const master = this.getMasterPage(getItemAtPath, cache, viewMode)
        return this.getComponent(getItemAtPath, cache, constants.COMP_IDS.FOOTER, master)
    },

    getHeader(getItemAtPath, cache, viewMode) {
        const master = this.getMasterPage(getItemAtPath, cache, viewMode)
        return this.getComponent(getItemAtPath, cache, constants.COMP_IDS.HEADER, master)
    },

    getUnattached(getItemAtPath, cache, id, viewMode) {
        return cache.getPointer(id, viewMode, [])
    },

    getPageOfComponent(getItemAtPath, cache, compPointer) {
        const compPath = cache.getPath(compPointer)
        if (!compPath) {
            return null
        }

        const pageId = compPath[1]
        return this.getPage(getItemAtPath, cache, pageId, compPointer.type)
    },

    isDescendant(getItemAtPath, cache, compPointer, possibleAncestorPointer) {
        return !!this.getAncestorByPredicate(getItemAtPath, cache, compPointer, ancestorPointer => _.isEqual(ancestorPointer, possibleAncestorPointer))
    },

    getAllDisplayedOnlyComponents(getItemAtPath, cache, compPointer) {
        return cache.getAllPointers(compPointer)
    },

    registerDisplayedOnlyComponent(getItemAtPath, cache, originalCompId, displayedCompId) {
        cache.registerDisplayedOnlyComponent(originalCompId, displayedCompId)
    },

    clearDisplayedOnlyComponents(getItemAtPath, cache, originalCompId) {
        cache.clearDisplayedOnlyComponents(originalCompId)
    },

    getAncestorByPredicate(getItemAtPath, cache, compPointer, predicate) {
        let ancestorPointer = this.getParent(getItemAtPath, cache, compPointer)
        while (ancestorPointer && !predicate(ancestorPointer)) {
            ancestorPointer = this.getParent(getItemAtPath, cache, ancestorPointer)
        }

        return ancestorPointer
    },

    getAncestors(getItemAtPath, cache, compPointer) {
        const ancestors = []
        let ancestorPointer = this.getParent(getItemAtPath, cache, compPointer)
        while (ancestorPointer) {
            ancestors.push(ancestorPointer)
            ancestorPointer = this.getParent(getItemAtPath, cache, ancestorPointer)
        }

        return ancestors
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator('components', getterFunctions, true)
