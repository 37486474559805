import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import type {PublicMethodDefinition, PublicMethodUtils, ViewerLibrary} from './publicMethods'
import santaCoreUtils from '@wix/santa-core-utils'

export interface Deprecation {
    wrapDeprecatedFunction(privateServices: PS, methodDef: PublicMethodDefinition, methodName?: string): PublicMethodDefinition
    warn(ps: PS, methodDef: PublicMethodDefinition, methodName: string): void
    setThrowOnDeprecation(ps: PS, newVal: boolean): void
}

export function deprecationUtil(viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils): Deprecation {
    const bi = viewerLibrary.get('documentServices/bi/bi')
    const biEvents = viewerLibrary.get('biEventTypes')

    const {isDeprecated, wrap} = publicMethodUtils

    const wrapDeprecated = (ps: PS, methodDef: PublicMethodDefinition, methodName: string) =>
        wrap(methodDef, (next: Function) => {
            warn(ps, methodDef, methodName)
            return next()
        })

    function reportBI(ps: PS, methodName: string) {
        const dsOrigin = ps.config.origin
        const params = {
            methodName,
            dsOrigin
        }
        bi.event(ps, biEvents.DEPRECATED_PUBLIC_API_CALL, params)
    }

    /**
     * @param {ps} ps
     * @param methodDef
     * @param methodName
     */
    function warn(ps: PS, methodDef: PublicMethodDefinition, methodName: string) {
        const deprecationMessage = `${methodDef.deprecationMessage}|${methodName}`
        if (ps.runtimeConfig.shouldThrowOnDeprecation) {
            throw new Error(`DocumentServices|Deprecation|${deprecationMessage}`)
        }
        reportBI(ps, methodName)
        santaCoreUtils.log.warnDeprecation(deprecationMessage)
    }

    return {
        wrapDeprecatedFunction(privateServices: PS, methodDef: PublicMethodDefinition, methodName: string) {
            if (isDeprecated(methodDef)) {
                return wrapDeprecated(privateServices, methodDef, methodName)
            }
            return methodDef
        },
        warn,
        setThrowOnDeprecation(ps: PS, newVal: boolean) {
            _.set(ps, ['runtimeConfig', 'shouldThrowOnDeprecation'], newVal)
        }
    }
}
