define(['lodash', 'documentServices/componentsMetaData/metaDataUtils', 'documentServices/component/componentStructureInfo'], function (
    _,
    metaDataUtils,
    componentStructureInfo
) {
    'use strict'

    return {
        isRepeatable: false,
        styleCanBeApplied: false,
        layoutLimits(ps, compPointer) {
            const measureMap = ps.siteAPI.getSiteMeasureMap()
            const customMeasure = _.get(measureMap, ['custom', compPointer.id], {})

            return {
                minHeight: customMeasure.minHeight,
                minWidth: customMeasure.minWidth
            }
        },
        containableByStructure: (ps, comp, containerPointer) => {
            if (containerPointer) {
                const isPage = componentStructureInfo.isPageComponent(ps, containerPointer)
                return isPage && metaDataUtils.notContainableByPopup(ps, comp, containerPointer)
            }
            return false
        },
        mobileConversionConfig: {
            desktopOnly: true
        }
    }
})
