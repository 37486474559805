define(['documentServices/utils/contextAdapter', '@wix/santa-core-utils'], (contextAdapter, santaCoreUtils) => ({
    SAVE: 'ds_save',
    FIRST_SAVE: 'ds_first_save',
    SERVER_SAVE: 'ds_serverSave',
    FULL_PARTIAL_SAVE: 'ds_fullPartial_save',
    BUILD_PARTIAL_PAYLOAD: 'ds_partialSave_buildPayload',
    start: (interaction, tags) => {
        contextAdapter.utils.fedopsLogger.interactionStarted(interaction, {
            tags,
            paramsOverrides: {
                sourceOfStart: tags?.sourceOfStart
            }
        })
    },
    end: (interaction, tags) =>
        contextAdapter.utils.fedopsLogger.interactionEnded(interaction, {
            tags,
            paramsOverrides: {
                sourceOfStart: tags?.sourceOfStart
            }
        }),
    error: (e, extras = {}, tags = {}) => {
        if (e.message === 'invalid CSRF Token') {
            extras.xsrf = santaCoreUtils.cookieUtils.getCookie('XSRF-TOKEN')
        }
        contextAdapter.utils.fedopsLogger.captureError(e, {
            tags: {
                saveError: true,
                ...tags
            },
            extras
        })
    }
}))
