define([
    'lodash',
    'documentServices/component/component',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/appControllerData/appControllerData'
], function (_, component, componentsMetaData, appControllerData) {
    'use strict'

    function getWidgetRootMetaData(ps, compRef, metaDataFn) {
        const [widgetRootRef] = component.getChildren(ps, compRef)
        return metaDataFn(ps, widgetRootRef)
    }

    function getFromStageData(ps, stageDataKey, compRef) {
        return _.get(appControllerData.getControllerStageDataByControllerRef(ps, compRef), ['behavior', stageDataKey])
    }

    function rotatable(ps, metaDataValue, compRef) {
        return getWidgetRootMetaData(ps, compRef, componentsMetaData.public.isRotatable) && getFromStageData(ps, 'rotatable', compRef) !== false
    }

    function resizableSides(ps, metaDataValue, compRef) {
        return _.intersection(
            getWidgetRootMetaData(ps, compRef, componentsMetaData.public.getResizableSides),
            getFromStageData(ps, 'resizable', compRef) === false ? [] : metaDataValue
        )
    }

    const calcMinMax = (mathFn, key, appWidgetControllerLayoutLimits, widgetRootLayoutLimits) => {
        const definedValues = [appWidgetControllerLayoutLimits?.[key], widgetRootLayoutLimits?.[key]].filter(value => !_.isUndefined(value))
        if (definedValues.length) {
            return mathFn(...definedValues)
        }
    }

    const getAppWidgetLayoutLimit = (appWidgetControllerLayoutLimits, widgetRootLayoutLimits) => {
        if (_.isEmpty(appWidgetControllerLayoutLimits)) {
            return widgetRootLayoutLimits
        }

        return _.omitBy(
            {
                aspectRatio: widgetRootLayoutLimits?.aspectRatio,
                maxWidth: calcMinMax(Math.min, 'maxWidth', widgetRootLayoutLimits, appWidgetControllerLayoutLimits),
                minWidth: calcMinMax(Math.max, 'minWidth', widgetRootLayoutLimits, appWidgetControllerLayoutLimits),
                maxHeight: calcMinMax(Math.min, 'maxHeight', widgetRootLayoutLimits, appWidgetControllerLayoutLimits),
                minHeight: calcMinMax(Math.max, 'minHeight', widgetRootLayoutLimits, appWidgetControllerLayoutLimits)
            },
            _.isUndefined
        )
    }
    /**
     * @param {ps} ps
     * @param metaDataValue
     * @param compRef
     * @returns {*}
     */
    function layoutLimits(ps, metaDataValue, compRef) {
        const widgetRootLayoutLimits = getWidgetRootMetaData(ps, compRef, componentsMetaData.public.getLayoutLimits)
        const appWidgetLayoutLimits = getAppWidgetLayoutLimit(metaDataValue, widgetRootLayoutLimits)
        const manifestPreventsResize = getFromStageData(ps, 'resizable', compRef) === false
        if (manifestPreventsResize) {
            delete appWidgetLayoutLimits.aspectRatio
        }
        return appWidgetLayoutLimits
    }

    /**
     * @param {ps} ps
     * @param metaDataValue
     * @param compRef
     * @param {Pointer} potentialContainerPointer
     * @returns {boolean|*}
     */
    function containable(ps, metaDataValue, compRef, potentialContainerPointer) {
        if (metaDataValue === false) {
            return false
        }
        const parentRef = component.getContainer(ps, compRef)
        if (ps.pointers.isSamePointer(parentRef, potentialContainerPointer)) {
            return true
        }
        if (component.getType(ps, potentialContainerPointer) === 'platform.components.AppWidget') {
            return componentsMetaData.public.isContainable(ps, compRef, _.head(component.getChildren(ps, potentialContainerPointer)))
        }
        return component.getType(ps, parentRef) !== 'platform.components.AppWidget'
    }

    return {
        rotatable,
        resizableSides,
        layoutLimits,
        containable
    }
})
