define([
    'lodash',
    '@wix/santa-ds-libs/src/coreUtils',
    'experiment',
    'documentServices/tpa/utils/ProvisionUrlBuilder',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/editorServerFacade/editorServerFacade',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/tpa/services/pendingAppsService',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/utils/provisionUtils',
    'documentServices/platform/common/constants',
    'documentServices/saveAPI/lib/saveRunner',
    'documentServices/utils/visitableData',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    'documentServices/platform/services/platformStateService'
], function (
    _,
    coreUtils,
    experiment,
    ProvisionUrlBuilder,
    clientSpecMapService,
    metaData,
    clientSpecMapMetaData,
    editorServerFacade,
    tpaUtils,
    pendingAppsService,
    installedTpaAppsOnSiteService,
    provisionUtils,
    constants,
    saveRunner,
    visitableData,
    extensionsAPI,
    contextAdapter,
    dsConstants,
    platformStateService
) {
    'use strict'

    //site not save support multiple apps if needed
    const preSaveAddAppsInTemplate = _.template('<%= provisionServerBaseUrl %>/editor/pre-save-add-apps/template/<%= metaSiteId %>?')

    //user site - before save
    const preSaveAddAppInUserSite = _.template('<%= provisionServerBaseUrl %>/editor/pre-save-add-app/<%= metaSiteId %>?appDefId=<%= appDefinitionId %>')

    const settleTemplate = _.template(
        '<%= provisionServerBaseUrl %>/editor/settle/<%= metaSiteId %>?context=<%= context %>&editorSessionId=<%= editorSessionId %>'
    )

    const provisionAppFromSourceTemplateUrlTemplate = _.template(
        '<%= provisionServerBaseUrl %>/editor/provision-from-source-template/<%= metaSiteId %>?appDefId=<%= appDefinitionId %>&sourceTemplateId=<%= sourceTemplateId %>'
    )

    const context = {
        save: 'save',
        load: 'load',
        firstSave: 'firstSave'
    }

    const handleServerResponse = function (ps, response, onSuccess) {
        const newAppsData = response.payload
        _.forEach(newAppsData, function (newAppData) {
            onProvisionComplete(ps, newAppData)
            if (onSuccess) {
                onSuccess(newAppData)
            }
        })
    }

    const preSaveAddApp = function (ps, appDefinitionId, onSuccess, onError, optionalAppVersion, firstLoad) {
        if (experiment.isOpen('dm_useProvisionApi')) {
            return onError('preSaveAddApp should not have been called since dm_useProvisionApi is open')
        }

        if (optionalAppVersion) {
            ps.dal.set(ps.pointers.platform.getSemanticAppVersionPointer(appDefinitionId), optionalAppVersion)
        }

        if (!tpaUtils.isSiteSaved(ps)) {
            const onComplete = function (response) {
                if (response && response.success) {
                    handleServerResponse(ps, response, onSuccess)
                } else {
                    //TODO - report BI?
                    onError(response)
                }
            }

            preSaveAddApps(ps, [appDefinitionId], onComplete, onError)
        } else {
            const metaSiteId = metaData.getProperty(ps, metaData.PROPERTY_NAMES.META_SITE_ID)
            const siteRevision = metaData.getProperty(ps, metaData.PROPERTY_NAMES.SITE_REVISION)
            const baseUrl = buildProvisionUrl(ps, preSaveAddAppInUserSite, appDefinitionId, metaSiteId)

            const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().addAppVersionAndSiteRevision(optionalAppVersion, String(siteRevision)).build()
            if (firstLoad) {
                doRequest(
                    ps,
                    url,
                    'GET',
                    {},
                    response => {
                        onProvisionCallback(ps, onSuccess, response)
                    },
                    onError
                )
            } else {
                saveRunner.runFunctionInSaveQueue(
                    /** @returns {Promise<void>}*/ () =>
                        new Promise((resolve, reject) =>
                            doRequest(
                                ps,
                                url,
                                'GET',
                                {},
                                result => {
                                    onProvisionCallback(ps, onSuccess, result)
                                    resolve()
                                },
                                (...args) => {
                                    onError(...args)
                                    reject(...args)
                                }
                            )
                        )
                )
            }
        }
    }

    const provisionAppFromSourceTemplate = function (ps, appDefinitionId, sourceTemplateId, onSuccess, onError) {
        if (!tpaUtils.isSiteSaved(ps)) {
            onError({success: false})
        } else {
            provisionAppFromSourceTemplateImp(ps, appDefinitionId, sourceTemplateId, onSuccess, onError)
        }
    }

    const provisionAppFromSourceTemplateImp = function (ps, appDefinitionId, sourceTemplateId, onSuccess, onError) {
        const metaSiteId = metaData.getProperty(ps, metaData.PROPERTY_NAMES.META_SITE_ID)
        const pointer = ps.pointers.general.getServiceTopology()
        const provisionServerTopology = ps.dal.get(ps.pointers.getInnerPointer(pointer, 'appStoreUrl'))

        const baseUrl = provisionAppFromSourceTemplateUrlTemplate({
            provisionServerBaseUrl: provisionServerTopology,
            metaSiteId,
            appDefinitionId,
            sourceTemplateId
        })

        const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()

        doRequest(
            ps,
            url,
            'POST',
            {},
            response => {
                if (experiment.isOpen('dm_noLegacyCallbackForProvisionFromSourceTemplateId')) {
                    ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
                        if (tpaUtils.isTpaByAppType(response.payload.type)) {
                            provisionUtils.cacheAppMarketDataAfterProvision(ps, response.payload)
                        }
                    })
                    onSuccess(response.payload)
                } else {
                    onProvisionCallback(ps, onSuccess, response)
                }
            },
            onError
        )
    }

    /**
     * @param {ps} ps
     * @returns {function(): Promise<void>}
     */
    const preSaveAddAppsAsync = function (ps) {
        return /** @return Promise<void> */ () =>
            new Promise((resolve, reject) => {
                const appDefinitionIds = installedTpaAppsOnSiteService.getAppsDefIdToProvisionOnSiteLoad(ps)
                if (_.isEmpty(appDefinitionIds)) {
                    resolve()
                    return
                }
                preSaveAddApps(
                    ps,
                    appDefinitionIds,
                    result => {
                        const newAppsData = result.payload
                        _.forEach(newAppsData, newAppData => {
                            onProvisionComplete(ps, newAppData)
                        })

                        const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
                        const clientSpecMap = ps.dal.get(clientSpecMapPointer)
                        const updatedClientSpecMap = _.assign(
                            {},
                            clientSpecMap,
                            newAppsData.reduce(
                                (acc, current) => ({
                                    ...acc,
                                    [current.applicationId]: current
                                }),
                                {}
                            )
                        )
                        ps.dal.set(clientSpecMapPointer, updatedClientSpecMap)
                        _.attempt(ps.dal.commitTransaction)

                        resolve()
                    },
                    reject
                )
            })
    }

    const preSaveAddApps = function (ps, appDefinitionIds, onSuccess, onError) {
        if (experiment.isOpen('dm_useProvisionApi')) {
            return onError('preSaveAddApps should not have been called since dm_useProvisionApi is open')
        }

        const metaSiteId = metaData.getProperty(ps, metaData.PROPERTY_NAMES.META_SITE_ID)

        const pointer = ps.pointers.general.getServiceTopology()
        const provisionServerTopology = ps.dal.get(ps.pointers.getInnerPointer(pointer, 'appStoreUrl'))

        const baseUrl = preSaveAddAppsInTemplate({
            provisionServerBaseUrl: provisionServerTopology,
            metaSiteId
        })

        const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()

        const data = {
            guids: appDefinitionIds
        }

        doRequest(ps, url, 'POST', data, onSuccess, onError)
    }

    const preSaveAddAppsOnLoadForSavedSite = function (ps, appsDefIdToProvisionOnSaveSite) {
        if (experiment.isOpen('dm_useProvisionApi')) {
            throw new Error('preSaveAddAppsOnLoadForSavedSite should not have been called since dm_useProvisionApi is open')
        }

        const onComplete = function (appData) {
            if (appData) {
                appData.shouldTriggerFullSave = true
                clientSpecMapService.registerAppData(ps, appData)
                _.attempt(ps.dal.commitTransaction)
            }
        }
        const onError = function () {
            //TODO - report BI?
        }

        _.forEach(appsDefIdToProvisionOnSaveSite, function (appDefId) {
            preSaveAddApp(ps, appDefId, onComplete, onError, null, true)
        })
    }

    const preSaveAddAppsOnLoad = function (ps) {
        const appsDefIdToProvisionOnSaveSite = installedTpaAppsOnSiteService.getAppsDefIdToProvisionOnSiteLoad(ps)
        if (!_.isEmpty(appsDefIdToProvisionOnSaveSite)) {
            if (tpaUtils.isSiteSaved(ps)) {
                //data fixer for a bug where provision on load apps were not provisioned
                if (!experiment.isOpen('ds_removePreSaveAppDataFixerOnLoad')) {
                    preSaveAddAppsOnLoadForSavedSite(ps, appsDefIdToProvisionOnSaveSite)
                }
            }
        }
    }

    const getSettleActionsForFullSave = (
        {currentPagesVisitable, clientSpecMap, routersConfigMap, semanticAppVersions, appsState},
        shouldAvoidRevoking = false
    ) => {
        const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
        const actions = getSettleActionsFromAllPages(
            currentPagesVisitable,
            filteredClientSpecMap,
            routersConfigMap,
            semanticAppVersions,
            appsState,
            shouldAvoidRevoking
        )
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS, getInteractionsParams(actions))
        return actions
    }

    const getProvisionAction = (clientSpecMap, appDefinitionId, version) => {
        const existingApp = _.find(clientSpecMap, {appDefinitionId})

        return {
            appDefId: appDefinitionId,
            applicationId: (existingApp && existingApp.applicationId) || getNewApplicationIdStartingFrom1k(clientSpecMap),
            appVersion: version,
            type: 'provision'
        }
    }

    const getProvisionActions = (clientSpecMap, apps /** {appDefinitionId, version(optional)} */) =>
        apps.map(app => getProvisionAction(clientSpecMap, app.appDefinitionId, app.version))

    const postSettleActions = (ps, data) => {
        if (!_.isNil(ps)) {
            extensionsAPI.rendererModel.askRemoteEditorsToRefreshClientSpecMap(ps)

            if (data && data.actions) {
                _.forEach(data.actions, ({appDefId, type}) => {
                    platformStateService.clearAppPendingAction(ps, appDefId)

                    if (type === 'provision' || type === 'add') {
                        platformStateService.setAppAsUsed(ps, appDefId)
                    }
                })
            }
        }
    }

    const makeSettleRequestWithPS = (ps, urlData, data, saveContext, onSuccess, onError) => {
        if (experiment.isOpen('dm_settleThroughProxy')) {
            editorServerFacade.sendWithPs(
                ps,
                editorServerFacade.ENDPOINTS.SETTLE,
                data,
                response => {
                    postSettleActions(ps, data)
                    onSuccess({...response, payload: {clientSpecMap: response.payload}})
                },
                error => onError(error)
            )
        } else {
            const url = buildBaseUrl(urlData, saveContext)

            doRequest(ps, url, 'POST', data, onSuccess, onError)
        }
    }

    const makeSettleRequestWithPSAsync = (ps, urlData, data, saveContext) =>
        new Promise((resolve, reject) => {
            makeSettleRequestWithPS(ps, urlData, data, saveContext, resolve, reject)
        })

    const queue = fn => {
        let lastPromise = Promise.resolve()

        return (...args) => {
            lastPromise = lastPromise
                .catch(() => {}) // eslint-disable-line promise/prefer-await-to-then
                .then(() => fn(...args)) // eslint-disable-line promise/prefer-await-to-then

            return lastPromise
        }
    }

    const queueMakingSettleRequestWithPSAsync = queue(makeSettleRequestWithPSAsync)

    const makeSettleRequestWithImmutable = (currentImmutable, data, onSuccess, onError) => {
        editorServerFacade.sendWithImmutable(
            currentImmutable,
            editorServerFacade.ENDPOINTS.SETTLE,
            data,
            response => {
                onSuccess({...response, payload: {clientSpecMap: response.payload}})
            },
            error => onError(error)
        )
    }

    const makeSettleRequestWithImmutableSnapshot = (immutableSnapshot, data, onSuccess, onError) => {
        editorServerFacade.sendWithSnapshotDal(
            immutableSnapshot,
            editorServerFacade.ENDPOINTS.SETTLE,
            data,
            response => {
                onSuccess({...response, payload: {clientSpecMap: response.payload}})
            },
            error => onError(error)
        )
    }

    /**
     * @param {ps} ps
     * @param {{applicationId: string, version: string?}[]} apps
     * @param onSuccess
     * @param onError
     */
    const provision = (ps, apps, onSuccess, onError) => {
        if (_.isEmpty(apps)) {
            onError('provision must be called with at least one app')
            return
        }

        const branchId = extensionsAPI.documentServicesModel.getBranchId(ps)
        const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)
        const actions = getProvisionActions(clientSpecMap, apps)
        const data = _.omitBy(
            {
                actions,
                siteRevision: extensionsAPI.documentServicesModel.getRevision(ps),
                maybeBranchId: branchId
            },
            _.isNil
        )

        const urlData = {
            appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
            metaSiteId: ps.dal.get(ps.pointers.general.getMetaSiteId()),
            editorSessionId: ps.siteAPI.getEditorSessionId()
        }

        queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave)
            .then(response => {
                if (_.get(response, 'success')) {
                    // @ts-ignore
                    onSuccess(response.payload.clientSpecMap)
                } else {
                    onError(response)
                }
            })
            .catch(onError)
    }

    /**
     * @param {ps} ps
     * @param {{applicationId: string, appVersion: string}[]} apps
     * @return {Promise<*>}
     */
    const update = async (ps, apps) => {
        if (_.isEmpty(apps)) {
            throw new Error('update must be called with at least one app')
        }

        const branchId = extensionsAPI.documentServicesModel.getBranchId(ps)
        const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)

        const data = _.omitBy(
            {
                actions: apps.map(app => {
                    const appData = _.find(clientSpecMap, {applicationId: app.applicationId})

                    return {
                        type: 'update',
                        appDefId: appData.appDefinitionId,
                        applicationId: appData.applicationId,
                        instanceId: appData.instanceId,
                        appVersion: app.appVersion
                    }
                }),
                siteRevision: extensionsAPI.documentServicesModel.getRevision(ps),
                maybeBranchId: branchId
            },
            _.isNil
        )

        const urlData = {
            appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
            metaSiteId: ps.dal.get(ps.pointers.general.getMetaSiteId()),
            editorSessionId: ps.siteAPI.getEditorSessionId()
        }

        return queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave).then(response => {
            if (_.get(response, 'success')) {
                // @ts-ignore
                return response.payload.clientSpecMap
            }

            throw Promise.reject(response)
        })
    }

    const settleOnFirstSave = function (appServiceData, revision, urlData, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForFullSave(appServiceData, false),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        // eslint-disable-next-line promise/prefer-await-to-then
        queueMakingSettleRequestWithPSAsync(null, urlData, data, context.firstSave).then(onSuccess, onError)
    }

    const settleOnFirstSaveWithImmutable = function (currentImmutable, appServiceData, revision, urlData, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForFullSave(appServiceData, false),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        makeSettleRequestWithImmutable(currentImmutable, data, onSuccess, onError)
    }

    const settleOnFirstSaveWithImmutableSnapshot = function (currentImmutableSnapshot, appServiceData, revision, urlData, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForFullSave(appServiceData, false),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
    }

    const getInteractionsParams = actions => ({paramsOverrides: {actions: _.map(actions, _.partialRight(_.pick, ['appDefId', 'type']))}})

    const getSettleActionsForSave = (
        {
            clientSpecMap,
            isMasterPageUpdated,
            updatedPagesVisitable,
            currentPagesVisitable,
            lastPagesVisitable,
            deletedPagesVisitable,
            semanticAppVerions,
            routerConfigMap,
            appsState
        },
        shouldAvoidRevoking
    ) => {
        const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
        const isPlatformOnlyAppUninstalled = installedTpaAppsOnSiteService.isPlatformOnlyAppUninstalled(
            updatedPagesVisitable,
            clientSpecMap,
            routerConfigMap,
            appsState
        )
        const shouldTriggerFullSave = _.some(clientSpecMap, {shouldTriggerFullSave: true})
        const wereTpaCompsUninstalled = installedTpaAppsOnSiteService.areTpaCompsWereUnInstalled(
            lastPagesVisitable,
            updatedPagesVisitable,
            deletedPagesVisitable,
            clientSpecMap,
            isMasterPageUpdated,
            appsState
        )
        const shouldGetDataFromAllPages = wereTpaCompsUninstalled || shouldTriggerFullSave || isPlatformOnlyAppUninstalled
        const actions = shouldGetDataFromAllPages
            ? getSettleActionsFromAllPages(currentPagesVisitable, filteredClientSpecMap, routerConfigMap, semanticAppVerions, appsState, shouldAvoidRevoking)
            : getSettleActionsFromUpdatedPages(updatedPagesVisitable, filteredClientSpecMap, routerConfigMap, semanticAppVerions, appsState)
        contextAdapter.utils.fedopsLogger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS, getInteractionsParams(actions))
        return actions
    }

    const settleOnSave = function (appServiceData, revision, urlData, shouldAvoidRevoking, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        if (!_.isEmpty(data.actions)) {
            // eslint-disable-next-line promise/prefer-await-to-then
            queueMakingSettleRequestWithPSAsync(null, urlData, data, context.save).then(onSuccess, onError)
        } else {
            onSuccess()
        }
    }

    const settleOnSaveWithImmutable = function (currentImmutable, appServiceData, revision, urlData, shouldAvoidRevoking, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        if (!_.isEmpty(data.actions)) {
            makeSettleRequestWithImmutable(currentImmutable, data, onSuccess, onError)
        } else {
            onSuccess()
        }
    }

    const settleOnSaveWithImmutableSnapshot = function (currentImmutableSnapshot, appServiceData, revision, urlData, shouldAvoidRevoking, onSuccess, onError) {
        const {branchId} = appServiceData

        const data = _.omitBy(
            {
                actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
                siteRevision: revision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        if (!_.isEmpty(data.actions)) {
            makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
        } else {
            onSuccess()
        }
    }

    const getProvisionActionsForAllPages = (clientSpecMap, semanticAppVersions = {}) => {
        if (!experiment.isOpen('dm_useProvisionApi')) {
            return []
        }

        return getProvisionActions(
            clientSpecMap,
            _.map(_.values(installedTpaAppsOnSiteService.getAppsToProvision(clientSpecMap)), ({appDefinitionId}) => ({
                appDefinitionId,
                version: semanticAppVersions[appDefinitionId]
            }))
        )
    }

    const getAppsToAddFromUpdatedPages = (appsToGrant, appIdsInstalledOnUpdatedPages, clientSpecMap, semanticAppVersions = {}, appsToProvision = []) =>
        _(appsToGrant)
            .filter(
                appData =>
                    _.includes(appIdsInstalledOnUpdatedPages, _.toString(appData.applicationId)) ||
                    clientSpecMapService.isDemoAppAfterProvision(appData) ||
                    clientSpecMapService.hasEditorPlatformPart(appData)
            )
            .reject(({appDefinitionId}) => _.some(appsToProvision, {appDefId: appDefinitionId}))
            .map(({appDefinitionId, applicationId, instanceId, version}) =>
                _.omitBy(
                    {
                        type: 'add',
                        appDefId: appDefinitionId,
                        applicationId: _.toString(applicationId),
                        instanceId,
                        appVersion: semanticAppVersions[appDefinitionId] || version
                    },
                    _.isUndefined
                )
            )
            .value()

    const getSettleActionsFromUpdatedPages = (updatedPagesVisitable, clientSpecMap, routerConfigMap, semanticAppVerions, appsState) => {
        const installedAppIds = installedTpaAppsOnSiteService.getAllAppIdsInstalledOnPages(updatedPagesVisitable, clientSpecMap, routerConfigMap)
        const appsToGrant = _.concat(
            _.filter(clientSpecMap, 'notProvisioned'),
            pendingAppsService.getAppsToAdd(),
            installedTpaAppsOnSiteService.getAppsToGrantPermissions(clientSpecMap, installedAppIds, appsState)
        )
        const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVerions)
        const appsToAdd = getAppsToAddFromUpdatedPages(appsToGrant, installedAppIds, clientSpecMap, semanticAppVerions, appsToProvision)
        const appsToDismiss = getAppsToDismiss()
        const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, null, appsState)

        return _.concat(appsToAdd, appsToProvision, appsToDismiss, appsToUpdate)
    }

    const settleOnLoad = function (ps, shouldAvoidRevoking, callback) {
        const onSuccess = function (response) {
            if (response && response.success) {
                _.forEach(response.payload.clientSpecMap, appData => clientSpecMapService.registerAppData(ps, appData))
            }
            if (callback) {
                callback()
            }
        }

        const visitableDataAllPages = visitableData.createFromPrivateServices(ps)
        const clientSpecMap = clientSpecMapService.filterApps(ps.dal.get(ps.pointers.general.getClientSpecMap()))
        const routerConfigMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
        const siteRevision = extensionsAPI.documentServicesModel.getRevision(ps)
        const urlData = {
            appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
            metaSiteId: ps.dal.get(ps.pointers.general.getMetaSiteId()),
            editorSessionId: ps.siteAPI.getEditorSessionId()
        }
        settleImplOnLoad(ps, visitableDataAllPages, clientSpecMap, routerConfigMap, siteRevision, urlData, shouldAvoidRevoking, onSuccess)
    }

    const buildBaseUrl = function ({appStoreUrl, metaSiteId, editorSessionId}, settleContext) {
        const baseUrl = buildSettleUrl(appStoreUrl, settleTemplate, metaSiteId, editorSessionId, settleContext)

        return new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()
    }

    /**
     * @param clientSpecMap
     * @param {any[]} [appsToGrant]
     * @param {any[]|null} [appsToRevoke]
     * @param {any} [appsState]
     * @returns {*}
     */
    const getAppsToUpdate = (clientSpecMap, appsToGrant = [], appsToRevoke = [], appsState = {}) =>
        _(clientSpecMap)
            .filter(({applicationId, appDefinitionId}) => {
                const appState = _.get(appsState, appDefinitionId, {})
                return (
                    appState.pendingAction === constants.APP_ACTION_TYPES.UPDATE &&
                    !_.some(appsToGrant, {applicationId}) &&
                    !_.some(appsToRevoke, {applicationId})
                )
            })
            .map(clientSpec => ({
                type: 'update',
                appDefId: clientSpec.appDefinitionId,
                applicationId: _.toString(clientSpec.applicationId),
                instanceId: clientSpec.instanceId,
                appVersion: clientSpec.version
            }))
            .value()

    const getAppsToAddFromAllPages = (appsToGrant, appsToRevoke, clientSpecMap, semanticAppVersions = {}, appsToProvision = []) =>
        _(appsToGrant)
            .reject(({applicationId, appDefinitionId}) => _.some(appsToRevoke, {applicationId}) || _.some(appsToProvision, {appDefId: appDefinitionId}))
            .map(({appDefinitionId, applicationId, instanceId, version}) =>
                _.omitBy(
                    {
                        type: 'add',
                        appDefId: appDefinitionId,
                        applicationId: _.toString(applicationId),
                        instanceId,
                        appVersion: semanticAppVersions[appDefinitionId] || version
                    },
                    _.isUndefined
                )
            )
            .value()

    const getAppsToRemove = appsToRevoke =>
        _.map(appsToRevoke, ({applicationId}) => ({
            type: 'remove',
            applicationId: _.toString(applicationId)
        }))

    const getAppsToDismiss = () =>
        _.map(pendingAppsService.getAppsToDismiss(), appDefId => ({
            type: 'dismiss',
            appDefId
        }))

    const settleImplOnLoad = (ps, visitableDataAllPages, clientSpecMap, routerConfigMap, siteRevision, urlData, shouldAvoidRevoking, callback) => {
        const branchId = extensionsAPI.documentServicesModel.getBranchId(ps)
        const {grant: appsToGrant, revoke: appsToRevoke} = installedTpaAppsOnSiteService.getAppsToGrantAndRevoke(
            clientSpecMap,
            visitableDataAllPages,
            {excludeHybrid: true, shouldAvoidRevoking},
            routerConfigMap,
            {},
            platformStateService.getAppsState(ps)
        )

        const appsToAdd = getAppsToAddFromAllPages(appsToGrant, appsToRevoke, clientSpecMap)
        const appsToRemove = getAppsToRemove(appsToRevoke)
        const appsToDismiss = getAppsToDismiss()

        const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, {})

        const data = _.omitBy(
            {
                actions: _.concat(appsToAdd, appsToRemove, appsToDismiss, appsToUpdate),
                siteRevision,
                maybeBranchId: branchId
            },
            _.isNil
        )

        if (!_.isEmpty(data.actions)) {
            queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.load)
                .then(callback)
                .catch(e => {
                    console.log(e)
                    callback()
                })
        } else {
            callback()
        }
    }

    const getSettleActionsFromAllPages = (currentPagesVisitable, clientSpecMap, routerConfigMap, semanticAppVersions, appsState, shouldAvoidRevoking) => {
        const appsToGrantAndRevoke = installedTpaAppsOnSiteService.getAppsToGrantAndRevoke(
            clientSpecMap,
            currentPagesVisitable,
            {shouldAvoidRevoking},
            routerConfigMap,
            appsState
        )
        const appsToGrant = _.concat(_.filter(clientSpecMap, 'notProvisioned'), pendingAppsService.getAppsToAdd(), appsToGrantAndRevoke.grant)
        const appsToRevoke = appsToGrantAndRevoke.revoke

        const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVersions)
        const appsToAdd = getAppsToAddFromAllPages(appsToGrant, appsToRevoke, clientSpecMap, semanticAppVersions, appsToProvision)
        const appsToRemove = getAppsToRemove(appsToRevoke)
        const appsToDismiss = getAppsToDismiss()
        const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, appsState)

        return _.concat(appsToAdd, appsToProvision, appsToRemove, appsToDismiss, appsToUpdate)
    }

    const onProvisionComplete = function (ps, resultAppDefinitionData) {
        const resultHasNoApplicationId = _.includes([undefined, -1], resultAppDefinitionData.applicationId)

        if (resultHasNoApplicationId) {
            const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)

            resultAppDefinitionData.applicationId = getNewApplicationIdStartingFrom1k(clientSpecMap)
            resultAppDefinitionData.notProvisioned = true
        } else {
            const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, resultAppDefinitionData.appDefinitionId)
            const isConvertedFromTemplate = existingAppData && existingAppData.demoMode && !resultAppDefinitionData.demoMode
            if (pendingAppsService.isPremiumPendingApp(ps, resultAppDefinitionData.applicationId)) {
                pendingAppsService.add(resultAppDefinitionData)
            } else if (isConvertedFromTemplate || !existingAppData) {
                resultAppDefinitionData.notProvisioned = true
            }
        }

        ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
            if (tpaUtils.isTpaByAppType(resultAppDefinitionData.type)) {
                provisionUtils.cacheAppMarketDataAfterProvision(ps, resultAppDefinitionData)
            }
        })
    }

    const onProvisionCallback = function (ps, onSuccess, result) {
        const resultAppDefinitionData = result.payload
        onProvisionComplete(ps, resultAppDefinitionData)
        onSuccess(resultAppDefinitionData)
    }

    const getNewApplicationIdStartingFrom1k = function (clientSpecMap) {
        const currentLargestId = clientSpecMapService.getLargestApplicationId(clientSpecMap)
        const newGeneratedApplicationId = provisionUtils.generateAppFlowsLargestAppId(currentLargestId)
        return newGeneratedApplicationId
    }

    const buildProvisionUrl = function (ps, template, appDefinitionId, metaSiteId) {
        const pointer = ps.pointers.general.getServiceTopology()
        const provisionServerTopology = ps.dal.get(ps.pointers.getInnerPointer(pointer, 'appStoreUrl'))

        return template({
            provisionServerBaseUrl: provisionServerTopology,
            metaSiteId,
            appDefinitionId
        })
    }

    const buildSettleUrl = function (provisionBaseUrl, template, metaSiteId, editorSessionId, settleContext) {
        return template({
            provisionServerBaseUrl: provisionBaseUrl,
            metaSiteId,
            editorSessionId,
            context: settleContext
        })
    }

    const doRequest = function (ps, url, type, data, onSuccess, onError) {
        const params = {
            type,
            url,
            dataType: 'json',
            contentType: 'application/json',
            success(response) {
                postSettleActions(ps, data)
                onSuccess(response)
            },
            error: onError
        }

        if (data && !_.isEmpty(data)) {
            params.data = JSON.stringify(data)
        }

        if (type === 'POST') {
            params.xhrFields = {
                withCredentials: true
            }
        }

        coreUtils.ajaxLibrary.ajax(params)
    }

    return {
        preSaveAddApp,
        provision,
        update,
        settleOnSave,
        settleOnSaveWithImmutable,
        settleOnSaveWithImmutableSnapshot,
        settleOnFirstSave,
        settleOnFirstSaveWithImmutable,
        settleOnFirstSaveWithImmutableSnapshot,
        getSettleActionsForFullSave,
        getSettleActionsForSave,
        settleOnLoad,
        preSaveAddAppsAsync,
        preSaveAddAppsOnLoad,
        provisionAppFromSourceTemplate
    }
})
