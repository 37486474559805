define(['lodash', 'documentServices/dataModel/dataModel', 'document-services-schemas'], function (_, dataModel, documentServicesSchemas) {
    'use strict'

    const {schemasService} = documentServicesSchemas.services

    //temporarily taken from componentValidations.js and slightly modified
    function compDataTypeFitsCompType(componentType, componentData) {
        const compDefinitions = schemasService.getDefinition(componentType)

        if (_.isArray(compDefinitions.dataTypes)) {
            if (_.isPlainObject(componentData)) {
                return componentData.type && _.includes(compDefinitions.dataTypes, componentData.type)
            } else if (_.isString(componentData)) {
                return _.includes(compDefinitions.dataTypes, componentData)
            }
            return _.includes(compDefinitions.dataTypes, '')
        }

        return !componentData
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     */
    function validateCompData(ps, compPointer) {
        const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
        const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
        const data = dataItemPointer && ps.dal.get(dataItemPointer)
        if (!compDataTypeFitsCompType(componentType, data)) {
            throw new Error(`Component data type [${data}` && `${data.type}] is not valid for component type [${componentType}]`)
        }
        if (data) {
            //if we don't have data, and no error was thrown, there's nothing else to validate
            documentServicesSchemas.services.dataValidators.validateDataBySchema(data, 'data')
        }
    }

    return {
        validateCompData
    }
})
