define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/variants/variantsUtils',
    'documentServices/variants/relationsUtils',
    'documentServices/dataModel/dataModel'
], function (_, constants, variantsUtils, relationsUtils, dataModel) {
    'use strict'

    const {DATA_TYPES} = constants

    const updateTransformationsData = (ps, componentVariantsPointer, transformation) => {
        if (_.isEmpty(transformation)) {
            console.warn('please pass non empty object, for full delete use transformations.remove api')
            return
        }
        const transformationsWithType = _.defaults({}, {type: 'TransformData'}, transformation)
        return variantsUtils.updateComponentDataConsideringVariants(ps, componentVariantsPointer, transformationsWithType, DATA_TYPES.transformations)
    }

    const getTransformationsData = (ps, componentVariantsPointer) =>
        variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transformations)

    const removeTransformationsData = (ps, componentVariantsPointer) =>
        variantsUtils.removeComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.transformations)

    const getAllHiddenComponentsByVariants = (ps, variantsPointers) => {
        const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
        const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointersArray))

        const hiddenTransformationPredicate = relation => {
            const scopedValueId = dataModel.variantRelation.extractTo(ps, relation)
            if (scopedValueId) {
                const scopedValuePointer = ps.pointers.data.getItem(DATA_TYPES.transformations, scopedValueId, pageId)
                const scopedValue = ps.dal.get(scopedValuePointer)
                return _.isMatch(scopedValue, {hidden: true})
            }

            return false
        }

        const relationsPointers = relationsUtils.getRelationsByVariantsAndPredicate(
            ps,
            variantsPointersArray,
            DATA_TYPES.transformations,
            true,
            hiddenTransformationPredicate
        )

        return _.map(relationsPointers, relationPointer => {
            const relation = ps.dal.get(relationPointer)
            return relationsUtils.getComponentFromRelation(ps, relation, pageId)
        })
    }

    const getAllHiddenComponentsByDefault = (ps, pagePointer) => {
        if (!pagePointer) {
            throw new Error('no page pointer provided')
        }

        const viewMode = ps.pointers.components.getViewMode(pagePointer)
        const comps = ps.pointers.components.getAllComponentPointers(viewMode, pagePointer.id)

        const nonScopedHiddenComps = comps
            .map(x => {
                if (_.get(getTransformationsData(ps, x), ['hidden'])) {
                    return x
                }
                return undefined
            })
            .filter(i => i)

        return nonScopedHiddenComps
    }

    const setPropsToIgnore = (ps, compPointers, ignoredTransformProps) => {
        const compPointersArray = _.isArray(compPointers) ? compPointers : [compPointers]
        const compIds = compPointersArray.map(compPointer => compPointer.id)
        return ps.siteAPI.setCompsIgnoredTransformProps(compIds, ignoredTransformProps)
    }

    const setGlobalPropsToIgnore = (ps, ignoredTransformProps) => ps.siteAPI.setGlobalIgnoredTransformProps(ignoredTransformProps)

    return {
        updateTransformationsData,
        getTransformationsData,
        removeTransformationsData,
        getAllHiddenComponentsByVariants,
        getAllHiddenComponentsByDefault,
        setPropsToIgnore,
        setGlobalPropsToIgnore
    }
})
