import type {CreateExtArgs, DAL, Extension, ExtensionAPI, Pointer} from '@wix/document-manager-core'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {METADATA_TYPES} from '../../constants/constants'
import {DEFAULTS} from './defaults'
import {metadataMap} from './metadataMap'
import type {Component} from '@wix/document-services-types'
const {getRepeaterTemplateId, isRepeatedComponent} = displayedOnlyStructureUtil

export interface ComponentsMetadataAPI extends ExtensionAPI {
    componentsMetadata: {
        canConnectToCode(componentPointer: Pointer): boolean
    }
}

const getCompOrDefaultMetaData = (compType: string, metadataKey: string) => {
    const metadataValue = _.get(metadataMap, [compType, metadataKey])
    return _.isUndefined(metadataValue) ? DEFAULTS[metadataKey] : metadataValue
}

const getComponent = (dal: DAL, componentPointer: Pointer): Component => {
    let component = dal.get(componentPointer)
    if (!component && isRepeatedComponent(componentPointer.id)) {
        const repeaterTemplateId = getRepeaterTemplateId(componentPointer.id)
        component = dal.get({id: repeaterTemplateId, type: componentPointer.type})
    }

    return component
}

const createExtension = (): Extension => {
    const canConnectToCode = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponent(dal, componentPointer)
        return getCompOrDefaultMetaData(componentType, METADATA_TYPES.CAN_CONNECT_TO_CODE)
    }

    const createExtensionAPI = ({dal}: CreateExtArgs): ComponentsMetadataAPI => {
        return {
            componentsMetadata: {
                canConnectToCode: (componentPointer: Pointer) => canConnectToCode(dal, componentPointer)
            }
        }
    }

    return {
        name: 'componentsMetadata',
        dependencies: new Set(),
        createExtensionAPI
    }
}

export {createExtension}
