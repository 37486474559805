define(['documentServices/constants/constants', 'documentServices/utils/utils'], function (consts, dsUtils) {
    'use strict'

    const HeightLayout = Object.freeze({
        MANUAL: 'manual',
        AUTO: 'auto'
    })

    return {
        defaultMobileProperties: {
            columnLayout: 'manual'
        },
        mobileConversionConfig: {
            fixedSize(ps, component) {
                return {height: component.layout.height}
            }
        },
        resizableSides(ps, compPointer) {
            const sides = [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT],
                compStructure = ps.dal.get(compPointer),
                page = ps.pointers.components.getPageOfComponent(compPointer),
                pageId = page && page.id,
                compPropsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(compStructure.propertyQuery), pageId),
                compProps = ps.dal.get(compPropsPointer)

            if (compProps.heightLayout !== HeightLayout.AUTO) {
                sides.push(consts.RESIZE_SIDES.TOP, consts.RESIZE_SIDES.BOTTOM)
            }
            return sides
        }
    }
})
