import _ from 'lodash'
import coreUtils from '../../../coreUtils'
import viewerComponentService from '@wix/wix-ui-santa/dist/statics/viewerComponentService.bundle.min'
import externalComponentsServices from './externalComponentsServices'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'

externalComponentsServices.register(viewerComponentService)

const {getPackagesForComponent, allPackages, packagesToCallbacks} = coreUtils

const _comps = {}
const STATUS = {
    PENDING: 'PENDING',
    LOADED: 'LOADED',
    FAILURE: 'FAILURE'
}

const storeData = {
    markComponentAsPending: componentType => {
        if (!_comps[componentType]) {
            _comps[componentType] = {status: STATUS.PENDING}
        }
    },

    isComponentLoaded: componentType => _comps[componentType] && _comps[componentType].status === STATUS.LOADED,

    markComponentAsLoaded: componentType => {
        _comps[componentType].status = STATUS.LOADED
    }
}

//const isBaseUIInSsr = componentType => typeof window === 'undefined' && componentType.indexOf('wixui.baseui.') === 0;

const viewerApiRegistrar = {
    register({santaComponent, componentType, component}, skinRegistrar, runningExperiments) {
        const componentToRegister = santaComponent || component
        if (componentToRegister) {
            componentsCore.compRegistrar.register(componentType, componentToRegister)
            const isExperimentOpen =
                componentToRegister.componentOverride &&
                runningExperiments &&
                runningExperiments[componentToRegister.componentOverride.viewerExperimentKey] === 'new'
            if (isExperimentOpen) {
                componentsCore.compRegistrar.invalidate(componentToRegister.componentOverride.overrideViewerType)
                componentsCore.compRegistrar.register(componentToRegister.componentOverride.overrideViewerType, componentToRegister)
            }
        }
        if (componentToRegister && componentToRegister.getComponentSkins) {
            skinRegistrar.addBatch(componentToRegister.getComponentSkins())
        }
    }
}

const loadAndRegisterExternalComponent = (componentType, skinRegistrar, wixUiSantaBaseUrl, runningExperiments) =>
    externalComponentsServices
        .load(componentType, wixUiSantaBaseUrl)
        .then(viewerBundles => viewerBundles.forEach(viewerBundle => viewerApiRegistrar.register(viewerBundle.default, skinRegistrar, runningExperiments)))

const loadAndRegisterAllExternalComponents = (skinRegistrar, wixUiSantaBaseUrl, runningExperiments) =>
    externalComponentsServices
        .loadAll(wixUiSantaBaseUrl)
        .then(components =>
            components.forEach(component =>
                component.forEach(viewerBundle => viewerApiRegistrar.register(viewerBundle.default, skinRegistrar, runningExperiments))
            )
        )

const loadAndRegisterAllInternalPackages = skinRegistrar => {
    const packageNames = allPackages()
    const packagesNamesWithCallbacks = packagesToCallbacks(packageNames)
    return new Promise<void>(resolve =>
        requirejs(packageNames, (...pkgs) => {
            packageNames.forEach((packageName, index) =>
                packagesNamesWithCallbacks[packageName](
                    pkgs[index],
                    componentsCore.compRegistrar.register,
                    componentsCore.siteAspectsRegistry.registerHostLibsAspect,
                    skinRegistrar.addBatch
                )
            )
            resolve()
        })
    )
}

const loadAndRegisterInternalPackages = (packageNames, skinRegistrar) => {
    const packagesNamesWithCallbacks = packagesToCallbacks(packageNames)
    return new Promise<void>(resolve =>
        requirejs(packageNames, (...pkgs) => {
            packageNames.forEach((packageName, index) =>
                packagesNamesWithCallbacks[packageName](
                    pkgs[index],
                    componentsCore.compRegistrar.register,
                    componentsCore.siteAspectsRegistry.registerHostLibsAspect,
                    skinRegistrar.addBatch
                )
            )
            resolve()
        })
    )
}

const getbaseUrl = () => `${requirejs.toUrl('wix-ui-santa')}/`

export default {
    isComponentLoaded: componentType => storeData.isComponentLoaded(componentType),

    exists: (componentType, siteData) => getPackagesForComponent(componentType, siteData).length > 0 || externalComponentsServices.exists(componentType),

    prefetchComponents: (componentTypes, siteData) => {
        const externalComponents = componentTypes.filter(externalComponentsServices.exists)
        const internalComponents = componentTypes.filter(componentType => !_.includes(externalComponents, componentType))

        externalComponents.forEach(componentType => externalComponentsServices.load(componentType, getbaseUrl()))

        const internalPackagesToPrefetch = _(internalComponents)
            .flatMap(compType => getPackagesForComponent(compType, siteData))
            .uniq()
            .value()
        if (internalPackagesToPrefetch.length > 0) {
            requirejs(internalPackagesToPrefetch, _.noop)
        }
    },

    loadAndRegisterAll: (isPreview, config) => {
        const runningExperiments = config ? config.runningExperiments : {}
        return new Promise(res => requirejs(['@wix/santa-ds-libs/src/skins'], res)).then((skins: any) =>
            Promise.all([
                loadAndRegisterAllExternalComponents(skins.skinsMap, getbaseUrl(), runningExperiments),
                loadAndRegisterAllInternalPackages(skins.skinsMap)
            ])
        )
    },

    loadAndRegister: (componentType, siteData) => {
        storeData.markComponentAsPending(componentType)
        return new Promise(res => requirejs(['@wix/santa-ds-libs/src/skins'], res))
            .then((skins: any) => {
                let runningExperiments = {}
                if (siteData && siteData.rendererModel) {
                    runningExperiments = siteData.rendererModel.runningExperiments
                }
                if (externalComponentsServices.exists(componentType)) {
                    return loadAndRegisterExternalComponent(componentType, skins.skinsMap, getbaseUrl(), runningExperiments)
                }
                const packageNames = getPackagesForComponent(componentType, siteData)
                return loadAndRegisterInternalPackages(packageNames, skins.skinsMap)
            })
            .then(() => {
                storeData.markComponentAsLoaded(componentType)
            })
    }
}
