define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/features/features',
    'documentServices/component/component',
    'documentServices/appStudio/nickname'
], function (_, constants, appStudioDataModel, features, component, nickname) {
    'use strict'

    const STAGE_DATA = ['gfpp', 'behavior', 'displayName']

    const hasLabel = button => !!button?.label

    const convertTranslatableLabelIfNeeded = (button, buttonPath, translationPathsAccumulator) => {
        if (hasLabel(button)) {
            if (button.label.shouldTranslate) {
                translationPathsAccumulator.push(`${buttonPath}.label`)
            }
            return {...button, label: button.label.value}
        }

        return button
    }

    const getTranslatedGfppData = (gfppData, basePath) => {
        const gfppTranslationPaths = []
        const translatedGfppData = {
            desktop: {}
        }
        if (gfppData.desktop?.mainAction1) {
            translatedGfppData.desktop.mainAction1 = convertTranslatableLabelIfNeeded(
                gfppData.desktop.mainAction1,
                `${basePath}.gfpp.desktop.mainAction1`,
                gfppTranslationPaths
            )
        }
        if (gfppData.desktop?.mainAction2) {
            translatedGfppData.desktop.mainAction2 = convertTranslatableLabelIfNeeded(
                gfppData.desktop.mainAction2,
                `${basePath}.gfpp.desktop.mainAction2`,
                gfppTranslationPaths
            )
        }
        if (gfppData.desktop?.iconButtons) {
            translatedGfppData.desktop.iconButtons = gfppData.desktop.iconButtons
        }
        return {translatedGfppData, gfppTranslationPaths}
    }

    const applyGfppToMobile = (translatedGfppData, gfppTranslationPaths) => {
        translatedGfppData.mobile = _.cloneDeep(translatedGfppData.desktop)
        const mobileTranslations = gfppTranslationPaths.map(path => path.replace('gfpp.desktop', 'gfpp.mobile'))
        return {gfppWithMobile: translatedGfppData, translationPathsWithMobile: gfppTranslationPaths.concat(mobileTranslations)}
    }

    const getWidgetStageDataWithTranslationPaths = (stageData, basePath) => {
        const translationPaths = []
        const newStageData = {}
        if (stageData.displayName) {
            if (stageData.displayName.shouldTranslate) {
                translationPaths.push(`${basePath}.displayName`)
            }
            newStageData.displayName = stageData.displayName.value
        }
        if (stageData.gfpp) {
            const {translatedGfppData, gfppTranslationPaths} = getTranslatedGfppData(stageData.gfpp, basePath)
            const {gfppWithMobile, translationPathsWithMobile} = applyGfppToMobile(translatedGfppData, gfppTranslationPaths)
            newStageData.gfpp = gfppWithMobile
            translationPaths.push(...translationPathsWithMobile)
        }
        if (stageData.behavior) {
            newStageData.behavior = stageData.behavior
        }
        return {
            stageData: newStageData,
            translationPaths
        }
    }

    const getInnerCompsStageData = (ps, rootAppWidgetRef) => {
        const translationPaths = []
        const widgetComps = component.getChildrenFromFull(ps, rootAppWidgetRef, true)
        const connections = _.reduce(
            widgetComps,
            (result, compRef) => {
                const manifestData = features.getFeatureData(ps, compRef, 'manifest')
                if (manifestData) {
                    const role = nickname.get(ps, compRef)
                    const {stageData, translationPaths: compTranslationPaths} = getWidgetStageDataWithTranslationPaths(
                        _.pick(manifestData, STAGE_DATA),
                        `default.connections.${role}`
                    )
                    result[role] = stageData
                    translationPaths.push(...compTranslationPaths)
                }
                return result
            },
            {}
        )
        return {
            connections,
            translationPaths
        }
    }

    const getWidgetManifest = (ps, widgetPointer) => {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)
        const pageRef = ps.pointers.components.getPage(_.replace(widgetData.rootCompId, '#', ''), constants.VIEW_MODES.DESKTOP)
        const rootAppWidgetRef = appStudioDataModel.getRootWidgetByPage(ps, pageRef)
        const manifestData = features.getFeatureData(ps, rootAppWidgetRef, 'manifest')
        const {stageData: widgetStageData, translationPaths: widgetTranslationPaths} = getWidgetStageDataWithTranslationPaths(
            _.pick(manifestData, STAGE_DATA),
            'default'
        )
        const {connections, translationPaths: connectionsTranslationPaths} = getInnerCompsStageData(ps, rootAppWidgetRef)
        if (!_.isEmpty(widgetStageData) || !_.isEmpty(connections)) {
            return {
                manifest: {
                    default: {
                        ...widgetStageData,
                        connections
                    }
                },
                translationPaths: [...widgetTranslationPaths, ...connectionsTranslationPaths]
            }
        }
    }

    return {
        getWidgetManifest
    }
})
