define(['lodash', 'experiment', 'documentServices/constants/constants'], function (_, experiment, constants) {
    'use strict'

    function getLayoutLimits(ps, repeaterPointer) {
        const itemPointer = _.head(ps.pointers.full.components.getChildren(repeaterPointer))
        const itemLayout = itemPointer && ps.dal.full.get(ps.pointers.getInnerPointer(itemPointer, ['layout']))
        return {
            minWidth: _.get(itemLayout, 'width')
        }
    }

    return {
        enforceContainerChildLimitsByWidth: false,
        layoutLimits: getLayoutLimits,
        canBeStretched: true,
        isRepeater: true,
        get isRepeatable() {
            return experiment.isOpen('dm_widgetInRepeater')
        },
        resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT],
        mobileConversionConfig: {
            filterChildrenWhenHidden: true,
            stretchHorizontally: true
        }
    }
})
