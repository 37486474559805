import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const TpaBaseUrlBuilder = function (baseUrl?) {
    this.url = coreUtils.urlUtils.parseUrl(baseUrl)
    this.url.query = this.url.query || {}
    this.url.search = null
    this.url.hash = this.url.hash || ''
}

TpaBaseUrlBuilder.prototype = {
    addQueryParam(param, value) {
        const {query} = this.url

        if (value && !_.isEmpty(value)) {
            if (!query[param]) {
                query[param] = value
            } else if (_.isArray(query[param])) {
                query[param].push(value)
            } else {
                query[param] = [query[param], value]
            }
        }

        return this
    },

    addMultipleQueryParams(queryParams) {
        if (queryParams && !_.isEmpty(queryParams)) {
            _.assign(this.url.query, queryParams)
        }
        return this
    },

    addQueryParamsFromFunc(funcThatAddsQueryParams, funcParams) {
        if (_.isFunction(funcThatAddsQueryParams)) {
            funcThatAddsQueryParams.apply(null, [this].concat(funcParams))
        }
        return this
    },

    setHash(hashValue) {
        if (_.isString(hashValue) && !_.isEmpty(hashValue)) {
            hashValue = hashValue.startsWith('#') ? hashValue : `#${hashValue}`
            this.url.hash = hashValue
        }
        return this
    },

    mutateIframeSrc(mutateIframeSrc) {
        if (mutateIframeSrc) {
            this.url = mutateIframeSrc(this.url)
        }

        return this
    },

    filterQueryParams(whiteList) {
        if (whiteList && !_.isEmpty(whiteList)) {
            this.url.query = _.pick(this.url.query, whiteList)
        }

        return this
    },

    build() {
        // @ts-ignore
        return coreUtils.urlUtils.buildFullUrl(this.url)
    }
}

export default TpaBaseUrlBuilder
