define(['wixCode'], function (wixCode) {
    'use strict'

    const {filePathAffectsBundles, filePathAffectsSingleBundle, bundleIdFromFilePath} = wixCode.wixCodeUserScriptsService

    function init(ps) {
        reset(ps)
    }

    function reset(ps) {
        clearBundleCacheKillerMap(ps)
        updateCacheKillerValue(ps, ps.pointers.wixCode.getGlobalBundleCacheKiller())
    }

    function notifyPathModified(ps, path) {
        if (filePathAffectsSingleBundle(path)) {
            const bundleId = bundleIdFromFilePath(path)
            const bundleCacheKillerPointer = ps.pointers.wixCode.getBundleCacheKiller(bundleId)
            updateCacheKillerValue(ps, bundleCacheKillerPointer)
        } else if (filePathAffectsBundles(path)) {
            reset(ps)
        }
    }

    function getNewCacheKillerValue(existingValue) {
        const suggestedValue = Date.now().toString()
        return suggestedValue === existingValue ? `${suggestedValue}_1` : suggestedValue
    }

    function updateCacheKillerValue(ps, pointer) {
        const currentVal = ps.dal.get(pointer)
        const newValue = getNewCacheKillerValue(currentVal)

        ps.dal.set(pointer, newValue)
    }

    function clearBundleCacheKillerMap(ps) {
        ps.dal.set(ps.pointers.wixCode.getBundleCacheKillerMap(), {})
    }

    return {
        init,
        getNewCacheKillerValue,
        notifyPathModified
    }
})
