import _ from 'lodash'
import PropTypes from 'prop-types'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import inputProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/inputProxy'
import localizer from '@wix/santa-ds-libs/src/wixappsCore/src/util/localizer'

function getCompProp() {
    return {
        placeholder: {
            text: localizer.localize(this.getCompProp('promptText'), this.props.viewProps.getLocalizationBundle()),
            value: -1
        },
        size: null
    }
}

/**
 * @class proxies.ComboBox
 * @extends proxies.mixins.inputProxy
 */
export default {
    mixins: [inputProxy],
    propTypes: {
        viewProps: PropTypes.shape({
            getLocalizationBundle: PropTypes.func.isRequired
        })
    },
    renderProxy() {
        const data = this.proxyData
        const self = this
        const componentType = 'wysiwyg.viewer.components.inputs.ComboBoxInput'
        const childProps = this.getChildCompProps(componentType)
        childProps.compProp = this.getCompProp('hasPrompt') && !_.some(data.items, {value: data.selectedValue}) ? getCompProp.call(this) : {}
        childProps.compData = typesConverter.selectableList(data)
        _.forEach(
            childProps.compData.options,
            function (item) {
                item.text = localizer.localize(item.text, this.props.viewProps.getLocalizationBundle())
                item.description = localizer.localize(item.description, this.props.viewProps.getLocalizationBundle())
            }.bind(this)
        )
        childProps.onSelectionChange = function onSelectionChange(event, domID) {
            event.payload.listData = data
            const selectedValue = event.payload.value || data.items[0].value
            self.setData(selectedValue, 'selectedValue')
            self.handleViewEvent(event, domID)
        }
        childProps.errorMessage = !data.valid

        return componentsCore.compRegistrar.getCompClass(componentType, true)(childProps)
    }
}
