import * as tpaComponents from '../../../_internal/tpaComponents'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {logger} = coreUtils.loggingUtils

export default {
    registerEventListener(siteAPI, msg) {
        siteAPI.getSiteAspect('tpaPubSubAspect').subscribe(msg)
    },

    publish(siteAPI, msg) {
        const msgData = msg.data
        const {compId} = msg
        const appDefId = tpaComponents.common.utils.getAppDefId(siteAPI, compId)

        if (appDefId) {
            msgData.eventKey = tpaComponents.common.utils.stripPubSubPrefix(msgData.eventKey)

            siteAPI.getSiteAspect('tpaPubSubAspect').publish(appDefId, compId, msgData)
        } else {
            logger.reportBI(siteAPI.getSiteData(), tpaComponents.bi.errors.SDK_PUBSUB_PUBLISH_ERROR)
        }
    },
    removeEventListener(siteAPI, msg) {
        const {compId} = msg
        const msgData = msg.data
        const appDefId = tpaComponents.common.utils.getAppDefId(siteAPI, compId)
        const event = tpaComponents.common.utils.stripPubSubPrefix(msgData.eventKey)

        siteAPI.getSiteAspect('tpaPubSubAspect').unsubscribe(appDefId, compId, event)
    }
}
