define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/platform/services/originService',
    'documentServices/platform/core/getEditorSdkUrl',
    'experiment'
], function (_, santaCoreUtils, siteMetadata, originService, getEditorSdkUrl, experiment) {
    'use strict'
    let _currentHandler

    const {joinURL} = santaCoreUtils.urlUtils

    const addWorkerHandler = function (handler, _appsContainer) {
        if (_currentHandler) {
            _appsContainer.removeEventListener('message', handler)
            _currentHandler = null
        }

        _currentHandler = handler
        _appsContainer.addEventListener('message', handler)
    }

    const semverInUrlRegex = /\d+\.\d+\.\d+/

    const replaceVersion = function (base, version) {
        return base.replace(semverInUrlRegex, version)
    }

    const isSemver = function (str) {
        return /^\d+\.\d+\.\d+$/.test(str)
    }

    const isLocalVersion = function (platformSource) {
        return /^https?\:\/\/localhost/.test(platformSource)
    }

    const getBaseUrl = function (appsContainerBase, scriptsDomainUrl, baseDomain, platformSource, servePlatformSourceAsHttp) {
        if (isLocalVersion(platformSource)) {
            return platformSource
        }

        const urlToReplace = _.trimEnd(joinURL(scriptsDomainUrl, 'services'), '/')

        let baseUrl = appsContainerBase.replace(urlToReplace, _.trimEnd(baseDomain, '/'))

        if (isSemver(platformSource)) {
            baseUrl = replaceVersion(baseUrl, platformSource)
        }

        if (servePlatformSourceAsHttp === 'true') {
            baseUrl = baseUrl.replace(/^https:\/\//, 'http://')
        }

        return baseUrl
    }

    const getAppsWorkerInitializeParams = (ps, config = {}) => {
        const sdkVersion = getEditorSdkUrl(ps)
        const extraNamespaces = _.isEmpty(config.extraNamespaces) ? undefined : _.join(config.extraNamespaces, ',')
        const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
        const userId = _.get(siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_INFO), 'userId')
        const editorType = originService.getEditorType()
        const commonConfig = ps.dal.get(ps.pointers.general.getCommonConfig())
        const initParams = {sdkVersion, extraNamespaces, metaSiteId, userId, editorType, commonConfig}
        if (experiment.isOpen('dm_platformWorkerWixExpConfig')) {
            initParams.wixExpConfig = {
                expUseNewApi: true,
                expConductionMethod: 'owner-account',
                ownerId: siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.USER_ID)
            }
        }
        if (ps.siteAPI.getQueryParam('editorScriptHot') === 'true') {
            initParams.editorScriptHot = true
        }
        initParams.concurrentEditing = false
        return initParams
    }

    const getAppsContainerUrl = function (ps) {
        const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
        const appsContainerBase = serviceTopology.scriptsLocationMap['santa-platform-apps-container']
        const baseDomain = serviceTopology.editorWorkerBaseUrl
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const query = _.get(currentUrl, 'query')
        const {scriptsDomainUrl} = serviceTopology
        const baseUrl = getBaseUrl(appsContainerBase, scriptsDomainUrl, baseDomain, query.PlatformContainerSource, query.PlatformContainerHttp)

        return joinURL(baseUrl, 'dist/platform-worker.js')
    }

    function isLocalWorker(urlBase) {
        const localhostRegex = /^https?:\/\/localhost($|:|\/)/
        return localhostRegex.test(urlBase) ? urlBase : null
    }

    function createWorkerBlobUrl(workerUrlOverride) {
        const blobUrl = new Blob([`importScripts('${workerUrlOverride}');`], {type: 'application/javascript'})

        return URL.createObjectURL(blobUrl)
    }

    return {
        getEditorSdkUrl,
        addWorkerHandler,
        getAppsContainerUrl,
        getAppsWorkerInitializeParams,
        isLocalWorker,
        createWorkerBlobUrl
    }
})
