define([
    'lodash',
    '@wix/mobile-conversion',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/utils/utils',
    'documentServices/theme/theme',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/componentsMetaData/metaDataUtils',
    'documentServices/mobileConversion/modules/componentSpecificConversionData/componentSpecificConversionData',
    'documentServices/variants/design',
    'documentServices/mobileConversion/modules/mobileHints'
], function (_, mobileCore, utils, dsUtils, theme, componentsMetaData, metaDataUtils, componentSpecificConversionData, design, mobileHints) {
    'use strict'

    function getStyle(ps, comp) {
        //this should consider refArray JIRA DM-4440
        const {styleId} = comp
        if (styleId) {
            return theme.styles.get(ps, styleId)
        }
        return null
    }

    function getBackground(ps, comp, pageId) {
        const designItem = design.getComponentDefaultDesign(ps, comp, pageId)
        return _.get(designItem, 'background')
    }

    function getBackgroundColor(ps, comp, pageId) {
        const backgroundColor = comp.designQuery
            ? _.get(getBackground(ps, comp, pageId), 'color', null)
            : _.get(getStyle(ps, comp), ['style', 'properties', 'bg'], null)
        return backgroundColor ? backgroundColor.replace(/[{}]/g, '') : null
    }

    function hasMediaBackground(ps, component, pageId) {
        return _.has(getBackground(ps, component, pageId), 'mediaRef')
    }

    function isSolidColorBackgroundComponent(ps, component, pageId) {
        if (_.get(component, ['conversionData', 'category']) === 'columns') {
            return _.size(component.components) === 1 && !hasMediaBackground(ps, component.components[0], pageId)
        }
        if (_.get(component, ['conversionData', 'category']) === 'column' || component.componentType === 'wysiwyg.viewer.components.StripContainer') {
            return !hasMediaBackground(ps, component, pageId)
        }
        return false
    }

    function getComponentBorderFromStyle(ps, component) {
        const border = '' + _.get(getStyle(ps, component), ['style', 'properties', 'brw'], '0') // eslint-disable-line
        // @ts-ignore
        return +border.match(/\d/g).join('')
    }

    function getContainerOpacity(ps, component, pageId, isSolidColorBackground) {
        if (isSolidColorBackground || _.get(component, ['conversionData', 'category']) === 'column') {
            return +_.get(getBackground(ps, component, pageId), 'colorOpacity', 1)
        }
        if (component.componentType !== 'mobile.core.components.Container') {
            return 1
        }
        const style = getStyle(ps, component)
        const styleSkin = _.get(style, 'skin')
        if (styleSkin !== 'wysiwyg.viewer.skins.area.RectangleArea' && styleSkin !== 'wysiwyg.viewer.skins.area.DefaultAreaSkin') {
            return 1
        }
        return +_.get(style, ['style', 'properties', 'alpha-bg'], 1)
    }

    function setConversionData(comp, overriddenValues) {
        const oldConversionData = _.get(comp, 'conversionData', {})
        const newConversionData = _.assign(oldConversionData, overriddenValues)
        _.assign(comp, {conversionData: _.omitBy(newConversionData, _.isNull)})
    }

    function shouldApplyPreset(ps, component, pageId) {
        const modeDefinitions = _.get(component, ['modes', 'definitions'])
        if (_.isEmpty(modeDefinitions)) {
            return true
        }
        const {propertyQuery} = component
        const propertyPointer = propertyQuery ? ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(propertyQuery), pageId) : null
        if (!propertyPointer) {
            return true
        }
        const compProps = ps.dal.get(propertyPointer) || {}
        const modeFromProps = compProps.mobileDisplayedModeId && _.find(modeDefinitions, 'modeId', compProps.mobileDisplayedModeId)
        return _.get(modeFromProps, 'type') !== utils.siteConstants.COMP_MODES_TYPES.DEFAULT
    }

    function validateComponentLayout(component) {
        _.defaults(component, {layout: {}})
        delete component.layout.anchors
        if (mobileCore.conversionUtils.isSiteSegmentOrPage(component) || mobileCore.conversionUtils.isMasterPage(component)) {
            _.assign(component.layout, {fixedPosition: false})
            return
        }
        _.defaults(
            component.layout,
            {
                height: _.get(component, ['conversionData', 'layoutLimits', 'minHeight']),
                width: _.get(component, ['conversionData', 'layoutLimits', 'minWidth'])
            },
            {
                height: utils.siteConstants.COMP_SIZE.MIN_HEIGHT,
                width: utils.siteConstants.COMP_SIZE.MIN_WIDTH,
                x: mobileCore.conversionConfig.COMPONENT_MOBILE_MARGIN_X,
                y: mobileCore.conversionConfig.COMPONENT_MOBILE_MARGIN_Y
            }
        )
    }

    function createConversionData(ps, component, pageId) {
        const mobileConversionConfig = componentsMetaData.public.getMobileConversionConfig(ps, component, pageId)
        const compWithConversionConfig = _.assign({}, _.clone(component), {conversionData: mobileConversionConfig})
        const conversionData = {}
        const borderWidth = getComponentBorderFromStyle(ps, component)
        conversionData.borderWidth = borderWidth || null
        conversionData.isSolidColorBackground = isSolidColorBackgroundComponent(ps, compWithConversionConfig, pageId) || null
        const containerOpacity = getContainerOpacity(ps, compWithConversionConfig, pageId, conversionData.isSolidColorBackground)
        conversionData.isSemiTransparentContainer = containerOpacity < 1
        conversionData.isTransparentContainer = borderWidth ? false : containerOpacity === 0
        conversionData.backgroundColor = getBackgroundColor(ps, component, pageId)
        conversionData.shouldApplyPreset = shouldApplyPreset(ps, component, pageId)

        const componentSpecificData = componentSpecificConversionData.getComponentSpecificConversionData(ps, component, pageId)
        _.merge(conversionData, componentSpecificData)

        conversionData.mobileHints = mobileHints.getMobileHintsItem(ps, component, pageId)

        const dockedRuntimeLayouts = ps.dal.get(ps.pointers.general.getDockedRuntimeLayout())

        if (_.has(component, ['layout', 'docked']) && dockedRuntimeLayouts[component.id]) {
            _.assign(component.layout.docked, dockedRuntimeLayouts[component.id])
        }
        conversionData.isScreenWidth =
            conversionData.isScreenWidth ||
            utils.dockUtils.isHorizontalDockToScreen(component.layout) ||
            utils.dockUtils.isFullPageComponent(component.layout) ||
            null
        conversionData.isNonContainableFullWidth = metaDataUtils.isNonContainableFullWidth(component.componentType)
        _.assign(conversionData, mobileConversionConfig)
        setConversionData(component, conversionData)
        validateComponentLayout(component)
    }

    function createConversionDataForMobileOnlyComp(ps, component, pageId) {
        const mobileConversionConfig = componentsMetaData.public.getMobileConversionConfig(ps, component, pageId)
        setConversionData(component, mobileConversionConfig || {})
    }

    return {
        createConversionData,
        createConversionDataForMobileOnlyComp
    }
})
