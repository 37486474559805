/*eslint max-statements:0*/
define([
    'lodash',
    'documentServices/component/componentStructureInfo',
    'documentServices/structure/fixedComponentMeasuring',
    'documentServices/structure/structureUtils',
    'documentServices/structure/relativeToScreenPlugins/relativeToScreenPlugins',
    'documentServices/dataModel/dataModel',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/component/componentScroll',
    'documentServices/page/popupUtils',
    'documentServices/component/componentStylesAndSkinsAPI',
    '@wix/santa-core-utils',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/utils/runtimeConfig',
    'documentServices/structure/utils/windowScroll'
], function (
    _,
    componentStructureInfo,
    fixedComponentMeasuring,
    structureUtils,
    relativeToScreenPlugins,
    dataModel,
    documentModeInfo,
    componentScroll,
    popupUtils,
    componentStylesAndSkinsAPI,
    santaCoreUtils,
    componentsMetaData,
    runtimeConfig,
    windowScroll
) {
    'use strict'

    const FIXED_POSITION_COMPS_WHICH_HAVE_RENDER_PLUGIN = {
        'wysiwyg.viewer.components.HeaderContainer': true,
        'wysiwyg.viewer.components.FooterContainer': true,
        'wysiwyg.viewer.components.mobile.TinyMenu': true,
        'wysiwyg.viewer.components.MenuToggle': true
    }

    function getFixedCompXYCoordinates(compStyle, windowWidth, windowHeight, siteMarginBottom) {
        return {
            y: _.isNumber(compStyle.top) ? compStyle.top : windowHeight - compStyle.height - siteMarginBottom,
            x: _.isNumber(compStyle.left) ? compStyle.left : windowWidth - compStyle.width
        }
    }

    function buildComponentMeasuringInfo(ps, compPointer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')

        return {
            data: dataModel.getDataItem(ps, compPointer),
            props: dataModel.getPropertiesItem(ps, compPointer),
            layout: ps.dal.get(compLayoutPointer)
        }
    }

    function getComponentLayoutProp(ps, compPointer, propName) {
        const propPointer = ps.pointers.getInnerPointer(compPointer, ['layout', propName])
        return ps.dal.get(propPointer) || 0
    }

    /**
     * Relative to structure
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {{x: number, y: number}|*}
     */
    function getXYInPixels(ps, compPointer) {
        if (ps.pointers.components.isPage(compPointer)) {
            return {
                x: 0,
                y: 0
            }
        }

        return _.pick(structureUtils.getPositionAndSize(ps, compPointer), ['x', 'y'])
    }

    function getFixedCompCoordinatesRelativeToStructure(ps, compPointer) {
        const relativeToStructureCoordinates = getXYInPixels(ps, compPointer)
        if (documentModeInfo.getViewMode(ps) === 'MOBILE') {
            return relativeToStructureCoordinates
        }

        relativeToStructureCoordinates.x -= getSiteX(ps)
        return relativeToStructureCoordinates
    }

    function sumCoordinates(coordinate1, coordinate2) {
        return {
            x: coordinate1.x + coordinate2.x,
            y: coordinate1.y + coordinate2.y
        }
    }

    /**
     * @param {ps} ps
     * @param {string} viewMode
     * @param {string} pageId
     * @returns {{x: number, y: number}} the coordinates of the pagesContainer relative to masterPage
     */
    function getPageCoordinates(ps, viewMode, pageId) {
        const pagesContainer = ps.pointers.components.getPagesContainer(viewMode)

        return {
            x: getComponentLayoutProp(ps, pagesContainer, 'x'),
            y: popupUtils.isPopup(ps, pageId) ? 0 : getComponentLayoutProp(ps, pagesContainer, 'y')
        }
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {*}
     */
    function isFixedPosition(ps, compPointer) {
        const compFixedPositionPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'fixedPosition'])
        return ps.dal.get(compFixedPositionPointer)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {boolean}
     */
    function isRenderedInFixedPosition(ps, compPointer) {
        if (!isFixedPosition(ps, compPointer)) {
            return false
        }

        const renderFlagPointer = ps.pointers.general.getRenderFlag('renderFixedPositionContainers')
        if (ps.dal.get(renderFlagPointer)) {
            return true
        }

        const compType = componentStructureInfo.getType(ps, compPointer)
        return !FIXED_POSITION_COMPS_WHICH_HAVE_RENDER_PLUGIN[compType]
    }

    function getCoordinatesRelativeToStructure(ps, compPointer) {
        if (isRenderedInFixedPosition(ps, compPointer)) {
            return getFixedCompCoordinatesRelativeToStructure(ps, compPointer)
        }

        const parentCompPointer = ps.pointers.components.getParent(compPointer)
        const coordinates = getXYInPixels(ps, compPointer)

        if (parentCompPointer) {
            const parentCoordinates = getCoordinatesRelativeToStructure(ps, parentCompPointer)
            if (componentsMetaData.public.isFullWidth(ps, compPointer)) {
                parentCoordinates.x = 0
            }
            return sumCoordinates(coordinates, parentCoordinates)
        }

        if (!ps.pointers.components.isInMasterPage(compPointer)) {
            const pageCoordinates = getPageCoordinates(ps, ps.pointers.components.getViewMode(compPointer), compPointer.id)
            return sumCoordinates(coordinates, pageCoordinates)
        }

        return coordinates
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {*}
     */
    function getCompLayoutRelativeToStructure(ps, compPointer) {
        const relativeToStructureCoordinates = getCoordinatesRelativeToStructure(ps, compPointer)
        let compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        const actualPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer, compLayout)

        compLayout = _.defaults(compLayout, actualPositionAndSize)
        const relativeToStructureLayout = _.assign(compLayout, relativeToStructureCoordinates)
        relativeToStructureLayout.bounding = structureUtils.getBoundingLayout(ps, relativeToStructureLayout)
        return relativeToStructureLayout
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param measurer
     * @returns {*}
     */
    function getLayoutRelativeToScreenWithMeasurer(ps, compPointer, measurer) {
        const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const compLayout = ps.dal.get(compLayoutPointer)
        const screenSize = ps.siteAPI.getScreenSize()
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const compMeasuringInfo = buildComponentMeasuringInfo(ps, compPointer)
        let compMeasurements = fixedComponentMeasuring.getFixedComponentMeasurements(measurer, compMeasuringInfo, screenSize, measureMap.siteMarginBottom)
        compMeasurements = getFixedCompXYCoordinates(compMeasurements, screenSize.width, screenSize.height, measureMap.siteMarginBottom)

        return _.assign(compLayout, compMeasurements)
    }

    function subtractTotalScrolls(ps, compPointer, layout) {
        const {x: totalScrollX, y: totalScrollY} = componentScroll.getTotalScrolls(ps, compPointer)
        layout.x -= totalScrollX
        layout.y -= totalScrollY
    }

    function getMeasuredRefComponentLayout(ps, compPointer, compLayoutRelativeToScreen) {
        const layout = {}
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        layout.y = measureMap.absoluteTop[compPointer.id]
        layout.height = measureMap.height[compPointer.id]
        layout.width = measureMap.width[compPointer.id]

        return _.defaults(layout, compLayoutRelativeToScreen)
    }

    function getAdjustedX(ps, compPointer, compLayoutRelativeToScreen, ignorePlugins) {
        let x
        if (!isHorizontallyDockedAndNotFixed(ps, compPointer) || ps.siteAPI.isMobileView()) {
            x = compLayoutRelativeToScreen.x + getSiteX(ps)
        } else {
            x = getRelativeToScreenX(ps, compPointer, ignorePlugins)
        }

        return x
    }

    function getCompLayoutRelativeToScreen(ps, compPointer, ignorePlugins) {
        if (!compPointer) {
            throw new Error('Invalid component pointer')
        }
        let compLayoutRelativeToScreen
        const compType = componentStructureInfo.getType(ps, compPointer)

        const measurer = fixedComponentMeasuring.getMeasuringByType(compType)
        if (measurer) {
            compLayoutRelativeToScreen = getLayoutRelativeToScreenWithMeasurer(ps, compPointer, measurer)
        } else {
            compLayoutRelativeToScreen = getCompLayoutRelativeToStructure(ps, compPointer)

            if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)) {
                compLayoutRelativeToScreen = getMeasuredRefComponentLayout(ps, compPointer, compLayoutRelativeToScreen)
            }

            compLayoutRelativeToScreen.x = getAdjustedX(ps, compPointer, compLayoutRelativeToScreen, ignorePlugins)

            if (!ignorePlugins) {
                const measureMap = ps.siteAPI.getSiteMeasureMap()
                const measuredRelativeToScreen = _.get(measureMap, ['relativeToScreenOverrides', compPointer.id])
                if (measuredRelativeToScreen) {
                    _.assign(compLayoutRelativeToScreen, measuredRelativeToScreen)
                }

                const relativeToScreenPlugin = relativeToScreenPlugins.getPlugin(ps, compPointer)
                if (relativeToScreenPlugin) {
                    const compProperties = dataModel.getPropertiesItem(ps, compPointer)
                    const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
                    _.assign(
                        compLayoutRelativeToScreen,
                        relativeToScreenPlugin(ps.siteAPI, compLayoutRelativeToScreen, compProperties, compPointer, skinExports)
                    )
                }
            }
        }

        subtractTotalScrolls(ps, compPointer, compLayoutRelativeToScreen)

        compLayoutRelativeToScreen.bounding = structureUtils.getBoundingLayout(ps, compLayoutRelativeToScreen)
        return compLayoutRelativeToScreen
    }

    const getRelativeToScreenX = (ps, compPointer, ignorePlugins) => {
        if (!compPointer) {
            return 0
        }

        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const measuredRelativeToScreen = _.get(measureMap, ['relativeToScreenOverrides', compPointer.id, 'x'])

        if (_.isNumber(measuredRelativeToScreen)) {
            return measuredRelativeToScreen
        }

        const layoutXY = getXYInPixels(ps, compPointer)

        return layoutXY.x + getRelativeToScreenX(ps, ps.pointers.components.getParent(compPointer), ignorePlugins)
    }

    function getCompLayoutRelativeToScreenConsideringScroll(ps, compPointer, ignorePlugins) {
        const compLayoutRelativeToScreen = getCompLayoutRelativeToScreen(ps, compPointer, ignorePlugins)

        if (!isShowOnFixedPosition(ps, compPointer)) {
            // TODO: remove santa lefovers
            const scrollY = runtimeConfig.isSanta(ps) ? windowScroll.getScroll(ps).y : ps.siteAPI.getScroll().y
            compLayoutRelativeToScreen.y -= scrollY
            compLayoutRelativeToScreen.bounding.y -= scrollY
        }

        return compLayoutRelativeToScreen
    }

    function isHorizontallyDocked(ps, compPointer) {
        return structureUtils.isHorizontallyDocked(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
    }

    const isHorizontallyDockedAndNotFixed = (ps, compPointer) => {
        if (!compPointer) {
            return false
        }
        return (
            (isHorizontallyDocked(ps, compPointer) && !isRenderedInFixedPosition(ps, compPointer)) ||
            isHorizontallyDockedAndNotFixed(ps, ps.pointers.components.getParent(compPointer))
        )
    }

    function isAncestorRenderedInFixedPosition(ps, compPointer) {
        let parentCompPointer = compPointer
        do {
            parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
            if (!parentCompPointer) {
                return false
            }
        } while (!isRenderedInFixedPosition(ps, parentCompPointer))
        return true
    }

    function getClosestAncestorRenderedInFixedPosition(ps, compPointer) {
        let parentCompPointer = compPointer
        do {
            parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
            if (!parentCompPointer) {
                return false
            }
        } while (!isRenderedInFixedPosition(ps, parentCompPointer))
        return parentCompPointer
    }

    function getSiteX(ps) {
        const siteWidth = ps.siteAPI.getSiteWidth()
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        return measureMap.clientWidth > siteWidth ? 0.5 * (measureMap.clientWidth - siteWidth) : 0
    }

    function isShowOnFixedPosition(ps, compPointer) {
        return isRenderedInFixedPosition(ps, compPointer) || isAncestorRenderedInFixedPosition(ps, compPointer)
    }

    return {
        /**
         * Calculates the coordinates of a component in the viewer relative to the masterPage
         * This is the site, not the window.
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Object} a coordinates object of the corresponding Component, with x and y properties.
         *      @example
         *      const compAbsPosition = documentServices.components.layout.getCompLayoutRelativeToStructure(componentPointer);
         *
         */
        getCompLayoutRelativeToStructure,

        /**
         * Calculates the coordinates of a component in the viewer relative to the masterPage
         * This is the site, not the window.
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Object} a Layout object of the corresponding Component.
         *      @example
         *      const compLayoutRelativeToScreen = documentServices.components.layout.getCompLayoutRelativeToScreen(componentPointer);
         *
         */
        getCompLayoutRelativeToScreen,

        getCompLayoutRelativeToScreenConsideringScroll,

        isRenderedInFixedPosition,

        isShowOnFixedPosition,

        isFixedPosition,

        isAncestorRenderedInFixedPosition,

        getClosestAncestorRenderedInFixedPosition,

        isHorizontallyDocked,

        getSiteX
    }
})
