define([
    'lodash',
    'documentServices/utils/contextAdapter',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/platform/common/constants'
], function (_, contextAdapter, clientSpecMapService, platformConstants) {
    'use strict'

    const ERRORS = {
        mandatoryParam: _.template('param <%=paramName %> is mandatory'),
        appIsNotInstalled: _.template('app <%=appDefId %>  should be provisioned before cloning the data'),
        noEndPoint: _.template('app <%=appDefId %> is not integrated with cloning data from template'),
        failedFetchData: 'failed to fetch data'
    }

    const waitForAjaxToBeResolved = async (url, requestType, signedInstance, data) =>
        new Promise((resolve, reject) => {
            const onSuccess = res => resolve(res)
            const onError = (failedReq, textStatus, errorMessage) => reject(errorMessage)

            const jsonData = {dataType: 'json', headers: {Authorization: signedInstance}}
            if (requestType === 'POST') {
                jsonData.xhrFields = {
                    withCredentials: true
                }
            }

            const bodyData = requestType === 'POST' ? data : null
            contextAdapter.actions.sendHttpRequest(url, requestType, jsonData, bodyData, onSuccess, onError)
        })

    const cloneAppData = async (ps, cloneAppDataUrl, options) =>
        await waitForAjaxToBeResolved(
            cloneAppDataUrl,
            'POST',
            clientSpecMapService.getAppData(ps, platformConstants.APPS.META_SITE.applicationId).instance,
            options
        )

    const getCloneAppDataUrl = (ps, appData) => {
        const appComp = _.find(appData.components, {type: 'PLATFORM'})
        return _.get(appComp, ['data', 'cloneAppDataUrl'])
    }

    const CLONE_TYPES = {
        COMP: 'COMP',
        APP: 'APP'
    }

    const canCloneData = (ps, appDefinitionId, cloneType, options = {}) => {
        if (!appDefinitionId) {
            throw new Error(ERRORS.mandatoryParam({paramName: 'appDefinitionId'}))
        }
        if (!options.origin_instance_id) {
            throw new Error(ERRORS.mandatoryParam({paramName: 'origin_instance_id'}))
        }
        if (!options.origin_comp_id && cloneType === CLONE_TYPES.COMP) {
            throw new Error(ERRORS.mandatoryParam({paramName: 'origin_comp_id'}))
        }
        if (!options.target_comp_id && cloneType === CLONE_TYPES.COMP) {
            throw new Error(ERRORS.mandatoryParam({paramName: 'target_comp_id'}))
        }
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (cloneType === CLONE_TYPES.COMP && !existingAppData) {
            throw new Error(ERRORS.appIsNotInstalled({appDefId: appDefinitionId}))
        }
    }

    const onError = () => {
        const errorDescriptor = {
            message: ERRORS.failedFetchData,
            tags: {fetchDataFailed: true}
        }
        contextAdapter.utils.fedopsLogger.captureError(new Error(errorDescriptor.message), errorDescriptor.tags)
        return false
    }

    const copyDataFromOriginTemplateByComp = async (ps, appDefinitionId, options) =>
        copyDataFromOriginTemplateByCloneType(ps, appDefinitionId, options, CLONE_TYPES.COMP)

    const copyDataFromOriginTemplateByApp = async (ps, appDefinitionId, appData, options) =>
        copyDataFromOriginTemplateByCloneType(ps, appDefinitionId, options, CLONE_TYPES.APP, appData)

    const copyDataFromOriginTemplateByCloneType = async (ps, appDefinitionId, options, cloneType, _appData = undefined) => {
        canCloneData(ps, appDefinitionId, cloneType, options)
        const appData = _appData || clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        const cloneAppDataUrl = getCloneAppDataUrl(ps, appData)

        if (!cloneAppDataUrl) {
            throw new Error(ERRORS.noEndPoint({appDefId: appDefinitionId}))
        }
        try {
            return await cloneAppData(ps, cloneAppDataUrl, options)
        } catch (e) {
            return onError()
        }
    }

    return {
        ERRORS,
        copyDataFromOriginTemplateByApp,
        copyDataFromOriginTemplateByComp
    }
})
