define(['lodash', 'documentServices/siteMetadata/siteMetadata'], function (_, siteMetadataAPI) {
    'use strict'

    function getSiteMetaData(ps) {
        return siteMetadataAPI.getProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA)
    }

    function getBrowserThemeColor(ps) {
        return getSiteMetaData(ps).browserThemeColor
    }

    function setBrowserThemeColorMetaData(ps, value) {
        const siteMetaData = getSiteMetaData(ps)
        siteMetaData.browserThemeColor = value
        siteMetadataAPI.setProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA, siteMetaData)
    }

    function validateColorHex(color) {
        if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color)) {
            throw new Error('invalid color, should be an hex code')
        }
    }

    function setBrowserThemeColor(ps, color) {
        validateColorHex(color)
        setBrowserThemeColorMetaData(ps, color)
    }

    function isBrowserThemeColorSet(ps) {
        return !_.isEmpty(getBrowserThemeColor(ps))
    }

    return {
        getBrowserThemeColor,
        setBrowserThemeColor,
        resetBrowserThemeColor: ps => setBrowserThemeColorMetaData(ps, null),
        isBrowserThemeColorSet
    }
})
