define([
    'documentServices/wixapps/services/items',
    'documentServices/wixapps/services/types',
    'documentServices/wixapps/services/lists',
    'documentServices/wixapps/services/selection',
    'documentServices/wixapps/services/listTemplates',
    'documentServices/wixapps/services/appBuilder',
    'documentServices/wixapps/services/classics',
    'documentServices/wixapps/services/clientSpecMap',
    'documentServices/wixapps/services/metadata',
    'documentServices/wixapps/utils/initializer',
    'documentServices/wixapps/services/blogUtils',
    'documentServices/documentServicesDataFixer/fixers/styles/garbageCollectionFixer',
    'documentServices/wixapps/utils/stylesCollectors/appPart2StylesCollector',
    'documentServices/wixapps/utils/stylesCollectors/appPartStylesCollector'
], function (
    items,
    types,
    lists,
    selection,
    listTemplates,
    appbuilder,
    classics,
    clientSpecMap,
    metadata,
    initializer,
    blogUtils,
    gcFixer,
    appPart2StylesCollector,
    appPartStylesCollector
) {
    'use strict'

    // custom style collectors registration
    const collectors = [appPart2StylesCollector, appPartStylesCollector]
    collectors.forEach(({componentsClasses, collect}) => {
        componentsClasses.forEach(componentClass => gcFixer.registerCustomStyleCollector(componentClass, collect))
    })

    return {
        items,
        types,
        lists,
        selection,
        listTemplates,
        appbuilder,
        classics,
        clientSpecMap,
        metadata,
        initializer,
        blogUtils
    }
})
