import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const getAnchorData = function (anchorInfo) {
    return {
        id: anchorInfo.compId,
        title: anchorInfo.name
    }
}
/* eslint-enable */

const getCurrentPageAnchors = function (siteAPI, msg, pageId, pageAnchors, callback) {
    callback(getCurrentPageAnchorsInfo(pageId, pageAnchors))
}

const getCurrentPageAnchorsInfo = function (pageId, pageAnchors) {
    return _.map(pageAnchors, getAnchorData)
}

const navigateToAnchor = function (siteAPI, msg, pageId, pageAnchors, getAnchorDataId, onFailure) {
    const anchorCompId = msg.data.anchorId
    if (anchorCompId === coreUtils.siteConstants.PAGE_ANCHORS.TOP_ANCHOR_COMP_ID) {
        siteAPI.scrollToAnchor(coreUtils.siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID)
    } else if (anchorCompId === coreUtils.siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_COMP_ID) {
        siteAPI.scrollToAnchor(coreUtils.siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID)
    } else if (isAnchorExistsOnPage(pageId, pageAnchors, anchorCompId)) {
        const anchorDataId = getAnchorDataId(anchorCompId)
        if (anchorDataId) {
            siteAPI.scrollToAnchor(anchorDataId)
        }
    } else if (onFailure) {
        onFailure({
            error: {
                message: `anchor with id "${anchorCompId}" was not found on the current page.`
            }
        })
    }
}

function isAnchorExistsOnPage(pageId, pageAnchors, anchorCompId) {
    const currentPageAnchors = getCurrentPageAnchorsInfo(pageId, pageAnchors)
    return _.some(currentPageAnchors, {id: anchorCompId})
}

export default {
    getCurrentPageAnchors,
    navigateToAnchor,
    isAnchorExistsOnPage
}
