define(['lodash', 'documentServices/routers/routers', 'documentServices/page/page'], function (_, routers, page) {
    'use strict'

    function removeNonExistingPagesFromRouter(ps) {
        const pageIds = page.getPageIdList(ps)
        const appRouters = routers.get.all(ps)

        _.forEach(appRouters, router => {
            const routerRef = routers.getRouterRef.byPrefix(ps, router.prefix)
            _.forEach(router.pages, pageId => {
                if (!_.includes(pageIds, pageId)) {
                    routers.pages.removePageFromRouter(ps, routerRef, pageId)
                }
            })
        })
    }

    return {
        exec(ps) {
            removeNonExistingPagesFromRouter(ps)
        },
        name: 'routersDataFixer',
        version: 1
    }
})
