import _ from 'lodash'
import * as namespaceMapping from '../build/namespaceMapping'
const {NAMESPACE_MAPPING, ALIAS_NAMESPACES, OVERRIDE_NAMESPACES} = namespaceMapping

const MAP_NAME_TO_NAMESPACE = _.invert(NAMESPACE_MAPPING)
const SCHEMA_TO_NAMESPACE = _(NAMESPACE_MAPPING)
    .mapValues((v, namespace) => namespace)
    .assign(MAP_NAME_TO_NAMESPACE)
    .value()

const VIRTUAL_NAMESPACES = _.keys(ALIAS_NAMESPACES).concat(_.keys(OVERRIDE_NAMESPACES))

function getSchemasByDataType(allSchemas: any = {}) {
    const {componentsDefinitionsMap, structure} = allSchemas

    const namespacesSchemas = _(allSchemas) //sometimes we get <namespace, schemas> and sometimes we get <dataMapName, schemas>
        .pickBy((v, k) => SCHEMA_TO_NAMESPACE[k])
        .mapKeys((v, k) => SCHEMA_TO_NAMESPACE[k])
        .value()

    const aliasedNamespaces = _.pick(allSchemas, VIRTUAL_NAMESPACES) //these schemas exist only in the non-client-only configs

    return _.pickBy(
        {
            definition: componentsDefinitionsMap,
            componentsDefinitionsMap,
            allComponentsDefinitionsMap: componentsDefinitionsMap,
            structure,
            ...namespacesSchemas,
            ...aliasedNamespaces
        },
        _.identity
    )
}

const arrangeSchemasByDataType = (rawSchemas: any) => getSchemasByDataType(rawSchemas)

/**
 * just remove the .json for now
 * soon this function will be removed completely
 */
const convertSchemaNamesToOldStyleFileNames = (schemasWithNewStyleNames: any) => _.mapKeys(schemasWithNewStyleNames, (v, k) => k.replace('.json', ''))
const arrangeByDataTypeAndConvertSchemaNamesToOldStyleFileNames = (schemas: any) => arrangeSchemasByDataType(convertSchemaNamesToOldStyleFileNames(schemas))
export {arrangeSchemasByDataType, convertSchemaNamesToOldStyleFileNames, arrangeByDataTypeAndConvertSchemaNamesToOldStyleFileNames}
