import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import appPartCommonDataManager from '@wix/santa-ds-libs/src/wixappsClassics/src/util/appPartCommonDataManager'
import numberOfPostsPerPageGetter from '@wix/santa-ds-libs/src/wixappsClassics/src/util/numberOfPostsPerPageGetter'
import wixappsCore from '@wix/santa-ds-libs/src/wixappsCore'

function CustomFeedLogic(partApi) {
    this.partApi = partApi
}

CustomFeedLogic.prototype.getViewVars = function () {
    return {
        currentPageNumber: getCurrentPageNumber.call(this),
        numberOfPostsPerPage: getNumberOfPostsPerPage.call(this),
        totalNumberOfPages: getTotalNumberOfPages.call(this)
    }
}

CustomFeedLogic.prototype.handlePageNavigationRequest = function (request) {
    setCurrentPageNumber.call(this, request.params.pageNumber)
    this.partApi.refreshRenderedRootsData(this.partApi.refreshPart)
}

wixappsCore.logicFactory.register(coreUtils.blogAppPartNames.CUSTOM_FEED, CustomFeedLogic)

function getTotalNumberOfPages() {
    return Math.ceil(getTotalNumberOfPosts.call(this) / getNumberOfPostsPerPage.call(this))
}

function getTotalNumberOfPosts() {
    return getExtraData.call(this).totalCount
}

function getExtraData() {
    return this.partApi.getCompExtraData()
}

function getNumberOfPostsPerPage() {
    return numberOfPostsPerPageGetter.getNumberOfPostsPerPage(this.partApi.getPartData())
}

function getCurrentPageNumber() {
    return appPartCommonDataManager.getAppPartCommonDataItem(getCompId.call(this), 'currentPageNumber', 1)
}

function setCurrentPageNumber(pageNumber) {
    appPartCommonDataManager.setAppPartCommonDataItem(getCompId.call(this), 'currentPageNumber', pageNumber)
}

function getCompId() {
    return this.partApi.getPartData().id
}
