define([
    'lodash',
    '@wix/document-manager-utils',
    'documentServices/dataModel/dataIds',
    'documentServices/utils/contextAdapter',
    'documentServices/utils/runtimeConfig',
    'documentServices/utils/utils'
], function (_, {ReportableError}, dataIds, contextAdapter, runtimeConfig, dsUtils) {
    const DESIGN_TYPES_REF_KEYS = {
        MediaContainerDesignData: ['background'],
        BackgroundMedia: ['mediaRef', 'imageOverlay'],
        WixVideo: ['posterImageRef'],
        Image: ['link', 'originalImageDataRef']
    }

    const removeRef = (ps, referer, refererPointer, refPropName) => {
        referer[refPropName] = null
        ps.dal.set(refererPointer, referer)
    }

    const allowNull = (ps, referer, refPropName) => referer.type === 'BackgroundMedia' && refPropName === 'mediaRef'

    const fixDuplication = (ps, designItem, pageId, refererPointer, refPropName) => {
        const newId = dataIds.generateNewDesignId()
        const newDesignItem = _.merge({}, designItem, {
            id: newId,
            metaData: {pageId}
        })
        ps.dal.set({type: 'design', id: newId}, newDesignItem)
        ps.dal.set(ps.pointers.getInnerPointer(refererPointer, refPropName), `#${newId}`)
        return newDesignItem
    }

    const exec = ps => {
        if (runtimeConfig.isSanta(ps)) {
            return
        }

        const allComponents = [..._.values(ps.siteAPI.getAllDesktopComponents()), ..._.values(ps.siteAPI.getAllMobileComponents())]
        const allDesignItems = _(ps.pointers.data.getItemsWithPredicate('design', {}, null))
            .mapValues(ptr => ps.dal.get(ptr))
            .pickBy()
            .mapKeys(v => v.id)
            .value()

        const visitedRefs = {}
        const dfsStack = _(allComponents)
            .map(comp => {
                if (!comp.designQuery) {
                    return null
                }
                return {
                    ref: dsUtils.stripHashIfExists(comp.designQuery),
                    pageId: comp.metaData.pageId,
                    referer: comp,
                    refererPointer: {type: 'DESKTOP', id: comp.id},
                    refPropName: 'designQuery'
                }
            })
            .compact()
            .value()

        while (dfsStack.length > 0) {
            const {ref, pageId, referer, refererPointer, refPropName} = dfsStack.pop()
            if (!visitedRefs[pageId]) {
                visitedRefs[pageId] = {}
            }

            if (visitedRefs[pageId][ref]) {
                continue
            }

            let designItem = allDesignItems[ref]
            if (!designItem) {
                if (allowNull(ps, referer, refPropName)) {
                    removeRef(ps, referer, refererPointer, refPropName)
                } else {
                    contextAdapter.utils.fedopsLogger.captureError(
                        new ReportableError({
                            message: 'broken design ref',
                            errorType: 'brokenDesignRef',
                            tags: {
                                brokenDesignReference: true
                            },
                            extras: {
                                compType: referer.type,
                                refName: refPropName
                            }
                        })
                    )
                }

                // After many user sites failed here for broken design references we decided to fail silently
                // First because this is not a new problem but an existing corruption many sites have and the editor can live with
                // Second because in such case there are no duplicates to clean anyway
                continue
            }

            if (designItem.metaData.pageId !== pageId) {
                designItem = fixDuplication(ps, designItem, pageId, refererPointer, refPropName)
            }

            const refs = _(DESIGN_TYPES_REF_KEYS[designItem.type])
                .map(propName => {
                    if (!designItem[propName]) {
                        return null
                    }

                    return {
                        ref: dsUtils.stripHashIfExists(designItem[propName]),
                        pageId,
                        referer: designItem,
                        refererPointer: {type: 'design', id: designItem.id},
                        refPropName: propName
                    }
                })
                .compact()
                .value()

            dfsStack.push(...refs)
            visitedRefs[pageId][ref] = ref
        }
    }

    return {
        exec,
        name: 'duplicateDesignDataFixer',
        version: 1
    }
})
