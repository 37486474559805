import _ from 'lodash'
import {RELATION_DATA_TYPES, DATA_TYPES} from '../constants/constants'
import {DalItem, DmApis, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

const NO_MATCH: string[] = []

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getVariantRelations = (itemDataType: string, variantId: string) => {
        const result = dal.query(itemDataType, dal.queryFilterGetters.getVariantRelationFilter(`#${variantId}`))
        return _(result)
            .keys()
            .map(id => getPointer(id, itemDataType))
            .value()
    }
    const getVariantDataItemsByComponentId = (componentId: string) => dal.query(DATA_TYPES.variants, dal.queryFilterGetters.getVariantFilter(componentId))

    return {
        data: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        },
        variants: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        }
    }
}

const createFilters = () => ({
    getVariantRelationFilter: (namespace: string, value: DalItem): string[] => {
        if (value.variants && value.type === RELATION_DATA_TYPES.VARIANTS) {
            return value.variants
        }
        return NO_MATCH
    },
    getVariantFilter: (namespace: string, value: any): string[] => {
        if (value?.componentId) {
            return [value.componentId]
        }
        return NO_MATCH
    }
})

const createExtension = (): Extension => ({
    name: 'variants',
    createFilters,
    createPointersMethods
})

export {createExtension}
