import allComponentsDefinitionsMap from '../../dist/originalSchemas/componentsDefinitionsMap.json'
import allRestrictedSchemas from '../../dist/allRestrictedSchemas.json'
import * as schemaArrangement from '../schemaArrangement'

const schemas = schemaArrangement.arrangeByDataTypeAndConvertSchemaNamesToOldStyleFileNames(allRestrictedSchemas)

export default {
    ...schemas,
    allComponentsDefinitionsMap,
    componentsDefinitionsMap: allComponentsDefinitionsMap
}
