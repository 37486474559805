import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const {
        defineGetter,
        deprecate,
        actions: {dataManipulation}
    } = publicMethodUtils
    const componentStylesAndSkinsAPI = viewerLibrary.get('documentServices/component/componentStylesAndSkinsAPI')
    const idGenerator = viewerLibrary.get('documentServices/utils/idGenerator')
    const refComponent = viewerLibrary.get('documentServices/refComponent/refComponent')

    return {
        methods: {
            components: {
                style: {
                    getId: defineGetter(componentStylesAndSkinsAPI.style.getId),
                    get: defineGetter(componentStylesAndSkinsAPI.style.get),
                    update: dataManipulation(componentStylesAndSkinsAPI.style.update, {
                        isUpdatingAnchors: refComponent.shouldUpdateAnchors
                    }),

                    setCustom: deprecate(
                        dataManipulation(componentStylesAndSkinsAPI.style.setCustom, {
                            getReturnValue: idGenerator.getStyleIdToAdd
                        }),
                        'This API is deprecated. Please either use components.style.update or components.style.fork instead'
                    ),
                    connectToTheme: dataManipulation(componentStylesAndSkinsAPI.style.connectToThemeStyle),
                    fork: dataManipulation(componentStylesAndSkinsAPI.style.fork),
                    scoped: {
                        remove: dataManipulation(componentStylesAndSkinsAPI.style.removeScoped)
                    }
                }
            }
        }
    }
}
