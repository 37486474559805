import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import typesConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/typesConverter'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

const componentType = 'wysiwyg.viewer.components.Video'

/**
 * @class proxies.Video
 * @extends proxies.mixins.baseProxy
 */
export default {
    mixins: [baseProxy],
    statics: {
        componentType
    },
    renderProxy() {
        const data = this.proxyData
        const props = this.getChildCompProps(componentType)
        props.compData = typesConverter.video(data)
        props.compProp = {
            showControls: this.getCompProp('showControls'),
            autoplay: this.getCompProp('autoplay'),
            loop: this.getCompProp('loop'),
            showinfo: this.getCompProp('showinfo'),
            lightTheme: this.getCompProp('lightTheme')
        }

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
