import _ from 'lodash'
import meshFixedHeaderAndFooter from './meshFixedHeaderAndFooter'
import layout from '../../util/layout'

function headerCustomLayout(id, nodesMap, measureMap) {
    const node = nodesMap[id]
    _.merge(measureMap, {
        left: {[id]: 0},
        width: {[id]: parseInt(node.dataset.siteWidth, 10)},
        top: {[id]: node.style.position === 'fixed' ? 0 : parseInt(node.style.top, 10)}
    })

    return meshFixedHeaderAndFooter(id)
}

const COMP_TYPE = 'wysiwyg.viewer.components.HeaderContainer'

layout.registerRequestToMeasureDom(COMP_TYPE)
layout.registerRequestToMeasureChildren(COMP_TYPE, [['screenWidthBackground'], ['inlineContent']])
layout.registerCustomLayoutFunction(COMP_TYPE, headerCustomLayout)
