define(['@wix/santa-core-utils', 'documentServicesSchemas/services/dataValidatorsBuilder'], function (warmupUtilsLib, dataValidatorsBuilder) {
    'use strict'

    return schemasService => {
        const dataValidators = dataValidatorsBuilder(schemasService)
        const {DATA_TYPES} = warmupUtilsLib.constants

        const createItemAccordingToSchema = schemaName => {
            const item = {type: schemaName}
            dataValidators.resolveDefaultItem(schemaName, item)
            //todo copy common.js here
            //
            //write a test for createDataItemToSchema
            return item
        }

        const defaultDataItemCreatorFactory = schemaType => schemaName => {
            if (schemasService.getSchema(schemaType, schemaName)) {
                return createItemAccordingToSchema(schemaName)
            }
            return null
        }

        const createDataItemByType = defaultDataItemCreatorFactory(DATA_TYPES.data)
        const createDesignItemByType = defaultDataItemCreatorFactory(DATA_TYPES.design)
        const createPropertiesItemByType = defaultDataItemCreatorFactory(DATA_TYPES.prop)
        const createStyleItemByType = defaultDataItemCreatorFactory(DATA_TYPES.theme)

        return {
            createDataItemByType,
            createStyleItemByType,
            createDesignItemByType,
            createPropertiesItemByType
        }
    }
})
