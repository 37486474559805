define([], function () {
    'use strict'

    function createRegistrar() {
        const customNicknameGetters = {}

        return {
            registerCustomGetter(componentType, getter) {
                customNicknameGetters[componentType] = getter
            },
            getCustomGetter(componentType) {
                return customNicknameGetters[componentType]
            }
        }
    }

    return createRegistrar()
})
