define(['lodash', 'documentServices/constants/constants', 'document-services-schemas'], function (_, constants, documentServicesSchemas) {
    'use strict'

    const {DATA_TYPES} = constants
    const {schemasService} = documentServicesSchemas.services
    function getPropertyDefinition(schema, propertyKey, schemaType) {
        if (!schema) {
            return null
        }

        if (schema.$ref) {
            const refSchema = schemasService.getSchema(schemaType, schema.$ref)
            return getPropertyDefinition(refSchema, propertyKey, schemaType)
        }

        if (schema.properties) {
            const def = schema.properties[propertyKey]
            if (def && def.$ref) {
                return schemasService.getSchema(schemaType, def.$ref)
            }
            return def
        }

        if (schema.allOf) {
            const dataType = _.find(schema.allOf, innerSchema => getPropertyDefinition(innerSchema, propertyKey, schemaType))
            return dataType && getPropertyDefinition(dataType, propertyKey, schemaType)
        }
    }

    function isOfType(schemaType, schemaName, propertyKey, expectedType) {
        const schema = schemasService.getSchema(schemaType, schemaName)
        return isSchemaOfType(schemaType, schema, propertyKey, expectedType)
    }

    const isSchemaOfType = (schemaType, schema, propertyKey, expectedType) => {
        const propertyDefinition = getPropertyDefinition(schema, propertyKey, schemaType)
        if (!propertyDefinition || _.isString(propertyDefinition)) {
            return (propertyDefinition && propertyDefinition === expectedType) || false
        }
        if (expectedType === 'list') {
            expectedType = 'array'
        }
        return (
            _.includes(propertyDefinition.pseudoType, expectedType) ||
            _.includes(propertyDefinition.type, expectedType) ||
            propertyDefinition.type === expectedType
        )
    }

    function executeForDataItemRefs(schemaType, dataItem, callback) {
        const schema = schemasService.getSchema(schemaType, dataItem.type)
        if (schema) {
            _.forOwn(dataItem, function (value, key) {
                if (isSchemaOfType(schemaType, schema, key, 'ref')) {
                    return callback(value)
                } else if (isSchemaOfType(schemaType, schema, key, 'refList')) {
                    return _.map(value, refItem => callback(refItem))
                }
            })
        }
    }

    const GET_DEFAULT_META_DATA = {
        [DATA_TYPES.prop]: () => ({schemaVersion: '1.0'}),
        DEFAULT: () => ({isPreset: false, schemaVersion: '1.0', isHidden: false})
    }

    function addDefaultMetaData(newDataItem, itemType = 'DEFAULT') {
        const defaultMetaDataCreator = GET_DEFAULT_META_DATA[itemType] || GET_DEFAULT_META_DATA.DEFAULT
        newDataItem.metaData = _.assign(defaultMetaDataCreator(), newDataItem.metaData)
    }

    return {
        isOfType,
        getPropertyDefinition,
        executeForDataItemRefs,
        addDefaultMetaData
    }
})
