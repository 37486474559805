define(['lodash'], function (_) {
    'use strict'

    return {
        canBeFixedPosition: true,
        layoutLimits: {minWidth: 40, minHeight: 40},
        containable: false,
        duplicatable: false,

        modal(ps, compPointer) {
            const renderFlagPointer = ps.pointers.general.getRenderFlag('componentPreviewStates')
            const componentPreviewStates = ps.dal.get(renderFlagPointer, {})
            return _.get(componentPreviewStates, compPointer.id) === 'open'
        },

        styleCanBeApplied(ps) {
            const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
            return ps.dal.get(isStudioUserPointer)
        },

        mobileOnly: true
    }
})
