define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataIds',
    'documentServices/variants/variantsUtils',
    'documentServices/variants/variants',
    'documentServices/dataModel/dataSerialization',
    'documentServices/dataModel/dataModel',
    'documentServices/utils/utils',
    'documentServices/extensionsAPI/extensionsAPI',
    '@wix/document-manager-utils'
], (_, constants, dataIds, variantsUtils, variants, dataSerialization, dataModel, dsUtils, extensionsAPI, documentManagerUtils) => {
    const {ReportableError} = documentManagerUtils
    const VARIABLES_NAMESPACE = constants.DATA_TYPES.variables

    const createVariablesError = message => {
        return new ReportableError({
            message,
            errorType: 'variablesError'
        })
    }

    const validateCompPointer = (ps, componentPointer) => {
        if (!componentPointer || !ps.dal.isExist(componentPointer)) {
            throw createVariablesError('The component pointer is not passed or it does not exist')
        }
    }
    const validateCompAndVariablePointers = (ps, componentPointer, variablePointer) => {
        validateCompPointer(ps, componentPointer)
        if (!variablePointer || !ps.dal.isExist(variablePointer)) {
            throw createVariablesError('The variable pointer is not passed or it does not exist')
        }
        const currentVariableIds = getComponentVariablesIds(ps, componentPointer)
        if (!_.includes(currentVariableIds, variablePointer.id)) {
            throw createVariablesError(`The component ${componentPointer.id} didn't define ${variablePointer.id} variable`)
        }
    }
    const validateVariants = (ps, compPointerWithVariants) => {
        _.forEach(compPointerWithVariants.variants, variant => {
            if (!ps.dal.isExist(variant)) {
                throw createVariablesError(`Passing component with non-existing variant ${variant.id}`)
            }
        })
    }

    const isVariableInUse = (ps, variablePointer) => {
        const references = extensionsAPI.relationships.getReferencesToPointer(ps, variablePointer)
        return references.some(({type}) => type !== VARIABLES_NAMESPACE)
    }

    const validateVariableNotInUse = (ps, variablePointer) => {
        if (isVariableInUse(ps, variablePointer)) {
            throw createVariablesError(`The variable is in use`)
        }
    }

    const getVariableToAddRef = () => ({
        id: dataIds.generateNewId(VARIABLES_NAMESPACE),
        type: VARIABLES_NAMESPACE
    })

    const getComponentVariablesIds = (ps, componentPointer) => {
        const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
        if (!ps.dal.isExist(variablesListPointer)) {
            return []
        }
        return ps.dal.get(variablesListPointer).variables.map(dsUtils.stripHashIfExists)
    }

    const getComponentVariablesList = (ps, componentPointer) => {
        validateCompPointer(ps, componentPointer)
        return getComponentVariablesIds(ps, componentPointer).map(variableId => ps.pointers.getPointer(variableId, VARIABLES_NAMESPACE))
    }

    const getVariable = (ps, componentPointer, variablePointer) => {
        validateCompAndVariablePointers(ps, componentPointer, variablePointer)
        const variableDataItem = ps.dal.get(variablePointer)
        const variableDefaultValueDataItem = variantsUtils.getDataConsideringVariants(ps, variablePointer, 'value', VARIABLES_NAMESPACE)
        const variableValueType = getVariableValueType(ps, variableDataItem.type)
        return {
            name: variableDataItem.name,
            type: variableDataItem.type,
            value: {type: variableValueType, value: variableDefaultValueDataItem.value}
        }
    }

    const addVariable = (ps, variableToAddRef, componentPointer, variableData) => {
        validateCompPointer(ps, componentPointer)
        if (!variableData) {
            throw createVariablesError('The variableData is missing')
        }
        if (!variableData.type) {
            throw createVariablesError('The variableData is missing variable type')
        }
        if (!variableData.value) {
            throw createVariablesError('The variableData is missing default value for the variant')
        }

        const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
        const pageId = pagePointer && pagePointer.id

        //create variable
        const variableID = dataSerialization.addSerializedItemToPage(
            ps,
            pageId,
            {
                name: variableData.name,
                type: variableData.type
            },
            variableToAddRef.id,
            VARIABLES_NAMESPACE
        )
        //add default value
        variantsUtils.updateDataConsideringVariants(ps, variableToAddRef, 'value', variableData.value, VARIABLES_NAMESPACE)

        const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
        const doesVariablesListExists = ps.dal.isExist(variablesListPointer)
        if (!doesVariablesListExists) {
            //create variables list and link comp to it
            const variablesListID = dataModel.addDeserializedItemToPage(ps, pageId, VARIABLES_NAMESPACE, {
                type: 'VariablesList',
                variables: [`#${variableID}`]
            })
            dataModel.linkComponentToItemByType(ps, componentPointer, variablesListID, VARIABLES_NAMESPACE)
        } else {
            //add variable to existing variables list of the component
            const variablesListDataItem = ps.dal.get(variablesListPointer)
            ps.dal.set(variablesListPointer, {...variablesListDataItem, variables: [...variablesListDataItem.variables, `#${variableID}`]})
        }
    }

    const removeVariable = (ps, componentPointer, variablePointer) => {
        validateCompAndVariablePointers(ps, componentPointer, variablePointer)
        validateVariableNotInUse(ps, variablePointer)

        //remove all the values of the variable
        variantsUtils.removeDataConsideringVariants(ps, variablePointer, 'value', VARIABLES_NAMESPACE)

        //remove variable from variables list
        const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
        const variablesListDataItem = ps.dal.get(variablesListPointer)
        ps.dal.set(variablesListPointer, {...variablesListDataItem, variables: _.without(variablesListDataItem.variables, `#${variablePointer.id}`)})

        //remove the variable
        ps.dal.remove(variablePointer)
    }

    const getVariableValueType = (ps, variableType) => {
        const referenceFieldsInfo = extensionsAPI.schema.extractReferenceFieldsInfoForSchema(ps, VARIABLES_NAMESPACE, variableType)
        const reference = _.find(referenceFieldsInfo, ref => _.isEqual(ref.path, ['value']) && !_.isEmpty(ref.refTypes))
        if (!reference) {
            throw createVariablesError(`${variableType} should define the variable value type in the schema`)
        }
        return reference.refTypes[0]
    }

    const updateVariableDefinition = (ps, componentPointer, variablePointer, variableDefinition) => {
        validateCompAndVariablePointers(ps, componentPointer, variablePointer)
        const currentVariable = ps.dal.get(variablePointer)
        if (variableDefinition.type && currentVariable.type !== variableDefinition.type) {
            throw createVariablesError('Variable type cannot be changed')
        }

        if (variableDefinition.name) {
            ps.dal.set(variablePointer, {...currentVariable, name: variableDefinition.name})
        }

        if (variableDefinition.value) {
            variantsUtils.updateDataConsideringVariants(ps, variablePointer, 'value', variableDefinition.value, VARIABLES_NAMESPACE)
        }
    }

    const getVariableData = (ps, compPointerWithVariants, variablePointer) => {
        validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
        validateVariants(ps, compPointerWithVariants)
        const variablePointerWithVariants = compPointerWithVariants.variants
            ? variants.getPointerWithVariants(ps, variablePointer, compPointerWithVariants.variants)
            : variablePointer
        const variableDataItemInVariant = variantsUtils.getDataConsideringVariants(ps, variablePointerWithVariants, 'value', VARIABLES_NAMESPACE)
        if (!variableDataItemInVariant) {
            return null
        }
        const variableDefinition = ps.dal.get(variablePointer)
        const variableValueType = getVariableValueType(ps, variableDefinition.type)
        return {
            type: variableValueType,
            value: variableDataItemInVariant.value
        }
    }

    const removeVariableData = (ps, compPointerWithVariants, variablePointer) => {
        validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
        if (_.isEmpty(compPointerWithVariants.variants)) {
            throw createVariablesError('Cannot remove the default variable value, please use the components.variables.remove function for that')
        }
        validateVariants(ps, compPointerWithVariants)
        const variablePointerWithVariants = variants.getPointerWithVariants(ps, variablePointer, compPointerWithVariants.variants)
        variantsUtils.removeDataConsideringVariants(ps, variablePointerWithVariants, 'value', VARIABLES_NAMESPACE)
    }

    const updateVariableData = (ps, compPointerWithVariants, variablePointer, variableData) => {
        validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
        validateVariants(ps, compPointerWithVariants)
        const variablePointerWithVariants = compPointerWithVariants.variants
            ? variants.getPointerWithVariants(ps, variablePointer, compPointerWithVariants.variants)
            : variablePointer
        variantsUtils.updateDataConsideringVariants(ps, variablePointerWithVariants, 'value', variableData, VARIABLES_NAMESPACE)
    }
    const getVariableConnection = (ps, variablePointer) => {
        return {
            variableId: `#${variablePointer.id}`,
            type: 'VariableReference'
        }
    }

    const getVariablePointerFromConnection = (ps, variableConnection) => ({
        id: dsUtils.stripHashIfExists(variableConnection.variableId),
        type: 'variables'
    })

    const getComponentsUsingVariable = (ps, variablePointer, viewMode = constants.VIEW_MODES.DESKTOP) =>
        extensionsAPI.variables.getComponentsUsingVariable(ps, variablePointer, viewMode)

    return {
        getVariableToAddRef,
        getComponentVariablesList,
        getVariable,
        addVariable,
        removeVariable,
        updateVariableDefinition,
        getVariableData,
        removeVariableData,
        updateVariableData,
        getVariableConnection,
        getVariablePointerFromConnection,
        getComponentsUsingVariable
    }
})
