define([], function () {
    'use strict'

    return {
        isRepeatable: false,
        canBeStretched: true,
        defaultMobileProperties: {
            textMode: 'noText'
        }
    }
})
