/* The "Wix instance" becomes invalid after some time.
To avoid this issue we refresh before its expiration time.
It's implemented in a separate extension since it's not needed in all configurations:
A user in a long browser session should refresh the instance while a server API doesn't need to.
It responds to a request and immediately shuts down.
 */
import type {CoreLogger, CreateExtensionArgument, DmApis, Extension} from '@wix/document-manager-core'
import type {RMApi} from './rendererModel'
import {ReportableError} from '@wix/document-manager-utils'

const reportFailure = (logger: CoreLogger, err: Error): void => {
    logger.captureError(
        new ReportableError({
            errorType: 'TIMED_WIX_INSTANCE_REFRESH_FAILED',
            message: 'Failed to refresh the wix instance before expiration',
            extras: {
                reason: err
            }
        })
    )
}

const _setTimeout = (op: () => void, delay: number): number => setTimeout(op, delay) as unknown as number

const interactionName = 'timedWixInstanceRefreshFromMetaSite'

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => ({
    name: 'browserRendererModel',
    async initialize(dmApis: DmApis): Promise<void> {
        if (!experimentInstance.isOpen('dm_timedWixInstanceRefresh')) {
            return
        }
        const {rendererModel} = dmApis.extensionAPI as RMApi
        const {logger} = dmApis.coreConfig

        let currentTimeout: number | null = null
        const refreshWixInstance = async () => {
            try {
                dmApis.coreConfig.logger.interactionStarted(interactionName)
                await rendererModel.refreshWixInstance()
                dmApis.coreConfig.logger.interactionEnded(interactionName)
            } catch (e) {
                reportFailure(logger, e as Error)
            }
        }

        const resetTimeout = () => {
            if (currentTimeout !== null) {
                clearTimeout(currentTimeout)
                currentTimeout = null
            }
            currentTimeout = _setTimeout(refreshWixInstance, rendererModel.getTimeUntilWixInstanceExpiration(Date.now()))
        }

        rendererModel.registerToClientSpecMapUpdate(resetTimeout)
        resetTimeout()
    }
})

export {createExtension}
