define(['documentServices/accessibility/utils'], function (accessibilityUtils) {
    'use strict'

    return {
        /**
         * @param {ps} ps
         * @returns {boolean}
         */
        get(ps) {
            const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
            const accessibilitySettings = ps.dal.get(pointer) || {}
            return Boolean(accessibilitySettings.skipToContent)
        },
        /**
         * @param {ps} ps
         * @param {boolean} enabled
         */
        set(ps, enabled) {
            if (enabled !== false && enabled !== true) {
                throw new Error('skipToContent.set accepts only boolean value')
            }

            const pointer = accessibilityUtils.getAccessibilitySettingsDataPointer(ps)
            const accessibilitySettings = ps.dal.get(pointer) || {}
            accessibilitySettings.skipToContent = enabled
            ps.dal.set(pointer, accessibilitySettings)
        }
    }
})
