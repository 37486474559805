import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixCodePostMessageService from './wixCodePostMessageService'

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
 * @implements {core.SiteAspectInterface}
 * @constructor
 */

function wixCodeMessageQueueAspect(aspectSiteAPI) {
    this.aspectSiteAPI = aspectSiteAPI
    this.siteAPI = aspectSiteAPI.getSiteAPI()
    this.messageQueue = []
    this._currentPageId = null

    aspectSiteAPI.registerToDidLayout(this.flushQueue.bind(this))
    aspectSiteAPI.registerToSSSRSuccess(this.flushQueue.bind(this))
    aspectSiteAPI.registerToOnRendered(
        function () {
            this._currentPageId = this.siteAPI.getSiteData().getCurrentUrlPageId()
            this.flushQueueOnRender()
        }.bind(this)
    )

    aspectSiteAPI.registerToUrlPageChange(
        function () {
            this._currentPageId = null
        }.bind(this)
    )
}

function compIsOnPage(siteAPI, compId, requiresFullRender) {
    const masterPageComps = _.keys(siteAPI.getComponentsByPageId(coreUtils.siteConstants.MASTER_PAGE_ID))
    const pageComps = _.keys(siteAPI.getComponentsOnFocusedPage())
    const compsOnPage = _.union(masterPageComps, pageComps)
    const isAfterSSSRSuccessIfRequired = requiresFullRender ? siteAPI.getSiteData().isAfterSSSRSuccess() : true
    return isAfterSSSRSuccessIfRequired && _.includes(compsOnPage, compId)
}

wixCodeMessageQueueAspect.prototype = {
    flushQueueOnRender() {
        _.forEach(
            this.messageQueue,
            function (messageDescriptor, index) {
                const isWindowMessageToFlush = !_.isNil(messageDescriptor)
                    ? messageDescriptor.compId === coreUtils.constants.COMP_IDS.WINDOW && this._currentPageId === messageDescriptor.message.contextId
                    : false
                if (isWindowMessageToFlush) {
                    delete messageDescriptor.message.data.requiresDom
                    wixCodePostMessageService.sendDelayedMessage(this.siteAPI, messageDescriptor.message, messageDescriptor.handler)
                    this.messageQueue.splice(index, 1)
                }
            }.bind(this)
        )
    },
    flushQueue() {
        _.forEach(
            this.messageQueue,
            function (messageDescriptor, index) {
                if (
                    messageDescriptor &&
                    compIsOnPage(this.siteAPI, messageDescriptor.compId, _.get(messageDescriptor, 'message.data.requiresDom.requiresFullRender'))
                ) {
                    delete messageDescriptor.message.data.requiresDom

                    if (_.get(messageDescriptor, 'message.data.params.requiresDom')) {
                        delete messageDescriptor.message.data.params.requiresDom
                    }

                    wixCodePostMessageService.sendDelayedMessage(this.siteAPI, messageDescriptor.message, messageDescriptor.handler)
                    this.messageQueue.splice(index, 1)
                }
            }.bind(this)
        )
    },
    addMessageToQueue(compId, message, handler) {
        this.messageQueue.push({compId, message, handler})
    },
    shouldQueueMessage(message) {
        const compId = _.get(message, 'data.requiresDom.compId')
        const requiresFullRender = _.get(message, 'data.requiresDom.requiresFullRender')

        const isWindowMessage = compId === coreUtils.constants.COMP_IDS.WINDOW
        if (isWindowMessage) {
            return this._currentPageId !== message.contextId
        }

        return !compIsOnPage(this.siteAPI, compId, requiresFullRender)
    }
}

export default wixCodeMessageQueueAspect
