import _ from 'lodash'
import experiment from 'experiment'
import warmupUtilsLib from '@wix/santa-core-utils'
import coreTpaUtils from '../core/coreTpaUtils'
import wixUrlParser from '../wixUrlParser/wixUrlParser'
import platformAppsUtils from './platformAppsUtils'

const LIGHT_BOX = 'lightbox'
const RESERVED_QUERY_WORDS = [LIGHT_BOX]

function getQueryParams() {
    return _.omit(this.currentUrl.query, RESERVED_QUERY_WORDS)
}

function getBaseUrl() {
    return _.replace(this.getPublicBaseUrl(), /\/$/, '')
}

//being called only in viewer
function getNavigationDataForRgiWithMock() {
    const rootNavigationInfo = wixUrlParser.parseUrl(this, this.currentUrl.full)
    const currPageId = rootNavigationInfo.pageId

    const navigationInfo = {
        tpaInnerRoute: rootNavigationInfo.tpaInnerRoute,
        host: this.currentUrl.host,
        currentPageId: currPageId,
        routerDefinition: rootNavigationInfo.routerDefinition,
        baseUrl: getBaseUrl.call(this),
        currentPageFullUrl: this.currentUrl.full
    }

    return navigationInfo
}

function getFullUrl(currPageId, rootNavigationInfo) {
    const isPreviewMode = !!this.documentServicesModel
    const isSiteWasPublished = isPreviewMode ? this.documentServicesModel.isPublished : true
    const {title} = this.getDataByQuery(currPageId)
    const tpaInnerRoute = _.get(rootNavigationInfo, ['tpaInnerRoute'])
    let url

    if (isSiteWasPublished) {
        const baseUrl = this.getPublicBaseUrl()

        if (this.isDynamicPage(currPageId)) {
            url = this.getPageUrl(rootNavigationInfo, null, baseUrl, false)
        } else {
            url = this.getPageUrl(
                {
                    pageId: currPageId,
                    title,
                    tpaInnerRoute,
                    queryParams: _.get(rootNavigationInfo, ['queryParams'])
                },
                null,
                baseUrl,
                false
            )
        }
    } else {
        const SITE_WAS_NOT_PUBLISHED_PLACE_HOLDER = 'http://yoursitename.wixsite.com/yoursitename'
        url = tpaInnerRoute ? `${SITE_WAS_NOT_PUBLISHED_PLACE_HOLDER}/${_.replace(title, ' ', '-')}/${tpaInnerRoute}` : SITE_WAS_NOT_PUBLISHED_PLACE_HOLDER
        const query = warmupUtilsLib.urlUtils.toQueryString(this.getQueryParams())
        url = query ? `${url}?${query}` : url
    }

    return url
}

function getNavigationDataForRgiWithFull(nextPageInfo) {
    const currPageId = _.get(nextPageInfo, ['pageId']) || this.getPrimaryPageId()
    const rootNavigationInfo = nextPageInfo || this.getRootNavigationInfo()

    const navigationInfo = {
        tpaInnerRoute: rootNavigationInfo.tpaInnerRoute,
        host: this.currentUrl.host,
        currentPageId: currPageId,
        routerDefinition: rootNavigationInfo.routerDefinition,
        baseUrl: getBaseUrl.call(this),
        currentPageFullUrl: getFullUrl.call(this, currPageId, rootNavigationInfo)
    }

    return navigationInfo
}

function getNavigationDataForRgi(isMockSiteData, nextPageInfo) {
    if (isMockSiteData) {
        return getNavigationDataForRgiWithMock.call(this)
    }

    return getNavigationDataForRgiWithFull.call(this, nextPageInfo)
}

function getSMbySiteExtensionInstanceForRgi() {
    const atobFun = typeof window !== 'undefined' ? window.atob : atob
    let extensionData = this.getClientSpecMapEntriesByType('siteextension')[0] || {}
    if (_.isEmpty(extensionData)) {
        const publicEntries = _.filter(this.getClientSpecMap(), app => coreTpaUtils.isTpaByAppType(app.type))
        extensionData = _.find(publicEntries, 'instance') || {}
    }

    // TODO If you refactor this as part of Bolt project please change the behaviour according to WEED-16329
    const instance = extensionData.instance || ''
    let instanceObject
    try {
        instanceObject = JSON.parse(atobFun(_.last(instance.split('.')) || '') || ' {"uid": null, "permissions": null}') || {
            uid: null,
            permissions: null
        }
    } catch (e) {
        instanceObject = {uid: null, permissions: null}
        //TODO: send BI message
    }
    return {
        uid: instanceObject.uid || instanceObject.sessionUId || null,
        permissions: instanceObject.permissions || null
    }
}

function getMultilingualInfoForRgi() {
    const currentLanguageCode = this.getCurrentLanguageCode() || ''
    const siteLanguages = this.getSiteLanguages()
        .filter(lang => lang.status !== 'Deleted')
        .map(lang => _.pick(lang, ['name', 'locale', 'languageCode', 'countryCode', 'isPrimaryLanguage']))
    const isEnabled = !_.isEmpty(siteLanguages)
    return {
        currentLanguageCode,
        siteLanguages,
        isEnabled
    }
}

function getAppsDataForRgi() {
    const {serviceTopology, rendererModel} = this
    const clientSpecMap = experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel})
        ? platformAppsUtils.addSiteExtensionsApps(this.getClientSpecMap(), serviceTopology, '', rendererModel)
        : this.getClientSpecMap()
    return _.reduce(
        clientSpecMap,
        (result, appData) => {
            result[appData.appDefinitionId] = {
                appDefinitionId: appData.appDefinitionId,
                applicationId: appData.applicationId,
                instance: _.get(this.getAppInstance(), [appData.applicationId]) || appData.instance,
                instanceId: appData.instanceId,
                type: appData.type
            }
            return result
        },
        {}
    )
}

function isApplicationStudio() {
    const queryParam = 'applicationStudio'
    return _.has(this.currentUrl.query, queryParam)
}

function getSessionInfoForRgi() {
    return {
        svSession: this.getSvSession()
    }
}

function getRegionalSettingsForRgi() {
    const rendererModel = this.getRendererModel()

    return {
        language: _.get(rendererModel, ['languageCode']),
        locale: _.get(rendererModel, ['sitePropertiesInfo', 'locale']),
        currency: _.get(rendererModel, ['sitePropertiesInfo', 'currency']),
        timezone: _.get(rendererModel, ['sitePropertiesInfo', 'timeZone']),
        browserLocale: typeof navigator !== 'undefined' ? navigator.language : null
    }
}

function getSiteRevisionForRgi() {
    return this.getSiteRevision()
}

function getRouterDataForRgi(contextId) {
    const dynamicPageData = this.getDynamicPageData(contextId)
    if (!dynamicPageData) {
        return
    }
    const {routerData} = dynamicPageData
    const {routerDefinition} = dynamicPageData
    if (!routerData || !routerDefinition) {
        return
    }
    return dynamicPageData
}

export default {
    bind: [
        'getNavigationDataForRgi',
        'getSMbySiteExtensionInstanceForRgi',
        'getSessionInfoForRgi',
        'isApplicationStudio',
        'getSiteRevisionForRgi',
        'getRouterDataForRgi'
    ],
    getNavigationDataForRgi,
    getSMbySiteExtensionInstanceForRgi,
    getMultilingualInfoForRgi,
    getSessionInfoForRgi,
    getSiteRevisionForRgi,
    getAppsDataForRgi,
    getRegionalSettingsForRgi,
    getQueryParams,
    isApplicationStudio,
    getRouterDataForRgi
}
