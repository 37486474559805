import * as _ from 'lodash'
import {walk} from '../../build/json-walker'
import {DATA_TYPES} from '../../build/namespaceMapping'
import {DataNode, PropDataNode} from '../../wixShared.json'
import type {SchemaFile} from '@wix/document-services-types'

const OLD_CONVERSION_RULES = {
    $ref: (value: string, key: string, parentNode: object) => {
        if (value.includes('#')) {
            parentNode[key] = value.split('/').pop()
        }
    }
}

export const convertSchemasToOldFormat = (schemasToConvert: object) => walk(OLD_CONVERSION_RULES, _.cloneDeep(schemasToConvert))

const DEREFERENCE = {
    $ref: (value: string, key: string, parentNode: object, ctx: DereferenceContext) => {
        //sometimes we receive $ref: 'some_file.json#/SchemaName' and sometimes just 'SchemaName' with the assumption it is in same map
        const schemaName = value.split('#/').pop() as string
        if (schemaName === ctx.name) {
            //dont dereference circular schemas
            return
        }
        const referredSchema = ctx.ns[schemaName] ?? ctx.common[schemaName]
        delete parentNode[key]
        const dereferencedReferredSchema = walk(DEREFERENCE, _.cloneDeep(referredSchema), ctx)
        _.assign(parentNode, _.cloneDeep(dereferencedReferredSchema))
    }
}
interface DereferenceContext {
    ns: object //all schemas in namespace of schema we are dereferencing
    common: object //all common schemas we can reference
    name: string //name of schema being dereferenced
}
export const dereference = (schema: object, ctx: DereferenceContext) => walk(DEREFERENCE, _.cloneDeep(schema), ctx)

export const combineAllOf = (schema: any): any => {
    if (schema.allOf) {
        const required = _.union(
            _.flatMap(schema.allOf, item => item.required || []),
            schema.required || []
        )

        const combined: any = {
            ..._.omit(schema, 'allOf'),
            type: 'object',
            properties: _.assign({}, ..._.map(schema.allOf, subschema => combineAllOf(subschema).properties))
        }
        if (required.length) {
            combined.required = required
        }
        return combined
    }

    if (schema.items) {
        return {...schema, items: combineAllOf(schema.items)}
    }

    if (schema.properties) {
        return {...schema, properties: _.mapValues(schema.properties, combineAllOf)}
    }
    return schema
}

interface SchemaLike {
    properties?: object
    allOf?: Array<object>
}

const combineSchemaWithDataNode = (schema: SchemaLike, dataNodeSchema: SchemaLike) => {
    if (schema.properties) {
        return {...schema, properties: {...schema.properties, ...dataNodeSchema.properties}}
    }

    if (schema.allOf) {
        const allOf = schema.allOf.slice()
        allOf.push(dataNodeSchema)
        return {...schema, allOf}
    }

    return schema
}

const DEFAULT_SCHEMA_EXTENSION = {
    [DATA_TYPES.prop]: PropDataNode,
    default: DataNode
}

export const extendDataNodeSchemas = (schemas: any, namespace: string): Record<string, SchemaFile> => {
    const nodeExtension = DEFAULT_SCHEMA_EXTENSION[namespace] ?? DEFAULT_SCHEMA_EXTENSION.default
    return _.mapValues(schemas, schema => combineSchemaWithDataNode(schema, nodeExtension)) as unknown as Record<string, SchemaFile>
}
