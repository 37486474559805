define(['lodash'], function (_) {
    'use strict'

    function create(isMergingOnSet) {
        let cacheEntries = {}
        let lockedEntries = {}

        const get = function (cacheKey) {
            return cacheEntries[cacheKey]
        }

        const set = function (cacheKey, value) {
            const cached = get(cacheKey)
            if (cached && isMergingOnSet) {
                cacheEntries[cacheKey] = _.merge({}, cached, value)
            } else {
                cacheEntries[cacheKey] = value
            }
            return cacheEntries[cacheKey]
        }

        const lock = function (cacheKey) {
            lockedEntries[cacheKey] = 1
        }

        const isLocked = function (cacheKey) {
            return lockedEntries[cacheKey]
        }

        const unlock = function (cacheKey) {
            delete lockedEntries[cacheKey]
        }

        const keys = function () {
            return (cacheEntries && _.keys(cacheEntries)) || []
        }

        const clear = function () {
            cacheEntries = {}
            lockedEntries = {}
        }

        return {
            get,
            set,
            lock,
            unlock,
            isLocked,
            keys,
            clear
        }
    }

    return {
        create
    }
})
