import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const layouters = viewerLibrary.get('documentServices/layouters/layouters')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    const {deprecate} = publicMethodUtils
    return {
        methods: {
            layouters: {
                getMasterChildren: publicMethodUtils.defineGetter(layouters.getMasterChildren),
                getNonMasterChildren: publicMethodUtils.defineGetter(layouters.getNonMasterChildren),
                isMasterChild: publicMethodUtils.defineGetter(layouters.isMasterChild),
                canBeMasterChild: publicMethodUtils.defineGetter(layouters.canBeMasterChild),
                getParentCompWithOverflowHidden: publicMethodUtils.defineGetter(layouters.getParentCompWithOverflowHidden),
                toggleMasterChild: deprecate(
                    publicMethodUtils.defineDataManipulationAction(layouters.toggleMasterChild, {
                        isUpdatingAnchors: dsUtils.YES
                    }),
                    'This method is deprecated'
                )
            }
        }
    }
}
