define(['lodash', 'documentServices/componentsMetaData/components/vectorImageMetaData'], function (_, vectorImageMetaData) {
    'use strict'

    return _.defaults(
        {
            mobileOnly: true,
            hiddenable: false,
            moveDirections: []
        },
        vectorImageMetaData
    )
})
