define([
    'lodash',
    'documentServices/component/component',
    'documentServices/hooks/layoutHooks/utils/layoutHooksUtils',
    'documentServices/structure/utils/componentLayout'
], function (_, component, layoutHooksUtils, componentLayout) {
    'use strict'

    function applyLayoutToWidgetRootOnLayoutUpdate(ps, compPointer, newLayout, updateCompLayoutCallback) {
        const previousLayout = componentLayout.getComponentLayout(ps, compPointer)
        const shouldUpdateChildLayout =
            layoutHooksUtils.isCompResizing(previousLayout, newLayout) || layoutHooksUtils.isCompRescaling(previousLayout, newLayout)
        if (_.isFunction(updateCompLayoutCallback) && shouldUpdateChildLayout) {
            const [widgetRoot] = component.getChildren(ps, compPointer)
            const dx = layoutHooksUtils.calculateDelta(newLayout.x, previousLayout.x)
            const dy = layoutHooksUtils.calculateDelta(newLayout.y, previousLayout.y)
            const rootLayoutUpdate = _.pickBy(
                {
                    x: dx,
                    y: dy,
                    width: newLayout.width,
                    height: newLayout.height,
                    scale: newLayout.scale
                },
                _.isNumber
            )
            updateCompLayoutCallback(ps, widgetRoot, rootLayoutUpdate)
        }
    }

    function applyLayoutToWidgetRootAfterLayoutUpdate(ps, compPointer, newLayout, updateCompLayoutCallback) {
        const [widgetRoot] = component.getChildren(ps, compPointer)
        updateCompLayoutCallback(ps, widgetRoot, {
            x: 0,
            y: 0,
            width: newLayout.width,
            height: newLayout.height
        })
    }

    return {
        applyLayoutToWidgetRootOnLayoutUpdate,
        applyLayoutToWidgetRootAfterLayoutUpdate
    }
})
