/*eslint max-statements:0*/
define([
    'lodash',
    '@wix/document-manager-core',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/structure/utils/arrangement',
    'documentServices/modes/modesUtils',
    'documentServices/anchors/anchors',
    'documentServices/componentsMetaData/componentsMetaData',
    'documentServices/component/componentStructureInfo',
    'documentServices/component/sospModes',
    'documentServices/component/componentModes',
    'documentServices/structure/structureUtils',
    'documentServices/structure/layoutUtils',
    'documentServices/dataModel/dataModel',
    'documentServices/layoutPlugins/layoutPlugins',
    'documentServices/structure/utils/layoutConstraintsUtils',
    'documentServices/hooks/hooks',
    'documentServices/smartBoxes/groupingUtils',
    'documentServices/structure/utils/windowScroll',
    'documentServices/structure/utils/componentLayout',
    'documentServices/structure/utils/layoutValidation',
    'documentServices/structure/siteGapMap',
    'documentServices/bi/events.json',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/utils/runtimeConfig',
    'documentServices/constants/constants',
    'documentServices/structure/utils/layoutSettingsUtils',
    'documentServices/variants/variantsUtils',
    'documentServices/variants/design',
    'documentServices/utils/utils'
], function (
    _,
    dmCore,
    utils,
    arrangement,
    modesUtils,
    anchors,
    componentsMetaData,
    componentStructureInfo,
    sospModes,
    componentModes,
    structureUtils,
    layoutUtils,
    dataModel,
    layoutPlugins,
    layoutConstraintsUtils,
    hooks,
    groupingUtils,
    windowScroll,
    componentLayout,
    layoutValidation,
    siteGapMap,
    biEvents,
    actionsAndBehaviors,
    mobileUtil,
    runtimeConfig,
    constants,
    layoutSettingsUtils,
    variantsUtils,
    design,
    dsUtils
) {
    'use strict'

    /**
     * @typedef {Object} layoutObject
     * @property {number} x
     * @property {number} y
     * @property {number} width
     * @property {number} height
     * @property {Object} [docked]
     * @property {number} rotationInDegrees
     * @property {boolean} fixedPosition
     * @property {number} scale
     */

    /**
     * @typedef {Object} proportionStructure
     * @property {Object} component
     * @property {layoutObject} proportions
     * @property {layoutObject} minLayout
     * @property {layoutObject} maxLayout
     * @property {Array<proportionStructure>} children
     */

    const {COMP_DATA_QUERY_KEYS_WITH_STYLE, COMP_DATA_QUERY_KEYS, DATA_TYPES} = constants
    const {getRepeaterTemplateId, isDisplayedOnlyComponent, getRepeaterItemId, getUniqueDisplayedId, isRefPointer, getOriginalStructure} =
        utils.displayedOnlyStructureUtil
    const {pointerUtils} = dmCore
    const VALID_PROPS_FOR_LAYOUT_UPDATE = ['x', 'y', 'width', 'height', 'rotationInDegrees', 'scale', 'fixedPosition', 'aspectRatio']
    const LAYOUT_UPDATE_PROPS_TO_IGNORE = ['bounding', 'anchors']
    const ANY = 'ANY'
    const simpleLayout = {
        x: ANY,
        y: ANY,
        width: ANY,
        height: ANY,
        anchors: ANY,
        bounding: ANY,
        rotationInDegrees: 0,
        scale: 1,
        fixedPosition: false
    }
    const LAYOUT_UPDATE_COMP_TYPES_TO_NOT_UPDATE_SINGLE_COMP = {
        'wysiwyg.viewer.components.StripColumnsContainer': true,
        'wysiwyg.viewer.components.Column': true
    }

    function validateLayoutForLayoutUpdate(compLayout) {
        const unsupportedProperties = _.omit(compLayout, VALID_PROPS_FOR_LAYOUT_UPDATE)
        if (!_.isEmpty(unsupportedProperties)) {
            throw new Error('updateCompLayout: new layout properties are not supported')
        }
    }

    function getSanitizedLayoutForUpdate(compLayout) {
        return _.omit(compLayout, LAYOUT_UPDATE_PROPS_TO_IGNORE)
    }

    function isSimpleLayout(layout) {
        if (!layout) {
            return true
        }
        let isSimple = true
        _.forOwn(layout, function (value, key) {
            const valueInSimple = simpleLayout[key]
            /*eslint eqeqeq:0*/ //I don't know if fixedPosition can be null as well..
            if (_.isUndefined(valueInSimple) || (valueInSimple !== ANY && value != valueInSimple)) {
                isSimple = false
                return false
            }
        })
        return isSimple
    }

    function getCoordinatesRelativeToContainer(ps, compPointer, containerPointer) {
        const compLayout = layoutUtils.getCompLayoutRelativeToStructure(ps, compPointer)
        const pageLayout = layoutUtils.getCompLayoutRelativeToStructure(ps, containerPointer)

        const relativeToContainerPosition = _.pick(compLayout, ['x', 'y'])
        relativeToContainerPosition.y -= pageLayout.y

        return relativeToContainerPosition
    }

    function reparentComponentToPage(ps, compPointer, keepPosition) {
        const currentContainer = ps.pointers.components.getParent(compPointer)
        if (!ps.pointers.components.isPage(currentContainer)) {
            let newContainerPointer = componentStructureInfo.getPage(ps, compPointer)
            newContainerPointer = componentStructureInfo.getContainerToAddComponentTo(ps, newContainerPointer)
            const relativeToPageCoordinates = keepPosition ? getCoordinatesRelativeToContainer(ps, compPointer, newContainerPointer) : null

            addCompToContainer(ps, compPointer, newContainerPointer)

            if (keepPosition) {
                updateCompLayout(ps, compPointer, relativeToPageCoordinates)
            }
        }
    }

    function adjustLayoutForFixedPositionUpdate(ps, compPointer, isFixed, compLayout) {
        const adjustedLayout = {fixedPosition: isFixed}

        if (isFixed) {
            const compLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(ps, compPointer)

            _.assign(adjustedLayout, _.pick(compLayoutRelativeToScreen, ['x', 'y']))
            adjustedLayout.y -= getScroll(ps).y
        } else {
            const parentPointer = ps.pointers.components.getParent(compPointer)
            const parentLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(ps, parentPointer, true)

            _.assign(adjustedLayout, {
                y: compLayout.y - parentLayoutRelativeToScreen.y + getScroll(ps).y,
                x: compLayout.x - parentLayoutRelativeToScreen.x
            })
        }

        updateCompLayout(ps, compPointer, adjustedLayout)
    }

    function updateAspectRatio(ps, compPointer, layout) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        layout = layout || ps.dal.get(layoutPointer)

        const positionAndSize = structureUtils.getPositionAndSize(ps, compPointer, _.omit(layout, 'aspectRatio'))
        const newAspectRatio = utils.layout.calcAspectRatio(positionAndSize.width, positionAndSize.height)
        const aspectRatioPointer = ps.pointers.getInnerPointer(layoutPointer, 'aspectRatio')
        ps.dal.set(aspectRatioPointer, newAspectRatio)
    }

    function removeAspectRatio(ps, compPointer) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const layout = ps.dal.get(layoutPointer)

        if (!_.isUndefined(layout.aspectRatio)) {
            delete layout.aspectRatio
            ps.dal.set(layoutPointer, layout)
        }
    }

    function isAspectRatioOn(ps, compPointer) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const layout = ps.dal.get(layoutPointer)

        return utils.layout.isAspectRatioOn(layout)
    }

    const isMasterPage = (ps, compPointer) => ps.pointers.isSamePointer(compPointer, ps.pointers.components.getMasterPage(compPointer.type))

    const isSiteShowOnAllPagesZindexMigrated = ps => _.get(getLayoutSettings(ps), 'soapCompsAroundPagesContainer', false)

    const getLayoutSettings = ps => layoutSettingsUtils.getLayoutSettings(ps)

    const fixCompIndexAfterToggleOffFixedPosition = (ps, compPointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)

        if (isMasterPage(ps, parentPointer) && isSiteShowOnAllPagesZindexMigrated(ps)) {
            arrangement.fixIndexForMasterPageChild(ps, compPointer)
        }
    }

    function updateFixedPosition(ps, compPointer, isFixed) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const layout = ps.dal.get(layoutPointer)

        if (isFixed === layout.fixedPosition) {
            return
        }

        adjustLayoutForFixedPositionUpdate(ps, compPointer, isFixed, layout)

        if (isFixed) {
            reparentComponentToPage(ps, compPointer)
        } else {
            fixCompIndexAfterToggleOffFixedPosition(ps, compPointer)
        }
    }
    function didCompResizeFromLeft(oldPositionAndSize, updatedLayout) {
        return updatedLayout.width !== oldPositionAndSize.width && updatedLayout.x !== oldPositionAndSize.x
    }

    function didCompResizeFromTop(oldPositionAndSize, updatedLayout) {
        return updatedLayout.height !== oldPositionAndSize.height && updatedLayout.y !== oldPositionAndSize.y
    }

    function moveChildrenToKeepThemInPlace(ps, compPointer, oldCompLayout, newCompLayout) {
        const childrenPointers = ps.pointers.components.getChildren(compPointer)

        const diffX = (newCompLayout.x || 0) - (oldCompLayout.x || 0)
        const diffY = (newCompLayout.y || 0) - (oldCompLayout.y || 0)
        _.forEach(childrenPointers, function (childPointer) {
            const childLayoutPointer = ps.pointers.getInnerPointer(childPointer, 'layout')
            const childLayout = ps.dal.get(childLayoutPointer)
            if (!structureUtils.isHorizontallyDocked(childLayout)) {
                childLayout.x -= diffX
            }
            if (!structureUtils.isVerticallyDocked(childLayout)) {
                childLayout.y -= diffY
            }
            ps.dal.merge(childLayoutPointer, childLayout)
        })
    }

    function getUpdateCompLayoutCallbackForHooks(isCallerFuncUpdateCompLayoutAndAdjustLayout) {
        const updateFunc = isCallerFuncUpdateCompLayoutAndAdjustLayout ? updateCompLayoutAndAdjustLayout : updateCompLayout

        return function updateCompLayoutCallbackForHooks(ps, compPtr, newLayout) {
            updateFunc(ps, compPtr, newLayout, true)
        }
    }

    const shouldEnforceKeepChildrenInPlace = ps => ps.dal.get(ps.pointers.general.getRenderFlag('enforceShouldKeepChildrenInPlace'))
    const shouldPreserveCompLayoutOnReparent = ps => ps.dal.get(ps.pointers.general.getRenderFlag('preserveCompLayoutOnReparent'))

    /**
     * updates the component layout and the anchors. The layout object can be a subset of {x, y, width, height, rotationInDegrees}
     * @param {ps} ps
     * @param compPointer
     * @param {layoutObject} newLayout
     * @param isCallerFuncUpdateCompLayoutAndAdjustLayout
     * @param isTriggeredByHook
     */
    function changeCompPositionAndSize(ps, compPointer, newLayout, isCallerFuncUpdateCompLayoutAndAdjustLayout, isTriggeredByHook) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const previousLayout = ps.dal.get(layoutPointer)
        const compType = componentStructureInfo.getType(ps, compPointer)
        const updateCompLayoutCallbackForHooks = getUpdateCompLayoutCallbackForHooks(isCallerFuncUpdateCompLayoutAndAdjustLayout)

        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [
            ps,
            compPointer,
            newLayout,
            updateCompLayoutCallbackForHooks,
            isTriggeredByHook,
            previousLayout
        ])

        const updatedLayout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, newLayout)

        layoutConstraintsUtils.constrainByDimensionsLimits(ps, compPointer, updatedLayout)

        if (componentsMetaData.public.isContainer(ps, compPointer) && componentsMetaData.public.isEnforcingContainerChildLimitations(ps, compPointer)) {
            const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(ps, compPointer)
            const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(ps, compPointer)

            layoutConstraintsUtils.constrainByChildrenLayout(ps, compPointer, updatedLayout, !isEnforcingByWidth, !isEnforcingByHeight)
        }
        layoutConstraintsUtils.constrainBySpecificType(ps, compPointer, updatedLayout)
        layoutConstraintsUtils.constrainsByContainer(ps, compPointer, updatedLayout, previousLayout)

        const currentPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer)

        ps.dal.set(layoutPointer, updatedLayout)

        if (_.has(updatedLayout, 'aspectRatio')) {
            updateAspectRatio(ps, compPointer, updatedLayout)
        }

        if (componentsMetaData.getShouldKeepChildrenInPlace(ps, compPointer) && shouldEnforceKeepChildrenInPlace(ps)) {
            const newPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer)

            const didResizeFromLeft = didCompResizeFromLeft(currentPositionAndSize, updatedLayout)
            const didResizeFromTop = didCompResizeFromTop(currentPositionAndSize, updatedLayout)
            if (didResizeFromLeft || didResizeFromTop) {
                moveChildrenToKeepThemInPlace(ps, compPointer, currentPositionAndSize, newPositionAndSize)
            }
        }

        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [
            ps,
            compPointer,
            updatedLayout,
            updateCompLayoutCallbackForHooks,
            isTriggeredByHook,
            previousLayout
        ])
    }

    function changeCompPositionAndSizeAndPreserveProportion(ps, compPointer, /**layoutObject*/ newLayout) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const previousLayout = ps.dal.get(layoutPointer)
        newLayout = getLayoutForUpdateOnCurrentSchema(ps, compPointer, newLayout)
        const compType = componentStructureInfo.getType(ps, compPointer)
        const updateCompLayoutCallbackForHooks = getUpdateCompLayoutCallbackForHooks(false)
        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [ps, compPointer, newLayout, updateCompLayoutCallbackForHooks, false, previousLayout])

        const updatedLayout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, newLayout)

        ps.dal.set(layoutPointer, updatedLayout)

        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [ps, compPointer, updatedLayout, updateCompLayout, false, previousLayout])
    }

    /**
     * Update component layout and preserve its aspect ratio and child parent proportions
     * @param {ps} ps
     * @param newLayout
     * @param proportionStructure
     * @param isRoot
     */
    function updateCompLayoutAndPreserveProportions(ps, newLayout, proportionStructure, isRoot) {
        const ignoreChildren = componentsMetaData.public.isIgnoreChildrenOnProportionalResize(ps, proportionStructure.component)
        const enforceMax = componentsMetaData.public.enforceMaxDimensionsOnProportionalResize(ps, proportionStructure.component)
        let structureChildren = proportionStructure.children
        const previousLayout = ps.dal.get(ps.pointers.getInnerPointer(proportionStructure.component, 'layout'))
        layoutConstraintsUtils.constrainProportionalResize(ps, proportionStructure, newLayout, isRoot, enforceMax)
        layoutConstraintsUtils.constrainsByContainer(ps, proportionStructure.component, newLayout, previousLayout, true)
        if (_.isArray(ignoreChildren)) {
            structureChildren = structureUtils.getChildrenToPreserveProportionsByType(ps, componentsMetaData, structureChildren, ignoreChildren)
        }
        if (ignoreChildren !== true && !_.isEmpty(structureChildren)) {
            _.forEach(structureChildren, function updateChildLayoutAndPreserveProportions(childProportionStructure) {
                const newChildLayout = calcLayoutFromLayoutAndRatios(newLayout, childProportionStructure.proportions)
                updateCompLayoutAndPreserveProportions(ps, newChildLayout, childProportionStructure, false)
            })
        }
        newLayout = _.pick(newLayout, ['x', 'y', 'width', 'height'])
        changeCompPositionAndSizeAndPreserveProportion(ps, proportionStructure.component, newLayout)
        updateAnchorsAfterOperation(ps, proportionStructure.component)
    }

    function getChildParentLayoutRatio(childLayout, parentLayout) {
        return {
            x: childLayout.x / parentLayout.width,
            y: childLayout.y / parentLayout.height,
            width: childLayout.width / parentLayout.width,
            height: childLayout.height / parentLayout.height
        }
    }

    function calcLayoutFromLayoutAndRatios(layout, layoutRatios) {
        let calculatedLayout = {
            x: layout.width * layoutRatios.x,
            y: layout.height * layoutRatios.y,
            width: layout.width * layoutRatios.width,
            height: layout.height * layoutRatios.height
        }

        calculatedLayout = _(calculatedLayout).pickBy(_.isFinite).mapValues(Math.round).value()
        return calculatedLayout
    }

    function getLayoutForUpdateOnCurrentSchema(ps, compPointer, newLayout) {
        if (isDocked(ps, compPointer)) {
            const updatedLayout = componentLayout.applyPositionAndSizeOnCurrentLayoutSchema(ps, compPointer, newLayout)
            return _.assign(updatedLayout, newLayout)
        }

        return newLayout
    }

    function updateAnchorsAfterOperation(ps, compPointer) {
        if (groupingUtils.isGroupedComponent(ps, compPointer)) {
            const groupComp = componentStructureInfo.getContainer(ps, compPointer)
            anchors.updateAnchors(ps, compPointer, groupComp)
        } else {
            anchors.updateAnchors(ps, compPointer)
        }
    }

    function validateAndUpdateLayout(ps, compPointer, newLayout, isCallerFuncUpdateCompLayoutAndAdjustLayout, isTriggeredByHook) {
        const sanitizedLayout = getSanitizedLayoutForUpdate(newLayout)
        if (!_.isEmpty(sanitizedLayout)) {
            validateLayoutForLayoutUpdate(sanitizedLayout)
            changeCompPositionAndSize(
                ps,
                compPointer,
                getLayoutForUpdateOnCurrentSchema(ps, compPointer, sanitizedLayout),
                isCallerFuncUpdateCompLayoutAndAdjustLayout,
                isTriggeredByHook
            )
        }
    }

    function updateCompLayout(ps, compPointer, newLayout, isTriggeredByHook) {
        validateAndUpdateLayout(ps, compPointer, newLayout, false, isTriggeredByHook)
        updateAnchorsAfterOperation(ps, compPointer)
    }

    function updateCompLayoutAndAdjustLayout(ps, compPointer, newLayout, isTriggeredByHook) {
        validateAndUpdateLayout(ps, compPointer, newLayout, true, isTriggeredByHook)

        if (ps.siteAPI.getLayoutMechanism() === utils.constants.LAYOUT_MECHANISMS.ANCHORS) {
            ps.setOperationsQueue.executeAfterCurrentOperationDone(() => {
                anchors.updateAnchorsForCompChildren(ps, compPointer)
            })
        }
    }

    function isReparentingOutsideOfDisplayedOnlyContainer(compPointer, newContainerPointer) {
        const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
        const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
        return !isContainerDisplayedOnly && isCompDisplayedOnly
    }

    function isReparentingIntoDisplayedOnlyContainer(compPointer, newContainerPointer) {
        const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
        const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
        return isContainerDisplayedOnly && !isCompDisplayedOnly
    }

    function notDisplayedOnlyPredicate(pointer) {
        return !isDisplayedOnlyComponent(pointer.id)
    }

    function isReparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer(ps, compPointer, newContainerPointer) {
        const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
        const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
        if (isContainerDisplayedOnly && isCompDisplayedOnly) {
            const compFirstNonDisplayOnlyAncestor = componentStructureInfo.getAncestorByPredicate(ps, compPointer, notDisplayedOnlyPredicate)
            const newContainerFirstNonDisplayOnlyAncestor = componentStructureInfo.getAncestorByPredicate(ps, newContainerPointer, notDisplayedOnlyPredicate)
            return !ps.pointers.isSamePointer(compFirstNonDisplayOnlyAncestor, newContainerFirstNonDisplayOnlyAncestor)
        }
        return false
    }

    function getNewComponentPointerAfterReparent(ps, compPointer, newContainerPointer) {
        const pointerToReturn = _.clone(compPointer)

        if (isReparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer(ps, compPointer, newContainerPointer)) {
            const origId = getRepeaterTemplateId(compPointer.id)
            const itemId = getRepeaterItemId(newContainerPointer.id)
            pointerToReturn.id = getUniqueDisplayedId(origId, itemId)
        } else if (isReparentingOutsideOfDisplayedOnlyContainer(compPointer, newContainerPointer)) {
            pointerToReturn.id = getRepeaterTemplateId(compPointer.id)
        } else if (isReparentingIntoDisplayedOnlyContainer(compPointer, newContainerPointer)) {
            const itemId = getRepeaterItemId(newContainerPointer.id)
            pointerToReturn.id = getUniqueDisplayedId(compPointer.id, itemId)
        }

        const viewMode = ps.pointers.components.getViewMode(newContainerPointer)
        return ps.pointers.components.getUnattached(pointerToReturn.id, viewMode)
    }

    function isScopeChange(ps, componentPointer, newContainerPointer) {
        const oldContainerPointer = ps.pointers.components.getParent(componentPointer)

        return (
            (ps.pointers.components.isPage(oldContainerPointer) && ps.pointers.components.isMasterPage(newContainerPointer)) ||
            (ps.pointers.components.isPage(newContainerPointer) && ps.pointers.components.isMasterPage(oldContainerPointer))
        )
    }

    function updateFixedPositionForReparent(ps, componentPointer, newContainerPointer) {
        if (layoutUtils.isFixedPosition(ps, componentPointer) && !isScopeChange(ps, componentPointer, newContainerPointer)) {
            const fixedPositionPointer = ps.pointers.getInnerPointer(componentPointer, 'layout.fixedPosition')
            ps.dal.set(fixedPositionPointer, false)
        }
    }

    function getBehaviorsToRemove(ps, componentPointer) {
        const compBehaviors = actionsAndBehaviors.getComponentBehaviors(ps, componentPointer)
        const actionsToRemove = ['modeChange', 'modeIn', 'modeOut']
        return _.filter(compBehaviors, function (behavior) {
            return _.includes(actionsToRemove, behavior.action)
        })
    }

    function resetModes(ps, containerPointer, componentPointer) {
        const containerModes = componentModes.getComponentModes(ps, containerPointer)
        if (!containerModes || containerModes.length === 0) {
            const behaviorsToRemove = getBehaviorsToRemove(ps, componentPointer)
            _.forEach(behaviorsToRemove, function (behavior) {
                actionsAndBehaviors.removeComponentSingleBehavior(ps, componentPointer, behavior.name, behavior.action)
            })
        }

        const compPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
        const containerPage = ps.pointers.full.components.getPageOfComponent(containerPointer)
        if (!_.isEqual(compPage, containerPage)) {
            deactivateAllComponentAndChildrenModes(ps, componentPointer)
        }
    }

    function deactivateAllComponentAndChildrenModes(ps, componentPointer) {
        let componentsToCheck = [componentPointer]
        while (!_.isEmpty(componentsToCheck)) {
            const compPointer = componentsToCheck.pop()
            deactivateAllCompModes(ps, compPointer)
            const compChildren = ps.pointers.full.components.getChildren(compPointer)
            componentsToCheck = componentsToCheck.concat(compChildren)
        }
    }

    function deactivateAllCompModes(ps, compPointer) {
        const compModes = componentModes.getComponentModes(ps, compPointer)
        _.forEach(compModes, function (mode) {
            ps.siteAPI.deactivateMode(compPointer, mode.modeId)
        })
    }

    function setContainer(ps, pointerToReturn, componentPointer, newContainerPointer) {
        validateSetContainer(ps, componentPointer, newContainerPointer)
        const actualNewContainerPointer = componentStructureInfo.getContainerToAddComponentTo(ps, newContainerPointer)
        adjustCompLayoutToNewContainer(ps, componentPointer, actualNewContainerPointer)
        updateFixedPositionForReparent(ps, componentPointer, actualNewContainerPointer)
        const oldParentPointer = ps.pointers.components.getParent(componentPointer)

        const compType = componentStructureInfo.getType(ps, componentPointer)
        hooks.executeHook(hooks.HOOKS.CHANGE_PARENT.BEFORE, compType, [ps, componentPointer, newContainerPointer])

        resetModes(ps, newContainerPointer, componentPointer)
        variantsUtils.removeVariantsOverridesIfNeeded(ps, componentPointer, newContainerPointer)
        hooks.executeHook(hooks.HOOKS.VARIANTS.CHANGE_PARENT_AFTER, compType, [ps, componentPointer, newContainerPointer])

        addCompToContainer(ps, componentPointer, actualNewContainerPointer, undefined, pointerToReturn)
        hooks.executeHook(hooks.HOOKS.CHANGE_PARENT.AFTER, compType, [ps, componentPointer, newContainerPointer, oldParentPointer])
        anchors.updateAnchors(ps, componentPointer, oldParentPointer)

        return pointerToReturn
    }

    const getSetContainerInteractionParams = (ps, pointerToReturn, componentPointer, newContainerPointer) => {
        return {component: componentPointer, newContainer: newContainerPointer}
    }

    function isShowOnSomePages(ps, compPointer) {
        return Boolean(sospModes.getShowOnSomePagesRoot(ps, compPointer))
    }

    /**
     * Checks if component is set to show on all pages (=in master page)
     * @member documentServices.components
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {boolean} true iff component is shown on all pages
     */
    function isShowOnAllPages(ps, compPointer) {
        return ps.pointers.components.isInMasterPage(compPointer) && !isShowOnSomePages(ps, compPointer)
    }

    const getSetContainerValidationMessage = (ps, compPointer, newContainerPointer) => {
        if (!ps.dal.isExist(compPointer) || !ps.dal.isExist(newContainerPointer)) {
            return 'invalid component ref or new parent container ref'
        }

        if (!componentsMetaData.public.isContainable(ps, compPointer, newContainerPointer)) {
            return `component isn't containable in container: ${newContainerPointer.id}`
        }

        if (!canContainMoreChildren(ps, newContainerPointer)) {
            return 'maximum number of child components reached'
        }

        return ''
    }

    const canSetContainer = (ps, compPointer, newContainerPointer) => _.isEmpty(getSetContainerValidationMessage(ps, compPointer, newContainerPointer))

    function validateSetContainer(ps, compPointer, newContainerPointer) {
        const setContainerValidationMessage = getSetContainerValidationMessage(ps, compPointer, newContainerPointer)

        if (!_.isEmpty(setContainerValidationMessage)) {
            throw new Error(setContainerValidationMessage)
        }

        return true
    }

    function canContainMoreChildren(ps, containerPointer) {
        return componentsMetaData.public.allowedToContainMoreChildren(ps, containerPointer)
    }

    function getCoordsWhenReparentingToGrandFather(ps, layoutPointer, oldParent) {
        const layout = ps.dal.get(layoutPointer)
        const parentLayout = ps.dal.get(ps.pointers.getInnerPointer(oldParent, 'layout'))
        return {
            x: layout.x + parentLayout.x,
            y: layout.y + parentLayout.y
        }
    }

    function getCoordsWhenReparenting(ps, compPointer, newParentPointer) {
        const compRelativeToScreenLayout = layoutUtils.getCompLayoutRelativeToScreenConsideringScroll(ps, compPointer, true)
        const parentRelativeToScreenLayout = layoutUtils.getCompLayoutRelativeToScreenConsideringScroll(ps, newParentPointer, true)

        return {
            x: compRelativeToScreenLayout.x - parentRelativeToScreenLayout.x,
            y: compRelativeToScreenLayout.y - parentRelativeToScreenLayout.y
        }
    }

    function adjustCompLayoutToNewContainer(ps, compPointer, newParentPointer) {
        if (shouldPreserveCompLayoutOnReparent(ps) || (layoutUtils.isFixedPosition(ps, compPointer) && isScopeChange(ps, compPointer, newParentPointer))) {
            return
        }

        const isCompHorizontallyStretched = isHorizontallyStretchedToScreen(ps, compPointer)
        if (!isCompHorizontallyStretched) {
            unDock(ps, compPointer)
        }

        const oldParent = ps.pointers.components.getParent(compPointer)
        const oldGrandfather = ps.pointers.components.getParent(oldParent)

        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')

        const adjustedCoords = ps.pointers.isSamePointer(oldGrandfather, newParentPointer)
            ? getCoordsWhenReparentingToGrandFather(ps, layoutPointer, oldParent)
            : getCoordsWhenReparenting(ps, compPointer, newParentPointer)

        if (isCompHorizontallyStretched) {
            // @ts-ignore
            delete adjustedCoords.x
        }

        ps.dal.merge(layoutPointer, adjustedCoords)
    }

    function removeDisplayedOnlyDataItems(ps, compPointer) {
        const compAndChildren = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
        const allDisplayedComps = _(compAndChildren).map(ps.pointers.components.getAllDisplayedOnlyComponents).flattenDeep().value()

        // remove with removeAllOverrides()
        _.forEach(allDisplayedComps, function (displayedCompPointer) {
            dataModel.deleteDataItem(ps, displayedCompPointer)
            dataModel.deleteDesignItem(ps, displayedCompPointer)
        })
    }

    const shouldAddRepeatedData = (ps, compPointer, templateDataPointer) => {
        const templateCompData = templateDataPointer && ps.dal.get(templateDataPointer)
        if (!templateCompData) {
            return
        }

        const isRefComponent = isRefPointer(compPointer)
        const byRefDataPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(templateDataPointer)
        const hasByRefDataOverrides = byRefDataPointer && !!ps.dal.get(byRefDataPointer)

        const hasCompData = !isRefComponent && !!templateCompData
        return (isRefComponent && hasByRefDataOverrides) || hasCompData
    }

    const addDisplayedDataItemsByType = (ps, compPointer, newParentPointer, pageId, dataType) => {
        const templatePointer = pointerUtils.getRepeatedItemPointerIfNeeded(compPointer)
        const templateDataPointer = dataModel.getComponentDataPointerByType(ps, templatePointer, dataType)

        const templateCompData = templateDataPointer && dataModel.getDataByPointer(ps, dataType, templateDataPointer, true) //ps.dal.get(templateDataPointer)
        const allDisplayedContainers = ps.pointers.components.getAllDisplayedOnlyComponents(newParentPointer)
        if (shouldAddRepeatedData(ps, compPointer, templateDataPointer)) {
            _.forEach(allDisplayedContainers, displayedContainerPointer => {
                const itemId = getRepeaterItemId(displayedContainerPointer.id)
                const newDisplayedDataItemId = templateDataPointer && getUniqueDisplayedId(templateDataPointer.id, itemId)
                dataModel.addSerializedItemToPage(ps, pageId, templateCompData, newDisplayedDataItemId, dataType)
            })
        }
    }

    function addDisplayedDataItemsForAllChildren(ps, compPointer, newParentPointer, pageId, children) {
        const pointersToUpdate = [compPointer].concat(children)

        _.forEach(pointersToUpdate, function (pointer) {
            addDisplayedDataItemsByType(ps, pointer, newParentPointer, pageId, DATA_TYPES.data)
            addDisplayedDataItemsByType(ps, pointer, newParentPointer, pageId, DATA_TYPES.design)
        })
    }

    function updateOriginalDataItem(ps, compPointer, displayedOnlyCompStructure, pagePointer) {
        if (displayedOnlyCompStructure.dataQuery) {
            const displayedOnlyCompData = dataModel.getDataItem(ps, compPointer, true)
            const originalDataQuery = dsUtils.stripHashIfExists(getRepeaterTemplateId(displayedOnlyCompStructure.dataQuery))
            const dataPointer = ps.pointers.data.getDataItem(originalDataQuery, pagePointer.id)
            dataModel.removeItemRecursivelyByType(ps, dataPointer)
            dataModel.addSerializedDataItemToPage(ps, pagePointer.id, displayedOnlyCompData, originalDataQuery)
        }

        if (displayedOnlyCompStructure.designQuery) {
            const displayedOnlyCompDesign = design.getDesignItem(ps, compPointer, true)
            const originalDesignQuery = dsUtils.stripHashIfExists(getRepeaterTemplateId(displayedOnlyCompStructure.designQuery))
            const designPointer = ps.pointers.data.getDesignItem(originalDesignQuery, pagePointer.id)
            dataModel.removeItemRecursivelyByType(ps, designPointer)
            dataModel.addSerializedDesignItemToPage(ps, pagePointer.id, displayedOnlyCompDesign, originalDesignQuery)
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param {Pointer} newParentPointer
     * @param {number} [optionalIndex]
     * @param [optionalNewCompPointer]
     */
    function addCompToContainer(ps, compPointer, newParentPointer, optionalIndex, optionalNewCompPointer) {
        const newCompPointer = optionalNewCompPointer || compPointer
        const oldPagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const newPagePointer = ps.pointers.components.getPageOfComponent(newParentPointer)

        if (oldPagePointer.id !== newPagePointer.id) {
            moveDataAndPropertiesToNewPage(ps, compPointer, newPagePointer, oldPagePointer)
        }

        const displayedChildrenPointers = ps.pointers.components.getChildrenRecursively(compPointer)
        const fullCompStructure = ps.dal.full.get(compPointer)
        let displayedCompStructure = ps.dal.get(compPointer)
        const reparentingOutsideOfDisplayedOnlyContainer = isReparentingOutsideOfDisplayedOnlyContainer(compPointer, newParentPointer)
        const reparentingIntoDisplayedOnlyContainer = isReparentingIntoDisplayedOnlyContainer(compPointer, newParentPointer)
        const reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer = isReparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer(
            ps,
            compPointer,
            newParentPointer
        )

        if (reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer) {
            updateOriginalDataItem(ps, compPointer, displayedCompStructure, newPagePointer)
            removeDisplayedOnlyDataItems(ps, compPointer)
            addDisplayedDataItemsForAllChildren(ps, compPointer, newParentPointer, newPagePointer.id, displayedChildrenPointers)
        } else if (reparentingOutsideOfDisplayedOnlyContainer) {
            updateOriginalDataItem(ps, compPointer, displayedCompStructure, newPagePointer)
            removeDisplayedOnlyDataItems(ps, compPointer)
        } else if (reparentingIntoDisplayedOnlyContainer) {
            addDisplayedDataItemsForAllChildren(ps, compPointer, newParentPointer, newPagePointer.id, displayedChildrenPointers)
        }

        const newCompIndex = arrangement.getNewIndex(ps, compPointer, newParentPointer, optionalIndex)
        ps.dal.full.remove(compPointer)
        if (
            reparentingOutsideOfDisplayedOnlyContainer ||
            reparentingIntoDisplayedOnlyContainer ||
            reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer
        ) {
            mobileUtil.removeComponentByDesktopComp(ps, compPointer, true)
        }

        const childrenPointer = ps.pointers.full.components.getChildrenContainer(newParentPointer)
        const containerPointer = ps.pointers.getOriginalPointerFromInner(childrenPointer)
        const newFullId = getRepeaterTemplateId(newCompPointer.id)
        //fix for repeates in bolt - DM-1358, setContainer fixes the layout of the template so we need to get the updatedLayout from the full
        displayedCompStructure = !runtimeConfig.isSanta(ps) ? getOriginalStructure(fullCompStructure) : getOriginalStructure(displayedCompStructure)

        const structureToPush = modesUtils.adjustModesToNewContainer(
            ps.pointers,
            ps.dal.fullJsonDal,
            ps.dal.displayedJsonDal,
            containerPointer,
            _.assign(fullCompStructure, _.omit(displayedCompStructure, ['components', 'modes', 'parent', 'id', 'componentType', 'skin']), {id: newFullId})
        )

        ps.dal.full.push(childrenPointer, structureToPush, newCompPointer, newCompIndex)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param newPagePointer
     */
    const moveVariantsToNewPage = (ps, compPointer, newPagePointer) => {
        const variantPointers = dataModel.getComponentVariantsData(ps, {compPointer})
        _.forEach(variantPointers, variantPointer => {
            const itemPointerWithoutRefFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(variantPointer)
            moveDataItemToAnotherPage(ps, itemPointerWithoutRefFallbacks, newPagePointer.id, DATA_TYPES.variants)
        })
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param newPagePointer
     * @param oldPagePointer
     */
    function moveDataAndPropertiesToNewPage(ps, compPointer, newPagePointer, oldPagePointer) {
        const DATA_QUERY_KEYS = ps.runtimeConfig.stylesPerPage ? COMP_DATA_QUERY_KEYS_WITH_STYLE : COMP_DATA_QUERY_KEYS

        function moveDataItemToNewPage(structurePointer, dataType) {
            if (dataType === DATA_TYPES.variants) {
                moveVariantsToNewPage(ps, compPointer, newPagePointer)
            }

            const dataQueryKey = DATA_QUERY_KEYS[dataType]

            const queryPointer = ps.pointers.getInnerPointer(structurePointer, dataQueryKey)
            if (dataQueryKey && ps.dal.full.isExist(queryPointer)) {
                const getter = isDisplayedOnlyComponent(queryPointer.id) ? ps.dal.get : ps.dal.full.get
                const itemId = dsUtils.stripHashIfExists(getter(queryPointer))

                const itemPointer = ps.pointers.data.getItem(dataType, itemId, oldPagePointer.id)
                const itemPointerWithoutRefFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(itemPointer)

                itemPointerWithoutRefFallbacks.useLanguage = _.get(ps.dal.get(ps.pointers.multilingual.originalLanguage()), 'languageCode')

                moveDataItemToAnotherPage(ps, itemPointerWithoutRefFallbacks, newPagePointer.id, dataType)
            }
        }

        _.forEach(DATA_TYPES, function (dataItemType) {
            moveDataItemToNewPage(compPointer, dataItemType)
        })

        const translationsPointer = ps.pointers.page.getPageTranslations(oldPagePointer.id)
        const translationData = ps.dal.full.get(translationsPointer)
        const originalDataQueryPointer = ps.pointers.getInnerPointer(compPointer, 'dataQuery')
        if (ps.dal.full.isExist(originalDataQueryPointer)) {
            const componentDataId = dsUtils.stripHashIfExists(ps.dal.full.get(originalDataQueryPointer))

            _.forEach(translationData, (translation, languageCode) => {
                const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(oldPagePointer.id, languageCode, componentDataId)
                if (ps.dal.full.isExist(translationDataItemPointer)) {
                    const translationDataItem = ps.dal.full.get(translationDataItemPointer)
                    const newPageDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(newPagePointer.id, languageCode, componentDataId)
                    ps.dal.fullJsonDal.remove(translationDataItemPointer)
                    ps.dal.fullJsonDal.set(newPageDataItemPointer, translationDataItem)
                }
            })

            ps.siteDataAPI.createDisplayedPages()
        }

        function moveOverridesDataItemsToNewPage() {
            const componentOverridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
            if (componentOverridesPointer && ps.dal.full.isExist(componentOverridesPointer)) {
                const componentOverrides = ps.dal.full.get(componentOverridesPointer)
                _.forEach(componentOverrides, function (override, index) {
                    const overridePointer = ps.pointers.getInnerPointer(componentOverridesPointer, index)
                    moveDataItemToNewPage(overridePointer, DATA_TYPES.design)
                    moveDataItemToNewPage(overridePointer, DATA_TYPES.prop)
                })
            }
        }

        moveOverridesDataItemsToNewPage()

        const fullChildrenPointers = ps.pointers.full.components.getChildren(compPointer)
        const displayedChildrenPointers = ps.pointers.components.getChildren(compPointer)
        const fullAndDisplayedOnlyChildrenPointers = _.unionBy(fullChildrenPointers, displayedChildrenPointers, function (pointer) {
            return pointer.id
        })
        _.forEach(fullAndDisplayedOnlyChildrenPointers, function (containedCompPointer) {
            moveDataAndPropertiesToNewPage(ps, containedCompPointer, newPagePointer, oldPagePointer)
        })
    }

    function moveDataItemToAnotherPage(ps, dataPointer, newPageId, dataType) {
        const dataItem = dataPointer && ps.dal.full.get(dataPointer)

        //TODO: what will happen if cannot move data..?
        if (dataItem && structureUtils.canMoveDataItemToAnotherPage(dataItem, dataType)) {
            //removing the data item itself should be after traversing the children, otherwise we want find the children'
            const oldPageId = ps.pointers.data.getPageIdOfData(dataPointer)
            dataModel.executeForDataItemRefs(dataType, dataItem, function (dataId) {
                if (!_.isString(dataId)) {
                    return
                }
                const childDataPointer = ps.pointers.data.getItem(dataType, dsUtils.stripHashIfExists(dataId), oldPageId)
                moveDataItemToAnotherPage(ps, childDataPointer, newPageId, dataType)
            })

            ps.dal.full.remove(dataPointer)
            const newDataPointer = ps.pointers.data.getItem(dataType, dataPointer.id, newPageId)
            ps.dal.full.set(newDataPointer, dataItem)
        }
    }

    function isAncestorFixedPosition(ps, compPointer) {
        let parentCompPointer = compPointer
        do {
            parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
            if (!parentCompPointer) {
                return false
            }
        } while (!layoutUtils.isFixedPosition(ps, parentCompPointer))
        return true
    }

    function isScrollingEnabled(ps) {
        return !ps.siteAPI.isSiteScrollingBlocked()
    }

    function isDocked(ps, compPointer) {
        return !!getDock(ps, compPointer)
    }

    function isDockedToScreen(ps, compPointer) {
        return utils.layout.isDockToScreen(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
    }

    function isRotated(ps, compPointer) {
        const compRotationPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'rotationInDegrees'])
        return ps.dal.get(compRotationPointer) !== 0
    }

    function isHorizontallyStretchedToScreen(ps, compPointer) {
        return utils.dockUtils.isHorizontalDockToScreen(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
    }

    function isHorizontallyStretchedToScreenByStructure(ps, compStructure) {
        return utils.dockUtils.isHorizontalDockToScreen(compStructure.layout)
    }

    function getEffectiveTextDimensions(ps, compPointer) {
        const textRuntimeOverallBorders = ps.pointers.general.getTextRuntimeOverallBorders()
        const textRuntimeOverallBordersPointer = ps.pointers.getInnerPointer(textRuntimeOverallBorders, compPointer.id)
        if (textRuntimeOverallBordersPointer) {
            return ps.dal.get(textRuntimeOverallBordersPointer)
        }
        return undefined
    }

    function getDeprecatedMinDimensions() {
        return {width: 0, height: 0}
    }

    function getSiteWidth(ps) {
        return ps.siteAPI.getSiteWidth()
    }

    function getSiteHeight(ps) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        if (measureMap) {
            const currentPopupId = ps.siteAPI.getCurrentPopupId()

            if (currentPopupId) {
                return measureMap.height[currentPopupId]
            }

            const renderFlagPointer = ps.pointers.general.getRenderFlag('extraSiteHeight')

            return measureMap.height[utils.siteConstants.MASTER_PAGE_ID] + (ps.dal.get(renderFlagPointer) || 0)
        }

        return 0
    }

    function getDock(ps, compPointer) {
        const dockedLayoutPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'docked'])
        return ps.dal.get(dockedLayoutPointer)
    }

    function getDockedStylePublic(ps, dockedLayout) {
        return utils.dockUtils.applyDockedStyleWithMargins(
            dockedLayout,
            {
                width: dockedLayout.width,
                height: dockedLayout.height
            },
            ps.siteAPI.getPageMargins(),
            ps.siteAPI.getScreenWidth(),
            ps.siteAPI.getSiteWidth(),
            ps.siteAPI.getSiteX()
        )
    }

    function unDockAndUpdateAnchors(ps, compPointer) {
        unDock(ps, compPointer)
        updateAnchorsAfterOperation(ps, compPointer)
    }

    function unDock(ps, compPointer) {
        if (!isDocked(ps, compPointer)) {
            return
        }

        const positionAndSize = componentLayout.getPositionAndSize(ps, compPointer)
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const layout = ps.dal.get(layoutPointer)

        _.assign(layout, positionAndSize)
        delete layout.docked

        ps.dal.set(layoutPointer, layout)
    }

    function updateDock(ps, compPointer, dockData) {
        const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
        const previousLayout = ps.dal.get(layoutPointer)
        const layout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, {docked: dockData})
        const compType = componentStructureInfo.getType(ps, compPointer)

        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [ps, compPointer, layout, updateCompLayout, false, previousLayout])
        ps.dal.set(layoutPointer, layout)

        if (isAspectRatioOn(ps, compPointer)) {
            updateAspectRatio(ps, compPointer)
        }

        hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [ps, compPointer, layout, updateCompLayout, false, previousLayout])
        updateAnchorsAfterOperation(ps, compPointer)
    }

    function setDock(ps, compPointer, dockData) {
        unDock(ps, compPointer)
        updateDock(ps, compPointer, dockData)
    }

    /**
     * @param {ps} ps
     * @param compPointer
     * @returns {{component: *, children: *, proportions: *}}
     */
    function getRatioStructure(ps, compPointer) {
        const compChildren = ps.pointers.components.getChildren(compPointer)
        const compContainer = ps.pointers.components.getParent(compPointer)
        const containerLayout = structureUtils.getComponentLayout(ps, compContainer)
        const compLayout = structureUtils.getComponentLayout(ps, compPointer)
        const layoutProportions = getChildParentLayoutRatio(compLayout, containerLayout)

        return {
            component: compPointer,
            proportions: layoutProportions,
            children: _.map(compChildren, childPointer => getRatioStructure(ps, childPointer))
        }
    }

    /**
     * @param {ps} ps
     * @param compPointer
     * @param resizeDirection
     * @returns {proportionStructure}
     */
    function getProportionStructure(ps, compPointer, resizeDirection) {
        const proportionStructure = getRatioStructure(ps, compPointer)

        layoutConstraintsUtils.addCompMinLayout(ps, proportionStructure, resizeDirection)
        return /** @type proportionStructure */ (proportionStructure) //forcing casting for tslint
    }

    function initialize(ps) {
        layoutPlugins.initialize(ps)

        const gapMap = siteGapMap.createInitialGapMap(ps)
        const siteHasGaps = _.some(gapMap, gapVal => gapVal > 0)

        if (siteHasGaps) {
            ps.siteAPI.reportBI(biEvents.SITE_GAPS_MAP, {
                above_header: gapMap.aboveHeader,
                above_page: gapMap.abovePagesContainer,
                above_footer: gapMap.aboveFooter
            })
        }
    }

    function shouldUpdateSingleComp(ps, compPointer) {
        const compType = componentsMetaData.getComponentType(ps, compPointer)
        return !LAYOUT_UPDATE_COMP_TYPES_TO_NOT_UPDATE_SINGLE_COMP[compType]
    }

    function isHorizontallyStretched(ps, compPointer) {
        return utils.dockUtils.isHorizontallyStretched(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
    }

    function isVerticallyStretched(ps, compPointer) {
        return utils.dockUtils.isVerticallyStretched(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
    }

    function isSiteLayoutMesh(ps) {
        return getLayoutSettings(ps).mechanism === utils.constants.LAYOUT_MECHANISMS.MESH
    }

    function migrateToMeshLayout(ps) {
        const layoutSettingsPointer = ps.pointers.getInnerPointer(ps.pointers.data.getDataItemFromMaster('masterPage'), 'layoutSettings')
        const layoutSettings = ps.dal.get(layoutSettingsPointer)

        ps.dal.set(
            layoutSettingsPointer,
            _.assign({}, layoutSettings, {
                mechanism: utils.constants.LAYOUT_MECHANISMS.MESH
            })
        )
    }

    function setScroll(ps, x, y) {
        return runtimeConfig.isSanta(ps) ? windowScroll.setScroll(ps, x, y) : ps.siteAPI.setScroll(x, y)
    }

    function getScroll(ps, ignorePopups) {
        return runtimeConfig.isSanta(ps) ? windowScroll.getScroll(ps, ignorePopups) : ps.siteAPI.getScroll(ignorePopups)
    }

    function setScrollAndScale(ps, x, y, scale, duration, originLeft, callback) {
        const renderFlagPointer = ps.pointers.general.getRenderFlag('siteScale')
        ps.dal.set(renderFlagPointer, scale)

        return runtimeConfig.isSanta(ps)
            ? windowScroll.setScrollAndScale(ps, x, y, scale, duration, originLeft, callback)
            : ps.siteAPI.setScrollAndScale(x, y, scale, duration, originLeft, callback)
    }

    function animateScroll(ps, x, y, duration, callback) {
        return runtimeConfig.isSanta(ps) ? windowScroll.animateScroll(ps, x, y, duration, callback) : ps.siteAPI.animateScroll(x, y, duration, callback)
    }

    return {
        initialize,
        getDockedStyle: getDockedStylePublic,

        /**
         * Removes docked property from component layout
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         */
        unDock: unDockAndUpdateAnchors,

        /**
         * Updates docked value in layout
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @param {Object} a the docked data to be updated.
         */
        updateDock,

        /**
         * Sets (overriding) docked value in layout
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @param {Object} a the docked data to be set.
         */
        setDock,

        /**
         * Gets docked value for component
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Object} the docked data.
         *      @example
         *      documentServices.components.getDock(componentPointer)
         */
        getDock,

        /**
         * Returns true if the component is docked left and right in unit vw
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Boolean} component is stretched to screen.
         *      @example
         *      documentServices.components.getDock(componentPointer)
         */
        isHorizontallyStretchedToScreen,

        /**
         * Returns true if the component is docked left and right in unit vw
         *
         * @member documentServices.components.layout
         * @param {Object} compStructure The component's structure.
         * @returns {Boolean} component is stretched to screen.
         *      @example
         *      documentServices.components.getDock(componentStructure)
         */
        isHorizontallyStretchedToScreenByStructure,

        getPositionAndSize: structureUtils.getPositionAndSize,

        updateCompLayout,

        getProportionStructure,

        updateCompLayoutAndAdjustLayout,

        updateAndPreserveProportions: updateCompLayoutAndPreserveProportions,

        canSetContainer,

        /**
         * Set the component container to be the one referenced by newContainerRef
         * @member documentServices.components
         * @param {AbstractComponent} compPointer
         * @param {AbstractComponent} newContainerRef
         */
        setContainer,
        getSetContainerInteractionParams,

        /**
         * @ignore
         */
        getNewComponentPointerAfterReparent,

        /**
         * Check if a component is shown on all pages
         *
         * @function
         * @memberof documentServices.structure.structure
         * @param {AbstractComponent} compPointer pointer to the component to check.
         * @returns {boolean}
         *      @example
         *      documentServices.components.isShowOnAllPages(componentPointer)
         *
         */
        isShowOnAllPages,

        isShowOnSomePages,

        /**
         * Calculates the coordinates of a component in the viewer relative to the masterPage
         * This is the site, not the window.
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Object} a coordinates object of the corresponding Component, with x and y properties.
         *      @example
         *      const compAbsPosition = documentServices.components.layout.getCompLayoutRelativeToStructure(componentPointer)
         *
         */
        getCompLayoutRelativeToStructure: layoutUtils.getCompLayoutRelativeToStructure,

        /**
         * Calculates the coordinates of a component in the viewer relative to the masterPage
         * This is the site, not the window.
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {Object} a Layout object of the corresponding Component.
         *      @example
         *      const compLayoutRelativeToScreen = documentServices.components.layout.getCompLayoutRelativeToScreen(componentPointer)
         *
         */
        getCompLayoutRelativeToScreen: layoutUtils.getCompLayoutRelativeToScreen,

        getCompLayoutRelativeToScreenConsideringScroll: layoutUtils.getCompLayoutRelativeToScreenConsideringScroll,

        addCompToContainer,

        /**
         * Determines if a component has fixed position or not.
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {boolean} true iff the component has fixed position
         *      @example
         *      const compAbsPosition = documentServices.components.layout.isFixedPosition(componentPointer)
         *
         */
        isFixedPosition: layoutUtils.isFixedPosition,

        isAncestorFixedPosition,

        /**
         * Determines if a component is currently rendered in fix position
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compLayout pointer to the component.
         * @returns {boolean} true iff the component is currently rendered in fix position
         *      @example
         *      const compAbsPosition = documentServices.components.layout.isRenderedInFixedPosition(componentPointer)
         *
         */
        isRenderedInFixedPosition: layoutUtils.isRenderedInFixedPosition,

        isAncestorRenderedInFixedPosition: layoutUtils.isAncestorRenderedInFixedPosition,

        getClosestAncestorRenderedInFixedPosition: layoutUtils.getClosestAncestorRenderedInFixedPosition,

        isShowOnFixedPosition: layoutUtils.isShowOnFixedPosition,

        isDocked,

        isDockedToScreen,

        isRotated,

        /**
         * Returns the minimum width / height of a component
         *
         * @member documentServices.components.layout
         * @param {AbstractComponent} compPointer Pointer to the component.
         * @returns {{width: number, height: number}} an object that represents the minimum dimensions for the given component
         *      @example
         *      var minDimensions = documentServices.components.layout.getMinDimensions(componentPointer)
         * @deprecated
         */
        getDeprecatedMinDimensions,

        /**
         * @returns the height of the site
         */
        getSiteHeight,
        getSiteWidth,

        setScroll,
        isScrollingEnabled,

        getScroll,

        setScrollAndScale,

        animateScroll,

        updateFixedPosition,

        reparentComponentToPage,

        getEffectiveTextDimensions,

        updateAspectRatio,

        removeAspectRatio,

        getSiteX: layoutUtils.getSiteX,

        isAspectRatioOn,

        shouldUpdateSingleComp,

        adjustCompLayoutToNewContainer,

        isHorizontallyStretched,

        isVerticallyStretched,

        isSimpleLayout,

        isSiteLayoutMesh,

        migrateToMeshLayout
    }
})
