define([
    'lodash',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/tpa/constants',
    'documentServices/tpa/utils/AppMarketUrlBuilder',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/services/appMarketCacheService',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/tpa/services/billingService',
    'documentServices/utils/contextAdapter',
    'documentServices/constants/constants',
    '@wix/document-manager-utils'
], function (
    _,
    siteMetadata,
    tpaConstants,
    AppMarketUrlBuilder,
    installedTpaAppsOnSiteService,
    cache,
    coreUtils,
    billing,
    contextAdapter,
    constants,
    {ReportableError}
) {
    const pricedAppUrl = _.template('<%= appMarketEditorAPIUrl %><%= appDefinitionId %>/priced-app?metaSiteId=<%= metaSiteId %>&lang=<%= lang %>')
    const relatedAppsUrl = _.template(
        '<%= appMarketEditorAPIUrl %>?market=related_apps&fields=appDefinitionId,slug,name,appIcon,colorSvgIcon,weights,categories,by,relatedAppsTeaser,teaser,hasSection,widgets.defaultWidth,widgets.defaultHeight'
    )
    const marketAPIBulk = _.template('<%= appMarketEditorAPIUrl %>?id=<%= appDefinitionIds %>')
    const sectionsTranslatedPageTitles = _.template(
        '<%= appMarketEditorAPIUrl %>page-apps?fields=widgets.title,widgets.widgetId,appDefinitionId&lang=<%= lang %>'
    )
    const premiumUrlTemplate = _.template(
        '<%= premiumBaseUrl %>?appInstanceId=<%= instanceId %>&appDefinitionId=<%= appDefinitionId %>&paymentCycle=<%= cycle %>&vendorProductId=<%= vendorProductId %>'
    )

    /**
     * @param {ps} ps
     * @returns {string}
     */
    const getAppMarketTopology = function (ps) {
        const serviceTopology = ps.pointers.general.getServiceTopology()
        const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'appMarketEditorApiUrl')
        return `${ps.dal.get(premiumStatePointer)}/`
    }

    /**
     * @param {ps} ps
     * @param appDefinitionId
     * @param currency
     * @returns {*}
     */
    function getPricedAppUrl(ps, appDefinitionId, currency) {
        const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
        const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
        let url = pricedAppUrl({
            appMarketEditorAPIUrl: getAppMarketTopology(ps),
            appDefinitionId,
            metaSiteId,
            lang
        })
        if (currency) {
            url += `&currency=${currency}`
        }
        return url
    }

    function shouldUpdateCache(options) {
        return !_.get(options, 'currency')
    }

    /**
     * @param {ps} ps
     * @param appDefinitionId
     * @param options
     * @param [resolve]
     * @param [reject]
     */
    const callServerWith = function (ps, appDefinitionId, options, resolve, reject) {
        contextAdapter.utils.fedopsLogger.interactionStarted(constants.INTERACTIONS.APP_MARKET_SERVICE.CALL_SERVER)
        cache.lock(appDefinitionId)
        const {ajaxLibrary} = coreUtils
        ajaxLibrary.ajax({
            type: 'GET',
            url: getPricedAppUrl(ps, appDefinitionId, _.get(options, 'currency')),
            success(response) {
                if (shouldUpdateCache(options)) {
                    response = cache.set(appDefinitionId, response)
                } else {
                    const dataFromCache = cache.get(appDefinitionId)
                    if (dataFromCache) {
                        response = _.merge({}, dataFromCache, response)
                    }
                }
                cache.unlock(appDefinitionId)
                if (resolve) {
                    resolve(response)
                }
                contextAdapter.utils.fedopsLogger.interactionEnded(constants.INTERACTIONS.APP_MARKET_SERVICE.CALL_SERVER)
            },
            error(xhr, errorType, err) {
                contextAdapter.utils.fedopsLogger.captureError(
                    new ReportableError({errorType: 'invalidAppMarketCall', message: `Error with calling server: Type: ${errorType}, Error: ${err}`}),
                    {
                        tags: {
                            appMarketCallServer: true,
                            appDefinitionId
                        }
                    }
                )
                //TODO - send bi message
                cache.unlock(appDefinitionId)
                const error = `app market response error for appDefinitionId: ${appDefinitionId}`
                if (resolve) {
                    resolve({
                        error,
                        errorType,
                        errorMessage: err
                    })
                } else if (reject) {
                    reject({
                        error,
                        errorType,
                        errorMessage: err
                    })
                }
            }
        })
    }

    const getAppMarketDataAsync = function (ps, appDefinitionId, options) {
        return new Promise(function (resolve, reject) {
            if (_.isUndefined(appDefinitionId)) {
                reject({error: 'appDefinitionId was not given'})
                //TODO - send bi message
            } else {
                const dataFromCache = cache.get(appDefinitionId)
                if (dataFromCache && hasPriceData(dataFromCache) && !_.get(options, 'currency')) {
                    resolve(dataFromCache)
                } else {
                    callServerWith(ps, appDefinitionId, options, resolve, reject)
                }
            }
        })
    }

    const getAppMarketData = function (ps, appDefinitionId, options) {
        if (_.isNil(appDefinitionId)) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({errorType: 'invalidAppMarketData', message: 'getAppMarketData was called with nil appDefinitionId'})
            )
        }

        const dataFromCache = cache.get(appDefinitionId)
        if (shouldMakeAServerRequest(appDefinitionId)) {
            callServerWith(ps, appDefinitionId, options)
        }
        return dataFromCache
    }

    const hasPriceData = function (data) {
        return data && data.price
    }

    const shouldMakeAServerRequest = function (appDefinitionId) {
        const dataFromCache = cache.get(appDefinitionId)
        const requestInProgress = cache.isLocked(appDefinitionId)
        return requestInProgress !== 1 && (_.isUndefined(dataFromCache) || !hasPriceData(dataFromCache))
    }

    const getAppMarketUrl = function (ps, editorParams) {
        const serviceTopology = ps.pointers.general.getServiceTopology()
        const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'appMarketEditorNewUrl')
        let appMarketBaseUrl = ps.dal.get(premiumStatePointer)
        appMarketBaseUrl = _.replace(appMarketBaseUrl, 'wix-app-market', 'one-app-market')
        const appMarketUrlBuilder = new AppMarketUrlBuilder(appMarketBaseUrl)
        const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
        const compId = 'MarketPanel'
        const metaSiteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.META_SITE_ID)
        const siteId = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.SITE_ID)
        //TODO TPA-DEPENDENCY-NOT-READY Needs to be implemented by IdoK's team in 1-2 weeks
        const isNewWixStores = 'true'

        return appMarketUrlBuilder
            .addOriginParam(editorParams.origin)
            .addModalParams(editorParams.modalName, editorParams.appName, editorParams.tab)
            .addDevAppParam(editorParams.appDefinitionId)
            .addLangParam(lang)
            .addAppMarketTests(editorParams.tests)
            .addCompIdParam(compId)
            .addMetaSiteIdParam(metaSiteId)
            .addSiteIdParam(siteId)
            .addTagsParam(editorParams.query)
            .addOpenAppParam(editorParams.openAppDefId)
            .addAppMarketParams(editorParams.appMarketParams)
            .addNewWixStores(isNewWixStores)
            .addCategoryParam(editorParams.categorySlug)
            .addBiReferralInfoParam(editorParams.openMarketOrigin)
            .addBiReferralInfoCategoryParam(editorParams.referralInfoCategory)
            .addBiSectionParam(editorParams.section)
            .addAddingMethodParam(editorParams.addingMethod)
            .addStateParam(editorParams.state)
            .addCommonConfigParam(ps)
            .build()
    }

    /**
     * @param {ps} ps
     * @param appDefinitionIds
     * @returns {*}
     */
    const getAppMarketBulkUrl = function (ps, appDefinitionIds) {
        const base = getAppMarketTopology(ps)
        const url = marketAPIBulk({
            appMarketEditorAPIUrl: base,
            appDefinitionIds: appDefinitionIds.toString()
        })
        return url
    }

    /**
     * @param {ps} ps
     * @param pageId
     * @returns {Array}
     */
    const getUncachedPageApps = function (ps, pageId) {
        const apps = installedTpaAppsOnSiteService.getInstalledAppsOnPage(ps, pageId)
        const appDefinitionIds = _.map(apps, 'appDefinitionId')
        const cachedAppDefinitionIds = cache.keys()
        const uncachedAppDefinitionIds = _.remove(appDefinitionIds, appDefId => !_.includes(cachedAppDefinitionIds, appDefId))
        return uncachedAppDefinitionIds
    }

    /**
     * gets market data with no price data
     * @param {ps} ps
     * @param pageId
     * @param resolve
     * @param reject
     */
    const getAppMarketDataForPage = function (ps, pageId, resolve, reject) {
        if (_.isUndefined(pageId)) {
            if (reject) {
                reject({
                    error: 'pageId was not given'
                })
            }
        } else {
            const appDefinitionIds = getUncachedPageApps(ps, pageId)
            if (_.isEmpty(appDefinitionIds)) {
                return
            }
            const url = getAppMarketBulkUrl(ps, appDefinitionIds)
            coreUtils.ajaxLibrary.ajax({
                type: 'GET',
                url,
                success(apps) {
                    _.forEach(apps, function (app) {
                        cache.set(app.appDefinitionId, app)
                    })
                    if (resolve) {
                        resolve(apps)
                    }
                },
                error() {
                    //TODO - send bi message
                    if (resolve) {
                        resolve({
                            error: `app market response error for appDefinitionIds: ${appDefinitionIds.toString()}`
                        })
                    } else if (reject) {
                        reject({
                            error: `app market response error for appDefinitionIds: ${appDefinitionIds.toString()}`
                        })
                    }
                }
            })
        }
    }

    const getSectionsTranslatedPageTitlesUrl = function (ps) {
        const base = getAppMarketTopology(ps)
        const lang = siteMetadata.getProperty(ps, siteMetadata.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
        return sectionsTranslatedPageTitles({
            appMarketEditorAPIUrl: base,
            lang
        })
    }

    const getSectionsTranslatedPageTitles = function (ps) {
        const url = getSectionsTranslatedPageTitlesUrl(ps)
        return new Promise(function (resolve, reject) {
            coreUtils.ajaxLibrary.ajax({
                type: 'GET',
                url,
                success(data) {
                    resolve(data)
                },
                error: reject
            })
        })
    }

    /**
     * @param {ps} ps
     * @returns {*}
     */
    const getRelatedAppsUrl = function (ps) {
        const appMarketEditorAPIUrl = getAppMarketTopology(ps)
        const url = relatedAppsUrl({appMarketEditorAPIUrl})
        return url
    }

    const defaultErrorHandler = e => {
        console.error(e)
    }

    /**
     * @param {ps} ps
     * @param onSuccess
     * @param onError
     */
    const getRelatedApps = function (ps, onSuccess, onError = defaultErrorHandler) {
        const url = getRelatedAppsUrl(ps)
        coreUtils.ajaxLibrary.ajax({
            type: 'GET',
            url,
            dataType: 'json',
            contentType: 'application/json',
            success(data) {
                onSuccess(relatedAppsNeededData(data))
            },
            error: onError
        })
    }

    const getAppMarketInfoUrl = function (ps, editorParams, appName) {
        editorParams.appName = appName
        editorParams.modalName = 'app-modal'
        const url = getAppMarketUrl(ps, editorParams)
        return url
    }

    const getAppReviewsUrl = function (ps, editorParams, appName) {
        editorParams.appName = appName
        editorParams.modalName = 'app-modal'
        editorParams.tab = 'reviews'
        const url = getAppMarketUrl(ps, editorParams)
        return url
    }

    const getAppMarketPermissionsUrl = function (ps, editorParams) {
        editorParams.modalName = 'permissions-modal'
        const url = getAppMarketUrl(ps, editorParams)
        return url
    }

    const requestPremiumAppsCycleToBeCached = function (ps, json, callback) {
        const tpaPackages = _.get(json, 'tpaPackages') || []
        _.forEach(tpaPackages, function (packageData) {
            const dataFromCache = cache.get(packageData.appDefinitionId)
            if (dataFromCache) {
                cache.set(packageData.appDefinitionId, {
                    upgradedToYearly: billing.isYearly(packageData)
                })
            } else {
                callServerWith(ps, packageData.appDefinitionId, {}, function () {
                    cache.set(packageData.appDefinitionId, {
                        upgradedToYearly: billing.isYearly(packageData)
                    })
                })
            }
        })

        if (_.isFunction(callback)) {
            callback(tpaPackages)
        }
    }

    const getPremiumApps = function (ps, metasiteId, onSuccess, onError) {
        billing.getPremiumApps(
            ps,
            metasiteId,
            function (premiumApps) {
                requestPremiumAppsCycleToBeCached(ps, premiumApps, onSuccess)
            },
            onError
        )
    }

    const relatedAppsNeededData = function (data) {
        if (!_.isArray(data)) {
            data = [data]
        }
        return data
    }

    function setPurchaseUrl(json, baseUrl, instanceId, appDefinitionId, cycle) {
        if (json.hasOwnProperty(cycle)) {
            if (json[cycle].hasOwnProperty('price')) {
                json[cycle].url = premiumUrlTemplate({
                    premiumBaseUrl: baseUrl,
                    instanceId,
                    appDefinitionId,
                    cycle: _.findKey(tpaConstants.CYCLE, value => value === cycle),
                    vendorProductId: json.id
                })
            } else {
                delete json[cycle]
            }
        }
    }

    const getPackages = async function (ps, appDefinitionId, instanceId, options) {
        const appMarketData = await getAppMarketDataAsync(ps, appDefinitionId, options)
        if (appMarketData.error) {
            throw appMarketData
        } else {
            const premiumBaseUrl = appMarketData.purchaseStartUrl
            const priceRelevantData = _.pick(appMarketData.price, 'currencyCode', 'currencySymbol')
            const result = _.map(appMarketData.packages, function (packageJson) {
                const refactoredJson = _.assign(_.cloneDeep(packageJson), priceRelevantData)
                _.forEach(tpaConstants.CYCLE, function (cycle) {
                    setPurchaseUrl(refactoredJson, premiumBaseUrl, instanceId, appDefinitionId, cycle)
                })
                return refactoredJson
            })
            return result
        }
    }

    return {
        getAppMarketDataAsync,
        getAppMarketData,
        getAppMarketDataForPage,
        getAppMarketUrl,
        getAppMarketInfoUrl,
        getAppReviewsUrl,
        getAppMarketPermissionsUrl,
        getPackages,
        getPremiumApps,
        getRelatedApps,
        getSectionsTranslatedPageTitles,
        requestAppMarketDataToBeCached: getAppMarketData
    }
})
