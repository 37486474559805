import {includes, get} from 'lodash'
import {Component, MasterPageComponent, ComponentWithConversionData, StructureWithConversionData} from '../../types'
const MOBILE_ONLY_COMP_IDS = [
    'TINY_MENU',
    'BACK_TO_TOP_BUTTON',
    'QUICK_ACTION_BAR',
    'QUICK_ACTION_BAR_ITEM',
    'MENU_AS_CONTAINER_TOGGLE',
    'MENU_AS_CONTAINER_EXPANDABLE_MENU',
    'MENU_TOGGLE'
]

const MOBILE_ONLY_COMP_TYPES = [
    'wysiwyg.viewer.components.mobile.TinyMenu',
    'wysiwyg.viewer.components.BackToTopButton',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.viewer.components.QuickActionBarItem',
    'wysiwyg.viewer.components.MenuToggle',
    'wysiwyg.viewer.components.ExpandableMenu'
]

export const isMobileOnlyFixedComponentId = (compId: string): boolean => includes(MOBILE_ONLY_COMP_IDS, compId)

export const isMobileOnlyComponent = (mobileComponent: Component | MasterPageComponent): boolean =>
    includes(MOBILE_ONLY_COMP_TYPES, get(mobileComponent, 'componentType'))

export const isMobileOnlyComponentByConversionData = (mobileComponent: ComponentWithConversionData) => get(mobileComponent, ['conversionData', 'mobileOnly'])

export const isMobileOnly = (comp: StructureWithConversionData) =>
    isMobileOnlyComponent(comp) || isMobileOnlyComponentByConversionData(comp as ComponentWithConversionData)
