import _ from 'lodash'

export default {
    componentWillMount() {
        this.handlers = {}
    },
    componentWillUnmount() {
        _.forEach(
            this.handlers,
            function (handler, name) {
                this.clearPostMessageHandlerNamed(name)
            }.bind(this)
        )
    },
    setPostMessageHandler(id, callback) {
        this.setPostMessageHandlerNamed('default', id, callback)
    },
    setPostMessageHandlerNamed(name, id, callback) {
        if (this.handlers.hasOwnProperty(name)) {
            this.clearPostMessageHandlerNamed(name)
        }
        const handlerWrap = function (msg) {
            let msgData

            try {
                // error handling for good JSON
                msgData = JSON.parse(msg.data)
            } catch (ee) {
                return
            }

            if (msgData.id === id || (id === null && msgData.id === undefined)) {
                callback(msgData)
            }
        }

        window.addEventListener('message', handlerWrap, false)

        this.handlers[name] = handlerWrap
    },
    clearPostMessageHandlerNamed(name) {
        if (this.handlers[name]) {
            window.removeEventListener('message', this.handlers[name], false)
            delete this.handlers[name]
        }
    }
}
