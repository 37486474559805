import _ from 'lodash'

const externalServices = []

function register(service) {
    if (service) {
        externalServices.push(service)
    }
}

function reset() {
    externalServices.length = 0
}

function exists(componentType) {
    return _.some(externalServices, service => service.exists(componentType) === true)
}

function load(componentType, baseUrl) {
    const serviceWithTheComponent = _.find(externalServices, service => service.exists(componentType) === true)
    if (serviceWithTheComponent) {
        return serviceWithTheComponent.load(componentType, baseUrl)
    }
}

function loadAll(baseUrl) {
    return Promise.all(_.invokeMap(externalServices, 'loadAll', baseUrl)).then(componentsFromEachService => _.flatten(componentsFromEachService))
}

export default {
    register,
    reset,
    exists,
    load,
    loadAll
}
