import type {PS} from '@wix/document-services-types'
import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const page = viewerLibrary.get('documentServices/page/page')
    const pageRemoval = viewerLibrary.get('documentServices/page/pageRemoval')
    const pageAdd = viewerLibrary.get('documentServices/page/pageAdd')
    const pageData = viewerLibrary.get('documentServices/page/pageData')
    const appStudio = viewerLibrary.get('documentServices/appStudio/appStudio')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    return {
        initMethod: page.initialize,
        methods: {
            homePage: {
                set: publicMethodUtils.defineDataManipulationAction(page.homePage.set),
                get: publicMethodUtils.defineGetter(page.homePage.get)
            },
            pages: {
                add: publicMethodUtils.defineDataManipulationAction(page.add, {
                    getReturnValue: page.getPageIdToAdd,
                    isUpdatingAnchors: true,
                    shouldLogInteraction: true
                }),
                addAndCloneTpaCompData: publicMethodUtils.defineDataManipulationAction(pageAdd.addAndCloneTpaCompData, {
                    getReturnValue: page.getPageIdToAdd,
                    isUpdatingAnchors: true,
                    shouldLogInteraction: true,
                    isAsyncOperation: true,
                    asyncPreDataManipulation: pageAdd.cloneTpaCompDataIfNeeded
                }),
                duplicate: publicMethodUtils.defineDataManipulationAction(page.duplicate, {
                    getReturnValue: page.getPageIdToAdd,
                    isUpdatingAnchors: dsUtils.YES
                }),
                remove: publicMethodUtils.defineDataManipulationAction(pageRemoval.remove, {
                    isAsyncOperation: true,
                    asyncPreDataManipulation: pageRemoval.validatePageRemoval,
                    isUpdatingAnchors: true
                }),
                navigateTo: publicMethodUtils.defineDataManipulationAction(page.navigateTo, {
                    waitingForTransition: true,
                    noBatchingAfter: true,
                    shouldLogInteraction: true
                }),
                navigateToAndScroll: publicMethodUtils.defineDataManipulationAction(page.navigateToPageAndScrollToAnchor, {
                    waitingForTransition: true,
                    noBatchingAfter: true
                }),
                scrollToComponent: publicMethodUtils.defineDataManipulationAction(page.scrollToComponent, {
                    waitingForTransition: true,
                    noBatchingAfter: true
                }),
                isRemovable: publicMethodUtils.defineGetter(page.isRemovable),
                isDuplicable: publicMethodUtils.defineGetter(page.isDuplicable),
                isLandingPage: publicMethodUtils.defineGetter(page.isLandingPage),
                isContainsCompWithType: publicMethodUtils.defineGetter(page.isPageContainsCompWithType),
                isPageContainsTPAMultiSectionComp: publicMethodUtils.defineGetter(page.isPageContainsTPAMultiSectionComp),
                getReference: publicMethodUtils.defineGetter(page.getReference),
                getLayout: publicMethodUtils.defineGetter(page.getLayout),
                serialize: publicMethodUtils.defineGetter(page.serializePage),
                doesPageExist: publicMethodUtils.defineGetter(pageData.doesPageExist),
                getStaticPagesCount: publicMethodUtils.defineGetter(pageData.getStaticPagesCount),
                isWidgetPage: publicMethodUtils.defineGetter(appStudio.widgets.isWidgetPage),
                getRootNavigationInfo: publicMethodUtils.defineGetter(page.getRootNavigationInfo),
                data: {
                    update: publicMethodUtils.defineDataManipulationAction(page.data.update), //should fix implementation
                    get: publicMethodUtils.defineGetter(page.data.get),
                    pageUriSEO: {
                        isDuplicate: publicMethodUtils.defineGetter(pageData.isDuplicatePageUriSeo),
                        isForbidden: publicMethodUtils.defineGetter(pageData.isForbiddenPageUriSeo),
                        isValid: publicMethodUtils.defineGetter(pageData.isValidPageUriSeo),
                        getValid: publicMethodUtils.defineGetter(pageData.getValidPageUriSEO),
                        convertPageNameToUrl: publicMethodUtils.defineGetter(pageData.convertPageNameToUrl)
                    }
                },
                style: {
                    connectToTheme: publicMethodUtils.defineDataManipulationAction(page.style.connectToThemeStyle),
                    getId: publicMethodUtils.defineGetter(page.style.getId)
                },
                background: {
                    get: publicMethodUtils.defineGetter(page.background.get),
                    update: publicMethodUtils.defineDataManipulationAction(page.background.update)
                },
                anchors: {
                    getPageAnchors: publicMethodUtils.defineGetter(page.getPageAnchors)
                },
                /** @deprecated */
                getCurrentPageId: publicMethodUtils.defineGetter((ps: PS) => ps.siteAPI.getPrimaryPageId()),

                /** @deprecated */
                getCurrentPage: publicMethodUtils.defineGetter((ps: PS) => {
                    const pageId = ps.siteAPI.getPrimaryPageId()
                    return page.getPage(ps, pageId)
                }),

                getMasterPageId: publicMethodUtils.defineGetter(() => 'masterPage'),
                getFocusedPageId: publicMethodUtils.defineGetter((ps: PS) => ps.siteAPI.getFocusedRootId()),
                getFocusedPage: publicMethodUtils.defineGetter((ps: PS) => {
                    const pageId = ps.siteAPI.getFocusedRootId()
                    return page.getPage(ps, pageId)
                }),
                getPrimaryPageId: publicMethodUtils.defineGetter((ps: PS) => ps.siteAPI.getPrimaryPageId()),
                getCurrentUrlPageId: publicMethodUtils.defineGetter((ps: PS) => ps.siteAPI.getCurrentUrlPageId()),
                getPageBottomByComponents: publicMethodUtils.defineGetter((ps: PS, pageId: string) => page.getPageBottomByComponents(ps, pageId)),

                getSocialUrl: publicMethodUtils.defineGetter(page.getSocialUrl),
                getPageUrl: publicMethodUtils.defineGetter(page.getPageUrl),
                getPageTitle: publicMethodUtils.defineGetter(page.getPageTitle),
                getPagesData: publicMethodUtils.defineGetter(page.getPagesDataItems),
                getPageIdList: publicMethodUtils.defineGetter(page.getPageIdList), ///???
                permissions: {
                    updatePassword: publicMethodUtils.defineAction(page.permissions.updatePassword),
                    removePassword: publicMethodUtils.defineAction(page.permissions.removePassword),
                    hasPassword: publicMethodUtils.defineGetter(page.permissions.hasPassword),
                    isPagesProtectionOnServerOn: publicMethodUtils.defineGetter(page.permissions.isPagesProtectionOnServerOn),
                    duplicatePagePassword: publicMethodUtils.defineAction(page.permissions.duplicatePagePassword)
                },
                properties: {
                    get: publicMethodUtils.defineGetter(page.properties.get),
                    update: publicMethodUtils.defineDataManipulationAction(page.properties.update)
                },
                popupPages: {
                    add: publicMethodUtils.defineDataManipulationAction(page.popupPages.add, {
                        getReturnValue: page.popupPages.getPopupIdToAdd
                    }),
                    duplicate: publicMethodUtils.defineDataManipulationAction(page.duplicate, {
                        getReturnValue: page.popupPages.getPopupIdToAdd,
                        isUpdatingAnchors: true
                    }),
                    open: publicMethodUtils.defineDataManipulationAction(page.popupPages.open, {
                        waitingForTransition: true,
                        noBatchingAfter: true
                    }),
                    close: publicMethodUtils.defineDataManipulationAction(page.popupPages.close, {
                        waitingForTransition: true,
                        noBatchingAfter: true
                    }),
                    getCurrentPopupId: publicMethodUtils.defineGetter(page.popupPages.getCurrentPopupId),
                    getDataList: publicMethodUtils.defineGetter(page.popupPages.getDataList),
                    isPopup: publicMethodUtils.defineGetter(page.popupPages.isPopup),
                    isPopupOpened: publicMethodUtils.defineGetter(page.popupPages.isPopupOpened)
                },
                getPagesStatus: publicMethodUtils.defineGetter(page.getPagesStatus)
            }
        }
    }
}
