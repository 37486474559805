define([], function () {
    'use strict'

    const createRegistrar = () => {
        let nicknameContextProvider

        const setContextProvider = provider => {
            nicknameContextProvider = provider
        }

        const getNicknameContext = (ps, compPointer) => (nicknameContextProvider ? nicknameContextProvider(ps, compPointer) : null)

        return {
            setContextProvider,
            getNicknameContext
        }
    }

    return {
        createRegistrar
    }
})
