import * as _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import * as mergeUtils from './mergeUtils'
import {preProcessStructureMinimal} from '../structurePreprocessor'
import {objectUtils} from '@wix/santa-core-utils'
import {conversionConfig} from '../conversionConfig'
import {isMobileOnlyComponent} from '../mobileOnlyComponents/mobileOnlyComponentsUtils'
import {getChildren} from '../conversionUtils'
import {ComponentWithConversionData, MasterPageComponentWithConversionData, Component, MasterPageComponent, ObjMap} from '../../types'

function cutComponentChildren(components: ComponentWithConversionData[]): ComponentWithConversionData[] {
    return <ComponentWithConversionData[]>_.map(components, component => {
        const componentClone = objectUtils.cloneDeep(component)
        if (conversionUtils.getChildren(componentClone)) {
            componentClone.components = []
        }
        return componentClone
    })
}

function getComponentsByIdsFromStructure(
    componentsIds: string[],
    structure: ComponentWithConversionData | MasterPageComponentWithConversionData
): ComponentWithConversionData[] {
    return <ComponentWithConversionData[]>_(componentsIds)
        .map(compId => conversionUtils.getComponentByIdFromStructure(compId, structure))
        .compact()
        .value()
}

function convertComponentsIdsToComponentsTrees(componentsIds: string[], container: ComponentWithConversionData): ComponentWithConversionData[] {
    const components = getComponentsByIdsFromStructure(componentsIds, container)
    const flattenedComponents = cutComponentChildren(components)
    const componentForest = []
    _.forEach(flattenedComponents, flatComponent => {
        for (let j = 0; j < flattenedComponents.length; j++) {
            const potentialParentWithChildrenInfo = components[j]
            if (
                _.some(conversionUtils.getChildren(potentialParentWithChildrenInfo), {
                    id: flatComponent.id
                })
            ) {
                const potentialParent = flattenedComponents[j]
                conversionUtils.addComponentsTo(potentialParent, [flatComponent])
                return
            }
        }
        componentForest.push(flatComponent)
    })
    return componentForest
}

function findComponentsParents(
    desktopParentComponent: ComponentWithConversionData | MasterPageComponentWithConversionData,
    mobilePage: Component | MasterPageComponent,
    componentForestToBeAdded,
    parentsMap = {}
) {
    const desktopChildren = conversionUtils.getChildren(desktopParentComponent)
    if (!desktopChildren) {
        return parentsMap
    }
    _.forEach(desktopChildren, (desktopChild: ComponentWithConversionData) => {
        findComponentsParents(desktopChild, mobilePage, componentForestToBeAdded, parentsMap)
        const componentToAdd = _(componentForestToBeAdded).remove({id: desktopChild.id}).head()
        if (_.isEmpty(componentToAdd)) {
            return
        }
        const mobileParent = conversionUtils.getComponentByIdFromStructure(desktopParentComponent.id, mobilePage) || mobilePage
        parentsMap[mobileParent.id] = parentsMap[mobileParent.id] || {
            componentsToAdd: [],
            parent: mobileParent
        }
        parentsMap[mobileParent.id].componentsToAdd.push(componentToAdd)
    })

    return parentsMap
}

function getDesktopComponentsOfPage(desktopPage: Component | MasterPageComponent): ObjMap<ComponentWithConversionData> {
    return <ObjMap<ComponentWithConversionData>>conversionUtils.getAllCompsInStructure(desktopPage, false, _.negate(conversionUtils.isDesktopOnlyComponent))
}

function getMobileComponentsOfPage(mobilePage: Component | MasterPageComponent): ObjMap<Component> {
    return conversionUtils.getAllCompsInStructure(mobilePage, false, _.negate(isMobileOnlyComponent))
}

function getComponentIdsToDelete(
    componentIdsShouldBeInMobile: string[],
    allDesktopComponents: ObjMap<ComponentWithConversionData>,
    allMobileComponents: ObjMap<Component>
): string[] {
    const currentMobileComponentIds = _.keys(allMobileComponents)
    const hiddenAndDeletedComponentIds = _.difference(currentMobileComponentIds, componentIdsShouldBeInMobile)
    const replacedDesktopComponentIds = mergeUtils.getReplacedCompIds(allDesktopComponents, allMobileComponents)

    return _.uniq([...hiddenAndDeletedComponentIds, ...replacedDesktopComponentIds])
}

function deleteComponents(component: Component | MasterPageComponent, idsOfComponentsToDelete: string[]): void {
    if (_.isEmpty(idsOfComponentsToDelete)) {
        return
    }
    const children = <Component[]>conversionUtils.getChildren(component)
    if (!children) {
        return
    }
    const childrenIds = _.map(children, 'id')
    const idsOfComponentsToDeleteFromCurrentComponent = _.remove(idsOfComponentsToDelete, idToDelete => _.includes(childrenIds, idToDelete))
    const componentsToDeleteFromCurrentComponent = conversionUtils.getComponentsByIds(component, idsOfComponentsToDeleteFromCurrentComponent)
    if (componentsToDeleteFromCurrentComponent.length > 0) {
        conversionUtils.removeChildrenFrom(component, componentsToDeleteFromCurrentComponent)
    }
    _.forEach(children, child => deleteComponents(child, idsOfComponentsToDelete))
}

function containsComponent(compId: string, container: ComponentWithConversionData | MasterPageComponentWithConversionData): boolean {
    return _.some(getChildren(container), (child: ComponentWithConversionData) => child.id === compId)
}

function getComponentParent(
    compId: string,
    compMap: ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>
): ComponentWithConversionData | MasterPageComponentWithConversionData {
    return _.find(compMap, container => containsComponent(compId, container))
}

function reorderAddedComponentsAccordingToDesktop(
    idsOfComponentsToAdd: string[],
    mobilePage: ComponentWithConversionData | MasterPageComponentWithConversionData,
    desktopPage: ComponentWithConversionData | MasterPageComponentWithConversionData
): void {
    const mobileComps = <ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>>conversionUtils.getAllCompsInStructure(mobilePage)
    const desktopComps = <ObjMap<ComponentWithConversionData | MasterPageComponentWithConversionData>>conversionUtils.getAllCompsInStructure(desktopPage)
    const compIdsToSort = _.filter(idsOfComponentsToAdd, compId => _.has(mobileComps, compId))
    _.forEach(compIdsToSort, compId => {
        const mobileParent = getComponentParent(compId, mobileComps)
        const desktopParent = getComponentParent(compId, desktopComps)
        if (mobileParent.id === desktopParent.id) {
            const mobileChildren = getChildren(mobileParent)
            const potentialOrder = _.filter(getChildren(desktopParent), comp => containsComponent(comp.id, mobileParent))
            const compIdToInsertBefore = _.get(potentialOrder, [_.findIndex(potentialOrder, {id: compId}) + 1, 'id'], null)
            if (compIdToInsertBefore) {
                const mobileCompToBeOrdered = _.remove(mobileChildren, {id: compId})
                mobileChildren.splice(_.findIndex(mobileChildren, {id: compIdToInsertBefore}), 0, _.head(mobileCompToBeOrdered))
            }
        }
    })
}

function addComponents(mobilePage, desktopPage, idsOfComponentsToAdd): void {
    if (_.isEmpty(idsOfComponentsToAdd)) {
        return
    }
    const componentTreesToBeAdded = convertComponentsIdsToComponentsTrees(idsOfComponentsToAdd, desktopPage)
    const componentTreesWithParents = findComponentsParents(desktopPage, mobilePage, componentTreesToBeAdded)
    _.forEach(
        componentTreesWithParents,
        (componentTreesWithParent: {
            componentsToAdd: ComponentWithConversionData[]
            parent: ComponentWithConversionData | MasterPageComponentWithConversionData
        }) => {
            const treesRoots = objectUtils.cloneDeep(componentTreesWithParent.componentsToAdd)
            const treesComponents = <ComponentWithConversionData[]>_.flatMap(treesRoots, root => _.values(conversionUtils.getAllCompsInStructure(root)))
            _.forEach(treesComponents, comp => {
                if (conversionUtils.shouldStretchToScreenWidth(comp)) {
                    return _.set(comp, ['layout', 'width'], conversionConfig.MOBILE_WIDTH)
                }
                return _.set(comp, ['layout', 'width'], _.min([comp.layout.width, conversionConfig.MOBILE_WIDTH]))
            })
            conversionUtils.addComponentsTo(componentTreesWithParent.parent, treesRoots)
        }
    )
    reorderAddedComponentsAccordingToDesktop(idsOfComponentsToAdd, mobilePage, desktopPage)
}

function getDescendantComponentsIds(ancestorIds: string[], page: ComponentWithConversionData | MasterPageComponentWithConversionData): string[] {
    const ancestorComponents = getComponentsByIdsFromStructure(ancestorIds, page)
    return <string[]>_(ancestorComponents)
        .flatMap(comp => _.values(conversionUtils.getAllCompsInStructure(comp)))
        .map('id')
        .uniq()
        .difference(ancestorIds)
        .value()
}

function syncPage(desktopPage: ComponentWithConversionData | MasterPageComponentWithConversionData, mobilePage: Component | MasterPageComponent) {
    const desktopComponents = getDesktopComponentsOfPage(desktopPage)
    preProcessStructureMinimal(desktopPage, desktopComponents)
    const componentIdsShouldBeInMobile = <string[]>_(desktopComponents).reject(['conversionData.mobileHints.hidden', true]).map('id').value()

    // remove from mobile all deleted from desktop, hidden components, and components with replaced ids
    const componentIdsToDelete = getComponentIdsToDelete(componentIdsShouldBeInMobile, desktopComponents, getMobileComponentsOfPage(mobilePage))
    deleteComponents(mobilePage, componentIdsToDelete)

    // remove from mobile descendants of components that are going to be re-added since descendants could be re-parented in previous mobile structure
    const componentIdsToAdd = _.difference(componentIdsShouldBeInMobile, _.keys(getMobileComponentsOfPage(mobilePage)))
    const descendantsOfComponentsToAdd = getDescendantComponentsIds(componentIdsToAdd, desktopPage)
    deleteComponents(mobilePage, descendantsOfComponentsToAdd)

    // add visible desktop components that are not in cleaned mobile structure
    const componentIdsToBeAddedIncludingDescendants = _.difference(componentIdsShouldBeInMobile, _.keys(getMobileComponentsOfPage(mobilePage)))
    addComponents(mobilePage, desktopPage, componentIdsToBeAddedIncludingDescendants)
}

const testAPI = {
    convertComponentsIdsToComponentsTrees,
    findComponentsParents,
    deleteComponents,
    addComponents,
    getComponentIdsToDelete
}

export {testAPI, syncPage}
