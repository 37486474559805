import _ from 'lodash'
import wixCodeUserScriptsService from '../services/wixCodeUserScriptsService'
import hostWorkerInit from '@wix/santa-host-platform-services/dist/host-worker-init'

const constants = hostWorkerInit.constants.WIX_CODE

function getWixCodeWidgetsExtraData(messageData, widget, wixCodeModel, wixCodeSpec, siteData) {
    if (
        messageData.type === 'load_widgets' ||
        messageData.type === 'load_user_code' ||
        messageData.type === constants.MESSAGE_TYPE.UPDATE_WIX_CODE_MODEL_DATA_AFTER_LOGIN
    ) {
        const extraData = {
            appConfig: {
                gridAppId: wixCodeModel.appData.codeAppId,
                instance: _.get(siteData.getAppInstance(), [wixCodeSpec.applicationId]) || wixCodeSpec.instance
            }
        }
        if (widget.type !== 'Application') {
            // @ts-ignore
            extraData.appConfig.userScript = wixCodeUserScriptsService.getUserScript(widget, wixCodeModel, wixCodeSpec, siteData)
        }
        return extraData
    }
}

function shouldExtandData(widgetData) {
    return widgetData.type !== 'Application' || widgetData.id === 'dataBinding'
}

function getExtendedMessage(messageData, wixCodeModel, wixCodeSpec, siteData) {
    // This function gets additional params for the wix code message.
    // When we have more apps, we will write a generic appConfig mechanism
    // and this code will be migrated to that.

    const extension = {
        intent: constants.MESSAGE_INTENT.WIX_CODE_MESSAGE
    }

    if (wixCodeSpec && messageData.widgets) {
        messageData.widgets = _.map(messageData.widgets, function (widgetData) {
            return shouldExtandData(widgetData)
                ? _.assign({}, widgetData, getWixCodeWidgetsExtraData(messageData, widgetData, wixCodeModel, wixCodeSpec, siteData))
                : widgetData
        })
    }

    return _.defaults({}, messageData, extension)
}

export default {
    getExtendedMessage
}
