define([
    'lodash',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/siteMetadata/clientSpecMap',
    'documentServices/tpa/utils/tpaUtils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/platform/services/platformStateService',
    'documentServices/platform/common/constants'
], function (_, metaData, clientSpecMapDS, tpaUtils, componentDetectorAPI, component, platformStateService, constants) {
    'use strict'

    let isDevMode = false
    let clientSpecMapReadyOnLoad = false
    const callbacksForClientSpecMapReadyOnLoad = []

    const isPremiumApp = function (privateServices, applicationId) {
        const appData = clientSpecMapDS.getAppData(privateServices, applicationId)
        return !!appData && !!appData.vendorProductId
    }

    const hasPremiumOffering = function (privateServices, applicationId) {
        const appData = clientSpecMapDS.getAppData(privateServices, applicationId)
        return !!appData && _.size(appData.vendorProducts) > 0
    }

    const setAppsData = function (privateServices, newClientSpecMap) {
        metaData.setProperty(privateServices, metaData.PROPERTY_NAMES.CLIENT_SPEC_MAP, newClientSpecMap)
    }

    const getLargestApplicationId = function (clientSpecMap) {
        const toInt = x => parseInt(x, 10)
        return _(clientSpecMap).keys().map(toInt).compact().concat(0).max()
    }

    const filterApps = function (csm) {
        return _.pickBy(csm, appData => tpaUtils.isTpaByAppType(appData.type) && (_.isUndefined(appData.appType) || isEditorOrHybridApp(appData)))
    }

    const getWidgetData = function (privateServices, applicationId, widgetId) {
        const widgets = clientSpecMapDS.getAppData(privateServices, applicationId).widgets || {}
        return widgets[widgetId] || null
    }

    const isWidgetPublished = function (widget) {
        const isPublishedInNewAndOldEditor = widget.published
        const isPublishedOnlyInNewEditor = widget.santaEditorPublished
        return isPublishedInNewAndOldEditor || isPublishedOnlyInNewEditor
    }

    const getExtensionsWidgets = function (privateServices, appData) {
        let widgets = appData && appData.widgets
        widgets = _.filter(widgets, widget => _.isNil(widget.appPage))

        if (!isDevMode) {
            widgets = _.filter(widgets, isWidgetPublished)
        }
        return widgets
    }

    const getAppSections = function (privateServices, appData) {
        let widgets = appData && appData.widgets
        widgets = _.filter(widgets, widget => !_.isNil(widget.appPage))

        if (!isDevMode) {
            widgets = _.filter(widgets, isWidgetPublished)
        }
        return widgets
    }

    const getAppSectionsToInstall = function (ps, applicationId) {
        const appData = clientSpecMapDS.getAppData(ps, applicationId)
        let sections = getAppSections(ps, appData)
        sections = _.filter(sections, {autoAddToSite: true})
        return _.sortBy(sections, 'appPage.order')
    }

    const getAppWorkerUrl = (ps, applicationId) => {
        const appData = clientSpecMapDS.getAppData(ps, applicationId)
        return _.get(appData, 'appWorkerUrl')
    }

    const isPageMarkedAsAutoAddToSite = function (ps, pageData) {
        const appData = clientSpecMapDS.getAppData(ps, _.get(pageData, 'tpaApplicationId'))
        const sections = getAppSections(ps, appData)
        const widgetData = _.find(sections, {appPage: {id: _.get(pageData, 'tpaPageId')}})
        return _.get(widgetData, 'autoAddToSite')
    }

    const isPageMarkedAsShouldDeleteAppWhenDeleted = function (ps, pageData) {
        const appData = clientSpecMapDS.getAppData(ps, _.get(pageData, 'tpaApplicationId'))
        const sections = getAppSections(ps, appData)
        const widgetData = _.find(sections, {appPage: {id: _.get(pageData, 'tpaPageId')}})
        return _.get(widgetData, 'componentFields.shouldDeleteAppWhenDeleted')
    }

    const hasSections = function (privateServices, appData) {
        const appSections = getAppSections(privateServices, appData)
        return !_.isEmpty(appSections)
    }

    const isHybridApp = function (privateServices, applicationId) {
        const appData = applicationId && clientSpecMapDS.getAppData(privateServices, applicationId)
        return isHybridAppFromAppData(appData)
    }

    const isHybridAppFromAppData = function (appData) {
        if (_.isUndefined(appData) || _.isUndefined(appData.appType)) {
            return false
        }

        return appData.appType === 'Hybrid'
    }

    const isHybridAppAndEditorPartNotDismissed = function (appData) {
        const isHybrid = isHybridAppFromAppData(appData)
        return isHybrid && (_.isUndefined(appData.editorPartDismissed) || appData.editorPartDismissed === false)
    }

    const isDashboardAppOnly = function (appData) {
        if (_.isUndefined(appData) || _.isUndefined(appData.appType)) {
            return false
        }

        return appData.appType === 'Dashboard'
    }

    // @ts-ignore
    const isEditorOrHybridApp = ({appType} = {}) => appType === 'Hybrid' || appType === 'Editor'

    const isEditorApp = ({appType}) => appType === 'Editor'

    const isAppPermissionsIsRevoked = function (appData, appsState) {
        const isRevokedByCSM = appData?.permissions?.revoked === true
        const isUnusedApp = appsState?.[appData?.appDefinitionId]?.unused === true

        return isRevokedByCSM || isUnusedApp
    }

    const isAppActive = function (ps, appData) {
        const appDefId = _.get(appData, 'appDefinitionId')
        const appPendingAction = appDefId ? platformStateService.getAppPendingAction(ps, appDefId) : null

        return (
            !_.isUndefined(appData) &&
            !isAppPermissionsIsRevoked(appData, platformStateService.getAppsState(ps)) &&
            appPendingAction !== constants.APP_ACTION_TYPES.REMOVE &&
            !appData.requiresEditorComponent
        )
    }

    const isAppAutoRevoked = function (appData) {
        return !_.has(appData, 'appFields.platform.studio')
    }

    const isAppPermissionsIsGranted = function (appData, appsState) {
        const isGrantedByCSM = appData?.permissions?.revoked === false
        const isAppUsed = appsState?.[appData?.appDefinitionId]?.unused !== true

        return isGrantedByCSM && isAppUsed
    }

    const hasHiddenPages = function (ps, applicationId) {
        const appData = clientSpecMapDS.getAppData(ps, applicationId)
        const sections = getAppSections(ps, appData)
        const hiddenSection = _.filter(sections, 'appPage.hidden')
        return _.size(hiddenSection) > 0
    }

    const hasMainSection = function (ps, appData) {
        const sections = getAppSections(ps, appData)
        return _.some(sections, app => !app.appPage.hidden)
    }

    const getMainSectionWidgetData = function (ps, appData) {
        const sections = getAppSections(ps, appData)
        return _.find(sections, app => !app.appPage.hidden)
    }

    const getMainSectionWidgetDataFromApplicationId = function (ps, applicationId) {
        return getMainSectionWidgetData(ps, clientSpecMapDS.getAppData(ps, applicationId))
    }

    const widgetsToAutoAddToSite = function (ps, appData) {
        const widgets = getExtensionsWidgets(ps, appData)
        return _.filter(widgets, 'autoAddToSite')
    }

    const getWidgetDataFromTPAPageId = function (ps, appDefinitionId, pageId) {
        const sections = getAppSections(ps, clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
        return _.find(sections, {appPage: {id: pageId}})
    }

    const getWidgetDataFromTPAWidgetId = function (ps, appDefinitionId, tpaWidgetId) {
        const widgets = getExtensionsWidgets(ps, clientSpecMapDS.getAppDataByAppDefinitionId(ps, appDefinitionId))
        return _.find(widgets, {tpaWidgetId})
    }

    const setIsInDevMode = function (isInDevMode) {
        isDevMode = isInDevMode
    }

    const isWidgetHasMobileUrl = function (ps, applicationId, widgetId) {
        if (!applicationId || !widgetId) {
            return false
        }

        const widgetData = getWidgetData(ps, applicationId, widgetId)
        if (widgetData && widgetData.mobileUrl) {
            const {mobileUrl} = widgetData
            const isPublished = widgetData.mobilePublished && _.isBoolean(widgetData.mobilePublished) && widgetData.mobilePublished
            return !_.isEmpty(mobileUrl) && (isDevMode || isPublished)
        }

        return false
    }

    const isAppProvisionedOnServer = function (ps, applicationId) {
        const appData = clientSpecMapDS.getAppData(ps, applicationId)

        return !appData.notProvisioned
    }

    const getSectionsWidgetIdsToPreFetch = function (appData) {
        return _(appData.widgets).filter('preFetch').filter('appPage').filter(isWidgetPublished).map('widgetId').value()
    }

    const isDemoAppAfterProvision = function (appData) {
        const instance = _.get(appData, 'instance')
        if (!_.isEmpty(instance)) {
            const instanceParts = appData.instance.split('.')
            const instanceValues = JSON.parse(atob(instanceParts[1]))
            return !_.isEmpty(instanceValues.originInstanceId)
        }
    }

    const isSuperAppByCompId = function (ps, compId) {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        const compData = component.data.get(ps, compPointer)
        const applicationId = _.get(compData, 'applicationId')
        const appData = clientSpecMapDS.getAppData(ps, applicationId)
        return appData.isWixTPA
    }

    const getDependentApps = function () {
        //temporarily hard coded until a proper mechanism will exist in devCenter
        return []
    }

    const hasEditorPlatformPart = function (appData) {
        const editorPlatformScript = _.get(appData, 'appFields.platform.editorScriptUrl')
        return !!editorPlatformScript
    }

    const hasViewerPlatformPart = function (appData) {
        const viewerScriptUrl = _.get(appData, 'appFields.platform.viewerScriptUrl')
        return !!viewerScriptUrl
    }

    const computeHidePage = function (widgetData, isHidden, defaultValue) {
        let hidePage = _.defaultTo(isHidden, _.get(widgetData, 'componentFields.hiddenInPagesPanel'))
        hidePage = _.defaultTo(hidePage, defaultValue)
        return hidePage
    }

    const isMultiSectionInstanceEnabled = function (appData, widgetId) {
        if (!widgetId) {
            return false
        }
        const widgetData = _.get(appData, ['widgets', widgetId])

        return _.get(widgetData, 'appPage.multiInstanceEnabled')
    }

    const setClientSpecMapReadyOnLoad = ps => {
        clientSpecMapReadyOnLoad = true
        _.forEach(callbacksForClientSpecMapReadyOnLoad, cb => cb(ps))
    }

    const registerToClientSpecMapOnLoad = (ps, callback) => {
        if (clientSpecMapReadyOnLoad) {
            callback(ps)
        }
        callbacksForClientSpecMapReadyOnLoad.push(callback)
    }

    return {
        // TODO: remove duplicates of clientSpecMapDS methods
        registerAppData: clientSpecMapDS.registerAppData,
        getAppData: clientSpecMapDS.getAppData,
        getAppDataByAppDefinitionId: clientSpecMapDS.getAppDataByAppDefinitionId,
        getAppsData: clientSpecMapDS.getAppsData,
        getAppsDataWithPredicate: clientSpecMapDS.getAppsDataWithPredicate,
        filterAppsDataByType: clientSpecMapDS.filterAppsDataByType,
        filterApps,
        getLargestApplicationId,
        getWidgetData,
        getAppWorkerUrl,
        getExtensionsWidgets,
        isHybridAppAndEditorPartNotDismissed,
        isHybridApp,
        isHybridAppFromAppData,
        isDashboardAppOnly,
        isEditorOrHybridApp,
        isEditorApp,
        setAppsData,
        isAppPermissionsIsRevoked,
        isAppActive,
        isAppAutoRevoked,
        isPremiumApp,
        hasPremiumOffering,
        hasHiddenPages,
        getAppSections,
        getAppSectionsToInstall,
        widgetsToAutoAddToSite,
        getWidgetDataFromTPAPageId,
        getWidgetDataFromTPAWidgetId,
        setIsInDevMode,
        isWidgetHasMobileUrl,
        isAppPermissionsIsGranted,
        hasSections,
        isAppProvisionedOnServer,
        hasMainSection,
        getMainSectionWidgetData,
        getMainSectionWidgetDataFromApplicationId,
        getSectionsWidgetIdsToPreFetch,
        isDemoAppAfterProvision,
        isSuperAppByCompId,
        getDependentApps,
        hasEditorPlatformPart,
        hasViewerPlatformPart,
        isPageMarkedAsAutoAddToSite,
        isPageMarkedAsShouldDeleteAppWhenDeleted,
        computeHidePage,
        isMultiSectionInstanceEnabled,
        setClientSpecMapReadyOnLoad,
        registerToClientSpecMapOnLoad
    }
})
