import _ from 'lodash'
import sitePages from '../utils/sitePages'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const privates = new coreUtils.SiteDataPrivates()

const mapPageToWidgets = function (siteAPI) {
    const privatesKey = siteAPI.getSiteData()
    if (!privates.has(privatesKey)) {
        privates.set(privatesKey, {})
    }
    const tpaToPageMap = privates.get(privatesKey)

    if (_.size(tpaToPageMap) > 0) {
        return tpaToPageMap
    }

    const pages = sitePages.getSitePagesData(siteAPI.getSiteData())
    _.forEach(pages, function (pageData) {
        if (pageData && pageData.tpaApplicationId > 0) {
            const data = {
                pageId: pageData.id,
                tpaId: pageData.tpaApplicationId,
                tpaPageId: pageData.tpaPageId
            }
            if (_.isUndefined(tpaToPageMap[data.tpaId])) {
                tpaToPageMap[data.tpaId] = []
            }
            tpaToPageMap[data.tpaId].push(data)
        }
    })

    return tpaToPageMap
}

export default {
    mapPageToWidgets
}
