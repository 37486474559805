define(['@wix/santa-core-utils', 'documentServices/siteMetadata/generalInfo'], function (santaCoreUtils, generalInfo) {
    'use strict'

    function getSiteMembersSettingsPointer(ps) {
        const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
        return ps.pointers.getInnerPointer(siteStructureDataPointer, 'smSettings')
    }

    function setSmSettingsData(ps, data, force = false) {
        const siteMembersSettingsPointer = getSiteMembersSettingsPointer(ps)
        if (ps.dal.isExist(siteMembersSettingsPointer)) {
            if (force) {
                ps.dal.merge(siteMembersSettingsPointer, data)
            }
        } else {
            ps.dal.set(siteMembersSettingsPointer, data)
        }
    }

    function exec(ps) {
        setSmSettingsData(
            ps,
            {
                socialLoginGoogleEnabled: true,
                socialLoginFacebookEnabled: true
            },
            generalInfo.isFirstSave(ps) || generalInfo.isDraft(ps)
        )
    }

    return {
        exec,
        name: 'siteMembersDataFixer',
        version: 1
    }
})
