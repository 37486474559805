import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const snapshotAPI = viewerLibrary.get('documentServices/snapshotAPI/snapshotAPI')
    return {
        methods: {
            snapshot: {
                getSnapshot: publicMethodUtils.defineGetter(snapshotAPI.getSnapshot)
            }
        }
    }
}
