define([
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/theme/common/constants',
    'documentServices/theme/common/themeDAL',
    'documentServices/theme/colors/colors'
], function (coreUtils, themeConstants, themeDAL, themeColors) {
    'use strict'

    const {PROPERTY_TYPE} = themeConstants
    const {textThemeToFontString} = coreUtils.fonts

    function getTextThemes(ps) {
        return themeDAL.getCollection(ps, PROPERTY_TYPE.TEXT_THEME)
    }

    function getAll(ps) {
        const textThemes = getTextThemes(ps)
        return coreUtils.fonts.getTextThemesMap(textThemes)
    }

    function get(ps, index) {
        return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index)
    }

    /**
     * We update both font and textTheme, since there are APIs in core-utils that uses font
     * so they won't return an old font string.
     * */
    function set(ps, index, val) {
        const fontString = textThemeToFontString(val)
        themeDAL.setCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index, val)
        themeDAL.setCollectionItem(ps, PROPERTY_TYPE.FONT, index, fontString)
    }

    function getStyles(ps) {
        const colors = themeColors.getAll(ps)
        const textThemes = getTextThemes(ps)

        return coreUtils.fonts.getTextThemesCss(textThemes, colors)
    }

    return {
        set,
        get,
        getAll,
        getStyles
    }
})
