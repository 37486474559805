define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/dataModel/dataIds',
    'documentServices/variants/relationsUtils',
    'documentServices/refComponent/refComponent',
    'documentServices/variants/variantsUtils'
], function (_, constants, dataModel, dataIds, relationsUtils, refComponent, variantsUtils) {
    'use strict'

    const {DATA_TYPES} = constants
    const {VALID_VARIANTS_DATA_TYPES, TYPES, SINGLE_VARIANT_PER_COMP_TYPES} = constants.VARIANTS
    const implicitApiAddTypes = [TYPES.STATE, TYPES.TRIGGER]

    const getComponentVariantsDataByType = (ps, compPointer, variantType) => {
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const compPointerToGet = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
        return dataModel.getVariantsDataByVariantType(ps, variantType, {compPointer: compPointerToGet})
    }

    const getAllAffectingVariantsGroupedByVariantType = (ps, componentPointer) =>
        relationsUtils.getAllAffectingVariantsGroupedByVariantType(ps, componentPointer)

    const getAllAffectingVariantsForPresets = (ps, componentPointer) => relationsUtils.getAllAffectingVariantsForDataType(ps, componentPointer, 'presets')

    const createVariantForComponent = (ps, variantToAddRef, compPointer, variantType, variantData) => {
        if (implicitApiAddTypes.includes(variantType)) {
            throw new Error(`Please use the public API to add variants of type ${variantType}`)
        }

        return createAnyVariantForComponent(ps, variantToAddRef, compPointer, variantType, variantData)
    }

    const createAnyVariantForComponent = (ps, variantToAddRef, compPointer, variantType, variantData) => {
        if (!ps || !variantType || !compPointer) {
            throw new Error('invalid args')
        }

        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const compPointerToAdd = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)

        if (variantToAddRef) {
            dataModel.createVariantData(ps, variantToAddRef, variantType, {compPointer: compPointerToAdd}, variantData)
        }
    }

    const getVariantToAddRef = (ps, compPointer, variantType) => {
        const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
        const compPointerToAdd = compPointer && ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
        const compVariantsByType = getComponentVariantsDataByType(ps, compPointerToAdd, variantType)

        if (_.includes(SINGLE_VARIANT_PER_COMP_TYPES, variantType) && !_.isEmpty(compVariantsByType)) {
            return null
        }

        const variantId = dataIds.generateNewId(DATA_TYPES.variants)
        return ps.pointers.data.getVariantsDataItem(variantId, pagePointer.id)
    }

    const removeVariants = (ps, variantsPointers) => {
        const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
        relationsUtils.removeScopedValuesByVariants(ps, variantsPointersArray)
    }

    const getCompnentsWithOverridesGroupedByType = (ps, variantsPointers = []) => {
        if (variantsPointers.length < 1) {
            return {}
        }
        const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
        const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointersArray))

        const result = {}

        _.forEach(VALID_VARIANTS_DATA_TYPES, itemType => {
            const relationsPointersByItemType = relationsUtils.getRelationsByVariantsAndPredicate(ps, variantsPointersArray, itemType).flat()
            const components = _.map(relationsPointersByItemType, relationPtr => {
                const relationData = ps.dal.get(relationPtr)
                return relationsUtils.getComponentFromRelation(ps, relationData, pageId)
            })
            if (components.length > 0) {
                result[itemType] = components
            }
        })

        return result
    }

    const hasOverrides = (ps, compPointerWithVariants, shouldCheckChildren = true) => {
        if (!ps.pointers.components.isWithVariants(compPointerWithVariants)) {
            return false
        }
        const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointerWithVariants)
        const compPointer = ps.pointers.full.components.getComponent(compPointerWithVariants.id, pagePointer)

        const rootCompId = compPointer.id
        const isRecursive =
            shouldCheckChildren && !!_.find(compPointerWithVariants.variants, variantPointer => ps.dal.get(variantPointer).componentId === rootCompId)

        const componentsWithOverrides = getCompnentsWithOverridesGroupedByType(ps, compPointerWithVariants.variants)
        const componentIdsThatMatchesVariants = _(componentsWithOverrides).values().flatten().map('id').uniq().value()

        const compHasOverrides = _compId => _.includes(componentIdsThatMatchesVariants, _compId)
        const descendantsHasOverrides =
            isRecursive && !_.isEmpty(componentIdsThatMatchesVariants)
                ? !!ps.pointers.components.findDescendant(compPointer, comp => compHasOverrides(comp.id))
                : false

        return compHasOverrides(rootCompId) || descendantsHasOverrides
    }

    const setToActiveVariantMap = (ps, variantsPointers, isUnset) => {
        const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
        const focusedPageId = ps.siteAPI.getFocusedRootId()
        const pagePointer = ps.pointers.components.getPage(focusedPageId, ps.siteAPI.getViewMode())

        variantsPointersArray.forEach(variantPointer => {
            const variantComponentId = ps.dal.get(variantPointer).componentId
            const valueToSet = isUnset ? undefined : variantPointer.id
            const compPointer = ps.pointers.full.components.getComponent(variantComponentId, pagePointer)
            const components = _.concat(ps.pointers.components.getAllDisplayedOnlyComponents(compPointer), refComponent.getReferredComponents(ps, compPointer))
            components.forEach(component => {
                const compActiveVariantPointer = ps.pointers.activeVariants.getActiveVariant(component.id)
                ps.dal.set(compActiveVariantPointer, valueToSet)
            })
        })
    }

    const enable = (ps, variantsPointers) => setToActiveVariantMap(ps, variantsPointers)

    const getComponentEnabledVariants = (ps, compPointerWithVariants) => {
        const activeVariantsPointer = ps.pointers.activeVariants.getActiveVariant(compPointerWithVariants.id)
        return ps.dal.get(activeVariantsPointer)
    }

    const disable = (ps, variantsPointers) => setToActiveVariantMap(ps, variantsPointers, true)

    const getHoverType = () => TYPES.HOVER
    const getMobileType = () => TYPES.MOBILE
    const getPresetType = () => TYPES.PRESET
    const getStateType = () => TYPES.STATE
    const getTriggerType = () => TYPES.TRIGGER
    const getBreakpointsDataType = () => TYPES.BREAKPOINTS

    return {
        getVariantToAddRef,
        getAllAffectingVariantsGroupedByVariantType,
        getAllAffectingVariantsForPresets,
        create: createVariantForComponent,
        createInternal: createAnyVariantForComponent,
        getByComponentAndType: getComponentVariantsDataByType,
        enable,
        getComponentEnabledVariants,
        disable,
        remove: removeVariants,
        getPointerWithVariants: variantsUtils.getPointerWithVariants,
        hasOverrides,
        getComponentsWithOverrides: getCompnentsWithOverridesGroupedByType,
        getHoverType,
        getPresetType,
        getMobileType,
        getStateType,
        getTriggerType,
        getBreakpointsDataType,
        getData: (ps, variantPointer) => dataModel.getDataByPointer(ps, DATA_TYPES.variants, variantPointer)
    }
})
