define(['lodash', '@wix/santa-ds-libs/src/coreUtils', 'experiment', 'documentServices/utils/contextAdapter', '@wix/document-manager-utils'], function (
    _,
    coreUtils,
    experiment,
    contextAdapter,
    documentManagerUtils
) {
    'use strict'

    const {ReportableError} = documentManagerUtils

    function getBoundingLayout(privateServices, layout) {
        return coreUtils.boundingLayout.getBoundingLayout(layout)
    }

    function getComponentLayout(ps, compPointer) {
        if (!compPointer) {
            return null
        }
        const layout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        if (!layout) {
            return null
        }
        if (layout.docked) {
            _.assign(layout, getPositionAndSize(ps, compPointer))
            delete layout.docked
        }

        return _.merge(layout, {bounding: getBoundingLayout(ps, layout)})
    }

    /**
     * This function is important for 2 reasons:
     * 1. We don't want to recursively get the width up the chain (i.e. our parent is docked left+right)
     * 2. We can't get our own width, since during batched updates in DS, the current value in the measureMap might not be correct (our JSON updated during batch but relayout is only at end of the batch), but the parent's value will still be correct
     *
     * @param {ps} ps
     * @param compPointer
     * @param compLayout
     * @returns {Number} Width of the parent, as it is currently rendered
     */
    function getParentWidth(ps, compPointer, compLayout) {
        if (compLayout.fixedPosition || coreUtils.dockUtils.isHorizontalDockToScreen(compLayout)) {
            return ps.siteAPI.getSiteMeasureMap().clientWidth
        }
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        return measureMap.width[parentPointer.id]
    }

    function getParentDimensions(ps, compPointer) {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        return {
            width: measureMap.width[parentPointer.id],
            height: measureMap.height[parentPointer.id]
        }
    }

    /**
     * This function is important for 2 reasons:
     * 1. We don't want to recursively get the height up the chain (i.e. our parent is docked top+bottom)
     * 2. We can't get our own height, since during batched updates in DS, the current value in the measureMap might not be correct (our JSON updated during batch but relayout is only at end of the batch), but the parent's value will still be correct
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param compLayout
     * @returns {Number} Height of the parent, as it is currently rendered
     */
    function getParentHeight(ps, compPointer, compLayout) {
        if (compLayout.fixedPosition) {
            return ps.siteAPI.getSiteMeasureMap().clientHeight
        }
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        return measureMap.height[parentPointer.id]
    }

    /**
     *
     * @param currentPositionAndSize
     * @param newPositionAndSize
     * @returns {PositionAndSize} the difference between the current PositionAndSize and the new one
     */
    function getPositionAndSizeDiff(currentPositionAndSize, newPositionAndSize) {
        return _.mapValues(currentPositionAndSize, function (value, key) {
            return _.isFinite(newPositionAndSize[key]) ? newPositionAndSize[key] - value : 0
        })
    }

    function isPctUnits(units) {
        return _.includes(['vw', 'pct'], units)
    }

    function applyDiffToUnitsData(dockData, diffInPx, parentInPx) {
        const dockUnits = _.keys(dockData)

        if (_.includes(dockUnits, 'px')) {
            dockData.px += diffInPx
        } else {
            const percentUnits = _.find(dockUnits, isPctUnits)
            dockData[percentUnits] += (diffInPx / parentInPx) * 100
            dockData[percentUnits] = parseFloat(dockData[percentUnits].toFixed(2))
        }
    }

    function updateHorizontalLayoutForDocked(compLayout, positionAndSizeDiff, parentWidth) {
        const {docked} = compLayout

        if (docked.left && positionAndSizeDiff.x) {
            applyDiffToUnitsData(docked.left, positionAndSizeDiff.x, parentWidth)
        }

        if (docked.right) {
            const rightDiff = (positionAndSizeDiff.x + positionAndSizeDiff.width) * -1
            if (rightDiff) {
                applyDiffToUnitsData(docked.right, rightDiff, parentWidth)
            }
        }

        if (docked.hCenter) {
            const centerDiff = positionAndSizeDiff.width / 2 + positionAndSizeDiff.x // eslint-disable-line no-mixed-operators
            if (centerDiff) {
                applyDiffToUnitsData(docked.hCenter, centerDiff, parentWidth)
            }
        }

        if (!(docked.left && docked.right)) {
            //if not horizontally stretched
            compLayout.width += positionAndSizeDiff.width
        }
    }

    function updateVerticalLayoutForDocked(compLayout, positionAndSizeDiff, parentHeight) {
        const {docked} = compLayout

        if (docked.top && positionAndSizeDiff.y) {
            applyDiffToUnitsData(docked.top, positionAndSizeDiff.y, parentHeight)
        }

        if (docked.bottom) {
            const diffBottom = (positionAndSizeDiff.y + positionAndSizeDiff.height) * -1
            if (diffBottom) {
                applyDiffToUnitsData(docked.bottom, diffBottom, parentHeight)
            }
        }

        if (docked.vCenter) {
            const centerDiff = positionAndSizeDiff.height / 2 + positionAndSizeDiff.y // eslint-disable-line no-mixed-operators
            if (centerDiff) {
                applyDiffToUnitsData(docked.vCenter, centerDiff, parentHeight)
            }
        }

        if (!(docked.top && docked.bottom)) {
            compLayout.height += positionAndSizeDiff.height
        }
    }

    /**
     * @param {ps} ps
     * @param compPointer
     * @param positionAndSize
     */
    function applyPositionAndSizeOnCurrentLayoutSchema(ps, compPointer, positionAndSize) {
        return componentLayout(ps, compPointer, positionAndSize).updateVerticalLayout().updateHorizontalLayout().value()
    }

    function componentLayout(ps, compPointer, positionAndSizeChanges) {
        return new ComponentLayoutBuilder(ps, compPointer, positionAndSizeChanges)
    }

    class ComponentLayoutBuilder {
        /**
         * @param {ps} ps
         * @param {Pointer} compPointer
         * @param positionAndSizeChanges
         * @constructor
         */
        constructor(ps, compPointer, positionAndSizeChanges) {
            this.ps = ps
            this.compPointer = compPointer
            const currentPositionAndSize = getPositionAndSize(ps, compPointer)
            this.newPositionAndSize = _.assign({}, currentPositionAndSize, positionAndSizeChanges)
            this.positionAndSizeDiff = getPositionAndSizeDiff(currentPositionAndSize, positionAndSizeChanges)
            this.compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        }

        value() {
            return this.compLayout
        }

        updateVerticalLayout() {
            if (this.positionAndSizeDiff.y === 0 && this.positionAndSizeDiff.height === 0) {
                return this
            }

            if (coreUtils.layoutUtils.isVerticallyDocked(this.compLayout) && !coreUtils.layoutUtils.isVerticallyStretchedToScreen(this.compLayout)) {
                const parentHeight = getParentHeight(this.ps, this.compPointer, this.compLayout)
                updateVerticalLayoutForDocked(this.compLayout, this.positionAndSizeDiff, parentHeight)
            } else {
                this.compLayout.y += this.positionAndSizeDiff.y
                this.compLayout.height += this.positionAndSizeDiff.height
            }

            return this
        }

        updateHorizontalLayout() {
            if (this.positionAndSizeDiff.x === 0 && this.positionAndSizeDiff.width === 0) {
                return this
            }

            if (coreUtils.layoutUtils.isHorizontallyDocked(this.compLayout)) {
                const parentWidth = getParentWidth(this.ps, this.compPointer, this.compLayout)
                updateHorizontalLayoutForDocked(this.compLayout, this.positionAndSizeDiff, parentWidth)
            } else {
                this.compLayout.x += this.positionAndSizeDiff.x
                this.compLayout.width += this.positionAndSizeDiff.width
            }

            return this
        }

        keepAspectRatioIfNeeded() {
            if (coreUtils.layoutUtils.isAspectRatioOn(this.compLayout)) {
                this.compLayout.aspectRatio = coreUtils.layoutUtils.calcAspectRatio(this.newPositionAndSize.width, this.newPositionAndSize.height)
            }

            return this
        }
    }

    /**
     *
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param [compLayout]
     * @returns {Object} the rendered position and size of the component, in pixels
     */
    function getPositionAndSize(ps, compPointer, compLayout) {
        const layout = compLayout || ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
        if (!layout.docked) {
            return _.pick(layout, ['x', 'y', 'width', 'height'])
        }

        const parentDimensions = getParentDimensions(ps, compPointer)
        const screenSize = ps.siteAPI.getScreenSize()

        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const rootPointer = ps.pointers.components.getPageOfComponent(compPointer)
        const siteWidth = coreUtils.layoutUtils.getRootWidth(measureMap, rootPointer.id, ps.siteAPI.getSiteWidth())

        const siteX = ps.siteAPI.getSiteX()
        const rootLeft = coreUtils.layoutUtils.getRootLeft(measureMap, rootPointer.id, siteX)

        const positionAndSize = coreUtils.positionAndSize.getPositionAndSize(layout, parentDimensions, screenSize, siteWidth, rootLeft)
        if (experiment.isOpen('dm_sendIllegalPositionAndSize') && positionAndSize.width < 0) {
            contextAdapter.utils.fedopsLogger.captureError(
                new ReportableError({
                    errorType: 'IllegalPositionAndSize',
                    message: 'Incorrect position and size',
                    extras: {
                        compPointer,
                        positionAndSize,
                        siteWidth,
                        parentDimensions,
                        screenSize,
                        docked: layout.docked,
                        componentType: ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
                    }
                })
            )
        }

        return positionAndSize
    }

    /**
     * @exports documentServices/structure/utils/componentLayout
     */
    return {
        /**
         *
         * @param {ps} ps
         * @param compPointer
         * @param positionAndSize
         */
        applyPositionAndSizeOnCurrentLayoutSchema,

        getBoundingLayout,
        getComponentLayout,
        getPositionAndSize
    }
})
