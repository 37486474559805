define([
    'documentServices/constants/constants',
    'documentServices/structure/siteGapMap',
    'documentServices/structure/utils/layoutSettingsUtils',
    'experiment'
], function (consts, siteGapMap, layoutSettingsUtils, experiment) {
    'use strict'

    return {
        anchors: {
            to: {allow: true, allowBottomBottom: false, lock: consts.ANCHORS.LOCK_CONDITION.THRESHOLD},
            from: {allow: true, lock: consts.ANCHORS.LOCK_CONDITION.ALWAYS}
        },
        resizableSides: [],
        moveDirections(ps) {
            const masterPageLayoutSettings = layoutSettingsUtils.getLayoutSettings(ps)

            if (masterPageLayoutSettings.useDesktopSectionsLayout) {
                return []
            }

            if (experiment.isOpen('sv_zeroGapsThreshold')) {
                return [consts.MOVE_DIRECTIONS.VERTICAL]
            }
            const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
            return initialSiteGapMap.abovePagesContainer >= consts.SITE_SEGMENTS_GAP_THRESHOLD ? [consts.MOVE_DIRECTIONS.VERTICAL] : []
        },
        removable: false,
        duplicatable: false,
        containable: false,
        fullWidth: false,
        hiddenable: false,
        collapsible: false,
        mobileConversionConfig: {
            marginX: 20,
            siteSegmentRole: 'PAGES_CONTAINER',
            category: 'siteSegments'
        }
    }
})
