define([
    'lodash',
    'documentServices/mobileUtilities/mobileUtilities',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/utils/contextAdapter',
    'experiment'
], function (_, mobileUtilities, extensionsAPI, contextAdapter, experiment) {
    'use strict'

    const {isMobileSupported} = mobileUtilities

    const validateIfNeeded = (ps, name, isAfter) => {
        if (!experiment.isOpen('dm_mobileAlgoValidationNoConduct') || !isMobileSupported(ps)) {
            return
        }

        const tags = {mobileFunction: name, afterMobileFunction: isAfter}
        contextAdapter.utils.fedopsLogger.interactionStarted('mobileAlgoValidation', {tags})
        extensionsAPI.validation.validateOpenTransactionAndReportOnly(ps, tags)
    }

    const wrap = (facade, exclusions) =>
        _.mapValues(facade, (facadeMethod, name) => {
            if (exclusions.includes(name)) {
                return facadeMethod
            }

            return _.wrap(facadeMethod, (func, ...args) => {
                const ps = args[0]

                validateIfNeeded(ps, name, false)
                const value = func(...args)
                validateIfNeeded(ps, name, true)

                return value
            })
        })

    return {
        wrap
    }
})
