import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import appPart from '@wix/santa-ds-libs/src/wixappsClassics/src/comps/appPart'
import santaComponents from '@wix/santa-components'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

const {createGlobalSantaTypesFetcher} = coreUtils.propsSelectorsUtils
const {createWixappsComponentFetcher} = wixapps.wixappsSantaTypesUtil

const {wixappsClassicsLogger} = coreUtils

function getDataLocations(state, props) {
    const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect')
    const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props)
    const compData = getCompData(state, props)
    return dataAspect.getDataByCompId(packageName, compData.id)
}

function touchPath(displayDal, counterDataPointer) {
    if (!displayDal.isExist(counterDataPointer)) {
        displayDal.set(counterDataPointer, 0)
    }
    displayDal.get(counterDataPointer)
}

function touchRenderCounter(state, props, dataLocations?) {
    dataLocations = dataLocations || getDataLocations(state, props)
    const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props)

    const displayDal = state.siteAPI.getDisplayedDAL()
    const pointers = state.siteAPI.getPointers()
    const packageCountersPointer = pointers.wixapps.getPackageCounterData(packageName)
    if (!displayDal.isExist(packageCountersPointer)) {
        displayDal.set(packageCountersPointer, {})
    }
    const packageCounterDataPointer = pointers.wixapps.getCounterData(packageName)
    touchPath(displayDal, packageCounterDataPointer)

    if (dataLocations) {
        // convert Array<String> to Array<Array<String>>
        if (_.isString(dataLocations[0])) {
            dataLocations = [dataLocations]
        }

        // Touch each root location
        _(dataLocations)
            .map('0')
            .uniq()
            .forEach(function (dataLocation) {
                const counterDataPointer = pointers.wixapps.getCounterData(packageName, dataLocation)
                touchPath(displayDal, counterDataPointer)
            })
    }
}

function getCompData(state, props) {
    let compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props)
    if (compData.type === 'PermaLink') {
        compData = compData.dataItemRef
    }
    return compData
}

const classicsReportEvent = createGlobalSantaTypesFetcher(function (state) {
    return wixappsClassicsLogger.reportEvent.bind(wixappsClassicsLogger, state.siteData)
}, 'wixappsClassics.reportEvent')

const classicReportError = createGlobalSantaTypesFetcher(function (state) {
    return wixappsClassicsLogger.reportError.bind(wixappsClassicsLogger, state.siteData)
}, 'wixappsClassics.reportError')

const reportAppPartMetadataError = createGlobalSantaTypesFetcher(function (state) {
    return wixappsClassicsLogger.reportAppPartMetadataError.bind(wixappsClassicsLogger, state.siteData)
}, 'wixappsClassics.reportAppPartMetadataError')

const reportFirstTimeRenderStart = createGlobalSantaTypesFetcher(function (state) {
    return wixappsClassicsLogger.reportFirstTimeRenderStart.bind(wixappsClassicsLogger, state.siteData)
}, 'wixappsClassics.reportFirstTimeRenderStart')

const reportFirstTimeRenderFinish = createGlobalSantaTypesFetcher(function (state) {
    return wixappsClassicsLogger.reportFirstTimeRenderFinish.bind(wixappsClassicsLogger, state.siteData)
}, 'wixappsClassics.reportFirstTimeRenderFinish')

const partDefinition = createWixappsComponentFetcher(function (state, props) {
    touchRenderCounter(state, props)
    const compData = getCompData(state, props)
    const descriptor = state.fetchSantaType(wixapps.CoreSantaTypes.descriptor, state, props)

    if (!descriptor) {
        return null
    }

    return _.find(descriptor.parts, {id: compData.appPartName})
})

const partDataLocation = createWixappsComponentFetcher(function (state, props) {
    const dataLocations = getDataLocations(state, props)
    touchRenderCounter(state, props, dataLocations)
    return dataLocations
})

const partData = createWixappsComponentFetcher(function (state, props) {
    touchRenderCounter(state, props)
    const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect')
    const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props)
    const path = state.fetchSantaType(appPart.statics.santaTypeDefinitions.wixappsClassics.partDataLocation, state, props)
    return path ? dataAspect.getDataByPath(packageName, path) : null
})

const blogStoreId = createGlobalSantaTypesFetcher(function (state) {
    const blogAppDefId = '61f33d50-3002-4882-ae86-d319c1a249ab'
    return state.siteData.getClientSpecMapEntryByAppDefinitionId(blogAppDefId)
}, 'blogStoreId')

const blogAppPage = createGlobalSantaTypesFetcher(function (state) {
    const pagesDataItems = state.siteData.getPagesDataItems()
    const blogPage = _.find(pagesDataItems, {appPageType: 'AppPage', appPageId: '79f391eb-7dfc-4adf-be6e-64434c4838d9'})
    return blogPage
}, 'blogAppPage')

const compMetadata = createWixappsComponentFetcher(function (state, props) {
    const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect')
    const compData = getCompData(state, props)
    const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props)
    return dataAspect.getMetadata(packageName, compData.id) || {}
})

const didCompRequestFailedInSSR = createWixappsComponentFetcher(function (state, props) {
    const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect')
    const compData = getCompData(state, props)
    const packageName = state.fetchSantaType(wixapps.CoreSantaTypes.packageName, state, props)
    return dataAspect.didCompHadFailedRequestsInSSR(packageName, compData.id)
})

const pageSubItemId = createGlobalSantaTypesFetcher(function (state) {
    return wixapps.wixappsUrlParser.getPageSubItemId(state.siteData)
}, 'wixappsClassics.pageSubItemId')

const getAppPartZoomAdditionalDataPart = createGlobalSantaTypesFetcher(function (state) {
    return wixapps.wixappsUrlParser.getAppPartZoomAdditionalDataPart.bind(wixapps.wixappsUrlParser, state.siteData)
}, 'wixappsClassics.getAppPartZoomAdditionalDataPart')

const AMP_QUERY_PARAM = '?_amp_'
const updatePageTitleAndMetaTags = createGlobalSantaTypesFetcher(function (state) {
    return function (pageTitle, metaDescription, pageTitleSEO, metaKeywords, ogTags, jsonld, twitterMetaTags) {
        state.siteAPI.setPageMetaKeywords(metaKeywords)
        state.siteAPI.setPageMetaOgTags(ogTags)
        state.siteAPI.setPageJsonld(jsonld)
        state.siteAPI.setPageLinkTag(state.siteData.getCurrentUrl() + AMP_QUERY_PARAM)
        state.siteAPI.setPageTwitterMetaTags(twitterMetaTags)
        state.siteAPI.setRunTimePageTitle(pageTitle, metaDescription, pageTitleSEO) // triggers head tag render
    }
}, 'wixappsClassics.updatePageTitleAndMetaTags')

const getCurrentUrl = createGlobalSantaTypesFetcher(function (state) {
    return state.siteData.getCurrentUrl
}, 'wixappsClassics.getCurrentUrl')

const showSelectionSharer = createGlobalSantaTypesFetcher(function (state) {
    return state.siteAPI.showSelectionSharer
}, 'wixappsClassics.showSelectionSharer')

const hideSelectionSharer = createGlobalSantaTypesFetcher(function (state) {
    return state.siteAPI.hideSelectionSharer
}, 'wixappsClassics.hideSelectionSharer')

const isSelectionSharerVisible = createGlobalSantaTypesFetcher(function (state) {
    return state.siteAPI.isSelectionSharerVisible() || false
}, 'wixappsClassics.isSelectionSharerVisible')

const setCustomUrlMapping = createGlobalSantaTypesFetcher(function (state) {
    return state.siteData.setCustomUrlMapping
}, 'wixappsClassics.setCustomUrlMapping')

const refreshRenderedRootsData = createGlobalSantaTypesFetcher(function (state) {
    const siteDataAPI = state.siteAPI.getSiteDataAPI()
    return siteDataAPI.refreshRenderedRootsData.bind(siteDataAPI)
}, 'wixappsClassics.refreshRenderedRootsData')

export default {
    Zoom: {
        pageSubItemId,
        getAppPartZoomAdditionalDataPart
    },
    updatePageTitleAndMetaTags,
    showSelectionSharer,
    hideSelectionSharer,
    isSelectionSharerVisible,
    setCustomUrlMapping,
    getCurrentUrl,
    blogStoreId,
    blogAppPage,
    refreshRenderedRootsData,
    reportEvent: classicsReportEvent,
    reportError: classicReportError,
    reportAppPartMetadataError,
    reportFirstTimeRenderStart,
    reportFirstTimeRenderFinish,
    partDefinition,
    partDataLocation,
    partData,
    didCompRequestFailedInSSR,
    compMetadata
}
