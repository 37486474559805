import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import styleMapping from '@wix/santa-ds-libs/src/wixappsCore/src/util/styleMapping'
import experiment from 'experiment'

const functions = {
    get(object, path) {
        return _.get(object, path)
    },

    getFromLocalStorage(itemName) {
        return window.localStorage.getItem(itemName)
    },

    parseFromJSON(json) {
        return JSON.parse(json) || {}
    },

    getObjectMember(object, memberName) {
        return _.get(object, memberName, false)
    },

    and() {
        for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] === false) {
                return false
            }
        }

        return true
    },

    or() {
        for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] === true) {
                return true
            }
        }

        return false
    },

    if(condition, trueValue, falseValue) {
        return condition ? trueValue : falseValue
    },

    eq(value1, value2) {
        return value1 === value2
    },

    ne(value1, value2) {
        return value1 !== value2
    },

    not(value) {
        return !value
    },
    '!'(value) {
        return !value
    },
    gt(value1, value2) {
        return value1 > value2
    },
    gte(value1, value2) {
        return value1 >= value2
    },

    lt(value1, value2) {
        return value1 < value2
    },
    lte(value1, value2) {
        return value1 <= value2
    },

    toString(value) {
        if (value) {
            return value.toString ? value.toString() : `${value}`
        }
        return undefined
    },

    toJson(value) {
        return JSON.stringify(value)
    },

    mod(a, b) {
        return a % b
    },

    add() {
        let sum = 0
        for (let i = 0; i < arguments.length; i++) {
            sum += arguments[i]
        }
        return sum
    },

    mult() {
        let product = 1
        for (let i = 0; i < arguments.length; i++) {
            product *= arguments[i]
        }
        return product
    },

    sub(a, b) {
        return a - b
    },

    div(a, b) {
        return a / b
    },

    negate(number) {
        return -number
    },

    match(value1, value2, resultMatch) {
        return value1 === value2 ? resultMatch : undefined
    },

    'true?'(cond, value) {
        return Boolean(cond) === true ? value : undefined
    },

    'false?'(cond, value) {
        return Boolean(cond) === false ? value : undefined
    },

    else(cond, value) {
        return cond === undefined ? value : cond
    },

    map() {
        // first parameter is the key to map
        // subsequent pairs are the map key and values
        // if item is not mapped, and there's a parameter after the last pair, it will be used as default.
        // otherwise, if not mapped, return the key itself.
        for (let i = 1; i < arguments.length - 1; i += 2) {
            if (arguments[0] === arguments[i]) {
                return arguments[i + 1]
            }
        }
        return arguments.length % 2 === 0 ? arguments[arguments.length - 1] : arguments[0]
    },

    Math: {
        floor(value) {
            return Math.floor(value)
        },

        round(value) {
            return Math.round(value)
        }
    },

    Array: {
        length(arr) {
            return arr.length
        },

        isEmpty(arr) {
            return arr === null || arr.length === 0
        },

        slice(arr, start, end) {
            return arr.slice(start, end)
        },
        join(arr, char) {
            return arr.join(char)
        },
        filter(arr, prop, values) {
            return arr.filter(function (element) {
                return values.contains(element[prop])
            })
        },
        itemAt(arr, index) {
            return arr[index]
        },

        fromArgs() {
            return _.toArray(arguments)
        },

        includes(array, element) {
            return _.includes(array, element)
        }
    },
    RichText: {
        isEmpty(richText) {
            return richText.text === '<div></div>'
        }
    },
    String: {
        length(str) {
            return String(str).length
        },

        isEmpty(str) {
            return String(str) === null || String(str).length === 0
        },

        charAt(str, index) {
            return String(str).charAt(index)
        },
        charCodeAt(str, index) {
            // @ts-ignore
            return String(str).charCodeAt(str, index)
        },
        concat() {
            return _.reduce(
                arguments,
                function (result, str) {
                    return result.concat(String(str))
                },
                ''
            )
        },
        indexOf(str, substr) {
            return String(str).indexOf(substr)
        },
        lastIndexOf(str, substr) {
            return String(str).lastIndexOf(substr)
        },
        match(str, pattern) {
            return String(str).match(new RegExp(pattern))
        },
        replace(str, searchStr, replaceStr) {
            return String(str).replace(searchStr, replaceStr)
        },
        search(str, pattern) {
            return String(str).search(new RegExp(pattern))
        },
        slice(str, start, end) {
            return String(str).slice(start, end)
        },
        split(str, separator) {
            return String(str).split(separator)
        },
        substr(str, start, length) {
            return String(str).substr(start, length)
        },
        substring(str, start, end) {
            return String(str).substring(start, end)
        },
        toLowerCase(str) {
            return String(str).toLowerCase()
        },
        toUpperCase(str) {
            return String(str).toUpperCase()
        },
        contains(str, substr) {
            // @ts-ignore
            return String(str).contains(substr)
        },
        trim(str) {
            return String(str).trim()
        },
        toInt(str) {
            return parseInt(str, 10)
        },
        toFloat(str) {
            return parseFloat(str)
        }
    },
    Date: {
        createDate(dateStr) {
            if (!dateStr) {
                return new Date()
            }
            return new Date(dateStr)
        },
        /**
         *  Return a date before the compared to date
         * @param daysBefore days before the date
         * @param monthsBefore months before the date
         * @param yearsBefore years before the date
         * @param {String} comparedDate - optional, if not given its the current time
         */
        getBefore(daysBefore, monthsBefore, yearsBefore, comparedDate) {
            const pastDate = comparedDate ? new Date(comparedDate) : new Date()
            pastDate.setDate(pastDate.getDate() - daysBefore)
            pastDate.setMonth(pastDate.getMonth() - monthsBefore)
            pastDate.setFullYear(pastDate.getFullYear() - yearsBefore)

            return pastDate
        },
        getAfter(daysAfter, monthsAfter, yearsAfter, comparedDate) {
            const futureDate = comparedDate ? new Date(comparedDate) : new Date()
            futureDate.setDate(futureDate.getDate() - daysAfter)
            futureDate.setMonth(futureDate.getMonth() - monthsAfter)
            futureDate.setFullYear(futureDate.getFullYear() - yearsAfter)

            return futureDate
        }
    },
    Theme: {
        getColor(colorId) {
            return this.getColor(colorId)
        }
    },
    Styles: {
        fontToTextStyle(fontNumber) {
            return styleMapping.fontClassToStyle(`font_${fontNumber}`)
        },
        calcFontSize(fontNumber) {
            return fontNumber * 3 + 9
        }
    },
    Mobile: {
        zoom() {
            return this.isMobile ? 1 / this.zoom : 1
        }
    },
    experiment(experimentName) {
        return experiment.isOpen(experimentName, this.experimentContext)
    },
    newBlogSocialIconSourceFor(type) {
        return coreUtils.media.getMediaUrl(this.serviceTopology, `new-blog-social-icons/${type}.svg`)
    },
    invertAlignment(alignment) {
        switch (alignment) {
            case 'left':
                return 'right'
            case 'right':
                return 'left'
            default:
                return alignment
        }
    }
}

/**
 * Flatten the nested functions object into dot separated keys (e.g. Math.floor)
 * @returns {object}
 */
function convertToPrototype() {
    const funcTable = {}

    function traverseFunc(target, obj, ns) {
        _.forEach(obj, function (item, key) {
            if (typeof item === 'function') {
                target[ns + key] = item
            } else if (typeof item === 'object') {
                traverseFunc(target, item, `${key}.`)
            }
        })
    }

    traverseFunc(funcTable, functions, '')

    return funcTable
}

/**
 * @class wixappsCore.FunctionLibrary
 * @constructor
 */
function FunctionLibrary(isMobile, zoom, serviceTopology, getColor, experimentContext) {
    this.isMobile = isMobile
    this.zoom = zoom
    this.getColor = getColor
    this.serviceTopology = serviceTopology
    this.experimentContext = experimentContext
}

FunctionLibrary.prototype = convertToPrototype()

// @ts-ignore
FunctionLibrary.prototype.addFunctions = function (funcsObject) {
    _.assign(FunctionLibrary.prototype, funcsObject)
}

export default FunctionLibrary
