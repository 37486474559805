import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import dataRefsMap from './dataRefsMap'
import customResolvers from './customDataResolvers/customDataResolvers'

//this is very very temp..

const DATA_REFS_TYPES = {
    component_properties: 'Properties',
    document_data: 'Data',
    design_data: 'Design'
}

const MASTER_PAGE = ['masterPage']
function resolveWithCustomResolver(dataQueryObj, dataType, getResolvedData, pageIdOverride) {
    const customResolver = customResolvers[dataQueryObj.type].resolve
    return customResolver(dataQueryObj, (query, rootId = pageIdOverride) => getDataByResolver(dataType, query, getResolvedData, null, rootId))
}

function getPageObjectFromSiteData(siteData, pageId) {
    //So mobx will know this path was visited
    const {resolvedDataMaps} = siteData
    if (resolvedDataMaps && resolvedDataMaps.has) {
        resolvedDataMaps.has(pageId)
    }

    return siteData.pagesData[pageId]
}

function doesPageHaveData(siteData, pageId) {
    return doesPageObjectHaveData(getPageObjectFromSiteData(siteData, pageId))
}

function doesPageObjectHaveData(pageObject) {
    return pageObject && pageObject.hasOwnProperty('data')
}

/**
 *
 * @param nestedDataItem the object to flatten, could be a whole data Item, and inner object or an array
 * @param dataType - the type of the schema (data/properties...)
 * @param accumulator
 * @param derivedRefs - undefined if root data item, an object if we are looking an an inner object and bool if we are looking at an item that is an inner data item
 * @param accumulator
 */
function getFlattenedData(nestedDataItem, dataType, accumulator, excludePageItems, derivedRefs?, fallbackId?) {
    if (typeof nestedDataItem !== 'object' || nestedDataItem === null) {
        return {
            id: null,
            data: nestedDataItem
        }
    }

    if (excludePageItems && nestedDataItem.type === 'Page') {
        return {
            id: nestedDataItem.id
        }
    }

    const myId = nestedDataItem.id || fallbackId || _.uniqueId('resolver_')

    const dataRefsForType = dataRefsMap[DATA_REFS_TYPES[dataType]]
    const isDataItemWithType = _.isUndefined(derivedRefs) || _.isBoolean(derivedRefs)

    let refs = derivedRefs
    if (isDataItemWithType) {
        if (_.isArray(nestedDataItem)) {
            return _.map(nestedDataItem, function (item, i) {
                return getFlattenedData(item, dataType, accumulator, excludePageItems, derivedRefs, `${myId}_${i}`)
            })
        }
        if (!nestedDataItem.type) {
            // @ts-ignore
            throw new Error('we need a type to resolve a data item', nestedDataItem)
        }
        refs = (dataRefsForType && dataRefsForType[nestedDataItem.type]) || {}
    }

    const childRefFieldValues = {}
    function resolveReturnedValueToFieldValue(returnedValue) {
        return returnedValue.id === null ? returnedValue.data : `#${returnedValue.id}`
    }
    _.forOwn(refs, function (value, fieldName) {
        if (_.isUndefined(nestedDataItem[fieldName])) {
            return
        }
        const returnedValue = getFlattenedData(nestedDataItem[fieldName], dataType, accumulator, excludePageItems, value, `${myId}_${fieldName}`)
        childRefFieldValues[fieldName] = _.isArray(returnedValue)
            ? _.map(returnedValue, resolveReturnedValueToFieldValue)
            : resolveReturnedValueToFieldValue(returnedValue)
    })

    let me = !_.isEmpty(childRefFieldValues) ? _.defaults(childRefFieldValues, nestedDataItem) : nestedDataItem

    if (isDataItemWithType) {
        if (!me.id) {
            me = _.defaults({id: myId}, me) //this shouldn't happen much.. if it is an issue move it up so we won't clone twice
        }
        accumulator[myId] = me
    }
    return {
        id: me.id || null,
        data: me
    }
}

function isSimpleRefs(refs) {
    return !refs || refs === true
}

function extractDataQuery(dataQuery) {
    if (_.startsWith(dataQuery, '#')) {
        return dataQuery.slice(1)
    }

    return dataQuery
}

function getDataByResolver(dataType, dataQuery, getResolvedData, refs?, pageIdOverride?) {
    if (_.isUndefined(dataQuery)) {
        return null
    }

    const dataRefsForType = dataRefsMap[DATA_REFS_TYPES[dataType]]
    let data = null

    if (_.isPlainObject(dataQuery)) {
        if (customResolvers.hasOwnProperty(dataQuery.type)) {
            data = resolveWithCustomResolver(dataQuery, dataType, getResolvedData, pageIdOverride)
        } else {
            refs = isSimpleRefs(refs) ? dataRefsForType && dataRefsForType[dataQuery.type] : refs
            if (isSimpleRefs(refs)) {
                return dataQuery
            }

            data = _.transform(
                refs,
                (refsData, isRef, refKey) => {
                    if (!dataQuery || !dataQuery.hasOwnProperty(refKey)) {
                        return
                    }

                    const propValue = getDataByResolver(dataType, dataQuery[refKey], getResolvedData, isRef, pageIdOverride)
                    refsData[refKey] = !propValue && isRef === true ? dataQuery[refKey] : propValue
                },
                {}
            )
        }

        return _.defaults(data, dataQuery)
    }

    if (_.isArray(dataQuery)) {
        return _.map(dataQuery, innerQuery => getDataByResolver(dataType, innerQuery, getResolvedData, null, pageIdOverride))
    }

    dataQuery = extractDataQuery(dataQuery)

    return getResolvedData(dataQuery, pageIdOverride, refs)
}

function calculateFallbacks(pageId, currentRootIds) {
    return pageId === 'masterPage' ? currentRootIds : MASTER_PAGE
}

function getSiteDataResolver({pagesData, resolvedDataMaps}, pageId, currentRootIds, dataType) {
    currentRootIds = _.compact(currentRootIds)

    const resolver = (dataQuery, pageIdOverride, refs) => {
        const fallbackPageIds = calculateFallbacks(pageIdOverride || pageId, currentRootIds)

        const pagesToSearch = _.compact([pageIdOverride || pageId].concat(fallbackPageIds))

        const computedData = _(pagesToSearch)
            .map(currPageId => {
                const types = resolvedDataMaps && resolvedDataMaps.get && resolvedDataMaps.get(currPageId)
                const resolvedDataMap = types && types[dataType]
                return (
                    resolvedDataMap &&
                    (resolvedDataMap.get(dataQuery) || resolvedDataMap.get(warmupUtilsLib.displayedOnlyStructureUtil.getRepeaterTemplateId(dataQuery)))
                )
            })
            .compact()
            .head()
        if (computedData) {
            return computedData.get()
        }

        let data = null
        _.forEach(pagesToSearch, currPageId => {
            data = _.get(pagesData, [currPageId, 'data', dataType, dataQuery])
            return !data
        })
        if (!data) {
            return null
        }

        return getDataByResolver(dataType, data, resolver, refs, pageIdOverride)
    }

    return resolver
}

function cloneDataItemWithNewRefIdsRecursively(currentJsonValue, dataType, newIdPrefix, clonedDataItemsIdsMap) {
    return cloneDataItemWithNewRefIds(warmupUtilsLib.objectUtils.cloneDeep(currentJsonValue), dataType, newIdPrefix, clonedDataItemsIdsMap)
}

function createClonedDataItemId(innerRefDataItemId, newIdPrefix, clonedDataItemsIdsMap) {
    let suffix = clonedDataItemsIdsMap[innerRefDataItemId]
    if (!suffix) {
        suffix = _.uniqueId(newIdPrefix)
        clonedDataItemsIdsMap[innerRefDataItemId] = suffix
    }

    return innerRefDataItemId + suffix
}

function cloneDataItemWithNewRefIds(newJsonValue, dataType, newIdPrefix, clonedDataItemsIdsMap) {
    const dataRefsForType = getDataRefsForDataItems(dataType, newJsonValue.type)
    if (dataRefsForType) {
        const refNames = _.keys(dataRefsForType)
        _.forEach(refNames, function (refName) {
            const innerRefData: any = newJsonValue[refName]
            if (innerRefData && _.isArray(innerRefData)) {
                // if Array go over each refItem
                _.forEach(innerRefData, function (innerRefDataItem, index) {
                    if (innerRefDataItem.id && !_.includes(innerRefDataItem.id, newIdPrefix)) {
                        const item = newJsonValue[refName][index]
                        item.id = createClonedDataItemId(innerRefDataItem.id, newIdPrefix, clonedDataItemsIdsMap)
                        newJsonValue[refName][index] = cloneDataItemWithNewRefIds(item, dataType, newIdPrefix, clonedDataItemsIdsMap)
                    }
                })
            }
            // @ts-ignore
            if (innerRefData && _.isObject(innerRefData) && innerRefData.id && !_.includes(innerRefData.id, newIdPrefix)) {
                // @ts-ignore
                newJsonValue[refName].id = createClonedDataItemId(innerRefData.id, newIdPrefix, clonedDataItemsIdsMap)
                newJsonValue[refName] = cloneDataItemWithNewRefIds(newJsonValue[refName], dataType, newIdPrefix, clonedDataItemsIdsMap)
            }
        })
    }
    return newJsonValue
}

/**
 * Resolves data of the dataQuery from the pageId or from the currentRootIds
 * @param {object} SiteData
 * @param {String[]} currentRootIds The current pageId
 * @param {String} pageId The requested pageId to get the data from.
 * @param {SiteData.dataTypes} dataType The type of the data
 * @param {String} query The data id
 * @returns {*} The resolved data of this query
 */
function getDataByQuery(siteData, currentRootIds, pageId, dataType, query) {
    if (!doesPageHaveData(siteData, pageId)) {
        return null
    }

    return getDataByResolver(dataType, query, getSiteDataResolver(siteData, pageId, currentRootIds, dataType))
}

/**
 * Resolves data of the dataQuery from the provided page object
 * @param {object} pageObject
 * @param {SiteData.dataTypes} dataType The type of the data
 * @param {String} query The data id
 * @returns {*} The resolved data of this query
 */
function getSinglePageDataByQuery(pageObject, dataType, dataQuery) {
    if (!doesPageObjectHaveData(pageObject)) {
        return null
    }

    const resolver = (query, pageIdOverride, refs) => {
        const data = _.get(pageObject, ['data', dataType, query])

        return data ? getDataByResolver(dataType, data, resolver, refs) : null
    }

    return getDataByResolver(dataType, dataQuery, resolver)
}

function getDataRefsForDataItems(dataType, compDataType) {
    const EXCLUDED_DATA_ITEMS = ['AnchorLink', 'PageLink', 'linkList', 'StyledText'] // pageId id should not changed
    if (_.includes(EXCLUDED_DATA_ITEMS, compDataType)) {
        return null
    }
    const dataRefsForType = dataRefsMap[DATA_REFS_TYPES[dataType]]
    if (dataRefsForType) {
        return dataRefsForType[compDataType] || null
    }
    return null
}

export default {
    getDataByQuery,
    getSinglePageDataByQuery,

    /**
     *
     * @param {Object} nestedDataItem - the nested resolved data item
     * @param {SiteData.dataTypes} dataType - the type of the data (data/properties..)
     * @param excludePageDataItems
     * @returns a map of data items derived from nestedDataItem
     */
    getFlatMapOfNestedItem(nestedDataItem, dataType, excludePageDataItems?): Record<string, any> {
        const flattenedDataItems: Record<string, any> = {}
        getFlattenedData(nestedDataItem, dataType, flattenedDataItems, !!excludePageDataItems)
        return flattenedDataItems
    },

    cloneDataItemWithNewRefIdsRecursively,

    getDataRefsForDataItems
}
