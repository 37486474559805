define([
    'lodash',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/constants/constants',
    'documentServices/hooks/hooks',
    '@wix/santa-core-utils',
    'experiment',
    'documentServices/mobileUtilities/mobileUtilities'
], function (_, documentModeInfo, constants, hooks, santaCoreUtils, experiment, mobileUtil) {
    'use strict'

    const getSetViewModeInteractionParams = (ps, viewMode) => ({working_mode_to: viewMode})

    /**
     * Sets the document's view mode (mobile or desktop)- sets the view that's rendered, regardless of the displaying device
     *  @param {ps} privateServices
     *  @param {String} viewMode - MOBILE or DESKTOP
     */
    function setViewMode(privateServices, viewMode) {
        const possibleViewModesValues = _.values(constants.VIEW_MODES)
        const isValidViewMode = _.includes(possibleViewModesValues, viewMode)
        if (!isValidViewMode) {
            throw new Error(`view mode not valid. valid viewModes are: ${possibleViewModesValues}`)
        }
        if (documentModeInfo.getViewMode(privateServices) !== viewMode) {
            const isMobile = viewMode === constants.VIEW_MODES.MOBILE
            if (isMobile) {
                mobileUtil.setMobileViewMode(privateServices, hooks)
            }
            resetRuntimeStore(privateServices)
            privateServices.siteAPI.setMobileView(isMobile)
            hooks.executeHook(hooks.HOOKS.SWITCH_VIEW_MODE.AFTER)
        }
    }

    function setRenderFlag(ps, flagName, value) {
        const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
        ps.dal.set(renderFlagPointer, value)
    }

    function getRenderFlag(ps, flagName) {
        const renderFlagPointer = ps.pointers.general.getRenderFlag(flagName)
        return ps.dal.get(renderFlagPointer)
    }

    function enablePageProtection(ps, allowed) {
        setRenderFlag(ps, 'isPageProtectionEnabled', allowed)
    }

    function allowPlaying(ps, allowed) {
        setRenderFlag(ps, 'isPlayingAllowed', allowed)
    }

    function allowZoom(ps, allowed) {
        setRenderFlag(ps, 'isZoomAllowed', allowed)
    }

    function enableTransFormScaleOnSite(ps, transformScale) {
        setRenderFlag(ps, 'siteTransformScale', transformScale)
    }

    function allowSocialInteraction(ps, allowed) {
        setRenderFlag(ps, 'isSocialInteractionAllowed', allowed)
    }

    function allowExternalNavigation(ps, allowed) {
        setRenderFlag(ps, 'isExternalNavigationAllowed', allowed)
    }

    function allowRenderFixedPositionContainer(ps, allowed) {
        setRenderFlag(ps, 'renderFixedPositionContainers', allowed)
    }

    function allowRenderFixedPositionBackgrounds(ps, allowed) {
        setRenderFlag(ps, 'renderFixedPositionBackgrounds', allowed)
    }

    function setRenderSticky(ps, value) {
        setRenderFlag(ps, 'renderSticky', value)
    }

    function setShouldKeepTPAComps(ps, value) {
        setRenderFlag(ps, 'shouldKeepTPAComps', value)
    }

    function setRenderScrollSnap(ps, value) {
        setRenderFlag(ps, 'renderScrollSnap', value)
    }

    /**
     * Set whether back to top button is visible in the viewer
     * @param {ps} ps
     * @param {boolean} allowed
     */
    function allowBackToTopButton(ps, allowed) {
        setRenderFlag(ps, 'isBackToTopButtonAllowed', allowed)
    }

    function allowSlideShowGalleryClick(ps, allowed) {
        setRenderFlag(ps, 'isSlideShowGalleryClickAllowed', allowed)
    }

    function allowShouldUpdateJsonFromMeasureMap(ps, allowed) {
        setRenderFlag(ps, 'shouldUpdateJsonFromMeasureMap', allowed)
    }

    function allowTinyMenuOpening(ps, allowed) {
        setRenderFlag(ps, 'isTinyMenuOpenAllowed', allowed)
    }

    function enableResetGalleryToOriginalState(ps, allowed) {
        setRenderFlag(ps, 'shouldResetGalleryToOriginalState', allowed)
    }

    function enableResetComponent(ps, allowed) {
        setRenderFlag(ps, 'shouldResetComponent', allowed)
    }

    function allowSiteMembersDialogsOpening(ps, allowed) {
        setRenderFlag(ps, 'isSiteMembersDialogsOpenAllowed', allowed)
    }

    function allowShowingFixedComponents(ps, allowed) {
        setRenderFlag(ps, 'allowShowingFixedComponents', allowed)
    }

    function allowShowingHiddenComponents(ps, allowed) {
        setRenderFlag(ps, 'showHiddenComponents', allowed)
    }

    function isShowingFixedComponentsEnabled(ps) {
        return getRenderFlag(ps, 'allowShowingFixedComponents')
    }

    function isHiddenComponentsEnabled(ps) {
        return getRenderFlag(ps, 'showHiddenComponents')
    }

    function isFetchDynamicPageInnerRoutesEnabled(ps) {
        return getRenderFlag(ps, 'shouldFetchDynamicPageInnerRoutes')
    }

    function isControllersVisibilityEnabled(ps) {
        return getRenderFlag(ps, 'showControllers')
    }

    function setComponentViewMode(ps, viewMode) {
        hooks.executeHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.BEFORE, 'componentViewMode', [ps, viewMode])
        setRenderFlag(ps, 'componentViewMode', viewMode)
        hooks.executeHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.AFTER, 'componentViewMode', [ps, viewMode])
    }

    function showControllers(ps, allowed) {
        setRenderFlag(ps, 'showControllers', allowed)
    }

    /**
     * Set whether wix ads are visible in the viewer
     * @param {ps} ps
     * @param {boolean} allowed
     */
    function allowWixAds(ps, allowed) {
        setRenderFlag(ps, 'isWixAdsAllowed', allowed)
    }

    function resetBehaviorsRegistration(privateServices, reset) {
        if (reset) {
            privateServices.siteAPI.resetBehaviorsRegistration()
        }
    }

    /**
     * @param {ps} privateServices
     * @param actionName
     * @param isAllowed
     */
    function allowActionByName(privateServices, actionName, isAllowed) {
        validateBooleanParam(isAllowed)

        if (isAllowed) {
            privateServices.siteAPI.enableAction(actionName)
        } else {
            privateServices.siteAPI.disableAction(actionName)
        }
    }

    function allowPageTransitions(privateServices, isAllowed) {
        allowActionByName(privateServices, 'pageTransition', isAllowed)
    }

    function enableComponentTransitions(ps, isEnabled) {
        setRenderFlag(ps, 'isComponentTransitionAllowed', isEnabled)
    }

    function enableRenderTPAsIframe(ps, isEnabled) {
        setRenderFlag(ps, 'shouldRenderTPAsIframe', isEnabled)
    }

    function enableStubifyComponents(ps, isEnabled) {
        setRenderFlag(ps, 'shouldStubifyComponents', isEnabled)
    }

    function enableFetchDynamicPageInnerRoutes(ps, isEnabled) {
        setRenderFlag(ps, 'shouldFetchDynamicPageInnerRoutes', isEnabled)
    }

    function validateBooleanParam(param) {
        if (typeof param !== 'boolean') {
            throw new Error(`param ${param} is not valid. Should be boolean`)
        }
    }

    function setShouldKeepChildrenInPlace(ps, val) {
        setRenderFlag(ps, 'enforceShouldKeepChildrenInPlace', val)
    }

    function enablePageAutoGrowForDetachedComponents(ps, val) {
        setRenderFlag(ps, 'enablePageAutoGrowForDetachedComponents', !!val)
    }

    function setPreserveCompLayoutOnReparent(ps, val) {
        setRenderFlag(ps, 'preserveCompLayoutOnReparent', val)
    }

    function getExtraSiteHeight(ps) {
        return getRenderFlag(ps, 'extraSiteHeight')
    }

    function setExtraSiteHeight(ps, extraSiteHeight) {
        setRenderFlag(ps, 'extraSiteHeight', extraSiteHeight)
    }

    function allowSiteOverflow(ps, shouldAllowSiteOverflow) {
        setRenderFlag(ps, 'allowSiteOverflow', shouldAllowSiteOverflow)
    }

    function resetRuntimeStore(ps) {
        //TODO: this is temporary until the displayed dal uses mobx!!!
        ps.siteAPI.resetRuntimeData()
    }

    function ignoreComponentsHiddenProperty(ps, compIds) {
        setRenderFlag(ps, 'ignoreComponentsHiddenProperty', compIds)
    }

    function ignoreComponentsCollapsedProperty(ps, compIds) {
        setRenderFlag(ps, 'ignoreComponentsCollapsedProperty', compIds)
    }

    function checkComponentsHiddenProperty(ps, compIds) {
        const currentCompIds = getRenderFlag(ps, 'ignoreComponentsHiddenProperty') || []
        return santaCoreUtils.objectUtils.isDifferent(compIds, currentCompIds)
    }

    function checkComponentsCollapsedProperty(ps, compIds) {
        const currentCompIds = getRenderFlag(ps, 'ignoreComponentsCollapsedProperty') || []
        return santaCoreUtils.objectUtils.isDifferent(compIds, currentCompIds)
    }

    function allowWixCodeInitialization(ps, allowed) {
        setRenderFlag(ps, 'initWixCode', allowed)
    }

    function setComponentPreviewState(ps, compId, state) {
        const previewStateMap = getRenderFlag(ps, 'componentPreviewStates') || {}
        previewStateMap[compId] = state
        setRenderFlag(ps, 'componentPreviewStates', previewStateMap)
    }

    function getComponentPreviewState(ps) {
        return getRenderFlag(ps, 'componentPreviewStates')
    }

    function setOnboardingViewportMode(ps, mode) {
        setRenderFlag(ps, 'onboardingViewportMode', mode)
    }

    function setComponentVisibility(ps, compId, state) {
        const map = getRenderFlag(ps, 'componentVisibility') || {}
        map[compId] = state
        setRenderFlag(ps, 'componentVisibility', map)
    }

    function isContactFormMigrationMode() {
        return experiment.isOpen('sv_contactFormFinalMigrationEditor')
    }

    function updateAnimationsViewMode(ps, viewMode) {
        const {animations} = ps.siteDataAPI.siteData
        animations.updateViewMode(viewMode)
    }

    function enableNativeComponentsForPreview(ps, isPreview) {
        if (experiment.isOpen('sv_editorNativeComponents')) {
            setRenderFlag(ps, 'shouldModifyTpaStructure', isPreview)

            if (isPreview) {
                resetRuntimeStore(ps)
            }
        }
    }

    function getComponentViewMode(ps) {
        return getRenderFlag(ps, 'componentViewMode')
    }

    /**
     * @class documentServices.documentMode
     */

    return {
        isMobileView: documentModeInfo.isMobileView,
        getViewMode: documentModeInfo.getViewMode,
        setViewMode,
        getSetViewModeInteractionParams,
        runtime: {
            /**
             * Resets all dynamic data
             */
            reset: resetRuntimeStore
        },
        /**
         * Set whether component is visible
         * @param {string} component id
         * @param {boolean} if the component is hidden
         */
        setComponentVisibility,
        /**
         * Retrieves the component view mode, which can be either "preview" or "editor".
         */
        getComponentViewMode,
        /**
         * Set whether component view mode
         * @param {string} view mode
         */
        setComponentViewMode,
        /**
         * Set whether playing of galleries, audio players and videos is allowed in the viewer
         * @param {boolean} allowed
         */
        enablePlaying: allowPlaying,
        /**
         * Set whether page protection / site members pop ups are allowed in the viewer
         * @param {boolean} allowed
         */
        enablePageProtection,
        /**
         * Set whether media zoom is opened in the viewer
         * @param {boolean} allowed
         */
        enableZoom: allowZoom,
        /**
         * Set whether media zoom is opened in the viewer
         * @param {boolean} allowed
         */
        enableTransFormScaleOnSite,

        getSiteTransformScale(ps) {
            const renderFlagPointer = ps.pointers.general.getRenderFlag('siteTransformScale')
            return ps.dal.get(renderFlagPointer)
        },
        /**
         * Set whether social components interact with 3rd party api's in the viewer
         * @param {boolean} allowed
         */
        enableSocialInteraction: allowSocialInteraction,
        /**
         * Set whether navigation to external urls is allowed in the viewer
         * @param {boolean} allowed
         */
        enableExternalNavigation: allowExternalNavigation,
        enableBackToTopButton: allowBackToTopButton,
        /**
         * Set whether wix ads are visible
         * @param {boolean} allowed
         */
        enableWixAds: allowWixAds,
        /**
         * Set whether clicking on slide show gallery works in the viewer
         * @param {boolean} allowed
         */
        enableSlideShowGalleryClick: allowSlideShowGalleryClick,

        enableShouldUpdateJsonFromMeasureMap: allowShouldUpdateJsonFromMeasureMap,
        /**
         * Set whether tiny menu can be opened
         * @param {boolean} allowed
         */
        enableTinyMenuOpening: allowTinyMenuOpening,
        enableResetGalleryToOriginalState,
        enableResetComponent,
        /**
         * Set whether site members dialogs can be opened
         * @param {boolean} allowed
         */
        allowSiteMembersDialogsOpening,
        /**
         * Reset all registered behaviors
         * @param {boolean} reset if not 'true' do nothing
         */
        resetBehaviorsRegistration,
        /**
         * Disable or Enable triggers of an action
         * @param {String} actionName
         */
        enableAction: allowActionByName,
        /**
         * Set whether page transitions are working when navigating between pages in the viewer
         * @param {boolean} isAllowed
         */
        enablePageTransitions: allowPageTransitions,
        /**
         * Set whether animation transitions on components are enabled. i.e. boxSlideShow
         * @param {boolean} isAllowed
         */
        enableComponentTransitions,
        /**
         * Set whether TPAs internal iFrames should be rendered
         * @param {boolean} isAllowed
         */
        enableRenderTPAsIframe,
        enableStubifyComponents,
        enableFetchDynamicPageInnerRoutes,
        /**
         * Disable or enable fixed position on containers
         * @param {boolean} isAllowed
         */
        enableRenderFixedPositionContainers: allowRenderFixedPositionContainer,
        /**
         * set the viewport mode for onboarding workaround,
         * the mode value will indicate from where to return the viewport height.
         * onboarding editor implementation bug .... should be fixed by onboarding
         */
        setOnboardingViewportMode,
        enableRenderFixedPositionBackgrounds: allowRenderFixedPositionBackgrounds,
        getExtraSiteHeight,
        setExtraSiteHeight,
        allowSiteOverflow,
        allowShowingFixedComponents,
        showHiddenComponents: allowShowingHiddenComponents,
        isHiddenComponentsEnabled,
        isFetchDynamicPageInnerRoutesEnabled,
        isShowingFixedComponentsEnabled,
        ignoreComponentsHiddenProperty,
        ignoreComponentsCollapsedProperty,
        setComponentPreviewState,
        getComponentPreviewState,
        checkComponentsHiddenProperty,
        checkComponentsCollapsedProperty,
        showControllers,
        isControllersVisibilityEnabled,
        allowWixCodeInitialization,
        isContactFormMigrationMode,
        setShouldKeepChildrenInPlace,
        enablePageAutoGrowForDetachedComponents,
        setPreserveCompLayoutOnReparent,
        updateAnimationsViewMode,
        enableNativeComponentsForPreview,
        setRenderScrollSnap,
        setRenderSticky,
        setShouldKeepTPAComps
    }
})
