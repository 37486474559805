import _ from 'lodash'
import {defaultAsyncAttempt, ReportableError} from '@wix/document-manager-utils'

export async function postJson(url: string, instance: string, data: any): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'POST', data)
}

export type Method = 'GET' | 'POST' | 'DELETE'

const GET = 'GET'

interface HTTPStatus {
    status: number
    statusText: string
    redirected: boolean
    type: string
    url: string
}
const getHTTPStatus = (response: Response): HTTPStatus => _.pick(response, ['status', 'statusText', 'redirected', 'type', 'url'])

const getExtras = (response: Response) => ({
    ...getHTTPStatus(response),
    request_id: response.headers.get('x-wix-request-id')
})

export class ServerError extends ReportableError {
    readonly details: ServerErrorDetails
    readonly status: number

    constructor(message: string, response: Response, result: Record<string, any>) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message,
            extras: {
                ...getExtras(response),
                result: JSON.stringify(result)
            }
        })
        this.status = response.status
        this.details = result.details
    }
}

const substr = (str: string, end: number) => (str ?? '').substring(0, end)

export class ServerTextError extends ReportableError {
    readonly status: number

    constructor(body: string, response: Response) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message: substr(body, 20),
            extras: {
                ...getExtras(response),
                result: substr(body, 200)
            }
        })
        this.status = response.status
    }
}

export interface ServerErrorDetails {
    applicationError?: {
        code: string
        description: string
    }
}

export interface ServerErrorData {
    message: string
    details: ServerErrorDetails
}

const ContentType = 'Content-Type'
const ContentTypeJson = 'application/json'

const isJson = (response: Response) => {
    const contentType = response.headers.get(ContentType) ?? ''
    return contentType.startsWith(ContentTypeJson)
}

export async function fetchJsonWithAuthorization<T = any, R = any>(url: string, instance: string, method: Method, data?: R): Promise<T> {
    const requestOptions = {
        method,
        headers: {
            Authorization: instance,
            [ContentType]: ContentTypeJson
        },
        body: JSON.stringify(data),
        redirect: 'follow' as RequestRedirect
    }
    const response = await fetch(url, requestOptions)
    const toJson = () => response.json()
    if (response.ok) {
        return await toJson()
    }

    if (isJson(response)) {
        const result = await defaultAsyncAttempt({message: 'Unable to parse error json'}, toJson)
        throw new ServerError(result.message, response, result)
    }
    const text = await response.text()
    throw new ServerTextError(text, response)
}

export async function deleteJson(url: string, instance: string, data: any): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'DELETE', data)
}

export async function fetchJson<T = any>(url: string): Promise<T> {
    const response = await fetch(url, {method: GET})
    return await response.json()
}

export async function getJson<T = any>(url: string, instance: string): Promise<T> {
    return fetchJsonWithAuthorization<T>(url, instance, GET)
}

export type Query = Record<string, any>

export const toQueryString = (queryParams: Query): string =>
    _(queryParams)
        .pickBy()
        .map((value, key) => `${key}=${value}`)
        .join('&')

export async function getJsonWithParams<T = any>(url: string, queryParams: Query, instance: string): Promise<T> {
    const queryString = toQueryString(queryParams)
    return getJson(`${url}?${queryString}`, instance)
}

export async function postJsonWithAuth<T = any, R = any>(url: string, instance: string, data: R): Promise<T> {
    return postJson(url, instance, data)
}
