type Boolean = boolean
type String = string

export interface Action {
    basedOnSignature?: String
    id?: String
    namespace?: String
    op?: ActionOperation
    previousPageId?: String
    value?: any
}

export enum ActionOperation {
    ADD = 'ADD',
    REPLACE = 'REPLACE',
    REMOVE = 'REMOVE'
}

export interface Channel {
    name?: String
    resourceId?: String
}

export interface CreateTransactionRequest {
    actions?: Action[]
    branchId?: String
    metadata?: Metadata

    // TODO not in proto
    correlationId?: string
    envSessionId?: string
}

export interface CreateTransactionResponse {
    transactionId?: String
}

export interface DeleteTransactionsRequest {
    branchId?: String
    from?: String
    to?: String
}

export interface DeleteTransactionsResponse {}

export interface GetChannelPermissionsRequest {
    channel?: Channel
    subscriber?: Subscriber
}

export interface GetChannelPermissionsResponse {
    read?: Boolean
    write?: Boolean
}

export interface GetDocumentRequest {
    afterTransactionId?: String
    branchId?: String
    untilTransactionId?: String
}

export interface GetDocumentResponse {
    actions?: Action[]
    firstTransactionId?: String
    lastTransactionId?: String
}

export interface GetTransactionRequest {
    transactionId?: String
}

export interface GetTransactionResponse {
    transaction?: Transaction
}

export interface GetTransactionsRequest {
    afterTransactionId?: String
    branchId?: String
    untilTransactionId?: String
}

export interface GetTransactionsResponse {
    transactions?: Transaction[]
}

export interface Metadata {
    lastTransactionId?: String
    siteVersion?: String
    // TODO not in proto
    correlationId?: String
}

export interface PendingTransaction {
    actions?: Action[]
    correlationId?: String
    envSessionId?: String
    metadata?: Metadata
}

export interface SaveRequest {
    branchId?: String
    transactions?: PendingTransaction[]
}

export interface SaveResponse {}

export interface Subscriber {
    id?: String
    type?: String
}

export interface Transaction {
    actions?: Action[]
    branchId?: String
    dateCreated?: Date
    metadata?: Metadata
    transactionId?: String
}
