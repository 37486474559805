import _ from 'lodash'
import layout from './util/layout'
import rootLayoutUtils from './util/rootLayoutUtils'
import balataLayout from './specificComponents/balataLayout'
import screenWidthContainerLayout from './specificComponents/screenWidthLayouts/screenWidthContainerLayout'
import singleCompLayout from './util/singleCompLayout'
import matrixGalleryLayout from './specificComponents/matrixGalleryLayout'
import paginatedGridGalleryLayout from './specificComponents/paginatedGridGalleryLayout'
import richtextLayout from './specificComponents/richtextLayout'
import slideShowLayout from './specificComponents/slideShowLayout'
import sliderGalleryLayout from './specificComponents/sliderGalleryLayout'
import wPhotoLayout from './specificComponents/wPhotoLayout'
import imageLayout from './specificComponents/imageLayout'
import createDOMPatchers from './util/createDOMPatchers'
import spaceCalculationsRegardingWixAds from './util/spaceCalculationsRegardingWixAds'
import menuLayout from './specificComponents/menuLayout'
import verticalMenuLayout from './specificComponents/verticalMenuLayout'
import './specificComponents/checkBoxLayout'
import './specificComponents/fiveGridLineLayout'
import './specificComponents/registerToMeasureOnly'
import './specificComponents/registerToPureDomMeasure'
import './specificComponents/loginSocialBarLayout'
import './specificComponents/dialogLayout'
import './specificComponents/siteBackgroundLayout'
import './specificComponents/wixFreemiumBannerLayout'
import './specificComponents/documentMediaLayout'
import './specificComponents/shapeLayout'
import './specificComponents/tinyMenuLayout'
import './specificComponents/imageZoomLayout'
import './specificComponents/tpaGalleryLayout'
import './specificComponents/formMixinLayout'
import './specificComponents/bgImageLayout'
import './specificComponents/wixSiteLayout'
import './specificComponents/fitToContentLayout'
import './specificComponents/imageButtonLayout'
import './specificComponents/boxSlideShowLayout'
import './specificComponents/stateBoxLayout'
import './specificComponents/mediaContainerLayout'
import './specificComponents/mediaControlsLayout'
import './specificComponents/mediaOverlayControlsLayout'
import './specificComponents/columnsLayout'
import './specificComponents/popupContainerLayout'
import './specificComponents/rssButtonLayout'
import './specificComponents/tpaLayout'
import './specificComponents/inlinePopupLayout'
import './specificComponents/inlinePopupToggleLayout'
import './specificComponents/refComponentLayout'
import './specificComponents/stylableHorizontalMenuLayout'
import './specificComponents/screenWidthLayouts/stripsComponentsLayout'
import './specificComponents/screenWidthLayouts/headerLayout'
import './specificComponents/screenWidthLayouts/footerLayout'
import './specificComponents/screenWidthLayouts/pageLayout'
import './specificComponents/screenWidthLayouts/pagesContainerLayout'
import './specificComponents/screenWidthLayouts/pageGroupLayout'
import './specificComponents/screenWidthLayouts/stripSlideshowLayout'
import './specificComponents/quickActionBarLayout'
import './specificComponents/quickActionBarItemLayout'
import './specificComponents/expandableMenuLayout'
import './specificComponents/touchMediaZoomLayout'
import './specificComponents/siteButtonLayout'

verticalMenuLayout.register(layout)
menuLayout.register(layout)

export default _.assign(layout, {
    singleCompLayout,
    rootLayoutUtils,
    spaceCalculationsRegardingWixAds,
    createDOMPatchers,
    specificComponents: {
        balataLayout,
        screenWidthContainerLayout,
        matrixGalleryLayout,
        paginatedGridGalleryLayout,
        richtextLayout,
        slideShowLayout,
        sliderGalleryLayout,
        wPhotoLayout,
        imageLayout,
        menuLayout: {
            dropDown: {
                measure: menuLayout.measure,
                patch: menuLayout.patch
            },
            vertical: {
                measure: verticalMenuLayout.measure,
                patch: verticalMenuLayout.patch
            }
        }
    }
})
