define([
    'lodash',
    '@wix/santa-ds-libs/src/utils',
    'tpa',
    'documentServices/page/page',
    'documentServices/page/pageUtils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/component/component',
    'documentServices/refComponent/refComponent',
    'documentServices/siteMetadata/siteMetadata',
    'documentServices/siteMetadata/seo',
    'documentServices/tpa/services/tpaDataService',
    'documentServices/tpa/services/tpaEventHandlersService',
    'documentServices/structure/structure',
    'documentServices/tpa/services/installedTpaAppsOnSiteService',
    'documentServices/tpa/constants',
    'documentServices/routers/routersGetters',
    'documentServices/platform/platform',
    'documentServices/tpa/services/tpaPreviewEditorCommunicationService',
    'documentServices/utils/contextAdapter',
    'documentServices/tpa/utils/tpaUtils',
    '@wix/document-manager-utils'
], function (
    _,
    utils,
    tpa,
    pageService,
    pageUtils,
    componentDetectorAPI,
    component,
    refComponent,
    siteMetadata,
    seo,
    tpaDataService,
    tpaEventHandlersService,
    structure,
    installedTpaAppsOnSiteService,
    tpaConstants,
    routersGetters,
    platform,
    tpaPreviewEditorCommunicationService,
    contextAdapter,
    tpaUtils,
    {ReportableError}
) {
    let tpads
    const {addCallback} = tpaPreviewEditorCommunicationService

    const reportedAsDeprecated = new Set()
    const reportDeprecated = name => {
        if (!reportedAsDeprecated.has(name)) {
            contextAdapter.utils.fedopsLogger.interactionStarted('deprecatedTpaHandlers', {extras: {name}})
            reportedAsDeprecated.add(name)
        }
    }

    const getWorkerCompData = function (ps, compId) {
        //not working method
        const workers = ps.siteAPI.getComponentsToRender()
        const worker = _.find(workers, ['structure.id', compId])
        return _.get(worker, 'props.compData')
    }

    const getCompData = function (ps, compId) {
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        return (
            component.data.get(ps, compPointer) ||
            tpaUtils.getModalCompData(ps, compId) ||
            tpaUtils.getPopupCompData(ps, compId) ||
            getWorkerCompData(ps, compId)
        )
    }

    const getApplicationId = function (ps, compId) {
        const compData = getCompData(ps, compId)
        const applicationId = parseInt(_.get(compData, 'applicationId'), 10)
        return applicationId
    }

    const getAppPagesByApplicationId = function (ps, applicationId) {
        const sitePages = pageService.getPagesDataItems(ps)
        return _.filter(sitePages, {tpaApplicationId: applicationId})
    }

    const getAppPagesByCompId = function (ps, compId) {
        const applicationId = getApplicationId(ps, compId)
        return getAppPagesByApplicationId(ps, applicationId)
    }

    const getMainAppPage = function (appPages) {
        return _.find(appPages, {hidePage: false})
    }

    const getAppPageByTpaPageId = function (appPages, tpaPageId) {
        return _.find(appPages, {tpaPageId})
    }

    /**
     * @param {ps} ps
     * @param msg
     * @param callback
     */
    const getSectionUrl = function (ps, msg, callback) {
        const isSiteSaved = !siteMetadata.generalInfo.isFirstSave(ps)
        if (!isSiteSaved) {
            callback({error: {message: 'Page was not found.'}})
            return
        }

        const tpaPageId = msg.data.sectionIdentifier
        const appPages = getAppPagesByCompId(ps, msg.compId)
        const appPage = getAppPageByTpaPageId(appPages, tpaPageId) || getMainAppPage(appPages)
        if (appPage) {
            callback({
                url: getPageUrl(ps, appPage)
            })
        } else {
            callback({error: {message: 'This app does not have any pages.'}})
        }
    }

    const getUrlForState = function (ps, appPage, state) {
        const baseUrl = siteMetadata.generalInfo.getPublicUrl(ps)
        return utils.wixUrlParser.getUrl(ps.siteDataAPI.siteData, {pageId: appPage.id, title: appPage.pageUriSEO, tpaInnerRoute: state}, false, true, baseUrl)
    }

    const getStateUrl = function (ps, msg, callback) {
        const isSiteSaved = !siteMetadata.generalInfo.isFirstSave(ps)
        if (!isSiteSaved) {
            callback({error: {message: 'Page was not found.'}})
            return
        }
        const tpaPageId = msg.data.sectionId
        const appPages = getAppPagesByCompId(ps, msg.compId)
        const appPage = getAppPageByTpaPageId(appPages, tpaPageId)
        let dynamicPageData
        if (appPage) {
            dynamicPageData = routersGetters.getRouterDataForPageIfExist(ps, appPage.id)
            if (dynamicPageData) {
                callback({error: {message: "Can't retrieve url for a dynamic page. Please use the platform app API instead."}})
                return
            }
            callback({
                url: getUrlForState(ps, appPage, msg.data.state)
            })
        } else {
            const mainAppPage = getMainAppPage(appPages)
            if (mainAppPage) {
                dynamicPageData = routersGetters.getRouterDataForPageIfExist(ps, mainAppPage.id)
                if (dynamicPageData) {
                    callback({error: {message: "Can't retrieve url for a dynamic page. Please use the platform app API instead."}})
                    return
                }
                callback({
                    url: getPageUrl(ps, mainAppPage)
                })
            } else {
                callback({error: {message: 'This app does not have any pages.'}})
            }
        }
    }

    const siteInfo = function (ps, msg, callback) {
        const info = {}
        const pageData = pageService.data.get(ps, ps.siteAPI.getCurrentUrlPageId())
        pageData.siteTitle = getCurrentPageTitle(ps, pageData)

        if (tpa.common.utils.sdkVersionIsAtLeast(msg.version, '1.42.0')) {
            info.pageTitle = pageData.siteTitle || ''
        } else {
            info.siteTitle = pageData.siteTitle || ''
            info.pageTitle = pageData.title || ''
        }

        info.pageTitleOnly = getCurrentPageTitle(ps, pageData, false)
        info.siteDescription = seo.description.get(ps)
        info.siteKeywords = seo.keywords.get(ps)

        if (!siteMetadata.generalInfo.isFirstSave(ps)) {
            const currentUrl = siteMetadata.generalInfo.getPublicUrl(ps) || ''
            const currentUrlParsed = (currentUrl && utils.urlUtils.parseUrl(currentUrl)) || ''
            info.baseUrl =
                (currentUrl && `${currentUrlParsed.protocol}//${currentUrlParsed.host}${currentUrlParsed.path}`) ||
                'baseUrl is not available - site is saved but not published'
        } else {
            info.baseUrl = ''
        }

        const editorUrl = ps.siteAPI.getCurrentUrl()
        info.url = tpa.common.utils.appendProtocolToUrlIfNeeded(editorUrl.host, editorUrl)
        info.referer = window.document.referrer
        callback(info)
    }

    const getCurrentPageTitle = function (ps, pageData, withAddedSiteName = true) {
        let title = seo.title.get(ps) || '' // site name
        const pageName = pageData.title || ''
        const pageTitleSEO = utils.pageUtils.extractPageTitleFromOriginalTitle(pageData.pageTitleSEO, withAddedSiteName, title)
        const urlPageId = ps.siteAPI.getCurrentUrlPageId()
        const isHomePage = pageUtils.isHomepage(ps, urlPageId)

        if (pageTitleSEO) {
            title = pageTitleSEO
        } else if (!isHomePage) {
            title = withAddedSiteName ? `${title} | ${pageName}` : pageName
        }

        return title
    }

    const getPageUrl = function (ps, appPage) {
        const basePublicUrl = siteMetadata.generalInfo.getPublicUrl(ps)
        return utils.wixUrlParser.getUrl(ps.siteDataAPI.siteData, {pageId: appPage.id, title: appPage.pageUriSEO}, false, true, basePublicUrl)
    }

    const registerEventListener = function (ps, msg) {
        const {eventKey} = msg.data
        const {compId} = msg
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        const compIdToUpdate = compPointer ? getCompPointerToUpdate(ps, compPointer).id : compId

        switch (eventKey) {
            case 'EDITOR_EVENT':
                tpads.change.register.editorEventHandler(ps, compIdToUpdate, function (data) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                    })
                })
                break

            case 'COMPONENT_DELETED':
                tpads.change.register.deleteCompHandler(ps, msg.compId, function () {
                    tpads.comp.postMessageBackToApp(ps, compId, eventKey, {})
                })
                break

            case 'COMPONENT_DISCONNECT':
                tpads.change.register.disconnectCompHandler(ps, msg.compId, function () {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compId, eventKey, {})
                    })
                })
                break

            case 'EDIT_MODE_CHANGE':
                if (!compIdToUpdate) {
                    contextAdapter.utils.fedopsLogger.captureError(
                        new ReportableError({
                            message: 'register to EDIT_MODE_CHANGE with no compId',
                            errorType: 'registerToEditModeChangeWithoutId'
                        })
                    )
                }
                tpads.change.register.editModeChangeHandler(ps, compIdToUpdate, function (mode) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {
                            editMode: mode
                        })
                    })
                })
                break

            case 'SITE_PUBLISHED':
                tpads.change.register.sitePublishedHandler(ps, compIdToUpdate, function () {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {})
                    })
                })
                break

            case 'SETTINGS_UPDATED':
                tpads.change.register.settingsUpdatedHandler(ps, compIdToUpdate, function (data) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                    })
                })
                break

            case 'WINDOW_PLACEMENT_CHANGED':
                tpads.change.register.windowPlacementChangedHandler(ps, compIdToUpdate, function (data) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                    })
                })
                break
            case 'THEME_CHANGE':
                tpads.change.register.themeChangeHandler(ps, compIdToUpdate, function (changedData) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.style.postBackThemeData(ps, compIdToUpdate, changedData)
                    })
                })
                break
            case 'DEVICE_TYPE_CHANGED':
                tpads.change.register.deviceTypeChangeHandler(ps, compIdToUpdate, function (device) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, {
                            deviceType: device
                        })
                    })
                })
                break
            case 'STYLE_PARAMS_CHANGE':
                break
            case 'SITE_SAVED':
                tpads.change.register.siteSavedHandler(ps, compIdToUpdate, function () {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey)
                    })
                })
                break
            case 'ON_MESSAGE_RESPONSE':
                ps.siteAPI.reportBI(tpads.bi.errors.EVENT_TYPE_NOT_SUPPORTED)
                break
            case 'PUBLIC_DATA_CHANGED':
                const applicationId = getApplicationId(ps, compId)

                tpads.change.register.registerPublicDataChangedHandler(ps, compIdToUpdate, applicationId, function (data) {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey, data)
                    })
                })
                break
            case 'REVISION_CHANGED':
                tpads.change.register.historyChangeHandler(ps, compIdToUpdate, function () {
                    ps.setOperationsQueue.runSetOperation(() => {
                        tpads.comp.postMessageBackToApp(ps, compIdToUpdate, eventKey)
                    })
                })
                break
        }
    }

    const getSitePages = function (ps, msg, callback) {
        ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
    }

    const getSiteMap = function (ps, msg, callback) {
        ps.siteAPI.handleDsTpaHandler({msg, addCallback, ps, callback, siteMetadata})
    }

    const getValidComps = function (ps, compIds) {
        const comps = []
        _.forEach(compIds, function (compId) {
            const comp = componentDetectorAPI.getComponentById(ps, compId)
            if (comp) {
                comps.push(comp)
            }
        })
        return comps
    }

    const refreshApp = function (ps, msg) {
        let comps
        if (_.isArray(msg.data.compIds)) {
            comps = getValidComps(ps, msg.data.compIds)
        } else {
            const applicationId = getApplicationId(ps, msg.compId)
            comps = tpads.app.getRenderedReactCompsByApplicationId(ps, applicationId)
        }

        tpads.app.refreshApp(ps, comps, msg.data.queryParams)
        ps.siteAPI.forceUpdate()
    }

    const openSettingsDialog = function (ps, msg, callback) {
        const applicationId = getApplicationId(ps, msg.compId)
        const comps = tpads.app.getAllCompsByApplicationId(ps, applicationId)
        let comp
        if (msg.data && msg.data.compId) {
            comp = _.find(comps, {id: msg.data.compId})

            if (_.isUndefined(comp)) {
                callback({onError: 'comp not found'})
                return
            }
        } else {
            comp = _.find(comps, {id: msg.compId})
        }

        const params = {
            pageId: comp.pageId,
            compId: comp.id
        }

        tpaPreviewEditorCommunicationService.doPostMessage('openSettingsDialog', params, msg.compId)
    }

    const isSupported = function (ps, msg, callback) {
        callback(true)
    }

    const navigateToPage = function (ps, msg, callback) {
        const primaryPageId = ps.siteAPI.getPrimaryPageId()
        const allPageIds = pageService.getPageIdList(ps, false, true)

        const getPageAnchors = function (pageId) {
            const topPageAnchorName = tpa.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
            return pageService.getPageAnchors(ps, pageId, topPageAnchorName)
        }

        const getAnchorDataId = function (anchorCompId) {
            const compPointer = componentDetectorAPI.getComponentById(ps, anchorCompId)
            const compData = component.data.get(ps, compPointer)
            return _.get(compData, 'id')
        }

        tpa.services.tpaNavigationService.navigateToPage(
            {
                scrollToAnchor: ps.siteAPI.scrollToAnchor.bind(ps.siteAPI),
                registerNavigationComplete: ps.siteAPI.registerNavigationComplete.bind(ps.siteAPI),
                navigateToPage: ps.siteAPI.navigateToPage.bind(ps.siteAPI)
            },
            msg,
            primaryPageId,
            allPageIds,
            getPageAnchors,
            getAnchorDataId,
            callback
        )
        tpaPreviewEditorCommunicationService.doPostMessage('deselectComponent')
    }

    const getExternalId = function (ps, msg, callback) {
        callback(tpads.comp.getExternalId(ps, componentDetectorAPI.getComponentById(ps, msg.compId)))
    }

    const getResultDataForUninstalledApp = function () {
        return {
            onError: true
        }
    }

    const getInstalledInstance = function (ps, msg, callback) {
        let installedInstance = {}

        const doNotFilterDemoMode = false
        const appData = tpads.app.getDataByAppDefId(ps, msg.data.appDefinitionId)
        if (_.isEmpty(appData)) {
            installedInstance = getResultDataForUninstalledApp()
        } else {
            const isInstalled = tpads.app.isInstalled(ps, msg.data.appDefinitionId, doNotFilterDemoMode)
            if (isInstalled) {
                installedInstance = {
                    instanceId: appData.instanceId
                }
            } else {
                installedInstance = getResultDataForUninstalledApp()
            }
        }

        callback(installedInstance)
    }

    const revalidateSession = function (ps, msg, callback) {
        const applicationId = getApplicationId(ps, msg.compId)
        const metaSiteId = ps.dal.get(ps.pointers.general.getMetaSiteId())

        tpads.provision.refreshAppSpecMap(
            ps,
            applicationId,
            metaSiteId,
            function (appData) {
                callback({
                    instance: appData.instance
                })
            },
            function (error) {
                callback({
                    onError: true,
                    error: {
                        errorCode: error.status,
                        errorText: error.statusText,
                        error: JSON.parse(error.response)
                    }
                })
            }
        )
    }

    const resizeComponent = function (ps, msg, callback) {
        const {compId} = msg
        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        tpads.comp.resize(ps, compPointer, msg.data.width, msg.data.height, callback)
    }

    const settingsResizeComponent = function (ps, msg, callback) {
        resizeComponent(ps, msg, callback)
    }

    const getOrigCompPointer = function (ps, compId) {
        const compData = tpaUtils.getModalCompData(ps, compId) || tpaUtils.getPopupCompData(ps, compId)
        const origCompId = _.get(compData, 'origCompId')
        return origCompId ? componentDetectorAPI.getComponentById(ps, origCompId) : null
    }

    const handleCompNotFound = function (callback) {
        callback({
            error: {
                message: 'comp not found'
            }
        })
    }

    const getPublicData = function (ps, msg, callback) {
        const {compId} = msg
        const applicationId = getApplicationId(ps, compId)
        const compPointer = componentDetectorAPI.getComponentById(ps, compId) || getOrigCompPointer(ps, compId)
        if (!applicationId || !compPointer) {
            handleCompNotFound(callback)
        } else {
            tpads.data.getPublicData(ps, applicationId, compPointer, callback)
        }
    }

    const getValue = function (ps, msg, callback) {
        const {compId} = msg
        if (msg.data.scope === tpads.data.SCOPE.APP) {
            const applicationId = getApplicationId(ps, compId)
            if (!applicationId) {
                handleCompNotFound(callback)
            } else {
                tpads.data.app.get(ps, applicationId, msg.data.key, callback)
            }
        } else {
            const compPointer = componentDetectorAPI.getComponentById(ps, compId) || getOrigCompPointer(ps, compId)
            if (!compPointer) {
                handleCompNotFound(callback)
            } else {
                tpads.data.comp.get(ps, compPointer, msg.data.key, callback)
            }
        }
    }

    const getValues = function (ps, msg, callback) {
        const {compId} = msg
        if (msg.data.scope === tpads.data.SCOPE.APP) {
            const applicationId = getApplicationId(ps, compId)
            if (!applicationId) {
                handleCompNotFound(callback)
            } else {
                tpads.data.app.getMulti(ps, applicationId, msg.data.keys, callback)
            }
        } else {
            const compPointer = componentDetectorAPI.getComponentById(ps, compId) || getOrigCompPointer(ps, compId)
            if (!compPointer) {
                handleCompNotFound(callback)
            } else {
                tpads.data.comp.getMulti(ps, compPointer, msg.data.keys, callback)
            }
        }
    }

    function getCompPointerToUpdate(ps, compPointer) {
        if (refComponent.isPartOfSharedBlock(ps, compPointer)) {
            return refComponent.getTemplateCompPointer(ps, compPointer)
        }

        return compPointer
    }

    const setValue = function (ps, msg, callback) {
        const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)

        if (!compPointer) {
            handleCompNotFound(callback)
        } else {
            const compPointerToUpdate = getCompPointerToUpdate(ps, compPointer)
            tpaDataService.set(ps, compPointerToUpdate, msg.data.key, msg.data.value, msg.data.scope, callback)
        }
    }

    const removeValue = function (ps, msg, callback) {
        const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)

        if (!compPointer) {
            handleCompNotFound(callback)
        } else {
            const compPointerToUpdate = getCompPointerToUpdate(ps, compPointer)
            tpads.data.remove(ps, compPointerToUpdate, msg.data.key, msg.data.scope, callback)
        }
    }

    const getCurrentPageAnchors = function (ps, msg, callback) {
        //we have anchors only on primary page for now
        //you should use some existing method for this, I'm sure we have one..
        const pageId = ps.siteAPI.getPrimaryPageId()
        const pagePointer = pageService.getPage(ps, pageId)
        const currentPageAnchors = componentDetectorAPI.getComponentByType(ps, 'wysiwyg.common.components.anchor.viewer.Anchor', pagePointer)
        const anchors = _.map(currentPageAnchors, anchorRef => getAnchorInfo(ps, anchorRef))

        const topPageAnchorName = tpa.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
        const topPageAnchor = utils.scrollAnchors.getPageTopAnchor(pageId, topPageAnchorName)
        const topPageAnchorInfo = {
            id: topPageAnchor.compId,
            title: topPageAnchor.name
        }
        anchors.push(topPageAnchorInfo)
        callback(anchors)
    }

    const getAnchorInfo = function (ps, anchorRef) {
        const data = component.data.get(ps, anchorRef)
        return {
            id: data.compId,
            title: data.name
        }
    }

    const getComponentInfo = function (ps, msg, callback) {
        const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
        const compData = component.data.get(ps, compPointer)
        const {applicationId} = compData
        const appData = tpads.app.getData(ps, applicationId)
        const showOnAllPages = structure.isShowOnAllPages(ps, compPointer)
        const widgetData = appData.widgets[compData.widgetId]

        const returnObj = {
            compId: msg.compId,
            showOnAllPages,
            pageId: showOnAllPages ? '' : ps.pointers.components.getPageOfComponent(compPointer).id,
            tpaWidgetId: _.get(widgetData, 'tpaWidgetId', ''),
            appPageId: _.get(widgetData, 'appPage.id', '')
        }

        callback(returnObj)
    }

    const settpads = function (tpaAPI) {
        tpads = tpaAPI
    }

    const isPageComp = function (ps, compPointer) {
        const compType = component.getType(ps, compPointer)
        return compType === tpaConstants.COMP_TYPES.TPA_SECTION || compType === tpaConstants.COMP_TYPES.TPA_MULTI_SECTION
    }

    const navigateToSectionPage = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('deselectComponent')
        //delete all but msg when santa is deprecated
        ps.siteAPI.handleDsTpaHandler({
            msg,
            ps,
            addCallback,
            callback,
            tpads,
            installedTpaAppsOnSiteService,
            componentDetectorAPI,
            isPageComp,
            routersGetters,
            structure,
            getApplicationId
        })
    }

    const addApplication = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('editorAddApplication', msg.data, msg.compId, callback)
    }

    const isApplicationInstalled = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('editorIsApplicationInstalled', msg.data, msg.compId, callback)
    }

    const isFullWidth = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('editorIsFullWidth', msg.data, msg.compId, callback)
    }

    const isCustomApplicationPermissionsGranted = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('isCustomApplicationPermissionsGranted', msg.data, msg.compId, callback)
    }

    const isGroupApplicationPermissionsGranted = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('isGroupApplicationPermissionsGranted', msg.data, msg.compId, callback)
    }

    const isAppSectionInstalled = function (ps, msg, callback) {
        let applicationId
        const appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
        if (appDefinitionId) {
            const appData = tpads.app.getDataByAppDefId(ps, appDefinitionId)
            applicationId = _.get(appData, 'applicationId')
        } else {
            applicationId = getApplicationId(ps, msg.compId)
        }

        const appPages = getAppPagesByApplicationId(ps, applicationId)
        const isInstalled = _.some(appPages, {
            tpaPageId: _.get(msg, ['data', 'sectionId'])
        })
        callback(isInstalled)
    }

    const getAppVendorProductId = function (ps, msg, callback) {
        const appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
        const appData = tpads.app.getDataByAppDefId(ps, appDefinitionId)

        if (!appData) {
            callback(null)
            return
        }

        const applicationSpecData = platform.getAppDataByApplicationId(ps, appData.applicationId)

        callback(_.get(applicationSpecData, 'vendorProductId') || null)
    }

    const smCurrentMember = function (ps, msg, callback) {
        ps.siteAPI.getMemberDetailsInPreview(function (memberData) {
            if (memberData) {
                callback(memberData)
            } else {
                callback(null)
            }
        })
    }

    /**
     * @param {ps} ps
     */
    const refreshCurrentMember = function (ps) {
        ps.siteAPI.getMemberDetailsInPreview(null, null, true)
    }

    const applicationLoaded = function (ps, msg) {
        const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
        tpaEventHandlersService.callCompLoaded(compPointer)
    }

    const onReady = function (ps, msg, callback) {
        tpaPreviewEditorCommunicationService.doPostMessage('onReady', msg.data, msg.compId, callback)
    }

    return _.mapValues(
        {
            registerEventListener,
            siteInfo,
            getSitePages,
            getSiteMap,
            refreshApp,
            refreshAppByCompIds: refreshApp,
            getSectionUrl,
            openSettingsDialog,
            isSupported,
            navigateToPage,
            getExternalId,
            getInstalledInstance,
            revalidateSession,
            resizeComponent,
            settingsResizeComponent,
            getValue,
            getValues,
            getPublicData,
            setValue,
            removeValue,
            getCurrentPageAnchors,
            getComponentInfo,
            settpads,
            navigateToSectionPage,
            getStateUrl,
            addApplication,
            applicationLoaded,
            isApplicationInstalled,
            isAppSectionInstalled,
            isFullWidth,
            getAppVendorProductId,
            smCurrentMember,
            refreshCurrentMember,
            onReady,
            isGroupApplicationPermissionsGranted,
            isCustomApplicationPermissionsGranted
        },
        (fn, name) => {
            return (...args) => {
                reportDeprecated(name)

                return fn(...args)
            }
        }
    )
})
