export default function mapStateToProps(siteAPI) {
    const siteData = siteAPI.getSiteData()

    function getCompPointer(popoverId) {
        const pageId = siteData.getCurrentUrlPageId()
        const viewMode = siteData.getViewMode()
        const rootPointer = siteAPI.getPointers().components.getPage(pageId, viewMode)
        return siteAPI.getPointers().components.getComponent(popoverId, rootPointer)
    }

    function getModeId(compPointer) {
        const dal = siteAPI.getDisplayedDAL()
        const pointers = siteAPI.getPointers()
        const modesDefinitionsPointer = pointers.componentStructure.getModesDefinitions(compPointer)
        return dal.get(modesDefinitionsPointer)
    }

    function toggleMode(popoverId, toggle) {
        const compPointer = getCompPointer(popoverId)
        const {modeId} = getModeId(compPointer)[0]
        toggle(compPointer, modeId)
    }

    function activateMode(popoverId) {
        toggleMode(popoverId, siteAPI.activateMode)
    }

    function deactivateMode(popoverId) {
        toggleMode(popoverId, siteAPI.deactivateMode)
    }

    function getComponentByPageAndCompId(compId) {
        const rootId = siteAPI.getRootOfComponentId(compId)
        return siteAPI.getComponentByPageAndCompId(rootId, compId)
    }

    function getScrollPosition() {
        const windowScrollEventAspect = siteAPI.getSiteAspect('windowScrollEvent')
        return windowScrollEventAspect.getScrollPosition()
    }

    function transaction(fn) {
        const actionQueue = siteAPI.getActionQueue()
        actionQueue.runImmediately(fn)
    }

    function getTargetPortal() {
        return siteAPI.getPopoversLayer()
    }

    function registerToDocumentClickEvent(popoverId) {
        const windowClickEventAspect = siteAPI.getSiteAspect('windowClickEventAspect')
        windowClickEventAspect.registerToDocumentClickEvent(popoverId)
    }

    function unRegisterToDocumentClickEvent(popoverId) {
        const windowClickEventAspect = siteAPI.getSiteAspect('windowClickEventAspect')
        windowClickEventAspect.unRegisterToDocumentClickEvent(popoverId)
    }

    return {
        deactivateMode,
        activateMode,
        getComponentByPageAndCompId,
        getScrollPosition,
        transaction,
        getTargetPortal,
        registerToDocumentClickEvent,
        unRegisterToDocumentClickEvent,
        isPopoverAllowed: siteAPI.getRenderFlag('componentViewMode') === 'preview',
        isMobileView: siteData.isMobileView()
    }
}
