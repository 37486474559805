import {CoreConfig, CoreLogger, createDMCore, DocumentManager, DSConfig} from '@wix/document-manager-core'
import type {ServiceTopology} from '@wix/document-manager-extensions/src/extensions/serviceTopology'
import type {DataFixer, Experiment} from '@wix/document-services-types'
import * as santaDataFixer from '@wix/santa-data-fixer'
import _ from 'lodash'
import {createConfig, isCEditOpen} from './createConfig'
import {createDataMigrationRunner, DataMigrationRunner} from './dataMigration/dataMigrationRunner'
import {loadExtensions} from './extensionLoader'
import {initialize, ServerStore} from './initialize/mainInitialization'
import type {DocumentServicesModel, DocumentServicesModelForServer, RendererModel, RendererModelForServer} from './initialize/modelTypes'
import type {BootstrapConfig} from './types'

export {isCEditOpen}
export type EnvironmentContext = Record<string, any>

const buildCoreConfig = (config: DSConfig, experimentInstance: Experiment, logger: CoreLogger, schemaService: any): CoreConfig => {
    const {origin} = config

    const strictModeFailDefault = experimentInstance.isOpen('dm_strictModeFail')

    return {
        experimentInstance,
        logger,
        schemaService,
        signatureSeed: config.signatureSeed,
        strictModeFailDefault,
        checkConflicts: isCEditOpen(origin, experimentInstance),
        undoRedoConfig: config.undoRedoConfig,
        dontCollectFixerVersionData: config.dontCollectFixerVersionData
    }
}

/**
 * Create a working document Manager which includes the core, registered extensions and initialized state
 * the Document Manager is ready to accept set operations. Public APIs are not initialized yet
 */
const createDocumentManager = (
    config: DSConfig,
    experimentInstance: Experiment,
    logger: CoreLogger,
    schemaService: any,
    environmentContext: EnvironmentContext
): DocumentManager => {
    const coreConfig = buildCoreConfig(config, experimentInstance, logger, schemaService)
    const core = createDMCore(coreConfig)
    loadExtensions(core, config, environmentContext)
    return core
}

interface Models {
    serviceTopology: ServiceTopology
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    rendererModel: RendererModelForServer | RendererModel
}

export interface CreateHostArgs {
    models: Models
    partialPages?: string[]
    config: BootstrapConfig
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    environmentContext?: EnvironmentContext
    dataFixer?: DataFixer
    dataMigrationRunner?: DataMigrationRunner
}

export interface Host {
    readonly documentManager: DocumentManager
    readonly config: DSConfig
    _dsInitTimeoutHandler?: any //should be number
    runInitializers(): Promise<{store: ServerStore}>
    environmentContext: EnvironmentContext
}

export const createHost = ({
    models,
    partialPages = [],
    config: bootstrapConfig,
    experimentInstance,
    logger,
    schemaService,
    environmentContext = {},
    dataFixer = santaDataFixer,
    dataMigrationRunner = createDataMigrationRunner()
}: CreateHostArgs): Host => {
    const config: DSConfig = createConfig(bootstrapConfig, experimentInstance)
    const documentManager = createDocumentManager(config, experimentInstance, logger, schemaService, environmentContext)

    const runInitializers = async (): Promise<{store: ServerStore}> => {
        await documentManager.createServiceAPIs({config})
        const {serviceTopology, documentServicesModel, rendererModel} = models
        const initResult = await initialize({
            documentManager,
            partialPages,
            dataFixer,
            dataMigrationRunner,
            serviceTopology,
            documentServicesModel,
            rendererModel,
            config,
            logger,
            fetchFn: _.get(environmentContext, ['fetchFn']),
            trackingFn: _.get(environmentContext, ['trackingFn']),
            experimentInstance
        })
        await documentManager.initialize()
        return initResult
    }

    return {
        documentManager,
        runInitializers,
        config,
        environmentContext
    }
}
