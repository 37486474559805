define(['lodash', '@wix/santa-core-utils', 'documentServices/constants/constants', 'documentServices/component/componentStructureInfo'], function (
    _,
    santaCoreUtils,
    constants,
    componentStructureInfo
) {
    'use strict'

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Pointer} oldParentPointer
     * @param {Pointer} oldParentPointer
     */
    function updateAnchors(ps, componentPointer, oldParentPointer) {
        if (componentPointer) {
            registerCompToRecalculateAnchorsLater(ps, componentPointer)
        }
        if (oldParentPointer) {
            registerCompToRecalculateAnchorsLater(ps, oldParentPointer)
        }
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function updateAnchorsForCompChildren(ps, componentPointer) {
        const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
        const pageId = pagePointer.id
        const compStructure = ps.dal.get(componentPointer)
        const forceMobileStructure = ps.pointers.components.getViewMode(componentPointer) === constants.VIEW_MODES.MOBILE
        ps.siteAPI.createChildrenAnchors(compStructure, pageId, {forceMobileStructure})
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     */
    function registerCompToRecalculateAnchorsLater(ps, componentPointer) {
        const compsToUpdateAnchorsPointer = ps.pointers.general.getCompsToUpdateAnchors()
        const currentValue = ps.dal.get(compsToUpdateAnchorsPointer)
        if (!currentValue) {
            ps.dal.set(compsToUpdateAnchorsPointer, [componentPointer])
        } else {
            const isDuplicate = currentValue.find(ptr => _.isEqual(componentPointer, ptr))
            if (!isDuplicate) {
                ps.dal.set(compsToUpdateAnchorsPointer, currentValue.concat(componentPointer))
            }
        }
    }

    /**
     * @param {ps} ps
     * @param compsToUpdate
     * @returns {*}
     */
    function getParentCompsForNonDisplayedOnlyComps(ps, compsToUpdate) {
        return _.transform(
            compsToUpdate,
            function (res, compPointer) {
                if (!santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)) {
                    const parent = ps.pointers.full.components.getParent(compPointer)
                    if (parent) {
                        res.push(parent)
                    }
                }
            },
            []
        )
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {*}
     */
    function getNonDisplayedOnlyAncestor(ps, compPointer) {
        return componentStructureInfo.getAncestorByPredicate(ps, compPointer, function (parentPointer) {
            return !santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(parentPointer.id)
        })
    }

    /**
     * @param {ps} ps
     */
    function updateAnchorsForMultipleComps(ps) {
        const compsToUpdateAnchorsPointer = ps.pointers.general.getCompsToUpdateAnchors()
        const compsToUpdate = ps.dal.get(compsToUpdateAnchorsPointer) || []

        _(compsToUpdate)
            .concat(getParentCompsForNonDisplayedOnlyComps(ps, compsToUpdate))
            .map(function (compPointer) {
                if (santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)) {
                    return getNonDisplayedOnlyAncestor(ps, compPointer)
                }

                return compPointer
            })
            .uniqBy('id')
            .compact()
            .filter(function (compPointer) {
                const page = ps.pointers.components.getPageOfComponent(compPointer)
                if (!page) {
                    //component was removed in same queue as it was added, so it was registered for anchors update but no longer exists
                    return false
                }
                return ps.siteAPI.isComponentRenderedOnSite(page.id)
            })
            .forEach(_.partial(updateAnchorsForCompChildren, ps))

        ps.dal.set(compsToUpdateAnchorsPointer, [])
    }

    /**
     * @param f
     * @returns {function(ps, ...[*]=)}
     */
    const ifAdapterUpdatesAnchors =
        f =>
        (ps, ...args) => {
            if (!ps.runtimeConfig.adapterUpdatesAnchors) {
                return
            }
            f(ps, ...args)
        }

    return _.mapValues(
        {
            updateAnchors,
            updateAnchorsForCompChildren,
            updateAnchorsForMultipleComps
        },
        ifAdapterUpdatesAnchors
    )
})
