import _ from 'lodash'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import warmupUtilsLib from '@wix/santa-core-utils'

const {VIDEO} = warmupUtilsLib.mediaConsts.balataConsts
const {alignTypes} = imageClientLib
const videoPositions = {
    [alignTypes.CENTER]: positionOptions => ({left: positionOptions.horizontalMiddle, top: positionOptions.verticalMiddle}),
    [alignTypes.LEFT]: positionOptions => ({left: 0, top: positionOptions.verticalMiddle}),
    [alignTypes.RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: positionOptions.verticalMiddle}),
    [alignTypes.TOP]: positionOptions => ({left: positionOptions.horizontalMiddle, top: 0}),
    [alignTypes.BOTTOM]: positionOptions => ({left: positionOptions.horizontalMiddle, top: positionOptions.verticalBottom}),
    [alignTypes.TOP_LEFT]: () => ({left: 0, top: 0}),
    [alignTypes.TOP_RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: 0}),
    [alignTypes.BOTTOM_LEFT]: positionOptions => ({left: 0, top: positionOptions.verticalBottom}),
    [alignTypes.BOTTOM_RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: positionOptions.verticalBottom})
}

/**
 *
 * @param {HTMLVideoElement} videoNode
 * @param {string} newSrcUrl
 * @param {boolean} hasError
 * @returns {*|boolean}
 */
function shouldUpdateSrc(videoNode, newSrcUrl, hasError) {
    const hasDiff = !videoNode.currentSrc.endsWith(newSrcUrl)
    return newSrcUrl && (hasDiff || hasError)
}

function getMP4Url(qualities, targetQuality, staticVideoUrl, videoId, videoFormat) {
    if (videoFormat === 'mp4') {
        const targetQualityItem = _.keyBy(qualities, 'quality')[targetQuality]
        //prefer video url from new design data structure
        if (targetQualityItem.url) {
            return warmupUtilsLib.urlUtils.joinURL(staticVideoUrl, targetQualityItem.url)
        }
        //build uri from videoId
        return warmupUtilsLib.urlUtils.joinURL(staticVideoUrl, videoId, targetQuality, 'mp4', 'file.mp4')
    }
    return ''
}

function getVideoQualityBySize(qualities, width, height) {
    const uniqQualities = _.uniqBy([...qualities].reverse(), 'size').reverse()
    const targetQuality = _.find(uniqQualities, value => value.size > width * height) || _.last(qualities)
    return targetQuality.quality
}

function getScaleFactor(containerWidth, containerHeight, videoWidth, videoHeight) {
    return {wScale: containerWidth / videoWidth, hScale: containerHeight / videoHeight}
}

function getVideoDimension(fittingType = imageClientLib.fittingTypes.SCALE_TO_FILL, videoScale, videoWidth, videoHeight) {
    let scale
    switch (fittingType) {
        case imageClientLib.fittingTypes.SCALE_TO_FILL:
            scale = Math.max(videoScale.wScale, videoScale.hScale)
            break
        case imageClientLib.fittingTypes.SCALE_TO_FIT:
            scale = Math.min(videoScale.wScale, videoScale.hScale)
            break
    }
    return {
        width: Math.round(videoWidth * scale),
        height: Math.round(videoHeight * scale)
    }
}

function getVideoPosition(alignType = imageClientLib.alignTypes.CENTER, videoSize, videoContainerSize) {
    const {width: containerWidth, height: containerHeight} = videoContainerSize
    const {width: videoWidth, height: videoHeight} = videoSize
    return videoPositions[alignType]({
        verticalMiddle: Math.round((containerHeight - videoHeight) / 2),
        horizontalMiddle: Math.round((containerWidth - videoWidth) / 2),
        verticalBottom: containerHeight - videoHeight,
        horizontalRight: containerWidth - videoWidth
    })
}

/**
 *
 * @param {boolean} shouldUpdateListener
 * @param {HTMLVideoElement} videoNode
 * @param {HTMLDivElement} posterNode
 * @param {boolean} animatePoster
 * @param {boolean} autoplay
 * @param {boolean} isEditorMode
 */
function handlePosterVisibility(shouldUpdateListener, videoNode, posterNode, animatePoster, autoplay, isEditorMode) {
    //todo: (preview -> Editor case) move to editor layout hooks
    //bring back the poster (preview -> Editor case)
    if (isEditorMode && videoNode.paused) {
        posterNode.style.opacity = '1'
        videoNode.style.opacity = '0'
    }
    //todo: (preview -> Editor case) move to editor layout hooks
    //register to events when updating src or when in Editor(preview -> Editor case)
    if (shouldUpdateListener || isEditorMode) {
        if (autoplay) {
            videoNode.ontimeupdate = () => {
                if (videoNode.currentTime > 0) {
                    videoNode.addEventListener('seeked', function handleSeeked() {
                        videoNode.ontimeupdate = null
                        videoNode.removeEventListener('seeked', handleSeeked)
                        removePoster(videoNode, posterNode, animatePoster)
                    })
                    videoNode.currentTime = 0
                }
            }
        } else {
            videoNode.onplay = () => {
                videoNode.onplay = null
                removePoster(videoNode, posterNode, animatePoster)
            }
        }
    }
}

/**
 * set and load the video url
 * @param {boolean} needsSrcUpdate
 * @param {HTMLVideoElement} videoNode
 * @param {string} newSrc
 */
function patchVideoSource(needsSrcUpdate, videoNode, newSrc) {
    if (needsSrcUpdate) {
        videoNode.src = newSrc
        videoNode.load()
    }
}

/**
 * show video , hide poster
 * @param videoNode
 * @param posterNode
 * @param animatePoster
 */
function removePoster(videoNode, posterNode, animatePoster) {
    if (animatePoster === 'fade') {
        posterNode.style.transition = 'opacity 1.6s ease-out'
    }
    posterNode.style.opacity = '0'
    videoNode.style.opacity = '1'
}

function clearVideoSource(videoNode, videoFormat) {
    if (videoFormat === 'mp4') {
        videoNode.src = ''
        videoNode.load()
    }
}

function measureBgVideo(parentId, id, posterId, measureMap, nodesMap) {
    const videoNode = nodesMap[id + VIDEO]
    const posterNode = nodesMap[posterId]
    measureMap.custom[id] = {}

    const videoInfo = JSON.parse(nodesMap[id].dataset.videoInfo)

    const {
        isVideoDataExists,
        videoWidth,
        videoHeight,
        qualities,
        staticVideoUrl,
        videoId,
        videoFormat,
        alignType,
        fittingType,
        hasBgScrollEffect,
        autoPlay,
        animatePoster,
        isEditorMode,
        isViewerMode,
        playbackRate
    } = videoInfo

    if (isVideoDataExists) {
        // in cases were background has a full height scroll effect we use the parent width ,
        // the element position is fixed and without explicit width he will take the window width
        //const qualitiesMeasures = JSON.parse(qualities);
        const width = hasBgScrollEffect ? measureMap.width[parentId] : measureMap.width[id]
        const height = measureMap.height[id]
        const vidWidth = parseInt(videoWidth, 10)
        const vidHeight = parseInt(videoHeight, 10)
        const scaleFactor = getScaleFactor(width, height, vidWidth, vidHeight)
        const videoScaledDimension = getVideoDimension(fittingType, scaleFactor, vidWidth, vidHeight)
        const videoPosition = getVideoPosition(alignType, videoScaledDimension, {width, height})
        const targetQuality = getVideoQualityBySize(qualities, videoScaledDimension.width, videoScaledDimension.height)
        const videoStyle = {
            width: videoScaledDimension.width,
            height: videoScaledDimension.height,
            left: videoPosition.left,
            top: videoPosition.top
        }
        const hasEditingError = videoNode.error && videoNode.networkState === videoNode.NETWORK_NO_SOURCE && !isViewerMode
        const videoSourceUrl = getMP4Url(qualities, targetQuality, staticVideoUrl, videoId, videoFormat)
        const needsSrcUpdate = shouldUpdateSrc(videoNode, videoSourceUrl, hasEditingError)

        measureMap.custom[id] = {
            hasRemovePosterHandler: !!videoNode.ontimeupdate,
            isVideoDataExists,
            videoSourceUrl,
            needsSrcUpdate,
            videoNode,
            posterNode,
            videoFormat,
            autoPlay,
            animatePoster,
            videoStyle,
            playbackRate,
            isEditorMode
        }
    } else {
        measureMap.custom[id] = {
            videoNode,
            isVideoDataExists
        }
    }
}

function patchBgVideo(id, canvasId, patchers, measureMap, prefersReducedMotion) {
    const customMeasure = measureMap.custom[id]
    const {
        videoStyle,
        videoSourceUrl,
        videoNode,
        posterNode,
        isVideoDataExists,
        videoFormat,
        autoPlay,
        animatePoster,
        isEditorMode,
        needsSrcUpdate,
        hasRemovePosterHandler,
        playbackRate
    } = customMeasure
    const isAutoPlay = prefersReducedMotion ? false : customMeasure.autoPlay

    if (isVideoDataExists) {
        const hasCanvas = measureMap.width[canvasId] !== undefined
        patchers.attr(id + VIDEO, {
            width: videoStyle.width,
            height: videoStyle.height,
            autoplay: isAutoPlay && !hasCanvas ? true : null
        })
        patchers.css(id + VIDEO, videoStyle)

        if (hasCanvas) {
            patchers.css(canvasId, videoStyle)
        } else {
            const shouldUpdateListener = needsSrcUpdate || (!hasRemovePosterHandler && videoFormat === 'hls')
            handlePosterVisibility(shouldUpdateListener, videoNode, posterNode, animatePoster, autoPlay, isEditorMode)
        }
        patchVideoSource(needsSrcUpdate, videoNode, videoSourceUrl)
        videoNode.playbackRate = playbackRate
    } else {
        clearVideoSource(videoNode, videoFormat)
    }
}

export default {
    patchBgVideo,
    measureBgVideo
}
