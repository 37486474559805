// @ts-nocheck
;(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], factory)
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory()
    } else {
        // Browser globals (root is window)
        root.returnExports = factory()
    }
})(typeof self !== 'undefined' ? self : this, function () {
    // Just return a value to define the module export.
    // This example returns an object, but the module
    // can return a function as the exported value.
    'use strict'
    const skins = {}
    skins['wysiwyg.skins.AppPartZoomSkin'] = {
        react: [],
        exports: {
            appPart: {
                skin: 'wysiwyg.viewer.skins.AppPartSkin'
            },
            mediaZoom: {
                skin: 'wysiwyg.viewer.skins.AppPartMediaZoomSkin'
            }
        },
        css: {}
    }
    skins['wysiwyg.skins.MobileAppPartZoomSkin'] = {
        react: [],
        exports: {
            appPart: {
                skin: 'wysiwyg.viewer.skins.AppPartSkin'
            },
            mediaZoom: {
                skin: 'wysiwyg.viewer.skins.AppPartMobileMediaZoomSkin'
            }
        },
        css: {}
    }
    skins['ecommerce.skins.mcom.LoadMoreButton'] = {
        react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
        params: {
            txt: 'TEXT_COLOR',
            fnt: 'FONT'
        },
        paramsDefaults: {
            txt: '#ffffff',
            fnt: 'font_7'
        },
        css: {
            '%': 'padding:15px 0;',
            '%link': 'width:100%;text-align:center;display:block;',
            '%label': 'color:[txt];[fnt]  display:block;'
        }
    }
    skins['ecommerce.skins.mcom.MobileAddButton'] = {
        react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
        params: {
            txt: 'BORDER_COLOR',
            rd: 'BORDER_RADIUS',
            shadow: 'BOX_SHADOW'
        },
        paramsDefaults: {
            txt: '#737373',
            rd: '4px',
            shadow: 'inset 0 -13px 20px -10px 0 rgba(0, 0, 0, 0.2)'
        },
        css: {
            '%link': 'display:block;position:absolute;top:0;right:0;bottom:0;left:0;color:[txt];border:1px solid [txt];background:#ffffff;[rd]  [shadow]',
            '%label': 'font-size:28px;font-weight:bold;line-height:30px;'
        }
    }
    skins['ecommerce.skins.mcom.MobileAddCouponButton'] = {
        react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
        params: {
            txt: 'BORDER_COLOR',
            rd: 'BORDER_RADIUS'
        },
        paramsDefaults: {
            txt: '#222222',
            rd: '300px'
        },
        css: {
            '%link': 'display:block;position:absolute;top:0;right:0;bottom:0;left:0;color:[txt];border:7px solid [txt];background:#ffffff;[rd]',
            '%link:before': 'display:block;content:"";position:absolute;right:35%;left:35%;top:0;bottom:50%;margin-bottom:-7px;border-bottom:14px solid [txt];',
            '%link:after': 'display:block;content:"";position:absolute;top:35%;bottom:35%;left:0;right:50%;margin-right:-7px;border-right:14px solid [txt];'
        }
    }
    skins['ecommerce.skins.mcom.MobileColorOptionSkin'] = {
        react: [
            ['p', 'description', [], {}],
            ['div', 'size', ['_selectedFrame'], {}]
        ],
        params: {
            tdr: 'URL'
        },
        paramsDefaults: {
            tdr: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%': 'position:relative;margin:0 7px 7px 0;display:inline-block;width:47px;height:50px;border:solid 1px #8e8e8e;',
            '%_selectedFrame': 'position:absolute;top:0;right:0;bottom:0;left:0;',
            '%[data-state~="selected"] %_selectedFrame':
                'position:absolute;top:0;right:0;bottom:0;left:0;background:transparent url([tdr]wix_apps/smallcheck.png) no-repeat 50% 50%;background-size:22px 21px;',
            '%[data-state~="disabled"],%description': 'display:none;',
            '%description': 'display:none;'
        }
    }
    skins['ecommerce.skins.mcom.MobileComboBoxSkin'] = {
        react: [
            ['select', 'collection', [], {}],
            ['div', null, ['_err'], {}]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            bg: 'BG_COLOR_ALPHA',
            txt: 'TEXT_COLOR',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR',
            bg2: 'BG_COLOR_ALPHA'
        },
        paramsDefaults: {
            rd: '5px',
            bg: '#ffffff',
            txt: '#888888',
            brw: '2px',
            brd: '#888888',
            bg2: '#aaaaaa'
        },
        css: {
            '%': 'position:relative;display:inline-block !important;',
            '%collection':
                '[rd]  -webkit-appearance:none;-moz-appearance:none;background-color:[bg];color:[txt];border:solid [brw] [brd];padding:5px 18px 5px 5px;margin:0 !important;position:relative;min-width:100%;min-height:100%;font-size:60px;line-height:60px;text-align:center;margin-left:60px;',
            '%collection option': 'min-width:100%;text-align:center;',
            '%:after':
                'content:"▼";position:absolute;[rd]  background-color:[bg2];top:1px;bottom:1px;right:1px;width:90px;pointer-events:none;color:#fff;text-align:center;font-size:40px;line-height:80px;border-bottom-left-radius:0;border-top-left-radius:0;',
            '%_err': 'position:absolute;top:50%;left:0;height:1px;width:1px;',
            '%[data-state~="invalid"] %_err:before':
                'color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:"!";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;',
            '%[data-state~="invalid"] %_err:after':
                'color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:"►";position:absolute;top:10px;left:8px;'
        }
    }
    skins['ecommerce.skins.mcom.MobileDeleteItemButton'] = {
        react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
        params: {
            tdr: 'URL'
        },
        paramsDefaults: {
            tdr: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%link':
                'position:absolute;top:0;right:0;bottom:0;left:0;background-size:100%;background:transparent url([tdr]wix_apps/deletebutton.png) center top no-repeat;',
            '%label': 'display:none;'
        }
    }
    skins['ecommerce.skins.mcom.MobileDropDownInputSkin2'] = {
        react: [['select', 'collection', [], {}]],
        css: {
            '%': 'position:relative;',
            '%collection': 'width:100%;height:100%;cursor:pointer;',
            '%collection option': 'padding:2px;'
        }
    }
    skins['ecommerce.skins.mcom.MobileErasableTextInputSkin'] = {
        react: [
            ['label', 'label', [], {}],
            [
                'input',
                'input',
                [],
                {
                    type: 'text'
                }
            ],
            ['span', 'erase', [], {}],
            ['p', 'message', [], {}]
        ],
        params: {
            bg: 'BG_COLOR_ALPHA',
            rd: 'BORDER_RADIUS',
            txt: 'TEXT_COLOR',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR'
        },
        paramsDefaults: {
            bg: '#ffffff',
            rd: '2px',
            txt: '#222222',
            brw: '1px',
            brd: '#aaa9a9'
        },
        css: {
            '%': 'position:relative;',
            '%input': 'background-color:[bg];[rd]  color:[txt];border:[brw] solid [brd];padding:7px 3px;margin:0;font-size:20px;line-height:20px;width:100%;',
            '%erase':
                'position:absolute;font-size:20px;right:3px;cursor:pointer;width:33px;height:auto;text-align:center;line-height:20px;color:[txt];padding:9px 0;',
            '%message': 'display:none;',
            '%[data-state~="showButton"] %erase': 'display:inline-block;',
            '%[data-state~="showButton"] %input': 'padding-right:38px;',
            '%[data-state~="hideButton"] %erase': 'display:none;'
        }
    }
    skins['ecommerce.skins.mcom.MobileOptionsListInputSkin'] = {
        react: [],
        css: {
            '%': 'margin-right:-33px;'
        }
    }
    skins['ecommerce.skins.mcom.MobilePaginatedGridDefaultSkin'] = {
        react: [
            ['div', 'itemsContainer', [], {}],
            ['div', 'rolloverHolder', [], {}, ['h3', 'title', [], {}], ['p', 'description', [], {}], ['a', 'link', [], {}]],
            ['div', null, ['_buttons'], {}, ['a', 'buttonPrev', [], {}], ['a', 'buttonNext', [], {}]],
            ['div', 'counter', ['_hlp', '_opc'], {}],
            ['div', 'autoplay', ['_hlp', '_opc'], {}, ['span', null, [], {}]]
        ],
        css: {
            '%_opc,%title,%description,%link,%buttonPrev,%buttonNext,%_buttons': 'display:none;'
        }
    }
    skins['ecommerce.skins.mcom.MobileRemoveButton'] = {
        react: [['a', 'link', [], {}, ['span', 'label', [], {}]]],
        params: {
            txt: 'BORDER_COLOR',
            tdr: 'URL',
            rd: 'BORDER_RADIUS',
            shadow: 'BOX_SHADOW'
        },
        paramsDefaults: {
            txt: '#373737',
            tdr: 'BASE_THEME_DIRECTORY',
            rd: '4px',
            shadow: 'inset 0 -13px 20px -10px 0 rgba(0, 0, 0, 0.2)'
        },
        css: {
            '%': 'cursor:pointer;',
            '%link':
                'display:block;position:absolute;top:0;right:0;bottom:0;left:0;color:[txt];border:1px solid [txt];background:#fff url([tdr]close.gif) no-repeat 50% 50%;[rd]  [shadow]  font-size:23px;font-weight:bold;line-height:30px;text-align:center;',
            '%label': 'width:100%;height:100%;'
        }
    }
    skins['ecommerce.skins.mcom.MobileSelectableSliderGalleryDefaultSkin'] = {
        react: [
            ['div', 'itemsContainer', [], {}, ['div', 'images', [], {}]],
            ['div', 'swipeLeftHitArea', ['_arr'], {}],
            ['div', 'swipeRightHitArea', ['_arr'], {}],
            ['div', 'counter', [], {}],
            ['div', 'autoplay', [], {}]
        ],
        exports: {
            imageItem: {
                skin: 'wysiwyg.viewer.skins.galleryselectableslider.SelectableSliderDisplayerDefaultSkin',
                styleGroup: 'inherit'
            },
            itemContainerTotalOffset: 70
        },
        css: {
            '%itemsContainer': 'left:35px !important;right:35px !important;height:100%;',
            '%_arr,%counter,%autoplay': 'display:none;'
        }
    }
    skins['ecommerce.skins.mcom.MobileSelectOptionsListSkin'] = {
        react: [
            ['div', null, ['_tipContainer'], {}, ['p', 'tooltip', [], {}]],
            ['div', 'itemsContainer', [], {}]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            trans: 'TRANSITION',
            trans1: 'TRANSITION',
            bg: 'BG_COLOR'
        },
        paramsDefaults: {
            rd: '2px',
            trans: 'opacity 0.3s ease-out 0s',
            trans1: 'opacity 1.2s ease-out 0s',
            bg: '#000000'
        },
        paramsMutators: {
            bg: {
                type: 'alpha',
                value: 0.66
            }
        },
        css: {
            '%': 'position:relative;',
            '%_tipContainer': 'text-align:center;position:absolute;top:-57px;left:0;right:0;',
            '%tooltip': 'display:inline-block;padding:12px 15px;min-width:33px;background-color:[bg];[rd]  color:#ffffff;font-size:15px;margin:0 auto;',
            '%[data-state~="displayed"] %tooltip': 'opacity:1;[trans]',
            '%[data-state~="hidden"] %tooltip': 'opacity:0;[trans1]'
        }
    }
    skins['ecommerce.skins.mcom.MobileTextAreaInputSkin'] = {
        react: [
            ['textarea', 'textarea', [], {}],
            ['p', 'errorMessage', [], {}]
        ],
        params: {
            txt: 'COLOR',
            bg: 'BG_COLOR_ALPHA',
            rd: 'BORDER_RADIUS',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR_ALPHA',
            brdf: 'BORDER_COLOR_ALPHA'
        },
        paramsDefaults: {
            txt: '#000000',
            bg: '#ffffff',
            rd: '5px',
            brw: '1px',
            brd: '#a3a0a1',
            brdf: '#19a0e9'
        },
        css: {
            '%': 'position:relative;',
            '%textarea':
                'text:[txt];box-sizing:border-box;background-color:[bg];[rd]  resize:none;width:100%  !important;min-height:33px;height:6.1em;overflow:auto;font-size:15px;line-height:1.4em;border:[brw] solid [brd];padding:3px 7px;box-shadow:0 1px 1px 0 rgba(0, 0, 0, 0.3) inset;',
            '%textarea:focus': 'border-color:[brdf];'
        }
    }
    skins['ecommerce.skins.mcom.MobileTextOptionSkin'] = {
        react: [
            ['p', 'size', [], {}],
            ['p', 'description', [], {}],
            ['p', null, ['_selectedCheckmark'], {}]
        ],
        params: {
            tdr: 'URL'
        },
        paramsDefaults: {
            tdr: 'BASE_THEME_DIRECTORY'
        },
        css: {
            '%': 'background:#ffffff;color:#000000;margin:0 7px 7px 0;padding:0 7px;min-width:33px;height:50px;font-size:13px;line-height:50px;display:inline-block;border:solid 1px #8e8e8e;text-align:center;position:relative;',
            '%[data-state~="selected"] %_selectedCheckmark':
                'position:absolute;top:0;right:0;bottom:0;left:0;background:rgba(30, 30, 30, 0.5) url([tdr]wix_apps/smallcheck.png) no-repeat 50% 50%;background-size:22px 21px;',
            '%[data-state~="selected"] %size': 'display:inline;',
            '%[data-state~="disabled"]': 'display:none;',
            '%description': 'display:none;',
            '%size': 'cursor:default;'
        }
    }
    skins['ecommerce.skins.mcom.MobileVerticalRepeaterSkin'] = {
        react: [['div', 'inlineContent', [], {}]],
        params: {
            bg: 'BG_COLOR_ALPHA',
            brw: 'BORDER_SIZE',
            brd: 'BORDER_COLOR_ALPHA',
            pad: 'PADDING_SIZE'
        },
        paramsDefaults: {
            bg: 'color_11',
            brw: '1px',
            brd: 'color_15',
            pad: '10px 0'
        },
        css: {
            '%': 'background-color:[bg];',
            '% > %inlineContent': 'border:[brw] solid [brd];',
            '% > %inlineContent:last-child': 'border-bottom:0;',
            '% > %inlineContent > *': 'border-bottom:[brw] solid [brd];padding:[pad] 0;'
        }
    }
    skins['ecommerce.skins.mcom.MobileZoomPartSkin'] = {
        react: [
            ['div', 'inlineContent', [], {}],
            ['div', 'error', [], {}],
            ['div', 'loading', [], {}, ['p', null, [], {}]]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            bg: 'BG_COLOR'
        },
        paramsDefaults: {
            rd: '7px',
            bg: '#000'
        },
        paramsMutators: {
            bg: {
                type: 'alpha',
                value: 0.4
            }
        },
        css: {
            '%[data-state="content"] %error': 'display:none;',
            '%[data-state="content"] %loading': 'display:none;',
            '%[data-state="error"] %inlineContent': 'display:none;',
            '%[data-state="error"] %loading': 'display:none;',
            '%[data-state="loading"] %loading': 'position:fixed;top:0;right:0;bottom:0;left:0;background-color:[bg];',
            '%[data-state="rendering"] %loading': 'position:fixed;top:0;right:0;bottom:0;left:0;background-color:[bg];',
            '%[data-state="loading"] %loading p':
                'color:#fff;font-size:14px;font-weight:bold;text-align:center;line-height:50px;letter-spacing:1px;height:100px;width:200px;position:absolute;top:50%;margin-top:-50px;left:50%;margin-left:-100px;[rd]',
            '%[data-state="loading"] %error': 'display:none;',
            '%[data-state="rendering"] %inlineContent': 'opacity:0;',
            '%[data-state="rendering"] %error': 'display:none;',
            '%[data-state="rendering"] %loading p': 'display:none;',
            '%[data-state="content"] %inlineContent': 'opacity:1;',
            '%inlineContent > div': 'width:100%;',
            '%inlineContent': 'box-sizing:border-box !important;',
            '%_singleLine': 'white-space:nowrap;display:block;overflow:hidden;text-overflow:ellipsis;word-wrap:normal;'
        }
    }
    skins['ecommerce.skins.viewer.dialogs.EcomCheckoutMessageDialogSkin'] = {
        react: [
            [
                'div',
                'blockingLayer',
                [],
                {},
                [
                    'div',
                    'dialog',
                    [],
                    {},
                    ['div', 'closeButton', [], {}],
                    ['p', 'title', [], {}],
                    ['p', 'subtitle', [], {}],
                    ['p', 'description', [], {}],
                    ['div', null, ['_actionButtons'], {}, ['div', 'tryButton', [], {}], ['div', 'upgradeButton', [], {}]]
                ]
            ]
        ],
        params: {
            rd: 'BORDER_RADIUS',
            tdr: 'URL',
            tdr2: 'URL',
            shadowColor: 'BOX_SHADOW_COLOR_ALPHA',
            shadowInsetColorOver: 'BOX_SHADOW_COLOR_ALPHA',
            shadowInsetColor: 'BOX_SHADOW_COLOR_ALPHA',
            bg: 'BG_COLOR'
        },
        paramsDefaults: {
            rd: '5px',
            tdr: 'WEB_THEME_DIRECTORY',
            tdr2: 'BASE_THEME_DIRECTORY',
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowInsetColorOver: 'rgba(204, 68, 233, 0.7)',
            shadowInsetColor: 'rgba(0, 0, 0, 0.7)',
            bg: '#000'
        },
        paramsMutators: {
            bg: {
                type: 'alpha',
                value: 0.75
            }
        },
        css: {
            '%': 'position:fixed;width:100%;height:100%;z-index:99;color:#373737;',
            '%blockingLayer': 'background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;',
            '%dialog':
                '[rd]  position:absolute;top:30%;left:50%;margin-left:-340px;width:680px;box-sizing:border-box;background:#fdfdfd;text-align:center;padding:34px 25px 28px;',
            '%closeButton': 'position:absolute;top:12px;right:12px;cursor:pointer;background:url([tdr]/x.png) no-repeat;height:10px;width:10px;',
            '%title':
                'font-size:20px;margin-bottom:5px;font-weight:bold;font-family:"HelveticaNeueW01-75Bold", "HelveticaNeueW02-75Bold", "HelveticaNeueW10-75Bold", Helvetica, Arial, sans-serif;',
            '%subtitle':
                'font-size:17px;margin-bottom:45px;font-family:"HelveticaNeueW01-55", "HelveticaNeueW02-55", "HelveticaNeueW10-55", Helvetica, Arial, sans-serif;',
            '%description':
                'max-height:650px;min-height:23px;height:auto;font-size:15px;line-height:18px;margin-bottom:60px;font-family:"HelveticaNeueW01-55Roma", "HelveticaNeueW02-55Roma", "HelveticaNeueW10-55Roma", Helvetica, Arial, sans-serif;',
            '%_actionButtons': 'width:100%;display:inline-block;',
            '%tryButton':
                'cursor:pointer;color:#09f;float:left;margin-top:8px;margin-left:-2px;font-size:15px;padding-right:13px;background:transparent url([tdr2]wix_apps/rightarrowblue.png) 100% 3px no-repeat;background-size:7px 11px;',
            '%tryButton:hover': 'border-bottom:1px solid #09f;',
            '%upgradeButton':
                'cursor:pointer;display:inline;height:36px;float:right;background-color:#9e199e;color:#fff;border-radius:4px;padding:0 20px;line-height:36px;font-size:14px;font-weight:bold;font-family:"HelveticaNeueW01-75Bold", "HelveticaNeueW02-75Bold", "HelveticaNeueW10-75Bold", Helvetica, Arial, sans-serif;',
            '%upgradeButton:hover': 'background-color:#8f178f;box-shadow:0 2px 3px 0 [shadowColor], 0 1px 2px 0 [shadowInsetColorOver] inset;',
            '%upgradeButton:active': 'border-color:transparent;box-shadow:0 1px 3px 0 [shadowInsetColor] inset;',
            '%[data-state="preview"] %tryButton': 'display:none;',
            '%[data-state="preview"] %upgradeButton': 'float:none;display:inline-block;'
        }
    }
    skins['wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperHorizontalSkin'] = {
        react: [
            [
                'div',
                'inputNumberWrapper',
                [],
                {},
                [
                    'div',
                    'inputNumberContainer',
                    ['_numericStepper'],
                    {},
                    [
                        'input',
                        'inputNumberInput',
                        [],
                        {
                            type: 'text'
                        }
                    ],
                    ['div', 'controls', ['_inputNumberControls'], {}, ['a', 'plus', ['_plus'], {}], ['a', 'minus', ['_minus'], {}]]
                ]
            ]
        ],
        params: {
            bordercolor: 'BORDER_COLOR_ALPHA',
            backgroundcolor: 'BG_COLOR_ALPHA',
            textcolor: 'TEXT_COLOR',
            buttonscolor: 'BG_COLOR_ALPHA',
            buttontextcolor: 'TEXT_COLOR'
        },
        paramsDefaults: {
            bordercolor: 'color_14',
            backgroundcolor: 'color_1',
            textcolor: 'color_13',
            buttonscolor: 'color_14',
            buttontextcolor: 'color_1'
        },
        css: {
            '%inputNumberWrapper': 'width:70px;height:38px;',
            '%inputNumberContainer': 'border:1px solid [bordercolor];position:relative;height:21px;width:68px;display:inline-block;margin-top:7px;',
            '%inputNumberInput':
                'border:0;width:26px;height:21px;text-align:center;position:absolute;top:0;left:21px;background-color:[backgroundcolor];color:[textcolor];font-size:10px;-webkit-appearance:none;-moz-appearance:none;padding:0;border-radius:0;',
            '%controls a%_minus': 'left:0;',
            '%controls a%_plus': 'left:47px;',
            '%controls a%_minus::after': "content:'-';",
            '%controls a%_plus::after': "content:'+';",
            '%controls a':
                'background-color:[buttonscolor];color:[buttontextcolor];display:block;width:21px;height:21px;position:absolute;top:0;text-align:center;line-height:20px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-size:14px;',
            '%controls a:focus': 'outline:0;'
        }
    }
    skins['wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperModernSkin'] = {
        react: [
            [
                'div',
                'inputNumberWrapper',
                [],
                {},
                [
                    'div',
                    'inputNumberContainer',
                    ['_numericStepper'],
                    {},
                    [
                        'input',
                        'inputNumberInput',
                        [],
                        {
                            type: 'text'
                        }
                    ],
                    [
                        'div',
                        'controls',
                        ['_inputNumberControls'],
                        {},
                        ['a', 'plus', ['_plus'], {}, ['span', null, [], {}]],
                        ['a', 'minus', ['_minus'], {}, ['span', null, [], {}]]
                    ]
                ]
            ]
        ],
        params: {
            bordercolor: 'BORDER_COLOR_ALPHA',
            backgroundcolor: 'BG_COLOR_ALPHA',
            textcolor: 'TEXT_COLOR',
            buttonscolor: 'BG_COLOR_ALPHA',
            arrowscolor: 'BORDER_COLOR_ALPHA'
        },
        paramsDefaults: {
            bordercolor: 'color_14',
            backgroundcolor: 'color_1',
            textcolor: 'color_13',
            buttonscolor: 'color_14',
            arrowscolor: 'color_1'
        },
        css: {
            '%inputNumberWrapper': 'width:70px;height:38px;',
            '%inputNumberContainer': 'border:1px solid [bordercolor];position:relative;height:21px;width:68px;display:inline-block;margin-top:7px;',
            '%inputNumberInput':
                'border:0;width:26px;height:21px;text-align:center;position:absolute;top:0;left:21px;background-color:[backgroundcolor];color:[textcolor];font-size:10px;-webkit-appearance:none;-moz-appearance:none;padding:0;border-radius:0;',
            '%controls a%_minus': 'left:0;',
            '%controls a%_plus': 'left:47px;',
            '%controls a':
                'background-color:[buttonscolor];display:block;width:21px;height:21px;position:absolute;top:0;text-align:center;line-height:20px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-size:14px;',
            '%controls a:focus': 'outline:0;',
            '%controls span':
                'display:inline-block;vertical-align:middle;width:10px;height:10px;border-top:1px solid [arrowscolor];border-right:1px solid [arrowscolor];margin-bottom:1px;',
            '%controls a%_plus span': '-webkit-transform:rotate(45deg);transform:rotate(45deg);margin-right:5px;',
            '%controls a%_minus span': '-webkit-transform:rotate(-135deg);transform:rotate(-135deg);margin-left:5px;'
        }
    }
    skins['wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperVerticalSkin'] = {
        react: [
            [
                'div',
                'inputNumberWrapper',
                [],
                {},
                [
                    'div',
                    'inputNumberContainer',
                    ['_numericStepper'],
                    {},
                    [
                        'input',
                        'inputNumberInput',
                        [],
                        {
                            type: 'text'
                        }
                    ],
                    [
                        'div',
                        'controls',
                        ['_inputNumberControls'],
                        {},
                        ['a', 'plus', ['_plus'], {}, ['span', null, [], {}]],
                        ['a', 'minus', ['_minus'], {}, ['span', null, [], {}]]
                    ]
                ]
            ]
        ],
        params: {
            bordercolor: 'BORDER_COLOR_ALPHA',
            backgroundcolor: 'BG_COLOR_ALPHA',
            textcolor: 'TEXT_COLOR',
            buttonscolor: 'BG_COLOR_ALPHA',
            arrowscolor: 'BORDER_COLOR_ALPHA'
        },
        paramsDefaults: {
            bordercolor: 'color_14',
            backgroundcolor: 'color_1',
            textcolor: 'color_13',
            buttonscolor: 'color_14',
            arrowscolor: 'color_1'
        },
        css: {
            '%inputNumberWrapper': 'width:70px;height:38px;',
            '%inputNumberContainer': 'border:1px solid [bordercolor];border-radius:3px;width:28px;position:relative;height:36px;margin:0 auto;',
            '%inputNumberInput':
                'width:28px;height:17px;text-align:center;position:absolute;top:10px;left:0;background-color:[backgroundcolor];color:[textcolor];border:0;font-size:10px;-webkit-appearance:none;-moz-appearance:none;padding:0;border-radius:0;',
            '%controls a%_plus': 'top:0;',
            '%controls a%_minus': 'top:26px;',
            '%controls a':
                'background-color:[buttonscolor];display:block;width:28px;height:10px;position:absolute;left:0;text-align:center;line-height:9px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;overflow:hidden;',
            '%controls a:focus': 'outline:0;',
            '%controls span': 'display:inline-block;width:3px;height:3px;border-top:1px solid [arrowscolor];border-right:1px solid [arrowscolor];',
            '%controls a%_plus span': '-webkit-transform:rotate(-45deg);transform:rotate(-45deg);',
            '%controls a%_minus span': '-webkit-transform:rotate(135deg);transform:rotate(135deg);margin-bottom:1px;'
        }
    }

    return skins
})
