'use strict'
const {createHelperAndMigratePage} = require('./CompsMigrationHelper')

const createComponentToContainerMigrator = (compType, experiment) =>
    createHelperAndMigratePage(
        {
            [compType]: (pageJson, cache, mobileView, comp) => {
                if (comp.type !== 'Container') {
                    comp.type = 'Container'
                    comp.components = []
                }
            }
        },
        experiment ? [experiment] : []
    )
module.exports = createComponentToContainerMigrator
