'use strict'

const _ = require('lodash')

function removeOpenPopupBehaviorForNonExistingPopup(pageBehaviorQuery, behaviors_data, pageIdList, pageJson) {
    const pageBehaviors = behaviors_data[pageBehaviorQuery]
    if (!pageBehaviors) {
        return
    }
    const pageBehaviorsItems = pageBehaviors.items

    if (!_.isEmpty(pageBehaviorsItems) && _.isString(pageBehaviorsItems)) {
        const items = JSON.parse(pageBehaviorsItems)
        const popupBehavior = _.find(items, item => _.get(item, ['behavior', 'name']) === 'openPopup')
        if (popupBehavior) {
            const targetId = _.get(popupBehavior, ['behavior', 'targetId'])
            if (targetId && !_.includes(pageIdList, targetId)) {
                const fixedItems = _.reject(items, item => _.get(item, ['behavior', 'name']) === 'openPopup')
                if (_.isEmpty(fixedItems)) {
                    delete pageJson.structure.behaviorQuery
                    delete pageJson.data.behaviors_data[pageBehaviorQuery]
                } else {
                    pageBehaviors.items = JSON.stringify(fixedItems)
                }
            }
        }
    }
}

/**
 * @exports utils/dataFixer/plugins/removePopupBehaviorForNonExistingPopupFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'removePopupBehaviorForNonExistingPopupFixer',
    version: 1,
    exec(pageJson, pageIdsArray) {
        const {behaviors_data} = pageJson.data
        const pageBehaviorQuery = pageJson.structure.behaviorQuery
        if (_.isEmpty(behaviors_data) || !pageBehaviorQuery) {
            return
        }
        removeOpenPopupBehaviorForNonExistingPopup(pageBehaviorQuery, behaviors_data, pageIdsArray, pageJson)
    }
}
