import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const pageLinkPrefix = '#!'
const externalHrefRegExp = /(:\/\/)|(^\/\/)/

const linksConvertMap = {
    'wix:Link': convertWixLink,
    'wix:DynamicPageLink': convertDynamicPageLink,
    'wix:PageLink': convertPageLink,
    'wix:ExternalLink': convertExternalLink,
    'wix:MailLink': convertEmailLink,
    'wix:PhoneLink': convertPhoneLink,
    'wix:DocLink': convertDocumentLink,
    'wix:AnchorLink': convertAnchorLink
}

Object.freeze(linksConvertMap)

function getPageData(pagesDataItem, pageId) {
    const cleanPageId = pageId ? pageId.replace('#', '') : ''
    return pagesDataItem[cleanPageId] || pageId
}

/**
 * Converts wix:AnchorLink to AnchorLink object.
 * @param {wixAnchorLink} linkData
 * @param {function(string, string)} getDataById A function that return the data according to the dataId, pageId params.
 * @returns {{type: string, anchorName: string, anchorDataId: string, pageId: string}}
 */
function convertAnchorLink(linkData, pagesDataItem) {
    return {
        type: 'AnchorLink',
        anchorName: linkData.anchorName,
        anchorDataId: linkData.anchorDataId,
        pageId: getPageData(pagesDataItem, linkData.pageId)
    }
}

/**
 * Converts wix:DocLink to DocumentLink object.
 * @param {wixDocLink} linkData
 * @returns {{type: string, docId: string, name: string}}
 */
function convertDocumentLink(linkData) {
    return {
        type: 'DocumentLink',
        docId: linkData.docId,
        name: linkData.docName
    }
}

/**
 * Converts wix:ExternalLink to ExternalLink object.
 * @param {wixExternalLink} linkData
 * @returns {{type: string, url: string, target: string?}}
 */
function convertExternalLink(linkData) {
    return {
        type: 'ExternalLink',
        url: linkData.protocol ? `${linkData.protocol}://${linkData.address}` : linkData.address,
        target: linkData.target
    }
}

/**
 * Converts wix:PageLink to PageLink object.
 * @param {wixPageLink} linkData
 * @param {function(string, string)} getDataById A function that return the data according to the dataId, pageId params.
 * @returns {{type: string, pageId: string}}
 */
function convertPageLink(linkData, pagesDataItem) {
    return {
        type: 'PageLink',
        pageId: getPageData(pagesDataItem, linkData.pageId)
    }
}

function convertDynamicPageLink(linkData) {
    return {
        type: 'DynamicPageLink',
        routerId: linkData.routerId,
        innerRoute: linkData.innerRoute,
        anchorDataId: linkData.anchorDataId
    }
}

/**
 * Converts wix:PhoneLink to PhoneLink object.
 * @param {wixPhoneLink} linkData
 * @returns {{type: string, phoneNumber: string}}
 */
function convertPhoneLink(linkData) {
    return {
        type: 'PhoneLink',
        phoneNumber: linkData.phoneNumber
    }
}

/**
 * Converts wix:MailLink to EmailLink object.
 * @param {wixMailLink} linkData
 * @returns {{type: string, recipient: string, subject: string?}}
 */
function convertEmailLink(linkData) {
    return {
        type: 'EmailLink',
        recipient: linkData.email,
        subject: linkData.subject
    }
}

/**
 * Converts wix:Link to ExternalLink, PageLink or EmailLink
 * @param {wixLink} linkData
 * @param {function(string, string)} getDataById A function that return the data according to the dataId, pageId params.
 * @returns {{type: string, recipient: string, subject: string?}|{type: string, pageId: string}|{type: string, url: string, target: string?}}
 */
function convertWixLink(linkData, pagesDataItem) {
    const {href} = linkData
    if (linkData.linkType === 'WEBSITE' || externalHrefRegExp.test(href)) {
        const wixExternalLinkData = {
            address: href
        }

        return convertExternalLink(wixExternalLinkData)
    }

    if (coreUtils.stringUtils.startsWith(href, pageLinkPrefix)) {
        return convertPageLink({pageId: href.substr(2)}, pagesDataItem)
    }

    if (coreUtils.stringUtils.startsWith(href, 'mailto:')) {
        const split = href.split('?')
        let subject = ''
        if (split.length > 1) {
            const params = coreUtils.urlUtils.parseUrlParams(split[1])
            // @ts-ignore
            subject = params.subject || ''
        }

        const emailLink = {
            subject,
            email: split[0].substr(7) // Cut the mailto: from the string
        }

        return convertEmailLink(emailLink)
    }
}

/**
 * Converts the link from wixapps link objects to wix link objects.
 * @param {wixLink|wixAnchorLink|wixDocLink|wixPageLink|wixExternalLink|wixMailLink} data
 * @param {function(string, string)} getDataById A function that return the data according to the dataId, pageId params.
 * @returns {*} The wix object that is equal to the wixapps data object.
 */
function convertLink(data, pagesDataItem) {
    if (!data) {
        return null
    }

    const convertFunc = linksConvertMap[data._type]
    if (!convertFunc) {
        return
    }

    const converted = convertFunc(data, pagesDataItem)
    if (_.isUndefined(converted)) {
        return
    }

    return _.assign(converted, {id: data.linkId})
}

/**
 * @class wixappsCore.linksConverter
 */
export default convertLink

/**
 * @typedef {object} wixAnchorLink
 * @property {string} anchorName
 * @property {string} anchorDataId
 * @property {string} pageId
 */

/**
 * @typedef {object} wixDocLink
 * @property {string} docId
 * @property {string} docName
 */

/**
 * @typedef {object} wixExternalLink
 * @property {string} address
 * @property {?string} protocol
 * @property {?string} target
 */

/**
 * @typedef {object} wixPageLink
 * @property {string} pageId
 */

/**
 * @typedef {object} wixMailLink
 * @property {string} email
 * @property {?string} subject
 */

/**
 * @typedef {object} wixLink
 * @property {?string} linkType
 * @property {string} href
 */
