define([
    'documentServices/documentServicesDataFixer/documentServicesDataFixer',
    'documentServices/documentServicesDataFixer/responsiveDocumentDataFixers'
], function (classicDocument, responsiveDocument) {
    'use strict'

    return {
        classicDocument,
        responsiveDocument
    }
})
