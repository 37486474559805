import type {CoreLogger, DocumentManager, DSConfig} from '@wix/document-manager-core'
import {MockCEditTestServer, PageAPI} from '@wix/document-manager-extensions'
import type {LayoutCBApi} from '@wix/document-manager-extensions/src/extensions/layoutCircuitBreaker'
import type {ServiceTopology} from '@wix/document-manager-extensions/src/extensions/serviceTopology'
import type {UndoRedoExtApi} from '@wix/document-manager-extensions/src/extensions/undoRedo'
import type {CSaveApi, DocumentServicesModelExtApi, EnvironmentExtensionAPI, ValidationWhitelistExtensionAPI} from '@wix/document-manager-extensions/src/types'
import {BootstrapConfig, constants, Host, host as hostCommon} from '@wix/document-manager-host-common'
import {ReportableError} from '@wix/document-manager-utils'
import type {ViewerLibrary} from '@wix/document-services'
import * as dsInterface from '@wix/document-services'
import documentServicesImplementation from '@wix/document-services-implementation'
import {newSchemaService} from '@wix/document-services-json-schemas'
import type {
    BIEvt,
    DataFixer,
    DocumentServicesModel,
    DocumentServicesObject,
    Experiment,
    FedopsLogger,
    HistoryObject,
    PS,
    RendererModel
} from '@wix/document-services-types'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import {createViewerManagerAdapter, ViewerManager} from '@wix/viewer-manager-adapter'
import {deepClone} from '@wix/wix-immutable-proxy'
import * as schemas from 'document-services-schemas'
import _ from 'lodash'
import {AuthorizationInstance, extractMetaSite} from '../host/clientSpecMap'
import {createHost, extendHostWithViewerManager, HostWithViewerManager} from '../host/host'
import {ServerFacadeWithAuthorization} from '../host/serverFacadeWithAuthorization'
import {registerAjaxMethod} from '../utils/fetchFunction'
import {getSubDomain} from '../utils/globalUtils'
import {AdapterLogger, createAdapterLogger} from './adapterLogger'
import {runMigrators} from './documentViewerSync/migrators'
import {runPostUpdateOperation} from './documentViewerSync/postUpdateOperation'
import * as dsAdapterDebug from './dsAdapterDebug'
import {createMiniSitePreview, createMiniSitePreviewTB} from './miniSites/dataConversion'
import {createModuleLibrary} from './moduleLibrary'
import {createPrivateServices} from './privateServices'
import {bridgeDMEventsToHooks} from './services/bridgeDMEventsToHooks'
import {registerToMessagesBeforeDsLoad} from './services/tpaMessageBuffer'
import {waitForViewerLoaded} from './SOQ/flushBatch'

const {DATA_TYPES, INTERACTIONS} = constants

interface CreateAdapterArgs {
    host: HostWithViewerManager
    viewerManager: ViewerManager
    // eslint-disable-next-line no-undef
    coreUtils: CoreUtils
    moduleLibraryOverrides?: any
    experimentInst: Experiment
}

interface CreateViewerManagerAndExtendHostArgs {
    hostWithDM: Host
    viewer: unknown
}

interface Models {
    rendererModel?: RendererModel
    documentServicesModel?: DocumentServicesModel
    serviceTopology?: ServiceTopology
}

interface ViewerManagerInterfaceAndHost {
    host: HostWithViewerManager
    viewerManager: ViewerManager
}

interface EnrichInitReportsParams<T> {
    readonly functionName: string
    readonly usingCEdit: boolean
    readonly mobileEditor: boolean
    readonly logger: FedopsLogger

    action(): Promise<T>
}

const getExperimentInstance = (host: Host) => host.documentManager.experimentInstance

/**
 * The adapter is intended to provide compliance with the same API that Santa exposes. This is for the current ds-impl
 * to work on both Santa and Bolt. Once Santa is no longer in the picture, the ds-impl can fully become extensions
 * and start leveraging the documentManager architecture
 */
export const createAdapter = ({host, viewerManager, coreUtils, moduleLibraryOverrides}: CreateAdapterArgs): Adapter => {
    const ps = createPrivateServices({host, viewerManager})
    const {logger, extensionAPI} = host.documentManager
    const experimentInstance = getExperimentInstance(host)

    const runMigratorsWithImplementation = _.partial(runMigrators, documentServicesImplementation, ps)
    const {layoutCircuitBreaker} = extensionAPI as LayoutCBApi
    const {config} = host
    const curriedRunPostUpdateOperation = _.curry(runPostUpdateOperation)
    viewerManager.viewerSiteAPI.registerToComponentsLayoutChange(
        curriedRunPostUpdateOperation(ps, config.postUpdateOperation, runMigratorsWithImplementation, layoutCircuitBreaker, experimentInstance, logger)
    )

    if (config.initialSeed) {
        coreUtils.guidUtils.setInitialSeed(_.parseInt(config.initialSeed as string))
    }
    bridgeDMEventsToHooks(ps, host.documentManager, documentServicesImplementation.hooks)

    const moduleLibrary = createModuleLibrary(
        documentServicesImplementation,
        schemas,
        coreUtils,
        logger,
        moduleLibraryOverrides,
        extensionAPI,
        ps.runtimeConfig
    )

    return {
        ps,
        config,
        host,
        santaLegacy: {
            viewerLibrary: moduleLibrary,
            addPostDataFixersMethodsToPublicAPI: () => console.error('addPostDataFixersMethodsToPublicAPI is missing')
        },
        documentServicesDataFixer: documentServicesImplementation.dataFixersConfig[config.documentDataFixer ?? 'classicDocument']
    }
}

export interface Bolt {
    data: any
    compClasses: any
}

const createSantaPreviewFunction =
    (documentManager: DocumentManager, boltInstance: Bolt, serviceTopology: ServiceTopology, {viewerSiteAPI}: ViewerManager, viewerName: string): any =>
    (hostReact: any) => {
        const {dal} = documentManager
        const allPagesIndex = (documentManager.extensionAPI.page as PageAPI).getAllPagesIndexId()
        const stylesType = {
            TopLevelStyle: 'TopLevelStyle',
            WFlatTheme: 'WFlatTheme',
            ComponentStyle: 'ComponentStyle'
        }

        const themeData = deepClone(dal.query(DATA_TYPES.theme, allPagesIndex, (style: any) => stylesType[style.type]))

        if (viewerName === 'tb') {
            return createMiniSitePreviewTB({
                hostReact,
                themeData,
                serviceTopology,
                getViewerFragment: viewerSiteAPI.getViewerFragment
            })
        }
        const getCompClasses = async (classesToDownload: string[]) => {
            await viewerSiteAPI.downloadCompClasses(classesToDownload)
            return boltInstance.compClasses
        }

        return createMiniSitePreview({
            getBoltFragment: viewerSiteAPI.getViewerFragment,
            getCompClasses,
            hostReact,
            themeData,
            serviceTopology
        })
    }

const isCrossOriginFrame = () => {
    try {
        return !window.top?.location.hostname
    } catch (e) {
        return true
    }
}

const parseUrl = (): Record<string, string> => {
    if (typeof window === 'undefined') {
        return {}
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const res = {}
    urlSearchParams.forEach((v, k) => {
        res[k] = v
    })
    return res
}

/**
 * @description
 * This function parse url and pass provided values to build dsConfig
 *
 * @returns {BootstrapConfig}
 */
const getBootstrapConfig = (): BootstrapConfig => {
    const bootstrapConfig = parseUrl()
    const {debug, dsOrigin} = bootstrapConfig

    if (!dsOrigin) {
        throw new Error('You must define dsOrigin parameter in order to use the documentServices - please speak to html-server team for a key')
    }

    return {
        ..._.omit(bootstrapConfig, 'debug'),
        isDebugMode: !!debug && debug !== 'false' && debug !== ''
    } as unknown as BootstrapConfig
}

const notifyViewerReady = () => {
    window.parent.postMessage('viewerLoaded', '*')
}

const notifyDSReady = (documentServices: DocumentServicesObject, documentManager: DocumentManager, viewerManager: ViewerManager, viewerName: string) => {
    const {logger} = documentManager
    window.documentServices = documentServices
    window.didLoadDocumentServices = true
    window.siteModel = {santaBase: `${window.boltBase}(bolt))`} // Hack as the editor is expecting this path. Should change the editor to read the version from API
    window.createSantaPreview = createSantaPreviewFunction(documentManager, window.boltInstance, window.serviceTopology, viewerManager, viewerName)
    window.parent.postMessage('documentServicesLoaded', '*')
    const event = {
        endpoint: 'document',
        eventId: 12,
        src: 40,
        params: ['viewerName', 'viewerVersion', 'site_id', 'msid', 'dsOrigin', 'esi', 'navigationType']
    }

    if (!isCrossOriginFrame()) {
        window.top?.addEventListener('unload', () => {
            const viewerInstance = _.has(window, ['boltInstance']) ? 'boltInstance' : 'tbInstance'
            const checkers = JSON.stringify(_.omitBy(_.get(window, [viewerInstance, 'dataRequirementCheckers'], {})), null, 4)
            const keys = Object.keys(checkers)
            const falseCheckers = keys.filter(k => checkers[k] === false)
            if (falseCheckers.length > 0) {
                const err = new ReportableError({
                    message: 'dataRequirementCheckers false on unload',
                    errorType: 'dataRequirementCheckersFalseOnUnload',
                    tags: {ds_unload: true},
                    extras: {
                        dataRequirementCheckers: falseCheckers
                    }
                })
                logger.captureError(err)
            }

            //reportBI(event)
        })

        const navigationType = _.get(window, ['top', 'performance', 'navigation', 'type'], -1)

        logger.reportBI(event, {navigationType})
    }
}

const fixDomain = () => {
    try {
        window.document.domain = getSubDomain(window.document.domain)
    } catch (err) {
        console.error(err)
    }
}

const runMobileMergeAfterLoadingFromAutosave = async (ps: PS, logger: CoreLogger) => {
    const autosaveChangesAppliedPointer = ps.pointers.general.getAutoSaveInnerPointer('changesApplied')
    if (ps.dal.get(autosaveChangesAppliedPointer)) {
        try {
            documentServicesImplementation.mobilePreSaveOperation(ps)
            ps.dal.commitTransaction('mobileAutosaveMerge')
        } catch (e: any) {
            logger.captureError(e, {
                tags: {mobileConversionOnLoadFailed: true}
            })
            await logger.flush?.()
            window.parent.postMessage('mobileConversionFailed_ReloadNeeded', '*')
            throw e // stopping the load to prevent documentServicesLoaded from being sent
        }
    }
}

export interface Adapter {
    ps: PS
    config: DSConfig
    host: HostWithViewerManager
    documentServicesDataFixer: DataFixer
    santaLegacy: {
        viewerLibrary: ViewerLibrary
        addPostDataFixersMethodsToPublicAPI(actions: Record<string, any>, history: HistoryObject): void
    }
}

export interface AdapterResult {
    modelsToApplyFromHost: {
        rendererModel: RendererModel
        documentServicesModel: DocumentServicesModel
    }
    hostWithDM: Host
    fetchPage: any
    fetchWixappsDataFromDM: any
}

const isHeadlessBrowser = (): boolean => {
    const agent = navigator?.userAgent ?? ''
    return agent.toLowerCase().includes('headless')
}

const isOnBoarding = (): boolean => getBootstrapConfig().dsOrigin === 'onboarding'

const isMobileEditor = (): boolean => isOnBoarding() && isHeadlessBrowser()

const enrichInitReports = async <T>({functionName, usingCEdit, mobileEditor, logger, action}: EnrichInitReportsParams<T>): Promise<T> => {
    try {
        return await action()
    } catch (e: any) {
        _.attempt(() => {
            window.documentServicesLoadingError = e
        })

        logger.captureError(e, {
            tags: {
                functionName,
                adapterInit: true,
                initWithCEdit: usingCEdit,
                isMobileEditor: mobileEditor
            }
        })

        throw e
    }
}

const interactionStartedWithOptions = (logger: CoreLogger, name: string, options: object) => logger.interactionStarted(name, options)
const interactionEndedWithOptions = (logger: CoreLogger, name: string, options: object) => logger.interactionEnded(name, options)

const getHref = (): string | undefined => window?.location.href

const isUsingLocalHost = (): boolean => {
    const href = getHref()
    return !!href && ['localhost', '127.0.0.1'].some(s => href.includes(s))
}

const getIsDebugMode = (host: Host) => (host.documentManager.extensionAPI as EnvironmentExtensionAPI).environment.isDebugMode()

const getDefaultTags = (bootstrapConfig: BootstrapConfig) => (!!bootstrapConfig.isDebugMode || isUsingLocalHost() ? {debugMode: true} : {})

const getDefaultOverrides = (bootstrapConfig: BootstrapConfig) => (bootstrapConfig.experience ? {experience: bootstrapConfig.experience} : {})

const createServerFacade = (experimentInstance: Experiment, logger: AdapterLogger, editorRootUrl: string, metaSite: AuthorizationInstance) => {
    if (experimentInstance.isOpen('mockCSaveServer')) {
        return new MockCEditTestServer()
    }
    return new ServerFacadeWithAuthorization(editorRootUrl, logger, metaSite)
}

/**
 * @param {DataFixer} dataFixer
 * @param {FedopsLogger} fedopsLogger
 * @param {Experiment} experimentInstance
 * @param {BootstrapConfig} bootstrapConfig
 * @param {Models} [modelsForInit]
 * @returns {Promise<AdapterResult>}
 */
async function _initDocumentServicesDocument(
    dataFixer: DataFixer,
    fedopsLogger: FedopsLogger,
    experimentInstance: Experiment,
    bootstrapConfig: BootstrapConfig,
    modelsForInit?: Models
): Promise<AdapterResult> {
    const modelsFromWindow = _.pick(window, ['serviceTopology', 'documentServicesModel', 'rendererModel'])
    const documentServicesModel = _.get(modelsForInit, ['documentServicesModel']) ?? modelsFromWindow.documentServicesModel
    const defaultTags = getDefaultTags(bootstrapConfig)
    const defaultOverrides = getDefaultOverrides(bootstrapConfig)
    // Setting a temporary function as reportBI. With Bolt, the actual function is only available later from the viewer
    const logger = createAdapterLogger(fedopsLogger, {defaultTags, defaultOverrides})

    const options = {
        isDraft: _.get(documentServicesModel, ['isDraft'])
    }

    interactionStartedWithOptions(logger, INTERACTIONS.FULL_LOAD, options)
    interactionStartedWithOptions(logger, INTERACTIONS.CREATE_HOST, options)

    const models = {
        rendererModel: _.get(modelsForInit, ['rendererModel']) ?? modelsFromWindow.rendererModel,
        documentServicesModel,
        serviceTopology: _.get(modelsForInit, ['serviceTopology']) ?? modelsFromWindow.serviceTopology
    }
    const metaSite = extractMetaSite(models.rendererModel)

    const serverFacade = createServerFacade(experimentInstance, logger, models.serviceTopology.editorRootUrl, metaSite)
    const environmentContext = {
        serverFacade,
        fetchFn: fetch
    }
    const hostWithDM = createHost({
        models,
        experimentInstance,
        logger,
        schemaService: newSchemaService.staticInstance,
        config: bootstrapConfig,
        environmentContext
    })
    hostWithDM.documentManager.dal.initLazyInitiators()
    interactionEndedWithOptions(logger, INTERACTIONS.CREATE_HOST, options)

    if (getIsDebugMode(hostWithDM)) {
        dsAdapterDebug.init(hostWithDM.documentManager)
    }

    interactionStartedWithOptions(logger, INTERACTIONS.INITIALIZE_HOST, options)
    const {store} = await hostWithDM.runInitializers()
    interactionEndedWithOptions(logger, INTERACTIONS.INITIALIZE_HOST, options)

    interactionStartedWithOptions(logger, INTERACTIONS.REGISTER_MESSAGE_ON_LOAD, options)
    registerToMessagesBeforeDsLoad()
    interactionEndedWithOptions(logger, INTERACTIONS.REGISTER_MESSAGE_ON_LOAD, options)

    interactionStartedWithOptions(logger, INTERACTIONS.LOADING_VIEWER, options)

    hostWithDM._dsInitTimeoutHandler = setTimeout(() => {
        const viewerInstance = _.has(window, ['boltInstance']) ? 'boltInstance' : 'tbInstance'
        const dataRequirementCheckers = JSON.stringify(_.omitBy(_.get(window, [viewerInstance, 'dataRequirementCheckers'], {})), null, 4)

        logger.captureError(
            new ReportableError({
                message: `ds creation failed ${viewerInstance ? 'after' : 'before'} bolt instance creation`,
                errorType: 'dsInitTimeout',
                tags: {ds_creation: true},
                extras: {dataRequirementCheckers}
            })
        )
    }, 35000)

    const {fetchPageFromDM, fetchWixappsDataFromDM} = hostWithDM.documentManager.extensionAPI

    return {
        modelsToApplyFromHost: {
            rendererModel: _.cloneDeep(_.get(store, ['rendererModel'])) as RendererModel,
            documentServicesModel: _.cloneDeep(_.get(store, ['documentServicesModel'])) as DocumentServicesModel
        },
        hostWithDM,
        fetchPage: fetchPageFromDM,
        fetchWixappsDataFromDM
    }
}

interface CreatDSArgs {
    hostWithDM: Host
    viewerManager?: ViewerManager // will become viewer and be removed
    // eslint-disable-next-line no-undef
    coreUtils: CoreUtils
    logger: FedopsLogger
    experimentInst: Experiment
}

interface CreatDSArgsWithHost extends CreatDSArgs {
    viewerManagerInstanceAndHost: ViewerManagerInterfaceAndHost
}

const tryCommittingOpenTransaction = (documentManager: DocumentManager, logger: CoreLogger, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen('dm_commitOpenTransactionBeforeBaseline')) {
        return
    }
    try {
        documentManager.dal.commitTransaction('before-baseline')
    } catch (e) {
        const err = e as Error
        logger.captureError(
            new ReportableError({
                message: err.message,
                errorType: 'COMMIT-BEFORE-BASELINE'
            })
        )
        documentManager.dal.commitTransaction('before-baseline', true) //lets get these into the baseline
    }
}
async function _createDocumentServices({
    hostWithDM,
    coreUtils,
    experimentInst,
    viewerManagerInstanceAndHost
}: CreatDSArgsWithHost): Promise<DocumentServicesObject> {
    const {viewerManager, host} = viewerManagerInstanceAndHost
    const {documentManager} = hostWithDM
    const {logger} = documentManager
    const {validationWhitelist} = documentManager.extensionAPI as ValidationWhitelistExtensionAPI

    const options = {
        paramsOverrides: {
            isDraft: documentManager.dal.get(documentManager.pointers.documentServicesModel.getIsDraft())
        }
    }
    interactionStartedWithOptions(logger, INTERACTIONS.CREATE_ADAPTER, options)
    registerAjaxMethod(warmupUtils.ajaxLibrary, hostWithDM.environmentContext.serverFacade, experimentInst, hostWithDM.config.isReadOnly)
    const adapter = createAdapter({host, viewerManager, coreUtils, experimentInst})

    const {ps} = adapter
    warmupUtils.ajaxLibrary.supportsAutoAuth = ps.runtimeConfig.viewerName === 'tb'
    const adapterLogger = logger as AdapterLogger
    // TODO {@see DM-3113}
    // The reportBI needs to be replaced here, as it was not available when the CoreLogger was created for Bolt
    const reportBI = (event: BIEvt, params?: Record<string, any>) => {
        const updatedParams = Object.assign(
            {
                dsOrigin: ps.config.origin,
                viewerName: ps.runtimeConfig.viewerName,
                esi: ps.siteAPI.getEditorSessionId()
            },
            params
        )
        ps.siteAPI.reportBI(event, updatedParams)
    }

    adapterLogger.registerSendBiFunction(reportBI)
    const gatherContext = ps.setOperationsQueue.getCurrentBatchSummary.bind(ps.setOperationsQueue)
    adapterLogger.registerContextProvider(gatherContext)
    validationWhitelist.registerContextProvider('SOQ', gatherContext)

    if (experimentInst.isOpen('dm_sendTentativeStoreOnError')) {
        adapterLogger.registerContextProvider(() => ({
            tentativeStore: JSON.stringify(hostWithDM.documentManager.dal._getTentativeStoreAsJson())
        }))
    }

    adapterLogger.registerContextProvider(() => ({
        revision: (hostWithDM.documentManager.extensionAPI as DocumentServicesModelExtApi).siteAPI.getSiteRevision(),
        untilTransactionId: (hostWithDM.documentManager.extensionAPI as CSaveApi).continuousSave?.getLastTransactionId()
    }))

    interactionEndedWithOptions(logger, INTERACTIONS.CREATE_ADAPTER, options)

    interactionStartedWithOptions(logger, INTERACTIONS.WAIT_FOR_VIEWER, options)
    await waitForViewerLoaded(viewerManager.viewerSiteAPI, logger, experimentInst)
    logger.reportBI(documentServicesImplementation.biEvents.PREVIEW_SITE_READY)
    notifyViewerReady()
    interactionEndedWithOptions(logger, INTERACTIONS.WAIT_FOR_VIEWER, options)

    interactionStartedWithOptions(logger, INTERACTIONS.FIX_DOMAIN, options)
    fixDomain()
    interactionEndedWithOptions(logger, INTERACTIONS.FIX_DOMAIN, options)

    interactionStartedWithOptions(logger, INTERACTIONS.INIT_PUBLIC_MODULES, options)
    const modules = dsInterface.initPublicModules(adapter.santaLegacy.viewerLibrary, adapter.config.modulesList)
    interactionEndedWithOptions(logger, INTERACTIONS.INIT_PUBLIC_MODULES, options)

    interactionStartedWithOptions(logger, INTERACTIONS.CREATE_DOCUMENT_SERVICES, options)
    const documentServices = (await dsInterface.createDocumentServices(adapter, modules, logger as unknown as FedopsLogger)) as DocumentServicesObject
    interactionEndedWithOptions(logger, INTERACTIONS.CREATE_DOCUMENT_SERVICES, options)

    interactionStartedWithOptions(logger, INTERACTIONS.VALIDATE_STORE, options)
    tryCommittingOpenTransaction(documentManager, logger, experimentInst)
    documentManager.dal.createValidationBaseline()
    interactionEndedWithOptions(logger, INTERACTIONS.VALIDATE_STORE, options)

    const {extensionAPI} = hostWithDM.documentManager
    interactionStartedWithOptions(logger, INTERACTIONS.AFTER_LOADING_MOBILE_MERGE, options)
    await runMobileMergeAfterLoadingFromAutosave(ps, logger)
    interactionEndedWithOptions(logger, INTERACTIONS.AFTER_LOADING_MOBILE_MERGE, options)

    interactionStartedWithOptions(logger, INTERACTIONS.CREATE_UNDO, options)
    const {createUndoRedoPublicApi, takeInitialSnapshotForUndoRedo} = extensionAPI as UndoRedoExtApi
    documentServices.history = createUndoRedoPublicApi(ps.setOperationsQueue) as unknown as HistoryObject
    takeInitialSnapshotForUndoRedo()
    interactionEndedWithOptions(logger, INTERACTIONS.CREATE_UNDO, options)

    logger.reportBI(documentServicesImplementation.biEvents.DOCUMENT_SERVICES_LOADED)
    if (window.performance?.mark) {
        window.performance.mark('documentServicesLoaded')
    }

    interactionEndedWithOptions(logger, INTERACTIONS.FULL_LOAD, options)
    notifyDSReady(documentServices, hostWithDM.documentManager, viewerManager, ps.runtimeConfig.viewerName)

    if (getIsDebugMode(adapter.host)) {
        dsAdapterDebug.registerReady(adapter, documentServices)
    }

    return documentServices
}

async function _createDocumentServicesAndHost({
    hostWithDM,
    viewerManager: viewer,
    logger,
    coreUtils,
    experimentInst
}: CreatDSArgs): Promise<DocumentServicesObject> {
    const viewerManagerInstanceAndHost = createViewerManagerAndExtendHost({hostWithDM, viewer})
    return _createDocumentServices({hostWithDM, coreUtils, logger, experimentInst, viewerManagerInstanceAndHost})
}

export function createViewerManagerAndExtendHost({hostWithDM, viewer}: CreateViewerManagerAndExtendHostArgs) {
    const {documentManager} = hostWithDM
    const {logger} = documentManager

    const options = {
        isDraft: documentManager.dal.get(documentManager.pointers.documentServicesModel.getIsDraft())
    }
    interactionEndedWithOptions(logger, INTERACTIONS.LOADING_VIEWER, options)
    clearTimeout(hostWithDM._dsInitTimeoutHandler)

    const viewerManager = createViewerManagerAdapter(viewer, getIsDebugMode(hostWithDM), getExperimentInstance(hostWithDM)) as ViewerManager
    interactionStartedWithOptions(logger, INTERACTIONS.EXTEND_VIEWER_MANAGER, options)
    const host = extendHostWithViewerManager(hostWithDM, viewerManager)
    interactionEndedWithOptions(logger, INTERACTIONS.EXTEND_VIEWER_MANAGER, options)
    return {
        viewerManager,
        host
    }
}

export async function initDocumentServicesDocument(
    dataFixer: DataFixer,
    fedopsLogger: FedopsLogger,
    experimentInstance: Experiment,
    models?: Models
): Promise<AdapterResult> {
    const bootstrapConfig = getBootstrapConfig()
    return await enrichInitReports({
        functionName: 'initDocumentServicesDocument',
        usingCEdit: hostCommon.isCEditOpen(bootstrapConfig.dsOrigin, experimentInstance),
        mobileEditor: isMobileEditor(),
        logger: fedopsLogger,
        action: () => _initDocumentServicesDocument(dataFixer, fedopsLogger, experimentInstance, bootstrapConfig, models)
    })
}

export async function createDocumentServices(dsArgs: CreatDSArgs): Promise<DocumentServicesObject> {
    const {
        logger,
        hostWithDM: {config}
    } = dsArgs
    return await enrichInitReports({
        functionName: 'createDocumentServices',
        usingCEdit: !!config.cedit,
        mobileEditor: isMobileEditor(),
        logger,
        action: () => _createDocumentServicesAndHost(dsArgs)
    })
}

export async function createDocumentServicesWithHost(dsArgs: CreatDSArgsWithHost): Promise<DocumentServicesObject> {
    const {
        logger,
        hostWithDM: {config}
    } = dsArgs
    return await enrichInitReports({
        functionName: 'createDocumentServicesWithHost',
        usingCEdit: !!config.cedit,
        mobileEditor: isMobileEditor(),
        logger,
        action: () => _createDocumentServices(dsArgs)
    })
}
