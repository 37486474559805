define([
    'lodash',
    '@wix/mobile-conversion',
    'documentServices/component/component',
    'documentServices/constants/constants',
    'documentServices/actionsAndBehaviors/actionsAndBehaviors',
    'documentServices/mobileConversion/modules/menuContainer/menuContainerDefaultPreset',
    'documentServices/mobileConversion/modules/menuContainer/menuContainerToggleDefaultPreset',
    'documentServices/mobileConversion/modules/menuContainer/expandableMenuDefaultPreset',
    'documentServices/mobileConversion/modules/mobileHints',
    'documentServices/mobileConversion/mobileConversionFacade',
    'documentServices/mobileConversion/modules/structuresComparer',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/mobileConversion/modules/conversionSettings'
], function (
    _,
    mobileCore,
    component,
    constants,
    actionsAndBehaviors,
    menuContainerDefaultPreset,
    menuContainerToggleDefaultPreset,
    expandableMenuDefaultPreset,
    mobileHints,
    mobileConversionFacade,
    structuresComparer,
    mergeAggregator,
    conversionSettingsBuilder
) {
    'use strict'

    const get = ps => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, masterPagePointer)
        return ps.dal.isExist(menuContainerPointer) ? menuContainerPointer : null
    }

    const getToggle = ps => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const menuContainerTogglePointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE, masterPagePointer)
        return ps.dal.isExist(menuContainerTogglePointer) ? menuContainerTogglePointer : null
    }

    const isMenuContainerExist = ps => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, masterPagePointer)
        return ps.dal.isExist(menuContainerPointer)
    }

    const isMenuToggleExist = ps => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE, masterPagePointer)
        return ps.dal.isExist(menuContainerPointer)
    }

    const isExpandableMenuExist = ps => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const expandableMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU, masterPagePointer)
        return ps.dal.isExist(expandableMenuPointer)
    }

    const addMenuContainerToggle = (ps, layoutOverrides) => {
        if (!isMenuContainerExist(ps)) {
            throw new Error('cannot add menu container toggle without menu instance')
        }

        if (isMenuToggleExist(ps)) {
            return
        }

        const headerPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.MOBILE)
        const compToAddRef = component.getComponentToAddRef(ps, headerPointer, null, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE)
        _.assign(menuContainerToggleDefaultPreset.layout, layoutOverrides)
        component.add(ps, compToAddRef, headerPointer, menuContainerToggleDefaultPreset)

        addToggleVisibilityBehaviorToToggle(ps, get(ps), compToAddRef)
    }

    const addToggleVisibilityBehaviorToToggle = (ps, menuContainerPointer, menuContainerTogglePointer) =>
        actionsAndBehaviors.updateBehavior(ps, menuContainerTogglePointer, {name: 'click', type: 'comp'}, menuContainerPointer, {
            name: 'toggle',
            type: 'comp'
        })

    const handleHiddenComponents = ps => {
        const masterPageDesktopPointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.DESKTOP)
        const componentsToUnhide = _(structuresComparer.getHiddenAndShownComponents(ps, constants.MASTER_PAGE_ID).hiddenComponents)
            .map(compId => ps.pointers.components.getComponent(compId, masterPageDesktopPointer))
            .filter(compPointer => {
                const componentTypePointer = ps.pointers.getInnerPointer(compPointer, 'componentType')
                if (!ps.dal.isExist(componentTypePointer)) {
                    return false
                }

                const compType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
                return _.includes(['wysiwyg.viewer.components.LoginSocialBar', 'wysiwyg.viewer.components.LanguageSelector'], compType)
            })
            .value()

        componentsToUnhide.forEach(compPointer => mobileHints.updateProperty(ps, {hidden: false}, compPointer))

        const needToRunMobileConversion = componentsToUnhide.length > 0 && ps.siteAPI.isMobileView()
        if (needToRunMobileConversion) {
            const desktopPage = mergeAggregator.getPage(ps, constants.MASTER_PAGE_ID)
            const mobilePage = mobileCore.conversionUtils.extractMobilePage(desktopPage)
            const settings = conversionSettingsBuilder.getConversionSettings({
                conversionType: 'MERGE_UNHIDE'
            })
            mobileConversionFacade.runMobileMergeOnPage(ps, desktopPage, mobilePage, settings)
        }
    }

    const removeTinyMenuIfNeeded = ps => {
        const headerPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.MOBILE)
        const tinyMenuPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, headerPointer)
        const tinyMenuLayoutPointer = ps.pointers.getInnerPointer(tinyMenuPointer, 'layout')
        const tinyMenuLayout = ps.dal.get(tinyMenuLayoutPointer)

        if (ps.dal.isExist(tinyMenuPointer)) {
            component.deleteComponent(ps, tinyMenuPointer)
            return _.pick(tinyMenuLayout, ['x', 'y'])
        }
    }

    const addExpandableMenu = ps => {
        if (!isMenuContainerExist(ps)) {
            throw new Error('cannot add menu container toggle without menu instance')
        }

        if (isExpandableMenuExist(ps)) {
            return
        }

        const menuContainerRef = get(ps)
        const expandableMenuRef = component.getComponentToAddRef(ps, menuContainerRef, null, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU)
        component.add(ps, expandableMenuRef, menuContainerRef, expandableMenuDefaultPreset)
    }

    const addMenuContainerIfNeeded = (ps, tinyMenuPosition) => {
        const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
        const mobileHeaderPointer = ps.pointers.components.getComponent(constants.COMP_IDS.HEADER, masterPagePointer)
        const menuContainerPointer = ps.pointers.components.getComponent(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER, mobileHeaderPointer)

        if (ps.dal.isExist(menuContainerPointer)) {
            return
        }

        const menuContainerRef = component.getComponentToAddRef(ps, mobileHeaderPointer, null, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER)
        component.add(ps, menuContainerRef, mobileHeaderPointer, menuContainerDefaultPreset)

        addExpandableMenu(ps)
        addMenuContainerToggle(ps, tinyMenuPosition)
        handleHiddenComponents(ps)
    }

    const upgrade = ps => {
        const tinyMenuPosition = removeTinyMenuIfNeeded(ps)
        addMenuContainerIfNeeded(ps, tinyMenuPosition)
    }

    return {
        get,
        isExist: isMenuContainerExist,
        upgrade,
        toggle: {
            get: getToggle,
            isExist: isMenuToggleExist,
            add: addMenuContainerToggle
        },
        expandableMenu: {
            isExist: isExpandableMenuExist,
            add: addExpandableMenu
        }
    }
})
