import type {Pointer, ViewerManagerDAL} from './types'
import type {Experiment} from '@wix/document-services-types'
import {getSetterForTypeFn, getSetterForInnerPath} from './typeToSetter'
import {getGetterForTypeFn, getGetterForInnerPath} from './typeToGetter'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {ViewerAPI} from '@wix/viewer-manager-interface'
import type {ViewerApiCallsManager} from './debug/viewerInterfaceCallsTrace'
import _ from 'lodash'

const wrapViewerInterfaceWithLogger = (viewer: ViewerAPI, viewerApiTrace: ViewerApiCallsManager) => {
    _.forEach(viewer, (methods, namespace) => {
        _.forEach(methods, (method, methodName) => {
            if (_.isFunction(method)) {
                const originalFunction = method as Function
                viewer[namespace][methodName] = (...args: any[]) => {
                    const res = originalFunction(...args)
                    if (viewerApiTrace.shouldLogTrace()) {
                        viewerApiTrace.logTrace({namespace, methodName, args, res})
                    }
                    return res
                }
            }
        })
    })
}

const createDAL = (viewer: ViewerAPI, viewerApiTrace: ViewerApiCallsManager, isDebugMode: boolean, experimentInstance: Experiment): ViewerManagerDAL => {
    if (isDebugMode) {
        wrapViewerInterfaceWithLogger(viewer, viewerApiTrace)
    }
    const typeToSetter = getSetterForTypeFn(viewer)
    const typeToSetterForInnerItem = getSetterForInnerPath(viewer)
    const typeToGetter = getGetterForTypeFn(viewer)
    const typeToGetterForInnerItem = getGetterForInnerPath(viewer)

    const get = (pointer: Pointer) => {
        if (!_.isString(pointer.id) || !_.isString(pointer.type)) {
            return
        }
        if (pointer.innerPath?.length) {
            const innerItemGetter = typeToGetterForInnerItem(pointer.type)
            //no throw here, because we generically handle get from innerPath if there is no specific implementation for it
            if (innerItemGetter) {
                return innerItemGetter(pointer.id, pointer.innerPath)
            }
        }
        const getter = typeToGetter(pointer.type)
        if (!getter) {
            throw new Error(`No getter registered for type ${pointer.type}`)
        }
        const value = getter(pointer.id, pointer.type, experimentInstance)
        return pointer.innerPath ? _.get(value, pointer.innerPath) : value
    }

    const set = (pointer: Pointer, value: any) => {
        if (pointer.innerPath?.length) {
            const innerItemSetter = typeToSetterForInnerItem(pointer.type)
            if (!innerItemSetter) {
                throw new Error(`No inner-setter registered for type ${pointer.type}`)
            }
            innerItemSetter(pointer.id, pointer.innerPath, deepClone(value))
            return
        }

        const setter = typeToSetter(pointer.type)
        if (!setter) {
            throw new Error(`No setter registered for type ${pointer.type}`)
        }
        setter(pointer.id, deepClone(value), pointer.type, experimentInstance)
    }

    const isExist = (pointer: Pointer) => !!get(pointer)

    const remove = (pointer: Pointer) => set(pointer, undefined)

    return {
        get,
        set,
        isExist,
        remove
    }
}

export {createDAL}
