define([
    'lodash',
    'documentServices/structure/utils/arrangementUtils',
    'documentServices/structure/utils/layoutSettingsUtils',
    'documentServices/structure/utils/arrayUtils',
    '@wix/santa-ds-libs/src/coreUtils',
    'documentServices/componentsMetaData/componentsMetaData',
    '@wix/santa-core-utils'
], function (_, arrangementUtils, layoutSettingsUtils, arrayUtils, coreUtils, componentsMetaData, santaCoreUtils) {
    'use strict'

    const {MASTER_PAGES_SECTIONS} = coreUtils.masterPageLayoutUtils
    const PAGES_CONTAINER_TYPE = 'wysiwyg.viewer.components.PagesContainer'
    const getDisplayedChildPointerInIndex = (ps, container, index) => ps.pointers.components.getChildren(container)[index]

    const isDesktopMasterPage = (ps, compPointer) =>
        ps.pointers.isSamePointer(compPointer, ps.pointers.components.getMasterPage(coreUtils.constants.VIEW_MODES.DESKTOP))

    const getPagesContainer = (ps, viewMode) => ps.pointers.components.getPagesContainer(viewMode)

    const isPagesContainerByType = compType => compType === PAGES_CONTAINER_TYPE

    const isPagesContainer = (ps, compPointer) => isPagesContainerByType(componentsMetaData.getComponentType(ps, compPointer))

    const isFixedPosition = (ps, compPointer) => {
        const isFixedPointer = ps.pointers.getInnerPointer(compPointer, 'layout.fixedPosition')
        return !!ps.dal.get(isFixedPointer)
    }

    const isFixedPositionByStructure = compStructure => _.get(compStructure, ['layout', 'fixedPosition'], false)

    const canSwapMasterPageChildren = (ps, source, target) => {
        if (!arrangementUtils.canSwapComponents(ps, source, target)) {
            return false
        }

        if (isFixedPosition(ps, source) && isFixedPosition(ps, target)) {
            return true
        }

        if (isMasterPageSection(ps, source)) {
            return isMasterPageSection(ps, target)
        }

        return !isMasterPageSection(ps, target) || isPagesContainer(ps, target)
    }

    const switchChildrenRange = (ps, container, groupARange, groupBRange) => {
        const dal = ps.dal.full
        const pointers = ps.pointers.full

        const childrenContainerPointer = pointers.components.getChildrenContainer(container)
        const children = dal.get(childrenContainerPointer)
        const groups = _.sortBy([groupARange, groupBRange], range => range[0])
        const newChildren = arrayUtils.swapGroups(children, groups[0], groups[1])

        dal.set(childrenContainerPointer, newChildren)
    }

    const moveChildrenRangeToEnd = (ps, container, groupRange) => {
        const dal = ps.dal.full
        const pointers = ps.pointers.full

        const childrenContainerPointer = pointers.components.getChildrenContainer(container)
        const children = dal.get(childrenContainerPointer)
        const newChildren = arrayUtils.moveGroupToEndOfArray(children, groupRange)

        dal.set(childrenContainerPointer, newChildren)
    }

    const moveChildrenRangeToStart = (ps, container, groupRange) => {
        const dal = ps.dal.full
        const pointers = ps.pointers.full

        const childrenContainerPointer = pointers.components.getChildrenContainer(container)
        const children = dal.get(childrenContainerPointer)
        const newChildren = arrayUtils.moveGroupToStartOfArray(children, groupRange)

        dal.set(childrenContainerPointer, newChildren)
    }

    const moveChildrenRangeToIndex = (ps, container, groupRange, index) => {
        const dal = ps.dal.full
        const pointers = ps.pointers.full

        const childrenContainerPointer = pointers.components.getChildrenContainer(container)
        const children = dal.get(childrenContainerPointer)
        const newChildren = arrayUtils.moveGroupToIndex(children, groupRange, index)

        dal.set(childrenContainerPointer, newChildren)
    }

    const getSingleCompRange = (ps, compPointer) => {
        const parentPointer = ps.pointers.full.components.getParent(compPointer)
        const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
        const compIndex = _.findIndex(childrenPointers, {id: santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)})

        return [compIndex, compIndex]
    }

    const getSectionIndexRange = (ps, sectionPointer) =>
        isPagesContainer(ps, sectionPointer) ? getPagesContainerRange(ps, sectionPointer) : getSingleCompRange(ps, sectionPointer)

    const moveMasterPageSectionForward = (ps, compPointer) => {
        const nextCompIndex = getNextCompIndexInDisplayedJson(ps, compPointer)
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const targetComp = getDisplayedChildPointerInIndex(ps, parentPointer, nextCompIndex)

        switchChildrenRange(ps, parentPointer, getSectionIndexRange(ps, compPointer), getSectionIndexRange(ps, targetComp))
    }

    const isSiteShowOnAllPagesZindexMigrated = ps => _.get(layoutSettingsUtils.getLayoutSettings(ps), 'soapCompsAroundPagesContainer', false)

    const isMasterPageSectionByCompType = (compType, viewMode) => _.includes(MASTER_PAGES_SECTIONS[viewMode], compType)
    const isMasterPageSection = (ps, compPointer) => isMasterPageSectionByCompType(componentsMetaData.getComponentType(ps, compPointer), compPointer.type)

    const getPagesContainerRangeStart = (ps, childrenPointers, pagesContainerIndex) => {
        if (pagesContainerIndex === 0) {
            return pagesContainerIndex
        }

        return arrayUtils.findPrev(childrenPointers, child => isMasterPageSection(ps, child) || isFixedPosition(ps, child), pagesContainerIndex) + 1
    }

    const getPagesContainerRangeEnd = (ps, childrenPointers, pagesContainerIndex) => {
        const lastChildIndex = childrenPointers.length - 1

        if (pagesContainerIndex === lastChildIndex) {
            return pagesContainerIndex
        }

        const firstSectionFromRight = arrayUtils.findNext(
            childrenPointers,
            child => isMasterPageSection(ps, child) || isFixedPosition(ps, child),
            pagesContainerIndex
        )

        return firstSectionFromRight === -1 ? lastChildIndex : firstSectionFromRight - 1
    }

    const moveMasterPageChildToFront = (ps, compPointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)

        if (isMasterPageSection(ps, compPointer)) {
            moveChildrenRangeToEnd(ps, parentPointer, getSectionIndexRange(ps, compPointer))
        } else {
            const masterPageChildren = ps.pointers.full.components.getChildren(parentPointer)
            const pagesContainerIndex = _.findIndex(masterPageChildren, {id: 'PAGES_CONTAINER'})
            const pagesContainerRangeEnd = getPagesContainerRangeEnd(ps, masterPageChildren, pagesContainerIndex)

            moveChildrenRangeToIndex(ps, parentPointer, getSingleCompRange(ps, compPointer), pagesContainerRangeEnd)
        }
    }

    const findPagesContainerRange = (ps, pagesContainer, childrenPointers) => {
        const pagesContainerIndex = _.findIndex(childrenPointers, {id: pagesContainer.id})

        return [getPagesContainerRangeStart(ps, childrenPointers, pagesContainerIndex), getPagesContainerRangeEnd(ps, childrenPointers, pagesContainerIndex)]
    }

    const getPagesContainerRange = (ps, pagesContainer) => {
        const parentPointer = ps.pointers.full.components.getParent(pagesContainer)

        return findPagesContainerRange(ps, pagesContainer, ps.pointers.full.components.getChildren(parentPointer))
    }

    const moveMasterPageChildToBack = (ps, compPointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)

        if (isMasterPageSection(ps, compPointer)) {
            moveChildrenRangeToStart(ps, parentPointer, getSectionIndexRange(ps, compPointer))
        } else {
            const masterPageChildren = ps.pointers.full.components.getChildren(parentPointer)
            const pagesContainerIndex = _.findIndex(masterPageChildren, {id: 'PAGES_CONTAINER'})
            const pagesContainerRangeStart = getPagesContainerRangeStart(ps, masterPageChildren, pagesContainerIndex)

            moveChildrenRangeToIndex(ps, parentPointer, getSingleCompRange(ps, compPointer), pagesContainerRangeStart)
        }
    }

    const moveMasterPageSectionBackward = (ps, compPointer) => {
        const prevCompIndex = getPrevCompIndexInDisplayedJson(ps, compPointer)
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const targetComp = getDisplayedChildPointerInIndex(ps, parentPointer, prevCompIndex)

        switchChildrenRange(ps, parentPointer, getSectionIndexRange(ps, compPointer), getSectionIndexRange(ps, targetComp))
    }

    const getNextCompIndexInDisplayedJson = (ps, compPointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const childrenPointers = ps.pointers.components.getChildren(parentPointer)
        const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

        return arrayUtils.findNext(childrenPointers, targetPointer => canSwapMasterPageChildren(ps, compPointer, targetPointer), compIndex)
    }

    const getPrevCompIndexInDisplayedJson = (ps, compPointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const childrenPointers = ps.pointers.components.getChildren(parentPointer)
        const compIndex = _.findIndex(childrenPointers, {id: compPointer.id})

        return arrayUtils.findPrev(childrenPointers, targetPointer => canSwapMasterPageChildren(ps, compPointer, targetPointer), compIndex)
    }

    const getRange = (start, end) => [start, end]

    const rejectEmptyRanges = ranges => _.reject(ranges, ([startIndex, endIndex]) => startIndex > endIndex)

    const getValidIndexRangesForChild = (ps, compPointer, parentPointer) => {
        const children = ps.pointers.full.components.getChildren(parentPointer)
        const childrenWithoutMovingChild = _.reject(children, child => ps.pointers.isSamePointer(compPointer, child))
        const pagesContainer = getPagesContainer(ps, compPointer.type)
        const [soapGroupStart, soapGroupEnd] = findPagesContainerRange(ps, pagesContainer, childrenWithoutMovingChild)

        const shouldContainInSoapGroup = !(isMasterPageSection(ps, compPointer) && !isPagesContainer(ps, compPointer)) && !isFixedPosition(ps, compPointer)

        return shouldContainInSoapGroup
            ? [getRange(soapGroupStart, soapGroupEnd + 1)]
            : rejectEmptyRanges([getRange(0, soapGroupStart), getRange(soapGroupEnd + 1, childrenWithoutMovingChild.length)])
    }

    const findValidIndexForChild = (ps, compPointer, parentPointer, preferredIndex) => {
        const children = ps.pointers.full.components.getChildren(parentPointer)
        const childrenWithoutMovingChild = _.reject(children, child => ps.pointers.isSamePointer(compPointer, child))
        preferredIndex = _.isUndefined(preferredIndex) ? childrenWithoutMovingChild.length : preferredIndex

        const validIndexRangesForChild = getValidIndexRangesForChild(ps, compPointer, parentPointer)

        return arrayUtils.findNearestValidIndex(childrenWithoutMovingChild, preferredIndex, validIndexRangesForChild)
    }

    const getValidIndexRangesForNewChild = (ps, compStructure, parentPointer) => {
        const viewMode = parentPointer.type
        const children = ps.pointers.full.components.getChildren(parentPointer)
        const pagesContainer = getPagesContainer(ps, viewMode)
        const [soapGroupStart, soapGroupEnd] = findPagesContainerRange(ps, pagesContainer, children)

        const shouldContainInSoapGroup =
            !(isMasterPageSectionByCompType(compStructure.componentType, viewMode) && !isPagesContainerByType(compStructure.componentType)) &&
            !isFixedPositionByStructure(compStructure)

        return shouldContainInSoapGroup
            ? [getRange(soapGroupStart, soapGroupEnd + 1)]
            : rejectEmptyRanges([getRange(0, soapGroupStart), getRange(soapGroupEnd + 1, children.length)])
    }

    const findValidIndexForNewChild = (ps, compStructure, parentPointer) => {
        const validIndexRangesForNewChild = getValidIndexRangesForNewChild(ps, compStructure, parentPointer)
        const children = ps.pointers.full.components.getChildren(parentPointer)

        return arrayUtils.findNearestValidIndex(children, children.length, validIndexRangesForNewChild)
    }

    const shouldUseMasterPageArrangement = (ps, parentPointer) => isDesktopMasterPage(ps, parentPointer) && isSiteShowOnAllPagesZindexMigrated(ps)

    return {
        shouldUseMasterPageArrangement,
        isMasterPageSection,
        getPrevCompIndexInDisplayedJson,
        getNextCompIndexInDisplayedJson,
        findValidIndexForNewChild,
        findValidIndexForChild,
        moveMasterPageSectionBackward,
        moveMasterPageSectionForward,
        moveMasterPageChildToFront,
        moveMasterPageChildToBack
    }
})
