define(['lodash', 'documentServices/wixapps/utils/classicsPathUtils'], function (_, classicsPathUtils) {
    'use strict'

    /**
     * @param {ps} ps Private Services
     * @param {string} packageName
     * @returns {object} the json describing the package.
     */
    function getDescriptor(ps, packageName) {
        return ps.dal.getByPath(classicsPathUtils.getPackageDescriptorPath(packageName))
    }

    /**
     * @param {ps} ps Private Services
     * @param {number} applicationId
     * @returns {string}.
     */
    function getPackageName(ps, applicationId) {
        const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
        // @ts-ignore
        const packageNamePointer = ps.pointers.getInnerPointer(clientSpecMapPointer, [applicationId, 'packageName'])
        return ps.dal.get(packageNamePointer)
    }

    function getAppPartRole(ps, packageName, appPartName) {
        const part = getAppPartDefinition(ps, packageName, appPartName)
        return _.get(part, 'role')
    }

    function getAppPartDefinition(ps, packageName, appPartName) {
        const descriptor = getDescriptor(ps, packageName)
        const parts = _.get(descriptor, 'parts')
        return _.find(parts, {id: appPartName})
    }

    return {
        getPackageName,
        getAppPartRole,
        getAppPartDefinition
    }
})
