import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {DAL, DalValue, Pointer, DalValueChangeCallback, createStoreFromJS, SnapshotDal, DmStore, logDalValueChanges} from '@wix/document-manager-core'

let dalValueChangeCallback: DalValueChangeCallback

/**
 * Log all dal value changes. Only the dal value properties that changed will be shown with their old and new values, making it easier to understand the data flow.
 * @param namespaceWhitelist - only show changes in these namespaces or all namespaces if this argument in falsy
 * @param idWhiteList - only show values with ids in this list or all ids if this argument in falsy
 */
function registerForDalValueChanges(dal: DAL, namespaceWhitelist?: string | string[], idWhiteList?: string | string[]) {
    if (_.isString(namespaceWhitelist)) {
        namespaceWhitelist = [namespaceWhitelist]
    }
    if (_.isString(idWhiteList)) {
        idWhiteList = [idWhiteList]
    }
    Error.stackTraceLimit = Math.max(Error.stackTraceLimit, 15)
    dal.unregisterForChangesCallback(dalValueChangeCallback)
    dalValueChangeCallback = (rootPointer: Pointer, oldValue: DalValue, newValue: DalValue) => {
        const {type} = rootPointer
        if (idWhiteList && !idWhiteList.includes(rootPointer.id)) {
            return
        }
        if (namespaceWhitelist && !namespaceWhitelist.includes(type)) {
            return
        }
        const prefix = new Error().stack?.includes('rebaseForeignChange') ? '*** ' : ''
        logDalValueChanges(rootPointer, oldValue, newValue, prefix)
    }
    dal.registerForChangesCallback(dalValueChangeCallback)
}

/**
 * Returns an array of the components in the component hierarchy.
 * @param dal
 * @param componentId - the id of the child component at the bottom of the hierarchy
 * @param fields - an array of component properties to display or '*' to show all properties
 */
const componentHierarchy = (
    dal: DAL,
    viewMode: string,
    componentId: string,
    fields: string[] | string = ['id', 'type', 'componentType', 'parent'],
    result: any[] = []
) => {
    const comp = deepClone(dal.get({id: componentId, type: viewMode}))
    if (comp) {
        result.push(Array.isArray(fields) ? _.pick(comp, fields) : comp)
        componentHierarchy(dal, viewMode, comp.parent, fields, result)
    }
    return result
}

/**
 * Recursively strips the signatures from any object without modifying the object.
 * @param obj - any value, including non dal values like serialized components
 * @returns the object stripped of all signature properties (sig and basedOnSignature)
 */
function stripSignatures(obj: any) {
    if (Array.isArray(obj)) {
        obj = obj.map(e => stripSignatures(e))
    } else if (_.isObject(obj)) {
        obj = _.omit(obj, ['sig', 'basedOnSignature'])
        Object.keys(obj).forEach(k => {
            obj[k] = stripSignatures(obj[k])
        })
    }
    return obj
}

class StateComparer {
    private count: number = 0
    private snapshots: Record<string, SnapshotDal> = {}
    constructor(readonly dal: DAL) {}
    private compareSnapshots(from: DmStore, to: DmStore, namespaceWhitelist?: string[]) {
        to.forEach((pointer: Pointer, value: DalValue) => {
            if (namespaceWhitelist && !namespaceWhitelist.includes(pointer.type)) {
                return
            }
            logDalValueChanges(pointer, from.get(pointer), value, '')
        })
        from.forEach((pointer: Pointer, value: DalValue) => {
            if (namespaceWhitelist && !namespaceWhitelist.includes(pointer.type)) {
                return
            }
            if (!to.has(pointer)) {
                logDalValueChanges(pointer, value, undefined, '')
            }
        })
    }
    private getNamed(key: string): DmStore {
        const snapshot = this.snapshots[key]
        if (!snapshot) {
            throw new Error(`Snapshot ${key} does not exist`)
        }
        return createStoreFromJS(snapshot.toJS())
    }
    takeSnapshot(): string {
        const name = `s${this.count++}`
        this.snapshots[name] = this.dal.getLastSnapshot()!
        return name
    }
    compareCurrent(namespaceWhitelist?: string[]): void {
        if (_.isEmpty(this.snapshots)) {
            console.log('Please take a snapshot to compare with')
            return
        }
        const lastKey = `s${this.count - 1}`
        const current = createStoreFromJS(this.dal._getMergedStoreAsJson())
        this.compareSnapshots(this.getNamed(lastKey), current, namespaceWhitelist)
    }
    compareNamed(fromKey: string, toKey: string, namespaceWhitelist?: string[]): void {
        this.compareSnapshots(this.getNamed(fromKey), this.getNamed(toKey), namespaceWhitelist)
    }
    clear() {
        this.count = 0
        this.snapshots = {}
    }
}

export {componentHierarchy, registerForDalValueChanges, stripSignatures, StateComparer}
