define(['documentServices/component/componentStructureInfo', 'documentServices/page/page', 'documentServices/dataModel/dataModel'], function (
    componentStructureInfo,
    page,
    dataModel
) {
    const updateDataItem = (ps, componentPointer, dataItem, useOriginalLanguage) => {
        if (componentStructureInfo.isPageComponent(ps, componentPointer)) {
            page.data.update(ps, componentPointer.id, dataItem, useOriginalLanguage)
        } else {
            dataModel.updateDataItem(ps, componentPointer, dataItem, useOriginalLanguage)
        }
    }
    return {
        updateDataItem
    }
})
