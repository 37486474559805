import _ from 'lodash'
import layout from '../util/layout'
import balataLayout from './balataLayout'
import warmupUtilsLib from '@wix/santa-core-utils'

const {balataConsts} = warmupUtilsLib.mediaConsts

function measureStateBox(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
    const parentNode = nodesMap[id]

    if (!parentNode) {
        return
    }

    const currentSlideId = parentNode.dataset.currentslide
    const slideStructureHeight = _.find(structureInfo.structure.components, {id: currentSlideId}).layout.height
    const currentSlideHeight = measureMap.height[currentSlideId] || measureMap.height[`${currentSlideId}${balataConsts.BALATA}`] || slideStructureHeight
    const children = warmupUtilsLib.dataUtils.getChildrenData(structureInfo.structure, isMobileView())
    const childrenIds = _.map(children, 'id')

    if (currentSlideHeight) {
        measureMap.height[id] = currentSlideHeight
        _(childrenIds)
            .without(currentSlideId)
            .forEach(slideID => {
                measureMap.injectedAnchors[slideID] = []
            })
        measureMap.injectedAnchors[currentSlideId] = [
            {
                fromComp: currentSlideId,
                distance: 0,
                topToTop: 0,
                originalValue: 0,
                type: 'BOTTOM_PARENT',
                locked: true,
                targetComponent: id
            }
        ]
    }
}

function measureStateBoxState(id, measureMap, nodesMap, structureInfo) {
    measureMap.top[id] = 0
    if (!structureInfo.designDataItem.background) {
        return
    }

    balataLayout.measure(id, measureMap, nodesMap, structureInfo)
}

function patchStateBoxState(id, patchers, measureMap, structureInfo, siteData) {
    patchers.css(id, {width: '100%'})
    if (!structureInfo.designDataItem.background) {
        return
    }

    balataLayout.patch(id, patchers, measureMap, structureInfo, siteData)
}

layout.registerCustomMeasure('wysiwyg.viewer.components.StateBox', measureStateBox)
layout.registerMeasureChildrenFirst('wysiwyg.viewer.components.StateBox', true)

//the default flow is that the parent is measured first
layout.registerRequestToMeasureDom('wysiwyg.viewer.components.StateBoxState')
layout.registerRequestToMeasureChildren(
    'wysiwyg.viewer.components.StateBoxState',
    [['inlineContentParent'], ['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE)
)
layout.registerCustomMeasure('wysiwyg.viewer.components.StateBoxState', measureStateBoxState)
layout.registerPatcher('wysiwyg.viewer.components.StateBoxState', patchStateBoxState)

layout.registerCustomMeasure('wysiwyg.viewer.components.StateStripState', measureStateBoxState)
layout.registerPatcher('wysiwyg.viewer.components.StateStripState', patchStateBoxState)

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.StateBoxFormState')
layout.registerRequestToMeasureChildren(
    'wysiwyg.viewer.components.StateBoxFormState',
    [['inlineContentParent'], ['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE)
)
layout.registerCustomMeasure('wysiwyg.viewer.components.StateBoxFormState', measureStateBoxState)
layout.registerPatcher('wysiwyg.viewer.components.StateBoxFormState', patchStateBoxState)
