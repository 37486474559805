import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import tpaUtils from '../utils/tpaUtils'
import TPAUrlBuilder from '../common/TPAUrlBuilder'

const BOOKING_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97'

const getIframeQueryParams = function (comp, queryParamsObj) {
    if (comp.mutateIframeUrlQueryParam) {
        queryParamsObj = comp.mutateIframeUrlQueryParam(queryParamsObj)
    }

    return queryParamsObj
}

const resolveTemplateUrlParams = (baseUrl, appData, compId) => {
    let resolvedBaseUrl = baseUrl

    if (_.includes(baseUrl, '{instanceId}')) {
        let {instanceId} = appData
        if (!instanceId) {
            const instance = tpaUtils.getInstanceFromAppData(appData)
            instanceId = instance.instanceId
        }

        resolvedBaseUrl = _.replace(resolvedBaseUrl, new RegExp('{instanceId}', 'g'), instanceId)
    }

    if (_.includes(baseUrl, '{compId}')) {
        resolvedBaseUrl = _.replace(resolvedBaseUrl, new RegExp('{compId}', 'g'), compId)
    }

    return resolvedBaseUrl
}

function shouldTemplateValue() {
    return this.props.isInSSR && this.props.isCacheable
}

function getFullUrl(url, hasQueryParams) {
    const {applicationId, widgetId} = this.props.compData
    if (!url || !shouldTemplateValue.call(this) || _.isNil(applicationId)) {
        return url
    }

    const baseUrl = `${_.head(_.split(url, '?'))}?`
    const queryConnector = hasQueryParams ? '&' : '?'
    const templateBaseUrl = _.get(this, 'isTPAWorker', _.noop)()
        ? `\${${applicationId}.appWorkerUrl}${queryConnector}`
        : `\${${applicationId}.widgets.${widgetId}.widgetUrl}${queryConnector}`
    return _.replace(url, baseUrl, templateBaseUrl)
}

function getMultipleQueryParams() {
    let params = _.clone(_.get(this.props, 'aspectData.queryParams', {}))
    params = _.merge(params, getIframeQueryParams(this, params))
    params = _.merge(params, tpaUtils.getDebuggingParamsFromUrl(this.props.currentUrl))
    params = _.merge(params, this.state.sectionUrlParams)

    // is linked to felix extension called tpa params,
    // should deprecate this and they should use the AppSectionParams!!
    if (this.getEcomParams && this.getEcomParams()) {
        params['ecom-tpa-params'] = this.getEcomParams()
    }
    return params
}

function getInstance() {
    if (shouldTemplateValue.call(this)) {
        return `\${${this.props.compData.applicationId}.instance}`
    }
    const isBookingOnTemplate = this.props.isPreviewMode && this.props.isTemplate && this.props.compData.appDefinitionId === BOOKING_APP_DEF_ID
    if (isBookingOnTemplate) {
        if (this.props.initialClientSpecMap && this.props.initialClientSpecMap[this.props.compData.applicationId]) {
            return this.props.initialClientSpecMap[this.props.compData.applicationId].instance
        }
    }
    return this.state.initialInstance
}

function getExternalId() {
    return this.state.preventRefresh ? this.state.externalId : this.props.compData.referenceId
}
/**
 * @class tpa.mixins.tpaUrlBuilder
 */
export default {
    propTypes: {
        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
        isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode,
        isGoogleBot: santaComponents.santaTypesDefinitions.isGoogleBot,
        componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
        languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode,
        compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
        rootId: santaComponents.santaTypesDefinitions.Component.rootId,
        viewerSessionId: santaComponents.santaTypesDefinitions.viewerSessionId,
        currentUrl: santaComponents.santaTypesDefinitions.currentUrl.isRequired,
        aspectData: santaComponents.santaTypesDefinitions.TPA.data,
        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
        currentLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.currentLanguage,
        originalLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.originalLanguage,
        regionalLanguage: santaComponents.santaTypesDefinitions.RendererModel.regionalLanguage,
        currency: santaComponents.santaTypesDefinitions.RendererModel.currency,
        currentCurrency: santaComponents.santaTypesDefinitions.RendererModel.multiCurrency.currentCurrency,
        timeZone: santaComponents.santaTypesDefinitions.RendererModel.timeZone,
        isInSSR: santaComponents.santaTypesDefinitions.isInSSR,
        isCacheable: santaComponents.santaTypesDefinitions.isCacheable,
        siteRevision: santaComponents.santaTypesDefinitions.PublicModel.siteRevision,
        isTemplate: santaComponents.santaTypesDefinitions.isTemplate,
        isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode,
        initialClientSpecMap: santaComponents.santaTypesDefinitions.TPA.initialClientSpecMap,
        templateCompId: santaComponents.santaTypesDefinitions.Component.templateCompId
    },
    getInitialState() {
        this.viewMode = this.getViewMode()
    },

    getViewMode() {
        return this.props.isViewerMode ? 'site' : this.props.componentViewMode
    },

    buildUrl(baseUrl, whiteList) {
        const hasQueryParams = /[?]/.test(baseUrl)
        baseUrl = tpaUtils.appendProtocolToUrlIfNeeded(baseUrl, this.props.currentUrl)

        const appData = this.getAppData(this)
        //when templateCompId exists, it should be the compId passed to the tpa e.g in case the controller lives within a shared block
        //compId is still needed for js-sdk
        const viewerCompId = this.props.id
        const compId = this.props.templateCompId || viewerCompId
        baseUrl = resolveTemplateUrlParams(baseUrl, appData, compId)

        const height = this.state.initialHeight ? this.state.initialHeight.toString() : undefined

        const currentLanguageCode = _.get(this.props, 'currentLanguage.languageCode')
        const originalLanguageCode = this.props.isViewerMode ? _.get(this.props, 'originalLanguage.languageCode') : _.get(this.props, 'originalLanguage.code')
        const dateNumberFormat = _.get(this.props, 'currentLanguage.locale')
        let isPrimaryLanguage
        if (currentLanguageCode && originalLanguageCode) {
            isPrimaryLanguage = originalLanguageCode === currentLanguageCode
        }

        const urlObj = new TPAUrlBuilder(baseUrl)
        urlObj
            .addCompId(compId)
            .addViewerCompId(viewerCompId)
            .addDeviceType(this.getDeviceType(this))
            .addInstance(getInstance.call(this))
            .addLocale(this.props.languageCode)
            .addViewMode(this.viewMode)
            .addCacheKiller(tpaUtils.getCacheKiller())
            .addExternalId(getExternalId.call(this))
            .addPageId(this.props.rootId)
            .addHeight(height)
            .filterQueryParams(whiteList)
            .addMultipleQueryParams(getMultipleQueryParams.call(this))
            .addViewerSessionId(this.props.viewerSessionId)
            .addRouterData(this.state.initialRouterData, this.props.isExperimentOpen)
            .addLang(currentLanguageCode)
            .addRegionalLanguage(this.props.regionalLanguage)
            .addDateNumberFormat(dateNumberFormat)
            .addIsPrimaryLanguage(_.toString(isPrimaryLanguage))
            .addCurrency(this.props.currency)
            .addTimeZone(this.props.timeZone)
            .addSiteRevision(this.props.siteRevision)
            .addCurrentCurrency(this.props.currentCurrency)

        if (this.mutateIframeSrc) {
            urlObj.mutateIframeSrc(this.mutateIframeSrc)
        }

        if (this.props.isInSSR) {
            const commonConfigPlaceholder = '{{commonConfig}}'
            const consentPolicyPlaceholder = '{{consentPolicy}}'
            urlObj.addCommonConfig(commonConfigPlaceholder)
            urlObj.addConsentPolicy(consentPolicyPlaceholder)
        } else if (this.state.commonConfig) {
            // for client fallbacks and navigation
            urlObj.addCommonConfig(this.state.commonConfig)
            const {consentPolicyHeader} = this.state.commonConfig
            if (!_.isEmpty(consentPolicyHeader)) {
                urlObj.addConsentPolicy(consentPolicyHeader['consent-policy'])
            }
        }
        return getFullUrl.call(this, urlObj.build(), hasQueryParams)
    }
}
