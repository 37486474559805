define(['@wix/santa-core-utils', 'lodash', 'documentServices/mobileConversion/mobileConversionFacade'], function (santaCoreUtils, _, mobileConversionFacade) {
    'use strict'

    const {constants} = santaCoreUtils

    function createCompPriorityFn(compOrder) {
        const componentsPriorityMap = _.invert(compOrder)

        return function (comp) {
            const compId = _.get(comp, 'id', comp)
            const compPriority = componentsPriorityMap[compId]

            return compPriority ? Number(compPriority) : -1
        }
    }

    /**
     * @param {Object} compIdsOrder
     * @returns {function(*, *): number}
     */
    function createSortingPredicat(compIdsOrder) {
        const getCompPriority = createCompPriorityFn(compIdsOrder)

        return function (comp1, comp2) {
            const comp1Priority = getCompPriority(comp1)
            const comp2Priority = getCompPriority(comp2)

            return comp1Priority - comp2Priority
        }
    }

    /**
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @param {Object} childComponentsOrder
     */
    function reorderChildrenOfComponent(ps, componentPointer, childComponentsOrder) {
        const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(componentPointer)
        const children = ps.dal.full.get(childrenContainerPointer)

        if (!(ps.dal.get(componentPointer).componentType === 'wysiwyg.viewer.components.HoverBox')) {
            children.sort(createSortingPredicat(childComponentsOrder))
        }

        ps.dal.full.set(childrenContainerPointer, children)
    }

    function runSeparatly(func, resultOfPrevFunction) {
        return new Promise(function (resolve, reject) {
            _.delay(function () {
                let funcResult
                try {
                    funcResult = func(resultOfPrevFunction)
                    resolve(funcResult)
                } catch (e) {
                    reject(e)
                }
            })
        })
    }

    function flowThrough(listOfFuncs) {
        return _.reduce(
            listOfFuncs,
            function (previousPromise, atomicFunction) {
                // eslint-disable-next-line promise/prefer-await-to-then
                return previousPromise.then(resultOfPrevFunction => runSeparatly(atomicFunction, resultOfPrevFunction))
            },
            Promise.resolve()
        )
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     * @param {string} containerId
     * @param {Object} containerOrder
     */
    function reorderPageContainer(ps, pageId, containerId, containerOrder) {
        const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const componentPointer = ps.pointers.full.components.getComponent(containerId, desktopPagePointer)
        if (componentPointer) {
            reorderChildrenOfComponent(ps, componentPointer, containerOrder)
        }
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     * @param orderOfContainers
     * @returns {Promise<unknown[]>}
     */
    function reorderAllContainersOnPage(ps, pageId, orderOfContainers) {
        return Promise.all(
            _.transform(
                orderOfContainers,
                function (promises, containerOrder, containerId) {
                    const reorderContainerPromise = runSeparatly(_.partial(reorderPageContainer, ps, pageId, containerId, containerOrder))

                    promises.push(reorderContainerPromise)
                    return promises
                },
                []
            )
        )
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     * @returns {*}
     */
    function reorderOnePage(ps, pageId) {
        return flowThrough([
            function getComponentsOrder() {
                return mobileConversionFacade.getComponentsOrder(ps, pageId)
            },
            function orderPageChildrenComponents(componentsOrder) {
                if (pageId !== 'masterPage') {
                    const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
                    reorderChildrenOfComponent(ps, desktopPagePointer, componentsOrder[pageId])
                }
                return _.omit(componentsOrder, pageId)
            },
            function orderPageContainersChildrenComponents(orderOfContainers) {
                return reorderAllContainersOnPage(ps, pageId, orderOfContainers)
            }
        ])
    }

    /**
     * @param {ps} ps
     * @param {function():void} onSuccess
     * @param {function():void} onError
     * @param pagesToReorder
     */
    const reorderComponents = (ps, onSuccess, onError, pagesToReorder) => {
        Promise.all(_.map(pagesToReorder, _.partial(reorderOnePage, ps))).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
    }

    return {
        reorderComponents
    }
})
