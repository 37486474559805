import type {DocumentManager} from '@wix/document-manager-core'
import type {hooks, PS} from '@wix/document-services-types'

export function bridgeDMEventsToHooks(ps: PS, documentManager: DocumentManager, dsHooks: hooks) {
    const {EVENTS, eventEmitter} = documentManager
    const undoRedoEventsToTriggerAutoSave = [EVENTS.UNDO_REDO.TAKE_SNAPSHOT, EVENTS.UNDO_REDO.UNDO, EVENTS.UNDO_REDO.REDO]

    const undoRedoEventsToTriggerAfterApplySnapShot = [EVENTS.UNDO_REDO.UNDO, EVENTS.UNDO_REDO.REDO]

    const notifyAutosave = () => dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.ACTION)

    const notifyAfterApplySnapshot = () => dsHooks.executeHook(dsHooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, null!, [ps])

    dsHooks.registerHook(dsHooks.HOOKS.SAVE.SITE_SAVED, (isFirstSave: boolean) => {
        if (EVENTS.CSAVE) {
            eventEmitter.emit(EVENTS.CSAVE.SITE_SAVED, isFirstSave)
        }
    })
    if (EVENTS.CSAVE) {
        eventEmitter.on(EVENTS.CSAVE.NON_RECOVERABLE_ERROR, () => {
            dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR)
        })
    }

    undoRedoEventsToTriggerAutoSave.forEach(ev => eventEmitter.on(ev, notifyAutosave))
    undoRedoEventsToTriggerAfterApplySnapShot.forEach(ev => eventEmitter.on(ev, notifyAfterApplySnapshot))
}
