import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import wixapps from '@wix/santa-ds-libs/src/wixappsCore'

const {logicFactory} = wixapps

const {blogAppPartNames} = coreUtils

/**
 * @class ecom.AddToCartButtonLogic
 * @param partApi
 * @constructor
 */
function ArchiveLogic(partApi) {
    this.partApi = partApi
}

ArchiveLogic.prototype = {
    comboBoxSelectionChanged(evt) {
        const blogPage = this.partApi.getBlogAppPage()
        const navigationInfo = {
            pageId: blogPage.id,
            pageAdditionalData: evt.payload.value ? `Date/${evt.payload.value}` : ''
        }

        this.partApi.navigateToPage(navigationInfo)
    },
    listOptionClicked() {
        // handled in the all mighty appPartDataRequirementsChecker
    }
}

logicFactory.register(blogAppPartNames.ARCHIVE, ArchiveLogic)
