import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const ASPECT_NAME = 'tpaPopupAspect'

function getPopupStructure() {
    return {
        componentType: 'wysiwyg.viewer.components.tpapps.TPAPopup',
        type: 'Component',
        // @ts-ignore
        id: coreUtils.guidUtils.getUniqueId(),
        skin: 'wysiwyg.viewer.skins.TPAPopupSkin',
        styleId: ''
    }
}

function getPopupGlobalData(aspectSiteApi) {
    return aspectSiteApi.getAspectGlobalData(ASPECT_NAME)
}

function hasPopups(popups) {
    return popups && _.isArray(popups) && popups.length > 0
}

function persistentPopups(popups) {
    return _.filter(popups, {popupData: {persistent: true}})
}

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteApi
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function TPAPopupAspect(aspectSiteApi) {
    /** @type core.SiteAspectsSiteAPI */
    this.aspectSiteApi = aspectSiteApi

    _.bindAll(this, _.functionsIn(this))
    this.aspectSiteApi.registerToUrlPageChange(this.removeAllPopups)
}

TPAPopupAspect.prototype = {
    getComponentsToRender() {
        const popups = getPopupGlobalData(this.aspectSiteApi)
        if (hasPopups(popups)) {
            return _.map(popups, function (popup) {
                return {
                    structure: popup.structure,
                    props: {
                        compData: popup.popupData,
                        onCloseCallback: popup.popupOnClose
                    }
                }
            })
        }
        return null
    },

    showPopup(data, callback) {
        const popupData = {structure: getPopupStructure(), popupData: data, popupOnClose: callback}
        let popups = getPopupGlobalData(this.aspectSiteApi)
        if (!hasPopups(popups)) {
            popups = []
        }
        popups.push(popupData)

        this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, popups)
        this.aspectSiteApi.forceUpdate()
    },

    removePopup(comp) {
        const compId = comp.props.id
        let popups = getPopupGlobalData(this.aspectSiteApi)
        if (hasPopups(popups)) {
            popups = _.reject(popups, {structure: {id: compId}})

            this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, popups)
            this.aspectSiteApi.forceUpdate()
        }
    },

    removeAllPopups() {
        const popups = getPopupGlobalData(this.aspectSiteApi)
        if (hasPopups(popups)) {
            this.aspectSiteApi.setAspectGlobalData(ASPECT_NAME, persistentPopups(popups))
            this.aspectSiteApi.forceUpdate()
        }
    }
}

export default TPAPopupAspect
