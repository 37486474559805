import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import coreUtilsLib from '@wix/santa-core-utils'

const {languages} = warmupUtils
const {siteDataUtils} = warmupUtils
const {cookieUtils} = coreUtilsLib
const LANGUAGE_COOKIE = 'wixLanguage'
const HELPER_COOKIE = 'wixClient'
const supportedRTLLanguages = ['he']

function getClientParams(cookieStr) {
    const paramsCookie = cookieUtils.parseCookieString(cookieStr)[HELPER_COOKIE]

    if (paramsCookie) {
        const params = paramsCookie.split('|')
        return {
            userName: params[0],
            email: params[1],
            mailStatus: params[2],
            permissions: params[3],
            isSessionNew: params[4],
            isSessionValid: params[5],
            userID: params[6]
        }
    }

    return null
}

function getLanguageFromCookie(cookieStr) {
    return cookieUtils.parseCookieString(cookieStr)[LANGUAGE_COOKIE]
}

function getLanguageFromBrowser() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return null
    }

    const lang = window.navigator.languages ? window.navigator.languages[0] : null
    // @ts-ignore
    return lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage
}

/** @class siteUtils.wixUserApi */
const userApi = {
    /**
     * Get wix user's username
     * @param {SiteData} siteData
     * @returns {string|null} username if user is logged in, null otherwise
     */
    getUsername(siteData) {
        const cookieStr = siteDataUtils.getRequestModel(siteData, 'cookie')
        const clientParams = getClientParams(cookieStr)

        return clientParams ? clientParams.userName : null
    },

    /**
     * Get wix user's language
     * @param {string} cookieStr
     * @param {Object.<string, *>} parsedUrl
     * @param {string} langFromModel
     * @returns {string} user language (default 'en')
     */
    getLanguage(cookieStr, parsedUrl, langFromModel) {
        const fromCookie = getLanguageFromCookie(cookieStr || '')
        const fromUrl = parsedUrl.query && parsedUrl.query.lang
        const fromDomain = () => {
            const parts = parsedUrl.host.split('.')
            return parts[0].length === 2 ? parts[0] : null
        }

        let lng = fromUrl || fromDomain() || fromCookie || getLanguageFromBrowser() || langFromModel
        if (lng) {
            lng = lng.substring(0, 2).toLowerCase()
        }

        return _.includes(languages, lng) ? lng : 'en'
    },

    getLanguageFromSiteData(siteData) {
        const cookie = siteData.getCookie()
        const {currentUrl} = siteData
        const languageFromModel = siteData.getUserLanguageFromModel()
        return this.getLanguage(cookie, currentUrl, languageFromModel)
    },

    /**
     * Check if the wix user is logged in with a valid session cookie
     * @param {string} cookieStr
     * @returns {boolean}
     */
    isSessionValid(cookieStr) {
        return !!getClientParams(cookieStr)
    },

    /**
     * Clear wix user cookies
     */
    logout(siteData) {
        let domain = window.document ? window.document.location.host : ''
        domain = domain.substring(domain.indexOf('.') + 1)
        cookieUtils.deleteCookie(HELPER_COOKIE, domain)

        if (typeof document !== 'undefined') {
            siteData.requestModel.cookie = window.document.cookie
        }
    },

    getLanguages() {
        return languages
    },

    getLangDirection(lang) {
        return supportedRTLLanguages.includes(lang) ? 'rtl' : 'ltr'
    }
}

export default userApi
