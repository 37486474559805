import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import componentsAnchorsMetaData from './componentsAnchorsMetaData'

const anchorsTypes = {
    bottomTop: 'BOTTOM_TOP',
    bottomParent: 'BOTTOM_PARENT',
    topTop: 'TOP_TOP'
}

const COMPS_WITH_NO_ANCHORS = ['BACK_TO_TOP_BUTTON']
const STRUCTURAL_COMP_IDS = ['SITE_HEADER', 'SITE_FOOTER', 'PAGES_CONTAINER']

const getComponentAnchorMetaDataByPath = (componentType, path) => {
    const compAnchorsMetaData = componentsAnchorsMetaData.get()
    return _.get(compAnchorsMetaData, [componentType, ...path], _.get(compAnchorsMetaData.default, path))
}

function componentCanBePushed(componentType) {
    return getComponentAnchorMetaDataByPath(componentType, ['to', 'allow']) !== false
}

function componentCanPush(componentType) {
    return getComponentAnchorMetaDataByPath(componentType, ['from', 'allow']) !== false
}

function canCreateAnchorsToComp(comp) {
    return (
        _.includes(STRUCTURAL_COMP_IDS, comp.id) ||
        !(_.includes(COMPS_WITH_NO_ANCHORS, comp.id) || comp.layout.fixedPosition || warmupUtilsLib.layoutUtils.isVerticallyDocked(comp.layout))
    )
}

function isAnchorLocked(type, fromCompType, distance) {
    if (type === anchorsTypes.topTop) {
        return true
    }

    const fromCompAnchorsMetaData = getComponentAnchorMetaDataByPath(fromCompType, ['from'])

    if (fromCompAnchorsMetaData.lock === warmupUtilsLib.constants.ANCHORS.LOCK_CONDITION.NEVER) {
        return false
    }

    if (fromCompAnchorsMetaData.lock === warmupUtilsLib.constants.ANCHORS.LOCK_CONDITION.ALWAYS) {
        return true
    }

    return distance <= warmupUtilsLib.constants.ANCHORS.LOCK_THRESHOLD
}

export default {
    isAnchorLocked,
    anchorsTypes,
    componentCanPush,
    componentCanBePushed,
    canCreateAnchorsToComp
}
