define(['lodash'], function (_) {
    'use strict'

    let origin = {}

    function setOrigin(originPassedByEditor = {}) {
        origin = originPassedByEditor
    }

    const getOrigin = () => origin

    const getEditorType = () => _.get(origin, 'type', '')

    return {
        setOrigin,
        getOrigin,
        getEditorType
    }
})
