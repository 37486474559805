import _ from 'lodash'
import wixappsCore from '@wix/santa-ds-libs/src/wixappsCore'
import transformAndSetMetaData from '@wix/santa-ds-libs/src/wixappsClassics/src/core/transformAndSetMetaData'
import transformMediaItemsResponse from '@wix/santa-ds-libs/src/wixappsClassics/src/core/transformMediaItemsResponse'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {urlUtils} = coreUtils
const {wixappsDataHandler} = wixappsCore

function getRelatedPostsUrl(baseUrl, permalink, storeId) {
    if (permalink) {
        return `${baseUrl}/apps/lists/1/posts/permalink/related?permalink=${encodeURIComponent(permalink)}&storeId=${storeId}`
    }

    //Return url to enpoint which return related posts for recent post.
    //So when we open single post page in editor first time we don't have permalink
    //and we use this url (see this bug BLOG-3542).
    return `${baseUrl}/apps/lists/1/posts/related?&storeId=${storeId}`
}

function wrappedTransformItemsFn(compData, params, lang, responseData, currentValue) {
    transformMediaItemsResponse(compData.id, params.collectionId, {payload: responseData}, currentValue, lang)
}

function readRelatedPosts(siteData, compData, appService, params, urlData, defaultOptions) {
    params.collectionId = params.collectionId || defaultOptions.collectionId

    const cookie = coreUtils.siteDataUtils.getRequestModel(siteData, 'cookie')
    const languageFromModel = coreUtils.siteDataUtils.getRequestModel(siteData, 'language')
    const lang = coreUtils.wixUserApi.getLanguage(cookie, siteData.currentUrl, languageFromModel).toLowerCase() || 'en'
    const primaryRootInfo = siteData.getExistingRootNavigationInfo(siteData.getPrimaryPageId())
    const pageInfo = urlData ? _.assign(primaryRootInfo, urlData) : primaryRootInfo
    const permalink = (pageInfo && pageInfo.pageAdditionalData) || ''
    const transformFn = _.partial(wrappedTransformItemsFn, compData, params, lang)

    return [
        {
            force: true,
            destination: wixappsDataHandler.getSiteDataDestination(appService.packageName),
            name: appService.packageName,
            url: getRelatedPostsUrl(urlUtils.baseUrl(siteData.getExternalBaseUrl()), permalink, appService.datastoreId),
            transformFunc: transformAndSetMetaData.bind(this, transformFn, siteData, appService.packageName, compData.id),
            timeout: 10
        }
    ]
}

export default {
    readRelatedPosts
}
