import _ from 'lodash'
import loggingUtils from '../loggingUtils/loggingUtils'

function BiData(wixBiSession, getCurrentUrlPageId) {
    this.getCurrentUrlPageId = getCurrentUrlPageId
    this.pageNumber = 1
    this.pageLoadStart = _.get(wixBiSession, ['initialTimestamp'], _.now())
    this.networkPageLoadStart = _.get(wixBiSession, ['initialRequestTimestamp'], _.now())
    this.wixBiSession = wixBiSession
}

BiData.prototype = {
    getPageId() {
        return this.pageId || this.getCurrentUrlPageId()
    },
    getPageNumber() {
        return this.pageNumber
    },
    getTime() {
        const loadingTime = _.now() - this.pageLoadStart
        const totalLoadingTime = this.pageNumber === 1 ? Math.round(loggingUtils.performance.now()) : loadingTime
        return {
            loadingTime,
            totalLoadingTime
        }
    },
    setPage(pageId) {
        this.pageId = pageId
    },
    switchPage(pageId) {
        this.setPage(pageId)
        this.wixBiSession.pn = ++this.pageNumber
        this.pageLoadStart = _.now()
        this.networkPageLoadStart = loggingUtils.performance.timeOrigin
    }
}

export default BiData
