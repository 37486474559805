import _ from 'lodash'

function appControllerDataResolver(data) {
    if (!_.has(data, ['settings'])) {
        return data
    }
    return _.assign({}, data, {settings: JSON.parse(data.settings)})
}

export default {
    resolve: appControllerDataResolver
}
