import _ from 'lodash'

const getTPAWorkerStructure = function (tpaWorkerId) {
    return {
        componentType: 'tpa.viewer.classes.TPAWorker',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        type: 'Component',
        id: tpaWorkerId
    }
}

/**
 *
 * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
 * @implements {core.SiteAspectInterface}
 * @constructor
 */
function TPAWorkerAspect(aspectSiteAPI) {
    this._aspectSiteAPI = aspectSiteAPI
}

TPAWorkerAspect.prototype = {
    getComponentsToRender() {
        const clientSpecMap = this._aspectSiteAPI.getSiteData().getClientSpecMap()
        const workerSpecs = this.getTPAWorkers(clientSpecMap)
        return _.map(workerSpecs, function (workerSpec) {
            const workerId = `tpaWorker_${workerSpec.applicationId}`

            return {
                structure: getTPAWorkerStructure(workerId),
                props: {
                    compData: workerSpec
                }
            }
        })
    },
    getTPAWorkers(specMap) {
        const workerSpecs = _.filter(specMap, function (spec) {
            return _.isString(spec.appWorkerUrl) && spec.permissions && !spec.permissions.revoked
        })

        return workerSpecs
    }
}

export default TPAWorkerAspect
