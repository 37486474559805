define(['lodash'], function (_) {
    'use strict'
    const QUICK_ACTION_ITEM_MAX_LENGTH = 40

    const quickActionIconsData = {
        address: {
            hash: '51ecb8e1148b45ba827df5c5134801af',
            color: '#00B594'
        },
        album: {
            hash: 'd709a69b4a714cebb65fc287d0630e75',
            color: '#733CA6'
        },
        chat: {
            hash: '55ef598f51c14f36ba4f0690cf28626f',
            color: '#2D41A9'
        },
        contactform: {
            hash: 'f0ca657289864c5b9edef58b618e6e5f',
            color: '#4D599B'
        },
        email: {
            hash: 'e4dde6644597420b93af3ca1ccfa9683',
            color: '#BE1867'
        },
        events: {
            hash: '4285df5e7be04a75814382611597fba0',
            color: '#EC125A'
        },
        facebook: {
            hash: 'a855d9a5d94e444a9ef9f8f8f9192a5a',
            color: '#3C5A99'
        },
        flickr: {
            hash: 'e27433a598ef44c1ba46e7f00ac0a7a1',
            color: '#000000'
        },
        google_plus: {
            hash: '0a6ab2eef3f14015ada0a0ab109afbcc',
            color: '#3B3B3B'
        },
        google_my_business: {
            hash: 'a3c153_da2161e51cf4410fbf6881022886fa2b',
            color: '#3B3B3B'
        },
        instegram: {
            hash: 'b6218b8d6b2549ad8a560dc75771a395',
            color: '#000000'
        },
        linkedin: {
            hash: '149a816584cd44b997f3023a42716113',
            color: '#1670BB'
        },
        phone: {
            hash: '4387e0ae8fd6412e9a988f77faa4f379',
            color: '#63C956'
        },
        whatsapp: {
            hash: 'a3c153_f546f836e5ca40dcb6a1bc44a1f3bdd7',
            color: '#21D366'
        },
        pinterest: {
            hash: '01c31f7f2d44432498ca45e05a3cf675',
            color: '#E33F44'
        },
        tiktok: {
            hash: 'a3c153_47551b91f8844fe399175491bdeededa',
            color: '#000000'
        },
        progallery: {
            hash: 'fd308926fe3e4a769b1f7ffbc228b502',
            color: '#733CA6'
        },
        sale_vert: {
            hash: 'f699000a2fef45348e20e216dae95f90',
            color: '#FFA300'
        },
        subscribe: {
            hash: '27c43b9381384a21ae01d8efcc0fbc12',
            color: '#3899EC'
        },
        twitter: {
            hash: '96ecb8929029472c90c7e72980d0c77c',
            color: '#68C1ED'
        },
        vk: {
            hash: '931c3651bada4183b7aa2c9b80016dc1',
            color: '#4E7DA0'
        },
        vimeo: {
            hash: '14a099fdcd514554a289f702afb73a6b',
            color: '#1AB7EA'
        },
        yelp: {
            hash: '2e61070007304317aadc1b49816ea07c',
            color: '#CD0F23'
        },
        line: {
            hash: 'a3c153_16c2d22de67d4d2aacbd96274922a9f9',
            color: '#00B900'
        },
        youtube: {
            hash: 'f58d5df33a944ddba14878977dd6dab0',
            color: '#E14D3B'
        },
        dribbble: {
            hash: 'c9d243a60f654aba9438199a22d986b1',
            color: '#F1538F'
        },
        signup_cont: {
            hash: '84d93d95e9ec4a779e5463f51cf311b5',
            color: '#52D3D4'
        }
    }

    const actionTypesMap = [
        'phone',
        'email',
        'contactform',
        'chat',
        'address',
        'facebook',
        'whatsapp',
        'twitter',
        'instegram',
        'pinterest',
        'tiktok',
        'linkedin',
        'youtube',
        'google_plus',
        'google_my_business',
        'yelp',
        'line',
        'vimeo',
        'vk',
        'flickr',
        'subscribe',
        'album',
        'progallery',
        'custom'
    ]

    const socialLinkTexts = {
        facebook: 'Facebook',
        twitter: 'Twitter',
        pinterest: 'Pinterest',
        tiktok: 'TikTok',
        google_plus: 'Google+',
        google_my_business: 'Google My Business',
        tumblr: 'Tumblr',
        linkedin: 'Linkedin',
        youtube: 'Youtube',
        vimeo: 'Vimeo',
        flickr: 'Flickr',
        instegram: 'instagram'
    }

    const defaultSerializedQuickActionBar = {
        type: 'Container',
        componentType: 'wysiwyg.viewer.components.QuickActionBar',
        components: [],
        layout: {
            fixedPosition: true
        },
        style: {
            type: 'TopLevelStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            style: {
                properties: {
                    'alpha-controlButtonBG': '1',
                    'alpha-controlButtonFG': '1',
                    'alpha-controlButtonBorderColor': '1',
                    'alpha-actionsBorderColor': '1',
                    'boxShadowToggleOn-actionsSHD': 'false',
                    'boxShadowToggleOn-controlButtonSHD': 'false',
                    controlButtonBG: '#008afc',
                    controlButtonFG: '#ffffff',
                    controlButtonBorderSize: '0',
                    controlButtonBorderColor: '#ffffff',
                    actionsBorderSize: '0',
                    actionsBorderColor: '#ffffff',
                    colorScheme: 'brand inv',
                    controlButtonSHD: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                    actionsSHD: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                },
                propertiesSource: {
                    controlButtonBG: 'value',
                    controlButtonFG: 'value',
                    controlButtonBorderSize: 'value',
                    controlButtonBorderColor: 'value',
                    actionsBorderSize: 'value',
                    actionsBorderColor: 'value',
                    colorScheme: 'value',
                    controlButtonSHD: 'value',
                    actionsSHD: 'value'
                },
                groups: {}
            },
            componentClassName: 'wysiwyg.viewer.components.QuickActionBar',
            styleType: 'custom'
        }
    }
    const defaultSerializedMAB = _.defaultsDeep(
        {},
        {
            skin: 'wysiwyg.viewer.skins.quickActionBar.anchoredSkin',
            style: {
                skin: 'wysiwyg.viewer.skins.quickActionBar.anchoredSkin',
                style: _.set({}, 'properties', {
                    colorScheme: 'brand',
                    actionsSHD: '0px 0px 20px 0px rgba(0,0,0,0.1)',
                    'boxShadowToggleOn-actionsSHD': 'true'
                })
            }
        },
        defaultSerializedQuickActionBar
    )

    const defaultSerializedFAB = _.defaultsDeep(
        {},
        {
            skin: 'wysiwyg.viewer.skins.quickActionBar.floatingSkin',
            style: {
                skin: 'wysiwyg.viewer.skins.quickActionBar.floatingSkin',
                style: _.set({}, 'properties', {
                    colorScheme: 'brand inv',
                    controlButtonBG: '#008afc',
                    'alpha-controlButtonBG': '1',
                    controlButtonSHD: '0px 2px 8px 0px rgba(0,0,0,0.3)',
                    actionsSHD: '0px 2px 8px 0px rgba(0,0,0,0.3)',
                    'boxShadowToggleOn-actionsSHD': 'true',
                    'boxShadowToggleOn-controlButtonSHD': 'true'
                })
            }
        },
        defaultSerializedQuickActionBar
    )

    const defaultSerializedQuickActionBarItem = {
        type: 'Component',
        componentType: 'wysiwyg.viewer.components.QuickActionBarItem',
        layout: {},
        style: {
            type: 'TopLevelStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            style: {
                properties: {
                    color: '#000000'
                },
                propertiesSource: {
                    color: 'value'
                },
                groups: {}
            },
            componentClassName: 'wysiwyg.viewer.components.QuickActionBarItem',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.quickActionBarItem'
        }
    }

    const defaultQuickActionItems = [
        {
            itemType: 'phone',
            link: {
                type: 'PhoneLink',
                phoneNumber: ''
            },
            text: 'Phone',
            icon: quickActionIconsData.phone.hash
        },
        {
            itemType: 'email',
            link: {
                type: 'EmailLink',
                recipient: '',
                subject: ''
            },
            text: 'Email',
            icon: quickActionIconsData.email.hash
        },
        {
            itemType: 'facebook',
            link: {
                type: 'ExternalLink',
                url: '',
                target: '_blank'
            },
            text: 'Facebook',
            icon: quickActionIconsData.facebook.hash
        }
    ]

    return {
        quickActionIconsData,
        actionTypesMap,
        socialLinkTexts,
        defaultSerializedMAB,
        defaultSerializedFAB,
        defaultSerializedQuickActionBarItem,
        defaultQuickActionItems,
        QUICK_ACTION_ITEM_MAX_LENGTH
    }
})
