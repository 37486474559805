import type {LoggerDriver} from '@wix/document-manager-core'

const maxOffline = 1000 * 60 * 60 * 24

const mockWindow = {
    addEventListener: () => {}
}

const win = (typeof window !== 'undefined' ? window : mockWindow) as Window

export type OnlineHandler = (stale: boolean, timeOffline: number) => void

export type RegisterHandlers = (log: LoggerDriver, cb: OnlineHandler) => void

export const registerOnlineHandler = (log: LoggerDriver, cb: OnlineHandler) => {
    let lastOnline: number = Date.now()

    win.addEventListener('offline', () => {
        log.info('offline')
    })
    win.addEventListener('online', () => {
        const delta = Date.now() - lastOnline
        if (delta > maxOffline) {
            log.info(`was offline for too long ${delta}`)
            cb(true, delta)
        } else {
            log.info('was offline for less then threshold')
            cb(false, delta)
        }
        lastOnline = Date.now()
    })
}
