import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const features = viewerLibrary.get('documentServices/features/features')
    return {
        methods: {
            components: {
                features: {
                    get: publicMethodUtils.defineGetter(features.getFeatureData),
                    update: publicMethodUtils.defineDataManipulationAction(features.updateFeatureData),
                    remove: publicMethodUtils.defineDataManipulationAction(features.removeComponentFeatureData)
                }
            }
        }
    }
}
