/**
 * Created by alexandreroitman on 07/11/2016.
 */

define(['lodash'], function (_) {
    'use strict'

    const WARNING = 'warning'
    const ERROR = 'error'

    function getDeprecatedComponents() {
        return {
            'wysiwyg.viewer.components.StripContainer': {
                deprecated: WARNING,
                message: 'This component will be deprecated'
            },
            'wysiwyg.viewer.components.ClipArt': {
                deprecated: WARNING,
                message: 'This component will be deprecated'
            }
        }
    }

    return {
        isComponentDeprecated(compType) {
            return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === ERROR
        },

        shouldWarnForDeprecation(compType) {
            return _.get(getDeprecatedComponents(), [compType, 'deprecated']) === WARNING
        },

        getDeprecationMessage(compType) {
            return `${compType}|${_.get(getDeprecatedComponents(), [compType, 'message'])}`
        }
    }
})
