define([
    'lodash',
    'documentServices/constants/constants',
    'documentServices/dataModel/dataModel',
    'documentServices/menu/menuUtils',
    'documentServices/page/popupUtils'
], function (_, constants, dataModel, menuUtils, popupUtils) {
    'use strict'

    function setItemData(ps, itemId, data) {
        const itemPointer = menuUtils.getMenuDataItemPointer(ps, itemId)
        const currentData = ps.dal.get(itemPointer)

        if (!currentData) {
            throw new Error(`Cannot update item with "${itemId}", it does not exist`)
        }

        dataModel.setDataItemByPointer(ps, itemPointer, _.assign(currentData, data), 'data')
    }

    /**
     * Set menu item's visibility
     *
     * @param {ps} ps
     * @param {string} itemId item id to change
     * @param {boolean} hideItem should this menu item be hidden
     * @param {boolean} hideItemMobile should this menu item be hidden on mobile devices
     */
    function setItemVisibility(ps, itemId, hideItem, hideItemMobile) {
        setItemData(ps, itemId, {isVisible: !hideItem, isVisibleMobile: !hideItemMobile})
    }

    /**
     * Set menu item's label
     *
     * @param {ps} ps
     * @param {string} itemId item id to change
     * @param {string} label the label to set
     */
    function setItemLabel(ps, itemId, label) {
        setItemData(ps, itemId, {label})
    }

    /**
     * Determine if a menu item can be explicitly removed
     *
     * @param {ps} ps
     * @param {string} itemId
     * @returns {boolean} true if link item or header item, false if page item
     */
    function isItemRemovable(ps, itemId) {
        return getItemType(ps, itemId) !== constants.MENU_ITEM_TYPES.PAGE
    }

    function isPageLink(ps, linkData) {
        const pageId = linkData.pageId && menuUtils.sanitizeHash(linkData.pageId)

        return linkData.type === 'PageLink' && !popupUtils.isPopup(ps, pageId)
    }
    function updateNonPageLinkItem(ps, itemId, linkData) {
        if (isPageLink(ps, linkData)) {
            throw new Error('Explicitly setting a LinkItem of type "PageLink" is not allowed')
        }

        const linkId = menuUtils.getLinkIdByMenuItemId(ps, itemId)
        if (!linkId) {
            throw new Error(`Cannot update item with "${itemId}", link does not exist`)
        }
        const linkItemPointer = menuUtils.getMenuDataItemPointer(ps, linkId)

        dataModel.setDataItemByPointer(ps, linkItemPointer, _.assign(linkData, {id: menuUtils.sanitizeHash(linkId)}), 'data')
    }

    function getItemType(ps, itemId) {
        const linkedItemId = menuUtils.getLinkIdByMenuItemId(ps, itemId)

        if (!linkedItemId) {
            return constants.MENU_ITEM_TYPES.HEADER
        }

        const linkedItemPointer = menuUtils.getMenuDataItemPointer(ps, linkedItemId)
        const linkedItemType = ps.dal.get(ps.pointers.getInnerPointer(linkedItemPointer, 'type'))

        if (linkedItemType === 'PageLink') {
            return constants.MENU_ITEM_TYPES.PAGE
        }

        return constants.MENU_ITEM_TYPES.LINK
    }

    return {
        /**
         * Set menu item's visibility
         *
         * @param {string} itemId item id to change
         * @param {boolean} hideItem should this menu item be hidden
         * @param {boolean} hideItemMobile should this menu item be hidden on mobile devices
         */
        setItemVisibility,
        /**
         * Determine if a menu item can be explicitly removed
         *
         * @param {string} itemId
         * @returns {boolean} true if link item or header item, false if page item
         */
        isItemRemovable,
        /**
         * Set menu item's label
         *
         * @param {string} itemId item id to change
         * @param {string} label the label to set
         */
        setItemLabel,
        /**
         * Returns the item type
         *
         * @param {string} itemId
         * @returns {string} one of [header, link, page]
         */
        getItemType,
        /**
         * Updates link of existing link menu item
         */
        updateLinkItem: updateNonPageLinkItem,
        isPageLink
    }
})
