define(['lodash', 'documentServices/constants/constants', 'documentServices/utils/utils'], function (_, constants, dsUtils) {
    const {
        RELATION_DATA_TYPES: {VARIANTS, BREAKPOINTS},
        REF_ARRAY_DATA_TYPE
    } = constants

    const refArray = {
        /**
         * Returns values property of provided object
         *
         * @param {Object} obj
         * @returns {Array}
         */
        extractValues: obj => _.get(obj, 'values', []),
        /**
         * Returns values key of provided object stripping hashes(#)
         * @param {Object} obj
         * @returns {string[]}
         */
        extractValuesWithoutHash: obj => _.map(refArray.extractValues(obj), dsUtils.stripHashIfExists),
        /**
         * Returns reference array object
         * @param {Array} values
         * @returns {{values: Array, type: string}}
         */
        create: (values = []) => ({
            type: REF_ARRAY_DATA_TYPE,
            values: _.map(values, v => (_.isString(v) ? `#${dsUtils.stripHashIfExists(v)}` : v))
        }),
        /**
         * returns new object, based on obj with provided values property
         *
         * @param {Object} obj
         * @param {Array} values
         * @returns {Object}
         */
        update: (obj, values) => ({
            ...obj,
            values: _.map(values, v => (_.isString(v) ? `#${dsUtils.stripHashIfExists(v)}` : v))
        }),
        /**
         * Check if object has reference type
         *
         * @param {Object} obj
         * @returns {boolean}
         */
        isRefArray: obj => obj && obj.type === REF_ARRAY_DATA_TYPE
    }

    const breakpointRelation = {
        extractBreakpoint: obj => obj && obj.breakpoint && dsUtils.stripHashIfExists(obj.breakpoint),
        extractRef: obj => obj && obj.ref,
        extractRefWithoutHash: obj => obj && obj.ref && dsUtils.stripHashIfExists(obj.ref),
        create: (breakpoint, ref) => ({
            type: BREAKPOINTS,
            breakpoint: `#${dsUtils.stripHashIfExists(breakpoint)}`,
            ref
        }),
        isBreakpointRelation: obj => obj && obj.type === BREAKPOINTS
    }
    const variantRelation = {
        create: (variants, from, to) => ({
            type: VARIANTS,
            variants: _.map(variants, v => (_.isString(v) ? `#${dsUtils.stripHashIfExists(v)}` : v)),
            from: `#${from}`,
            to: _.isString(to) ? `#${to}` : to
        }),
        extractVariants: obj => (obj && obj.variants ? _.map(obj.variants, dsUtils.stripHashIfExists) : false),
        extractTo: obj => obj && obj.to && dsUtils.stripHashIfExists(obj.to),
        extractFrom: obj => obj && obj.from && dsUtils.stripHashIfExists(obj.from),
        isVariantRelation: obj => obj && obj.type === VARIANTS
    }

    return {
        refArray,
        breakpointRelation,
        variantRelation
    }
})
