define([
    'lodash',
    '@wix/santa-ds-libs/src/utils',
    'documentServices/constants/constants',
    'documentServices/utils/utils',
    'documentServices/dataModel/common',
    'documentServices/dataModel/dataIds',
    'documentServices/dataModel/draftData',
    'documentServices/utils/multilingual',
    'documentServices/hooks/hooks',
    'document-services-schemas',
    'documentServices/extensionsAPI/extensionsAPI'
], function (_, utils, constants, dsUtils, common, dataIds, draft, mlUtils, hooks, documentServicesSchemas, extensionsAPI) {
    'use strict'

    const {dataValidators, schemasService} = documentServicesSchemas.services

    const {DATA_TYPES} = utils.constants

    const IS_PRESET_PATH = ['metaData', 'isPreset']

    /**
     * @param  {ps} ps
     * @param  {string} pageId
     * @param  {object} dataItem
     * @param  {string} customId
     * @param  {string} useLanguage
     */
    function addSerializedDataItemToPage(ps, pageId, dataItem, customId, useLanguage) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.data, undefined, useLanguage)
    }

    function addSerializedPropertyItemToPage(ps, pageId, dataItem, customId, componentPointer) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.prop, componentPointer)
    }

    function addSerializedStyleItemToPage(ps, pageId = 'masterPage', dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.theme)
    }

    function addSerializedDesignItemToPage(ps, pageId, dataItem, customId, componentPointer) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.design, componentPointer)
    }

    function addSerializedBehaviorsItemToPage(ps, pageId, dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.behaviors)
    }

    function addSerializedFeatureItemToPage(ps, pageId, dataItem, featureName, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, featureName)
    }

    function addSerializedAnchorDataItemToPage(ps, pageId, dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.anchors)
    }

    function addSerializedBreakpointItemToPage(ps, pageId, dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.breakpoints)
    }

    function addSerializedVariantItemToPage(ps, pageId, dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.variants)
    }

    function addSerializedConnectionsItemToPage(ps, pageId, dataItem, customId) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.connections)
    }

    function addSerializedMobileHintsItemToPage(ps, pageId, dataItem, customId, componentPointer) {
        return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.mobileHints, componentPointer)
    }

    function addDeserializedStyleItemToPage(ps, pageId, dataItem, customId) {
        return addDeserializedItemToPage(ps, pageId, DATA_TYPES.theme, dataItem, customId)
    }

    function deserializeDataItemAndAddToDAL(ps, pageId, serializedDataItem, dataItemId, itemType, optionalCompPointer, useLanguage) {
        const deserializedItems = []
        const serializedDataItemAfterPlugin = hooks.executeHookAndUpdateValue(
            ps,
            hooks.HOOKS.DESERIALIZE.BEFORE,
            undefined,
            [itemType, dataItemId],
            serializedDataItem
        )
        const deserializedItemId = deserializeDataItemOriginal(
            ps,
            pageId,
            serializedDataItemAfterPlugin,
            dataItemId,
            itemType,
            optionalCompPointer,
            deserializedItems,
            useLanguage
        )
        const rootToLeavesOrderedDeserializedDTOs = deserializedItems.reverse()

        setDeserializedItemsToDAL(ps, rootToLeavesOrderedDeserializedDTOs)
        return deserializedItemId
    }

    /**
     * @param {ps} ps
     * @param deserializedItemDTOs
     */
    function setDeserializedItemsToDAL(ps, deserializedItemDTOs) {
        let compPointer = null
        _.forEach(deserializedItemDTOs, deserializedItemDTO => {
            const {pointer, item} = deserializedItemDTO
            if (!compPointer && pointer.component) {
                compPointer = pointer.component
            }
            pointer.component = compPointer
            ps.dal.set(pointer, item)
        })
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     * @param serializedDataItem
     * @param {string} dataItemId
     * @param {string} itemType
     * @param optionalCompPointer
     * @param deserializedItems
     * @param {string} [useLanguage] languageCode
     * @returns {string}
     */
    function deserializeDataItemOriginal(ps, pageId, serializedDataItem, dataItemId, itemType, optionalCompPointer, deserializedItems, useLanguage) {
        const deserializedDataItem = deserializeDataItemInner(
            ps,
            pageId,
            serializedDataItem,
            dataItemId,
            itemType,
            deserializeDataItemOriginal,
            deserializedItems,
            useLanguage
        )
        if (_.has(deserializedDataItem, IS_PRESET_PATH)) {
            _.set(deserializedDataItem, IS_PRESET_PATH, false)
        }
        const itemPointer = ps.pointers.data.getItemWithOptional(itemType, deserializedDataItem.id, pageId, optionalCompPointer)
        itemPointer.useLanguage = useLanguage || ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
        dataValidators.validateDataBySchema(deserializedDataItem, itemType)
        deserializedItems.push({pointer: itemPointer, item: deserializedDataItem})
        return deserializedDataItem.id
    }

    /**
     * @param {ps} ps
     * @param {any} pageId
     * @param serializedDataItem
     * @param {string} dataItemId
     * @param {string} itemType
     * @param [handleRefItem]
     * @param [deserializedItems]
     * @param {string} [useLanguage=undefined] language code
     * @return {{id: *}}
     */
    function deserializeDataItemInner(ps, pageId, serializedDataItem, dataItemId, itemType, handleRefItem, deserializedItems, useLanguage) {
        const itemId = dataItemId || dataIds.generateNewId(itemType)
        let dataItemInDAL = {}
        let deserializedDataItem = {id: itemId}
        const updatedItemPointer = ps.pointers.data.getItem(itemType, itemId, pageId)
        if (ps.dal.isExist(updatedItemPointer)) {
            dataItemInDAL = ps.dal.full.get(updatedItemPointer)
        }

        let translationInDAL = {}
        const translationPointer = {...updatedItemPointer, useLanguage}
        if (ps.dal.isExist(translationPointer)) {
            translationInDAL = ps.dal.full.get(translationPointer)
        }
        serializedDataItem.type = serializedDataItem.type || _.get(dataItemInDAL, 'type')
        const schemaName = serializedDataItem.type
        const hasSchema = schemasService.hasSchemaForDataType(itemType, schemaName)
        if (!hasSchema) {
            throw new Error(`missing schema (schemaType: ${itemType} schemaName: ${schemaName}) for the given data item`)
        }

        function deserializeProp(key, shouldReuseIds, value) {
            return `#${handleRefItem(ps, pageId, value, shouldReuseIds ? value.id : undefined, itemType, null, deserializedItems, useLanguage)}`
        }

        const shouldReuse = (refs, id) => refs.has(id) || !ps.dal.full.isExist(ps.pointers.data.getItem(itemType, id, pageId))

        if (handleRefItem) {
            _.forOwn(serializedDataItem, function (value, key) {
                if (_.isPlainObject(value) && common.isOfType(itemType, schemaName, key, 'ref')) {
                    const refs = new Set([dsUtils.stripHashIfExists(dataItemInDAL[key]), dsUtils.stripHashIfExists(translationInDAL[key])])
                    deserializedDataItem[key] = deserializeProp(key, shouldReuse(refs, value.id), value)
                } else if (common.isOfType(itemType, schemaName, key, 'refList')) {
                    const refs = new Set(
                        _.flatMap([dataItemInDAL[key], translationInDAL[key]], refList => _.map(refList, ref => dsUtils.stripHashIfExists(ref)))
                    )
                    deserializedDataItem[key] = _.map(value, v => deserializeProp(key, shouldReuse(refs, v.id), v))
                } else if (key !== 'id') {
                    deserializedDataItem[key] = value
                }
            })
        } else {
            deserializedDataItem = _.defaults(deserializedDataItem, serializedDataItem)
        }

        if (shouldMergeDataItems(dataItemInDAL, deserializedDataItem)) {
            deserializedDataItem = _.assign(dataItemInDAL, deserializedDataItem)
        }

        common.addDefaultMetaData(deserializedDataItem, itemType)
        const updatedItem = _.omitBy(deserializedDataItem, _.isNil)
        draft.addDraftAnnotations(itemType, updatedItem)
        return updatedItem
    }

    /**
     * @param {ps} ps
     * @param {string} pageId
     * @param {string} itemType
     * @param dataItem
     * @param {string} customId
     * @returns {string}
     */
    function addDeserializedItemToPage(ps, pageId, itemType, dataItem, customId) {
        const deserializedDataItem = deserializeDataItemInner(ps, pageId, dataItem, customId || dataItem.id, itemType)
        if (_.has(deserializedDataItem, IS_PRESET_PATH)) {
            _.set(deserializedDataItem, IS_PRESET_PATH, false)
        }
        const itemPointer = ps.pointers.data.getItemWithOptional(itemType, deserializedDataItem.id, pageId)
        dataValidators.validateDataBySchema(deserializedDataItem, itemType)
        setDeserializedItemsToDAL(ps, [
            {
                pointer: itemPointer,
                item: deserializedDataItem
            }
        ])
        return deserializedDataItem.id
    }

    const cleanSingleLayoutData = (ps, singleLayoutData) => {
        const {SINGLE_LAYOUT_KEYS} = constants.LAYOUT_TYPES
        const cleanLayoutData = layout => (_.isEmpty(layout) ? layout : cleanLayoutAdditionalProps(ps, layout))
        return dsUtils.mapSpecificValues(SINGLE_LAYOUT_KEYS, singleLayoutData, cleanLayoutData)
    }

    const cleanLayoutAdditionalProps = (ps, originalLayoutItem) => {
        const data = _.cloneDeep(originalLayoutItem)
        if (originalLayoutItem.type === constants.LAYOUT_TYPES.SINGLE_LAYOUT) {
            return cleanSingleLayoutData(ps, data)
        }
        extensionsAPI.schema.removeAdditionalProperties(ps, DATA_TYPES.layout, data)

        return _.omit(data, 'metaData')
    }

    function shouldMergeDataItems(existingDataItem, newDataItem) {
        return !existingDataItem.type || !newDataItem.type || _.isEqual(existingDataItem.type, newDataItem.type)
    }

    function deserializeDataItem(ps, serializedDataItem, itemType) {
        const handleRef = (_ps, _pageId, value) => `#${value.id || dataIds.generateNewId(itemType)}`
        return deserializeDataItemInner(ps, null, serializedDataItem, serializedDataItem.id, itemType, handleRef)
    }

    /**
     *
     * @param {ps} ps
     * @param {string} dataType
     * @param {Pointer} dataItemPointer
     * @param {boolean} deleteId
     * @param {boolean} [useOriginalLanguage=false]
     * @returns {any}
     */
    function serializeDataItem(ps, dataType, dataItemPointer, deleteId, useOriginalLanguage = false) {
        const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
        return serializeDataItemInLang(ps, dataType, dataItemPointer, deleteId, useLanguage)
    }
    /**
     *
     * @param {ps} ps
     * @param {string} dataType
     * @param {Pointer} dataItemPointer
     * @param {boolean} deleteId
     * @param {string|undefined} [useLanguage]
     * @returns {any}
     */
    function serializeDataItemInLang(ps, dataType, dataItemPointer, deleteId, useLanguage) {
        const serializedItem = extensionsAPI.data.getItemWithMultilingualOverridesInLang(ps, dataItemPointer, useLanguage)
        if (!serializedItem) {
            return undefined
        }

        const pageId = ps.pointers.data.getPageIdOfData(dataItemPointer)

        function serializeRef(ref) {
            const pointer = getRefPointer(ps, ref, pageId, dataType)
            return serializeDataItemInLang(ps, dataType, pointer, deleteId, useLanguage)
        }

        function serializeRefList(refList) {
            return _.compact(_.map(refList, ref => serializeRef(ref)))
        }

        if (deleteId) {
            delete serializedItem.id
        }

        const schemaName = serializedItem.type

        _.forOwn(serializedItem, function (value, key) {
            const isRef = common.isOfType(dataType, schemaName, key, 'ref')
            if (value && isRef) {
                serializedItem[key] = serializeRef(value /*, dataItemPointer.type*/)
            } else {
                const isRefList = common.isOfType(dataType, schemaName, key, 'refList')
                if (value && isRefList) {
                    serializedItem[key] = serializeRefList(value)
                }
            }
        })

        draft.removeDraftAnnotations(serializedItem)
        return serializedItem
    }

    const getIdFromValue = value => _.isString(value) && utils.stringUtils.startsWith(value, '#') && dsUtils.stripHashIfExists(value)

    function getRefPointer(ps, value, pageId, dataType) {
        const itemId = _.isObject(value) ? value.id : getIdFromValue(value)
        return ps.pointers.data.getItem(dataType, itemId, pageId)
    }

    const addSerializedItemToPage = (ps, pageId, dataItem, customId, dataType) => deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, dataType)

    return {
        addSerializedItemToPage,
        addSerializedStyleItemToPage,
        addSerializedDataItemToPage,
        addSerializedPropertyItemToPage,
        addSerializedDesignItemToPage,
        addSerializedBehaviorsItemToPage,
        addSerializedFeatureItemToPage,
        addSerializedAnchorDataItemToPage,
        addSerializedBreakpointItemToPage,
        addSerializedVariantItemToPage,
        addSerializedConnectionsItemToPage,
        addSerializedMobileHintsItemToPage,

        addDeserializedItemToPage,
        addDeserializedStyleItemToPage,

        cleanLayoutAdditionalProps,

        deserializeDataItem,
        serializeDataItem,
        serializeDataItemInLang
    }
})
