define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/utils/utils',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/documentMode/documentModeInfo',
    'documentServices/component/component',
    'documentServices/dataModel/dataModel',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/refComponent/openCloseRefUtils',
    'documentServices/componentDetectorAPI/resolveAdditionalComponentsRegistrar'
], function (
    _,
    santaCoreUtils,
    dsUtils,
    componentDetectorAPI,
    documentModeInfo,
    component,
    dataModel,
    refComponentUtils,
    openCloseRefUtils,
    resolveAdditionalComponentsRegistrar
) {
    'use strict'

    const {getRefHostCompId, getRootRefHostCompId, getUniqueRefId, getReferredCompId, isRefPointer} = santaCoreUtils.displayedOnlyStructureUtil
    const REF_COMPONENT_TYPE = 'wysiwyg.viewer.components.RefComponent'
    const REF_TYPES = {
        INTERNAL: 'InternalRef',
        WIDGET: 'WidgetRef'
    }

    /**
     * @param {ps} ps
     * @param {Pointer} refHostPtr
     * @param {Pointer} masterCompPtr
     * @returns {Pointer}
     */
    const getUniqueRefCompPointer = (ps, refHostPtr, masterCompPtr) => {
        const viewMode = ps.pointers.components.getViewMode(refHostPtr)
        const uniqueRefCompId = getUniqueRefId(refHostPtr.id, masterCompPtr.id)

        return ps.pointers.components.getUnattached(uniqueRefCompId, viewMode)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} referredCompPtr
     * @returns {Pointer|undefined}
     */
    const getRefHostCompPointer = (ps, referredCompPtr) => {
        const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
        const refHostId = getRefHostCompId(referredCompPtr.id)

        return refHostId ? ps.pointers.components.getUnattached(refHostId, viewMode) : undefined
    }

    /**
     * @param {ps} ps
     * @param {Pointer} referredCompPtr
     * @returns {Pointer|undefined}
     */
    const getRootRefHostCompPointer = (ps, referredCompPtr) => {
        const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
        const rootRefHostId = getRootRefHostCompId(referredCompPtr.id)

        return rootRefHostId ? ps.pointers.components.getUnattached(rootRefHostId, viewMode) : undefined
    }

    /**
     * @param {ps} ps
     * @param {Pointer} referredCompPtr
     * @returns {Pointer|undefined}
     */
    const getTemplateCompPointer = (ps, referredCompPtr) => {
        const viewMode = ps.pointers.components.getViewMode(referredCompPtr)
        const templateCompId = getReferredCompId(referredCompPtr.id)

        return templateCompId ? ps.pointers.components.getUnattached(templateCompId, viewMode) : undefined
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPtr
     * @returns {*}
     */
    const isReferredComponent = (ps, compPtr) => isRefPointer(compPtr)

    /**
     *
     * @param ps
     * @param compPtr
     * @returns {string} dsUtils.NO|dsUtils.YES
     */
    const shouldUpdateAnchors = (ps, compPtr) => (isReferredComponent(ps, compPtr) ? dsUtils.NO : dsUtils.YES)

    /**
     * @param {ps} ps
     * @param {Pointer} compPtr
     * @returns {Object}
     * return a map of the referred component to the original component structure
     */
    const getGhostRefComponents = (ps, compPtr) => ps.pointers.referredStructure.getGhostRefComponents(compPtr.id)

    const getComponentConnections = (ps, compDefinition, compId) => {
        if (compDefinition.componentType === REF_COMPONENT_TYPE) {
            const [overriddenConnectionsItem] = ps.pointers.referredStructure.getDisplayedConnectionOverrides(compDefinition.id)
            return overriddenConnectionsItem.items
        }

        const compPointer = componentDetectorAPI.getComponentById(ps, compId)
        const pagePointers = ps.pointers.components.getPageOfComponent(compPointer)
        const connectionQuery = dsUtils.stripHashIfExists(compDefinition.connectionQuery)

        return dataModel.getConnectionItemsByQuery(ps, connectionQuery, pagePointers)
    }

    /**
     * @param {ps} ps
     * @param {Pointer} refHostPointer
     * @returns {Object}
     * return a map of the referred component to the original component primery connection
     */
    const getAllGhostRefComponentsPrimaryConnection = (ps, refHostPointer) => {
        const ghostRefComps = getGhostRefComponents(ps, refHostPointer)
        return _.mapValues(ghostRefComps, (comp, key) => {
            const connections = getComponentConnections(ps, comp, key)

            return _.find(connections, connectionItem => connectionItem.isPrimary)
        })
    }

    const removeUnusedOverrides = (ps, refComponentPtr) => {
        const compType = component.getType(ps, refComponentPtr)
        if (compType === REF_COMPONENT_TYPE) {
            const allOverrides = ps.pointers.referredStructure.getAllOverrides(refComponentPtr)
            const refChildComps = componentDetectorAPI.getComponentsUnderAncestor(ps, refComponentPtr)
            const templateCompIds = _.map(refChildComps, 'id')
            const unusedOverrides = _.filter(allOverrides, override => {
                const templateId = refComponentUtils.extractBaseComponentId(override)
                return !templateCompIds.includes(templateId)
            })
            refComponentUtils.removeOverrides(ps, unusedOverrides)
        }
    }

    const init = () => {
        // register for resolving additional components for refComponent when getting
        // on methods that allow fetching additional components per comp type, resolve refComponent inner components
        resolveAdditionalComponentsRegistrar.registerAdditionalComponentsResolver(REF_COMPONENT_TYPE, (ps, compPtr) => {
            const rootChild = _.head(ps.pointers.components.getChildren(compPtr))
            return rootChild ? _.concat(ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(rootChild), compPtr) : []
        })
    }

    const getChildrenOfNotInflatedRefComponent = (ps, refComp) => {
        const {rootCompId} = dataModel.getDataItem(ps, refComp)
        const rootCompPtr = ps.pointers.components.getUnattached(rootCompId, documentModeInfo.getViewMode(ps))

        return _.concat(rootCompPtr, componentDetectorAPI.getComponentsUnderAncestor(ps, rootCompPtr))
    }

    function getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps, compRef) {
        if (component.getType(ps, compRef) === 'wysiwyg.viewer.components.RefComponent') {
            const directChildren = getChildrenOfNotInflatedRefComponent(ps, compRef)

            const allChildren = _.reduce(
                directChildren,
                (acc, child) => {
                    const childrenOfChildren = getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps, child)
                    return _.concat(acc, childrenOfChildren)
                },
                directChildren
            )

            return _.map(allChildren, child => getUniqueRefCompPointer(ps, compRef, child))
        }

        return compRef
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {boolean}
     */
    function isSharedBlock(ps, compPointer) {
        const data = dataModel.getDataItem(ps, compPointer)

        if (data && data.type === REF_TYPES.INTERNAL) {
            return !data.pageId || data.pageId === 'masterPage'
        }

        return false
    }

    /**
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @returns {boolean}
     */
    function isPartOfSharedBlock(ps, compPointer) {
        const refHost = getRefHostCompPointer(ps, compPointer)

        if (refHost && isSharedBlock(ps, refHost)) {
            return true
        }

        return false
    }

    const getReferredComponents = (ps, compRef, pageRef) => {
        const referredComponentsIds = ps.pointers.referredStructure.getInternallyReferredComponents(compRef)
        let referredComponentsRefs = _.map(referredComponentsIds, compId => componentDetectorAPI.getComponentById(ps, compId))
        if (pageRef) {
            referredComponentsRefs = _.filter(referredComponentsRefs, compPointer =>
                _.isEqual(ps.pointers.full.components.getPageOfComponent(compPointer), pageRef)
            )
        }
        return referredComponentsRefs
    }

    return {
        init,
        isPartOfSharedBlock,
        shouldUpdateAnchors,
        getUniqueRefCompPointer,
        getRefHostCompPointer,
        getRootRefHostCompPointer,
        getTemplateCompPointer,
        isReferredComponent,
        getGhostRefComponents,
        getComponentsUnderNotInflatedRefComponentWithInflatedIDs,
        hasOverridesToBeRemoved: refComponentUtils.hasOverridesToBeRemoved,
        removeAllOverrides: refComponentUtils.removeAllOverrides,
        getAllOverrides: refComponentUtils.getOverriddenData,
        removeUnusedOverrides,
        getComponentToCreateRef: refComponentUtils.getComponentToCreateRef,
        unGhostifyComponent: refComponentUtils.unGhostifyComponent,
        openReferredComponent: openCloseRefUtils.openReferredComponent,
        closeWidgetToReferredComponent: openCloseRefUtils.closeWidgetToReferredComponent,
        generateRefComponentStructure: openCloseRefUtils.generateRefComponentStructure,
        getAllGhostRefComponentsPrimaryConnection,
        getReferredComponents
    }
})
