define(['lodash', 'experiment', '@wix/santa-core-utils'], function (_, experiment, santaCoreUtils) {
    'use strict'

    function visualFocusIsDefault(ps, accessibilitySettingsPointer) {
        const vfFlag = _.get(ps.dal.get(accessibilitySettingsPointer), 'visualFocusDisabled')
        return _.isUndefined(vfFlag)
    }

    return {
        exec(ps) {
            const masterPageDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
            const accessibilitySettingsPointer = ps.pointers.getInnerPointer(masterPageDataPointer, 'accessibilitySettings')

            if (experiment.isOpen('sv_visualFocus') && visualFocusIsDefault(ps, accessibilitySettingsPointer)) {
                ps.dal.set(accessibilitySettingsPointer, {visualFocusDisabled: false})
            }
        },
        name: 'visualFocusFixer',
        version: 1
    }
})
