import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    const transformations = viewerLibrary.get('documentServices/variants/transformations')
    return {
        methods: {
            components: {
                transformations: {
                    update: publicMethodUtils.defineDataManipulationAction(transformations.updateTransformationsData, {
                        isUpdatingAnchors: dsUtils.NO
                    }),
                    get: publicMethodUtils.defineGetter(transformations.getTransformationsData),
                    remove: publicMethodUtils.defineDataManipulationAction(transformations.removeTransformationsData),
                    getAllHiddenComponents: publicMethodUtils.defineGetter(transformations.getAllHiddenComponentsByVariants),
                    getAllHiddenComponentsByDefault: publicMethodUtils.defineGetter(transformations.getAllHiddenComponentsByDefault),
                    setPropsToIgnore: publicMethodUtils.defineAction(transformations.setPropsToIgnore),
                    setGlobalPropsToIgnore: publicMethodUtils.defineAction(transformations.setGlobalPropsToIgnore)
                }
            }
        }
    }
}
