import * as _ from 'lodash'
import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const wixapps = viewerLibrary.get('documentServices/wixapps/wixapps')
    const {items, types, lists, listTemplates, appbuilder, classics, clientSpecMap, metadata, initializer, blogUtils} = wixapps
    const {deprecate} = publicMethodUtils
    // @ts-ignore
    const defineGetter = (...args: any[]) => deprecate(publicMethodUtils.defineGetter(...args), 'wixapps is deprecated')
    // @ts-ignore
    const defineAction = (...args: any[]) => deprecate(publicMethodUtils.defineAction(...args), 'wixapps is deprecated')
    // @ts-ignore
    const defineDataManipulationAction = (...args: any[]) => deprecate(publicMethodUtils.defineDataManipulationAction(...args), 'wixapps is deprecated')

    return {
        methods: {
            wixapps: {
                classics: {
                    invalidateComponentViewCache: defineAction(classics.invalidateComponentViewCache),
                    getAllAppPartComps: defineGetter(classics.getAllAppPartComps),
                    getPackageName: defineGetter(classics.getPackageName),
                    getLegacyAppPartContent: defineGetter(classics.getLegacyAppPartContent),
                    getDataStoreId: defineGetter(classics.getDataStoreId),
                    account: {
                        getApplicationId: defineGetter(classics.getApplicationId),
                        getBlogInstanceId: defineGetter(classics.getBlogInstanceId)
                    },
                    reloadApp: defineDataManipulationAction(classics.reloadApp),
                    registerHooks: defineDataManipulationAction(classics.registerHooks),
                    isBlogPage: defineGetter(blogUtils.isBlogPage),
                    getComponentTypeByProxyName: defineGetter(classics.getComponentTypeByProxyName),
                    styleMapping: {
                        styleToFontClass: defineGetter(classics.styleMapping.styleToFontClass),
                        fontClassToStyle: defineGetter(classics.styleMapping.fontClassToStyle)
                    },
                    getBlogPaginationCustomizationsByAppPartName: defineGetter(classics.getBlogPaginationCustomizationsByAppPartName),
                    blog: {
                        isSinglePost: defineGetter(classics.blog.isSinglePost),
                        isFeed: defineGetter(classics.blog.isFeed),
                        getBlogSinglePostPageData: defineGetter(classics.blog.getBlogSinglePostPageData),
                        deleteAllAppParts: defineAction(classics.blog.deleteAllAppParts)
                    },
                    getBlogNewSocialShareButtonsCustomizationForView: defineGetter(classics.getBlogNewSocialShareButtonsCustomizationForView)
                },
                appbuilder: {
                    metadata: {
                        wasPartLoadedSuccessfully: defineGetter(metadata.wasPartLoadedSuccessfully),
                        wasRepoLoadedSuccessfully: defineGetter(metadata.wasRepoLoadedSuccessfully)
                    },
                    reload: defineDataManipulationAction(appbuilder.reload),
                    save: defineAction(appbuilder.save),
                    account: {
                        getInstanceId: defineGetter(clientSpecMap.getInstanceId)
                    },
                    data: {
                        markDataAsUpdated: defineDataManipulationAction(lists.markDataAsUpdated),
                        items: {
                            //performance tweak
                            create: defineAction(items.createItem),
                            update: defineAction(items.updateItem),
                            delete: defineAction(items.deleteItem)
                        },
                        lists: {
                            selection: {
                                get: defineGetter(lists.getSelector),
                                setManual: defineAction(lists.setManualSelector)
                            },
                            getItemSchema: defineGetter(lists.getType),
                            getDatabaseId: defineGetter(lists.getTypeName),
                            publish: defineAction(_.noop),
                            getListName: defineGetter(lists.getDisplayName),
                            getListVersion: defineGetter(lists.getVersion),
                            rename: defineDataManipulationAction(lists.rename),
                            getItems: defineGetter(lists.getItems),
                            getHiddenItems: defineGetter(lists.getHiddenItems),
                            generateTemplate: defineGetter(listTemplates.generateTemplate),
                            createFromTemplate: defineAction(listTemplates.createListFromTemplate)
                        },
                        databases: {
                            getItems: defineGetter(items.getAllItemsOfType),
                            getSchemas: defineGetter(types.getAllTypes)
                        }
                    }
                },
                migrate: {
                    convertAppPage: defineAction(classics.convertAppPage)
                }
            }
        },
        initMethod: initializer.initialize
    }
}
