import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import _ from 'lodash'
import santaComponents from '@wix/santa-components'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import tpaMetaData from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/common/tpaMetaData'
import tpaErrors from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/bi/errors'
import clientSpecMapService from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/services/clientSpecMapService'
import tpaUtils from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/utils/tpaUtils'
import events from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/bi/events'
import tpaScrollMixin from '@wix/santa-ds-libs/src/_internal/tpaComponents/src/mixins/tpaScrollMixin'

const {santaTypesDefinitions} = santaComponents
const {Component: component} = santaTypesDefinitions
const {tpaWarmup} = warmupUtils

const DENY_IFRAME_RENDERING_STATES = {
    mobile: 'unavailableInMobile',
    https: 'unavailableInHttps'
}

const OVERLAY_STATES = {
    notInClientSpecMap: 'notInClientSpecMap',
    unresponsive: 'unresponsive',
    preloader: 'preloader'
}

const onKeyEvent = function (event, eventType) {
    const params = {charCode: event.charCode, keyCode: event.keyCode}
    this.sendPostMessage({
        intent: 'addEventListener',
        eventType,
        params
    })
}

const shouldHandleEvent = function (props) {
    return !props.rootId || _.includes(props.getRootIdsWhichShouldBeRendered(), props.rootId)
}

const isFullScreenAppOnMobile = function (props) {
    const clientSpecMapEntry = props.getClientSpecMapEntry(props.compData.applicationId)
    return _.includes(tpaMetaData.PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE, _.get(clientSpecMapEntry, 'appDefinitionId'))
}

/**
 * @class tpa.mixins.tpaCompBase
 */
export default {
    propTypes: {
        pageStub: component.pageStub,
        isMobileView: santaTypesDefinitions.isMobileView,
        formFactor: santaTypesDefinitions.NativeComponentSantaTypes.formFactor.isRequired,
        isViewerMode: santaTypesDefinitions.isViewerMode,
        currentUrl: santaTypesDefinitions.currentUrl.isRequired,
        compData: component.compData.isRequired,
        style: component.style.isRequired,
        id: component.id.isRequired,
        isClientAfterSSR: santaTypesDefinitions.isClientAfterSSR,
        isFirstRenderAfterSSR: santaTypesDefinitions.isFirstRenderAfterSSR,
        getMemberDetails: santaTypesDefinitions.SiteMembersSantaTypes.getMemberDetails.isRequired,
        windowResizeEvent: santaTypesDefinitions.SiteAspects.windowResizeEvent.isRequired,
        reportBI: santaTypesDefinitions.reportBI.isRequired,
        biData: santaTypesDefinitions.biData.isRequired,
        compProp: component.compProp,
        getCommonConfig: santaTypesDefinitions.CommonConfig.getAll,
        primaryPageId: santaTypesDefinitions.primaryPageId.isRequired,
        getRootIdsWhichShouldBeRendered: santaTypesDefinitions.getRootIdsWhichShouldBeRendered,
        reportBeatEvent: santaTypesDefinitions.reportBeatEvent.isRequired,
        santaBase: santaTypesDefinitions.santaBase.isRequired,
        getClientSpecMapEntry: santaTypesDefinitions.getClientSpecMapEntry.isRequired,
        dynamicClientSpecMapAspect: santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
        clientSpecMap: santaTypesDefinitions.RendererModel.clientSpecMap.isRequired,
        languageCode: santaTypesDefinitions.RendererModel.languageCode,
        pageId: component.pageId,
        browser: santaComponents.santaTypesDefinitions.Browser.browser,
        ios: santaComponents.santaTypesDefinitions.BrowserFlags.ios.isRequired,
        wixTopAdsHeight: santaComponents.santaTypesDefinitions.WixAds.wixTopAdsHeight,
        isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
        rootId: component.rootId,
        structure: component.structure,
        tpaComponentsDomAspect: santaTypesDefinitions.SiteAspects.tpaComponentsDomAspect.isRequired,
        isHorizontallyDocked: component.isHorizontallyDocked,
        isTpaRenderedInSsr: santaTypesDefinitions.isTpaRenderedInSsr,
        isExperimentOpen: santaTypesDefinitions.isExperimentOpen,
        getExistingRootNavigationInfo: santaTypesDefinitions.getExistingRootNavigationInfo.isRequired,
        currentUrlPageId: component.currentUrlPageId.isRequired,
        shouldRenderTPAsIframe: santaTypesDefinitions.RenderFlags.shouldRenderTPAsIframe.isRequired,
        loadingTranslation: santaTypesDefinitions.TPA.loadingTranslation
    },

    getDefaultProps() {
        return {
            shouldRenderTPAsIframe: true
        }
    },

    mixins: [componentsCore.mixins.createChildComponentMixin, tpaScrollMixin],

    ALIVE_TIMEOUT: 20000, // MUST be longer than INTERSECTION_TIMEOUT (19000) in iframesPatcher.js
    OVERLAY_GRACE: 5000,

    statics: {
        compSpecificIsDomOnlyOverride() {
            return false
        }
    },

    getCompMinHeight(props) {
        if (!this.isUnderMobileView()) {
            return
        }
        const clientSpecMapEntry = props.getClientSpecMapEntry(props.compData.applicationId)
        const widgetId = this.getWidgetId()
        const minHeightInMobile = _.get(clientSpecMapEntry, ['widgets', widgetId, 'componentFields', 'minHeightInMobile'])
        if (minHeightInMobile && _.isNumber(minHeightInMobile)) {
            return minHeightInMobile
        }
    },

    onKeyDown(event) {
        if (shouldHandleEvent(this.props)) {
            onKeyEvent.call(this, event, 'KEY_DOWN')
        }
    },

    onKeyUp(event) {
        if (shouldHandleEvent(this.props)) {
            onKeyEvent.call(this, event, 'KEY_UP')
        }
    },

    isUnderMobileView() {
        const isMobileOnResponsive = this.props.isExperimentOpen('se_full_screen_by_form_factor_on_editorx')
            ? this.props.formFactor === 'Mobile'
            : this.props.isMobileDevice
        return this.props.isResponsive ? isMobileOnResponsive : this.props.isMobileView
    },

    getEcomParams() {
        const appData = this.getAppData()
        return appData.appDefinitionId === '1380b703-ce81-ff05-f115-39571d94dfcd' && this.props.currentUrl.query['ecom-tpa-params']
    },

    _createOverlayChildComponent(type, overlay, skinExport, props) {
        props = props || {}
        return this.createChildComponent(
            _.merge(props, {
                id: skinExport,
                style: this.props.style,
                overlay,
                applicationId: this.props.compData.applicationId,
                hideOverlayFunc: this._hideOverlay,
                reloadFunc: this._reload,
                compData: this.props.compData,
                languageCode: this.props.languageCode,
                santaBase: this.props.santaBase,
                getClientSpecMapEntry: this.props.getClientSpecMapEntry
            }),
            type,
            skinExport,
            {}
        )
    },

    _hideOverlay() {
        this.setState({showOverlay: false})
    },

    _getUnresponsiveText() {
        if (this.isUnderMobileView()) {
            return "Can't see the content? "
        }
        return 'To view this content, click '
    },

    _getReloadext() {
        if (this.isUnderMobileView()) {
            return 'Tap to reload.'
        }
        return 'reload.'
    },

    _createOverlay(overlayState) {
        if (!overlayState) {
            return null
        }
        switch (overlayState) {
            case OVERLAY_STATES.preloader:
                return this._createOverlayChildComponent('wysiwyg.viewer.components.tpapps.TPAPreloaderOverlay', overlayState, 'preloaderOverlay')
            case OVERLAY_STATES.unresponsive:
                const props = {
                    text: this._getUnresponsiveText(),
                    reload: this._getReloadext()
                }
                return this._createOverlayChildComponent(
                    'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                    overlayState,
                    'unavailableMessageOverlay',
                    props
                )
            case DENY_IFRAME_RENDERING_STATES.https:
                return this._createOverlayChildComponent(
                    'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                    overlayState,
                    'unavailableMessageOverlay',
                    {text: "We're sorry, this content cannot be displayed."}
                )
            case DENY_IFRAME_RENDERING_STATES.mobile:
                return this._createOverlayChildComponent(
                    'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                    overlayState,
                    'unavailableMessageOverlay',
                    {text: "We're sorry, this content is currently not optimized for mobile view."}
                )
            case OVERLAY_STATES.notInClientSpecMap:
                return this._createOverlayChildComponent(
                    'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                    overlayState,
                    'unavailableMessageOverlay'
                )
            default:
                return null
        }
    },

    _getWidgetName() {
        const {compData} = this.props
        const widgetId = compData && compData.widgetId
        const {widgets, appDefinitionName} = this.getAppData()
        const widgetData = widgets && widgets[widgetId]
        return _.get(widgetData, 'appPage.name', appDefinitionName)
    },

    getCompHeight() {
        const {state} = this
        const {style} = this.props
        if (state.ignoreAnchors) {
            return style.height
        }
        const isZeroHeightAllowed = _.result(this, 'isZeroHeightAllowed', false)
        const height = !_.isUndefined(state.height) ? state.height : style.height
        if (height === 0 && !isZeroHeightAllowed) {
            return style.height
        }
        return height
    },

    getCompWidth() {
        const {width} = this.state
        return !_.isUndefined(width) ? width : this.props.style.width
    },

    getCommonStyle() {
        const minHeight = this.getCompMinHeight(this.props)
        const height = this.getCompHeight()
        const width = this.getCompWidth()
        return {
            width,
            height,
            minHeight: minHeight ? Math.max(minHeight, height) : height,
            minWidth: width
        }
    },

    componentDidUpdate() {
        if (this.isUnderMobileView() && this.state.isMobileFullScreenMode && this.props.isResponsive && (this.props.browser.safari || this.props.ios())) {
            // horrible safari hack - force relayout on the component (full screen chat)
            this.refs[''].style.left = '-1px'

            requestAnimationFrame(() => {
                this.refs[''].style.left = '0px'
            })
        }
    },

    getCompRootStyle(style) {
        const {state, props} = this
        if (this.isUnderMobileView()) {
            if (state.isMobileFullScreenMode) {
                let compRootStyle: any = {
                    display: 'block'
                }

                if (props.isResponsive) {
                    if (this.props.browser.safari || this.props.ios()) {
                        compRootStyle = {
                            display: 'block',
                            position: 'relative',
                            margin: '0',
                            top: -1 * this.props.wixTopAdsHeight,
                            height: `calc(100% + ${this.props.wixTopAdsHeight}px)`,
                            width: '100vw',
                            left: '0'
                        }
                    } else {
                        compRootStyle = {
                            display: 'block',
                            position: 'fixed',
                            zIndex: 2000, // TODO doesn't work cause pinned layer stuff
                            margin: '0',
                            top: '0', // TODO equivalent of props.shouldShowWixAds ? layout.spaceCalculationsRegardingWixAds.getFirstUnoccupiedTopCoordinate(measureMap): '0' in responsive
                            left: '0',
                            width: '100vw',
                            bottom: '0'
                        }
                    }
                }

                return compRootStyle
            }
            if (isFullScreenAppOnMobile(this.props)) {
                if (props.isResponsive) {
                    if (this.state.width && this.state.height) {
                        return {
                            position: 'relative',
                            justifySelf: 'end',
                            alignSelf: 'end',
                            width: this.state.width,
                            height: this.state.height
                        }
                    }
                    return {}
                }
                return {display: 'none'}
            }
        }

        if (
            state &&
            (state.isAlive ||
                state.overlay === OVERLAY_STATES.preloader ||
                state.overlay === OVERLAY_STATES.unresponsive ||
                state.overlay === DENY_IFRAME_RENDERING_STATES.mobile)
        ) {
            state.visibility = 'visible'
        }

        style = _.assign(
            {},
            style,
            {
                overflow: this.state.ignoreAnchors ? 'visible' : 'hidden',
                visibility: this.state.visibility === 'hidden' ? 'hidden' : ''
            },
            this.getCommonStyle()
        )

        if (this.props.isHorizontallyDocked) {
            style = _.omit(style, ['width', 'minWidth'])
        }
        //  Z-Index was added to support 2G (new) eCom cart widget on sticky headers, but we don't want to set z-index on components.( https://jira.wixpress.com/browse/TPA-2123 )
        //  SINCE MANY SITES WERE SAVED WITH THE WIDGET *ON TOP* OF THE HEADER (NOT INSIDE)
        //  IT MAY DISAPPEAR UNDER IT IF IT'S FIXED POSITIONED AND  WE MIGHT NEED TO BRING IT BACK AND FIND A BETTER SOLUTION
        //
        //if (this.props.rootId === 'masterPage' && !this.isUnderMobileView()) {
        //    style.zIndex = '100';
        //}

        return style
    },

    shouldRenderIframe() {
        if (this.props.shouldRenderTPAsIframe === false) {
            return false
        }
        const {overlay} = this.state
        const overlayDoesNotContaineDenyState = !_.includes(DENY_IFRAME_RENDERING_STATES, overlay)
        const appIsNotResponsive = overlay === OVERLAY_STATES.unresponsive
        const shouldRenderIframe = overlayDoesNotContaineDenyState && !appIsNotResponsive && this.isCompInClientSpecMap()

        return shouldRenderIframe
    },

    allowScrolling() {
        const {props} = this
        const appData = this.getAppData()
        const isFullPage = clientSpecMapService.isFullPage(props.compData, appData)
        if (isFullPage) {
            return 'yes'
        }
        const isFullPageInDesktop = clientSpecMapService.isFullPageDesktopOnly(props.compData, appData) && !props.isMobileView
        return isFullPageInDesktop ? 'yes' : 'no'
    },

    getIframeDimensions() {
        const {state} = this
        if (state.isMobileFullScreenMode) {
            return {
                width: '100%',
                height: '100%'
            }
        }
        const size: any = {}
        if (this.props.isHorizontallyDocked) {
            size.width = '100%'
        }
        if (state.height && (state.ignoreAnchors || this.props.isResponsive)) {
            size.height = state.height
        }
        return size
    },

    shouldRenderIframeWithSrc() {
        return this.props.isExperimentOpen('sv_renderIframeSrcInSsr')
    },

    _getIframeProperties() {
        if (!this.shouldRenderIframe()) {
            return null
        }

        const shouldShowIframe = this._shouldShowIframe(this.state)
        const iframeSrc = this.buildUrl(this.getBaseUrl())
        const widgetName = this._getWidgetName()

        const iframeProperties: any = {
            'data-src': iframeSrc,
            key: iframeSrc,
            scrolling: this.allowScrolling(),
            frameBorder: '0',
            allow: tpaUtils.getIFramePermissions(),
            allowtransparency: 'true',
            allowFullScreen: true,
            name: this.props.id,
            style: _.assign(this.getCommonStyle(), this.getIframeDimensions(), {
                display: shouldShowIframe ? 'block' : 'none',
                position: this.props.isResponsive ? 'relative' : 'absolute',
                zIndex: this.state.ignoreAnchors ? 1001 : ''
            }),
            title: widgetName,
            'aria-label': widgetName
        }

        if (this.shouldRenderIframeWithSrc()) {
            _.unset(iframeProperties, 'data-src')
            iframeProperties.src = iframeSrc
        }

        return iframeProperties
    },

    getSkinProperties() {
        const overlay = this._createOverlay(this.state.overlay)
        const compRootStyle = this.getCompRootStyle(this.props.style)
        const iframeProperties = this._getIframeProperties()

        let skinProps = {
            '': {
                style: compRootStyle,
                'data-ignore-anchors': this.state.ignoreAnchors,
                'data-has-iframe': !!iframeProperties,
                'data-src': iframeProperties ? iframeProperties['data-src'] : null,
                tagName: iframeProperties ? 'wix-iframe' : 'div'
            },
            overlay: this.state.showOverlay && this.isNotWorker() ? overlay : null,
            iframe: iframeProperties ? iframeProperties : 'remove'
        }

        if (iframeProperties) {
            // data attributes for BI events reported from wix-iframe custom element
            const appData = this.getAppData()
            skinProps['']['data-is-tpa'] = true
            skinProps['']['data-widget-id'] = this.getWidgetId()
            skinProps['']['data-app-definition-id'] = _.get(appData, 'appDefinitionId')
        }

        this.checkIfNeedToSendMemberData()

        if (this.mutateSkinProperties) {
            skinProps = this.mutateSkinProperties(skinProps)
        }

        return skinProps
    },

    _reload() {
        this._clearAliveTimeout()
        clearTimeout(this.showOverlyIntervalId)
        this.showOverlyIntervalId = setTimeout(() => {
            this._showOverlayIfNeeded()
        }, this.OVERLAY_GRACE)
        this._appAliveTimeout = setTimeout(this._onAppAliveTimeoutExpires, this.ALIVE_TIMEOUT)
        const {appDefinitionId, appDefinitionName: appName} = this.getAppData() || {}
        this.props.reportBI(events.RELOAD_TPA_CLICK, {
            appDefinitionId,
            appName
        })

        this.registerReLayout()
        this.setState(this.getInitialState())
    },

    checkIfNeedToSendMemberData() {
        const siteMemberDetailsState = this.state.shouldGetSiteMemberDetails
        if (siteMemberDetailsState) {
            const memberData = this.props.getMemberDetails()

            if (memberData) {
                siteMemberDetailsState.callback({
                    authResponse: true,
                    data: memberData
                })
            }
        }
    },

    getInitialState() {
        let biParams
        if (!this.isNotWorker()) {
            biParams = {widgetName: 'worker'}
        }

        const {props} = this
        if (!props.isFirstRenderAfterSSR && this.shouldRenderIframeWithSrc()) {
            this.reportPerformanceBiEvent(tpaWarmup.events.APP_IFRAME_START_LOADING, biParams)
        }

        this.urlState = ''

        const underMobileAndNotSupported = this.isUnderMobileView() && this.isMobileReady && !this.isMobileReady()
        const overlay = underMobileAndNotSupported ? DENY_IFRAME_RENDERING_STATES.mobile : null

        let initialState = {
            visibility: 'hidden',
            overlay,
            isAlive: false,
            registeredEvents: [],
            showOverlay: true,
            commonConfig: _.cloneDeep(props.getCommonConfig()),
            initialWidth: props.style.width,
            initialHeight: props.style.height,
            initialInstance: props.dynamicClientSpecMapAspect.getAppInstance(props.compData.applicationId) || this.getAppData(this).instance,
            initialRouterData: _.get(props.getExistingRootNavigationInfo(props.currentUrlPageId), 'routerPublicData'),
            externalId: props.compData.referenceId
        }

        this.shouldUpdateInitialRouterData = true

        if (this.mutateInitialState) {
            initialState = this.mutateInitialState(initialState)
        }
        return initialState
    },

    isCompInClientSpecMap() {
        const {clientSpecMap, compData} = this.props || {}
        if (!clientSpecMap) {
            return false
        }
        const {applicationId} = compData || {}
        if (!applicationId) {
            return false
        }
        const clientSpecMapEntry = clientSpecMap[applicationId]
        return !!clientSpecMapEntry
    },

    _showOverlayIfNeeded() {
        const isCompInClientSpecMap = this.isCompInClientSpecMap()
        const {isViewerMode} = this.props || {}
        const {state} = this
        if (state && state.isAlive === false && ((!state.overlay && isCompInClientSpecMap) || (!isCompInClientSpecMap && !isViewerMode))) {
            if (this._isMounted) {
                this.setState({
                    overlay: this._getInitialOverlay(),
                    visibility: 'visible'
                })
            }
        }
    },

    _shouldShowIframe(state) {
        const {props} = this
        if (props && props.compProp && props.compProp.isHidden) {
            return false
        }
        return !state.overlay || state.overlay === 'preloader'
    },

    _getInitialOverlay() {
        if (this.isMobileReady && this.isUnderMobileView() && !this.isMobileReady()) {
            return DENY_IFRAME_RENDERING_STATES.mobile
        }

        /*  //server has regax to switch iframe to the site protocol so this use-case is not valid
            var isIframeUrlSecure = this._isUrlSecure(iframeUrl);
            var isSiteUrlSecure = this._isUrlSecure(siteUrl);
            var isUnmatchingSecureness = isSiteUrlSecure && !isIframeUrlSecure;
            return isUnmatchingSecureness ? DENY_IFRAME_RENDERING_STATES.https : 'preloader';
            */

        if (!this.isCompInClientSpecMap()) {
            return OVERLAY_STATES.notInClientSpecMap
        }

        return OVERLAY_STATES.preloader
    },

    componentSpecificShouldUpdate(nextProps) {
        return !nextProps.pageStub
    },

    handleExternalIdStateUpdate(nextProps) {
        const shouldUpdateExternalId = !this.state.preventRefresh && this.state.externalId !== nextProps.compData.referenceId
        if (shouldUpdateExternalId) {
            this.setState({
                externalId: nextProps.compData.referenceId
            })
        }
    },

    updateInitialRouterData(nextProps) {
        const {props} = this
        const newRouterPublicData = _.get(props.getExistingRootNavigationInfo(nextProps.currentUrlPageId), 'routerPublicData')
        if (props.rootId === 'masterPage' && !_.isUndefined(newRouterPublicData) && !_.isEqual(this.state.initialRouterData, newRouterPublicData)) {
            this.setState({
                initialRouterData: newRouterPublicData
            })
            this.shouldUpdateInitialRouterData = false
        }
    },

    componentWillReceiveProps(nextProps) {
        this.handleExternalIdStateUpdate(nextProps)
        this.updateInitialRouterData(nextProps)

        const isResizePreviewExtensionAvailable = this.resize
        if (isResizePreviewExtensionAvailable) {
            this.resize(nextProps)
        }
        const {props} = this
        const oldInstance = _.get(props.clientSpecMap, [props.compData.applicationId, 'instance'])
        const newInstance = _.get(nextProps.clientSpecMap, [props.compData.applicationId, 'instance'])
        if (oldInstance !== newInstance) {
            this.setState({
                initialInstance: newInstance
            })
        }
    },

    componentWillMount() {
        this.props.windowResizeEvent.registerToResize(this)
    },

    componentDidMount() {
        this._isMounted = true
        if (this.props.shouldRenderTPAsIframe) {
            this.startTPAPendingPhase()
        }
    },

    startTPAPendingPhase() {
        const {overlay} = this.state
        if (this.isNotWorker()) {
            this.showOverlyIntervalId = setTimeout(() => {
                this._showOverlayIfNeeded()
            }, this.OVERLAY_GRACE)
            if (!this.props.isViewerMode) {
                if (this.setOverlayState) {
                    this.setOverlayState()
                }
            }
        }
        if (!(this.state.isAlive || _.includes(DENY_IFRAME_RENDERING_STATES, overlay)) && this.isNotWorker()) {
            this._appAliveTimeout = setTimeout(this._onAppAliveTimeoutExpires, this.ALIVE_TIMEOUT)
            tpaUtils.incAppCounter()
        }
    },

    isNotWorker() {
        if (this.isTPAWorker) {
            return !this.isTPAWorker()
        }
        return true
    },

    _onAppAliveTimeoutExpires() {
        this.showUnresponsiveOverlay()
    },

    showUnresponsiveOverlay() {
        if (this._isMounted && !this.state.isAlive && this.isCompInClientSpecMap()) {
            this.setState(
                {
                    overlay: OVERLAY_STATES.unresponsive,
                    visibility: 'visible'
                },
                function () {
                    this.reportBIAppNotResponsive()
                }.bind(this)
            )
        }
    },

    reportBIAppNotResponsive() {
        const {widgetId} = this.props.compData
        const appData = this.getAppData()
        const widgetData = _.get(appData.widgets, widgetId)
        const {biData} = this.props
        const reportParams = {
            endpoint: _.get(widgetData, 'widgetUrl'),
            app_id: _.get(appData, 'appDefinitionId'),
            pageNo: biData.getPageNumber(),
            totalLoadingTime: biData.getTime().totalLoadingTime
        }
        this.props.reportBI(tpaErrors.APP_IS_NOT_RESPONSIVE, reportParams)
    },

    componentWillUnmount() {
        this._isMounted = false
        this._clearAliveTimeout()
        clearTimeout(this.showOverlyIntervalId)
    },

    _clearAliveTimeout() {
        if (this._appAliveTimeout) {
            window.clearTimeout(this._appAliveTimeout)
            this._appAliveTimeout = 0

            if (this.props) {
                tpaUtils.decAppCounter(this.props.reportBeatEvent, this.props.primaryPageId)
            }
        }
    },

    setAppIsAlive(callback) {
        if (!(this.props.isClientAfterSSR && this.props.isTpaRenderedInSsr)) {
            this.reportPerformanceBiEvent(tpaWarmup.events.APP_SDK_START_LOADING)
        }
        this._clearAliveTimeout()

        this.setState(
            {
                isAlive: true,
                overlay: this.state.overlay === OVERLAY_STATES.preloader ? null : this.state.overlay
            },
            function () {
                this.props.tpaComponentsDomAspect.markComponentIsAlive(this.props.id)
                if (callback) {
                    callback()
                }
            }
        )
    },

    _isUrlSecure(url) {
        return url.startsWith('https')
    },

    getTPAOverrideUrl(tpaOverrideParam, widgetId) {
        return tpaUtils.getTpaOverrideMap(this.props.currentUrl, tpaOverrideParam)[widgetId]
    },

    getWidgetId() {
        return _.get(this.props, 'compData.widgetId')
    },

    reportPerformanceBiEvent(eventDescriptor, params) {
        const appData = this.getAppData()
        const widgetId = this.getWidgetId()
        tpaWarmup.reportPerformanceBiEvent(
            eventDescriptor,
            this.props.reportBI,
            this.props.biData,
            this.props.id,
            this.props.isTpaRenderedInSsr,
            appData,
            widgetId,
            params
        )
    },

    enterFullScreen(callback) {
        if (isFullScreenAppOnMobile(this.props)) {
            this.setState({isMobileFullScreenMode: true}, callback)
        }
    },

    exitFullScreen(callback) {
        this.setState({isMobileFullScreenMode: false}, callback)
    }
}
