define([
    'lodash',
    '@wix/santa-core-utils',
    'documentServices/dataModel/dataModel',
    'documentServices/component/component',
    'documentServices/theme/theme',
    'documentServices/mobileConversion/mobileEditorSettings/quickActionsDefinitions',
    'documentServices/mobileConversion/modules/mergeAggregator',
    'documentServices/tpa/services/clientSpecMapService',
    'documentServices/platform/services/platformStateService'
], function (_, coreUtils, dataModel, component, theme, quickActionsDefinitions, mergeAggregator, clientSpecMapService, platformStateService) {
    'use strict'
    const {constants} = coreUtils

    function validateActionText(actionItemData) {
        actionItemData.text = actionItemData.text.slice(0, quickActionsDefinitions.QUICK_ACTION_ITEM_MAX_LENGTH)
    }

    function validateActionType(actionItemData) {
        if (!_.includes(quickActionsDefinitions.actionTypesMap, actionItemData.itemType)) {
            throw new Error(`"${actionItemData.itemType}" is an unsupported action`)
        }
    }

    function validatePredefinedIcon(actionItemData) {
        const predefinedIconData = quickActionsDefinitions.quickActionIconsData[actionItemData.itemType]
        _.assign(actionItemData, {
            icon: predefinedIconData.hash,
            color: predefinedIconData.color
        })
    }

    function validateActionItemData(actionItemData) {
        validateActionText(actionItemData)
        validateActionType(actionItemData)
        if (actionItemData.itemType !== 'custom') {
            validatePredefinedIcon(actionItemData)
        }
    }

    function getMasterPagePointer(ps) {
        return ps.pointers.components.getPage('masterPage', constants.VIEW_MODES.MOBILE)
    }

    function getQuickActionBarPointer(ps) {
        return ps.pointers.components.getComponent('QUICK_ACTION_BAR', getMasterPagePointer(ps))
    }

    function hasQuickActionBar(ps) {
        const quickActionBarPointer = getQuickActionBarPointer(ps)
        return !_.isNull(quickActionBarPointer) && component.isExist(ps, quickActionBarPointer)
    }

    function addAction(ps, actionBarItemData) {
        validateActionItemData(actionBarItemData)
        const serializedQuickActionBarItem = _.merge(_.clone(quickActionsDefinitions.defaultSerializedQuickActionBarItem), {
            data: _.assign(dataModel.createDataItemByType(ps, 'QuickActionBarItem'), _.pick(actionBarItemData, ['link', 'itemType', 'text', 'icon'])),
            style: _.set({}, 'style.properties', _.pick(actionBarItemData, ['color']))
        })
        const quickActionBarRef = getQuickActionBarPointer(ps)
        const compToAddRef = component.getComponentToAddRef(ps, quickActionBarRef, null)
        component.add(ps, compToAddRef, quickActionBarRef, serializedQuickActionBarItem)
    }

    function addQuickActionBar(ps, serializedActionBar, actionBarItemsData) {
        const hasActionBar = hasQuickActionBar(ps)
        const isEmpty = _(getActions(ps)).reject('isDynamic').isEmpty()
        if (hasActionBar && !isEmpty) {
            return
        }
        if (!hasActionBar) {
            const serializedQuickActionBar = _.merge(_.clone(serializedActionBar || quickActionsDefinitions.defaultSerializedFAB), {
                props: dataModel.createPropertiesItemByType(ps, 'QuickActionBarProperties')
            })
            const masterPagePointer = getMasterPagePointer(ps)
            const compToAddRef = component.getComponentToAddRef(ps, masterPagePointer, null, 'QUICK_ACTION_BAR')
            component.add(ps, compToAddRef, masterPagePointer, serializedQuickActionBar)
            mergeAggregator.resetCommittedMobilePages(ps)
        }
        _.forEach(actionBarItemsData, _.partial(addAction, ps, _))
    }

    function removeQuickActionBar(ps) {
        if (!hasQuickActionBar(ps)) {
            return
        }
        component.deleteComponent(ps, getQuickActionBarPointer(ps))
        mergeAggregator.resetCommittedMobilePages(ps)
    }

    function getStructuredActions(ps) {
        const actionCompPointers = ps.pointers.components.getChildren(getQuickActionBarPointer(ps))
        return _.map(actionCompPointers, function (p) {
            const actionData = component.data.get(ps, p)
            const actionStyleId = ps.dal.get(p).styleId
            const actionTheme = theme.styles.get(ps, actionStyleId)
            return _.assign({pointer: p}, _.pick(actionData, ['text', 'icon', 'link', 'itemType']), _.pick(actionTheme.style.properties, ['color']))
        })
    }

    function getDynamicActions(ps) {
        const appsState = platformStateService.getAppsState(ps)
        const optionalAppIds = _(ps.siteDataAPI.siteData.getClientSpecMap())
            .reject(function (appData) {
                return clientSpecMapService.isAppPermissionsIsRevoked(appData, appsState)
            })
            .map('appDefinitionId')
            .value()
        return _(constants.SUPPORTED_DYNAMIC_ACTIONS)
            .map(function (act) {
                return _.assign({}, act, {isDynamic: true})
            })
            .filter(function (act) {
                return _.includes(optionalAppIds, act.appId)
            })
            .value()
    }

    function getActions(ps) {
        if (!hasQuickActionBar(ps)) {
            return []
        }
        const structuredActions = getStructuredActions(ps)
        const dynamicActions = getDynamicActions(ps)
        return _.concat(structuredActions, dynamicActions)
    }

    function clearActions(ps) {
        if (!hasQuickActionBar(ps)) {
            return
        }
        const actionCompPointers = ps.pointers.components.getChildren(getQuickActionBarPointer(ps))
        _.forEach(actionCompPointers, p => component.deleteComponent(ps, p))
    }

    function getAllTypes() {
        return quickActionsDefinitions.actionTypesMap
    }

    function getIconsMap() {
        return quickActionsDefinitions.quickActionIconsData
    }

    return {
        getQuickActionBarPointer,
        hasQuickActionBar,
        addAction,
        addQuickActionBar,
        removeQuickActionBar,
        getActions,
        clearActions,
        getAllTypes,
        validateActionItemData,
        getIconsMap
    }
})
