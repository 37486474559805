import siteButtonProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/siteButtonProxy'
import _ from 'lodash'

/**
 * @class proxies.Button
 * @extends proxies.mixins.siteButton
 */
export default {
    mixins: [siteButtonProxy],
    transformSkinProperties(refData) {
        const rootProps = {
            'data-proxy-name': 'Button',
            onClick: this.handleViewEvent
        }
        refData[''] = _.merge({}, refData[''], rootProps)

        return refData
    },

    getAdditionalButtonStyle() {
        const additionalStyle = {}
        const label = this.getCompProp('label') || this.proxyData
        if (label === '') {
            // @ts-ignore
            additionalStyle.display = 'none'
        }
        return additionalStyle
    },

    getDefaultLabel() {
        return 'Submit'
    }
}
