import type {PublicMethodUtils, ViewerLibrary} from '../apiUtils/publicMethods'

export default function (viewerLibrary: ViewerLibrary, publicMethodUtils: PublicMethodUtils) {
    const smartBoxes = viewerLibrary.get('documentServices/smartBoxes/smartBoxes')
    const multiComponentsUtils = viewerLibrary.get('documentServices/smartBoxes/multiComponentsUtils')
    const multiComponentsUtilsValidations = viewerLibrary.get('documentServices/smartBoxes/multiComponentsUtilsValidations')
    const grouping = viewerLibrary.get('documentServices/smartBoxes/grouping')
    const groupingUtils = viewerLibrary.get('documentServices/smartBoxes/groupingUtils')
    const dsUtils = viewerLibrary.get('documentServices/utils/utils')
    return {
        methods: {
            components: {
                layout: {
                    getSnugPositionAndSize: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugPositionAndSize),
                    getSnugLayout: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugLayout),
                    getSnugLayoutRelativeToScreen: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugLayoutRelativeToScreen),
                    getSnugLayoutRelativeToScreenConsideringScroll: publicMethodUtils.defineGetter(
                        multiComponentsUtils.getSnugLayoutRelativeToScreenConsideringScroll
                    ),
                    getSnugLayoutRelativeToStructure: publicMethodUtils.defineGetter(multiComponentsUtils.getSnugLayoutRelativeToStructure)
                },
                alignment: {
                    alignComponents: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.align, {
                        isUpdatingAnchors: dsUtils.YES
                    }),
                    alignInContainer: publicMethodUtils.defineDataManipulationAction(smartBoxes.alignInContainer, {
                        isUpdatingAnchors: dsUtils.YES,
                        singleComp: true
                    }),
                    canAlign: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canAlign)
                },
                multiComponents: {
                    distribute: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.distribute, {
                        isUpdatingAnchors: dsUtils.YES
                    }),
                    canDistribute: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canDistribute),
                    matchSize: publicMethodUtils.defineDataManipulationAction(multiComponentsUtils.matchSize, {
                        isUpdatingAnchors: dsUtils.YES
                    }),
                    canMatchSize: publicMethodUtils.defineGetter(multiComponentsUtilsValidations.canMatchSize)
                },
                groupComponents: publicMethodUtils.defineDataManipulationAction(grouping.groupComponents, {
                    isUpdatingAnchors: dsUtils.YES,
                    getReturnValue: grouping.genGroupPointer
                }),
                addToGroup: publicMethodUtils.defineDataManipulationAction(grouping.addToGroup, {
                    isUpdatingAnchors: dsUtils.YES
                }),
                ungroup: publicMethodUtils.defineDataManipulationAction(grouping.ungroup, {
                    isUpdatingAnchors: dsUtils.YES,
                    getReturnValue: grouping.getUngroupedComponents
                }),
                is: {
                    group: publicMethodUtils.defineGetter(groupingUtils.isGroup),
                    groupedComponent: publicMethodUtils.defineGetter(groupingUtils.isGroupedComponent),
                    topMost: publicMethodUtils.defineGetter(multiComponentsUtils.isTopMost),
                    leftMost: publicMethodUtils.defineGetter(multiComponentsUtils.isLeftMost)
                }
            }
        },
        initMethod: grouping.initialize
    }
}
