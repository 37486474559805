import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import skinAnchorsMetaData from './skinAnchorsMetaData'

function createOriginalValuesMap(pageStructure, siteTheme, isMobileView) {
    const originalValuesMap = {}

    function addComponentToOriginalValuesMap(component) {
        const children = warmupUtilsLib.dataUtils.getChildrenData(component, isMobileView)
        const originalData: Record<string, any> = {}

        if (component.layout) {
            originalData.top = warmupUtilsLib.boundingLayout.getBoundingY(component.layout)
        }

        if (!_.isEmpty(children)) {
            if (component.layout) {
                const componentStyleId = component.styleId && component.styleId.replace('#', '')
                const componentStyle = siteTheme[componentStyleId]
                const componentSkin = (componentStyle && componentStyle.skin) || component.skin

                originalData.height = component.layout.height - skinAnchorsMetaData.getNonAnchorableHeightForSkin(componentSkin, componentStyle)
            }

            _.forEach(children, addComponentToOriginalValuesMap)
        }

        originalValuesMap[component.id] = originalData
    }

    addComponentToOriginalValuesMap(pageStructure)
    return originalValuesMap
}

export default {
    createOriginalValuesMap
}
