import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {getPagesPlatformApplications} from './utils/pagesPlatformApplicationsUtils'
import addExperimentsFromQuery from '@wix/santa-main-r/lib/lib/common/addExperimentsFromQuery'
import * as experimentInstance from './experiment'
import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils'
import {startTb} from './loadTb'
import {registerAjaxMethod, fetchFunction} from '@wix/bolt-ds-adapter'
import {createThunderboltLogger} from './logger'
import {getBILoggerFactoryForFedops} from './biLoggerFactory'
import {createBILogger} from './bi'
import * as dsCoreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import {urlUtils} from '@wix/santa-core-utils'

const {rendererModel, documentServicesModel, wixBiSession, serviceTopology, viewerBase, dmBase} = window
const queryUtil = getQueryUtils(window)
const rawUrl = `${location.origin}${location.pathname}${location.search || ''}${encodeURIComponent('%20')}`
window.queryUtil = queryUtil

const manipulateRendererModel = (): any => {
    const {siteMetaData, runningExperiments} = rendererModel
    const {isResponsive} = siteMetaData
    const runningExperimentsWithQueryOverrides = addExperimentsFromQuery(runningExperiments, queryUtil, 'viewer', location.search)
    const pagesPlatformApplications = getPagesPlatformApplications(rendererModel)
    const currentAdaptiveMobileOn = _.get(rendererModel, ['siteMetaData', 'adaptiveMobileOn'])
    const adaptiveMobileOn = isResponsive ? false : siteMetaData.adaptiveMobileOn
    if (
        !_.isEqual(runningExperiments, runningExperimentsWithQueryOverrides) ||
        !_.isEqual(pagesPlatformApplications, rendererModel.pagesPlatformApplications) ||
        currentAdaptiveMobileOn !== adaptiveMobileOn
    ) {
        const clonedRendererModel = deepClone(rendererModel)
        clonedRendererModel.pagesPlatformApplications = pagesPlatformApplications as unknown as PagesPlatformApplicationsFromServer
        clonedRendererModel.siteMetaData.adaptiveMobileOn = adaptiveMobileOn
        clonedRendererModel.runningExperiments = runningExperimentsWithQueryOverrides
        return clonedRendererModel
    }
    return rendererModel
}

const manipulatedRendererModel = manipulateRendererModel()
experimentInstance.setContext({
    rendererModel: manipulatedRendererModel
})

// eslint-disable-next-line @typescript-eslint/no-require-imports
const {createDocumentServicesWithHost, initDocumentServicesDocument, createViewerManagerAndExtendHost} = require('@wix/bolt-ds-adapter')

import type {GetViewerApiParams, ViewerAPI} from '@wix/viewer-manager-interface'
import type {Host} from '@wix/document-manager-host-common'
import type {DocumentManager} from '@wix/document-manager-core'
import type {ViewerExtensionAPI} from '@wix/document-manager-extensions/src/extensions/viewer/viewerExtension'
import type {
    PagesPlatformApplicationsFromServer,
    RendererModelFromServer,
    DocumentServicesModel,
    WixBiSession,
    PossibleViewModes
} from '@wix/document-services-types'
import type {PageExtensionAPI} from '@wix/document-manager-extensions/src/extensions/page'
import type {Client as SentryClient} from '@sentry/types'
import type {Factory} from '@wix/web-bi-logger/dist/src/logger'

interface LoadDocumentServicesAndThunderBoltResult {
    hostWithDM: Host
    documentServices: any
    viewerManager: ViewerAPI
}

declare global {
    interface Window {
        rendererModel: RendererModelFromServer
        documentServicesModel: DocumentServicesModel
        wixBiSession: WixBiSession
        serviceTopology: any
        getViewerApi(params: GetViewerApiParams): ViewerAPI
        viewerSource: string
        viewerBase: string
        manifest: {[entry: string]: string}
        queryUtil: any
        thunderboltBase: string
        Sentry: SentryClient
        mainLoaded?: number
    }
}

const isSiteHistoryEndpoint = (_rawUrl: string): boolean => _.includes(_rawUrl, '/html/editor/web/renderer/revisions/view')

const getExternalBaseUrl = (): string => {
    const _rawUrl = `${location.origin}${location.pathname}${(location.search || '').replace(/\+/g, '%20')}`
    const relevantPathParts = isSiteHistoryEndpoint(_rawUrl) ? 8 : 7
    return urlUtils.getBaseUrlWithPath(urlUtils.parseUrl(_rawUrl), relevantPathParts)
}

const loadPages = (extensionAPI: any, pageIds: string[], viewMode?: PossibleViewModes) => {
    const notImplemented = () => {
        throw new Error('not implemented')
    }
    return _.get(extensionAPI as ViewerExtensionAPI, ['viewer', 'syncPagesToViewer'], notImplemented)(pageIds, viewMode)
}

export const getViewerManagerParamsForInit = (extensionAPI: ViewerExtensionAPI, fedopsFactory: Factory): GetViewerApiParams => {
    const {
        geo,
        languageCode,
        locale,
        siteMetaData,
        runningExperiments,
        dataFixerExperiments,
        platformAppsExperiments,
        siteMediaToken,
        mediaAuthToken,
        siteOwnerCoBranding,
        urlFormatModel,
        useSandboxInHTMLComp,
        userId,
        metaSiteId
    } = manipulatedRendererModel
    const {isResponsive} = siteMetaData
    const {editorSessionId, isHttpsEnabled, mediaManagerInfo, originalTemplateId, userInfo} = documentServicesModel

    return {
        dmBase,
        isResponsive,
        fedopsFactory,
        isPreview: true,
        wixBiSession,
        serviceTopology,
        runningExperiments,
        dataFixerExperiments,
        platformAppsExperiments,
        geo,
        languageCode,
        locale,
        siteMediaToken,
        mediaAuthToken,
        siteOwnerCoBranding,
        urlFormatModel,
        useSandboxInHTMLComp,
        userId,
        editorSessionId,
        isHttpsEnabled,
        mediaManagerInfo,
        originalTemplateId,
        userInfo,
        viewerBase,
        externalBaseUrl: getExternalBaseUrl(),
        fetchFunction,
        loadPages: loadPages.bind(null, extensionAPI),
        metaSiteId
    }
}

const initThunderBoltModels = (viewerManager: ViewerAPI, {extensionAPI}: DocumentManager, mainPageId: string) => {
    const isMobileView = !!queryUtil.getParameterByName('showMobileView')

    if (isMobileView) {
        viewerManager.runtime.setWantedIsMobileView(true)
    }

    ;(extensionAPI as ViewerExtensionAPI).viewer.syncViewer({}, true)
    viewerManager.runtime.setWantedNavInfo({pageId: mainPageId})
}

export const loadDocumentServicesAndThunderBolt = async (): Promise<LoadDocumentServicesAndThunderBoltResult> => {
    const fedopsFactory = getBILoggerFactoryForFedops(rendererModel, documentServicesModel, dmBase, rawUrl, wixBiSession)

    const thunderboltLogger = createThunderboltLogger(dmBase, wixBiSession, fedopsFactory)
    const dsPromise = initDocumentServicesDocument(null, thunderboltLogger, experimentInstance, {
        rendererModel: manipulatedRendererModel,
        documentServicesModel,
        serviceTopology
    })
    const [{hostWithDM}] = await Promise.all([dsPromise, startTb()])
    const isReadOnly = !!queryUtil.getParameterByName('isReadOnly')
    registerAjaxMethod(warmupUtils.ajaxLibrary, hostWithDM.environmentContext.serverFacade, experimentInstance, isReadOnly)
    const {extensionAPI} = hostWithDM.documentManager
    const viewerManager = await window.getViewerApi(getViewerManagerParamsForInit(extensionAPI, fedopsFactory))
    const viewerManagerInstanceAndHost = createViewerManagerAndExtendHost({hostWithDM, viewer: viewerManager, logger: thunderboltLogger})
    const mainPageIdOverride = queryUtil.getParameterByName('mainPageIdOverride')
    const pageIdsList = _.map(manipulatedRendererModel.pageList.pages, 'pageId')
    const mainPageId =
        mainPageIdOverride && _.includes(pageIdsList, mainPageIdOverride) ? mainPageIdOverride : (extensionAPI as PageExtensionAPI).page.getMainPageId()
    initThunderBoltModels(viewerManager, hostWithDM.documentManager, mainPageId)

    window.biLogger = createBILogger(rendererModel, documentServicesModel, dmBase, rawUrl, wixBiSession, warmupUtils)

    const documentServices = await createDocumentServicesWithHost({
        hostWithDM,
        viewerManager,
        coreUtils: dsCoreUtils,
        logger: thunderboltLogger,
        experimentInst: experimentInstance,
        viewerManagerInstanceAndHost
    })
    return {hostWithDM, documentServices, viewerManager}
}
