define([
    'lodash',
    'documentServices/siteMetadata/siteName',
    'documentServices/constants/constants',
    'documentServices/siteMetadata/generalInfo',
    'documentServices/utils/runtimeConfig'
], function (_, siteName, constants, generalInfo, runtimeConfig) {
    'use strict'
    const coBrandedValues = {
        None: false,
        CoBranded: true,
        CoBranded_Customer_New: true,
        CoBranded_Customer_Existing: true
    }
    const CO_BRANDED_DEFAULT = 'None'

    /**
     * @param {ps} ps
     * @returns {boolean|*}
     */
    function hasSites(ps) {
        const value = ps.dal.get(ps.pointers.general.hasSites())

        if (_.isNil(value)) {
            const usedSiteNames = siteName.getUsedSiteNames(ps)

            return _.get(usedSiteNames, 'length', 0) > 0
        }

        return value
    }

    /**
     * @param {ps} ps
     * @returns {boolean}
     */
    function isCoBranded(ps) {
        if (!runtimeConfig.isSanta(ps)) {
            const coBrandedPointer = ps.pointers.accountInfo.getCoBranding()
            const coBranding = ps.dal.get(coBrandedPointer)
            return !!coBrandedValues[coBranding]
        }
        return false
    }

    /**
     * Returns whether the user can upgrade the site
     * @param ps
     * @returns {boolean}
     */
    function canUserUpgrade(ps) {
        const permissions = new Set(generalInfo.getUserPermissions(ps))
        return permissions.has(constants.PERMISSIONS.CONNECT_DOMAIN) || permissions.has(constants.PERMISSIONS.UPGRADE)
    }

    /**
     * @param {ps} ps
     * @returns {string}
     */
    function getCoBrandedType(ps) {
        if (!runtimeConfig.isSanta(ps)) {
            const coBrandedPointer = ps.pointers.accountInfo.getCoBranding()
            const coBranding = ps.dal.get(coBrandedPointer)
            return _.includes(_.keys(coBrandedValues), coBranding) ? coBranding : CO_BRANDED_DEFAULT
        }
        return CO_BRANDED_DEFAULT
    }

    return {
        hasSites,
        isCoBranded,
        canUserUpgrade,
        getCoBrandedType
    }
})
