type ReportTags = Record<string, any>
type ReportExtras = Record<string, any>

export interface ReportableErrorParams {
    errorType: string
    message: string
    tags?: ReportTags
    extras?: ReportExtras
}

export class ReportableError extends Error {
    errorType: string
    tags?: ReportTags
    extras?: ReportExtras

    constructor(params: ReportableErrorParams) {
        super(params.message)
        this.errorType = params.errorType
        this.tags = params.tags
        this.extras = params.extras

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ReportableError.prototype)
    }
}
