import _ from 'lodash'
import * as tpaComponents from '../../../_internal/tpaComponents'

const shouldNotifyComp = function (listener, newPageId, oldPageId, compPageId) {
    return (
        listener.type === tpaComponents.common.supportedSiteEvents.PAGE_NAVIGATION ||
        listener.type === tpaComponents.common.supportedSiteEvents.PAGE_NAVIGATION_CHANGE ||
        (listener.type === tpaComponents.common.supportedSiteEvents.PAGE_NAVIGATION_IN && compPageId === newPageId) ||
        (listener.type === tpaComponents.common.supportedSiteEvents.PAGE_NAVIGATION_OUT && compPageId === oldPageId)
    )
}

/**
 *
 * @constructor
 * @param {core.SiteAspectsSiteAPI} siteAPI
 */
const TPAPageNavigationAspect = function (siteAPI, pageId) {
    this._listeners = {}
    this._siteAPI = siteAPI
    this._currentPageId = pageId || this._siteAPI.getSiteData().getCurrentUrlPageId()

    this._siteAPI.registerToUrlPageChange(this.notifyPageChanged.bind(this))
}

TPAPageNavigationAspect.prototype = {
    notifyPageChanged() {
        const isTPAsIframeRendered = this._siteAPI.getSiteData().renderFlags.shouldRenderTPAsIframe
        const newPageId = this._siteAPI.getSiteData().getCurrentUrlPageId()
        const oldPageId = this._currentPageId

        _.forEach(
            this._listeners,
            function (listenersArray) {
                _.forEach(
                    listenersArray,
                    function (listener) {
                        const compPageId = listener.comp.props.rootId
                        const isCompMounted = listener.comp._isMounted

                        if (isCompMounted && isTPAsIframeRendered && shouldNotifyComp(listener, newPageId, oldPageId, compPageId)) {
                            const params: any = {
                                toPage: newPageId,
                                fromPage: oldPageId,
                                isAppOnPage: compPageId === newPageId,
                                wasAppOnPage: compPageId === oldPageId
                            }
                            const routerPublicData = _.get(this._siteAPI.getSiteData().getExistingRootNavigationInfo(newPageId), 'routerPublicData')
                            if (routerPublicData) {
                                params.routerData = routerPublicData
                            }
                            listener.comp.sendPostMessage({
                                intent: 'addEventListener',
                                eventType: listener.type,
                                params
                            })
                        }
                    }.bind(this)
                )
            }.bind(this)
        )

        this._currentPageId = newPageId
    },

    registerToPageChanged(comp, type) {
        let listeners = this._listeners[comp.props.id]
        if (!listeners) {
            listeners = this._listeners[comp.props.id] = []
        }

        listeners.push({
            comp,
            type
        })
    },

    unregisterToPageChanged(comp) {
        delete this._listeners[comp.props.id]
    }
}

export default TPAPageNavigationAspect
