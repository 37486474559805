import _ from 'lodash'
import dsQTrace from '../utils/dsQTrace'
import type {CoreLogger} from '@wix/document-manager-core'

const injectContextAdapter = (dsImplementation: any, viewerLibrary: any, extensionAPI: any, runtimeConfig: any) => {
    const {coreUtils, fedopsLogger} = viewerLibrary
    const {isReadOnly} = runtimeConfig

    const sendHttpRequest = (url: string, type: string, jsonData: any, body: any, success: any, error: any) => {
        const headers = _.get(jsonData, ['headers'], {})
        const {cookieUtils} = coreUtils
        headers['X-XSRF-TOKEN'] = cookieUtils.getCookie('XSRF-TOKEN')
        if (isReadOnly && type !== 'GET') {
            const readOnlyErr = new Error("this operation isn't allowed while in read only mode")
            if (error) {
                return error(readOnlyErr)
            }
            throw readOnlyErr
        }
        coreUtils.ajaxLibrary.ajax(_.merge(jsonData, {url, type, headers, data: body, success, error}))
    }

    dsImplementation.contextAdapter.actions = {
        sendHttpRequest
    }
    dsImplementation.contextAdapter.documentServicesHandlersThatOverridesViewerHandlers = {
        smCurrentMember: true,
        refreshCurrentMember: true,
        getValue: true,
        getValues: true
    }

    dsImplementation.contextAdapter.viewerName = runtimeConfig.viewerName

    dsImplementation.contextAdapter.utils = {
        fedopsLogger
    }
}

const getDefaultLibrary = (dsImplementation: any, documentServicesSchemas: any, coreUtils: any, logger: CoreLogger) => ({
    'document-services-schemas': documentServicesSchemas,
    coreUtils,
    fedopsLogger: logger,

    'documentServices/debug/dsQTrace': dsQTrace,
    'documentServices/utils/contextAdapter': dsImplementation.contextAdapter,
    'documentServices/accessibility/skipToContentData': dsImplementation.temp.skipToContentData,
    'documentServices/accessibility/visualFocusData': dsImplementation.temp.visualFocusData,
    'documentServices/accessibility/autoDomReorderData': dsImplementation.temp.autoDomReorderData,
    'documentServices/actionsAndBehaviors/actionsAndBehaviors': dsImplementation.temp.actionsAndBehaviors,
    'documentServices/appControllerData/appControllerData': dsImplementation.temp.appControllerData,
    'documentServices/appStudio/appStudio': dsImplementation.temp.appStudio,
    'documentServices/appStudioWidgets/appStudioWidgets': dsImplementation.temp.appStudioWidgets,
    'documentServices/appStudio/appStudioComponents': dsImplementation.temp.appStudioComponents,
    'documentServices/autosave/autosave': dsImplementation.autosave,
    'documentServices/bi/bi': dsImplementation.bi,
    'documentServices/atomicScopes/atomicScopes': dsImplementation.atomicScopes,
    'documentServices/environment/environment': dsImplementation.environment,
    biEventTypes: dsImplementation.biEventTypes,
    hooks: dsImplementation.hooks,
    'documentServices/browserThemeColor/browserThemeColor': dsImplementation.temp.browserThemeColor,
    'documentServices/constants/constants': dsImplementation.constants,
    'documentServices/component/component': dsImplementation.temp.component,
    'documentServices/refComponent/refComponent': dsImplementation.temp.refComponent,
    'documentServices/accessibility/componentA11yAPI': dsImplementation.temp.componentA11yAPI,
    'documentServices/component/componentStylesAndSkinsAPI': dsImplementation.temp.componentStylesAndSkinsAPI,
    'documentServices/component/componentCode': dsImplementation.temp.componentCode,
    'documentServices/component/componentBehaviors': dsImplementation.temp.componentBehaviors,
    'documentServices/component/componentStructureInfo': dsImplementation.componentStructureInfo,
    'documentServices/component/componentScroll': dsImplementation.temp.componentScroll,
    'documentServices/componentDetectorAPI/componentDetectorAPI': dsImplementation.temp.componentDetectorAPI,
    'documentServices/componentsLoader/componentsLoader': dsImplementation.componentsLoader,
    'documentServices/componentsMetaData/componentsMetaData': dsImplementation.temp.componentsMetaData,
    'documentServices/connections/connections': dsImplementation.temp.connections,
    'documentServices/dataModel/dataModel': dsImplementation.dataModel,
    'documentServices/dataModel/componentsDataModel': dsImplementation.componentsDataModel,
    'documentServices/features/features': dsImplementation.features,
    'documentServices/states/states': dsImplementation.states,
    'documentServices/triggers/triggers': dsImplementation.triggers,
    'documentServices/reactions/reactions': dsImplementation.reactions,
    'documentServices/documentMode/documentMode': dsImplementation.documentMode,
    'documentServices/errors/errors': dsImplementation.temp.tmpErrors,
    'documentServices/feedback/feedback': dsImplementation.temp.feedback,
    'documentServices/fonts/fonts': dsImplementation.temp.fonts,
    'documentServices/hooks/componentsExternalHooks/createCompDriver': dsImplementation.temp.createCompDriver,
    'documentServices/inlinePopup/inlinePopup': dsImplementation.temp.inlinePopup,
    'documentServices/layouters/layouters': dsImplementation.temp.layouters,
    'documentServices/media/globalImageQualityData': dsImplementation.temp.globalImageQualityData,
    'documentServices/mobileConversion/mobileActions': dsImplementation.temp.mobileActions,
    'documentServices/media/vectorImageAPI': dsImplementation.temp.vectorImageAPI,
    'documentServices/media/svgFiltersDefinitions': dsImplementation.temp.svgFiltersDefinitions,
    'documentServices/menu/mainMenu': dsImplementation.temp.mainMenu,
    'documentServices/menu/menuAPI': dsImplementation.temp.menuAPI,
    'documentServices/menu/basicMenuItemMethods': dsImplementation.temp.basicMenuItemMethods,
    'documentServices/mobileConversion/mobileConversionFacade': dsImplementation.temp.mobileConversionFacade,
    'documentServices/mobileConversion/mobileEditorSettings/mobileSettings': dsImplementation.temp.mobileSettings,
    'documentServices/mobileConversion/mobileEditorSettings/quickActions': dsImplementation.temp.quickActions,
    'documentServices/mobileConversion/mobileEditorSettings/quickActionsVersionUtils': dsImplementation.temp.quickActionsVersionUtils,
    'documentServices/mobileConversion/mobileEditorSettings/quickActionBarManager': dsImplementation.temp.quickActionBarManager,
    'documentServices/mobileConversion/modules/mergeAggregator': dsImplementation.temp.mergeAggregator,
    'documentServices/mobileConversion/modules/mobileHintsValidator': dsImplementation.temp.mobileHintsValidator,
    'documentServices/mobileConversion/modules/mobilePresets/mobilePresetsHandler': dsImplementation.temp.mobilePresetsHandler,
    'documentServices/mobileConversion/modules/menuContainer/menuContainer': dsImplementation.temp.menuContainer,
    'documentServices/page/page': dsImplementation.page,
    'documentServices/page/pageRemoval': dsImplementation.pageRemoval,
    'documentServices/page/pageAdd': dsImplementation.pageAdd,
    'documentServices/wix2x/wix2x': dsImplementation.temp.wix2x,
    'documentServices/page/pageData': dsImplementation.temp.pageData,
    'documentServices/pagesGroup/pagesGroup': dsImplementation.temp.pagesGroup,
    'documentServices/platform/platform': dsImplementation.temp.platform,
    'documentServices/platform/provision': dsImplementation.temp.provision || dsImplementation.temp.platform, //TODO remove hack when WEED-19051 is fully merged;
    'documentServices/platform/addApps': dsImplementation.temp.addApps,
    'documentServices/platform/uninstall': dsImplementation.temp.uninstall,
    'documentServices/platform/pages': dsImplementation.temp.pages,
    'documentServices/platform/serialization': dsImplementation.temp.serialization,
    'documentServices/platform/services/viewerInfoChangedEmitter': dsImplementation.temp.viewerInfoChangedEmitter,
    'documentServices/platform/services/apiCallBiService': dsImplementation.temp.apiCallBiService,
    'documentServices/platform/livePreview/livePreview': dsImplementation.temp.livePreview,
    'documentServices/platform/common/constants': dsImplementation.temp.platformConstants,
    'documentServices/platform/sessionState': dsImplementation.temp.sessionState,
    'documentServices/renderPlugins/renderPlugins': dsImplementation.temp.renderPlugins,
    'documentServices/responsiveLayout/responsiveLayout': dsImplementation.responsiveLayout,
    'documentServices/responsiveLayout/breakpoints': dsImplementation.breakpoints,
    'documentServices/routers/pointers/routersPointers': {},
    'documentServices/routers/routers': dsImplementation.temp.routers,
    'documentServices/saveAPI/saveAPI': dsImplementation.temp.saveAPI,
    'documentServices/scrollAnchors/scrollAnchors': dsImplementation.scrollAnchors,
    'documentServices/siteMembers/siteMembers': dsImplementation.temp.siteMembers,
    'documentServices/siteMetadata/siteMetadata': dsImplementation.siteMetadata,
    'documentServices/siteSegments/siteSegments': dsImplementation.temp.siteSegments,
    'documentServices/smartBoxes/smartBoxes': dsImplementation.temp.smartBoxes,
    'documentServices/smartBoxes/multiComponentsUtils': dsImplementation.temp.multiComponentsUtils,
    'documentServices/smartBoxes/multiComponentsUtilsValidations': dsImplementation.temp.multiComponentsUtilsValidations,
    'documentServices/smartBoxes/grouping': dsImplementation.temp.grouping,
    'documentServices/smartBoxes/groupingUtils': dsImplementation.temp.groupingUtils,
    'documentServices/structure/structure': dsImplementation.structure,
    'documentServices/structure/structureUtils': dsImplementation.temp.structureUtils,
    'documentServices/structure/utils/arrangement': dsImplementation.temp.arrangement,
    'documentServices/structure/meshMigrationUtil': dsImplementation.temp.meshMigrationUtil,
    'documentServices/theme/theme': dsImplementation.temp.theme,
    'documentServices/tpa/tpa': dsImplementation.temp.tpa,
    'documentServices/tpa/core': dsImplementation.temp.tpaCore,
    'documentServices/utils/utils': dsImplementation.utils,
    'documentServices/utils/setOperationQueueUtils': dsImplementation.setOperationQueueUtils,
    'documentServices/utils/idGenerator': dsImplementation.temp.idGenerator,
    'documentServices/validation/validation': dsImplementation.temp.validation,
    'documentServices/wixCode/wixCode': dsImplementation.temp.wixCode,
    'documentServices/wixapps/wixapps': dsImplementation.temp.wixapps,
    'documentServices/snapshotAPI/snapshotAPI': dsImplementation.temp.snapshotAPI,
    'documentServices/customElements/customElements': dsImplementation.customElements,
    'documentServices/stylableEditor/stylableEditorAPI': dsImplementation.temp.stylableEditorAPI,
    'documentServices/variants/variants': dsImplementation.variants,
    'documentServices/account/account': dsImplementation.account,
    'documentServices/variants/design': dsImplementation.design,
    'documentServices/variants/transformations': dsImplementation.transformations,
    'documentServices/variants/transitions': dsImplementation.transitions,
    'documentServices/partners/partners': dsImplementation.partners,
    'documentServices/slots/slots': dsImplementation.slots,
    'documentServices/variables/variables': dsImplementation.variables
})

const createModuleLibrary = (
    dsImplementation: any,
    documentServicesSchemas: any,
    coreUtils: any,
    logger: CoreLogger,
    moduleLibraryOverrides: any,
    extensionAPI: any,
    runtimeConfig: any
) => {
    const defaultLibrary = getDefaultLibrary(dsImplementation, documentServicesSchemas, coreUtils, logger)
    const viewerLibrary = _.assign({}, defaultLibrary, moduleLibraryOverrides)

    injectContextAdapter(dsImplementation, viewerLibrary, extensionAPI, runtimeConfig)

    const get = (moduleName: string) => {
        const module = viewerLibrary[moduleName]

        if (!module) {
            throw new Error(`Missing module: ${moduleName}`)
        }

        return module
    }

    return {
        get
    }
}

export {createModuleLibrary}
