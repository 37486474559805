import errors from './errors.json'
import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'

const {logger} = coreUtils.loggingUtils

/**
 * Please ctrl/cmd + click on biError to see the schema :)
 * @type {Object.<String, biError>}
 */

_.forEach(errors, function (error, key) {
    // @ts-ignore
    error.errorName = key
})

logger.register('tpa', 'error', errors)

export default errors
