export interface Attempt<T> {
    value: T | null
    error: Error | null
    didThrow: boolean
}

export async function asyncAttempt<T>(f: (...params: any[]) => Promise<T>, ...args: any[]): Promise<Attempt<T>> {
    try {
        return {
            value: await f(...args),
            error: null,
            didThrow: false
        }
    } catch (e: any) {
        return {
            value: null,
            error: e,
            didThrow: true
        }
    }
}

export function throwAttempt<T>(result: Attempt<T>): T {
    if (result.didThrow) {
        throw result.error
    }
    return result.value!
}

export async function defaultAsyncAttempt<T>(defaultValue: T, f: (...params: any[]) => Promise<T>, ...args: any[]) {
    try {
        return (await f(...args)) ?? defaultValue
    } catch (e) {
        return defaultValue
    }
}
