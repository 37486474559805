import _ from 'lodash'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import wixApps from '@wix/santa-ds-libs/src/wixappsCore'

const {typesConverter} = wixApps
const {baseCompositeProxy} = wixApps

function getCellDefinition(index, type) {
    const columnsDefinition = this.getCompProp('columns')
    return columnsDefinition[index][type]
}

function getItemKey(row, column, childViewDef) {
    return [row, column, this.getViewDefProp('id', childViewDef)].join('_')
}

function getHeaderFooterCell(column, type) {
    const childViewDef = getCellDefinition.call(this, column, type)
    if (!childViewDef) {
        return null
    }

    return this.renderChildProxy(childViewDef, getItemKey.call(this, type, column, childViewDef))
}

/**
 * @class proxies.Table
 * @extends proxies.mixins.baseComposite
 */
export default {
    mixins: [baseCompositeProxy],

    getBodyCell(column, row) {
        const childViewDef = getCellDefinition.call(this, column, 'item')
        const dataPath = _.compact(['this', this.getCompProp('rowsDataArray'), String(row)])
        const childContextId = `${row}_${column}`
        const props = this.getChildProxyProps(childViewDef, dataPath, {})
        props.proxyParentId = `${this.props.viewDef.id}_${childContextId}`

        return this.renderChildProxy(childViewDef, getItemKey.call(this, row, column, childViewDef), null, props)
    },

    getHeaderCell(index) {
        return getHeaderFooterCell.call(this, index, 'header')
    },

    getFooterCell(index) {
        return getHeaderFooterCell.call(this, index, 'footer')
    },

    renderProxy() {
        const componentType = 'wysiwyg.viewer.components.Table'
        const props = this.getChildCompProps(componentType)
        const columnsDefinition = this.getCompProp('columns')
        const rowsDataItems = this.getDataByPath(_.compact(['this', this.getCompProp('rowsDataArray')]))
        props.compData = typesConverter.table(columnsDefinition)

        props.compProp = {
            minHeight: this.getCompProp('minHeight'),
            numOfRows: rowsDataItems.length,
            numOfColumns: columnsDefinition.length,
            header: _.some(columnsDefinition, 'header'),
            footer: _.some(columnsDefinition, 'footer')
        }

        props.getBodyCell = this.getBodyCell
        props.getHeaderCell = this.getHeaderCell
        props.getFooterCell = this.getFooterCell

        return componentsCore.compRegistrar.getCompClass(componentType, true)(props)
    }
}
