define([], function () {
    'use strict'

    /**
     * returns the name of the viewer used
     * @param {ps} ps
     * @return string
     */
    const getViewerName = ps => ps.runtimeConfig.viewerName

    /**
     * returns the version of the viewer used
     * @param {ps} ps
     * @return string
     */
    const getViewerVersion = ps => ps.runtimeConfig.viewerVersion

    /**
     * returns whether this document is responsive
     * @param {ps} ps
     * @return boolean
     */
    const isResponsiveDocument = ps => {
        const isResponsivePointer = ps.pointers.general.isResponsive()

        return !!ps.dal.full.get(isResponsivePointer)
    }

    /**
     * returns screen resolution
     * @param {ps} ps
     * @return Object - {height: , width:}
     */
    function getScreenResolution(ps) {
        const {
            width: {device: deviceWidth},
            height: {device: deviceHeight}
        } = ps.siteAPI.getSiteMeasureMap()
        return {height: deviceHeight, width: deviceWidth}
    }

    return {
        isResponsiveDocument,
        getViewerName,
        getViewerVersion,
        getScreenResolution
    }
})
