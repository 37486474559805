define(['documentServices/page/page', 'documentServices/tpa/utils/componentAppDataClone'], function (page, componentAppDataClone) {
    'use strict'
    const cloneTpaCompDataIfNeeded = async (ps, pageComponentPointer, pageTitle, serializedPage) => {
        let oldToNewMapId = {}
        if (serializedPage) {
            const compsWithApps = componentAppDataClone.getAllTpaWidgetsAndGenerateId(serializedPage)
            oldToNewMapId = await componentAppDataClone.cloneData(ps, compsWithApps)
        }
        ps.setOperationsQueue.asyncPreDataManipulationComplete(oldToNewMapId)
    }

    function addAndCloneTpaCompData(ps, oldToNewMapId, pageComponentPointer, pageTitle, serializedPage, shouldAddMenuItem) {
        page.addPageInternal(ps, pageComponentPointer, pageTitle, serializedPage, shouldAddMenuItem, oldToNewMapId)
    }

    const exports = {
        cloneTpaCompDataIfNeeded,
        addAndCloneTpaCompData
    }

    return exports
})
