import {getPointer} from './pointerUtils'
const pointerType = 'runtime'

const createPointersMethods = () => {
    const getWantedNavInfo = () => getPointer('wantedNavInfo', pointerType)
    const getWantedIsMobileView = () => getPointer('wantedIsMobileView', pointerType)

    return {
        runtime: {
            getWantedNavInfo,
            getWantedIsMobileView
        }
    }
}

export {createPointersMethods}
