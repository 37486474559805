define(['@wix/santa-core-utils', 'documentServices/componentsMetaData/componentsMetaData'], function (santaCoreUtils, componentsMetaData) {
    'use strict'

    const CONTROLLER_TYPE = 'platform.components.AppController'

    const isFixedPosition = (ps, compPointer) => {
        const isFixedPointer = ps.pointers.getInnerPointer(compPointer, 'layout.fixedPosition')
        return !!ps.dal.get(isFixedPointer)
    }

    const isController = (ps, compPointer) => componentsMetaData.getComponentType(ps, compPointer) === CONTROLLER_TYPE

    const isCompArrangeable = (ps, compPointer) => {
        if (ps.pointers.components.isPage(compPointer)) {
            return false
        }

        if (isController(ps, compPointer)) {
            return false
        }

        const parentPointer = ps.pointers.components.getParent(compPointer)
        const isRepeatedComponent = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)
        const isParentRepeatedComponent = parentPointer && santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(parentPointer.id)
        const isRefComponent = santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)

        if (isRefComponent || (isRepeatedComponent && !isParentRepeatedComponent)) {
            return false
        }
        return true
    }

    const canSwapComponents = (ps, source, target) => isCompArrangeable(ps, target) && isFixedPosition(ps, source) === isFixedPosition(ps, target)

    return {
        isCompArrangeable,
        canSwapComponents
    }
})
