define(['lodash', 'documentServices/utils/utils'], function (_, dsUtils) {
    'use strict'

    const getControllerInPageByDataId = (ps, pagePointer, isMobileView, controllerDataId) => {
        const predicate = comp => {
            const dataQuery = _.get(comp, ['dataQuery'], '')
            return dsUtils.stripHashIfExists(dataQuery) === controllerDataId
        }

        return ps.pointers.components.findComponentInPage(pagePointer, isMobileView, predicate)
    }

    return {
        getControllerInPageByDataId
    }
})
