import _ from 'lodash'
import {CreateExtArgs, DocumentDataTypes, Extension, ExtensionAPI, PointerMethods, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {CreateExtension} from '@wix/document-manager-core/src/types'

const {getPointer} = pointerUtils

const pointerType = 'documentServicesModel'
const NO_MATCH: string[] = []
const INDEX_MATCH = [pointerType]

const getMetadataInnerPointer = (key: string): Pointer => getPointer('metaSiteData', pointerType, [key])

const isDraftPointer = getPointer('isDraft', pointerType)

const createSeoPointers = () => ({
    getIndexing: () => getMetadataInnerPointer('indexable'),
    getTitle: () => getMetadataInnerPointer('title'),
    getMetaTags: () => getMetadataInnerPointer('metaTags'),
    getRedirectUrls: () => getMetadataInnerPointer('externalUriMappings'),
    getThumbnail: () => getMetadataInnerPointer('thumbnail'),
    getSuppressTrackingCookies: () => getMetadataInnerPointer('suppressTrackingCookies'),
    getFavicon: () => getMetadataInnerPointer('favicon')
})

const createPointersMethods = (): PointerMethods => {
    const getEditorSessionId = () => getPointer('editorSessionId', pointerType)
    const getEnvSessionId = () => getPointer('envSessionId', pointerType)
    const getMediaUserUploadToken = () => getPointer('mediaManagerInfo', pointerType, ['userUploadToken'])
    const getMediaSiteUploadToken = () => getPointer('mediaManagerInfo', pointerType, ['siteUploadToken'])
    const isHttpsEnabled = () => getPointer('isHttpsEnabled', pointerType)
    const getUseOnboarding = () => getMetadataInnerPointer('useOnboarding')
    const getUsedMetaSiteNames = () => getPointer('usedMetaSiteNames', pointerType)
    const isPublished = () => getPointer('isPublished', pointerType)
    const hasSites = () => getPointer('hasSites', pointerType)
    const getPublicUrl = () => getPointer('publicUrl', pointerType)
    const getPermissionsInfo = () => getPointer('permissionsInfo', pointerType)
    const isOwner = () => getPointer('permissionsInfo', pointerType, ['isOwner'])
    const getPermissions = () => getPointer('permissionsInfo', pointerType, ['permissions'])
    const getSiteToken = () => getPointer('permissionsInfo', pointerType, ['siteToken'])
    const getUserInfo = () => getPointer('userInfo', pointerType)
    const getOriginalTemplateId = () => getPointer('originalTemplateId', pointerType)
    const getNeverSaved = () => getPointer('neverSaved', pointerType)
    const getAutoSaveInnerPointer = (key: string) => getPointer('autoSaveInfo', pointerType, [key])
    const getAutosaveInfo = () => getPointer('autoSaveInfo', pointerType)
    const getPendingApps = () => getPointer('pendingApps', pointerType)
    const getSiteName = () => getPointer('siteName', pointerType)
    const getCustomHeadTags = () => getPointer('customHeadTags', pointerType)
    const getMetaSiteData = () => getPointer('metaSiteData', pointerType)
    const getSiteRevision = () => getPointer('versionInfo', pointerType, ['revision'])
    const getSiteVersion = () => getPointer('versionInfo', pointerType, ['version'])
    const getIsStudioUser = () => getPointer('userInfo', pointerType, ['isStudio'])
    const getDockedRuntimeLayout = () => getPointer('dockedRuntimeLayout', pointerType)
    const getIsDraft = () => isDraftPointer
    const getBranchId = () => getPointer('branchId', pointerType)
    const getOpenWixCodeAppId = () => getPointer('openWixCodeAppId', pointerType)

    return {
        documentServicesModel: {
            getEditorSessionId,
            getEnvSessionId,
            getMediaUserUploadToken,
            getMediaSiteUploadToken,
            isHttpsEnabled,
            getUseOnboarding,
            getUsedMetaSiteNames,
            isPublished,
            hasSites,
            getPublicUrl,
            isOwner,
            getUserInfo,
            getIsDraft,
            getOriginalTemplateId,
            getPermissions,
            getSiteToken,
            getPendingApps,
            getSiteName,
            getCustomHeadTags,
            getMetaSiteData,
            getSiteVersion,
            getIsStudioUser,
            getDockedRuntimeLayout,
            getPermissionsInfo,
            getBranchId
        },
        general: {
            getNeverSaved,
            getIsDraft,
            getAutoSaveInnerPointer,
            getAutosaveInfo,
            getIsStudioUser,
            getPublicUrl,
            getDockedRuntimeLayout,
            getSiteVersion,
            hasSites
        },
        save: {
            getNeverSaved,
            getSiteRevision
        },
        autoSave: {
            getAutoSaveInnerPointer,
            getAutosaveInfo
        },
        seo: {
            ...createSeoPointers()
        },
        wixCode: {
            getOpenWixCodeAppId
        }
    }
}

const initialState = {
    [pointerType]: {
        dockedRuntimeLayout: {}
    }
}

const createExtensionAPI = ({dal, pointers}: CreateExtArgs): DocumentServicesModelExtApi => {
    const esiPointer = pointers.documentServicesModel.getEditorSessionId()
    return {
        siteAPI: {
            getPublicBaseUrl: () => (dal.get(pointers.documentServicesModel.isPublished()) ? dal.get(pointers.documentServicesModel.getPublicUrl()) : ''),
            getLastTransactionId: () => dal.get(pointers.general.getAutoSaveInnerPointer('lastTransactionId')),
            getActionsCount: () => dal.get(pointers.general.getAutoSaveInnerPointer('actionsCount')),
            setActionsCount: (value: number) => {
                const autosaveCountPointer = pointers.general.getAutoSaveInnerPointer('actionsCount')
                dal.set(autosaveCountPointer, value)
            },
            setAutosaveCount: (value: number) => {
                const autosaveCountPointer = pointers.general.getAutoSaveInnerPointer('autosaveCount')
                dal.set(autosaveCountPointer, value)
            },
            getAutosaveCount: () => dal.get(pointers.general.getAutoSaveInnerPointer('autosaveCount')),
            getChangesApplied: () => dal.get(pointers.general.getAutoSaveInnerPointer('changesApplied')),
            getEditorSessionId: () => dal.get(esiPointer),
            getEnvSessionId: () => dal.get(pointers.documentServicesModel.getEnvSessionId()),
            forceUpdate: () => {
                dal.commitTransaction('documentServicesModelExt')
            },
            getNeverSaved: () => dal.get(pointers.general.getNeverSaved()),
            getIsDraft: () => dal.get(pointers.general.getIsDraft()),
            getAutosaveInfo: () => dal.get(pointers.autoSave.getAutosaveInfo()),
            getSiteRevision: () => dal.get(pointers.save.getSiteRevision()),
            getBranchId: () => dal.get(pointers.documentServicesModel.getBranchId()),
            setSiteRevision: (val: number) => {
                dal.set(pointers.save.getSiteRevision(), val)
            },
            setChangesApplied: (val: boolean) => {
                dal.set(pointers.general.getAutoSaveInnerPointer('changesApplied'), val)
            },
            getSiteVersion: () => dal.get(pointers.documentServicesModel.getSiteVersion()),
            setSiteVersion: (val: number) => {
                dal.set(pointers.documentServicesModel.getSiteVersion(), val)
            },
            getDocumentServicesModel: () =>
                _.map(dal.query(pointerType, dal.queryFilterGetters.getDocumentServicesModelFilter(pointerType)), (value, id) => ({
                    pointer: getPointer(id, pointerType),
                    value
                }))
        }
    }
}

const createFilters = () => ({
    getDocumentServicesModelFilter: (namespace: string): string[] => {
        if (namespace === pointerType) {
            return INDEX_MATCH
        }
        return NO_MATCH
    }
})

export interface DocumentServicesModelExtApi extends ExtensionAPI {
    siteAPI: {
        getPublicBaseUrl(): string
        getLastTransactionId(): string
        getActionsCount(): number
        setActionsCount(value: number): void
        getAutosaveCount(): number
        setAutosaveCount(value: number): void
        getChangesApplied(): boolean
        getEditorSessionId(): string
        getEnvSessionId(): string
        forceUpdate(): void
        getNeverSaved(): boolean
        getIsDraft(): boolean
        getAutosaveInfo(): {
            shouldAutoSave: boolean
            lastTransactionId?: string
        }
        setChangesApplied(val: boolean): void
        getBranchId(): string
        getSiteRevision(): number
        setSiteRevision(val: number): void
        getSiteVersion(): number
        setSiteVersion(val: number): void
        getDocumentServicesModel(): any
    }
}

const createExtension: CreateExtension = (): Extension => {
    const getDocumentDataTypes = (): DocumentDataTypes => ({
        [pointerType]: {idsWithSignature: new Set(['metaSiteData'])}
    })

    return {
        name: 'documentServicesModel',
        createPointersMethods,
        getDocumentDataTypes,
        initialState,
        createExtensionAPI,
        createFilters
    }
}

export {createExtension, isDraftPointer}
