import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as tpaComponents from '../../../_internal/tpaComponents'

const {urlUtils} = coreUtils
const {throttledForEach} = coreUtils.throttleUtils

const getPixelApps = function (specMap) {
    return _.filter(specMap, function (spec) {
        return _.isString(spec.pixelUrl) && spec.permissions && !spec.permissions.revoked
    })
}

const sendRequest = function (url) {
    new window.Image(0, 0).src = url
}

const sendRequestsIfNeeded = function () {
    const getCurrentPageUrl = this._aspectSiteAPI.getPageUrl.bind(this._aspectSiteAPI)
    const apps = getPixelApps(this._aspectSiteAPI.getSiteData().getClientSpecMap())
    const urls = _.map(apps, function (app) {
        return new tpaComponents.common.TPAUrlBuilder(app.pixelUrl)
            .addMultipleQueryParams({
                instance: app.instance,
                page: getCurrentPageUrl(),
                ck: urlUtils.cacheKiller()
            })
            .build()
    })

    throttledForEach(urls, PixelTrackerSiteAspect.sendRequest, PixelTrackerSiteAspect.CHUNK_SIZE, PixelTrackerSiteAspect.CHUNK_INTERVAL)
}

function PixelTrackerSiteAspect(aspectSiteAPI) {
    this._aspectSiteAPI = aspectSiteAPI
    this._aspectSiteAPI.registerToComponentDidMount(sendRequestsIfNeeded.bind(this))
    this._aspectSiteAPI.registerToUrlPageChange(sendRequestsIfNeeded.bind(this))
}

PixelTrackerSiteAspect.sendRequest = sendRequest
PixelTrackerSiteAspect.CHUNK_SIZE = 1
PixelTrackerSiteAspect.CHUNK_INTERVAL = 100

export default PixelTrackerSiteAspect
