define([
    'lodash',
    'documentServices/theme/theme',
    'documentServices/theme/isSystemStyle',
    'documentServices/componentDetectorAPI/componentDetectorAPI',
    'documentServices/constants/constants',
    'documentServices/extensionsAPI/extensionsAPI',
    'documentServices/documentServicesDataFixer/fixers/styles/stylesCollectorPlugins/stylesCollectorPlugins',
    'documentServices/documentServicesDataFixer/fixers/utils/variantThemeUtils'
], function (_, theme, isSystemStyle, componentDetectorAPI, constants, extensionsAPI, customStylesCollectors, variantThemeUtils) {
    'use strict'

    const styleCollectorPlugins = {}

    const omitSystemStyles = (ps, styleIds) => _.reject(_.without(styleIds, constants.STYLES.THEME_DATA_ID), styleId => isSystemStyle(styleId))
    const getComponentType = (ps, comp) => ps.dal.get(ps.pointers.getInnerPointer(comp, 'componentType'))
    const getModeOverrideStyles = (ps, comp) => {
        const modes = ps.dal.full.get(ps.pointers.getInnerPointer(comp, 'modes'))
        const overrides = _.get(modes || {}, 'overrides')

        if (!overrides) {
            return []
        }

        return modes.overrides.map(override => override.styleId)
    }

    const getCompUsedStyles = (ps, comp) => {
        const componentType = getComponentType(ps, comp)
        const modesStyles = getModeOverrideStyles(ps, comp)
        const componentStyles = []
        variantThemeUtils.applyToEachComponentStyleAndReference(ps, comp, style => {
            componentStyles.push(style.id)
        })
        const classBasedStyles = styleCollectorPlugins[componentType] ? styleCollectorPlugins[componentType](ps, comp) : []
        return [...modesStyles, ...classBasedStyles, ...componentStyles]
    }

    const collectUsedStylesFromAllPages = ps => {
        const desktop = componentDetectorAPI.getAllComponentsFromFull(ps, null, _.constant(true), 'DESKTOP')
        const mobile = componentDetectorAPI.getAllComponentsFromFull(ps, null, _.constant(true), 'MOBILE')
        return _(desktop)
            .concat(mobile)
            .flatMap(comp => getCompUsedStyles(ps, comp))
            .uniq()
            .filter(styleId => !!styleId)
            .value()
    }

    const garbageCollectionFixer = {
        registerCustomStyleCollector(componentClassName, collector) {
            styleCollectorPlugins[componentClassName] = collector
        },
        exec(ps) {
            const pageIds = extensionsAPI.pages.getAllPagesIds(ps)
            const usedStyles = collectUsedStylesFromAllPages(ps)

            let orphanStyles = ps.dal.get(ps.pointers.general.getOrphanPermanentDataNodes())
            const orphanStyleIdsInPage = {}

            _.forEach(pageIds, pageId => {
                const allCustomStyleIds = omitSystemStyles(ps, theme.styles.getAllIds(ps, pageId))
                const newOrphanStyles = _.difference(allCustomStyleIds, usedStyles)

                orphanStyles = orphanStyles.concat(newOrphanStyles)

                if (newOrphanStyles && newOrphanStyles.length > 0) {
                    orphanStyleIdsInPage[pageId] = newOrphanStyles
                }
            })
            ps.dal.set(ps.pointers.general.getOrphanPermanentDataNodes(), orphanStyles)
            _.forEach(orphanStyleIdsInPage, (styleIdsToDelete, pageId) => _.forEach(styleIdsToDelete, styleId => theme.styles.remove(ps, styleId, pageId)))
        },
        name: 'garbageCollectionFixer',
        version: 1
    }

    // style collectors may also be registered from other modules @see {registerCustomStyleCollector}
    customStylesCollectors.forEach(({collect, componentsClasses}) => {
        componentsClasses.forEach(compClass => garbageCollectionFixer.registerCustomStyleCollector(compClass, collect))
    })

    return garbageCollectionFixer
})
