define(['lodash', 'documentServices/tpa/services/provisionService', 'documentServices/metaSiteProvisioner/metaSiteProvisioner'], function (
    _,
    provisionService,
    metaSiteProvisioner
) {
    'use strict'

    const TASK_NAME = 'unProvisionedApps'

    const fetchProvisionDataFromSnapshotDal = snapshotDal => ({
        metaSiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
        sessionId: snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
        clientSpecMap: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}),
        provisionBaseUrl: _.get(snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'}), 'appStoreUrl')
    })

    const createTask = () => ({
        partialSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        fullSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        firstSave: metaSiteProvisioner(function (lastSavedData, currentData, resolve, reject, bi, options, lastSnapshotDal, currentSnapshotDal) {
            const {metaSiteId, sessionId, clientSpecMap, provisionBaseUrl} = fetchProvisionDataFromSnapshotDal(currentSnapshotDal)
            provisionService.completeProvisionAppsAfterSave(provisionBaseUrl, metaSiteId, sessionId, clientSpecMap, resolve, reject)
        }),
        saveAsTemplate(lastSavedData, currentData, resolve) {
            resolve()
        },
        autosave(lastSavedData, currentData, resolve) {
            resolve()
        },
        publish(currentData, resolve) {
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        }
    })

    return () => createTask()
})
