import _ from 'lodash'
import performance from '../performance'

const MAX_PROGRESS_COUNT = 10
const MAX_FINISH_COUNT = 10
const PROGRESS_INTERVAL_MS = 3000

const IMAGE_STATUS = {
    SUCCESS: 0,
    FAIL: 1
}

const PAGE_STATUS = {
    INITIAL: 0,
    PROGRESS: 1,
    FINISHED: 2
}

const FINISH_REASON = {
    INITIAL: 0,
    SUCCESS_OR_FAIL: 1,
    TIMED_OUT: 2,
    DESTROYED: 3
}

const imagePool = {}

const reMediaUrl = /\/(v[0-9.]+\/.+)$/
const reUrl = /\/([^\/]+)$/

function getUrlPart(url) {
    if (_.isString(url)) {
        let match = url.match(reMediaUrl)
        if (!match) {
            match = url.match(reUrl)
            if (!match) {
                return ''
            }
        }
        return match[1]
    }
    return ''
}

function extractPageImages(images, pageId, isNewPage) {
    return _.filter(images, function (image) {
        const {id} = image

        if (!imagePool[id]) {
            imagePool[id] = {}
            imagePool[id][pageId] = true
            return true
        }

        return isNewPage && !!imagePool[id][pageId]
    })
}

function subscribeToImageEvents(images, success, fail) {
    _.forEach(images, function (image) {
        image.promise.then(success, fail)
    })
}

function SiteContext() {
    this._current = null
}

SiteContext.prototype = {
    createImageContext(pageId, siteData, onProgress, onSuccess, onFail) {
        return (this._current = new ImageContext(
            siteData,
            {pageId},
            {
                onProgress,
                onSuccess,
                onFail
            }
        ))
    },
    getCurrentImageContext() {
        return this._current
    }
}

function ImageContext(siteData, params, events) {
    this._siteData = siteData
    this._pageId = params.pageId
    this._events = events
    this._interval = 0
    this._finishCount = 1
    this._failDetailsBuffer = []
    this._status = PAGE_STATUS.INITIAL
    this._reason = FINISH_REASON.INITIAL
    this._images = {
        total: 0,
        success: 0,
        fail: 0,
        failDetails: [],
        totalSize: 0
    }
}

ImageContext.prototype = {
    _isFinished() {
        return this._images.total === this._images.success + this._images.fail
    },
    _isFinishSuccess() {
        return this._isFinished() && this._images.fail === 0
    },
    _checkFinished() {
        if (this._finishCount <= MAX_FINISH_COUNT && this._isFinished()) {
            this._finish(FINISH_REASON.SUCCESS_OR_FAIL)

            if (this._isFinishSuccess()) {
                this._events.onSuccess(this)
            } else {
                this._events.onFail(this)
            }

            this._finishCount++
        }
    },
    _startProgress() {
        const self = this
        let index = 1

        this._status = PAGE_STATUS.PROGRESS

        this._interval = setInterval(function () {
            self._flushFailDetailsBuffer()

            self._events.onProgress(self, index++)

            if (index > MAX_PROGRESS_COUNT) {
                self._finish(FINISH_REASON.TIMED_OUT)
            }
        }, PROGRESS_INTERVAL_MS)
    },
    _stopProgress() {
        if (this._interval) {
            clearInterval(this._interval)
            this._interval = 0
        }
    },
    _finish(reason) {
        this._status = PAGE_STATUS.FINISHED
        this._reason = reason

        if (this._failDetailsBuffer.length) {
            this._flushFailDetailsBuffer()
        }

        this._stopProgress()
    },
    _onImageStatusChange(imageStatus, event) {
        if (this._status < PAGE_STATUS.PROGRESS || this._reason > FINISH_REASON.SUCCESS_OR_FAIL || _.get(event, ['canceled'])) {
            return
        }

        if (imageStatus === IMAGE_STATUS.SUCCESS) {
            const imageSize = _.get(event, ['target']) ? performance.getResourceSize(event.target.src) : 0
            this._increaseSuccessCount(imageSize)
        } else {
            const urlPart = event.target ? getUrlPart(event.target.src) : ''
            this._increaseFailCount(urlPart)
        }

        this._checkFinished()
    },
    _increaseSuccessCount(imageSize) {
        this._images.success++
        this._images.totalSize += imageSize
    },
    _increaseFailCount(imageUrl) {
        const time = this._siteData.biData.getTime()

        this._images.fail++
        this._failDetailsBuffer.push({d: time.totalLoadingTime, url: imageUrl})
    },
    _flushFailDetailsBuffer() {
        this._images.failDetails.push(this._failDetailsBuffer)
        this._failDetailsBuffer = []
    },
    getImages() {
        return this._images
    },
    getPageId() {
        return this._pageId
    },
    setImages(images) {
        const newImages = extractPageImages(images, this.getPageId(), this._status === PAGE_STATUS.INITIAL)
        this._images.total += newImages.length

        if (this._status === PAGE_STATUS.INITIAL) {
            this._startProgress()
            this._checkFinished() // will send a finish success event if there are 0 images
        }

        subscribeToImageEvents(newImages, this._onImageStatusChange.bind(this, IMAGE_STATUS.SUCCESS), this._onImageStatusChange.bind(this, IMAGE_STATUS.FAIL))

        return this
    },
    destroy() {
        this._finish(FINISH_REASON.DESTROYED)
    }
}

const sites = {
    _sites: {},
    get(siteData) {
        return (this._sites[siteData.siteId] = this._sites[siteData.siteId] || new SiteContext())
    }
}

export default {
    create(siteData, pageId, onProgress, onSuccess, onFail) {
        onProgress = onProgress || _.noop
        onSuccess = onSuccess || _.noop
        onFail = onFail || _.noop

        return sites
            .get(siteData)
            .createImageContext(pageId, siteData, onProgress.bind(null, siteData), onSuccess.bind(null, siteData), onFail.bind(null, siteData))
    },
    getCurrent(siteData) {
        return sites.get(siteData).getCurrentImageContext()
    }
}
