import _ from 'lodash'
const MOBILE_COMPONENTS = 'mobileComponents'
const MASTER_PAGE_CHILDREN = 'children'
const COMPONENTS = 'components'

function getChildrenKey(data: any, isMobile: boolean) {
    if (isMobile && data.mobileComponents) {
        return MOBILE_COMPONENTS
    }
    return data.children ? MASTER_PAGE_CHILDREN : COMPONENTS
}

function getChildrenComponents(structure: any, isMobile: boolean) {
    const children = isMobile ? structure.mobileComponents : structure.children
    return children || structure.components || []
}

const omitPaths = [MASTER_PAGE_CHILDREN, MOBILE_COMPONENTS]

const convertChildrenToIds = (comp: any, parentId: string | undefined, isMobile: boolean, pageId: string) => {
    const compChildren = comp[getChildrenKey(comp, isMobile)]
    const newComp: any = _.omit(comp, omitPaths)
    newComp.metaData = {...comp.metaData, pageId}
    if (parentId) {
        newComp.parent = parentId
    }
    if (compChildren) {
        newComp.components = compChildren.map((item: any) => item.id)
    }
    return newComp
}

const flattenRecursive = (structure: any, parentId: string | undefined, pageId: string, isMobile: boolean, result: any) => {
    const {id} = structure
    result[id] = convertChildrenToIds(structure, parentId, isMobile, pageId)
    getChildrenComponents(structure, isMobile).forEach((childStructure: any) => {
        flattenRecursive(childStructure, id, pageId, isMobile, result)
    })
}

export function convertNestedPageStructureToFlat(structure: any, pageId: string, isMobile: boolean) {
    const result = {}
    flattenRecursive(structure, undefined, pageId, isMobile, result)
    return result
}
