import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import baseProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mixins/baseProxy'

function isVar(dataPath) {
    return coreUtils.stringUtils.startsWith(dataPath, '$')
}

/**
 * @class proxies.mixins.inputProxy
 * @extends {proxies.mixins.baseProxy}
 */
export default {
    mixins: [baseProxy],
    setData(value, propertyPath) {
        let dataPath = this.getViewDefProp('data') || 'this'
        if (propertyPath) {
            dataPath += `.${propertyPath}`
        }
        const setFunc = isVar(dataPath) ? this.props.viewProps.setVar : this.props.viewProps.setDataByPath
        setFunc(this.contextPath, dataPath, value)
    }
}
