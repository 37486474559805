import $ from 'zepto'
import _ from 'lodash'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import componentsCore from '@wix/santa-ds-libs/src/componentsCore'
import {constants} from '@wix/santa-core-utils'
import * as utils from '@wix/santa-ds-libs/src/utils'
import ReactDOM from 'react-dom'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import * as tpaComponents from '../../../_internal/tpaComponents'
import tpaNavigationService from '../services/tpaNavigationService'
import anchorHandlers from './anchorHandlers'
import performanceHandlers from './performanceHandlers'
import tpaDummyHandlers from './tpaDummyHandlers'
import tpaHandlersCommon from './tpaHandlersCommon'
import experiment from 'experiment'

const {logger} = utils
const {tpaWarmup} = warmupUtils

const WIDGET_MAX_HEIGHT = 1000000 // million
const {PERF_CATEGORY} = tpaComponents.common.metaData

const {TPAActivity} = componentsCore.activityTypes
const {activityService} = componentsCore

const QUICK_ACTION_BAR_ID = 'QUICK_ACTION_BAR'
const QUICK_BAR_BEHAVIOR_METHOD = 'notifyWidget'

function getSiteMembersAspect(siteAPI) {
    return siteAPI.getSiteAspect('siteMembers')
}

function getQuickActionBarAspect(siteAPI) {
    return siteAPI.getSiteAspect('QuickActionBarAspect')
}

const siteInfo = function (siteAPI, msg, callback) {
    const info: any = {}
    const pageData = siteAPI.getSiteData().getDataByQuery(siteAPI.getSiteData().getCurrentUrlPageId())
    const siteTitle = siteAPI.getSiteData().getCurrentUrlPageTitle()

    if (tpaComponents.common.utils.sdkVersionIsAtLeast(msg.version, '1.42.0')) {
        info.pageTitle = siteTitle
    } else {
        info.siteTitle = siteTitle
        info.pageTitle = pageData.title
    }

    info.pageTitleOnly = siteAPI.getSiteData().getCurrentUrlPageName()
    info.siteDescription = pageData.descriptionSEO
    info.siteKeywords = pageData.metaKeywordsSEO

    const {currentUrl} = siteAPI.getSiteData()
    info.url = currentUrl.full
    info.baseUrl = siteAPI.getSiteData().getExternalBaseUrl()
    //TODO get referrer from siteAPI
    info.referer = window.document.referrer

    callback(info)
}

const reportErrorIfCallbackDefined = (callback, errorMessage) => {
    if (callback) {
        callback({error: {message: errorMessage}})
    }
}

const setPageMetadata = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (!comp) {
        return
    }

    const isSection = tpaComponents.common.utils.isTPASection(comp)
    const componentIsOnCurrentPage = _.get(comp, 'props.pageId') === siteAPI.getSiteData().getCurrentUrlPageId()

    if (!isSection) {
        reportErrorIfCallbackDefined(callback, 'Setting a page meta data is possible only to TPA Sections and MultiSections')
        return
    }

    if (!componentIsOnCurrentPage) {
        reportErrorIfCallbackDefined(callback, 'Setting a page meta data is possible only to component that is located on the current page')
        return
    }

    const pageData = siteAPI.getPageData()
    const currentPageTitle = _.get(pageData, 'title', '')
    const tpaPageTitle = _.get(msg, 'data.title')
    const tpaPageFullTitle = _.get(msg, 'data.fullTitle')
    const description = _.get(msg, 'data.description') || _.get(pageData, 'descriptionSEO', '')
    const siteTitle = _.get(siteAPI.getSiteData(), 'rendererModel.siteInfo.siteTitleSEO')
    let pageTitleSEO

    if (tpaPageFullTitle) {
        const appData = comp.getAppData()
        const isSuperApp = _.get(appData, 'isWixTPA')

        if (isSuperApp) {
            siteAPI.setRunTimePageTitle(currentPageTitle, description, tpaPageFullTitle)
            return
        }
    }

    if (tpaPageTitle) {
        pageTitleSEO = `${tpaPageTitle} | ${siteTitle}`
    }

    siteAPI.setRunTimePageTitle(currentPageTitle, description, pageTitleSEO)
}

const buildCustomizedUrl = function (siteAPI, message, callback) {
    callback(undefined)
}

const getCustomizedUrlSegments = function (siteAPI, message, callback) {
    callback(undefined)
}

const setAppMetadata = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp) {
        const appData = comp.getAppData()
        const isSuperApp = _.get(appData, 'isWixTPA')
        const isSection = tpaComponents.common.utils.isTPASection(comp)
        const componentIsOnCurrentPage = _.get(comp, 'props.pageId') === siteAPI.getSiteData().getCurrentUrlPageId()

        if (!isSuperApp) {
            return
        }

        if (!isSection) {
            reportErrorIfCallbackDefined(callback, 'Setting an app meta data is possible only for TPA Sections and MultiSections')
            return
        }

        if (!componentIsOnCurrentPage) {
            reportErrorIfCallbackDefined(callback, 'Setting an app meta data is possible only for components that are located on the current page')
            return
        }
        const linkTags = _.reduce(
            constants.TPA_LINK_TAGS.SUPPORTED_RELS,
            function (result, rel) {
                const href = _.get(msg, `data.${rel}.href`)
                if (href) {
                    result[rel] = href
                }
                return result
            },
            {}
        )
        return siteAPI.setRunTimeLinkTags(linkTags)
    }
}

const removeAppMetadata = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp) {
        const appData = comp.getAppData()
        const isSuperApp = _.get(appData, 'isWixTPA')
        const isSection = tpaComponents.common.utils.isTPASection(comp)
        const componentIsOnCurrentPage = _.get(comp, 'props.pageId') === siteAPI.getSiteData().getCurrentUrlPageId()

        if (!isSuperApp) {
            return
        }

        if (!isSection) {
            reportErrorIfCallbackDefined(callback, 'Removing an app meta data is possible only for TPA Sections and MultiSections')
            return
        }

        if (!componentIsOnCurrentPage) {
            reportErrorIfCallbackDefined(callback, 'Removing an app meta data is possible only for components that are located on the current page')
            return
        }
        const metadataKeysToRemove = _.reduce(
            _.get(msg, 'data'),
            (keysToRemove, value, linkRel) => {
                if (value && _.includes(constants.TPA_LINK_TAGS.SUPPORTED_RELS, linkRel)) {
                    keysToRemove.push(linkRel)
                }
                return keysToRemove
            },
            []
        )
        return siteAPI.removePageSEORuntimeData(metadataKeysToRemove)
    }
}

const heightChanged = function (siteAPI, msg) {
    const height = typeof msg.data === 'number' ? msg.data : msg.data.height
    if (height > WIDGET_MAX_HEIGHT) {
        //a very great height causes the site to render slowly.
        const params = {
            height
        }
        logger.reportBI(siteAPI.getSiteData(), tpaComponents.bi.errors.SDK_SET_HEIGHT_ERROR, params)
    }
    const compState = {
        height,
        ignoreAnchors: msg.data.overflow
    }

    const comp = siteAPI.getComponentById(msg.compId)

    _.invoke(comp, 'registerReLayout')
    _.invoke(comp, 'setState', compState)
}

const getCurrentPageId = function (siteAPI, msg, callback) {
    callback(siteAPI.getSiteData().getCurrentUrlPageId())
}

const getCurrentPageNavigationInfo = function (siteAPI, msg, callback) {
    const routerInfo = siteAPI.getSiteData().getRootNavigationInfo()
    const linkData: any = {}
    if (routerInfo.routerDefinition) {
        linkData.innerRoute = routerInfo.innerRoute
        linkData.type = 'DynamicPageLink'
        linkData.routerId = routerInfo.routerDefinition.routerId
    } else {
        linkData.type = 'PageLink'
        linkData.pageId = routerInfo.pageId
    }
    callback(linkData)
}

const getSitePages = function (siteAPI, msg, callback) {
    const options = {
        filterHideFromMenuPages: true,
        includePagesUrl: _.get(msg, 'data.includePagesUrl', false),
        includeIsHomePage: true,
        enhancedInfo: false
    }

    callback(tpaComponents.sitePages.getSitePagesInfoData(siteAPI.getSiteData(), options))
}

const getSiteMap = function (siteAPI, msg, callback) {
    const options = {
        filterHideFromMenuPages: true,
        includePagesUrl: false,
        includeIsHomePage: false,
        enhancedInfo: true
    }

    callback(tpaComponents.sitePages.getSitePagesInfoData(siteAPI.getSiteData(), options))
}

const navigateToSectionPage = function (siteAPI, msg, callback) {
    let appData

    const comp = siteAPI.getComponentById(msg.compId)
    const appDefinitionId = _.get(msg, 'data.sectionIdentifier.appDefinitionId')
    if (appDefinitionId) {
        appData = tpaComponents.common.utils.getAppDataByAppDefinitionId(siteAPI, appDefinitionId)
    } else if (comp) {
        appData = comp.getAppData()
    }

    const applicationId = _.get(appData, 'applicationId')
    const tpaPages = tpaComponents.services.pageService.mapPageToWidgets(siteAPI)
    const appPages = _.get(tpaPages, applicationId)
    const primaryPageId = siteAPI.getSiteData().getPrimaryPageId()

    const getSectionCompId = function (pageId) {
        const pageComps = siteAPI.getComponentsByPageId(pageId)
        const sectionComp = _.find(pageComps, 'isTPASection')
        return _.get(sectionComp, 'props.id')
    }

    tpaNavigationService.navigateToSection(
        siteAPI,
        appData,
        msg,
        appPages,
        primaryPageId,
        getSectionCompId,
        siteAPI.getSiteData().isDynamicPage,
        callback,
        _.partial(reportAppStateChangedBiEvent, siteAPI, appData)
    )
}

const scrollBy = function (siteAPI, msg, callback) {
    siteAPI.scrollSiteBy(msg.data.x, msg.data.y, callback)
}

const scrollTo = function (siteAPI, msg, callback) {
    if (msg.data.scrollAnimation) {
        siteAPI
            .getSiteData()
            .animations.animate('BaseScroll', siteAPI.getSiteContainer ? siteAPI.getSiteContainer() : siteAPI._site.props.getSiteContainer(), 1, 0, {
                y: msg.data.y,
                x: msg.data.x,
                callbacks: {
                    onComplete: () => {
                        if (callback) {
                            callback()
                        }
                    }
                }
            })
    } else {
        siteAPI.getSiteAspect('windowScrollEvent').scrollSiteTo(msg.data.x, msg.data.y, _.isFunction(callback) && callback)
    }
}

const navigateTo = function (siteAPI, msg, callback) {
    const link = _.get(msg, 'data.link')
    coreUtils.linkUtils.navigateToLink(siteAPI, link, callback)
}

const navigateToPage = function (siteAPI, msg, callback) {
    const primaryPageId = siteAPI.getSiteData().getPrimaryPageId()
    const allPageIds = siteAPI.getSiteData().getAllPageIds()

    const getPageAnchors = function (pageId) {
        const topPageAnchorName = tpaComponents.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
        return utils.scrollAnchors.getPageAnchors(siteAPI.getSiteData(), pageId, topPageAnchorName)
    }

    tpaNavigationService.navigateToPage(siteAPI, msg, primaryPageId, allPageIds, getPageAnchors, _.partial(getAnchorDataId, siteAPI), callback)
}

const navigateToComponent = function (siteAPI, msg, callback) {
    const {pageId} = msg.data
    const {compId} = msg.data
    const siteData = siteAPI.getSiteData()
    const focusedPageId = siteData.getFocusedRootId()

    const comp = siteAPI.getComponentById(compId)
    if (comp && comp.props.structure.componentType === 'wysiwyg.viewer.components.tpapps.TPAGluedWidget') {
        callback({
            error: {
                message: 'Navigation to glued widget not supported.'
            }
        })
        return
    }

    if (!_.isEmpty(pageId) && pageId !== focusedPageId) {
        const allPageIds = siteAPI.getSiteData().getAllPageIds()
        coreUtils.linkUtils.navigateToPage(
            siteAPI,
            pageId,
            allPageIds,
            msg.data.noPageTransition,
            undefined,
            scrollToComponent.bind(null, siteAPI, comp, compId, pageId, callback),
            callback
        )
    } else {
        scrollToComponent(siteAPI, comp, compId, pageId, callback)
    }
}

const scrollToComponent = function (siteAPI, comp, compId, pageId, callback) {
    const siteData = siteAPI.getSiteData()
    pageId = pageId || siteData.getFocusedRootId()
    comp = comp || getComponentByPageId(siteAPI, compId, pageId)

    if (!comp || (!isCompOnAllPages(comp) && comp.props.rootId !== pageId)) {
        const errorMessage = _.isEmpty(pageId) ? 'Current page' : `Page id "${pageId}"`
        callback({
            error: {
                message: `${errorMessage} does not contain the component id "${compId}".`
            }
        })
        return
    }

    const componentPosition = calcCompOffset(comp, siteAPI)

    siteData.animations.animate('BaseScroll', siteAPI.getSiteContainer(), 1, 0, {
        y: componentPosition.y,
        x: componentPosition.x,
        callbacks: {onComplete: callback}
    })
}

const isCompOnAllPages = function (comp) {
    return comp.props.rootId === 'masterPage'
}

const getComponentByPageId = function (siteAPI, compId, pageId) {
    const pageComps = siteAPI.getComponentsByPageId(pageId)
    return pageComps[compId]
}

const calcCompOffset = function (comp, siteAPI) {
    const siteData = siteAPI.getSiteData()

    const compDOMode = ReactDOM.findDOMNode(comp)
    const siteContainerTopOffset = siteData.measureMap.siteMarginTop || 0
    const boundingRectObject = utils.domMeasurements.getElementRect(compDOMode)
    let compYOffset = boundingRectObject.top + siteContainerTopOffset

    if (_.get(siteData, 'measureMap.custom.SITE_HEADER.isFixedPosition')) {
        compYOffset -= siteData.measureMap.height.SITE_HEADER
    }

    const scrollToY = utils.scrollAnchors.normalizeYOffset(compYOffset, siteData)
    const scrollToX = boundingRectObject.left

    return {x: scrollToX, y: scrollToY}
}

const boundingRectAndOffsets = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    const comp = siteAPI.getComponentById(msg.compId)
    const compDOMNode = ReactDOM.findDOMNode(comp)
    const headerIsFixedPosition = _.get(siteData, 'measureMap.custom.SITE_HEADER.isFixedPosition')
    let headerHeight = 0
    if (headerIsFixedPosition) {
        headerHeight = siteData.measureMap.height.SITE_HEADER
    }
    const siteScale = siteAPI.getRenderFlag('siteScale') || 1

    callback({
        rect: buildBoundingRectObject(compDOMNode, headerHeight),
        offsets: transformOffset($(compDOMNode).offset(), headerHeight),
        scale: siteScale
    })
}

const buildBoundingRectObject = function (node, headerHeight) {
    const boundingRect = node.getBoundingClientRect()
    const boundingRectObj = _(boundingRect)
        .pick(['left', 'right', 'top', 'bottom', 'height', 'width'])
        .mapValues(function (value) {
            return Math.floor(value)
        })
        .value()

    if (headerHeight) {
        boundingRectObj.top -= headerHeight
    }

    return boundingRectObj
}

const transformOffset = function (offset, headerHeight) {
    return {
        x: offset.left,
        y: offset.top - headerHeight
    }
}

const openModal = function (siteAPI, msg, callback) {
    const data = msg.data || {}
    tpaHandlersCommon.openModal(siteAPI, msg.compId, data.url, data.width, data.height, data.theme, callback, data.title)
}

const openPopup = function (siteAPI, msg, callback) {
    const data = msg.data || {}

    tpaHandlersCommon.openPopup(siteAPI, msg.compId, data.url, data.width, data.height, data.theme, data.position, false, callback)
}

const openPersistentPopup = function (siteAPI, msg, callback) {
    const {url, width, height, theme, position} = msg.data
    tpaHandlersCommon.openPopup(siteAPI, msg.compId, url, width, height, theme, position, true, callback)
}

const closeWindow = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp && comp.hide) {
        comp.hide(msg.data)
    }
}

const isPercentValue = function (value) {
    return _.isString(value) && /^[0-9]+%$/.test(value)
}

const shouldAllowResizeWindow = function (comp) {
    const allowedTypes = [
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        'wysiwyg.viewer.components.tpapps.TPAPopup',
        'wysiwyg.viewer.components.tpapps.TPAModal'
    ]

    return comp && comp.resizeWindow && _.includes(allowedTypes, comp.props.structure.componentType)
}

const resizeWindow = function (siteAPI, msg, callback) {
    let {width} = msg.data
    let {height} = msg.data

    if (!isPercentValue(width)) {
        width = parseFloat(width)
    }

    if (!isPercentValue(height)) {
        height = parseFloat(height)
    }

    const comp = siteAPI.getComponentById(msg.compId)

    if (shouldAllowResizeWindow(comp)) {
        comp.resizeWindow(width, height, callback)
    }
}

const registerEventListener = function (siteAPI, msg) {
    const {eventKey} = msg.data
    if (eventKey === 'QUICK_ACTION_TRIGGERED') {
        if (!isComponentRegisteredOnQuickBarBehavior(siteAPI, eventKey, msg.compId) && siteAPI.getSiteData().isViewerMode()) {
            registerToQuickAction(siteAPI, msg.compId)
        }
        return
    }
    const comp = siteAPI.getComponentById(msg.compId)

    //in change gallery we change the gallery type but leave the id, so the comp might not be a tpa comp
    if (comp && comp.isCompListensTo && !comp.isCompListensTo(eventKey)) {
        comp.startListen(eventKey)
    }
}

const isComponentRegisteredOnQuickBarBehavior = function (siteAPI, eventKey, compId) {
    const actionName = getCompCorrespondingApplicationDefId(siteAPI, compId)
    const actionsAndBehaviors = siteAPI.getRuntimeDal().getActionsAndBehaviors(QUICK_ACTION_BAR_ID)
    const matchingActions = _(actionsAndBehaviors)
        .filter({action: {sourceId: QUICK_ACTION_BAR_ID, name: actionName}})
        .filter({behavior: {targetId: compId}})
        .filter({behavior: {name: QUICK_BAR_BEHAVIOR_METHOD}})
        .value()
    return !_.isEmpty(matchingActions)
}

const registerToQuickAction = function (siteAPI, compId) {
    const actionName = getCompCorrespondingApplicationDefId(siteAPI, compId)

    const quabActionAndBehavior = createActionAndBehaviorForQuickBar(compId, actionName)

    siteAPI.getRuntimeDal().addActionsAndBehaviors(QUICK_ACTION_BAR_ID, quabActionAndBehavior)
}

function createActionAndBehaviorForQuickBar(tpaCompId, actionName) {
    return {
        action: {
            type: 'comp',
            name: actionName, // this is the action the QUAB needs to fire("handleAction")
            sourceId: QUICK_ACTION_BAR_ID
        },
        behavior: {
            type: 'comp',
            targetId: tpaCompId,
            name: QUICK_BAR_BEHAVIOR_METHOD,
            params: {
                sourceId: QUICK_ACTION_BAR_ID,
                action: actionName
            }
        }
    }
}

const removeEventListener = function (siteAPI, msg) {
    const eventKey = msg.data.eventKey || msg.data

    if (eventKey === 'QUICK_ACTION_TRIGGERED') {
        removeQuickAction(siteAPI, msg.compId)
        return
    }

    const comp = siteAPI.getComponentById(msg.compId)
    if (comp.stopListen) {
        comp.stopListen(eventKey)
    }
}

const removeQuickAction = function (siteAPI, compId) {
    const actionName = getCompCorrespondingApplicationDefId(siteAPI, compId)

    siteAPI.getRuntimeDal().removeActionsAndBehaviors(QUICK_ACTION_BAR_ID, {action: {name: actionName}})
}

function getCompCorrespondingApplicationDefId(siteAPI, compId) {
    const comp = siteAPI.getComponentById(compId)
    const tpaApplicationId = _.get(comp, 'props.compData.applicationId')
    const appData = siteAPI.getSiteData().getClientSpecMap()[tpaApplicationId]
    return appData.appDefinitionId
}

const appStateChanged = function (siteAPI, msg) {
    const state = typeof msg.data === 'string' ? msg.data : msg.data.state
    const comp = siteAPI.getComponentById(msg.compId)
    let parsedState

    const rootId = siteAPI.getRootOfComponentId(msg.compId)
    const navInfo = siteAPI.getSiteData().getExistingRootNavigationInfo(rootId)
    const pageId = navInfo ? navInfo.pageId : rootId //if the root isn't rendered at this point there won't be navInfo
    try {
        parsedState = JSON.parse(state)
        switch (parsedState.cmd) {
            case 'zoom':
                comp.processImageZoom(parsedState)
                break
            case 'itemClicked':
                comp.processItemClicked(parsedState)
                break
            case 'itemChanged':
                comp.processItemChanged(parsedState)
                break
            case 'componentReady':
                comp.setComponentInIframeReady()
                break
            case 'navigateToDynamicPage':
                const linkData = parsedState.args[0]
                const url = utils.wixUrlParser.getUrl(siteAPI.getSiteData(), linkData)
                const navigationInfo = utils.wixUrlParser.parseUrl(siteAPI.getSiteData(), url)
                if (parsedState.args[1]) {
                    navigationInfo.anchorData = parsedState.args[1]
                }
                siteAPI.handleNavigation(navigationInfo, parsedState.args[2])
                break
            case 'navigateToAnchor':
                const navigatePageId = parsedState.args[0]
                const anchorData = parsedState.args[1]
                //we have anchors only on the primary page for now..
                if (siteAPI.getSiteData().getPrimaryPageId() === navigatePageId) {
                    if (anchorData) {
                        siteAPI.scrollToAnchor(anchorData)
                    }
                } else {
                    siteAPI.navigateToPage({
                        pageId: navigatePageId,
                        pageAdditionalData: null,
                        anchorData
                    })
                }
                break
            default:
                pushStateToSection(siteAPI, comp, pageId, state)
        }
    } catch (e) {
        pushStateToSection(siteAPI, comp, pageId, state)
    }
}

const pushStateToSection = function (siteAPI, comp, pageId, state) {
    if (tpaComponents.common.utils.isTPASection(comp)) {
        comp.setState({
            pushState: state
        })

        const appData = comp.getAppData()
        const widgetId = _.get(comp, 'props.compData.widgetId')
        const primaryPageId = siteAPI.getSiteData().getPrimaryPageId()
        tpaNavigationService.navigateWithoutClosingPopupIfPossible(
            siteAPI,
            {
                pageId,
                tpaInnerRoute: state,
                transition: 'none'
            },
            primaryPageId,
            undefined,
            undefined,
            undefined,
            _.partial(reportAppStateChangedBiEvent, siteAPI, appData, widgetId, comp.props.id)
        )
    }
}

const replaceSectionState = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    const {state, queryParams, ignorePageUriSeo} = msg.data
    const rootId = siteAPI.getRootOfComponentId(msg.compId)
    const skipHistory = false
    const replaceHistory = true

    if (tpaComponents.common.utils.isTPASection(comp)) {
        comp.setState({
            pushState: state
        })

        const appData = comp.getAppData()
        const primaryPageId = siteAPI.getSiteData().getPrimaryPageId()
        const widgetId = _.get(comp, 'props.compData.widgetId')
        const onComplete = _.partial(reportAppStateChangedBiEvent, siteAPI, appData, widgetId, msg.compId)
        const navInfo = {
            pageId: rootId,
            tpaInnerRoute: state,
            transition: 'none'
        }
        tpaNavigationService.addAppSectionParamsToNavInfo(queryParams, navInfo)
        tpaNavigationService.navigateWithoutClosingPopupIfPossible(
            siteAPI,
            navInfo,
            primaryPageId,
            skipHistory,
            replaceHistory,
            undefined,
            onComplete,
            ignorePageUriSeo
        )
    }
}

const getSectionUrl = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp) {
        const tpaPages = tpaComponents.services.pageService.mapPageToWidgets(siteAPI)
        const appData = siteAPI.getSiteData().getClientSpecMap()[comp.props.compData.applicationId]
        const tpaId = appData.applicationId
        if (_.isEmpty(tpaPages) || _.isUndefined(tpaPages[tpaId])) {
            callback({
                error: {
                    message: `Page with app "${appData.appDefinitionName}" was not found.`
                }
            })
        } else {
            const pages = tpaPages[tpaId]
            const tpaPageId = msg.data.sectionIdentifier

            const page = _.find(pages, {tpaPageId}) || pages[0]

            let pageId
            if (page) {
                pageId = page.pageId
            }

            if (_.isUndefined(pageId)) {
                callback({error: {message: 'Page was not found.'}})
            } else {
                const url = siteAPI.getPageUrlFor(pageId)
                if (_.isUndefined(url)) {
                    callback({error: {message: 'Page was not found.'}})
                } else {
                    callback({url})
                }
            }
        }
    } else {
        callback({error: {message: 'Component was not found.'}})
    }
}

const sendSiteMemberDataToWidget = function (comp, msg, siteMemberAspect, callback) {
    siteMemberAspect.getMemberDetails(function (memberData) {
        if (memberData !== null) {
            callback(memberData)
            if (comp) {
                comp.setSiteMemberDataState(null)
            }
        } else if (comp) {
            comp.setSiteMemberDataState({callback})
        } else {
            callback(null)
        }
    })
}

const smRequestLogin = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    const siteMemberAspect = getSiteMembersAspect(siteAPI)
    const isLoggedIn = siteMemberAspect.isLoggedIn()

    if (isLoggedIn) {
        sendSiteMemberDataToWidget(comp, msg, siteMemberAspect, function (memberData) {
            callback({
                authResponse: true,
                data: memberData
            })
        })
    } else {
        const mode = _.get(msg, 'data.mode')
        const showLoginDialog = mode && mode === 'login'
        const language = _.get(msg, 'data.language', 'en')
        let cancelCallback
        if (_.get(msg, 'data.callOnCancel')) {
            cancelCallback = function () {
                callback({
                    wasCancelled: true
                })
            }
        }
        const onSuccess = function (memberData) {
            callback({
                authResponse: true,
                data: memberData
            })
        }

        siteMemberAspect.showAuthenticationDialog({
            successCallback: onSuccess,
            cancelCallback,
            language,
            showLoginDialog,
            appId: tpaComponents.common.utils.getAppDefId(siteAPI, msg.compId),
            checkCommunityCheckbox: _.get(msg, 'data.checkCommunityCheckbox')
        })
    }
}

const logOutCurrentMember = function (siteAPI, msg, callback) {
    const siteMemberAspect = getSiteMembersAspect(siteAPI)
    const isLoggedIn = siteMemberAspect.isLoggedIn()

    if (isLoggedIn) {
        const language = _.get(msg, 'data.language')
        siteMemberAspect.logout(language)
    } else {
        callback({
            onError: 'No member is logged in'
        })
    }
}

const refreshCurrentMember = function (siteAPI) {
    const siteMemberAspect = getSiteMembersAspect(siteAPI)
    if (siteMemberAspect.isLoggedIn()) {
        siteMemberAspect.getMemberDetails(null, null, true)
    }
}

const authorizeMemberPages = function (siteAPI, msg, callback) {
    const siteMemberAspect = getSiteMembersAspect(siteAPI)
    const onSuccess = callback
    const onError = () =>
        callback({
            error: {
                message: 'Failed to get authorized pages'
            }
        })
    if (siteMemberAspect.isLoggedIn()) {
        siteMemberAspect.authorizeMemberPages(onSuccess, onError)
    }
}

const smCurrentMember = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    const siteMemberAspect = getSiteMembersAspect(siteAPI)
    const isLoggedIn = siteMemberAspect.isLoggedIn()

    if (isLoggedIn) {
        sendSiteMemberDataToWidget(comp, msg, siteMemberAspect, function (memberData) {
            callback(memberData)
        })
    } else {
        callback(null)
    }
}

const registerCampaignPixel = function (siteAPI, msg) {
    const pixelId = _.get(msg, 'data.pixelId')
    const pixelType = _.get(msg, 'data.pixelType')
    if (pixelId && pixelType === 'FACEBOOK') {
        siteAPI.initFacebookRemarketing(pixelId)
    }
}

const reportCampaignEvent = function (siteAPI, msg) {
    const eventData = _.get(msg, 'data', {})
    const eventName = _.get(msg, 'data.eventName')
    if (eventName) {
        delete eventData.eventName
        siteAPI.fireFacebookRemarketingPixel(eventName, eventData)
    }
}

const trackEvent = function (siteAPI, msg) {
    const eventName = _.get(msg, 'data.eventName')
    const params = _.get(msg, 'data.params')
    const options = _.get(msg, 'data.options')

    // @ts-ignore
    utils.integrations.promoteAnalytics.trackEvent(siteAPI, eventName, params, options)
}

const postActivity = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    const activitySiteInfo = getPostActivitySiteInfo(siteAPI)
    const activityData = getPostActivityData(siteAPI, msg)
    const sendResponseToComp = function (params) {
        comp.sendPostMessage({
            intent: 'TPA_RESPONSE',
            callId: msg.callId,
            type: msg.type,
            res: {
                response: {
                    activityId: params.response.activityId,
                    contactId: params.response.contactId
                },
                status: params.status
            },
            status: params.status,
            compId: msg.compId
        })
    }
    const onSuccess = function (response) {
        if (response.userSessionToken) {
            siteAPI.setUserSession(response.userSessionToken)
        }
        sendResponseToComp({
            status: true,
            response
        })
    }
    const onError = function (response) {
        const res = {
            status: response.status,
            statusText: response.statusText,
            responseText: response.responseText
        }
        sendResponseToComp({
            status: false,
            response: res
        })
    }

    const activity = new TPAActivity(activitySiteInfo, activityData)
    activityService.reportActivity(siteAPI.getSiteData().getExternalBaseUrl(), activity, onSuccess, onError)
}

const getPostActivitySiteInfo = function (siteAPI) {
    const siteData = siteAPI.getSiteData()
    return {
        hubSecurityToken: siteData.getHubSecurityToken(),
        svSession: siteData.getSvSession(),
        metaSiteId: siteData.getMetaSiteId(),
        currentUrl: siteData.getCurrentUrl()
    }
}

const getPostActivityData = function (siteAPI, msg) {
    const appData = tpaComponents.common.utils.getAppData(siteAPI, msg.compId)
    const {data} = msg
    return {
        type: _.get(data, 'activity.type') || 'TPA',
        appDefinitionId: appData.appDefinitionId || 'TPA',
        info: _.get(data, 'activity.info') || {},
        details: _.get(data, 'activity.details') || {},
        contactUpdate: _.get(data, 'activity.contactUpdate') || {},
        instance: appData.instance
    }
}

const getUserSession = function (siteAPI, msg, callback) {
    callback(siteAPI.getUserSession())
}

const appIsAlive = function (siteAPI, msg, callback) {
    const appDefId = tpaComponents.common.utils.getAppDefId(siteAPI, msg.compId)
    coreUtils.loggingUtils.performance.start(`appIsAlive ${appDefId}`, PERF_CATEGORY)
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp && comp.setAppIsAlive) {
        comp.setAppIsAlive()
    }
    //in change gallery we change the gallery type but leave the id, so the comp might not be a tpa comp
    if (_.get(comp, 'hasOrigComponent')) {
        try {
            const styleDataToPassIntoApp = getStyleDataToPassIntoApp(siteAPI, comp)
            callback(styleDataToPassIntoApp)
            coreUtils.loggingUtils.performance.finish(`appIsAlive ${appDefId}`, PERF_CATEGORY)
            coreUtils.loggingUtils.performance.finish(`getStyleParams ${appDefId}`, PERF_CATEGORY)
        } catch (e) {
            siteAPI.reportBI(tpaComponents.bi.events.APP_IS_ALIVE_ERROR, {
                app_id: appDefId,
                errorMessage: e.toString()
            })
        }
    }
}

const getStyleDataToPassIntoApp = function (siteAPI, comp) {
    const siteData = siteAPI.getSiteData()
    const appComp = comp && comp.hasOrigComponent() ? siteAPI.getComponentById(comp.getOrigComponentId()) : comp
    const styleId = _.get(appComp, 'props.structure.styleId')
    const allTheme = utils.styleUtils.getAllThemeForSDK(siteData, comp.getPageId(), styleId)

    const {documentType} = siteData.rendererModel.siteInfo
    const {characterSets} = siteData.getDataByQuery('masterPage')
    const isVisualFocusEnabled = siteData.isVisualFocusEnabled()

    const styleData = utils.styleUtils.getStyleDataToPassIntoApp(
        styleId,
        allTheme,
        siteData.santaBase,
        documentType,
        characterSets,
        siteData.serviceTopology,
        true
    )

    return _.assign(styleData, {isVisualFocusEnabled})
}

const getCtToken = function (siteAPI, msg, callback) {
    if (_.isFunction(callback)) {
        callback(siteAPI.getSiteData().getCTToken())
    }
}

const postCountersReport = function (siteAPI, msg, callback) {
    msg.data.messageId = Date.now()
    const params = {
        ctToken: siteAPI.getSiteData().getCTToken()
    }
    const payload = _.omit(msg.data, 'version')
    const query = `?${utils.urlUtils.toQueryString(params)}`
    //TODO - get this from topology
    const url = `//player-counters.wix.com/collector/rest/collect-js${query}`

    const onSuccess = function (response) {
        callback({
            status: 'success',
            response
        })
    }

    const onError = function (response) {
        const responseText = response && response.responseText
        callback({
            status: 'error',
            response: responseText
        })
    }

    utils.ajaxLibrary.ajax({
        type: 'OPTIONS',
        url,
        data: JSON.stringify(payload),
        dataType: 'json',
        contentType: 'application/json',
        success: onSuccess,
        error: onError
    })
}

const getExternalId = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (comp) {
        callback(comp.props.compData.referenceId)
    }
}

const getViewMode = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    const viewMode = siteData.isViewerMode() ? 'site' : siteAPI.getRenderFlag('componentViewMode')
    callback({editMode: viewMode})
}

const getValue = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    const {compData} = comp.props
    const rootOfComp = siteAPI.getRootOfComponentId(msg.compId)
    const result = tpaWarmup.tpaDataService.getValue(siteAPI.getSiteData(), msg, rootOfComp, compData)
    callback(result)
}

const getPublicData = (siteAPI, msg, callback) => {
    const siteData = siteAPI.getSiteData()
    const rootId = siteAPI.getRootOfComponentId(msg.compId)
    const comp = siteAPI.getComponentById(msg.compId, rootId)
    const {compData} = comp.props
    const result: any = {}
    result.APP = tpaWarmup.tpaDataService.getTpaDataContent(siteData, `tpaData-${compData.applicationId}`, 'masterPage')
    result.COMPONENT = tpaWarmup.tpaDataService.getTpaDataContent(siteData, compData.tpaData, rootId)
    callback(result)
}

const getValues = function (siteAPI, msg, callback) {
    const keys = _.uniq(msg.data.keys)
    const siteData = siteAPI.getSiteData()
    const rootId = siteAPI.getRootOfComponentId(msg.compId)
    const comp = siteAPI.getComponentById(msg.compId, rootId)
    const {compData} = comp.props
    const {scope} = msg.data
    let resultObj

    if (scope === tpaWarmup.tpaDataService.SCOPE.APP) {
        const globalTpaDataContent = tpaWarmup.tpaDataService.getTpaDataContent(siteData, `tpaData-${compData.applicationId}`, 'masterPage')
        // @ts-ignore
        resultObj = _.pick(globalTpaDataContent, keys)
    } else {
        const compTpaDataContent = tpaWarmup.tpaDataService.getTpaDataContent(siteData, compData.tpaData, rootId)
        // @ts-ignore
        resultObj = _.pick(compTpaDataContent, keys)
    }

    if (!_.isEmpty(resultObj) && _(resultObj).keys().isEqual(keys)) {
        callback(resultObj)
    } else {
        const resultKeys = _.keys(resultObj)
        // @ts-ignore
        const keysNotFound = _(resultKeys).xor(keys).intersection(keys).value()
        callback({
            error: {
                message: `keys ${keysNotFound} not found in ${scope} scope`
            }
        })
    }
}

const getComponentInfo = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    const {compData} = comp.props
    const appData = comp.getAppData()
    const showOnAllPages = isCompOnAllPages(comp)
    const widgetData = appData.widgets[compData.widgetId]

    const returnObj = {
        compId: msg.compId,
        showOnAllPages,
        pageId: showOnAllPages ? '' : comp.props.pageId,
        tpaWidgetId: _.get(widgetData, 'tpaWidgetId', ''),
        appPageId: _.get(widgetData, 'appPage.id', '')
    }

    callback(returnObj)
}

const getStateUrl = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    if (!comp) {
        return callback({error: {message: 'Component was not found.'}})
    }
    const tpaPages = tpaComponents.services.pageService.mapPageToWidgets(siteAPI)
    const appData = siteAPI.getSiteData().getClientSpecMap()[comp.props.compData.applicationId]
    const tpaId = appData.applicationId
    if (_.isEmpty(tpaPages) || _.isUndefined(tpaPages[tpaId])) {
        return callback({
            error: {
                message: `Page with app "${appData.appDefinitionName}" was not found.`
            }
        })
    }
    const pages = tpaPages[tpaId]
    const page = _.find(pages, {tpaPageId: msg.data.sectionId})
    let pageInfo
    if (page) {
        pageInfo = {
            pageId: page.pageId,
            tpaInnerRoute: msg.data.state
        }
    } else {
        pageInfo = {
            pageId: pages[0].pageId
        }
    }
    if (siteAPI.getSiteData().isDynamicPage(pageInfo.pageId)) {
        return callback({error: {message: "Can't retrieve url for a dynamic page. Please use the platform app API instead."}})
    }
    return callback({url: utils.wixUrlParser.getUrl(siteAPI.getSiteData(), pageInfo, undefined, true)})
}

const getStyleId = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    callback(comp.props.structure.styleId)
}

const getStyleParamsByStyleId = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    const {styleId} = msg.data
    const {pageId} = msg.data

    const allStyles = siteData.getAllStylesFromPossiblyRenderedRoots()

    const addStyleToAllStyles = () => {
        const styleDef = siteData.getDataByQuery(styleId, pageId, 'theme_data')
        if (styleDef) {
            allStyles[styleId] = styleDef
        }
    }

    const notifyUserWithStyle = () => {
        if (_.isUndefined(allStyles[styleId])) {
            callback({
                error: {
                    message: `Style id "${styleId}" was not found.`
                }
            })
        } else {
            const {documentType} = siteData.rendererModel.siteInfo
            const {characterSets} = siteData.getDataByQuery('masterPage')
            const styleItem = utils.styleUtils.getStylesForSDK(allStyles, styleId, true, siteData.serviceTopology, documentType, characterSets, true)
            callback(styleItem)
        }
    }

    const onPageFetched = () => {
        addStyleToAllStyles()
        notifyUserWithStyle()
    }

    if (_.isUndefined(allStyles[styleId]) && pageId) {
        if (siteData.pagesDataRaw.pagesData[pageId]) {
            onPageFetched()
        } else {
            // const pageRequest = core.dataRequirementsChecker.getRequestsForPages(siteData, siteData.pagesDataRaw, {pageId})
            // const {store} = siteData
            // store.loadBatch(pageRequest, onPageFetched)
        }
    } else {
        notifyUserWithStyle()
    }
}

const getDimensions = function (siteData, compId) {
    return {
        width: _.get(siteData, `measureMap.width.WIX_ADS${compId}`),
        height: _.get(siteData, `measureMap.height.WIX_ADS${compId}`),
        top: _.get(siteData, `measureMap.top.WIX_ADS${compId}`),
        left: _.get(siteData, `measureMap.left.WIX_ADS${compId}`)
    }
}

const getAdsOnPage = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()

    if (siteData.shouldShowWixAds()) {
        callback({
            top: getDimensions(siteData, 'top')
        })
    } else {
        callback({})
    }
}

const setFullScreenMobile = function (siteAPI, message, callback) {
    if (!siteAPI.getSiteData().isMobileView()) {
        callback({
            error: {
                message: 'show full screen is only available in Mobile view'
            }
        })
        return
    }
    const {isFullScreen} = message.data
    const {compId} = message

    const reactComp = siteAPI.getComponentById(compId)

    if (isComponentAllowedInFullScreenMode(siteAPI, compId)) {
        if (isFullScreen) {
            siteAPI.enterFullScreenMode()
            siteAPI.setSiteRootHiddenState(true)
            reactComp.enterFullScreen(callback)
        } else {
            siteAPI.setSiteRootHiddenState(false)
            siteAPI.exitFullScreenMode()
            reactComp.exitFullScreen(callback)
        }
        return
    }
    if (callback) {
        callback()
    }
}

function isComponentAllowedInFullScreenMode(siteAPI, compId) {
    const compAppData = getComponentAppData(siteAPI, compId)
    return _.includes(tpaComponents.common.metaData.PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE, compAppData.appDefinitionId)
}

function setMobileActionBarButton(siteAPI, message, callback) {
    message = message || {}
    const {appDefinitionId} = getComponentAppData(siteAPI, message.compId)
    if (!appDefinitionId) {
        callback({
            error: {
                message: `No matching app definition found for comp id ${message.compId}`
            }
        })
    }

    const messageData = message.data || {}
    const stateToUpdate = getQUABStateToUpdate(messageData)

    const shouldUpdateQUAB = !_.isEmpty(stateToUpdate)
    if (shouldUpdateQUAB) {
        stateToUpdate.appId = appDefinitionId
        getQuickActionBarAspect(siteAPI).updateDynamicActions(stateToUpdate)
    }

    if (callback) {
        callback()
    }
}

function getQUABStateToUpdate(messageData) {
    const stateToUpdate: any = {}

    if (_.isBoolean(messageData.visible)) {
        stateToUpdate.enabled = messageData.visible
    }

    if (_.isBoolean(messageData.notifications)) {
        stateToUpdate.notificationCount = messageData.notifications ? 1 : 0
    }

    if (_.isString(messageData.color)) {
        stateToUpdate.color = messageData.color
    }

    return stateToUpdate
}

function getComponentAppData(siteAspectSiteAPI, compId, optionalPageId?) {
    let appData = null
    const santaComponent = siteAspectSiteAPI.getComponentById(compId, optionalPageId)
    if (santaComponent) {
        const applicationId = _.get(santaComponent.props, 'compData.applicationId') || null
        appData = siteAspectSiteAPI.getSiteData().getClientSpecMapEntry(applicationId) || null
    }
    return appData || {}
}

const getAnchorDataId = function (siteAPI, anchorCompId) {
    const anchorComp = siteAPI.getComponentById(anchorCompId)
    const {compData} = anchorComp.props
    return compData && compData.id
}

const navigateToAnchor = function (siteAPI, msg, onFailure) {
    const pageId = siteAPI.getSiteData().getCurrentUrlPageId()
    const topPageAnchorName = tpaComponents.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
    const pageAnchors = utils.scrollAnchors.getPageAnchors(siteAPI.getSiteData(), pageId, topPageAnchorName)
    anchorHandlers.navigateToAnchor(siteAPI, msg, pageId, pageAnchors, _.partial(getAnchorDataId, siteAPI), onFailure)
}

const getCurrentPageAnchors = function (siteAPI, msg, callback) {
    const pageId = siteAPI.getSiteData().getCurrentUrlPageId()
    const topPageAnchorName = tpaComponents.common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId
    const pageAnchors = utils.scrollAnchors.getPageAnchors(siteAPI.getSiteData(), pageId, topPageAnchorName)
    anchorHandlers.getCurrentPageAnchors(siteAPI, msg, pageId, pageAnchors, callback)
}

const isAppSectionInstalled = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    let appData

    const appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
    if (appDefinitionId) {
        appData = tpaComponents.services.clientSpecMapService.getAppDataByAppDefinitionId(siteData, appDefinitionId)
    } else {
        const comp = siteAPI.getComponentById(msg.compId)
        appData = comp.getAppData()
    }

    const applicationId = _.get(appData, 'applicationId')
    const tpaPages = tpaComponents.services.pageService.mapPageToWidgets(siteAPI)
    const appPages = _.get(tpaPages, applicationId)

    const isInstalled = _.some(appPages, {
        tpaPageId: _.get(msg, ['data', 'sectionId'])
    })
    callback(isInstalled)
}

const getAppVendorProductId = function (siteAPI, msg, callback) {
    const siteData = siteAPI.getSiteData()
    const appDefinitionId = _.get(msg, ['data', 'appDefinitionId'])
    const appData = tpaComponents.services.clientSpecMapService.getAppDataByAppDefinitionId(siteData, appDefinitionId)

    if (!appData) {
        callback(null)
        return
    }

    const instanceParts = appData.instance.split('.')
    const instanceValues = JSON.parse(atob(instanceParts[1]))

    callback(instanceValues.vendorProductId)
}

const hasLoginOnPage = function (siteAPI, msg, callback) {
    const loginCompType = 'wysiwyg.viewer.components.LoginSocialBar'
    const pageId = siteAPI.getSiteData().getFocusedRootId()
    let compsOnPage = siteAPI.getComponentsByPageId(pageId)
    let hasLogin = _.some(compsOnPage, ['props.structure.componentType', loginCompType])
    if (!hasLogin && !siteAPI.isPageLandingPage(pageId) && !siteAPI.getSiteData().isPopupPage(pageId)) {
        compsOnPage = siteAPI.getComponentsByPageId('masterPage')
        hasLogin = _.some(compsOnPage, ['props.structure.componentType', loginCompType])
    }

    callback(hasLogin)
}

const waitForWixCodeWorkerToBeReady = function (siteAPI, msg, callback) {
    const widgetAspect = siteAPI.getSiteAspect('WidgetAspect')
    const siteData = siteAPI.getSiteData()
    const currentPageId = siteData.getCurrentUrlPageId()
    const isContextReadyOrLifeCycleFailed = experiment.isOpen('sv_handleFailingWixCodeSdk', siteData)
        ? widgetAspect.isContextReadyOrLifeCycleFailed(currentPageId)
        : widgetAspect.isContextReady(currentPageId)
    const wixCodeWidgetAspect = siteAPI.getSiteAspect('wixCodeWidgetAspect')
    if (!wixCodeWidgetAspect || !wixCodeWidgetAspect.isAppInitiated()) {
        callback({error: true})
        return
    }
    if (isContextReadyOrLifeCycleFailed) {
        callback({})
    } else {
        widgetAspect.registerToOnWidgetReady(currentPageId, function () {
            callback({})
        })
    }
}

const reportAppStateChangedBiEvent = function (siteAPI, appData, widgetId, compId) {
    const {biData} = siteAPI.getSiteData()
    const time = biData.getTime()

    const eventParams: any = {
        compId,
        pageId: biData.getPageId(),
        pageNo: biData.getPageNumber(),
        loadingTime: time.loadingTime,
        totalLoadingTime: time.totalLoadingTime
    }

    if (_.get(appData, 'isWixTPA')) {
        eventParams.widgetId = widgetId
        eventParams.appDefinitionId = _.get(appData, 'appDefinitionId')
    } else {
        eventParams.externalWidgetId = widgetId
        eventParams.externalAppDefinitionId = _.get(appData, 'appDefinitionId')
    }

    logger.reportBI(siteAPI.getSiteData(), tpaComponents.bi.events.APP_STATE_CHANGED, eventParams)
}

const registerApi = function (siteAPI, msg) {
    const comp = siteAPI.getComponentById(msg.compId)
    const iframeId = _.get(comp.getIframe(), 'id')
    siteAPI.getSiteAspect('TPARPCAspect').registerApi(iframeId, msg.compId)
}

const requireSingletonAPI = function (siteAPI, msg, callback) {
    const appDefId = _.get(msg.data, 'appDefId')
    const componentType = _.get(msg.data, 'componentType')
    siteAPI.getSiteAspect('TPARPCAspect').requireSingletonAPI(appDefId, componentType, callback)
}

const revalidateSession = function (siteAPI, msg, callback) {
    const comp = siteAPI.getComponentById(msg.compId)
    const appData = comp.getAppData()
    const applicationId = _.get(appData, 'applicationId')
    const dynamicClientSpecMapAspect = siteAPI.getSiteAspect('dynamicClientSpecMap')
    dynamicClientSpecMapAspect.getNewAppInstance(applicationId, instance => {
        if (instance) {
            callback({instance})
        } else {
            callback({
                error: {
                    message: 'Error getting new instance from server'
                }
            })
        }
    })
}

const onReady = function (siteAPI, msg, callback) {
    siteAPI.registerToOnFullyRendered(callback)
}

const echo = function (siteAPI, msg, callback) {
    callback()
}

const getApplicationFields = function (siteAPI, msg, callback) {
    const appDefinitionId = _.get(msg.data, 'appDefinitionId')
    const app = _(siteAPI.getSiteData().getClientSpecMap()).values().find({appDefinitionId})
    const appFields = _.get(app, 'appFields', {})
    callback(appFields)
}

export default _.merge(
    {
        waitForWixCodeWorkerToBeReady,
        siteInfo,
        heightChanged,
        registerEventListener,
        removeEventListener,
        navigateTo,
        navigateToPage,
        smRequestLogin,
        smCurrentMember,
        refreshCurrentMember,
        authorizeMemberPages,
        scrollBy,
        scrollTo,
        navigateToComponent,
        registerCampaignPixel,
        reportCampaignEvent,
        postActivity,
        getCurrentPageId,
        getCurrentPageNavigationInfo,
        getUserSession,
        boundingRectAndOffsets,
        navigateToSectionPage,
        getSitePages,
        getSiteMap,
        appIsAlive,
        openModal,
        openPopup,
        openPersistentPopup,
        closeWindow,
        setFullScreenMobile,
        resizeWindow,
        appStateChanged,
        getSectionUrl,
        postCountersReport,
        getExternalId,
        getViewMode,
        getValue,
        getValues,
        getPublicData,
        getCurrentPageAnchors,
        navigateToAnchor,
        getStateUrl,
        getComponentInfo,
        getStyleId,
        getStyleParamsByStyleId,
        replaceSectionState,
        logOutCurrentMember,
        getCtToken,
        setPageMetadata,
        buildCustomizedUrl,
        getCustomizedUrlSegments,
        setAppMetadata,
        removeAppMetadata,
        applicationLoaded: performanceHandlers.applicationLoaded,
        applicationLoadingStep: performanceHandlers.applicationLoadingStep,
        getAdsOnPage,
        setMobileActionBarButton,
        isAppSectionInstalled,
        getAppVendorProductId,
        hasLoginOnPage,
        registerApi,
        requireSingletonAPI,
        trackEvent,
        revalidateSession,
        onReady,
        echo,
        getApplicationFields,

        /* Only handlers available for TPA Worker */
        tpaWorker: {
            siteInfo,
            getSitePages,
            getSiteMap,
            removeEventListener,
            registerEventListener,
            registerCampaignPixel,
            reportCampaignEvent,
            smCurrentMember,
            appIsAlive,
            navigateToSectionPage,
            getValue,
            getValues,
            getPublicData,
            applicationLoaded: performanceHandlers.applicationLoaded,
            applicationLoadingStep: performanceHandlers.applicationLoadingStep,
            isAppSectionInstalled,
            getAppVendorProductId
        }
    },
    tpaDummyHandlers
)
