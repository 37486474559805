import proxyFactory from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxyFactory'
import zoomLink from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/zoomLinkProxy'
import appLink from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/appLinkProxy'
import nativeAppLink from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/nativeAppLinkProxy'
import area from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/areaProxy'
import button from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/buttonProxy'
import button2 from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/button2Proxy'
import clippedParagraph from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/clippedParagraphProxy'
import comboBox from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/comboBoxProxy'
import css from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/cssProxy'
import date from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/dateProxy'
import erasableTextInput from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/erasableTextInputProxy'
import fixedRatio from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/fixedRatioProxy'
import gallery from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/galleryProxy'
import hBox from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/hBoxProxy'
import horizontalLine from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/horizontalLineProxy'
import icon from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/iconProxy'
import imageButton from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/imageButtonProxy'
import imageButtonWithText from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/imageButtonWithTextProxy'
import image from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/imageProxy'
import inlineSpacer from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/inlineSpacerProxy'
import inlineText from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/inlineTextProxy'
import label from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/labelProxy'
import link from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/linkProxy'
import list from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/listProxy'
import mediaLabel from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/mediaLabelProxy'
import paginatedColumnGallery from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/paginatedColumnGalleryProxy'
import paginationProxy from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/paginationProxy'
import sliderGallery from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/sliderGalleryProxy'
import stack from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/stackProxy'
import switchBox from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/switchBoxProxy'
import textArea from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/textAreaProxy'
import textInput from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/textInputProxy'
import toggle from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/toggleProxy'
import vBox from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/vBoxProxy'
import flowList from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/flowListProxy'
import video from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/videoProxy'
import videoThumb from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/videoThumbProxy'
import view from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/viewProxy'
import verticalLine from '@wix/santa-ds-libs/src/wixappsCore/src/proxies/verticalLineProxy'

proxyFactory.register('ZoomLink', zoomLink)
proxyFactory.register('AppLink', appLink)
proxyFactory.register('NativeAppLink', nativeAppLink)
proxyFactory.register('Area', area)
proxyFactory.register('Container', area)
proxyFactory.register('Button2', button2)
proxyFactory.register('Button', button)
proxyFactory.register('ClippedParagraph', clippedParagraph)
proxyFactory.register('ClippedParagraph2', clippedParagraph)
proxyFactory.register('ComboBox', comboBox)
proxyFactory.register('Css', css)
proxyFactory.register('Date', date)
proxyFactory.register('ErasableTextInput', erasableTextInput)
proxyFactory.register('FixedRatioLayout', fixedRatio)
proxyFactory.register('Gallery', gallery)
proxyFactory.register('HBox', hBox)
proxyFactory.register('HorizontalLine', horizontalLine)
proxyFactory.register('Icon', icon)
proxyFactory.register('ImageButton', imageButton)
proxyFactory.register('ImageButtonWithText', imageButtonWithText)
proxyFactory.register('Image', image)
proxyFactory.register('InlineSpacer', inlineSpacer)
proxyFactory.register('InlineText', inlineText)
proxyFactory.register('Label', label)
proxyFactory.register('Link', link)
proxyFactory.register('MediaLabel', mediaLabel)
proxyFactory.register('ColumnGallery', paginatedColumnGallery)
proxyFactory.register('PaginatedColumnGallery', paginatedColumnGallery)
proxyFactory.register('Pagination', paginationProxy)
proxyFactory.register('SliderGallery', sliderGallery)
proxyFactory.register('Stack', stack)
proxyFactory.register('SwitchBox', switchBox)
proxyFactory.register('TextArea', textArea)
proxyFactory.register('TextInput', textInput)
proxyFactory.register('Toggle', toggle)
proxyFactory.register('VBox', vBox)
proxyFactory.register('VerticalList', list)
proxyFactory.register('List2', list)
proxyFactory.register('PaginatedList', list)
proxyFactory.register('FlowList', flowList)
proxyFactory.register('Video', video)
proxyFactory.register('VideoThumb', videoThumb)
proxyFactory.register('View', view)
proxyFactory.register('VerticalLine', verticalLine)
