import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {PageAPI} from '@wix/document-manager-extensions/src/extensions/page'
import type {DataFixerVersioningApi} from '@wix/document-manager-extensions/src/extensions/dataFixerVersioning/dataFixerVersioning'
import type {FixerActions, FixerNameToVersion} from '@wix/document-services-types'
import {defaultRunAlways, defaultRunOnce, defaultMasterPageMigrations, defaultRunOncePerSite} from './migrators'

export enum FixerCategory {
    VIEWER = 'viewer_fixer',
    MIGRATOR = 'migration_fixer',
    DS = 'ds_fixer'
}

interface BaseMigrator {
    name: string
    version: number
    disableVersioning?: boolean
    disableFixerAfterFirstRun?: boolean
}

export interface SiteMigrator extends BaseMigrator {
    migrateSite(documentManager: DocumentManager): void
}

export interface MasterPageMigrator extends BaseMigrator {
    migrateMasterPage(documentManager: DocumentManager): void
}

export interface PageMigrator extends BaseMigrator {
    migratePage(documentManager: DocumentManager, pageId: string): void
}

export interface DataMigrationRunner {
    runDataMigration(documentManager: DocumentManager, fixerChangesOnReruns?: FixerActions): void
}

const getPageList = (documentManager: DocumentManager): string[] => {
    const pageAPI = documentManager.extensionAPI.page as PageAPI
    return pageAPI.getAllPagesIds(true)
}

const createDataMigrationRunner = (
    runAlways: PageMigrator[] = defaultRunAlways,
    runOnce: PageMigrator[] = defaultRunOnce,
    runOncePerSite: SiteMigrator[] = defaultRunOncePerSite,
    masterPageMigrators: MasterPageMigrator[] = defaultMasterPageMigrations
): DataMigrationRunner => {
    const runDataMigration = (documentManager: DocumentManager): void => {
        const {dataFixerVersioning} = documentManager.extensionAPI as DataFixerVersioningApi
        const fixerChangesOnReruns: FixerActions = {}

        const pageIds = getPageList(documentManager).filter(pageId => (documentManager.extensionAPI.page as PageAPI).hasPageBeenLoaded(pageId))

        const addFixerVersion = (fixerVersions: FixerNameToVersion, migrator: BaseMigrator) => {
            const {name, version, disableVersioning} = migrator
            if (!disableVersioning) {
                fixerVersions[name] = version
            }
        }

        const migratePage = (migrator: PageMigrator, pageId: string, fixerVersions: FixerNameToVersion) => {
            if (dataFixerVersioning.hasFixerRunOnCurrentVersion(pageId, FixerCategory.MIGRATOR, migrator.name, migrator.version)) {
                if (migrator.disableFixerAfterFirstRun) {
                    return
                }

                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migratePage(documentManager, pageId),
                    pageId,
                    migrator.name,
                    migrator.version
                )

                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator)
                migrator.migratePage(documentManager, pageId)
            }
        }

        const migrateMasterPage = (migrator: MasterPageMigrator, fixerVersions: FixerNameToVersion) => {
            const pageId = 'masterPage'
            if (dataFixerVersioning.hasFixerRunOnCurrentVersion(pageId, FixerCategory.MIGRATOR, migrator.name, migrator.version)) {
                if (migrator.disableFixerAfterFirstRun) {
                    return
                }

                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migrateMasterPage(documentManager),
                    pageId,
                    migrator.name,
                    migrator.version
                )

                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator)
                migrator.migrateMasterPage(documentManager)
            }
        }

        const masterPageId = 'masterPage'
        pageIds.forEach(pageId => {
            const fixerVersions: FixerNameToVersion = {}
            const pageMigrators = runOnce.concat(runAlways)

            pageMigrators.forEach(migrator => {
                migratePage(migrator, pageId, fixerVersions)
            })

            if (pageId === masterPageId) {
                masterPageMigrators.forEach(masterPageMigrator => {
                    migrateMasterPage(masterPageMigrator, fixerVersions)
                })
            }

            dataFixerVersioning.updatePageVersionData(pageId, {[FixerCategory.MIGRATOR]: fixerVersions})
        })

        const fixerVersions: FixerNameToVersion = {}
        runOncePerSite.forEach(migrator => {
            const {name: fixerName, version: fixerVersion, disableFixerAfterFirstRun} = migrator
            if (dataFixerVersioning.hasFixerRunOnCurrentVersion(masterPageId, FixerCategory.MIGRATOR, fixerName, fixerVersion)) {
                if (disableFixerAfterFirstRun) {
                    return
                }
                const changes = dataFixerVersioning.executeFixerAndSetModifications(
                    () => migrator.migrateSite(documentManager),
                    '_site_',
                    fixerName,
                    fixerVersion
                )
                _.merge(fixerChangesOnReruns, changes)
            } else {
                addFixerVersion(fixerVersions, migrator)
                migrator.migrateSite(documentManager)
            }
        })
        dataFixerVersioning.updatePageVersionData(masterPageId, {[FixerCategory.MIGRATOR]: fixerVersions})
        dataFixerVersioning.reportFixerActions(FixerCategory.MIGRATOR, fixerChangesOnReruns)
    }

    return {
        runDataMigration
    }
}

export {createDataMigrationRunner}
