import wixCodeAppApi from '@wix/santa-ds-libs/src/platformInit/src/api/wixCodeAppApi'
import initMainR from '@wix/santa-ds-libs/src/platformInit/src/api/initMainR'
import specMapUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/specMapUtils'
import appsUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/appsUtils'
import widgetsPreLoader from '@wix/santa-ds-libs/src/platformInit/src/utils/widgetsPreLoader'
import hostWorkerInit from '@wix/santa-host-platform-services/dist/host-worker-init'
import wixCodeUrlUtils from '@wix/santa-ds-libs/src/platformInit/src/utils/wixCodeUrlUtils'

const {messageProxy} = hostWorkerInit
export default {
    getAppApi: wixCodeAppApi.getApi,
    initMainR,
    specMapUtils,
    appsUtils,
    messageProxy,
    widgetsPreLoader,
    registerDeps: widgetsPreLoader.registerDeps,
    wixCodeUrlUtils
}
