define([], function () {
    'use strict'

    const ROUTERS_PATH = ['routers']
    const ROUTERS_CONFIG_MAP_PATH = ROUTERS_PATH.concat('configMap')

    const paths = {
        ROUTERS_PATH,
        ROUTERS_CONFIG_MAP: ROUTERS_CONFIG_MAP_PATH
    }

    return {
        ROUTER_TYPE: 'router',
        paths,
        routerPrefixPath: 'prefix',
        routerPagesPath: 'pages',
        InvalidPrefixReason: {
            PREFIX_CAN_NOT_BE_EMPTY: 1,
            PREFIX_IS_TOO_LONG: 2,
            PREFIX_IS_DUPLICATE_OF_URI_SEO: 3,
            PREFIX_CONTAINS_INVALID_CHARACTERS: 4,
            PREFIX_IS_FORBIDDEN_WORD: 5,
            PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION: 6
        }
    }
})
