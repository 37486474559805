define([
    'lodash',
    'documentServices/utils/utils',
    'documentServices/variants/variants',
    'documentServices/component/component',
    'documentServices/features/features',
    'documentServices/dataModel/dataModel',
    'documentServices/appStudio/nameGenerator',
    'documentServices/variants/transformations',
    'documentServices/appStudio/appStudioDataModel',
    'documentServices/responsiveLayout/responsiveLayout',
    'documentServices/refComponent/refComponent',
    'documentServices/refComponent/refComponentUtils',
    'documentServices/constants/constants',
    'documentServices/component/componentStylesAndSkinsAPI',
    'documentServices/variables/variables'
], function (
    _,
    utils,
    variants,
    component,
    features,
    dataModel,
    nameGenerator,
    transformations,
    appStudioDataModel,
    responsiveLayout,
    refComponent,
    refComponentUtils,
    constants,
    componentStylesAndSkinsAPI,
    variables
) {
    'use strict'

    const PRESET_TYPE = 'PresetDescriptor'
    const NEW_PRESET_PREFIX = 'Design Preset '
    const MAX_PRESET_NAME_LENGTH = 100

    const addPresetsToWidgetDataItem = (ps, presetsPointers, widgetPointer) => {
        const presetsPointersArray = _.isArray(presetsPointers) ? presetsPointers : [presetsPointers]
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)

        if (widgetData) {
            const presetsIdsArray = _.map(presetsPointersArray, presetPointer => `#${presetPointer.id}`)
            widgetData.presets = [...(widgetData.presets || []), ...presetsIdsArray]
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        }
    }

    const addDuplicatedPresetToWidgetDataItem = (ps, presetPointer, originalPresetPointer, widgetPointer) => {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)

        if (widgetData) {
            const originalPresetIndex = _.findIndex(widgetData.presets, preset => preset === `#${originalPresetPointer.id}`)
            widgetData.presets.splice(originalPresetIndex + 1, 0, `#${presetPointer.id}`)
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        }
    }

    const removePresetFromWidgetDataItem = (ps, presetPointer, widgetPointer) => {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer)

        if (widgetData) {
            widgetData.presets = _.filter(widgetData.presets, item => item !== `#${presetPointer.id}`)
            appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        }
    }

    const addInitialScopedDataToVariant = (ps, variantPointer, widgetPointer) => {
        const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
        const pageVariantRef = variants.getPointerWithVariants(ps, pageRef, variantPointer)

        transformations.updateTransformationsData(ps, pageVariantRef, {rotate: 0})
    }

    const createPresetVariant = (ps, widgetPointer) => {
        const presetType = variants.getPresetType()
        const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
        const variantToAddRef = variants.getVariantToAddRef(ps, pageRef, presetType)
        variants.create(ps, variantToAddRef, pageRef, presetType)

        addInitialScopedDataToVariant(ps, variantToAddRef, widgetPointer)

        return variantToAddRef
    }

    const removePresetVariant = (ps, presetPointer, widgetPointer) => {
        const variantPointer = getPresetVariantPointer(ps, presetPointer, widgetPointer)
        variants.remove(ps, variantPointer)
    }

    const generateNewPresetName = (ps, prefix, widgetPointer) => {
        const widgetPresets = getWidgetPresets(ps, widgetPointer)
        return nameGenerator.generateName(widgetPresets, prefix)
    }

    const generateDuplicatedPresetName = originalPresetName => {
        const duplicatedPresetName = `Copy of ${originalPresetName}`
        return duplicatedPresetName.substr(0, MAX_PRESET_NAME_LENGTH)
    }

    const validatePresetName = presetName => {
        if (_.isEmpty(presetName)) {
            throw new Error('appStudio.presets: Preset name is required')
        }

        if (presetName.length > MAX_PRESET_NAME_LENGTH) {
            throw new Error('appStudio.presets: Preset name is too long')
        }
    }

    const createBlankPresetData = (ps, presetPointer, presetName, variantId) => {
        const presetDataItem = dataModel.createDataItemByType(ps, PRESET_TYPE)
        presetDataItem.id = presetPointer.id
        presetDataItem.name = presetName
        presetDataItem.presetId = `#${variantId}`

        return presetDataItem
    }

    const createDuplicatedPresetData = (ps, presetPointer, originalPresetPointer, presetName, variantId) => {
        const presetData = createBlankPresetData(ps, presetPointer, presetName, variantId)
        const originalPresetData = appStudioDataModel.getData(ps, originalPresetPointer)

        if (_.get(originalPresetData, 'defaultSize')) {
            presetData.defaultSize = originalPresetData.defaultSize
        }

        return presetData
    }

    const createDuplicatedPreset = (ps, presetPointer, originalPresetPointer, widgetPointer, newPresetName) => {
        const variantPointer = createPresetVariant(ps, widgetPointer)
        const originalVariantPointer = getPresetVariantPointer(ps, originalPresetPointer, widgetPointer)

        const originalPresetName = getPresetName(ps, originalPresetPointer)
        const presetName = newPresetName || generateDuplicatedPresetName(originalPresetName)
        validatePresetName(presetName)

        const presetData = createDuplicatedPresetData(ps, presetPointer, originalPresetPointer, presetName, variantPointer.id)
        appStudioDataModel.setData(ps, presetPointer, presetData)

        copyPresetScopedData(ps, variantPointer, originalVariantPointer, widgetPointer)
    }

    const copyOverridesBetweenVariants = (ps, refComp, originalVariantPointer, destinationVariantPointer) => {
        const pageRef = ps.pointers.components.getPageOfComponent(refComp)

        const overriddenDataArr = refComponentUtils.getOverriddenData(ps, refComp)
        _.forEach(overriddenDataArr, overriddenData => {
            if (overriddenData.itemType === 'style') {
                const compRef = ps.pointers.components.getComponent(overriddenData.compId, pageRef)
                const originalComp = refComponent.getUniqueRefCompPointer(ps, refComp, compRef)
                const originalCompVariantRef = variants.getPointerWithVariants(ps, originalComp, originalVariantPointer)
                const compVariantRef = variants.getPointerWithVariants(ps, originalComp, destinationVariantPointer)

                const originalScopedStyle = componentStylesAndSkinsAPI.style.get(ps, originalCompVariantRef)
                if (originalScopedStyle) {
                    componentStylesAndSkinsAPI.style.update(ps, compVariantRef, originalScopedStyle)
                }
            }
        })
    }

    const copyVariablesScopedData = (ps, widgetPointer, originalVariantPointer, variantPointer) => {
        const widgetRef = appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
        if (!widgetRef) {
            return
        }
        const originalCompVariantRef = variants.getPointerWithVariants(ps, widgetRef, originalVariantPointer)
        const compVariantRef = variants.getPointerWithVariants(ps, widgetRef, variantPointer)

        const widgetVariablePointers = variables.getComponentVariablesList(ps, widgetRef)
        widgetVariablePointers.forEach(variablePointer => {
            const variableData = variables.getVariableData(ps, originalCompVariantRef, variablePointer)
            if (variableData) {
                variables.updateVariableData(ps, compVariantRef, variablePointer, variableData)
            }
        })
    }

    const copyPresetScopedData = (ps, variantPointer, originalVariantPointer, widgetPointer) => {
        const pageRef = appStudioDataModel.getPageByWidgetPointer(ps, widgetPointer)
        const allCompRefs = component.getChildrenFromFull(ps, pageRef, true)

        _.forEach(allCompRefs, compRef => {
            const originalCompVariantRef = variants.getPointerWithVariants(ps, compRef, originalVariantPointer)
            const compVariantRef = variants.getPointerWithVariants(ps, compRef, variantPointer)

            if (refComponentUtils.isRefHost(ps, compRef)) {
                const originalScopedPreset = features.getFeatureData(ps, originalCompVariantRef, constants.DATA_TYPES.presets)
                if (originalScopedPreset) {
                    features.updateFeatureData(ps, compVariantRef, constants.DATA_TYPES.presets, originalScopedPreset)
                }

                copyOverridesBetweenVariants(ps, compRef, originalVariantPointer, variantPointer)
            }

            const originalScopedStyle = componentStylesAndSkinsAPI.style.get(ps, originalCompVariantRef)
            if (originalScopedStyle) {
                componentStylesAndSkinsAPI.style.update(ps, compVariantRef, originalScopedStyle)
            }

            const originalScopedLayout = responsiveLayout.get(ps, originalCompVariantRef)
            if (originalScopedLayout) {
                responsiveLayout.update(ps, compVariantRef, _.omit(originalScopedLayout, 'id'))
            }
        })

        copyVariablesScopedData(ps, widgetPointer, originalVariantPointer, variantPointer)
    }

    const getWidgetPresets = (ps, widgetPointer) => {
        if (widgetPointer) {
            const widgetData = appStudioDataModel.getData(ps, widgetPointer) || {}
            return _.map(widgetData.presets, presetId => {
                const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(presetId))
                const data = appStudioDataModel.getData(ps, pointer)
                return {
                    pointer,
                    name: _.get(data, 'name')
                }
            })
        }
        return []
    }

    const createPreset = (ps, presetPointer, widgetPointer, options = {}) => {
        if (!widgetPointer) {
            throw new Error('appStudio.presets: Invalid arguments')
        }

        const widgetPresets = getWidgetPresets(ps, widgetPointer)
        const isFirstPreset = _.isEmpty(widgetPresets)

        const presetName = options.newPresetName || generateNewPresetName(ps, NEW_PRESET_PREFIX, widgetPointer)
        validatePresetName(presetName)

        if (isFirstPreset) {
            const variantPointer = createPresetVariant(ps, widgetPointer)
            const presetData = createBlankPresetData(ps, presetPointer, presetName, variantPointer.id)
            appStudioDataModel.setData(ps, presetPointer, presetData)
        } else {
            const firstPresetPointer = _.get(_.head(widgetPresets), 'pointer')
            createDuplicatedPreset(ps, presetPointer, firstPresetPointer, widgetPointer, presetName)
        }
        addPresetsToWidgetDataItem(ps, presetPointer, widgetPointer)
        options.callback?.(presetPointer)
    }

    const getDefaultPresetVariantId = (ps, widgetPointer) => {
        const [firstPreset] = getWidgetPresets(ps, widgetPointer)

        return getPresetVariantId(ps, firstPreset.pointer)
    }

    const changePresetDataIfNeeded = (ps, refComp, oldPresetVariant, newPresetVariant) => {
        const presetData = features.getFeatureData(ps, refComp, constants.DATA_TYPES.presets)

        if (!presetData) {
            return
        }

        if (presetData.layout === oldPresetVariant || presetData.style === oldPresetVariant) {
            features.updateFeatureData(ps, refComp, constants.DATA_TYPES.presets, {
                layout: presetData.layout === oldPresetVariant ? newPresetVariant : presetData.layout,
                style: presetData.style === oldPresetVariant ? newPresetVariant : presetData.style,
                type: constants.PRESETS.PRESET_DATA_TYPE
            })
        }
    }

    const fixInnerWidgetsPresetData = (ps, innerWidgets, removedPresetVariantId, defaultVariantId) => {
        innerWidgets.forEach(innerWidgetPointer => {
            changePresetDataIfNeeded(ps, innerWidgetPointer, removedPresetVariantId, defaultVariantId)

            const affectingVariants = variants.getAllAffectingVariantsForPresets(ps, innerWidgetPointer)
            affectingVariants.forEach(variant => {
                const scopedInnerWidgetPointer = variants.getPointerWithVariants(ps, innerWidgetPointer, variant)
                changePresetDataIfNeeded(ps, scopedInnerWidgetPointer, removedPresetVariantId, defaultVariantId)
            })
        })
    }

    const fixInnerWidgetPresetInOtherWidgets = (ps, widgetPointer, removedPresetPointer) => {
        const defaultVariantId = getDefaultPresetVariantId(ps, widgetPointer)

        const removedPresetVariantId = getPresetVariantId(ps, removedPresetPointer)
        const allWidgets = appStudioDataModel.getAllWidgets(ps)

        allWidgets.forEach(({pointer: currentWidgetPointer}) => {
            const innerWidgets = appStudioDataModel.getFirstLevelRefChildren(ps, currentWidgetPointer)

            fixInnerWidgetsPresetData(ps, innerWidgets, removedPresetVariantId, defaultVariantId)
        })
    }

    const removePreset = (ps, presetPointer, widgetPointer) => {
        if (!presetPointer || !widgetPointer) {
            throw new Error('appStudio.presets: Invalid arguments')
        }

        removePresetFromWidgetDataItem(ps, presetPointer, widgetPointer)
        removePresetVariant(ps, presetPointer, widgetPointer)
        fixInnerWidgetPresetInOtherWidgets(ps, widgetPointer, presetPointer)
    }

    const duplicatePreset = (ps, presetPointer, originalPresetPointer, widgetPointer, options = {}) => {
        if (!originalPresetPointer || !widgetPointer) {
            throw new Error('appStudio.presets: Invalid arguments')
        }

        createDuplicatedPreset(ps, presetPointer, originalPresetPointer, widgetPointer, options.newPresetName)
        addDuplicatedPresetToWidgetDataItem(ps, presetPointer, originalPresetPointer, widgetPointer)
        options.callback?.(presetPointer)
    }

    const getPresetVariantId = (ps, presetPointer) => {
        const presetData = appStudioDataModel.getData(ps, presetPointer)
        return utils.stripHashIfExists(_.get(presetData, 'presetId'))
    }

    const getPresetVariantPointer = (ps, presetPointer, widgetPointer) => {
        const presetVariantId = getPresetVariantId(ps, presetPointer)
        const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
        return ps.pointers.data.getVariantsDataItem(presetVariantId, rootCompId)
    }

    const getPresetDefaultSize = (ps, presetPointer) => {
        const presetData = appStudioDataModel.getData(ps, presetPointer)
        return _.get(presetData, 'defaultSize')
    }

    const getPresetName = (ps, presetPointer) => {
        const presetData = appStudioDataModel.getData(ps, presetPointer)
        return _.get(presetData, 'name')
    }

    const setPresetDefaultSize = (ps, presetPointer, defaultSize) => {
        appStudioDataModel.mergeData(ps, presetPointer, {defaultSize})
    }

    const setPresetName = (ps, presetPointer, name) => {
        validatePresetName(name)
        appStudioDataModel.mergeData(ps, presetPointer, {name})
    }

    const displayPreset = (ps, presetPointer, widgetPointer) => {
        const variantPointer = getPresetVariantPointer(ps, presetPointer, widgetPointer)
        variants.enable(ps, variantPointer)
    }

    return {
        createPreset,
        removePreset,
        displayPreset,
        setPresetName,
        getPresetName,
        duplicatePreset,
        getWidgetPresets,
        getPresetVariantId,
        getPresetVariantPointer,
        setPresetDefaultSize,
        getPresetDefaultSize,
        createDuplicatedPresetData,
        addPresetsToWidgetDataItem
    }
})
