define(['documentServices/documentServicesDataFixer/fixers/utils/variantThemeUtils'], function (variantThemeUtils) {
    'use strict'

    return {
        componentsClasses: ['wysiwyg.viewer.components.svgshape.SvgShape', 'wysiwyg.viewer.components.PopupCloseIconButton'],
        collect(ps, comp) {
            const styles = []

            variantThemeUtils.applyToEachComponentStyleAndReference(ps, comp, style => styles.push(style.id))

            return styles
        }
    }
})
