define([
    'lodash',
    'documentServices/hooks/hooks',
    'documentServices/platform/livePreview/appFinder',
    'documentServices/platform/livePreview/appFilters',
    'documentServices/dataModel/dataModel',
    'documentServices/connections/connections',
    'documentServices/extensionsAPI/extensionsAPI',
    'experiment'
], function (_, hooks, appFinder, appFilters, dataModel, connections, extensionsAPI, experiment) {
    'use strict'

    const notifyLivePreviewStateIfNeeded = (ps, effectiveOptions) => {
        const shouldSync = _.get(effectiveOptions, ['sharedStateSyncOptions', 'shouldSync'])
        if (shouldSync) {
            ps.setOperationsQueue.runSetOperation(() => {
                extensionsAPI.livePreviewSharedState.notifyLivePreviewDataChanged(ps, effectiveOptions)
            })
        }
    }

    const refreshAppsInternal = (ps, effectiveOptions) => {
        if (effectiveOptions.immediate && effectiveOptions.shouldRunInQueue !== false) {
            ps.setOperationsQueue.runSetOperation(
                () => {
                    ps.siteAPI.refreshAppsInCurrentPage(effectiveOptions)
                },
                [],
                {enforceType: 'GENERATE_AND_ENFORCE'}
            )
        } else {
            ps.siteAPI.refreshAppsInCurrentPage(effectiveOptions)
        }
    }

    const refreshAfterDataOrPropertyUpdate = (hookName, ps, componentPointer, data) => {
        const controllersToRefresh = getComponentsControllers(ps, componentPointer, true)
        if (_.isEmpty(controllersToRefresh)) {
            return
        }
        const compData = data || dataModel.getDataItem(ps, componentPointer)
        const options = getOptionsWithAllAppsModifiers(ps, componentPointer, _.get(hooks.HOOKS, hookName))
        refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, compData, componentPointer), hookName, componentPointer, _.assign({controllersToRefresh}, options))
    }

    /**
     * @param {ps} ps
     * @param {Object} options
     * @param [originatingCmpPtr] - optional, used to customize options according to originating comp
     */
    function refresh(ps, options, originatingCmpPtr) {
        if (!isActive(ps)) {
            return
        }
        options.apps = _.intersection(options.apps, ps.siteAPI.getAllowedApps())
        options.apps = appFilters.addDependantApps(ps, options.apps)
        if (!options.skipAppsCheck && _.isEmpty(options.apps)) {
            return
        }

        const effectiveOptions = extendOptionsByApp(ps, options, originatingCmpPtr)
        refreshAppsInternal(ps, effectiveOptions)
        notifyLivePreviewStateIfNeeded(ps, effectiveOptions)
    }

    function refreshAppsAPI(ps, {apps, source, shouldFetchData}) {
        const options = _.assign(appFilters.optionsModifierSelfRefresh(ps, apps), {
            source,
            shouldFetchData,
            sendInitAndStart: true,
            sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
        })

        if (!_.isEmpty(options.controllersToRefresh) && !_.isEmpty(options.compsIdsToReset)) {
            refresh(ps, options)
        }
    }

    function extendOptionsByApp(ps, options, componentPointer) {
        if (!options || !options.apps) {
            return options
        }
        return options.apps.reduce((res, app) => {
            const changeFromApp = _.get(appFilters.optionsModifiers, app, _.noop)(ps, componentPointer)
            return _.assign(res, changeFromApp)
        }, options)
    }

    function refreshWhenAppsNotEmpty(ps, apps, source, componentPointer, extraOptions) {
        const options = _.assign({apps, source, sendInitAndStart: true}, extraOptions || {})
        if (!_.isEmpty(apps)) {
            refresh(ps, options, componentPointer)
        }
    }

    function refreshAllApps(ps, options) {
        refresh(ps, _.assign(options, {apps: ps.siteAPI.getAllowedApps()}))
    }

    function isActive(ps) {
        const enabledEditors = {
            'Editor1.4': true,
            editor_x: experiment.isOpen('bv_livePreview_x'),
            onboarding: experiment.isOpen('bv_livePreview_adi')
        }
        return experiment.isOpen('sv_livePreview') && enabledEditors[ps.config.origin]
    }

    let registeredHooks

    function registerHook(hookName, cb) {
        registeredHooks[hookName] = hooks.registerHook(hookName, cb)
    }

    function unregisterHooks() {
        _.forEach(registeredHooks, (hookValue, hookKey) => hooks.unregisterHook(hookKey, hookValue))
        registeredHooks = Object.create(null)
    }

    function getOptionsWithAllAppsModifiers(ps, componentPointer, hook) {
        const options = _.get(appFilters.optionsModifiersAllApps, hook, _.noop)(ps, componentPointer)
        _.assign(options, {sharedStateSyncOptions: getGenericSharedStateComponentOptions(ps, componentPointer)})
        return options
    }

    const getGenericSharedStateComponentOptions = (ps, compPointer) => ({
        shouldSync: true,
        pageId: _.get(ps.pointers.full.components.getPageOfComponent(compPointer), 'id')
    })

    function getComponentsControllers(ps, componentPointer, excludeOOIControllers) {
        const compControllers = []
        const componentType = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'componentType'))
        const isIncludedOOIController = connections.isOOIController(componentType) && !excludeOOIControllers
        if (connections.isControllerType(componentType) || isIncludedOOIController) {
            const controllerData = dataModel.getDataItem(ps, componentPointer)
            compControllers.push(controllerData.id)
        }
        const connectionItemPointer = dataModel.getConnectionsItemPointer(ps, componentPointer)
        if (!connectionItemPointer) {
            return compControllers
        }
        const componentConnections = ps.dal.get(connectionItemPointer)
        if (!componentConnections || !componentConnections.items) {
            return compControllers
        }
        return _.map(_.filter(componentConnections.items, {type: 'ConnectionItem'}), 'controllerId').concat(compControllers)
    }

    function isDynamicPageDataChanged(pageInfo, prevPageInfo) {
        if (_.isObject(pageInfo) && _.isObject(prevPageInfo) && prevPageInfo.routerDefinition && pageInfo.routerDefinition) {
            return (
                pageInfo.innerRoute === prevPageInfo.innerRoute &&
                pageInfo.routerDefinition.routerId === prevPageInfo.routerDefinition.routerId &&
                !_.isEqual(pageInfo.routerDefinition, prevPageInfo.routerDefinition)
            )
        }
        return false
    }

    function autoRun(ps) {
        extensionsAPI.livePreviewSharedState.subscribeToLivePreviewDataChanges(ps, livePreviewOptions => {
            const syncByPageId = _.get(livePreviewOptions, ['sharedStateSyncOptions', 'pageId'])
            const currentPageId = ps.siteAPI.getPrimaryPageId()
            if (!syncByPageId || syncByPageId === currentPageId || syncByPageId === 'masterPage') {
                refreshAppsInternal(ps, livePreviewOptions)
            }
        })
        unregisterHooks()

        registerHook(hooks.HOOKS.DATA.UPDATE_AFTER, (_ps, componentPointer, data) => {
            refreshAfterDataOrPropertyUpdate('DATA.UPDATE_AFTER', ps, componentPointer, data)
        })

        registerHook(hooks.HOOKS.PROPERTIES.UPDATE_AFTER, (_ps, componentPointer) => {
            refreshAfterDataOrPropertyUpdate('PROPERTIES.UPDATE_AFTER', ps, componentPointer)
        })

        registerHook(hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS, (_ps, data, componentPointer) => {
            const controllersToRefresh = getComponentsControllers(ps, componentPointer)
            const options = getOptionsWithAllAppsModifiers(ps, componentPointer, hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS)
            const dataItem = dataModel.getDataItem(_ps, componentPointer)
            refreshWhenAppsNotEmpty(
                ps,
                appFinder.appsOfComp(ps, dataItem, componentPointer),
                'AFTER_UPDATE_CONNECTIONS',
                componentPointer,
                _.assign({controllersToRefresh}, options)
            )
        })

        registerHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, (_ps, componentPointer, controllerRef) => {
            const controllerData = dataModel.getDataItem(ps, controllerRef)
            const controllersToRefresh = getComponentsControllers(ps, componentPointer).concat(controllerData.id)
            const options = getOptionsWithAllAppsModifiers(ps, componentPointer, hooks.HOOKS.CONNECTION.AFTER_DISCONNECT)
            refreshWhenAppsNotEmpty(
                ps,
                [appFinder.getAppOfController(ps, controllerRef)],
                'AFTER_DISCONNECT',
                componentPointer,
                _.assign({controllersToRefresh}, options)
            )
        })

        registerHook(hooks.HOOKS.DATA.SET_BY_POINTER_AFTER, (_ps, dataItemPointer, data) =>
            refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, data, dataItemPointer), 'SET_BY_POINTER_AFTER', null, {
                sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
            })
        )

        registerHook(hooks.HOOKS.ADD_ROOT.AFTER, (_ps, componentPointer) =>
            refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, null, componentPointer), 'ADD_ROOT.AFTER', null, {
                sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
            })
        )

        registerHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.AFTER, (_ps, viewMode) => {
            if (viewMode === 'editor') {
                refreshAllApps(ps, {
                    immediate: true,
                    restartWorker: true,
                    skipAppsCheck: !!appFilters.hasAppsWithViewerScript(ps),
                    source: 'CHANGE_COMPONENT_VIEW_MODE',
                    sharedStateSyncOptions: {shouldSync: false}
                })
            } else {
                refreshAllApps(ps, {
                    sendLoad: true,
                    sendInitAndStart: true,
                    skipAppsCheck: !!appFilters.hasAppsWithViewerScript(ps),
                    source: 'CHANGE_COMPONENT_VIEW_MODE',
                    sharedStateSyncOptions: {shouldSync: false}
                })
            }
        })

        registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, (_ps, componentPointer) => {
            if (ps.dal.get(componentPointer)) {
                refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, null, componentPointer), 'CHANGE_PARENT', componentPointer, {
                    immediate: true,
                    sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
                })
            } else {
                refreshAllApps(ps, {
                    resetRuntime: true,
                    sendInitAndStart: true,
                    source: 'CHANGE_PARENT',
                    sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
                })
            }
        })

        registerHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE, () =>
            refreshAllApps(ps, {source: 'AFTER_NAVIGATE_TO_PAGE', immediate: true, shouldRunInQueue: false, sharedStateSyncOptions: {shouldSync: false}})
        )
        registerHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE_DONE, (_ps, pageInfo, prevPageInfo) => {
            const isDataChanged = isDynamicPageDataChanged(pageInfo, prevPageInfo)
            if (isDataChanged) {
                refreshAllApps(ps, {
                    source: 'AFTER_NAVIGATE_TO_PAGE_DONE',
                    immediate: true,
                    sendLoad: true,
                    sendInitAndStart: true,
                    resetRuntime: true,
                    sharedStateSyncOptions: {shouldSync: false}
                })
            }
        })

        registerHook(hooks.HOOKS.WIXCODE.UPDATE_MODEL, () =>
            refreshAllApps(ps, {
                source: 'WIXCODE.UPDATE_MODEL',
                immediate: true,
                sendLoad: true,
                sendInitAndStart: true,
                resetRuntime: true,
                sharedStateSyncOptions: {shouldSync: false}
            })
        )

        registerHook(hooks.HOOKS.ROUTER.DATA_RELOADED, () =>
            refreshAllApps(ps, {
                source: 'DATA_RELOADED',
                immediate: true,
                sendLoad: true,
                sendInitAndStart: true,
                resetRuntime: true,
                sharedStateSyncOptions: {shouldSync: false}
            })
        )
        registerHook(hooks.HOOKS.MULTILINGUAL.AFTER_CHANGE_LANGUAGE, () =>
            refreshAllApps(ps, {source: 'AFTER_CHANGE_LANGUAGE', immediate: true, shouldRunInQueue: false, sharedStateSyncOptions: {shouldSync: false}})
        )

        registerHook(hooks.HOOKS.SWITCH_VIEW_MODE.AFTER, () =>
            refreshAllApps(ps, {restartWorker: true, source: 'SWITCH_VIEW_MODE', sharedStateSyncOptions: {shouldSync: false}})
        )
        registerHook(hooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, () =>
            refreshAllApps(ps, {
                restartWorker: true,
                source: 'UNDO_REDO.AFTER_APPLY_SNAPSHOT',
                shouldFetchData: true,
                sharedStateSyncOptions: {shouldSync: true}
            })
        )
        // registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, () => refreshAllApps(ps, {restartWorker: true, resetRuntime: true, source: 'PLATFORM.APP_UPDATED'}))
        // registerHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, () => refreshAllApps(ps, {restartWorker: true, resetRuntime: true, source: 'PLATFORM.APP_PROVISIONED'}))
    }
    return {
        debouncedRefresh: refreshAppsAPI,
        isActive,
        autoRun
    }
})
