define(['lodash', '@wix/mobile-conversion'], function (_, mobileCore) {
    'use strict'
    function extractNonLayoutComponentsFromStructure(root) {
        const children = mobileCore.conversionUtils.getChildren(root)
        const nonLayoutChildren = _.remove(children, isNonLayoutComponent)
        return _.concat(nonLayoutChildren, _.flatMap(children, extractNonLayoutComponentsFromStructure))
    }
    function removeNonLayoutComponentsFromStructure(root, nonLayoutComponents) {
        const children = mobileCore.conversionUtils.getChildren(root)
        _.remove(children, comp => _.some(nonLayoutComponents, ['id', comp.id]))
        _.forEach(children, comp => removeNonLayoutComponentsFromStructure(comp, nonLayoutComponents))
    }
    function getRootForNonLayoutComponents(page) {
        const popupContainerCompType = 'wysiwyg.viewer.components.PopupContainer'
        const children = mobileCore.conversionUtils.getChildren(page)
        if (children.length === 1 && children[0].componentType === popupContainerCompType) {
            return children[0]
        }
        return page
    }
    function insertNonLayoutComponentsToStructure(root, comps) {
        _.forEach(comps, comp =>
            _.assign(comp.layout, {
                width: 0,
                height: 0,
                x: 0,
                y: 0
            })
        )
        mobileCore.conversionUtils.addComponentsTo(root, comps)
    }
    function isNonLayoutComponent(comp) {
        const desktopOnlyComponent = _.get(comp, ['conversionData', 'desktopOnly'], false)
        const nonLayoutComponent = _.get(comp, ['conversionData', 'nonLayoutComponent'], false)
        return !desktopOnlyComponent && nonLayoutComponent
    }
    function extractNonLayoutComponentsFromPages(page, mobilePage) {
        const nonLayoutComponents = extractNonLayoutComponentsFromStructure(page)
        if (mobilePage && !_.isEmpty(nonLayoutComponents)) {
            // just remove comps from mobile - if needed they will be added later
            removeNonLayoutComponentsFromStructure(mobilePage, nonLayoutComponents)
        }

        return nonLayoutComponents
    }

    function insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps) {
        const root = getRootForNonLayoutComponents(mobilePage)
        insertNonLayoutComponentsToStructure(root, nonLayoutComps)
    }

    return {
        extractNonLayoutComponentsFromPages,
        insertNonLayoutComponentsToPage
    }
})
