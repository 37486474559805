import _ from 'lodash'
import coreUtilsLib from '@wix/santa-core-utils'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import wixUserApi from './wixUserApi'
import linkUtils from './linkUtils'
import linkTypes from './linkTypes'

const {wixUrlParser} = warmupUtils

const SAME_TAB_TARGET = '_self'
const NEW_TAB_TARGET = '_blank'
const LOGIN_TO_WIX_URL = 'https://users.wix.com/signin?'
/*eslint no-script-url:0*/
const EMPTY_HREF = 'javascript:void()'

const newOptionalParameters = {
    sendEmail: 'sendMail',
    welcomeEmailTemplate: 'mailTemplate',
    userColor: 'userColor',
    loginCompName: 'loginCompName',
    referralInfo: 'loginCompName'
}

function renderExternalLink(siteData, linkData) {
    let urlToRender = linkData.url === '/' ? siteData.publicBaseUrl : linkData.url

    if (!coreUtilsLib.validationUtils.isValidUrl(urlToRender)) {
        return {}
    }

    urlToRender = wixUrlParser.normalizeProtocolForSameSite(siteData, urlToRender)
    const target = linkData.target || NEW_TAB_TARGET
    const rel = _.trim(`${siteData.isPremiumDomain ? '' : 'nofollow'} ${target === NEW_TAB_TARGET ? 'noopener' : ''}`)

    return {
        href: urlToRender,
        target,
        'data-content': urlToRender,
        'data-type': 'external',
        rel
    }
}

function renderCustomPageLink(siteData, linkData, customNavInfo?) {
    const pageData = linkData.pageId

    if (!pageData) {
        return {}
    }

    const navInfo = customNavInfo || {
        pageId: pageData.id,
        title: pageData.pageUriSEO
    }

    _.assign(navInfo, getNavInfoFromLinkData(linkData))

    // @ts-ignore
    const href = wixUrlParser.getUrl(siteData, navInfo)
    const link = {
        href,
        target: linkData.target || SAME_TAB_TARGET
    }

    if (isPopupPage(siteData, pageData.id)) {
        _.assign(link, {
            href: EMPTY_HREF,
            'data-no-physical-url': href
        })
    }

    return link
}

function getNavInfoFromLinkData({queryParams, tpaInnerRoute, isTpaRoute}) {
    return _.assign(queryParams ? {queryParams} : undefined, tpaInnerRoute ? {tpaInnerRoute} : undefined, isTpaRoute ? {ignorePageUriSeo: true} : undefined)
}

function renderDynamicPageLink(siteData, linkData) {
    if (!linkData.routerId) {
        return {}
    }

    if (linkData.isTpaRoute && _.get(siteData.pagesDataItemsMap, [linkData.routerId, 'tpaApplicationId']) > 0) {
        return renderCustomPageLink(
            siteData,
            _.assign(
                {
                    pageId: siteData.pagesDataItemsMap[linkData.routerId],
                    tpaInnerRoute: linkData.innerRoute
                },
                linkData
            )
        )
    }

    const navInfo = {
        routerId: linkData.routerId,
        innerRoute: linkData.innerRoute
    }

    if (linkData.queryParams) {
        _.assign(navInfo, {
            queryParams: linkData.queryParams
        })
    }

    // @ts-ignore
    const href = wixUrlParser.getUrl(siteData, navInfo)
    const link = {
        href,
        target: SAME_TAB_TARGET
    }
    if (linkData.anchorDataId) {
        link['data-anchor'] = linkData.anchorDataId
    }

    return link
}

function renderPageLink(siteData, linkData) {
    return renderCustomPageLink(siteData, linkData)
}

//for now, we have link anchors on primary pages only (not on popups)
function renderAnchorLink(siteData, linkData, navInfo) {
    const isMasterPage = linkData.pageId.id === 'masterPage'
    const clonedLinkData = _.clone(linkData)
    const anchorId = _.has(linkData.anchorDataId, ['id']) ? linkData.anchorDataId.id : linkData.anchorDataId
    const anchorCompId = _.get(linkData.anchorDataId, ['compId'], '')
    const isPageScroll = _.includes(coreUtilsLib.siteConstants.SAME_PAGE_SCROLL_ANCHORS, anchorId) || isMasterPage
    const isLinkOnZoom = navInfo.imageZoom
    const isLinkOnPopup = isPopupPage(siteData, navInfo.pageId)
    const isDynamicPage = _.has(navInfo, ['routerDefinition'])
    let linkProperty

    if (isPageScroll) {
        if (isLinkOnZoom || isLinkOnPopup) {
            //TODO: Alissa this should be silent - update not nvigate
            const pageData = siteData.pagesDataItemsMap[siteData.primaryPageId]
            linkProperty = renderCustomPageLink(siteData, {pageId: pageData})
        } else {
            linkProperty = renderCustomPageLink(siteData, clonedLinkData, navInfo)
        }
    } else if (isDynamicPage) {
        const isAnchorOnCurrentPage = _.get(clonedLinkData, ['pageId', 'id']) === siteData.currentUrlPageId
        linkProperty = renderCustomPageLink(siteData, clonedLinkData, isAnchorOnCurrentPage ? navInfo : undefined)
    } else {
        linkProperty = renderPageLink(siteData, clonedLinkData)
    }

    if (!isLinkOnPopup) {
        linkProperty['data-keep-roots'] = true
    }

    linkProperty['data-anchor-comp-id'] = anchorCompId
    linkProperty['data-anchor'] = anchorId

    return linkProperty
}

function renderPhoneLink(linkData) {
    return {
        href: linkUtils.getPhoneLink(linkData),
        'data-content': linkData.phoneNumber,
        'data-type': 'phone'
    }
}

function renderWhatsAppLink(linkData) {
    return {
        href: linkUtils.getWhatsAppLink(linkData),
        target: '_blank',
        'data-content': linkData.phoneNumber,
        'data-type': 'whatsapp'
    }
}

function renderAddressLink(linkData) {
    return {
        href: `http://maps.google.com/maps?daddr=${encodeURI(linkData.address)}`,
        target: NEW_TAB_TARGET,
        'data-content': linkData.address,
        'data-type': 'address'
    }
}

function renderEmailLink(linkData) {
    return {
        href: linkUtils.getEmailLink(linkData),
        target: SAME_TAB_TARGET,
        'data-content': linkData.recipient,
        'data-type': 'mail'
    }
}

function renderDocumentLink(siteData, linkData) {
    return {
        href: linkUtils.getDocumentLink(linkData, siteData),
        target: NEW_TAB_TARGET,
        'data-type': 'document'
    }
}

function renderLoginToWixLink(siteData, linkData) {
    const postLoginUrl = linkData.postLoginUrl || ''
    let postSignupUrl = linkData.postSignupUrl || ''
    let view = linkData.dialog || 'showLogin'
    let target = ''
    let urlParams
    let href
    let paramObj

    if (_.includes(postSignupUrl, 'ifcontext')) {
        paramObj = coreUtilsLib.urlUtils.parseUrl(postSignupUrl).query
        _.forOwn(paramObj, function (value, key) {
            if (key.toLowerCase() === 'ifcontext') {
                target = value.replace('#', '')
                if (/^[a-zA-Z0-9]+$/.test(target)) {
                    postSignupUrl = postSignupUrl.replace('{ifcontext}', target)
                } else {
                    postSignupUrl = postSignupUrl.replace('{ifcontext}', 'illegalContextValue')
                }
            }
        })
    }

    if (wixUserApi.isSessionValid(siteData.cookie)) {
        href = postLoginUrl
    } else {
        if (view === 'createUser') {
            view = 'sign-up'
        }

        urlParams = {
            originUrl: siteData.currentUrl.full,
            postLogin: postLoginUrl,
            postSignUp: postSignupUrl,
            view,
            overrideLocale: siteData.languageCode
        }

        _.forEach(newOptionalParameters, function (value, key) {
            const linkDataItem = linkData[value]
            if (!_.isUndefined(linkDataItem) && linkDataItem !== '') {
                urlParams[key] = linkDataItem
            }
        })

        href = LOGIN_TO_WIX_URL + coreUtilsLib.urlUtils.toQueryString(urlParams)
    }

    return {
        href,
        target: SAME_TAB_TARGET
    }
}

function renderSwitchMobileViewModeLink(siteData, linkData) {
    return {
        href: siteData.currentUrl.full,
        target: '_self',
        'data-mobile': linkData.dataMobile
    }
}

function renderFormSubmitLink() {
    return {
        parentConstType: 'button'
    }
}

function isPopupPage(siteData, pageId) {
    const pageData = siteData.pagesDataItemsMap[pageId]
    return pageData && pageData.isPopup
}

function isEmailLink(linkUrl) {
    return !!linkUrl && linkUrl.indexOf('mailto') === 0
}

function isExternalLink(siteData, linkUrl) {
    if (!linkUrl) {
        return false
    }

    const resolvedSiteData = wixUrlParser.utils.getResolvedSiteData(siteData)
    const isInSameSiteUrl = !!wixUrlParser.parseUrl(resolvedSiteData, linkUrl)
    const documentDomain = resolvedSiteData.serviceTopology.userFileDomainUrl
    const isMediaLink = linkUrl.indexOf(documentDomain) === 0
    const isEmptyLink = linkUrl === EMPTY_HREF
    //image from Naama + validate server new schema + move to QA

    return !(isInSameSiteUrl || isEmailLink(linkUrl) || isMediaLink || isEmptyLink)
}

function isInvalidLink(linkData) {
    return (
        (linkData.type === 'PageLink' && !linkData.pageId) ||
        (linkData.type === 'AnchorLink' && !linkData.pageId) ||
        (linkData.type === 'AnchorLink' && !linkData.anchorDataId) ||
        (linkData.type === 'DocumentLink' && !linkData.docId) ||
        (linkData.type === 'DynamicPageLink' && !linkData.routerId)
    )
}

function isRelativeUrl(url) {
    if (!url) {
        return false
    }
    return url.charAt(0) === '/'
}

function getContextString(galleryDataId, propertyQuery, galleryCompId) {
    const contextArr: any[] = []
    if (galleryDataId) {
        contextArr.push(`galleryId:${galleryDataId}`)
    }
    if (propertyQuery) {
        contextArr.push(`propertyQuery:${propertyQuery}`)
    }
    if (galleryCompId) {
        contextArr.push(`galleryCompId:${galleryCompId}`)
    }
    return contextArr.join(' ')
}

/**
 * @class utils.linkRenderer
 */
export default {
    renderLink(linkData, siteData, navInfo) {
        if (!linkData || isInvalidLink(linkData) || !navInfo) {
            return {}
        }

        const resolvedSiteData = wixUrlParser.utils.getResolvedSiteData(siteData)
        switch (linkData.type) {
            case linkTypes.PAGE_LINK:
                return renderPageLink(resolvedSiteData, linkData)
            case linkTypes.EXTERNAL_LINK:
                return renderExternalLink(resolvedSiteData, linkData)
            case linkTypes.ANCHOR_LINK:
                return renderAnchorLink(resolvedSiteData, linkData, navInfo)
            case linkTypes.LOGIN_TO_WIX_LINK:
                return renderLoginToWixLink(resolvedSiteData, linkData)
            case linkTypes.EMAIL_LINK:
                return renderEmailLink(linkData)
            case linkTypes.PHONE_LINK:
                return renderPhoneLink(linkData)
            case linkTypes.WHATSAPP_LINK:
                return renderWhatsAppLink(linkData)
            case linkTypes.DOCUMENT_LINK:
                return renderDocumentLink(resolvedSiteData, linkData)
            case linkTypes.SWITCH_MOBILE_VIEW_MODE:
                return renderSwitchMobileViewModeLink(resolvedSiteData, linkData)
            case linkTypes.DYNAMIC_PAGE_LINK:
                return renderDynamicPageLink(resolvedSiteData, linkData)
            case 'FormSubmitButtonLink':
                return renderFormSubmitLink()
            case linkTypes.ADDRESS_LINK:
                return renderAddressLink(linkData)
            default:
                return {}
        }
    },

    /**
     *
     * @param {core.SiteData} siteData
     * @param {site.rootNavigationInfo} parentNavInfo
     * @param {(string|data.compDataItem)} imageData
     * @param {(string)} [propertyQuery]
     * @returns {{href: string}}
     */
    renderImageZoomLink(siteData, parentNavInfo, imageData, galleryDataId, propertyQuery, galleryCompId) {
        const navInfoForLink = _.defaults(
            {
                pageItemId: imageData.id,
                title: imageData.title,
                imageZoom: true
            },
            parentNavInfo
        )
        const resolvedSiteDataForLink = wixUrlParser.utils.getResolvedSiteData(siteData)

        /** @type data.compDataItem*/
        // @ts-ignore
        const href = wixUrlParser.getUrl(resolvedSiteDataForLink, navInfoForLink)
        const linkProps = {
            href,
            target: SAME_TAB_TARGET
        }
        if (isPopupPage(resolvedSiteDataForLink, navInfoForLink.pageId)) {
            linkProps.href = EMPTY_HREF
            linkProps['data-no-physical-url'] = href
        }

        const context = getContextString(galleryDataId, propertyQuery, galleryCompId)

        if (context) {
            linkProps['data-page-item-context'] = context
        }

        return linkProps
    },

    /**
     *
     * @param pageId
     * @param siteData
     * @returns {*}
     */
    renderPageLink(pageId, siteData) {
        const resolvedSiteData = wixUrlParser.utils.getResolvedSiteData(siteData)

        return renderPageLink(resolvedSiteData, {pageId})
    },

    isExternalLink,

    isRelativeUrl,

    isEmailLink,

    getLinkUrlFromLinkProps(linkProps) {
        return linkProps.href === EMPTY_HREF ? linkProps['data-no-physical-url'] : linkProps.href
    },

    CONSTS: {
        LOGIN_TO_WIX_URL
    }
}
